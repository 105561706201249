import { filter } from 'lodash-es';

import { environment } from '@environment';
import { AppModel } from '@http/app/app.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { INTEGRATIONS_HEADLINERS } from '@panel/app/partials/service-installation/service-installation-integrations/service-installation-integrations.constants';
import { SPECIAL_PLANS_ARRAY, SUBSCRIPTION_STATUSES } from '@panel/app/services/billing-info/billing-info.constants';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { OneTimeFlagHelper } from '@panel/app-old/shared/services/one-time-flag/one-time-flag.service';
import { ServiceInstallationToastService } from '@panel/app-old/shared/services/service-installation-toast/service-installation-toast.service';
import { UtilsService } from '@panel/app-old/shared/services/utils/utils.service';

export class BlockPanelHelper {
  hasInit: boolean = false;

  // @ngInject
  constructor(
    private readonly $q: any,
    private readonly $state: any,
    private readonly $transitions: any,
    private readonly appModel: AppModel,
    private readonly integrationModel: any,
    private readonly limitOfUniqueVisitorsModalService: any,
    private readonly oneTimeFlagHelper: OneTimeFlagHelper,
    private readonly partyIsOverModalService: any,
    private readonly serviceInstallationToast: ServiceInstallationToastService,
    private readonly utilsService: UtilsService,
    private readonly writeToIntegrationModalService: any,
    private readonly l10nHelper: any,
  ) {}

  init(): void {
    if (this.hasInit === true) {
      return;
    }

    this.hasInit = true;

    /** Заход на App из ниоткуда */
    this.$transitions.onSuccess(
      {
        entering: 'app',
      },
      (transition: any) => this.toAppFromNowhere(transition),
    );

    /** Заход на App из аккаунта */
    this.$transitions.onSuccess(
      {
        entering: 'app',
        exiting: 'account',
      },
      (transition: any) => this.toApp(transition),
    );

    /** Переходы по App'у */
    this.$transitions.onSuccess(
      {
        entering: 'app',
        exiting: 'app',
      },
      (transition: any) => this.toApp(transition),
    );

    /** Переход по дочерним состояниям App'а */
    this.$transitions.onSuccess(
      {
        retained: 'app',
      },
      (transition: any) => this.toApp(transition),
    );

    /** Заход из App'а на аккаунт */
    this.$transitions.onSuccess(
      {
        entering: 'account',
        exiting: 'app',
      },
      () => this.toAccount(),
    );
  }

  /** Закрытие всех механик */
  hideAllMechanics() {
    this.limitOfUniqueVisitorsModalService.hideModal();
    this.partyIsOverModalService.hideModal();
    this.serviceInstallationToast.hideToast();
    this.writeToIntegrationModalService.hideModal();
  }

  /**
   * Находится ли пользователь на страницах интеграций, которые мы даём настраивать без установки кода
   *
   * @param {String} transitionStateName - Имя state, которое нужно проверить
   */
  checkHeadlinerIntegrationPages(transitionStateName: any): any {
    let integrationsHeadlinerStateNames: any = [];
    INTEGRATIONS_HEADLINERS[environment.country].forEach((integration) => {
      integrationsHeadlinerStateNames.push(`app.content.integrations.details.configured.${integration}`);
    });

    return [...integrationsHeadlinerStateNames].includes(transitionStateName);
  }

  /**
   * Настроена ли интеграция полностью.
   * Для каждого типа интеграции свои условия, при которой она считается настроенной.
   *
   * @param integration Объект интеграции
   */
  isIntegrationSetUp(integration: any): any {
    const integrationType = integration.type;

    switch (integrationType) {
      case INTEGRATION_TYPES.TELEGRAM:
        return !!integration.settings.botToken;
      case INTEGRATION_TYPES.VK:
        const subscribedVkGroups = integration.settings.groups.filter((group: any) => group.subscribed);
        return subscribedVkGroups.length > 0;
      case INTEGRATION_TYPES.INSTAGRAM:
        const subscribedInstagramPages = filter(integration.settings.pages, { subscribed: true });
        return subscribedInstagramPages.length > 0;
      case INTEGRATION_TYPES.FACEBOOK:
        const subscribedFacebookPages = filter(integration.settings.pages, { subscribed: true });
        return subscribedFacebookPages.length > 0;
      case INTEGRATION_TYPES.VIBER:
        return !!integration.settings.paToken;
    }
  }

  /** Обработчик перехода из App'а в аккаунт */
  toAccount(): any {
    this.hideAllMechanics();
  }

  /**
   * Обработчик переходов по App'у
   *
   * @param transition
   */
  toApp(transition: any): any {
    this.hideAllMechanics();

    const appBlocks = transition.injector().get('appBlocks');
    const currentApp = transition.injector().get('currentApp');
    const billingInfo = transition.injector().get('billingInfo');
    const djangoUser = transition.injector().get('djangoUser');

    const isAppBlocked = this.appModel.isAppBlocked(currentApp);
    const blockUsersHardQuota = appBlocks.blockUsersHardQuota;
    const userReachedQuota = appBlocks.usersUsed > appBlocks.usersPlanLimit;
    const hasSocialNetworkActivation = !!currentApp.activation.social_network_integrations_reply_user; // Был активирован в соцсети
    const isDashboardPage = transition.to().name === 'app.content.dashboard';
    const isHeadlinerIntegrationPages = this.checkHeadlinerIntegrationPages(transition.to().name);
    const isSettingsPage = transition.to().name.includes('app.content.settings');
    const isSpecialPlan = SPECIAL_PLANS_ARRAY.includes(billingInfo.subscription.plan_id);
    const isStarterGuideTooltipHasBeenShown = this.oneTimeFlagHelper.isSet(ONE_TIME_FLAGS.STARTER_GUIDE_LOGO_TOOLTIP);
    const isSubscriptionCanceled = billingInfo.subscription.status === SUBSCRIPTION_STATUSES.CANCELLED;
    const isSubscriptionPage = ['app.content.subscription.general', 'app.content.subscription.pricing'].includes(
      transition.to().name,
    );
    const isUsCountry = this.l10nHelper.isUsCountry();

    /**
     * Показываем тултип про настройку сервиса, если был переход НЕ на стартергайд
     * и тултип ещё ни разу не показывался
     */
    if (!isDashboardPage && !isStarterGuideTooltipHasBeenShown) {
      this.utilsService.isStarterGuideTooltipOpened.next(true);
    }

    if (isSubscriptionPage) {
      return;
    }

    if (isSubscriptionCanceled) {
      return this.partyIsOverModalService.showModal(currentApp, djangoUser, billingInfo);
    }

    if (isUsCountry) {
      if (blockUsersHardQuota && userReachedQuota) {
        if (!isSpecialPlan) {
          return;
        }
        if (isSettingsPage) {
          return;
        }
        return this.limitOfUniqueVisitorsModalService.showModal(currentApp, djangoUser, billingInfo, appBlocks);
      }
    }

    if (isDashboardPage || isSettingsPage) {
      return;
    }

    if (!isAppBlocked) {
      return;
    }

    if (!isHeadlinerIntegrationPages) {
      return this.serviceInstallationToast.showToast(currentApp, djangoUser, billingInfo, this.$state, null);
    }

    // Проверяем, хочет ли пользователь получить доступ в панель через интеграции
    this.integrationModel.getList(currentApp.id, INTEGRATIONS_HEADLINERS[environment.country]).then((response: any) => {
      const stateParams = this.$state.router.globals.params;
      // Интеграция, на которую произошёл переход
      const currentHeadlinerIntegration = response.filter((integration: any) => {
        return integration.active === true && integration.id === stateParams.integrationId;
      })[0];

      if (currentHeadlinerIntegration) {
        if (hasSocialNetworkActivation || !this.isIntegrationSetUp(currentHeadlinerIntegration)) {
          return this.serviceInstallationToast.showToast(currentApp, djangoUser, billingInfo, this.$state, null);
        }

        if (!hasSocialNetworkActivation && this.isIntegrationSetUp(currentHeadlinerIntegration)) {
          return this.writeToIntegrationModalService.showModal(currentApp, djangoUser);
        }
      }
    });
  }

  /**
   * Обработчик захода на App из ниоткуда
   *
   * @param transition
   */
  toAppFromNowhere(transition: any): any {
    const isEmptyTransitionPage = transition.from().name === '';

    if (isEmptyTransitionPage) {
      this.toApp(transition);
    }
  }
}

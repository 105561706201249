import { ChangeDetectorRef, Directive, inject, NgZone } from '@angular/core';
import { AbstractControl, FormArray, NgControl } from '@angular/forms';

import { AbsBaseCVAComponent } from './abstract-base-cva-component';

@Directive()
export abstract class AbsCVAFormArrayBasedComponent<
  T,
  ControlType extends AbstractControl<T>,
> extends AbsBaseCVAComponent {
  abstract readonly control: FormArray<ControlType>;

  protected readonly changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly zone = inject(NgZone);
  protected readonly ngControl = inject(NgControl, { optional: true, self: true });

  constructor() {
    super();
  }

  /**
   * Программно обновляем список контролов.
   * Сначала чистим, потом поштучно добавляем.
   */
  writeValue(values: T[]) {
    this.control.clear({ emitEvent: false });
    if (!values) {
      return;
    }
    values.forEach((val) => {
      const control = this.getNewControl(val);
      this.control.push(control, { emitEvent: false });
    });
    // Необходимость возникает из-за использования OnPush. Без markForCheck ангуляр не подхватывает,
    // что кто-то что-то изменил в массиве
    this.changeDetectorRef.markForCheck();
  }

  abstract getNewControl(val: T): ControlType;
}

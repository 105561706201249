/**
 * Контроллер выбора CSS селектора
 */
(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventCssSelectorController', CqAutoEventCssSelectorController);

  function CqAutoEventCssSelectorController(validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.validationHelper = validationHelper;
    }
  }
})();

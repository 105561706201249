import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StepTitleComponent } from './step-title.component';

@NgModule({
  declarations: [StepTitleComponent],
  exports: [StepTitleComponent],
  imports: [CommonModule],
})
export class StepTitleModule {}

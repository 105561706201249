<ng-container *transloco="let t; read: 'chatWidgetHeaderComponent'">
  <label for="header-value">{{ t('input.label') }}:</label>
  <div
    class="form-control-container"
    [cqShowError]="control"
  >
    <textarea
      class="form-control no-resize"
      id="header-value"
      [formControl]="control"
      placeholder="{{ t('input.placeholder') }}"
      rows="2"
    ></textarea>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('input.errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('input.errors.maxlength', { maxLength: VALUE_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./popup-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupBlockSettings', cqPopupBlockSettings());

  function cqPopupBlockSettings() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        messagePartType: '=', // тип сообщения
        onClose: '&', // колбэк, вызываемый при закрытии если форма внутри валидна
        popupBlock: '=', // блок поп-апа
        properties: '=', // свойства, события, типы событий
        setErrorHandler: '&?', // функция для установки обработчика ошибок
      },
      controller: 'CqPopupBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-block-settings.html'),
    };
  }
})();

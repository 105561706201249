import { translate } from '@jsverse/transloco';

import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';

import { BotHiddenAction } from './hidden-action/hidden.action';

export class BotPropertyAction extends BotHiddenAction {
  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected get icon(): string {
    return '\ue928';
  }

  protected get translation(): string {
    return translate('classes.action.canvasText.property', {
      value: this.form.controls.body.value,
      property: this.form.prettyKeyName,
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { MoveStartGuideModalComponent } from './move-start-guide-modal.component';

@NgModule({
  declarations: [MoveStartGuideModalComponent],
  imports: [CommonModule, TranslocoModule, UIRouterModule, NgbModalModule],
})
export class MoveStartGuideModalModule {}

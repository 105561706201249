(function () {
  'use strict';

  require('./insales.controller');

  angular.module('myApp.integrations').component('cqInsalesModal', cqInsalesModal());

  function cqInsalesModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // apiCredentials - ключи API
        // currentApp - текущее приложение
        // integration - текущая интеграция
      },
      controller: 'CqInsalesModalController',
      controllerAs: 'vm',
      template: require('./insales.html'),
    };
  }
})();

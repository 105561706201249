import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * General information:
 * So, every time you use .subscribe(), RxJS creates a new entity called Subscription.
 * This entity will exist in your app while the stream is alive.
 * That is why subscriptions created inside a component live after component has been destroyed.
 *
 * Services, defined in providers array of Component/Directive metadata follow same lifecycle hooks
 * and ngOnDestroy of this service is called when host instance is destroyed.
 */
@Injectable()
export class DestroyService extends Subject<void> implements OnDestroy {
  ngOnDestroy() {
    this.next();
    this.complete();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageNameEditorComponent } from './message-name-editor.component';

@NgModule({
  declarations: [MessageNameEditorComponent],
  exports: [MessageNameEditorComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, SharedModule],
})
export class MessageNameEditorModule {}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { tuiPure } from '@taiga-ui/cdk';
import { OnUpdatedEventListenerArgs, OverlayScrollbars } from 'overlayscrollbars';
import { BehaviorSubject } from 'rxjs';

import { App } from '@http/app/app.model';
import { CHANNEL_TYPES, PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { Channel, Counters } from '@http/channel/channel.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { WhatsAppChannelPopoverWrapperComponent } from '@panel/app/pages/conversations/conversations-channel-list/whats-app-channel-popover-wrapper/whats-app-channel-popover-wrapper.component';
import { fadeTrigger } from '@panel/app/shared/animations/fade';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

import { TelegramChannelPopoverWrapperComponent } from './telegram-channel-popover-wrapper/telegram-channel-popover-wrapper.component';

@Component({
  selector:
    'cq-conversations-channel-list[channel][channels][currentApp][djangoUser][notReadCounters][notAssignedCounters]',
  templateUrl: './conversations-channel-list.component.html',
  styleUrls: ['./conversations-channel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeTrigger],
})
export class ConversationsChannelListComponent implements AfterViewInit {
  /** Активный канал */
  @Input()
  activeChannel!: Channel;

  /** Список каналов */
  @Input()
  channels!: Channel[];

  @Input()
  currentApp!: App;

  @Input()
  djangoUser!: DjangoUser;

  @Input()
  initShowUseCalendlyPopoverFn!: (func: () => void) => void;

  // HACK: счётчики пришлось разнести на два immutable-объекта, чтобы работал change detection
  /** Счётчики неназначенных диалогов */
  @Input()
  notAssignedCounters!: Counters['notAssigned'];

  /** Счётчики непрочтённых сообщений */
  @Input()
  notReadCounters!: Counters['notRead'];

  /** Изменение канала */
  @Output()
  channelChanged = new EventEmitter<Channel>();

  @ViewChild(WhatsAppChannelPopoverWrapperComponent)
  whatsAppChannelPopover: WhatsAppChannelPopoverWrapperComponent | null = null;

  @ViewChild(TelegramChannelPopoverWrapperComponent)
  telegramChannelPopover: TelegramChannelPopoverWrapperComponent | null = null;

  PSEUDO_CHANNEL_TYPES = PSEUDO_CHANNEL_TYPES;

  /**
   * Есть ли у каналов переполнение
   *
   * или другими словами есть скрол или нет
   */
  isChannelsOverflow$ = new BehaviorSubject(false);

  onOsUpdate([instance, onUpdatedArgs]: [OverlayScrollbars, OnUpdatedEventListenerArgs]) {
    this.isChannelsOverflow$.next(!!onUpdatedArgs.changedOptions.overflow?.y?.includes('scroll'));
  }

  constructor(private readonly carrotquestHelper: CarrotquestHelper, private readonly ngZone: NgZone) {}

  ngAfterViewInit() {
    if (this.telegramChannelPopover) {
      this.telegramChannelPopover.showPopover().subscribe({
        next: this.trackShowTelegramChannelPopover,
        error: () => {},
      });
    }

    if (this.whatsAppChannelPopover) {
      this.whatsAppChannelPopover.showPopover().subscribe({
        next: this.trackShowWhatsAppChannelPopover,
        error: () => {},
      });
    }
  }

  /** Каналы, доступные для чтения */
  get availableChannels(): Channel[] {
    return this.getAvailableChannels(this.channels);
  }

  /** Последний созданный канал Telegram */
  get latestTelegramChannel(): Channel | null {
    return this.getLatestChannelByType(this.userChannels, CHANNEL_TYPES.TELEGRAM);
  }

  /** Последний созданный канал WhatsApp */
  get latestWhatsAppChannel(): Channel | null {
    return this.getLatestChannelByType(this.userChannels, CHANNEL_TYPES.WHATS_APP);
  }

  /** Псевдоканалы */
  get pseudoChannels(): Channel[] {
    return this.getPseudoChannels(this.availableChannels, this.userChannels);
  }

  /** Пользовательские каналы (доступные на чтение за исключением псевдоканалов) */
  get userChannels(): Channel[] {
    return this.getUserChannels(this.availableChannels);
  }

  activateChannel(channel: Channel): void {
    if (this.activeChannel.id === channel.id) {
      return;
    }

    this.trackChangeChannel();
    this.activeChannel = channel;
    this.channelChanged.emit(this.activeChannel);
  }

  /**
   * Получение доступных пользователю каналов для чтения
   *
   * @param channels Список всех каналов аппа
   */
  @tuiPure
  private getAvailableChannels(channels: Channel[]): Channel[] {
    this.activeChannel = channels[0];
    return channels.filter((channel) => channel.readPermission);
  }

  /**
   * Получение последнего созданного канала WhatsApp или Telegram
   * Почему-то считается, что такой канал был создан автоматически, интеграцией с WhatsApp (я хз почему так, так было сделано до апгрейда списка каналов на Angular)
   * Рядом с таким каналом будет показываться поповер об автоматическом создании канала для диалогов из WhatsApp
   *
   * @param userChannels Пользовательские каналы
   * @param channelType Тип канала
   */
  @tuiPure
  private getLatestChannelByType(userChannels: Channel[], channelType: CHANNEL_TYPES): Channel | null {
    const channels = userChannels.filter((channel) => channel.type === channelType);

    if (channels.length === 0) {
      return null;
    }

    return channels.reduce((latestChannel, currentChannel) => {
      if (!currentChannel.id || !latestChannel.id) {
        return latestChannel;
      }

      return currentChannel.id > latestChannel.id ? currentChannel : latestChannel;
    });
  }

  /**
   * Получение псевдоканалов
   *
   * @param availableChannels Доступные пользователю каналы для чтения
   * @param userChannels Пользовательские каналы
   */
  @tuiPure
  private getPseudoChannels(availableChannels: Channel[], userChannels: Channel[]): Channel[] {
    // если у пользователя нет доступных пользовательских каналов - выводится только 1 псевдоканал ("Все каналы", находится по индексу 0)
    // если есть доступные пользовательские каналы - выводится 2 псевдоканала ("Все каналы" и "Без канала", находится по индексу 1)
    if (userChannels.length) {
      return [availableChannels[0], availableChannels[1]];
    } else {
      return [availableChannels[0]];
    }
  }

  /**
   * Получение отсортированного списка пользовательских каналов (всех доступных для чтения, за исключением псевдоканалов)
   *
   * @param availableChannels Доступные пользователю каналы
   */
  @tuiPure
  private getUserChannels(availableChannels: Channel[]): Channel[] {
    // пользовательские каналы должны быть отсортированы по приоритету, у самого приоритетного канала priority меньше всех
    return availableChannels
      .filter(
        (channel) =>
          channel.type !== PSEUDO_CHANNEL_TYPES.ALL_CHANNELS && channel.type !== PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL,
      )
      .sort((a, b) => a.priority - b.priority);
  }

  /**
   * Трекинг списка каналов по ID
   *
   * @param index Индекс канала в *ngFor
   * @param channel Канал
   * @returns ID Канала
   */
  trackByChannelId(index: number, channel: Channel): string | null {
    return channel.id;
  }

  /**
   * Трек смены канала
   */
  trackChangeChannel(): void {
    this.carrotquestHelper.track('Диалоги - переключил канал');
  }

  /**
   * Трек показа поповера про создание канала с Telegram
   */
  trackShowTelegramChannelPopover(): void {
    this.carrotquestHelper.track('Показ поповера про канал Telegram (Активация)');
  }

  /**
   * Трек показа поповера про создание канала с WhatsApp
   */
  trackShowWhatsAppChannelPopover(): void {
    this.carrotquestHelper.track('Показ поповера про канал WhatsApp (Активация)');
  }
}

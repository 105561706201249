/**
 * Вызов $sce.trustAsHtml() при помощи фильтра
 */
(function () {
  'use strict';

  angular.module('myApp.filters.trustHtml').filter('trustHtml', trustHtml);

  function trustHtml($sce) {
    return $sce.trustAsHtml;
  }
})();

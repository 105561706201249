/**
 * Максимальная длина UTM меток
 */
export const UTM_MARKS_MAX_LENGTH = 100;

/**
 * Массив с объектами системных значений с их порядковыми номерами для сортировки
 */
export const UTM_MARKS_SYSTEM_VALUES = {
  MESSAGE_SUBJECT: '$MessageSubject', //MESSAGE_TYPE: '$MessageType', 27.08.2019 Ваня Берестов: Скрыто, т.к. на данный момент в эту переменную может подставляться только email
  MESSAGE_ID: '$MessageID',
  DATE_START: '$DateStart',
  CARROT_ID: '$CarrotID',
  USER_ID: '$UserID',
};

/**
 * Массив системных значений UTM меток
 */
export const UTM_MARKS_SYSTEM_VALUES_ARRAY = [
  UTM_MARKS_SYSTEM_VALUES.MESSAGE_SUBJECT,
  //UTM_MARKS_SYSTEM_VALUES.MESSAGE_TYPE, 27.08.2019 Ваня Берестов: Скрыто, т.к. на данный момент в эту переменную может подставляться только email
  UTM_MARKS_SYSTEM_VALUES.MESSAGE_ID,
  UTM_MARKS_SYSTEM_VALUES.DATE_START,
  UTM_MARKS_SYSTEM_VALUES.CARROT_ID,
  UTM_MARKS_SYSTEM_VALUES.USER_ID,
];

/**
 * Типы UTM меток
 */
export const UTM_MARKS_TYPES = {
  UTM_CAMPAIGN: 'utmCampaign',
  UTM_CONTENT: 'utmContent',
  UTM_MEDIUM: 'utmMedium',
  UTM_SOURCE: 'utmSource',
  UTM_TERM: 'utmTerm',
};

/**
 * Массив типов UTM меток
 */
export const UTM_MARKS_TYPES_ARRAY = [
  UTM_MARKS_TYPES.UTM_SOURCE,
  UTM_MARKS_TYPES.UTM_MEDIUM,
  UTM_MARKS_TYPES.UTM_CAMPAIGN,
  UTM_MARKS_TYPES.UTM_TERM,
  UTM_MARKS_TYPES.UTM_CONTENT,
];

(function () {
  'use strict';

  require('./live.controller');

  angular.module('myApp.live').component('cqLive', cqLive());

  function cqLive() {
    return {
      bindings: {
        billingInfo: '=', // информация по биллингу
        currentApp: '=', // текущее приложение
        djangoUser: '=', // текущий пользователь
        telegramIntegrations: '=',
      },
      controller: 'CqLiveController',
      controllerAs: 'vm',
      template: require('./live.html'),
    };
  }
})();

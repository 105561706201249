/**
 * Директива выключает валидацию, если элемент деактивирован (disabled)
 */
(function () {
  'use strict';

  angular.module('myApp.directives.disableValidation').directive('cqDisableValidation', cqDisableValidation);

  function cqDisableValidation() {
    return {
      // HACK: я так и не понял как priority влияет на $observe, но без priority не работает отключение валидации в случае, если элемент показывается по ng-if
      // подробнее читать тут https://stackoverflow.com/questions/24213342/directive-render-in-different-order
      priority: 1,
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var errorsWatcher; // watcher для объекта ngModelController.$error

      scope.$watch(disabledWatchExpression, watchDisabled);

      /**
       * Отслеживание изменений iAttrs.disabled
       *
       * @returns {*}
       */
      function disabledWatchExpression() {
        return iAttrs.disabled;
      }

      /**
       * Функция, выполняющаяся при изменении iAttrs.disabled
       *
       * @param newValue
       * @param oldValue
       */
      function watchDisabled(newValue, oldValue) {
        if (newValue) {
          // на всякий случай нужно убедиться, что вотчера нету, т.к. в newValue может прийти истинное значение, отличающееся от предыдущего (например, в первый раз пришло true, а во второй какая-нибудь непустая строка)
          if (!errorsWatcher) {
            errorsWatcher = scope.$watchCollection(errorsWatchExpression, watchErrors);
          }
        } else {
          errorsWatcher && errorsWatcher(); // если инпут разблокировался - нужно перестать принудительно убирать ошибки, т.е. нужно убить вотчер
          errorsWatcher = null;
          controller.$validate(); // принудительно вызываем валидаторы инпута, иначе он будет всегда валидным, пока не вызовется $setViewValue
        }

        /**
         * Отслеживание изменений controller.$error
         *
         * @returns {Object}
         */
        function errorsWatchExpression() {
          return controller.$error;
        }

        /**
         * Функция, выполняющаяся при изменении controller.$error
         *
         * @param newValue
         * @param oldValue
         */
        function watchErrors(newValue, oldValue) {
          if (newValue) {
            // принудительно для каждой ошибки говорим, что это не ошибка
            angular.forEach(newValue, function (value, key) {
              controller.$setValidity(key, true);
            });
          }
        }
      }
    }
  }
})();

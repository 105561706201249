import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { TriggerChainOverlayEventService } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/services';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
@Component({
  selector: 'cq-tc-type-actions-overlay',
  templateUrl: './tc-type-actions-overlay.component.html',
  styleUrls: ['./tc-type-actions-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcTypeActionsOverlayComponent {
  @Input()
  @HostBinding('style.width.px')
  width: number = 0;

  @Input()
  @HostBinding('style.height.px')
  height: number = 0;

  @Input()
  @HostBinding('style.left.px')
  x = 0;

  @Input()
  @HostBinding('style.top.px')
  y = 0;

  _overlaySource?: IBlockView;

  set overlaySource(value: IBlockView) {
    this._overlaySource = value;
  }

  get overlaySource(): IBlockView {
    if (!this._overlaySource) {
      throw new Error('Could not find overlay source');
    }

    return this._overlaySource;
  }
  constructor(private readonly triggerChainOverlayEventService: TriggerChainOverlayEventService) {}

  copyBlock() {
    this.triggerChainOverlayEventService.emitEvent({
      type: 'action-copy-overlay',
      event: {
        uuid: this.overlaySource.uuid,
      },
    });
  }

  deleteBlock() {
    this.triggerChainOverlayEventService.emitEvent({
      type: 'action-delete-overlay',
      event: {
        uuid: this.overlaySource.uuid,
      },
    });
  }
}

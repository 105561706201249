<div class="card no-border no-margin-bottom">
  <div class="card-header flex flex-grow justify-space-between">
    <cq-member-invitations-filters
      class="w-100"
      [resetAllFiltersEmitter]="resetAllFiltersEmitter"
      (filterByStatus)="onFilterInvitationByStatus($event)"
      (filterByEmail)="onFilterInvitationByEmail($event)"
      data-test="cq-member-invitations-filters"
    ></cq-member-invitations-filters>
  </div>

  <div class="card-body no-padding">
    <cq-member-invitations-not-exist-zero-data
      *ngIf="!isInvitationsExist()"
      (clickAddTeamMembersButton)="onClickAddTeamMembersButton()"
      data-test="cq-member-invitations-not-exist-zero-data"
    ></cq-member-invitations-not-exist-zero-data>

    <cq-member-invitations-table
      *ngIf="isInvitationsExist()"
      [memberInvitations]="filteredInvitations"
      (resendInvitationFn)="onResendInvitationFn($event)"
      data-test="cq-member-invitations-table"
    ></cq-member-invitations-table>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { CalendlyIntegration } from '@http/integration/integrations/calendly/calendly-integration';
import { CalendlyIntegrationExternal } from '@http/integration/integrations/calendly/interfaces/calendly-integration.interface';
import { IntegrationService } from '@http/integration/services/integration.service';
import { DestroyService, SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class CalendlyComponent implements OnInit {
  /** Текущая или новая интеграция во внешнем формате */
  @Input({ required: true })
  integrationExternal: CalendlyIntegrationExternal | null = null;

  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: CalendlyIntegration;
  /** Авторизован ли пользователь */
  isAuthorized: boolean = false;
  /** OAuth Url для авторизации в интеграции */
  oAuthUrl = '';
  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;

  /** Получение контрола с названием интеграции */
  get nameControl() {
    return this.integration.form.controls.name;
  }

  /** ID текущего приложения */
  get currentAppId() {
    return this.appService.currentAppId;
  }
  /** Получение переведённого названия сервиса */
  get integrationTypeName() {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.CALENDLY);
  }

  requestIsPending = false;

  constructor(
    private readonly appService: AppService,
    private readonly destroy$: DestroyService,
    private readonly integrationFactory: IntegrationFactory,
    private readonly integrationService: IntegrationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly systemToastService: SystemToastService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    if (!!this.integrationExternal?.id) {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.CALENDLY, this.integrationExternal);
    } else {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.CALENDLY);
    }

    this.isAuthorized = !!this.integration.settings.oauthAccessToken;
    if (!this.isAuthorized) {
      this.updateOAuthUrl();
    }
  }

  /** Сохранение интеграции */
  save() {
    this.integrationService
      .save(this.currentAppId, this.integration)
      .pipe(
        catchError((err) => {
          this.systemToastService.requestError();
          throw err;
        }),
        finalize(() => {
          this.requestIsPending = false;
          // this.cdr.markForCheck();
        }),
      )
      .subscribe(() => {
        this.showIntegrationSaveToast();
      });
  }

  /** Показ тоста успешного сохранения интеграции */
  showIntegrationSaveToast() {
    this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));
  }

  /** Обновление OAuth url */
  updateOAuthUrl(): void {
    this.integrationService
      .getOAuthUrl(INTEGRATION_TYPES.CALENDLY, this.integration.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.oAuthUrl = response.authUrl;
        this.changeDetectorRef.detectChanges();
      });
  }
}

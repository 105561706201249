<ng-container *transloco="let t; read: 'paramEditorComponent'">
  <div class="d-flex align-items-center justify-content-between margin-between-cols-10">
    <div
      class="form-group has-feedback mb-0 flex-grow-1 col-6 p-0"
      [cqShowError]="keyForm"
    >
      <input
        type="text"
        class="form-control"
        [formControl]="keyForm"
        [placeholder]="t('paramKeyInput.placeholder')"
      />
      <span
        *ngIf="paramInfo"
        class="form-control-feedback auto-pointer-events"
      >
        <i
          class="cqi-sm cqi-question-circle"
          [ngbTooltip]="paramInfo"
          container="body"
          placement="top-right"
        ></i>
      </span>
      <cq-validation-messages [control]="keyForm">
        <cq-validation-message errorName="required"> {{ t('paramKeyInput.errors.required') }} </cq-validation-message>
        <cq-validation-message errorName="hasDefaultHeader">
          {{ t('paramKeyInput.errors.hasDefaultHeader') }}
        </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('paramKeyInput.errors.maxlength', { maxLength: PARAM_MAX_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <div
      class="form-group mb-0 flex-grow-1"
      [cqShowError]="valueForm"
    >
      <input
        type="text"
        class="form-control"
        [formControl]="valueForm"
        [placeholder]="t('paramValueInput.placeholder')"
      />
      <cq-validation-messages [control]="valueForm">
        <cq-validation-message errorName="required"> {{ t('paramValueInput.errors.required') }} </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('paramValueInput.errors.maxlength', { maxLength: PARAM_MAX_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <button
      *ngIf="!_paramsDisabled"
      class="btn btn-outline-secondary"
      (click)="delete.emit()"
      type="button"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
</ng-container>

import { Injectable } from '@angular/core';

import { EMOJI_GROUP, EMOJI_LIST, NATIVE_EMOJI } from '@panel/app/shared/services/emoji/emoji.constants';
import { Emoji, EmojiNamedObject, EmojiOptions } from '@panel/app/shared/services/emoji/emoji.types';

@Injectable({ providedIn: 'root' })
export class EmojiService {
  /** Объект, ключами которого являются имена emoji (чтобы каждый раз не искать emoji по имени в объекте emojiList) */
  emojiNamedObject!: EmojiNamedObject;

  /** Регулярное выражение, используется для замены emoji по имени в строке */
  emojiRegexp!: RegExp;

  constructor() {
    this.generateNamedObjectAndRegexp();
  }

  /** Функция, которая создаёт emojiNamedObject и emojiRegexp */
  private generateNamedObjectAndRegexp(): void {
    this.emojiNamedObject = {};
    let emojiRegexpStr = '(';

    for (const emojiGroup of Object.values(EMOJI_LIST)) {
      //@ts-ignore
      emojiGroup.forEach((emoji: any) => {
        this.emojiNamedObject[emoji.name] = {
          position: emoji.position,
        };
        emojiRegexpStr += escapeRegExp(emoji.name) + '|';
      });
    }

    // игнорирование emoji внутри тегов и в атрибутах тегов
    emojiRegexpStr = emojiRegexpStr.substr(0, emojiRegexpStr.length - 1) + ')(?![^<]*>|[^<>]*</)';

    this.emojiRegexp = new RegExp(emojiRegexpStr, 'g');

    /**
     * Функция для экранирования строки для последующего её использования в RegExp
     *
     * @param str Экранируемая строка
     * @returns Экранированная строка
     */
    function escapeRegExp(str: string): string {
      return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }
  }

  /**
   * Получение координат emoji по имени
   *
   * @param name Имя emoji
   * @returns Координаты emoji, если имя задано верно, и null в противном случае
   */
  getEmojiByName(name: string): EmojiOptions | null {
    return this.emojiNamedObject[name] ?? null;
  }

  /**
   * Получение списка названий всех групп emoji
   *
   * @returns Список названий групп emoji
   */
  getEmojiGroupList(): string[] {
    return Object.values(EMOJI_GROUP);
  }

  /**
   * Получение списка emoji по имени группы
   *
   * @param groupName Имя группы emoji
   * @returns Список emoji, если имя группы существует в emojiList, и null в противном случае
   */
  getEmojiListByGroup(groupName: EMOJI_GROUP): Emoji[] | null {
    return EMOJI_LIST[groupName] ?? null;
  }

  /**
   * Получение нативного emoji по имени
   *
   * @param name - Имя emoji
   * @returns Emoji, если имя задано верно, и null в противном случае
   */
  getNativeEmojiByName(name: string): string | null {
    return NATIVE_EMOJI[name] ?? null;
  }

  /**
   * Функция, заменяющая div-элементы с emoji на emoji во внутреннем формате админки
   *
   * @param stringWithDiv Строка с заменёнными emoji
   * @returns Строка с emoji во внутреннем формате админки
   */
  replaceDivEmojiToNativeEmoji(stringWithDiv: string): string {
    return stringWithDiv.replace(/<div title="([^"]+)" class="emoji"[^>]*><\/div>/g, '$1');
  }

  /**
   * Функция, заменяющая все вхождения emoji по имени в строке на div-элементы
   *
   * @param stringWithEmojis Строка, в которой будет производиться поиск и замена emoji по имени
   * @param tryUseBigEmoji Попытаться использовать большие emoji (к div-элементам будут применены другие стили)
   * @returns Строка с заменёнными emoji, если таковые нашлись, и исходная строка, если emoji в строке отсутствовали
   */
  replaceEmojis(stringWithEmojis: string, tryUseBigEmoji: boolean = true): string {
    // todo: Это всё надо будет переписать, когда будет нормальная директива для сообщений, куда будут вставляться emoji и уже из неё надо будет при помощи $compile создавать элемент cqEmojiElem

    let stringWithoutEmojis, stringWithReplacedEmojis;
    stringWithoutEmojis = stringWithEmojis.replace(this.emojiRegexp, '');
    const isBigEmoji = /^\s*$/.test(stringWithoutEmojis) && tryUseBigEmoji;
    const sheetMultiplier = isBigEmoji ? 32 : 22;

    // в соответствии с regexp в entry будет найденное вхождение, в match1 будет код emoji (:grinning:), а в match2 будет текстовый смайл emoji( :) )
    // при этом одновременно может быть либо match1, либо match2 (смотри спецификацию replace с callback-функцией)
    stringWithReplacedEmojis = stringWithEmojis.replace(this.emojiRegexp, (entry, match1, match2) => {
      const matchedGroup = match1 || match2;

      const cls = 'emoji';
      let style = '';

      const emoji = this.getEmojiByName(matchedGroup);

      if (emoji) {
        style = `
          width: ${sheetMultiplier}px;
          height: ${sheetMultiplier}px;
          background-position:
            -${emoji.position.x * sheetMultiplier}px -${emoji.position.y * sheetMultiplier}px !important
        `;

        return entry.replace(matchedGroup, `<div title="${entry}" class="${cls}" style="${style}"></div>`);
      } else {
        return entry;
      }
    });

    return stringWithReplacedEmojis;
  }
}

/**
 * Контроллер задержки срабатывания
 */
import { AUTO_EVENTS_PARAMS_TYPES } from '../../../../../app/http/track-master/track-master.constants';

(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventDelayController', CqAutoEventDelayController);

  function CqAutoEventDelayController(validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.AUTO_EVENTS_PARAMS_TYPES = AUTO_EVENTS_PARAMS_TYPES;
      vm.delayHas = angular.isUndefined(vm.delayHas) ? false : vm.delayHas;
      vm.validationHelper = validationHelper;
    }
  }
})();

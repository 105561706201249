(function () {
  'use strict';

  require('./user-events-list.controller');

  angular.module('myApp.userCard').component('cqUserEventsList', cqUserEventsList());

  function cqUserEventsList() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        events: '=', // События пользователя
        eventsPaginator: '<', // Пагинация в событиях пользователя
        eventTypes: '<', // Типы события
        userId: '<', // Id пользователя
        eventsLoaded: '<', // Флаг для загрузки событий
      },
      controller: 'CqUserEventsListController',
      controllerAs: 'vm',
      template: require('./user-events-list.html'),
    };
  }
})();

(function () {
  'use strict';

  angular.module('myApp.integrations', ['myApp.models.integration']);

  require('./integrations.routes');
  require('./bitrix1c/bitrix1c.controller');
  require('./bitrix1c/bitrix1c.html');
  require('./bitrix24/bitrix24.constants');
  require('./bitrix24/bitrix24.controller');
  require('./bitrix24/bitrix24.html');
  require('./bitrix24/oauth/bitrix24-oauth.component');
  require('./bitrix24/steps/step1.html');
  require('./bitrix24/steps/step2.html');
  require('./bitrix24/steps/step3.html');
  require('./bitrix24/steps/step4.html');
  require('./bitrix24/steps/step5.html');
  require('./dialogflow/dialogflow.controller');
  require('./dialogflow/dialogflow.html');
  require('./ecwid/ecwid.controller');
  require('./ecwid/ecwid.html');
  require('./email/email.controller');
  require('./email/email.html');
  require('./facebook/facebook.constants');
  require('./facebook/facebook.controller');
  require('./facebook/oauth/facebook-oauth.component');
  require('./facebook/facebook.html');
  require('./facebook/steps/step1.html');
  require('./facebook/steps/step2.html');
  require('./facebook/steps/step3.html');
  require('./facebook-leads/facebook-leads.constants');
  require('./facebook-leads/facebook-leads.controller');
  require('./facebook-leads/oauth/facebook-leads-oauth.component');
  require('./facebook-leads/facebook-leads.html');
  require('./facebook-leads/steps/step1.html');
  require('./facebook-leads/steps/step2.html');
  require('./facebook-leads/steps/step3.html');
  require('./facebook-leads/steps/step4.html');
  require('./insales/insales.controller');
  require('./insales/insales.html');
  require('./instagram/instagram.constans');
  require('./instagram/instagram.controller');
  require('./instagram/oauth/instagram-oauth.component');
  require('./instagram/instagram.html');
  require('./instagram/steps/step1.html');
  require('./instagram/steps/step2.html');
  require('./instagram/steps/step3.html');
  require('./jsscript/jsscript.controller');
  require('./jsscript/jsscript.html');
  require('./lambdascript/lambdascript.controller');
  require('./lambdascript/lambdascript.html');
  require('./mixpanel/mixpanel.controller');
  require('./mixpanel/mixpanel.html');
  require('./modals/bitrix1c/bitrix1c.component');
  require('./modals/insales/insales.component');
  require('./modals/opencart/opencart.component');
  require('./modals/prestashop/prestashop.component');
  require('./modals/wordpress/wordpress.component');
  require('./mytarget/mytarget.constants');
  require('./mytarget/mytarget.controller');
  require('./mytarget/oauth/mytarget-oauth.component');
  require('./mytarget/mytarget.html');
  require('./mytarget/steps/step1.html');
  require('./mytarget/steps/step2.html');
  require('./mytarget/steps/step3.html');
  require('./omnidesk/omnidesk.controller');
  require('./omnidesk/omnidesk.html');
  require('./opencart/opencart.controller');
  require('./opencart/opencart.html');
  require('./prestashop/prestashop.controller');
  require('./prestashop/prestashop.html');
  require('./retailcrm/retailcrm.constants');
  require('./retailcrm/retailcrm.controller');
  require('./retailcrm/retailcrm.html');
  require('./retailcrm/steps/step1.html');
  require('./retailcrm/steps/step2.html');
  require('./retailcrm/steps/step3.html');
  require('./roistat/roistat.controller');
  require('./roistat/roistat.html');
  require('./segment/segment.constants');
  require('./segment/segment.component');
  require('./sendsay/sendsay.controller');
  require('./sendsay/prop-name-format-validation.directive');
  require('./sendsay/sendsay.html');
  require('./shopify/shopify.controller');
  require('./shopify/shopify.html');
  require('./slack/slack.controller');
  require('./slack/slack.html');
  require('./usedesk/usedesk.controller');
  require('./usedesk/usedesk.html');
  require('./viber/viber.controller');
  require('./viber/viber.html');
  require('./webhook/webhook.controller');
  require('./webhook/webhook.html');
  require('./wordpress/wordpress.controller');
  require('./wordpress/wordpress.html');
  require('./yandex-metrica/yandex-metrica.controller');
  require('./yandex-metrica/yandex-metrica.html');
  require('./zapier/zapier.controller');
  require('./zapier/zapier.html');
  require('./zendesk/zendesk.component');
})();

/**
 * Контроллер для блока с событием пользователя
 */
(function () {
  'use strict';

  angular.module('myApp.userCard').controller('CqUserEventController', CqUserEventController);

  function CqUserEventController() {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.isShowUserEventProps = isShowUserEventProps;
    }

    /**
     * Показать св-ва события, если они есть, и если это не событие склейки
     * @returns {boolean}
     */
    function isShowUserEventProps() {
      return vm.event.type.name != '$user_merged' && vm.event.propsArray.length > 0;
    }
  }
})();

(function () {
  'use strict';

  require('./manual-messages-table.controller');

  angular.module('myApp.manualMessages').component('cqManualMessagesTable', cqManualMessagesTable());

  function cqManualMessagesTable() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        djangoUser: '=', // текущий пользователь
      },
      controller: 'CqManualMessagesTableController',
      controllerAs: 'vm',
      template: require('./manual-messages-table.html'),
    };
  }
})();

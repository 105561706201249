import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { upperFirst } from 'lodash-es';

import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureDenialReason } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';
import { App } from '@http/app/app.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { ProductFeatureTextService } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.service';
import { DenialReasonTexts } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.types';

/**
 * Компонент для работы с модальным окном отказа в доступе к фиче
 */

@Component({
  selector: 'cq-access-denial-modal[currentApp][denialReason]',
  templateUrl: './access-denial-modal.component.html',
  styleUrls: ['./access-denial-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDenialModalComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Причина | Причины отказа */
  @Input()
  denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] = {
    addOn: BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS,
    capability: PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS,
    limitAmount: null,
    limitExceeded: false,
    needChat: false,
    paidPlanVersion: PAID_PLAN_VERSION.V1,
    productFeature: PLAN_FEATURE.ANALYTICS_CONVERSATIONS,
  };

  /** Инстанс модального окна */
  _modalRef: any;
  @Input()
  set modalRef(value: any) {
    this._modalRef = value ? value : this.activeModal;
  }
  get modalRef() {
    return this._modalRef;
  }

  /** Тексты для причины отказа в доступе */
  denialReasonTexts: DenialReasonTexts = {
    action: '',
    description: '',
    message: '',
    title: '',
  };

  /** Получение заголовка причины отказа в доступе */
  get denialReasonTitleText() {
    return upperFirst(this.denialReasonTexts.title);
  }

  /** Получение описания причины отказа в доступе */
  get denialReasonDescriptionText() {
    return this.denialReasonTexts.description;
  }

  /** Получение текста сообщения в чат для запроса доступа */
  get denialReasonMessageText() {
    return this.denialReasonTexts.message;
  }

  constructor(
    protected readonly activeModal: NgbActiveModal,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly productFeatureTextService: ProductFeatureTextService,
  ) {}

  ngOnInit(): void {
    this.denialReasonTexts = this.productFeatureTextService.getDenialReasonTexts(this.denialReason);
  }

  /**
   * Отправка сообщения в чат
   *
   * @param message - Сообщение, которое необходимо отправить в чат
   */
  sendMessageToChat(message: string): void {
    this.carrotquestHelper.sendChatMessage(message);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { isNull } from 'lodash-es';
import { catchError } from 'rxjs/operators';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { WHATS_APP_EDNA_API_ERRORS } from '@http/integration/integrations/whats-app-edna/constants/whats-app-edna-integration.constants';
import { IntegrationExternal } from '@http/integration/interfaces/integration.interfaces';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AlreadyActiveTelegramBotModalComponent } from '@panel/app/partials/modals/already-active-telegram-bot-modal/already-active-telegram-bot-modal.component';
import { ALREADY_ACTIVE_TELEGRAM_BOT_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/already-active-telegram-bot-modal/already-active-telegram-bot-modal.token';
import { ModalHelperService, SystemToastService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { SafeRemoveComponent } from '@panel/app/shared/modals/safe-remove/safe-remove.component';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { App } from '@http/app/app.model';
import { TelegramBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { TelegramBotStoreService } from '@panel/app-old/shared/services/telegram-bot-editor-store/telegram-bot-store.service';

/**
 * Действия над интеграцией на странице её создания|редактирования
 */

@Component({
  selector: 'cq-integration-description-actions[currentApp][integrationExternal]',
  templateUrl: './integration-description-actions.component.html',
  styleUrls: ['./integration-description-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationDescriptionActionsComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Интеграция во внешнем формате */
  @Input()
  integrationExternal!: IntegrationExternal;

  /** Доступ до типа интеграции */
  accessToIntegrationType: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Получение переведённого названия типа интеграции */
  get translatedIntegrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(this.integrationExternal.type);
  }

  constructor(
    @Inject(CarrotquestHelper) private readonly carrotquestHelper: CarrotquestHelper,
    @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(NgbModal) private readonly modalService: NgbModal,
    private readonly modalHelperService: ModalHelperService,
    @Inject(StateService) private readonly stateService: StateService,
    @Inject(SystemToastService) private readonly systemToastService: SystemToastService,
    private readonly telegramBotStoreService: TelegramBotStoreService,
    @Inject(ToastService) private readonly toastService: ToastService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
    private readonly paywallService: PaywallService,
  ) {}

  ngOnInit(): void {
    this.accessToIntegrationType = this.integrationService.getAccessToIntegrationType(
      this.integrationExternal.type,
      this.currentApp,
    );
  }

  /** Получение тела модального окна удаления интеграции */
  getBodyForRemovedIntegrationModal(): string {
    let body = this.translocoService.translate(
      'integrationDescriptionActionsComponent.modals.confirmRemovedIntegration.body.default',
      {
        integrationTypeName: this.translatedIntegrationTypeName,
      },
    );

    switch (this.integrationExternal.type) {
      case INTEGRATION_TYPES.EMAIL_NOTIFICATION:
        body = this.translocoService.translate(
          `integrationDescriptionActionsComponent.modals.confirmRemovedIntegration.body.${this.integrationExternal.type}`,
          {
            integrationTypeName: this.translatedIntegrationTypeName,
          },
        );
    }

    return body;
  }

  /** Получение тела модального окна приостановки интеграции */
  getBodyForSuspendIntegrationModal(): string {
    let body = this.translocoService.translate(
      'integrationDescriptionActionsComponent.modals.confirmSuspendIntegration.body.default',
      {
        integrationTypeName: this.translatedIntegrationTypeName,
      },
    );

    switch (this.integrationExternal.type) {
      case INTEGRATION_TYPES.FACEBOOK:
        body = this.translocoService.translate(
          `integrationDescriptionActionsComponent.modals.confirmSuspendIntegration.body.${this.integrationExternal.type}`,
          {
            integrationTypeName: this.translatedIntegrationTypeName,
          },
        );
    }

    return body;
  }

  /** Получение списка Телеграм-ботов, который привязаны к интеграции */
  getTelegramBotWithCurrentIntegration(): TelegramBot[] {
    return this.telegramBotStoreService.telegramBotAllList$
      .getValue()
      .filter((telegramBot) => telegramBot.active && telegramBot.integration === this.integrationExternal.id);
  }

  /** Редирект на список preview-страницу интеграции */
  goToPreviewIntegrationPage() {
    this.stateService.go(
      'app.content.integrations.preview',
      {
        integrationType: this.integrationExternal.type,
      },
      {
        location: 'replace', // Чтобы кнопка 'Назад' вела в список интеграций, а не на удаленную интеграцию
      },
    );
  }

  /** Является ли интеграция активной */
  isActiveIntegration(): boolean {
    return this.integrationExternal.active;
  }

  /** Является ли интеграция приостановленной */
  isSuspendedIntegration(): boolean {
    return !this.integrationExternal.active;
  }

  /** Клик по кнопке "Активировать|Приостановить" */
  onClickToggleIntegrationStateButton() {
    if (!isNull(this.accessToIntegrationType.denialReason)) {
      this.paywallService.showPaywall(this.currentApp, this.accessToIntegrationType.denialReason);
    } else {
      this.trackOnClickToggleIntegrationStateButton();

      if (this.integrationExternal.type === INTEGRATION_TYPES.TELEGRAM) {
        const telegramBotWithCurrentIntegration = this.getTelegramBotWithCurrentIntegration();

        if (telegramBotWithCurrentIntegration.length) {
          const modal = this.openAlreadyActiveTelegramBotModal('pause', telegramBotWithCurrentIntegration);

          modal.result
            .then(() => {
              this.toggleIntegrationState();
              this.telegramBotStoreService.telegramBotAllList$.next(
                telegramBotWithCurrentIntegration.map((telegramBot) => {
                  return { ...telegramBot, active: !telegramBot.active };
                }),
              );
            })
            .catch(() => {});

          return;
        }
      }

      if (this.isActiveIntegration()) {
        this.openConfirmSuspendIntegrationModal();
      }

      if (this.isSuspendedIntegration()) {
        this.toggleIntegrationState();
      }
    }
  }

  /** Клик по кнопке удаления интеграции */
  onClickRemoveIntegrationButton(): void {
    this.trackOnClickRemoveIntegrationButton();

    if (this.integrationExternal.type === INTEGRATION_TYPES.TELEGRAM) {
      const telegramBotWithCurrentIntegration = this.getTelegramBotWithCurrentIntegration();

      if (telegramBotWithCurrentIntegration.length) {
        const modal = this.openAlreadyActiveTelegramBotModal('remove', telegramBotWithCurrentIntegration);

        modal.result
          .then(() => {
            this.removeIntegration();
          })
          .catch(() => {});

        return;
      }
    }

    this.openConfirmRemoveIntegrationModal(this.integrationExternal);
  }

  /**
   * Открытие модалки с предупреждением об активных Телеграм-ботах
   *
   * @param actionType
   * @param telegramBotWithCurrentIntegration
   */
  openAlreadyActiveTelegramBotModal(actionType: 'pause' | 'remove', telegramBotWithCurrentIntegration: TelegramBot[]) {
    return this.modalHelperService
      .provide(ALREADY_ACTIVE_TELEGRAM_BOT_MODAL_DATA_TOKEN, {
        actionType,
        appName: this.currentApp.name,
        integrationName: this.integrationExternal.name,
        botCount: telegramBotWithCurrentIntegration.length,
      })
      .open(AlreadyActiveTelegramBotModalComponent, {
        centered: true,
      });
  }

  /**
   * Открытие модального окна с подтверждением удаления интеграции
   *
   * @param integrationExternal - Интеграция во внешнем формате
   */
  openConfirmRemoveIntegrationModal(integrationExternal: IntegrationExternal): void {
    const modal = this.modalService.open(SafeRemoveComponent);
    const componentInstance = modal.componentInstance as SafeRemoveComponent;

    componentInstance.heading = this.translocoService.translate(
      'integrationDescriptionActionsComponent.modals.confirmRemovedIntegration.heading',
    );
    componentInstance.body = this.getBodyForRemovedIntegrationModal();
    componentInstance.confirmText = this.translocoService.translate(
      `integrationDescriptionActionsComponent.modals.confirmRemovedIntegration.confirmText`,
      { currentAppName: this.currentApp.name },
    );
    componentInstance.confirmedItemName = this.currentApp.name;

    modal.result.then(() => this.removeIntegration());
  }

  /** Открытие модального окна с подтверждением приостановки интеграции */
  openConfirmSuspendIntegrationModal() {
    const modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate(
          'integrationDescriptionActionsComponent.modals.confirmSuspendIntegration.heading',
        ),
        body: this.getBodyForSuspendIntegrationModal(),
        confirmButtonText: this.translocoService.translate('general.disable'),
      })
      .open(ConfirmModalComponent);

    modal.result.then(() => this.toggleIntegrationState()).catch(() => {});
  }

  /** Удаление интеграции */
  removeIntegration() {
    this.integrationService.remove(this.integrationExternal.id, this.integrationExternal.type).subscribe(() => {
      this.showIntegrationRemovedToast();

      this.goToPreviewIntegrationPage();
    });
  }

  /** Показ тоста об успешном удалении интеграции */
  showIntegrationRemovedToast(): void {
    this.toastService.success(
      this.translocoService.translate('integrationDescriptionActionsComponent.toasts.integrationRemoved', {
        integrationTypeName: this.translatedIntegrationTypeName,
      }),
    );
  }

  /** Показ тоста успешной активации интеграции */
  showIntegrationActivatedToast(): void {
    const text = this.translocoService.translate('integrationDescriptionActionsComponent.toasts.integrationActivated', {
      integrationTypeName: this.translatedIntegrationTypeName,
    });

    this.toastService.success(text);
  }

  /** Показ тоста успешной приостановке интеграции */
  showIntegrationSuspendedToast(): void {
    const text = this.translocoService.translate('integrationDescriptionActionsComponent.toasts.integrationSuspended', {
      integrationTypeName: this.translatedIntegrationTypeName,
    });

    this.toastService.success(text);
  }

  /** Показ тоста с ошибками в интеграции WhatsApp через Edna */
  showIntegrationEdnaErrorsToast(error: string) {
    if ([WHATS_APP_EDNA_API_ERRORS.VALIDATION_ERROR, WHATS_APP_EDNA_API_ERRORS.INVALID_ACCESS_TOKEN].includes(error)) {
      let text = this.translocoService.translate(
        `whatsAppEdnaIntegration.toasts.${WHATS_APP_EDNA_API_ERRORS.VALIDATION_ERROR}`,
      );

      return this.toastService.danger(text);
    }

    return this.systemToastService.requestError();
  }

  /** Изменение состояния интеграции "Активная|Приостановлена" */
  toggleIntegrationState() {
    this.integrationService
      .toggleState(this.integrationExternal)
      .pipe(
        catchError((error) => {
          throw error;
        }),
      )
      .subscribe(
        () => {
          this.integrationExternal.active = !this.integrationExternal.active;

          if (this.isActiveIntegration()) {
            this.showIntegrationActivatedToast();
          }

          if (this.isSuspendedIntegration()) {
            this.showIntegrationSuspendedToast();
          }

          this.cdr.markForCheck();
        },
        (error) => {
          if (this.integrationExternal.type === INTEGRATION_TYPES.WHATS_APP_EDNA) {
            this.showIntegrationEdnaErrorsToast(error.error);
          }
        },
      );
  }

  /** Трекинг клика по кнопке удаления интеграции */
  trackOnClickRemoveIntegrationButton(): void {
    const eventName = `Интеграции - ${this.integrationExternal.type} - клик на "Удалить"`;
    const params = {
      'Название интеграции': this.integrationExternal.name,
    };

    this.carrotquestHelper.track(eventName, params);
  }

  /** Трекинг клика по кнопке смены состояния интеграции */
  trackOnClickToggleIntegrationStateButton() {
    const eventName = `Интеграции - ${this.integrationExternal.type} - клик на "Приостановить/Включить"`;
    const params = {
      'Название интеграции': this.integrationExternal.name,
    };

    this.carrotquestHelper.track(eventName, params);
  }
}

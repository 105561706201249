import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BotSingleBlockOverlayComponent } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-single-block-overlay/bot-single-block-overlay.component';

import { BotCanvasOverlayAbleElement } from './bot-blocks-overlay.interfaces';

@Injectable()
export class BotBlocksOverlayService implements OnDestroy {
  private readonly overlayAbleElementsMap: Map<string, BotCanvasOverlayAbleElement> = new Map();

  readonly overlayComponentsMap: Map<string, BotSingleBlockOverlayComponent> = new Map();

  /**
   * Список элементов, для которых нужно показывать overlay.
   */
  private readonly elementsSubj = new BehaviorSubject<BotCanvasOverlayAbleElement[]>([]);

  readonly elements$ = this.elementsSubj.asObservable();

  ngOnDestroy() {
    this.elementsSubj.next([]);
    this.elementsSubj.complete();
  }

  public getOverlayComponent(uid: string): BotSingleBlockOverlayComponent | undefined {
    return this.overlayComponentsMap.get(uid);
  }

  addOverlayAbleElement(element: BotCanvasOverlayAbleElement) {
    this.overlayAbleElementsMap.set(element.uid, element);
    this.elementsSubj.next(Array.from(this.overlayAbleElementsMap.values()));
  }

  clearOverlays(): void {
    this.elementsSubj.next([]);
    this.overlayAbleElementsMap.clear();
    this.overlayComponentsMap.clear();
  }

  deleteOverlayAbleElement(element: BotCanvasOverlayAbleElement) {
    this.overlayAbleElementsMap.delete(element.uid);
    this.elementsSubj.next(Array.from(this.overlayAbleElementsMap.values()));
  }
}

import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.readyMessageTemplates').config(config);

  function config($stateProvider) {
    $stateProvider.state('app.content.messagesAjs.templates.ready', {
      url: '/ready/{templateId:id}',
      bindings: {
        billingInfo: 'billingInfo',
        currentApp: 'currentApp',
        directories: 'templateDirectories',
        djangoUser: 'djangoUser',
        properties: 'properties',
        template: 'template',
      },
      component: 'cqReadyMessageTemplateEditor',
      resolve: {
        messagePart: getMessagePartFromReadyTemplate,
        properties: getProperties,
        templateDirectories: getTemplateDirectoriesWithoutAllDirectory,
        template: getReadyMessageTemplate,
      },
      data: {
        roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
      },
    });

    /**
     * Получение варианта сообщения
     *
     * @param $stateParams
     * @param $transition$
     * @param readyMessageTemplateModel
     * @returns {Promise}
     */
    function getMessagePartFromReadyTemplate($stateParams, $transition$, readyMessageTemplateModel) {
      let messagePartModel = $transition$.injector().get('messagePartModel');

      return firstValueFrom(readyMessageTemplateModel.get($stateParams.templateId)).then(
        messagePartModel.parseFromTemplate.bind(messagePartModel),
      );
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение готового шаблона
     *
     * @param $stateParams
     * @param readyMessageTemplateModel
     * @returns {Promise}
     */
    function getReadyMessageTemplate($stateParams, readyMessageTemplateModel) {
      return firstValueFrom(readyMessageTemplateModel.get($stateParams.templateId));
    }

    /**
     * Получение списка директорий шаблонов без папки "Все шаблоны"
     *
     * @param $transition$
     * @param currentApp
     * @returns {Promise}
     */
    function getTemplateDirectoriesWithoutAllDirectory($transition$, currentApp) {
      let templateDirectoryModel = $transition$.injector().get('templateDirectoryModel');

      return firstValueFrom(templateDirectoryModel.getList(currentApp.id, true, false));
    }
  }
})();

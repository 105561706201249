import { Container } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

/**
 * View экшена "Задержка"
 */
export class DelayView extends AbsInteractiveBlockPartView<TRIGGER_CHAIN_BLOCK_TYPE.DELAY> {
  /** Тип */
  type: INTERACTIVE_BLOCK_PART = INTERACTIVE_BLOCK_PART.DELAY;

  hasConnection(): boolean {
    return this.stepView.data.nextStepConnected;
  }

  renderContent(): Container {
    const icon = CanvasRenderService.renderIcon(this.icon, { color: HEX_COLOR.PRIMARY_400 });

    const delayText: Container = CanvasRenderService.renderText(this.stepView.data.delayText, {
      color: HEX_COLOR.PRIMARY_400,
      wordWrap: true,
      //Ширина контента минус ширина иконки с отступом
      wordWrapWidth: 252 - (icon.width + 5),
    });

    const textContainer = ElementBuilder.wrapElementsInRectangle([icon, delayText], {
      placement: {
        marginBetweenCols: 5,
      },
    });

    const mainContainer = ElementBuilder.wrapElementsInRectangle([textContainer], {
      background: {
        sideTextureName: 'background-primary950-272-side',
        innerTextureName: 'background-primary950-272-inner',
      },
      placement: {
        alignItems: 'center',
        justifyContent: 'start',
        padding: {
          x: 10,
          y: 10,
        },
      },
    });

    this.addAndPositionConnectionPoint(mainContainer);

    return ElementBuilder.wrapElementsInRectangle([mainContainer], {
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 15,
      },
    });
  }

  get icon() {
    switch (this.stepView.data.delayType) {
      case 'beforePropDate':
        return UNICODE_ICON['cqi-user-o'];
      case 'date':
        return UNICODE_ICON['cqi-calendar-o'];
      case 'seconds':
        return UNICODE_ICON['cqi-clock'];
      default:
        return '';
    }
  }
}

import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[cqHighlight]',
})
export class HighlightDirective implements OnChanges, AfterViewInit {
  /**
   * Текст для выделения
   */
  @Input('cqHighlight')
  searchTerm: string = '';

  /**
   * Флаг i регулярного выражения для поиска
   */
  @Input()
  caseSensitive = false;

  /**
   * Флаг g регулярного выражения для поиска
   */
  @Input()
  global = false;

  /**
   * Классы для выделения
   */
  @Input()
  customClasses = '';

  /**
   * Флаги для регулярного выражения
   */
  private get flags(): string {
    let flags = '';

    if (!this.caseSensitive) {
      flags += 'i';
    }

    if (this.global) {
      flags += 'g';
    }

    return flags;
  }

  constructor(private readonly elementRef: ElementRef, private readonly sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this.highlight();
  }

  ngAfterViewInit(): void {
    this.highlight();
  }

  /**
   * Выделить в тексте searchTerm
   * @private
   */
  private highlight(): void {
    if (!this.elementRef?.nativeElement?.innerHTML) {
      return;
    }

    let text = this.elementRef.nativeElement.textContent;

    if (this.searchTerm !== '') {
      const regex = new RegExp(this.searchTerm, this.flags);
      let newText = text.replace(regex, (match: string) => {
        return `<mark class="${this.customClasses}">${match}</mark>`;
      });

      text = this.sanitizer.sanitize(SecurityContext.HTML, newText);
    }

    this.elementRef.nativeElement.innerHTML = text;
  }
}

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import { Subject } from 'rxjs';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { PricingStore } from '@panel/app/pages/subscription/pricing/pricing.store';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { PLANS_PERIODS } from '@panel/app/services/billing-plan/billing-plan.constants';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';

/**
 * Компонент для работы с кратким описанием тарифных планов
 */

@Component({
  selector: 'cq-pricing-plan-description',
  templateUrl: './pricing-plan-description.component.html',
  styleUrls: ['./pricing-plan-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingPlanDescriptionComponent {
  @Input()
  currentApp!: App;

  /** Показывается ли информация о доступности фич в зависимости от тарифных планов */
  @Input()
  isShowFeaturesAvailabilityByRatePlans: boolean = false;

  /** Callback на изменение состояния isShowFeaturesAvailabilityByRatePlans */
  @Output()
  isShowFeaturesAvailabilityByRatePlansChange: Subject<boolean> = new Subject<boolean>();

  /** Показывать ли элемент показа/скрытия полного сравнения тарифных планов */
  isShowFeaturesAvailabilityByRatePlansToggleControl: boolean = true;

  /** Название проекта */
  projectName = environment.projectName;

  PLANS_PERIODS = PLANS_PERIODS;

  constructor(
    public readonly pricingStore: PricingStore,
    private readonly translocoService: TranslocoService,
    private readonly billingPlanService: BillingPlanService,
    private readonly billingInfoModel: BillingInfoModel,
  ) {}

  @tuiPure
  getDiscountValue(period: PLANS_PERIODS): number {
    return this.billingPlanService.getCurrentDiscountsByPeriod(this.currentApp, this.billingInfoModel.billingInfo)[
      period
    ];
  }

  /** Получение иконки для элемента показа/скрытия полного сравнения тарифных планов */
  public getToggleIcon(): string {
    return this.isShowFeaturesAvailabilityByRatePlans ? 'cqi-arrow-up' : 'cqi-arrow-down';
  }

  /** Получение текста для элемента показа/скрытия полного сравнения тарифных планов */
  public getToggleText(): string {
    return this.isShowFeaturesAvailabilityByRatePlans
      ? this.translocoService.translate('pricingPlanDescriptionComponent.toggleDifferenceTariffPlans.hide')
      : this.translocoService.translate('pricingPlanDescriptionComponent.toggleDifferenceTariffPlans.show');
  }

  /** Обработчик клика по элементу показа/скрытия полного сравнения тарифных планов */
  public onClickToggleDifferenceTariffPlans(): void {
    this.isShowFeaturesAvailabilityByRatePlans = !this.isShowFeaturesAvailabilityByRatePlans;
    this.isShowFeaturesAvailabilityByRatePlansChange.next(this.isShowFeaturesAvailabilityByRatePlans);
  }
}

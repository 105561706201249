import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { ChatWidgetButtonTextComponent } from '@panel/app/pages/widget-bot/editor/components/button-text/chat-widget-button-text.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [ChatWidgetButtonTextComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, ReactiveFormsModule],
  exports: [ChatWidgetButtonTextComponent],
})
export class ChatWidgetButtonTextModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { ServiceInstallationIntegrationsModule } from '@panel/app/partials/service-installation/service-installation-integrations/service-installation-integrations.module';

import { StarterGuideSocialIntegrationsComponent } from './starter-guide-social-integrations.component';

@NgModule({
  declarations: [StarterGuideSocialIntegrationsComponent],
  exports: [StarterGuideSocialIntegrationsComponent],
  imports: [CommonModule, TranslocoModule, NgbAlertModule, ServiceInstallationIntegrationsModule],
  providers: [CarrotquestHelperProvider],
})
export class StarterGuideSocialIntegrationsModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cq-message-editor-collapse',
  templateUrl: './message-editor-collapse.component.html',
  styleUrls: ['./message-editor-collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorCollapseComponent {
  @Input()
  collapse: boolean = true;

  @Output()
  collapseChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  changeCollapse() {
    this.collapse = !this.collapse;
    this.collapseChange.emit(this.collapse);
  }
}

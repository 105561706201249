import { INSTALLATION_MODAL_SKIPPED } from '../../../../app/http/app/app.constants';
import { PLAN_CAPABILITIES_PRICING_PLACE } from '../../../../app/services/plan-capability-helper/plan-capability-helper.constants';

(function () {
  'use strict';

  angular
    .module('myApp.planCapability')
    .controller('CqPlanCapabilityPaywallController', CqPlanCapabilityPaywallController);

  function CqPlanCapabilityPaywallController($state, $timeout, carrotquestHelper, planVersionService) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.onSuccessPaymentCard = onSuccessPaymentCard;
      vm.PLAN_CAPABILITIES_PRICING_PLACE = PLAN_CAPABILITIES_PRICING_PLACE;
      vm.planVersionService = planVersionService;
    }

    /** Обработчик успешной оплаты через карту */
    function onSuccessPaymentCard() {
      skipInstallationModal();

      redirectToSubscriptionPage();
    }

    /** Редирект на страницу "Оплата" */
    function redirectToSubscriptionPage() {
      $state.go('app.content.subscription', {}, { reload: true });
    }

    /**
     * Пропустить модальное окно с обязательной установкой кода
     *
     * NOTE:
     *  При пропуске установки кода, разделы админки раз блокируются, но механики, которые пушат на установку кода
     *  остаются работать
     */
    function skipInstallationModal() {
      $timeout(() => {
        localStorage.setItem(INSTALLATION_MODAL_SKIPPED, 'true');

        carrotquestHelper.track('starterguide_skipped');
      });
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';

import { WriteToIntegrationsModalComponent } from './write-to-integrations-modal.component';

@NgModule({
  declarations: [WriteToIntegrationsModalComponent],
  exports: [WriteToIntegrationsModalComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [CarrotquestHelperProvider],
})
export class WriteToIntegrationsModalModule {}

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
/* eslint-disable unused-imports/no-unused-imports */
// TODO: Этот импорт тут только для того, что куда-то в ТС включить файл, чтоб билдер видел его
import { MERGE_OPERATIONS_KEYS } from 'app/http/user/user.constants';
/* eslint-enable unused-imports/no-unused-imports */
import { UtilsService } from 'app-old/shared/services/utils/utils.service';
import moment from 'moment';
import { defaultIfEmpty, forkJoin, mergeMap, Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { environment } from '@environment';
import { NamePlaceholder } from '@http/chat-bot-statistics/chat-bot-statistics.types';
import { EmailStatusModel } from '@http/email-status/email-status.model';
import { EventModel } from '@http/event/event.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { ELASTICSEARCH_PROPERTY_OPERATIONS } from '@http/property/property.constants';
import { Properties, PropertyModel } from '@http/property/property.model';
import { APIPaginatableResponse, APIResponse, PaginationParamsRequest } from '@http/types';
import {
  ConversationUser,
  GetListRequestParams,
  GetListResponse,
  GetUserListParams,
  ImportUserParams,
  OverrideParams,
  ParamsEventsRequest,
  ParamsGetList,
  RemoveUsersParams,
  SendConfirmEmailParams,
  User,
  UserConsent,
  UserTag,
} from '@http/user/types/user.type';
import { UserNoteModel } from '@http/user-note/user-note.model';
import { CaseStyleHelper } from '@panel/app/services';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';
import { FiltersAJS } from '@panel/app/services/filter-ajs/filter-ajs.types';
import {
  EXTENDED_ERROR,
  EXTENDED_RESPONSE,
  IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS,
  SKIP_INTERCEPTOR,
} from '@panel/app/shared/constants/http.constants';
import { LS_PINNED_PROPS } from '@panel/app/shared/constants/localstorage.keys';
import { interpolate } from '@panel/app/shared/functions/interpolate.function';

type ColorCodes = {
  anonymous: string;
  blue: string;
  brown: string;
  burgundy: string;
  crimson: string;
  cyan: string;
  green: string;
  grey: string;
  lilac: string;
  lime: string;
  orange: string;
  pink: string;
  purple: string;
  red: string;
  scarlet: string;
  yellow: string;
};

/**
 * Сервис для работы с пользователями
 */
@Injectable({ providedIn: 'root' })
export class UserModel {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly sanitizer: DomSanitizer,
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly http: HttpClient,
    private readonly emailStatusModel: EmailStatusModel,
    private readonly eventModel: EventModel,
    private readonly filterAjsModel: FilterAjsModel,
    private readonly propertyModel: PropertyModel,
    private readonly userNoteModel: UserNoteModel,
    private readonly utilsService: UtilsService,
  ) {}

  /**
   * Коды цветов для цветов из $name_placeholder
   *
   * @type {Object}
   */
  COLOR_CODES: ColorCodes = {
    anonymous: '#a5c0c7',
    blue: '#536dfe',
    brown: '#8d6e63',
    burgundy: '#b71c1c',
    crimson: '#f44336',
    cyan: '#4fc3f7',
    green: '#9ccc65',
    grey: '#78909c',
    lilac: '#5e35b1',
    lime: '#dce775',
    orange: '#ffab40',
    pink: '#ff4081',
    purple: '#ab47bc',
    red: '#d50000',
    scarlet: '#ff3d00',
    yellow: '#ffea00',
  };

  /** Свойства закрепленные по умолчанию */
  DEFAULT_PINNED_PROPS = ['$email', '$phone'];

  // FIXME когда-нибудь вынести object-fit в отдельный класс
  IMAGE_TEMPLATE = '<img class="full-width full-height" style="object-fit: cover;" src="{{src}}">';

  // имя svg-файла аватарки с возможностью вставить внутрь букву
  LETTER_SVG_NAME = 'letter';

  // Используется для старых анонимусов, без $name_placeholder
  OLD_APPEARANCE = 'anonymous';
  OLD_COLOR = 'anonymous';
  OLD_SUBJECT = 'anonymous';

  /**
   * Ключи, значения которых не нужно преобразовывать в camelCase. Если преобразовать их значения в camelCase,
   * то в UI вместо свойства «Пользовательское свойство номер 1» будем показывать «пользовательскоеСвойствоНомер1».
   * props_custom и props_events не трогаю, потому что там системные свойства. Кажется, что их оставить так, как есть.
   */
  EXCEPT_PARSING_FIELDS = ['props', 'props_custom', 'props_events'];

  /**
   * Добавление события пользователю
   *
   * @param userId ID пользователя
   * @param eventName Название события
   * @param eventProps Свойства события
   */
  addEvent(userId: string, eventName: string, eventProps: Object) {
    let body = {
      event: eventName,
      params: JSON.stringify(eventProps),
    };

    return this.http.post('/users/' + userId + '/events', body);
  }

  /**
   * Бан и разбан пользователя
   *
   * @param userId ID пользователя
   * @param flag Флаг бана пользователя. Если true - пользователь банится, false - разбанивается
   */
  ban(userId: string, flag: boolean) {
    let body = {
      ignoreLoadingBar: true,
      value: flag,
    };

    return this.http.post<unknown>('/users/' + userId + '/ban', body);
  }

  /**
   * Добавление пользователя в чёрный список
   * Фактически на бэкэнде будет взят email пользователя с userId и сменён его emailStatus статус на black_list
   * NOTE: выполнять эту функцию можно только для пользователей с emailStatus not_confirmed или confirmed
   *
   * @param userId ID пользователя
   */
  blacklist(userId: string) {
    let body = {
      unblock: false, // обратное действие (убрать из чёрного списка) TODO: сделать функцию для убирания из чёрного списка, надо всего лишь выставить флаг в true. Эта функция будет работать только при emailStatus black_list
      send_confirm_email: false, // посылать или нет письмо подтверждения (в случае unblock: true)
    };

    return this.http.post('/users/' + userId + '/blacklist', body);
  }

  /**
   * Экспорт пользователей
   *
   * @param appId ID приложения
   * @param props свойства для запроса
   * @param idsOrFilters массив ID пользователей или фильтр пользователей
   */
  exportUsers(appId: string, props: [], idsOrFilters: string[] | FiltersAJS) {
    type Params = {
      app: string;
      props: Properties | [];
      manual_ids: string[];
      filters: string;
    };
    let body: Partial<Params> = {
      app: appId,
    };

    body.props = props !== undefined ? props : [];
    if (Array.isArray(idsOrFilters)) {
      body.manual_ids = idsOrFilters;
    } else {
      body.filters = this.filterAjsModel.parseToServerFormat(idsOrFilters);
    }

    return this.http.post('/users/export', body, { context: new HttpContext().set(EXTENDED_ERROR, true) });
  }

  private readonly avatarCache: Map<string, HttpResponse<string>> = new Map();

  private readonly letterSvgNameReq$ = this.http
    .get('assets/img/default/users/avatars/name.svg'.replace('name', this.LETTER_SVG_NAME), {
      context: new HttpContext().set(SKIP_INTERCEPTOR, true),
      observe: 'response',
      responseType: 'text',
    })
    .pipe(shareReplay(1));

  /**
   * Функция генерации аватара для пользователя
   *
   * @param user Пользователь
   */
  private generateAvatar<UserType extends User | ConversationUser>(user: UserType) {
    let namePlaceholder = this.parseNamePlaceholder(user.props.$name_placeholder as string);

    if (!user.props.$avatar) {
      if (user.props.$name || user.props.$email) {
        if (this.avatarCache.has(this.LETTER_SVG_NAME)) {
          return of(this.avatarCache.get(this.LETTER_SVG_NAME)!).pipe(
            map((res) => {
              return this.setAvatarAsSvg(res, namePlaceholder, user);
            }),
          );
        }

        return this.letterSvgNameReq$.pipe(
          tap((res) => this.avatarCache.set(this.LETTER_SVG_NAME, res)),
          map((res) => {
            return this.setAvatarAsSvg(res, namePlaceholder, user);
          }),
        );
      }

      if (this.avatarCache.has(namePlaceholder.subject)) {
        return of(this.avatarCache.get(namePlaceholder.subject)!).pipe(
          map((res) => {
            return this.setAvatarAsSvg(res, namePlaceholder, user);
          }),
        );
      }

      return this.http
        .get('assets/img/default/users/avatars/name.svg'.replace('name', namePlaceholder.subject), {
          context: new HttpContext().set(SKIP_INTERCEPTOR, true),
          observe: 'response',
          responseType: 'text',
        })
        .pipe(
          tap((res) => this.avatarCache.set(namePlaceholder.subject, res)),
          map((res) => {
            return this.setAvatarAsSvg(res, namePlaceholder, user);
          }),
        );
    }

    return of<string>(
      environment.userFilesUrl + '/avatars-users/name'.replace('name', user.props.$avatar as string),
    ).pipe(
      map((res) => {
        return this.setAvatarAsImg(res);
      }),
    );
  }

  private setAvatarAsSvg<UserType extends User | ConversationUser>(
    response: HttpResponse<string>,
    namePlaceholder: NamePlaceholder,
    user: UserType,
  ): SafeHtml {
    // NOTE: Тут не спроста проверка, что пришла svg. Возможно 2 варианта:
    // 1) Поскольку используется html5Mode, на локалке на любую кривую ссылку придёт индексная страница. Поэтому нужно проверить, что пришла действительно SVG-картинка.
    // 2) На бою запрос идёт на CDN, поэтому может вернуться 404 (т.к. эта же функция повешана на reject), и опять-таки нужно проверить, что пришла SVG
    // Если пришла не SVG - делаем запрос на стандартную аватарку
    if (!~response.headers.get('content-type')!.indexOf('image/svg+xml')) {
      if (this.avatarCache.has(this.OLD_SUBJECT)) {
        return of(this.avatarCache.get(this.OLD_SUBJECT)!).pipe(
          map((res) => this.setAvatarAsSvg(res, namePlaceholder, user)),
        );
      }

      return this.http
        .get('assets/img/default/users/avatars/OLD_SUBJECT.svg'.replace('OLD_SUBJECT', this.OLD_SUBJECT), {
          context: new HttpContext().set(SKIP_INTERCEPTOR, true),
          observe: 'response',
          responseType: 'text',
        })
        .pipe(
          tap((res) => this.avatarCache.set(this.OLD_SUBJECT, res)),
          map((res) => this.setAvatarAsSvg(res, namePlaceholder, user)),
        );
    } else {
      let svgString = response.body!;
      let colorCode = this.COLOR_CODES[namePlaceholder.color as keyof ColorCodes];

      let firstNameLetter = this.getFirstLetter(user.props.$name);
      let firstEmailLetter = this.getFirstLetter(user.props.$email);

      // Отрисовываем первую букву имени,
      // в случае если имени нет покажем первую букву email'а, иначе ничего
      let letter = firstNameLetter || firstEmailLetter;

      svgString = interpolate(svgString, {
        color: colorCode,
        letter: letter,
      });

      return this.sanitizer.bypassSecurityTrustHtml(svgString);
    }
  }

  private setAvatarAsImg(imgUrl: string) {
    let imgString = interpolate(this.IMAGE_TEMPLATE, { src: imgUrl });

    return this.sanitizer.bypassSecurityTrustHtml(imgString);
  }

  /**
   * Генерация имени для пользователя
   *
   * @param {Object} user Пользователь
   */
  private generateName<UserType extends ConversationUser | User>(user: UserType) {
    let name;

    if (!user.props.$name && !user.props.$email) {
      let namePlaceholder = this.parseNamePlaceholder(user.props.$name_placeholder as string);

      let translatedSubjectGender = this.transloco.translate(
        'models.user.name.subjects.' + namePlaceholder.subject + '.gender',
      );
      let translatedAppearance = this.transloco.translate(
        'models.user.name.appearances.' + namePlaceholder.appearance + '.' + translatedSubjectGender,
      );
      let translatedColor = this.transloco.translate(
        'models.user.name.colors.' + namePlaceholder.color + '.' + translatedSubjectGender,
      );
      let translatedSubject = this.transloco.translate(
        'models.user.name.subjects.' + namePlaceholder.subject + '.value',
      );

      if (user.props.$name_placeholder) {
        // если у пользователя нет имени и при этом не пришёл $name_placeholder - значит чувак создан давно, до введения крутых имён, он является Анонимусом, и ему надо приколбасить в конец имени userId
        name = this.caseStyleHelper.toSentenceCase(
          (translatedAppearance + ' ' + translatedColor + ' ' + translatedSubject).trim(),
        );
      } else if (!user.removed) {
        // если у пользователя нет имени и при этом не пришёл $name_placeholder - значит чувак создан давно, до введения крутых имён, он является Анонимусом, и ему надо добавить в конец имени userId
        name =
          this.caseStyleHelper.toSentenceCase(
            (translatedAppearance + ' ' + translatedColor + ' ' + translatedSubject).trim(),
          ) +
          ' #' +
          user.id;
      } else {
        // если у пользователя есть поле removed - значит, он удалён, ему надо выдать соответствующее имя
        name = this.transloco.translate('models.user.removed');
      }
    } else if (user.props.$name) {
      name = user.props.$name as string;
    } else {
      // Если у пользователя нет имени, но есть email - покажем его
      name = user.props.$email as string;
    }

    return name;
  }

  /**
   * Получение пользователя
   *
   * @param userId ID пользователя
   * @param overrideParams Объект с параметрами, перезаписывающими стандартные значения
   * @param ignoreLoadingBar
   */
  get(userId: string, overrideParams: OverrideParams | null = null, ignoreLoadingBar = true) {
    let params: OverrideParams = {
      by_user_id: false, // Выполнить поиск пользователя не по ID не из нашей системы
      convert_props_types: false, // конвертировать значения свойств в типы, если false - возвращать все значения строкой
      email_status: true, // получить статус подписки
      events: false, // получить события, совершённые пользователем
      notes: true, // получить заметки
      presence_details: true, // получить подробности статуса
      props: true, // получить значения стандартных свойств
      props_custom: true, // получить значения пользовательских свойств
      props_events: true, // получить агрегационные свойства пользователя о совершённых событиях
      segments: true, // получить сегменты, в которые входит пользователь
      tags: true, // получить теги
      timezone_offset: true, // получить смещение в минутах таймозоны пользователя
    };

    Object.assign(params, overrideParams);

    return this.http
      .get<User>('/users/' + userId, {
        params,
        context: new HttpContext()
          .set(EXTENDED_ERROR, true)
          .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar)
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, this.EXCEPT_PARSING_FIELDS),
      })
      .pipe(
        mergeMap((user) => {
          return this.parseUser(user, params.convert_props_types);
        }),
      );
  }

  /**
   * Установка закрепленных свойств и отправка их на бек
   *
   * @param pinnedProps - Массив св-в
   * @param currentAppId - Текущий appId
   * @param djangoUserId - Id джанго юзера
   */
  setPinnedProps(pinnedProps: string[], currentAppId: string, djangoUserId: string) {
    localStorage.setItem(LS_PINNED_PROPS, JSON.stringify(pinnedProps));

    const { ...pinnedPropsObject } = pinnedProps;

    let body = {
      app: currentAppId,
      pinned_props: pinnedPropsObject,
    };

    return this.http.patch('/django_users/' + djangoUserId + '/settings', body);
  }

  /**
   * Функция возвращает первую букву строки или пустую строку
   */
  getFirstLetter(str: string | undefined): string {
    if (!str) {
      return '';
    }

    return str.toString().slice(0, 1).toUpperCase();
  }

  /**
   * Получить список онлайн пользователей
   *
   * @param appId ID приложения
   * @param paginatorParams Пагинация
   * @param ignoreLoadingBar Игнорировать или нет loading bar
   */
  getActiveUsers(appId: string, paginatorParams: PaginationParamsRequest | null, ignoreLoadingBar: boolean = true) {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    let params: any = {
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition?.join() || undefined,
      paginate_page_order: paginatePageOrder,
    };

    return this.http
      .get<APIPaginatableResponse<[]>>('/apps/' + appId + '/activeusers', {
        params,
        context: new HttpContext()
          .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar)
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        mergeMap((res) => {
          this.caseStyleHelper.keysToCamelCase(res, this.EXCEPT_PARSING_FIELDS);

          let users = res.data;

          const parseObservables: Observable<unknown>[] = [];

          for (let i = 0; i < users.length; i++) {
            let user = users[i];

            parseObservables.push(this.parseUser(user, true));
          }

          return forkJoin(parseObservables).pipe(
            defaultIfEmpty([]),
            map(() => {
              return {
                users: users,
                paginatorParams: {
                  paginateDirection,
                  paginateCount,
                  paginateIncluding,
                  paginatePageOrder,
                  paginatePosition: res.meta.nextBeforePosition,
                },
              };
            }),
          );
        }),
      );
  }

  /**
   * Получение количества онлайн пользователей
   *
   * @param appId ID приложения
   */
  getActiveUsersCount(appId: string) {
    return this.http
      .get<APIResponse<{ activeUsersCount: number }>>(`/apps/${appId}/activeusers_count`, {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response) => {
          return this.getActiveUsersCountSuccess(response);
        }),
      );
  }

  getActiveUsersCountSuccess(response: APIResponse<{ activeUsersCount: number }>) {
    this.caseStyleHelper.keysToCamelCase(response);

    return response.data.activeUsersCount;
  }

  /**
   * Получение списка событий пользователя
   *
   * @param userId ID пользователя
   * @param eventName Название события, которое нужно получить
   * @param propsAsString
   * @param paginatorParams
   * @param ignoreLoadingBar Игнорировать или нет loading bar
   */
  getEvents(
    userId: Number,
    eventName: string,
    propsAsString: boolean,
    paginatorParams: PaginationParamsRequest | null,
    ignoreLoadingBar: boolean = true,
  ) {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    let params: ParamsEventsRequest = {
      props_as_string: propsAsString,
      paginate_direction: paginateDirection ?? undefined,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_page_order: paginatePageOrder,
    };

    if (paginatePosition && paginatePosition.length > 0) {
      params.paginate_position = paginatePosition.join();
    }

    if (eventName) {
      params['filter_name'] = eventName;
    }

    return this.http
      .get<APIPaginatableResponse<[]>>('/users/' + userId + '/events', {
        params,
        context: new HttpContext()
          .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar)
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((res) => {
          this.caseStyleHelper.keysToCamelCase(res, this.EXCEPT_PARSING_FIELDS);
          let events = res.data;

          for (let i = 0; i < events.length; i++) {
            this.eventModel.parse(events[i]);
          }

          return {
            events: events,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: res.meta.nextBeforePosition,
            },
          };
        }),
      );
  }

  /**
   * Получение списка пользователей
   *
   * @param appId ID приложения
   * @param props свойства для запроса
   * @returns {Promise}
   */
  getList(appId: string, props: GetListRequestParams): Observable<GetListResponse> {
    let body: Partial<ParamsGetList> = {};

    body.filters =
      props.filters !== undefined ? this.filterAjsModel.parseToServerFormat(props.filters as FiltersAJS) : '{}';
    body.sort_prop = props.sortProp !== undefined ? props.sortProp : '';
    body.sort_order = props.sortOrder !== undefined ? props.sortOrder : '';
    body.offset = props.offset !== undefined ? props.offset : 0;
    body.limit = props.limit !== undefined ? props.limit : 20;
    body.convert_props_types = props.convertPropsTypes !== undefined ? props.convertPropsTypes : false;

    return this.http
      .post<APIResponse<{ total: number; users: Array<User> }>>('/apps/' + appId + '/users', body, {
        context: new HttpContext()
          .set(EXTENDED_RESPONSE, true)
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(NGX_LOADING_BAR_IGNORED, props.ignoreLoadingBar !== undefined ? props.ignoreLoadingBar : true),
      })
      .pipe(
        mergeMap((res) => {
          return this.getListSuccess(res, body);
        }),
      );
  }

  /**
   * Поулчение пользователей с диалогами
   * @param appId - ID аппа
   * @param props - Свойства для запроса
   */
  getListWithConversations(appId: string, props: GetUserListParams): Observable<GetListResponse> {
    let body: Partial<ParamsGetList> = {};

    body.filters = props.query ? this.filterAjsModel.parseToServerFormat(this.getUserListFilter(props.query)) : '{}';
    body.sort_order = props.sortOrder !== undefined ? props.sortOrder : 'desc';
    body.offset = props.offset !== undefined ? props.offset : 0;
    body.limit = props.limit !== undefined ? props.limit : 20;
    body.convert_props_types = props.convertPropsTypes !== undefined ? props.convertPropsTypes : false;

    body.ignoreLoadingBar = props.ignoreLoadingBar !== undefined ? props.ignoreLoadingBar : true;

    return this.http
      .post<APIResponse<{ total: number; users: Array<User> }>>('/apps/' + appId + '/conversations_user_search', body, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
      })
      .pipe(
        mergeMap((res) => {
          return this.getListSuccess(res, body);
        }),
      );
  }

  /**
   * Получение согласий пользователя
   * @param userId
   */
  getUserConsentList(userId: string | number): Observable<UserConsent[]> {
    return this.http.get<UserConsent[]>(`/users/${userId}/user_consent`);
  }

  /**
   * Получение фильтра
   * @param query - Строка с запросом поиска
   */
  private getUserListFilter(query: string): ParamsGetList['filters'] {
    const filters = this.filterAjsModel.getDefaultOr();

    const propsList = ['$name', '$phone', '$email'];

    propsList.forEach((propName) => {
      const searchProp = this.filterAjsModel.getDefaultFilterProp(ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS);
      searchProp.value.value = query;
      //NOTE тут создается минимальная версия свойства пользователя
      // небольшой компромисс между получением свойства из списка свойств
      // и формированием фильтра для поиска лидов
      searchProp.userProp = {
        name: propName,
      };

      filters.filters.props.push(searchProp);
    });

    return filters;
  }

  private getListSuccess(
    usersData: APIResponse<{ total: number; users: Array<User> }>,
    params: Partial<ParamsGetList>,
  ): Observable<GetListResponse> {
    this.caseStyleHelper.keysToCamelCase(usersData, this.EXCEPT_PARSING_FIELDS);

    let parseUserObservables = [];
    let users = usersData.data.users;

    for (let i = 0; i < users.length; i++) {
      let user = users[i];

      parseUserObservables.push(this.parseUser(user, params.convert_props_types));
    }

    return forkJoin(parseUserObservables).pipe(
      defaultIfEmpty([]),
      map((users: User[]) => {
        return {
          total: usersData.data.total,
          users: users,
        };
      }),
    );
  }

  /**
   * Получить правила склейки свойств
   */
  getMergeRules() {
    return this.http.get<unknown>('/users/merge_rules/', {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }

  /**
   * Получение закрепленных свойств
   *
   * @param appId - Id аппа
   * @param djangoUserId - Id джанго юзера
   * @param fromServer - Через запрос или из localStorage
   */
  getPinnedProps(appId: string = '', djangoUserId: string = '', fromServer: boolean = false) {
    const getInitialPinnedProps = (props?: string[]) => {
      if (props && props.length > 0) {
        localStorage.setItem(LS_PINNED_PROPS, JSON.stringify(props));
        return props;
      }

      let resultFromLS = localStorage.getItem(LS_PINNED_PROPS);
      if (resultFromLS) {
        return JSON.parse(resultFromLS);
      }

      return this.DEFAULT_PINNED_PROPS;
    };

    // Получаем закрепленные св-ва с бека
    if (fromServer) {
      let params = {
        app: appId,
        include_muted_channels: false,
        include_temp_data: false,
      };

      return this.http
        .get<APIResponse<{ [key: string]: string }>>('/django_users/' + djangoUserId + '/settings', {
          params,
          context: new HttpContext().set(EXTENDED_RESPONSE, true).set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
        })
        .pipe(
          map((res) => {
            let pinnedProps: string[] = Object.values(res.data.pinned_props);
            return pinnedProps;
          }),
          map(getInitialPinnedProps.bind(this)),
        );
    } else {
      return of(getInitialPinnedProps());
    }
  }

  /**
   * Получение сигнатуры для интеграции с Calendly. Это нужно для того, чтобы один и тот же аккаунт Calendly могли подключить два оператора
   *
   * @param userId ID пользователя
   * @param djangoUserIntegrationId ID интеграции django-пользователя с Calendly
   * @param conversationId ID диалога
   */
  getSignatureForCalendly(userId: string, djangoUserIntegrationId: string, conversationId: string) {
    let params = {
      external_service: INTEGRATION_TYPES.CALENDLY,
      additional_signed_values: JSON.stringify([djangoUserIntegrationId, conversationId]),
    };

    return this.http.get('/users/' + userId + '/signature', { params });
  }

  /**
   * Импорт пользователей
   *
   * @param {String} appId - ID приложения
   * @param {String} importType - Тип импорта
   * @param {File} file - Загружаемый файл
   * @param {String} delimiter - Разделитель
   * @param {Array} tags - Массив тегов пользователей
   * @param integrationId - ID интеграции
   */
  importUsers(
    appId: string,
    importType: '$social_telegram_id',
    file: File,
    delimiter: string,
    tags: Array<UserTag>,
    integrationId: string,
  ): Observable<unknown>;
  importUsers(
    appId: string,
    importType: '$email' | '$user_id',
    file: File,
    delimiter: string,
    tags: Array<UserTag>,
  ): Observable<unknown>;
  importUsers(
    appId: string,
    importType: '$email' | '$user_id' | '$social_telegram_id',
    file: File,
    delimiter: string,
    tags: Array<UserTag>,
    integrationId?: string,
  ) {
    let body: ImportUserParams = {
      merge_field: importType,
      file: file,
      delimiter: delimiter, // Разделитель
    };
    /* Добавляем теги если они есть */
    if (tags && tags.length > 0) {
      body.tags = tags;
    }
    if (importType === '$social_telegram_id') {
      body.integration = integrationId;
    }

    return this.http.post('/apps/' + appId + '/import_users', body);
  }

  /**
   * Ручная склейка пользователей
   *
   * @param newUserId Пользователь, в которого произойдёт склейка
   * @param removingUserId Удаляемый пользователь
   */
  merge(newUserId: string, removingUserId: string) {
    return this.http.post<unknown>('/users/' + removingUserId + '/mergeto/' + newUserId, {});
  }

  /**
   * Парсинг namePlaceholder, в котором с сервера приходит информация о сущности, качестве и цвете пользователя
   * Если каких-то значений в namePlaceholder не хватает - подставляются значения, которые используются старыми анонимами
   *
   * @param namePlaceholder Информация о сущности, качестве и цвете пользователя
   * @return {{appearance: *, color: *, subject: *}}
   */
  parseNamePlaceholder(namePlaceholder: string) {
    let namePlaceholderArray = namePlaceholder ? namePlaceholder.split('-') : [];

    return {
      appearance: namePlaceholderArray[0] || this.OLD_APPEARANCE,
      color: namePlaceholderArray[1] || this.OLD_COLOR,
      subject: namePlaceholderArray[2] || this.OLD_SUBJECT,
    };
  }

  /**
   * Парсинг пользователя
   *
   * @param user Пользователь
   * @param propsTypesConverted В каком формате приходят значения свойств пользователя: false - строкой, true - распарсеными
   */
  parseUser<UserType extends User | ConversationUser>(
    user: UserType,
    propsTypesConverted?: boolean,
  ): Observable<UserType> {
    //@ts-ignore
    if (user.notes) {
      //@ts-ignore
      for (let i = 0; i < user.notes.length; i++) {
        //@ts-ignore
        user.notes[i] = this.userNoteModel.parse(user.notes[i]);
      }
    }

    //@ts-ignore
    if (user.emailStatus) {
      //@ts-ignore
      this.emailStatusModel.parse(user.emailStatus);
    }

    //@ts-ignore
    if (user.presence !== 'offline' && user.presenceDetails) {
      //@ts-ignore
      user.presenceDetails.sessionStarted = moment(user.presenceDetails.sessionStarted * 1000);
    }

    //@ts-ignore
    if (user.propsCustom) {
      //@ts-ignore
      this.propertyModel.parseUserProperties(user.propsCustom, propsTypesConverted);
    }

    if (user.props) {
      this.propertyModel.parseUserProperties(user.props, propsTypesConverted);
    }

    if (user.removed) {
      user.removed = moment(user.removed, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    }

    return forkJoin([of(this.generateName(user)), this.generateAvatar(user)]).pipe(
      map((result) => {
        user.name = result[0];
        user.avatar = result[1];

        return user;
      }),
    );
  }

  /**
   * Удаление пользователей
   *
   * @param appId ID приложения
   * @param users ID пользователя, или пользователь, или массив ID пользователей, или массив пользователей, или фильтр
   */
  remove(appId: string, users: any) {
    let body: Partial<RemoveUsersParams> = {
      app: appId,
    };

    if (this.utilsService.isStringNumber(users)) {
      // users - ID пользователя
      body.users = [users];
    } else if (typeof users === 'object' && users !== null && users.constructor == Object) {
      if (users.filters) {
        // users - фильтр
        body.filters = this.filterAjsModel.parseToServerFormat(users);
      } else {
        // users - один пользователь
        body.users = [users.id];
      }
    } else if (Array.isArray(users)) {
      if (this.utilsService.isStringNumber(users[0])) {
        // users - массив ID пользователей
        body.users = users;
      } else if (typeof users[0] === 'object' && users[0] !== null) {
        // users - массив пользователей
        body.users = users.map((user) => user.id);
      }
    }

    return this.http.delete('/users/hide/', { body });
  }

  /**
   * Полное удаление пользователя
   *
   * @param userId ID пользователя
   */
  removePermanently(userId: string) {
    return this.http.delete('/users/' + userId);
  }

  /**
   * Посылка письма подтверждения электронной почты
   * В добавление к письму подтверждения фактически на бэкэнде будет взят email пользователей и сменён его emailStatus статус на not_confirmed
   * NOTE: выполнять эту функцию можно только для пользователей с emailStatus not_confirmed или confirmed
   *
   * @param appId ID приложения
   * @param users ID пользователя, или пользователь, или массив ID пользователей, или массив пользователей, или фильтр
   */
  sendConfirmEmail(appId: string, users: any) {
    let body: Partial<SendConfirmEmailParams> = {};

    if (this.utilsService.isStringNumber(users as string)) {
      // users - ID пользователя
      body.manual_ids = [users];
    } else if (typeof users === 'object' && users !== null && users.constructor == Object) {
      if (users.filters) {
        // users - фильтр
        body.filters = this.filterAjsModel.parseToServerFormat(users);
      } else {
        // users - один пользователь
        body.manual_ids = [users.id];
      }
    } else if (Array.isArray(users)) {
      if (this.utilsService.isStringNumber(users[0])) {
        // users - массив ID пользователей
        body.manual_ids = users;
      } else if (typeof users[0] === 'object' && users[0] !== null) {
        // users - массив пользователей
        body.manual_ids = users.map((user) => user.id);
      }
    }

    return this.http.post('/apps/' + appId + '/recheck_confirm', body);
  }

  /**
   * Отправка сообщения пользователю
   * NOTE Испольлзуется только в карточке пользователя, когда нет ни одного диалога. Выглядит как костыль
   *
   * @param userId ID пользователя
   * @param message Сообщение
   */
  sendMessage(userId: string, message: string) {
    let body = {
      body: message,
    };

    return this.http.post('/users/' + userId + '/sendmessage', body);
  }

  /**
   * Установка свойств пользователю
   *
   * @param userId ID пользователя
   * @param appId ID приложения
   * @param props Массив записываемых свойств
   * @param ignoreLoadingBar игнор или нет loading bar
   */
  setProperties(userId: string, appId: string, props: Properties, ignoreLoadingBar: boolean = false) {
    const body = {
      app: appId,
      operations: JSON.stringify(props),
    };

    return this.http.post('/users/' + userId + '/setproperties', body, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }

  /**
   * Отписка пользователя
   * Фактически на бэкэнде будет взят email пользователя с userId и сменён его emailStatus статус на unsubscribe
   * NOTE: выполнять эту функцию можно только для пользователей с emailStatus not_confirmed или confirmed
   *
   * @param userId ID пользователя
   */
  unsubscribe(userId: string) {
    return this.http.post('/users/' + userId + '/unsubscribeemail', {});
  }
}

import { BaseTexture, Renderer, RenderTexture } from '@pixi/core';
import { Container } from 'pixi.js';

import { getIconBackgroundCircleBaseTexture } from '@panel/app/pages/chat-bot/content/views/actions/shared-textures';
import { BOT_GREY, BOT_LIGHTEST_GREY } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { BOT_DEFAULT_ICON_BG_RADIUS } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.constants';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';
import { PixiTextureBuilderService } from '@panel/app/shared/services/pixi/texture-builder/pixi-texture-builder.service';

const ICON_CODE = '\ue952';

let iconTexture: BaseTexture | null = null;
let lastRendererContextUID: number | null = null;

function getIconBaseTexture(renderer: Renderer): BaseTexture {
  if (iconTexture) {
    return iconTexture;
  }

  const icon = PixiIconRenderService.renderIcon(ICON_CODE, { fill: BOT_GREY });

  const renderTexture = RenderTexture.create({
    width: icon.width,
    height: icon.height,
  });

  renderer.render(icon, { renderTexture });

  icon.destroy();

  iconTexture = renderTexture.castToBaseTexture();

  return iconTexture;
}

export function getIcon(renderer: Renderer): Container {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    clearCache();
  }

  lastRendererContextUID = renderer.CONTEXT_UID;

  const iconBaseTexture = getIconBaseTexture(renderer);

  const iconBackgroundCircleSprite = getIconBackgroundCircleBaseTexture(
    renderer,
    BOT_LIGHTEST_GREY,
    BOT_DEFAULT_ICON_BG_RADIUS,
  );

  return PixiTextureBuilderService.buildIconTextureWithBackground(iconBackgroundCircleSprite, iconBaseTexture);
}

function clearCache() {
  iconTexture = null;
}

<ng-container *transloco="let t; read: 'addonsComponent'">
  <ng-container
    *tuiLet="{
      app: subscriptionStore.app$ | async,
      billing: subscriptionStore.billing$ | async,
      availableAddons: subscriptionStore.availableAddons$.elements$ | async,
      connectedAddons: subscriptionStore.connectedAddons$.elements$ | async,
      plan: subscriptionStore.plan$ | async
    } as values"
  >
    <ng-container
      *ngIf="values.app && values.availableAddons && values.billing && values.connectedAddons && values.plan"
    >
      <h4 class="mb-15 font-weight-bold">{{ t('title') }}</h4>

      <ng-container *ngIf="!isEmptyListAddons(values.connectedAddons)">
        <cq-addon
          *ngFor="let addon of values.connectedAddons"
          [addon]="addon"
          [isSubscriptionActive]="values.billing.subscription.isActive || values.billing.subscription.isActiveTrial"
          [numbersUniqueUsers]="values.plan.availableUniqueUsers"
          [paymentPeriod]="values.billing.subscription.paymentPeriod"
          (clickConnectButton)="onClickConnectButton($event)"
        ></cq-addon>
      </ng-container>

      <ng-container *ngIf="!isEmptyListAddons(values.availableAddons)">
        <cq-addon
          *ngFor="let addon of getAddonsNotConnected(values.availableAddons, values.connectedAddons)"
          [addon]="addon"
          [isSubscriptionActive]="values.billing.subscription.isActive || values.billing.subscription.isActiveTrial"
          [numbersUniqueUsers]="values.plan.availableUniqueUsers"
          [paymentPeriod]="values.billing.subscription.paymentPeriod"
          (clickConnectButton)="onClickConnectButton($event)"
        ></cq-addon>
      </ng-container>

      <ng-container *ngIf="isEmptyListAddons(values.availableAddons)">
        <cq-upgrade [app]="values.app"></cq-upgrade>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';

import { TelegramBotStoreProvider } from '@panel/app/ajs-upgraded-providers';
import { IntegrationIconModule } from '@panel/app/pages/integrations/integration-icon/integration-icon.module';
import { IntegrationPreviewComponent } from '@panel/app/pages/integrations/integration-preview/integration-preview.component';
import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { SafeRemoveModule } from '@panel/app/shared/modals/safe-remove/safe-remove.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [IntegrationPreviewComponent],
  imports: [
    SharedModule,
    CommonModule,
    TranslocoModule,
    UIRouterModule,
    MomentModule,
    NgbDropdownModule,
    SafeRemoveModule,
    NgbModalModule,
    IntegrationIconModule,
    AccessDenialPopoverModule,
  ],
  exports: [IntegrationPreviewComponent],
  providers: [TelegramBotStoreProvider],
})
export class IntegrationPreviewModule {}

import { translate } from '@jsverse/transloco';
import { Graphics, TextStyle } from 'pixi.js';

import {
  ActionStyle,
  MAX_ACTION_WIDTH,
  SimpleActionABS,
} from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { getHorizontalSplitter } from '@panel/app/pages/chat-bot/content/views/actions/shared-textures';
import { BOT_GREY, BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';

import { getIcon } from './mark-conversation-visible-action.texture';

export class BotMarkConversationVisibleAction extends SimpleActionABS {
  protected style!: ActionStyle;

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 10,
        horizontal: 10,
      },
      border: {
        size: 1,
        color: 0xc7cad1,
        radius: 10,
      },
      background: {
        color: BOT_WHITE_COLOR,
      },
    };
  }

  render(): Graphics {
    const element = this.element ?? new Graphics();
    const padding = this.style.padding;

    const textOptionsTop: Partial<TextStyle> = {
      padding: this.style.padding.horizontal,
      wordWrapWidth: MAX_ACTION_WIDTH - padding.horizontal * 2,
      align: 'center',
      fontWeight: '100',
      fill: BOT_GREY,
    };

    const headingTranslation = translate('classes.action.canvasText.markConversationVisible');
    const text1 = renderCanvasText(headingTranslation, textOptionsTop);
    const line = getHorizontalSplitter(this.pixiApp.renderer);
    const icon = getIcon(this.pixiApp.renderer);

    const rectSize = 25;
    const paddingToIcon = 7.5;
    const textPadding = rectSize + paddingToIcon;
    text1.position.set((MAX_ACTION_WIDTH - text1.width) / 2, textPadding);
    line.position.set((MAX_ACTION_WIDTH - line.width) / 2, rectSize / 2);
    icon.position.set((MAX_ACTION_WIDTH - icon.width) / 2, 0);

    element.addChild(text1);
    element.addChild(line);
    element.addChild(icon);

    return element;
  }
}

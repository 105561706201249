import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import Moment, { MomentInput } from 'moment';

import { User } from '@http/user/types/user.type';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { UserSystemProperty } from '@http/property/property.constants';

@Component({
  selector: 'cq-user-systems-props',
  templateUrl: './user-systems-props.component.html',
  styleUrls: ['./user-systems-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSystemsPropsComponent {
  @Input()
  systemProps: Array<UserSystemProperty | '$carrotId' | '$userId'> = [];

  @Input()
  user!: User;

  @Input()
  pinnedProps: Partial<Array<UserSystemProperty | string | string[]>> = [];

  @Input()
  pinningPossibility: boolean = false;

  /**
   * Отключен ли класс property-body у детей кастомных и системных св-в.
   * Это необходимо, чтобы отключать hover у элементов при перетаскивании.
   * При использовании sortableJs сохраняется hover эффект на изначальной позиции.
   */
  @Input()
  disablePropertyBodyClass: boolean = false;

  @Output()
  pinProp: EventEmitter<string> = new EventEmitter();

  @Output()
  unpinProp: EventEmitter<string> = new EventEmitter();

  /** Свойства в которых у значения массив */
  arraysSystemsProps = [
    '$viewed_categories',
    '$viewed_products',
    '$ordered_categories',
    '$ordered_items',
    '$cart_items',
  ];

  systemsPropsWithLink = [
    '$last_session_referrer',
    '$initial_referrer',
    '$initial_referrer_domain',
    '$social_vk',
    '$social_facebook',
    '$social_instagram',
    '$social_foursquare',
    '$social_googleplus',
    '$social_pinterest',
    '$social_twitter',
    '$social_skype',
    '$social_telegram',
    '$social_whatsapp',
  ];

  requirementsSystemProps = [
    '$sessions',
    '$score',
    '$city',
    '$last_session_referrer',
    '$initial_referrer',
    '$initial_referrer_domain',
    '$last_seen',
    '$userId',
    '$device_type',
    '$carrotId',
    '$social_telegram_id',
  ];

  constructor(private readonly translocoService: TranslocoService, private readonly toastService: ToastService) {}

  copiedSuccess() {
    const text = this.translocoService.translate('userPropsComponent.copiedTooltip');
    this.toastService.success(text);
  }

  getModifySystemPropValue(propName: UserSystemProperty | '$carrotId' | '$userId'): string | undefined {
    if (propName === '$city') {
      // Если нет никаких данных о географии, то показываем прочерк
      if ((!this.user.props.$city && !this.user.props.$region) || !this.user.props.$country) {
        return this.translocoService.translate('general.unknown');
      }
      return (
        (this.user.props.$city || '-') +
        ', ' +
        (this.user.props.$region || '-') +
        ', ' +
        (this.user.props.$country || '-')
      );
    }

    if (propName === '$last_seen') {
      return this.getPrettyDate(this.getUserSystemPropValue(propName) as string);
    }

    if (propName === '$userId') {
      return this.user.userId || this.translocoService.translate('general.unknown');
    }

    if (propName === '$carrotId') {
      return this.user.id;
    }

    if (propName === '$score' || propName === '$sessions') {
      return this.getUserSystemPropValue(propName) || '0';
    }

    if (propName === '$social_telegram_id') {
      return (this.user.props[propName] as string) || this.translocoService.translate('general.unknown');
    }

    return this.user.props[propName] as string;
  }

  /**
   * Преобразование в красивый формат даты
   *
   * @param value
   */
  getPrettyDate(value: Moment.Moment | string | boolean): string {
    let now = Moment();

    if (value instanceof Moment) {
      value = Moment(value as MomentInput);

      if (now.diff(value, 'seconds') >= 0 && now.diff(value, 'seconds') < 20) {
        return this.translocoService.translate('userSystemsPropsComponent.prettyDate.justNow');
      } else if (now.diff(value, 'seconds') >= 20 && now.diff(value, 'seconds') < 60) {
        return this.translocoService.translate('userSystemsPropsComponent.prettyDate.secondsAgo', {
          count: now.diff(value, 'seconds'),
        });
      } else if (now.diff(value, 'minutes') >= 1 && now.diff(value, 'minutes') < 60) {
        return this.translocoService.translate('userSystemsPropsComponent.prettyDate.minutesAgo', {
          count: now.diff(value, 'minutes'),
        });
      } else {
        return value.format('lll');
      }
    } else {
      throw new Error('Only Moment type supported');
    }
  }

  /**
   * Получение значения системного св-ва
   * Св-в $carrotId и $userId нет в списке системных св-в,
   * но мы их отображаем наряду с сист. св-вами, поэтому они здесь присутствуют.
   * @param propName
   */
  getUserSystemPropValue(propName: UserSystemProperty | '$carrotId' | '$userId'): string {
    if (propName === '$carrotId') {
      return this.user.id;
    }

    if (propName === '$userId') {
      return this.user.userId;
    }

    if (this.user.props[propName]) {
      return this.user.props[propName] as string;
    }

    return '';
  }

  /**
   * Является ли значение св-ва идами клиентов гугл
   * @param propName
   */
  isGoogleClientsIds(propName: string): boolean {
    return propName === '$google_client_ids';
  }

  /**
   * Является ли значение св-ва массивом
   * @param propName
   */
  isPropWithArray(propName: string): boolean {
    return this.arraysSystemsProps.includes(propName);
  }

  /**
   * Является ли значение св-ва ссылкой
   * @param propName
   */
  isPropWithLink(propName: string): boolean {
    return this.systemsPropsWithLink.includes(propName);
  }

  /**
   * Является ли св-во закрепленным
   * @param propName
   */
  isPinned(propName: string): boolean {
    return this.pinnedProps.includes(propName);
  }

  /**
   * Является ли значение св-ва обязательным к отображению
   * @param propName
   */
  isRequirementProp(propName: string): boolean {
    return this.requirementsSystemProps.includes(propName);
  }

  trackByFn(index: number, value: string) {
    return index;
  }

  /**
   * Функция для оборачивания строки
   * @param input - Оборачиваемая строка
   * @param start - Значение, вставляемое в начале
   * @param end - Значение, вставляемое в конце
   */
  wrap(input: string | number, start: string, end: string): string {
    return input && (start || end) ? [start, input, end || start].join('') : (input as string);
  }
}

import { InjectionToken, Provider } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const FORM_SUBMIT_SOURCE_TOKEN = new InjectionToken<Subject<void>>(
  'Subject to send notifications about form submit',
);

const formSubmitSourceTokenProvider: Provider = {
  provide: FORM_SUBMIT_SOURCE_TOKEN,
  useFactory: () => {
    return new Subject<number>();
  },
};

export const FORM_SUBMIT_TOKEN = new InjectionToken<Observable<void>>(
  'Observable to notify children controls about form submit',
);

const formSubmitTokenProvider: Provider = {
  provide: FORM_SUBMIT_TOKEN,
  deps: [FORM_SUBMIT_SOURCE_TOKEN],
  useFactory: (formSubmitSource: Subject<void>) => {
    return formSubmitSource.asObservable();
  },
};

export const formSubmitTokenProviders = [formSubmitSourceTokenProvider, formSubmitTokenProvider];

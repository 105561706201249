/**
 * Фильтр, переводящий названия системных типов событий
 * FIXME: удалить этот фильтр, когда все события будут обрабатываться в одном месте в модели
 */
(function () {
  'use strict';

  angular.module('myApp.filters.prettyEventTypeName').filter('prettyEventTypeName', prettyEventTypeName);

  function prettyEventTypeName(eventTypeModel) {
    return prettyEventTypeNameFilter;

    /**
     * Получение названия типа события
     *
     * @param {String} eventTypeName Название типа события
     * @returns {String}
     */
    function prettyEventTypeNameFilter(eventTypeName) {
      return eventTypeModel.parseName(eventTypeName);
    }
  }
})();

import { Pipe, PipeTransform } from '@angular/core';
import { transliterate as tr } from 'transliteration';

@Pipe({
  name: 'transliterate',
})
export class TransliteratePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Транслитерация русского текста в английский
    let transliterated = tr(value);

    // Замена пробелов на дефисы и приведение к нижнему регистру
    transliterated = transliterated.replace(/\s+/g, '-').toLowerCase();

    return transliterated;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageEditorSwitchComponent } from './message-editor-switch.component';

@NgModule({
  declarations: [MessageEditorSwitchComponent],
  imports: [CommonModule, NgbTooltipModule, SharedModule, FormsModule],
  exports: [MessageEditorSwitchComponent],
})
export class MessageEditorSwitchModule {}

import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { BotAmocrmActionBodyJson } from 'app/http/chat-bot/actions';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AmocrmLeadTag } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';

/**
 * Компонент для вывода блоков ботов, при посещении которых срабатывает интеграция
 */

@Component({
  selector: 'cq-integration-chat-bots-trigger-blocks[chatBots][integrationId][integrationType][isShowedFromModal]',
  templateUrl: './integration-chat-bots-trigger-blocks.component.html',
  styleUrls: ['./integration-chat-bots-trigger-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationChatBotsTriggerBlocksComponent<T extends CHAT_BOT_TYPE> {
  /** Список с чат-ботами */
  @Input()
  chatBots!: ChatBot<T>[];

  /** ID текущей интеграции */
  @Input()
  integrationId!: string;

  /** Тип интеграции */
  @Input()
  integrationType!: INTEGRATION_TYPES;

  /** Показываются ли компоненты из модального окна создания интеграции */
  @Input()
  isShowedFromModal!: boolean;

  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(this.integrationType);
  }

  /** Является ли список ботов пустым */
  get isEmptyChatBots(): boolean {
    return this.chatBots.length === 0;
  }

  constructor(@Inject(IntegrationService) private readonly integrationService: IntegrationService) {}

  /**
   * Получение экшенов с интеграцией AMOCRM
   *
   * @param actions - Экшены бота
   */
  getAmocrmNotificationActions(actions: ChatBotAction[]): ChatBotAction[] {
    return actions.filter((action: ChatBotAction) => {
      return action.type === CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION && action.body === this.integrationId;
    });
  }

  /**
   * Получение экшенов с текущей интеграцией
   *
   * @param actions - Экшены бота
   */
  getChatBotActions(actions: ChatBotAction[]): ChatBotAction[] {
    switch (this.integrationType) {
      case INTEGRATION_TYPES.AMOCRM:
        return this.getAmocrmNotificationActions(actions);
      case INTEGRATION_TYPES.EMAIL_NOTIFICATION:
        return this.getEmailNotificationActions(actions);
      default:
        return [];
    }
  }

  /**
   * Получение веток бота
   *
   * @param chatBot - Бот у которого нужно получить ветки
   */
  getChatBotBranches(chatBot: ChatBot<T>): ChatBotBranch[] {
    if (!chatBot) {
      return [];
    }

    return chatBot.branches.filter((branch: ChatBotBranch) => this.getChatBotActions(branch.actions).length);
  }

  /**
   * Получение всех тегов сделки из ветки с настроенной передачей данных amoCRM
   *
   * @param branch Ветка чат-бота
   */
  getChatBotBranchAmocrmLeadTags(branch: ChatBotBranch): AmocrmLeadTag[] {
    // В одной ветке чат-бота может быть только одна передача данных в amoCRM, поэтому берём первый элемент
    const chatBotActions = this.getChatBotActions(branch.actions)[0] as ChatBotAction<BotAmocrmActionBodyJson>;

    return chatBotActions?.bodyJson.tags || [];
  }

  /**
   * Получение экшенов с интеграцией EMAIL_NOTIFICATION
   *
   * @param actions - Экшены бота
   */
  getEmailNotificationActions(actions: ChatBotAction[]): ChatBotAction[] {
    return actions.filter((action: ChatBotAction) => {
      return action.type === CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION && action.body === this.integrationId;
    });
  }

  /**
   * Получение отформатированного названия веток бота
   *
   * NOTE:
   *  Пример: "«Название ветки 1», «Название ветки 2»..."
   *
   * @param chatBot - Чат-бот, у которого нужно получить отформатированные названия веток
   */
  getFormattedNamesChatBotBranches(chatBot: ChatBot<T>): string {
    return this.getChatBotBranches(chatBot)
      .map((branch: ChatBotBranch) => `«${branch.name}»`)
      .join(', ');
  }

  /**
   * Проверка наличия тегов сделки amoCRM в ветке чат-бота
   *
   * @param branch Ветка чат-бота
   */
  isChatBotBranchAmocrmLeadTagsNotEmpty(branch: ChatBotBranch): boolean {
    return this.getChatBotBranchAmocrmLeadTags(branch).length > 0;
  }

  /**
   * Является ли бот лид-ботом
   *
   * @param chatBot - Бот у которого необходимо определить тип
   */
  isLeadBot(chatBot: ChatBot<T>): boolean {
    return chatBot.type === CHAT_BOT_TYPE.LEAD;
  }

  /**
   * Является ли бот welcome-ботом
   *
   * @param chatBot - Бот у которого необходимо определить тип
   */
  isWelcomeBot(chatBot: ChatBot<T>): boolean {
    return chatBot.type === CHAT_BOT_TYPE.ROUTING;
  }

  /**
   * Является ли бот welcome-ботом
   *
   * @param chatBot - Бот у которого необходимо определить тип
   */
  isWidgetBot(chatBot: ChatBot<T>): boolean {
    return chatBot.type === CHAT_BOT_TYPE.WIDGET;
  }
}

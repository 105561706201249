import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Simple } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';
import { generate } from 'short-uuid';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { DefaultActionHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-action.helper';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ActionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { PIXI_APP, PIXI_VIEWPORT, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { PIXI_CULL } from '@panel/app/pages/chat-bot/content/tokens/viewport-culling.token';
import { ActionBlockView, ConditionBlockView } from '@panel/app/pages/chat-bot/content/views/blocks';
import { Branch, BranchOptions } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { HybridBlockView } from '@panel/app/pages/chat-bot/content/views/blocks/hybrid-block-view';
import { MeetingBlockView } from '@panel/app/pages/chat-bot/content/views/blocks/meeting-block-view';

@Injectable()
export class BranchFactory {
  constructor(
    private readonly actionFactory: ActionFactory,
    private readonly ngZone: NgZone,
    @Inject(PIXI_CULL)
    private readonly cull: Simple,
    private readonly scenariosHelper: BotScenariosHelper,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    private readonly interactionService: PixiInteractionService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
  ) {}

  create(branch: ChatBotBranch, options: BranchOptions): Branch {
    return this.ngZone.runOutsideAngular(() => {
      const args: ConstructorParameters<typeof Branch> = [
        branch,
        this.cull,
        this.actionFactory,
        this,
        options,
        this.viewport,
        this.interactionService,
        this.document,
        this.scenariosHelper,
        this.pixiApp,
      ];

      switch (Branch.getBlockType(branch)) {
        case 'condition':
          return new ConditionBlockView(...args);
        case 'action':
          return new ActionBlockView(...args);
        case 'meeting':
          return new MeetingBlockView(...args);
      }

      return new HybridBlockView(...args);
    });
  }

  private createEmptyBranch(name: string, actions: ChatBotAction[] = []): ChatBotBranch {
    //TODO ANGULAR_TS В модели есть метод getDefaultBranch
    // Его надо переделать на static и вызывать тут
    const chatBotBranch: ChatBotBranch = {
      actions: actions,
      coordinates: null,
      id: null,
      isInvalid: false,
      linkId: generate(),
      name,
      parentBranchIds: [],
    };

    return chatBotBranch;
  }

  createDefaultBlock(name: string, options: BranchOptions, targetActionType = CHAT_BOT_ACTIONS_TYPES.CLOSE): Branch {
    const textAction = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.TEXT);
    const targetAction = DefaultActionHelper.getDefaultAction(targetActionType);

    const chatBotBranch = this.createEmptyBranch(name, [textAction, targetAction]);

    return this.create(chatBotBranch, options);
  }

  createCondition(name: string, options: BranchOptions): Branch {
    const appOnlineAction = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION);
    const defaultAction = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION);

    const chatBotBranch = this.createEmptyBranch(name, [appOnlineAction, defaultAction]);

    return this.create(chatBotBranch, options);
  }

  createActionBlock(name: string, options: BranchOptions): Branch {
    const nextBlockAction = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.NEXT);

    const chatBotBranch = this.createEmptyBranch(name, [nextBlockAction]);

    return this.create(chatBotBranch, options);
  }

  createMeetingBlock(name: string, options: BranchOptions): Branch {
    const textAction = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.TEXT);
    const meetingAction = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.MEET);

    const chatBotBranch = this.createEmptyBranch(name, [textAction, meetingAction]);

    return this.create(chatBotBranch, options);
  }
}

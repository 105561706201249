import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ItemContentComponent } from '@panel/app/pages/subscription/general/signed/faq/item-content/item-content.component';

@NgModule({
  declarations: [ItemContentComponent],
  exports: [ItemContentComponent],
  imports: [CommonModule],
})
export class ItemContentModule {}

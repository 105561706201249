import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { IntegrationService } from '@http/integration/services/integration.service';
import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { IntegrationIconModule } from '@panel/app/pages/integrations/integration-icon/integration-icon.module';
import { IntegrationDescriptionComponent } from '@panel/app/pages/integrations/partials/integration-description/integration-description.component';
import { IntegrationDescriptionActionsModule } from '@panel/app/pages/integrations/partials/integration-description-actions/integration-description-actions.module';

@NgModule({
  declarations: [IntegrationDescriptionComponent],
  exports: [IntegrationDescriptionComponent],
  imports: [
    CommonModule,
    IntegrationDescriptionActionsModule,
    IntegrationIconModule,
    NgbAlertModule,
    NgbCollapseModule,
    TranslocoModule,
    UIRouterModule,
  ],
  providers: [CarrotquestHelperProvider, IntegrationService],
})
export class IntegrationDescriptionModule {}

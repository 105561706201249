import { IMAGE_EXTENSION } from '../../../shared/services/file-helper/file-helper.constants';
import { BILLING_ADD_ONS } from '../../../../app/services/billing-info/billing-info.constants';
import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import {
  PSEUDO_TEAM_MEMBERS_GROUPS,
  PSEUDO_TEAM_MEMBERS_GROUPS_IDS,
} from '../../../../app/http/team-member-group/team-member-group.constants';
import {
  TEAM_MEMBER_DEFAULT_AVATAR_URL,
  TEAM_MEMBER_PERMISSIONS,
  TEAM_MEMBER_PERMISSIONS_ARRAY,
} from '../../../../app/http/team-member/team-member.constants';
import { AssignNewSuperAdminModalComponent } from '../../../../app/pages/account/shared/assign-new-super-admin-modal/assign-new-super-admin-modal.component';

/**
 * Контроллер для таблицы членов команды
 */
(function () {
  'use strict';

  angular.module('myApp.teamMembers').controller('TeamMemberEditorController', TeamMemberEditorController);

  function TeamMemberEditorController(
    $filter,
    $q,
    $scope,
    $translate,
    $uibModal,
    $uibModalInstance,
    toastr,
    ACTIONS_ON_TEAM_MEMBERS_GROUP,
    billingInfo,
    callback,
    carrotquestHelper,
    currentApp,
    currentTeamMember,
    fileHelper,
    planVersionService,
    planFeatureAccessService,
    teamMember,
    teamMemberGroupModel,
    teamMemberModel,
    teamMembers,
    teamMembersGroups,
    utilsService,
    validationHelper,
    modalHelperService,
  ) {
    var vm = this;

    var AVATAR_MAX_SIZE = 1024 * 1024; // максимальный размер аватара (1мб)
    var EMAIL_FROM_MAX_LENGTH = 255; // ограничение на максимальную длину электронной почты в рассылках
    var EMAIL_FROM_PATTERN = /^[0-9a-zA-Z\-_][0-9a-zA-Z\-_.]+$/; // регулярное выражение для электронной почты в рассылках
    var INTERNAL_NAME_MAX_LENGTH = 255; // ограничение на максимальную длину внутреннего имени
    var NAME_MAX_LENGTH = 30; // ограничение на максимальную длину видимого имени

    var initialPermissions = angular.copy(teamMember.permissions); // первоначальные разрешения, которые были у пользователя на момент его редактирования

    vm.accessToAdminGroups = planFeatureAccessService.getAccess(PLAN_FEATURE.APPS_ADMIN_GROUPS, currentApp);
    vm.apiAdminGroupDoesNotExists = false; // флаг добавления члена команды в удаленную группу
    vm.apiDuplicateAdmin = false; // флаг уже существующего члена команды с таким email-адресом
    vm.availableTeamMemberPermissions = getAvailableTeamMemberPermissions();
    vm.AVATAR_MAX_SIZE = AVATAR_MAX_SIZE;
    vm.BILLING_ADD_ONS = BILLING_ADD_ONS;
    vm.billingInfo = billingInfo;
    vm.currentApp = currentApp;
    vm.EMAIL_FROM_MAX_LENGTH = EMAIL_FROM_MAX_LENGTH;
    vm.EMAIL_FROM_PATTERN = EMAIL_FROM_PATTERN;
    vm.fileHelper = fileHelper;
    vm.IMAGE_EXTENSION = IMAGE_EXTENSION;
    vm.INTERNAL_NAME_MAX_LENGTH = INTERNAL_NAME_MAX_LENGTH;
    vm.isApiRequestPerformed = false; // флаг выполнения запроса
    vm.isDisabledPermissionExport = isDisabledPermissionExport;
    vm.isDisabledPermissionSendBulk = isDisabledPermissionSendBulk;
    vm.isDisabledPermissionConversationUserList = isDisabledPermissionConversationUserList;
    vm.isFreemium = planVersionService.isFreemium();
    vm.NAME_MAX_LENGTH = NAME_MAX_LENGTH;
    vm.newAvatar = {
      // новая аватарка
      file: null, // файл
      preview: null, // превью
    };
    vm.openTeamMembersGroupEditorModal = openTeamMembersGroupEditorModal;
    vm.PSEUDO_TEAM_MEMBERS_GROUPS = PSEUDO_TEAM_MEMBERS_GROUPS;
    vm.PSEUDO_TEAM_MEMBERS_GROUPS_IDS = PSEUDO_TEAM_MEMBERS_GROUPS_IDS;
    vm.saveTeamMember = saveTeamMember;
    vm.setDefaultAvatar = setDefaultAvatar;
    vm.TEAM_MEMBER_DEFAULT_AVATAR_URL = TEAM_MEMBER_DEFAULT_AVATAR_URL;
    vm.TEAM_MEMBER_PERMISSIONS = TEAM_MEMBER_PERMISSIONS;
    vm.TEAM_MEMBER_PERMISSIONS_ARRAY = TEAM_MEMBER_PERMISSIONS_ARRAY;
    vm.teamMember = teamMember;
    vm.teamMembersGroups = teamMembersGroups;
    vm.utilsService = utilsService;
    vm.uploadAvatarError = false; // Флаг ошибки загрузки аватарки
    vm.validationHelper = validationHelper;

    init();

    function init() {
      trackOpenModal();

      $scope.$watch('vm.teamMember.name', function (newValue, oldValue) {
        // автоматическое формирование электронной почты в рассылках из имени
        if (
          teamMember.emailFrom == undefined ||
          $filter('transliterate')(oldValue) == teamMember.emailFrom ||
          teamMember.emailFrom == ''
        ) {
          teamMember.emailFrom = $filter('transliterate')(teamMember.name);
        }
      });

      $scope.$watch('vm.newAvatar.file', function (newValue, oldValue) {
        if (newValue) {
          carrotquestHelper.track('Загрузил аватарку в аккаунт');
        }
      });

      $scope.$watch('vm.teamMember.permissions', function (newValue) {
        // если пользователь меняет права - нужно обновлять состояние чекбоксов
        // так же в темплейте они дизейблятся в некоторых случаях
        if (newValue == TEAM_MEMBER_PERMISSIONS.OPERATOR) {
          teamMember.permissionsExport = false;
          teamMember.permissionsSendBulk = false;
          teamMember.notifications.stats = false;
        } else if (newValue == TEAM_MEMBER_PERMISSIONS.ADMIN) {
          teamMember.permissionsConversationUserList = true;
        } else if (newValue == TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN) {
          teamMember.permissionsExport = true;
          teamMember.permissionsSendBulk = true;
          teamMember.permissionsConversationUserList = true;
        }
      });
    }

    /**
     * Получение прав, которые член команды может выставлять редактируемому члену команды
     */
    function getAvailableTeamMemberPermissions() {
      var availablePermissions = angular.copy(TEAM_MEMBER_PERMISSIONS_ARRAY);

      for (var i = availablePermissions.length; i >= 0; i--) {
        var teamMemberPermission = availablePermissions[i];

        if (!teamMemberModel.hasPermissions(currentTeamMember.permissions, teamMemberPermission)) {
          availablePermissions.splice(i, 1);
        }
      }

      return availablePermissions;
    }

    /**
     * Доступно ли разрешение на экспорт
     *
     * NOTE:
     *  Данная функция возвращает true|null, т.к. используется для определение disabled состояния чек-бокса,
     *  а ng-disabled ожидает true|null.
     *
     * @param {TEAM_MEMBER_PERMISSIONS} teamMemberPermission - Права доступа
     *
     * @return {boolean | null}
     */
    function isDisabledPermissionExport(teamMemberPermission) {
      return teamMemberModel.hasAccessToEditPermissionExport(teamMemberPermission) ? null : true;
    }

    /**
     * Доступно ли разрешение на массовую рассылку
     *
     * NOTE:
     *  Данная функция возвращает true|null, т.к. используется для определение disabled состояния чек-бокса,
     *  а ng-disabled ожидает true|null.
     *
     * @param {TEAM_MEMBER_PERMISSIONS} teamMemberPermission - Права доступа
     *
     * @return {boolean | null}
     */
    function isDisabledPermissionSendBulk(teamMemberPermission) {
      return teamMemberModel.hasAccessToEditPermissionSendBulk(teamMemberPermission) ? null : true;
    }

    /**
     * Доступно ли разрешение на массовую рассылку
     *
     * NOTE:
     *  Данная функция возвращает true|null, т.к. используется для определение disabled состояния чек-бокса,
     *  а ng-disabled ожидает true|null.
     *
     * @param {TEAM_MEMBER_PERMISSIONS} teamMemberPermission - Права доступа
     *
     * @return {boolean | null}
     */
    function isDisabledPermissionConversationUserList(teamMemberPermission) {
      return teamMemberModel.isDisabledPermissionConversationUserList(teamMemberPermission) ? null : true;
    }

    /**
     * Открытие модалки редактирования/создания группы членов команды
     *
     * @param {Object=} group Группа членов команды
     */
    function openTeamMembersGroupEditorModal(group) {
      var teamMembersGroupEditorModal = $uibModal.open({
        component: 'cqTeamMembersGroupEditorModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          group: angular.bind(null, angular.identity, angular.copy(group)),
        },
      });

      teamMembersGroupEditorModal.result.then(teamMembersGroupEditorSuccess);

      function teamMembersGroupEditorSuccess(response) {
        var group = $filter('filter')(vm.teamMembersGroups, { id: response.group.id }, true)[0];

        switch (response.action) {
          case ACTIONS_ON_TEAM_MEMBERS_GROUP.CREATE: {
            vm.teamMembersGroups.push(response.group);
            vm.teamMember.group = response.group;
            break;
          }
          case ACTIONS_ON_TEAM_MEMBERS_GROUP.REMOVE: {
            // Удаляем группу из списка групп
            vm.teamMembersGroups.splice(vm.teamMembersGroups.indexOf(group), 1);

            // Если член команды в удаляемой группе, то выбираем группу Без группы
            if (vm.teamMember.group.id === response.group.id) {
              vm.teamMember.group = $filter('filter')(
                vm.teamMembersGroups,
                { id: PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.WITHOUT_GROUP] },
                true,
              )[0];
            }

            // Перенести всех членов команды из удаленной группы в группу Без группы
            for (var i = 0; i < teamMembers.length; i++) {
              if (teamMembers[i].group.id === response.group.id) {
                teamMembers[i].group = $filter('filter')(
                  vm.teamMembersGroups,
                  { id: PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.WITHOUT_GROUP] },
                  true,
                )[0];
              }
            }
            break;
          }
          case ACTIONS_ON_TEAM_MEMBERS_GROUP.RENAME: {
            // Переименовываем в селекте, если выбрана редактируемая группа
            if (vm.teamMember.group.id === response.group.id) {
              angular.extend(vm.teamMember.group, response.group);
            }

            // Переименовываем у всех пользователей из этой группы
            for (var i = 0; i < teamMembers.length; i++) {
              if (teamMembers[i].group.id === response.group.id) {
                angular.extend(teamMembers[i].group, response.group);
              }
            }

            // Переименовываем в списке ui-select
            angular.extend(group, response.group);
            break;
          }
        }
      }
    }

    /**
     * Закрытие модалки с передачей члена команды в resolve
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function saveTeamMember(isValid) {
      if (isValid) {
        vm.apiAdminGroupDoesNotExists = false;
        vm.apiDuplicateAdmin = false;

        teamMember.avatar = vm.newAvatar.file || teamMember.avatar;

        // если чувак понижает себе права - его нужно предупреждать об этом
        // NOTE: FIXME currentApp.admins: этот функционал сделан на будущее, по дизайнам, в текущей версии чувак не может себя редактировать, но в будущем сможет это делать
        if (
          teamMember.id == currentTeamMember.id &&
          TEAM_MEMBER_PERMISSIONS_ARRAY.indexOf(initialPermissions) >
            TEAM_MEMBER_PERMISSIONS_ARRAY.indexOf(teamMember.permissions)
        ) {
          var bodyTemplate = '';
          var permissionsFromTo = '';

          var anotherSuperAdmins = $filter('filter')(
            teamMembers,
            { permissions: TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN },
            true,
          );
          anotherSuperAdmins = $filter('filter')(anotherSuperAdmins, { id: '!' + teamMember.id }, true);

          if (initialPermissions == TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN && teamMembers.length == 1) {
            // TODO: надо добавить поп-ап о том, что чувак снимает с себя права суперадмина, при этом он остался единственным членом команды
          } else if (
            initialPermissions == TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN &&
            teamMembers.length > 1 &&
            !anotherSuperAdmins.length
          ) {
            // если чувак был суперадмином, и при этом существуют другие члены команды, ни один из которых не является суперадмином - надо попросить его передать права суперадмину кому-нибудь
            if (teamMember.permissions == TEAM_MEMBER_PERMISSIONS.ADMIN) {
              permissionsFromTo = 'teamMembers.editor.removeSelfAsLastSuperAdminModal.body.permissionsToAdmin';
            } else if (teamMember.permissions == TEAM_MEMBER_PERMISSIONS.OPERATOR) {
              permissionsFromTo = 'teamMembers.editor.removeSelfAsLastSuperAdminModal.body.permissionsToOperator';
            }

            let assignNewSuperAdminModal = modalHelperService.open(AssignNewSuperAdminModalComponent);

            assignNewSuperAdminModal.componentInstance.modalWindowParams = {
              body:
                '<div class="margin-bottom-20">\
                          ' +
                $translate.instant(permissionsFromTo, { originCurrentApp: currentApp.origin }) +
                '\
                      </div>\
                      <div class="margin-bottom-20">\
                        ' +
                $translate.instant('teamMembers.editor.removeSelfAsLastSuperAdminModal.body.chooseNewSuperAdmin') +
                '\
                      </div>',
              appId: currentApp.id,
              currentApp: currentApp,
              teamMember: teamMember,
              teamMembers: teamMembers,
              currentAppName: null,
            };

            assignNewSuperAdminModal.result.then(close);
          } else if (anotherSuperAdmins.length) {
            // если есть другие суперадмины - просто предупреждаем чувака, что он понижает себе права
            // если чувак был суперадмином - он может понизить себе права до админа или оператора
            if (initialPermissions == TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN) {
              if (teamMember.permissions == TEAM_MEMBER_PERMISSIONS.ADMIN) {
                bodyTemplate =
                  '\
                  <div class="margin-bottom-20">\
                    ' +
                  $translate.instant(
                    'teamMembers.editor.reducePermissionsModal.body.permissionsFromSuperAdmin.toAdmin.basicDescription',
                    { originCurrentApp: currentApp.origin },
                  ) +
                  '\
                  </div>\
                  <div class="margin-bottom-20">\
                    ' +
                  $translate.instant(
                    'teamMembers.editor.reducePermissionsModal.body.permissionsFromSuperAdmin.toAdmin.downgradePermissions',
                  ) +
                  '\
                  </div>\
                  <div>\
                    <span>\
                      ' +
                  $translate.instant(
                    'teamMembers.editor.reducePermissionsModal.body.permissionsFromSuperAdmin.toAdmin.areYouSure',
                  ) +
                  '\
                    </span>\
                  </div>\
              ';
              } else {
                bodyTemplate =
                  '\
                  <div class="margin-bottom-20">\
                    ' +
                  $translate.instant(
                    'teamMembers.editor.reducePermissionsModal.body.permissionsFromSuperAdmin.toOperator.basicDescription',
                    { originCurrentApp: currentApp.origin },
                  ) +
                  '\
                  </div>\
                  <div class="margin-bottom-20">\
                    ' +
                  $translate.instant(
                    'teamMembers.editor.reducePermissionsModal.body.permissionsFromSuperAdmin.toOperator.downgradePermissions',
                  ) +
                  '\
                  </div>\
                  <div>\
                    ' +
                  $translate.instant(
                    'teamMembers.editor.reducePermissionsModal.body.permissionsFromSuperAdmin.toOperator.areYouSure',
                  ) +
                  '\
                  </div>\
              ';
              }
            } else if (initialPermissions == TEAM_MEMBER_PERMISSIONS.ADMIN) {
              // если чувак был админом - он может понизить себе права только до оператора
              bodyTemplate =
                '\
                <div class="margin-bottom-20">\
                  ' +
                $translate.instant(
                  'teamMembers.editor.reducePermissionsModal.body.permissionsFromAdmin.toOperator.basicDescription',
                  { originCurrentApp: currentApp.origin },
                ) +
                '\
                </div>\
                <div class="margin-bottom-20">\
                  ' +
                $translate.instant(
                  'teamMembers.editor.reducePermissionsModal.body.permissionsFromAdmin.toOperator.downgradePermissions',
                ) +
                '\
                </div>\
                <div>\
                  ' +
                $translate.instant(
                  'teamMembers.editor.reducePermissionsModal.body.permissionsFromAdmin.toOperator.areYouSure',
                ) +
                '\
                </div>\
            ';
            }

            var reducePermissionsModal = $uibModal.open({
              controller: 'ConfirmModalController',
              controllerAs: 'vm',
              resolve: {
                modalWindowParams: function () {
                  return {
                    heading: $translate.instant('teamMembers.editor.reducePermissionsModal.heading'),
                    body: bodyTemplate,
                    confirmButtonText: $translate.instant(
                      'teamMembers.editor.reducePermissionsModal.confirmButtonText',
                    ),
                  };
                },
              },
              templateUrl: 'js/shared/modals/confirm/confirm.html',
            });

            reducePermissionsModal.result.then(close);
          }
        } else {
          close();
        }
      }

      function close() {
        vm.isApiRequestPerformed = true;
        $q.when(callback(teamMember)).then(callbackSuccess, callbackError).finally(callbackFinally);

        function callbackError(response) {
          if (response.meta && response.meta.error == 'AdminGroupDoesNotExistsError') {
            vm.apiAdminGroupDoesNotExists = true;
          } else if (response.meta && response.meta.error == 'DuplicateAdminError') {
            vm.apiDuplicateAdmin = true;
          } else if (response.meta && response.meta.error == 'TooManyAdminsError') {
            toastr.error($translate.instant('teamMembers.editor.toasts.tooManyAdminsError'));
          } else if (response.meta && response.meta.error === 'ValidationError') {
            if (response.meta.error_fields && 'avatar' in response.meta.error_fields) {
              vm.uploadAvatarError = true;
            }
          }
        }

        function callbackFinally() {
          vm.isApiRequestPerformed = false;
        }

        function callbackSuccess(teamMember) {
          $uibModalInstance.close(teamMember);
          vm.uploadAvatarError = false;
        }
      }
    }

    /**
     * Установка аватара по умолчанию
     */
    function setDefaultAvatar() {
      vm.newAvatar.file = null;
      teamMember.avatar = TEAM_MEMBER_DEFAULT_AVATAR_URL;
      teamMember.setDefaultAvatar = true;
      vm.uploadAvatarError = false;
    }

    /**
     * Трек открытия модалки
     */
    function trackOpenModal() {
      carrotquestHelper.track('Настройки добавления члена команды - зашел');
    }
  }
})();

/**
 * Директива, которая осуществляет проверку, что введённое в input число меньше, чем число, заданное в атрибут директивы
 */
(function () {
  'use strict';

  angular.module('myApp.directives.number.additions').directive('cqMax', cqMax);

  function cqMax() {
    return {
      priority: 1010,
      restrict: 'A',
      require: ['ngModel', '?cqInteger', '?cqMoney'],
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var ngModelController = controller[0];
      var cqIntegerController = controller[1];
      var cqMoneyController = controller[2];

      if (!cqIntegerController && !cqMoneyController) {
        throw Error('cqMax expects either cqInteger or cqMoney to be defined');
      }

      var maxVal;

      ngModelController.$validators.max = max;
      iAttrs.$observe('cqMax', observe);

      /**
       * Валидация значения из модели, что оно меньше, чем maxVal
       *
       * @param {Number} value Значение, переданное на вход функции валидации
       * @returns {Boolean} Если значение валидное число и оно меньше, чем maxVal - возвращается true, иначе - false
       */
      function max(value) {
        if (ngModelController.$isEmpty(value) && !isNaN(value)) {
          return true;
        } else {
          if (isNaN(value)) {
            return false;
          }
          return value <= maxVal;
        }
      }

      /**
       * Динамическое получение значения из атрибута cq-max (чтобы можно было задавать в атрибут angular expression)
       *
       * @param {*} value Значение, переданное в атрибут
       */
      function observe(value) {
        if (angular.isDefined(value) && !angular.isNumber(value)) {
          value = parseFloat(value);
        }
        maxVal = angular.isNumber(value) && !isNaN(value) ? value : undefined;

        ngModelController.$validate();
      }
    }
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cq-block-header',
  templateUrl: './block-header.component.html',
  styleUrls: ['./block-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockHeaderComponent {
  @Input()
  blockType: 'text' | 'file' = 'text';

  @Input({ required: true })
  index: number = 0;

  @Input({ required: true })
  lastIndex: number = 0;

  @Input()
  isDuplicateDisabled: boolean = false;

  iconsMap = {
    file: 'cqi-paperclip',
    text: 'cqi-align-left',
  };

  @Output()
  readonly duplicate = new EventEmitter<void>();

  @Output()
  readonly delete = new EventEmitter<void>();

  @Output()
  readonly moveUp = new EventEmitter<void>();

  @Output()
  readonly moveDown = new EventEmitter<void>();
}

import { Pipe, PipeTransform } from '@angular/core';
import sortBy from 'lodash-es/sortBy';

import { UserProperty } from '@http/property/property.model';

@Pipe({
  name: 'filterAndSortUserProps',
})
export class FilterAndSortUserPropsPipe implements PipeTransform {
  transform(
    userProperties: UserProperty[],
    selectedProp: string,
    hasAccessToUsersCustomProperties: boolean,
  ): UserProperty[] {
    const filteredEvents = userProperties.filter((userProp: UserProperty) => {
      return (
        !userProp.readonly &&
        (hasAccessToUsersCustomProperties || userProp.prettyName === selectedProp || userProp.groupOrder !== 5)
      );
    });
    return sortBy(filteredEvents, ['groupOrder', 'prettyName']);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { AMOCRM_NOTE_MAX_LENGTH } from '@http/integration/integrations/amo/constants/amocrm-integration.constants';

/**
 * Компонент для работы с примечанием сделки AmoCRM
 */

@Component({
  selector: 'cq-amocrm-lead-note[controlNote]',
  templateUrl: './amocrm-lead-note.component.html',
  styleUrls: ['./amocrm-lead-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmLeadNoteComponent {
  /** Контрол для настройки примечания */
  @Input()
  controlNote!: UntypedFormControl;

  /** Отображается ли компонент при настройке бота */
  @Input()
  isChatBot?: boolean;

  /** Максимальная длинна примечания */
  AMOCRM_NOTE_MAX_LENGTH = AMOCRM_NOTE_MAX_LENGTH;
}

import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import cloneDeep from 'lodash-es/cloneDeep';
import { uuid } from 'short-uuid';

import { EventTypeModel } from '@http/event-type/event-type.model';
import { USER_STATUSES_FOR_SENDING } from '@http/message/message.constants';
import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { MessagePart } from '@http/message-part/message-part.types';
import { TriggerChainFactoryExtraData } from '@panel/app/http/trigger-chain/factory/trigger-chain.factory';
import {
  TriggerChainStep,
  TriggerChainStepSendingConditions,
  TriggerChainStepType,
} from '@panel/app/http/trigger-chain/internal-types';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { FILTER_LOGICAL_OPERATION } from '@panel/app/services/filter/filter.constants';
import { TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

// TODO подумать как лучше сделать дефолтный сценарий с преьвю

@Injectable({ providedIn: 'root' })
export class TriggerChainStepFactory {
  constructor(private readonly messagePartModel: MessagePartModel, private readonly transloco: TranslocoService) {}

  /** Копирование данных шага цепочки */
  copy(step: TriggerChainStep): TriggerChainStep {
    const newStep = cloneDeep(step);

    newStep.name += this.transloco.translate('triggerChainModel.copyNameSuffix');
    newStep.uuid = uuid();
    delete newStep.id;

    switch (newStep.type) {
      case 'autoMessage':
        newStep.meta.nextStep = null;
        delete newStep.meta.message.messageId;
        newStep.meta.message.parts.map((part) => {
          delete part.id;
        });
        break;
      case 'sendingConditions':
        newStep.meta.nextStep = null;
        break;
      case 'delay':
      case 'filter':
        newStep.meta.nextStep = null;
        newStep.meta.nextStepOnFail = null;
        break;
      case 'reaction':
        newStep.meta.nextStep = null;
        newStep.meta.nextStepOnFail = null;
        break;
    }

    return newStep;
  }

  generate(
    stepType: TRIGGER_CHAIN_BLOCK_TYPE,
  ): Extract<TriggerChainStep, { type: Exclude<TriggerChainStepType, 'sendingConditions'> }> {
    const commonFields = {
      uuid: uuid(),
      coordinates: {
        x: 0,
        y: 0,
      },
      name: this.transloco.translate(`triggerChainBlock.type.${stepType}`),
    };

    let meta: TriggerChainStep['meta'];
    let type: TriggerChainStepType;

    switch (stepType) {
      case TRIGGER_CHAIN_BLOCK_TYPE.CHAT:
      case TRIGGER_CHAIN_BLOCK_TYPE.EMAIL:
      case TRIGGER_CHAIN_BLOCK_TYPE.POPUP:
      case TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE:
      case TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK:
      case TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH:
        type = 'autoMessage';
        let chatPart = Object.assign(this.messagePartModel.getDefault(), {
          proportion: 1,
          type: this.getMessagePartTypeByBlockType(stepType),
        });
        meta = this.generateMetaForAutoMessageChainStep(chatPart);

        return {
          ...commonFields,
          type,
          meta,
        };
      case TRIGGER_CHAIN_BLOCK_TYPE.DELAY:
        type = 'delay';
        meta = {
          waitForDate: null,
          delay: {
            time: 5,
            unit: TIME_UNITS.SECOND,
          },
          propertyName: null,
          nextStep: null,
          nextStepOnFail: null,
        };

        return {
          ...commonFields,
          type,
          meta,
        };
      case TRIGGER_CHAIN_BLOCK_TYPE.FILTER:
        type = 'filter';
        meta = {
          filters: {
            logicalOperation: FILTER_LOGICAL_OPERATION.AND,
            filters: {
              props: [],
              events: [],
              tags: [],
            },
          },
          nextStep: null,
          nextStepOnFail: null,
          jinjaFilterTemplate: null,
        };

        return {
          ...commonFields,
          type,
          meta,
        };
      case TRIGGER_CHAIN_BLOCK_TYPE.REACTION:
        type = 'reaction';
        meta = {
          /** Сколько ждать реакцию */
          reactionTime: {
            time: 24 * 60 * 60,
            unit: TIME_UNITS.DAY,
          },
          reactionType: null,
          event: null,
          /** UUID шага при совершенной реакции */
          nextStep: null,
          /** UUID шага, если реакций не было */
          nextStepOnFail: null,
        };

        return {
          ...commonFields,
          type,
          meta,
        };
      case TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS:
        type = 'exit';
        meta = {
          type: 'success',
        };

        return {
          ...commonFields,
          type,
          meta,
        };
      case TRIGGER_CHAIN_BLOCK_TYPE.EXIT:
        type = 'exit';
        meta = {
          type: 'exit',
        };

        return {
          ...commonFields,
          type,
          meta,
        };
      default:
        throw new Error(`Type "${stepType}" is not supported`);
    }
  }

  generateFirstStep() {
    const commonFields = {
      uuid: uuid(),
      coordinates: {
        x: 0,
        y: 0,
      },
      name: this.transloco.translate(`triggerChainBlock.type.popup`),
    };

    let meta: TriggerChainStep['meta'];
    let type: TriggerChainStepType = 'autoMessage';

    let popupPart = Object.assign(
      this.messagePartModel.getDefault({
        blocks: [
          [
            [
              {
                type: 'text',
                params: {
                  autoMinHeight: true,
                  backgroundColor: '#ffffff',
                  backgroundImage: '',
                  backgroundType: 'transparent',
                  minHeight: '200',
                  text: this.transloco.translate('triggerChainModel.factory.chat.body'),
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                },
              },
            ],
          ],
        ],
        params: {
          backgroundColor: '#ffffff',
          backgroundImage: '',
          backgroundType: 'color',
          composition: 'oneColumn',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        },
      }),
      {
        proportion: 1,
        type: MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
      },
    );
    meta = this.generateMetaForAutoMessageChainStep(popupPart, {
      url: this.transloco.translate('triggerChainModel.factory.chat.preview.url'),
      heightPx: Number(this.transloco.translate('triggerChainModel.factory.chat.preview.heightPx')),
    });

    return {
      ...commonFields,
      type,
      meta,
    };
  }

  generateMetaForAutoMessageChainStep(
    part: MessagePart,
    previewImage: { url: string; heightPx: number } = { url: '', heightPx: 0 },
  ) {
    return {
      previewImage: previewImage,
      message: {
        parts: [
          part,
          Object.assign(this.messagePartModel.getDefault(), {
            proportion: 0,
            type: MESSAGE_PART_TYPES.CONTROL_GROUP,
          }),
        ],
      },
      nextStep: null,
    };
  }

  generateSendingConditions(extraData: TriggerChainFactoryExtraData): TriggerChainStepSendingConditions {
    const sessionStartEvent = EventTypeModel.findEventByName('$session_start', extraData.eventTypes);

    return {
      uuid: uuid(),
      coordinates: {
        x: 150,
        y: 150,
      },
      name: Math.random().toString(36).slice(2, 7),
      type: 'sendingConditions',
      meta: {
        triggerTypes: {
          openedWebPageTriggers: [],
          openedSdkPageTriggers: [],
          leaveSiteAttemptTrigger: false,
        },
        delay: {
          isEnabled: false,
          value: {
            time: 5,
            unit: TIME_UNITS.SECOND,
          },
        },
        filters: {
          type: FILTER_LOGICAL_OPERATION.AND,
          filters: {
            props: [],
            events: [],
            tags: [],
          },
        },
        userStatuses: USER_STATUSES_FOR_SENDING.NO_MATTER,
        triggers: [sessionStartEvent.id],
        nextStep: uuid(),
        sendingRepeat: {
          isEnabled: true,
          repeatDelay: 60 * 60,
          notSendReplied: false,
        },
        jinjaFilterTemplate: null,
        sendingTime: {
          isSendAtTime: false,
          sendTimeValueFromH: '09',
          sendTimeValueFromM: '00',
          sendTimeValueToH: '18',
          sendTimeValueToM: '00',
        },
      },
    };
  }

  private getMessagePartTypeByBlockType(stepType: TRIGGER_CHAIN_BLOCK_TYPE): MESSAGE_PART_TYPES {
    switch (stepType) {
      case TRIGGER_CHAIN_BLOCK_TYPE.CHAT:
        return MESSAGE_PART_TYPES.POPUP_CHAT;
      case TRIGGER_CHAIN_BLOCK_TYPE.EMAIL:
        return MESSAGE_PART_TYPES.EMAIL;
      case TRIGGER_CHAIN_BLOCK_TYPE.POPUP:
        return MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL;
      case TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE:
        return MESSAGE_PART_TYPES.TELEGRAM;
      case TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH:
        return MESSAGE_PART_TYPES.SDK_PUSH;
      case TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK:
        return MESSAGE_PART_TYPES.WEBHOOK;
      default:
        throw new Error(`Unsupported step type: ${stepType}`);
    }
  }
}

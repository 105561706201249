import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { FILTER_EVENT_TYPE } from '@panel/app/services/filter/filter.constants';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-event-type-select',
  templateUrl: './event-type-select.component.html',
  styleUrls: ['./event-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTypeSelectComponent extends AbsCVAFormControlBasedComponent<FILTER_EVENT_TYPE> {
  FILTER_EVENT_TYPE = FILTER_EVENT_TYPE;

  readonly control: FormControl<FILTER_EVENT_TYPE> = this.fb.control(FILTER_EVENT_TYPE.COUNT, { nonNullable: true });

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  protected getClassIconByEventType(eventType: FILTER_EVENT_TYPE): string {
    switch (eventType) {
      case FILTER_EVENT_TYPE.COUNT:
        return 'cqi-stopwatch-o';
      case FILTER_EVENT_TYPE.FIRST:
        return 'cqi-step-backward';
      case FILTER_EVENT_TYPE.LAST:
        return 'cqi-step-forward';
      default:
        throw new Error('Case is not handled');
    }
  }
}

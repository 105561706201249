import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { DATA_SOURCE } from '@http/ai-data/ai-data.constants';
import { AiData } from '@http/ai-data/internal-types/ai-data.internal.type';
import { AI_DATA_ACTIONS } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal.service';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';

@Injectable({
  providedIn: 'root',
})
export class AiDataActionModalTextService {
  constructor(
    private translocoService: TranslocoService,
    private aiDataSourcesListStateService: AiDataSourcesListStateService,
  ) {}

  getModalHeadingText(action: AI_DATA_ACTIONS): string {
    const selectedData = this.getSelectedData();
    const isAllSelected = this.aiDataSourcesListStateService.isSelectedAll$.getValue();

    if (selectedData.length === 1) {
      if (action === AI_DATA_ACTIONS.MULTIPLE_ENABLE) {
        return this.translocoService.translate(`aiDataActionModalTextService.${AI_DATA_ACTIONS.SINGLE_ENABLE}.heading`);
      }

      if (action === AI_DATA_ACTIONS.MULTIPLE_DISABLE) {
        return this.translocoService.translate(
          `aiDataActionModalTextService.${AI_DATA_ACTIONS.SINGLE_DISABLE}.heading`,
        );
      }
    }

    if ([AI_DATA_ACTIONS.DELETE, AI_DATA_ACTIONS.RENAME, AI_DATA_ACTIONS.SINGLE_DISABLE].includes(action)) {
      return this.translocoService.translate(`aiDataActionModalTextService.${action}.heading`);
    }

    const headingKey = `aiDataActionModalTextService.${action}.heading.${isAllSelected ? 'all' : 'multiple'}`;

    return this.translocoService.translate(headingKey, {
      count: selectedData.length,
    });
  }

  getModalBodyText(action: AI_DATA_ACTIONS, data?: AiData): string {
    const selectedData = this.getSelectedData();
    const isAllSelected = this.aiDataSourcesListStateService.isSelectedAll$.getValue();

    if (selectedData.length === 1) {
      if (action === AI_DATA_ACTIONS.MULTIPLE_ENABLE) {
        return this.translocoService.translate(`aiDataActionModalTextService.${AI_DATA_ACTIONS.SINGLE_ENABLE}.body`, {
          name: selectedData[0].name,
        });
      }

      if (action === AI_DATA_ACTIONS.MULTIPLE_DISABLE) {
        return this.translocoService.translate(`aiDataActionModalTextService.${AI_DATA_ACTIONS.SINGLE_DISABLE}.body`, {
          name: selectedData[0].name,
        });
      }
    }

    if ([AI_DATA_ACTIONS.DELETE, AI_DATA_ACTIONS.RENAME, AI_DATA_ACTIONS.SINGLE_DISABLE].includes(action)) {
      return this.translocoService.translate(`aiDataActionModalTextService.${action}.body`, {
        name: data!.name,
      });
    }

    if (isAllSelected) {
      const selectedSource = this.aiDataSourcesListStateService.selectedSource$.getValue();
      switch (selectedSource) {
        case DATA_SOURCE.KNOWLEDGE_BASE:
          const selectedCategoryId = this.aiDataSourcesListStateService.selectedCategory$.getValue();

          if (selectedCategoryId === '0') {
            return this.translocoService.translate(`aiDataActionModalTextService.${action}.body.knowledgeBase`);
          } else {
            const selectedCategoryName = this.aiDataSourcesListStateService.knowledgeBaseCategories$
              .getValue()
              .filter((category) => category.id === selectedCategoryId)[0].name;
            return this.translocoService.translate(`aiDataActionModalTextService.${action}.body.singleCategory`, {
              category: selectedCategoryName,
            });
          }
        case DATA_SOURCE.ALL:
          return this.translocoService.translate(`aiDataActionModalTextService.${action}.body.all`);
      }
    }

    return this.translocoService.translate(`aiDataActionModalTextService.${action}.body.multiple`, {
      count: selectedData.length,
    });
  }

  getConfirmButtonText(action: AI_DATA_ACTIONS): string {
    switch (action) {
      case AI_DATA_ACTIONS.DELETE:
        return this.translocoService.translate('general.remove');
      case AI_DATA_ACTIONS.RENAME:
        return this.translocoService.translate('general.save');
      case AI_DATA_ACTIONS.SINGLE_DISABLE:
      case AI_DATA_ACTIONS.MULTIPLE_DISABLE:
        return this.translocoService.translate('general.disable');
      case AI_DATA_ACTIONS.MULTIPLE_ENABLE:
      case AI_DATA_ACTIONS.SINGLE_ENABLE:
        return this.translocoService.translate('general.enable');
    }
  }

  getRenameModalInputPlaceholderText(): string {
    return this.translocoService.translate('aiDataActionModalTextService.rename.placeholder');
  }

  getRenameModalInputRequiredErrorText(): string {
    return this.translocoService.translate('aiDataActionModalTextService.rename.requiredError');
  }

  private getSelectedData() {
    const selectedIds = this.aiDataSourcesListStateService.selectedDataSources$.getValue();
    const allDataSources = this.aiDataSourcesListStateService.dataSourceList$.getValue();
    const selectedData = allDataSources.filter((ds) => selectedIds.includes(ds.contentId));

    return selectedData;
  }
}

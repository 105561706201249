import { InjectionToken } from '@angular/core';

import { STRIPE_ADD_CARD_MODAL_OPEN_REASON } from '@panel/app/services/billing-info/billing-info.constants';

/** Тип данных, передаваемых в модалку редактирования карты Stripe */
export type StripeEditCardModalData = {
  /** Заголовок */
  reason: STRIPE_ADD_CARD_MODAL_OPEN_REASON;
};

/**
 * Токен для передачи данных через DI в модалку редактирования карты Stripe
 */
export const STRIPE_EDIT_CARD_MODAL_DATA_TOKEN = new InjectionToken<StripeEditCardModalData>(
  'Stripe edit card modal data provider',
);

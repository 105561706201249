import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';

import { MESSAGE_PAGE_TYPES } from '@http/message/message.constants';
import { UtilsModel } from '@http/utils/utils.model';
import { MESSAGE_PAGE_TYPE_TOKEN } from '@panel/app/partials/message-editor/tokens';
import { MessageEditorTriggerSendingFilters } from '@panel/app/partials/message-editor/trigger/trigger/message-editor-trigger.component';
import {
  URL_FILTER_TYPE,
  UrlFilterConfiguratorComponent,
  UrlFilterConfiguratorOptions,
  UrlFilterFormGroup,
} from '@panel/app/partials/url-filter-configurator/url-filter-configurator.component';
import { SENDING_FILTERS_GROUP_TYPES } from '@panel/app/services/conditions-sending/conditions-sending.constants';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

type DispatchUrlFormGroup = {
  type: FormControl<SENDING_FILTERS_GROUP_TYPES>;
  filters: FormArray<UrlFilterFormGroup>;
};

/**
 * Компонент для настройки URL, на которых будет или не будет срабатывать сообщение
 */
@Component({
  selector: 'cq-message-editor-dispatch-url',
  templateUrl: './message-editor-dispatch-url.component.html',
  styleUrls: ['./message-editor-dispatch-url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorDispatchUrlComponent extends AbsCVAFormGroupBasedComponent<DispatchUrlFormGroup> {
  readonly control = this.fb.group<DispatchUrlFormGroup>({
    type: this.fb.control<SENDING_FILTERS_GROUP_TYPES>(SENDING_FILTERS_GROUP_TYPES.NO, {
      nonNullable: true,
    }),
    filters: this.fb.array<UrlFilterFormGroup>([]),
  });

  @Input()
  disableSwitch: boolean = false;

  /** Человеко читаемое название раздела в котором используется компонент */
  @Input()
  entityName: string = '';

  /** Список фильтров, которые можно выбрать */
  readonly availableSendingFilters: SENDING_FILTERS_GROUP_TYPES[] = [
    SENDING_FILTERS_GROUP_TYPES.INCLUSION,
    SENDING_FILTERS_GROUP_TYPES.EXCLUSION,
  ];

  readonly SENDING_FILTERS_GROUP_TYPES = SENDING_FILTERS_GROUP_TYPES;

  /** Набор опций для конфигуратора фильтров */
  readonly urlFilterConfiguratorOptions: UrlFilterConfiguratorOptions = {
    track: {
      eventName: 'Ограничение отправки на страницах - показ поповера про параметры',
      eventParams: {
        'Тип коммуникации': this.messagePageType,
      },
    },
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly utilsModel: UtilsModel,
    @Inject(MESSAGE_PAGE_TYPE_TOKEN)
    private readonly messagePageType: MESSAGE_PAGE_TYPES,
  ) {
    super();
  }

  get typeControl(): FormControl<SENDING_FILTERS_GROUP_TYPES> {
    return this.control.controls.type;
  }

  ///** Обновляет форму с настройками фильтров */
  onFilterTypeChange(type: SENDING_FILTERS_GROUP_TYPES): void {
    this.control.controls.filters.clear();

    if (type === SENDING_FILTERS_GROUP_TYPES.NO) {
      return;
    }

    const filter = UrlFilterConfiguratorComponent.getNewUrlFilter(
      this.utilsModel,
      URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS,
    );
    this.control.controls.filters.push(filter);
  }

  onSwitchChange($event: boolean) {
    this.control.controls.type.setValue(
      $event ? SENDING_FILTERS_GROUP_TYPES.INCLUSION : SENDING_FILTERS_GROUP_TYPES.NO,
    );
    this.onFilterTypeChange(this.control.controls.type.value);
  }

  /**
   * Записывает value установленное программно.
   * В ситуации, когда:
   *  + значение передается через [(ngModel)]
   * writeValue сначала вызовется с val = null, что сломает "this.control.setValue", поэтому добавляем отдельно первую проверку
   *
   * Переопределяем метод, потому что мы не можем записать через setValue фильтры,
   * так как фильтры это formArray и для нормальной работы нужно,
   * чтоб длина фильтров в formArray совпадала с длинной нового значения фильтров в setValue
   **/
  writeValue(val: MessageEditorTriggerSendingFilters): void {
    if (val === null) {
      return;
    }

    if (val !== undefined) {
      this.control.controls.filters.clear();
      val.filters.forEach((filter) => {
        const newControl = UrlFilterConfiguratorComponent.getNewUrlFilter(this.utilsModel, filter.type, filter.match);
        this.control.controls.filters.push(newControl, { emitEvent: false });
      });
      this.control.controls.type.setValue(val.type, { emitEvent: false });
    }
  }
}

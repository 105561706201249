<ng-container *transloco="let t">
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="integration.form"
  >
    <cq-telegram-manual
      [integrationTypeName]="integrationTypeName"
      [isManualExpanded]="isManualExpanded"
    ></cq-telegram-manual>

    <hr />
    <cq-bot-token
      [control]="botTokenControl"
      [integration]="integration"
    ></cq-bot-token>

    <hr />
    <cq-integration-name-control
      class="margin-bottom-20"
      [control]="nameControl"
    ></cq-integration-name-control>
  </form>
</ng-container>

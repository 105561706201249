import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { map } from 'rxjs/operators';

import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class SavedReplyModel {
  /**
   * Сохранённый ответ по-умолчанию
   *
   * @type {{app: null, header: string, id: null, body: string, order: number}}
   */
  DEFAULT_SAVED_REPLY = {
    app: null, // ID приложения, в котором находится этот ответ
    body: '', // Текст сохранённого ответа
    header: '', // Заголовок сохранённого ответа
    id: null, // ID сохранённого ответа
    order: 0, // Приоритет сохранённого ответа
  };

  constructor(private readonly httpClient: HttpClient) {}

  changeOrder(appId: any, savedReplyId: any, newOrder: any) {
    const params = {
      app: appId,
      order: newOrder,
    };

    return this.httpClient.patch(`/savedreplies/${savedReplyId}`, params);
  }

  /**
   * Создание сохранённого ответа
   *
   * @param {String} appId ID приложения
   * @param {String} body Текст сохранённого ответа
   * @param {String=} header Заголовок сохранённого ответа
   * @param {Boolean=} isLowestOrder Если указать true, то ответ создастся с наименьшим приоритетом
   * @return {Promise}
   */
  create(appId: any, body: any, header: any, isLowestOrder: any) {
    const params = {
      app: appId,
      body,
      header,
      lowest_order: isLowestOrder,
    };

    return this.httpClient.post('/savedreplies', params);
  }

  /**
   * Редактирование сохранённого ответа
   *
   * @param {String} appId ID приложения
   * @param {String} savedReplyId ID сохранённого ответа
   * @param {String} body Текст сохранённого ответа
   * @param {String=} header Заголовок сохранённого ответа
   * @param {Number=} order Приоритет сохранённого ответа
   * @return {Promise}
   */
  edit(appId: any, savedReplyId: any, body: any, header: any, order?: any) {
    const params = {
      app: appId,
      body,
      header,
      order,
    };

    return this.httpClient.patch(`/savedreplies/${savedReplyId}`, params);
  }

  /**
   * Получение дефолтного сохранённого ответа
   *
   * @returns {Object}
   */
  getDefault() {
    const defaultSavedReply = angular.copy(this.DEFAULT_SAVED_REPLY);

    return defaultSavedReply;
  }

  /**
   * Получение списка сохранённых ответов
   *
   * @param {String} appId ID приложения
   * @param {String=} searchString Строка, по которой будет идти поиск среди всех ответов
   * @return {Promise}
   */
  getList(appId: any, searchString: any) {
    let params = {};

    if (searchString) {
      //@ts-ignore
      params['search_string'] = searchString;
    }

    return this.httpClient.get(`/apps/${appId}/savedreplies`, { params }).pipe(
      map((response: any) => {
        let savedRepliesList = orderBy(response.items, ['order'], ['desc']);

        return savedRepliesList;
      }),
    );
  }

  /**
   * Удаление сохранённого ответа
   *
   * @param {String} savedReplyId ID сохранённого ответа
   * @return {Promise}
   */
  remove(savedReplyId: any) {
    return this.httpClient.delete(`/savedreplies/${savedReplyId}`, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }
}

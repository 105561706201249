import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { filter, flattenDeep, includes, isString, isUndefined } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import { firstValueFrom, map, Observable } from 'rxjs';

import { environment } from '@environment';
import { AppModel } from '@http/app/app.model';
import { MessageModel } from '@http/message/message.model';
import {
  EMAIL_TYPES,
  MESSAGE_PART_TYPES,
  POPUP_REPLY_TYPES,
  RECIPIENT_TYPES,
} from '@http/message-part/message-part.constants';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { MESSAGE_TEMPLATES_STATUSES } from '@http/message-template/message-template.constants';
import { MessageUtilsModel } from '@http/message-utils/message-utils.model';
import {
  PSEUDO_TEMPLATE_DIRECTORY_IDS,
  PSEUDO_TEMPLATE_DIRECTORY_TYPES,
} from '@http/template-directory/template-directory.constants';
import { TemplateDirectoryModel } from '@http/template-directory/template-directory.model';
import { CaseStyleHelper } from '@panel/app/services';
import { POPUP_BUTTON_BLOCK_TYPES } from '@panel/app/services/popup-block/popup-block.constants';
import { PopupBlockModel } from '@panel/app/services/popup-block/popup-block.model';
import { UtmMarkModel } from '@panel/app/services/utm-mark/utm-mark.model';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplateModel {
  createFromMessagePart = this.saveOrCreateFromMessagePart.bind(this, this.create.bind(this));
  saveFromMessagePart = this.saveOrCreateFromMessagePart.bind(this, this.save.bind(this));

  constructor(
    private readonly appModel: AppModel,
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly httpClient: HttpClient,
    private readonly messageModel: MessageModel,
    private readonly messagePartModel: MessagePartModel,
    private readonly messageUtilsModel: MessageUtilsModel,
    private readonly popupBlockModel: PopupBlockModel,
    private readonly templateDirectoryModel: TemplateDirectoryModel,
    private readonly utmMarkModel: UtmMarkModel,
  ) {}

  /**
   * Изменение статуса шаблона
   */
  changeDirectory(templateId: any, directoryId: any, ignoreLoadingBar: any): Observable<any> {
    const params: any = {
      directory:
        directoryId === PSEUDO_TEMPLATE_DIRECTORY_IDS[PSEUDO_TEMPLATE_DIRECTORY_TYPES.WITHOUT_DIRECTORY]
          ? 0
          : directoryId,
    };

    return this.httpClient.patch(`/messagetemplates/${templateId}`, params, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar ?? true),
    });
  }

  /**
   * Изменение статуса шаблона
   */
  changeStatus(template: any, ignoreLoadingBar: any): Observable<any> {
    const params: any = {
      active: !template.active,
    };

    return this.httpClient.patch(`/messagetemplates/${template.id}`, params, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar ?? true),
    });
  }

  /**
   * Создание шаблона
   */
  create(appId: any, template: any): Observable<any> {
    const directoryId =
      template.directory.id === PSEUDO_TEMPLATE_DIRECTORY_IDS[PSEUDO_TEMPLATE_DIRECTORY_TYPES.WITHOUT_DIRECTORY]
        ? 0
        : template.directory.id;
    const params: any = {
      app: appId,
      body: template.body,
      body_json: template.bodyJson,
      directory: directoryId,
      name: template.name,
      pure_html: template.pureHtml,
      reply_type: template.replyType,
      subject: template.subject,
      type: template.type,
      screen_size: this.getExternalPageSize(template.type), // нужно задать размеры браузера для страцницы со скнинами
    };

    if (template.isUtmMarksEnabled) {
      params.utm_marks = this.utmMarkModel.parseToServerFormat(template.utmMarks);
    }

    // NOTE Роман Е. Для "Чат в SDK" и "Поп-ап в SDK" есть возможность отправить push-уведомление вместе с сообщением.
    if (template.send_sdk_push) {
      params.send_sdk_push = template.send_sdk_push;
    }

    // HACK Роман Е. SDK-типы - это не отдельные типы шаблонов сообщений на backend, а те же самые (кроме Push в SDK).
    // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
    // HACK Поэтому показываем backend, что это сообщение SDK-типа.
    if (
      includes(
        [MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_PUSH, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL],
        params.type,
      )
    ) {
      params.recipient_type = RECIPIENT_TYPES.SDK;
    }

    // HACK Роман Е. SDK-типы - это не отдельные типы шаблонов сообщений на backend, а те же самые (кроме Push в SDK).
    // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
    // HACK Поэтому скрываем от backend, что это сообщение SDK-типа.
    if (includes([MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL], params.type)) {
      params.type = params.type.replace(`${RECIPIENT_TYPES.SDK}_`, '');
    }

    return this.httpClient
      .post('/messagetemplates', params, {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(map((response: any) => response.data));
  }

  /**
   * Фильтрация шаблонов
   * NOTE : Не показывать шаблоны в которых есть подписка на Web Push.
   * NOTE Роман Е. Модифицировать стандартные типы в SDK-типы, чтобы получить акутальные SDK-типам шаблоны.
   */
  filterTemplate(templates: any): any {
    for (let i = templates.length - 1; i >= 0; i--) {
      if (includes([MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL], templates[i].type)) {
        const parsedBodyJson = JSON.parse(templates[i].bodyJson);
        const flattenBlocks = flattenDeep(parsedBodyJson.blocks);
        if (filter(flattenBlocks, { params: { type: POPUP_BUTTON_BLOCK_TYPES.PUSH_SUBSCRIPTION } }).length > 0) {
          templates.splice(i, 1);
        }
      } else if (
        includes([MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL], templates[i].type) &&
        templates[i].replyType === POPUP_REPLY_TYPES.PUSH
      ) {
        templates.splice(i, 1);
      } else if (templates[i].recipient_type === RECIPIENT_TYPES.SDK) {
        templates[i].type = `${RECIPIENT_TYPES.SDK}_${templates[i].type}`;
      }
    }
    return templates;
  }

  /**
   * Получение шаблона
   */
  get(templateId: any, includeDirectory: any): Observable<any> {
    includeDirectory = isUndefined(includeDirectory) ? true : includeDirectory;

    const params: any = {
      include_directory: includeDirectory,
    };

    return this.httpClient
      .get(`/messagetemplates/${templateId}`, {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          const template = response.data;

          this.parse(response.data, includeDirectory);

          return template;
        }),
      );
  }

  /**
   * Получение шаблона по токену
   */
  getByToken(token: any, includeApp: any): Observable<any> {
    const params: any = {
      token: token,
      include_app: isUndefined(includeApp) ? true : includeApp,
    };

    return this.httpClient
      .get('/messagetemplates/', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          // У нас настройки аппа используются в андерскоре
          this.caseStyleHelper.keysToUnderscore(response.data.app);

          this.appModel.parse(response.data.app);

          if (
            response.data.recipientType === RECIPIENT_TYPES.SDK &&
            response.data.type !== MESSAGE_PART_TYPES.SDK_PUSH
          ) {
            response.data.type = `${RECIPIENT_TYPES.SDK}_${response.data.type}`;
          }

          return response.data;
        }),
      );
  }

  /**
   * Получение дефолтного шаблона
   */
  getDefault(): any {
    return {
      active: true,
      body: '',
      bodyJson: '',
      directory: this.templateDirectoryModel.getWithoutDirectory(),
      id: '',
      name: '',
      pureHtml: false,
      replyType: 'text',
      screenShot: null,
      subject: '',
      type: MESSAGE_PART_TYPES.POPUP_CHAT,
      utmMarks: null,
    };
  }

  /**
   * Получение списка шаблонов сообщений
   */
  getList(
    appId: any,
    status: any,
    messagePartTypes: any,
    directoryId: any,
    paginatorParams: any,
    paginationEnabled: any = true,
    includeDirectory: any = true,
    searchPhrase: any,
  ): Observable<any> {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    let currentDirectoryId = directoryId;
    const messagePartTypesArray = this.parseMessagePartTypes(messagePartTypes);

    if (directoryId === PSEUDO_TEMPLATE_DIRECTORY_IDS[PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY]) {
      currentDirectoryId = undefined;
    }

    let params: any = {
      active: status === MESSAGE_TEMPLATES_STATUSES.ALL || isUndefined(status) ? null : status,
      types: includes(messagePartTypesArray, MESSAGE_PART_TYPES.ALL) ? null : JSON.stringify(messagePartTypesArray),
      pagination_enabled: paginationEnabled,
      directory: currentDirectoryId,
      include_directory: includeDirectory,
      name_contains: searchPhrase || null,
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition.join() || null,
      paginate_page_order: paginatePageOrder,
    };

    let templateTypes = JSON.parse(params.types);
    if (templateTypes) {
      for (let i = 0; i < templateTypes.length; i++) {
        params.recipient_type = RECIPIENT_TYPES.WEB;

        if (
          includes(
            [MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL, MESSAGE_PART_TYPES.SDK_PUSH],
            templateTypes[i],
          )
        ) {
          params.recipient_type = RECIPIENT_TYPES.SDK;
        }

        if (templateTypes[i] !== MESSAGE_PART_TYPES.SDK_PUSH) {
          templateTypes[i] = templateTypes[i].replace(`${RECIPIENT_TYPES.SDK}_`, '');
        }
      }
      params.types = JSON.stringify(templateTypes);
    }

    return this.httpClient
      .get(`/apps/${appId}/messagetemplates`, {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          const templates = response.data;

          for (let i = 0; i < templates.length; i++) {
            this.parse(templates[i], includeDirectory);
          }

          return {
            templates: templates,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.nextBeforePosition,
            },
          };
        }),
      );
  }

  private parseMessagePartTypes(messagePartTypes: any): any[] {
    if (isString(messagePartTypes)) {
      return [messagePartTypes];
    } else {
      return messagePartTypes;
    }
  }

  /**
   * Собирает тело шаблона
   */
  getTemplateFromMessage(messagePart: any, template: any): any {
    const { body, bodyJson } = this.parseFromMessagePart(messagePart, template);
    return body ? body : bodyJson;
  }

  /**
   * Парсинг шаблона
   */
  parse(template: any, includeDirectory: any): void {
    if (includeDirectory) {
      if (isUndefined(template.directory) || template.directory === null) {
        template.directory = this.templateDirectoryModel.getWithoutDirectory();
      }
      this.templateDirectoryModel.parse(template.directory);
    }

    if (template.recipientType === RECIPIENT_TYPES.SDK && template.type !== MESSAGE_PART_TYPES.SDK_PUSH) {
      template.type = `${RECIPIENT_TYPES.SDK}_${template.type}`;
    }
  }

  /**
   * Удаление шаблона
   */
  remove(templateId: any): Observable<any> {
    return this.httpClient.delete(`/messagetemplates/${templateId}`, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Сохранение шаблона
   */
  save(appId: any, template: any): Observable<any> {
    const directoryId =
      template.directory.id === PSEUDO_TEMPLATE_DIRECTORY_IDS[PSEUDO_TEMPLATE_DIRECTORY_TYPES.WITHOUT_DIRECTORY]
        ? 0
        : template.directory.id;
    let params: any = {
      active: template.active,
      app: appId,
      body: template.body,
      body_json: template.bodyJson,
      directory: directoryId,
      name: template.name,
      pure_html: template.pureHtml,
      reply_type: template.replyType,
      subject: template.subject,
      type: template.type,
      screen_size: this.getExternalPageSize(template.type),
    };

    params.utm_marks = template.isUtmMarksEnabled ? this.utmMarkModel.parseToServerFormat(template.utmMarks) : {};

    if (
      includes(
        [MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_PUSH, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL],
        params.type,
      )
    ) {
      params.recipient_type = RECIPIENT_TYPES.SDK;
    } else {
      params.recipient_type = RECIPIENT_TYPES.WEB;
    }

    if (includes([MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL], params.type)) {
      params.send_sdk_push = template.sendSdkPush;
      params.type = params.type.replace(`${RECIPIENT_TYPES.SDK}_`, '');
    }

    return this.httpClient.patch(`/messagetemplates/${template.id}`, params, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Обработка варианта сообщеия перед сохра��ением в шаблон
   */
  saveOrCreateFromMessagePart(callback: any, appId: any, messagePart: any, template: any): Promise<any> {
    return this.uploadImages(appId, messagePart)
      .then((messagePart: any) => this.parseFromMessagePart(messagePart, template))
      .then((result: any) => firstValueFrom(callback(appId, result)));
  }

  /**
   * Парсинг из варианта сообщения в шаблон
   *
   * @param messagePart - Вариант сообщения
   * @param template - Шаблон сообщения
   */
  parseFromMessagePart(messagePart: any, template: any): any {
    // TODO: в данном случае вот так копировать нельзя, т.к. некоторые штуки не поддаются deep copy (File, Blob), это нужно переделать.
    //  сейчас это работа��т из-за того, что перед в��зовом этой функции файлы отсылаются на сервер и заменяются на URL
    const copiedMessagePart: any = cloneDeep(messagePart);
    let bodyJson: any = {};
    template = isUndefined(template) ? this.getDefault() : template;

    template.pureHtml =
      copiedMessagePart.type === MESSAGE_PART_TYPES.EMAIL &&
      (copiedMessagePart[MESSAGE_PART_TYPES.EMAIL].type === EMAIL_TYPES.HTML ||
        copiedMessagePart[MESSAGE_PART_TYPES.EMAIL].type === EMAIL_TYPES.BEE);
    template.replyType = copiedMessagePart[copiedMessagePart.type].replyType;
    template.subject =
      copiedMessagePart.type === MESSAGE_PART_TYPES.EMAIL ? copiedMessagePart[MESSAGE_PART_TYPES.EMAIL].subject : '';
    template.type = copiedMessagePart.type;

    // заполнение body и других параметров
    if (~[MESSAGE_PART_TYPES.POPUP_CHAT].indexOf(copiedMessagePart.type)) {
      template.body = copiedMessagePart[copiedMessagePart.type].body;
    } else if (~[MESSAGE_PART_TYPES.SDK_POPUP_CHAT].indexOf(copiedMessagePart.type)) {
      template.body = copiedMessagePart[copiedMessagePart.type].body;
    } else if (~[MESSAGE_PART_TYPES.POPUP_SMALL, MESSAGE_PART_TYPES.POPUP_BIG].indexOf(copiedMessagePart.type)) {
      template.body = copiedMessagePart[copiedMessagePart.type].body;
      if (
        ~[POPUP_REPLY_TYPES.PUSH, POPUP_REPLY_TYPES.BUTTON].indexOf(copiedMessagePart[copiedMessagePart.type].replyType)
      ) {
        /*NOTE Артем: "в body_json можно хранить что угодно и поэтому я решил хранить сразу в удобном формате. И еще потому что в чате нет методов преобразование объектов в разн��е нотации"*/
        bodyJson = {
          replyButtonText: copiedMessagePart[copiedMessagePart.type].replyButtonText,
          replyButtonAction: copiedMessagePart[copiedMessagePart.type].replyButtonAction || '',
        };
      }
    } else if (
      ~[
        MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
        MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
        MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
      ].indexOf(copiedMessagePart.type)
    ) {
      bodyJson = {
        blocks: [],
        footer: undefined, // тут undefined, а не null, потому что при JSON.stringify поле с null остаётся, а с undefined не попадает в итоговый результат
        params: {},
      };

      // NOTE Роман Е. Для поп-ап в SDK есть возможность кастомизировать push-уведомлен��е.
      // NOTE Тема push-��ведомления записывается в subject, a текст push-уведомления в body.
      if (copiedMessagePart.type === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        template.subject = copiedMessagePart[copiedMessagePart.type].subject;
      }

      template.body = copiedMessagePart[copiedMessagePart.type].body;

      // парсинг блоков
      let popupBlocks = copiedMessagePart[copiedMessagePart.type].bodyJson.blocks;

      for (let rowIndex = 0; rowIndex < popupBlocks.length; rowIndex++) {
        bodyJson.blocks[rowIndex] = [];

        for (let colIndex = 0; colIndex < popupBlocks[rowIndex].length; colIndex++) {
          bodyJson.blocks[rowIndex][colIndex] = [];

          for (let blockIndex = 0; blockIndex < popupBlocks[rowIndex][colIndex].length; blockIndex++) {
            bodyJson.blocks[rowIndex][colIndex][blockIndex] = this.popupBlockModel.parseToServerFormat(
              popupBlocks[rowIndex][colIndex][blockIndex],
            );
          }
        }
      }

      if (copiedMessagePart[copiedMessagePart.type].bodyJson.footer) {
        bodyJson.footer = this.popupBlockModel.parseToServerFormat(
          copiedMessagePart[copiedMessagePart.type].bodyJson.footer,
        );
      }

      // парсинг параметров п��п-апа
      /*NOTE Артем: "в body_json можно хранить что угодно и поэтому я решил хранить сразу в удобном формате. И еще потому что в чате нет методов преобразование    объектов в разные нотации"*/
      bodyJson.params = JSON.parse(JSON.stringify(copiedMessagePart[copiedMessagePart.type].bodyJson.params));

      if (
        copiedMessagePart[copiedMessagePart.type].bodyJson.params.backgroundImage &&
        !(copiedMessagePart[copiedMessagePart.type].bodyJson.params.backgroundImage instanceof File)
      ) {
        // если ссылка на картинку уже была в блоке - нужно её оставить
        bodyJson.params.backgroundImage = copiedMessagePart[
          copiedMessagePart.type
        ].bodyJson.params.backgroundImage.split(
          this.messageUtilsModel.getPathToImages(
            copiedMessagePart[copiedMessagePart.type].bodyJson.params.backgroundImage,
          ),
        )[1];
      } else {
        bodyJson.params.backgroundImage = '';
      }
    } else if (copiedMessagePart.type === MESSAGE_PART_TYPES.EMAIL) {
      template.body = copiedMessagePart.email[copiedMessagePart.email.type].body;
      if (copiedMessagePart[MESSAGE_PART_TYPES.EMAIL].type === EMAIL_TYPES.BEE) {
        bodyJson = copiedMessagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.BEE].bodyJson;
      }
      if (copiedMessagePart[MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled) {
        template.isUtmMarksEnabled = true;
        template.utmMarks = copiedMessagePart[MESSAGE_PART_TYPES.EMAIL].utmMarks;
      } else {
        template.isUtmMarksEnabled = false;
      }
    } else if (copiedMessagePart.type === MESSAGE_PART_TYPES.PUSH) {
      template.body = copiedMessagePart[MESSAGE_PART_TYPES.PUSH].body;
      template.subject = copiedMessagePart[MESSAGE_PART_TYPES.PUSH].subject;

      bodyJson = {
        click_action: copiedMessagePart[MESSAGE_PART_TYPES.PUSH].clickAction,
        icon: (
          copiedMessagePart[MESSAGE_PART_TYPES.PUSH].newIconUrl || copiedMessagePart[MESSAGE_PART_TYPES.PUSH].icon
        ).split(environment.userFilesUrl + '/push-icons/')[1],
      };
    } else if (copiedMessagePart.type === MESSAGE_PART_TYPES.SDK_PUSH) {
      template.body = copiedMessagePart[MESSAGE_PART_TYPES.SDK_PUSH].body;
      template.subject = copiedMessagePart[MESSAGE_PART_TYPES.SDK_PUSH].subject;

      bodyJson = {
        click_action: copiedMessagePart[MESSAGE_PART_TYPES.SDK_PUSH].clickAction,
      };
    }
    template.bodyJson = isString(bodyJson) ? bodyJson : JSON.stringify(bodyJson);

    // NOTE Роман Е. Для "Чат в SDK" и "Поп-ап в SDK" есть возможност�� отправить push-уведомление вместе с сообщение��.
    if (copiedMessagePart[copiedMessagePart.type].send_sdk_push) {
      template.send_sdk_push = copiedMessagePart[copiedMessagePart.type].send_sdk_push;
    }

    return template;
  }

  /**
   * Загрузка изображений
   */
  uploadImages(appId: any, messagePart: any): Promise<any> {
    if (messagePart.type === MESSAGE_PART_TYPES.PUSH && messagePart[MESSAGE_PART_TYPES.PUSH].newIcon) {
      return firstValueFrom(this.messageModel.uploadPushIcon(appId, messagePart[MESSAGE_PART_TYPES.PUSH].newIcon)).then(
        (icon: any) => {
          messagePart[MESSAGE_PART_TYPES.PUSH].newIconUrl = environment.userFilesUrl + '/push-icons/' + icon.filename;
          return messagePart;
        },
      );
    } else if (
      includes(
        [
          MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
          MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
          MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
        ],
        messagePart.type,
      )
    ) {
      let preprocessingTasks = [];
      let blockPopup = messagePart[messagePart.type];
      let popupBlocks = flattenDeep(blockPopup.bodyJson.blocks);

      blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

      preprocessingTasks.push(this.messagePartModel.saveAdditionalData(messagePart));

      for (let j = 0; j < popupBlocks.length; j++) {
        let popupBlock = popupBlocks[j];
        preprocessingTasks.push(this.popupBlockModel.saveAdditionalData(appId, popupBlock));
      }
      return Promise.all(preprocessingTasks).then(() => messagePart);
    } else {
      return Promise.resolve(messagePart);
    }
  }

  /**
   * Возвращает ширину и высоту для страницы с превью
   */
  getExternalPageSize(messageType: any): any {
    if (MESSAGE_PART_TYPES.EMAIL === messageType) {
      return [674, 650];
    } else if (MESSAGE_PART_TYPES.BLOCK_POPUP_BIG === messageType) {
      return [640, 550];
    } else if (MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL === messageType) {
      return [410, 520];
    } else if (MESSAGE_PART_TYPES.POPUP_BIG === messageType) {
      return [660, 620];
    } else if (MESSAGE_PART_TYPES.POPUP_SMALL === messageType) {
      return [410, 500];
    } else if (MESSAGE_PART_TYPES.POPUP_CHAT === messageType) {
      return [385, 255];
    } else if (MESSAGE_PART_TYPES.PUSH === messageType) {
      return [430, 200];
    } else if (MESSAGE_PART_TYPES.SDK_POPUP_CHAT === messageType) {
      return [462, 469];
    } else if (MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL === messageType) {
      return [414, 709];
    } else if (MESSAGE_PART_TYPES.SDK_PUSH === messageType) {
      return [462, 549];
    }
  }
}

(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('accept', accept);

  function accept() {
    return {
      restrict: 'A',
      require: ['?cqFile', '?ngModel'],
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controllers) {
      const cqFileController = controllers[0];
      const ngModelController = controllers[1];
      let acceptedExtensions = []; // допустимые типы файлов

      if (cqFileController && ngModelController) {
        iAttrs.$observe('accept', observe);
        ngModelController.$validators.accept = accept;
      }

      /**
       * Валидатор, проверяющий соответствие типа файла валидным типам файлов
       *
       * @param {File} modelValue Файл, помещённый в модель при помощи директивы cqFile
       * @return {Boolean}
       */
      function accept(modelValue) {
        if (ngModelController.$isEmpty(modelValue)) {
          return true;
        } else {
          const fileName = modelValue.name;
          const fileExtension = '.' + fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

          return acceptedExtensions.includes(fileExtension);
        }
      }

      /**
       * Получение массива валидных типов файлов из атрибута-строки
       *
       * @param {String} newValue Значение атрибута accept
       */
      function observe(newValue) {
        if (newValue) {
          acceptedExtensions = newValue.split(',').map((ext) => ext.replace(' ', ''));
        }

        // нужно вызывать валидацию принудительно, т.к. $validators могут отработать раньше $observe
        ngModelController.$validate();
      }
    }
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';

import { MessageWebhookParam } from '@http/message-part/message-part.types';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';

@Component({
  selector: 'cq-headers-editor',
  templateUrl: './headers-editor.component.html',
  styleUrls: ['./headers-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadersEditorComponent extends AbsCVAFormArrayBasedComponent<MessageWebhookParam, FormControl> {
  readonly control: FormArray<FormControl<MessageWebhookParam>> = this.fb.array<FormControl<MessageWebhookParam>>([]);

  readonly defaultHeaders: FormArray<FormControl<MessageWebhookParam>> = this.fb.array<
    FormControl<MessageWebhookParam>
  >([
    this.getNewControl({ key: 'Host', value: this.transloco.translate('headersEditorComponent.generatedOnSend') }),
    this.getNewControl({
      key: 'Content-Length',
      value: this.transloco.translate('headersEditorComponent.generatedOnSend'),
    }),
    this.getNewControl({ key: 'Content-Type', value: 'application/json' }),
  ]);

  constructor(private readonly fb: FormBuilder, private transloco: TranslocoService) {
    super();
  }

  addHeader() {
    this.control.push(this.getNewControl());
  }

  deleteHeader(index: number): void {
    this.control.removeAt(index);
  }

  getNewControl(val?: MessageWebhookParam): FormControl {
    if (!val) {
      val = { key: '', value: '' };
    }
    return this.fb.control<MessageWebhookParam>(val, {
      nonNullable: true,
    });
  }
}

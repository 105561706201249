(function () {
  'use strict';

  require('./plan-capability-paywall.controller');

  angular.module('myApp.planCapability').component('cqPlanCapabilityPaywall', cqPlanCapabilityPaywall());

  function cqPlanCapabilityPaywall() {
    return {
      bindings: {
        billingInfo: '=', // Информация о биллинге
        currentApp: '=', // Текущее приложение
        denialReason: '<?', // Информация о причинах отказа в доступе к фиче
        djangoUser: '=', // Текущий пользователь
        onRequestPerformed: '<?', // функция, которая выполнится при нажатии на кнопку в правом верхнем углу
      },
      controller: 'CqPlanCapabilityPaywallController',
      controllerAs: 'vm',
      template: require('./plan-capability-paywall.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'triggerChainGeneralStatisticsTableComponent'">
  <table class="table table-align-middle table-sticky-header position-sticky">
    <thead>
      <tr>
        <th>{{ t('headings.name') }}</th>
        <th>{{ t('headings.sent') }}</th>
        <th>{{ t('headings.read') }}</th>
        <th>{{ t('headings.replied') }}</th>
        <th>{{ t('headings.click') }}</th>
        <th>{{ t('headings.unsubscribe') }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        cq-trigger-chain-general-statistics-table-item
        [chainStep]="step"
        *ngFor="let step of chainSteps"
      ></tr>
    </tbody>
  </table>
</ng-container>

/**
 * Контроллер для модалки сохранения сегмента
 *
 * При создании модального окна в resolve можно передать modalWindowParams - объект следующего вида:
 *    {Object} currentApp - ID аппа
 *    {Object} filters - Фильтры
 *    {Object} segment - Выбранный егмента
 */
import { PSEUDO_SEGMENT_IDS } from '../../../../app/http/segment/segment.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.modals.saveSegment').controller('SaveSegmentModalController', SaveSegmentModalController);

  function SaveSegmentModalController(
    $translate,
    $uibModalInstance,
    toastr,
    carrotquestHelper,
    segmentModel,
    validationHelper,
    modalWindowParams,
  ) {
    var vm = this;

    vm.isSaveAsNew = false; // Сохранить сегмент как новый или внести изменения в текущий
    vm.modalWindowParams = modalWindowParams;
    vm.save = save;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      modalWindowParams.segment = angular.copy(modalWindowParams.segment);
      if (modalWindowParams.segment.id === PSEUDO_SEGMENT_IDS.ALL) {
        modalWindowParams.segment.id = null;
        modalWindowParams.segment.name = '';
      }
    }

    /**
     * Обработка нажатия на кнопку подтверждения
     *
     * @param isValid Валидация формы
     */
    function save(isValid) {
      if (isValid) {
        var params = {
          app: modalWindowParams.currentApp.id,
          filters: modalWindowParams.filters,
          name: modalWindowParams.segment.name,
        };
        //Сохранять сегмент как новый надо в случае, если не передали segmentId или было выбрано сохранить как новый
        if (!modalWindowParams.segment.id || vm.isSaveAsNew) {
          firstValueFrom(segmentModel.create(params)).then(createSuccess);
        } else {
          firstValueFrom(segmentModel.save(modalWindowParams.segment.id, params)).then(saveSuccess);
        }
      }

      function createSuccess(response) {
        trackCreateSegment();
        toastr.success(
          $translate.instant('modals.saveSegment.toasts.createSuccess', {
            segmentName: modalWindowParams.segment.name,
          }),
        );
        var defaultSegment = segmentModel.getDefault();
        defaultSegment.name = modalWindowParams.segment.name;
        defaultSegment.filters = modalWindowParams.filters;
        defaultSegment.id = response.data.id;
        $uibModalInstance.close(defaultSegment);
      }

      function saveSuccess() {
        trackSaveSegment();
        toastr.success(
          $translate.instant('modals.saveSegment.toasts.saveSuccess', { segmentName: modalWindowParams.segment.name }),
        );
        var defaultSegment = segmentModel.getDefault();
        defaultSegment.name = modalWindowParams.segment.name;
        defaultSegment.filters = modalWindowParams.filters;
        defaultSegment.id = modalWindowParams.segment.id;
        $uibModalInstance.close(defaultSegment);
      }
    }

    /**
     * Трек создания сегмента
     */
    function trackCreateSegment() {
      carrotquestHelper.track('Пользователи - создал сегмент', {
        App: modalWindowParams.currentApp.name,
        Сегмент: modalWindowParams.segment.name,
      });
    }

    /**
     * Трек сохранения сегмента
     */
    function trackSaveSegment() {
      carrotquestHelper.track('Пользователи - сохранил сегмент', {
        App: modalWindowParams.currentApp.name,
        Сегмент: modalWindowParams.segment.name,
      });
    }
  }
})();

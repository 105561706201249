(function () {
  'use strict';

  require('./popup-button-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupButtonBlockSettings', cqPopupButtonBlockSettings());

  function cqPopupButtonBlockSettings() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        eventTypes: '=', // типы событий
        messagePartType: '=', // тип сообщения
        popupBlockParams: '=', // настройки блока поп-апа
        setErrorHandler: '&?', // функция для установки обработчика ошибок
      },
      controller: 'CqPopupButtonBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-button-block-settings.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { OAuthButtonModule } from '@panel/app/pages/integrations/partials/o-auth-button/o-auth-button.module';

import { CalendlyComponent } from './calendly.component';

@NgModule({
  declarations: [CalendlyComponent],
  exports: [CalendlyComponent],
  imports: [CommonModule, TranslocoModule, UIRouterModule, OAuthButtonModule, IntegrationNameControlModule],
})
export class CalendlyModule {}

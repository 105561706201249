<ng-container *transloco="let t; read: 'conversationStatisticSourceSelect'">
  <div [cqShowError]="control">
    <ng-select
      [formControl]="control"
      [items]="sources"
      [multiple]="true"
      bindLabel="name"
      [selectableGroupAsModel]="false"
      [closeOnSelect]="false"
    >
      <ng-template
        ng-multi-label-tmp
        let-items="items"
        let-clear="clear"
      >
        <div class="ng-value">
          <span class="ng-value-label">{{ 'models.conversationStatistics.sources.' + items[0] | transloco }}</span>
          <span
            class="ng-value-icon right"
            (click)="clear(items[0])"
            aria-hidden="true"
            >×</span
          >
        </div>
        <div
          class="ng-value"
          *ngIf="items.length > 1"
        >
          <span class="ng-value-label">{{ t('more', { count: items.length - 1 }) }}</span>
        </div>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <div class="custom-control custom-checkbox">
          <input
            class="custom-control-input"
            type="checkbox"
            [ngModel]="item$.selected"
            [disabled]="isDisabled(item$.value)"
          />
          <span
            class="custom-control-label"
            [ngbTooltip]="t('tooltip')"
            [disableTooltip]="!isDisabled(item$.value)"
            container="body"
            placement="bottom-left"
          >
            {{ 'models.conversationStatistics.sources.' + item | transloco }}
          </span>
        </div>
      </ng-template>
    </ng-select>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('errors.notSelectedSources') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { ColorService } from '@panel/app/services/color/color.service';

@Component({
  selector: 'cq-carrot-messenger-collapsed-frame',
  templateUrl: './carrot-messenger-collapsed-frame.component.html',
  styleUrls: ['./carrot-messenger-collapsed-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CarrotMessengerCollapsedFrameComponent {
  @Input({ required: true })
  app!: App;

  protected readonly environment = environment;

  constructor(private readonly colorService: ColorService) {}

  getRgbFromHex(hex: string): string | null {
    return this.colorService.getRgbFromHex(hex);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';

import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

/**
 * Компонент поля ввода для URL.
 */
@Component({
  selector: 'cq-url-input',
  templateUrl: './url-input.component.html',
  styleUrls: ['./url-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UrlInputComponent extends AbstractCVAControl<string> {
  control = new GenericFormControl<string>(null, Validators.required);
}

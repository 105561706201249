import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Компонент для работы с контентом шага в стартергайде */
@Component({
  selector: 'cq-step-content',
  templateUrl: './step-content.component.html',
  styleUrls: ['./step-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepContentComponent {}

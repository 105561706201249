import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { App } from '@http/app/app.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AmocrmMappingForm } from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import { AmocrmIntegrationFormData } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { Message } from '@http/message/message.types';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { LeadBot } from '@http/chat-bot/types/chat-bot-internal.types';

/**
 * Компонент для работы со сделкой и передачей данных в AmoCRM
 */

@Component({
  selector:
    'cq-amocrm-data-to-leads[chatBots][controlLeadsEventTypeIds][controlLeadDefaultPipeline][controlLeadDefaultStatus][controlLeadEventTypes][controlLeadProps][currentApp][integrationId][isShowedFromModal][triggerMessages]',
  templateUrl: './amocrm-data-to-leads.component.html',
  styleUrls: ['./amocrm-data-to-leads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmDataToLeadsComponent {
  /** Список с чат-ботами */
  @Input()
  chatBots!: LeadBot[];

  /** ID воронки, в которую будет добавлена сделка */
  @Input()
  controlLeadDefaultPipeline!: GenericFormControl<AmocrmIntegrationFormData['settings']['leadDefaultPipeline']>;

  /** Контрол для ID статуса сделки в amoCRM, с которым сделка будет создана */
  @Input()
  controlLeadDefaultStatus!: GenericFormControl<AmocrmIntegrationFormData['settings']['leadDefaultStatus']>;

  /** ID событий, после срабатывания которых создаётся сделка в AmoCRM */
  @Input()
  controlLeadsEventTypeIds!: GenericFormControl<AmocrmIntegrationFormData['settings']['leadsEventTypeIds']>;

  /** Контрол с событиями, которые передаются из лида в сделку amoCRM */
  @Input()
  controlLeadEventTypes!: GenericFormArray<AmocrmMappingForm>;

  /** Контрол со свойствами, которые передаются из лида в сделку amoCRM */
  @Input()
  controlLeadProps!: GenericFormArray<AmocrmMappingForm>;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** ID текущей интеграции */
  @Input()
  integrationId!: string;

  /** Показываются ли компоненты из модального окна создания интеграции */
  @Input()
  isShowedFromModal!: boolean;

  /** Список с триггерными сообщениями */
  @Input()
  triggerMessages!: Message[];

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(@Inject(IntegrationService) private readonly integrationService: IntegrationService) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { AmocrmLeadTagsReadonlyModule } from '@panel/app/pages/integrations/content/amocrm/lead-tags-readonly/amocrm-lead-tags-readonly.module';

import { IntegrationTriggerMessagesComponent } from './integration-trigger-messages.component';

@NgModule({
  declarations: [IntegrationTriggerMessagesComponent],
  exports: [IntegrationTriggerMessagesComponent],
  imports: [AmocrmLeadTagsReadonlyModule, CommonModule, TranslocoModule, UIRouterModule],
})
export class IntegrationTriggerMessagesModule {}

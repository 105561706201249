import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AccessDenialAlertModule } from '@panel/app/partials/access-denial/alert/access-denial-alert.module';
import { TagFiltersComponent } from '@panel/app/partials/filters/filters/tag-filters/tag-filters.component';
import { UserTagModule } from '@panel/app/partials/user-tag/user-tag.module';

@NgModule({
  declarations: [TagFiltersComponent],
  exports: [TagFiltersComponent],
  imports: [CommonModule, NgbTooltipModule, TranslocoModule, NgbCollapseModule, AccessDenialAlertModule, UserTagModule],
})
export class TagFiltersModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { TextBlockEditorComponent } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/text-block-editor/text-block-editor.component';
import { QuillTextEditorModule } from '@panel/app/partials/quill-text-editor/quill-text-editor.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [TextBlockEditorComponent],
  exports: [TextBlockEditorComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, FormsModule, ReactiveFormsModule, QuillTextEditorModule],
})
export class TextBlockEditorModule {}

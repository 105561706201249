import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { concat, Observable, switchMap } from 'rxjs';
import { debounceTime, finalize, map, takeUntil, tap } from 'rxjs/operators';

import { SavedReplyModel } from '@http/saved-reply/saved-reply.model';
import { EditSavedReplyModalComponent } from '@panel/app/pages/conversations-settings/shared/components/edit-saved-reply-modal/edit-saved-reply-modal.component';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-saved-replies',
  templateUrl: './saved-replies.component.html',
  styleUrls: ['./saved-replies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SavedRepliesComponent implements OnInit {
  /** текущее приложение */
  @Input() currentApp!: any;
  /** сохранённые ответы */
  @Input() savedReplies!: any[];

  /**
   * Максимальная длинна поисковай фразы
   *
   * @type {Number}
   */
  protected MAX_SEARCH_LENGTH: number = 255;

  /**
   * Минимальная длинна поисковай фразы
   *
   * @type {Number}
   */
  protected MIN_SEARCH_LENGTH: number = 3;

  protected isApiRequestPerformed: boolean = false;
  /** Флаг, который показывает находимся ли мы в режиме поиска */
  protected isSearch: boolean = false;
  /** Фраза поиска по сохранённым ответам */

  /** Список найденных сохранённых ответов */
  protected searchedSavedReplies: any[] = [];
  /** Фраза поиска по сохранённым ответам */
  protected searchPattern: string = '';

  protected savedRepliesSearch = this.fb.control('');

  constructor(
    protected readonly translocoService: TranslocoService,
    protected readonly toastr: ToastService,
    protected readonly savedReplyModel: SavedReplyModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    private fb: FormBuilder,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly cdr: ChangeDetectorRef,
    private readonly destroyService: DestroyService,
  ) {}

  ngOnInit(): void {
    this.isApiRequestPerformed = false;
    this.isSearch = false; // Флаг, который показывает находимся ли мы в режиме поиска
    this.searchedSavedReplies = []; // Список найденных сохранённых ответов
    this.searchPattern = ''; // Фраза поиска по сохранённым ответам

    // Настройка задержки (debounce) и обработчика изменений
    this.savedRepliesSearch.valueChanges
      .pipe(takeUntil(this.destroyService), debounceTime(500)) // задержка 500 мс
      .subscribe((value) => {
        this.search(this.savedRepliesSearch.getRawValue());
      });
  }

  /**
   * Смена приоритета сохранённого ответа
   *
   * @param newVal Содержит сохранённый ответ и новый порядок
   */
  changeSavedReplyOrder(newVal: any): void {
    this.isApiRequestPerformed = true;

    this.savedReplyModel
      .changeOrder(this.currentApp.id, newVal.savedReply.id, newVal.newOrder)
      .pipe(
        takeUntil(this.destroyService),
        switchMap(() => {
          return this.getList(null);
        }),
        finalize(() => {
          this.isApiRequestPerformed = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe((response: any) => {
        this.savedReplies = response;
      });
  }

  /**
   * Сбрасывание данных, связанных с поиском
   * HACK Небольшой костыль для того, чтобы при клике на кресик у поля поиска не срабатывало два запроса на получение списка ответов
   */
  disableSearch(): void {
    this.isSearch = false;
    this.savedRepliesSearch.setValue('');
    this.searchPattern = '';
    this.searchedSavedReplies = [];
  }

  /**
   * Получение списка сохранённых ответов
   *
   * @param searchPhrase — Фраза для поиска
   * @returns {Object}
   */
  getList(searchPhrase: any): Observable<any> {
    return this.savedReplyModel.getList(this.currentApp.id, searchPhrase);
  }

  /**
   * Открытие модалки создания сохранённого ответа
   */
  openCreateSavedReplyModal(): void {
    const defaultSavedReply = this.savedReplyModel.getDefault();

    let modal = this.modalHelperService.open(EditSavedReplyModalComponent);

    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
      savedReply: defaultSavedReply,
    };

    modal.result.then((result: any) => {
      concat(
        this.createSavedReply(result.reply, result.addToTheEnd).pipe(
          tap((savedReply: any) => {
            this.isApiRequestPerformed = true;
            this.trackCreateSavedReply(savedReply.header);
            this.toastr.success(this.translocoService.translate('settings.savedReplies.toasts.created'));
          }),
        ),
        this.getList(null).pipe(
          tap((response: any) => {
            this.savedReplies = response;
            if (this.isSearch) {
              this.search(this.savedRepliesSearch.getRawValue());
            }
          }),
        ),
      )
        .pipe(
          takeUntil(this.destroyService),
          finalize(() => {
            this.isApiRequestPerformed = false;
            this.cdr.detectChanges();
          }),
        )
        .subscribe();
    });
  }

  private createSavedReply(createdSavedReply: any, addToTheEnd?: any): Observable<any> {
    return this.savedReplyModel.create(
      this.currentApp.id,
      createdSavedReply.body,
      createdSavedReply.header,
      addToTheEnd,
    );
  }

  /**
   * Открытие модалки редактирования сохранённого ответа
   */
  openEditSavedReplyModal(savedReply: any): void {
    let savedReplyCopy = { ...savedReply };

    let modal = this.modalHelperService.open(EditSavedReplyModalComponent);

    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
      savedReply: savedReplyCopy,
    };

    modal.result.then((result: any) => {
      concat(
        this.editSavedReply(result.reply).pipe(
          tap(() => {
            this.isApiRequestPerformed = true;
            this.toastr.success(this.translocoService.translate('settings.savedReplies.toasts.saved'));
          }),
        ),
        this.getList(null).pipe(
          tap((response: any) => {
            this.savedReplies = response;
            if (this.isSearch) {
              this.search(this.savedRepliesSearch.getRawValue());
            }
          }),
        ),
      )
        .pipe(
          takeUntil(this.destroyService),
          finalize(() => {
            this.isApiRequestPerformed = false;
            this.cdr.detectChanges();
          }),
        )
        .subscribe();
    });
  }

  private editSavedReply(editedSavedReply: any): Observable<any> {
    return this.savedReplyModel.edit(
      this.currentApp.id,
      editedSavedReply.id,
      editedSavedReply.body,
      editedSavedReply.header,
    );
  }

  /**
   * Удаление сохранённого ответа
   *
   * @param savedReply Сохранённый ответ
   */
  removeSavedReply(savedReply: any): void {
    let modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('settings.savedReplies.removeSavedReplyModal.heading'),
        body: `
          <div class="margin-bottom-20">${
            savedReply.header
              ? this.translocoService.translate('settings.savedReplies.removeSavedReplyModal.body.withHeader', {
                  header: savedReply.header,
                })
              : this.translocoService.translate('settings.savedReplies.removeSavedReplyModal.body.withoutHeader')
          }</div>
          <div class="card padding-20 no-margin-bottom">
            <div class="text-break">${savedReply.body}</div>
          </div>
        `,
        confirmButtonText: this.translocoService.translate('general.remove'),
      })
      .open(ConfirmModalComponent);

    modal.result.then(() => {
      concat(
        this.savedReplyModel.remove(savedReply.id).pipe(
          tap(() => {
            this.isApiRequestPerformed = true;
            this.toastr.success(this.translocoService.translate('settings.savedReplies.toasts.deleted'));
          }),
        ),
        this.getList(null).pipe(
          tap((response: any) => {
            this.savedReplies = response;
            if (this.isSearch) {
              this.search(this.savedRepliesSearch.getRawValue());
            }
          }),
        ),
      )
        .pipe(
          takeUntil(this.destroyService),
          finalize(() => {
            this.isApiRequestPerformed = false;
            this.cdr.detectChanges();
          }),
        )
        .subscribe();
    });
  }

  /**
   * Сбросить поиск и получить актуальный список ответов
   */
  resetSearch(): void {
    this.disableSearch();
    this.isApiRequestPerformed = true;

    this.getList(null)
      .pipe(
        takeUntil(this.destroyService),
        finalize(() => {
          this.isApiRequestPerformed = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe((savedReplies: any) => {
        this.savedReplies = savedReplies;
      });
  }

  /**
   * Поиск сохранённых ответов
   *
   * @param isValid - Валидность формы
   * @param searchPhrase - Фраза для поиска
   */
  search(searchPhrase: any): void | Observable<any> {
    if (searchPhrase) {
      this.getList(searchPhrase)
        .pipe(
          takeUntil(this.destroyService),
          map((savedReplies: any) => this.searchSuccess(savedReplies, searchPhrase)),
        )
        .subscribe();
    } else {
      this.resetSearch();
    }
  }

  private searchSuccess(savedReplies: any, searchPhrase: any): void {
    this.isSearch = true;
    this.searchPattern = searchPhrase;
    this.searchedSavedReplies = savedReplies;
    this.cdr.detectChanges();
  }

  /**
   * Трек создания нового сохранённого ответа
   *
   * @param savedReplyHeader — Название сохранённого ответа
   */
  trackCreateSavedReply(savedReplyHeader: any): void {
    this.carrotquestHelper.track('Сохраненные ответы - создан новый ответ', {
      'Название ответа': savedReplyHeader,
    });
  }
}

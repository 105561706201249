(function () {
  'use strict';

  angular.module('myApp.users').controller('CqLeadsImportTypesController', CqLeadsImportTypesController);

  function CqLeadsImportTypesController(PROJECT_NAME) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.PROJECT_NAME = PROJECT_NAME;
    }
  }
})();

<ng-container *transloco="let t; read: 'downgradeAbilitiesComponent'">
  <div class="p-20 border-bottom">
    <p class="d-flex font-weight-bold mb-15">
      <span>
        <i class="cqi cqi-sm cqi-triangle-exclamation text-warning mb-5 mr-10"></i>
      </span>
      <ng-container *ngIf="data.addons.length === 0; else tariffAndModule">
        {{ t('title.warningTariff') }}
      </ng-container>
      <ng-template #tariffAndModule>
        {{ t('title.warningTariffAndModule') }}
      </ng-template>
    </p>
    <div class="ml-25">
      <div>{{ t('whatChange') }}</div>
      <table class="activities mb-5">
        <!-- Активности количественные -->
        <tr *ngFor="let counterActivity of downgradeAbilitiesService.counterActivities$ | async">
          <td class="w-250 align-top">
            <p class="mb-0">
              {{
                t('activities.' + counterActivity.nameTranslated + '.message', {
                  activityTotal: counterActivity.activeAndAvailable[0]
                })
              }}
            </p>
            <small class="text-muted">
              {{ t('defaultNotes.noteCounterBefore') }}
            </small>
          </td>
          <td class="align-top">
            <span>
              <i class="cqi cqi-sm cqi-arrow-right mx-15"></i>
            </span>
          </td>
          <td class="w-250 align-top">
            <p class="mb-0">
              {{
                t('activities.' + counterActivity.nameTranslated + '.message', {
                  activityTotal: counterActivity.activeAndAvailable[1]
                })
              }}
            </p>
            <small class="text-muted">
              {{
                t('activities.' + counterActivity.nameTranslated + '.noteAfter', {
                  activityTotal: counterActivity.activeAndAvailable[1]
                })
              }}
            </small>
          </td>
        </tr>

        <!-- Активности вкл/выкл -->
        <tr *ngFor="let booleanActivities of downgradeAbilitiesService.booleanActivities$ | async">
          <td class="w-250 align-top">
            <p class="mb-0">
              {{ t('activities.' + booleanActivities.nameTranslated + '.message') }}
            </p>
            <small class="text-muted">
              {{ t('defaultNotes.noteBooleanBefore') }}
            </small>
          </td>
          <td class="align-top">
            <span>
              <i class="cqi cqi-sm cqi-arrow-right mx-15"></i>
            </span>
          </td>
          <td class="w-250 align-top">
            <p class="mb-0">
              <s>
                {{ t('activities.' + booleanActivities.nameTranslated + '.message') }}
              </s>
            </p>
            <small class="text-muted">
              {{ t('defaultNotes.noteBooleanAfter') }}
            </small>
          </td>
        </tr>
      </table>
      <p>
        {{ t('whatElse') }}
        <a
          href="#"
          (click)="activeModal.dismiss()"
        >
          {{ t('selectAnother') }}
        </a>
      </p>
    </div>
  </div>
</ng-container>

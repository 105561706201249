/**
 * Валидатор для HEX цвета
 */
(function () {
  'use strict';

  angular.module('myApp.directives.hexColorValidation').directive('cqHexColor', cqHexColor);

  function cqHexColor() {
    return {
      priority: 2,
      restrict: 'A',
      require: 'ngModel',
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$formatters.push(formatter);
      controller.$parsers.push(parser);
      controller.$validators.hexColor = hexColor;

      /**
       * Приведение значения модели к верхнему регистру для вывода
       *
       * @param {String} modelValue Значение из модели
       * @returns {String}
       */
      function formatter(modelValue) {
        if (angular.isDefined(modelValue)) {
          return modelValue.toUpperCase();
        } else {
          return modelValue;
        }
      }

      /**
       * Валидация значения input
       *
       * @param modelValue Значение, которое записано в модель
       * @param viewValue Значение, которое отображается в input
       * @returns {Boolean} Если строка является HEX цветом - вернётся true, иначе false
       */
      function hexColor(modelValue, viewValue) {
        // делаем валидацию только тогда, когда значение, которое будет записано в модель не пустое
        return controller.$isEmpty(modelValue) || /^#[0-9a-f]{6}$/i.test(modelValue);
      }

      /**
       * Парсер, который не даёт вводить ничего, HEX цвета
       *
       * @param {String} viewValue Значение, переданное из представления
       * @returns {String} Цвет в HEX формате
       */
      function parser(viewValue) {
        if (viewValue) {
          var upperCasedValue = viewValue.toUpperCase();

          upperCasedValue = upperCasedValue.replace(/[^#0-9a-f]/gi, '').slice(0, 7);

          if (upperCasedValue != viewValue) {
            controller.$setViewValue(upperCasedValue);
            controller.$render();
          }

          // если пользователь ввёл 3 буквы в цвете - надо их удвоить перед отправкой в модель, т.к. 3 буквы - тоже валидный цвет
          if (/^#[0-9a-f]{3}$/gi.test(upperCasedValue)) {
            upperCasedValue = upperCasedValue.replace(/[0-9a-f]/gi, duplicateLetter);
          }

          // в модель всегда уходит значение в нижнем регистре
          return upperCasedValue.toLowerCase();
        } else {
          return viewValue;
        }

        /**
         * Удваивает буквы
         *
         * @param {String} letter - буква
         * @returns {String} - удвоенная буква
         */
        function duplicateLetter(letter) {
          return letter + letter;
        }
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { KnowledgeBaseActivateComponent } from './knowledge-base-activate.component';

@NgModule({
  declarations: [KnowledgeBaseActivateComponent],
  imports: [CommonModule, TranslocoModule, SharedModule],
  exports: [KnowledgeBaseActivateComponent],
})
export class KnowledgeBaseActivateModule {}

<ng-container *transloco="let t; read: 'plansComparisonComponent'">
  <div class="d-flex align-items-center mb-20 px-20">
    <h2 class="mb-0">{{ t('heading') }}</h2>
    <cq-switch-new
      class="ml-auto mr-5"
      [ngModel]="showOnlyDifference"
      (ngModelChange)="showOnlyDifference = !showOnlyDifference"
    ></cq-switch-new>
    {{ t('showOnlyDifference') }}
  </div>

  <ng-container *ngFor="let block of infoBlocks">
    <div class="card">
      <div class="card-header">
        <h3 class="m-0">{{ getTranslateForBlockTitle(block) }}</h3>
      </div>

      <div class="card-body p-0">
        <table class="table table-fixed table-borderless table-hover table-align-middle">
          <tbody>
            <ng-container *ngIf="block === INFO_BLOCK.ANALYTICS">
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'funnels.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'funnels.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'eventFrequencyReport.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'eventFrequencyReport.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'statisticsManualSendingsTriggeredCampaigns.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="block === INFO_BLOCK.CHAT">
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'liveChat.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'liveChat.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'teamInbox.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'teamInbox.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'conversationChannels.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'conversationChannels.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="20"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="50"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="50"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'manualConversationAssignment.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'conversationNotes.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'conversationNotes.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="
                      getTranslateForBlockPoint(block, 'autoRepliesDuringWorkingAndNonWorkingHours.tooltip')
                    "
                    >{{ getTranslateForBlockPoint(block, 'autoRepliesDuringWorkingAndNonWorkingHours.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'mobileAppForOperators.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'mobileAppForOperators.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'generalReport.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'generalReport.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'teamPerformanceReport.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'teamPerformanceReport.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING"
                    [rowCount]="3"
                  ></cq-feature-addon>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING"
                    [rowCount]="3"
                  ></cq-feature-addon>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'operatorGroups.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'operatorGroups.text') }}</span
                  >
                </td>
                <td class="border-right"></td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td></td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'automatedConversationAssignment.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'automatedConversationAssignment.text') }}</span
                  >
                </td>
                <td class="border-right"></td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td></td>
              </tr>
            </ng-container>

            <ng-container *ngIf="block === INFO_BLOCK.CHATBOTS">
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'activeLeadBots.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'activeLeadBots.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="1"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="2"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="3"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'expansionTo40ActiveLeadBots.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'expansionTo40ActiveLeadBots.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'launchToSpecificSegments.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'launchToSpecificSegments.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'collectingLeadsRepliesForSegmentation.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'collectingLeadsRepliesForSegmentation.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'sendingCollectedDataToTheMailAndCrmSystems.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'welcomeBot.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'welcomeBot.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
                <td>
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'telegramBots.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'telegramBots.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.TELEGRAM_BOT"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.TELEGRAM_BOT"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
                <td>
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.TELEGRAM_BOT"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="block === INFO_BLOCK.INTEGRATION">
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'sendingDataViaWebhook.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'sendingDataViaWebhook.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'launchJavaScriptOnWebsite.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'launchJavaScriptOnWebsite.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'emailInbox.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'emailInbox.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'messengers.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'messengers.text') }}</span
                  >
                  <div class="text-muted">
                    {{ getTranslateForBlockPoint(block, 'messengers.description') }}
                  </div>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'socialMedia.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'socialMedia.text') }}</span
                  >
                  <div class="text-muted">
                    {{ getTranslateForBlockPoint(block, 'socialMedia.description') }}
                  </div>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'crmSystems.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'crmSystems.text') }}</span
                  >
                  <div class="text-muted">
                    {{ getTranslateForBlockPoint(block, 'crmSystems.description') }}
                  </div>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'cms.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'cms.text') }}</span
                  >
                  <div class="text-muted">
                    {{ getTranslateForBlockPoint(block, 'cms.description') }}
                  </div>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'analyticServices.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'analyticServices.text') }}</span
                  >
                  <div class="text-muted">
                    {{ getTranslateForBlockPoint(block, 'analyticServices.description') }}
                  </div>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'ticketSystems.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'ticketSystems.text') }}</span
                  >
                  <div class="text-muted">
                    {{ getTranslateForBlockPoint(block, 'ticketSystems.description') }}
                  </div>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'otherServices.text') }}</span>
                  <div class="text-muted">
                    {{ getTranslateForBlockPoint(block, 'otherServices.description') }}
                  </div>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'sendingDataFromThirdPartyServices.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="block === INFO_BLOCK.KNOWLEDGE_BASE">
              <tr>
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'defaultNumberOfArticles.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="30"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value
                    [value]="'models.billingInfo.billingGeneral.unlimited' | transloco"
                  ></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="30"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'unlimitedNumberOfArticles.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING"
                    [rowCount]="2"
                  ></cq-feature-addon>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING"
                    [rowCount]="2"
                  ></cq-feature-addon>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'seoSettings.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'seoSettings.text') }}</span
                  >
                </td>
                <td class="border-right"></td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td></td>
              </tr>
            </ng-container>

            <ng-container *ngIf="block === INFO_BLOCK.MANUAL_SENDING">
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'spotSending.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'spotSending.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="
                      getTranslateForBlockPoint(block, 'bulkSendingToAnUnlimitedNumberOfRecipients.tooltip')
                    "
                    >{{ getTranslateForBlockPoint(block, 'bulkSendingToAnUnlimitedNumberOfRecipients.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'monthlyQuotaOfMails.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'monthlyQuotaOfMails.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value
                    *ngIf="getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.CONVERSATION, expectedVisitors)"
                    [value]="getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.CONVERSATION, expectedVisitors)"
                  ></cq-feature-value>
                  <cq-custom-price-cap
                    *ngIf="!getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.CONVERSATION, expectedVisitors)"
                    [displayFlex]="true"
                  ></cq-custom-price-cap>
                </td>
                <td class="border-right">
                  <cq-feature-value
                    *ngIf="getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.SUPPORT, expectedVisitors)"
                    [value]="getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.SUPPORT, expectedVisitors)"
                  ></cq-feature-value>
                  <cq-custom-price-cap
                    *ngIf="!getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.SUPPORT, expectedVisitors)"
                    [displayFlex]="true"
                  ></cq-custom-price-cap>
                </td>
                <td>
                  <cq-feature-value
                    *ngIf="getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.MARKETING, expectedVisitors)"
                    [value]="getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.MARKETING, expectedVisitors)"
                  ></cq-feature-value>
                  <cq-custom-price-cap
                    *ngIf="!getEmailsCountByExpectedVisitors(UNIQUE_BILLING_PLAN_ID.MARKETING, expectedVisitors)"
                    [displayFlex]="true"
                  ></cq-custom-price-cap>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'visualEmailBuilder.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'visualEmailBuilder.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.BEE_EDITOR_V2"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.BEE_EDITOR_V2"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="block === INFO_BLOCK.TRIGGERED_MESSAGES">
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'activeMessagesOfAnyType.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'activeMessagesOfAnyType.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="1"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="3"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="30"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'expansionTo100ActiveMessages.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'expansionTo100ActiveMessages.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'launchToSpecificSegments.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'launchToSpecificSegments.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'collectingContactDataForSegmentation.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'collectingContactDataForSegmentation.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'sendingCollectedDataToTheMailAndCrmSystems.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'popUpBuilder.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *ngIf="!showOnlyDifference">
                <td class="border-right">
                  <span>{{ getTranslateForBlockPoint(block, 'triggeredMessageTemplates.text') }}</span>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'abTesting.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'abTesting.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'controlGroups.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'controlGroups.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="false"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'visualEmailBuilder.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'visualEmailBuilder.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.BEE_EDITOR_V2"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
                <td class="border-right">
                  <cq-feature-addon
                    [addon]="BILLING_ADD_ONS.BEE_EDITOR_V2"
                    [rowCount]="1"
                  ></cq-feature-addon>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="block === INFO_BLOCK.LEADS_DATABASE">
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'eCRM.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'eCRM.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr *cqHideInUs>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'automatedContactDataCollection.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'automatedContactDataCollection.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'websiteVisitorsActivityTracking.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'websiteVisitorsActivityTracking.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'segmentationBasedOnCollectedData.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'segmentationBasedOnCollectedData.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
              <tr>
                <td class="border-right">
                  <span
                    class="dashed"
                    placement="top-left"
                    [ngbTooltip]="getTranslateForBlockPoint(block, 'importAndExportTheLeadsDatabase.tooltip')"
                    >{{ getTranslateForBlockPoint(block, 'importAndExportTheLeadsDatabase.text') }}</span
                  >
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td class="border-right">
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
                <td>
                  <cq-feature-value [value]="true"></cq-feature-value>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</ng-container>

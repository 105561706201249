import { ValidatorFn, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

import { BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

const propertyKeyNameValidators: ValidatorFn[] = [Validators.required];
const propertyBodyValidators: ValidatorFn[] = [Validators.required];

export class BotPropertyActionForm extends BaseBotActionForm {
  get allTouchedChanges$() {
    return combineLatest([
      extractTouchedChanges(this.controls.keyName).pipe(startWith(this.controls.keyName.touched)),
      extractTouchedChanges(this.controls.body).pipe(startWith(this.controls.body.touched)),
    ]).pipe(map(([t1, t2]) => t1 && t2));
  }

  getCustomControls(action: ChatBotAction): CustomControlsWithBodyJsonRequired {
    return {
      body: new GenericFormControl(action.body || null, propertyBodyValidators),
      bodyJson: new GenericFormGroup({}),
      keyName: new GenericFormControl(action.keyName || null, propertyKeyNameValidators),
    };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AmocrmCreateNewFieldModalModule } from '@panel/app/pages/integrations/content/amocrm/create-new-field-modal/create-new-field-modal.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmFieldsSelectComponent } from './amocrm-fields-select.component';

@NgModule({
  declarations: [AmocrmFieldsSelectComponent],
  exports: [AmocrmFieldsSelectComponent],
  imports: [
    AmocrmCreateNewFieldModalModule,
    CommonModule,
    NgbModalModule,
    NgSelectModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
  ],
})
export class AmocrmFieldsSelectModule {}

import { Container } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { TextStyleForRender } from '@panel/app/services/canvas/common/render/canvas-render.types';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
  TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

export class FilterMatchView extends AbsInteractiveBlockPartView<TRIGGER_CHAIN_BLOCK_TYPE.FILTER> {
  type: INTERACTIVE_BLOCK_PART = INTERACTIVE_BLOCK_PART.FILTER_MATCH;

  hasConnection(): boolean {
    return this.stepView.data.nextStepConnected;
  }

  renderContent(): Container {
    let elementsForRender: Container[] = [];

    elementsForRender.push(this.renderValue());

    const el = ElementBuilder.wrapElementsInRectangle(elementsForRender, {
      background: {
        sideTextureName: 'background-success950-272-side',
        innerTextureName: 'background-success950-272-inner',
      },
      placement: {
        alignItems: 'center',
        justifyContent: 'start',
        padding: {
          x: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
          y: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
        },
      },
    });

    this.addAndPositionConnectionPoint(el);

    return el;
  }

  private renderValue(): Container {
    let elementsForRender: Container[] = [];

    elementsForRender.push(this.renderDescription());

    return ElementBuilder.wrapElementsInRectangle(elementsForRender, {
      placement: {
        flexDirection: 'column',
      },
    });
  }

  private renderDescription(): Container {
    let icon = this.renderDescriptionIcon();
    let text = this.renderDescriptionText();

    return ElementBuilder.wrapElementsInRectangle([icon, text], {
      placement: {
        marginBetweenCols: 5,
      },
    });
  }

  private renderDescriptionText(): Container {
    let text: string = this.transloco.translate(
      `interactiveBlockPart.filterMatch.text.${this.stepView.data.logicalOperation}`,
    );
    let style: TextStyleForRender = {
      color: HEX_COLOR.SUCCESS_400,
      wordWrap: true,
      wordWrapWidth: TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
    };

    return CanvasRenderService.renderText(text, style);
  }

  private renderDescriptionIcon(): Container {
    let iconCode = UNICODE_ICON['cqi-check-circle-o'];
    let style: TextStyleForRender = {
      color: HEX_COLOR.SUCCESS_400,
      fontSize: 15,
    };

    return CanvasRenderService.renderIcon(iconCode, style);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { MomentModule } from 'ngx-moment';

import { UserConsentModalComponent } from '@panel/app/partials/modals/user-consent-modal/user-consent-modal.component';

@NgModule({
  declarations: [UserConsentModalComponent],
  exports: [UserConsentModalComponent],
  imports: [TranslocoModule, CommonModule, MomentModule],
})
export class UserConsentModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { RequisitesComponent } from './requisites.component';

@NgModule({
  declarations: [RequisitesComponent],
  exports: [RequisitesComponent],
  imports: [CommonModule, TranslocoModule],
})
export class RequisitesModule {}

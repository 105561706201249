import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConversationPartPreviewSpaceComponent } from './conversation-part-preview-space.component';

@NgModule({
  declarations: [ConversationPartPreviewSpaceComponent],
  exports: [ConversationPartPreviewSpaceComponent],
  imports: [CommonModule],
})
export class ConversationPartPreviewSpaceModule {}

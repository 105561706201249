import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { TriggerChainStepNameEditorComponent } from './trigger-chain-step-name-editor.component';

@NgModule({
  declarations: [TriggerChainStepNameEditorComponent],
  exports: [TriggerChainStepNameEditorComponent],
  imports: [CommonModule, NgbTooltipModule, SharedModule, ReactiveFormsModule, TranslocoModule],
})
export class TriggerChainStepNameEditorModule {}

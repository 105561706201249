/**
 * Возможные ошибки API
 */
export const TELEGRAM_API_ERRORS = {
  /** Токен невалидный */
  INVALID_ACCESS_TOKEN: 'TelegramAPITokenInvalid',
  /** Токен не удовлетворяет формату */
  VALIDATION_ERROR: 'ValidationError',
  /** Интеграция с таким токеном уже создана */
  INTERGRATION_ALREADY_EXISTS: 'TelegramBotIntegrationAlreadyExists',
  /** Попытка поменять токен на другого бота */
  TELEGRAM_BOT_IDS_NOT_MATCHED: 'TelegramBotIdsNotMatched',
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { UnansweredFilterComponent } from './unanswered-filter.component';

@NgModule({
  declarations: [UnansweredFilterComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, NgbTooltipModule],
})
export class UnansweredFilterModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { FilterAppsWithNoAccessPipe } from '@panel/app/pages/account/shared/notification-settings/filter-apps-with-no-access.pipe';
import { NotificationAppSettingModule } from '@panel/app/pages/account/shared/notification-settings/notification-app-setting/notification-app-setting.module';
import { NotificationChannelSettingsModule } from '@panel/app/pages/account/shared/notification-settings/notification-channel-settings/notification-channel-settings.module';
import { NotificationSettingsComponent } from '@panel/app/pages/account/shared/notification-settings/notification-settings.component';
import { NotificationSettingsGeneralModule } from '@panel/app/pages/account/shared/notification-settings/notification-settings-general/notification-settings-general.module';

import { AppIdToNamePipe } from './app-id-to-name.pipe';

@NgModule({
  declarations: [NotificationSettingsComponent, AppIdToNamePipe, FilterAppsWithNoAccessPipe],
  exports: [NotificationSettingsComponent],
  imports: [
    CommonModule,
    NotificationSettingsGeneralModule,
    NotificationAppSettingModule,
    TranslocoModule,
    NgbCollapseModule,
    NotificationChannelSettingsModule,
  ],
  providers: [],
})
export class NotificationSettingsModule {}

import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { FILTER_EVENT_TYPE } from '@panel/app/services/filter/filter.constants';
import { EventFilter, PropertyFilter } from '@panel/app/services/filter/types/filter.internal-types';

export class FilterService {
  static getDefaultPropertyFilter(): PropertyFilter {
    return {
      propertyName: null,
      operation: {
        type: ELASTICSEARCH_OPERATION.KNOWN,
        value: {
          value: '',
        },
      },
    };
  }

  static getDefaultEventFilter(): EventFilter {
    return {
      eventId: null,
      eventType: FILTER_EVENT_TYPE.COUNT,
      operation: {
        type: ELASTICSEARCH_OPERATION.KNOWN,
        value: {
          value: '',
        },
      },
    };
  }

  static isNumberRangeValue(type: ELASTICSEARCH_OPERATION): boolean {
    return [ELASTICSEARCH_OPERATION.NUMBER_RANGE].includes(type);
  }

  static isNumberValue(type: ELASTICSEARCH_OPERATION): boolean {
    return [
      ELASTICSEARCH_OPERATION.NUMBER_GT,
      ELASTICSEARCH_OPERATION.NUMBER_LT,
      ELASTICSEARCH_OPERATION.NUMBER_GT_OR_UNKNOWN,
      ELASTICSEARCH_OPERATION.NUMBER_LT_OR_UNKNOWN,
      ELASTICSEARCH_OPERATION.NUMBER_EQ,
      ELASTICSEARCH_OPERATION.NUMBER_NOT_EQ,
    ].includes(type);
  }

  static isDateValue(type: ELASTICSEARCH_OPERATION): boolean {
    return [
      ELASTICSEARCH_OPERATION.DAYS_LESS_THAN,
      ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN,
      ELASTICSEARCH_OPERATION.DAYS_MORE_THAN,
      ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN,
    ].includes(type);
  }

  static isListValue(type: ELASTICSEARCH_OPERATION): boolean {
    return [
      ELASTICSEARCH_OPERATION.LIST_CONTAINS,
      ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS,
      ELASTICSEARCH_OPERATION.LIST_CONTAINS_ANY,
      ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ANY,
      ELASTICSEARCH_OPERATION.LIST_CONTAINS_ALL,
      ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ALL,
    ].includes(type);
  }

  static isStrContains(type: ELASTICSEARCH_OPERATION): boolean {
    return [ELASTICSEARCH_OPERATION.STR_CONTAINS, ELASTICSEARCH_OPERATION.STR_NOT_CONTAINS].includes(type);
  }

  static isStrValue(type: ELASTICSEARCH_OPERATION): boolean {
    return [ELASTICSEARCH_OPERATION.STR_EQ, ELASTICSEARCH_OPERATION.STR_NOT_EQ].includes(type);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { EventTypesModule } from '@panel/app/pages/data-collection/shared/event-types/event-types.module';
import { TrackMasterModule } from '@panel/app/pages/data-collection/shared/track-master/track-master.module';
import { PermissionDeniedModule } from '@panel/app/pages/permission-denied/permission-denied.module';

import { DataCollectionComponent } from './data-collection.component';

@NgModule({
  declarations: [DataCollectionComponent],
  exports: [DataCollectionComponent],
  imports: [
    CommonModule,
    PermissionDeniedModule,
    TrackMasterModule,
    EventTypesModule,
    TranslocoModule,
    NgbTooltipModule,
    NgbNavModule,
  ],
})
export class DataCollectionModule {}

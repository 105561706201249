import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ArticleCategoryExternal } from '@http/article-category/external-types/article-category.external.type';
import { ArticleCategory } from '@http/article-category/internal-types/article-category.internal.type';
import { EmojiService } from '@panel/app/shared/services/emoji/emoji.service';

@Injectable({ providedIn: 'root' })
export class ArticleCategoryModel {
  /**
   * Дефолтное имя emoji
   *
   * @type {string}
   */
  DEFAULT_EMOJI_NAME = ':file_folder:';

  /**
   * Дефолтный emoji
   *
   * @type {string}
   */
  DEFAULT_EMOJI = this.emojiService.getNativeEmojiByName(this.DEFAULT_EMOJI_NAME);

  /**
   * Категория по умолчанию
   *
   * @type {Object}
   */
  DEFAULT_ARTICLE_CATEGORY = {
    app: null, // ID приложения, в котором находится эта категория
    articlesCounter: 0, // Количество статей
    icon: this.DEFAULT_EMOJI, // Иконка
    id: null, // ID
    name: '', // Название
  };

  constructor(private readonly httpClient: HttpClient, private readonly emojiService: EmojiService) {}

  /**
   * Изменение приоритета вывода категории
   *
   * @param {String} appId - ID приложения
   * @param {String} categoryId - ID категории
   * @param {Number} priority - Приоритет
   *
   * @return {Promise}
   */
  changePriority(appId: any, categoryId: any, priority: any) {
    var params = {
      priority: priority,
    };

    return this.httpClient.post('/knowledgebase/' + appId + '/categories/' + categoryId + '/priority', params);
  }

  /**
   * Создание категории статей
   *
   * @param {String} appId - ID приложения
   * @param {Object} category - Группа
   *
   * @return {Promise}
   */
  create(appId: any, category: any) {
    var params = {
      icon: category.icon,
      name: category.name,
      ignoreErrors: true,
    };

    return this.httpClient.post('/knowledgebase/' + appId + '/categories', params).pipe(
      switchMap((response: any) => {
        return this.get(appId, response.id, false);
      }),
      catchError((meta) => {
        throw meta;
      }),
    );
  }

  /**
   * Получение категории статей по ID
   *
   * @param {String} appId - ID приложения
   * @param {String} categoryId - ID категории
   * @param {Boolean=} [includeArticlesCounter=false] - Флаг добавления количества статей в категории
   *
   * @return {Promise}
   */
  get(appId: any, categoryId: any, includeArticlesCounter?: any) {
    var params = {
      include_articles_counter: angular.isDefined(includeArticlesCounter) ? includeArticlesCounter : false,
    };

    categoryId = categoryId ?? '';

    return this.httpClient.get('/knowledgebase/' + appId + '/categories/' + categoryId, { params }).pipe(
      map((response: any) => {
        var articlesCategory = response;

        this.parse(articlesCategory);

        return articlesCategory;
      }),
    );
  }

  /**
   * Получение дефолтной категории
   *
   * @return {DEFAULT_ARTICLE_CATEGORY}
   */
  getDefault() {
    return angular.copy(this.DEFAULT_ARTICLE_CATEGORY);
  }

  /**
   * Получение дефолтной иконки emoji
   *
   * @return {DEFAULT_EMOJI}
   */
  getDefaultEmoji() {
    return this.DEFAULT_EMOJI;
  }

  /**
   * Получение дефолтного имени иконки emoji
   *
   * @return {DEFAULT_EMOJI_NAME}
   */
  getDefaultEmojiName() {
    return this.DEFAULT_EMOJI_NAME;
  }

  /**
   * Получение всех категорий
   *
   * @param {String} appId - ID приложения
   * @param {Boolean=} [includeArticlesCounter=true] - Флаг добавления количества статей в категории
   * @param {Boolean=} [includeNotPublicArticles=true] - Флаг для дополнительного добавления количества неопубликованных статей
   *
   * @return {Object}
   */
  getList(appId: string, includeArticlesCounter?: any, includeNotPublicArticles?: any): Observable<ArticleCategory[]> {
    var params = {
      include_articles_counter: angular.isDefined(includeArticlesCounter) ? includeArticlesCounter : true,
      include_not_public_articles: angular.isDefined(includeNotPublicArticles) ? includeNotPublicArticles : true,
    };

    return this.httpClient.get<ArticleCategoryExternal[]>('/knowledgebase/' + appId + '/categories', { params }).pipe(
      map((response: ArticleCategoryExternal[]) => {
        var articlesCategories = orderBy(response, ['priority'], ['desc']);

        for (var i = 0; i < articlesCategories.length; i++) {
          this.parse(articlesCategories[i]);
        }

        return articlesCategories;
      }),
    );
  }

  /**
   * Парсинг категории статей
   *
   * @param {Object} category Категория
   */
  parse(category: ArticleCategoryExternal): void {
    if (angular.isDefined(category.articlesCounter) && !angular.isNumber(category.articlesCounter)) {
      category.articlesCounter = 0;
    }
  }

  /**
   * Удаление категории статей по её ID
   *
   * @param {String} appId - ID приложения
   * @param {Object} categoryId - ID категории статей
   *
   * @return {Promise}
   */
  remove(appId: any, categoryId: any) {
    const params = {
      ignoreErrors: true,
    };

    return this.httpClient.delete('/knowledgebase/' + appId + '/categories/' + categoryId, { params });
  }

  /**
   * Сохранение данных о категории статей
   *
   * @param {String} appId - ID приложения
   * @param {Object} category - Группа
   *
   * @return {Promise}
   */
  save(appId: any, category: any) {
    var categoryId = category.id;
    var params = {
      icon: category.icon,
      name: category.name,
      ignoreErrors: true,
    };

    return this.httpClient.patch('/knowledgebase/' + appId + '/categories/' + categoryId, params).pipe(
      switchMap((response: any) => {
        return this.get(appId, categoryId, false);
      }),
      catchError((meta) => {
        throw meta;
      }),
    );
  }
}

<div class="modal-header">
  <h3 class="modal-title">{{ title }}</h3>
  <button
    class="close"
    (click)="closeModal()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>

<div class="modal-body pb-20">
  <ng-template #dynamicRef></ng-template>
</div>

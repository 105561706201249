import { AbstractControl, ValidatorFn } from '@angular/forms';

export function floatMin(min: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let value: any;

    if (typeof control.value === 'string') {
      value = parseFloat(control.value.toString().replace(',', '.'));
    } else {
      value = parseFloat(control.value);
    }

    // Если значение меньше минимума, возвращаем ошибку
    return value < min ? { min: true } : null;
  };
}

import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AMOCRM_NOTE_MAX_LENGTH } from '@http/integration/integrations/amo/constants/amocrm-integration.constants';
import { AmocrmIntegrationExternal } from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { BotAmocrmActionBodyJson } from 'app/http/chat-bot/actions';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

import { ActionValidationExtra, BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

/**
 * Валидатор с проверкой на активность интеграции amoCRM
 *
 * @param amocrmIntegrations Список интеграций amoCRM
 */
function inactiveAmocrmIntegration(amocrmIntegrations: AmocrmIntegrationExternal[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const currentIntegration = amocrmIntegrations.filter((int) => int.id === control.value)[0];
    return currentIntegration?.active ? null : { inactiveIntegration: { value: control.value } };
  };
}

/** Валидаторы для поля с комментарием к сделке amoCRM */
const getAmocrmNotificationBodyJsonCommentValidators = (): ValidatorFn[] => [
  Validators.maxLength(AMOCRM_NOTE_MAX_LENGTH),
];

/** Валидаторы для поля с выбранной интеграцией amoCRM к сделке */
const getAmocrmNotificationBodyJsonIntegrationValidators = (
  amocrmIntegrations: AmocrmIntegrationExternal[],
): ValidatorFn[] => [Validators.required, inactiveAmocrmIntegration(amocrmIntegrations)];

export class BotAmocrmNotificationActionForm extends BaseBotActionForm<BotAmocrmActionBodyJson> {
  getCustomControls(
    action: ChatBotAction<BotAmocrmActionBodyJson>,
    validationExtra: ActionValidationExtra,
  ): CustomControlsWithBodyJsonRequired<BotAmocrmActionBodyJson> {
    return {
      bodyJson: new GenericFormGroup<BotAmocrmActionBodyJson>({
        comment: new GenericFormControl(
          action.bodyJson?.comment ?? null,
          getAmocrmNotificationBodyJsonCommentValidators(),
        ),
        integration: new GenericFormControl(
          action.bodyJson?.integration ?? null,
          getAmocrmNotificationBodyJsonIntegrationValidators(validationExtra.amocrmIntegrations),
        ),
        tags: new GenericFormControl(action.bodyJson?.tags ?? null, []),
      }),
    };
  }

  get allTouchedChanges$() {
    return combineLatest([
      extractTouchedChanges(this.controls.bodyJson).pipe(startWith(this.controls.bodyJson.touched)),
      extractTouchedChanges(this.controls.body).pipe(startWith(this.controls.body.touched)),
    ]).pipe(map(([t1, t2]) => t1 && t2));
  }
}

(function () {
  'use strict';

  angular.module('myApp.emailFrame').component('cqEmailFrame', cqEmailFrame());

  function cqEmailFrame() {
    return {
      transclude: true,
      template: require('./email-frame.html'),
    };
  }
})();

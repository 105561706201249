(function () {
  'use strict';

  angular.module('myApp.services.longPollConnection').service('LongPollConnection', LongPollConnection);

  function LongPollConnection(
    REAL_TIME_SERVICES_HOST,
    REAL_TIME_SERVICES_HOST_NEW,
    USE_NEW_RTS,
    ipCookie,
    $http,
    $q,
    $rootScope,
    $window,
  ) {
    let lastConnectionTime = Date.now();
    let lastMessageTime = Date.now();

    return function (events, callback) {
      const watcher = {
        ws: {},
        wss: {},
        lp: {},

        CONN_WAIT_TIMEOUT: 25 * 1000, // Время ожидания первого коннекта к вебсокетам
        SHORT_POLL_INTERVAL: 3 * 1000, // Интервал для short polling
        ERROR_RETRY_INTERVAL: 15 * 1000, // При ошибке
        FORCE_RECONNECT_INTERVAL: 10 * 1000,

        // Параметры последнего сообщения
        tag: '',
        time: lastMessageTime,

        // Функция отмены
        canceler: $q.defer(),

        reconnectTimeout: null,
      };

      watcher.init = function () {
        watcher.currentModule = watcher.wss;
        watcher.currentModule.connect();

        watcher.currentModule.reset = () => {
          watcher.currentModule.disconnect();
          watcher.currentModule.connect();
        };
      };

      watcher.canceler.promise.then(function () {
        watcher.currentModule.disconnect();
      });

      watcher.onMessage = function (rtsMessage) {
        callback(rtsMessage.channel, rtsMessage.message);
      };

      // ----------------------------------------------------------------------------
      // WebSocket
      // ----------------------------------------------------------------------------
      watcher.ws.connect = function () {
        // при несовпадении протоколов сразу переключаемся на WebSocket Secure
        if (document.location.protocol === 'https:') {
          watcher.wss.connect();
          return;
        }

        watcher.currentModule = watcher.ws;
        sessionStorage.setItem('panel_rts_transport', 'ws');

        let token, rtsHost;
        const appId = $rootScope.currentApp.id;

        //TODO Костыль для быстрой работы с шардами
        if (appId) {
          token = `appm-${appId}-${ipCookie('carrotquest_auth_token_panel')}`;
        } else {
          token = ipCookie('carrotquest_auth_token_panel');
        }

        if (appId && USE_NEW_RTS) {
          rtsHost = REAL_TIME_SERVICES_HOST_NEW;
        } else {
          rtsHost = REAL_TIME_SERVICES_HOST;
        }

        watcher.ws._request = function (reconnect = false, closeCode, closeReason) {
          let url = `ws:${rtsHost}/websocket/${events.join('/')}?auth_token=${token}`;
          if (watcher.time) {
            url += `&time=${watcher.time}&tag=${watcher.tag}`;
          }

          if (reconnect) {
            url += '&reconnect=true';
          }

          if (closeCode) {
            url += `&reconnect_code=${closeCode}`;
          }

          if (closeReason) {
            url += `&reconnect_reason=${closeReason}`;
          }

          const beforeConnectTimestamp = $window.performance.now();

          watcher.ws.socket = new WebSocket(url);

          watcher.ws.socket.onopen = function () {
            const afterConnectTimestamp = $window.performance.now();

            if (USE_NEW_RTS) {
              collectConnectionTime(Math.round(afterConnectTimestamp - beforeConnectTimestamp));
            }

            lastConnectionTime = Date.now();
            watcher.ws.firstConnection = true;
          };

          watcher.ws.socket.onmessage = function (message) {
            try {
              message = JSON.parse(message.data);
              watcher.tag = message.tag;
              watcher.time = message.time;
              lastMessageTime = Date.now();
              watcher.onMessage(message);
            } catch (e) {}
          };

          watcher.ws.socket.onclose = function (event) {
            if (this._reallyClosed) {
              return;
            }
            this._reallyClosed = true;

            if (watcher.ws.firstConnection || USE_NEW_RTS) {
              watcher.reconnectTimeout = setTimeout(
                () => watcher.ws._request(true, event.code, event.reason),
                watcher.ERROR_RETRY_INTERVAL,
              );
            } else {
              watcher.lp.connect();
            }
          };
        };
        watcher.ws._request();
      };

      watcher.ws.disconnect = function () {
        if (watcher.ws.socket) {
          watcher.ws.socket._reallyClosed = true;
          watcher.ws.socket.close();
        }
      };

      // ----------------------------------------------------------------------------
      // WebSocket Secure
      // ----------------------------------------------------------------------------
      watcher.wss.connect = function () {
        // при несовпадении протоколов сразу переключаемся на WebSocket
        if (document.location.protocol === 'http:') {
          watcher.ws.connect();
          return;
        }

        watcher.currentModule = watcher.wss;
        sessionStorage.setItem('panel_rts_transport', 'ws');

        let token, rtsHost;
        const appId = $rootScope.currentApp.id;

        //TODO Костыль для быстрой работы с шардами
        if (appId) {
          token = `appm-${appId}-${ipCookie('carrotquest_auth_token_panel')}`;
        } else {
          token = ipCookie('carrotquest_auth_token_panel');
        }

        if (appId && USE_NEW_RTS) {
          rtsHost = REAL_TIME_SERVICES_HOST_NEW;
        } else {
          rtsHost = REAL_TIME_SERVICES_HOST;
        }

        watcher.wss._request = function (reconnect = false, closeCode, closeReason) {
          let url = `wss:${rtsHost}/websocket/${events.join('/')}?auth_token=${token}`;
          if (watcher.time) {
            url += `&time=${watcher.time}&tag=${watcher.tag}`;
          }

          if (reconnect) {
            url += '&reconnect=true';
          }

          if (closeCode) {
            url += `&reconnect_code=${closeCode}`;
          }

          if (closeReason) {
            url += `&reconnect_reason=${closeReason}`;
          }

          const beforeConnectTimestamp = $window.performance.now();

          watcher.wss.socket = new WebSocket(url);

          watcher.wss.socket.onopen = function () {
            const afterConnectTimestamp = $window.performance.now();

            if (USE_NEW_RTS) {
              collectConnectionTime(Math.round(afterConnectTimestamp - beforeConnectTimestamp));
            }

            lastConnectionTime = Date.now();
            watcher.wss.firstConnection = true;
          };

          watcher.wss.socket.onmessage = function (message) {
            try {
              message = JSON.parse(message.data);
              watcher.tag = message.tag;
              watcher.time = message.time;
              lastMessageTime = Date.now();
              watcher.onMessage(message);
            } catch (e) {}
          };

          watcher.wss.socket.onclose = function (event) {
            if (this._reallyClosed) {
              return;
            }
            this._reallyClosed = true;

            if (watcher.wss.firstConnection || USE_NEW_RTS) {
              watcher.reconnectTimeout = setTimeout(
                () => watcher.wss._request(true, event.code, event.reason),
                watcher.ERROR_RETRY_INTERVAL,
              );
            } else {
              watcher.lp.connect();
            }
          };
        };
        watcher.wss._request();
      };

      watcher.wss.disconnect = function () {
        if (watcher.wss.socket) {
          watcher.wss.socket._reallyClosed = true;
          watcher.wss.socket.close();
        }
      };

      // ----------------------------------------------------------------------------
      // Long Polling
      // ----------------------------------------------------------------------------
      watcher.lp.connect = function () {
        watcher.currentModule = watcher.lp;
        sessionStorage.setItem('panel_rts_transport', 'lp');

        watcher.lp._request = function () {
          watcher.lp.xhr = new XMLHttpRequest();
          watcher.lp.xhr.onreadystatechange = function () {
            if (watcher.lp.xhr.readyState != 4) {
              return;
            }

            if (watcher.lp.xhr._reallyCancelled) {
              return;
            }

            if (watcher.lp.xhr.status == 304) {
              //watcher.tag = watcher.lp.xhr.getResponseHeader('ETag');
              //watcher.time = watcher.lp.xhr.getResponseHeader('Last-Modified');

              if (document.readyState != 'complete') {
                watcher.reconnectTimeout = setTimeout(watcher.lp._request, watcher.SHORT_POLL_INTERVAL);
              } else {
                watcher.lp._request();
              }
            } else if (watcher.lp.xhr.status == 200) {
              let messages = watcher.lp.xhr.responseText.split('\n');
              messages.pop(); // Remove last empty element

              for (let i = 0; i < messages.length; i++) {
                const message = JSON.parse(messages[i]);
                watcher.tag = message.tag;
                watcher.time = message.time;
                lastMessageTime = Date.now();
                watcher.onMessage(message);
              }

              //watcher.tag = watcher.lp.xhr.getResponseHeader('ETag');
              //watcher.time = watcher.lp.xhr.getResponseHeader('Last-Modified');

              if (document.readyState != 'complete') {
                watcher.reconnectTimeout = setTimeout(watcher.lp._request, watcher.SHORT_POLL_INTERVAL);
              } else {
                watcher.lp._request();
              }
            } else {
              watcher.reconnectTimeout = setTimeout(watcher.lp._request, watcher.ERROR_RETRY_INTERVAL);
            }
          };

          let token = ipCookie('carrotquest_auth_token_panel');
          //TODO Костыль для быстрой работы с шардами
          if ($rootScope.currentApp) {
            token = 'appm-' + $rootScope.currentApp.id + '-' + token;
          }

          let url = REAL_TIME_SERVICES_HOST;

          if (document.readyState != 'complete') {
            url += '/shortpoll/' + events.join('/') + '?auth_token=' + token;
          } else {
            url += '/longpoll/' + events.join('/') + '?auth_token=' + token;
          }

          if (watcher.tag) {
            url += '&tag=' + watcher.tag + '&time=' + decodeURIComponent(watcher.time);
          }

          watcher.lp.xhr.open('GET', url, true);
          watcher.lp.xhr.send();
        };
        watcher.lp._request();
      };

      watcher.lp.disconnect = function () {
        if (watcher.lp.xhr) {
          watcher.lp.xhr._reallyCancelled = true;
          watcher.lp.xhr.abort();
        }
      };

      watcher.init();

      return {
        currentModule: watcher.currentModule,
        destroy: watcher.canceler,
        lastConnectionTime: () => lastConnectionTime,
        lastMessageTime: () => lastMessageTime,
        reset: watcher.currentModule.reset,
      };
    };

    /**
     * Сбор информации о времени, которое было потрачено на установление соединения с RTS
     * NOTE: присрано костылём, лишь бы работало, да побыстрее
     *
     * @param {number} time Время в миллисекундах
     */
    function collectConnectionTime(time) {
      let formData = new FormData();

      formData.append('time', time);

      return $http({
        method: 'POST',
        url: `${$window.location.protocol}${REAL_TIME_SERVICES_HOST_NEW}/websocket_connect_time`,
        data: formData,
        headers: {
          'Content-Type': undefined, // браузер за нас решит какой content-type подставлять
        },
        ignoreLoadingBar: true,
      })
        .then(() => {})
        .catch(() => {});
    }
  }
})();

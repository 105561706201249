<ng-container *transloco="let t; read: 'listComponent'">
  <div class="card-body p-0">
    <table class="table table-sm table-align-middle">
      <thead>
        <tr>
          <th>
            <span class="text-secondary">{{ t('headings.number') }}</span>
          </th>
          <th>
            <span class="text-secondary">{{ t('headings.date') }}</span>
          </th>
          <th>
            <span class="text-secondary">
              <span>{{ t('headings.status.title') }}</span>
              <i
                class="cqi-sm cqi-question-circle inline-block ml-5 cursor-pointer"
                [ngbTooltip]="t('headings.status.description')"
              ></i>
            </span>
          </th>
          <th class="text-right">
            <span class="text-secondary">{{ t('headings.amount') }}</span>
          </th>
          <th class="text-right">
            <span class="text-secondary">{{ t('headings.amountDue') }}</span>
          </th>
          <th class="shrink-by-content"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invoice of invoices">
          <td>{{ invoice.formattedInvoiceId }}</td>
          <td>{{ invoice.createDate | amFromUnix | amDateFormat: 'L' }}</td>
          <td>
            <cq-status [invoice]="invoice"></cq-status>
          </td>
          <td class="text-right">
            {{ invoice.total | toMoney | toFormattedMoney }}
          </td>
          <td class="text-right">
            {{ invoice.amountDue | toMoney | toFormattedMoney }}
          </td>
          <td class="shrink-by-content">
            <cq-download-documents
              class="d-inline-block"
              [invoice]="invoice"
            ></cq-download-documents>

            <button
              *ngIf="!invoice.isPaid() && isNeedToShowInvoicePayButton()"
              class="btn btn-sm btn-primary"
              type="button"
              (click)="trackClickPayButton(); invoice.pay()"
            >
              {{ t('pay') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-container *ngIf="isNeedToShowShowMoreButton">
      <div class="px-20 mb-20">
        <button
          type="button"
          class="btn btn-secondary btn-sm w-100"
          (click)="onClickShowMoreButton()"
        >
          {{ t('showMore') }}
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>

import Moment from 'moment';

import { Message } from '@http/message/message.types';
import { TgTriggerType } from '@panel/app/partials/chat-bot/telegram-bot/trigger-types/tg-trigger.types';

import { ChatBotBranch } from 'app/http/chat-bot/types/branch-internal.types';
import { CHAT_BOT_TYPE } from 'app/http/chat-bot/types/chat-bot-external.types';
/**
 * Родительский тип для ботов, ниже есть два отдельных (роутинг и лид)
 */
export type ChatBot<T extends CHAT_BOT_TYPE> = {
  id?: string;
  name: string;
  type: T;
  created?: Moment.Moment;
  interruptBranchId?: string | null;
  interruptBranchLinkId: string | null;
  startBranchId?: string | null;
  startBranchLinkId: string | null;
  allowUserReplies: boolean;
  branches: ChatBotBranch[];
  triggerTypes: TgTriggerType[];
  message?: Partial<Message>;
  stats?: RoutingBotStats;
  integration: string | null;
  chatWidget?: ChatWidget;
};

export type LeadBot = ChatBot<CHAT_BOT_TYPE.LEAD>;

export type BotWithActiveStatus<T extends Exclude<CHAT_BOT_TYPE, 'lead'>> = ChatBot<T> & {
  active: boolean;
};

export type BotListPagination<T extends Exclude<CHAT_BOT_TYPE, 'lead'>> = {
  bots: BotWithActiveStatus<T>[];
  paginatePosition: string[] | null; // Бек в nextBeforePosition отправляет массив
};

export type FacebookBot = BotWithActiveStatus<CHAT_BOT_TYPE.FACEBOOK>;

export type RoutingBot = BotWithActiveStatus<CHAT_BOT_TYPE.ROUTING>;

export type WidgetBot = BotWithActiveStatus<CHAT_BOT_TYPE.WIDGET>;

export type TelegramBot = BotWithActiveStatus<CHAT_BOT_TYPE.TELEGRAM>;

export type RoutingBotStats = {
  sent: number;
  startedConversation: number;
  finished: number;
  interrupted: number;
  leftContacts: number;
  lastSent: Moment.Moment | null;
};

/** Активный чат-виджет */
export type ActiveChatWidget = {
  id: string;
  subHeader: string;
  header: string;
  buttonText: string;
  order: number;
  action: {
    chatBotId: string;
  };
};

/** Чат-виджет, который является частью embedded-бота */
export type ChatWidget = {
  buttonText: string;
  header: string;
  id?: string;
  order: number;
  subHeader: string;
  visible: boolean;
};

(function () {
  'use strict';

  angular
    .module('myApp.integrations')
    .constant('FACEBOOK_LEADS_INTEGRATION_STEPS', getFacebookLeadsIntegrationSteps())
    .constant('FACEBOOK_LEADS_STANDARD_FIELDS_TYPES', getFacebookLeadsStandardFieldsTypes())
    .constant('FACEBOOK_LEADS_STANDARD_PROPS_DEFAULT', getFacebookLeadsStandardPropsDefault());

  function getFacebookLeadsIntegrationSteps() {
    return {
      STEP_1: {
        ORDER: 0,
        TEMPLATE_URL: 'js/components/integrations/facebook-leads/steps/step1.html',
        VALUE: 'step1',
      },
      STEP_2: {
        ORDER: 1,
        TEMPLATE_URL: 'js/components/integrations/facebook-leads/steps/step2.html',
        VALUE: 'step2',
      },
      STEP_3: {
        ORDER: 2,
        TEMPLATE_URL: 'js/components/integrations/facebook-leads/steps/step3.html',
        VALUE: 'step3',
      },
      STEP_4: {
        ORDER: 3,
        TEMPLATE_URL: 'js/components/integrations/facebook-leads/steps/step4.html',
        VALUE: 'step4',
      },
    };
  }

  function getFacebookLeadsStandardFieldsTypes() {
    return {
      $name: ['FIRST_NAME', 'FULL_NAME', 'LAST_NAME'],
      $email: ['EMAIL', 'WORK_EMAIL'],
      $phone: ['PHONE', 'WORK_PHONE_NUMBER'],
    };
  }

  function getFacebookLeadsStandardPropsDefault() {
    return [
      {
        checked: false,
        cqName: '$name',
        fbName: '',
      },
      {
        checked: false,
        cqName: '$email',
        fbName: '',
      },
      {
        checked: false,
        cqName: '$phone',
        fbName: '',
      },
    ];
  }
})();

<ng-container *transloco="let t">
  <div
    [ngbTooltip]="replyTimerTooltip"
    placement="top"
  >
    <i class="cqi-sm cqi-stopwatch-o margin-right-5"></i>
    <span>{{ format(replyTimer) }}</span>
  </div>

  <ng-template #replyTimerTooltip>
    <span>{{ replyTimerTooltipText }}</span>
  </ng-template>
</ng-container>

/**
 * Контроллер для модального окна добавления тега
 * При создании модального окна в resolve можно передать modalWindowParams - объект следующего вида:
 *    {String=} body Текст тела модального окна
 */
(function () {
  'use strict';

  angular.module('myApp.modals.addTag').controller('AddTagModalController', AddTagModalController);

  function AddTagModalController(
    $filter,
    $interpolate,
    $translate,
    $uibModal,
    $uibModalInstance,
    modalWindowParams,
    tagList,
  ) {
    var vm = this;

    vm.addNewTag = addNewTag;
    vm.addTag = addTag;
    vm.modalWindowParams = modalWindowParams;
    vm.tagList = tagList;

    function init() {
      modalWindowParams.body = modalWindowParams.body ?? '';
    }

    init();

    /**
     * Открытие модалки с добавлением нового тега
     */
    function addNewTag() {
      var addTagModal = $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.addTag.addNewTagModal.heading'),
              confirmButtonText: $translate.instant('modals.addTag.addNewTagModal.confirmButtonText'),
              inputLabel: $translate.instant('modals.addTag.addNewTagModal.inputLabel'),
              inputErrorText: $translate.instant('modals.addTag.addNewTagModal.inputErrorText'),
            };
          },
        },
        size: 'md modal-dialog-centered',
      });

      addTagModal.result.then(addTag);

      function addTag(tagName) {
        var newTag = {
          name: tagName,
        };

        // при добавлении нового тега надо проверить не существует ли добавляемый тег
        var existingTagByName = $filter('filter')(vm.tagList, { name: tagName }, true)[0];

        if (existingTagByName) {
          vm.selectedTag = existingTagByName;
        } else {
          vm.selectedTag = newTag;
        }
      }
    }

    /**
     * Обработка нажатия на кнопку добавления тега
     */
    function addTag() {
      if (vm.selectedTag) {
        $uibModalInstance.close(vm.selectedTag);
      }
    }
  }
})();

/**
 * Сервис для показа уведомлений
 * FIXME: тут нужно почти всё переделать, функционал уведомлений перенесён из app.js
 */
(function () {
  'use strict';

  angular.module('myApp.services.desktopNotification').factory('desktopNotification', desktopNotification);

  function desktopNotification(
    $rootScope,
    $state,
    $translate,
    $window,
    toastr,
    PANEL_URL,
    PROJECT_NAME,
    electronApi,
    l10nHelper,
    utilsService,
  ) {
    var desktopInfiniteSoundInterval = null;
    var desktopNotificationCancelledByUser = false;
    var desktopNotificationPermissionRequestToast = null;
    var notificationSound = new Audio('assets/sounds/chat-notification.mp3');
    var permission = ($window.Notification && $window.Notification.permission) || 'denied';

    return {
      requestPermission: requestPermission,
      setReadTitle: setReadTitle,
      show: show,
      showForConversation: showForConversation,
      turnOffInfiniteSound: turnOffInfiniteSound,
    };

    /**
     * Проверяет есть ли в title "* " или нет
     *
     * @returns {boolean}
     */
    function hasTitleNotification() {
      return /^\*(\b)?/.test(document.title);
    }

    /**
     * Запрос разрешения на показ уведомлений
     */
    function requestPermission() {
      if (
        permission == 'granted' ||
        permission == 'denied' ||
        desktopNotificationCancelledByUser ||
        (desktopNotificationPermissionRequestToast && desktopNotificationPermissionRequestToast.isOpened)
      ) {
        return;
      }

      desktopNotificationPermissionRequestToast = toastr.warning(
        $translate.instant('services.desktopNotification.toasts.requestPermission'),
        '',
        {
          closeButton: true,
          extendedTimeOut: 0,
          onHidden: requestNotificationCancel,
          onTap: requestNotificationClick,
          tapToDismiss: false,
          timeOut: 0,
        },
      );

      function requestNotificationCancel() {
        desktopNotificationCancelledByUser = true;
      }

      function requestNotificationClick() {
        $window.Notification.requestPermission(function (newPermission) {
          permission = newPermission;

          if (permission == 'granted') {
            $rootScope.$apply(function () {
              toastr.clear(desktopNotificationPermissionRequestToast);
            });
          }
        });
      }
    }

    /**
     * Убирает из titile "* "
     */
    function setReadTitle() {
      if (hasTitleNotification() && utilsService.webApp) {
        document.title = document.title.replace(/^\*(\b)?/, '');
      }

      electronApi.setDefaultIcon();
    }

    /**
     * Добавляет в title "* ", оповещая о новых сообщениях
     */
    function setUnreadTitle() {
      if (utilsService.webApp && !hasTitleNotification()) {
        document.title = '* ' + document.title;
      }

      electronApi.setUnreadMessageIcon();
    }

    /**
     * Показ нотификации
     *
     * @param {PROJECT_NAME} title — Название приложения
     * @param {String} body — Сообщение
     * @param {String} conversationId — ID диалога
     */
    function show(title, body, conversationId) {
      const icon = l10nHelper.isUsCountry()
        ? 'assets/img/default/favicons/dashly/notification-icon.png'
        : 'assets/img/default/favicons/carrot-quest/notification-icon.png';

      if (electronApi.desktopApp) {
        electronApi.showConversationNotification({
          title,
          conversationId,
          body,
          icon: `${PANEL_URL}/${icon}`,
        });

        // Sound notification
        if ($rootScope.djangoUser.prefs.desktop_sound) {
          notificationSound.play();
        }
      } else if ($window.Notification && $window.Notification.permission == 'granted') {
        // Веб-версия
        var notification = new Notification(title, {
          body: body,
          icon: icon,
          requireInteraction: $rootScope.djangoUser.prefs.desktop_notification_opened || null,
          tag: conversationId,
        });

        // Sound notification
        if ($rootScope.djangoUser.prefs.desktop_sound) {
          if ($rootScope.djangoUser.prefs.desktop_infinite_sound) {
            if (!desktopInfiniteSoundInterval) {
              desktopInfiniteSoundInterval = setInterval(function () {
                notificationSound.play();
              }, 1000);
            }
          } else {
            notificationSound.play();
          }
        }

        notification.onclick = function (e) {
          if (!$state.includes('app.content.conversations')) {
            $state.go('app.content.conversations.general');
          }

          $window.focus();
        };

        if (!$rootScope.djangoUser.prefs.desktop_notification_opened) {
          setTimeout(function () {
            notification.close();
          }, 60 * 1000);
        }
      }
    }

    function showForConversation(conversationAssignee, message, conversationId) {
      if (!$rootScope.djangoUser || !$rootScope.currentApp) {
        return;
      }
      setUnreadTitle();

      // Назначено на меня
      if (
        $rootScope.djangoUser.prefs[$rootScope.currentApp.id].notification_desktop_assigned_me &&
        conversationAssignee &&
        conversationAssignee.id == $rootScope.djangoUser.id
      ) {
        // Show
        show(PROJECT_NAME, message, conversationId);
      } else if (
        $rootScope.djangoUser.prefs[$rootScope.currentApp.id].notification_desktop_assigned_nobody &&
        !conversationAssignee
      ) {
        // Никому не назначено
        show(PROJECT_NAME, message, conversationId);
      } else if (
        $rootScope.djangoUser.prefs[$rootScope.currentApp.id].notification_desktop_assigned_other_admin &&
        conversationAssignee &&
        conversationAssignee.id != $rootScope.djangoUser.id
      ) {
        // Назначено на другого админа
        show(PROJECT_NAME, message, conversationId);
      }
    }

    /**
     * Выключить воспроизведение беспонечного оповещения
     */
    function turnOffInfiniteSound() {
      if (utilsService.webApp && desktopInfiniteSoundInterval) {
        clearInterval(desktopInfiniteSoundInterval);
        desktopInfiniteSoundInterval = null;
      }
    }
  }
})();

(function () {
  'use strict';

  require('./karma-notification.controller');

  angular.module('myApp.dashboard').component('cqKarmaNotification', cqKarmaNotification());

  function cqKarmaNotification() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
      },
      controller: 'CqKarmaNotificationController',
      controllerAs: 'vm',
      template: require('./karma-notification.html'),
    };
  }
})();

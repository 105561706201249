(function () {
  'use strict';

  require('./chat-preview.controller');

  angular.module('myApp.chatSettings').component('cqChatPreview', cqChatPreview());

  function cqChatPreview() {
    return {
      bindings: {
        accentHoveredColor: '=', // Пользовательский hover-цвет
        admins: '=?', // Админы
        autoReplyTexts: '=?', // Текст автоответа
        autoReplyType: '=?', // Тип собираемых контактов
        avatar: '=', // Аватар
        color: '=', // Цвет бейджа чата
        contrastColor: '=?', // цвет для кнопки закрытия
        doAccentAndWhiteMatchGood: '=?', // ок ли матчатся цвет клиента и белый
        editableChatWidget: '=?', // Редактируемый чат-виджет
        isContrastGood: '=?',
        isLeadBot: '&?',
        isMainSettings: '=?', // Основные настройки чата
        isRoutingBot: '=?',
        isWaitingOperatorResponse: '=?', // Ожидание ответа оператора
        name: '=', // Имя по умолчанию
        newAvatarSrc: '=', // Путь до нового аватара
        offlineMessage: '=', // Текст сообщения в нерабочее время
        onlineMessage: '=', // Текст сообщения в рабочее время
        orderedSocialNetworks: '=', // Отсортированный и упорядоченный список соц. сетей
        pattern: '=', // Фон чата
        showConfirmSubscription: '=', // Показать условия обрботки данных
        showKbLinkInWelcomeMessage: '=', // Введет ли приветственное сообщение на БЗ
        showOfflineMessage: '=', // Включить отображение статуса
        showPoweredBy: '=', // Показывать надпись "Мы используем Carrot quest"
        theme: '=', // Цветовая сехма чата
        trackingDataKnown: '=?', // Знаем ли мы данные пользователя
        userColorAndThemeDependent: '=?', // цвет для кнопки перехода в меню, кнопок отправки/эмоджи
        welcomeMessage: '=?', // Приветственное сообщение в чате после его открытия
        activeChatWidgetList: '=?', // Список активных чат-виджетов
        workingTime: '=', // Рабочее ли сейчас время

        /**
         * HACK т.к. есть отдельная настройка языка чата дополнительно передаём её в превью
         * для того, чтобы отобразить интерфейс чата на нужном языке используем в переводе
         * дополнительно ключи en и ru, т.к. использовать существующий механизм i18n не получится
         */
        chatLanguage: '=', // язык чатика
      },
      controller: 'CqChatPreviewController',
      controllerAs: 'vm',
      template: require('./chat-preview.html'),
    };
  }
})();

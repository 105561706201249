import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '@ng-web-apis/common';
import { tuiPure } from '@taiga-ui/cdk';
import moment from 'moment/moment';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { PaymentModalService } from '@panel/app/pages/subscription/general/services/payment-modal/payment-modal.service';
import {
  PLAN_CHANGE_CONFIRM_MODAL_TOKEN,
  PlanChangeConfirmModalData,
} from '@panel/app/pages/subscription/pricing/plan-change-confirm-modal/plan-change-confirm-modal.token';
import { BILLING_ADD_ONS, UNIQUE_BILLING_PLAN_ID } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { MONTHS_BY_PERIOD } from '@panel/app/services/billing-plan/billing-plan.constants';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

import { DowngradeAbilitiesService } from './downgrade-abilities.service';
import { CounterActivitiesDowngrade } from './downgrade-abilities/downgrade-abilities.type';

@Component({
  selector: 'cq-plan-change-confirm-modal',
  templateUrl: './plan-change-confirm-modal.component.html',
  styleUrls: ['./plan-change-confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DowngradeAbilitiesService],
})
export class PlanChangeConfirmModalComponent {
  isApiRequestPerformed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  paymentRate: number = MONTHS_BY_PERIOD[this.data.paymentPeriod];

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly billingInfoModel: BillingInfoModel,
    private readonly billingPlanService: BillingPlanService,
    @Inject(PLAN_CHANGE_CONFIRM_MODAL_TOKEN)
    public readonly data: PlanChangeConfirmModalData,
    public readonly downgradeAbilitiesService: DowngradeAbilitiesService,
    private readonly translocoService: TranslocoService,
    private readonly l10nHelper: L10nHelperService,
    private readonly paymentModalService: PaymentModalService,
    @Inject(WINDOW)
    private readonly windowRef: Window,
  ) {}

  get nextPaymentDate(): string {
    return moment().add(this.paymentRate, 'month').format('LL');
  }

  addOnTranslate(billingAddOn: BILLING_ADD_ONS): string {
    return this.translocoService.translate(`models.billingInfo.billingAddOns.${billingAddOn}`);
  }

  payByOther(): void {
    this.isApiRequestPerformed$.next(true);

    // Формируем id тарифа, который отправится на бэк
    const planFullId =
      this.data.planId +
      (this.l10nHelper.isUsCountry() ? '-plan-' : '-') +
      this.data.price.expectedVisitors +
      '-' +
      this.paymentRate +
      'month';

    firstValueFrom(
      this.billingInfoModel.generateTmpInvoice(this.data.currentApp.id, {
        addons: this.data.price.addons.map((addonInfo) => addonInfo.addon),
        planId: planFullId,
        amount: this.data.price.totalDiscountedPriceWithAddons * 100,
      }),
    )
      .then((response) => {
        this.windowRef.location.href = `${environment.paymentUrl}?code=${response.data.code}`;
      })
      .finally(() => {
        this.isApiRequestPerformed$.next(false);
      });
  }

  planIdTranslate(planId: UNIQUE_BILLING_PLAN_ID): string {
    return this.translocoService.translate(`models.billingInfo.billingPlansNames.${planId}`);
  }

  markDowngradeAutoMessageOrLeadBot(counterActivities: CounterActivitiesDowngrade[]) {
    this.downgradeAbilitiesService.checkDowngradeAutoMessageOrLeadBot(counterActivities);
  }

  openPaymentModal() {
    this.activeModal.close();
    this.paymentModalService.openModal(
      this.data.price,
      this.data.planId,
      this.data.paymentPeriod,
      this.data.currentApp,
      this.data.djangoUser,
    );
  }

  @tuiPure
  get discountValue(): number {
    return this.billingPlanService.getCurrentDiscountsByPeriod(this.data.currentApp, this.billingInfoModel.billingInfo)[
      this.data.paymentPeriod
    ];
  }
}

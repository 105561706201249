<ng-container *transloco="let t; read: 'pricingWrapperComponent'">
  <section id="content">
    <div class="container-fluid limited-container">
      <div class="flex flex-col">
        <!-- ХЛЕБНЫЕ КРОШКИ -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb ml-20">
            <li class="breadcrumb-item">
              <a uiSref="app.content.subscription">{{ t('breadcrumbs.payment') }}</a>
            </li>
            <li
              class="breadcrumb-item active"
              aria-current="page"
            >
              {{ t('breadcrumbs.pricing') }}
            </li>
          </ol>
        </nav>

        <div>
          <!-- ТАРИФЫ -->
          <!--          <cq-pricing-->
          <!--            *ngIf="!showNewPricing"-->
          <!--            appropriate-rate="vm.appropriateRate"-->
          <!--            billing-info="vm.billingInfo"-->
          <!--            call-place="vm.PLAN_CAPABILITIES_PRICING_PLACE.BILLING"-->
          <!--            curren="vm.currentApp"-->
          <!--            django-user="vm.djangoUser"-->
          <!--            success-payment-card="vm.onSuccessPaymentCard()"-->
          <!--          ></cq-pricing>-->

          <cq-pricing-old
            *ngIf="!showNewPricing"
            [billingInfo]="billingInfo"
            [currentApp]="app"
            [djangoUser]="djangoUser"
          >
          </cq-pricing-old>
          <cq-pricing-new
            *ngIf="showNewPricing"
            [currentApp]="app"
            [djangoUser]="djangoUser"
          ></cq-pricing-new>
        </div>
      </div>
    </div>
  </section>
</ng-container>

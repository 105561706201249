<ng-container *transloco="let t">
  <ng-container *ngFor="let prop of customsProps">
    <div
      *ngIf="needToShowProp(prop)"
      [ngClass]="{ 'property-body': !disablePropertyBodyClass }"
      class="align-items-center padding-left-15 flex"
    >
      <div
        class="flex-grow-1"
        [ngbTooltip]="
          pinningPossibility
            ? t('userCustomsPropsComponent.copyTooltip')
            : t('userCustomsPropsComponent.copyAndDragTooltip')
        "
        [disableTooltip]="disablePropertyBodyClass"
        placement="top-left"
        container="body"
      >
        <div
          class="flex-grow-1"
          ngxClipboard
          (cbOnSuccess)="copiedSuccess()"
          [cbContent]="getUserCustomPropValue(prop)"
        >
          <small
            class="property-title padding-top-5"
            data-test="user-card-user-email-span"
          >
            {{ getUserCustomPropTitle(prop) }}
          </small>
          <div
            *ngIf="!getUserCustomPropValue(prop)"
            class="prop-value padding-bottom-5 text-muted"
          >
            {{ t('general.unknown') }}
          </div>
          <div
            *ngIf="!isLink(prop)"
            class="prop-value padding-bottom-5"
            data-test="user-card-user-email-span"
          >
            {{ getUserCustomPropValue(prop) }}
          </div>
          <div
            *ngIf="isLink(prop)"
            class="prop-value padding-bottom-5"
          >
            <a
              (click)="$event.stopPropagation()"
              target="_blank"
              [attr.href]="getUserCustomPropValue(prop)"
            >
              {{ getUserCustomPropValue(prop) }}
            </a>
          </div>
        </div>
      </div>

      <div class="flex padding-right-5">
        <div
          ngbDropdown
          display="static"
          #dropMenu="ngbDropdown"
          class="edit-prop-btn hide"
        >
          <!-- Необходим тег с директивой ngbDropdownToggle, чтобы не было ошибок -->
          <!-- https://github.com/ng-bootstrap/ng-bootstrap/issues/3303 -->
          <div
            ngbDropdownToggle
            class="hide"
          ></div>
          <button
            (click)="dropMenu.toggle(); cdr.detectChanges()"
            class="btn btn-secondary"
            type="button"
          >
            <i class="btn-icon cqi-sm cqi-ellipsis"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-position"
            ngbDropdownMenu
          >
            <div
              class="cursor-pointer dropdown-item"
              ngbDropdownItem
              (click)="editUserPropertyModal(prop)"
            >
              <i class="btn-icon cqi-sm cqi-pencil dropdown-item-icon-left"></i>
              {{ t('userCustomsPropsComponent.edit') }}
            </div>
            <div
              class="cursor-pointer dropdown-item"
              ngbDropdownItem
              ngxClipboard
              (cbOnSuccess)="copiedSuccess()"
              [cbContent]="getUserCustomPropValue(prop)"
            >
              <i class="btn-icon cqi-sm cqi-copy dropdown-item-icon-left"></i>
              {{ t('userCustomsPropsComponent.copy') }}
            </div>
            <div
              class="cursor-pointer text-danger dropdown-item"
              ngbDropdownItem
              (click)="removeUserProperty.emit(prop)"
            >
              <i class="btn-icon cqi-sm cqi-trash dropdown-item-icon-left"></i>
              {{ t('userCustomsPropsComponent.delete') }}
            </div>
          </div>
        </div>

        <div
          *ngIf="pinningPossibility"
          class="edit-prop-btn justify-center hide"
          [ngbTooltip]="t('userCustomsPropsComponent.pin')"
          placement="top-right"
          container="body"
        >
          <button
            class="btn btn-secondary"
            type="button"
            (click)="pinProp.emit(prop)"
          >
            <i class="btn-icon cqi-sm cqi-pin"></i>
          </button>
        </div>

        <div
          *ngIf="!pinningPossibility"
          class="flex pin justify-center align-items-center"
        >
          <i class="text-muted btn-icon cqi-sm cqi-pin"></i>
        </div>
        <div
          *ngIf="!pinningPossibility"
          class="edit-prop-btn justify-center hide"
          [ngbTooltip]="t('userCustomsPropsComponent.unpin')"
          placement="top-right"
          container="body"
        >
          <button
            class="btn btn-secondary"
            type="button"
            (click)="unpinProp.emit(prop)"
          >
            <i class="btn-icon cqi-sm cqi-pin-strikethrough"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { TriggerChainTrackService } from '@panel/app/pages/trigger-chains/shared/services/track-service/trigger-chain-track.service';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';

import { TRIGGER_CHAIN_ACTION_BUTTON } from './trigger-chain-actions.types';

/**
 * Компонент для работы с действиями над цепочкой
 */
@Component({
  selector: 'cq-trigger-chain-actions',
  templateUrl: './trigger-chain-actions.component.html',
  styleUrls: ['./trigger-chain-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainActionsComponent {
  @Input({ required: true })
  accessToAutoMessagesTotal: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  @Input({ required: true })
  accessToTelegramAutoMessages: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  @Input({ required: true })
  isEdit = false;

  @Input({ required: true })
  isActive = false;

  /** Callback на клик по кнопке запуска цепочки */
  @Output()
  clickOnRunButton: Subject<void> = new Subject<void>();

  /** Callback на клик по кнопке сохранения цепочки */
  @Output()
  clickOnSaveButton: Subject<void> = new Subject<void>();

  @Output()
  clickOnShowPaywall: Subject<void> = new Subject<void>();

  @Output()
  clickOnStatusChange: Subject<boolean> = new Subject<boolean>();

  @Output()
  clickStopAndSave: Subject<void> = new Subject<void>();

  @Output()
  clickOnCopyButton: Subject<void> = new Subject<void>();

  TRIGGER_CHAIN_ACTION_BUTTON = TRIGGER_CHAIN_ACTION_BUTTON;

  constructor(private readonly triggerChainTrackService: TriggerChainTrackService) {}

  onClickCopyButton(): void {
    this.clickOnCopyButton.next();
  }

  /** Обрабатывает клик на кнопку запуска цепочки */
  onClickRunButton(): void {
    this.clickOnRunButton.next();
  }

  onClickStopAndSave(): void {
    this.clickStopAndSave.next();
  }

  /** Обрабатывает клик на кнопку сохранения цепочки */
  onClickSaveButton(): void {
    this.clickOnSaveButton.next();
  }

  onChangeStatusButtonClick(): void {
    this.clickOnStatusChange.next(!this.isActive);
  }

  getOrderedButtons(): TRIGGER_CHAIN_ACTION_BUTTON[] {
    let hasAccessToAutoMessageTotal = this.accessToAutoMessagesTotal.hasAccess;
    let hasAccessToTelegramAutoMessage = this.accessToTelegramAutoMessages.hasAccess;
    let isCreate = !this.isEdit;
    let isEdit = this.isEdit;
    let isActive = this.isActive;
    let haveBeenStepCountChanges: boolean = this.isActive;

    if (!hasAccessToAutoMessageTotal) {
      switch (true) {
        case isCreate:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.RUN_NOT_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.SAVE_NOT_ACCESS_NOT_ACCENT,
          ];
        case isEdit && isActive && haveBeenStepCountChanges:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.COPY_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.SAVE_NOT_ACCESS_ACCENT,
          ];
        case isEdit && isActive && !haveBeenStepCountChanges:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.PAUSE_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.COPY_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.PAUSE_AND_SAVE_NOT_ACCESS_NOT_ACCENT,
          ];
        case isEdit && !isActive:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.ACTIVATE_NOT_ACCESS_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.COPY_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.SAVE_NOT_ACCESS_ACCENT,
          ];
      }
    }

    if (!hasAccessToTelegramAutoMessage) {
      switch (true) {
        case isCreate:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.RUN_NOT_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.SAVE_ACCESS_ACCENT,
          ];
        case isEdit && isActive:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.PAUSE_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.COPY_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.SAVE_NOT_ACCESS_ACCENT,
          ];
        case isEdit && !isActive:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.ACTIVATE_NOT_ACCESS_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.COPY_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.SAVE_ACCESS_NOT_ACCENT,
          ];
      }
    }

    if (hasAccessToAutoMessageTotal && hasAccessToTelegramAutoMessage) {
      switch (true) {
        case isCreate:
          return [TRIGGER_CHAIN_ACTION_BUTTON.SAVE_ACCESS_NOT_ACCENT, TRIGGER_CHAIN_ACTION_BUTTON.RUN_ACCESS_ACCENT];
        case isEdit && isActive:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.PAUSE_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.COPY_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.SAVE_ACCESS_ACCENT,
          ];
        case isEdit && !isActive:
          return [
            TRIGGER_CHAIN_ACTION_BUTTON.COPY_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.SAVE_ACCESS_NOT_ACCENT,
            TRIGGER_CHAIN_ACTION_BUTTON.ACTIVATE_ACCESS_ACCENT,
          ];
      }
    }

    throw new Error('There is not handled case');
  }

  trackClickOnPaywallButton() {
    this.triggerChainTrackService.trackAttemptRunChainWithAccessDenial();
  }
}

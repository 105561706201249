import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { UserTagModule } from '@panel/app/partials/user-tag/user-tag.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { UserTagsComponent } from './user-tags.component';

@NgModule({
  declarations: [UserTagsComponent],
  exports: [UserTagsComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    NgbCollapseModule,
    ClipboardModule,
    NgbTooltipModule,
    UserTagModule,
    AccessDenialPopoverModule,
  ],
  providers: [],
})
export class UserTagsModule {}

import { AbstractControl, ValidatorFn } from '@angular/forms';

export function floatMax(max: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let value: any;

    if (typeof control.value === 'string') {
      value = parseFloat(control.value.toString().replace(',', '.'));
    } else {
      value = parseFloat(control.value);
    }

    // Если значение больше максимума, возвращаем ошибку
    return value > max ? { max: true } : null;
  };
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import { finalize } from 'rxjs/operators';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { TELEGRAM_API_ERRORS } from '@http/integration/integrations/telegram/constants/telegram-integration.constants';
import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';
import { IntegrationService } from '@http/integration/services/integration.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-telegram[currentAppId][integrationExternal]',
  templateUrl: './telegram.component.html',
  styleUrls: ['./telegram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramComponent implements OnInit {
  /** Текущее приложение */
  @Input() currentAppId!: string;
  /** Текущая или новая интеграция во внешнем формате */
  @Input() integrationExternal!: TelegramIntegrationExternal;
  /** Текущий язык */
  activeLang: string;
  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: TelegramIntegration;
  /** Название типа текущей интеграции */
  integrationTypeName!: string;
  /** Режим редактирования интеграции */
  isEdit!: boolean;
  /** Свёрнута или нет инструкция по настройке интеграции */
  isManualExpanded!: boolean;
  /** Флаг выполнения запроса */
  isRequestPerformed: boolean = false;
  /** Текущее имя проекта */
  projectName = environment.projectName;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly integrationFactory: IntegrationFactory,
    private readonly integrationService: IntegrationService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly uiRouter: UIRouter,
  ) {
    this.activeLang = translocoService.getActiveLang();
  }

  /** Получение контрола с токеном бота */
  get botTokenControl() {
    return this.integration.form.controls.settings.controls.botToken;
  }

  /** Получение контрола с названием интеграции */
  get nameControl() {
    return this.integration.form.controls.name;
  }

  ngOnInit(): void {
    this.isEdit = !!this.integrationExternal.id;
    this.integrationTypeName = this.translocoService.translate(
      `models.integration.types.${this.integrationExternal.type}.name`,
    );
    this.isManualExpanded = !!this.integrationExternal.id;

    if (this.isEdit) {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.TELEGRAM, this.integrationExternal);
    } else {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.TELEGRAM);
    }
  }

  /** Создание интеграции */
  create(): void {
    this.isRequestPerformed = true;

    this.integrationService
      .create(this.currentAppId, this.integration)
      .pipe(
        finalize(() => {
          this.isRequestPerformed = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        (integration) => {
          this.goToConfiguredIntegration(integration);

          this.toastService.success(
            this.translocoService.translate('integrations.integration.toasts.created', {
              integrationTypeName: this.translocoService.translate(
                'models.integration.types.' + integration.type + '.name',
              ),
            }),
          );
        },
        (error) => {
          switch (error.error) {
            case TELEGRAM_API_ERRORS.VALIDATION_ERROR:
            case TELEGRAM_API_ERRORS.INVALID_ACCESS_TOKEN:
              this.integration.form.controls.settings.controls.botToken.setErrors({
                [TELEGRAM_API_ERRORS.VALIDATION_ERROR]: true,
              });
              break;
          }
        },
      );
  }

  /**
   * Перенаправление на настроенную интеграцию, после её создания
   *
   * @param integration - Созданная интеграция
   */
  goToConfiguredIntegration(integration: TelegramIntegration) {
    const params = {
      integrationType: integration.type,
      integrationId: integration.id,
    };

    this.uiRouter.stateService.go(`app.content.integrations.details.configured.${integration.type}`, params, {
      location: 'replace', // Чтобы кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
    });
  }

  /** Отправка формы с настройками интеграции */
  onSubmit(): void {
    if (this.integration.form.valid) {
      this.isEdit ? this.save() : this.create();
    } else {
      this.integration.form.markAllAsTouched();
    }
  }

  /** Сохранение интеграции */
  save(): void {
    this.isRequestPerformed = true;

    this.integrationService
      .save(this.currentAppId, this.integration)
      .pipe(
        finalize(() => {
          this.isRequestPerformed = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        () => {
          this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));
        },
        (error) => {
          switch (error.error) {
            case TELEGRAM_API_ERRORS.VALIDATION_ERROR:
            case TELEGRAM_API_ERRORS.INVALID_ACCESS_TOKEN:
              this.integration.form.controls.settings.controls.botToken.setErrors({
                [TELEGRAM_API_ERRORS.VALIDATION_ERROR]: true,
              });
              break;
            case TELEGRAM_API_ERRORS.TELEGRAM_BOT_IDS_NOT_MATCHED:
              this.integration.form.controls.settings.controls.botToken.setErrors({
                [TELEGRAM_API_ERRORS.TELEGRAM_BOT_IDS_NOT_MATCHED]: true,
              });
              break;
          }
        },
      );
  }
}

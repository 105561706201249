import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Компонент для универсального модального окна безопасного удаления
 *
 * При создании модального окна в resolve можно передать слудющие параметры:
 * @property body Текст тела модального окна
 * @property confirmButtonText Текст кнопки подтвеждения удаления
 * @property confirmedItemName Что надо вести для подтверждения
 * @property confirmText Текст описания того что надо сделать
 * @property heading Текст заголовка модального окна
 */
@Component({
  selector: 'cq-safe-remove[body][confirmedItemName]',
  templateUrl: './safe-remove.component.html',
  styleUrls: ['./safe-remove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SafeRemoveComponent implements OnInit {
  @Input() body!: string;
  @Input() heading!: string;
  @Input() confirmText!: string;
  @Input() confirmedItemName!: string;
  @Input() confirmButtonText!: string;

  @Input() modalWindowParams: any;

  confirmNameControl!: UntypedFormControl;

  constructor(public readonly activeModal: NgbActiveModal, private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    this.body = this.modalWindowParams?.body || this.body;
    this.heading =
      this.modalWindowParams?.heading || this.heading || this.transloco.translate('modals.safeRemove.title');
    this.confirmText =
      this.modalWindowParams?.confirmText ||
      this.confirmButtonText ||
      this.transloco.translate('modals.safeRemove.confirmText', { itemName: this.confirmedItemName });
    this.confirmButtonText =
      this.modalWindowParams?.confirmButtonText || this.confirmButtonText || this.transloco.translate('general.remove');

    const confirmNameValidator: ValidatorFn = (control) => {
      const value: string = control.value ?? '';
      return value.trim() === this.modalWindowParams?.confirmedItemName || value.trim() === this.confirmedItemName
        ? null
        : { confirmName: control.value };
    };
    this.confirmNameControl = new UntypedFormControl(null, confirmNameValidator);
  }

  confirmRemove() {
    this.confirmNameControl.markAsTouched();
    if (this.confirmNameControl.valid) {
      this.activeModal.close();
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { InsertPropsIntoTextModalData } from '@panel/app/partials/modals/insert-props-into-text/insert-props-into-text-modal.token';

@Component({
  selector: 'cq-insert-props-into-text-modal',
  templateUrl: './insert-props-into-text-modal.component.html',
  styleUrls: ['./insert-props-into-text-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertPropsIntoTextModalComponent implements OnInit {
  /**
   * Инстанс AJS модалки. Нужен для закрытия.
   * TODO Переделать при переезде на A2 компонента, который вызывает эту модалку
   */
  @Input()
  ajsModalInstance?: any;

  /** Параметры, передаваемые в модалку */
  @Input()
  modalWindowParams!: InsertPropsIntoTextModalData;

  defaultValueControl: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required],
  });

  propertyControl: FormControl<string | null> = new FormControl<string | null>(null, {
    validators: [Validators.required],
  });

  constructor(public readonly activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.modalWindowParams.propName) {
      const selectedProp = this.modalWindowParams.userProps.find(
        (prop) => prop.name === this.modalWindowParams.propName,
      )!;
      this.propertyControl.setValue(selectedProp.name);
    }
    if (this.modalWindowParams.defaultValue) {
      this.defaultValueControl.setValue(this.modalWindowParams.defaultValue);
    }
  }

  addProp() {
    this.closeModal({
      property: this.propertyControl.value,
      defaultValue: this.defaultValueControl.value,
      prettyName: this.modalWindowParams.userProps.find((prop) => prop.name === this.propertyControl.value)?.prettyName,
    });
  }

  closeModal(value?: any) {
    this.ajsModalInstance ? this.ajsModalInstance.close(value) : this.activeModal.close(value);
  }

  dismissModal() {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.activeModal.dismiss();
  }
}

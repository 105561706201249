import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { INTEGRATION_TYPES } from '../../../app/http/integration/constants/integration.constants';
import { MESSAGE_PART_SENDER_TYPES } from '../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.autoMessages').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.messagesAjs', {
        url: '',
        template: '<div ui-view></div>',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
        },
      })

      .state('app.content.messagesAjs.create', {
        url: '/messages/auto/create?{fromStarterGuideStep}',
        bindings: {
          autoEvents: 'autoEvents',
          activeMessagesAmounts: 'activeMessagesAmounts',
          amocrmIntegrations: 'amocrmIntegrations',
          telegramIntegrations: 'telegramIntegrations',
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          directories: 'directories',
          djangoUser: 'djangoUser',
          editableMessage: 'editableMessage',
          emailNotifications: 'emailNotifications',
          emailRecipients: 'emailRecipients',
          isEdit: 'isEdit',
          messageSenders: 'messageSenders',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          segments: 'segments',
          tags: 'tags',
          teamMembers: 'teamMembers',
          usedTemplate: 'usedTemplate',
        },
        component: 'cqAutoMessageEditor',
        resolve: {
          autoEvents: getAutoEvents,
          activeMessagesAmounts: getActiveMessagesAmounts,
          amocrmIntegrations: getAmocrmIntegrations,
          telegramIntegrations: getTelegramActiveIntegrations,
          directories: getDirectories,
          editableMessage: angular.bind(null, angular.identity, {}),
          emailNotifications: getEmailNotificationIntegrations,
          emailRecipients: getEmailNotificationRecipients,
          isEdit: angular.bind(null, angular.identity, false),
          messageSenders: getMessageSenders,
          properties: getProperties,
          segments: getSegments,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          usedTemplate: angular.bind(null, angular.identity, {}),
        },
      })

      .state('app.content.messagesAjs.createOnTemplate', {
        url: '/messages/auto/create/on-template/{templateId:id}?{step:int}',
        bindings: {
          autoEvents: 'autoEvents',
          activeMessagesAmounts: 'activeMessagesAmounts',
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          directories: 'directories',
          editableMessage: 'editableMessage',
          emailNotifications: 'emailNotifications',
          emailRecipients: 'emailRecipients',
          isEdit: 'isEdit',
          messageSenders: 'messageSenders',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          segments: 'segments',
          tags: 'tags',
          teamMembers: 'teamMembers',
          usedTemplate: 'usedTemplate',
        },
        component: 'cqAutoMessageEditor',
        resolve: {
          autoEvents: getAutoEvents,
          activeMessagesAmounts: getActiveMessagesAmounts,
          directories: getDirectories,
          editableMessage: angular.bind(null, angular.identity, {}),
          emailNotifications: getEmailNotificationIntegrations,
          emailRecipients: getEmailNotificationRecipients,
          isEdit: angular.bind(null, angular.identity, false),
          messageSenders: getMessageSenders,
          properties: getProperties,
          segments: getSegments,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          usedTemplate: getMessagePartFromTemplate,
        },
      })

      .state('app.content.messagesAjs.createOnReadyTemplate', {
        url: '/messages/auto/create/on-ready-template/{readyTemplateId:id}?{step:int}',
        bindings: {
          autoEvents: 'autoEvents',
          activeMessagesAmounts: 'activeMessagesAmounts',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          directories: 'directories',
          editableMessage: 'editableMessage',
          emailNotifications: 'emailNotifications',
          emailRecipients: 'emailRecipients',
          isEdit: 'isEdit',
          messageSenders: 'messageSenders',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          segments: 'segments',
          tags: 'tags',
          teamMembers: 'teamMembers',
          usedTemplate: 'usedTemplate',
        },
        component: 'cqAutoMessageEditor',
        resolve: {
          autoEvents: getAutoEvents,
          activeMessagesAmounts: getActiveMessagesAmounts,
          directories: getDirectories,
          editableMessage: angular.bind(null, angular.identity, {}),
          emailNotifications: getEmailNotificationIntegrations,
          emailRecipients: getEmailNotificationRecipients,
          isEdit: angular.bind(null, angular.identity, false),
          messageSenders: getMessageSenders,
          properties: getProperties,
          segments: getSegments,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          usedTemplate: getMessagePartFromReadyTemplate,
        },
      })

      .state('app.content.messagesAjs.statistics', {
        url: '/messages/auto/{messageId:id}',
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          currentMessage: 'currentMessage',
          djangoUser: 'djangoUser',
          pushSettings: 'pushSettings',
          telegramIntegrations: 'telegramIntegrations',
        },
        component: 'cqAutoMessageStatistics',
        resolve: {
          currentMessage: getMessage,
          pushSettings: getPushSettings,
          telegramIntegrations: getTelegramIntegrations,
        },
      })

      .state('app.content.messagesAjs.edit', {
        url: '/messages/auto/{messageId:id}/edit?{step:int}',
        bindings: {
          autoEvents: 'autoEvents',
          activeMessagesAmounts: 'activeMessagesAmounts',
          amocrmIntegrations: 'amocrmIntegrations',
          telegramIntegrations: 'telegramIntegrations',
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          directories: 'directories',
          djangoUser: 'djangoUser',
          editableMessage: 'editableMessage',
          emailNotifications: 'emailNotifications',
          emailRecipients: 'emailRecipients',
          isEdit: 'isEdit',
          messageSenders: 'messageSenders',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          segments: 'segments',
          tags: 'tags',
          teamMembers: 'teamMembers',
          usedTemplate: 'usedTemplate',
        },
        component: 'cqAutoMessageEditor',
        reloadOnSearch: false,
        resolve: {
          autoEvents: getAutoEvents,
          activeMessagesAmounts: getActiveMessagesAmounts,
          amocrmIntegrations: getAmocrmIntegrations,
          telegramIntegrations: getTelegramActiveIntegrations,
          directories: getDirectories,
          editableMessage: getMessage,
          emailNotifications: getEmailNotificationIntegrations,
          emailRecipients: getEmailNotificationRecipients,
          isEdit: angular.bind(null, angular.identity, true),
          messageSenders: getMessageSenders,
          properties: getProperties,
          segments: getSegments,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          usedTemplate: angular.bind(null, angular.identity, {}),
        },
      })

      .state('app.content.messagesAjs.edit.copy', {
        url: '/copy',
      });
  }

  function getAutoEvents(currentApp, trackMasterModel) {
    return firstValueFrom(trackMasterModel.getList(currentApp.id));
  }

  /**
   * Затирает в sessionStorage фильтры автосообщений
   */
  function clearAutoMessagesFilters() {
    sessionStorage.setItem('autoMessageFilters', '');
  }

  /**
   * Получение количества активных автосообщений
   *
   * @param messageModel
   *
   * @return {*}
   */
  function getActiveMessagesAmounts(messageModel) {
    return firstValueFrom(messageModel.getActiveTriggerMessageCount());
  }

  /**
   * Получение всех тегов пользователя
   *
   * @param currentApp
   * @param tagModel
   * @returns {Promise}
   */
  function getAllUserTags(currentApp, tagModel) {
    return firstValueFrom(tagModel.getList(currentApp.id));
  }

  /**
   * Получение списка директорий автосообщений
   *
   * @param currentApp
   * @param messageDirectoryModel
   * @returns {Promise}
   */
  function getDirectories(currentApp, messageDirectoryModel) {
    return firstValueFrom(messageDirectoryModel.getList(currentApp.id, true, true));
  }

  function getTelegramIntegrations(integrationModel, currentApp) {
    return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
      return [...response[0]].filter((integration) => {
        return integration.active === true;
      });
    });
  }

  /**
   * Получение автосообщения по messageId
   *
   * @param $stateParams
   * @param $transition$
   * @param messageModel
   * @returns {Promise}
   */
  function getMessage($stateParams, $transition$, messageModel) {
    let messagePartModel = $transition$.injector().get('messagePartModel');

    return getMessage().then(getMessageParts);

    function getMessage() {
      return firstValueFrom(messageModel.getAutoMessage($stateParams.messageId, true, true, true, false, true));
    }

    function getMessageParts(currentMessage) {
      // флаг includeClosed выставляется в false, т.к редактировать и сохранять можно только открытые варианты сообщения
      return firstValueFrom(messagePartModel.getMessageTestGroupParts(currentMessage.activeTestGroup.id, false)).then(
        getMessageTestGroupPartsSuccess,
      );

      function getMessageTestGroupPartsSuccess(parts) {
        currentMessage.activeTestGroup.parts = parts;
        return currentMessage;
      }
    }
  }

  /**
   * Получение варианта сообщения из готового шаблона
   *
   * @param $stateParams
   * @param $transition$
   * @param readyMessageTemplateModel
   * @returns {*}
   */
  function getMessagePartFromReadyTemplate($stateParams, $transition$, readyMessageTemplateModel) {
    let messagePartModel = $transition$.injector().get('messagePartModel');

    if ($stateParams.readyTemplateId) {
      return firstValueFrom(readyMessageTemplateModel.get($stateParams.readyTemplateId)).then(
        messagePartModel.parseFromTemplate.bind(messagePartModel),
      );
    } else {
      return messagePartModel.parseFromTemplate(readyMessageTemplateModel.getDefault());
    }
  }

  /**
   * Получение варианта сообщения
   *
   * @param $stateParams
   * @param $transition$
   * @param currentApp
   * @param planFeatureAccessService
   *
   * @returns {Promise}
   */
  function getMessagePartFromTemplate($stateParams, $transition$, currentApp, planFeatureAccessService) {
    let messageTemplateModel = $transition$.injector().get('messageTemplateModel');
    let messagePartModel = $transition$.injector().get('messagePartModel');
    const accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, currentApp);

    if (accessToMessagesTemplates.hasAccess && $stateParams.templateId) {
      return firstValueFrom(messageTemplateModel.get($stateParams.templateId)).then(
        messagePartModel.parseFromTemplate.bind(messagePartModel),
      );
    } else {
      return messagePartModel.parseFromTemplate(messageTemplateModel.getDefault());
    }
  }

  /**
   * Получение отправителей
   *
   * @param $transition$
   * @param currentApp
   */
  function getMessageSenders($transition$, currentApp) {
    let messageSenderModel = $transition$.injector().get('messageSenderModel');

    return firstValueFrom(messageSenderModel.getList(currentApp.id)).then(getMessageSendersListSuccess);

    function getMessageSendersListSuccess(messageSenders) {
      for (let i = 0; i < messageSenders.length; i++) {
        messageSenders[i].type = MESSAGE_PART_SENDER_TYPES.MESSAGE_SENDER;
      }

      return messageSenders;
    }
  }

  /**
   * Получения списка email-нотификаций для аппа
   */
  function getEmailNotificationIntegrations(currentApp, integrationModel, INTEGRATION_TYPES) {
    return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.EMAIL_NOTIFICATION);
  }

  /**
   * Получение списка получателей email-оповещений
   */
  function getEmailNotificationRecipients(currentApp, integrationModel) {
    return integrationModel.getEmailRecipients(currentApp.id);
  }

  function getAmocrmIntegrations(currentApp, integrationModel, INTEGRATION_TYPES) {
    return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.AMOCRM);
  }

  /**
   * Получение списка активных ТГ интеграций
   */
  function getTelegramActiveIntegrations(currentApp, integrationModel, INTEGRATION_TYPES) {
    return integrationModel
      .getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)
      .then((tgIntegrationList) => tgIntegrationList.filter((integration) => integration.active));
  }

  /**
   * Получение списка свойств и типов событий
   *
   * @param currentApp
   * @param propertyModel
   * @return {Promise}
   */
  function getProperties(currentApp, propertyModel) {
    return firstValueFrom(propertyModel.getList(currentApp.id));
  }

  /**
   * Получение настроек пушей
   *
   * @param $transition$
   * @param currentApp
   * @returns {Promise}
   */
  function getPushSettings($transition$, currentApp) {
    let pushSettingsModel = $transition$.injector().get('pushSettingsModel');

    return firstValueFrom(pushSettingsModel.get(currentApp.id));
  }

  /**
   * Получение сегментов
   *
   * @param $transition$
   * @param currentApp
   * @param tags
   * @param properties
   * @returns {*}
   */
  function getSegments($transition$, currentApp, tags, properties) {
    let filterAjsModel = $transition$.injector().get('filterAjsModel');
    let segmentModel = $transition$.injector().get('segmentModel');

    return firstValueFrom(segmentModel.getList(currentApp.id, true)).then(getSegmentsSuccess);

    function getSegmentsSuccess(segments) {
      for (var i = 0; i < segments.length; i++) {
        filterAjsModel.linkWithPropsAndTags(segments[i].filters, properties, tags);
      }
      return segments;
    }
  }

  /**
   * Получение членов команды
   *
   * @param $transition$
   * @param currentApp
   */
  function getTeamMembers($transition$, currentApp) {
    let teamMemberModel = $transition$.injector().get('teamMemberModel');

    return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
  }
})();

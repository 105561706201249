import { FederatedPointerEvent } from 'pixi.js';

import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';

/** Сущности, которые участвуют в интеракции */
export const enum INTERACTION_ENTITY {
  /** Экшен */
  INTERACTIVE_BLOCK_PART = 'action',
  /** Шаг */
  BLOCK = 'step',
}

/** Вспомогательная утилита для построения типа интеракции */
export type InteractionConfigUtils<SourceType extends INTERACTION_ENTITY, ViewType extends object> = {
  /** Тип интеракции */
  type: SourceType;
  /** Сущность */
  view: ViewType;
};

/** Конфигурация интеракции */
export type InteractionConfig =
  | InteractionConfigUtils<INTERACTION_ENTITY.INTERACTIVE_BLOCK_PART, AbsInteractiveBlockPartView>
  | InteractionConfigUtils<INTERACTION_ENTITY.BLOCK, IBlockView>;

/** Конфигурация интеракции с event интеракции */
export type ExtendInteractionConfig = InteractionConfig & {
  event: FederatedPointerEvent;
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { InputListComponent } from './input-list.component';

@NgModule({
  declarations: [InputListComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    SharedModule,
    TranslocoModule,
    ReactiveFormsModule,
    NgbTooltipModule,
  ],
  exports: [InputListComponent],
})
export class InputListModule {}

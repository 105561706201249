import { DOCUMENT } from '@angular/common';
import { InjectionToken, NgZone, Provider } from '@angular/core';
import { Renderer } from '@pixi/core';
import { Container, Ticker, UPDATE_PRIORITY } from 'pixi.js';
import { fromEvent } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { DestroyService } from '@panel/app/services';

export const PIXI_APP = new InjectionToken<PixiApplication>('Wrapper for pixi.js application');

Ticker.shared.autoStart = false;
Ticker.shared.stop();

export class PixiApplication {
  renderer: Renderer;
  ticker: Ticker;
  stage: Container;

  constructor() {
    this.renderer = new Renderer({
      backgroundColor: 0xe3e5e8,
      resolution: 2,
      autoDensity: true,
      antialias: true,
    });

    this.ticker = new Ticker();
    this.stage = new Container();

    this.ticker.maxFPS = 1;
    this.ticker.add(this.render.bind(this), UPDATE_PRIORITY.LOW);
    this.ticker.start();
  }

  get screen() {
    return this.renderer.screen;
  }

  render() {
    this.renderer.render(this.stage);
  }

  destroy() {
    this.ticker.destroy();
    this.renderer.removeAllListeners();
    this.renderer.destroy(true);
    this.stage.destroy({ texture: true, baseTexture: true });
  }
}

export const pixiTokenProvider: Provider = {
  provide: PIXI_APP,
  useFactory: (zone: NgZone, destroy$: DestroyService, document: Document): PixiApplication => {
    return zone.runOutsideAngular(() => {
      const app = new PixiApplication();

      fromEvent(document, 'visibilitychange')
        .pipe(takeUntil(destroy$))
        .subscribe(() => {
          if (document.hidden) {
            app.ticker.stop();
            return;
          }
          app.ticker.start();
        });

      destroy$.pipe(first()).subscribe(() => {
        app.destroy();
      });

      if (!environment.production) {
        //@ts-ignore
        globalThis.__PIXI_RENDERER__ = app.renderer;
      }

      return app;
    });
  },
  deps: [NgZone, DestroyService, DOCUMENT],
};

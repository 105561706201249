import { translate } from '@jsverse/transloco';
import { Container, Graphics, TextStyle } from 'pixi.js';

import { CHANNEL_TYPES } from '@http/channel/channel.constants';
import { ChannelModel } from '@http/channel/channel.model';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';

import { BOT_GREY, BOT_WHITE_COLOR } from '../../utils/colors';
import { renderCanvasText } from '../../utils/helpers-functions';
import { ActionStyle, MAX_ACTION_WIDTH, SimpleActionABS } from '../abstract';
import { getHorizontalSplitter } from '../shared-textures';
import { CHANNEL_ACTION_RECT_SIZE, getChannelActionSquareBackground } from './channel-action.textures';

export class BotChannelAction extends SimpleActionABS {
  protected style!: ActionStyle;

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 10,
        horizontal: 10,
      },
      border: {
        size: 1,
        color: 0xc7cad1,
        radius: 10,
      },
      background: {
        color: BOT_WHITE_COLOR,
      },
    };
  }

  render(): Graphics | null {
    const element = this.element ?? new Graphics();
    const padding = this.style.padding;

    const textOptionsTop: Partial<TextStyle> = {
      padding: this.style.padding.horizontal,
      wordWrapWidth: MAX_ACTION_WIDTH - padding.horizontal * 2,
      align: 'center',
      fontWeight: '100',
      fill: BOT_GREY,
    };

    const rectSize = CHANNEL_ACTION_RECT_SIZE;
    const channelTranslation = translate('classes.action.canvasText.channel');
    const text1 = renderCanvasText(channelTranslation, textOptionsTop);

    const line = getHorizontalSplitter(this.pixiApp.renderer);
    const icon = this.renderChannelIcon(rectSize);

    if (!icon) {
      return null;
    }

    const paddingToIcon = 7.5;
    const textPadding = rectSize + paddingToIcon;
    line.position.set((MAX_ACTION_WIDTH - line.width) / 2, rectSize / 2);
    icon.position.set((MAX_ACTION_WIDTH - icon.width) / 2, 0);
    text1.position.set((MAX_ACTION_WIDTH - text1.width) / 2, textPadding);

    element.addChild(text1);
    element.addChild(line);
    element.addChild(icon);

    return element;
  }

  private renderChannelIcon(rectSize: number): Container | void {
    const backgroundContainer = getChannelActionSquareBackground(this.pixiApp.renderer);
    const channels = this.validationExtra.channels;
    const channelId = this.form.controls.body.value;
    const channel = channels.find((ch) => ch.id === channelId);
    if (!channel) {
      return;
    }
    const typesIcons: Record<string, string> = {
      facebook: '\ue984',
      vk: '\ue987',
      telegram: '\ue985',
      email: '\ue912',
      yandex_dialogs: '\ue9a8',
      viber: '\ue9cd',
      mobile: '\ue93d',
      whatsapp: '\ue9ce',
      instagram: '\ue9a0',
    };
    let icon;
    switch (channel.type) {
      case CHANNEL_TYPES.MANUAL:
        icon = renderCanvasText(ChannelModel.getChannelShortName(channel.name), {
          fontSize: 15,
          fill: '#5c6370',
          align: 'center',
        });
        icon.position.set(backgroundContainer.width / 2 - icon.width / 2, (rectSize - icon.height) / 2);
        backgroundContainer.addChild(icon);
        return backgroundContainer;
      default:
        icon = PixiIconRenderService.renderIcon(typesIcons[channel.type], {
          fill: BOT_GREY,
        });
        icon.position.set(backgroundContainer.width / 2 - icon.width / 2, (rectSize - icon.height) / 2);
        backgroundContainer.addChild(icon);
        return backgroundContainer;
    }
  }
}

<ng-container *transloco="let t; read: 'triggerChainGeneralStatisticsComponent'">
  <div class="container-fluid limited-container padding-bottom-60">
    <div class="d-flex justify-space-between py-20">
      <cq-trigger-chain-general-statistics-breadcrumbs
        [chainName]="chain.name"
      ></cq-trigger-chain-general-statistics-breadcrumbs>

      <a
        class="btn btn-primary ml-auto"
        uiSref="app.content.triggerChains.editor.edit"
        [uiParams]="{ id: chain.id }"
        >{{ t('editButton') }}</a
      >
    </div>

    <div class="card">
      <cq-trigger-chain-general-statistics-table></cq-trigger-chain-general-statistics-table>
    </div>
  </div>
</ng-container>

import { of } from 'rxjs';

import {
  ActionValidationExtra,
  BaseBotActionForm,
  CustomControlsWithBodyJsonRequired,
} from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

export class BotCloseActionForm extends BaseBotActionForm {
  get allTouchedChanges$() {
    return of(false);
  }

  getCustomControls(action: ChatBotAction, validationExtra: ActionValidationExtra): CustomControlsWithBodyJsonRequired {
    return {
      bodyJson: new GenericFormGroup({}),
    };
  }
}

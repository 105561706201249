import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ConversationPartPreviewImageComponent } from './conversation-part-preview-image.component';

@NgModule({
  declarations: [ConversationPartPreviewImageComponent],
  exports: [ConversationPartPreviewImageComponent],
  imports: [CommonModule, TranslocoModule],
})
export class ConversationPartPreviewImageModule {}

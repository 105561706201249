import { OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { InteractionEvent, Interactive, PIXI_INTERACTION_EVENT } from './pixi-interaction.types';

export type OfUnion<T extends { type: string }> = {
  [P in T['type']]: Extract<T, { type: P }>;
};

export function pickOnlyEvent<
  TypeName extends PIXI_INTERACTION_EVENT,
  InstanceType extends OfUnion<InteractionEvent>[TypeName]['instance'],
  T extends Interactive<TypeName, InstanceType> & InteractionEvent,
>(eventType: TypeName): OperatorFunction<InteractionEvent, T> {
  return (source$) =>
    source$.pipe(
      filter<InteractionEvent, T>((event): event is T => {
        return event.type === eventType;
      }),
    );
}

export function filterAndExtractInstance<
  TypeName extends PIXI_INTERACTION_EVENT,
  InstanceType extends OfUnion<InteractionEvent>[TypeName]['instance'],
  T extends Interactive<TypeName, InstanceType> & InteractionEvent,
>(eventType: TypeName): OperatorFunction<InteractionEvent, InstanceType> {
  return (source$) =>
    source$.pipe(
      pickOnlyEvent<TypeName, InstanceType, T>(eventType),
      map((event) => event.instance),
    );
}

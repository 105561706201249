import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { catchError, finalize } from 'rxjs/operators';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { VkIntegrationExternal } from '@http/integration/integrations/vk/interfaces/vk-integration.interfaces';
import { VkIntegration } from '@http/integration/integrations/vk/vk-integration';
import { IntegrationService } from '@http/integration/services/integration.service';
import { DestroyService, SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Компонент для работы с новой настройкой интеграции с Вконтакте
 */

@Component({
  selector: 'cq-vk-new[currentAppId][integrationExternal][isEdit]',
  templateUrl: './vk-new.component.html',
  styleUrls: ['./vk-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class VkNewComponent implements OnInit {
  /** ID текущего приложения */
  @Input()
  currentAppId!: string;

  /** Текущая или новая интеграция во внешнем формате */
  @Input()
  integrationExternal!: VkIntegrationExternal;

  /** Режим редактирования интеграции */
  @Input()
  isEdit!: boolean;

  /** OAuth Url для авторизации групп */
  groupsOAuthUrl!: string;

  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: VkIntegration;

  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;

  /** Авторизован ли пользователь в ВК */
  isAuthorized!: boolean;

  requestIsPending = false;

  constructor(
    @Inject(CarrotquestHelper) private readonly carrotquestHelper: CarrotquestHelper,
    @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    @Inject(IntegrationFactory) private readonly integrationFactory: IntegrationFactory,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(SystemToastService) private readonly systemToastService: SystemToastService,
    @Inject(ToastService) private readonly toastService: ToastService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {}

  /** Получение контрола с названием интеграции */
  get nameControl() {
    return this.integration.form.controls.name;
  }

  /** Получение массива контролов с группами */
  get vkGroupForms() {
    return this.integration.form.controls.settings.controls.groups;
  }

  ngOnInit(): void {
    if (this.isEdit) {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.VK, this.integrationExternal);
    } else {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.VK);
    }

    this.isAuthorized = !!this.integration.settings.oauthAccessToken;
  }

  /** Необходимо ли авторизовывать группы */
  isGroupAuthorizationRequired(): boolean {
    return !!this.groupsOAuthUrl;
  }

  /** Callback на неудачную авторизацию сообществ VK */
  onAuthorizeGroupsError() {
    this.trackAuthorizeGroupsError();

    /** Покажем тост, если пользователь отменил авторизацию сообществ */
    this.toastService.danger(this.translocoService.translate('vkNewComponent.toasts.authGroupsError'));
  }

  /** Callback на успешную авторизацию сообществ VK */
  onAuthorizeGroupsSuccess() {
    if (this.integration.id) {
      this.requestIsPending = true;
      this.integrationService
        .vkSubscribe(
          this.integration.id,
          this.integration.groupsForSubscribing.map((group) => group.groupId || group.id),
        )
        .pipe(
          catchError((err) => {
            throw err;
          }),
          finalize(() => {
            this.requestIsPending = false;
            this.cdr.markForCheck();
          }),
        )
        .subscribe((settings) => {
          this.integration.updateSettings(settings);
        });
    }
    this.trackAuthorizeGroupsSuccess();

    this.showIntegrationSaveToast();
  }

  /** Callback на неудачную авторизацию пользователя VK */
  onAuthorizeError() {
    this.trackAuthorizeError();
  }

  /** Callback на успешную авторизацию пользователя VK */
  onAuthorizeSuccess() {
    this.trackAuthorizeSuccess();

    this.toastService.success(
      this.translocoService.translate('integrations.integration.toasts.created', {
        integrationTypeName: this.translocoService.translate(
          'models.integration.types.' + this.integration.type + '.name',
        ),
      }),
    );
  }

  /** Обработчик клика на сохранение интеграции */
  onClickSaveIntegration() {
    this.saveIntegration();
  }

  /** Есть ли возможность сохранить интеграцию */
  isDisabledSaveIntegration(): boolean | null {
    return (this.integration.form.invalid ?? null) || this.requestIsPending;
  }

  /** Обработчик выбора группы */
  onChoiceGroups(groupsId: number[]) {
    if (groupsId.length === 0) {
      this.groupsOAuthUrl = '';
      this.cdr.detectChanges();
    } else {
      this.integrationService.vkGetGroupsOAuthUrl(this.integration.id!, groupsId).subscribe((response) => {
        this.groupsOAuthUrl = response.authUrl;
        this.cdr.detectChanges();
      });
    }
  }

  /** Сохранение интеграции */
  saveIntegration() {
    this.requestIsPending = true;
    this.integrationService
      .vkSave(this.currentAppId, this.integration)
      .pipe(
        catchError((err) => {
          this.systemToastService.requestError();
          throw err;
        }),
        finalize(() => {
          this.requestIsPending = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(() => {
        this.showIntegrationSaveToast();
      });
  }

  /** Показ тоста успешного сохранения интеграции */
  showIntegrationSaveToast() {
    this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));
  }

  /** Трек неудачной авторизации пользователя VK */
  trackAuthorizeError() {
    this.carrotquestHelper.track(`Интеграции - ${this.integration.type} - аккаунт - ошибка`);
  }

  /** Трек успешной авторизации пользователя VK */
  trackAuthorizeSuccess() {
    this.carrotquestHelper.track(`Интеграции - ${this.integration.type} - аккаунт - авторизован`);
  }

  /** Трек успешной авторизации сообществ VK */
  trackAuthorizeGroupsError() {
    this.carrotquestHelper.track(`Интеграции - ${this.integration.type} - сообщества - ошибка`);
  }

  /** Трек неудачной авторизации сообществ VK */
  trackAuthorizeGroupsSuccess() {
    this.carrotquestHelper.track(`Интеграции - ${this.integration.type} - сообщества - получен доступ`);
  }
}

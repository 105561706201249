import { inject } from '@angular/core';
import { IPromise } from 'angular';

import { AppService } from '@http/app/services/app.service';
import { ConversationTagAdapterService } from '@panel/app-old/shared/entities/conversation-tag/adapter/conversation-tag-adapter.service';
import { ConversationTag } from '@panel/app-old/shared/entities/conversation-tag/conversation-tag';
import { ConversationTagFactoryService } from '@panel/app-old/shared/entities/conversation-tag/factory/conversation-tag-factory.service';
import { getAllApiResponse } from '@panel/app-old/shared/entities/conversation-tag/types/conversation-tag-api.types';

/** Http-сервис для работы с тегами диалогов */
export class ConversationTagHttpService {
  /** Сервис из Angular, поэтому inject в обход конструктора */
  private readonly appService = inject(AppService);

  /** Возвращает Id приложения */
  get appId(): string {
    return this.appService.currentAppId;
  }

  //@ngInject
  constructor(
    private readonly APIRequest: any,
    private readonly conversationTagAdapterService: ConversationTagAdapterService,
    private readonly conversationTagFactoryService: ConversationTagFactoryService,
  ) {}

  /**
   * Создаёт тег
   *
   * @param tag - Тег
   */
  create(tag: ConversationTag): IPromise<void> {
    const params = {
      tag: this.conversationTagAdapterService.toApi(tag.dto),
    };

    return this.APIRequest('POST', `/apps/${this.appId}/conversationtags`, params);
  }

  /** Получает список тегов */
  getAll(): IPromise<ConversationTag[]> {
    return this.APIRequest('GET', `/apps/${this.appId}/conversationtags`, {}).then((response: getAllApiResponse) => {
      return this.conversationTagFactoryService.getInstancesFromApi(response.data);
    });
  }

  /**
   * Удаляет тег
   *
   * @param tag - Тег
   */
  remove(tag: ConversationTag): IPromise<void> {
    const params = {
      tag: this.conversationTagAdapterService.toApi(tag.dto),
    };

    return this.APIRequest('DELETE', `/apps/${this.appId}/conversationtags`, params);
  }
}

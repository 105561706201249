/** Форматы Moment'а для отображения времени, которое прошло с момента последней реплики */
export const TIME_FORMATS = {
  ru: {
    exactTime: 'H:mm',
    isLastDay: 'D MMM',
    isLastYear: 'D MMM YYYY',
  },
  en: {
    exactTime: 'h:mm A',
    isLastDay: 'MMM D',
    isLastYear: 'MMM D, YYYY',
  },
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHref',
})
export class RemoveHrefPipe implements PipeTransform {
  private findLinksToAddTargetBlankRegexp = /<a(.*?)>[\s\S]*?<\/a>/gim;
  private findHrefRegexp = /href=".*?"/gim;

  transform(value: string): string {
    if (typeof value === 'string') {
      value = value.replace(this.findLinksToAddTargetBlankRegexp, (entry, match) => {
        const matchWithoutHref = match.replace(this.findHrefRegexp, '');
        return entry.replace(match, matchWithoutHref);
      });
    }
    return value;
  }
}

import { CHAT_BOT_URL_PATHS } from '../../chat-bot-routes.constants';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_STATUSES } from '../../../../../app/http/message/message.constants';

(function () {
  'use strict';

  angular.module('myApp.chatBot.facebookBot').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.messagesAjs.facebookBot', {
        url: CHAT_BOT_URL_PATHS.FACEBOOK_BOT,
        redirectTo: 'app.content.messagesAjs.facebookBot.create',
        template: '<div ui-view></div>',
      })

      .state('app.content.messagesAjs.facebookBot.create', {
        url: '/new',
        resolve: {
          channels: getChannels,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
        },
        bindings: {
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          tags: 'tags',
          teamMembers: 'teamMembers',
        },
        component: 'cqFacebookBotEdit',
      })

      .state('app.content.messagesAjs.facebookBot.edit', {
        url: '/{facebookBotId:id}/edit',
        resolve: {
          channels: getChannels,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          facebookBot: getFacebookBot,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
        },
        bindings: {
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          facebookBot: 'facebookBot',
          tags: 'tags',
          teamMembers: 'teamMembers',
        },
        component: 'cqFacebookBotEdit',
      });

    /**
     * Получение всех тегов пользователя
     *
     * @param currentApp
     * @param tagModel
     * @returns {Promise}
     */
    function getAllUserTags(currentApp, tagModel) {
      return firstValueFrom(tagModel.getList(currentApp.id));
    }

    /**
     * Получение списка каналов
     *
     * @param channelModel
     * @param currentApp
     * @return {Promise}
     */
    function getChannels(channelModel, currentApp) {
      return firstValueFrom(channelModel.getList(currentApp.id));
    }

    /**
     * Получение информации об активных Facebook ботах
     *
     * @param chatBotModel
     */
    function getDataAboutActiveFacebookBots(chatBotModel) {
      return firstValueFrom(chatBotModel.getFacebookBot()).then((response) => {
        const facebookBots = response ? [response].filter((bot) => bot.active) : [];
        return {
          ids: facebookBots.map((bot) => bot.id),
          amount: facebookBots.length,
        };
      });
    }

    /**
     * Получение информации об активных Lead ботах
     *
     * @param currentApp
     * @param MESSAGE_STATUSES
     * @param chatBotModel
     */
    function getDataAboutActiveLeadBots(currentApp, chatBotModel) {
      return firstValueFrom(chatBotModel.getList(currentApp.id, MESSAGE_STATUSES.ACTIVE)).then((response) => {
        return {
          ids: response.chatBots.map((bot) => bot.id),
          amount: response.chatBots.length,
        };
      });
    }

    /**
     * Получение информации об активных Welcome ботах
     *
     * @param chatBotModel
     */
    function getDataAboutActiveWelcomeBots(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot()).then((response) => {
        const welcomeBots = response ? [response].filter((bot) => bot.active) : [];
        return {
          ids: welcomeBots.map((bot) => bot.id),
          amount: welcomeBots.length,
        };
      });
    }

    /**
     * Получение существующих интеграций "Уведомление на Email"
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getEmailNotificationIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.EMAIL_NOTIFICATION);
    }

    /**
     * Получение существующих интеграций AmoCRM
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getAmocrmIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.AMOCRM);
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение роутинг-бота
     *
     * @param chatBotModel
     * @returns {Promise}
     */
    function getFacebookBot(chatBotModel) {
      return firstValueFrom(chatBotModel.getFacebookBot());
    }

    /**
     * Получение членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
    }
  }
})();

<ng-container *transloco="let t; read: 'eventTypeSelectComponent'">
  <div ngbDropdown>
    <button
      type="button"
      class="btn btn-outline-primary"
      ngbDropdownToggle
    >
      <i
        class="btn-icon cqi-sm"
        [ngClass]="getClassIconByEventType(control.value)"
      ></i>
    </button>
    <div ngbDropdownMenu>
      <button
        type="button"
        ngbDropdownItem
        (click)="control.setValue(FILTER_EVENT_TYPE.COUNT)"
      >
        <i
          class="cqi-sm"
          [ngClass]="getClassIconByEventType(FILTER_EVENT_TYPE.COUNT)"
        ></i>
        <span class="ml-5">{{ t('count') }}</span>
      </button>

      <button
        type="button"
        ngbDropdownItem
        (click)="control.setValue(FILTER_EVENT_TYPE.FIRST)"
      >
        <i
          class="cqi-sm"
          [ngClass]="getClassIconByEventType(FILTER_EVENT_TYPE.FIRST)"
        ></i>
        <span class="ml-5">{{ t('first') }}</span>
      </button>

      <button
        type="button"
        ngbDropdownItem
        (click)="control.setValue(FILTER_EVENT_TYPE.LAST)"
      >
        <i
          class="cqi-sm"
          [ngClass]="getClassIconByEventType(FILTER_EVENT_TYPE.LAST)"
        ></i>
        <span class="ml-5">{{ t('last') }}</span>
      </button>
    </div>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { DefaultActionHelper } from 'app/http/chat-bot/helpers-for-gerenation-of-defaults/default-action.helper';
import { DefaultBranchHelper } from 'app/http/chat-bot/helpers-for-gerenation-of-defaults/default-branch.helper';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { BotWithActiveStatus, ChatBot, LeadBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { TRIGGER_TYPE_KIND } from '@http/message/message.constants';
import { cloneObjectMutable } from '@panel/app/shared/functions/clone-object-mutable.function';

@Injectable({ providedIn: 'root' })
export class DefaultBotHelper {
  private readonly DEFAULT_CHAT_BOT: Omit<ChatBot<any>, 'type'> = {
    name: '',
    interruptBranchLinkId: null,
    interruptBranchId: null,
    startBranchLinkId: null,
    startBranchId: null,
    allowUserReplies: true,
    branches: [],
    triggerTypes: [],
    integration: null,
  };

  constructor(
    private readonly transloco: TranslocoService,
    private readonly defaultBranchHelper: DefaultBranchHelper,
  ) {}

  public getEmptyBot<T extends CHAT_BOT_TYPE>(type: T): ChatBot<T> {
    return Object.assign({ type }, cloneObjectMutable(this.DEFAULT_CHAT_BOT));
  }

  public getInitialBot<T extends CHAT_BOT_TYPE>(type: T): ChatBot<T> {
    switch (type) {
      case CHAT_BOT_TYPE.FACEBOOK:
        return this.getDefaultWelcomeBot(type as Exclude<CHAT_BOT_TYPE, 'lead'>) as ChatBot<T>;
      case CHAT_BOT_TYPE.LEAD:
        return this.getMinimalFilledBot(type);
      case CHAT_BOT_TYPE.ROUTING:
        const routingBot = this.getMinimalFilledBot(CHAT_BOT_TYPE.ROUTING);
        return this.getBotWithActiveStatus(type as Exclude<CHAT_BOT_TYPE, 'lead'>, routingBot) as unknown as ChatBot<T>;
      case CHAT_BOT_TYPE.TELEGRAM:
        return this.getMinimalFilledBot(type as Exclude<CHAT_BOT_TYPE, 'lead'>) as ChatBot<T>;
      case CHAT_BOT_TYPE.WIDGET:
        return this.getMinimalFilledBot(type as Exclude<CHAT_BOT_TYPE, 'lead'>) as ChatBot<T>;
      default:
        throw new Error();
    }
  }

  /**
   * Получение заполненного лид-бота
   *
   * NOTE если не понадобится в течении несколкьких месяцев => можно удалить
   */
  getDefaultLeadBot(): LeadBot {
    const t = (suffix: string) => {
      return this.transloco.translate(`models.chatBot.defaultBotScenarios.lead.${suffix}`);
    };

    const type: CHAT_BOT_TYPE.LEAD = CHAT_BOT_TYPE.LEAD;
    let bot: LeadBot = this.getEmptyBot(type);

    const branch6text = DefaultActionHelper.createTextAction(t('branch6.text'));
    const branch6markVisible = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE);
    const branch6 = this.defaultBranchHelper.getDefaultBranch(t('branch6.heading'), false, false);
    branch6.actions.push(branch6text, branch6markVisible);

    const branch5text = DefaultActionHelper.createTextAction(t('branch5.text'));
    const branch5propertyField = DefaultActionHelper.createPropertyFieldAction(branch6);
    branch5propertyField.active = true;
    branch5propertyField.keyName = '$email';
    const branch5 = this.defaultBranchHelper.getDefaultBranch(t('branch5.heading'), false, false);
    branch5.actions.push(branch5text, branch5propertyField);

    const branch4text = DefaultActionHelper.createTextAction(t('branch4.text'));
    const branch4markVisible = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE);
    const branch4 = this.defaultBranchHelper.getDefaultBranch(t('branch4.heading'), false, false);
    branch4.actions.push(branch4text, branch4markVisible);

    const branch3text = DefaultActionHelper.createTextAction(t('branch3.text'));
    const branch3propertyField = DefaultActionHelper.createPropertyFieldAction(branch5);
    const branch3 = this.defaultBranchHelper.getDefaultBranch(t('branch3.heading'), false, false);
    branch3.actions.push(branch3text, branch3propertyField);

    const branch2text = DefaultActionHelper.createTextAction(t('branch2.text'));
    const branch2button1 = DefaultActionHelper.createButtonAction(t('branch2.button1'), branch4);
    const branch2button2 = DefaultActionHelper.createButtonAction(t('branch2.button2'), branch4);
    const branch2 = this.defaultBranchHelper.getDefaultBranch(t('branch2.heading'), false, false);
    branch2.actions.push(branch2text, branch2button1, branch2button2);

    const branch1text = DefaultActionHelper.createTextAction(t('branch1.text'));
    const branch1 = this.defaultBranchHelper.getDefaultBranch(t('branch1.heading'), true, false);
    branch1.actions.unshift(branch1text);

    const conditionAction1 = DefaultActionHelper.createConditionAction(
      CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION,
      branch2,
    );
    const conditionAction2 = DefaultActionHelper.createConditionAction(
      CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION,
      branch3,
    );
    const conditionName = `${this.transloco.translate(
      'models.chatBot.defaultConditionPrefix',
    )}: ${this.transloco.translate('models.chatBot.conditionName.appOnlineCondition')}`;
    const condition = this.defaultBranchHelper.getDefaultBranch(conditionName, false, false);
    condition.actions.push(conditionAction1, conditionAction2);

    const interruptedText = DefaultActionHelper.createTextAction(t('interrupted.text'));
    const interruptedMarkVisible = DefaultActionHelper.getDefaultAction(
      CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE,
    );
    let interruptedBranch = this.defaultBranchHelper.getDefaultBranch(t('interrupted.heading'), false, false);
    interruptedBranch.actions.push(interruptedText, interruptedMarkVisible);

    const startedText = DefaultActionHelper.createTextAction(t('started.text'));
    const startedButton1 = DefaultActionHelper.createButtonAction(t('started.button1'), condition);
    const startedButton2 = DefaultActionHelper.createButtonAction(t('started.button2'), branch1);
    let startedBranch = this.defaultBranchHelper.getDefaultBranch(t('started.heading'), false, false);
    startedBranch.actions.push(startedText, startedButton1, startedButton2);

    bot.branches.push(
      startedBranch,
      interruptedBranch,
      condition,
      branch1,
      branch2,
      branch3,
      branch4,
      branch5,
      branch6,
    );
    bot.interruptBranchLinkId = interruptedBranch.linkId;
    bot.startBranchLinkId = startedBranch.linkId;

    // Делаю депрекейтед bullshit, чтоб отрисовалось дерево (выпилить вместе с parentBranchIds)
    // Вместо айдишников тут просто строки, потому что при отрисовке важно только то, сколько элементов в массиве
    // (Количество элементов + 1) === колонка, в которой будет рисоваться блок
    branch6.parentBranchIds.push('', '', '', '');
    branch5.parentBranchIds.push('', '', '');
    branch4.parentBranchIds.push('', '', '');
    branch3.parentBranchIds.push('', '');
    branch2.parentBranchIds.push('', '');
    branch1.parentBranchIds.push('');
    condition.parentBranchIds.push('');

    return bot;
  }

  /**
   * Получить минимально заполненного бота
   *
   * NOTE Имеет только 2 блока (стартовы и прерывания)
   */
  getMinimalFilledBot<T extends CHAT_BOT_TYPE>(type: T): ChatBot<T> {
    const t = (suffix: string) => {
      if (type === CHAT_BOT_TYPE.TELEGRAM) {
        return this.transloco.translate(`models.chatBot.defaultBotScenarios.telegram.${suffix}`);
      }
      return this.transloco.translate(`models.chatBot.defaultBotScenarios.empty.${suffix}`);
    };

    let bot: ChatBot<T> = this.getEmptyBot(type);

    const interruptedText = DefaultActionHelper.createTextAction(t('interrupted.text'));
    const interruptedMarkVisible = DefaultActionHelper.getDefaultAction(
      CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE,
    );
    let interruptedBranch = this.defaultBranchHelper.getDefaultBranch(t('interrupted.heading'), false, false);
    interruptedBranch.actions.push(interruptedText, interruptedMarkVisible);

    const startedText = DefaultActionHelper.createTextAction(t('started.text'));
    let startedBranch = this.defaultBranchHelper.getDefaultBranch(t('started.heading'), true, false);
    startedBranch.actions.unshift(startedText);

    bot.branches.push(startedBranch, interruptedBranch);
    bot.interruptBranchLinkId = interruptedBranch.linkId;
    bot.startBranchLinkId = startedBranch.linkId;

    if (type === CHAT_BOT_TYPE.TELEGRAM) {
      //Пока телеграм бот 1 то выставляем дефолтный триггер тайп "на кнопку старт"
      //Когда ботов станет больше, нужно будет убрать
      bot.triggerTypes = [TRIGGER_TYPE_KIND.BOT_MANUAL_START];
    }
    bot.triggerTypes = [];

    if (type === CHAT_BOT_TYPE.WIDGET) {
      bot.chatWidget = {
        buttonText: '',
        header: '',
        order: 0,
        subHeader: '',
        visible: false,
      };
    }

    return bot;
  }

  private getBotWithActiveStatus<T extends Exclude<CHAT_BOT_TYPE, 'lead'>>(
    type: T,
    existBot?: ChatBot<T>,
  ): BotWithActiveStatus<T> {
    if ([CHAT_BOT_TYPE.LEAD].includes(type) || (existBot && [CHAT_BOT_TYPE.LEAD].includes(existBot.type))) {
      throw new Error(`'Wrong type. You can\'t passed ${type}`);
    }

    const bott = existBot ?? this.getEmptyBot(type);

    return {
      active: true,
      ...bott,
    };
  }

  getDefaultWelcomeBot<T extends Exclude<CHAT_BOT_TYPE, 'lead'>>(type: T): ChatBot<T> {
    const t = (suffix: string) => {
      return this.transloco.translate(`models.chatBot.defaultBotScenarios.routing.${suffix}`);
    };

    let bot = this.getBotWithActiveStatus(type);

    const branch7text = DefaultActionHelper.createTextAction(t('branch7.text'));
    const branch7markVisible = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE);
    const branch7 = this.defaultBranchHelper.getDefaultBranch(t('branch7.heading'), false, false);
    branch7.actions.push(branch7text, branch7markVisible);

    const branch6text = DefaultActionHelper.createTextAction(t('branch6.text'));
    const branch6propertyField = DefaultActionHelper.createPropertyFieldAction(branch7);
    branch6propertyField.active = true;
    branch6propertyField.keyName = '$email';
    const branch6 = this.defaultBranchHelper.getDefaultBranch(t('branch6.heading'), false, false);
    branch6.actions.push(branch6text, branch6propertyField);

    const branch5text = DefaultActionHelper.createTextAction(t('branch5.text'));
    const branch5propertyField = DefaultActionHelper.createPropertyFieldAction(branch6);
    const branch5 = this.defaultBranchHelper.getDefaultBranch(t('branch5.heading'), false, false);
    branch5.actions.push(branch5text, branch5propertyField);

    const branch4text = DefaultActionHelper.createTextAction(t('branch4.text'));
    const branch4markVisible = DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE);
    const branch4 = this.defaultBranchHelper.getDefaultBranch(t('branch4.heading'), false, false);
    branch4.actions.push(branch4text, branch4markVisible);

    const branch3text = DefaultActionHelper.createTextAction(t('branch3.text'));
    const branch3 = this.defaultBranchHelper.getDefaultBranch(t('branch3.heading'), true, false);
    branch3.actions.unshift(branch3text);

    const branch2text = DefaultActionHelper.createTextAction(t('branch2.text'));
    const branch2 = this.defaultBranchHelper.getDefaultBranch(t('branch2.heading'), true, false);
    branch2.actions.unshift(branch2text);

    const branch1text = DefaultActionHelper.createTextAction(t('branch1.text'));
    const branch1 = this.defaultBranchHelper.getDefaultBranch(t('branch1.heading'), true, false);
    branch1.actions.unshift(branch1text);

    const conditionAction1 = DefaultActionHelper.createConditionAction(
      CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION,
      branch4,
    );
    const conditionAction2 = DefaultActionHelper.createConditionAction(
      CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION,
      branch5,
    );
    const conditionName = `${this.transloco.translate(
      'models.chatBot.defaultConditionPrefix',
    )}: ${this.transloco.translate('models.chatBot.conditionName.appOnlineCondition')}`;
    const condition = this.defaultBranchHelper.getDefaultBranch(conditionName, false, false);
    condition.actions.push(conditionAction1, conditionAction2);

    const interruptedText = DefaultActionHelper.createTextAction(t('interrupted.text'));
    const interruptedMarkVisible = DefaultActionHelper.getDefaultAction(
      CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE,
    );
    let interruptedBranch = this.defaultBranchHelper.getDefaultBranch(t('interrupted.heading'), false, false);
    interruptedBranch.actions.push(interruptedText, interruptedMarkVisible);

    const startedText = DefaultActionHelper.createTextAction(t('started.text'));
    const startedButton1 = DefaultActionHelper.createButtonAction(t('started.button1'), condition);
    const startedButton2 = DefaultActionHelper.createButtonAction(t('started.button2'), branch1);
    const startedButton3 = DefaultActionHelper.createButtonAction(t('started.button3'), branch2);
    const startedButton4 = DefaultActionHelper.createButtonAction(t('started.button4'), branch3);
    let startedBranch = this.defaultBranchHelper.getDefaultBranch(t('started.heading'), false, false);
    startedBranch.actions.push(startedText, startedButton1, startedButton2, startedButton3, startedButton4);

    bot.branches.push(
      startedBranch,
      interruptedBranch,
      condition,
      branch1,
      branch2,
      branch3,
      branch4,
      branch5,
      branch6,
      branch7,
    );
    bot.interruptBranchLinkId = interruptedBranch.linkId;
    bot.startBranchLinkId = startedBranch.linkId;

    // Делаю депрекейтед bullshit, чтоб отрисовалось дерево (выпилить вместе с parentBranchIds)
    // Вместо айдишников тут просто строки, потому что при отрисовке важно только то, сколько элементов в массиве
    // (Количество элементов + 1) === колонка, в которой будет рисоваться блок
    branch7.parentBranchIds.push('', '', '', '');
    branch6.parentBranchIds.push('', '', '');
    branch5.parentBranchIds.push('', '');
    branch4.parentBranchIds.push('', '');
    branch3.parentBranchIds.push('');
    branch2.parentBranchIds.push('');
    branch1.parentBranchIds.push('');
    condition.parentBranchIds.push('');

    return bot;
  }
}

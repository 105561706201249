import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { COMMENT_MAX_LENGTH } from '@http/integration/integrations/email-notification/constants/email-notification-integration.constants';
import { EmailNotificationIntegrationFormData } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-email-notification-comment[control]',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationCommentComponent {
  /** Control для настройки комментария к письму */
  @Input() control!: GenericFormControl<EmailNotificationIntegrationFormData['settings']['comment']>;
  /** Максимальная длина комментария */
  COMMENT_MAX_LENGTH = COMMENT_MAX_LENGTH;
}

/**
 * Контроллер для модального окна с видео
 *
 * При создании модального окна в resolve можно передать modalWindowParams - объект следующего вида:
 *    {String} heading Текст заголовка модального окна
 *    {String} body Текст тела модального окна
 *    {String} videoUrl Ссылка на видео
 *    {String} confirmButtonClass Класс для кнопки подтвеждения
 *    {String} confirmButtonText Текст кнопки подтвеждения
 *    {String} cancelButtonClass Класс для кнопки отмены
 *    {String} cancelButtonText Текст кнопки отмены
 */
(function () {
  'use strict';

  angular.module('myApp.modals.video').controller('VideoModalController', VideoModalController);

  function VideoModalController($uibModalInstance, modalWindowParams) {
    var vm = this;

    vm.body = modalWindowParams.body;
    vm.cancel = cancel;
    vm.cancelButtonClass = modalWindowParams.cancelButtonClass || 'btn-outline-primary';
    vm.cancelButtonText = modalWindowParams.cancelButtonText;
    vm.confirm = confirm;
    vm.confirmButtonClass = modalWindowParams.confirmButtonClass || 'btn-primary';
    vm.confirmButtonText = modalWindowParams.confirmButtonText;
    vm.heading = modalWindowParams.heading || '';
    vm.showButton = modalWindowParams.showButton;
    vm.videoUrl = modalWindowParams.videoUrl;

    /**
     * Обработка нажатия на кнопку отмены
     */
    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
    /**
     * Обработка нажатия на кнопку подтверждения
     */
    function confirm() {
      $uibModalInstance.close();
    }
  }
})();

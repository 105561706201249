/** Блоки с информацией о доступах к фичам */
export enum INFO_BLOCK {
  /** Аналитика */
  ANALYTICS = 'analytics',
  /** Чат */
  CHAT = 'chat',
  /** Чат-боты */
  CHATBOTS = 'chatbots',
  /** Интеграции */
  INTEGRATION = 'integration',
  /** База знаний */
  KNOWLEDGE_BASE = 'knowledge_base',
  /** Работа с лидами */
  LEADS_DATABASE = 'leads_database',
  /** Ручные рассылки */
  MANUAL_SENDING = 'manual_sending',
  /** Триггерные сценарии */
  TRIGGERED_MESSAGES = 'triggered_messages',
}

<ng-container *transloco="let t; read: 'notificationSettingsComponent'">
  <cq-notification-settings-general
    [(notificationSettingsGeneral)]="settings.general"
  ></cq-notification-settings-general>
  <ng-container
    *ngFor="let appSettings of settings.apps | filterAppsWithNoAccess: appList; trackBy: trackByFn; let i = index"
  >
    <hr />
    <div
      class="d-flex justify-content-between align-items-center cursor-pointer"
      (click)="addChannelList(appSettings.appId, collapse)"
      [attr.aria-expanded]="!collapsedAppsState[appSettings.appId]"
      aria-controls="collapse"
    >
      <h3 class="no-margin">
        {{ appSettings.appId | appIdToName: appList }}
      </h3>
      <button class="btn btn-outline-secondary">
        <i
          class="btn-icon cqi-sm"
          [ngClass]="{
            'cqi-chevron-up': !collapsedAppsState[appSettings.appId],
            'cqi-chevron-down': collapsedAppsState[appSettings.appId]
          }"
        ></i>
        <span>{{ (collapsedAppsState[appSettings.appId] ? 'general.show' : 'general.hide') | transloco }}</span>
      </button>
    </div>
    <div
      #collapse="ngbCollapse"
      [(ngbCollapse)]="collapsedAppsState[appSettings.appId]"
      [attr.data-test]="'collapse-' + i"
    >
      <cq-notification-channel-settings
        [channelList]="filteredAppsChannels[appSettings.appId]"
        [notMutedChannelsControl]="notMutedChannelsFormGroup.controls[appSettings.appId]"
        (noMutedChannels)="changeDisabledNotPersonalNotifications(appSettings.appId, $event)"
        class="pt-20"
      ></cq-notification-channel-settings>
      <cq-notification-app-setting
        class="pt-20"
        [appId]="appSettings.appId"
        [settings]="appSettings.settings"
        [disabledNotPersonalNotifications]="disabledNotPersonalNotifications[appSettings.appId]"
      ></cq-notification-app-setting>
    </div>
  </ng-container>
  <div class="position-sticky bottom-0 sticky-bottom margin-left-20-neg margin-right-20-neg">
    <hr />
    <button
      (click)="saveSettings()"
      class="btn btn-primary ml-20"
      data-test="save-notification-settings-button"
    >
      {{ t('saveButton') }}
    </button>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import isInteger from 'lodash-es/isInteger';
import moment from 'moment';

@Injectable()
export class DatetimePickerAdapter extends NgbDateAdapter<moment.Moment> {
  fromModel(date: moment.Moment | null): NgbDateStruct | null {
    if (!date || !moment.isMoment(date)) {
      return null;
    }
    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  }

  toModel(date: NgbDateStruct | null): moment.Moment | null {
    if (!date || !isInteger(date.day) || !isInteger(date.month) || !isInteger(date.year)) {
      return null;
    }
    return moment(date.day + '-' + date.month + '-' + date.year, 'DD-MM-YYYY');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { SortablejsModule } from 'nxt-sortablejs';

import { SavedRepliesNewComponent } from './saved-replies-new.component';

@NgModule({
  declarations: [SavedRepliesNewComponent],
  exports: [SavedRepliesNewComponent],
  imports: [CommonModule, SortablejsModule, TranslocoModule],
})
export class SavedRepliesNewModule {}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { AiData } from '@http/ai-data/internal-types';
import { ConversationPartModel } from '@http/conversation-part/conversation-part.model';
import { ConversationPartAiContentModalStore } from '@panel/app/partials/modals/conversation-part-ai-content-modal/conversation-part-ai-content-modal.store';
import {
  CONVERSATION_PART_AI_CONTENT_MODAL_DATA_TOKEN,
  ConversationPartAiContentModalData,
} from '@panel/app/partials/modals/conversation-part-ai-content-modal/conversation-part-ai-content-modal.token';
import { DestroyService } from '@panel/app/services';

/**
 * Модалка для отображения источников данных, которые использовал AI-бот для ответа пользователю
 */
@Component({
  selector: 'cq-conversation-part-ai-content-modal',
  templateUrl: './conversation-part-ai-content-modal.component.html',
  styleUrls: ['./conversation-part-ai-content-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, ConversationPartAiContentModalStore],
})
export class ConversationPartAiContentModalComponent implements OnInit {
  /** Флаг загрузки данных */
  isDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  aiContent$: BehaviorSubject<AiData[]> = new BehaviorSubject<AiData[]>([]);

  constructor(
    public activeModal: NgbActiveModal,
    @Inject(CONVERSATION_PART_AI_CONTENT_MODAL_DATA_TOKEN)
    public readonly data: ConversationPartAiContentModalData,
    private readonly conversationPartModel: ConversationPartModel,
    private readonly destroy$: DestroyService,
    private readonly store$: ConversationPartAiContentModalStore,
  ) {
    this.store$.activeModal$.next(this.activeModal);
    this.store$.modalData$.next(this.data);
  }

  ngOnInit() {
    this.getData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => this.aiContent$.next(data));
  }

  private getData() {
    this.isDataLoading$.next(true);

    return this.conversationPartModel
      .getAIContent(this.data.conversationPart.id)
      .pipe(finalize(() => this.isDataLoading$.next(false)));
  }
}

import { CHAT_BOT_URL_PATHS } from '../../chat-bot-routes.constants';
import { firstValueFrom } from 'rxjs';
import { FEATURES } from '../../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.chatBot.widgetBot').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.messagesAjs.widgetBot', {
        url: CHAT_BOT_URL_PATHS.WIDGET_BOT,
        redirectTo: 'app.content.messagesAjs.widgetBot.create',
        template: '<div ui-view></div>',
      })

      .state('app.content.messagesAjs.widgetBot.create', {
        url: '/new',
        resolve: {
          calendlyEventList: getCalendlyEventList,
          calendlyIntegrationList: getCalendlyIntegrationList,
          channels: getChannels,
          chatSettings: getChatSettings,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          activeChatWidgetList: getActiveChatWidgetList,
        },
        bindings: {
          billingInfo: 'billingInfo',
          channels: 'channels',
          chatSettings: 'chatSettings',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          tags: 'tags',
          teamMembers: 'teamMembers',
          activeChatWidgetList: 'activeChatWidgetList',
        },
        component: 'cqWidgetBotEdit',
      })

      .state('app.content.messagesAjs.widgetBot.edit', {
        url: '/{widgetBotId:id}/edit',
        resolve: {
          calendlyEventList: getCalendlyEventList,
          calendlyIntegrationList: getCalendlyIntegrationList,
          channels: getChannels,
          chatSettings: getChatSettings,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          widgetBot: getWidgetBot,
          activeChatWidgetList: getActiveChatWidgetList,
        },
        bindings: {
          billingInfo: 'billingInfo',
          channels: 'channels',
          chatSettings: 'chatSettings',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          tags: 'tags',
          teamMembers: 'teamMembers',
          widgetBot: 'widgetBot',
          activeChatWidgetList: 'activeChatWidgetList',
        },
        component: 'cqWidgetBotEdit',
      });

    /**
     * Получение всех тегов пользователя
     *
     * @param currentApp
     * @param tagModel
     * @returns {Promise}
     */
    function getAllUserTags(currentApp, tagModel) {
      return firstValueFrom(tagModel.getList(currentApp.id));
    }

    /**
     * Получение списка каналов
     *
     * @param channelModel
     * @param currentApp
     * @return {Promise}
     */
    function getChannels(channelModel, currentApp) {
      return firstValueFrom(channelModel.getList(currentApp.id));
    }

    /**
     * Получение настроек чата
     *
     * @param currentApp - Текущее приложение
     */
    function getChatSettings(currentApp) {
      return angular.copy(currentApp.settings);
    }

    /**
     * Получение существующих интеграций "Уведомление на Email"
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getEmailNotificationIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.EMAIL_NOTIFICATION);
    }

    /**
     * Получение существующих интеграций AmoCRM
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getAmocrmIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.AMOCRM);
    }

    /** Получения списка интеграций Calendly
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     * @return {Promise}
     * */
    function getCalendlyIntegrationList(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel
        .getByType(currentApp.id, INTEGRATION_TYPES.CALENDLY)
        .then((integrations) => integrations.filter((integration) => integration.active));
    }

    /** Получения списка календарей в Calendly
     * @return {Promise}
     * */
    function getCalendlyEventList(integrationModel, calendlyIntegrationList, featureModel) {
      if (!calendlyIntegrationList.length) {
        return Promise.resolve([]);
      }

      const integrationId = calendlyIntegrationList[0].id;

      return integrationModel.getCalendlyEventList(integrationId);
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение виджет-бота
     *
     * @param $stateParams
     * @param chatBotModel
     * @returns {Promise}
     */
    function getWidgetBot($stateParams, chatBotModel) {
      return firstValueFrom(chatBotModel.getWidgetBot($stateParams.widgetBotId));
    }

    /**
     * Получение списка активных чат-виджетов
     *
     * @param chatBotModel
     * @param currentApp
     * @returns {IPromise<ActiveChatWidget>}
     */
    function getActiveChatWidgetList(chatBotModel, currentApp) {
      return firstValueFrom(chatBotModel.getActiveWidgetBotList(currentApp.id));
    }

    /**
     * Получение членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
    }
  }
})();

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupIndentBlockPreviewController', CqPopupIndentBlockPreviewController);

  function CqPopupIndentBlockPreviewController($q, $templateRequest) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.template = ''; // строка, содержащая html и css-код, необходимый для работы превью блока

      getTemplate();
    }

    /**
     * Получения темплейта для блока поп-апа
     */
    function getTemplate() {
      var templateRequests = [];

      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/general-style.scss'));
      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/indent/indent.html'));

      $q.all(templateRequests).then(getTemplatesSuccess);

      function getTemplatesSuccess(templates) {
        // NOTE: стили нужно обернуть в тег, т.к. итоговая строка должна быть валидным html
        vm.template = '<style>' + templates[0] + '</style>' + templates[1];
      }
    }
  }
})();

/**
 * Сервис для работы с фича лейблами
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FEATURE_LABELS } from '@http/feature-label/feature-label.constants';
import { FeatureLabelsObject } from '@http/feature-label/feature-label.types';

/**
 * Сервис для работы с фичами
 */
@Injectable({ providedIn: 'root' })
export class FeatureLabelModel {
  constructor(private http: HttpClient) {}

  /**
   * Название ключа в localStorage, в котором находится массив новых фичей, которые пользователь уже использовал
   */
  private readonly HIDE_NEW_FEATURE_LABELS_LOCAL_STORAGE_KEY: string = 'carrotquest_hide_new_labels';

  /**
   * Списки новых и бета фичей
   */
  private featureLabels: FeatureLabelsObject = {
    new: [],
    beta: [],
  };

  /**
   * Получение списка фичей
   */
  public getList(): Observable<FeatureLabelsObject> {
    return this.http.get<Partial<FeatureLabelsObject>>('/featurelabels').pipe(
      map((data) => {
        this.featureLabels.beta = data.beta || [];
        this.featureLabels.new = data.new || [];

        return this.featureLabels;
      }),
    );
  }

  /**
   * Находится ли фича в бете
   *
   * @param featureLabel Название фичи
   */
  public isBeta(featureLabel: FEATURE_LABELS): boolean {
    return this.featureLabels.beta.includes(featureLabel);
  }

  /**
   * Новая ли фича
   *
   * @param featureLabel Название фичи
   */
  public isNew(featureLabel: FEATURE_LABELS): boolean {
    if (!this.featureLabels.new.includes(featureLabel)) {
      return false;
    }

    const storedFeatureLabelsString: string =
      localStorage.getItem(this.HIDE_NEW_FEATURE_LABELS_LOCAL_STORAGE_KEY) || '[]';
    const storedFeatureLabels: FEATURE_LABELS[] = JSON.parse(storedFeatureLabelsString);

    // Если пользователь уже пользовался этой фичей (то есть она находится в HIDE_NEW_FEATURE_LABELS_LOCAL_STORAGE_KEY), то возвращаем false
    return !storedFeatureLabels.includes(featureLabel);
  }

  /**
   * Записываем в localStorage название фичи, которую больше не нужно отображать
   * Влияет только на новые фичи, бета фичи отображаются в любом случае
   *
   * @param featureLabel Название фичи
   */
  public remove(featureLabel: FEATURE_LABELS): void {
    const storedFeatureLabelsString: string =
      localStorage.getItem(this.HIDE_NEW_FEATURE_LABELS_LOCAL_STORAGE_KEY) || '[]';
    const storedFeatureLabels: FEATURE_LABELS[] = JSON.parse(storedFeatureLabelsString);

    if (!storedFeatureLabels.includes(featureLabel)) {
      storedFeatureLabels.push(featureLabel);
      localStorage.setItem(this.HIDE_NEW_FEATURE_LABELS_LOCAL_STORAGE_KEY, JSON.stringify(storedFeatureLabels));
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import { filter } from 'lodash-es';

import {
  AUTO_EVENTS_GROUPS_LIST,
  AUTO_EVENTS_TYPES,
  TRACK_MASTER_SETTING_BY_GROUPS,
  TRACK_MASTER_SETTING_GROUPS_LIST,
  TRACK_MASTER_SETTINGS,
} from '@http/track-master/track-master.constants';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

@Component({
  selector: 'cq-track-master',
  templateUrl: './track-master.component.html',
  styleUrls: ['./track-master.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackMasterComponent implements OnInit {
  /** Автособытие */
  @Input() autoEvents!: any;
  /** Информация по биллингу */
  @Input() billingInfo!: any;
  /** Текущий App */
  @Input() currentApp!: any;
  /** Django user */
  @Input() djangoUser!: any;

  protected accessToTrackMasterJsCode: any;
  protected expanded: any;
  protected TRACK_MASTER_SETTINGS = TRACK_MASTER_SETTINGS;
  protected TRACK_MASTER_SETTING_BY_GROUPS = TRACK_MASTER_SETTING_BY_GROUPS;
  protected TRACK_MASTER_SETTING_GROUPS_LIST = TRACK_MASTER_SETTING_GROUPS_LIST;
  protected AUTO_EVENTS_GROUPS_LIST = AUTO_EVENTS_GROUPS_LIST;
  protected AUTO_EVENTS_TYPES = AUTO_EVENTS_TYPES;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly l10nHelperService: L10nHelperService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    protected readonly uiRouter: UIRouter,
  ) {}

  ngOnInit(): void {
    this.accessToTrackMasterJsCode = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.TRACK_MASTER_JS_CODE,
      this.currentApp,
    );

    this.trackEnterOnPage();

    this.expanded = this.getInitialExpanded();
  }

  /**
   * Получение начального состояния открытости настройки
   */
  getInitialExpanded(): any {
    var expanded: any = {};
    if (this.uiRouter.stateService.params.createdInGroup) {
      expanded[this.uiRouter.stateService.params.createdInGroup] = true;
    }
    return expanded;
  }

  /**
   * Получение автособытий по группе
   *
   * @param group
   * @returns
   */
  getAutoEventsByGroup(group: any): any {
    return filter(this.autoEvents, { group: group });
  }

  /**
   * Трек захода на страницу
   */
  trackEnterOnPage(): void {
    this.carrotquestHelper.track('Мастер сбора данных - зашел');
  }

  /**
   * Трек добавление новой настройки
   * @param group
   */
  trackClickAddSetting(group: any): void {
    this.carrotquestHelper.track('Мастер сбора данных - добавить', { group: group });
  }

  trackEditAutoEvent(group: any): void {
    this.carrotquestHelper.track('Мастер сбора данных - редактировать', { group: group });
  }

  trackBySettingGroup(settingGroup: any) {
    return settingGroup;
  }

  trackBySetting(settings: any) {
    return settings;
  }

  trackByAutoEventId(autoEvent: any) {
    return autoEvent.id;
  }

  indexOf(items: any, item: any): any {
    return items.indexOf(item);
  }
}

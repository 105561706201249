import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cq-ai-data-sources-page',
  templateUrl: './ai-data-source-page.component.html',
  styleUrls: ['./ai-data-source-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiDataSourcePageComponent {
  constructor() {}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QueueRtsService {
  next: any = null;
  queue: any = [];
  executeNext$ = new BehaviorSubject(null);

  constructor() {}

  addToQueue(data: any): void {
    this.queue.push(data);
  }

  executeNext(): void {
    this.next = this.queue.shift();
    this.executeNext$.next(this.next);
  }

  execute(data: any): void {
    this.executeNext$.next(data);
  }
}

<div class="modal-header">
  <h3
    *ngIf="!isEdit"
    class="modal-title"
  >
    {{ 'modals.editSavedReply.createSavedReplyHeading' | transloco }}
  </h3>
  <h3
    *ngIf="isEdit"
    class="modal-title"
  >
    {{ 'modals.editSavedReply.editSavedReplyHeading' | transloco }}
  </h3>
  <button
    class="close"
    (click)="ngbActiveModal.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body">
  <form
    autocomplete="off"
    novalidate
  >
    <!--ЗАГОЛОВОК-->
    <div class="form-group margin-bottom-20">
      <label>{{ 'modals.editSavedReply.savedReplyForm.headerInput.label' | transloco }}</label>
      <input
        class="form-control"
        name="header"
        [formControl]="savedReplyForm.controls.header"
        [placeholder]="'modals.editSavedReply.savedReplyForm.headerInput.placeholder' | transloco"
      />
      <cq-validation-messages [control]="savedReplyForm.controls.header">
        <cq-validation-message errorName="maxlength">
          {{
            'modals.editSavedReply.savedReplyForm.headerInput.errors.maxlength'
              | transloco: { maxLength: MAX_HEADER_LENGTH }
          }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>

    <!--ТЕКСТ-->
    <div class="form-control-container">
      <label>{{ 'modals.editSavedReply.savedReplyForm.bodyInput.label' | transloco }}</label>
      <textarea
        class="form-control full-max-width no-resize"
        name="body"
        required
        rows="7"
        [formControl]="savedReplyForm.controls.body"
        [placeholder]="'modals.editSavedReply.savedReplyForm.bodyInput.placeholder' | transloco"
      ></textarea>
      <cq-validation-messages [control]="savedReplyForm.controls.body">
        <cq-validation-message errorName="required">
          {{ 'modals.editSavedReply.savedReplyForm.bodyInput.errors.required' | transloco }}
        </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{
            'modals.editSavedReply.savedReplyForm.bodyInput.errors.maxlength'
              | transloco: { maxLength: MAX_BODY_LENGTH }
          }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>

    <div
      *ngIf="!isEdit"
      class="form-group margin-top-20"
    >
      <ng-select
        name="addToTheEnd"
        [items]="ADD_TO_THE_END_OPTIONS"
        [searchable]="false"
        [clearable]="false"
        [(ngModel)]="addToTheEnd"
      >
        <ng-template
          ng-label-tmp
          ng-option-tmp
          let-item="item"
        >
          <span [innerHTML]="'modals.editSavedReply.savedReplyForm.addToTheEndSelect.' + item | transloco"></span>
        </ng-template>
      </ng-select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    class="btn btn-outline-primary"
    (click)="ngbActiveModal.dismiss()"
  >
    {{ 'general.cancel' | transloco }}
  </button>
  <label
    *ngIf="!isEdit"
    class="btn btn-primary"
    type="button"
    (click)="createOrSave(savedReplyForm.valid)"
    [ngClass]="{ disabled: isApiRequestPerformed || savedReplyForm.invalid }"
    >{{ 'modals.editSavedReply.createButton' | transloco }}</label
  >
  <button
    *ngIf="isEdit"
    class="btn btn-primary"
    type="button"
    [ngClass]="{ disabled: isApiRequestPerformed || savedReplyForm.invalid }"
    (click)="createOrSave(savedReplyForm.valid)"
  >
    {{ 'general.save' | transloco }}
  </button>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ConversationPartPreviewAvatarModule } from './avatar/conversation-part-preview-avatar.module';
import { ConversationPartPreviewButtonsModule } from './buttons/conversation-part-preview-buttons.module';
import { ConversationPartPreviewComponent } from './conversation-part-preview.component';
import { ConversationPartPreviewDelimiterModule } from './delimiter/conversation-part-preview-delimiter.module';
import { ConversationPartPreviewFileModule } from './file/conversation-part-preview-file.module';
import { ConversationPartPreviewImageModule } from './image/conversation-part-preview-image.module';
import { ConversationPartPreviewSpaceModule } from './space/conversation-part-preview-space.module';
import { ConversationPartPreviewTextModule } from './text/conversation-part-preview-text.module';
import { ConversationPartPreviewVideoModule } from './video/conversation-part-preview-video.module';

@NgModule({
  declarations: [ConversationPartPreviewComponent],
  exports: [ConversationPartPreviewComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ConversationPartPreviewButtonsModule,
    ConversationPartPreviewDelimiterModule,
    ConversationPartPreviewFileModule,
    ConversationPartPreviewImageModule,
    ConversationPartPreviewSpaceModule,
    ConversationPartPreviewTextModule,
    ConversationPartPreviewVideoModule,
    ConversationPartPreviewAvatarModule,
  ],
})
export class ConversationPartPreviewModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { ClipboardService } from 'ngx-clipboard';

import { CarrotquestHelperProvider, UtilsServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { SendScriptInstructionComponent } from '@panel/app/partials/modals/send-script-instruction/send-script-instruction.component';
import { DestroyService } from '@panel/app/services';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { HttpUtilsService } from '@panel/app/shared/services/utils/http-utils.service';
import { SharedModule } from '@panel/app/shared/shared.module';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@NgModule({
  declarations: [SendScriptInstructionComponent],
  exports: [SendScriptInstructionComponent],
  imports: [CommonModule, DirectivesModule, FormsModule, ReactiveFormsModule, SharedModule, TranslocoModule],
  providers: [
    CarrotquestHelperProvider,
    ClipboardService,
    DestroyService,
    HttpUtilsService,
    ToastService,
    UtilsServiceProvider,
  ],
})
export class SendScriptInstructionModule {}

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '@ng-web-apis/common';

type ModalData = {
  iframeSrc: string;
};

export const STRIPE_3DS_MODAL_DATA = new InjectionToken<ModalData>('Modal data for Stripe3dsModalComponent');

@Component({
  selector: 'cq-stripe-3ds-modal',
  templateUrl: './stripe-3ds-modal.component.html',
  styleUrls: ['./stripe-3ds-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Stripe3dsModalComponent implements OnInit, OnDestroy {
  protected readonly THREE_DS_ID = 'three-ds';

  protected is3DSReady = false;

  constructor(
    @Inject(STRIPE_3DS_MODAL_DATA)
    private readonly data: ModalData,
    @Inject(DOCUMENT)
    private readonly document: Document,
    @Inject(WINDOW)
    private readonly window: Window,
    protected readonly ngbActiveModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    const create3DSFrameInterval = setInterval(() => {
      if (document.getElementById(this.THREE_DS_ID)) {
        this.create3DSIframe();
        clearInterval(create3DSFrameInterval);
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.window.removeEventListener('message', (event) => this.on3DSAuthComplete(event), false);
  }

  /**
   * Создаение iframe с 3DS-подтверждением
   */
  create3DSIframe() {
    let stripeIframe = this.document.createElement('iframe');

    stripeIframe.src = this.data.iframeSrc;
    stripeIframe.onload = () => this.onLoadStripeIframe();

    this.document.getElementById(this.THREE_DS_ID)!.append(stripeIframe);

    this.window.addEventListener('message', (event) => this.on3DSAuthComplete(event), false);
  }

  /**
   * Прохождение 3DS-подтверждения карты
   *
   * @param {Event} event - Информация о совершенном событии
   */
  on3DSAuthComplete(event: any) {
    if (event.data === '3DS-authentication-complete') {
      this.ngbActiveModal.close();
    }
  }

  onLoadStripeIframe() {
    this.is3DSReady = false;
  }
}

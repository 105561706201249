import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'removeJinja',
})
export class RemoveJinjaPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(value: string): string {
    if (typeof value === 'string') {
      value = value.replace(
        /{{[ ]*user\[('|&#39;)\$name('|&#39;)\][ ]*\|[ ]*default\(('|&#39;)[^}]*?('|&#39;)\)[ ]*}}/g,
        this.translocoService.translate('filters.removeJinja.userName'),
      );
      value = value.replace(
        /{{[ ]*user\[('|&#39;)\$name('|&#39;)\][ ]*}}/g,
        this.translocoService.translate('filters.removeJinja.userName'),
      );
      value = value.replace(
        /{{[ ]*user\[('|&#39;)\$email('|&#39;)\][ ]*}}/g,
        this.translocoService.translate('filters.removeJinja.userEmail'),
      );
      value = value.replace(
        /{{[ ]*user\[('|&#39;)\$phone('|&#39;)\][ ]*}}/g,
        this.translocoService.translate('filters.removeJinja.userPhone'),
      );
      value = value.replace(
        /{{[ ]*user\[('|&#39;)[^}]*?('|&#39;)\][ ]*\|[ ]*default\(('|&#39;)[^}]*?('|&#39;)\)[ ]*}}/g,
        this.translocoService.translate('filters.removeJinja.userProperty'),
      );
      value = value.replace(
        /{{[ ]*user\[('|&#39;)[^}]*?('|&#39;)\][ ]*}}/g,
        this.translocoService.translate('filters.removeJinja.userProperty'),
      );
    }

    return value;
  }
}

<ng-container *transloco="let t; read: 'datepickerPageComponent'">
  <div class="margin-between-rows-50">
    <h1>Datepicker</h1>

    <div>
      <h3>Basic datepicker</h3>
      <div class="card">
        <div class="card-body">
          <cq-datepicker
            class="d-inline-block border rounded"
            [(ngModel)]="datepicker1"
          ></cq-datepicker>
          <hr />
          <div>Model:</div>
          <div>{{ datepicker1.format('LLLL') }}</div>
        </div>
      </div>
    </div>

    <div>
      <h3>Datepicker in popover</h3>
      <div class="card">
        <div class="card-body">
          <cq-datepicker-input [(ngModel)]="datepicker2"></cq-datepicker-input>
          <hr />
          <div>Model:</div>
          <div>{{ datepicker2.format('LLLL') }}</div>
        </div>
      </div>
    </div>

    <div>
      <h3>Datepicker in popover width timepicker and apply</h3>
      <div class="card">
        <div class="card-body">
          <cq-datepicker-input
            [(ngModel)]="datepicker3"
            [options]="{
              showApplyButtons: true,
              showTimepicker: true,
              format: 'L в HH:mm'
            }"
          ></cq-datepicker-input>
          <hr />
          <div>Model:</div>
          <div>{{ datepicker3.format('LLLL') }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

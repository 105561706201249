/**
 * Директива для превью блока поп-апа
 */
(function () {
  'use strict';

  require('./popup-block-preview.controller');
  // HACK: костыль, это должно делать не так. CSS в этом случае никак не обрабатывается angular-cli при билде/серве, потому что стоит ! перед ng-cache-loader
  require('!ng-cache-loader?prefix=panel/src:js/**&url=false!./general-style.scss');

  angular.module('myApp.popupEditor').component('cqPopupBlockPreview', cqPopupBlockPreview());

  function cqPopupBlockPreview() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        popupBlock: '=', // блок поп-апа
        privacyPolicyUrl: '<?', // ссылка на условия обработки данных
        showDataCollectionConsent: '<?', // показывается ли согласие на сбор данных
        showDataCollectionConsentWithCheckbox: '<?', // показывается ли чекбокс в согласии на сбор данных
      },
      controller: 'CqPopupBlockPreviewController',
      controllerAs: 'vm',
      template: require('./popup-block-preview.html'),
    };
  }
})();

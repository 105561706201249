/** Сервис для работы Electron API (Desktop-приложением) */
export class ElectronApiService {
  /** Открыта ли админка в данный момент внутри Desktop-приложения на Electron */
  readonly desktopApp: boolean;

  /** Открыта ли админка внутри нового Desktop-приложения */
  readonly newDesktopApp: boolean;

  /** Операционная система, в которой запущено приложение */
  readonly os: string;

  //@ngInject
  constructor(private ipCookie: any, private $window: ng.IWindowService) {
    this.desktopApp = this.isDesktopApp();
    this.newDesktopApp = this.isNewDesktopApp();
    this.os = this.getOs();
  }

  /**
   * Получение операционной системы, в которой запущено приложение
   */
  private getOs(): string {
    if (!this.desktopApp) {
      return '';
    }

    // новое Desktop-приложение
    if (this.$window.electronApi) {
      this.$window.electronApi.os;
    }

    // старое Desktop-приложение
    return 'Windows';
  }

  /**
   * Открыта ли админка в данный момент внутри Desktop-приложения на Electron
   */
  private isDesktopApp(): boolean {
    // сейчас определение Desktop-приложения осуществляется по наличию объекта electronApi в window
    if (this.$window.electronApi) {
      return true;
    }

    // раньше определение Desktop-приложения осуществлялось при помощи куки
    if (this.ipCookie('desktop_app') === 1) {
      return true;
    }

    return false;
  }

  /**
   * Открыта ли админка внутри нового Desktop-приложения. Также вернёт false, если админка открыта не в Desktop-приложении
   */
  private isNewDesktopApp(): boolean {
    if (!this.desktopApp) {
      return false;
    }

    if (this.$window.electronApi) {
      return true;
    }

    return false;
  }

  /**
   * Устанавливает функцию-обработчик на клик по нативному уведомлению операционной системы из диалогов
   *
   * @param callback
   */
  onConversationNotificationClick(callback: (event: Event, conversationId: string) => void): void {
    if (!this.desktopApp) {
      return;
    }

    // новое Desktop-приложение
    if (this.$window.electronApi) {
      this.$window.electronApi.onConversationNotificationClick(callback);
    }

    // старое Desktop-приложение
    if (!this.$window.electronApi) {
      this.$window.ipcRenderer.on('notification-click', callback);
    }
  }

  /**
   * Устанавливает иконку непрочитанного сообщения в Desktop-приложении
   */
  setUnreadMessageIcon(): void {
    if (!this.desktopApp) {
      return;
    }

    // новое Desktop-приложение
    if (this.$window.electronApi) {
      this.$window.electronApi.setUnreadMessageIcon();
    }

    // старое Desktop-приложение
    if (!this.$window.electronApi) {
      this.$window.ipcRenderer.sendToHost('set-unread-msg-icon');
    }
  }

  /**
   * Устанавливает иконку непрочитанного сообщения в Desktop-приложении
   */
  setDefaultIcon(): void {
    if (!this.desktopApp) {
      return;
    }

    // новое Desktop-приложение
    if (this.$window.electronApi) {
      this.$window.electronApi.setDefaultIcon();
    }

    // старое Desktop-приложение
    if (!this.$window.electronApi) {
      this.$window.ipcRenderer.sendToHost('set-default-icon');
    }
  }

  /**
   * Показывает Desktop-уведомление о диалоге
   *
   * @param object Уведомление из диалогов
   */
  showConversationNotification(notification: ConversationNotification): void {
    if (!this.desktopApp) {
      return;
    }

    // новое Desktop-приложение
    if (this.$window.electronApi) {
      this.$window.electronApi.showConversationNotification(notification);
    }

    // старое Desktop-приложение
    if (!this.$window.electronApi) {
      this.$window.ipcRenderer.sendToHost('panel-notification', notification);
    }
  }
}

/**
 * Уведомление из диалогов, которое будет отправлено в Electron
 */
export type ConversationNotification = {
  title: string;
  conversationId: string;
  body: string;
  icon: string;
};

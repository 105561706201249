(function () {
  'use strict';

  require('./edit.controller');

  angular.module('myApp.chatBot.leadBot').component('cqChatBotEdit', cqChatBotEdit());

  function cqChatBotEdit() {
    return {
      bindings: {
        autoEvents: '=', // Автособытия
        billingInfo: '=', // Информация о биллинге
        channels: '=', // Каналы
        chatBotMessage: '=', // Чат-бот
        conversationTags: '=', // Теги диалогов
        currentApp: '=', // Текущее приложение
        calendlyEventList: '=', // Список календарей в интеграциях Calendly
        calendlyIntegrationList: '=', // Список интеграций Calendly
        dataAboutActiveFacebookBots: '=', // Информация об активных Facebook ботах
        dataAboutActiveLeadBots: '=', // Информация об активных Lead ботах
        dataAboutActiveTelegramBots: '=', // Информация об активных Telegram ботах
        dataAboutActiveWelcomeBots: '=', // Информация об активных Welcome ботах
        djangoUser: '=', // Текущий пользователь
        djangoUserTempData: '=', // Временные данные django-пользователя
        emailNotificationIntegrationsExternal: '=', // Интеграции типа "Уведомление на Email" в внешнем формате
        amocrmIntegrationsExternal: '=', // Интеграции типа "AmoCRM" в внешнем формате
        isFirstLeadBot: '=?', // Первый ли это лид-бот
        planCapabilities: '=', // Список ограничений по тарифу
        properties: '=', // Список свойств и типов событий
        templates: '=', // Список шаблонов
        segments: '=', // Сегменты
        tags: '=', // Теги пользователей
        teamMembers: '=', // члены команды
        telegramIntegrations: '=',
      },
      controller: 'cqChatBotEditController',
      controllerAs: 'vm',
      template: require('./edit.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TELEGRAM_API_ERRORS } from '@http/integration/integrations/telegram/constants/telegram-integration.constants';
import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';
import { TelegramIntegrationFormData } from '@http/integration/integrations/telegram/types/telegram-integration.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-bot-token',
  templateUrl: './bot-token.component.html',
  styleUrls: ['./bot-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramBotTokenComponent {
  /** Control для настройки bot_token'а интеграции */
  @Input({ required: true })
  control!: GenericFormControl<TelegramIntegrationFormData['settings']['botToken']>;

  @Input({ required: true })
  integration!: TelegramIntegration;

  /** Возможные ошибки API */
  TELEGRAM_API_ERRORS = TELEGRAM_API_ERRORS;
  /** Пример токена бота */
  tokenExample: string = '000000000:AAAAa0Aaaa-aAaaA0AaAaAAA_AAaaAAaaAA';
}

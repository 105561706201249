<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('modals.waChatIcon.header') }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="margin-bottom-20">{{ t('modals.waChatIcon.body') }}</div>
    <img
      class="d-block mx-auto"
      src="assets/img/default/integrations/whatsapp-edna/chat-preview.svg"
    />
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
      type="button"
    >
      {{ t('modals.waChatIcon.cancelButton') }}
    </button>
    <a
      class="btn btn-primary"
      (click)="goToChatSettings()"
      >{{ t('modals.waChatIcon.goToChatSettingsButton') }}</a
    >
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'articlesLimitationBannerComponent'">
  <div
    class="flex align-items-center padding-top-10 padding-right-20 padding-bottom-10 padding-left-20 bg-light-primary"
  >
    <div class="margin-right-10">{{ 'articlesLimitationBanner.knowledgeBaseLimitationInfo' | transloco }}</div>
    <button
      class="btn btn-sm btn-outline-primary"
      (click)="showPaywall()"
    >
      {{ 'articlesLimitationBanner.learnMoreButton' | transloco }}
    </button>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'textBlockEditorComponent'">
  <div
    class="form-control-container"
    [cqShowError]="control.controls.value"
  >
    <cq-quill-text-editor
      [formats]="formats"
      [formControl]="control.controls.value"
      [placeholder]="t('quillTextEditor.placeholder')"
    ></cq-quill-text-editor>

    <cq-validation-messages [control]="control.controls.value">
      <cq-validation-message errorName="required">
        {{ t('quillTextEditor.errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('quillTextEditor.errors.maxLength', { maxLength: TEXT_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

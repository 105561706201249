import { Injectable } from '@angular/core';

import { App } from '@http/app/app.model';
import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import { PlanCapabilityModel } from '@http/plan-capability/plan-capability.model';
import { PlanAddonService } from '@panel/app/services/billing/plan-addon/plan-addon.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import {
  ProductFeatureDenialReason,
  SpecialProductFeatures,
} from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';

@Injectable({ providedIn: 'root' })
export class PlanSpecialDenialReasonService {
  constructor(
    private readonly planAddonService: PlanAddonService,
    private readonly planCapabilityModel: PlanCapabilityModel,
    private readonly planVersionService: PlanVersionService,
  ) {}

  /**
   * Получение причины отказа в доступе до специальных фич
   *
   * NOTE:
   *  Про «специальные» фичи смотри комментарий к SPECIAL_PLAN_FEATURES
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   */
  getReason(productFeature: SpecialProductFeatures, app: App, amount?: number): ProductFeatureDenialReason {
    switch (productFeature) {
      case PLAN_FEATURE.AI_BOT:
        return this.getReasonForAiBot(productFeature, app);
      case PLAN_FEATURE.AUTO_MESSAGES_MASS_ACTIVATION:
        return this.getReasonForAutoMessagesMassActivation(productFeature, app);
      case PLAN_FEATURE.AUTO_MESSAGES_TOTAL:
        return this.getReasonForAutoMessagesTotal(productFeature, app, amount);
      case PLAN_FEATURE.CHAT_BOTS_MASS_ACTIVATION:
        return this.getReasonForChatBotsMassActivation(productFeature, app);
      case PLAN_FEATURE.AUTO_MESSAGES_PAGE:
        return this.getReasonForAutoMessagesPage(productFeature);
      case PLAN_FEATURE.CHAT_BOTS_PAGE:
        return this.getReasonForChatBotsPage(productFeature, app);
      case PLAN_FEATURE.FACEBOOK_BOTS:
        return this.getReasonForFacebookBots(productFeature, app, amount);
      case PLAN_FEATURE.KNOWLEDGE_BASE:
        return this.getReasonForKnowledgeBase(productFeature, app);
      case PLAN_FEATURE.KNOWLEDGE_BASE_PRO:
        return this.getReasonForKnowledgeBasePro(productFeature, app);
      case PLAN_FEATURE.LEAD_BOTS:
        return this.getForLeadBots(productFeature, app, amount);
      case PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND:
        return this.getReasonForManualMessagesBulkSend(productFeature, app);
      case PLAN_FEATURE.MESSAGES_CHANNELS:
        return this.getReasonForMessagesChannels(productFeature, app);
      case PLAN_FEATURE.NO_BRANDING:
        return this.getReasonForNoBranding(productFeature, app);
      case PLAN_FEATURE.TELEGRAM_BOTS:
        return this.getReasonForTelegramBots(productFeature, app, amount);
      case PLAN_FEATURE.WELCOME_BOTS:
        return this.getReasonForWelcomeBots(productFeature, app);
    }
  }

  /**
   * Получение причины отказа в доступе до страницы триггерных сообщений
   *
   * @param productFeature - Фича, доступ к которой ограничен
   */
  getReasonForAutoMessagesPage(productFeature: SpecialProductFeatures): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      default:
        throw new Error('Should not be called on this plan version');
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "AI-бот"
   *
   * @param productFeature - Фича, доступ к которой ограничен
   * @param app - Текущее приложение
   */
  getReasonForAiBot(productFeature: SpecialProductFeatures, app: App) {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: BILLING_ADD_ONS.AI_BOTS_UNITS_PRICING_V2,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: BILLING_ADD_ONS.AI_BOTS_UNITS_PRICING_V2,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: BILLING_ADD_ONS.AI_BOTS_UNITS_PRICING_V2,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: BILLING_ADD_ONS.AI_BOTS_UNITS_PRICING_V2,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        return {
          addOn: BILLING_ADD_ONS.AI_BOTS_UNITS_PRICING_V2,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Массовая активация триггерных сообщений"
   *
   * @param productFeature - Фича, доступ к которой ограничен
   * @param app - Текущее приложение
   */
  getReasonForAutoMessagesMassActivation(productFeature: SpecialProductFeatures, app: App) {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.AUTO_MESSAGES_MASS_ACTIVATION,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.AUTO_MESSAGES_MASS_ACTIVATION,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        throw new Error('Should not be called for this plan version');
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.AUTO_MESSAGES_MASS_ACTIVATION,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.AUTO_MESSAGES_MASS_ACTIVATION,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /** Получение причины отказа в доступе к странице чат-ботов */
  getReasonForChatBotsPage(productFeature: SpecialProductFeatures, app: App): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isLTD():
        return {
          addOn: BILLING_ADD_ONS.LTD_CHAT_BOTS_LIMIT_EXTENSION,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      default:
        throw new Error('Should not be called for this plan version');
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Массовая активация чат-ботов"
   *
   * @param productFeature - Фича, доступ к которой ограничен
   * @param app - Текущее приложение
   */
  getReasonForChatBotsMassActivation(productFeature: SpecialProductFeatures, app: App): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_MASS_ACTIVATION,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_MASS_ACTIVATION,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        throw new Error('Should not be called for this plan version');
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_MASS_ACTIVATION,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_MASS_ACTIVATION,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Facebook-боты"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   */
  getReasonForFacebookBots(
    productFeature: SpecialProductFeatures,
    app: App,
    amount?: number,
  ): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_TOTAL,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.LEAD_BOTS_TOTAL,
          limitExceeded: true,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL),
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature: PLAN_FEATURE.LEAD_BOTS, // Facebook-боты в LTD завязаны на лимите Lead-ботов
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: BILLING_ADD_ONS.CHAT_BOTS,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V1,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_TOTAL,
          limitExceeded: true,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL),
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature: PLAN_FEATURE.LEAD_BOTS, // Facebook-боты в V2 завязаны на лимите Lead-ботов
        };
      default:
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return {
          addOn:
            this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING) ||
            this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING_V0224)
              ? null
              : BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING,
          capability: this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL, amount)
            ? null
            : PLAN_CAPABILITIES.LEAD_BOTS_TOTAL,
          limitExceeded: true,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL),
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature: PLAN_FEATURE.LEAD_BOTS, // Facebook-боты в V3 завязаны на лимите Lead-ботов
        };
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Telegram-боты"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   */
  getReasonForTelegramBots(
    productFeature: SpecialProductFeatures,
    app: App,
    amount?: number,
  ): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL,
          // Временно до тех пор, пока боты безлимитны
          limitExceeded: false, // true
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL,
          // Временно до тех пор, пока боты безлимитны
          limitExceeded: false, // true
          limitAmount: null, // this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL)
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature: PLAN_FEATURE.TELEGRAM_BOTS, // Telegram-боты в LTD завязаны на лимите Lead-ботов
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: BILLING_ADD_ONS.TELEGRAM_BOT_FOR_OLD_PLANS,
          capability: null,
          // Временно до тех пор, пока боты безлимитны
          limitExceeded: false, // true
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V1,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL,
          // Временно до тех пор, пока боты безлимитны
          limitExceeded: false, // true
          limitAmount: null, // this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL)
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature: PLAN_FEATURE.TELEGRAM_BOTS, // Telegram-боты в V2 завязаны на лимите Lead-ботов
        };
      case this.planVersionService.isV3(app):
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return {
          addOn: BILLING_ADD_ONS.TELEGRAM_BOT,
          capability: this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL)
            ? null
            : PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL,
          // Временно до тех пор, пока боты безлимитны
          limitExceeded: false, // true
          limitAmount: null, // this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL)
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature: PLAN_FEATURE.TELEGRAM_BOTS, // Telegram-боты в V3 завязаны на лимите Lead-ботов
        };
      default:
        throw new Error('Unknown plan version');
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "База знаний Pro"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   */
  getReasonForKnowledgeBase(productFeature: SpecialProductFeatures, app: App): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.KNOWLEDGE_BASE,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.KNOWLEDGE_BASE_PRO,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.KNOWLEDGE_BASE,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V1,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.KNOWLEDGE_BASE,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      case this.planVersionService.isV3(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.KNOWLEDGE_BASE,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
      default:
        throw new Error('Unknown plan version');
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "База знаний Pro"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   */
  getReasonForKnowledgeBasePro(productFeature: SpecialProductFeatures, app: App): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.KNOWLEDGE_BASE_PRO,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.KNOWLEDGE_BASE_PRO,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V1,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.KNOWLEDGE_BASE_PRO,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        return {
          addOn:
            this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING) ||
            this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING_V0224)
              ? null
              : BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING,
          capability: this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.KNOWLEDGE_BASE_PRO)
            ? null
            : PLAN_CAPABILITIES.KNOWLEDGE_BASE_PRO,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Лид-боты"
   *
   * @param productFeature - Фича, доступ к которой ограничен
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   */
  getForLeadBots(productFeature: SpecialProductFeatures, app: App, amount?: number): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_TOTAL,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.LEAD_BOTS_TOTAL,
          limitExceeded: amount !== 0,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL),
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: BILLING_ADD_ONS.CHAT_BOTS,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V1,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_TOTAL,
          limitExceeded: true,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL),
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return {
          addOn:
            this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING) ||
            this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING_V0224)
              ? null
              : BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING,
          capability: this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL, amount)
            ? null
            : PLAN_CAPABILITIES.LEAD_BOTS_TOTAL,
          limitExceeded: true,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL),
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Массовая отправка ручных сообщений"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   */
  getReasonForManualMessagesBulkSend(productFeature: SpecialProductFeatures, app: App): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
      case this.planVersionService.isV1(app):
      case this.planVersionService.isV2(app):
        throw new Error('Should not be called for this plan version');
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.MANUAL_MESSAGES_BULK_SEND,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.MANUAL_MESSAGES_BULK_SEND),
          limitExceeded: true,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      default:
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.MANUAL_MESSAGES_BULK_SEND,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.MANUAL_MESSAGES_BULK_SEND),
          limitExceeded: true,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Ручное создание каналов"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   */
  getReasonForMessagesChannels(productFeature: SpecialProductFeatures, app: App): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.MESSAGES_CHANNELS,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.MESSAGES_CHANNELS,
          limitExceeded: true,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.MESSAGES_CHANNELS),
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        throw new Error('Should not be called for this plan version');
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.MESSAGES_CHANNELS,
          limitExceeded: true,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.MESSAGES_CHANNELS),
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.MESSAGES_CHANNELS,
          limitExceeded: true,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.MESSAGES_CHANNELS),
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Без брендинга"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   */
  getReasonForNoBranding(productFeature: SpecialProductFeatures, app: App): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: BILLING_ADD_ONS.NO_BRANDING,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: BILLING_ADD_ONS.NO_BRANDING,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V1,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: BILLING_ADD_ONS.NO_BRANDING,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        return {
          addOn: BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /**
   * Получение причины отказа в доступе до фичи "Welcome-боты"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   */
  getReasonForWelcomeBots(productFeature: SpecialProductFeatures, app: App): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_TOTAL,
          limitAmount: null,
          limitExceeded: false,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: BILLING_ADD_ONS.LTD_WELCOME_BOTS,
          capability: null,
          limitAmount: null,
          limitExceeded: false,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: BILLING_ADD_ONS.CHAT_BOTS,
          capability: null,
          limitAmount: null,
          limitExceeded: false,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V1,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.CHAT_BOTS_TOTAL,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL),
          limitExceeded: true,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature: PLAN_FEATURE.LEAD_BOTS, // Welcome-боты в V2 завязаны на лимите Lead-ботов
        };
      default:
        return {
          addOn: BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING,
          capability: null,
          limitAmount: null,
          limitExceeded: false,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }

  /**
   * Получение причины отказа в доступе для фичи "Триггерные сообщения"
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   */
  private getReasonForAutoMessagesTotal(
    productFeature: SpecialProductFeatures,
    app: App,
    amount?: number,
  ): ProductFeatureDenialReason {
    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL,
          limitAmount: null,
          limitExceeded: false,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isLTD():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL),
          // Если amount=0, то значит фича недоступна в принципе, тексты надо показывать соотвествующие
          limitExceeded: amount !== 0,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        throw new Error('Should not be called for this plan version');
      case this.planVersionService.isV2(app):
        return {
          addOn: null,
          capability: PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL),
          limitExceeded: true,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return {
          addOn:
            this.planAddonService.isAddonConnected(BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING) ||
            this.planAddonService.isAddonConnected(BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING_V0224)
              ? null
              : BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING,
          capability: this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL, amount)
            ? null
            : PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL,
          limitAmount: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL),
          limitExceeded: true,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }
}

(function () {
  'use strict';

  require('./widget-indent.controller');

  angular.module('myApp.chatSettings').component('cqWidgetIndent', cqWidgetIndent());

  function cqWidgetIndent() {
    return {
      bindings: {
        horizontalIndent: '=', // Горизонтальный отступ бейджа чата
        position: '=', // Расположение бейджа чата
        verticalIndent: '=', // Вертикальный отступ бейджа чата
      },
      controller: 'CqWidgetIndentController',
      controllerAs: 'vm',
      template: require('./widget-indent.html'),
    };
  }
})();

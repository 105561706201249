/** Сервис для работы с модальным окном "Напиши себе в интеграцию" */
(function () {
  'use strict';

  angular
    .module('myApp.services.writeToIntegrationModalService')
    .service('writeToIntegrationModalService', writeToIntegrationModalService);

  function writeToIntegrationModalService($uibModal) {
    /** Инстанс модального окна */
    let writeToIntegrationModalInstance;

    return {
      hideModal: hideModal,
      showModal: showModal,
    };

    /** Скрытие модального окна */
    function hideModal() {
      if (writeToIntegrationModalInstance) {
        writeToIntegrationModalInstance.close();
        writeToIntegrationModalInstance = null;
      }
    }

    /**
     * Открытие модального окна
     *
     * @param {Object} currentApp - Текущее приложение
     * @param {Object} djangoUser - Текущий django-пользователь
     */
    function showModal(currentApp, djangoUser) {
      if (!writeToIntegrationModalInstance) {
        writeToIntegrationModalInstance = $uibModal.open({
          component: 'cqWriteToIntegrationsWrapper',
          size: 'md modal-dialog-centered',
        });

        writeToIntegrationModalInstance.result.finally(() => {
          writeToIntegrationModalInstance = null;
        });
      }
    }
  }
})();

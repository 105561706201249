(function () {
  'use strict';

  angular
    .module('myApp.modals.leaveSiteAttemptSettingWrapper')
    .component('cqLeaveSiteAttemptSettingWrapper', cqLeaveSiteAttemptSettingWrapper());

  function cqLeaveSiteAttemptSettingWrapper() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=', // В resolve должны передаваться следующие параметры:
        // currentApp - Текущее приложение
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./leave-site-attempt-setting-wrapper.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment/moment';

@Component({
  selector: 'cq-events-analytics-page',
  templateUrl: './events-analytics-page.component.html',
  styleUrls: ['./events-analytics-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsAnalyticsPageComponent {
  constructor() {
    var months = moment.monthsShort();

    for (var i = 0; i < months.length; i++) {
      months[i] = (months[i].charAt(0).toUpperCase() + months[i].slice(1)).replace('.', '');
    }

    //@ts-ignore
    AmCharts.baseHref = true;
    //@ts-ignore
    AmCharts.shortMonthNames = months;
  }
}

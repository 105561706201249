import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { ChatBotActionAttachment } from '@http/chat-bot/types/action-internal.types';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

@Component({
  selector: 'cq-branch-action-file[actionForm]',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionFileComponent {
  @Input() actionForm!: BaseBotActionForm;

  readonly EMPTY_FILE_SIZE = 0;
  readonly MAX_FILE_SIZE = 10 * 1024 * 1024;

  // @formatter:off
  constructor(
    readonly fileHelperService: FileHelperService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}
  // @formatter:on

  get attachments(): GenericFormControl<ChatBotActionAttachment[]> {
    return this.actionForm.controls.attachments;
  }

  get file(): ChatBotActionAttachment | File {
    return this.actionForm.get('attachments').value[0];
  }

  onFileChange(event: NgxDropzoneChangeEvent) {
    // rejectedFiles сюда добавлены, чтоб до клиента все равно доходила ошибка
    const files = [...event.addedFiles, ...event.rejectedFiles];

    if (!files || files.length !== 1) {
      return;
    }

    const file = files[0];
    if (!this.fileHelperService.isAcceptedExtension(file.name)) {
      const message = this.translocoService.translate('chatBot.actionFile.toasts.fileExtension');
      this.toastService.danger(message);
      return;
    }

    if (file.size > this.MAX_FILE_SIZE) {
      const message = this.translocoService.translate('chatBot.actionFile.toasts.maxFileSize', {
        maxFileSize: this.MAX_FILE_SIZE / 1024 / 1024,
      });
      this.toastService.danger(message);
      return;
    }

    if (file.size === this.EMPTY_FILE_SIZE) {
      const message = this.translocoService.translate('chatBot.actionFile.toasts.emptyFileSize');
      this.toastService.danger(message);
      return;
    }

    // @ts-ignore TODO ANGULAR_TS тут проблема в том, что есть Attachment а есть File, надо разделять контролы для этого
    this.actionForm.controls.attachments.setValue([file]);

    this.readFile(file).then(
      function (file: any, fileContents: any) {
        file.url = fileContents;
      }.bind(this, file),
    );
  }

  private readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        return resolve(e.target!.result!);
      };

      reader.onerror = (e) => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      reader.readAsDataURL(file);
    });
  }

  removeFile() {
    this.actionForm.controls.attachments.setValue([]);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserTag } from '@http/user/types/user.type';
import { Properties } from '@http/property/property.model';

@Injectable()
export class AiDataBotSettingsStateService {
  readonly properties$: BehaviorSubject<Properties> = new BehaviorSubject<Properties>({
    eventTypeProps: [],
    eventTypes: [],
    userProps: [],
  });
  readonly tags$: BehaviorSubject<UserTag[]> = new BehaviorSubject<UserTag[]>([]);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { StripeInputModule } from '@panel/app/pages/subscription/general/stripe-input/stripe-input.module';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

import { StripeEditCardModalComponent } from './stripe-edit-card-modal.component';

@NgModule({
  declarations: [StripeEditCardModalComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, StripeInputModule],
  providers: [NgbActiveModal, SubscriptionStore],
})
export class StripeEditCardModalModule {}

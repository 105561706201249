(function () {
  'use strict';

  angular
    .module('myApp.modals.createUrlAutoEventWrapper')
    .component('cqCreateUrlAutoEventWrapper', cqCreateUrlAutoEventWrapper());

  function cqCreateUrlAutoEventWrapper() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=', // В resolve должны передаваться следующие параметры:
        // currentAppId - Текущее приложение
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./create-url-auto-event-wrapper.html'),
    };
  }
})();

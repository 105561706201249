import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { WHATS_APP_EDNA_API_ERRORS } from '@http/integration/integrations/whats-app-edna/constants/whats-app-edna-integration.constants';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-whats-app-edna-access-token',
  templateUrl: './access-token.component.html',
  styleUrls: ['./access-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsAppEdnaAccessTokenComponent extends AbsCVAFormControlBasedComponent<string | null> {
  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
    validators: [Validators.required],
  });

  /** Возможные ошибки API */
  WHATS_APP_EDNA_API_ERRORS = WHATS_APP_EDNA_API_ERRORS;
}

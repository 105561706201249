/**
 * Валидация, проверяющая есть ли выбранный элемент в списке
 *
 * @param array cqHasItem - Список элементов в котором надо проверить удаленный элемент
 */
(function () {
  'use strict';
  //!!!!ИСПОЛЬЗОВАТЬ ВАЛИДАТОР НАДО С КРАЙНЕЙ ОСТОРОЖНОСТЬЮ И ОЧЕНЬ ОСОЗНАННО, А ЛУЧШЕ ВООБЩЕ НЕ ИСПОЛЬЗОВАТЬ
  // ПРИ БОЛЬШОМ МАССИВЕ С БОЛЬШОИМ КОЛИЧЕСТВОМ СВОЙСТВ В ОБЬЕКТЕ МОЖЕТ ЧИЛЬНО ЛАГАТЬ
  // ИЗ-ЗА ИСОЛЬЗОВАТНИЯ $filter с {$: value}
  angular.module('myApp.integrations').directive('cqPropNameFormat', cqPropNameFormat);

  function cqPropNameFormat() {
    return {
      restrict: 'A',
      require: 'ngModel',
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$validators.cqPropNameFormat = cqPropNameFormatValidator;
      const PROPS_NAME_FORMAT = /^(anketa)\.a\d+\.q\d+/; // Формат имени свойтсва

      /**
       * Функция для валидации на удаленный элемент из селекта
       *
       * @param modelValue Значение модели для валидации
       * @returns {Boolean}
       */
      function cqPropNameFormatValidator(modelValue) {
        // если значение модели пустое - валидация не требуется
        if (controller.$isEmpty(modelValue)) {
          return true;
        }

        return PROPS_NAME_FORMAT.test(modelValue);
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'assigneeFilterComponent'">
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
  >
    <button
      class="d-flex justify-space-between align-items-center btn btn-outline-primary"
      type="button"
      [disabled]="disabled"
      ngbDropdownToggle
      [ngbTooltip]="t('tooltip')"
      [autoClose]="false"
      container="body"
    >
      <!--Без фильтра-->
      <ng-container *ngIf="control.value === PSEUDO_ASSIGNEES.ALL">
        <i class="btn-icon cqi-sm cqi-users-o"> </i>
      </ng-container>

      <!--Неразобранные-->
      <ng-container *ngIf="control.value === PSEUDO_ASSIGNEES.NOT_ASSIGNED">
        <i class="btn-icon cqi-sm cqi-user-square text-danger"> </i>
      </ng-container>

      <!--Мои диалоги и неразобранные-->
      <ng-container *ngIf="control.value === PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED">
        <div class="position-relative mine-and-not-assigned-wrapper">
          <img
            class="team-member-avatar"
            [ngSrc]="me.avatar"
            width="25"
            height="25"
          />
          <div class="not-assigned-icon with-team-member-avatar d-flex align-items-center justify-center bg-danger">
            <i class="cqi-sm cqi-user"></i>
          </div>
        </div>
      </ng-container>

      <!-- Диалоги оператора -->
      <ng-container *ngIf="isTeamMember(control.value)">
        <img
          class="team-member-avatar"
          [src]="control.value.avatar"
          width="25"
          height="25"
        />
      </ng-container>

      <!--Чат-боты-->
      <ng-container *ngIf="selectedAssistantType">
        <i
          class="btn-icon cqi-sm"
          [ngClass]="getAssistantIcon(selectedAssistantType)"
        >
        </i>
      </ng-container>

      <i class="btn-icon cqi-sm cqi-chevron-down"></i>
    </button>

    <div
      class="overflow-hidden"
      ngbDropdownMenu
    >
      <ng-container *ngIf="dropdown.isOpen()">
        <div class="dropdown-menu-inner">
          <div class="has-feedback has-feedback-left m-0 bordered-bottom">
            <input
              class="form-control form-control-lg"
              [autofocus]="dropdown.isOpen()"
              [formControl]="searchPhrase"
              [placeholder]="t('searchInput.placeholder')"
            />
            <span class="form-control-feedback">
              <i class="cqi-sm cqi-search"></i>
            </span>
          </div>
          <div class="custom-scroll overflow-y-auto py-5">
            <div *ngIf="!searchPhrase.value">
              <ng-container *ngFor="let pseudoAssignee of PSEUDO_ASSIGNEES_ARRAY">
                <ng-container *ngIf="pseudoAssignee === PSEUDO_ASSIGNEES.ALL">
                  <ng-container
                    [ngTemplateOutlet]="dropdownItemTemplate"
                    [ngTemplateOutletContext]="{
                      $implicit: withoutOperatorsTemplate,
                      clickFn: trackClickOnTheFilterByOperatorButton.bind(this, PSEUDO_ASSIGNEES.ALL),
                      teamMember: PSEUDO_ASSIGNEES.ALL
                    }"
                  ></ng-container>
                </ng-container>

                <ng-container *ngIf="pseudoAssignee === PSEUDO_ASSIGNEES.NOT_ASSIGNED">
                  <ng-container
                    [ngTemplateOutlet]="dropdownItemTemplate"
                    [ngTemplateOutletContext]="{
                      $implicit: notAssignedTemplate,
                      clickFn: trackClickOnTheFilterByOperatorButton.bind(this, PSEUDO_ASSIGNEES.NOT_ASSIGNED),
                      teamMember: PSEUDO_ASSIGNEES.NOT_ASSIGNED
                    }"
                  ></ng-container>
                </ng-container>

                <ng-container *ngIf="pseudoAssignee === PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED">
                  <ng-container
                    [ngTemplateOutlet]="dropdownItemTemplate"
                    [ngTemplateOutletContext]="{
                      $implicit: mineAndNotAssignedTemplate,
                      clickFn: trackClickOnTheFilterByOperatorButton.bind(this, PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED),
                      teamMember: PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED
                    }"
                  ></ng-container>
                </ng-container>
              </ng-container>
              <ng-container
                [ngTemplateOutlet]="dropdownItemTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: meTemplate,
                  clickFn: trackClickOnTheFilterByOperatorButton.bind(this, me),
                  teamMember: me
                }"
              ></ng-container>

              <!--Чат-боты-->
              <div *ngIf="availableAssistants!.length">
                <div class="dropdown-divider mb-0"></div>
                <span class="dropdown-header">{{ t('assistants.dropdownHeader') }}</span>
                <ng-container *ngFor="let assistant of availableAssistants">
                  <ng-container
                    [ngTemplateOutlet]="dropdownItemTemplate"
                    [ngTemplateOutletContext]="{
                      $implicit: assistantTemplate,
                      clickFn: trackClickOnTheFilterByAssistantButton.bind(this, assistant),
                      teamMember: assistant
                    }"
                  ></ng-container>
                </ng-container>
              </div>
            </div>

            <ng-container
              *ngFor="
                let item of groupedTeamMembers$ | async | keyvalue;
                first as first;
                trackBy: trackByGroupName;
                empty: blank
              "
            >
              <div
                *ngIf="!(first && searchPhrase.value)"
                class="dropdown-divider no-margin-bottom"
              ></div>
              <span class="dropdown-header">{{ item.key.name }}</span>
              <ng-container
                *ngFor="let teamMember of item.value; trackBy: trackByTeamMemberId"
                [ngTemplateOutlet]="dropdownItemTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: conversationOperatorTemplate,
                  clickFn: trackClickOnTheFilterByOperatorButton.bind(this, teamMember.name),
                  teamMember: teamMember
                }"
              ></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-template
    #dropdownItemTemplate
    let-teamMember="teamMember"
    let-clickFn="clickFn"
    let-param
  >
    <label
      class="d-flex align-items-center mb-0 cursor-pointer"
      (click)="clickFn()"
      ngbDropdownItem
    >
      <input
        class="hidden"
        type="radio"
        (click)="$event.stopPropagation()"
        [formControl]="control"
        [value]="teamMember"
      />
      <ng-container
        [ngTemplateOutlet]="param"
        [ngTemplateOutletContext]="{ teamMember: teamMember }"
      ></ng-container>
      <i
        *ngIf="control.value === teamMember"
        class="dropdown-item-icon-right cqi-sm cqi-check"
      ></i>
    </label>
  </ng-template>

  <ng-template #withoutOperatorsTemplate>
    <div class="d-flex overflow-hidden align-items-center ml-5">
      <i class="cqi-sm cqi-users-o mr-15"></i>
      <div class="text-truncate">
        {{ t('assignees.' + PSEUDO_ASSIGNEES.ALL) }}
      </div>
    </div>
  </ng-template>

  <ng-template #notAssignedTemplate>
    <cq-not-assigned [unsorted]="true"></cq-not-assigned>
  </ng-template>

  <ng-template #mineAndNotAssignedTemplate>
    <div class="d-flex align-items-center overflow-hidden">
      <div class="position-relative no-flex-shrink mine-and-not-assigned-wrapper">
        <img
          class="team-member-avatar"
          [ngSrc]="me.avatar"
          [width]="20"
          [height]="20"
        />
        <div class="not-assigned-icon with-team-member-avatar d-flex align-items-center justify-center bg-danger">
          <i class="cqi-sm cqi-user"></i>
        </div>
      </div>
      <div class="overflow-hidden ml-10">
        <div class="text-truncate">
          {{ t('assignees.' + PSEUDO_ASSIGNEES.MINE_AND_NOT_ASSIGNED) }}
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #meTemplate>
    <div class="d-flex align-items-center overflow-hidden">
      <img
        class="team-member-avatar"
        [ngSrc]="me.avatar"
        [width]="25"
        [height]="25"
      />
      <div class="overflow-hidden ml-10">
        <div class="text-truncate">
          {{ t('assignees.mine') }}
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template
    #assistantTemplate
    let-teamMember="teamMember"
  >
    <div class="d-flex align-items-center overflow-hidden">
      <i
        class="cqi-sm ml-5 mr-15"
        [ngClass]="getAssistantIcon(teamMember)"
      ></i>
      <span>{{ t('assistants.' + teamMember) }}</span>
    </div>
  </ng-template>

  <ng-template
    #conversationOperatorTemplate
    let-teamMember="teamMember"
  >
    <cq-conversation-operator [teamMember]="teamMember"></cq-conversation-operator>
  </ng-template>

  <ng-template #blank>
    <h4 class="m-auto p-15 text-center text-muted">
      {{ t('zeroData.assigneeNotFound') }}
    </h4>
  </ng-template>
</ng-container>

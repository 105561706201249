import { SETTINGS_TABS } from '../../settings/settings.constants';

(function () {
  'use strict';

  angular
    .module('myApp.privacySettings')
    .controller('CqGeneralPrivacyPolicySettingsController', CqGeneralPrivacyPolicySettingsController);

  function CqGeneralPrivacyPolicySettingsController(PRIVACY_POLICY_URL, validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.generalPrivacyPolicySettingsForm = null;
      vm.PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;
      vm.SETTINGS_TABS = SETTINGS_TABS;
      vm.validationHelper = validationHelper;
    }
  }
})();

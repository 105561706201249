<ng-container *transloco="let t; read: 'oAuthButtonComponent'">
  <button
    *ngIf="isAuthorized"
    class="btn btn-success"
    [ngClass]="getBtnSizeClass()"
    disabled
    type="button"
    data-test="oauth-button-success"
  >
    {{ t('authorized') }}
  </button>

  <button
    *ngIf="!isAuthorized"
    class="btn btn-primary"
    [ngClass]="getBtnSizeClass()"
    [attr.disabled]="getBtnDisabledAttr()"
    (click)="onClickOAuthButton()"
    type="button"
    data-test="oauth-button"
  >
    <ng-content></ng-content>
  </button>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '@http/app/services/app.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { EmailNotificationIntegration } from '@http/integration/integrations/email-notification/email-notification-integration';
import { TeamMember } from '@http/team-member/team-member.types';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { EmailNotificationCreateModalParams } from '@panel/app/pages/integrations/content/email-notification/create-modal-wrapper/email-notification-create-modal-wrapper.types';
import { EmailNotificationComponent } from '@panel/app/pages/integrations/content/email-notification/email-notification.component';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-email-notification-modal-wrapper[modalWindowParams]',
  templateUrl: './email-notification-create-modal-wrapper.component.html',
  styleUrls: ['./email-notification-create-modal-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationCreateModalWrapperComponent implements OnInit {
  /** Параметры, передаваемые в модалку */
  @Input() modalWindowParams!: EmailNotificationCreateModalParams;

  /** TODO: Удалить как переделаются автосообщения, нужна чтоб закрывтаь ajs модалку и прокидывать созданную интеграцию */
  @Input() ajsModalInstance?: any;

  /** Дочерний элемент настройки интеграции */
  @ViewChild(EmailNotificationComponent) emailNotificationComponent!: EmailNotificationComponent;

  constructor(
    public activeModal: NgbActiveModal,
    public readonly appService: AppService,
    private readonly carrotquestHelper: CarrotquestHelper,
    public readonly stateService: StateService,
  ) {}

  ngOnInit() {
    if (
      isDefined(this.modalWindowParams.teamMembers) &&
      this.stateService.teamMembers$.getValue() !== this.modalWindowParams.teamMembers
    ) {
      this.stateService.teamMembers$.next(this.modalWindowParams.teamMembers as TeamMember[]);
    }

    this.trackOpenModal();
  }

  /**
   * Закрывает модалку и отдает в callback созданную интеграцию
   * @param integration
   */
  closeModal(integration: EmailNotificationIntegration) {
    this.ajsModalInstance ? this.ajsModalInstance.close(integration) : this.activeModal.close(integration);
  }

  /**
   * Закрывает модалку новую/старую в зависимости от того, откуда открыта
   */
  dismissModal() {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.activeModal.dismiss();
  }

  /**
   * Колбэк на создание интеграции
   *
   * @param integration Интеграция email notification
   */
  onIntegrationCreate(integration: EmailNotificationIntegration) {
    this.closeModal(integration);
  }

  /**
   * Трек открытия модалки
   */
  trackOpenModal(): void {
    this.carrotquestHelper.track(`Интеграции - ${INTEGRATION_TYPES.EMAIL_NOTIFICATION} - подключение интеграции`);
  }
}

<ng-container *transloco="let t">
  <section
    class="d-flex"
    id="content"
  >
    <div class="container-fluid limited-container">
      <div class="mt-40 text-center">{{ t('general.permissionDenied') }}</div>
    </div>
  </section>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ConversationPartAttachment } from '@http/conversation-part/internal-types';

@Component({
  selector: 'cq-conversation-part-preview-image[attachment]',
  templateUrl: './conversation-part-preview-image.component.html',
  styleUrls: ['./conversation-part-preview-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPartPreviewImageComponent {
  /** Прикрепление */
  @Input() attachment: ConversationPartAttachment | null = null;

  /** Url изображения */
  get imageUrl(): string | null {
    let url = null;

    if (this.attachment) {
      url = this.attachment.url;
    }

    return url;
  }
}

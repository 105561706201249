// todo: когда-нибудь этот контроллер надо будет отрефакторить и сделать так, чтобы стики-скролл работал везде
(function () {
  'use strict';

  angular.module('myApp.directives.stickyScroll').controller('CqStickyScrollController', CqStickyScrollController);

  function CqStickyScrollController($scope, $window, $element) {
    var vm = this;

    /**
     * Контейнер, к верху которого будет зафиксирован элемент
     *
     * @type {jQuery|Object}
     */
    var container;

    var widthElement;

    /**
     * Клон элемента
     *
     * @type {jQuery|Object}
     */
    var elementClone;

    /**
     * Смещение контейнера по вертикали относительно body
     *
     * @type {Object}
     */
    var containerVerticalOffset;

    var widthElementVerticalOffset;

    /**
     * Смещение элемента по вертикали относительно container
     *
     * @type {Object}
     */
    var elementVerticalOffset;

    var elementContainer;

    vm.$onInit = init;

    function init() {
      vm.cloneClass = angular.isDefined(vm.cloneClass) ? vm.cloneClass : 'clone'; // класс, который будет установлен клону элемента
      vm.fixClass = angular.isDefined(vm.fixClass) ? vm.fixClass : 'fixed'; // класс, который будет установлен элементу после его фиксации вверху контейнера
      vm.fixContainerClass = 'fixed-container';
      vm.setup = setup;
    }

    /**
     * Подгонка ширины элемента по ширине клона
     */
    function adjustWidth() {
      containerVerticalOffset = getVerticalOffset(container, angular.element('body'));
      widthElementVerticalOffset = getVerticalOffset(widthElement, container);

      if (elementClone) {
        elementVerticalOffset = getVerticalOffset(elementClone, widthElement);
      } else {
        elementVerticalOffset = getVerticalOffset($element, widthElement);
      }
    }

    /**
     * Отключение обработчиков событий
     */
    function destroy() {
      container.off('scroll', toggleStickyState);
      angular.element($window).off('resize', toggleStickyState);

      // на случай, если вдруг уничтожили элемент в зафиксированном положении (каким-нибудь ng-if)
      if (elementClone) {
        elementClone.remove();
      }
    }

    /**
     * Получение вертикального смещения ребёнка относительно родителя
     *
     * @param {jQuery|Object} child Дочерний элемент
     * @param {jQuery|Object} parent Родительский элемент
     * @returns {Object}
     */
    function getVerticalOffset(child, parent) {
      return child.offset().top - parent.offset().top + parent.scrollTop();
    }

    /**
     * Добавление обработчиков событий
     */
    function setEventListeners() {
      container.on('scroll', toggleStickyState);
      angular.element($window).on('resize', toggleStickyState);
      $element.on('$destroy', destroy);
    }

    /**
     * Инициализация и настройка директивы
     */
    function setup() {
      if (angular.isUndefined(vm.container)) {
        throw Error('container must be specified');
      }

      if (angular.isUndefined(vm.widthElement)) {
        throw Error('widthElement must be specified');
      }

      if (!(container = $element.parents(vm.container)).length) {
        throw Error('container element with this selector in parents of element not found: ', vm.container);
      }

      if (!(widthElement = $element.parents(vm.widthElement)).length) {
        throw Error('widthElement element with this selector in parents of element not found: ', vm.widthElement);
      }

      containerVerticalOffset = getVerticalOffset(container, angular.element('body'));
      widthElementVerticalOffset = getVerticalOffset(widthElement, container);
      elementVerticalOffset = getVerticalOffset($element, widthElement);
      toggleStickyState();
      setEventListeners();
    }

    /**
     * Переключение фиксации элемента внутри контейнера
     */
    function toggleStickyState() {
      adjustWidth();

      var a = container.scrollTop();
      var b = widthElement.innerHeight();
      var c = $element.outerHeight();

      // как только контейнер проскроллился до позиции элемента - создаём клона, скрываем его, а сам элемент начинаем скролить
      if (elementVerticalOffset + widthElementVerticalOffset <= a) {
        if (!elementClone) {
          elementClone = $element.clone();
          elementClone.addClass(vm.cloneClass);
          $element.after(elementClone);

          $element.detach();
          elementContainer = angular.element('<div></div>');
          elementContainer.addClass(vm.fixContainerClass);
          elementContainer.append($element);
          $element.addClass(vm.fixClass);
          elementClone.before(elementContainer);

          $scope.$apply(function () {
            vm.isFixed = true;
          });
        }

        // если элемент дошёл до конца контейнера - надо его там и оставить
        if (a > widthElementVerticalOffset + b - c) {
          $element.css({ transform: 'translate3d(0, ' + (b - elementVerticalOffset - c) + 'px, 0)' });
        } else {
          $element.css({
            transform: 'translate3d(0, ' + (a - widthElementVerticalOffset - elementVerticalOffset) + 'px, 0)',
          });
        }
      } else {
        if (elementClone) {
          $element.detach();
          elementContainer.remove();
          elementClone.after($element);

          elementClone.remove();
          $element.removeClass(vm.fixClass);
          $element.css({ transform: '' });
          elementClone = null;

          $scope.$apply(function () {
            vm.isFixed = false;
          });
        }
      }
    }

    /*function toggleStickyState() {
      adjustWidth();
    
      // как только контейнер проскроллился до позиции элемента - создаём клона, скрываем его, а сам элемент начинаем скролить
      if (elementVerticalOffset + widthElementVerticalOffset <= container.scrollTop()) {
        widthElement.addClass(vm.fixContainerClass);
      
        // если элемент дошёл до конца контейнера - надо его там и оставить
        if (container.scrollTop() > widthElementVerticalOffset + widthElement.innerHeight() - $element.outerHeight()) {
          $element.css({top: widthElement.innerHeight() - $element.outerHeight()});
        } else {
          $element.css({top: container.scrollTop() - widthElementVerticalOffset});
        }
      
        if (!elementClone) {
          elementClone = $element.clone();
          elementClone.addClass(vm.cloneClass);
          $element.after(elementClone);
          $element.addClass(vm.fixClass);
        
          vm.isFixed = true;
        }
      } else {
        if (elementClone) {
          elementClone.remove();
          widthElement.removeClass(vm.fixContainerClass);
          $element.removeClass(vm.fixClass);
          $element.css({top: ''});
          elementClone = null;
        
          vm.isFixed = false;
        }
      }
    }*/
  }
})();

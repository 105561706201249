import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { DatetimePickerModule } from '@panel/app/shared/components/datetime-picker/datetime-picker.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';

import { SearchFilterComponent } from './search-filter.component';

@NgModule({
  declarations: [SearchFilterComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    DirectivesModule,
    DatetimePickerModule,
    NgbPopoverModule,
    FormsModule,
  ],
})
export class SearchFilterModule {}

/**
 * Директива-обёртка для Split.js (https://github.com/nathancahill/Split.js)
 */
(function () {
  'use strict';

  angular.module('myApp.directives.split').directive('cqSplitView', cqSplitView);

  function cqSplitView() {
    return {
      restrict: 'EA',
      require: ['cqSplitView', '^^cqSplit'],
      scope: {
        // смотри параметры в документации к split.js
        minSize: '@',
        size: '@',
      },
      controller: angular.noop,
      controllerAs: 'vm',
      bindToController: true,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var cqSplitViewController = controller[0];
      var cqSplitController = controller[1];

      var viewData = {
        element: iElement,
        minSize: cqSplitViewController.minSize,
        size: cqSplitViewController.size,
      };

      cqSplitController.addView(viewData);

      scope.$on('$destroy', function () {
        cqSplitController.removeView(viewData);
      });
    }
  }
})();

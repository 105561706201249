import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent extends AbsCVAFormControlBasedComponent<string | null> {
  @ViewChild(NgSelectComponent)
  sourceSelect!: NgSelectComponent;

  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
    validators: [Validators.required],
  });
}

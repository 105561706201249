/**
 * Вызов $sce.trustAsResourceUrl() при помощи фильтра
 */
(function () {
  'use strict';

  angular.module('myApp.filters.trustUri').filter('trustUri', trustUri);

  function trustUri($sce) {
    return trustUriFilter;

    /**
     * Получение результата $sce.trustAsResourceUrl от строки с кодированием
     *
     * @param {String} value Строка для преобразования
     * @return {*}
     */
    function trustUriFilter(value) {
      return $sce.trustAsResourceUrl(
        'data:text/html;charset=utf-8;base64,' + window.btoa(unescape(encodeURIComponent(value))),
      );
    }
  }
})();

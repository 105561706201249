import { environment } from '@environment';
import { BillingPlanIds } from '@panel/app/services/billing-info/billing-info.model';

/*** Список всех уникальных ID тарифхных планов */
export enum UNIQUE_BILLING_PLAN_ID {
  AUTOMATION_11_20 = 'automation-11-20',
  AUTOMATION_V0421 = 'automation-V0421',
  AUTOMATION_V1021 = 'automation-V1021',
  BUSINESS_CHAT_11_20 = 'business-chat-11-20',
  BUSINESS_CHAT_V0421 = 'business-chat-V0421',
  BUSINESS_CHAT_V1021 = 'business-chat-V1021',
  FREE_FOREVER = 'free-forever', // NOTE: Используется только для APP ID = 100 и 4
  FREE_TRIAL = 'free-trial',
  FREEMIUM_03_21 = 'freemium-03-21', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
  LIFE_TIME_DEAL = 'life-time-deal', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_15K = 'life-time-deal-15k', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_20K = 'life-time-deal-20k', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_BUSINESS = 'life-time-deal-business', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_PRO = 'life-time-deal-pro', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_V2023_CONVERSATION_1 = 'LTD-v2023-conversation-1', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_V2023_CONVERSATION_2 = 'LTD-v2023-conversation-2', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_V2023_MARKETING_1 = 'LTD-v2023-marketing-1', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_V2023_MARKETING_2 = 'LTD-v2023-marketing-2', // NOTE В CQ нет такого тарифа
  LIFE_TIME_DEAL_V2023_MARKETING_3 = 'LTD-v2023-marketing-3', // NOTE В CQ нет такого тарифа
  CONVERSATION = 'conversation-V1122',
  SUPPORT = 'support-V1122',
  MARKETING = 'marketing-V1122',
}

/** ID тарифов третей тарифной сетки */
export const V3_PLAN_IDS = [
  UNIQUE_BILLING_PLAN_ID.CONVERSATION,
  UNIQUE_BILLING_PLAN_ID.SUPPORT,
  UNIQUE_BILLING_PLAN_ID.MARKETING,
];

/** Платные модули */
export enum BILLING_ADD_ONS {
  /** AI-бот */
  AI_BOTS_UNITS_PRICING = 'ai-bots-units-pricing',
  /** AI-бот (с исправленными ценами) */
  AI_BOTS_UNITS_PRICING_V2 = 'ai-bots-units-pricing-v2',
  /** Аналитика диалогов */
  ANALYTICS_CONVERSATIONS = 'analytics-conversations',
  /** Конструктор писем за 1000 */
  BEE_EDITOR = 'bee-editor',
  /** Конструктор писем за 1200 */
  BEE_EDITOR_V2 = 'bee-editor-v2',
  /** Чат-боты */
  CHAT_BOTS = 'chat-bots',
  /** Расширение лимита чат-ботов
   *
   * @deprecated DEPRECATED для CQ
   * */
  CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING = 'chat-bots-limit-extension-units-pricing',
  /** Индивидуальный почтовый домен */
  MAIL_DOMAIN = 'mail-domain',
  /** Настройка и персональное сопровождение */
  MARKETING = 'marketing',
  /** Расширение лимита чат-ботов на LTD */
  LTD_CHAT_BOTS_LIMIT_EXTENSION = 'ltd-chat-bots-limit-extension',
  /** Расширение лимита триггерных сообщений на LTD */
  LTD_TRIGGER_MESSAGES_LIMIT_EXTENSION = 'ltd-trigger-messages-limit-extension',
  /** «Оптимизация поддержки» в зависимости от количества уников в тарифе на LTD */
  LTD_OPTIMIZATION_SUPPORT = 'ltd-optimization-support',
  /** «Welcome-бот» в зависимости от количества уников в тарифе */
  LTD_WELCOME_BOTS = 'ltd-welcome-bots',
  /** Без брендинга (No branding) (индивидуальный почтовый домен и возможность убрать "Мы используем Carrot quest / Dashly") */
  NO_BRANDING = 'no-branding',
  /** «Без брендинга» в зависимости от количества уников в тарифе */
  NO_BRANDING_UNITS_PRICING = 'no-branding-units-pricing',
  /** «Оптимизация поддержки» в зависимости от количества уников в тарифе
   *
   * @deprecated DEPRECATED для CQ
   * */
  OPTIMIZATION_SUPPORT_UNITS_PRICING = 'optimization-support-units-pricing',
  /** База знаний PRO */
  PRO_KNOWLEDGE_BASE = 'pro-knowledge-base',
  /** База знаний PRO в зависимости от количества уников в тарифе
   *
   * @deprecated DEPRECATED для CQ
   * */
  PRO_KNOWLEDGE_BASE_UNITS_PRICING = 'pro-knowledge-base-units-pricing',
  /** Безопасность (логирование действий и двухфакторная авторизация) */
  SECURITY = 'security',
  /** Telegram-боты */
  TELEGRAM_BOT = 'telegram-bot',
  /** Telegram-боты для старых тарифов */
  TELEGRAM_BOT_FOR_OLD_PLANS = 'telegram-bot-for-old-plans',
  /** Отправка сообщений в Telegram через сообщения триггерные, ручные и цепочки */
  MESSAGES_TO_TELEGRAM = 'messages-to-telegram-tmp',
  /** Отправка сообщений в Telegram через сообщения триггерные, ручные и цепочки. СТАРЫЕ ТАРИФЫ */
  MESSAGES_TO_TELEGRAM_FOR_OLD_PLANS = 'messages-to-telegram-tmp-old',
  /** Расширение лимита триггерных сообщений
   *
   * @deprecated DEPRECATED для CQ
   * */
  TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING = 'trigger-messages-limit-extension-units-pricing',
  /** «Welcome-бот» в зависимости от количества уников в тарифе
   *
   * @deprecated DEPRECATED для CQ
   * */
  WELCOME_BOTS_UNITS_PRICING = 'welcome-bots-units-pricing',
  // Аддоны версии V0224
  /** Расширение лимита чат-ботов */
  CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING_V0224 = 'chat-bots-limit-extension-units-pricing-v0224',
  /** «Оптимизация поддержки» в зависимости от количества уников в тарифе */
  OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224 = 'optimization-support-units-pricing-v0224',
  /** База знаний PRO в зависимости от количества уников в тарифе */
  PRO_KNOWLEDGE_BASE_UNITS_PRICING_V0224 = 'pro-knowledge-base-units-pricing-v0224',
  /** «Welcome-бот» в зависимости от количества уников в тарифе */
  WELCOME_BOTS_UNITS_PRICING_V0224 = 'welcome-bots-units-pricing-v0224',
  /** Расширение лимита триггерных сообщений */
  TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING_V0224 = 'trigger-messages-limit-extension-units-pricing-v0224',
}

/**
 * Список постфиксов аддонов указывающие на версию аддона
 */
export const BILLING_ADDONS_VERSION_POSTFIX = ['-v0224'];

/** Список ID тарифных планов */
export const BILLING_PLAN_IDS = getBillingPlanIds();

/**
 * Ограничения специальных тарифов
 */
export const SPECIAL_PLANS_LIMITS = {
  [BILLING_PLAN_IDS.FREEMIUM]: {
    EMAILS: 500,
    SEATS: 2,
    USERS: 1000,
  },
  [BILLING_PLAN_IDS.LIFE_TIME_DEAL_PRO]: {
    EMAILS: 10000,
    SEATS: 4,
    USERS: 5000,
  },
  [BILLING_PLAN_IDS.LIFE_TIME_DEAL_BUSINESS]: {
    EMAILS: 15000,
    SEATS: 8,
    USERS: 10000,
  },
  [BILLING_PLAN_IDS.LIFE_TIME_DEAL_15K]: {
    EMAILS: 20000,
    SEATS: 8,
    USERS: 15000,
  },
  [BILLING_PLAN_IDS.LIFE_TIME_DEAL_20K]: {
    EMAILS: 25000,
    SEATS: 8,
    USERS: 20000,
  },
};

/**
 * Ограничения тарифов V3
 */
export const V3_PLANS_LIMITS = {
  [BILLING_PLAN_IDS.CONVERSATION]: {
    ACTIVE_TRIGGER_MESSAGES: 1,
    ACTIVE_LEAD_BOTS: 1,
    CONVERSATION_CHANNELS: 20,
    KNOWLEDGE_BASE_ARTICLES: 30,
    ADDONS: [],
  },
  [BILLING_PLAN_IDS.SUPPORT]: {
    ACTIVE_TRIGGER_MESSAGES: 3,
    ACTIVE_LEAD_BOTS: 2,
    CONVERSATION_CHANNELS: 50,
    KNOWLEDGE_BASE_ARTICLES: Infinity,
    ADDONS: [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING],
  },
  [BILLING_PLAN_IDS.MARKETING]: {
    ACTIVE_TRIGGER_MESSAGES: 30,
    ACTIVE_LEAD_BOTS: 10,
    CONVERSATION_CHANNELS: 50,
    KNOWLEDGE_BASE_ARTICLES: 30,
    ADDONS: [],
  },
};

export const V2023_LTD_PLAN_IDS = [
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_V2023_CONVERSATION_1,
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_V2023_CONVERSATION_2,
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_V2023_MARKETING_1,
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_V2023_MARKETING_2,
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_V2023_MARKETING_3,
];

/** Массив специальных тарифов */
export const SPECIAL_PLANS_ARRAY = [
  BILLING_PLAN_IDS.FREEMIUM,
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_PRO,
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_BUSINESS,
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_15K,
  BILLING_PLAN_IDS.LIFE_TIME_DEAL_20K,
  ...V2023_LTD_PLAN_IDS,
];

/** Причины открытия модалки ввода карты Stripe */
export enum STRIPE_ADD_CARD_MODAL_OPEN_REASON {
  ADD = 'add', // Добавление новой карты
  CONFIRM_SUBSCRIPTION = 'confirm_subscription', // При переходе на платный тариф, если отсутствует привязанная карта
  EDIT = 'edit', // Редактирование карты (по факту это добавление новой)
}

/** Статусы подписки (взяты из chargebee, вместе с описанием) */
export enum SUBSCRIPTION_STATUSES {
  ACTIVE = 'active', // активна
  CANCELLED = 'cancelled', // отменена
  FUTURE = 'future', // запланирована для активации в будущем (мы её не используем)
  IN_TRIAL = 'in_trial', // подписка в триале
  NON_RENEWING = 'non_renewing', // подписка автоматически не продляется после окончания текущего оплаченного периода
  PAUSED = 'paused', // приостановлена (мы её не используем)
}

/** Получение ID тарифов для CQ и Dashly для константы BILLING_PLAN_IDS */
function getBillingPlanIds(): BillingPlanIds {
  const billingPlanIds: Record<typeof environment['country'], BillingPlanIds> = {
    RU: {
      AUTOMATION: UNIQUE_BILLING_PLAN_ID.AUTOMATION_V0421,
      BUSINESS_CHAT: UNIQUE_BILLING_PLAN_ID.BUSINESS_CHAT_V0421,
      FREEMIUM: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      FREE_TRIAL: UNIQUE_BILLING_PLAN_ID.FREE_TRIAL,
      FREE_FOREVER: UNIQUE_BILLING_PLAN_ID.FREE_FOREVER, // NOTE: Используется только для APP ID = 100
      LIFE_TIME_DEAL: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_PRO: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_BUSINESS: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_15K: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_20K: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_V2023_CONVERSATION_1: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_V2023_CONVERSATION_2: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_V2023_MARKETING_1: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_V2023_MARKETING_2: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      LIFE_TIME_DEAL_V2023_MARKETING_3: '', // NOTE В CQ нет такого тарифа, но он должен присутствовать в тип TS
      CONVERSATION: UNIQUE_BILLING_PLAN_ID.CONVERSATION,
      SUPPORT: UNIQUE_BILLING_PLAN_ID.SUPPORT,
      MARKETING: UNIQUE_BILLING_PLAN_ID.MARKETING,
    },
    US: {
      AUTOMATION: UNIQUE_BILLING_PLAN_ID.AUTOMATION_11_20,
      BUSINESS_CHAT: UNIQUE_BILLING_PLAN_ID.BUSINESS_CHAT_11_20,
      FREEMIUM: UNIQUE_BILLING_PLAN_ID.FREEMIUM_03_21,
      FREE_TRIAL: UNIQUE_BILLING_PLAN_ID.FREE_TRIAL,
      FREE_FOREVER: UNIQUE_BILLING_PLAN_ID.FREE_FOREVER, // NOTE: Используется только для APP ID = 4
      LIFE_TIME_DEAL: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL,
      LIFE_TIME_DEAL_PRO: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_PRO,
      LIFE_TIME_DEAL_BUSINESS: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_BUSINESS,
      LIFE_TIME_DEAL_15K: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_15K,
      LIFE_TIME_DEAL_20K: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_20K,
      LIFE_TIME_DEAL_V2023_CONVERSATION_1: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_V2023_CONVERSATION_1,
      LIFE_TIME_DEAL_V2023_CONVERSATION_2: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_V2023_CONVERSATION_2,
      LIFE_TIME_DEAL_V2023_MARKETING_1: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_V2023_MARKETING_1,
      LIFE_TIME_DEAL_V2023_MARKETING_2: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_V2023_MARKETING_2,
      LIFE_TIME_DEAL_V2023_MARKETING_3: UNIQUE_BILLING_PLAN_ID.LIFE_TIME_DEAL_V2023_MARKETING_3,
      CONVERSATION: UNIQUE_BILLING_PLAN_ID.CONVERSATION,
      SUPPORT: UNIQUE_BILLING_PLAN_ID.SUPPORT,
      MARKETING: UNIQUE_BILLING_PLAN_ID.MARKETING,
    },
  };

  return billingPlanIds[environment.country];
}

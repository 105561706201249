import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { AUTO_EVENTS_GROUPS } from '../../../../app/http/track-master/track-master.constants';

/**
 * Общий контроллер настроек мастера сбора данных
 */
(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('cqTrackMasterModalController', cqTrackMasterModalController);

  function cqTrackMasterModalController(
    $translate,
    toastr,
    carrotquestHelper,
    paywallService,
    planFeatureAccessService,
    trackMasterModel,
  ) {
    var vm = this;

    /**
     * Вкладки
     *
     * @type {{SCRIPT: string, SETUP: string}}
     */
    var TRACK_MASTER_TABS = {
      SETUP: 'setup', // Настроить сбор данных
      SCRIPT: 'script', // Установить скрипт на сайт
    };

    vm.$onInit = init;

    function init() {
      vm.accessToEventsEventTypesCustom = planFeatureAccessService.getAccess(
        PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
        vm.resolve.currentApp,
      );

      vm.activeNav = TRACK_MASTER_TABS.SETUP;
      vm.AUTO_EVENTS_GROUPS = AUTO_EVENTS_GROUPS;
      vm.create = create;
      vm.paywallService = paywallService;
      vm.requestIsExecuting = false; // Флаг выполнения запроса
      vm.TRACK_MASTER_TABS = TRACK_MASTER_TABS;
    }

    /**
     * Создание автособытия
     *
     * @param {Boolean} isValid
     */
    function create(isValid) {
      if (isValid && !vm.requestIsExecuting) {
        vm.requestIsExecuting = true;

        firstValueFrom(trackMasterModel.create(vm.resolve.currentApp.id, vm.resolve.autoEvent))
          .then(createSuccess)
          .finally(createFinally);
      }

      function createSuccess() {
        trackAutoEventCreated();
        toastr.success(
          $translate.instant('trackMasterModal.toastr.created', { autoEventName: vm.resolve.autoEvent.name }),
        );
        vm.modalInstance.close();
      }

      function createFinally() {
        vm.requestIsExecuting = false;
      }
    }

    /**
     * Трек ксоздания события
     */
    function trackAutoEventCreated() {
      carrotquestHelper.track('Автосообщения  - триггер - создание события - создал событие', {
        'Тип события': vm.resolve.autoEvent.type,
      });
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { MessageEditorAnotherEventModule } from '@panel/app/partials/message-editor/trigger/another-events/another-event/message-editor-another-event.module';
import { MessageEditorAnotherEventsComponent } from '@panel/app/partials/message-editor/trigger/another-events/message-editor-another-events.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [MessageEditorAnotherEventsComponent],
  exports: [MessageEditorAnotherEventsComponent],
  providers: [CarrotquestHelperProvider],
  imports: [CommonModule, MessageEditorAnotherEventModule, TranslocoModule, SharedModule, ReactiveFormsModule],
})
export class MessageEditorAnotherEventsModule {}

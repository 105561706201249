import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import moment from 'moment';
import Moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { CONVERSATION_ASSISTANT_TYPES } from '@http/conversation/conversation.constants';
import { Conversation } from '@http/conversation/conversation.types';
import { ConversationPartModel } from '@http/conversation-part/conversation-part.model';
import { PaginationParamsRequest } from '@http/types';
import { UserModel } from '@http/user/user.model';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class ConversationModel {
  constructor(
    private readonly http: HttpClient,
    private readonly conversationPartModel: ConversationPartModel,
    private readonly userModel: UserModel,
  ) {}

  /**
   * Добавление тега
   *
   * @param conversationId ID диалога
   * @param tag Тег
   * @param randomId ID, необходимый для синхронизации реплик (подробнее расскажет Серёга)
   */
  addTag(conversationId: string, tag: string, randomId: number): Observable<unknown> {
    const body = {
      random_id: randomId,
      tag: tag,
    };

    return this.http.post(`/conversations/${conversationId}/tag`, body, {
      context: new HttpContext()
        .set(EXTENDED_RESPONSE, true)
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /**
   * Назначение диалога другому пользователю (администратору||оператору)
   *
   * @param conversationId ID диалога, который назначается пользователю
   * @param admin ID администратора||оператора, которому назначается диалог
   * @param randomId Случайный ID ConversationPart для контроля отправки
   * @param ignoreLoadingBar Отображать Loading Bar
   */
  assign(
    conversationId: string,
    admin: string,
    randomId: number,
    ignoreLoadingBar: boolean = true,
  ): Observable<unknown> {
    const body = {
      admin,
      random_id: randomId,
    };

    return this.http.post(`/conversations/${conversationId}/assign`, body, {
      context: new HttpContext()
        .set(EXTENDED_RESPONSE, true)
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }

  /**
   * Закрытие диалога
   *
   * @param conversationId ID диалога
   * @param randomId ID, необходимый для синхронизации реплик (подробнее расскажет Серёга)
   * @param ignoreLoadingBar Отображать Loading Bar
   */
  close(conversationId: string, randomId: number, ignoreLoadingBar: boolean = true): Observable<unknown> {
    const body = {
      random_id: randomId,
    };

    return this.http.post(`/conversations/${conversationId}/close`, body, {
      context: new HttpContext()
        .set(EXTENDED_RESPONSE, true)
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }

  /**
   * Выполнение слэш-команды
   *
   * @param conversationId ID диалога, в котором вызывается слэш-команда
   * @param params Параметры запроса
   * @param command Название слэш-команды
   * @param params Параметры слэш-команды
   * @param ignoreLoadingBar Отображать Loading Bar
   */
  command(
    conversationId: string,
    command: string,
    params?: string,
    ignoreLoadingBar: boolean = true,
  ): Observable<unknown> {
    const body = {
      command,
      params,
    };

    return this.http.post(`/conversations/${conversationId}/command`, body, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }

  /**
   * Откладывание диалога
   *
   * @param conversationId ID диалога
   * @param minutes На сколько минут отложить диалог
   * @param randomId ID, необходимый для синхронизации реплик (подробнее расскажет Серёга)
   */
  delay(conversationId: string, minutes: number, randomId: number): Observable<unknown> {
    const body = {
      delay_for: minutes,
      random_id: randomId,
      ignoreErrors: true,
    };

    return this.http.post(`/conversations/${conversationId}/delay`, body, {
      context: new HttpContext().set(EXTENDED_RESPONSE, true).set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }

  /**
   * Получение диалога
   *
   * @param conversationId ID диалога
   */
  get(conversationId: string): Observable<Conversation> {
    return this.http
      .get(`/conversations/${conversationId}`, {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((data: any) => {
          this.parse(data);

          return data;
        }),
      );
  }

  /**
   * Получение списка диалогов
   *
   * @param appId ID приложения
   * @param closed Возвращаемые диалоги должны быть закрыты
   * @param delayed Диалоги должны быть отложены
   * @param unanswered Диалоги должны быть без ответа оператора
   * @param includeNotAssigned Диалоги должны быть не назначены
   * @param channelId Диалоги должны быть в канале с указанным ID
   * @param teamMemberId Диалоги должны быть назначены на члена команды с указанным ID
   * @param tags В диалоге должны присутствовать теги NOTE чтобы не плодить параметры, tags может принимать null, что означает показывать диалоги без тегов
   * @param assistantType Тип бота
   * @param paginatorParams Параметры пагинации
   */
  getList(
    appId: string,
    closed: boolean,
    delayed: boolean,
    unanswered: boolean,
    includeNotAssigned: boolean,
    channelId: string,
    teamMemberId: string,
    tags: string[] | null,
    assistantType: CONVERSATION_ASSISTANT_TYPES,
    paginatorParams?: PaginationParamsRequest,
  ): Observable<{ conversations: Conversation[]; [key: string]: unknown }> {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    let params: any = {
      assigned: teamMemberId,
      assistant_type: assistantType,
      channel: channelId,
      closed,
      delayed,
      include_channel: true,
      include_no_tags: tags === null,
      include_not_assigned: includeNotAssigned,
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition?.join() || undefined,
      paginate_page_order: paginatePageOrder,
    };

    if (unanswered) {
      params.answered = false;
    }

    if (tags && tags.length) {
      params.tags = JSON.stringify(tags);
    } else {
      params.tags = null;
    }

    return this.http
      .get('/apps/' + appId + '/conversations', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
      })
      .pipe(
        map((response: any) => {
          const conversations = response.data;

          for (let i = 0; i < conversations.length; i++) {
            this.parse(conversations[i]);
          }

          return {
            conversations: conversations,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.next_before_position,
            },
          };
        }),
      );
  }

  /**
   * Получение диалогов пользователя по его ID
   *
   * @param userId ID пользователя
   * @param paginatorParams Параметры пагинации
   * @param onlyWithUserReplies=false Если true - получатся диалоги только с ответом пользователя, а не все под ряд (например, начатые с авто или ручного сообщения)
   * @param ignoreLoadingBar Игнорировать или нет loading bar
   */
  getListByUserId(
    userId: string,
    paginatorParams?: any,
    onlyWithUserReplies: boolean = false,
    ignoreLoadingBar: boolean = false,
  ): Observable<{ conversations: Conversation[]; [key: string]: unknown }> {
    const {
      paginateDirection = 'before',
      paginateCount = 3,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    const params = {
      include_channel: true,
      with_user_replies_only: onlyWithUserReplies,
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition.join() || undefined,
      paginate_page_order: paginatePageOrder,
    };

    return this.http
      .get('/users/' + userId + '/conversations', {
        params,
        context: new HttpContext()
          .set(EXTENDED_RESPONSE, true)
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((response: any) => {
          const conversations = response.data;

          for (let i = 0; i < conversations.length; i++) {
            this.parse(conversations[i]);
          }

          return {
            conversations: conversations,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.next_before_position,
            },
          };
        }),
      );
  }

  /**
   * Проверка отложенности диалога
   *
   * @param conversation Диалог
   */
  isDelayed(conversation: Conversation): boolean {
    if (conversation.closed) {
      return false;
    }

    if (!conversation.delayed_until) {
      return false;
    }

    if (conversation.delayed_until <= conversation.last_update) {
      return false;
    }

    return true;
  }

  /**
   * Проверка соответствия диалога фильтрам
   *
   * @param conversation Диалог
   * @param closed Флаг закрытости диалога. Если null - без разницы закрыт диалог или открыт
   * @param delayed Флаг отложенности диалога. Если null - без разницы отложен диалог или нет
   * @param unanswered Флаг отсутствия ответа на диалог от члена команды
   * @param includeNotAssigned Флаг учёта неназначенных диалогов
   * @param channelId ID канала
   * @param teamMemberId ID члена команды
   * @param tags Список тегов
   */
  isSatisfiesFilters(
    conversation: Conversation,
    closed: boolean | null,
    delayed: boolean | null,
    unanswered: boolean,
    includeNotAssigned: boolean,
    channelId: string,
    teamMemberId: string | null,
    tags: Array<string>,
  ): boolean {
    // проверка закрытости диалога
    if (closed !== null && closed !== conversation.closed) {
      return false;
    }

    // проверка отложенности диалога
    if (delayed !== null) {
      if (delayed !== this.isDelayed(conversation)) {
        return false;
      }
    }

    // проверка отвеченности
    if (unanswered && !conversation.admin_unread_count) {
      return false;
    }

    if (!unanswered && conversation.admin_unread_count) {
      return false;
    }

    // проверка назначения
    if (conversation.assignee) {
      // если за диалогом закреплён член команды, и при этом пришёл его ID - нужно проверить, что ID совпадают
      if (teamMemberId && conversation.assignee.id != teamMemberId) {
        return false;
      }
    } else {
      // если за диалогом никого не закреплено - нужно проверить, что неразобранные диалоги разрешены
      if (!includeNotAssigned) {
        return false;
      }
    }

    // проверка принадлежности каналу
    if (channelId === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL]) {
      // если пришёл ID псевдоканала "Без канала" - нужно проверить, что у диалога нет канала
      if (conversation.channel) {
        return false;
      }
    } else if (channelId && channelId !== PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]) {
      // если пришёл ID канала, при этом это не ID псевдоканала "Все каналы" - нужно проверить наличие канала и его ID у диалога
      if (!conversation.channel || conversation.channel.id != channelId) {
        return false;
      }
    }

    // проверка наличия хотя бы 1 тега из списка
    if (tags.length) {
      if (!conversation.tags) {
        return false;
      }

      let hasTag = false;

      cycle: for (let i = 0; i < tags.length; i++) {
        if (conversation.tags.includes(tags[i])) {
          hasTag = true;
          break cycle;
        }
      }

      if (!hasTag) {
        return false;
      }
    }

    return true;
  }

  /**
   * Парсинг диалога
   *
   * @param {Object} conversation Диалог
   */
  parse(conversation: Conversation): void {
    conversation.created = moment.isMoment(conversation.created)
      ? conversation.created
      : moment(conversation.created * 1000);

    if (conversation.delayed_until) {
      conversation.delayed_until = moment.isMoment(conversation.delayed_until)
        ? conversation.delayed_until
        : moment(conversation.delayed_until * 1000);
    }

    conversation.last_update = moment.isMoment(conversation.last_update)
      ? conversation.last_update
      : moment(conversation.last_update * 1000);

    if (conversation.last_user_reply_time) {
      conversation.last_user_reply_time = moment.isMoment(conversation.last_user_reply_time)
        ? conversation.last_user_reply_time
        : moment(conversation.last_user_reply_time * 1000);
    }

    if (conversation.part_last) {
      this.conversationPartModel.parse(conversation.part_last);
    }

    if (conversation.removed) {
      conversation.removed = moment.isMoment(conversation.removed)
        ? conversation.removed
        : moment(conversation.removed * 1000);
    }

    if (conversation.reply_last) {
      this.conversationPartModel.parse(conversation.reply_last);
    }

    if (conversation.user) {
      this.userModel.parseUser(conversation.user).subscribe();
    }

    if (conversation.found_part) {
      conversation.isSearching = true;
    }
  }

  /**
   * Удаление тега диалога
   *
   * @param conversationId ID диалога
   * @param tag Тег
   * @param randomId ID, необходимый для синхронизации реплик (подробнее расскажет Серёга)
   */
  removeTag(conversationId: string, tag: string, randomId: number): Observable<unknown> {
    const params = {
      random_id: randomId,
      tag: tag,
    };

    return this.http.delete(`/conversations/${conversationId}/tag`, {
      params,
      context: new HttpContext()
        .set(EXTENDED_RESPONSE, true)
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /**
   * Ответ в диалог
   *
   * @param conversationId ID диалога, в который нужно ответить
   * @param params Параметры запроса
   * @param params.attachment Прикрепленный фаил
   * @param params.attachmentFileName Имя  прикрепленного файла
   * @param params.autoAssign ID администратора. Перед ответом автоматически назначить диалог администратору с этим ID
   * @param params.autoAssignRandomId Случайный ID ConversationPart о назначении для контроля отправки
   * @param params.body Содержание сообщения в body
   * @param params.bodyJson Содержание сообщения в body_json
   * @param params.conversation ID диалога, в который уходит ответ
   * @param params.externalId ID для внешних сервисов
   * @param params.randomId Случайный ID ConversationPart для контроля отправки
   * @param params.type Тип сообщения
   * @param ignoreLoadingBar Отображать Loading Bar
   */
  reply(
    conversationId: string,
    params: {
      attachment?: File;
      attachmentFileName?: string;
      autoAssign?: string;
      autoAssignRandomId?: number;
      body: string;
      bodyJson?: any;
      conversation?: string;
      externalId?: number; // TODO
      randomId?: number;
      type?: string;
    },
    ignoreLoadingBar: boolean = true,
  ): Observable<unknown> {
    const serverFormatParams: any = {
      attachment: params.attachment,
      attachment_file_name: params.attachmentFileName,
      auto_assign: params.autoAssign,
      auto_assign_random_id: params.autoAssignRandomId,
      body: params.body,
      body_json: params.bodyJson,
      conversation: params.conversation,
      external_id: params.externalId,
      random_id: params.randomId,
      type: params.type,
    };

    return this.http.post(`/conversations/${conversationId}/reply`, serverFormatParams, {
      context: new HttpContext()
        .set(EXTENDED_RESPONSE, true)
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }

  /**
   * Запустить онбординг пользователя (создать самый первый диалог)
   */
  runOnboarding(): Observable<unknown> {
    const body = {
      ignoreErrors: true,
    };

    return this.http.post('/conversations/onboarding', body);
  }

  /**
   * Поиск по диалогам
   *
   * @param appId ID сайта
   * @param pattern Что искать
   * @param startDate Начало периода, в котором нужно искать
   * @param endDate Конец периода, в котором нужно искать
   * @param paginatorParams Параметры пагинации
   */
  search(
    appId: string,
    pattern: string,
    startDate: Moment.Moment,
    endDate: Moment.Moment,
    paginatorParams?: any,
  ): Observable<{ conversations: Conversation[]; [key: string]: unknown }> {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    const params = {
      pattern: pattern,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      ignoreLoadingBar: true,
      include_channel: true, //Миша сказал что для запроса ему нужен этот параметр.
      // include_part_last: true,
      include_reply_last: true, // запрашиваем reply_last, чтобы сделать reply_last обязательным полем в типе и не охуеть при написании кода
      include_important_part_last: true, // запрашиваем important_part_last, чтобы сделать important_part_last обязательным полем в типе и не охуеть при написании кода
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition.join() || undefined,
      paginate_page_order: paginatePageOrder,
    };

    return this.http
      .get(`/apps/${appId}/searchconversations`, {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
      })
      .pipe(
        map((response: any) => {
          response.data.forEach((conversation: Conversation) => {
            this.parse(conversation);
          });

          return {
            conversations: response.data,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.next_before_position,
            },
          };
        }),
      );
  }

  /**
   * Отправляет на почту реплики диалога
   *
   * @param conversationId ID открытого диалога
   * @param recipient Тип получателя
   */
  sendConversationToRecipientEmail(conversationId: string, recipient: 'user' | 'admin'): Observable<unknown> {
    const body = {
      recipient_type: recipient,
      ignoreErrors: true,
    };

    return this.http.post(`/conversations/${conversationId}/send_to_email`, body);
  }

  /**
   * Перемещение диалога в канал
   *
   * @param conversationId ID диалога, который перемещается в другой канал
   * @param channelId ID канала, в который перемещается диалог
   * @param randomId ID, необходимый для синхронизации реплик (подробнее расскажет Серёга)
   * @param restore FIXME Хз что это за флаг, описание к нему отсутствовало до апгрейда
   */
  setChannel(
    conversationId: string,
    channelId: string,
    randomId: number,
    restore: boolean = false,
  ): Observable<unknown> {
    const body = {
      channel: channelId,
      random_id: randomId,
      restore,
    };

    return this.http.put(`/conversations/${conversationId}/channel`, body, {
      context: new HttpContext().set(EXTENDED_RESPONSE, true).set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }

  /**
   * Установка "Кто-то печатает" для сообщения, которое набирает пользователь
   *
   * @param conversationId ID диалога, в который передается сообщение
   * @param messageBody Тело сообщения
   * @param ignoreLoadingBar Отображать Loading Bar
   */
  setTyping(conversationId: string, messageBody: string, ignoreLoadingBar: boolean = true): Observable<unknown> {
    const body = {
      body: messageBody,
    };

    return this.http.post(`/conversations/${conversationId}/settyping`, body, {
      context: new HttpContext()
        .set(EXTENDED_RESPONSE, true)
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
    });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { REPLIES_LENGTH } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings.types';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-replies-length-control',
  templateUrl: './replies-length-control.component.html',
  styleUrls: ['./replies-length-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepliesLengthControlComponent extends AbsCVAFormControlBasedComponent<REPLIES_LENGTH> {
  readonly control: FormControl<REPLIES_LENGTH> = new FormControl<REPLIES_LENGTH>(REPLIES_LENGTH.LONG, {
    nonNullable: true,
    validators: [Validators.required],
  });

  selectItems = Object.values(REPLIES_LENGTH);

  constructor() {
    super();
  }
}

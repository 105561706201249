import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { Requisites } from '@http/requisites/requisites';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

type EditRequisitesFormForGroup = {
  address: FormControl<string | null>;
  fio: FormControl<string | null>;
  inn: FormControl<string | null>;
  kpp: FormControl<string | null>;
  name: FormControl<string | null>;
  ogrn: FormControl<string | null>;
  postalAddress: FormControl<string | null>;
};

/** Компонент-модалка для редактирования реквизитов компании */
@Component({
  selector: 'cq-edit-requisites-modal',
  templateUrl: './edit-requisites-modal.component.html',
  styleUrls: ['./edit-requisites-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRequisitesModalComponent {
  /** Форма редактирования биллингового емейла */
  form: FormGroup<EditRequisitesFormForGroup>;

  /** Флаг выполнения запроса */
  isApiRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public readonly ngbActiveModal: NgbActiveModal, private readonly subscriptionStore$: SubscriptionStore) {
    const requisitesInstance = this.subscriptionStore$.billing$.getValue()!.requisites;

    this.form = new FormGroup<EditRequisitesFormForGroup>({
      address: new FormControl<string | null>(requisitesInstance.address, [Validators.required]),
      fio: new FormControl<string | null>(requisitesInstance.fio, [Validators.required]),
      inn: new FormControl<string | null>(requisitesInstance.inn, [Validators.required]),
      kpp: new FormControl<string | null>(requisitesInstance.kpp),
      name: new FormControl<string | null>(requisitesInstance.name, [Validators.required]),
      ogrn: new FormControl<string | null>(requisitesInstance.ogrn),
      postalAddress: new FormControl<string | null>(requisitesInstance.postalAddress),
    });
  }

  /** Обработчик отправки формы */
  onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isApiRequestPerformed.next(true);

    const requisitesInstance = this.subscriptionStore$.billing$.getValue()!.requisites;
    const requisites = this.form.value as Requisites;

    requisitesInstance
      .editRequisites(requisites)
      .then((response) => this.ngbActiveModal.close(response))
      .finally(() => this.isApiRequestPerformed.next(false));
  }
}

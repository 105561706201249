<ng-container *transloco="let t">
  <div class="margin-bottom-15 text-secondary">
    {{ t('chatBot.botActionEmailNotification.emailNotificationSelect.label') }}
  </div>
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="actionForm.controls.body"
  >
    <ng-select
      (change)="openSelectIntegrationModal($event)"
      [classList]="['ng-touched']"
      [clearable]="false"
      [cqShowError]="actionForm.controls.body"
      [formControl]="actionForm.controls.body"
      [items]="(stateService.emailNotificationIntegrationsExternal$ | async) ?? []"
      bindLabel="name"
      bindValue="id"
      placeholder="{{ t('chatBot.botActionEmailNotification.emailNotificationSelect.placeholder') }}"
    >
      <ng-template
        ng-label-tmp
        ng-option-tmp
        let-item="item"
      >
        <i
          class="cqi-sm cqi-triangle-exclamation-o text-warning margin-right-5"
          *ngIf="!item.active"
          [ngbTooltip]="t('chatBot.botActionEmailNotification.pausedIntegrationTooltip')"
          container="body"
        ></i>
        <span [ngClass]="{ 'text-muted': !item.active }">{{ item.name }}</span>
      </ng-template>
      <ng-container *ng-footer-tmp>
        <button
          class="btn btn-secondary full-width"
          (click)="openCreateNewIntegrationModal()"
        >
          {{ t('chatBot.botActionEmailNotification.emailNotificationSelect.actionText') }}
        </button>
      </ng-container>
    </ng-select>
    <cq-validation-messages [control]="actionForm.controls.body">
      <cq-validation-message errorName="required">{{
        t('chatBot.botActionEmailNotification.emailNotificationSelect.errors.required')
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

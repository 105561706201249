import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { APP_CATEGORY } from '@http/app/app.constants';
import { WizardService } from '@panel/app/partials/wizard/service/wizard.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-add-app-modal-step-first',
  templateUrl: './add-app-modal-step-first.component.html',
  styleUrls: ['./add-app-modal-step-first.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAppModalStepFirstComponent {
  @Input() appType!: string;
  @Output() appTypeChange = new EventEmitter<any>();

  get appTypeValue() {
    return this.appType;
  }

  set appTypeValue(value) {
    this.appType = value;
    this.appTypeForm.controls.appType.setValue(value);
    this.appTypeChange.emit(this.appType);
  }

  protected APP_CATEGORY = APP_CATEGORY;

  public appTypeForm = this.fb.group({
    appType: this.fb.control('', [Validators.required]),
  });

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    private fb: FormBuilder,
    protected readonly wizardService: WizardService,
  ) {}

  /**
   * Получение описания типа приложения
   *
   * @param appCategories Категория приложения
   * @return {String}
   */
  getAppTypeDescription(appCategories: any): string {
    let appTypeDescription: string = '';

    switch (appCategories) {
      case APP_CATEGORY.LANDING: {
        appTypeDescription = 'Лендинг';
        break;
      }
      case APP_CATEGORY.SHOP: {
        appTypeDescription = 'Интернет-магазин';
        break;
      }
      case APP_CATEGORY.SAAS: {
        appTypeDescription = 'SaaS';
        break;
      }
      case APP_CATEGORY.OTHER: {
        appTypeDescription = 'Что-то другое';
        break;
      }
    }

    return appTypeDescription;
  }

  /**
   * Подтверждение выбора типа приложения
   */
  trackClickSelectAppType(appType: any): void {
    this.carrotquestHelper.track('Создает новое приложение - Выбрал тип сайта', {
      Тип: this.getAppTypeDescription(appType),
    });
  }
}

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { AceModule } from 'ngx-ace-wrapper';

import { AiContentMakerComponent } from '@panel/app/pages/trigger-messages/email-part-editor/components/ai-content-maker/ai-content-maker.component';

@NgModule({
  exports: [AiContentMakerComponent],
  declarations: [AiContentMakerComponent],
  imports: [CommonModule, HttpClientModule, TranslocoModule, AceModule],
})
export class AiContentMakerModule {}

import { Pipe, PipeTransform } from '@angular/core';
import Moment from 'moment';

@Pipe({
  name: 'workTime',
  pure: true,
})
export class WorkTimePipe implements PipeTransform {
  transform(value: number): string {
    return Moment('00:00', 'H:mm').set('minute', value).format('LT');
  }
}

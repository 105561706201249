import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationService } from '@http/integration/services/integration.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

import { AmocrmDomainSelectionComponent } from './amocrm-domain-selection.component';

@NgModule({
  declarations: [AmocrmDomainSelectionComponent],
  exports: [AmocrmDomainSelectionComponent],
  imports: [CommonModule, NgbTooltipModule, ReactiveFormsModule, TranslocoModule],
  providers: [IntegrationService, L10nHelperService],
})
export class AmocrmDomainSelectionModule {}

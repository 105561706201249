<ng-container *transloco="let t; read: 'emailsComponent'">
  <ng-container
    *tuiLet="{
      billing: subscriptionStore.billing$ | async,
      plan: subscriptionStore.plan$ | async,
      quotas: subscriptionStore.quotas$ | async
    } as values"
  >
    <ng-container *ngIf="values.plan && values.quotas">
      <div class="d-flex justify-content-between align-content-center">
        <div class="font-weight-bold">
          <span>{{ t('title') }}</span>
          <i
            class="cqi-sm cqi-question-circle ml-5"
            [ngbTooltip]="
              t('tooltip', {
                price: values.plan.overspendingEmailPrice | toMoney | toFormattedMoney
              })
            "
            container="body"
            placement="top"
          ></i>
        </div>

        <!-- На активной подписке и триале показываем израсходованное количество писем вместе с квотой  -->
        <span *ngIf="values.billing && !values.billing.subscription.isCancelledTrial">
          {{ values.quotas.spentEmails | toFormattedNumber }} /
          {{ values.plan.availableEmails | toFormattedNumber }}
        </span>

        <!-- На отменённой подписке показываем израсходованное количество писем без квоты  -->
        <span *ngIf="values.billing && values.billing.subscription.isCancelledTrial">{{
          values.quotas.spentEmails | toFormattedNumber
        }}</span>
      </div>

      <ngb-progressbar
        *ngIf="values.billing && !values.billing.subscription.isCancelledTrial"
        class="mt-10 mb-5"
        [type]="values.quotas.getOverspendingEmails(values.plan.availableEmails) ? 'danger' : 'success'"
        [value]="values.quotas.spentEmails / values.plan.availableEmails | percent | toNumber"
      ></ngb-progressbar>

      <small
        *ngIf="
          values.billing &&
          !values.billing.subscription.isCancelledTrial &&
          values.quotas.getOverspendingEmails(values.plan.availableEmails)
        "
        class="d-block text-danger mt-10"
      >
        {{
          t('overspending', {
            price:
              getOverspendingTotalPrice(
                values.quotas.getOverspendingEmails(values.plan.availableEmails),
                values.plan.overspendingEmailPrice
              )
              | toMoney
              | toFormattedMoney
          })
        }}
      </small>

      <button
        class="btn btn-secondary btn-sm w-100 mt-15 mb-5"
        type="button"
        (click)="onClickDetailsButton()"
      >
        <i
          class="cqi-sm mr-5"
          [ngClass]="{ 'cqi-chevron-down': isCollapsed, 'cqi-chevron-up': !isCollapsed }"
        ></i>
        <span>{{ t('details.title') }}</span>
      </button>

      <div [ngbCollapse]="isCollapsed">
        <div class="mt-15 mb-15">
          <ng-container
            [ngTemplateOutlet]="emailDetails"
            [ngTemplateOutletContext]="{
              title: t('details.manualMessages'),
              value: values.quotas.spentEmailsForManualMessages
            }"
          >
          </ng-container>
        </div>

        <div class="mb-15">
          <ng-container
            [ngTemplateOutlet]="emailDetails"
            [ngTemplateOutletContext]="{
              title: t('details.triggeredMessages'),
              value: values.quotas.spentEmailsForTriggerMessages
            }"
          >
          </ng-container>
        </div>

        <div class="mb-15">
          <ng-container
            [ngTemplateOutlet]="emailDetails"
            [ngTemplateOutletContext]="{
              title: t('details.adminNotifications'),
              value: values.quotas.spentEmailsForAdminNotifications
            }"
          >
          </ng-container>
        </div>

        <div class="mb-15">
          <ng-container
            [ngTemplateOutlet]="emailDetails"
            [ngTemplateOutletContext]="{
              title: t('details.unreadConversationsNotifications'),
              value: values.quotas.spentEmailsForUnreadConversationsNotifications
            }"
          >
          </ng-container>
        </div>

        <div class="mb-15">
          <ng-container
            [ngTemplateOutlet]="emailDetails"
            [ngTemplateOutletContext]="{
              title: t('details.doubleOptInEmails'),
              value: values.quotas.spentEmailsForDoubleOptIn
            }"
          >
          </ng-container>
        </div>

        <div class="mb-15">
          <ng-container
            [ngTemplateOutlet]="emailDetails"
            [ngTemplateOutletContext]="{
              title: t('details.leadsNotifications'),
              value: values.quotas.spentEmailsForForNotificationsLeads
            }"
          >
          </ng-container>
        </div>
      </div>

      <ng-template
        #emailDetails
        let-title="title"
        let-value="value"
      >
        <div class="d-flex justify-content-between align-items-end mb-10">
          <span [innerHTML]="title"></span>
          <span>{{ getRatio(value, values.quotas.spentEmails) | percent: '1.2' }}</span>
        </div>

        <ngb-progressbar
          [value]="getRatio(value, values.quotas.spentEmails) | percent | toNumber"
          type="info"
        ></ngb-progressbar>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

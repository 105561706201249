(function () {
  'use strict';

  angular.module('myApp.integrations').controller('InstagramController', InstagramController);

  function InstagramController(
    $filter,
    $location,
    $q,
    $scope,
    $state,
    $translate,
    $uibModal,
    $window,
    caseStyleHelper,
    toastr,
    INSTAGRAM_INTEGRATION_STEPS,
    PROJECT_NAME,
    currentApp,
    integration,
    integrationModel,
    l10nHelper,
    utilsModel,
    validationHelper,
    wizardHelper,
  ) {
    let vm = this;

    /**
     * Все типы сообщений Instagram
     */
    const ALLOWED_MESSAGES_TYPES = {
      SIMPLE_MESSAGE: 'simple_message', // Сообщения в директ
      STORY_MENTION: 'story_mention', // Упоминания в историях
      STORY_REPLY: 'story_reply', // Ответы и реакции на истории
      SHARE_MESSAGE: 'share_message', // Пересылаемые сообщения
      REPLY_REACTION: 'reply_reaction', // Реакции на сообщения в директе
      STORY_MENTION_REACTION: 'story_mention_reaction', // Реакции на историю с упоминанием
      STORY_REPLY_REACTION: 'story_reply_reaction', // Реакции на сообщение истории с упоминанием
    };

    /**
     * Доступные для рендера типы сообщений Instagram
     */
    const ALLOWED_MESSAGES_TYPES_FOR_RENDER = {
      SIMPLE_MESSAGE: ALLOWED_MESSAGES_TYPES.SIMPLE_MESSAGE,
      STORY_MENTION: ALLOWED_MESSAGES_TYPES.STORY_MENTION,
      STORY_REPLY: ALLOWED_MESSAGES_TYPES.STORY_REPLY,
      MESSAGE_REACTIONS: 'message_reactions', // Содержит в себе 3 типа story_reply_reaction, story_mention_reaction, reply_reaction
      SHARE_MESSAGE: ALLOWED_MESSAGES_TYPES.SHARE_MESSAGE,
    };

    /**
     * Типы событий для реакций
     */
    const REACTION_TYPES = [
      ALLOWED_MESSAGES_TYPES.REPLY_REACTION,
      ALLOWED_MESSAGES_TYPES.STORY_MENTION_REACTION,
      ALLOWED_MESSAGES_TYPES.STORY_REPLY_REACTION,
    ];

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.authorize = authorize;
      vm.ALLOWED_MESSAGES_TYPES = ALLOWED_MESSAGES_TYPES;
      vm.ALLOWED_MESSAGES_TYPES_FOR_RENDER = ALLOWED_MESSAGES_TYPES_FOR_RENDER;
      vm.checkOAuth = checkOAuth;
      vm.createOrUpdateIntegration = createOrUpdateIntegration;
      vm.currentStep = angular.isDefined(integration.settings.lastCompletedStep)
        ? getStepByIndex(INSTAGRAM_INTEGRATION_STEPS, integration.settings.lastCompletedStep + 1)
        : getStepByIndex(INSTAGRAM_INTEGRATION_STEPS, 0); // текущий шаг визарда
      vm.INSTAGRAM_INTEGRATION_STEPS = INSTAGRAM_INTEGRATION_STEPS;
      vm.integration = integration;
      vm.isCheckedMessageType = isCheckedMessageType;
      vm.isDisabledMessageType = isDisabledMessageType;
      vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
        ? vm.currentStep.ORDER > integration.settings.lastCompletedStep
        : angular.isUndefined(integration.id); // раскрыта ли инструкция
      vm.oAuthErrors = {
        required: false,
        auth: false,
      };
      vm.onClickSaveButton = onClickSaveButton;
      vm.openConfirmUnsubscribedModal = openConfirmUnsubscribedModal;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.toggleManualVisibility = toggleManualVisibility;
      vm.toogleMessageType = toogleMessageType;
      vm.updateUserPages = updateUserPages;
      vm.validationHelper = validationHelper;
      vm.wizard = null; // инстанс визарда

      setRequiredMessageTypes();

      // пока Facebook заблокирован в РФ - будем принудительно показывать предупреждение
      if (l10nHelper.isRusCountry()) {
        integration.warnings = integration.warnings || [];
        integration.warnings.push('blocking');
      }

      $scope.$watch('vm.currentStep', function (newValue) {
        //обновляем состояние инструкции в зависимости от текущего шага
        vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
          ? newValue.ORDER > integration.settings.lastCompletedStep
          : angular.isUndefined(integration.id);
      });

      wizardHelper.getWizard().then(getWizardSuccess);

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }
    }

    /**
     * Авторизация в Instagram
     */
    function authorize() {
      const w = 350;
      const h = 250;

      const wLeft = $window.screenLeft ? $window.screenLeft : $window.screenX;
      const wTop = $window.screenTop ? $window.screenTop : $window.screenY;

      const left = wLeft + $window.innerWidth / 2 - w / 2;
      const top = wTop + $window.innerHeight / 2 - h / 2;

      // чтобы всплывающее окно корректно открылось - его вначале надо открыть без url, а затем, когда url получен с сервера - заменить location.href на этот url
      let authWindow = $window.open(
        '',
        '_blank',
        'toolbar=0, menubar=0,height=' + h + ',width=' + w + ',top=' + top + ',left=' + left,
      );

      integrationModel.instagram.getOAuthUrl(currentApp.id, integration.id).then(function (url) {
        authWindow.location.href = url;
      });

      $window.addEventListener('message', messageListener, false);

      /**
       * Удаление слушателя сообщений из дочернего окна
       */
      function destroyMessageListener() {
        $window.removeEventListener('message', messageListener);
      }

      function messageListener(message) {
        $scope.$apply(function () {
          let origin = $('<a>', { href: message.origin })[0];

          if (origin.hostname === $location.host()) {
            if (message.data.status === 'accepted') {
              vm.oAuthErrors.auth = false;
              vm.oAuthErrors.required = false;
            } else if (message.data.status === 'error') {
              vm.oAuthErrors.auth = true;
            }
          }

          authWindow.close();
          destroyMessageListener();
          vm.wizard.nextStep();
        });
      }
    }

    /**
     * Проверяет авторизацию в Facebook, и если человек авторизован - делает запрос на получение страниц
     *
     * @return {Promise}
     */
    function checkOAuth() {
      if (!(vm.oAuthErrors.required || vm.oAuthErrors.auth)) {
        if (integration.settings.accessToken) {
          // если accessToken присутствует - запрашиваем актуальные страницы и переходим на следующий шаг
          return getUserPages(integration).then(getUserPagesSuccess);
        } else {
          // если accessToken отсутствует - проверяем его наличие в самой актуальной интеграции, и если он там есть - запрашиваем страницы Facebook
          return integrationModel.get(integration.id, integration.type).then(getSuccess).then(getUserPagesSuccess);
        }
      } else {
        return $q.reject();
      }

      function getSuccess(refreshedIntegration) {
        angular.extend(integration, refreshedIntegration);

        if (!integration.settings.accessToken) {
          vm.oAuthErrors.required = true;
          return $q.reject();
        } else {
          return getUserPages(integration);
        }
      }

      function getUserPagesSuccess() {
        createOrUpdateIntegration();
      }
    }

    /**
     * Проверка наличия integration.settings.allowedMessageTypes и обязательного типа simple_message
     */
    function setRequiredMessageTypes() {
      if (!integration.settings.allowedMessageTypes) {
        const allMessagesType = Object.keys(ALLOWED_MESSAGES_TYPES).map((typeKey) => ALLOWED_MESSAGES_TYPES[typeKey]);
        integration.settings.allowedMessageTypes = allMessagesType;
      }

      if (!integration.settings.allowedMessageTypes.includes(vm.ALLOWED_MESSAGES_TYPES.SIMPLE_MESSAGE)) {
        integration.settings.allowedMessageTypes.push(vm.ALLOWED_MESSAGES_TYPES.SIMPLE_MESSAGE);
      }
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {form.FormController=} form Контроллер формы
     */
    function createOrUpdateIntegration(form) {
      if (form) {
        form.$commitViewValue();
        form.$setSubmitted();

        if (form.$invalid) {
          return $q.reject();
        }
      }

      integration.settings.lastCompletedStep =
        integration.settings.lastCompletedStep > vm.currentStep.ORDER
          ? integration.settings.lastCompletedStep
          : vm.currentStep.ORDER;

      if (integration.id) {
        return integrationModel.save(currentApp.id, integration);
      } else {
        return integrationModel.create(currentApp.id, integration).then(createSuccess);
      }

      function createSuccess(integration) {
        const params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        const transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
      }
    }

    /**
     * Получение шага из массива/объекта шагов steps по индексу index
     *
     * @param {Object|Array.<Object>} steps Массив/объект шагов, из которых осуществляется выборка
     * @param {Number} index Индекс шага (шаги упорядочены по ORDER, т.е. этот индекс и является ORDER)
     * @return {Object}
     */
    function getStepByIndex(steps, index) {
      let stepsArray = $filter('toArray')(steps);

      // если номер искомого шага больше, чем количество всех шагов - полагаем, что надо найти последний шаг
      index = index > stepsArray.length - 1 ? stepsArray.length - 1 : index;

      let step = $filter('filter')(stepsArray, { ORDER: index }, true)[0];
      if (step) {
        return step;
      } else {
        return $filter('filter')(stepsArray, { ORDER: 0 }, true)[0];
      }
    }

    /**
     * Получение страниц пользователя для интеграции с Facebook
     *
     * @param {Object} integration Интеграция
     * @return {Promise}
     */
    function getUserPages(integration) {
      return integrationModel.instagram.getUserPages(integration.id).then(getUserPagesSuccess);

      function getUserPagesSuccess(pages) {
        integration.settings.pages = pages;
      }
    }

    /**
     * Проверка - включен ли данный тип в массив активных типов сообщений
     *
     * @param {string} messageType - Тип сообщения
     */
    function isCheckedMessageType(messageType) {
      if (messageType === vm.ALLOWED_MESSAGES_TYPES_FOR_RENDER.MESSAGE_REACTIONS) {
        return integration.settings.allowedMessageTypes.some((type) => REACTION_TYPES.includes(type));
      }

      return integration.settings.allowedMessageTypes.includes(messageType);
    }

    /**
     * Проверка - нужно ли заблокировать данный тип сообщения
     *
     * @param {string} messageType - Тип сообщения
     * @returns {boolean}
     */
    function isDisabledMessageType(messageType) {
      return messageType === vm.ALLOWED_MESSAGES_TYPES_FOR_RENDER.SIMPLE_MESSAGE;
    }

    /**
     * Модальное окно при отключении страницы (информирует, что страница будет убрана из всех каналов)
     *
     * @param {Object} page Страница, которую отписываем
     */
    function openConfirmUnsubscribedModal(page) {
      if (!page.subscribed) {
        let modalInstance = $uibModal.open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          templateUrl: 'js/shared/modals/confirm/confirm.html',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant('integrations.instagram.step3.unsubscribeConfirmationWindow.heading'),
                body: $translate.instant('integrations.instagram.step3.unsubscribeConfirmationWindow.body'),
                confirmButtonText: $translate.instant(
                  'integrations.instagram.step3.unsubscribeConfirmationWindow.confirmButton',
                ),
              };
            },
          },
        });

        modalInstance.result.catch(openModalError);
      }

      function openModalError() {
        page.subscribed = true;
      }
    }

    /**
     * Обработка клика на кнопку "Сохранить интеграцию"
     */
    function onClickSaveButton() {
      vm.createOrUpdateIntegration();
      vm.updateUserPages();
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }

    /**
     * Переключение выбранного типа сообщения для отправки
     *
     * @param {string} messageType - Тип сообщения
     */
    function toogleMessageType(messageType) {
      if (messageType === vm.ALLOWED_MESSAGES_TYPES_FOR_RENDER.SIMPLE_MESSAGE) {
        return;
      }
      if (messageType === vm.ALLOWED_MESSAGES_TYPES_FOR_RENDER.MESSAGE_REACTIONS) {
        toogleMessageTypeReactions();
      } else if (integration.settings.allowedMessageTypes.includes(messageType)) {
        integration.settings.allowedMessageTypes = integration.settings.allowedMessageTypes.filter(
          (type) => type !== messageType,
        );
      } else {
        integration.settings.allowedMessageTypes.push(messageType);
      }
    }

    /**
     * Включение/Выключение типа сообщений для реакций
     */
    function toogleMessageTypeReactions() {
      if (integration.settings.allowedMessageTypes.some((el) => REACTION_TYPES.includes(el))) {
        integration.settings.allowedMessageTypes = integration.settings.allowedMessageTypes.filter(
          (type) => !REACTION_TYPES.includes(type),
        );
      } else {
        integration.settings.allowedMessageTypes.push(...REACTION_TYPES);
      }
    }

    /**
     * Обновление состояний пользовательских страниц
     */
    function updateUserPages() {
      integrationModel.instagram.subscribe(integration).then(subscribeSuccess);

      function subscribeSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }
  }
})();

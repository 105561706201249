import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Billing } from '@http/billing/billing';

/** Компонент для работы с картой оплаты */
@Component({
  selector: 'cq-card[billing][addCard][changeCard][removeCard][requestPerformed]',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  /** Биллинг */
  @Input()
  billing!: Billing;

  @Input()
  requestPerformed: boolean | null = false;

  /** Добавление карты */
  @Output()
  addCard: EventEmitter<void> = new EventEmitter<void>();

  /** Смена карты */
  @Output()
  changeCard: EventEmitter<void> = new EventEmitter<void>();

  /** Удаление карты */
  @Output()
  removeCard: EventEmitter<void> = new EventEmitter<void>();

  /** Обработчик добавления платёжной карты */
  onAddCard(): void {
    this.addCard.emit();
  }

  /** Обработчик смены платёжной карты */
  onChangeCard(): void {
    this.changeCard.emit();
  }

  /** Обработчик удаления платёжной карты */
  onRemoveCard(): void {
    this.removeCard.emit();
  }
}

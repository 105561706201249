<ng-container *transloco="let t; read: 'aiDataSourceTableComponent'">
  <ng-container *ngIf="state.dataSourceList$ | async as dataSourceList">
    <ng-container *ngIf="(isLoading | async) === false; else loading">
      <ng-container *ngIf="dataSourceList.length !== 0 || filtersActive()">
        <div class="card position-sticky">
          <div class="card-header">
            <ng-container *ngIf="(state.selectedDataSources$ | async)!.length === 0">
              <div class="d-flex">
                <cq-status-filter
                  [ngModel]="state.selectedStatus$ | async"
                  (ngModelChange)="state.selectedStatus$.next($event)"
                ></cq-status-filter>
                <cq-source-filter
                  class="ml-20"
                  [ngModel]="state.selectedSource$ | async"
                  (ngModelChange)="state.selectedSource$.next($event)"
                ></cq-source-filter>
                <!--<cq-search
                  class="ml-auto"
                  [ngModel]="state.searchPhrase$ | async"
                  (ngModelChange)="state.searchPhrase$.next($event)"
                ></cq-search>-->
              </div>
            </ng-container>
            <!--<ng-container *ngIf="(state.selectedDataSources$ | async)!.length > 0">
              <div class="d-flex align-items-center justify-center">
                <button
                  class="btn btn-borderless-primary"
                  type="button"
                  (click)="openBulkActionModal(AI_DATA_ACTIONS.MULTIPLE_ENABLE)"
                >
                  <i class="btn-icon-left cqi-sm cqi-lightning"></i>
                  {{ t('bulkActions.enable') }}
                </button>
                <button
                  class="btn btn-borderless-primary ml-5"
                  type="button"
                  (click)="openBulkActionModal(AI_DATA_ACTIONS.MULTIPLE_DISABLE)"
                >
                  <i class="btn-icon-left cqi-sm cqi-lightning-slash"></i>
                  {{ t('bulkActions.disable') }}
                </button>
              </div>
            </ng-container>-->
          </div>

          <table
            *ngIf="dataSourceList.length !== 0"
            class="table table-align-middle table-sticky-header position-sticky"
          >
            <thead>
              <tr>
                <!--<th class="shrink-by-content">
                  <div class="custom-control custom-checkbox">
                    <input
                      class="custom-control-input"
                      id="select-all-data"
                      type="checkbox"
                      [ngModel]="state.isSelectedAll$ | async"
                      (ngModelChange)="onSelectAll($event)"
                    />
                    <label
                      class="custom-control-label"
                      for="select-all-data"
                    ></label>
                  </div>
                </th>-->
                <th>{{ t('headings.name') }}</th>
                <th>{{ t('headings.status') }}</th>
                <th>{{ t('headings.source') }}</th>
                <th>{{ t('headings.syncDate') }}</th>
                <!--<th class="shrink-by-content"></th>-->
              </tr>
            </thead>

            <tbody>
              <tr
                cq-ai-data-source-table-item
                [dataSource]="dataSource"
                *ngFor="let dataSource of dataSourceList; trackBy: trackByDataSource"
              ></tr>
            </tbody>
          </table>

          <div
            *ngIf="canLoadMore"
            class="card-body text-center"
          >
            <button
              (click)="downloadNextSubject.next()"
              class="btn btn-outline-primary"
            >
              {{ 'general.showMore' | transloco }}
            </button>
          </div>

          <div
            *ngIf="dataSourceList.length === 0"
            class="card-body"
          >
            <cq-zero-data
              class="zero-data-container mx-auto"
              src="assets/img/default/not-found.png"
              [imgHeightPx]="87"
            >
              <h4>{{ t('notFound.heading') }}</h4>
              <div>{{ t('notFound.body') }}</div>
              <a
                href="#"
                class="btn btn-text-primary text-primary"
                (click)="resetFilters()"
                >{{ t('notFound.resetFilters') }}</a
              >
            </cq-zero-data>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="dataSourceList.length === 0 && !filtersActive()">
        <div class="card">
          <div class="card-body">
            <div class="zero-data-container mx-auto text-center">
              <h4>{{ t('zeroData.heading') }}</h4>
              <div>{{ t('zeroData.body') }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <cq-loader class="content-loader"></cq-loader>
  </ng-template>
</ng-container>

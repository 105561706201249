<ng-container *transloco="let t; read: 'notificationAppSettingComponent'">
  <div class="font-weight-bold mb-15">{{ t('notifyAbout') }}</div>
  <table class="table table-striped table-sm table-align-middle">
    <thead>
      <tr>
        <th>{{ t('device.type') }}</th>
        <th class="shrink-by-content">
          {{ t('device.desktop') }}
          <i
            class="cqi-sm cqi-question-circle"
            placement="bottom"
            [ngbTooltip]="t('deviceTooltip.desktop')"
          ></i>
        </th>
        <th class="shrink-by-content">
          {{ t('device.push') }}
          <i
            class="cqi-sm cqi-question-circle"
            placement="bottom"
            [ngbTooltip]="t('deviceTooltip.push')"
          ></i>
        </th>
        <th class="shrink-by-content">{{ t('device.email') }}</th>
      </tr>
    </thead>

    <tbody>
      <!--О статистике приложения	-->
      <tr>
        <td>{{ t('settingKeys.appStatistic') }}</td>
        <td></td>
        <td></td>
        <td>
          <div class="custom-control custom-checkbox flex justify-center">
            <input
              class="custom-control-input"
              [attr.id]="'appStatistic.email.' + appId"
              [(ngModel)]="settings.stats.email"
              (change)="notificationCheckboxChange(); trackChangeStatsNotifications()"
              type="checkbox"
            />
            <label
              class="custom-control-label"
              [attr.for]="'appStatistic.email.' + appId"
            ></label>
          </div>
        </td>
      </tr>
      <!--О назначенных на меня диалогах-->
      <tr>
        <td>{{ t('settingKeys.assignedToMe') }}</td>
        <td>
          <div class="custom-control custom-checkbox flex justify-center">
            <input
              class="custom-control-input"
              [attr.id]="'assignedToMe.desktop.' + appId"
              type="checkbox"
              [(ngModel)]="settings.assignedMe.desktop"
              (change)="notificationCheckboxChange()"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToMe.desktop.' + appId"
            ></label>
          </div>
        </td>
        <td>
          <div class="custom-control custom-checkbox flex justify-center">
            <input
              class="custom-control-input"
              [attr.id]="'assignedToMe.push.' + appId"
              type="checkbox"
              [(ngModel)]="settings.assignedMe.push"
              (change)="notificationCheckboxChange()"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToMe.push.' + appId"
            ></label>
          </div>
        </td>
        <td>
          <div class="custom-control custom-checkbox flex justify-center">
            <input
              class="custom-control-input"
              [attr.id]="'assignedToMe.email.' + appId"
              [(ngModel)]="settings.assignedMe.email"
              type="checkbox"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToMe.email.' + appId"
            ></label>
          </div>
        </td>
      </tr>
      <!--О не назначенные никому диалоги-->
      <tr>
        <td>{{ t('settingKeys.assignedToNoOne') }}</td>
        <td>
          <div
            class="custom-control custom-checkbox flex justify-center"
            [ngbTooltip]="disabledNotPersonalNotifications ? t('disabledInputTooltip') : ''"
            container="body"
          >
            <input
              class="custom-control-input"
              [attr.id]="'assignedToNoOne.desktop.' + appId"
              type="checkbox"
              [(ngModel)]="settings.assignedNoOne.desktop"
              (change)="notificationCheckboxChange()"
              [disabled]="disabledNotPersonalNotifications"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToNoOne.desktop.' + appId"
            ></label>
          </div>
        </td>
        <td>
          <div
            class="custom-control custom-checkbox flex justify-center"
            [ngbTooltip]="disabledNotPersonalNotifications ? t('disabledInputTooltip') : ''"
            container="body"
          >
            <input
              class="custom-control-input"
              [attr.id]="'assignedToNoOne.push.' + appId"
              type="checkbox"
              [(ngModel)]="settings.assignedNoOne.push"
              (change)="notificationCheckboxChange()"
              [disabled]="disabledNotPersonalNotifications"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToNoOne.push.' + appId"
            ></label>
          </div>
        </td>
        <td>
          <div
            class="custom-control custom-checkbox flex justify-center"
            [ngbTooltip]="disabledNotPersonalNotifications ? t('disabledInputTooltip') : ''"
            container="body"
          >
            <input
              class="custom-control-input"
              [attr.id]="'assignedToNoOne.email.' + appId"
              [(ngModel)]="settings.assignedNoOne.email"
              (change)="notificationCheckboxChange()"
              type="checkbox"
              [disabled]="disabledNotPersonalNotifications"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToNoOne.email.' + appId"
            ></label>
          </div>
        </td>
      </tr>
      <!--О назначенные на других диалоги-->
      <tr>
        <td>{{ t('settingKeys.assignedToAnotherAdmin') }}</td>
        <td>
          <div
            class="custom-control custom-checkbox flex justify-center"
            [ngbTooltip]="disabledNotPersonalNotifications ? t('disabledInputTooltip') : ''"
            container="body"
          >
            <input
              class="custom-control-input"
              [attr.id]="'assignedToAnotherAdmin.desktop.' + appId"
              type="checkbox"
              [(ngModel)]="settings.assignedOtherAdmin.desktop"
              (change)="notificationCheckboxChange()"
              [disabled]="disabledNotPersonalNotifications"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToAnotherAdmin.desktop.' + appId"
            ></label>
          </div>
        </td>
        <td>
          <div
            class="custom-control custom-checkbox flex justify-center"
            [ngbTooltip]="disabledNotPersonalNotifications ? t('disabledInputTooltip') : ''"
            container="body"
          >
            <input
              class="custom-control-input"
              [attr.id]="'assignedToAnotherAdmin.push.' + appId"
              type="checkbox"
              [(ngModel)]="settings.assignedOtherAdmin.push"
              (change)="notificationCheckboxChange()"
              [disabled]="disabledNotPersonalNotifications"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToAnotherAdmin.push.' + appId"
            ></label>
          </div>
        </td>
        <td>
          <div
            class="custom-control custom-checkbox flex justify-center"
            [ngbTooltip]="disabledNotPersonalNotifications ? t('disabledInputTooltip') : ''"
            container="body"
          >
            <input
              class="custom-control-input"
              [attr.id]="'assignedToAnotherAdmin.email.' + appId"
              [(ngModel)]="settings.assignedOtherAdmin.email"
              (change)="notificationCheckboxChange()"
              type="checkbox"
              [disabled]="disabledNotPersonalNotifications"
            />
            <label
              class="custom-control-label"
              [attr.for]="'assignedToAnotherAdmin.email.' + appId"
            ></label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { SUBSCRIPTION_STATUSES } from '../../../../app/services/billing-info/billing-info.constants';
import { PAID_PLAN_VERSION } from '../../../../app/services/billing-plan/billing-plan.constants';
import { firstValueFrom } from 'rxjs';
import { EMAIL_TYPES, MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.messageSenders').controller('CqMessageSendersTableController', CqMessageSendersTableController);

  function CqMessageSendersTableController(
    $filter,
    $rootScope,
    $scope,
    $translate,
    $uibModal,
    toastr,
    appModel,
    billingInfoModel,
    billingPlanService,
    carrotquestHelper,
    caseStyleHelper,
    featureModel,
    messageSenderModel,
    paywallService,
    planFeatureAccessService,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToNoBranding = planFeatureAccessService.getAccess(PLAN_FEATURE.NO_BRANDING, vm.currentApp);

      vm.billingInfoModel = billingInfoModel;
      vm.defaultMessageSender = null; // отправитель по умолчанию
      vm.EMAIL_TYPES = EMAIL_TYPES;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.isSubscriptionStatusIsActive = vm.billingInfo.subscription.status === SUBSCRIPTION_STATUSES.ACTIVE;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.noBrandingAddOnId = billingPlanService.getNoBrandingBillingAddOnId(vm.currentApp);
      vm.openCreateMessageSenderModal = openCreateMessageSenderModal;
      vm.openEditMessageSenderModal = openEditMessageSenderModal;
      vm.openNoBrandingPaywall = openNoBrandingPaywall;
      vm.openRemoveMessageSenderModal = openRemoveMessageSenderModal;
      vm.sendMessageAboutCustomDomain = sendMessageAboutCustomDomain;
      vm.trackClickAppendAppName = trackClickAppendAppName;
      vm.trackClickAppendAppNameInSignature = trackClickAppendAppNameInSignature;
      vm.trackClickCreateMessageSender = trackClickCreateMessageSender;
      vm.trackClickEditMessageSender = trackClickEditMessageSender;
      vm.trackClickRemoveMessageSender = trackClickRemoveMessageSender;
      vm.updateMessengerAppendAppName = updateMessengerAppendAppName;
      vm.updateMessengerAppendAppNameInSignature = updateMessengerAppendAppNameInSignature;

      $scope.$watch('vm.messageSenders', watchMessageSenders, true);

      /**
       * Вызывается при измении отправителей и устанавливает нового отправителя по умолчанию
       *
       * @param {Array.<Object>} newValue Массив отправителей
       */
      function watchMessageSenders(newValue) {
        vm.defaultMessageSender = $filter('filter')(newValue, { isDefault: true }, true)[0];
      }
    }

    /**
     * Открытие модалки создания нового отправителя
     */
    function openCreateMessageSenderModal() {
      var defaultMessageSender = messageSenderModel.getDefault();

      openMessageSenderEditorModal(defaultMessageSender, createMessageSender).then(createMessageSenderSuccess);

      function createMessageSender(newMessageSender) {
        return firstValueFrom(messageSenderModel.create(vm.currentApp.id, newMessageSender));
      }

      function createMessageSenderSuccess(messageSender) {
        trackMessageSenderCreated();
        vm.messageSenders.push(messageSender);
        unmarkAnotherDefaultMessageSender(messageSender);
        toastr.success($translate.instant('messageSenders.table.toasts.createMessageSenderSuccess'));
      }
    }

    /**
     * Открытие модалки редактирования отправителя
     *
     * @param {Object} messageSender Отправитель
     */
    function openEditMessageSenderModal(messageSender) {
      var messageSenderCopy = angular.copy(messageSender);

      openMessageSenderEditorModal(messageSenderCopy, saveMessageSender).then(saveMessageSenderSuccess);

      function saveMessageSender(editedMessageSender) {
        return firstValueFrom(messageSenderModel.save(editedMessageSender));
      }

      function saveMessageSenderSuccess(messageSender) {
        trackMessageSenderSaved();

        var oldMessageSender = $filter('filter')(vm.messageSenders, { id: messageSender.id }, true)[0];

        angular.extend(oldMessageSender, messageSender);
        unmarkAnotherDefaultMessageSender(messageSender);
        toastr.success(
          $translate.instant('messageSenders.table.toasts.saveMessageSenderSuccess', { name: messageSender.name }),
        );
      }
    }

    /**
     * Открытие модалки редактирования отправителя
     *
     * @param {Object} messageSender Отправитель
     * @param {Function} callback Колбэк, который вызовется перед закрытием модалки
     */
    function openMessageSenderEditorModal(messageSender, callback) {
      var messageSenderEditorModal = $uibModal.open({
        backdrop: 'static',
        controller: 'MessageSenderEditorController',
        controllerAs: 'vm',
        resolve: {
          billingInfo: angular.bind(null, angular.identity, vm.billingInfo),
          callback: angular.bind(null, angular.identity, callback),
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          messageSender: angular.bind(null, angular.identity, messageSender),
          messageSenders: angular.bind(null, angular.identity, vm.messageSenders),
        },
        templateUrl: 'js/components/message-senders/editor/message-sender-editor-modal.html',
        windowClass: 'message-sender-editor-modal',
      });

      return messageSenderEditorModal.result;
    }

    /**
     * Удаление отправителя
     *
     * @param {Object} messageSender Отправитель
     */
    function openRemoveMessageSenderModal(messageSender) {
      if (messageSender.isDefault) {
        var removeDefaultMessageSenderModal = $uibModal.open({
          controller: 'AssignNewDefaultMessageSenderModalController',
          controllerAs: 'vm',
          templateUrl: 'js/shared/modals/assign-new-default-message-sender/assign-new-default-message-sender.html',
          resolve: {
            body: function () {
              return (
                '<div class="margin-bottom-20">' +
                $translate.instant('messageSenders.table.removeDefaultMessageSenderModal.body') +
                '</div>'
              );
            },
            currentApp: angular.bind(null, angular.identity, vm.currentApp),
            messageSender: angular.bind(null, angular.identity, messageSender),
            messageSenders: angular.bind(null, angular.identity, vm.messageSenders),
          },
        });

        removeDefaultMessageSenderModal.result.then(removeMessageSender);
      } else {
        var removeMessageSenderModal = $uibModal.open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          templateUrl: 'js/shared/modals/confirm/confirm.html',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant('messageSenders.table.removeMessageSenderModal.heading'),
                body: $translate.instant('messageSenders.table.removeMessageSenderModal.body', {
                  name: messageSender.name,
                }),
                confirmButtonText: $translate.instant('general.remove'),
              };
            },
          },
        });

        removeMessageSenderModal.result.then(removeMessageSender);
      }

      function removeMessageSender() {
        firstValueFrom(messageSenderModel.remove(messageSender.id)).then(removeMessageSenderSuccess);

        function removeMessageSenderSuccess() {
          trackMessageSenderRemoved();
          vm.messageSenders.splice(vm.messageSenders.indexOf(messageSender), 1);
          toastr.success(
            $translate.instant('messageSenders.table.toasts.removeMessageSenderSuccess', { name: messageSender.name }),
          );
        }
      }
    }

    /**
     * Открытие пейвола No Branding
     */
    function openNoBrandingPaywall() {
      switch (vm.accessToNoBranding.denialReason.paidPlanVersion) {
        case PAID_PLAN_VERSION.V1:
        case PAID_PLAN_VERSION.V2:
          trackOpenNoBrandingPaywall();
      }

      paywallService.showPaywall(vm.currentApp, vm.accessToNoBranding.denialReason);
    }

    /**
     * Отправка сообщения о подключении кастомного домена
     */
    function sendMessageAboutCustomDomain() {
      var addOnName = $translate.instant('models.billingInfo.billingAddOns.' + vm.noBrandingAddOnId);

      carrotquestHelper.sendChatMessage(
        $translate.instant('messageSenders.table.messageForConnectingCustomDomain', { addOnName: addOnName }),
      );
    }

    /**
     * Трек клика на 'Подставлять название компании'
     */
    function trackClickAppendAppName() {
      carrotquestHelper.track('Настройка email отправителей - кликнул на "Подставлять название компании"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на 'Подставлять название компании в подпись письма'
     */
    function trackClickAppendAppNameInSignature() {
      carrotquestHelper.track(
        'Настройка email отправителей - кликнул на "Подставлять название компании в подпись письма"',
        {
          app: vm.currentApp.name,
        },
      );
    }

    /**
     * Трек клика на 'Добавить отправителя писем'
     */
    function trackClickCreateMessageSender() {
      carrotquestHelper.track('Настройка email отправителей - кликнул на "Добавить отправителя писем"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на редактирование отправителя
     */
    function trackClickEditMessageSender() {
      carrotquestHelper.track('Настройка email отправителей - кликнул на "Редактировать отправителя"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на удаление отправителя
     */
    function trackClickRemoveMessageSender() {
      carrotquestHelper.track('Настройка email отправителей - кликнул на "Удалить отправителя"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек создания нового отправителя
     */
    function trackMessageSenderCreated() {
      carrotquestHelper.track('Настройка email отправителей - добавил отправителя писем', { app: vm.currentApp.name });
    }

    /**
     * Трек удаления отправителя
     */
    function trackMessageSenderRemoved() {
      carrotquestHelper.track('Настройка email отправителей - удалил отправителя', { app: vm.currentApp.name });
    }

    /**
     * Трек сохранения данных об отправителе
     */
    function trackMessageSenderSaved() {
      carrotquestHelper.track('Настройка email отправителей - отредактировал отправителя', { app: vm.currentApp.name });
    }

    /**
     * Трек открытия пейвола No Branding
     */
    function trackOpenNoBrandingPaywall() {
      carrotquestHelper.track('Показ пейвола Без брендинга', { 'Раздел настроек': 'Отправка Email' });
    }

    /**
     * Поиск старого отправителя по умолчанию и снятие с него этого статуса
     *
     * @param {Object} newDefaultMessageSender Новый отправитель по умолчанию
     */
    function unmarkAnotherDefaultMessageSender(newDefaultMessageSender) {
      // если этот отправитель является отправителем по умолчанию - пытаемся найти других отправителей по умолчанию (а такой может быть максимум ещё один, который перестал быть отправителем по умолчанию после редактирования текущего отправителя)
      if (newDefaultMessageSender.isDefault) {
        var defaultMessageSenders = $filter('filter')(vm.messageSenders, { isDefault: true }, true);
        var anotherDefaultMessageSender = $filter('filter')(
          defaultMessageSenders,
          { id: '!' + newDefaultMessageSender.id },
          true,
        )[0];

        // если нашли такого отправителя - убираем с него флаг отправителя по умолчанию, бэкэнд делает это автоматически при назанчении другого отправителя по умолчанию
        if (anotherDefaultMessageSender) {
          anotherDefaultMessageSender.isDefault = false;
        }
      }
    }

    /**
     * Обновление настройки подстановки названия компании в письмах
     */
    function updateMessengerAppendAppName() {
      var params = {
        messenger_append_app_name: vm.currentApp.settings.messenger_append_app_name,
      };

      firstValueFrom(appModel.saveSettings(vm.currentApp.id, params)).then(updateSuccess);

      function updateSuccess() {
        toastr.success($translate.instant('messageSenders.table.toasts.updateSuccess'));
      }
    }

    /** Обновление настройки подстановки названия компании в подпись письма */
    function updateMessengerAppendAppNameInSignature() {
      const params = {
        messenger_append_app_name_in_signature: vm.currentApp.settings.messenger_append_app_name_in_signature,
      };

      firstValueFrom(appModel.saveSettings(vm.currentApp.id, params)).then(updateSuccess);

      function updateSuccess() {
        toastr.success($translate.instant('messageSenders.table.toasts.updateSuccess'));
      }
    }
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { InputNumberRangeForm } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-number-range/input-number-range.type';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

@Component({
  selector: 'cq-input-number-range',
  templateUrl: './input-number-range.component.html',
  styleUrls: ['./input-number-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberRangeComponent extends AbsCVAFormGroupBasedComponent<InputNumberRangeForm> {
  MIN_VALUE: number = 0;

  readonly control: FormGroup<InputNumberRangeForm> = this.fb.group<InputNumberRangeForm>({
    value1: new FormControl(null, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(this.MIN_VALUE)]),
    value2: new FormControl(null, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(this.MIN_VALUE)]),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}

(function () {
  'use strict';

  angular
    .module('myApp.completionRegistration')
    .controller(
      'cqCompletionRegistrationFormControlAppUrlController',
      cqCompletionRegistrationFormControlAppUrlController,
    );

  function cqCompletionRegistrationFormControlAppUrlController(validationHelper) {
    let vm = this;
    vm.$onInit = init;

    /**
     * Максимальная длинна адреса сайта
     *
     * @type {number}
     */
    const APP_URL_MAX_LENGTH = 200;

    /**
     * Регулярное выражение для проверки адреса сайта на корректность
     *
     * @type {string}
     */
    const APP_URL_REG_EXP = '.+\\.+.+';

    function init() {
      vm.APP_URL_MAX_LENGTH = APP_URL_MAX_LENGTH;
      vm.APP_URL_REG_EXP = APP_URL_REG_EXP;
      vm.getClassForControl = getClassForControl;
      vm.isRequiredMode = vm.isRequiredMode ?? null;
    }

    /**
     * Получение класса для контрола
     *
     * @return {object}
     */
    function getClassForControl() {
      return {
        'has-error': isValidAppUrl(),
      };
    }

    /**
     * Является ли адрес сайта валидным
     *
     * @return {boolean}
     */
    function isValidAppUrl() {
      return validationHelper.getValidationResult(vm.completionRegistrationForm, 'appUrl');
    }
  }
})();

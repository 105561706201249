(function () {
  'use strict';

  angular.module('myApp.integrations').controller('MyTargetController', MyTargetController);

  function MyTargetController(
    $scope,
    $filter,
    $q,
    $location,
    $state,
    $stateParams,
    $translate,
    $window,
    toastr,
    integrationModel,
    propertyModel,
    validationHelper,
    wizardHelper,
    MYTARGET_INTEGRATION_STEPS,
    MYTARGET_STANDARD_FIELDS_TYPES,
    MYTARGET_STANDARD_PROPS_DEFAULT,
    PROJECT_NAME,
    currentApp,
    integration,
  ) {
    let vm = this;

    vm.$translate = $translate;
    vm.addForm = addForm;
    vm.addFormsQuestion = addFormsQuestion;
    vm.authorize = authorize;
    vm.checkOAuth = checkOAuth;
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.currentStep = angular.isDefined(integration.settings.lastCompletedStep)
      ? getStepByIndex(MYTARGET_INTEGRATION_STEPS, integration.settings.lastCompletedStep + 1)
      : getStepByIndex(MYTARGET_INTEGRATION_STEPS, 0); // текущий шаг визарда
    vm.forms = [];
    vm.integration = integration;
    vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
      ? vm.currentStep.ORDER > integration.settings.lastCompletedStep
      : angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.MYTARGET_INTEGRATION_STEPS = MYTARGET_INTEGRATION_STEPS;
    vm.MYTARGET_STANDARD_FIELDS_TYPES = MYTARGET_STANDARD_FIELDS_TYPES;
    vm.MYTARGET_STANDARD_PROPS_DEFAULT = MYTARGET_STANDARD_PROPS_DEFAULT;
    vm.myTargetForms = [];
    vm.oAuthErrors = {
      required: false,
      auth: false,
    };
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.props = [];
    vm.removeItem = removeItem;
    vm.standardQuestions = {};
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.validationHelper = validationHelper;
    vm.wizard = null; // инстанс визарда

    init();

    function init() {
      firstValueFrom(propertyModel.getList(currentApp.id)).then(getPropertyListSuccess);

      initForms();

      $scope.$watch('vm.currentStep', function (newValue) {
        //обновляем состояние инструкции в зависимости от текущего шага
        vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
          ? newValue.ORDER > integration.settings.lastCompletedStep
          : angular.isUndefined(integration.id);
      });

      wizardHelper.getWizard().then(getWizardSuccess);

      function getPropertyListSuccess(properties) {
        vm.props = [].concat(properties.userProps);
      }

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }
    }

    /**
     * Добавление новой формы в массив форм
     *
     * @param {Array} array Массив, в который добавляется объект
     */
    function addForm(array) {
      array.push({
        formLink: '',
        standardQuestions: angular.copy(vm.standardQuestions),
        questions: [],
      });
    }

    /**
     * Добавление нового вопроса в список
     * @param array
     */
    function addFormsQuestion(array) {
      if (typeof array === 'undefined') {
        array = [];
      }
      array.push({});
    }

    /**
     * Авторизация в Facebook
     */
    function authorize() {
      let w = 350;
      let h = 250;

      let wLeft = $window.screenLeft ? $window.screenLeft : $window.screenX;
      let wTop = $window.screenTop ? $window.screenTop : $window.screenY;

      let left = wLeft + $window.innerWidth / 2 - w / 2;
      let top = wTop + $window.innerHeight / 2 - h / 2;

      // чтобы всплывающее окно корректно открылось - его вначале надо открыть без url, а затем, когда url получен с сервера - заменить location.href на этот url
      let authWindow = $window.open(
        '',
        '_blank',
        'toolbar=0, menubar=0,height=' + h + ',width=' + w + ',top=' + top + ',left=' + left,
      );

      integrationModel.myTarget.getOAuthUrl(currentApp.id, integration.id).then(function (url) {
        authWindow.location.href = url;
      });

      $window.addEventListener('message', messageListener, false);

      /**
       * Удаление слушателя сообщений из дочернего окна
       */
      function destroyMessageListener() {
        $window.removeEventListener('message', messageListener);
      }

      function messageListener(message) {
        $scope.$apply(function () {
          let origin = $('<a>', { href: message.origin })[0];
          console.log('message', message);
          if (origin.hostname === $location.host()) {
            if (message.data.status === 'accepted') {
              vm.oAuthErrors.auth = false;
              vm.oAuthErrors.required = false;
            } else if (message.data.status === 'error') {
              vm.oAuthErrors.auth = true;
            }
          }

          vm.wizard.nextStep();

          authWindow.close();
          destroyMessageListener();
        });
      }
    }

    /**
     * Проверяет авторизацию в myTarget, и если человек авторизован - обновляет интеграцию
     *
     * @return {Promise}
     */
    function checkOAuth() {
      if (!(vm.oAuthErrors.required || vm.oAuthErrors.auth)) {
        if (integration.settings.accessToken) {
          // если accessToken присутствует успешно завершаем проверку
          return $q.resolve();
        } else {
          // если accessToken отсутствует - проверяем его наличие в самой актуальной интеграции, и если он там есть обновляем локальную копию интеграции
          return integrationModel.get(integration.id, integration.type).then(getSuccess);
        }
      } else {
        return $q.reject();
      }

      function getSuccess(refreshedIntegration) {
        angular.extend(integration, refreshedIntegration);

        if (!integration.settings.accessToken) {
          vm.oAuthErrors.required = true;
          return $q.reject();
        } else {
          return $q.resolve();
        }
      }
    }

    function checkQuestions(item, form) {
      for (let i = 0; i < item.standardQuestions.length; i++) {
        if (angular.isDefined(form.standardQuestions[item.standardQuestions[i].id])) {
          item.standardQuestions[i].checked = true;
          item.standardQuestions[i].cqName = form.standardQuestions[item.standardQuestions[i].id];
        } else {
          item.standardQuestions[i].checked = false;
        }
      }
    }

    /**
     * Конвертация массива объектов (ключи и значения этих объектов должны быть примитивными типами) в объект
     *
     * @example
     * // вернёт {'value1': 'value2', 'value3': 'value4'}
     * convertArrayToMapping([{'key1': 'value1', 'key2': 'value2'}, {'key1': 'value3', 'key2': 'value4'}], 'key1', 'key2');
     *
     * @param {Array.<Object>} array Массив объектов для конвертации
     * @param {String} keyName Название ключа объекта массива, значение которого будет являться ключом в результирующем объекте
     * @param {String} valueName Название ключа объекта массива, значение которого будет являться значением в результирующем объекте
     * @return {Object}
     */
    function convertArrayToMapping(array, keyName, valueName) {
      let mapping = {};

      for (let i = 0; i < array.length; i++) {
        let item = array[i];

        mapping[item[keyName]] = item[valueName];
      }

      return mapping;
    }

    /**
     * Конвертация объекта (ключи и значения объекта должны быть примитивными типами) в массив объектов
     *
     * @example
     * // вернёт [{'newKey1': 'key1', 'newKey2': 'value1'}, {'newKey1': 'key2', 'newKey2': 'value2'}]
     * convertMappingToArray({'key1': 'value1', 'key2': 'value2'}, 'newKey1', 'newKey2');
     *
     * @param {Object} mapping Объект для конвертации
     * @param {String} keyName Название ключа элемента массива, значение которого будет являться ключом объекта
     * @param {String} valueName Название ключа элемента массива, значение которого будет являться значением объекта
     * @return {Array.<Object>}
     */
    function convertMappingToArray(mapping, keyName, valueName) {
      let array = [];

      for (let key in mapping) {
        if (mapping.hasOwnProperty(key)) {
          let arrayItem = {};

          arrayItem[keyName] = key;
          arrayItem[valueName] = mapping[key];

          array.push(arrayItem);
        }
      }

      return array;
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {form.FormController=} form Контроллер формы
     */
    function createOrUpdateIntegration(form) {
      if (form) {
        form.$commitViewValue();
        form.$setSubmitted();

        if (form.$invalid) {
          return $q.reject();
        } else {
          parseMyTargetIntegrationToServerFormat(integration);
        }
      }

      integration.settings.lastCompletedStep =
        integration.settings.lastCompletedStep > vm.currentStep.ORDER
          ? integration.settings.lastCompletedStep
          : vm.currentStep.ORDER;

      if (integration.id) {
        return integrationModel.save(currentApp.id, integration).then(saveSuccess);
      } else {
        return integrationModel.create(currentApp.id, integration).then(createSuccess);
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }

      function createSuccess(integration) {
        let params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        let transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка "Назад" вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }
    }

    /**
     * Получение шага из массива/объекта шагов steps по индексу index
     *
     * @param {Object|Array.<Object>} steps Массив/объект шагов, из которых осуществляется выборка
     * @param {Number} index Индекс шага (шаги упорядочены по ORDER, т.е. этот индекс и является ORDER)
     * @return {Object}
     */
    function getStepByIndex(steps, index) {
      let stepsArray = $filter('toArray')(steps);

      // если номер искомого шага больше, чем количество всех шагов - полагаем, что надо найти последний шаг
      index = index > stepsArray.length - 1 ? stepsArray.length - 1 : index;

      let step = $filter('filter')(stepsArray, { ORDER: index }, true)[0];
      if (step) {
        return step;
      } else {
        return $filter('filter')(stepsArray, { ORDER: 0 }, true)[0];
      }
    }

    /**
     * Инициализация списка форм, отображаемого на третьем шаге
     */
    function initForms() {
      if (typeof vm.forms === 'undefined') {
        vm.forms = [];
      }

      if (integration.id) {
        integrationModel.myTarget.getQuestions(integration.id).then(getQuestionsSuccess); // Справочник стандартных вопросов
      }

      function getQuestionsSuccess(questions) {
        vm.standardQuestions = questions;

        if (integration.settings.forms && !angular.equals(integration.settings.forms, [])) {
          // При наличии уже настроенных форм, вносим их в рабочий список форм
          for (let i = 0; i < integration.settings.forms.length; i++) {
            let form = integration.settings.forms[i];
            addForm(vm.forms);
            vm.forms[vm.forms.length - 1].formLink = form.formLink;
            vm.forms[vm.forms.length - 1].formId = form.formId;
            vm.forms[vm.forms.length - 1].questions = convertMappingToArray(form.questions, 'id', 'cqName');
            checkQuestions(vm.forms[vm.forms.length - 1], form);
          }
        } else {
          // При отсутствии уже настроенных форм инициализируем список пустой формой
          addForm(vm.forms);
        }
      }
    }

    /**
     * Парсинг интеграции в формат сервера
     *
     * @param {Object} integration Интеграция
     */
    function parseMyTargetIntegrationToServerFormat(integration) {
      integration.settings.forms = [];
      for (let i = 0; i < vm.forms.length; i++) {
        if (vm.forms[i] && vm.forms[i].formLink) {
          let form = {
            formLink: vm.forms[i].formLink,
            formId: vm.forms[i].formId || null,
            questions: convertArrayToMapping(vm.forms[i].questions, 'id', 'cqName'),
            standardQuestions: convertArrayToMapping(
              $filter('filter')(vm.forms[i].standardQuestions, { checked: true }),
              'id',
              'cqName',
            ),
          };
          integration.settings.forms.push(form);
        }
      }
    }

    /**
     * Удаление объекта из массива
     *
     * @param array
     * @param item
     */
    function removeItem(array, item) {
      let index = array.indexOf(item);

      if (~index) {
        array.splice(index, 1);
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

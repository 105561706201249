import { ConditionsSendingModel } from '../../../../../../app/services/conditions-sending/conditions-sending.model';
import {
  DELETE_MESSAGE_MAX_INTERVAL,
  DELETE_MESSAGE_MIN_INTERVAL,
  REPEAT_MIN_DELAY,
} from '../../../../../../app/services/conditions-sending/conditions-sending.constants';
import { MESSAGE_DELETING_TYPES, MESSAGE_PAGE_TYPES } from '../../../../../../app/http/message/message.constants';

(function () {
  'use strict';

  angular.module('myApp.autoMessages').controller('CqDisplaySettingsController', CqDisplaySettingsController);

  function CqDisplaySettingsController(
    $filter,
    $interval,
    $scope,
    $translate,
    FILTER_SEGMENTS_PAGE_TYPE,
    TIME_UNIT_MEASURES,
    carrotquestHelper,
    featureModel,
    messageModel,
    messagePartModel,
    timeUnitService,
    validationHelper,
  ) {
    var vm = this;

    var interval; // $interval для обновления времени

    var DELETE_MESSAGE_MAX_HOURS = 23; // Максимальное количество часов в указании конкретной даты
    var DELETE_MESSAGE_MAX_MINUTES = 59; // Максимальное количество минут в указании конкретной даты
    var DELETE_MESSAGE_MIN_HOURS = 0; // Минимальное количество часов в указании конкретной даты
    var DELETE_MESSAGE_MIN_MINUTES = 0; // Минимальное количество минут в указании конкретной даты

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.hasFilters = vm.currentMessage.isMessageHaveFilters;
      vm.changeDeleteSwitch = changeDeleteSwitch;
      vm.collapseChange = collapseChange;
      vm.deleteMessageForm = null; // Форма протухания автосообщений
      vm.deleteSwitcher = !!vm.deleteMessage?.deleteType;
      vm.DELETE_MESSAGE_MAX_HOURS = DELETE_MESSAGE_MAX_HOURS;
      vm.DELETE_MESSAGE_MAX_INTERVAL = DELETE_MESSAGE_MAX_INTERVAL;
      vm.DELETE_MESSAGE_MAX_MINUTES = DELETE_MESSAGE_MAX_MINUTES;
      vm.DELETE_MESSAGE_MIN_HOURS = DELETE_MESSAGE_MIN_HOURS;
      vm.DELETE_MESSAGE_MIN_INTERVAL = DELETE_MESSAGE_MIN_INTERVAL;
      vm.DELETE_MESSAGE_MIN_MINUTES = DELETE_MESSAGE_MIN_MINUTES;
      vm.REPEAT_MIN_DELAY = vm.minRepeatDelay ?? REPEAT_MIN_DELAY;
      vm.emptySettings = emptySettings;
      vm.FILTER_SEGMENTS_PAGE_TYPE = FILTER_SEGMENTS_PAGE_TYPE;
      vm.listTimeH = [];
      vm.listTimeM = [];
      vm.isMessageFilterableByUrl = isMessageFilterableByUrl;
      vm.nowTime = ConditionsSendingModel.getCurrentTimeForTriggerStep(vm.currentApp.settings.timezone_offset); // Текущее время NOTE должно быть до vm.maxTime
      vm.maxTime = ConditionsSendingModel.getMaxTimeForTriggerStep(vm.nowTime); // Максимальное время, которое можно выбрать для удаления сообщения
      vm.MESSAGE_DELETING_TYPES = MESSAGE_DELETING_TYPES; // типы удаления автосообщения
      vm.messagePageType = vm.messagePageType || MESSAGE_PAGE_TYPES.AUTO_MESSAGES; // Тип страницы, на которой размещен компонент, необходимо для корректного трекинга событий и переводов
      vm.messagePartTypeName = messageModel.getMessagePartTypeName(vm.currentMessage.parts);

      vm.onSendReplyChange = onSendReplyChange;
      vm.onSendTimeChange = onSendTimeChange;

      vm.sendTime = {
        sendTimeValueFromH: vm.currentMessage.sendTimeValueFromH ?? 0,
        sendTimeValueFromM: vm.currentMessage.sendTimeValueFromM ?? 0,
        sendTimeValueToH: vm.currentMessage.sendTimeValueToH ?? 0,
        sendTimeValueToM: vm.currentMessage.sendTimeValueToM ?? 0,
      };

      vm.TIME_UNIT_MEASURES = TIME_UNIT_MEASURES;
      vm.timeUnitService = timeUnitService;
      vm.trackClickExpandSettingsDisplay = trackClickExpandSettingsDisplay;

      vm.validationHelper = validationHelper;

      for (let i = 0; i < 24; i++) {
        vm.listTimeH.push(pad(i, 2));
      }
      for (let i = 0; i < 60; i++) {
        vm.listTimeM.push(pad(i, 2));
      }

      if (vm.deleteMessage) {
        $scope.$watchCollection(
          '[vm.deleteMessage.time.date, vm.deleteMessage.time.hours, vm.deleteMessage.time.minutes]',
          setDeleteMessageTime,
        );
      }

      interval = $interval(updateNowTime, 1000);

      /**
       * Обновляет текущее время
       */
      function updateNowTime() {
        vm.nowTime = ConditionsSendingModel.getCurrentTimeForTriggerStep(vm.currentApp.settings.timezone_offset); // Текущее время
        vm.maxTime = ConditionsSendingModel.getMaxTimeForTriggerStep(vm.nowTime); // Максимальное время, которое можно выбрать для удаления сообщения
      }

      /**
       * Установка времени удаления сообщения
       *
       * @param newValue
       */
      function setDeleteMessageTime(newValue) {
        var date = newValue[0];
        var hours = newValue[1];
        var minutes = newValue[2];

        vm.deleteMessage.time.time = mergeTimeValues(date, hours, minutes);

        /**
         * Соединение даты, часов и минут в одну дату типа moment
         *
         * @param {moment} date Дата
         * @param {Number} hours Часы
         * @param {Number} minutes Минуты
         * @returns {moment}
         */
        function mergeTimeValues(date, hours, minutes) {
          var mergedTime;

          // если дата undefined, то считается, что она не прошла валидацию, поэтому берём текущую дату
          if (angular.isDefined(date)) {
            mergedTime = moment(date);
          } else {
            mergedTime = moment();
          }

          // то же самое с часами
          if (angular.isDefined(hours)) {
            mergedTime.hours(hours);
          } else {
            mergedTime.hours(0);
          }

          // то же самое с минутами
          if (angular.isDefined(minutes)) {
            mergedTime.minutes(minutes);
          } else {
            mergedTime.minutes(0);
          }

          mergedTime.utcOffset(vm.currentApp.settings.timezone_offset, true); // выставляем полученному значению смещение относительно UTC, взятое из настроек апа
          mergedTime.seconds(0); // секунды выставляем в 0. Их всё равно никак нельзя задать, поэтому делаем это для порядочка

          return mergedTime;
        }
      }
    }

    /**
     * Изменение значения свича удаления сообщения
     * @param value
     */
    function changeDeleteSwitch(value) {
      $scope.$apply(() => {
        vm.deleteMessage.deleteType = value ? MESSAGE_DELETING_TYPES.CERTAIN_DATE : null;
      });
    }

    function collapseChange() {
      //Нужно чтобы отработало сразу
      $scope.$apply(() => {
        vm.trackClickExpandSettingsDisplay();
        vm.isCollapsed = !vm.isCollapsed;
      });
    }

    function destroy() {
      $interval.cancel(interval);
    }

    /**
     * Состояние когда все настройки выключены
     * @returns {boolean}
     */
    function emptySettings() {
      return !(
        vm.currentMessage.isSendAtTime ||
        vm.currentMessage.isRepeat ||
        (vm.deleteMessage?.deleteType && vm.hasDeletableMessagePart())
      );
    }

    /**
     * Проверка на одновременное нахождение в одном автосообщении двух вариантов типов сообщений, которые могут участвовать в фильтрации по URL
     *
     * @return {boolean}
     */
    function isMessageFilterableByUrl() {
      const filterableMessageParts = $filter('filter')(vm.currentMessage.parts, (messagePart) => {
        return messagePartModel.isFilterableByUrlType(messagePart.type);
      });
      return filterableMessageParts.length === vm.currentMessage.parts.length;
    }

    /**
     * Записывает объект из нового компонента по отдельным свойствам currentMessage
     * @param {SendingRepeatConfig} sendReply
     */
    function onSendReplyChange(sendReply) {
      vm.currentMessage.repeatDelay = sendReply.timeConfig.time;
      vm.currentMessage.repeatDelayTimeUnit = sendReply.timeConfig.unit;
      vm.currentMessage.notSendReplied = sendReply.notSendReplied;
    }

    /**
     * Записывает объект из нового компонента по отдельным свойствам currentMessage
     * @param {SendTime} sendTime
     */
    function onSendTimeChange(sendTime) {
      vm.currentMessage.sendTimeValueFromH = sendTime.sendTimeValueFromH;
      vm.currentMessage.sendTimeValueFromM = sendTime.sendTimeValueFromM;
      vm.currentMessage.sendTimeValueToH = sendTime.sendTimeValueToH;
      vm.currentMessage.sendTimeValueToM = sendTime.sendTimeValueToM;
    }

    /**
     * Добивка строки нулями и возвращения его в виде строки
     *
     * @param {String|Number} num Число для добивки
     * @param {String|Number} size Сколькими нулями добивать
     * @returns {String}
     */
    function pad(num, size) {
      return ('000000000' + num).substr(-size);
    }

    /**
     * Трек клика 'Чат-бот - Настройка запуска - раскрыл настройки показа'
     */
    function trackClickExpandSettingsDisplay() {
      if (vm.isCollapsed) {
        return;
      }
      if (vm.messagePageType === MESSAGE_PAGE_TYPES.CHAT_BOTS) {
        carrotquestHelper.track('Чат-бот - Настройка запуска - раскрыл настройки показа');
      } else {
        carrotquestHelper.track('Автосообщения - Настройка запуска - раскрыл настройки показа');
      }
    }
  }
})();

<div class="d-flex py-15 px-20 cursor-pointer border-bottom">
  <div class="d-flex align-items-center margin-between-cols-20">
    <ng-content></ng-content>
  </div>

  <button
    class="btn btn-text-secondary ml-auto"
    type="button"
  >
    <i
      class="cqi-sm"
      [ngClass]="getIconClass()"
    ></i>
  </button>
</div>

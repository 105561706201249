<ng-container *transloco="let t; read: 'inputNumberRangeComponent'">
  <div class="flex">
    <div
      class="form-group no-margin-bottom"
      [cqShowError]="control.controls.value1"
    >
      <input
        class="form-control"
        [formControl]="control.controls.value1"
        [placeholder]="t('placeholder.value1')"
        autofocus
        required
        type="text"
      />
      <cq-validation-messages [control]="control.controls.value1">
        <cq-validation-message errorName="min">{{ t('error.min', { value: MIN_VALUE }) }}</cq-validation-message>
        <cq-validation-message errorName="pattern">{{ t('error.pattern') }}</cq-validation-message>
        <cq-validation-message errorName="required">{{ t('error.required') }}</cq-validation-message>
      </cq-validation-messages>
    </div>

    <div
      class="form-group no-margin-bottom"
      [cqShowError]="control.controls.value2"
    >
      <input
        class="form-control"
        [formControl]="control.controls.value2"
        [placeholder]="t('placeholder.value2')"
        required
        type="text"
      />
      <cq-validation-messages [control]="control.controls.value2">
        <cq-validation-message errorName="min">{{ t('error.min', { value: MIN_VALUE }) }}</cq-validation-message>
        <cq-validation-message errorName="pattern">{{ t('error.pattern') }}</cq-validation-message>
        <cq-validation-message errorName="required">{{ t('error.required') }}</cq-validation-message>
      </cq-validation-messages>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { environment } from '@environment';
import { MESSAGE_PART_TYPE_ICON_CLASSES } from '@http/message-part/message-part.constants';
import { ReadyMessageTemplate } from '@http/ready-message-template/ready-message-template.types';

@Component({
  selector: 'cq-ready-auto-messages-template',
  templateUrl: './ready-auto-messages-template.component.html',
  styleUrls: ['./ready-auto-messages-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyAutoMessagesTemplateComponent {
  @Input()
  template!: ReadyMessageTemplate;

  MESSAGE_PART_TYPE_ICON_CLASSES = MESSAGE_PART_TYPE_ICON_CLASSES;

  getTemplateUrl(name: string) {
    return environment.userFilesUrl + '/ready_message_templates_preview/' + name;
  }
}

<ng-container *transloco="let t">
  <cq-vk-forms-form-link
    class="margin-bottom-20"
    [control]="formLinkControl"
    (removeForm)="removeForm.emit(true)"
    [showRemoveFormButton]="showRemoveFormButton"
  ></cq-vk-forms-form-link>

  <div class="margin-bottom-20">
    <h4 class="font-weight-bold">{{ t('vkFormsFormComponent.heading', { projectName: projectName }) }}</h4>
    <div>{{ t('vkFormsFormComponent.description', { projectName: projectName }) }}</div>
  </div>

  <cq-vk-forms-standard-questions
    [currentApp]="currentApp"
    [properties]="properties"
    [standardQuestions]="standardQuestionsControl"
  ></cq-vk-forms-standard-questions>

  <cq-vk-forms-questions
    [currentApp]="currentApp"
    [properties]="properties"
    [questions]="questionsControl"
  ></cq-vk-forms-questions>

  <cq-vk-forms-custom-event
    class="margin-top-20"
    [control]="eventTypeNameControl"
    [formIndex]="formIndex"
  ></cq-vk-forms-custom-event>
</ng-container>

import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { Point } from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { fromEvent, interval, merge, mergeMap, Observable } from 'rxjs';
import { filter, map, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import {
  PointerHorizontalPosition,
  PointerVerticalPosition,
} from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.types';
import { PIXI_VIEWPORT } from '@panel/app/pages/chat-bot/content/tokens';
import { DestroyService } from '@panel/app/services';
import { CanvasInteractionService } from '@panel/app/services/canvas/common/interaction/canvas-interaction.service';
import { ConnectionService } from '@panel/app/services/canvas/tirgger-chain/connections/connection.service';
import { InteractionService } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.service';

/**
 * Сервис, за счет которого реализовано перемещение viewport при рисовании стрелки или перемещении блока
 * к краям канваса
 */
@Injectable()
export class ViewportPointerFollowService {
  private viewportMoveTickTime = 100;

  constructor(
    private readonly connectionService: ConnectionService,
    private readonly interactionService: InteractionService,
    private readonly canvasInteractionService: CanvasInteractionService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    private readonly destroy$: DestroyService,
  ) {}

  init({ nativeElement: canvasEl }: ElementRef<HTMLElement>): void {
    this.viewportPositionChange$(canvasEl)
      .pipe(takeUntil(this.destroy$))
      .subscribe((newPosition) => {
        this.viewport.animate({ position: newPosition, time: this.viewportMoveTickTime });
      });
  }

  private viewportPositionChange$(canvasEl: HTMLElement): Observable<Point> {
    return merge(this.interactionService.moveStart$, this.connectionService.connectionDrawStart$).pipe(
      mergeMap((event) => {
        return interval(this.viewportMoveTickTime).pipe(
          takeUntil(fromEvent(this.document, 'pointerup').pipe(tap(() => this.enableUserSelect()))),
          map(() => event),
        );
      }),
      withLatestFrom(this.pointerRelativePosition$(canvasEl)),
      map(([event, positions]) => {
        return { event, verticalPosition: positions.vertical, horizontalPosition: positions.horizontal };
      }),
      // убираем возможность выделать на всем документе
      tap(() => this.disableUserSelect()),
      // если курсор в центре, то дальше и делать ничего не надо
      filter(({ verticalPosition, horizontalPosition }) => {
        return verticalPosition.position !== 'middle' || horizontalPosition.position !== 'middle';
      }),
      map(({ event, verticalPosition, horizontalPosition }) => {
        const movePx = 5;
        const nextPos = this.viewport.center.clone();

        if (verticalPosition.position === 'top') {
          nextPos.y -= movePx / verticalPosition.distanceCoefficient! / this.viewport.scaled;
        } else if (verticalPosition.position === 'bottom') {
          nextPos.y += movePx / verticalPosition.distanceCoefficient! / this.viewport.scaled;
        }

        if (horizontalPosition.position === 'left') {
          nextPos.x -= movePx / horizontalPosition.distanceCoefficient! / this.viewport.scaled;
        } else if (horizontalPosition.position === 'right') {
          nextPos.x += movePx / horizontalPosition.distanceCoefficient! / this.viewport.scaled;
        }

        return nextPos;
      }),
    );
  }

  /**
   * Отдает относительную позицию курсора на канвасе (лево/право, низ/вех) и коэффициент близости (0 to 1)
   */
  private pointerRelativePosition$(canvasEl: HTMLElement) {
    return merge(this.canvasInteractionService.pointerMove$, this.canvasInteractionService.pointerOut$).pipe(
      withLatestFrom(this.stepEditorWidth$),
      map(([pointerEvent, editorWidth]) => {
        let { clientWidth: canvasW, clientHeight: canvasH } = canvasEl;
        const { x: pointerX, y: pointerY } = pointerEvent.global;

        // Ширина "виртуальной рамки", на расстоянии от которой курсор все еще считается в рамках какой-то стороны
        const borderSize = 50;

        const isBottom = canvasH - pointerY < borderSize;
        const isTop = pointerY < borderSize;
        canvasW = editorWidth ? canvasW - editorWidth : canvasW;
        const isRight = canvasW - pointerX < borderSize;
        const isLeft = pointerX < borderSize;

        let vertPosition: PointerVerticalPosition;
        let vertDistanceCoefficient: number | null;
        if (isBottom) {
          vertPosition = 'bottom';
          vertDistanceCoefficient = (canvasH - pointerY) / borderSize;
        } else if (isTop) {
          vertPosition = 'top';
          vertDistanceCoefficient = pointerY / borderSize;
        } else {
          vertPosition = 'middle';
          vertDistanceCoefficient = null;
        }

        let horizPosition: PointerHorizontalPosition;
        let horizDistanceCoefficient: number | null;
        if (isRight) {
          horizPosition = 'right';
          horizDistanceCoefficient = (canvasW - pointerX) / borderSize;
        } else if (isLeft) {
          horizPosition = 'left';
          horizDistanceCoefficient = pointerX / borderSize;
        } else {
          horizPosition = 'middle';
          horizDistanceCoefficient = null;
        }

        return {
          vertical: {
            position: vertPosition,
            distanceCoefficient: Math.max(vertDistanceCoefficient ?? 0, 0.1),
          },
          horizontal: {
            position: horizPosition,
            distanceCoefficient: Math.max(horizDistanceCoefficient ?? 0, 0.1),
          },
        };
      }),
    );
  }

  /**
   * Короче тут мне было не*уй делать и я решил поиграться с браузерным MutationObserver.
   * Если редактор шага открыт - возвращает его ширину. Если нет - 0
   */
  private stepEditorWidth$ = new Observable<MutationRecord[]>((subscriber) => {
    const chainEditor = this.document.querySelector(`cq-trigger-chain-editor [data-stepEditorParent]`);
    if (!chainEditor) {
      throw new Error('Could not find an elements');
    }

    const mutationObserver = new MutationObserver((entries) => {
      subscriber.next(entries);
    });
    mutationObserver.observe(chainEditor, { childList: true });

    return {
      unsubscribe() {
        mutationObserver.disconnect();
      },
    };
  }).pipe(
    map((records) => {
      const record = records.find((record) => {
        if (record.type !== 'childList') {
          return false;
        }
        const editorNode = record.addedNodes.item(0);
        if (!editorNode) {
          return false;
        }
        return editorNode.isEqualNode(this.document.querySelector('cq-trigger-chain-step-editor'));
      });
      return record ? record.addedNodes.item(0) : null;
    }),
    map((node) => {
      if (!node) {
        return 0;
      }
      if (!(node instanceof Element)) {
        throw new Error('Node is not instance of Element');
      }
      return node.clientWidth;
    }),
    startWith(0),
  );

  private disableUserSelect() {
    this.document.body.style.userSelect = 'none';
  }

  private enableUserSelect() {
    this.document.body.style.userSelect = 'initial';
  }
}

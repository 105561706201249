(function () {
  'use strict';

  require('./leads-import-types.controller');

  angular.module('myApp.users').component('cqLeadsImportTypes', cqLeadsImportTypes());

  function cqLeadsImportTypes() {
    return {
      bindings: {
        importType: '<', // Тип импорта
        importTypes: '<', // Типы импорта
        importTypeIcons: '<', // Иконки для типов импорта
        importTypesList: '<', // Список типов импорта
        onImportTypeChange: '&?', // Колбэк, срабатывающий при выборе типа импорта
      },
      controller: 'CqLeadsImportTypesController',
      controllerAs: 'vm',
      template: require('./leads-import-types.html'),
    };
  }
})();

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupImageBlockPreviewController', CqPopupImageBlockPreviewController);

  function CqPopupImageBlockPreviewController($q, $scope, $templateRequest, fileHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.parsedParams = null; // скорректированные параметры поп-апа
      vm.template = ''; // строка, содержащая html и css-код, необходимый для работы превью блока

      getTemplate();

      // FIXME NOTE: в параметрах может быть картика, поэтому нельзя использовать deep watch, он крашится из-за картинки, не умеет с ней работать. Нужно, чтобы все картинки в админке преобразовывались в Blob + название, а при отпраке перегонялись назад в File
      //$scope.$watch('vm.params', parseParams, true);
      $scope.$watchCollection('vm.params', parseParams);
    }

    /**
     * Получения темплейта для блока
     */
    function getTemplate() {
      $templateRequest('js/components/popup-editor/block-preview/image/image.html').then(getTemplateSuccess);

      function getTemplateSuccess(templates) {
        vm.template = templates;
      }
    }

    /**
     * Парсинг исходных параметров блока
     * HACK: из-за того, что файлы нельзя корректно передавать в iframe - пришлось костылять, чтобы передавать туда Blob
     *
     * @param {Object} params Параметры блока
     */
    function parseParams(params) {
      if (params) {
        vm.parsedParams = angular.copy(params);
        if (params.backgroundImage instanceof File) {
          vm.parsedParams.backgroundImage = fileHelper.getBlob(params.backgroundImage);
        }
      }
    }
  }
})();

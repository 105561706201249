import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import Sortable, { Options } from 'sortablejs';

import { ActiveChatWidget, WidgetBot } from '@http/chat-bot/types/chat-bot-internal.types';

/**
 * Компонент для редактирования порядка чат-виджетов
 */
@Component({
  selector: 'cq-active-chat-widget-order[activeChatWidgetList]',
  templateUrl: './active-chat-widget-order.component.html',
  styleUrls: ['./active-chat-widget-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveChatWidgetOrderComponent {
  /** Текущий редактируемый embedded-бот */
  @Input()
  widgetBotCurrent?: WidgetBot;

  /** Список активных чат-виджетов */
  @Input()
  set activeChatWidgetList(widgetBotList: ActiveChatWidget[]) {
    this.orderedActiveChatWidgetList = widgetBotList;
  }

  /** Колбэк на смену порядка чат-виджетов */
  @Output()
  chatWidgetOrderChanged = new EventEmitter<ActiveChatWidget[]>();

  sortablejsOptions: Options = {
    handle: '.handle-icon',
    onUpdate: this.onOrderUpdate.bind(this),
  };

  /** Отсортированный по полю order список чат-виджетов */
  orderedActiveChatWidgetList: ActiveChatWidget[] = [];

  /** Является ли бот из списка в данный момент редактируемым */
  isCurrent(activeChatWidget: ActiveChatWidget): boolean {
    return !!this.widgetBotCurrent && activeChatWidget.action.chatBotId === this.widgetBotCurrent.id;
  }

  /** Колбэк на смену порядка чат-виджетов */
  onOrderUpdate(event: Sortable.SortableEvent): void {
    this.chatWidgetOrderChanged.emit(this.orderedActiveChatWidgetList);
  }
}

import { FormArray, FormControl } from '@angular/forms';

import { AiBotAddressForm } from '@http/ai-data/internal-types';

export enum COMMUNICATION_STYLE {
  INFORMAL = 'informal',
  NEUTRAL = 'neutral',
  FORMAL = 'formal',
}

export enum REPLIES_LENGTH {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
}

export type AiBotSettingsForm = {
  communicationStyle: FormControl<COMMUNICATION_STYLE>;
  addressForm: FormControl<AiBotAddressForm>;
  repliesLength: FormControl<REPLIES_LENGTH>;
  creativityLevel: FormControl<number>;
  stopPhrases: FormControl<string[]>;
  workingTimeReply: FormControl<string>;
  nonWorkingTimeReply: FormControl<string>;
  closeTimeout: FormControl<number>;
  userTag: FormControl<string | null>;
  userEvent: FormControl<string | null>;
  botRules: FormArray<FormControl<string | null>>;
  companyName: FormControl<string | null>;
  companyInfo: FormControl<string | null>;
};

<ng-container *transloco="let t; read: 'triggerChainBreadcrumbsComponent'">
  <ol class="breadcrumb mt-0 mb-0">
    <li class="breadcrumb-item d-flex align-items-center">
      <a uiSref="app.content.triggerChains">{{ t('title') }}</a>
      <i
        *ngIf="!nameIsNotNull(chainName)"
        class="cqi-sm cqi-question-circle ml-5"
        container="body"
        placement="right"
        [ngbTooltip]="t('tooltip')"
      ></i>
    </li>
    <li
      *ngIf="nameIsNotNull(chainName)"
      class="breadcrumb-item active"
      aria-current="page"
    >
      <cq-message-name-editor
        [messageName]="chainName"
        (messageNameChange)="onChangeName($event)"
      ></cq-message-name-editor>
    </li>
  </ol>
</ng-container>

import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import {
  PSEUDO_TEMPLATE_DIRECTORY_IDS,
  PSEUDO_TEMPLATE_DIRECTORY_TYPES,
} from '../../../app/http/template-directory/template-directory.constants';
import { MESSAGE_TEMPLATES_STATUSES } from '../../../app/http/message-template/message-template.constants';
import { MESSAGE_PART_TYPES, RECIPIENT_TYPES } from '../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../app/http/feature/feature.constants';
import {
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE,
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE_LIST,
} from '../../../app/http/ready-message-template/ready-message-template.constants';

/**
 * Контроллер списка шаблонов для поповера
 */
(function () {
  'use strict';

  angular
    .module('myApp.messageTemplatesPopover')
    .controller('CqMessageTemplatesPopoverController', CqMessageTemplatesPopoverController);

  function CqMessageTemplatesPopoverController(
    $filter,
    $q,
    $scope,
    $state,
    $translate,
    $uibModal,
    caseStyleHelper,
    l10nHelper,
    MESSAGE_TEMPLATES_TABS,
    carrotquestHelper,
    djangoUserModel,
    featureModel,
    messageTemplateModel,
    planFeatureAccessService,
    readyMessageTemplateModel,
    templateDirectoryModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, vm.currentApp);
      vm.accessToReadyAutoMessage = planFeatureAccessService.getAccess(PLAN_FEATURE.READY_AUTO_MESSAGES, vm.currentApp);

      var messagePartType = vm.messagePart.type;

      // Если тип сообщения попап (попадаем из лидов), то нужно получать и маленькие и большие шаблоны, но не в поповере!
      if (
        [MESSAGE_PART_TYPES.POPUP_SMALL, MESSAGE_PART_TYPES.POPUP_BIG].includes(vm.messagePart.type) &&
        !vm.isPopover
      ) {
        messagePartType = [MESSAGE_PART_TYPES.POPUP_SMALL, MESSAGE_PART_TYPES.POPUP_BIG];
      }

      vm.clearTemplatesFilters = clearTemplatesFilters;
      vm.clearReadyTemplatesFilters = clearReadyTemplatesFilters;
      vm.currentDirectory = templateDirectoryModel.getAllDirectory(); // Выбранная директория
      vm.currentScopeOfUse = READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY; // Выбранный тип сайта
      vm.currentTab = MESSAGE_TEMPLATES_TABS.TEMPLATE_GALLERY; // Выбранный статус шаблона сообщения
      vm.currentTemplateStatus = MESSAGE_TEMPLATES_STATUSES.USED; // Выбранный статус шаблона сообщения
      vm.directories = []; // Шаблоны
      vm.djangoUserModel = djangoUserModel;
      vm.FEATURES = FEATURES;
      vm.featureModel = featureModel;
      vm.getReadyTemplates = getReadyTemplates;
      vm.getTemplates = getTemplates;
      vm.getTemplatesWithRecipientType = getTemplatesWithRecipientType;
      vm.isDataLoading = true; // Флаг загрузки данных
      vm.isReadyTemplatesFiltersExpanded = false; // Флаг отображения фильтров у вкладки с галереей шаблонов
      vm.isTemplatesFiltersExpanded = false; // Флаг отображения фильтров у вкладки с сохраненными шаблонами
      vm.isTemplateEditorPage =
        $state.includes('app.content.messagesAjs.templates') &&
        !$state.is('app.content.messagesAjs.createOnReadyTemplate'); // Является ли эта страница страницей редактирования шаблона или создания автосообщения на основе готового шаблона. Надо скрывать некоторые элементы шаблона, если true
      vm.MESSAGE_TEMPLATES_TABS = MESSAGE_TEMPLATES_TABS;
      vm.PSEUDO_TEMPLATE_DIRECTORY_IDS = PSEUDO_TEMPLATE_DIRECTORY_IDS;
      vm.PSEUDO_TEMPLATE_DIRECTORY_TYPES = PSEUDO_TEMPLATE_DIRECTORY_TYPES;
      vm.READY_MESSAGE_TEMPLATE_SCOPES_OF_USE = READY_MESSAGE_TEMPLATE_SCOPES_OF_USE;
      vm.READY_MESSAGE_TEMPLATE_SCOPES_OF_USE_LIST = READY_MESSAGE_TEMPLATE_SCOPES_OF_USE_LIST;
      vm.readyTemplates = []; // список готовых шаблонов
      vm.readyTemplatesPaginator = null; // пагинация готовых шаблонов
      vm.searchPhraseForReadyTemplates = ''; // Фраза для поиска готовых шаблонов
      vm.searchPhraseForTemplates = ''; // Фраза для поиска шаблонов
      vm.showReadyTemplatesFiltersZeroData = showReadyTemplatesFiltersZeroData;
      vm.showReadyTemplatesSearch = showReadyTemplatesSearch;
      vm.showReadyTemplatesZeroData = showReadyTemplatesZeroData;
      vm.showTemplatesFiltersZeroData = showTemplatesFiltersZeroData;
      vm.showTemplatesSearch = showTemplatesSearch;
      vm.showTemplatesZeroData = showTemplatesZeroData;
      vm.templates = []; // список сохранённых шаблонов
      vm.templatesPaginator = null; // пагинация сохранённых шаблонов
      vm.toggleReadyTemplatesFilters = toggleReadyTemplatesFilters;
      vm.toggleTemplatesFilters = toggleTemplatesFilters;
      vm.trackChooseDirectory = trackChooseDirectory;
      vm.trackClickSearchInput = trackClickSearchInput;
      vm.trackOnCleanTemplateClick = trackOnCleanTemplateClick;
      vm.zeroDataButtonText = $state.includes('app.content.messagesAjs')
        ? 'createCleanAutoMessageButton'
        : $state.includes('app.content.messagesAjs.templates')
        ? 'createCleanTemplateButton'
        : 'createCleanMessageButton';

      let preprocessingTasks = [];
      vm.accessToReadyAutoMessage.hasAccess &&
        preprocessingTasks.push(
          firstValueFrom(
            readyMessageTemplateModel.getList(
              vm.currentApp.id,
              messagePartType,
              READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY,
              true,
            ),
          ).then(getReadyTemplatesSuccess),
        );
      vm.accessToMessagesTemplates.hasAccess &&
        preprocessingTasks.push(
          firstValueFrom(
            messageTemplateModel.getList(
              vm.currentApp.id,
              MESSAGE_TEMPLATES_STATUSES.USED,
              messagePartType,
              vm.currentDirectory.id,
              null,
              true,
              true,
            ),
          ).then(getTemplatesSuccess),
        );
      preprocessingTasks.push(
        firstValueFrom(templateDirectoryModel.getList(vm.currentApp.id, true, true)).then(
          getTemplateDirectoriesSuccess,
        ),
      );

      $q.all(preprocessingTasks).finally(loadDataFinally);

      $scope.$watch('vm.messagePart.type', resetCurrentTab);
      $scope.$watchGroup(['vm.currentTab', 'vm.messagePart.type'], getTemplatesWatch);
      $scope.$watch('vm.currentDirectory', templatesFilterByDirectoryWatch);
      $scope.$watch('vm.currentScopeOfUse', readyTemplatesFilterByScopeOfUseWatch);
      $scope.$watch('vm.searchPhraseForReadyTemplates', readyTemplatesFilterByNameWatch);
      $scope.$watch('vm.searchPhraseForTemplates', templatesFilterByNameWatch);

      $scope.$on('message', handleRts);

      /**
       * Получает готовые шаблоны и сохраненные
       *
       * @param newVal
       * @param oldVal
       */
      function getTemplatesWatch(newVal, oldVal) {
        if (!angular.equals(newVal, oldVal) && !vm.isPopover) {
          getReadyTemplates(vm.messagePart.type, vm.currentScopeOfUse);
          getTemplates(vm.currentTemplateStatus, vm.messagePart.type, vm.currentDirectory);
        }
      }

      /**
       * Успешное получение готовых шаблонов
       *
       * @param {Array} data - Готовые шаблоны
       */
      function getReadyTemplatesSuccess(data) {
        vm.readyTemplates = data.templates;
        vm.readyTemplatesPaginator = data.paginatorParams;
      }

      /**
       * Успешное получение папок шаблонов
       *
       * @param {Array} directories - Список папок
       */
      function getTemplateDirectoriesSuccess(directories) {
        vm.directories = directories;
      }

      /**
       * Успешное получение шаблонов
       *
       * @param {Array} data - Шаблоны
       */
      function getTemplatesSuccess(data) {
        // HACK В ручных сообщениях нельзя выбирать тип ответа "Подписка на Web Push уведомления".
        // HACK Поэтому нужно фильтровать шаблоны с таким типом ответа везде, кроме раздела автосообщений и раздела шаблонов.

        // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
        // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
        // HACK Поэтому необходимо, немного расширить (но не персонализировать для SDK-типов) алгортим фильтрации.

        if (!($state.includes('app.content.messagesAjs') || $state.includes('app.content.messagesAjs.templates'))) {
          vm.templates = messageTemplateModel.filterTemplate(data.templates);
        } else {
          vm.templates = data.templates;
          vm.templatesPaginator = data.paginatorParams;
        }
      }

      /**
       * Финальная стадия загрузки данных
       */
      function loadDataFinally() {
        vm.isDataLoading = false;
      }

      function readyTemplatesFilterByNameWatch(newVal, oldVal) {
        if (newVal !== oldVal) {
          getReadyTemplates(vm.messagePart.type, vm.currentScopeOfUse);
        }
      }

      function readyTemplatesFilterByScopeOfUseWatch(newVal, oldVal) {
        if (newVal !== oldVal) {
          getReadyTemplates(vm.messagePart.type, vm.currentScopeOfUse);
        }
      }

      function templatesFilterByDirectoryWatch(newVal, oldVal) {
        if (newVal !== oldVal) {
          getTemplates(vm.currentTemplateStatus, vm.messagePart.type, vm.currentDirectory);
        }
      }

      function templatesFilterByNameWatch(newVal, oldVal) {
        if (newVal !== oldVal) {
          getTemplates(vm.currentTemplateStatus, vm.messagePart.type, vm.currentDirectory);
        }
      }
    }

    /**
     * Сброс фильтров
     */
    function clearTemplatesFilters() {
      vm.currentDirectory = templateDirectoryModel.getAllDirectory();
      vm.searchPhraseForTemplates = '';
    }

    /**
     * Сброс фильтров
     */
    function clearReadyTemplatesFilters() {
      vm.currentScopeOfUse = READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY;
      vm.searchPhraseForReadyTemplates = '';
    }

    /**
     * Получение типов сообщения для попапов и конструкторов попапов
     * для поиска нам нужно показывать и большие и маленькие шаблоны
     */
    function parsePopupsToShowAllTemplates() {
      var multiMessagePartTypes = [];

      if (
        vm.messagePart.type === MESSAGE_PART_TYPES.POPUP_SMALL ||
        vm.messagePart.type === MESSAGE_PART_TYPES.POPUP_BIG
      ) {
        multiMessagePartTypes = [MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL];
      } else if (
        vm.messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL ||
        vm.messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG
      ) {
        multiMessagePartTypes = [MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL];
      } else {
        multiMessagePartTypes = [];
      }

      return multiMessagePartTypes;
    }

    /**
     * Получение готовых шаблонов (используется для пагинации)
     *
     * @param {MESSAGE_PART_TYPES} messagePartType Тип вариантов сообщения
     * @param {Object} scopeOfUse Сфера использования
     * @returns {Promise<T>}
     */
    function getReadyTemplates(messagePartType, scopeOfUse, concat) {
      var multiMessagePartTypes = parsePopupsToShowAllTemplates();
      vm.isDataLoading = true;

      if (!concat) {
        vm.readyTemplates = [];
        vm.readyTemplatesPaginator = null;
      }

      return firstValueFrom(
        readyMessageTemplateModel.getList(
          vm.currentApp.id,
          multiMessagePartTypes.length > 0 && !vm.isPopover ? multiMessagePartTypes : messagePartType,
          scopeOfUse,
          true,
          vm.readyTemplatesPaginator,
          vm.searchPhraseForReadyTemplates,
        ),
      )
        .then(updateTemplatesSuccess)
        .finally(updateTemplatesFinally);

      function updateTemplatesSuccess(data) {
        vm.readyTemplates.push.apply(vm.readyTemplates, data.templates);
        vm.readyTemplatesPaginator = data.paginatorParams;
      }

      function updateTemplatesFinally() {
        vm.isDataLoading = false;
      }
    }

    /**
     * Получение шаблонов (используется для пагинации)
     *
     * @param {MESSAGE_PART_TYPES_ARRAY} status - Статус шаблонов
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип вариантов сообщения
     * @param {Object} directory - Директория
     * @returns {*}
     */
    function getTemplates(status, messagePartType, directory, concat) {
      var multiMessagePartTypes = parsePopupsToShowAllTemplates();
      vm.isDataLoading = true;

      if (!concat) {
        vm.templates = [];
        vm.templatesPaginator = null;
      }

      return firstValueFrom(
        messageTemplateModel.getList(
          vm.currentApp.id,
          status,
          multiMessagePartTypes.length > 0 && !vm.isPopover ? multiMessagePartTypes : messagePartType,
          directory.id,
          vm.templatesPaginator,
          true,
          true,
          vm.searchPhraseForTemplates,
        ),
      )
        .then(getTemplatesSuccess)
        .finally(getTemplatesFinally);

      function getTemplatesSuccess(data) {
        vm.templates.push.apply(vm.templates, data.templates);
        vm.templatesPaginator = data.paginatorParams;
      }

      function getTemplatesFinally() {
        vm.isDataLoading = false;
      }
    }

    /**
     * Получение шаблонов релевантных recipient_type (web|sdk)
     * HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
     * HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
     *
     * @param template - Шаблон сообщения
     * @returns {boolean}
     */
    function getTemplatesWithRecipientType(template) {
      if (
        !!~[
          MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
          MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
          MESSAGE_PART_TYPES.SDK_PUSH,
        ].indexOf(vm.messagePart.type)
      ) {
        return template.recipientType === RECIPIENT_TYPES.SDK;
      } else {
        return template.recipientType === RECIPIENT_TYPES.WEB;
      }
    }

    /**
     * Обработка RTS-сообщений
     *
     * @param event
     * @param info
     */
    function handleRts(event, info) {
      var channel = info.channel;
      var newTemplate = info.data;
      caseStyleHelper.keysToCamelCase(newTemplate);
      // РТС изменения превью шаблона
      if (channel.indexOf('message_template_updated.') === 0) {
        let callApply = false;

        for (var i = 0; i < vm.templates.length; i++) {
          if (vm.templates[i].id === newTemplate.id) {
            var directory = $filter('filter')(vm.directories, { id: newTemplate.directory }, true)[0];
            if (directory) {
              newTemplate.directory = $filter('filter')(vm.directories, { id: newTemplate.directory }, true)[0];
            } else {
              newTemplate.directory = templateDirectoryModel.getWithoutDirectory();
            }
            messageTemplateModel.parse(newTemplate, true);
            vm.templates[i] = newTemplate;

            callApply = true;

            break;
          }
        }

        callApply && $scope.$applyAsync();
      }
    }

    /**
     * При изменении типа сообщения сбрасывает таб на галерею шаблонов
     *
     * @param newVal
     * @param oldVal
     */
    function resetCurrentTab(newVal, oldVal) {
      if (newVal !== oldVal) {
        vm.currentTab = MESSAGE_TEMPLATES_TABS.TEMPLATE_GALLERY;
      }
    }

    /**
     * Изменение состояния, zeroData если нет готовых шаблонов (по фильтрам),
     * и изменение состояния поиска (показывается/скрыт)
     *
     * @return {boolean}
     */
    function showReadyTemplatesFiltersZeroData() {
      return (
        !vm.isDataLoading &&
        vm.readyTemplates.length === 0 &&
        (vm.searchPhraseForReadyTemplates.length > 0 ||
          vm.currentScopeOfUse !== vm.READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY)
      );
    }

    /**
     * Показывать поиск в готовых шаблонах
     * @return {boolean}
     */
    function showReadyTemplatesSearch() {
      return (
        vm.readyTemplates.length === 0 &&
        (vm.searchPhraseForReadyTemplates.length > 0 ||
          vm.currentScopeOfUse !== vm.READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY)
      );
    }

    /**
     * Изменение состояния, zeroData если нет сохраненных шаблонов (по фильтрам),
     * и изменение состояния поиска (показывается/скрыт)
     *
     * @return {boolean}
     */
    function showTemplatesFiltersZeroData() {
      return (
        !vm.isDataLoading &&
        vm.templates.length === 0 &&
        (vm.searchPhraseForTemplates.length > 0 ||
          vm.currentDirectory.id !== vm.PSEUDO_TEMPLATE_DIRECTORY_IDS[vm.PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY])
      );
    }

    /**
     * Показывать поиск в сохраненных шаблонах
     * @return {boolean}
     */
    function showTemplatesSearch() {
      return (
        vm.templates.length === 0 &&
        (vm.searchPhraseForTemplates.length > 0 ||
          vm.currentDirectory.id !== vm.PSEUDO_TEMPLATE_DIRECTORY_IDS[vm.PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY])
      );
    }

    /**
     * Изменение состояния zeroData если ВООБЩЕ нет готовых шаблонов
     * @return {boolean}
     */
    function showReadyTemplatesZeroData() {
      return (
        !vm.isDataLoading &&
        vm.isPopover &&
        vm.readyTemplates.length === 0 &&
        vm.searchPhraseForReadyTemplates.length === 0 &&
        vm.currentScopeOfUse === vm.READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY
      );
    }

    /**
     * Изменение состояния zeroData если ВООБЩЕ нет сохраненных шаблонов
     * @return {boolean}
     */
    function showTemplatesZeroData() {
      return (
        !vm.isDataLoading &&
        vm.isPopover &&
        vm.templates.length === 0 &&
        vm.searchPhraseForTemplates.length === 0 &&
        vm.currentDirectory.id === vm.PSEUDO_TEMPLATE_DIRECTORY_IDS[vm.PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY]
      );
    }

    /**
     * Изменение состояния фильтров (открыто/закрыто)
     */
    function toggleReadyTemplatesFilters() {
      vm.isReadyTemplatesFiltersExpanded = !vm.isReadyTemplatesFiltersExpanded;
    }

    /**
     * Изменение состояния фильтров (открыто/закрыто)
     */
    function toggleTemplatesFilters() {
      vm.isTemplatesFiltersExpanded = !vm.isTemplatesFiltersExpanded;
    }

    /**
     * Трек выбора фильтра папки
     */
    function trackChooseDirectory(directory) {
      carrotquestHelper.track('Шаблоны - клик на поиск ', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        'Название папки': directory.prettyName,
        Расположение: 'Поповер',
      });
    }

    /**
     * Трек клика на поиск
     */
    function trackClickSearchInput() {
      carrotquestHelper.track('Шаблоны - клик на поиск ', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
      });
    }

    /**
     * Трек по клику на "создать автосообщение с нуля"
     */
    function trackOnCleanTemplateClick() {
      var page = $state.includes('app.content.messagesAjs')
        ? 'Автосообщения'
        : $state.includes('app.content.messagesAjs.templates')
        ? 'Шаблоны'
        : 'Сообщения';
      carrotquestHelper.track(page + ' - клик на "Создать автосообщения с нуля" внутри шаблона', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Тип: vm.messagePart.type,
      });
    }
  }
})();

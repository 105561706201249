(function () {
  'use strict';

  require('./trigger-chain-sending-type-switcher.controller');

  angular
    .module('myApp.triggerChains.sendingTypeSwitcher')
    .component('cqTriggerChainSendingTypeSwitcher', cqTriggerChainSendingTypeSwitcher());

  function cqTriggerChainSendingTypeSwitcher() {
    return {
      bindings: {
        currentMessage: '=', // Сообщение из триггерной цепочки
        sendings: '=', // Данные для компонента HACK Этот объект — помойка из разных настроек, оставил так, как есть
      },
      controller: 'CqTriggerChainSendingTypeSwitcherController',
      controllerAs: 'vm',
      template: require('./trigger-chain-sending-type-switcher.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ProgressbarComponent } from '@panel/app/shared/visual-components/progressbar/progressbar.component';

@NgModule({
  declarations: [ProgressbarComponent],
  exports: [ProgressbarComponent],
  imports: [CommonModule, NgbProgressbarModule, NgbTooltipModule],
})
export class ProgressbarModule {}

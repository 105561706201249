<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('emailNotificationCreateModalWrapper.heading') }}</h3>
    <button
      class="close"
      (click)="dismissModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body custom-scroll">
    <cq-email-notification
      class="card-body"
      (integrationCreated)="onIntegrationCreate($event)"
      [autoMessages]="[]"
      [currentApp]="modalWindowParams.currentApp"
      [emailRecipients]="modalWindowParams.emailRecipients"
      [integrationExternal]="modalWindowParams.integrationExternal"
      [isShowedFromModal]="true"
      [properties]="modalWindowParams.properties"
      [teamMembers]="$any(stateService.teamMembers$ | async)"
    ></cq-email-notification>
  </div>
  <div class="modal-footer border-top">
    <button
      class="btn btn-outline-primary"
      (click)="dismissModal()"
      type="button"
    >
      {{ t('general.cancel') }}
    </button>
    <button
      class="btn btn-primary"
      (click)="emailNotificationComponent.onSubmit()"
    >
      {{ t('emailNotificationCreateModalWrapper.createIntegrationButton') }}
    </button>
  </div>
</ng-container>

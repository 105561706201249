import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgbCollapse, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AutoMessageGoalComponent } from './auto-message-goal.component';

@NgModule({
  declarations: [AutoMessageGoalComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbTooltipModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslocoPipe,
    NgbCollapse,
    FormsModule,
  ],
})
export class AutoMessageGoalModule {}

import { InjectionToken, Provider } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { MESSAGE_PAGE_TYPES } from '@http/message/message.constants';

export const MESSAGE_PAGE_TYPE_TOKEN = new InjectionToken<MESSAGE_PAGE_TYPES>('Message page type token');

export const messagePageTypeTokenProvider: Provider = {
  provide: MESSAGE_PAGE_TYPE_TOKEN,
  useFactory: (uiRouter: UIRouter) => {
    return uiRouter.stateService.includes('app.content.messagesAjs.chatBot')
      ? MESSAGE_PAGE_TYPES.CHAT_BOTS
      : MESSAGE_PAGE_TYPES.AUTO_MESSAGES;
  },
  deps: [UIRouter],
};

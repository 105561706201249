<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('chatBot.modals.linkEditor.heading.' + (isEdit ? 'edit' : 'add')) }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
      type="button"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="onSubmit()"
      autocomplete="off"
      id="linkEditorForm"
      name="linkEditorForm"
    >
      <div
        class="form-group"
        [cqShowError]="linkEditorForm.controls.text"
      >
        <label>{{ t('chatBot.modals.linkEditor.textInput.label') }}</label>
        <input
          class="form-control"
          [formControl]="linkEditorForm.controls.text"
          [placeholder]="t('chatBot.modals.linkEditor.textInput.placeholder')"
          name="text"
        />
        <cq-validation-messages [control]="linkEditorForm.controls.text">
          <cq-validation-message errorName="required">
            {{ t('chatBot.modals.linkEditor.textInput.errors.required') }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
      <div
        class="form-group no-margin-bottom"
        [cqShowError]="linkEditorForm.controls.url"
      >
        <label>{{ t('chatBot.modals.linkEditor.linkInput.label') }}</label>
        <input
          class="form-control"
          [formControl]="linkEditorForm.controls.url"
          [placeholder]="t('chatBot.modals.linkEditor.linkInput.placeholder')"
          name="url"
          ngbAutofocus
        />
        <cq-validation-messages [control]="linkEditorForm.controls.url">
          <cq-validation-message errorName="required">
            {{ t('chatBot.modals.linkEditor.linkInput.errors.required') }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
      <input
        class="hidden"
        id="linkEditorForm.submit"
        type="submit"
      />
    </form>
  </div>
  <div class="modal-footer flex flex-row justify-space-between">
    <div>
      <button
        *ngIf="isEdit"
        (click)="removeLink()"
        class="btn btn-outline-danger"
        type="button"
      >
        {{ t('chatBot.modals.linkEditor.removeLinkButton') }}
      </button>
    </div>
    <div>
      <button
        (click)="activeModal.dismiss()"
        class="btn btn-outline-primary"
        type="button"
      >
        {{ t('general.cancel') }}
      </button>
      <label
        class="btn btn-primary"
        for="linkEditorForm.submit"
        >{{ t('general.save') }}</label
      >
    </div>
  </div>
</ng-container>

import { Container } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

/**
 * View экшена "Когда нет ожидаемой реакции"
 */
export class NoReactionView extends AbsInteractiveBlockPartView<TRIGGER_CHAIN_BLOCK_TYPE.REACTION> {
  /** Тип */
  type: INTERACTIVE_BLOCK_PART = INTERACTIVE_BLOCK_PART.NO_REACTION;

  hasConnection(): boolean {
    return this.stepView.data.nextStepOnFailConnected;
  }

  renderContent(): Container {
    let elementsForRender: Container[] = [];

    elementsForRender.push(this.renderValue());

    const el = ElementBuilder.wrapElementsInRectangle(elementsForRender, {
      background: {
        sideTextureName: 'background-danger950-272-side',
        innerTextureName: 'background-danger950-272-inner',
      },
      placement: {
        alignItems: 'center',
        justifyContent: 'start',
        padding: {
          x: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
          y: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
        },
      },
    });

    this.addAndPositionConnectionPoint(el);

    return el;
  }

  private renderValue(): Container {
    const icon = CanvasRenderService.renderIcon(UNICODE_ICON['cqi-ban'], { color: HEX_COLOR.DANGER_400 });

    const descriptionText: Container = CanvasRenderService.renderText(
      this.transloco.translate('interactiveBlockPart.noReaction.noReactionText', {
        reactionTimeText: this.stepView.data.reactionTimeText,
      }),
      {
        color: HEX_COLOR.DANGER_400,
        wordWrap: false,
      },
    );

    return ElementBuilder.wrapElementsInRectangle([icon, descriptionText], {
      placement: {
        marginBetweenCols: 5,
      },
    });
  }
}

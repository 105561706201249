import { firstValueFrom } from 'rxjs';
import { CONVERSATION_STATISTICS } from '../../../../app/http/conversation-statistics/conversation-statistics.constants';
import { CONVERSATION_ASSISTANT_TYPES } from '../../../../app/http/conversation/conversation.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';
import { VOTES, VOTES_LIST } from '../../../../app/http/vote/vote.constants';
import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';

(function () {
  'use strict';

  angular
    .module('myApp.conversationsStatistics')
    .controller('ConversationsStatisticsController', ConversationsStatisticsController);

  function ConversationsStatisticsController(
    $filter,
    $q,
    $scope,
    $translate,
    moment,
    carrotquestHelper,
    caseStyleHelper,
    channelModel,
    chartHelper,
    featureModel,
    conversationStatisticsModel,
    conversationTagsModel,
    dateRangePickerHelper,
    planFeatureAccessService,
    timeUnitService,
    voteModel,
    l10nHelper,
  ) {
    var vm = this;

    var axisSettings = {
      // настройки осей для большинства графиков
      xAxes: [
        {
          ticks: {
            autoSkipPadding: 10,
            maxRotation: 0,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: chartHelper.removeDecimalLabels,
          },
        },
      ],
    };

    /**
     * Вкладки аналитики диалогов
     *
     * @type {Object}
     */
    const CONVERSATIONS_STATISTICS_TABS = {
      AI_BOT: {
        ORDER: 2,
        TEMPLATE_URL: 'js/components/conversations-statistics/general/tabs/ai-bot.html',
        VALUE: 'aiBot',
        DISABLED: false,
      },
      CHANNELS: {
        ORDER: 3,
        TEMPLATE_URL: 'js/components/conversations-statistics/general/tabs/channels.html',
        VALUE: 'channels',
        DISABLED: false,
      },
      GENERAL: {
        ORDER: 0,
        TEMPLATE_URL: 'js/components/conversations-statistics/general/tabs/general.html',
        VALUE: 'general',
        DISABLED: false,
      },
      OPERATORS: {
        ORDER: 1,
        TEMPLATE_URL: 'js/components/conversations-statistics/general/tabs/operators.html',
        VALUE: 'operators',
        DISABLED: false,
      },
      VOTE: {
        ORDER: 4,
        TEMPLATE_URL: 'js/components/conversations-statistics/general/tabs/vote.html',
        VALUE: 'vote',
        DISABLED: false,
      },
      TAGS: {
        ORDER: 5,
        TEMPLATE_URL: 'js/components/conversations-statistics/general/tabs/tags.html',
        VALUE: 'tags',
        DISABLED: false,
      },
      SOURCE: {
        ORDER: 6,
        TEMPLATE_URL: 'js/components/conversations-statistics/general/tabs/source.html',
        VALUE: 'source',
        DISABLED: false,
      },
    };

    /**
     * Причины показа zero-даты при запросе статистики по операторам
     *
     * @type {Object}
     */
    const OPERATORS_STATISTICS_ZERO_DATA_REASONS = {
      NO_OPERATORS_FOR_SELECTED_PERIOD: 'no_operators_for_selected_period',
      NO_OPERATORS_IN_CHANNEL: 'no_operators_in_channel',
    };

    /**
     * Причины показа zero-даты при запросе статистики по тегам
     *
     * @type {Object}
     */
    const TAG_STATISTICS_ZERO_DATA_REASONS = {
      NO_TAG_IN_APP: 'no_tag_in_app',
      NOT_FOUND: 'no_found',
      NO_TAGS_IN_CHANNEL: 'no_tags_in_channel',
      OPERATOR_DIDNT_PUT_ANY_TAG: 'operator_didnt_put_any_tag',
      OPERATOR_DIDNT_PUT_ANY_TAG_IN_CHANNEL: 'operator_didnt_put_any_tag_in_channel',
      NO_TAGS_FOR_SELECTED_PERIOD: 'no_tags_for_selected_period',
    };

    /**
     * Заголовки таблицы с тегами
     *
     * @type {Array}
     */
    const TAG_TABLE_HEADERS = [
      ['name', $translate.instant('conversationsStatistics.tagsTab.table.headings.tag')],
      ['counter', $translate.instant('conversationsStatistics.tagsTab.table.headings.countConversation')],
      [
        'percent_conversation',
        $translate.instant('conversationsStatistics.tagsTab.table.headings.percentConversation'),
      ],
    ];

    vm.$onInit = init;

    function init() {
      vm.accessToAiBot = planFeatureAccessService.getAccess(PLAN_FEATURE.AI_BOT, vm.currentApp);
      vm.accessToAnalyticsConversations = planFeatureAccessService.getAccess(
        PLAN_FEATURE.ANALYTICS_CONVERSATIONS,
        vm.currentApp,
      );
      vm.accessToAnalyticsConversationsChannels = planFeatureAccessService.getAccess(
        PLAN_FEATURE.ANALYTICS_CONVERSATIONS_CHANNELS,
        vm.currentApp,
      );
      vm.accessToAnalyticsConversationsOperators = planFeatureAccessService.getAccess(
        PLAN_FEATURE.ANALYTICS_CONVERSATIONS_OPERATORS,
        vm.currentApp,
      );
      vm.accessToanalyticsConversationsVote = planFeatureAccessService.getAccess(
        PLAN_FEATURE.ANALYTICS_CONVERSATIONS_VOTE,
        vm.currentApp,
      );
      vm.accessToAnalyticsConversationsTags = planFeatureAccessService.getAccess(
        PLAN_FEATURE.ANALYTICS_CONVERSATIONS_TAGS,
        vm.currentApp,
      );
      vm.accessToAnalyticsConversationsSource = planFeatureAccessService.getAccess(
        PLAN_FEATURE.ANALYTICS_CONVERSATIONS_SOURCE,
        vm.currentApp,
      );

      vm.answerSpeed = {
        // средняя скорость ответа
        speedForAllPeriod: 0,
        chart: {
          type: 'line',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                label: $translate.instant('conversationsStatistics.generalTab.answerSpeed.chart.label'),
                data: [],
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('conversationsStatistics.generalTab.answerSpeed.chart.zeroData'),
            },
          },
        },
      };
      vm.changeTab = changeTab;
      vm.channelsTab = {
        totalOpened: 0,
        chart: {
          type: 'cqDoughnut',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
              },
            ],
          },
          options: {
            count: {
              display: true,
              fontColor: '#5c5cd6',
            },
          },
        },
        tableData: [],
      };

      vm.closedConversations = {
        // решённые вопросы
        closed: 0,
        chart: {
          type: 'bar',
          instance: null,
          data: {
            labels: [],
            datasets: [
              //Велком бот
              {
                data: [],
                label: $translate.instant('conversationsStatistics.generalTab.closedConversations.chart.label1'),
                type: 'bar',
              },
              //Вопросы решенные оператором
              {
                data: [],
                label: $translate.instant('conversationsStatistics.generalTab.closedConversations.chart.label2'),
                type: 'bar',
              },
            ],
          },
          options: {
            legendCallback: legendCallback,
            scales: {
              xAxes: axisSettings.xAxes,
              yAxes: [
                {
                  stacked: true,
                  ...axisSettings.yAxes[0],
                },
              ],
            },
            zeroData: {
              text: $translate.instant('conversationsStatistics.generalTab.closedConversations.chart.zeroData'),
            },
          },
        },
      };
      vm.CONVERSATIONS_STATISTICS_TABS = CONVERSATIONS_STATISTICS_TABS; // доступные вкладки аналитики диалогов
      vm.CONVERSATIONS_STATISTICS_TABS.AI_BOT.DISABLED =
        !vm.accessToAiBot.hasAccess && !featureModel.hasAccess(FEATURES.CHAT_GPT);
      vm.CONVERSATIONS_STATISTICS_TABS.CHANNELS.DISABLED = !vm.accessToAnalyticsConversationsChannels.hasAccess;
      vm.CONVERSATIONS_STATISTICS_TABS.OPERATORS.DISABLED = !vm.accessToAnalyticsConversationsOperators.hasAccess;
      vm.CONVERSATIONS_STATISTICS_TABS.TAGS.DISABLED = !vm.accessToAnalyticsConversationsTags.hasAccess;
      vm.CONVERSATIONS_STATISTICS_TABS.SOURCE.DISABLED = !vm.accessToAnalyticsConversationsSource.hasAccess;
      vm.CONVERSATIONS_STATISTICS_TABS.VOTE.DISABLED = !vm.accessToanalyticsConversationsVote.hasAccess;
      vm.conversationSize = {
        // средняя длина диалога
        sizeForAllPeriod: 0,
        chart: {
          type: 'line',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                label: $translate.instant('conversationsStatistics.generalTab.conversationSize.chart.label'),
                data: [],
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('conversationsStatistics.generalTab.conversationSize.chart.zeroData'),
            },
          },
        },
      };
      vm.currentRange = CONVERSATION_STATISTICS.RANGES.HOUR; // текущая группировка статистики
      vm.currentTab = CONVERSATIONS_STATISTICS_TABS.GENERAL.VALUE; // текущая вкладка
      vm.exportAiBotStatistics = exportAiBotStatistics;
      vm.exportChannelsStatistics = exportChannelsStatistics;
      vm.exportOperatorsStatistics = exportOperatorsStatistics;
      vm.exportTagsStatistics = exportTagsStatistics;
      vm.getAccessForConversationsStatisticsTabs = getAccessForConversationsStatisticsTabs;
      vm.getClassForTagsStatisticsTableTh = getClassForTagsStatisticsTableTh;
      vm.getMoreVotes = getMoreVotes;
      vm.getVoteStatsSum = getVoteStatsSum;
      vm.hasAccessForConversationsStatisticsTabs = hasAccessForConversationsStatisticsTabs;
      vm.isAnswerSpeedStatsLoading = false; // Флаг загрузки статистика по скорости ответа
      vm.isSameData = isSameData;
      vm.isWorkingTime = true; // Флаг показа рабочего или нерабочего времени в статистике по скорости ответа
      vm.translocoInlineLoader = false; // Грузятся ли сейчас данные
      vm.lostConversations = {
        // упущенные диалоги
        lost: 0,
        chart: {
          type: 'line',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('conversationsStatistics.generalTab.lostConversations.chart.label'),
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('conversationsStatistics.generalTab.lostConversations.chart.zeroData'),
            },
          },
        },
      };
      vm.notAnsweredConversations = {
        // неотвеченные диалоги
        notAnswered: 0,
        chart: {
          type: 'line',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('conversationsStatistics.generalTab.notAnsweredConversations.chart.label'),
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('conversationsStatistics.generalTab.notAnsweredConversations.chart.zeroData'),
            },
          },
        },
      };
      vm.notSelectedChannels = notSelectedChannels;
      vm.notSelectedSources = notSelectedSources;

      vm.onChangeSelect = function (newChannels) {
        vm.statisticsOptions.channels = newChannels;
        $scope.$evalAsync();
      };

      vm.openedConversations = {
        // открытые диалоги
        opened: 0,
        chart: {
          type: 'bar',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('conversationsStatistics.generalTab.openedConversations.chart.label'),
                type: 'bar',
              },
            ],
          },
          options: {
            legendCallback: legendCallback,
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('conversationsStatistics.generalTab.openedConversations.chart.zeroData'),
            },
          },
        },
      };
      vm.operators = {
        tableData: [], // Табличные данные статистики по операторам
        zeroDataReason: '', // Текущая перчина показа zero-dat'ы
      };
      vm.OPERATORS_STATISTICS_ZERO_DATA_REASONS = OPERATORS_STATISTICS_ZERO_DATA_REASONS;
      vm.statisticsOptions = {
        teamMember: vm.teamMembers[0],
        channels: [vm.channels[0].id],
        dates: {
          // период отображения статистики
          startDate: moment().startOf('day'),
          endDate: moment().endOf('day'),
        },
        dateRangePickerOptions: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          dateLimit: {
            quarters: 1,
          },
          eventHandlers: {
            'apply.daterangepicker': trackRangeSelect,
          },
          maxDate: moment().endOf('day'),
          opens: 'right',
          parentEl: '#datepicker-holder',
        }),
        channelsStatisticsOldData: {},
        operatorsStatisticsOldData: {},
        statisticsOldData: {},
        voteStatisticsOldData: {},
        tagsStatisticsOldData: {},
        sourceStatisticsOldData: {},
        aiBotStatisticsOldData: {},
      };
      vm.submitSearchForm = submitSearchForm;
      vm.aiBotTabData = {
        /** Все вопросы которые прошли через AI-бота */
        allQuestions: 0,
        /** Вопросы, на которые ответил AI-бот */
        answeredOfAiBot: 0,
        /** Вопросы которые AI-бот назначил на оператора */
        aiAssignedToOperator: 0,
        chart: {
          type: 'bar',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('conversationsStatistics.aiBotTab.chart.label1'),
                type: 'bar',
              },
              {
                data: [],
                label: $translate.instant('conversationsStatistics.aiBotTab.chart.label2'),
                type: 'bar',
              },
            ],
          },
          options: {
            legendCallback: legendCallback,
            scales: {
              xAxes: axisSettings.xAxes,
              yAxes: [
                {
                  stacked: true,
                  ...axisSettings.yAxes[0],
                },
              ],
            },
            zeroData: {
              text: $translate.instant('conversationsStatistics.aiBotTab.chart.zeroData'),
            },
          },
        },
      };
      vm.sourceTabData = {
        sources: [CONVERSATION_STATISTICS.SOURCES.ALL],
        closedConversations: {
          // решённые вопросы
          closed: 0,
          chart: {
            type: 'bar',
            instance: null,
            data: {
              labels: [],
              datasets: [
                {
                  data: [],
                  label: $translate.instant('conversationsStatistics.sourceTab.closedConversations.chart.label1'),
                  type: 'bar',
                },
                {
                  data: [],
                  label: $translate.instant('conversationsStatistics.sourceTab.closedConversations.chart.label2'),
                  type: 'bar',
                },
              ],
            },
            options: {
              legendCallback: legendCallback,
              scales: {
                xAxes: axisSettings.xAxes,
                yAxes: [
                  {
                    stacked: true,
                    ...axisSettings.yAxes[0],
                  },
                ],
              },
              zeroData: {
                text: $translate.instant('conversationsStatistics.sourceTab.closedConversations.chart.zeroData'),
              },
            },
          },
        },
        openedConversations: {
          // открытые диалоги
          opened: 0,
          chart: {
            type: 'bar',
            instance: null,
            data: {
              labels: [],
              datasets: [
                {
                  data: [],
                  label: $translate.instant('conversationsStatistics.sourceTab.openedConversations.chart.label'),
                  type: 'bar',
                },
              ],
            },
            options: {
              legendCallback: legendCallback,
              scales: axisSettings,
              zeroData: {
                text: $translate.instant('conversationsStatistics.sourceTab.openedConversations.chart.zeroData'),
              },
            },
          },
        },
      };
      vm.onChangeSourcesSelect = (newSources) => {
        vm.trackSourceChange();
        vm.sourceTabData.sources = newSources;
        $scope.$evalAsync();
      };
      vm.TAG_STATISTICS_ZERO_DATA_REASONS = TAG_STATISTICS_ZERO_DATA_REASONS;
      vm.TAG_TABLE_HEADERS = TAG_TABLE_HEADERS;
      vm.tags = {
        changeOrder: changeTagsOrder,
        contains: '', // Фраза для поиска по тегам
        currentStatistics: {
          totalConversations: 0, // Всего вопросов за указанный период
          totalConversationsWithTags: 0, // Всего вопросов помеченных тегами, за указанный период
          totalPercentConversationsWithTags: 0, // Всего вопросов помеченных тегами, за указанный период в процентах
        },
        currentContainsPhrase: '', // Текущая фраза для поиска по тегам
        daysPeriodCounter: 0, // Текущий указанный период в днях
        getTooltipText: getTooltipTextForTagStatisticDynamic,
        orderBy: 'desc', // Тип сортировки таблицы с тегами (asc или desc), по умолчанию desc
        orderByField: 'counter', // Поле, по которому происходит сортировка таблицы с тегами, по умолчанию counter (кол-во диалогов с тегом)
        previousStatistics: {
          startDate: '', // Дата начала предыдущего периода статистики
          endDate: '', // Дата конца предыдущего периода статистики
          totalPercentConversationsWithTags: 0, // Всего вопросов помеченных тегами, за предыдущий период в процентах
        },
        searchForm: null, // Форма для поиска по тегам
        tableData: [], // Табличные данные статистики по тегам
        zeroDataReason: '', // Текущая перчина показа zero-dat'ы
      };
      vm.trackChannelChange = trackChannelChange;
      vm.trackClickApplyFilterButton = trackClickApplyFilterButton;
      vm.trackClickChangeWorkingTime = trackClickChangeWorkingTime;
      vm.trackClickConversation = trackClickConversation;
      vm.trackClickExportAiBotStatistics = trackClickExportAiBotStatistics;
      vm.trackClickExportChannelsStatistics = trackClickExportChannelsStatistics;
      vm.trackClickExportOperatorsStatistics = trackClickExportOperatorsStatistics;
      vm.trackClickExportTagsStatistics = trackClickExportTagsStatistics;
      vm.trackClickTuneWorkingTime = trackClickTuneWorkingTime;
      vm.trackOperatorChange = trackOperatorChange;
      vm.trackSourceChange = trackSourceChange;
      vm.trackTabChange = trackTabChange;
      vm.trackVoteFilterChange = trackVoteFilterChange;
      vm.updateAnswerSpeedStatistics = updateAnswerSpeedStatistics;
      vm.updateStatistics = updateStatistics;
      vm.usedColors = chartHelper.getUsedColors();
      vm.VOTES = VOTES;
      vm.votes = {
        paginatorParams: null, // параметры пагинации
        tableData: [], // Данные для таблицы
        voteStats: voteModel.getDefault(), // Статистика голосов
        voteStatsSum: 0, // Сумма голосов оценок операторов
        fullVoteStatsSum: 0, // Сумма голосов оценок операторов + решённые вопросы без голосов
        vote: VOTES.BAD, // Выбранный тип оценок
      };
      vm.VOTES_LIST = VOTES_LIST;
      vm.workingPeriods = []; // Рабочее время чата
      vm.workload = {
        chart: {
          type: 'heatMap',
          instance: null,
          data: {
            datasets: [
              {
                label: $translate.instant('conversationsStatistics.generalTab.workload.chart.label'),
                data: [],
              },
            ],
          },
          options: {
            scales: {
              xAxes: [
                {
                  ticks: {
                    autoSkipPadding: 10,
                    maxRotation: 0,
                  },
                },
              ],
            },
            zeroData: {
              text: $translate.instant('conversationsStatistics.generalTab.workload.chart.zeroData'),
            },
          },
        },
        mostBusyTime: $translate.instant('conversationsStatistics.generalTab.workload.mostBusyTime.defaultValue'),
      };

      if (
        angular.isDefined(vm.currentApp.settings.messenger_work_time) ||
        vm.currentApp.settings.messenger_work_time.length !== 0
      ) {
        angular.copy(vm.currentApp.settings.messenger_work_time, vm.workingPeriods);

        // Преобразуем минуты в человеческий формат ЧАСЫ:МИНУТЫ
        for (var i = 0; i < vm.workingPeriods.length; i++) {
          vm.workingPeriods[i].start = moment
            .utc(moment.duration(vm.workingPeriods[i].start, 'minutes').asMilliseconds())
            .format('HH:mm');
          vm.workingPeriods[i].end = moment
            .utc(moment.duration(vm.workingPeriods[i].end, 'minutes').asMilliseconds())
            .format('HH:mm');
        }

        // Группируем по периоду
        vm.workingPeriods = $filter('groupBy')(vm.workingPeriods, 'days');
      }
    }

    /**
     * Функция сложения двух чисел
     *
     * @param {Number} a Первое число
     * @param {Number} b Второе число
     * @returns {Number}
     */
    function calcSum(a, b) {
      return a + b;
    }

    /**
     * Смена вкладки
     *
     * @param {String} tabValue Название вкладки
     */
    function changeTab(tabValue) {
      //Если в текущей вкладке isSameData == false => поменяли данные и не нажали кнопку "Применить"
      //Поэтому надо загрузить следующую вкладку со старыми данными
      if (!isSameData(vm.currentTab)) {
        // Если endDate и startDate пустые => это самая первая загрузка страницы и старые данные применять не надо
        if (
          vm.currentTab === CONVERSATIONS_STATISTICS_TABS.CHANNELS.VALUE &&
          vm.statisticsOptions.channelsStatisticsOldData.startDate &&
          vm.statisticsOptions.channelsStatisticsOldData.endDate
        ) {
          vm.statisticsOptions.dates.startDate = angular.copy(vm.statisticsOptions.channelsStatisticsOldData.startDate);
          vm.statisticsOptions.dates.endDate = angular.copy(vm.statisticsOptions.channelsStatisticsOldData.endDate);
        } else if (
          vm.currentTab === CONVERSATIONS_STATISTICS_TABS.OPERATORS.VALUE &&
          vm.statisticsOptions.operatorsStatisticsOldData.startDate &&
          vm.statisticsOptions.operatorsStatisticsOldData.endDate
        ) {
          vm.statisticsOptions.dates.startDate = angular.copy(
            vm.statisticsOptions.operatorsStatisticsOldData.startDate,
          );
          vm.statisticsOptions.dates.endDate = angular.copy(vm.statisticsOptions.operatorsStatisticsOldData.endDate);
          vm.statisticsOptions.channels = angular.copy(vm.statisticsOptions.operatorsStatisticsOldData.channels);
        } else if (
          vm.currentTab === CONVERSATIONS_STATISTICS_TABS.GENERAL.VALUE &&
          vm.statisticsOptions.statisticsOldData.startDate &&
          vm.statisticsOptions.statisticsOldData.endDate
        ) {
          vm.statisticsOptions.dates.startDate = angular.copy(vm.statisticsOptions.statisticsOldData.startDate);
          vm.statisticsOptions.dates.endDate = angular.copy(vm.statisticsOptions.statisticsOldData.endDate);
          vm.statisticsOptions.channels = angular.copy(vm.statisticsOptions.statisticsOldData.channels);
        } else if (
          vm.currentTab === CONVERSATIONS_STATISTICS_TABS.VOTE.VALUE &&
          vm.statisticsOptions.voteStatisticsOldData.startDate &&
          vm.statisticsOptions.voteStatisticsOldData.endDate
        ) {
          vm.statisticsOptions.dates.startDate = angular.copy(vm.statisticsOptions.voteStatisticsOldData.startDate);
          vm.statisticsOptions.dates.endDate = angular.copy(vm.statisticsOptions.voteStatisticsOldData.endDate);
          vm.statisticsOptions.channels = angular.copy(vm.statisticsOptions.voteStatisticsOldData.channels);
          vm.statisticsOptions.teamMember = angular.copy(vm.statisticsOptions.voteStatisticsOldData.teamMember);
          vm.votes.vote = angular.copy(vm.statisticsOptions.voteStatisticsOldData.vote);
        } else if (
          CONVERSATIONS_STATISTICS_TABS.TAGS &&
          vm.currentTab === CONVERSATIONS_STATISTICS_TABS.TAGS.VALUE &&
          vm.statisticsOptions.tagsStatisticsOldData.startDate &&
          vm.statisticsOptions.tagsStatisticsOldData.endDate
        ) {
          vm.statisticsOptions.dates.startDate = angular.copy(vm.statisticsOptions.tagsStatisticsOldData.startDate);
          vm.statisticsOptions.dates.endDate = angular.copy(vm.statisticsOptions.tagsStatisticsOldData.endDate);
          vm.statisticsOptions.channels = angular.copy(vm.statisticsOptions.tagsStatisticsOldData.channels);
          vm.statisticsOptions.teamMember = angular.copy(vm.statisticsOptions.tagsStatisticsOldData.teamMember);
        } else if (
          vm.currentTab === CONVERSATIONS_STATISTICS_TABS.SOURCE.VALUE &&
          vm.statisticsOptions.sourceStatisticsOldData.startDate &&
          vm.statisticsOptions.sourceStatisticsOldData.endDate
        ) {
          vm.statisticsOptions.dates.startDate = angular.copy(vm.statisticsOptions.sourceStatisticsOldData.startDate);
          vm.statisticsOptions.dates.endDate = angular.copy(vm.statisticsOptions.sourceStatisticsOldData.endDate);
          vm.sourceTabData.sources = angular.copy(vm.statisticsOptions.sourceStatisticsOldData.sources);
        } else if (
          vm.currentTab === CONVERSATIONS_STATISTICS_TABS.AI_BOT.VALUE &&
          vm.statisticsOptions.sourceStatisticsOldData.startDate &&
          vm.statisticsOptions.sourceStatisticsOldData.endDate
        ) {
          vm.statisticsOptions.dates.startDate = angular.copy(vm.statisticsOptions.aiBotStatisticsOldData.startDate);
          vm.statisticsOptions.dates.endDate = angular.copy(vm.statisticsOptions.aiBotStatisticsOldData.endDate);
          vm.sourceTabData.sources = angular.copy(vm.statisticsOptions.aiBotStatisticsOldData.sources);
        }
      }

      if (tabValue === CONVERSATIONS_STATISTICS_TABS.AI_BOT.VALUE) {
        getAiBotStatistics(vm.statisticsOptions.dates.startDate, vm.statisticsOptions.dates.endDate);
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.CHANNELS.VALUE) {
        getChannelsStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
        );
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.OPERATORS.VALUE) {
        getOperatorsStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
        );
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.GENERAL.VALUE) {
        getStatistics(
          vm.isWorkingTime,
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
        );
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.VOTE.VALUE) {
        getVoteStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.votes.vote,
          vm.statisticsOptions.channels,
          vm.statisticsOptions.teamMember,
        );
      } else if (CONVERSATIONS_STATISTICS_TABS.TAGS && tabValue === CONVERSATIONS_STATISTICS_TABS.TAGS.VALUE) {
        getTagsStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
          vm.statisticsOptions.teamMember,
          vm.tags.contains,
        );
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.SOURCE.VALUE) {
        getSourceStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.sourceTabData.sources,
        );
      }
    }

    /**
     * Смена сортировки в таблице статистики тегов
     *
     * @param {String} field - Поле, по которому необходимо отсортировать
     */
    function changeTagsOrder(field) {
      let isPercentField = false; // Флаг, который опрделеяет запрос сортировки по полю "% от вопросов, помеченных тегами"

      if (field === vm.tags.orderByField) {
        vm.tags.orderBy = vm.tags.orderBy == 'desc' ? 'asc' : 'desc';
      } else {
        vm.tags.orderBy = 'desc';
        vm.tags.orderByField = field;
      }

      // NOTE
      //  Поле "Кол-во вопросов, помеченных этим тегом" - считается на бэке, а поле "% от вопросов, помеченных тегами" - считается на фронте.
      //  Но результат их сортировки одинаковый.
      //  Чтобы сортировка по "% от вопросов, помеченных тегами" работала, подменяем "% от вопросов, помеченных тегами" на "Кол-во вопросов, помеченных этим тегом" и посылаем данные на бэк.
      if (vm.tags.orderByField === TAG_TABLE_HEADERS[2][0]) {
        vm.tags.orderByField = TAG_TABLE_HEADERS[1][0];
        isPercentField = true;
      }

      getTagsStatistics(
        vm.statisticsOptions.dates.startDate,
        vm.statisticsOptions.dates.endDate,
        vm.statisticsOptions.channels,
        vm.statisticsOptions.teamMember,
        vm.tags.contains,
        true,
      );

      // NOTE
      //  После запроса, если запрашили сортировку по полю "% от вопросов, помеченных тегами", возвращаем значение orderByField в нужное "правильное" состояние, чтобы в интерфейсе все было правильно.
      if (isPercentField) {
        vm.tags.orderByField = TAG_TABLE_HEADERS[2][0];
      }
    }

    /** Выгрузка диалогов с AI-ботом */
    function exportAiBotStatistics() {
      conversationStatisticsModel.aiBot.getConversations(
        vm.currentApp.id,
        vm.statisticsOptions.dates.startDate,
        vm.statisticsOptions.dates.endDate,
        CONVERSATION_ASSISTANT_TYPES.CHAT_GPT,
      );
    }

    /**
     * Экспорт статистики по каналам
     */
    function exportChannelsStatistics() {
      trackClickExportChannelsStatistics();

      var columnsToExport = [];

      // FIXME: не знал как написать по-другому, в основном из-за того, что нигде не хранятся выводимые в таблицу колонки
      columnsToExport.push([
        'channel',
        $translate.instant('conversationsStatistics.channelsTab.table.headings.channelName'),
      ]);
      columnsToExport.push(['opened', $translate.instant('conversationsStatistics.channelsTab.table.headings.opened')]);
      columnsToExport.push([
        'opened_by_user',
        $translate.instant('conversationsStatistics.channelsTab.table.headings.openedByUser'),
      ]);
      columnsToExport.push(['closed', $translate.instant('conversationsStatistics.channelsTab.table.headings.closed')]);
      columnsToExport.push([
        'closed_by_user',
        $translate.instant('conversationsStatistics.channelsTab.table.headings.closedByUser'),
      ]);
      columnsToExport.push([
        'not_answered',
        $translate.instant('conversationsStatistics.channelsTab.table.headings.notAnswered'),
      ]);
      columnsToExport.push([
        'lost_customers',
        $translate.instant('conversationsStatistics.channelsTab.table.headings.lostCustomers'),
      ]);
      columnsToExport.push([
        'answer_speed_working_time',
        $translate.instant('conversationsStatistics.channelsTab.table.headings.answerSpeedWorkingTime'),
      ]);
      columnsToExport.push([
        'answer_speed_not_working_time',
        $translate.instant('conversationsStatistics.channelsTab.table.headings.answerSpeedNotWorkingTime'),
      ]);
      columnsToExport.push([
        'dialog_size',
        $translate.instant('conversationsStatistics.channelsTab.table.headings.dialogSize'),
      ]);

      channelModel.statistics.exportAsCsv(
        vm.currentApp.id,
        vm.statisticsOptions.dates.startDate,
        vm.statisticsOptions.dates.endDate,
        vm.statisticsOptions.channels,
        undefined,
        true,
        columnsToExport,
      );
    }

    /**
     * Экспорт статистики по операторам
     */
    function exportOperatorsStatistics() {
      var columnsToExport = [];

      // FIXME: не знал как написать по-другому, в основном из-за того, что нигде не хранятся выводимые в таблицу колонки
      columnsToExport.push([
        'name',
        $translate.instant('conversationsStatistics.operatorsTab.table.headings.operatorName'),
      ]);
      columnsToExport.push([
        'name_internal',
        $translate.instant('conversationsStatistics.operatorsTab.table.headings.operatorNameInternal'),
      ]);
      columnsToExport.push(['vote', $translate.instant('conversationsStatistics.operatorsTab.table.headings.vote')]);
      columnsToExport.push([
        'answer_speed_working_time',
        $translate.instant('conversationsStatistics.operatorsTab.table.headings.answerSpeedWorkingTime'),
      ]);
      columnsToExport.push([
        'answer_speed_not_working_time',
        $translate.instant('conversationsStatistics.operatorsTab.table.headings.answerSpeedNotWorkingTime'),
      ]);
      columnsToExport.push([
        'participated',
        $translate.instant('conversationsStatistics.operatorsTab.table.headings.participated'),
      ]);
      columnsToExport.push([
        'closed',
        $translate.instant('conversationsStatistics.operatorsTab.table.headings.closed'),
      ]);
      columnsToExport.push([
        'not_answered',
        $translate.instant('conversationsStatistics.operatorsTab.table.headings.notAnswered'),
      ]);
      columnsToExport.push([
        'lost_customers',
        $translate.instant('conversationsStatistics.operatorsTab.table.headings.lostCustomers'),
      ]);

      conversationStatisticsModel.operators.exportStatisticsAsCsv(
        vm.currentApp.id,
        vm.statisticsOptions.dates.startDate,
        vm.statisticsOptions.dates.endDate,
        vm.statisticsOptions.channels,
        undefined,
        undefined,
        true,
        columnsToExport,
      );
    }

    /**
     * Экспорт статистики по тегам диалога
     */
    function exportTagsStatistics() {
      conversationStatisticsModel.tags.exportStatisticsAsCsv(
        vm.currentApp.id,
        vm.statisticsOptions.dates.startDate,
        vm.statisticsOptions.dates.endDate,
        vm.statisticsOptions.channels,
        vm.statisticsOptions.teamMember.id,
        vm.tags.contains,
        true,
        vm.tags.orderByField,
        vm.tags.orderBy,
      );
    }

    /**
     * Получение доступа до типа вкладки статистики
     *
     * @param conversationTabType - Тип вкладки
     */
    function getAccessForConversationsStatisticsTabs(conversationTabType) {
      switch (conversationTabType) {
        case CONVERSATIONS_STATISTICS_TABS.CHANNELS.VALUE:
          return vm.accessToAnalyticsConversationsChannels;
        case CONVERSATIONS_STATISTICS_TABS.OPERATORS.VALUE:
          return vm.accessToAnalyticsConversationsOperators;
        case CONVERSATIONS_STATISTICS_TABS.TAGS.VALUE:
          return vm.accessToAnalyticsConversationsTags;
        case CONVERSATIONS_STATISTICS_TABS.SOURCE.VALUE:
          return vm.accessToAnalyticsConversationsSource;
        case CONVERSATIONS_STATISTICS_TABS.VOTE.VALUE:
          return vm.accessToanalyticsConversationsVote;
        default:
          return {
            hasAccess: true,
            denialReason: null,
          };
      }
    }

    /**
     * Получение статистики AI-бота
     *
     * @param {moment} startDate - Дата начала периода
     * @param {moment} endDate - Дата конца периода
     */
    function getAiBotStatistics(startDate, endDate) {
      if (isSameData(CONVERSATIONS_STATISTICS_TABS.AI_BOT.VALUE)) {
        return;
      }

      vm.statisticsOptions.aiBotStatisticsOldData.startDate = angular.copy(startDate);
      vm.statisticsOptions.aiBotStatisticsOldData.endDate = angular.copy(endDate);

      vm.translocoInlineLoader = true;

      $q.all([
        firstValueFrom(conversationStatisticsModel.aiBot.getClosed(vm.currentApp.id, startDate, endDate)),
        firstValueFrom(conversationStatisticsModel.aiBot.getAssignToOperators(vm.currentApp.id, startDate, endDate)),
      ])
        .then((responses) => {
          const answeredOfAiBot = responses[0].data;
          const aiAssignedToOperator = responses[1].data;

          const answeredOfAiBotCount = answeredOfAiBot.data.reduce(calcSum, 0);
          const aiAssignedToOperatorCount = aiAssignedToOperator.data.reduce(calcSum, 0);

          vm.aiBotTabData.allQuestions = answeredOfAiBotCount + aiAssignedToOperatorCount;
          vm.aiBotTabData.answeredOfAiBot = answeredOfAiBotCount;
          vm.aiBotTabData.aiAssignedToOperator = aiAssignedToOperatorCount;

          vm.aiBotTabData.chart.data.labels = answeredOfAiBot.labels;
          vm.aiBotTabData.chart.data.datasets[0].data = answeredOfAiBot.data;
          vm.aiBotTabData.chart.data.datasets[1].data = aiAssignedToOperator.data;

          chartHelper.updateChart(vm.aiBotTabData.chart.instance, {});
        })
        .finally(getStatisticsFinally);

      function getStatisticsFinally() {
        vm.translocoInlineLoader = false;
      }
    }

    /**
     * Получение статистики по средней скорости ответа
     *
     * @param {Boolean} isWorkingTime По какому времени получается статистика: по рабочему (true), по нерабочему (false), по всему (undefined)
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {String[]} channelsIds ID канала
     */
    function getAnswerSpeedStatistics(isWorkingTime, startDate, endDate, channelsIds) {
      return $q
        .all([
          firstValueFrom(
            conversationStatisticsModel.getAnswerSpeed(
              vm.currentApp.id,
              isWorkingTime,
              startDate,
              endDate,
              channelsIds,
            ),
          ),
          firstValueFrom(
            conversationStatisticsModel.getAnswerSpeed(
              vm.currentApp.id,
              isWorkingTime,
              startDate,
              endDate,
              channelsIds,
              CONVERSATION_STATISTICS.RANGES.ALL_PERIOD,
            ),
          ),
        ])
        .then(function (responses) {
          var answerSpeed = responses[0].data;
          var answerSpeedForAllPeriod = responses[1].data;

          var fullTimeUnits = timeUnitService.parseSeconds(answerSpeedForAllPeriod.data.reduce(calcSum, 0));
          for (var i = 0; i < fullTimeUnits.length; i++) {
            fullTimeUnits[i] = {
              value: fullTimeUnits[i].value,
              measure: $translate.instant('services.timeUnit.units.' + fullTimeUnits[i].timeUnit + '.short'),
            };
          }
          vm.answerSpeed.speedForAllPeriod = fullTimeUnits;

          vm.currentRange = answerSpeed.range;
          vm.answerSpeed.chart.data.labels = answerSpeed.labels;
          vm.answerSpeed.chart.data.datasets[0].data = answerSpeed.data;

          chartHelper.updateChart(vm.answerSpeed.chart.instance);
        });
    }

    /**
     * Получение статистики по каналам
     *
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {Object} channels Каналы
     */
    function getChannelsStatistics(startDate, endDate, channels) {
      if (isSameData(CONVERSATIONS_STATISTICS_TABS.CHANNELS.VALUE)) {
        return;
      }

      // Обнуляем данные
      vm.channelsTab = {
        totalOpened: 0,
        chart: {
          type: 'cqDoughnut',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
              },
            ],
          },
          options: {
            count: {
              display: true,
              fontColor: '#5c5cd6',
            },
          },
        },
        tableData: [],
      };
      vm.statisticsOptions.channelsStatisticsOldData.startDate = angular.copy(startDate);
      vm.statisticsOptions.channelsStatisticsOldData.endDate = angular.copy(endDate);

      firstValueFrom(channelModel.statistics.getOpened(vm.currentApp.id, startDate, endDate, channels))
        .then(getStatisticsSuccess)
        .finally(getOpenedFinally);
      firstValueFrom(channelModel.statistics.getOpenedByUser(vm.currentApp.id, startDate, endDate, channels)).then(
        getStatisticsSuccess,
      );
      firstValueFrom(channelModel.statistics.getClosed(vm.currentApp.id, startDate, endDate, channels)).then(
        getStatisticsSuccess,
      );
      firstValueFrom(channelModel.statistics.getClosedByUser(vm.currentApp.id, startDate, endDate, channels)).then(
        getStatisticsSuccess,
      );
      firstValueFrom(channelModel.statistics.getNotAnswered(vm.currentApp.id, startDate, endDate, channels)).then(
        getStatisticsSuccess,
      );
      firstValueFrom(channelModel.statistics.getLostCustomers(vm.currentApp.id, startDate, endDate, channels)).then(
        getStatisticsSuccess,
      );
      firstValueFrom(
        channelModel.statistics.getAnswerSpeedWorkingTime(vm.currentApp.id, startDate, endDate, channels),
      ).then(getStatisticsSuccess);
      firstValueFrom(
        channelModel.statistics.getAnswerSpeedNotWorkingTime(vm.currentApp.id, startDate, endDate, channels),
      ).then(getStatisticsSuccess);
      firstValueFrom(channelModel.statistics.getDialogSize(vm.currentApp.id, startDate, endDate, channels)).then(
        getStatisticsSuccess,
      );

      function getOpenedFinally() {
        var labels = $filter('map')($filter('map')(vm.channelsTab.tableData, 'channel'), 'name'); // Массив из названий каналов
        var openedAnswersArray = $filter('map')($filter('map')(vm.channelsTab.tableData, 'stat'), 'opened'); // Массив из количества новый вопросов в каналах

        vm.channelsTab.totalOpened = vm.channelsTab.tableData.reduce(calcTotalOpened, 0);

        vm.channelsTab.chart.data.labels = labels;
        vm.channelsTab.chart.data.datasets[0].data = openedAnswersArray;

        chartHelper.updateChart(vm.channelsTab.chart.instance, vm.channelsTab.chart.options);
      }

      function getStatisticsSuccess(response) {
        for (var i = 0; i < response.tableData.length; i++) {
          var currentChannel = $filter('filter')(
            vm.channelsTab.tableData,
            { channel: { id: response.tableData[i].channel.id } },
            true,
          )[0]; // Ищем канал, которому добавляем статистику
          if (currentChannel) {
            // NOTE angular.extend не поддерживает deep copy, поэтому заносим данные по каждому каналу отдельно для каждого ключа
            currentChannel.channel = angular.extend({}, currentChannel.channel, response.tableData[i].channel);
            currentChannel.stat = angular.extend({}, currentChannel.stat, response.tableData[i].stat);
          } else {
            vm.channelsTab.tableData.push(response.tableData[i]); // Если канал не нашелся, то добавляем его в список каналов
          }
        }
      }

      function calcTotalOpened(total, channel) {
        return total + channel.stat.opened;
      }
    }

    /**
     * Получение класса для заголовков таблицы с статистикой по тегам
     *
     * NOTE
     *  Каждому столбцу, необходиом устнавоить соответствюущие, разные классы.
     *  Так же, во время сортировки, у сортируемого столбца появляется иконка сортировки, что нарушает его отступы от соседних элементов.
     *  Данное поведение критично для стобца "Кол-во вопросов, помеченных этим тегом", который должен находится вровень с input'ом поиска.
     *
     * @param {String} field - Поле, по которому строится заголовк столбца
     *
     * @return {Object}
     */
    function getClassForTagsStatisticsTableTh(field) {
      return {
        'padding-right-30':
          field === TAG_TABLE_HEADERS[1][0] &&
          [TAG_TABLE_HEADERS[1][0], TAG_TABLE_HEADERS[2][0]].includes(vm.tags.orderByField),
        'padding-right-50': field === TAG_TABLE_HEADERS[1][0] && vm.tags.orderByField === TAG_TABLE_HEADERS[0][0],
        'shrink-by-content': [TAG_TABLE_HEADERS[1][0], TAG_TABLE_HEADERS[2][0]].includes(field),
      };
    }

    /**
     * Получение статистики по решённым вопросам
     *
     * @param {Boolean} isWorkingTime По какому времени получается статистика: по рабочему (true), по нерабочему (false), по всему (undefined)
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {String} channelId ID канала
     */
    function getClosedStatistics(isWorkingTime, startDate, endDate, channelId) {
      return $q
        .all([
          firstValueFrom(
            conversationStatisticsModel.getClosed(vm.currentApp.id, isWorkingTime, startDate, endDate, channelId),
          ),
          firstValueFrom(
            conversationStatisticsModel.getClosed(
              vm.currentApp.id,
              isWorkingTime,
              startDate,
              endDate,
              channelId,
              undefined,
              undefined,
              undefined,
              CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT,
            ),
          ),
        ])
        .then(function (responses) {
          const closed = responses[0].data;
          const closedByRoutingBot = responses[1].data;

          const closedCount = closed.data.reduce(calcSum, 0);
          const closedByRoutingBotCount = closedByRoutingBot.data.reduce(calcSum, 0);

          // в сумме решённых вопросов, нужно учитывать вопросы, решённые роутинг ботом
          vm.closedConversations.closed = closedCount + closedByRoutingBotCount;
          vm.closedConversations.chart.data.labels = closed.labels;
          vm.closedConversations.chart.data.datasets[0].data = closedByRoutingBot.data;
          vm.closedConversations.chart.data.datasets[1].data = closed.data;

          chartHelper.updateChart(vm.closedConversations.chart.instance);
        });
    }

    /**
     * Получение статистики решенных вопросов по источникам
     *
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {CONVERSATION_STATISTICS.SOURCES[]=} sources Список источников
     * @return {*}
     */
    function getSourceClosedStatistics(startDate, endDate, sources) {
      return $q
        .all([
          firstValueFrom(conversationStatisticsModel.sources.getClosed(vm.currentApp.id, startDate, endDate, sources)),
          firstValueFrom(
            conversationStatisticsModel.sources.getClosed(
              vm.currentApp.id,
              startDate,
              endDate,
              sources,
              CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT,
            ),
          ),
        ])
        .then((responses) => {
          const closed = responses[0].data;
          const closedByRoutingBot = responses[1].data;

          const closedCount = closed.data.reduce(calcSum, 0);
          const closedByRoutingBotCount = closedByRoutingBot.data.reduce(calcSum, 0);

          // в сумме решённых вопросов, нужно учитывать вопросы, решённые роутинг ботом
          vm.sourceTabData.closedConversations.closed = closedCount + closedByRoutingBotCount;
          vm.sourceTabData.closedConversations.chart.data.labels = closed.labels;
          vm.sourceTabData.closedConversations.chart.data.datasets[1].data = closed.data;
          vm.sourceTabData.closedConversations.chart.data.datasets[0].data = closedByRoutingBot.data;

          chartHelper.updateChart(vm.sourceTabData.closedConversations.chart.instance);
        });
    }

    /**
     * Получение статистики по средней длине сообщения
     *
     * @param {Boolean} isWorkingTime По какому времени получается статистика: по рабочему (true), по нерабочему (false), по всему (undefined)
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {String} channelId ID канала
     */
    function getConversationSizeStatistics(isWorkingTime, startDate, endDate, channelId) {
      return $q
        .all([
          firstValueFrom(
            conversationStatisticsModel.getConversationSize(
              vm.currentApp.id,
              isWorkingTime,
              startDate,
              endDate,
              channelId,
            ),
          ),
          firstValueFrom(
            conversationStatisticsModel.getConversationSize(
              vm.currentApp.id,
              isWorkingTime,
              startDate,
              endDate,
              channelId,
              CONVERSATION_STATISTICS.RANGES.ALL_PERIOD,
            ),
          ),
        ])
        .then(function (responses) {
          var conversationSize = responses[0].data;
          var conversationSizeForAllPeriod = responses[1].data;

          vm.conversationSize.sizeForAllPeriod = conversationSizeForAllPeriod.data.reduce(calcSum, 0);

          vm.conversationSize.chart.data.labels = conversationSize.labels;
          vm.conversationSize.chart.data.datasets[0].data = conversationSize.data;

          chartHelper.updateChart(vm.conversationSize.chart.instance);
        });
    }

    /**
     * Получение текущй причины отсутствия статистики по тегам
     */
    function getTagStatisticsZeroDataReason() {
      let reason;

      if (vm.tags.contains) {
        reason = vm.TAG_STATISTICS_ZERO_DATA_REASONS.NOT_FOUND;
      } else if (vm.statisticsOptions.channels && vm.statisticsOptions.teamMember.id) {
        reason = vm.TAG_STATISTICS_ZERO_DATA_REASONS.OPERATOR_DIDNT_PUT_ANY_TAG_IN_CHANNEL;
      } else if (vm.statisticsOptions.channels) {
        reason = vm.TAG_STATISTICS_ZERO_DATA_REASONS.NO_TAGS_IN_CHANNEL;
      } else if (vm.statisticsOptions.teamMember.id) {
        reason = vm.TAG_STATISTICS_ZERO_DATA_REASONS.OPERATOR_DIDNT_PUT_ANY_TAG;
      } else {
        reason = vm.TAG_STATISTICS_ZERO_DATA_REASONS.NO_TAGS_FOR_SELECTED_PERIOD;
      }

      return reason;
    }

    /**
     * Получение динамики
     *
     * @param {Number} previous - Значение за предыдущий период
     * @param {Number} current - Значение за текущий период
     * @param {Number=} fractionDigits - Кол-во чисел после запятой
     *
     * @return {Object}
     */
    function getDynamic(previous, current, fractionDigits) {
      let dynamic = {
        isPositive: false, // Направление динамики
        value: current - previous, // Значение динамики
      };

      dynamic.value = fractionDigits ? dynamic.value.toFixed(fractionDigits) : dynamic.value;

      dynamic.value = +`${dynamic.value}`.replace('.0', '');

      if (dynamic.value > 0) {
        dynamic.isPositive = true;
        dynamic.value = `+${dynamic.value}`;
      }

      return dynamic;
    }

    /**
     * Получение статистики по упущенным диалогам
     *
     * @param {Boolean} isWorkingTime По какому времени получается статистика: по рабочему (true), по нерабочему (false), по всему (undefined)
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {String} channelId ID канала
     */
    function getLostStatistics(isWorkingTime, startDate, endDate, channelId) {
      return firstValueFrom(
        conversationStatisticsModel.getLost(vm.currentApp.id, isWorkingTime, startDate, endDate, channelId),
      ).then(function (response) {
        var lost = response.data;

        vm.lostConversations.lost = lost.data.reduce(calcSum, 0);

        vm.lostConversations.chart.data.labels = lost.labels;
        vm.lostConversations.chart.data.datasets[0].data = lost.data;

        chartHelper.updateChart(vm.lostConversations.chart.instance);
      });
    }

    /**
     * Получение дополнительных данных для таблицы статистики
     *
     * @param {moment} startDate - Дата начала периода отображения статистики
     * @param {moment} endDate - Дата конца периода
     * @param {VOTES} vote - Оценка
     * @param {[]} channels - Канал
     * @param {Object} teamMember - Оператор
     * @returns {*}
     */
    function getMoreVotes(startDate, endDate, vote, channels, teamMember) {
      vm.translocoInlineLoader = true;
      firstValueFrom(
        voteModel.getList(
          vm.currentApp.id,
          startDate,
          endDate,
          vote,
          channels,
          teamMember.id,
          vm.votes.paginatorParams,
        ),
      )
        .then(getMoreVotesSuccess)
        .finally(getMoreVotesFinally);

      function getMoreVotesSuccess(data) {
        vm.votes.tableData = vm.votes.tableData.concat(data.votes);
        vm.votes.paginatorParams = data.paginatorParams;
      }

      function getMoreVotesFinally() {
        vm.translocoInlineLoader = false;
      }
    }

    /**
     * Получение статистики по неотвеченным диалогам
     *
     * @param {Boolean} isWorkingTime По какому времени получается статистика: по рабочему (true), по нерабочему (false), по всему (undefined)
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {String} channelId ID канала
     */
    function getNotAnsweredStatistics(isWorkingTime, startDate, endDate, channelId) {
      return firstValueFrom(
        conversationStatisticsModel.getNotAnswered(vm.currentApp.id, isWorkingTime, startDate, endDate, channelId),
      ).then(function (response) {
        var notAnswered = response.data;

        vm.notAnsweredConversations.notAnswered = notAnswered.data.reduce(calcSum, 0);

        vm.notAnsweredConversations.chart.data.labels = notAnswered.labels;
        vm.notAnsweredConversations.chart.data.datasets[0].data = notAnswered.data;

        chartHelper.updateChart(vm.notAnsweredConversations.chart.instance);
      });
    }

    /**
     * Получение статистики по открытым диалогам
     *
     * @param {Boolean} isWorkingTime По какому времени получается статистика: по рабочему (true), по нерабочему (false), по всему (undefined)
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {String[]} channelsIds ID'ы каналов
     */
    function getOpenedStatistics(isWorkingTime, startDate, endDate, channelsIds) {
      $q.all([
        firstValueFrom(
          conversationStatisticsModel.getOpened(vm.currentApp.id, isWorkingTime, startDate, endDate, channelsIds),
        ),
        firstValueFrom(
          conversationStatisticsModel.getOpened(
            vm.currentApp.id,
            isWorkingTime,
            startDate,
            endDate,
            channelsIds,
            undefined,
            undefined,
            undefined,
            CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT,
          ),
        ),
      ]).then(function (responses) {
        const opened = responses[0].data;
        const openedByRoutingBot = responses[1].data;

        // Открыто диалогов с учетом роутинг бота
        const realOpened = {
          data: [],
          labels: opened.labels,
        };

        // Суммируем значения для opened и openedAssistantTypeRoutingBot
        for (let i = 0; i < opened.data.length; i++) {
          realOpened.data.push(opened.data[i] + openedByRoutingBot.data[i]);
        }

        vm.openedConversations.opened = realOpened.data.reduce(calcSum, 0);
        vm.openedConversations.chart.data.labels = realOpened.labels;
        vm.openedConversations.chart.data.datasets[0].data = realOpened.data;

        chartHelper.updateChart(vm.openedConversations.chart.instance);
      });
    }

    /**
     * Получение статистики открытых вопросов по источникам
     *
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {CONVERSATION_STATISTICS.SOURCES[]=} sources Список источников
     * @return {*}
     */
    function getSourceOpenedStatistics(startDate, endDate, sources) {
      return $q
        .all([
          firstValueFrom(conversationStatisticsModel.sources.getOpened(vm.currentApp.id, startDate, endDate, sources)),
          firstValueFrom(
            conversationStatisticsModel.sources.getOpened(
              vm.currentApp.id,
              startDate,
              endDate,
              sources,
              CONVERSATION_ASSISTANT_TYPES.ROUTING_BOT,
            ),
          ),
        ])
        .then((responses) => {
          const opened = responses[0].data;
          const openedByRoutingBot = responses[1].data;

          // Открыто диалогов с учетом роутинг бота
          const realOpened = {
            data: [],
            labels: opened.labels,
          };

          // Суммируем значения для opened и openedAssistantTypeRoutingBot
          for (let i = 0; i < opened.data.length; i++) {
            realOpened.data.push(opened.data[i] + openedByRoutingBot.data[i]);
          }

          vm.sourceTabData.openedConversations.opened = realOpened.data.reduce(calcSum, 0);
          vm.sourceTabData.openedConversations.chart.data.labels = realOpened.labels;
          vm.sourceTabData.openedConversations.chart.data.datasets[0].data = realOpened.data;

          chartHelper.updateChart(vm.sourceTabData.openedConversations.chart.instance);
        });
    }

    /**
     * Получение статистики по операторам
     *
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {[]} channels Канал
     */
    function getOperatorsStatistics(startDate, endDate, channels) {
      if (isSameData(CONVERSATIONS_STATISTICS_TABS.OPERATORS.VALUE)) {
        return;
      }

      // Очищаем данные предыдущих вопросов
      vm.operators.tableData = [];
      vm.operators.zeroDataReason = null;
      vm.translocoInlineLoader = true;

      vm.statisticsOptions.operatorsStatisticsOldData.startDate = angular.copy(startDate);
      vm.statisticsOptions.operatorsStatisticsOldData.endDate = angular.copy(endDate);
      vm.statisticsOptions.operatorsStatisticsOldData.channels = angular.copy(channels);

      firstValueFrom(
        conversationStatisticsModel.operators.getParticipated(vm.currentApp.id, startDate, endDate, channels),
      )
        .then(getParticipatedOperatorsStatisticsSuccess)
        .finally(getOperatorsStatisticsFinally);

      function getParticipatedOperatorsStatisticsSuccess(response) {
        $q.all([
          firstValueFrom(conversationStatisticsModel.operators.getVote(vm.currentApp.id, startDate, endDate, channels)),
          firstValueFrom(
            conversationStatisticsModel.operators.getAnswerSpeedWorkingTime(
              vm.currentApp.id,
              startDate,
              endDate,
              channels,
            ),
          ),
          firstValueFrom(
            conversationStatisticsModel.operators.getAnswerSpeedNotWorkingTime(
              vm.currentApp.id,
              startDate,
              endDate,
              channels,
            ),
          ),
          firstValueFrom(
            conversationStatisticsModel.operators.getClosed(vm.currentApp.id, startDate, endDate, channels),
          ),
        ]).then(getOperatorsStatisticsSuccess.bind(null, response.data));
      }

      function getOperatorsStatisticsSuccess(participatedOperators, response) {
        for (let i = 0; i < participatedOperators.length; i++) {
          if (participatedOperators[i].stat.participated > 0 || response[3].data[i].stat.closed > 0) {
            let currentOperator = {
              user: participatedOperators[i].user,
              stat: participatedOperators[i].stat,
            };

            currentOperator.user = angular.extend(
              {},
              currentOperator.user,
              response[0].data[i].user,
              response[1].data[i].user,
              response[2].data[i].user,
              response[3].data[i].user,
            );
            currentOperator.stat = angular.extend(
              {},
              currentOperator.stat,
              response[0].data[i].stat,
              response[1].data[i].stat,
              response[2].data[i].stat,
              response[3].data[i].stat,
            );

            vm.operators.tableData.push(currentOperator);
          }
        }

        if (vm.operators.tableData.length === 0) {
          vm.operators.zeroDataReason = getOperatorsStatisticsZeroDataReason();
        }
      }

      function getOperatorsStatisticsFinally() {
        vm.translocoInlineLoader = false;
      }
    }

    /**
     * Получение текущй причины отсутствия статистики по операторам
     */
    function getOperatorsStatisticsZeroDataReason() {
      let reason;

      if (vm.statisticsOptions.channels) {
        reason = OPERATORS_STATISTICS_ZERO_DATA_REASONS.NO_OPERATORS_IN_CHANNEL;
      } else {
        reason = OPERATORS_STATISTICS_ZERO_DATA_REASONS.NO_OPERATORS_FOR_SELECTED_PERIOD;
      }

      return reason;
    }

    /**
     * Получение процентного соотношения
     *
     * @param {Number} numerator - Числитель
     * @param {Number} denominator - Знаменатель
     * @param {Number} fractionDigits - Кол-во чисел после запятой
     */
    function getPercent(numerator, denominator, fractionDigits) {
      let percent =
        numerator === 0 || denominator === 0 ? 0 : $filter('percent')(numerator, denominator).toFixed(fractionDigits);

      percent = +`${percent}`.replace('.0', '');

      return percent;
    }

    /**
     * Получение кучи статистических данных
     *
     * @param {Boolean} isWorkingTime По какому времени получается статистика: по рабочему (true), по нерабочему (false), по всему (undefined)
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {Object} channels Каналы
     */
    function getStatistics(isWorkingTime, startDate, endDate, channels) {
      if (isSameData(CONVERSATIONS_STATISTICS_TABS.GENERAL.VALUE)) {
        return;
      }

      vm.statisticsOptions.statisticsOldData.startDate = angular.copy(startDate);
      vm.statisticsOptions.statisticsOldData.endDate = angular.copy(endDate);
      vm.statisticsOptions.statisticsOldData.channels = angular.copy(channels);

      vm.translocoInlineLoader = true;

      $q.all([
        getAnswerSpeedStatistics(isWorkingTime, startDate, endDate, channels),
        getClosedStatistics(undefined, startDate, endDate, channels),
        getConversationSizeStatistics(undefined, startDate, endDate, channels),
        //getLostStatistics(undefined, startDate, endDate, channels),
        //getNotAnsweredStatistics(undefined, startDate, endDate, channels),
        getOpenedStatistics(undefined, startDate, endDate, channels),
        getWorkload(undefined, startDate, endDate, channels),
      ]).finally(getStatisticsFinally);

      function getStatisticsFinally() {
        vm.translocoInlineLoader = false;
      }
    }

    /**
     * Получение статистики по тегам
     *
     * @param {moment} startDate - Дата начала периода
     * @param {moment} endDate - Дата конца периода
     * @param {[]} channels - Выбранный канал
     * @param {Object} teamMember - Выбранный оператор
     * @param {String} tagContains - Поисковый запрос
     * @param {Boolean=} skipSameDate - Пропуск проверки на изменение фильтра
     */
    function getTagsStatistics(startDate, endDate, channels, teamMember, tagContains, skipSameDate) {
      if (isSameData(CONVERSATIONS_STATISTICS_TABS.TAGS && CONVERSATIONS_STATISTICS_TABS.TAGS.VALUE) && !skipSameDate) {
        return;
      }

      vm.statisticsOptions.tagsStatisticsOldData.startDate = angular.copy(startDate);
      vm.statisticsOptions.tagsStatisticsOldData.endDate = angular.copy(endDate);
      vm.statisticsOptions.tagsStatisticsOldData.channels = angular.copy(channels);
      vm.statisticsOptions.tagsStatisticsOldData.teamMember = angular.copy(teamMember);

      vm.translocoInlineLoader = true;

      // Считаем запрашиваемый период в днях
      vm.tags.daysPeriodCounter = moment(endDate).diff(moment(startDate), 'days') + 1;

      // Считаем стартовую и конечную дату прошлого периода
      vm.tags.previousStatistics.startDate = moment(startDate).subtract(vm.tags.daysPeriodCounter, 'days');
      vm.tags.previousStatistics.endDate = moment(endDate).subtract(vm.tags.daysPeriodCounter, 'days');

      $q.all([
        firstValueFrom(conversationTagsModel.getList(vm.currentApp.id)),
        firstValueFrom(
          conversationStatisticsModel.tags.getStatistics(
            vm.currentApp.id,
            moment(startDate).subtract(vm.tags.daysPeriodCounter, 'days'),
            moment(endDate).subtract(vm.tags.daysPeriodCounter, 'days'),
            channels,
            teamMember.id,
            tagContains,
            false,
            vm.tags.orderByField,
            vm.tags.orderBy,
          ),
        ),
        firstValueFrom(
          conversationStatisticsModel.tags.getStatistics(
            vm.currentApp.id,
            startDate,
            endDate,
            channels,
            teamMember.id,
            tagContains,
            false,
            vm.tags.orderByField,
            vm.tags.orderBy,
          ),
        ),
      ])
        .then(getTagsStatisticsSuccess)
        .finally(getTagsStatisticsFinally);

      /**
       * Успешное выполенние всех запросов по статистике тегов
       *
       * @param {Object} response - Информация о тегах и статистики по тегам
       */
      function getTagsStatisticsSuccess(response) {
        let tags = response[0];
        let previousTagsStats = response[1].data;
        let currentTagsStats = response[2].data;

        // Очищаем данные предыдущих вопросов
        vm.tags.tableData = [];
        vm.tags.zeroDataReason = null;

        // Если у app'а вообще нет тегов - показываем zero-dat'у
        if (tags.length === 0) {
          vm.tags.zeroDataReason = TAG_STATISTICS_ZERO_DATA_REASONS.NO_TAG_IN_APP;
          return;
        }

        // Если у app'а нет статистиики по тегам за указанный период
        if (currentTagsStats['tagStats'].length === 0) {
          vm.tags.currentStatistics.totalConversations = 0;
          vm.tags.currentStatistics.totalConversationsWithTags = 0;

          // Считаем статистику по тегам по всем вопросам за предыдущий период, равному указанному периоду
          vm.tags.previousStatistics.totalPercentConversationsWithTags = previousTagsStats['tagStats'].length
            ? getPercent(previousTagsStats['partGroupsWithTags'], previousTagsStats['partGroupsTotal'], 1)
            : 0;

          vm.tags.zeroDataReason = getTagStatisticsZeroDataReason();
          return;
        }

        // Считаем статистику по тегам за указанный период по всем вопросам
        vm.tags.currentStatistics.totalConversations = currentTagsStats['partGroupsTotal'];
        vm.tags.currentStatistics.totalConversationsWithTags = currentTagsStats['partGroupsWithTags'];
        vm.tags.currentStatistics.totalPercentConversationsWithTags = getPercent(
          vm.tags.currentStatistics.totalConversationsWithTags,
          vm.tags.currentStatistics.totalConversations,
          1,
        );

        // Считаем статистику по тегам по всем вопросам за предыдущий период, равному указанному периоду
        vm.tags.previousStatistics.totalPercentConversationsWithTags = previousTagsStats['tagStats'].length
          ? getPercent(previousTagsStats['partGroupsWithTags'], previousTagsStats['partGroupsTotal'], 1)
          : 0;

        currentTagsStats['tagStats'].forEach((currentStatisticsItem) => {
          // Считаем процентное соотношение количества вопросов с тегом к общему количеству вопросов помеченных тегами
          currentStatisticsItem.percent = getPercent(
            currentStatisticsItem.counter,
            vm.tags.currentStatistics.totalConversationsWithTags,
            1,
          );

          // Получаем статистику по такому же тегу за предыдущий период и если она есть - считам динамику
          let previousStatisticsItem = previousTagsStats['tagStats'].filter(
            (item) => item.name === currentStatisticsItem.name,
          )[0];
          if (previousStatisticsItem) {
            currentStatisticsItem.counterDynamic = getDynamic(
              previousStatisticsItem.counter,
              currentStatisticsItem.counter,
            );
            previousStatisticsItem.percent = getPercent(
              previousStatisticsItem.counter,
              previousTagsStats['partGroupsWithTags'],
              1,
            );
            currentStatisticsItem.percentDynamic = getDynamic(
              previousStatisticsItem.percent,
              currentStatisticsItem.percent,
              1,
            );
          } else {
            // Если за прошлый период тега не было, показываем динамику только в абсолютной величене
            currentStatisticsItem.counterDynamic = getDynamic(0, currentStatisticsItem.counter);
          }

          vm.tags.tableData.push(currentStatisticsItem);
        });
      }

      /**
       * Финальная функция после выполеннеия всех запросов по статистике тегов
       */
      function getTagsStatisticsFinally() {
        vm.translocoInlineLoader = false;
      }
    }

    /**
     * Получение статистики по источникам
     *
     * @param {moment} startDate - Дата начала периода
     * @param {moment} endDate - Дата конца периода
     * @param {CONVERSATION_STATISTICS.SOURCES[]} sources - Источник
     */
    function getSourceStatistics(startDate, endDate, sources) {
      if (isSameData(CONVERSATIONS_STATISTICS_TABS.SOURCE.VALUE)) {
        return;
      }

      vm.statisticsOptions.sourceStatisticsOldData.startDate = angular.copy(startDate);
      vm.statisticsOptions.sourceStatisticsOldData.endDate = angular.copy(endDate);
      vm.statisticsOptions.sourceStatisticsOldData.sources = sources;

      vm.translocoInlineLoader = true;

      $q.all([
        getSourceClosedStatistics(startDate, endDate, sources),
        getSourceOpenedStatistics(startDate, endDate, sources),
      ]).finally(getStatisticsFinally);

      function getStatisticsFinally() {
        vm.translocoInlineLoader = false;
      }
    }

    /**
     * Получение текста тултипа для динаимики статистики тега
     *
     * @param {Number} daysPeriodCounter - Количество дней установленном в периоде
     */
    function getTooltipTextForTagStatisticDynamic(daysPeriodCounter) {
      let translateParams = {
        startDatePreviousPeriod: vm.tags.previousStatistics.startDate.format('L'),
        endDatePreviousPeriod: vm.tags.previousStatistics.endDate.format('L'),
      };

      return $translate.instant(
        'conversationsStatistics.tagsTab.table.tooltips.dynamic.text',
        translateParams,
        'messageformat',
      );
    }

    /**
     * Получение статистики по оценкам
     */
    function getVoteStatistics(startDate, endDate, vote, channels, teamMember) {
      if (isSameData(CONVERSATIONS_STATISTICS_TABS.VOTE.VALUE)) {
        return;
      }

      vm.statisticsOptions.voteStatisticsOldData.startDate = angular.copy(startDate);
      vm.statisticsOptions.voteStatisticsOldData.endDate = angular.copy(endDate);
      vm.statisticsOptions.voteStatisticsOldData.channels = angular.copy(channels);
      vm.statisticsOptions.voteStatisticsOldData.teamMember = angular.copy(teamMember);
      vm.statisticsOptions.voteStatisticsOldData.vote = angular.copy(vote);

      vm.translocoInlineLoader = true;

      $q.all([
        firstValueFrom(voteModel.getList(vm.currentApp.id, startDate, endDate, vote, channels, teamMember.id)),
        firstValueFrom(voteModel.getStatistics(vm.currentApp.id, startDate, endDate, channels, teamMember.id)),
      ])
        .then(getVoteStatisticsSuccess)
        .finally(getVoteStatisticsFinally);

      function getVoteStatisticsSuccess(response) {
        vm.votes.tableData = response[0].votes;
        vm.votes.paginatorParams = response[0].paginatorParams;

        vm.votes.voteStats = response[1];
        vm.votes.voteStatsSum = getVoteStatsSum(vm.votes.voteStats);
        vm.votes.fullVoteStatsSum = getVoteStatsSum(vm.votes.voteStats, true);
      }

      function getVoteStatisticsFinally() {
        vm.translocoInlineLoader = false;
      }
    }

    /**
     * Получение суммы голосов оценок операторов
     *
     * @param {Object} voteStats - Статистика голосов
     * @param {Boolean=} includeNotVoted - Учитывать не оцененые
     * @returns {number}
     */
    function getVoteStatsSum(voteStats, includeNotVoted) {
      var sum = 0;
      for (var vote in voteStats) {
        if (
          voteStats.hasOwnProperty(vote) &&
          (parseInt(vote) !== VOTES.NOT_VOTED || (includeNotVoted && parseInt(vote) === VOTES.NOT_VOTED))
        ) {
          sum += voteStats[vote];
        }
      }
      return sum;
    }

    /**
     * Получение загруженности по часам
     *
     * @param {Boolean} isWorkingTime По какому времени получается статистика: по рабочему (true), по нерабочему (false), по всему (undefined)
     * @param {moment} startDate Дата начала периода отображения статистики
     * @param {moment} endDate Дата конца периода
     * @param {String} channelId ID канала
     */
    function getWorkload(isWorkingTime, startDate, endDate, channelId) {
      return firstValueFrom(
        conversationStatisticsModel.getOpened(
          vm.currentApp.id,
          isWorkingTime,
          startDate,
          endDate,
          channelId,
          CONVERSATION_STATISTICS.RANGES.HOUR,
        ),
      ).then(function (response) {
        var workload = response.data;
        var currentDataset = []; // текущий датасет для графика (содержит загруженность по конкретному дню / дню недели)
        var datasetStructure = {
          // стрктура датасета для графика
          label: '', // короткое название дня недели
          data: [
            // загруженность по часам, каждый элемент - 3 часа в дне (0:00 - 3:00, 3:00 - 6:00 и т.д.)
            [
              0, // сколько вопросов было решено в эти часы
              0, // количество вхождений дня недели c этими часами в выборку (для нахождения среднего, на это число поделится количество вопросов в эти часы, если количество дней в запрашиваемой статистике больше 7)
            ],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
          ],
        };
        var dayData = []; // данные о загруженности по каждому часу конкретного дня
        var maxWorkload = {
          // данные о максимальной загруженности
          dataIndex: 0, // индекс самых загруженных часов (нужен для получения подписи по оси Y)
          dayLabel: '', // название дня недели / дня и месяца
          max: 0, // значение максимальной загруженности
        };
        var parsedWorkloadLabels = [];

        vm.workload.chart.data.datasets = [];

        // NOTE: этот алгоритм нужно целиком переписать к чертям собачьим, он дерьмовый((
        // если разница в датах больше 7 дней - отображаются дни недели, если меньше - отображаются даты
        if (endDate.diff(startDate, 'days') >= 7) {
          var weekDays = moment.weekdays(true);
          var shortWeekDays = moment.weekdaysShort(true);

          // инициализация датасетов по дням недели и заполнение надписей
          for (var i = 0; i < shortWeekDays.length; i++) {
            currentDataset = angular.copy(datasetStructure);
            currentDataset.label = shortWeekDays[i];
            vm.workload.chart.data.datasets.push(currentDataset);
          }

          // суммируем полученную статистику с бэкенда по дням и по 3 часа, а так же запоминаем сколько раз в один и тот же день недели попадался трёхчасовой промежуток
          for (var i = 0; i < endDate.diff(startDate, 'days') + 1; i++) {
            var weekDayIndex = moment(startDate).add(i, 'days').format('e');
            dayData = workload.data.slice(i * 24, i * 24 + 24);

            for (var j = 0, k = 0; j < dayData.length; j += 3, k++) {
              vm.workload.chart.data.datasets[weekDayIndex].data[k][0] += dayData.slice(j, j + 3).reduce(calcSum, 0);
              vm.workload.chart.data.datasets[weekDayIndex].data[k][1]++;
            }
          }

          for (let i = 0; i < vm.workload.chart.data.datasets.length; i++) {
            for (let j = 0; j < vm.workload.chart.data.datasets[i].data.length; j++) {
              if (vm.workload.chart.data.datasets[i].data[j][1] === 0) {
                vm.workload.chart.data.datasets[i].data[j] = 0;
              } else {
                // заменяем массив из двух элементов (с суммой диалогов по часам и количеству этих часов в текущем дне недели) на среднюю загруженность по текущим часам j в текущий день недели i
                // округление производится к ближайшему целому
                vm.workload.chart.data.datasets[i].data[j] = Math.round(
                  vm.workload.chart.data.datasets[i].data[j][0] / vm.workload.chart.data.datasets[i].data[j][1],
                );
              }

              if (maxWorkload.max < vm.workload.chart.data.datasets[i].data[j]) {
                maxWorkload.max = vm.workload.chart.data.datasets[i].data[j];
                maxWorkload.dayLabel = weekDays[i];
                maxWorkload.dataIndex = j;
              }
            }
          }

          vm.workload.chart.data.labels = shortWeekDays;
        } else {
          for (var i = 0; i < endDate.diff(startDate, 'days') + 1; i++) {
            currentDataset = angular.copy(datasetStructure);
            currentDataset.label = moment(startDate).add(i, 'days').format('L');
            vm.workload.chart.data.datasets.push(currentDataset);
            parsedWorkloadLabels.push(moment(startDate).add(i, 'days').format('L'));
            dayData = workload.data.slice(i * 24, i * 24 + 24);

            for (var j = 0, k = 0; j < dayData.length; j += 3, k++) {
              vm.workload.chart.data.datasets[i].data[k][0] += dayData.slice(j, j + 3).reduce(calcSum, 0);
              vm.workload.chart.data.datasets[i].data[k] = vm.workload.chart.data.datasets[i].data[k][0];

              if (maxWorkload.max < vm.workload.chart.data.datasets[i].data[k]) {
                maxWorkload.max = vm.workload.chart.data.datasets[i].data[k];
                maxWorkload.dayLabel = moment(startDate).add(i, 'days').format('D MMMM');
                maxWorkload.dataIndex = k;
              }
            }
          }
          vm.workload.chart.data.labels = parsedWorkloadLabels;
        }

        chartHelper.updateChart(vm.workload.chart.instance);

        // вот тут внимательно: часы самого загруженного времени берутся из подписи оси Y графика, поэтому нужно дождаться пока график инициализируется, и только тогда доставать из него подпись оси
        if (vm.workload.chart.instance) {
          refreshMostBusyTime(vm.workload.chart.instance);
        } else {
          var chartInstanceWatcher = $scope.$watch('vm.workload.chart.instance', function (newValue) {
            if (newValue) {
              refreshMostBusyTime(newValue);
              // удаляем watcher после того, как график инициализирован
              chartInstanceWatcher();
            }
          });
        }

        function refreshMostBusyTime(chartInstance) {
          var timeLabel = chartInstance.scales[chartInstance.options.scales.yAxes[0].id].ticks[maxWorkload.dataIndex];
          vm.workload.mostBusyTime = maxWorkload.max
            ? caseStyleHelper.toSentenceCase(maxWorkload.dayLabel) + ', ' + timeLabel
            : $translate.instant('conversationsStatistics.generalTab.workload.mostBusyTime.defaultValue');
        }
      });
    }

    /**
     * Проверка, есть ли доступ до типа вкладки статистики
     *
     * @param conversationTabType - Тип вкладки
     *
     * @return {boolean}
     */
    function hasAccessForConversationsStatisticsTabs(conversationTabType) {
      switch (conversationTabType) {
        case CONVERSATIONS_STATISTICS_TABS.AI_BOT.VALUE:
          return vm.accessToAiBot.hasAccess || featureModel.hasAccess(FEATURES.CHAT_GPT);
        case CONVERSATIONS_STATISTICS_TABS.CHANNELS.VALUE:
          return vm.accessToAnalyticsConversationsChannels.hasAccess;
        case CONVERSATIONS_STATISTICS_TABS.OPERATORS.VALUE:
          return vm.accessToAnalyticsConversationsOperators.hasAccess;
        case CONVERSATIONS_STATISTICS_TABS.SOURCE.VALUE:
          return vm.accessToAnalyticsConversationsSource.hasAccess;
        case CONVERSATIONS_STATISTICS_TABS.TAGS.VALUE:
          return vm.accessToAnalyticsConversationsTags.hasAccess;
        case CONVERSATIONS_STATISTICS_TABS.VOTE.VALUE:
          return vm.accessToanalyticsConversationsVote.hasAccess;
        default:
          return true;
      }
    }

    /**
     * Проверка на изменение данных
     *
     * @param {String} currentTab Название вкладки
     * @returns {Boolean}
     */
    function isSameData(currentTab) {
      if (currentTab === CONVERSATIONS_STATISTICS_TABS.AI_BOT.VALUE) {
        return (
          vm.statisticsOptions.dates.startDate.isSame(vm.statisticsOptions.aiBotStatisticsOldData.startDate) &&
          vm.statisticsOptions.dates.endDate.isSame(vm.statisticsOptions.aiBotStatisticsOldData.endDate)
        );
      } else if (currentTab === CONVERSATIONS_STATISTICS_TABS.CHANNELS.VALUE) {
        return (
          vm.statisticsOptions.dates.startDate.isSame(vm.statisticsOptions.channelsStatisticsOldData.startDate) && // @formatter:off
          vm.statisticsOptions.dates.endDate.isSame(vm.statisticsOptions.channelsStatisticsOldData.endDate)
        ); // @formatter:on
      } else if (currentTab === CONVERSATIONS_STATISTICS_TABS.OPERATORS.VALUE) {
        return (
          vm.statisticsOptions.dates.startDate.isSame(vm.statisticsOptions.operatorsStatisticsOldData.startDate) && // @formatter:off
          vm.statisticsOptions.dates.endDate.isSame(vm.statisticsOptions.operatorsStatisticsOldData.endDate) &&
          angular.equals(vm.statisticsOptions.operatorsStatisticsOldData.channels, vm.statisticsOptions.channels)
        ); // @formatter:on
      } else if (currentTab === CONVERSATIONS_STATISTICS_TABS.GENERAL.VALUE) {
        return (
          vm.statisticsOptions.dates.startDate.isSame(vm.statisticsOptions.statisticsOldData.startDate) && // @formatter:off
          vm.statisticsOptions.dates.endDate.isSame(vm.statisticsOptions.statisticsOldData.endDate) &&
          angular.equals(vm.statisticsOptions.statisticsOldData.channels, vm.statisticsOptions.channels)
        ); // @formatter:on
      } else if (currentTab === CONVERSATIONS_STATISTICS_TABS.VOTE.VALUE) {
        return (
          vm.statisticsOptions.dates.startDate.isSame(vm.statisticsOptions.voteStatisticsOldData.startDate) && // @formatter:off
          vm.statisticsOptions.dates.endDate.isSame(vm.statisticsOptions.voteStatisticsOldData.endDate) &&
          angular.equals(vm.statisticsOptions.voteStatisticsOldData.channels, vm.statisticsOptions.channels) &&
          angular.equals(vm.statisticsOptions.voteStatisticsOldData.teamMember, vm.statisticsOptions.teamMember) &&
          angular.equals(vm.statisticsOptions.voteStatisticsOldData.vote, vm.votes.vote)
        ); // @formatter:on
      } else if (CONVERSATIONS_STATISTICS_TABS.TAGS && currentTab === CONVERSATIONS_STATISTICS_TABS.TAGS.VALUE) {
        return (
          vm.statisticsOptions.dates.startDate.isSame(vm.statisticsOptions.tagsStatisticsOldData.startDate) && // @formatter:off
          vm.statisticsOptions.dates.endDate.isSame(vm.statisticsOptions.tagsStatisticsOldData.endDate) &&
          angular.equals(vm.statisticsOptions.tagsStatisticsOldData.channels, vm.statisticsOptions.channels) &&
          angular.equals(vm.statisticsOptions.tagsStatisticsOldData.teamMember, vm.statisticsOptions.teamMember)
        ); // @formatter:on
      } else if (currentTab === CONVERSATIONS_STATISTICS_TABS.SOURCE.VALUE) {
        return (
          vm.statisticsOptions.dates.startDate.isSame(vm.statisticsOptions.sourceStatisticsOldData.startDate) &&
          vm.statisticsOptions.dates.endDate.isSame(vm.statisticsOptions.sourceStatisticsOldData.endDate) &&
          angular.equals(vm.statisticsOptions.sourceStatisticsOldData.sources, vm.sourceTabData.sources)
        );
      }
    }

    /**
     * Функция для построения легенды в графиках
     *
     * @param {Object} chart Инстанс графика ChartJs
     * @returns {String}
     */
    function legendCallback(chart) {
      var text = [];

      text.push('<div class="flex align-items-center justify-center margin-between-cols-15">');
      for (var i = 0; i < chart.data.datasets.length; i++) {
        text.push('<small class="flex align-items-center margin-between-cols-5">');
        text.push(
          `<span class="legend-box" style="background: ${chart.data.datasets[i].backgroundColor}; border: 1px solid ${chart.data.datasets[i].borderColor};"></span>`,
        );
        if (chart.data.datasets[i].label) {
          text.push('<span>' + chart.data.datasets[i].label + '</span>');
        }
        text.push('</small>');
      }
      text.push('</div>');

      return text.join('');
    }

    /**
     * Выбран ли хоть один канал
     *
     * @returns {boolean}
     */
    function notSelectedChannels() {
      return vm.statisticsOptions.channels && vm.statisticsOptions.channels.length === 0;
    }

    /**
     * Выбран хотя бы один источник
     *
     * @returns {boolean}
     */
    function notSelectedSources() {
      return vm.sourceTabData.sources && vm.sourceTabData.sources.length === 0;
    }

    /**
     * Отправка формы поиска по тегам
     *
     * @param {Boolean} isValid - Валидна ли форма
     * @param {String} containsPhrase - Поисковая фраза
     */
    function submitSearchForm(isValid, containsPhrase) {
      if (isValid && vm.tags.currentContainsPhrase !== containsPhrase) {
        vm.tags.currentContainsPhrase = containsPhrase;
        getTagsStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
          vm.statisticsOptions.teamMember,
          vm.tags.contains,
          true,
        );
      }
    }

    /**
     * Трек клика на "рабочее время"/"нерабочее время"
     */
    function trackClickChangeWorkingTime() {
      carrotquestHelper.track('Статистика диалогов - клик на переключение рабочего / нерабочего времени', {
        App: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на "открыть диалог"
     */
    function trackClickConversation() {
      carrotquestHelper.track('Статистика диалогов - кликнул "Открыть диалог" в качестве поддержки', {
        App: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на "Применить фильтр"
     */
    function trackClickApplyFilterButton() {
      carrotquestHelper.track('Статистика диалогов - Кликнул на "Применить фильтр"', {
        app: vm.currentApp.name,
        Раздел: vm.currentTab,
      });
    }

    /**
     * Трек клика на экспорт статистики по диалогам с AI-ботом
     */
    function trackClickExportAiBotStatistics() {
      carrotquestHelper.track('Статистика диалогов - кликнул "Выгрузить диалоги с AI-ботом"');
    }

    /**
     * Трек клика на экспорт статистики по каналам
     */
    function trackClickExportChannelsStatistics() {
      carrotquestHelper.track('Статистика диалогов - кликнул "Скачать статистику по каналам"');
    }

    /**
     * Трек клика на экспорт статистики по операторам
     */
    function trackClickExportOperatorsStatistics() {
      carrotquestHelper.track('Статистика диалогов - кликнул "Скачать статистику по операторам"');
    }

    /**
     * Трек клика на экспорт статистики по тегам диалогов
     */
    function trackClickExportTagsStatistics() {
      carrotquestHelper.track('Статистика диалогов - кликнул "Скачать статистику по тегам диалогов"');
    }

    function trackClickTuneWorkingTime() {
      carrotquestHelper.track(
        'Статистика диалогов - клик на "Настроить время работы" в статистике рабочего / нерабочего времени',
        { App: vm.currentApp.name },
      );
    }

    /**
     * Трек изменения канала
     */
    function trackChannelChange() {
      carrotquestHelper.track('Статистика диалогов - выбрал канал', { Раздел: vm.currentTab });
    }

    /**
     * Трек изменения фильтра по оценке
     *
     * @param {VOTES} vote - Оценка
     */
    function trackVoteFilterChange(vote) {
      carrotquestHelper.track('Статистика диалогов - применил фильтр по вопросам с оценкой', {
        app: vm.currentApp.name,
        Фильтр: vote,
      });
    }

    /**
     * Трек изменения оператора
     */
    function trackOperatorChange() {
      carrotquestHelper.track('Статистика диалогов - выбрал оператора в фильтре', { Раздел: vm.currentTab });
    }

    /**
     * Трек изменения источника
     */
    function trackSourceChange() {
      carrotquestHelper.track('Статистика диалогов - выбрал источник в фильтре');
    }

    /**
     * Трек изменения вкладки
     *
     * @param {String} tabValue Название вкладки
     */
    function trackTabChange(tabValue) {
      if (tabValue === CONVERSATIONS_STATISTICS_TABS.AI_BOT.VALUE) {
        carrotquestHelper.track('Статистика диалогов - зашёл на статистику по AI-боту');
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.CHANNELS.VALUE) {
        carrotquestHelper.track('Статистика диалогов - зашёл на статистику по каналам');
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.GENERAL.VALUE) {
        carrotquestHelper.track('Статистика диалогов - зашёл на общую статистику');
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.OPERATORS.VALUE) {
        carrotquestHelper.track('Статистика диалогов - зашёл на статистику по операторам');
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.VOTE.VALUE) {
        carrotquestHelper.track('Статистика диалогов - зашёл на качество поддержки', { App: vm.currentApp.name });
      } else if (CONVERSATIONS_STATISTICS_TABS.TAGS && tabValue === CONVERSATIONS_STATISTICS_TABS.TAGS.VALUE) {
        carrotquestHelper.track('Статистика диалогов - зашёл на статистику по тегам');
      } else if (tabValue === CONVERSATIONS_STATISTICS_TABS.SOURCE.VALUE) {
        carrotquestHelper.track('Статистика диалогов - зашёл на статистику по источнику');
      }
    }

    /**
     * Трек изменения периода отображения статистики
     */
    function trackRangeSelect() {
      carrotquestHelper.track('Статистика диалогов - выбрал период', { Раздел: vm.currentTab });
    }

    /**
     * Обновления график скорости ответа операторов
     */
    function updateAnswerSpeedStatistics() {
      vm.isAnswerSpeedStatsLoading = true;

      getAnswerSpeedStatistics(
        vm.isWorkingTime,
        vm.statisticsOptions.dates.startDate,
        vm.statisticsOptions.dates.endDate,
        vm.statisticsOptions.channels,
      ).then(getAnswerSpeedSuccess);

      function getAnswerSpeedSuccess() {
        vm.isAnswerSpeedStatsLoading = false;
      }
    }

    /**
     * Обновление статистики
     */
    function updateStatistics() {
      if (vm.currentTab === CONVERSATIONS_STATISTICS_TABS.AI_BOT.VALUE) {
        getAiBotStatistics(vm.statisticsOptions.dates.startDate, vm.statisticsOptions.dates.endDate);
      } else if (vm.currentTab === CONVERSATIONS_STATISTICS_TABS.CHANNELS.VALUE) {
        getChannelsStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
        );
      } else if (vm.currentTab === CONVERSATIONS_STATISTICS_TABS.OPERATORS.VALUE) {
        getOperatorsStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
        );
      } else if (vm.currentTab === CONVERSATIONS_STATISTICS_TABS.GENERAL.VALUE) {
        getStatistics(
          vm.isWorkingTime,
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
        );
      } else if (vm.currentTab === CONVERSATIONS_STATISTICS_TABS.VOTE.VALUE) {
        getVoteStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.votes.vote,
          vm.statisticsOptions.channels,
          vm.statisticsOptions.teamMember,
        );
      } else if (CONVERSATIONS_STATISTICS_TABS.TAGS && vm.currentTab === CONVERSATIONS_STATISTICS_TABS.TAGS.VALUE) {
        getTagsStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.statisticsOptions.channels,
          vm.statisticsOptions.teamMember,
          vm.tags.contains,
        );
      } else if (vm.currentTab === CONVERSATIONS_STATISTICS_TABS.SOURCE.VALUE) {
        getSourceStatistics(
          vm.statisticsOptions.dates.startDate,
          vm.statisticsOptions.dates.endDate,
          vm.sourceTabData.sources,
        );
      }
    }
  }
})();

import { translate } from '@jsverse/transloco';
import { IPointData } from '@pixi/math';
import { Container, Graphics } from 'pixi.js';
import { Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { generate } from 'short-uuid';

import { BotMeetingActionBodyJson } from '@http/chat-bot/actions';
import {
  ActionStyle,
  BaseActionABS,
  ConnectionSourceActionABS,
  MAX_ACTION_WIDTH,
} from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { BotButtonMeetingAction } from '@panel/app/pages/chat-bot/content/views/actions/meeting-action/button-meeting-action';
import { BotTextMeetingAction } from '@panel/app/pages/chat-bot/content/views/actions/meeting-action/text-meeting-action';
import {
  getActionsBackgroundRect,
  getHorizontalSplitter,
} from '@panel/app/pages/chat-bot/content/views/actions/shared-textures';
import { BlockType, CARD_WIDTH } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { IPoint } from '@panel/app/pages/chat-bot/content/views/connection';
import { BOT_GREY, BOT_LIGHT_GREY, BOT_PRIMARY_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import {
  renderCanvasHTMLText,
  renderCanvasText,
} from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

export class BotMeetingAction extends ConnectionSourceActionABS<BotMeetingActionBodyJson> {
  protected style!: ActionStyle;
  private buttonMeetingAction;
  private textMeetingAction;
  private gap = 15;

  private meetingActionContainer!: Container;
  private buttonMeetingActionContainer!: Container;
  private splitterSucceed!: Graphics;
  private afterBookMeetingActionContainer!: Container;
  private nextBranchActionContainer!: Container;

  blockType: BlockType = 'meeting';

  rerenderConnection$!: Observable<void>;

  // Подписка на удаление nextBranch
  nextBranchDestroySub?: Subscription;

  uid!: string;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);

    this.buttonMeetingAction = new BotButtonMeetingAction(...args);
    this.textMeetingAction = new BotTextMeetingAction(...args);

    this.name.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => this.redraw());

    const content = this.render();
    this.uid = generate();
    this.rerenderConnection$ = this.externalPositionChange;

    if (content) {
      this.container.addChild(content);
    }
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 10,
        horizontal: 10,
      },
      border: {
        size: 0,
        color: 0xebebfa,
        radius: 10,
      },
      background: {
        color: 0xebebfa,
      },
    };
  }

  get href() {
    return this.form.controls.bodyJson.controls.href;
  }

  get name() {
    return this.form.controls.bodyJson.controls.name;
  }

  get integration() {
    return this.form.controls.bodyJson.controls.integration;
  }

  get apiReadyBodyJson(): BotMeetingActionBodyJson {
    return {
      href: this.href.value,
      name: this.name.value,
      integration: this.integration.value,
    };
  }

  get icon(): string {
    return UNICODE_ICON['cqi-calendar'];
  }

  getConnectionPointCoords(container: Container | null = null): IPointData {
    if (!container) {
      return { x: 0, y: 0 };
    }
    return {
      x: container.width - this.connectionPoint.width / 2 + this.style.padding.horizontal,
      y: container.height / 2 - this.connectionPoint.height / 2,
    };
  }

  get connectionPointGlobalCoordinates(): IPoint {
    // TODO: Вот это обращение к parent.parent мне очень не нравится, хочется абстракции, но пока не удалось сообразить
    const branchPosition = this.container.parent.parent.position;
    return {
      x: branchPosition.x + CARD_WIDTH,
      y: branchPosition.y + this.container.y + this.container.height - this.nextBranchActionContainer.height / 2,
    };
  }

  destroy() {
    this.connection?.dropConnection();
    super.destroy();
  }

  redraw() {
    if (this.destroyed) {
      return;
    }

    this.container.removeChildren();
    const content = this.render();
    this.container.addChild(content);
  }

  render(): Container {
    const container = new Container();

    this.meetingActionContainer = this.getMeetingActionContainer();
    this.buttonMeetingActionContainer = this.getButtonMeetingActionContainer();
    this.splitterSucceed = this.getSplitterSucceed();
    this.afterBookMeetingActionContainer = this.getAfterBookMeetingActionContainer();
    this.nextBranchActionContainer = this.getNextBranchActionContainer();

    container.addChild(
      this.meetingActionContainer,
      this.buttonMeetingActionContainer,
      this.splitterSucceed,
      this.afterBookMeetingActionContainer,
      this.nextBranchActionContainer,
    );

    return container;
  }

  getButtonMeetingActionContainer(): Container {
    const buttonMeetingAction = this.buttonMeetingAction.render();

    const x = buttonMeetingAction.x - 12;
    const y = this.meetingActionContainer.height + this.gap;

    buttonMeetingAction.position.set(x, y);

    return buttonMeetingAction;
  }

  getMeetingActionContainer(): Container {
    const padding = this.style.padding.horizontal * 2;
    const fontSize = 14;
    const gap = 10;
    const textOptions = {
      fontSize,
      lineHeight: fontSize * 1.42857143,
      wordWrapWidth: MAX_ACTION_WIDTH - padding - gap,
      fill: BOT_PRIMARY_COLOR,
    };

    const icon = PixiIconRenderService.renderIcon(this.icon, { fill: BOT_PRIMARY_COLOR });
    const text = renderCanvasHTMLText(this.translatedText, textOptions);
    const bg = getActionsBackgroundRect(
      text.height + this.style.padding.vertical * 2,
      this.style.background.color,
      this.pixiApp.renderer,
    );

    bg.zIndex = 1;
    bg.addChild(icon);
    bg.addChild(text);

    icon.position.set(this.style.padding.horizontal, (bg.height - icon.height) / 2);
    text.position.set(this.style.padding.horizontal + icon.width + gap, this.style.padding.vertical);

    return bg;
  }

  getSplitterSucceed(): Graphics {
    const element = new Graphics();

    const padding = this.style.padding.horizontal * 2;
    const textOptions = {
      fontSize: 12,
      lineHeight: 12 * 1.42857143,
      wordWrapWidth: MAX_ACTION_WIDTH - padding,
      fill: BOT_LIGHT_GREY,
    };

    const text = renderCanvasHTMLText(this.translatedSplitterText, textOptions);
    const splitter = getHorizontalSplitter(this.pixiApp.renderer, { linePadding: text.width + 20 });

    const x = splitter.x;
    const y =
      this.buttonMeetingActionContainer.y + this.buttonMeetingActionContainer.height + text.height / 2 + this.gap;

    splitter.position.set(x, y);
    text.position.set((MAX_ACTION_WIDTH - text.width) / 2, y - text.height / 2);

    element.addChild(splitter);
    element.addChild(text);

    return element;
  }

  getAfterBookMeetingActionContainer(): Container {
    const textMeetingAction = this.textMeetingAction.render();

    const x = 0;
    const y =
      this.buttonMeetingActionContainer.y +
      this.buttonMeetingActionContainer.height +
      this.splitterSucceed.height +
      this.gap * 2;

    textMeetingAction.position.set(x, y);

    return textMeetingAction;
  }

  getNextBranchActionContainer(): Container {
    const container = new Container();

    const text = renderCanvasText(this.translatedNextActionText, { fill: BOT_GREY, wordWrap: false });
    container.addChild(text, this.connectionPoint);

    this.connectionPoint.position.set(...Object.values(this.getConnectionPointCoords(text)));

    const x = CARD_WIDTH - container.width - this.connectionPoint.width / 2;
    const y = this.afterBookMeetingActionContainer.y + this.afterBookMeetingActionContainer.height + this.gap;

    container.position.set(x, y);

    return container;
  }

  get translatedSplitterText() {
    return translate('classes.action.canvasText.meeting.splitter');
  }

  get translatedText(): string {
    if (this.href.value) {
      return translate('classes.action.canvasText.meeting.selected', {
        meet: this.name.value,
      });
    }

    return translate('classes.action.canvasText.meeting.unselected');
  }

  get translatedNextActionText(): string {
    return translate('classes.action.canvasText.next');
  }

  get height(): number {
    return this.container.height;
  }

  get width(): number {
    return this.container.width;
  }

  get renderReady(): boolean {
    return isDefined(this.container) && this.container.renderable && this.container.children.length > 0;
  }
}

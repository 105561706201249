import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';

export const BOT_WHITE_COLOR = HEX_COLOR.WHITE;
export const BOT_SUCCESS_COLOR = HEX_COLOR.SUCCESS_600;
export const BOT_ERROR_COLOR = HEX_COLOR.DANGER_600;
export const BOT_PRIMARY_COLOR = HEX_COLOR.PRIMARY_600;

export const BOT_DARK_GREY = HEX_COLOR.GRAY_150;
export const BOT_GREY = HEX_COLOR.GRAY_400;
export const BOT_LIGHT_GREY = HEX_COLOR.GRAY_650;
export const BOT_LIGHTEST_GREY = HEX_COLOR.GRAY_950;
export const BOT_MOST_LIGHTEST_GREY = HEX_COLOR.GRAY_900;

export const BOT_MESSAGE_BLOCK_PRIMARY_COLOR = HEX_COLOR.GRAY_400;

export const BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR = HEX_COLOR.SUCCESS_400;
export const BOT_ACTION_BLOCK_PRIMARY_COLOR = HEX_COLOR.SUCCESS_600;
export const BOT_ACTION_BLOCK_LIGHTEN_PRIMARY_COLOR = HEX_COLOR.SUCCESS_950;

export const BOT_CONDITION_BLOCK_DARKEN_PRIMARY_COLOR = HEX_COLOR.WARNING_400;
export const BOT_CONDITION_BLOCK_PRIMARY_COLOR = HEX_COLOR.WARNING_600;
export const BOT_CONDITION_BLOCK_LIGHTEN_PRIMARY_COLOR = HEX_COLOR.WARNING_950;

import { LocationPartial } from '@environments/types';

export const k8sLocationPartial: LocationPartial = {
  apiEndpoint: 'https://to-sed-value.k8s-dev.carrotquest.io/api',
  awsLambdaScriptsUrl: 'https://lambda-script.carrotquest.io',
  cdnEndpoint: 'https://to-sed-value.k8s-dev.carrotquest.io/cdn',
  cloudPaymentsPublicId: 'to-sed-value-cloud-payments-public-id',
  externalPagesUrl: 'https://to-sed-value.k8s-dev.carrotquest.io/panel/unauthorized',
  firstDialogUrl: 'https://to-sed-value.k8s-dev.carrotquest.io/first-dialog',
  froalaEditorKey: 'gcg1pqkqwrmzB-9E4eb==',
  googleClientId: '44569780138-5ttc4sulbmgb0hupmmvp6sa62embufnk.apps.googleusercontent.com',
  notAvailableFromMobileUrl: 'https://to-sed-value.k8s-dev.carrotquest.io/not-available-from-mobile',
  oauthRedirectUrl: 'https://to-sed-value.k8s-dev.carrotquest.io/oauthcallback',
  panelUrl: 'https://to-sed-value.k8s-dev.carrotquest.io/panel',
  paymentUrl: 'https://to-sed-value.k8s-dev.carrotquest.io/paynow',
  previewFrameUrl: 'https://to-sed-value.k8s-dev.carrotquest.io/panel/preview',
  realTimeServicesHost: '//to-sed-value.k8s-dev.carrotquest.io/realtime-services',
  realTimeServicesHostNew: '//to-sed-value.k8s-dev.carrotquest.io/rts',
  recaptchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  stripePaymentsPublicKey:
    'pk_test_51H4lc0EUZ4yc3sIEjmVLyjH1YD9mfapfJMSl4mNXB8RqB4gdrr5iNBwiUOQfYAMl21vxpRWQ2zJowkK6RK2mcJ0O000FrrpjuT',
  useNewRts: false,
  webhooksEndpoint: 'https://to-sed-value.k8s-dev.carrotquest.io/api/hooks',
};

(function () {
  'use strict';

  angular.module('myApp.chatSettings').component('cqChatPatterns', cqChatPatterns());

  function cqChatPatterns() {
    return {
      bindings: {
        pattern: '=', // Фон чата
      },
      controllerAs: 'vm',
      template: require('./chat-patterns.html'),
    };
  }
})();

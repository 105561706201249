import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { MomentModule } from 'ngx-moment';

import { MemberActionsTableComponent } from './member-actions-table.component';

@NgModule({
  declarations: [MemberActionsTableComponent],
  exports: [MemberActionsTableComponent],
  imports: [CommonModule, MomentModule, TranslocoModule],
})
export class MemberActionsTableModule {}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.chatBot.telegramBot.statistics').config(config);

  function config($stateProvider) {
    $stateProvider.state('app.content.messagesAjs.telegramBot.statistics', {
      url: '/{telegramBotId:id}',
      bindings: {
        telegramBot: 'telegramBot',
        currentApp: 'currentApp',
        djangoUser: 'djangoUser',
      },
      component: 'cqTelegramBotStatistics',
      resolve: {
        telegramBot: getTelegramBot,
      },
    });

    /**
     * Получение телеграм-бота
     *
     * @param $stateParams
     * @param chatBotModel
     * @returns {Promise}
     */
    function getTelegramBot($stateParams, chatBotModel) {
      return firstValueFrom(chatBotModel.getTelegramBot($stateParams.telegramBotId));
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { BlockHeaderModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/block-header/block-header.module';
import { ButtonBlockEditorModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/button-block-editor/button-block-editor.module';
import { ButtonBlocksComponent } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/button-blocks/button-blocks.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [ButtonBlocksComponent],
  exports: [ButtonBlocksComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonBlockEditorModule,
    BlockHeaderModule,
  ],
})
export class ButtonBlocksModule {}

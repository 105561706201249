import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SendingWhatsAppTemplateModule } from '@panel/app/partials/modals/sending-whats-app-template/sending-whats-app-template.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { WhatsAppTemplatesPopoverComponent } from './whats-app-templates-popover.component';

@NgModule({
  declarations: [WhatsAppTemplatesPopoverComponent],
  exports: [WhatsAppTemplatesPopoverComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule, SharedModule, SendingWhatsAppTemplateModule],
})
export class WhatsAppTemplatesPopoverModule {}

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqPrestashopModalController', CqPrestashopModalController);

  function CqPrestashopModalController($translate, PROJECT_NAME, USER_FILES_URL, carrotquestHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.USER_FILES_URL = USER_FILES_URL;
      vm.trackClickDownloadModule = trackClickDownloadModule;
    }

    /**
     * Трек клика на кнопку 'Скачать модуль'
     */
    function trackClickDownloadModule() {
      carrotquestHelper.track('Интеграции - ' + vm.resolve.integration.type + ' - клик на "Скачать модуль"');
    }
  }
})();

import { Validators } from '@angular/forms';

import { INTEGRATION_NAME_MAX_LENGTH } from '@http/integration/constants/integration.constants';
import {
  VK_FORMS_CUSTOM_EVENT_TYPE_NAME_MAX_LENGTH,
  VK_FORMS_INTEGRATION_FORM_URL_REGEXP,
} from '@http/integration/integrations/vk-forms/constants/vk-forms-integration.constants';
import {
  VkFormsIntegrationFormExternal,
  VkFormsIntegrationQuestionExternal,
  VkFormsIntegrationSettingsExternal,
  VkFormsIntegrationStandardQuestionExternal,
} from '@http/integration/integrations/vk-forms/interfaces/vk-forms-integration.interfaces';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { UrlValidator } from '@panel/app/shared/validators/url/url';

import {
  VkFormsFormFormData,
  VkFormsIntegrationFormData,
  VkFormsSettingsFormData,
} from '../types/vk-forms-integration.types';
import { VkFormsIntegration } from '../vk-forms-integration';

/** Класс для работы с формой представления VK_FORMS-интеграции */
export class VkFormsIntegrationForm extends GenericFormGroup<VkFormsIntegrationFormData> {
  constructor(integration: VkFormsIntegration) {
    let controls: Controls<VkFormsIntegrationFormData> = {
      name: new GenericFormControl(integration.name, [
        Validators.required,
        Validators.maxLength(INTEGRATION_NAME_MAX_LENGTH),
      ]),
      settings: new VkFormsSettingsFormGroup(integration.settings),
    };

    super(controls, null, null);
  }
}

/** Класс для работы с формой представления настроек VK_FORMS-интеграции */
export class VkFormsSettingsFormGroup extends GenericFormGroup<VkFormsSettingsFormData> {
  constructor(settings: VkFormsIntegrationSettingsExternal) {
    const formsForms = settings.forms.map((form: VkFormsIntegrationFormExternal) => {
      return new VkFormsFormForm(form);
    });

    const controls: Controls<VkFormsSettingsFormData> = {
      apiToken: new GenericFormControl(settings.apiToken, [Validators.required]),
      confirmCode: new GenericFormControl(settings.confirmCode, [Validators.required]),
      forms: new GenericFormArray(formsForms),
      token: new GenericFormControl(settings.token),
    };

    super(controls, null, null);
  }
}

/** Класс для работы с формой представления поля с формами сбора заявок у VK_FORMS-интеграции */
export class VkFormsFormForm extends GenericFormGroup<VkFormsFormFormData> {
  constructor(form: VkFormsIntegrationFormExternal) {
    const standardQuestions = form.standardQuestions.map(
      (standardQuestion: VkFormsIntegrationStandardQuestionExternal) => {
        return new VkFormsStandardQuestionForm(standardQuestion);
      },
    );

    const questionsForms = form.questions.map((question: VkFormsIntegrationQuestionExternal) => {
      return new VkFormsQuestionForm(question);
    });

    let controls: Controls<VkFormsFormFormData> = {
      formLink: new GenericFormControl(form.formLink, [
        Validators.required,
        UrlValidator,
        Validators.pattern(VK_FORMS_INTEGRATION_FORM_URL_REGEXP),
      ]),
      standardQuestions: new GenericFormArray(standardQuestions),
      questions: new GenericFormArray(questionsForms),
      eventTypeName: new GenericFormControl(form.eventTypeName, [
        Validators.required,
        Validators.maxLength(VK_FORMS_CUSTOM_EVENT_TYPE_NAME_MAX_LENGTH),
      ]),
    };

    if (form.formId) {
      controls.formId = new GenericFormControl(form.formId, []);
    }

    super(controls, null, null);
  }
}

/** Класс для работы с формой представлениея поля со стандартными вопросами в форме сбора заявок у VK_FORMS-интеграции */
export class VkFormsStandardQuestionForm extends GenericFormGroup<VkFormsIntegrationStandardQuestionExternal> {
  constructor(standardQuestion: VkFormsIntegrationStandardQuestionExternal) {
    const controls: Controls<VkFormsIntegrationStandardQuestionExternal> = {
      v: new GenericFormControl(standardQuestion.v, [Validators.required]),
      checked: new GenericFormControl(standardQuestion.checked, []),
    };

    super(controls, null, null);
  }
}

/** Класс для работы с формой представления поля с кастомными вопросами в форме сбора заявок у VK_FORMS-интеграции */
export class VkFormsQuestionForm extends GenericFormGroup<VkFormsIntegrationQuestionExternal> {
  constructor(question: VkFormsIntegrationQuestionExternal) {
    const controls: Controls<VkFormsIntegrationQuestionExternal> = {
      v: new GenericFormControl(question.v, [Validators.required]),
      k: new GenericFormControl(question.k, [Validators.required]),
    };

    super(controls, null, null);
  }
}

<div
  class="card dashed m-0 w-100 h-0"
  #addonCardRef
>
  <div class="card-body d-flex align-items-center justify-content-center p-10">
    <div class="text-center">
      <div>{{ 'models.billingInfo.billingGeneral.availableWithAddOn' | transloco }}</div>
      <a
        href="#"
        (click)="scrollToAddon(addon)"
        >{{ 'models.billingInfo.billingAddOns.' + addon | transloco }}</a
      >
    </div>
  </div>
</div>

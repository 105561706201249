import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { find } from 'lodash-es';
import orderBy from 'lodash-es/orderBy';
import { map } from 'rxjs';

import { PSEUDO_DIRECTORY_IDS, PSEUDO_DIRECTORY_TYPES } from '@http/message-directory/message-directory.constants';
import { DirectoryEditorModalComponent } from '@panel/app/pages/auto-messages/modals/shared/directory-editor/directory-editor-modal.component';
import { ModalHelperService } from '@panel/app/services';
import { ACTIONS_ON_DIRECTORY } from '@panel/app-old/shared/modals/directory-editor/directory-editor.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

import { directoriesStore$ } from '../../../stores/directories.store';

@Component({
  selector: 'cq-directory-filter',
  templateUrl: './directory-filter.component.html',
  styleUrls: ['./directory-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryFilterComponent {
  @Input()
  currentApp: any;

  @Input()
  currentDirectoryId: any;

  @Output()
  changeDirectory: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NgSelectComponent) ngSelectComponent!: NgSelectComponent;

  protected directoriesStore$ = directoriesStore$.pipe(
    map((directories: any) => this.getOrderedDirectories(directories)),
  );
  protected prevDirectoryId: any;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalHelperService: ModalHelperService,
  ) {}

  openEditDirectoryModal(directory?: any): void {
    this.ngSelectComponent.close();
    this.ngSelectComponent.blur();

    let createDirectoryModal = this.modalHelperService.open(DirectoryEditorModalComponent);

    createDirectoryModal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
      directory: directory,
    };

    createDirectoryModal.result
      .then((response: any) => {
        let directory = find(directoriesStore$.getValue(), { id: response.directory.id });

        switch (response.action) {
          case ACTIONS_ON_DIRECTORY.CREATE: {
            directoriesStore$.next([...directoriesStore$.getValue(), ...[response.directory]]);
            this.cdr.detectChanges();
            break;
          }
          case ACTIONS_ON_DIRECTORY.DELETE: {
            const filteredDirectoriesWithoutDeleted = directoriesStore$
              .getValue()
              .filter((dir: any) => dir.id !== directory.id);
            directoriesStore$.next(filteredDirectoriesWithoutDeleted);

            if (this.currentDirectoryId === directory.id) {
              let defaultDirectory = find(directoriesStore$.getValue(), {
                id: PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY],
              });

              this.changeDirectory.emit(defaultDirectory);
              this.currentDirectoryId = defaultDirectory.id;
              this.cdr.detectChanges();
            }
            break;
          }
          case ACTIONS_ON_DIRECTORY.RENAME: {
            directory.prettyName = directory.name = response.directory.prettyName;
            this.cdr.detectChanges();
            break;
          }
        }
      })
      .catch(() => {});
  }

  getOrderedDirectories(unorderedDirectories: any): any {
    return orderBy(unorderedDirectories, ['prettyName', 'isSystem'], ['asc', 'desc']);
  }

  onFocus(): void {
    this.prevDirectoryId = this.currentDirectoryId;
    this.currentDirectoryId = null;
  }

  onBlur(): void {
    if (this.currentDirectoryId === null) {
      this.currentDirectoryId = this.prevDirectoryId;
      this.prevDirectoryId = null;
    }
  }

  onChange(directory: any): void {
    if (directory.id === this.prevDirectoryId) {
      return;
    }

    this.trackChangeDirectory(directory);

    this.changeDirectory.emit(directory);

    this.ngSelectComponent.blur();
  }

  /**
   * Обновление фильтра "Папка"
   */
  trackChangeDirectory(directory: any): void {
    this.carrotquestHelper.track('Автосообщения - применил фильтр "Папка"', { Название: directory.prettyName });
  }
}

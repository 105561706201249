(function () {
  'use strict';

  require('./add-tags-to-import-leads.controller');

  angular.module('myApp.users').component('cqAddTagsToImportLeads', cqAddTagsToImportLeads());

  function cqAddTagsToImportLeads() {
    return {
      bindings: {
        importTags: '<', // Теги, которые будут присвоены импортируемым пользователям
        onNewTagAdded: '<', // Колбэк, срабатывающий при добавлении нового тега
        onTagRemoved: '<', // Колбэк, срабатывающий при удалении тега
        tags: '<', // Список тегов приложения
      },
      controller: 'CqAddTagsToImportLeadsController',
      controllerAs: 'vm',
      template: require('./add-tags-to-import-leads.html'),
    };
  }
})();

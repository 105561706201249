<ng-container *transloco="let t; read: 'starterGuideHeaderComponent'">
  <div class="container-fluid bounded-container d-flex align-items-center my-0 py-25">
    <div class="left-content w-100">
      <h1>{{t('heading', {projectName})}}</h1>
      <div class="mb-15 text-secondary">{{ t('description') }}</div>
      <ng-select
        [clearable]="false"
        [disabled]="isDisabledTaskSelect"
        [items]="tasks"
        [ngModel]="selectedTask"
        [placeholder]="t('taskSelect.placeholder')"
        [searchable]="false"
        (change)="changeTask($event)"
      >
        <ng-template
          let-task="item"
          ng-label-tmp
        >
          {{ translateTask(task) }}
        </ng-template>
        <ng-template
          ng-option-tmp
          let-task="item"
        >
          {{ translateTask(task) }}
        </ng-template>
      </ng-select>
    </div>
    <div class="ml-auto">
      <img
        class="cursor-pointer"
        (click)="openViewVideoModal(headingModal, videoSrc)"
        data-test="open-view-video-modal-img"
        src="assets/img/{{ activeLang }}/starter-guide/onboarding_v2_about_service.png"
      />
    </div>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./display-settings.controller');

  angular.module('myApp.autoMessages').component('cqDisplaySettings', cqDisplaySettings());

  function cqDisplaySettings() {
    return {
      bindings: {
        currentApp: '=', // Текущий апп
        currentMessage: '=', // Сообщение
        deleteMessage: '=', // Параметры удаления сообщений
        deleteMessageForm: '=',
        filtersForm: '=', // Объект формы фильтров
        hasDeletableMessagePart: '&', // Есть ли среди настраиваемых вариантов сообщений хотя бы одно удаляемое
        hasUndeletableMessagePart: '&', // Есть ли среди настраиваемых вариантов сообщений хотя бы одно неудаляемое
        hideForCanvasBot: '=?', // Скрывать элементы если компонент используется в чат-боте на канвасе
        isCollapsed: '=', //Состояние аккордеона
        isHideForNewOnboarding: '=?', // Флаг для скрывания элементов для нового онбординга
        isMessageFilterableByUrl: '<', //Проверка на одновременное нахождение в одном автосообщении двух вариантов типов сообщений
        translationEntityName: '<',
        messagePageType: '=?', // Тип страницы, на котором размещен компонент, необходимо для корректного трекинга событий и переводов
        newComponentValidators: '=', // Костыль для валидации новых компонентов при попытке выхода из вкладки триггеров
        properties: '=', // Свойства и типы событий
        segments: '=', // Сегменты
        showedSendingFiltersPopoverInAudience: '=', // Статус показа поповера с условиями отправки по URL в "Аудитория"
        tags: '=', // Теги
        minRepeatDelay: '<', // Минимальное возможное время повторной отправки
      },
      controller: 'CqDisplaySettingsController',
      controllerAs: 'vm',
      template: require('./display-settings.html'),
    };
  }
})();

<ng-container *transloco="let t">
  <div class="modal-header align-items-baseline">
    <h3 class="modal-title">{{ t('writeToIntegrationsModalComponent.heading') }}</h3>
    <button
      class="close"
      (click)="dismissModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <p>{{ t('writeToIntegrationsModalComponent.description.1') }}</p>
    <p>{{ t('writeToIntegrationsModalComponent.description.2') }}</p>
  </div>

  <div class="modal-footer">
    <button
      class="btn check-dialogs-button btn-primary"
      (click)="trackClickOnCheckDialogButton()"
      data-test="check-integration-conversations-button"
      type="button"
      #checkDialogsButton
    >
      <div
        class="fade"
        [ngClass]="{ in: (isDataLoadingObs$ | async) === false }"
      >
        {{ t('writeToIntegrationsModalComponent.buttons.checkConversationButton.text') }}
      </div>
      <div
        class="checking-text fade"
        [ngClass]="{ in: isDataLoadingObs$ | async }"
      >
        <i class="btn-icon-left cqi-sm cqi-spinner inline-block spin"></i>
        {{ t('writeToIntegrationsModalComponent.buttons.checkConversationButton.checkingText') }}
      </div>
    </button>
  </div>
</ng-container>

(function () {
  'use strict';

  angular
    .module('myApp.modals.lightweightEmailTemplatePreview')
    .controller('CqLightweightEmailTemplatePreviewModalController', CqLightweightEmailTemplatePreviewModalController);

  function CqLightweightEmailTemplatePreviewModalController() {
    var vm = this;

    /**
     * Типы писем
     *
     * @type {{MESSAGE: string, CONVERSATION: string}}
     */
    var EMAIL_TYPES = {
      CONVERSATION: 'conversation',
      MESSAGE: 'message',
    };

    vm.$onInit = init;

    function init() {
      vm.emailType = EMAIL_TYPES.MESSAGE;
      vm.EMAIL_TYPES = EMAIL_TYPES;
    }
  }
})();

<ng-container *transloco="let t">
  <div
    class="d-flex attachment"
    [ngClass]="{ 'zero-data': !attachment }"
  >
    <div class="d-flex align-items-center justify-content-center overflow-hidden icon">
      <i class="cqi-sm cqi-paperclip"></i>
    </div>
    <div class="overflow-hidden info">
      <div class="overflow-hidden flex-nowrap name">{{ attachmentName }}</div>
      <div class="d-inline-flex overflow-hidden description">
        {{ t('conversationPartPreviewFileComponent.attachment.size', { size: attachmentSize }) }}
      </div>
    </div>
  </div>
</ng-container>

import { ValidatorFn, Validators } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import {
  FACEBOOK_REGEXP,
  INSTAGRAM_REGEXP,
  MAILTO_REGEXP,
  PHONE_REGEXP,
  TELEGRAM_URL_REGEXP,
  VIBER_REGEXP,
  VK_REGEXP,
  WHATSAPP_REGEXP,
} from '@panel/app/pages/chat-bot/content/branch-editor/actions/button/button.component';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { noWhitespaceValidator } from '@panel/app/shared/validators/no-whitespace/no-whitespace-validator';
import { BotButtonActionBodyJson } from 'app/http/chat-bot/actions';
import {
  CHAT_BOT_BUTTON_ACTION_URL_TARGET,
  ChatBotAction,
} from '@http/chat-bot/types/action-internal.types';

import { BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

/**
 * Максимальная длина текста кнопки
 */
export const BUTTON_TEXT_MAX_LENGTH: number = 300;

/**
 * Button action validators
 */
const buttonBodyValidators: ValidatorFn[] = [
  Validators.required,
  noWhitespaceValidator,
  // Так же есть валидатор на макс. длину текста в кнопке, он находится в компоненте кнопок
];
const buttonNextBranchIdValidators: ValidatorFn[] = [Validators.required];

export class BotButtonActionForm extends BaseBotActionForm {
  getCustomControls(action: ChatBotAction<BotButtonActionBodyJson>): CustomControlsWithBodyJsonRequired {
    return {
      bodyJson: new GenericFormGroup({
        enableUrl: new GenericFormControl(!!action.bodyJson.href),
        // Для этого поля валидаторы задаются непосредственно в компоненте
        href: new GenericFormControl(action.bodyJson.href || null),
        icon: new GenericFormControl(getInitialIcon(action.bodyJson)),
        target: new GenericFormControl(action.bodyJson.target || CHAT_BOT_BUTTON_ACTION_URL_TARGET.BLANK),
      }),
      body: new GenericFormControl(action.body || null, buttonBodyValidators),
      nextBranchLinkId: new GenericFormControl(action.nextBranchLinkId ?? null, buttonNextBranchIdValidators),
    };
  }

  get allTouchedChanges$() {
    return combineLatest([
      extractTouchedChanges(this.controls.body).pipe(startWith(this.controls.body.touched)),
      extractTouchedChanges(this.controls.nextBranchLinkId).pipe(startWith(this.controls.nextBranchLinkId.touched)),
    ]).pipe(map(([t1, t2]) => t1 && t2));
  }
}

function getInitialIcon(bodyJson: BotButtonActionBodyJson): string {
  if (bodyJson.icon) {
    return bodyJson.icon;
  }

  if (bodyJson.href) {
    switch (true) {
      case FACEBOOK_REGEXP.test(bodyJson.href):
        return 'cqi-facebook';
      case INSTAGRAM_REGEXP.test(bodyJson.href):
        return 'cqi-instagram';
      case MAILTO_REGEXP.test(bodyJson.href):
        return 'cqi-envelope-o';
      case PHONE_REGEXP.test(bodyJson.href):
        return 'cqi-phone';
      case TELEGRAM_URL_REGEXP.test(bodyJson.href):
        return 'cqi-telegram';
      case VIBER_REGEXP.test(bodyJson.href):
        return 'cqi-viber';
      case VK_REGEXP.test(bodyJson.href):
        return 'cqi-vk';
      case WHATSAPP_REGEXP.test(bodyJson.href):
        return 'cqi-whatsapp';
    }
  }

  return 'cqi-external-link';
}

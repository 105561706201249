// @formatter:off
function addScript(script) {
  var newScriptElement = document.createElement('script');
  var firstScriptElement = document.getElementsByTagName('script')[0];

  newScriptElement.async = script.async;
  newScriptElement.src = script.src;
  firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement)
}

var scripts = [
  {
    async: true,
    src: 'https://widget.cloudpayments.ru/bundles/cloudpayments'
  },
  {
    async: true,
    src: 'https://app-rsrc.getbee.io/plugin/BeePlugin.js'
  }
];

for (var i = 0; i < scripts.length; i++) {
  addScript(scripts[i])
}

// Carrot quest BEGIN
if (location.href.indexOf('/panel/message_template?') == -1) {
  let config = {
    settings: {}
  };
  if (document.cookie.indexOf('desktop_app=1') !== -1 || window.electronApi) {
    config['settings']['messenger_mode'] = 'hidden';
  }
  (function(){
    function Build(name, args){return function(){window.carrotquestasync.push(name, arguments);} }
    if (typeof carrotquest === 'undefined') {
      var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true;
      s.src = 'https://to-sed-value.k8s-dev.carrotquest.io/cdn/api.min.js';
      var x = document.getElementsByTagName('head')[0]; x.appendChild(s);
      window.carrotquest = {}; window.carrotquestasync = []; carrotquest.settings = {};
      var m = ['connect', 'track', 'identify', 'auth', 'open', 'onReady', 'addCallback', 'removeCallback', 'trackMessageInteraction'];
      for (var i = 0; i < m.length; i++) carrotquest[m[i]] = Build(m[i]);
    }
  })();
 carrotquest.connect('4-e64564a8d3b3bfbe97c32b12f89945', config);
} else {
  window.carrotquest = {
    track: function () {
      console.log(arguments);
    },
    identify: function () {
      console.log(arguments);
    },
    auth: function () {
      console.log(arguments);
    }
  };
}

// Google Tag Manager
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PHNG63V');
// End Google Tag Manager

// @formatter:on

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Log, PaginatableLogs } from 'app/http/logs/logs.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIPaginatableResponse } from '@http/types';
import { CaseStyleHelper } from '@panel/app/services';
import { EXTENDED_RESPONSE } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class LogsModel {
  constructor(private readonly http: HttpClient, private readonly caseStyleHelper: CaseStyleHelper) {}

  get(appId: string, dateRange: any, paginatorParams?: any): Observable<PaginatableLogs> {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePageOrder = 'desc',
      paginatePosition = [],
    } = paginatorParams ?? {};

    const params = {
      start_date: dateRange.startDate.format('YYYY-MM-DD'),
      end_date: dateRange.endDate.format('YYYY-MM-DD'),
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_page_order: paginatePageOrder,
      paginate_position: paginatePosition?.join() || null,
    };

    return this.http
      .get<APIPaginatableResponse<Log[]>>(`/apps/${appId}/logs/`, {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: APIPaginatableResponse<Log[]>) => {
          return {
            membersActions: response.data,
            // тут кастую тип, просто чтоб сошлось, потому что код до переписывания на нормальные типы был рабочим, но наши нормальные типы - говно
            paginatePosition: response.meta.nextBeforePosition as unknown as null,
          };
        }),
      );
  }
}

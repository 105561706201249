import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TriggerChainTrackService } from '@panel/app/pages/trigger-chains/shared/services/track-service/trigger-chain-track.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

@Component({
  selector: 'cq-trigger-chain-additional-actions',
  templateUrl: './trigger-chain-additional-actions.component.html',
  styleUrls: ['./trigger-chain-additional-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainAdditionalActionsComponent {
  isRusCountry: boolean = this.l10nHelperService.isRusCountry();

  constructor(
    private readonly triggerChainTrackService: TriggerChainTrackService,
    private readonly l10nHelperService: L10nHelperService,
  ) {}

  trackClick() {
    this.triggerChainTrackService.trackTransitionOnLearningBase();
  }
}

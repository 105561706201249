/** Типы прикреплений шаблонов WhatsApp в edna */
export enum WHATS_APP_TEMPLATE_HEADER_TYPE {
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

/** Статусы одобрения шаблонов WhatsApp в edna */
export enum WHATS_APP_TEMPLATE_STATUS {
  APPROVED = 'APPROVED',
  NOT_SENT = 'NOT_SENT',
  REJECTED = 'REJECTED',
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ConversationTagHelpService } from '@panel/app-old/shared/entities/conversation-tag/help/conversation-tag-help.service';

/** Компонент для работы zero-data в тегах диалогах */
@Component({
  selector: 'cq-tag-list-empty',
  templateUrl: './tag-list-empty.component.html',
  styleUrls: ['./tag-list-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListEmptyComponent {
  constructor(private readonly conversationTagHelpService: ConversationTagHelpService) {}

  /** Обрабатывает намеренье создать тег */
  onIntentToCreateTag(): void {
    this.conversationTagHelpService.startCreateTagProcess();
  }
}

/**
 * Псевдо статусы адресов электронной почты
 */
export enum PSEUDO_EMAIL_STATUSES {
  NOT_SUBSCRIBED = 'not_subscribed', // Не подписан (включает все варианты, кроме "Подписан"), используется только в сегментации
}

/**
 * Статусы адресов электронной почты
 */
export enum EMAIL_STATUSES {
  BLACK_LIST = 'black_list', // блокирован (не используется, чёрный список делать не стали)
  BOUNCED = 'bounced', // bounced
  CONFIRMED = 'confirmed', // подписан
  NO_EMAIL = 'no_email', // емейл отсутствует !!! фиктивный статус, который используется только в elasticsearch, в действительности если почты у пользователя нет - в поле emailStatus придёт null
  NOT_CONFIRMED = 'not_confirmed', // ожидает подтверждения
  NOT_VALID = 'not_valid', // невалидный емейл
  SPAM = 'spam', // пожаловался на спам
  VALIDATION = 'validation', // на валидации
  UNSUBSCRIBED = 'unsubscribed', // отписан
}

/**
 * Список статусов адресов электронной почты
 */
export const EMAIL_STATUSES_LIST = [
  EMAIL_STATUSES.BLACK_LIST,
  EMAIL_STATUSES.BOUNCED,
  EMAIL_STATUSES.CONFIRMED,
  EMAIL_STATUSES.NO_EMAIL,
  EMAIL_STATUSES.NOT_CONFIRMED,
  EMAIL_STATUSES.NOT_VALID,
  EMAIL_STATUSES.SPAM,
  EMAIL_STATUSES.VALIDATION,
  EMAIL_STATUSES.UNSUBSCRIBED,
];

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { BranchActionContainerModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/container/container.module';
import { BranchActionNextModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/next/next.module';
import { OtherActionsDropdownModule } from '@panel/app/pages/chat-bot/content/branch-editor/partials/other-actions-dropdown/other-actions-dropdown.model';

import { BlockActionEditorComponent } from './block-action-editor.component';

@NgModule({
  declarations: [BlockActionEditorComponent],
  imports: [
    BranchActionContainerModule,
    BranchActionNextModule,
    CommonModule,
    NgbCollapseModule,
    OtherActionsDropdownModule,
    TranslocoModule,
  ],
  exports: [BlockActionEditorComponent],
})
export class BlockActionEditorModule {}

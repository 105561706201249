(function () {
  'use strict';

  angular.module('myApp.planCapability', []);

  require('./plan-capability.routes');
  require('./paywall/plan-capability-paywall.component');
  require('./paywall-modal/plan-capability-paywall-modal.component');
  require('./paywall-wrapper/plan-capability-paywall-wrapper.component');
  require('./paywall-auto-message-total-modal/plan-capability-auto-message-total-paywall-modal.component');
})();

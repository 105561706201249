import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { skip } from 'rxjs/operators';

import { EMAIL_TYPES, MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';

@Component({
  selector: 'cq-ai-content-maker',
  templateUrl: './ai-content-maker.component.html',
  styleUrls: ['./ai-content-maker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiContentMakerComponent {
  _bodyJson!: string;
  @Input({ required: true })
  set bodyJson(bodyJson) {
    this._bodyJson = bodyJson;
    this.control.setValue(bodyJson, { emitEvent: false });
  }

  get bodyJson() {
    return this._bodyJson;
  }

  @Input({ required: true })
  body!: string;

  readonly control = new FormControl('', { nonNullable: true });

  @Output()
  bodyJsonChange = this.control.valueChanges.pipe(skip(1));

  @Output()
  bodyChange = new EventEmitter();

  protected readonly MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
  protected readonly EMAIL_TYPES = EMAIL_TYPES;

  protected readonly aceEditorOptions = {
    mode: 'json',
    theme: 'monokai',
  };

  constructor(private readonly http: HttpClient) {}

  //Оставил тут, потому что все это костыль для тестов
  generateContent() {
    const params = JSON.parse(this.bodyJson);

    this.http.post(`/integrations/ai_content_maker/generate_content`, params).subscribe((res) => {
      //@ts-ignore
      this.bodyChange.emit(res.generatedContent);
    });
  }
}

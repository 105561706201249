import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { App } from '@http/app/app.model';
import { EventType, UserProperty } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';

/**
 * Компонент прокладка между AngularJS & Angular
 */
@Component({
  selector: 'cq-event-type-select-trigger-wrapper[currentApp]',
  templateUrl: './event-type-select-trigger-wrapper.component.html',
  styleUrls: ['./event-type-select-trigger-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTypeSelectTriggerWrapperComponent {
  @Input()
  autoEvents: AutoEvent[] = [];

  @Input()
  currentApp!: App;

  @Input()
  placeholderText!: string;

  @Input()
  value: string | null = null;

  @Input()
  eventTypes: EventType[] = [];

  @Output()
  autoEventsChange = new Subject();

  @Output()
  eventTypesChange = new Subject();

  @Output()
  valueChange = new Subject<string | null>();

  @Input()
  userProps: UserProperty[] = [];

  @Output()
  selectedEventTypeChange = new Subject();

  @Output()
  openChange = new Subject();

  @Output()
  urlEventTypeAdd = new Subject();

  @Output()
  sessionStartAdd = new Subject();
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { WINDOW } from '@ng-web-apis/common';
import { UIRouter } from '@uirouter/core';
import { isNull } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, pairwise, shareReplay, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { UUID } from 'short-uuid';

import { App } from '@http/app/app.model';
import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import { PlanCapabilityModel } from '@http/plan-capability/plan-capability.model';
import { EventType, Properties } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';
import { TriggerChainStepFactory } from '@http/trigger-chain/factory';
import { TriggerChain, TriggerChainStep, TriggerChainStepType } from '@http/trigger-chain/internal-types';
import { TriggerChainModel } from '@http/trigger-chain/trigger-chain.model';
import {
  resetValidationState,
  triggerChainValidator,
  validateTriggerChainStep,
} from '@http/trigger-chain/validators/trigger-chain.validator';
import { TriggerChainTemplate } from '@http/trigger-chain-template/internal-types/trigger-chain-template.internal.type';
import { UserSegment, UserTag } from '@http/user/types/user.type';
import { pixiCullProvider, pixiTokenProvider, pixiViewportProvider } from '@panel/app/pages/chat-bot/content/tokens';
import {
  TriggerChainOverlayEventService,
  TriggerChainOverlayService,
} from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/services';
import { CanvasOverlayHandlerService } from '@panel/app/pages/trigger-chains/editor/components/canvas/services/canvas-overlay-handler.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { TriggerChainTemplateComponent } from '@panel/app/partials/modals/trigger-chain-template/trigger-chain-template.component';
import { TRIGGER_CHAIN_TEMPLATE_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/trigger-chain-template/trigger-chain-template.token';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { CanvasBaseService } from '@panel/app/services/canvas/common/base/canvas-base.service';
import { CanvasInteractionService } from '@panel/app/services/canvas/common/interaction/canvas-interaction.service';
import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { BlockViewFactory } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.factory';
import { BlockViewParser } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.parser';
import { BlockViewService } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.service';
import { ConnectionService } from '@panel/app/services/canvas/tirgger-chain/connections/connection.service';
import { ConnectionValidationService } from '@panel/app/services/canvas/tirgger-chain/connections/connection-validation.service';
import { filterByInteractionViewType } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.helpers';
import { InteractionService } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.service';
import { INTERACTION_ENTITY } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.types';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';
import { FilterParser } from '@panel/app/services/filter/filter.parser';
import { slideLeft } from '@panel/app/shared/animations/slide-left';
import { enterZone } from '@panel/app/shared/functions/zone/enter-zone.function';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { ProductFeatureTextService } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.service';

import { TriggerChainTrackService } from '../shared/services/track-service/trigger-chain-track.service';

/**
 * Компонент для работы с редактированием цепочки
 */
@Component({
  selector: 'cq-trigger-chain-editor',
  templateUrl: './trigger-chain-editor.component.html',
  styleUrls: ['./trigger-chain-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    BlockViewFactory,
    BlockViewParser,
    BlockViewService,
    CanvasBaseService,
    CanvasInteractionService,
    CanvasOverlayHandlerService,
    CanvasRenderService,
    ConnectionService,
    ConnectionValidationService,
    DestroyService,
    FilterParser,
    InteractionService,
    InteractiveBlockPartViewFactory,
    pixiCullProvider,
    pixiTokenProvider,
    pixiViewportProvider,
    TriggerChainEditorStore,
    TriggerChainOverlayEventService,
    TriggerChainOverlayService,
    formSubmitTokenProviders,
  ],
  animations: [slideLeft(300, '365px')],
})
export class TriggerChainEditorComponent implements OnInit, OnDestroy {
  /** Количество активных триггерных сообщений */
  @Input({ required: true })
  activeTriggerMessagesCount!: number;

  /** Приложение */
  @Input({ required: true })
  app!: App;

  /** Цепочка */
  @Input({ required: true })
  chain!: TriggerChain;

  @Input({ required: true })
  eventTypes: EventType[] = [];

  @Input({ required: true })
  autoEvents: AutoEvent[] = [];

  @Input({ required: true })
  currentApp!: App;

  @Input({ required: true })
  properties!: Properties;

  @Input({ required: true })
  tags: UserTag[] = [];

  @Input({ required: true })
  segments: UserSegment[] = [];

  @Input({ required: true })
  templates: TriggerChainTemplate[] = [];

  @Input({ required: true })
  telegramIntegrations: TelegramIntegrationExternal[] = [];

  isValidationStrict: boolean = false;

  accessToAutoMessagesTotal: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  accessToTelegramAutoMessages: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  usedTemplate: TriggerChainTemplate | null = null;

  triggerMessageCountInChain!: number;

  unchangedTriggerChain!: TriggerChain;

  manualSelectStepSubj: BehaviorSubject<TriggerChainStep | null> = new BehaviorSubject<TriggerChainStep | null>(null);

  readonly selectedStep$: Observable<TriggerChainStep | null> = merge(
    this.manualSelectStepSubj.asObservable(),
    this.interactionService.click$.pipe(
      filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
      filter(
        (step) => ![TRIGGER_CHAIN_BLOCK_TYPE.EXIT, TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS].includes(step.view.type),
      ),
      map((event) => this.getStepByUUID(event.view.uuid)),
    ),
    this.canvasInteractionService.click$.pipe(map(() => null)),
  ).pipe(enterZone(this.ngZone), shareReplay(1));

  constructor(
    private readonly blockViewService: BlockViewService,
    protected readonly canvasBaseService: CanvasBaseService,
    private readonly canvasInteractionService: CanvasInteractionService,
    private readonly connectionService: ConnectionService,
    private readonly interactionService: InteractionService,
    private readonly modalHelperService: ModalHelperService,
    protected readonly paywallService: PaywallService,
    private readonly planCapabilityModel: PlanCapabilityModel,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly ngZone: NgZone,
    private readonly destroy$: DestroyService,
    private readonly toastService: ToastService,
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
    private readonly triggerChainModel: TriggerChainModel,
    private readonly triggerChainStepFactory: TriggerChainStepFactory,
    private readonly uiRouter: UIRouter,
    private readonly cdr: ChangeDetectorRef,
    private readonly triggerChainOverlayEventService: TriggerChainOverlayEventService,
    private readonly translocoService: TranslocoService,
    private readonly triggerChainTrackService: TriggerChainTrackService,
    @Inject(WINDOW)
    private readonly window: Window,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSubj: Subject<void>,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly productFeatureTextService: ProductFeatureTextService,
  ) {
    this.initStepSelectListener();
    this.initBlockViewMoveListener();
    this.initConnectionChangeListener();
    this.initOverlayListener();
    this.initUnsavedChangesListener();
    this.initZoomChangeListener();
  }

  ngOnInit() {
    this.initCounterMessageForChain();

    this.changeAccessToAutoMessageTotal(this.activeTriggerMessagesCount);
    this.changeAccessToTelegramAutoMessages(this.chain.steps);

    this.unchangedTriggerChain = cloneDeep(this.chain);
    this.setStoreValues();

    if (this.templates.length && this.isModeCreate()) {
      this.openTemplatesModal();
    }

    if (this.isModeCopy()) {
      this.prepareForCopy();
    }
  }

  ngOnDestroy() {
    this.closeLimitToast('triggerChainAutoMessagesOverLimit');
    this.closeLimitToast('triggerChainTelegramAutoMessagesLimit');
  }

  isDisabledOpenAnimationForStepEditor(step: TriggerChainStep): boolean {
    const editorsWithOpenAnimationDisabled: TriggerChainStepType[] = ['autoMessage', 'sendingConditions', 'filter'];

    return editorsWithOpenAnimationDisabled.includes(step.type);
  }

  initZoomChangeListener(): void {
    this.canvasInteractionService.zoomed$
      .pipe(
        takeUntil(this.destroy$), // Пропускаем дефолтное значение, чтобы не делать scale лишний раз
        debounceTime(100),
      )
      .subscribe(() => {
        this.blockViewService.store.forEach((view) => {
          this.blockViewService.scaleScalableContainerInBlock(view);
        });
        this.connectionService.redrawAllConnections();
      });
  }

  setStoreValues() {
    this.triggerChainEditorStore.currentApp$.next(this.currentApp);
    this.triggerChainEditorStore.properties$.next(this.properties);
    this.triggerChainEditorStore.eventTypes$.next(this.eventTypes);
    this.triggerChainEditorStore.autoEvents$.next(this.autoEvents);
    this.triggerChainEditorStore.segments$.next(this.segments);
    this.triggerChainEditorStore.tags$.next(this.tags);
    this.triggerChainEditorStore.triggerChain$.next(this.chain);
    this.triggerChainEditorStore.telegramIntegrations$.next(this.telegramIntegrations);
  }

  openConfirmRemoveTriggerChainStepModal(step: TriggerChainStep) {
    this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate(
          'triggerChainsEditorComponent.confirmDeleteTriggerChainStepModal.heading',
        ),
        body: this.translocoService.translate('triggerChainsEditorComponent.confirmDeleteTriggerChainStepModal.body'),
        cancelButtonText: this.translocoService.translate('general.cancel'),
        confirmButtonText: this.translocoService.translate('general.remove'),
      })
      .open(ConfirmModalComponent, { centered: true })
      .result.then(
        () => {
          this.deleteStep(step);
        },
        () => {},
      );
  }

  /** Показ модалки с выбором шаблона */
  openTemplatesModal() {
    this.modalHelperService
      .provide(TRIGGER_CHAIN_TEMPLATE_MODAL_DATA_TOKEN, {
        isCreate: true,
        templates: this.templates,
      })
      .open(TriggerChainTemplateComponent, {
        centered: true,
        size: 'lg',
      })
      .result.then((template: TriggerChainTemplate) => {
        this.usedTemplate = template;

        this.triggerChainTrackService.trackTemplateSelection(template);

        this.uiRouter.stateService.go('app.content.triggerChains.editor.createFromTemplate', {
          templateId: template.id,
        });
      })
      .catch(() => {
        this.triggerChainTrackService.trackTemplateModalClose();
      });
  }

  initStepSelectListener(): void {
    this.selectedStep$
      .pipe(
        takeUntil(this.destroy$),
        //// Это немного костыль, но pairwise сохраняет "слепок", и если данные prevStep поменяется, то тут это не отразится
        map((step) => step?.uuid),
        pairwise(),
      )
      .subscribe(([prevStepUUID, currentStepUUID]) => {
        if (prevStepUUID) {
          const prevStep = this.getStepByUUID(prevStepUUID);
          const view = this.blockViewService.updateViewByStep(prevStep, { selected: false });
          this.connectionService.redrawOutgoingConnectionsForView(view);
        }

        if (currentStepUUID) {
          const currentStep = this.getStepByUUID(currentStepUUID);
          const view = this.blockViewService.updateViewByStep(currentStep, { selected: true });
          this.connectionService.redrawOutgoingConnectionsForView(view);
        }
      });
  }

  initBlockViewMoveListener(): void {
    this.interactionService.move$
      .pipe(
        takeUntil(this.destroy$),
        filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
        throttleTime(500, undefined, { trailing: true }),
      )
      .subscribe((event) => {
        this.updateStepCoordinates(event.view);
      });
  }

  initConnectionChangeListener(): void {
    this.connectionService.connectionChange$.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      const sourceStep = this.getStepByUUID(event.sourceStepUUID);

      switch (sourceStep.type) {
        case 'autoMessage':
        case 'sendingConditions':
          sourceStep.meta.nextStep = event.connectionInfo ? event.connectionInfo.target.uuid : null;
          break;
        case 'delay':
        case 'reaction':
        case 'filter':
          if (event.typeOfUpdate === 'nextStepOnSuccess') {
            sourceStep.meta.nextStep = event.connectionInfo ? event.connectionInfo.target.uuid : null;
          } else {
            sourceStep.meta.nextStepOnFail = event.connectionInfo ? event.connectionInfo.target.uuid : null;
          }
          break;
        case 'exit':
          break;
        default:
          throw new Error('Case is not handled');
      }

      this.updateViewAndValidationStatus(sourceStep);
    });
  }

  initCounterMessageForChain() {
    this.triggerMessageCountInChain = this.chain.steps.filter((step) => step.type === 'autoMessage').length;

    this.activeTriggerMessagesCount = this.chain.active
      ? this.activeTriggerMessagesCount
      : this.activeTriggerMessagesCount + this.triggerMessageCountInChain;
  }

  initOverlayListener() {
    let step;

    this.triggerChainOverlayEventService.events$.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      switch (event.type) {
        case 'connection-point-overlay':
          step = this.addStep(event.event.blockType, false);
          const stepView = this.blockViewService.getViewByUUID(step.uuid);
          this.blockViewService.moveViewNextToContainer(stepView, event.event.overlaySource.connectionPoint);
          this.updateStepCoordinates(stepView);
          this.connectionService.addConnectionBySteps(event.event.overlaySource, stepView);
          break;
        case 'action-copy-overlay':
          this.copyStep(this.getStepByUUID(event.event.uuid));
          break;
        case 'action-delete-overlay':
          step = this.getStepByUUID(event.event.uuid);
          if (step.type === 'exit') {
            this.deleteStep(step);
          } else {
            this.openConfirmRemoveTriggerChainStepModal(this.getStepByUUID(event.event.uuid));
          }
          break;
        default:
          throw new Error('Failed to determine the type of event');
      }
    });
  }

  /**
   * Обновление ограничений для триггерных сообщений
   *
   * @param amount - количество триггерных сообщений
   */
  changeAccessToAutoMessageTotal(amount: number) {
    this.accessToAutoMessagesTotal = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
      this.currentApp,
      amount,
      '<=',
    );

    const totalAvailableAmountAutoMessage = this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL)!;

    const hasAccessToAutoMessages = this.accessToAutoMessagesTotal.hasAccess; // Есть доступ до автосообщений
    const noLimits = isNull(totalAvailableAmountAutoMessage); // Нет лимитов по количеству
    const amountLessThanTotalLimit = this.triggerMessageCountInChain <= totalAvailableAmountAutoMessage; // Количество меньше чем тотал лимит

    if (hasAccessToAutoMessages && (noLimits || amountLessThanTotalLimit)) {
      this.closeLimitToast('triggerChainAutoMessagesOverLimit');
    } else {
      this.openAutoMessagesOverLimitToast();
    }
  }

  /**
   * Обновление ограничений для сообщений в TG
   */
  changeAccessToTelegramAutoMessages(steps: TriggerChainStep[]) {
    const hasTelegramAutoMessages = steps.some(
      (step) => step.type === 'autoMessage' && step.meta.message.parts[0].type === MESSAGE_PART_TYPES.TELEGRAM,
    );

    if (hasTelegramAutoMessages) {
      this.accessToTelegramAutoMessages = this.planFeatureAccessService.getAccess(
        PLAN_FEATURE.CHAIN_MESSAGES_TELEGRAM,
        this.currentApp,
      );
    } else {
      this.accessToTelegramAutoMessages = { hasAccess: true, denialReason: null };
    }

    if (this.accessToTelegramAutoMessages.hasAccess) {
      this.closeLimitToast('triggerChainTelegramAutoMessagesLimit');
    } else {
      this.openTelegramAutoMessagesLimitToast();
    }
  }

  /**
   * Обрабатывает клик по кнопке добавления нового шага в триггерную цепочку
   *
   * @param stepType - Тип шага
   */
  onClickOnAddChainStepButton(stepType: TRIGGER_CHAIN_BLOCK_TYPE): void {
    this.addStep(stepType);
  }

  onClickOnCopyButton() {
    this.uiRouter.stateService.go(
      'app.content.triggerChains.editor.copy',
      {
        id: this.chain.id,
      },
      {
        reload: true,
      },
    );
  }

  /** Обрабатывает клик на кнопку запуска цепочки */
  onClickOnRunButton(): void {
    this.chain.active = true;
    this.onClickOnSaveButton();
  }

  /** Обрабатывает клик на кнопку сохранения цепочки */
  onClickOnSaveButton(): void {
    if (this.chain.active && !this.updateChainValidationStatusAndGetIt()) {
      this.formSubmitSubj.next();
      return;
    }

    const updateUnchangedTriggerChain = () => (this.unchangedTriggerChain = cloneDeep(this.chain));

    const obs: Observable<TriggerChain> = this.chain.id
      ? this.triggerChainModel
          .update(this.chain.id, this.chain, {
            properties: this.triggerChainEditorStore.properties$.getValue(),
            userTags: this.triggerChainEditorStore.tags$.getValue(),
          })
          .pipe(
            tap((updatedChain) => (this.chain = updatedChain)),
            tap(() => updateUnchangedTriggerChain()),
          )
      : this.triggerChainModel.create(this.chain).pipe(
          tap((response) => {
            if (this.chain.active) {
              this.triggerChainTrackService.trackFirstRunChain(this.usedTemplate);
            }

            this.uiRouter.stateService.go('app.content.triggerChains.editor.edit', { id: response.id });
          }),
        );

    obs.pipe(tap(() => this.showSaveSuccessToast())).subscribe((chain) => {
      this.triggerChainTrackService.trackUsePropsIntoEmailTitle(chain);
    });
  }

  private addStep(stepType: TRIGGER_CHAIN_BLOCK_TYPE, autoSelect = true): TriggerChainStep {
    const newStep = this.triggerChainStepFactory.generate(stepType);

    this.blockViewService.addViewByStep(newStep, true);
    this.chain.steps = [...this.chain.steps, newStep];

    if (autoSelect) {
      this.manualSelectStepSubj.next(newStep);
    }

    //Получаем новые ограничения
    if (newStep.type === 'autoMessage') {
      this.triggerMessageCountInChain++;
      this.changeAccessToAutoMessageTotal(++this.activeTriggerMessagesCount);
      this.changeAccessToTelegramAutoMessages(this.chain.steps);
    }

    return newStep;
  }

  onStepChange(changedStep: TriggerChainStep) {
    switch (changedStep.type) {
      case 'filter':
        if (
          (changedStep.meta.jinjaFilterTemplate === null || changedStep.meta.jinjaFilterTemplate.length === 0) &&
          (changedStep.meta.filters.filters.props.length === 0 ||
            changedStep.meta.filters.filters.props[0].propertyName === null) &&
          (changedStep.meta.filters.filters.events.length === 0 ||
            changedStep.meta.filters.filters.events[0].eventId === null)
        ) {
          changedStep.meta.nextStep = null;
          changedStep.meta.nextStepOnFail = null;
        }
        break;
      case 'delay':
        if (changedStep.meta.waitForDate === null && changedStep.meta.propertyName === null) {
          changedStep.meta.nextStepOnFail = null;
        }
        break;
    }

    const view = this.updateViewAndValidationStatus(changedStep);
    this.connectionService.redrawOutgoingConnectionsForView(view);
    this.chain.steps = this.chain.steps.map((step) => {
      return step.uuid === changedStep.uuid ? changedStep : step;
    });
  }

  onClickOnShowPaywall() {
    if (!this.accessToAutoMessagesTotal.hasAccess) {
      this.paywallService.showAutoMessageTotalPaywall(
        this.currentApp,
        this.accessToAutoMessagesTotal.denialReason,
        this.triggerMessageCountInChain,
        this.activeTriggerMessagesCount - this.triggerMessageCountInChain,
      );
    } else if (!this.accessToTelegramAutoMessages.hasAccess) {
      this.paywallService.showPaywallForAccessDenial(this.currentApp, this.accessToTelegramAutoMessages.denialReason);
    }
  }

  onClickOnStatusChange(newStatus: boolean) {
    if (!this.chain.id) {
      throw new Error('Should not be called for chain without id');
    }

    if (newStatus && !this.updateChainValidationStatusAndGetIt()) {
      this.formSubmitSubj.next();
      return;
    }

    this.triggerChainModel
      .update(
        this.chain.id,
        { ...this.chain, active: newStatus },
        {
          properties: this.triggerChainEditorStore.properties$.getValue(),
          userTags: this.triggerChainEditorStore.tags$.getValue(),
        },
      )
      .subscribe(() => {
        this.chain.active = newStatus;
        this.initCounterMessageForChain();
        this.cdr.markForCheck();

        if (newStatus) {
          this.triggerChainTrackService.trackActivateChain();
        } else {
          this.triggerChainTrackService.trackPauseChain();
        }
        this.showSaveSuccessToast();
      });
  }

  deleteStep(stepToRemove: TriggerChainStep) {
    this.manualSelectStepSubj.next(null);
    this.connectionService.removeConnectionsByStepUUID(stepToRemove.uuid);
    this.blockViewService.removeViewByStep(stepToRemove);
    this.chain.steps = this.chain.steps.filter((step) => step !== stepToRemove);

    //Получаем новые ограничения
    if (stepToRemove.type === 'autoMessage') {
      this.triggerMessageCountInChain--;
      this.changeAccessToAutoMessageTotal(--this.activeTriggerMessagesCount);
      this.changeAccessToTelegramAutoMessages(this.chain.steps);
    }
  }

  copyStep(stepToCopy: TriggerChainStep) {
    const newStep = this.triggerChainStepFactory.copy(stepToCopy);

    this.blockViewService.addViewByStep(newStep, true);
    this.chain.steps = [...this.chain.steps, newStep];

    //Получаем новые ограничения
    if (stepToCopy.type === 'autoMessage') {
      this.triggerMessageCountInChain++;
      this.changeAccessToAutoMessageTotal(++this.activeTriggerMessagesCount);
      this.changeAccessToTelegramAutoMessages(this.chain.steps);
    }
  }

  private getStepByUUID(uuid: UUID): TriggerChainStep {
    let step = this.chain.steps.find((s) => s.uuid === uuid);

    if (!step) {
      throw new Error('Could not find a step to update');
    }
    return step;
  }

  /** Открыть модалку о превышении лимита */
  openAutoMessagesOverLimitToast() {
    if (this.toastService.isOpen('triggerChainAutoMessagesOverLimit')) {
      return;
    }

    const toastText = this.chain.active
      ? 'triggerChainsEditorComponent.toasts.saveOverAutoTriggerMessageLimit'
      : 'triggerChainsEditorComponent.toasts.runOverAutoTriggerMessageLimit';

    this.toastService.warning(
      this.translocoService.translate(toastText, {
        availableAutoMessageTotal: this.planCapabilityModel.getLimit(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL),
      }),
      '',
      {
        autohide: false,
        name: 'triggerChainAutoMessagesOverLimit',
      },
    );
  }

  /** Открыть модалку о наличии запрещенных сообщений в Telegram */
  openTelegramAutoMessagesLimitToast() {
    if (
      this.toastService.isOpen('triggerChainAutoMessagesOverLimit') ||
      this.toastService.isOpen('triggerChainTelegramAutoMessagesLimit') ||
      !this.accessToTelegramAutoMessages.denialReason
    ) {
      return;
    }

    const toastText = 'triggerChainsEditorComponent.toasts.telegramAutoMessagesLimit';
    const addonText = this.translocoService.translate(
      `models.billingInfo.billingAddOns.${this.accessToTelegramAutoMessages.denialReason.addOn}`,
    );

    this.toastService.warning(
      this.translocoService.translate(toastText, {
        addon: addonText,
      }),
      '',
      {
        autohide: false,
        name: 'triggerChainTelegramAutoMessagesLimit',
        onTap: () => {
          const denialTexts = this.productFeatureTextService.getDenialReasonTexts(
            //@ts-ignore
            this.accessToTelegramAutoMessages.denialReason,
          );
          this.carrotquestHelper.sendChatMessage(denialTexts.message);
        },
      },
    );
  }

  /**
   * Закрыть модалку о превышении лимита
   * @param name - Имя тоста
   * */
  closeLimitToast(name: string) {
    const toast = this.toastService.get(name);

    if (toast) {
      this.toastService.remove(toast);
    }
  }

  private initUnsavedChangesListener() {
    const errorMessage = this.translocoService.translate('triggerChainsEditorComponent.confirmExitModal');

    const chainHasChanged = () => {
      return !isEqual(this.chain, this.unchangedTriggerChain);
    };

    fromEvent(this.window, 'beforeunload')
      .pipe(
        takeUntil(this.destroy$),
        filter(() => chainHasChanged()),
      )
      .subscribe((event) => {
        event.preventDefault();
        return errorMessage;
      });

    this.uiRouter.stateService.$current.onExit = (transition) => {
      if (
        (transition.from().name === 'app.content.triggerChains.editor.create' &&
          transition.to().name === 'app.content.triggerChains.editor.edit') ||
        (transition.from().name === 'app.content.triggerChains.editor.copy' &&
          transition.to().name === 'app.content.triggerChains.editor.edit')
      ) {
        return true;
      }

      if (!chainHasChanged()) {
        return true;
      }
      return confirm(errorMessage);
    };
  }

  private updateStepCoordinates(view: IBlockView) {
    const updatedStep = this.getStepByUUID(view.uuid);
    updatedStep.coordinates.x = view.graphicContainer.x;
    updatedStep.coordinates.y = view.graphicContainer.y;
  }

  private updateViewAndValidationStatus(step: TriggerChainStep) {
    let contentValid = true;
    let connectionsValid = true;

    if (this.chain.active) {
      const validationResult = validateTriggerChainStep(step);

      if (validationResult) {
        for (let error of validationResult.errors) {
          if (!error.groupName) {
            continue;
          }

          if (error.fieldName.includes('meta.nextStep')) {
            connectionsValid = false;
          } else {
            contentValid = false;
          }
        }
        resetValidationState();
      }
    }
    return this.blockViewService.updateViewByStep(step, { contentValid, connectionsValid });
  }

  private updateChainValidationStatusAndGetIt(): boolean {
    const chainValidationResult = triggerChainValidator(this.chain);

    let blocksWithErrors = new Set<UUID>();
    let blocksWithContentErrors = new Set<UUID>();
    let blocksWithConnectionErrors = new Set<UUID>();
    let blocksWithIncomingConnectionErrors = new Set<UUID>();

    for (let error of chainValidationResult.errors) {
      if (!error.groupName) {
        continue;
      }
      blocksWithErrors.add(error.groupName);

      switch (error.fieldName) {
        case 'meta.nextStep':
        case 'meta.nextStepOnFail':
          blocksWithConnectionErrors.add(error.groupName);
          break;
        case 'hasIncomingConnectionsEmpty':
          blocksWithIncomingConnectionErrors.add(error.groupName);
          break;
        default:
          blocksWithContentErrors.add(error.groupName);
      }
    }

    this.chain.steps.forEach((step) => {
      let contentValid = !blocksWithContentErrors.has(step.uuid);
      let connectionsValid = !blocksWithConnectionErrors.has(step.uuid);
      let incomingConnectionsValid = !blocksWithIncomingConnectionErrors.has(step.uuid);

      const stepView = this.blockViewService.getViewByUUID(step.uuid);
      this.blockViewService.updateView(stepView, {
        ...stepView.data,
        ...{ contentValid, connectionsValid, incomingConnectionsValid },
      });
    });

    if (blocksWithErrors.size > 0) {
      this.isValidationStrict = true;

      const [firstStepWithError] = blocksWithErrors;
      const stepView = this.blockViewService.getViewByUUID(firstStepWithError);
      this.canvasBaseService.moveViewportTo(stepView.graphicContainer);

      if (blocksWithContentErrors.size > 0 || blocksWithConnectionErrors.size > 0) {
        this.toastService.danger(
          this.translocoService.translate('triggerChainsEditorComponent.toasts.multipleErrors', {
            count: blocksWithErrors.size,
          }),
        );
      } else {
        this.toastService.danger(
          this.translocoService.translate('triggerChainsEditorComponent.toasts.incomingConnectionsInvalid', {
            count: blocksWithIncomingConnectionErrors.size,
          }),
        );
      }
    } else {
      this.isValidationStrict = false;
    }

    const validationResult = chainValidationResult.valid;
    resetValidationState();
    return validationResult;
  }

  private isModeCopy() {
    return this.uiRouter.stateService.is('app.content.triggerChains.editor.copy');
  }

  private isModeCreate() {
    return this.uiRouter.stateService.is('app.content.triggerChains.editor.create');
  }

  private prepareForCopy(): void {
    delete this.chain.id;

    this.chain.active = false;
    this.chain.name = this.chain.name + ' (копия)';

    this.chain.steps.forEach((step) => {
      delete step.id;

      switch (step.type) {
        case 'autoMessage':
          delete step.meta.message.messageId;
          step.meta.message.parts.forEach((part) => {
            delete part.id;
          });
          break;
      }
    });
  }

  private showSaveSuccessToast() {
    const text = this.translocoService.translate('triggerChainsEditorComponent.toasts.saveSuccess');
    this.toastService.success(text);
  }
}

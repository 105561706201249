<ng-container *transloco="let t; read: 'worksOnPageComponent'">
  <div>
    <div class="d-flex align-items-center mb-10">
      <strong>{{ t('heading') }}</strong>
    </div>
    <div class="custom-control custom-radio">
      <input
        [formControl]="control.controls.enabled"
        [value]="false"
        class="custom-control-input"
        type="radio"
        name="urlHasRadios"
        id="urlHasFalseRadio"
      />
      <label
        class="custom-control-label"
        for="urlHasFalseRadio"
      >
        {{ t('urlHasRadio.anyPage') }}
      </label>
    </div>
    <div class="mb-10">
      <div class="custom-control custom-radio mt-10">
        <input
          [formControl]="control.controls.enabled"
          [value]="true"
          class="custom-control-input"
          type="radio"
          name="urlHasRadios"
          id="urlHasTrueRadio"
        />
        <label
          class="custom-control-label"
          for="urlHasTrueRadio"
        >
          {{ t('urlHasRadio.onlyPage') }}
        </label>
      </div>
    </div>
    <div *ngIf="control.controls.enabled.value">
      <cq-url-input [formControl]="control.controls.url"></cq-url-input>
      <cq-checkbox-control [formControl]="control.controls.hasSubpages">
        {{ t('hasSubpagesCheckbox') }}
      </cq-checkbox-control>
    </div>
  </div>
</ng-container>

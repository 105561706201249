import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { AmocrmSettingsModule } from '@panel/app/pages/integrations/content/amocrm/settings/amocrm-settings.module';

import { AmocrmWrapperSettingsComponent } from './amocrm-wrapper-settings.component';

@NgModule({
  declarations: [AmocrmWrapperSettingsComponent],
  exports: [AmocrmWrapperSettingsComponent],
  imports: [AmocrmSettingsModule, CommonModule, TranslocoModule],
})
export class AmocrmWrapperSettingsModule {}

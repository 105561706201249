import { TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Container, Graphics } from 'pixi.js';

import { spliceText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { AbsBlockBaseView } from '@panel/app/services/canvas/tirgger-chain/blocks/block-base.view';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
  TRIGGER_CHAIN_BLOCK_NAME_MAX_LENGTH,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { BlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import {
  AtlasTexture,
  ElementBuilder,
  ElementBuilderRectangleConfig,
} from '@panel/app/services/canvas/tirgger-chain/builders';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';

/**
 * Абстрактный класс view шага
 */
export abstract class AbsBlockView<Type extends TRIGGER_CHAIN_BLOCK_TYPE> extends AbsBlockBaseView<Type> {
  protected constructor(
    data: Extract<BlockViewData, { type: Type }>,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    transloco: TranslocoService,
    renderer: Renderer,
  ) {
    super(data, interactiveBlockPartViewFactory, transloco, renderer);
  }

  abstract getTitleIconCode(): string;
  abstract getTitleIconColorIfDefault(): number;
  abstract getTitleIconColorIfSelected(): number;
  abstract getTitleIconBackgroundTextureIfDefault(): AtlasTexture;
  abstract getTitleIconBackgroundTextureIfSelected(): AtlasTexture;
  abstract getTitleIconBorderTextureIfDefault(): AtlasTexture | undefined;
  abstract getTitleIconBorderTextureIfSelected(): AtlasTexture | undefined;
  abstract getTitleIconBorderTextureIfIncomingConnectionsInvalid(): AtlasTexture;
  abstract renderContent(): Container;

  /** Рендерит view для canvas */
  render(): void {
    let title: Container = this.renderTitle();
    let contentCard: Container = this.renderContentCard();

    this.setOverlayParams(contentCard);

    this.zoomScalableContainer = title;

    let graphic = CanvasRenderService.renderElementsInRectangle([this.zoomScalableContainer, contentCard], {
      flexDirection: 'column',
      marginBetweenRows: 10,
      width: TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
    });
    this.graphicContainer.addChild(graphic);
  }

  /** Рендерит title блок */
  private renderTitle(): Graphics {
    let iconCode = this.getTitleIconCode();
    let iconText = CanvasRenderService.renderIcon(iconCode, {
      color: this.getTitleIconColor(),
    });
    let icon: Container = ElementBuilder.wrapElementInCircle(iconText, {
      backgroundTextureName: this.getTitleIconBackgroundTexture(),
      borderTextureName: this.getTitleIconBorderTexture(),
    });
    this.connectionPoint = icon;

    let slicedText = spliceText(this.data.name, TRIGGER_CHAIN_BLOCK_NAME_MAX_LENGTH);
    let text: Container = CanvasRenderService.renderText(slicedText, {
      color: HEX_COLOR.GRAY_400,
      wordWrap: false,
    });

    return CanvasRenderService.renderElementsInRectangle([icon, text], {
      alignItems: 'center',
      marginBetweenCols: 5,
    });
  }

  /** Рендерит content-card блок */
  private renderContentCard(): Container {
    let content = this.renderContent.call(this);

    return ElementBuilder.wrapElementsInRectangle([content], {
      background: {
        sideTextureName: 'background-white-292-side',
        innerTextureName: 'background-white-292-inner',
      },
      border: this.getContentCardBorderTextures(),
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 10,
        padding: {
          x: 10,
          y: 10,
        },
      },
    });
  }

  private getContentCardBorderTextures(): ElementBuilderRectangleConfig['border'] {
    switch (true) {
      case !this.data.contentValid || this.data.hoveredInvalid:
        return {
          sideTextureName: 'border-danger600-292-side',
          innerTextureName: 'border-danger600-292-inner',
        };
      case this.data.selected || this.data.hoveredValid:
        return {
          sideTextureName: 'border-primary600-292-side',
          innerTextureName: 'border-primary600-292-inner',
        };
      default:
        return;
    }
  }

  private getTitleIconColor(): number {
    switch (true) {
      case this.data.selected:
        return this.getTitleIconColorIfSelected();
      default:
        return this.getTitleIconColorIfDefault();
    }
  }

  private getTitleIconBackgroundTexture(): AtlasTexture {
    switch (true) {
      case this.data.selected:
        return this.getTitleIconBackgroundTextureIfSelected();
      default:
        return this.getTitleIconBackgroundTextureIfDefault();
    }
  }

  private getTitleIconBorderTexture(): AtlasTexture | undefined {
    switch (true) {
      case !this.data.incomingConnectionsValid || this.data.hoveredInvalid:
        return this.getTitleIconBorderTextureIfIncomingConnectionsInvalid();
      case this.data.selected || this.data.hoveredValid:
        return this.getTitleIconBorderTextureIfSelected();
      default:
        return this.getTitleIconBorderTextureIfDefault();
    }
  }

  private setOverlayParams(contentCard: Container) {
    this.blockOverlayContainer = contentCard;
    if (this.interactiveBlockParts[0]) {
      this.blockOverlayWidthCut = this.interactiveBlockParts[0].connectionPoint.width / 2;
    }
  }
}

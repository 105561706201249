import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { VkFormsIntegrationFormExternal } from '@http/integration/integrations/vk-forms/interfaces/vk-forms-integration.interfaces';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-vk-forms-form-link[control][removeForm][showRemoveFormButton]',
  templateUrl: './form-link.component.html',
  styleUrls: ['./form-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsFormLinkComponent {
  /** Форма сбора заявок */
  @Input() control!: GenericFormControl<VkFormsIntegrationFormExternal['formLink']>;
  /** Показывать ли кнопку удаления формы*/
  @Input() showRemoveFormButton!: boolean;
  /** Callback на удаление формы */
  @Output() removeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
}

/**
 * Статусы активности шаблонов
 */
export const MESSAGE_TEMPLATES_STATUSES = {
  ALL: 'all', // Все
  USED: true, // Используется
  UNUSED: false, // Не используется
};

/**
 * Список статусов активности шаблонов
 */
export const MESSAGE_TEMPLATES_STATUSES_LIST = [
  MESSAGE_TEMPLATES_STATUSES.ALL,
  MESSAGE_TEMPLATES_STATUSES.USED,
  MESSAGE_TEMPLATES_STATUSES.UNUSED,
];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Сервис для сохранения изображения превью сообщений
 */
@Injectable({ providedIn: 'root' })
export class MessagePreviewService {
  constructor(private readonly http: HttpClient) {}

  save(base64ImageData: string): Observable<any> {
    const dataURLtoFile = (dataurl: string, filename: string) => {
      let arr = dataurl.split(',');
      let mimeString = arr[0].match(/:(.*?);/)![1];
      let extension = mimeString.match(/\/(jpeg|png)$/i)?.[1] || 'bin';
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
      }
      return new File([u8arr], `${filename}.${extension}`, { type: mimeString });
    };

    let file = dataURLtoFile(base64ImageData, 'preview');

    return this.http.post('/messages/preview', {
      file: file,
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { ModalHelperService } from '@panel/app/services';
import { PromptModalModule } from '@panel/app/shared/modals/prompt/prompt-modal.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { FilterAndSortUserPropsPipe } from './pipes/filter-and-sort-user-props.pipe';
import { PropertyOptionDirective } from './property-option.directive';
import { PropertySelectorComponent } from './property-selector.component';

@NgModule({
  declarations: [PropertySelectorComponent, FilterAndSortUserPropsPipe, PropertyOptionDirective],
  imports: [CommonModule, TranslocoModule, SharedModule, NgSelectModule, ReactiveFormsModule, PromptModalModule],
  exports: [PropertySelectorComponent, PropertyOptionDirective],
  providers: [ModalHelperService],
})
export class PropertySelectorModule {}

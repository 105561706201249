/**
 * Контроллер для превью сообщения из триггерной цепочки в статистике
 */
import {
  EMAIL_TYPES,
  MESSAGE_PART_TYPES,
  POPUP_CHAT_TYPES,
  TYPE_OS_PREVIEW,
} from '../../../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular
    .module('myApp.triggerChains.messagePreview')
    .controller('CqTriggerChainMessagePreviewController', CqTriggerChainMessagePreviewController);

  function CqTriggerChainMessagePreviewController(caseStyleHelper, messagePartModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.caseStyleHelper = caseStyleHelper;
      vm.EMAIL_TYPES = EMAIL_TYPES;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.messageParts = messagePartModel.filterMessageParts(vm.currentMessage.activeTestGroup.parts); // варианты сообщения
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.TYPE_OS_PREVIEW = TYPE_OS_PREVIEW;
    }
  }
})();

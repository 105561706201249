/**
 * Компонент для превью шаблонов за пределами интерфейса админки (для скриншотов)
 * !!! НЕ УДАЛЯТЬ! То, что этот компонент используется только в одном неиспользуемом роуте - только кажется. Этот компонент исопльзуется бэкендом для создания скриншотов для превью шаблонов
 */
(function () {
  'use strict';

  require('./preview.controller');

  angular
    .module('myApp.externalTemplatePreviewApp')
    .component('cqMessageTemplatesExternalPreview', cqMessageTemplatesExternalPreview());

  function cqMessageTemplatesExternalPreview() {
    return {
      controller: 'CqMessageTemplatesExternalPreviewController',
      controllerAs: 'vm',
      template: require('./preview.html'),
    };
  }
})();

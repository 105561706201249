/**
 * Контроллер для переключения типа статистики по сообщению из цепочки
 */
import { SENDING_TYPES } from '../../../../../../app/http/message/message.constants';
import { MESSAGE_PART_TYPES } from '../../../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular
    .module('myApp.triggerChains.sendingTypeSwitcher')
    .controller('CqTriggerChainSendingTypeSwitcherController', CqTriggerChainSendingTypeSwitcherController);

  function CqTriggerChainSendingTypeSwitcherController($filter, $translate, carrotquestHelper, messagePartModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.getBlockPopupCount = getBlockPopupCount;
      vm.onClickSendingType = onClickSendingType;
      vm.SENDING_TYPES = SENDING_TYPES;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
    }

    /**
     * Подсчёт количества блочных поп-апов в сообщении
     *
     * @returns {number}
     */
    function getBlockPopupCount() {
      const messageParts = messagePartModel.filterMessageParts(vm.currentMessage.activeTestGroup.parts); // варианты сообщения

      return $filter('filter')(messageParts, filterBlockPopups).length;

      function filterBlockPopups(messagePart) {
        return (
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
        );
      }
    }

    /**
     * Обработчик клика по кнопке смены типа статистики
     *
     * @param {SENDING_TYPES} sendingType Тип статистики
     */
    function onClickSendingType(sendingType) {
      vm.sendings.currentSendingType = sendingType;
      trackClickSendingTypeFilter(sendingType);
    }

    /**
     * Трек клика на фильтр по действиям получателей
     *
     * @param {SENDING_TYPES} sendingType Действие получателя сообщения
     */
    function trackClickSendingTypeFilter(sendingType) {
      const sendingTypeTranslate = $translate.instant(`models.message.sendingTypes.${sendingType}`);

      carrotquestHelper.track('Цепочки сообщений - клик по типу получателей в "Пользователи"', {
        Название: vm.currentMessage.name,
        'Тип получателей': sendingTypeTranslate,
      });
    }
  }
})();

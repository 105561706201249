(function () {
  'use strict';

  require('./chat-settings-section-header.controller');

  angular.module('myApp.chatSettings').component('cqChatSettingsSectionHeader', cqChatSettingsSectionHeader());

  function cqChatSettingsSectionHeader() {
    return {
      bindings: {
        state: '<?',
        type: '<',
      },
      controller: 'CqChatSettingsSectionHeaderController',
      controllerAs: 'vm',
      template: require('./chat-settings-section-header.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TeamMember } from '@http/team-member/team-member.types';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';

@Component({
  selector: 'cq-branch-action-operator[actionForm]',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionOperatorComponent {
  @Input() actionForm!: BaseBotActionForm; // Форма с действием

  constructor(public readonly state: StateService) {}

  /**
   * Функция выбора члена команды
   *
   * @param teamMember Член команды
   */
  teamMemberSelect(teamMember: TeamMember): TeamMember['id'] {
    return teamMember.id;
  }

  searchOperatorFn(term: string, item: TeamMember) {
    return !!item.name && item.name.toLowerCase().search(term.toLowerCase()) > -1;
  }
}

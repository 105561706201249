import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { EmojiService } from '@panel/app/shared/services/emoji/emoji.service';

@Component({
  selector: 'cq-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiComponent implements OnInit {
  /** большой смайл или маленький */
  @Input() isBig?: boolean;
  /** название смайла, по которому берётся изображение */
  @Input() name!: string;

  protected emoji: any;
  protected sideSize: any;
  protected backgroundPosition: any;

  constructor(protected readonly emojiService: EmojiService) {}

  ngOnInit(): void {
    this.emoji = this.emojiService.getEmojiByName(this.name);

    // для больших смайлов длина стороны равна 32px, для маленьких - 22px
    if (this.isBig) {
      this.sideSize = 32;
    } else {
      this.sideSize = 22;
    }

    this.backgroundPosition =
      this.emoji.position.x * this.sideSize * -1 + 'px ' + this.emoji.position.y * this.sideSize * -1 + 'px';
    this.sideSize += 'px';
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { CONVERSATIONS_SETTINGS_PAGE_ROUTES } from '@panel/app/pages/conversations-settings/conversations-settings-page.routes';
import { ChannelEditorModule } from '@panel/app/pages/conversations-settings/pages/channel-editor/channel-editor.module';
import { ConversationsSettingsModule } from '@panel/app/pages/conversations-settings/pages/conversations-settings/conversations-settings.module';

import { ConversationsSettingsPageComponent } from './conversations-settings-page.component';

@NgModule({
  declarations: [ConversationsSettingsPageComponent],
  exports: [ConversationsSettingsPageComponent],
  imports: [
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: CONVERSATIONS_SETTINGS_PAGE_ROUTES }),
    ConversationsSettingsModule,
    ChannelEditorModule,
  ],
})
export class ConversationsSettingsPageModule {}

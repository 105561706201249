/** Шаги стартер-гайда */
import { APP_TASKS } from '@http/app/app.constants';

export enum STARTER_GUIDE_STEPS {
  /** Чем полезен сервис */
  ABOUT_SERVICE = 'onboarding_v2_about_service',
  /** Внешний вид чата */
  CHAT_SETTINGS = 'onboarding_v2_chat_settings',
  /** Первое взаимодействие с пользователем. Нужен только для рендера в шагах, больше нигде не используется */
  ENGAGEMENT_IN_A_CONVERSATION = 'onboarding_v2_engagement_in_a_conversation',
  /** Первое триггерное сообщение */
  FIRST_TRIGGER_MESSAGE = 'onboarding_v2_first_trigger_message',
  /** Сбор лидов */
  LEADS_COLLECTION = 'onboarding_v2_leads_collection',
  /** Сбор лидов */
  LEADS_PASSING = 'onboarding_v2_leads_passing',
  /** Получить больше */
  GET_MORE = 'onboarding_v2_get_more',
  /** Установка кода */
  INSTALLATION_SERVICE = 'onboarding_v2_installation_service',
  /** Подключение социальных сетей */
  SOCIAL_INTEGRATIONS = 'onboarding_v2_social_integrations',
  /** Прогревание тех, кто не готов купить */
  WARM_UP = 'onboarding_v2_warm_up',
  /** Рабочее время */
  WORK_TIME = 'onboarding_v2_work_time',
  /** Написать себе */
  WRITE_SELF = 'onboarding_v2_write_self',
}

/** Набор шагов для секции «Основы» в стартергайде */
export const STARTER_GUIDE_STEPS_BASICS = [
  STARTER_GUIDE_STEPS.CHAT_SETTINGS,
  STARTER_GUIDE_STEPS.WRITE_SELF,
  STARTER_GUIDE_STEPS.INSTALLATION_SERVICE,
];

/** Задачи, который можно выбрать в стартергайде */
export enum STARTER_GUIDE_TASKS {
  TALK_TO_CUSTOMERS = 'talkToCustomers',
  RAISE_SITE_CONVERSION = 'raiseSiteConversion',
}

/** Набор шагов под выбранную задачу */
export const STARTER_GUIDE_STEPS_BY_TASK = {
  [STARTER_GUIDE_TASKS.TALK_TO_CUSTOMERS]: [
    STARTER_GUIDE_STEPS.WORK_TIME,
    //STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE,
    STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION,
    STARTER_GUIDE_STEPS.SOCIAL_INTEGRATIONS,
  ],
  [STARTER_GUIDE_TASKS.RAISE_SITE_CONVERSION]: [
    STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION,
    //STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE,
    //STARTER_GUIDE_STEPS.LEADS_COLLECTION,
    STARTER_GUIDE_STEPS.LEADS_PASSING,
    STARTER_GUIDE_STEPS.WARM_UP,
  ],
};

/** Задача стартергайда в завимости от выбранной задачи в регистрации */
export const STARTER_GUIDE_TASK_BY_APP_TASK = {
  [APP_TASKS.TALK_TO_CUSTOMERS]: STARTER_GUIDE_TASKS.TALK_TO_CUSTOMERS,
  [APP_TASKS.RAISE_PURCHASE_CONVERSION]: STARTER_GUIDE_TASKS.RAISE_SITE_CONVERSION,
  [APP_TASKS.RAISE_LEAD_CONVERSION]: STARTER_GUIDE_TASKS.RAISE_SITE_CONVERSION,
  [APP_TASKS.SUPPORT_CUSTOMERS]: STARTER_GUIDE_TASKS.TALK_TO_CUSTOMERS,
  [APP_TASKS.OTHER]: STARTER_GUIDE_TASKS.TALK_TO_CUSTOMERS,
};

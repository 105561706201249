import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';
import { map } from 'rxjs/operators';

import { EventTypeModel } from '@http/event-type/event-type.model';
import {
  CLICKHOUSE_PROPERTY_OPERATIONS,
  CLICKHOUSE_PROPERTY_OPERATIONS_WITH_VALUES,
  CLICKHOUSE_USER_SYSTEM_PROPERTIES,
} from '@http/property/property.constants';
import { EventType } from '@http/property/property.model';

/**
 * Сервис для работы с воронками
 */
@Injectable({ providedIn: 'root' })
export class FunnelModel {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly http: HttpClient,
    private readonly eventTypeModel: EventTypeModel,
  ) {}

  /**
   * Воронка по умолчанию
   */
  static DEFAULT_FUNNEL = {
    name: '',
    steps: [
      [
        {
          includeProperty: false,
          operation: CLICKHOUSE_PROPERTY_OPERATIONS.KNOWN,
        },
      ],
    ],
  };

  /**
   * Значение свойства в сегментации, показывающее количество счётчиков для каждого шага воронки сверх лимита сегментации
   */
  static EXCEEDED_LIMIT_PROPERTY_VALUE = '$others';

  /**
   * Лимит возвращаемых записей по умолчанию в сегментации воронки
   */
  static SEGMENTATION_LIMIT = 200;

  /**
   * Значение свойства в сегментации, показывающее общее количество счётчиков для каждого шага воронки в сегментации
   */
  static TOTAL_PROPERTY_VALUE = '$totals';

  /**
   * Префикс, добавляемый к свойствам пользователя при парсинге воронки в формат сервера
   * Этого требует бэк-энд, обычные свойства посылаются как есть
   */
  static USER_PROPERTY_PREFIX = '$user__';

  /**
   * Размер 'окна' воронки в секундах (по умолчанию 30 дней). Что это конкретно такое - лучше спросить у Миши
   */
  static WINDOW_SIZE = 60 * 60 * 24 * 30;

  /**
   * Построение воронки
   *
   * @param funnelId ID воронки
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param cacheTime=0 Период кэширования данных воронки в секундах
   */
  count(funnelId: string, startDate: moment.Moment, endDate: moment.Moment, cacheTime: number = 0) {
    const params = {
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      cache_time: cacheTime || 0,
    };

    return this.http.get('/funnels/' + funnelId + '/count', { params });
  }

  /**
   * Создание воронки
   *
   * @param appId ID приложения
   * @param funnel Воронка
   * @param isCreateMain флаг создания главной воронки
   */
  create(appId: string, funnel: any, isCreateMain: boolean) {
    funnel = this.parseFunnelToExternalFormat(funnel);

    const body = {
      app: appId,
      is_default: isCreateMain,
      name: funnel.name,
      steps: funnel.steps,
      window: FunnelModel.WINDOW_SIZE,
    };

    return this.http.post<any>('/funnels', body).pipe(map((data) => data.id));
  }

  /**
   * Получение воронки
   *
   * @param funnelId ID воронки
   * @param includeEventTypes Флаг получения сериализованного представления event_type
   *  Если true - вернётся сериализованное представление
   *  Если false - вернётся ID
   */
  get(funnelId: string, includeEventTypes: boolean) {
    const params = {
      include_event_types: includeEventTypes || false,
    };

    return this.http.get('/funnels/' + funnelId, { params }).pipe(
      map((data) => {
        this.parseFunnelToInternalFormat(data);
        return data;
      }),
    );
  }

  /**
   * Получение воронки по умолчанию
   * @param isCreateMain флаг создания главной воронки
   */
  getDefault(isCreateMain: boolean) {
    const defaultFunnel = angular.copy(FunnelModel.DEFAULT_FUNNEL);

    if (isCreateMain) {
      defaultFunnel.name = this.transloco.translate('models.funnel.mainName');
    } else {
      defaultFunnel.name = this.transloco.translate('models.funnel.defaultName', { time: moment().format('L') });
    }

    return defaultFunnel;
  }

  /**
   * Получения списка воронок для приложения
   *
   * @param appId ID приложения
   * @param includeEventTypes Флаг получения сериализованного представления event_type
   *  Если true - вернётся сериализованное представление
   *  Если false - вернётся ID
   */
  getList(appId: string, includeEventTypes = false) {
    const params = {
      include_event_types: includeEventTypes || false,
    };

    return this.http.get<any[]>('/apps/' + appId + '/funnels', { params }).pipe(
      map((funnels) => {
        for (let i = 0; i < funnels.length; i++) {
          const funnel = funnels[i];

          this.parseFunnelToInternalFormat(funnel);
        }

        return funnels;
      }),
    );
  }

  /**
   * Получение главной воронки для отображения ее виджета
   *
   * @param appId ID приложения
   * @param includeEventTypes Флаг получения сериализованного представления event_type
   * @return вернётся воронка отмеченная специальным флагом
   */
  getMain(appId: string, includeEventTypes = false) {
    const params = {
      include_event_types: includeEventTypes || false,
      ignoreErrors: true,
    };

    return this.http.get('/apps/' + appId + '/funnels/default', { params }).pipe(
      map((funnel) => {
        this.parseFunnelToInternalFormat(funnel);
        return funnel;
      }),
    );
  }

  /**
   * Функция связывания воронок с типами событий
   * Используется для того, чтобы обращаться к типам событий по ссылке
   *
   * @param funnels Массив воронок
   * @param eventTypes Массив типов событий
   */
  linkWithEventTypes(funnels: any[], eventTypes: EventType[]) {
    for (let i = 0; i < funnels.length; i++) {
      const funnel = funnels[i];

      for (let j = 0; j < funnel.steps.length; j++) {
        const step = funnel.steps[j];

        for (let k = 0; k < step.length; k++) {
          const condition = step[k];
          let eventTypeId: string;

          if (angular.isObject(condition.eventType)) {
            eventTypeId = condition.eventType.id;
          } else {
            eventTypeId = condition.eventType;
          }

          condition.eventType = eventTypes.find((eventType) => eventType.id === eventTypeId);
        }
      }
    }
  }

  /**
   * Парсинг воронки во внутренний формат
   *
   * @param {Object} funnel Воронка
   */
  parseFunnelToInternalFormat(funnel: any) {
    for (let i = 0; i < funnel.steps.length; i++) {
      const step = funnel.steps[i];

      for (let j = 0; j < step.length; j++) {
        const condition = step[j];

        // если в условии шага воронки пришёл полноценный тип события - его надо распарсить
        if (typeof condition.eventType === 'object' && condition.eventType !== null) {
          this.eventTypeModel.parse(condition.eventType);
        }

        // если в условии шага есть свойство события - его надо распарсить и выставить includeProperty в true
        if (condition.property) {
          condition.includeProperty = true;
          condition.property = condition.property.replace(FunnelModel.USER_PROPERTY_PREFIX, '');
        }
      }
    }
  }

  /**
   * Парсинг воронки в формат сервера
   *
   * @param funnel Воронка
   */
  parseFunnelToExternalFormat(funnel: any) {
    const funnelCopy = cloneDeep(funnel);

    for (let i = 0; i < funnelCopy.steps.length; i++) {
      const step = funnelCopy.steps[i];

      for (let j = 0; j < step.length; j++) {
        const condition = step[j];

        // если eventType - объект, то надо взять только его ID
        if (angular.isObject(condition.eventType)) {
          condition.eventType = condition.eventType.id;
        }

        // если в условие не включается свойство события - нужно удалить все поля, относящиеся к свойству
        if (!condition.includeProperty) {
          delete condition.property;
          delete condition.operation;
          delete condition.value;
        }

        // если в свойстве выбрано свойство пользователя - нужно добавить к нему префикс, этого требует бэк-энд
        if (~CLICKHOUSE_USER_SYSTEM_PROPERTIES.indexOf(condition.property)) {
          condition.property = FunnelModel.USER_PROPERTY_PREFIX + condition.property;
        }

        // если задана операция, для которой указание значения не требуется - нужно удалить значение
        if (!~CLICKHOUSE_PROPERTY_OPERATIONS_WITH_VALUES.indexOf(condition.operation)) {
          delete condition.value;
        }

        // тут удаляется всякий мусор, который не должен попасть на бэк-энд
        delete condition.includeProperty;
      }
    }

    return funnelCopy;
  }

  /**
   * Парсинг сегментации во внутренний формат
   *
   * @param segmentation Сегментация воронки
   * @param limit Лимит значений в сегментации
   */
  parseSegmentation(segmentation: any[], limit: number) {
    // каждый раз в нулевом элементе приходит общее количество счётчиков ($totals, приходит первым элементом), и его надо заменить
    // эта проверка по сути тут не нужна, сделана для наглядности, с использованием константы
    if (segmentation[0][0] == FunnelModel.TOTAL_PROPERTY_VALUE) {
      segmentation[0][0] = this.transloco.translate('models.funnel.segmentation.total');
    }

    // если в сегментации пришло данных о значении свойства на 2 больше (limit + $totals + $others) - значит пришло ещё и остальное количество счётчиков ($others, приходит последним элементом), и его надо заменить
    if (
      segmentation.length == limit + 2 &&
      segmentation[segmentation.length - 1][0] == FunnelModel.EXCEEDED_LIMIT_PROPERTY_VALUE
    ) {
      segmentation[segmentation.length - 1][0] = this.transloco.translate('models.funnel.segmentation.others');
    }

    return segmentation;
  }

  /**
   * Удаление воронки
   *
   * @param funnelId ID воронки
   */
  remove(funnelId: string) {
    return this.http.delete('/funnels/' + funnelId);
  }

  /**
   * Сохранение воронки
   *
   * @param funnel Воронка
   */
  save(funnel: any) {
    funnel = this.parseFunnelToExternalFormat(funnel);

    const body = {
      name: funnel.name,
      steps: funnel.steps,
      window: FunnelModel.WINDOW_SIZE,
    };

    return this.http.put('/funnels/' + funnel.id, body).pipe(map(() => funnel.id));
  }

  /**
   * Сегментация воронки
   *
   * @param funnelId ID воронки
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param propertyName Название свойства, по которому сегментируется воронка
   * @param funnelStepNumber Номер шага воронки, который сегментируется
   * @param includeFunnel Включать ли воронку в результат
   * @param limit Максимальное количество записей в результате
   */
  segment(
    funnelId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    propertyName: string,
    funnelStepNumber: number,
    includeFunnel = true,
    limit: number = FunnelModel.SEGMENTATION_LIMIT,
  ) {
    const params = {
      end_date: endDate.format('YYYY-MM-DD'),
      include_funnel: includeFunnel,
      limit: limit,
      property_name: ~CLICKHOUSE_USER_SYSTEM_PROPERTIES.indexOf(propertyName)
        ? FunnelModel.USER_PROPERTY_PREFIX + propertyName
        : propertyName,
      start_date: startDate.format('YYYY-MM-DD'),
      step: funnelStepNumber,
    };

    return this.http.get<any>('/funnels/' + funnelId + '/segment', { params }).pipe(
      map((data) => {
        let returnedValue;

        // от include_funnel зависит формат, в котором возвращаются данные, поэтому парсить их нужно по-разному
        if (params.include_funnel) {
          returnedValue = {
            counters: data.funnel,
            segmentation: this.parseSegmentation(data.segments, params.limit),
          };
        } else {
          returnedValue = this.parseSegmentation(data, params.limit);
        }

        return returnedValue;
      }),
    );
  }

  /**
   * Сделать воронку основной
   *
   * @param appId ID приложения
   * @param funnelId ID воронки
   */
  setMain(appId: string, funnelId: string) {
    const body = {
      funnel: funnelId,
    };

    return this.http.post('/apps/' + appId + '/funnels/default', body);
  }
}

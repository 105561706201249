/**
 * Директива, необходимая для работы поповера, копипаста с uibPopoverTemplatePopup
 */
(function () {
  'use strict';

  angular.module('myApp.directives.colorPicker').directive('cqColorPickerPopup', function () {
    return {
      restrict: 'A',
      scope: {
        contentExp: '&',
        originScope: '&',
        uibTitle: '@',
      },
      templateUrl: 'uib/template/popover/popover-template.html', // используется темплейт из ui-bootstrap
    };
  });
})();

<ng-container *transloco="let t; read: 'buttonMenuComponent'">
  <div
    ngbDropdown
    (openChange)="openChange.emit($event)"
  >
    <div class="d-flex justify-center">
      <button
        class="btn btn-secondary"
        ngbDropdownToggle
      >
        <i class="cqi-chat cqi-menu-burger"></i>
      </button>
    </div>
    <div ngbDropdownMenu>
      <button
        *ngIf="hasPermissionToUserList"
        ngbDropdownItem
        (click)="searchByUserClicked.emit()"
        type="button"
      >
        <i class="cqi-sm cqi-user-o mr-5"></i>
        <span>{{ t('usersList') }}</span>
      </button>

      <!-- Смена темы -->
      <button
        ngbDropdownItem
        type="button"
        (click)="toggleTheme()"
      >
        <ng-container *ngIf="!isDarkThemeActive">
          <i class="cqi-sm cqi-moon-stars mr-5"></i>
          <span>{{ t('darkThemeOn') }}</span>
        </ng-container>
        <ng-container *ngIf="isDarkThemeActive">
          <i class="cqi-sm cqi-sun mr-5"></i>
          <span>{{ t('lightThemeOn') }}</span>
        </ng-container>
      </button>

      <ng-container *ngIf="hasPermissionMoreOperator">
        <div class="dropdown-divider my-5"></div>

        <button
          ngbDropdownItem
          type="button"
          (click)="goToConversationsChannelsSettingsPage()"
        >
          <i class="cqi-sm cqi-sliders mr-5"></i>
          <span>{{ t('conversationsChannelsSettings') }}</span>
        </button>

        <button
          *ngIf="accessToConversationsAutoAssignment.hasAccess"
          ngbDropdownItem
          type="button"
          (click)="goToConversationsAutoAssignmentSettingsPage()"
        >
          <i class="cqi-sm cqi-square-arrow-up mr-5"></i>
          <div class="align-top d-inline-block">
            <div>{{ t('conversationsAutoAssign.button') }}</div>
            <ng-container *tuiLet="teamMemberWithAutoAssign$ | async as teamMemberWithAutoAssign">
              <div
                *ngIf="teamMemberWithAutoAssign"
                class="small text-success"
              >
                {{ t('conversationsAutoAssign.enabled', { count: teamMemberWithAutoAssign }) }}
              </div>
              <div
                *ngIf="!teamMemberWithAutoAssign"
                class="small text-secondary"
              >
                {{ t('conversationsAutoAssign.disabled') }}
              </div>
            </ng-container>
          </div>
        </button>

        <button
          *ngIf="!accessToConversationsAutoAssignment.hasAccess"
          ngbDropdownItem
          type="button"
          (click)="paywallService.showPaywall(currentApp, accessToConversationsAutoAssignment.denialReason)"
        >
          <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
          <div class="align-top d-inline-block">
            <div>{{ t('conversationsAutoAssign.button') }}</div>
            <div class="small text-secondary">
              {{ t('conversationsAutoAssign.disabled') }}
            </div>
          </div>
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>

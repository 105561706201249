import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { EventPropsEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/components/event-props-editor/event-props-editor.module';
import { HeadersEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/components/headers-editor/headers-editor.module';
import { QueryParamsEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/components/query-params-editor/query-params-editor.module';
import { RequestBodyEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/components/request-body-editor/request-body-editor.module';
import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { WebhookPartEditorComponent } from './webhook-part-editor.component';

@NgModule({
  declarations: [WebhookPartEditorComponent],
  exports: [WebhookPartEditorComponent],
  imports: [
    CommonModule,
    EventTypeSelectModule,
    EventPropsEditorModule,
    FormsModule,
    TranslocoModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    RequestBodyEditorModule,
    QueryParamsEditorModule,
    HeadersEditorModule,
    SharedModule,
    NgbTooltipModule,
  ],
})
export class WebhookPartEditorModule {}

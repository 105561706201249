<ng-container *transloco="let t; read: 'eventTypesComponent'">
  <table
    *ngIf="eventTypes.length"
    class="table table-align-middle table-sm w-100 table-sticky-header position-sticky"
  >
    <thead>
      <tr>
        <th class="shrink-by-content">{{ t('table.headings.id') }}</th>
        <th>{{ t('table.headings.name') }}</th>
        <th class="shrink-by-content">{{ t('table.headings.point') }}</th>
        <th class="shrink-by-content"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let eventType of eventTypes | filterByImpure: ['visible']:true | orderByImpure: 'prettyName';
          trackBy: trackById
        "
      >
        <td class="shrink-by-content">{{ eventType.id }}</td>
        <td class="position-relative">
          <span class="table-cell-name position-absolute left-0 right-0 text-truncate margin-left-10 margin-right-10">{{
            eventType.prettyName
          }}</span>
        </td>
        <td class="shrink-by-content">{{ eventType.score }}</td>
        <td class="shrink-by-content">
          <button
            class="btn btn-borderless-primary"
            (click)="openEditEventTypeModal(eventType)"
          >
            <i class="btn-icon cqi-sm cqi-pencil"></i>
          </button>
          <button
            class="btn btn-borderless-primary"
            (click)="openRemoveEventTypeModal(eventType)"
          >
            <i class="btn-icon cqi-sm cqi-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div
    *ngIf="eventTypes.length > 0 && !hasCustomEvents"
    class="card-body text-center events-zero"
  >
    <img
      class="margin-bottom-10"
      src="../../../../../assets/img/default/events-zero-2.png"
    />
    <div class="margin-bottom-10">{{ t('zeroData.noEvents.description') }}</div>
    <div class="margin-bottom-10">
      <a
        class="btn btn-primary"
        uiSref="app.content.trackmaster"
        >{{ t('zeroData.noEvents.useTrackMasterButton') }}</a
      >
    </div>
    <a
      target="_blank"
      [attr.href]="t('zeroData.noEvents.useApiLink.href')"
      >{{ t('zeroData.noEvents.useApiLink.text') }}</a
    >
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'messageNameEditorComponent'">
  <div class="d-flex align-items-center justify-content-start">
    <!-- В span вставляется текст для вычисления ширины input -->
    <span
      class="hidden-layer-input"
      #messageNameHideLayer
      [textContent]="formControl.value"
    ></span>
    <div>
      <input
        #messageNameInput
        type="text"
        class="bot-name-input no-border p-0"
        (blur)="isMessageNameInputFocused = false"
        (focus)="isMessageNameInputFocused = true"
        [formControl]="formControl"
        [style.width]="messageNameHideLayer.clientWidth + 'px'"
      />
      <cq-validation-messages
        position="bottom"
        [control]="formControl"
      >
        <cq-validation-message errorName="required">
          {{ t('errors.required') }}
        </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('errors.maxLength', { maxLength: MESSAGE_NAME_MAX_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>

    <button
      class="btn btn-borderless-primary ml-10"
      type="button"
      [ngClass]="{ focus: isMessageNameInputFocused }"
      (click)="messageNameInput.focus()"
    >
      <i class="btn-icon cqi-sm cqi-pencil"></i>
    </button>
  </div>
</ng-container>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import {
  EVENT_TYPE_GROUP,
  EVENT_TYPE_GROUPS_ORDER,
  SYSTEM_EVENT_TYPES,
  SYSTEM_EVENT_TYPES_BY_GROUPS,
} from 'app/http/event-type/event-type.constants';
import { ApiCreateRequest, ApiGetListRequest, ApiUpdateRequest } from 'app/http/event-type/event-type.types';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { AppService } from '@http/app/services/app.service';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { EventType } from '@http/property/property.model';

/**
 * Модель типа события
 */
@Injectable({ providedIn: 'root' })
export class EventTypeModel {
  /**
   * Тип события по умолчанию
   */
  private DEFAULT_EVENT_TYPE: EventType = {
    active: true, // Флаг, показывающий выполнялось ли событие хоть раз
    group: EVENT_TYPE_GROUP.CUSTOM,
    groupOrder: EVENT_TYPE_GROUPS_ORDER[EVENT_TYPE_GROUP.CUSTOM],
    id: '0',
    name: '',
    prettyName: '',
    score: 0, // Балл события
    visible: true, // Флаг, показывающий не удалено ли событие
  };

  constructor(
    private transloco: TranslocoService,
    private http: HttpClient,
    private featureModel: FeatureModel,
    private readonly appService: AppService,
  ) {}

  /**
   * Создание типа события
   *
   * @param appId ID приложения
   * @param eventTypes Имя типа события или массив имён
   */
  public create(appId: App['id'], eventTypes: EventType['name']): Observable<EventType>;
  public create(appId: App['id'], eventTypes: EventType['name'][]): Observable<EventType[]>;
  public create(
    appId: App['id'],
    eventTypes: EventType['name'] | EventType['name'][],
  ): Observable<EventType | EventType[]> {
    let body: ApiCreateRequest = {
      event_types: Array.isArray(eventTypes) ? eventTypes : [eventTypes],
    };

    return this.http.post<any>(`/apps/${appId}/createeventtypes`, body).pipe(
      map((eventTypes) => {
        for (let i = 0; i < eventTypes.length; i++) {
          this.parse(eventTypes[i]);
        }

        // если создавался один тип события - возвращаем именно тип события, а не массив типов событий
        if (eventTypes.length == 1) {
          return eventTypes[0];
        } else {
          return eventTypes;
        }
      }),
    );
  }

  /**
   * Фильтрация событий
   *
   * @param event Объект с информацией о событии
   */
  public filterEvents(event: EventType): boolean {
    return (
      !event.name.includes('$push') ||
      (this.featureModel.hasAccess(FEATURES.WEB_PUSH, this.appService.app && this.appService.app.created) &&
        event.name.includes('$push'))
    );
  }

  /**
   * Получение типа события по умолчанию
   *
   * @param name Имя события
   */
  public getDefault(name: EventType['name']): EventType {
    let defaultEventType = cloneDeep(this.DEFAULT_EVENT_TYPE);

    defaultEventType.name = name;
    defaultEventType.prettyName = this.parseName(defaultEventType.name);

    return defaultEventType;
  }

  /**
   * Получение группы события
   * @param eventType
   * @private
   */
  private getGroup(eventType: EventType): EVENT_TYPE_GROUP {
    if (!!~eventType.name.indexOf('$')) {
      for (let group in SYSTEM_EVENT_TYPES_BY_GROUPS) {
        if (SYSTEM_EVENT_TYPES_BY_GROUPS[group as EVENT_TYPE_GROUP].includes(eventType.name)) {
          return group as EVENT_TYPE_GROUP;
        }
      }
      //NOTE на локалках и бетах есть события начинающиеся с $ но не являющиеся системными событиями
      // поэтому пришлось тут возвращать EVENT_TYPE_GROUP.CUSTOM
      return EVENT_TYPE_GROUP.CUSTOM;
    } else if (eventType.visible) {
      return EVENT_TYPE_GROUP.CUSTOM;
    } else {
      return EVENT_TYPE_GROUP.ARCHIVE;
    }
  }

  /**
   * Получение списка типов событий
   * NOTE: их так же можно получить через метод propertyModel.getList(), но там будут получаться ещё дополнительные данные
   *
   * @param appId ID приложения
   */
  public getList(appId: App['id']): Observable<EventType[]> {
    const params: ApiGetListRequest = {
      include_not_visible: true,
    };

    return this.http.get<any>(`/apps/${appId}/eventtypes`, { params }).pipe(
      map((data) => {
        let eventTypes: any[] = data.eventTypes;

        for (let i = 0; i < eventTypes.length; i++) {
          this.parse(eventTypes[i]);
        }

        return eventTypes.filter((eventType: EventType) => this.filterEvents(eventType));
      }),
    );
  }

  /**
   * Парсинг типа события
   *
   * @param eventType Тип события
   */
  public parse(eventType: EventType): void {
    eventType.prettyName = eventType.visible
      ? this.parseName(eventType.name)
      : this.transloco.translate('general.deletedEvent');

    const group = this.getGroup(eventType);
    eventType.group = group;
    eventType.groupOrder = EVENT_TYPE_GROUPS_ORDER[group];
  }

  /**
   * Парсинг имени
   * NOTE: временный метод, который используется в фильтре. Лучше его нигде больше не использовать. Будет удалён, когда все типы событий будут парситься через эту модель
   *
   * @param {String} eventTypeName Название типа события
   * @return {String} Если на вход пришло навание системного типа события - вернётся переведённое имя, если нет - вернётся то же самое, что пришло на вход
   */
  public parseName(eventTypeName: EventType['name']): EventType['name'] {
    return ~SYSTEM_EVENT_TYPES.indexOf(eventTypeName)
      ? this.transloco.translate(`models.eventType.systemEventTypes.${eventTypeName}`)
      : eventTypeName;
  }

  /**
   * Метод для сохранения отредактированного события
   *
   * @param eventType Тип события
   */
  public update(eventType: EventType): Observable<any> {
    const body: ApiUpdateRequest = {
      name: eventType.name,
      score: eventType.score,
      visible: eventType.visible,
    };

    return this.http.put(`/eventtypes/${eventType.id}`, body);
  }

  static findEventTypeById(eventTypeId: string, eventTypes: EventType[]): EventType {
    const eventType = eventTypes.find((eventType) => eventType.id === eventTypeId);
    if (!eventType) {
      throw new Error('Could not find an eventType');
    }
    return eventType;
  }

  static findEventByName(eventTypeName: string, eventTypes: EventType[]) {
    const eventType = eventTypes.find((eventType) => eventType.name === eventTypeName);
    if (!eventType) {
      throw new Error('Could not find an eventType');
    }
    return eventType;
  }
}

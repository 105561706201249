import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Добавляет apiEndpoint к URL запроса
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!needToSkipInterceptors(request)) {
      const endpoint = environment.apiEndpoint;

      request = request.clone({
        url: `${endpoint}${request.url}`,
      });
    }

    return next.handle(request);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { App, AppModel } from '@http/app/app.model';
import { EmailRecipient } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';

/** Сервис для работы с приложением */
@Injectable({
  providedIn: 'root',
})
export class AppService {
  private readonly appSubject: BehaviorSubject<App | null> = new BehaviorSubject<App | null>(null);

  readonly app$ = this.appSubject.pipe(filter((v): v is App => !!v));

  constructor(
    private readonly http: HttpClient,
    private readonly appModel: AppModel,
    private readonly router: UIRouter,
  ) {}

  get currentAppId(): string {
    let appId: string | null = this.router.globals.params.appId;

    if (!appId) {
      // эта штука отрабатывает только тогда, когда грузится впервые апп и в параметрах еще нет appId
      appId = this.router.stateService.transition && this.router.stateService.transition.targetState().params().appId;
    }

    if (!appId) {
      throw new Error('Could not get ID of the current app');
    }

    return appId;
  }

  get app(): App {
    const app = this.appSubject.getValue();
    if (!app) {
      throw new Error('Could not get the app');
    }
    return app;
  }

  set app(app: App) {
    this.appSubject.next(app);
  }

  refreshApp(): void {
    this.appModel.get(this.currentAppId).subscribe((app) => this.appSubject.next(app));
  }

  /**
   * Получение списка получателей
   */
  getEmailRecipients(appId: string): Observable<EmailRecipient[]> {
    return this.http.get<EmailRecipient[]>(`/apps/${appId}/emailrecipients/`);
  }
}

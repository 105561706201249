import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { LinkEditorModalComponent } from './link-editor-modal.component';

@NgModule({
  declarations: [LinkEditorModalComponent],
  imports: [CommonModule, FormsModule, SharedModule, ReactiveFormsModule, TranslocoModule],
  providers: [NgbActiveModal],
})
export class LinkEditorModalModule {}

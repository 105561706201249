/**
 * Контроллер для модального окна с отправкой файла
 *
 * При создании модального окна в resolve можно передать modalWindowParams - объект следующего вида:
 *    {Boolean=} activeNote Флаг активен ли режим заметки
 *    {Object=} file Отправляемый файл
 *    {String=} message Отправляемое сообещине
 *    {String=} userName Имя пользователя, которому отправляем сообщение
 *    {number=} textMaxLength Максимальная длина текста
 */
(function () {
  'use strict';

  angular
    .module('myApp.modals.fileConversation')
    .controller('FileConversationModalController', FileConversationModalController);

  function FileConversationModalController(
    $rootScope,
    $translate,
    $uibModalInstance,
    validationHelper,
    modalWindowParams,
  ) {
    var vm = this;

    /**
     * Максимальная длина сообщения
     *
     * @type {Number}
     */
    var REPLY_MAX_LENGTH = 20000;

    vm.cancel = cancel;
    vm.confirm = confirm;
    vm.djangoUser = $rootScope.djangoUser;
    vm.fileExtension = ''; //расширение файла
    vm.fileIsImg = false; //является ли файл изображением
    vm.heading = ''; //заголовок модалки
    vm.keypress = keypress;
    vm.modalWindowParams = modalWindowParams;
    vm.REPLY_MAX_LENGTH = modalWindowParams.textMaxLength ?? REPLY_MAX_LENGTH;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      vm.fileExtension = getFileExtension(modalWindowParams.file.name);

      // Браузеры не рендерят этот тип изображения, по этому покажем его как прикрепление
      let isTiffType = modalWindowParams.file.type === 'image/tiff';
      // SVG не отображаем как картинку из-за соображений безопасности
      let isSvgType = modalWindowParams.file.type === 'image/svg+xml';
      vm.fileIsImg = new RegExp('image').test(modalWindowParams.file.type) && !isTiffType && !isSvgType;

      if (modalWindowParams.activeNote) {
        if (vm.fileIsImg) {
          vm.heading = $translate.instant('modals.fileConversation.imageNote.title');
          vm.description = $translate.instant('modals.fileConversation.imageNote.description', {
            userName: modalWindowParams.userName,
          });
        } else {
          vm.heading = $translate.instant('modals.fileConversation.fileNote.title');
          vm.description = $translate.instant('modals.fileConversation.fileNote.description', {
            userName: modalWindowParams.userName,
          });
        }
      } else {
        if (vm.fileIsImg) {
          vm.heading = $translate.instant('modals.fileConversation.imageMessage.title');
          vm.description = $translate.instant('modals.fileConversation.imageMessage.description', {
            userName: modalWindowParams.userName,
          });
        } else {
          vm.heading = $translate.instant('modals.fileConversation.fileMessage.title');
          vm.description = $translate.instant('modals.fileConversation.fileMessage.description', {
            userName: modalWindowParams.userName,
          });
        }
      }
    }

    /**
     * Обработка нажатия на кнопку отмены
     */
    function cancel() {
      $uibModalInstance.dismiss({ message: modalWindowParams.message });
    }

    /**
     * Обработка нажатия на кнопку подтверждения
     *
     * @param isValid Валидация формы
     */
    function confirm(isValid) {
      if (isValid) {
        $uibModalInstance.close({ message: modalWindowParams.message });
      }
    }

    /**
     * Получение расширения файла
     *
     * @param {String} name имя файла
     * @returns {String} расширение файла
     */
    function getFileExtension(name) {
      var fileExtension = /^.+\.([^.]+)$/.exec(name);
      return fileExtension == null ? '' : fileExtension[1];
    }

    /**
     * Обработка нажатия комбинации Ctrl+Enter
     *
     * @param {Object} event
     */
    function keypress(event) {
      var isEnterPressed = event.which == 13 || event.which == 10; // Был нажат Enter
      var isCtrlPressed = event.ctrlKey || event.metaKey; // Был нажат Ctrl

      // Если включена отправка по Enter
      if ($rootScope.djangoUser.messenger_send_by_enter && !event.shiftKey) {
        // Был нажат Enter, не был нажат Ctrl
        if (isEnterPressed && !isCtrlPressed) {
          vm.confirm(vm.confirmationForm.$valid);
          event.preventDefault(); // Запрет переноса на след. строку
          // Был нажат Ctrl+Enter
        } else if (isEnterPressed && isCtrlPressed) {
          vm.modalWindowParams.message += '\n';
        }
        // Если включена отправка оп Ctrl+Enter, был нажат Enter, был нажат Ctrl
      } else if (!$rootScope.djangoUser.messenger_send_by_enter && isEnterPressed && isCtrlPressed && !event.shiftKey) {
        vm.confirm(vm.confirmationForm.$valid);
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { EventNameInputModule } from '@panel/app/partials/track-master/event-name-input/event-name-input.module';

import { CreateEventTypeModalComponent } from './create-event-type-modal.component';

@NgModule({
  declarations: [CreateEventTypeModalComponent],
  imports: [CommonModule, TranslocoModule, EventNameInputModule, ReactiveFormsModule],
})
export class CreateEventTypeModalModule {}

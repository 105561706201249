import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { WHATS_APP_EDNA_PHONE_REGEXP } from '@http/integration/integrations/whats-app-edna/constants/whats-app-edna-integration.constants';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-whats-app-edna-account-phone',
  templateUrl: './account-phone.component.html',
  styleUrls: ['./account-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsAppEdnaAccountPhoneComponent extends AbsCVAFormControlBasedComponent<string | null> {
  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
    validators: [Validators.required, Validators.pattern(WHATS_APP_EDNA_PHONE_REGEXP)],
  });
}

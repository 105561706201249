<ng-container *transloco="let t; read: 'createAutoEventModalComponent'">
  <div class="modal-header flex-column align-items-baseline">
    <div class="d-flex mb-15 w-100">
      <h3 class="modal-title">{{ t('heading') }}</h3>
      <button
        class="close"
        (click)="activeModal.dismiss()"
      >
        <i class="cqi-sm cqi-03x cqi-times"></i>
      </button>
    </div>
    <div class="mb-10">{{ t('description') }}</div>
    <cq-event-type-selector
      class="w-100"
      [formControl]="form.controls.type"
      (eventTypeChange)="onEventTypeChange($event)"
    ></cq-event-type-selector>
  </div>
  <div
    *ngIf="form.controls.type.value"
    class="modal-body"
  >
    <cq-event-name-input [formControl]="form.controls.name"></cq-event-name-input>

    <!--  КЛИК ИЛИ ПОЛЕ ВВОДА  -->
    <ng-container *ngIf="is(AUTO_EVENTS_GROUPS.CLICK, AUTO_EVENTS_GROUPS.INPUT)">
      <cq-css-selector-input
        class="mt-20"
        [formControl]="form.controls.cssSelector"
      ></cq-css-selector-input>
      <cq-works-on-page
        class="mt-20"
        [formControl]="form.controls.worksOnPage"
      ></cq-works-on-page>
      <cq-write-property-selector
        *ngIf="is(AUTO_EVENTS_GROUPS.INPUT)"
        class="mt-20"
        [currentApp]="data.currentApp"
        [(properties)]="data.userProps"
        [formControl]="form.controls.writeProperty"
      ></cq-write-property-selector>
    </ng-container>

    <!--  ОТКРЫЛ ИЛИ ПРОСКРОЛЛИЛ СТРАНИЦУ  -->
    <ng-container *ngIf="is(AUTO_EVENTS_GROUPS.URL_SCROLL, AUTO_EVENTS_GROUPS.URL)">
      <cq-page-url
        class="mt-20"
        [formControl]="form.controls.pageUrl"
      ></cq-page-url>
    </ng-container>

    <!--  ДОПОЛНИТЕЛЬНЫЕ СВОЙСТВА  -->

    <hr />

    <div
      class="d-flex justify-space-between cursor-pointer"
      (click)="collapse.toggle()"
      [attr.aria-expanded]="!extraSettingsCollapsed"
    >
      <span>{{ t('extraProperties') }}</span>
      <i
        *ngIf="extraSettingsCollapsed"
        class="cqi-sm cqi-chevron-down"
      ></i>
      <i
        *ngIf="!extraSettingsCollapsed"
        class="cqi-sm cqi-chevron-up"
      ></i>
    </div>

    <div
      #collapse="ngbCollapse"
      [(ngbCollapse)]="extraSettingsCollapsed"
    >
      <cq-selectors-properties
        class="mt-20"
        [currentApp]="data.currentApp"
        [formControl]="form.controls.selectorsProperties"
        [(properties)]="data.userProps"
      ></cq-selectors-properties>
      <cq-text-properties
        class="mt-20"
        [currentApp]="data.currentApp"
        [formControl]="form.controls.textProperties"
        [(properties)]="data.userProps"
      ></cq-text-properties>
    </div>

    <hr />

    <ng-container *ngIf="eventDuplicates$ | async as duplicates">
      <cq-duplicates-errors
        (selectEvent)="selectEvent($event)"
        *ngIf="duplicates.length > 0"
        [eventDuplicates]="duplicates"
      ></cq-duplicates-errors>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <button
      (click)="create()"
      [disabled]="!form.controls.type.value || ((eventDuplicates$ | async) ?? []).length > 0"
      class="btn btn-primary"
    >
      {{ t('createButton') }}
    </button>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'telegramPartEditorComponent'">
  <!--Селект выбора интеграции-->
  <div class="form-group">
    <label class="mb-15">{{ t('integrationSelectLabel') }}</label>
    <cq-integration-select
      [integrationId]="telegramIntegrationId"
      (integrationIdChange)="onTelegramIntegrationIdChange($event)"
      [integrationList]="telegramIntegrations"
      (touchAndValidateCallback)="setValidationCallback($event)"
    ></cq-integration-select>
  </div>

  <!--Ошбика "Вставьте хотя бы 1 блок"-->
  <div
    class="form-control-container"
    [cqShowError]="form"
  >
    <cq-validation-messages [control]="form">
      <cq-validation-message errorName="contentAreEmpty">{{ t('errors.required') }}</cq-validation-message>
    </cq-validation-messages>
  </div>

  <!--Контент-->
  <cq-content-blocks [formControl]="contents"></cq-content-blocks>

  <!--Записать ответ в свойство пользователя-->
  <!--NOTE пока скрыли запись в свойство-->
  <!--  <ng-container *ngIf="buttons.value.length">-->
  <!--    <div class="mb-20">-->
  <!--      <cq-answer-to-user-prop-->
  <!--        [userProps]="userProps"-->
  <!--        [formControl]="propToWrite"-->
  <!--        [currentApp]="currentApp"-->
  <!--      ></cq-answer-to-user-prop>-->
  <!--    </div>-->
  <!--    <hr class="mb-20" />-->
  <!--  </ng-container>-->

  <!--Кнопки-->
  <cq-button-blocks
    [currentApp]="currentApp"
    [formControl]="buttons"
  ></cq-button-blocks>
</ng-container>

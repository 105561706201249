import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

import { ArticleModel } from '@http/article/article.model';
import { ArticlesCategoryEditorModalComponent } from '@panel/app/pages/knowledge-base/shared/components/articles-category-editor-modal/articles-category-editor-modal.component';
import { ACTIONS_ON_ARTICLES_CATEGORY } from '@panel/app/pages/knowledge-base/shared/components/articles-category-editor-modal/articles-category-editor-modal.constnats';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-set-article-category-modal',
  templateUrl: './set-article-category-modal.component.html',
  styleUrls: ['./set-article-category-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SetArticleCategoryModalComponent implements OnInit {
  /** Параметры, передаваемые в модальное окно */
  _modalWindowParams: any;

  @Input() set modalWindowParams(value: any) {
    this._modalWindowParams = value;

    this.setCategoryForm.setValue(
      {
        category: this.modalWindowParams.articles.length === 1 ? this.modalWindowParams.articles[0].category.id : null,
      },
      { emitEvent: false },
    );
  }

  get modalWindowParams(): any {
    return this._modalWindowParams;
  }

  protected isApiRequestPerformed: boolean = false;
  protected newCategory: any;
  protected initialCategory: any;

  protected setCategoryForm = this.fb.group({
    category: this.fb.control('', Validators.required),
  });

  constructor(
    protected readonly destroy$: DestroyService,
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly articleModel: ArticleModel,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    private readonly fb: FormBuilder,
    protected readonly modalService: ModalHelperService,
  ) {
    this.setCategoryForm.valueChanges.subscribe((value) => {
      this.newCategory = this.modalWindowParams.categories.filter((category: any) => category.id === value.category)[0];
    });
  }

  ngOnInit(): void {
    this.newCategory =
      this.modalWindowParams.articles.length === 1 ? this.modalWindowParams.articles[0].category : null; // Новая категория
    this.initialCategory =
      this.modalWindowParams.articles.length === 1 ? this.modalWindowParams.articles[0].category : null; // Начальная категория перемещения
  }

  /**
   * Закрытие модалки с перемещением сообщений
   *
   * @param isValid
   */
  confirm(isValid: any): void {
    if (isValid) {
      this.isApiRequestPerformed = true;
      const articlesIdsList = this.modalWindowParams.articles.map((article: any) => article.id);

      this.articleModel
        .setCategory(this.modalWindowParams.currentApp.id, articlesIdsList, this.newCategory.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.modalWindowParams.articles.length > 1) {
            this.toastr.success(
              this.translocoService.translate('modals.setArticleCategory.toastr.articlesMovedSuccess', {
                countUpdate: this.modalWindowParams.articles.length,
                newCategory: this.newCategory.name,
              }),
            );
          } else {
            this.toastr.success(
              this.translocoService.translate('modals.setArticleCategory.toastr.articleMovedSuccess', {
                newCategory: this.newCategory.name,
              }),
            );
          }
          this.ngbActiveModal.close(this.newCategory);

          this.isApiRequestPerformed = false;
        });
    }
  }

  /**
   * Открытие модалки для создания категории
   */
  openCreateCategoryModal(): void {
    const modal = this.modalService.open(ArticlesCategoryEditorModalComponent);

    modal.componentInstance.modalWindowParams = {
      currentApp: this.modalWindowParams.currentApp,
    };

    modal.result.then((response: any) => {
      if (ACTIONS_ON_ARTICLES_CATEGORY.CREATE === response.action) {
        this.modalWindowParams.categories = [...this.modalWindowParams.categories, response.category];
        this.newCategory = response.category;
        this.setCategoryForm.controls.category.setValue(this.newCategory.id);
      }
    });
  }
}

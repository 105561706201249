import { InjectionToken } from '@angular/core';

/**
 * Тип данных, передаваемых в модалку предупреждения об активном Телеграм-боте
 */
export type AlreadyActiveTelegramBotModalData = {
  /** Тип действия, который производится с интеграцией */
  actionType: 'pause' | 'remove';
  /** Название приложения */
  appName?: string;
  /** Название интеграции */
  integrationName: string;
  /** Количество ботов, связанных с данной интеграцией  */
  botCount: number;
};

/**
 * Токен для передачи данных через DI в модалку предупреждения об активном Телеграм-боте
 */
export const ALREADY_ACTIVE_TELEGRAM_BOT_MODAL_DATA_TOKEN = new InjectionToken<AlreadyActiveTelegramBotModalData>(
  'EditUserPropertyModal data provider',
);

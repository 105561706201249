(function () {
  'use strict';

  angular
    .module('myApp.starterGuide')
    .controller('CqStarterGuideInstallationServiceController', CqStarterGuideInstallationServiceController);

  function CqStarterGuideInstallationServiceController($state, PROJECT_NAME) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.PROJECT_NAME = PROJECT_NAME;
    }
  }
})();

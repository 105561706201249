<ng-container *transloco="let t; read: 'tagDefaultComponent'">
  <div
    class="tag flex align-items-baseline"
    [ngClass]="{
      active: canActivate && isActive,
      'cursor-pointer': canActivate && !disabled,
      'cursor-not-allowed disabled': disabled
    }"
    (click)="onClickTag()"
  >
    <i
      *ngIf="isRemoved"
      class="cqi-sm cqi-triangle-exclamation text-danger margin-right-5"
      [ngbTooltip]="t('removed')"
      container="body"
    ></i>
    <span
      class="text-truncate"
      [innerText]="text"
      [ngbTooltip]="text"
      [disableTooltip]="!isLongTag"
      container="body"
    ></span>
    <i
      *ngIf="canRemove"
      class="tag-delete margin-left-5 cqi-sm cqi-times cursor-pointer"
      (click)="onClickRemove()"
      aria-hidden="true"
    ></i>
  </div>
</ng-container>

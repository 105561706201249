import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { BRANCH_VIEW_MAP, BranchViewMap } from '@panel/app/pages/chat-bot/content/tokens';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { DestroyService } from '@panel/app/services';

/**
 * Сервис для помощи в именовании новых блоков.
 */
@Injectable()
export class BlockNamingHelperService {
  /**
   * Счетчик имен со стандарным названием по ключу "models.chatBot.defaultBranchName".
   * Так называются все новые блоки по кнопке "+ Блок"
   */
  defaultBlockNameCounter = 0;
  /**
   * Счетчик имен со стандартным названием по ключу "models.chatBot.defaultSuccessBranchName"
   * Так называются все блоки созданные для PropertyFieldAction
   */
  defaultSuccessBlockNameCounter = 0;
  /**
   * Счетчик имен со стандартным названием по ключу "models.chatBot.defaultConditionPrefix"
   * Такой префикс имеют все условия
   */
  defaultConditionPrefixCounter = 0;
  /**
   * Счетчик имен со стандартным названием по ключу "models.chatBot.defaultActionBLockPrefix"
   * Такой префикс имеют все условия
   */
  defaultActionBLockCounter = 0;
  /**
   * Счетчик имен со стандартным названием по ключу "models.chatBot.defaultMeetingCounter"
   * Такой префикс имеют все условия
   */
  defaultMeetingCounter = 0;

  private defaultBlockName = this.translocoService.translate<string>('models.chatBot.defaultBranchName');
  private defaultSuccessBranchName = this.translocoService.translate<string>('models.chatBot.defaultSuccessBranchName');
  private defaultConditionPrefix = this.translocoService.translate<string>('models.chatBot.defaultConditionPrefix');
  private defaultActionBLockPrefix = this.translocoService.translate<string>('models.chatBot.defaultActionBLockPrefix');
  private defaultMeetingPrefix = this.translocoService.translate<string>('models.chatBot.defaultMeetingPrefix');

  constructor(
    @Inject(BRANCH_VIEW_MAP)
    private readonly branchCollection: BranchViewMap,
    private readonly destroy$: DestroyService,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Обновляем максимальный номер блока каждого типа
   */
  calcBlockNamesCounters(): void {
    let biggestDefaultNameCounter = 0;
    let biggestDefaultSuccessNameCounter = 0;
    let biggestDefaultConditionNameCounter = 0;
    let biggestDefaultActionBLockCounter = 0;
    let biggestDefaultMeetingCounter = 0;

    for (const { name } of this.branchCollection.values()) {
      if (name.value.toLowerCase().includes(this.defaultBlockName.toLowerCase())) {
        const counter = Number.parseFloat(name.value.replace(/\D+/g, '').trim()) || 0;
        biggestDefaultNameCounter = Math.max(biggestDefaultNameCounter, counter);
        continue;
      }

      if (name.value.toLowerCase().includes(this.defaultSuccessBranchName.toLowerCase())) {
        const counter = Number.parseFloat(name.value.replace(/\D+/g, '').trim()) || 0;
        biggestDefaultSuccessNameCounter = Math.max(biggestDefaultSuccessNameCounter, counter);
        continue;
      }

      if (name.value.toLowerCase().includes(this.defaultConditionPrefix.toLowerCase())) {
        const counter = Number.parseFloat(name.value.replace(/\D+/g, '').trim()) || 0;
        biggestDefaultConditionNameCounter = Math.max(biggestDefaultConditionNameCounter, counter);
      }

      if (name.value.toLowerCase().includes(this.defaultActionBLockPrefix.toLowerCase())) {
        const counter = Number.parseFloat(name.value.replace(/\D+/g, '').trim()) || 0;
        biggestDefaultActionBLockCounter = Math.max(biggestDefaultActionBLockCounter, counter);
      }

      if (name.value.toLowerCase().includes(this.defaultMeetingPrefix.toLowerCase())) {
        const counter = Number.parseFloat(name.value.replace(/\D+/g, '').trim()) || 0;
        biggestDefaultMeetingCounter = Math.max(biggestDefaultMeetingCounter, counter);
      }
    }

    this.defaultBlockNameCounter = biggestDefaultNameCounter;
    this.defaultSuccessBlockNameCounter = biggestDefaultSuccessNameCounter;
    this.defaultConditionPrefixCounter = biggestDefaultConditionNameCounter;
    this.defaultActionBLockCounter = biggestDefaultActionBLockCounter;
    this.defaultMeetingCounter = biggestDefaultMeetingCounter;
  }

  /**
   * Функция используется при создании ветки
   * дополняет счетчиком новую ветку с дефолтным названием
   * в зависимости от options
   */
  getNewBranchName(newName?: string): string {
    let name = this.defaultBlockName;

    if (!newName) {
      this.defaultBlockNameCounter++;

      if (this.defaultBlockNameCounter > 1) {
        name += ' ' + this.defaultBlockNameCounter;
      }

      return name;
    }

    name = newName;

    if (newName === this.defaultSuccessBranchName) {
      this.defaultSuccessBlockNameCounter++;
      if (this.defaultSuccessBlockNameCounter > 1) {
        name += ' ' + this.defaultSuccessBlockNameCounter;
      }
    }

    return name;
  }

  /**
   * Получение нового префикса для условия с его номером в последовательности
   * ("Условие 1...", "Условие 2..." и тд)
   */
  getNewConditionName(conditionType: Exclude<Branch['branchType'], 'default'>): string {
    let prefix = this.defaultConditionPrefix;

    this.defaultConditionPrefixCounter++;
    if (this.defaultConditionPrefixCounter > 1) {
      prefix += ' ' + this.defaultConditionPrefixCounter;
    }

    const conditionNameByType = this.translocoService.translate(`models.chatBot.conditionName.${conditionType}`);
    return `${prefix}: ${conditionNameByType}`;
  }

  /**
   * Получение имени action блока со счетчиком
   */
  getNewActionBlockName(): string {
    let name = this.defaultActionBLockPrefix;

    this.defaultActionBLockCounter++;

    if (this.defaultActionBLockCounter > 1) {
      name += ' ' + this.defaultActionBLockCounter;
    }

    return name;
  }

  getNewMeetingName(): string {
    let name = this.defaultMeetingPrefix;

    this.defaultMeetingCounter++;

    if (this.defaultMeetingCounter > 1) {
      name += ' ' + this.defaultMeetingCounter;
    }

    return name;
  }
}

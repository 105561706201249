import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * КОМПОНЕНТ ОБЕРТКА ДЛЯ ИСПОЛЬЗОВАНИЯ В СТАРОМ АНГУЛЯРЕ, ЛЕЖИТ ТУТ, ПОТОМУ ЧТО ХОТЕЛ ПОЛОЖИТЬ РЯДОМ
 * С ОРИГИНАЛЬНЫМ КОМПОНЕНТОМ, КОТОРЫЙ В СТАРОМ АНГУЛЯРЕ НЕ РАБОТАЕТ ИЗ-ЗА ТОГО ЧТО ОН СVA И ДЛЯ ЕГО ИСПОЛЬЗОВАНИЯ
 * НАДО FormsModule/ReactiveFormsModule
 */
@Component({
  selector: 'cq-trigger-chain-step-name-editor-ajs',
  templateUrl: './trigger-chain-step-name-editor-ajs.component.html',
  styleUrls: ['./trigger-chain-step-name-editor-ajs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainStepNameEditorAjsComponent {
  @Input()
  name: string = '';

  @Output()
  nameChange: Subject<string> = new Subject<string>();
}

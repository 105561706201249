<ng-container *transloco="let t; read: 'messageEditorTriggerSelectComponent'">
  <ng-container *ngIf="messageEditorTriggerState.currentApp$ | async as currentApp">
    <div class="btn-group btn-group-toggle w-100 pb-15 mt-n5">
      <label
        class="btn btn-outline-primary active"
        [ngClass]="{ active: selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.VISITED_WEBSITE }"
      >
        <input
          name="eventType"
          type="radio"
          [value]="MESSAGE_EDITOR_SELECTED_TRIGGER.VISITED_WEBSITE"
          [(ngModel)]="selectedTriggerType"
          (ngModelChange)="selectedTriggerTypeChange.next($event)"
        />
        {{ t('buttonGroup.visitedWebsite') }}
      </label>

      <label
        class="btn btn-outline-primary"
        [ngClass]="{
          active:
            selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE ||
            selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_SDK_PAGE
        }"
      >
        <cq-access-denial-popover
          *ngIf="!accessToEventsEventTypesCustom.hasAccess"
          [currentApp]="currentApp"
          [denialReason]="accessToEventsEventTypesCustom.denialReason"
          [popoverContainer]="'body'"
          [popoverPlacement]="'bottom'"
        >
          <input
            name="eventType"
            type="radio"
          />
          <cq-plan-capability-coin></cq-plan-capability-coin>
          {{ t(sdkActive ? 'buttonGroup.openedPage' : 'buttonGroup.openedPageWebOnly') }}
        </cq-access-denial-popover>
        <ng-container *ngIf="accessToEventsEventTypesCustom.hasAccess">
          <input
            name="eventType"
            type="radio"
            (click)="selectOpenedPageTrigger()"
          />
          {{ t(sdkActive ? 'buttonGroup.openedPage' : 'buttonGroup.openedPageWebOnly') }}</ng-container
        >
      </label>

      <label
        class="btn btn-outline-primary"
        [ngClass]="{ active: selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.LEAVE_SITE_ATTEMPT }"
      >
        <input
          name="eventType"
          type="radio"
          [value]="MESSAGE_EDITOR_SELECTED_TRIGGER.LEAVE_SITE_ATTEMPT"
          [(ngModel)]="selectedTriggerType"
          (ngModelChange)="selectedTriggerTypeChange.next($event)"
        />
        {{ t('buttonGroup.leavedSiteAttempt') }}
      </label>

      <label
        class="btn btn-outline-primary"
        [ngClass]="{ active: selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.ANOTHER_EVENT }"
      >
        <input
          name="eventType"
          type="radio"
          [value]="MESSAGE_EDITOR_SELECTED_TRIGGER.ANOTHER_EVENT"
          [(ngModel)]="selectedTriggerType"
          (ngModelChange)="selectedTriggerTypeChange.next($event)"
        />
        {{ t('buttonGroup.anotherEvent') }}
      </label>
    </div>

    <div
      class="pb-15"
      *ngIf="
        sdkActive &&
        (selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE ||
          selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_SDK_PAGE)
      "
    >
      <div class="d-flex align-items-center">
        <div class="custom-control custom-radio custom-control-inline">
          <input
            class="custom-control-input"
            id="radio-0"
            type="radio"
            [value]="MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE"
            [(ngModel)]="selectedTriggerType"
            (ngModelChange)="selectedTriggerTypeChange.next($event)"
          />
          <label
            class="custom-control-label"
            for="radio-0"
            >{{ t('openedPageRadio.web') }}</label
          >
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            class="custom-control-input"
            id="radio-1"
            type="radio"
            [value]="MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_SDK_PAGE"
            [(ngModel)]="selectedTriggerType"
            (ngModelChange)="selectedTriggerTypeChange.next($event)"
          />
          <label
            class="custom-control-label"
            for="radio-1"
            >{{ t('openedPageRadio.sdk') }}</label
          >
        </div>
      </div>
    </div>

    <!-- Зашел на сайт -->
    <div *ngIf="selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.VISITED_WEBSITE">
      {{ t('description.visitedWebsite', { typeMessageName: entityName }) | capitalize }}
    </div>

    <!-- Открыл страницу -->
    <div *ngIf="selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE">
      <ng-content select="[triggerTypesWebPageContent]"></ng-content>
    </div>

    <!-- Открыл страницу -->
    <div *ngIf="selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_SDK_PAGE">
      <ng-content select="[triggerTypesSdkPageContent]"></ng-content>
    </div>

    <!-- Попытка ухода с сайта -->
    <div *ngIf="selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.LEAVE_SITE_ATTEMPT">
      {{ t('description.leavedSiteAttempt', { typeMessageName: entityName }) | capitalize }}
    </div>

    <!-- Другое событие -->
    <div *ngIf="selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.ANOTHER_EVENT">
      <ng-content select="[anotherEventContent]"></ng-content>
    </div>
  </ng-container>
</ng-container>

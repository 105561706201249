<ng-container *transloco="let t; read: 'triggerChainTableItemComponent'">
  <!--НАЗВАНИЕ-->
  <td>
    <!-- ИМЯ -->
    <a
      class="link-unstyled d-block mb-10"
      uiSref="app.content.triggerChains.editor.edit"
      [uiParams]="{ id: chain.id }"
    >
      <span
        class="text-break"
        title="{{ chain.name }}"
      >
        {{ chain.name }}
      </span>
    </a>

    <!-- ТРИГГЕРЫ-->
    <div
      class="d-inline small text-muted"
      [ngClass]="{
        'dashed cursor-pointer': isTriggersPopoverEnabled
      }"
      placement="bottom"
      [ngbPopover]="triggersPopover"
      [autoClose]="'outside'"
      [disablePopover]="!isTriggersPopoverEnabled"
    >
      <span>{{ t('name.condition') }}</span>

      <!-- События из триггеров -->
      <span
        *ngIf="chain.sendingConditions.eventTypes.length >= 1"
        class="text-break"
      >
        <i
          *ngIf="isEventRemoved(chain.sendingConditions.eventTypes[0])"
          class="cqi-sm cqi-triangle-exclamation text-danger"
          [ngbTooltip]="t('name.deletedEventTooltip')"
          container="body"
          placement="top"
        ></i>
        <span
          class="inline"
          [ngClass]="{ 'text-muted': isEventRemoved(chain.sendingConditions.eventTypes[0]) }"
        >
          {{ getChainEventTriggerPrettyName(chain.sendingConditions.eventTypes[0]) }}
        </span>
      </span>

      <!-- Открыл страницу -->
      <span
        *ngIf="hasOpenedWebPageTriggersTypes"
        class="inline text-break"
      >
        {{ t('triggerTypes.openedWebPage.nameShort') }}
      </span>

      <!-- Открыл экран -->
      <span
        *ngIf="hasOpenedSdkPageTriggersTypes"
        class="inline text-break"
      >
        {{ t('triggerTypes.openedSdkPage.nameShort') }}
      </span>

      <!-- Попытка ухода с сайта -->
      <span
        *ngIf="chain.sendingConditions.triggerTypes.leaveSiteAttemptTrigger"
        class="inline text-break"
        >{{ t('triggerTypes.leaveSiteAttemptTrigger') }}
      </span>

      <span *ngIf="isTriggersPopoverEnabled">{{ t('name.andOtherSettings') }}</span>
    </div>

    <!--СОЗДАНО-->
    <div class="small text-muted mt-5 mb-10">
      <span>
        {{ chain.created | amDateFormat: 'LLL' }}
      </span>
    </div>

    <!--СТАТУС-->
    <div class="d-flex">
      <span
        *ngIf="chain.active; else pause"
        class="chain-status label label-light-success"
      >
        <img
          class="chain-status-icon"
          src="assets/img/default/auto-messages/table/active.svg"
        />
        <span class="chain-status-text">{{ t('status.active') }}</span>
      </span>
      <ng-template #pause>
        <span class="chain-status label label-light-danger">
          <img
            class="chain-status-icon"
            src="assets/img/default/auto-messages/table/paused.svg"
          />
          <span class="chain-status-text">{{ t('status.pause') }}</span>
        </span>
      </ng-template>
    </div>
  </td>

  <!--ПОСЛЕДНЯЯ ОТПРАВКА-->
  <td>
    <div *ngIf="chain.stats.lastSent; else noLastSent">
      {{ chain.stats.lastSent | amDateFormat: 'LLL' }}
    </div>
    <ng-template #noLastSent>
      <div class="text-muted">
        {{ t('statistics.notSended') }}
      </div>
    </ng-template>
  </td>

  <!--ОТПРАВЛЕНО-->
  <td>
    <h4 class="m-0">{{ chain.stats.sent }}</h4>
  </td>

  <!--В ПРОЦЕССЕ ПРОХОЖДЕНИЯ-->
  <td>
    <cq-progressbar
      [value]="chain.stats.inProgress"
      [max]="chain.stats.sent"
      [type]="'secondary'"
    >
      <ng-template #leftContent>
        <div class="fade statistic-percent">
          {{ getPercentStats(chain.stats.inProgress) }}
        </div>
        <div class="position-absolute top-0 fade statistic-count">{{ chain.stats.inProgress }}</div>
      </ng-template>
    </cq-progressbar>
  </td>

  <!--ВЫШЛИ ИЗ ЦЕПОЧКИ-->
  <td>
    <cq-progressbar
      [value]="chain.stats.droppedOff"
      [max]="chain.stats.sent"
      [type]="'danger'"
    >
      <ng-template #leftContent>
        <div class="fade statistic-percent">
          {{ getPercentStats(chain.stats.droppedOff) }}
        </div>
        <div class="position-absolute top-0 fade statistic-count">{{ chain.stats.droppedOff }}</div>
      </ng-template>
    </cq-progressbar>
  </td>

  <!--ПРОШЛИ ДО КОНЦА-->
  <td>
    <cq-progressbar
      [value]="chain.stats.finished"
      [max]="chain.stats.sent"
      [type]="'success'"
    >
      <ng-template #leftContent>
        <div class="fade statistic-percent">
          {{ getPercentStats(chain.stats.finished) }}
        </div>
        <div class="position-absolute top-0 fade statistic-count">{{ chain.stats.finished }}</div>
      </ng-template>
    </cq-progressbar>
  </td>

  <!--КНОПКИ-->
  <td class="shrink-by-content">
    <!-- СТАТИСТИКА -->
    <a
      *ngIf="chain.id"
      class="btn btn-borderless-primary"
      [title]="t('statistics.button.title')"
      uiSref="app.content.triggerChains.statistics.general"
      [uiParams]="{ id: chain.id }"
    >
      <i class="btn-icon cqi-sm cqi-chart-bar"></i>
    </a>
    <!-- МЕНЮ -->
    <div
      ngbDropdown
      class="d-inline-block"
    >
      <button
        type="button"
        class="btn btn-borderless-primary"
        ngbDropdownToggle
      >
        <i class="btn-icon cqi-sm cqi-ellipsis"></i>
      </button>
      <div
        class="dropdown-menu-right"
        ngbDropdownMenu
      >
        <!-- АКТИВАЦИЯ -->
        <button
          *ngIf="accessToAutoMessagesTotal.hasAccess && accessToTelegramAutoMessages.hasAccess && !chain.active"
          (click)="changeStatus()"
          class="dropdown-item"
          type="button"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-play-o"></i>
          <span>{{ 'general.activate' | transloco }}</span>
        </button>
        <!-- АКТИВАЦИЯ (Ограничение по капабилити) -->
        <button
          *ngIf="(!accessToAutoMessagesTotal.hasAccess || !accessToTelegramAutoMessages.hasAccess) && !chain.active"
          (click)="openSomeAutoMessageBillingModal()"
          class="dropdown-item"
          type="button"
        >
          <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
          <span>{{ 'general.activate' | transloco }}</span>
        </button>

        <!-- ПРИОСТАНОВКА -->
        <button
          class="dropdown-item"
          type="button"
          *ngIf="chain.active"
          (click)="changeStatus()"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-pause"></i>
          <span>{{ 'general.suspend' | transloco }}</span>
        </button>

        <a
          uiSref="app.content.triggerChains.editor.copy"
          [uiParams]="{ id: chain.id }"
          ngbDropdownItem
          class="dropdown-item"
          type="button"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-copy"></i>
          <span>{{ t('action.copy') }}</span>
        </a>
      </div>
    </div>
  </td>

  <!-- ПОПОВЕР -->
  <ng-template #triggersPopover>
    <div class="font-weight-bold mb-5">{{ t('popover.triggers.heading') }}</div>
    <!-- Триггеры -->
    <div *ngFor="let trigger of chain.sendingConditions.eventTypes; trackBy: trackByTriggers">
      <i
        *ngIf="isEventRemoved(trigger)"
        class="cqi-sm cqi-triangle-exclamation text-danger"
        [ngbTooltip]="t('name.deletedEventTooltip')"
        container="body"
        placement="top"
      ></i>
      <span class="inline text-secondary">{{ getChainEventTriggerPrettyName(trigger) }}</span>
    </div>

    <!-- Новые триггеры (Открыл страницу)-->
    <div *ngIf="hasOpenedWebPageTriggersTypes">
      <div class="text-secondary">{{ t('triggerTypes.openedWebPage.name') }}</div>
      <div
        *ngFor="
          let openedPageTriggerType of chain.sendingConditions.triggerTypes.openedWebPageTriggers;
          trackBy: trackByOpenedWebPageTriggers
        "
      >
        <span class="inline text-secondary">
          {{ getOpenedWebPageComparisonPrettyName(openedPageTriggerType.comparison) + ':' }}
        </span>
        <span class="inline text-secondary">
          {{ openedPageTriggerType.url }}
        </span>
      </div>
    </div>

    <!-- Новые триггеры (Открыл экран)-->
    <div *ngIf="hasOpenedSdkPageTriggersTypes">
      <div class="text-secondary">{{ t('triggerTypes.openedSdkPage.name') }}</div>
      <div
        *ngFor="
          let openedPageTriggerType of chain.sendingConditions.triggerTypes.openedSdkPageTriggers;
          trackBy: trackByOpenedSdkPageTriggers
        "
      >
        <span class="inline text-secondary">
          {{ getOpenedSdkPageComparisonPrettyName(openedPageTriggerType.comparison) + ':' }}
        </span>
        <span class="inline text-secondary">
          {{ openedPageTriggerType.sdkPage }}
        </span>
      </div>
    </div>

    <!-- Фильтры запуска -->
    <div *ngIf="hasFilters">
      <hr />

      <div class="font-weight-bold mb-5">{{ t('popover.filters.heading') }}</div>

      <div
        *ngIf="!hasJinja"
        class="text-secondary"
      >
        <span *ngIf="chain.sendingConditions.filters.type === 'and'">{{
          t('popover.filters.satisfies.allFilters')
        }}</span>
        <span *ngIf="chain.sendingConditions.filters.type === 'or'">{{
          t('popover.filters.satisfies.anyOfFilters')
        }}</span>
      </div>

      <div
        *ngIf="hasJinja"
        class="text-secondary"
      >
        {{ t('popover.filters.jinjaFilterTemplateSetup') }}
      </div>

      <div *ngFor="let filter of chain.sendingConditions.filters.filters.props; first as isFirst">
        <div
          *ngIf="isFirst"
          class="text-secondary"
        >
          {{ t('popover.filters.properties') }}
        </div>
        <span class="text-secondary">{{ filter.userProp.prettyName }}</span>
        <cq-message-filters
          class="d-inline text-secondary"
          [filter]="filter"
        ></cq-message-filters>
      </div>

      <div *ngFor="let filter of chain.sendingConditions.filters.filters.events; first as isFirst">
        <div
          *ngIf="isFirst"
          class="text-secondary"
        >
          {{ t('popover.filters.events') }}
        </div>
        <span class="text-secondary">{{ filter.prettyName }}</span>
        <cq-message-filters
          class="d-inline text-secondary"
          [filter]="filter"
        ></cq-message-filters>
      </div>

      <span
        class="no-space"
        *ngFor="let tag of chain.sendingConditions.filters.filters.tags; first as isFirst; last as isLast"
      >
        <div
          *ngIf="isFirst"
          class="mt-10 text-secondary"
        >
          {{ t('popover.filters.tags') }}
        </div>
        <cq-user-tag [tag]="tag.tag"></cq-user-tag>
        <span *ngIf="isLast">,&nbsp;</span>
      </span>
    </div>
  </ng-template>
</ng-container>

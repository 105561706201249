(function () {
  'use strict';

  require('./statistics.controller');
  require('./tabs/sendings.html');

  angular.module('myApp.chatBot.routingBot').component('cqRoutingBotStatistics', cqRoutingBotStatistics());

  function cqRoutingBotStatistics() {
    return {
      bindings: {
        routingBot: '=', // Роутинг-бот
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
      },
      controller: 'CqRoutingBotStatisticsController',
      controllerAs: 'vm',
      template: require('./statistics.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { TriggerChainGeneralStatisticsBreadcrumbsModule } from '@panel/app/pages/trigger-chains/general-statistics/components/breadcrumbs/trigger-chain-general-statistics-breadcrumbs.module';
import { TriggerChainGeneralStatisticsTableModule } from '@panel/app/pages/trigger-chains/general-statistics/components/table/trigger-chain-general-statistics-table.module';

import { TriggerChainGeneralStatisticsComponent } from './trigger-chain-general-statistics.component';

@NgModule({
  declarations: [TriggerChainGeneralStatisticsComponent],
  exports: [TriggerChainGeneralStatisticsComponent],
  imports: [
    CommonModule,
    TriggerChainGeneralStatisticsBreadcrumbsModule,
    TriggerChainGeneralStatisticsTableModule,
    UIRouterModule,
    TranslocoModule,
  ],
})
export class TriggerChainGeneralStatisticsModule {}

/**
 * Удаление элемента, на котором висит директива, у российских пользователей
 * Подробнее о реализации - тут https://stackoverflow.com/questions/19224028/add-directives-from-directive-in-angularjs
 */
(function () {
  'use strict';

  angular.module('myApp.directives.hideInRu').directive('cqHideInRu', cqHideInRu);

  function cqHideInRu($compile, l10nHelper) {
    return {
      restrict: 'A',
      compile: compile,
      priority: 1000,
      terminal: true,
    };

    /**
     * Функция компиляции директивы, в которой данная директива заменяется на ng-if
     * Это нужно из-за того, что ng-if при своей работе учитывает много всяких штук, а не просто удаляет элемент из DOM
     * Благодаря этому, например, работает удаление инпутов из форм, которые должны показываться только американским пользователям. Если просто удалять элемент из DOM - формы будут работать некорректно
     *
     * @param tElement
     * @param tAttrs
     * @returns {{pre: angular.noop, post: post}}
     */
    function compile(tElement, tAttrs) {
      var hide = !l10nHelper.isUsCountry();

      if (hide) {
        tElement.attr('ng-if', !hide);
      }

      tElement.removeAttr('cq-hide-in-ru');

      return {
        pre: angular.noop,
        post: function (scope, iElement, iAttrs, controller) {
          $compile(iElement)(scope);
        },
      };
    }
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { InputStrContainsForm } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-str-contains/input-str-contains.types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

@Component({
  selector: 'cq-input-str-contains',
  templateUrl: './input-str-contains.component.html',
  styleUrls: ['./input-str-contains.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputStrContainsComponent extends AbsCVAFormGroupBasedComponent<InputStrContainsForm> {
  MAX_LENGTH = 15;
  MIN_LENGTH = 3;

  readonly control: FormGroup<InputStrContainsForm> = this.fb.group<InputStrContainsForm>({
    value: this.fb.control(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(this.MIN_LENGTH), Validators.maxLength(this.MAX_LENGTH)],
    }),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}

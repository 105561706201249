(function () {
  'use strict';

  require('./auto-event-setting.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventSetting', cqAutoEventSetting());

  function cqAutoEventSetting() {
    return {
      bindings: {
        autoEvent: '=', // Автособытие
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // Текущий App
        djangoUser: '=', // Джанго юзер
        properties: '=', // Свойства
      },
      controller: 'CqAutoEventSettingController',
      controllerAs: 'vm',
      template: require('./auto-event-setting.html'),
    };
  }
})();

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmOAuthUrlApiResponse } from '@http/integration/types/integration.types';
import { AMOCRM_ACCOUNT_DOMAIN } from '@panel/app/pages/integrations/content/amocrm/domain-selection/amocrm-domain-selection.constants';
import { DestroyService, SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

/**
 * Компонент для OAuth авторизации в amoCRM
 */

@Component({
  selector: 'cq-amocrm-o-auth-container[accountDomain][authorizeError][authorizeSuccess][isAuthorized]',
  templateUrl: './amocrm-o-auth-container.component.html',
  styleUrls: ['./amocrm-o-auth-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AmocrmOAuthContainerComponent implements OnInit {
  /** Выбранный домен по умолчанию */
  @Input()
  accountDomain!: AMOCRM_ACCOUNT_DOMAIN;

  /** ID интеграции */
  @Input()
  integrationId?: string | null;

  /** Авторизован ли пользователь */
  @Input()
  isAuthorized: boolean = false;

  /** Событие неуспешной авторизации */
  @Output()
  authorizeError: EventEmitter<void> = new EventEmitter<void>();

  /** Событие успешной авторизации */
  @Output()
  authorizeSuccess: EventEmitter<void> = new EventEmitter<void>();

  /** OAuth Url для авторизации в интеграции */
  oAuthUrl: AmocrmOAuthUrlApiResponse = {
    authUrl: {
      ru: '',
      com: '',
    },
  };

  /** Текущий проект */
  projectName: string = environment.projectName;

  /** Получение названия интеграции */
  get integrationTypeName() {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly destroy$: DestroyService,
    private readonly featureModel: FeatureModel,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(SystemToastService) private readonly systemToastService: SystemToastService,
    @Inject(ToastService) private readonly toastService: ToastService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if (!this.isAuthorized) {
      this.updateOAuthUrl();
    }
  }

  /**
   * Есть ли доступ до выбора доменов
   *
   * NOTE:
   *  Убрать после тестирования com домена на бою (CAR-45590)
   */
  hasAccessToChoiceDomains(): boolean {
    return this.featureModel.hasAccess(FEATURES.AMOCRM_DOMAINS);
  }

  /** Callback на ошибку в авторизации */
  onAuthorizeError(): void {
    this.authorizeError.emit();

    this.toastService.danger(
      this.translocoService.translate('amocrmOAuthContainerComponent.toasts.authorizeError', {
        integrationTypeName: this.integrationTypeName,
      }),
    );

    this.updateOAuthUrl();
  }

  /** Callback на успешную авторизацию */
  onSuccessAuthorize(): void {
    this.authorizeSuccess.emit();
  }

  /** Обновление OAuth url */
  updateOAuthUrl(): void {
    this.integrationService
      .getOAuthUrl(INTEGRATION_TYPES.AMOCRM, this.integrationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.oAuthUrl = response as AmocrmOAuthUrlApiResponse;
        this.changeDetectorRef.detectChanges();
      });
  }
}

(function () {
  'use strict';

  require('./remove-user-property.controller');

  angular.module('myApp.modals.removeUserProperty').component('cqRemoveUserPropertyModal', cqRemoveUserPropertyModal());

  function cqRemoveUserPropertyModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentAppId — ID текущего приложения
        // propertyName — Название удаляемого свойства
        // userId — ID пользователя CQ
      },
      controller: 'CqRemoveUserPropertyModalController',
      controllerAs: 'vm',
      template: require('./remove-user-property.html'),
    };
  }
})();

import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ArticleModel } from '@http/article/article.model';
import { ArticleCategoryModel } from '@http/article-category/article-category.model';
import { KnowledgeBaseModel } from '@http/knowledge-base/knowledge-base.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { KnowledgeBasePageComponent } from '@panel/app/pages/knowledge-base/knowledge-base-page.component';
import { KnowledgeBaseActivateWrapperComponent } from '@panel/app/pages/knowledge-base/pages/knowledge-base-activate-wrapper/knowledge-base-activate-wrapper.component';
import { KnowledgeBaseArticlesComponent } from '@panel/app/pages/knowledge-base/pages/knowledge-base-articles/knowledge-base-articles.component';
import { KnowledgeBaseCategoriesComponent } from '@panel/app/pages/knowledge-base/pages/knowledge-base-categories/knowledge-base-categories.component';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';

export const KNOWLEDGE_BASE_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.knowledgeBase',
    url: '/knowledge-base',
    redirectTo: redirectBasedOnKnowledgeBaseExistence,
    component: KnowledgeBasePageComponent,
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
    },
    resolve: [
      {
        token: 'settings',
        deps: [KnowledgeBaseModel, 'currentApp'],
        resolveFn: getSettings,
      },
    ],
  },
  {
    name: 'app.content.knowledgeBase.activate',
    url: '',
    component: KnowledgeBaseActivateWrapperComponent,
    resolve: [
      {
        token: 'billingInfo',
        deps: ['billingInfo'],
        resolveFn: (billingInfo: any) => billingInfo,
      },
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'settings',
        deps: ['settings'],
        resolveFn: (settings: any) => settings,
      },
    ],
  },
  {
    name: 'app.content.knowledgeBase.categories',
    url: '',
    component: KnowledgeBaseCategoriesComponent,
    resolve: [
      {
        token: 'billingInfo',
        deps: ['billingInfo'],
        resolveFn: (billingInfo: any) => billingInfo,
      },
      {
        token: 'categories',
        deps: ['categories'],
        resolveFn: (categories: any) => categories,
      },
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'djangoUser',
        deps: ['djangoUser'],
        resolveFn: (djangoUser: any) => djangoUser,
      },
      {
        token: 'settings',
        deps: ['settings'],
        resolveFn: (settings: any) => settings,
      },
      {
        token: 'articlesCount',
        deps: [ArticleModel, 'currentApp'],
        resolveFn: getArticlesCount,
      },
      {
        token: 'categories',
        deps: [ArticleCategoryModel, 'currentApp'],
        resolveFn: getCategoriesList,
      },
      {
        token: 'currentCategory',
        deps: ['$stateParams', '$state', ArticleCategoryModel, 'currentApp'],
        resolveFn: getCurrentCategory,
      },
      {
        token: 'isKnowledgeBaseProActivated',
        deps: ['currentApp', 'planVersionService', 'planFeatureAccessService'],
        resolveFn: isKnowledgeBaseProActivated,
      },
    ],
  },
  {
    name: 'app.content.knowledgeBase.category',
    url: '/{categoryId}',
    component: KnowledgeBaseArticlesComponent,
    resolve: [
      {
        token: 'articles',
        deps: ['articles'],
        resolveFn: (articles: any) => articles,
      },
      {
        token: 'billingInfo',
        deps: ['billingInfo'],
        resolveFn: (billingInfo: any) => billingInfo,
      },
      {
        token: 'categories',
        deps: ['categories'],
        resolveFn: (categories: any) => categories,
      },
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'djangoUser',
        deps: ['djangoUser'],
        resolveFn: (djangoUser: any) => djangoUser,
      },
      {
        token: 'settings',
        deps: ['settings'],
        resolveFn: (settings: any) => settings,
      },
      {
        token: 'articles',
        deps: [ArticleModel, 'currentApp', 'currentCategory'],
        resolveFn: getArticlesListByCategory,
      },
      {
        token: 'articlesCount',
        deps: [ArticleModel, 'currentApp'],
        resolveFn: getArticlesCount,
      },
      {
        token: 'categories',
        deps: [ArticleCategoryModel, 'currentApp'],
        resolveFn: getCategoriesList,
      },
      {
        token: 'currentCategory',
        deps: ['$stateParams', '$state', ArticleCategoryModel, 'currentApp'],
        resolveFn: getCurrentCategory,
      },
      {
        token: 'isKnowledgeBaseProActivated',
        deps: ['currentApp', 'planVersionService', 'planFeatureAccessService'],
        resolveFn: isKnowledgeBaseProActivated,
      },
    ],
  },
  {
    name: 'app.content.knowledgeBase.articleEdit',
    url: '/{categoryId}/{articleId}',
    redirectTo: 'app.content.knowledgeBaseArticleEditAjs',
  },
  {
    name: 'app.content.knowledgeBase.articleCreate',
    url: '/{categoryId}/new',
    redirectTo: 'app.content.knowledgeBaseArticleCreateAjs',
  },
  //{
  //  name: 'app.content.knowledgeBase.articleEdit',
  //  url: '/{categoryId}/{articleId}',
  //  component: ArticleEditComponent,
  //  resolve: [
  //    {
  //      token: 'article',
  //      deps: ['article'],
  //      resolveFn: (article: any) => article,
  //    },
  //    {
  //      token: 'billingInfo',
  //      deps: ['billingInfo'],
  //      resolveFn: (billingInfo: any) => billingInfo,
  //    },
  //    {
  //      token: 'currentApp',
  //      deps: ['currentApp'],
  //      resolveFn: (currentApp: any) => currentApp,
  //    },
  //    {
  //      token: 'djangoUser',
  //      deps: ['djangoUser'],
  //      resolveFn: (djangoUser: any) => djangoUser,
  //    },
  //    {
  //      token: 'settings',
  //      deps: ['settings'],
  //      resolveFn: (settings: any) => settings,
  //    },
  //    {
  //      token: 'article',
  //      deps: ['$stateParams', '$state', ArticleModel, 'currentApp'],
  //      resolveFn: getArticle,
  //    },
  //    {
  //      token: 'currentCategory',
  //      deps: ['$stateParams', '$state', ArticleCategoryModel, 'currentApp'],
  //      resolveFn: getCurrentCategory,
  //    },
  //    {
  //      token: 'isKnowledgeBaseProActivated',
  //      deps: ['currentApp', 'planVersionService', 'planFeatureAccessService'],
  //      resolveFn: isKnowledgeBaseProActivated,
  //    },
  //    {
  //      token: 'articlesCount',
  //      deps: [ArticleModel, 'currentApp'],
  //      resolveFn: getArticlesCount,
  //    },
  //  ],
  //},
  //{
  //  name: 'app.content.knowledgeBase.articleEdit.copy',
  //  url: '/copy',
  //},
  //{
  //  name: 'app.content.knowledgeBase.articleCreate',
  //  url: '/{categoryId}/new',
  //  component: ArticleEditComponent,
  //  resolve: [
  //    {
  //      token: 'article',
  //      deps: ['article'],
  //      resolveFn: (article: any) => article,
  //    },
  //    {
  //      token: 'billingInfo',
  //      deps: ['billingInfo'],
  //      resolveFn: (billingInfo: any) => billingInfo,
  //    },
  //    {
  //      token: 'currentApp',
  //      deps: ['currentApp'],
  //      resolveFn: (currentApp: any) => currentApp,
  //    },
  //    {
  //      token: 'djangoUser',
  //      deps: ['djangoUser'],
  //      resolveFn: (djangoUser: any) => djangoUser,
  //    },
  //    {
  //      token: 'settings',
  //      deps: ['settings'],
  //      resolveFn: (settings: any) => settings,
  //    },
  //    {
  //      token: 'article',
  //      deps: ['$stateParams', '$state', ArticleModel, 'currentApp'],
  //      resolveFn: getArticle,
  //    },
  //    {
  //      token: 'articlesCount',
  //      deps: [ArticleModel, 'currentApp'],
  //      resolveFn: getArticlesCount,
  //    },
  //    {
  //      token: 'currentCategory',
  //      deps: ['$stateParams', '$state', ArticleCategoryModel, 'currentApp'],
  //      resolveFn: getCurrentCategory,
  //    },
  //    {
  //      token: 'isKnowledgeBaseProActivated',
  //      deps: ['currentApp', 'planVersionService', 'planFeatureAccessService'],
  //      resolveFn: isKnowledgeBaseProActivated,
  //    },
  //  ],
  //},
];

/**
 * Перенаправление в зависимости от существования БЗ
 *
 * @param transition
 *
 * @return {Promise}
 */
function redirectBasedOnKnowledgeBaseExistence(transition: any): any {
  return Promise.all([
    transition.injector().getAsync('knowledgeBaseModel'),
    transition.injector().getAsync('currentApp'),
  ]).then(([knowledgeBaseModel, currentApp]) => {
    return getSettings(knowledgeBaseModel, currentApp).then((settings: any) => {
      return Object.keys(settings).length === 0
        ? 'app.content.knowledgeBase.activate'
        : 'app.content.knowledgeBase.categories';
    });
  });
}

/**
 * Получение настроек БЗ
 *
 * @param knowledgeBaseModel
 * @param currentApp
 *
 * @returns {Promise}
 */
function getSettings(knowledgeBaseModel: KnowledgeBaseModel, currentApp: any) {
  return firstValueFrom(knowledgeBaseModel.get(currentApp.id)).then((response: any) => {
    return response === 'KnowledgeBaseAppDoesNotExist' ? {} : response;
  });
}

/**
 * Получение количества статей
 *
 * @param articleModel
 * @param currentApp
 *
 * @returns {Promise}
 */
function getArticlesCount(articleModel: ArticleModel, currentApp: any): Promise<any> {
  return firstValueFrom(articleModel.getListCount(currentApp.id));
}

/**
 * Получение списка категорий
 *
 * @param articleCategoryModel
 * @param currentApp
 *
 * @returns {Promise}
 */
function getCategoriesList(articleCategoryModel: ArticleCategoryModel, currentApp: any): Promise<any> {
  return firstValueFrom(articleCategoryModel.getList(currentApp.id));
}

/**
 * Проверка, подключена ли расширенная версия базы знаний
 */
function isKnowledgeBaseProActivated(currentApp: any, planVersionService: any, planFeatureAccessService: any): any {
  return (
    planVersionService.isTrial() ||
    planFeatureAccessService.getAccess(PLAN_FEATURE.KNOWLEDGE_BASE_PRO, currentApp).hasAccess
  );
}

/**
 * Получение статей для категории
 *
 * @param articleModel
 * @param currentApp
 * @param currentCategory
 *
 * @returns {Promise}
 */
function getArticlesListByCategory(articleModel: ArticleModel, currentApp: any, currentCategory: any): Promise<any> {
  return firstValueFrom(articleModel.getList(currentApp.id, true, true, currentCategory.id));
}

/**
 * Получение текущей категории
 *
 * @param $stateParams
 * @param $state
 * @param articleCategoryModel
 * @param currentApp
 *
 * @returns {Promise}
 */
function getCurrentCategory(
  $stateParams: any,
  $state: any,
  articleCategoryModel: ArticleCategoryModel,
  currentApp: any,
): Promise<any> {
  return firstValueFrom(
    articleCategoryModel.get(currentApp.id, $stateParams.categoryId).pipe(
      catchError(() => {
        $state.go('app.content.knowledgeBase.categories', { ...$stateParams, categoryId: null });

        return of();
      }),
    ),
  );
}

/**
 * Получение статьи
 *
 * @param $stateParams
 * @param $state
 * @param articleModel
 * @param currentApp
 *
 * @return {Promise}
 */
function getArticle($stateParams: any, $state: any, articleModel: ArticleModel, currentApp: any): Promise<any> {
  if ($stateParams.articleId) {
    return firstValueFrom(articleModel.get(currentApp.id, $stateParams.articleId)).catch(() => {
      $state.go('app.content.knowledgeBaseAjs.articleCreate', { ...$stateParams, articleId: null });
    });
  } else {
    return firstValueFrom(of(articleModel.getDefault()));
  }
}

import { firstValueFrom } from 'rxjs';
import { PLAN_FEATURE } from './../app/services/billing/plan-feature/plan-feature.constants';
import { OnboardingModel } from '../app/http/onboarding/onboarding.model';

(function () {
  'use strict';

  angular.module('myApp').config(configUiRouter).config(configStates).run(run);

  /**
   * Конфигурация uiRouter
   *
   * @param $locationProvider
   * @param $urlRouterProvider
   * @param $urlServiceProvider
   */
  function configUiRouter($locationProvider, $urlRouterProvider, $urlServiceProvider) {
    $locationProvider.html5Mode(true);

    // HACK strict mode: строки с настройками незначащих слешей и регистронезависимыми ссылками приходится дублировать там, где объявляются корневые состояния
    //  Так сделано, потому что эти настройки должны применяться до объявления корневых состояний, но блоки config никак не упорядочить, они отрабатывают не скорневого модуля, а по мере объявления файлов в html
    //  Гуглёж не помог решить эту проблему, есть только общее правило объявлять эти настройки до создания состояний https://github.com/angular-ui/ui-router/issues/1866#issuecomment-417176766
    $urlServiceProvider.config.caseInsensitive(true);
    $urlServiceProvider.config.strictMode(false);
    $urlServiceProvider.config.type('id', getIdType());
    $urlServiceProvider.config.type('appId', getAppIdType());

    // HACK: по идее всё должно быть сделано через $urlServiceProvider, т.к. $urlRouterProvider устаревший провайдер, но я не нашёл как при использовании $urlServiceProvider в otherwise переходить на внешнюю ссылку, поэтому оставил так
    $urlRouterProvider.otherwise(otherwise);

    //$urlServiceProvider.rules.otherwise(otherwise);

    /**
     * Определение нового типа параметра appId для URL состояния
     *
     * @return {Object}
     */
    function getAppIdType() {
      // HACK: паттерны в типах параметров не поддерживают regex-флаги, поэтому пришлось регистронезависимость делать костылём https://ui-router.github.io/ng1/docs/latest/interfaces/params.paramtypedefinition.html#pattern
      var APP_ID_REGEXP = /\d+|[Tt][Oo][Aa][Pp][Pp]/;

      return {
        pattern: APP_ID_REGEXP,
      };
    }

    /**
     * Определение нового типа параметра id для URL состояния
     *
     * @return {Object}
     */
    function getIdType() {
      var ID_REGEXP = /\d+/;

      return {
        pattern: ID_REGEXP,
      };
    }

    /**
     * Перенаправление в случае несуществующего URL
     */
    function otherwise($injector) {
      // FIXME: возможно, тут можно сделать красивее
      $injector.get('$window').location.href = $injector.get('utilsService').getExternalPagesAbsoluteUrl('login');
    }
  }

  /**
   * Конфигурация состояний административной панели
   *
   * @param $stateProvider
   * @param INTEGRATION_TYPES
   */
  function configStates($stateProvider, INTEGRATION_TYPES) {
    $stateProvider
      .state('onboarding', {
        //Fixme: когда configUiRouter будет вынесен в app.config.js надо будет перенести этот роут в app-onboarding
        url: '/onboarding/{appId:appId}',
        redirectTo: 'onboarding.appSettings',
        resolve: {
          appList: getAppList,
          currentApp: getCurrentApp,
          djangoUser: getDjangoUser,
        },
      })

      .state('questions', {
        url: '/questions/{appId:appId}',
        redirectTo: 'questions.questions',
        resolve: {
          appList: getAppList,
          currentApp: getCurrentApp,
          djangoUser: getDjangoUser,
          djangoUserTempData: getDjangoUserTempData,
        },
      })

      .state('app', {
        url: '/{appId:appId}', //abstract: true, //!!! это состояние не может быть абстрактным, т.к. при переходе по panel/{appId} напрямую из браузера переход на абстрактные состояния не осуществляется
        //bindings: {
        //  channelCounters: 'channelCounters',
        //  currentApp: 'currentApp'
        //},
        //component: 'cqGeneral',
        controller: 'CqGeneralController',
        controllerAs: 'vm',
        templateUrl: 'js/components/general/general.html',
        redirectTo: redirectBasedOnPermissions,
        params: {
          // выставляется в true, когда происходит переход из онбординга
          fromOnboarding: {
            value: false,
            dynamic: true,
          },
          fromQuestions: {
            value: false,
            dynamic: true,
          },
          showCompletionRegistrationModal: {
            value: false,
            dynamic: true,
          },
        },
        resolve: {
          appBlocks: getAppBlocks,
          appList: getAppList,
          billingInfo: getBillingInfo,
          channelCounters: getChannelCounters,
          channels: getChannels, // !!! каналы загружать обязательно! Потому что счётчики диалогов в каналах приходят без учёта разрешений на каналы! Поэтому чтобы корректно посчитать счётчики - необходимы данные о каналах. И ещё по одной причине: уведомления о новых сообщениях посылаются в app.js (что не правильно, но пока так), и там тоже нужна инфа о каналах сразу после перехода на app
          currentApp: getCurrentApp,
          djangoUser: getDjangoUser,
          djangoUserTempData: getDjangoUserTempData,
          featureLabels: getFeatureLabelsList,
          features: getFeaturesList,
          mutedChannels: getMutedChannels,
          starterGuide: getStarterGuide,
          teamMembers: getTeamMembers,
          teamMembersGroups: getTeamMembersGroups,
          planCapabilities: getPlanCapabilities,
        },
      })

      .state('app.content', {
        redirectTo: redirectBasedOnPermissions,
        views: {
          '': {
            template: '<div ui-view></div>',
          },
        },
      })

      // состояние, в котором все дочерние состояния используются для авторизации OAuth
      .state('oAuth', {
        abstract: true,
        url: '/oauth',
      })

      .state('3ds', {
        abstract: true,
        url: '/3ds',
      })

      .state('3ds.stripe', {
        url: '/stripe',
      });

    /**
     * Получение блокировок приложения
     * @param billingInfoModel
     * @param currentApp
     * @return {Promise}
     */
    function getAppBlocks(billingInfoModel, currentApp) {
      return firstValueFrom(billingInfoModel.getAppBlocks(currentApp.id));
    }

    /**
     * Получение списка приложений
     *
     * @param $rootScope
     * @param djangoUser
     * @return {Promise}
     */
    // !!! нужно добавить в $inject djangoUser, чтобы внтури роута app упорядочить вызов resolv'ов, т.к. без успешного запроса на данные пользователя дальше что-то делать бессмысленно
    function getAppList($rootScope, $transition$, djangoUser) {
      let appModel = $transition$.injector().get('appModel');

      return firstValueFrom(appModel.getList()).then(getAppListSuccess);

      function getAppListSuccess(apps) {
        // FIXME: потом убрать это, когда currentApp не нужно будет хранить в рутскоупе
        $rootScope.apps = apps;

        return apps;
      }
    }

    /**
     * Получение информации о биллинге
     *
     * @param billingInfoModel
     * @param currentApp
     * @returns {Promise}
     */
    function getBillingInfo(billingInfoModel, currentApp) {
      return firstValueFrom(billingInfoModel.get(currentApp.id));
    }

    /**
     * Получение счётчиков по каналам
     *
     * @param channelModel
     * @param channels
     * @param currentApp
     * @return {Promise}
     */
    function getChannelCounters(channelModel, channels, currentApp) {
      return firstValueFrom(channelModel.getCounters(currentApp.id));
    }

    /**
     * Получение списка каналов
     * HACK counters: т.к. счётчики присылаются без учёта разрешений члена команды на канал - нужно считывать это разрешение из канала. А для этого нужно получить весь список каналов сразу же после захода в приложение
     *
     * @param channelModel
     * @param currentApp
     * @return {*}
     */
    function getChannels(channelModel, currentApp) {
      return firstValueFrom(channelModel.getList(currentApp.id));
    }

    /**
     * Получение информации по выбранному приложению
     *
     * @param $q
     * @param $rootScope
     * @param $state
     * @param $transition$
     * @param ipCookie
     * @param carrotquestHelper
     * @param appList
     * @param djangoUser
     * @param l10nHelper
     * @return {Promise}
     */
    function getCurrentApp(
      $q,
      $rootScope,
      $state,
      $transition$,
      ipCookie,
      carrotquestHelper,
      appList,
      djangoUser,
      l10nHelper,
      appService,
    ) {
      let appModel = $transition$.injector().get('appModel');

      //FixMe Вызывается resolve два раза
      //Проверяем парраметр appId для того что бы можно было обратится по ссылке toApp/live
      var appId = $transition$.params().appId;
      if (appId.match(/^toApp$/i)) {
        $transition$.abort(); // отменяем переход на состояние (всё равно ничего не получится, т.к. appId === 'toApp') и формируем новое состояние

        appId = localStorage.getItem('currentAppId'); // пытаемся получить appId из localStorage
        // если не получилось - пытаемся достать appId из списка апов
        if (!appId && appList.length > 0) {
          appId = appList[0].id;
        }

        if (!appId) {
          // если appId не нашлось в localStorage и список апов оказался пустой - перенаправляем пользователя в аккаунт
          $state.go('account.content');
        } else {
          // если всё-таки appId нашёлся - то заменяем toApp на нормальный айдишник и перенаправляем пользователя на корректное состояние
          // параметры, полученные через $state.transition.params() нельзя менять, они immutable, поэтому создаётся новый объект и туда кладутся все параметры (подробнее тут https://github.com/angular-ui/ui-router/issues/3506#issuecomment-322370709)
          var params = angular.extend({}, $transition$.params(), { appId: appId });

          $state.go($transition$.to().name, params, { location: 'replace' });
        }

        return $q.reject();
      }

      return firstValueFrom(appModel.get(appId)).then(getCurrentAppSuccess).catch(getCurrentAppError);

      function getCurrentAppSuccess(app) {
        var permissions = {};
        switch (djangoUser.prefs[app.id].permissions) {
          case 'superadmin':
            permissions = { Суперадмин: 'Да' };
            break;
          case 'admin':
            permissions = { Админ: 'Да' };
            break;
          case 'operator':
            permissions = { Оператор: 'Да' };
            break;
        }

        carrotquestHelper.identify(permissions);

        // HACK: приходится присваивать текущий апп не только в onEnterInApp, но и тут, потому что в APIRequest проверяется currentApp до того, как вызвалась функция onEnterInApp. В будущем эту строчку отсюда надо убрать и оставить присвоение аппа только в onEnterInApp
        $rootScope.currentApp = app;

        // храним appId для того что бы можно было обратится по ссылке toApp/live
        localStorage.setItem('currentAppId', app.id);

        appService.app = app;

        // проставление куки бета-релиза на 30% аппов (такие аппы получают сборку из другой папки в nginx)
        if (l10nHelper.isRusCountry()) {
          const EXCLUSION_APP_ID_LIST = ['23139', '57433', '13160', '51753', '38815'];
          const SPECIAL_APP_ID_LIST = ['100', '2782'];

          if (EXCLUSION_APP_ID_LIST.includes(app.id)) {
            ipCookie('carrotquest_beta_release') && ipCookie.remove('carrotquest_beta_release', { path: '/' });
            return app;
          }

          let lastDigit = app.id % 10;
          if (lastDigit === 3 || lastDigit === 6 || lastDigit === 9 || SPECIAL_APP_ID_LIST.includes(app.id)) {
            !ipCookie('carrotquest_beta_release') &&
              ipCookie('carrotquest_beta_release', true, {
                expires: 100,
                path: '/',
              });
          } else {
            ipCookie('carrotquest_beta_release') && ipCookie.remove('carrotquest_beta_release', { path: '/' });
          }
        }

        return app;
      }

      function getCurrentAppError(response) {
        // если произошла какая-то ошибка на получение приложения - нужно перенаправить пользователя в аккаунт
        $state.go('account.content');
        return $q.reject();
      }
    }

    /**
     * Получение информации по текущему пользователю
     *
     * @param $q
     * @param $rootScope
     * @param $window
     * @param $transition$
     * @param ipCookie
     * @param utilsService
     * @return {Promise}
     */
    function getDjangoUser($q, $rootScope, $window, $transition$, ipCookie, utilsService) {
      let djangoUserModel = $transition$.injector().get('djangoUserModel');

      return firstValueFrom(djangoUserModel.get()).then(getDjangoUserSuccess).catch(getDjangoUserError);

      function getDjangoUserSuccess(djangoUser) {
        $rootScope.djangoUser = djangoUser;

        return djangoUser;
      }

      function getDjangoUserError(response) {
        // ошибка в этом запросе обычно происходит при отсутствии доступа (но не всегда, ещё может сервер упасть), а когда доступ отсутствует - нужно затереть токен и перенаправить пользователя на страницу логина
        if (response && (response.status === 401 || response.status === 403)) {
          // Reset
          ipCookie.remove('carrotquest_auth_token_panel', { path: '/' });

          // удаление куки с django user id, которая записана для fullstory
          ipCookie.remove('carrotquest_django_user_id', { path: '/' });

          $window.location.href = utilsService.getExternalPagesAbsoluteUrl(`login?path=${$window.location.pathname}`);
          return $q.reject();
        }
      }
    }

    /**
     * Получает временные данные для текущего django-пользователя
     *
     * @param $transition$
     * @param currentApp
     * @param djangoUser
     */
    function getDjangoUserTempData($transition$, currentApp, djangoUser) {
      let djangoUserModel = $transition$.injector().get('djangoUserModel');

      return firstValueFrom(djangoUserModel.getTempData(currentApp.id, djangoUser.id));
    }

    /**
     * Получение списка каналов в которых выключены уведомления
     *
     * @param channelModel - Модель получения данных для каналов
     * @param currentApp - Данные аппа
     * @param djangoUser - Джангоюзер
     */
    function getMutedChannels(channelModel, currentApp, djangoUser) {
      firstValueFrom(channelModel.getMutedChannels(currentApp.id, djangoUser.id)).then((mutedChannels) => {
        return mutedChannels;
      });
    }

    /**
     * Получение списка доступных фич
     */
    function getFeaturesList($transition$, currentApp) {
      return $transition$
        .injector()
        .getAsync('featureModel')
        .then((featureModel) => {
          // NOTE: currentApp нужно дождаться, чтобы запрос на фичи ушёл с указанием currentApp.id в get-параметрах
          //  Так же внутри featureModel.getList сделана подстраховка: ID приложения передаётся в APIRequest, чтобы он наверняка записался в get-параметр
          return firstValueFrom(featureModel.getList(currentApp.id));
        });
    }

    /**
     * Получение списка необходимых для отображения меток New
     *
     * @param featureLabelModel
     * @returns {Promise}
     */
    function getFeatureLabelsList(featureLabelModel) {
      return firstValueFrom(featureLabelModel.getList());
    }

    /**
     * Получение данных по доступам к фичам тарифа
     *
     * @param currentApp
     * @param planCapabilityModel
     *
     * @returns {Promise}
     */
    function getPlanCapabilities(currentApp, planCapabilityModel) {
      return firstValueFrom(planCapabilityModel.getList(currentApp.id));
    }

    /**
     * Получение данных стартергайда
     *
     * @param starterGuideModel
     * @param currentApp
     * @return {Promise}
     */
    function getStarterGuide(starterGuideModel, currentApp) {
      return firstValueFrom(starterGuideModel.get(currentApp));
    }

    /**
     * Получение членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
    }

    /**
     * Получение групп членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembersGroups($transition$, currentApp) {
      let teamMemberGroupModel = $transition$.injector().get('teamMemberGroupModel');

      return firstValueFrom(teamMemberGroupModel.getList(currentApp.id, true, true));
    }

    /**
     * Перенаправление в зависимости от прав члена команды на приложение
     *
     * @param transition
     * @return {Promise}
     */
    function redirectBasedOnPermissions(transition) {
      let $q = transition.injector().get('$q');
      let currentApp;
      let djangoUser;
      let djangoUserModel = transition.injector().get('djangoUserModel');
      let planCapabilityModel = transition.injector().get('planCapabilityModel');
      let planFeatureAccessService = transition.injector().get('planFeatureAccessService');

      return $q
        .all([
          transition.injector().getAsync('currentApp'), // !!! обязательно нужно дождаться получения currentApp, а не получать appId из параметров, потому что вместо appId может быть строка toApp, и тогда всё свалится
          transition.injector().getAsync('djangoUser'),
          transition.injector().getAsync('billingInfo'),
        ])
        .then(getAllAsyncInjectSuccess)
        .then(getPlanCapabilities)
        .then(redirect);

      function getAllAsyncInjectSuccess(result) {
        currentApp = result[0];
        djangoUser = result[1];
      }

      function getPlanCapabilities() {
        return firstValueFrom(planCapabilityModel.getList(currentApp.id));
      }

      function redirect() {
        const accessToDashboard = planFeatureAccessService.getAccess(PLAN_FEATURE.DASHBOARD, currentApp);
        const isOperator = djangoUserModel.isOperator(currentApp.id, djangoUser);

        if (isOperator || !accessToDashboard.hasAccess) {
          return 'app.content.conversations.general';
        }

        return 'app.content.dashboard';
      }
    }
  }

  /**
   * Настройка перенаправления на состояния админки
   *
   * @param $location
   * @param $q
   * @param $rootScope
   * @param $state
   * @param $transitions
   * @param $translate
   * @param $uibModalStack
   * @param $window
   * @param ipCookie
   * @param moment
   * @param INTEGRATION_TYPES
   * @param PROJECT_NAME
   * @param carrotquestHelper
   * @param faviconNotification
   * @param l10nHelper
   * @param pageTitleHelper
   * @param utilsService
   */
  function run(
    $location,
    $q,
    $rootScope,
    $state,
    $transitions,
    $translate,
    $uibModalStack,
    $window,
    ipCookie,
    API_OBJECT_NAME,
    moment,
    INTEGRATION_TYPES,
    NOT_AVAILABLE_FROM_MOBILE_URL,
    PROJECT_NAME,
    carrotquestHelper,
    faviconNotification,
    l10nHelper,
    pageTitleHelper,
    utilsService,
  ) {
    $transitions.onBefore({}, checkAuthToken); // перед каждым переходом в админке нужно проверить авторизацию
    $transitions.onBefore(
      {
        entering: 'app',
      },
      checkUserPermission,
    );
    $transitions.onBefore(
      {
        retained: 'app',
      },
      checkUserPermission,
    );

    $transitions.onBefore(
      {
        retained: 'app',
      },
      checkCompletionRegistrationModal,
    );
    $transitions.onBefore(
      {
        entering: 'app',
        exiting: 'app',
      },
      checkCompletionRegistrationModal,
    );
    $transitions.onBefore(
      {
        entering: 'app',
        exiting: 'account',
      },
      checkCompletionRegistrationModal,
    );

    $transitions.onEnter({ to: '3ds.stripe' }, onEnterToStripe3DS);

    $transitions.onEnter({ entering: 'app' }, onEnterInApp); // когда пользователь заходит в приложение - нужно произвести некоторые действия
    $transitions.onFinish({}, $rootScope.updateState); // FIXME: когда будет рефакториться этот файл - убрать это отсюда, updateState переименовать, и вызывать только 1 раз при входе в админку (потому что он и так один раз выполнит внутренний код)
    $transitions.onFinish({}, setTitle); // при загрузке админки нужно выставить title
    $transitions.onExit({ exiting: 'app' }, onExitFromApp); // когда пользователь покидает/меняет приложение - нужно произвести некоторые действия

    /**
     * Отправка трека после посещения третьего раздела панельки,
     * отправляем только для Dashly и тем кто еще его не получал
     */
    if (l10nHelper.isUsCountry() && !localStorage.getItem('isVisitedThreePanelSections')) {
      let panelSectionsTransitionsCount = 0; // Счетчик переходов по разделам приложения

      $transitions.onSuccess({ retained: 'app' }, stateChangeCounter);

      /**
       * Счетчик переходов по разделам приложения
       *
       * @param transition
       */
      function stateChangeCounter(transition) {
        const RELEASE_DATE = '2021-11-09'; // Дата релиза фичи на Dashly
        const TRANSITIONS_LIMIT = 3; // Количество переходов после которого будет отправка события
        const currentApp = transition.injector().get('currentApp');

        // Не отправляем событие если пользователь зарегистрировался до даты релиза фичи
        if (currentApp.created.isBefore(moment(RELEASE_DATE))) {
          return;
        }

        if (panelSectionsTransitionsCount < TRANSITIONS_LIMIT) {
          panelSectionsTransitionsCount++;
        } else {
          return;
        }

        if (panelSectionsTransitionsCount === TRANSITIONS_LIMIT) {
          carrotquestHelper.track(`Посетил три раздела админки`);
          localStorage.setItem('isVisitedThreePanelSections', 'true');
        }
      }
    }

    /**
     * Проверка наличия токена у пользователя
     *
     * @return {*}
     */
    function checkAuthToken() {
      // почти все части приложения - запрещенная зона без авториации
      if (!ipCookie('carrotquest_auth_token_panel')) {
        // FIXME: сделать тут проверку через $state, а не через $location (P.S. Хрен там, не получится тут так сделать, нужно в onBefore первым параметром эту штуку контроллировать, чтобы он вообще не зашёл в коллбэк, если состояние 'logout')
        if (
          !($location.path() === '/logout' || $location.path() === '/code' || $location.path() === '/message_template')
        ) {
          $window.location.href = utilsService.getExternalPagesAbsoluteUrl(`login?path=${$window.location.pathname}`);
        }

        if (!($location.path() === '/code' || $location.path() === '/message_template')) {
          return false;
        }

        return;
      }

      // если пользователь авторизован - продлеваем его токен в куки
      ipCookie('carrotquest_auth_token_panel', ipCookie('carrotquest_auth_token_panel'), {
        expires: 100,
        path: '/',
      });

      // NOTE Это запись django user id для fullstory, т.к. доступ к id нельзя получить из scope из-за prod mode
      ipCookie('carrotquest_django_user_id', ipCookie('carrotquest_django_user_id'), {
        expires: 100,
        path: '/',
      });

      // NOTE: Для поддоменов (например, лендингов) нужно понимать, что человек пользуется/пользовался сервисом (например, для показа попапов людям, которые никогда не регистрировались в сервисе)
      ipCookie('carrotquest_panel_user', true, {
        expires: 365,
        path: '/',
        domain: '.' + $location.host(),
      });
    }

    /**
     * При переходах между состояниями нужно затереть флаг для показа модалки успешной регистрации
     *
     * @param transition
     * @return {*}
     */
    function checkCompletionRegistrationModal(transition) {
      if (transition.params().showCompletionRegistrationModal) {
        return $state.target(
          transition.to().name,
          angular.extend({}, transition.params(), {
            showCompletionRegistrationModal: false,
          }),
        );
      }
    }

    /** Проверка прав пользователя на посещение раздела приложения */
    function checkUserPermission(transition) {
      return $q
        .all([transition.injector().getAsync('currentApp'), transition.injector().getAsync('djangoUser')])
        .then((result) => {
          return {
            currentApp: result[0],
            djangoUser: result[1],
          };
        })
        .then(({ currentApp, djangoUser }) => {
          // Роли, которым доступно состояние
          const availableRoles = transition.to().data?.roles;
          if (!availableRoles) {
            return;
          }

          const userRole = djangoUser.prefs[currentApp.id].permissions;
          const hasAccess = availableRoles?.includes(userRole);
          if (hasAccess) {
            return;
          }

          return transition.router.stateService.target(
            'app.content.permissionDenied',
            angular.extend({}, transition.params()),
          );
        });
    }

    /**
     * Выполняется при заходе в приложение
     *
     * @param transition
     */
    function onEnterInApp(transition) {
      // FIXME: потом убрать это, когда currentApp не нужно будет хранить в рутскоупе
      transition
        .injector()
        .getAsync('currentApp')
        .then((currentApp) => {
          $rootScope.currentApp = currentApp;
        });
      $rootScope.longPollSetup(transition);

      let blockPanelHelper = transition.injector().get('blockPanelHelper');
      blockPanelHelper.init($transitions);

      transition
        .injector()
        .getAsync('froalaHelper')
        .then((froalaHelper) => {
          froalaHelper.setup(); // HACK: не понятно где и как переводить сторонние библиотеки, пока вставил сюда
        });

      const djangoUserModel = transition.injector().get('djangoUserModel');
      const currentApp = $rootScope.currentApp;
      const djangoUser = $rootScope.djangoUser;
      const utilsService = transition.injector().get('utilsService');

      const isSuperAdmin = djangoUserModel.isSuperAdmin(currentApp.id, djangoUser);
      /*
       * После регистрации и заполнении недостающих регистрационных полей, необходимо отследить выполнение события
       * "Onboarding v2 - перешел на стартергайд". Оно должно выполнится один раз, т.к. на него завязаны приветственные
       * механики в сервисе. Использовать transition.from() не представляется возможным, т.к. при регистрации не через
       * Google, переход на /onboarding перехватывается и перенаправляется в app. Вследствие этого, form() становится
       * пустым, поэтому используется отдельный параметр передаваемый сквозь state.
       */
      if (transition.params().fromOnboarding) {
        return $state.target('questions', angular.extend({}, transition.params(), { fromOnboarding: false }));
      }

      if (transition.params().fromQuestions) {
        const isMobile = utilsService.isMobile();
        const showCompletionRegistrationModal = !ipCookie('carrotquest_oauth_google');
        ipCookie.remove('carrotquest_oauth_google', { path: '/' });

        trackFirstEnterToStarterGuide();

        // Отправка данных в GA
        if (typeof gtag === 'function') {
          gtag('event', 'signup_finish', {
            carrot_id: ipCookie(`${API_OBJECT_NAME}_uid`),
          });
        }

        if (isMobile) {
          trackMobileRegistration();

          $window.location.href = `${NOT_AVAILABLE_FROM_MOBILE_URL}?email=${djangoUser.email}`;
        }

        return $state.target(
          'app.content.dashboard',
          angular.extend({}, transition.params(), {
            fromQuestions: false,
            showCompletionRegistrationModal: showCompletionRegistrationModal,
          }),
        );
      }

      // если пользователь должен был завершить регистрацию, но ещё этого не сделал - перенаправляем его туда
      if (!OnboardingModel.isBaseDataChanged(currentApp, djangoUser) && isSuperAdmin) {
        return $state.target('onboarding.appSettings', { appId: currentApp.id });
      }

      // Трек события первого перехода на стартергайд из онбординга
      function trackFirstEnterToStarterGuide() {
        carrotquestHelper.track('Onboarding v2 - перешел на стартергайд');
      }

      // Трек регистрации с мобильного устройства
      function trackMobileRegistration() {
        const eventName = 'Зарегистрировался с мобильного устройства';

        carrotquestHelper.track(eventName);
      }
    }

    /**
     * Выполняется при редиректе после 3DS-подтверждения карты в Stripe
     */
    function onEnterToStripe3DS() {
      if (carrotquest && carrotquest.messenger) {
        carrotquest.messenger.toStateNo();
      }
      window.top.postMessage('3DS-authentication-complete');
    }

    /**
     * Выполняется при выходе из приложения
     */
    function onExitFromApp(transition) {
      // Закрываем все модальные окна, когда покидаем App
      $uibModalStack.dismissAll();

      $rootScope.longPollDestroy();
      // FIXME: потом убрать это, когда currentApp не нужно будет хранить в рутскоупе
      // HACK!!!: это условие сделано из-за корявого переключения между апами. Кейс следующий:
      //  Пользователь выбирает другой апп -> выполняется redirectTo (redirectBasedOnPermissions), в котором получаются djangoUser и currentApp и делается $rootScope.currentApp = app -> выполняется onExitFromApp -> выполняются остальные запросы
      //  Соответственно, перед тем, как выполняются остальные запросы $rootScope.currentApp зануляется и переставать существовать, а такого быть не должно, т.к. тем запросам нужен $rootScope.currentApp в сервисе APIRequest, чтобы сформировать правильный токен
      //  Все проблемы от рутскоупа, его вообще быть не должно. Поэтому написан костыль, который зануляет $rootScope.currentApp только тогда, когда у состояния, на которое осуществляется переход, отсутствует currentApp в резолвах
      try {
        transition.injector().get('currentApp');
      } catch (e) {
        $rootScope.currentApp = null;
      }

      faviconNotification.setFavicon();
    }

    /**
     * Установка title для страницы
     * FIXME: быстро написанный функционал, в будущем нужно сделать круче (динамическая смена title в зависимости от текущей страницы, цикличные title для уведомления о новых сообщениях и т.д.)
     */
    function setTitle() {
      pageTitleHelper.set($translate.instant('general.title', { projectName: PROJECT_NAME }));
    }
  }
})();

import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ElementRef,
  Inject,
  Injectable,
  Injector,
  NgZone,
  Renderer2,
  runInInjectionContext,
  ViewContainerRef,
} from '@angular/core';
import { NgbPopover, NgbPopoverConfig, NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class BootstrapElementsFactory {
  constructor(
    private readonly applicationRef: ApplicationRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly injector: Injector,
    private readonly ngZone: NgZone,
    private readonly popoverConfig: NgbPopoverConfig,
    private readonly renderer: Renderer2,
    private readonly tooltipConfig: NgbTooltipConfig,
    private readonly viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) {}

  /**
   * Создание тултипа
   * @param element - элемент на котором будет рендерится тултип
   * @param viewRef - viewContainer элемента на котором надо показать тултип
   * @param customConfig - Кастомный конфиг для тултипа
   */
  public createTooltip(
    element: ElementRef<HTMLElement>,
    viewRef: ViewContainerRef = this.viewContainerRef,
    customConfig: NgbTooltipConfig = this.tooltipConfig,
  ): NgbTooltip {
    // Создание через runInInjectionContext это небольшой хак
    // Это не стоит копировать. Лучше посмотри как сделано в цепочках
    return runInInjectionContext(
      this.injector,
      () =>
        new NgbTooltip(
          element,
          this.renderer,
          this.injector,
          viewRef,
          customConfig,
          this.ngZone,
          this.document,
          this.changeDetector,
          this.applicationRef,
        ),
    );
  }

  /**
   * Создание поповера
   * @param element - элемент на котором будет рендерится поповер
   * @param viewRef - viewContainer элемента на котором надо показать поповер
   * @param customConfig - Кастомный конфиг для поповера
   */
  public createPopover(
    element: ElementRef<HTMLElement>,
    viewRef: ViewContainerRef = this.viewContainerRef,
    customConfig: Partial<NgbPopoverConfig> = {},
  ): NgbPopover {
    const config = Object.assign({}, this.popoverConfig, customConfig);
    // Создание через runInInjectionContext это небольшой хак
    // Это не стоит копировать. Лучше посмотри как сделано в цепочках
    return runInInjectionContext(
      this.injector,
      () =>
        new NgbPopover(
          element,
          this.renderer,
          this.injector,
          viewRef,
          config,
          this.ngZone,
          this.document,
          this.changeDetector,
          this.applicationRef,
        ),
    );
  }
}

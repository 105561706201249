<div class="btn-group mr-30">
  <button
    *ngFor="let status of MESSAGE_STATUSES_ARRAY; trackBy: trackByFn"
    class="btn btn-outline-primary"
    [ngClass]="{ active: status === currentStatus }"
    (click)="onClick(status)"
    [disabled]="currentDirectory.name === SYSTEM_DIRECTORIES.ARCHIVE"
    type="button"
  >
    <i
      *ngIf="status === MESSAGE_STATUSES.ACTIVE"
      class="btn-icon-left cqi-sm cqi-play-o"
    ></i>
    <i
      *ngIf="status === MESSAGE_STATUSES.STOPPED"
      class="btn-icon-left cqi-sm cqi-pause"
    ></i>
    <span>{{ 'autoMessages.table.messageStatuses.' + status | transloco }}</span>
  </button>
</div>

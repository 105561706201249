import { translate } from '@jsverse/transloco';

import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';

import { BotHiddenAction } from './hidden-action/hidden.action';

export class BotEmailNotificationAction extends BotHiddenAction {
  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected get icon(): string {
    return '\ue912';
  }

  protected get translation(): string {
    return translate('classes.action.canvasText.emailNotification');
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { LeadsPassingPreviewModalComponent } from '@panel/app/pages/starter-guide/leads-passing/preview-modal/leads-passing-preview-modal.component';
import { LEADS_PASSING_PREVIEW_MODAL_DATA_TOKEN } from '@panel/app/pages/starter-guide/leads-passing/preview-modal/leads-passing-preview-modall.token';
import {
  AVAILABLE_INTEGRATIONS,
  STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES,
} from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.constants';
import { STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE } from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.types';
import { ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { App } from '@http/app/app.model';

/**
 * Компонент шага стартергайда про передачу лидов
 */
@Component({
  selector: 'cq-starter-guide-leads-passing[currentApp][isStepMade]',
  templateUrl: './starter-guide-leads-passing.component.html',
  styleUrls: ['./starter-guide-leads-passing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarterGuideLeadsPassingComponent {
  /**
   * Текущее приложение
   * Передаётся сюда исключительно из-за того, что нужно передавать текущий апп в пейвол
   */
  @Input()
  currentApp!: App;

  /** Текст описания шага */
  @Input()
  description: string = this.translocoService.translate('starterGuideLeadsPassingComponent.description');

  /** Отображаемые интеграции */
  @Input()
  displayedIntegrations: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE[] = AVAILABLE_INTEGRATIONS;

  /** Пройден ли текущий шаг стартергайда */
  @Input()
  isStepMade!: boolean;

  /** Типы интеграцией */
  STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES = STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES;

  constructor(
    private readonly integrationService: IntegrationService,
    private readonly modalHelperService: ModalHelperService,
    private readonly paywallService: PaywallService,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Доступна ли интеграция пользователю исходя из ограничений тарифа
   *
   * @param integrationType Тип интеграции
   */
  getAccessToIntegrationType(
    integrationType: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE,
  ): ProductFeatureAccess {
    const accessToIntegrationType = this.integrationService.getAccessToIntegrationType(
      integrationType as INTEGRATION_TYPES,
      this.currentApp,
    );

    return accessToIntegrationType;
  }

  /**
   * Получение перевода названия типа интеграции
   *
   * @param integrationType Тип интеграции
   */
  getIntegrationTypeNameTranslate(integrationType: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE): string {
    switch (integrationType) {
      case STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.LEAD_BOT:
        return this.translocoService.translate(`starterGuideLeadsPassingComponent.integrations.${integrationType}`);
      case STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.EMAIL_NOTIFICATION:
        return this.integrationService.getTranslatedIntegrationType(integrationType as INTEGRATION_TYPES);
      default:
        return this.translocoService.translate('starterGuideLeadsPassingComponent.integrations.integrationWith', {
          integrationTypeName: this.integrationService.getTranslatedIntegrationType(
            integrationType as INTEGRATION_TYPES,
          ),
        });
    }
  }

  /**
   * Обработчик клика по блоку с интеграцией
   *
   * @param integrationType Тип интеграции
   */
  onClickIntegrationPreview(integrationType: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE): void {
    const accessToIntegrationType = this.getAccessToIntegrationType(integrationType);

    if (accessToIntegrationType.hasAccess) {
      this.openIntegrationPreviewModal(integrationType);
    } else {
      this.paywallService.showPaywall(this.currentApp, accessToIntegrationType.denialReason);
    }
  }

  /**
   * Открытие модалки с превью выбранной интеграции
   *
   * @param integrationType Тип интеграции
   */
  openIntegrationPreviewModal(integrationType: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE): void {
    this.modalHelperService
      .provide(LEADS_PASSING_PREVIEW_MODAL_DATA_TOKEN, {
        heading: this.getIntegrationTypeNameTranslate(integrationType),
        integrationType,
      })
      .open(LeadsPassingPreviewModalComponent, {
        centered: true,
      });
  }
}

<ng-container *transloco="let t; read: 'balanceComponent'">
  <ng-container
    *tuiLet="{
      billing: subscriptionStore.billing$ | async,
      plan: subscriptionStore.plan$ | async,
      quotas: subscriptionStore.quotas$ | async
    } as values"
  >
    <ng-container *ngIf="values.plan && values.quotas && values.billing">
      <div class="d-flex align-items-end justify-content-between">
        <div>
          <small class="text-secondary">{{ t('heading') }}</small>
          <h4
            class="mb-0 font-weight-bold"
            [ngClass]="{ 'text-danger': notEnoughFunds() | async }"
          >
            {{ values.billing.customer.balance | toMoney | toFormattedMoney }}
          </h4>
        </div>
        <ng-container
          *ngIf="values.billing.subscription.isActive && !values.billing.subscription.isCancelledInEndBillingPeriod()"
        >
          <div class="d-flex align-items-center ml-auto">
            <small
              class="text-secondary"
              [innerHTML]="
                t('nextInvoice', {
                  date: values.billing.subscription.currentTermEnd | amDateFormat: 'L',
                  total:
                    values.billing.subscription.getTotalPrice(values.plan.availableUniqueUsers) +
                      values.quotas.getOverspendingUniqueUsers(values.plan.availableUniqueUsers) *
                        values.plan.overspendingUniqueUserPrice +
                      getOverspendingTotalPrice(
                        values.quotas.getOverspendingEmails(values.plan.availableEmails),
                        values.plan.overspendingEmailPrice
                      )
                    | toMoney
                    | toFormattedMoney
                })
              "
            ></small>
            <i
              class="cqi-sm cqi-question-circle cursor-pointer inline-block ml-5 text-secondary"
              [ngbPopover]="details"
              container="body"
              placement="bottom-right"
              triggers="mouseenter:mouseleave"
            ></i>
            <button
              *cqHideInUs
              type="button"
              class="btn btn-secondary ml-15"
              (click)="onClickTopUpBalance()"
            >
              {{ t('button') }}
            </button>
          </div>
        </ng-container>
      </div>

      <ng-template #details>
        <div class="margin-between-rows-10">
          <div>
            <div>{{ t('details.plan.title') }}</div>
            <div class="d-flex justify-content-between align-items-center">
              <small class="text-secondary">
                {{
                  t('details.plan.forMonth', {
                    price:
                      values.billing.subscription.getPlanTotalPriceForMonth(values.plan.availableUniqueUsers)
                      | toMoney
                      | toFormattedMoney
                  })
                }}
              </small>
              <b class="ml-30">{{
                values.billing.subscription.getPlanTotalPrice(values.plan.availableUniqueUsers)
                  | toMoney
                  | toFormattedMoney
              }}</b>
            </div>
          </div>

          <ng-container
            *ngIf="values.billing.subscription.getConnectedAddonsTotalPrice(values.plan.availableUniqueUsers)"
          >
            <div>
              <div>{{ t('details.addons.title') }}</div>
              <div class="d-flex justify-content-between align-items-center">
                <small class="text-secondary">
                  {{
                    t('details.addons.forMonth', {
                      price:
                        values.billing.subscription.getConnectedAddonsTotalPriceForMonth(
                          values.plan.availableUniqueUsers
                        )
                        | toMoney
                        | toFormattedMoney
                    })
                  }}
                </small>
                <b class="ml-30">{{
                  values.billing.subscription.getConnectedAddonsTotalPrice(values.plan.availableUniqueUsers)
                    | toMoney
                    | toFormattedMoney
                }}</b>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="values.quotas.getOverspendingUniqueUsers(values.plan.availableUniqueUsers)">
            <div>
              <div [innerHTML]="t('details.overspendingUniqueUsers.title')"></div>
              <div class="d-flex justify-content-between align-items-center">
                <small class="text-secondary">
                  {{
                    t('details.overspendingUniqueUsers.forUniqueUser', {
                      price: values.plan.overspendingUniqueUserPrice | toMoney | toFormattedMoney
                    })
                  }}
                </small>
                <b class="ml-30">
                  {{
                    values.quotas.getOverspendingUniqueUsers(values.plan.availableUniqueUsers) *
                      values.plan.overspendingUniqueUserPrice
                      | toMoney
                      | toFormattedMoney
                  }}
                </b>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="values.quotas.getOverspendingEmails(values.plan.availableEmails)">
            <div>
              <div [innerHTML]="t('details.overspendingEmails.title')"></div>
              <div class="d-flex justify-content-between align-items-center">
                <small class="text-secondary">
                  {{
                    t('details.overspendingEmails.forEmail', {
                      price: values.plan.overspendingEmailPrice | toMoney | toFormattedMoney
                    })
                  }}
                </small>
                <b class="ml-30">
                  {{
                    getOverspendingTotalPrice(
                      values.quotas.getOverspendingEmails(values.plan.availableEmails),
                      values.plan.overspendingEmailPrice
                    )
                      | toMoney
                      | toFormattedMoney
                  }}
                </b>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessageEditorTriggerModule } from '../trigger/message-editor-trigger.module';
import { MessageEditorTriggerWrapperComponent } from './message-editor-trigger-wrapper.component';

@NgModule({
  declarations: [MessageEditorTriggerWrapperComponent],
  exports: [MessageEditorTriggerWrapperComponent],
  imports: [CommonModule, MessageEditorTriggerModule],
})
export class MessageEditorTriggerWrapperModule {}

import { module } from 'angular';

import { DATE_FORMATS_FOR_RANGE, TIME_UNIT_MEASURES, TIME_UNITS } from './time-unit.constants';
import { TimeUnitService } from './time-unit.service';

module('myApp.services.timeUnit', []);

module('myApp.services.timeUnit')
  .constant('DATE_FORMATS_FOR_RANGE', DATE_FORMATS_FOR_RANGE)
  .constant('TIME_UNIT_MEASURES', TIME_UNIT_MEASURES)
  .constant('TIME_UNITS', TIME_UNITS)
  .service('timeUnitService', TimeUnitService);

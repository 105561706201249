<ng-container *transloco="let t">
  <div>
    <div class="d-flex align-items-center margin-bottom-10">
      <h3 class="mb-0">{{ t('vkFormsManualComponent.heading') }}</h3>
      <button
        class="btn btn-outline-secondary btn-sm ml-auto"
        [attr.aria-expanded]="!isManualExpanded"
        aria-controls="manualCollapse"
        type="button"
        (click)="manualCollapse.toggle()"
      >
        {{ t('models.integration.other.toggleManualVisibilityButtonText') }}
        <i
          class="btn-icon-right cqi-sm"
          [ngClass]="{ 'cqi-chevron-up': !isManualExpanded, 'cqi-chevron-down': isManualExpanded }"
        ></i>
      </button>
    </div>
    <p [innerHTML]="t('vkFormsManualComponent.description1')"></p>
    <p>{{ t('vkFormsManualComponent.description2') }}</p>
  </div>

  <div
    #manualCollapse="ngbCollapse"
    [(ngbCollapse)]="isManualExpanded"
  >
    <h3>{{ t('vkFormsManualComponent.steps.1.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.1.description') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-1.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.2.heading') }}</h3>
    <p [innerHTML]="t('vkFormsManualComponent.steps.2.description')"></p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-2.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.3.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.3.description.1') }}</p>
    <img
      class="w-100 margin-bottom-10"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-3.png"
    />
    <p>{{ t('vkFormsManualComponent.steps.3.description.2') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-4.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.4.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.4.description', { projectName: projectName }) }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-5.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.5.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.5.description.1', { projectName: projectName }) }}</p>
    <p>{{ t('vkFormsManualComponent.steps.5.description.2', { projectName: projectName }) }}</p>

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.6.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.6.description') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-6.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.7.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.7.description') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-7.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.8.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.8.description.1') }}</p>
    <img
      class="w-100 margin-bottom-10"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-8.png"
    />
    <p>{{ t('vkFormsManualComponent.steps.8.description.2') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-9.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.9.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.9.description') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-10.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.10.heading') }}</h3>
    <p>
      <span>{{ t('vkFormsManualComponent.steps.10.description.1') }}</span>
      <a
        [uiParams]="{ integrationType: INTEGRATION_TYPES.VK }"
        target="_blank"
        uiSref="app.content.integrations.details.unconfigured"
        >{{ t('vkFormsManualComponent.steps.10.description.2', { projectName: projectName }) }}</a
      >
      <span>{{ t('vkFormsManualComponent.steps.10.description.3') }}</span>
    </p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-11.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.11.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.11.description') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-12.png"
    />

    <hr />
    <h3>{{ t('vkFormsManualComponent.steps.12.heading') }}</h3>
    <p>{{ t('vkFormsManualComponent.steps.12.description') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/vk-forms/vk-forms-13.png"
    />
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { EventFilterModule } from '@panel/app/partials/filters/components/event-filter/event-filter.module';
import { EventSelectModule } from '@panel/app/partials/filters/components/event-select/event-select.module';

import { EventFiltersComponent } from './event-filters.component';

@NgModule({
  declarations: [EventFiltersComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    EventFilterModule,
    EventSelectModule,
  ],
  exports: [EventFiltersComponent],
})
export class EventFiltersModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ArticlePreviewModule } from '@panel/app/pages/knowledge-base/shared/components/article-preview/article-preview.module';

import { ArticlePreviewModalComponent } from './article-preview-modal.component';

@NgModule({
  declarations: [ArticlePreviewModalComponent],
  exports: [ArticlePreviewModalComponent],
  imports: [CommonModule, TranslocoModule, ArticlePreviewModule],
})
export class ArticlePreviewModalModule {}

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'cq-zero-data',
  templateUrl: './zero-data.component.html',
  styleUrls: ['./zero-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZeroDataComponent implements OnInit {
  /** Высота изображения */
  @Input()
  imgHeightPx?: number;

  /** Путь до изображения */
  @Input()
  src?: string = 'assets/img/default/users-zero.png';

  /** Изображение */
  @ViewChild('img', { static: true })
  img!: ElementRef<HTMLElement>;

  ngOnInit(): void {
    if (this.imgHeightPx) {
      this.img.nativeElement.style.height = `${this.imgHeightPx}px`;
    }
  }
}

import { ConversationTagDto } from '@panel/app-old/shared/entities/conversation-tag/types/conversation-tag-dto.types';

/** Adapter-сервис для работы с тегами диалогов */
export class ConversationTagAdapterService {
  //@ngInject
  constructor() {}

  /**
   * Преобразует из формата api
   *
   * @param dtoApi - DtoApi формат
   */
  fromApi(dtoApi: string): ConversationTagDto {
    return {
      text: dtoApi,
    };
  }

  /**
   * Преобразует в формат api
   *
   * @param dto - Dto формат
   */
  toApi(dto: ConversationTagDto): string {
    return dto.text;
  }
}

(function () {
  'use strict';

  require('./wizard-item.controller');

  angular.module('myApp.directives.wizard').directive('cqWizardItem', cqWizardItem);

  function cqWizardItem() {
    return {
      restrict: 'E',
      require: {
        cqWizardController: '^^cqWizard',
        cqWizardItemController: 'cqWizardItem',
      },
      scope: {
        name: '=',
        heading: '@',
        onEnterCallback: '&?onEnter',
        onEnterFromNextCallback: '&?onEnterFromNext',
        onEnterFromPreviousCallback: '&?onEnterFromPrevious',
        onExitToPreviousCallback: '&?onExitToPrevious',
        onExitCallback: '&?onExit',
        onExitToNextCallback: '&?onExitToNext',
      },
      controller: 'CqWizardItemController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
      template: require('./wizard-item.html'),
      transclude: true,
    };

    function link(scope, iElement, iAttrs, controllers, transcludeFn) {
      scope.$on('$destroy', controllers.cqWizardItemController.destroy);

      // эта функция вызывается внутри wizard-item-content для вставки контента в себя
      controllers.cqWizardItemController.transcludeFn = transcludeFn;
    }
  }
})();

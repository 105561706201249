import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmLeadTagsReadonlyModule } from '@panel/app/pages/integrations/content/amocrm/lead-tags-readonly/amocrm-lead-tags-readonly.module';

import { IntegrationChatBotsTriggerBlocksComponent } from './integration-chat-bots-trigger-blocks.component';

@NgModule({
  declarations: [IntegrationChatBotsTriggerBlocksComponent],
  exports: [IntegrationChatBotsTriggerBlocksComponent],
  imports: [AmocrmLeadTagsReadonlyModule, CommonModule, NgbAlertModule, TranslocoModule, UIRouterModule],
  providers: [IntegrationService],
})
export class IntegrationChatBotsTriggerBlocksModule {}

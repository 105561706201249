import { translate } from 'angular';
import { isArray, some } from 'lodash-es';

import { environment } from '@environment';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureDenialReason } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';
import { DenialReasonTexts } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.types';

/**
 * Сервис для работы с текстами связанными с ограничениями биллинга
 */

export class ProductFeatureTextService {
  /** Название проекта */
  private readonly projectName = environment.projectName;

  //@ngInject
  constructor(private readonly $translate: translate.ITranslateService) {}

  /**
   * Получение текстов для причин отказа в доступе
   *
   * @param reason - Причина | Причины отказа в доступе
   */
  getDenialReasonTexts(reason: ProductFeatureDenialReason | ProductFeatureDenialReason[]): DenialReasonTexts {
    const reasons: ProductFeatureDenialReason[] = isArray(reason) ? reason : [reason];

    return {
      action: this.getTextForAction(reasons),
      description: this.getTextForDescription(reasons),
      message: this.getTextForMessage(reasons),
      title: this.getTextForTitle(reasons),
    };
  }

  /**
   * Получение ключа для перевода в зависимости от израсходованного лимита фичи
   *
   * @param reasons - Причины ограничений в доступе
   */
  getLimitExceededTranslateKey(reasons: ProductFeatureDenialReason[]): 'limitExceeded' | 'notLimitExceeded' {
    return reasons.some((reason: ProductFeatureDenialReason) => reason.limitExceeded)
      ? 'limitExceeded'
      : 'notLimitExceeded';
  }

  /**
   * Объединяет массив строк в одну используя правильный разделитель
   *
   * @param array - Массив строк
   */
  private concat(array: string[]): string {
    const separator = this.$translate.instant('productFeatureTextService.separator');

    switch (true) {
      case array.length === 2:
        return array.join(` ${separator} `);
      case array.length > 2:
        return array.slice(0, -2).join(', ') + ', ' + array.slice(-2).join(` ${separator} `);
      default:
        return array.join();
    }
  }

  /**
   * Получение причин отказа в доступе связанных с аддонами
   *
   * @param reasons - Причины отказа в доступе
   */
  private getAddonReasons(reasons: ProductFeatureDenialReason[]): ProductFeatureDenialReason[] {
    return reasons.filter((reason: ProductFeatureDenialReason) => !!reason.addOn);
  }

  /**
   * Получение ID аддонов из причин отказа в доступе связанных с аддонами
   *
   * @param addonsReasons - Причины отказа в доступе связанные с аддонами
   */
  private getAddonsIds(addonsReasons: ProductFeatureDenialReason[]): BILLING_ADD_ONS[] {
    return addonsReasons.map((reason: ProductFeatureDenialReason) => {
      if (!reason.addOn) {
        throw Error('Addon is undefined');
      }

      return reason.addOn;
    });
  }

  /**
   * Получение текста для действия пользователя, чтобы обойти причину отказа в доступе
   *
   * @param reasons - Причины ограничений в доступе
   */
  private getTextForAction(reasons: ProductFeatureDenialReason[]): string {
    const limitExceededTranslateKey = this.getLimitExceededTranslateKey(reasons);

    switch (true) {
      case this.isNeedChat(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.chat.action`);
      case this.isNeedConnectAddons(reasons) && this.isNeedUpdatePlan(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.universal.action`);
      case this.isNeedConnectAddons(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.addon.action`);
      case this.isNeedUpdatePlan(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.capability.action`);
      default:
        throw Error('There is error case');
    }
  }

  /**
   * Получение текста для описания причины отказа в доступе
   *
   * @param reasons - Причины отказа в доступе
   */
  private getTextForDescription(reasons: ProductFeatureDenialReason[]): string {
    const limitExceededTranslateKey = this.getLimitExceededTranslateKey(reasons);

    const needProductFeatures = this.getProductFeatures(reasons);
    const translatedFeatureNames = this.getTranslatedProductFeatureNames(needProductFeatures);

    const addonReasons = this.getAddonReasons(reasons);
    const needAddonIds = this.getAddonsIds(addonReasons);
    const translatedAddonNames = this.getTranslatedAddonNames(needAddonIds);

    switch (true) {
      case this.isNeedChat(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.chat.description`);
      case this.isNeedConnectAddons(reasons) && this.isNeedUpdatePlan(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.universal.description`, {
          translatedAddonNames,
        });
      case this.isNeedConnectAddons(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.addon.description`, {
          translatedAddonNames,
        });
      case this.isNeedUpdatePlan(reasons):
        return this.$translate.instant(
          `productFeatureTextService.${limitExceededTranslateKey}.capability.description`,
          {
            translatedFeatureNames,
          },
        );
      default:
        throw Error('There is error case');
    }
  }

  /**
   * Получение текста сообщение для отправки в чат при отказе в доступе
   *
   * @param reasons - Причины отказа в доступе
   */
  private getTextForMessage(reasons: ProductFeatureDenialReason[]): string {
    const limitExceededTranslateKey = this.getLimitExceededTranslateKey(reasons);

    const needProductFeatures = this.getProductFeatures(reasons);
    const translatedFeatureNames = this.getTranslatedProductFeatureNames(needProductFeatures);

    if (this.isNeedChat(reasons)) {
      const firstReason = reasons[0];

      if (firstReason.paidPlanVersion === PAID_PLAN_VERSION.LTD) {
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.chat.message.ltd`, {
          translatedFeatureNames,
        });
      }
      return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.chat.message.default`, {
        translatedFeatureNames,
      });
    }

    return '';
  }

  /**
   * Получение заголовка для причины отказа в доступе
   *
   * @param reasons - Причины отказа в доступе
   */
  private getTextForTitle(reasons: ProductFeatureDenialReason[]): string {
    const limitExceededTranslateKey = this.getLimitExceededTranslateKey(reasons);

    const needProductFeatures = this.getProductFeatures(reasons);
    const translatedFeatureNames = this.getTranslatedProductFeatureNames(needProductFeatures);

    switch (true) {
      case this.isTriggeredMessagesMassActivationReasons(reasons):
        return this.$translate.instant('productFeatureTextService.triggeredMessagesMassActivation');
      case this.isLeadBotsMassActivationReasons(reasons):
        return this.$translate.instant('productFeatureTextService.leadBotsMassActivation');
      case this.isManualMessagesBulkSend(reasons):
        return this.$translate.instant('productFeatureTextService.manualMessagesBulkSend');
      case this.isNeedChat(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.chat.title`, {
          translatedFeatureNames,
        });
      case this.isNeedConnectAddons(reasons) && this.isNeedUpdatePlan(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.universal.title`, {
          translatedFeatureNames,
        });
      case this.isNeedConnectAddons(reasons):
        const addonReasons = this.getAddonReasons(reasons);
        const needAddonIds = this.getAddonsIds(addonReasons);
        const translatedAddonNames = this.getTranslatedAddonNames(needAddonIds);
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.addon.title`, {
          translatedAddonNames,
          translatedFeatureNames,
        });
      case this.isNeedUpdatePlan(reasons):
        return this.$translate.instant(`productFeatureTextService.${limitExceededTranslateKey}.capability.title`, {
          translatedFeatureNames,
        });
      default:
        throw Error('There is error case');
    }
  }

  /**
   * Получение переведённых названий аддонов
   *
   * @param addonIds - ID аддонов
   */
  private getTranslatedAddonNames(addonIds: BILLING_ADD_ONS[]): string {
    const addonNames = addonIds.map((addon) => {
      return this.$translate.instant(`models.billingInfo.billingAddOns.${addon}`);
    });

    return this.concat(addonNames);
  }

  /**
   * Получение продуктовых фич из причин отказа в доступе
   *
   * @param productFeatures - Продуктовые фичи
   */
  private getProductFeatures(productFeatures: ProductFeatureDenialReason[]): PLAN_FEATURE[] {
    return productFeatures.map((reason: ProductFeatureDenialReason) => {
      return reason.productFeature;
    });
  }

  /**
   * Получение переведённых названий фич
   *
   * @param productFeatures - Продуктовые фичи
   */
  private getTranslatedProductFeatureNames(productFeatures: PLAN_FEATURE[]): string {
    const featureNames = productFeatures.map((productFeature) => {
      return this.$translate.instant(`productFeatureTextService.feature.${productFeature}`, {
        projectName: this.projectName,
      });
    });

    return this.concat(featureNames);
  }

  /**
   * Является ли причина массовой активацией лид-ботов
   *
   * @param reasons - Причины отказа в доступе
   * @private
   */
  private isLeadBotsMassActivationReasons(reasons: ProductFeatureDenialReason[]): boolean {
    return some(reasons, { productFeature: PLAN_FEATURE.CHAT_BOTS_MASS_ACTIVATION });
  }

  /**
   * Является ли причина массовой ручной рассылки
   *
   * @param reasons - Причины отказа в доступе
   * @private
   */
  private isManualMessagesBulkSend(reasons: ProductFeatureDenialReason[]): boolean {
    return some(reasons, { productFeature: PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND });
  }

  /**
   * Необходимо ли пользователю написать в чат, чтобы получить доступ
   *
   * @param reasons - Причины отказа в доступе
   */
  private isNeedChat(reasons: ProductFeatureDenialReason[]): boolean {
    return some(reasons, { needChat: true });
  }

  /**
   * Является ли причина отказа в доступе не подключённый аддон
   *
   * @param reasons - Причины отказа в доступе
   */
  private isNeedConnectAddons(reasons: ProductFeatureDenialReason[]): boolean {
    return reasons.some((reason: ProductFeatureDenialReason) => !!reason.addOn);
  }

  /**
   * Является ли причина отказа в доступе отсутствие фичи в тарифном плане
   *
   * @param reasons - Причины отказа в доступе
   */
  private isNeedUpdatePlan(reasons: ProductFeatureDenialReason[]): boolean {
    return reasons.some((reason: ProductFeatureDenialReason) => !!reason.capability);
  }

  /**
   * Является ли причина массовой активацией триггерных сообщений
   *
   * @param reasons - Причины отказа в доступе
   * @private
   */
  private isTriggeredMessagesMassActivationReasons(reasons: ProductFeatureDenialReason[]): boolean {
    return some(reasons, { productFeature: PLAN_FEATURE.AUTO_MESSAGES_MASS_ACTIVATION });
  }
}

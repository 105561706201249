import { IPromise } from 'angular';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { ApiGenerateTmpInvoiceResponse, BillingDto } from '@http/billing/billing.types';
import { BillingHttpService } from '@http/billing/http/billing-http.service';
import { Customer } from '@http/customer/customer';
import { PAYMENT_REASON } from '@http/invoice/invoice.constants';
import { Requisites } from '@http/requisites/requisites';
import { Subscription } from '@http/subscription/subscription';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';

/** Класс для работы с биллингом */
export class Billing {
  /** Список бухгалтерских емейлов */
  accountingEmails: string[];
  /** 4 последние цифры платёжной карты */
  cardLast4: string;
  /** Плательщик */
  customer: Customer;
  /** Реквизиты компании */
  requisites: Requisites;
  /** Подписка */
  subscription: Subscription;

  constructor(
    private readonly httpService: BillingHttpService,
    private readonly factory: InstanceFactory,
    private readonly dto: BillingDto,
  ) {
    this.accountingEmails = dto.booker_emails;
    this.cardLast4 = dto.card_last4;
    this.customer = this.factory.getInstanceCustomer(dto.customer);
    this.requisites = this.factory.getInstanceRequisites(dto.contract_data);
    this.subscription = this.factory.getInstanceSubscription(dto.subscription);
  }

  /** Запуск процедуры привязки карты для автоматической оплаты счетов */
  addCard(): IPromise<string> {
    return firstValueFrom(this.httpService.addCard());
  }

  /**
   * Изменение биллингового емейла
   *
   * @param email
   */
  changeBillingEmail(email: string): IPromise<Billing> {
    return firstValueFrom(this.httpService.changeBillingEmail(email));
  }

  /** Удаление подключенной карты для оплат */
  deleteCard(): IPromise<Billing> {
    return firstValueFrom(this.httpService.deleteCard());
  }

  /**
   * Смена бухгалтерских емейлов
   *
   * @param emails Список бухгалтерских емейлов
   */
  editAccountingEmails(emails: string[]): IPromise<Billing> {
    return firstValueFrom(this.httpService.editAccountingEmails(emails));
  }

  /** Генерация временного счёта для привязки карты */
  generateTmpInvoiceForAddCard(): IPromise<ApiGenerateTmpInvoiceResponse> {
    return firstValueFrom(this.httpService.generateTmpInvoice({ planId: '__add_card__' }));
  }

  /** Отмена подписки в текущий момент */
  cancelSubscriptionAtNow(): IPromise<Billing> {
    return firstValueFrom(this.httpService.cancelSubscriptionAtNow());
  }

  /** Отмена подписки в конце текущего биллинг периода */
  cancelSubscriptionAtEndBillingPeriod(): IPromise<Billing> {
    return firstValueFrom(this.httpService.cancelSubscriptionAtEndBillingPeriod());
  }

  /**
   * Пополнение баланса
   *
   * @param amount Сумма пополнения (в рублях)
   */
  topUpBalance(amount: string): IPromise<void> {
    return firstValueFrom(
      this.httpService.generateTmpInvoice({ amount: parseFloat(amount) * 100, initial: false }),
    ).then((response) => {
      let a = document.createElement('a');

      a.href = `${environment.paymentUrl}?code=${response.code}&payment_reason=${PAYMENT_REASON.REFILL_BALANCE}`;
      a.style.display = 'none';
      a.target = '_blank';

      document.body.appendChild(a);

      a.click();

      a.remove();
    });
  }
}

<ng-container *transloco="let t; read: 'messageEditorAnotherEventComponent'">
  <div
    class="flex"
    [cqShowError]="control"
  >
    <ng-container *ngIf="messageEditorTriggerState.currentApp$ | async as currentApp">
      <cq-event-type-select
        class="w-100"
        [formControl]="control"
        [autoEvents]="(messageEditorTriggerState.autoEvents$ | async) ?? []"
        (autoEventsChange)="messageEditorTriggerState.autoEvents$.next($event)"
        [eventTypes]="(messageEditorTriggerState.eventTypes$ | async) ?? []"
        (eventTypesChange)="messageEditorTriggerState.eventTypes$.next($event)"
        [userProps]="(messageEditorTriggerState.userProps$ | async) ?? []"
        [currentApp]="currentApp"
        [showHeader]="false"
      >
      </cq-event-type-select>
    </ng-container>
    <button
      *ngIf="!isOnlyEvent"
      class="btn btn-outline-primary ml-10"
      type="button"
      (click)="trackClickRemoveTrigger(); deleteTrigger.next()"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>

  <!-- ПРОВЕРИТЬ ВАЛИДАЦИЮ -->
  <cq-validation-messages
    [control]="control"
    [position]="'bottom'"
  >
    <cq-validation-message errorName="required">
      {{ t('triggersSelect.errors.required') }}
    </cq-validation-message>
  </cq-validation-messages>

  <div
    *ngIf="isUnusedEventMessageVisible(control.value)"
    class="py-10 d-flex align-items-start"
  >
    <i class="cqi-sm cqi-triangle-exclamation-o text-danger mr-5"></i>

    <small class="text-muted">
      <span>
        {{
          t('unusedEventDescription.main', {
            projectName
          })
        }}
      </span>
      <br />
      <span
        *ngIf="getArticleByEventTypeId(control.value!); else checkSettings"
        [innerHTML]="
          t('unusedEventDescription.goToArticle', {
            articleUrl: getArticleByEventTypeId(control.value!)
          })
        "
      >
      </span>
      <ng-template #checkSettings>
        <span> {{ t('unusedEventDescription.checkSettings') }}</span>
      </ng-template>
    </small>
  </div>
</ng-container>

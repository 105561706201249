(function () {
  'use strict';

  require('./double-opt-in-settings.controller');

  angular.module('myApp.privacySettings').component('cqDoubleOptInSettings', cqDoubleOptInSettings());

  function cqDoubleOptInSettings() {
    return {
      bindings: {
        apiAppAlreadyExists: '=', // HACK: пока не понятно как делать серверную валидацию во вложенных формах, поэтому пришлось костылять при помощи дополнительного параметра компонента api-app-already-exists
        appName: '=', // название приложения
        currentApp: '=', // приложение
        messageSender: '=', // отправитель для писем Double Opt-In
        logo: '=', // новый логотип FIXME HACK: костыль, т.к. не понятно как по-человечески работать с новым загружаемым файлом
        settings: '=', // настройки обработки персональных данных
      },
      controller: 'CqDoubleOptInSettingsController',
      controllerAs: 'vm',
      template: require('./double-opt-in-settings.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { UrlInputModule } from '@panel/app/partials/url-input/url-input.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { WorksOnPageComponent } from './works-on-page.component';

@NgModule({
  declarations: [WorksOnPageComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, UrlInputModule, SharedModule],
  exports: [WorksOnPageComponent],
})
export class WorksOnPageModule {}

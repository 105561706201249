import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { STARTER_GUIDE_TASKS } from '@http/starter-guide/starter-guide.constants';
import { VideoViewModalService } from '@panel/app/services/video-view/video-view-modal.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Компонент шапки в стартергайде
 */
@Component({
  selector: 'cq-starter-guide-header[selectedTask]',
  templateUrl: './starter-guide-header.component.html',
  styleUrls: ['./starter-guide-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarterGuideHeaderComponent implements OnInit {
  /** Блокировать ли select */
  @Input()
  isDisabledTaskSelect: boolean = false;

  /** Выбранная задача */
  @Input()
  selectedTask!: string;

  /** Колбэк на смену задачи */
  @Output()
  taskChanged: EventEmitter<string> = new EventEmitter<string>();

  /** Текущий язык */
  activeLang!: string;

  /** Заголовок модального окна */
  headingModal!: string;

  /** Название проекта */
  projectName = environment.projectName;

  /** Список доступных задач, которые решает сервис */
  tasks: string[] = Object.values(STARTER_GUIDE_TASKS);

  /** Url видео */
  videoSrc!: string;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly translocoService: TranslocoService,
    private readonly videoViewModalService: VideoViewModalService,
  ) {
    this.activeLang = translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.headingModal = this.translocoService.translate('starterGuideHeaderComponent.modal.heading');
    this.videoSrc = this.translocoService.translate('starterGuideHeaderComponent.modal.src');
  }

  /**
   * Обработчик смены задачи
   *
   * @param newTask Выбранная задача
   */
  changeTask(newTask: string): void {
    this.taskChanged.emit(newTask);
  }

  /**
   * Открытие модального окна для просмотра видео
   *
   * @param heading - Заголовок модального окна
   * @param videoUrl - Ссылка на видео
   */
  openViewVideoModal(heading: string, videoUrl: string): void {
    this.videoViewModalService
      .open({
        heading: heading,
        videoUrl: videoUrl,
      })
      .result.then(null, () => {
        this.trackVideoViewed();
      });
  }

  /**
   * Перевод для задачи
   *
   * @param task Задача, для которой получается перевод
   */
  translateTask(task: STARTER_GUIDE_TASKS): string {
    return this.translocoService.translate(`starterGuideHeaderComponent.taskSelect.options.${task}`);
  }

  /** Трек просмотра видео про сервиса */
  trackVideoViewed(): void {
    const eventName = 'Стартергайд - welcome-видео просмотрено';

    this.carrotquestHelper.track(eventName);
  }
}

import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';

/**
 * Валидаторы для массива значений
 */
/** @param validatorFn - Валидаторов, который нужно применить к каждому элементу значения */
export function validatorsForEach(validatorFn: ValidatorFn): ValidatorFn;
/** @param validatorsFnList - Массив валидаторов, которые нужно применить к каждому элементу значения */
export function validatorsForEach(validatorsFnList: ValidatorFn[]): ValidatorFn;
export function validatorsForEach(...args: any): any {
  return (control: AbstractControl): any => {
    let resultValidation = {};

    for (let value of control.value) {
      let control = new UntypedFormControl(value);

      if (typeof args[0] === 'function') {
        const controlResultValidation = args[0](control);
        resultValidation = controlResultValidation;

        // Если значение не валидно, следующие можно не проверять
        if (controlResultValidation) {
          break;
        }
      } else {
        args[0].forEach((validatorFn: ValidatorFn) => {
          resultValidation = Object.assign(resultValidation, validatorFn(control));
        });
      }
    }

    return resultValidation;
  };
}

<ng-container *transloco="let t; read: 'messageEditorCollapseComponent'">
  <div class="d-flex align-items-start justify-content-between">
    <ng-content select="[titleContent]"></ng-content>

    <div class="d-flex flex-column inner-container w-100 mt-5 mx-auto">
      <div [ngbCollapse]="!collapse">
        <ng-content select="[collapsedContent]"></ng-content>
      </div>
      <div [ngbCollapse]="collapse">
        <ng-content select="[expandContent]"></ng-content>
      </div>
    </div>

    <div class="d-flex justify-content-end flex-grow-1 no-flex-basis">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="changeCollapse()"
      >
        <div *ngIf="collapse">
          <span class="mr-10">{{ t('setting') }}</span>
          <i class="cqi-sm cqi-chevron-down margin-left-auto"></i>
        </div>

        <div *ngIf="!collapse">
          <span class="mr-10">{{ t('hide') }}</span>
          <i class="cqi-sm cqi-chevron-up margin-left-auto"></i>
        </div>
      </button>
    </div>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { DatetimePickerModule } from '@panel/app/shared/components/datetime-picker/datetime-picker.module';

import { TimepickerPageComponent } from './timepicker-page.component';

@NgModule({
  declarations: [TimepickerPageComponent],
  exports: [TimepickerPageComponent],
  imports: [CommonModule, DatetimePickerModule, FormsModule, TranslocoModule],
})
export class TimepickerPageModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { SingleUrlFilterConfiguratorModule } from '@panel/app/partials/url-filter-configurator/single-url-filter-configurator/single-url-filter-configurator.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { UrlFilterConfiguratorComponent } from './url-filter-configurator.component';

@NgModule({
  declarations: [UrlFilterConfiguratorComponent],
  exports: [UrlFilterConfiguratorComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    SharedModule,
    NgbPopoverModule,
    SingleUrlFilterConfiguratorModule,
  ],
  providers: [CarrotquestHelperProvider],
})
export class UrlFilterConfiguratorModule {}

<ng-container *transloco="let t">
  <div
    class="row"
    *ngIf="selectedEventTriggers.length > 0"
  >
    <div class="col-5">
      <strong>{{ t('eventTriggersReadonly.heading') }}</strong>
    </div>
    <div class="col-7">
      <div
        class="margin-bottom-5"
        data-test="filtered-event-div"
        *ngFor="let event of filteredAndSortEventTypes"
      >
        {{ event.prettyName }}
      </div>
    </div>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmChatBotsForLeadModule } from '@panel/app/pages/integrations/content/amocrm/chat-bots-for-lead/amocrm-chat-bots-for-lead.module';
import { AmocrmEventTriggersForLeadModule } from '@panel/app/pages/integrations/content/amocrm/event-triggers-for-lead/amocrm-event-triggers-for-lead.module';
import { AmocrmPipelineModule } from '@panel/app/pages/integrations/content/amocrm/pipeline/amocrm-pipeline.module';
import { AmocrmPropsToLeadModule } from '@panel/app/pages/integrations/content/amocrm/props-to-lead/amocrm-props-to-lead.module';
import { AmocrmTriggerMessagesForLeadModule } from '@panel/app/pages/integrations/content/amocrm/trigger-messages-for-lead/amocrm-trigger-messages-for-lead.module';

import { AmocrmDataToLeadsComponent } from './amocrm-data-to-leads.component';

@NgModule({
  declarations: [AmocrmDataToLeadsComponent],
  exports: [AmocrmDataToLeadsComponent],
  imports: [
    AmocrmChatBotsForLeadModule,
    AmocrmEventTriggersForLeadModule,
    AmocrmPipelineModule,
    AmocrmPropsToLeadModule,
    AmocrmTriggerMessagesForLeadModule,
    CommonModule,
    NgbTooltipModule,
    TranslocoModule,
  ],
  providers: [IntegrationService],
})
export class AmocrmDataToLeadsModule {}

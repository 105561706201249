import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/** Компонент для работы с действием "Создать тег" */
@Component({
  selector: 'cq-tag-create[intentToCreateTag]',
  templateUrl: './tag-create.component.html',
  styleUrls: ['./tag-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagCreateComponent {
  /** Callback на намеренье создать тег */
  @Output()
  intentToCreateTag: EventEmitter<void> = new EventEmitter<void>();

  /** Обрабатывает намеренье создать тег */
  onIntentToCreateTag(): void {
    this.intentToCreateTag.emit();
  }
}

<ng-container *transloco="let t; read: 'requestBodyEditorComponent'">
  <div
    style="height: 200px"
    class="mb-10"
  >
    <ace
      [config]="aceConfig"
      [value]="formControl.value"
      (valueChange)="formControl.setValue($event)"
    ></ace>
  </div>
  <small
    class="text-secondary"
    [innerHTML]="t('description')"
  ></small>
</ng-container>

<ng-container *transloco="let t; read: 'knowledgeBaseArticlesComponent'">
  <cq-knowledge-base
    [billingInfo]="billingInfo"
    [currentApp]="currentApp"
    [currentCategory]="currentCategory"
    [djangoUser]="djangoUser"
    [isKnowledgeBaseProActivated]="isKnowledgeBaseProActivated"
    [isLoading]="isLoading"
    [settings]="settings"
  >
    <cq-articles-list
      [articles]="articles"
      [articlesCount]="articlesCount"
      [billingInfo]="billingInfo"
      [categories]="categories"
      [currentApp]="currentApp"
      [currentCategory]="currentCategory"
      [isSearch]="isSearch"
      (onDataUpdate)="updateData()"
      (onResetSearch)="resetSearch()"
      data-test="cq-knowledge-base-articles-cq-articles-list"
    >
      <cq-articles-search
        [isSearch]="isSearch"
        (onResetSearch)="resetSearch()"
        (onSubmitSearch)="submitSearch($event)"
        [(searchPhrase)]="searchPhrase"
      ></cq-articles-search>
    </cq-articles-list>
  </cq-knowledge-base>
</ng-container>

<ng-container *transloco="let t; read: 'communicationStyleControlComponent'">
  <h4 class="mb-5">{{ t('heading') }}</h4>
  <div class="mb-10 text-secondary">{{ t('description') }}</div>
  <ng-select
    [items]="selectItems"
    [formControl]="control"
    [clearable]="false"
    [searchable]="false"
  >
    <ng-template
      ng-label-tmp
      let-item="item"
    >
      {{ t('select.items.' + item + '.name') }}
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
    >
      <div>{{ t('select.items.' + item + '.name') }}</div>
      <div class="small text-secondary">{{ t('select.items.' + item + '.description') }}</div>
    </ng-template>
  </ng-select>
  <div class="small text-secondary mt-5">{{ t('select.items.' + control.value + '.description') }}</div>
</ng-container>

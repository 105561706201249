<ng-container *transloco="let t; read: 'triggerChainReactionEditorComponent'">
  <cq-trigger-chain-step-editor-wrap
    [formControl]="form.controls.name"
    (deleteBlockClick)="deleteStepChain()"
    (copyBlockClick)="copyStepChain()"
  >
    <div class="p-20">
      <div class="text-secondary">{{ t('description') }}</div>

      <div class="mt-15">
        <label
          for="reactionType"
          class="mb-10"
        >
          <span>{{ t('reactionType.label') }}</span>
          <i
            class="cqi-sm cqi-question-circle"
            placement="top-right"
            container="body"
            [ngbTooltip]="t('reactionType.tooltip')"
          ></i>
        </label>
        <div [cqShowError]="form.controls.reactionType">
          <ng-select
            id="reactionType"
            class="trigger-chain-reaction-type-selector"
            [placeholder]="t('reactionType.select.placeholder')"
            [searchable]="false"
            [clearable]="false"
            [formControl]="form.controls.reactionType"
          >
            <ng-container *ngFor="let blockType of TRIGGER_CHAIN_REACTION_TYPES; let i = index">
              <ng-option [value]="blockType">
                <!-- это костыль(в стилях), ответственность за него на Жене, я ему сказал, что это плохой прецедент -->
                <!-- он сказал, что если что уберем) -->
                <hr *ngIf="blockType === TRIGGER_CHAIN_REACTION_TYPE.EVENT_TYPE" />
                <div class="d-flex align-items-baseline">
                  <i
                    class="cqi-sm"
                    [ngClass]="getBlockTypeIconClass(blockType)"
                  ></i>
                  <div class="ml-10 white-space-normal">
                    <div class="text-truncate">{{ t('reactionType.select.' + blockType + '.label') }}</div>
                    <div class="small text-secondary mt-5">
                      {{ t('reactionType.select.' + blockType + '.description') }}
                    </div>
                  </div>
                </div>
              </ng-option>
            </ng-container>

            <ng-template
              ng-label-tmp
              let-blockType="item"
            >
              <div class="d-flex align-items-center">
                <i
                  class="cqi-sm"
                  [ngClass]="getBlockTypeIconClass(blockType)"
                ></i>
                <span class="ml-10">{{ t('reactionType.select.' + blockType + '.label') }}</span>
              </div>
            </ng-template>
          </ng-select>

          <cq-validation-messages [control]="form.controls.reactionType">
            <cq-validation-message errorName="required">{{ t('errors.required') }}</cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>

      <cq-event-type-select
        *ngIf="form.controls.reactionType.value === TRIGGER_CHAIN_REACTION_TYPE.EVENT_TYPE"
        class="w-100 mt-10"
        [formControl]="form.controls.eventType"
        [autoEvents]="(triggerChainEditorStore.autoEvents$ | async) ?? []"
        (autoEventsChange)="triggerChainEditorStore.autoEvents$.next($event)"
        [eventTypes]="(triggerChainEditorStore.eventTypes$ | async) ?? []"
        (eventTypesChange)="triggerChainEditorStore.eventTypes$.next($event)"
        [userProps]="(triggerChainEditorStore.properties$ | async)?.userProps ?? []"
        [currentApp]="$any(triggerChainEditorStore.currentApp$.getValue())"
        [showHeader]="false"
        [placeholderText]="t('reactionType.select.event_type.selector.placeholder')"
      >
      </cq-event-type-select>
    </div>
    <div class="px-20">
      <label for="reactionTime">{{ t('reactionTime.label') }}</label>
      <cq-time-selector
        id="reactionTime"
        class="mt-5"
        [formControl]="form.controls.reactionTime"
        [maxSeconds]="MAX_REACTION_TIME"
      ></cq-time-selector>
    </div>
  </cq-trigger-chain-step-editor-wrap>
</ng-container>

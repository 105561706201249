<ng-container *transloco="let t; read: 'editBillingEmailModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="text-secondary mb-15">{{ t('body') }}</div>
    <form
      (ngSubmit)="onSubmit()"
      [formGroup]="form"
    >
      <div [cqShowError]="form.controls.email">
        <input
          class="form-control"
          [formControl]="form.controls.email"
          [placeholder]="t('emailInput.placeholder')"
        />
        <cq-validation-messages [control]="form.controls.email">
          <cq-validation-message errorName="maxlength">{{
            t('emailInput.errors.maxlength', { maxLength: BILLING_EMAIL_MAX_LENGTH })
          }}</cq-validation-message>
          <cq-validation-message errorName="required">{{ t('emailInput.errors.required') }}</cq-validation-message>
          <cq-validation-message errorName="email">{{ t('emailInput.errors.email') }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <input
        class="hidden"
        id="form.submit"
        type="submit"
        [disabled]="isApiRequestPerformed | async"
      />
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <label
      class="btn btn-primary"
      for="form.submit"
      [ngClass]="{ disabled: isApiRequestPerformed | async }"
      >{{ 'general.save' | transloco }}</label
    >
  </div>
</ng-container>

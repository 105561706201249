<div class="d-flex align-items-center justify-space-between">
  <a
    [href]="landingUrl"
    (click)="trackLogoClick()"
  >
    <img
      class="w-100"
      alt="logo"
      [src]="logoPath"
    />
  </a>
</div>

import { IMAGE_EXTENSION } from '../../services/file-helper/file-helper.constants';
import { UserColorsCalculationService } from '../../../../app/shared/services/user-colors-calculation/user-colors-calculation.service';
import { BOT_SENDER_DEFAULT_AVATAR_URL } from '../../../../app/http/message-sender/message-sender.constants';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.modals.leadBotSettings').controller('CqLeadBotSettingsController', CqLeadBotSettingsController);

  function CqLeadBotSettingsController(
    USER_FILES_URL,
    carrotquestHelper,
    fileHelper,
    validationHelper,
    messageSenderModel,
  ) {
    var vm = this;

    /**
     * Максимальный размер аватарки (1мб)
     *
     * @type {number}
     */
    var AVATAR_MAX_SIZE = 1024 * 1024;

    /**
     * Максимальная длинна имени бота
     *
     * @type {number}
     */
    var MAX_BOT_NAME_LENGTH = 255;

    /**
     * Минимальная длинна имени бота
     *
     * @type {number}
     */
    var MIN_BOT_NAME_LENGTH = 2;

    vm.$onInit = init;

    function init() {
      vm.AVATAR_MAX_SIZE = AVATAR_MAX_SIZE;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.BOT_SENDER_DEFAULT_AVATAR_URL = BOT_SENDER_DEFAULT_AVATAR_URL;
      vm.namePatternError = false;
      vm.fileHelper = fileHelper;
      vm.IMAGE_EXTENSION = IMAGE_EXTENSION;
      vm.MAX_BOT_NAME_LENGTH = MAX_BOT_NAME_LENGTH;
      vm.MIN_BOT_NAME_LENGTH = MIN_BOT_NAME_LENGTH;
      vm.sender = angular.copy(vm.resolve.sender);
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса
      vm.botSettingsForm = {}; // Форма настроек бота
      vm.newAvatar = {
        // Новый логотип
        file: null, // файл
        preview: null, // превью
      };
      vm.save = save;
      vm.setDefaultAvatar = setDefaultAvatar;
      vm.validationHelper = validationHelper;

      const updatedUserColor = UserColorsCalculationService.getMainUserColor(
        vm.resolve.currentApp.settings.messenger_collapsed_color,
        vm.resolve.currentApp.settings.messenger_theme,
      );
      vm.colorPalette = UserColorsCalculationService.getUserColorPalette(
        updatedUserColor,
        vm.resolve.currentApp.settings.messenger_theme,
      );

      if (vm.sender.avatar === vm.BOT_SENDER_DEFAULT_AVATAR_URL && !vm.newAvatar.file) {
        vm.setDefaultAvatar();
      }
    }

    /**
     * Сохранение настроек
     *
     * @param {Boolean} isValid - Валидность формы
     */
    function save(isValid) {
      if (isValid) {
        vm.isApiRequestPerformed = true;
        vm.namePatternError = false;

        vm.sender.avatar = vm.newAvatar.file || vm.sender.avatar;
        firstValueFrom(messageSenderModel.save(vm.sender))
          .then(saveSuccess)
          .catch(isNameContainsError)
          .finally(saveFinally);
      }

      function saveFinally() {
        vm.isApiRequestPerformed = false;
      }

      function isNameContainsError(response) {
        if (response.meta.error_fields.name) {
          vm.namePatternError = true;
        }
      }

      function saveSuccess(sender) {
        if (sender.name !== vm.resolve.sender.name) {
          trackChangeName();
        }
        if (sender.avatar !== vm.resolve.sender.avatar) {
          trackChangeAvatar();
        }

        trackSave();

        vm.modalInstance.close(sender);
      }
    }

    /**
     * Установка аватара по умолчанию
     */
    function setDefaultAvatar() {
      vm.newAvatar.file = null;
      vm.sender.avatar = UserColorsCalculationService.changeDefaultAvatarByCondition(
        vm.resolve.currentApp.settings.messenger_collapsed_color,
        vm.resolve.currentApp.settings.messenger_theme,
        BOT_SENDER_DEFAULT_AVATAR_URL,
      );
      vm.sender.setDefaultAvatar = true;
    }

    /**
     * Трек смены аватарки бота
     */
    function trackChangeAvatar() {
      carrotquestHelper.track('Чат-бот - поменял аватарку бота');
    }

    /**
     * Трек смены имени ботов
     */
    function trackChangeName() {
      carrotquestHelper.track('Чат-бот - поменял имя бота');
    }

    /**
     * Трек сохранения внешности бота
     */
    function trackSave() {
      carrotquestHelper.track('Чат-бот - клик на сохранить внешность ботов');
    }
  }
})();

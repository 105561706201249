import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment/moment';

@Component({
  selector: 'cq-timepicker-page',
  templateUrl: './timepicker-page.component.html',
  styleUrls: ['./timepicker-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerPageComponent {
  timepicker1: moment.Moment = moment();
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CqHideInRuDirective } from '@panel/app/shared/directives/hide-in-country/cq-hide-in-ru.directive';
import { HighlightDirective } from '@panel/app/shared/directives/highlight/highlight.directive';

import { AutofocusDirective } from './autofocus/autofocus.directive';
import { AutosizeTextareaDirective } from './autosize-textarea/autosize-textarea.directive';
import { FileDirective } from './file/file.directive';
import { FileAsDataUrlDirective } from './file-as-data-url/file-as-data-url.directive';
import { FloatDirective } from './float/float.directive';
import { CqHideInUsDirective } from './hide-in-country/cq-hide-in-us.directive';
import { ImageMaxHeightDirective } from './image-max-height/image-max-height.directive';
import { IntegerDirective } from './integer/integer.directive';
import { MaxSizeDirective } from './max-size/max-size.directive';
import { NumberDirective } from './number/number.directive';
import { ShowErrorDirective } from './show-error/show-error.directive';
import { StateActiveDirective } from './state-active/state-active.directive';
import { StickyStuckDirective } from './sticky-stuck/sticky-stuck.directive';
import { TrueFalseValueDirective } from './true-false-value/true-false-value.directive';

const directives = [
  AutofocusDirective,
  AutosizeTextareaDirective,
  CqHideInRuDirective,
  CqHideInUsDirective,
  HighlightDirective,
  NumberDirective,
  ShowErrorDirective,
  StateActiveDirective,
  StickyStuckDirective,
  TrueFalseValueDirective,
  ImageMaxHeightDirective,
  MaxSizeDirective,
  FileDirective,
  FileAsDataUrlDirective,
  IntegerDirective,
  FloatDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...directives, ImageMaxHeightDirective, MaxSizeDirective, FileDirective, FileAsDataUrlDirective],
  exports: [...directives],
})
export class DirectivesModule {}

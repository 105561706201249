/**
 * Фильтр для удаления href="..." со всем ссылкок (надо чтобы превью не могло открывать ссылки)
 */
(function () {
  'use strict';

  angular.module('myApp.filters.removeHref').filter('removeHref', removeHref);

  function removeHref() {
    var findLinksToAddTargetBlankRegexp = /<a(.*?)>[\s\S]*?<\/a>/gim;
    var findHrefRegexp = /href=".*?"/gim;

    return addTargetBlankFilter;

    /**
     * Функция, которая выполняет поиск ссылок в строке и удаляет href
     *
     * @param {String} value Строка, в которой будут искаться ссылки
     * @returns {String}
     */
    function addTargetBlankFilter(value) {
      if (value.constructor === String) {
        value = value.replace(findLinksToAddTargetBlankRegexp, function (entry, match) {
          const matchWithoutHref = match.replace(findHrefRegexp, '');
          return entry.replace(match, matchWithoutHref);
        });
      }
      return value;
    }
  }
})();

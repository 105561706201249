import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

/**
 * Компонент с input'ом и кнопкой копирования значения из input'а
 */

@Component({
  selector: 'cq-input-copy-button',
  templateUrl: './input-copy-button.component.html',
  styleUrls: ['./input-copy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class InputCopyButtonComponent implements OnInit {
  /** Текст кнопки */
  @Input()
  buttonText?: string;

  /** Значение плейсхолдера input'а */
  @Input()
  inputPlaceholder: string = '';

  /** Значение input'а */
  @Input()
  inputValue: string = '';

  /** Текст тоста об успешном копировании значения из input'а */
  @Input()
  successToastText?: string;

  /** Получение текста кнопки по умолчанию */
  get defaultButtonText(): string {
    return this.translocoService.translate('inputCopyButtonComponent.button.text');
  }

  /** Получение текста тоста об успешном копировании значения из input'а по умолчанию */
  get defaultSuccessToastText(): string {
    return this.translocoService.translate('inputCopyButtonComponent.toasts.copySuccess');
  }

  constructor(
    @Inject(ClipboardService) private readonly clipboardService: ClipboardService,
    @Inject(DestroyService) private readonly destroyService$: DestroyService,
    @Inject(ToastService) private readonly toastService: ToastService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.clipboardService.copyResponse$
      .pipe(takeUntil(this.destroyService$))
      .subscribe((response: IClipboardResponse) => {
        if (response.isSuccess && response.content === this.inputValue) {
          this.onCopySuccess();
        }
      });
  }

  /** Копирование значения из input'а в буфер обмена */
  copyValue(): void {
    this.clipboardService.copy(this.inputValue);
  }

  /** Получение текста кнопки */
  getButtonText(): string {
    return this.buttonText || this.defaultButtonText;
  }

  /** Callback на успешное копирование значения из input'а в буфер обмена */
  onCopySuccess(): void {
    const text = this.successToastText || this.defaultSuccessToastText;

    this.toastService.success(text);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { QuillTextEditorModule } from '@panel/app/partials/quill-text-editor/quill-text-editor.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchActionTextComponent } from './text.component';

@NgModule({
  declarations: [BranchActionTextComponent],
  imports: [CommonModule, SharedModule, FormsModule, TranslocoModule, QuillTextEditorModule, ReactiveFormsModule],
  exports: [BranchActionTextComponent],
})
export class BranchActionTextModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EMAIL_KARMA_STATUSES,
  EMAIL_KARMA_STATUSES_ORDER,
  EMAIL_KARMA_STATUSES_TYPES,
} from 'app/http/email-karma/email-karma.constants';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmailKarmaModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Получение данных кармы за жалобы, спам и bounce-rate в рассылке email-писем
   *
   * @param appId ID текущего приложения
   */
  getEmailKarma(appId: string) {
    return this.http.get('/apps/' + appId + '/email_karma').pipe(map((data) => this.parse(data)));
  }

  /**
   * Проверяет поле status у каждого key в объекте emailKarma
   * Возвращает объект со следующими полями:
   * @param emailKarma Ответ на запрос текущего статуса email кармы с API
   * @property emailKarma.statuses {Object} response с API
   * @property emailKarma.worstStatus {Object} содержит name, type, values худшего статуса
   *
   */
  private parse(emailKarma: any) {
    const worstMetrics: any = {};

    // Сортирует статусы по любому количеству key в объекте emailKarma
    //@ts-ignore даже не хочу разбираться как это работает
    Object.keys(emailKarma).sort((a: string, b: string): number => {
      // Проверяет равенство статусов
      if (
        EMAIL_KARMA_STATUSES_ORDER[emailKarma[a].status as EMAIL_KARMA_STATUSES] ===
        EMAIL_KARMA_STATUSES_ORDER[emailKarma[b].status as EMAIL_KARMA_STATUSES]
      ) {
        angular.extend(worstMetrics, emailKarma[a], emailKarma[b]);
        worstMetrics.type = EMAIL_KARMA_STATUSES_TYPES.EQUAL;
      }

      // Проверяет что a > b
      if (
        EMAIL_KARMA_STATUSES_ORDER[emailKarma[a].status as EMAIL_KARMA_STATUSES] >
        EMAIL_KARMA_STATUSES_ORDER[emailKarma[b].status as EMAIL_KARMA_STATUSES]
      ) {
        angular.extend(worstMetrics, emailKarma[a]);
        worstMetrics.type = a;
      }

      // Проверяет что b > a
      if (
        EMAIL_KARMA_STATUSES_ORDER[emailKarma[a].status as EMAIL_KARMA_STATUSES] <
        EMAIL_KARMA_STATUSES_ORDER[emailKarma[b].status as EMAIL_KARMA_STATUSES]
      ) {
        angular.extend(worstMetrics, emailKarma[b]);
        worstMetrics.type = b;
      }
    });

    const result = {
      statuses: emailKarma,
      worstMetrics: worstMetrics,
    };

    return result;
  }
}

import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.messageTemplates').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.messagesAjs.templates', {
        url: '/messages/templates',
        redirectTo: 'app.content.messagesAjs.templates.list',
        template: '<div ui-view></div>',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
      })

      .state('app.content.messagesAjs.templates.list', {
        url: '',
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          directories: 'templateDirectories',
          djangoUser: 'djangoUser',
        },
        resolve: {
          templateDirectories: getTemplateDirectoriesWithAllDirectory,
        },
        component: 'cqMessageTemplates',
      })

      .state('app.content.messagesAjs.templates.create', {
        url: '/new',
        resolve: {
          messagePart: getMessagePartFromTemplate,
          properties: getProperties,
          template: getTemplate, // FIXME тут получается, что шаблон получается дважды в обоих запросах. Надо это оптимизировать
          templateDirectories: getTemplateDirectoriesWithoutAllDirectory,
        },
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          directories: 'templateDirectories',
          djangoUser: 'djangoUser',
          properties: 'properties',
          template: 'template',
        },
        component: 'cqMessageTemplatesEdit',
      })

      .state('app.content.messagesAjs.templates.detail', {
        url: '/{templateId:id}',
        resolve: {
          messagePart: getMessagePartFromTemplate,
          properties: getProperties,
          template: getTemplate, // FIXME тут получается, что шаблон получается дважды в обоих запросах. Надо это оптимизировать
          templateDirectories: getTemplateDirectoriesWithoutAllDirectory,
        },
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          directories: 'templateDirectories',
          djangoUser: 'djangoUser',
          properties: 'properties',
          template: 'template',
        },
        component: 'cqMessageTemplatesEdit',
      })

      .state('app.content.messagesAjs.templates.detail.copy', {
        url: '/copy',
      });

    /**
     * Получение варианта сообщения
     *
     * @param $stateParams
     * @param $transition$
     * @returns {Promise}
     */
    function getMessagePartFromTemplate($stateParams, $transition$) {
      let messagePartModel = $transition$.injector().get('messagePartModel');
      let messageTemplateModel = $transition$.injector().get('messageTemplateModel');

      if ($stateParams.templateId) {
        return firstValueFrom(messageTemplateModel.get($stateParams.templateId)).then(
          messagePartModel.parseFromTemplate.bind(messagePartModel),
        );
      } else {
        return messagePartModel.parseFromTemplate(messageTemplateModel.getDefault());
      }
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение шаблона
     *
     * @param $stateParams
     * @param $state
     * @param $transition$
     * @returns {*}
     */
    function getTemplate($stateParams, $state, $transition$) {
      let messageTemplateModel = $transition$.injector().get('messageTemplateModel');

      if ($stateParams.templateId) {
        return firstValueFrom(messageTemplateModel.get($stateParams.templateId)).catch(getTemplateError);
      } else {
        return messageTemplateModel.getDefault();
      }

      function getTemplateError() {
        // Если запрос завершился ошибкой, скорее всего, шаблона с таким ID нет => надо перенаправить на создание нового
        $state.go('app.content.messagesAjs.templates.create', angular.extend({}, $stateParams, { templateId: null }));
      }
    }

    /**
     * Получение списка директорий шаблонов с папкой "Все шаблоны"
     *
     * @param $transition$
     * @param currentApp
     * @param planFeatureAccessService
     *
     * @returns {Promise}
     */
    function getTemplateDirectoriesWithAllDirectory($transition$, currentApp, planFeatureAccessService) {
      let templateDirectoryModel = $transition$.injector().get('templateDirectoryModel');

      const accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, currentApp);

      if (accessToMessagesTemplates.hasAccess) {
        return firstValueFrom(templateDirectoryModel.getList(currentApp.id, true, true));
      } else {
        return new Promise((resolve) => resolve([]));
      }
    }

    /**
     * Получение списка директорий шаблонов без папки "Все шаблоны"
     *
     * @param $transition$
     * @param currentApp
     * @param planFeatureAccessService
     *
     * @returns {Promise}
     */
    function getTemplateDirectoriesWithoutAllDirectory($transition$, currentApp, planFeatureAccessService) {
      let templateDirectoryModel = $transition$.injector().get('templateDirectoryModel');

      const accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, currentApp);

      if (accessToMessagesTemplates.hasAccess) {
        return firstValueFrom(templateDirectoryModel.getList(currentApp.id, true, false));
      } else {
        return new Promise((resolve) => resolve([]));
      }
    }
  }
})();

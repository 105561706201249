(function () {
  'use strict';

  require('./copy-script.controller');

  angular.module('myApp.serviceInstallation').component('cqCopyScript', cqCopyScript());

  function cqCopyScript() {
    return {
      bindings: {
        onCopy: '&?',
      },
      controller: 'cqCopyScriptController',
      controllerAs: 'vm',
      template: require('./copy-script.html'),
      transclude: true,
    };
  }
})();

(function () {
  'use strict';

  angular.module('myApp.users').controller('CqPrepareForLeadsImportController', CqPrepareForLeadsImportController);

  function CqPrepareForLeadsImportController($translate, PROJECT_NAME, carrotquestHelper) {
    var vm = this;

    /**
     * Максимальный размер файла
     *
     * @type {number}
     */
    var MAX_FILE_SIZE = 30 * 1024 * 1024;

    vm.$onInit = init;

    function init() {
      vm.getTemplateFileLink = getTemplateFileLink;
      vm.MAX_FILE_SIZE = MAX_FILE_SIZE;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.trackClickSystemProperty = trackClickSystemProperty;
      vm.trackClickTemplateImportFile = trackClickTemplateImportFile;
    }

    /**
     * Получение имени типа импорта для трека событий
     *
     * @param {String} importType - Тип импорта
     */
    function getImportNameForTrack(importType) {
      return importType === vm.importTypes.LEADS ? 'Импорт лидов' : 'Импорт пользователей';
    }

    /**
     * Получение ссылка на файл с шаблоном
     *
     * @returns {string}
     */
    function getTemplateFileLink() {
      switch (vm.importType) {
        case vm.importTypes.USERS:
          return 'assets/template-import-files/users-' + $translate.use() + '.csv';
        case vm.importTypes.LEADS:
          return 'assets/template-import-files/leads-' + $translate.use() + '.csv';
        case vm.importTypes.TELEGRAM:
          return 'assets/template-import-files/telegram-' + $translate.use() + '.csv';
      }
    }

    /**
     * Трек клика по ссылке "список системных свойств"
     */
    function trackClickSystemProperty() {
      carrotquestHelper.track('Импорт лидов - клик по ссылке - список системных свойств', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек открытия шаблона
     */
    function trackClickTemplateImportFile() {
      carrotquestHelper.track('Импорт лидов - открыть шаблон файла для импорта', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }
  }
})();

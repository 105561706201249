import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CaseStyleHelper } from '@panel/app/services';
import { IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Преобразует все query-параметры запроса в _underscore
 * Не путать с RequestBodyToUnderscoreInterceptor, он преобразует тело запроса (POST/PATCH и другие запросы)
 * Так делается, поскольку внутри админки все параметры используются в camelCase, а бэкенд присылает результаты и принимает на вход всё в _underscore
 * В этом интерсепторе отсутствует функционал игнорирования преобразования ключей, т.к. ВСЕ query-параметры ВСЕГДА преобразуются в _underscore
 */
@Injectable()
export class RequestParamsToUnderscoreInterceptor implements HttpInterceptor {
  constructor(private caseStyleHelper: CaseStyleHelper) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    if (request.context.get(IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS)) {
      return next.handle(request);
    }

    // проще способа превратить HttpParams в объект я не нашёл
    const paramsObject = request.params.keys().reduce((object: any, key) => {
      object[key] = request.params.get(key);
      return object;
    }, {});

    request = request.clone({
      // HACK: Не знаю насколько это хак, но пришлось кастануть тип, т.к. caseStyleHelper возвращает не то, что надо
      params: new HttpParams({
        fromObject: this.caseStyleHelper.keysToUnderscore(paramsObject) as { [param: string]: string },
      }),
    });

    return next.handle(request);
  }
}

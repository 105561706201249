/**
 * Компонент для завершения регистрации
 */

(function () {
  'use strict';

  require('./completion-registration.controller');

  angular.module('myApp.completionRegistration').component('cqCompletionRegistration', cqCompletionRegistration());

  function cqCompletionRegistration() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
      },
      controller: 'cqCompletionRegistrationController',
      controllerAs: 'vm',
      template: require('./completion-registration.html'),
    };
  }
})();

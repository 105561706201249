(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqOpencartModalController', CqOpencartModalController);

  function CqOpencartModalController($translate, PROJECT_NAME, carrotquestHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.trackClickDownloadModule = trackClickDownloadModule;
    }

    /**
     * Трек клика на 'Скачать модуль'
     */
    function trackClickDownloadModule() {
      carrotquestHelper.track('Интеграции - ' + vm.resolve.integration.type + ' - клик на "Скачать модуль"');
    }
  }
})();

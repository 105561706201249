import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BotBlocksOverlayModule } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-blocks-overlay.module';

import { CanvasOptionsModule } from '../canvas-options/canvas-options.module';
import { BotCanvasEditorComponent } from './bot-canvas-editor.component';

@NgModule({
  declarations: [BotCanvasEditorComponent],
  imports: [CommonModule, CanvasOptionsModule, BotBlocksOverlayModule],
  exports: [BotCanvasEditorComponent],
})
export class BotCanvasEditorModule {}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, merge, Observable, startWith, Subject, switchMap, tap } from 'rxjs';

import { TriggerChainListItem } from '@http/trigger-chain/internal-types';
import { TriggerChainModel } from '@http/trigger-chain/trigger-chain.model';

import { CHAIN_STATUS } from '../trigger-chain-list.constants';
import { TriggerChainListState } from '../trigger-chain-list-state.service';

@Component({
  selector: 'cq-trigger-chain-table',
  templateUrl: './trigger-chain-table.component.html',
  styleUrls: ['./trigger-chain-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainTableComponent {
  isLoading: boolean = false;

  selectedStatus: CHAIN_STATUS = CHAIN_STATUS.ACTIVE;

  CHAIN_STATUS_LIST = Object.values(CHAIN_STATUS);

  CHAIN_STATUS = CHAIN_STATUS;

  /** Обновление списка цепочек по статусу */
  refreshListSubj: Subject<void> = new Subject();

  /** Изменение списка цепочек по статусу */
  changeStatusSubj: BehaviorSubject<CHAIN_STATUS> = new BehaviorSubject(this.selectedStatus);

  /** Список цепочек */
  chainList$: Observable<TriggerChainListItem[]> = merge(
    this.refreshListSubj,
    this.changeStatusSubj.pipe(tap((status) => (this.selectedStatus = status))),
  ).pipe(
    tap(() => (this.isLoading = true)),
    switchMap(() =>
      this.triggerChainModel.getAll(this.selectedStatus, {
        properties: this.triggerChainListState.properties$.getValue(),
        userTags: this.triggerChainListState.tags$.getValue(),
      }),
    ),
    tap((chainList) => this.triggerChainListState.chainList$.next(chainList)),
    tap(() => (this.isLoading = false)),
    startWith(this.triggerChainListState.chainList$.getValue()),
  );

  constructor(
    private readonly triggerChainModel: TriggerChainModel,
    private readonly triggerChainListState: TriggerChainListState,
  ) {}

  trackByChain(index: number, chain: TriggerChainListItem) {
    return chain.id;
  }
}

<ng-container>
  <div
    class="margin-between-rows-10"
    [ngClass]="{ overlay: isShowOverlay }"
  >
    <div
      *ngFor="let chatWidget of activeChatWidgetList"
      class="widget"
      [ngClass]="{ highlighted: isCurrent(chatWidget) }"
      [style.--accent-color]="accentColor"
    >
      <div class="date">{{ chatWidget.subHeader }}</div>
      <div class="heading">{{ chatWidget.header }}</div>
      <button
        class="button"
        type="button"
        [style.border-color]="accentColor"
        [style.--accent-color]="accentColor"
        [style.--contrast-color]="contrastColor"
      >
        {{ chatWidget.buttonText }}
      </button>
    </div>
  </div>
</ng-container>

(function () {
  'use strict';

  angular.module('myApp.votePreview').component('cqVotePreview', cqVotePreview());

  function cqVotePreview() {
    return {
      bindings: {
        messengerColor: '=', // цвет чата
      },
      controllerAs: 'vm',
      template: require('./vote-preview.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { PricingAddonModule } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addon/pricing-addon.module';
import { PricingAddonsComponent } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.component';

@NgModule({
  declarations: [PricingAddonsComponent],
  exports: [PricingAddonsComponent],
  imports: [CommonModule, TranslocoModule, PricingAddonModule, FormsModule],
})
export class PricingAddonsModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { FILTER_EVENT_TYPE } from '@panel/app/services/filter/filter.constants';
import { EventFilter } from '@panel/app/services/filter/types/filter.internal-types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';
import { EventType } from '@http/property/property.model';

@Component({
  selector: 'cq-event-filter[index][events][removeFilter]',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFilterComponent extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<EventFilter>> {
  @Input()
  index!: number;

  @Input()
  events!: EventType[];

  @Input()
  showEventCounter: boolean = true;

  @Input()
  showEventTypeSelect: boolean = true;

  @Input()
  showRemoveButton: boolean = true;

  _showOperationSelect: boolean = true;
  @Input()
  get showOperationSelect(): boolean {
    return this._showOperationSelect && !this.isEventIdNull();
  }
  set showOperationSelect(value: boolean) {
    this._showOperationSelect = value;
  }

  @Output()
  removeFilter: EventEmitter<number> = new EventEmitter<number>();

  readonly control: FormGroup<ToFormGroupControls<EventFilter>> = this.fb.group<ToFormGroupControls<EventFilter>>({
    eventId: this.fb.control(null, { nonNullable: true }),
    eventType: this.fb.control(FILTER_EVENT_TYPE.COUNT, { nonNullable: true }),
    operation: this.fb.control(
      {
        type: ELASTICSEARCH_OPERATION.KNOWN,
        value: {
          value: '',
        },
      },
      { nonNullable: true },
    ),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  protected getPositionByIndex(index: number): number {
    return index + 1;
  }

  protected onRemoveButtonClick(index: number): void {
    this.removeFilter.emit(index);
  }

  private isEventIdNull(): boolean {
    return this.control.controls.eventId.value === null;
  }
}

import { Validators } from '@angular/forms';

import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

interface SendingWhatsAppTemplateFormControls {
  attachments: File[];
  variables: GenericFormControl<string>[];
}

export class SendingWhatsAppTemplateForm extends GenericFormGroup<SendingWhatsAppTemplateFormControls> {
  constructor(hasAttachments: boolean, templateVariablesLength: number) {
    const variablesForms = Array.from({ length: templateVariablesLength }, () => {
      return new GenericFormControl('', [Validators.required]);
    });
    const controls: Controls<SendingWhatsAppTemplateFormControls> = {
      attachments: new GenericFormControl([], hasAttachments ? [Validators.required] : null),
      variables: new GenericFormArray(variablesForms),
    };

    super(controls);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { ArticlesSearchComponent } from './articles-search.component';

@NgModule({
  declarations: [ArticlesSearchComponent],
  exports: [ArticlesSearchComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, SharedModule, ReactiveFormsModule],
})
export class ArticlesSearchModule {}

export const PSEUDO_DIRECTORY_TYPES = {
  ALL_DIRECTORY: '$allDirectory', // псевдопапка "Все автосообщения"
  WITHOUT_DIRECTORY: '$withoutDirectory', // псевдопапка "Без папки"
} as const;

export const PSEUDO_DIRECTORY_IDS = {
  [PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]: 'all', //NOTE в автосообщениях используетя ui-select для вывода папок а для него пустая строка это равнозначно тому что ни чего не выбрано. Поэтому пришлось изменить null на 'all'
  [PSEUDO_DIRECTORY_TYPES.WITHOUT_DIRECTORY]: '0',
} as const;

export const SYSTEM_DIRECTORIES = {
  ARCHIVE: '$archive',
};

import {
  BotButtonActionBodyJson,
  BotMeetingActionBodyJson,
  BotPropertyFieldActionBodyJson,
} from '@http/chat-bot/actions';
import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { OPTIONS } from '@panel/app/pages/chat-bot/content/branch-editor/actions/property-field/bot-custom-placeholder/bot-custom-placeholder.component';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions';

export class ChatBotActionBodyJsonMapper {
  static parseToExternalFormat<BodyJson extends object = EMPTY_BODY_JSON>(action: ChatBotAction<BodyJson>) {
    switch (action.type) {
      case CHAT_BOT_ACTIONS_TYPES.BUTTON:
        const buttonBodyJson = (action as ChatBotAction<BotButtonActionBodyJson>).bodyJson;

        if (buttonBodyJson.enableUrl) {
          return {
            href: buttonBodyJson.href!.trim(),
            icon: buttonBodyJson.icon,
            target: buttonBodyJson.target,
          };
        }

        return {};
      case CHAT_BOT_ACTIONS_TYPES.MEET:
        const meetBodyJson = (action as ChatBotAction<BotMeetingActionBodyJson>).bodyJson;

        if (meetBodyJson.href) {
          return {
            href: meetBodyJson.href,
            name: meetBodyJson.name,
            integration: meetBodyJson.integration,
          };
        }

        return {};
      case CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD:
        const placeholderBodyJson = (action as ChatBotAction<BotPropertyFieldActionBodyJson>).bodyJson;

        if (placeholderBodyJson.placeholder.type === OPTIONS.DEFAULT) {
          return {};
        }

        return action.bodyJson;
      default:
        return action.bodyJson;
    }
  }
}

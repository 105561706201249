(function () {
  'use strict';

  require('./plan-capability-paywall-modal.controller');

  angular.module('myApp.planCapability').component('cqPlanCapabilityPaywallModal', cqPlanCapabilityPaywallModal());

  function cqPlanCapabilityPaywallModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // billingInfo - Информация о биллинге
        // currentApp - Текущее приложение
        // denialReasons - Причина | Причины отказа в доступе
        // djangoUser - Текущий пользователь
      },
      controller: 'CqPlanCapabilityPaywallModalController',
      controllerAs: 'vm',
      template: require('./plan-capability-paywall-modal.html'),
    };
  }
})();

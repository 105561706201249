<ng-container *transloco="let t; read: 'activeChatWidgetOrderComponent'">
  <div
    class="margin-between-rows-10"
    [nxtSortablejs]="orderedActiveChatWidgetList"
    [config]="sortablejsOptions"
  >
    <div
      *ngFor="let chatWidget of orderedActiveChatWidgetList"
      class="d-flex align-items-center margin-between-cols-10"
    >
      <i class="cqi-sm cqi-grip-vertical handle-icon"></i>
      <span *ngIf="chatWidget.subHeader">{{ chatWidget.subHeader }}</span>
      <div
        *ngIf="isCurrent(chatWidget)"
        class="badge badge-light-secondary"
      >
        {{ t('current') }}
      </div>
      <a
        *ngIf="!isCurrent(chatWidget)"
        class="btn btn-text-primary"
        [uiParams]="{ widgetBotId: chatWidget.action.chatBotId }"
        uiSref="app.content.messagesAjs.widgetBot.edit"
        target="_blank"
      >
        <i class="btn-icon cqi-sm cqi-pencil"></i>
      </a>
    </div>
  </div>
</ng-container>

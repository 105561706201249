import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, switchMap, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { DATA_SOURCE, FILTER_DEFAULT_VALUES } from '@http/ai-data/ai-data.constants';
import { AiDataModel } from '@http/ai-data/ai-data.model';
import { AiData } from '@http/ai-data/internal-types/ai-data.internal.type';
import { PaginationParamsRequest } from '@http/types';
import {
  AI_DATA_ACTIONS,
  AiDataActionModalService,
} from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal.service';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';
import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-ai-data-source-table',
  templateUrl: './ai-data-source-table.component.html',
  styleUrls: ['./ai-data-source-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AiDataSourceTableComponent {
  downloadNextSubject: Subject<void> = new Subject();

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  isShowCategoryFilter$: Observable<boolean> = this.state.selectedSource$.pipe(
    map((selectedSource) => selectedSource === DATA_SOURCE.KNOWLEDGE_BASE),
    takeUntil(this.destroy$),
  );

  private paginatorParams?: PaginationParamsRequest;

  private shouldResetList: boolean = false;

  protected readonly AI_DATA_ACTIONS = AI_DATA_ACTIONS;

  constructor(
    private readonly aiDataModel: AiDataModel,
    private readonly aiDataActionModalService: AiDataActionModalService,
    private readonly destroy$: DestroyService,
    public readonly state: AiDataSourcesListStateService,
  ) {
    combineLatest([
      this.downloadNextSubject.pipe(startWith(null)),
      this.state.selectedStatus$.pipe(
        distinctUntilChanged(),
        tap(() => {
          this.paginatorParams = undefined;
          this.shouldResetList = true;
        }),
      ),
      this.state.selectedSource$.pipe(
        distinctUntilChanged(),
        tap(() => {
          this.paginatorParams = undefined;
          this.shouldResetList = true;
        }),
      ),
      this.state.selectedCategory$.pipe(
        distinctUntilChanged(),
        tap(() => {
          this.paginatorParams = undefined;
          this.shouldResetList = true;
        }),
      ),
      this.state.searchPhrase$.pipe(
        distinctUntilChanged(),
        tap(() => (this.paginatorParams = undefined)),
        debounceTime(500),
      ),
    ])
      .pipe(
        tap(() => this.isLoading.next(true)),
        switchMap(([_, status, source, category, searchPhrase]) =>
          this.aiDataModel.getList(source, status, this.paginatorParams),
        ),
        tap(({ dataSourceList, paginatorParams }) => {
          if (this.shouldResetList) {
            this.state.dataSourceList$.next(dataSourceList);
          } else {
            this.state.dataSourceList$.next([...this.state.dataSourceList$.getValue(), ...dataSourceList]);
          }

          //@ts-ignore
          this.paginatorParams = paginatorParams;
          this.shouldResetList = false;
        }),
        tap(() => this.isLoading.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /** Нужно ли показывать кнопку дозагрузки данных */
  get canLoadMore(): boolean {
    return !!this.paginatorParams && this.paginatorParams.paginatePosition !== null;
  }

  openBulkActionModal(action: AI_DATA_ACTIONS) {
    if (action === AI_DATA_ACTIONS.MULTIPLE_ENABLE) {
      const selectedData = this.state.selectedDataSources$.getValue();
      if (selectedData.length === 1) {
        return;
      }

      this.aiDataActionModalService.openEnableMultipleItemsModal();
    }

    if (action === AI_DATA_ACTIONS.MULTIPLE_DISABLE) {
      this.aiDataActionModalService.openDisableMultipleItemsModal();
    }
  }

  filtersActive(): boolean {
    if (
      this.state.selectedStatus$.getValue() !== FILTER_DEFAULT_VALUES.STATUS ||
      this.state.selectedSource$.getValue() !== FILTER_DEFAULT_VALUES.SOURCE ||
      this.state.selectedCategory$.getValue() !== FILTER_DEFAULT_VALUES.CATEGORY ||
      this.state.searchPhrase$.getValue() !== FILTER_DEFAULT_VALUES.SEARCH_PHRASE
    ) {
      return true;
    }

    return false;
  }

  onSelectAll(value: boolean) {
    this.state.toggleSelectAll(value);
  }

  resetFilters() {
    this.state.selectedStatus$.next(FILTER_DEFAULT_VALUES.STATUS);
    this.state.selectedSource$.next(FILTER_DEFAULT_VALUES.SOURCE);
    this.state.selectedCategory$.next(FILTER_DEFAULT_VALUES.CATEGORY);
    this.state.searchPhrase$.next(FILTER_DEFAULT_VALUES.SEARCH_PHRASE);
  }

  trackByDataSource(index: number, dataSource: AiData) {
    return dataSource.contentId;
  }
}

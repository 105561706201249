(function () {
  'use strict';

  require('./widget-animation.controller');

  angular.module('myApp.chatSettings').component('cqWidgetAnimation', cqWidgetAnimation());

  function cqWidgetAnimation() {
    return {
      bindings: {
        animations: '=', // анимация дефолтной иконки бейджа чата
        isAnimationActive: '=', // флаг для отображения анимации дефолтной иконки бейджа чата в превью
      },
      controller: 'CqWidgetAnimationController',
      controllerAs: 'vm',
      template: require('./widget-animation.html'),
    };
  }
})();

import { firstValueFrom } from 'rxjs';
import { CONVERSATIONS_SETTINGS_TABS } from '../../../app/pages/conversations-settings/pages/conversations-settings/conversations-settings.constants';

(function () {
  'use strict';

  angular
    .module('myApp.savedRepliesPopover')
    .controller('CqSavedRepliesPopoverController', CqSavedRepliesPopoverController);

  function CqSavedRepliesPopoverController(
    $scope,
    browserDetector,
    carrotquestHelper,
    hotkeys,
    validationHelper,
    djangoUserModel,
    savedReplyModel,
  ) {
    let vm = this;

    /**
     * Массив элементов, в которых будет работать хоткей
     *
     * @type {string[]}
     */
    const hotKeysAllowIn = ['INPUT', 'SELECT', 'TEXTAREA'];

    /**
     * Массив с установленными горячими клавишами.
     * Нужно для того, чтобы после уничтожения компонента уничтожались и хоткеи
     *
     * @type {*[]}
     */
    let hotKeysArray = [];

    /**
     * Максимальная длинна поисковай фразы
     *
     * @type {Number}
     */
    const MAX_SEARCH_LENGTH = 255;

    /**
     * Минимальная длинна поисковай фразы
     *
     * @type {Number}
     */
    const MIN_SEARCH_LENGTH = 3;

    /**
     * Коды клавиш, которые отслеживаются для навигации по сохраненным ответам
     *
     * @type {{ARROW_DOWN: string, ENTER: string, ARROW_UP: string, TAB: string}}
     */
    const TRACKED_KEYBOARD_CODES = {
      ARROW_DOWN: 'ArrowDown',
      ARROW_UP: 'ArrowUp',
      ENTER: 'Enter',
      TAB: 'Tab',
    };

    /**
     * Id конейнера с сохраненными ответами
     *
     * @type {string}
     */
    const SAVED_REPLIES_CONTAINER_ID = 'savedReplies';

    /**
     * Id input'а поиска сохраенных ответов
     *
     * @type {string}
     */
    const SAVED_REPLIES_SEARCH_INPUT = 'savedRepliesSearchInput';

    /**
     * Id конейнера с найденными сохраненными ответами
     *
     * @type {string}
     */
    const SEARCHED_SAVED_REPLIES_CONTAINER_ID = 'searchedSavedReplies';

    vm.$onInit = init;
    vm.$onDestroy = onDestroy;

    function init() {
      vm.CONVERSATIONS_SETTINGS_TABS = CONVERSATIONS_SETTINGS_TABS;
      vm.djangoUserModel = djangoUserModel;
      vm.isPreventMouseenterInReply = true; // Флаг прерывания mouseenter на сохраненный ответ, чтобы не было случайных выделений сохраненного ответа при первом открытии поповера и при навгиации из конца списка ответов в начало и на оборот
      vm.isSafariScrollView = false; // Флаг смены сохранненого ответа на Safari, чтобы при навигации с клавиатуры в Safari не триггерились mouse-events и не было случайных выделений сохраненного ответа
      vm.isSearch = false; // Флаг, который показывает находимся ли мы в режиме поиска
      vm.MAX_SEARCH_LENGTH = MAX_SEARCH_LENGTH;
      vm.MIN_SEARCH_LENGTH = MIN_SEARCH_LENGTH;
      vm.mouseenterInReply = mouseenterInReply;
      vm.mouseleaveInSavedRepliesPopover = mouseleaveInSavedRepliesPopover;
      vm.resetSearch = resetSearch;
      vm.SAVED_REPLIES_CONTAINER_ID = SAVED_REPLIES_CONTAINER_ID;
      vm.SAVED_REPLIES_SEARCH_INPUT = SAVED_REPLIES_SEARCH_INPUT;
      vm.savedRepliesSearch = ''; // Фраза поиска по сохранённым ответам
      vm.search = search;
      vm.SEARCHED_SAVED_REPLIES_CONTAINER_ID = SEARCHED_SAVED_REPLIES_CONTAINER_ID;
      vm.searchedSavedReplies = []; // Список найденных сохранённых ответов
      vm.searchForm = null; // Форма поиска
      vm.searchPattern = ''; // Фраза поиска по сохранённым ответам
      vm.selectedRepliesNavigation = selectedRepliesNavigation;
      vm.selectedReplyIndex = 0; // Индекс выбраного оператором ответа
      vm.validationHelper = validationHelper;

      $scope.$watch('vm.savedRepliesSearch', () => {
        vm.searchForm && vm.search(vm.searchForm.$valid, vm.savedRepliesSearch);
      });

      setupHotkeys();
    }

    function onDestroy() {
      for (let i = 0; i < hotKeysArray.length; i++) {
        hotkeys.del(hotKeysArray[i].combo);
      }
    }

    /**
     * Устанавливает горячую клвашу и записывает хоткей в массив
     * Засовывать в массив приходится мз-за того что после перехода на другую страницу нужна убрать хоткеии текущей
     *
     * @param {Object} hotKeyObject хранит комбинацию клавиш combo и callback
     */
    function addHotKey(hotKeyObject) {
      hotKeysArray.push(hotKeyObject);
      hotkeys.add({
        combo: hotKeyObject.combo,
        allowIn: hotKeysAllowIn,
        callback: hotKeyObject.callback,
      });
    }

    /**
     * Обработчик выхода курсора за границы поповера с сохраненными ответами
     */
    function mouseleaveInSavedRepliesPopover() {
      vm.selectedReplyIndex = null;
    }

    /**
     * Обработчик движения курсора в Html-node сохраненного ответа
     *
     * @param event
     */
    function mouseenterInReply(event) {
      const replyNodeIndex =
        event.target.getAttribute('tabindex') || event.target.closest('div[tabindex]').getAttribute('tabindex'); // Tabindex Html-nod'ы сохраненного ответа, на который навели курсор
      const repliesSearchInput = document.getElementById(SAVED_REPLIES_SEARCH_INPUT); // Html-node input'а поиска сохраненных ответов

      if (vm.isPreventMouseenterInReply) {
        vm.isPreventMouseenterInReply = false;
        return;
      }

      if (replyNodeIndex && !vm.isSafariScrollView) {
        // После scrollIntoView в Safari происходит срабатываение mouseenter, исключаем этот момент
        vm.selectedReplyIndex = replyNodeIndex - 1;

        repliesSearchInput.focus();
      }

      vm.isSafariScrollView = false;
    }

    /**
     * Сбросить поиск
     */
    function resetSearch() {
      vm.isSearch = false;
      vm.savedRepliesSearch = '';
      vm.searchPattern = '';
      vm.searchedSavedReplies = [];
      vm.selectedReplyIndex = 0;
    }

    /**
     * Поиск сохранённых ответов
     *
     * @param {Boolean} isValid - Валидность формы
     * @param {String} searchPhrase - Фраза для поиска
     */
    function search(isValid, searchPhrase) {
      // Поле должно быть валидно и текущее введеное значение не должно совпадать с предыдущим запросом
      if (isValid && searchPhrase !== vm.searchPattern) {
        if (searchPhrase) {
          firstValueFrom(savedReplyModel.getList(vm.currentApp.id, searchPhrase)).then(searchSuccess);
        } else {
          resetSearch();
        }
      }

      function searchSuccess(savedReplies) {
        vm.isSearch = true;
        vm.searchPattern = searchPhrase;
        vm.searchedSavedReplies = savedReplies;
        vm.selectedReplyIndex = 0;
      }
    }

    /**
     * Прослушивание события нажатия клавиши в поповре с сохраненными ответами
     * NOTE:
     *  В навигации участвует весь список сохраненных ответов и найденые сохраненные ответы по поиску
     *
     * @param {KeyboardEvent} event - Событие клавиатуры
     */
    function selectedRepliesNavigation(event) {
      const repliesArray = vm.isSearch ? vm.searchedSavedReplies : vm.savedReplies; // Массив сохраненных ответов, которые участвуют в навигации
      const repliesNodes = vm.isSearch
        ? document.querySelectorAll('#' + SEARCHED_SAVED_REPLIES_CONTAINER_ID + ' > div')
        : document.querySelectorAll('#' + SAVED_REPLIES_CONTAINER_ID + ' > div'); // Html-nodes сохраненных ответов, которые участвюут в навигации
      const repliesSearchInput = document.getElementById(SAVED_REPLIES_SEARCH_INPUT); // Html-node input'а поиска сохраненных ответов
      const isEndRepliesList = vm.selectedReplyIndex === repliesArray.length - 1; // Находимся в конце списка с сохраненными ответами, которые участвуют в навигации
      const isStartRepliesList = vm.selectedReplyIndex === 0; // Находимся в начале списка с сохраненными ответами, которые участвуют в навигации

      if (repliesNodes.length === 0) {
        return false;
      }

      // Исключаем срабатывание mouseevent на сохраненном ответе при навигации из конца списка отвтеов в начало и наоборот
      if (isStartRepliesList || isEndRepliesList) {
        vm.isPreventMouseenterInReply = true;
      }

      if (event.code === TRACKED_KEYBOARD_CODES.ENTER) {
        return vm.selectedReplyIndex && vm.onSelectSavedReply({ savedReply: repliesArray[vm.selectedReplyIndex] });
      }

      if ([TRACKED_KEYBOARD_CODES.ARROW_DOWN, TRACKED_KEYBOARD_CODES.TAB].includes(event.code)) {
        vm.selectedReplyIndex = isEndRepliesList || vm.selectedReplyIndex === null ? 0 : vm.selectedReplyIndex + 1;
      }

      if (event.code === TRACKED_KEYBOARD_CODES.ARROW_UP) {
        vm.selectedReplyIndex =
          isStartRepliesList || vm.selectedReplyIndex === null ? repliesArray.length - 1 : vm.selectedReplyIndex - 1;
      }

      // В Safari не работает focus() на HTMLElement, поэтому обрабатываем эту ситуацию отедльно
      if (browserDetector.isSafari()) {
        repliesNodes[vm.selectedReplyIndex].scrollIntoView({ behavior: 'smooth' });
        vm.isSafariScrollView = true; // Необходимо, чтобы остановить срабатывание mouseenter, после ScrollIntoView в Safari
      } else {
        repliesNodes[vm.selectedReplyIndex].focus();
      }

      repliesSearchInput.focus();
    }

    /**
     * Установка горячих клавиш для навигации по ответам и их выбора
     */
    function setupHotkeys() {
      addHotKey({
        combo: 'up',
        callback: function (event) {
          event.preventDefault();
          selectedRepliesNavigation(event);
        },
      });
      addHotKey({
        combo: 'down',
        callback: function (event) {
          event.preventDefault();
          selectedRepliesNavigation(event);
        },
      });
      addHotKey({
        combo: 'enter',
        callback: function (event) {
          event.preventDefault();
          selectedRepliesNavigation(event);
        },
      });
      addHotKey({
        combo: 'tab',
        callback: function (event) {
          event.preventDefault();
          selectedRepliesNavigation(event);
        },
      });
    }
  }
})();

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqSegmentController', CqSegmentController);

  function CqSegmentController(
    $filter,
    $location,
    $q,
    $scope,
    $state,
    $translate,
    toastr,
    PROJECT_NAME,
    SEGMENT_INTEGRATION_STEPS,
    integrationModel,
    carrotquestHelper,
    validationHelper,
    wizardHelper,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.createOrUpdateIntegration = createOrUpdateIntegration;
      vm.currentStep = angular.isDefined(vm.integration.settings.lastCompletedStep)
        ? getStepByIndex(SEGMENT_INTEGRATION_STEPS, vm.integration.settings.lastCompletedStep + 1)
        : getStepByIndex(SEGMENT_INTEGRATION_STEPS, 0); // текущий шаг визарда
      vm.lastCompletedStep = angular.isDefined(vm.integration.settings.lastCompletedStep)
        ? getStepByIndex(SEGMENT_INTEGRATION_STEPS, vm.integration.settings.lastCompletedStep)
        : null; // последний завершённый шаг
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.SEGMENT_INTEGRATION_STEPS = SEGMENT_INTEGRATION_STEPS;
      vm.trackClickGoToSegment = trackClickGoToSegment;
      vm.validationHelper = validationHelper;
      vm.wizard = null; // инстанс визарда

      $scope.$watch('vm.currentStep', function (newValue) {
        //обновляем состояние инструкции в зависимости от текущего шага
        vm.isManualExpanded = angular.isDefined(vm.integration.settings.lastCompletedStep)
          ? newValue.ORDER > vm.integration.settings.lastCompletedStep
          : angular.isUndefined(vm.integration.id);
      });

      wizardHelper.getWizard().then(getWizardSuccess);

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function createOrUpdateIntegration(isValid) {
      if (isValid) {
        vm.integration.settings.lastCompletedStep =
          vm.integration.settings.lastCompletedStep > vm.currentStep.ORDER
            ? vm.integration.settings.lastCompletedStep
            : vm.currentStep.ORDER;

        if (vm.integration.id) {
          integrationModel.save(vm.currentApp.id, vm.integration).then(saveSuccess);
        } else {
          integrationModel.create(vm.currentApp.id, vm.integration).then(createSuccess);
        }
      }

      function createSuccess(integration) {
        let params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        let transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Получение шага из массива/объекта шагов steps по индексу index
     *
     * @param {Object|Array.<Object>} steps Массив/объект шагов, из которых осуществляется выборка
     * @param {Number} index Индекс шага (шаги упорядочены по ORDER, т.е. этот индекс и является ORDER)
     * @return {Object}
     */
    function getStepByIndex(steps, index) {
      let stepsArray = $filter('toArray')(steps);
      let step = $filter('filter')(stepsArray, { ORDER: index }, true)[0];
      if (step) {
        return step;
      } else {
        return $filter('filter')(stepsArray, { ORDER: 0 }, true)[0];
      }
    }

    /**
     * Трек нажатия на кнопку 'Перейти в Segment'
     */
    function trackClickGoToSegment() {
      carrotquestHelper.track('Интеграции - ' + vm.integration.type + ' - клик на "Перейти в Segment"');
    }
  }
})();

/**
 * Директива-обёртка для Split.js (https://github.com/nathancahill/Split.js)
 */
(function () {
  'use strict';

  require('./split.controller');

  angular.module('myApp.directives.split').directive('cqSplit', cqSplit);

  function cqSplit() {
    return {
      restrict: 'EA',
      require: 'cqSplit',
      scope: {
        // большинство этих параметров смотри в документации к split.js, кастомные парметры подписаны
        cursor: '@',
        direction: '@',
        elementStyle: '=',
        gutter: '=',
        gutterIndent: '@', // отступы внутри разделителя
        gutterSize: '@',
        gutterStyle: '=',
        minSize: '@', // Number, массив сюда не передаётся, он формируется из minSize, которые можно задать split-view
        onDrag: '=',
        onDragEnd: '=',
        onDragStart: '=',
        snapOffset: '@',
        splitInstance: '=?', // экземпляр split
      },
      controller: 'CqSplitController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      scope.$watchCollection('vm.views', controller.onViewsChange);
      scope.$on('destroy', controller.destroy);
    }
  }
})();

import { Validators } from '@angular/forms';

import {
  COMMENT_MAX_LENGTH,
  SUBJECT_MAX_LENGTH,
} from '@http/integration/integrations/email-notification/constants/email-notification-integration.constants';
import { EmailNotificationIntegration } from '@http/integration/integrations/email-notification/email-notification-integration';
import { EmailNotificationIntegrationSettingsExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { EmailNotificationIntegrationFormData } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { INTEGRATION_NAME_MAX_LENGTH } from '@http/integration/constants/integration.constants';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { validatorsForEach } from '@panel/app/shared/functions/validators-for-each.function';
import { emailValidator } from '@panel/app/shared/validators/email/email-validator';

/** Класс для работы с формой EMAIL_NOTIFICATION-интеграции */
export class EmailNotificationIntegrationForm extends GenericFormGroup<EmailNotificationIntegrationFormData> {
  constructor(integration: EmailNotificationIntegration) {
    let controls: Controls<EmailNotificationIntegrationFormData> = {
      name: new GenericFormControl(integration.name, [
        Validators.required,
        Validators.maxLength(INTEGRATION_NAME_MAX_LENGTH),
      ]),
      settings: new EmailNotificationSettingsForm(integration.settings),
    };

    super(controls, null, null);
  }
}

/** Класс для работы с формой поля settings у EMAIL_NOTIFICATION-интеграции */
class EmailNotificationSettingsForm extends GenericFormGroup<EmailNotificationIntegrationSettingsExternal> {
  constructor(settings: EmailNotificationIntegrationSettingsExternal) {
    const controls: Controls<EmailNotificationIntegrationSettingsExternal> = {
      emailRecipients: new GenericFormControl(settings.emailRecipients, [
        Validators.required,
        validatorsForEach(emailValidator),
      ]),
      eventTypeIds: new GenericFormControl(settings.eventTypeIds),
      comment: new GenericFormControl(settings.comment, [Validators.maxLength(COMMENT_MAX_LENGTH)]),
      props: new GenericFormControl(settings.props),
      subject: new GenericFormControl(settings.subject, [
        Validators.required,
        Validators.maxLength(SUBJECT_MAX_LENGTH),
      ]),
    };

    super(controls, null, null);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { User } from '@http/user/types/user.type';
import { EditUserPropertyModalComponent } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.component';
import { EDIT_USER_PROPERTY_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.token';
import { ModalHelperService } from '@panel/app/services';

/**
 * Компонент блока с основными данными пользователя: аватаркой, имени, емейла и телефона
 */
@Component({
  selector: 'cq-user-main-props[user]',
  templateUrl: './user-main-props.component.html',
  styleUrls: ['./user-main-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMainPropsComponent implements OnInit {
  /** Пользователь */
  @Input()
  user!: User;

  /** Колбэк на открытие модалки редактирования свойств пользователя */
  @Output()
  openEditPropModal: EventEmitter<void> = new EventEmitter<void>();

  /** Колбэк на открытие карточки пользователя */
  @Output()
  openUserCardFn: EventEmitter<void> = new EventEmitter<void>();

  /** Колбэк на смену емейла пользователя */
  @Output()
  updateActiveConversation: EventEmitter<string> = new EventEmitter<string>();

  /** Колбэк на смену значения свойства пользователя */
  @Output()
  updateProps: EventEmitter<void> = new EventEmitter<void>();

  /** Передана ли в компонент функция для открытия карточки пользователя */
  canOpenUserCard!: boolean;

  /** Email пользователя */
  get userEmail(): string {
    if (!this.user.props.$email) {
      return this.translocoService.translate('userMainPropsComponent.emailTextarea.placeholder');
    }

    return this.user.props.$email as string;
  }

  /** Телефон пользователя */
  get userPhone(): string {
    if (!this.user.props.$phone) {
      return this.translocoService.translate('userMainPropsComponent.phoneTextarea.placeholder');
    }

    return this.user.props.$phone as string;
  }

  constructor(
    private readonly modalHelperService: ModalHelperService,
    private readonly modalService: NgbModal,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    // !!! В 7 версии RxJS поля observers больше не будет. TODO Нужно будет заменить на observed
    this.canOpenUserCard = this.openUserCardFn.observers.length > 0;
  }

  /**
   * Открытие модалки редактирования свойства пользователя
   *
   * @param propertyName Название свойства
   * @param propertyValue Значение свойства
   */
  openEditUserPropertyModal(propertyName: '$email' | '$name' | '$phone', propertyValue: string | undefined): void {
    this.openEditPropModal.emit();

    this.modalHelperService
      .provide(EDIT_USER_PROPERTY_MODAL_DATA_TOKEN, {
        propertyName,
        propertyValue,
        userId: this.user.id,
      })
      .open(EditUserPropertyModalComponent, {
        centered: true,
        scrollable: true,
      })
      .result.then(
        (propertyValue: string) => {
          this.updateProps.emit();

          if (propertyName === '$email') {
            this.updateActiveConversation.emit(propertyValue);
          }
        },
        () => {},
      );
  }

  /**
   * Открытие карточки пользователя
   */
  openUserCard(): void {
    this.openUserCardFn.emit();
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';

import { App } from '@http/app/app.model';
import { MessageTelegramButton } from '@http/message-part/message-part.types';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';

@Component({
  selector: 'cq-button-blocks',
  templateUrl: './button-blocks.component.html',
  styleUrls: ['./button-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBlocksComponent
  extends AbsCVAFormArrayBasedComponent<MessageTelegramButton, FormControl>
  implements OnInit
{
  @Input()
  currentApp!: App;

  readonly MAX_BUTTON_BLOCKS = 10;

  isAllowToAddBlock = false;

  readonly control: FormArray<FormControl<MessageTelegramButton>> = this.fb.array<FormControl<MessageTelegramButton>>(
    [],
    [Validators.maxLength(this.MAX_BUTTON_BLOCKS)],
  );

  constructor(private readonly fb: FormBuilder, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.updateAddBlocksAbility();
  }

  protected addButtonBlock(button?: MessageTelegramButton): void {
    if (!this.isAllowToAddBlock) {
      return;
    }
    let control: FormControl<MessageTelegramButton> = this.getNewControl(button);

    this.control.push(control);
    this.updateAddBlocksAbility();
  }

  protected moveButtonBlock(index: number, newIndex: number): void {
    if (newIndex < 0 || newIndex >= this.control.controls.length) {
      throw new Error('Wrong new index for moving');
    }
    const control = this.control.at(index);
    this.control.removeAt(index);
    this.control.insert(newIndex, control);
    this.cdr.markForCheck();
  }

  protected copyButtonBlock(index: number): void {
    if (!this.isAllowToAddBlock) {
      return;
    }
    const rawValue = { ...this.defaultButton, ...this.control.at(index).getRawValue() };
    const control: FormControl<MessageTelegramButton> = this.getNewControl(rawValue);

    this.control.push(control);
    this.updateAddBlocksAbility();
  }

  protected deleteButtonBlock(index: number): void {
    this.control.removeAt(index);
    this.updateAddBlocksAbility();
  }

  get defaultButton(): MessageTelegramButton {
    return {
      actionType: 'none',
      text: '',
      url: null,
      eventToWrite: null,
    };
  }

  getNewControl(button?: MessageTelegramButton): FormControl<MessageTelegramButton> {
    if (!button) {
      button = this.defaultButton;
    }

    return this.fb.control<MessageTelegramButton>(button, { nonNullable: true });
  }

  private updateAddBlocksAbility() {
    this.isAllowToAddBlock = this.control.length < this.MAX_BUTTON_BLOCKS;
  }

  get lastIndex(): number {
    return this.control.controls.length ? this.control.controls.length - 1 : 0;
  }
}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.unsubscribeWebPush')
    .controller('CqUnsubscribeWebPushModalController', CqUnsubscribeWebPushModalController);

  function CqUnsubscribeWebPushModalController($uibModal, $translate, carrotquestHelper, pushSubscriptionModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.openModalConfirmUnsubscribeWebPush = openModalConfirmUnsubscribeWebPush;
    }

    /**
     * Открытие модального окна подтверждения
     *
     * @param {object} subscription Объект подписки
     */
    function openModalConfirmUnsubscribeWebPush(subscription) {
      const modalInstance = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.unsubscribeWebPush.unsubscribeModal.heading'),
              body: $translate.instant('modals.unsubscribeWebPush.unsubscribeModal.body'),
              confirmButtonText: $translate.instant('modals.unsubscribeWebPush.unsubscribeModal.confirmButtonText'),
              needConfirmation: false,
            };
          },
        },
      });

      modalInstance.result
        .then(() =>
          firstValueFrom(
            pushSubscriptionModel.unsubscribeByDeviceGuid(subscription.deviceGuid, vm.resolve.modalWindowParams.userId),
          ),
        )
        .then(() => {
          carrotquestHelper.track('Карточка пользователя - отписал лида от Web Push', {
            $device_guid: subscription.deviceGuid,
          });
          vm.resolve.modalWindowParams.subscriptions.splice(
            vm.resolve.modalWindowParams.subscriptions.indexOf(subscription),
            1,
          );

          if (vm.resolve.modalWindowParams.subscriptions.length === 0) {
            vm.modalInstance.dismiss();
          }
        });
    }
  }
})();

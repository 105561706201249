import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { InputDateComponent } from './input-date.component';

@NgModule({
  declarations: [InputDateComponent],
  exports: [InputDateComponent],
  imports: [CommonModule, TranslocoModule, NgSelectModule, ReactiveFormsModule],
})
export class InputDateModule {}

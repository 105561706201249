import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';

import { TgTriggerType } from '../trigger-types/tg-trigger.types';

@Component({
  selector: 'cq-tg-trigger[tgTriggerTypes]',
  templateUrl: './tg-trigger.component.html',
  styleUrls: ['./tg-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TgTriggerComponent {
  @Input()
  tgTriggerTypes!: TgTriggerType[];

  @Output()
  tgTriggerTypesChange: Subject<TgTriggerType[]> = new Subject();

  @Output()
  ajsValidationFn: Subject<ValidationCallback> = new Subject();
}

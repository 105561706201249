import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { UserConsentComponent } from '@panel/app/partials/user-card/user-consent/user-consent.component';

@NgModule({
  declarations: [UserConsentComponent],
  exports: [UserConsentComponent],
  imports: [TranslocoModule, NgbCollapseModule, NgbTooltipModule, CommonModule],
})
export class UserConsentModule {}

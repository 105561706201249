/**
 * Фильтр для добавления в начало строки (подразумевается, что в строке содержится HTML) <!DOCTYPE html> если в этой строке DOCTYPE отсутствует в любом виде
 */
(function () {
  'use strict';

  angular.module('myApp.filters.addDoctype').filter('addDoctype', addDoctype);

  function addDoctype() {
    var findDoctypeRegexp = /<!doctype html/im;

    return addDoctypeFilter;

    /**
     * Функция, добавляющая <!DOCTYPE html> в начало строки, если DOCTYPE не найден в ней ни в каком виде
     * Всевозможные виды DOCTYPE прописаны тут http://www.w3schools.com/TAgs/tag_doctype.asp
     * Как видно, Regexp, приведённый ниже, покрывает их все
     *
     * @param {String} value Строка для фильтрации
     * @returns {String}
     */
    function addDoctypeFilter(value) {
      if (value.constructor == String && value.match(findDoctypeRegexp) === null) {
        return '<!DOCTYPE html> ' + value;
      } else {
        return value;
      }
    }
  }
})();

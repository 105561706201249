/**
 * Контроллер для модалки удаления себя как последнего суперадминистратора
 */
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.assignNewDefaultMessageSender')
    .controller('AssignNewDefaultMessageSenderModalController', AssignNewDefaultMessageSenderModalController);

  function AssignNewDefaultMessageSenderModalController(
    $filter,
    $uibModalInstance,
    messageSenderModel,
    validationHelper,
    body,
    currentApp,
    messageSender,
    messageSenders,
  ) {
    var vm = this;

    vm.availableMessageSenders = getAvailableMessageSenders();
    vm.body = body; // FIXME: пока что текст перед селектом заполняется вот таким костылём, это дерьмо, надо придумать что-нибудь получше (в идеале - состояние с темплейтом)
    vm.currentApp = currentApp;
    vm.messageSender = messageSender;
    vm.messageSenders = messageSenders;
    vm.newDefaultMessageSender = null; // новый отправитель по умолчанию
    vm.setDefaultMessageSender = setDefaultMessageSender;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      vm.newDefaultMessageSender = vm.availableMessageSenders[0];
    }

    /**
     * Получение списка доступных отправителей для назначения их по умолчанию
     *
     * @return {Array.<Object>}
     */
    function getAvailableMessageSenders() {
      // по сути достаточно убрать себя из этого списка
      return $filter('filter')(messageSenders, { id: '!' + messageSender.id }, true);
    }

    /**
     * Назначение нового суперадмина
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function setDefaultMessageSender(isValid) {
      if (isValid) {
        firstValueFrom(messageSenderModel.setAsDefault(vm.newDefaultMessageSender.id)).then(setAsDefaultSuccess);
      }

      function setAsDefaultSuccess(messageSender) {
        var oldMessageSender = $filter('filter')(messageSenders, { id: messageSender.id }, true)[0];
        angular.extend(oldMessageSender, messageSender);
        $uibModalInstance.close();
      }
    }
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

export function wordCountValidator(maxWords: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !Array.isArray(control.value)) {
      return null;
    }

    const totalWords = control.value.reduce((acc, item) => acc + (item.label ? item.label.split(' ').length : 0), 0);

    return totalWords > maxWords ? { wordCountExceeded: true } : null;
  };
}

/** Максимальное общее количество слов в стоп-фразах */
export const STOP_PHRASES_MAX_COUNT = 100;

@Component({
  selector: 'cq-stop-phrases-control',
  templateUrl: './stop-phrases-control.component.html',
  styleUrls: ['./stop-phrases-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopPhrasesControlComponent extends AbsCVAFormControlBasedComponent<string[]> {
  readonly control: FormControl<string[]> = new FormControl<string[]>([], {
    nonNullable: true,
    validators: [wordCountValidator(STOP_PHRASES_MAX_COUNT)],
  });

  STOP_PHRASES_MAX_COUNT = STOP_PHRASES_MAX_COUNT;

  constructor() {
    super();
  }
}

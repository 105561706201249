<ng-container *transloco="let t">
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="actionForm.controls.body"
  >
    <ng-select
      bindLabel="name"
      bindValue="name"
      [clearable]="false"
      [formControl]="actionForm.controls.body"
      [items]="(state.tags$ | async) ?? []"
      placeholder="{{ t('chatBot.botActionUserTag.userTagSelect.placeholder') }}"
      [required]="true"
      [cqShowError]="actionForm.controls.body"
      [classList]="['ng-touched']"
    >
      <ng-container *ngIf="accessToUsersTags.hasAccess">
        <ng-container *ng-footer-tmp>
          <button
            (click)="openAddTagModal()"
            class="btn btn-secondary btn-block"
          >
            {{ t('chatBot.botActionUserTag.userTagSelect.addTagButton') }}
          </button>
        </ng-container>
      </ng-container>
    </ng-select>

    <cq-validation-messages [control]="actionForm.controls.body">
      <cq-validation-message errorName="required">{{
        t('chatBot.botActionUserTag.userTagSelect.errors.required')
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { ReadyMessageTemplateComponent } from './ready-message-template.component';

@NgModule({
  declarations: [ReadyMessageTemplateComponent],
  imports: [CommonModule, UIRouterModule],
})
export class ReadyMessageTemplateModule {}

(function () {
  'use strict';

  require('./preview-import-leads.controller');

  angular.module('myApp.users').component('cqPreviewImportLeads', cqPreviewImportLeads());

  function cqPreviewImportLeads() {
    return {
      bindings: {
        parsedHeaders: '<', // Распаршенные заголовки
        parsedResults: '<', // Результат парсинга
      },
      controller: 'CqPreviewImportLeadsController',
      controllerAs: 'vm',
      template: require('./preview-import-leads.html'),
    };
  }
})();

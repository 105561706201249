import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  NgZone,
  Output,
  QueryList,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, delay, distinctUntilChanged, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';
import { StepComponent } from '@panel/app/shared/visual-components/stepper/step/step.component';

/**
 * Получение шага
 */
function getStep(steps: QueryList<StepComponent>, index: number): StepComponent {
  const stepRef = steps.get(index);
  if (!stepRef) {
    throw new Error(`Could not find a step with index ${index}`);
  }
  return stepRef;
}

type SelectQueueItem = {
  index: number;
  force?: boolean;
};

export const STOP_SWITCHING_STEP_ERROR = 'An error happened during the process of changing step';

export function stopSwitchingStep(): Observable<never> {
  return throwError(STOP_SWITCHING_STEP_ERROR);
}

@Component({
  selector: 'cq-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  encapsulation: ViewEncapsulation.None,
})
export class StepperComponent implements AfterContentInit {
  @Input()
  set selectedIndex(index: number) {
    this.addQueueNext(index, true);
  }
  addQueueNext(value: number, force: boolean = false) {
    this.selectQueue$.next({ index: value, force });
  }

  /**
   * Любое изменение шага попадает в очередь
   */
  private selectQueue$: BehaviorSubject<SelectQueueItem> = new BehaviorSubject({ index: NaN });

  @Output('selectedIndexChange') // eslint-disable-line
  selectedTabIndex$ = new BehaviorSubject<number>(0);

  @ContentChildren(StepComponent, { descendants: true })
  steps: QueryList<StepComponent> = new QueryList<StepComponent>();

  /**
   * Observable для отображения/скрытия глобального загрузчика (на initial load)
   */
  private globalLoaderSubj = new BehaviorSubject<boolean>(false);

  get globalLoader$() {
    return this.globalLoaderSubj.asObservable();
  }

  /**
   * Observable для отображения/скрытия загрузчика при смене страниц контента
   */
  private contentLoaderSubj = new BehaviorSubject<boolean>(false);

  get contentLoader$() {
    return this.contentLoaderSubj.asObservable();
  }

  /**
   * Степпер valid если все шаги valid
   */
  get isValid() {
    return this.steps.map((val) => val.valid).every((v) => v);
  }

  constructor(private readonly destroy$: DestroyService, private readonly zone: NgZone) {}

  /**
   * Подписываемся на изменения в очереди и реагируем сменой шага
   */
  ngAfterContentInit() {
    this.selectQueue$
      .pipe(
        filter<SelectQueueItem>((next) => !Number.isNaN(next.index)),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(({ index: next, force }) => {
        if (force) {
          this.forceSelectStep(next);
        } else {
          this.selectStep(next);
        }
      });
  }

  /**
   * Выборанный шаг
   */
  get selectedStep(): StepComponent {
    return getStep(this.steps, this.selectedTabIndex$.getValue());
  }

  /**
   * Actions выборанного шага
   */
  get selectedStepActions(): TemplateRef<any> {
    return this.selectedStep.actions;
  }

  /**
   * Content выборанного шага
   */
  get selectedStepContent(): TemplateRef<any> {
    return this.selectedStep.content;
  }

  /**
   * Index выборанного шага
   */
  get selectedStepIndex(): number {
    return this.selectedTabIndex$.getValue();
  }

  /**
   * Доступен ли шаг для перехода на него (иначе disable)
   */
  isStepAvailable(index: number) {
    // Первые две вкладки всегда открыты
    return index < 2 ? true : getStep(this.steps, index - 1).visited;
  }

  /**
   * Переход на выбранную ветку.
   * @param index - шаг, который нужно выбрать
   */
  private selectStep(index: number) {
    this.contentLoaderSubj.next(true);
    const currentStep = this.selectedStep;
    const nextStep = getStep(this.steps, index);

    currentStep
      .actualizeValidity()
      .pipe(
        filter((valid) => {
          if (this.selectedStepIndex > index) {
            return true;
          }
          return valid ? true : !currentStep.requireValid;
        }),
        mergeMap(() => {
          return currentStep.onExit();
        }),
        mergeMap(() => {
          return nextStep.onEnter();
        }),
        catchError((err, caught) => {
          if (err === STOP_SWITCHING_STEP_ERROR) {
            return caught;
          }
          throw err instanceof Error ? err : new Error(err);
        }),
      )
      .subscribe(
        () => {
          this.selectedTabIndex$.next(index);
          nextStep.visited = true;
        },
        undefined,
        () => {
          this.contentLoaderSubj.next(false);
        },
      );
  }

  /**
   * Принудительный выбор шага. Идет по упрощенной схеме выбора,
   * отмечая все предыдущие как visited и обновляя статусы их валидности
   * @param index
   */
  private forceSelectStep(index: number) {
    this.globalLoaderSubj.next(true);

    const revalidateAll: Observable<boolean>[] = [];

    for (let i = 0; i <= index; i++) {
      const step = getStep(this.steps, i);
      revalidateAll.push(step.actualizeValidity());
    }

    // TODO: разобраться нормально с зоной и убрать delay()
    // Сейчас проблема в том, что лоадер убирается раньше, чем переключается вкладка
    forkJoin(...revalidateAll)
      .pipe(
        /**
         * Если среди шагов есть невалидный, требующий валидацию - возвращаем его индекс в качестве шага для выбора
         */
        map<boolean[], number>((validationResults): number => {
          const firstRequiredValid = validationResults.findIndex((valid, i) => {
            return getStep(this.steps, i).requireValid && !valid;
          });
          return firstRequiredValid > -1 ? firstRequiredValid : index;
        }),
        tap((newIndex) => {
          for (let i = 0; i <= newIndex; i++) {
            getStep(this.steps, i).visited = true;
          }
        }),
        tap((newIndex) => this.selectedTabIndex$.next(newIndex)),
        mergeMap(() => this.zone.onStable.pipe(distinctUntilChanged(), takeUntil(this.destroy$))),
        delay(500),
      )
      .subscribe(() => {
        this.zone.run(() => {
          this.globalLoaderSubj.next(false);
        });
      });
  }

  /**
   * Открыть след шаг
   */
  stepForward() {
    return this.selectedStepIndex === this.steps.length - 1 ? null : this.selectStep(this.selectedStepIndex + 1);
  }

  /**
   * Открыть пред шаг
   */
  stepBackward() {
    return this.selectedStepIndex === 0 ? null : this.selectStep(this.selectedStepIndex - 1);
  }

  /**
   * Выбран первый шаг?
   */
  isSelectedFirst() {
    return this.selectedStepIndex === 0;
  }

  /**
   * Выбран последний шаг?
   */
  isSelectedLast() {
    return this.selectedStepIndex === this.steps.length - 1;
  }
}

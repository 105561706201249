import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TriggerChainOverlayEvent } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/types/trigger-chain-overlay-events.types';

@Injectable()
export class TriggerChainOverlayEventService {
  private readonly eventsSubj: Subject<TriggerChainOverlayEvent> = new Subject<TriggerChainOverlayEvent>();

  events$: Observable<TriggerChainOverlayEvent> = this.eventsSubj.asObservable();

  emitEvent(event: TriggerChainOverlayEvent) {
    this.eventsSubj.next(event);
  }
}

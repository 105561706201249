import { create, enforce, group, omitWhen, test } from 'vest';

import { EMAIL_TYPES, MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { TriggerChainStepAutoMessage } from '@http/trigger-chain/internal-types';

export const automessageStepValidator = create((step: TriggerChainStepAutoMessage) => {
  group(step.uuid, () => {
    test(`name`, () => {
      enforce(step.name).isNotEmpty();
    });

    test('meta.nextStep', () => {
      enforce(step.meta.nextStep).isNotNull();
    });

    test('meta.message.parts', () => {
      enforce(step.meta.message.parts).lengthEquals(2);
    });

    const contentPart = step.meta.message.parts[0];

    omitWhen(contentPart.type !== MESSAGE_PART_TYPES.POPUP_CHAT, () => {
      const chat = contentPart[MESSAGE_PART_TYPES.POPUP_CHAT];

      test('meta.message.parts', () => {
        enforce(chat.body).isNotEmpty();
      });
    });

    omitWhen(contentPart.type !== MESSAGE_PART_TYPES.EMAIL, () => {
      const email = contentPart[MESSAGE_PART_TYPES.EMAIL];

      test('meta.message.parts', () => {
        enforce(email.subject).isNotEmpty();
      });

      omitWhen(email.type !== EMAIL_TYPES.DEFAULT, () => {
        test('meta.message.parts', () => {
          enforce(email[EMAIL_TYPES.DEFAULT].body).isNotEmpty();
        });
      });
      omitWhen(email.type !== EMAIL_TYPES.HTML, () => {
        test('meta.message.parts', () => {
          enforce(email[EMAIL_TYPES.HTML].body).isNotEmpty();
        });
      });
      omitWhen(email.type !== EMAIL_TYPES.BEE, () => {
        test('meta.message.parts', () => {
          enforce(email[EMAIL_TYPES.BEE].body).isNotEmpty();
        });
      });
    });

    omitWhen(contentPart.type !== MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL, () => {
      const popup = contentPart[MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL];

      test('meta.message.parts', () => {
        enforce(popup.bodyJson.blocks[0][0]).isNotEmpty();
      });
    });

    omitWhen(contentPart.type !== MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, () => {
      const popup = contentPart[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG];

      test('meta.message.parts', () => {
        // Хотя бы в одной из частей большого попапа должны быть элементы
        enforce(popup.bodyJson.blocks[0].some((block: any[]) => block.length > 0)).isTruthy();
      });
    });
  });
});

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TuiLetModule } from '@taiga-ui/cdk';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { SharedModule } from '@panel/app/shared/shared.module';

import { EditAccountingEmailsModalComponent } from './edit-accounting-emails-modal.component';

@NgModule({
  declarations: [EditAccountingEmailsModalComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, SharedModule, TuiLetModule],
  providers: [NgbActiveModal, SubscriptionStore],
})
export class EditAccountingEmailsModalModule {}

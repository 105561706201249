import { environment } from '@environment';

export class SystemErrorService {
  offlineConversationsToast: any = null; // Инстанс тоста о разыве интернет-соединения для раздела Диалоги
  offlineRequestToast: any = null; // Инстанс тоста для свалившихся запросов по причине отсутствия интернет-соединения
  RTSConnectionProblemToast: any = null; // Инстанс тоста о проблемах с RTS
  somethingWentWrongToast: any = null; // Инстанс тоста Что-то пошло не так

  private _offlineConversationsToast: any = null; // Инстанс тоста о разыве интернет-соединения для раздела Диалоги
  private _offlineRequestToast: any = null; // Инстанс тоста для свалившихся запросов по причине отсутствия интернет-соединения
  private _RTSConnectionProblemToast: any = null; // Инстанс тоста о проблемах с RTS
  private _somethingWentWrongToast: any = null; // Инстанс тоста Что-то пошло не так

  states = {
    offline: false, // Отсутствие интернет-соединения
    rtsProblem: false, // Наличие проблем с РТС-соединением
  };

  /**
   * Имена тостов
   *
   * @type {{RTS_CONNECTION_PROBLEM: string, SOMETHING_WENT_WRONG: string, OFFLINE_REQUEST: string, OFFLINE_CONVERSATIONS: string}}
   */
  TOASTS_NAMES = {
    OFFLINE_CONVERSATIONS: 'OFFLINE_CONVERSATIONS',
    OFFLINE_REQUEST: 'OFFLINE_REQUEST',
    RTS_CONNECTION_PROBLEM: 'OFFLINE_CONVERSATIONS',
    SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  };

  SOMETHING_WENT_WRONG_TOAST_DELAY = 6 * 1000; // Время, через которое закроется тост Что-то пошло не так

  // @ngInject
  constructor(private readonly $state: any, private readonly $translate: any, private readonly toastr: any) {
    this.offlineConversationsToast = {
      hide: () => this.hideOfflineConversationsToast(),
      isOpen: () => this.isOfflineConversationsToastOpen(),
      show: () => this.showOfflineConversationsToast(),
    };
    this.offlineRequestToast = {
      hide: () => this.hideOfflineRequestToast(),
      isOpen: () => this.isOfflineRequestToastOpen(),
      show: () => this.showOfflineRequestToast(),
    };
    this.RTSConnectionProblemToast = {
      hide: () => this.hideRTSConnectionProblemToast(),
      isOpen: () => this.isRTSConnectionProblemToastOpen(),
      show: () => this.showRTSConnectionProblemToast(),
    };
    this.somethingWentWrongToast = {
      show: () => this.showSomethingWentWrongToast(),
    };
  }

  changeOfflineStatus(isOnline: any): void {
    this.states.offline = !isOnline;
  }

  changeRTSStatus(isRTSProblem: any): void {
    this.states.rtsProblem = isRTSProblem;
  }

  /**
   * Скрытие тоста о разрыве интернет-соединения в разделе Диалоги
   */
  hideOfflineConversationsToast(): void {
    this.hideToast(this._offlineConversationsToast);
  }

  /**
   * Скрытие тоста о свалившемся запросе из-за отсутствия интернет-соединения
   */
  hideOfflineRequestToast(): void {
    this.hideToast(this._offlineRequestToast);
  }

  /**
   * Скрытие тоста о проблемах с RTS
   */
  hideRTSConnectionProblemToast(): void {
    this.hideToast(this._RTSConnectionProblemToast);
  }

  /**
   * Получение статуса открытости тоста с отсутствием интернет-соединения в разделе Диалоги
   *
   * @returns {Boolean}
   */
  isOfflineConversationsToastOpen(): boolean {
    return this.isToastShowed(this._offlineConversationsToast);
  }

  /**
   * Получение статуса открытости тоста о свалившемся запросе из-за отсутствия интернет-соединения
   *
   * @returns {Boolean}
   */
  isOfflineRequestToastOpen(): boolean {
    return this.isToastShowed(this._offlineRequestToast);
  }

  /**
   * Получение статуса открытости тоста с проблемами RTS
   *
   * @returns {Boolean}
   */
  isRTSConnectionProblemToastOpen(): boolean {
    return this.isToastShowed(this._RTSConnectionProblemToast);
  }

  /**
   * Показ тоста о разрыве интернет-соединения в разделе Диалоги
   */
  showOfflineConversationsToast(): void {
    if (!this.isOfflineConversationsToastOpen()) {
      this._offlineConversationsToast = this.toastr.error(
        `
          <div class="flex align-items-center">
            <div class="spinner-border spinner-border-sm margin-right-5" role="status"></div>
            <span>${this.$translate.instant('services.systemError.toastr.offlineConversations')}</span>
          </div>`,
        {
          allowHtml: true,
          extendedTimeOut: 0,
          tapToDismiss: false, // Не даём закрыть этот тост при клике на него
          timeOut: 0,
          toastClass: 'alert animate connection-problem',
          toastName: this.TOASTS_NAMES.OFFLINE_CONVERSATIONS,
        },
      );
    }
  }

  /**
   * Показ тоста о свалившемся запросе из-за отсутствия интернет-соединения
   */
  showOfflineRequestToast(): void {
    if (!this.isOfflineRequestToastOpen() && !this.isOfflineConversationsToastOpen()) {
      this._offlineRequestToast = this.toastr.error(
        this.$translate.instant('services.systemError.toastr.offlineRequest'),
        {
          allowHtml: true,
          toastName: this.TOASTS_NAMES.OFFLINE_REQUEST,
        },
      );
    }
  }

  /**
   * Показ тоста о проблемах с RTS
   */
  showRTSConnectionProblemToast(): void {
    if (!this.isRTSConnectionProblemToastOpen() && !this.isOfflineConversationsToastOpen()) {
      this._RTSConnectionProblemToast = this.toastr.error(
        `
          <div class="flex align-items-center">
            <div class="spinner-border spinner-border-sm margin-right-5" role="status"></div>
            <span>${this.$translate.instant('services.systemError.toastr.RTSConnectionProblem', {
              projectName: environment.projectName,
            })}</span>
          </div>
        `,
        {
          allowHtml: true,
          extendedTimeOut: 0,
          tapToDismiss: false,
          timeOut: 0,
          toastClass: 'alert animate connection-problem',
          toastName: this.TOASTS_NAMES.RTS_CONNECTION_PROBLEM,
        },
      );
    }
  }

  /**
   * Показ тоста Что-то пошло не так
   */
  showSomethingWentWrongToast(): void {
    // Тост об ошибке должен показываться только внутри админки и когда нет тоста об отсутствии интернет-соединения
    if (this.$state.includes('app') || this.$state.includes('account')) {
      if (!this.isOfflineConversationsToastOpen()) {
        if (this.isToastShowed(this._somethingWentWrongToast)) {
          this.toastr.refreshTimer(this._somethingWentWrongToast, this.SOMETHING_WENT_WRONG_TOAST_DELAY);
        } else {
          this._somethingWentWrongToast = this.toastr.error(
            this.$translate.instant('services.systemError.toastr.somethingWentWrong'),
            '',
            {
              timeOut: this.SOMETHING_WENT_WRONG_TOAST_DELAY,
              toastName: this.TOASTS_NAMES.SOMETHING_WENT_WRONG,
            },
          );
        }
      }
    }
  }

  /**
   * Скрывает тост
   *
   * @param {Object} toastInstance — Инстанс тоста, который нужно закрыть
   */
  hideToast(toastInstance: any): void {
    if (this.isToastShowed(toastInstance)) {
      this.toastr.closeByName(toastInstance.scope.toastName); // Закрываем тост программно…
      toastInstance = null; // …и затираем инстанс
    }
  }

  /**
   * Показывается ли сейчас тост
   *
   * @param {Object} toastInstance — Инстанс тоста, который нужно проверить открыт он или нет
   * @returns {Boolean}
   */
  isToastShowed(toastInstance: any): any {
    return toastInstance && toastInstance.isOpened;
  }
}

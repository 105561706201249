import { Injectable } from '@angular/core';
import { some } from 'lodash-es';

import { App } from '@http/app/app.model';
import { PlanCapabilityModel } from '@http/plan-capability/plan-capability.model';
import { ComparisonOperator } from '@http/plan-capability/plan-capability.types';
import { PlanAddonService } from '@panel/app/services/billing/plan-addon/plan-addon.service';
import { PlanDenialReasonService } from '@panel/app/services/billing/plan-denial-reason/plan-denial-reason.service';
import { PLAN_FEATURE, PLAN_FEATURES } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureService } from '@panel/app/services/billing/plan-feature/plan-feature.service';
import { ProductFeatureAccess, ProductFeatures } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanSpecialFeatureAccessService } from '@panel/app/services/billing/plan-special-feature-access/plan-special-feature-access.service';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';

/**
 * Сервис для работы с доступами до фич тарифных
 */
@Injectable({ providedIn: 'root' })
export class PlanFeatureAccessService {
  constructor(
    private readonly planAddonService: PlanAddonService,
    private readonly planCapabilityModel: PlanCapabilityModel,
    private readonly planDenialReasonService: PlanDenialReasonService,
    private readonly planFeatureService: PlanFeatureService,
    private readonly planSpecialFeatureAccessService: PlanSpecialFeatureAccessService,
    private readonly planVersionService: PlanVersionService,
  ) {}

  /**
   * Получение доступа до фичи
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @param comparisonOperator - Оператор сравнения для фич с лимитом
   */
  getAccess(
    productFeature: PLAN_FEATURE,
    app: App,
    amount?: number,
    comparisonOperator: ComparisonOperator = '<',
  ): ProductFeatureAccess {
    const hasAccess = this.hasAccess(productFeature, app, amount, comparisonOperator);

    if (hasAccess) {
      return {
        denialReason: null,
        hasAccess,
      };
    }

    return {
      denialReason: this.planDenialReasonService.getReason(productFeature, app, amount),
      hasAccess,
    };
  }

  /**
   * Проверка наличия доступа к фиче
   *
   * @param productFeature - фича
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @param comparisonOperator - Оператор сравнения для фич с лимитом
   */
  private hasAccess(
    productFeature: PLAN_FEATURE,
    app: App,
    amount?: number,
    comparisonOperator: ComparisonOperator = '<',
  ): boolean {
    if (this.planFeatureService.isSpecialFeature(productFeature)) {
      return this.planSpecialFeatureAccessService.hasAccess(productFeature, app, amount, comparisonOperator);
    }

    switch (true) {
      case this.planVersionService.isFreemium():
        return this.hasAccessForFreemium(productFeature);
      case this.planVersionService.isLTD():
        return this.hasAccessForLTD(productFeature);
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isV1(app):
        return this.hasAccessForV1(productFeature);
      case this.planVersionService.isV2(app):
        return this.hasAccessForV2(productFeature);
      default:
        return this.hasAccessForV3(productFeature);
    }
  }

  /**
   * Есть ли доступ к фиче через подключение аддона
   *
   * @param productFeature - Фича
   * @param paidPlanVersion - Версия тарифа
   * @private
   */
  private hasAccessByAddon(productFeature: ProductFeatures, paidPlanVersion: PAID_PLAN_VERSION): boolean | null {
    const addons = PLAN_FEATURES[productFeature][paidPlanVersion].addOns;
    if (!addons.length) {
      return null;
    }

    return some(addons, (o) => this.planAddonService.isAddonConnected(o));
  }

  /**
   * Есть ли доступ к фиче через подключение аддона
   *
   * @param productFeature - Фича
   * @param paidPlanVersion - Версия тарифа
   * @private
   */
  private hasAccessByCapability(productFeature: ProductFeatures, paidPlanVersion: PAID_PLAN_VERSION): boolean | null {
    const capabilities = PLAN_FEATURES[productFeature][paidPlanVersion].capabilities;
    if (!capabilities.length) {
      return null;
    }

    return some(capabilities, (o) => this.planCapabilityModel.hasAccess(o));
  }

  /**
   * Есть ли доступ до фичи у приложения с Freemium тарифом
   *
   * @param productFeature - Фича
   * @private
   */
  private hasAccessForFreemium(productFeature: ProductFeatures): boolean {
    const hasAddOnAccess = this.hasAccessByAddon(productFeature, PAID_PLAN_VERSION.FREEMIUM);
    const hasCapabilityAccess = this.hasAccessByCapability(productFeature, PAID_PLAN_VERSION.FREEMIUM);

    if (hasAddOnAccess === null && hasCapabilityAccess === null) {
      throw new Error('Provide either addOns or capabilities for check');
    }

    if (hasAddOnAccess === null) {
      return hasCapabilityAccess!;
    }

    if (hasCapabilityAccess === null) {
      return hasAddOnAccess;
    }

    return hasAddOnAccess || hasCapabilityAccess;
  }

  /**
   * Есть ли доступ до фичи у приложения с LTD тарифом
   *
   * @param productFeature - Фича
   */
  private hasAccessForLTD(productFeature: ProductFeatures) {
    const hasAddOnAccess = this.hasAccessByAddon(productFeature, PAID_PLAN_VERSION.LTD);
    const hasCapabilityAccess = this.hasAccessByCapability(productFeature, PAID_PLAN_VERSION.LTD);

    if (hasAddOnAccess === null && hasCapabilityAccess === null) {
      throw new Error('Provide either addOns or capabilities for check');
    }

    if (hasAddOnAccess === null) {
      return hasCapabilityAccess!;
    }

    if (hasCapabilityAccess === null) {
      return hasAddOnAccess;
    }

    return hasAddOnAccess || hasCapabilityAccess;
  }

  /**
   * Есть ли доступ до фичи у приложения с тарифом первой тарифной сетки
   *
   * @param productFeature - Фича
   */
  private hasAccessForV1(productFeature: ProductFeatures): boolean {
    const hasAddOnAccess = this.hasAccessByAddon(productFeature, PAID_PLAN_VERSION.V1);
    if (hasAddOnAccess === null) {
      return true;
    }

    return hasAddOnAccess;
  }

  /**
   * Есть ли доступ до фичи у приложения с тарифом второй тарифной сетки
   *
   * @param productFeature - Фича
   */
  private hasAccessForV2(productFeature: ProductFeatures): boolean {
    const hasAddOnAccess = this.hasAccessByAddon(productFeature, PAID_PLAN_VERSION.V2);
    const hasCapabilityAccess = this.hasAccessByCapability(productFeature, PAID_PLAN_VERSION.V2);

    if (hasAddOnAccess === null && hasCapabilityAccess === null) {
      throw new Error('Provide either addOns or capabilities for check');
    }

    if (hasAddOnAccess === null) {
      return hasCapabilityAccess!;
    }

    if (hasCapabilityAccess === null) {
      return hasAddOnAccess;
    }

    return hasAddOnAccess || hasCapabilityAccess;
  }

  /**
   * Есть ли доступ до фичи у приложения с тарифом тертей тарифной сетки
   *
   * @param productFeature - Фича
   */
  private hasAccessForV3(productFeature: ProductFeatures): boolean {
    const hasAddOnAccess = this.hasAccessByAddon(productFeature, PAID_PLAN_VERSION.V3);
    const hasCapabilityAccess = this.hasAccessByCapability(productFeature, PAID_PLAN_VERSION.V3);

    if (hasAddOnAccess === null && hasCapabilityAccess === null) {
      throw new Error('Provide either addOns or capabilities for check');
    }

    if (hasAddOnAccess === null) {
      return hasCapabilityAccess!;
    }

    if (hasCapabilityAccess === null) {
      return hasAddOnAccess;
    }

    return hasAddOnAccess || hasCapabilityAccess;
  }
}

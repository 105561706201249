import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { generate } from 'short-uuid';

import { MessageEditorOpenedWebPageTriggerTypesForm } from '@panel/app/partials/message-editor/trigger/opened-web-page-trigger-type/message-editor-opened-web-page-trigger-type/message-editor-opened-web-page-trigger-type.component';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';

@Component({
  selector: 'cq-message-editor-opened-web-page-trigger-types',
  templateUrl: './message-editor-opened-web-page-trigger-types.component.html',
  styleUrls: ['./message-editor-opened-web-page-trigger-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorOpenedWebPageTriggerTypesComponent extends AbsCVAFormArrayBasedComponent<
  MessageEditorOpenedWebPageTriggerTypesForm,
  FormControl
> {
  readonly control: FormArray<FormControl<MessageEditorOpenedWebPageTriggerTypesForm>> = this.fb.array<
    FormControl<MessageEditorOpenedWebPageTriggerTypesForm>
  >([]);

  @Input()
  entityName: string = '';

  @Input()
  formSubmit?: Observable<void>;

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  trackBy(index: number, value: FormControl<MessageEditorOpenedWebPageTriggerTypesForm>) {
    return value.value.localId;
  }

  addTriggerTypeControl() {
    const control = MessageEditorOpenedWebPageTriggerTypesComponent.getNewControl();
    this.control.push(control);
  }

  removeTriggerType(index: number) {
    this.control.removeAt(index);
  }

  static getNewControl(data?: MessageEditorOpenedWebPageTriggerTypesForm) {
    const control: MessageEditorOpenedWebPageTriggerTypesForm = {
      localId: generate(),
      comparison: data ? data.comparison : 'eq',
      url: data ? data.url : '',
    };

    return new FormControl<MessageEditorOpenedWebPageTriggerTypesForm>(control, { nonNullable: true });
  }

  getNewControl(val: MessageEditorOpenedWebPageTriggerTypesForm): FormControl {
    return MessageEditorOpenedWebPageTriggerTypesComponent.getNewControl(val);
  }
}

<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t(headingTranslatePath) }}</h3>
    <button
      class="close"
      (click)="dismissModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div [innerHTML]="t(bodyTranslatePath)"></div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="dismissModal()"
    >
      {{ t('general.cancel') }}
    </button>
    <button
      class="btn btn-primary"
      (click)="closeModal()"
    >
      {{ t(confirmButtonTranslatePath) }}
    </button>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'branchNameEditorComponent'">
  <div class="bot-branch-name no-margin flex-grow-1">
    <input
      #branchNameInput
      type="text"
      class="bot-branch-name-input form-control no-border"
      (blur)="isBranchNameInputFocused = false"
      (focus)="isBranchNameInputFocused = true"
      [formControl]="formNameControl"
      placeholder="{{ t('placeholder') }}"
    />
    <cq-validation-messages
      position="bottom"
      [control]="formNameControl"
    >
      <cq-validation-message errorName="required">
        {{ t('errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('errors.maxLength', { maxLength: BRANCH_NAME_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
  <button
    class="btn btn-borderless-primary"
    type="button"
    [ngClass]="{ focus: this.isBranchNameInputFocused }"
    (click)="branchNameInput.focus()"
    [ngbTooltip]="t('renameButton.tooltip')"
    container="body"
    [placement]="isFullscreenEditorEnabled ? 'bottom' : 'top'"
  >
    <i class="btn-icon cqi-sm cqi-pencil"></i>
  </button>
  <button
    class="btn btn-borderless-primary"
    type="button"
    (click)="blockDelete.emit()"
    [ngbTooltip]="t('removeBranchButton.tooltip')"
    container="body"
    [placement]="isFullscreenEditorEnabled ? 'bottom-right' : 'top'"
  >
    <i class="btn-icon cqi-sm cqi-trash"></i>
  </button>
</ng-container>

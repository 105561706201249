import { TranslocoService } from '@jsverse/transloco';
import { Container, FederatedPointerEvent, Graphics, Point, Text } from 'pixi.js';
import { Simple } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';
import { fromEvent, merge, Observable, of, partition, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { generate } from 'short-uuid';

import { BotCanvasOverlayAbleElement } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-blocks-overlay.interfaces';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ConnectionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { PIXI_INTERACTION_EVENT } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.types';
import { OverlayBootstrapElementsHelper } from '@panel/app/pages/chat-bot/content/services/overlay-bootstrap-elements-helper.service';
import { BranchViewMap, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import {
  CONNECTION_POINT_BORDER_SIZE,
  CONNECTION_POINT_DIAMETER,
} from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { BlockType, Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import {
  Connection,
  ConnectionSource,
  IPoint,
  VIEWPORT_ON_ZOOM_THROTTLE,
} from '@panel/app/pages/chat-bot/content/views/connection';
import { ABSCard } from '@panel/app/pages/chat-bot/content/views/element.abs';
import { BadgeForm } from '@panel/app/pages/chat-bot/forms/badge.form';
import { decreaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/decrease-ticker-max-fps.function';
import { increaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/increase-ticker-max-fps.function';
import { Immutable } from '@panel/app/shared/types/immutable.type';
import { ZoomedEventData } from '@panel/app/shared/types/zoomed-event-data.type';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';

import { BOT_GREY, BOT_MESSAGE_BLOCK_PRIMARY_COLOR, BOT_WHITE_COLOR } from '../utils/colors';

export type BadgeStyle = {
  border: {
    size: number;
    color: number;
    radius: number;
  };
  background: {
    color: number;
  };
  text: {
    color: number;
    size: number;
  };
};

export const DEFAULT_BADGE_STYLE: Immutable<BadgeStyle> = {
  border: {
    size: 0,
    color: BOT_WHITE_COLOR,
    radius: 22,
  },
  background: {
    color: BOT_WHITE_COLOR,
  },
  text: {
    color: BOT_GREY,
    size: 14,
  },
};

export const BADGE_HORIZONTAL_PADDING = 15; // Горизонтальные отступы бейджа
export const BADGE_VERTICAL_PADDING = 7; // Вертикальные отступы бейджа
export const BADGE_ELEMENTS_INDENT = 5; // Отступ между элементами бейджа

export abstract class BaseBadge extends ABSCard implements ConnectionSource, BotCanvasOverlayAbleElement {
  blockType: BlockType = 'branch'; // TODO Пока оставляю так, НО в будущем это надо поправить
  clickOnPointerUp = false;
  connection?: Connection;
  connectionPoint: Graphics = new Graphics();
  public readonly container: Container;
  readonly form: BadgeForm;
  isDragging = false; // маркер того, идет ли перемещиение эелемента в данный момент
  protected card: Graphics;
  protected abstract readonly style: BadgeStyle;
  nextBranchDestroySub?: Subscription;
  protected readonly eventEmitters = {
    move: new Subject<void>(), // Чтоб можно было трекать когда двигается и перерисовывать связи с новыми координатами
    redraw: new Subject<void>(), // Когда перерисовывается ветка
  };
  rerenderConnection$: Observable<void> = this.eventEmitters.move.asObservable();
  targetBranchChange$: Subject<Branch | null> = new Subject();

  constructor(
    protected readonly botScenariosHelper: BotScenariosHelper,
    protected readonly cull: Simple,
    protected readonly interactionService: PixiInteractionService,
    protected readonly document: Document,
    protected readonly connectionFactory: ConnectionFactory,
    protected readonly transloco: TranslocoService,
    private targetBlock: Branch | undefined,
    protected readonly botType: CHAT_BOT_TYPE,
    protected readonly canvasBranches: BranchViewMap,
    protected readonly viewport: Viewport,
    protected readonly bootstrapElementsHelper: OverlayBootstrapElementsHelper,
    protected readonly pixiApp: PixiApplication,
  ) {
    super();
    this.container = this.createContainer();
    this.container.zIndex++; // Чтоб всегда был выше остальных элементов
    this.card = this.drawCard();
    this.container.addChild(this.card);
    this.initialSetUpConnectionPoint();
    this.renderDefaultConnectionPoint();
    this.container.addChild(this.connectionPoint);
    this.setPivot(this.viewport.scaled);

    this.form = new BadgeForm(targetBlock);

    if (targetBlock) {
      this.connection = this.connectionFactory.create(this, targetBlock);
    }

    this.canvasBranches.update$.pipe(debounceTime(300), take(1)).subscribe(() => this.subToNextBranchDestroy());
    this.subToNextBranchChange();
    this.initListeners();
  }

  get topLeftPositionOnCanvas(): IPoint {
    return {
      x: this.viewport.position.x + this.container.position.x * this.viewport.scaled - this.scaledWidth,
      y: this.viewport.position.y + this.container.position.y * this.viewport.scaled - this.scaledHeight / 2,
    };
  }

  readonly positionChange$ = this.eventEmitters.move.asObservable();

  readonly sizeChange$ = of<void>();

  readonly showOverlay$: Observable<boolean> = of(true);
  readonly uid: string = generate();

  abstract get apiReadyData(): unknown;

  get connectionPointGlobalCoordinates(): IPoint {
    return {
      x: this.container.position.x,
      y: this.container.position.y,
    };
  }

  get height(): number {
    return this.card.height;
  }

  get width(): number {
    return this.card.width;
  }

  addConnectionInfo(connection: Connection): void {
    this.connection = connection;
  }

  destroy() {
    this.connection?.dropConnection();
    super.destroy();
  }

  deleteConnectionInfo(connection: Connection) {
    this.connection = undefined;
  }

  getContentHeight(): number {
    return this.container.height;
  }

  redraw() {
    this.card = this.drawCard();
  }

  render(): Container {
    return this.container;
  }

  updateConnectionTarget(nextBranchLinkId: string | null) {
    const block = this.canvasBranches.get(nextBranchLinkId ?? '');
    if (!block) {
      this.form.controls.nextBranchLinkId.setValue(null);
      this.form.nextBranchId = null;
      return;
    }
    this.form.controls.nextBranchLinkId.setValue(block.linkId);
    this.form.nextBranchId = block.id;
  }

  /**
   * Инициализация слешателей для connectionPoint
   * @protected
   */
  protected initConnectionListeners(): void {
    this.connectionPoint.on('pointerdown', (event: Event) => {
      event.stopPropagation(); // чтоб ивент не прокидывался на полотно и не отрабатывали dragListeners
      if (this.connection) {
        this.connection.startDrawToNewTarget();
      } else {
        this.connection = this.connectionFactory.create(this);
      }
      this.interactionService.registerPointerDown({ type: PIXI_INTERACTION_EVENT.CONNECTION_POINT, instance: this });
    });

    this.connectionPoint.on('mouseover', () => this.renderHoveredConnectionPoint());
    this.connectionPoint.on('mouseout', () => this.renderDefaultConnectionPoint());
  }

  /**
   * Задает точку отсчета для контейнера (в месте крепления связи)
   *
   * NOTE нужен для корректоно пересчета коориднат при зумировании
   * @param viewportScaled
   * @private
   */
  protected setPivot(viewportScaled: number) {
    this.container.pivot.set(this.container.width * viewportScaled, (this.container.height / 2) * viewportScaled);
  }

  private subToNextBranchChange() {
    this.form.controls.nextBranchLinkId.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((nextBranchLinkId: string | null) => {
        if (this.connection?.target) {
          this.botScenariosHelper.removeTargetFromScenarioOfSource(this, this.connection.target);
        }
        this.connection?.dropConnection();
        const targetBranch = this.canvasBranches.get(nextBranchLinkId ?? '');
        if (!targetBranch) {
          this.targetBranchChange$.next(null);
          return;
        }
        this.connection = this.connectionFactory.create(this, targetBranch);
        this.botScenariosHelper.addTargetToScenarioOfSource(this, targetBranch);
        this.targetBranchChange$.next(targetBranch);
        this.subToNextBranchDestroy();
      });
  }

  /**
   * Подписка на удаление активной nextBranch
   */
  private subToNextBranchDestroy() {
    this.nextBranchDestroySub?.unsubscribe();
    const targetBranch = this.canvasBranches.get(this.form.controls.nextBranchLinkId.value ?? '');
    if (!targetBranch) {
      return;
    }
    this.nextBranchDestroySub = targetBranch.destroy$.pipe(takeUntil(this.destroy$), first()).subscribe(() => {
      this.form.controls.nextBranchLinkId.setValue(null);
      this.form.nextBranchId = null;
    });
  }

  /**
   * Задает начальные коориданы бейджа
   */
  public setInitialPosition() {
    this.setPositionByBlock(this.targetBlock);
  }

  /**
   * Отрисовка картоточки бейджа
   */
  abstract drawCard(): Graphics;

  /**
   * Коллбек для события клика
   */
  protected abstract clickCallback(): void;

  /**
   * Коллбек для mouseover события
   */
  protected abstract mouseoverCallback(): void;

  /**
   * Коллбек для mouseout события
   */
  protected abstract mouseoutCallback(): void;

  /**
   * Начальная позиция бейджа при отсутствии таргет блока
   * @protected
   */
  protected get initPosition(): IPoint {
    return {
      x: 0,
      y: 0,
    };
  }

  /**
   * Задает начальную позицию бейджа - рядом со связанным блоком
   */
  private setPositionByBlock(targetBlock?: Branch): void {
    const xPosition = targetBlock ? targetBlock.connectionPoint.x - 30 : this.initPosition.x;
    const yPosition = targetBlock ? targetBlock.connectionPoint.y : this.initPosition.y;

    this.container.position.set(xPosition, yPosition);
    this.eventEmitters.move.next();
    this.cull.updateObject(this.container);
  }

  /**
   * Обработка событий клика по бейджу
   */
  protected initClickListeners() {
    this.container.on('pointerdown', () => {
      this.clickOnPointerUp = true;
      this.interactionService.registerPointerDown({ type: PIXI_INTERACTION_EVENT.BADGE, instance: this });
    });
    this.container.on('pointerup', () => {
      this.interactionService.registerPointerUp({ type: PIXI_INTERACTION_EVENT.BADGE, instance: this });
      if (this.clickOnPointerUp) {
        this.clickCallback();
        this.clickOnPointerUp = false;
        this.interactionService.registerClick({ type: PIXI_INTERACTION_EVENT.BADGE, instance: this });
      }
    });
  }

  /**
   * Обработка ивентов для перемещения блока по канвасу
   * @private
   */
  protected initDragListeners(): void {
    let dragStartEvent: FederatedPointerEvent | null = null; // инфа о ивенте mousedown по элементу
    let localClickPosition: Point; // инфа о местоположении курсора относительно элемента
    let moveCount = 0; // посчитать сколько раз сместился блок. Если больше 3(из головы число) раз, то this.clickOnPointerUp = false;

    const onDragStart = (event: FederatedPointerEvent) => {
      dragStartEvent = event;
      localClickPosition = this.container.toLocal(dragStartEvent.global);
      //NOTE Пересчитываю localClickPosition, учитывая pivot
      localClickPosition.x = (localClickPosition.x - this.container.pivot.x) * Math.pow(this.viewport.scaled, -1);
      localClickPosition.y = (localClickPosition.y - this.container.pivot.y) * Math.pow(this.viewport.scaled, -1);
      this.isDragging = true;
      event.stopPropagation(); // чтоб ивент нажатия на элемент не прокидывался на полотно
    };
    const onDragEnd = () => {
      if (!this.isDragging) {
        return;
      }
      this.isDragging = false;
      dragStartEvent = null;
    };
    const onDragMove = () => {
      if (this.isDragging) {
        if (moveCount > 3) {
          this.clickOnPointerUp = false;
          moveCount = 0;
        } else {
          moveCount++;
        }
        if (!dragStartEvent) {
          return;
        }
        const newPosition = dragStartEvent.getLocalPosition(this.container.parent);
        this.container.position.set(newPosition.x - localClickPosition.x, newPosition.y - localClickPosition.y);
        this.eventEmitters.move.next();
        this.cull.updateObject(this.container);
        this.interactionService.registerDrag({ type: PIXI_INTERACTION_EVENT.BADGE, instance: this });
      }
    };

    const dragStart$ = fromEvent<FederatedPointerEvent>(this.container, 'pointerdown');

    const dragEnd$ = merge(fromEvent(this.document, 'pointerup'), fromEvent(this.container, 'pointerupoutside'));

    dragStart$.pipe(takeUntil(this.destroy$)).subscribe(onDragStart);

    dragStart$
      .pipe(
        increaseTickerMaxFPS(this.pixiApp),
        mergeMap(() => {
          return fromEvent(this.container, 'pointermove').pipe(takeUntil(dragEnd$));
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(onDragMove);

    dragEnd$.pipe(decreaseTickerMaxFPS(this.pixiApp), takeUntil(this.destroy$)).subscribe(onDragEnd);
  }

  /**
   * Обработка событий формы
   * @private
   */
  protected initFormListeners(): void {
    this.form.showError$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        tap(() => {
          this.bootstrapElementsHelper.createErrorTooltip(
            this.uid,
            this.transloco.translate('chatBot.badges.errors.required'),
          );
        }),
      )
      .subscribe((showError) => {
        if (showError) {
          this.bootstrapElementsHelper.openErrorTooltip(this.uid);
        } else {
          this.bootstrapElementsHelper.closeErrorTooltip(this.uid);
        }
      });
  }

  /**
   * Обработка hover событий
   */
  protected initHoverListeners(): void {
    this.container.on('mouseover', () => this.mouseoverCallback());
    this.container.on('mouseout', () => this.mouseoutCallback());
  }

  /**
   * Обработка событий зума
   * @private
   */
  protected initZoomListener(): void {
    const [withoutThrottle$, withThrottle$] = partition(
      fromEvent<ZoomedEventData>(this.viewport, 'zoomed'),
      (val: ZoomedEventData) => !!val.preventThrottle,
    );

    merge(withThrottle$.pipe(throttleTime(VIEWPORT_ON_ZOOM_THROTTLE, undefined, { trailing: true })), withoutThrottle$)
      .pipe(
        map((event) => event.viewport.scaled),
        takeUntil(this.destroy$),
      )
      .subscribe((viewportScaled) => {
        const scale = Math.pow(viewportScaled, -1);
        this.container.setTransform(this.container.position.x, this.container.position.y, scale, scale);
        this.setPivot(viewportScaled);
      });
  }

  /**
   * Первоначальная настройка connectionPoint
   */
  private initialSetUpConnectionPoint() {
    this.connectionPoint.eventMode = 'static';
    this.connectionPoint.cursor = 'pointer';
    this.connectionPoint.name = 'Connection point';
    this.connectionPoint.zIndex = 2;
  }

  /**
   * Инициализация обработчиков
   */
  protected initListeners() {
    this.initDragListeners();
    this.initClickListeners();
    this.initHoverListeners();
    this.initZoomListener();
    this.initFormListeners();
  }

  /**
   * Рисование вида по умолчанию
   */
  protected renderDefaultConnectionPoint(): void {
    const circle = this.connectionPoint;

    circle.clear();

    circle.lineStyle(CONNECTION_POINT_BORDER_SIZE, BOT_MESSAGE_BLOCK_PRIMARY_COLOR, 1);
    circle.beginFill(BOT_WHITE_COLOR, 1);
    circle.drawCircle(
      this.container.width * this.viewport.scaled,
      (this.container.height / 2) * this.viewport.scaled,
      CONNECTION_POINT_DIAMETER * 2,
    );
    circle.endFill();
  }

  /**
   * Рисование вида при наведении
   */
  private renderHoveredConnectionPoint(): void {
    const circle = this.connectionPoint;
    circle.clear();
    circle.beginFill(0x5c6370, 1);
    circle.drawCircle(
      this.container.width * this.viewport.scaled,
      (this.container.height / 2) * this.viewport.scaled,
      CONNECTION_POINT_DIAMETER * 2,
    );
    circle.endFill();
  }

  get scaledHeight(): number {
    return this.container.height * this.viewport.scaled;
  }

  get scaledWidth(): number {
    return this.container.width * this.viewport.scaled;
  }

  /**
   * Расставление элементов внутри контейнера
   *
   * @param card - Контейнер для элеметов
   * @param elements - Элементы для расставления
   * @protected
   */
  protected updateContentPosition(card: Graphics, elements: (Container | Text)[] = []) {
    elements.reduce((itemX, item) => {
      const itemY = (card.height - item.height) / 2;

      item.position.set(itemX, itemY);

      return itemX + item.width + BADGE_ELEMENTS_INDENT;
    }, BADGE_HORIZONTAL_PADDING);
  }
}

import { SETTINGS_TABS } from '../../settings/settings.constants';

(function () {
  'use strict';

  angular
    .module('myApp.privacySettings')
    .controller('CqChatbotAndPopupDataCollectionSettingsController', CqChatbotAndPopupDataCollectionSettingsController);

  function CqChatbotAndPopupDataCollectionSettingsController(
    $translate,
    toastr,
    PRIVACY_POLICY_EMAIL_URL,
    PRIVACY_POLICY_PHONE_URL,
    validationHelper,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.chatbotAndPopupDataCollectionPreview = {
        CONVERSATION: 'conversation',
        NOTIFICATION: 'notification',
      };

      vm.chatbotAndPopupDataCollectionSettingsForm = null;
      vm.changeChatbotAndPopupDataCollectionPreview = changeChatbotAndPopupDataCollectionPreview;
      vm.PRIVACY_POLICY_EMAIL_URL = PRIVACY_POLICY_EMAIL_URL;
      vm.PRIVACY_POLICY_PHONE_URL = PRIVACY_POLICY_PHONE_URL;
      vm.SETTINGS_TABS = SETTINGS_TABS;
      vm.stateChatbotAndPopupDataCollectionPreview = vm.chatbotAndPopupDataCollectionPreview.NOTIFICATION;
      vm.validationHelper = validationHelper;
    }

    function changeChatbotAndPopupDataCollectionPreview() {
      if (vm.stateChatbotAndPopupDataCollectionPreview === vm.chatbotAndPopupDataCollectionPreview.NOTIFICATION) {
        vm.stateChatbotAndPopupDataCollectionPreview = vm.chatbotAndPopupDataCollectionPreview.CONVERSATION;
        return;
      }
      if (vm.stateChatbotAndPopupDataCollectionPreview === vm.chatbotAndPopupDataCollectionPreview.CONVERSATION) {
        vm.stateChatbotAndPopupDataCollectionPreview = vm.chatbotAndPopupDataCollectionPreview.NOTIFICATION;
        return;
      }
    }
  }
})();

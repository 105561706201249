<ng-container *transloco="let t; read: 'inputNumberComponent'">
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="control.controls.value"
  >
    <input
      class="form-control"
      [formControl]="control.controls.value"
      autofocus
      required
      type="text"
    />
    <cq-validation-messages [control]="control.controls.value">
      <cq-validation-message errorName="min">{{ t('error.min', { value: MIN_VALUE }) }}</cq-validation-message>
      <cq-validation-message errorName="pattern">{{ t('error.pattern') }}</cq-validation-message>
      <cq-validation-message errorName="required">{{ t('error.required') }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

(function () {
  'use strict';

  angular.module('myApp.serviceInstallation', []);

  require('./copy-script/copy-script.component');
  require('./general/service-installation.component');
  require('./script-insertion/script-insertion.constants');
  require('./script-insertion/script-insertion.component');
  require('./service-installation-checker/service-installation-checker.component');
})();

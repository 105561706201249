(function () {
  'use strict';

  angular.module('myApp.directives.wizard').directive('cqWizardItemContent', cqWizardItemContent);

  function cqWizardItemContent() {
    return {
      restrict: 'A',
      require: '^^cqWizard',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var step = scope.$eval(iAttrs.cqWizardItemContent);

      step.transcludeFn(scope.$parent.$parent, function (contents) {
        angular.forEach(contents, function (node) {
          iElement.append(node);
        });
      });
    }
  }
})();

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqZendeskController', CqZendeskController);

  function CqZendeskController($filter, $state, $translate, toastr, PROJECT_NAME, integrationModel, validationHelper) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.createOrUpdateIntegration = createOrUpdateIntegration;
      vm.isManualExpanded = angular.isUndefined(vm.integration.id); // раскрыта ли инструкция
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.propertyFilter = ''; // Фильтр по свойствам
      vm.toggleManualVisibility = toggleManualVisibility;
      vm.validationHelper = validationHelper;
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function createOrUpdateIntegration(isValid) {
      if (isValid) {
        if (vm.integration.id) {
          integrationModel.save(vm.currentApp.id, vm.integration).then(saveSuccess);
        } else {
          integrationModel.create(vm.currentApp.id, vm.integration).then(createSuccess);
        }
      }

      function createSuccess(integration) {
        let params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        let transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

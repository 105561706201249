import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { TrackMasterModel } from '@http/track-master/track-master.model';
import { DataCollectionComponent } from '@panel/app/pages/data-collection/pages/data-collection/data-collection.component';
import { DATA_COLLECTION_TABS } from '@panel/app/pages/data-collection/pages/data-collection/data-collection.constants';

export const DATA_COLLECTION_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.dataCollection',
    component: DataCollectionComponent,
    url: '/data-collection?{tab}',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
    },
    params: {
      createdInGroup: {
        value: null,
        dynamic: true,
      },
    },
    resolve: [
      {
        token: 'autoEvents',
        deps: ['autoEvents'],
        resolveFn: (autoEvents: any) => autoEvents,
      },

      {
        token: 'billingInfo',
        deps: ['billingInfo'],
        resolveFn: (billingInfo: any) => billingInfo,
      },
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'djangoUser',
        deps: ['djangoUser'],
        resolveFn: (djangoUser: any) => djangoUser,
      },
      {
        token: 'eventTypes',
        deps: ['eventTypes'],
        resolveFn: (eventTypes: any) => eventTypes,
      },
      {
        token: 'autoEvents',
        deps: ['currentApp', 'trackMasterModel'],
        resolveFn: getAutoEvents,
      },
      {
        token: 'eventTypes',
        deps: ['eventTypeModel', 'currentApp'],
        resolveFn: getEventTypes,
      },
    ],
  },

  // DEPRECATED: перенаправление со старого URL типов событий
  {
    name: 'app.content.events',
    url: '/eventslist',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
    },
    redirectTo: function (transition) {
      return {
        state: 'app.content.dataCollection',
        params: {
          ...transition.params(),
          tab: DATA_COLLECTION_TABS.EVENT_TYPES,
        },
      };
    },
  },
];

/**
 * Получение всех автособытий
 *
 * @param currentApp
 * @param trackMasterModel
 * @returns {*|PromiseLike<T>|Promise<T>}
 */
function getAutoEvents(currentApp: App, trackMasterModel: TrackMasterModel) {
  return firstValueFrom(trackMasterModel.getList(currentApp.id));
}

/**
 * Получение списка событий
 *
 * @param eventTypeModel
 * @param currentApp
 * @returns {*}
 */
function getEventTypes(eventTypeModel: EventTypeModel, currentApp: App) {
  return firstValueFrom(eventTypeModel.getList(currentApp.id));
}

import { ChangeDetectionStrategy, Component, NgZone, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';

export type UrlWorksOnForm = {
  enabled: boolean;
  url: string;
  hasSubpages: boolean;
};

@Component({
  selector: 'cq-works-on-page',
  templateUrl: './works-on-page.component.html',
  styleUrls: ['./works-on-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorksOnPageComponent extends AbstractCVAControl<UrlWorksOnForm> {
  control = this.fb.group<UrlWorksOnForm>({
    enabled: false,
    url: null,
    hasSubpages: false,
  });

  constructor(
    private readonly fb: GenericFormBuilder,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { filter } from 'lodash-es';
import sortBy from 'lodash-es/sortBy';

import { MEMBER_INVITATION_STATUS } from '@http/team-member-invitation/constants/member-invitations.constants';
import { MemberInvitation } from '@http/team-member-invitation/types/member-invitations.types';

/**
 * Компонента для работы с приглашениями членов команды
 */

@Component({
  selector: 'cq-member-invitations[memberInvitations][clickAddTeamMembersButton]',
  templateUrl: './member-invitations.component.html',
  styleUrls: ['./member-invitations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberInvitationsComponent {
  /** Приглашения */
  @Input() set memberInvitations(invitations: MemberInvitation[]) {
    this.unfilteredInvitations = invitations;
    this.resetAllFiltersEmitter.emit();
    this.filteredInvitations = this.filterInvitationsDefault(invitations);
    this.changeDetectorRef.detectChanges();
  }

  /** Callback на клик по кнопке добавления члена команды */
  @Output()
  clickAddTeamMembersButton: EventEmitter<void> = new EventEmitter<void>();

  /** Отфильтрованные приглашения */
  filteredInvitations!: MemberInvitation[];

  /** Сброс всех фильтров */
  resetAllFiltersEmitter: EventEmitter<void> = new EventEmitter<void>();

  /** Нефильтрованные приглашения */
  unfilteredInvitations: MemberInvitation[] = [];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Фильтрация приглашений по умолчанию
   *
   * @param unfilteredInvitations - Нефильтрованные приглашения
   */
  filterInvitationsDefault(unfilteredInvitations: MemberInvitation[]): MemberInvitation[] {
    return this.sortInvitationByEmail(unfilteredInvitations);
  }

  /**
   * Фильтрация приглашений по Email
   *
   * @param unfilteredInvitations - Нефильтрованные приглашения
   * @param emailPhrase - Email по которому необходимо провести фильтрацию
   */
  filterInvitationsByEmail(unfilteredInvitations: MemberInvitation[], emailPhrase: string): MemberInvitation[] {
    const filteredInvitations = filter(this.unfilteredInvitations, (invitation) => {
      return invitation.email.includes(emailPhrase);
    });

    return this.sortInvitationByEmail(filteredInvitations);
  }

  /**
   * Фильтрация приглашений по статусу
   *
   * @param unfilteredInvitations - Нефильтрованные приглашения
   * @param status - Email по которому необходимо провести фильтрацию
   */
  filterInvitationsByStatus(
    unfilteredInvitations: MemberInvitation[],
    status: MEMBER_INVITATION_STATUS,
  ): MemberInvitation[] {
    const inviteExpired = status === MEMBER_INVITATION_STATUS.EXPIRED;
    const filteredInvitations = filter(this.unfilteredInvitations, (invitation) => {
      return invitation.inviteExpired === inviteExpired;
    });

    return this.sortInvitationByEmail(filteredInvitations);
  }

  /** Существуют ли приглашения */
  isInvitationsExist(): boolean {
    return this.unfilteredInvitations.length > 0;
  }

  /**
   * Обработчик фильтрации приглашений по Email
   *
   * @param emailPhrase - Email по которому необходимо провести фильтрацию
   */
  onFilterInvitationByEmail(emailPhrase: string | null): void {
    this.filteredInvitations = emailPhrase
      ? this.filterInvitationsByEmail(this.unfilteredInvitations, emailPhrase)
      : this.filterInvitationsDefault(this.unfilteredInvitations);

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Обработчик фильтрации приглашений по статусу
   *
   * @param status - Статус по которому необходимо провести фильтрацию
   */
  onFilterInvitationByStatus(status: MEMBER_INVITATION_STATUS | null): void {
    this.filteredInvitations = status
      ? this.filterInvitationsByStatus(this.unfilteredInvitations, status)
      : this.filterInvitationsDefault(this.unfilteredInvitations);

    this.changeDetectorRef.detectChanges();
  }

  /** Обработчик клика по кнопке добавления члена команды */
  onClickAddTeamMembersButton(): void {
    this.clickAddTeamMembersButton.emit();
  }

  /**
   * Обработчик повторной отправки приглашения
   *
   * @param memberInvitation - Повторно отправленное приглашение
   */
  onResendInvitationFn(memberInvitation: MemberInvitation): void {
    this.unfilteredInvitations.forEach((invitation, index) => {
      if (invitation.email === memberInvitation.email) {
        this.unfilteredInvitations[index] = memberInvitation;
      }
    });

    this.filteredInvitations = this.filterInvitationsDefault(this.unfilteredInvitations);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Сортировка приглашений по Email
   *
   * @param unsortedInvitations - Не отсортированные приглашения
   */
  sortInvitationByEmail(unsortedInvitations: MemberInvitation[]): MemberInvitation[] {
    return sortBy(unsortedInvitations, ['email']);
  }
}

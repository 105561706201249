import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationService } from '@http/integration/services/integration.service';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmModalCreateLeadTagComponent } from './amocrm-modal-create-lead-tag.component';

@NgModule({
  declarations: [AmocrmModalCreateLeadTagComponent],
  exports: [AmocrmModalCreateLeadTagComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, ReactiveFormsModule],
  providers: [IntegrationService],
})
export class AmocrmModalCreateLeadTagModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';
import { NgArrayPipesModule } from 'ngx-pipes';
import { SortablejsModule } from 'nxt-sortablejs';

import { ActiveChatWidgetOrderComponent } from '@panel/app/pages/widget-bot/editor/components/order/active-chat-widget-order.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [ActiveChatWidgetOrderComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    ReactiveFormsModule,
    SortablejsModule,
    UIRouterModule,
    NgArrayPipesModule,
  ],
  exports: [ActiveChatWidgetOrderComponent],
})
export class ActiveChatWidgetOrderModule {}

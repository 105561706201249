<ng-container *transloco="let t">
  <div class="flex-shrink-1 overflow-hidden bubble">
    <ng-container *ngFor="let part of readyPartPreviewComponents">
      <cq-conversation-part-preview-buttons
        *ngIf="part === CONVERSATION_PART_PREVIEW_COMPONENT.BUTTONS"
        [buttons]="partButtons"
      ></cq-conversation-part-preview-buttons>

      <cq-conversation-part-preview-delimiter
        *ngIf="part === CONVERSATION_PART_PREVIEW_COMPONENT.DELIMITER"
      ></cq-conversation-part-preview-delimiter>

      <cq-conversation-part-preview-file
        *ngIf="part === CONVERSATION_PART_PREVIEW_COMPONENT.FILE"
        [attachment]="partAttachment"
      ></cq-conversation-part-preview-file>

      <cq-conversation-part-preview-image
        *ngIf="part === CONVERSATION_PART_PREVIEW_COMPONENT.IMAGE"
        [attachment]="partAttachment"
      ></cq-conversation-part-preview-image>

      <cq-conversation-part-preview-space
        *ngIf="part === CONVERSATION_PART_PREVIEW_COMPONENT.SPACE"
      ></cq-conversation-part-preview-space>

      <cq-conversation-part-preview-text
        *ngIf="part === CONVERSATION_PART_PREVIEW_COMPONENT.TEXT"
        [text]="partText"
      ></cq-conversation-part-preview-text>

      <cq-conversation-part-preview-video
        *ngIf="part === CONVERSATION_PART_PREVIEW_COMPONENT.VIDEO"
        [attachment]="partAttachment"
      ></cq-conversation-part-preview-video>
    </ng-container>
  </div>

  <cq-conversation-part-preview-avatar></cq-conversation-part-preview-avatar>
</ng-container>

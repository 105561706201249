<ng-container *transloco="let t; read: 'tagFiltersComponent'">
  <div class="pb-20">
    <div
      class="flex align-items-center cursor-pointer"
      (click)="collapse.toggle()"
    >
      <div class="font-weight-bold">
        {{ t('usersTags') }}
      </div>
      <i
        class="cqi-sm cqi-question-circle margin-left-5"
        [ngbTooltip]="t('usersTagsTooltip')"
      ></i>
      <div class="margin-right-5 ml-auto">
        {{ getFilteredTags(tags, defaultFilters, showRemoved).length }}
      </div>
      <i
        *ngIf="collapse.collapsed"
        class="cqi-sm cqi-chevron-up"
      ></i>
      <i
        *ngIf="!collapse.collapsed"
        class="cqi-sm cqi-chevron-down"
      ></i>
    </div>
    <div
      class="tags"
      #collapse="ngbCollapse"
      [ngbCollapse]="false"
    >
      <div
        *ngIf="tags.length"
        class="flex flex-wrap padding-top-15 margin-5-neg"
      >
        <ng-container *ngFor="let tag of getFilteredTags(tags, defaultFilters, showRemoved); trackBy: trackById">
          <cq-user-tag
            class="margin-5"
            (containsChange)="trackClickTagContains(); changeTagFilterType(tag)"
            (remove)="openRemoveTagModal(tag)"
            (selectChange)="trackClickTag(); tagSelectChange(tag)"
            [canActivate]="true"
            [canRemove]="canRemoveTags"
            [isActive]="isTagSelected(tag)"
            [isContains]="isTagContains(tag)"
            [tag]="tag"
          ></cq-user-tag>
        </ng-container>
      </div>
      <div
        *ngIf="!tags.length"
        class="padding-top-15"
      >
        {{ 'filterSegments.general.tags.noTags' | transloco }}
      </div>
      <cq-access-denial-alert
        *ngIf="!accessToUsersTags.hasAccess"
        class="mt-25"
        [currentApp]="app"
        [denialReason]="accessToUsersTags.denialReason"
      ></cq-access-denial-alert>
    </div>
  </div>
</ng-container>

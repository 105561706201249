import { Injectable } from '@angular/core';

import { EventTypeModel } from '@http/event-type/event-type.model';
import { TriggerChain } from '@panel/app/http/trigger-chain/internal-types';
import { TriggerChainStepMapper } from '@panel/app/http/trigger-chain/mappers/trigger-chain-step.mapper';
import { CaseStyleHelper } from '@panel/app/services';
import { EventType } from '@http/property/property.model';

@Injectable({ providedIn: 'root' })
export class TriggerChainUtils {
  constructor(
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly triggerChainStepMapper: TriggerChainStepMapper,
  ) {}

  copyStructure(triggerChain: TriggerChain, eventTypes: EventType[]) {
    const template = triggerChain.steps.map((triggerChainStep) => {
      const parsedTemplateStep = this.triggerChainStepMapper.parseToTemplate(triggerChainStep);

      if (parsedTemplateStep.type === 'sending_conditions') {
        const content = parsedTemplateStep.metadata.content;
        content.eventTypes = content.eventTypes
          .map((eventId: string) => {
            return EventTypeModel.findEventTypeById(eventId, eventTypes).name;
          })
          .filter((eventName) => eventName.startsWith('$'));
      }

      if (parsedTemplateStep.type === 'auto_message') {
        const { metadata } = parsedTemplateStep;
        const { content } = metadata;

        const updatedMessageParts = content.parts.map((part) => ({ ...part, muser: null, id: null }));
        const updatedContent = { ...content, messageId: null, parts: updatedMessageParts };
        const updatedMetadata = { ...metadata, content: updatedContent };

        return { ...parsedTemplateStep, metadata: updatedMetadata };
      }

      if (parsedTemplateStep.type === 'reaction') {
        const content = parsedTemplateStep.metadata.content;
        if (content.eventType) {
          content.eventType = EventTypeModel.findEventTypeById(content.eventType, eventTypes).name;
          if (!content.eventType.startsWith('$')) {
            content.eventType = null;
          }
        }
      }

      return parsedTemplateStep;
    });

    return this.caseStyleHelper.keysToUnderscore(template);
  }
}

import { HashMap, TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Assets, Container, Rectangle, Sprite, Texture } from 'pixi.js';
import { from, take } from 'rxjs';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { AbsBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.view';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { AutomessageBlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { AtlasTexture, ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';
import { PREVIEW_MAX_HEIGHT_PX } from '@panel/app-old/shared/services/message-preview-image/message-preview-image.constants';

export type TRIGGER_CHAIN_AUTOMESSAGE_BLOCK_TYPE =
  | TRIGGER_CHAIN_BLOCK_TYPE.POPUP
  | TRIGGER_CHAIN_BLOCK_TYPE.EMAIL
  | TRIGGER_CHAIN_BLOCK_TYPE.CHAT
  | TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE
  | TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH
  | TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK;

/** View шага "Сообщение в чат" */
export class AutomessageView extends AbsBlockView<TRIGGER_CHAIN_AUTOMESSAGE_BLOCK_TYPE> {
  constructor(
    data: AutomessageBlockViewData,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    transloco: TranslocoService,
    renderer: Renderer,
  ) {
    super(data, interactiveBlockPartViewFactory, transloco, renderer);
  }

  /** Рендерит контент блок */
  renderContent(): Container {
    let preview = this.data.isContentEmpty ? this.renderPlaceholder() : this.renderPreview();
    let action = this.renderInteractiveBlock();

    return ElementBuilder.wrapElementsInRectangle([preview, action.graphicContainer], {
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 10,
      },
    });
  }

  private renderInteractiveBlock() {
    if (this.interactiveBlockParts.length === 0) {
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.AUTOMESSAGE_SENT, this);
    } else {
      this.interactiveBlockParts.forEach((el) => el.rerender());
    }

    return this.interactiveBlockParts[0];
  }

  private renderPreview(): Container {
    let { url, heightPx } = this.data.previewImage;
    let text = this.t('preview.uploaded');
    const hasAnActualPreview = !!url && !!heightPx;

    if (hasAnActualPreview) {
      from(Assets.load(url))
        .pipe(take(1))
        .subscribe((texture: Texture) => {
          preview.removeChild(previewText);

          const scaleMultiplier = texture.width / TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX;

          sprite.texture = new Texture(
            texture.baseTexture,
            new Rectangle(0, 0, texture.width, Math.min(texture.height, PREVIEW_MAX_HEIGHT_PX * scaleMultiplier)),
          );
          sprite.width = TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX;
        });
    } else {
      text = this.t('preview.unsupported');
      heightPx = 136;
    }

    let previewText = CanvasRenderService.renderText(text, {
      color: HEX_COLOR.GRAY_650,
      wordWrap: true,
      wordWrapWidth: TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX - 40,
      textAlign: 'center',
    });

    previewText.position.set(
      (TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX - previewText.width) / 2,
      (heightPx - previewText.height) / 2,
    );

    let preview = new Container();

    let sprite = new Sprite();
    sprite.width = TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX;
    sprite.height = heightPx;

    preview.addChild(previewText);
    preview.addChild(sprite);

    return ElementBuilder.wrapElementsInRectangle([preview], {
      border: hasAnActualPreview
        ? undefined
        : {
            sideTextureName: 'border-gray800-272-side',
            innerTextureName: 'border-gray800-272-inner',
          },
      placement: {
        flexDirection: 'column',
      },
    });
  }

  private renderPlaceholder() {
    const text = CanvasRenderService.renderText(this.t('placeholder.text'), {
      color: HEX_COLOR.GRAY_650,
      wordWrap: false,
      textAlign: 'center',
    });

    return ElementBuilder.wrapElementsInRectangle([text], {
      border: {
        sideTextureName: 'border-gray800-272-side',
        innerTextureName: 'border-gray800-272-inner',
      },
      placement: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 136,
      },
    });
  }

  getTitleIconCode(): string {
    switch (this.data.type) {
      case TRIGGER_CHAIN_BLOCK_TYPE.CHAT:
        return UNICODE_ICON['cqi-comment-out-filled'];
      case TRIGGER_CHAIN_BLOCK_TYPE.POPUP:
        return UNICODE_ICON['cqi-popup-big'];
      case TRIGGER_CHAIN_BLOCK_TYPE.EMAIL:
        return UNICODE_ICON['cqi-envelope'];
      case TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE:
        return UNICODE_ICON['cqi-telegram'];
      case TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH:
        return UNICODE_ICON['cqi-mobile-push'];
      case TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK:
        return UNICODE_ICON['cqi-webhook'];
    }
  }

  getTitleIconBackgroundTextureIfDefault(): AtlasTexture {
    return 'circle-background-white-15';
  }

  getTitleIconBackgroundTextureIfSelected(): AtlasTexture {
    return 'circle-background-gray400-15';
  }

  getTitleIconBorderTextureIfDefault(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfSelected(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfIncomingConnectionsInvalid(): AtlasTexture {
    return 'circle-border-danger600-15';
  }

  getTitleIconColorIfDefault(): number {
    return HEX_COLOR.GRAY_400;
  }

  getTitleIconColorIfSelected(): number {
    return HEX_COLOR.WHITE;
  }

  private t(key: string, params?: HashMap): string {
    return this.transloco.translate(`triggerChainBlock.views.automessage.${key}`, params);
  }
}

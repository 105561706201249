/** Типы статусов django-пользователя */
export enum DJANGO_USER_PRESENCE_TYPES {
  OFFLINE = 'offline',
  ONLINE = 'online',
}

/** Типы разрешений на действия */
export enum PERMISSIONS_FOR_ACTIONS {
  PERMISSIONS_EXPORT = 'permissions_export',
  PERMISSIONS_SEND_BULK = 'permissions_send_bulk',
}

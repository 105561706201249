import { InjectionToken } from '@angular/core';

import { App } from '@http/app/app.model';
import { EventType, UserProperty } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';

/**
 * Тип данных, передаваемых в модалку создания ивента
 */
export type CreateAutoEventModalData = {
  autoEvents: AutoEvent[];
  currentApp: App;
  userProps: UserProperty[];
  eventTypes: EventType[];
};

/**
 * Токен, чтоб через DI передать в модалку создания ивента данные
 */
export const CREATE_AUTO_EVENT_MODAL_DATA_TOKEN = new InjectionToken<CreateAutoEventModalData>(
  'CreateAutoEventModal data provider',
);

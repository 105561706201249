import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { IntegrationService } from '@http/integration/services/integration.service';
import { VkGroupsChoiceModule } from '@panel/app/pages/integrations/content/vk/content/groups-choice/vk-groups-choice.module';
import { VkOAuthContainerModule } from '@panel/app/pages/integrations/content/vk/content/o-auth-container/vk-o-auth-container.module';
import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { IntegrationsPartialsModule } from '@panel/app/pages/integrations/partials/integrations-partials.module';
import { OAuthButtonModule } from '@panel/app/pages/integrations/partials/o-auth-button/o-auth-button.module';
import { SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

import { VkNewComponent } from './vk-new.component';

@NgModule({
  declarations: [VkNewComponent],
  exports: [VkNewComponent],
  imports: [
    CommonModule,
    IntegrationNameControlModule,
    IntegrationsPartialsModule,
    ReactiveFormsModule,
    TranslocoModule,
    UIRouterModule,
    VkGroupsChoiceModule,
    VkOAuthContainerModule,
    OAuthButtonModule,
  ],
  providers: [IntegrationFactory, IntegrationService, SystemToastService, ToastService],
})
export class VkNewModule {}

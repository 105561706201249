/**
 * Карточка пользователя
 */
(function () {
  'use strict';

  require('./user-card.controller');

  angular.module('myApp.userCard').component('cqUserCard', cqUserCard());

  function cqUserCard() {
    return {
      bindings: {
        billingInfo: '=',
        close: '&?',
        currentApp: '=',
        djangoUser: '=',
        onOpenConversationClick: '<?', // функция, вызываемая при нажатии на кнопку перехода в диалог
        onRedirectConversationClick: '<?', // нужно ли после нажатия на кнопку нужно перенаправить в раздел диалоги
        updateActiveConversation: '&?', // TODO Возможно это отсюда можно убрать в рамках рефакторинга
        telegramIntegrations: '=',
        userId: '=',
      },
      controller: 'CqUserCardController',
      controllerAs: 'vm',
      template: require('./user-card.html'),
    };
  }
})();

<div
  *ngIf="!dataIsLoad"
  class="flex flex-col flex-grow full-height"
>
  <!--Поиск-->
  <form
    class="flex no-flex-shrink align-items-center padding-right-10 bordered-bottom"
    [formGroup]="searchForm"
    novalidate
    (submit)="search(searchForm.valid, articlesSearch)"
  >
    <div class="form-group has-feedback has-feedback-left no-margin flex-grow">
      <i class="cqi-sm cqi-search form-control-feedback text-right text-muted"></i>
      <input
        class="form-control no-border"
        autocomplete="off"
        autofocus
        (click)="$event.stopPropagation(); trackClickSearchInput()"
        [formControl]="searchForm.controls.search"
        placeholder="{{ 'knowledgeBasePopover.searchInput.placeholder' | transloco }}"
        (keydown.enter)="search(searchForm.valid, articlesSearch)"
      />
      <cq-validation-messages [control]="searchForm.controls.search">
        <cq-validation-message errorName="maxlength">
          {{ 'knowledgeBasePopover.searchInput.errors.maxlength' | transloco: { maxLength: MAX_SEARCH_LENGTH } }}
        </cq-validation-message>
        <cq-validation-message errorName="minlength">
          {{ 'knowledgeBasePopover.searchInput.errors.minlength' | transloco: { minLength: MIN_SEARCH_LENGTH } }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <button
      *ngIf="isSearch"
      class="btn btn-text-primary"
      type="button"
      (click)="$event.stopPropagation(); resetSearch()"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </form>
  <overlay-scrollbars
    #overlayScrollbars
    class="flex-grow os-host-flexbox"
  >
    <!--Список категорий-->
    <div *ngIf="!currentCategory && !isSearch">
      <div *ngIf="categoriesList.length > 0">
        <h4
          class="padding-left-20 no-margin-bottom margin-top-20"
          [innerHTML]="'knowledgeBasePopover.categories' | transloco"
        ></h4>
        <div
          class="flex align-items-center padding-20 bordered-bottom cursor-pointer"
          (click)="$event.stopPropagation(); trackClickCategory(category.name); selectCategory(category)"
          *ngFor="let category of categoriesList; trackBy: trackByCategoryId"
        >
          <div
            class="category-icon margin-right-20"
            [innerHTML]="category.icon"
          ></div>
          <div>
            <div
              class="text-break font-weight-bold"
              [innerHTML]="category.name"
            ></div>
            <div>
              {{ 'knowledgeBasePopover.articlesCount' | transloco: { articlesCount: category.articlesCounter } }}
            </div>
          </div>
        </div>
      </div>
      <!--ZERO DATA-->
      <div
        *ngIf="categoriesList.length === 0"
        class="margin-top-50 text-center"
      >
        <img
          class="margin-bottom-20"
          src="assets/img/default/book.png"
        />
        <h4
          class="margin-bottom-20"
          [innerHTML]="'knowledgeBasePopover.zeroData.noCategories.description' | transloco"
        ></h4>
        <a
          class="btn btn-primary"
          target="_blank"
          uiSref="app.content.knowledge-base.categories"
          (click)="$event.stopPropagation(); trackClickCreateArticle()"
          [innerHTML]="'knowledgeBasePopover.zeroData.noCategories.createArticleButton' | transloco"
        ></a>
      </div>
    </div>

    <!--Список статей-->
    <div *ngIf="currentCategory && !isSearch">
      <div *ngIf="articlesListByCategory.length > 0">
        <h4 class="padding-left-20 no-margin-bottom margin-top-20">{{ currentCategory.name }}</h4>
        <ng-container *ngFor="let article of articlesListByCategory; trackBy: trackByArticleId; last as last">
          <div
            class="padding-20 cursor-pointer"
            [ngClass]="{ 'bordered-bottom': !last }"
            (click)="$event.stopPropagation(); trackClickArticle(article.name); openPreviewModal(article.id)"
          >
            <div class="text-break font-weight-bold">{{ article.name }}</div>
            <div class="text-break">{{ article.shortDescription }}</div>
          </div>
        </ng-container>
      </div>
      <!--ZERO DATA-->
      <div
        *ngIf="articlesListByCategory.length === 0"
        class="margin-top-50 text-center"
      >
        <img
          class="margin-bottom-20"
          src="assets/img/default/book.png"
        />
        <h4>{{ 'knowledgeBasePopover.zeroData.noArticles' | transloco }}</h4>
      </div>
    </div>

    <!--Список найденных статей-->
    <div *ngIf="isSearch">
      <div *ngIf="searchedArticlesList.length > 0">
        <h4 class="padding-left-20 no-margin-bottom margin-top-20">
          {{ 'knowledgeBasePopover.searchResult' | transloco: { pattern: searchPattern } }}
        </h4>
        <ng-container *ngFor="let article of searchedArticlesList; trackBy: trackByArticleId; last as last">
          <div
            class="padding-20 cursor-pointer"
            [ngClass]="{ 'bordered-bottom': !last }"
            (click)="$event.stopPropagation(); openPreviewModal(article.id)"
          >
            <div class="font-weight-bold">{{ article.name }}</div>
            <div>{{ article.quote }}</div>
          </div>
        </ng-container>
      </div>
      <!--ZERO DATA-->
      <div
        *ngIf="searchedArticlesList.length === 0"
        class="margin-top-50 text-center"
      >
        <img
          class="margin-bottom-20"
          src="assets/img/default/not-found.png"
        />
        <h4>{{ 'knowledgeBasePopover.zeroData.noSearchedArticles.heading' | transloco }}</h4>
        <div>
          <span>{{ 'knowledgeBasePopover.zeroData.noSearchedArticles.description' | transloco }}</span>
          <a
            class="inline-block cursor-pointer"
            (click)="$event.stopPropagation(); resetSearch()"
            >{{ 'knowledgeBasePopover.zeroData.noSearchedArticles.clearSearch' | transloco }}</a
          >
        </div>
      </div>
    </div>
  </overlay-scrollbars>

  <div
    *ngIf="currentCategory && !isSearch"
    class="unselect-category-container bordered-top text-center"
  >
    <button
      class="btn btn-borderless-primary"
      (click)="$event.stopPropagation(); selectCategory(null)"
    >
      <i class="btn-icon-left cqi-sm cqi-arrow-left"></i>
      <span>{{ 'knowledgeBasePopover.backwardsButton' | transloco }}</span>
    </button>
  </div>
</div>
<cq-loader *ngIf="dataIsLoad"></cq-loader>

<section id="content">
  <cq-account-header [djangoUser]="djangoUser"></cq-account-header>

  <div class="container-fluid limited-container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ 'account.header.breadcrumbs.account' | transloco }}
        </li>
      </ol>
    </nav>

    <ul
      #nav="ngbNav"
      ngbNav
      [(activeId)]="currentTab"
      class="nav nav-tabs"
    >
      <li [ngbNavItem]="ACCOUNT_TABS.APPS">
        <a ngbNavLink>{{ 'account.tabs.' + ACCOUNT_TABS.APPS | transloco }}</a>
        <ng-template ngbNavContent
          ><div class="card-body block">
            <cq-apps
              [appList]="appList"
              [djangoUser]="djangoUser"
            ></cq-apps></div
        ></ng-template>
      </li>

      <li [ngbNavItem]="ACCOUNT_TABS.PROFILE">
        <a ngbNavLink>{{ 'account.tabs.' + ACCOUNT_TABS.PROFILE | transloco }}</a>
        <ng-template ngbNavContent
          ><div class="card-body block">
            <cq-profile
              [appList]="appList"
              [djangoUser]="djangoUser"
            ></cq-profile></div
        ></ng-template>
      </li>

      <li [ngbNavItem]="ACCOUNT_TABS.PASSWORD_CHANGE">
        <a ngbNavLink>{{ 'account.tabs.' + ACCOUNT_TABS.PASSWORD_CHANGE | transloco }}</a>
        <ng-template ngbNavContent>
          <div class="card-body block">
            <cq-password-change></cq-password-change>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="ACCOUNT_TABS.NOTIFICATIONS">
        <a ngbNavLink>{{ 'account.tabs.' + ACCOUNT_TABS.NOTIFICATIONS | transloco }}</a>
        <ng-template ngbNavContent>
          <div class="card-body block">
            <cq-notification-settings
              [settingsExternal]="notifications"
              [appList]="appList"
              (settingsChange)="onNotificationSettingsChange($event)"
            ></cq-notification-settings>
          </div>
        </ng-template>
      </li>

      <div
        *ngIf="isWebApp"
        class="margin-left-auto"
      >
        <button
          class="btn btn-sm btn-outline-primary"
          (click)="trackClickGetDemo(); openGetDemoModal()"
          ngbTooltip="{{ 'account.header.requestDemoTooltip' | transloco }}"
          placement="left"
        >
          <i class="btn-icon-left cqi-sm cqi-lightning"></i>
          <span>{{ 'account.header.requestDemoButton' | transloco }}</span>
        </button>
      </div>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</section>

<cq-loader
  *ngIf="stateParams.createAppLoading"
  class="fullsize-background-create-app"
>
  <div class="loader-text-container">
    <div class="loader-text text-1">{{ 'account.createAppTexts.text1' | transloco }}</div>
    <div class="loader-text text-2">{{ 'account.createAppTexts.text2' | transloco }}</div>
    <div class="loader-text text-3">{{ 'account.createAppTexts.text3' | transloco }}</div>
  </div>
</cq-loader>

<cq-toast
  aria-atomic="true"
  aria-live="polite"
></cq-toast>

import { TriggerChainContentEditorModal } from './content-editor/modal/trigger-chain-content-editor.modal';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TriggerChainTrackService } from '../../../app/pages/trigger-chains/shared/services/track-service/trigger-chain-track.service';

/**
 * Модуль для работы с триггерными цепочками
 */
(function () {
  'use strict';

  require('./content-editor/automessage/trigger-chain-automessage-editor.module');
  require('./content-editor/sending-conditions/trigger-chain-sending-conditions-editor.module');
  require('./detailed-statistics/trigger-chain-detailed-statistics.module');

  angular
    .module('myApp.triggerChains', [
      'myApp.triggerChains.automessageEditor',
      'myApp.triggerChains.detailedStatistics',
      'myApp.triggerChains.sendingConditionsEditor',
    ])
    .service('triggerChainContentEditorModal', TriggerChainContentEditorModal)
    .service('triggerChainTrackService', downgradeInjectable(TriggerChainTrackService));

  require('./trigger-chains.routes');
})();

/**
 * Контроллер "Адреса страницы"
 */
(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventPageUrlController', CqAutoEventPageUrlController);

  function CqAutoEventPageUrlController(validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.validationHelper = validationHelper;
    }
  }
})();

import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { ToastService } from './toast-service';

@Component({
  selector: 'cq-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  @HostBinding('class')
  class = 'toast-container';

  constructor(public toastService: ToastService) {}
}

(function () {
  'use strict';

  require('./message-senders-table.controller');
  require('./append-app-name-popover.html');
  require('./append-signature-popover.html');

  angular.module('myApp.messageSenders').component('cqMessageSendersTable', cqMessageSendersTable());

  function cqMessageSendersTable() {
    return {
      bindings: {
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // текущее приложение
        messageSenders: '=', // список отправителей
      },
      controller: 'CqMessageSendersTableController',
      controllerAs: 'vm',
      template: require('./message-senders-table.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ConversationPartButton } from '@http/conversation-part/internal-types';

/** Preview кнопок в реплике */
@Component({
  selector: 'cq-conversation-part-preview-buttons[buttons]',
  templateUrl: './conversation-part-preview-buttons.component.html',
  styleUrls: ['./conversation-part-preview-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPartPreviewButtonsComponent {
  /** Кнопки */
  @Input()
  buttons!: ConversationPartButton[];
}

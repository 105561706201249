import { Environment } from '@environments/types';
import { overridesPartial } from '@environments/partials/overrides';
import { k8sLocationPartial } from '@environments/partials/locations/k8s';
import { carrotQuestProjectPartial } from '@environments/partials/projects/carrot-quest';
import { k8sModePartial } from '@environments/partials/modes/k8s';

export const environment: Environment = {
  ...k8sLocationPartial,
  ...carrotQuestProjectPartial,
  ...k8sModePartial,
  ...overridesPartial,
};

(function () {
  'use strict';

  angular.module('myApp.trackMaster', []);

  require('./track-master.routes');
  require('./auto-event-modal/auto-event-modal.component');
  require('./auto-event-modal/click/track-master-click.component');
  require('./auto-event-modal/input/track-master-input.component');
  require('./auto-event-modal/url/track-master-url.component');
  require('./auto-event-modal/url-scroll/track-master-url-scroll.component');
  require('./auto-event-setting/base-auto-events-wrapper/base-auto-events-wrapper.component');
  require('./auto-event-setting/click/track-master-click.component');
  require('./auto-event-setting/input/track-master-input.component');
  require('./auto-event-setting/pro/track-master-pro.component');
  require('./auto-event-setting/url/track-master-url.component');
  require('./auto-event-setting/url-scroll/track-master-url-scroll.component');
  require('./auto-event-setting/auto-event-setting.component');
  require('./auto-tracking/auto-tracking.component');
  require('./blocks/css-selector/auto-event-css-selector.component');
  require('./blocks/delay/auto-event-delay.component');
  require('./blocks/header/auto-event-header.component');
  require('./blocks/name/auto-event-name.component');
  require('./blocks/page-url/auto-event-page-url.component');
  require('./blocks/properties/auto-event-properties.component');
  require('./blocks/properties-select/auto-event-properties-select.component');
  require('./blocks/type/auto-event-type.component');
  require('./blocks/video-collapsing/auto-event-video-collapsing.component');
  require('./blocks/work-url/auto-event-work-url.component');
  require('./blocks/write-property/auto-event-write-property.component');
  require('./header/header.component');
  require('./js-code/js-code.component');
})();

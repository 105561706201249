import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { FilterNextBranchOptionsPipe } from '@panel/app/pages/chat-bot/content/branch-editor/partials/bot-action-next-branch-selector/filter-next-branch-options.pipe';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BotActionNextBranchSelectorComponent } from './bot-action-next-branch-selector.component';

@NgModule({
  declarations: [BotActionNextBranchSelectorComponent, FilterNextBranchOptionsPipe],
  imports: [CommonModule, TranslocoModule, SharedModule, NgSelectModule, ReactiveFormsModule, NgbTooltipModule],
  exports: [BotActionNextBranchSelectorComponent],
})
export class BotActionNextBranchSelectorModule {}

<ng-container *transloco="let t; read: 'socialSettingsComponent'">
  <label>{{ t('label') }}</label>
  <p>{{ t('description') }}</p>
  <div
    [config]="sortablejsOptions"
    [nxtSortablejs]="$any(orderSocials)"
  >
    <ng-container *ngFor="let social of orderSocials">
      <cq-social-network
        [attr.data-test]="'social-network-' + social"
        [socialName]="social"
        [ngModel]="getSocialSettingByName(social)"
        (ngModelChange)="onChangeSettings($event, social)"
      ></cq-social-network>
    </ng-container>
  </div>
</ng-container>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { tuiPure } from '@taiga-ui/cdk';

import { PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { ConversationModel } from '@http/conversation/conversation.model';
import { Conversation } from '@http/conversation/conversation.types';
import { ConversationPart } from '@http/conversation-part/conversation-part.types';

@Component({
  selector: 'cq-conversation-card[conversation]',
  templateUrl: './conversation-card.component.html',
  styleUrls: ['./conversation-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationCardComponent implements OnChanges {
  @Input()
  conversation!: Conversation;

  PSEUDO_CHANNEL_TYPES = PSEUDO_CHANNEL_TYPES;

  /** Лимит выводимых тегов, выявлен экспериментально, больше такого количества самых коротких тегов в карточку диалога не входит */
  TAGS_LIMIT = 5;

  constructor(private readonly cdr: ChangeDetectorRef, readonly conversationModel: ConversationModel) {}

  // HACK: вся свистопляска ниже из-за того, что аватарка пользователя получается асинхронно, но почти нигде это не учитывается
  // Этот факт вместе с changeDetection OnPush приводит к тому, что если ава не успела загрузиться до инициализации компонента - вместо неё будет выводиться undefined
  // Поэтому пришлось написать костыль, чтобы при отсутствии аватарки, компонент дожидался её
  // Этот хак можно будет полностью удалить, когда аватарка будет получаться синхронно, либо когда везде будет учитываться асинхронность её получения
  ngOnChanges(changes: SimpleChanges): void {
    let watchAvatarTimeout: NodeJS.Timer;

    const watchAvatar = () => {
      if (this.conversation?.user.avatar) {
        watchAvatarTimeout && clearInterval(watchAvatarTimeout);
        this.cdr.markForCheck();
      }
    };

    if (changes.conversation && changes.conversation.firstChange && !changes.conversation.currentValue.user.avatar) {
      watchAvatarTimeout = setInterval(() => watchAvatar(), 500);
    }
  }

  /** Правильная реплика диалога */
  get correctPart(): ConversationPart {
    return this.getCorrectPart(this.conversation);
  }

  /**
   * Получение правильной реплики диалога
   * При поиске по диалогам следует брать всю информацию из found_part, т.е. из той реплики, которая была найдена
   * В обычной ситуации информацию нужно брать из important_part_last
   *
   * @param conversation Диалог
   */
  @tuiPure
  getCorrectPart(conversation: Conversation): ConversationPart {
    if (conversation.isSearching) {
      // по идее, если isSearching === true, то и found_part точно должна существовать
      // но как это сделать так, чтобы TS не ругался - я не понял, поэтому добавил ещё одну проверку, на всякий случай
      if (conversation.found_part) {
        return conversation.found_part;
      }
    }

    return conversation.important_part_last;
  }

  /** Трекинг по тегу */
  trackByTag(index: number, tag: string): string {
    return tag;
  }
}

<ng-container *transloco="let t; read: 'userMainPropsComponent'">
  <div class="d-flex flex-column">
    <div class="flex align-items-center user-info padding-10 padding-left-15 position-relative">
      <div
        class="flex flex-grow-1 overflow-hidden"
        (click)="openUserCard()"
      >
        <cq-user-avatar
          class="align-self-start"
          [avatar]="user.avatar"
          [status]="user.presence"
        ></cq-user-avatar>

        <div class="flex align-items-center padding-left-10 overflow-hidden">
          <div
            *ngIf="canOpenUserCard"
            class="property text-primary text-truncate"
            [innerText]="user.name"
            [ngClass]="{ muted: !user.props.$name }"
            data-test="user-card-user-name-button"
          ></div>
          <span
            *ngIf="!canOpenUserCard"
            class="property text-truncate"
            [innerText]="user.name"
            [ngClass]="{ muted: !user.props.$name }"
            data-test="user-card-user-name-span"
          ></span>
        </div>
      </div>
      <div class="d-flex align-items-center edit-user-name margin-right-5">
        <button
          class="btn hide btn-secondary edit-user-name-btn"
          (click)="openEditUserPropertyModal('$name', user.props.$name)"
          data-test="user-card-user-name-edit-button"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-pencil"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'dialogStartComponent'">
  <label class="margin-bottom-10">{{ t('label') }}</label>
  <div class="custom-control custom-radio margin-bottom-10">
    <input
      class="custom-control-input"
      [formControl]="formControl"
      id="create-one-dialog"
      type="radio"
      [value]="false"
      name="dialog-start"
      data-test="dialog-radio-true"
    />
    <label
      class="custom-control-label"
      for="create-one-dialog"
      >{{ t('radio.createOneDialog') }}</label
    >
    <i
      class="cqi-sm cqi-question-circle"
      placement="right"
      [ngbTooltip]="t('tooltip.createOneDialog')"
    ></i>
  </div>
  <div class="custom-control custom-radio">
    <input
      class="custom-control-input"
      [formControl]="formControl"
      id="create-new-dialog"
      type="radio"
      [value]="true"
      name="dialog-start"
    />
    <label
      class="custom-control-label"
      for="create-new-dialog"
      data-test="dialog-radio-false"
      >{{ t('radio.createNewDialog') }}</label
    >
    <i
      class="cqi-sm cqi-question-circle"
      placement="right"
      [ngbTooltip]="t('tooltip.createNewDialog')"
    ></i>
  </div>
</ng-container>

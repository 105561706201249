export type HexColor = typeof HEX_COLOR[keyof typeof HEX_COLOR];

/**
 * Цвета и именования на основе
 * https://www.figma.com/file/RxKE4i7n3qR8byetzwEIGj/Admin-Panel-Styles-%26-Grids?type=design&node-id=702-42&mode=design&t=xHGBgoo3ZkmtrteJ-4
 *
 * Если ссылка не открывается, спрашивайте у дизайнеров фигму с палитрой цветов панельки
 */
export const HEX_COLOR = {
  DANGER_400: 0xcc4400,
  DANGER_600: 0xff7733,
  DANGER_950: 0xffeee5,
  GRAY_150: 0x22252a,
  GRAY_400: 0x5c6370,
  GRAY_650: 0x9da3af,
  GRAY_800: 0xc7cad1,
  GRAY_900: 0xe3e5e8,
  GRAY_950: 0xf1f2f4,
  PRIMARY_400: 0x2929a3,
  PRIMARY_600: 0x5c5cd6,
  PRIMARY_950: 0xebebfa,
  SUCCESS_400: 0x339933,
  SUCCESS_600: 0x66cc66,
  SUCCESS_950: 0xecf9ec,
  WARNING_400: 0xcc8800,
  WARNING_600: 0xffbb33,
  WARNING_950: 0xfff6e5,
  WHITE: 0xffffff,
} as const;

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DATA_SOURCE, DATA_SOURCE_STATUS, FILTER_DEFAULT_VALUES } from '@http/ai-data/ai-data.constants';
import { AiData } from '@http/ai-data/internal-types/ai-data.internal.type';
import { ArticleCategory } from '@http/article-category/internal-types/article-category.internal.type';

@Injectable()
export class AiDataSourcesListStateService {
  readonly dataSourceList$: BehaviorSubject<AiData[]> = new BehaviorSubject<AiData[]>([]);
  readonly isSelectedAll$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly selectedDataSources$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  readonly knowledgeBaseCategories$: BehaviorSubject<ArticleCategory[]> = new BehaviorSubject<ArticleCategory[]>([]);

  // Фильтры в таблице
  readonly selectedStatus$: BehaviorSubject<DATA_SOURCE_STATUS> = new BehaviorSubject<DATA_SOURCE_STATUS>(
    FILTER_DEFAULT_VALUES.STATUS,
  );
  readonly selectedSource$: BehaviorSubject<DATA_SOURCE> = new BehaviorSubject<DATA_SOURCE>(
    FILTER_DEFAULT_VALUES.SOURCE,
  );
  readonly selectedCategory$: BehaviorSubject<string> = new BehaviorSubject<string>(FILTER_DEFAULT_VALUES.CATEGORY);
  readonly searchPhrase$: BehaviorSubject<string> = new BehaviorSubject<string>(FILTER_DEFAULT_VALUES.SEARCH_PHRASE);

  // Метод для выделения/снятия выделения всех элементов
  toggleSelectAll(newValue: boolean): void {
    this.isSelectedAll$.next(newValue);

    const updatedDataSources = this.dataSourceList$.getValue().map((dataSource) => ({
      ...dataSource,
      checked: newValue,
    }));
    this.dataSourceList$.next(updatedDataSources);

    // Обновляем список выделенных источников
    const selectedIds = newValue ? updatedDataSources.map((dataSource) => dataSource.contentId) : [];
    this.selectedDataSources$.next(selectedIds);
  }

  // Метод для обновления статуса checked элемента и списка выделенных элементов
  toggleDataSourceSelection(dataSourceId: string, newValue: boolean): void {
    const currentDataSources = this.dataSourceList$.getValue();
    let updatedDataSources = [...currentDataSources];
    let selectedIds = this.selectedDataSources$.getValue();

    updatedDataSources = updatedDataSources.map((dataSource) => {
      if (dataSource.contentId === dataSourceId) {
        dataSource.checked = newValue;
        selectedIds = dataSource.checked
          ? [...selectedIds, dataSource.contentId]
          : selectedIds.filter((id) => id !== dataSource.contentId);
      }
      return dataSource;
    });

    this.dataSourceList$.next(updatedDataSources);
    this.selectedDataSources$.next(selectedIds);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConversationTagsModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Получение списка тегов диалогов приложения
   *
   * @param appId ID приложения
   */
  getList(appId: string) {
    return this.http.get('/apps/' + appId + '/conversationtags');
  }

  /**
   * Удаления тега диалогов приложения
   *
   * @param appId ID приложения
   * @param tag Название тега
   */
  remove(appId: string, tag: string) {
    const body = {
      tag,
    };

    return this.http.delete('/apps/' + appId + '/conversationtags', { body });
  }
}

import { Container } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { TRIGGER_CHAIN_BLOCK_CONTENT_INDENT } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { TRIGGER_CHAIN_AUTOMESSAGE_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/views/automessage.view';
import { ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

/**
 * View экшена "Сообщение отправлено"
 */
export class AutomessageSentView extends AbsInteractiveBlockPartView<TRIGGER_CHAIN_AUTOMESSAGE_BLOCK_TYPE> {
  /** Тип */
  type: INTERACTIVE_BLOCK_PART = INTERACTIVE_BLOCK_PART.AUTOMESSAGE_SENT;

  hasConnection(): boolean {
    return this.stepView.data.nextStepConnected;
  }

  renderContent(): Container {
    const icon = CanvasRenderService.renderIcon(UNICODE_ICON['cqi-paper-plane-o'], {
      color: HEX_COLOR.SUCCESS_400,
      fontSize: 15,
    });
    let text = CanvasRenderService.renderText(
      this.transloco.translate('interactiveBlockPart.automessageSent.whenMessageSent'),
      {
        color: HEX_COLOR.SUCCESS_400,
        wordWrap: false,
      },
    );

    const textAndIcon = ElementBuilder.wrapElementsInRectangle([icon, text], {
      placement: {
        marginBetweenCols: 5,
      },
    });

    const el = ElementBuilder.wrapElementsInRectangle([textAndIcon], {
      background: {
        sideTextureName: 'background-success950-272-side',
        innerTextureName: 'background-success950-272-inner',
      },
      placement: {
        alignItems: 'center',
        justifyContent: 'start',
        padding: {
          x: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
          y: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
        },
      },
    });

    this.addAndPositionConnectionPoint(el);

    return el;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChatWidgetButtonTextModule } from '@panel/app/pages/widget-bot/editor/components/button-text/chat-widget-button-text.module';
import { ChatWidgetHeaderModule } from '@panel/app/pages/widget-bot/editor/components/header/chat-widget-header.module';
import { ChatWidgetInsertingIntoChatModule } from '@panel/app/pages/widget-bot/editor/components/inserting-into-chat-toggle/chat-widget-inserting-into-chat.module';
import { ActiveChatWidgetOrderModule } from '@panel/app/pages/widget-bot/editor/components/order/active-chat-widget-order.module';
import { ChatWidgetOverheaderModule } from '@panel/app/pages/widget-bot/editor/components/overheader/chat-widget-overheader.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ChatWidgetButtonTextModule,
    ChatWidgetInsertingIntoChatModule,
    ChatWidgetHeaderModule,
    ActiveChatWidgetOrderModule,
    ChatWidgetOverheaderModule,
  ],
})
export class WidgetBotEditorModule {}

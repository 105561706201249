(function () {
  'use strict';

  angular.module('myApp.users').controller('CqLeadsImportFinishedController', CqLeadsImportFinishedController);

  function CqLeadsImportFinishedController(carrotquestHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.trackClickToUsersPage = trackClickToUsersPage;
    }

    /**
     * Получение имени типа импорта для трека событий
     *
     * @param {IMPORT_TYPES} importType - Тип импорта
     */
    function getImportNameForTrack(importType) {
      return importType === vm.importTypes.LEADS ? 'Импорт лидов' : 'Импорт пользователей';
    }

    /**
     * Трек клика по кнопке "Перейти в лиды"
     */
    function trackClickToUsersPage() {
      carrotquestHelper.track('Импорт лидов - импорт запущен - клик по ссылке "Переход в лиды"', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }
  }
})();

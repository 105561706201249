/** Help-сервис для работы с квотами */
export class QuotasHelpService {
  /**
   * Получение количества перерасхода emails
   *
   * @param available - Доступное количество
   * @param spent - Потраченное количество
   */
  static getOverspendingEmails(available: number, spent: number): number {
    let overspending = 0;

    if (spent > available) {
      return spent - available;
    }

    return overspending;
  }

  /**
   * Получение количества перерасхода уникальных пользователей
   *
   * @param available - Доступное количество
   * @param spent - Потраченное количество
   */
  static getOverspendingUniqueUsers(available: number, spent: number): number {
    let overspending = 0;

    if (spent > available) {
      return spent - available;
    }

    return overspending;
  }
}

<div class="modal-header">
  <h3 class="modal-title">{{ 'modals.assignNewSuperAdmin.title' | transloco }}</h3>
  <button
    class="close"
    (click)="ngbActive.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body">
  <div [innerHtml]="modalWindowParams.body"></div>
  <div>
    <ng-select
      class="team-members-select"
      [searchable]="false"
      [clearable]="false"
      [items]="availableTeamMembers"
      [formControl]="newSuperAdminForm.controls.newSuperAdmin"
      bindValue="id"
      bindLabel="name"
      placeholder="{{ 'modals.assignNewSuperAdmin.newSuperAdminForm.newSuperAdminSelect.placeholder' | transloco }}"
    >
      <ng-template
        ng-label-tmp
        ng-option-tmp
        let-item="item"
      >
        <img
          class="avatar margin-right-5"
          [src]="item.avatar"
        />
        <span class="align-middle">
          <span>{{ item.name }}</span>
          <span>{{ '(' + item.email + ')' }}</span>
        </span>
      </ng-template>
    </ng-select>

    <cq-validation-messages [control]="newSuperAdminForm.controls.newSuperAdmin">
      <cq-validation-message errorName="required">
        {{ 'modals.assignNewSuperAdmin.newSuperAdminForm.newSuperAdminSelect.errors.required' | transloco }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>

  <div
    *ngIf="modalWindowParams.currentAppName"
    class="margin-top-20"
    [cqShowError]="newSuperAdminForm.controls.confirmation"
  >
    <div
      class="margin-bottom-5"
      [innerHTML]="'modals.assignNewSuperAdmin.confirmText' | transloco: { appName: modalWindowParams.currentAppName }"
    ></div>
    <input
      class="form-control"
      type="text"
      [formControl]="newSuperAdminForm.controls.confirmation"
    />

    <cq-validation-messages [control]="newSuperAdminForm.controls.confirmation">
      <cq-validation-message errorName="required">
        {{ 'modals.assignNewSuperAdmin.confirmation.errors.required' | transloco }}
      </cq-validation-message>
      <cq-validation-message errorName="equal">
        {{ 'modals.assignNewSuperAdmin.confirmation.errors.equal' | transloco }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-outline-primary"
    (click)="ngbActive.dismiss()"
  >
    {{ 'general.cancel' | transloco }}
  </button>
  <button
    class="btn btn-primary"
    type="button"
    [disabled]="newSuperAdminForm.invalid"
    (click)="setSuperAdmin()"
  >
    {{ 'modals.assignNewSuperAdmin.confirmButton' | transloco }}
  </button>
</div>

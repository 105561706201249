<ng-container *transloco="let t; read: 'unansweredFilterComponent'">
  <label
    class="btn btn-outline-primary"
    [ngClass]="{ active: control.value, disabled: control.disabled }"
    [ngbTooltip]="t('tooltip', { count: count$ | async })"
    container="body"
  >
    <input
      type="checkbox"
      autocomplete="off"
      [formControl]="control"
    />
    <i
      class="btn-icon cqi-sm"
      [ngClass]="{ 'cqi-fire': control.value, 'cqi-fire-o': !control.value }"
    ></i>
  </label>
</ng-container>

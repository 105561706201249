<ng-container>
  <div class="container-fluid limited-container padding-bottom-60">
    <div class="d-flex justify-space-between py-20">
      <cq-trigger-chain-breadcrumbs></cq-trigger-chain-breadcrumbs>
      <cq-trigger-chain-additional-actions></cq-trigger-chain-additional-actions>
    </div>

    <cq-trigger-chain-table></cq-trigger-chain-table>
  </div>
</ng-container>

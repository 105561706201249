(function () {
  'use strict';

  angular.module('myApp.popupEditor', []);

  require('./popup-editor.component');
  require('./block-preview/popup-block-preview.component');
  require('./block-preview/button/popup-button-block-preview.component');
  require('./block-preview/cart/popup-cart-block-preview.component');
  require('./block-preview/footer/popup-footer-block-preview.component');
  require('./block-preview/html/popup-html-block-preview.component');
  require('./block-preview/image/popup-image-block-preview.component');
  require('./block-preview/indent/popup-indent-block-preview.component');
  require('./block-preview/input-with-big-button/popup-input-with-big-button-block-preview.component');
  require('./block-preview/input-with-button/popup-input-with-button-block-preview.component');
  require('./block-preview/text/popup-text-block-preview.component');
  require('./block-preview/video/popup-video-block-preview.component');
  require('./block-settings/popup-block-settings.component');
  require('./block-settings/button/popup-button-block-settings.component');
  require('./block-settings/cart/popup-cart-block-settings.component');
  require('./block-settings/footer/popup-footer-block-settings.component');
  require('./block-settings/html/popup-html-block-settings.component');
  require('./block-settings/image/popup-image-block-settings.component');
  require('./block-settings/indent/popup-indent-block-settings.component');
  require('./block-settings/input-with-big-button/popup-input-with-big-button-block-settings.component');
  require('./block-settings/input-with-button/popup-input-with-button-block-settings.component');
  require('./block-settings/text/popup-text-block-settings.component');
  require('./block-settings/video/popup-video-block-settings.component');
  require('./popup-preview/popup-preview.component');
  require('./popup-preview/popup-preview-content/popup-preview-content.component');
  require('./popup-settings/popup-settings.component');
})();

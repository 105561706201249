import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import FontFaceObserver from 'fontfaceobserver';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { Viewport } from 'pixi-viewport';
import { forkJoin, from, merge, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, first, map, takeUntil } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { Channel } from '@http/channel/channel.model';
import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotModel } from '@http/chat-bot/chat-bot.model';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { ChatBotTemplateModel } from '@http/chat-bot-template/chat-bot-template.model';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { FEATURE_LABELS_ONLY } from '@http/feature-label/feature-label.constants';
import { AmocrmIntegrationExternal } from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { CalendlyIntegrationExternal } from '@http/integration/integrations/calendly/interfaces/calendly-integration.interface';
import { EmailNotificationIntegrationExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import {
  LeaveSiteAttemptTriggerType,
  OpenedSdkPageTriggerType,
  OpenedWebPageTriggerType,
} from '@http/message/trigger.types';
import { Properties } from '@http/property/property.model';
import { Tag } from '@http/tag/tag.types';
import { TeamMember } from '@http/team-member/team-member.types';
import { BotBlocksOverlayService } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-blocks-overlay.service';
import { BlockNamingHelperService } from '@panel/app/pages/chat-bot/content/services/block-naming-helper.service';
import { BotBranchSelectService } from '@panel/app/pages/chat-bot/content/services/bot-branch-select.service';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ConnectionValidatorService } from '@panel/app/pages/chat-bot/content/services/connection-validator.service';
import { ActionFactory, BranchFactory, ConnectionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { BadgeFactory } from '@panel/app/pages/chat-bot/content/services/factories/badge.factory';
import { BootstrapElementsFactory } from '@panel/app/pages/chat-bot/content/services/factories/bootstrap-elements.factory';
import { pickOnlyEvent } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.helpers';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { PIXI_INTERACTION_EVENT } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.types';
import { OverlayBootstrapElementsHelper } from '@panel/app/pages/chat-bot/content/services/overlay-bootstrap-elements-helper.service';
import { PixiStatsLoggerService } from '@panel/app/pages/chat-bot/content/services/pixi-stats-logger.service';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { botTokenProviders, PIXI_VIEWPORT, VIEWPORT_ZOOM_SETTINGS } from '@panel/app/pages/chat-bot/content/tokens';
import { BaseBadge } from '@panel/app/pages/chat-bot/content/views/badges';
import { CalendlyEvent } from '@panel/app/pages/integrations/content/calendly/calendly.type';
import { TgTriggerType } from '@panel/app/partials/chat-bot/telegram-bot/trigger-types/tg-trigger.types';
import { DestroyService } from '@panel/app/services';
import { BillingInfo } from '@panel/app/services/billing-info/billing-info.model';
import { CanvasBaseService } from '@panel/app/services/canvas/common/base/canvas-base.service';
import { CanvasScreenshotService } from '@panel/app/services/canvas/common/screenshot/canvas-screenshot.service';
import { screenshotAnimation } from '@panel/app/shared/animations/screenshot';
import { slideLeft } from '@panel/app/shared/animations/slide-left';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

import { ChatBotForm } from '../forms/bot.form';
import { BotCanvasEditorComponent } from './canvas-editor/bot-canvas-editor.component';
import { BlockType, Branch } from './views/blocks/base-block/branch';

export interface BranchCreateOptionsInterface {
  newName?: string;
}

export type CreateBranchCb = (
  branchType: BlockType,
  parentBranch?: Branch,
  newName?: string,
  addToScenario?: 'default' | 'interrupt',
) => Branch;

type SetBot<T extends CHAT_BOT_TYPE> = {
  chatBot: ChatBot<T>;
  resetViewportPosition: boolean;
  doNotRedrawCanvas: boolean;
  mapIds: Record<string, string>;
};

@Component({
  selector:
    'cq-bot-content[billingInfo][chatBot][channels][hasAccessDenial][initBotUpdateRequestFn][properties][tags][emailNotificationIntegrations]',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...botTokenProviders,
    ActionFactory,
    BadgeFactory,
    BlockNamingHelperService,
    BootstrapElementsFactory,
    BotBlocksOverlayService,
    BotBranchSelectService,
    BotScenariosHelper,
    CanvasBaseService,
    CanvasScreenshotService,
    ConnectionValidatorService,
    BranchFactory,
    ConnectionFactory,
    DestroyService,
    OverlayBootstrapElementsHelper,
    PixiStatsLoggerService,
  ],
  animations: [slideLeft(300, '335px'), screenshotAnimation()],
})
export class ContentComponent<T extends CHAT_BOT_TYPE> implements OnInit, OnDestroy {
  @Input()
  set channels(value: Channel[]) {
    this.stateService.channels$.next(value);
  }

  @Input()
  billingInfo!: BillingInfo;

  @Input()
  chatBot!: ChatBot<T>;

  @Input()
  currentApp!: App;

  @Input()
  chatBotAvatar!: string;

  @Input()
  djangoUser: any;

  @Input()
  deletedBranches!: string[];

  /** Есть ли ограничения в доступе к фичам связанным с ботами */
  @Input()
  hasAccessDenial: boolean = false;

  /** Список интеграций "Уведомление на Email" во внешнем формате */
  @Input()
  set emailNotificationIntegrationsExternal(value: EmailNotificationIntegrationExternal[]) {
    this.stateService.emailNotificationIntegrationsExternal$.next(value);
  }

  /** Список интеграций "AmoCRM" во внешнем формате */
  @Input() set amocrmIntegrationsExternal(value: AmocrmIntegrationExternal[]) {
    this.stateService.amocrmIntegrationsExternal$.next(value);
  }

  @Input()
  set calendlyIntegrationList(value: CalendlyIntegrationExternal[]) {
    this.stateService.calendlyIntegrationList$.next(value);
  }

  @Input()
  set calendlyEventList(value: CalendlyEvent[]) {
    this.stateService.calendlyEventList$.next(value);
  }

  @Input()
  properties!: Properties;

  @Input()
  initBotUpdateRequestFn!: (cb: () => void) => void;

  @Input()
  setBot!: Observable<SetBot<T>>;

  @Input()
  set tags(value: Tag[]) {
    this.stateService.tags$.next(value);
  }

  @Input()
  set teamMembers(value: TeamMember[]) {
    this.stateService.teamMembers$.next(value);
  }

  private _triggersIds: string[] = [];

  @Input()
  set triggersIds(value: string[]) {
    this._triggersIds = isDefined(value) ? value.filter((v) => v !== null) : [];
    if (this.chatBotForm && this.properties) {
      this.chatBotForm.triggers = this.triggersIdsToNames(value);
    }
  }

  get triggersIds(): string[] {
    return this._triggersIds;
  }

  private _triggerTypesKinds: TgTriggerType[] = [];

  @Input()
  set triggerTypesKinds(value: TgTriggerType[]) {
    this._triggerTypesKinds = value.filter((v) => v !== null);
    if (this.chatBotForm && this._triggerTypesKinds.length) {
      this.chatBotForm.triggers = this.triggersKindsToNames(value);
    }
  }

  get triggerTypesKinds() {
    return this._triggerTypesKinds;
  }

  _openWebPageTriggers: OpenedWebPageTriggerType[] = [];
  _openSdkPageTriggers: OpenedSdkPageTriggerType[] = [];

  @Input()
  set openWebPageTriggers(value: OpenedWebPageTriggerType[]) {
    this._openWebPageTriggers = value;
    if (this.chatBotForm) {
      this.chatBotForm.openWebPageTriggerAmount = this.openWebPageTriggerAmount;
    }
  }

  @Input()
  set openSdkPageTriggers(value: OpenedSdkPageTriggerType[]) {
    this._openSdkPageTriggers = value;
    if (this.chatBotForm) {
      this.chatBotForm.openSdkPageTriggerAmount = this.openSdkPageTriggerAmount;
    }
  }

  get openWebPageTriggerAmount(): number {
    return this._openWebPageTriggers.length;
  }

  get openSdkPageTriggerAmount(): number {
    return this._openSdkPageTriggers.length;
  }

  private _leaveSiteAttemptTrigger: LeaveSiteAttemptTriggerType = false;

  @Input()
  set leaveSiteAttemptTrigger(value: LeaveSiteAttemptTriggerType) {
    this._leaveSiteAttemptTrigger = value;
    if (this.chatBotForm) {
      this.chatBotForm.leaveSiteAttemptTrigger = value;
    }
  }

  get leaveSiteAttemptTrigger() {
    return this._leaveSiteAttemptTrigger;
  }

  @Output()
  chatBotChange: EventEmitter<ChatBot<T>> = new EventEmitter();

  @Output()
  startBadgeCanvasClick: Subject<void> = new Subject();

  @Output()
  botFormChange: EventEmitter<any> = new EventEmitter();

  @Output()
  deletedBranchesChange: EventEmitter<ReadonlyArray<string>> = new EventEmitter();

  @Output()
  validationCallback: EventEmitter<(errorNameTail: string) => Promise<boolean>> = new EventEmitter();

  @ViewChild(BotCanvasEditorComponent)
  canvasEditor!: BotCanvasEditorComponent<T>;

  CHAT_BOT_TYPE = CHAT_BOT_TYPE;
  FEATURES = FEATURES;
  FEATURE_LABELS_ONLY = FEATURE_LABELS_ONLY;

  chatBotForm!: ChatBotForm<T>;
  selectedBranch: Branch | null = null;
  zoomValue = 100;

  fullscreen = false;

  nextBranchOptions$: Observable<Branch[]> = of([]);

  screenshotInProgress!: boolean;

  constructor(
    private readonly destroy$: DestroyService,
    private readonly translocoService: TranslocoService,
    private readonly badgeFactory: BadgeFactory,
    private readonly blocksOverlayService: BotBlocksOverlayService,
    private readonly branchFactory: BranchFactory,
    public readonly canvasScreenshotService: CanvasScreenshotService,
    private readonly clipboard: ClipboardService,
    private readonly chatBotModel: ChatBotModel,
    private readonly chatBotTemplateModel: ChatBotTemplateModel,
    public readonly featureModel: FeatureModel,
    private readonly stateService: StateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly toastService: ToastService,
    private readonly interactionService: PixiInteractionService,
    private readonly zone: NgZone,
    @Inject(PIXI_VIEWPORT) private readonly viewport: Viewport,
    private readonly pixiStatsLogger: PixiStatsLoggerService,
    private readonly botBranchSelectService: BotBranchSelectService,
    private readonly blockNamingHelperService: BlockNamingHelperService,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly scenariosHelper: BotScenariosHelper,
  ) {}

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    this.canvasEditor.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    const iconsFontLoader = new FontFaceObserver('CQ-Icons-chat');
    const channelsFontLoader = new FontFaceObserver('CQ-Icons-channels');
    forkJoin([
      this.translocoService.selectTranslateObject('classes').pipe(first()),
      from(iconsFontLoader.load()),
      from(channelsFontLoader.load()),
    ]).subscribe(() => {
      this.scenariosHelper.updateScenarios(this.chatBot);
      this.chatBotForm = new ChatBotForm(
        this.chatBot,
        this.branchFactory,
        this.badgeFactory,
        this.scenariosHelper,
        this.getTriggers(),
        this.openWebPageTriggerAmount,
        this.openSdkPageTriggerAmount,
        this.leaveSiteAttemptTrigger,
      );
      this.selectBlock(this.chatBotForm.startedBranch);
      this.nextBranchOptions$ = this.getNextBranchOptionsObs();
      this.pixiStatsLogger.initLogging(
        this.currentApp.id,
        this.djangoUser.id,
        this.chatBotForm,
        () => this.chatBot.branches.length,
      );
      this.addChatBotFormValueChangeSubscription();

      this.validationCallback.emit((errorNameTail: string = 'next') => {
        this.chatBotChange.next(this.chatBotForm.chatBot);
        this.deletedBranchesChange.next(this.chatBotForm.deletedBranchesId);
        this.chatBotForm.revalidate();
        const invalidBranches: Array<Branch | BaseBadge> = this.chatBotForm.branches.filter((br) => br.form.invalid);

        if (this.chatBotForm.startBadge.form.invalid) {
          invalidBranches.push(this.chatBotForm.startBadge);
        }

        if (this.chatBotForm.interruptBadge.form.invalid) {
          invalidBranches.push(this.chatBotForm.interruptBadge);
        }

        if (invalidBranches.length > 0) {
          const warningText = this.translocoService.translate(
            `chatBot.branchContent.validationErrorOn.${errorNameTail}`,
            { errorNumber: invalidBranches.length },
          );
          this.toastService.danger(warningText);
          const br = invalidBranches[0];
          if (br instanceof Branch) {
            this.selectBlock(br);
          }
          this.canvasEditor.moveViewportCornerToBranch(br);
        }
        return new Promise((resolve, reject) => {
          if (this.chatBotForm.valid) {
            resolve(this.chatBotForm.valid);
          } else {
            reject(this.chatBotForm.valid);
          }
        });
      });
      this.changeDetectorRef.detectChanges();

      this.chatBotForm.branches.forEach((branch) => {
        this.subscribeToBranchesDestroy(branch);
      });

      this.initBotUpdateRequestFn(() => {
        if (!this.chatBotForm.areBadgesValid()) {
          if (this.chatBotForm.startBadge.form.invalid) {
            this.canvasEditor.moveViewportCornerToBranch(this.chatBotForm.startBadge);
          }
          if (this.chatBotForm.interruptBadge.form.invalid) {
            this.canvasEditor.moveViewportCornerToBranch(this.chatBotForm.interruptBadge);
          }
        }
        this.chatBotChange.next(this.chatBotForm.chatBot);
        this.deletedBranchesChange.next(this.chatBotForm.deletedBranchesId);
      });

      this.setBot
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ chatBot, resetViewportPosition = false, mapIds = {}, doNotRedrawCanvas = false }) => {
          this.updateChatBot(chatBot, doNotRedrawCanvas, mapIds);
          if (resetViewportPosition) {
            this.zone.runOutsideAngular(() => {
              //Обновление позиции происходит раньше, чем обновление самого бота, поэтому ставим в очередь
              setTimeout(() => this.viewportToStartBadge(), 0);
            });
          }
          this.chatBotForm.resetDeletedBranchesId();
        });
    });
    this.interactionService.click$
      .pipe(takeUntil(this.destroy$), pickOnlyEvent(PIXI_INTERACTION_EVENT.VIEWPORT))
      .subscribe(() => {
        this.selectBlock(null);
      });
    this.botBranchSelectService.pipe(takeUntil(this.destroy$)).subscribe((branch) => this.selectBlock(branch));

    this.initClipboardSubscription();

    this.canvasScreenshotService.screenshotInProgress.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.screenshotInProgress = value;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.chatBotForm?.destroy();
  }

  addChatBotFormValueChangeSubscription(): void {
    this.chatBotForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      this.botFormChange.emit();
    });
  }

  createBranch(parentBranch?: Branch, newName?: string, addToScenario?: 'default' | 'interrupt'): Branch {
    let name = this.blockNamingHelperService.getNewBranchName(newName);

    const newBranch = this.branchFactory.createDefaultBlock(
      name,
      {
        getBranchByLinkId: (linkId: string) => this.getBranchByLinkId(linkId),
      },
      CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE,
    );
    if (addToScenario === 'default') {
      this.scenariosHelper.addChainToDefaultScenario(newBranch);
    } else if (addToScenario === 'interrupt') {
      this.scenariosHelper.addChainToInterruptScenario(newBranch);
    }
    this.addCreatedBranch(newBranch, parentBranch);
    this.subscribeToBranchesDestroy(newBranch);
    return newBranch;
  }

  createCondition(
    type: Exclude<Branch['branchType'], 'default'>,
    parentBranch?: Branch,
    addToScenario?: 'default' | 'interrupt',
  ): Branch {
    const name = this.blockNamingHelperService.getNewConditionName(type);
    const newCondition = this.branchFactory.createCondition(name, {
      getBranchByLinkId: (linkId: string) => this.getBranchByLinkId(linkId),
    });
    if (addToScenario === 'default') {
      this.scenariosHelper.addChainToDefaultScenario(newCondition);
    } else if (addToScenario === 'interrupt') {
      this.scenariosHelper.addChainToInterruptScenario(newCondition);
    }
    this.addCreatedBranch(newCondition, parentBranch);
    this.subscribeToBranchesDestroy(newCondition);
    return newCondition;
  }

  createActionBlock(parentBranch?: Branch, newName?: string, addToScenario?: 'default' | 'interrupt'): Branch {
    let name = this.blockNamingHelperService.getNewActionBlockName();

    const newAction = this.branchFactory.createActionBlock(name, {
      getBranchByLinkId: (linkId: string) => this.getBranchByLinkId(linkId),
    });
    if (addToScenario === 'default') {
      this.scenariosHelper.addChainToDefaultScenario(newAction);
    } else if (addToScenario === 'interrupt') {
      this.scenariosHelper.addChainToInterruptScenario(newAction);
    }
    this.addCreatedBranch(newAction, parentBranch);
    this.subscribeToBranchesDestroy(newAction);
    return newAction;
  }

  createMeetingBlock(parentBranch?: Branch, newName?: string, addToScenario?: 'default' | 'interrupt'): Branch {
    let name = this.blockNamingHelperService.getNewMeetingName();

    const newMeeting = this.branchFactory.createMeetingBlock(name, {
      getBranchByLinkId: (linkId: string) => this.getBranchByLinkId(linkId),
    });
    if (addToScenario === 'default') {
      this.scenariosHelper.addChainToDefaultScenario(newMeeting);
    } else if (addToScenario === 'interrupt') {
      this.scenariosHelper.addChainToInterruptScenario(newMeeting);
    }
    this.addCreatedBranch(newMeeting, parentBranch);
    this.subscribeToBranchesDestroy(newMeeting);
    return newMeeting;
  }

  /**
   * Копирование структуры бота для создания шаблона
   */
  public copyStructureForTemplate() {
    this.clipboard.copy(JSON.stringify(this.chatBotTemplateModel.getTemplateFromChatBot(this.chatBotForm.chatBot)));
  }

  /**
   * Инициализация слушателя копирования
   * @private
   */
  private initClipboardSubscription() {
    this.clipboard.copyResponse$.pipe(takeUntil(this.destroy$)).subscribe((response: IClipboardResponse) => {
      if (response.isSuccess) {
        this.toastService.success(this.translocoService.translate('chatBot.branchContent.toasts.copied'));
      }
    });
  }

  /**
   * Добавление созаднной ветки в канвас и форму
   * @param branch
   * @param parentBranch
   */
  public addCreatedBranch(branch: Branch, parentBranch?: Branch): void {
    this.chatBotForm.addBranch(branch);
    this.canvasEditor.addBranch(branch, parentBranch);
    this.blocksOverlayService.addOverlayAbleElement(branch);
  }

  addBranchAndSelect() {
    const branch = this.createBranch();
    this.botBranchSelectService.next(branch);
  }

  addConditionAndSelect() {
    const condition = this.createCondition('appOnlineCondition');
    this.selectBlock(condition);
  }

  addActionBlockAndSelect() {
    const actionBLock = this.createActionBlock();
    this.selectBlock(actionBLock);
  }

  addMeetingAndSelect() {
    const actionBLock = this.createMeetingBlock();
    this.selectBlock(actionBLock);
  }

  /**
   * Создание новой ветки
   */
  createNewBlock(
    blockType: BlockType,
    parentBranch?: Branch,
    newName?: string,
    addToScenario?: 'default' | 'interrupt',
  ): Branch {
    if (blockType === 'branch') {
      return this.createBranch(parentBranch, newName, addToScenario);
    }
    if (blockType === 'action') {
      return this.createActionBlock(parentBranch, newName, addToScenario);
    }
    return this.createCondition('appOnlineCondition', parentBranch, addToScenario);
  }

  getBranchByLinkId(linkId: string): Branch | null {
    const branch = this.chatBotForm.branches.find((branch) => branch.linkId === linkId);
    return branch ? branch : null;
  }

  getNextBranchOptionsObs(): Observable<Branch[]> {
    return merge(
      this.botBranchSelectService.asObservable(),
      this.chatBotForm.controls.branches.valueChanges.pipe(
        map(() => this.chatBotForm.branches.length),
        distinctUntilChanged(),
      ),
    ).pipe(
      map((): Branch[] => {
        return this.chatBotForm.branches.filter((branch) => {
          const isDifferentBlock = branch.linkId !== this.selectedBranch?.linkId;

          if (this.selectedBranch && this.scenariosHelper.blockIsPartOfInterruptScenario(this.selectedBranch)) {
            const isNotPartOfDefaultScenario = !this.scenariosHelper.blockIsPartOfDefaultScenario(branch);
            const isValidForInterruptScenario = !this.scenariosHelper.deepValidateBlockForInterruptScenario(branch);
            return isDifferentBlock && isNotPartOfDefaultScenario && isValidForInterruptScenario;
          }

          return isDifferentBlock && !this.scenariosHelper.blockIsPartOfInterruptScenario(branch);
        });
      }),
    );
  }

  onChangeZoomButtonClick(direction: 'up' | 'down') {
    let newZoomValue =
      direction === 'up'
        ? this.zoomValue + VIEWPORT_ZOOM_SETTINGS.STEP_PERCENT
        : this.zoomValue - VIEWPORT_ZOOM_SETTINGS.STEP_PERCENT;
    if (newZoomValue < VIEWPORT_ZOOM_SETTINGS.MIN_PERCENT) {
      newZoomValue = VIEWPORT_ZOOM_SETTINGS.MIN_PERCENT;
    } else if (newZoomValue > VIEWPORT_ZOOM_SETTINGS.MAX_PERCENT) {
      newZoomValue = VIEWPORT_ZOOM_SETTINGS.MAX_PERCENT;
    }
    this.canvasEditor.setZoom(newZoomValue);
  }

  onZoomChange(zoom: number) {
    this.zoomValue = zoom;
  }

  /**
   * Выбор ветки
   *
   * @param branch - Ветка
   */
  selectBlock(branch: string | Branch | null): void {
    let branchInstance = typeof branch === 'string' ? this.getBranchByLinkId(branch) : branch;

    // Если ID не поменялся - значит второй клик на ту же ветку (99,9%)
    if (branchInstance?.linkId === this.selectedBranch?.linkId) {
      return;
    }

    if (this.selectedBranch) {
      this.selectedBranch.active = false;
    }

    if (!branchInstance) {
      this.selectedBranch = null;
    } else {
      this.selectedBranch = branchInstance;
      this.selectedBranch.active = true;
    }

    this.zone.run(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  subscribeToBranchesDestroy(branch: Branch) {
    branch.destroy$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.chatBotForm.deleteBranch(branch);
      if (branch.linkId === this.selectedBranch?.linkId) {
        this.selectBlock(null);
      }
      this.blockNamingHelperService.calcBlockNamesCounters();
    });
  }

  /**
   * Callback на обновление бота
   */
  updateChatBot(chatBot: ChatBot<T>, doNotRedrawCanvas: boolean, mapIds: Record<string, string>): void {
    if (doNotRedrawCanvas) {
      return this.updateExistingChatBot(chatBot, mapIds);
    }

    this.blocksOverlayService.clearOverlays();
    this.chatBotForm.destroy();
    this.scenariosHelper.updateScenarios(this.chatBot);
    this.chatBotForm = new ChatBotForm(
      chatBot,
      this.branchFactory,
      this.badgeFactory,
      this.scenariosHelper,
      this.getTriggers(),
      this.openWebPageTriggerAmount,
      this.openSdkPageTriggerAmount,
      this.leaveSiteAttemptTrigger,
    );
    this.canvasEditor.addOverlaysToAllElements(this.chatBotForm);
    this.nextBranchOptions$ = this.getNextBranchOptionsObs();
    this.addChatBotFormValueChangeSubscription();
    this.selectedBranch = null;
    this.selectBlock(this.chatBotForm.startedBranch);
    this.chatBotForm.branches.forEach((branch) => {
      this.subscribeToBranchesDestroy(branch);
    });
  }

  private updateExistingChatBot(chatBot: ChatBot<T>, mapIds: Record<string, string>) {
    if (Object.keys(mapIds).length === 0) {
      return;
    }

    for (let linkId in mapIds) {
      const branch = this.chatBotForm.branches.find((branch) => branch.linkId === linkId);

      if (!branch) {
        throw new Error('Could not find the branch');
      }

      branch.id = mapIds[linkId];

      if (this.chatBotForm.startBadge.form.controls.nextBranchLinkId.value === linkId) {
        this.chatBotForm.startBadge.form.nextBranchId = mapIds[linkId];
      }

      if (this.chatBotForm.interruptBadge.form.controls.nextBranchLinkId.value === linkId) {
        this.chatBotForm.interruptBadge.form.nextBranchId = mapIds[linkId];
      }
    }

    for (let i = 0; i < this.chatBotForm.branches.length; i++) {
      const branchToUpdate = this.chatBotForm.branches[i];
      const updatedBranch = chatBot.branches.find((branch) => branch.id === branchToUpdate.id);

      if (!branchToUpdate || !updatedBranch) {
        throw new Error('Could not find the branch');
      }

      // Чтобы код ниже работал нормально, надо гарантировать, что в момент получения бота с созданными действиями
      // их количество и порядок был таким же как и в момент сохранения, т.е. пользователь не производил ни каких действий с действиями (сорян за тавтологию)
      // на текущий меомент это гарантируется лоадером
      // таким образом i-ое действие текущего бота и равно i-му действию бота, пришедшего с бекенда
      for (let g = 0; g < branchToUpdate.actions.length; g++) {
        const action = branchToUpdate.actions[g];
        action.id = updatedBranch.actions[g].id;

        if (
          ChatBotModel.isConnectionSourceAction(action.type) &&
          action.nextBranchLinkId.value &&
          Object.keys(mapIds).includes(action.nextBranchLinkId.value)
        ) {
          action.form.nextBranchId = updatedBranch.actions[g].nextBranchId;
        }
      }
    }
  }

  private getTriggers() {
    if (this.triggersIds.length) {
      return this.triggersIdsToNames(this.triggersIds);
    }

    if (this.triggerTypesKinds.length) {
      return this.triggersKindsToNames(this.triggerTypesKinds);
    }

    return [];
  }

  viewportToStartBadge() {
    this.canvasEditor.moveViewportCornerToBranch(this.chatBotForm.startBadge);
  }

  /** Обрабатывает клик по кнопке создания скриншота */
  onClickTakeScreenshot(): void {
    this.canvasScreenshotService.take();
  }

  onBranchRename(): void {
    this.blockNamingHelperService.calcBlockNamesCounters();
  }

  trackAddActionClick() {
    this.carrotquestHelper.track('Чат-бот - добавил блок действия', {
      app_id: this.currentApp.id,
      chatBotId: this.chatBot.id ?? '',
      botType: this.chatBot.type,
      source: 'new block',
    });
  }

  trackAddConditionClick() {
    this.carrotquestHelper.track('Чат-бот - добавил условие из меню', {
      app_id: this.currentApp.id,
      chatBotId: this.chatBot.id ?? '',
      botType: this.chatBot.type,
      source: 'new block',
    });
  }

  private triggersIdsToNames(triggersIds: string[]): string[] {
    // две проходки по циклам нужны, потому что если делать eventTypes.filter().map(), то сортировка будет как в списке eventTypes
    const selectedTriggers = this.properties.eventTypes.filter((event) => triggersIds.includes(event.id));
    return triggersIds.map((id) => {
      const trigger = selectedTriggers.find((trigger) => trigger.id === id);
      if (!trigger) {
        return this.translocoService.translate('chatBot.badges.startBadge.triggerIsNotSelected');
      }
      return trigger!.prettyName;
    });
  }

  private triggersKindsToNames(tgTriggerTypesIds: TgTriggerType[]): string[] {
    return tgTriggerTypesIds.map((kind) => {
      return this.translocoService.translate(`models.message.triggerTypeKind.${kind}`);
    });
  }
}

(function () {
  'use strict';

  require('./save-message-part-as-template.controller');

  angular
    .module('myApp.modals.saveMessagePartAsTemplate')
    .component('cqSaveMessagePartAsTemplateModal', cqSaveMessagePartAsTemplateModal());

  function cqSaveMessagePartAsTemplateModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // {Object} currentApp - текущее приложение
        // {Array} directories - Список Папок
        // {Object} messagePart - Вариант сообщения
      },
      controller: 'CqSaveMessagePartAsTemplateModalController',
      controllerAs: 'vm',
      template: require('./save-message-part-as-template.html'),
    };
  }
})();

<div
  class="fullsize-background cq-loader"
  [ngClass]="{ 'fullsize-background-white': background }"
  [ngStyle]="{ height: containerHeight }"
>
  <div class="flex flex-col align-items-center position-absolute loader-container">
    <div
      class="overflow-hidden loader-img"
      [ngStyle]="{
        'width.px': width,
        'height.px': height
      }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="loader"
      >
        <defs>
          <linearGradient
            x1="28.154%"
            y1="63.74%"
            x2="74.629%"
            y2="17.783%"
            id="loadingCircle"
          >
            <stop
              stop-color="#5C5CD6"
              offset="0%"
            ></stop>
            <stop
              class="tail"
              stop-opacity="0"
              offset="100%"
            ></stop>
          </linearGradient>
        </defs>
        <g
          transform="translate(2)"
          fill="none"
          fill-rule="evenodd"
        >
          <circle
            stroke="url(#loadingCircle)"
            stroke-width="3"
            cx="10"
            cy="12"
            r="10"
          ></circle>
          <path
            d="M10 2C4.477 2 0 6.477 0 12"
            stroke="#5C5CD6"
            stroke-width="3"
          ></path>
          <rect
            fill="#5C5CD6"
            x="8.5"
            y="0.5"
            width="3"
            height="3"
            rx="6"
          ></rect>
        </g>
      </svg>
    </div>
    <ng-content></ng-content>
  </div>
</div>

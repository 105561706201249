<ng-container *transloco="let t; read: 'userTagComponent'">
  <ng-container *ngIf="isRemoved">
    <div
      class="user-tag-wrapper removed flex align-items-center"
      (click)="onClickTag()"
      [ngClass]="{ active: isActive, selectable: canActivate }"
      [ngbTooltip]="isActive ? t('tagHasBeenRemovedClickOnItTooltip') : t('tagHasBeenRemovedTooltip')"
      container="body"
      data-test="user-tag-removed"
    >
      <i class="cqi-xs cqi-triangle-exclamation no-flex-shrink mr-5 text-danger"></i>
      <span
        class="text text-truncate small"
        [innerText]="tag.name"
      ></span>
    </div>
  </ng-container>

  <ng-container *ngIf="!isRemoved">
    <div
      class="user-tag-wrapper flex align-items-center"
      (click)="onClickTag()"
      [ngClass]="{ active: isActive, selectable: canActivate }"
      data-test="user-tag"
    >
      <i
        *ngIf="!isActive"
        class="cqi-xs cqi-hash mr-5 text-primary"
      ></i>

      <button
        *ngIf="isActive"
        class="btn btn-sm btn-text-primary flex mr-5"
        (click)="onClickContainsButton($event)"
        [ngbTooltip]="isContains ? t('enableThisTagTooltip') : t('disableThisTagTooltip')"
        data-test="user-tag-contains-btn"
        placement="top-left"
        type="button"
      >
        <i
          class="btn-icon cqi-xs"
          [ngClass]="{ 'cqi-plus-circle': isContains, 'cqi-minus-circle': !isContains }"
        ></i>
      </button>

      <span
        class="text text-truncate small"
        [innerText]="tag.name"
      ></span>

      <button
        *ngIf="canRemove"
        class="btn btn-sm btn-text-primary flex ml-5"
        (click)="onClickRemoveButton($event)"
        [ngbTooltip]="t('removeTagTooltip')"
        data-test="user-tag-remove-btn"
        type="button"
      >
        <i class="btn-icon cqi-xs cqi-times text-primary"></i>
      </button>
    </div>
  </ng-container>
</ng-container>

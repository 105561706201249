/**
 * Сервис-помощник для валидации
 */
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.services.validationHelper').factory('validationHelper', validationHelper);

  function validationHelper($parse, $q, VALIDATION_EXPRESSION, VALIDATION_SUBMIT_EXPRESSION, utilsModel) {
    return {
      getValidationResult: angular.bind(null, getValidationResult, false),
      getValidationResultBySubmit: angular.bind(null, getValidationResult, true),
      validateAsync: validateAsync,
    };

    /**
     * Функция, использующаяся в ng-class для has-error (используется для того, чтобы каждый раз не писать партянку условий в ng-class для отображения ошибок)
     *
     * @param {Boolean} validateBySubmit Флаг для валидации по сабмиту фармы
     * @param {FormController} formController Объект контроллера в $scope, используемый в качестве контекста для $parse
     * @param {String} fieldName Имя поля для валидации
     * @returns {Boolean} Результат валидации
     */
    function getValidationResult(validateBySubmit, formController, fieldName) {
      var resultValidationString = (validateBySubmit ? VALIDATION_SUBMIT_EXPRESSION : VALIDATION_EXPRESSION).replace(
        /FIELD_NAME/gm,
        fieldName,
      );

      var context = {};
      context.form = formController;

      return $parse(resultValidationString)(context);
    }

    /**
     * Асинхронная серверная валидация
     *
     * @param {*} value Значение, которое надо провалидировать на сервере
     * @param {String} validator Тип, которому должно удовлетворять значение
     * @param {Object=} validatorParameters Параметры валидации
     * @return {Promise}
     */
    function validateAsync(value, validator, validatorParameters) {
      var params = {
        validator: validator,
        validatorArguments: validatorParameters,
        value: value,
      };

      return firstValueFrom(utilsModel.asyncValidate(params)).then(validateAsyncSuccess);

      function validateAsyncSuccess(response) {
        if (response.valid) {
          return $q.resolve();
        } else {
          return $q.reject();
        }
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { VideoViewModalService } from '@panel/app/services/video-view/video-view-modal.service';

import { StarterGuideWarmUpComponent } from './starter-guide-warm-up.component';

@NgModule({
  declarations: [StarterGuideWarmUpComponent],
  exports: [StarterGuideWarmUpComponent],
  imports: [CommonModule, TranslocoModule, NgbAlertModule, UIRouterModule, NgbTooltipModule],
  providers: [CarrotquestHelperProvider, VideoViewModalService],
})
export class StarterGuideWarmUpModule {}

<ng-container *transloco="let t; read: 'knowledgeBaseComponent'">
  <section id="content">
    <cq-articles-limitation-banner
      *ngIf="!isKnowledgeBaseProActivated"
      [billingInfo]="billingInfo"
      [currentApp]="currentApp"
    ></cq-articles-limitation-banner>
    <div class="container-fluid limited-container">
      <cq-permission-denied *ngIf="!accessToKnowledgeBase.hasAccess"></cq-permission-denied>

      <div *ngIf="accessToKnowledgeBase.hasAccess">
        <div class="flex justify-space-between align-items-center">
          <nav aria-label="breadcrumb">
            <ol
              *ngIf="!currentCategory"
              class="breadcrumb"
            >
              <li
                class="breadcrumb-item active"
                aria-current="page"
                [innerHTML]="'knowledgeBase.header.breadcrumbs' | transloco"
              ></li>
            </ol>
            <p
              class="text-reset mb-20"
              [innerHTML]="'knowledgeBase.description' | transloco"
            ></p>
            <ol
              *ngIf="currentCategory"
              class="breadcrumb"
            >
              <li class="breadcrumb-item">
                <a
                  uiSref="app.content.knowledgeBase.categories"
                  [innerHTML]="'knowledgeBase.header.breadcrumbs' | transloco"
                ></a>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
              >
                {{ currentCategory.name }}
              </li>
            </ol>
          </nav>
          <div class="text-right flex">
            <button
              class="btn btn-text-primary margin-right-20"
              type="button"
              (click)="trackClickSetUp(); openSetUpModal()"
            >
              <i class="btn-icon-left cqi-sm cqi-cog"></i>
              <span [innerHTML]="'knowledgeBase.header.setUpButton' | transloco"></span>
            </button>
            <a
              class="btn btn-text-primary"
              target="_blank"
              (click)="trackClickGoToBase()"
              [href]="'https://' + settings.fullDomain"
            >
              <i class="btn-icon-left cqi-sm cqi-external-link"></i>
              <span [innerHTML]="'knowledgeBase.header.toKnowledgeBase' | transloco"></span>
            </a>
          </div>
        </div>
        <div class="card">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </section>
  <cq-loader
    *ngIf="isLoading"
    class="content-loader"
  ></cq-loader>
</ng-container>

(function () {
  'use strict';

  /**
   * Компонент-обёртка модального окна
   *
   * NOTE:
   *  Необходимо для того, чтобы была возможность открывать модальное окно из Angular в AngularJS.
   *
   * TODO:
   *  Удалить при полном переходе на Angular
   */

  angular
    .module('myApp.modals.accessDenialModalWrapper')
    .component('cqAccessDenialModalWrapper', cqAccessDenialModalWrapper());

  function cqAccessDenialModalWrapper() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // modalWindowParams.currentApp - Текущее приложение
        // modalWindowParams.denialReason - Причина отказа в доступе до фичи
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./access-denial-modal-wrapper.html'),
    };
  }
})();

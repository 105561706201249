<ng-container *transloco="let t; read: 'contentBlocksComponent'">
  <ng-container *ngFor="let content of control.controls; index as i">
    <cq-block-header
      class="mb-5"
      (delete)="deleteContentBlock(i)"
      (moveUp)="moveBlock(i, i - 1)"
      (moveDown)="moveBlock(i, i + 1)"
      (duplicate)="copyBLock(i)"
      [isDuplicateDisabled]="!isAllowToAddContentBlock"
      [blockType]="content.value.type"
      [index]="i"
      [lastIndex]="control.controls.length - 1"
    ></cq-block-header>

    <ng-container [ngSwitch]="content.value.type">
      <cq-text-block-editor
        class="mb-20"
        *ngSwitchCase="'text'"
        [formControl]="content"
        (delete)="deleteContentBlock(i)"
      ></cq-text-block-editor>

      <cq-file-block-editor
        class="mb-20"
        *ngSwitchCase="'file'"
        [formControl]="content"
        (delete)="deleteContentBlock(i)"
      ></cq-file-block-editor>
    </ng-container>
  </ng-container>

  <!--Кнопки добавления блоков контента-->
  <div class="mb-20 ml-n10 mr-n10 row">
    <div class="col-6 px-10">
      <button
        class="btn btn-secondary btn-block"
        type="button"
        (click)="addContentBlock('text')"
        [disabled]="!isAllowToAddContentBlock"
      >
        <i class="btn-icon-left cqi-sm cqi-align-left"></i>
        <span> {{ t('addTextButton') }} </span>
      </button>
    </div>
    <div class="col-6 px-10">
      <button
        class="btn btn-secondary btn-block"
        type="button"
        (click)="addContentBlock('file')"
        [disabled]="!isAllowToAddContentBlock"
      >
        <i class="btn-icon-left cqi-sm cqi-paperclip"></i>
        <span>{{ t('addFileButton') }}</span>
      </button>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

import { DATA_SOURCE_STATUS, DATA_SOURCE_STATUS_FILTERED } from '@http/ai-data/ai-data.constants';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusFilterComponent extends AbsCVAFormControlBasedComponent<DATA_SOURCE_STATUS> {
  @ViewChild(NgSelectComponent)
  statusSelect!: NgSelectComponent;

  readonly control: FormControl<DATA_SOURCE_STATUS> = new FormControl<DATA_SOURCE_STATUS>(DATA_SOURCE_STATUS.ALL, {
    nonNullable: true,
    validators: [Validators.required],
  });

  readonly DATA_SOURCE_STATUS = DATA_SOURCE_STATUS;
  readonly DATA_SOURCE_STATUS_FILTERED = DATA_SOURCE_STATUS_FILTERED;

  getStatusIcon(status: DATA_SOURCE_STATUS) {
    switch (status) {
      case DATA_SOURCE_STATUS.ALL:
        return '';
      case DATA_SOURCE_STATUS.ACTIVE:
        return 'cqi-lightning';
      case DATA_SOURCE_STATUS.STOPPED:
        return 'cqi-lightning-slash';
    }
  }
}

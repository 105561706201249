<ng-container *transloco="let t; read: 'botRuleControlComponent'">
  <div [cqShowError]="control">
    <div class="position-relative d-flex align-items-center">
      <input
        class="form-control"
        autocomplete="off"
        [formControl]="control"
        [placeholder]="t('input.placeholder')"
      />
      <span class="counter text-secondary">{{ control.value?.length || 0 }}/{{ BOT_RULE_MAX_LENGTH }}</span>
    </div>
  </div>
</ng-container>

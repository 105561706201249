import { Container } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

/**
 * View экшена "Задержка"
 */
export class DelayPassedView extends AbsInteractiveBlockPartView<TRIGGER_CHAIN_BLOCK_TYPE.DELAY> {
  /** Тип */
  type: INTERACTIVE_BLOCK_PART = INTERACTIVE_BLOCK_PART.DELAY_PASSED;

  hasConnection(): boolean {
    return this.stepView.data.nextStepOnFailConnected;
  }

  renderContent(): Container {
    const icon = CanvasRenderService.renderIcon(UNICODE_ICON['cqi-triangle-exclamation-o'], {
      color: HEX_COLOR.DANGER_400,
    });

    const waitForDateText: Container = CanvasRenderService.renderText(this.stepView.data.failText, {
      color: HEX_COLOR.DANGER_400,
      wordWrap: false,
    });

    const textContainer = ElementBuilder.wrapElementsInRectangle([icon, waitForDateText], {
      placement: {
        marginBetweenCols: 5,
      },
    });

    const mainContainer = ElementBuilder.wrapElementsInRectangle([textContainer], {
      background: {
        sideTextureName: 'background-danger950-272-side',
        innerTextureName: 'background-danger950-272-inner',
      },
      placement: {
        alignItems: 'center',
        justifyContent: 'start',
        padding: {
          x: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
          y: TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
        },
      },
    });

    this.addAndPositionConnectionPoint(mainContainer);

    return ElementBuilder.wrapElementsInRectangle([mainContainer], {
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 15,
      },
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { MemberActionsNotFoundZeroDataComponent } from './member-actions-not-found-zero-data.component';

@NgModule({
  declarations: [MemberActionsNotFoundZeroDataComponent],
  exports: [MemberActionsNotFoundZeroDataComponent],
  imports: [CommonModule, TranslocoModule, SharedModule],
})
export class MemberActionsNotFoundZeroDataModule {}

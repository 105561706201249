import { AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS } from '@http/integration/constants/integration.constants';
import { MESSAGE_PART_TYPES } from '../../../../../app/http/message-part/message-part.constants';

/**
 * Проверяет, есть ли у messagePart внутри кнопки/ссылки
 * @param {Object} messagePart часть триггерного сообщения
 * @param MESSAGE_PART_TYPES типы автосообщений
 * @returns {boolean}
 */
export function isMessagePartValidForEmailNotification(messagePart) {
  const linkRe = /<a([^>]+)>(.+?)<\/a>/gm;

  switch (messagePart.type) {
    case MESSAGE_PART_TYPES.POPUP_CHAT:
    case MESSAGE_PART_TYPES.SDK_POPUP_CHAT:
      // В таких всегда есть поле ввода, поэтому всегда валидные
      return true;
    case MESSAGE_PART_TYPES.POPUP_SMALL:
    case MESSAGE_PART_TYPES.POPUP_BIG:
      // Тут кроме типа ответа "no" подходят все, но если есть ссылка, то и такой попап сойдет
      const { replyType, body } = messagePart[messagePart.type];
      return replyType !== 'no' || linkRe.test(body);
    case MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL:
    case MESSAGE_PART_TYPES.BLOCK_POPUP_BIG:
    case MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL:
      const { bodyJson } = messagePart[messagePart.type];
      const { footer, blocks } = bodyJson;

      // Если есть футер - значит есть кнопка, автоматом валидное
      if (footer) {
        return true;
      }

      // Тут блоки, и если хоть один из них содержит кнопку или текст, то попап валидный тоже
      return blocks[0].some((row) => {
        return row.some((block) => {
          if (['button', 'cart', 'inputWithBigButton', 'inputWithButton'].includes(block.type)) {
            return true;
          }
          const text = block.params.text;
          if (!text) {
            return false;
          }
          return linkRe.test(text);
        });
      });
    case MESSAGE_PART_TYPES.JS:
      const re = new RegExp(/trackMessageInteraction\(['"`]{1}(.+)['"`]{1}, *['"`]{1}(.+)['"`]\)/gm);
      const allMatches = [...messagePart.run_script.body.matchAll(re)];

      if (allMatches.length === 0) {
        return false;
      }

      return allMatches.some((match) => {
        return ['clicked', 'replied'].includes(match[2]);
      });
    default:
      return false;
  }
}

/**
 * Проверяет, является ли тип messagePart валидным
 * @param messagePart часть триггерного сообщения
 * @param MESSAGE_PART_TYPES
 * @returns {boolean}
 */
export function isMessageHasValidTypeForEmailNotification(messagePart) {
  switch (messagePart.type) {
    case MESSAGE_PART_TYPES.POPUP_CHAT:
    case MESSAGE_PART_TYPES.POPUP_SMALL:
    case MESSAGE_PART_TYPES.POPUP_BIG:
    case MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL:
    case MESSAGE_PART_TYPES.BLOCK_POPUP_BIG:
    case MESSAGE_PART_TYPES.SDK_POPUP_CHAT:
    case MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL:
    case MESSAGE_PART_TYPES.JS:
      return true;
    default:
      return false;
  }
}

/**
 * Проверяет валидность частей автосообщения для отправки Email-нотификации
 */
export function checkMessagePartValidityForNotification(parts) {
  let aSideError = null;
  let bSideError = null;

  if (parts.length === 1) {
    const part = parts[0];
    if (!isMessageHasValidTypeForEmailNotification(part, MESSAGE_PART_TYPES)) {
      aSideError = AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.INVALID_MESSAGE_TYPE;
    } else if (!isMessagePartValidForEmailNotification(part, MESSAGE_PART_TYPES)) {
      aSideError = AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.MESSAGE_HAS_NO_BUTTONS;
    }
  } else if (parts.length === 2) {
    const aSide = parts[0];
    const bSide = parts[1];

    if (!isMessageHasValidTypeForEmailNotification(aSide, MESSAGE_PART_TYPES)) {
      aSideError = AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.INVALID_MESSAGE_TYPE;
    } else if (!isMessagePartValidForEmailNotification(aSide, MESSAGE_PART_TYPES)) {
      aSideError = AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.MESSAGE_HAS_NO_BUTTONS;
    }

    if (!isMessageHasValidTypeForEmailNotification(bSide, MESSAGE_PART_TYPES)) {
      bSideError = AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.INVALID_MESSAGE_TYPE;
    } else if (!isMessagePartValidForEmailNotification(bSide, MESSAGE_PART_TYPES)) {
      bSideError = AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.MESSAGE_HAS_NO_BUTTONS;
    }
  }

  return {
    aSideError,
    bSideError,
  };
}

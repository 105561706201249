/**
 * Фильтр, превращающий число в последовательность букв
 */
(function () {
  'use strict';

  angular.module('myApp.filters.letter').filter('letter', letter);

  function letter($translate, LANGUAGE) {
    // начальные и конечные символы алфавитов разных языков
    var ALPHABET_LETTERS = {
      en: ['A', 'Z'],
      ru: ['А', 'Я'],
    };

    return letterFilter;

    /**
     * Превращение числа в последовательность заглавных букв английского алфавита.
     *
     * @example
     * // для английского алфавита вернёт A
     * $filter('letter')(0);
     *
     * @example
     * // для английского алфавита вернёт АА
     * $filter('letter')(26);
     *
     * @example
     * // для английского алфавита вернёт CW
     * $filter('letter')(100);
     *
     * @param {String|Number} value
     * @returns {String}
     */
    function letterFilter(value) {
      var parsedValue = parseInt(value);
      var firstLetterCode;
      var lastLetterCode;
      var numberOfLetters;

      if (angular.isDefined(parsedValue) && angular.isNumber(parsedValue) && isFinite(parsedValue)) {
        firstLetterCode = (
          ALPHABET_LETTERS[LANGUAGE] ? ALPHABET_LETTERS[LANGUAGE] : ALPHABET_LETTERS[$translate.fallbackLanguage()]
        )[0].charCodeAt(0);
        lastLetterCode = (
          ALPHABET_LETTERS[LANGUAGE] ? ALPHABET_LETTERS[LANGUAGE] : ALPHABET_LETTERS[$translate.fallbackLanguage()]
        )[1].charCodeAt(0);
        numberOfLetters = lastLetterCode - firstLetterCode + 1;
        var result = '';

        while (parsedValue >= 0) {
          result = String.fromCharCode((parsedValue % numberOfLetters) + firstLetterCode) + result;
          parsedValue = Math.floor(parsedValue / numberOfLetters) - 1;
        }

        return result;
      } else {
        return value;
      }
    }
  }
})();

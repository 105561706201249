import { InjectionToken } from '@angular/core';

/** Тип данных, передаваемых в prompt-модалку */
export type PromptModalData = {
  /** Тело модалки */
  body?: string;
  /** CSS-класс кнопки отмены */
  cancelButtonClass?: string;
  /** Текст в кнопке отмены */
  cancelButtonText?: string;
  /** CSS-класс подтверждающей кнопки */
  confirmButtonClass?: string;
  /** Текст в подтверждающей кнопке */
  confirmButtonText?: string;
  /** Заголовок */
  heading?: string;
  /** Текст для required ошибки */
  inputErrorText?: string;
  /** Лейбл для инпута */
  inputLabel?: string;
  /** Максимальная длина значения в инпуте */
  inputMaxLength?: number;
  /** Текст для maxlength ошибки */
  inputMaxLengthErrorText?: string;
  /** Плейсхолдер для инпута */
  inputPlaceholder?: string;
  /** Начальное значение инпута */
  text?: string;
};

/**
 * Токен для передачи данных через DI в prompt-модалку
 */
export const PROMPT_MODAL_DATA_TOKEN = new InjectionToken<PromptModalData>('Prompt modal data provider');

import { ChangeDetectionStrategy, Component, Input, NgZone, Optional, Self } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AMOCRM_FIELDS_TYPE } from '@http/integration/integrations/amo/constants/amocrm-integration.constants';
import { AmocrmField } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AmocrmCreateNewFieldModalComponent } from '@panel/app/pages/integrations/content/amocrm/create-new-field-modal/amocrm-create-new-field-modal.component';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: `
    cq-amocrm-fields-select[fields][integrationId][type][formControlName],
    cq-amocrm-fields-select[fields][integrationId][type][formControl],
    cq-amocrm-fields-select[fields][integrationId][type][ngModel]
  `,
  templateUrl: './amocrm-fields-select.component.html',
  styleUrls: ['./amocrm-fields-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmFieldsSelectComponent extends AbstractCVAControl<string> {
  /**
   * Список полей из Amocrm
   */
  @Input()
  fields: AmocrmField[] = [];

  /** ID интеграции */
  @Input()
  integrationId!: string;

  /**
   * Тип полей из Amocrm
   */
  @Input()
  type!: AMOCRM_FIELDS_TYPE;

  readonly control = new GenericFormControl<string>(null, Validators.required);

  constructor(
    private readonly amocrmState: AmocrmStateService,
    private readonly modalService: NgbModal,
    private readonly integrationService: IntegrationService,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  isRemovedField(amocrmLeadField: string) {
    return !this.fields.map((field) => field.name).includes(amocrmLeadField);
  }

  /**
   * Открыть модалку создания нового поля
   */
  openCreateNewFieldModal() {
    const modalRef = this.modalService.open(AmocrmCreateNewFieldModalComponent, {
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.integrationId = this.integrationId;

    modalRef.result.then((field) => {
      if (this.type === 'lead') {
        this.amocrmState.leadFields$.next([...this.amocrmState.leadFields$.getValue(), field]);
      } else {
        this.amocrmState.contactFields$.next([...this.amocrmState.contactFields$.getValue(), field]);
      }

      this.control.setValue(field);
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ChangeRoutingBotActiveStatusConfirmComponent } from './change-routing-bot-active-status-confirm.component';

@NgModule({
  declarations: [ChangeRoutingBotActiveStatusConfirmComponent],
  exports: [ChangeRoutingBotActiveStatusConfirmComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [NgbActiveModal],
})
export class ChangeRoutingBotActiveStatusConfirmModule {}

<ng-container *transloco="let t; read: 'memberInvitationComponent'">
  <div class="d-flex align-items-baseline margin-between-cols-10">
    <div class="form-group">
      <ng-select
        [clearable]="false"
        [formControl]="controlPermissions"
        [items]="availableTeamMemberPermissions"
        [searchable]="false"
        (change)="onChangePermissionSelect()"
      >
        <ng-template
          let-item="item"
          ng-label-tmp
        >
          <div>
            <i
              class="cqi-sm mr-5"
              [ngClass]="getIconClassForPermission(item)"
            ></i>
            <span>{{ 'models.teamMember.permissions.' + item + '.name' | transloco }}</span>
          </div>
        </ng-template>

        <ng-template
          let-permission="item"
          ng-option-tmp
        >
          <div>
            <i
              class="cqi-sm mr-5"
              [ngClass]="getIconClassForPermission(permission)"
            ></i>
            <span>{{ getNameForPermission(permission) }}</span>
          </div>
          <small class="text-muted white-space-normal">{{ getDescriptionForPermission(permission) }}</small>
        </ng-template>
      </ng-select>
    </div>

    <div
      class="form-group flex-grow-1"
      [cqShowError]="controlEmail"
    >
      <input
        class="form-control"
        [formControl]="controlEmail"
        [placeholder]="t('form.email.placeholder')"
      />
      <cq-validation-messages [control]="controlEmail">
        <cq-validation-message errorName="required">{{ t('form.email.errors.required') }}</cq-validation-message>
        <cq-validation-message errorName="email">{{ t('form.email.errors.email') }}</cq-validation-message>
      </cq-validation-messages>
    </div>
  </div>

  <div class="margin-between-rows-10">
    <div class="custom-control custom-checkbox">
      <input
        id="member-invitation-permission-show-user-list"
        class="custom-control-input"
        [formControl]="controlPermissionsConversationUserList"
        type="checkbox"
      />
      <label
        class="custom-control-label"
        for="member-invitation-permission-show-user-list"
        >{{ t('form.permissionsConversationUserList') }}
      </label>
    </div>

    <div class="custom-control custom-checkbox">
      <input
        id="member-invitation-permission-export-checkbox"
        class="custom-control-input"
        [formControl]="controlPermissionsExport"
        type="checkbox"
      />
      <label
        class="custom-control-label"
        for="member-invitation-permission-export-checkbox"
        >{{ t('form.permissionsExport') }}
      </label>
    </div>

    <div class="custom-control custom-checkbox">
      <input
        id="member-invitation-permission-send-bulk-checkbox"
        class="custom-control-input"
        [formControl]="controlPermissionsSendBulk"
        type="checkbox"
      />
      <label
        class="custom-control-label"
        for="member-invitation-permission-send-bulk-checkbox"
        >{{ t('form.permissionsSendBulk') }}
      </label>
    </div>
  </div>
</ng-container>

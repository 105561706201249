<ng-container *transloco="let t; read: 'insertPropsIntoTextComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('title') }}</h3>
    <button
      class="close"
      (click)="dismissModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <form>
      <div class="form-group">
        <div class="mb-10">{{ t('props.description') }}</div>
        <cq-property-select
          [formControl]="propertyControl"
          [properties]="modalWindowParams.userProps"
        ></cq-property-select>
      </div>
      <div
        class="form-group no-margin-bottom"
        [cqShowError]="defaultValueControl"
      >
        <div class="mb-10">{{ t('default.description') }}</div>
        <div>
          <input
            class="form-control"
            [placeholder]="t('default.placeholder')"
            [formControl]="defaultValueControl"
          />
          <cq-validation-messages [control]="defaultValueControl">
            <cq-validation-message errorName="required">{{ t('default.errors.required') }}</cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      (click)="dismissModal()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <button
      [disabled]="!(defaultValueControl.value && propertyControl.value)"
      (click)="addProp()"
      type="submit"
      class="btn btn-primary"
    >
      {{ t('addProp') }}
    </button>
  </div>
</ng-container>

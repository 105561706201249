/**
 * Контроллер шапки настройки
 */
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventController', CqAutoEventController);

  function CqAutoEventController(trackMasterModel) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.removeAutoEvent = removeAutoEvent;
    }

    /**
     * Удаление автособытия
     */
    function removeAutoEvent() {
      firstValueFrom(trackMasterModel.remove(vm.id)).then(vm.onRemoved || angular.identity);
    }
  }
})();

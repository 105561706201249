import { translate } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';

import { getActionsBackgroundRect } from '@panel/app/pages/chat-bot/content/views/actions/shared-textures';
import {
  Branch,
  BranchStyle,
  DEFAULT_CARD_STYLE,
  NOT_VALID_CARD_STYLE,
} from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import {
  BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR,
  BOT_ACTION_BLOCK_LIGHTEN_PRIMARY_COLOR,
  BOT_ACTION_BLOCK_PRIMARY_COLOR,
  BOT_LIGHT_GREY,
  BOT_WHITE_COLOR,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { cloneObjectMutable } from '@panel/app/shared/functions/clone-object-mutable.function';
import { Immutable } from '@panel/app/shared/types/immutable.type';

const TEXT_PADDING = 10;

const ACTION_DEFAULT_STYLE: Immutable<BranchStyle> = {
  ...DEFAULT_CARD_STYLE,
  heading: {
    icon: {
      color: BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR,
      background: BOT_WHITE_COLOR,
    },
  },
};

export class ActionBlockView extends Branch {
  protected get headingIcon(): string {
    return '\ue9c4';
  }

  get description(): string | null {
    return translate('classes.block.action.description');
  }

  protected getCurrentStyle(): BranchStyle {
    const style: BranchStyle = cloneObjectMutable(ACTION_DEFAULT_STYLE);

    if (this.active) {
      style.border.color = BOT_ACTION_BLOCK_PRIMARY_COLOR;
      style.heading.icon.color = BOT_WHITE_COLOR;
      style.heading.icon.background = BOT_ACTION_BLOCK_PRIMARY_COLOR;
    } else if (!this.active && !this.valid) {
      style.border.color = NOT_VALID_CARD_STYLE.border.color;
      style.text.color = NOT_VALID_CARD_STYLE.text.color;
    }

    if (this.hovered) {
      style.border.color = BOT_ACTION_BLOCK_PRIMARY_COLOR;
      style.text.color = 0x22252a;
    }

    return style;
  }

  getContentHeight(): number {
    if (this.form.controls.actions.length > 1) {
      return super.getContentHeight();
    }
    return super.getContentHeight() + this.placeholder.height + this.actions[0].height - 5;
  }

  drawActions() {
    // FIXME: Это надо переделывтаь, потому что сейчас общаая логика рендера переопределяется каким-то костылем, в виде этой функции нижу
    super.drawActions();
    if (this.form.controls.actions.length === 1) {
      this.card.addChild(this.placeholder);
      this.placeholder.position.set(
        this.style.padding.horizontal,
        this.style.padding.vertical + (this.descriptionNode?.height ?? 0) + 10,
      );
      const nextAction = this.actions[0];
      nextAction.container.position.set(
        nextAction.container.x,
        this.placeholder.position.y + this.placeholder.height + 10,
      );
      this.actions[0].connection?.redraw();
    }
  }

  @tuiPure
  private get placeholder() {
    const placeholderText = renderCanvasText(translate('classes.block.action.placeholder'), {
      fill: BOT_LIGHT_GREY,
      wordWrap: false,
    });
    const rect = getActionsBackgroundRect(
      placeholderText.height + TEXT_PADDING * 2,
      BOT_ACTION_BLOCK_LIGHTEN_PRIMARY_COLOR,
      this.pixiApp.renderer,
    );
    rect.addChild(placeholderText);
    placeholderText.position.set(TEXT_PADDING, (rect.height - placeholderText.height) / 2);
    return rect;
  }
}

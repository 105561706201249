<div class="flex flex-col full-height window">
  <div class="header flex no-flex-shrink align-items-center">
    <div class="flex">
      <div class="button"></div>
      <div class="button"></div>
      <div class="button"></div>
    </div>
    <div class="address-bar flex-grow full-height mr-30 ml-30"></div>
    <img
      class="full-height"
      src="assets/img/default/browser-frame/default/tabs-button.svg"
      alt=""
    />
  </div>
  <div class="position-relative flex-grow">
    <ng-content></ng-content>
  </div>
</div>

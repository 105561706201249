import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

/** Компонент для работы с поиском по тегам */
@Component({
  selector: 'cq-search[searchPhrase][inputSearchPhrase]',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
  /** Фраза для поиска */
  @Input()
  searchPhrase!: string | null;

  /** Callback на ввод поисковой фразы */
  @Output()
  inputSearchPhrase: EventEmitter<string> = new EventEmitter<string>();

  /** Callback на сброс поиска */
  @Output()
  resetSearch: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  /** Обрабатывает клик по кнопке сброса поиска */
  onClickResetSearchButton() {
    this.resetSearchPhrase();

    this.resetSearch.emit();
  }

  /**
   * Обрабатывает поиск
   *
   * @param searchPhrase - Поисковая фраза
   */
  onInputSearchPhrase(searchPhrase: string): void {
    this.inputSearchPhrase.emit(searchPhrase);
  }

  /** Сбрасывает поисковую фразу */
  resetSearchPhrase(): void {
    this.searchPhrase = null;
    this.changeDetectorRef.detectChanges();
  }
}

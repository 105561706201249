import angular from 'angular';
import { firstValueFrom, Subject } from 'rxjs';
import { PLAN_FEATURE } from '../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { ChatBotModel } from '../../../../../app/http/chat-bot/chat-bot.model';
import { CHAT_BOT_ACTIONS_TYPES } from '../../../../../app/http/chat-bot/chat-bot.constants';
import { CHAT_BOT_TYPE } from '../../../../../app/http/chat-bot/types/chat-bot-external.types';
import { ChatBotActionMapper } from '../../../../../app/http/chat-bot/mappers/action.mapper';

(function () {
  'use strict';

  angular.module('myApp.chatBot.facebookBot').controller('cqFacebookBotEditController', cqFacebookBotEditController);

  function cqFacebookBotEditController(
    $filter,
    $state,
    $stateParams,
    $translate,
    $uibModal,
    $q,
    toastr,
    billingInfoModel,
    chatBotModel,
    defaultBotHelper,
    carrotquestHelper,
    paywallService,
    planVersionService,
    planFeatureAccessService,
  ) {
    /** Действия над Facebook-ботом */
    const ACTION_ON_FACEBOOK_BOT = {
      /** Активация/Приостановка */
      CHANGE_STATUS: 'change_status',
      /** Сохранение и запуск */
      SAVE_AND_LAUNCH: 'save_and_launch',
      /** Сохранение в режиме создания */
      SAVE_IN_CREATE: 'save_in_create',
      /** Сохранение в режиме редактирования */
      SAVE_IN_EDIT: 'save_in_edit',
    };

    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.activeBotsAmount = getActiveBotsAmount(vm.currentApp);
      vm.activeBotsIds = getActiveBotsIds(vm.currentApp);

      vm.accessToFacebookBots = planFeatureAccessService.getAccess(
        PLAN_FEATURE.FACEBOOK_BOTS,
        vm.currentApp,
        vm.activeBotsAmount,
      );

      vm.ACTION_ON_FACEBOOK_BOT = ACTION_ON_FACEBOOK_BOT;
      vm.activateBot = changeBotStatus.bind(vm, true);
      vm.bot = vm.facebookBot ?? defaultBotHelper.getInitialBot(CHAT_BOT_TYPE.FACEBOOK);
      vm.chatBotSnapshot = angular.copy(vm.bot);
      vm.deletedBranches = [];
      vm.getActionOnFacebookBot = getActionOnFacebookBot;
      vm.initUpdateBotFn = initUpdateBotFn;
      vm.isEditing = $state.is('app.content.messagesAjs.facebookBot.edit');
      vm.isPreventHasAccessToFacebookBots = isPreventHasAccessToFacebookBots;
      vm.onValidationCallbackReady = (validationCallback) => (vm.validate = validationCallback);
      vm.pauseBot = changeBotStatus.bind(vm, false);
      vm.paywallService = paywallService;
      vm.setBotSubject = new Subject();
      vm.setBot$ = vm.setBotSubject.asObservable();
      vm.save = save;
      vm.launchAndLeave = launchAndLeave;
      vm.validate = () => {};

      if (!vm.facebookBot) {
        vm.bot.name = $translate.instant('facebookBot.header.name');
      }

      if (!vm.isEditing) {
        trackOpenCreatePage();
      } else {
        trackOpenEditPage();
      }

      ChatBotActionMapper.parseActionsKeyName(vm.bot.branches, vm.properties);
    }

    /**
     * Меняет статус активности роутинг-бота
     * @param activateStatus
     * @returns {angular.IPromise<void[]>}
     */
    function changeBotStatus(activateStatus) {
      if (activateStatus) {
        increaseActiveBotsAmount(vm.currentApp, 1);
        vm.activeBotsIds.push(vm.facebookBot.id);
        trackSetActiveBot();
      } else {
        decreaseActiveBotsAmount(vm.currentApp, 1);
        vm.activeBotsIds.pop(vm.facebookBot.id);
        trackSetPauseBot();
      }

      save(activateStatus);
    }

    /** Получение набора действий над Facebook-ботом */
    function getActionOnFacebookBot() {
      if (vm.isEditing) {
        return [vm.ACTION_ON_FACEBOOK_BOT.CHANGE_STATUS, vm.ACTION_ON_FACEBOOK_BOT.SAVE_IN_EDIT];
      }

      return [vm.ACTION_ON_FACEBOOK_BOT.SAVE_IN_CREATE, vm.ACTION_ON_FACEBOOK_BOT.SAVE_AND_LAUNCH];
    }

    /**
     * Уменьшение количества активных ботов
     *
     * @param currentApp - Текущее приложение
     * @param decreaseAmount - Количество на которое уменьшить
     */
    function decreaseActiveBotsAmount(currentApp, decreaseAmount) {
      vm.activeBotsAmount = vm.activeBotsAmount - decreaseAmount;
    }

    /**
     * Получение количества активных ботов
     *
     * @param currentApp - Текущее приложение
     *
     * @return {number}
     */
    function getActiveBotsAmount(currentApp) {
      switch (true) {
        case planVersionService.isV1(currentApp):
        case planVersionService.isV2(currentApp):
          return (
            vm.dataAboutActiveFacebookBots.amount +
            vm.dataAboutActiveLeadBots.amount +
            vm.dataAboutActiveWelcomeBots.amount
          );
        default:
          return vm.dataAboutActiveLeadBots.amount;
      }
    }

    /**
     * Получение активных ID's ботов
     *
     * @param currentApp - Текущее приложение
     *
     * @return {number[]}
     */
    function getActiveBotsIds(currentApp) {
      switch (true) {
        case planVersionService.isV1(currentApp):
        case planVersionService.isV2(currentApp):
          return [
            ...vm.dataAboutActiveFacebookBots.ids,
            ...vm.dataAboutActiveLeadBots.ids,
            ...vm.dataAboutActiveWelcomeBots.ids,
          ];
        default:
          return vm.dataAboutActiveLeadBots.ids;
      }
    }

    /**
     * Получение веток, которые изменились после последнего сохранения
     *
     * @return {Object}
     */
    function getChangedBotBranches() {
      const changedBot = angular.copy(vm.bot);
      changedBot.branches = [];

      for (let i = 0; i < vm.bot.branches.length; i++) {
        const branch = vm.bot.branches[i];

        const branchSnapshot = $filter('filter')(vm.chatBotSnapshot.branches, { id: branch.id }, true)[0]; // Ветка до сохранения

        //Если нет id => это новая ветка
        // или имя веток отличается
        // или количество действий изменилось
        // или изменились координаты положения
        // => ветка поменялась
        if (
          !branch.id ||
          branchSnapshot.name !== branch.name ||
          branch.actions.length !== branchSnapshot.actions.length ||
          isCoordinatesChanged(branch.coordinates, branchSnapshot.coordinates)
        ) {
          changedBot.branches.push({ ...branch });
          continue;
        }

        for (let k = 0; k < branch.actions.length; k++) {
          const action = branch.actions[k];
          const filterQuery = action.id ? { id: action.id } : { linkId: action.linkId };
          const actionBranchSnapshot = $filter('filter')(branchSnapshot.actions, filterQuery, true)[0]; // Действие ветки до сохранения

          //Если есть нет id => это новое действие
          // или body отличается
          // или key_name отличается
          // или у прикрепления нет ID
          // или отличается порядок
          // или изменлось свойство NextBranch
          // => ветка поменялась
          if (
            !action.id ||
            // костыль: дополнительная проверка, потому что initial value данных с бэка и формы не совпадают
            (action.body !== actionBranchSnapshot.body && action.body !== null && actionBranchSnapshot.body !== '') ||
            (!angular.equals(action.bodyJson, actionBranchSnapshot.bodyJson) && action.bodyJson !== null) ||
            (action.keyName !== actionBranchSnapshot.keyName &&
              // костыль: дополнительная проверка, потому что initial value данных с бэка и формы не совпадают
              action.keyName !== null &&
              actionBranchSnapshot.keyName !== '') ||
            (action.type === CHAT_BOT_ACTIONS_TYPES.FILE && !action.attachments[0]?.id) ||
            action.order !== actionBranchSnapshot.order ||
            action.nextBranchLinkId !== actionBranchSnapshot.nextBranchLinkId
          ) {
            changedBot.branches.push({ ...branch });
            break;
          }
        }
      }

      return changedBot;

      function isCoordinatesChanged(branchCoords, branchSnapshotCoords) {
        // Считам что что-то поменялось если
        // branchSnapshot не имеет координат, а branch их получила
        // или branchSnapshot и branch имеет координаты и они не совпадают
        return (
          (!branchSnapshotCoords && branchCoords) ||
          // костыль: "> 3" сделано потому, что там при отрисовке происходит небольшое смещение, которое я не хочу править)
          (branchSnapshotCoords && branchCoords && Math.abs(branchSnapshotCoords.x - branchCoords.x) > 3) ||
          Math.abs(branchSnapshotCoords.y - branchCoords.y) > 3
        );
      }
    }

    /**
     * Увеличение количества активных ботов
     *
     * @param currentApp - Текущее приложение
     * @param increaseAmount - Количество на которое увеличить
     */
    function increaseActiveBotsAmount(currentApp, increaseAmount) {
      vm.activeBotsAmount = vm.activeBotsAmount + increaseAmount;
    }

    /**
     * Инициализация функции по обновлению бота
     *
     * @param {Function} cb
     */
    function initUpdateBotFn(cb) {
      vm.updateBot = cb;
    }

    /**
     * Нужно ли отменять работу accessToFacebookBots.hasAccess
     *
     * NOTE:
     *  Пользователь должен иметь возможность деактивировать facebook-бота,
     *  даже если у него нет доступа до фичи "Facebook-боты"
     *
     *  @return {boolean}
     */
    function isPreventHasAccessToFacebookBots() {
      if (vm.facebookBot?.id && vm.activeBotsIds.includes(vm.facebookBot.id)) {
        return true;
      }

      return false;
    }

    /**
     * Сливает бота полученного с сервера с текущим ботом
     *
     * @param {Object} chatBot - Чат-бот
     * @param {Object} mapIds - Сопоставление linkId и id
     */
    function mergeBotFromServer(chatBot, mapIds) {
      for (var linkId in mapIds) {
        var branch = $filter('filter')(vm.bot.branches, { linkId: linkId }, true)[0];

        branch.id = mapIds[linkId];

        branch.parentBranchIds.splice(-1, 1, branch.id);
      }

      for (var i = 0; i < vm.bot.branches.length; i++) {
        var branch = $filter('filter')(chatBot.branches, { id: vm.bot.branches[i].id }, true)[0];

        // Чтобы код ниже работал нормально, надо гарантировать, что в момент получения бота с созданными действиями
        // их количество и порядок был таким же как и в момент сохранения, т.е. пользователь не производил ни каких действий с действиями (сорян за тавтологию)
        // на текущий меомент это гарантируется лоадером
        // таким образом i-ое действие текущего бота и равно i-му действию бота, пришедшего с бекенда
        for (var g = 0; g < vm.bot.branches[i].actions.length; g++) {
          var action = vm.bot.branches[i].actions[g];
          action.id = branch.actions[g].id;

          if (ChatBotModel.isConnectionSourceAction(action.type) && action.nextBranchLinkId) {
            action.nextBranchId = branch.actions[g].nextBranchId;
          }
        }
      }
    }

    /**
     * Запустить и выходим к списку ботов
     */
    function launchAndLeave() {
      const activateStatus = true;

      save(activateStatus)
        .then(() => {
          vm.deletedBranches = [];
        })
        .catch(() => {});
    }

    /**
     * Функция сохранения бота
     * @param {boolean} activateStatus
     */
    function save(activateStatus) {
      // Надо ВСЕГДА выставлять allowUserReplies значение true
      vm.bot.allowUserReplies = true;

      return vm
        .validate()
        .then(vm.isEditing ? saveRoutingBot : createRoutingBot)
        .then((response) => {
          return vm.isEditing ? saveSuccess(response) : createSuccess(response);
        })
        .catch(saveOrCreateError)
        .finally(saveOrCreateFinally);

      function saveRoutingBot() {
        trackSaveBot();
        vm.isRequestPerforming = true;
        const botChanges = getChangedBotBranches();
        return firstValueFrom(
          chatBotModel.saveFacebookBot(
            vm.currentApp.id,
            {
              ...botChanges,
              active: activateStatus,
            },
            vm.deletedBranches,
          ),
        );
      }

      function createRoutingBot() {
        if (activateStatus) {
          trackCreateAndSetActive();
        } else {
          trackCreateAndSetPaused();
        }
        vm.isRequestPerforming = true;
        return firstValueFrom(chatBotModel.createFacebookBot(vm.currentApp.id, { ...vm.bot, active: activateStatus }));
      }

      function saveSuccess(response) {
        toastr.success($translate.instant('chatBot.toasts.botHasBeenSaved'));
        mergeBotFromServer(response.chatBot, response.mapIds);
        vm.routingBot.active = response.chatBot.active;
        vm.chatBotSnapshot = angular.copy(vm.bot);
        vm.deletedBranches = [];
        vm.setBotSubject.next({ chatBot: vm.bot, mapIds: response.mapIds, doNotRedrawCanvas: true });
        return $q.resolve();
      }

      function createSuccess(response) {
        $state.go('app.content.messagesAjs.facebookBot.edit', {
          facebookBotId: response.chatBot.id,
        });
        toastr.success($translate.instant('chatBot.toasts.botHasBeenSaved'));
        return $q.resolve();
      }

      function saveOrCreateError() {
        return $q.reject();
      }

      function saveOrCreateFinally() {
        vm.isRequestPerforming = false;
      }
    }

    /**
     * Трек открытия страницы создания
     */
    function trackOpenCreatePage() {
      carrotquestHelper.track('Facebook-бот - перешел на страницу создания');
    }

    /**
     * Трек открытия страницы редактирования
     */
    function trackOpenEditPage() {
      carrotquestHelper.track('Facebook-бот - зашел на страницу редактирования');
    }

    /**
     * Трек создания бота в статусе активен
     */
    function trackCreateAndSetActive() {
      carrotquestHelper.track('Facebook-бот - создал и запустил');
    }

    /**
     * Трек создания бота в статусе приостановлен
     */
    function trackCreateAndSetPaused() {
      carrotquestHelper.track('Facebook-бот - создал, но не запустил');
    }

    /**
     * Трек сохранения бота
     */
    function trackSaveBot() {
      carrotquestHelper.track('Facebook-бот - сохранил изменения');
    }

    /**
     * Трек смены статуса бота на "Активен"
     */
    function trackSetActiveBot() {
      carrotquestHelper.track('Facebook-бот - активировал');
    }

    /**
     * Трек смены статуса бота на "Приостановлен"
     */
    function trackSetPauseBot() {
      carrotquestHelper.track('Facebook-бот - приостановил');
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { PermissionDeniedModule } from '@panel/app/pages/permission-denied/permission-denied.module';

import { TrackMasterComponent } from './track-master.component';

@NgModule({
  declarations: [TrackMasterComponent],
  imports: [CommonModule, PermissionDeniedModule, TranslocoModule, UIRouterModule, NgbCollapseModule, FormsModule],
  exports: [TrackMasterComponent],
})
export class TrackMasterModule {}

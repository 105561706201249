import drop from 'lodash-es/drop';
import { Container, Graphics, ITextStyle, Text, TextStyle } from 'pixi.js';

import {
  CircleStyleForRender,
  ParsedCircleStyleForRender,
  ParsedRectangleStyleForRender,
  RectangleStyleForRender,
  TextStyleForRender,
} from '@panel/app/services/canvas/common/render/canvas-render.types';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';

/**
 * Сервис для работы с рендером представлений на canvas
 */
export class CanvasRenderService {
  /**
   * Расставляет элементы в контейнере по горизонтали
   *
   * @param elements - Элементы
   * @param containerStyle - Стиль контейнера
   */
  private static arrangeHorizontalInContainer(
    elements: Container[],
    containerStyle: ParsedRectangleStyleForRender,
  ): void {
    let parentWidth: number = elements[0].parent.width;
    let totalElementWidth: number = elements.reduce((width: number, element: Container) => width + element.width, 0);

    elements.reduce((elementX: number, element: Container, index: number) => {
      let x: number;
      let y: number;

      const isFirstElement = index === 0;

      switch (containerStyle.alignItems) {
        case 'start':
          y = containerStyle.padding.y;
          break;
        case 'center':
          // Паддинги уже учтены в высоте, так что мы просто вертикально центрируем элементы
          let elementWidthWithBorder = 0;
          //@ts-ignore
          if (element.line) {
            //@ts-ignore
            elementWidthWithBorder = element.line.width;
          }
          if (element.height < element.parent.height) {
            y = (element.parent.height - element.height) / 2 + elementWidthWithBorder;
          } else {
            y = 0;
          }
          break;
        case 'end':
          if (element.height < element.parent.height) {
            y = element.parent.height - element.height;
          } else {
            y = 0;
          }
          break;
      }

      switch (containerStyle.justifyContent) {
        case 'start':
          x = elementX;
          break;
        case 'center':
          x = elementX + (parentWidth - totalElementWidth) / 2;
          break;
        case 'end':
          let remainingElements: Container[] = drop(elements, index);
          let remainingElementWidth: number = remainingElements.reduce(
            (width: number, element: Container) => width + element.width,
            0,
          );
          x = parentWidth - remainingElementWidth;
          break;
        case 'space-between':
          let s = parentWidth - totalElementWidth;
          switch (index) {
            case 0:
              x = elementX + containerStyle.padding.x;
              break;
            case elements.length - 1:
              x = elementX + s - containerStyle.padding.x;
              break;
            default:
              x = elementX + s;
          }
          break;
      }

      x += isFirstElement ? containerStyle.padding.x : containerStyle.marginBetweenCols;

      element.position.set(x, y);

      return x + element.width;
    }, 0);
  }

  /**
   * Расставляет элементы в контейнере по вертикали
   *
   * @param elements - Элементы
   * @param containerStyle - Стиль контейнера
   */
  private static arrangeVerticalInContainer(
    elements: Container[],
    containerStyle: ParsedRectangleStyleForRender,
  ): void {
    let parent = elements[0].parent;
    let totalElementHeight: number = elements.reduce(
      (height: number, element: Container) => height + element.height,
      0,
    );

    elements.reduce((elementY: number, element: Container, index: number) => {
      let x: number;
      let y: number;

      const isFirstElement = index === 0;

      switch (containerStyle.alignItems) {
        case 'start':
          // Нет возможности протестировать, поэтому реализуйте по мере необходимости
          y = elementY;
          break;
        case 'center':
          y = elementY + (parent.height - containerStyle.padding.y * 2 - totalElementHeight) / 2;
          break;
        case 'end':
          // Нет возможности протестировать, поэтому реализуйте по мере необходимости
          throw new Error('Not implemented');
      }

      switch (containerStyle.justifyContent) {
        case 'start':
          x = containerStyle.padding.x;
          break;
        case 'center':
          // Паддинги уже учтены в высоте, так что мы просто вертикально центрируем элементы
          let elementHeightWithBorder = 0;
          //@ts-ignore
          if (element.line) {
            //@ts-ignore
            elementHeightWithBorder = element.line.height;
          }
          if (element.width < element.parent.width) {
            x = (element.parent.width - element.width) / 2 + elementHeightWithBorder;
          } else {
            x = 0;
          }
          break;
        case 'end':
          // Нет возможности протестировать, поэтому реализуйте по мере необходимости
          throw new Error('Not implemented');
        case 'space-between':
          // Нет возможности протестировать, поэтому реализуйте по мере необходимости
          throw new Error('Not implemented');
      }

      y += isFirstElement ? containerStyle.padding.y : containerStyle.marginBetweenRows;

      element.position.set(x, y);

      return y + element.height;
    }, 0);
  }

  /**
   * Получает высоту элементов
   *
   * @param elements - Элементы
   */
  private static getElementHeight(elements: Container[]): number {
    return elements.reduce((height: number, element: Container) => {
      return height + element.height;
    }, 0);
  }

  /**
   * Получает высоту контейнера относительно внутренних элементов
   *
   * @param elements - Элементы
   * @param containerStyle - Стиль контейнера
   */
  private static getHeight(elements: Container[], containerStyle: ParsedRectangleStyleForRender): number {
    let height: number;

    if (containerStyle.flexDirection === 'row') {
      height = calcHeightForRow();
    } else {
      height = calcHeightForColumn();
    }

    if (containerStyle.padding.y) {
      height += containerStyle.padding.y * 2;
    }

    return height;

    function calcHeightForRow() {
      return containerStyle.height === 'auto'
        ? Math.max(...elements.map((element: Container) => element.height))
        : containerStyle.height;
    }

    function calcHeightForColumn() {
      let baseHeight =
        containerStyle.height === 'auto'
          ? elements.map((e) => e.height).reduce((sum, value) => sum + value)
          : containerStyle.height;

      if (containerStyle.marginBetweenRows && elements.length > 1) {
        baseHeight += containerStyle.marginBetweenRows * (elements.length - 1);
      }

      return baseHeight;
    }
  }

  /**
   * Получает ширину контейнера относительно внутренних элементов
   *
   * @param elements - Элементы
   * @param containerStyle - Стиль контейнера
   */
  private static getWidth(elements: Container[], containerStyle: ParsedRectangleStyleForRender): number {
    let width: number;

    if (containerStyle.flexDirection === 'column') {
      width = calcWidthForColumn();
    } else {
      width = calcWidthForRow();
    }

    if (containerStyle.padding.x) {
      width += containerStyle.padding.x * 2;
    }

    return width;

    function calcWidthForColumn(): number {
      return containerStyle.width === 'auto'
        ? Math.max(...elements.map((element: Container) => element.width))
        : containerStyle.width;
    }

    function calcWidthForRow(): number {
      let baseWidth =
        containerStyle.width === 'auto'
          ? elements.map((e) => e.width).reduce((sum, value) => sum + value)
          : containerStyle.width;

      if (containerStyle.width === 'auto' && containerStyle.marginBetweenCols && elements.length > 1) {
        baseWidth += containerStyle.marginBetweenCols * (elements.length - 1);
      }

      return baseWidth;
    }
  }

  /**
   * Парсит стиль круга для Pixi и задаёт дефолтные значения
   *
   * @param style - Стили
   */
  private static parseCircleStyle(style?: CircleStyleForRender): ParsedCircleStyleForRender {
    let parsedStyle: ParsedCircleStyleForRender = {
      backgroundColor: HEX_COLOR.WHITE,
      backgroundAlpha: 1,
      radius: 0,
      ...style,
    };

    if (style?.borderColor && style.borderWidth) {
      parsedStyle.border = {
        color: style.borderColor,
        size: style.borderWidth,
      };
    }

    return parsedStyle;
  }

  /**
   * Парсит стиль прямоугольника для Pixi и задаёт дефолтные значения

   * @param style - Стиль
   */
  private static parseRectangleStyle(style: RectangleStyleForRender): ParsedRectangleStyleForRender {
    let parsedStyle: ParsedRectangleStyleForRender = {
      alignItems: 'start',
      flexDirection: 'row',
      justifyContent: 'start',
      marginBetweenCols: 0,
      marginBetweenRows: 0,
      padding: {
        x: 0,
        y: 0,
      },
      border: {},
      width: 'auto',
      height: 'auto',
    };

    if (style.backgroundColor) {
      parsedStyle.backgroundColor = style.backgroundColor;
    } else {
      parsedStyle.alpha = 0;
    }

    if (isDefined(style.borderColor)) {
      parsedStyle.border.color = style.borderColor;
    }

    if (isDefined(style.borderWidth)) {
      parsedStyle.border.size = style.borderWidth;
    }

    if (isDefined(style.borderRadius)) {
      parsedStyle.border.radius = style.borderRadius;
    }

    if (style.alignItems) {
      parsedStyle.alignItems = style.alignItems;
    }

    if (style.flexDirection) {
      parsedStyle.flexDirection = style.flexDirection;
    }

    if (style.justifyContent) {
      parsedStyle.justifyContent = style.justifyContent;
    }

    if (style.marginBetweenCols) {
      parsedStyle.marginBetweenCols = style.marginBetweenCols;
    }

    if (style.marginBetweenRows) {
      parsedStyle.marginBetweenRows = style.marginBetweenRows;
    }

    if (style.padding) {
      parsedStyle.padding = style.padding;
    }

    if (style.width) {
      parsedStyle.width = style.width;
    }

    if (style.height) {
      parsedStyle.height = style.height;
    }

    return parsedStyle;
  }

  /**
   * Парсит стиль текста для Pixi и задаёт дефолтные значения
   *
   * @param style - Стиль
   */
  private static parseTextStyle(style?: TextStyleForRender): TextStyle {
    let parsedStyle: Partial<ITextStyle> = {
      align: 'left',
      breakWords: true,
      fill: HEX_COLOR.GRAY_150,
      fontFamily: ['PT Root UI', 'Helvetica', 'Arial', 'sans-serif'],
      fontSize: 14,
      lineHeight: 20,
      wordWrap: true,
    };

    if (style) {
      if (style.color) {
        parsedStyle.fill = style.color;
      }

      if (isDefined(style.breakWords)) {
        parsedStyle.breakWords = style.breakWords;
      }

      if (style.fontFamily) {
        parsedStyle.fontFamily = style.fontFamily;
      }

      if (style.fontSize) {
        parsedStyle.fontSize = style.fontSize;
      }

      if (style.lineHeight) {
        parsedStyle.lineHeight = style.lineHeight;
      }

      if (style.textAlign) {
        parsedStyle.align = style.textAlign;
      }
      if (style.wordWrapWidth) {
        parsedStyle.wordWrapWidth = style.wordWrapWidth;
      }

      if (isDefined(style.wordWrap)) {
        parsedStyle.wordWrap = style.wordWrap;
      }

      if (isDefined(style.trim)) {
        parsedStyle.trim = style.trim;
      }
    }

    return new TextStyle(parsedStyle);
  }

  /**
   * Рендерит круг
   *
   * @param style - Стиль
   */
  static renderCircle(style?: CircleStyleForRender): Graphics {
    const styleConfig: ParsedCircleStyleForRender = this.parseCircleStyle(style);

    const graphics: Graphics = new Graphics();

    let radius: number = styleConfig.radius;
    let borderColor: number | undefined = styleConfig.border?.color;
    let borderSize: number | undefined = styleConfig.border?.size ?? 0;

    graphics.beginFill(styleConfig.backgroundColor, styleConfig.backgroundAlpha);
    graphics.lineStyle(borderSize, borderColor, undefined, 0);
    graphics.drawCircle(radius, radius, radius);
    graphics.endFill();

    return graphics;
  }

  /**
   * Рендерит элемент в круге
   *
   * @param element - Элемент
   * @param style - Стиль
   */
  static renderElementInCircle(element: Container, style?: CircleStyleForRender): Graphics {
    let circle: Graphics = this.renderCircle(style);

    circle.addChild(element);

    const backgroundsCenterX = (circle.width - element.width - (style?.borderWidth ?? 0)) / 2;
    const backgroundsCenterY = (circle.height - element.height - (style?.borderWidth ?? 0)) / 2;

    element.position.set(backgroundsCenterX, backgroundsCenterY);

    return circle;
  }

  /**
   * Рендерит элементы в контейнере
   */
  static renderElementsInRectangle(elements: Container[], containerStyle: RectangleStyleForRender): Graphics {
    let styleConfig: ParsedRectangleStyleForRender = this.parseRectangleStyle(containerStyle);

    let width: number = this.getWidth(elements, styleConfig);
    let height: number = this.getHeight(elements, styleConfig);

    let container: Graphics = this.renderRectangle(width, height, styleConfig);

    container.addChild(...elements);

    if (styleConfig.flexDirection === 'column') {
      this.arrangeVerticalInContainer(elements, styleConfig);
    } else {
      this.arrangeHorizontalInContainer(elements, styleConfig);
    }

    return container as Graphics;
  }

  /**
   * Рендерит иконку
   *
   * @param iconCode - Код иконки
   * @param style - Стиль
   */
  static renderIcon(iconCode: string, style?: TextStyleForRender): Text {
    let styleConfig: TextStyleForRender = {
      fontFamily: 'CQ-Icons-sm',
      textAlign: 'center',
      fontSize: 15,
      ...style,
    };

    return this.renderText(iconCode, styleConfig);
  }

  /**
   * Рендерит прямоугольник
   *
   * @param width - Ширина
   * @param height - Высота
   * @param style - Стиль
   * @param existingGraphicObject - Расширенный Graphics
   */
  static renderRectangle(
    width: number,
    height: number,
    style: ParsedRectangleStyleForRender,
    existingGraphicObject?: Graphics,
  ): Graphics {
    const graphics: Graphics = existingGraphicObject ? existingGraphicObject : new Graphics();

    let alpha: number | undefined = style.alpha;
    let backgroundColor: number | undefined = style.backgroundColor;
    let borderColor: number | undefined = style.border?.color;
    let borderRadius: number | undefined = style.border?.radius;
    let borderSize: number | undefined = style.border?.size ?? 0;

    graphics.lineStyle(borderSize, borderColor, alpha);

    if (backgroundColor) {
      graphics.beginFill(backgroundColor, alpha);
    } else {
      graphics.beginHole();
    }

    if (borderRadius) {
      graphics.drawRoundedRect(0, 0, width, height, borderRadius);
    } else {
      graphics.drawRect(0, 0, width, height);
    }

    if (backgroundColor) {
      graphics.endFill();
    } else {
      graphics.endHole();
    }

    return graphics;
  }

  /**
   * Рендерит текст
   *
   * @param text - Текст
   * @param style - Стиль
   */
  static renderText(text: string, style?: TextStyleForRender): Text {
    let styleConfig: TextStyle = this.parseTextStyle(style);

    return new Text(text, styleConfig);
  }
}

(function () {
  'use strict';

  angular.module('myApp.userCard').component('cqUserCardModal', cqUserCardModal());

  function cqUserCardModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve в функции modalWindowParams должны передаваться следующие параметры:
        // billingInfo - информация о биллинге
        // currentApp - текущее приложение
        // djangoUser - текущий пользователь
        // onOpenConversationClick: - '&?' функция, вызываемая при нажатии на кнопку перехода в диалог
        // onRedirectConversationClick: - '<?' нужно ли после нажатия на кнопку нужно перенаправить в раздел диалоги
        // userId - ID пользователя
        // telegramIntegrations
        // updateActiveConversation - обновление данных
      },
      controller: angular.noop,
      controllerAs: 'vm',
      template: require('./user-card-modal.html'),
    };
  }
})();

/**
 * Директива для создания iframe с подключением ангуляра и динамическим контентом внутри
 */
(function () {
  'use strict';

  angular.module('myApp.directives.dynamicIframe').directive('cqDynamicIframeAjs', cqDynamicIframe);

  require('./dynamic-iframe.controller');

  function cqDynamicIframe($window, PREVIEW_FRAME_URL) {
    return {
      restrict: 'E',
      require: 'cqDynamicIframeAjs',
      scope: {
        template: '<', // html-темплейт, который компилируется и вставляется ангуляром
        localeId: '<', // id локали, используется для i18n темплейта (подробнее смотри angular i18n, в качестве примера - локализация дат при использовании angular фильтра date)
        content: '<', // объект, ключи которого будут присвоены в контроллер, обязательный ключ - template (см. контроллер в dynamic-iframe.html)
        typeOs: '=', // Тип операционной системы для отображения превью "Push в SDK"
        // Эти свойства задаются атрибутами без значений (т.е. это Boolean атрибутами) и обрабатываются в link
        // fitByContentWidth подгонять ширину iframe под внутрненний контент
        // fitByContentHeight подгонять высоту iframe под внутрненний контент
      },
      controller: 'CqDynamicIframeController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
      template:
        '<iframe class="no-border" sandbox="allow-scripts allow-same-origin" src="' + PREVIEW_FRAME_URL + '"></iframe>',
    };

    function link(scope, iElement, iAttrs, controller) {
      var iframeElement = iElement.find('iframe');
      var windowElement = angular.element($window);
      var frameId = Math.random().toString(36).substring(2) + new Date().getTime().toString(36);

      iframeElement.on('load', registerIframe);
      scope.$on('$destroy', onDestroy);

      /**
       * Обработчик сообщений, поступающих от iframe
       *
       * @param event
       */
      function messageHandler(event) {
        scope.$apply(function () {
          var messageData = event.originalEvent.data;

          if (iframeElement[0].id == messageData.frameId && angular.isDefined(messageData.command)) {
            if (messageData.command == 'iframeRegistered') {
              // если снаружи что-то поменялось - обновить iframe (на случай, если будет использоваться один и тот же cqDynamicIframe для разных параметров)
              scope.$watch(
                'vm.localeId',
                function (newValue) {
                  controller.setLocale(iframeElement, newValue);
                },
                true,
              );

              scope.$watch(
                'vm.content',
                function (newValue) {
                  controller.updateIframe(iframeElement, newValue);
                },
                true,
              );

              scope.$watch(
                'vm.typeOs',
                function (newValue) {
                  controller.changeTypeOs(iframeElement, newValue);
                },
                true,
              );

              scope.$watch(
                'vm.template',
                function (newValue) {
                  controller.setTemplate(iframeElement, newValue);
                },
                true,
              );

              // если iframe был невидим и стали видимым - нужно принудительно его отресайзить, если это требуется, т.к. высота и ширина у потомков элемента с display: none равна 0
              scope.$watch(
                function () {
                  return iframeElement.is(':visible');
                },
                function (newValue, oldValue) {
                  if (newValue === true && oldValue === false) {
                    controller.forceResize(iframeElement);
                  }
                },
              );

              // если изменилась ширина iframe - значит, скорее всего, контент в высоту стал занимать больше места, поэтому iframe нужно принудительно отресайзить
              scope.$watch(
                function () {
                  return iframeElement.width();
                },
                function (newValue, oldValue) {
                  if (newValue != oldValue) {
                    controller.forceResize(iframeElement);
                  }
                },
              );

              // то же самое с высотой
              // NOTE: но не факт, потому что изменение высоты вряд ли повлияет на ширину, поэтому просто оставлю это тут
              scope.$watch(
                function () {
                  return iframeElement.height();
                },
                function (newValue, oldValue) {
                  if (newValue != oldValue) {
                    controller.forceResize(iframeElement);
                  }
                },
              );
            }

            if (messageData.command == 'resizeFrame') {
              if (angular.isDefined(messageData.width)) {
                controller.adjustWidth(iframeElement, messageData.width);
              }

              if (angular.isDefined(messageData.height)) {
                controller.adjustHeight(iframeElement, messageData.height);
              }
            }
          }
        });
      }

      /**
       * уборка мусора при уничтожении компонента
       */
      function onDestroy() {
        windowElement.off('message', messageHandler);
      }

      /**
       * Регистрация iframe
       * По-сути - задание ему ID, по которому можно будет отличать postMessage от разных iframe'ов, и создание обработчика postMessage
       * FIXME: наверное, в целях оптимизации, не имеет смысла создавать кучу обработчиков для каждого фрейма, а достаточно создать один обработчик, ибо в него будут сыпаться все сообщения от всех фреймов.
       */
      function registerIframe() {
        scope.$apply(function () {
          controller.setId(iframeElement, frameId);
          windowElement.on('message', messageHandler);
        });
      }
    }
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Компонент-preloader для счетов */
@Component({
  selector: 'cq-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {}

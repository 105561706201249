import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { UserService } from '@http/user/services/user.service';
import { SharedModule } from '@panel/app/shared/shared.module';

import { EditUserPropertyModalComponent } from './edit-user-property-modal.component';

@NgModule({
  declarations: [EditUserPropertyModalComponent],
  exports: [EditUserPropertyModalComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, TranslocoModule],
  providers: [UserService],
})
export class EditUserPropertyModalModule {}

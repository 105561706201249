import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserConsent } from '@http/user/types/user.type';
import {
  USER_CONSENT_MODAL_DATA_TOKEN,
  UserConsentModalData,
} from '@panel/app/partials/modals/user-consent-modal/user-consent-modal.token';

@Component({
  selector: 'cq-user-consent-modal',
  templateUrl: './user-consent-modal.component.html',
  styleUrls: ['./user-consent-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserConsentModalComponent {
  /** Соглашения пользователя */
  userConsents!: UserConsent[];

  constructor(
    public activeModal: NgbActiveModal,
    @Inject(USER_CONSENT_MODAL_DATA_TOKEN)
    private readonly modalParams: UserConsentModalData,
  ) {
    this.userConsents = this.modalParams.consents;
  }
}

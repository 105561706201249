import { environment } from '@environment';

/**
 * Ошибки, которые возникают при удалении члена команды
 */
export enum REMOVE_TEAM_MEMBER_ERRORS {
  NO_MORE_SUPER_ADMINS = 'noMoreSuperAdmins', // больше нет суперадминистраторов
  NO_MORE_TEAM_MEMBERS = 'noMoreTeamMembers', // больше нет членов команды
  REMOVE_SELF = 'removeSelf', // удаление себя
  REMOVE_TEAM_MEMBER = 'removeTeamMember', // удаление члена команды
}

/**
 * Типы псеводооператоров
 */
export enum PSEUDO_TEAM_MEMBERS_TYPES {
  ALL_TEAM_MEMBERS = 'allTeamMembers', // псевдооператор "Все операторы"
}

/**
 * Псевдооператоры и соответствующие им ID
 */
export const PSEUDO_TEAM_MEMBERS_IDS = {
  [PSEUDO_TEAM_MEMBERS_TYPES.ALL_TEAM_MEMBERS]: null,
};

/**
 * URL аватарки члена команды по умолчанию
 */
export const TEAM_MEMBER_DEFAULT_AVATAR_URL = `${environment.userFilesUrl}/avatars/default-v4.png`;

/**
 * Доступные роли для члена команды
 */
export enum TEAM_MEMBER_PERMISSIONS {
  ADMIN = 'admin', // администратор
  OPERATOR = 'operator', // оператор
  SUPER_ADMIN = 'superadmin', // супер-администратор
}

/**
 * Массив ролей члена команды
 *
 * !!! ВНИМАНИЕ! ЭТОТ МАССИВ ПОКАЗЫВАЕТ ПРИОРИТЕТ ОДНИХ РОЛЕЙ НАД ДРУГИМИ, ПОРЯДОК НЕ МЕНЯТЬ!
 */
export const TEAM_MEMBER_PERMISSIONS_ARRAY = [
  TEAM_MEMBER_PERMISSIONS.OPERATOR,
  TEAM_MEMBER_PERMISSIONS.ADMIN,
  TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN,
];

/**
 * Компонент для редактирования настроек поп-апа
 */
(function () {
  'use strict';

  require('./popup-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupSettings', cqPopupSettings());

  function cqPopupSettings() {
    return {
      bindings: {
        popupParams: '=', // настройки поп-апа
      },
      controller: 'CqPopupSettingsController',
      controllerAs: 'vm',
      template: require('./popup-settings.html'),
    };
  }
})();

import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import isEqual from 'lodash-es/isEqual';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MESSAGE_PAGE_TYPES, USER_STATUSES_FOR_SENDING } from '@http/message/message.constants';
import { MessageModel } from '@http/message/message.model';
import { Message } from '@http/message/message.types';
import {
  AutoMessageTriggerType,
  LeaveSiteAttemptTriggerType,
  OpenedSdkPageTriggerType,
  OpenedWebPageTriggerType,
} from '@http/message/trigger.types';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { EventType } from '@http/property/property.model';
import { TimeSelectorConfig } from '@panel/app/partials/message-editor/shared/time-selector/time-selector.component';
import { MESSAGE_PAGE_TYPE_TOKEN, messagePageTypeTokenProvider } from '@panel/app/partials/message-editor/tokens';
import { MessageEditorAnotherEventForm } from '@panel/app/partials/message-editor/trigger/another-events/another-event/message-editor-another-event.component';
import { FORM_SUBMIT_SOURCE_TOKEN } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { MessageEditorTriggerState } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-state.service';
import { MessageEditorOpenedSdkPageTriggerTypesComponent } from '@panel/app/partials/message-editor/trigger/opened-sdk-page-trigger-type/message-editor-opened-sdk-page-trigger-types.component';
import { MessageEditorOpenedWebPageTriggerTypesComponent } from '@panel/app/partials/message-editor/trigger/opened-web-page-trigger-type/message-editor-opened-web-page-trigger-types.component';
import { UrlFilter } from '@panel/app/partials/url-filter-configurator/single-url-filter-configurator/single-url-filter-configurator.component';
import { SENDING_FILTERS_GROUP_TYPES } from '@panel/app/services/conditions-sending/conditions-sending.constants';
import { Immutable } from '@panel/app/shared/types/immutable.type';
import { TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';
import { TimeUnitService } from '@panel/app-old/shared/services/time-unit/time-unit.service';

import { MESSAGE_EDITOR_SELECTED_TRIGGER } from '../trigger-select/message-editor-trigger-select.component';
import { ValidationCallback } from '../validation-callback.type';

export type MessageEditorTriggerSendingFilters = {
  type: SENDING_FILTERS_GROUP_TYPES;
  filters: UrlFilter[];
};

export type MessageEditorTriggerParams = {
  triggers: Array<string | null>;
  triggerTypes: AutoMessageTriggerType;
  delay: {
    isEnabled: boolean;
    value: TimeSelectorConfig;
  };
  sendingFilters: MessageEditorTriggerSendingFilters | null;
};

export type MessageEditorTriggerForm = {
  triggers: FormControl<MessageEditorAnotherEventForm[]>;
  openedWebPageTriggers: FormControl<OpenedWebPageTriggerType[]>;
  openedSdkPageTriggers: FormControl<OpenedSdkPageTriggerType[]>;
  leaveSiteAttemptTrigger: FormControl<LeaveSiteAttemptTriggerType>;
  delay: FormControl<{
    isEnabled: boolean;
    value: TimeSelectorConfig;
  }>;
  sendingFilters: FormControl<MessageEditorTriggerSendingFilters | null>;
};

@Component({
  selector: 'cq-message-editor-trigger[triggerParams][currentMessage][translationEntityName]',
  templateUrl: './message-editor-trigger.component.html',
  styleUrls: ['./message-editor-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [messagePageTypeTokenProvider],
})
export class MessageEditorTriggerComponent implements AfterViewInit {
  readonly form = this.fb.group<MessageEditorTriggerForm>({
    triggers: this.fb.control([], { nonNullable: true }),
    openedWebPageTriggers: this.fb.control([], { nonNullable: true }),
    openedSdkPageTriggers: this.fb.control([], { nonNullable: true }),
    leaveSiteAttemptTrigger: this.fb.control(false, { nonNullable: true }),
    delay: this.fb.control(
      {
        isEnabled: false,
        value: {
          time: 5,
          unit: TIME_UNITS.SECOND,
        },
      },
      { nonNullable: true },
    ),
    sendingFilters: this.fb.control(null, { nonNullable: true }),
  });

  @Input()
  set triggerParams(value: MessageEditorTriggerParams) {
    if (isEqual(this.form.value, value)) {
      return;
    }
    this.form.setValue({
      triggers: this.needToSetDefaultTrigger(value) ? [this.sessionStartEventTypeId] : value.triggers,
      openedWebPageTriggers: value.triggerTypes.openedWebPageTriggers,
      openedSdkPageTriggers: value.triggerTypes.openedSdkPageTriggers,
      leaveSiteAttemptTrigger: value.triggerTypes.leaveSiteAttemptTrigger,
      delay: value.delay,
      sendingFilters: value.sendingFilters,
    });
    this.cacheTriggers = {
      sessionStart: [this.sessionStartEventTypeId],
      triggers: this.triggers.value,
      openedWebPageTriggers: this.openedWebPageTriggers.value,
      openedSdkPageTriggers: this.openedSdkPageTriggers.value,
      leaveSiteAttemptTrigger: false,
    };
  }

  @Output()
  triggerParamsChange: Observable<MessageEditorTriggerParams> = this.form.valueChanges.pipe(
    map(() => {
      const rawValue = this.form.getRawValue();
      return {
        triggers: rawValue.triggers,
        triggerTypes: {
          openedWebPageTriggers: rawValue.openedWebPageTriggers,
          openedSdkPageTriggers: rawValue.openedSdkPageTriggers,
          leaveSiteAttemptTrigger: rawValue.leaveSiteAttemptTrigger,
        },
        delay: rawValue.delay,
        sendingFilters: rawValue.sendingFilters,
      };
    }),
  );

  @Output()
  ajsValidationFn: Subject<ValidationCallback> = new Subject();

  /**
   * В нормальной ситуации мы бы не стали передавать весь currentMessage, чтоб проверить какие-то на нем условия,
   * но т.к. корневой компонент на старом ангуляре и мы не можем привезти какой-то нормальный
   * state management - передаем вот так в ридонли виде
   */
  @Input()
  currentMessage!: Immutable<Message>;

  @Input()
  readonly translationEntityName!: string;

  prevSelectType!: MESSAGE_EDITOR_SELECTED_TRIGGER;

  cacheTriggers: {
    sessionStart: [string] | [];
    openedWebPageTriggers: OpenedWebPageTriggerType[];
    openedSdkPageTriggers: OpenedSdkPageTriggerType[];
    triggers: Array<string | null>;
    leaveSiteAttemptTrigger: boolean;
  } = {
    sessionStart: [],
    openedWebPageTriggers: [],
    openedSdkPageTriggers: [],
    triggers: [],
    leaveSiteAttemptTrigger: false,
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly messageModel: MessageModel,
    private readonly messagePartModel: MessagePartModel,
    protected readonly timeUnitService: TimeUnitService,
    private readonly transloco: TranslocoService,
    @Inject(MESSAGE_PAGE_TYPE_TOKEN)
    private readonly messagePageType: MESSAGE_PAGE_TYPES,
    private readonly messageEditorTriggerState: MessageEditorTriggerState,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSource: Subject<void>,
  ) {}

  ngAfterViewInit() {
    this.ajsValidationFn.next(() => {
      this.formSubmitSource.next();
      return this.form.status !== 'PENDING'
        ? Promise.resolve(this.form.valid)
        : firstValueFrom(
            this.form.statusChanges.pipe(
              filter((status) => status !== 'PENDING'),
              map(() => this.form.valid),
            ),
          );
    });
  }

  get delay() {
    return this.form.controls.delay;
  }

  get triggers() {
    return this.form.controls.triggers;
  }

  get openedWebPageTriggers() {
    return this.form.controls.openedWebPageTriggers;
  }

  get openedSdkPageTriggers() {
    return this.form.controls.openedSdkPageTriggers;
  }

  get leaveSiteAttemptTrigger() {
    return this.form.controls.leaveSiteAttemptTrigger;
  }

  get sendingFilters() {
    return this.form.controls.sendingFilters;
  }

  /** Возвращает событие по ID */
  @tuiPure
  getEventTypeByEventId(eventTypeId: string): EventType {
    const eventTypes = this.messageEditorTriggerState.eventTypes$.getValue();
    const eventType = eventTypes.find(({ id }) => id === eventTypeId);
    if (!eventType) {
      throw new Error(`Couldn't find that event`);
    }
    return eventType;
  }

  /** Возвращает название события */
  @tuiPure
  getMessageEventTriggerPrettyName(eventTypeId: string): string {
    if (this.isMessageEventTriggerRemoved(eventTypeId)) {
      return this.transloco.translate('general.deletedEvent');
    }

    const eventType = this.getEventTypeByEventId(eventTypeId);

    return eventType.prettyName;
  }

  isDispatchUrlConfigAllowed(): boolean {
    return !this.openedWebPageTriggers.value.length;
  }

  /** Является ли триггер сообщения удалённым */
  @tuiPure
  isMessageEventTriggerRemoved(eventId: string) {
    if (!eventId) {
      return;
    }
    const eventType = this.getEventTypeByEventId(eventId);

    return !eventType?.visible || eventType === undefined;
  }

  isMessageFilterableByUrl(): boolean {
    if (!this.currentMessage.parts || this.currentMessage.parts.length === 0) {
      return false;
    }

    return this.currentMessage.parts.every((messagePart) => {
      //@ts-ignore
      return this.messagePartModel.isFilterableByUrlType(messagePart.type);
    });
  }

  isSwitchDisabled(): boolean {
    return this.currentMessage.userStatuses === USER_STATUSES_FOR_SENDING.OFFLINE;
  }

  openedPageTriggerTrackByFn(index: number, openedPageTrigger: OpenedWebPageTriggerType): string {
    return openedPageTrigger.localId;
  }

  onTriggerTypeSelect(val: MESSAGE_EDITOR_SELECTED_TRIGGER): void {
    this.rewriteCache(this.prevSelectType);

    this.resetTriggers();

    switch (val) {
      case MESSAGE_EDITOR_SELECTED_TRIGGER.VISITED_WEBSITE:
        this.triggers.setValue(this.cacheTriggers.sessionStart);
        break;
      case MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE:
        if (this.cacheTriggers.openedWebPageTriggers.length) {
          this.openedWebPageTriggers.setValue(this.cacheTriggers.openedWebPageTriggers);
          break;
        }
        this.openedWebPageTriggers.setValue([
          MessageEditorOpenedWebPageTriggerTypesComponent.getNewControl().getRawValue(),
        ]);
        break;
      case MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_SDK_PAGE:
        if (this.cacheTriggers.openedSdkPageTriggers.length) {
          this.openedSdkPageTriggers.setValue(this.cacheTriggers.openedSdkPageTriggers);
          break;
        }
        this.openedSdkPageTriggers.setValue([
          MessageEditorOpenedSdkPageTriggerTypesComponent.getNewControl().getRawValue(),
        ]);
        break;
      case MESSAGE_EDITOR_SELECTED_TRIGGER.LEAVE_SITE_ATTEMPT:
        this.leaveSiteAttemptTrigger.setValue(true);
        break;
      case MESSAGE_EDITOR_SELECTED_TRIGGER.ANOTHER_EVENT:
        if (this.cacheTriggers.triggers.length && this.cacheTriggers.triggers[0] !== this.sessionStartEventTypeId) {
          this.triggers.setValue(this.cacheTriggers.triggers);
          break;
        }
        this.triggers.setValue([null]);
        break;
    }

    this.prevSelectType = val;
  }

  /** Перезаписываем кэш перед полной отчисткой форм */
  rewriteCache(selectType: MESSAGE_EDITOR_SELECTED_TRIGGER) {
    switch (selectType) {
      case MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE:
        this.cacheTriggers.openedWebPageTriggers = this.openedWebPageTriggers.value;
        break;
      case MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_SDK_PAGE:
        this.cacheTriggers.openedSdkPageTriggers = this.openedSdkPageTriggers.value;
        break;
      case MESSAGE_EDITOR_SELECTED_TRIGGER.ANOTHER_EVENT:
        this.cacheTriggers.triggers = this.triggers.value;
        break;
    }
  }

  get selectedTriggerType(): MESSAGE_EDITOR_SELECTED_TRIGGER {
    if (this.openedSdkPageTriggers.value.length) {
      return MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_SDK_PAGE;
    }
    if (this.openedWebPageTriggers.value.length) {
      return MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE;
    }
    if (this.leaveSiteAttemptTrigger.value) {
      return MESSAGE_EDITOR_SELECTED_TRIGGER.LEAVE_SITE_ATTEMPT;
    }
    if (this.triggers.value.length > 1 || this.triggers.value[0] !== this.sessionStartEventTypeId) {
      return MESSAGE_EDITOR_SELECTED_TRIGGER.ANOTHER_EVENT;
    }
    return MESSAGE_EDITOR_SELECTED_TRIGGER.VISITED_WEBSITE;
  }

  @tuiPure
  get sessionStartEventTypeId() {
    const sessionStartEventType = this.messageEditorTriggerState.eventTypes$
      .getValue()
      .find((eventType) => eventType.name === '$session_start');
    if (!sessionStartEventType) {
      throw new Error('Could not find session start event type');
    }
    return sessionStartEventType.id;
  }

  get timeUnits(): TIME_UNITS[] | undefined {
    const timeUnits: TIME_UNITS[] = this.currentMessage.afterTimeTimeUnits.map((unitTime) => unitTime);
    return timeUnits;
  }

  needToSetDefaultTrigger(triggerParams: MessageEditorTriggerParams): boolean {
    return !(
      triggerParams.triggers.length ||
      triggerParams.triggerTypes.openedWebPageTriggers.length ||
      triggerParams.triggerTypes.openedSdkPageTriggers.length ||
      triggerParams.triggerTypes.leaveSiteAttemptTrigger
    );
  }

  private resetTriggers() {
    this.triggers.reset([]);
    this.leaveSiteAttemptTrigger.reset(false);
    this.openedWebPageTriggers.reset([]);
    this.openedSdkPageTriggers.reset([]);
    this.sendingFilters.reset({
      type: SENDING_FILTERS_GROUP_TYPES.NO,
      filters: [],
    });
  }
}

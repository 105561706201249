import { UserColorsCalculationService } from '../../../app/shared/services/user-colors-calculation/user-colors-calculation.service';
import { AUTO_REPLY_TYPE } from '../../../app/http/app/app.constants';
import { InstallScriptModalComponent } from '../../../app/shared/modals/install-script-modal/install-script-modal.component';
import { BOT_SENDER_DEFAULT_AVATAR_URL } from '../../../app/http/message-sender/message-sender.constants';
import { MESSAGE_PART_TYPES, POPUP_CHAT_TYPES } from '../../../app/http/message-part/message-part.constants';
import { firstValueFrom } from 'rxjs';
import { CHAT_VISIBILITY_STATUSES } from '../../../app/http/chat-settings/chat-settings.constants';
import { FEATURES } from '../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqChatSettingsController', CqChatSettingsController);

  function CqChatSettingsController(
    $anchorScroll,
    $location,
    $translate,
    $timeout,
    $uibModal,
    $scope,
    $state,
    toastr,
    CDN_ENDPOINT,
    DEFAULT_ORDER_SOCIAL_NETWORKS,
    WIDGET_BADGE_TYPES,
    appModel,
    carrotquestHelper,
    chatBotModel,
    chatSettingsModel,
    featureModel,
    l10nHelper,
    planVersionService,
    systemError,
    modalHelperService,
  ) {
    let vm = this;

    /**
     * Названия секций настроек чата
     *
     * @type {{CHAT_BUTTON: string, CONVERSATION_CLOSE: string, GENERAL_SETTINGS: string, OPERATOR_ANSWER: string, SOUND_NOTIFICATIONS: string}}
     */
    const SETTINGS_SECTIONS = {
      CHAT_BUTTON: 'chatButton',
      CONVERSATION_CLOSE: 'conversationClose',
      GENERAL_SETTINGS: 'generalSettings',
      OPERATOR_ANSWER: 'operatorAnswer',
      SOUND_NOTIFICATIONS: 'soundNotifications',
    };

    vm.$onInit = init;

    function init() {
      vm.chatSettings = angular.copy(vm.currentApp.settings);

      vm.accentHexColor = vm.chatSettings.messenger_collapsed_color;
      vm.accentHoveredColor = vm.chatSettings.messenger_collapsed_color; // Пользовательский hover-цвет
      vm.AUTO_REPLY_TYPES = AUTO_REPLY_TYPE;
      vm.badgeType = !!vm.chatSettings.messenger_avatar_big ? WIDGET_BADGE_TYPES.AVATAR : WIDGET_BADGE_TYPES.DEFAULT; // тип аватарки
      vm.botAvatar =
        !vm.botSender || vm.botSender.avatar === BOT_SENDER_DEFAULT_AVATAR_URL
          ? UserColorsCalculationService.changeDefaultAvatarByCondition(
              vm.chatSettings.messenger_collapsed_color,
              vm.chatSettings.messenger_theme,
              BOT_SENDER_DEFAULT_AVATAR_URL,
            )
          : vm.botSender.avatar;
      vm.CDN_ENDPOINT = CDN_ENDPOINT;
      vm.chatSettingsForm = null;
      vm.checkChangedSettings = checkChangedSettings;
      vm.contrastColor = '#ffffff';
      vm.doAccentAndWhiteMatchGood = true;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.getVisibilityValidity = () => true;
      vm.iconTheme = 'default';
      vm.isContrastGood = true;
      vm.isFreemium = planVersionService.isFreemium();
      vm.isMainSettings = true;
      vm.isRoutingBotActive = isRoutingBotActive;
      vm.isWaitingOperatorResponse = true;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.mobileIndentCollapsed = true; // сворачивание настройки отступов для мобильной версии кнопки чата
      vm.mobileIndentForm = null; // форма позиции кнопки чата на мобильной версии сайта
      vm.modeSettings = vm.chatSettings.messenger_mode_settings;
      vm.newAvatar = null; // новая своя иконка в виджете
      vm.onChangeAudioMessageValue = onChangeAudioMessageValue;
      vm.onChangeSocialButtons = onChangeSocialButtons;
      vm.onChangeStatusSocialButtons = onChangeStatusSocialButtons;
      vm.onChangeSocialValid = onChangeSocialValid;
      vm.onChatWidgetOrderChanged = onChatWidgetOrderChanged;
      vm.onModeChange = onModeChange;
      vm.onModeSettingsChange = onModeSettingsChange;
      vm.onValidationTrigger = onValidationTrigger;
      vm.openStartScreenModal = openStartScreenModal;
      vm.orderedSocialNetworks = updateOrderSocialNetworks();
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.save = save;
      vm.sectionsStates = {
        // состояния свёрнутости секций настроек
        [SETTINGS_SECTIONS.GENERAL_SETTINGS]: {
          collapsed: true,
          form: null,
        },
        [SETTINGS_SECTIONS.CHAT_BUTTON]: {
          collapsed: true,
          form: null,
        },
        [SETTINGS_SECTIONS.OPERATOR_ANSWER]: {
          collapsed: true,
          form: null,
        },
        [SETTINGS_SECTIONS.CONVERSATION_CLOSE]: {
          collapsed: true,
          form: null,
        },
        [SETTINGS_SECTIONS.SOUND_NOTIFICATIONS]: {
          collapsed: true,
          form: null,
        },
      };
      vm.setDefaultAvatar = setDefaultAvatar;
      vm.SETTINGS_SECTIONS = SETTINGS_SECTIONS;
      vm.socialSettingsValid = true;
      vm.toggleCollapse = toggleCollapse;
      vm.trackClickChangeToNewSchedule = trackClickChangeToNewSchedule; // REVIEW не могу найти для чего это используется
      vm.trackClickOnSave = trackClickOnSave;
      vm.trackClickOnSectionHeader = trackClickOnSectionHeader;
      vm.trackClickShowPrivacyPolicyLink = trackClickShowPrivacyPolicyLink; // REVIEW не могу найти для чего это используется
      vm.trackingDataKnown = false; // Знаем ли мы данные пользователя
      vm.userColorAndThemeDependent = vm.accentHexColor;
      vm.WIDGET_BADGE_TYPES = WIDGET_BADGE_TYPES;
      vm.workingTime = false; // Рабочее ли сейчас время

      if ($location.hash()) {
        // если есть якорь, то надо к нему прокрутить.Таймаут - чтобы прокрутка вызвалась, после прогрузки html
        $timeout($anchorScroll);
      }
      // vm.chatSettings.messenger_collapsed_color
      $scope.$watchGroup(
        ['vm.chatSettings.messenger_collapsed_color', 'vm.chatSettings.messenger_theme'],
        function (newValues, oldValues) {
          const [color, theme] = newValues;
          calcChatColors(color);
        },
      );

      $scope.$watch(
        'vm.chatSettings.messenger_icon_order',
        () => {
          vm.orderedSocialNetworks = updateOrderSocialNetworks();
        },
        true,
      );

      $scope.$watchGroup(
        [
          'vm.chatSettings.messenger_icon_vk_show',
          'vm.chatSettings.messenger_icon_facebook_show',
          'vm.chatSettings.messenger_icon_instagram_show',
          'vm.chatSettings.messenger_icon_telegram_show',
          'vm.chatSettings.messenger_icon_viber_show',
          'vm.chatSettings.messenger_icon_whatsapp_show',
        ],
        () => {
          vm.orderedSocialNetworks = updateOrderSocialNetworks();
        },
      );
    }

    /**
     * Вся логика - копипаста из репо cqstatic. Смотреть файл getUserColorPalette.js
     */
    function calcChatColors(newColor) {
      // В статике есть логика того, что мы меняем его цвет, если цвет у клиента слишком светлый в светлой теме
      vm.accentHexColor = UserColorsCalculationService.getMainUserColor(newColor, vm.chatSettings.messenger_theme);

      const {
        accentHoveredHexColor,
        isLightOnClientsColorLooksWell,
        contrastColor,
        userColorAndThemeDependent,
        iconTheme,
        isContrastGood,
      } = UserColorsCalculationService.getUserColorPalette(vm.accentHexColor, vm.chatSettings.messenger_theme);

      vm.accentHoveredColor = accentHoveredHexColor;
      vm.contrastColor = contrastColor;
      vm.userColorAndThemeDependent = userColorAndThemeDependent;
      vm.doAccentAndWhiteMatchGood = isLightOnClientsColorLooksWell;
      vm.iconTheme = iconTheme;
      vm.isContrastGood = isContrastGood;
    }

    /**
     * Проверка измененных настроек
     */
    function checkChangedSettings() {
      if (!angular.equals(vm.currentApp.settings['messenger_mode'], vm.chatSettings['messenger_mode'])) {
        trackChangeMessengerModeSetting(vm.chatSettings['messenger_mode']);
      }
    }

    /**
     * Активность бота
     * @returns {boolean}
     */
    function isRoutingBotActive() {
      return !!vm.routingBot && vm.routingBot.active;
    }

    /**
     * Колбэк на изменение значения настройки «Голосовые сообщения в чате»
     *
     * @param {boolean} value
     */
    function onChangeAudioMessageValue(value) {
      vm.chatSettings.messenger_enable_audio_messages = value;

      trackChangeAudioMessageValue(value);
    }

    /**
     * Обработка изменений в компоненте соц.кнопок
     */
    function onChangeSocialButtons(obj) {
      // не самое лучшее решение, по другому нужна интеграция новых ReactiveForm в старые от AngularJS
      Object.assign(vm.chatSettings, obj);
      $timeout();
    }

    /**
     * Подписка на изменение статуса валидности формы в Angular2+
     * @param {String} status: 'VALID' | 'INVALID'
     */
    function onChangeStatusSocialButtons(status) {
      switch (status) {
        case 'VALID':
          // кнопки социальных сетей находятся в секции SETTINGS_SECTIONS.CHAT_BUTTON, поэтому у неё и выставляется валидность
          vm.sectionsStates[SETTINGS_SECTIONS.CHAT_BUTTON].form.$setValidity('required', true);
          break;
        case 'INVALID':
          vm.sectionsStates[SETTINGS_SECTIONS.CHAT_BUTTON].form.$setValidity('required', false);
          break;
      }
    }

    /**
     * Подписка на изменение статуса валидности социальной сети
     *
     * @param {boolean} status
     */
    function onChangeSocialValid(status) {
      vm.socialSettingsValid = status;
    }

    /**
     * Колбэк на изменение порядка чат-виджетов
     *
     * @param {ActiveChatWidget[]} newChatWidgetListOrder
     */
    function onChatWidgetOrderChanged(newChatWidgetListOrder) {
      $scope.$apply(() => {
        vm.activeChatWidgetList = [...newChatWidgetListOrder];
      });
    }

    /**
     * Колбэк при смене режима видимости чата
     *
     * @param {VISIBILITY_MODE} mode Режим видимости чата
     */
    function onModeChange(mode) {
      vm.chatSettings.messenger_mode = mode;
    }

    /**
     * Колбэк при смене расширенных настроек видимости чата
     *
     * @param {ModeSettingsExternal} settings Расширенные настройки
     */
    function onModeSettingsChange(settings) {
      vm.chatSettings.messenger_mode_settings = settings;
    }

    /**
     * Колбэк для вызова валидации из AJS, так как форма внутри A2
     *
     * @param fn { Function }
     */
    function onValidationTrigger(fn) {
      vm.getVisibilityValidity = fn;
    }

    /**
     * Открытие модалки с отключением стартового экрана
     */
    function openStartScreenModal() {
      const startScreenDisableModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.messageDirectoryEditor.startScreenDisableModal.header'),
              body: $translate.instant('modals.messageDirectoryEditor.startScreenDisableModal.body'),
              confirmButtonText: $translate.instant('modals.messageDirectoryEditor.startScreenDisableModal.submit'),
              needConfirmation: false,
            };
          },
        },
        size: 'md modal-dialog-centered',
      });

      startScreenDisableModal.result.then(() => {
        trackClickOnDisableStartScreenButton();
      });
    }

    /**
     * Сохранение настроек
     *
     * @param {Boolean} valid Флаг валидности формы
     */
    function save(valid) {
      if (!valid || !vm.socialSettingsValid) {
        //Если обязательные поля не заполены выводим "Что-то пошло не так"
        systemError.somethingWentWrongToast.show();

        Object.values(vm.sectionsStates)
          .filter((state) => state.form.$invalid)
          .forEach((state) => (state.collapsed = false));

        // отступы кнопки чата не вводить нельзя, они всегда required, поэтому если в них ошибка - нужно раскрыть collapse
        if (vm.mobileIndentForm.$invalid) {
          vm.mobileIndentCollapsed = false;
        }

        return;
      }

      // если ошибка произошла в настройках видимости чата - разворачиваем первый раздел
      if (!vm.getVisibilityValidity()) {
        vm.sectionsStates[SETTINGS_SECTIONS.GENERAL_SETTINGS].collapsed = false;
      }

      if (vm.getVisibilityValidity()) {
        let settings = {
          dialog_final_closed_interval: vm.chatSettings.dialog_final_closed_interval,
          messenger_show_kb_link_in_welcome_message: vm.knowledgeBase
            ? vm.chatSettings.messenger_show_kb_link_in_welcome_message
            : false,
          messenger_always_show_tooltip: vm.chatSettings.messenger_always_show_tooltip,
          messenger_auto_reply_show_config: vm.chatSettings.messenger_auto_reply_show_config,
          messenger_auto_reply_texts: vm.chatSettings.messenger_auto_reply_texts,
          messenger_auto_reply_type: vm.chatSettings.messenger_auto_reply_type,
          messenger_avatar: vm.chatSettings.messenger_avatar,
          messenger_collapsed_animations: vm.chatSettings.messenger_collapsed_animations,
          messenger_collapsed_color: vm.chatSettings.messenger_collapsed_color.split('#')[1],
          messenger_collapsed_text: vm.chatSettings.messenger_collapsed_text,
          messenger_enable_audio_messages: vm.chatSettings.messenger_enable_audio_messages,
          messenger_hide_collapsed: vm.chatSettings.messenger_hide_collapsed,
          messenger_icon_facebook_show: vm.chatSettings.messenger_icon_facebook_show,
          messenger_icon_facebook_text: vm.chatSettings.messenger_icon_facebook_text || '',
          messenger_icon_telegram_show: vm.chatSettings.messenger_icon_telegram_show,
          messenger_icon_telegram_text: vm.chatSettings.messenger_icon_telegram_text || '',
          messenger_icon_viber_show: vm.chatSettings.messenger_icon_viber_show,
          messenger_icon_viber_text: vm.chatSettings.messenger_icon_viber_text || '',
          messenger_icon_vk_show: vm.chatSettings.messenger_icon_vk_show,
          messenger_icon_vk_text: vm.chatSettings.messenger_icon_vk_text || '',
          messenger_icon_instagram_show: vm.chatSettings.messenger_icon_instagram_show,
          messenger_icon_instagram_text: vm.chatSettings.messenger_icon_instagram_text || '',
          messenger_icon_order: vm.chatSettings.messenger_icon_order || DEFAULT_ORDER_SOCIAL_NETWORKS,
          messenger_icon_whatsapp_show: vm.chatSettings.messenger_icon_whatsapp_show,
          messenger_icon_whatsapp_text: vm.chatSettings.messenger_icon_whatsapp_text || '',
          messenger_indent: vm.chatSettings.messenger_indent,
          messenger_mobile_indent: vm.chatSettings.messenger_mobile_indent,
          messenger_mode: vm.chatSettings.messenger_mode,
          messenger_mode_settings: vm.chatSettings.messenger_mode_settings,
          messenger_name: vm.chatSettings.messenger_name,
          messenger_offline_if_no_operators: vm.chatSettings.messenger_offline_if_no_operators,
          messenger_offline_message: vm.chatSettings.messenger_offline_message,
          messenger_online_message: vm.chatSettings.messenger_online_message,
          messenger_pattern: vm.chatSettings.messenger_pattern,
          messenger_position: vm.chatSettings.messenger_position,
          messenger_show_offline_message: vm.chatSettings.messenger_show_offline_message,
          messenger_show_powered_by: vm.chatSettings.messenger_show_powered_by,
          messenger_sound_part: vm.chatSettings.messenger_sound_part,
          messenger_sound_popup: vm.chatSettings.messenger_sound_popup,
          messenger_enable_new_conversation_button: vm.chatSettings.messenger_enable_new_conversation_button,
          messenger_theme: vm.chatSettings.messenger_theme,
          messenger_user_notification_timeout: vm.chatSettings.messenger_user_notification_timeout,
          messenger_vote: vm.chatSettings.messenger_vote,
          messenger_welcome_message: vm.chatSettings.messenger_welcome_message,
          messenger_work_time: vm.chatSettings.messenger_work_time,
        };

        if (vm.newAvatar) {
          settings.messenger_avatar = vm.newAvatar;
        }
        settings.set_default_messenger_avatar = settings.messenger_avatar === 'default-v3.png';

        firstValueFrom(chatSettingsModel.save(vm.currentApp.id, settings))
          .then(() => {
            if (vm.activeChatWidgetList.length === 0) {
              return;
            }

            const chatWidgetIdArray = vm.activeChatWidgetList.map((chatWidget) => chatWidget.id);

            return firstValueFrom(chatBotModel.saveChatWidgetListOrder(vm.currentApp.id, chatWidgetIdArray)).then(
              (response) => (vm.activeChatWidgetList = response),
            );
          })
          .then(saveSuccess);
      }

      function saveSuccess() {
        toastr.success($translate.instant('chatSettings.toasts.chatSettingsSaveSuccess'));

        if (appModel.isAppBlocked(vm.currentApp)) {
          let modal = modalHelperService.open(InstallScriptModalComponent);

          modal.componentInstance.modalWindowParams = {
            body: $translate.instant('chatSettings.installScriptModal.body'),
            currentApp: vm.currentApp,
            djangoUser: vm.djangoUser,
            heading: $translate.instant('chatSettings.installScriptModal.heading'),
          };
        }

        angular.extend(vm.currentApp.settings, vm.chatSettings);
      }
    }

    /**
     * Установка стандартного аватара
     *
     * @param {WIDGET_BADGE_TYPES} badgeType Тип иконки в виджете
     */
    function setDefaultAvatar(badgeType) {
      if (badgeType === WIDGET_BADGE_TYPES.DEFAULT) {
        vm.chatSettings.messenger_avatar = 'default-v3.png';
        vm.newAvatar = null;
        vm.newAvatarSrc = null;
      }
    }

    /**
     * Переключение состояния свёрнутости секции настроек чата
     *
     * @param {SETTINGS_SECTIONS} sectionName Секция настроек чата
     * @param {Boolean} collapsingState Новое состояние свёрнутости секции
     */
    function toggleCollapse(sectionName, collapsingState) {
      vm.sectionsStates[sectionName].collapsed = collapsingState;
    }

    /**
     * Трек клика на кнопку "Включить" в конфирме при переключении с автоответов на расписание операторов
     */
    function trackClickChangeToNewSchedule() {
      carrotquestHelper.track('Автоответ в чате - клик включить/выключить в нерабочее время', {
        app_id: vm.currentApp.id,
      });
    }

    /**
     * Трек клика на кнопку "Начать диалог и вернуть старый дизайн"
     */
    function trackClickOnDisableStartScreenButton() {
      const eventName = 'Клик по кнопке откатиться на старый виджет чата';
      const params = {
        app_id: vm.currentApp.id,
      };
      carrotquestHelper.track(eventName, params);
    }

    /**
     * Трек клика на кнопку "Сохранить настройки"
     */
    function trackClickOnSave() {
      carrotquestHelper.track('Настройки чата - кликнул на "Сохранить настройки"', { app: vm.currentApp.name });

      // Дополнительно проверяем была ли выбрана настройка с созданием нового диалога
      if (vm.chatSettings.messenger_enable_new_conversation_button) {
        carrotquestHelper.track('Настройки чата - выбрал опцию "Создавать новый диалог при каждом нажатии"', {
          app: vm.currentApp.name,
        });
      }
    }

    /**
     * Трек клика на заголовок секции настроек
     *
     * @param {SETTINGS_SECTIONS} sectionName Название секции
     * @param {boolean} collapsing Открывается (false)/закрывается (true) ли секция
     */
    function trackClickOnSectionHeader(sectionName, collapsing) {
      let name;

      switch (sectionName) {
        case SETTINGS_SECTIONS.CHAT_BUTTON:
          name = 'Кнопка чата';
          break;
        case SETTINGS_SECTIONS.CONVERSATION_CLOSE:
          name = 'Завершение диалога';
          break;
        case SETTINGS_SECTIONS.GENERAL_SETTINGS:
          name = 'Основные';
          break;
        case SETTINGS_SECTIONS.OPERATOR_ANSWER:
          name = 'Ожидание ответа';
          break;
        case SETTINGS_SECTIONS.SOUND_NOTIFICATIONS:
          name = 'Звуковые уведомления';
          break;
      }

      // событие отрабатывает только при открытии секции настроек
      if (!collapsing) {
        carrotquestHelper.track(`Настройки чата – открыл блок "${name}"`);
      }
    }

    /**
     * Трек клика на "Показывать ссылку на политику конфиденциальности"
     */
    function trackClickShowPrivacyPolicyLink() {
      carrotquestHelper.track('Настройки чата - кликнул на "Показывать ссылку на политику конфиденциальности"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек изменения настройки «Голосовые сообщения в чате»
     *
     * @param {boolean} value Значение настройки
     */
    function trackChangeAudioMessageValue(value) {
      const eventName = value
        ? 'Настройки чата - включил отправку аудиосообщений'
        : 'Настройки чата - выключил отправку аудиосообщений';

      carrotquestHelper.track(eventName);
    }

    /**
     * Трек изменения настройки "Видимость чата"
     *
     * @param newValue - Новое значение настройки
     */
    function trackChangeMessengerModeSetting(newValue) {
      carrotquestHelper.track('Настройки чата - изменил "Видимость чата"', {
        app_id: vm.currentApp.id,
        value: CHAT_VISIBILITY_STATUSES[newValue.toUpperCase()],
      });
    }

    /**
     * Удаление выключенных и обновление порядка соц. сетей
     *
     * @returns {*[]}
     */
    function updateOrderSocialNetworks() {
      const orderSocialNetworks = vm.chatSettings.messenger_icon_order
        ? vm.chatSettings.messenger_icon_order
        : DEFAULT_ORDER_SOCIAL_NETWORKS;

      let newOrderSocialNetworks = [];

      orderSocialNetworks.forEach((socialName) => {
        if (vm.chatSettings[`messenger_icon_${socialName}_show`]) {
          newOrderSocialNetworks.push(socialName);
        }
      });

      return newOrderSocialNetworks;
    }
  }
})();

import { firstValueFrom } from 'rxjs';
import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '../../../app/http/channel/channel.constants';
import { DJANGO_USER_PRESENCE_TYPES } from '../../../app/http/django-user/django-user.constants';
import { SUBSCRIPTION_STATUSES } from '../../../app/services/billing-info/billing-info.constants';
import { FEATURES } from '../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.general').controller('CqGeneralController', CqGeneralController);

  function CqGeneralController(
    $interval,
    $q,
    $scope,
    $rootScope,
    $translate,
    $uibModal,
    $window,
    ipCookie,
    moment,
    toastr,
    PROJECT_NAME,
    appModel,
    billingInfoModel,
    browserDetector,
    carrotquestHelper,
    desktopNotification,
    djangoUserModel,
    faviconNotification,
    featureModel,
    planCapabilityModel,
    utilsService,
    appBlocks,
    appList,
    billingInfo,
    channelCounters,
    currentApp,
    djangoUser,
    djangoUserTempData,
    teamMembers,
    teamMembersGroups,
  ) {
    let vm = this;

    /**
     * Интервал, через который проверяется, нужно посылать серверу информацию о присутствии или нет
     *
     * @type {number}
     */
    const CHECK_PRESENCE_INTERVAL = 30 * 1000; // 30 секунд

    /**
     * Promise для прерывания интервала отправки присутствия
     *
     * @type {Promise}
     */
    let interval;

    /**
     * Время (timestamp) последнего успешно выполненного запроса присутствия
     *
     * @type {number}
     */
    let lastSendPresenceTimestamp = 0;

    /**
     * Минимальный интервал, через которым посылается запрос presence (присутствия) из админки на бэкенд
     *
     * @type {number}
     */
    const SEND_PRESENCE_INTERVAL = 120 * 1000; // 2 минуты

    vm.appBlocks = appBlocks;
    vm.appList = appList;
    vm.billingInfo = billingInfo;
    vm.channelCounters = channelCounters;
    vm.currentApp = currentApp;
    vm.djangoUser = djangoUser;
    vm.djangoUserTempData = djangoUserTempData;
    vm.featureModel = featureModel;
    vm.FEATURES = FEATURES;
    vm.isShowOutdatedBrowserBanner = isShowOutdatedBrowserBanner;
    vm.onOutdatedBrowserBannerClose = onOutdatedBrowserBannerClose;
    vm.teamMembers = teamMembers;
    vm.teamMembersGroups = teamMembersGroups;
    vm.utilsService = utilsService;
    vm.PSEUDO_CHANNEL_IDS = PSEUDO_CHANNEL_IDS;
    vm.PSEUDO_CHANNEL_TYPES = PSEUDO_CHANNEL_TYPES;

    init();

    function init() {
      if (!browserDetector.isBrowserSupported()) {
        trackBrowserNotSupported();
      }

      if (!appModel.isAppBlocked(currentApp)) {
        desktopNotification.requestPermission();
      }

      if (appBlocks.blockEmails) {
        openEmailsBlockedModal();
      } else if (appBlocks.hasHighBounceRate && appBlocks.blockAllEmails) {
        openEmailsBlockedBecauseOfBounceRateModal();
      } else if (appBlocks.hasHighComplaintRate && appBlocks.blockAllEmails) {
        openEmailsBlockedBecauseOfComplaintRateModal();
      } else if (appBlocks.blockAllEmails) {
        openAllEmailsBlockedModal();
      }

      djangoUserModel.savePresenceFromStorageToPrefs().subscribe();

      sendPresence();
      interval = $interval(sendPresence, CHECK_PRESENCE_INTERVAL);

      $scope.$watch('vm.channelCounters', (newVal) => faviconNotification.setFavicon(newVal), true);

      $scope.$on('message', handleRts);

      $scope.$on('$destroy', () => $interval.cancel(interval));
    }

    /**
     * Обработка сообщений из RTS
     *
     * @param event
     * @param info
     */
    function handleRts(event, info) {
      let channel = info.channel,
        data = info.data;

      if (channel.indexOf('admin_presence_changed.') === 0) {
        let callApply = false;

        // фактически в data в этот канал приходит всегда массив из одного элемента
        if (data[0].admin === djangoUser.id && data[0].presence_pref_changed) {
          djangoUser.prefs[currentApp.id].presence = data[0].presence;
          callApply = true;
        }

        callApply && $scope.$applyAsync();
      }

      // NOTE Это сделано для решения бага с тем, что после создания аппа в некоторых случаях информация о подписке приходит долго и в разделе оплаты из-за этого всё ломается
      if (channel.indexOf('billing_updated.') === 0) {
        $q.all([
          firstValueFrom(billingInfoModel.get(currentApp.id)),
          firstValueFrom(planCapabilityModel.getList(currentApp.id)),
        ])
          // Получаем информацию о подписке
          .then((responses) => {
            billingInfo.subscription = responses[0].subscription;
            $scope.$applyAsync();
          });
      }
    }

    /**
     * Показывать ли баннер для устаревших браузеров
     */
    function isShowOutdatedBrowserBanner() {
      if (!featureModel.hasAccess(FEATURES.OUTDATED_BROWSER_BANNER)) {
        return false;
      }

      if (browserDetector.isBrowserSupported()) {
        return false;
      }

      if (ipCookie('carrotquest_outdated_browser_banner_closed')) {
        return false;
      }

      return true;
    }

    /**
     * Коллбэк на закрытие баннера для устаревших браузеров
     */
    function onOutdatedBrowserBannerClose() {
      // при закрытии баннера нужно оповестить об этом AngularJS, чтобы баннер исчез
      $scope.$applyAsync();
    }

    /**
     * Открытие поп-апа об обнаружении подозрительной активности
     */
    function openAllEmailsBlockedModal() {
      carrotquestHelper.track('Показан попап - "Обнаружена подозрительная активность"');

      $uibModal
        .open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant('general.allEmailsBlockedModal.heading'),
                body: $translate.instant('general.allEmailsBlockedModal.body', { projectName: PROJECT_NAME }),
                imgUrl: 'assets/img/default/users/image-barier.png',
                confirmButtonText: $translate.instant('general.allEmailsBlockedModal.confirmButtonText'),
              };
            },
          },
          templateUrl: 'js/shared/modals/confirm/confirm.html',
        })
        .result.then(allEmailsBlockedModalSuccess);

      function allEmailsBlockedModalSuccess() {
        carrotquestHelper.track('Попап "Обнаружена подозрительная активность" - клик "Открыть чат"');
        carrotquestHelper.open();
      }
    }

    /**
     * Открытие поп-апа о высоком bounce rate
     */
    function openEmailsBlockedBecauseOfBounceRateModal() {
      carrotquestHelper.track('Показан попап - "Емейл-рассылки заблокированы (bounce)"');

      $uibModal
        .open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant('general.emailsBlockedBecauseOfBounceRateModal.heading'),
                body:
                  '\
                <div class="margin-bottom-20">\
                  ' +
                  $translate.instant('general.emailsBlockedBecauseOfBounceRateModal.body') +
                  '\
                </div>\
                <div>\
                  ' +
                  $translate.instant('general.emailsBlockedBecauseOfBounceRateModal.importantNote') +
                  '\
                </div>',
                imgUrl: 'assets/img/default/users/image-barier.png',
                confirmButtonText: $translate.instant(
                  'general.emailsBlockedBecauseOfBounceRateModal.confirmButtonText',
                ),
              };
            },
          },
          templateUrl: 'js/shared/modals/confirm/confirm.html',
        })
        .result.then(emailsBlockedBecauseOfBounceRateModalSuccess);

      function emailsBlockedBecauseOfBounceRateModalSuccess() {
        carrotquestHelper.track('Попап "Емейл-рассылки заблокированы (bounce)" - клик "Открыть чат"');
        carrotquestHelper.open();
      }
    }

    /**
     * Открытие поп-апа о высоком complaint rate
     */
    function openEmailsBlockedBecauseOfComplaintRateModal() {
      carrotquestHelper.track('Показан попап - "Емейл-рассылки заблокированы"');

      $uibModal
        .open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant('general.emailsBlockedBecauseOfComplaintRateModal.heading'),
                body:
                  '\
                <div class="margin-bottom-20">\
                  ' +
                  $translate.instant('general.emailsBlockedBecauseOfComplaintRateModal.body') +
                  '\
                </div>\
                <div>\
                  ' +
                  $translate.instant('general.emailsBlockedBecauseOfComplaintRateModal.importantNote') +
                  '\
                </div>',
                imgUrl: 'assets/img/default/users/image-barier.png',
                confirmButtonText: $translate.instant(
                  'general.emailsBlockedBecauseOfComplaintRateModal.confirmButtonText',
                ),
              };
            },
          },
          templateUrl: 'js/shared/modals/confirm/confirm.html',
        })
        .result.then(emailsBlockedBecauseOfComplaintRateModalSuccess);

      function emailsBlockedBecauseOfComplaintRateModalSuccess() {
        carrotquestHelper.track('Попап "Емейл-рассылки заблокированы" - клик "Открыть чат"');
        carrotquestHelper.open();
      }
    }

    /**
     * Открытие поп-апа о блокировке отправки email'ов
     */
    function openEmailsBlockedModal() {
      carrotquestHelper.track('Показан попап - "Отправка писем была приостановлена"');

      $uibModal
        .open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant('general.emailsBlockedModal.heading'),
                body: $translate.instant('general.emailsBlockedModal.body'),
                imgUrl: 'assets/img/default/users/image-barier.png',
                confirmButtonText: $translate.instant('general.emailsBlockedModal.confirmButtonText'),
                cancelButtonClass: 'hidden',
              };
            },
          },
          templateUrl: 'js/shared/modals/confirm/confirm.html',
        })
        .result.then(
          angular.bind(null, carrotquestHelper.sendChatMessage, $translate.instant('general.messageToChat')),
        );
    }

    function sendPresence() {
      // запросы присутствия, очевидно, нужно посылать только в случае когда пользователь online, чтобы поддерживать этот самый online
      // если djangoUser сам себе выставил статус в offline - setpresence посылать не имеет смысла
      if (djangoUser.prefs[currentApp.id].presence === DJANGO_USER_PRESENCE_TYPES.OFFLINE) {
        return;
      }

      if (moment().valueOf() - lastSendPresenceTimestamp < SEND_PRESENCE_INTERVAL) {
        return;
      }

      firstValueFrom(djangoUserModel.sendPresence(currentApp.id, djangoUser.prefs[currentApp.id].presence)).then(
        () => (lastSendPresenceTimestamp = moment().valueOf()),
      );
    }

    /**
     * Трек закрытия баннера неподдерживаемых браузеров
     */
    function trackBrowserNotSupported() {
      carrotquestHelper.track('Браузер не поддерживается', {
        userAgent: $window.navigator.userAgent,
      });
    }
  }
})();

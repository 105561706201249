(function () {
  'use strict';

  require('./script-insertion.controller');

  angular.module('myApp.serviceInstallation').component('cqScriptInsertion', cqScriptInsertion());

  function cqScriptInsertion() {
    return {
      bindings: {
        apiKey: '<', // ключ для запросов к API
        appId: '<', // ID приложения
        appName: '<', // название приложения
        appOrigin: '<?', // адрес приложения
        currentApp: '<?', // Текущее приложение
        djangoUser: '<?', // Текущий django-пользователь
        modalInstance: '<?', // Инстанс модального окна "Установка кода"
        onCopyScript: '<?', // Callback копирования скрипта
        isDeveloperPage: '<?', // Если компонент показывается на странице для программиста
      },
      controller: 'CqScriptInsertionController',
      controllerAs: 'vm',
      template: require('./script-insertion.html'),
    };
  }
})();

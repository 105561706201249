<ng-container *transloco="let t; read: 'propertyFiltersComponent'">
  <div class="d-flex">
    <div class="margin-between-cols-5">
      <span class="font-weight-bold">{{ t('title') }}</span>
      <i
        class="cqi-sm cqi-question-circle"
        [ngbTooltip]="t('tooltip')"
      ></i>
    </div>

    <div class="ml-auto">
      <span>{{ filterNumber() }}</span>
      <i
        class="cqi-sm cursor-pointer"
        [ngClass]="{
          'cqi-chevron-up': !collapse.collapsed,
          'cqi-chevron-down': collapse.collapsed
        }"
        (click)="collapse.toggle()"
      ></i>
    </div>
  </div>

  <div
    #collapse="ngbCollapse"
    [ngbCollapse]="false"
  >
    <div class="mt-15 margin-between-rows-20">
      <ng-container *ngFor="let control of control.controls; let index = index">
        <cq-property-filter
          [formControl]="control"
          [index]="index"
          [showOperationSelect]="!hasEmptyFilters()"
          [showPropertyCounter]="hasManyFilters()"
          [showRemoveButton]="!hasEmptyFilters()"
          [props]="props"
          [telegramIntegrations]="telegramIntegrations"
          (removeFilter)="removeFilter($event)"
        ></cq-property-filter>
      </ng-container>
    </div>

    <button
      class="btn btn-borderless-primary mt-10"
      *ngIf="!hasEmptyFilters()"
      (click)="addFilter()"
      type="button"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ t('addProperty') }}</span>
    </button>
  </div>
</ng-container>

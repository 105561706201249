<ng-container *transloco="let t; read: 'branchActionAppOfflineConditionComponent'">
  <div class="d-flex flex-nowrap justify-content-between align-items-center margin-bottom-10">
    <span class="font-weight-bold">{{ t('heading') }}</span>
    <button
      class="btn btn-borderless-primary"
      [ngbPopover]="workingTimePopover"
      container="body"
      placement="bottom-right"
      [autoClose]="true"
    >
      <i class="btn-icon cqi-sm cqi-question-circle"></i>
    </button>
  </div>
  <cq-bot-action-next-branch-selector
    [formControl]="actionForm.controls.nextBranchLinkId!"
    [nextBranchOptions]="nextBranchOptions"
    [canSelectActionAsNextBranch]="canSelectActionAsNextBranch"
    [chatBotType]="chatBotType"
    [chatBotId]="chatBotId"
    (branchSelect)="onChangeBranch($event)"
    (addBranchClick)="branchCreate.next($event)"
  >
  </cq-bot-action-next-branch-selector>

  <ng-template #workingTimePopover>
    <div class="flex">
      <div>
        {{
          t(
            currentApp.settings.messenger_offline_if_no_operators ? 'popovers.byOnlineStatus' : 'popovers.byWorkingTime'
          )
        }}
      </div>
      <i class="cqi-sm cqi-times cursor-pointer"></i>
    </div>
  </ng-template>
</ng-container>

/** Типы цветовых схем для чата */
export enum CHAT_THEMES_TYPES {
  DARK = 'dark', // Темная
  DEFAULT = 'default', // Стандартная
}

/** Массив цветовых тем чата */
export const CHAT_THEMES_TYPES_ARRAY = [CHAT_THEMES_TYPES.DEFAULT, CHAT_THEMES_TYPES.DARK];

/** Типы статусов видимости чата */
export enum CHAT_VISIBILITY_STATUSES {
  HAS_DIALOGS = 'has_dialogs',
  HIDDEN = 'hidden',
  VISIBLE = 'visible',
}

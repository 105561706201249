<div class="modal-header align-items-start">
  <img
    class="pt-15"
    src="assets/img/default/access-denial-modal.svg"
    alt="Feature is not available"
  />
  <button
    class="close"
    (click)="modalRef.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="pb-15">
    <h2 class="mb-15">
      {{ denialReasonTitleText }}
    </h2>
    <div class="mb-20">{{ denialReasonDescriptionText }}</div>
    <button
      (click)="sendMessageToChat(denialReasonMessageText); modalRef.close()"
      class="btn btn-primary"
      type="button"
    >
      {{ denialReasonTexts.action }}
    </button>
  </div>
</div>

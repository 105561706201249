import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SetupIntentResult } from '@stripe/stripe-js';
import { BehaviorSubject } from 'rxjs';

import { StripeService } from '@panel/app/pages/subscription/general/services/stripe/stripe.service';
import {
  STRIPE_EDIT_CARD_MODAL_DATA_TOKEN,
  StripeEditCardModalData,
} from '@panel/app/pages/subscription/general/stripe-edit-card-modal/stripe-edit-card-modal.token';
import { StripeInputComponent } from '@panel/app/pages/subscription/general/stripe-input/stripe-input.component';
import { StripeThreeDSModalComponent } from '@panel/app/pages/subscription/general/stripe-three-d-s-modal/stripe-three-d-s-modal.component';
import { STRIPE_THREE_DS_MODAL_DATA_TOKEN } from '@panel/app/pages/subscription/general/stripe-three-d-s-modal/stripe-three-d-s-modal.token';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { ModalHelperService } from '@panel/app/services';
import { STRIPE_ADD_CARD_MODAL_OPEN_REASON } from '@panel/app/services/billing-info/billing-info.constants';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

interface StripeFormGroup {
  stripe: FormControl<string | null>;
}

/** Компонент-модалка для редактирования карты Stripe */
@Component({
  selector: 'cq-stripe-edit-card-modal',
  templateUrl: './stripe-edit-card-modal.component.html',
  styleUrls: ['./stripe-edit-card-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeEditCardModalComponent {
  /** Компонент с инпутом Stripe */
  @ViewChild(StripeInputComponent)
  stripeInputComponent!: StripeInputComponent;

  /** Флаг выполнения запроса */
  isApiRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Форма с вводом карты Stripe */
  stripeForm: FormGroup<StripeFormGroup> = new FormGroup<StripeFormGroup>({
    stripe: new FormControl<string | null>(null, [Validators.required]),
  });

  /** Секретный ключ для Stripe */
  private clientSecret!: string;

  constructor(
    @Inject(STRIPE_EDIT_CARD_MODAL_DATA_TOKEN)
    private readonly data: StripeEditCardModalData,
    private readonly modalHelperService: ModalHelperService,
    public readonly ngbActiveModal: NgbActiveModal,
    private readonly stripeService: StripeService,
    private readonly subscriptionStore: SubscriptionStore,
    private readonly toastrService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  /** Флаг добавления новой карты */
  get isAddCard(): boolean {
    return this.data.reason === STRIPE_ADD_CARD_MODAL_OPEN_REASON.ADD;
  }

  /** Обработчик отправки формы */
  onSubmit(): void {
    if (!this.stripeForm.valid) {
      return;
    }

    this.isApiRequestPerformed.next(true);

    const billing = this.subscriptionStore.billing$.getValue()!;

    billing
      .addCard()
      .then((clientSecret: string) => this.confirmCardSetup(clientSecret))
      .then((response: SetupIntentResult) => this.confirmCardSetupCheckResult(response))
      .finally(() => this.isApiRequestPerformed.next(false));
  }

  /**
   * Подтверждение добавления карты в Stripe
   * NOTE:
   *  В return_url указан протокол и адрес до панели, потому что Stripe воспринимает этот параметр в том виде, в котором его передали.
   *  Если передать параметр без протокола, Stripe вернет ошибку.
   *
   * @param {Object} clientSecret Секретный ключ платёжного намерения
   */
  private confirmCardSetup(clientSecret: string) {
    this.clientSecret = clientSecret;

    return this.stripeService.confirmCardSetup(this.stripeInputComponent.card, clientSecret);
  }

  /**
   * Проверка результата попытки добавить карту в Stripe
   *
   * @param {Object} response - Информация о результатах попытки добавления карты в Stripe
   */
  private confirmCardSetupCheckResult(response: SetupIntentResult) {
    if (response.error) {
      // Если при добавлении карты произошли технические ошибки на стороне Stripe
      this.toastrService.danger(response.error.message!);
    } else if (response.setupIntent.status === 'requires_action') {
      // Если требуется 3DS-подтверждение от банка
      this.openStripe3DSModal(response.setupIntent!.next_action!.redirect_to_url!.url!);
    } else if (response.setupIntent.status !== 'succeeded') {
      // Если привязка карты не осуществлена по каким-то причинам
      this.toastrService.danger(this.translocoService.translate('stripeEditCardModalComponent.toasts.failed'));
    } else {
      // Если 3DS-подтверждение от банка не требовалось и привязка карты прошла успешно
      this.isApiRequestPerformed.next(false);

      this.toastrService.success(this.translocoService.translate('stripeEditCardModalComponent.toasts.success'));

      this.ngbActiveModal.close();
    }
  }

  /**
   * Открытие модалки 3DS подтверждения карты
   *
   * @param iframeSrc URL для iframe, который должен открыться для валидации карты
   * @private
   */
  private openStripe3DSModal(iframeSrc: string): void {
    const stripe3DSModal = this.modalHelperService
      .provide(STRIPE_THREE_DS_MODAL_DATA_TOKEN, {
        iframeSrc,
      })
      .open(StripeThreeDSModalComponent);

    stripe3DSModal.result
      .then(() => this.stripe3DSModalClosed())
      .then((response: SetupIntentResult) => this.confirmCardSetupCheckResult(response));
  }

  /**
   * Callback-функция после закрытия модального окна с 3DS-подтверждением
   * NOTE: После успешного 3DS-подтверждения карты в банке, необходимо повторно подтвердить намерение добавить карту в Stripe
   */
  private stripe3DSModalClosed(): Promise<SetupIntentResult> {
    return this.stripeService.retrieveSetupIntent(this.clientSecret);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgMathPipesModule } from 'ngx-pipes';

import { FileHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { InstallScriptModalModule } from '@panel/app/shared/modals/install-script-modal/install-script-modal.module';
import { PipesModule } from '@panel/app/shared/pipes/pipes.module';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';
import { ProgressbarModule } from '@panel/app/shared/visual-components/progressbar/progressbar.module';
import { StepperModule } from '@panel/app/shared/visual-components/stepper/stepper.module';
import { SwitchComponent } from '@panel/app/shared/visual-components/switch/switch.component';
import { SwitcherComponent } from '@panel/app/shared/visual-components/switcher/switcher.component';
import { ToastComponent } from '@panel/app/shared/visual-components/toast/toast.component';

import { DestroyService } from '../services';
import { BetaLabelComponent } from './visual-components/beta-label/beta-label.component';
import { CheckboxControlComponent } from './visual-components/checkbox-control/checkbox-control.component';
import { FileUploadDropzoneComponent } from './visual-components/file-upload-dropzone/file-upload-dropzone.component';
import { NewLabelComponent } from './visual-components/new-label/new-label.component';
import { PlanCapabilityCoinComponent } from './visual-components/plan-capability-coin/plan-capability-coin.component';
import { PreviewWrapperComponent } from './visual-components/preview-wrapper/preview-wrapper.component';
import { SwitchWrapperComponent } from './visual-components/switch-wrapper/switch-wrapper.component';
import { ValidationMessageComponent } from './visual-components/validation-messages/validation-message.component';
import { ValidationMessagesComponent } from './visual-components/validation-messages/validation-messages.component';
import { ZeroDataComponent } from './visual-components/zero-data/zero-data.component';

const modules = [PipesModule, DirectivesModule, StepperModule, LoaderModule, ProgressbarModule];

const components = [
  CheckboxControlComponent,
  FileUploadDropzoneComponent,
  PreviewWrapperComponent,
  SwitchComponent,
  SwitcherComponent,
  ValidationMessageComponent,
  ValidationMessagesComponent,
  CheckboxControlComponent,
  PlanCapabilityCoinComponent,
  ZeroDataComponent,
  ToastComponent,
];

@NgModule({
  declarations: [...components, NewLabelComponent, BetaLabelComponent, SwitchWrapperComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...modules,
    NgxDropzoneModule,
    NgMathPipesModule,
    TranslocoModule,
    NgbToastModule,
    NgbAlertModule,
    InstallScriptModalModule,
  ],
  exports: [...modules, ...components, NewLabelComponent, BetaLabelComponent, SwitchWrapperComponent],
  providers: [DestroyService, FileHelperProvider],
})
export class SharedModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import {
  AmocrmLeadsEventsMappingForm,
  AmocrmMappingForm,
} from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import {
  AmocrmPipeline,
  AmocrmPipelineModified,
} from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { IntegrationService } from '@http/integration/services/integration.service';
import { EventType, UserProperty } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { DestroyService } from '@panel/app/services';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';

/**
 * Компонент для работы с данными, которые передаются из сделок AmoCRM
 */

@Component({
  selector:
    'cq-amocrm-data-from-leads[controlAmocrmLeadsPropsMapping][currentApp][integrationId][leadsEventsMappingControl]',
  templateUrl: './amocrm-data-from-leads.component.html',
  styleUrls: ['./amocrm-data-from-leads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AmocrmDataFromLeadsComponent implements OnInit {
  /** Контрол с полями сделки amoCRM, после изменения которой это поле будет записано в свойство лида в проект */
  @Input()
  controlAmocrmLeadsPropsMapping!: GenericFormArray<AmocrmMappingForm>;
  /** ID интеграции */

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** ID интеграции */
  @Input()
  integrationId!: string;

  /** Контрол со статусами сделки amoCRM, после изменения которых будет создаваться событие в проект */
  @Input()
  leadsEventsMappingControl!: GenericFormArray<AmocrmLeadsEventsMappingForm>;

  /** Инстанс коллапса */
  @ViewChild(NgbCollapse)
  collapse!: NgbCollapse;

  /** Список пользовательских свойств проекта */
  customProperties: UserProperty[] = [];

  /** Флаг свёрнутости списка данных, которые передаются из контактов amoCRM */
  isAmoCrmDataCollapsed: boolean = true;

  /** Воронки AmoCRM + соответствующие статусам события в проект */
  pipelines: AmocrmPipelineModified[] = [];

  /** Название проекта */
  projectName = environment.projectName;

  /** Получение количества настроенных связок статусов и полей сделок в AmoCRM с событиями и свойствами лида */
  get countBunch() {
    return this.leadsEventsMappingControl.controls.length + this.controlAmocrmLeadsPropsMapping.controls.length;
  }

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    public readonly amocrmState: AmocrmStateService,
    @Inject(AmocrmService) private readonly amocrmService: AmocrmService,
    @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.customProperties = this.getCustomProperties(this.amocrmState.userProps$.getValue());

    this.getPipelinesIntegration();

    // Следим за изменениями статусов валидности и touched-статуса, чтобы раскрыть коллапс этого компонента,
    // если один из контролов невалидный
    combineLatest(
      extractTouchedChanges(this.controlAmocrmLeadsPropsMapping),
      extractTouchedChanges(this.leadsEventsMappingControl),
      this.controlAmocrmLeadsPropsMapping.statusChanges.pipe(
        startWith(this.controlAmocrmLeadsPropsMapping.status),
        map((status) => status === 'INVALID'),
      ),
      this.leadsEventsMappingControl.statusChanges.pipe(
        startWith(this.leadsEventsMappingControl.status),
        map((status) => status === 'INVALID'),
      ),
    )
      .pipe(map((statuses) => [statuses[2], statuses[3]].some((value) => value)))
      .subscribe((invalid) => {
        if (invalid && this.collapse.collapsed) {
          this.collapse.toggle(true);
        }
      });
  }

  /** Добавить элемент со свойствами события и сделки */
  addLeadProperty(leadFormGroup: AmocrmLeadsEventsMappingForm): void {
    const propertyControl = new AmocrmMappingForm({});

    leadFormGroup.controls.eventProps.push(propertyControl);
    leadFormGroup.controls.eventProps.updateValueAndValidity();
  }

  /** Добавление группы с контролами свойства, которое отправляется из контакта amoCRM в лида Carrot quest */
  addProperty(): void {
    const propertyControl = this.getLeadsEventFormGroup();

    this.leadsEventsMappingControl.push(propertyControl);
    this.leadsEventsMappingControl.updateValueAndValidity();
  }

  /** Добавление группы с контролом поля сделки, которое отправляется в свойство лида Carrot quest */
  addLeadField(): void {
    const propertyControl = this.getLeadFieldFormGroup();

    this.controlAmocrmLeadsPropsMapping.push(propertyControl);
    this.leadsEventsMappingControl.updateValueAndValidity();
  }

  /**
   * Так как значения в списке выводятся не просто по имени а через "label", искать нужно тоже по нему
   */
  findPipelineByLabel(term: string, item: AmocrmPipeline): boolean {
    const label = this.getPipelineLabel(item);
    return label.toLowerCase().search(term.toLowerCase()) > -1;
  }

  /** Получение списка пользовательских свойств проекта */
  getCustomProperties(userProps: UserProperty[]) {
    return userProps.filter((property) => property.groupOrder === 5);
  }

  /** Создание группы AmocrmMappingForm */
  getLeadFieldFormGroup() {
    const controlData = {};

    return new AmocrmMappingForm(controlData);
  }

  /** Создание группы AmocrmMappingForm с контролами */
  getLeadsEventFormGroup() {
    const controlData = {
      checked: false,
      event: {},
      eventProps: {},
    };

    return new AmocrmLeadsEventsMappingForm(controlData);
  }

  /** Получение статусов сделки */
  getPipelinesIntegration(): void {
    this.amocrmService
      .getPipelinesIntegration(this.integrationId)
      .pipe(
        takeUntil(this.destroy$),
        map<AmocrmPipeline[], AmocrmPipelineModified[]>((pipelines: AmocrmPipeline[]) => {
          return pipelines.reduce((newArr: AmocrmPipelineModified[], pipeline) => {
            newArr.push({
              ...pipeline,
              additionalId: `${pipeline.id}-${pipeline.statusId}`,
            });

            return newArr;
          }, []);
        }),
      )
      .subscribe((response: AmocrmPipelineModified[]) => {
        this.pipelines = response;
        this.cdr.detectChanges();
      });
  }

  /**
   * Сборное название пайплайна состоящее из имени и статуса
   */
  getPipelineLabel(pipeline: AmocrmPipeline): string {
    return pipeline.name + ': ' + pipeline.statusName;
  }

  /**
   * Удалён ли статус сделки в amoCRM
   *
   * @param pipeline - Сделка, чей статус необходимо проверить
   */
  isRemovedPipeline(pipeline: AmocrmPipeline): boolean {
    return !(pipeline.name || pipeline.statusName);
  }

  /** Колбэк на выбор события в селекте */
  onEventTypeChange(control: GenericFormControl<string>, eventType: string | string[] | null) {
    control.setValue(eventType as string);
  }

  /** Удаления элемента со свойствами событий и сделки из массива контролов */
  removeEventPropertyItem(leadFormGroup: AmocrmLeadsEventsMappingForm, index: number): void {
    leadFormGroup.controls.eventProps.removeAt(index);
  }

  /** Удаление элемента из массива контролов */
  removeItem(formArray: GenericFormArray<AmocrmMappingForm | AmocrmLeadsEventsMappingForm>, index: number) {
    formArray.removeAt(index);
  }

  /**
   * Обработчик добавления нового события
   *
   * @param autoEvents - Новые автособытия
   */
  onAutoEventsChange(autoEvents: AutoEvent[]): void {
    this.amocrmState.autoEvents$.next([...autoEvents]);
  }

  /**
   * Обработчик добавления нового события
   *
   * @param eventTypes - Новые свойства и события
   */
  onEventTypesChange(eventTypes: EventType[]): void {
    this.amocrmState.eventTypes$.next([...eventTypes]);
  }
}

(function () {
  'use strict';

  require('./prepare-for-leads-import.controller');

  angular.module('myApp.users').component('cqPrepareForLeadsImport', cqPrepareForLeadsImport());

  function cqPrepareForLeadsImport() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        importType: '<', // Тип импорта
        importTypes: '<', // Типы импорта
        importTypesList: '<', // Список типов импорта
      },
      controller: 'CqPrepareForLeadsImportController',
      controllerAs: 'vm',
      template: require('./prepare-for-leads-import.html'),
    };
  }
})();

(function () {
  'use strict';

  require('./popup-indent-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupIndentBlockSettings', cqPopupIndentBlockSettings());

  function cqPopupIndentBlockSettings() {
    return {
      bindings: {
        popupBlockParams: '=',
      },
      controller: 'CqPopupIndentBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-indent-block-settings.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLetModule } from '@taiga-ui/cdk';

import { UtilsServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ButtonMenuComponent } from './button-menu.component';

@NgModule({
  declarations: [ButtonMenuComponent],
  imports: [CommonModule, NgbDropdownModule, TranslocoModule, TuiLetModule, SharedModule],
  exports: [ButtonMenuComponent],
  providers: [UtilsServiceProvider],
})
export class ButtonMenuModule {}

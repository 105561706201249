import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { App, AppModel } from '@http/app/app.model';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';

/**
 * Параметры передаваемые в модалку
 */
export type LeaveSiteAttemptSettingModalParams = {
  currentApp: App;
};

@Component({
  selector: 'cq-leave-site-attempt-setting',
  templateUrl: './leave-site-attempt-setting.component.html',
  styleUrls: ['./leave-site-attempt-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeaveSiteAttemptSettingComponent implements OnInit {
  /**
   * Инстанс AJS модалки. Нужен для закрытия.
   * TODO Переделать при переезде на A2 компонента, который вызывает эту модалку
   */
  @Input()
  ajsModalInstance?: any;

  /** Параметры, передаваемые в модалку */
  @Input()
  modalWindowParams!: LeaveSiteAttemptSettingModalParams;

  /** Доступ до мастера сбора данных */
  accessToTrackMasterJsCode: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  public leaveSiteAttempt: boolean = false;

  constructor(
    private readonly appModel: AppModel,
    private readonly activeModal: NgbActiveModal,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
  ) {}

  ngOnInit() {
    this.accessToTrackMasterJsCode = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.TRACK_MASTER_JS_CODE,
      this.modalWindowParams.currentApp,
    );
  }

  closeModal(value?: string) {
    this.ajsModalInstance ? this.ajsModalInstance.close(value) : this.activeModal.close(value);
  }

  dismissModal() {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.activeModal.dismiss();
  }

  /**
   * Начать записывать событие попытки ухода с сайта
   */
  public startTrackLeaveSiteAttempt(): void {
    if (!this.leaveSiteAttempt) return;

    const settings = {
      track_leave_site_attempt: this.leaveSiteAttempt,
    };

    firstValueFrom(this.appModel.saveSettings(this.modalWindowParams.currentApp.id, settings)).then(() => {
      this.modalWindowParams.currentApp.settings.track_leave_site_attempt = this.leaveSiteAttempt;
      this.closeModal();
    });
  }
}

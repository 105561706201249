<ng-container *transloco="let t; read: 'costComponent'">
  <ng-container
    *tuiLet="{ billing: subscriptionStore.billing$ | async, plan: subscriptionStore.plan$ | async } as values"
  >
    <ng-container *ngIf="values.billing && values.plan">
      <div class="d-flex justify-content-between align-content-center">
        <span class="font-weight-bold">{{ t('title') }}</span>
        <div>
          <span>{{
            values.billing.subscription.getTotalPrice(values.plan.availableUniqueUsers) | toMoney | toFormattedMoney
          }}</span>
          <i
            class="cqi-sm cqi-question-circle cursor-pointer d-inline-block ml-5"
            [ngbPopover]="details"
            container="body"
            placement="bottom"
            triggers="mouseenter:mouseleave"
          ></i>
        </div>
      </div>

      <ng-template #details>
        <div class="margin-between-rows-10">
          <div class="d-flex justify-content-between align-items-end">
            <div>
              <div>{{ t('details.plan.title') }}</div>
              <small class="text-secondary">{{
                t('details.plan.forMonth', {
                  price: values.billing.subscription.getPlanPriceForMonth() | toMoney | toFormattedMoney
                })
              }}</small>
            </div>
            <div class="ml-30 font-weight-bold">
              {{ values.billing.subscription.planTotalCost | toMoney | toFormattedMoney }}
            </div>
          </div>

          <ng-container
            *ngIf="values.billing.subscription.getConnectedAddonsTotalPrice(values.plan.availableUniqueUsers)"
          >
            <div class="d-flex justify-content-between align-items-end">
              <div>
                <div>{{ t('details.addons.title') }}</div>
                <small class="text-secondary">{{
                  t('details.addons.forMonth', {
                    price:
                      values.billing.subscription.getConnectedAddonsTotalPriceForMonth(values.plan.availableUniqueUsers)
                      | toMoney
                      | toFormattedMoney
                  })
                }}</small>
              </div>
              <div class="ml-30 font-weight-bold">
                {{
                  values.billing.subscription.getConnectedAddonsTotalPrice(values.plan.availableUniqueUsers)
                    | toMoney
                    | toFormattedMoney
                }}
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="values.billing.subscription.getTotalDiscount(values.plan.availableUniqueUsers)">
            <div class="d-flex justify-content-between align-items-end">
              <div>
                <div>{{ t('details.discount.title') }}</div>
                <small class="text-secondary">{{ t('details.discount.description') }}</small>
              </div>
              <div class="ml-30 font-weight-bold text-success">
                <span>-</span>
                <span>{{
                  values.billing.subscription.getTotalDiscount(values.plan.availableUniqueUsers)
                    | toMoney
                    | toFormattedMoney
                }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

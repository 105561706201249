(function () {
  'use strict';

  angular.module('myApp.filterSegments').constant('FILTER_SEGMENTS_PAGE_TYPE', getFilterSegmentsPageType());

  /**
   * Получение страниц, на которых может отображаться компонент фильтров
   *
   * @returns {{CHANNEL_EDITOR: string, MESSAGE_EDITOR: string, LEAD_DATA_BASE: string}}
   */
  function getFilterSegmentsPageType() {
    return {
      CHANNEL_EDITOR: 'channelEditor', // Редактор каналов
      LEAD_DATA_BASE: 'leadDataBase', // Лиды
      MESSAGE_EDITOR: 'messageEditor', // Редактор автосообщений или лид-ботов
    };
  }
})();

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { DestroyService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

/**
 * Компонент для OAuth авторизации в VK
 */

@Component({
  selector: 'cq-vk-o-auth-container[authorizeError][authorizeSuccess][isAuthorized]',
  templateUrl: './vk-o-auth-container.component.html',
  styleUrls: ['./vk-o-auth-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class VkOAuthContainerComponent implements OnInit {
  /** ID интеграции */
  @Input()
  integrationId?: string | null;

  /** Авторизован ли пользователь */
  @Input()
  isAuthorized: boolean = false;

  /** Событие неуспешной авторизации */
  @Output()
  authorizeError: EventEmitter<void> = new EventEmitter<void>();

  /** Событие успешной авторизации */
  @Output()
  authorizeSuccess: EventEmitter<void> = new EventEmitter<void>();

  /** OAuth Url для авторизации в интеграции */
  oAuthUrl!: string;

  /** Текущий проект */
  projectName: string = environment.projectName;

  /** Получение названия интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.VK);
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly destroy$: DestroyService,
    private readonly integrationService: IntegrationService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if (!this.isAuthorized) {
      this.updateOAuthUrl();
    }
  }

  /**
   * Callback на ошибку в авторизации
   *
   * @param error - Причина ошибки
   */
  onAuthorizeError(error: string): void {
    let errorReason;

    this.authorizeError.emit();

    switch (error) {
      case 'csrfTokenHasExpired':
        errorReason = 'csrfTokenHasExpired';
        break;
      default:
        errorReason = 'default';
    }

    this.toastService.danger(
      this.translocoService.translate(`vkOAuthContainerComponent.toasts.authorizeError.${error}`, {
        integrationTypeName: this.integrationTypeName,
      }),
    );

    this.updateOAuthUrl();
  }

  /** Callback на успешную авторизацию */
  onSuccessAuthorize(): void {
    this.authorizeSuccess.emit();
  }

  /** Обновление OAuth url */
  updateOAuthUrl(): void {
    this.integrationService
      .getOAuthUrl(INTEGRATION_TYPES.VK, this.integrationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.oAuthUrl = response.authUrl;
        this.changeDetectorRef.detectChanges();
      });
  }
}

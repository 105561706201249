import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

/** Компонент для работы с подпиской */
@Component({
  selector: 'cq-signed',
  templateUrl: './signed.component.html',
  styleUrls: ['./signed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignedComponent {
  constructor(
    protected readonly subscriptionStore: SubscriptionStore,
    @Inject(WINDOW) private readonly windowRef: Window,
  ) {}
}

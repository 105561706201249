(function () {
  'use strict';

  require('./auto-event-work-url.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventWorkUrl', cqAutoEventWorkUrl());

  function cqAutoEventWorkUrl() {
    return {
      bindings: {
        urlContains: '=', // Учитывать подстраницы
        pageWriteUrl: '=', // Начальное значение свернуточти
        urlHas: '=', // Сработатет на любой или конкретной страннице
      },
      controller: 'CqAutoEventWorkUrlController',
      controllerAs: 'vm',
      template: require('./auto-event-work-url.html'),
    };
  }
})();

/**
 * Компонент-обёртка модального окна для просмотра оригинала входящего email для AngularJS
 *
 * TODO:
 *  Удалить при полном переходе на Angular
 */
(function () {
  'use strict';

  angular
    .module('myApp.modals.originalInboundEmailViewModalWrapper')
    .component('cqOriginalInboundEmailViewModalWrapper', cqOriginalInboundEmailViewModalWrapper());

  function cqOriginalInboundEmailViewModalWrapper() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // {String} modalWindowParams.body - Содержимое письма
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./original-inbound-email-view-modal-wrapper.html'),
    };
  }
})();

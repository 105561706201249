import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtmlTags',
})
export class RemoveHtmlTagsPipe implements PipeTransform {
  transform(value: string): string {
    value = value.replace(/<(?:.|\n)*?>/gm, ' '); // удаление тегов
    value = value.replace(/&nbsp;/gm, ' '); // Удаление неразрывных пробелов (хз зачем, это было написано давно)
    value = value.replace(/^\s+|\s+$/gm, ''); // трим

    // так было сделано в оригинальном фильтре на AngularJS, решил оставить аналогичное поведение
    return value || '...';
  }
}

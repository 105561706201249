import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import {
  AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS,
  INTEGRATION_TYPES,
} from '@http/integration/constants/integration.constants';
import { MessageModel } from '@http/message/message.model';
import { EmailNotificationCreateModalWrapperComponent } from '@panel/app/pages/integrations/content/email-notification/create-modal-wrapper/email-notification-create-modal-wrapper.component';
import { EmailNotificationSelectModalWrapperComponent } from '@panel/app/pages/integrations/content/email-notification/select-modal-wrapper/email-notification-select-modal-wrapper.component';
import { ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { IntegrationModel } from '@panel/app-old/shared/models/integration/integration.model';

@Component({
  selector: 'cq-auto-message-email-notification',
  templateUrl: './auto-message-email-notification.component.html',
  styleUrls: ['./auto-message-email-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageEmailNotificationComponent implements OnInit {
  @Input()
  abASide!: any;

  @Input()
  abBSide!: any;

  @Input()
  currentApp!: any;

  @Input()
  currentMessage!: any;

  @Input()
  disableNotificationSelector!: any;

  @Input()
  emailNotifications!: any;

  @Input()
  emailRecipients!: any;

  @Input()
  properties!: any;

  @Input()
  teamMembers!: any;

  @Output()
  wizardMoveStep: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NgbPopover)
  ngbPopover!: NgbPopover;

  protected accessToIntegrationsEmailNotification: any;
  protected control: any;
  protected emailNotificationSwitch: any;
  protected messagePartTypeName: any;

  protected AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS: any = AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS;

  constructor(
    protected readonly fb: FormBuilder,
    protected readonly integrationModel: IntegrationModel,
    protected readonly messageModel: MessageModel,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly paywallService: PaywallService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.accessToIntegrationsEmailNotification = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.INTEGRATIONS_EMAIL_NOTIFICATION,
      this.currentApp,
    );
    this.control = this.fb.control(null, Validators.required);
    this.emailNotificationSwitch = this.getSwitchValue();
    this.messagePartTypeName = this.messageModel.getMessagePartTypeName(this.currentMessage.parts);

    if (
      this.currentMessage.notificationIntegrations &&
      this.currentMessage.notificationIntegrations.emailNotification
    ) {
      this.control.setValue(this.currentMessage.notificationIntegrations.emailNotification.integration.id, {
        emitEvent: false,
      });
    }

    this.control.valueChanges.subscribe((value: any) => {
      console.log(value);
      if (!value) {
        delete this.currentMessage.notificationIntegrations.emailNotification;
        return;
      }

      this.currentMessage.notificationIntegrations = {
        ...this.currentMessage.notificationIntegrations,
        emailNotification: {
          integration: this.emailNotifications.find((item: any) => item.id === value),
        },
      };
    });
  }

  getSwitchValue() {
    if (
      this.currentMessage.notificationIntegrations &&
      this.currentMessage.notificationIntegrations.emailNotification
    ) {
      return true;
    }

    return false;
  }

  changeEmailNotificationSwitch(value: any): void {
    if (!value) {
      this.removeIntegration();
    }
  }

  openAddEmailNotificationModal(): void {
    let modal = this.modalHelperService.open(EmailNotificationCreateModalWrapperComponent);

    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
      integrationExternal: this.integrationModel.getDefaultIntegration(INTEGRATION_TYPES.EMAIL_NOTIFICATION),
      properties: this.properties,
      emailRecipients: this.emailRecipients,
      teamMembers: this.teamMembers,
    };

    modal.result
      .then((newNotification: any) => {
        this.emailNotifications = [...this.emailNotifications, newNotification];
        this.currentMessage.notificationIntegrations.emailNotification = {
          integration: newNotification,
          messageConfig: { comment: null },
        };
        this.control.setValue(newNotification.id);
      })
      .catch(() => {});
  }

  openSelectEmailNotificationModal(integration: any): void {
    if (!integration.active) {
      return this.control.setValue(null);
    }

    let modal = this.modalHelperService.open(EmailNotificationSelectModalWrapperComponent);

    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
      integrationExternal: this.emailNotifications.find((item: any) => item.id === this.control.getRawValue()),
      properties: this.properties,
    };

    modal.result.catch(() => {
      this.removeIntegration();
    });
  }

  /**
   * Удаление нотификации сообщения
   */
  removeIntegration(): void {
    this.control.setValue(null);
  }
}

/**
 * Компонент чарта воронок
 */
(function () {
  'use strict';

  require('./funnel-chart.controller');
  require('./funnel-step-popover-template.html');

  angular.module('myApp.funnelChart').component('cqFunnelChart', cqFunnelChart());

  function cqFunnelChart() {
    return {
      bindings: {
        counters: '=', // Результат построения воронки (счётчики)
        currentApp: '=', // Текущее приложение
        currentStep: '=?', // Текущий шаг для сегментации
        djangoUser: '=', // Текущий пользователь
        funnel: '=', // Воронка
        isSegmentationNotAvailable: '=?', // Флаг указывающий возможно ли использовать сегментацию воронок
        isStepsBlocked: '=?', // Флаг указывающий что шаг в воронке не активен
      },
      controller: 'CqFunnelChartController',
      controllerAs: 'vm',
      template: require('./funnel-chart.html'),
    };
  }
})();

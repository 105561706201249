(function () {
  'use strict';

  angular.module('myApp.autoMessages', []);

  require('./auto-messages.routes');
  require('./editor/auto-message-editor.component');
  require('./editor/steps/audience/audience.component');
  require('./editor/steps/content/content.component');
  require('./editor/steps/display-settings/display-settings.component');
  require('./editor/steps/goal-and-notification/goal.component');
  require('./statistics/auto-message-statistics.constants');
  require('./statistics/auto-message-statistics.component');
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { TopUpBalanceModalForm } from '@panel/app/pages/subscription/general/signed/balance/top-up-balance-modal/top-up-balance-modal.types';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-top-up-balance-modal',
  templateUrl: './top-up-balance-modal.component.html',
  styleUrls: ['./top-up-balance-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopUpBalanceModalComponent {
  /** Флаг выполнения запроса */
  isApiRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Форма с вводом суммы пополнения */
  topUpBalanceForm!: FormGroup<TopUpBalanceModalForm>;

  /** Максимальная сумма для платежа */
  MAX_PAYMENT_AMOUNT = 1000000;
  /** Минимальная сумма для платежа */
  MIN_PAYMENT_AMOUNT = 500;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    public readonly ngbActiveModal: NgbActiveModal,
    protected readonly subscriptionStore: SubscriptionStore,
  ) {
    this.topUpBalanceForm = new FormGroup<TopUpBalanceModalForm>({
      amount: new FormControl<string | null>(null, [
        Validators.required,
        Validators.min(this.MIN_PAYMENT_AMOUNT),
        Validators.max(this.MAX_PAYMENT_AMOUNT),
      ]),
    });
  }

  /** Формирование временного счёта и переход на страницу пополнения баланса */
  topUpBalance(): void {
    if (!this.topUpBalanceForm.valid) {
      return;
    }

    const billing = this.subscriptionStore.billing$.getValue()!;
    const amount = this.topUpBalanceForm.controls.amount.value!;

    this.isApiRequestPerformed.next(true);

    billing
      .topUpBalance(amount)
      .then(() => {
        this.trackClickChoosePaymentMethodButton(amount);
        this.ngbActiveModal.close();
      })
      .finally(() => this.isApiRequestPerformed.next(false));
  }

  /**
   * Трек выбора способа оплаты
   *
   * @param amount Сумма пополнения баланса (в рублях)
   */
  trackClickChoosePaymentMethodButton(amount: string): void {
    const eventName = 'Оплата - клик в попапе "Пополнение баланса"';
    const eventParams = { $amount: parseFloat(amount) };

    this.carrotquestHelper.track(eventName, eventParams);
  }

  /** Дизейблить ли кнопку выбора метода оплаты */
  isDisabledChoosePaymentMethodButton() {
    switch (true) {
      case this.topUpBalanceForm.controls.amount.invalid:
      case this.isApiRequestPerformed.getValue():
        return true;
      default:
        return false;
    }
  }
}

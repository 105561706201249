import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { EventPropEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/components/event-prop-editor/event-prop-editor.module';

import { EventPropsEditorComponent } from './event-props-editor.component';

@NgModule({
  declarations: [EventPropsEditorComponent],
  exports: [EventPropsEditorComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, EventPropEditorModule],
})
export class EventPropsEditorModule {}

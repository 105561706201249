import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StaticProgressbarComponent } from './static-progressbar.component';

@NgModule({
  declarations: [StaticProgressbarComponent],
  exports: [StaticProgressbarComponent],
  imports: [CommonModule],
})
export class StaticProgressbarModule {}

<ng-container *transloco="let t; read: 'branchActionAppOnlineConditionComponent'">
  <div class="d-flex flex-nowrap justify-content-between align-items-center margin-bottom-10">
    <span class="font-weight-bold">{{ t('heading') }}</span>
    <button
      class="btn btn-borderless-primary"
      [ngbPopover]="workingTimePopover"
      container="body"
      placement="bottom-right"
      [autoClose]="true"
    >
      <i class="btn-icon cqi-sm cqi-question-circle"></i>
    </button>
  </div>
  <cq-bot-action-next-branch-selector
    [formControl]="actionForm.controls.nextBranchLinkId!"
    [chatBotType]="chatBotType"
    [canSelectActionAsNextBranch]="canSelectActionAsNextBranch"
    [chatBotId]="chatBotId"
    [nextBranchOptions]="nextBranchOptions"
    (branchSelect)="onChangeBranch($event)"
    (addBranchClick)="branchCreate.next($event)"
  >
  </cq-bot-action-next-branch-selector>

  <ng-template #workingTimePopover>
    <div class="flex">
      <div>
        <div>{{ t('popover.timeZone') }}</div>
        <div class="margin-bottom-15">{{ currentApp.settings.timezone }}</div>
        <div class="mb-10">
          <div *ngFor="let workTime of currentApp.settings.messenger_work_time">
            <span>{{ t('popover.days.' + workTime.days) }}</span>
            <span>{{ workTime.start | workTime }}</span>
            -
            <span>{{ workTime.end | workTime }}</span>
          </div>
        </div>
        <a
          uiSref="app.content.settings.general"
          [uiParams]="{ tab: 'chatSettings' }"
          target="_blank"
        >
          <i class="cqi-sm cqi-external-link"></i>
          {{ t('popover.link') }}
        </a>
      </div>
      <i class="cqi-sm cqi-times cursor-pointer"></i>
    </div>
  </ng-template>
</ng-container>

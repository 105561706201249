import { TranslocoService } from '@jsverse/transloco';
import { Container, Sprite } from 'pixi.js';

import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_INDENT,
  TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { AtlasBuilderService } from '@panel/app/services/canvas/tirgger-chain/builders/atlas-builder/atlas-builder.service';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';

/**
 * View экшена
 */
export abstract class AbsInteractiveBlockPartView<BlockType extends TRIGGER_CHAIN_BLOCK_TYPE = any> {
  graphicContainer: Container = new Container();

  connectionPoint: Container = new Container();

  abstract type: INTERACTIVE_BLOCK_PART;
  abstract renderContent(): Container;

  constructor(readonly stepView: IBlockView<BlockType>, protected readonly transloco: TranslocoService) {
    this.graphicContainer.name = `ActionView :: ${stepView.type} :: ${stepView.uuid}`;
    this.connectionPoint.eventMode = 'static';
    this.connectionPoint.cursor = 'pointer';
    this.connectionPoint.position.set(-(this.connectionPoint.width / 2), 0);

    this.render();
  }

  rerender(): void {
    this.graphicContainer.removeChildren();

    this.render();
  }

  abstract hasConnection(): boolean;

  render(): void {
    this.connectionPoint.removeChildren();

    const newConnectionPoint = this.hasConnection() ? this.renderConnectionPoint() : this.renderConnectionPointPlus();

    this.connectionPoint.addChild(newConnectionPoint);

    let graphic = this.renderContent.call(this);

    this.graphicContainer.addChild(graphic);
  }

  private renderConnectionPoint(): Container {
    return new Sprite(AtlasBuilderService.getTexture('connection-point-blank'));
  }

  protected addAndPositionConnectionPoint(parent: Container): void {
    parent.addChild(this.connectionPoint);
    this.connectionPoint.position.set(
      TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX - TRIGGER_CHAIN_BLOCK_CONTENT_INDENT + this.connectionPoint.width,
      (parent.height - this.connectionPoint.height) / 2,
    );
  }

  private renderConnectionPointPlus(): Container {
    const isConnectionValid = this.stepView.data.connectionsValid ? true : this.hasConnection();
    return new Sprite(
      AtlasBuilderService.getTexture(
        isConnectionValid ? 'connection-point-plus-gray400' : 'connection-point-plus-danger400',
      ),
    );
  }
}

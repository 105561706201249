<ng-container *transloco="let t; read: 'billingEmailComponent'">
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center title">
      <div class="font-weight-bold">{{ t('title') }}</div>
      <i
        class="cqi-sm cqi-question-circle ml-5"
        [ngbTooltip]="t('tooltip')"
      ></i>
    </div>
    <div class="flex-grow text-secondary text-truncate">
      <ng-container *ngIf="customer.email; else empty">{{ customer.email }}</ng-container>
      <ng-template #empty>–</ng-template>
    </div>
    <div class="ml-20">
      <button
        *ngIf="customer.email; else add"
        class="btn btn-secondary btn-sm"
        type="button"
        (click)="onEditEmail()"
      >
        {{ t('editEmailButton') }}
      </button>
      <ng-template #add
        ><button
          class="btn btn-primary btn-sm"
          type="button"
          (click)="onAddEmail()"
        >
          {{ t('addEmailButton') }}
        </button>
      </ng-template>
    </div>
  </div>
</ng-container>

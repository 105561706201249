(function () {
  'use strict';

  require('./welcome-message.controller');

  angular.module('myApp.chatSettings').component('cqWelcomeMessage', cqWelcomeMessage());

  function cqWelcomeMessage() {
    return {
      bindings: {
        currentApp: '=',
        hasKnowledgeBase: '=', // Существует ли БЗ
        message: '=', // Приветственное сообщение в чате после его открытия
        showKbLinkInWelcomeMessage: '=', // Введет ли приветственное сообщение на БЗ
        routingBot: '=', // ID роутинг бота, если тот включен, чтоб можно было перейти
      },
      controller: 'CqWelcomeMessageController',
      controllerAs: 'vm',
      template: require('./welcome-message.html'),
    };
  }
})();

(function () {
  'use strict';

  angular
    .module('myApp.privacySettings')
    .controller('CqDoubleOptInEmailPreviewController', CqDoubleOptInEmailPreviewController);

  function CqDoubleOptInEmailPreviewController() {
    var vm = this;
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';

@Component({
  selector: 'cq-email-notification-chat-bots-list-triggers[chatBots][integrationId]',
  templateUrl: './chat-bots-list-triggers.component.html',
  styleUrls: ['./chat-bots-list-triggers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationChatBotsListTriggersComponent<T extends CHAT_BOT_TYPE> implements OnInit {
  /** Список чат-ботов, использующих интеграцию */
  @Input() chatBots!: ChatBot<T>[];
  /** ID текущей интеграции */
  @Input() integrationId!: string | null;
  /** Флаг показа компонента из модалки создания интеграции */
  @Input() isShowedFromModal?: boolean;
  /** Флаг свёрнутости списка ботов */
  isCollapsed!: boolean;

  ngOnInit(): void {
    this.isCollapsed = this.isShowedFromModal ? false : this.chatBots.length === 0;
  }

  /**
   * Получение экшенов с текущей интеграцией EMAIL_NOTIFICATION
   *
   * @param actions Экшены чат-бота
   */
  getActionsWithCurrentIntegration(actions: ChatBotAction[]): ChatBotAction[] {
    return actions.filter(
      (action: ChatBotAction) =>
        action.type === CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION && action.body === this.integrationId,
    );
  }

  /**
   * Получение веток с текущей интеграцией EMAIL_NOTIFICATION
   *
   * @param chatBot - Чат-бот, у которого нужно получить ветки
   */
  getBranchesWithEmailNotificationAction(chatBot: ChatBot<T>): ChatBotBranch[] {
    return (
      (chatBot &&
        chatBot.branches.filter(
          (branch: ChatBotBranch) => this.getActionsWithCurrentIntegration(branch.actions).length,
        )) ||
      []
    );
  }

  /**
   * Получение отформатированного названия веток бота в виде:
   * "«Название ветки 1», «Название ветки 2»..."
   *
   * @param chatBot - Чат-бот, у которого нужно получить отформатированные названия веток
   */
  getFormattedChatBotBranchesNames(chatBot: ChatBot<T>): string {
    return this.getBranchesWithEmailNotificationAction(chatBot)
      .map((branch: ChatBotBranch) => `«${branch.name}»`)
      .join(', ');
  }
}

<ng-container *transloco="let t">
  <div
    class="flex align-items-center margin-between-cols-20 margin-bottom-10"
    *ngFor="let question of questionsControls; let i = index"
  >
    <cq-vk-forms-question-name
      [control]="question.controls.k"
      class="flex-grow no-flex-basis"
    ></cq-vk-forms-question-name>
    <div>
      <i class="cqi-sm cqi-arrow-right"></i>
    </div>
    <cq-vk-forms-question-property
      class="flex-grow no-flex-basis margin-right-10 min-width-0"
      [control]="question.controls.v"
      [currentApp]="currentApp"
      [properties]="properties"
    ></cq-vk-forms-question-property>
    <button
      class="btn btn-borderless-primary"
      type="button"
      (click)="removeQuestion(i)"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
  <button
    class="btn btn-borderless-primary"
    type="button"
    (click)="addQuestion()"
  >
    <i class="btn-icon-left cqi-sm cqi-plus"></i>
    <span>{{ t('vkFormsQuestionsComponent.addPropertyButton') }}</span>
  </button>
</ng-container>

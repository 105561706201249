import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-dialog-start[radioValue]',
  templateUrl: './dialog-start.component.html',
  styleUrls: ['./dialog-start.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogStartComponent {
  formControl: GenericFormControl<boolean> = new GenericFormControl<boolean>(true);

  @Input()
  set radioValue(value: boolean) {
    this.formControl.setValue(value);
  }

  /** Колбек на изменение активного радио баттона */
  @Output()
  radioValueChange = this.formControl.valueChanges;
}

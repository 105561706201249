<ng-container *transloco="let t; read: 'datepickerInputComponent'">
  <div class="has-feedback">
    <input
      class="form-control"
      [ngbPopover]="dateTimePickerPopover"
      [autoClose]="'outside'"
      [ngModel]="getDisplayDate()"
      (blur)="onBlurInput($event)"
      popoverClass="datetimepicker-input-popover"
      container="body"
    />
    <span class="form-control-feedback">
      <i class="btn-icon cqi-sm cqi-calendar-o"></i>
    </span>
  </div>

  <ng-template #dateTimePickerPopover>
    <cq-datepicker
      [initialDate]="initialDate"
      [(ngModel)]="selectedDate"
      (ngModelChange)="onSelectDate()"
    >
    </cq-datepicker>

    <ng-container *ngIf="options">
      <ng-container *ngIf="options.showTimepicker">
        <hr class="m-0" />
        <cq-timepicker
          class="p-10"
          [(ngModel)]="selectedDate"
          (ngModelChange)="onSelectDate()"
        ></cq-timepicker>
      </ng-container>

      <ng-container *ngIf="options.showApplyButtons">
        <hr class="m-0" />
        <div class="d-flex padding-10">
          <button
            class="btn btn-secondary flex-grow-1 flex-shrink-1 no-flex-basis"
            (click)="onClickCancelButton()"
            type="button"
          >
            {{ 'general.cancel' | transloco }}
          </button>
          <button
            class="btn btn-primary flex-grow-1 flex-shrink-1 no-flex-basis ml-10"
            (click)="onClickApplyButton()"
            type="button"
          >
            {{ 'general.apply' | transloco }}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>

import { Injectable } from '@angular/core';

import { AUTO_EVENTS_GROUPS } from '@http/track-master/track-master.constants';
import { AutoEvent, AutoEventParams, TrackMasterModel } from '@http/track-master/track-master.model';
import { AutoEventModalForm } from '@panel/app/partials/modals/create-auto-event-modal/create-auto-event-modal.component';

@Injectable()
export class CreateAutoEventModalHelperService {
  constructor(private readonly trackMasterModel: TrackMasterModel) {}

  /**
   * Преобразовывает данные с формы в тип AutoEvent (который потом можно уже сохранять API Сервисом)
   */
  formDataToAutoEvent(form: AutoEventModalForm): AutoEvent {
    const newEvent = this.trackMasterModel.getDefault(form.type);
    newEvent.name = form.name;

    // Преобразовываем "Свойства из селекторов"
    newEvent.paramsSelectors = form.selectorsProperties.map(({ propertyName, value }): AutoEventParams => {
      if (!propertyName || !value) {
        throw new Error('selectorsProperties should not contain null values');
      }
      return {
        name: propertyName,
        selector: value,
      };
    });

    // Преобразовываем "Текстовые свойства"
    newEvent.params = form.textProperties.map(({ propertyName, value }): AutoEventParams => {
      if (!propertyName || !value) {
        throw new Error('textProperties should not contain null values');
      }
      return {
        name: propertyName,
        selector: value,
      };
    });

    switch (form.type) {
      case AUTO_EVENTS_GROUPS.CLICK:
        newEvent.selector = form.cssSelector;
        newEvent.urlHas = form.worksOnPage.enabled;
        newEvent.url = form.worksOnPage.url;
        newEvent.urlContains = form.worksOnPage.hasSubpages;
        break;
      case AUTO_EVENTS_GROUPS.INPUT:
        newEvent.selector = form.cssSelector;
        newEvent.urlHas = form.worksOnPage.enabled;
        newEvent.url = form.worksOnPage.url;
        newEvent.urlContains = form.worksOnPage.hasSubpages;
        newEvent.inputKey = form.writeProperty;
        break;
      case AUTO_EVENTS_GROUPS.URL:
      case AUTO_EVENTS_GROUPS.URL_SCROLL:
        newEvent.url = form.pageUrl.url;
        newEvent.urlContains = form.pageUrl.hasSubpages;
        break;
      default:
        throw new Error('Unknown form type');
    }
    return newEvent;
  }
}

(function () {
  'use strict';

  require('./conversations-conversation-history.controller');

  angular
    .module('myApp.conversations')
    .component('cqConversationsConversationHistory', cqConversationsConversationHistory());

  function cqConversationsConversationHistory() {
    return {
      bindings: {
        channels: '=?', // каналы
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        isDarkThemeActive: '=?', // теманя тема чата
        onClose: '&?', // функция, вызываемая при закрытии истории
        onModalClose: '&?', // функция, вызываемая при закрытии истории
        onOpenConversationClick: '<?', // функция, вызываемая при нажатии на кнопку перехода в диалог
        onRedirectConversationClick: '<?', // нужно ли после нажатия на кнопку нужно перенаправить в раздел диалоги
        teamMembers: '=?', // члены команды
        showCloseButton: '=?', // Флаг показа кнопки "Назад"
        userId: '<', // ID пользователя, для которого нужно получить историю диалогов
      },
      controller: 'CqConversationsConversationHistoryController',
      controllerAs: 'vm',
      template: require('./conversations-conversation-history.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'memberInvitationsFiltersComponent'">
  <div
    *ngIf="!isFilterByEmailMode"
    class="flex flex-grow align-items-center"
  >
    <div class="btn-group">
      <button
        class="btn btn-outline-primary"
        [ngClass]="getActiveClassForStatusButton(null)"
        (click)="onClickFilterByStatusButton(null)"
        data-test="member-invitations-filters-by-status-button-null"
      >
        {{ t('filter.all') }}
      </button>

      <button
        *ngFor="let memberInvitationStatus of MEMBER_INVITATION_STATUS_ARRAY"
        class="btn btn-outline-primary"
        [ngClass]="getActiveClassForStatusButton(memberInvitationStatus)"
        (click)="onClickFilterByStatusButton(memberInvitationStatus)"
        [attr.data-test]="'member-invitations-filters-by-status-button-' + memberInvitationStatus"
      >
        {{ getStatusTranslate(memberInvitationStatus) }}
      </button>
    </div>

    <button
      class="btn btn-outline-primary margin-left-auto"
      type="button"
      (click)="onClickToggleFilterByEmailModeButton()"
      data-test="member-invitations-filters-enable-filter-by-email-button"
    >
      <i class="btn-icon cqi-sm cqi-search"></i>
    </button>
  </div>

  <div
    *ngIf="isFilterByEmailMode"
    class="flex flex-grow align-items-center margin-left-10-neg"
  >
    <div class="form-group has-feedback has-feedback-left flex-grow no-margin">
      <input
        #inputEmailPhrase
        class="form-control no-border"
        [(ngModel)]="currentFilterEmailPhrase"
        [placeholder]="t('search.input.placeholder')"
        autocomplete="off"
        type="text"
        (ngModelChange)="onClickFilterInvitationByEmailButton()"
        data-test="member-invitations-filters-input-email-phrase"
      />
      <span class="form-control-feedback">
        <i class="cqi-sm cqi-search text-muted"></i>
      </span>
    </div>

    <button
      class="btn btn-borderless-primary"
      type="button"
      (click)="onClickToggleFilterByEmailModeButton()"
      data-test="member-invitations-filters-disable-filter-by-email-button"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ConversationStatusFilterComponent } from './status-filter.component';

@NgModule({
  declarations: [ConversationStatusFilterComponent],
  imports: [CommonModule, TranslocoModule, NgbDropdownModule, NgbTooltipModule, ReactiveFormsModule],
})
export class StatusFilterModule {}

/**
 * Проверка того, что переданное значение является ссылкой на картинку
 */
(function () {
  'use strict';

  angular.module('myApp.filters.isImageUrl').filter('isImageUrl', isImageUrl);

  function isImageUrl($filter, fileHelper) {
    return isImageUrlFilter;

    /**
     * Если переданное значение - это ссылка на картинку, то вернётся true, иначе false
     *
     * @param {*} value Значение для проверки
     * @return {Boolean}
     */
    function isImageUrlFilter(value) {
      if (value) {
        const stringValue = value.toString();
        return $filter('isUrl')(stringValue) && fileHelper.isAcceptedImageExtension(stringValue);
      } else {
        return false;
      }
    }
  }
})();

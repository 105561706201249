/**
 * Директива для отслеживания overflow элемента. Если элемент 'переполнен' своими потомками - в переменную директивы вернётся true, иначе - false
 */
(function () {
  'use strict';

  angular.module('myApp.directives.overflow').directive('cqOverflow', cqOverflow);

  function cqOverflow($parse, $window, OverlayScrollbars) {
    return {
      link: link,
      require: {
        cqCustomScroll: '?',
      },
      restrict: 'A',
      scope: {
        cqOverflow: '=?',
        overflowY: '=?',
        overflowX: '=?',
      },
    };

    function link(scope, iElement, iAttrs, controllers) {
      var overlayScrollbarsInstance;

      if (controllers.cqCustomScroll) {
        // если директива висит на одном элементе с cqCustomScroll, то нужно получать параметры скролла при помощи OverlayScrollbars
        overlayScrollbarsInstance = OverlayScrollbars(iElement[0]);

        if (
          (iAttrs.hasOwnProperty('overflowX') && iAttrs.hasOwnProperty('overflowY')) ||
          (!iAttrs.hasOwnProperty('overflowX') && !iAttrs.hasOwnProperty('overflowY'))
        ) {
          scope.$watchCollection(getWidthWatchExpressionForCqCustomScroll, watchWidthHandler);
          scope.$watchCollection(getHeightWatchExpressionForCqCustomScroll, watchHeightHandler);
        } else if (iAttrs.hasOwnProperty('overflowX')) {
          scope.$watchCollection(getWidthWatchExpressionForCqCustomScroll, watchWidthHandler);
        } else if (iAttrs.hasOwnProperty('overflowY')) {
          scope.$watchCollection(getHeightWatchExpressionForCqCustomScroll, watchHeightHandler);
        }
      } else {
        if (
          (iAttrs.hasOwnProperty('overflowX') && iAttrs.hasOwnProperty('overflowY')) ||
          (!iAttrs.hasOwnProperty('overflowX') && !iAttrs.hasOwnProperty('overflowY'))
        ) {
          scope.$watchCollection(getWightWatchExpression, watchWidthHandler);
          scope.$watchCollection(getHeightWatchExpression, watchHeightHandler);
        } else if (iAttrs.hasOwnProperty('overflowX')) {
          scope.$watchCollection(getWightWatchExpression, watchWidthHandler);
        } else if (iAttrs.hasOwnProperty('overflowY')) {
          scope.$watchCollection(getHeightWatchExpression, watchHeightHandler);
        }
      }

      // надо так же отслеживать ресайз окна браузера, на случай если пользователь решит руками его начать ресайзить
      angular.element($window).on('resize', resizeHandler);

      scope.$on('$destroy', destroy);

      function destroy() {
        angular.element($window).off('resize', resizeHandler);
      }

      /**
       * Выражение, которое надо отслеживать для определения переполнеия по высоте (по оси Y)
       *
       * @returns {Array.<Number>}
       */
      function getHeightWatchExpression() {
        // в обработчик передаётся массив из двух значений, которые позволяют определить, 'переполнен' элемент или нет
        return [iElement.prop('scrollHeight'), iElement.prop('clientHeight')];
      }

      /**
       * Выражение, которое надо отслеживать для определения переполнеия по высоте (по оси Y) при использовании cqCustomScroll
       *
       * @returns {Array.<Number>}
       */
      function getHeightWatchExpressionForCqCustomScroll() {
        var scrollInfo = overlayScrollbarsInstance.scroll();

        return [scrollInfo.trackLength.y + scrollInfo.max.y, scrollInfo.trackLength.y];
      }

      /**
       * Выражение, которое надо отслеживать для определения переполнеия по ширине (по оси X)
       *
       * @returns {Array.<Number>}
       */
      function getWightWatchExpression() {
        // в обработчик передаётся массив из двух значений, которые позволяют определить, 'переполнен' элемент или нет
        return [iElement.prop('scrollWidth'), iElement.prop('clientWidth')];
      }

      /**
       * Выражение, которое надо отслеживать для определения переполнеия по ширине (по оси X) при использовании cqCustomScroll
       *
       * @returns {Array.<Number>}
       */
      function getWidthWatchExpressionForCqCustomScroll() {
        var scrollInfo = overlayScrollbarsInstance.scroll();

        return [scrollInfo.trackLength.x + scrollInfo.max.x, scrollInfo.trackLength.x];
      }

      /**
       * Функция-обработчик, вызываемая при ресайзе окна
       */
      function resizeHandler() {
        scope.$applyAsync();
      }

      /**
       * Установка scope.cqOverflow в true или false в зависимости от переполнения
       */
      function setCqOverflow() {
        // последнее условие || false стоит не с проста, т.к. без него в cqOverflow может записаться undefined
        scope.cqOverflow = scope.overflowX || scope.overflowY || false;
      }

      /**
       * Определение переполненности элемента по высоте (по оси Y)
       *
       * @param {Array.<Number>} newValue Значение, которое вернёт getWatchExpression()
       */
      function watchHeightHandler(newValue) {
        scope.overflowY = newValue[0] > newValue[1];

        setCqOverflow();
      }

      /**
       * Определение переполненности элемента по ширине (по оси X)
       *
       * @param {Array.<Number>} newValue Значение, которое вернёт getWatchExpression()
       */
      function watchWidthHandler(newValue) {
        scope.overflowX = newValue[0] > newValue[1];

        setCqOverflow();
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'integrationTriggerMessagesComponent'">
  <ng-container *ngIf="isEmptyTriggerMessages">
    <a
      class="btn btn-secondary"
      data-test="go-to-trigger-messages-page-a"
      target="_blank"
      uiSref="app.content.messages.auto.table"
    >
      <i class="cqi-sm cqi-external-link"></i>
      <span>{{ t('goToTriggerMessagesPage') }}</span>
    </a>
  </ng-container>

  <ng-container *ngIf="!isEmptyTriggerMessages">
    <div class="mb-20 margin-between-rows-15">
      <div
        *ngFor="let triggerMessage of triggerMessages; let i = index"
        [attr.data-test]="'integration-trigger-message-div-' + i"
      >
        <div class="d-flex align-items-center">
          <i [classList]="'cqi-sm text-primary mr-5 ' + getIconForTriggerMessage(triggerMessage.type!)"></i>
          <a
            class="font-weight-bold"
            [uiParams]="{ messageId: triggerMessage.id }"
            uiSref="app.content.messages.auto.edit"
            >{{ triggerMessage.name }}</a
          >
        </div>

        <div *ngIf="integrationType === INTEGRATION_TYPES.AMOCRM">
          <!-- Теги сделки -->
          <div
            class="mt-10"
            *ngIf="isTriggerMessageAmocrmLeadTagsNotEmpty(triggerMessage)"
            data-test="integration-trigger-message-lead-tags-div"
          >
            <div class="d-inline-block font-weight-bold">
              {{ t('leadTags', { leadTags: getTriggerMessageAmocrmLeadTags(triggerMessage).length }) }}
            </div>
            <cq-amocrm-lead-tags-readonly
              class="d-inline-block"
              [leadTags]="getTriggerMessageAmocrmLeadTags(triggerMessage)"
            ></cq-amocrm-lead-tags-readonly>
          </div>
        </div>
      </div>
    </div>

    <div class="text-secondary">
      <div class="d-flex align-items-baseline">
        <i class="cqi-sm cqi-lightbulb mr-5"></i>
        <div>
          <span>{{ t('note') }}</span>
          <a
            target="_blank"
            uiSref="app.content.messages.auto.table"
            >{{ t('goToTriggerMessagesPage') }}</a
          >
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

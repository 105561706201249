import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '../../shared.module';
import { SafeRemoveComponent } from './safe-remove.component';

@NgModule({
  declarations: [SafeRemoveComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, TranslocoModule],
  exports: [SafeRemoveComponent],
})
export class SafeRemoveModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { CarrotquestHelperProvider, ConversationTagHelpServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { ManageTagsStore } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.store';

import { RemoveTagComponent } from './remove-tag.component';

@NgModule({
  declarations: [RemoveTagComponent],
  exports: [RemoveTagComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [CarrotquestHelperProvider, ConversationTagHelpServiceProvider, ManageTagsStore],
})
export class RemoveTagModule {}

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import {
  LEAD_BOT_PSEUDO_INTEGRATION_TYPE,
  STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE,
} from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.types';

/** Объект с типами интеграций и псевдоинтеграцией с лид ботом */
export const STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES = {
  ...INTEGRATION_TYPES,
  ...LEAD_BOT_PSEUDO_INTEGRATION_TYPE,
};

/** Список интеграций, которые будут отображаться в темплейте */
export const AVAILABLE_INTEGRATIONS: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE[] = (() => {
  const availableIntegrations = {
    RU: [
      STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.LEAD_BOT,
      STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.EMAIL_NOTIFICATION,
      STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.AMOCRM,
      STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.BITRIX24,
    ],
    US: [
      STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.LEAD_BOT,
      STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.EMAIL_NOTIFICATION,
      STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.AMOCRM,
    ],
  };

  return availableIntegrations[environment.country];
})();

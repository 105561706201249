import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import { UserService } from '@http/user/services/user.service';
import {
  EDIT_USER_PROPERTY_MODAL_DATA_TOKEN,
  EditUserPropertyModalData,
} from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.token';
import { noWhitespaceValidator } from '@panel/app/shared/validators/no-whitespace/no-whitespace-validator';
import { PropertyModel } from '@http/property/property.model';

@Component({
  selector: 'cq-edit-user-property-modal',
  templateUrl: './edit-user-property-modal.component.html',
  styleUrls: ['./edit-user-property-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditUserPropertyModalComponent implements OnInit {
  /** Флаг выполнения запроса */
  isApiRequestPerformed: boolean = false;

  /**
   * Преобразованное в читабельный формат свойство пользователя.
   * Если это системное свойство, то будет взят перевод для него. Если пользовательское — останется как есть.
   */
  parsedPropertyName!: string;

  /** Контрол со значением свойства пользователя */
  propertyValueControl!: UntypedFormControl;

  constructor(
    @Inject(NgbActiveModal)
    public readonly activeModal: NgbActiveModal,
    @Inject(EDIT_USER_PROPERTY_MODAL_DATA_TOKEN)
    public readonly data: EditUserPropertyModalData,
    public readonly propertyModel: PropertyModel,
    @Inject(TranslocoService)
    public readonly translocoService: TranslocoService,
    @Inject(UserService)
    public readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.parsedPropertyName = this.propertyModel.parseUserPropertyName(this.data.propertyName);

    this.propertyValueControl = new UntypedFormControl(this.data.propertyValue, [
      Validators.required,
      noWhitespaceValidator,
    ]);
  }

  /** Сохранение свойства пользователя */
  saveProperty(): void {
    if (!this.propertyValueControl.valid) {
      this.propertyValueControl.markAsTouched();
      return;
    }

    this.isApiRequestPerformed = true;

    const props = [
      {
        op: 'update_or_create',
        key: this.data.propertyName,
        value: this.propertyValueControl.value.trim(),
      },
    ];

    this.userService
      .setProperties(this.data.userId, props)
      .pipe(finalize(() => (this.isApiRequestPerformed = false)))
      .subscribe(() => this.activeModal.close(this.propertyValueControl.value));
  }
}

import {
  PSEUDO_TEMPLATE_DIRECTORY_IDS,
  PSEUDO_TEMPLATE_DIRECTORY_TYPES,
} from '../../../../app/http/template-directory/template-directory.constants';

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.saveReadyMessageTemplateAsTemplateModal')
    .controller(
      'CqSaveReadyMessageTemplateAsTemplateModalController',
      CqSaveReadyMessageTemplateAsTemplateModalController,
    );

  function CqSaveReadyMessageTemplateAsTemplateModalController(
    $uibModal,
    validationHelper,
    ACTIONS_ON_DIRECTORY,
    messageTemplateModel,
    templateDirectoryModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.createTemplate = createTemplate;
      vm.currentDirectory = templateDirectoryModel.getWithoutDirectory(); // Выбранная директория
      vm.openCreateDirectoryModal = openCreateDirectoryModal;
      vm.PSEUDO_TEMPLATE_DIRECTORY_IDS = PSEUDO_TEMPLATE_DIRECTORY_IDS;
      vm.PSEUDO_TEMPLATE_DIRECTORY_TYPES = PSEUDO_TEMPLATE_DIRECTORY_TYPES;
      vm.templateName = ''; // Имя шаблона
      vm.validationHelper = validationHelper;
    }

    /**
     * Создание шаблона
     *
     * @param {boolean} isValid - Валидность формы
     * @returns {*|PromiseLike<T>|Promise<T>}
     */
    function createTemplate(isValid) {
      if (isValid) {
        var template = messageTemplateModel.getDefault();
        template.name = vm.templateName;
        template.directory = vm.currentDirectory;
        return messageTemplateModel
          .createFromMessagePart(vm.resolve.currentApp.id, vm.resolve.messagePart, template)
          .then(createTemplateSuccess);
      }

      /**
       * Шаблон успешно создан
       *
       * @param {String} templateId - ID шаблона
       */
      function createTemplateSuccess(templateId) {
        template.id = templateId;
        vm.modalInstance.close(template);
      }
    }

    /**
     * Открытие модалки создания папки
     */
    function openCreateDirectoryModal() {
      var createDirectoryModal = $uibModal.open({
        component: 'cqTemplatesDirectoryEditorModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.resolve.currentApp),
        },
      });

      createDirectoryModal.result.then(createDirectorySuccess);

      function createDirectorySuccess(response) {
        switch (response.action) {
          case ACTIONS_ON_DIRECTORY.CREATE: {
            vm.resolve.directories.push(response.directory);
            vm.currentDirectory = response.directory;
            break;
          }
        }
      }
    }
  }
})();

import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';

import { ToastOptions, ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

const SOMETHING_WENT_WRONG_TOAST_DELAY = 6 * 1000; // Время, через которое закроется тост Что-то пошло не так

/**
 * Имена системных тостов
 */
export const enum TOAST_NAME {
  OFFLINE_CONVERSATIONS = 'OFFLINE_CONVERSATIONS',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
}

@Injectable({
  providedIn: 'root',
})
export class SystemToastService {
  constructor(
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly router: UIRouter,
  ) {}

  /**
   * Показ тоста с ошибкой «Что-то пошло не так»
   */
  public requestError(): void {
    // Тост об ошибке должен показываться только внутри админки
    if (!this.router.stateService.includes('app') && !this.router.stateService.includes('account')) {
      return;
    }

    // Тост об ошибке должен показываться, когда нет тоста об отсутствии интернет-соединения
    if (this.toastService.isOpen(TOAST_NAME.OFFLINE_CONVERSATIONS)) {
      return;
    }

    const toastText: string = this.translocoService.translate('systemToastService.toastr.somethingWentWrong');
    const toastOptions: ToastOptions = {
      delay: SOMETHING_WENT_WRONG_TOAST_DELAY,
      name: TOAST_NAME.SOMETHING_WENT_WRONG,
    };
    // TODO В AngularJS время жизни этого тоста продлялось, если тост уже показывается. В ng-bootstrap такого метода нет.
    //  Нужно что-то придумать, иначе при каждом вызове метода будет плодиться тост
    this.toastService.danger(toastText, '', toastOptions);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'cq-ajs-display-safehtml',
  templateUrl: './ajs-display-safehtml.component.html',
  styleUrls: ['./ajs-display-safehtml.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AjsDisplaySafehtmlComponent {
  @Input({ required: true })
  safeHtml!: SafeHtml;
}

/**
 * Директива для создания графиков ChartJs
 */
(function () {
  'use strict';

  require('./chart.controller');

  angular.module('myApp.directives.chart').directive('cqChart', cqChart);

  function cqChart() {
    return {
      restrict: 'A',
      require: 'cqChart',
      scope: {
        instance: '=',
        data: '=',
        legend: '@',
      },
      controller: 'CqChartController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      scope.$watch('vm.data', function (newValue) {
        if (angular.isDefined(newValue)) {
          controller.createChart();
        }
      });
    }
  }
})();

<div class="btn-group">
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="copyBlock()"
  >
    <i class="btn-icon cqi-sm cqi-copy"></i>
  </button>
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="deleteBlock()"
  >
    <i class="btn-icon cqi-sm cqi-trash"></i>
  </button>
</div>

import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { StateService } from '@uirouter/core';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { BillingHttpService } from '@http/billing/http/billing-http.service';
import { CloudPaymentsService } from '@panel/app/pages/subscription/general/services/cloud-payments/cloud-payments.service';
import { StripeEditCardModalComponent } from '@panel/app/pages/subscription/general/stripe-edit-card-modal/stripe-edit-card-modal.component';
import { STRIPE_EDIT_CARD_MODAL_DATA_TOKEN } from '@panel/app/pages/subscription/general/stripe-edit-card-modal/stripe-edit-card-modal.token';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import {
  STRIPE_PAY_MODAL_DATA,
  StripePayModalComponent,
} from '@panel/app/pages/subscription/modals/stripe-pay-modal/stripe-pay-modal.component';
import { PlanTotalPrices } from '@panel/app/pages/subscription/pricing/pricing.service';
import { ModalHelperService } from '@panel/app/services';
import { STRIPE_ADD_CARD_MODAL_OPEN_REASON } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { MONTHS_BY_PERIOD, PLANS_PERIODS } from '@panel/app/services/billing-plan/billing-plan.constants';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';
import { LS_AUTO_MESSAGES_DOWNGRADE, LS_LEAD_BOTS_DOWNGRADE } from '@panel/app/shared/constants/localstorage.keys';
import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

/** Сервис для работы с платёжными модалками */
@Injectable({
  providedIn: 'root',
})
export class PaymentModalService {
  /** Название проекта */
  projectName: string = environment.projectName;

  constructor(
    private readonly billingHttpService: BillingHttpService,
    private readonly billingInfoModel: BillingInfoModel,
    private readonly billingPlanService: BillingPlanService,
    private readonly cloudPaymentsService: CloudPaymentsService,
    private readonly cookieService: CookieService,
    private readonly l10nHelper: L10nHelperService,
    @Inject(LOCAL_STORAGE)
    private readonly localStorage: Storage,
    private readonly modalHelperService: ModalHelperService,
    private readonly stateService: StateService,
    private readonly subscriptionStore: SubscriptionStore,
    private readonly translocoService: TranslocoService,
    private readonly uibModalService: UibModalService,
  ) {}

  /**
   * Открытие модалки привязки карты для оплаты
   *
   * @param amount Сумма, которую спишем для валидации карты. Приходит с бэкенда.
   * @param code Код, который пришёл с бэкенда
   * @param djangoUserEmail Email django-пользователя
   */
  openAddCardModal(amount: number, code: string, djangoUserEmail: string): void {
    if (this.l10nHelper.isUsCountry()) {
      this.openEditCardStripeModal(STRIPE_ADD_CARD_MODAL_OPEN_REASON.ADD);
    } else {
      this.openEditCardCloudPaymentsModal(amount, code, djangoUserEmail, true);
    }
  }

  /**
   * Открытие модалки изменения карты для оплаты
   *
   * @param amount Сумма, которую спишем для валидации карты. Приходит с бэкенда.
   * @param code Код, который пришёл с бэкенда
   * @param djangoUserEmail Email django-пользователя
   */
  openEditCardModal(amount: number, code: string, djangoUserEmail: string): void {
    if (this.l10nHelper.isUsCountry()) {
      this.openEditCardStripeModal(STRIPE_ADD_CARD_MODAL_OPEN_REASON.EDIT);
    } else {
      this.openEditCardCloudPaymentsModal(amount, code, djangoUserEmail);
    }
  }

  /** Открытие модалки редактирования карты Stripe */
  private openEditCardStripeModal(reason: STRIPE_ADD_CARD_MODAL_OPEN_REASON): void {
    const modal = this.modalHelperService
      .provide(STRIPE_EDIT_CARD_MODAL_DATA_TOKEN, {
        reason,
      })
      .open(StripeEditCardModalComponent);

    modal.result.then(() => this.updateBilling());
  }

  /**
   * Открытие модалки CloudPayments для редактирования платёжной карты
   *
   * @param amount Сумма, которую спишем для валидации карты. Приходит с бэкенда.
   * @param code Код, который пришёл с бэкенда
   * @param djangoUserEmail Email джанго-пользователя
   * @param isNew true — происходит добавление новой карты, false — изменение
   */
  private openEditCardCloudPaymentsModal(
    amount: number,
    code: string,
    djangoUserEmail: string,
    isNew: boolean = false,
  ): void {
    // Заголовок в модальном окне CloudPayments
    let description;
    if (isNew) {
      description = this.translocoService.translate('paymentModalService.addCardModal.title');
    } else {
      description = this.translocoService.translate('paymentModalService.editCardModal.title', {
        projectName: this.projectName,
      });
    }

    const cloudPayments = this.cloudPaymentsService.init();
    this.cloudPaymentsService.auth(
      cloudPayments,
      description,
      amount / 100,
      code,
      djangoUserEmail,
      this.updateBilling.bind(this),
    );
  }

  openModal(
    prices: PlanTotalPrices,
    planId: string,
    paymentRate: PLANS_PERIODS,
    currentApp: App,
    djangoUser: DjangoUser,
  ): void {
    if (this.l10nHelper.isUsCountry()) {
      this.openStripeModal(prices, planId, paymentRate, currentApp);
    } else {
      this.openCloudPaymentsModal(prices, planId, paymentRate, djangoUser, currentApp);
    }
  }

  openCloudPaymentsModal(
    prices: PlanTotalPrices,
    planId: string,
    paymentRate: PLANS_PERIODS,
    djangoUser: DjangoUser,
    currentApp: App,
  ): void {
    // Формируем id тарифа, который отправится на бэк
    const planFullId =
      planId +
      (this.l10nHelper.isUsCountry() ? '-plan-' : '-') +
      prices.expectedVisitors +
      '-' +
      MONTHS_BY_PERIOD[paymentRate] +
      'month';

    firstValueFrom(
      this.billingInfoModel.generateTmpInvoice(currentApp.id, {
        addons: prices.addons.map((addonInfo) => addonInfo.addon),
        planId: planFullId,
        amount: prices.totalDiscountedPriceWithAddons * 100,
      }),
    ).then((response) => {
      const description = this.translocoService.translate('paymentModalService.paymentModal.title', {
        projectName: environment.projectName,
      });
      const amount = response.data.amount / 100;
      const invoiceId = response.data.code;
      const djangoUserEmail = djangoUser.email;

      const cloudPayments = this.cloudPaymentsService.init();
      this.cloudPaymentsService.charge(
        cloudPayments,
        description,
        amount,
        invoiceId,
        djangoUserEmail,
        () => {
          this.redirectToSubscriptionPage();
        },
        (reason: any) => {
          this.removeDowngradeFlagFromLocalStorage();
        },
      );
    });
  }

  openStripeModal(prices: PlanTotalPrices, planId: string, paymentRate: PLANS_PERIODS, currentApp: App): void {
    // Формируем id тарифа, который отправится на бэк
    const planFullId =
      planId +
      (this.l10nHelper.isUsCountry() ? '-plan-' : '-') +
      prices.expectedVisitors +
      '-' +
      MONTHS_BY_PERIOD[paymentRate] +
      'month';
    const chosenDiscount = this.billingPlanService.getCurrentDiscountsByPeriod(
      currentApp,
      this.billingInfoModel.billingInfo,
    )[paymentRate];

    const planName = this.translocoService.translate(`models.billingInfo.billingPlansNames.${planId}`);

    this.modalHelperService
      .provide(STRIPE_PAY_MODAL_DATA, {
        addOns: prices.addons.map((addonInfo) => {
          return {
            id: addonInfo.addon,
            active: true,
          };
        }),
        amount: prices.priceWithoutAddonsPerMonth,
        billingInfo: this.billingInfoModel.billingInfo,
        chosenDiscount: chosenDiscount,
        currentApp: currentApp,
        getPriceWithoutSale: getPriceWithoutSale,
        getPriceWithSale: getPriceWithSale,
        payPeriod: MONTHS_BY_PERIOD[paymentRate],
        planId: planFullId,
        planName: planName,
      })
      .open(StripePayModalComponent)
      .result.then(
        () => {
          this.redirectToSubscriptionPage();
        },
        () => {
          this.removeDowngradeFlagFromLocalStorage();
        },
      );

    /**
     * Получение стоимости тарифа с учетом скидки за выбранный период оплаты
     *
     * @param planPrice Стоимость тарифа
     * @param payPeriod Период оплаты
     */
    function getPriceWithSale(planPrice: number, payPeriod = 1): number {
      const activeAddOnsPrice = prices.addons.reduce(
        (previousValue, currentValue) => previousValue + currentValue.scaledPrice,
        0,
      );

      return planPrice * payPeriod * (1 - chosenDiscount) + activeAddOnsPrice * payPeriod;
    }

    /**
     * Получение стоимости тарифа без учёта скидки за выбранный период оплаты
     *
     * @param planPrice Стоимость тарифа
     * @param payPeriod Период оплаты
     */
    function getPriceWithoutSale(planPrice: number, payPeriod = 1): number {
      const activeAddOnsPrice = prices.addons.reduce(
        (previousValue, currentValue) => previousValue + currentValue.scaledPrice,
        0,
      );

      return planPrice * payPeriod + activeAddOnsPrice * payPeriod;
    }
  }

  /** Редирект на страницу подписки */
  private redirectToSubscriptionPage(): void {
    this.stateService.go('app.content.subscription');
  }

  removeDowngradeFlagFromLocalStorage() {
    if (this.localStorage.getItem(LS_AUTO_MESSAGES_DOWNGRADE)) {
      this.localStorage.removeItem(LS_AUTO_MESSAGES_DOWNGRADE);
    }
    if (this.localStorage.getItem(LS_LEAD_BOTS_DOWNGRADE)) {
      this.localStorage.removeItem(LS_LEAD_BOTS_DOWNGRADE);
    }
  }

  /**
   * Обновление инстанса биллинга в subscriptionStore
   *
   * @private
   */
  private updateBilling(): void {
    firstValueFrom(this.billingHttpService.get()).then((response) => this.subscriptionStore.billing$.next(response));
  }
}

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { FeatureLabelModel } from '@http/feature-label/feature-label.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationExternal } from '@http/integration/interfaces/integration.interfaces';
import { IntegrationService } from '@http/integration/services/integration.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Описание интеграции на странице её создания|редактирования
 */

@Component({
  selector: 'cq-integration-description[currentApp][integrationExternal]',
  templateUrl: './integration-description.component.html',
  styleUrls: ['./integration-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationDescriptionComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Интеграция во внешнем формате */
  @Input()
  integrationExternal!: IntegrationExternal;

  /** Режим редактирования интеграции */
  isEdit!: boolean;

  /** Получение переведённого описания интеграции */
  get translatedIntegrationDescription(): string {
    return this.translocoService.translate(`models.integration.types.${this.integrationExternal.type}.description`, {
      integrationTypeName: this.translatedIntegrationTypeName,
      projectName: environment.projectName,
    });
  }

  /** Получение переведённого названия типа интеграции */
  get translatedIntegrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(this.integrationExternal.type);
  }

  constructor(
    @Inject(UIRouter) private readonly uiRouter: UIRouter,
    @Inject(CarrotquestHelper) private readonly carrotquestHelper: CarrotquestHelper,
    @Inject(FeatureLabelModel) private readonly featureLabelModel: FeatureLabelModel,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.isEdit = !!this.integrationExternal.id;

    // Если пользовать заходит на новую интеграцию первый раз - нужно удалить feature label "NEW"
    if (this.isFirstEnteringOnNewIntegration()) {
      this.removeNewIntegrationStatus();
    }

    if (this.isSimpleIntegration()) {
      this.trackEnteringOnSimpleIntegration();
    } else {
      if (this.isEdit) {
        this.trackEnteringOnIntegration();
      }
    }
  }

  /** Находится ли интеграция в "Beta" режиме */
  isBetaIntegration(): boolean {
    return this.featureLabelModel.isBeta(this.integrationExternal.type);
  }

  /** Первый ли заход на новую интеграцию */
  isFirstEnteringOnNewIntegration(): boolean {
    return this.featureLabelModel.isNew(this.integrationExternal.type);
  }

  /** Отображать ли кнопки с действиями над интеграцией  */
  isShowActionsOnIntegration(): boolean {
    if ([INTEGRATION_TYPES.ZAPIER].includes(this.integrationExternal.type)) {
      return false;
    }

    if (!this.integrationExternal.id) {
      return false;
    }

    return true;
  }

  /**
   * Является ли интеграция простой
   *
   * NOTE:
   *  Простые интеграции не требуют хранения настроек на backend, все настройки хранятся на стороне интеграции
   *  Пример: Alloka
   */
  isSimpleIntegration(): boolean {
    return !!this.uiRouter.globals.params['isSimpleIntegration'];
  }

  /** Удаление feature label'а "NEW" у интеграции */
  removeNewIntegrationStatus() {
    return this.featureLabelModel.remove(this.integrationExternal.type);
  }

  /** Трекинг захода интеграцию */
  trackEnteringOnIntegration() {
    const eventName = `Интеграции - ${this.integrationExternal.type} - переход на интеграцию`;
    const params = {
      'Название интеграции': this.integrationExternal.name,
    };

    this.carrotquestHelper.track(eventName, params);
  }

  /**
   * Трекинг захода на простую интеграцию
   *
   * NOTE:
   *  Простые интеграции не требуют хранения настроек на backend, все настройки хранятся на стороне интеграции
   *  Пример: Alloka
   */
  trackEnteringOnSimpleIntegration() {
    const eventName = `Интеграции - ${this.integrationExternal.type} - переход`;

    this.carrotquestHelper.track(eventName);
  }
}

<ng-container *transloco="let t; read: 'branchActionAssistant'">
  <div
    class="form-group"
    [cqShowError]="actionForm.controls.body"
  >
    <label class="font-weight-normal small">{{ t('actionHeading') }}:</label>
    <ng-select
      [clearable]="false"
      [formControl]="actionForm.controls.body"
      [required]="true"
      placeholder="{{ t('assistantSelect.placeholder') }}"
      [cqShowError]="actionForm.controls.body"
      [classList]="['ng-touched']"
      [items]="allowedAssistants"
    >
      <ng-template
        ng-label-tmp
        ng-option-tmp
        let-assistant="item"
      >
        <ng-container *ngTemplateOutlet="assistantTemplate; context: { $implicit: assistant }"></ng-container>
      </ng-template>
    </ng-select>
    <cq-validation-messages [control]="actionForm.controls.body">
      <cq-validation-message errorName="required">{{ t('assistantSelect.errors.required') }}</cq-validation-message>
    </cq-validation-messages>
  </div>

  <ng-template
    #assistantTemplate
    let-assistant
  >
    <span class="align-middle">
      <i
        class="cqi-sm margin-right-5"
        [ngClass]="[assistantIcon[assistant]]"
      ></i>
      <!--TODO Перевод для ассистентов стоит хранить в одном месте-->
      {{ t('assistants.' + assistant) }}
    </span>
  </ng-template>
</ng-container>

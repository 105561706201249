import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { GoogleAnalyticsIntegration } from '@http/integration/integrations/google-analytics/google-analytics-integration';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-google-analytics',
  templateUrl: './google-analytics.component.html',
  styleUrls: ['./google-analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleAnalyticsComponent implements OnInit {
  activeLang: string;
  integration: GoogleAnalyticsIntegration;
  integrationTypeTranslation!: string;
  projectName = environment.projectName;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly integrationFactory: IntegrationFactory,
    private readonly translocoService: TranslocoService,
  ) {
    this.activeLang = translocoService.getActiveLang();
    this.integration = this.integrationFactory.create(INTEGRATION_TYPES.GOOGLE_ANALYTICS);
  }

  ngOnInit(): void {
    this.integrationTypeTranslation = this.translocoService.translate(
      `models.integration.types.${this.integration.type}.name`,
    );
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BRANCH_NAME_MAX_LENGTH } from '@panel/app/pages/chat-bot/forms/bot-branch.form';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-branch-name-editor',
  templateUrl: './branch-name-editor.component.html',
  styleUrls: ['./branch-name-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchNameEditorComponent {
  @Input()
  isFullscreenEditorEnabled = false;

  @Input()
  formNameControl!: GenericFormControl<string>;

  @Output()
  blockDelete: EventEmitter<void> = new EventEmitter();

  readonly BRANCH_NAME_MAX_LENGTH = BRANCH_NAME_MAX_LENGTH;

  isBranchNameInputFocused = false;
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { InputStrForm } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-str/input-str.type';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

@Component({
  selector: 'cq-input-str',
  templateUrl: './input-str.component.html',
  styleUrls: ['./input-str.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputStrComponent extends AbsCVAFormGroupBasedComponent<InputStrForm> {
  readonly control: FormGroup<InputStrForm> = this.fb.group<InputStrForm>({
    value: this.fb.control(null, { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../shared.module';
import { PromptModalComponent } from './prompt-modal.component';

@NgModule({
  declarations: [PromptModalComponent],
  exports: [PromptModalComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  providers: [NgbActiveModal],
})
export class PromptModalModule {}

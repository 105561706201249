import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { CaseStyleHelper } from 'app/services';

import { App } from '@http/app/app.model';
import { CHAT_BOT_ACTIONS_TYPES, CHAT_BOT_ACTIONS_TYPES_ICONS } from '@http/chat-bot/chat-bot.constants';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { BranchContainerCallbacks } from '@panel/app/pages/chat-bot/content/branch-editor/branch-editor.component';
import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { Properties } from '@http/property/property.model';

@Component({
  selector:
    'cq-branch-action-container[currentApp][nextBranchOptions][action][propertyOptions][callbacks][chatBotType]',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionContainerComponent implements OnInit {
  @Input() action!: BotAction;
  @Input() currentApp!: App;
  @Input() nextBranchOptions!: Branch[];
  @Input() propertyOptions!: Properties;
  @Input() showHeader: boolean = true;
  @Input() boldHeader: boolean = false;
  @Input() index: number = 1;

  @Input()
  chatBotType: CHAT_BOT_TYPE | null = null;

  @Input()
  chatBotId: string | null = null;

  @Input() isTargetAction: boolean = false;
  @Input() callbacks!: BranchContainerCallbacks;

  @Output() branchCreate: EventEmitter<BotAction> = new EventEmitter(); // Callback на создание новой ветки

  ACTION_TYPE = CHAT_BOT_ACTIONS_TYPES;
  CHAT_BOT_ACTIONS_TYPES_ICONS = CHAT_BOT_ACTIONS_TYPES_ICONS;

  constructor(
    public readonly caseStyleHelper: CaseStyleHelper,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
  ) {}

  get showDeleteButtonOnly(): boolean {
    return (
      !this.callbacks.isShowCopyButton(this.action) &&
      !this.callbacks.isShowUpButton(this.action) &&
      !this.callbacks.isShowDownButton(this.action)
    );
  }

  ngOnInit() {
    // Это нам нужно, чтоб обновлять nextBranchLinkId в селекторах, когда связь создается на канвасе
    this.action.nextBranchLinkId.valueChanges.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Получение перевода для экшена
   *
   * @param actionType Тип экшена
   */
  getTranslateForActionType(actionType: CHAT_BOT_ACTIONS_TYPES): string {
    return this.translocoService.translate(
      `models.chatBot.actionsTypes.${this.caseStyleHelper.toCamelCase(actionType)}`,
    );
  }
}

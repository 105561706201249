import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CarrotMessengerCollapsedFrameModule } from '@panel/app/partials/message-preview/previews/popup-chat/elements/carrot-messenger-collapsed-frame/carrot-messenger-collapsed-frame.module';
import { CarrotNotificationFrameModule } from '@panel/app/partials/message-preview/previews/sdk-popup-chat/elements/notification/carrot-notification-frame.module';

import { MessagePreviewSdkPopupChatComponent } from './message-preview-sdk-popup-chat.component';

@NgModule({
  declarations: [MessagePreviewSdkPopupChatComponent],
  exports: [MessagePreviewSdkPopupChatComponent],
  imports: [CommonModule, CarrotMessengerCollapsedFrameModule, CarrotNotificationFrameModule],
})
export class MessagePreviewSdkPopupChatModule {}

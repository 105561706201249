(function () {
  'use strict';

  require('./goal.controller');

  angular.module('myApp.autoMessages').component('cqAutoMessageGoalAjs', cqAutoMessageGoal());

  function cqAutoMessageGoal() {
    return {
      bindings: {
        amocrmIntegrations: '=', // список амо интеграций
        checkValidityForNotification: '=', // колбек для дизейбла свитчеров для передачи данных на шаге "условия отправки"
        currentApp: '=', // текущее приложение
        currentMessage: '=', // Сообщение
        emailNotifications: '=', // Список интеграций нотификации
        emailRecipients: '=', // Получатели email-оповещений
        eventTypes: '=', // Типы событий
        form: '=', //
        hideForCanvasBot: '=?', // Скрывать элементы если компонент используется в чат-боте на канвасе
        isHideForNewOnboarding: '=?', // Флаг для скрывания элементов для нового онбординга
        properties: '=', // Свойства
        teamMembers: '=', // Список операторов
        wizardMoveStep: '&', // Переместиться на шаг
      },
      controller: 'CqAutoMessageGoalController',
      controllerAs: 'vm',
      template: require('./goal.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { Channel } from '@http/channel/channel.model';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-conversation-statistic-channel-select',
  templateUrl: './conversation-statistic-channel-select.component.html',
  styleUrls: ['./conversation-statistic-channel-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationStatisticChannelSelectComponent {
  /** Id канала "Все каналы" */
  readonly ALL_CHANNELS_ID = PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS];

  readonly control = new GenericFormControl<Array<Channel | typeof this.ALL_CHANNELS_ID>>(
    [this.ALL_CHANNELS_ID],
    [Validators.required],
  );

  /** Массив со всеми каналами */
  @Input()
  channels: Channel[] = [];

  /** Массив с выбранными каналами */
  @Input()
  set selectedChannels(channelsList: Channel[]) {
    if (channelsList) {
      this.control.setValue(channelsList, { emitEvent: false });
    }
  }

  /** Событие с изменением списка выбранных каналов */
  @Output()
  selectedChannelsChange = new Subject<Array<Channel | typeof this.ALL_CHANNELS_ID>>();

  constructor() {
    this.control.valueChanges.subscribe((newValue) => {
      this.changeSelectedChannels();
    });
  }

  /** Изменение выбранных каналов*/
  changeSelectedChannels() {
    if (this.control.value.includes(this.ALL_CHANNELS_ID)) {
      this.control.setValue([this.ALL_CHANNELS_ID], { emitEvent: false });
    }
    this.selectedChannelsChange.next(this.control.value);
  }

  /** Блокировать или нет все чекбоксы, кроме "Все каналы" */
  isDisabled(item: Channel) {
    let disabled = false;
    if (item.type === 'allChannels') {
      return disabled;
    }
    if (this.control.value.includes(this.ALL_CHANNELS_ID)) {
      disabled = true;
    }
    return disabled;
  }
}

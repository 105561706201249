import { HashMap, TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Container } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { TextStyleForRender } from '@panel/app/services/canvas/common/render/canvas-render.types';
import { AbsBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.view';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { DelayBlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { AtlasTexture, ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

/** View шага "Задержка" */
export class DelayView extends AbsBlockView<TRIGGER_CHAIN_BLOCK_TYPE.DELAY> {
  constructor(
    data: DelayBlockViewData,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    transloco: TranslocoService,
    renderer: Renderer,
  ) {
    super(data, interactiveBlockPartViewFactory, transloco, renderer);
  }

  /** Рендерит контент блок */
  renderContent(): Container {
    let elementsForRender: Container[] = [];

    let descriptionText: Container | null = null;
    let delay: Container | null = null;
    let delayPassed: Container | null = null;

    descriptionText = this.renderDescriptionText();

    switch (this.data.delayType) {
      case 'date':
      case 'beforePropDate':
        delay = this.renderDelayInteractivePart();
        delayPassed = this.renderDelayPassedInteractivePart();
        break;
      default:
        delay = this.renderDelayInteractivePart();
    }

    [descriptionText, delay, delayPassed].forEach((el) => {
      if (el) {
        elementsForRender.push(el);
      }
    });

    return ElementBuilder.wrapElementsInRectangle(elementsForRender, {
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 10,
      },
    });
  }

  private renderDelayInteractivePart() {
    if (this.interactiveBlockParts.length === 0) {
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.DELAY, this);
    } else {
      this.interactiveBlockParts[0].rerender();
    }

    return this.interactiveBlockParts[0].graphicContainer;
  }

  private renderDelayPassedInteractivePart() {
    if (this.interactiveBlockParts.length === 1) {
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.DELAY_PASSED, this);
    } else {
      this.interactiveBlockParts[1].rerender();
    }

    return this.interactiveBlockParts[1].graphicContainer;
  }

  private renderDescriptionText(): Container {
    let text: string = this.t('description');

    let style: TextStyleForRender = {
      color: HEX_COLOR.GRAY_400,
      wordWrap: true,
      wordWrapWidth: 295,
    };
    return CanvasRenderService.renderText(text, style);
  }

  getTitleIconCode(): string {
    return UNICODE_ICON['cqi-stopwatch'];
  }

  getTitleIconColorIfDefault(): number {
    return HEX_COLOR.PRIMARY_600;
  }

  getTitleIconColorIfSelected(): number {
    return HEX_COLOR.WHITE;
  }

  getTitleIconBackgroundTextureIfDefault(): AtlasTexture {
    return 'circle-background-white-15';
  }

  getTitleIconBackgroundTextureIfSelected(): AtlasTexture {
    return 'circle-background-primary600-15';
  }

  getTitleIconBorderTextureIfDefault(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfSelected(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfIncomingConnectionsInvalid(): AtlasTexture {
    return 'circle-border-danger600-15';
  }

  private t(key: string, params?: HashMap): string {
    return this.transloco.translate(`triggerChainBlock.views.delay.${key}`, params);
  }
}

import html2canvas from 'html2canvas';
import { from, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { environment } from '@environment';
import { TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { PREVIEW_MAX_HEIGHT_PX } from '@panel/app-old/shared/services/message-preview-image/message-preview-image.constants';
import {
  MessagePartPreviewImage,
  MessagePartPreviewImageData,
} from '@panel/app-old/shared/services/message-preview-image/message-preview-image.types';

/**
 * Сервис для создания превью триггерных сообщений
 *
 * В редакторе триггерных сообщений, есть отображение превью. Оно работает через набор компонентов,
 * которые внутри себя загружают iframe с адресом /preview и отображают часть триггерного сообщения.
 * Триггерное сообщение может состоять из сообщения в чат, а может из нескольких блоков блочного popup'а.
 * Всё это ifram'ы со своей частью триггерного сообщения.
 *
 * Этот сервис запускает процесс общения родительского окна и всех iframe с превью. С помощью библиотеки htmlToImage,
 * ifram'ы делают скрины частей триггерного сообщения и посылают их в родительское окно. Родительское окно
 * собирает скрины частей в единое целое и с помощью той же библиотеки, делает полный скрин превью.
 */
export class MessagePreviewImageService {
  previewImage$: Observable<MessagePartPreviewImage>;

  private previewContainerId = 'preview-image-container';
  private previewImageSubj$: Subject<MessagePartPreviewImage> = new Subject();

  //@ngInject
  constructor(private readonly browserDetector: any) {
    this.previewImage$ = this.previewImageSubj$.asObservable();
  }

  private addPartPreviewImageToPreviewContainer(
    img: HTMLImageElement,
    popupSection?: 'left' | 'right' | 'bottom',
  ): void {
    let container = this.getPreviewContainer();

    switch (popupSection) {
      case 'left':
        let leftPopupSection = document.querySelector('.left-preview-section')!;
        leftPopupSection.appendChild(img);
        break;
      case 'right':
        let rightPopupSection = document.querySelector('.right-preview-section')!;
        rightPopupSection.appendChild(img);
        break;
      case 'bottom':
        let bottomPopupSection = document.querySelector('.bottom-preview-section')!;
        bottomPopupSection.appendChild(img);
        break;
      default:
        container.appendChild(img);
    }
  }

  private createPreviewContainer(): void {
    let container = document.createElement('div');

    container.id = this.previewContainerId;

    container.style.border = '1px solid #c7cad1';
    container.style.borderRadius = '4px';
    container.style.padding = '1px';

    container.classList.add('d-flex');
    container.classList.add('overflow-y-hidden');
    container.classList.add('flex-column');
    container.classList.add('margin-between-rows-10');

    container.style.width = TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX + 'px';
    container.style.maxHeight = PREVIEW_MAX_HEIGHT_PX + 'px';

    if (this.isDefaultEmail()) {
      container.classList.add('p-10');
    }

    if (this.isPopup()) {
      let popupBackground = this.getPopupBackground();

      container.classList.add('p-10');

      container.style.backgroundColor = popupBackground.backgroundColor;
      container.style.backgroundImage = popupBackground.backgroundImage;
      container.style.backgroundPosition = popupBackground.backgroundPosition;
      container.style.backgroundSize = popupBackground.backgroundSize;
    }

    if (this.isSectionPopup()) {
      container.classList.remove('margin-between-rows-20');

      let centerPreviewSection = document.createElement('div');
      centerPreviewSection.classList.add('d-flex');
      centerPreviewSection.classList.add('flex-row');

      let leftPreviewSection = document.createElement('div');
      leftPreviewSection.classList.add('left-preview-section');
      leftPreviewSection.classList.add('flex-grow-1');

      let rightPreviewSection = document.createElement('div');
      rightPreviewSection.classList.add('right-preview-section');
      rightPreviewSection.classList.add('flex-grow-1');

      let bottomPreviewSection = document.createElement('div');
      bottomPreviewSection.classList.add('bottom-preview-section');
      bottomPreviewSection.classList.add('w-100');

      centerPreviewSection.appendChild(leftPreviewSection);
      centerPreviewSection.appendChild(rightPreviewSection);

      container.appendChild(centerPreviewSection);
      container.appendChild(bottomPreviewSection);
    }

    document.body.appendChild(container);
  }

  private generateFullPreviewImage(): any {
    setTimeout(() => {
      from(
        html2canvas(this.getPreviewContainer(), {
          allowTaint: true,
          useCORS: true,
        }),
      )
        .pipe(
          take(1),
          filter((canvas) => !!canvas.toDataURL()),
        )
        .subscribe((canvas: HTMLCanvasElement) => {
          this.finalizeGenerateFullPreviewImage(canvas.toDataURL());
        });
    }, 0);
  }

  private getPopupBackground(): any {
    let popup: HTMLElement | null = document.querySelector('.popup-body');

    return {
      backgroundColor: popup?.style.backgroundColor,
      backgroundPosition: popup?.style.backgroundPosition,
      backgroundImage: popup?.style.backgroundImage === 'url("")' ? null : popup?.style.backgroundImage,
      backgroundRepeat: popup?.style.backgroundRepeat,
      backgroundSize: popup?.style.backgroundSize,
    };
  }

  private getPreviewContainer(): HTMLElement {
    let container = document.getElementById(this.previewContainerId);

    if (!container) {
      throw new Error('Preview container is not found');
    }

    return container;
  }

  private finalizeGenerateFullPreviewImage(base64ImageData: string): void {
    this.previewImageSubj$.next({
      base64ImageData,
      heightPx: this.getPreviewContainer().clientHeight,
    });

    this.removePreviewContainer();
  }

  private hasAllPartFullPreview(): boolean {
    let previewIframes = document.querySelectorAll(`iframe[src="${environment.previewFrameUrl}"]`);
    let previewImages = document.querySelectorAll(`#${this.previewContainerId} img`);

    return previewIframes.length === previewImages.length;
  }

  private handleReadyPartPreviewImage(data: MessagePartPreviewImageData): void {
    const { base64ImageData, iframeIndex, popupSection } = data;

    const img = document.createElement('img');
    img.src = base64ImageData;
    img.classList.add('d-block');
    img.setAttribute('data-iframe-index', `${iframeIndex}`);

    if (this.isSectionPopup()) {
      img.classList.add('w-100');
      img.setAttribute('data-popup-section', `${popupSection}`);

      this.addPartPreviewImageToPreviewContainer(img, popupSection);
    } else {
      this.addPartPreviewImageToPreviewContainer(img);
    }

    if (this.hasAllPartFullPreview()) {
      this.sortPartPreviewAfterAsyncCreation();

      this.generateFullPreviewImage();
    }
  }

  private isDefaultEmail(): boolean {
    let defaultEmailBody = document.querySelector('.email-default-iframe-container');

    if (!defaultEmailBody) {
      return false;
    }

    return true;
  }

  private isPopup(): boolean {
    let popupBody = document.querySelector('.popup-body');

    if (!popupBody) {
      return false;
    }

    return true;
  }

  private isSectionPopup(): boolean {
    let popupSection = document.querySelector('.popup-sections');

    if (!popupSection) {
      return false;
    }

    return popupSection.children.length > 1;
  }

  private removePreviewContainer(): void {
    let container = this.getPreviewContainer();

    container.remove();
  }

  private sendCommandToPreviewIframe(iframe: any, commandData: any): void {
    if (!iframe.contentWindow) {
      throw new Error('Iframe content is not found');
    }

    iframe.contentWindow.postMessage(commandData, '*');
  }

  private sendCommandForGeneratePartPreviewImage(): void {
    if (this.isSectionPopup()) {
      let leftSectionIframes = document.querySelectorAll(
        `.popup-sections > div:nth-child(1) iframe[src="${environment.previewFrameUrl}"]`,
      );
      leftSectionIframes.forEach((iframe, index) => {
        this.sendCommandToPreviewIframe(iframe, {
          command: 'generatePreviewImage',
          iframeIndex: index,
          popupSection: 'left',
        });
      });

      let rightSectionIframes = document.querySelectorAll(
        `.popup-sections > div:nth-child(2) iframe[src="${environment.previewFrameUrl}"]`,
      );
      rightSectionIframes.forEach((iframe, index) => {
        this.sendCommandToPreviewIframe(iframe, {
          command: 'generatePreviewImage',
          iframeIndex: index,
          popupSection: 'right',
        });
      });

      let bottomSectionIframes = document.querySelectorAll(
        `.bottom-popup-section iframe[src="${environment.previewFrameUrl}"]`,
      );
      bottomSectionIframes.forEach((iframe, index) => {
        this.sendCommandToPreviewIframe(iframe, {
          command: 'generatePreviewImage',
          iframeIndex: index,
          popupSection: 'bottom',
        });
      });
    } else {
      let previewIframes = document.querySelectorAll(`iframe[src="${environment.previewFrameUrl}"]`);
      previewIframes.forEach((iframe, index) => {
        this.sendCommandToPreviewIframe(iframe, {
          command: 'generatePreviewImage',
          iframeIndex: index,
        });
      });
    }
  }

  private sortPartPreviewAfterAsyncCreation(): void {
    let containers: any;

    if (this.isSectionPopup()) {
      containers = [
        document.querySelector('.left-preview-section'),
        document.querySelector('.right-preview-section'),
        document.querySelector('.bottom-preview-section'),
      ];
    } else {
      containers = [this.getPreviewContainer()];
    }

    containers
      .filter((container: HTMLElement) => !!container)
      .forEach((container: HTMLElement) => {
        [...Array.prototype.slice.call(container.children)]
          .sort((a, b) => (+a.getAttribute('data-iframe-index') > +b.getAttribute('data-iframe-index') ? 1 : -1))
          .forEach((node) => container.appendChild(node));
      });
  }

  hasIframes(): boolean {
    return document.querySelectorAll(`iframe[src="${environment.previewFrameUrl}"]`).length !== 0;
  }

  startGeneratePreviewImage(): void {
    this.createPreviewContainer();

    this.sendCommandForGeneratePartPreviewImage();
    window.onmessage = (event) => {
      if (event.data.command == 'partPreviewImageReady') {
        this.handleReadyPartPreviewImage(event.data);
      }
    };
  }
}

import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { ProjectPartial } from '@environments/types';
import { WordpressNews } from '@http/news-helper/news-helper.types';

/**
 * Ключ LS, который содержит дату последнего клика на кнопку "Что нового"
 * @type {string}
 */
let LS_LAST_READ_WHATS_NEW = 'carrotquest_last_read_whats_new';

const wordpressAPI: Record<ProjectPartial['country'], string> = {
  RU: 'https://www.carrotquest.io/updates.json',
  US: 'https://www.dashly.io/updates.json',
};

/**
 * Хелпер для работы
 */
@Injectable({ providedIn: 'root' })
export class NewsHelperService {
  private newestNewsId: number | null = null;

  /**
   * Получение последних "важных" новостей из вордпресса
   */
  getUnreadNews(): Promise<WordpressNews[]> {
    return new Promise<WordpressNews[]>((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', wordpressAPI[environment.country]);
      xhr.onload = () => {
        if (xhr.status !== 200) {
          reject();
        }
        const newsData = JSON.parse(xhr.response);
        this.newestNewsId = newsData[0].id;
        resolve(newsData);
      };
      xhr.onerror = reject;
      xhr.send();
    });
  }

  getUnreadNewsCounterByUser(djangoUser: any): Promise<number> {
    return this.getUnreadNews().then((news) => {
      if (news.length === 0) {
        return 0;
      }

      const lastReadJson = localStorage.getItem(LS_LAST_READ_WHATS_NEW) || '{}';
      let lastClickedNewsId = JSON.parse(lastReadJson)[djangoUser.id] || null;

      if (lastClickedNewsId !== null) {
        const indexOfLastReadNews = news.findIndex((item) => item.id === lastClickedNewsId);
        return indexOfLastReadNews > -1 ? indexOfLastReadNews : 0;
      } else {
        return news.length;
      }
    });
  }

  markNewsAsReadForUser(djangoUser: any) {
    const lastReadJson = localStorage.getItem(LS_LAST_READ_WHATS_NEW) || '{}';
    const lastReadNews = JSON.parse(lastReadJson);
    lastReadNews[djangoUser.id] = this.newestNewsId;
    localStorage.setItem(LS_LAST_READ_WHATS_NEW, JSON.stringify(lastReadNews));
  }
}

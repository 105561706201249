<ng-container *transloco="let t; read: 'inputStrContainsComponent'">
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="control.controls.value"
  >
    <input
      class="form-control"
      [formControl]="control.controls.value"
      autofocus
      required
      type="text"
    />
    <cq-validation-messages [control]="control.controls.value">
      <cq-validation-message errorName="maxlength">{{
        t('error.maxlength', { value: MAX_LENGTH })
      }}</cq-validation-message>
      <cq-validation-message errorName="minlength">{{
        t('error.minlength', { value: MIN_LENGTH })
      }}</cq-validation-message>
      <cq-validation-message errorName="required">{{ t('error.required') }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

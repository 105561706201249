import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { AddAppModalStepFirstModule } from '@panel/app/pages/account/shared/add-app-modal/add-app-modal-step-first/add-app-modal-step-first.module';
import { AddAppModalStepSecondModule } from '@panel/app/pages/account/shared/add-app-modal/add-app-modal-step-second/add-app-modal-step-second.module';
import { WizardModule } from '@panel/app/partials/wizard/wizard.module';
import { WizardItemModule } from '@panel/app/partials/wizard/wizard-item/wizard-item.module';

import { AddAppModalComponent } from './add-app-modal.component';

@NgModule({
  declarations: [AddAppModalComponent],
  exports: [AddAppModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    WizardModule,
    WizardItemModule,
    AddAppModalStepFirstModule,
    AddAppModalStepSecondModule,
  ],
})
export class AddAppModalModule {}

import { ui } from 'angular';

/**
 * Сервис для открытия модальных окон angularjs
 * Позволяет использовать открывать их в angular2
 */
export class UibModalService {
  //@ngInject
  constructor(
    private readonly $uibModal: ui.bootstrap.IModalService,
    private readonly $uibModalStack: ui.bootstrap.IModalStackService,
  ) {}

  public dismissAll(reason?: any) {
    return this.$uibModalStack.dismissAll(reason);
  }

  public open(options: ui.bootstrap.IModalSettings) {
    return this.$uibModal.open(options);
  }
}

<ng-container *transloco="let t">
  <div
    class="row"
    *ngIf="chatBotsList && chatBotsList.length > 0"
  >
    <div class="col-5">
      <strong>{{ t('chatBotsListReadonly.heading') }}</strong>
    </div>
    <div class="col-7 margin-between-rows-10">
      <div *ngFor="let chatBot of chatBotsList">
        <span class="font-weight-bold">{{ chatBot.name }}</span>
        <div>
          {{ t('chatBotsListReadonly.blocks', { blocks: getBranchesWithEmailNotificationAction(chatBot).length }) }}
          {{ getFormattedChatBotBranchesNames(chatBot) }}
        </div>
      </div>
    </div>
  </div>
</ng-container>

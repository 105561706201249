import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

/**
 * Сервис для работы с окном для OAuth авторизаций
 */

@Injectable({
  providedIn: 'root',
})
export class OAuthWindowService {
  constructor(@Inject(WINDOW) readonly windowRef: Window) {}

  /** Получение параметров window для OAuth авторизации */
  getOAuthWindowOptions(): string {
    const wLeft = this.windowRef.screenLeft ? this.windowRef.screenLeft : this.windowRef.screenX;
    const wTop = this.windowRef.screenTop ? this.windowRef.screenTop : this.windowRef.screenY;

    const width = 350;
    const height = 250;
    const left = wLeft + this.windowRef.innerWidth / 2 - width / 2;
    const top = wTop + this.windowRef.innerHeight / 2 - height / 2;

    return ['toolbar=0', 'menubar=0', `height=${height}`, `width=${width}`, `top=${top}`, `left=${left}`].join(',');
  }

  /** Открытие window для OAuth авторизации */
  open(): Window {
    let windowConfig = this.getOAuthWindowOptions();

    /* Чтобы всплывающее окно корректно открылось - его вначале надо открыть без url,
       а затем, когда url получен с сервера - заменить location.href на этот url */
    return this.windowRef.open('', '_blank', windowConfig)!;
  }

  /**
   * Установка OAuth url'а в адресную строку окна
   *
   * @param window - Окно
   * @param oauthUrl - OAuth url
   */
  setOAuthUrlToWindow(window: Window, oauthUrl: string): void {
    window.location.href = oauthUrl;
  }
}

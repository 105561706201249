<ng-container *transloco="let t; read: 'tagListNotFoundComponent'">
  <div class="text-center">
    <h4>{{ t('title') }}</h4>

    <div class="mb-20">{{ t('description') }}</div>

    <button
      class="btn btn-primary"
      (click)="onResetSearch()"
      type="button"
    >
      {{ t('buttons.search.text') }}
    </button>
  </div>
</ng-container>

import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '../../../../app/http/channel/channel.constants';
import { firstValueFrom } from 'rxjs';
import { SENDING_STATUSES, SENDING_TYPES } from '../../../../app/http/message/message.constants';
import {
  EMAIL_TYPES,
  MESSAGE_PART_TYPES,
  POPUP_CHAT_TYPES,
  TYPE_OS_PREVIEW,
} from '../../../../app/http/message-part/message-part.constants';
import { REPLY_TYPES } from '../../../../app/http/conversation-part/conversation-part.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

/**
 * Контроллер для статистики ручных сообщений
 */
(function () {
  'use strict';

  angular
    .module('myApp.manualMessages')
    .controller('CqManualMessageStatisticsController', CqManualMessageStatisticsController);

  function CqManualMessageStatisticsController(
    $filter,
    $interval,
    $scope,
    $translate,
    $uibModal,
    moment,
    toastr,
    MANUAL_MESSAGE_STATISTICS_TABS,
    carrotquestHelper,
    caseStyleHelper,
    featureModel,
    messageModel,
    messageTemplateModel,
    planFeatureAccessService,
    tagModel,
    templateDirectoryModel,
    timeUnitService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, vm.currentApp);
      vm.accessToUsersTags = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, vm.currentApp);

      vm.answers = []; // список ответов пользователя
      vm.answersPaginator = null; // пагинация ответов пользователей
      vm.cancelSending = cancelSending;
      vm.caseStyleHelper = caseStyleHelper;
      vm.currentSendingType = SENDING_TYPES.SENDED; // выбранный тип действий получателей сообщения
      vm.currentTab = MANUAL_MESSAGE_STATISTICS_TABS.GENERAL; // выбранная вкладка
      vm.EMAIL_TYPES = EMAIL_TYPES;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.getAnswers = getAnswers;
      vm.getSendings = getSendings;
      vm.isAnswersRequestPerformed = false; // флаг выполнения запроса на получение ответов пользователей
      vm.isSendingsRequestPerformed = false; // флаг выполнения запроса на получение пользователей в текущий момент времени
      vm.isMessageOutdated = isMessageOutdated;
      vm.isTabDisabled = isTabDisabled;
      vm.MANUAL_MESSAGE_STATISTICS_TABS = MANUAL_MESSAGE_STATISTICS_TABS;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      // HACK: у ручных сообщений с бэк-энда возвращается вариант И контрольная группа среди вариантов сообщения, а фронт-энд рассчитан на один вариант, без контрольной группы. Вариант сообщения, не являющийся контрольной группой будет в конце массива
      vm.messagePart = vm.currentMessage.activeTestGroup.parts[vm.currentMessage.activeTestGroup.parts.length - 1];
      vm.openAddTag = openAddTag;
      vm.openCreateTemplateModal = openCreateTemplateModal;
      vm.openUser = openUser;
      vm.paginationParamsForReplies = {
        paginateCount: 2,
        paginateDirection: REPLY_TYPES.AFTER,
      }; // Параметры пагинации для запроса получения реплик диалогов с ответами на рассылку
      vm.pauseSending = pauseSending;
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.resumeSending = resumeSending;
      vm.sendings = []; // список получателей сообщения
      (vm.sendingsForceReload = false), // флаг принудительной перезагрузки таблицы пользователей
        (vm.sendingsPaginator = null); // пагинация получателей
      vm.SENDING_STATUSES = SENDING_STATUSES;
      vm.SENDING_TYPES = SENDING_TYPES;
      vm.showSendings = showSendings;
      vm.timeUnitService = timeUnitService;
      vm.trackClickAddTag = trackClickAddTag;
      vm.trackClickCancelSending = trackClickCancelSending;
      vm.trackClickPauseSending = trackClickPauseSending;
      vm.trackClickResumeSending = trackClickResumeSending;
      vm.trackEnterInTab = trackEnterInTab;
      vm.TYPE_OS_PREVIEW = TYPE_OS_PREVIEW;
      vm.writeAboutAnError = writeAboutAnError;

      getAnswers(vm.currentMessage.id);

      $scope.$watch('vm.currentSendingType', function (newValue, oldValue) {
        // изменение любого параметра влечёт за собой полную перезагрузку страницы пользователей
        vm.sendingsForceReload = true;

        getSendings(vm.currentMessage.id, newValue);
      });
    }

    /**
     * Отменить рассылку (можно при статусах SENDING_STATUSES.ACTIVE, SENDING_STATUSES.PAUSED)
     */
    function cancelSending() {
      var cancelSendingModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('manualMessages.statistics.cancelSendingModal.heading'),
              body: $translate.instant('manualMessages.statistics.cancelSendingModal.body'),
              confirmButtonText: $translate.instant('manualMessages.statistics.cancelSendingModal.confirmButtonText'),
              cancelButtonText: $translate.instant('general.close'),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      cancelSendingModal.result.then(cancelSending);

      function cancelSending() {
        firstValueFrom(messageModel.cancelSending(vm.currentMessage.id)).then(cancelSendingSuccess);

        function cancelSendingSuccess() {
          vm.currentMessage.status = SENDING_STATUSES.CANCELED;
          toastr.success($translate.instant('manualMessages.statistics.toasts.sendingCanceled'));
        }
      }
    }

    /**
     * Получение ответов получателей на сообщение
     *
     * @param {String} messageId ID сообщения
     * @returns {Promise}
     */
    function getAnswers(messageId) {
      vm.isAnswersRequestPerformed = true;

      return firstValueFrom(messageModel.getConversations(messageId, SENDING_TYPES.REPLIED, vm.answersPaginator))
        .then(getConversationsSuccess)
        .finally(getConversationsFinally);

      function getConversationsSuccess(data) {
        for (let i = 0; i < data.conversations.length; i++) {
          const conversation = data.conversations[i];

          conversation.assignee =
            conversation.assignee && $filter('filter')(vm.teamMembers, { id: conversation.assignee.id }, true)[0];
          conversation.channel = conversation.channel
            ? $filter('filter')(vm.channels, { id: conversation.channel.id }, true)[0]
            : $filter('filter')(vm.channels, { id: PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL] }, true)[0];
        }

        vm.answers.push.apply(vm.answers, data.conversations);
        vm.answersPaginator = data.paginatorParams;

        return data;
      }

      function getConversationsFinally() {
        vm.isAnswersRequestPerformed = false;
      }
    }

    /**
     * Получение пользователей
     *
     * @param {String} messageId ID сообщения
     * @param {SENDING_TYPES} sendingType Тип действия получателя сообщения
     * @return {Promise}
     */
    function getSendings(messageId, sendingType) {
      var startDate = moment('2010-01-01T00:00:00').utc().add(1, 'days'); // дата начала - 02.01.1970 (сервер не корректно обрабатывает 01.01.1970 и с 02.01.1970 и т.д.)
      var endDate = moment(); // дата конца - сегодня

      vm.isSendingsRequestPerformed = true;

      // если идёт перезагрукзка таблицы пользователей - всё обнуляем
      if (vm.sendingsForceReload) {
        vm.sendings = [];
        vm.sendingsPaginator = null;
        vm.sendingsForceReload = false;
      }

      return firstValueFrom(
        messageModel.getMessageSendings(messageId, startDate, endDate, sendingType, vm.sendingsPaginator),
      )
        .then(getSendingsSuccess)
        .finally(getSendingsFinally);

      function getSendingsSuccess(data) {
        // защита от быстрого кликания пользователем по значениям фильтра
        if (sendingType == vm.currentSendingType) {
          vm.sendings.push.apply(vm.sendings, data.sendings);
          vm.sendingsPaginator = data.paginatorParams;
        }

        return data;
      }

      function getSendingsFinally() {
        vm.isSendingsRequestPerformed = false;
      }
    }

    /**
     * Вкладку нужно заблокировать, если это вкладка «Получатели» у рассылки, которая была отправлена 1.5 года назад.
     * Данные о получателях такой рассылки были нами удалены.
     *
     * @param tab
     */
    function isTabDisabled(tab) {
      return tab === MANUAL_MESSAGE_STATISTICS_TABS.SENDINGS && isMessageOutdated();
    }

    /**
     * Является ли рассылка устаревшей (той, у которой мы удалили данные)
     *
     * @return {boolean}
     */
    function isMessageOutdated() {
      return moment().diff(vm.currentMessage.lastSent, 'months') >= 18;
    }

    /**
     * Открытие модалки добавления тегов получателям сообщения по их действиям
     */
    function openAddTag(messageId, sendingType) {
      var addTagModal = $uibModal.open({
        controller: 'AddTagModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              body: $translate.instant('manualMessages.statistics.addTagModal.body'),
            };
          },
          tagList: function () {
            return firstValueFrom(tagModel.getList(vm.currentApp.id, false));
          },
        },
        templateUrl: 'js/shared/modals/add-tag/add-tag.html',
      });

      addTagModal.result.then(function (tag) {
        firstValueFrom(tagModel.setBySendingType(tag.name, messageId, sendingType)).then(setBySendingTypeSuccess);

        function setBySendingTypeSuccess() {
          trackAddTag();
          toastr.success($translate.instant('manualMessages.statistics.toasts.tagAdded'));
        }
      });
    }

    /**
     * Открытие модалки создания шаблона
     */
    function openCreateTemplateModal() {
      var createTemplateModal = $uibModal.open({
        component: 'cqSaveMessagePartAsTemplateModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          directories: firstValueFrom(templateDirectoryModel.getList(vm.currentApp.id, true, true)),
          messagePart: angular.bind(null, angular.identity, vm.messagePart),
        },
      });
      createTemplateModal.result.then(createTemplate);

      function createTemplate(template) {
        trackSaveAsTemplate(template.name);
        toastr.success($translate.instant('manualMessages.statistics.toasts.templateCreated'));
      }
    }

    /**
     * Открытие модалки с пользователем
     *
     * @param {String} userId ID пользователя
     */
    function openUser(userId) {
      $uibModal.open({
        component: 'cqUserCardModal',
        resolve: {
          modalWindowParams: () => {
            return {
              billingInfo: vm.billingInfo,
              currentApp: vm.currentApp,
              djangoUser: vm.djangoUser,
              onRedirectConversationClick: true,
              userId: userId,
              telegramIntegrations: vm.telegramIntegrations,
            };
          },
        },
        size: 'lg',
        windowClass: 'user-card-modal',
      });
    }

    /**
     * Остановить рассылку
     */
    function pauseSending() {
      var pauseSendingModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('manualMessages.statistics.pauseSendingModal.heading'),
              body: $translate.instant('manualMessages.statistics.pauseSendingModal.body'),
              confirmButtonText: $translate.instant('manualMessages.statistics.pauseSendingModal.confirmButtonText'),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      pauseSendingModal.result.then(pauseSending);

      function pauseSending() {
        firstValueFrom(messageModel.pauseSending(vm.currentMessage.id)).then(pauseSendingSuccess);

        function pauseSendingSuccess() {
          vm.currentMessage.status = SENDING_STATUSES.PAUSED;
          toastr.success($translate.instant('manualMessages.statistics.toasts.sendingPaused'));
        }
      }
    }

    /**
     * Возобновить рассылку (можно только при статусе SENDING_STATUSES.PAUSED)
     */
    function resumeSending() {
      var resumeSendingModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('manualMessages.statistics.resumeSendingModal.heading'),
              body: $translate.instant('manualMessages.statistics.resumeSendingModal.body'),
              confirmButtonText: $translate.instant('manualMessages.statistics.resumeSendingModal.confirmButtonText'),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      resumeSendingModal.result.then(resumeSending);

      function resumeSending() {
        firstValueFrom(messageModel.resumeSending(vm.currentMessage.id)).then(resumeSendingSuccess);

        function resumeSendingSuccess() {
          vm.currentMessage.status = SENDING_STATUSES.ACTIVE;
          toastr.success($translate.instant('manualMessages.statistics.toasts.sendingResumed'));
        }
      }
    }

    /**
     * Функция перехода на вкладку с пользователями
     *
     * @param {SENDING_TYPES} sendingType Тип действия получателя сообщения
     */
    function showSendings(sendingType) {
      trackClickShowSendings(sendingType);

      vm.currentSendingType = sendingType;

      vm.currentTab = MANUAL_MESSAGE_STATISTICS_TABS.SENDINGS;
    }

    /**
     * Трек добавления тега
     */
    function trackAddTag() {
      carrotquestHelper.track('Статистика рассылок - добавил тег');
    }

    /**
     * Трек клика на "Добавить тег"
     */
    function trackClickAddTag() {
      carrotquestHelper.track('Статистика рассылок - клик на "Добавить тег"');
    }

    /**
     * Трек клика на "Отменить рассылку"
     */
    function trackClickCancelSending() {
      carrotquestHelper.track('Статистика рассылок - клик на "Отменить рассылку"', {
        Название: vm.messagePart.customName,
      });
    }

    /**
     * Трек клика на "Приостановить рассылку"
     */
    function trackClickPauseSending() {
      carrotquestHelper.track('Статистика рассылок - клик на "Приостановить рассылку"', {
        Название: vm.messagePart.customName,
      });
    }

    /**
     * Трек клика на "Возобновить рассылку"
     */
    function trackClickResumeSending() {
      carrotquestHelper.track('Статистика рассылок - клик на "Возобновить рассылку"', {
        Название: vm.messagePart.customName,
      });
    }

    /**
     * Трек клика на кнопку "Посмотреть"
     *
     * @param {SENDING_TYPES} sendingType Действие получателя сообщения
     */
    function trackClickShowSendings(sendingType) {
      // todo: пока что закостылял, когда будет многоязычность - надо будет думать что отправлять в параметре 'Тип получателей' и где хранить возможные значения этого параметра
      var sendingTypeDescriptions = {};

      sendingTypeDescriptions[SENDING_TYPES.BOUNCED] = 'Bounced';
      sendingTypeDescriptions[SENDING_TYPES.CLICKED] = 'Перешли по ссылке';
      sendingTypeDescriptions[SENDING_TYPES.GOAL_COMPLETED] = 'Достигнуто целей';
      sendingTypeDescriptions[SENDING_TYPES.READ] = 'Прочитали';
      sendingTypeDescriptions[SENDING_TYPES.REPLIED] = 'Ответили';
      sendingTypeDescriptions[SENDING_TYPES.SENDED] = 'Отправлено';
      sendingTypeDescriptions[SENDING_TYPES.SPAM] = 'Пожаловались на спам';
      sendingTypeDescriptions[SENDING_TYPES.UNSUBSCRIBED] = 'Отписались от рассылки';

      carrotquestHelper.track('Автосообщения - клик на "Посмотреть"', {
        'Тип получателей': sendingTypeDescriptions[sendingType],
      });
    }

    /**
     * Трек захода на вкладку
     *
     * @param {MANUAL_MESSAGE_STATISTICS_TABS} tab Вкладка, на которую зашёл пользователь
     */
    function trackEnterInTab(tab) {
      var tabDescriptions = {};

      tabDescriptions[MANUAL_MESSAGE_STATISTICS_TABS.ANSWERS] = 'Ответы';
      tabDescriptions[MANUAL_MESSAGE_STATISTICS_TABS.GENERAL] = 'Общая статистика';
      tabDescriptions[MANUAL_MESSAGE_STATISTICS_TABS.SENDINGS] = 'Пользователи';

      carrotquestHelper.track('Статистика рассылок - зашёл на "' + tabDescriptions[tab] + '"', {
        Название: vm.messagePart.customName,
      });
    }

    /**
     * Трек сохранения сообщения как шаблон
     *
     * @param {String} templateName - Имя шаблона
     */
    function trackSaveAsTemplate(templateName) {
      carrotquestHelper.track('Статистика рассылок - сохранить как шаблон', {
        App: vm.currentApp.name,
        Название: templateName,
        Тип: vm.messagePart.type,
      });
    }

    /**
     * Написать в чат о ошибке
     */
    function writeAboutAnError() {
      var text = $translate.instant('manualMessages.statistics.writeAboutAnError', {
        messageId: vm.currentMessage.id,
        appName: vm.currentApp.name,
        appId: vm.currentApp.id,
      });
      carrotquestHelper.sendChatMessage(text);
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';

import { AceEditorModule } from '@panel/app/partials/ace-editor/ace-editor.module';
import { AceEditorWrapperForAjsComponent } from '@panel/app/partials/ace-editor-wrapper-for-ajs/ace-editor-wrapper-for-ajs.component';

@NgModule({
  declarations: [AceEditorWrapperForAjsComponent],
  exports: [AceEditorWrapperForAjsComponent],
  imports: [CommonModule, TranslocoDirective, ReactiveFormsModule, AceEditorModule],
})
export class AceEditorWrapperForAjsModule {}

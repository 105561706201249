(function () {
  'use strict';

  angular.module('myApp.users', []);

  require('./users.routes');
  require('./users.controller');
  require('./users.html');
  require('./import/leads-import.component');
  require('./import/add-tags-to-import-leads/add-tags-to-import-leads.component');
  require('./import/leads-import-finished/leads-import-finished.component');
  require('./import/leads-import-types/leads-import-types.component');
  require('./import/prepare-for-leads-import/prepare-for-leads-import.component');
  require('./import/preview-import-leads/preview-import-leads.component');
  require('./import/upload-file-with-leads/upload-file-with-leads.component');
})();

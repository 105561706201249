import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { MemberInvitationsNotFoundZeroDataModule } from '@panel/app/pages/admins/invitations-not-found-zero-data/member-invitations-not-found-zero-data.module';

import { MemberInvitationsTableComponent } from './member-invitations-table.component';

@NgModule({
  declarations: [MemberInvitationsTableComponent],
  exports: [MemberInvitationsTableComponent],
  imports: [CommonModule, MemberInvitationsNotFoundZeroDataModule, NgbTooltipModule, TranslocoModule],
})
export class MemberInvitationsTableModule {}

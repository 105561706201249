<div
  class="position-relative"
  [ngClass]="size"
>
  <img
    [ngClass]="{ 'has-status': !!status }"
    [src]="avatar"
    class="avatar"
  />
  <div
    *ngIf="status"
    [ngClass]="statusCssClass"
    class="status"
    data-test="presence-status"
  ></div>
</div>

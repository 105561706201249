import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TagRemoveModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/tag-remove/tag-remove.module';
import { TagTextModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/tag-text/tag-text.module';

import { TagListComponent } from './tag-list.component';

@NgModule({
  declarations: [TagListComponent],
  exports: [TagListComponent],
  imports: [CommonModule, TranslocoModule, TagTextModule, TagRemoveModule],
})
export class TagListModule {}

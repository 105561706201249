import { ChangeDetectionStrategy, Component, Inject, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import {
  CONVERSATION_STATISTICS_SOURCES,
  CONVERSATION_STATISTICS_SOURCES_LIST,
} from '@http/conversation-statistics/conversation-statistics.constants';

@Component({
  selector: 'cq-conversation-statistic-source-select',
  templateUrl: './conversation-statistic-source-select.component.html',
  styleUrls: ['./conversation-statistic-source-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationStatisticSourceSelectComponent {
  readonly control: FormControl<CONVERSATION_STATISTICS_SOURCES[]> = new FormControl(
    [CONVERSATION_STATISTICS_SOURCES.ALL],
    {
      validators: [Validators.required],
      nonNullable: true,
    },
  );

  /** Массив с выбранными источниками */
  @Input()
  set selectedSources(sourcesList: CONVERSATION_STATISTICS_SOURCES[]) {
    if (sourcesList) {
      this.control.setValue(sourcesList, { emitEvent: false });
    }
  }

  /** Событие с изменением списка выбранных источников */
  @Output()
  selectedSourcesChange = new Subject<Array<CONVERSATION_STATISTICS_SOURCES>>();

  constructor(@Inject(CONVERSATION_STATISTICS_SOURCES_LIST) public sources: CONVERSATION_STATISTICS_SOURCES[]) {
    this.control.valueChanges.subscribe(() => {
      this.changeSelectedSources();
    });
  }

  /** Изменение выбранных источников*/
  changeSelectedSources() {
    if (this.control.value.includes(CONVERSATION_STATISTICS_SOURCES.ALL)) {
      this.control.setValue([CONVERSATION_STATISTICS_SOURCES.ALL], { emitEvent: false });
    }
    this.selectedSourcesChange.next(this.control.value);
  }

  /** Блокировать или нет все чекбоксы, кроме "Все источники" */
  isDisabled(item: CONVERSATION_STATISTICS_SOURCES) {
    let disabled = false;
    if (item === CONVERSATION_STATISTICS_SOURCES.ALL) {
      return disabled;
    }
    if (this.control.value.includes(CONVERSATION_STATISTICS_SOURCES.ALL)) {
      disabled = true;
    }
    return disabled;
  }
}

import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { App } from '@http/app/app.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { TeamMember } from '@http/team-member/team-member.types';
import { MemberInvitationsModalParams } from '@http/team-member-invitation/types/member-invitations.types';
import { MemberInvitationModalComponent } from '@panel/app/partials/modals/member-invitation/member-invitation-modal.component';
import { MEMBER_INVITATION_MODAL_OPENING_PLACE } from '@panel/app/partials/modals/member-invitation/member-invitation-modal.constants';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Сервис-хелпер для работы с членами команды
 *
 * NOTE:
 *  Данный сервис является дублёром сервиса-хелпера для работы с членами команды на AngularJS.
 *  При добавлении или изменении функционала необходимо синхронизировать их между собой.
 */

@Injectable({
  providedIn: 'root',
})
export class TeamMemberHelpService {
  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly ngbModal: NgbModal,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Получение описания для прав пользователя
   *
   * @param permission - Права пользователя
   */
  getDescriptionForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.translocoService.translate('models.teamMember.permissions.' + permission + '.description');
  }

  /**
   * Получение класса для иконки обозначающей права пользователя
   *
   * @param permission - Права пользователя
   */
  getIconClassForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    switch (permission) {
      case TEAM_MEMBER_PERMISSIONS.ADMIN:
        return 'cqi-star';
      case TEAM_MEMBER_PERMISSIONS.OPERATOR:
        return 'cqi-headset';
      case TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN:
        return 'cqi-superman-sign';
    }
  }

  /** Получение класса для иконки обозначающей права экспорта */
  getIconClassForPermissionExport(): string {
    return 'cqi-export';
  }

  /** Получение класса для иконки обозначающей права массовой рассылки */
  getIconClassForPermissionSendBulk(): string {
    return 'cqi-envelopes';
  }

  /**
   * Получение названия для прав пользователя
   *
   * @param permission - Права пользователя
   */
  getNameForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.translocoService.translate('models.teamMember.permissions.' + permission + '.name');
  }

  /** Получение названия для права на экспорт */
  getNameForPermissionExport(): string {
    return this.translocoService.translate('models.teamMember.permissions.export.name');
  }

  /** Получение названия для права на массовую рассылку */
  getNameForPermissionSendBulk(): string {
    return this.translocoService.translate('models.teamMember.permissions.sendBulk.name');
  }

  /**
   * Открытие модального окна с приглашениями
   *
   * @param currentTeamMember - Текущий член команды
   */
  openModalInvitations(currentTeamMember: TeamMember): NgbModalRef {
    const modalRef = this.ngbModal.open(MemberInvitationModalComponent, {
      centered: true,
    });

    (modalRef.componentInstance.modalWindowParams as MemberInvitationsModalParams) = {
      currentTeamMember,
    };

    return modalRef;
  }

  /**
   * Показ механики после не удачной отправки приглашений
   *
   * @param error - Название ошибки
   */
  showFailedInvitationsSending(error: string): void {
    if (!error) {
      return;
    }

    let text = '';
    switch (error) {
      case 'DuplicateAdminError':
        text = this.translocoService.translate('models.teamMember.toasts.duplicateAdminError');
        break;
      case 'DuplicateInviteError':
        text = this.translocoService.translate('models.teamMember.toasts.duplicateInviteError');
        break;
      case 'TooManyAdminsError':
        text = this.translocoService.translate('models.teamMember.toasts.tooManyAdminsError');
        break;
    }

    this.toastService.warning(text);
  }

  /** Показ механики после успешной отправки приглашений */
  showSuccessfulInvitationsSending() {
    const text = this.translocoService.translate('models.teamMember.toasts.sendInvitation');
    this.toastService.success(text);
  }

  /**
   * Трек добавления нового члена команды
   *
   * NOTE:
   *  Название события является историческим и не отражает того, когда оно действительно отправляется.
   */
  trackCreatingMember(currentApp: App): void {
    const eventName = 'Добавление членов команды из меню - добавил администратора';
    const param = {
      app: currentApp.name,
    };

    this.carrotquestHelper.track(eventName, param);
  }

  /**
   * Трекинг отправления приглашений
   *
   * @param openingPlace - Место из которого открывается модальное окно
   */
  trackSendingInvitations(openingPlace: MEMBER_INVITATION_MODAL_OPENING_PLACE): void {
    let space: string;
    switch (openingPlace) {
      case MEMBER_INVITATION_MODAL_OPENING_PLACE.NAVIGATION:
        space = 'Меню';
        break;
      case MEMBER_INVITATION_MODAL_OPENING_PLACE.ADMINISTRATORS:
        space = 'Управление командой';
    }

    const eventName = `Приглашение членов команды - из ${space}`;

    this.carrotquestHelper.track(eventName);
  }
}

(function () {
  'use strict';

  require('./edit-user-property.controller');

  angular.module('myApp.modals.editUserProperty').component('cqEditUserPropertyModal', cqEditUserPropertyModal());

  function cqEditUserPropertyModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp — Текущее приложение
        // propertyName — Название редактируемого свойства
        // propertyValue — Значение редактируемого свойства
        // userId — ID пользователя CQ
      },
      controller: 'CqEditUserPropertyModalController',
      controllerAs: 'vm',
      template: require('./edit-user-property.html'),
    };
  }
})();

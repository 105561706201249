import { QuestionsComponent } from '../../../app/pages/questions/questions.component';

(function () {
  'use strict';

  angular.module('myApp.questions').config(configUiRouter).config(configStates).run(run);

  /**
   * Конфигурация uiRouter
   *
   * @param $locationProvider
   * @param $urlServiceProvider
   */
  function configUiRouter($locationProvider, $urlServiceProvider) {
    $locationProvider.html5Mode(true);
    $urlServiceProvider.config.caseInsensitive(true);
    $urlServiceProvider.config.strictMode(false);
  }

  function configStates($stateProvider) {
    $stateProvider.state('questions.questions', {
      url: '',
      bindings: {
        djangoUser: 'djangoUser',
        currentApp: 'currentApp',
        djangoUserTempData: 'djangoUserTempData',
      },
      component: QuestionsComponent,
    });
  }

  function run($state, $timeout, $transitions, $window) {
    $transitions.onFinish({ entering: 'questions' }, onEnterQuestionsState);
    $transitions.onSuccess({ exiting: 'questions' }, onExitQuestionsState);

    /** Переход на состояние ответов на вопросы */
    function onEnterQuestionsState() {
      const viewportMetaTag = $window.document.getElementsByName('viewport')[0];

      // После попадания на это состояние после регистрации через Google, viewport страницы нужно изменить, чтобы
      // адаптировать страницу под мобильные устройства
      $timeout(() => {
        viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1');
      }, 1000);
    }

    /** Выход из состояния ответов на вопросы */
    function onExitQuestionsState() {
      const viewportMetaTag = $window.document.getElementsByName('viewport')[0];

      viewportMetaTag.setAttribute('content', 'width=1024');
    }
  }
})();

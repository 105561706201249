import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ArticlesListModule } from '@panel/app/pages/knowledge-base/shared/components/articles-list/articles-list.module';
import { ArticlesSearchModule } from '@panel/app/pages/knowledge-base/shared/components/articles-search/articles-search.module';
import { CategoryListModule } from '@panel/app/pages/knowledge-base/shared/components/category-list/category-list.module';
import { KnowledgeBaseModule } from '@panel/app/pages/knowledge-base/shared/components/knowledge-base/knowledge-base.module';

import { KnowledgeBaseCategoriesComponent } from './knowledge-base-categories.component';

@NgModule({
  declarations: [KnowledgeBaseCategoriesComponent],
  exports: [KnowledgeBaseCategoriesComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    KnowledgeBaseModule,
    CategoryListModule,
    ArticlesSearchModule,
    ArticlesListModule,
  ],
})
export class KnowledgeBaseCategoriesModule {}

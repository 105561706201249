/**
 * Валидатор строки на наличие в ней ссылки "Отписаться" (например, используется в html-письмах)
 */
import { FEATURES } from '../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.directives.unsubscribe').directive('cqUnsubscribe', cqUnsubscribe);

  function cqUnsubscribe(featureModel) {
    var findUnsubscribeLinkRegexp = /<a[^>]+href="\s*{{\s*unsubscribe_url\s*}}\s*"[\s\S]*?>\s*\S[\s\S]*<\/a>/gm;
    var findCommentsRegexp = /<!--(.|\s)*?-->/gm;

    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      // Не добавляем валидатор, если есть такая возможность
      if (featureModel.hasAccess(FEATURES.CUSTOM_UNSUBSCRIBE_LINK)) {
        return;
      }

      controller.$validators.unsubscribe = unsubscribe;

      /**
       * Поиск в строке ссылки "Отписаться". Если строка не найдена - ошибка валидации
       *
       * @param {String} value Строка, в которой осуществляется поиск
       * @returns {Boolean}
       */
      function unsubscribe(value) {
        if (controller.$isEmpty(value)) {
          return true;
        } else {
          // удаляем все html-комментарии
          var stringForCheckUnsubscribeLink = value.replace(findCommentsRegexp, '');

          return stringForCheckUnsubscribeLink.match(findUnsubscribeLinkRegexp) !== null;
        }
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CarrotquestHelperProvider, L10nHelperProvider } from '@panel/app/ajs-upgraded-providers';

import { QuestionsHeaderComponent } from './questions-header.component';

@NgModule({
  declarations: [QuestionsHeaderComponent],
  exports: [QuestionsHeaderComponent],
  imports: [CommonModule],
  providers: [CarrotquestHelperProvider, L10nHelperProvider],
})
export class QuestionsHeaderModule {}

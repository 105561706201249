import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'cq-articles-search',
  templateUrl: './articles-search.component.html',
  styleUrls: ['./articles-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesSearchComponent {
  /** Включен ли поиск */
  @Input() isSearch!: boolean;
  /** Фраза для поиска */
  _searchPhrase: string = '';
  @Input()
  set searchPhrase(value: string) {
    this._searchPhrase = value;
    this.searchForm.controls.search.setValue(value, { emitEvent: false });
  }
  get searchPhrase(): string {
    return this._searchPhrase;
  }
  @Output() searchPhraseChange = new EventEmitter<string>();
  /** Callback функция сброса поиска */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onResetSearch: EventEmitter<any> = new EventEmitter();
  /** Callback функция поиска */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmitSearch: EventEmitter<any> = new EventEmitter();

  /**
   * Максимальная длинна поисковой фразы
   *
   * @type {Number}
   */
  protected MAX_SEARCH_LENGTH: number = 250;

  /**
   * Минимальная длинна поиска
   *
   * @type {number}
   */
  protected MIN_SEARCH_LENGTH: number = 3;

  searchForm = new FormGroup({
    search: new FormControl('', [
      Validators.minLength(this.MIN_SEARCH_LENGTH),
      Validators.maxLength(this.MAX_SEARCH_LENGTH),
    ]),
  });

  constructor() {
    this.searchForm.valueChanges.subscribe((value) => {
      this.searchPhraseChange.emit(value.search ?? '');
    });
  }
}

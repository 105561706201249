import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NotificationAppSettings } from '@panel/app/pages/account/shared/notification-settings/types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-notification-app-setting[settings][appId]',
  templateUrl: './notification-app-setting.component.html',
  styleUrls: ['./notification-app-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationAppSettingComponent {
  /**
   * ID аппа
   *
   * NOTE нужен для формирования ID input'а
   */
  @Input()
  appId!: string;

  /**
   * Настройка уведомлений аппа
   */
  @Input()
  settings!: NotificationAppSettings;

  /**
   * Дизейбл не персональных настроек(т.е. не "назначены на меня")
   */
  @Input()
  disabledNotPersonalNotifications: boolean = false;

  @Output()
  settingsChange = new EventEmitter<NotificationAppSettings>();

  notificationCheckboxChange() {
    this.settingsChange.emit(this.settings);
  }

  constructor(private readonly carrotquestHelper: CarrotquestHelper) {}

  /**
   * Трек изменения подписки на статистику приложения
   */
  trackChangeStatsNotifications() {
    this.carrotquestHelper.identify({ 'Отписан от статистики приложений': !this.settings.stats.email ? 'Да' : 'Нет' });
    this.carrotquestHelper.track('Аккаунт - клик на "Получать статистику приложения"', {
      app: this.appId,
      value: this.settings.stats.email,
    });
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@environment';

/**
 * Модальное окно завершения регистрации
 */

@Component({
  selector: 'cq-completion-registration-modal',
  templateUrl: './completion-registration-modal.component.html',
  styleUrls: ['./completion-registration-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompletionRegistrationModalComponent {
  /** Инстанс модального окна на AngularJS */
  @Input()
  ajsModalInstance?: any;

  /** Текущий проект */
  projectName: string = environment.projectName;

  constructor(private readonly ngbActiveModal: NgbActiveModal) {}

  /** Закрытие модального окна */
  closeModal(): void {
    this.ajsModalInstance ? this.ajsModalInstance.close() : this.ngbActiveModal.close();
  }
}

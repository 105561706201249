<div class="modal-body">
  <cq-wizard-new
    menuClass="margin-bottom-30"
    [restricted]="true"
  >
    <cq-wizard-item-new
      name="1"
      [heading]="'modals.addApp.body.wizard.step1' | transloco"
      [onExitCallback]="isFormValid(addAppModalStepFirstComponent && addAppModalStepFirstComponent.appTypeForm)"
      (exitCallback)="markAsTouchedForm(addAppModalStepFirstComponent && addAppModalStepFirstComponent.appTypeForm)"
    >
      <cq-add-app-modal-step-first [(appType)]="appType"></cq-add-app-modal-step-first>
    </cq-wizard-item-new>
    <cq-wizard-item-new
      name="2"
      [heading]="'modals.addApp.body.wizard.step2' | transloco"
      [onExitCallback]="isFormValid(addAppModalStepSecondComponent && addAppModalStepSecondComponent.appForm)"
      (exitCallback)="markAsTouchedForm(addAppModalStepSecondComponent && addAppModalStepSecondComponent.appForm)"
    >
      <cq-add-app-modal-step-second [appType]="appType"></cq-add-app-modal-step-second>
    </cq-wizard-item-new>
  </cq-wizard-new>
</div>

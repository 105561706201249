import { POPUP_BLOCK_TYPES } from '../../../../app/services/popup-block/popup-block.constants';

(function () {
  'use strict';

  angular.module('myApp.popupEditor').controller('CqPopupBlockPreviewController', CqPopupBlockPreviewController);

  function CqPopupBlockPreviewController() {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.POPUP_BLOCK_TYPES = POPUP_BLOCK_TYPES;
    }
  }
})();

(function () {
  'use strict';

  require('./auto-event-write-property.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventWriteProperty', cqWriteProperty());

  function cqWriteProperty() {
    return {
      bindings: {
        key: '=', // Выбранное свойство
        userProps: '=', // Свойства пользователя
      },
      controller: 'CqAutoEventWritePropertyController',
      controllerAs: 'vm',
      template: require('./auto-event-write-property.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/** Компонент для работы с оформлением подписки */
@Component({
  selector: 'cq-subscribe-intent[subscribeIntent]',
  templateUrl: './subscribe-intent.component.html',
  styleUrls: ['./subscribe-intent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeIntentComponent {
  /** Callback на намеренье оформить подписку */
  @Output()
  subscribeIntent: EventEmitter<void> = new EventEmitter<void>();

  /** Обрабатывает намерение оформить подписку */
  onSubscribeIntent(): void {
    this.subscribeIntent.emit();
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

import { CONVERSATION_ASSISTANT_TYPES } from '@http/conversation/conversation.constants';

/** Статусы для фильтрации диалогов */
enum STATUSES {
  ALL = 'all',
  CLOSED = 'closed',
  DELAYED = 'delayed',
  OPENED = 'opened',
  UNANSWERED = 'unanswered',
}

@Component({
  selector: 'cq-conversation-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationStatusFilterComponent {
  readonly control: FormControl<STATUSES> = new FormControl<STATUSES>(STATUSES.OPENED, {
    nonNullable: true,
  });

  /** TODO Скорее всего, эту штуку лучше вынести в стор */
  @Input()
  selectedAssistantType!: CONVERSATION_ASSISTANT_TYPES;

  @Input()
  disabled: boolean = false;

  @Input()
  set value(value: STATUSES) {
    this.control.setValue(value, { emitEvent: false });
  }

  @Output()
  valueChanges = this.control.valueChanges.pipe(distinctUntilChanged());

  /** Этот класс обязательно должен быть на host-элементе, без него вёрстка будет некорректной */
  @HostBinding('class')
  class = 'btn-group';

  /** Массив статусов для фильтрации диалогов */
  STATUSES_ARRAY = [STATUSES.OPENED, STATUSES.DELAYED, STATUSES.CLOSED, STATUSES.ALL];

  constructor() {}

  isStatusItemDisabled(status: STATUSES) {
    return status === STATUSES.DELAYED && this.selectedAssistantType === CONVERSATION_ASSISTANT_TYPES.YANDEX_AI;
  }
}

<ng-container *transloco="let t; read: 'channelMuteComponent'">
  <div
    (click)="toggleChannelNotification()"
    class="d-flex justify-content-center align-items-center w-100 h-100"
    [ngbTooltip]="tooltip"
    container="body"
    placement="right"
    [autoClose]="false"
    [attr.data-test]="isMuted ? 'channel-unmute' : 'channel-mute'"
  >
    <img
      *ngIf="!isMuted"
      class="speaker-icon"
      src="assets/img/default/channels/mute.svg"
    />
    <img
      *ngIf="isMuted"
      class="speaker-icon"
      src="assets/img/default/channels/unmute.svg"
    />
  </div>

  <!-- пришлось тултип вынести в темплейт, т.к. иначе текст в тултипе после клика не менялся -->
  <ng-template #tooltip>{{ t(isMuted ? 'unmute' : 'mute') }}</ng-template>
</ng-container>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { INTEGRATION_COLORS, INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationExternal } from '@http/integration/interfaces/integration.interfaces';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AlreadyActiveTelegramBotModalComponent } from '@panel/app/partials/modals/already-active-telegram-bot-modal/already-active-telegram-bot-modal.component';
import { ALREADY_ACTIVE_TELEGRAM_BOT_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/already-active-telegram-bot-modal/already-active-telegram-bot-modal.token';
import { ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { SafeRemoveComponent } from '@panel/app/shared/modals/safe-remove/safe-remove.component';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { App } from '@http/app/app.model';
import { TelegramBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { TelegramBotStoreService } from '@panel/app-old/shared/services/telegram-bot-editor-store/telegram-bot-store.service';

@Component({
  selector: 'cq-integration-preview[integrationType][appName]',
  templateUrl: './integration-preview.component.html',
  styleUrls: ['./integration-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationPreviewComponent implements OnInit {
  @Input()
  currentApp!: App;

  @Input()
  integrationType!: INTEGRATION_TYPES;

  @Input()
  integrations: IntegrationExternal[] = [];

  @Input()
  telegramBotAllList: TelegramBot[] = [];

  /** Доступ до типа интеграции */
  accessToIntegrationType: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  accessToCreateNew: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  capabilities!: ReadonlyArray<string>;
  integrationTypeName!: string;
  integrationBackground!: string;
  instructionLink!: string | null;
  knowledgeBaseLink!: string | null;
  readonly INTEGRATION_TYPES = INTEGRATION_TYPES;
  readonly projectName = environment.projectName;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly cdr: ChangeDetectorRef,
    private readonly integrationService: IntegrationService,
    private readonly transloco: TranslocoService,
    private readonly modalHelper: ModalHelperService,
    private readonly telegramBotStoreService: TelegramBotStoreService,
    private readonly toastService: ToastService,
    private readonly paywallService: PaywallService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.accessToIntegrationType = this.integrationService.getAccessToIntegrationType(
      this.integrationType,
      this.currentApp,
    );
    this.accessToCreateNew = this.getAccessToCreateNew(this.integrationType);

    this.trackEnteringToPreview(this.integrationType);

    this.integrationBackground = INTEGRATION_COLORS[this.integrationType];
    this.integrationTypeName = this.transloco.translate(`models.integration.types.${this.integrationType}.name`, {
      projectName: this.projectName,
    });
    this.capabilities = this.transloco.translate(`models.integration.types.${this.integrationType}.capabilities`, {
      projectName: this.projectName,
      integrationTypeName: this.integrationTypeName,
    });
    this.instructionLink =
      this.transloco.translate(`models.integration.types.${this.integrationType}.links.toArticle`).trim() || null;
    this.knowledgeBaseLink =
      this.transloco.translate(`models.integration.types.${this.integrationType}.links.toKnowledgeBase`).trim() || null;
  }

  /**
   * Удаление интеграции
   * @param index индекс
   * @param integration интеграция
   */
  removeIntegration(index: number, integration: IntegrationExternal) {
    if (!integration.id) {
      throw new Error('removeIntegration is called for integration without an id');
    }
    this.integrationService.remove(integration.id, this.integrationType).subscribe(() => {
      this.integrations.splice(index, 1);
      this.cdr.markForCheck();
      this.toastService.success(
        this.transloco.translate('integrationDescriptionActionsComponent.toasts.integrationRemoved', {
          integrationTypeName: this.integrationTypeName,
        }),
      );
    });
  }

  /**
   * Коллбек клика на кнопку активации интеграции
   * @param integration
   * @param i
   */
  activeIntegrationCallback(integration: IntegrationExternal, i: number) {
    if (this.accessToIntegrationType.hasAccess) {
      this.changeIntegrationStatus(integration, i, true);
    } else {
      const paywall = this.paywallService.showPaywall(this.currentApp, this.accessToIntegrationType.denialReason);
      paywall.result.catch(() => {}); // Избавляемся от ошибки необработанного промиса, при закрытии модалки
    }
  }

  /**
   * Функция для trackBy
   * @param index
   * @param integration
   */
  trackByFn(index: number, integration: IntegrationExternal): string {
    return integration.id!;
  }

  /**
   * Изменение статуса активности интеграции
   * @param integration интеграция
   * @param index index
   * @param active новый статус
   */
  changeIntegrationStatus(integration: IntegrationExternal, index: number, active: boolean) {
    return this.integrationService
      .saveFromJson(this.currentApp.id, {
        ...integration,
        active,
      })
      .subscribe((response) => {
        this.integrations.splice(index, 1, response);
        this.cdr.markForCheck();
      });
  }

  /**
   * Клик по кнопке приостановки работы интеграции
   *
   * @param integration
   * @param i
   */
  pauseIntegrationCallback(integration: IntegrationExternal, i: number): void {
    if (this.integrationType === INTEGRATION_TYPES.TELEGRAM) {
      const telegramBotWithCurrentIntegration = this.getTelegramBotWithCurrentIntegration(integration);

      if (telegramBotWithCurrentIntegration.length) {
        const modal = this.openAlreadyActiveTelegramBotModal(integration, 'pause', telegramBotWithCurrentIntegration);

        modal.result
          .then(() => {
            this.changeIntegrationStatus(integration, i, false);
            this.telegramBotStoreService.telegramBotAllList$.next(
              telegramBotWithCurrentIntegration.map((telegramBot) => {
                return { ...telegramBot, active: !telegramBot.active };
              }),
            );
          })
          .catch(() => {});

        return;
      }
    }

    this.changeIntegrationStatus(integration, i, false);
  }

  /**
   * Открытие модалки с предупреждением об активных Телеграм-ботах
   *
   * @param integration
   * @param actionType
   * @param telegramBotWithCurrentIntegration
   */
  openAlreadyActiveTelegramBotModal(
    integration: IntegrationExternal,
    actionType: 'pause' | 'remove',
    telegramBotWithCurrentIntegration: TelegramBot[],
  ) {
    return this.modalHelper
      .provide(ALREADY_ACTIVE_TELEGRAM_BOT_MODAL_DATA_TOKEN, {
        actionType,
        appName: this.currentApp.name,
        integrationName: integration.name,
        botCount: telegramBotWithCurrentIntegration.length,
      })
      .open(AlreadyActiveTelegramBotModalComponent, {
        centered: true,
      });
  }

  openRemoveConfirmModal(index: number, integration: IntegrationExternal) {
    const modal = this.modalHelper.open(SafeRemoveComponent);
    const componentInstance = modal.componentInstance as SafeRemoveComponent;

    let bodyText: string;

    switch (integration.type) {
      // Если это интеграция "Уведомление на Email" нужно предупредить пользоватля об удалении всех экшеннов бота
      case INTEGRATION_TYPES.EMAIL_NOTIFICATION:
        bodyText = this.transloco.translate(
          'integrationDescriptionActionsComponent.modals.confirmRemovedIntegration.body.' + integration.type,
          { integrationTypeName: this.integrationTypeName },
        );
        break;
      default:
        bodyText = this.transloco.translate(
          'integrationDescriptionActionsComponent.modals.confirmRemovedIntegration.body.default',
          { integrationTypeName: this.integrationTypeName },
        );
        break;
    }

    componentInstance.heading = this.transloco.translate(
      'integrationDescriptionActionsComponent.modals.confirmRemovedIntegration.heading',
      { integrationTypeName: this.integrationTypeName },
    );
    componentInstance.body = bodyText;
    componentInstance.confirmText = this.transloco.translate(
      'integrationDescriptionActionsComponent.modals.confirmRemovedIntegration.confirmText',
      { currentApp: this.currentApp.name },
    );
    componentInstance.confirmedItemName = this.currentApp.name;

    modal.result.then(() => this.removeIntegration(index, integration)).catch(() => {});
  }

  removeIntegrationCallback(integration: IntegrationExternal, index: number): void {
    if (integration.type === INTEGRATION_TYPES.TELEGRAM) {
      const telegramBotWithCurrentIntegration = this.getTelegramBotWithCurrentIntegration(integration);

      if (telegramBotWithCurrentIntegration.length) {
        const modal = this.openAlreadyActiveTelegramBotModal(integration, 'remove', telegramBotWithCurrentIntegration);

        modal.result
          .then(() => {
            this.removeIntegration(index, integration);
          })
          .catch(() => {});

        return;
      }
    }

    this.openRemoveConfirmModal(index, integration);
  }

  /**
   * Трекинг захода на страницу с preview
   *
   * @param integrationType - Тип интеграции, на которую зашли
   */
  trackEnteringToPreview(integrationType: INTEGRATION_TYPES): void {
    const eventName = `Интеграции - ${integrationType} - переход на превью`;

    this.carrotquestHelper.track(eventName);
  }

  /** Трекинг клика на кнопку создания интеграции */
  trackClickToAddIntegrationButton(integrationType: INTEGRATION_TYPES): void {
    const eventName = `Интеграции - ${integrationType} - подключение интеграции`;

    this.carrotquestHelper.track(eventName);
  }

  private getAccessToCreateNew(type: INTEGRATION_TYPES): ProductFeatureAccess {
    switch (type) {
      case INTEGRATION_TYPES.WHATS_APP_EDNA:
        return this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS_WHATSAPP, this.currentApp);
      default:
        return {
          hasAccess: true,
          denialReason: null,
        };
    }
  }

  /**
   * Получение списка Телеграм-ботов, который привязаны к интеграции
   *
   * @param integration
   * @private
   */
  private getTelegramBotWithCurrentIntegration(integration: IntegrationExternal): TelegramBot[] {
    return this.telegramBotStoreService.telegramBotAllList$
      .getValue()
      .filter((telegramBot) => telegramBot.active && telegramBot.integration === integration.id);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationService } from '@http/integration/services/integration.service';
import { IntegrationIconModule } from '@panel/app/pages/integrations/integration-icon/integration-icon.module';
import { LeadsPassingPreviewModalModule } from '@panel/app/pages/starter-guide/leads-passing/preview-modal/leads-passing-preview-modal.module';
import { ModalHelperService } from '@panel/app/services';
import { SharedModule } from '@panel/app/shared/shared.module';

import { StarterGuideLeadsPassingComponent } from './starter-guide-leads-passing.component';

@NgModule({
  declarations: [StarterGuideLeadsPassingComponent],
  exports: [StarterGuideLeadsPassingComponent],
  imports: [
    CommonModule,
    IntegrationIconModule,
    LeadsPassingPreviewModalModule,
    NgbAlertModule,
    SharedModule,
    TranslocoModule,
  ],
  providers: [IntegrationService, ModalHelperService],
})
export class StarterGuideLeadsPassingModule {}

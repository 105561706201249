import { AbstractControl, ValidationErrors } from '@angular/forms';

export function integerValidator(control: AbstractControl): ValidationErrors | null {
  const INTEGER_REGEXP = /^\-?\d+$/;

  if (control.value === null || control.value === '') {
    return null;
  }

  const valid = INTEGER_REGEXP.test(control.value);

  return valid ? null : { integer: true };
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { App } from '@http/app/app.model';

/**
 * Компонент-обёртка для настройки интеграции с Albato
 */

@Component({
  selector: 'cq-albato-wrapper-settings[currentApp]',
  templateUrl: './albato-wrapper-settings.component.html',
  styleUrls: ['./albato-wrapper-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlbatoWrapperSettingsComponent {
  /** Текущее приложение */
  @Input()
  currentApp!: App;
}

(function () {
  'use strict';

  require('./conversations-conversation-list.controller');

  angular.module('myApp.conversations').component('cqConversationsConversationList', cqConversationsConversationList());

  function cqConversationsConversationList() {
    return {
      bindings: {
        channel: '=', // текущий выбранный канал
        channelCounters: '=', // счётчики каналов
        channels: '=', // текущий выбранный канал
        conversationId: '<', // ID выбранного диалога (должна быть возможность выбирать диалог снаружи)
        currentApp: '=', // текущее приложение
        djangoUser: '=', // django-пользователь
        onConversationChange: '&?', // функция, вызываемая на смену текущего диалога
        tags: '=', // список тегов для диалогов
        teamMembers: '=', // список членов команды
        isDarkThemeActive: '=', // Активна ли тесная тема
        showUseCalendlyPopover: '=',
        changeTheme: '&', // Callback для смены темной темы
        searchByUserClicked: '&', // Callback для клика по кнопке списка пользователей
        onChannelChange: '<?', // функция, вызываемая на смену текущего канала
      },
      controller: 'CqConversationsConversationListController',
      controllerAs: 'vm',
      template: require('./conversations-conversation-list.html'),
    };
  }
})();

<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ heading }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div
      class="margin-bottom-20"
      [innerHTML]="body"
    ></div>
    <form
      autocomplete="off"
      novalidate
      (ngSubmit)="confirmRemove()"
    >
      <div
        class="form-group no-margin"
        [cqShowError]="confirmNameControl"
      >
        <div
          class="margin-bottom-5"
          [innerHTML]="confirmText"
        ></div>
        <input
          class="form-control"
          required
          type="text"
          [formControl]="confirmNameControl"
        />
      </div>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
    >
      {{ t('general.cancel') }}
    </button>
    <label
      class="btn btn-primary"
      (click)="confirmRemove()"
      >{{ confirmButtonText }}</label
    >
  </div>
</ng-container>

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('RetailcrmController', RetailcrmController);

  function RetailcrmController(
    $filter,
    $q,
    $location,
    $scope,
    $state,
    $stateParams,
    $translate,
    $uibModal,
    toastr,
    PROJECT_NAME,
    RETAILCRM_INTEGRATION_STEPS,
    WEBHOOKS_ENDPOINT,
    caseStyleHelper,
    eventTypeModel,
    integrationModel,
    propertyModel,
    validationHelper,
    wizardHelper,
    currentApp,
    integration,
  ) {
    var vm = this;

    var STANDARD_RETAILCRM_PROPS_DEFAULT = [
      {
        checked: false,
        cqName: '$name',
      },
      {
        checked: false,
        cqName: '$email',
      },
      {
        checked: false,
        cqName: '$phone',
      },
    ];

    var STANDARD_PROPS_DEFAULT = [
      {
        retailName: '$name',
        cqName: '$name',
      },
      {
        retailName: '$email',
        cqName: '$email',
      },
      {
        retailName: '$phone',
        cqName: '$phone',
      },
    ];

    const RETAILCRM_ADDRESS_PATTERN_REGEXP = /(?:https:\/\/)?([\w-]+)\.retailcrm\.ru/;

    vm.$translate = $translate;
    vm.addMappingItem = addMappingItem;
    vm.apiNotAvailable = false; // ошибка при загрузке воронок RetailCRM
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.currentStep = angular.isDefined(integration.settings.lastCompletedStep)
      ? getStepByIndex(RETAILCRM_INTEGRATION_STEPS, integration.settings.lastCompletedStep + 1)
      : getStepByIndex(RETAILCRM_INTEGRATION_STEPS, 0); // текущий шаг визарда
    vm.customerWebhookUrl = '';
    vm.openEditStatus = openEditStatus;
    vm.eventsPropsMapping = []; //соответствие между свойствами событий (первое, последнее, счетчик) и свойствами контакта в amo (при передаче из cq в amo)
    vm.eventTypes = []; // справочник типов событий
    vm.eventTypesFilter = ''; // поиск в списке событий-триггеров для отправки контактов
    vm.filterEvents = filterEvents;
    vm.filterProps = filterProps;
    vm.integration = integration;
    vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
      ? vm.currentStep.ORDER > integration.settings.lastCompletedStep
      : angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.onCopyUrlSuccess = onCopyUrlSuccess;
    vm.ordersEventsMapping = []; // соответствие статусов сделок amo событиям в кэрроте (после смены статуса в amo)
    vm.orderStatuses = []; // воронки RetailCRM + соответствующие статусам события в кэрроте
    vm.orderWebhookUrl = '';
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.props = []; // свойства
    vm.propsEvents = []; // свойства событий (первое, последнее, счетчик)
    vm.propsMapping = []; // соответствие свойств кэррота свойствам контакта в amo (из cq в amo)
    vm.removeMappingItem = removeMappingItem;
    vm.RETAILCRM_INTEGRATION_STEPS = RETAILCRM_INTEGRATION_STEPS;
    vm.retailcrmOrdersPropsMapping = []; //соответствие между свойствами сделки и свойствами кэррота (при передаче из amo в cq)
    vm.retailcrmPropsMapping = []; //соответствие между свойствами контакта и свойствами кэррота (при передаче из amo в cq)
    vm.STANDARD_PROPS_DEFAULT = STANDARD_PROPS_DEFAULT; // имя, емейл, телефон из кэррота в amo (неизменяемое)
    vm.standardProps = []; // имя, емейл, телефон из amo в кэррот (можно отключить, нельзя изменить)
    vm.statusEvents = []; // справочник системных событий
    vm.statusEventsNames = []; // справочник названий системных событий
    vm.toggleOrdersEvent = toggleOrdersEvent;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.validationHelper = validationHelper;
    vm.wizard = null; // инстанс визарда
    vm.wizardHelper = wizardHelper;

    init();

    function init() {
      parseRetailcrmIntegrationToInternalFormat(integration);

      if ($stateParams.integrationId) {
        integrationModel.retailcrm
          .getCustomerFields(integration.id)
          .then(getCustomerFieldsSuccess)
          .catch(getCustomerFieldsError);
        integrationModel.retailcrm
          .getOrderFields(integration.id)
          .then(getOrderFieldsSuccess)
          .catch(getOrderFieldsError);
        integrationModel.retailcrm
          .getOrderStatuses(integration.id)
          .then(getOrderStatusesSuccess)
          .catch(getOrderStatusesError);

        vm.customerWebhookUrl =
          WEBHOOKS_ENDPOINT + '/retailcrm/' + integration.id + '/customer_update/' + integration.settings.token;
        vm.orderWebhookUrl =
          WEBHOOKS_ENDPOINT + '/retailcrm/' + integration.id + '/order_update/' + integration.settings.token;
      }

      firstValueFrom(propertyModel.getList(currentApp.id)).then(getPropertyListSuccess);

      wizardHelper.getWizard().then(getWizardSuccess);

      $scope.$watchCollection('[vm.orderStatuses, vm.ordersEventsMapping]', function (newValues, oldValues) {
        // если изменились воронки, или их сопоставление с событиями - надо их заново сопоставить
        if (newValues[0].length) {
          matchStatusesWithOrdersEvents(newValues[0], newValues[1]);
        }
      });
      $scope.$watch('vm.currentStep', function (newValue, oldValue) {
        //обновляем состояние инструкции в зависимости от текущего шага
        vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
          ? newValue.ORDER > integration.settings.lastCompletedStep
          : angular.isUndefined(integration.id);
      });

      function getCustomerFieldsSuccess(customerFields) {
        vm.customerFields = customerFields;
      }

      function getCustomerFieldsError() {
        vm.apiNotAvailable = true;
      }

      function getOrderFieldsSuccess(orderFields) {
        vm.orderFields = orderFields;
      }

      function getOrderFieldsError() {
        vm.apiNotAvailable = true;
      }

      function getOrderStatusesSuccess(orderStatuses) {
        vm.orderStatuses = orderStatuses;
      }

      function getOrderStatusesError() {
        vm.apiNotAvailable = true;
      }

      function getPropertyListSuccess(properties) {
        vm.eventTypes = properties.eventTypes;
        vm.props = properties.userProps;
        vm.propsEvents = properties.eventTypeProps;
        vm.statusEvents = properties.eventTypes.filter(filterEventTypesByName);
        vm.statusEventsNames = vm.statusEvents.map(mapStatusEventsToStatusEventsNames);

        function filterEventTypesByName(eventType) {
          return eventType.name != eventType.prettyName;
        }

        function mapStatusEventsToStatusEventsNames(eventType) {
          return eventType.name;
        }
      }

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }
    }

    /**
     * Добавление объекта в массив
     *
     * @param {Array} array Массив, в который добавляется объект
     */
    function addMappingItem(array) {
      array.push({});
    }

    /**
     * Конвертация массива объектов (ключи и значения этих объектов должны быть примитивными типами) в объект
     *
     * @example
     * // вернёт {'value1': 'value2', 'value3': 'value4'}
     * convertArrayToMapping([{'key1': 'value1', 'key2': 'value2'}, {'key1': 'value3', 'key2': 'value4'}], 'key1', 'key2');
     *
     * @param {Array.<Object>} array Массив объектов для конвертации
     * @param {String} keyName Название ключа объекта массива, значение которого будет являться ключом в результирующем объекте
     * @param {String} valueName Название ключа объекта массива, значение которого будет являться значением в результирующем объекте
     * @return {Object}
     */
    function convertArrayToMapping(array, keyName, valueName) {
      var mapping = {};

      for (var i = 0; i < array.length; i++) {
        var item = array[i];

        mapping[item[keyName]] = item[valueName];
      }

      return mapping;
    }

    /**
     * Конвертация объекта (ключи и значения объекта должны быть примитивными типами) в массив объектов
     *
     * @example
     * // вернёт [{'newKey1': 'key1', 'newKey2': 'value1'}, {'newKey1': 'key2', 'newKey2': 'value2'}]
     * convertMappingToArray({'key1': 'value1', 'key2': 'value2'}, 'newKey1', 'newKey2');
     *
     * @param {Object} mapping Объект для конвертации
     * @param {String} keyName Название ключа элемента массива, значение которого будет являться ключом объекта
     * @param {String} valueName Название ключа элемента массива, значение которого будет являться значением объекта
     * @return {Array.<Object>}
     */
    function convertMappingToArray(mapping, keyName, valueName) {
      var array = [];

      for (var key in mapping) {
        if (mapping.hasOwnProperty(key)) {
          var arrayItem = {};

          arrayItem[keyName] = key;
          arrayItem[valueName] = mapping[key];

          array.push(arrayItem);
        }
      }

      return array;
    }

    /**
     * Создание новых типов событий
     *
     * @param {Array.<Object>} ordersEvents Типы событий, которые надо создать
     */
    function createEventTypes(ordersEvents) {
      var eventTypesNames = ordersEvents.map(mapEventTypes);

      firstValueFrom(eventTypeModel.create(currentApp.id, eventTypesNames)).then();

      function mapEventTypes(ordersEvent) {
        return ordersEvent.event.prop;
      }
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {form.FormController} form Контроллер формы
     */
    function createOrUpdateIntegration(form) {
      form.$commitViewValue();
      form.$setSubmitted();

      if (form.$valid) {
        integration.settings.lastCompletedStep =
          integration.settings.lastCompletedStep > vm.currentStep.ORDER
            ? integration.settings.lastCompletedStep
            : vm.currentStep.ORDER;

        parseRetailcrmIntegrationToServerFormat(integration);

        if (integration.id) {
          return integrationModel.save(currentApp.id, integration).then(saveSuccess);
        } else {
          return integrationModel.create(currentApp.id, integration).then(createSuccess);
        }
      } else {
        return $q.reject();
      }

      function createSuccess(integration) {
        var params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        var transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess(integration) {
        parseRetailcrmIntegrationToInternalFormat(integration);
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Фильтрация событий из общего списка свойств и событий
     *
     * @param propMapping
     * @return {Boolean}
     */
    function filterEvents(propMapping) {
      if (angular.equals({}, propMapping) || propMapping.cqName.indexOf('$event_') == 0) {
        return true;
      }
    }

    /**
     * Фильтрация свойств из общего списка свойств и событий
     *
     * @param propMapping
     * @return {Boolean}
     */
    function filterProps(propMapping) {
      if (angular.equals({}, propMapping) || propMapping.cqName.indexOf('$event_') == -1) {
        return true;
      }
    }

    /**
     * Получение шага из массива/объекта шагов steps по индексу index
     *
     * @param {Object|Array.<Object>} steps Массив/объект шагов, из которых осуществялется выборка
     * @param {Number} index Индекс шага (шаги упорядочены по ORDER, т.е. этот индекс и является ORDER)
     * @return {Object}
     */
    function getStepByIndex(steps, index) {
      var stepsArray = $filter('toArray')(steps);

      // если номер искомого шага больше, чем количество всех шагов - полагаем, что надо найти последний шаг
      index = index > stepsArray.length - 1 ? stepsArray.length - 1 : index;

      var step = $filter('filter')(stepsArray, { ORDER: index }, true)[0];
      if (step) {
        return step;
      } else {
        return $filter('filter')(stepsArray, { ORDER: 0 }, true)[0];
      }
    }

    /**
     * Создание соотношений между воронками и событиями
     *
     * @param {Array.<Object>} orderStatuses Воронки
     * @param {Array.<Object>} ordersEvents События
     */
    function matchStatusesWithOrdersEvents(orderStatuses, ordersEvents) {
      for (var i = 0; i < orderStatuses.length; i++) {
        var status = orderStatuses[i];
        // вначале пытаемся найти событие, соответствующее воронке
        var ordersEvent = $filter('filter')(
          ordersEvents,
          {
            event: {
              code: status.code,
            },
          },
          true,
        )[0];

        // если такого не нашлось - создаём его
        if (angular.isUndefined(ordersEvent)) {
          ordersEvent = {
            event: {
              code: status.code,
              prop: 'RetailCRM: ' + status.groupName + ' - ' + status.name,
            },
            eventProps: [],
            checked: false,
            alreadyCreated: false,
          };
        }

        status.ordersEvent = ordersEvent;
      }
    }

    /**
     * Тост при успешном копировании ссылки
     */
    function onCopyUrlSuccess() {
      toastr.success($translate.instant('general.linkCopied'));
    }

    /**
     * Открытие модалки для редактирования соответствия статуса сделки amo и события в кэрроте.
     * (Свойства событий можно задать только в модальном окне)
     *
     * @param status Статус заказа retailCRM, для коорого настраивается соответствие
     */
    function openEditStatus(status) {
      var modalInstance = $uibModal.open({
        controller: 'EditRetailcrmStatusModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/edit-retailcrm-status/edit-retailcrm-status.html',
        size: 'lg',
        resolve: {
          // список событий копируется из-за того, что в модалке в этот список добавляются фиктивные элементы
          eventTypes: angular.bind(null, angular.identity, angular.copy(vm.eventTypes)),
          retailcrmProps: angular.bind(null, angular.identity, angular.copy(vm.orderFields)),
          // список воронок копируется из-за того, что применяться изменения должны не сразу, а только после подтверждения в модалке
          status: angular.bind(null, angular.identity, angular.copy(status)),
        },
      });

      modalInstance.result.then(editStatusSuccess);

      function editStatusSuccess(resultStatus) {
        // просто так мерджить нельзя, потому что событие и набор свойств могли измениться полностью, в т.ч. свойства могли удалить
        status.ordersEvent.checked = resultStatus.ordersEvent.checked;
        status.ordersEvent.event = resultStatus.ordersEvent.event;
        status.ordersEvent.eventProps = resultStatus.ordersEvent.eventProps;
      }
    }

    /**
     * Парсинг интеграции во внутренний формат админки
     *
     * @param {Object} integration Интеграция
     */
    function parseRetailcrmIntegrationToInternalFormat(integration) {
      if (!RETAILCRM_ADDRESS_PATTERN_REGEXP.test(integration.settings.address)) {
        integration.settings.address = `https://${integration.settings.address}.retailcrm.ru`;
      }

      integration.settings.eventTypeIds = angular.isDefined(integration.settings.eventTypeIds)
        ? integration.settings.eventTypeIds
        : [];
      integration.settings.ordersEventTypeIds = angular.isDefined(integration.settings.ordersEventTypeIds)
        ? integration.settings.ordersEventTypeIds
        : [];
      vm.propsMapping = angular.isDefined(integration.settings.propsMapping)
        ? $filter('filter')(
            convertMappingToArray(integration.settings.propsMapping, 'retailName', 'cqName'),
            filterProps,
          )
        : [];
      vm.eventsPropsMapping = angular.isDefined(integration.settings.propsMapping)
        ? $filter('filter')(
            convertMappingToArray(integration.settings.propsMapping, 'retailName', 'cqName'),
            filterEvents,
          )
        : [];
      vm.standardProps = angular.isDefined(integration.settings.standardProps)
        ? convertMappingToArray(integration.settings.standardProps, 'cqName', 'checked')
        : angular.copy(STANDARD_RETAILCRM_PROPS_DEFAULT);
      vm.retailcrmPropsMapping = angular.isDefined(integration.settings.retailcrmPropsMapping)
        ? convertMappingToArray(integration.settings.retailcrmPropsMapping, 'retailName', 'cqName')
        : [];
      vm.ordersEventsMapping = angular.isDefined(integration.settings.ordersEventsMapping)
        ? parseOrdersEventsMappingToInternalFormat()
        : [];
      vm.retailcrmOrdersPropsMapping = angular.isDefined(integration.settings.retailcrmOrdersPropsMapping)
        ? convertMappingToArray(integration.settings.retailcrmOrdersPropsMapping, 'retailName', 'cqName')
        : [];
    }

    /**
     * Парсинг интеграции в формат сервера
     *
     * @param {Object} integration Интеграция
     */
    function parseRetailcrmIntegrationToServerFormat(integration) {
      if (RETAILCRM_ADDRESS_PATTERN_REGEXP.test(integration.settings.address)) {
        integration.settings.address = integration.settings.address.match(RETAILCRM_ADDRESS_PATTERN_REGEXP)[1];
      }

      integration.settings.propsMapping = angular.extend(
        {},
        convertArrayToMapping(vm.propsMapping, 'retailName', 'cqName'),
        convertArrayToMapping(vm.eventsPropsMapping, 'retailName', 'cqName'),
      );
      integration.settings.standardProps = convertArrayToMapping(vm.standardProps, 'cqName', 'checked');
      integration.settings.retailcrmPropsMapping = convertArrayToMapping(
        vm.retailcrmPropsMapping,
        'retailName',
        'cqName',
      );
      integration.settings.ordersEventsMapping = parseOrdersEventsMappingToServerFormat();
      integration.settings.retailcrmOrdersPropsMapping = convertArrayToMapping(
        vm.retailcrmOrdersPropsMapping,
        'retailName',
        'cqName',
      );

      // нужно создать события для всех отмеченных статусов retailcrm
      var newOrdersEvents = $filter('filter')(vm.ordersEventsMapping, { checked: true }, true);
      if (newOrdersEvents.length) {
        createEventTypes(newOrdersEvents);
      }
    }

    /**
     * Парсинг событий во внутренний формат админки
     *
     * @return {Array}
     */
    function parseOrdersEventsMappingToInternalFormat() {
      var result = [];

      for (var i = 0; i < integration.settings.ordersEventsMapping.length; i++) {
        var ordersEvent = integration.settings.ordersEventsMapping[i];
        var parsedOrdersEvent = {
          event: {},
          eventProps: [],
          checked: false,
          alreadyCreated: true,
        };

        for (var key in ordersEvent.event) {
          if (ordersEvent.event.hasOwnProperty(key)) {
            parsedOrdersEvent.event.code = key;
            parsedOrdersEvent.event.prop = ordersEvent.event[key];
          }
        }

        for (var key in ordersEvent.eventProps) {
          if (ordersEvent.eventProps.hasOwnProperty(key)) {
            parsedOrdersEvent.eventProps.push({
              retailName: key,
              cqName: ordersEvent.eventProps[key],
            });
          }
        }

        parsedOrdersEvent.checked = ordersEvent.checked;

        result.push(parsedOrdersEvent);
      }

      return result;
    }

    /**
     * Парсинг событий в формат сервера
     *
     * @return {Array}
     */
    function parseOrdersEventsMappingToServerFormat() {
      var result = [];

      for (var i = 0; i < vm.ordersEventsMapping.length; i++) {
        var ordersEventMapping = vm.ordersEventsMapping[i];
        var parsedOrderEventMapping = {
          event: {},
          eventProps: {},
          checked: false,
        };
        console.log('event', ordersEventMapping.event);
        parsedOrderEventMapping.event[ordersEventMapping.event.code] = ordersEventMapping.event.prop;

        for (var j = 0; j < ordersEventMapping.eventProps.length; j++) {
          var eventProp = ordersEventMapping.eventProps[j];
          parsedOrderEventMapping.eventProps[eventProp.retailName] = eventProp.cqName;
        }

        parsedOrderEventMapping.checked = ordersEventMapping.checked;
        console.log('parsedOrderEventMapping', parsedOrderEventMapping);
        result.push(parsedOrderEventMapping);
      }

      return result;
    }

    /**
     * Удаление объекта из массива
     *
     * @param array
     * @param item
     */
    function removeMappingItem(array, item) {
      var index = array.indexOf(item);

      if (~index) {
        array.splice(index, 1);
      }
    }

    /**
     * Вкд/выкл отправку события в кэррот при переходе сделки amo в выбранный статус
     *
     * @param ordersEvent Соответствие статуса и события в кэрроте, которое будет отправлено при переходе в этот статус
     */
    function toggleOrdersEvent(ordersEvent) {
      if (ordersEvent.checked && !ordersEvent.alreadyCreated) {
        vm.ordersEventsMapping.push(ordersEvent);
      } else if (!ordersEvent.alreadyCreated && !ordersEvent.alreadyCreated) {
        vm.ordersEventsMapping.splice(vm.ordersEventsMapping.indexOf(ordersEvent), 1);
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

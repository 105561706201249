import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import {
  BotAppOnlineConditionActionForm,
  BotDefaultConditionActionForm,
} from '@panel/app/pages/chat-bot/forms/actions';
import { BotAssistantActionForm } from '@panel/app/pages/chat-bot/forms/actions/assistant-action.form';
import { BotCloseActionForm } from '@panel/app/pages/chat-bot/forms/actions/close-action.form';
import { BotMarkConversationVisibleActionForm } from '@panel/app/pages/chat-bot/forms/actions/mark-conversation-visible-action.form';
import { BotMeetingActionForm } from '@panel/app/pages/chat-bot/forms/actions/meeting-action.form';
import { BotMoveNextBranchActionForm } from '@panel/app/pages/chat-bot/forms/actions/move-next-branch-action.form';

import { BotAmocrmNotificationActionForm } from '../amocrm-notification-action.form';
import { ActionValidationExtra, BaseBotActionForm } from '../base-action.form';
import { BotButtonActionForm } from '../button-action.form';
import { BotButtonsPropertyActionForm } from '../buttons-property-action.form';
import { BotChannelActionForm } from '../channel-action.form';
import { BotEmailNotificationActionForm } from '../email-notification-action.form';
import { BotEventActionForm } from '../event-action.form';
import { BotFileActionForm } from '../file-action.form';
import { BotOperatorActionForm } from '../operator-action.form';
import { BotPropertyActionForm } from '../property-action.form';
import { BotPropertyFieldActionForm } from '../property-field-action.form';
import { BotTextActionForm } from '../text-action.form';
import { BotUserTagActionForm } from '../user-tag-action.form';

export class ActionFormFactory {
  static create(action: ChatBotAction, validationExtra: ActionValidationExtra): BaseBotActionForm {
    switch (action.type) {
      case CHAT_BOT_ACTIONS_TYPES.TEXT:
        return new BotTextActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.BUTTON:
        return new BotButtonActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.EVENT:
        return new BotEventActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION:
        return new BotEmailNotificationActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.USER_TAG:
        return new BotUserTagActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE:
        return new BotMarkConversationVisibleActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.MEET:
        return new BotMeetingActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.PROPERTY:
        return new BotPropertyActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION:
        return new BotAmocrmNotificationActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.FILE:
        return new BotFileActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD:
        return new BotPropertyFieldActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.ASSISTANT:
        return new BotAssistantActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.OPERATOR:
        return new BotOperatorActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.CHANNEL:
        return new BotChannelActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY:
        return new BotButtonsPropertyActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.CLOSE:
        return new BotCloseActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION:
        return new BotAppOnlineConditionActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.NEXT:
        return new BotMoveNextBranchActionForm(action, validationExtra);
      case CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION:
        return new BotDefaultConditionActionForm(action, validationExtra);
      default:
        throw new Error(`Could not find form constructor for specified type: ${action.type}`);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { TelegramChannelPopoverWrapperModule } from '@panel/app/pages/conversations/conversations-channel-list/telegram-channel-popover-wrapper/telegram-channel-popover-wrapper.module';
import { WhatsAppChannelPopoverWrapperModule } from '@panel/app/pages/conversations/conversations-channel-list/whats-app-channel-popover-wrapper/whats-app-channel-popover-wrapper.module';
import { ChannelModule } from '@panel/app/partials/channel/channel.module';
import { ChannelMuteModule } from '@panel/app/partials/channel-mute/channel-mute.module';

import { ConversationsChannelListComponent } from './conversations-channel-list.component';

@NgModule({
  declarations: [ConversationsChannelListComponent],
  imports: [
    ChannelModule,
    ChannelMuteModule,
    CommonModule,
    NgbCollapseModule,
    NgbPopoverModule,
    OverlayscrollbarsModule,
    TranslocoModule,
    WhatsAppChannelPopoverWrapperModule,
    TelegramChannelPopoverWrapperModule,
  ],
  exports: [ConversationsChannelListComponent],
  providers: [CarrotquestHelperProvider],
})
export class ConversationsChannelListModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TuiLetModule } from '@taiga-ui/cdk';

import { TriggerChainStepEditorWrapModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-editor-wrap/trigger-chain-step-editor-wrap.module';
import { TriggerChainStepNameEditorModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-name-editor/trigger-chain-step-name-editor.module';
import { AceEditorModule } from '@panel/app/partials/ace-editor/ace-editor.module';
import { PropertyFilterModule } from '@panel/app/partials/filters/components/property-filter/property-filter.module';
import { EventFiltersModule } from '@panel/app/partials/filters/filters/event-filters/event-filters.module';
import { PropertyFiltersModule } from '@panel/app/partials/filters/filters/property-filters/property-filters.module';
import { TimeSelectorModule } from '@panel/app/partials/message-editor/shared/time-selector/time-selector.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { TriggerChainFilterEditorComponent } from './trigger-chain-filter-editor.component';
import { TriggerChainFilterEditorModalWrapComponent } from './trigger-chain-filter-editor-modal-wrap.component';

@NgModule({
  declarations: [TriggerChainFilterEditorComponent, TriggerChainFilterEditorModalWrapComponent],
  imports: [
    CommonModule,
    TimeSelectorModule,
    TriggerChainStepEditorWrapModule,
    TranslocoModule,
    ReactiveFormsModule,
    PropertyFilterModule,
    PropertyFiltersModule,
    EventFiltersModule,
    TuiLetModule,
    DirectivesModule,
    SharedModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    TriggerChainStepNameEditorModule,
    NgSelectModule,
    AceEditorModule,
    FormsModule,
  ],
  exports: [TriggerChainFilterEditorComponent, TriggerChainFilterEditorModalWrapComponent],
})
export class TriggerChainFilterEditorModule {}

<div class="flex justify-center text-center margin-bottom-30">
  <ng-container *ngFor="let step of wizardService.steps">
    <div
      *ngIf="!newWizard"
      class="cq-wizard-item no-space"
      [ngClass]="{ active: step.active, complete: step.complete }"
    >
      <div class="cq-wizard-item-progress-bar">
        <div class="cq-wizard-item-progress-bar-left"></div>
        <div
          class="cq-wizard-item-circle"
          (click)="step.selectStep(step)"
        ></div>
        <div class="cq-wizard-item-progress-bar-right"></div>
      </div>
      <span
        class="cq-wizard-item-text small font-weight-bold cursor-pointer"
        (click)="step.selectStep(step)"
        >{{ step.heading }}</span
      >
    </div>

    <button
      *ngIf="newWizard"
      class="btn btn-secondary"
      (click)="step.selectStep(step)"
      [innerHTML]="step.heading"
      [ngClass]="{ active: step.active }"
    ></button>
  </ng-container>
</div>

<ng-content></ng-content>

(function () {
  'use strict';

  require('./auto-event-page-url.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventPageUrl', cqAutoEventPageUrl());

  function cqAutoEventPageUrl() {
    return {
      bindings: {
        isUrlContain: '=', // Учитывать подстраницы
        pageUrl: '=', // Начальное значение свернуточти
      },
      controller: 'CqAutoEventPageUrlController',
      controllerAs: 'vm',
      template: require('./auto-event-page-url.html'),
    };
  }
})();

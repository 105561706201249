<ng-container *transloco="let t; read: 'blockActionEditorComponent'">
  <span class="small text-muted">{{ t('description') }}</span>
  <hr />
  <ng-container
    *ngFor="
      let action of getRenderContentActions();
      let actionIndex = index;
      let first = first;
      trackBy: trackByActionLinkId
    "
  >
    <div [ngbCollapse]="actionsCollapse[action.linkId]">
      <cq-branch-action-container
        class="padding-bottom-20"
        [action]="action"
        [boldHeader]="false"
        [callbacks]="getCallbacks(actionIndex, ACTIONS_GROUPS.CONTENT)"
        [currentApp]="currentApp"
        [chatBotType]="chatBotType"
        [chatBotId]="chatBotId"
        [index]="getActionHeaderIndex(action.type, actionIndex)"
        [isTargetAction]="false"
        [nextBranchOptions]="nextBranchOptions"
        [propertyOptions]="properties"
        [showHeader]="true"
      ></cq-branch-action-container>
    </div>
  </ng-container>

  <cq-other-actions-dropdown
    [currentApp]="currentApp"
    (botActionCreate)="botActionCreate.emit($event)"
    [isButtonDisabled]="isButtonDisabled"
  ></cq-other-actions-dropdown>
  <hr />
  <cq-branch-action-next
    [actionForm]="getNextAction(branch).form"
    [nextBranchOptions]="nextBranchOptions"
    [currentApp]="currentApp"
    [chatBotType]="chatBotType"
    [chatBotId]="chatBotId"
    (branchCreate)="callbacks.createNewBranch($event, getNextAction(branch))"
  ></cq-branch-action-next>
</ng-container>

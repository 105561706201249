import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { BranchFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { BadgeFactory } from '@panel/app/pages/chat-bot/content/services/factories/badge.factory';
import { InterruptBadge, StartBadge } from '@panel/app/pages/chat-bot/content/views/badges';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { BadgeForm } from '@panel/app/pages/chat-bot/forms/badge.form';
import { BotBranchForm } from '@panel/app/pages/chat-bot/forms/bot-branch.form';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { Modify } from '@panel/app/shared/types/modify.type';

type BotFormData<T extends CHAT_BOT_TYPE> = Modify<
  Pick<ChatBot<T>, 'name' | 'branches'>,
  {
    branches: BotBranchForm[];
    startBadge: BadgeForm;
    interruptBadge: BadgeForm;
  }
>;

export class ChatBotForm<T extends CHAT_BOT_TYPE> extends GenericFormGroup<BotFormData<T>> {
  allowUserReplies: boolean;
  /**
   * Т.к. при любом обновлении списка branchs надо обновлять и список controls в форме,
   * притяно решение реализовать таким образом, а редактирование branchs через отдельные методы,
   * которые обновят и controls тоже.
   * Оставляю так, до принятия более элегантного решения по структуре.
   */
  private _branches: Branch[];
  public get branches(): ReadonlyArray<Branch> {
    return this._branches;
  }

  private _deletedBranchesId: string[];
  public get deletedBranchesId(): ReadonlyArray<string> {
    return this._deletedBranchesId;
  }

  initialData: ChatBot<T>;
  interruptedBranch: Branch | null;
  startedBranch: Branch | null;
  startBadge: StartBadge;
  interruptBadge: InterruptBadge;

  set triggers(value: string[]) {
    this.startBadge.triggers = value;
  }

  set leaveSiteAttemptTrigger(value: boolean) {
    this.startBadge.leaveSiteAttemptTrigger = value;
  }

  set openWebPageTriggerAmount(value: number) {
    this.startBadge.openWebPageTriggerAmount = value;
  }

  set openSdkPageTriggerAmount(value: number) {
    this.startBadge.openSdkPageTriggerAmount = value;
  }

  constructor(
    chatBot: ChatBot<T>,
    branchFactory: BranchFactory,
    badgeFactory: BadgeFactory,
    scenariosHelper: BotScenariosHelper,
    triggers: string[],
    openWebPageTriggerAmount: number,
    openSdkPageTriggerAmount: number,
    leaveSiteAttemptTrigger: boolean,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    const getBranchByLinkId = (linkId: string) => this.branches.find((item) => item.linkId === linkId) ?? null;
    const branches = chatBot.branches
      ? chatBot.branches.map((branch) => {
          return branchFactory.create(branch, {
            isFirstBlockOfDefaultScenario: branch.linkId === chatBot.startBranchLinkId,
            getBranchByLinkId,
          });
        })
      : [];

    const interruptedBranch = branches.find((branch) => branch.linkId === chatBot.interruptBranchLinkId);
    const startedBranch = branches.find((branch) => branch.linkId === chatBot.startBranchLinkId);

    const startBadge = badgeFactory.createStart(
      chatBot,
      triggers,
      openWebPageTriggerAmount,
      openSdkPageTriggerAmount,
      leaveSiteAttemptTrigger,
      startedBranch,
    );
    const interruptBadge = badgeFactory.createInterrupt(chatBot, interruptedBranch);

    const branchForms = branches.map((branch) => branch.form);
    const controls: Controls<BotFormData<T>> = {
      name: new GenericFormControl(chatBot?.name ?? null),
      branches: new GenericFormArray(branchForms),
      startBadge: startBadge.form,
      interruptBadge: interruptBadge.form,
    };
    super(controls, validatorOrOpts, asyncValidator);

    this._branches = branches;
    this._deletedBranchesId = [];
    this.initialData = chatBot;
    this.interruptedBranch = interruptedBranch || null;
    this.startedBranch = startedBranch || null;
    this.allowUserReplies = chatBot.allowUserReplies;

    this.startBadge = startBadge;
    this.interruptBadge = interruptBadge;

    startBadge.targetBranchChange$.pipe(takeUntil(startBadge.destroy$)).subscribe((newStartedBlock) => {
      this.startedBranch = newStartedBlock;
    });

    interruptBadge.targetBranchChange$.pipe(takeUntil(interruptBadge.destroy$)).subscribe((newInterruptBlock) => {
      this.interruptedBranch = newInterruptBlock;
    });
  }

  /**
   * См. ссылку
   * @link _branches
   */
  addBranch(branch: Branch) {
    this._branches = [...this.branches, branch];
    this.controls.branches.push(branch.form);
  }

  public get chatBot(): ChatBot<T> {
    return {
      id: this.initialData.id,
      type: this.initialData.type,
      name: this.controls.name.value,
      branches: this._branches.map((branch) => branch.apiReadyData),
      ...this.startBadge.apiReadyData,
      ...this.interruptBadge.apiReadyData,
      allowUserReplies: this.allowUserReplies,
      triggerTypes: [],
      integration: this.initialData.integration,
      chatWidget: this.initialData.chatWidget,
    };
  }

  /**
   * См. ссылку
   * @link _branches
   */
  deleteBranch(branch: Branch) {
    const index = this._branches.indexOf(branch);
    this.deleteBranchAt(index);
    if (branch.id) {
      this._deletedBranchesId.push(branch.id);
    }
  }

  /**
   * См. ссылку
   * @link _branches
   */
  private deleteBranchAt(index: number) {
    this._branches.splice(index, 1);
    this._branches = [...this._branches];
    this.controls.branches.removeAt(index);
  }

  /**
   * См. ссылку
   * @link _branches
   */
  insertBranchAt(index: number, branch: Branch) {
    this._branches.splice(index, 0, branch);
    this._branches = [...this._branches];
    this.controls.branches.insert(index, branch.form);
  }

  public destroy() {
    for (let i = this._branches.length - 1; i >= 0; i--) {
      this._branches[i].destroy();
    }
    this.startBadge.destroy();
    this.interruptBadge.destroy();
  }

  /**
   * Очистка массива с удаленными ID веток
   */
  public resetDeletedBranchesId(): void {
    this._deletedBranchesId = [];
  }

  areBadgesValid() {
    if (this.controls.interruptBadge.valid && this.controls.startBadge.valid) {
      return true;
    }
    this.controls.startBadge.markAllAsTouched();
    this.controls.interruptBadge.markAllAsTouched();
    return false;
  }

  revalidate(touch: boolean = true) {
    if (touch) {
      this.markAllAsTouched();
    }
    this.updateValueAndValidity();
    this.get('name').updateValueAndValidity();
    this.get('branches').controls.forEach((branchFormGroup) => {
      branchFormGroup.revalidate();
    });
    this.get('startBadge').updateValueAndValidity();
    this.get('interruptBadge').updateValueAndValidity();
  }
}

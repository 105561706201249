import { ChangeDetectorRef, Component, Input } from '@angular/core';

/**
 * Временная (а может быть и нет) замена ng-message
 */
@Component({
  selector: 'cq-validation-message[errorName]',
  styleUrls: ['./validation-message.scss'],
  template: ` <ng-container *ngIf="_show">
    <div class="validation-message">
      <ng-content></ng-content>
    </div>
  </ng-container>`,
})
export class ValidationMessageComponent {
  @Input()
  errorName!: string;

  _show = false;

  set show(value: boolean) {
    this._show = value;
    this.cdr.detectChanges();
  }

  constructor(private cdr: ChangeDetectorRef) {}
}

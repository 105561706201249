<ng-container>
  <div
    class="d-flex"
    [ngClass]="{ 'mb-15': switched }"
  >
    <cq-switch-new
      class="mr-15"
      [ngModel]="switched"
      (ngModelChange)="switchedChange.next($event)"
      [disabled]="disabled"
    ></cq-switch-new>
    <div class="white-space-nowrap">
      <h5 class="d-inline-block font-weight-bold mb-0">{{ heading }}</h5>
      <i
        *ngIf="tooltip"
        class="cqi-sm cqi-question-circle"
        [ngbTooltip]="tooltip"
      ></i>
    </div>
  </div>

  <div
    *ngIf="switched"
    class="pl-50"
  >
    <ng-content></ng-content>
  </div>
</ng-container>

/**
 * Директива для автоматической автофокусировки на элементе
 */
(function () {
  'use strict';

  angular.module('myApp.directives.autofocus').directive('cqAutofocus', cqAutofocus);

  function cqAutofocus($timeout) {
    return {
      restrict: 'A',
      controller: angular.noop,
      link: link,
    };

    function link(scope, element, attrs) {
      // Если элемент удаляется из DOM, например, с помощью ng-if, то в него можно ни чего не передавать и при каждом его появлении будет срабатывать else
      if (angular.isDefined(getEvaluatedFocusValue())) {
        scope.$watch(getEvaluatedFocusValue, watch);
      } else {
        // $timeout нужен чтобы фокус элемента выполнился в следующем дайджест-цикле
        $timeout(function () {
          element[0].focus();
        }, 0);
      }

      function getEvaluatedFocusValue() {
        return scope.$eval(attrs.cqAutofocus);
      }

      function watch(newValue) {
        if (newValue) {
          element[0].focus();
        }
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationService } from '@http/integration/services/integration.service';
import { CarrotquestHelperProvider, TelegramBotStoreProvider } from '@panel/app/ajs-upgraded-providers';
import { SystemToastService } from '@panel/app/services';
import { SharedModule } from '@panel/app/shared/shared.module';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

import { IntegrationDescriptionActionsComponent } from './integration-description-actions.component';

@NgModule({
  declarations: [IntegrationDescriptionActionsComponent],
  exports: [IntegrationDescriptionActionsComponent],
  imports: [CommonModule, TranslocoModule, SharedModule],
  providers: [
    CarrotquestHelperProvider,
    IntegrationService,
    SystemToastService,
    TelegramBotStoreProvider,
    ToastService,
  ],
})
export class IntegrationDescriptionActionsModule {}

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { UtilsServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { DestroyService, OneTimeFlagService } from '@panel/app/services';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';

import { NavigationLogoComponent } from './navigation-logo.component';

@NgModule({
  declarations: [NavigationLogoComponent],
  exports: [NavigationLogoComponent],
  imports: [CommonModule, DirectivesModule, TranslocoModule, UIRouterModule, NgbTooltipModule, HttpClientModule],
  providers: [DestroyService, OneTimeFlagService, UtilsServiceProvider],
})
export class NavigationLogoModule {}

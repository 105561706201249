import { Injectable } from '@angular/core';
import { BaseTexture, Container, Rectangle, Sprite, Texture } from 'pixi.js';

@Injectable()
export class PixiTextureBuilderService {
  /**
   * @param sideTexture текстура, которая будет использоваться в качестве нижней и верхней (как булочка в бургере)
   * @param innerTexture текстура, которая будет использоваться внутри. Обрезаться и/или дублироваться
   *                     в зависимости от запрошенной высоты
   * @param height - необходимая высота текстуры
   */
  static buildBurgerTexture(sideTexture: BaseTexture, innerTexture: BaseTexture, height: number): Container {
    const topSprite = new Sprite(new Texture(sideTexture));
    const bottomSprite = new Sprite(new Texture(sideTexture));
    bottomSprite.pivot.set(bottomSprite.width, bottomSprite.height / 2);
    bottomSprite.angle = 180;

    const innerSprites: Sprite[] = [];

    const innerHeight = height - sideTexture.height * 2;

    const numberOfFullTextures = Math.floor(innerHeight / innerTexture.height);

    for (let i = 0; i < numberOfFullTextures; i++) {
      const innerSprite = new Sprite(new Texture(innerTexture));
      innerSprites.push(innerSprite);
    }

    const remainder = innerHeight % innerTexture.height;
    if (remainder > 0) {
      const heightMultiplier = remainder / innerTexture.height;
      const remainderTexture = new Texture(
        innerTexture,
        new Rectangle(0, 0, innerTexture.width, innerTexture.height * heightMultiplier),
      );
      innerSprites.push(new Sprite(remainderTexture));
    }

    const container = new Container();

    container.addChild(topSprite);

    let lastY = topSprite.height;
    innerSprites.forEach((sprite) => {
      container.addChild(sprite);
      sprite.position.y = lastY;
      lastY = lastY + sprite.height;
    });
    bottomSprite.position.y = lastY + bottomSprite.height / 2;
    container.addChild(bottomSprite);

    return container;
  }

  /**
   * В целом основной принцип как у
   * @link buildBurgerTexture
   * но текстура может обрезаться по ширине (не может становиться шире основной текстуры)
   *
   *
   * @param sideTexture текстура, которая будет использоваться в качестве нижней и верхней (как булочка в бургере)
   * @param innerTexture текстура, которая будет использоваться внутри. Обрезаться и/или дублироваться
   *                     в зависимости от запрошенной высоты
   * @param width - необходимая ширина текстуры
   * @param height - необходимая высота текстуры
   */
  static buildBurgerTextureWithWidthReduced(
    sideTexture: BaseTexture,
    innerTexture: BaseTexture,
    width: number,
    height: number,
  ): Container {
    if (width > innerTexture.width) {
      throw new Error(`The new textures width could not be bigger than the source texture`);
    }

    // Собираем угловые текстуры
    const topLeftSideTexture = new Texture(sideTexture, new Rectangle(0, 0, width / 2, sideTexture.height));

    const topRightSideTexture = new Texture(
      sideTexture,
      new Rectangle(sideTexture.width - width / 2, 0, width / 2, sideTexture.height),
    );

    const bottomLeftSideTexture = new Texture(
      sideTexture,
      new Rectangle(sideTexture.width - width / 2, 0, width / 2, sideTexture.height),
      undefined,
      undefined,
      4,
    );

    const bottomRightSideTexture = new Texture(
      sideTexture,
      new Rectangle(0, 0, width / 2, sideTexture.height),
      undefined,
      undefined,
      4,
    );

    // Собираем внутренние текстуры
    const innerLeftBaseTexture = new Texture(innerTexture, new Rectangle(0, 0, width / 2, innerTexture.height));

    const innerRightBaseTexture = new Texture(
      innerTexture,
      new Rectangle(innerTexture.width - width / 2, 0, width / 2, innerTexture.height),
    );

    // Создали спрайты из угловых текстур
    const topLeftSprite = new Sprite(topLeftSideTexture);
    const topRightSprite = new Sprite(topRightSideTexture);
    const bottomLeftSprite = new Sprite(bottomLeftSideTexture);
    const bottomRightSprite = new Sprite(bottomRightSideTexture);

    // Создаем внутренние спрайты
    const innerSprites: [Sprite, Sprite][] = [];

    const innerHeight = height - sideTexture.height * 2;

    const numberOfFullTextures = Math.floor(innerHeight / innerLeftBaseTexture.height);

    for (let i = 0; i < numberOfFullTextures; i++) {
      const innerLeftSprite = new Sprite(innerLeftBaseTexture);
      const innerRightSprite = new Sprite(innerRightBaseTexture);
      innerSprites.push([innerLeftSprite, innerRightSprite]);
    }

    const remainder = innerHeight % innerLeftBaseTexture.height;
    if (remainder > 0) {
      const heightMultiplier = remainder / innerLeftBaseTexture.height;
      const remainderLeftTexture = new Texture(
        innerLeftBaseTexture.baseTexture,
        new Rectangle(0, 0, innerLeftBaseTexture.width, innerLeftBaseTexture.height * heightMultiplier),
      );
      const remainderRightTexture = new Texture(
        innerRightBaseTexture.baseTexture,
        new Rectangle(
          innerTexture.width - width / 2,
          0,
          innerRightBaseTexture.width,
          innerRightBaseTexture.height * heightMultiplier,
        ),
      );
      innerSprites.push([new Sprite(remainderLeftTexture), new Sprite(remainderRightTexture)]);
    }

    const container = new Container();

    // Собираем в одну картинку
    container.addChild(topLeftSprite);
    container.addChild(topRightSprite);
    topRightSprite.position.set(topLeftSprite.width, 0);

    let lastY = topLeftSprite.height;
    innerSprites.forEach(([leftSprite, rightSprite]) => {
      container.addChild(leftSprite);
      container.addChild(rightSprite);
      leftSprite.position.y = lastY;
      rightSprite.position.set(leftSprite.width, lastY);
      lastY = lastY + leftSprite.height;
    });
    bottomLeftSprite.position.y = lastY;
    bottomRightSprite.position.set(bottomLeftSprite.width, lastY);
    container.addChild(bottomLeftSprite);
    container.addChild(bottomRightSprite);

    return container;
  }

  static buildIconTextureWithBackground(circleBaseTexture: BaseTexture, iconBaseTexture: BaseTexture): Container {
    const container = new Container();
    const circleTexture = new Texture(circleBaseTexture);
    const iconTexture = new Texture(iconBaseTexture);
    const circleSprite = new Sprite(circleTexture);
    const iconSprite = new Sprite(iconTexture);

    iconSprite.position.set((circleSprite.width - iconSprite.width) / 2, (circleSprite.height - iconSprite.height) / 2);

    container.addChild(circleSprite, iconSprite);

    return container;
  }
}

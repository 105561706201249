import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

/** Компонент для работы с периодом оплаты */
@Component({
  selector: 'cq-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodComponent {
  constructor(protected readonly subscriptionStore: SubscriptionStore) {}
}

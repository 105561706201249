import { AbstractControl, ValidationErrors } from '@angular/forms';

//NOTE Регулярное выражение взято из Validators.email
// (https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address) и отредактировано
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

/**
 * Валидация емейлов
 *
 * NOTE Стандартная Validators.email валидирует емейлы, которые наш бек не пропускает.
 *  Может пропустить email вида **name@mail**
 * @param control
 */
export function emailValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value == null || control.value.length === 0) {
    return null;
  }
  return EMAIL_REGEX.test(control.value) ? null : { email: true };
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { WHATS_APP_EDNA_API_ERRORS } from '@http/integration/integrations/whats-app-edna/constants/whats-app-edna-integration.constants';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-whats-app-edna-cascade-id',
  templateUrl: './cascade-id.component.html',
  styleUrls: ['./cascade-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsAppEdnaCascadeIdComponent extends AbsCVAFormControlBasedComponent<number | null> {
  readonly control: FormControl<number | null> = new FormControl<number | null>(null, {
    nonNullable: false,
  });

  readonly WHATS_APP_EDNA_API_ERRORS = WHATS_APP_EDNA_API_ERRORS;

  constructor(private readonly featureModel: FeatureModel) {
    super();

    if (this.featureModel.hasAccess(FEATURES_ONLY.NEW_EDNA_API)) {
      this.control.addValidators(Validators.required);
    }
  }
}

/**
 * Контроллер для предпросмотра изображений
 */
(function () {
  'use strict';

  angular
    .module('myApp.modals.picturePreview')
    .controller('PicturePreviewModalController', PicturePreviewModalController);

  function PicturePreviewModalController($translate, $uibModalInstance, pictureUrl) {
    var vm = this;

    vm.pictureUrl = pictureUrl;
  }
})();

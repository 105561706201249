import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';

import { TeamMember } from '@http/team-member/team-member.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

import { ActionValidationExtra, BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

export function removedOperator(operators: TeamMember[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return operators.map((c) => c.id).includes(control.value) ? null : { operatorRemoved: { value: control.value } };
  };
}
const getOperatorBodyValidators = (teamMembers: TeamMember[]): ValidatorFn[] => [
  Validators.required,
  removedOperator(teamMembers),
];

export class BotOperatorActionForm extends BaseBotActionForm {
  get allTouchedChanges$() {
    return extractTouchedChanges(this.controls.body).pipe(startWith(this.controls.body.touched));
  }

  getCustomControls(action: ChatBotAction, validationExtra: ActionValidationExtra): CustomControlsWithBodyJsonRequired {
    return {
      body: new GenericFormControl(action.body || null, getOperatorBodyValidators(validationExtra.teamMembers)),
      bodyJson: new GenericFormGroup({}),
    };
  }
}

/**
 * Директива, которая ограничвает ввод в input денежной суммой (например, 125,95)
 */
(function () {
  'use strict';

  angular.module('myApp.directives.number').directive('cqMoney', cqMoney);

  function cqMoney() {
    return {
      priority: 1000,
      restrict: 'A',
      require: 'ngModel',
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      /**
       * Регулярное выражение для денежных едениц
       * Денежная еденица - float с минусом или без в начале, и, если введена запятая, с двумя числами после неё
       *
       * @type {RegExp}
       */
      var MONEY_REGEXP = /^\-?(\d+)(,\d{1,2})?$/;

      controller.$parsers.push(parser);
      controller.$validators.money = money;
      controller.$formatters.push(formatter);

      /**
       * Конвертация значения из модели (с точнкой) в значение в представлении (с запятой, так людям привычнее)
       *
       * @param modelValue Значение из модели
       * @returns {String} Значение с заменённой точкой
       */
      function formatter(modelValue) {
        if (angular.isDefined(modelValue)) {
          return modelValue.toString().replace('.', ',');
        } else {
          return modelValue;
        }
      }

      /**
       * Валидация денег
       *
       * @param modelValue
       * @param viewValue
       * @returns {Boolean}
       */
      function money(modelValue, viewValue) {
        // делаем валидацию только тогда, когда значение, которое будет записано в модель не пустое и не NaN
        return (controller.$isEmpty(modelValue) && !isNaN(modelValue)) || MONEY_REGEXP.test(viewValue);
      }

      /**
       * Парсер, который не даёт вводить ничего кроме чисел, знака минуса и запятой
       * Минус можно вводить только вначале числа
       * Если введена хотя бы одна запятая - вторую вводить уже нельзя
       *
       * @param viewValue Значение из предсталения
       * @returns {*}
       */
      function parser(viewValue) {
        if (viewValue) {
          var resultValue;
          var digitValue = viewValue;

          var isComma = digitValue.indexOf(',') != -1;
          var isMinus = digitValue.indexOf('-') == 0;

          if (isMinus) {
            digitValue = digitValue.substr(1);
          }

          digitValue = digitValue.replace(/[^\d,]/g, '');
          var array = digitValue.split(',');
          if (array[1]) {
            array[1] = array[1].substr(0, 2);
            resultValue = array[0] + ',' + array[1];
          } else {
            if (isComma) {
              resultValue = array[0] + ',';
            } else {
              resultValue = array[0];
            }
          }
          if (isMinus) {
            resultValue = '-' + resultValue;
          }

          if (resultValue != viewValue) {
            controller.$setViewValue(resultValue);
            controller.$render();
          }

          // в модель должен быть записан float
          return parseFloat(resultValue.replace(',', '.'));
        } else {
          // если viewValue - пустая строка
          return viewValue;
        }
      }
    }
  }
})();

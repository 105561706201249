import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

@Component({
  selector:
    'cq-switcher[rightValue][leftValue][formControlName],cq-switcher[rightValue][leftValue][formControl],cq-switcher[rightValue][leftValue][ngModel]',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitcherComponent),
      multi: true,
    },
  ],
})
export class SwitcherComponent implements OnInit, ControlValueAccessor {
  @Input()
  rightValue: unknown; // Значение, которое запишется в модель, при переключении свичера в правое положение

  @Input()
  leftValue: unknown; // Значение, которое запишется в модель, при переключении свичера в левое положение

  @ContentChild('rightText')
  rightText: TemplateRef<unknown> | null = null; // Текст для отображения справа от свитчера

  @ContentChild('leftText')
  leftText: TemplateRef<unknown> | null = null; // Текст для отображения слева от свитчера

  switcherControl = new UntypedFormControl();

  constructor() {}

  ngOnInit() {
    this.switcherControl.valueChanges.subscribe((value) => this.onChange(value));
  }

  onChange = (v: string) => {};

  onTouched = () => {};

  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.switcherControl.disable();
    } else {
      this.switcherControl.enable();
    }
  }

  writeValue(value: string): void {
    this.switcherControl.setValue(value, { emitEvent: false });
  }

  /**
   * Отметить контрол формы как touched
   */
  markAsTouched(): void {
    if (this.switcherControl.untouched && this.switcherControl.enabled) {
      this.onTouched();
      this.switcherControl.markAsTouched();
    }
  }

  get isDisabled(): boolean {
    return this.switcherControl.disabled;
  }
}

<label
  [ngClass]="{ disabled: isDisabled }"
  (click)="markAsTouched()"
  data-test="switcher-label"
>
  <input
    [formControl]="switcherControl"
    trueFalseValue
    [falseValue]="leftValue"
    [trueValue]="rightValue"
    type="checkbox"
  />
  <span class="left-slot">
    <ng-template [ngTemplateOutlet]="leftText"></ng-template>
  </span>
  <div></div>
  <span class="right-slot">
    <ng-template [ngTemplateOutlet]="rightText"></ng-template>
  </span>
</label>

(function () {
  'use strict';

  require('./instagram-oauth.controller');

  angular.module('myApp.integrations').component('cqInstagramOAuth', cqInstagramOAuth());

  function cqInstagramOAuth() {
    return {
      controller: 'CqInstagramOAuthController',
      controllerAs: 'vm',
    };
  }
})();

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, merge } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { AUTO_EVENTS_GROUPS } from '@http/track-master/track-master.constants';
import { AutoEvent, TrackMasterModel } from '@http/track-master/track-master.model';
import {
  CREATE_AUTO_EVENT_MODAL_DATA_TOKEN,
  CreateAutoEventModalData,
} from '@panel/app/partials/modals/create-auto-event-modal/create-auto-event-modal.token';
import { CreateAutoEventModalHelperService } from '@panel/app/partials/modals/create-auto-event-modal/services/create-auto-event-modal-helper.service';
import { SelectableEventType } from '@panel/app/partials/track-master/event-type-selector/event-type-selector.component';
import { PageUrlForm } from '@panel/app/partials/track-master/page-url/page-url.component';
import { AutoEventsDuplicatesHelperService } from '@panel/app/partials/track-master/services/duplicates-helper.service';
import { UrlWorksOnForm } from '@panel/app/partials/track-master/works-on-page/works-on-page.component';
import { WritePropertyValueForm } from '@panel/app/partials/track-master/write-property-value/write-property-value.component';
import { DestroyService } from '@panel/app/services';
import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';

/**
 * Форма собрана из типов, с которыми оперируют отдельные компоненты.
 * Например, тип pageUrl, это тип CVA контрола компонента PageUrlComponent
 */
export type AutoEventModalForm = {
  name: string;
  type: SelectableEventType;
  cssSelector: string;
  worksOnPage: UrlWorksOnForm;
  pageUrl: PageUrlForm;
  writeProperty: string;
  selectorsProperties: WritePropertyValueForm[];
  textProperties: WritePropertyValueForm[];
};

@Component({
  selector: 'cq-create-auto-event-modal',
  templateUrl: './create-auto-event-modal.component.html',
  styleUrls: ['./create-auto-event-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, AutoEventsDuplicatesHelperService],
})
export class CreateAutoEventModalComponent {
  AUTO_EVENTS_GROUPS = AUTO_EVENTS_GROUPS;

  form = this.fb.group<AutoEventModalForm>({
    name: null,
    type: null,
    cssSelector: null,
    worksOnPage: {
      enabled: false,
      url: null,
      hasSubpages: false,
    },
    pageUrl: {
      url: null,
      hasSubpages: false,
    },
    writeProperty: null,
    selectorsProperties: [],
    textProperties: [],
  });

  /**
   * Открыта/закрыта выпадашка с доп. свойствами
   */
  extraSettingsCollapsed: boolean = true;

  eventDuplicates$ = merge(
    this.form.controls.name.valueChanges,
    this.form.controls.cssSelector.valueChanges,
    this.form.controls.worksOnPage.valueChanges,
    this.form.controls.pageUrl.valueChanges,
  ).pipe(
    debounceTime(500),
    map(() => {
      const autoEvent = this.modalHelperService.formDataToAutoEvent(this.form.value);
      return this.autoEventsDuplicatesHelper.getDuplicates(autoEvent, this.data.autoEvents);
    }),
    takeUntil(this.destroy$),
  );

  constructor(
    private readonly autoEventsDuplicatesHelper: AutoEventsDuplicatesHelperService,
    @Inject(TrackMasterModel)
    private readonly trackMasterModel: TrackMasterModel,
    @Inject(NgbActiveModal)
    public readonly activeModal: NgbActiveModal,
    @Inject(UntypedFormBuilder)
    private readonly fb: GenericFormBuilder,
    @Inject(CREATE_AUTO_EVENT_MODAL_DATA_TOKEN)
    public readonly data: CreateAutoEventModalData,
    private readonly appService: AppService,
    @Inject(CreateAutoEventModalHelperService)
    private readonly modalHelperService: CreateAutoEventModalHelperService,
    private readonly destroy$: DestroyService,
  ) {}

  /**
   * Создаем ивент если форма валидна и закрываем модалку с передачей созданного ивента
   */
  create() {
    const autoEvent = this.modalHelperService.formDataToAutoEvent(this.form.value);

    if (!this.form.valid || this.autoEventsDuplicatesHelper.hadDuplicatesLastTime) {
      return this.form.markAllAsTouched();
    }

    firstValueFrom(this.trackMasterModel.create(this.appService.currentAppId, autoEvent)).then((response) => {
      this.activeModal.close(response);
    });
  }

  /**
   * Выбор события из существющих
   * @param autoEvent - Автособытие
   */
  selectEvent(autoEvent: AutoEvent) {
    const eventType = this.data.eventTypes.find((eventType) => eventType.name === autoEvent.name);

    if (!eventType) {
      throw new Error('No such event');
    }

    this.activeModal.close({
      autoEvent,
      eventType,
    });
  }

  /**
   * Проверка текущего типа евента формы
   */
  is(...types: AUTO_EVENTS_GROUPS[]): boolean {
    return types.includes(this.form.controls.type.value);
  }

  /**
   * Callback на смену типа события.
   * Очищаем все контролы.
   */
  onEventTypeChange(type: SelectableEventType) {
    this.form.reset({
      name: null,
      type,
      cssSelector: null,
      worksOnPage: {
        enabled: false,
        url: null,
        hasSubpages: false,
      },
      pageUrl: {
        url: null,
        hasSubpages: false,
      },
      writeProperty: null,
      selectorsProperties: [],
      textProperties: [],
    });
  }
}

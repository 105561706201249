(function () {
  'use strict';

  angular
    .module('myApp.modals.doubleOptInSubscriptionConfirmPreview')
    .controller(
      'DoubleOptInSubscriptionConfirmPreviewModalController',
      DoubleOptInSubscriptionConfirmPreviewModalController,
    );

  function DoubleOptInSubscriptionConfirmPreviewModalController(currentApp, logo) {
    var vm = this;

    vm.currentApp = currentApp;
    vm.logo = logo;
  }
})();

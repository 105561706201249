import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { EventSelectModule } from '@panel/app/partials/filters/components/event-select/event-select.module';
import { EventTypeSelectModule } from '@panel/app/partials/filters/components/event-type-select/event-type-select.module';
import { OperationSelectModule } from '@panel/app/partials/filters/components/operation-select/operation-select.module';
import { PropertySelectModule } from '@panel/app/partials/filters/components/property-select/property-select.module';

import { EventFilterComponent } from './event-filter.component';

@NgModule({
  declarations: [EventFilterComponent],
  exports: [EventFilterComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    OperationSelectModule,
    PropertySelectModule,
    ReactiveFormsModule,
    EventSelectModule,
    EventTypeSelectModule,
    NgbDropdownModule,
  ],
})
export class EventFilterModule {}

(function () {
  'use strict';

  angular.module('myApp.messageTemplates').constant('MESSAGE_TEMPLATES_TABS', getMessageTemplatesTabs());

  /**
   * Вкладки настроек
   *
   * @return {Object}
   */
  function getMessageTemplatesTabs() {
    return {
      SAVED_TEMPLATES: 'savedTemplates',
      TEMPLATE_GALLERY: 'templateGallery',
    };
  }
})();

<ng-container *transloco="let t; read: 'dateTimeRangePickerComponent'">
  <div class="d-flex p-10 margin-between-cols-10">
    <div
      *ngIf="options"
      class="margin-between-rows-10"
    >
      <div
        *ngIf="options.showRangeNavigation"
        class="d-flex flex-column margin-between-rows-10"
      >
        <button
          *ngFor="let range of rangeList"
          class="btn btn-borderless-primary text-left"
          (click)="goToRange(range)"
        >
          {{ 'datetimePicker.daterange.' + range | transloco }}
        </button>
      </div>

      <ng-content select="[slot=applyButtons]"></ng-content>
    </div>

    <ng-container
      [ngTemplateOutlet]="datepickerTemplate"
      [ngTemplateOutletContext]="{
        initialDate: fromControl.value,
        dateType: 'from'
      }"
    ></ng-container>

    <ng-container
      [ngTemplateOutlet]="datepickerTemplate"
      [ngTemplateOutletContext]="{
        initialDate: toControl.value,
        dateType: 'to'
      }"
    ></ng-container>
  </div>

  <ng-template
    #datepickerTemplate
    let-initialDate="initialDate"
    let-dateType="dateType"
  >
    <div class="d-flex flex-column">
      <div class="has-feedback has-feedback-left mb-10">
        <span class="form-control-feedback">
          <i class="btn-icon cqi-sm cqi-calendar-o"></i>
        </span>
        <input
          class="form-control"
          type="text"
          [ngModel]="getDisplayDate(dateType)"
          (blur)="onBlurInput($event, dateType)"
        />
      </div>

      <ngb-datepicker
        #datepicker
        class="ngb-custom-date-picker p-0"
        navigation="none"
        (dateSelect)="onSelectDate($event)"
        [startDate]="initialDate"
        [dayTemplate]="day"
        [maxDate]="maxDateCache"
      >
        <ng-template ngbDatepickerContent>
          <cq-datepicker-month-navigation
            [datepicker]="datepicker"
            [initialDate]="initialDate"
          ></cq-datepicker-month-navigation>

          <div *ngFor="let month of datepicker.state.months">
            <ngb-datepicker-month
              class="border rounded"
              [month]="month"
            ></ngb-datepicker-month>
          </div>
        </ng-template>
      </ngb-datepicker>
    </div>

    <ng-template
      #day
      let-date
      let-focused="focused"
    >
      <div
        class="date-range-picker-day-view"
        [class.focused]="focused"
        [class.range]="isRangeDate(date)"
        [class.hover]="date === hoveredDate"
        [class.start-date]="isStartDate(date)"
        [class.end-date]="isEndDate(date)"
        [class.outside]="isOutside(datepicker.state, date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </div>
    </ng-template>
  </ng-template>
</ng-container>

<ng-container *transloco="let t; read: 'manageTagsComponent'">
  <ng-container *ngIf="tags$.isEmpty$ | async">
    <cq-tag-list-empty></cq-tag-list-empty>
  </ng-container>

  <ng-container *ngIf="tags$.isNotEmpty$ | async">
    <div class="flex justify-content-between px-20 py-15">
      <cq-search
        [searchPhrase]="searchPhrase"
        (inputSearchPhrase)="onSearch($event)"
        (resetSearch)="onResetSearch()"
      ></cq-search>

      <cq-tag-create (intentToCreateTag)="onIntentToCreateTag()"></cq-tag-create>
    </div>

    <ng-container *ngIf="tags$.elements$ | async">
      <ng-container *ngIf="!tags$.isEmpty()">
        <cq-tag-list
          [list]="tags$.toArray()"
          (intentToRemoveTag)="onIntentToRemoveTag($event)"
        ></cq-tag-list>
      </ng-container>

      <ng-container *ngIf="tags$.isEmpty()">
        <cq-tag-list-not-found (resetSearch)="onResetSearch()"></cq-tag-list-not-found>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-vk-forms-webhook-url[webhookUrl]',
  templateUrl: './vk-forms-webhook-url.component.html',
  styleUrls: ['./vk-forms-webhook-url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class VkFormsWebhookUrlComponent implements OnInit {
  /** Адрес для вставки в настройки Callback API */
  @Input() webhookUrl!: string;

  constructor(
    private readonly clipboardService: ClipboardService,
    private readonly destroy$: DestroyService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    // Подписываемся на событие копирования
    this.clipboardService.copyResponse$.pipe(takeUntil(this.destroy$)).subscribe((response: IClipboardResponse) => {
      if (response.isSuccess && response.content === this.webhookUrl) {
        this.onCopyUrlSuccess();
      }
    });
  }

  /** Копирование адреса сервера в буфер обмена */
  copyUrl() {
    this.clipboardService.copy(this.webhookUrl);
  }

  /** Callback на успешное копирование адреса сервера в буфер обмена */
  onCopyUrlSuccess() {
    this.toastService.success(this.translocoService.translate('general.linkCopied'));
  }
}

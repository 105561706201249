/** Компонент модального окна отписки устройств пользователя от Web Push */
(function () {
  'use strict';

  angular.module('myApp.modals.unsubscribeWebPush').component('cqUnsubscribeWebPushModal', cqUnsubscribeWebPushModal());

  require('./unsubscribe-web-push.controller');

  function cqUnsubscribeWebPushModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // {Object} subscriptions - Список подписок Web Push
        // {Object} userId - ID пользователя
      },
      controller: 'CqUnsubscribeWebPushModalController',
      controllerAs: 'vm',
      template: require('./unsubscribe-web-push.html'),
    };
  }
})();

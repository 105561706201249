import { Directive, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';

import { GenericAbstractControl } from '@panel/app/shared/abstractions/deprecated/generic-abstract-control';

/**
 * @deprecated использовалось с нетипизированными формами, более не используется
 * Presents form as FormControl, value is an object
 */
@Directive()
export abstract class AbstractCVAControl<T> implements ControlValueAccessor, Validator, OnDestroy, OnInit {
  /**
   * Реализуем дестрой на уровне абстрактного класса, чтоб не приходилось каждый раз передавать $destroy снаружи
   */
  private readonly destroySubj = new Subject<void>();

  private readonly ngControl: NgControl;

  abstract readonly control: GenericAbstractControl<T>;

  constructor(ngControl: NgControl | null, private readonly zone: NgZone) {
    if (!ngControl) {
      throw new Error(
        `NgControl not injected in ${this.constructor.name}!\n
         Use [(ngModel)] or [formControl] or formControlName for correct work.`,
      );
    }
    this.ngControl = ngControl;
    ngControl.valueAccessor = this;
    this.syncExternalTouched(zone, ngControl);
  }

  ngOnInit() {
    this.syncInternalValidity(this.ngControl);
    this.syncInternalChanges();
    this.syncInternalTouched();
  }

  ngOnDestroy() {
    this.destroySubj.next();
    this.destroySubj.complete();
  }

  protected onChange: Function = EMPTY_FUNCTION;

  protected onTouched: Function = EMPTY_FUNCTION;

  /**
   * Записывает value установленное программно.
   * В ситуации, когда:
   *  + значение передается через [(ngModel)]
   *  + контрол - FormGroup
   * writeValue сначала вызовется с val = null, что сломает "this.control.setValue", поэтому добавляем отдельно первую проверку
   *
   * Наверн лучше разделить это на разные классы для CVA на основе FormControl/FormGroup/FormArray, но не сейчас
   */
  writeValue(val: any): void {
    if (this.control instanceof UntypedFormGroup && val === null) {
      return;
    }

    if (val !== undefined) {
      this.control.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  setTouchedState(isTouched: boolean): void {
    isTouched ? this.control.markAllAsTouched() : this.control.markAsUntouched();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return c.valid
      ? null
      : {
          [String(this.ngControl.name)]: {
            value: this.control.value,
            message: `Nested AbstractControl is invalid`,
          },
        };
  }

  /**
   * "Передает" изменения touched внешнего контрола внутреннему
   */
  private syncExternalTouched(zone: NgZone, ngControl: NgControl) {
    zone.onStable
      .pipe(
        map(() => ngControl.touched),
        distinctUntilChanged(),
        takeUntil(this.destroySubj),
        filter((v): v is boolean => ngControl.touched !== null),
      )
      .subscribe((val) => {
        this.setTouchedState(val);
      });
  }

  /**
   * "Передает" изменения валидости внутреннего контрола вшенгему
   */
  private syncInternalValidity(ngControl: NgControl) {
    this.control.statusChanges.pipe(startWith(this.control.status), takeUntil(this.destroySubj)).subscribe(() => {
      // Проверять наличие ошибок нужно, чтоб не переписать уже отработавшие ошибки на уровне control'a из компонента,
      // который использует этот CVA
      // 2 ошибки одновременно не показываются все равно, так что выставлять ошибки отсюда можно только когда там уже все починено
      if (ngControl.control) {
        const errors = this.validate(this.control);
        ngControl.control.setErrors(errors);
      }
    });
  }

  /**
   * "Передает" изменения value внутреннего контрола вшенгему
   */
  protected syncInternalChanges() {
    this.control.valueChanges.pipe(takeUntil(this.destroySubj)).subscribe((value: any) => this.onChange(value));
  }

  /**
   * "Передает" изменения touched внутреннего контрола вшенгему
   */
  private syncInternalTouched() {
    this.zone.onStable
      .pipe(
        map(() => this.control.touched),
        distinctUntilChanged(),
        takeUntil(this.destroySubj),
        filter((v) => v),
      )
      .subscribe(() => this.onTouched());
  }
}

import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';

(function () {
  'use strict';

  angular.module('myApp.teamMembers').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.teamMembers', {
        url: '/admins?{member_invitation}',
        redirectTo: 'app.content.teamMembers.table',
        template:
          '\
          <section id="content">\
            <div class="container-fluid limited-container" ui-view></div>\
          </section>\
        ',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
      })

      .state('app.content.teamMembers.table', {
        url: '',
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          memberInvitations: 'memberInvitations',
          teamMembers: 'teamMembers',
          teamMembersGroups: 'teamMembersGroups',
        },
        component: 'cqTeamMembersTableWrapper',
        resolve: {
          memberInvitations: getMemberInvitations,
          teamMembers: getTeamMembers,
          teamMembersGroups: getTeamMembersGroups,
        },
      });

    /**
     * Получение приглашений членов команды
     */
    function getMemberInvitations(memberInvitationsService) {
      return firstValueFrom(memberInvitationsService.getList());
    }

    /**
     * Получение членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
    }

    /**
     * Получение групп членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembersGroups($transition$, currentApp) {
      let teamMemberGroupModel = $transition$.injector().get('teamMemberGroupModel');

      return firstValueFrom(teamMemberGroupModel.getList(currentApp.id, true, true));
    }
  }
})();

<ng-container *transloco="let t">
  <div class="margin-bottom-15 text-secondary">
    {{ t('chatBot.botActionAmocrmNotification.amocrmNotificationSelect.label') }}
  </div>
  <div class="row">
    <div [ngClass]="{ 'col-lg-10 padding-right-5': isNoteEditButtonVisible, 'col-lg-12': !isNoteEditButtonVisible }">
      <div
        class="form-group no-margin-bottom"
        [cqShowError]="integrationControl"
      >
        <ng-select
          (change)="openAmocrmLeadSettingsModal($event)"
          [classList]="['ng-touched']"
          [clearable]="false"
          [cqShowError]="integrationControl"
          [formControl]="integrationControl"
          [items]="amocrmIntegrations"
          bindLabel="name"
          bindValue="id"
          placeholder="{{ t('chatBot.botActionAmocrmNotification.amocrmNotificationSelect.placeholder') }}"
        >
          <ng-template
            ng-label-tmp
            ng-option-tmp
            let-item="item"
          >
            <i
              class="cqi-sm cqi-triangle-exclamation-o text-warning margin-right-5"
              *ngIf="!item.active"
              [ngbTooltip]="t('chatBot.botActionAmocrmNotification.pausedIntegrationTooltip')"
              placement="top"
              container="body"
            ></i>
            <span [ngClass]="{ 'text-muted': !item.active }">{{ item.name }}</span>
          </ng-template>
        </ng-select>
        <cq-validation-messages [control]="integrationControl">
          <cq-validation-message errorName="required">
            {{ t('chatBot.botActionAmocrmNotification.amocrmNotificationSelect.errors.required') }}
          </cq-validation-message>
          <cq-validation-message errorName="inactiveIntegration">
            {{ t('chatBot.botActionAmocrmNotification.amocrmNotificationSelect.errors.inactiveIntegration') }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
    </div>
    <div
      *ngIf="isNoteEditButtonVisible"
      class="col-lg-2 no-padding-left"
    >
      <button
        class="btn btn-outline-primary"
        (click)="openAmocrmLeadSettingsModal(selectedIntegration)"
        [ngbTooltip]="t('chatBot.botActionAmocrmNotification.editNoteTooltip')"
        placement="top-right"
      >
        <i class="cqi-sm cqi-cog"></i>
      </button>
    </div>
  </div>
</ng-container>

/** Сервис для работы с модальным окном "Закончилась квота" */
(function () {
  'use strict';

  angular
    .module('myApp.services.limitOfUniqueVisitorsModalService')
    .service('limitOfUniqueVisitorsModalService', limitOfUniqueVisitorsModalService);

  function limitOfUniqueVisitorsModalService($uibModal) {
    /** Инстанс модального окна */
    let limitOfUniqueVisitorsModalServiceInstance;

    return {
      hideModal: hideModal,
      showModal: showModal,
    };

    /** Скрытие модального окна */
    function hideModal() {
      if (limitOfUniqueVisitorsModalServiceInstance) {
        limitOfUniqueVisitorsModalServiceInstance.close();
        limitOfUniqueVisitorsModalServiceInstance = null;
      }
    }

    /**
     * Открытие модального окна
     *
     * @param {Object=} currentApp - Текущее приложение
     * @param {Object} djangoUser - Текущий django-пользователь
     * @param {Object} billingInfo - Информация о биллинге
     * @param {Object} appBlocks - Информация о блокировках App'а
     */
    function showModal(currentApp, djangoUser, billingInfo, appBlocks) {
      if (!limitOfUniqueVisitorsModalServiceInstance) {
        limitOfUniqueVisitorsModalServiceInstance = $uibModal.open({
          component: 'cqLimitOfUniqueVisitorsModal',
          keyboard: false,
          resolve: {
            appBlocks: angular.bind(null, angular.identity, appBlocks),
            billingInfo: angular.bind(null, angular.identity, billingInfo),
            currentApp: angular.bind(null, angular.identity, currentApp),
            djangoUser: angular.bind(null, angular.identity, djangoUser),
          },
          backdrop: 'static',
          size: 'sm modal-dialog-centered',
          windowClass: 'limit-of-unique-visitors',
        });

        limitOfUniqueVisitorsModalServiceInstance.result.finally(() => {
          limitOfUniqueVisitorsModalServiceInstance = null;
        });
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AjsDisplaySafehtmlComponent } from '@panel/app/shared/components/ajs-display-safehtml/ajs-display-safehtml.component';

@NgModule({
  declarations: [AjsDisplaySafehtmlComponent],
  exports: [AjsDisplaySafehtmlComponent],
  imports: [CommonModule],
})
export class AjsDisplaySafehtmlModule {}

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmModalCreateLeadTagParams } from '@panel/app/pages/integrations/content/amocrm/modal-create-lead-tag/amocrm-modal-create-lead-tag.types';

/**
 * Компонент модального окна для создания тега сделки AmoCRM
 */

@Component({
  selector: 'cq-modal-create-lead-tag',
  templateUrl: './amocrm-modal-create-lead-tag.component.html',
  styleUrls: ['./amocrm-modal-create-lead-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmModalCreateLeadTagComponent implements OnInit {
  /** Параметры модального окна для создания тега сделки AmoCRM */
  @Input()
  modalWindowParams?: AmocrmModalCreateLeadTagParams;

  /** Контрол для работы с тегом сделки AmoCRM */
  controlLeadTag: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);

  /** Получение переведённого названия интеграции */
  get integrationTypeName() {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    @Inject(NgbActiveModal) private readonly ngbActiveModal: NgbActiveModal,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
  ) {}

  ngOnInit(): void {
    if (this.modalWindowParams?.leadTag) {
      this.controlLeadTag.setValue(this.modalWindowParams.leadTag);
    }
  }

  /** Обработчик клика по кнопке создания тега */
  onClickCreateButton(): void {
    if (this.controlLeadTag.valid) {
      this.ngbActiveModal.close(this.controlLeadTag.value);
    } else {
      this.controlLeadTag.markAsTouched();
    }
  }

  /** Обработчик клика по кнопке закрытия модального окна */
  onClickCloseModalButton(): void {
    this.ngbActiveModal.close();
  }

  /** Обработчик клика по кнопке отмены модального окна */
  onClickDismissModalButton(): void {
    this.ngbActiveModal.dismiss();
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  inject,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';
import { MODAL_DATA_TOKEN, ModalData } from '@panel/app/shared/modals/modal/modal.token';

/** Компонент для работы с модальным окном */
@Component({
  selector: 'cq-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit, AfterViewInit {
  /** Инстанс модального окна, если был вызов из AngularJS */
  @Input()
  ajsInstance?: any;

  /** Данные модального окна */
  @Input()
  data?: ModalData;
  _data!: ModalData;

  /** Ref на template в который будет помещаться динамически созданный компонент */
  @ViewChild('dynamicRef', { read: ViewContainerRef }) dynamicAreaRef!: ViewContainerRef;

  /** Ref на динамически созданный компонент */
  dynamicComponentRef!: ComponentRef<any>;

  /** Возвращает компонент, который необходимо динамически создать */
  get component(): any {
    return this._data.component;
  }

  /** Возвращает заголовок */
  get title(): string {
    return this._data.title;
  }

  /** Возвращает inputs для компонента */
  get inputs(): Record<string, any> | null {
    return this._data.inputs ?? null;
  }

  /** Возвращает outputs для компонента */
  get outputs(): Record<string, any> | null {
    return this._data.outputs ?? null;
  }

  constructor(private readonly destroy$: DestroyService, private readonly ngbActiveModal: NgbActiveModal) {}

  ngOnInit(): void {
    this._data = this.data ?? inject(MODAL_DATA_TOKEN);
  }

  ngAfterViewInit(): void {
    this.createDynamicComponent();
  }

  /** Создаёт динамический компонент */
  createDynamicComponent() {
    this.dynamicAreaRef.clear();

    this.dynamicComponentRef = this.dynamicAreaRef.createComponent(this.component);

    if (this.inputs) {
      for (let key in this.inputs) {
        this.dynamicComponentRef.instance[key] = this.inputs[key];
      }
    }

    if (this.outputs) {
      for (let key in this.outputs) {
        this.dynamicComponentRef.instance[key].pipe(takeUntil(this.destroy$)).subscribe((event: any) => {
          this.outputs![key](event);
        });
      }
    }

    this.dynamicComponentRef.changeDetectorRef.detectChanges();
  }

  /** Закрывает модальное окно */
  closeModal(): void {
    return this.ajsInstance ? this.ajsInstance.close() : this.ngbActiveModal.close();
  }
}

import {
  POPUP_VIDEO_BLOCK_REGEXP,
  POPUP_VIDEO_BLOCK_TYPES,
  POPUP_VIDEO_BLOCK_URL_PARTS,
} from '../../../../../app/services/popup-block/popup-block.constants';

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupVideoBlockSettingsController', CqPopupVideoBlockSettingsController);

  function CqPopupVideoBlockSettingsController($scope, $translate, shortUuid, validationHelper) {
    var vm = this;

    /**
     * Уникальный идентификатор для корректной работы радиобаттонов при наличии нескольких компонентов на одной странице
     * Без этого индентификатора, ID у input'ов будут одинаковые, а это противоречит правилам HTML, поэтому при клике на чекбоксы/радио будут баги
     * Тот же самый баг будет с одинаковыми именами input[type="radio"], т.к. такие радио объединяются в radio group
     */
    const UUID = shortUuid();

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.contentForm = null; // форма вкладки "Содержимое"
      vm.parsedUrl = vm.popupBlockParams.url.toString(); // Строковое представление ссылки на видео
      vm.UUID = UUID;
      vm.validationHelper = validationHelper;

      $scope.$watch('vm.parsedUrl', watchParsedUrl);
      $scope.$watch('vm.popupBlockParams.autoplay', watchAutoplayFlag);
      $scope.$watch('vm.popupBlockParams.url', watchVideoUrl);

      /**
       * Обработка смены значения галочки автовоспроизведения
       *
       * @param newVal
       * @param oldVal
       */
      function watchAutoplayFlag(newVal, oldVal) {
        if (newVal !== oldVal) {
          var url = new URI(vm.parsedUrl);
          var searchParams = url.search(true);
          // Если в ссылке уже есть GET-параметр autoplay и у него ненулевое значение, то берем значение из него
          if (searchParams['autoplay']) {
            vm.popupBlockParams.autoplay = searchParams['autoplay'] === '1' ? true : false;
          }

          if (newVal) {
            vm.popupBlockParams.url.setSearch({ autoplay: '1' }); // Добавляем GET-параметр autoplay
          } else if (searchParams['autoplay']) {
            // Убираем автовоспроизведение, если в ссылке уже есть GET-параметр autoplay и у него ненулевое значение
            // Если оставить просто else, то при удалении 1 или 0 из строки &autoplay=1 удалится сразу весь GET-параметр, а не только один знак
            vm.popupBlockParams.url.removeSearch('autoplay');
          }
          vm.parsedUrl = vm.popupBlockParams.url.toString();
        }
      }

      /**
       * Обработка смены значения в инпуте с URL видео
       *
       * @param newVal
       * @param oldVal
       */
      function watchParsedUrl(newVal, oldVal) {
        if (newVal) {
          vm.popupBlockParams.url = new URI(newVal); // Парсим строку с видео
          var searchParams = vm.popupBlockParams.url.search(true);
          // Если в распарсенной строке есть GET-параметр autoplay и у него ненулевое значение, то выставляем соответствующее значение галочке автовоспроизведения
          if (searchParams.hasOwnProperty('autoplay')) {
            var autoplay = searchParams['autoplay'];
            vm.popupBlockParams.autoplay = autoplay === '1' ? true : false;
          } else {
            vm.popupBlockParams.url = vm.popupBlockParams.url.removeSearch('autoplay');
            vm.popupBlockParams.autoplay = false;
          }

          if (POPUP_VIDEO_BLOCK_REGEXP[POPUP_VIDEO_BLOCK_TYPES.VIMEO].test(newVal)) {
            var videoId = vm.popupBlockParams.url.path();
            var videoURI = new URI(POPUP_VIDEO_BLOCK_URL_PARTS[POPUP_VIDEO_BLOCK_TYPES.VIMEO]);
            videoURI.path(videoURI.path() + videoId);

            // Проходимся по всем GET-параметрам и вставляем их в embed ссылку
            for (key in searchParams) {
              if (searchParams.hasOwnProperty(key)) {
                videoURI.setSearch(key, searchParams[key]);
              }
            }

            // По политике браузеров автовоспроизведение видео возможно только без звука
            if (searchParams['autoplay']) {
              videoURI.setSearch('muted', 1);
            }

            // Если есть временная метка (вида #t=XX) в ссылке, то вставляем её
            if (vm.popupBlockParams.url.hash()) {
              videoURI.hash(vm.popupBlockParams.url.hash());
            }

            vm.popupBlockParams.embeddableUrl = videoURI.toString();
          } else if (POPUP_VIDEO_BLOCK_REGEXP[POPUP_VIDEO_BLOCK_TYPES.YOU_TUBE].test(newVal)) {
            var videoId =
              searchParams['v'] || vm.popupBlockParams.url.path().slice(1, vm.popupBlockParams.url.path().length); // Второй вариант — для коротких ссылок youtu.be
            var videoURI = new URI(POPUP_VIDEO_BLOCK_URL_PARTS[POPUP_VIDEO_BLOCK_TYPES.YOU_TUBE]);
            videoURI.path(videoURI.path() + videoId);

            // Проходимся по всем GET-параметрам и вставляем их в embed ссылку
            for (var key in searchParams) {
              if (searchParams.hasOwnProperty(key) && key !== 'v') {
                videoURI.setSearch(key, searchParams[key]);
              }
            }

            // Если в обычной ссылке начало просмотра указывается через GET-параметр t, то в embed ссылке это GET-параметр start
            if (searchParams['t']) {
              videoURI.setSearch('start', searchParams['t']);
              videoURI.removeSearch('t');
            }

            // По политике браузеров автовоспроизведение видео возможно только без звука
            if (searchParams['autoplay']) {
              videoURI.setSearch('mute', 1);
            }

            vm.popupBlockParams.embeddableUrl = videoURI.toString();
          }
        }
      }

      /**
       * Обработка смены значения объекта с частями URL видео
       *
       * @param newVal
       * @param oldVal
       */
      function watchVideoUrl(newVal, oldVal) {
        if (newVal !== oldVal) {
          var searchParams = newVal.search(true);
          if (searchParams.hasOwnProperty('autoplay')) {
            var autoplay = searchParams['autoplay'];
            vm.popupBlockParams.autoplay = autoplay === '1' ? true : false;
          }
        }
      }
    }

    function destroy() {
      // при уничтожении компонента нужно выставить значения необязательным полям, т.к. все поля должны быть заполнены
      if (!vm.parsedUrl) {
        vm.popupBlockParams.url = $translate.instant(
          'popupVideoBlockSettings.content.contentForm.urlInput.placeholder',
        );
        vm.popupBlockParams.embeddableUrl = 'https://www.youtube.com/embed/dQw4w9WgXcQ';
      }
    }
  }
})();

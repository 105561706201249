import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'cq-switch-preview',
  templateUrl: './switch-preview.component.html',
  styleUrls: [],
})
export class SwitchPreviewComponent {
  switch = {
    value: false,
    disabled: false,
    formSwitch: new UntypedFormControl(false, [Validators.requiredTrue]),
  };
  switcherValues = [
    {
      id: 1,
      name: 'Биба',
    },
    {
      id: 2,
      name: 'Боба',
    },
  ];
  switcher = {
    value: this.switcherValues[0],
    rightValue: this.switcherValues[1],
    leftValue: this.switcherValues[0],
  };

  constructor() {}
}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.editUserProperty')
    .controller('CqEditUserPropertyModalController', CqEditUserPropertyModalController);

  function CqEditUserPropertyModalController($translate, userModel, validationHelper) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса
      vm.save = save;
      vm.validationHelper = validationHelper;
    }

    function save(isValid) {
      if (isValid) {
        vm.isApiRequestPerformed = true;

        const props = [
          {
            op: 'update_or_create',
            key: vm.resolve.propertyName,
            value: vm.resolve.propertyValue,
          },
        ];

        firstValueFrom(userModel.setProperties(vm.resolve.userId, vm.resolve.currentApp.id, props))
          .then(setPropertiesSuccess)
          .then(setPropertiesFinally);
      }

      function setPropertiesFinally() {
        vm.isApiRequestPerformed = false;
      }

      function setPropertiesSuccess() {
        vm.modalInstance.close({
          value: vm.resolve.propertyValue,
        });
      }
    }
  }
})();

<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('sendingWhatsAppTemplate.header') }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <cq-conversation-part-preview
      class="d-flex justify-content-end align-items-end"
      [attachments]="parsedTemplateAttachment"
      [bodyJson]="parsedTemplate.bodyJson"
      [body]="parsedTemplateBodyWithHtml"
      [expectedAttachment]="getAttachmentType(headerType)"
    ></cq-conversation-part-preview>
    <form
      (ngSubmit)="onSubmit()"
      name="variablesForm"
    >
      <ng-container *ngFor="let control of variablesControls; let i = index">
        <div
          class="form-group"
          [cqShowError]="control"
        >
          <label> <span>{{</span>{{ i + 1 }}<span>}}</span> </label>
          <input
            class="form-control"
            [attr.data-test]="'whats-app-template-variable-control-' + i"
            [formControl]="control"
            [placeholder]="t('sendingWhatsAppTemplate.variablesForm.variableInput.placeholder')"
            type="text"
          />
          <cq-validation-messages [control]="control">
            <cq-validation-message errorName="required">
              {{ t('sendingWhatsAppTemplate.variablesForm.variableInput.errors.required') }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
      </ng-container>
      <cq-file-upload-dropzone
        class="margin-top-40"
        *ngIf="hasAttachment(headerType)"
        [accept]="getAcceptableMimeTypesForAttach(headerType)"
        [control]="attachmentControl"
        [maxFileSize]="getMaxFileSize(headerType)"
      >
        <div class="margin-bottom-5 text-primary">{{ getUploadZoneHeadingTranslate(headerType) }}</div>
        <div class="text-secondary small">{{ getUploadZoneTextTranslate(headerType) }}</div>
      </cq-file-upload-dropzone>
      <input
        class="hidden"
        id="variablesForm.submit"
        type="submit"
      />
    </form>
  </div>

  <div class="modal-footer flex-nowrap">
    <div class="text-muted small margin-right-30">
      <span>{{ t('sendingWhatsAppTemplate.footer.paidSending.text') }}</span>
      <a
        [attr.href]="t('sendingWhatsAppTemplate.footer.paidSending.link.href')"
        target="_blank"
        >{{ t('sendingWhatsAppTemplate.footer.paidSending.link.text') }}</a
      >
    </div>
    <button
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
      type="button"
    >
      {{ t('general.cancel') }}
    </button>
    <label
      class="btn btn-primary"
      for="variablesForm.submit"
    >
      <i class="btn-icon-left cqi-md cqi-message-send"></i>
      {{ t('sendingWhatsAppTemplate.footer.sendTemplateButton') }}
    </label>
  </div>
</ng-container>

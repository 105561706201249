import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { CategoryListComponent } from './category-list.component';

@NgModule({
  declarations: [CategoryListComponent],
  exports: [CategoryListComponent],
  imports: [CommonModule, TranslocoModule, RouterLinkWithHref, NgbTooltipModule, UIRouterModule, NgbDropdownModule],
})
export class CategoryListModule {}

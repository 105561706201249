/**
 * Контроллер выбора свойств из селектора
 */
(function () {
  'use strict';

  angular
    .module('myApp.trackMaster')
    .controller('CqAutoEventPropertiesSelectController', CqAutoEventPropertiesSelectController);

  function CqAutoEventPropertiesSelectController(validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.addProperty = addProperty;
      vm.removeProperty = removeProperty;
      vm.validationHelper = validationHelper;
    }

    /**
     * Добавление свойства
     */
    function addProperty() {
      vm.params.push({
        name: '',
        selector: '',
      });
    }

    /**
     * Удаление свойства
     *
     * @param {Object} prop
     */
    function removeProperty(prop) {
      vm.params.splice(vm.params.indexOf(prop), 1);
    }
  }
})();

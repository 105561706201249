/**
 * Контроллер сбора данных о пользователях онбординга
 */
(function () {
  'use strict';

  angular
    .module('myApp.serviceInstallationForDeveloper')
    .controller(
      'CqDataCollectionInstructionsForDeveloperController',
      CqDataCollectionInstructionsForDeveloperController,
    );

  function CqDataCollectionInstructionsForDeveloperController() {
    var vm = this;

    /**
     * Значения радиобаттона
     *
     * @type {{SAAS: string, ECOMMERCE: string}}
     */
    var DATA_COLLECTION_RADIO = {
      ECOMMERCE: 'ecommerce',
      SAAS: 'saas',
    };

    vm.$onInit = init;

    function init() {
      vm.DATA_COLLECTION_RADIO = DATA_COLLECTION_RADIO;
      vm.dataCollectionRadio = DATA_COLLECTION_RADIO.ECOMMERCE; // текущее значение радиобаттона
    }
  }
})();

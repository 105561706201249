import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIResponse } from '@http/types';
import { ApiStatsdMetricRequest, ApiUtilsLogsRequest } from '@panel/app/shared/services/utils/utils.types';

@Injectable({
  providedIn: 'root',
})
export class HttpUtilsService {
  constructor(private readonly http: HttpClient) {}

  sendLogs<T extends object>(payload: ApiUtilsLogsRequest<T>) {
    return this.http.post('/utils/logs', payload);
  }

  /**
   * Отправка логов в statsd (Grafana)
   *
   * @param payload Данные для отправки
   * @returns
   */
  sendStatsdMetric(payload: ApiStatsdMetricRequest): Observable<APIResponse<{}>> {
    const params: ApiStatsdMetricRequest = { log: false, ...payload };

    return this.http.post<APIResponse<{}>>('/utils/statsd', params);
  }

  /**
   * Отправка сообщения с инструкцией по установке кода
   *
   * @param appId Id приложения
   * @param email Электронная почта, на которую отправится сообщение с инструкцией
   */
  sendTutorial(appId: string, email: string): Observable<APIResponse<{}>> {
    const params: any = {
      app: appId,
      email: email,
    };

    return this.http.post<APIResponse<{}>>('/utils/sendtutorial', params);
  }
}

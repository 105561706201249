import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { TriggerChainStepSendingConditions } from '@panel/app/http/trigger-chain/internal-types';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { TriggerChainContentEditorModal } from '@panel/app-old/components/trigger-chains/content-editor/modal/trigger-chain-content-editor.modal';

@Component({
  selector: 'cq-trigger-chain-sending-conditions-editor',
  templateUrl: './trigger-chain-sending-conditions-editor.component.html',
  styleUrls: ['./trigger-chain-sending-conditions-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainSendingConditionsEditorComponent implements OnInit {
  @Input({ required: true })
  sendingConditionsStep!: TriggerChainStepSendingConditions;

  @Output()
  sendingConditionsStepChange: Subject<TriggerChainStepSendingConditions> = new Subject<TriggerChainStepSendingConditions>();

  constructor(
    private readonly triggerChainContentEditorModal: TriggerChainContentEditorModal,
    private readonly editorInteractions: TriggerChainStepEditorInteractionsService,
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
  ) {}

  ngOnInit() {
    this.triggerChainContentEditorModal
      .openSendingConditionsEditorModal(this.sendingConditionsStep, this.triggerChainEditorStore)
      .then((sendingConditionsStep) => {
        this.sendingConditionsStepChange.next(sendingConditionsStep);
        this.editorInteractions.closeClick.next();
      })
      .catch(() => {
        this.editorInteractions.closeClick.next();
      });
  }
}

import { translate } from '@jsverse/transloco';
import { Container, IPointData, Text } from 'pixi.js';
import { Observable, Subscription } from 'rxjs';
import { generate } from 'short-uuid';

import {
  ActionStyle,
  BaseActionABS,
  ConnectionSourceActionABS,
} from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { BlockType, CARD_WIDTH } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { IPoint } from '@panel/app/pages/chat-bot/content/views/connection';
import {
  BOT_CONDITION_BLOCK_LIGHTEN_PRIMARY_COLOR,
  BOT_GREY,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

export class BotNextAction extends ConnectionSourceActionABS {
  blockType: BlockType = 'action';

  rerenderConnection$!: Observable<void>;

  // Подписка на удаление nextBranch
  nextBranchDestroySub?: Subscription;

  uid!: string;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);
    this.render();
    this.uid = generate();
    this.rerenderConnection$ = this.externalPositionChange;
  }

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  getConnectionPointCoords(container: Container | null = this.text): IPointData {
    if (!container) {
      return { x: 0, y: 0 };
    }
    return {
      x: container.width + 10 - this.connectionPoint.width / 2,
      y: container.height / 2 - this.connectionPoint.height / 2,
    };
  }

  get connectionPointGlobalCoordinates(): IPoint {
    // TODO: Вот это обращение к parent.parent мне очень не нравится, хочется абстракции, но пока не удалось сообразить
    const branchPosition = this.container.parent.parent.position;
    return {
      x: branchPosition.x + CARD_WIDTH,
      y: branchPosition.y + this.container.y + this.container.height / 2, // Центр блока
    };
  }

  destroy() {
    this.connection?.dropConnection();
    super.destroy();
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 0,
        horizontal: 0,
      },
      border: {
        size: 0,
        color: 0xc7cad1,
        radius: 10,
      },
      background: {
        color: BOT_CONDITION_BLOCK_LIGHTEN_PRIMARY_COLOR,
      },
    };
  }

  get height(): number {
    return this.container.height;
  }

  redraw(): void {}

  text: Text | null = null;

  render(): void {
    this.text = renderCanvasText(translate('classes.action.canvasText.next'), { fill: BOT_GREY, wordWrap: false });
    this.container.addChild(this.text, this.connectionPoint);
    this.text.position.set(0, 0);
    this.connectionPoint.position.set(...Object.values(this.getConnectionPointCoords()));
  }

  get renderReady(): boolean {
    return isDefined(this.container) && this.container.renderable;
  }

  protected style!: ActionStyle;

  get width(): number {
    return this.container!.width;
  }
}

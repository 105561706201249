export enum ELASTICSEARCH_OPERATION {
  BOOLEAN_FALSE = 'bool_false',
  BOOLEAN_TRUE = 'bool_true',
  DAYS_LESS_THAN = 'daysless',
  DAYS_LESS_THAN_OR_UNKNOWN = 'daysless_or_unknown',
  DAYS_MORE_THAN = 'daysmore',
  DAYS_MORE_THAN_OR_UNKNOWN = 'daysmore_or_unknown',
  KNOWN = 'known',
  LIST_CONTAINS = 'lcontains',
  LIST_CONTAINS_ALL = 'lcontains_all',
  LIST_CONTAINS_ANY = 'lcontains_any',
  LIST_NOT_CONTAINS = 'lnotcontains',
  LIST_NOT_CONTAINS_ALL = 'lnotcontains_all',
  LIST_NOT_CONTAINS_ANY = 'lnotcontains_any',
  NUMBER_EQ = 'eq',
  NUMBER_GT = 'gt',
  NUMBER_GT_OR_UNKNOWN = 'gt_or_unknown',
  NUMBER_LT = 'lt',
  NUMBER_LT_OR_UNKNOWN = 'lt_or_unknown',
  NUMBER_NOT_EQ = 'neq',
  NUMBER_RANGE = 'range',
  STR_CONTAINS = 'str_contains',
  STR_EQ = 'str_eq',
  STR_NOT_CONTAINS = 'str_notcontains',
  STR_NOT_EQ = 'str_neq',
  UNKNOWN = 'unknown',
}

export enum ELASTICSEARCH_OPERATION_GROUP {
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  DATE = 'date',
  GENERAL = 'general',
  NUMBER = 'number',
  STRING = 'string',
}

export const ELASTICSEARCH_OPERATIONS_BY_GROUP = {
  [ELASTICSEARCH_OPERATION_GROUP.GENERAL]: [ELASTICSEARCH_OPERATION.KNOWN, ELASTICSEARCH_OPERATION.UNKNOWN],
  [ELASTICSEARCH_OPERATION_GROUP.STRING]: [
    ELASTICSEARCH_OPERATION.STR_CONTAINS,
    ELASTICSEARCH_OPERATION.STR_NOT_CONTAINS,
    ELASTICSEARCH_OPERATION.STR_EQ,
    ELASTICSEARCH_OPERATION.STR_NOT_EQ,
  ],
  [ELASTICSEARCH_OPERATION_GROUP.NUMBER]: [
    ELASTICSEARCH_OPERATION.NUMBER_GT,
    ELASTICSEARCH_OPERATION.NUMBER_LT,
    ELASTICSEARCH_OPERATION.NUMBER_GT_OR_UNKNOWN,
    ELASTICSEARCH_OPERATION.NUMBER_LT_OR_UNKNOWN,
    ELASTICSEARCH_OPERATION.NUMBER_EQ,
    ELASTICSEARCH_OPERATION.NUMBER_NOT_EQ,
    ELASTICSEARCH_OPERATION.NUMBER_RANGE,
  ],
  [ELASTICSEARCH_OPERATION_GROUP.ARRAY]: [
    ELASTICSEARCH_OPERATION.LIST_CONTAINS,
    ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS,
    ELASTICSEARCH_OPERATION.LIST_CONTAINS_ANY,
    ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ANY,
    ELASTICSEARCH_OPERATION.LIST_CONTAINS_ALL,
    ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ALL,
  ],
  [ELASTICSEARCH_OPERATION_GROUP.DATE]: [
    ELASTICSEARCH_OPERATION.DAYS_MORE_THAN,
    ELASTICSEARCH_OPERATION.DAYS_LESS_THAN,
    ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN,
    ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN,
  ],
  [ELASTICSEARCH_OPERATION_GROUP.BOOLEAN]: [
    ELASTICSEARCH_OPERATION.BOOLEAN_TRUE,
    ELASTICSEARCH_OPERATION.BOOLEAN_FALSE,
  ],
} as const;

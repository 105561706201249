import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isDefined } from '@panel/app/shared/functions/is-defended.function';

/**
 * Функцкия для получения observable статуса по определенной ошибке
 * @param control FormControl/FormGroup/FormArray ошибку которого надо трекать
 * @param errorName Имя ошибки которую надо трекать
 *
 * @return Observable<boolean> статус валидности контрола по указаной ошибке. true = invalid
 */
export function extractValidationError(control: AbstractControl, errorName: string): Observable<boolean> {
  return control.statusChanges.pipe(
    map(() => {
      return (
        control.status === 'INVALID' && isDefined(control.errors) && Object.keys(control.errors).includes(errorName)
      );
    }),
  );
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TuiLetModule } from '@taiga-ui/cdk';
import { NgMathPipesModule } from 'ngx-pipes';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { CustomPriceCapModule } from '@panel/app/pages/subscription/pricing/custom-price-cap/custom-price-cap.module';
import { PlanChangeConfirmModalModule } from '@panel/app/pages/subscription/pricing/plan-change-confirm-modal/plan-change-confirm-modal.module';
import { PricingStore } from '@panel/app/pages/subscription/pricing/pricing.store';
import { PricingPlanHeadingComponent } from '@panel/app/pages/subscription/pricing/pricing-plan-heading/pricing-plan-heading.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [PricingPlanHeadingComponent],
  exports: [PricingPlanHeadingComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    NgSelectModule,
    TuiLetModule,
    PlanChangeConfirmModalModule,
    NgxSliderModule,
    NgMathPipesModule,
    SharedModule,
    NgbTooltipModule,
    CustomPriceCapModule,
  ],
  providers: [CarrotquestHelperProvider, PricingStore],
})
export class PricingPlanHeadingModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SUBSCRIPTION_STATUSES } from '@panel/app/services/billing-info/billing-info.constants';

/** Компонент для работы со статусом подписки */
@Component({
  selector: 'cq-subscription-status[status][statusText]',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionStatusComponent {
  /** Статус */
  @Input()
  status!: SUBSCRIPTION_STATUSES;

  /** Текст статуса */
  @Input()
  statusText!: string;

  /**
   * Возвращает класс для текста статуса
   *
   * @param status - Статус подписки
   */
  getStatusTextClass(status: SUBSCRIPTION_STATUSES): 'text-success' | 'text-warning' {
    switch (status) {
      case SUBSCRIPTION_STATUSES.ACTIVE:
      case SUBSCRIPTION_STATUSES.IN_TRIAL:
        return 'text-success';
      case SUBSCRIPTION_STATUSES.CANCELLED:
      case SUBSCRIPTION_STATUSES.NON_RENEWING:
        return 'text-warning';
      default:
        throw new Error('There is not handled case');
    }
  }
}

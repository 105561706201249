import { IMAGE_EXTENSION } from '../../../shared/services/file-helper/file-helper.constants';

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqWidgetAvatarController', CqWidgetAvatarController);

  function CqWidgetAvatarController(carrotquestHelper, fileHelper) {
    let vm = this;

    /**
     * Максимальный размер загружаемого файла
     *
     * @type {number}
     */
    const MAX_FILE_SIZE = 1024 * 1024;

    /**
     * Минимальная высота аватара в пикселях
     *
     * @type {number}
     */
    const MIN_AVATAR_HEIGHT = 120;

    /**
     * Минимальная ширина аватара в пикселях
     *
     * @type {number}
     */
    const MIN_AVATAR_WIDTH = 120;

    vm.$onInit = init;

    function init() {
      vm.fileHelper = fileHelper;
      vm.IMAGE_EXTENSION = IMAGE_EXTENSION;
      vm.MAX_FILE_SIZE = MAX_FILE_SIZE;
      vm.MIN_AVATAR_HEIGHT = MIN_AVATAR_HEIGHT;
      vm.MIN_AVATAR_WIDTH = MIN_AVATAR_WIDTH;
      vm.trackClickChangeAvatar = trackClickChangeAvatar;
    }

    /**
     * Трек клика на кнопку "Загрузить новую аватарку"
     */
    function trackClickChangeAvatar() {
      carrotquestHelper.track('Настройки чата - кликнул на "Загрузить новую аватарку"', { app: vm.currentAppName });
    }
  }
})();

<div
  class="flex flex-grow-1 position-relative overflow-hidden"
  #canvasWrap
  (wheel)="$event.preventDefault()"
>
  <cq-canvas-options
    [isShow]="isOptionsShow"
    [x]="optionsXCoords"
    [y]="optionsYCoords"
  >
    <div
      #optionsContent
      class="btn-group padding-bottom-5"
    >
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="copyBranch()"
      >
        <i class="btn-icon cqi-sm cqi-copy"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="removeBranch()"
      >
        <i class="btn-icon cqi-sm cqi-trash"></i>
      </button>
    </div>
  </cq-canvas-options>
  <cq-bot-blocks-overlay></cq-bot-blocks-overlay>
</div>

<div class="card m-0 cursor-pointer">
  <div class="card-header-wrap position-relative bg-light-primary">
    <div
      class="card-header position-absolute p-0 top-0 right-0 bottom-0 left-0"
      [ngStyle]="{ backgroundImage: 'url(' + template.catalogImage + ')' }"
    ></div>
  </div>

  <div class="card-body flex justify-space-between flex-column">
    <div
      class="template-card-name mb-0 mb-10"
      data-test="template-name"
    >
      {{ template.name }}
    </div>
    <div class="tags flex flex-wrap">
      <span
        *ngFor="let tag of template.tags"
        class="badge badge-light-secondary text-truncate"
        data-test="template-tags"
        >{{ tag }}</span
      >
    </div>
  </div>
</div>

<ngb-alert
  class="mb-0"
  [type]="'warning'"
  [dismissible]="false"
>
  <div class="d-flex align-items-center">
    <div>
      <i class="cqi-sm cqi-lightning"></i>

      <span>{{ denialReasonTitleText }}</span>

      <a
        *ngIf="showAction"
        class="alert-link"
        href="#"
        (click)="handleClickAction(currentApp, denialReason)"
        >{{ denialReasonTexts.action }}
      </a>
    </div>
    <ng-content></ng-content>
  </div>
</ngb-alert>

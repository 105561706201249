import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmDataFromContactsModule } from '@panel/app/pages/integrations/content/amocrm/data-from-contacts/amocrm-data-from-contacts.module';
import { AmocrmDataFromLeadsModule } from '@panel/app/pages/integrations/content/amocrm/data-from-leads/amocrm-data-from-leads.module';

import { AmocrmDataFromComponent } from './amocrm-data-from.component';

@NgModule({
  declarations: [AmocrmDataFromComponent],
  exports: [AmocrmDataFromComponent],
  imports: [AmocrmDataFromContactsModule, AmocrmDataFromLeadsModule, TranslocoModule],
  providers: [IntegrationService],
})
export class AmocrmDataFromModule {}

<div
  *ngIf="selectedMessages.length || selectedMessagesAll"
  class="flex justify-center"
>
  <div
    *ngIf="currentDirectory.name !== SYSTEM_DIRECTORIES.ARCHIVE"
    class="padding-right-20 bordered-right"
  >
    <!--Кнопка "Активировать"-->
    <button
      *ngIf="
        checkStatusMessagesToDisable(true) ||
        (accessToSelectedAutoMessages.hasAccess && accessToSelectedTelegramAutoMessages.hasAccess)
      "
      class="btn btn-borderless-primary"
      (click)="clickActivateButton.emit(selectedMessages)"
      [disabled]="checkStatusMessagesToDisable(true)"
    >
      <i class="btn-icon-left cqi-sm cqi-play-o"></i>
      <span>{{ 'autoMessages.table.activateButton' | transloco }}</span>
    </button>
    <!--Кнопка "Активировать" С ограничениями-->
    <button
      *ngIf="
        !checkStatusMessagesToDisable(true) &&
        (!accessToSelectedAutoMessages.hasAccess || !accessToSelectedTelegramAutoMessages.hasAccess)
      "
      (click)="openSomeAutoMessageBillingModal()"
      class="btn btn-borderless-primary"
      type="button"
    >
      <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
      <span>{{ 'autoMessages.table.activateButton' | transloco }}</span>
    </button>

    <!--Кнопка "Приостановить"-->
    <button
      class="btn btn-borderless-primary"
      (click)="clickPauseButton.emit(selectedMessages)"
      [disabled]="checkStatusMessagesToDisable(false)"
    >
      <i class="btn-icon-left cqi-sm cqi-pause"></i>
      <span>{{ 'autoMessages.table.pauseButton' | transloco }}</span>
    </button>
  </div>

  <div class="padding-left-20">
    <!--Кнопка "Архивировать"-->
    <button
      *ngIf="currentDirectory.name !== SYSTEM_DIRECTORIES.ARCHIVE"
      class="btn btn-borderless-primary"
      (click)="clickArchiveButton.emit(selectedMessages)"
    >
      <i class="btn-icon-left cqi-sm cqi-archive"></i>
      <span>{{ 'autoMessages.table.archiveButton' | transloco }}</span>
    </button>

    <!--Кнопка "Восстановить"-->
    <button
      *ngIf="currentDirectory.name === SYSTEM_DIRECTORIES.ARCHIVE"
      class="btn btn-borderless-primary"
      (click)="clickReestablishButton.emit(selectedMessages)"
    >
      <i class="btn-icon-left cqi-sm cqi-archive"></i>
      <span>{{ 'autoMessages.table.reestablishButton' | transloco }}</span>
    </button>

    <!--Кнопка "Создать копию"-->
    <button
      class="btn btn-borderless-primary"
      (click)="clickCopyButton.emit(selectedMessages[0])"
      [disabled]="selectedMessages.length > 1"
    >
      <i class="btn-icon-left cqi-sm cqi-copy"></i>
      <span>{{ 'autoMessages.table.createCopyButton' | transloco }}</span>
    </button>
    <!--Кнопка "Переместить в папку"-->
    <button
      *ngIf="currentDirectory.name !== SYSTEM_DIRECTORIES.ARCHIVE"
      class="btn btn-borderless-primary"
      (click)="clickChangeDirectoryButton.emit(selectedMessages)"
    >
      <i class="btn-icon-left cqi-sm cqi-move"></i>
      <span>{{ 'autoMessages.table.setDirectoryButton' | transloco }}</span>
    </button>
  </div>
</div>

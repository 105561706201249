<ng-container *transloco="let t; read: 'categoryFilterComponent'">
  <ng-select
    class="ng-select-header-options"
    [clearable]="false"
    [searchable]="false"
    [formControl]="control"
    [items]="store.knowledgeBaseCategories$ | async"
    bindValue="id"
  >
    <ng-container *ng-header-tmp>
      <button
        class="ng-select-header-option"
        type="button"
        (click)="control.setValue('0'); categoriesSelect.close()"
      >
        {{ t('all') }}
      </button>
    </ng-container>
    <ng-template
      ng-label-tmp
      ng-option-tmp
      let-option="item"
    >
      <span [innerHTML]="option.icon"></span>
      <span class="ng-value-label">{{ getCategoryName(option) }}</span>
    </ng-template>
  </ng-select>
</ng-container>

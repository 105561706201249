/**
 * Директива колор пикера
 * По сути эта директива делает возможным переиспользовать colpick внутри uib-popover, не плодя по темплейту для каждого инпута с выбором цвета
 */
(function () {
  'use strict';

  angular.module('myApp.directives.colorPicker').directive('cqColorPicker', cqColorPicker);

  function cqColorPicker($uibTooltip) {
    var newDirectiveParams = {
      require: {
        ngModel: 'ngModel',
        ngModelOptions: '?ngModelOptions',
      },
      controller: controller,
      bindToController: true,
    };

    var originalDirective = $uibTooltip('cqColorPicker', 'popover', 'click', {
      useContentExp: true,
    });

    // @ngInject
    function controller($attrs, $templateCache, moment) {
      var vm = this;

      vm.$onInit = init;

      function init() {
        // имя темплейта генерируется из таймштампа и, на всякий случай, рандомного числа (один раз напоролся на то, что таймштамп был одинаковый, хоть он и в милисекундах)
        var templateUrl = 'js/shared/directives/color-picker/' + moment().valueOf() + Math.random() + '.html';
        var ngModel = 'ng-model="' + $attrs.ngModel + '"';
        var ngModelOptions = vm.ngModelOptions ? 'ng-model-options="' + $attrs.ngModelOptions + '"' : '';

        $templateCache.put(templateUrl, '<div cq-color-picker-selector ' + ngModel + ' ' + ngModelOptions + '"></div>');

        // в uib-popover можно передать строку в качестве темплейта, но её надо обернуть в дополнительные кавычки
        $attrs.cqColorPicker = '"' + templateUrl + '"';
      }
    }

    return angular.extend(originalDirective, newDirectiveParams);
  }
})();

<ng-container *transloco="let t">
  <div
    class="row"
    *ngIf="messages && messages.length > 0"
  >
    <div class="col-5">
      <strong>{{ t('emailNotificationMessageTriggersReadonly.heading') }}</strong>
    </div>
    <div class="col-7 margin-between-rows-10">
      <div *ngFor="let message of messages">
        <span class="font-weight-bold">{{ message.name }}</span>
      </div>
    </div>
  </div>
</ng-container>

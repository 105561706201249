<ng-container *transloco="let t; read: 'readyAutoMessagesModalComponent'">
  <div class="modal-header align-items-baseline">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="dismissModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body pt-0">
    <div>{{ t('filter.description') }}</div>
    <div class="flex mt-20 mb-15 align-items-center">
      <span>{{ t('filter.type.label') }}:</span>
      <ng-select
        [formControl]="messageTypeControl"
        [clearable]="false"
        class="ml-10 message-type-select"
        data-test="ready-made-templates-modal-types-dropdown"
      >
        <ng-option
          [value]="option"
          *ngFor="let option of READY_AUTO_MESSAGES_MODAL_FILTER"
        >
          <div class="flex align-items-center">
            <span data-test="ready-made-templates-modal-types-dropdown-item">{{
              t('filter.type.types.' + option)
            }}</span>
          </div>
        </ng-option>
      </ng-select>
      <span class="ml-20">{{ t('filter.useCase.label') }}:</span>
      <ng-select
        class="ml-10 use-case-select"
        [formControl]="useCaseControl"
        [clearable]="false"
        data-test="ready-made-templates-cases-dropdown"
      >
        <ng-option [value]="'any'">{{ t('filter.useCase.options.any') }}</ng-option>
        <ng-option
          [value]="option"
          *ngFor="let option of APP_USAGE_GOAL_LIST"
        >
          {{ 'models.starterGuideModel.appUsageGoal.' + option | transloco }}
        </ng-option>
      </ng-select>
    </div>

    <div class="d-flex flex-wrap space-between templates">
      <ng-container *ngFor="let template of templates$ | async; else: loader; empty: zeroData; trackBy: trackByFn">
        <cq-ready-auto-messages-template
          [template]="template"
          (click)="trackSelectedTemplate(template); closeModal()"
          uiSref="app.content.messagesAjs.templates.ready"
          [uiParams]="{ templateId: template.id }"
          data-test="ready-made-templates-modal-item-container"
        ></cq-ready-auto-messages-template>
      </ng-container>
    </div>

    <div class="mt-20 mb-15 text-center">
      <button
        *ngIf="canLoadMore"
        (click)="downloadNextSubject.next()"
        class="btn btn-outline-primary"
      >
        {{ 'general.showMore' | transloco }}
      </button>
    </div>
  </div>

  <ng-template #loader>
    <hr class="ml-n20 mr-n20" />
    <cq-loader class="position-relative d-block p-30 flex-grow-1"></cq-loader>
  </ng-template>

  <ng-template #zeroData>
    <hr class="ml-n20 mr-n20" />
    <cq-zero-data class="mt-30 mb-20 text-muted flex-grow-1">
      {{ t('zeroData.text') }}
    </cq-zero-data>
  </ng-template>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { MESSAGE_PAGE_TYPES } from '@http/message/message.constants';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';
import { DestroyService } from '@panel/app/services';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

type SendTime = {
  sendTimeValueFromH: number;
  sendTimeValueFromM: number;
  sendTimeValueToH: number;
  sendTimeValueToM: number;
};

/**
 * Компонент для настройки времени отправки автосообщений/ботов
 */
@Component({
  selector: 'cq-message-editor-sending-hours',
  templateUrl: './message-editor-sending-hours.component.html',
  styleUrls: ['./message-editor-sending-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class MessageEditorSendingHoursComponent {
  /**
   * Включена ли настройка времени отправки
   */
  @Input()
  isEnabled = true;

  /**
   * Используется компонент в автосообщениях или в боте
   */
  @Input()
  messagePageType: MESSAGE_PAGE_TYPES = MESSAGE_PAGE_TYPES.AUTO_MESSAGES;

  /**
   * Человекочитаемое название раздела в котором используется компонент
   */
  @Input()
  entityName: string = '';

  /**
   * Конфиг времени отправки сообщений
   */
  @Input()
  set sendTime(value: SendTime) {
    this.sendTimeFormGroup.setValue(value);
  }

  /**
   * Список опций часов отправки
   */
  @Input()
  readonly listTimeH: number[] = [];

  /**
   * Список опций минут отправки
   */
  @Input()
  readonly listTimeM: number[] = [];

  @Input()
  timeZone: string = '';

  @Output()
  isEnabledChange = new Subject<boolean>();

  @Output()
  sendTimeChange = new Subject<SendTime>();

  readonly sendTimeFormGroup = this.fb.group<ToFormGroupControls<SendTime>>({
    sendTimeValueFromM: this.fb.control(0, { nonNullable: true }),
    sendTimeValueFromH: this.fb.control(0, { nonNullable: true }),
    sendTimeValueToH: this.fb.control(0, { nonNullable: true }),
    sendTimeValueToM: this.fb.control(0, { nonNullable: true }),
  });

  @Output()
  touchAndValidateCallback: Observable<ValidationCallback> = of(() => {
    this.sendTimeFormGroup.markAllAsTouched();
    return this.sendTimeFormGroup.status !== 'PENDING'
      ? Promise.resolve(this.sendTimeFormGroup.valid)
      : firstValueFrom(
          this.sendTimeFormGroup.statusChanges.pipe(
            filter((status) => status !== 'PENDING'),
            map(() => this.sendTimeFormGroup.valid),
          ),
        );
  });

  constructor(private readonly destroy$: DestroyService, private readonly fb: FormBuilder) {
    this.sendTimeFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.sendTimeChange.next(this.sendTimeFormGroup.getRawValue());
    });
  }
}

(function () {
  'use strict';

  require('./wordpress.controller');

  angular.module('myApp.integrations').component('cqWordpressModal', cqWordpressModal());

  function cqWordpressModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // apiCredentials - ключи API
        // integration - текущая интеграция
      },
      controller: 'CqWordpressModalController',
      controllerAs: 'vm',
      template: require('./wordpress.html'),
    };
  }
})();

import { IMAGE_EXTENSION } from '../../../shared/services/file-helper/file-helper.constants';
import { BILLING_ADD_ONS } from '../../../../app/services/billing-info/billing-info.constants';
import { MESSAGE_SENDER_DEFAULT_AVATAR_URL } from '../../../../app/http/message-sender/message-sender.constants';
import { EMAIL_TYPES, MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.messageSenders').controller('MessageSenderEditorController', MessageSenderEditorController);

  function MessageSenderEditorController(
    $filter,
    $q,
    $scope,
    $translate,
    $uibModal,
    $uibModalInstance,
    billingInfo,
    fileHelper,
    utilsService,
    validationHelper,
    callback,
    currentApp,
    messageSender,
    messageSenders,
    planVersionService,
  ) {
    var vm = this;

    var AVATAR_MAX_SIZE = 1024 * 1024; // максимальный размер аватара (1мб)
    var EMAIL_NAME_MAX_LENGTH = 255; // ограничение на максимальную длину электронной почты в рассылках
    var EMAIL_NAME_PATTERN = /^[0-9a-zA-Z\-_][0-9a-zA-Z\-_.]*$/; // регулярное выражение для электронной почты в рассылках
    var NAME_SENDER_PATTERN = /^[0-9a-zA-ZА-Яа-яЁёЇїІіЄєҐґ'\-_ ][0-9a-zA-ZА-Яа-яЁёЇїІіЄєҐґ'\-_. ]*$/; // Регулярка для проверки имени
    var NAME_MAX_LENGTH = 30; // ограничение на максимальную длину видимого имени

    var isMessageSenderDefault = angular.copy(messageSender.isDefault); // нужно запомнить является ли отправитель на момент начала редактирования отправителем по умолчанию

    vm.AVATAR_MAX_SIZE = AVATAR_MAX_SIZE;
    vm.BILLING_ADD_ONS = BILLING_ADD_ONS;
    vm.billingInfo = billingInfo;
    vm.currentApp = currentApp;
    vm.EMAIL_NAME_MAX_LENGTH = EMAIL_NAME_MAX_LENGTH;
    vm.EMAIL_NAME_PATTERN = EMAIL_NAME_PATTERN;
    vm.EMAIL_TYPES = EMAIL_TYPES;
    vm.fileHelper = fileHelper;
    vm.IMAGE_EXTENSION = IMAGE_EXTENSION;
    vm.isApiRequestPerformed = false; // флаг выполнения запроса
    vm.isFreemium = planVersionService.isFreemium();
    vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
    vm.MESSAGE_SENDER_DEFAULT_AVATAR_URL = MESSAGE_SENDER_DEFAULT_AVATAR_URL;
    vm.NAME_MAX_LENGTH = NAME_MAX_LENGTH;
    vm.messageSender = messageSender;
    vm.messageSenders = messageSenders;
    vm.NAME_SENDER_PATTERN = NAME_SENDER_PATTERN;
    vm.newAvatar = {
      // новая аватарка
      file: null, // файл
      preview: null, // превью
    };
    vm.saveMessageSender = saveMessageSender;
    vm.setDefaultAvatar = setDefaultAvatar;
    vm.utilsService = utilsService;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      $scope.$watch('vm.messageSender.name', function (newValue, oldValue) {
        // автоматическое формирование электронной почты в рассылках из имени
        if (
          messageSender.emailName == undefined ||
          $filter('transliterate')(oldValue) == messageSender.emailName ||
          messageSender.emailName == ''
        ) {
          messageSender.emailName = $filter('transliterate')(messageSender.name);
        }
      });
    }

    /**
     * Закрытие модалки с передачей отправителя в resolve
     *
     * @param isValid
     */
    function saveMessageSender(isValid) {
      if (isValid) {
        // если отправитель был отправителем по умолчанию, а во время редактирования этот флаг убрали - нужно выдать поп-ап о назначении нового отправителя по умолчанию
        if (isMessageSenderDefault && !messageSender.isDefault) {
          var removeDefaultMessageSenderModal = $uibModal.open({
            controller: 'AssignNewDefaultMessageSenderModalController',
            controllerAs: 'vm',
            templateUrl: 'js/shared/modals/assign-new-default-message-sender/assign-new-default-message-sender.html',
            resolve: {
              body: function () {
                return (
                  '<div class="margin-bottom-20">' +
                  $translate.instant('messageSenders.editor.removeDefaultMessageSenderModal.body') +
                  '</div>'
                );
              },
              currentApp: angular.bind(null, angular.identity, currentApp),
              messageSender: angular.bind(null, angular.identity, messageSender),
              messageSenders: angular.bind(null, angular.identity, messageSenders),
            },
          });

          removeDefaultMessageSenderModal.result.then(closeModal);
        } else {
          closeModal();
        }
      }

      function closeModal() {
        vm.isApiRequestPerformed = true;

        messageSender.avatar = vm.newAvatar.file || messageSender.avatar;
        $q.when(callback(messageSender)).then(callbackSuccess).finally(callbackFinally);

        function callbackFinally() {
          vm.isApiRequestPerformed = false;
        }

        function callbackSuccess(messageSender) {
          $uibModalInstance.close(messageSender);
        }
      }
    }

    /**
     * Установка аватара по умолчанию
     */
    function setDefaultAvatar() {
      vm.newAvatar.file = null;
      messageSender.avatar = MESSAGE_SENDER_DEFAULT_AVATAR_URL;
      messageSender.setDefaultAvatar = true;
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { QuestionComponent } from './question.component';

@NgModule({
  declarations: [QuestionComponent],
  exports: [QuestionComponent],
  imports: [CommonModule, TranslocoModule],
})
export class QuestionModule {}

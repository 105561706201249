<ng-container *transloco="let t; read: 'cssSelectorInputComponent'">
  <div>
    <div class="d-flex align-items-center mb-10">
      <strong>{{ t('heading.text') }}</strong>
      <i
        class="cqi-sm cqi-question-circle ml-5"
        [ngbTooltip]="tipContent"
        triggers="focus:blur"
        tooltipClass="css-selector-tooltip"
        tabindex="0"
      ></i>
    </div>
    <div [cqShowError]="control">
      <input
        class="form-control"
        [formControl]="control"
        placeholder="{{ t('input.placeholder') }}"
      />
      <cq-validation-messages [control]="control">
        <cq-validation-message errorName="required">{{ t('input.errors.required') }}</cq-validation-message>
      </cq-validation-messages>
    </div>
  </div>

  <ng-template #tipContent>
    <span [innerHTML]="t('heading.tooltip.p1')"></span>
    <a
      href="https://chrome.google.com/webstore/detail/selectorgadget/mhjhnkcfbdhnjickkkdbjoemdmbfginb"
      target="_blank"
    >
      {{ t('heading.tooltip.linkText') }}
    </a>
    <span [innerHTML]="t('heading.tooltip.p2')"></span>
  </ng-template>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { OriginalInboundEmailViewModalComponent } from './original-inbound-email-view-modal.component';

@NgModule({
  declarations: [OriginalInboundEmailViewModalComponent],
  exports: [OriginalInboundEmailViewModalComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [NgbActiveModal],
})
export class OriginalInboundEmailViewModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { TriggerChainStepEditorWrapModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-editor-wrap/trigger-chain-step-editor-wrap.module';
import { TriggerChainStepNameEditorModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-name-editor/trigger-chain-step-name-editor.module';
import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { TimeSelectorModule } from '@panel/app/partials/message-editor/shared/time-selector/time-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { TriggerChainReactionEditorComponent } from './trigger-chain-reaction-editor.component';

@NgModule({
  declarations: [TriggerChainReactionEditorComponent],
  exports: [TriggerChainReactionEditorComponent],
  imports: [
    CommonModule,
    TriggerChainStepEditorWrapModule,
    FormsModule,
    TranslocoModule,
    NgbTooltipModule,
    NgSelectModule,
    TriggerChainStepNameEditorModule,
    TimeSelectorModule,
    ReactiveFormsModule,
    EventTypeSelectModule,
    SharedModule,
  ],
})
export class TriggerChainReactionEditorModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { UibModalServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { StepContentModule } from '@panel/app/pages/starter-guide/components/step-content/step-content.module';
import { StepHeaderModule } from '@panel/app/pages/starter-guide/components/step-header/step-header.module';
import { StepNumberModule } from '@panel/app/pages/starter-guide/components/step-number/step-number.module';
import { StepTitleModule } from '@panel/app/pages/starter-guide/components/step-title/step-title.module';
import { StarterGuideLeadsPassingModule } from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.module';
import { StarterGuideCreatingLeadbotModule } from '@panel/app/pages/starter-guide/steps/starter-guide-creating-leadbot/starter-guide-creating-leadbot.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { StarterGuideForMarketersComponent } from './starter-guide-for-marketers.component';

@NgModule({
  declarations: [StarterGuideForMarketersComponent],
  exports: [StarterGuideForMarketersComponent],
  imports: [
    CommonModule,
    NgbAccordionModule,
    SharedModule,
    StarterGuideCreatingLeadbotModule,
    StarterGuideLeadsPassingModule,
    StepContentModule,
    StepHeaderModule,
    StepNumberModule,
    StepTitleModule,
    TranslocoModule,
  ],
  providers: [UibModalServiceProvider],
})
export class StarterGuideForMarketersModule {}

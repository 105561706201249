import { TranslocoService } from '@jsverse/transloco';

import { AmocrmIntegrationForm } from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import {
  AmocrmIntegrationExternal,
  AmocrmIntegrationSettingsExternal,
} from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { arrayToMap } from '@panel/app/shared/functions/map/array-to-map.function';
import { PseudoMap } from '@panel/app/shared/types/pseudo-map.type';

import { INTEGRATION_TYPES } from '../../constants/integration.constants';
import { SettingsIntegration } from '../../integration';

export class AmocrmIntegration extends SettingsIntegration<AmocrmIntegrationSettingsExternal> {
  /** Форма настройки интеграции */
  form: AmocrmIntegrationForm;

  /** Тип интеграции */
  type = INTEGRATION_TYPES.AMOCRM;

  /** Настройки интеграции */
  settings: AmocrmIntegrationSettingsExternal = {
    amocrmAddress: '',
    amocrmLeadsPropsMapping: {},
    amocrmPropsMapping: {},
    amocrmUserHash: '',
    amocrmUserLogin: '',
    eventTypeIds: [],
    leadDefaultPipeline: '',
    leadDefaultStatus: '',
    leadsEventsMapping: [],
    leadsEventTypeIds: [],
    leadsPropsMapping: {},
    oauthAccessExpiresAt: null,
    oauthAccessToken: '',
    oauthCsrfToken: '',
    oauthRefreshExpiresAt: null,
    oauthRefreshToken: '',
    propsMapping: {},
    standardProps: {
      $email: false,
      $name: false,
      $phone: false,
    },
    systemFields: {},
    webhookIsSet: false,
    clientId: '',
    clientSecret: '',
  };

  get externalFormat(): AmocrmIntegrationExternal {
    const amocrmLeadsPropsMapping = arrayToMap(this.form.controls.settings.controls.amocrmLeadsPropsMapping.value);
    const amocrmPropsMapping = arrayToMap(
      this.form.controls.settings.controls.amocrmPropsMappingInternal.value.filter(
        (value: PseudoMap) => !value.value.includes('$'),
      ),
    );
    const leadsEventsMapping = this.form.controls.settings.controls.leadsEventsMapping.controls.map((form) => {
      return {
        checked: form.controls['checked'].value,
        event: arrayToMap([form.controls.event.value]),
        eventProps: arrayToMap(form.controls.eventProps.value),
      };
    });
    const leadsPropsMapping = arrayToMap([
      ...this.form.controls.settings.controls.leadEventTypesMapping.value,
      ...this.form.controls.settings.controls.leadPropsMapping.value,
    ]);
    const propsMapping = arrayToMap([
      ...this.form.controls.settings.controls.userEventsTypesMapping.value,
      ...this.form.controls.settings.controls.userPropsMapping.value,
    ]);
    const standardProps = standardPropsToExternalFormat(
      arrayToMap(
        this.form.controls.settings.controls.amocrmPropsMappingInternal
          .getRawValue()
          .filter((value: PseudoMap) => value.value.includes('$')),
      ),
    );
    const systemFields = arrayToMap(this.form.controls.settings.controls.systemFields.value);

    /**
     * Эта конструкция составляет новый объект настроек newSettings со всеми полями из this.form.controls.settings.value
     * кроме тех, которые указаны перед ...newSettings
     */
    const {
      amocrmPropsMappingInternal,
      leadEventTypesMapping,
      leadPropsMapping,
      userEventsTypesMapping,
      userPropsMapping,
      ...newSettings
    } = this.form.controls.settings.value;

    return {
      ...this.baseExternalFormat,
      ...this.form.value,
      settings: {
        ...newSettings,
        amocrmLeadsPropsMapping,
        amocrmPropsMapping,
        leadsEventsMapping,
        leadsPropsMapping,
        propsMapping,
        standardProps,
        systemFields,
      },
    };
  }

  constructor(translocoService: TranslocoService, integrationExternal?: AmocrmIntegrationExternal) {
    super(integrationExternal);

    // Если интеграция создается из внешнего формата, обновим settings, иначе задаем дефолтные значения
    if (integrationExternal) {
      // С backend'а может прийти только часть полей. Для удобства нужны все,
      // поэтому добавляем в настройки все дефолтные значение
      this.updateSettings({
        ...this.settings,
        ...integrationExternal.settings,
      });
    } else {
      this.active = true;

      const integrationTypeName = translocoService.translate(`models.integration.types.${this.type}.name`);
      this.name = translocoService.translate('models.integration.defaultValues.name', { integrationTypeName });
    }

    // Данные о настроенной интеграции собираются с формы и отправляются на backend
    this.form = new AmocrmIntegrationForm(this);
  }
}

function standardPropsToExternalFormat(map: Record<string, string>) {
  let standardPropsExternal: { [index: string]: boolean } = {};

  for (let key in map) {
    standardPropsExternal[map[key]] = true;
  }

  return standardPropsExternal;
}

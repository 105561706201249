<ng-container *transloco="let t; read: 'userSegmentsComponent'">
  <div class="no-margin-bottom">
    <div
      (click)="collapse.toggle(); collapseStateChange.emit(userSegmentsLSName)"
      [attr.aria-expanded]="!isCollapsed"
      class="flex align-items-center cursor-pointer"
      aria-controls="collapse"
      data-test="user-segments-collapse"
    >
      <div class="font-weight-bold flex-grow-1 padding-15">
        <span>{{ t('userSegments') }}</span>
        <i
          class="cqi-sm cqi-question-circle margin-left-5"
          [ngbTooltip]="t('tooltip')"
        ></i>
      </div>
      <div class="margin-right-5">{{ userSegments.length }}</div>
      <i
        [class]="{ 'cqi-chevron-down': isCollapsed, 'cqi-chevron-up': !isCollapsed }"
        class="btn-icon cqi-sm margin-right-15"
      ></i>
    </div>
    <div
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
    >
      <div class="card-body no-padding-top padding-right-10 padding-bottom-15 padding-left-10">
        <div class="user-segments-container flex flex-wrap">
          <ng-container *ngIf="userId || userSegments.length; else zeroData">
            <small
              *ngIf="userId"
              data-test="user-segment"
              class="user-segment text-truncate"
            >
              <i class="cqi-sm cqi-star"></i>
              <span>{{ t('registeredUsers') }}</span>
            </small>
            <ng-container *ngFor="let segment of userSegments">
              <small
                data-test="user-segment"
                class="user-segment text-truncate"
                >{{ segment.name }}</small
              >
            </ng-container>
          </ng-container>
          <ng-template #zeroData>
            <div class="text-muted padding-left-5">{{ t('zeroData') }}</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>

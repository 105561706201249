import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { SortablejsModule } from 'nxt-sortablejs';

import { CarrotquestHelperProvider, UibModalServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { UserCustomsPropsModule } from '@panel/app/partials/user-card/user-customs-props/user-customs-props.module';
import { UserSystemsPropsModule } from '@panel/app/partials/user-card/user-systems-props/user-systems-props.module';
import { PipesModule } from '@panel/app/shared/pipes/pipes.module';

import { UserLocationComponent } from './user-location.component';

@NgModule({
  declarations: [UserLocationComponent],
  exports: [UserLocationComponent],
  imports: [
    ClipboardModule,
    CommonModule,
    NgbTooltipModule,
    PipesModule,
    SortablejsModule,
    TranslocoModule,
    UserCustomsPropsModule,
    UserSystemsPropsModule,
  ],
  providers: [UibModalServiceProvider, CarrotquestHelperProvider],
})
export class UserLocationModule {}

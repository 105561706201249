/**
 * Компонент
 */
(function () {
  'use strict';

  angular.module('myApp.savedBeeTemplate').component('cqSavedBeeTemplate', cqSavedBeeTemplate());

  function cqSavedBeeTemplate() {
    return {
      bindings: {
        click: '&', // Функция вызываемая при клике
        close: '&', // Функция вызываемая при клине на кнопку "Закрыть"
      },
      controllerAs: 'vm',
      template: require('./saved-bee-template.html'),
    };
  }
})();

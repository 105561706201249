import { ONE_TIME_FLAGS } from '../../../../../app/services/one-time-flag/one-time-flag.constants';
import { PLAN_FEATURE } from '../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { CONVERSATION_PART_SENT_VIA } from '../../../../../app/http/conversation-part/conversation-part.constants';
import { SecurityContext } from '@angular/core';

(function () {
  'use strict';

  angular.module('myApp.conversations').controller('CqConversationFrameController', CqConversationFrameController);

  function CqConversationFrameController(
    $filter,
    $scope,
    $sce,
    $state,
    $translate,
    $uibModal,
    $window,
    DEFAULT_LANGUAGE,
    carrotquestHelper,
    conversationModalsService,
    conversationPartModel,
    oneTimeFlagHelper,
    paywallService,
    planFeatureAccessService,
    domSanitizer,
  ) {
    var vm = this;
    vm.$onInit = init;

    function init() {
      vm.appSettings = {
        knowledgeBaseDomain: vm.knowledgeBaseDomain, // Ссылка на базу знаний
        lang: localStorage.getItem('carrotquest_language') || DEFAULT_LANGUAGE, // Язык интерфейса
        theme: vm.isDarkThemeActive ? 'dark' : 'default', // тема оформления
      };
      vm.onPlayAudio = onPlayAudio;
      vm.openAddAnswerTemplateModal = openAddAnswerTemplateModal;
      vm.openAIContentModal = openAIContentModal;
      vm.openMessageSource = openMessageSource;
      vm.openPicturePreviewModal = openPicturePreviewModal;
      vm.openVoteModal = openVoteModal;
      vm.showInboundEmail = showInboundEmail;
      vm.transcribeAudio = transcribeAudio;
      vm.hasAssesToTranscribeAudio = planFeatureAccessService.getAccess(PLAN_FEATURE.TRANSCRIBE_AUDIO, this.currentApp);
      vm.showPaywall = showPaywall;
      // vue не сомжет показать safeHtml
      vm.userAvatar = domSanitizer.sanitize(SecurityContext.HTML, vm.conversation.user.avatar); // Аватар пользователя
      $scope.$watch('vm.isDarkThemeActive', watchThemeChange);
    }

    /** Колбэк на клик по кнопке воспроизведения голосового сообщения */
    function onPlayAudio() {
      if (!oneTimeFlagHelper.isSet(ONE_TIME_FLAGS.TRACK_CLICK_PLAY_AUDIO)) {
        oneTimeFlagHelper.set(ONE_TIME_FLAGS.TRACK_CLICK_PLAY_AUDIO);
        trackClickPlayAudio();
      }
    }

    /** Трек клика по кнопке воспроизведения голосового сообщения в первый раз */
    function trackClickPlayAudio() {
      const eventName = 'Диалоги - клик на прослушивание аудио в первый раз';
      carrotquestHelper.track(eventName);
    }

    /** Трек клика по кнопке транскрипции аудио в первый раз */
    function trackClickTranscribeAudio() {
      const eventName = 'Диалоги - клик на транскрипцию в первый раз';
      carrotquestHelper.track(eventName);
    }

    /**
     * Распознавание текста из аудиофайла
     *
     * @param conversationPartId ID реплики
     * @return {IPromise<string>}
     */
    function transcribeAudio(conversationPartId) {
      if (!oneTimeFlagHelper.isSet(ONE_TIME_FLAGS.TRACK_CLICK_TRANSCRIBE_AUDIO)) {
        oneTimeFlagHelper.set(ONE_TIME_FLAGS.TRACK_CLICK_TRANSCRIBE_AUDIO);
        trackClickTranscribeAudio();
      }

      return firstValueFrom(conversationPartModel.transcribeAudio(conversationPartId));
    }

    /**
     * Отслеживание за изменением темы чата
     */
    function watchThemeChange(newVal, oldVal) {
      if (newVal !== oldVal) {
        vm.appSettings.theme = newVal ? 'dark' : 'default';
      }
    }

    /**
     * Открытие модального окна добавления шаблона быстрого ответа
     *
     * @param {String} message Сообщение
     */
    function openAddAnswerTemplateModal(message) {
      $uibModal.open({
        controller: 'AddAnswerTemplateModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/add-answer-template/add-answer-template.html',
        resolve: {
          message: angular.bind(null, angular.identity, message),
        },
      });
    }

    /**
     * Открытие модалки для просмотра контента, который использовал AI-бот для ответа пользователю
     * @param conversationPartId
     */
    function openAIContentModal(conversationPartId) {
      const conversationPart = $filter('filter')(vm.conversationParts, { id: conversationPartId })[0];

      conversationModalsService.openAIContentModal({
        conversationPart,
        djangoUser: vm.djangoUser,
        knowledgeBaseDomain: vm.knowledgeBaseDomain,
      });
    }

    /**
     * Колбэк на клик по кнопке «Открыть поп-ап» и «Открыть письмо» в теле диалога
     * Открывает либо отправленное автосообщение, либо статистику ручного сообщения
     *
     * @param {String} messageId — ID автосообщения
     */
    function openMessageSource(messageId) {
      var conversationPart = $filter('filter')(vm.conversationParts, { id: messageId })[0];

      if (conversationPart) {
        var conversationId = vm.conversation.message;
        if (conversationPart.sent_via === CONVERSATION_PART_SENT_VIA.MESSAGE_AUTO) {
          var autoMessageUrl = $state.href('app.content.messagesAjs.edit', { messageId: conversationId });
          $window.open(autoMessageUrl, '_blank');
        } else if (conversationPart.sent_via === CONVERSATION_PART_SENT_VIA.MESSAGE_MANUAL) {
          var manualMessageUrl = $state.href('app.content.messagesAjs.manual.statistics', {
            messageId: conversationId,
          });
          $window.open(manualMessageUrl, '_blank');
        }
      }
    }

    /**
     * Открытие модального окна с увеличенным изображением
     *
     * @param {String} pictureUrl URL картинки
     */
    function openPicturePreviewModal(pictureUrl) {
      $uibModal.open({
        controller: 'PicturePreviewModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/picture-preview/picture-preview.html',
        resolve: {
          pictureUrl: angular.bind(null, angular.identity, pictureUrl),
        },
        windowClass: 'picture-preview',
      });
    }

    /**
     * Открытие модалки с оставленным отзывом
     *
     * @param voteData Данные отзыва для отображения в модальном окне
     */
    function openVoteModal(voteData) {
      $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('conversationsConversationFrame.voteModal.heading'),
              body:
                '\
              <div class="margin-bottom-20">\
                <b>' +
                $translate.instant('conversationsConversationFrame.voteModal.body.vote') +
                '</b>\
                <span>' +
                $translate.instant('models.vote.votes.' + voteData.vote) +
                '</span>\
                </div>\
              <div class="text-break">\
                <b>' +
                $translate.instant('conversationsConversationFrame.voteModal.body.voteComment') +
                '</b>\
                <span>' +
                voteData.comment +
                '</span>\
              </div>',
              confirmButtonText: $translate.instant('general.close'),
              cancelButtonClass: 'hidden',
            };
          },
        },
      });
    }

    /**
     * Открытие модального окна с оригиналом письма
     *
     * @param {String} inboundEmailId ID письма
     */
    function showInboundEmail(inboundEmailId) {
      firstValueFrom(conversationPartModel.getInboundEmail(inboundEmailId))
        .then(getInboundEmailSuccess)
        .then(openInboundEmailModal);

      function getInboundEmailSuccess(response) {
        var inboundEmail = response.inboundEmail;

        // HACK: иногда в этом ID в начале и в конце стоят треугольные скобки, и из-за этого ID воспринимается как html-тег, и не выводится
        if (inboundEmail.messageId.indexOf('<') === 0) {
          inboundEmail.messageId = inboundEmail.messageId.substring(1, inboundEmail.messageId.length - 1);
        }

        return inboundEmail;
      }

      /**
       * Открытие модального окна с оригиналом email сообщения
       *
       * @param inboundEmail - Информация об оригинале email сообщения
       */
      function openInboundEmailModal(inboundEmail) {
        const parsedModalBody = parseInboundEmailBody(inboundEmail.body);

        $uibModal
          .open({
            component: 'cqOriginalInboundEmailViewModalWrapper',
            resolve: {
              modalWindowParams: {
                body: parsedModalBody,
                messageId: inboundEmail.messageId,
                recipient: inboundEmail.recipient,
                sender: inboundEmail.sender,
                subject: inboundEmail.subject,
              },
            },
          })
          .result.catch(() => {});
      }

      /**
       * Экранирование < и > на &lt; и &gt; вокруг емейлов, иначе AJS не доверит контенту в таких скобках и вырежет его.
       * Например, <some@email.com> вырежется
       *
       * @param inboundEmailBody Тело письма
       * @returns {string}
       */
      function parseInboundEmailBody(inboundEmailBody) {
        return inboundEmailBody.replace(
          /(<)([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+)(>)/,
          '&lt;$2&gt;',
        );
      }
    }

    /**
     * Показ пейвола
     */
    function showPaywall() {
      paywallService.showPaywall(vm.currentApp, vm.hasAssesToTranscribeAudio.denialReason);
    }
  }
})();

/**
 * Контроллер работы с iframe-барузера превью
 */
import { MESSAGE_PART_TYPES } from '../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.browserFrame').controller('CqBrowserFrameController', CqBrowserFrameController);

  function CqBrowserFrameController($scope) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
    }
  }
})();

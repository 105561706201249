/**
 * Компонент шаблона сообщения
 */
(function () {
  'use strict';

  require('./message-template.controller');

  angular.module('myApp.messageTemplate').component('cqMessageTemplate', cqMessageTemplate());

  function cqMessageTemplate() {
    return {
      bindings: {
        currentApp: '=',
        directories: '=?', // Список папок шаблонов
        hideAdditionalInformation: '=?', // Скрывать или нет дополнительну инфу или действия
        onChanged: '&?', // Вызываемая функция при изменени шиблона
        onRemove: '&?', // Вызываемая функция после удаления
        template: '=', // Шаблон
      },
      controller: 'CqMessageTemplateController',
      controllerAs: 'vm',
      template: require('./message-template.html'),
    };
  }
})();

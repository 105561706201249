import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { AUTO_EVENTS_TYPES } from '@http/track-master/track-master.constants';
import { AutoEvent } from '@http/track-master/track-master.model';

export type AutoEventDuplicate = {
  event: AutoEvent;
  duplicateBy: Extract<keyof AutoEvent, 'name' | 'url' | 'selector'>[];
};

@Component({
  selector: 'cq-duplicates-errors',
  templateUrl: './duplicates-errors.component.html',
  styleUrls: ['./duplicates-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoEventsDuplicatesErrorsComponent {
  @Input()
  eventDuplicates: AutoEventDuplicate[] = [];

  @Output()
  selectEvent: Subject<AutoEvent> = new Subject<AutoEvent>();

  AUTO_EVENTS_TYPES = AUTO_EVENTS_TYPES;

  /**
   * Проверка на то что автособытие тип CLICK или INPUT
   * @param event
   */
  isInputOrClick(event: AutoEvent) {
    return [AUTO_EVENTS_TYPES.CLICK, AUTO_EVENTS_TYPES.INPUT].includes(event.type);
  }
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Invoice } from '@http/invoice/invoice';
import {
  InvoiceAPIRequestDetalizationUrlParams,
  InvoiceAPIResponseDetalizationUrl,
  InvoiceDto,
} from '@http/invoice/invoice.types';
import { PaginatableElements, PaginatableElementsApiResponse, PaginatorParametersRequest } from '@http/types';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';
import { PaginatorParameters } from '@panel/app-old/shared/patterns/array-stream/array-stream.types';

/** Сервис для работы со счетами */
@Injectable({ providedIn: 'root' })
export class InvoiceHttpService {
  constructor(private readonly http: HttpClient, private readonly instanceFactory: InstanceFactory) {}

  /**
   * Получение списка инвойсов
   */
  getList(
    billing: boolean,
    paginatorParameters: PaginatorParameters = {},
    ignoreLoadingBar: boolean = false,
  ): Observable<PaginatableElements<Invoice>> {
    let paginatorParametersDto: PaginatorParametersRequest = {};

    if (paginatorParameters.paginateCount) {
      paginatorParametersDto.paginate_count = paginatorParameters.paginateCount;
    }
    if (paginatorParameters.paginateDirection) {
      paginatorParametersDto.paginate_direction = paginatorParameters.paginateDirection;
    }
    if (paginatorParameters.paginateIncluding) {
      paginatorParametersDto.paginate_including = paginatorParameters.paginateIncluding;
    }
    if (paginatorParameters.paginatePageOrder) {
      paginatorParametersDto.paginate_page_order = paginatorParameters.paginatePageOrder;
    }
    if (paginatorParameters.paginatePosition) {
      paginatorParametersDto.paginate_position = paginatorParameters.paginatePosition.join();
    }

    const params = {
      billing: billing,
      ...paginatorParametersDto,
    };

    return this.http
      .get<PaginatableElementsApiResponse<InvoiceDto>>('/billing/invoices', {
        params,
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(EXTENDED_RESPONSE, true)
          .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((response) => {
          const paginatableElements: PaginatableElements<Invoice> = {
            elements: this.instanceFactory.getInstanceInvoices(response.data),
            paginatorParameters: {
              ...paginatorParameters,
              paginatePosition: response.meta.next_before_position,
            },
          };

          return paginatableElements;
        }),
      );
  }

  /**
   * Получение ссылки на детализацию счёта
   */
  getDetalizationFileUrl(invoiceId: string): Observable<string> {
    const params: InvoiceAPIRequestDetalizationUrlParams = {
      id: invoiceId,
    };

    return this.http
      .get<InvoiceAPIResponseDetalizationUrl>('/billing/downloads/chargebee_pdf_invoice_url', {
        params,
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(map((response) => response.url));
  }
}

import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment/moment';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { InvoiceHttpService } from '@http/invoice/http/invoice-http.service';
import { INVOICE_STATUS, PAYMENT_REASON } from '@http/invoice/invoice.constants';
import { InvoiceDto } from '@http/invoice/invoice.types';

/** Класс для работы со счётом */
export class Invoice {
  /** Задолженность по счёту */
  amountDue: number = 0;
  /** Токен счёта */
  code: string = '';
  /** Дата формирования счёта */
  createDate: number = 0;
  /** ID счёта (выводится пользователю) */
  formattedInvoiceId: string = '';
  /** ID счёта (в нашей системе) */
  id: string = '';
  /** ИНН организации, для которой производится оплата */
  organizationInn: string = '';
  /** Способ оплаты счёта */
  paidVia: null | 'manual' | 'Модульбанк' | 'CloudPayments' | 'Яндекс Кассы' = null;
  /** Статус счёта */
  status: INVOICE_STATUS = INVOICE_STATUS.NOT_PAID;
  /** Сумма счёта (в копейках или центах) */
  total: number = 0;

  /** Получение переведённого статуса */
  get translatedStatus(): string {
    return this.translator.translate(`invoiceEntity.status.${this.status}`, {
      daysForPayment: this.getNumberDaysForPayment(),
    });
  }

  constructor(
    private readonly httpService: InvoiceHttpService,
    private readonly translator: TranslocoService,
    private readonly dto: InvoiceDto,
  ) {
    this.amountDue = dto.body.amount_due;
    this.code = dto.code;
    this.createDate = dto.body.date;
    this.formattedInvoiceId = dto.formatted_invoice_id;
    this.id = dto.id;
    this.organizationInn = dto.contract_data.organization_inn;
    this.paidVia = dto.paid_via;
    this.status = dto.body.status;
    this.total = dto.body.total;
  }

  /** Скачивание акта */
  downloadAct() {
    let a = document.createElement('a');

    a.href = `${environment.apiEndpoint}/billing/downloads/act?code=${this.code}`;
    a.style.display = 'none';

    document.body.appendChild(a);

    a.click();

    a.remove();
  }

  /** Получение количества дней доступных для оплаты*/
  private getNumberDaysForPayment() {
    let daysPassed = moment().diff(moment.unix(this.createDate), 'days');
    let daysForPayment = 7;

    if (daysPassed > daysForPayment) {
      daysForPayment = 0;
    } else {
      daysForPayment = daysForPayment - daysPassed;
    }

    return daysForPayment;
  }

  /** Скачивание детализации счёта в PDF-формате */
  downloadDetalization(): void {
    firstValueFrom(this.httpService.getDetalizationFileUrl(this.id)).then((pdfUrl) => {
      let a = document.createElement('a');

      a.href = pdfUrl;
      a.style.display = 'none';

      document.body.appendChild(a);

      a.click();

      a.remove();
    });
  }

  /** Скачивание счёта */
  downloadInvoice() {
    let a = document.createElement('a');

    a.href = `${environment.apiEndpoint}/billing/downloads/invoice?code=${this.code}`;
    a.style.display = 'none';

    document.body.appendChild(a);

    a.click();

    a.remove();
  }

  /** Оплачен ли счёт */
  isPaid(): boolean {
    return ![INVOICE_STATUS.NOT_PAID, INVOICE_STATUS.PAYMENT_DUE].includes(this.status);
  }

  /** Оплата счёта */
  pay() {
    let a = document.createElement('a');

    a.href = `${environment.paymentUrl}?code=${this.code}&payment_reason=${PAYMENT_REASON.PAY_SCORE}`;
    a.style.display = 'none';

    document.body.appendChild(a);

    a.click();

    a.remove();
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_VERSION } from '@panel/app/shared/constants/http.constants';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    if (!request.context.has(API_VERSION)) {
      return next.handle(request);
    }

    request = request.clone({
      setParams: {
        version: request.context.get(API_VERSION),
      },
    });

    return next.handle(request);
  }
}

import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=checkbox][trueFalseValue]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrueFalseValueDirective),
      multi: true,
    },
  ],
})
export class TrueFalseValueDirective implements ControlValueAccessor {
  @Input() trueValue: any = true;
  @Input() falseValue: any = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('change', ['$event']) onHostChange(event: Event): void {
    // @ts-ignore
    this.propagateChange(event.target!['checked'] ? this.trueValue : this.falseValue);
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'checked', obj === this.trueValue);
  }

  private propagateChange = (_: any) => {};
}

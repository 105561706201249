import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Компонент-обёртка для настроек интеграции в модальном окне создания интеграции
 */

@Component({
  selector: 'cq-amocrm-wrapper-create-modal',
  templateUrl: './amocrm-wrapper-create-modal.component.html',
  styleUrls: ['./amocrm-wrapper-create-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmWrapperCreateModalComponent {}

<div class="py-15 px-20 text-center bg-primary">
  <i class="cqi-sm cqi-lightning"></i>

  <span>{{ denialReasonTitleText }}</span>

  <a
    ng-if="showAction"
    class="text-white font-weight-bold"
    href="#"
    (click)="handleClickOnAction(currentApp, denialReason)"
    >{{ denialReasonTexts.action }}</a
  >
</div>

/**
 * Получение типов группировки данных
 */
export enum DASHBOARD_DATE_GROUPING_TYPES {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

/**
 * Получение типов статистики
 */
export enum DASHBOARD_STATISTICS_TYPES {
  AUTO_MESSAGE_COUNT = 'autoConversationsCount', //отправленных автосообщений
  CONVERSATIONS_COUNT = 'conversationsCount', //новых диалогов
  LEADS_COUNT = 'leadsCount', //собранных лидов
  MANUAL_EMAIL_MESSAGES_COUNT = 'manualEmailSent', //ручных емейл-рассылок
  USERS_COUNT = 'usersCount', //уникальных посетителей
}

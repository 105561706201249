import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import {
  AUTO_EVENTS_GROUPS,
  AUTO_EVENTS_PARAMS_TYPES,
  AUTO_EVENTS_TYPES,
} from 'app/http/track-master/track-master.constants';
import { ApiCreateOrSaveRequest, ApiCreateResponse, ApiGetResponse } from 'app/http/track-master/track-master.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EventTypeModel } from '@http/event-type/event-type.model';
import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

export type AutoEvent = {
  /** @deprecated на беке с 2017 года помечена как устаревшее */
  active: boolean;
  app: string;
  delay: number; // Задержка
  delayHas: boolean; // Включена/выключена задержка
  desc: string; // Описание
  /** @deprecated на беке с 2018 года помечена как устаревшее */
  eventType: {};
  group: AUTO_EVENTS_GROUPS;
  id: string;
  inputKey: string | null; // В какое свойство записать TODO ANGULAR_TS возможно string надо будет заменить на свойства
  name: string; // Имя
  params: AutoEventParams[]; // Текстовые свойства
  paramsSelectors: AutoEventParams[]; // Свойства из селекторов
  /** @deprecated на беке с 2017 года помечена как устаревшее */
  paramsSubstitutes: '{}';
  paramsType: AUTO_EVENTS_PARAMS_TYPES;
  selector: string; // CSS-селектор элемента
  type: AUTO_EVENTS_TYPES;
  url: string; // URL срабатывания
  urlHas: boolean; // Включено ли срабатывание на опредленной странице
  urlContains: boolean; // Учитывать подстраницы
};

export type AutoEventParams = {
  name: string;
  selector: string;
};

/**
 * Модель для работы с Автособытиями
 */
@Injectable({ providedIn: 'root' })
export class TrackMasterModel {
  constructor(private readonly http: HttpClient, private eventTypeModel: EventTypeModel) {}

  /**
   * Создание автособытия
   *
   * @param appId - ID аппа
   * @param autoEvent - Автосообщение
   */
  public create(appId: string, autoEvent: AutoEvent): Observable<ApiCreateResponse> {
    const body = this.parseToServerFormat(autoEvent);
    body.app = appId;

    return this.http.post<any>('/autoevents', body).pipe(
      map((data) => {
        data.autoEvent = this.parse(data.autoEvent);
        this.eventTypeModel.parse(data.eventType);

        return data;
      }),
    );
  }

  /**
   * Получение автособытия
   *
   * @param autoEventId - ID автособытия
   * @param ignoreLoadingBar - Флаг показа полосы загразки
   */
  public get(autoEventId: string, ignoreLoadingBar = true): Observable<AutoEvent> {
    return this.http
      .get<ApiGetResponse>('/autoevents/' + autoEventId, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((autoEvent) => {
          return this.parse(autoEvent);
        }),
      );
  }

  /**
   * Получение дефолтного автособытия
   */
  public getDefault(group: AUTO_EVENTS_GROUPS): AutoEvent {
    let defaultAutoEvent: Omit<AutoEvent, 'type' | 'paramsType'> = {
      active: true,
      app: '',
      delay: 0, // Задержка
      delayHas: false, // Включена/выключена задержка
      desc: '', // Описание
      eventType: {},
      group: group, // AUTO_EVENTS_GROUPS
      id: '',
      inputKey: null, // В какое свойство записать
      name: '', // Имя
      params: [], // Текстовые свойства
      paramsSelectors: [], // Свойства из селекторов
      paramsSubstitutes: '{}',
      selector: '', // CSS-селектор элемента
      url: '', // URL срабатывания
      urlHas: false, // Включено ли срабатывание на опредленной странице
      urlContains: true, // Учитывать подстраницы
    };

    return { ...defaultAutoEvent, ...this.getTypesByGroup(group) } as AutoEvent;
  }

  /**
   * Получение группы автособытия
   *
   * @param autoEvent - Автособытие
   */
  public getGroup(autoEvent: AutoEvent): AUTO_EVENTS_GROUPS {
    if (autoEvent.type === AUTO_EVENTS_TYPES.CLICK && autoEvent.paramsType === AUTO_EVENTS_PARAMS_TYPES.EVENT) {
      return AUTO_EVENTS_GROUPS.CLICK;
    } else if (autoEvent.type === AUTO_EVENTS_TYPES.URL && autoEvent.paramsType === AUTO_EVENTS_PARAMS_TYPES.EVENT) {
      return AUTO_EVENTS_GROUPS.URL;
    } else if (
      autoEvent.type === AUTO_EVENTS_TYPES.URL_SCROLL &&
      autoEvent.paramsType === AUTO_EVENTS_PARAMS_TYPES.EVENT
    ) {
      return AUTO_EVENTS_GROUPS.URL_SCROLL;
    } else if (
      autoEvent.type === AUTO_EVENTS_TYPES.INPUT &&
      autoEvent.paramsType === AUTO_EVENTS_PARAMS_TYPES.IDENTIFY
    ) {
      return AUTO_EVENTS_GROUPS.INPUT;
    } else {
      return AUTO_EVENTS_GROUPS.PRO;
    }
  }

  /**
   * Получение списка сбора данных
   *
   * @param appId - ID аппа
   * @param ignoreLoadingBar - Флаг показа полосы загразки
   */
  public getList(appId: string, ignoreLoadingBar = true): Observable<AutoEvent[]> {
    return this.http
      .get<ApiGetResponse[]>('/apps/' + appId + '/autoevents', {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((autoEvents) => {
          const parsedAutoEvents: AutoEvent[] = [];
          for (let i = 0; i < autoEvents.length; i++) {
            parsedAutoEvents.push(this.parse(autoEvents[i]));
          }

          return parsedAutoEvents;
        }),
      );
  }

  /**
   * Удаление автособытия
   *
   * @param autoEventId - ID автособытия
   * @param ignoreLoadingBar - Флаг показа полосы загразки
   */
  public remove(autoEventId: string, ignoreLoadingBar = true): Observable<Object> {
    return this.http.delete('/autoevents/' + autoEventId, {
      context: new HttpContext()
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar)
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }

  /**
   * Создание автособытия
   *
   * @param appId - ID аппа
   * @param autoEvent - Автосообщение
   */
  public save(appId: string, autoEvent: AutoEvent): Observable<Object> {
    const body = this.parseToServerFormat(autoEvent);
    body.app = appId;

    return this.http.put('/autoevents/' + autoEvent.id, body, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }

  /**
   * Получение распарсеных параметров
   */
  private getParsedParams(params: string): AutoEventParams[] {
    const objectParams = JSON.parse(params);
    const parsedParams = [];

    for (let key in objectParams) {
      parsedParams.push({
        name: key,
        selector: objectParams[key],
      });
    }
    return parsedParams;
  }

  /**
   * Получение распарсеных параметров для сотправки на сервер
   */
  private getParsedParamsToServer(params: AutoEventParams[]): string {
    const parsedParams: any = {};

    for (let i = 0; i < params.length; i++) {
      parsedParams[params[i].name] = params[i].selector;
    }

    return JSON.stringify(parsedParams);
  }

  /**
   * Получение типа автосообщения и типа параметров по группе автосообщения
   */
  private getTypesByGroup(group: AUTO_EVENTS_GROUPS): Pick<AutoEvent, 'type' | 'paramsType'> | {} {
    switch (group) {
      case AUTO_EVENTS_GROUPS.CLICK:
        return {
          type: AUTO_EVENTS_TYPES.CLICK,
          paramsType: AUTO_EVENTS_PARAMS_TYPES.EVENT,
        };
      case AUTO_EVENTS_GROUPS.URL:
        return {
          type: AUTO_EVENTS_TYPES.URL,
          paramsType: AUTO_EVENTS_PARAMS_TYPES.EVENT,
        };
      case AUTO_EVENTS_GROUPS.URL_SCROLL:
        return {
          type: AUTO_EVENTS_TYPES.URL_SCROLL,
          paramsType: AUTO_EVENTS_PARAMS_TYPES.EVENT,
        };
      case AUTO_EVENTS_GROUPS.INPUT:
        return {
          type: AUTO_EVENTS_TYPES.INPUT,
          paramsType: AUTO_EVENTS_PARAMS_TYPES.IDENTIFY,
        };
      case AUTO_EVENTS_GROUPS.PRO:
        return {};
    }
  }

  /**
   * Парсинг автособытия в серверный формат
   */
  private parseToServerFormat(autoEvent: AutoEvent): ApiCreateOrSaveRequest {
    const params: ApiCreateOrSaveRequest = {
      ignoreLoadingBar: true,
      selector: autoEvent.selector,
      url_contains: autoEvent.urlContains,
      name: autoEvent.name,
      input_key: autoEvent.inputKey,
      delay: (autoEvent.delayHas && autoEvent.delay) || 0,
      params: this.getParsedParamsToServer(autoEvent.params),
      params_selectors: this.getParsedParamsToServer(autoEvent.paramsSelectors),
      desc: autoEvent.desc || '',
      type: autoEvent.type,
      params_type: autoEvent.paramsType,
    };

    if (
      !!~[AUTO_EVENTS_GROUPS.CLICK, AUTO_EVENTS_GROUPS.INPUT].indexOf(autoEvent.group) ||
      (autoEvent.group === AUTO_EVENTS_GROUPS.PRO &&
        !!~[AUTO_EVENTS_TYPES.CLICK, AUTO_EVENTS_TYPES.FORM, AUTO_EVENTS_TYPES.INPUT].indexOf(autoEvent.type))
    ) {
      params.url = (autoEvent.urlHas && autoEvent.url) || '';
    } else {
      params.url = autoEvent.url || '';
    }

    return params;
  }

  /**
   * Парсинг автособытия
   *
   * @param autoEvent - Автособытие в серверном формате
   */
  private parse(autoEvent: ApiGetResponse): AutoEvent {
    const parsedAutoEvent: AutoEvent = { ...autoEvent };

    parsedAutoEvent.group = this.getGroup(autoEvent);
    parsedAutoEvent.urlHas = !!autoEvent.url;
    parsedAutoEvent.delayHas = autoEvent.delay > 0;
    parsedAutoEvent.paramsSelectors = this.getParsedParams(autoEvent.paramsSelectors);
    parsedAutoEvent.params = this.getParsedParams(autoEvent.params);

    return parsedAutoEvent;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { ArticleCategoryModel } from '@http/article-category/article-category.model';
import { ArticlesCategoryEditorModalComponent } from '@panel/app/pages/knowledge-base/shared/components/articles-category-editor-modal/articles-category-editor-modal.component';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class CategoryListComponent {
  /** Категории */
  @Input() categories!: any[];
  /** Текущий App */
  @Input() currentApp!: any;
  /** Callback для обновления данных */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDataUpdate: EventEmitter<any> = new EventEmitter();

  constructor(
    protected readonly destroy$: DestroyService,
    protected readonly ngbModal: NgbModal,
    protected readonly articleCategoryModel: ArticleCategoryModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    protected readonly modalHelperService: ModalHelperService,
  ) {}

  /**
   * Изменение приоритета категории
   *
   * @param categoryId - ID категории
   * @param newPriority - Новый приоритет
   */
  changePriority(categoryId: any, newPriority: any): void {
    this.articleCategoryModel
      .changePriority(this.currentApp.id, categoryId, newPriority)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onDataUpdate.emit());
  }

  /**
   * Открытие модалки редактирование категории
   *
   * @param category - Категория
   */
  openArticlesCategoryEditorModal(category: any = null): void {
    const modal = this.ngbModal.open(ArticlesCategoryEditorModalComponent);
    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
      category: category,
    };

    modal.result.then(() => this.onDataUpdate.emit());
  }

  /**
   * Открытие модалки удаления категории
   *
   * @param category - Категория
   */
  openRemoveArticlesCategoryModal(category: any): boolean {
    if (category.articlesCounter !== 0) {
      return false;
    }

    const modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('knowledgeBase.categoryList.removeArticlesCategoryModal.heading'),
        body: this.translocoService.translate('knowledgeBase.categoryList.removeArticlesCategoryModal.body', {
          categoryName: category.name,
        }),
        confirmButtonText: this.translocoService.translate(
          'knowledgeBase.categoryList.removeArticlesCategoryModal.confirmButtonText',
        ),
      })
      .open(ConfirmModalComponent, { modalDialogClass: 'cq-category-list-remove-modal' });

    modal.result.then(() => {
      this.articleCategoryModel
        .remove(this.currentApp.id, category.id)
        .pipe(
          takeUntil(this.destroy$),
          catchError((response: any) => {
            if (response.meta && response.meta.error === 'CategoryHasArticles') {
              this.toastr.danger(
                this.translocoService.translate('knowledgeBase.categoryList.toasts.categoryHasArticlesError'),
              );
            }

            return of();
          }),
        )
        .subscribe(() => {
          this.trackCategoryRemoved(category.name);
          this.toastr.success(
            this.translocoService.translate('knowledgeBase.categoryList.toasts.categoryRemoveSuccess', {
              categoryName: category.name,
            }),
          );
          this.onDataUpdate.emit();
        });
    });

    return true;
  }

  /**
   * Трек удаления группы
   *
   * @param categoryName - Имя группы
   */
  trackCategoryRemoved(categoryName: any): void {
    this.carrotquestHelper.track('База знаний - удалил категорию', {
      'Название категории': categoryName,
    });
  }

  /**
   * Трек клика создания новой категории
   */
  trackClickAddCategory(): void {
    this.carrotquestHelper.track('База знаний - клик на "Добавить новую категорию"');
  }

  trackByFn(category: any): any {
    return category.id;
  }
}

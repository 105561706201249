<ng-container *transloco="let t; read: 'tagListComponent'">
  <table class="table table-sm table-align-middle border-top">
    <thead>
      <tr>
        <th>{{ t('table.thead.tagName') }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let tag of list">
        <tr>
          <td>
            <cq-tag-text [text]="tag.text"></cq-tag-text>
          </td>
          <td class="shrink-by-content">
            <cq-tag-remove (intentToRemoveTag)="onIntentToRemoveTag(tag)"></cq-tag-remove>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>

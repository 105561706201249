import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { AUTO_MESSAGES_PAGE_ROUTES } from '@panel/app/pages/auto-messages/auto-messages-page.routes';
import { AutoMessageListPageModule } from '@panel/app/pages/auto-messages/pages/list/auto-message-list-page.module';

import { AutoMessagesPageComponent } from './auto-messages-page.component';

@NgModule({
  declarations: [AutoMessagesPageComponent],
  imports: [
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: AUTO_MESSAGES_PAGE_ROUTES }),
    AutoMessageListPageModule,
  ],
})
export class AutoMessagesPageModule {}

<ng-container *transloco="let t">
  <div class="form-group no-margin-bottom">
    <ng-select
      [clearable]="false"
      [formControl]="control"
      [readonly]="true"
      [hideSelected]="true"
      [items]="filteredAndOrderedUserProperties"
      [trackByFn]="userPropsTrackFn"
      bindLabel="prettyName"
      bindValue="name"
      groupBy="group"
    ></ng-select>
  </div>
</ng-container>

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { mean, round, slice } from 'lodash-es';
import { from, fromEvent, merge, Observable } from 'rxjs';
import { bufferCount, concatMap, filter, map, mergeMap, takeUntil, throttleTime } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { DjangoUser } from '@http/django-user/django-user.types';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { CaseStyleHelper, DestroyService } from '@panel/app/services';
import { HttpUtilsService } from '@panel/app/shared/services/utils/http-utils.service';
import { ApiStatsdMetricRequest } from '@panel/app/shared/services/utils/utils.types';

type FpsMetrics = {
  percentile20: number;
  avgBelowPercentile20: number;
  percentile50: number;
  avgBelowPercentile50: number;
};

@Component({
  selector: 'cq-stats-collector[djangoUser]',
  templateUrl: './stats-collector.component.html',
  styleUrls: ['./stats-collector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class StatsCollectorComponent implements OnInit {
  @Input()
  djangoUser: DjangoUser;

  constructor(
    private readonly appService: AppService,
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly destroy$: DestroyService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly featureModel: FeatureModel,
    private readonly httpUtilsService: HttpUtilsService,
    @Inject(WINDOW)
    private readonly window: Window,
  ) {}

  ngOnInit(): void {
    if (this.featureModel.hasAccess(FEATURES.CONVERSATIONS_STATS) && !this.isMobile()) {
      this.startCollectMetrics();
    }
  }

  /**
   * Расчёт номера элемента массива, который является персентилем
   *
   * @param percentile Персентиль
   * @param numberOfValues Количество элементов в упорядоченном массиве
   * @returns
   */
  calcPercentileRank(percentile: number, numberOfValues: number): number {
    return Math.ceil((percentile / 100) * numberOfValues);
  }

  /**
   * Определение мобильного устройства
   * По какой-то причине на мобильных устройствах некоторых клиентов FPS опускается до 5, что портит всю статистику
   * Поэтому мы решили определять мобильные устройства, и просто не собирать с них стату
   *
   * NOTE: метод UtilsService.isMobile не подходит, т.к. на момент написания этого кода там плохая проверка по разрешению экрана
   * Эта проверка не сработает в админке с метатегом, в котором указано минимальное разрешение 1024 пикселя
   * Поэтому пришлось писать своё определение мобилки
   */
  isMobile(): boolean {
    // эта проверка отсечёт только телефоны/планшеты, но не ноуты с тачскринами. Но нам этого достаточно
    return this.window.matchMedia('(pointer: coarse)').matches;
  }

  /** Вычисление FPS следующей секунды */
  recordFps(): Observable<number> {
    return new Observable<number>((sub) => {
      let prevTime = performance.now();
      let frames = 0;

      function loop() {
        requestAnimationFrame(() => {
          const time = performance.now();
          if (time > prevTime + 1000) {
            sub.next(frames);
            sub.complete();

            return;
          } else {
            frames++;
          }

          loop();
        });
      }

      loop();
    });
  }

  /** Измерение FPS в секунду, следующую за действием пользователя. Если пользователь ничего не делает - FPS не измеряется */
  fpsMeter$ = merge(
    fromEvent(this.document, 'mousedown'),
    fromEvent(this.document, 'mousemove'),
    fromEvent(this.document, 'keydown'),
    fromEvent(this.document, 'wheel'),
  ).pipe(throttleTime(1000), mergeMap(this.recordFps));

  /** Подсчёт метрик для statsd */
  metricsCollector$ = this.fpsMeter$.pipe(
    bufferCount(60), // подсчёт метрик производится только при сборе 60 величин FPS (т.е. раз в минуту в лучшем случае)
    filter((fpsArray) => fpsArray.length === 60),
    map((fpsArray) => fpsArray.sort((a, b) => a - b)),
    map((fpsArray): FpsMetrics => {
      const percentile20Rank = this.calcPercentileRank(20, fpsArray.length);
      const percentile50Rank = this.calcPercentileRank(50, fpsArray.length);

      const percentile20 = fpsArray[percentile20Rank - 1];
      const avgBelowPercentile20 = round(mean(slice(fpsArray, 0, percentile20Rank)), 0);
      const percentile50 = fpsArray[percentile50Rank - 1];
      const avgBelowPercentile50 = round(mean(slice(fpsArray, 0, percentile50Rank)), 0);

      return {
        percentile20,
        avgBelowPercentile20,
        percentile50,
        avgBelowPercentile50,
      };
    }),
  );

  /** Генерация payload для отправки метрик в statsd */
  payloadGenerator$ = this.metricsCollector$.pipe(
    map((fpsMetrics) => {
      let payloads: ApiStatsdMetricRequest[] = [];

      Object.entries(fpsMetrics).forEach(([metricName, value]) => {
        payloads.push({
          metricType: 'counter',
          key: `panel.conversations_performance.${this.caseStyleHelper.toUnderscore(metricName)}.app_${
            this.appService.currentAppId
          }.django_user_${this.djangoUser.id}`,
          value: value,
        });
      });

      return payloads;
    }),
    concatMap((payloads) => from(payloads)),
  );

  /**
   * Отправка метрик в statsd
   *
   * @param payload Данные для statsd
   */
  sendStatsdMetric(payload: ApiStatsdMetricRequest) {
    this.httpUtilsService.sendStatsdMetric(payload).subscribe();
  }

  startCollectMetrics() {
    this.payloadGenerator$.pipe(takeUntil(this.destroy$)).subscribe((payload) => this.sendStatsdMetric(payload));
  }
}

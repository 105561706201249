import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { isArray, upperFirst } from 'lodash-es';

import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureDenialReason } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';
import { App } from '@http/app/app.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { ProductFeatureTextService } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.service';
import { DenialReasonTexts } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.types';

/**
 * Компонент для работы с баннером отказа в доступе к фиче
 */

@Component({
  selector: 'cq-access-denial-banner[currentApp][denialReason]',
  templateUrl: './access-denial-banner.component.html',
  styleUrls: ['./access-denial-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDenialBannerComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Отключение показа пейволла по клику на действие */
  @Input()
  set disableShowPaywall(value: boolean) {
    if (value) {
      this.showAction = false;
    } else {
      this.showAction = true;
    }
  }

  /** Причина | Причины отказа */
  _denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] = {
    addOn: BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS,
    capability: PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS,
    limitAmount: null,
    limitExceeded: false,
    needChat: false,
    paidPlanVersion: PAID_PLAN_VERSION.V1,
    productFeature: PLAN_FEATURE.ANALYTICS_CONVERSATIONS,
  };
  @Input()
  set denialReason(denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[]) {
    this._denialReason = denialReason;

    this.denialReasonTexts = this.productFeatureTextService.getDenialReasonTexts(this.denialReason);

    this.trackShowBanner(denialReason);
  }
  get denialReason(): ProductFeatureDenialReason | ProductFeatureDenialReason[] {
    return this._denialReason;
  }

  /** Тексты для причины отказа в доступе */
  denialReasonTexts: DenialReasonTexts = {
    action: '',
    description: '',
    message: '',
    title: '',
  };

  /** Показывать действие */
  showAction: boolean = true;

  /** Получение заголовка причины отказа в доступе */
  get denialReasonTitleText() {
    if (!this.showAction) {
      return upperFirst(this.denialReasonTexts.title);
    }

    return `${upperFirst(this.denialReasonTexts.title)}.`;
  }

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly paywallService: PaywallService,
    private readonly productFeatureTextService: ProductFeatureTextService,
  ) {}

  ngOnInit(): void {
    this.denialReasonTexts = this.productFeatureTextService.getDenialReasonTexts(this.denialReason);
  }

  /**
   * Обработчик клика по "Действие"
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа в доступе
   */
  handleClickOnAction(currentApp: App, denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[]): void {
    this.trackClickOnAction(denialReason);

    if (this.denialReasonTexts.message) {
      this.sendMessageToChat(this.denialReasonTexts.message);
    } else {
      this.showPaywall(currentApp, denialReason);
    }
  }

  /** Отправка сообщения в чат */
  sendMessageToChat(message: string): void {
    this.carrotquestHelper.sendChatMessage(message);
  }

  /**
   * Показ пейволла
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа в доступе
   */
  showPaywall(currentApp: App, denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[]): void {
    this.paywallService.showPaywall(currentApp, denialReason);
  }

  /**
   * Трек клика по "Действие"
   *
   * @param denialReason - Причина | Причины отказа в доступе
   */
  trackClickOnAction(denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[]): void {
    const eventName = 'Тариф коммуникации - клик на бампер';
    let eventParams = {};

    if (isArray(denialReason)) {
      //@ts-ignore
      eventParams['Ограничение'] = PLAN_CAPABILITIES.DOWNGRADE;
    } else {
      const { addOn, capability } = denialReason;
      if (!!addOn) {
        //@ts-ignore
        eventParams['Модуль'] = addOn;
      }
      if (!!capability) {
        //@ts-ignore
        eventParams['Ограничение'] = capability;
      }
    }

    this.carrotquestHelper.track(eventName, eventParams);
  }

  /**
   * Трек показа баннера
   *
   * @param denialReason - Причина | Причины отказа в доступе
   */
  trackShowBanner(denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[]): void {
    const eventName = 'Тариф коммуникации - показ бампера';
    let eventParams = {};

    if (isArray(denialReason)) {
      //@ts-ignore
      eventParams['Ограничение'] = PLAN_CAPABILITIES.DOWNGRADE;
    } else {
      const { addOn, capability } = denialReason;
      if (!!addOn) {
        //@ts-ignore
        eventParams['Модуль'] = addOn;
      }
      if (!!capability) {
        //@ts-ignore
        eventParams['Ограничение'] = capability;
      }
    }

    this.carrotquestHelper.track(eventName, eventParams);
  }
}

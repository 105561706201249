import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RESIZE_OPTION_BOX, ResizeObserverService } from '@ng-web-apis/resize-observer';
import { tuiPure } from '@taiga-ui/cdk';
import { distinctUntilChanged, map, shareReplay, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { FeatureModel } from '@http/feature/feature.model';
import {
  INTEGRATION_CLASSES,
  INTEGRATION_CLASSES_ARRAY,
  INTEGRATION_TYPES,
  INTEGRATIONS_BY_CLASS,
} from '@http/integration/constants/integration.constants';
import { Integration } from '@http/integration/integration';
import { DestroyService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Дополнительное значение в фильтрации интеграций по классу, выбрав которое отобразятся все классы интеграций
 */
const allIntegrationClasses = 'all';

/**
 * Состояния интеграций
 * @enum {string}
 */
enum IntegrationTypeState {
  /** Все интеграции */
  All = 'all',
  /** Только настроенные */
  Configured = 'configured',
}

/**
 * Брейкпоинты ширины компонента, при которых изменяется количество интеграций в строке
 */
type WidthBreakpoints = 0 | 990 | 1230;

@Component({
  selector: 'cq-integration-list[configuredIntegrations][currentApp][djangoUser][integrationsLimit]',
  templateUrl: './integration-list.component.html',
  styleUrls: ['./integration-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    ResizeObserverService,
    {
      provide: RESIZE_OPTION_BOX,
      useValue: 'border-box',
    },
  ],
})
export class IntegrationListComponent implements OnInit {
  @Input()
  currentApp!: App;

  @Input()
  djangoUser!: any; // TODO ANGULAR_TS сделать описание djangoUser

  /**
   * Список настроенных интеграций
   */
  @Input()
  configuredIntegrations!: Integration[];

  /**
   * Максимальное количество настроенных интеграций
   */
  @Input()
  integrationsLimit!: number;

  /** Доступ до интеграций */
  accessToIntegrations: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  readonly INTEGRATIONS_BY_CLASS = INTEGRATIONS_BY_CLASS;
  readonly projectName = environment.projectName;

  readonly allIntegrationClasses = allIntegrationClasses;
  readonly IntegrationTypeState = IntegrationTypeState;

  /**
   * CSS-стиль flex-basis для правильной сетки из карточек интеграций
   */
  readonly flexBasis$ = this.resizeObserver$.pipe(
    takeUntil(this.destroy$),
    map((entries) => entries[0].contentRect.width),
    map((width) => this.calcIntegrationsInARow(width)),
    distinctUntilChanged(),
    map((count) => this.domSanitizer.bypassSecurityTrustStyle(`calc(100% / ${count} - ${this.gapBetweenCards}px)`)),
    // этот оператор нужен для *ngFor, чтобы предыдущие операторы не вызывались каждый раз при подписке
    // NOTE: вызова обычного share() оказалось недостаточно, т.к. при фильтрации новые появившиеся в списке интеграции при подписке не получали последнее значение
    //  подробнее читай тут https://www.learnrxjs.io/learn-rxjs/operators/multicasting/sharereplay
    shareReplay(1),
  );

  /**
   * Фильтр по наличию настроенных интеграций
   */
  integrationTypeStateFilter: IntegrationTypeState = IntegrationTypeState.All;

  /**
   * Фильтр по классу интеграций
   */
  integrationClassFilter: INTEGRATION_CLASSES | typeof allIntegrationClasses = allIntegrationClasses;

  /**
   * Промежуток в пикселях между карточками интеграций
   */
  readonly gapBetweenCards = 20;

  /**
   * Оставшееся количество интеграций, при котором показывается предупреждение об ограничении их максимального количества
   */
  readonly remainingIntegrationCountForWarning = 10;

  /**
   * Отношение ширины компонента и количеству карточек интеграций в строке
   */
  private readonly widthBreakpoints: [WidthBreakpoints, number][] = [
    [1230, 5],
    [990, 4],
    [0, 3],
  ];

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    private domSanitizer: DomSanitizer,
    private readonly resizeObserver$: ResizeObserverService,
    readonly featureModel: FeatureModel,
    readonly planFeatureAccessService: PlanFeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.accessToIntegrations = this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS, this.currentApp);

    this.trackEnteringToList();
  }

  // NOTE: функции для геттеров вынесены для того, чтобы закешировать их результат при помощи @tuiPure

  /**
   * Классы интеграций, которые доступны в аппе
   */
  get accessibleIntegrationClasses(): INTEGRATION_CLASSES[] {
    return this.getAccessibleIntegrationClasses(this.accessibleIntegrationTypes);
  }

  /**
   * Интеграции, которые доступны в аппе
   */
  get accessibleIntegrationTypes(): INTEGRATION_TYPES[] {
    return this.getAccessibleIntegrationTypes(this.configuredIntegrationTypes);
  }

  /**
   * Типы только настроенных интеграций
   */
  get configuredIntegrationTypes(): INTEGRATION_TYPES[] {
    return this.getConfiguredIntegrationTypes(this.configuredIntegrations);
  }

  /**
   * Фильтрованные типы интеграций
   */
  get filteredIntegrationTypes(): INTEGRATION_TYPES[] {
    return this.getFilteredIntegrationTypes(
      this.accessibleIntegrationTypes,
      this.integrationClassFilter,
      this.integrationTypeStateFilter,
    );
  }

  /**
   * Фильтрованные классы интеграций
   */
  get filteredIntegrationClasses(): INTEGRATION_CLASSES[] {
    return this.getFilteredIntegrationClasses(this.accessibleIntegrationClasses, this.filteredIntegrationTypes);
  }

  /**
   * Есть ли у пользователя доступ к разделу
   */
  get hasAccess(): boolean {
    return this.accessToIntegrations.hasAccess;
  }

  /**
   * Получение количества оставшихся интеграций
   */
  get remainingIntegrationsCount(): number {
    return Math.max(0, this.integrationsLimit - this.configuredIntegrations.length);
  }

  /**
   * Рассчёт количества интеграций в строке
   *
   * @param componentWidth Ширина текущего компонента
   */
  calcIntegrationsInARow(componentWidth: number): number {
    // NOTE: использую "!", т.к ширина компонента не может быть отрицательной, поэтому find всегда вернёт какое-то значение
    return this.widthBreakpoints.find((breakpoint) => componentWidth >= breakpoint[0])![1];
  }

  /**
   * Получение доступных аппу классов интеграций
   *
   * @param accessibleIntegrationTypes Доступные аппу типы интеграций
   * @private
   */
  @tuiPure
  private getAccessibleIntegrationClasses(accessibleIntegrationTypes: INTEGRATION_TYPES[]): INTEGRATION_CLASSES[] {
    return INTEGRATION_CLASSES_ARRAY.filter((integrationClass) => {
      return INTEGRATIONS_BY_CLASS[integrationClass].some((integrationType) => {
        return accessibleIntegrationTypes.includes(integrationType);
      });
    });
  }

  /**
   * Получение доступных аппу типов интеграций
   * Если интеграция аппу не доступна по фичагалке, но есть хотя бы одна такая настроенная интеграция - она тоже считается доступной и выводится в списке
   *
   * NOTE: @tuiPure используется не смотря на то, что внутри функции вызывается featureModel.hasAccess(), т.к. фичагалки сейчас не обновляются в реальном времени
   *
   * @param configuredIntegrationTypes
   * @private
   */
  @tuiPure
  private getAccessibleIntegrationTypes(configuredIntegrationTypes: INTEGRATION_TYPES[]): INTEGRATION_TYPES[] {
    return Object.values(INTEGRATION_TYPES).filter((integrationType) => {
      // если у пользователя настроена недоступная ему интеграция - её тоже нужно вывести в списке
      return this.featureModel.hasAccess(integrationType) || configuredIntegrationTypes.includes(integrationType);
    });
  }

  /**
   * Получение количества настроенных интеграций определённого типа
   *
   * @param type Тип интеграции
   */
  getConfiguredIntegrationTypeCount(type: INTEGRATION_TYPES): number {
    return this.configuredIntegrations.filter((integration) => integration.type === type).length;
  }

  /**
   * Получение типов настроенных интеграций
   *
   * @param configuredIntegrations Список настроенных интеграций
   */
  @tuiPure
  private getConfiguredIntegrationTypes(configuredIntegrations: Integration[]): INTEGRATION_TYPES[] {
    return configuredIntegrations
      .map((integration) => integration.type)
      .filter((integrationType, index, array) => array.indexOf(integrationType) === index);
  }

  /**
   * Получение отфильтрованных классов интеграций
   *
   * @param accessibleIntegrationClasses Доступные аппу классы интеграции
   * @param filteredIntegrationTypes Отфильтрованные типы интеграций
   */
  @tuiPure
  private getFilteredIntegrationClasses(
    accessibleIntegrationClasses: INTEGRATION_CLASSES[],
    filteredIntegrationTypes: INTEGRATION_TYPES[],
  ): INTEGRATION_CLASSES[] {
    return accessibleIntegrationClasses.filter((integrationClass) => {
      return INTEGRATIONS_BY_CLASS[integrationClass].some((integrationType) => {
        // если хотя бы одна интеграция из класса отображается - отображается весь класс
        return filteredIntegrationTypes.includes(integrationType);
      });
    });
  }

  /**
   * Получение отфильтрованных типов интеграций
   *
   * @param accessibleIntegrationTypes Типы интеграций, которые доступны аппу
   * @param integrationClassFilter Фильтр по классам интеграций
   * @param integrationTypeStateFilter Фильтр по наличию настроенных интеграций
   * @private
   */
  @tuiPure
  private getFilteredIntegrationTypes(
    accessibleIntegrationTypes: INTEGRATION_TYPES[],
    integrationClassFilter: INTEGRATION_CLASSES | typeof allIntegrationClasses,
    integrationTypeStateFilter: IntegrationTypeState,
  ): INTEGRATION_TYPES[] {
    return accessibleIntegrationTypes
      .filter((integrationType) => {
        // если выбран фильтр "Все" - должны отображаться все классы интеграций
        if (integrationClassFilter === allIntegrationClasses) {
          return true;
        }

        // фильтр по классу
        return INTEGRATIONS_BY_CLASS[integrationClassFilter].includes(integrationType);
      })
      .filter((integrationType) => {
        // фильтр по наличию настроенных интеграций
        if (integrationTypeStateFilter === IntegrationTypeState.Configured) {
          return this.configuredIntegrationTypes.includes(integrationType);
        }

        return true;
      });
  }

  /**
   * Открытие чата
   */
  openChat(): void {
    this.carrotquestHelper.open();
  }

  /**
   * Сброс фильтрации списка интеграций
   */
  resetFilters(): void {
    this.integrationTypeStateFilter = IntegrationTypeState.All;
    this.integrationClassFilter = allIntegrationClasses;
  }

  /**
   * Трекинг захода на страницу со списком интеграций
   */
  private trackEnteringToList(): void {
    this.carrotquestHelper.track('Интеграции - переход');
  }
}

/**
 * Директива для отображения каких-либо показателей статистики
 */
(function () {
  'use strict';

  require('./statistics-value.controller');

  angular.module('myApp.directives.statisticsValue').directive('cqStatisticsValue', cqStatisticsValue);

  function cqStatisticsValue() {
    return {
      restrict: 'E',
      require: 'cqStatisticsValue',
      scope: {
        entity: '@',
        firstMeasure: '=',
        firstValue: '=',
        placeholder: '@',
        secondMeasure: '=?',
        secondValue: '=?',
        tooltip: '@',
        valueClass: '@',
      },
      controller: 'CqStatisticsValueController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
      template: require('./statistics-value.html'),
    };

    function link(scope, iElement, iAttrs, controller) {
      scope.$watchCollection('[vm.firstValue, vm.firstMeasure]', function (newValues) {
        controller.parseFirstValue(newValues[0], newValues[1]);
      });

      if (angular.isDefined(controller.secondValue)) {
        scope.$watchCollection('[vm.secondValue, vm.secondMeasure]', function (newValues) {
          controller.parseSecondValue(newValues[0], newValues[1]);
        });
      }
    }
  }
})();

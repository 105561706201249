import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { tuiPure } from '@taiga-ui/cdk';

import { Conversation } from '@http/conversation/conversation.types';
import { CONVERSATION_ATTACHMENT_MIME_TYPES } from '@http/conversation-part/conversation-part.constants';
import { ConversationPart } from '@http/conversation-part/conversation-part.types';

type AttachmentType = 'image' | 'file';

@Component({
  selector: 'cq-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
  @Input()
  conversation!: Conversation;

  constructor() {}

  /** Правильная реплика диалога */
  get correctPart(): ConversationPart {
    return this.getCorrectPart(this.conversation);
  }

  /** Формат файла в прикреплении. undefined, если прикрепление отсутствует */
  get attachmentType(): AttachmentType | undefined {
    if (!this.correctPart.attachments) {
      return;
    }

    const image = CONVERSATION_ATTACHMENT_MIME_TYPES.IMAGE.slice(0, -1); // убираем последний символ для парсинга по константе

    if (this.correctPart.attachments[0].mime_type.includes(image)) {
      return 'image';
    } else {
      return 'file';
    }
  }

  /**
   * Получение правильной реплики диалога
   * При поиске по диалогам следует брать всю информацию из found_part, т.е. из той реплики, которая была найдена
   * В обычной ситуации информацию нужно брать из important_part_last
   *
   * @param conversation Диалог
   */
  @tuiPure
  getCorrectPart(conversation: Conversation): ConversationPart {
    if (conversation.isSearching) {
      // по идее, если isSearching === true, то и found_part точно должна существовать
      // но как это сделать так, чтобы TS не ругался - я не понял, поэтому добавил ещё одну проверку, на всякий случай
      if (conversation.found_part) {
        return conversation.found_part;
      }
    }

    return conversation.important_part_last;
  }
}

/**
 * Контроллер для страницы со всеми элементами административной панели
 */
(function () {
  'use strict';

  angular.module('myApp.serviceSection').controller('VisualComponentsController', VisualComponentsController);

  function VisualComponentsController(
    $document,
    $injector,
    $scope,
    $q,
    $timeout,
    $uibModal,
    moment,
    toastr,
    dateRangePickerHelper,
    validationHelper,
    wizardHelper,
  ) {
    var vm = this;

    vm.$onInit = init;

    vm.alerts = {
      types: ['primary', 'secondary', 'success', 'warning', 'danger'],
      closeNgBootstrap: closeNgBootstrapAlert,
      closeUiBootstrap: closeUiBootstrapAlert,
    };
    vm.buttons = {
      checkbox: {
        middle: false,
        left: true,
        right: false,
      },
      radio: 'Left',
    };
    vm.collapse = {
      collapsed: collapsed,
      collapsing: collapsing,
      expanded: expanded,
      expanding: expanding,
      isCollapsed1: false,
      isCollapsed2: false,
      isCollapsed3: false,
      isContentShouldExist: true,
    };
    vm.dateRangePicker = {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      options: angular.extend(dateRangePickerHelper.getOptions(), {}),
    };
    vm.emojiSelector = {
      addEmoji: addEmoji,
      inputModel: '',
    };
    vm.forms = {
      email: '123@',
      isRequestPerformed: false,
      isSubmitted: false,
      name: '',
      password: '',
      radio: '1',
      submitTestForm: submitTestForm,
      surname: '',
      timeoutPromise: null,
    };
    vm.icons = {
      currentSizeClass: '',
      icons: {
        'cqi-chat': [
          'cqi-activity',
          'cqi-angry-o',
          'cqi-angry',
          'cqi-arrow-down',
          'cqi-arrow-left-thin',
          'cqi-arrow-left',
          'cqi-arrow-right',
          'cqi-arrow-up',
          'cqi-article',
          'cqi-attach',
          'cqi-check-circle-o',
          'cqi-chevron-down',
          'cqi-chevron-left',
          'cqi-chevron-right',
          'cqi-chevron-up',
          'cqi-external-link',
          'cqi-facebook',
          'cqi-file',
          'cqi-flags',
          'cqi-food',
          'cqi-happy-o',
          'cqi-happy',
          'cqi-img',
          'cqi-instagram',
          'cqi-menu-burger',
          'cqi-nature',
          'cqi-neutral-o',
          'cqi-neutral',
          'cqi-objects',
          'cqi-paperclip',
          'cqi-paperplane',
          'cqi-pen',
          'cqi-pencil',
          'cqi-people',
          'cqi-places',
          'cqi-pop-up',
          'cqi-search',
          'cqi-smile',
          'cqi-spinner',
          'cqi-symbols',
          'cqi-telegram',
          'cqi-times',
          'cqi-viber',
          'cqi-vk',
          'cqi-whatsapp',
        ],
        'cqi-xs': [
          'cqi-hash',
          'cqi-minus-circle',
          'cqi-plus-circle',
          'cqi-plus',
          'cqi-times',
          'cqi-triangle-exclamation',
        ],
        'cqi-sm': [
          'cqi-ab-test',
          'cqi-adjust',
          'cqi-align-center',
          'cqi-align-justify',
          'cqi-align-left',
          'cqi-align-right',
          'cqi-amocrm',
          'cqi-android',
          'cqi-apple',
          'cqi-archive',
          'cqi-arrow-alt-circle-up',
          'cqi-arrow-down',
          'cqi-arrow-from-top-to-right',
          'cqi-arrow-left',
          'cqi-arrow-right',
          'cqi-arrow-turning-down',
          'cqi-arrow-up',
          'cqi-arrows-alt',
          'cqi-arrows-alt-v',
          'cqi-automsgs',
          'cqi-b-i-u',
          'cqi-ball',
          'cqi-ban',
          'cqi-bars',
          'cqi-bars-v',
          'cqi-bell',
          'cqi-bell-o',
          'cqi-block-popup-big',
          'cqi-block-popup-small',
          'cqi-bold',
          'cqi-book',
          'cqi-braces',
          'cqi-burger',
          'cqi-calendar',
          'cqi-calendar-o',
          'cqi-calendly',
          'cqi-camera-viewfinder',
          'cqi-car',
          'cqi-chart-bar',
          'cqi-chart-pie',
          'cqi-check',
          'cqi-check-circle',
          'cqi-check-circle-o',
          'cqi-check-read',
          'cqi-check-sent',
          'cqi-checklist',
          'cqi-chevron-down',
          'cqi-chevron-left',
          'cqi-chevron-right',
          'cqi-chevron-up',
          'cqi-chrome',
          'cqi-circle',
          'cqi-circle-dollar',
          'cqi-circle-exclamation',
          'cqi-circle-user',
          'cqi-clock',
          'cqi-code',
          'cqi-copy-in',
          'cqi-cog',
          'cqi-comment-in',
          'cqi-comment-in-unread',
          'cqi-comment-out-о',
          'cqi-comment-out-filled',
          'cqi-comments',
          'cqi-compress',
          'cqi-copy',
          'cqi-credit-card',
          'cqi-cursor-arrow',
          'cqi-cursor-click',
          'cqi-desktop',
          'cqi-download',
          'cqi-ellipsis',
          'cqi-ellipsis-v',
          'cqi-envelope',
          'cqi-envelope-o',
          'cqi-envelope-ban',
          'cqi-envelope-check',
          'cqi-envelope-plus',
          'cqi-envelopes',
          'cqi-eraser',
          'cqi-event-never-triggered',
          'cqi-exchange-alt',
          'cqi-exit',
          'cqi-expand',
          'cqi-export',
          'cqi-external-link',
          'cqi-eye',
          'cqi-eye-closed',
          'cqi-facebook',
          'cqi-file-invoice',
          'cqi-film',
          'cqi-filter',
          'cqi-fire',
          'cqi-fire-o',
          'cqi-flash-o',
          'cqi-flash-filled',
          'cqi-flag',
          'cqi-folder',
          'cqi-font-family',
          'cqi-gift',
          'cqi-grip-vertical',
          'cqi-hand-pointer',
          'cqi-heading',
          'cqi-headset',
          'cqi-heart',
          'cqi-home-o',
          'cqi-home-filled',
          'cqi-image',
          'cqi-import',
          'cqi-indent-left',
          'cqi-indent-right',
          'cqi-info',
          'cqi-instagram',
          'cqi-italic',
          'cqi-keyboard',
          'cqi-level-down',
          'cqi-life-ring',
          'cqi-lightbulb',
          'cqi-lightning',
          'cqi-lightning-slash',
          'cqi-link',
          'cqi-list-ol',
          'cqi-list-ul',
          'cqi-lock',
          'cqi-magic',
          'cqi-map-marker',
          'cqi-merge',
          'cqi-minus',
          'cqi-minus-circle',
          'cqi-mobile',
          'cqi-mobile-block-popup',
          'cqi-mobile-comment-out',
          'cqi-mobile-push',
          'cqi-mortarboard',
          'cqi-move',
          'cqi-page',
          'cqi-paint-brush',
          'cqi-paper-plane',
          'cqi-paper-plane-o',
          'cqi-paperclip',
          'cqi-paragraph',
          'cqi-pause',
          'cqi-pencil',
          'cqi-phone',
          'cqi-play',
          'cqi-play-o',
          'cqi-play-circle',
          'cqi-plus',
          'cqi-plus-circle',
          'cqi-popup-big',
          'cqi-popup-big-o',
          'cqi-popup-small',
          'cqi-pin',
          'cqi-pin-strikethrough',
          'cqi-question-circle',
          'cqi-redo',
          'cqi-refresh',
          'cqi-refresh-2',
          'cqi-robot',
          'cqi-save',
          'cqi-search',
          'cqi-smile',
          'cqi-sort-ascending',
          'cqi-sort-descending',
          'cqi-spacing-lg',
          'cqi-spacing-md',
          'cqi-spacing-sm',
          'cqi-spacing-xs',
          'cqi-spinner',
          'cqi-split',
          'cqi-split-from-square',
          'cqi-square-o',
          'cqi-star',
          'cqi-stars',
          'cqi-step-backward',
          'cqi-step-forward',
          'cqi-stop',
          'cqi-stopwatch',
          'cqi-stopwatch-o',
          'cqi-strikethrough',
          'cqi-subscript',
          'cqi-superman-sign',
          'cqi-superscript',
          'cqi-square-arrow-up',
          'cqi-table',
          'cqi-tachometer',
          'cqi-tag',
          'cqi-tag-o',
          'cqi-tag-strikethrough',
          'cqi-telegram',
          'cqi-templates',
          'cqi-templates-arrow-down',
          'cqi-text-height',
          'cqi-text-i-cursor',
          'cqi-times',
          'cqi-tint',
          'cqi-trash',
          'cqi-tree',
          'cqi-triangle-exclamation',
          'cqi-triangle-exclamation-o',
          'cqi-twitter',
          'cqi-underline',
          'cqi-undo',
          'cqi-unlink',
          'cqi-unlock',
          'cqi-upload',
          'cqi-user',
          'cqi-user-add',
          'cqi-user-list',
          'cqi-user-o',
          'cqi-user-square',
          'cqi-users',
          'cqi-users-o',
          'cqi-viber',
          'cqi-vk',
          'cqi-whats-app',
          'cqi-web-push',
          'cqi-webhook',
          'cqi-window',
          'cqi-window-arrow-down',
          'cqi-windows',
          'cqi-yandex',
        ],
        'cqi-md': [
          'cqi-book',
          'cqi-book-o',
          'cqi-calendly',
          'cqi-chart-pie',
          'cqi-lightning-circle',
          'cqi-message-send',
          'cqi-note',
          'cqi-note-o',
          'cqi-paperclip',
          'cqi-smile',
          'cqi-smile-o',
          'cqi-templates',
          'cqi-templates-o',
          'cqi-whats-app',
          'cqi-zoom',
        ],
        'cqi-lg': [
          'cqi-button',
          'cqi-cart',
          'cqi-code',
          'cqi-comment-out-о',
          'cqi-cursor-default',
          'cqi-cursor-pointer',
          'cqi-cursor-text',
          'cqi-equals',
          'cqi-footer',
          'cqi-html',
          'cqi-image',
          'cqi-indent',
          'cqi-input-with-big-button',
          'cqi-input-with-button',
          'cqi-plus',
          'cqi-puzzle-piece',
          'cqi-text',
          'cqi-toggles',
          'cqi-video',
          'cqi-viewed-products',
          'cqi-window',
          'cqi-window-scroll',
        ],
        'cqi-channels': [
          'cqi-all-channels',
          'cqi-email',
          'cqi-facebook',
          'cqi-instagram',
          'cqi-telegram',
          'cqi-viber',
          'cqi-vk',
          'cqi-whatsapp',
          'cqi-without-channel',
          'cqi-yandex',
          'cqi-yandex-dialogs',
          'cqi-mobile',
        ],
        'cqi-navigation': [
          'cqi-card',
          'cqi-cog',
          'cqi-conversation',
          'cqi-graphs',
          'cqi-home-o',
          'cqi-mortarboard',
          'cqi-paper-plane',
          'cqi-puzzle',
          'cqi-robot',
          'cqi-users',
          'cqi-series',
        ],
      },
      sizeClasses: ['', 'cqi-03x', 'cqi-2x', 'cqi-3x'],
    };
    vm.modals = {
      openConfirmModal: openConfirmModal,
      openModal: openModal,
      openPromptModal: openPromptModal,
    };
    vm.navs = {
      active: 0,
    };
    vm.changeComponentVisibility = changeComponentVisibility;
    vm.onSelectAllComponents = onSelectAllComponents;
    vm.selectAllComponents = false;
    vm.viewsPath = 'js/components/service-section/visual-components/views';
    vm.components = [
      {
        name: 'Сетка',
        value: 'grid',
        visible: isVisible('grid'),
      },
      {
        name: 'Типографика',
        value: 'typography',
        visible: isVisible('typography'),
      },
      {
        name: 'Иконки',
        value: 'icons',
        visible: isVisible('icons'),
      },
      {
        name: 'Таблицы',
        value: 'tables',
        visible: isVisible('tables'),
      },
      {
        name: 'Формы и элементы форм',
        value: 'forms',
        visible: isVisible('forms'),
      },
      {
        name: 'Кнопки',
        value: 'buttons',
        visible: isVisible('buttons'),
      },
      {
        name: 'Выпадающие списки',
        value: 'dropdowns',
        visible: isVisible('dropdowns'),
      },
      {
        name: 'Навигация',
        value: 'navs',
        visible: isVisible('navs'),
      },
      {
        name: 'Хлебные крошки',
        value: 'breadcrumbs',
        visible: isVisible('breadcrumbs'),
      },
      {
        name: 'Badges',
        value: 'badges',
        visible: isVisible('badges'),
      },
      {
        name: 'Уведомления',
        value: 'alerts',
        visible: isVisible('alerts'),
      },
      {
        name: 'Всплывающие уведомления (toasts)',
        value: 'toasts',
        visible: isVisible('toasts'),
      },
      {
        name: 'Progress bars',
        value: 'progress-bars',
        visible: isVisible('progress-bars'),
      },
      {
        name: 'Карточки',
        value: 'cards',
        visible: isVisible('cards'),
      },
      {
        name: 'Модальные окна',
        value: 'modals',
        visible: isVisible('modals'),
      },
      {
        name: 'Подсказки',
        value: 'tooltips',
        visible: isVisible('tooltips'),
      },
      {
        name: 'Popovers',
        value: 'popovers',
        visible: isVisible('popovers'),
      },
      {
        name: 'Collapse',
        value: 'collapse',
        visible: isVisible('collapse'),
      },
      {
        name: 'Аккордеон',
        value: 'accordion',
        visible: isVisible('accordion'),
      },
      {
        name: 'Date range picker',
        value: 'date-range-picker',
        visible: isVisible('date-range-picker'),
      },
      {
        name: 'Ui-select/Ng-select',
        value: 'ui-select',
        visible: isVisible('ui-select'),
      },
      {
        name: 'Emoji selector',
        value: 'emoji-selector',
        visible: isVisible('emoji-selector'),
      },
      {
        name: 'Statistics value',
        value: 'statistics-value',
        visible: isVisible('statistics-value'),
      },
      {
        name: 'Sticky scroll',
        value: 'sticky-scroll',
        visible: isVisible('sticky-scroll'),
      },
      {
        name: 'Переключатели',
        value: 'switches',
        visible: isVisible('switches'),
      },
      {
        name: 'Wizard',
        value: 'wizard',
        visible: isVisible('wizard'),
      },
      {
        name: 'Zero data',
        value: 'zero-data',
        visible: isVisible('zero-data'),
      },
      {
        name: 'Тест на XSS',
        value: 'xss-test',
        visible: isVisible('xss-test'),
      },
      {
        name: 'Тег пользователя',
        value: 'user-tag',
        visible: isVisible('user-tag'),
      },
    ];
    vm.popovers = {
      closeDelay: 500,
      delay: 500,
      enabled: true,
      html: '<h4>Заголовок</h4> <span>Какой-то текст</span> <span class="label label-danger">Label</span>',
      isOpen: true,
      template: 'js/components/service-section/visual-components/templates/popover.html',
      templateTitle: 'Заголовок',
    };
    vm.progressBars = {
      animate: true,
      height: '20px',
      max: 100,
      randomizeProgressValue: randomizeProgressValue,
      stackedValue: 20,
      value: 50,
    };
    vm.statisticsValue = {
      value: 20,
      measure: '\u20BD',
      arrayValue: [
        {
          value: 30,
          measure: '\u20BD',
        },
        {
          value: 50,
          measure: 'коп.',
        },
      ],
    };
    vm.switch = {
      currentValue: true,
      onSwitchChange: onSwitchChange,
    };
    vm.tables = {
      alignMiddle: true,
      bordered: false,
      borderless: false,
      sm: false,
      row: {
        data1: Math.random().toString(36).substring(2, 15),
        data2: Math.random().toString(36).substring(2, 15),
        data3: Math.random().toString(36).substring(2, 15),
        data4: Math.random().toString(36).substring(2, 15),
        data5: Math.random().toString(36).substring(2, 15),
        data6: Math.random().toString(36).substring(2, 15),
        data7: Math.random().toString(36).substring(2, 15),
        data8: Math.random().toString(36).substring(2, 15),
        data9: Math.random().toString(36).substring(2, 15),
        data10: Math.random().toString(36).substring(2, 15),
      },
      data: [],
      hover: false,
      responsive: false,
      stripped: false,
    };
    vm.toasts = {
      openToast: openToast,
      openNewToast: openNewToast,
    };
    vm.tooltips = {
      closeDelay: 500,
      delay: 500,
      enabled: true,
      html: '<h4>Заголовок</h4> <span>Какой-то текст</span> <span class="label label-danger">Label</span>',
      isOpen: true,
      removeLastTitleSymbol: removeLastTitleSymbol,
      template: 'js/components/service-section/visual-components/templates/tooltip.html',
      templateTitle: 'Заголовок',
    };
    vm.validationHelper = validationHelper;
    vm.uiSelect = {
      closeOnSelect: true,
      defaultItem: {
        name: '',
      },
      focusMultiple: focusMultiple,
      focusSingle: focusSingle,
      items: [
        {
          id: 1,
          name: 'Первый элемент',
          group: 'Группа А',
        },
        {
          id: 2,
          name: 'Второй элемент',
          group: 'Группа Б',
        },
        {
          id: 3,
          name: 'Третий элемент',
          group: 'Группа А',
        },
        {
          id: 4,
          name: 'Четвёртый элемент',
          group: 'Группа Б',
        },
        {
          id: 5,
          name: 'Пятый элемент',
          group: 'Группа А',
        },
        {
          id: 6,
          name: 'Шестой элемент',
          group: 'Группа Б',
        },
        {
          id: 7,
          name: 'Седьмой элемент',
          group: 'Группа А',
        },
        {
          id: 8,
          name: 'Восьмой элемент',
          group: 'Группа Б',
        },
        {
          id: 9,
          name: 'Девятый элемент',
          group: 'Группа А',
        },
        {
          id: 10,
          name: 'Десятый элемент',
          group: 'Группа Б',
        },
      ],
      lockChoiceId: 1,
      onHighlight: onHighlight,
      onOpenClose: onOpenClose,
      onRemove: onRemove,
      onSelect: onSelect,
      selectedItem: null,
      selectedItems: [],
      taggingFunction: taggingFunction,
    };
    vm.userTags = {
      isContains: false,
      isSelected: false,
      removed: {
        app: '4',
        id: '24',
        name: 'new tag',
        removed: moment,
      },
      nonRemoved: {
        app: '4',
        id: '24',
        name: 'new tag',
        removed: null,
      },
      onContainsChange: () => {
        console.log('onContainsChange callback');
      },
      onRemove: () => {
        console.log('onRemove callback');
      },
      onSelectChange: () => {
        console.log('onSelectChange callback');
      },
    };
    vm.wizard = {
      currentStep: null,
      instance: null,
      lastCompletedStep: null,
      selectStep: selectStep,
      steps: {
        step1: {
          name: 1,
          heading: 'Обычный шаг',
          templateUrl: 'js/components/service-section/visual-components/steps/step-1.html',
        },
        step2: {
          name: 2,
          heading: "Шаг с ng-if для контента (не жрёт watcher'ов)",
          templateUrl: 'js/components/service-section/visual-components/steps/step-2.html',
        },
        step3: {
          name: 3,
          heading: 'Шаг с колбэками',
          onEnterCallback: onEnterCallback,
          onExitCallback: onExitCallback,
          templateUrl: 'js/components/service-section/visual-components/steps/step-3.html',
        },
        step4: {
          name: {
            stepNumber: 4,
            extraInfo: 'iddqd',
          },
          heading: 'Шаг с названием-объектом и отложенными колбэками (Promises)',
          onEnterDeferredCallback: onEnterDeferredCallback,
          onExitDeferredCallback: onExitDeferredCallback,
          templateUrl: 'js/components/service-section/visual-components/steps/step-4.html',
        },
        step5: {
          name: 5,
          heading: 'Шаг с формой и колбэком валидации этой формы',
          onExitFormCallback: onExitFormCallback,
          templateUrl: 'js/components/service-section/visual-components/steps/step-5.html',
        },
        step6: {
          name: 6,
          heading: 'Последний шаг',
          finalCallback: angular.noop,
          templateUrl: 'js/components/service-section/visual-components/steps/step-6.html',
        },
      },
    };
    vm.xssTest = {
      nonEscaped: '<div style="color: red" onmouseover="console.log(123)">Красный</div>',
      escaped: '&lt;div style="color: red" onmouseover="console.log(123)"&gt;Красный&lt;/div&gt;',
    };

    init();

    function init() {
      generateTableData(vm.tables);
      wizardHelper.getWizard().then(getWizardSuccess);

      function getWizardSuccess(wizard) {
        vm.wizard.instance = wizard;
      }
    }

    /**
     * Вставляет emojiName в поле ввода на место курсора
     *
     * @param {String} emojiName
     */
    function addEmoji({ emojiName }) {
      var textAreaElem = $document.find('#emojiInput')[0];

      if (angular.isDefined(textAreaElem.selectionStart)) {
        var selectionStart = textAreaElem.selectionStart;
        var selectionEnd = textAreaElem.selectionEnd;
        vm.emojiSelector.inputModel =
          vm.emojiSelector.inputModel.substring(0, selectionStart) +
          emojiName +
          vm.emojiSelector.inputModel.substring(selectionEnd, textAreaElem.value.length);
        textAreaElem.selectionStart = selectionStart + emojiName.length;
        textAreaElem.selectionEnd = selectionEnd + emojiName.length;
      } else {
        vm.emojiSelector.inputModel += emojiName;
      }

      textAreaElem.focus();
    }

    /**
     * Вызывается при закрытии алерта
     *
     * @param {string} alertType Тип алерта
     */
    function closeNgBootstrapAlert(alertType) {
      // вызов $apply в данном случае необходим из-за работы downgradeComponent, иначе alert уберётся из DOM не сразу же после удаления alertType
      // подробнее можно почитать вот тут https://angular.io/guide/upgrade-performance#change-detection-with-downgrademodule
      $scope.$apply(() => {
        console.log('Уведомление закрыто');

        vm.alerts.types.splice(vm.alerts.types.indexOf(alertType), 1);
      });
    }

    /**
     * Вызывается при закрытии алерта
     */
    function closeUiBootstrapAlert() {
      console.log('Уведомление закрыто');
    }

    /**
     * Вызывается при полном сворачивании коллапса
     */
    function collapsed() {
      vm.collapse.isContentShouldExist = false;
      console.log('Контент свёрнут');
    }

    /**
     * Вызывается при начале сворачивания коллапса
     */
    function collapsing() {
      console.log('Контент сворачивается...');
    }

    /**
     * Вызывается при полном разворачивании коллапса
     */
    function expanded() {
      console.log('Контент развёрнут');
    }

    /**
     * Вызывается при начале разворачивания коллапса
     *
     * @return {Promise}
     */
    function expanding() {
      console.log('Контент разворачивается...');

      var deferred = $q.defer();

      fakeRequest().then(expandingSuccess);

      return deferred.promise;

      function fakeRequest() {
        var deferred = $q.defer();

        $timeout(fakeRequestTimeout, 500);

        return deferred.promise;

        function fakeRequestTimeout() {
          vm.collapse.isContentShouldExist = true;
          deferred.resolve();
        }
      }

      function expandingSuccess() {
        $timeout(function () {
          deferred.resolve();
        }, 0);
      }
    }

    /**
     * Посылает event для фокуса мультиселекта ui-select
     */
    function focusMultiple() {
      $scope.$broadcast('focusUiSelectMultiple');
    }

    /**
     * Посылает event для фокуса одиночного ui-select
     */
    function focusSingle() {
      $scope.$broadcast('focusUiSelectSingle');
    }

    /**
     * Генерация данных для таблицы
     *
     * @param {Object} table Объект таблицы
     */
    function generateTableData(table) {
      table.data = [];

      for (var i = 0; i < 10; i++) {
        var currentRow = angular.copy(table.row);

        switch (i) {
          case 0: {
            currentRow.colorClass = 'table-active';
            break;
          }
          case 1: {
            currentRow.colorClass = 'table-primary';
            break;
          }
          case 2: {
            currentRow.colorClass = 'table-secondary';
            break;
          }
          case 3: {
            currentRow.colorClass = 'table-success';
            break;
          }
          case 4: {
            currentRow.colorClass = 'table-warning';
            break;
          }
          case 5: {
            currentRow.colorClass = 'table-danger';
            break;
          }
        }

        table.data.push(currentRow);
      }
    }

    /**
     * Проверка отображения компонента во время его инициализации
     *
     * @param value
     * @returns {boolean}
     */
    function isVisible(value) {
      const hiddenItems = JSON.parse(localStorage.getItem('carrotquest_visual_components')) || [];
      return !!~hiddenItems.indexOf(value);
    }

    /**
     * Смена отображения компонента на странице
     *
     * @param {Object} component — Компонент
     */
    function changeComponentVisibility(component) {
      let hiddenItems = JSON.parse(localStorage.getItem('carrotquest_visual_components')) || [];
      if (component.visible) {
        if (!~hiddenItems.indexOf(component.value)) {
          hiddenItems.push(component.value);
        }
      } else {
        hiddenItems.splice(hiddenItems.indexOf(component.value), 1);
      }

      localStorage.setItem('carrotquest_visual_components', JSON.stringify(hiddenItems));
    }

    /**
     * Показ или скрытие всех компонентов
     */
    function onSelectAllComponents() {
      for (let i = 0; i < vm.components.length; i++) {
        vm.components[i].visible = vm.selectAllComponents;
        changeComponentVisibility(vm.components[i]);
      }
    }

    /**
     * Выполняется при выборе emoji из списка
     */
    function onEmojiSelect() {
      console.log('Emoji выбрана и вставлена в input');
    }

    /**
     * Выполняется при открытии cq-emoji-selector
     */
    function onEmojiSelectorOpen() {
      console.log('cq-emoji-selector открыт');
    }

    /**
     * Колбэк входа на шаг визарда
     */
    function onEnterCallback() {
      console.log('Зашёл на шаг!');
    }

    /**
     * Колбэк входа на шаг с промисом
     *
     * @return {Promise}
     */
    function onEnterDeferredCallback() {
      console.log('Эмуляция запроса на сервер (заход на шаг)');

      var deferred = $q.defer();

      $timeout(timeoutFunction, 2000);

      return deferred.promise;

      function timeoutFunction() {
        console.log('Сервер ответил! (заход на шаг)');
        deferred.resolve();
      }
    }

    /**
     * Колбэк выхода с шага визарда
     */
    function onExitCallback() {
      console.log('Ушёл с шага!');
    }

    /**
     * Колбэк выхода с шага с промисом
     *
     * @return {Promise}
     */
    function onExitDeferredCallback() {
      console.log('Эмуляция запроса на сервер (выход с шага)');

      var deferred = $q.defer();

      $timeout(timeoutFunction, 2000);

      return deferred.promise;

      function timeoutFunction() {
        console.log('Сервер ответил! (выход с шага)');
        deferred.resolve();
      }
    }

    /**
     * Колбэк выхода с шага с валидацией формы
     *
     * @param {form.FormController} form
     * @return {*}
     */
    function onExitFormCallback(form) {
      console.log('Валидирую форму...');
      form.$commitViewValue();
      form.$setSubmitted();

      if (form.$invalid) {
        console.log('Валидация не пройдена!');
        return $q.reject();
      }
      console.log('Валидация пройдена!');
    }

    function onHighlight(item) {
      console.log('on-highlight');
      console.log('item: ', item);
    }

    /**
     * Функция-callback на открытие/закрытие селекта ui-select
     *
     * @param {Boolean} isOpen Открылся или нет селект
     */
    function onOpenClose(isOpen) {
      console.log('uis-open-close');
      console.log('isOpen: ', isOpen);
    }

    /**
     * Функция-callback на удаление элемента из ui-select
     *
     * @param {*} item Удалённый элемент
     * @param {*} model Значение, которое удалено из модели
     */
    function onRemove(item, model) {
      console.log('on-remove');
      console.log('$item: ', item);
      console.log('$model: ', model);
    }

    /**
     * Функция-callback на выбор элемента из ui-select
     *
     * @param {*} item Выбранный элемент из списка
     * @param {*} model Значение, которое запишется в модель
     */
    function onSelect(item, model) {
      console.log('on-select');
      console.log('$item: ', item);
      console.log('$model: ', model);
    }

    /**
     * Функция, вызываемая при изменении состояния cq-switch
     */
    function onSwitchChange(value) {
      console.log('cq-switch on-change');
      console.log('Значение: ', value);
    }

    /**
     * Открытие модального окна подтверждения
     */
    function openConfirmModal() {
      var modalInstance = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: 'Любой текст заголовка',
              body: 'Любой текст тела, в том числе <code>html</code>. Размер модалки может быть любым.',
              needConfirmation: true,
              confirmationText: 'Любой текст для чекбокса (чекбокс также можно не показывать)',
              confirmButtonText: 'Кнопка подтвеждения (по умолч. Удалить)',
              cancelButtonText: 'Кнопка отмены (по умолч. Отмена)',
            };
          },
        },
      });

      modalInstance.result.then(openModalSuccess).catch(openModalError);

      function openModalSuccess() {
        console.log('Success callback закрытия модального окна');
      }

      function openModalError() {
        console.log('Error callback закрытия модального окна');
      }
    }

    /**
     * Открытие модального окна подтверждения
     */
    function openPromptModal() {
      var modalInstance = $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: 'Любой текст заголовка',
              body: 'Любой текст тела, в том числе <code>html</code>. Размер модалки может быть любым.',
              inputLabel: 'Любой текст названия поля',
              inputPlaceholder: 'Любой placeholder',
              inputInitialValue: 'Любое значение по умолчанию',
              inputErrorText: 'Любой текст ошибки',
              confirmButtonText: 'Кнопка подтвеждения (по умолч. Создать)',
              cancelButtonText: 'Кнопка отмены (по умолч. Отмена)',
            };
          },
        },
      });

      modalInstance.result.then(openModalSuccess).catch(openModalError);

      function openModalSuccess() {
        console.log('Success callback закрытия модального окна');
      }

      function openModalError() {
        console.log('Error callback закрытия модального окна');
      }
    }

    /**
     * Открытие cq-emoji-selector
     */
    function openEmojiSelector() {
      vm.emojiSelectorOpen = true;
    }

    /**
     * Открытие модального окна
     *
     * @param {String} size Размер модального окна
     */
    function openModal(size) {
      var modalInstance = $uibModal.open({
        controller: 'VisualComponentsTestModalController',
        controllerAs: 'vm',
        size: size,
        templateUrl: 'js/shared/modals/visual-components-test/visual-components-test.html',
      });

      modalInstance.result.then(openModalSuccess).catch(openModalError);

      function openModalSuccess() {
        console.log('Success callback закрытия модального окна');
      }

      function openModalError() {
        console.log('Error callback закрытия модального окна');
      }
    }

    /**
     * Показ тоста
     *
     * @param {String} type Тип тоста
     */
    function openToast(type) {
      switch (type) {
        case 'success': {
          toastr.success('Сообщение', 'Заголовок');
          break;
        }
        case 'warning': {
          toastr.warning('Сообщение', 'Заголовок');
          break;
        }
        case 'danger': {
          toastr.error('Сообщение', 'Заголовок');
          break;
        }
      }
    }
    function openNewToast(type) {
      switch (type) {
        case 'success': {
          $injector.get('toastHelper').success('Сообщение', 'Заголовок');
          break;
        }
        case 'warning': {
          $injector.get('toastHelper').warning('Сообщение', 'Заголовок');
          break;
        }
        case 'danger': {
          $injector.get('toastHelper').danger('Сообщение', 'Заголовок');
          break;
        }
      }
    }

    /**
     * Рандомизация значений для прогресс баров
     */
    function randomizeProgressValue() {
      vm.progressBars.value = Math.floor(Math.random() * 100);
      vm.progressBars.stackedValue = Math.floor(Math.random() * 33);
    }

    /**
     * Удаление полсдеднего символа из заголовка тултипа
     */
    function removeLastTitleSymbol() {
      vm.tooltips.templateTitle = vm.tooltips.templateTitle.slice(0, -1);
    }

    /**
     * Смена шага в cq-wizard
     *
     * @param {*} step Шаг, на который должен перейти визард
     */
    function selectStep(step) {
      vm.wizard.currentStep = step;
    }

    /**
     * Функция отправки тестовой формы
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function submitTestForm(isValid) {
      if (isValid) {
        vm.forms.isRequestPerformed = true;
        fakeRequest(vm.forms.email, vm.forms.password).then(submitTestFormSuccess);
      }

      function fakeRequest(email, password) {
        var deferred = $q.defer();

        $timeout(fakeRequestTimeout, 500);

        return deferred.promise;

        function fakeRequestTimeout() {
          deferred.resolve({
            email: email,
            password: password,
          });
        }
      }

      function submitTestFormSuccess() {
        vm.forms.isRequestPerformed = false;
        vm.forms.isSubmitted = true;

        if (vm.forms.timeoutPromise) {
          $timeout.cancel();
        }

        vm.forms.timeoutPromise = $timeout(resetTimeout, 2000);

        function resetTimeout() {
          vm.forms.isSubmitted = false;
        }
      }
    }

    /**
     * Функция тегирования (добавления на лету) в ui-select
     */
    function taggingFunction(name) {
      var newItem = angular.copy(vm.uiSelect.defaultItem);

      newItem.name = name;

      return newItem;
    }
  }
})();

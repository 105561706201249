import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { StatusFilterComponent } from './status-filter.component';

@NgModule({
  declarations: [StatusFilterComponent],
  exports: [StatusFilterComponent],
  imports: [CommonModule, TranslocoModule, FormsModule],
})
export class AutoMessageStatusFilterModule {}

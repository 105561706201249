import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';

import { ControlsConfig } from '@panel/app/services/generic-form-builder.service';

import { GenericFormArray } from './generic-form-array';
import { GenericFormControl } from './generic-form-control';

/**
 * Это хелперы для GenericFormGroup и не используется отдельно
 */

// Есть вот такая проблема https://github.com/microsoft/TypeScript/issues/22596 и этот хелпер - способ решить
// Получается не очень красиво,потому что true | false превращается в boolean | boolean, но оно работает
type BoolHelper<T> = T extends boolean ? boolean : T;

// @formatter:off
type Control<T> = [T] extends [Array<infer E>]
  ? [E] extends [AbstractControl]
    ? GenericFormArray<E>
    : GenericFormControl<BoolHelper<T>>
  : [T] extends [object]
  ? T extends AbstractControl
    ? T
    : GenericFormGroup<T>
  : GenericFormControl<BoolHelper<T>>;

// @formatter:on

export type Controls<T> = { [K in keyof T]: Control<T[K]> };

/**
 * Конструирует тип для FormGroup интерфейса IFormControls;.
 * Позволяет определить точный набор controls и список доступных ключей для get()
 *
 * Пример:
 *
 * interface MyFormControls {
 *   id: number;
 *   langs: string[]
 * }
 *
 * const MyFormGroup extends GenericFormGroup<MyFormControls> {
 *   const controls = {
 *     id: fb.control(),
 *     langs: fb.control(), <=== Если не указать "langs", то вызов super() ниже будет ругаться на отсутствие такого ключа
 *   };
 *   super(controls, validatorOrOpts, asyncValidator);
 * }
 *
 * const myFormGroup = new MyFormGroup(...);
 *
 * Автокомплит предложит варианты control, которые присутствуют у данной FormGroup.
 * myFormGroup.get('[id/langs]')
 * myFormGroup.controls.[id/langs]
 *
 * В то же время
 * myFormGroup.controls.keyDoesntExist
 * покажет ошибку, тк ключ "keyDoesntExist" не был задекларирован
 *
 *
 * @deprecated используй новые CVA-классы
 */
export class GenericFormGroup<IFormControls> extends UntypedFormGroup {
  controls!: {
    [K in keyof IFormControls]: Control<IFormControls[K]>;
  };

  constructor(
    controls: Controls<IFormControls>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  get<K extends keyof Controls<IFormControls> & string>(key: K): Control<IFormControls[K]> {
    return super.get(key) as Control<IFormControls[K]>;
  }

  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    },
  ): void {
    super.setValue(value, options);
  }
}

/**
 * @deprecated используй новые CVA-классы
 */
export class GenericFormGroupV2<IFormControls extends object> extends UntypedFormGroup {
  controls!: {
    [K in keyof IFormControls]: GenericFormControl<IFormControls[K]>;
  };

  constructor(
    controls: Controls<IFormControls>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  get<K extends keyof Controls<IFormControls> & string>(key: K): GenericFormControl<IFormControls[K]> {
    return super.get(key) as unknown as GenericFormControl<IFormControls[K]>;
  }

  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ): void {
    super.setValue(value, options);
  }

  reset(value?: ControlsConfig<IFormControls>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.reset(value, options);
  }

  readonly value!: IFormControls;
}

(function () {
  'use strict';

  require('./conversation-frame.controller');

  angular.module('myApp.conversations').component('cqConversationFrame', cqConversationFrame());

  function cqConversationFrame() {
    return {
      bindings: {
        conversation: '=', // Текущий диалог
        conversationParts: '=', // Реплики диалога
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        editMessage: '&?', // Колбэк на нажатие иконки редактирования сообщения
        isDarkThemeActive: '=', // Флаг включена ли темная тема
        knowledgeBaseDomain: '=', // Домен БЗ
        onScrollBottom: '&?', // Колбек на сролл вниз
        onScrollTop: '&?', // Колбек на сролл вверх
        removeMessage: '&?', // Коллбэк на нажатие иконки удаления сообщения
        sendingFiles: '=?', // Отправляемые сообщения с прикреплениями
        states: '=?', // Состояния отвечающие за отображения лоудеров при подгрузке сообщений сверху и снизу
        writeStatuses: '=?', // Печатаемые сообщения
      },
      controller: 'CqConversationFrameController',
      controllerAs: 'vm',
      template: require('./conversation-frame.html'),
    };
  }
})();

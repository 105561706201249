/**
 * Контроллер для создания/редактирования воронки
 */
import { firstValueFrom } from 'rxjs';
import {
  CLICKHOUSE_PROPERTY_OPERATIONS,
  CLICKHOUSE_PROPERTY_OPERATIONS_ARRAY,
  CLICKHOUSE_SYSTEM_PROPERTIES_BY_EVENT_TYPE,
  CLICKHOUSE_USER_SYSTEM_PROPERTIES,
} from '../../../../app/http/property/property.constants';

(function () {
  'use strict';

  angular.module('myApp.funnels').controller('CqFunnelEditorController', CqFunnelEditorController);

  function CqFunnelEditorController($filter, carrotquestHelper, funnelModel, validationHelper) {
    var vm = this;

    /**
     * Имя копируемой воронки
     *
     * @type {String}
     */
    var COPIED_FUNNEL_NAME = null;

    /**
     * Лимит условий внутри каждого шага воронки
     *
     * @type {Number}
     */
    var CONDITIONS_LIMIT = 20;

    /**
     * Лимит шагов в воронке
     *
     * @type {Number}
     */
    var STEPS_LIMIT = 10;

    vm.$onInit = init;

    function init() {
      COPIED_FUNNEL_NAME = vm.currentFunnelName;
      vm.addStep = addStep;
      vm.addStepCondition = addStepCondition;
      vm.changeStepOrder = changeStepOrder;
      vm.CLICKHOUSE_PROPERTY_OPERATIONS = CLICKHOUSE_PROPERTY_OPERATIONS;
      vm.CLICKHOUSE_PROPERTY_OPERATIONS_ARRAY = CLICKHOUSE_PROPERTY_OPERATIONS_ARRAY;
      vm.CONDITIONS_LIMIT = CONDITIONS_LIMIT;
      vm.funnelEditorForm = null; // форма создания/редактирования воронки
      vm.getClickhouseProperties = getClickhouseProperties;
      vm.removeStepCondition = removeStepCondition;
      vm.onSubmit = saveFunnel;
      vm.STEPS_LIMIT = STEPS_LIMIT;
      vm.FUNNEL_MAX_LENGTH = 255;
      vm.validationHelper = validationHelper;

      // По умолчанию отображает в каждом шаге все заданые свойства событий
      for (var i = 0; i < vm.funnel.steps.length; i++) {
        var step = vm.funnel.steps[i];

        for (var j = 0; j < step.length; j++) {
          var condition = step[j];

          if (condition.property) {
            condition.includeProperty = true;
          }
        }
      }
    }

    /**
     * Добавление нового шага в воронку
     */
    function addStep() {
      var newStep = [];

      addStepCondition(newStep);

      vm.funnel.steps.push(newStep);
    }

    /**
     * Добавление условия в шаг воронки
     *
     * @param {Array.<Object>} step Шаг, в который надо добавить условие
     */
    function addStepCondition(step) {
      var stepCondition = angular.copy(funnelModel.getDefault().steps[0][0]);

      // при добавлении условия нужно выставить дефолтные параметры
      step.push(stepCondition);
    }

    /**
     * Смена порядка шагов воронки
     *
     * @param {Array.<Object>} step Шаг, который надо переместить
     * @param {Number} index Индекс, на который надо переместить шаг. При этом шаг, находящийся по этому индексу перемещается на место step
     */
    function changeStepOrder(step, index) {
      var stepIndex = vm.funnel.steps.indexOf(step);

      vm.funnel.steps[stepIndex] = vm.funnel.steps.splice(index, 1, step)[0];
    }

    /**
     * Получение clickhouse-свойств для условия шага воронки
     *
     * @param {Object} condition Условие шага воронки
     * @return {Array.<String>}
     */
    function getClickhouseProperties(condition) {
      var clickhouseProperties = CLICKHOUSE_USER_SYSTEM_PROPERTIES.concat(
        CLICKHOUSE_SYSTEM_PROPERTIES_BY_EVENT_TYPE[condition.eventType.name],
      );

      // HACK сраный костыль из-за того, что в ui-select при удалении выбранного элемента из списка элементов ng-model не очищается
      if (!~clickhouseProperties.indexOf(condition.property)) {
        delete condition.property;
      }

      return clickhouseProperties;
    }

    /**
     * Удаление шага
     *
     * @param {Array.<Object>} step Шаг для удаления
     */
    function removeStep(step) {
      vm.funnel.steps.splice(vm.funnel.steps.indexOf(step), 1);
    }

    /**
     * Удаление условия из шага воронки
     *
     * @param {Array.<Object>} step
     * @param {Object} condition
     */
    function removeStepCondition(step, condition) {
      step.splice(step.indexOf(condition), 1);

      if (!step.length) {
        removeStep(step);
      }
    }

    /**
     * Создание/сохранение воронки и закрытие модалки
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function saveFunnel(isValid) {
      if (isValid) {
        if (vm.funnel.id) {
          trackEditFunnel(vm.funnel);
          return firstValueFrom(funnelModel.save(vm.funnel));
        } else {
          // Проверяем, какое действие осуществляется с воронкой
          if (vm.modalAction == 'copy') {
            trackCopyFunnel(vm.funnel, COPIED_FUNNEL_NAME);
          } else {
            trackCreateFunnel(vm.funnel);
          }
          return firstValueFrom(funnelModel.create(vm.currentApp.id, vm.funnel, vm.isCreateMain));
        }
      }
    }

    /**
     * Трек на редактирование воронки
     *
     * @param {String} funnel воронка
     */
    function trackEditFunnel(funnel) {
      var flattenSteps = $filter('flatten')(vm.funnel.steps);

      carrotquestHelper.track('Воронки 2018 - редактировал воронку', {
        'Название воронки': funnel.name,
        'Количество шагов в воронке': funnel.steps.length,
        'Количество событий в воронке': flattenSteps.length,
        'Названия свойств событий': $filter('map')(
          $filter('filter')(flattenSteps, { property: '!!' }),
          'property',
        ).join(', '),
      });
    }

    /**
     * Трек на копирование воронки
     *
     * @param {String} funnel воронка
     * @param {String} funnel название скопированной воронки
     */
    function trackCopyFunnel(funnel, copiedFunnelName) {
      var flattenSteps = $filter('flatten')(vm.funnel.steps);

      carrotquestHelper.track('Воронки 2018 - скопировал воронку', {
        'Название скопированной воронки': copiedFunnelName,
        'Название воронки': funnel.name,
        'Количество шагов в воронке': funnel.steps.length,
        'Количество событий в воронке': flattenSteps.length,
        'Названия свойств событий': $filter('map')(
          $filter('filter')(flattenSteps, { property: '!!' }),
          'property',
        ).join(', '),
      });
    }

    /**
     * Трек на создание воронки
     *
     * @param {String} funnel воронка
     */
    function trackCreateFunnel(funnel) {
      var flattenSteps = $filter('flatten')(vm.funnel.steps);

      carrotquestHelper.track('Воронки 2018 - создал воронку', {
        'Название воронки': funnel.name,
        'Количество шагов в воронке': funnel.steps.length,
        'Количество событий в воронке': flattenSteps.length,
        'Названия свойств событий': $filter('map')(
          $filter('filter')(flattenSteps, { property: '!!' }),
          'property',
        ).join(', '),
      });
    }
  }
})();

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Simple } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';

import { ConnectionValidatorService } from '@panel/app/pages/chat-bot/content/services/connection-validator.service';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import {
  BOT_CONNECTIONS_COLLECTION,
  PIXI_APP,
  PIXI_CULL,
  PIXI_VIEWPORT,
  PixiApplication,
} from '@panel/app/pages/chat-bot/content/tokens';
import { Connection, ConnectionSource, ConnectionTarget } from '@panel/app/pages/chat-bot/content/views/connection';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Injectable()
export class ConnectionFactory {
  constructor(
    @Inject(PIXI_CULL)
    private readonly cull: Simple,
    @Inject(BOT_CONNECTIONS_COLLECTION)
    private readonly connections: Connection[],
    private readonly connectionValidatorService: ConnectionValidatorService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly interactionService: PixiInteractionService,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    private readonly toastService: ToastService,
    private readonly transloco: TranslocoService,
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
  ) {}

  create(source: ConnectionSource, target?: ConnectionTarget) {
    const connection = new Connection(
      this.viewport,
      this.cull,
      this.connections,
      this.document,
      this.interactionService,
      this.connectionValidatorService,
      this.toastService,
      this.transloco,
      this.pixiApp,
    );
    connection.addConnectionSide(source);
    if (target) {
      connection.addConnectionSide(target);
    }
    return connection;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import {
  MessageWebhookBodyJson,
  MessageWebhookMethod,
  MessageWebhookParam,
} from '@http/message-part/message-part.types';
import { EventType } from '@http/property/property.model';
import { WebhookBodyJsonForm } from '@panel/app/pages/trigger-messages/webhook-part-editor/webhook-part-editor.types';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { DestroyService } from '@panel/app/services';
@Component({
  selector: 'cq-webhook-part-editor',
  templateUrl: './webhook-part-editor.component.html',
  styleUrls: ['./webhook-part-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, formSubmitTokenProviders],
})
export class WebhookPartEditorComponent implements OnInit {
  @Input({ required: true })
  set bodyJson(bodyJson: MessageWebhookBodyJson) {
    // HACK this.bodyJsonChange.emit(value) триггерит этот инпут
    // этим условием я ограничил пересбор формы ТОЛЬКО на реальный инпут
    if (this._bodyJson === bodyJson) {
      return;
    }
    this._bodyJson = bodyJson;
    this.form.setValue(bodyJson, { emitEvent: false });
  }
  _bodyJson!: MessageWebhookBodyJson;

  @Input({ required: true })
  set body(body: string) {
    this.urlForm.setValue(body, { emitEvent: false });
  }

  @Input({ required: true })
  currentApp!: App;

  @Input()
  eventTypes: EventType[] = [];

  readonly URL_MAX_LENGTH = 2000;

  form = this.fb.group<WebhookBodyJsonForm>({
    headers: this.fb.control<MessageWebhookParam[]>([], { nonNullable: true }),
    queryParams: this.fb.control<MessageWebhookParam[]>([], { nonNullable: true }),
    body: this.fb.control<string>('{}', { nonNullable: true, validators: [Validators.required] }),
    method: this.fb.control<MessageWebhookMethod>('post', { nonNullable: true, validators: [Validators.required] }),
    eventType: this.fb.control<string | null>(null, { validators: [] }),
    eventProps: this.fb.control<MessageWebhookParam[]>([], { nonNullable: true }),
  });

  urlForm = this.fb.control<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(this.URL_MAX_LENGTH)],
  });

  collapsedSettingsMap: Record<string, boolean> = {
    body: false,
    queryParams: false,
    headers: false,
  };

  setEventType = false;

  @Output()
  bodyJsonChange = this.form.valueChanges.pipe(
    tap((formData) => {
      this._bodyJson = formData as MessageWebhookBodyJson;
    }),
  );

  @Output()
  bodyChange = this.urlForm.valueChanges;

  @Output()
  submitValidation: EventEmitter<() => void> = new EventEmitter<() => void>();

  /**
   * Этот Output нужен чтобы засинхронить валидации Ajs с валидностью формы этого компонента
   */
  @Output()
  validationChange = this.form.statusChanges.pipe(
    startWith(this.form.status),
    distinctUntilChanged(),
    map(() => this.form.valid),
  );

  @Output()
  readonly eventTypesChange = new EventEmitter<EventType[]>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroy$: DestroyService,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    readonly formSubmitSubject: Subject<void>,
  ) {}

  ngOnInit(): void {
    this.submitValidation.emit(() => {
      this.formSubmitSubject.next();
    });
    this.setEventType = !!this.eventForm.value;
  }

  readonly REQUEST_METHODS: MessageWebhookMethod[] = ['post', 'get', 'patch', 'put', 'delete'];

  toggleCollapse(key: string): void {
    if (key in this.collapsedSettingsMap) {
      this.collapsedSettingsMap[key] = !this.collapsedSettingsMap[key];
    }
  }

  setEventTypeChanged(value: boolean) {
    if (!value) {
      this.eventForm.setValue(null);
      this.eventPropsForm.setValue([]);
    }
  }

  get headersForm() {
    return this.form.controls.headers;
  }

  get queryParamsForm() {
    return this.form.controls.queryParams;
  }

  get bodyForm() {
    return this.form.controls.body;
  }

  get methodForm() {
    return this.form.controls.method;
  }

  get eventForm() {
    return this.form.controls.eventType;
  }

  get eventPropsForm() {
    return this.form.controls.eventProps;
  }
}

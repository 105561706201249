(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqWidgetBadgeTypeController', CqWidgetBadgeTypeController);

  function CqWidgetBadgeTypeController(WIDGET_BADGE_TYPES, carrotquestHelper) {
    let vm = this;

    /**
     * Массив типов иконок в чате, для селекта
     *
     * @type {(*|string)[]}
     */
    const WIDGET_BADGE_TYPES_ARRAY = [WIDGET_BADGE_TYPES.DEFAULT, WIDGET_BADGE_TYPES.AVATAR];

    vm.$onInit = init;

    function init() {
      vm.trackClickSetDefaultAvatar = trackClickSetDefaultAvatar;
      vm.WIDGET_BADGE_TYPES_ARRAY = WIDGET_BADGE_TYPES_ARRAY;
    }

    /**
     * Трек выбора стандартной иконки в чате
     */
    function trackClickSetDefaultAvatar(type) {
      if (type === WIDGET_BADGE_TYPES.DEFAULT) {
        carrotquestHelper.track('Настройки чата - кликнул на "Вернуть стандартную аватарку"', {
          app: vm.currentAppName,
        });
      }
    }
  }
})();

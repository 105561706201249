<ng-container *transloco="let t">
  <div class="d-flex justify-content-between margin-bottom-20">
    <h3>{{ t('vkFormsFormLinkComponent.heading') }}</h3>
    <button
      *ngIf="showRemoveFormButton"
      class="btn btn-outline-secondary"
      (click)="removeForm.emit(true)"
      type="button"
    >
      {{ t('vkFormsFormLinkComponent.removeFormButton.text') }}
    </button>
  </div>
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="control"
  >
    <input
      class="form-control"
      [formControl]="control"
      [placeholder]="t('vkFormsFormLinkComponent.input.placeholder')"
      type="url"
    />
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">{{
        t('vkFormsFormLinkComponent.input.errors.required')
      }}</cq-validation-message>
      <cq-validation-message errorName="url">{{
        t('vkFormsFormLinkComponent.input.errors.url')
      }}</cq-validation-message>
      <cq-validation-message errorName="pattern">{{
        t('vkFormsFormLinkComponent.input.errors.pattern')
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Inject, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { IS_WEB_APP } from '@panel/app/shared/tokens';

import { DEFAULT_NOTIFICATION_SETTINGS_GENERAL } from './notification-settings-general.constants';

export type NotificationSettingsGeneral = {
  enableNotificationSound: boolean;
  repeatSoundNotification: boolean;
  keepDesktopNotificationOpened: boolean;
};

@Component({
  selector: 'cq-notification-settings-general',
  templateUrl: './notification-settings-general.component.html',
  styleUrls: ['./notification-settings-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsGeneralComponent {
  @Input()
  notificationSettingsGeneral: NotificationSettingsGeneral = DEFAULT_NOTIFICATION_SETTINGS_GENERAL;

  @Output()
  notificationSettingsGeneralChange = new Subject<NotificationSettingsGeneral>();

  constructor(
    @Inject(IS_WEB_APP)
    public readonly isWebApp: boolean,
  ) {}
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ConversationPartAttachment } from '@http/conversation-part/internal-types';

/**
 TODO:
 - Сделать возможность воспроизведения видео
 */
@Component({
  selector: 'cq-conversation-part-preview-video[attachment]',
  templateUrl: './conversation-part-preview-video.component.html',
  styleUrls: ['./conversation-part-preview-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPartPreviewVideoComponent {
  /** Прикрепление */
  @Input() attachment: ConversationPartAttachment | null = null;

  /** Url видео */
  get videoUrl(): string | null {
    let url = null;

    if (this.attachment) {
      url = this.attachment.url;
    }

    return url;
  }
}

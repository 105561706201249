<ng-container *transloco="let t">
  <div
    class="form-control-container"
    [cqShowError]="actionForm.controls.body"
  >
    <cq-quill-text-editor
      [formControl]="actionForm.controls.body"
      [formats]="availableTextFormats"
      [placeholder]="t('chatBot.actionText.botMessageTextarea.placeholder')"
    ></cq-quill-text-editor>

    <cq-validation-messages [control]="actionForm.controls.body">
      <cq-validation-message errorName="required">
        {{ t('chatBot.actionText.botMessageTextarea.errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('chatBot.actionText.botMessageTextarea.errors.maxLength', { maxLength: TEXT_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';

import { UpgradeComponent } from './upgrade.component';

@NgModule({
  declarations: [UpgradeComponent],
  exports: [UpgradeComponent],
  imports: [CommonModule, TranslocoModule, NgbAlertModule],
  providers: [StateService],
})
export class UpgradeModule {}

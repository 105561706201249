/**
 * Сервис для работы с авторизацией
 */
(function () {
  'use strict';

  angular.module('myApp.models.auth').factory('authModel', authModel);

  function authModel(APIRequest, OAUTH_REDIRECT_URL, ipCookie) {
    return {
      fastRegister: fastRegister,
      confirmInvite: {
        addAlreadyRegisteredUserToApp: addAlreadyRegisteredUserToApp,
        checkInviteLinkExpiration: checkInviteLinkExpiration,
        registerNewTeamMember: registerNewTeamMember,
      },
      getHash: getHash,
      googleOAuth: googleOAuth,
      login: {
        fromDesktopApp: loginFromDesktopApp,
        fromWeb: loginFromWeb,
      },
      twoFA: {
        resendCode: resendCode,
        validatePanelTwoFACode: validateTwoFACode.bind(null, 'panellogin'),
        validateDesktopTwoFACode: validateTwoFACode.bind(null, 'appdesktoplogin'),
      },
      password: {
        reset: passwordReset,
        generateResetToken: generatePasswordResetToken,
      },
    };

    /**
     * Добавление ранее зарегистрировавшегося пользователя в апп
     *
     * @param settings Параметры для добавления пользователя
     * @param {String} settings.email Email пользователя
     * @param {String} settings.hash Сгенерированный хеш пользователя
     * @returns {Promise}
     */
    function addAlreadyRegisteredUserToApp(settings) {
      return APIRequest('POST', '/auth/add_admin_from_invite', settings);
    }

    /**
     * Проверка истечения срока действия приглашения нового пароля
     *
     * @param settings Параметры для проверки истечения срока действия приглашения нового пароля
     * @param {String} settings.email Email пользователя
     * @param {String} settings.hash Сгенерированный хеш пользователя
     * @returns {Promise}
     */
    function checkInviteLinkExpiration(settings) {
      return APIRequest('GET', '/auth/check_invite_for_existence', settings);
    }

    /**
     * Быстрая регистрация
     *
     * @param {Object} settings Параметры регистрации
     * @param {String} settings.email Email, указанный во время регистрации
     * @param {String} settings.phone Телефон, указанный во время регистрации
     * @param {String} settings.locale Локаль пользователя
     * @param {String} settings.gRecaptchaResponse Рекапча
     * @param {String} settings.gaClientId GA ID
     * @returns {Promise}
     */
    function fastRegister(settings) {
      let params = {
        email: settings.email,
        phone: settings.phone,
        locale: settings.locale,
      };
      params['g-recaptcha-response'] = settings.gRecaptchaResponse;

      if (settings.gaClientId) {
        params['ga_client_id'] = settings.gaClientId;
      }

      /** Проверяем наличие куки из сервиса FirstPromoter */
      const firstPromoterId = ipCookie('_fprom_tid');
      if (firstPromoterId) {
        params['tid'] = firstPromoterId;
      }

      return APIRequest('POST', '/auth/panelfastregister', params);
    }

    /**
     * Запрос на сброс пароля по email
     *
     * @param settings Параметры запроса
     * @param settings.email Email пользователя
     * @returns {Promise}
     */
    function generatePasswordResetToken(settings) {
      var params = {
        email: settings.email,
      };

      return APIRequest('POST', '/utils/generatepasswordresettoken', params);
    }

    /**
     * Получение хеша для ID django-пользователя, для выполнения склейки
     *
     * @returns {Promise}
     */
    function getHash() {
      return APIRequest('GET', '/auth/panelhash', {});
    }

    /**
     * Регистрация через Google
     *
     * @param {String} code
     * @returns {Promise}
     */
    function googleOAuth(code, gaClientId) {
      var params = {
        code: code,
        redirect_uri: OAUTH_REDIRECT_URL,
      };
      if (gaClientId) {
        params['ga_client_id'] = gaClientId;
      }

      return APIRequest('POST', '/auth/googleoauth', params);
    }

    /**
     * Авторизация через декстоп-приложение
     *
     * @param {Object} settings Параметры авторизации
     * @param {String} settings.email Email, указанный во время авторизации
     * @param {String} settings.password Пароль, указанный во время авторизации
     * @param {String} settings.gRecaptchaResponse Рекапча
     * @returns {Promise}
     */
    function loginFromDesktopApp(settings) {
      var params = {
        email: settings.email,
        password: settings.password,
      };
      params['g-recaptcha-response'] = settings.gRecaptchaResponse;

      return APIRequest('POST', '/auth/appdesktoplogin', params);
    }

    /**
     * Авторизация через панельку
     *
     * @param {Object} settings Параметры авторизации
     * @param {String} settings.email Email, указанный во время авторизации
     * @param {String} settings.password Пароль, указанный во время авторизации
     * @param {String} settings.gRecaptchaResponse Рекапча
     * @returns {Promise}
     */
    function loginFromWeb(settings) {
      var params = {
        email: settings.email,
        password: settings.password,
      };
      params['g-recaptcha-response'] = settings.gRecaptchaResponse;

      return APIRequest('POST', '/auth/panellogin', params);
    }

    /**
     * Запрос на повторную отправку 2FA кода
     *
     * @param {Object} settings
     * @param {String} settings.gRecaptchaResponse Рекапча
     * @param {string} settings.twoFAToken Токен 2FA
     */
    function resendCode(settings) {
      const params = {
        tfa_token: settings.twoFAToken,
        ['g-recaptcha-response']: settings.gRecaptchaResponse,
      };

      return APIRequest('POST', '/auth/send_2fa_code', params);
    }

    /**
     * Отправка запроса на валидацию 2FA кода
     *
     * @param {'panellogin'|'appdesktoplogin'} loginType
     * @param {Object} settings
     * @param {String} settings.gRecaptchaResponse Рекапча
     * @param {String} settings.twoFACode Код проверки 2FA
     * @param {String} settings.twoFAToken Токен 2FA
     */
    function validateTwoFACode(loginType, settings) {
      const params = {
        tfa_verification_code: settings.twoFACode,
        tfa_token: settings.twoFAToken,
        ['g-recaptcha-response']: settings.gRecaptchaResponse,
      };

      return APIRequest('POST', `/auth/${loginType}`, params);
    }

    /**
     * Сохранение нового пароля пользователя
     *
     * @param settings Параметры для сохранения нового пароля
     * @param {String} settings.token Токен
     * @param {String} settings.password Новый пароль
     * @returns {Promise}
     */
    function passwordReset(settings) {
      var params = {
        token: settings.token,
        password: settings.password,
      };

      return APIRequest('POST', '/utils/passwordreset', params);
    }

    /**
     * Регистрация нового члена команды
     *
     * @param settings Данные нового члена команды
     * @param {String} settings.email Email пользователя
     * @param {String} settings.name Имя пользователя
     * @param {String} settings.password Пароль
     * @param {String} settings.hash Сгенерированный хеш пользователя
     * @returns {Promise}
     */
    function registerNewTeamMember(settings) {
      return APIRequest('POST', '/auth/register_from_invite', settings);
    }
  }
})();

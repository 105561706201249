import { Validators } from '@angular/forms';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

import { INTEGRATION_NAME_MAX_LENGTH } from '../../../constants/integration.constants';
import { TelegramIntegrationSettingsExternal } from '../interfaces/telegram-integration.interfaces';
import { TelegramIntegration } from '../telegram-integration';
import { TelegramIntegrationFormData } from '../types/telegram-integration.types';

/** Класс для работы с формой TELEGRAM-интеграции */
export class TelegramIntegrationForm extends GenericFormGroup<TelegramIntegrationFormData> {
  constructor(integration: TelegramIntegration) {
    let controls: Controls<TelegramIntegrationFormData> = {
      name: new GenericFormControl(integration.name, [
        Validators.required,
        Validators.maxLength(INTEGRATION_NAME_MAX_LENGTH),
      ]),
      settings: new TelegramSettingsForm(integration.settings),
    };

    super(controls, null, null);
  }
}

/** Класс для работы с формой поля settings у TELEGRAM-интеграции */
class TelegramSettingsForm extends GenericFormGroup<Omit<TelegramIntegrationSettingsExternal, 'botName' | 'botId'>> {
  constructor(settings: TelegramIntegrationSettingsExternal) {
    let controls: Controls<Omit<TelegramIntegrationSettingsExternal, 'botName' | 'botId'>> = {
      botToken: new GenericFormControl(settings.botToken, [Validators.required]),
    };

    if (settings.token) {
      controls.token = new GenericFormControl(settings.token);
    }

    super(controls, null, null);
  }
}

import { translate } from '@jsverse/transloco';
import { Container } from 'pixi.js';

import { ActionStyle, BaseActionABS, MAX_ACTION_WIDTH } from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { getButtonBackground } from '@panel/app/pages/chat-bot/content/views/actions/button-action/button-action.textures';
import { CARD_WIDTH } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { BOT_DARK_GREY, BOT_GREY, BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

export class BotButtonMeetingAction extends BaseActionABS<EMPTY_BODY_JSON> {
  protected style!: ActionStyle;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);

    const content = this.render();
    if (content) {
      this.container.addChild(content);
    }
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 7,
        horizontal: 12,
      },
      border: {
        size: 1,
        color: BOT_GREY,
        radius: 10,
      },
      background: {
        color: BOT_WHITE_COLOR,
      },
    };
  }

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected get icon(): string {
    return UNICODE_ICON['cqi-calendar-o'];
  }

  redraw() {
    if (this.destroyed) {
      return;
    }
    this.container.removeChildren();
    const content = this.render();
    this.container.addChild(content);
  }

  render(): Container {
    const paddingX = this.style.padding.horizontal * 2;
    const paddingY = this.style.padding.vertical * 2;
    const iconGapRight = 5;
    const padding = (CARD_WIDTH - MAX_ACTION_WIDTH) / 2;

    const textOptions = {
      fontSize: 14,
      lineHeight: 14 * 1.42857143,
      wordWrapWidth: MAX_ACTION_WIDTH - paddingX,
      fill: BOT_GREY,
    };
    const icon = PixiIconRenderService.renderIcon(this.icon, { fill: BOT_DARK_GREY });
    const text = renderCanvasText(this.translatedText, textOptions);
    const bgWidth = icon.width + text.width + paddingX;
    const bgHeight = Math.max(icon.height, text.height) + paddingY;
    const bg = getButtonBackground(this.pixiApp.renderer, bgWidth, bgHeight);

    bg.zIndex = 1;
    bg.addChild(icon);
    bg.addChild(text);

    icon.position.set(paddingX / 2, bgHeight / 2 - icon.height / 2);
    text.position.set(icon.x + icon.width + iconGapRight, paddingY / 2);
    bg.position.set(CARD_WIDTH - (bg.width + padding), bg.position.y);

    return bg;
  }
  get translatedText(): string {
    return translate('classes.action.canvasText.meeting.bookButton');
  }

  get height(): number {
    return this.container.height;
  }

  get width(): number {
    return this.container.width;
  }

  get renderReady(): boolean {
    return this.container.renderable && this.container.children.length > 0;
  }
}

<div class="d-flex align-items-center py-15 px-20 bg-primary">
  <ng-content></ng-content>
  <button
    class="close text-white ml-auto"
    type="button"
    (click)="onClickCloseButton()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>

(function () {
  'use strict';

  require('./add-doctype/add-doctype.module');
  require('./add-option/add-option.module');
  require('./add-target-blank/add-target-blank.module');
  require('./clean-js/clean-js.module');
  require('./filter-templates/filter-templates.module');
  require('./format-seconds/format-seconds.module');
  require('./handle-incorrect-value/handle-incorrect-value.module');
  require('./is-image-url/is-image-url.module');
  require('./is-url/is-url.module');
  require('./letter/letter.module');
  require('./linkify/linkify.module');
  require('./money/money.module');
  require('./not-in-array/not-in-array.module');
  require('./percent/percent.module');
  require('./pretty-date/pretty-date.module');
  require('./pretty-event-prop-name/pretty-event-prop-name.module');
  require('./pretty-event-type-name/pretty-event-type-name.module');
  require('./pretty-user-prop-name/pretty-user-prop-name.module');
  require('./remove-href/remove-href.module');
  require('./remove-html-tags/remove-html-tags.module');
  require('./replaceDivEmoji/replace-div-emoji.module');
  require('./remove-jinja/remove-jinja.module');
  require('./round-up/round-up.module');
  require('./to-camel-case/to-camel-case.module');
  require('./to-kebab-case/to-kebab-case.module');
  require('./to-sentence-case/to-sentence-case.module');
  require('./to-underscore/to-underscore.module');
  require('./translate/translate.module');
  require('./transliterate/transliterate.module');
  require('./trust-html/trust-html.module');
  require('./trust-uri/trust-uri.module');
  require('./trust-url/trust-url.module');
  require('./user-prop-pretty/user-prop-pretty.module');

  angular.module('myApp.filters', [
    'myApp.filters.addDoctype',
    'myApp.filters.addOption',
    'myApp.filters.addTargetBlank',
    'myApp.filters.cleanJs',
    'myApp.filters.filterTemplates',
    'myApp.filters.formatSeconds',
    'myApp.filters.handleIncorrectValue',
    'myApp.filters.isImageUrl',
    'myApp.filters.isUrl',
    'myApp.filters.letter',
    'myApp.filters.linkify',
    'myApp.filters.money',
    'myApp.filters.notInArray',
    'myApp.filters.percent',
    'myApp.filters.prettyDate',
    'myApp.filters.prettyEventPropName',
    'myApp.filters.prettyEventTypeName',
    'myApp.filters.prettyUserPropName',
    'myApp.filters.removeHref',
    'myApp.filters.removeHtmlTags',
    'myApp.filters.removeJinja',
    'myApp.filters.replaceDivEmoji',
    'myApp.filters.roundUp',
    'myApp.filters.toCamelCase',
    'myApp.filters.toKebabCase',
    'myApp.filters.toSentenceCase',
    'myApp.filters.toUnderscore',
    'myApp.filters.translate',
    'myApp.filters.transliterate',
    'myApp.filters.trustHtml',
    'myApp.filters.trustUri',
    'myApp.filters.trustUrl',
    'myApp.filters.userPropPretty',
  ]);
})();

<div class="d-flex justify-space-between">
  <button
    class="p-10 btn btn-text-primary"
    (click)="navigate(-1)"
  >
    <i class="btn-icon cqi-sm cqi-chevron-left"></i>
  </button>
  <span class="p-10">
    {{ navigationDate }}
  </span>
  <button
    class="p-10 btn btn-text-primary"
    (click)="navigate(1)"
  >
    <i class="btn-icon cqi-sm cqi-chevron-right"></i>
  </button>
</div>

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupHtmlBlockPreviewController', CqPopupHtmlBlockPreviewController);

  function CqPopupHtmlBlockPreviewController($templateRequest) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.template = ''; // строка, содержащая html и css-код, необходимый для работы превью блока

      getTemplate();
    }

    /**
     * Получения темплейта для блока
     */
    function getTemplate() {
      $templateRequest('js/components/popup-editor/block-preview/html/html.html').then(getTemplateSuccess);

      function getTemplateSuccess(templates) {
        vm.template = templates;
      }
    }
  }
})();

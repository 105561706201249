import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { BehaviorSubject, fromEvent, interval, Observable, of } from 'rxjs';
import { delay, finalize, map, mergeMap, startWith, takeUntil } from 'rxjs/operators';

import { AppModel } from '@http/app/app.model';
import { AppActivation } from '@http/app/app.types';
import { AppService } from '@http/app/services/app.service';
import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';
import { StarterGuideModel } from '@http/starter-guide/starter-guide.model';
import { APIResponse } from '@http/types';
import { DestroyService } from '@panel/app/services';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Модальное окно проверки наличия диалогов из интеграций для разблокировки админки */
@Component({
  selector: 'cq-write-to-integrations-modal',
  templateUrl: './write-to-integrations-modal.component.html',
  styleUrls: ['./write-to-integrations-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class WriteToIntegrationsModalComponent implements OnInit, AfterViewInit {
  /** AJS инстанс модального окна */
  @Input()
  ajsModalInstance?: any;

  /** Кнопка проверки наличия диалогов */
  @ViewChild('checkDialogsButton')
  checkDialogsButton!: ElementRef;

  /** Интервал проверки активации приложения в диалог со стороны пользователя */
  checkReplyUserAppActivationInterval: number = 30 * 1000;

  /** Флаг загрузки данных */
  isDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isDataLoadingObs$!: Observable<boolean>;

  constructor(
    private readonly appService: AppService,
    public activeModal: NgbActiveModal,
    private readonly appModel: AppModel,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly destroy$: DestroyService,
    private readonly starterGuideModel: StarterGuideModel,
    private readonly stateService: StateService,
  ) {
    this.isDataLoadingObs$ = this.isDataLoading$.pipe(
      mergeMap((value: boolean) => {
        if (value) {
          return of(value);
        } else {
          // Ставлю отсрочку в 1 секунду, чтобы текст в кнопке не менялся слишком быстро
          return of(value).pipe(delay(1000));
        }
      }),
    );
  }

  ngOnInit() {
    this.trackOpenedModal();

    interval(this.checkReplyUserAppActivationInterval)
      .pipe(
        takeUntil(this.destroy$),
        // Сразу запускаем пайп, без ожидания интервала
        startWith(-1),
        mergeMap((event) => {
          return this.checkReplyUserAppActivation();
        }),
      )
      .subscribe((hasConversations) => this.appHasReplyUserActivationHandler(hasConversations));
  }

  ngAfterViewInit() {
    fromEvent(this.checkDialogsButton.nativeElement, 'click')
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(() => {
          return this.checkReplyUserAppActivation();
        }),
      )
      .subscribe((hasConversations) => this.appHasReplyUserActivationHandler(hasConversations));
  }

  /** Обработка наличия активации в диалог со стороны пользователя */
  appHasReplyUserActivationHandler(hasConversations: boolean): void {
    if (hasConversations) {
      this.starterGuideModel
        .setStepIsMade(this.appService.currentAppId, STARTER_GUIDE_STEPS.SOCIAL_INTEGRATIONS)
        .subscribe();

      this.stateService.go('app.content.conversations.general');
      this.closeModal();
    }
  }

  /** Проверка была ли активация в диалоги со стороны пользователя.
   * Так мы определяем, что настроенная пользователем интеграция работает. */
  checkReplyUserAppActivation(): Observable<boolean> {
    this.isDataLoading$.next(true);

    return this.appModel.getActivations(this.appService.currentAppId).pipe(
      finalize(() => {
        this.isDataLoading$.next(false);
      }),
      map((appActivations: APIResponse<AppActivation>) => {
        return this.hasReplyUserAppActivation(appActivations);
      }),
    );
  }

  /**
   * Закрытие модального окна
   */
  closeModal() {
    this.ajsModalInstance ? this.ajsModalInstance.close(true) : this.activeModal.close(true);
  }

  /**
   * Отмена модального окна
   */
  dismissModal() {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.activeModal.dismiss();
  }

  /**
   * Проверка активации в диалог из социальных сетей со стороны пользователя
   *
   * @param appActivations Список активаций приложения
   */
  hasReplyUserAppActivation(appActivations: APIResponse<AppActivation>): boolean {
    return !!appActivations.data.social_network_integrations_reply_user;
  }

  /** Трекинг клика на кнопку проверки диалогов */
  trackClickOnCheckDialogButton() {
    const eventName = 'Установка сервиса - кликнул на "Проверить наличие диалогов"';

    this.carrotquestHelper.track(eventName);
  }

  /** Трекинг открытия модального окна */
  trackOpenedModal() {
    const eventName = 'Показалась модалка "напиши себе сам"';

    this.carrotquestHelper.track(eventName);
  }
}

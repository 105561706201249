import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { NgMathPipesModule } from 'ngx-pipes';

import { PipesModule } from '@panel/app/shared/pipes/pipes.module';
import { ProgressbarModule } from '@panel/app/shared/visual-components/progressbar/progressbar.module';

import { TriggerChainGeneralStatisticsTableItemComponent } from './trigger-chain-general-statistics-table-item.component';

@NgModule({
  declarations: [TriggerChainGeneralStatisticsTableItemComponent],
  exports: [TriggerChainGeneralStatisticsTableItemComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ProgressbarModule,
    PipesModule,
    NgMathPipesModule,
    NgbTooltipModule,
    UIRouterModule,
  ],
  providers: [PercentPipe],
})
export class TriggerChainGeneralStatisticsTableItemModule {}

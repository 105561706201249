/** Валидные форматы для прикреплений */
export enum CONVERSATION_ATTACHMENT_MIME_TYPES {
  DOCUMENT = 'text/html,text/csv,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/*',
  FILE = 'application/x-rar,application/x-rar-compressed,application/zip,application/x-zip-compressed',
  IMAGE = 'image/*',
}

/** Типы реплик по отправившему */
export enum CONVERSATION_PART_DIRECTIONS {
  /** От админа к пользователю */
  ADMIN_TO_USER = 'a2u',
  /** От пользователя к админу */
  USER_TO_ADMIN = 'u2a',
}

/**
 * Откуда была отправлена реплика
 * Полного и правильного списка нет ни у кого, т.к. бэкенд не отслеживает точного списка
 */
export enum CONVERSATION_PART_SENT_VIA {
  /** Мобильное приложение на андроиде */
  APP_ANDROID = 'app_android',
  /** Через API */
  API = 'api',
  /** @deprecated Chrome-приложение */
  APP_CHROME = 'app_chrome',
  /** Windows-приложение */
  APP_DESKTOP = 'app_desktop',
  /** Мобильное приложение на iOS */
  APP_IOS = 'app_ios',
  /** Автоответ */
  AUTO_REPLY = 'auto_reply',
  /** Ответ AI-бота */
  CHAT_GPT = 'chat_gpt',
  /** Интеграция с Dialogflow */
  DIALOGFLOW = 'dialogflow',
  /** С почты оператора/администратора/суперадмина */
  EMAIL_ADMIN = 'email_admin',
  /** С почты пользователя */
  EMAIL_USER = 'email_user',
  /** Интеграция с Facebook */
  FACEBOOK = 'facebook',
  /** Интеграция с Instagram */
  INSTAGRAM = 'instagram',
  /** Триггерное сообщение */
  MESSAGE_AUTO = 'message_auto',
  /** Ручная рассылка */
  MESSAGE_MANUAL = 'message_manual',
  /** @deprecated Интеграция со Skype */
  SKYPE = 'skype',
  /** Интеграция со Slack */
  SLACK = 'slack',
  /** Системная реплика (например, оценка пользователя) */
  SYSTEM = 'system',
  /** Интеграция с Telegram */
  TELEGRAM = 'telegram',
  /** Интеграция с Viber */
  VIBER = 'viber',
  /** Интеграция с ВК */
  VK = 'vk',
  /** Админка, открытая в браузере */
  WEB_PANEL = 'web_panel',
  /** Чат у клиента на сайте */
  WEB_USER = 'web_user',
  /** Интеграция с WhatsApp */
  WHATS_APP = 'whatsapp_edna',
  /** Интеграция с Яндекс.Диалогами */
  YANDEX_DIALOGS = 'yandex_dialogs',
}

/** Типы источников отправки, которые нельзя ни редактировать, ни удалять */
export const CONVERSATION_PART_NON_EDITABLE_NON_REMOVABLE_TYPES = [
  CONVERSATION_PART_SENT_VIA.EMAIL_ADMIN,
  CONVERSATION_PART_SENT_VIA.EMAIL_USER,
  CONVERSATION_PART_SENT_VIA.MESSAGE_AUTO,
  CONVERSATION_PART_SENT_VIA.MESSAGE_MANUAL,
];

/** Типы реплик */
export enum CONVERSATION_PART_TYPES {
  /** Статья БЗ */
  ARTICLE = 'article',
  /** Назначение оператора */
  ASSIGNED = 'assigned',
  /** Автоответ */
  AUTO_REPLY = 'auto_reply',
  /** Сообщение из Calendly */
  CALENDLY = 'calendly',
  /** Смена канала */
  CHANNEL_CHANGED = 'channel_changed',
  /** Откат назначения канала. Не используется */
  CHANNEL_RESTORED = 'channel_restored',
  /** Текстовые реплики бота */
  CHAT_BOT_ADMIN = 'chat_bot_admin',
  /** Реплики бота, на которые подразумевается ответ пользователя */
  CHAT_BOT_USER = 'chat_bot_user',
  /** Закрытие диалога */
  CLOSED = 'closed',
  /** Откладывание диалога */
  DELAYED = 'delayed',
  /** @deprecated Финальное закрытие диалога. В новых диалогах не встречается, но в старых существует */
  FINAL_CLOSED = 'final_closed',
  /** Заметка */
  NOTE = 'note',
  /** @deprecated Что-то невероятно старое */
  OPENED = 'opened',
  /** Сообщение оператора/админа/суперадмина */
  REPLY_ADMIN = 'reply_admin',
  /** Сообщение пользователя */
  REPLY_USER = 'reply_user',
  /** Сервисные сообщения (например, когда сервис не смог отправить сообщение в интеграцию) */
  SERVICE = 'service',
  /** Добавление тега диалога */
  TAG_ADDED = 'tag_added',
  /** Удаление тега диалога */
  TAG_DELETED = 'tag_deleted',
  /** Оценка оператора */
  VOTE = 'vote',
}

/** Типы реплик, которые можно редактировать */
export const CONVERSATION_PART_EDITABLE_TYPES = [CONVERSATION_PART_TYPES.NOTE, CONVERSATION_PART_TYPES.REPLY_ADMIN];

/** Типы реплик, которые можно удалить */
export const CONVERSATION_PART_REMOVABLE_TYPES = [
  CONVERSATION_PART_TYPES.ARTICLE,
  CONVERSATION_PART_TYPES.NOTE,
  CONVERSATION_PART_TYPES.REPLY_ADMIN,
];

/** Системные типы реплик */
export const CONVERSATION_PART_SYSTEM_TYPES = [
  CONVERSATION_PART_TYPES.ASSIGNED,
  CONVERSATION_PART_TYPES.CHANNEL_CHANGED,
  CONVERSATION_PART_TYPES.CHAT_BOT_USER,
  CONVERSATION_PART_TYPES.DELAYED,
  CONVERSATION_PART_TYPES.TAG_ADDED,
  CONVERSATION_PART_TYPES.TAG_DELETED,
  CONVERSATION_PART_TYPES.CLOSED,
  CONVERSATION_PART_TYPES.FINAL_CLOSED,
  CONVERSATION_PART_TYPES.SERVICE,
  CONVERSATION_PART_TYPES.VOTE,
];

/** Направления для пагинации сообщений диалога */
export enum REPLY_TYPES {
  AFTER = 'after',
  AROUND = 'around',
  BEFORE = 'before',
}

(function () {
  'use strict';

  angular.module('myApp.manualMessages').factory('MANUAL_MESSAGE_STATISTICS_TABS', getManualMessageStatisticsTabs);

  function getManualMessageStatisticsTabs() {
    return {
      ANSWERS: {
        ORDER: 2,
        TEMPLATE_URL: 'js/components/manual-messages/statistics/tabs/answers.html',
        VALUE: 'answers',
      },
      GENERAL: {
        ORDER: 0,
        TEMPLATE_URL: 'js/components/manual-messages/statistics/tabs/general.html',
        VALUE: 'general',
      },
      SENDINGS: {
        ORDER: 1,
        TEMPLATE_URL: 'js/components/manual-messages/statistics/tabs/sendings.html',
        VALUE: 'sendings',
      },
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';

import { App } from '@http/app/app.model';

/** Компонент для работы с upgrad'ом для получения большего количества аддонов */
@Component({
  selector: 'cq-upgrade[app]',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeComponent {
  /** Приложение */
  @Input()
  app!: App;

  constructor(private readonly stateService: StateService) {}

  /** Редирект на страницу с тарифами */
  redirectToPricingPage(): void {
    this.stateService.go('app.content.subscription.pricing');
  }
}

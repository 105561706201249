import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

/**
 * Сервис для работы с API TOKEN'ами
 */

@Injectable({
  providedIn: 'root',
})
export class ApiTokenModel {
  constructor(private httpClient: HttpClient) {}

  /**
   * Генерация токена
   *
   * @param appId - ID приложения
   */
  generate(appId: string): Observable<any> {
    const params = {
      app: appId,
    };

    return this.httpClient
      .post('/apitokens', params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((response: any) => {
          let apiToken = response.data;

          this.parse(apiToken);

          return apiToken;
        }),
      );
  }

  /**
   * Получение API токена по ID
   *
   * @param tokenId - ID API токена
   */
  get(tokenId: string): Observable<any> {
    return this.httpClient
      .get('/apitokens/' + tokenId, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          let apiToken = response.data;

          this.parse(apiToken);

          return apiToken;
        }),
      );
  }

  /**
   * Получение списка API токенов
   *
   * FIXME:
   *  Запрос сделан без использования пагинации, хотя она там есть! Поэтому работает он не корректно!
   *  Но функцию я всё равно оставлю, чтобы видеть, что возможность получить список токенов есть
   *
   * @param appId - ID приложения
   */
  getList(appId: string): Observable<any> {
    const params = {
      app: appId,
    };

    return this.httpClient
      .get('/apitokens', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          let apiTokens = response.data;

          for (let i = 0; i < apiTokens.length; i++) {
            this.parse(apiTokens[i]);
          }

          return apiTokens;
        }),
      );
  }

  /**
   * Парсинг API токена
   *
   * @param apiToken - API токен
   */
  parse(apiToken: any): any {
    apiToken.created = moment(apiToken.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  }

  /**
   * Отзыв токена
   *
   * @param tokenId - ID токена
   */
  revoke(tokenId: string): Observable<any> {
    return this.httpClient.delete('/apitokens/' + tokenId, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }
}

import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';

/** Таймер ответа пользователю */
type ReplyTimer = {
  hours: number;
  minutes: number;
};

/** Интервал обновления таймера ответа пользователю в милисекундах */
const TIMER_REPLY_UPDATE_INTERVAL_MS: number = 1000 * 60;

@Component({
  selector: 'cq-conversation-reply-timer[lastUserReply][replyDuration]',
  templateUrl: './conversation-reply-timer.component.html',
  styleUrls: ['./conversation-reply-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ConversationReplyTimerComponent implements OnChanges, OnInit, OnDestroy {
  /** Последнее время ответа пользователя */
  @Input() lastUserReply!: moment.Moment;
  /** Время, в течение котрого можно ответить пользователю */
  @Input() replyDuration!: number;
  /** Таймер ответа пользователю */
  replyTimer!: ReplyTimer;
  /** Интервал обновления таймера */
  timerReplyUpdateInterval!: Subscription;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    @Inject(DOCUMENT) readonly document: Document,
    private readonly translocoService: TranslocoService,
  ) {}

  /** Получение текста для тултипа */
  get replyTimerTooltipText() {
    return this.translocoService.translate('conversationReplyTimerComponent.tooltips.whatsapp', {
      hours: this.replyTimer.hours,
      minutes: this.replyTimer.minutes,
      replyDuration: this.replyDuration,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lastUserReply) {
      this.updateTimer();

      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.initReplyTimer();
    this.document.addEventListener('visibilitychange', this.documentVisibilityChangeListener);
  }

  ngOnDestroy(): void {
    this.destroyReplyTimer();
    this.document.removeEventListener('visibilitychange', this.documentVisibilityChangeListener);
  }

  /** Уничтожение таймера ответа пользователю */
  destroyReplyTimer() {
    this.timerReplyUpdateInterval?.unsubscribe();
  }

  /**
   * Обработчик события смены видимости вкладки
   * NOTE: Стрелочная функция тут не просто так, без неё не сработает removeEventListener, либо придётся городить другие костыли
   */
  documentVisibilityChangeListener = () => {
    if (this.document.hidden) {
      this.destroyReplyTimer();
    } else {
      this.initReplyTimer();
    }
  };

  /**
   * Форматирование таймера ответа пользователю
   *
   * @param timer - Объект таймера в не отформатированном формате
   */
  format(timer: ReplyTimer): string {
    const formattedHours = timer.hours;
    const formattedMinutes = timer.minutes < 10 ? '0' + timer.minutes : timer.minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }

  /** Получение таймера ответа пользователю */
  getReplyTimer(): ReplyTimer {
    const replyDuration: moment.Duration = moment.duration(this.replyDuration, 'hours');
    const now: moment.Moment = moment();

    let leftTime: moment.Duration = moment.duration(replyDuration.asMilliseconds() - now.diff(this.lastUserReply));

    // Багфикс. Иногда бывает так, что в leftTime оказывается длительность на несколько миллисекунд больше,
    //  чем в replyDuration, что невозможно
    if (leftTime.asMilliseconds() > replyDuration.asMilliseconds()) {
      // moment преобразует целые значения часов в дни: 12 часов в 1 день, 24 в 2 и т.д. Из-за этого методы hours и
      //  minutes отдаёт 0. Решения, кроме как этого, я на нашёл
      leftTime = replyDuration.subtract(1, 'millisecond');
    }

    return {
      hours: leftTime.hours(),
      minutes: leftTime.minutes(),
    };
  }

  /** Инициализация таймера ответа пользователю */
  initReplyTimer(): void {
    this.timerReplyUpdateInterval = interval(TIMER_REPLY_UPDATE_INTERVAL_MS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateTimer();

        this.cdr.detectChanges();
      });
  }

  /** Обновление таймера ответа пользователю */
  updateTimer(): void {
    this.replyTimer = this.getReplyTimer();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { ACCOUNT_PAGE_ROUTES } from '@panel/app/pages/account/account-page.routes';
import { AccountModule } from '@panel/app/pages/account/pages/account/account.module';

@NgModule({
  imports: [CommonModule, AccountModule, UIRouterUpgradeModule.forChild({ states: ACCOUNT_PAGE_ROUTES })],
})
export class AccountPageModule {}

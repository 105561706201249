import { Addon } from '@http/addon/addon';
import { ADDON_STATUS } from '@http/addon/addon.constants';
import { AddonConstruct } from '@http/addon/addon.types';
import { Coupon } from '@http/coupon/coupon';
import { SubscriptionHelpService } from '@http/subscription/help/subscription-help.service';
import { SubscriptionDto } from '@http/subscription/subscription.types';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';
import { BILLING_PLAN_IDS, SUBSCRIPTION_STATUSES } from '@panel/app/services/billing-info/billing-info.constants';

/** Класс для работы с подпиской */
export class Subscription {
  /** Применённый к подписке купон */
  appliedCoupon: string = '';

  /** Применённые к подписке купоны */
  appliedCoupons: Coupon[] = [];

  /** Подключенные в подписке аддоны */
  connectedAddons: Addon[] = [];

  /** Конец текущего платёжного периода подписки */
  currentTermEnd: number = 0;

  /** Количество счетов ожидающих оплаты */
  dueInvoicesCount: number = 0;

  /** Запланировано ли изменение подписки в следующем биллинг периоде */
  hasScheduledChanges: boolean = false;

  /** Период оплаты подписки */
  paymentPeriod: number = 0;

  /** ID тарифного плана */
  planId: string = '';

  /** Стоимость тарифного плана за весь период оплаты без скидки и без аддонов. */
  planTotalCost: number = 0;

  /** Статус подписки */
  status: SUBSCRIPTION_STATUSES = SUBSCRIPTION_STATUSES.ACTIVE;

  /** Сумма ожидающих оплаты счетов */
  totalDues: number = 0;

  /** Цена подписки (тариф/мес + аддоны/мес) с учётом действующих скидок, которая платится в месяц */
  totalPriceForMonth: number = 0;

  /** Дата окончания триальной подписки */
  trialEndDate: number = 0;

  /** Цена подключённых на тарифном плане аддонов, которая платится за весь период оплаты */
  getConnectedAddonsTotalPrice(numbersUniqueUsers: number): number {
    let price = 0;

    price = this.getConnectedAddonsTotalPriceForMonth(numbersUniqueUsers) * this.paymentPeriod;

    return price;
  }

  /** Цена подключённых на тарифном плане аддонов, которая платится за месяц */
  getConnectedAddonsTotalPriceForMonth(numbersUniqueUsers: number): number {
    let price = 0;

    price = this.connectedAddons.reduce(
      (accumulator, addon: Addon) => accumulator + addon.getPricePerMonth(numbersUniqueUsers),
      0,
    );

    return price;
  }

  /** Активная ли подписка */
  get isActive(): boolean {
    return [SUBSCRIPTION_STATUSES.ACTIVE, SUBSCRIPTION_STATUSES.NON_RENEWING].includes(this.status);
  }

  /** Отменённая ли подписка */
  get isCancelled(): boolean {
    return [SUBSCRIPTION_STATUSES.CANCELLED].includes(this.status);
  }

  /** Возвращает true, если подписка будет отменена в конце текущего биллинг периода */
  isCancelledInEndBillingPeriod(): boolean {
    return this.status === SUBSCRIPTION_STATUSES.NON_RENEWING;
  }

  /** Триальная ли подписки */
  get isTrial(): boolean {
    return this.planId === BILLING_PLAN_IDS.FREE_TRIAL;
  }

  /** Активный триал */
  get isActiveTrial(): boolean {
    return this.isTrial && [SUBSCRIPTION_STATUSES.IN_TRIAL].includes(this.status);
  }

  /** Закончившийся триал */
  get isCancelledTrial(): boolean {
    return this.isTrial && this.isCancelled;
  }

  /** Цена тарифного плана, которая платится за весь период оплаты */
  getPlanTotalPrice(numbersUniqueUsers: number): number {
    let price = 0;

    price = this.getPlanTotalPriceForMonth(numbersUniqueUsers) * this.paymentPeriod;

    return price;
  }

  /** Стоимость тарифного плана за месяц без скидок и аддонов */
  getPlanPriceForMonth(): number {
    let price = 0;

    if (this.planTotalCost) {
      price = this.planTotalCost / this.paymentPeriod;
    }

    return price;
  }

  /** Цена тарифного плана, которая платится за месяц */
  getPlanTotalPriceForMonth(numbersUniqueUsers: number): number {
    let price = 0;

    if (this.totalPriceForMonth) {
      price = this.getPlanTotalPriceForMonthForActiveSubscription(numbersUniqueUsers);
    } else {
      price = this.planTotalPriceForMonthForNotActiveSubscription;
    }

    return price;
  }

  /** Цена тарифного плана для активной подписки, которая платится за месяц */
  getPlanTotalPriceForMonthForActiveSubscription(numbersUniqueUsers: number): number {
    let price = 0;

    if (this.totalPriceForMonth) {
      price = this.totalPriceForMonth - this.getConnectedAddonsTotalPriceForMonth(numbersUniqueUsers);
    }

    return price;
  }

  /** Цена тарифного плана для не активной подписки, которая платится за месяц */
  get planTotalPriceForMonthForNotActiveSubscription(): number {
    let price = 0;

    if (this.planTotalCost) {
      price = this.planTotalCost / this.paymentPeriod;
    }

    return price;
  }

  /** Размер скидки на подписку */
  getTotalDiscount(numbersUniqueUsers: number): number {
    let discount = 0;

    if (this.appliedCoupons.length && this.planTotalCost) {
      discount =
        this.planTotalCost +
        this.getConnectedAddonsTotalPrice(numbersUniqueUsers) -
        this.getTotalPrice(numbersUniqueUsers);
    }

    return discount;
  }

  /**
   * Цена подписки, которая платится за весь период оплаты.
   * В неё входит стоимость тарифа за весь период оплаты и стоимость аддонов за весь период оплаты.
   * В цене уже учтена скидка по купону за тариф.
   *
   * @param numbersUniqueUsers Квота уникальных пользователей в тарифе
   */
  getTotalPrice(numbersUniqueUsers: number): number {
    return this.getPlanTotalPrice(numbersUniqueUsers) + this.getConnectedAddonsTotalPrice(numbersUniqueUsers);
  }

  constructor(
    private readonly factory: InstanceFactory,
    private readonly helpService: SubscriptionHelpService,
    dto: SubscriptionDto,
  ) {
    if (dto.coupon) {
      this.appliedCoupon = dto.coupon;
    }

    if (dto.coupons) {
      this.appliedCoupons = this.factory.getInstanceCoupons(dto.coupons);
    }

    if (dto.paid_addons) {
      const dtosPaidAddons: AddonConstruct[] = dto.paid_addons;
      dtosPaidAddons.map((dtoPaidAddon) => {
        dtoPaidAddon.status = [SUBSCRIPTION_STATUSES.CANCELLED].includes(dto.status)
          ? ADDON_STATUS.SUSPENDED
          : ADDON_STATUS.CONNECTED;
      });
      this.connectedAddons = this.factory.getInstanceAddons(dtosPaidAddons);
    }

    if (dto.current_term_end) {
      this.currentTermEnd = dto.current_term_end * 1000;
    }

    if (dto.due_invoices_count) {
      this.dueInvoicesCount = dto.due_invoices_count;
    }

    this.hasScheduledChanges = dto.has_scheduled_changes;

    if (dto.billing_period) {
      this.paymentPeriod = dto.billing_period;
    }

    if (dto.plan_amount) {
      this.planTotalCost = dto.plan_amount;
    }

    if (dto.plan_id) {
      this.planId = dto.plan_id;
    }

    this.status = dto.status;

    if (dto.total_dues) {
      this.totalDues = dto.total_dues;
    }

    if (dto.mrr) {
      this.totalPriceForMonth = dto.mrr;
    }

    if (dto.trial_end) {
      this.trialEndDate = dto.trial_end * 1000;
    }
  }

  /** Возвращает переведённый текст статуса подписки */
  getStatusText(): string {
    return this.helpService.getSubscriptionStatusText(this.status, this.currentTermEnd);
  }
}

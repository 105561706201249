<ng-container *transloco="let t">
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="collapse.toggle()"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
  >
    <h4 class="no-margin-bottom">{{ t('emailNotificationEventTriggers.heading') }}</h4>
    <i
      class="cqi-sm ml-auto"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>
  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="padding-top-20">
      <ng-select
        [clearable]="false"
        [formControl]="control"
        [hideSelected]="true"
        [items]="filteredAndSortEventTypes"
        [multiple]="true"
        [trackByFn]="eventSelectTrackFn"
        bindLabel="prettyName"
        bindValue="id"
        placeholder="{{ t('emailNotificationEventTriggers.select.placeholder') }}"
      >
        <ng-template
          let-clear="clear"
          let-event="item"
          ng-label-tmp
        >
          <button
            class="btn btn-sm btn-outline-primary d-flex align-items-center overflow-hidden"
            type="button"
          >
            <span class="text-truncate">{{ event.prettyName }}</span>
            <span
              class="d-flex"
              (click)="clear(event)"
            >
              <i class="cqi-xs cqi-times margin-left-5"></i>
            </span>
          </button>
        </ng-template>
      </ng-select>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { UserProperty } from '@http/property/property.model';

@Component({
  selector: 'cq-property-select[properties]',
  templateUrl: './property-select.component.html',
  styleUrls: ['./property-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertySelectComponent extends AbsCVAFormControlBasedComponent<string | null> {
  @Input()
  properties!: UserProperty[];

  readonly control: FormControl<string | null> = this.fb.control(null, { nonNullable: true });

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  protected groupByFn(prop: UserProperty): string {
    return prop.group;
  }

  protected trackByFn(prop: UserProperty): string {
    return prop.name;
  }

  protected searchFn(term: string, item: UserProperty) {
    return !!item.prettyName && item.prettyName.toLowerCase().indexOf(term.toLowerCase()) > -1;
  }
}

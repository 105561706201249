<ng-container *transloco="let t; read: 'stripeEditCardModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <form
      (ngSubmit)="onSubmit()"
      [formGroup]="stripeForm"
    >
      <label
        class="mb-10"
        for="stripeInput"
        >{{ t('label') }}</label
      >
      <cq-stripe-input
        id="stripeInput"
        [formControl]="stripeForm.controls.stripe"
      ></cq-stripe-input>
      <input
        class="hidden"
        id="stripeForm.submit"
        type="submit"
        [disabled]="isApiRequestPerformed | async"
      />
    </form>
    <div
      *ngIf="isAddCard"
      class="text-secondary mt-10"
    >
      {{ t('addCardNote') }}
    </div>
  </div>

  <div class="modal-footer">
    <label
      class="btn btn-primary flex-grow-1"
      for="stripeForm.submit"
      [ngClass]="{ disabled: isApiRequestPerformed | async }"
      >{{ t('saveCardButton') }}</label
    >
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TemplateModule } from '@panel/app/partials/template/template.module';

import { TriggerChainTemplateComponent } from './trigger-chain-template.component';

@NgModule({
  declarations: [TriggerChainTemplateComponent],
  imports: [CommonModule, TemplateModule, TranslocoModule],
})
export class TriggerChainTemplateModule {}

import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { INTEGRATION_TYPES } from '../../../app/http/integration/constants/integration.constants';

(function () {
  'use strict';

  angular.module('myApp.conversations').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.conversations', {
        url: '/conversations',
        redirectTo: 'app.content.conversations.general',
        template: '<div ui-view></div>',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.OPERATOR],
        },
      })

      .state('app.content.conversations.general', {
        url: '?{fromStarterGuideStep}',
        bindings: {
          appBlocks: 'appBlocks',
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          conversations: 'conversations',
          channelCounters: 'channelCounters',
          djangoUser: 'djangoUser',
          telegramIntegrations: 'telegramIntegrations',
          knowledgeBaseSettings: 'knowledgeBaseSettings',
          teamMembers: 'teamMembers',
        },
        component: 'cqConversationsGeneral',
        params: {
          conversationId: {
            value: null,
          },
        },
        resolve: {
          channels: getChannels,
          conversations: getConversations,
          telegramIntegrations: getTelegramIntegrations,
          knowledgeBaseSettings: getKnowledgeBaseSettings,
          teamMembers: getTeamMembers,
        },
      })

      .state('app.content.conversations.detail', {
        url: '/{conversationId:id}',
        bindings: {
          appBlocks: 'appBlocks',
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          teamMembers: 'teamMembers',
          telegramIntegrations: 'telegramIntegrations',
        },
        component: 'cqConversationsSingle',
        resolve: {
          channels: getChannels,
          knowledgeBaseSettings: getKnowledgeBaseSettings,
          teamMembers: getTeamMembers,
          telegramIntegrations: getTelegramIntegrations,
        },
      });

    /**
     * Получение списка каналов
     *
     * @param channelModel
     * @param currentApp
     * @return {Promise}
     */
    function getChannels(channelModel, currentApp) {
      return firstValueFrom(channelModel.getList(currentApp.id, true, true));
    }

    /**
     * Получение настроек БЗ
     *
     * @param knowledgeBaseModel
     * @param currentApp
     * @returns {Promise}
     */
    function getKnowledgeBaseSettings(knowledgeBaseModel, currentApp) {
      return firstValueFrom(knowledgeBaseModel.get(currentApp.id));
    }

    function getTelegramIntegrations(integrationModel, currentApp) {
      return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
        return [...response[0]].filter((integration) => {
          return integration.active === true;
        });
      });
    }

    /**
     * Получение списка членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {Promise}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, false, true));
    }

    /**
     * Получение списка диалогов
     *
     * @param conversationModel
     * @param currentApp
     * @returns {Promise}
     */
    function getConversations(conversationModel, currentApp) {
      return firstValueFrom(
        conversationModel.getList(currentApp.id, null, null, false, true, null, null, [], null, null),
      );
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Invoice } from '@http/invoice/invoice';
import { INVOICE_STATUS } from '@http/invoice/invoice.constants';

/** Компонент для работы со скачиванием документов по счёту */
@Component({
  selector: 'cq-download-documents[invoice]',
  templateUrl: './download-documents.component.html',
  styleUrls: ['./download-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadDocumentsComponent {
  /** Счёт */
  @Input()
  invoice!: Invoice;

  /**
   * Показывать ли кнопку скачивания акта счёта
   *
   * @param inv - Счёт
   */
  idNeedToShowDownloadActButton(inv: Invoice): boolean {
    const isPaidInv = inv.status === INVOICE_STATUS.PAID;
    const isPayViaExist = !inv.paidVia || (inv.paidVia && ['manual', 'Модульбанк'].includes(inv.paidVia));
    const isOrganizationInnExist = inv.organizationInn;
    const isAmountOrTotalPositive = inv.amountDue > 0 || inv.total > 0;

    if ([isPaidInv, isPayViaExist, isOrganizationInnExist, isAmountOrTotalPositive].every((c) => c)) {
      return true;
    }

    return false;
  }

  /**
   * Показывать ли кнопку скачивания счёта
   *
   * @param inv - Счёт
   */
  isNeedToShowDownloadInvoiceButton(inv: Invoice): boolean {
    const isNotVoidedInv = inv.status !== INVOICE_STATUS.VOIDED;
    const isPayViaExist = !inv.paidVia || (inv.paidVia && ['manual', 'Модульбанк'].includes(inv.paidVia));
    const isOrganizationInnExist = !!inv.organizationInn;
    const isAmountOrTotalPositive = inv.amountDue > 0 || inv.total > 0;

    if ([isNotVoidedInv, isPayViaExist, isOrganizationInnExist, isAmountOrTotalPositive].every((c) => c)) {
      return true;
    }

    return false;
  }
}

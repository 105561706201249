import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { LeadBot } from '@http/chat-bot/types/chat-bot-internal.types';

@Component({
  selector: 'cq-email-notification-chat-bots-list-readonly[chatBotsList][integrationId]',
  templateUrl: './chat-bots-list-readonly.component.html',
  styleUrls: ['./chat-bots-list-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationChatBotsListReadonlyComponent {
  /** Список чат-ботов, в которых используется интеграция */
  @Input() chatBotsList!: LeadBot[];
  /** ID текущей интеграции */
  @Input() integrationId!: string;

  /**
   * Получение экшенов с текущей интеграцией EMAIL_NOTIFICATION
   *
   * @param actions Экшены чат-бота
   */
  getActionsWithCurrentIntegration(actions: ChatBotAction[]): ChatBotAction[] {
    return actions.filter(
      (action: ChatBotAction) =>
        action.type === CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION && action.body === this.integrationId,
    );
  }

  /**
   * Получение веток с текущей интеграцией EMAIL_NOTIFICATION
   *
   * @param chatBot - Чат-бот, у которого нужно получить ветки
   */
  getBranchesWithEmailNotificationAction(chatBot: LeadBot): ChatBotBranch[] {
    return (
      (chatBot &&
        chatBot.branches.filter(
          (branch: ChatBotBranch) => this.getActionsWithCurrentIntegration(branch.actions).length,
        )) ||
      []
    );
  }

  /**
   * Получение отформатированного названия веток бота в виде:
   * "«Название ветки 1», «Название ветки 2»..."
   *
   * @param chatBot - Чат-бот, у которого нужно получить отформатированные названия веток
   */
  getFormattedChatBotBranchesNames(chatBot: LeadBot): string {
    return this.getBranchesWithEmailNotificationAction(chatBot)
      .map((branch: ChatBotBranch) => `«${branch.name}»`)
      .join(', ');
  }
}

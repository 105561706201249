import { MESSAGE_PART_TYPES, POPUP_BACKGROUND_TYPES } from '../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.popupEditor').controller('CqPopupPreviewController', CqPopupPreviewController);

  function CqPopupPreviewController($scope, PROJECT_NAME, caseStyleHelper, fileHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.backgroundImageUrl = null;
      vm.caseStyleHelper = caseStyleHelper;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.POPUP_BACKGROUND_TYPES = POPUP_BACKGROUND_TYPES;
      vm.PROJECT_NAME = PROJECT_NAME;
    }
  }
})();

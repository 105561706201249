import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { MAX_AFTER_DELAY } from '@http/message/message.constants';
import { TimeSelectorConfig } from '@panel/app/partials/message-editor/shared/time-selector/time-selector.component';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';
import { TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

export type MessageEditorSendingDelayParams = {
  isEnabled: boolean;
  value: TimeSelectorConfig;
};

@Component({
  selector: 'cq-message-editor-sending-delay',
  templateUrl: './message-editor-sending-delay.component.html',
  styleUrls: ['./message-editor-sending-delay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorSendingDelayComponent extends AbsCVAFormGroupBasedComponent<
  ToFormGroupControls<MessageEditorSendingDelayParams>
> {
  readonly control = this.fb.group<ToFormGroupControls<MessageEditorSendingDelayParams>>({
    isEnabled: this.fb.control<boolean>(false, { nonNullable: true }),
    value: this.fb.control<TimeSelectorConfig>(
      {
        time: 5,
        unit: TIME_UNITS.SECOND,
      },
      { nonNullable: true },
    ),
  });

  /**
   * Человеко читаемое название раздела в котором используется компонент
   */
  @Input()
  entityName: string = '';

  readonly MAX_DELAY = MAX_AFTER_DELAY;

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}

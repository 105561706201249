import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { MessageUtilsModel } from '@http/message-utils/message-utils.model';
import { Properties } from '@http/property/property.model';
import { TriggerChainStepFilter } from '@http/trigger-chain/internal-types';
import { getTriggerChainEmptyFiltersValidator } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-filter-editor/trigger-chain-filter-editor.validators';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  FORM_SUBMIT_TOKEN,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { DestroyService } from '@panel/app/services';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { FILTER_EVENT_TYPE, FILTER_LOGICAL_OPERATION } from '@panel/app/services/filter/filter.constants';
import { EventFilter, PropertyFilter } from '@panel/app/services/filter/types/filter.internal-types';

import { FilterStepEditorForm, TriggerChainFilterModalData } from './trigger-chain-filter-editor.types';

type SelectedFilterType = 'default' | 'jinja';

const JINJA_MAX_LENGTH = 8000 as const;

export const TRIGGER_CHAIN_FILTER_MODAL_DATA_TOKEN = new InjectionToken<TriggerChainFilterModalData>(
  'Data for the filter modal',
);

@Component({
  selector: 'cq-trigger-chain-filter-editor',
  templateUrl: './trigger-chain-filter-editor.component.html',
  styleUrls: ['./trigger-chain-filter-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainFilterEditorComponent implements OnInit {
  app!: App;

  isValidationStrict: boolean = false;

  properties!: Properties;

  get step() {
    return this._step;
  }
  set step(step: TriggerChainStepFilter) {
    this._step = step;

    this.form.setValue(
      {
        name: step.name,
        logicalOperation: step.meta.filters.logicalOperation,
        propertyFilters: step.meta.filters.filters.props,
        eventFilters: step.meta.filters.filters.events,
        jinjaFilter: step.meta.jinjaFilterTemplate,
      },
      {
        emitEvent: false,
      },
    );
    this.selectedFilterType = this.form.controls.jinjaFilter.value === null ? 'default' : 'jinja';
    this.onSelectedFilterTypeChange(this.selectedFilterType);
  }
  private _step!: TriggerChainStepFilter;

  readonly form: FormGroup<FilterStepEditorForm> = this.fb.group<FilterStepEditorForm>(
    {
      name: this.fb.control('', { nonNullable: true }),
      logicalOperation: this.fb.control(FILTER_LOGICAL_OPERATION.AND, { nonNullable: true }),
      propertyFilters: this.fb.control<PropertyFilter[]>(
        [
          {
            propertyName: null,
            operation: {
              type: ELASTICSEARCH_OPERATION.KNOWN,
              value: {
                value: 0,
              },
            },
          },
        ],
        { nonNullable: true },
      ),
      eventFilters: this.fb.control<EventFilter[]>(
        [
          {
            eventId: null,
            eventType: FILTER_EVENT_TYPE.COUNT,
            operation: {
              type: ELASTICSEARCH_OPERATION.KNOWN,
              value: {
                value: 0,
              },
            },
          },
        ],
        { nonNullable: true },
      ),
      jinjaFilter: this.fb.control(null, [Validators.required, Validators.maxLength(JINJA_MAX_LENGTH)]),
    },
    { validators: getTriggerChainEmptyFiltersValidator() },
  );

  selectedFilterType: SelectedFilterType = 'default';

  jinjaValidationResult: boolean | string | null = null;

  readonly JINJA_MAX_LENGTH = JINJA_MAX_LENGTH;

  constructor(
    private readonly fb: FormBuilder,
    private readonly editorInteractions: TriggerChainStepEditorInteractionsService,
    protected readonly triggerChainEditorStore: TriggerChainEditorStore,
    @Inject(FORM_SUBMIT_TOKEN)
    formSubmit$: Observable<void>,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSubject: Subject<void>,
    @Inject(TRIGGER_CHAIN_FILTER_MODAL_DATA_TOKEN)
    private readonly data: TriggerChainFilterModalData,
    destroy$: DestroyService,
    private readonly activeModal: NgbActiveModal,
    private readonly messageUtilsModel: MessageUtilsModel,
    private readonly cdr: ChangeDetectorRef,
  ) {
    formSubmit$.pipe(takeUntil(destroy$)).subscribe(() => {
      this.form.markAsTouched();
    });
    this.app = data.app;
    this.isValidationStrict = data.isValidationStrict;
    this.properties = data.properties;
    this.step = data.step;
  }

  ngOnInit() {
    if (this.isValidationStrict) {
      this.formSubmitSubject.next();
    }
  }

  copyStepChain() {
    this.dismissModal();
    this.editorInteractions.copyClick.next(this.step);
  }

  deleteStepChain() {
    this.dismissModal();
    this.editorInteractions.deleteClick.next(this.step);
  }

  saveAndClose() {
    if (this.form.invalid) {
      this.formSubmitSubject.next();
      return;
    }

    const updatedStep = this.getUpdatedStep();
    this.closeModal(updatedStep);
  }

  getUpdatedStep() {
    let { name, propertyFilters, eventFilters, logicalOperation, jinjaFilter } = this.form.getRawValue();

    const isJinja = this.selectedFilterType === 'jinja';

    this.step.name = name;
    this.step.meta.filters = {
      logicalOperation: logicalOperation,
      filters: {
        props: isJinja ? [] : propertyFilters,
        events: isJinja ? [] : eventFilters,
        tags: [],
      },
    };
    this.step.meta.jinjaFilterTemplate = isJinja ? jinjaFilter : null;

    return this.step;
  }

  runJinjaTest() {
    if (!this.form.controls.jinjaFilter.value) {
      return;
    }
    this.messageUtilsModel.checkJinjaFilterTemplate(this.form.controls.jinjaFilter.value).subscribe({
      next: ({ validationResult }) => {
        this.jinjaValidationResult = validationResult;
        this.cdr.markForCheck();
      },
      error: (err) => {
        this.jinjaValidationResult = err.errorMessage;
        this.cdr.markForCheck();
      },
      complete: () => console.log('completed'),
    });
  }

  onSelectedFilterTypeChange(type: SelectedFilterType) {
    if (type === 'default') {
      this.form.controls.jinjaFilter.disable({ emitEvent: false });
      this.form.controls.eventFilters.enable({ emitEvent: false });
      this.form.controls.propertyFilters.enable({ emitEvent: false });
    } else {
      this.form.controls.jinjaFilter.enable({ emitEvent: false });
      this.form.controls.eventFilters.disable({ emitEvent: false });
      this.form.controls.propertyFilters.disable({ emitEvent: false });
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
    this.editorInteractions.closeClick.next();
  }

  closeModal(step: TriggerChainStepFilter) {
    this.activeModal.close(step);
    this.editorInteractions.closeClick.next();
  }

  isJinjaError(value: boolean | string | null) {
    return typeof value === 'string';
  }
}

(function () {
  'use strict';

  angular.module('myApp.users').controller('CqAddTagsToImportLeadsController', CqAddTagsToImportLeadsController);

  function CqAddTagsToImportLeadsController($translate, $uibModal) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.openAddTagModal = openAddTagModal;
    }

    /**
     * Открытие модалки добавления тега пользователям
     */
    function openAddTagModal() {
      var addTagModal = $uibModal.open({
        controller: 'AddTagModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              body: $translate.instant('userTagsComponent.addTagModal.body'),
            };
          },
          tagList: function () {
            return vm.tags;
          },
        },
        templateUrl: 'js/shared/modals/add-tag/add-tag.html',
      });

      addTagModal.result.then(addTagsToUsersSuccess);

      function addTagsToUsersSuccess(newTag) {
        vm.onNewTagAdded(newTag);
      }
    }
  }
})();

import { copy } from 'angular';

import {
  VkFormsIntegrationFormExternal,
  VkFormsIntegrationQuestionExternal,
  VkFormsIntegrationStandardQuestionExternal,
} from '../interfaces/vk-forms-integration.interfaces';

/** Максимальная длина названия для события при появлении лида */
export const VK_FORMS_CUSTOM_EVENT_TYPE_NAME_MAX_LENGTH = 255;

/** Дефолтные стандартные вопросы*/
export const VK_FORMS_INTEGRATION_DEFAULT_STANDARD_QUESTIONS: VkFormsIntegrationStandardQuestionExternal[] = [
  {
    checked: false,
    v: '$name',
  },
  {
    checked: false,
    v: '$phone',
  },
  {
    checked: false,
    v: '$email',
  },
];

/** Дефолтная форма */
export const VK_FORMS_INTEGRATION_DEFAULT_FORM: VkFormsIntegrationFormExternal = {
  standardQuestions: copy(VK_FORMS_INTEGRATION_DEFAULT_STANDARD_QUESTIONS),
  formLink: null,
  questions: [],
  eventTypeName: null,
};

/** Дефолтный кастомный вопрос */
export const VK_FORMS_INTEGRATION_DEFAULT_QUESTION: VkFormsIntegrationQuestionExternal = {
  k: null,
  v: null,
};

/** Паттерн для валидации URL формы VK. Проверяем наличие хэша с Form ID */
export const VK_FORMS_INTEGRATION_FORM_URL_REGEXP = /#.*form_id=([^&]+)[&|$]?/;

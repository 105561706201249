<ng-container *transloco="let t; read: 'navigationLogoComponent'">
  <div
    #starterGuideTooltip="ngbTooltip"
    [ngbTooltip]="t('starterGuideTooltip')"
    container="body"
    placement="right"
    tooltipClass="navigation-item-tooltip"
    (hidden)="onHideStarterGuideTooltip()"
  >
    <a
      class="btn btn-borderless-navigation"
      [cqStateActive]="states"
      [uiSref]="stateTo"
      data-test="navigation-logo-non-operator-permissions"
    >
      <img
        class="logo carrot-quest"
        *cqHideInUs
        src="assets/img/default/logos/carrot-quest.svg"
      />
      <img
        class="logo dashly"
        *cqHideInRu
        src="assets/img/default/logos/dashly.svg"
      />
    </a>
  </div>
</ng-container>

/** Компонент нужен для возможности вызывать modal.component в AJS */
(function () {
  'use strict';

  angular.module('myApp.modals.modalWrapper').component('cqModalWrapper', cqModalWrapper());

  function cqModalWrapper() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // data.title - Заголовок модального окна
        // data.component - Компонент
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./modal-wrapper.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';

import { AccountingPercent } from '@panel/app/shared/pipes/percent/accounting-percent.pipe';
import { RemoveJinjaPipe } from '@panel/app/shared/pipes/remove-jinja/remove-jinja.pipe';
import { ToFormattedNumberPipe } from '@panel/app/shared/pipes/to-formatted-number/to-formatted-number.pipe';
import { ToNumberPipe } from '@panel/app/shared/pipes/to-number/to-number.pipe';
import { TruncatePipe } from '@panel/app/shared/pipes/truncate/truncate.pipe';
import { WorkTimePipe } from '@panel/app/shared/pipes/work-time/work-time.pipe';
import { EmojiService } from '@panel/app/shared/services/emoji/emoji.service';

import { AddDoctypePipe } from './add-doctype/add-doctype.pipe';
import { AddTargetBlankPipe } from './add-target-blank/add-target-blank.pipe';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { RemoveHrefPipe } from './remove-href/remove-href.pipe';
import { RemoveHtmlTagsPipe } from './remove-html-tags/remove-html-tags.pipe';
import { ReplaceDivEmojiPipe } from './replace-div-emoji/replace-div-emoji.pipe';
import { ToFormattedMoneyPipe } from './to-formatted-money/to-formatted-money.pipe';
import { ToMoneyPipe } from './to-money/to-money.pipe';
import { TransliteratePipe } from './transliterate/transliterate.pipe';
import { UserPropToIterablePipe } from './user-prop-to-iterable/user-prop-to-iterable.pipe';
import { UserPropToSystemPipe } from './user-prop-to-system/user-prop-to-system.pipe';

const declarePipes = [
  CapitalizePipe,
  RemoveHtmlTagsPipe,
  ReplaceDivEmojiPipe,
  ToFormattedMoneyPipe,
  ToFormattedNumberPipe,
  ToMoneyPipe,
  ToNumberPipe,
  UserPropToIterablePipe,
  UserPropToSystemPipe,
  WorkTimePipe,
  TransliteratePipe,
  AccountingPercent,
  RemoveJinjaPipe,
  AddTargetBlankPipe,
  AddDoctypePipe,
  RemoveHrefPipe,
];

@NgModule({
  imports: [CommonModule, MomentModule],
  declarations: [...declarePipes, TruncatePipe],
  exports: [...declarePipes, TruncatePipe],
  providers: [EmojiService],
})
export class PipesModule {}

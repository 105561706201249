import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { App } from '@http/app/app.model';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { UserProperty } from '@http/property/property.model';

@Component({
  selector: 'cq-write-property-selector[currentApp][properties]',
  templateUrl: './write-property-selector.component.html',
  styleUrls: ['./write-property-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WritePropertySelectorComponent extends AbstractCVAControl<string> {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  readonly control = new GenericFormControl<string>(null, Validators.required);

  @Input()
  properties: UserProperty[] = [];

  @Output()
  readonly propertiesChange = new Subject<UserProperty[]>();
}

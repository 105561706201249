import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppService } from '@http/app/services/app.service';
import { MemberInvitation } from '@http/team-member-invitation/types/member-invitations.types';

/**
 * Http-сервис для работы с приглашениями членов команды
 *
 * NOTE:
 *  Данная модель является дублёром модели для работы с приглашениями членов команды на AngularJS.
 *  При добавлении или изменении функционала необходимо синхронизировать их между собой.
 */

@Injectable({
  providedIn: 'root',
})
export class MemberInvitationsService {
  constructor(private readonly appService: AppService, private readonly httpClient: HttpClient) {}

  /** Получение списка приглашений */
  getList(): Observable<MemberInvitation[]> {
    const params = {
      app: this.appService.currentAppId,
    };

    return this.httpClient.get<MemberInvitation[]>('/panel/admins/invite', { params });
  }

  /**
   * Повторная отправка приглашения
   *
   * @param memberInvitation - Приглашение, которое необходимо повторно отправить
   */
  resend(memberInvitation: MemberInvitation): Observable<MemberInvitation> {
    return this.httpClient.patch<MemberInvitation>('/panel/admins/invite', memberInvitation);
  }

  /**
   * Отправка приглашения
   *
   * @param memberInvitation - Приглашение, которое необходимо повторно отправить
   */
  send(memberInvitation: MemberInvitation): Observable<MemberInvitation> {
    return this.httpClient.post<MemberInvitation>('/panel/admins/invite', memberInvitation);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment';

import { Daterange } from '@panel/app/shared/components/datetime-picker/types/daterange.type';

@Component({
  selector: 'cq-daterangepicker-page',
  templateUrl: './daterangepicker-page.component.html',
  styleUrls: ['./daterangepicker-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaterangepickerPageComponent {
  daterangepicker1: Daterange = {
    from: moment(),
    to: moment().add(1, 'month'),
  };
  daterangepicker2: Daterange = {
    from: moment(),
    to: moment().add(1, 'month'),
  };
  daterangepicker3: Daterange = {
    from: moment(),
    to: moment().add(1, 'month'),
  };
}

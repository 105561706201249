import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';

import { OverlayHostDirective } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/directives/overlay-host.directive';
import { TriggerChainOverlayService } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/services';

/**
 * 1. Оверлеи постоянные, как у ботов. Дополнительные компоненты вызываются ручками.
 */

@Component({
  selector: 'cq-trigger-chain-overlay',
  templateUrl: './trigger-chain-overlay.component.html',
  styleUrls: ['./trigger-chain-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainOverlayComponent implements OnInit {
  @ViewChild(OverlayHostDirective, { static: true })
  overlayHost!: OverlayHostDirective;

  constructor(private readonly triggerChainOverlayService: TriggerChainOverlayService) {}

  ngOnInit() {
    this.triggerChainOverlayService.registerOverlayHost(this.overlayHost);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { AutoMessageLoopModalComponent } from './auto-message-loop-modal.component';

@NgModule({
  declarations: [AutoMessageLoopModalComponent],
  exports: [AutoMessageLoopModalComponent],
  imports: [CommonModule, TranslocoModule],
})
export class AutoMessageLoopModalModule {}

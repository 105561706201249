<div class="flex align-items-center margin-between-cols-5 pt-20 pb-20 sticky-top theme-background">
  <ng-container *ngFor="let step of steps; let index = index">
    <button
      class="btn btn-secondary"
      [class.active]="(selectedTabIndex$ | async) === index"
      [class.text-danger]="!step.valid"
      [disabled]="!isStepAvailable(index)"
      (click)="addQueueNext(index)"
      type="button"
    >
      <i
        *ngIf="!step.valid"
        class="cqi-sm cqi-triangle-exclamation-o"
      ></i>
      <ng-container *polymorpheusOutlet="step.label as primitive">
        {{ primitive }}
      </ng-container>
    </button>
  </ng-container>

  <div class="flex ml-auto">
    <ng-container [ngTemplateOutlet]="selectedStepActions"></ng-container>
  </div>
</div>

<div class="stepper-content">
  <ng-container [ngTemplateOutlet]="selectedStepContent"></ng-container>
</div>

<ng-container *ngIf="globalLoader$ | async">
  <cq-loader class="position-absolute left-0 right-0 bottom-0 top-0 loader-wrap theme-background"></cq-loader>
</ng-container>

<ng-container *transloco="let t; read: 'messageEditorOpenedSdkPageTriggerTypesComponent'">
  <div class="mb-15">
    {{ t('description', { entityName: entityName, triggerTypesCount: control.length }) | capitalize }}
  </div>
  <ng-container *ngFor="let triggerTypeControl of control.controls; let index = index; trackBy: trackBy">
    <cq-message-editor-opened-sdk-page-trigger-type
      class="pb-15"
      [formControl]="triggerTypeControl"
      [isOnlyEvent]="control.length < 2"
      (deleteTriggerType)="removeTriggerType(index)"
    ></cq-message-editor-opened-sdk-page-trigger-type>
  </ng-container>
  <button
    (click)="addTriggerTypeControl()"
    class="btn btn-secondary w-100"
  >
    <i class="cqi-sm btn-icon cqi-plus"></i>
    <span>{{ t('addTriggerTypeButton') }}</span>
  </button>
</ng-container>

/**
 * Директива-обёртка для colpick
 */
(function () {
  'use strict';

  angular.module('myApp.directives.colorPicker').directive('cqColorPickerSelector', colorPicker);

  function colorPicker() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, ngModel) {
      var initColor = '000000';

      if (ngModel.$viewValue) {
        initColor = ngModel.$viewValue.split('#')[1];
      }

      iElement.colpick({
        submit: 0,
        flat: true,
        color: initColor,
        colorScheme: 'light',
        layout: 'hex',
        height: 148, // за вычетом border у элемента
        onChange: function (hsb, hex, rgb, iElement, bySetColor) {
          if (ngModel.$viewValue && ngModel.$viewValue.split('#')[1] == hex) {
            return;
          }

          scope.$apply(function () {
            if (!bySetColor) {
              ngModel.$setViewValue('#' + hex);
            }
          });
        },
      });

      ngModel.$render = function () {
        if (!ngModel.$viewValue) {
          return;
        }

        iElement.colpickSetColor(ngModel.$viewValue.split('#')[1]);
      };
    }
  }
})();

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqBitrix1cModalController', CqBitrix1cModalController);

  function CqBitrix1cModalController($translate, appModel, PROJECT_NAME, carrotquestHelper) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.trackClickInstallModule = trackClickInstallModule;
    }

    /**
     * Трек клика на 'Установить модуль'
     */
    function trackClickInstallModule() {
      carrotquestHelper.track('Интеграции - ' + vm.resolve.integration.type + ' - клик на "Установить модуль"');
    }
  }
})();

/**
 * Типы взаимодействия с чат-ботами получателей чат-ботов
 */

export enum CHAT_BOT_TYPES_SENDINGS_INTERACTION {
  CLICKED = 'clicked',
  FINISHED = 'finished',
  INTERRUPTED = 'interrupted',
  NOT_FINISHED = 'not_finished',
  SENT = 'sent',
  STARTED = 'started',
  LEFT_CONTACTS = 'left_contacts',
  STARTED_CONVERSATION = 'started_conversation',
  IN_PROGRESS = 'in_progress',
}

export enum CHAT_BOT_EXPORT_HEADER {
  USER_NAME = 'user_name',
  USER_LINK = 'user_link',
  SENT = 'sent',
  STARTED_CONVERSATION = 'started_conversation',
  IN_PROGRESS = 'in_progress',
  INTERRUPTED = 'interrupted',
  LAST_PHONE = 'last_phone',
  LAST_EMAIL = 'last_email',
  LEFT_CONTACTS = 'left_contacts',
  FINISHED = 'finished',
  FINISHED_BRANCH_NAME = 'finished_branch_name',
  FINISHED_ACTION_TYPE = 'finished_action_type',
  CONVERSATION_LINK = 'conversation_link',
}

/**
 * Упорядоченный массив с типами взаимодействия с чат-ботами получателей чат-ботов
 */
export const CHAT_BOT_TYPES_SENDINGS_INTERACTION_ARRAY = [
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.SENT,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.STARTED_CONVERSATION,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.IN_PROGRESS,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.INTERRUPTED,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.LEFT_CONTACTS,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.FINISHED,
];

/**
 * Упорядоченный массив с типами взаимодействия с велком-ботами
 */
export const ROUTING_BOT_TYPES_SENDINGS_INTERACTION_ARRAY = [
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.STARTED_CONVERSATION,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.IN_PROGRESS,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.INTERRUPTED,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.LEFT_CONTACTS,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.FINISHED,
];

/**
 * Упорядоченный массив с типами взаимодействия с telegram-ботами
 */
export const TELEGRAM_BOT_TYPES_SENDINGS_INTERACTION_ARRAY = [
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.SENT,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.STARTED_CONVERSATION,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.IN_PROGRESS,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.INTERRUPTED,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.LEFT_CONTACTS,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION.FINISHED,
];

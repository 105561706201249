<ng-container *transloco="let t; read: 'telegramValueSelectComponent'">
  <ng-select
    class="mt-10 integrations-select"
    [items]="telegramIntegrations"
    [multiple]="true"
    [placeholder]="t('integrationSelector.placeholder')"
    [clearable]="false"
    [closeOnSelect]="false"
    [searchable]="false"
    [(ngModel)]="selectedIntegrations"
    (change)="updateFilterValue()"
    bindValue="settings.botId"
  >
    <ng-template
      ng-multi-label-tmp
      let-items="items"
    >
      <div
        [ngbTooltip]="stringifyArray(items)"
        [disableTooltip]="items.length < 2"
        container="body"
        class="selected-integrations"
      >
        {{ stringifyArray(items) }}
      </div>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item$="item$"
      let-item="item"
    >
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          [ngModel]="item$.selected"
        />
        <span class="custom-control-label">@{{ item.settings.botName }}</span>
      </div>
    </ng-template>
  </ng-select>
  <cq-validation-messages [control]="control.controls.value">
    <cq-validation-message errorName="required">
      {{ t('integrationSelector.errors.required') }}
    </cq-validation-message>
  </cq-validation-messages>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { UserTagModule } from '@panel/app/partials/user-tag/user-tag.module';

import { MessageFiltersComponent } from './message-filters.component';

@NgModule({
  exports: [MessageFiltersComponent],
  declarations: [MessageFiltersComponent],
  imports: [CommonModule, TranslocoModule, UserTagModule],
})
export class MessageFiltersModule {}

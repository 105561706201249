(function () {
  'use strict';

  require('./widget-tooltip.controller');

  angular.module('myApp.chatSettings').component('cqWidgetTooltip', cqWidgetTooltip());

  function cqWidgetTooltip() {
    return {
      bindings: {
        hideText: '=', // Видимость тултипа чата
        text: '=', // Текст тултипа чата
        showAlways: '=', // Показывать тултип всегда
      },
      controller: 'CqWidgetTooltipController',
      controllerAs: 'vm',
      template: require('./widget-tooltip.html'),
    };
  }
})();

import {
  MESSAGE_PART_TYPES,
  POPUP_BACKGROUND_TYPES,
} from '../../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.popupEditor').controller('CqPopupPreviewContentController', CqPopupPreviewContentController);

  function CqPopupPreviewContentController($scope, PROJECT_NAME, caseStyleHelper, fileHelper, l10nHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.backgroundImageUrl = null;
      vm.caseStyleHelper = caseStyleHelper;
      vm.currentLocale = l10nHelper.getTwoLetterLang(vm.locale);
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.POPUP_BACKGROUND_TYPES = POPUP_BACKGROUND_TYPES;
      vm.PROJECT_NAME = PROJECT_NAME;

      // если пользователь выбирает файл - его нужно распарсить в URL, а иначе - присвоить как есть
      $scope.$watch('vm.popup.bodyJson.params.backgroundImage', function (newValue, oldValue) {
        if (newValue instanceof File) {
          vm.backgroundImageUrl = fileHelper.getObjectUrl(newValue);
        } else {
          vm.backgroundImageUrl = newValue;
        }
      });
    }
  }
})();

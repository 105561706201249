<ng-container *transloco="let t; read: 'templatePreviewComponent'">
  <div class="d-flex">
    <div class="preview-left full-width d-flex flex-column align-items-start p-30 padding-right-60">
      <button
        class="btn btn-secondary"
        (click)="onClickBackButton()"
      >
        <i class="cqi-sm cqi-arrow-left mr-5"></i>
        {{ 'general.backward' | transloco }}
      </button>
      <h1 class="my-15 template-name position-relative">{{ template.name }}</h1>
      <div class="tags flex flex-wrap">
        <span
          *ngFor="let tag of template.tags"
          class="badge badge-light-secondary"
        >
          {{ tag }}
        </span>
      </div>
      <div
        class="my-20"
        [innerHTML]="template.description"
      ></div>
      <div class="d-flex align-items-end flex-grow-1">
        <button
          class="btn btn-primary"
          (click)="onClickSelectTemplate()"
        >
          <ng-container *ngIf="isCreate; else replaceCurrent">
            <span data-test="use-template-btn">{{ t('useTemplateBtn') }}</span>
          </ng-container>
          <ng-template #replaceCurrent>
            <i class="cqi-sm cqi-external-link mr-5"></i>
            <span data-test="create-template-btn">{{ t('createFromTemplateBtn') }}</span>
          </ng-template>
        </button>
      </div>
    </div>
    <div class="preview-right full-width bg-light-primary flex flex-column justify-content-center">
      <img
        class="full-width"
        [src]="template.previewImage"
        alt="preview"
      />
    </div>
  </div>
</ng-container>

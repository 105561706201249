import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings } from '@http/app/app.types';

/** Модель для работы с настройками чата */
@Injectable({ providedIn: 'root' })
export class ChatSettingsModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Сохранение настроек
   *
   * @param appId ID приложения
   * @param settings Объект с настройками чата
   */
  save(appId: string, settings: Partial<AppSettings>): Observable<void> {
    return this.http.patch<void>(`/apps/${appId}`, settings);
  }
}

import { UserColorsCalculationService } from '../../../../app/shared/services/user-colors-calculation/user-colors-calculation.service';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_PART_TYPES, POPUP_CHAT_TYPES } from '../../../../app/http/message-part/message-part.constants';
import { STARTER_GUIDE_STEPS } from '../../../../app/http/starter-guide/starter-guide.constants';

(function () {
  'use strict';

  angular
    .module('myApp.starterGuide')
    .controller('CqStarterGuideFirstTriggerMessageController', CqStarterGuideFirstTriggerMessageController);

  function CqStarterGuideFirstTriggerMessageController(
    $filter,
    $translate,
    CDN_ENDPOINT,
    PROJECT_NAME,
    appModel,
    carrotquestHelper,
    eventTypeModel,
    messageModel,
    starterGuideModel,
    validationHelper,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.CDN_ENDPOINT = CDN_ENDPOINT;
      vm.defaultMessageSender = $filter('filter')(vm.messageSenders, { isDefault: true })[0];
      vm.iconTheme = getIconTheme();
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса на создание триггерного сообщения
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.sessionStartedEventId = ''; // ID события «Зашел на сайт»
      vm.STARTER_GUIDE_STEPS = STARTER_GUIDE_STEPS;
      vm.starterGuideModel = starterGuideModel;
      vm.submit = submit;
      vm.triggerMessageText = $translate.instant('starterGuideFirstTriggerMessage.triggerMessageForm.predefinedText');
      vm.validationHelper = validationHelper;

      getSessionStartedEventId();
    }

    /**
     * Возвращает iconTheme, тема иконки чата (default или dark)
     *
     * @returns {string}
     */
    function getIconTheme() {
      let { iconTheme } = UserColorsCalculationService.getUserColorPalette(
        vm.currentApp.settings.messenger_collapsed_color,
        vm.currentApp.settings.messenger_theme,
      );

      return iconTheme;
    }

    /** Получение ID события «Зашел на сайт» */
    function getSessionStartedEventId() {
      firstValueFrom(eventTypeModel.getList(vm.currentApp.id)).then((eventTypes) => {
        vm.sessionStartedEventId = eventTypes
          .filter((eventType) => eventType.name === '$session_start')
          .map((eventType) => eventType.id)[0];
      });
    }

    /** Создание триггерного сообщения */
    function submit(isValid) {
      if (isValid) {
        vm.isApiRequestPerformed = true;

        firstValueFrom(
          messageModel.createFirstTriggerMessage(
            vm.currentApp.id,
            vm.triggerMessageText,
            vm.defaultMessageSender,
            vm.sessionStartedEventId,
          ),
        )
          .then(() => {
            starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE).subscribe();

            trackStarterGuideStepFirstTriggerMessageCompletion();
          })
          .finally(() => {
            vm.isApiRequestPerformed = false;
          });
      }
    }

    /** Трекинг завершения шага "Первое триггерное сообщение" в стартергайде */
    function trackStarterGuideStepFirstTriggerMessageCompletion() {
      const eventName = 'Стартергайд - приветственное сообщение создано';

      carrotquestHelper.track(eventName);
    }
  }
})();

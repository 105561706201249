import { Injectable } from '@angular/core';

import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { TriggerChain } from '@http/trigger-chain/internal-types';
import { TriggerChainTemplate } from '@http/trigger-chain-template/internal-types/trigger-chain-template.internal.type';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Injectable({
  providedIn: 'root',
})
export class TriggerChainTrackService {
  constructor(private readonly carrotquestHelper: CarrotquestHelper) {}

  trackActivateChain(): void {
    this.carrotquestHelper.track('Цепочки сообщений — активировал цепочку');
  }

  trackAttemptRunChainWithAccessDenial(): void {
    this.carrotquestHelper.track('Цепочки сообщений — клик на «Запустить» с монеткой');
  }

  trackClickOnDatePickerInChainMessageStatistics(messageName: string): void {
    let eventName = 'Цепочки сообщений - клик на датапикер';
    let eventParams = {
      Название: messageName,
    };

    this.carrotquestHelper.track(eventName, eventParams);
  }

  trackClickOnFilterByUserInChainMessageStatistics(messageName: string): void {
    let eventName = 'Цепочки сообщений - клик по фильтру в "Пользователи"';
    let eventParams = {
      Название: messageName,
    };

    this.carrotquestHelper.track(eventName, eventParams);
  }

  trackFirstRunChain(template: TriggerChainTemplate | null = null): void {
    let eventName = 'Цепочки сообщений — запустил цепочку';
    let eventParams = {
      'Название шаблона': template ? template.name : null,
    };

    this.carrotquestHelper.track(eventName, eventParams);
  }

  trackPauseChain(): void {
    this.carrotquestHelper.track('Цепочки сообщений — приостановил цепочку');
  }

  trackTemplateZeroSelection(): void {
    const eventName = 'Цепочки сообщений — выбрал шаблон c нуля';

    this.carrotquestHelper.track(eventName);
  }

  trackTemplateSelection(template: TriggerChainTemplate): void {
    const eventName = 'Цепочки сообщений — выбрал шаблон';
    const eventParams = {
      'Название шаблона': template.name,
    };

    this.carrotquestHelper.track(eventName, eventParams);
  }

  trackTransitionOnChainList(): void {
    this.carrotquestHelper.track('Цепочки сообщений — зашел');
  }

  trackTransitionOnChainMessageStatistics(): void {
    this.carrotquestHelper.track('Цепочки сообщений — перешёл на статистику сообщения');
  }

  trackTransitionOnChainStatistics(): void {
    this.carrotquestHelper.track('Цепочки сообщений — перешёл в статистику цепочки');
  }

  trackTransitionOnCreateChain(): void {
    this.carrotquestHelper.track('Цепочки сообщений — перешёл к созданию цепочки');
  }

  trackTransitionOnEditChain(): void {
    this.carrotquestHelper.track('Цепочки сообщений — перешёл к редактированию цепочки');
  }

  trackTransitionOnLearningBase(): void {
    this.carrotquestHelper.track('Цепочки сообщений — обучающие статьи');
  }

  trackTransitionOnTabInStatistics(statisticTabName: string, messageName: string): void {
    let eventName = `'Цепочки сообщений - зашёл на "${statisticTabName}"`;
    let eventParams = {
      Название: messageName,
    };

    this.carrotquestHelper.track(eventName, eventParams);
  }

  trackTemplateModalClose(): void {
    this.carrotquestHelper.track('Цепочки сообщений — закрыл шаблоны');
  }

  trackUsePropsIntoEmailTitle(chain: TriggerChain) {
    const emailSteps = chain.steps.filter(
      (step) => step.type === 'autoMessage' && step.meta.message.parts[0].type === MESSAGE_PART_TYPES.EMAIL,
    );
    const isEmailPartMessageUseInsertProps = emailSteps
      ? emailSteps.some(
          (step) => step.type === 'autoMessage' && /\{\{.*\}\}/g.test(step.meta.message.parts[0].email.subject),
        )
      : false;

    if (isEmailPartMessageUseInsertProps) {
      this.carrotquestHelper.track('Цепочки сообщений - Вставил свойство в тему письма');
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { User } from '@http/user/types/user.type';
import { EditUserPropertyModalComponent } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.component';
import { EDIT_USER_PROPERTY_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.token';
import { ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import {
  ProductFeatureAccess,
  ProductFeatureDenialReason,
} from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { LS_UNCOLLAPSED_USER_CARD_BLOCKS } from '@panel/app/shared/constants/localstorage.keys';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { UserSystemProperty } from '@http/property/property.constants';
import { Properties, PropertyModel, UserProperty } from '@http/property/property.model';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Component({
  selector: 'cq-user-props',
  templateUrl: './user-props.component.html',
  styleUrls: ['./user-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPropsComponent implements OnInit {
  /** Кастомные свойства пользователя */
  @Input()
  customProps!: User['propsCustom'];

  /** Текущий апп */
  @Input()
  currentApp!: App;

  /** Пользователь */
  @Input()
  user!: User;

  @Input()
  pinnedProps: Partial<Array<UserSystemProperty | '$carrotId' | '$userId' | string>> = [];

  @Output()
  addPinnedProp: EventEmitter<string> = new EventEmitter();

  /** Колбек на смену состояния коллапса */
  @Output()
  collapseStateChange: EventEmitter<string> = new EventEmitter();

  /** Колбэк на смену значения свойства пользователя */
  @Output()
  updateProps: EventEmitter<void> = new EventEmitter<void>();

  /** Доступ до свойств пользователей */
  accessToUsersProps: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /** Имя для localeStorage */
  userPropsLSName = 'user_props';

  /** Состояние коллапса */
  isCollapsed: boolean = true;

  /** Массив кастомных свойств пользователя */
  convertedCustomProps: string[] = [];

  /** Cвойства с начальными UTM-метками */
  initialUtmProps: UserSystemProperty[] = [
    '$initial_utm_campaign',
    '$initial_utm_content',
    '$initial_utm_medium',
    '$initial_utm_source',
    '$initial_utm_term',
  ];

  /** Свойства с последними UTM-метками */
  lastUtmProps: UserSystemProperty[] = [
    '$last_utm_campaign',
    '$last_utm_content',
    '$last_utm_medium',
    '$last_utm_source',
    '$last_utm_term',
  ];

  /** Свойства обязательные к отображению, вне зависимости наличия значения */
  requirementsSystemProps: Array<UserSystemProperty | '$carrotId' | '$userId'> = [
    '$sessions',
    '$score',
    '$city',
    '$last_session_referrer',
    '$initial_referrer',
    '$initial_referrer_domain',
    '$last_seen',
    '$userId',
    '$device_type',
    '$social_telegram_id',
  ];

  /** Свойства Roistat */
  roistatProps: UserSystemProperty[] = ['$roistat_visit', '$lead_cost'];

  /** Социальные сети */
  userSocials: UserSystemProperty[] = [
    '$social_vk',
    '$social_facebook',
    '$social_instagram',
    '$social_foursquare',
    '$social_googleplus',
    '$social_pinterest',
    '$social_twitter',
    '$social_skype',
    '$social_telegram',
    '$social_whatsapp',
  ];

  /** Свойства для CRM */
  userSystemsCrmProps: UserSystemProperty[] = ['$bitrix24_id', '$bitrix24_responsible', '$retailcrm_id'];

  /** Свойства для интернет-магазинов */
  userSystemsEcomProps: UserSystemProperty[] = [
    '$group',
    '$orders_count',
    '$discount',
    '$cart_amount',
    '$last_payment',
    '$revenue',
    '$viewed_categories',
    '$viewed_products',
    '$ordered_categories',
    '$ordered_items',
    '$cart_items',
    '$last_order_status',
    '$profit',
  ];

  /** Системные свойства, который нужно отобразить как кастомные для редактирования */
  userSystemsPropsAsCustom: string[] = ['$email', '$phone'];

  /**
   * Возможность закрепления св-ва.
   * если true, то будет закрепление св-в,
   * если false, то будет открепление св-в.
   */
  pinningPossibility = true;

  constructor(
    public readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly propertyModel: PropertyModel,
    private readonly $uibModal: UibModalService,
    private readonly paywallService: PaywallService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly modalHelperService: ModalHelperService,
  ) {}

  ngOnInit(): void {
    this.accessToUsersProps = this.planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_PROPS, this.currentApp);

    if (localStorage.getItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS)?.includes(this.userPropsLSName)) {
      this.isCollapsed = false;
    }
    this.convertedCustomProps = Object.keys(this.customProps);
  }

  /**
   * При использовании двусторонней связи в ngbCollapse блок дергался при открытии и закрытии
   */
  changeCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    this.changeDetectorRef.detectChanges();
  }

  copiedSuccess() {
    const text = this.translocoService.translate('userPropsComponent.copiedTooltip');
    this.toastService.success(text);
  }

  openAddUserPropertyModal() {
    firstValueFrom(this.propertyModel.getList(this.currentApp.id)).then((properties: Properties) => {
      let props1 = properties.userProps.filter((prop) => this.filterUserPropsGroups(prop));
      const addTagModal = this.$uibModal.open({
        component: 'cqAddUserPropertyModal',
        resolve: {
          modalWindowParams: () => {
            return {
              currentApp: this.currentApp,
              propertyList: props1,
              userId: this.user.id,
            };
          },
        },
        size: 'md modal-dialog-centered',
      });

      addTagModal.result.then(() => {
        this.updateProps.emit();
      });
    });
  }

  filterUserPropsGroups(userProperty: UserProperty) {
    return !~[
      this.translocoService.translate('models.property.groups.utm'),
      this.translocoService.translate('models.property.groups.geography'),
      this.translocoService.translate('models.property.groups.basicProps'),
    ].indexOf(userProperty.group);
  }

  /**
   * Открытие модалки редактирования свойства пользователя
   *
   * @param property
   */
  openEditUserPropertyModal(property: { propertyName: string | undefined; propertyValue: string | undefined }): void {
    this.modalHelperService
      .provide(EDIT_USER_PROPERTY_MODAL_DATA_TOKEN, {
        propertyName: property.propertyName as string,
        propertyValue: property.propertyValue,
        userId: this.user.id,
      })
      .open(EditUserPropertyModalComponent, {
        centered: true,
        scrollable: true,
      })
      .result.then(
        () => {
          this.updateProps?.emit();
        },
        () => {},
      );
  }

  /** Является св-во закрепленным */
  isPinned(propName: UserSystemProperty | string | undefined) {
    return this.pinnedProps.includes(propName as string);
  }

  /** Есть ли у пользователя свойства для CRM */
  isUserHasCrmProps(): boolean {
    return !!(this.user.props.$bitrix24_id || this.user.props.$bitrix24_responsible || this.user.props.$retailcrm_id);
  }

  /** Есть ли у пользователя свойства для интернет-магазинов */
  isUserHasEcomProps(): boolean {
    return !!(
      this.user.props.$cart_amount ||
      this.user.props.$last_payment ||
      this.user.props.$revenue ||
      this.user.props.$viewed_products ||
      this.user.props.$cart_items ||
      this.user.props.$last_order_status ||
      this.user.props.$discount ||
      this.user.props.$orders_count ||
      this.user.props.$group ||
      this.user.props.$profit ||
      this.user.props.$ordered_items ||
      this.user.props.$ordered_categories ||
      this.user.props.$viewed_categories
    );
  }

  /** Есть ли у пользователя свойства с начальными UTM-метками */
  isUserHasInitialUtmProps(): boolean {
    return !!(
      this.user.props.$initial_utm_campaign ||
      this.user.props.$initial_utm_content ||
      this.user.props.$initial_utm_medium ||
      this.user.props.$initial_utm_source ||
      this.user.props.$initial_utm_term
    );
  }

  /** Есть ли у пользователя свойства с последними UTM-метками */
  isUserHasLastUtmProps(): boolean {
    return !!(
      this.user.props.$last_utm_campaign ||
      this.user.props.$last_utm_content ||
      this.user.props.$last_utm_medium ||
      this.user.props.$last_utm_source ||
      this.user.props.$last_utm_term
    );
  }

  /** Есть ли у пользователя свойства Roistat */
  isUserHasRoistatProps(): boolean {
    return !!(this.user.props.$roistat_visit || this.user.props.$lead_cost);
  }

  /** Есть ли у пользователя свойства социальных сетей */
  isUserHasSocialProps(): boolean {
    return !!(
      this.user.props.$social_vk ||
      this.user.props.$social_facebook ||
      this.user.props.$social_instagram ||
      this.user.props.$social_foursquare ||
      this.user.props.$social_googleplus ||
      this.user.props.$social_pinterest ||
      this.user.props.$social_twitter ||
      this.user.props.$social_skype ||
      this.user.props.$social_telegram ||
      this.user.props.$social_whatsapp
    );
  }

  /**
   * Открытие модалки удаления свойства пользователя
   *
   * @param prop
   */
  removeUserProperty(prop: string) {
    const removeUserPropertyModal = this.$uibModal.open({
      component: 'cqRemoveUserPropertyModal',
      resolve: {
        modalWindowParams: () => {
          return {
            currentAppId: this.currentApp.id,
            propertyName: prop,
            userId: this.user.id,
          };
        },
      },
      size: 'md modal-dialog-centered',
    });

    removeUserPropertyModal.result.then(() => {
      this.updateProps?.emit();
    });
  }

  /**
   * Показ пейволла
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина отказа в доступе
   */
  showPaywall(currentApp: App, denialReason: ProductFeatureDenialReason): void {
    this.paywallService.showPaywall(currentApp, denialReason);
  }

  trackByFn(index: number, value: string | undefined) {
    return index;
  }

  /**
   * Функция для оборачивания строки
   * @param input
   * @param start
   * @param end
   */
  wrap(input: string | number, start: string, end: string) {
    return input && (start || end) ? [start, input, end || start].join('') : input;
  }
}

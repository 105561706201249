(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqWidgetTooltipController', CqWidgetTooltipController);

  function CqWidgetTooltipController(validationHelper) {
    let vm = this;

    /**
     * Максимальное количество символов в тултипе
     *
     * @type {number}
     */
    const MAX_TEXT_LENGTH = 120;

    vm.$onInit = init;

    function init() {
      vm.MAX_TEXT_LENGTH = MAX_TEXT_LENGTH;
      vm.validationHelper = validationHelper;
    }
  }
})();

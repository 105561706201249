import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDate, NgbDatepickerConfig, NgbDatepickerState, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';

import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { DATERANGE } from '@panel/app/shared/components/datetime-picker/constants/daterange.constant';
import { DatetimePickerService } from '@panel/app/shared/components/datetime-picker/services/datetime-picker.service';
import { Daterange } from '@panel/app/shared/components/datetime-picker/types/daterange.type';
import { DaterangepickerFormGroup } from '@panel/app/shared/components/datetime-picker/types/daterangepicker-form-group.type';
import { DaterangepickerOptions } from '@panel/app/shared/components/datetime-picker/types/daterangepicker-options.type';

@Component({
  selector: 'cq-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaterangepickerComponent extends AbsCVAFormGroupBasedComponent<DaterangepickerFormGroup> {
  @Input()
  options: DaterangepickerOptions | null = null;

  defaultRangeList: DATERANGE[] = [
    DATERANGE.TODAY,
    DATERANGE.YESTERDAY,
    DATERANGE.LAST_7_DAYS,
    DATERANGE.LAST_MONTH,
    DATERANGE.THIS_MONTH,
    DATERANGE.LAST_MONTH,
  ];

  initialDate: Daterange = {
    from: moment().subtract(1, 'month'),
    to: moment(),
  };

  hoveredDate: NgbDate | null = null;

  maxDateCache!: NgbDateStruct;

  readonly control: FormGroup<DaterangepickerFormGroup> = this.fb.group<DaterangepickerFormGroup>({
    from: this.fb.control(moment()),
    to: this.fb.control(moment().add(1, 'month')),
  });

  get rangeList(): DATERANGE[] {
    if (this.options && this.options.rangeList) {
      return this.options.rangeList;
    }

    return this.defaultRangeList;
  }

  constructor(
    protected readonly datetimePickerService: DatetimePickerService,
    private readonly fb: FormBuilder,
    public readonly datepickerConfig: NgbDatepickerConfig,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.maxDateCache = this.datepickerConfig.maxDate;
  }

  get fromControl() {
    return this.control.controls.from;
  }

  get toControl() {
    return this.control.controls.to;
  }

  getDisplayDate(dateType: 'to' | 'from'): string {
    let date = dateType === 'from' ? this.control.controls.from.getRawValue() : this.control.controls.to.getRawValue();
    let format = (this.options && this.options.format) ?? 'L';

    if (!date) {
      if (this.hoveredDate) {
        return this.datetimePickerService.fromNgbToMoment(this.hoveredDate).format(format);
      } else {
        return moment().format(format);
      }
    }

    return date.format(format);
  }

  goToRange(type: DATERANGE): void {
    let range: Daterange = this.datetimePickerService.getPreparedRange(type);

    this.control.controls.from.setValue(range.from);
    this.control.controls.to.setValue(range.to);
  }

  isOutside(state: NgbDatepickerState, date: NgbDate): boolean {
    return (
      date.before(state.firstDate) ||
      date.after(state.lastDate) ||
      date.before(state.minDate) ||
      date.after(state.maxDate)
    );
  }

  isEndDate(date: NgbDate): boolean {
    let endDate = this.control.controls.to.getRawValue();
    if (endDate === null) {
      return false;
    }

    return date.equals(this.datetimePickerService.fromMomentToNgb(endDate));
  }

  isRangeDate(date: NgbDate): boolean {
    let fromDate = this.control.controls.from.getRawValue();
    let toDate = this.control.controls.to.getRawValue();

    if (fromDate && date.equals(this.datetimePickerService.fromMomentToNgb(fromDate))) {
      return false;
    }

    if (toDate && date.equals(this.datetimePickerService.fromMomentToNgb(toDate))) {
      return false;
    }

    if (!toDate) {
      if (date.equals(this.hoveredDate)) {
        return true;
      }

      if (
        fromDate &&
        date.after(this.datetimePickerService.fromMomentToNgb(fromDate)) &&
        !date.after(this.hoveredDate)
      ) {
        return true;
      }
    }

    return (
      toDate !== null &&
      fromDate !== null &&
      date.after(this.datetimePickerService.fromMomentToNgb(fromDate)) &&
      date.before(this.datetimePickerService.fromMomentToNgb(toDate))
    );
  }

  isStartDate(date: NgbDate): boolean {
    let startDate = this.control.controls.from.getRawValue();
    if (startDate === null) {
      return false;
    }

    return date.equals(this.datetimePickerService.fromMomentToNgb(startDate));
  }

  onBlurInput({ target }: Event, dateType: 'from' | 'to'): void {
    const input = target as HTMLInputElement;
    const momentDate = moment(input.value, moment.localeData().longDateFormat('L'), true);

    switch (true) {
      case dateType === 'from' && momentDate.isValid():
        this.control.controls.from.setValue(momentDate);
        break;
      case dateType === 'from' && !momentDate.isValid():
        this.control.controls.from.setValue(this.initialDate.from);
        break;
      case dateType === 'to' && momentDate.isValid():
        this.control.controls.to.setValue(momentDate);
        break;
      case dateType === 'to' && !momentDate.isValid():
        this.control.controls.from.setValue(this.initialDate.to);
        break;
      default:
        throw new Error('There is not handled case');
    }
  }

  onSelectDate(date: NgbDate): void {
    let fromDate = this.control.controls.from.getRawValue();
    let toDate = this.control.controls.to.getRawValue();

    if (
      !toDate &&
      fromDate &&
      (date.equals(this.datetimePickerService.fromMomentToNgb(fromDate)) ||
        date.after(this.datetimePickerService.fromMomentToNgb(fromDate)))
    ) {
      this.control.controls.to.setValue(this.datetimePickerService.fromNgbToMoment(date));
      this.updateMaxDateValue(this.control.controls.from.getRawValue());
    } else {
      this.control.controls.from.setValue(this.datetimePickerService.fromNgbToMoment(date));
      this.control.controls.to.setValue(null);
      this.updateMaxDateValue(this.control.controls.from.getRawValue());
    }
  }

  /**
   * Реализация аналога dateLimit из datepicker'а uib-bootstrap
   *
   * @param fromDate
   */
  updateMaxDateValue(fromDate: moment.Moment | null): void {
    if (this.options?.dateLimit) {
      const limit = this.options.dateLimit;
      const unitOfLimit = Object.keys(limit)[0] as 'day' | 'month';
      const maxDate = fromDate!.clone().add(limit[unitOfLimit], unitOfLimit);
      const limitedMaxDate = maxDate.isAfter(this.datetimePickerService.fromNgbToMoment(this.datepickerConfig.maxDate))
        ? this.datepickerConfig.maxDate
        : this.datetimePickerService.fromMomentToNgb(maxDate);

      this.maxDateCache = limitedMaxDate;

      this.cdr.detectChanges();
    }
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

/**
 * Компонент для отображения цены по запросу
 */

@Component({
  selector: 'cq-custom-price-cap',
  templateUrl: './custom-price-cap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPriceCapComponent {
  /** Показывать ли компонент как flex элемент */
  @Input()
  set displayFlex(value: boolean) {
    if (value) {
      this.componentClass = 'd-flex justify-content-center';
    } else {
      this.componentClass = '';
    }
  }

  /** Атрибут class текущего компонента  */
  @HostBinding('class')
  componentClass: string = '';
}

<ng-container *transloco="let t; read: 'templateZeroComponent'">
  <div class="card m-0 cursor-pointer">
    <div class="card-header-wrap position-relative bg-light-primary">
      <div
        class="card-header position-absolute p-0 top-0 right-0 bottom-0 left-0"
        [style.background-image]="'url(assets/img/default/template-zero.png'"
      ></div>
    </div>

    <div class="card-body flex justify-space-between flex-column">
      <div
        class="template-card-name mb-0 mb-10"
        data-test="template-name"
      >
        {{ t('heading') }}
      </div>
    </div>
  </div>
</ng-container>

<h3 class="margin-bottom-20">{{ 'account.profile.title' | transloco }}</h3>
<form
  class="account-profile-form margin-bottom-40"
  novalidate
>
  <div class="form-group row">
    <label class="col-4 col-form-label">
      <span>{{ 'account.profile.profileForm.email' | transloco }}</span>
    </label>
    <div class="col-8">
      <input
        class="form-control-plaintext"
        readonly
        [value]="djangoUser.email"
      />
    </div>
  </div>
  <div
    class="form-group row"
    [cqShowError]="profileForm.controls.telephone"
  >
    <label class="col-4 col-form-label">
      <span>{{ 'account.profile.profileForm.telephoneInput.label' | transloco }}</span>
    </label>
    <div class="col-8">
      <input
        class="form-control"
        placeholder="{{ 'account.profile.profileForm.telephoneInput.placeholder' | transloco }}"
        [formControl]="profileForm.controls.telephone"
      />
    </div>
    <cq-validation-messages [control]="profileForm.controls.telephone">
      <cq-validation-message errorName="maxlength">
        {{ 'account.profile.profileForm.telephoneInput.errors.maxlength' | transloco: { maxLength: 50 } }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
  <div
    class="form-group row"
    [cqShowError]="profileForm.controls.skype"
  >
    <label class="col-4 col-form-label">
      <span>{{ 'account.profile.profileForm.skypeInput.label' | transloco }}</span>
    </label>
    <div class="col-8">
      <input
        class="form-control"
        placeholder="{{ 'account.profile.profileForm.skypeInput.placeholder' | transloco }}"
        [formControl]="profileForm.controls.skype"
      />
    </div>
    <cq-validation-messages [control]="profileForm.controls.skype">
      <cq-validation-message errorName="maxlength">
        {{ 'account.profile.profileForm.skypeInput.errors.maxlength' | transloco: { maxLength: 80 } }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
  <div
    *ngIf="featureModel.hasAccess(FEATURES.CHANGE_LANGUAGE)"
    class="form-group row"
    [cqShowError]="profileForm.controls.language"
  >
    <label class="col-4 col-form-label">
      <span>{{ 'account.profile.profileForm.languageSelect.label' | transloco }}</span>
    </label>
    <div class="col-8">
      <div class="position-relative">
        <ng-select
          [searchable]="false"
          [clearable]="false"
          [required]="true"
          [formControl]="profileForm.controls.language"
          [items]="availableLanguages"
        >
          <ng-template
            ng-label-tmp
            ng-option-tmp
            let-item="item"
          >
            <span>{{ 'general.languages.' + item | transloco }}</span>
          </ng-template>
        </ng-select>
        <cq-validation-messages [control]="profileForm.controls.language">
          <cq-validation-message errorName="required">
            {{ 'account.profile.profileForm.languageSelect.errors.required' | transloco }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
      <small
        *ngIf="profileForm.controls.language.getRawValue() !== LANGUAGE"
        class="form-text text-warning"
        >{{ 'account.profile.profileForm.languageSelect.help' | transloco }}</small
      >
    </div>
  </div>
  <div class="form-group row no-margin-bottom">
    <div class="offset-4 col-8">
      <button
        type="button"
        class="btn btn-lg btn-primary"
        (click)="updateAccount()"
      >
        {{ 'general.save' | transloco }}
      </button>
    </div>
  </div>
</form>
<h3 class="mb-15">{{ 'account.profile.dataOnAppsTitle' | transloco }}</h3>
<table class="table table-hover table-align-middle mb-30 bordered-bottom">
  <thead>
    <tr>
      <th>{{ 'account.profile.table.headings.appName' | transloco }}</th>
      <th>{{ 'account.profile.table.headings.appOrigin' | transloco }}</th>
      <th>{{ 'account.profile.table.headings.avatar' | transloco }}</th>
      <th>{{ 'account.profile.table.headings.name' | transloco }}</th>
      <th>{{ 'account.profile.table.headings.nameInternal' | transloco }}</th>
      <th>{{ 'account.profile.table.headings.emailFrom' | transloco }}</th>
      <th>{{ 'account.profile.table.headings.permissions' | transloco }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody class="text-break">
    <tr
      class="cursor-pointer"
      (click)="trackClickChangeAppSettings(); openChangeAppSettingsModal(app)"
      *ngFor="let app of sortedApps; trackBy: trackById"
    >
      <td>{{ app.name }}</td>
      <td>{{ app.origin }}</td>
      <td>
        <img
          class="user-avatar"
          [src]="djangoUser.prefs[app.id].avatar"
        />
      </td>
      <td>{{ djangoUser.prefs[app.id].name }}</td>
      <td>{{ djangoUser.prefs[app.id].name_internal }}</td>
      <td>
        <span *ngIf="djangoUser.prefs[app.id].email_from">{{
          djangoUser.prefs[app.id].email_from + app.settings.email_domain
        }}</span>
        <span *ngIf="!djangoUser.prefs[app.id].email_from">{{
          (djangoUser.prefs[app.id].name | transliterate) + app.settings.email_domain
        }}</span>
      </td>
      <td>
        <span *ngIf="djangoUser.prefs[app.id].permissions === 'operator'">{{
          'account.profile.permissions.operator' | transloco
        }}</span>
        <span *ngIf="djangoUser.prefs[app.id].permissions === 'admin'">{{
          'account.profile.permissions.admin' | transloco
        }}</span>
        <span *ngIf="djangoUser.prefs[app.id].permissions === 'superadmin'">{{
          'account.profile.permissions.superAdmin' | transloco
        }}</span>
      </td>
      <td class="shrink-by-content">
        <button
          type="button"
          class="btn btn-borderless-primary"
          (click)="$event.stopPropagation(); openChangeAppSettingsModal(app)"
        >
          <i class="btn-icon cqi-sm cqi-pencil"></i>
        </button>
        <button
          class="btn btn-borderless-primary"
          type="button"
          (click)="$event.stopPropagation(); removeSelfFromApp(app, false)"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
<cq-delete-django-user></cq-delete-django-user>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { WritePropertyValueModule } from '@panel/app/partials/track-master/write-property-value/write-property-value.module';

import { TextPropertiesComponent } from './text-properties.component';

@NgModule({
  declarations: [TextPropertiesComponent],
  imports: [CommonModule, TranslocoModule, WritePropertyValueModule, ReactiveFormsModule],
  exports: [TextPropertiesComponent],
})
export class TextPropertiesModule {}

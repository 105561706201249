import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationService } from '@http/integration/services/integration.service';
import { IntegrationTriggerMessagesModule } from '@panel/app/pages/integrations/partials/integration-trigger-messages/integration-trigger-messages.module';

import { AmocrmTriggerMessagesForLeadComponent } from './amocrm-trigger-messages-for-lead.component';

@NgModule({
  declarations: [AmocrmTriggerMessagesForLeadComponent],
  exports: [AmocrmTriggerMessagesForLeadComponent],
  imports: [CommonModule, IntegrationTriggerMessagesModule, NgbCollapseModule, NgbTooltipModule, TranslocoModule],
  providers: [IntegrationService],
})
export class AmocrmTriggerMessagesForLeadModule {}

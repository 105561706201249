import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { VkIntegrationExternal } from '@http/integration/integrations/vk/interfaces/vk-integration.interfaces';

/**
 * Настройка интеграции с ВКонтакте
 */

@Component({
  selector: 'cq-vk[currentAppId][integrationExternal]',
  templateUrl: './vk.component.html',
  styleUrls: ['./vk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentAppId!: string;

  /** Текущая или новая интеграция во внешнем формате */
  @Input()
  integrationExternal!: VkIntegrationExternal;

  /** Режим редактирования интеграции */
  isEdit!: boolean;

  /** Является ли интеграция старой (до перехода на OAuth авторизацию) */
  get isOldIntegration(): boolean {
    /*
     Интеграции старая, если у неё проставлен apiToken, это важно только в режиме редактирования,
     т.к. новые интеграции будут создаваться через OAuth
    */
    return this.isEdit && !!this.integrationExternal.settings.apiToken;
  }

  ngOnInit(): void {
    this.isEdit = !!this.integrationExternal.id;
  }
}

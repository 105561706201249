<ng-container *transloco="let t; read: 'botActionNextBranchSelectorComponent'">
  <div [cqShowError]="control">
    <label class="font-weight-normal small">{{ t('selector.label') }}</label>
    <div class="row">
      <div class="col-lg-10 padding-right-5">
        <ng-select
          class="ng-select-header-options"
          [clearable]="false"
          (change)="branchSelect.next($event)"
          [formControl]="control"
          [items]="nextBranchOptions | filterNextBranchOptions: canSelectConditionAsNextBranch"
          [searchable]="false"
          groupBy="blockType"
          [trackByFn]="branchSelectTrackFn"
          bindLabel="name.value"
          bindValue="linkId"
          appendTo="body"
          placeholder="{{ t('selector.placeholder') }}"
          [cqShowError]="control"
          [classList]="['ng-touched']"
        >
          <ng-container *ng-header-tmp>
            <button
              class="ng-select-header-option"
              (click)="addBranchClick.next('branch'); boBlockSelect.close()"
              type="button"
              data-test="action-button"
            >
              <div class="inline-flex align-items-center">
                <i class="cqi-sm cqi-comment-out-filled message-block-color mr-10"></i>
                <span>{{ t('selector.actions.block') }}</span>
              </div>
            </button>
            <button
              *ngIf="canSelectConditionAsNextBranch"
              class="ng-select-header-option"
              (click)="addBranchClick.next('condition'); trackAddConditionClick(); boBlockSelect.close()"
              type="button"
              data-test="action-button"
            >
              <div class="inline-flex align-items-center">
                <i class="cqi-sm cqi-split condition-block-color mr-10"></i>
                <span>{{ t('selector.actions.condition') }}</span>
              </div>
            </button>
            <button
              *ngIf="canSelectActionAsNextBranch"
              class="ng-select-header-option"
              (click)="addBranchClick.next('action'); trackAddActionClick(); boBlockSelect.close()"
              type="button"
              data-test="action-button"
            >
              <div class="inline-flex align-items-center">
                <i class="cqi-sm cqi-flash-filled action-block-color mr-10"></i>
                <span>{{ t('selector.actions.action') }}</span>
              </div>
            </button>
          </ng-container>

          <ng-template
            ng-optgroup-tmp
            let-item="item"
          >
            <div class="inline-flex align-items-center">
              <i
                class="cqi-sm"
                [ngClass]="getIconForBlockType(item.blockType)"
              ></i>
              <span class="ml-10">{{ t('selector.groups.' + item.blockType) }}</span>
            </div>
          </ng-template>
        </ng-select>

        <cq-validation-messages [control]="control">
          <cq-validation-message errorName="required">{{ t('selector.errors.required') }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <div class="col-lg-2 no-padding-left">
        <button
          class="btn btn-block btn-outline-primary"
          (click)="emitBranchSelect()"
          [disabled]="control.invalid"
          [ngbTooltip]="t('nextBranchButton.tooltip')"
          type="button"
          data-test="go-to-branch-button"
        >
          <i class="btn-icon cqi-sm cqi-arrow-from-top-to-right"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>

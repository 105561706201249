import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { AlbatoAuthTokenComponent } from './albato-auth-token.component';

@NgModule({
  declarations: [AlbatoAuthTokenComponent],
  exports: [AlbatoAuthTokenComponent],
  imports: [CommonModule, TranslocoModule, FormsModule],
  providers: [],
})
export class AlbatoAuthTokenModule {}

<ng-container *transloco="let t; read: 'timeSelectorComponent'">
  <form
    class="flex align-items-center"
    [formGroup]="control!"
  >
    <div
      class="mr-10"
      [cqShowError]="control.controls.time!"
    >
      <input
        class="form-control time"
        name="afterTimeValue"
        [formControlName]="'time'"
        cqNumber
      />

      <cq-validation-messages [control]="control.controls.time!">
        <cq-validation-message errorName="required">
          {{ t('timeInput.errors.required') }}
        </cq-validation-message>
        <cq-validation-message errorName="max">
          {{ maxSecondsError }}
        </cq-validation-message>
        <cq-validation-message errorName="min">
          {{ minSecondsError }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <div
      [cqShowError]="control.controls.unit!"
      class="flex-grow-1"
    >
      <ng-select
        [items]="selectableUnits"
        [clearable]="false"
        [formControlName]="'unit'"
      >
        <ng-template
          ng-label-tmp
          let-item="item"
        >
          {{ getPluralTimeUnitTranslate(item) }}
        </ng-template>
        <ng-template
          ng-option-tmp
          let-item="item"
        >
          {{ getPluralTimeUnitTranslate(item) }}
        </ng-template>
      </ng-select>

      <cq-validation-messages [control]="control.controls.unit!">
        <cq-validation-message errorName="required">
          {{ t('unitSelect.errors.required') }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </form>
</ng-container>

import { Injectable } from '@angular/core';
import { IPromise } from 'angular';

import { Billing } from '@http/billing/billing';
import { PaymentModalService } from '@panel/app/pages/subscription/general/services/payment-modal/payment-modal.service';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

/** Сервис для работы с картой для оплаты */
@Injectable({
  providedIn: 'root',
})
export class PaymentCardService {
  constructor(
    private readonly paymentModalService: PaymentModalService,
    private readonly subscriptionStore: SubscriptionStore,
  ) {}

  /** Привязки карты для оплаты */
  addCard(): IPromise<void> {
    const billing = this.subscriptionStore.billing$.getValue()!;
    const djangoUserEmail = this.subscriptionStore.djangoUser$.getValue()!.email;

    return billing.generateTmpInvoiceForAddCard().then((response) => {
      this.paymentModalService.openAddCardModal(response.amount, response.code, djangoUserEmail);
    });
  }

  /** Удаление привязанной карты для оплаты */
  deleteCard(): IPromise<Billing> {
    const billing = this.subscriptionStore.billing$.getValue()!;

    return billing.deleteCard();
  }

  /** Изменение карты для оплаты */
  editCard(): IPromise<void> {
    const billing = this.subscriptionStore.billing$.getValue()!;
    const djangoUserEmail = this.subscriptionStore.djangoUser$.getValue()!.email;

    return billing.generateTmpInvoiceForAddCard().then((response) => {
      this.paymentModalService.openEditCardModal(response.amount, response.code, djangoUserEmail);
    });
  }
}

import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { environment } from '@environment';
import { AmocrmMappingForm } from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import { AmocrmIntegrationFormData } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { App } from '@http/app/app.model';

/**
 * Компонент для работы с данными, которые передаются в контакты AmoCRM
 */

@Component({
  selector:
    'cq-amocrm-data-to-contacts[currentApp][eventTypeIdsControl][integrationId][userEventsTypesGroup][userPropsGroup]',
  templateUrl: './amocrm-data-to-contacts.component.html',
  styleUrls: ['./amocrm-data-to-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmDataToContactsComponent {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Контрол с ID событий, после срабатывания которых данные из Carrot quest передаются в контакт amoCRM */
  @Input()
  eventTypeIdsControl!: GenericFormControl<AmocrmIntegrationFormData['settings']['eventTypeIds']>;

  /** ID интеграции */
  @Input()
  integrationId!: string;

  /** Контрол с типами событий, которые передаются из лида Carrot quest в контакт amoCRM */
  @Input()
  userEventsTypesGroup!: GenericFormArray<AmocrmMappingForm>;

  /** Контрол со свойствами, которые передаются из лида Carrot quest в контакт amoCRM */
  @Input()
  userPropsGroup!: GenericFormArray<AmocrmMappingForm>;

  /** Название проекта */
  projectName = environment.projectName;

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(@Inject(IntegrationService) private readonly integrationService: IntegrationService) {}
}

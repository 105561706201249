/**
 * Директива для исправления бага с браузерным автозаполнением полей
 * https://github.com/angular/angular.js/issues/1460
 */
(function () {
  'use strict';

  angular.module('myApp.directives.autoFillSync').directive('cqAutoFillSync', cqAutoFillSync);

  function cqAutoFillSync($interval) {
    return {
      require: 'ngModel',
      scope: {
        cqAutoFillSync: '=',
      },
      link: link,
    };

    function link(scope, element, attrs, ngModel) {
      var origVal = element.val();
      var refresh = $interval(function () {
        var newVal = element.val();
        if (origVal !== newVal) {
          ngModel.$setViewValue(newVal);
        }
      }, 500);

      scope.$on('$destroy', onDestroy);

      /**
       * Отключение интервала
       */
      function onDestroy() {
        $interval.cancel(refresh);
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { TuiForModule } from '@taiga-ui/cdk';
import { UIRouterModule } from '@uirouter/angular';
import { NgObjectPipesModule } from 'ngx-pipes';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { ReadyAutoMessagesModalComponent } from '@panel/app/partials/modals/ready-auto-messages-modal/ready-auto-messages-modal.component';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ReadyAutoMessagesTemplateComponent } from './ready-auto-messages-template/ready-auto-messages-template.component';

@NgModule({
  declarations: [ReadyAutoMessagesModalComponent, ReadyAutoMessagesTemplateComponent],
  exports: [ReadyAutoMessagesModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgSelectModule,
    NgObjectPipesModule,
    ReactiveFormsModule,
    TuiForModule,
    SharedModule,
    UIRouterModule,
    OverlayscrollbarsModule,
  ],
  providers: [CarrotquestHelperProvider],
})
export class ReadyAutoMessagesModalModule {}

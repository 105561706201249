<ng-container *transloco="let t">
  <div
    *ngIf="!userLoading"
    class="card card-body mh-100 no-padding overflow-y-auto overflow-x-hidden new-conversations-user-card full-height d-flex flex-column flex-grow-1"
  >
    <div>
      <cq-user-main-props
        class="highlight"
        (openEditPropModal)="trackClickOpenEditPropModal()"
        (openUserCardFn)="openUserCard()"
        (updateProps)="updateProps()"
        [user]="user"
      ></cq-user-main-props>
      <hr class="full-width no-padding no-margin" />
    </div>
    <div class="custom-scroll overflow-y-auto overflow-x-hidden">
      <cq-user-location
        [currentApp]="currentApp"
        [pinnedProps]="pinnedProps"
        [user]="user"
        (changePinnedPropsOrder)="changePinnedPropsOrder($event)"
        (removePinnedProp)="removePinnedProp($event)"
        (updateProps)="updateProps()"
      ></cq-user-location>
      <hr class="full-width no-padding no-margin" />
      <cq-user-props
        [customProps]="user.propsCustom"
        [currentApp]="currentApp"
        (collapseStateChange)="toogleLSUncollapsedBlock($event)"
        [user]="user"
        [pinnedProps]="pinnedProps"
        (addPinnedProp)="addPinnedProp($event)"
        (updateProps)="updateProps()"
      ></cq-user-props>
      <hr class="full-width no-padding no-margin" />
      <cq-user-notes-list
        [userId]="user.id"
        [notesList]="user.notes"
        (collapseStateChange)="toogleLSUncollapsedBlock($event)"
      ></cq-user-notes-list>
      <hr class="full-width no-padding no-margin" />
      <cq-user-segments
        [userId]="user.id"
        [userSegments]="user.segments"
        (collapseStateChange)="toogleLSUncollapsedBlock($event)"
      ></cq-user-segments>
      <hr class="full-width no-padding no-margin" />
      <cq-user-tags
        [currentApp]="currentApp"
        [userTags]="user.tags"
        [userId]="user.id"
        (collapseStateChange)="toogleLSUncollapsedBlock($event)"
      ></cq-user-tags>
      <hr class="w-100 p-0 m-0" />
      <cq-user-subscriptions
        [currentApp]="currentApp"
        [djangoUser]="djangoUser"
        [user]="user"
        [telegramIntegrations]="telegramIntegrations"
        (collapseStateChange)="toogleLSUncollapsedBlock($event)"
        (updateProps)="updateProps()"
      ></cq-user-subscriptions>
      <hr class="w-100 p-0 m-0" />
      <ng-container *ngIf="consentSettingsEnabled()">
        <cq-user-consent
          [user]="user"
          (collapseStateChange)="toogleLSUncollapsedBlock($event)"
        ></cq-user-consent>
        <hr class="w-100 p-0 m-0" />
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="userLoading"
    class="card card-body position-relative h-100"
  >
    <cq-loader></cq-loader>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { EmojiSelectorModule } from '@panel/app/partials/emoji-selector/emoji-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ArticlesCategoryEditorModalComponent } from './articles-category-editor-modal.component';

@NgModule({
  declarations: [ArticlesCategoryEditorModalComponent],
  exports: [ArticlesCategoryEditorModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    SharedModule,
    NgbDropdownModule,
    EmojiSelectorModule,
    FormsModule,
  ],
})
export class ArticlesCategoryEditorModalModule {}

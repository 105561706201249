<ng-container *transloco="let t">
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="integration.form"
  >
    <cq-vk-forms-manual [isManualExpanded]="isManualExpanded"></cq-vk-forms-manual>

    <ng-container *ngFor="let form of formsArray.controls; let formIndex = index">
      <hr />
      <cq-vk-forms-form
        [currentApp]="currentApp"
        [form]="form"
        [formIndex]="formIndex"
        [properties]="properties"
        (removeForm)="removeForm(formIndex)"
        [showRemoveFormButton]="formsArray.controls.length > 1"
      ></cq-vk-forms-form>
    </ng-container>

    <hr />
    <button
      class="btn btn-outline-secondary"
      type="button"
      (click)="addForm()"
    >
      {{ t('vkFormsComponent.addFormButton') }}
    </button>

    <hr />
    <cq-vk-forms-api-token [control]="apiTokenControl"></cq-vk-forms-api-token>

    <hr />
    <cq-vk-forms-confirm-code [control]="confirmCodeControl"></cq-vk-forms-confirm-code>

    <hr />
    <cq-vk-forms-webhook-url [webhookUrl]="webhookUrl"></cq-vk-forms-webhook-url>

    <hr />
    <cq-integration-name-control [control]="nameControl"></cq-integration-name-control>
  </form>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Message } from '@http/message/message.types';

@Component({
  selector: 'cq-email-notification-message-triggers[messages]',
  templateUrl: './message-triggers.component.html',
  styleUrls: ['./message-triggers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationMessageTriggersComponent implements OnInit {
  @Input() messages: readonly Partial<Message>[] = [];

  isCollapsed!: boolean;

  constructor() {}

  ngOnInit(): void {
    this.isCollapsed = this.messages.length === 0;
  }
}

<ng-container *transloco="let t; read: 'cardComponent'">
  <div class="d-flex justify-content-between align-items-center">
    <div class="title font-weight-bold">{{ t('title') }}</div>
    <div class="flex-grow text-secondary text-truncate">
      <ng-container *ngIf="billing.cardLast4; else empty">**** **** **** {{ billing.cardLast4 }}</ng-container>
      <ng-template #empty>–</ng-template>
    </div>
    <div class="ml-20">
      <button
        *ngIf="!billing.cardLast4; else edit"
        class="btn btn-primary btn-sm"
        type="button"
        [disabled]="requestPerformed"
        (click)="onAddCard()"
      >
        {{ t('addCardButton') }}
      </button>
      <ng-template #edit>
        <div
          ngbDropdown
          placement="bottom-end"
          container="body"
        >
          <button
            class="btn btn-secondary btn-sm"
            type="button"
            ngbDropdownToggle
          >
            {{ t('editCardButtonDropdown.title') }}
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              type="button"
              (click)="onChangeCard()"
            >
              {{ t('editCardButtonDropdown.changeCardButton') }}
            </button>

            <button
              class="text-danger"
              ngbDropdownItem
              type="button"
              (click)="onRemoveCard()"
            >
              {{ t('editCardButtonDropdown.removeCardButton') }}
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

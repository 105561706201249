import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cq-knowledge-base-activate-wrapper',
  templateUrl: './knowledge-base-activate-wrapper.component.html',
  styleUrls: ['./knowledge-base-activate-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBaseActivateWrapperComponent {
  /** текущее приложение */
  @Input() currentApp!: any;
  /** Настройки БЗ */
  @Input() settings!: any;

  constructor() {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { LogoutComponent } from '@panel/app/pages/logout/logout.component';
import { LOGOUT_PAGE_ROUTES } from '@panel/app/pages/logout/logout.routes';

@NgModule({
  declarations: [LogoutComponent],
  imports: [CommonModule, TranslocoModule, UIRouterUpgradeModule.forChild({ states: LOGOUT_PAGE_ROUTES })],
})
export class LogoutModule {}

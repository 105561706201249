import { Injectable } from '@angular/core';
import { Text, TextStyle } from 'pixi.js';

import { PixiGeometryService } from '@panel/app/shared/services/pixi/geometry/pixi-geometry.service';
import {
  BOT_DEFAULT_ICON_BG_RADIUS,
  DEFAULT_FONT_SIZE,
} from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.constants';
import { TextStyleParameters } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.type';

@Injectable()
export class PixiIconRenderService {
  static renderIcon(iconCode: string, style?: TextStyleParameters) {
    const iconStyle = new TextStyle({
      fontFamily: 'CQ-Icons-sm',
      align: 'center',
      fontSize: style?.fontSize ?? DEFAULT_FONT_SIZE,
      ...style,
    });
    return new Text(iconCode, iconStyle);
  }

  static renderRounded(iconCode: string, bgColor: number, style?: TextStyleParameters) {
    const icon = this.renderIcon(iconCode, style);
    const iconCircle = PixiGeometryService.renderCircle(BOT_DEFAULT_ICON_BG_RADIUS, { backgroundColor: bgColor });
    iconCircle.addChild(icon);
    const backgroundsCenterX = (iconCircle.width - icon.width) / 2;
    const backgroundsCenterY = (iconCircle.height - icon.height) / 2;
    icon.position.set(backgroundsCenterX, backgroundsCenterY);
    return iconCircle;
  }
}

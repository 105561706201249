import { TranslocoService } from '@jsverse/transloco';

import { VK_FORMS_INTEGRATION_DEFAULT_FORM } from '@http/integration/integrations/vk-forms/constants/vk-forms-integration.constants';
import { VkFormsIntegrationForm } from '@http/integration/integrations/vk-forms/forms/vk-forms-integration.form';

import { INTEGRATION_TYPES } from '../../constants/integration.constants';
import { SettingsIntegration } from '../../integration';
import {
  VkFormsIntegrationExternal,
  VkFormsIntegrationSettingsExternal,
} from './interfaces/vk-forms-integration.interfaces';

export class VkFormsIntegration extends SettingsIntegration<VkFormsIntegrationSettingsExternal> {
  /** Форма настройки интеграции */
  form: VkFormsIntegrationForm;
  /** Тип интеграции */
  type = INTEGRATION_TYPES.VK_FORMS;
  /** Настройки интеграции */
  settings: VkFormsIntegrationSettingsExternal = {
    forms: [],
    apiToken: null,
    confirmCode: null,
    token: null,
  };

  constructor(translocoService: TranslocoService, integrationExternal?: VkFormsIntegrationExternal) {
    super(integrationExternal);

    // Если интеграция создается из внешнего формата, обновим settings, иначе задаем дефолтные значения
    if (integrationExternal) {
      this.updateSettings(integrationExternal.settings);
    } else {
      this.active = true;

      const integrationTypeName = translocoService.translate(`models.integration.types.${this.type}.name`);
      this.name = translocoService.translate('models.integration.defaultValues.name', { integrationTypeName });

      // Добавляем одну форму, чтобы пользователь не совершал лишних действий
      this.settings.forms.push(VK_FORMS_INTEGRATION_DEFAULT_FORM);
    }

    // Данные о настроенной интеграции собираются с формы и отправляются на backend
    this.form = new VkFormsIntegrationForm(this);
  }

  get externalFormat(): VkFormsIntegrationExternal {
    return {
      ...this.baseExternalFormat,
      ...this.form.value,
    };
  }
}

/**
 * Возможные позиции фона в блоке
 * NOTE: Не путать с POPUP_BACKGROUND_POSITION_TYPES, та константа используется в поп-апах
 */
export const POPUP_BLOCK_BACKGROUND_POSITION_TYPES = {
  CONTAIN: 'contain', // во весь блок без обрезания картинки (во весь блок)
  COVER: 'cover', // во весь блок с обрезание�� картинки (заполнить)
  REPEAT: 'repeat', // повторять
  STRETCH: 'stretch', // растянуть
};

/**
 * Типы фон��в в блоке
 * NOTE: Не путать с POPUP_BACKGROUND_TYPES, та к��нстанта используется в поп-апах
 */
export const POPUP_BLOCK_BACKGROUND_TYPES = {
  COLOR: 'color', // цвет
  IMAGE: 'image', // картинка
  TRANSPARENT: 'transparent', // прозрачный (без фона)
};

/**
 * Коды валют
 */
export const POPUP_BLOCK_CURRENCY_CODES = {
  AZN: 'AZN',
  CNY: 'CNY',
  EUR: 'EUR',
  GBP: 'GBP',
  JPY: 'JPY',
  KRW: 'KRW',
  KZT: 'KZT',
  RUB: 'RUB',
  UAH: 'UAH',
  USD: 'USD',
};

/**
 * Позиционирования валюты по их ко��у
 */
export const POPUP_BLOCK_CURRENCY_POSITIONS_BY_CODE = {
  [POPUP_BLOCK_CURRENCY_CODES.AZN]: 'left',
  [POPUP_BLOCK_CURRENCY_CODES.CNY]: 'left',
  [POPUP_BLOCK_CURRENCY_CODES.EUR]: 'left',
  [POPUP_BLOCK_CURRENCY_CODES.GBP]: 'left',
  [POPUP_BLOCK_CURRENCY_CODES.JPY]: 'left',
  [POPUP_BLOCK_CURRENCY_CODES.KRW]: 'left',
  [POPUP_BLOCK_CURRENCY_CODES.KZT]: 'right',
  [POPUP_BLOCK_CURRENCY_CODES.RUB]: 'right',
  [POPUP_BLOCK_CURRENCY_CODES.UAH]: 'right',
  [POPUP_BLOCK_CURRENCY_CODES.USD]: 'left',
};

/**
 * Символы валют по их коду
 */
export const POPUP_BLOCK_CURRENCY_SYMBOLS_BY_CODE = {
  [POPUP_BLOCK_CURRENCY_CODES.AZN]: '₼',
  [POPUP_BLOCK_CURRENCY_CODES.CNY]: '¥',
  [POPUP_BLOCK_CURRENCY_CODES.EUR]: '€',
  [POPUP_BLOCK_CURRENCY_CODES.GBP]: '£',
  [POPUP_BLOCK_CURRENCY_CODES.JPY]: '¥',
  [POPUP_BLOCK_CURRENCY_CODES.KRW]: '₩',
  [POPUP_BLOCK_CURRENCY_CODES.KZT]: '₸',
  [POPUP_BLOCK_CURRENCY_CODES.RUB]: '₽',
  [POPUP_BLOCK_CURRENCY_CODES.UAH]: '₴',
  [POPUP_BLOCK_CURRENCY_CODES.USD]: '$',
};

/**
 * Типы позиции валюты
 */
export const POPUP_BLOCK_CURRENCY_POSITION_TYPES = {
  LEFT: 'left', // Слева
  RIGHT: 'right', // Справа
};

/**
 * Типы блоков поп-апа
 */
export const POPUP_BLOCK_TYPES = {
  BUTTON: 'button', // кнопка
  CART: 'cart', // Товары в корзине
  FOOTER: 'footer', // футер
  HTML: 'html', // html-код
  IMAGE: 'image', // картинка
  INDENT: 'indent', // отступ
  INPUT_WITH_BIG_BUTTON: 'inputWithBigButton', // поле и большая кнопка
  INPUT_WITH_BUTTON: 'inputWithButton', // поле �� кнопкой
  TEXT: 'text', // текст
  VIDEO: 'video', // видео
};

export const POPUP_BLOCK_TYPES_ARRAY = [
  POPUP_BLOCK_TYPES.TEXT,
  POPUP_BLOCK_TYPES.IMAGE,
  POPUP_BLOCK_TYPES.BUTTON,
  POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON,
  POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON,
  POPUP_BLOCK_TYPES.INDENT,
  POPUP_BLOCK_TYPES.HTML,
  POPUP_BLOCK_TYPES.CART,
  POPUP_BLOCK_TYPES.VIDEO,
  POPUP_BLOCK_TYPES.FOOTER,
];

/**
 * Типы кнопок в блоке POPUP_BLOCK_TYPES.BUTTON
 */
export const POPUP_BUTTON_BLOCK_TYPES = {
  LINK: 'link', // ссылка
  POPUP_CLOSING: 'popupClosing', // закрытие поп-апа, без действия
  PUSH_SUBSCRIPTION: 'pushSubscription', // подписка на Web Push
};

/**
 * Regexp'ы для валидации ссылок на видео
 */
export const POPUP_VIDEO_BLOCK_REGEXP = {
  VIMEO:
    /^(?:https?:\/\/)?(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|)(\d+)(?:|\/\?)(#t=.*)?/,
  YOU_TUBE:
    /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu\.be))(?:\/(?:[\w\-]+\?v=|v\/)?)([\w\-]+)(\S+)?$/,
};

/**
 * Объекты с конструктором ссылок в формате библиотки URI.js
 */
export const POPUP_VIDEO_BLOCK_URL_PARTS = {
  VIMEO: {
    protocol: 'https',
    username: null,
    password: null,
    hostname: 'player.vimeo.com',
    urn: null,
    port: null,
    path: '/video',
    query: null,
    fragment: null,
  },
  YOU_TUBE: {
    protocol: 'https',
    username: null,
    password: null,
    hostname: 'www.youtube.com',
    urn: null,
    port: null,
    path: '/embed/',
    query: null,
    fragment: null,
  },
};

/**
 * Варианты источников видео в блоке POPUP_BLOCK_TYPES.VIDEO
 */
export enum POPUP_VIDEO_BLOCK_TYPES {
  VIMEO = 'VIMEO', // видео �� Vimeo
  YOU_TUBE = 'YOU_TUBE', // видео с YouTube
}

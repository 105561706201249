import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'cq-popover-template',
  templateUrl: './triggers-popover-template.component.html',
  styleUrls: ['./triggers-popover-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggersPopoverTemplateComponent {
  @Input()
  triggers: string[] = [];

  @ViewChild('templateRef')
  public readonly templateRef!: TemplateRef<any>;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { BranchActionContainerModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/container/container.module';
import { BranchActionNextModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/next/next.module';
import { FilterTargetActionOptionsPipe } from '@panel/app/pages/chat-bot/content/branch-editor/partials/block-branch-editor/filter-target-action-options.pipe';
import { OtherActionsDropdownModule } from '@panel/app/pages/chat-bot/content/branch-editor/partials/other-actions-dropdown/other-actions-dropdown.model';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BlockBranchEditorComponent } from './block-branch-editor.component';

@NgModule({
  declarations: [BlockBranchEditorComponent, FilterTargetActionOptionsPipe],
  imports: [
    BranchActionContainerModule,
    BranchActionNextModule,
    CommonModule,
    NgbCollapseModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgSelectModule,
    OtherActionsDropdownModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
    FormsModule,
  ],
  exports: [BlockBranchEditorComponent],
})
export class BlockBranchEditorModule {}

<div *ngIf="featureModel.hasAccess(FEATURES.AMOCRM_NOTIFICATION)">
  <div class="d-flex align-items-center py-15">
    <!-- Отображение ошибки, которая не позволяет настроить интеграции -->
    <cq-switch-wrapper
      *ngIf="abASide"
      [value]="false"
      [disabled]="true"
    ></cq-switch-wrapper>
    <cq-switch-wrapper
      *ngIf="!abASide"
      [(value)]="amocrmNotificationSwitch"
      (valueChange)="changeAmocrmNotificationSwitch($event)"
      [disabled]="disableNotificationSelector"
    ></cq-switch-wrapper>
    <div class="flex align-items-center white-space-nowrap ml-15">
      <h5
        class="d-inline-block font-weight-bold mb-0"
        [innerHTML]="
          'autoMessages.editor.amocrm.heading'
            | transloco: { integrationTypeName: ('models.integration.types.amocrm.name' | transloco) }
        "
      ></h5>
      <div
        *ngIf="abASide"
        class="position-relative"
      >
        <i class="cqi-sm cqi-circle-exclamation text-danger ml-10"></i>
        <div
          class="full-height full-width position-absolute top-0 cursor-pointer"
          [ngbPopover]="notificationIntegrationDisabledPopoverTemplate"
          container="body"
          popoverClass="notification-integration-disabled-popover"
        ></div>
      </div>
    </div>
  </div>

  <div
    *ngIf="amocrmNotificationSwitch"
    class="ml-50 mb-15"
  >
    <div
      class="text-secondary mb-15"
      [innerHTML]="
        'autoMessages.editor.amocrm.description'
          | transloco
            : {
                messageTypeGroup: messagePartTypeName,
                integrationTypeName: ('models.integration.types.amocrm.name' | transloco),
                projectName: PROJECT_NAME
              }
      "
    ></div>

    <div class="form-group flex no-margin-bottom">
      <ng-select
        class="flex-grow"
        [clearable]="false"
        [disabled]="disableNotificationSelector"
        [formControl]="control"
        [items]="amocrmIntegrations"
        [placeholder]="'autoMessages.editor.amocrm.integrationSelect.placeholder' | transloco"
        [searchable]="true"
        (change)="onAmocrmIntegrationSelect($event)"
        bindLabel="name"
        bindValue="id"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
        >
          <div
            class="d-flex align-items-center"
            [ngClass]="{ 'text-muted cursor-not-allowed': !item.active }"
          >
            <i
              *ngIf="!item.active"
              class="cqi-sm cqi-triangle-exclamation-o text-danger"
              [ngbTooltip]="'autoMessages.editor.amocrm.integrationSelect.disabledOptionTooltip' | transloco"
            ></i>
            <span [ngClass]="{ 'ml-10': !item.active }">{{ item.name }}</span>
          </div>
        </ng-template>
      </ng-select>

      <button
        *ngIf="control.getRawValue()"
        class="btn btn-outline-primary margin-left-10"
        type="button"
        (click)="openAmocrmLeadSettingsModal(false)"
        [ngbTooltip]="'autoMessages.editor.amocrm.editButton.tooltip' | transloco"
      >
        <i class="btn-icon cqi-sm cqi-cog"></i>
      </button>
      <button
        *ngIf="control.getRawValue()"
        class="btn btn-outline-primary margin-left-10"
        type="button"
        (click)="removeIntegration()"
      >
        <i class="btn-icon cqi-sm cqi-times"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #notificationIntegrationDisabledPopoverTemplate>
  <div>
    <div class="flex align-items-baseline margin-bottom-10">
      <span class="font-weight-bold">{{
        'autoMessages.editor.notificationUnavailablePopover.header' | transloco
      }}</span>
      <button
        class="btn btn-text-primary margin-left-auto"
        type="button"
        (click)="ngbPopover.close()"
      >
        <i class="btn-icon cqi-sm cqi-times"></i>
      </button>
    </div>
    <div class="margin-bottom-10">
      <span
        *ngIf="
          currentMessage.parts.length === 1 && abASide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.MESSAGE_HAS_NO_BUTTONS
        "
        >{{ 'autoMessages.editor.notificationUnavailablePopover.description.messageHasNoButtons' | transloco }}</span
      >
      <span
        *ngIf="
          currentMessage.parts.length === 1 && abASide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.INVALID_MESSAGE_TYPE
        "
        >{{ 'autoMessages.editor.notificationUnavailablePopover.description.invalidType' | transloco }}</span
      >
      <div *ngIf="currentMessage.parts.length === 2 && abASide === abBSide && abASide !== null">
        <span *ngIf="abASide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.MESSAGE_HAS_NO_BUTTONS">{{
          'autoMessages.editor.notificationUnavailablePopover.description.abBothNoButtons' | transloco
        }}</span>
        <span *ngIf="abASide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.INVALID_MESSAGE_TYPE">{{
          'autoMessages.editor.notificationUnavailablePopover.description.abBothInvalidType' | transloco
        }}</span>
      </div>
      <span
        *ngIf="
          currentMessage.parts.length === 2 &&
          abASide !== abBSide &&
          (abASide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.INVALID_MESSAGE_TYPE ||
            abBSide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.INVALID_MESSAGE_TYPE)
        "
      >
        {{
          'autoMessages.editor.notificationUnavailablePopover.description.abInvalidType'
            | transloco
              : { invalidSide: abASide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.INVALID_MESSAGE_TYPE ? 'A' : 'B' }
        }}
      </span>
      <span
        *ngIf="
          currentMessage.parts.length === 2 &&
          abASide !== abBSide &&
          (abASide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.MESSAGE_HAS_NO_BUTTONS ||
            abBSide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.MESSAGE_HAS_NO_BUTTONS)
        "
      >
        {{
          'autoMessages.editor.notificationUnavailablePopover.description.abMessageHasNoButtons'
            | transloco
              : { invalidSide: abASide === AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS.MESSAGE_HAS_NO_BUTTONS ? 'A' : 'B' }
        }}
      </span>
    </div>
    <button
      class="btn btn-primary full-width"
      (click)="wizardMoveStep.emit({ $stepNumber: 1 })"
      type="button"
    >
      <i class="btn-icon-left cqi-sm cqi-lightning"></i>
      <span>{{ 'autoMessages.editor.notificationUnavailablePopover.goToContentStepButton' | transloco }}</span>
    </button>
  </div>
</ng-template>

(function () {
  'use strict';

  require('./js-code.controller');

  angular.module('myApp.trackMaster').component('cqJsCode', cqJsCode());

  function cqJsCode() {
    return {
      bindings: {
        billingInfo: '=', // Информация по биллингу
        currentApp: '=', // Текущий App
        djangoUser: '=', // Джанго юзер
      },
      controller: 'JsCodeController',
      controllerAs: 'vm',
      template: require('./js-code.html'),
    };
  }
})();

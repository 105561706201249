(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupButtonBlockPreviewController', CqPopupButtonBlockPreviewController);

  function CqPopupButtonBlockPreviewController($q, $scope, $templateRequest, fileHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.parsedParams = null; // скорректированные параметры поп-апа
      vm.template = ''; // строка, содержащая html и css-код, необходимый для работы превью блока

      getTemplate();
    }

    /**
     * Получения темплейта для блока поп-апа
     */
    function getTemplate() {
      var templateRequests = [];

      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/general-style.scss'));
      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/button/button.scss'));
      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/button/button.html'));

      $q.all(templateRequests).then(getTemplatesSuccess);

      function getTemplatesSuccess(templates) {
        // NOTE: стили нужно обернуть в тег, т.к. итоговая строка должна быть валидным html
        vm.template = '<style>' + templates[0] + templates[1] + '</style>' + templates[2];
      }
    }
  }
})();

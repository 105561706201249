import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cq-canvas-options[isShow][x][y]',
  templateUrl: './canvas-options.component.html',
  styleUrls: ['./canvas-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanvasOptionsComponent {
  @Input() isShow!: boolean;
  @Input() x!: number;
  @Input() y!: number;

  public get translateStyle() {
    return `translate(calc(${this.x}px - 100%), calc(${this.y}px - 100%))`;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { TelegramOperationSelectComponent } from './telegram-operation-select.component';
import { TelegramOperationSelectWrapComponent } from './telegram-operation-select-wrap/telegram-operation-select-wrap.component';
import { TelegramValueSelectComponent } from './telegram-value-select/telegram-value-select.component';

@NgModule({
  declarations: [TelegramOperationSelectComponent, TelegramOperationSelectWrapComponent, TelegramValueSelectComponent],
  exports: [TelegramOperationSelectComponent, TelegramOperationSelectWrapComponent],
  imports: [
    CommonModule,
    TranslocoDirective,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgbTooltip,
    SharedModule,
  ],
})
export class TelegramOperationSelectModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BotAmocrmActionBodyJson } from '@http/chat-bot/actions';
import {
  ACTIONS_GROUPS,
  CHAT_BOT_ACTIONS_TYPES,
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP,
} from '@http/chat-bot/chat-bot.constants';
import { DefaultActionHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-action.helper';
import { DefaultBotHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-bot.helper';
import { DefaultBranchHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-branch.helper';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import {
  ApiChatBotActionTemplateRequest,
  ApiChatBotBodyTemplateRequest,
  ApiChatBotBranchTemplateRequest,
} from '@http/chat-bot-template/types/chat-bot-template-external.types';
import {
  ChatBotActionTemplate,
  ChatBotBranchTemplate,
  ChatBotTemplate,
} from '@http/chat-bot-template/types/chat-bot-template-internal.types';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { PropertyModel } from '@http/property/property.model';

/**
 * Сервис для работы с шаблонами чат-ботов
 */
@Injectable({ providedIn: 'root' })
export class ChatBotTemplateModel {
  constructor(
    private readonly http: HttpClient,
    private readonly defaultBotHelper: DefaultBotHelper,
    private readonly defaultBranchHelper: DefaultBranchHelper,
    private readonly propertyModel: PropertyModel,
  ) {}

  public get(templateId: string, appId: string): Observable<ChatBotTemplate> {
    const params = {
      app: appId,
      include_body: true,
    };

    return this.http.get<ChatBotTemplate>(`/chat_bots/basic_templates/${templateId}`, { params });
  }

  /**
   * Получение списка шаблонов
   */
  public getList(appId: string, botType: CHAT_BOT_TYPE): Observable<ChatBotTemplate[]> {
    const params = {
      app: appId,
      bot_types: botType,
      include_body: true,
    };

    return this.http.get<ChatBotTemplate[]>(`/chat_bots/basic_templates/`, { params });
  }

  /**
   * Собирает тело шаблона из бота
   */
  public getTemplateFromChatBot<T extends CHAT_BOT_TYPE>(chatBot: ChatBot<T>): ApiChatBotBodyTemplateRequest {
    if (!chatBot.interruptBranchLinkId || !chatBot.startBranchLinkId) {
      throw new Error('Has no interrupt or start branch linkId');
    }

    const bodyTemplate: ApiChatBotBodyTemplateRequest = {
      branches: [],
      allow_user_replies: chatBot.allowUserReplies,
      interrupt_branch_link_id: chatBot.interruptBranchLinkId,
      start_branch_link_id: chatBot.startBranchLinkId,
    };
    chatBot.branches.forEach((branch) => {
      if (!branch.coordinates) {
        throw new Error('Has no coordinates');
      }
      let templateActions: ApiChatBotActionTemplateRequest[] = [];
      branch.actions
        .filter((action) => {
          if (!this.isActionAllowedForTemplate(action)) {
            return false;
          }

          if (!this.isActionValidForTemplate(action)) {
            return false;
          }

          return true;
        })
        .forEach((action) => {
          let templateAction: ApiChatBotActionTemplateRequest = {
            active: action.active,
            type: action.type,
            body: action.body ?? '',
            body_json: action.bodyJson,
          };
          // Если отдавать эти значения на бек пустыми
          // он будет выдавать ошибку
          action.keyName && (templateAction.key_name = action.keyName);
          action.nextBranchLinkId && (templateAction.next_branch_link_id = action.nextBranchLinkId);

          templateActions.push(templateAction);
        });
      let templateBranch: ApiChatBotBranchTemplateRequest = {
        link_id: branch.linkId,
        name: branch.name,
        actions: templateActions,
        coordinates: branch.coordinates,
      };

      bodyTemplate.branches.push(templateBranch);
    });

    return bodyTemplate;
  }

  /**
   * Собирает чат-бота из шаблона
   * @param template - Шаблон бота
   * @param botType - Тип собираемого бота
   * @param botName - Взять имя бота НЕ из шаблона
   */
  public getChatBotFromTemplate<T extends CHAT_BOT_TYPE>(
    template: ChatBotTemplate,
    botType: T,
    botName?: string,
  ): ChatBot<T> {
    const chatBot = this.defaultBotHelper.getEmptyBot(botType);

    chatBot.name = botName ?? template.name;
    chatBot.allowUserReplies = template.body.allowUserReplies;
    chatBot.interruptBranchLinkId = template.body.interruptBranchLinkId;
    chatBot.startBranchLinkId = template.body.startBranchLinkId;

    template.body.branches.forEach((templateBranch) => {
      chatBot.branches.push(this.getBranchFromTemplateBranch(templateBranch));
    });

    return chatBot;
  }

  /**
   * Получение бранча бота из шаблона
   * @param templateBranch
   * @private
   */
  private getBranchFromTemplateBranch(templateBranch: ChatBotBranchTemplate): ChatBotBranch {
    const branch = this.defaultBranchHelper.getDefaultBranch(templateBranch.name, false, false);

    branch.linkId = templateBranch.linkId;
    branch.coordinates = templateBranch.coordinates;

    templateBranch.actions.forEach((templateAction) => {
      branch.actions.push(this.getActionFromTemplateAction(templateAction));
    });

    const hasTargetActions = branch.actions.some((action) => {
      return CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[ACTIONS_GROUPS.TARGET_SELECT].includes(action.type);
    });

    if (!hasTargetActions && !['condition', 'action'].includes(Branch.getBlockType(branch))) {
      branch.actions.push(DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.CLOSE));
    }

    return branch;
  }

  /**
   * Получение действие бота из шаблона
   * @param templateAction
   * @private
   */
  private getActionFromTemplateAction(templateAction: ChatBotActionTemplate): ChatBotAction {
    const action = DefaultActionHelper.getDefaultAction(templateAction.type);

    action.active = templateAction.active;
    action.type = templateAction.type;
    action.body = templateAction.body;
    action.bodyJson = templateAction.bodyJson;
    action.keyName = templateAction.keyName;
    action.nextBranchLinkId = templateAction.nextBranchLinkId;

    if (action.keyName) {
      action.prettyKeyName = this.propertyModel.parseUserPropertyName(action.keyName);
    }

    return action;
  }

  /**
   * Может ли действие быть в шаблоне
   *
   * @param action
   * @private
   */
  private isActionAllowedForTemplate(action: ChatBotAction): boolean | Error {
    if ([CHAT_BOT_ACTIONS_TYPES.FILE].includes(action.type)) {
      console.warn(`You can't add ${CHAT_BOT_ACTIONS_TYPES.FILE} types actions`);
      return false;
    }

    if ([CHAT_BOT_ACTIONS_TYPES.CLOSE].includes(action.type)) {
      return false;
    }

    return true;
  }

  /**
   * Является ли действием валидным для шаблона.
   *
   * @param action Действие в боте
   * @private
   */
  private isActionValidForTemplate(action: ChatBotAction): boolean | Error {
    if (
      [CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION].includes(action.type) &&
      !!(action as ChatBotAction<BotAmocrmActionBodyJson>).bodyJson.integration
    ) {
      console.warn(`${action.type} action should not contain the value`);
      return false;
    }

    if (
      [
        CHAT_BOT_ACTIONS_TYPES.CHANNEL,
        CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION,
        CHAT_BOT_ACTIONS_TYPES.OPERATOR,
        CHAT_BOT_ACTIONS_TYPES.USER_TAG,
      ].includes(action.type) &&
      !!action.body
    ) {
      console.warn(`${action.type} action should not contain the value`);
      return false;
    }

    if (
      [
        CHAT_BOT_ACTIONS_TYPES.EVENT,
        CHAT_BOT_ACTIONS_TYPES.PROPERTY,
        CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD,
        CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY,
      ].includes(action.type) &&
      !!action.keyName &&
      action.keyName[0] !== '$'
    ) {
      console.warn(`${action.type} action should not contain the value`);
      return false;
    }

    return true;
  }
}

/**
 * Компонент поповера сохранённых ответов
 */
(function () {
  'use strict';

  require('./saved-replies-popover.controller');
  require('./saved-replies-popover.html');

  angular.module('myApp.savedRepliesPopover').component('cqSavedRepliesPopover', cqSavedRepliesPopover());

  function cqSavedRepliesPopover() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        isDarkThemeActive: '=', // Активна ли темная тема
        onSelectSavedReply: '&', // Callback при клике на сохранённый ответ
        savedReplies: '<', // Список сохранённых ответов
      },
      controller: 'CqSavedRepliesPopoverController',
      controllerAs: 'vm',
      template: require('./saved-replies-popover.html'),
    };
  }
})();

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupInputWithButtonBlockPreviewController', CqPopupInputWithButtonBlockPreviewController);

  function CqPopupInputWithButtonBlockPreviewController($q, $scope, $templateRequest, $translate, l10nHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.parsedParams = null; // скорректированные параметры поп-апа
      vm.template = ''; // строка, содержащая html и css-код, необходимый для работы превью блока

      getTemplate();

      $scope.$watch('vm.params', parseParams, true);
    }

    /**
     * Получения темплейта для блока поп-апа
     */
    function getTemplate() {
      var templateRequests = [];

      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/general-style.scss'));
      templateRequests.push(
        $templateRequest('js/components/popup-editor/block-preview/input-with-button/input-with-button.scss'),
      );
      templateRequests.push(
        $templateRequest('js/components/popup-editor/block-preview/input-with-button/input-with-button.html'),
      );

      $q.all(templateRequests).then(getTemplatesSuccess);

      function getTemplatesSuccess(templates) {
        // NOTE: стили нужно обернуть в тег, т.к. итоговая строка должна быть валидным html
        vm.template = '<style>' + templates[0] + templates[1] + '</style>' + templates[2];
      }
    }

    /**
     * Парсинг исходных параметров блока
     *
     * @param {Object} params Параметры блока
     */
    function parseParams(params) {
      if (params) {
        vm.parsedParams = angular.copy(params);

        //Для email
        vm.parsedParams.emailConsentWithCheckboxText = $translate.instant(
          'popupInputWithButtonBlockPreview.emailConsent.withCheckbox',
          {
            confirmSubscriptionEmailUrl: vm.currentApp.settings.terms_of_email_storage,
            privacyPolicyUrl: vm.currentApp.settings.messenger_allow_cookies_policy,
          },
        );
        vm.parsedParams.emailConsentWithoutCheckboxText = $translate.instant(
          'popupInputWithButtonBlockPreview.emailConsent.withoutCheckbox',
          {
            confirmSubscriptionEmailUrl: vm.currentApp.settings.terms_of_email_storage,
            privacyPolicyUrl: vm.currentApp.settings.messenger_allow_cookies_policy,
          },
        );

        //Для телефона
        vm.parsedParams.phoneConsentWithCheckboxText = $translate.instant(
          'popupInputWithButtonBlockPreview.phoneConsent.withCheckbox',
          {
            confirmSubscriptionPhoneUrl: vm.currentApp.settings.terms_of_phone_storage,
            privacyPolicyUrl: vm.currentApp.settings.messenger_allow_cookies_policy,
          },
        );
        vm.parsedParams.phoneConsentWithoutCheckboxText = $translate.instant(
          'popupInputWithButtonBlockPreview.phoneConsent.withoutCheckbox',
          {
            confirmSubscriptionPhoneUrl: vm.currentApp.settings.terms_of_phone_storage,
            privacyPolicyUrl: vm.currentApp.settings.messenger_allow_cookies_policy,
          },
        );

        //Общая политика
        vm.parsedParams.privacyPolicyWithCheckboxText = $translate.instant(
          'popupInputWithButtonBlockPreview.privacyPolicyConsent.withCheckbox',
          {
            privacyPolicyUrl: vm.currentApp.settings.messenger_allow_cookies_policy,
          },
        );
        vm.parsedParams.privacyPolicyWithoutCheckboxText = $translate.instant(
          'popupInputWithButtonBlockPreview.privacyPolicyConsent.withoutCheckbox',
          {
            privacyPolicyUrl: vm.currentApp.settings.messenger_allow_cookies_policy,
          },
        );

        vm.parsedParams.privacyPolicyUrl = vm.currentApp.settings.messenger_allow_cookies_policy;
        vm.parsedParams.confirmSubscriptionEmailUrl = vm.currentApp.settings.terms_of_email_storage;
        vm.parsedParams.confirmSubscriptionPhoneUrl = vm.currentApp.settings.terms_of_phone_storage;

        vm.parsedParams.isConfirmSubscriptionEmail =
          vm.currentApp.settings.messenger_show_confirm_subscription &&
          vm.currentApp.settings.terms_of_email_storage.length > 0;

        vm.parsedParams.isConfirmSubscriptionPhone =
          vm.currentApp.settings.messenger_show_confirm_subscription &&
          vm.currentApp.settings.terms_of_phone_storage.length > 0;

        vm.parsedParams.isConfirmSubscriptionWithCheckbox =
          vm.currentApp.settings.messenger_confirm_subscription_with_checkbox;

        vm.parsedParams.isPrivacyPolicyAllowed = vm.currentApp.settings.messenger_show_confirm_subscription;

        vm.parsedParams.isOldSettingsForPrivacyPolicy =
          !vm.currentApp.settings.terms_of_email_storage &&
          !vm.currentApp.settings.terms_of_phone_storage &&
          vm.currentApp.settings.messenger_confirm_subscription_policy;

        vm.parsedParams.isRusCountry = l10nHelper.isRusCountry();
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'tagsFilterComponent'">
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    (openChange)="onToggleDropdown($event)"
  >
    <button
      class="btn btn-outline-primary"
      type="button"
      [disabled]="disabled"
      ngbDropdownToggle
      [ngbTooltip]="t('tooltip')"
      [autoClose]="false"
      container="body"
    >
      <i
        class="btn-icon cqi-sm"
        [ngClass]="filterButtonIconClass"
      ></i>
    </button>

    <div
      class="overflow-hidden"
      ngbDropdownMenu
    >
      <ng-container *ngIf="dropdown.isOpen()">
        <div class="dropdown-menu-inner">
          <div class="has-feedback has-feedback-left m-0 bordered-bottom">
            <input
              class="form-control form-control-lg"
              [autofocus]="dropdown.isOpen()"
              [formControl]="searchPhraseControl"
              [placeholder]="t('searchInput.placeholder')"
            />
            <span class="form-control-feedback">
              <i class="cqi-sm cqi-search"></i>
            </span>
          </div>
          <div class="custom-scroll overflow-y-auto">
            <div class="tags-container d-flex flex-wrap p-15">
              <ng-container *ngFor="let item of tagsFiltered$ | async; trackBy: trackByFn; empty: blank">
                <cq-tag-default
                  class="overflow-hidden"
                  [disabled]="withoutTagsControl.value"
                  [canActivate]="true"
                  [isActive]="isTagActive(item)"
                  [text]="item.tag"
                  (activate)="onToggleTag(item)"
                ></cq-tag-default>
              </ng-container>
            </div>
          </div>
          <div class="p-15 bordered-top">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                id="notIncludeTagsCheckbox"
                type="checkbox"
                [formControl]="withoutTagsControl"
              />
              <label
                class="custom-control-label"
                for="notIncludeTagsCheckbox"
                >{{ t('notIncludeTagsCheckbox') }}</label
              >
            </div>
          </div>
          <div class="p-15 bordered-top flex align-items-center justify-space-between">
            <button
              class="btn btn-secondary margin-right-10 flex-grow-1"
              type="button"
              (click)="onClickClearFilterButton()"
            >
              {{ t('clearFilterButton') }}
            </button>
            <button
              class="btn btn-primary flex-grow-1"
              type="button"
              (click)="onClickApplyFilterButton()"
            >
              {{ t('applyFilterButton') }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-template #blank>
    <ng-container *ngIf="(conversationsStoreService.tags$ | async)!.length === 0; else noResults">
      <h4>{{ t('zeroData.haveNoTags.title') }}</h4>
      <div>{{ t('zeroData.haveNoTags.description') }}</div>
    </ng-container>
    <ng-template #noResults>
      <h4 class="margin-auto text-muted">
        {{ t('zeroData.tagsNotFound') }}
      </h4>
    </ng-template>
  </ng-template>
</ng-container>

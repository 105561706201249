import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import cloneDeep from 'lodash-es/cloneDeep';
import { map, Observable } from 'rxjs';

import { PSEUDO_SEGMENT_IDS } from '@http/segment/segment.constants';
import { CaseStyleHelper } from '@panel/app/services';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({
  providedIn: 'root',
})
export class SegmentModel {
  /**
   * Дефолтный сегмент
   *
   * @type {Object}
   */
  DEFAULT_SEGMENT = {
    app: '', //TODO с бека приходит ID аппа, но это ни где не исползуется надо от этого зибавиться
    name: '',
    filters: {},
    id: '',
  };

  constructor(
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly filterAjsModel: FilterAjsModel,
    private readonly httpClient: HttpClient,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Создание сегмента
   */
  create(params: any, ignoreLoadingBar?: boolean): Observable<any> {
    let serverFormatParams = {
      app: params.app,
      filters: this.filterAjsModel.parseToServerFormat(params.filters),
      name: params.name,
    };

    return this.httpClient.post('/segments', serverFormatParams, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar ?? true),
    });
  }

  /**
   * Получение дефолтного сегмента
   */
  getDefault(): any {
    return cloneDeep(this.DEFAULT_SEGMENT);
  }

  /**
   * Получение С��гментов
   */
  getList(appId: any, includeAllUsersSegment?: boolean): Observable<any> {
    return this.httpClient
      .get(`/apps/${appId}/segments`, {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          this.caseStyleHelper.keysToCamelCase(response.data);

          let segments = [];

          for (let i = 0; i < response.data.segments.length; i++) {
            this.parse(response.data.segments[i]);
          }

          if (includeAllUsersSegment) {
            segments.push(this.getPseudoSegment(appId));
          }

          return segments.concat(response.data.segments);
        }),
      );
  }

  /**
   * Получение псевдос��гмента
   */
  getPseudoSegment(appId: any): any {
    let pseudoSegment = this.getDefault();

    pseudoSegment.app = appId;
    pseudoSegment.filters = this.filterAjsModel.getDefaultAnd();
    pseudoSegment.id = PSEUDO_SEGMENT_IDS.ALL;
    pseudoSegment.name = this.translocoService.translate('models.segments.pseudoSegment.all');

    return pseudoSegment;
  }

  /**
   * Парсинг сегмента
   */
  parse(segment: any): void {
    segment.filters = this.filterAjsModel.parse(segment.filters);
  }

  /**
   * Удаление сегмента
   */
  remove(segmentId: any, params?: any, ignoreLoadingBar?: boolean): Observable<any> {
    return this.httpClient.delete(`/segments/${segmentId}`, {
      context: new HttpContext().set(EXTENDED_RESPONSE, true).set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar ?? true),
    });
  }

  /**
   * Сохранение сегмента
   */
  save(segmentId: any, params: any, ignoreLoadingBar?: boolean): Observable<any> {
    let serverFormatParams = {
      app: params.app,
      filters: this.filterAjsModel.parseToServerFormat(params.filters),
      name: params.name,
    };

    return this.httpClient.put(`/segments/${segmentId}`, serverFormatParams, {
      context: new HttpContext().set(EXTENDED_RESPONSE, true).set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar ?? true),
    });
    // TODO тут беку надобы возвращать измененный сегмент
  }
}

/**
 * Помощник для работы с canvas
 */
(function () {
  'use strict';

  angular.module('myApp.services.canvasHelper').factory('canvasHelper', canvasHelper);

  function canvasHelper() {
    return {
      drawRoundedRect: drawRoundedRect,
      drawWrappedText: drawWrappedText,
      getWrappedTextHeight: getWrappedTextHeight,
    };

    /**
     * Рисование прямоугольника со скруглёнными углами
     * ВНИМАНИЕ! Эта функция не вызывает функции рисования fill() и stroke(), они должны вызываться руками после вызова функции, чтобы нарисовался прямоугольник
     *
     * @param {CanvasRenderingContext2D} context 2D Canvas контекст
     * @param {Number} x Точка отсчёта по оси X
     * @param {Number} y Точка отсчёта по оси Y
     * @param {Number} width Ширина прямоугольника
     * @param {Number} height Высота прямоугольника
     * @param {Number} borderRadius Радиус углов прямоугольника
     */
    function drawRoundedRect(context, x, y, width, height, borderRadius) {
      context.beginPath();
      context.moveTo(x + borderRadius, y);
      context.lineTo(x + width - borderRadius, y);
      context.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
      context.lineTo(x + width, y + height - borderRadius);
      context.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height);
      context.lineTo(x + borderRadius, y + height);
      context.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
      context.lineTo(x, y + borderRadius);
      context.quadraticCurveTo(x, y, x + borderRadius, y);
      context.closePath();
    }

    /**
     * Рисует текст, вписанный в прямоугольник по ширине
     *
     * @param {CanvasRenderingContext2D} context 2D Canvas контекст
     * @param {String|*} text Текст, который необходимо нарисовать
     * @param {Number} x Точка отсчёта по оси X
     * @param {Number} y Точка отсчёта по оси Y
     * @param {Number} maxWidth Максимально допустимая ширина текста (ширина прямоугольника)
     * @param {Number} lineHeight Высота каждой строки текста
     * @returns {Number} Высота получившегося текста
     */
    function drawWrappedText(context, text, x, y, maxWidth, lineHeight) {
      var words = text.split(' ');
      var countWords = words.length;
      var line = '';
      var height = 0;

      for (var n = 0; n < countWords; n++) {
        var testLine = line + words[n] + ' ';
        var testWidth = context.measureText(testLine).width;
        if (testWidth > maxWidth) {
          context.fillText(line, x, y);
          line = words[n] + ' ';
          y += lineHeight;
          height += lineHeight;
        } else {
          line = testLine;
        }
      }
      context.fillText(line, x, y);
      height += lineHeight;

      return height;
    }

    /**
     * Получение высоты текста, вписанного в прямоугольник
     *
     * @param {CanvasRenderingContext2D} context 2D Canvas контекст
     * @param {String|*} text Текст, у которого надо получить высоту
     * @param {Number} maxWidth Максимально допустимая ширина текста (ширина прямоугольника)
     * @param {Number} lineHeight Высота каждой линии текста
     * @returns {Number} Высота текста
     */
    function getWrappedTextHeight(context, text, maxWidth, lineHeight) {
      var words = text.split(' ');
      var countWords = words.length;
      var line = '';
      var height = 0;

      for (var n = 0; n < countWords; n++) {
        var testLine = line + words[n] + ' ';
        var testWidth = context.measureText(testLine).width;
        if (testWidth > maxWidth) {
          line = words[n] + ' ';
          height += lineHeight;
        } else {
          line = testLine;
        }
      }
      height += lineHeight;

      return height;
    }
  }
})();

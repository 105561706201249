/**
 * Директива для получения ссылки на файл при загрузке файла
 */
(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('cqFileAsDataUrl', cqFileAsDataUrl);

  function cqFileAsDataUrl($parse, fileHelper) {
    return {
      restrict: 'A',
      require: ['ngModel', 'cqFile'],
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var modelSetter = $parse(iAttrs.cqFileAsDataUrl).assign;
      var ngModelController = controller[0];

      scope.$watch(
        function () {
          return ngModelController.$viewValue;
        },
        function (newValue) {
          var url;

          if (newValue) {
            url = fileHelper.getObjectUrl(newValue);
            // NOTE: тут по-хорошему надо вызывать URL.revokeObjectURL, но это надо делать после того, как картинка отрендерилась в <img>, а тут доступа к элементу img нет... так что фиг так сделаешь
            // подробнее про revokeObjectURL читай тут: https://developer.mozilla.org/en-US/docs/Using_files_from_web_applications#Example_Using_object_URLs_to_display_images
          } else {
            url = '';
          }

          modelSetter(scope, url);
        },
      );
    }
  }
})();

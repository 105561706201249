import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';

import { SharedModule } from '@panel/app/shared/shared.module';

import { ArticlesListComponent } from './articles-list.component';

@NgModule({
  declarations: [ArticlesListComponent],
  exports: [ArticlesListComponent],
  imports: [CommonModule, TranslocoModule, NgbDropdownModule, FormsModule, UIRouterModule, MomentModule, SharedModule],
})
export class ArticlesListModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';

import { App } from '@http/app/app.model';
import { LeadBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { EMAIL_NOTIFICATION_STATUS } from '@http/integration/integrations/email-notification/constants/email-notification-integration.constants';
import { EmailNotificationIntegration } from '@http/integration/integrations/email-notification/email-notification-integration';
import {
  EmailNotificationIntegrationExternal,
  EmailNotificationIntegrationSettingsExternal,
} from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { EmailRecipient } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { IntegrationAutoMessage } from '@http/integration/types/integration.types';
import { Properties } from '@http/property/property.model';
import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';
import { StarterGuideModel } from '@http/starter-guide/starter-guide.model';
import { TeamMember } from '@http/team-member/team-member.types';
import { IntegrationNameControlCustomTranslations } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.component';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector:
    'cq-email-notification[autoMessages][currentApp][emailRecipients][integrationExternal][properties][teamMembers]',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationComponent implements OnInit {
  /** Триггерные сообщения  */
  @Input() autoMessages: IntegrationAutoMessage[] = [];
  /** Список чат-ботов, использующих интеграцию */
  @Input() chatBots: LeadBot[] = [];

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Все получатели уведомлений в App'е */
  @Input() emailRecipients!: EmailRecipient[];
  /** Текущая или новая интеграция во внешнем формате */
  @Input() integrationExternal!: EmailNotificationIntegrationExternal;
  /** Флаг показа компонента из модального окна создания интеграции */
  @Input() isShowedFromModal: boolean = false;
  /** Список свойств и событий пользователей */
  @Input() properties!: Properties;
  /** Список членов команды */
  @Input() teamMembers!: TeamMember[];
  /** Callback на создание интеграции */
  @Output() integrationCreated: EventEmitter<EmailNotificationIntegration> = new EventEmitter();
  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: EmailNotificationIntegration;
  /** Режим редактирования интеграции */
  isEdit!: boolean;

  integrationNameControlCustomTranslations: IntegrationNameControlCustomTranslations;

  /** Получение значения "Комментарий" из формы интеграции */
  get commentValue(): EmailNotificationIntegrationExternal['settings']['comment'] {
    return this.integration.form.get('settings').get('comment').value;
  }

  // @formatter:on

  /** Получение контрола с комментарием */
  get commentControl(): GenericFormControl<EmailNotificationIntegrationSettingsExternal['comment']> {
    return this.integration.form.controls.settings.controls.comment;
  }

  /** Получение значения "События" из формы интеграции */
  get eventTypeIdsValue(): EmailNotificationIntegrationExternal['settings']['eventTypeIds'] {
    return this.integration.form.get('settings').get('eventTypeIds').value;
  }

  /** Получение контрола с получателями */
  get emailRecipientsControl(): GenericFormControl<EmailNotificationIntegrationSettingsExternal['emailRecipients']> {
    return this.integration.form.controls.settings.controls.emailRecipients;
  }

  /** Получение контрола с событиями */
  get eventTypeIdsControl(): GenericFormControl<EmailNotificationIntegrationSettingsExternal['eventTypeIds']> {
    return this.integration.form.controls.settings.controls.eventTypeIds;
  }

  /** Получение значения "Свойства пользователя" из формы интеграции */
  get propsValue(): EmailNotificationIntegrationExternal['settings']['props'] {
    return this.integration.form.get('settings').get('props').value;
  }

  /** Получение контрола с названием */
  get nameControl(): GenericFormControl<EmailNotificationIntegrationExternal['name']> {
    return this.integration.form.controls.name;
  }

  /** Получение контрола с свойствами пользователя */
  get propsControl(): GenericFormControl<EmailNotificationIntegrationSettingsExternal['props']> {
    return this.integration.form.controls.settings.controls.props;
  }

  /** Получение значения "Тема письма" из формы интеграции */
  get subjectValue(): EmailNotificationIntegrationExternal['settings']['subject'] {
    return this.integration.form.get('settings').get('subject').value;
  }

  /** Получение контрола с темой письма */
  get subjectControl(): GenericFormControl<EmailNotificationIntegrationSettingsExternal['subject']> {
    return this.integration.form.controls.settings.controls.subject;
  }

  // @formatter:off
  constructor(
    private integrationFactory: IntegrationFactory,
    private integrationService: IntegrationService,
    private readonly starterGuideModel: StarterGuideModel,
    private toastService: ToastService,
    private translocoService: TranslocoService,
    private uiRouter: UIRouter,
  ) {
    this.integrationNameControlCustomTranslations = {
      heading: translocoService.translate('emailNotificationComponent.integrationNameControl.heading'),
      input: {
        placeholder: translocoService.translate('emailNotificationComponent.integrationNameControl.input.placeholder'),
        errors: {
          required: translocoService.translate(
            'emailNotificationComponent.integrationNameControl.input.errors.required',
          ),
        },
      },
    };
  }

  ngOnInit(): void {
    this.isEdit = !!this.integrationExternal.id;

    if (this.isEdit) {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.EMAIL_NOTIFICATION, this.integrationExternal);
    } else {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.EMAIL_NOTIFICATION);
    }
  }

  /** Создание интеграции */
  create(): void {
    this.integrationService.create(this.currentApp.id, this.integration).subscribe((integration) => {
      this.starterGuideModel.setStepIsMade(this.currentApp.id, STARTER_GUIDE_STEPS.LEADS_PASSING).subscribe();

      if (!this.isShowedFromModal) {
        this.goToConfiguredIntegration(integration);
      }

      if (this.getNotConfirmedEmailRecipientsCount() !== 0) {
        this.showNotConfirmedEmailRecipientsToast();
      } else {
        this.toastService.success(
          this.translocoService.translate('integrations.integration.toasts.created', {
            integrationTypeName: this.translocoService.translate(
              'models.integration.types.' + integration.type + '.name',
            ),
          }),
        );
      }

      this.integrationCreated.emit(integration);
    });
  }

  /** Получение количества неподтвержденных получателей уведомлений о лиде */
  getNotConfirmedEmailRecipientsCount(): number {
    let notConfirmedEmailRecipients: EmailRecipient[];

    notConfirmedEmailRecipients = this.emailRecipients.filter((emailRecipient: EmailRecipient) => {
      const formEmailRecipients = this.integration.form.controls.settings.controls.emailRecipients.value;

      return (
        formEmailRecipients.includes(emailRecipient.email) &&
        (emailRecipient.status === EMAIL_NOTIFICATION_STATUS.NOT_CONFIRMED || emailRecipient.status === null)
      );
    });

    return notConfirmedEmailRecipients.length;
  }

  /**
   * Перенаправление на настроенную интеграцию, после её создания
   *
   * @param integration - Созданная интеграция
   */
  goToConfiguredIntegration(integration: EmailNotificationIntegration) {
    const params = {
      integrationType: integration.type,
      integrationId: integration.id,
    };

    this.uiRouter.stateService.go(`app.content.integrations.details.configured.${integration.type}`, params, {
      location: 'replace', // Чтобы кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
    });
  }

  /** Отправка формы с настройками интеграции */
  onSubmit(): void {
    if (this.integration.form.valid) {
      this.isEdit ? this.save() : this.create();
    } else {
      this.integration.form.markAllAsTouched();
    }
  }

  /** Сохранение интеграции */
  save(): void {
    this.integrationService.save(this.currentApp.id, this.integration).subscribe((integration) => {
      if (this.getNotConfirmedEmailRecipientsCount() !== 0) {
        this.showNotConfirmedEmailRecipientsToast();
      } else {
        this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));
      }
    });
  }

  /** Показ тоста с призывом подтвердить получение email-оповещений */
  showNotConfirmedEmailRecipientsToast(): void {
    const text = this.translocoService.translate(`emailNotificationComponent.toasts.notConfirmedEmailRecipients`);

    this.toastService.secondary(text, '', {
      autohide: false,
      closeButton: true,
      closable: true,
    });
  }
}

import { Injectable } from '@angular/core';
import { camelCase, isObject, snakeCase } from 'lodash';

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : S;

type SnakeCase<S extends string> = S extends `${infer P1}${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${P2 extends Uppercase<P2> ? `_${Lowercase<P2>}` : P2}${SnakeCase<P3>}`
  : S;

export type CamelCaseKeys<T, U extends string[] = never[]> = {
  [K in keyof T as K extends U[number] ? K : CamelCase<K & string>]: K extends U[number]
    ? T[K]
    : T[K] extends Array<infer V>
    ? V extends Record<string, any>
      ? Array<CamelCaseKeys<V, U>>
      : T[K]
    : T[K] extends Record<string, any>
    ? CamelCaseKeys<T[K], U>
    : T[K];
};

type SnakeCaseKeys<T, U extends string[] = never[]> = {
  [K in keyof T as K extends U[number] ? K : SnakeCase<K & string>]: K extends U[number]
    ? T[K]
    : T[K] extends Array<infer V>
    ? V extends Record<string, any>
      ? Array<SnakeCaseKeys<V, U>>
      : T[K]
    : T[K] extends Record<string, any>
    ? SnakeCaseKeys<T[K], U>
    : T[K];
};

/**
 * Помощник для конвертирования строк, а так же ключей объектов в разные case styles
 */
@Injectable({ providedIn: 'root' })
export class GenericCaseStyleHelper {
  /**
   * Преобразует ключи объекта в CamelCase, за исключением ключей, указанных в списке исключений.
   * Преобразование выполняется рекурсивно для вложенных объектов и массивов объектов.
   *
   * @param obj - Объект, ключи которого нужно преобразовать.
   * @param except - Массив ключей, которые следует исключить из преобразования.
   * @returns Объект с преобразованными ключами.
   */
  keysToCamelCase<T extends Record<string, any>, U extends string[] = never[]>(obj: T, except?: U): CamelCaseKeys<T> {
    return this.transformKeys(obj, camelCase, except) as CamelCaseKeys<T>;
  }

  /**
   * Преобразует ключи объекта в snake_case, за исключением ключей, указанных в списке исключений.
   * Преобразование выполняется рекурсивно для вложенных объектов и массивов объектов.
   *
   * @param obj - Объект, ключи которого нужно преобразовать.
   * @param except - Массив ключей, которые следует исключить из преобразования.
   * @returns Объект с преобразованными ключами.
   */
  keysToUnderscore<T extends Record<string, any>, U extends string[]>(obj: T, except?: U): SnakeCaseKeys<T, U> {
    return this.transformKeys(obj, snakeCase, except) as SnakeCaseKeys<T, U>;
  }

  /**
   * Преобразует строку в CamelCase.
   *
   * @param {string} stringToBeConverted - Строка для преобразования.
   * @returns {string} Преобразованная строка.
   */
  toCamelCase(stringToBeConverted: string): string {
    return camelCase(stringToBeConverted);
  }

  /**
   * Преобразует строку в snake_case.
   *
   * @param {string} stringToBeConverted - Строка для преобразования.
   * @returns {string} Преобразованная строка.
   */
  toUnderscore(stringToBeConverted: string): string {
    return snakeCase(stringToBeConverted);
  }

  /**
   * Преобразует ключи объекта с помощью предоставленной функции преобразования.
   * Преобразование выполняется рекурсивно для вложенных объектов и массивов объектов.
   *
   * @param obj - Объект, ключи которого нужно преобразовать.
   * @param transform - Функция преобразования ключа.
   * @param except - Массив ключей, которые следует исключить из преобразования.
   * @returns {any} Объект с преобразованными ключами.
   */
  private transformKeys<T extends Record<string, any>>(
    obj: T,
    transform: (key: string) => string,
    except?: string[],
  ): any {
    for (let key in obj) {
      const value = obj[key];

      if (except && except.includes(key)) {
        this.convertKey(obj, key, transform);
        continue;
      }

      if (!obj.hasOwnProperty(key)) {
        continue;
      }

      if (typeof value === 'undefined') {
        continue;
      }

      /** Если значением является массив, то рекурсивно правим ключи всех объектов внутри */
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          if (isObject(value[i])) {
            this.transformKeys(value[i], transform, except);
          }
        }
      }

      /** Если значением является объект, то так же рекурсивно правим ключи */
      if (!Array.isArray(value) && typeof value === 'object') {
        obj[key] = this.transformKeys(value, transform, except);
      }

      this.convertKey(obj, key, transform);
    }

    return obj;
  }

  /**
   * Преобразует ключ объекта с помощью предоставленной функции преобразования.
   * Если преобразованный ключ отличается от исходного, заменяет исходный ключ на преобразованный в объекте.
   *
   * @param object - Объект, ключ которого нужно преобразовать.
   * @param key - Ключ, который нужно преобразовать.
   * @param transform - Функция преобразования ключа.
   */
  private convertKey(object: any, key: string, transform: (key: string) => string) {
    const convertedKey = transform(key);
    if (convertedKey !== key) {
      object[convertedKey] = object[key];
      delete object[key];
    }
  }
}

import { Injectable } from '@angular/core';
import Moment from 'moment/moment';

import { DATA_SOURCE, DATA_SOURCE_STATUS } from '@http/ai-data/ai-data.constants';
import { AiDataBotSettingsExternal, AiDataExternal } from '@http/ai-data/external-types';
import { AiBotAddressForm, AiContentKind, AiData, AiDataBotSettings } from '@http/ai-data/internal-types';
import {
  COMMUNICATION_STYLE,
  REPLIES_LENGTH,
} from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings.types';

@Injectable({ providedIn: 'root' })
export class AiDataMapper {
  constructor() {}

  parseAiBotSettingsToInternal(aiBotSettingsExternal: AiDataBotSettingsExternal): AiDataBotSettings {
    return {
      ...aiBotSettingsExternal,
      addressForm: aiBotSettingsExternal.addressForm ?? AiBotAddressForm.NEUTRAL,
      callHumanOperatorMessage: aiBotSettingsExternal.callHumanOperatorMessage ?? {
        work: '',
        notWork: '',
      },
      communicationStyle: aiBotSettingsExternal.communicationStyle ?? COMMUNICATION_STYLE.NEUTRAL,
      customRules: aiBotSettingsExternal.customRules ?? [],
      delayForClosing: aiBotSettingsExternal.delayForClosing ?? 15,
      gptTemperature: aiBotSettingsExternal.gptTemperature * 100,
      replyWords: this.parseReplyWordsToInternal(aiBotSettingsExternal.replyWords),
      stopPhrases: aiBotSettingsExternal.stopPhrases || [],
    };
  }

  parseAiBotSettingsToExternal(aiBotSettingsInternal: AiDataBotSettings): AiDataBotSettingsExternal {
    return {
      ...aiBotSettingsInternal,
      gptTemperature: aiBotSettingsInternal.gptTemperature / 100,
      replyWords: this.parseReplyWordsToExternal(aiBotSettingsInternal.replyWords),
      customRules: aiBotSettingsInternal.customRules.filter(Boolean) as string[],
      addressForm: +aiBotSettingsInternal.addressForm as 0 | 1 | 2,
    };
  }

  parseToInternal(aiDataExternal: AiDataExternal): AiData {
    return {
      ...aiDataExternal,
      removed: aiDataExternal.removed ? Moment(aiDataExternal.removed) : null,
      lastAiSync: Moment(aiDataExternal.lastAiSync),
      status: aiDataExternal.removed ? DATA_SOURCE_STATUS.STOPPED : DATA_SOURCE_STATUS.ACTIVE,
      checked: false,
    };
  }

  parseDataSourceToExternal(source: DATA_SOURCE) {
    switch (source) {
      case DATA_SOURCE.ALL:
        return null;
      case DATA_SOURCE.KNOWLEDGE_BASE:
        return AiContentKind.KNOWLEDGE_BASE_ARTICLE;
    }
  }

  parseDataStatusToExternal(status: DATA_SOURCE_STATUS) {
    switch (status) {
      case DATA_SOURCE_STATUS.ALL:
        return null;
      case DATA_SOURCE_STATUS.ACTIVE:
        return false;
      case DATA_SOURCE_STATUS.STOPPED:
        return true;
    }
  }

  private parseReplyWordsToExternal(replyWordsInternal: REPLIES_LENGTH): number {
    switch (replyWordsInternal) {
      case REPLIES_LENGTH.SHORT:
        return 35;
      case REPLIES_LENGTH.MEDIUM:
        return 70;
      case REPLIES_LENGTH.LONG:
        return 200;
    }
  }

  private parseReplyWordsToInternal(replyWordsExternal: number) {
    switch (replyWordsExternal) {
      case 35:
        return REPLIES_LENGTH.SHORT;
      case 75:
        return REPLIES_LENGTH.MEDIUM;
      case 200:
      default:
        return REPLIES_LENGTH.LONG;
    }
  }
}

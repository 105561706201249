<ng-container *transloco="let t; read: 'editUserPropertyModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      type="button"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="margin-bottom-15">
      {{ t('body', {parsedPropertyName}) }}
    </div>
    <form
      (ngSubmit)="saveProperty()"
      autocomplete="off"
      id="editPropertyForm"
      name="editPropertyForm"
    >
      <div
        class="form-group no-margin-bottom"
        [cqShowError]="propertyValueControl"
      >
        <input
          class="form-control"
          [formControl]="propertyValueControl"
          ngbAutofocus
          name="value"
          placeholder="{{ t('editPropertyForm.valueInput.placeholder') }}"
          required
        />
        <cq-validation-messages [control]="propertyValueControl">
          <cq-validation-message errorName="required">{{
            t('editPropertyForm.valueInput.errors.required')
          }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <input
        class="hidden"
        [disabled]="isApiRequestPerformed"
        id="editPropertyForm.submit"
        type="submit"
      />
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="activeModal.dismiss()"
      data-test="cancel-modal-btn"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <label
      class="btn btn-primary"
      [ngClass]="{ disabled: isApiRequestPerformed }"
      for="editPropertyForm.submit"
      data-test="save-modal-btn"
      >{{ 'general.save' | transloco }}</label
    >
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'amocrmPropsToLeadComponent'">
  <div
    class="d-flex align-items-center cursor-pointer"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
    (click)="collapse.toggle()"
  >
    <h4 class="no-margin">
      {{t('heading', {
      projectName,
      integrationTypeName
    })}}
    </h4>

    <div class="ml-auto">{{ countBunch }}</div>

    <i
      class="cqi-sm ml-10"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>

  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <!-- ПОЛЯ ЛИДА В СДЕЛКУ -->
    <div class="pt-20">
      <div class="mb-20">
        {{t('propToAmocrm.description', {
        projectName,
        integrationTypeName
      })}}
      </div>

      <div
        *ngIf="controlLeadProps.controls.length"
        class="d-flex align-items-center"
      >
        <p class="flex-grow no-flex-basis font-weight-bolder">{{t('propToAmocrm.prop.title', {projectName})}}</p>
        <div class="mx-10 invisible">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>
        <p class="flex-grow no-flex-basis font-weight-bolder">
          {{t('propToAmocrm.amocrm.title', {integrationTypeName})}}
        </p>
        <button
          class="btn btn-borderless-primary ml-10 invisible"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </div>

      <div
        class="d-flex align-items-center margin-bottom-15"
        *ngFor="let formGroup of controlLeadProps.controls; index as index"
      >
        <div
          class="flex-grow min-width-0 no-flex-basis"
          [cqShowError]="formGroup.controls.value"
        >
          <ng-select
            [clearable]="false"
            [formControl]="formGroup.controls.value"
            [items]="(amocrmState.userProps$ | async) ?? []"
            [placeholder]="t('propToAmocrm.prop.select.placeholder')"
            [required]="true"
            bindLabel="prettyName"
            bindValue="name"
            groupBy="group"
          >
            <ng-template
              let-item="item"
              ng-option-tmp
            >
              <span class="text-wrap">{{ item.prettyName }}</span>
            </ng-template>
          </ng-select>
          <cq-validation-messages [control]="formGroup.controls.value">
            <cq-validation-message errorName="required">
              {{ t('propToAmocrm.prop.select.errors.required') }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>

        <div class="mx-10">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>

        <div class="flex-grow min-width-0 no-flex-basis">
          <cq-amocrm-fields-select
            [fields]="
              (amocrmState.leadFields$ | async) ?? [] | duplicatesFields: formGroup.controls.key.value:chosenLeadFields
            "
            [formControl]="formGroup.controls.key"
            [integrationId]="integrationId"
            [type]="'lead'"
          ></cq-amocrm-fields-select>
        </div>

        <button
          class="btn btn-borderless-primary ml-10"
          (click)="removePropToAmocrmBunch(index)"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </div>

      <button
        class="btn btn-borderless-primary mt-5"
        type="button"
        (click)="addPropToAmocrmBunch()"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        {{ t('propToAmocrm.addPropButton') }}
      </button>
    </div>

    <!-- СОБЫТИЯ ЛИДА  В СДЕЛКУ -->
    <div class="pt-20">
      <div class="mb-20">
        {{t('eventTypeToAmocrm.description', {
        integrationTypeName,
        projectName
      })}}
      </div>

      <div
        *ngIf="controlLeadEventTypes.controls.length"
        class="d-flex align-items-center"
      >
        <p class="flex-grow no-flex-basis font-weight-bolder">
          {{t('eventTypeToAmocrm.eventType.title', {projectName})}}
        </p>
        <div class="mx-10 invisible">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>
        <p class="flex-grow no-flex-basis font-weight-bolder">
          {{t('eventTypeToAmocrm.amocrm.title', {integrationTypeName})}}
        </p>
        <button
          class="btn btn-borderless-primary ml-10 invisible"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </div>

      <div
        class="d-flex align-items-center margin-bottom-15"
        *ngFor="let formGroup of controlLeadEventTypes.controls; index as index"
      >
        <div
          class="flex-grow min-width-0 no-flex-basis"
          [cqShowError]="formGroup.controls.value"
        >
          <ng-select
            class="flex-grow"
            [clearable]="false"
            [formControl]="formGroup.controls.value"
            [items]="(amocrmState.eventTypeProps$ | async) ?? []"
            [placeholder]="t('eventTypeToAmocrm.eventType.select.placeholder')"
            [required]="true"
            bindLabel="prettyName"
            bindValue="name"
            groupBy="group"
          >
            <ng-template
              let-item="item"
              ng-option-tmp
            >
              <span class="text-wrap">{{ item.prettyName }}</span>
            </ng-template>
          </ng-select>
          <cq-validation-messages [control]="formGroup.controls.value">
            <cq-validation-message errorName="required">
              {{ t('eventTypeToAmocrm.eventType.select.errors.required') }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>

        <div class="mx-10">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>

        <div class="flex-grow min-width-0 no-flex-basis">
          <cq-amocrm-fields-select
            [fields]="
              (amocrmState.leadFields$ | async) ?? [] | duplicatesFields: formGroup.controls.key.value:chosenLeadFields
            "
            [formControl]="formGroup.controls.key"
            [integrationId]="integrationId"
            [type]="'lead'"
          ></cq-amocrm-fields-select>
        </div>

        <button
          class="btn btn-borderless-primary ml-10"
          (click)="removeEventTypeToAmocrmBunch(index)"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </div>

      <button
        class="btn btn-borderless-primary mt-5"
        type="button"
        (click)="addEventTypeToAmocrmBunch()"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        {{ t('eventTypeToAmocrm.addEventTypeButton') }}
      </button>
    </div>
  </div>
</ng-container>

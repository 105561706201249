import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';

import { StarterGuideLeadsCollectionComponent } from './leads-collection.component';

@NgModule({
  declarations: [StarterGuideLeadsCollectionComponent],
  exports: [StarterGuideLeadsCollectionComponent],
  imports: [CommonModule, TranslocoModule, NgbAlertModule, UIRouterModule, NgbTooltipModule],
  providers: [CarrotquestHelperProvider],
})
export class StarterGuideLeadsCollectionModule {}

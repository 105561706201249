(function () {
  'use strict';

  require('./popup-cart-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupCartBlockSettings', cqPopupCartBlockSettings());

  function cqPopupCartBlockSettings() {
    return {
      bindings: {
        popupBlockParams: '=', // настройки блока поп-апа
        eventTypes: '=', // события
        setErrorHandler: '&?', // функция для установки обработчика ошибок
      },
      controller: 'CqPopupCartBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-cart-block-settings.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChatWidgetListComponent } from '@panel/app/partials/chat-preview/chat-widget-list/chat-widget-list.component';

@NgModule({
  declarations: [ChatWidgetListComponent],
  imports: [CommonModule],
})
export class ChatWidgetListModule {}

import { Graphics } from 'pixi.js';

import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

import { BaseActionABS } from './base-action.abs';

/**
 * Дополнительный абстрактный класс, который подходит для всех actions,
 * отрисовка которых выполняется одним элементом (пока что все, кроме PropertyFieldAction)
 */
export abstract class SimpleActionABS<BodyJson extends object = EMPTY_BODY_JSON> extends BaseActionABS<BodyJson> {
  element: Graphics | null;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);

    this.element = this.render();
    if (this.element) {
      this.container.addChild(this.element);
    }
  }

  get height(): number {
    return this.element!.height;
  }

  redraw(): void {
    if (this.destroyed) {
      return;
    }
    if (isDefined(this.element)) {
      this.container.removeChildren();
      this.element.clear();
      this.element.removeChildren();
    }
    this.element = this.render();
    if (this.element) {
      this.container.addChild(this.element);
    }
  }

  abstract render(): Graphics | null;

  get renderReady(): boolean {
    return isDefined(this.element) && this.container.renderable;
  }

  get width(): number {
    return this.element!.width;
  }
}

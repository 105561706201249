<ng-container *transloco="let t; read: 'webhookPartEditorComponent'">
  <div class="row">
    <div class="col col-lg-4 bordered-right pt-25 pb-25">
      <!--Метод запроса-->
      <div class="form-group mb-25">
        <h4 class="mb-15">{{ t('requestMethod.label') }}</h4>
        <div class="btn-group btn-block btn-group-toggle mb-10">
          <label
            class="btn btn-outline-primary"
            *ngFor="let method of REQUEST_METHODS"
            [class.active]="methodForm.value === method"
          >
            <input
              [formControl]="methodForm"
              type="radio"
              [value]="method"
            />
            <span>{{ method | uppercase }}</span>
          </label>
        </div>
        <small class="text-secondary">{{ t('requestMethod.description.' + methodForm.value) }}</small>
      </div>

      <!--URL-адрес, куда отправлять запрос-->
      <div
        class="form-group mb-25"
        [cqShowError]="urlForm"
      >
        <h4 class="mb-15">
          {{ t('urlInput.label') }}
        </h4>
        <input
          [formControl]="urlForm"
          class="form-control"
          [placeholder]="t('urlInput.placeholder')"
        />
        <cq-validation-messages [control]="urlForm">
          <cq-validation-message errorName="required">
            {{ t('urlInput.errors.required') }}
          </cq-validation-message>
          <cq-validation-message errorName="maxlength">
            {{ t('urlInput.errors.maxlength', { maxLength: URL_MAX_LENGTH }) }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>

      <!--Записать событие при успешном запросе-->
      <div>
        <cq-checkbox-control
          [(ngModel)]="setEventType"
          (ngModelChange)="setEventTypeChanged($event)"
          class="mb-15"
        >
          {{ t('eventSelect.label') }}
          <i
            class="cqi-sm cqi-question-circle"
            [ngbTooltip]="t('eventSelect.labelTooltip')"
            placement="top"
          ></i>
        </cq-checkbox-control>

        <ng-container *ngIf="setEventType">
          <cq-event-type-select
            class="mb-15"
            [currentApp]="currentApp"
            [eventTypes]="eventTypes"
            (eventTypesChange)="eventTypesChange.next($event)"
            [formControl]="eventForm"
            [showHeader]="false"
            [createTrackMasterEvent]="false"
          ></cq-event-type-select>

          <cq-event-props-editor [formControl]="eventPropsForm"></cq-event-props-editor>
        </ng-container>
      </div>
    </div>
    <div class="col col-lg-8 p-0 pt-25 pb-25">
      <!--Тело запроса-->
      <div
        *ngIf="methodForm.value !== 'get'"
        class="form-group inner-col mb-25"
      >
        <ng-container
          *ngTemplateOutlet="headerWithCollapseBtn; context: { label: 'bodyRequest', collapseKey: 'body' }"
        ></ng-container>

        <div [(ngbCollapse)]="collapsedSettingsMap.body">
          <cq-request-body-editor [formControl]="bodyForm"></cq-request-body-editor>
        </div>
      </div>
      <hr *ngIf="methodForm.value !== 'get'" />

      <!--Query параметры-->
      <div class="form-group inner-col mb-25">
        <ng-container
          *ngTemplateOutlet="headerWithCollapseBtn; context: { label: 'queryParams', collapseKey: 'queryParams' }"
        ></ng-container>

        <div
          [(ngbCollapse)]="collapsedSettingsMap.queryParams"
          class="mb-10"
        >
          <cq-query-params-editor [formControl]="queryParamsForm"></cq-query-params-editor>
        </div>
      </div>
      <hr />

      <!--Заголовки запроса-->
      <div class="form-group inner-col mb-25">
        <ng-container
          *ngTemplateOutlet="headerWithCollapseBtn; context: { label: 'headersRequest', collapseKey: 'headers' }"
        ></ng-container>

        <div
          [(ngbCollapse)]="collapsedSettingsMap.headers"
          class="mb-10"
        >
          <cq-headers-editor [formControl]="headersForm"></cq-headers-editor>
        </div>
      </div>
      <hr />
    </div>
  </div>

  <ng-template
    #headerWithCollapseBtn
    let-label="label"
    let-collapseKey="collapseKey"
  >
    <div class="d-flex align-items-center justify-content-between mb-20">
      <h4 class="mb-0">
        <span>{{ t(label + '.label') }}</span>
        <i
          class="cqi-sm cqi-question-circle"
          [ngbTooltip]="t(label + '.infoTooltip')"
        ></i>
      </h4>
      <button
        (click)="toggleCollapse(collapseKey)"
        class="btn btn-outline-secondary"
        type="button"
      >
        <i
          class="btn-icon cqi-sm"
          [ngClass]="{
            'cqi-chevron-up': !collapsedSettingsMap[collapseKey],
            'cqi-chevron-down': collapsedSettingsMap[collapseKey]
          }"
        ></i>
        <span>{{ (collapsedSettingsMap[collapseKey] ? 'general.show' : 'general.hide') | transloco }}</span>
      </button>
    </div>
  </ng-template>
</ng-container>

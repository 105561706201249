import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Компонент для работы с контентом вопроса-ответа */
@Component({
  selector: 'cq-item-content',
  templateUrl: './item-content.component.html',
  styleUrls: ['./item-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemContentComponent {}

import {
  MESSAGE_PART_TYPE_ICON_CLASSES,
  MESSAGE_PART_TYPES,
  MESSAGE_PART_TYPES_ARRAY,
} from '../../../app/http/message-part/message-part.constants';
import { firstValueFrom } from 'rxjs';
import {
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE,
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE_LIST,
} from '../../../app/http/ready-message-template/ready-message-template.constants';

(function () {
  'use strict';

  angular
    .module('myApp.readyMessageTemplates')
    .controller('CqReadyMessageTemplatesController', CqReadyMessageTemplatesController);

  function CqReadyMessageTemplatesController(
    $scope,
    $filter,
    carrotquestHelper,
    l10nHelper,
    readyMessageTemplateModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.currentMessagePartType = MESSAGE_PART_TYPES.ALL; // Выбрыннй тип сообещния
      vm.currentScopeOfUse = READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY; // Выбранный тип сайта
      vm.FILTERED_MESSAGE_PART_TYPES = filterMessagePartTypesArray(MESSAGE_PART_TYPES_ARRAY);
      vm.getTemplates = getTemplates;
      vm.MESSAGE_PART_TYPE_ICON_CLASSES = MESSAGE_PART_TYPE_ICON_CLASSES;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.READY_MESSAGE_TEMPLATE_SCOPES_OF_USE_LIST = READY_MESSAGE_TEMPLATE_SCOPES_OF_USE_LIST;
      vm.templates = []; // список готовых шаблонов
      vm.templatesPaginator = null; // пагинация готовых шаблонов
      vm.trackChooseScopeOfUse = trackChooseScopeOfUse;

      $scope.$watchCollection('[vm.currentMessagePartType, vm.currentScopeOfUse]', filtersWatch);

      function filtersWatch(newValues) {
        getTemplates(newValues[0], newValues[1], true);
      }
    }

    /**
     * Фильтрация массива типов варианта сообщения (контрольная группа не нужна)
     *
     * @param {MESSAGE_PART_TYPES_ARRAY} messagePartTypesArray
     * @returns {MESSAGE_PART_TYPES_ARRAY}
     */
    function filterMessagePartTypesArray(messagePartTypesArray) {
      var filteredMessagePartTypesArray = angular.copy(messagePartTypesArray);

      for (var i = filteredMessagePartTypesArray.length - 1; i >= 0; i--) {
        var messagePartType = filteredMessagePartTypesArray[i];

        // @formatter:off
        if (
          ~[
            MESSAGE_PART_TYPES.JS,
            MESSAGE_PART_TYPES.PUSH,
            MESSAGE_PART_TYPES.WEBHOOK,
            MESSAGE_PART_TYPES.CONTROL_GROUP,
          ].indexOf(messagePartType)
        ) {
          // @formatter:on
          filteredMessagePartTypesArray.splice(i, 1);
        }

        // NOTE: пуши отключены для США
        if (~[MESSAGE_PART_TYPES.PUSH].indexOf(messagePartType) && l10nHelper.isUsCountry()) {
          filteredMessagePartTypesArray.splice(i, 1);
        }

        const userActivatedInSdk =
          Boolean(vm.currentApp.activation.installed_sdk_ios) ||
          Boolean(vm.currentApp.activation.installed_sdk_android);
        // NOTE: Доступ до SDK-типов только по фичагалке
        if (
          !!~[
            MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
            MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
            MESSAGE_PART_TYPES.SDK_PUSH,
          ].indexOf(messagePartType) &&
          !userActivatedInSdk
        ) {
          filteredMessagePartTypesArray.splice(i, 1);
        }

        // Нет шаблонов для Telegram
        if (messagePartType === MESSAGE_PART_TYPES.TELEGRAM) {
          filteredMessagePartTypesArray.splice(i, 1);
        }
      }

      return filteredMessagePartTypesArray;
    }

    /**
     * Обновление шаблонов
     *
     * @param {MESSAGE_PART_TYPES} messagePartType Тип вариантов сообщения
     * @param {Object} scopeOfUse Сфера использования
     * @param {Boolean=} forceReload=false Принудительная перезагрузка всего списка готовых шаблонов
     * @returns {Promise<T>}
     */
    function getTemplates(messagePartType, scopeOfUse, forceReload) {
      vm.onLoad();

      if (forceReload) {
        vm.templates = [];
        vm.templatesPaginator = null;
      }

      return firstValueFrom(
        readyMessageTemplateModel.getList(vm.currentApp.id, messagePartType, scopeOfUse, true, vm.templatesPaginator),
      )
        .then(updateTemplatesSuccess)
        .finally(updateTemplatesFinally);

      function updateTemplatesSuccess(data) {
        vm.templates.push.apply(vm.templates, data.templates);
        vm.templatesPaginator = data.paginatorParams;
      }

      function updateTemplatesFinally() {
        vm.onLoad();
      }
    }

    /**
     * Трек фильтра выбора типа сайта
     *
     * @param {String} scopeOfUse - Тип сайта
     */
    function trackChooseScopeOfUse(scopeOfUse) {
      carrotquestHelper.track('Шаблоны - клик на тип сайта', {
        App: vm.currentApp.name,
        Тип: scopeOfUse,
      });
    }
  }
})();

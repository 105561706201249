<div class="row margin-bottom-30">
  <div class="col-6 bordered-right">
    <form novalidate>
      <div
        class="form-group"
        [cqShowError]="appForm.controls.appUrl"
      >
        <label>{{ 'modals.addApp.body.step2.appUrlInput.label' | transloco }}</label>
        <input
          class="form-control"
          required
          [formControl]="appForm.controls.appUrl"
        />
        <cq-validation-messages [control]="appForm.controls.appUrl">
          <cq-validation-message errorName="required">
            {{ 'modals.addApp.body.step2.appUrlInput.errors.required' | transloco }}
          </cq-validation-message>
          <cq-validation-message errorName="maxlength">
            {{ 'modals.addApp.body.step2.appUrlInput.errors.maxlength' | transloco: { maxLength: APP_URL_MAX_LENGTH } }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
      <div
        class="form-group no-margin-bottom"
        [cqShowError]="appForm.controls.companyName"
      >
        <label>{{ 'modals.addApp.body.step2.companyNameInput.label' | transloco }}</label>
        <input
          class="form-control"
          required
          [formControl]="appForm.controls.companyName"
        />
        <cq-validation-messages [control]="appForm.controls.companyName">
          <cq-validation-message errorName="required">
            {{ 'modals.addApp.body.step2.companyNameInput.errors.required' | transloco }}
          </cq-validation-message>
          <cq-validation-message errorName="maxlength">
            {{
              'modals.addApp.body.step2.companyNameInput.errors.maxlength'
                | transloco: { maxLength: COMPANY_NAME_MAX_LENGTH }
            }}
          </cq-validation-message>
          <cq-validation-message errorName="companyNameExist">
            {{ 'modals.addApp.body.step2.companyNameInput.errors.companyNameExist' | transloco }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
    </form>
  </div>
  <div class="col-6 margin-between-rows-10">
    <h4>{{ 'modals.addApp.body.step2.daysFree' | transloco }}</h4>
    <div>{{ 'modals.addApp.body.step2.writeAddress' | transloco }}</div>
    <div>{{ 'modals.addApp.body.step2.companyName' | transloco }}</div>
  </div>
</div>
<div class="text-center">
  <button
    type="button"
    class="btn btn-lg btn-primary margin-bottom-10"
    (click)="createApp(appForm.valid)"
    [disabled]="isApiRequestPerformed || appForm.invalid"
  >
    {{ 'modals.addApp.body.step2.createAppButton' | transloco }}
  </button>
</div>

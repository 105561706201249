(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqBitrix24OAuthController', CqBitrix24OAuthController);

  function CqBitrix24OAuthController($stateParams, $window, integrationModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      const { appId, code, csrfToken, error, integrationId } = $stateParams;

      if (carrotquest && carrotquest.messenger) {
        carrotquest.messenger.toStateNo();
      }
      if (error) {
        $window.opener.postMessage(
          {
            status: 'error',
          },
          '*',
        );
      } else if (code) {
        authorize(appId, integrationId, code, csrfToken);
      }
    }

    /**
     * Авторизация в Facebook
     *
     * @param appId
     * @param integrationId
     * @param code
     * @param csrfToken
     */
    function authorize(appId, integrationId, code, csrfToken) {
      integrationModel.bitrix24
        .authorize(integrationId, appId, code, csrfToken)
        .then(authorizeSuccess)
        .catch(authorizeError);

      function authorizeSuccess() {
        $window.opener.postMessage(
          {
            status: 'accepted',
          },
          '*',
        );
      }

      function authorizeError() {
        $window.opener.postMessage(
          {
            status: 'error',
          },
          '*',
        );
      }
    }
  }
})();

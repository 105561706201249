/**
 * Контроллер Dashboard
 */
(function () {
  'use strict';

  angular.module('myApp.dashboard').controller('CqDomainWarmingUpController', CqDomainWarmingUpController);

  function CqDomainWarmingUpController(PROJECT_NAME) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.PROJECT_NAME = PROJECT_NAME;
    }
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { InputListForm } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-list/input-list.types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

@Component({
  selector: 'cq-input-list',
  templateUrl: './input-list.component.html',
  styleUrls: ['./input-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputListComponent extends AbsCVAFormGroupBasedComponent<InputListForm> {
  MIN_LENGTH = 3;

  readonly control: FormGroup<InputListForm> = this.fb.group<InputListForm>({
    value: this.fb.control(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(this.MIN_LENGTH)],
    }),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}

/**
 * Директива, используемая для установки любых кастомных правил валидации по булевым значениям
 */
(function () {
  'use strict';

  angular.module('myApp.directives.dynamicValidation').directive('cqDynamicValidation', cqDynamicValidation);

  function cqDynamicValidation() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller, transcludeFn) {
      parseAttr();
      scope.$watch(iAttrs.cqDynamicValidation, watch);

      /**
       * Функция парсинга атрибута cq-dynamic-validation
       * В этом атрибуте должен содержаться объект вида {validationKey: validationValue [, validationKey2: validationValue2]} (angular expression)
       * Для каждой пары ключ: значение этого объекта будет создан парсер, который будет убирать правило серверной валидации при любом изменении значения модели из представления
       * Это нужно для того, чтобы пользователь при изменении данных мог совершить повторную отправку данных формы на сервер
       * Если этого не делать, то пользователь не сможет отправить новые введённые данные на сервер, т.к. в каждой функции отправки формы проверяется валидность формы
       */
      function parseAttr() {
        var evaluatedValue = scope.$eval(iAttrs['cqDynamicValidation']);

        if (evaluatedValue.constructor != Object) {
          throw Error(
            'cq-dynamic-vlidation attribute must be an Object, ' + evaluatedValue.constructor.name + ' given',
          );
        }

        angular.forEach(evaluatedValue, function (value, validationKey) {
          // при любом изменении значения из представления серверная валидация должна быть снята
          controller.$parsers.push(function (viewValue) {
            controller.$setValidity(validationKey, true);
            return viewValue;
          });
        });
      }

      /**
       * Функция, выполняемая при изменении angular expression в атрибуте (фактически меняться будет только validationValue)
       * Если в объекте вида {validationKey: validationValue} значение validationValue истинно - поле ввода будет считаться невалидным с ключом validationKey
       *
       * @param newValue
       * @param oldValue
       */
      function watch(newValue, oldValue) {
        angular.forEach(newValue, function (value, key) {
          controller.$setValidity(key, !value);

          /*if (oldValue[key] != value) {
            controller.$setValidity(key, !value);
          }*/
        });
      }
    }
  }
})();

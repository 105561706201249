import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { environment } from '@environment';
import { AiDataModel } from '@http/ai-data/ai-data.model';
import { AiDataBotSettings } from '@http/ai-data/internal-types';
import { App } from '@http/app/app.model';
import { Properties } from '@http/property/property.model';
import { UserTag } from '@http/user/types/user.type';
import { AiBotSettingsForm } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings.types';
import { AiDataBotSettingsStateService } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings-state.service';
import { BOT_RULE_MAX_LENGTH } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/bot-rules-control/bot-rule-control.component';
import { COMPANY_INFO_MAX_LENGTH } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/company-info-control/company-info-control.component';
import {
  STOP_PHRASES_MAX_COUNT,
  wordCountValidator,
} from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/stop-phrases-control/stop-phrases-control.component';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { OneTimeFlagService } from '@panel/app/services';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-ai-data-bot-settings',
  templateUrl: './ai-data-bot-settings.component.html',
  styleUrls: ['./ai-data-bot-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...formSubmitTokenProviders],
})
export class AiDataBotSettingsComponent implements OnInit {
  @Input({ required: true })
  aiDataBotSettings!: AiDataBotSettings;

  @Input({ required: true })
  currentApp!: App;

  @Input({ required: true })
  properties!: Properties;

  @Input({ required: true })
  tags!: UserTag[];

  isApiRequestPerformed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isContentOfDialogsCollapsed: boolean = true;
  isSwitchingDialogsCollapsed: boolean = true;
  isBotRulesCollapsed: boolean = true;
  isOtherSettingsCollapsed: boolean = true;

  settingsForm!: FormGroup<AiBotSettingsForm>;

  isRuLanguage: boolean = environment.language === 'ru';

  constructor(
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSubject: Subject<void>,
    private readonly aiDataBotSettingsStateService: AiDataBotSettingsStateService,
    private readonly aiDataModel: AiDataModel,
    private readonly oneTimeFlagHelper: OneTimeFlagService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.settingsForm = new FormGroup<AiBotSettingsForm>({
      communicationStyle: new FormControl(this.aiDataBotSettings.communicationStyle, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      addressForm: new FormControl(this.aiDataBotSettings.addressForm, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      repliesLength: new FormControl(this.aiDataBotSettings.replyWords, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      creativityLevel: new FormControl(this.aiDataBotSettings.gptTemperature, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      stopPhrases: new FormControl(this.aiDataBotSettings.stopPhrases, {
        nonNullable: true,
        validators: [wordCountValidator(STOP_PHRASES_MAX_COUNT)],
      }),
      workingTimeReply: new FormControl(this.aiDataBotSettings.callHumanOperatorMessage.work, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      nonWorkingTimeReply: new FormControl(this.aiDataBotSettings.callHumanOperatorMessage.notWork, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      closeTimeout: new FormControl(this.aiDataBotSettings.delayForClosing, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      userTag: new FormControl(this.aiDataBotSettings.userTag, { nonNullable: false }),
      userEvent: new FormControl(this.aiDataBotSettings.userEvent, { nonNullable: false }),
      botRules: this.parseBotRules(this.aiDataBotSettings.customRules),
      companyName: new FormControl(this.aiDataBotSettings.serviceName || this.currentApp.name, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      companyInfo: new FormControl(this.aiDataBotSettings.serviceDescription, {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(COMPANY_INFO_MAX_LENGTH)],
      }),
    });

    this.aiDataBotSettingsStateService.properties$.next(this.properties);
    this.aiDataBotSettingsStateService.tags$.next(this.tags);
  }

  addBotRule() {
    this.settingsForm.controls.botRules.push(new FormControl<string>('', { nonNullable: true }));
  }

  removeBotRule(index: number) {
    this.settingsForm.controls.botRules.controls.splice(index, 1);
  }

  saveSettings(): void {
    if (!this.settingsForm.valid) {
      this.formSubmitSubject.next();
      this.openInvalidCollapses();
      return;
    }

    this.isApiRequestPerformed$.next(true);

    const params: AiDataBotSettings = {
      addressForm: this.settingsForm.value.addressForm!,
      callHumanOperatorMessage: {
        work: this.settingsForm.value.workingTimeReply!,
        notWork: this.settingsForm.value.nonWorkingTimeReply!,
      },
      communicationStyle: this.settingsForm.value.communicationStyle!,
      customRules: this.settingsForm.value.botRules!.filter(Boolean),
      delayForClosing: this.settingsForm.value.closeTimeout!,
      gptTemperature: this.settingsForm.value.creativityLevel!,
      integration: this.aiDataBotSettings.integration,
      replyWords: this.settingsForm.value.repliesLength!,
      serviceDescription: this.settingsForm.value.companyInfo!,
      serviceName: this.settingsForm.value.companyName!,
      stopPhrases: this.settingsForm.value.stopPhrases!,
      userEvent: this.settingsForm.value.userEvent!,
      userTag: this.settingsForm.value.userTag!,
    };

    this.aiDataModel
      .saveSettings(params)
      .pipe(
        take(1),
        finalize(() => this.isApiRequestPerformed$.next(false)),
      )
      .subscribe(() => {
        this.oneTimeFlagHelper.set(ONE_TIME_FLAGS.SHOW_AI_DATA_SETTINGS_BANNER);
        this.toastService.success(this.translocoService.translate('aiDataBotSettingsComponent.saveSuccessToast'));
      });
  }

  private openInvalidCollapses() {
    if (this.settingsForm.controls.companyName.invalid || this.settingsForm.controls.companyInfo.invalid) {
      this.isContentOfDialogsCollapsed = false;
    }

    if (
      this.settingsForm.controls.stopPhrases.invalid ||
      this.settingsForm.controls.workingTimeReply.invalid ||
      this.settingsForm.controls.nonWorkingTimeReply.invalid
    ) {
      this.isSwitchingDialogsCollapsed = false;
    }

    if (this.settingsForm.controls.botRules.invalid) {
      this.isBotRulesCollapsed = false;
    }

    if (this.settingsForm.controls.closeTimeout.invalid) {
      this.isOtherSettingsCollapsed = false;
    }
  }

  private parseBotRules(botRules: (string | null)[]) {
    const formControlsArray = botRules.map(
      (rule) =>
        new FormControl<string | null>(rule, {
          nonNullable: true,
          validators: [Validators.maxLength(BOT_RULE_MAX_LENGTH)],
        }),
    );

    if (formControlsArray.length === 0) {
      formControlsArray.push(
        new FormControl<string | null>(null, {
          nonNullable: false,
          validators: [Validators.maxLength(BOT_RULE_MAX_LENGTH)],
        }),
      );
    }

    return new FormArray<FormControl<string | null>>(formControlsArray);
  }
}

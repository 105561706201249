(function () {
  'use strict';

  angular.module('myApp.userCard').controller('CqUserCardWrapperController', CqUserCardWrapperController);

  function CqUserCardWrapperController($stateParams) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.userId = $stateParams.userId; // ID пользователя
    }
  }
})();

import { firstValueFrom } from 'rxjs';

import {
  EMAIL_TYPES,
  MESSAGE_PART_TYPES,
  POPUP_CHAT_TYPES,
  TYPE_OS_PREVIEW,
} from '../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular
    .module('myApp.externalTemplatePreviewApp')
    .controller('CqMessageTemplatesExternalPreviewController', CqMessageTemplatesExternalPreviewController);

  function CqMessageTemplatesExternalPreviewController(
    $stateParams,
    caseStyleHelper,
    featureModel,
    messageTemplateModel,
    messagePartModel,
    messageSenderModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.caseStyleHelper = caseStyleHelper;
      vm.currentApp = null;
      vm.EMAIL_TYPES = EMAIL_TYPES;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.messagePart = null;
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.typeOs = TYPE_OS_PREVIEW.IOS;

      getMessagePartFromTemplate();
    }

    /**
     * Задает дефолтные данные для сендера
     */
    function initSenderEmail() {
      vm.messagePart[MESSAGE_PART_TYPES.EMAIL].sender = messageSenderModel.getDefault();
      vm.messagePart[MESSAGE_PART_TYPES.EMAIL].sender.name = 'Name';
    }

    /**
     * Получение варианта сообщения из шаблона
     *
     * @returns {*|PromiseLike<{currentApp: *}>|Promise<{currentApp: *}>}
     */
    function getMessagePartFromTemplate() {
      /*NOTE Данные надо получать тут, т.к. либа которая делает скрины, делает их когда отработали запросы к серверу. И когда запрос делается в роуте, то в момент скриншота компоненты еще не успели отрендерится*/
      return firstValueFrom(messageTemplateModel.getByToken($stateParams.token)).then(getTemplateSuccess);

      function getTemplateSuccess(template) {
        vm.currentApp = template.app;
        vm.messagePart = messagePartModel.parseFromTemplate(template);
        vm.messagePart.type === MESSAGE_PART_TYPES.EMAIL && initSenderEmail();
      }
    }
  }
})();

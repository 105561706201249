import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EXTENDED_RESPONSE } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class ApiCredentialsModel {
  constructor(private httpClient: HttpClient) {}

  /**
   * Получение ключей API
   *
   * @param {String} appId - ID приложения
   */
  get(appId: string): Observable<any> {
    const params = {
      include_api_tokens: true,
    };

    return this.httpClient
      .get('/apps/' + appId + '/credentials', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          const apiCredentials = response.data;

          this.parse(apiCredentials);

          return apiCredentials;
        }),
      );
  }

  /**
   * Парсинг API ключей
   *
   * @param {Object} apiCredentials - API ключи
   */
  parse(apiCredentials: any) {
    apiCredentials.apiTokens = apiCredentials.apiTokens || [];

    for (let i = 0; i < apiCredentials.apiTokens.length; i++) {
      this.parse(apiCredentials.apiTokens[i]);
    }
  }
}

import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[cqMaxSize]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxSizeDirective), multi: true }],
})
export class MaxSizeDirective {
  @Input('cqMaxSize') maxSize!: number; // максимальный размер файла в байтах

  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    const file = control.value;

    if (!file || !(file instanceof File)) {
      return null;
    }

    if (file.size > this.maxSize) {
      return {
        maxSize: {
          maxSize: this.maxSize,
          actualSize: file.size,
        },
      };
    }

    return null;
  }
}

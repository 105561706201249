import { IFilterService, translate } from 'angular';
import Moment from 'moment';

import { environment } from '@environment';
import { CaseStyleHelper } from '@panel/app/services';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

import { DATE_FORMATS_FOR_RANGE, TIME_UNIT_MEASURES, TIME_UNITS, UppercaseLanguage } from './time-unit.constants';

/**
 * Сервис для работы с единицами измерения времени
 */
export class TimeUnitService {
  //@ngInject
  constructor(
    private readonly $filter: IFilterService,
    private readonly $translate: translate.ITranslateService,
    private readonly moment: typeof Moment,
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly l10nHelper: L10nHelperService,
  ) {}

  /**
   * Форматирование двух дат в диапазон
   * Вдохновлялся вот этим https://alxrm.github.io/date-ranger (https://gist.github.com/alxrm/13646d121053bbae57bf920aa58cc73f)
   *
   * @param startDate Начальная дата
   * @param endDate Конечная дата
   */
  formatDateRange(startDate: Moment.Moment | number, endDate: Moment.Moment | number): string {
    const today = this.moment();
    let start = this.moment.isMoment(startDate) ? startDate : this.moment(startDate * 1000);
    let end = this.moment.isMoment(endDate) ? endDate : this.moment(endDate * 1000);

    // свопаем даты, если они перепутаны местами
    if (start > end) {
      const temp = start;
      start = end;
      end = temp;
    }

    const uppercaseLanguage = (environment.language as string).toUpperCase() as UppercaseLanguage;
    const day = DATE_FORMATS_FOR_RANGE[uppercaseLanguage].DAY;
    const dayMonth = DATE_FORMATS_FOR_RANGE[uppercaseLanguage].DAY_MONTH;
    const dayYear = DATE_FORMATS_FOR_RANGE[uppercaseLanguage].DAY_YEAR;
    const dayMonthYear = DATE_FORMATS_FOR_RANGE[uppercaseLanguage].DAY_MONTH_YEAR;

    const thisYear = start.isSame(today, 'year');

    if (start.isSame(end, 'day') && thisYear) {
      return start.format(dayMonth);
    }

    if (start.isSame(end, 'day')) {
      return start.format(dayMonthYear);
    }

    if (start.isSame(end, 'month') && thisYear) {
      if (this.l10nHelper.isUsCountry()) {
        return start.format(dayMonth) + ' — ' + end.format(day);
      } else {
        return start.format(day) + ' — ' + end.format(dayMonth);
      }
    }

    if (start.isSame(end, 'month')) {
      if (this.l10nHelper.isUsCountry()) {
        return start.format(dayMonth) + ' — ' + end.format(dayYear);
      } else {
        return start.format(day) + ' — ' + end.format(dayMonthYear);
      }
    }

    if (start.isSame(end, 'year') && thisYear) {
      return start.format(dayMonth) + ' — ' + end.format(dayMonth);
    }

    if (start.isSame(end, 'year')) {
      return start.format(dayMonth) + ' — ' + end.format(dayMonthYear);
    }

    return start.format(dayMonthYear) + ' — ' + end.format(dayMonthYear);
  }

  /**
   * Получение самой подходящей единицы времени по значению в секундах
   * Самая подходящая - максимальная из availableTimeUnits, на которую valueInSeconds делится нацело
   *
   * @param valueInSeconds Значение в секундах
   * @param availableTimeUnits Массив имён единиц времени, из которых можно производить выборку
   */
  getByValue(valueInSeconds: number | string, availableTimeUnits?: Array<TIME_UNITS>): TIME_UNITS {
    let timeUnits: TIME_UNITS[] = [];
    let timeUnitsWithMeasures = [];
    let foundTimeUnit;

    if (availableTimeUnits) {
      timeUnits.push.apply(timeUnits, availableTimeUnits);
    } else {
      //@ts-ignore
      timeUnits = this.$filter('toArray')(TIME_UNITS);
    }

    // сопоставление единиц времени и их величин, чтобы их можно было посортировать по возрастанию
    for (let i = 0; i < timeUnits.length; i++) {
      const timeUnit = timeUnits[i];

      timeUnitsWithMeasures.push({
        timeUnit: timeUnit,
        measure: TIME_UNIT_MEASURES[timeUnit],
      });
    }

    // нужно посортировать массив по возрастанию measure
    timeUnitsWithMeasures = this.$filter('orderBy')(timeUnitsWithMeasures, '+measure');

    // бежим по всем единицам измерения до тех пор, пока не дойдём до последней, либо не найдём такую, measure которой меньше, чем valueInSeconds (т.к. в этом случае valueInSeconds уже не разделится нацело на measure)
    for (let i = 0; i < timeUnitsWithMeasures.length; i++) {
      if (Number(valueInSeconds) >= timeUnitsWithMeasures[i].measure) {
        if ((valueInSeconds as number) % timeUnitsWithMeasures[i].measure == 0) {
          foundTimeUnit = timeUnitsWithMeasures[i].timeUnit;
        }
      } else {
        break;
      }
    }

    // если не нашли в цикле ни одну подходящую единицу измерения - ищем ту, measure которой будет максимально приближен к valueInSeconds
    if (!foundTimeUnit) {
      let closestTimeUnit = timeUnitsWithMeasures[0];

      for (let i = 1; i < timeUnitsWithMeasures.length; i++) {
        if (
          Math.abs(closestTimeUnit.measure - (valueInSeconds as number)) >
          Math.abs(timeUnitsWithMeasures[i].measure - (valueInSeconds as number))
        ) {
          closestTimeUnit = timeUnitsWithMeasures[i];
        }
      }
      foundTimeUnit = closestTimeUnit.timeUnit;
    }

    return foundTimeUnit;
  }

  /**
   * Получение строки перевода
   * Самая подходящая единица времени - максимальная из availableTimeUnits, на которую valueInSeconds делится нацело
   *
   * @param valueInSeconds Значение в секундах
   * @param availableTimeUnits Массив имён единиц времени, из которых можно производить выборку
   * @param shortTranslation Получить короткий перевод
   * @param useAltTranslation Использовать альтернативный перевод (актуально только при shortTranslation == false)
   */
  getTranslation(
    valueInSeconds: number | string,
    availableTimeUnits?: Array<TIME_UNITS>,
    shortTranslation?: boolean,
    useAltTranslation?: boolean,
  ): string {
    const parsedTime = this.parseSeconds(valueInSeconds, availableTimeUnits); // получаем подходящую единицу времени
    let resultString = '';
    let translationType; // тип перевода (full, short, onlyUnit, по сути это часть ключа в переводах)
    let useMessageFormat; // использовать messageformat или нет (сейчас актуально только для типа перевода full)

    // HACK: это, конечно, всё костыли, т.к. они не покрывают всех падежей русского языка, и из-за этого в некоторых местах пришлось просто поменять переводы.
    //  Надо это будет переделать и сделать универсально
    if (shortTranslation) {
      translationType = 'short';
      useMessageFormat = false;
    } else {
      if (useAltTranslation) {
        translationType = 'fullAlt';
      } else {
        translationType = 'full';
      }

      useMessageFormat = true;
    }

    for (let i = 0; i < parsedTime.length; i++) {
      resultString +=
        this.$translate.instant(
          'services.timeUnit.unitsWithValue.' + parsedTime[i].timeUnit + '.' + translationType,
          { count: parsedTime[i].value },
          useMessageFormat ? 'messageformat' : '',
        ) + ' ';
    }

    return resultString.trim();
  }

  /**
   * Парсинг секунд в массив объектов вида [{value: целое_число, timeUnit: единица_измерения}, ...]
   * value показывает целое количество timeUnit.measure в valueInSeconds
   *
   * @example
   * // вернёт [{value: 17, timeUnit: TIME_UNITS.MINUTE}, {value: 4, timeUnit: TIME_UNITS.SECOND}]
   * parseSeconds(1024);
   *
   * @param valueInSeconds Значение в секундах
   * @param availableTimeUnits Массив имён единиц времени, из которых можно формировать выходное значение
   */
  parseSeconds(
    valueInSeconds: number | string,
    availableTimeUnits?: Array<TIME_UNITS>,
  ): Array<{ value: number; timeUnit: TIME_UNITS }> {
    const result = [];
    let timeUnits: TIME_UNITS[] = [];
    let timeUnitsWithMeasures = [];

    valueInSeconds = Math.round(valueInSeconds as number); // округляем, т.к. время в секундах должно быть целой величиной

    if (availableTimeUnits) {
      timeUnits.push.apply(timeUnits, availableTimeUnits);
    } else {
      //@ts-ignore
      timeUnits = this.$filter('toArray')(TIME_UNITS);
    }

    // сопоставление единиц времени и их величин, чтобы их можно было посортировать по возрастанию
    for (let i = 0; i < timeUnits.length; i++) {
      const timeUnit = timeUnits[i];

      timeUnitsWithMeasures.push({
        timeUnit: timeUnit,
        measure: TIME_UNIT_MEASURES[timeUnit],
      });
    }

    timeUnitsWithMeasures = this.$filter('orderBy')(timeUnitsWithMeasures, '-measure');

    for (let i = 0; i < timeUnitsWithMeasures.length && (valueInSeconds > 0 || result.length == 0); i++) {
      let currentTimeUnit = timeUnitsWithMeasures[i];

      if (currentTimeUnit.measure <= valueInSeconds) {
        const part = {
          timeUnit: currentTimeUnit.timeUnit,
          value: Math.floor(valueInSeconds / currentTimeUnit.measure),
        };

        result.push(part);

        valueInSeconds %= currentTimeUnit.measure;
      }
    }

    return result;
  }
}

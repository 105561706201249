import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbDate, NgbDatepickerState } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { DatetimePickerService } from '@panel/app/shared/components/datetime-picker/services/datetime-picker.service';

@Component({
  selector: 'cq-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent extends AbsCVAFormControlBasedComponent<moment.Moment> {
  @Input()
  initialDate: moment.Moment = moment();

  readonly control: FormControl<moment.Moment> = this.fb.control<moment.Moment>(moment(), { nonNullable: true });

  hoveredDate: NgbDate | null = null;

  get controlValue(): moment.Moment {
    return this.control.getRawValue() ?? this.control.defaultValue;
  }

  constructor(private readonly datetimePickerService: DatetimePickerService, private readonly fb: FormBuilder) {
    super();
  }

  isOutside(state: NgbDatepickerState, date: NgbDate): boolean {
    return (
      date.before(state.firstDate) ||
      date.after(state.lastDate) ||
      date.before(state.minDate) ||
      date.after(state.maxDate)
    );
  }

  isHover(ngbDate: NgbDate): boolean {
    return ngbDate.equals(this.hoveredDate);
  }

  isSelected(ngbDate: NgbDate): boolean {
    let date = this.controlValue;

    return ngbDate.equals(this.datetimePickerService.fromMomentToNgb(date));
  }

  onSelectDate(ngbDate: NgbDate): void {
    let beforeSelectedDate = this.controlValue;
    let selectedDate = this.datetimePickerService.fromNgbToMoment(ngbDate);

    if (selectedDate) {
      this.control.setValue(
        selectedDate.add(beforeSelectedDate.hours(), 'hours').add(beforeSelectedDate.minutes(), 'minutes'),
      );
    }
  }
}

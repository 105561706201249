import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';

/**
 * Контроллер списка шаблонов
 */
(function () {
  'use strict';

  angular.module('myApp.messageTemplates').controller('CqMessageTemplatesController', CqMessageTemplatesController);

  function CqMessageTemplatesController(
    $scope,
    $translate,
    $uibModal,
    MESSAGE_TEMPLATES_TABS,
    carrotquestHelper,
    planFeatureAccessService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, vm.currentApp);

      vm.currentTab = MESSAGE_TEMPLATES_TABS.TEMPLATE_GALLERY; // Выбранная вкладка
      vm.isReadyTemplatesLoading = false; // Флаг загрузки готовых шаблонов
      vm.isTemplatesLoading = false; // Флаг загрузки сохраненных шаблонов
      vm.MESSAGE_TEMPLATES_TABS = MESSAGE_TEMPLATES_TABS;
      vm.toggleReadyTemplatesLoadingFlag = toggleReadyTemplatesLoadingFlag;
      vm.toggleTemplatesLoadingFlag = toggleTemplatesLoadingFlag;
      vm.trackClickCreateTemplate = trackClickCreateTemplate;
      vm.trackEnterOnPage = trackEnterOnPage;
    }

    /**
     * Переключение флага загрузки готовых шаблонов
     */
    function toggleReadyTemplatesLoadingFlag() {
      vm.isReadyTemplatesLoading = !vm.isReadyTemplatesLoading;
    }

    /**
     * Переключение флага загрузки сохраненных шаблонов
     */
    function toggleTemplatesLoadingFlag() {
      vm.isTemplatesLoading = !vm.isTemplatesLoading;
    }

    /**
     * Трек перехода на вкладку с разделами шаблонов
     *
     * @param {String} tab - Название вкладки
     */
    function trackEnterOnPage(tab) {
      carrotquestHelper.track('Шаблоны - зашел', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        'Источник шаблона': tab,
      });
    }

    /**
     * Трек клика на кнопку "создать новый шаблон"
     */
    function trackClickCreateTemplate() {
      carrotquestHelper.track('Шаблоны - клик на создать шаблон', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
      });
    }
  }
})();

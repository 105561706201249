import { AddonsConfiguration } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.types';
import { BILLING_ADD_ONS, UNIQUE_BILLING_PLAN_ID } from '@panel/app/services/billing-info/billing-info.constants';

export const DEFAULT_RU_PRICING_ADDONS: AddonsConfiguration = {
  [BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'included',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING_V0224]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'not-available',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'not-available',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING_V0224]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING_V0224]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'included',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING_V0224]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'not-available',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'not-available',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.BEE_EDITOR_V2]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'included',
      value: null,
    },
  },
  [BILLING_ADD_ONS.SECURITY]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.TELEGRAM_BOT]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
};

export const DEFAULT_US_PRICING_ADDONS: AddonsConfiguration = {
  [BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'included',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'not-available',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'not-available',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'included',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'not-available',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'not-available',
      value: null,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.BEE_EDITOR_V2]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'included',
      value: null,
    },
  },
  [BILLING_ADD_ONS.SECURITY]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
  [BILLING_ADD_ONS.TELEGRAM_BOT]: {
    [UNIQUE_BILLING_PLAN_ID.CONVERSATION]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.SUPPORT]: {
      availability: 'available',
      value: false,
    },
    [UNIQUE_BILLING_PLAN_ID.MARKETING]: {
      availability: 'available',
      value: false,
    },
  },
};

export const DEFAULT_PRICING_ADDONS: Record<'RU' | 'US', AddonsConfiguration> = {
  RU: DEFAULT_RU_PRICING_ADDONS,
  US: DEFAULT_US_PRICING_ADDONS,
};

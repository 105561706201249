import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { DestroyService } from '@panel/app/services';

import { MemberInvitationsFiltersComponent } from './member-invitations-filters.component';

@NgModule({
  declarations: [MemberInvitationsFiltersComponent],
  exports: [MemberInvitationsFiltersComponent],
  imports: [CommonModule, FormsModule, TranslocoModule],
  providers: [DestroyService],
})
export class MemberInvitationsFiltersModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';
import { PUSH_SUBSCRIPTION_TYPE } from '@http/push-subscription/push-subscription.constants';
import { PushSubscriptionModel } from '@http/push-subscription/push-subscription.model';
import { PushSubscription } from '@http/push-subscription/push-subscription.types';
import { UserService } from '@http/user/services/user.service';
import { User } from '@http/user/types/user.type';
import { ModalHelperService } from '@panel/app/services';
import { LS_UNCOLLAPSED_USER_CARD_BLOCKS } from '@panel/app/shared/constants/localstorage.keys';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

/** Компонент для работы с подписками на Web Push */
@Component({
  selector: 'cq-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSubscriptionsComponent implements OnInit {
  /** Текущий апп */
  @Input({ required: true })
  currentApp!: App;

  /** Текущий django пользователь */
  @Input({ required: true })
  djangoUser: DjangoUser;

  /** Пользователь */
  @Input({ required: true })
  user!: User;

  @Input({ required: true })
  telegramIntegrations: TelegramIntegration[] = [];

  @Output()
  collapseStateChange: EventEmitter<string> = new EventEmitter();

  /** Колбэк на смену значения свойства пользователя */
  @Output()
  updateProps: EventEmitter<void> = new EventEmitter<void>();

  /** Состояние коллапса */
  isCollapsed: boolean = true;

  /** Есть ли доступ к фичегалке Web Push'ами */
  hasAccessWebPush!: boolean;

  /** Нужно ли показывать информацию о Web Push'ах*/
  isShowWebPush: boolean = false;

  /** Список устройств и браузеров, с которых совершена подписка на Web-пуши */
  webPushSubscriptions: PushSubscription[] = [];

  /** Имя для localeStorage */
  userSubscriptionsLSName = 'user_subscriptions';

  constructor(
    private readonly $uibModal: UibModalService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly featureModel: FeatureModel,
    private readonly modalHelperService: ModalHelperService,
    private readonly pushSubscriptionModel: PushSubscriptionModel,
    private readonly translocoService: TranslocoService,
    private readonly userService: UserService,
  ) {}

  /** Подписан на пуши в SDK */
  get isSdkPushSubscribed(): boolean {
    return !!this.user.props.$sdk_push_subscribed;
  }

  /** Статус подписки на пуши рассылок в SDK */
  get isSdkPushCampaignsSubscribed(): boolean {
    return (
      !isDefined(this.user.props.$sdk_push_campaigns_subscribed) ||
      this.user.props.$sdk_push_campaigns_subscribed === 'true' ||
      this.user.props.$sdk_push_campaigns_subscribed === true
    );
  }

  isSubscribedTo(tgIntegration: TelegramIntegration) {
    if (!this.user.props.$telegram_subscriptions) {
      return false;
    }
    if (!Array.isArray(this.user.props.$telegram_subscriptions)) {
      throw new Error('Subscriptions list must be an array');
    }
    return this.user.props.$telegram_subscriptions.includes(tgIntegration.settings.botId);
  }

  /** Статус подписки на пуши сообщений из чата в SDK */
  get isSdkPushNotificationsSubscribed(): boolean {
    return (
      !isDefined(this.user.props.$sdk_push_notifications_subscribed) ||
      this.user.props.$sdk_push_notifications_subscribed === 'true' ||
      this.user.props.$sdk_push_notifications_subscribed === true
    );
  }

  ngOnInit(): void {
    if (localStorage.getItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS)?.includes(this.userSubscriptionsLSName)) {
      this.isCollapsed = false;
    }

    this.hasAccessWebPush = this.featureModel.hasAccess(FEATURES.WEB_PUSH, this.currentApp.created);

    this.refreshPushSubscriptions();
  }

  /** Количество подписок */
  countOfSubscriptions() {
    const lengthOfTelegramSubscriptions = this.isOperator
      ? 0
      : this.telegramIntegrations.filter((t) => this.isSubscribedTo(t)).length;
    const lengthOfPushSubscriptions = this.webPushSubscriptions?.length ?? 0;
    return this.user.emailStatus
      ? lengthOfPushSubscriptions + lengthOfTelegramSubscriptions + 1
      : lengthOfPushSubscriptions + lengthOfTelegramSubscriptions;
  }

  /**
   * Изменение состояние collapse
   *
   * NOTE:
   *  Так реализовано из-за того, что
   *  при использовании двусторонней связи в ngbCollapse блок дёргается при открытии и закрытии
   */
  changeCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    this.changeDetectorRef.detectChanges();
  }

  /** Получение текста перевода статуса подписки на пуши на сообщения из чата в SDK */
  getChatMessagesSubscriptionStatusTranslate(): string {
    return this.isSdkPushNotificationsSubscribed
      ? this.translocoService.translate(
          'userSubscriptionsComponent.inAppPushNotifications.chatMessages.status.subscribed',
        )
      : this.translocoService.translate(
          'userSubscriptionsComponent.inAppPushNotifications.chatMessages.status.unsubscribed',
        );
  }

  /** Получение текста перевода статуса подписки на пуши на рассылки в SDK */
  getCampaignsSubscriptionStatusTranslate(): string {
    return this.isSdkPushCampaignsSubscribed
      ? this.translocoService.translate('userSubscriptionsComponent.inAppPushNotifications.campaigns.status.subscribed')
      : this.translocoService.translate(
          'userSubscriptionsComponent.inAppPushNotifications.campaigns.status.unsubscribed',
        );
  }

  /**
   * Получение подписок пользователя
   */
  getSubscriptions(userId: User['id']): Promise<PushSubscription[]> {
    return firstValueFrom(this.pushSubscriptionModel.getList(userId));
  }

  /**
   * Проверяет, есть ли подписки на Web Push
   */
  isHasEmailSubscription() {
    return !!this.user.emailStatus;
  }

  /**
   * Открытие модалки со списком подписок на Web Push
   *
   * @param subscriptions Массив подписок Web Push
   */
  openEditPushSubscriptionsModal(subscriptions: PushSubscription[]) {
    const modalInstance = this.$uibModal.open({
      component: 'cqUnsubscribeWebPushModal',
      resolve: {
        modalWindowParams: () => {
          return {
            subscriptions: subscriptions,
            userId: this.user.id,
          };
        },
      },
      size: 'lg modal-dialog-centered',
    });

    modalInstance.result.then(() => this.refreshPushSubscriptions()).catch(() => this.refreshPushSubscriptions());
  }

  /** Открытие модалки отписки от пушей с сообщениями из чата в SDK */
  openUnsubscribeSdkChatMessageNotificationsModal(): void {
    const modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate(
          'userSubscriptionsComponent.unsubscribeSdkChatMessageNotificationsModal.heading',
        ),
        body: this.translocoService.translate(
          'userSubscriptionsComponent.unsubscribeSdkChatMessageNotificationsModal.body',
        ),
        confirmButtonText: this.translocoService.translate(
          'userSubscriptionsComponent.unsubscribeSdkChatMessageNotificationsModal.confirmButtonText',
        ),
      })
      .open(ConfirmModalComponent, {
        centered: true,
      });

    modal.result.then(() => this.updateUserProperty('$sdk_push_notifications_subscribed', false)).catch(() => {});
  }

  /** Открытие модалки отписки от пушей с рассылками в SDK */
  openUnsubscribeSdkCampaignsNotificationsModal(): void {
    const modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate(
          'userSubscriptionsComponent.unsubscribeSdkCampaignsNotificationsModal.heading',
        ),
        body: this.translocoService.translate(
          'userSubscriptionsComponent.unsubscribeSdkCampaignsNotificationsModal.body',
        ),
        confirmButtonText: this.translocoService.translate(
          'userSubscriptionsComponent.unsubscribeSdkCampaignsNotificationsModal.confirmButtonText',
        ),
      })
      .open(ConfirmModalComponent);

    modal.result.then(() => this.updateUserProperty('$sdk_push_campaigns_subscribed', false)).catch(() => {});
  }

  /**
   * Обновление данных о списке подписок на Web Push
   */
  refreshPushSubscriptions() {
    this.getSubscriptions(this.user.id).then((subscriptions: PushSubscription[]) => {
      this.webPushSubscriptions = subscriptions.filter((sub) => sub.type === PUSH_SUBSCRIPTION_TYPE.BROWSER);
      this.isShowWebPush = this.hasAccessWebPush && this.webPushSubscriptions.length > 0;
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Обновление свойства пользователя
   *
   * @param property Свойство
   * @param value Значение
   */
  updateUserProperty(property: string, value: any): void {
    this.userService
      .setProperties(this.user.id, [{ op: 'update_or_create', key: property, value }])
      .pipe(take(1))
      .subscribe(() => this.updateProps.emit());
  }

  trackById(index: number, { id }: TelegramIntegration) {
    return id;
  }

  @tuiPure
  get isOperator(): boolean {
    return this.djangoUser.prefs[this.currentApp.id].permissions === 'operator';
  }
}

import { FEATURES } from '../../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.directives.wizard').controller('CqWizardController', CqWizardController);

  function CqWizardController($filter, $q, featureModel, wizardHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.addStep = addStep;
      vm.currentStep = null;
      vm.destroy = destroy;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.getStepByName = getStepByName;
      vm.goToStep = goToStep;
      vm.goToStepWithoutValidation = goToStepWithoutValidation;
      vm.lastCompletedStep = null;
      vm.nextStep = nextStep;
      vm.nextStepWithoutValidation = nextStepWithoutValidation;
      vm.previousStep = previousStep;
      vm.previousStepWithoutValidation = previousStepWithoutValidation;
      vm.removeStep = removeStep;
      vm.setLastCompletedStep = setLastCompletedStep;
      vm.steps = [];

      wizardHelper.addWizard(vm);
    }

    /**
     * Добавление шага визарда в массив шагов
     *
     * @param {Object} step Контроллер шага
     */
    function addStep(step) {
      vm.steps.push(step);

      // если текущий шаг является первым, или же совпадает с выбранным шагом - выбираем его
      if (
        step.name === vm.currentStepName ||
        ((angular.isUndefined(vm.currentStepName) || vm.currentStepName == null) && vm.steps.length === 1)
      ) {
        goToStepWithoutValidation(step);
      }
    }

    /**
     * Удаление визарда из общего списка (вызывается после уничтожения визарда)
     */
    function destroy() {
      wizardHelper.removeWizard(vm);
    }

    /**
     * Получение шага по имени
     *
     * @param {*} stepName Имя шага
     * @return {Object|null}
     */
    function getStepByName(stepName) {
      if (stepName) {
        return $filter('filter')(vm.steps, { name: stepName }, true)[0];
      } else {
        return null;
      }
    }

    /**
     * Переход на следующий шаг
     */
    function nextStep() {
      var nextStep = vm.steps[vm.steps.indexOf(vm.currentStep) + 1];

      if (angular.isDefined(nextStep)) {
        goToStep(nextStep);
      }
    }

    /**
     * Переход на следующий шаг без валидации и коллбэков
     */
    function nextStepWithoutValidation() {
      var nextStep = vm.steps[vm.steps.indexOf(vm.currentStep) + 1];

      if (angular.isDefined(nextStep)) {
        goToStepWithoutValidation(nextStep);
      }
    }

    /**
     * Переход на предыдущий шаг
     */
    function previousStep() {
      var previousStep = vm.steps[vm.steps.indexOf(vm.currentStep) - 1];

      if (angular.isDefined(previousStep)) {
        goToStep(previousStep);
      }
    }

    /**
     * Переход на следующий шаг без валидации и коллбэков
     */
    function previousStepWithoutValidation() {
      var previousStep = vm.steps[vm.steps.indexOf(vm.currentStep) - 1];

      if (angular.isDefined(previousStep)) {
        goToStepWithoutValidation(previousStep);
      }
    }

    /**
     * Обновление состояния всех шагов
     */
    function refreshStepStates() {
      var currentStepIndex = vm.steps.indexOf(vm.currentStep);

      for (var i = 0; i < vm.steps.length; i++) {
        var step = vm.steps[i];

        step.active = i == currentStepIndex;
        step.complete = i < currentStepIndex;
      }
    }

    /**
     * Функция удаления шага
     *
     * @param step
     */
    function removeStep(step) {
      var index = vm.steps.indexOf(step);

      if (step.name == vm.currentStepName) {
        if (index == 0) {
          if (vm.steps.length > 1) {
            vm.currentStepName = vm.steps[index + 1].name;
          } else {
            vm.currentStepName = null;
          }
        } else {
          vm.currentStepName = vm.steps[index - 1].name;
        }
      }

      if (step.name == vm.lastCompletedStepName) {
        if (index == 0) {
          vm.lastCompletedStepName = null;
        } else {
          vm.lastCompletedStepName = vm.steps[index - 1].name;
        }
      }

      vm.steps.splice(index, 1);
    }

    /**
     * Переход на шаг
     *
     * @param {Object} step Шаг
     */
    function goToStep(step) {
      // переход осуществляется только если текущий шаг не равен шагу, на который осуществляется переход
      if (vm.currentStep != step) {
        if (vm.restricted) {
          // если последний завешённый шаг определён, и пользователь хочет перейти с текущего шага на текущий шаг + 2 - надо ему это запретить, т.к. переходить он может толкьо на шаг, следующий за текущим
          if (vm.lastCompletedStep) {
            if (vm.steps.indexOf(vm.lastCompletedStep) < vm.steps.indexOf(step) - 2) {
              return $q.reject();
            }
            // если же последний завешённый шаг не определён - значит пользователь сейчас на первом шаге, и если он пытается перейти на третий или больше - надо ему запретить это сделать
          } else if (vm.steps.indexOf(step) >= 2) {
            return $q.reject();
          }
        }

        // Если мы движемся вперед в визарде, то вызываем коллбэки для движения вперед
        if (vm.steps.indexOf(vm.currentStep) < vm.steps.indexOf(step)) {
          return vm.currentStep
            .onExitToNext()
            .then(step.onEnterFromPrevious)
            .then(angular.bind(null, goToStepWithoutValidation, step));
        }

        // Если мы движемся назад в визарде, то вызываем коллбэки для движения назад
        if (vm.steps.indexOf(vm.currentStep) > vm.steps.indexOf(step)) {
          return vm.currentStep
            .onExitToPrevious()
            .then(step.onEnterFromNext)
            .then(angular.bind(null, goToStepWithoutValidation, step));
        }
      }
    }

    /**
     * Выбор шага без валидации и коллбэков
     *
     * @param step
     */
    function goToStepWithoutValidation(step) {
      if (vm.currentStep != step) {
        var stepIndex = vm.steps.indexOf(step);

        if (~stepIndex) {
          vm.currentStep = step;
          vm.currentStepName = step.name;
        }

        if (stepIndex >= 1) {
          setLastCompletedStep(vm.steps[stepIndex - 1]);
        }

        refreshStepStates();
      }
    }

    /**
     * Установка шага последним завершённым шагом
     *
     * @param {Object} step Шаг
     */
    function setLastCompletedStep(step) {
      if (!vm.lastCompletedStep || vm.steps.indexOf(vm.lastCompletedStep) < vm.steps.indexOf(step)) {
        vm.lastCompletedStep = step;
        vm.lastCompletedStepName = step.name;
      }
    }
  }
})();

/**
 * Помощник для работы с ChartJs
 */
(function () {
  'use strict';

  angular.module('myApp.services.chartHelper').factory('chartHelper', chartHelper);

  function chartHelper() {
    var DEFAULT_COLORS = [
      '#5c5cd6',
      '#f6bd60',
      '#8fad68',
      '#cb9164',
      '#f06449',
      '#977390',
      '#af2bbf',
      '#6c91bf',
      '#a1cf6b',
      '#a69658',
    ];

    var usedColors = DEFAULT_COLORS.slice();

    return {
      createChart: createChart,
      generateColors: generateColors,
      getUsedColors: getUsedColors,
      mergeDatasetColors: mergeDatasetColors,
      removeDecimalLabels: removeDecimalLabels,
      updateChart: updateChart,
    };

    /**
     * Создание графика ChartJs
     *
     * @param {String|HTMLCanvasElement} element Селектор canvas-элемента, или сам canvas-элемент
     * @param {String} type Тип графика
     * @param {Object} data Данные для графика
     * @param {Object} options Опции для графика
     * @returns {Chart.Controller} Инстанс графика ChartJs
     */
    function createChart(element, type, data, options) {
      if (element.constructor == String) {
        element = angular.element(element)[0];
        if (!element) {
          throw Error('Element with this selector not found: ', element);
        }
      }

      if (element.constructor != HTMLCanvasElement) {
        throw Error('Element must be a HTMLCanvasElement: ', element);
      }

      fillColors(type, data);

      return new Chart(element.getContext('2d'), {
        type: type,
        data: data,
        options: options,
      });
    }

    /**
     * Создание цветов для каждого датасета графика (у каждого датасета свой цвет)
     *
     * @param {Array.<Object>} datasets Массив датасетов
     */
    function createColorsForDatasets(datasets) {
      var currentColors;
      var randomColor;

      for (var i = 0; i < datasets.length; i++) {
        if (usedColors[i]) {
          currentColors = generateColors(usedColors[i]);
        } else {
          randomColor = createRandomColor();
          usedColors.push(randomColor.hexString());
          currentColors = generateColors(randomColor);
        }

        mergeDatasetColors(datasets[i], currentColors);
      }
    }

    /**
     * Создание массива цветов для каждого датасета графика (у каждого элемента датасета свой цвет)
     *
     * @param {Array.<Object>} datasets Массив датасетов
     */
    function createColorsArrayForDatasets(datasets) {
      for (var i = 0; i < datasets.length; i++) {
        var colors = {
          backgroundColor: [],
          borderColor: [],
        };
        var currentColor;
        var randomColor;

        for (var j = 0; j < datasets[i].data.length; j++) {
          // если в массиве цветов уже есть цвета для этого элемента - их не надо генерировать
          if (
            datasets[i].backgroundColor &&
            datasets[i].borderColor &&
            datasets[i].backgroundColor[j] &&
            datasets[i].borderColor[j]
          ) {
            currentColor = datasets[i].backgroundColor[j];
          } else {
            if (usedColors[j]) {
              currentColor = Chart.helpers.color(usedColors[j]).rgbaString();
            } else {
              randomColor = createRandomColor();
              usedColors.push(randomColor.hexString());
              currentColor = randomColor.rgbaString();
            }
          }

          colors.backgroundColor.push(currentColor);
          colors.borderColor.push(currentColor);
        }

        datasets[i] = angular.merge(datasets[i], colors);
      }
    }

    /**
     * Получение объекта цветом для графика с рандомным цветом
     *
     * @returns {Color}
     */
    function createRandomColor() {
      return Chart.helpers.color({
        r: Math.floor(Math.random() * 256),
        g: Math.floor(Math.random() * 256),
        b: Math.floor(Math.random() * 256),
      });
    }

    function fillColors(type, data) {
      if (~['doughnut', 'cqDoughnut', 'pie', 'polarArea'].indexOf(type)) {
        createColorsArrayForDatasets(data.datasets);
      } else {
        createColorsForDatasets(data.datasets);
      }
    }

    /**
     * Получение цветов для графика
     *
     * @param {Color|String|Object} color Цвет, который сможет распарсить ChartJs
     * @returns {{backgroundColor: *, pointBackgroundColor: *, pointHoverBackgroundColor: *, borderColor: *, pointBorderColor: *, pointHoverBorderColor: *}}
     */
    function generateColors(color) {
      var parsedColor = Chart.helpers.color(color);
      var originalAlpha = parsedColor.alpha();

      return {
        backgroundColor: parsedColor.alpha(0.1).rgbaString(),
        pointBackgroundColor: parsedColor.alpha(originalAlpha).rgbaString(),
        pointHoverBackgroundColor: parsedColor.alpha(0.8).rgbaString(),
        borderColor: parsedColor.alpha(originalAlpha).rgbaString(),
        pointBorderColor: Chart.helpers.color('#ffffff').rgbaString(),
        pointHoverBorderColor: parsedColor.alpha(originalAlpha).rgbaString(),
      };
    }

    /**
     * Получение текущего списка цветов, которые используются в графиках
     *
     * @return {Array.<String>}
     */
    function getUsedColors() {
      return usedColors;
    }

    /**
     * Слияние цветов датасета и созданных цветов
     *
     * @param {Object} dataset Датасет
     * @param {Object} colors Объект с цветами
     */
    function mergeDatasetColors(dataset, colors) {
      for (var color in colors) {
        if (colors.hasOwnProperty(color)) {
          dataset[color] = angular.isDefined(dataset[color]) ? dataset[color] : colors[color];
        }
      }
    }

    /**
     * Функция для фильтрации лейблов в графиках, оставляет только целочисленные лейблы
     * Использовать в options.scales.yAxes.ticks.callback
     *
     * @param {Number} label Исходный лейбл
     * @returns {Number}
     */
    function removeDecimalLabels(label) {
      if (Math.floor(label) === label) {
        return label;
      }
    }

    /**
     * Обновление данных в инстансе графика
     *
     * @param {Chart.Controller} instance Инстанс графика ChartJs
     * @param {Object} options Опции графика
     */
    function updateChart(instance, options) {
      if (instance) {
        angular.merge(instance.options, options);
        fillColors(instance.chart.config.type, instance.data);
        instance.update();
      }
    }
  }
})();

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TriggerChain, TriggerChainMessageStep } from '@http/trigger-chain/internal-types';

@Injectable()
export class TriggerChainGeneralStatisticsStateService {
  readonly chain$: BehaviorSubject<TriggerChain | null> = new BehaviorSubject<TriggerChain | null>(null);
  readonly chainStepList$: BehaviorSubject<TriggerChainMessageStep[]> = new BehaviorSubject<TriggerChainMessageStep[]>(
    [],
  );
}

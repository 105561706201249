import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { AllokaIntegration } from '@http/integration/integrations/alloka/alloka-integration';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-alloka',
  templateUrl: './alloka.component.html',
  styleUrls: ['./alloka.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllokaComponent implements OnInit {
  activeLang: string;
  /** Текущая интеграция */
  integration: AllokaIntegration;
  integrationTypeTranslation!: string;
  projectName = environment.projectName;

  // @formatter:off
  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly integrationFactory: IntegrationFactory,
    private readonly translocoService: TranslocoService,
  ) {
    this.activeLang = translocoService.getActiveLang();
    this.integration = this.integrationFactory.create(INTEGRATION_TYPES.ALLOKA);
  }
  // @formatter:on

  ngOnInit(): void {
    this.integrationTypeTranslation = this.translocoService.translate(
      `models.integration.types.${this.integration.type}.name`,
    );
  }

  /**
   * Трек клика на кнопку "Перейти в Alloka"
   */
  trackClickGoToAlloka() {
    this.carrotquestHelper.track('Интеграции - ' + this.integration.type + ' - клик на "Перейти в Alloka"');
  }
}

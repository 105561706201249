import { POPUP_BLOCK_BACKGROUND_POSITION_TYPES } from '../../../../../app/services/popup-block/popup-block.constants';

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupImageBlockSettingsController', CqPopupImageBlockSettingsController);

  function CqPopupImageBlockSettingsController($scope, USER_FILES_URL, fileHelper, popupBlockModel, validationHelper) {
    var vm = this;

    /**
     * Максимальная высота картинки
     *
     * @type {number}
     */
    var BACKGROUND_IMAGE_MAX_HEIGHT = 1000;

    /**
     * Минимальная высота картинки
     *
     * @type {number}
     */
    var BACKGROUND_IMAGE_MIN_HEIGHT = 0;

    /**
     * Максимальный размер картинки
     *
     * @type {Number}
     */
    var BACKGROUND_IMAGE_MAX_SIZE = 1024 * 1024;

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      /**
       * Изначальная фоновая картинка
       *
       * @type {File|String|null}
       */
      var currentBackgroundImage = vm.popupBlockParams.backgroundImage;

      vm.BACKGROUND_IMAGE_MAX_HEIGHT = BACKGROUND_IMAGE_MAX_HEIGHT;
      vm.BACKGROUND_IMAGE_MIN_HEIGHT = BACKGROUND_IMAGE_MIN_HEIGHT;
      vm.BACKGROUND_IMAGE_MAX_SIZE = BACKGROUND_IMAGE_MAX_SIZE;
      vm.backgroundPosition = popupBlockModel.getBackgroundPositionType(vm.popupBlockParams); // позиция картинки
      vm.currentBackgroundImagePreview =
        currentBackgroundImage instanceof File
          ? fileHelper.getObjectUrl(currentBackgroundImage)
          : currentBackgroundImage; // URL изначальной фоновой картинки (для вывода превью)
      vm.fileHelper = fileHelper;
      vm.imageBlockSettingsForm = null; // форма настроек блока Картинка
      vm.newBackgroundImage = {
        // новая фоновая картинка
        file: null, // файл нового фона
        url: '', // URL нового фона (для вывода превью)
      };
      vm.POPUP_BLOCK_BACKGROUND_POSITION_TYPES = POPUP_BLOCK_BACKGROUND_POSITION_TYPES;
      vm.popupBlockModel = popupBlockModel;
      vm.validationHelper = validationHelper;

      $scope.$watch('vm.newBackgroundImage.file', setBackgroundImage);

      /**
       * Установка фоновой картинки
       * NOTE: Так пришлось извращаться, потому что поле vm.popupBlockParams.backgroundImage напрямую не связано с моделью инпута
       *
       * @param {File|String|null} newValue
       */
      function setBackgroundImage(newValue) {
        if (newValue) {
          // если новая фоновая картинка присутствует (выбрана и валидна) - выставляем её в качестве нового фона
          vm.popupBlockParams.backgroundImage = newValue;
        } else {
          // иначе - возвращаем старую картинку
          vm.popupBlockParams.backgroundImage = currentBackgroundImage;
        }

        vm.imageBlockSettingsForm.$setSubmitted();
      }
    }

    function destroy() {
      // при уничтожении компонента нужно выставить значения необязательным полям, т.к. все поля должны быть заполнены
      if (!vm.popupBlockParams.backgroundImage) {
        vm.popupBlockParams.backgroundImage = USER_FILES_URL + '/message-images/default.png';
      }
    }
  }
})();

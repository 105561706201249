(function () {
  'use strict';

  require('./conversations-single.controller');

  angular.module('myApp.conversations').component('cqConversationsSingle', cqConversationsSingle());

  function cqConversationsSingle() {
    return {
      bindings: {
        appBlocks: '=', // данные о блокировках приложения
        billingInfo: '=', // информация о биллинге
        channels: '=', // список каналов
        currentApp: '=', // текущее приложение
        djangoUser: '=', // django-пользователь
        knowledgeBaseSettings: '=', // настройки БЗ
        telegramIntegrations: '=',
        teamMembers: '=', // список членов команды
      },
      controller: 'CqConversationsSingleController',
      controllerAs: 'vm',
      template: require('./conversations-single.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'meetingComponent'">
  <ng-container *ngIf="calendlyEventList.length > 0; else setIntegrationCalendly">
    <div [cqShowError]="hrefControl">
      <ng-select
        bindValue="attributes.url"
        bindLabel="attributes.name"
        [formControl]="hrefControl"
        [searchable]="true"
        [clearable]="false"
        [items]="calendlyEventList"
        [required]="true"
        placeholder="{{ t('placeholder') }}"
      ></ng-select>
    </div>

    <cq-validation-messages [control]="hrefControl">
      <cq-validation-message errorName="required">
        {{ t('errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </ng-container>

  <ng-template #setIntegrationCalendly>
    <div>{{ t('setIntegration.description', { integrationType: INTEGRATION_TYPES.CALENDLY }) }}</div>

    <div [cqShowError]="hrefControl">
      <a
        class="btn btn-primary mt-10 w-100"
        type="button"
        uiSref="app.content.integrations.preview"
        [uiParams]="{ integrationType: INTEGRATION_TYPES.CALENDLY }"
      >
        <i class="cqi-sm cqi-external-link"></i>
        <span>{{
          t('setIntegration.button', {
            integrationType: INTEGRATION_TYPES.CALENDLY
          })
        }}</span>
      </a>
    </div>

    <cq-validation-messages [control]="hrefControl">
      <cq-validation-message errorName="required">
        {{
          t('errors.setIntegration', {
            integrationType: INTEGRATION_TYPES.CALENDLY
          })
        }}
      </cq-validation-message>
    </cq-validation-messages>
  </ng-template>
</ng-container>

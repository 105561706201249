<ng-container *transloco="let t; read: 'statusFilterComponent'">
  <ng-select
    class="ng-select-header-options"
    [clearable]="false"
    [searchable]="false"
    [formControl]="control"
    [items]="DATA_SOURCE_STATUS_FILTERED"
  >
    <ng-container *ng-header-tmp>
      <button
        class="ng-select-header-option"
        type="button"
        (click)="control.setValue(DATA_SOURCE_STATUS.ALL); statusSelect.close()"
      >
        {{ t('all') }}
      </button>
    </ng-container>
    <ng-template
      ng-label-tmp
      ng-option-tmp
      let-status="item"
    >
      <i
        class="cqi-sm mr-5"
        [ngClass]="getStatusIcon(status)"
      ></i>
      <span class="ng-value-label">{{ t(status) }}</span>
    </ng-template>
  </ng-select>
</ng-container>

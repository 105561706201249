(function () {
  'use strict';

  /**
   * Компонент-обёртка модального окна приглашения члена команды для AngularJS
   *
   * NOTE:
   *  Необходимо для того, чтобы была возможность открывать модальное окно приглашения члена команды в Angular
   *  и AngularJS.
   *
   * TODO:
   *  Удалить при полном переходе на Angular
   */

  angular
    .module('myApp.modals.memberInvitationModalWrapper')
    .component('cqMemberInvitationModalWrapper', cqMemberInvitationModalWrapper());

  function cqMemberInvitationModalWrapper() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // {TeamMember} modalWindowParams.currentTeamMember - Текущий член команды, который выполняет приглашение
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./member-invitation-modal-wrapper.html'),
    };
  }
})();

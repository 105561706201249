<section id="content">
  <div class="container-fluid limited-container">
    <cq-permission-denied *ngIf="!accessToTrackMasterJsCode.hasAccess"></cq-permission-denied>

    <div *ngIf="accessToTrackMasterJsCode.hasAccess">
      <nav
        class="mb-20"
        aria-label="breadcrumb"
      >
        <ol class="breadcrumb">
          <li
            class="breadcrumb-item active"
            aria-current="page"
          >
            {{ 'dataCollection.breadcrumbs.dataCollection' | transloco }}
          </li>
        </ol>
        <div>
          <span>{{ 'dataCollection.eventTypes.description.firstText' | transloco }}</span>
          <span
            class="dashed"
            [ngbTooltip]="'dataCollection.eventTypes.description.hint.firstTooltip' | transloco"
            >{{ 'dataCollection.eventTypes.description.hint.firstText' | transloco }}</span
          >
          <span>{{ 'dataCollection.eventTypes.description.secondText' | transloco }}</span>
          <span
            class="dashed"
            [ngbTooltip]="'dataCollection.eventTypes.description.hint.secondTooltip' | transloco"
            >{{ 'dataCollection.eventTypes.description.hint.secondText' | transloco }}</span
          >
          <span [innerHTML]="'dataCollection.eventTypes.description.thirdText' | transloco"></span>
        </div>
      </nav>
      <div>
        <ul
          #nav="ngbNav"
          ngbNav
          [(activeId)]="currentTab"
          class="nav nav-tabs"
        >
          <li [ngbNavItem]="DATA_COLLECTION_TABS.TRACK_MASTER">
            <a ngbNavLink>{{ 'dataCollection.tabs.' + DATA_COLLECTION_TABS.TRACK_MASTER | transloco }}</a>
            <ng-template ngbNavContent>
              <cq-track-master
                class="card-body"
                [autoEvents]="autoEvents"
                [billingInfo]="billingInfo"
                [currentApp]="currentApp"
                [djangoUser]="djangoUser"
              ></cq-track-master>
            </ng-template>
          </li>

          <li [ngbNavItem]="DATA_COLLECTION_TABS.EVENT_TYPES">
            <a ngbNavLink>{{ 'dataCollection.tabs.' + DATA_COLLECTION_TABS.EVENT_TYPES | transloco }}</a>
            <ng-template ngbNavContent>
              <cq-event-types
                [currentApp]="currentApp"
                [eventTypes]="eventTypes"
              ></cq-event-types>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</section>

(function () {
  'use strict';

  angular
    .module('myApp.emailTemplates')
    .component('cqLightweightMessageEmailTemplate', cqLightweightMessageEmailTemplate());

  function cqLightweightMessageEmailTemplate() {
    return {
      bindings: {
        currentApp: '=', // приложение
        messageSender: '=', // отправитель письма
      }, //controller: 'CqLightweightEmailTemplatesController',
      controllerAs: 'vm',
      template: require('./lightweight-message-email-template.html'),
    };
  }
})();

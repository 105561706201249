import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider, ProductFeatureTextServiceProvider } from '@panel/app/ajs-upgraded-providers';

import { AccessDenialModalComponent } from './access-denial-modal.component';

@NgModule({
  declarations: [AccessDenialModalComponent],
  exports: [AccessDenialModalComponent],
  imports: [CommonModule],
  providers: [CarrotquestHelperProvider, ProductFeatureTextServiceProvider, NgbActiveModal],
})
export class AccessDenialModalModule {}

import { Inject, Injectable } from '@angular/core';
import { FederatedPointerEvent, IPointData } from 'pixi.js';
import { combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import { PIXI_APP, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { DestroyService } from '@panel/app/services';
import { CanvasInteractionService } from '@panel/app/services/canvas/common/interaction/canvas-interaction.service';
import {
  ExtendInteractionConfig,
  InteractionConfig,
} from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.types';
import { decreaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/decrease-ticker-max-fps.function';
import { increaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/increase-ticker-max-fps.function';

/**
 * Сервис для работы с интеракциями над view
 */
@Injectable()
export class InteractionService {
  private click: Subject<ExtendInteractionConfig> = new Subject<ExtendInteractionConfig>();
  private mouseOver: Subject<ExtendInteractionConfig> = new Subject<ExtendInteractionConfig>();
  private mouseOut: Subject<ExtendInteractionConfig> = new Subject<ExtendInteractionConfig>();
  private move: Subject<ExtendInteractionConfig> = new Subject<ExtendInteractionConfig>();
  private moveStart: Subject<void> = new Subject<void>();
  private moveEnd: Subject<void> = new Subject<void>();
  private pointerDown: Subject<ExtendInteractionConfig> = new Subject<ExtendInteractionConfig>();
  private pointerMove: Subject<ExtendInteractionConfig> = new Subject<ExtendInteractionConfig>();
  private pointerUp: Subject<ExtendInteractionConfig> = new Subject<ExtendInteractionConfig>();

  click$: Observable<ExtendInteractionConfig> = this.click.asObservable();
  mouseOver$: Observable<ExtendInteractionConfig> = this.mouseOver.asObservable();
  mouseOut$: Observable<ExtendInteractionConfig> = this.mouseOut.asObservable();
  move$: Observable<ExtendInteractionConfig> = this.move.asObservable();
  moveStart$: Observable<void> = this.moveStart.asObservable();
  moveEnd$: Observable<void> = this.moveStart.asObservable();
  pointerDown$: Observable<ExtendInteractionConfig> = this.pointerDown.asObservable();
  pointerMove$: Observable<ExtendInteractionConfig> = this.pointerMove.asObservable();
  pointerUp$: Observable<ExtendInteractionConfig> = this.pointerUp.asObservable();

  constructor(
    private readonly destroyService$: DestroyService,
    private readonly canvasInteractionService: CanvasInteractionService,
    @Inject(PIXI_APP)
    public readonly pixiApp: PixiApplication,
  ) {}

  /**
   * Делает view интерактивным
   *
   * @param interactionConfig - Конфигурация интеракции
   */
  makeInteractive(interactionConfig: InteractionConfig): void {
    let { view } = interactionConfig;

    view.graphicContainer.eventMode = 'static';
    view.graphicContainer.cursor = 'pointer';

    this.initInteractiveObservers(interactionConfig);
  }

  /**
   * Делает view передвигаемым
   *
   * @param interactionConfig - Конфигурация интеракции
   */
  makeMovable(interactionConfig: InteractionConfig): void {
    let { view } = interactionConfig;

    view.graphicContainer.eventMode = 'static';
    view.graphicContainer.cursor = 'pointer';

    this.initMoveObservers(interactionConfig);
  }

  /**
   * Инициализирует наблюдателей за интеракциями
   *
   * @param inputInteraction - Конфигурация интеракции
   */
  initInteractiveObservers(inputInteraction: InteractionConfig): void {
    let { view } = inputInteraction;

    fromEvent<FederatedPointerEvent>(view.graphicContainer, 'pointerdown')
      .pipe(
        switchMap(() =>
          fromEvent<FederatedPointerEvent>(view.graphicContainer, 'pointerup').pipe(takeUntil(this.move$), take(1)),
        ),
      )
      .subscribe((event: FederatedPointerEvent) => {
        this.click.next({
          event,
          ...inputInteraction,
        });
      });

    fromEvent<FederatedPointerEvent>(view.graphicContainer, 'mouseover')
      .pipe(
        takeUntil(this.destroyService$),
        filter((elem) => elem.nativeEvent.target instanceof HTMLCanvasElement),
      )
      .subscribe((event: FederatedPointerEvent) => {
        this.mouseOver.next({
          event,
          ...inputInteraction,
        });
      });

    fromEvent<FederatedPointerEvent>(view.graphicContainer, 'mouseout')
      .pipe(takeUntil(this.destroyService$))
      .subscribe((event: FederatedPointerEvent) => {
        this.mouseOut.next({
          event,
          ...inputInteraction,
        });
      });

    fromEvent<FederatedPointerEvent>(view.graphicContainer, 'pointerdown')
      .pipe(takeUntil(this.destroyService$))
      .subscribe((event: FederatedPointerEvent) => {
        this.pointerDown.next({
          event,
          ...inputInteraction,
        });
      });

    fromEvent<FederatedPointerEvent>(view.graphicContainer, 'pointermove')
      .pipe(takeUntil(this.destroyService$))
      .subscribe((event: FederatedPointerEvent) => {
        this.pointerMove.next({
          event,
          ...inputInteraction,
        });
      });

    fromEvent<FederatedPointerEvent>(view.graphicContainer, 'pointerup')
      .pipe(takeUntil(this.destroyService$))
      .subscribe((event: FederatedPointerEvent) => {
        this.pointerUp.next({
          event,
          ...inputInteraction,
        });
      });
  }

  /**
   * Инициализирует наблюдателей за передвижениями
   *
   * @param inputInteraction - Конфигурация интеракции
   */
  initMoveObservers(inputInteraction: InteractionConfig): void {
    let { view } = inputInteraction;
    let moveStartPosition: IPointData | null = null;

    fromEvent<FederatedPointerEvent>(view.graphicContainer, 'pointerdown')
      .pipe(takeUntil(this.destroyService$), increaseTickerMaxFPS(this.pixiApp))
      .subscribe((event: FederatedPointerEvent) => {
        event.stopPropagation();

        moveStartPosition = view.graphicContainer.toLocal(event.global);
        this.moveStart.next();
      });

    combineLatest([
      fromEvent<FederatedPointerEvent>(view.graphicContainer, 'pointermove').pipe(
        switchMap(() => this.canvasInteractionService.pointerMove$),
      ),
      this.canvasInteractionService.moved$.pipe(startWith(null)),
    ])
      .pipe(
        takeUntil(this.destroyService$),
        map(([event, _]) => event),
      )
      .subscribe((event: FederatedPointerEvent) => {
        if (moveStartPosition) {
          let moveProcessPosition = event.getLocalPosition(view.graphicContainer.parent);

          view.graphicContainer.position.set(
            moveProcessPosition.x - moveStartPosition.x,
            moveProcessPosition.y - moveStartPosition.y,
          );

          this.move.next({
            event,
            ...inputInteraction,
          });
        }
      });

    fromEvent<FederatedPointerEvent>(view.graphicContainer, 'pointerup')
      .pipe(takeUntil(this.destroyService$), decreaseTickerMaxFPS(this.pixiApp))
      .subscribe((event: FederatedPointerEvent) => {
        moveStartPosition = null;
        this.moveEnd.next();
      });
  }
}

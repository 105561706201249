<ng-container *transloco="let t">
  <div
    *ngIf="actionForm"
    class="flex flex-col"
  >
    <div
      class="form-group"
      [cqShowError]="actionForm.controls.body"
    >
      <label class="font-weight-normal small"> {{ t('chatBot.actionButton.buttonTextInput.label') }}: </label>
      <input
        class="form-control"
        name="button"
        type="text"
        [formControl]="actionForm.get('body')"
        placeholder="{{ t('chatBot.actionButton.buttonTextInput.placeholder') }}"
      />
      <cq-validation-messages [control]="actionForm.get('body')">
        <cq-validation-message errorName="whitespace">
          {{ t('chatBot.actionButton.buttonTextInput.errors.required') }}
        </cq-validation-message>
        <cq-validation-message errorName="required">
          {{ t('chatBot.actionButton.buttonTextInput.errors.required') }}
        </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('chatBot.actionButton.buttonTextInput.errors.maxLength', { maxLength: BUTTON_TEXT_MAX_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <cq-bot-action-next-branch-selector
      [formControl]="actionForm.controls.nextBranchLinkId!"
      [canSelectConditionAsNextBranch]="true"
      [chatBotType]="chatBotType"
      [chatBotId]="chatBotId"
      [nextBranchOptions]="targetBranchOptions"
      (branchSelect)="onChangeBranch($event)"
      (addBranchClick)="branchCreate.next($event)"
    ></cq-bot-action-next-branch-selector>
    <div
      *ngIf="hasAccessToChatBotButtonLinkFeature"
      class="mt-15"
    >
      <cq-switch-new [formControl]="enableUrlControl">
        <ng-template
          #trueText
          #falseText
        >
          {{ t('chatBot.actionButton.enableUrlSwitch.text') }}
        </ng-template>
      </cq-switch-new>
      <ng-container *ngIf="enableUrlControl.value">
        <div
          class="form-group has-feedback mt-10 mb-0"
          [cqShowError]="hrefControl"
        >
          <input
            class="form-control"
            type="text"
            (click)="onClickHrefControl()"
            [formControl]="hrefControl"
            placeholder="{{ t('chatBot.actionButton.hrefInput.placeholder') }}"
          />
          <span
            *ngIf="!isTelegramBot"
            class="form-control-feedback auto-pointer-events"
          >
            <i
              class="cqi-sm cqi-question-circle"
              [ngbTooltip]="tooltipTemplate"
              container="body"
              placement="top-right"
            ></i>
            <ng-template #tooltipTemplate>
              <div [innerHtml]="t('chatBot.actionButton.hrefInput.tooltip')"></div>
            </ng-template>
          </span>

          <cq-validation-messages [control]="hrefControl">
            <cq-validation-message errorName="href">
              {{ t('chatBot.actionButton.hrefInput.errors.href') }}
            </cq-validation-message>
            <cq-validation-message errorName="whitespace">
              {{ t('chatBot.actionButton.hrefInput.errors.required') }}
            </cq-validation-message>
            <cq-validation-message errorName="required">
              {{ t('chatBot.actionButton.hrefInput.errors.required') }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
        <div
          *ngIf="!isTelegramBot"
          class="mt-5 small"
        >
          <span>{{ t('chatBot.actionButton.urlTarget.openLink') }}</span>
          <span
            class="dashed text-primary cursor-pointer"
            (click)="changeUrlType()"
          >
            {{ t('chatBot.actionButton.urlTarget.' + targetControl.value) }}
          </span>
        </div>
        <div
          *ngIf="isIntegrationWithAuthLink | async as integrationType"
          class="mt-5 small"
        >
          {{
            t('chatBot.actionButton.integrationWithAuthLink', {
              integrationTypeName: integrationTypeName(integrationType)
            })
          }}
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

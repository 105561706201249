import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { MessageFiltersModule } from '@panel/app/partials/message-editor/message-filters/message-filters.module';
import { UserTagModule } from '@panel/app/partials/user-tag/user-tag.module';

import { OverlayHostDirective } from './directives/overlay-host.directive';
import { TcBlockOnboardingOverlayComponent } from './overlays/tc-block-onboarding-overlay/tc-block-onboarding-overlay.component';
import { TcConnectionPointOverlayComponent } from './overlays/tc-connection-point-overlay/tc-connection-point-overlay.component';
import { TcConnectionPointOverlayModule } from './overlays/tc-connection-point-overlay/tc-connection-point-overlay.module';
import { TcSendingConditionOverlayComponent } from './overlays/tc-sending-condition-overlay/tc-sending-condition-overlay.component';
import { TcTypeActionsOverlayComponent } from './overlays/tc-type-actions-overlay/tc-type-actions-overlay.component';
import { TriggerChainOverlayComponent } from './trigger-chain-overlay.component';

@NgModule({
  declarations: [
    TriggerChainOverlayComponent,
    OverlayHostDirective,
    TcTypeActionsOverlayComponent,
    TcBlockOnboardingOverlayComponent,
    TcSendingConditionOverlayComponent,
  ],
  exports: [TriggerChainOverlayComponent, TcConnectionPointOverlayComponent, TcSendingConditionOverlayComponent],
  imports: [
    CommonModule,
    MessageFiltersModule,
    NgbDropdownModule,
    NgbPopoverModule,
    NgbTooltipModule,
    TcConnectionPointOverlayModule,
    TranslocoModule,
    UserTagModule,
  ],
})
export class TriggerChainOverlayModule {}

<ng-container *transloco="let t; read: 'writePropertySelectorComponent'">
  <div>
    <div class="d-flex align-items-center mb-10">
      <strong>{{ t('heading') }}</strong>
    </div>
    <cq-property-selector
      [currentApp]="currentApp"
      [formControl]="control"
      [(properties)]="properties"
    ></cq-property-selector>
  </div>
</ng-container>

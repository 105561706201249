(function () {
  'use strict';

  angular
    .module('myApp.modals.doubleOptInEmailPreview')
    .controller('DoubleOptInEmailPreviewModalController', DoubleOptInEmailPreviewModalController);

  function DoubleOptInEmailPreviewModalController(currentApp, logo, messageSender) {
    var vm = this;

    vm.currentApp = currentApp;
    vm.logo = logo;
    vm.messageSender = messageSender;
  }
})();

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('EmailController', EmailController);

  function EmailController($translate, PROJECT_NAME, integration) {
    var vm = this;

    vm.$translate = $translate;
    vm.integration = integration;
    vm.PROJECT_NAME = PROJECT_NAME;
  }
})();

<ng-container *transloco="let t; read: 'searchComponent'">
  <div class="form-group has-feedback has-feedback-left m-0">
    <span class="form-control-feedback">
      <i class="cqi-sm cqi-search text-muted"></i>
    </span>
    <input
      class="form-control"
      autocomplete="off"
      [formControl]="control"
      [placeholder]="t('placeholder')"
    />
  </div>
</ng-container>

/**
 * Фильтр, обрабатывающий некорректные значения
 */
(function () {
  'use strict';

  angular.module('myApp.filters.handleIncorrectValue').filter('handleIncorrectValue', handleIncorrectValue);

  function handleIncorrectValue() {
    return handleIncorrectValueFilter;

    /**
     * Обработчик некорректных значений undefined, null, NaN, Infinity и -Infinity
     *
     * @param {*} value Значение на входе
     * @param {*=} defaultValue Возвращаемое значение если value некорректное
     * @returns {*}
     */
    function handleIncorrectValueFilter(value, defaultValue) {
      if (
        angular.isUndefined(value) ||
        value == null ||
        (angular.isNumber(value) && (isNaN(value) || !isFinite(value)))
      ) {
        return angular.isDefined(defaultValue) ? defaultValue : null;
      } else {
        return value;
      }
    }
  }
})();

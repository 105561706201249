<ng-container *transloco="let t; read: 'creativityLevelControlComponent'">
  <h4 class="mb-5">{{ t('heading') }}</h4>
  <div class="mb-10 text-secondary">{{ t('description') }}</div>
  <ngx-slider
    [formControl]="control"
    [options]="sliderOptions"
  ></ngx-slider>
  <div class="d-flex justify-content-between">
    <span>{{ t('slider.labels.accuracy') }}</span>
    <span>{{ t('slider.labels.creativity') }}</span>
  </div>
  <ngb-alert
    *ngIf="control.value >= 80"
    class="mt-20"
    [dismissible]="false"
    type="warning"
  >
    {{ t('warning') }}
  </ngb-alert>
</ng-container>

import { firstValueFrom } from 'rxjs';

import { Billing } from '@http/billing/billing';
import { RequisitesHttpService } from '@http/requisites/http/requisites-http.service';
import { RequisitesDto } from '@http/requisites/requisites.types';

/** Класс для работы с реквизитами компании */
export class Requisites {
  /** Юридический адрес */
  address: string | null = null;
  /** ФИО руководителя */
  fio: string | null = null;
  /** ИНН */
  inn: string | null = null;
  /** КПП */
  kpp: string | null = null;
  /** Название организации */
  name: string | null = null;
  /** ОГРН */
  ogrn: string | null = null;
  /** Почтовый адрес */
  postalAddress: string | null = null;

  constructor(private readonly httpService: RequisitesHttpService, private readonly dto: RequisitesDto) {
    if (dto.organization_address) {
      this.address = dto.organization_address;
    }

    if (dto.organization_fio) {
      this.fio = dto.organization_fio;
    }

    if (dto.organization_inn) {
      this.inn = dto.organization_inn;
    }

    if (dto.organization_kpp) {
      this.kpp = dto.organization_kpp;
    }

    if (dto.organization_name) {
      this.name = dto.organization_name;
    }

    if (dto.organization_ogrn) {
      this.ogrn = dto.organization_ogrn;
    }

    if (dto.organization_postal_address) {
      this.postalAddress = dto.organization_postal_address;
    }
  }

  /**
   * Смена реквизитов компании
   *
   * @param requisites Реквизиты компании
   */
  editRequisites(requisites: Requisites): Promise<Billing> {
    return firstValueFrom(this.httpService.editRequisites(requisites));
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { LOG_TYPE } from '@http/logs/logs.constants';
import { ActionsMetaData, Log } from '@http/logs/logs.types';

@Component({
  selector: 'cq-member-actions-table',
  templateUrl: './member-actions-table.component.html',
  styleUrls: ['./member-actions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberActionsTableComponent {
  @Input()
  logs!: Log[];

  readonly projectName = environment.projectName;

  constructor(private readonly translocoService: TranslocoService) {}

  isEmail(val: string): boolean {
    return val.includes('@');
  }

  getActionsText(type: LOG_TYPE, metaData: ActionsMetaData): string {
    const permissions = metaData.permissions
      ? this.translocoService.translate(`models.teamMember.permissions.${metaData.permissions}.name`)
      : '';
    const typeIntegration = metaData.type
      ? this.translocoService.translate(`models.integration.types.${metaData.type}.name`, {
          projectName: this.projectName,
        })
      : '';
    return this.translocoService.translate(`memberActionsTableComponent.table.body.actionsList.${type}`, {
      name: metaData.name,
      email: metaData.email,
      permissions: permissions,
      id: metaData.id,
      type: typeIntegration,
      apiTokenId: metaData.apiTokenId,
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TriggerChainOverlayModule } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/trigger-chain-overlay.module';
import { CanvasBaseService } from '@panel/app/services/canvas/common/base/canvas-base.service';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { TriggerChainCanvasActionsModule } from './components/actions/trigger-chain-canvas-actions.module';
import { TriggerChainCanvasComponent } from './trigger-chain-canvas.component';

@NgModule({
  declarations: [TriggerChainCanvasComponent],
  exports: [TriggerChainCanvasComponent],
  imports: [CommonModule, TriggerChainCanvasActionsModule, TriggerChainOverlayModule, LoaderModule, TranslocoModule],
  providers: [CanvasBaseService],
})
export class TriggerChainCanvasModule {}

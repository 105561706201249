import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CaseStyleHelper } from '@panel/app/services';
import { IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Преобразует всё содержимое body запроса в _underscore
 * Не путать с NullRequestParamsInterceptor, он преобразует query-параметры
 * Так делается, поскольку внутри админки все параметры используются в camelCase, а бэкенд присылает результаты и принимает на вход всё в _underscore
 */
@Injectable()
export class RequestBodyToUnderscoreInterceptor implements HttpInterceptor {
  constructor(private caseStyleHelper: CaseStyleHelper) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    const ignoreFields = request.context.get(IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS);

    // Если передан true, то пропускаем преобразование
    if (ignoreFields === true) {
      return next.handle(request);
    }

    let ignoredFields: string[] = Array.isArray(ignoreFields) ? ignoreFields.filter((v) => !!v) : [];

    request = request.clone({
      body: this.caseStyleHelper.keysToUnderscore(request.body, ignoredFields),
    });

    return next.handle(request);
  }
}

(function () {
  'use strict';

  require('./dashboard.controller');

  angular.module('myApp.dashboard').component('cqDashboard', cqDashboard());

  function cqDashboard() {
    return {
      bindings: {
        activeMessagesAmounts: '=', // Массив с количеством активных триггерных сообщений по типам
        appBlocks: '=', // данные о блокировках приложения
        billingInfo: '=', // информация о биллинге
        chatSettings: '=', // Настройки чата
        currentApp: '=', // приложение
        activeLeadBotsAmount: '=', // Информация об активных Lead ботах
        djangoUser: '=', // текущий пользователь
        djangoUserTempData: '=', // Временные данные django-пользователя
        funnels: '=', // Воронки
        knowledgeBase: '=', // Настройки БЗ
        messageSenders: '=', // Список отправителей
        properties: '=', // Свойства пользователей, типы событий, свойства типов событий
        readyAutoMessagesTemplates: '=', // готовые сценарии
        templates: '=', // Шаблоны чат-ботов
      },
      controller: 'CqDashboardController',
      controllerAs: 'vm',
      template: require('./dashboard.html'),
    };
  }
})();

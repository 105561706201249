/**
 * Типов сообщений системного лога
 */
export const SYSTEM_LOG_MESSAGE_TYPES = {
  AMOCRM_DISABLED_AUTH: 'amocrm_disabled_auth', // ошибка в интеграции с amoCRM
  APP_EMAILS_BOUNCE_RATE_ALMOST_BAD: 'app_emails_bounce_rate_almost_bad', // предупреждение о блокировке емейл-рассылок из-за большого bounce
  APP_EMAILS_BOUNCE_RATE_NOT_GOOD: 'app_emails_bounce_rate_not_good', // предупреждение о блокировке емейл-рассылок из-за небольшого bounce
  APP_EMAILS_COMPLAINTS_RATE_ALMOST_BAD: 'app_emails_complaints_rate_almost_bad', // предупреждение о блокировке емейл-рассылок из-за большого количества жалоб
  APP_EMAILS_COMPLAINTS_RATE_NOT_GOOD: 'app_emails_complaints_rate_not_good', // предупреждение о блокировке емейл-рассылок из-за небольшого количества жалоб
  AUTO_MSG_BLOCKED_ALL_EMAILS: 'auto_msg_blocked_all_emails', // блокировака емейл-рассылок из-за подозрительной активности
  AUTO_MSG_BLOCKED_BY_HARD_QUOTA: 'auto_msg_blocked_by_hard_quota', // блокировака автосообщений при превышении хардквоты
  BITRIX24_DISABLED_AUTH: 'bitrix24_disabled_auth', // интеграция с Bitrix отключена из-за слетевшей авторизации
  CONVERSATION_TAG_REMOVED: 'conversation_tag_removed', // удаление тега диалогов
  DIALOGFLOW_DEPRECATED_VER: 'dialogflow_deprecated_ver', //
  DIALOGFLOW_DISABLED_AUTH: 'dialogflow_disabled_auth', //
  EMAILS_BLOCKED_BY_BOUNCE_RATE: 'emails_blocked_by_bounce_rate', // блокировака емейл-рассылок из-за bounce
  EMAILS_BLOCKED_BY_COMPLAINT_RATE: 'emails_blocked_by_complaint_rate', // блокировака емейл-рассылок из-за жалоб
  EXPORT_FINISHED: 'export_finished', // окончание экспорта
  FACEBOOK_DISABLED_AUTH: 'facebook_disabled_auth', // интеграция с Facebook отключена из-за слетевшей авторизации
  MANUAL_MSG_CANCELED_30_DAYS: 'manual_msg_canceled_30_days', // отмена рассылки, если она находится в паузе более 30 дней
  MANUAL_MSG_ERROR: 'manual_msg_error', // ошибка ручной отправки
  MANUAL_MSG_FINISHED: 'manual_msg_finished', // окончание ручной отправки
  RETAILCRM_DISABLED_AUTH: 'retailcrm_disabled_auth', // окончание ручной отправки
  SENDSAY_DISABLED_AUTH: 'sendsay_disabled_auth', // интеграция с Sendsay отключена из-за слетевшей авторизации
  TELEGRAM_DISABLED_AUTH: 'telegram_disabled_auth', // интеграция с Telegram отключена из-за протухшего токена бота
  TG_AUTO_MESSAGE_DISABLED: 'tg_auto_message_disabled', // интеграция с Telegram отключена из-за протухшего токена бота
  USERS_IMPORT_FINISHED: 'users_import_finished', // окончание импорта
  VK_DISABLED_AUTH: 'vk_disabled_auth', // интеграция с VK отключена из-за слетевшей авторизации
  WEBHOOK_INTEGR_CRIT: 'webhook_integr_crit', // ошибка при отключении интеграции с вебхуком
  WEBHOOK_INTEGR_WARN: 'webhook_integr_warn', // предупреждение о потенциальном отключении интеграции
  WHATSAPP_EDNA_DISABLED_AUTH: 'whatsapp_edna_disabled_auth', // интеграция с WhatsApp (edna) отключена из-за невалидного токена
};

/**
 * Разделение сообщений лога на классы
 * Эти классы будут использоваться в html, поэтому их названия соответствуют бутстраповским
 */
export const SYSTEM_LOG_MESSAGE_CLASSES = {
  [SYSTEM_LOG_MESSAGE_TYPES.AMOCRM_DISABLED_AUTH]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.APP_EMAILS_BOUNCE_RATE_ALMOST_BAD]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.APP_EMAILS_BOUNCE_RATE_NOT_GOOD]: 'warning',
  [SYSTEM_LOG_MESSAGE_TYPES.APP_EMAILS_COMPLAINTS_RATE_ALMOST_BAD]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.APP_EMAILS_COMPLAINTS_RATE_NOT_GOOD]: 'warning',
  [SYSTEM_LOG_MESSAGE_TYPES.AUTO_MSG_BLOCKED_ALL_EMAILS]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.AUTO_MSG_BLOCKED_BY_HARD_QUOTA]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.BITRIX24_DISABLED_AUTH]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.CONVERSATION_TAG_REMOVED]: 'success',
  [SYSTEM_LOG_MESSAGE_TYPES.DIALOGFLOW_DEPRECATED_VER]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.DIALOGFLOW_DISABLED_AUTH]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.EMAILS_BLOCKED_BY_BOUNCE_RATE]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.EMAILS_BLOCKED_BY_COMPLAINT_RATE]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.EXPORT_FINISHED]: 'success',
  [SYSTEM_LOG_MESSAGE_TYPES.FACEBOOK_DISABLED_AUTH]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.MANUAL_MSG_CANCELED_30_DAYS]: 'warning',
  [SYSTEM_LOG_MESSAGE_TYPES.MANUAL_MSG_ERROR]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.MANUAL_MSG_FINISHED]: 'success',
  [SYSTEM_LOG_MESSAGE_TYPES.RETAILCRM_DISABLED_AUTH]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.SENDSAY_DISABLED_AUTH]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.TELEGRAM_DISABLED_AUTH]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.TG_AUTO_MESSAGE_DISABLED]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.USERS_IMPORT_FINISHED]: 'success',
  [SYSTEM_LOG_MESSAGE_TYPES.VK_DISABLED_AUTH]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.WEBHOOK_INTEGR_CRIT]: 'danger',
  [SYSTEM_LOG_MESSAGE_TYPES.WEBHOOK_INTEGR_WARN]: 'warning',
  [SYSTEM_LOG_MESSAGE_TYPES.WHATSAPP_EDNA_DISABLED_AUTH]: 'danger',
};

/**
 * Получение иконок для каждого типа сообщений системного лога
 */
export const SYSTEM_LOG_MESSAGE_ICONS = {
  [SYSTEM_LOG_MESSAGE_TYPES.AMOCRM_DISABLED_AUTH]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.APP_EMAILS_BOUNCE_RATE_ALMOST_BAD]: 'cqi-tachometer',
  [SYSTEM_LOG_MESSAGE_TYPES.APP_EMAILS_BOUNCE_RATE_NOT_GOOD]: 'cqi-tachometer',
  [SYSTEM_LOG_MESSAGE_TYPES.APP_EMAILS_COMPLAINTS_RATE_ALMOST_BAD]: 'cqi-tachometer',
  [SYSTEM_LOG_MESSAGE_TYPES.APP_EMAILS_COMPLAINTS_RATE_NOT_GOOD]: 'cqi-tachometer',
  [SYSTEM_LOG_MESSAGE_TYPES.AUTO_MSG_BLOCKED_ALL_EMAILS]: 'cqi-envelope-ban',
  [SYSTEM_LOG_MESSAGE_TYPES.AUTO_MSG_BLOCKED_BY_HARD_QUOTA]: 'cqi-envelope-ban',
  [SYSTEM_LOG_MESSAGE_TYPES.BITRIX24_DISABLED_AUTH]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.CONVERSATION_TAG_REMOVED]: 'cqi-trash',
  [SYSTEM_LOG_MESSAGE_TYPES.DIALOGFLOW_DEPRECATED_VER]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.DIALOGFLOW_DISABLED_AUTH]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.EMAILS_BLOCKED_BY_BOUNCE_RATE]: 'cqi-envelope-ban',
  [SYSTEM_LOG_MESSAGE_TYPES.EMAILS_BLOCKED_BY_COMPLAINT_RATE]: 'cqi-envelope-ban',
  [SYSTEM_LOG_MESSAGE_TYPES.EXPORT_FINISHED]: 'cqi-export',
  [SYSTEM_LOG_MESSAGE_TYPES.FACEBOOK_DISABLED_AUTH]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.MANUAL_MSG_CANCELED_30_DAYS]: 'cqi-envelope-ban',
  [SYSTEM_LOG_MESSAGE_TYPES.MANUAL_MSG_ERROR]: 'cqi-envelope-ban',
  [SYSTEM_LOG_MESSAGE_TYPES.MANUAL_MSG_FINISHED]: 'cqi-envelope-check',
  [SYSTEM_LOG_MESSAGE_TYPES.RETAILCRM_DISABLED_AUTH]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.SENDSAY_DISABLED_AUTH]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.TELEGRAM_DISABLED_AUTH]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.TG_AUTO_MESSAGE_DISABLED]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.USERS_IMPORT_FINISHED]: 'cqi-import',
  [SYSTEM_LOG_MESSAGE_TYPES.VK_DISABLED_AUTH]: 'cqi-vk',
  [SYSTEM_LOG_MESSAGE_TYPES.WEBHOOK_INTEGR_CRIT]: 'cqi-lock',
  [SYSTEM_LOG_MESSAGE_TYPES.WEBHOOK_INTEGR_WARN]: 'cqi-triangle-exclamation-o',
  [SYSTEM_LOG_MESSAGE_TYPES.WHATSAPP_EDNA_DISABLED_AUTH]: 'cqi-triangle-exclamation-o',
};

/**
 * Массив из типов сообщений системного лога, для которых нужно использовать messageFormat
 */
export const SYSTEM_LOG_MESSAGE_FORMAT = [
  SYSTEM_LOG_MESSAGE_TYPES.CONVERSATION_TAG_REMOVED,
  SYSTEM_LOG_MESSAGE_TYPES.USERS_IMPORT_FINISHED,
];

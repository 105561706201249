(function () {
  'use strict';

  require('./starter-guide-chat-settings.controller');

  angular.module('myApp.starterGuide').component('cqStarterGuideChatSettings', cqStarterGuideChatSettings());

  function cqStarterGuideChatSettings() {
    return {
      bindings: {
        alertText: '=', // Текст алерта
        chatSettings: '=', // Настройки чата
        currentApp: '=', // Текущее приложение
        descriptionText: '=', // Текст описания
        isStepMade: '=', // Пройден ли шаг
      },
      controller: 'CqStarterGuideChatSettingsController',
      controllerAs: 'vm',
      template: require('./starter-guide-chat-settings.html'),
    };
  }
})();

import { InjectionToken } from '@angular/core';

/** Тип данных для MODAL_DATA_TOKEN */
export type ModalData = {
  /** Заголовок */
  title: string;

  /** Компонент */
  component: any;

  /** Inputs */
  inputs?: Record<string, any>;

  /** Outputs */
  outputs?: Record<string, any>;
};

/** Токен для передачи данных через DI в модальное окно */
export const MODAL_DATA_TOKEN = new InjectionToken<ModalData>('Modal data provider');

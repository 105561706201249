import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgxDropzoneChangeEvent, NgxDropzoneComponent } from 'ngx-dropzone';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { EXTENSION_DELIMITER, FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

@Component({
  selector: 'cq-file-upload-dropzone[accept][control]',
  templateUrl: './file-upload-dropzone.component.html',
  styleUrls: ['./file-upload-dropzone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadDropzoneComponent {
  /** Список допустимых MIME-типов */
  @Input() accept!: string;
  /** Контрол, в который загружается файл */
  @Input() control!: GenericFormControl<File[]>;
  /** Колбэк на прикрепление файла */
  @Input() onFileChangeFn: EventEmitter<File> = new EventEmitter<File>();
  /** Колбэк на удаление файла */
  @Input() onRemoveFileFn: EventEmitter<File> = new EventEmitter<File>();
  /** Максимальный размер загружаемого файла */
  @Input() maxFileSize: number = 10 * 1024 * 1024;
  /** Возможность загрузки нескольких файлов */
  @Input() multiple: boolean = false;

  @ViewChild('ngxDropzone') ngxDropzone!: NgxDropzoneComponent;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly fileHelperService: FileHelperService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  get files(): File[] {
    return this.control.value;
  }

  /**
   * Колбэк на прикрепление файла
   *
   * @param event
   */
  onFileChange(event: NgxDropzoneChangeEvent) {
    // rejectedFiles сюда добавлены, чтоб до клиента все равно доходила ошибка
    const files = [...event.addedFiles, ...event.rejectedFiles];

    if (!files) {
      return;
    }

    // TODO Пока компонент сделан для загрузки только одного файла. В будущем переделать
    const file = files[0];

    const acceptableExtensionsAsArray = this.accept.split(EXTENSION_DELIMITER);
    if (this.accept && !this.fileHelperService.isFileHasExtensions(file.name, acceptableExtensionsAsArray)) {
      const message = this.translocoService.translate('fileUploadDropzone.toasts.fileExtension', {
        availableExtensions: this.accept,
      });
      this.toastService.danger(message);

      return;
    }

    if (file.size > this.maxFileSize) {
      const message = this.translocoService.translate('fileUploadDropzone.toasts.maxFileSize', {
        maxFileSize: this.maxFileSize / 1024 / 1024,
      });
      this.toastService.danger(message);

      return;
    }

    this.readFile(file)
      .then(
        function (file: File & { url?: string | ArrayBuffer | null }, fileContents: string | ArrayBuffer | null) {
          file.url = fileContents;
        }.bind(this, file),
      )
      .then(() => {
        this.control.setValue([...this.files, ...files]);

        this.onFileChangeFn.emit(file);

        this.cdr.markForCheck();
      });
  }

  /**
   * Удаление файла
   *
   * @param file Удаляемый файл
   */
  removeFile(file: File): void {
    let newFilesArray = this.files;
    const fileIndex = newFilesArray.indexOf(file);
    newFilesArray.splice(fileIndex, 1);
    this.control.setValue(newFilesArray);

    this.onRemoveFileFn.emit(file);
  }

  private readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        return resolve(e.target!.result!);
      };

      reader.onerror = (e) => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      reader.readAsDataURL(file);
    });
  }
}

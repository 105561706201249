import { Inject, Injectable } from '@angular/core';
import { FederatedPointerEvent } from '@pixi/events/lib/FederatedPointerEvent';
import { Viewport } from 'pixi-viewport';
import { ClickedEvent, MovedEvent, ZoomedEvent } from 'pixi-viewport/dist/types';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import { PIXI_APP, PIXI_VIEWPORT, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { DestroyService } from '@panel/app/services';
import { decreaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/decrease-ticker-max-fps.function';
import { increaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/increase-ticker-max-fps.function';

/**
 * Сервис для работы с интеракциями на canvas
 */
@Injectable()
export class CanvasInteractionService {
  private pointerMoveSubj: Subject<FederatedPointerEvent> = new Subject();

  private clickSubj: Subject<ClickedEvent> = new Subject();

  private hoverSubj: Subject<FederatedPointerEvent> = new Subject();

  private pointerOutSubj: Subject<FederatedPointerEvent> = new Subject();

  private movedSubj: Subject<void> = new Subject<void>();

  private pointerUp: Subject<FederatedPointerEvent> = new Subject();

  private pointerDown: Subject<FederatedPointerEvent> = new Subject();

  private zoomed: Subject<ZoomedEvent> = new Subject();

  pointerMove$ = this.pointerMoveSubj.asObservable();

  pointerOut$ = this.pointerOutSubj.asObservable();

  click$ = this.clickSubj.asObservable();

  moved$ = this.movedSubj.asObservable();

  hover$ = this.hoverSubj.asObservable();

  pointerUp$ = this.pointerUp.asObservable();

  pointerDown$ = this.pointerDown.asObservable();

  zoomed$ = this.zoomed.asObservable();

  constructor(
    private readonly destroy$: DestroyService,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    @Inject(PIXI_APP)
    public readonly pixiApp: PixiApplication,
  ) {
    this.registerPointerMoveEvent();
    this.registerHoverEvent();
    this.registerClickEvent();
    this.registerPointerUp();
    this.registerPointerOut();
    this.registerPointerDown();
    this.registerMoved();
    this.registerZoomed();
  }

  private registerPointerMoveEvent() {
    fromEvent<FederatedPointerEvent>(this.viewport, 'pointermove')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: FederatedPointerEvent) => {
        this.pointerMoveSubj.next(event);
      });
  }

  private registerHoverEvent() {
    this.pointerMove$
      .pipe(
        takeUntil(this.destroy$),
        // без этого мутируется оригинальный объект и distinctUntilChanged не работает
        map((event) => [event.target, event] as const),
        distinctUntilChanged(([t1, _], [t2, __]) => {
          return t1 === t2;
        }),
        map(([_, event]) => event),
        filter((event) => {
          return event.target === this.viewport;
        }),
      )
      .subscribe((event: FederatedPointerEvent) => {
        this.hoverSubj.next(event);
      });
  }

  private registerClickEvent() {
    fromEvent<ClickedEvent>(this.viewport, 'clicked')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.clickSubj.next(event);
      });
  }

  private registerPointerDown() {
    fromEvent<FederatedPointerEvent>(this.viewport, 'pointerdown')
      .pipe(takeUntil(this.destroy$), increaseTickerMaxFPS(this.pixiApp))
      .subscribe((event: FederatedPointerEvent) => {
        this.pointerDown.next(event);
      });
  }

  private registerPointerUp() {
    fromEvent<FederatedPointerEvent>(this.viewport, 'pointerup')
      .pipe(takeUntil(this.destroy$), decreaseTickerMaxFPS(this.pixiApp))
      .subscribe((event: FederatedPointerEvent) => {
        this.pointerUp.next(event);
      });
  }

  private registerPointerOut() {
    fromEvent<FederatedPointerEvent>(this.viewport, 'pointerout')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: FederatedPointerEvent) => {
        this.pointerOutSubj.next(event);
      });
  }

  private registerMoved() {
    fromEvent<MovedEvent>(this.viewport, 'moved')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.movedSubj.next();
      });
  }

  private registerZoomed() {
    fromEvent<ZoomedEvent>(this.viewport, 'zoomed')
      .pipe(takeUntil(this.destroy$), increaseTickerMaxFPS(this.pixiApp))
      .subscribe((event) => this.zoomed.next(event));

    // Дополнительный подписчик нужен только для того, чтоб уменьшить maxFPS спустя некоторое время после после зума
    fromEvent(this.viewport, 'zoomed')
      .pipe(takeUntil(this.destroy$), debounceTime(100), decreaseTickerMaxFPS(this.pixiApp))
      .subscribe();
  }
}

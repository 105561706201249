import { animate, style, transition, trigger } from '@angular/animations';

export function slideLeft(animationSpeedMs: number, containerWidthPx: string) {
  return trigger('slideLeft', [
    transition(':enter', [
      style({ width: '0px' }),
      animate(`${animationSpeedMs}ms ease`, style({ width: containerWidthPx })),
    ]),
    transition(':leave', [
      style({ width: containerWidthPx }),
      animate(`${animationSpeedMs}ms ease`, style({ width: '0px' })),
    ]),
  ]);
}

(function () {
  'use strict';

  angular
    .module('myApp.conversationWithSelf')
    .constant('CONVERSATION_WITH_SELF_WINDOW_PARAMS', getConversationWithSelfWindowParams());

  /**
   * Параметры window диалога с самим собой
   *
   * @returns {Object}
   */
  function getConversationWithSelfWindowParams() {
    return {
      NAME: 'conversationWidthSelfWindow',
      WIDTH: 980,
      HEIGHT: 650,
    };
  }
})();

import {
  POPUP_BLOCK_TYPES,
  POPUP_BUTTON_BLOCK_TYPES,
} from '../../../../../app/services/popup-block/popup-block.constants';

import { MESSAGE_PART_TYPES } from '../../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupButtonBlockSettingsController', CqPopupButtonBlockSettingsController);

  function CqPopupButtonBlockSettingsController(
    $filter,
    $state,
    $translate,
    $uibModal,
    carrotquestHelper,
    eventTypeModel,
    featureModel,
    shortUuid,
    validationHelper,
  ) {
    var vm = this;

    /**
     * Типы ширины кнопки
     *
     * @type {{BY_BLOCK_WIDTH: String, BY_TEXT_WIDTH: String, FIXED: String}}
     */
    var BUTTON_WIDTH_TYPES = {
      BY_BLOCK_WIDTH: 'byBlockWidth', // по ширине блока
      BY_TEXT_WIDTH: 'byTextWidth', // по ширине текста
      FIXED: 'fixed', // фиксированная, в пикселях
    };

    /**
     * Вкладки
     *
     * @type {{CONTENT: String, STYLE: String}}
     */
    var TABS = {
      CONTENT: 'content', // содержимое
      STYLE: 'style', // стиль
    };

    /**
     * Уникальный идентификатор для корректной работы радиобаттонов при наличии нескольких компонентов на одной странице
     * Без этого индентификатора, ID у input'ов будут одинаковые, а это противоречит правилам HTML, поэтому при клике на чекбоксы/радио будут баги
     * Тот же самый баг будет с одинаковыми именами input[type="radio"], т.к. такие радио объединяются в radio group
     */
    const UUID = shortUuid();

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.$state = $state;
      vm.BUTTON_WIDTH_TYPES = BUTTON_WIDTH_TYPES;
      vm.buttonWidthType = getButtonWidthType(); // текущий тип ширины кнопки
      vm.checkErrors = handleError;
      vm.contentForm = null; // форма вкладки "Содержимое"
      vm.currentTab = TABS.CONTENT; // текущая вкладка
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.isExtraSettingsOpened = !!vm.popupBlockParams.eventType; // флаг раскрытия дополнительных настроек
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.openCreateEventTypeModal = openCreateEventTypeModal;
      vm.POPUP_BUTTON_BLOCK_TYPES = POPUP_BUTTON_BLOCK_TYPES;
      vm.setButtonWidthType = setButtonWidthType;
      vm.styleForm = null; // форма вкладки "Стиль"
      vm.TABS = TABS;
      vm.trackClickOnContent = trackClickOnContent;
      vm.trackClickOnStyle = trackClickOnStyle;
      vm.UUID = UUID;
      vm.validationHelper = validationHelper;

      vm.setErrorHandler && vm.setErrorHandler({ handler: handleError });

      /**
       * Получение текущего типа ширины кнопки
       *
       * @return {BUTTON_WIDTH_TYPES}
       */
      function getButtonWidthType() {
        if (vm.popupBlockParams.autoWidth) {
          return BUTTON_WIDTH_TYPES.BY_TEXT_WIDTH;
        } else if (vm.popupBlockParams.fullWidth) {
          return BUTTON_WIDTH_TYPES.BY_BLOCK_WIDTH;
        } else {
          return BUTTON_WIDTH_TYPES.FIXED;
        }
      }
    }

    function destroy() {
      // при уничтожении компонента нужно выставить значения необязательным полям, т.к. все поля должны быть заполнены
      if (vm.popupBlockParams.type == POPUP_BUTTON_BLOCK_TYPES.LINK && !vm.popupBlockParams.link) {
        vm.popupBlockParams.link = vm.currentApp.origin;
      }
    }

    /**
     * Функция открытия вкладки с ошибкой при отправке формы снаружи компонента
     */
    function handleError() {
      // искусственно сабмитим формы (с ngForm по-другому не получится) на случай, если родительская форма не была засабмичена, чтобы отображались ошибки
      vm.contentForm.$setSubmitted();
      vm.styleForm.$setSubmitted();

      // если открыта невалидная вкладка - переключаться на другую вкладку не нужно
      if (vm.contentForm.$invalid && vm.styleForm.$valid) {
        vm.currentTab = TABS.CONTENT;
      } else if (vm.styleForm.$invalid && vm.contentForm.$valid) {
        vm.currentTab = TABS.STYLE;
      }
    }

    /**
     * Открытие модалки создания нового события
     */
    function openCreateEventTypeModal() {
      var createEventTypeModal = $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        bindToController: true,
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('popupButtonBlockSettings.createEventTypeModal.heading'),
              body: $translate.instant('popupButtonBlockSettings.createEventTypeModal.body'),
              inputPlaceholder: $translate.instant('popupButtonBlockSettings.createEventTypeModal.inputPlaceholder'),
              inputErrorText: $translate.instant('popupButtonBlockSettings.createEventTypeModal.inputErrorText'),
              confirmButtonText: $translate.instant('popupButtonBlockSettings.createEventTypeModal.confirmButtonText'),
            };
          },
        },
      });

      createEventTypeModal.result.then(addEventType);

      function addEventType(eventTypeName) {
        var newEventType = eventTypeModel.getDefault(eventTypeName);

        // при добавлении нового события надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
        var existingEventTypeByName = $filter('filter')(vm.eventTypes, { name: newEventType.name }, true)[0];
        var existingEventTypeByPrettyName = $filter('filter')(
          vm.eventTypes,
          { prettyName: newEventType.prettyName },
          true,
        )[0];

        vm.popupBlockParams.eventType =
          existingEventTypeByName ||
          existingEventTypeByPrettyName ||
          (vm.eventTypes.push(newEventType) && newEventType);
      }
    }

    /**
     * Установка параметров кнопки в зависимости от типа ширины кнопки
     *
     * @param {BUTTON_WIDTH_TYPES} buttonWidthType Тип ширины кнопки
     */
    function setButtonWidthType(buttonWidthType) {
      switch (buttonWidthType) {
        case BUTTON_WIDTH_TYPES.BY_BLOCK_WIDTH:
          vm.popupBlockParams.autoWidth = false;
          vm.popupBlockParams.fullWidth = true;
          break;
        case BUTTON_WIDTH_TYPES.BY_TEXT_WIDTH:
          vm.popupBlockParams.autoWidth = true;
          vm.popupBlockParams.fullWidth = false;
          break;
        case BUTTON_WIDTH_TYPES.FIXED:
          vm.popupBlockParams.autoWidth = false;
          vm.popupBlockParams.fullWidth = false;
          break;
      }
    }

    /**
     * Трек клика на вкладку 'Содержимое'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnContent(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на содержимое блока', {
        Название: POPUP_BLOCK_TYPES.BUTTON,
      });
    }

    /**
     * Трек клика на вкладку 'Стиль'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnStyle(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на стиль блока', {
        Название: POPUP_BLOCK_TYPES.BUTTON,
      });
    }
  }
})();

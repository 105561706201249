/**
 * Компонент-обёртка для отображения модального окна завершения регистрации на Angular в AngularJS
 */

(function () {
  'use strict';

  angular
    .module('myApp.modals.quizSkipConfirmModalWrapper')
    .component('cqQuizSkipConfirmModalWrapper', cqQuizSkipConfirmModalWrapper());

  function cqQuizSkipConfirmModalWrapper() {
    return {
      bindings: {
        modalInstance: '=',
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./quiz-skip-confirm-modal-wrapper.html'),
    };
  }
})();

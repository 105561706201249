import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { RESIZE_OPTION_BOX, ResizeObserverService } from '@ng-web-apis/resize-observer';
import isEqual from 'lodash-es/isEqual';
import { FederatedPointerEvent, UPDATE_PRIORITY } from 'pixi.js';
import { Simple as SimpleCull } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';
import { fromEvent, interval, merge, Subject, timer } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  mergeMap,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ChatBotModel } from '@http/chat-bot/chat-bot.model';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { BotBlocksOverlayService } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-blocks-overlay.service';
import { BotBranchSelectService } from '@panel/app/pages/chat-bot/content/services/bot-branch-select.service';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ConnectionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { BadgeFactory } from '@panel/app/pages/chat-bot/content/services/factories/badge.factory';
import {
  filterAndExtractInstance,
  pickOnlyEvent,
} from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.helpers';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import {
  InteractionEvent,
  PIXI_INTERACTION_EVENT,
  PointerHorizontalPosition,
  PointerVerticalPosition,
} from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.types';
import { PIXI_CULL } from '@panel/app/pages/chat-bot/content/tokens';
import { BRANCH_VIEW_MAP, BranchViewMap } from '@panel/app/pages/chat-bot/content/tokens/branch-collection.token';
import { PIXI_APP, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens/pixi.token';
import { PIXI_VIEWPORT } from '@panel/app/pages/chat-bot/content/tokens/pixi-viewport.token';
import { BaseBadge, StartBadge } from '@panel/app/pages/chat-bot/content/views/badges';
import { Branch, CARD_WIDTH } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { ChatBotForm } from '@panel/app/pages/chat-bot/forms/bot.form';
import { DestroyService } from '@panel/app/services';
import { PixiStats } from '@panel/app/services/pixi/stats';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { decreaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/decrease-ticker-max-fps.function';
import { ZoomedEventData } from '@panel/app/shared/types/zoomed-event-data.type';

const BRANCH_INITIAL_INDENTS = {
  LEFT: 50,
  TOP: 30,
};

const BRANCH_POSITIONING_INDENTS = {
  VERTICAL: 40,
  HORIZONTAL: 50,
};

@Component({
  selector: 'cq-bot-canvas-editor[chatBotForm]',
  templateUrl: './bot-canvas-editor.component.html',
  styleUrls: ['./bot-canvas-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    ResizeObserverService,
    {
      provide: RESIZE_OPTION_BOX,
      useValue: 'border-box',
    },
  ],
})
export class BotCanvasEditorComponent<T extends CHAT_BOT_TYPE> implements OnInit, AfterViewInit, OnChanges {
  @Input()
  chatBotForm!: ChatBotForm<T>;

  @Output()
  branchCreated: EventEmitter<Branch> = new EventEmitter();

  @Output()
  branchSelect: EventEmitter<string | Branch> = new EventEmitter();

  @Output()
  zoomChange: EventEmitter<number> = new EventEmitter();

  @Output()
  startBadgeClick: Subject<void> = new Subject();

  @ViewChild('canvasWrap', { static: true })
  canvasWrapEl!: ElementRef<HTMLElement>;

  @ViewChild('optionsContent', { static: true })
  optionsContent!: ElementRef<HTMLElement>;
  /**
   * Показывать кнопки опций или нет
   */
  public isOptionsShow: boolean = false;

  /**
   * X координата кнопок опций
   */
  public optionsXCoords: number = 0;

  /**
   * Y координата кнопок опций
   */
  public optionsYCoords: number = 0;

  /**
   * ID таймаута для скрытия опций ветки
   * @private
   */
  private optionsTimeoutId?: ReturnType<typeof setTimeout>;

  /**
   * Hovered ветка
   * @private
   */
  private hoveredBranch: Branch | null = null;

  constructor(
    private readonly badgeFactory: BadgeFactory,
    private readonly botBranchSelectService: BotBranchSelectService,
    private readonly blocksOverlayService: BotBlocksOverlayService,
    public readonly changeDetectorRef: ChangeDetectorRef,
    private readonly connectionFactory: ConnectionFactory,
    private readonly destroy$: DestroyService,
    entries$: ResizeObserverService,
    private readonly interactionService: PixiInteractionService,
    private readonly ngZone: NgZone,
    pixiStats: PixiStats,
    private readonly scenariosHelper: BotScenariosHelper,
    private readonly transloco: TranslocoService,
    @Inject(BRANCH_VIEW_MAP)
    private canvasBranches: BranchViewMap,
    @Inject(DOCUMENT)
    private readonly document: Document,
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
    @Inject(PIXI_CULL)
    private readonly cull: SimpleCull,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
  ) {
    entries$.pipe(takeUntil(destroy$)).subscribe(() => {
      this.resize();
    });

    pixiApp.ticker.add(pixiStats.update.bind(pixiStats), null, UPDATE_PRIORITY.UTILITY);
  }

  ngOnInit(): void {
    this.initCanvas();
    this.initViewportListeners();
    this.initOptionListeners();
    this.initialConstructBot();

    this.initialConstructBadges();
    this.initBadgesListeners();

    this.addOverlaysToAllElements(this.chatBotForm);

    this.interactionService.click$
      .pipe(filterAndExtractInstance(PIXI_INTERACTION_EVENT.BRANCH), takeUntil(this.destroy$))
      .subscribe((branch) => {
        this.botBranchSelectService.next(branch);
      });

    this.scenariosHelper.interruptScenarioUpdated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.ngZone.run(() => this.changeDetectorRef.markForCheck());
    });
  }

  ngAfterViewInit() {
    this.resize();
    if (!this.chatBotForm.startedBranch) {
      return;
    }
    this.moveViewportCornerToBranch(this.chatBotForm.startBadge);
    // Костыль, чтоб заставить обновить обновить позиции оверлеев. Так как пикси вне зоны ответственности ангуляра,
    // пока хз как заставить без такого обновиться
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes.chatBotForm.firstChange &&
      !isEqual(changes.chatBotForm.currentValue, changes.chatBotForm.previousValue)
    ) {
      this.chatBotRerender();
    }
  }

  addOverlaysToAllElements(chatBotForm: ChatBotForm<any>) {
    chatBotForm.branches.forEach((branch) => {
      this.blocksOverlayService.addOverlayAbleElement(branch);
    });

    this.blocksOverlayService.addOverlayAbleElement(chatBotForm.interruptBadge);
    this.blocksOverlayService.addOverlayAbleElement(chatBotForm.startBadge);
  }

  resize() {
    const canvas = this.pixiApp.renderer.view;
    const w = this.canvasWrapEl.nativeElement.clientWidth;
    const h = this.canvasWrapEl.nativeElement.clientHeight;
    canvas.width = w;
    canvas.height = h;
    this.pixiApp.renderer.resize(w, h);
    this.viewport.resize(w, h);
  }

  /**
   * Перерисовывает канвас
   */
  public chatBotRerender() {
    this.canvasBranches.clear();
    this.initialConstructBot();
    this.initialConstructBadges();
  }

  /**
   * Рисуем контейнер ветки, "слушаем" нажатия на точку создания связи и момент,
   * когда отпускаешь ЛКМ поверх ветки (для того чтоб закрыть связь)
   *
   * @param branch
   * @param parentBranch
   *
   * @return созданную ветку
   */
  public addBranch(branch: Branch, parentBranch?: Branch): void {
    if (!this.viewport.getChildByName(branch.container.name!)) {
      this.viewport.addChild(branch.container);
      this.addBranchSubscriptions(branch);
    }
    this.canvasBranches.set(branch.linkId, branch);

    // добавляем связь, если была указана родительская ветка
    const parentCanvasBranch = this.canvasBranches.get(parentBranch?.linkId ?? '');
    if (parentCanvasBranch) {
      const x = parentCanvasBranch.container.x + parentCanvasBranch.width + BRANCH_POSITIONING_INDENTS.HORIZONTAL;
      branch.moveTo(x, parentCanvasBranch.container.y);
    }
    // Когда ветка создана, у нее координаты 0,0, надо переместить в видимую часть
    else if (branch.container.x === 0 && branch.container.y === 0) {
      branch.moveTo(this.viewport.corner.x + 20, this.viewport.corner.y + 20);
    }
  }

  /**
   * Добавление подписок на события ветки
   * @param branch
   * @private
   */
  private addBranchSubscriptions(branch: Branch): void {
    branch.destroy$.pipe(first()).subscribe(() => {
      this.canvasBranches.delete(branch.linkId);
    });
  }

  /**
   * Отобразить опции ветки
   * @param branch
   * @private
   */
  private showOptions(branch: Branch): void {
    this.hoveredBranch = branch;
    clearTimeout(this.optionsTimeoutId!);
    // iofedurin: Почему тут используется "viewport.lastViewport.scaleX", а не "viewport.scale" ?
    this.optionsXCoords =
      this.viewport.position.x + (branch.container.position.x + CARD_WIDTH) * (this.viewport.lastViewport?.scaleX ?? 1);
    this.optionsYCoords =
      this.viewport.position.y + branch.container.position.y * (this.viewport.lastViewport?.scaleX ?? 1);
    this.isOptionsShow = true;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Обработка события mouseEnter на опциях ветки
   */
  public optionsMouseEnter() {
    clearTimeout(this.optionsTimeoutId!);
  }

  /**
   * Удаление ветки
   */
  public removeBranch() {
    if (this.hoveredBranch) {
      this.blocksOverlayService.deleteOverlayAbleElement(this.hoveredBranch);
      this.hoveredBranch.destroy();
      if (this.hoveredBranch.isInterruptScenario) {
        this.scenariosHelper.removeChainFromInterruptScenario(this.hoveredBranch);
      } else if (this.hoveredBranch.isDefaultScenario) {
        this.scenariosHelper.removeChainFromDefaultScenario(this.hoveredBranch);
      }
    }
    this.hideOptions();
  }

  /**
   * Копирование ветки
   */
  public copyBranch() {
    if (this.hoveredBranch) {
      this.branchCreated.emit(this.hoveredBranch.makeCopy());
    }
  }

  /**
   * Получение канвас ветки по ее linkId
   *
   * @param linkId - Внутренний ID ветки
   */
  public getBranchByLinkId(linkId: string): Branch | undefined {
    return this.canvasBranches.get(linkId);
  }

  /**
   * Отрисовка веток бота по сохраненным координатам
   *
   * @param branches - Список веток
   * @private
   */
  private drawBotByCoords(branches: ReadonlyArray<Branch>): void {
    branches.forEach((branch) => {
      branch.moveTo(branch.coordinates.x, branch.coordinates.y);
      branch.actions.forEach((action) => {
        if (ChatBotModel.isConnectionSourceAction(action.type) && action.nextBranchLinkId.value) {
          const actionBranchId = action.nextBranchLinkId.value;
          const actionBranch = this.canvasBranches.get(actionBranchId);
          this.connectionFactory.create(action, actionBranch);
        }
      });
    });
  }

  /**
   * Создаем Pixi канвас, настраиваем viewport
   * @private
   */
  private initCanvas(): void {
    const canvasEl = this.canvasWrapEl.nativeElement;
    // TODO: pixi.js update - поправить типы, чтоб тут не отдавалось непонятного типа view
    canvasEl.appendChild(this.pixiApp.renderer.view as HTMLCanvasElement);

    merge(fromEvent<MouseEvent>(canvasEl, 'mousemove'), fromEvent<MouseEvent>(canvasEl, 'mouseout'))
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(
          merge(
            this.interactionService.click$.pipe(pickOnlyEvent(PIXI_INTERACTION_EVENT.BRANCH)),
            this.interactionService.click$.pipe(pickOnlyEvent(PIXI_INTERACTION_EVENT.VIEWPORT), startWith(null)),
          ),
        ),
      )
      .subscribe(([mouseEvent, clickEvent]) => {
        let { clientWidth: canvasW, clientHeight: canvasH } = canvasEl;
        const { offsetX: pointerX, offsetY: pointerY } = mouseEvent;
        // Ширина "виртуальной рамки", на расстоянии от которой курсор все еще считается в рамках какой-то стороны
        const borderSize = 50;
        const editorWidth = 335;

        const isBottom = canvasH - pointerY < borderSize;
        const isTop = pointerY < borderSize;
        canvasW = clickEvent?.instance instanceof Branch ? canvasW - editorWidth : canvasW;
        const isRight = canvasW - pointerX < borderSize;
        const isLeft = pointerX < borderSize;

        let vertPosition: PointerVerticalPosition;
        let vertDistanceCoefficient: number | null;
        if (isBottom) {
          vertPosition = 'bottom';
          vertDistanceCoefficient = (canvasH - pointerY) / borderSize;
        } else if (isTop) {
          vertPosition = 'top';
          vertDistanceCoefficient = pointerY / borderSize;
        } else {
          vertPosition = 'middle';
          vertDistanceCoefficient = null;
        }

        let horizPosition: PointerHorizontalPosition;
        let horizDistanceCoefficient: number | null;
        if (isRight) {
          horizPosition = 'right';
          horizDistanceCoefficient = (canvasW - pointerX) / borderSize;
        } else if (isLeft) {
          horizPosition = 'left';
          horizDistanceCoefficient = pointerX / borderSize;
        } else {
          horizPosition = 'middle';
          horizDistanceCoefficient = null;
        }

        this.interactionService.registerPointerPosition({
          vertical: {
            position: vertPosition,
            distanceCoefficient: Math.max(vertDistanceCoefficient ?? 0, 0.1),
          },
          horizontal: {
            position: horizPosition,
            distanceCoefficient: Math.max(horizDistanceCoefficient ?? 0, 0.1),
          },
        });
      });
  }

  /**
   * Инициализация слушателей зумурования для канваса
   * @private
   */
  private initViewportListeners() {
    fromEvent<ZoomedEventData>(this.viewport, 'zoomed')
      .pipe(
        filter((event) => event.type === 'wheel'),
        map((event: ZoomedEventData) => Math.ceil(event.viewport.scale.x * 100)),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((percent) => {
        if (percent <= 20) {
          this.zoomChange.emit(20);
        } else if (percent >= 200) {
          this.zoomChange.emit(200);
        } else {
          this.zoomChange.emit(percent);
        }
      });
    const eventData: InteractionEvent = { type: PIXI_INTERACTION_EVENT.VIEWPORT, instance: this.viewport };
    fromEvent<PointerEvent>(this.viewport, 'mousemove')
      .pipe(
        takeUntil(this.destroy$),
        filter(({ target, currentTarget }) => isDefined(currentTarget) && target === currentTarget),
      )
      .subscribe(() => {
        this.interactionService.registerHover(eventData);
      });
    fromEvent<PointerEvent>(this.viewport, 'pointerup')
      .pipe(
        decreaseTickerMaxFPS(this.pixiApp),
        takeUntil(this.destroy$),
        filter(({ target, currentTarget }) => isDefined(currentTarget) && target === currentTarget),
      )
      .subscribe(() => {
        this.interactionService.registerPointerUp(eventData);
      });
    fromEvent<PointerEvent>(this.viewport, 'clicked')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.interactionService.registerClick(eventData);
      });
    fromEvent<PointerEvent>(this.viewport, 'pointerdown')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.pixiApp.ticker.maxFPS = 0;
      });

    const disableUserSelect = () => (this.document.body.style.userSelect = 'none');
    const enableUserSelect = () => (this.document.body.style.userSelect = 'initial');
    const intervalMS = 100;

    // ==== Перемещение viewport, когда что-нибудь перемещается к краю (например, пытаешься переместить ветку) ====
    const branchPointerDown$ = this.interactionService.pointerDown$.pipe(pickOnlyEvent(PIXI_INTERACTION_EVENT.BRANCH));
    const connectionPointPointerDown$ = this.interactionService.pointerDown$.pipe(
      pickOnlyEvent(PIXI_INTERACTION_EVENT.CONNECTION_POINT),
    );
    // Когда получаем клик по ветке или connectionPoint
    merge(branchPointerDown$, connectionPointPointerDown$)
      .pipe(
        takeUntil(this.destroy$),
        // Преобразуем в интервал
        mergeMap((event) => {
          return interval(intervalMS).pipe(
            // интервал этот отрубаем когда есть на документе pointerUp, + по возвращаем возможность выделения (убирается ниже)
            takeUntil(fromEvent(this.document, 'pointerup').pipe(tap(enableUserSelect))),
            map(() => event),
          );
        }),
        // На каждый тик подкитываем информацию о том, в какой части канваса находится курсор
        withLatestFrom(this.interactionService.pointerPosition$),
        map(([event, positions]) => {
          return { event, verticalPosition: positions.vertical, horizontalPosition: positions.horizontal };
        }),
        // убираем возможность выделать на всем документе
        tap(disableUserSelect),
        // если курсор в центре, то дальше и делать ничего не надо
        filter(({ verticalPosition, horizontalPosition }) => {
          return verticalPosition.position !== 'middle' || horizontalPosition.position !== 'middle';
        }),
      )
      .subscribe(({ event, verticalPosition, horizontalPosition }) => {
        const movePx = 5;
        const nextPos = this.viewport.center.clone();

        if (verticalPosition.position === 'top') {
          nextPos.y -= movePx / verticalPosition.distanceCoefficient! / this.viewport.scaled;
        } else if (verticalPosition.position === 'bottom') {
          nextPos.y += movePx / verticalPosition.distanceCoefficient! / this.viewport.scaled;
        }

        if (horizontalPosition.position === 'left') {
          nextPos.x -= movePx / horizontalPosition.distanceCoefficient! / this.viewport.scaled;
        } else if (horizontalPosition.position === 'right') {
          nextPos.x += movePx / horizontalPosition.distanceCoefficient! / this.viewport.scaled;
        }

        // Тут мы эмитим ивенты, которые триггерят перерисовку. А 10 раз за тик, чтоб была плавность перемещения, иначе все будет дергаться
        const numberOfBranchPositionChanges = 10;

        if (event.type === PIXI_INTERACTION_EVENT.BRANCH) {
          interval(intervalMS / numberOfBranchPositionChanges)
            .pipe(
              take(numberOfBranchPositionChanges),
              withLatestFrom(fromEvent<FederatedPointerEvent>(this.viewport, 'mousemove')),
            )
            .subscribe(([_, pointerEvent]) => {
              event.instance.container.emit('pointermove', pointerEvent);
            });
        }
        if (event.type === PIXI_INTERACTION_EVENT.CONNECTION_POINT) {
          interval(intervalMS / numberOfBranchPositionChanges)
            .pipe(
              take(numberOfBranchPositionChanges),
              withLatestFrom(fromEvent<FederatedPointerEvent>(this.viewport, 'mousemove')),
            )
            .subscribe(([_, pointerEvent]) => {
              event.instance.connectionPoint?.emit('pointermove', pointerEvent);
            });
        }

        this.viewport.animate({ position: nextPos, time: intervalMS });
      });
  }

  /**
   * Инициализация слушателей для скрытия опций ветки канваса
   * @private
   */
  private initOptionListeners() {
    // Подписываемся на попадание курсора на менюшку, чтоб скинуть скрытие подсказок
    fromEvent(this.optionsContent.nativeElement, 'mouseover')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.optionsMouseEnter();
      });
    // Подписываемся на ховеры, фильтруем только ветки, показываем опции
    this.interactionService.hover$
      .pipe(takeUntil(this.destroy$), filterAndExtractInstance(PIXI_INTERACTION_EVENT.BRANCH))
      .subscribe((branch) => {
        this.showOptions(branch);
      });
    // на ховер по viewport или mouseleave с опций скрываем их
    merge(
      this.interactionService.hover$.pipe(filterAndExtractInstance(PIXI_INTERACTION_EVENT.VIEWPORT)),
      fromEvent(this.optionsContent.nativeElement, 'mouseleave'),
      fromEvent(this.viewport, 'moved'),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.optionsTimeoutId = this.ngZone.runOutsideAngular(() => setTimeout(this.hideOptions.bind(this), 500));
      });
    // Подписываемся на передвижение ветки, сразу же отключаем опции, через 200мс после последнего перемещения возвращаем их
    this.interactionService.drag$
      .pipe(
        takeUntil(this.destroy$),
        filterAndExtractInstance(PIXI_INTERACTION_EVENT.BRANCH),
        tap(() => {
          this.hideOptions();
        }),
        switchMap((branch) => {
          return timer(200, 1).pipe(first(), mapTo(branch));
        }),
      )
      .subscribe((branch) => {
        this.showOptions(branch);
      });

    this.viewport.on('zoomed', this.hideOptions.bind(this)).on('drag-start', this.hideOptions.bind(this));
  }

  /**
   * Инициализация слушателей для событий от бейджей
   * @private
   */
  public initBadgesListeners() {
    this.interactionService.click$
      .pipe(filterAndExtractInstance(PIXI_INTERACTION_EVENT.SWITCHER), takeUntil(this.destroy$))
      .subscribe((switcher) => {
        this.chatBotForm.allowUserReplies = switcher.status;
        this.ngZone.run(() => {
          this.changeDetectorRef.markForCheck();
        });
      });

    this.interactionService.click$
      .pipe(filterAndExtractInstance(PIXI_INTERACTION_EVENT.BADGE), takeUntil(this.destroy$))
      .subscribe((badge) => {
        if (badge instanceof StartBadge) {
          this.startBadgeClick.next();
        }
      });
  }

  /**
   * Отрисовка бейджей
   * @private
   */
  private initialConstructBadges(): void {
    this.viewport.addChild(this.chatBotForm.startBadge.container);
    this.viewport.addChild(this.chatBotForm.interruptBadge.container);
    this.chatBotForm.startBadge.setInitialPosition();
    this.chatBotForm.interruptBadge.setInitialPosition();
  }

  private hideOptions() {
    if (!this.isOptionsShow) {
      return;
    }
    this.hoveredBranch = null;
    this.isOptionsShow = false;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Первоначальная отрисовка древовидной структуры бота
   *
   * @param startedBranch - Начальная ветка
   * @private
   */
  private drawInitialBotTree(startedBranch: Branch): void {
    // Инфо по тому, какой отступ для каждой колонки/ряда
    const columnsInfo = new Map<number, { columnLeftIndent: number; columnTopIndent: number }>();

    // Сохраняем по колонкам ветки, чтоб потом по колонкам их разместить в канвасе
    const columns: Set<Branch>[] = [];

    // Добавляем блоки на канвас со связами. Без расстановки по координатам, просто все в кучу добавляются.
    const drawBranches = (branch: Branch, parentBranch?: Branch) => {
      // Индекс колонки
      const columnIndex = branch.initialAPIData.parentBranchIds.length - 1;
      // Массив веток этой колонки
      const columnArray = columns[columnIndex] ?? new Set<Branch>();
      columnArray.add(branch);
      // Обновляем массив колонки [хз зачем, вроде не надо, но не буду трогать :) ]
      columns.splice(columnIndex, 1, columnArray);

      // Добавляем на канвас
      this.addBranch(branch, parentBranch);

      // Отрисовываем связи до след блока
      branch.actions.forEach((action) => {
        if (ChatBotModel.isConnectionSourceAction(action.type)) {
          const nextBranch = this.getBranchByLinkId(action.nextBranchLinkId.value!);
          if (nextBranch) {
            drawBranches(nextBranch);
            this.connectionFactory.create(action, nextBranch);
          }
        }
      });
    };

    drawBranches(startedBranch);

    /**
     * Получение позиции ветки в зависимости от колонки в которой он находится.
     *
     * @param columnNumber - по факту - сколько веток от этой до стартовой ветки
     * @param branch - по факту - сколько веток от этой до стартовой ветки
     */
    const getBranchPosition = (columnNumber: number, branch: Branch): [x: number, y: number] => {
      /* Получаем ширину предыдущего ряда */
      const prevColumnIndent = columnsInfo.get(columnNumber - 1)?.columnLeftIndent ?? 0;
      /* Суммируем: Базовый отступ, ширину предыдущего ряда,  */
      const x = BRANCH_INITIAL_INDENTS.LEFT + prevColumnIndent;
      const y = columnsInfo.get(columnNumber)?.columnTopIndent ?? BRANCH_INITIAL_INDENTS.TOP;
      const columnPrevIndent = columnsInfo.get(columnNumber)?.columnLeftIndent ?? 0;
      columnsInfo.set(columnNumber, {
        columnLeftIndent: Math.max(
          columnPrevIndent,
          prevColumnIndent + branch.width + BRANCH_POSITIONING_INDENTS.HORIZONTAL,
        ),
        columnTopIndent: y + branch.height + BRANCH_POSITIONING_INDENTS.VERTICAL,
      });
      return [x, y];
    };

    // Перемещаем все ветки в нужные позиции
    columns.forEach((columnArr, index) => {
      columnArr.forEach((branch) => {
        const branchPosition = getBranchPosition(index, branch);
        branch.moveTo(...branchPosition);
      });
    });
  }

  /**
   * Отрисовка веток, полученных с бэка
   * @private
   */
  private initialConstructBot(): void {
    this.chatBotForm.branches.forEach((branch) => {
      this.addBranch(branch);
    });

    // Если нет координат, то надо отрисавать древовидную структуру
    // NOTE Наличие координат достаточно проверить у одной из веток
    //  т.к. если они есть в ней, то остальные ветки тоже будут иметь координаты
    if (!this.chatBotForm.branches[0].initialAPIData.coordinates) {
      this.chatBotForm.startedBranch && this.drawInitialBotTree(this.chatBotForm.startedBranch);
      const interruptBranchY =
        this.chatBotForm.startedBranch!.coordinates.y +
        this.chatBotForm.startedBranch!.container.height +
        BRANCH_POSITIONING_INDENTS.VERTICAL;
      this.chatBotForm.interruptedBranch?.moveTo(BRANCH_INITIAL_INDENTS.LEFT, interruptBranchY);
    } else {
      this.drawBotByCoords(this.chatBotForm.branches);
    }
  }

  /**
   * Перемещает Viewport к указанной ветке с оступом от краев
   */
  public moveViewportCornerToBranch(element: Branch | BaseBadge) {
    const xIndent = element instanceof Branch ? 150 : 250; // отступ от краев при перемещении
    const yIndent = 150; // отступ от краев при перемещении
    this.viewport.moveCorner(
      element.coordinates.x - xIndent / this.viewport.scaled,
      element.coordinates.y - yIndent / this.viewport.scaled,
    );
    // TODO: pixi.js update - поправить ивент или переписать
    this.viewport.emit('moved', {} as any);
  }

  setZoom(percentValue: number) {
    this.viewport.setZoom(percentValue / 100);
    // Если вызывать setZoom, то событие zoomed не срабатывает
    // а т.к. это событие используется в нескольких местах,
    // то надо руками вызывать событие изменения зума.
    // Тип установлен в 'wheel' чтобы слушатель выше пропустил это событие
    const zoomEvent: ZoomedEventData = {
      type: 'wheel',
      viewport: this.viewport,
    };
    this.viewport.emit('zoomed', zoomEvent);
  }
}

<ng-container *transloco="let t; read: 'starterGuideWarmUpComponent'">
  <ngb-alert
    *ngIf="isStepMade"
    class="mb-30"
    [dismissible]="false"
    type="success"
    data-test="starter-guide-warm-up-step-made-alert"
  >
    <i class="cqi-sm cqi-check-circle mr-10"></i>
    {{ t('stepPassedAlert') }}
  </ngb-alert>
  <div class="d-flex justify-content-between">
    <div class="margin-between-rows-20">
      <div>
        <span
          class="font-weight-bold dashed"
          [ngbTooltip]="t('hint.tooltip')"
          >{{ t('hint.text') }}</span
        >
        <span class="font-weight-bold">{{ t('description') }}</span>
      </div>
      <ul class="steps-list">
        <li>
          <div>
            <span>{{ t('instructions.1.text') }}</span>
            <a
              target="_blank"
              uiSref="app.content.messagesAjs.chatBot.list"
              >{{ t('instructions.1.link.text') }}
              <i class="cqi-sm cqi-external-link"></i>
            </a>
          </div>
        </li>
        <li>
          <div class="no-space">
            <span>{{ t('instructions.2.firstText') }}</span>
            <span
              class="dashed"
              [ngbTooltip]="t('instructions.2.hint.tooltip')"
              >{{ t('instructions.2.hint.text') }}</span
            >
            <span>{{ t('instructions.2.secondText') }}</span>
          </div>
        </li>
        <li>
          <div>
            <span>{{ t('instructions.3.text') }}</span>
            <a
              class="dashed"
              target="_blank"
              uiSref="app.content.messages.auto.table"
              [ngbTooltip]="t('instructions.3.tooltip')"
              (click)="onClickTriggerMessagesLink()"
              >{{ t('instructions.3.link.text') }}
              <i class="cqi-sm cqi-external-link"></i>
            </a>
          </div>
        </li>
        <li>
          <div class="no-space">
            <span>{{ t('instructions.4.firstText') }}</span>
            <span
              class="dashed"
              [ngbTooltip]="t('instructions.4.hint.tooltip')"
              >{{ t('instructions.4.hint.text') }}</span
            >
            <span>{{ t('instructions.4.secondText') }}</span>
          </div>
        </li>
        <li>{{ t('instructions.5') }}</li>
      </ul>
    </div>
    <div>
      <img
        class="cursor-pointer"
        (click)="openViewVideoModal()"
        data-test="open-view-video-modal-img"
        src="assets/img/{{ activeLang }}/starter-guide/onboarding_v2_warm_up.png"
      />
    </div>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { DeleteDjangoUserModalModule } from '@panel/app/partials/modals/delete-django-user-modal/delete-django-user-modal.module';

import { DeleteDjangoUserComponent } from './delete-django-user.component';

@NgModule({
  declarations: [DeleteDjangoUserComponent],
  exports: [DeleteDjangoUserComponent],
  imports: [CommonModule, TranslocoModule, NgbTooltipModule, DeleteDjangoUserModalModule],
})
export class DeleteDjangoUserModule {}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';

import { MESSAGE_PAGE_TYPES, USER_STATUSES_FOR_SENDING } from '@http/message/message.constants';
import { MessageModel } from '@http/message/message.model';
import { SENDING_FILTERS_GROUP_TYPES } from '@panel/app/services/conditions-sending/conditions-sending.constants';
import { MessagePartModel } from '@http/message-part/message-part.model';

@Component({
  selector: 'cq-auto-message-user-statuses',
  templateUrl: './auto-message-user-statuses.component.html',
  styleUrls: ['./auto-message-user-statuses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageUserStatusesComponent implements OnInit {
  /** Сообщение */
  @Input() currentMessage!: any;
  /** Тип страницы, на котором размещен компонент, необходимо для корректного трекинга событий и переводов */
  @Input() messagePageType?: any;

  protected USER_STATUSES_FOR_SENDING: any = USER_STATUSES_FOR_SENDING;
  protected SENDING_FILTERS_GROUP_TYPES: any = SENDING_FILTERS_GROUP_TYPES;
  protected userStatusSwitch: any;
  protected userStatusForm: any = this.fb.group({
    userStatuses: this.fb.control(USER_STATUSES_FOR_SENDING.ONLINE_OR_IDLE, [Validators.required]),
  });

  constructor(
    protected readonly fb: FormBuilder,
    protected readonly messageModel: MessageModel,
    protected readonly messagePartModel: MessagePartModel,
    protected readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.messagePageType = this.messagePageType || MESSAGE_PAGE_TYPES.AUTO_MESSAGES; // Тип старницы, на которой размещен компоненет, необходимо для корректного трекинга событий и переводов
    this.userStatusSwitch = this.currentMessage.userStatuses !== USER_STATUSES_FOR_SENDING.NO_MATTER;

    this.userStatusForm.controls.userStatuses.setValue(this.currentMessage.userStatuses, { emitEvent: false });

    this.userStatusForm.valueChanges.subscribe((value: any) => {
      this.currentMessage.userStatuses = value.userStatuses;
    });
  }

  /**
   * Изменение значения свича статуса пользователя
   * @param value
   */
  changeUserStatusSwitch(value: any): void {
    this.userStatusForm.controls.userStatuses.setValue(
      value ? USER_STATUSES_FOR_SENDING.ONLINE_OR_IDLE : USER_STATUSES_FOR_SENDING.NO_MATTER,
    );
  }
}

import { InjectionToken } from '@angular/core';

/** Тип данных, передаваемых в confirm-модалку */
export type ConfirmModalData = {
  /** Заголовок */
  heading: string;
  /** Тело модалки */
  body: string;
  /** CSS-класс подтверждающей кнопки */
  confirmButtonClass?: string;
  /** Текст в подтверждающей кнопке */
  confirmButtonText: string;
  /** Текст в кнопке отмены */
  cancelButtonText?: string;
  /** CSS-класс кнопки отмены */
  cancelButtonClass?: string;
};

/**
 * Токен для передачи данных через DI в confirm-модалку
 */
export const CONFIRM_MODAL_DATA_TOKEN = new InjectionToken<ConfirmModalData>('Confirm modal data provider');

import { Injectable } from '@angular/core';
import { find } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { WizardItemComponent } from '@panel/app/partials/wizard/wizard-item/wizard-item.component';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  restricted?: boolean;
  /** Имя текущего шага */
  currentStepName!: any;
  steps: WizardItemComponent[] = [];
  /** Имя последнего завершенного шага */
  lastCompletedStepName: string | null = null;
  currentStep: any = null;
  lastCompletedStep: any = null;

  changes: BehaviorSubject<any> = new BehaviorSubject('');

  /**
   * Добавление шага визарда в массив шагов
   *
   * @param step Контроллер шага
   */
  addStep(step: any): void {
    this.steps.push(step);

    // если текущий шаг является первым, или же совпадает с выбранным шагом - выбираем его
    if (
      step.name === this.currentStepName ||
      ((this.currentStepName === undefined || this.currentStepName == null) && this.steps.length === 1)
    ) {
      this.goToStepWithoutValidation(step);
    }

    this.changes.next(step);
  }

  /**
   * Функция удаления шага
   *
   * @param step
   */
  removeStep(step: any): void {
    const index = this.steps.indexOf(step);

    if (step.name == this.currentStepName) {
      if (index == 0) {
        this.currentStepName = this.steps.length > 1 ? this.steps[index + 1].name : null;
      } else {
        this.currentStepName = this.steps[index - 1].name;
      }
    }

    if (step.name == this.lastCompletedStepName) {
      this.lastCompletedStepName = index == 0 ? null : this.steps[index - 1].name;
    }

    this.steps.splice(index, 1);
  }

  /**
   * Выбор шага без валидации и коллбэков
   *
   * @param step
   */
  goToStepWithoutValidation(step: any): void {
    if (this.currentStep !== step) {
      const stepIndex = this.steps.indexOf(step);
      if (~stepIndex) {
        this.currentStep = step;
        this.currentStepName = step.name;
      }

      if (stepIndex >= 1) {
        this.setLastCompletedStep(this.steps[stepIndex - 1]);
      }

      this.refreshStepStates();
    }
  }

  /**
   * Установка шага последним завершённым шагом
   *
   * @param step Шаг
   */
  setLastCompletedStep(step: any): void {
    if (!this.lastCompletedStep || this.steps.indexOf(this.lastCompletedStep) < this.steps.indexOf(step)) {
      this.lastCompletedStep = step;
      this.lastCompletedStepName = step.name;
    }

    this.changes.next(step);
  }

  /**
   * Обновление состояния всех шагов
   */
  refreshStepStates(): void {
    const currentStepIndex = this.steps.indexOf(this.currentStep);
    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i];

      step.active = i === currentStepIndex;
      step.show = i === currentStepIndex;
      step.complete = i < currentStepIndex;

      this.changes.next(step);
    }
  }

  /**
   * Переход на шаг
   *
   * @param step Шаг
   */
  goToStep(step: any): Promise<any> {
    if (this.currentStep != step) {
      if (this.restricted) {
        if (this.lastCompletedStep && this.steps.indexOf(this.lastCompletedStep) < this.steps.indexOf(step) - 2) {
          return Promise.reject();
        } else if (!this.lastCompletedStep && this.steps.indexOf(step) >= 2) {
          return Promise.reject();
        }
      }

      if (this.steps.indexOf(this.currentStep) < this.steps.indexOf(step)) {
        return this.currentStep
          .onExitToNext()
          .then(() => step.onEnterFromPrevious())
          .then(() => this.goToStepWithoutValidation(step));
      }

      if (this.steps.indexOf(this.currentStep) > this.steps.indexOf(step)) {
        return this.currentStep
          .onExitToPrevious()
          .then(() => step.onEnterFromNext())
          .then(() => this.goToStepWithoutValidation(step));
      }
    }

    return Promise.resolve();
  }

  /**
   * Получение шага по имени
   *
   * @param stepName Имя шага
   * @return {Object|null}
   */
  getStepByName(stepName: any): any | null {
    if (stepName) {
      return find(this.steps, { name: stepName });
    } else {
      return null;
    }
  }

  /**
   * Переход на следующий шаг
   */
  nextStep(): void {
    const nextStep = this.steps[this.steps.indexOf(this.currentStep) + 1];

    if (nextStep !== undefined) {
      this.goToStep(nextStep);
    }
  }

  /**
   * Переход на следующий шаг без валидации и коллбэков
   */
  nextStepWithoutValidation(): void {
    const nextStep = this.steps[this.steps.indexOf(this.currentStep) + 1];

    if (nextStep !== undefined) {
      this.goToStepWithoutValidation(nextStep);
    }
  }

  /**
   * Переход на предыдущий шаг
   */
  previousStep(): void {
    const previousStep = this.steps[this.steps.indexOf(this.currentStep) - 1];

    if (previousStep !== undefined) {
      this.goToStep(previousStep);
    }
  }

  /**
   * Переход на следующий шаг без валидации и коллбэков
   */
  previousStepWithoutValidation(): void {
    const previousStep = this.steps[this.steps.indexOf(this.currentStep) - 1];

    if (previousStep !== undefined) {
      this.goToStepWithoutValidation(previousStep);
    }
  }
}

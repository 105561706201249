<ng-container *transloco="let t; read: 'pricingOldComponent'">
  <div class="card no-border">
    <div class="card-body">
      <div
        class="row justify-center"
        [ngClass]="{ 'margin-bottom-50': l10nHelperService.isUsCountry() }"
      >
        <div class="col-sm-6 col-md-3">
          <h2 class="margin-bottom-20">{{ t('heroBlock.sliderBlock.title') }}</h2>
          <p class="margin-bottom-30">
            <span
              *ngIf="slider.value === limitUniqueUsers"
              [innerHTML]="
                'pricingOldComponent.heroBlock.sliderBlock.overLimit'
                  | transloco: { limit: limitUniqueUsers - 1 | number }
              "
            >
            </span>
            <span
              *ngIf="slider.value < limitUniqueUsers"
              [innerHTML]="
                'pricingOldComponent.heroBlock.sliderBlock.noOverLimit' | transloco: { limit: slider.value | number }
              "
            >
            </span>
            <span
              [ngbTooltip]="
                'pricingOldComponent.heroBlock.sliderBlock.forecast.tooltip.1'
                  | transloco: { projectName: PROJECT_NAME }
              "
              container="body"
              placement="right"
            >
              <i class="cqi-sm cqi-question-circle text-secondary cursor-pointer"></i>
            </span>
          </p>
          <ngx-slider
            class="mt-0"
            [(value)]="slider.value"
            [options]="slider.options"
          ></ngx-slider>
          <div
            *ngIf="getForecastValue(appQuotas.users7)"
            class="margin-bottom-30"
          >
            <span
              class="cursor-pointer dashed"
              ngbTooltip="{{
                'pricingOldComponent.heroBlock.sliderBlock.forecast.tooltip.2'
                  | transloco: { visitors: getForecastValue(appQuotas.users7) }
              }}"
              container="body"
              placement="right"
            >
              {{
                'pricingOldComponent.heroBlock.sliderBlock.forecast.title'
                  | transloco: { visitors: getForecastValue(appQuotas.users7) }
              }}
            </span>
          </div>
          <div class="margin-bottom-30">
            <label class="mb-0 font-weight-normal">
              {{ 'pricingOldComponent.heroBlock.sliderBlock.billed.billed' | transloco }}
            </label>

            <div
              class="d-flex align-items-center margin-top-10"
              *ngFor="let discountByPeriod of discountsByPeriod | keyvalue | orderBy: 'value'"
            >
              <div class="period-wrapper d-flex flex-wrap">
                <div class="custom-control custom-radio">
                  <input
                    class="custom-control-input"
                    type="radio"
                    [id]="'discount-' + discountByPeriod.key"
                    [(ngModel)]="chosenDiscount"
                    [value]="discountByPeriod.value"
                  />
                  <label
                    class="custom-control-label"
                    [for]="'discount-' + discountByPeriod.key"
                  >
                    {{
                      'pricingOldComponent.heroBlock.sliderBlock.billed.paymentOption.' + discountByPeriod.key
                        | transloco
                    }}
                  </label>
                </div>
                <div class="d-flex align-items-baseline">
                  <div
                    *ngIf="discountByPeriod.value"
                    class="label label-light-secondary"
                  >
                    {{
                      'pricingOldComponent.heroBlock.sliderBlock.billed.sale'
                        | transloco: { saleValue: discountByPeriod.value * 100 | number }
                    }}
                  </div>
                  <cq-first-payment-discount-label
                    *ngIf="hasFirstPaymentDiscount()"
                    class="ml-5"
                  ></cq-first-payment-discount-label>
                </div>
              </div>
            </div>
          </div>

          <!-- Дополнительные модули -->
          <div
            *ngIf="planVersionService.isTrial()"
            class="margin-bottom-30"
          >
            <div class="margin-bottom-10">
              {{ 'pricingOldComponent.heroBlock.sliderBlock.addOns.heading' | transloco }}
            </div>
            <div
              class="custom-control custom-checkbox margin-bottom-10"
              *ngFor="let addOn of addOns"
            >
              <input
                class="custom-control-input"
                type="checkbox"
                [id]="'addon-' + addOn.id"
                [(ngModel)]="addOn.active"
              />
              <label
                class="custom-control-label"
                [for]="'addon-' + addOn.id"
              >
                <span>{{ addOn.name + ':' }}</span>
                <span>{{ getAddOnPriceTranslate(addOn.id) }}</span>
              </label>
              <i
                class="cqi-sm cqi-question-circle"
                [ngbTooltip]="
                  'pricingOldComponent.heroBlock.sliderBlock.addOns.' + addOn.id + '.tooltip'
                    | transloco: { projectName: PROJECT_NAME }
                "
                container="body"
                placement="right"
              ></i>
            </div>
          </div>

          <ngb-alert
            [dismissible]="false"
            class="bg-light-secondary text-body border-0"
          >
            <span>{{ 'pricingOldComponent.heroBlock.sliderBlock.wholeTeam' | transloco }}</span>
          </ngb-alert>
        </div>
        <div class="col-sm-6 col-md-9">
          <!-- Название и описание тарифов -->
          <div class="row">
            <div class="col-md-4 flex flex-col align-items-start">
              <img
                class="block margin-bottom-20"
                src="assets/img/default/subscription/pricing/plan-1.png"
              />
              <h4>{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</h4>
              <div
                class="margin-bottom-15"
                [innerHTML]="
                  'pricingOldComponent.heroBlock.plan1.description.' + currentPlanDesriptionTranslationKey | transloco
                "
              ></div>
            </div>
            <div class="col-md-4 flex flex-col align-items-start">
              <img
                class="block margin-bottom-20"
                src="assets/img/default/subscription/pricing/plan-2.png"
              />
              <h4>{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</h4>
              <div
                class="margin-bottom-15"
                [innerHTML]="
                  'pricingOldComponent.heroBlock.plan2.description.' + currentPlanDesriptionTranslationKey | transloco
                "
              ></div>
            </div>
            <div class="col-md-4 flex flex-col align-items-start">
              <img
                class="block margin-bottom-20"
                src="assets/img/default/subscription/pricing/plan-3.png"
              />
              <h4>{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</h4>
              <div
                class="margin-bottom-15"
                [innerHTML]="
                  'pricingOldComponent.heroBlock.plan3.description.default' | transloco: { projectName: PROJECT_NAME }
                "
              ></div>
            </div>
          </div>
          <!-- Кнопки подключения тарифов -->
          <div class="row margin-top-5 margin-bottom-40">
            <div class="col-md-4">
              <div *ngIf="slider.value === limitUniqueUsers">
                <span class="h3">{{ 'pricingOldComponent.heroBlock.overLimit' | transloco }} </span>
                <div class="margin-top-10 margin-bottom-40"></div>
              </div>
              <div *ngIf="slider.value < limitUniqueUsers">
                <span class="h3">
                  {{
                    'pricingOldComponent.heroBlock.plan1.price.value'
                      | transloco
                        : {
                            price:
                              (getPriceWithSale(billingPlansSettings[billingPlans.BUSINESS_CHAT][slider.value].PRICE)
                              | ceil
                              | toFormattedMoney: 0)
                          }
                  }}
                </span>
                <span>{{ 'pricingOldComponent.heroBlock.plan1.price.period' | transloco }}</span>
                <span
                  *ngIf="chosenDiscount"
                  class="text-muted ml-5"
                >
                  <small>
                    <s>
                      <span class="h5">
                        {{
                          'pricingOldComponent.heroBlock.plan1.price.value'
                            | transloco
                              : {
                                  price:
                                    (getPriceWithoutSale(
                                      billingPlansSettings[billingPlans.BUSINESS_CHAT][slider.value].PRICE
                                    ) | toFormattedMoney: 0)
                                }
                        }}
                      </span>
                      <span>{{ 'pricingOldComponent.heroBlock.plan1.price.period' | transloco }}</span>
                    </s>
                  </small>
                </span>
                <div class="margin-top-10 margin-bottom-10">
                  {{
                    'pricingOldComponent.table.4.6.title.2'
                      | transloco
                        : {
                            emailsCount:
                              (billingPlansSettings[billingPlans.BUSINESS_CHAT][slider.value].EMAILS | number)
                          }
                  }}
                </div>
              </div>
              <button
                class="btn btn-lg btn-outline-primary margin-top-10"
                (click)="trackClickChoosePlanButton(NEW_PLANS_NAMES.PLAN_1); sendChatMessage(NEW_PLANS_NAMES.PLAN_1)"
              >
                {{ 'pricingOldComponent.heroBlock.plan1.connectButtonText' | transloco }}
              </button>
            </div>
            <div class="col-md-4">
              <div *ngIf="slider.value === limitUniqueUsers">
                <span class="h3">{{ 'pricingOldComponent.heroBlock.overLimit' | transloco }}</span>
                <div class="margin-top-10 margin-bottom-40"></div>
              </div>
              <div *ngIf="slider.value < limitUniqueUsers">
                <span class="h3">
                  {{
                    'pricingOldComponent.heroBlock.plan2.price.value'
                      | transloco
                        : {
                            price:
                              (getPriceWithSale(billingPlansSettings[billingPlans.AUTOMATION][slider.value].PRICE)
                              | ceil
                              | toFormattedMoney: 0)
                          }
                  }}
                </span>
                <span>{{ 'pricingOldComponent.heroBlock.plan2.price.period' | transloco }}</span>
                <span
                  *ngIf="chosenDiscount"
                  class="text-muted ml-5"
                >
                  <small>
                    <s>
                      <span class="h5">
                        {{
                          'pricingOldComponent.heroBlock.plan2.price.value'
                            | transloco
                              : {
                                  price:
                                    (getPriceWithoutSale(
                                      billingPlansSettings[billingPlans.AUTOMATION][slider.value].PRICE
                                    ) | toFormattedMoney: 0)
                                }
                        }}
                      </span>
                      <span>{{ 'pricingOldComponent.heroBlock.plan2.price.period' | transloco }}</span>
                    </s>
                  </small>
                </span>
                <div class="margin-top-10 margin-bottom-10">
                  {{
                    'pricingOldComponent.table.4.6.title.3'
                      | transloco
                        : {
                            emailsCount: (billingPlansSettings[billingPlans.AUTOMATION][slider.value].EMAILS | number)
                          }
                  }}
                </div>
              </div>
              <button
                class="btn btn-lg btn-primary margin-top-10"
                (click)="trackClickChoosePlanButton(NEW_PLANS_NAMES.PLAN_2); sendChatMessage(NEW_PLANS_NAMES.PLAN_2)"
              >
                {{ 'pricingOldComponent.heroBlock.plan2.connectButton' | transloco }}
              </button>
            </div>
            <div class="col-md-4">
              <span class="h3">{{ 'pricingOldComponent.heroBlock.plan3.price.value' | transloco }}</span>
              <div class="margin-top-10 margin-bottom-40"></div>
              <button
                class="btn btn-lg btn-outline-primary margin-top-10"
                (click)="
                  trackClickChoosePlanButton(NEW_PLANS_NAMES.PLAN_3);
                  l10nHelper.isUsCountry() ? sendChatMessage(NEW_PLANS_NAMES.PLAN_3) : trackChoicePremium()
                "
              >
                {{ 'pricingOldComponent.heroBlock.plan3.callWithUsButton' | transloco }}
              </button>
            </div>
          </div>
          <!-- Кратное описание тарифов -->
          <div class="row margin-bottom-20">
            <div class="col-md-4">
              <p class="margin-bottom-15 font-weight-bold">
                {{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.1.title' | transloco }}
              </p>
              <p class="margin-bottom-15">
                {{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.2.title' | transloco }}
              </p>
              <p class="margin-bottom-15">
                {{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.3.title' | transloco }}
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.4.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.4.title' | transloco }}</span
                >
              </p>
              <hr />
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.5.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.5.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.6.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.6.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.7.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.7.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.8.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.8.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.9.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan1.planIncludeTitles.9.title' | transloco }}</span
                >
              </p>
            </div>
            <div class="col-md-4">
              <p class="margin-bottom-15 font-weight-bold">
                {{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.1.title' | transloco }}
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.2.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.2.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.3.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.3.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.4.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.4.title' | transloco }}</span
                >
              </p>
              <hr />
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.5.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.5.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.6.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.6.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.7.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.7.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.8.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.8.title' | transloco }}</span
                >
              </p>
              <p class="margin-bottom-15">
                <span
                  class="cursor-pointer dashed"
                  ngbTooltip="{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.9.tooltip' | transloco }}"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.heroBlock.plan2.planIncludeTitles.9.title' | transloco }}</span
                >
              </p>
            </div>
            <div class="col-md-4">
              <p class="margin-bottom-15 font-weight-bold">
                {{ 'pricingOldComponent.heroBlock.plan3.planIncludeTitles.1.title' | transloco }}
              </p>
              <p class="margin-bottom-15">
                {{ 'pricingOldComponent.heroBlock.plan3.planIncludeTitles.2.title' | transloco }}
              </p>
              <p class="margin-bottom-15">
                {{ 'pricingOldComponent.heroBlock.plan3.planIncludeTitles.3.title' | transloco }}
              </p>
              <p class="margin-bottom-15">
                {{ 'pricingOldComponent.heroBlock.plan3.planIncludeTitles.4.title' | transloco }}
              </p>
              <p class="margin-bottom-15">
                {{ 'pricingOldComponent.heroBlock.plan3.planIncludeTitles.5.title' | transloco }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- NOTE: На России нет Freemium-->
      <ngb-alert
        *cqHideInRu
        class="bg-light-secondary text-body"
      >
        <div class="row justify-center padding-top-20 padding-bottom-20">
          <div class="col-md-3 flex flex-col padding-left-40 justify-center align-items-start">
            <img
              class="block margin-bottom-20"
              src="assets/img/default/subscription/pricing/plan-0.png"
            />
            <h4>{{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}</h4>
            <div>{{ 'pricingOldComponent.heroBlock.plan0.description' | transloco }}</div>
          </div>
          <div class="col-md-3 flex flex-col justify-center align-items-start">
            <h3 class="margin-bottom-15 no-margin-top">
              {{ 'pricingOldComponent.heroBlock.plan0.planIncludeTitles.1' | transloco }}
            </h3>
            <h3>{{ 'pricingOldComponent.heroBlock.plan0.planIncludeTitles.2' | transloco }}</h3>
          </div>
          <div class="col-md-3 flex flex-col justify-center align-items-start">
            <p class="margin-bottom-15">{{ 'pricingOldComponent.heroBlock.plan0.planIncludeTitles.3' | transloco }}</p>
            <p class="margin-bottom-15">{{ 'pricingOldComponent.heroBlock.plan0.planIncludeTitles.4' | transloco }}</p>
            <p class="margin-bottom-15">{{ 'pricingOldComponent.heroBlock.plan0.planIncludeTitles.5' | transloco }}</p>
            <p class="no-margin-bottom">{{ 'pricingOldComponent.heroBlock.plan0.planIncludeTitles.6' | transloco }}</p>
          </div>
          <div class="col-md-3 flex flex-col justify-center align-items-start">
            <h3>
              <span>{{
                'pricingOldComponent.heroBlock.plan0.price.value'
                  | transloco
                    : {
                        price:
                          getPriceWithSale(billingPlansSettings[billingPlans.FREEMIUM][slider.value].PRICE)
                          | toFormattedMoney: 0
                      }
              }}</span>
            </h3>
            <button
              class="btn btn-lg btn-outline-primary margin-top-10"
              (click)="trackClickChoosePlanButton(NEW_PLANS_NAMES.PLAN_0); sendChatMessage(NEW_PLANS_NAMES.PLAN_0)"
              [disabled]="billingInfoModel.planId === billingPlans.FREEMIUM"
            >
              {{ 'pricingOldComponent.heroBlock.plan0.connectButtonText' | transloco }}
            </button>
          </div>
        </div>
      </ngb-alert>
      <!-- Кнопка скрытия подробного описания тарифов -->
      <div
        class="flex justify-center padding-top-10 padding-bottom-30"
        [ngClass]="{ 'no-padding-bottom': isCollapsedFullComparison }"
      >
        <button
          class="btn btn-text-primary"
          (click)="isCollapsedFullComparison = !isCollapsedFullComparison"
        >
          <span>{{ 'pricingOldComponent.heroBlock.seeFullComparison' | transloco }}</span>
          <i
            *ngIf="isCollapsedFullComparison"
            class="btn-icon-right cqi-sm cqi-03x cursor-pointer cqi-chevron-down"
          ></i>
          <i
            *ngIf="!isCollapsedFullComparison"
            class="btn-icon-right cqi-sm cqi-03x cursor-pointer cqi-chevron-up"
          ></i>
        </button>
      </div>
      <!-- Подробное описание тарифов -->
      <div [ngbCollapse]="isCollapsedFullComparison">
        <table class="table no-margin-bottom">
          <tbody>
            <!-- 1 -->
            <tr class="active">
              <td class="flex align-items-center">
                <img
                  class="margin-right-10"
                  src="assets/img/default/subscription/pricing/pricing-table-1.png"
                />
                <span class="font-weight-bold">{{ 'pricingOldComponent.table.1.title' | transloco }}</span>
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td
                *cqHideInRu
                class="font-weight-bold"
              >
                {{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}
              </td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.1.1.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.1.1.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.1.2.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.1.2.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.1.3.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu>{{ 'pricingOldComponent.table.1.3.title.2' | transloco }}</td>
              <td>{{ 'pricingOldComponent.table.1.3.title.3' | transloco }}</td>
              <td>{{ 'pricingOldComponent.table.1.3.title.4' | transloco }}</td>
              <td>{{ 'pricingOldComponent.table.1.3.title.5' | transloco }}</td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.1.4.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.1.4.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.1.5.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.1.5.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.1.6.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.1.6.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="
                    'pricingOldComponent.table.1.6.tooltip.2'
                      | transloco: { addOnName: 'models.billingInfo.billingAddOns.' + noBrandingAddOnId | transloco }
                  "
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.1.6.title.2' | transloco }}</span
                >
              </td>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="
                    'pricingOldComponent.table.1.6.tooltip.3'
                      | transloco: { addOnName: 'models.billingInfo.billingAddOns.' + noBrandingAddOnId | transloco }
                  "
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.1.6.title.3' | transloco }}</span
                >
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <!-- 2 -->
            <tr class="active">
              <td class="flex align-items-center">
                <img
                  class="margin-right-10"
                  src="assets/img/default/subscription/pricing/pricing-table-2.png"
                />
                <span class="font-weight-bold">{{ 'pricingOldComponent.table.2.title' | transloco }}</span>
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td
                *cqHideInRu
                class="font-weight-bold"
              >
                {{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}
              </td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.2.1.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.2.1.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.2.2.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.2.2.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.2.3.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.2.3.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.2.4.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.2.4.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                {{ 'pricingOldComponent.table.2.4.title.2' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.2.4.tooltip.2' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.2.4.title.3' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.2.4.tooltip.3' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.2.4.title.4' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.2.4.tooltip.4' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.2.5.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.2.5.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.2.6.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.2.6.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                {{ 'pricingOldComponent.table.2.6.title.2' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.2.6.tooltip.2' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.2.6.title.3' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.2.6.tooltip.3' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.2.6.title.4' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.2.6.tooltip.4' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
            </tr>
            <!-- 3 -->
            <tr class="active">
              <td class="flex align-items-center padding-right-20">
                <img
                  class="margin-right-10"
                  src="assets/img/default/subscription/pricing/pricing-table-3.png"
                />
                <span class="font-weight-bold">{{ 'pricingOldComponent.table.3.title' | transloco }}</span>
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td
                *cqHideInRu
                class="font-weight-bold"
              >
                {{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}
              </td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.3.1.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.3.1.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                {{ 'pricingOldComponent.table.3.1.title.2' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.1.tooltip.2' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.3.1.title.3' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.1.tooltip.3' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.3.1.title.4' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.1.tooltip.4' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.3.2.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.3.2.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                {{ 'pricingOldComponent.table.3.2.title.2' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.2.tooltip.2' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.3.2.title.3' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.2.tooltip.3' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.3.2.title.4' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.2.tooltip.4' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.3.3.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.3.3.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                {{ 'pricingOldComponent.table.3.3.title.2' | transloco }}
                <!-- NOTE: На России нет тултипа-->
                <i
                  *cqHideInRu
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.3.tooltip.2' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.3.3.title.3' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle -03x text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.3.tooltip.3' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.3.3.title.4' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.3.3.tooltip.4' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.3.4.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.3.5.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.3.5.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.3.6.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.3.7.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <!-- 4 -->
            <tr class="active">
              <td class="flex align-items-center">
                <img
                  class="margin-right-10"
                  src="assets/img/default/subscription/pricing/pricing-table-4.png"
                />
                <span class="font-weight-bold">{{ 'pricingOldComponent.table.4.title' | transloco }}</span>
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td
                *cqHideInRu
                class="font-weight-bold"
              >
                {{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}
              </td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.4.1.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.4.2.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.4.2.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.4.3.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.4.3.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                {{ 'pricingOldComponent.table.4.3.title.2' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.4.3.tooltip.2' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.4.3.title.3' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.4.3.tooltip.3' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.4.3.title.4' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.4.3.tooltip.4' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.4.4.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.4.4.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.4.5.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.4.5.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="
                    'pricingOldComponent.table.4.5.tooltip.2'
                      | transloco: { addOnName: 'models.billingInfo.billingAddOns.' + noBrandingAddOnId | transloco }
                  "
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.4.5.title.2' | transloco }}</span
                >
              </td>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="
                    'pricingOldComponent.table.4.5.tooltip.3'
                      | transloco: { addOnName: 'models.billingInfo.billingAddOns.' + noBrandingAddOnId | transloco }
                  "
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.4.5.title.3' | transloco }}</span
                >
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.4.6.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu>
                {{
                  'pricingOldComponent.table.4.6.title.2'
                    | transloco: { emailsCount: billingPlansSettings[billingPlans.FREEMIUM][slider.value].EMAILS }
                }}
              </td>
              <td>
                <span
                  *ngIf="slider.value === limitUniqueUsers"
                  class="margin-right-5"
                  >{{ 'pricingOldComponent.heroBlock.overLimit' | transloco }}</span
                >
                <span
                  *ngIf="slider.value < limitUniqueUsers"
                  class="margin-right-5"
                  >{{
                    'pricingOldComponent.table.4.6.title.3'
                      | transloco
                        : {
                            emailsCount:
                              (billingPlansSettings[billingPlans.BUSINESS_CHAT][slider.value].EMAILS | number)
                          }
                  }}</span
                >
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.4.6.tooltip.3' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                <span
                  *ngIf="slider.value === limitUniqueUsers"
                  class="margin-right-5"
                  >{{ 'pricingOldComponent.heroBlock.overLimit' | transloco }}</span
                >
                <span
                  *ngIf="slider.value < limitUniqueUsers"
                  class="margin-right-5"
                  >{{
                    'pricingOldComponent.table.4.6.title.4'
                      | transloco
                        : { emailsCount: (billingPlansSettings[billingPlans.AUTOMATION][slider.value].EMAILS | number) }
                  }}</span
                >
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.4.6.tooltip.4' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>{{ 'pricingOldComponent.table.4.6.title.5' | transloco }}</td>
            </tr>
            <!-- 5 -->
            <tr class="active">
              <td class="flex align-items-center">
                <img
                  class="margin-right-10"
                  src="assets/img/default/subscription/pricing/pricing-table-5.png"
                />
                <span class="font-weight-bold">{{ 'pricingOldComponent.table.5.title' | transloco }}</span>
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td
                *cqHideInRu
                class="font-weight-bold"
              >
                {{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}
              </td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.5.1.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.5.1.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                {{ 'pricingOldComponent.table.5.1.title.2' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.5.1.tooltip.2' | transloco"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.5.1.title.3' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.5.1.tooltip.3' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
              <td>
                {{ 'pricingOldComponent.table.5.1.title.4' | transloco }}
                <i
                  class="cqi-sm cqi-question-circle text-secondary cursor-pointer"
                  [ngbTooltip]="'pricingOldComponent.table.5.1.tooltip.4' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                ></i>
              </td>
            </tr>
            <tr>
              <td>
                <span *cqHideInUs>{{ 'pricingOldComponent.table.5.2.title.1' | transloco }}</span>
                <span
                  *cqHideInRu
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.5.2.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.5.2.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.5.3.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.5.3.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.5.4.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.5.4.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <!-- 6 -->
            <tr class="active">
              <td class="flex align-items-center padding-right-20">
                <img
                  class="margin-right-10"
                  src="assets/img/default/subscription/pricing/pricing-table-6.png"
                />
                <span class="font-weight-bold">{{
                  'pricingOldComponent.table.6.title' | transloco: { projectName: PROJECT_NAME }
                }}</span>
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td
                *cqHideInRu
                class="font-weight-bold"
              >
                {{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}
              </td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.1.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.1.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.2.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.2.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr *cqHideInRu>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.3.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.3.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr *cqHideInUs>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.4.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.4.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span *cqHideInUs>{{ 'pricingOldComponent.table.6.5.title.1' | transloco }}</span>
                <span
                  *cqHideInRu
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.5.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.5.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.6.tooltip.1' | transloco"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.6.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.7.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.7.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.8.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.8.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.9.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.9.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  class="cursor-pointer dashed"
                  [ngbTooltip]="'pricingOldComponent.table.6.10.tooltip.1' | transloco: { projectName: PROJECT_NAME }"
                  container="body"
                  placement="top-left"
                  >{{ 'pricingOldComponent.table.6.10.title.1' | transloco }}</span
                >
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <!-- 7 -->
            <tr class="active">
              <td class="flex align-items-center">
                <img
                  class="margin-right-10"
                  src="assets/img/default/subscription/pricing/pricing-table-7.png"
                />
                <span class="font-weight-bold">{{ 'pricingOldComponent.table.7.title' | transloco }}</span>
              </td>
              <!-- NOTE: На России нет Freemium-->
              <td
                *cqHideInRu
                class="font-weight-bold"
              >
                {{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}
              </td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</td>
              <td class="font-weight-bold">{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.7.1.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.7.2.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <tr>
              <td>{{ 'pricingOldComponent.table.7.3.title.1' | transloco }}</td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu></td>
              <td></td>
              <td></td>
              <td>
                <i class="cqi-sm cqi-check cqi-03x text-secondary"></i>
              </td>
            </tr>
            <!-- 8 -->
            <tr class="active">
              <td class="align-top"></td>
              <!-- NOTE: На России нет Freemium-->
              <td *cqHideInRu>
                <span>{{ 'pricingOldComponent.heroBlock.plan0.name' | transloco }}</span>
                <h3>
                  <span>
                    {{
                      'pricingOldComponent.heroBlock.plan0.price.value'
                        | transloco
                          : {
                              price:
                                (getPriceWithSale(billingPlansSettings[billingPlans.FREEMIUM][slider.value].PRICE)
                                | toFormattedMoney: 0)
                            }
                    }}
                  </span>
                </h3>
                <button
                  class="btn btn-lg btn-outline-primary margin-top-10"
                  (click)="trackClickChoosePlanButton(NEW_PLANS_NAMES.PLAN_0); sendChatMessage(NEW_PLANS_NAMES.PLAN_0)"
                  [disabled]="billingInfoModel.planId === billingPlans.FREEMIUM"
                >
                  {{ 'pricingOldComponent.heroBlock.plan0.connectButtonText' | transloco }}
                </button>
              </td>
              <td>
                <span>{{ 'pricingOldComponent.heroBlock.plan1.name' | transloco }}</span>
                <div
                  *ngIf="slider.value === limitUniqueUsers"
                  class="h3 margin-top-20"
                >
                  {{ 'pricingOldComponent.heroBlock.overLimit' | transloco }}
                </div>
                <div *ngIf="slider.value < limitUniqueUsers">
                  <span class="h3">
                    {{
                      'pricingOldComponent.heroBlock.plan1.price.value'
                        | transloco
                          : {
                              price:
                                (getPriceWithSale(billingPlansSettings[billingPlans.BUSINESS_CHAT][slider.value].PRICE)
                                | ceil
                                | toFormattedMoney: 0)
                            }
                    }}
                  </span>
                  <span>{{ 'pricingOldComponent.heroBlock.plan1.price.period' | transloco }}</span>
                  <span
                    *ngIf="chosenDiscount"
                    class="text-muted ml-5"
                  >
                    <small>
                      <s>
                        <span class="h5">
                          {{
                            'pricingOldComponent.heroBlock.plan1.price.value'
                              | transloco
                                : {
                                    price:
                                      (getPriceWithoutSale(
                                        billingPlansSettings[billingPlans.BUSINESS_CHAT][slider.value].PRICE
                                      ) | toFormattedMoney: 0)
                                  }
                          }}
                        </span>
                        <span>{{ 'pricingOldComponent.heroBlock.plan1.price.period' | transloco }}</span>
                      </s>
                    </small>
                  </span>
                </div>
                <button
                  class="btn btn-lg btn-outline-primary margin-top-10"
                  (click)="trackClickChoosePlanButton(NEW_PLANS_NAMES.PLAN_1); sendChatMessage(NEW_PLANS_NAMES.PLAN_1)"
                >
                  {{ 'pricingOldComponent.heroBlock.plan1.connectButtonText' | transloco }}
                </button>
              </td>
              <td>
                <span>{{ 'pricingOldComponent.heroBlock.plan2.name' | transloco }}</span>
                <div
                  *ngIf="slider.value === limitUniqueUsers"
                  class="h3 margin-top-20"
                >
                  {{ 'pricingOldComponent.heroBlock.overLimit' | transloco }}
                </div>
                <div *ngIf="slider.value < limitUniqueUsers">
                  <span class="h3">
                    {{
                      'pricingOldComponent.heroBlock.plan2.price.value'
                        | transloco
                          : {
                              price:
                                (getPriceWithSale(billingPlansSettings[billingPlans.AUTOMATION][slider.value].PRICE)
                                | ceil
                                | toFormattedMoney: 0)
                            }
                    }}
                  </span>
                  <span>{{ 'pricingOldComponent.heroBlock.plan2.price.period' | transloco }}</span>
                  <span
                    *ngIf="chosenDiscount"
                    class="text-muted ml-5"
                  >
                    <small>
                      <s>
                        <span class="h5">
                          {{
                            'pricingOldComponent.heroBlock.plan2.price.value'
                              | transloco
                                : {
                                    price:
                                      (getPriceWithoutSale(
                                        billingPlansSettings[billingPlans.AUTOMATION][slider.value].PRICE
                                      ) | toFormattedMoney: 0)
                                  }
                          }}
                        </span>
                        <span>{{ 'pricingOldComponent.heroBlock.plan2.price.period' | transloco }}</span>
                      </s>
                    </small>
                  </span>
                </div>
                <button
                  class="btn btn-lg btn-primary margin-top-10"
                  (click)="trackClickChoosePlanButton(NEW_PLANS_NAMES.PLAN_2); sendChatMessage(NEW_PLANS_NAMES.PLAN_2)"
                >
                  {{ 'pricingOldComponent.heroBlock.plan2.connectButton' | transloco }}
                </button>
              </td>
              <td>
                <span>{{ 'pricingOldComponent.heroBlock.plan3.name' | transloco }}</span>
                <h3>{{ 'pricingOldComponent.heroBlock.plan3.price.value' | transloco }}</h3>
                <button
                  class="btn btn-lg btn-outline-primary margin-top-10"
                  (click)="
                    trackClickChoosePlanButton(NEW_PLANS_NAMES.PLAN_3);
                    l10nHelper.isUsCountry() ? sendChatMessage(NEW_PLANS_NAMES.PLAN_3) : trackChoicePremium()
                  "
                >
                  {{ 'pricingOldComponent.heroBlock.plan3.callWithUsButton' | transloco }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>

<i
  *ngIf="channel.type !== CHANNEL_TYPES.MANUAL"
  class="cqi-channels"
  [ngClass]="[iconClass]"
></i>
<small
  *ngIf="channel.type === CHANNEL_TYPES.EMAIL"
  class="lettered-icon"
  >{{ parsedName }}</small
>
<small
  *ngIf="channel.type === CHANNEL_TYPES.FACEBOOK"
  class="lettered-icon"
  >{{ parsedName }}</small
>
<small
  *ngIf="channel.type === CHANNEL_TYPES.INSTAGRAM"
  class="lettered-icon"
  >{{ parsedName }}</small
>
<small
  *ngIf="channel.type === CHANNEL_TYPES.MOBILE"
  class="lettered-icon"
  >{{ parsedName }}</small
>
<!-- пока что аватарки у каналов нету, код закомменчен, чтобы уменьшить количество вотчеров
<div *ngIf="channel.type === CHANNEL_TYPES.MANUAL && channel.avatar" class="flex full-max-height full-max-width" ng-include="channel.avatar"></div>
<img *ngIf="channel.type === CHANNEL_TYPES.MANUAL && channel.avatar" class="full-max-height full-max-width" ng-src="{{avatar}}">-->
<div
  *ngIf="channel.type === CHANNEL_TYPES.MANUAL && !channel.avatar"
  class="title"
>
  {{ parsedName }}
</div>

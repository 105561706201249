import { PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MESSAGE_PART_TYPE_ICON_CLASSES, MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { TriggerChainMessageStep } from '@http/trigger-chain/internal-types';
import { TriggerChainGeneralStatisticsStateService } from '@panel/app/pages/trigger-chains/general-statistics/trigger-chain-general-statistics-state.service';

@Component({
  //eslint-disable-next-line @angular-eslint/component-selector
  selector: '[cq-trigger-chain-general-statistics-table-item]',
  templateUrl: './trigger-chain-general-statistics-table-item.component.html',
  styleUrls: ['./trigger-chain-general-statistics-table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainGeneralStatisticsTableItemComponent {
  @Input({ required: true })
  chainStep!: TriggerChainMessageStep;

  MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;

  constructor(
    private readonly percentPipe: PercentPipe,
    private readonly triggerChainGeneralStatisticsStateService: TriggerChainGeneralStatisticsStateService,
  ) {}

  get chainId() {
    return this.triggerChainGeneralStatisticsStateService.chain$.getValue()?.id!;
  }

  get clickedStats() {
    return this.chainStep.statistics.clicked;
  }

  get readStats() {
    return this.chainStep.statistics.read;
  }

  get repliedStats() {
    return this.chainStep.statistics.replied;
  }

  get sendedStats() {
    return this.chainStep.statistics.sended;
  }

  get unsubscribedStats() {
    return this.chainStep.statistics.unsubscribed;
  }

  getMessageTypeIcon(messageType: string | undefined): string {
    //@ts-ignore
    return MESSAGE_PART_TYPE_ICON_CLASSES[messageType];
  }

  getPercentStats(statValue: number): string | null {
    const value = statValue / this.sendedStats;

    return this.percentPipe.transform(value ? value : 0);
  }

  needToShowBlockPopUpsTipTooltip(): boolean {
    return [MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].includes(this.chainStep.type);
  }
}

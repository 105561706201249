(function () {
  'use strict';

  require('./trigger-chain-detailed-statistics.controller');
  require('./tabs/general.html');
  require('./tabs/sendings.html');

  angular
    .module('myApp.triggerChains.detailedStatistics')
    .component('cqTriggerChainDetailedStatistics', cqTriggerChainDetailedStatistics());

  function cqTriggerChainDetailedStatistics() {
    return {
      bindings: {
        billingInfo: '=', // Информация о биллинге
        chain: '=', // Триггерная цепочка
        currentApp: '=', // Текущее приложение
        currentMessage: '=', // Сообщение из триггерной цепочки
        djangoUser: '=', // Текущий пользователь
        pushSettings: '=', // Настройки пушей
        telegramIntegrations: '=',
      },
      controller: 'CqTriggerChainDetailedStatisticsController',
      controllerAs: 'vm',
      template: require('./trigger-chain-detailed-statistics.html'),
    };
  }
})();

/**
 * Возможные ошибки API
 */
export const WHATS_APP_EDNA_API_ERRORS = {
  INVALID_ACCESS_TOKEN: 'WhatsAppInvalidAccessToken', // Токен невалидный
  INVALID_CASCADE_ID: 'WhatsAppInvalidCascade', // Невалидный Cascade ID
  VALIDATION_ERROR: 'ValidationError', // Токен не удовлетворяет формату
};

/**
 * Регулярка для проверки валидности телефона
 */
export const WHATS_APP_EDNA_PHONE_REGEXP = /^\d{10,15}$/;

<ng-container *transloco="let t; read: 'messageEditorTriggerComponent'">
  <cq-message-editor-collapse [collapse]="false">
    <h4
      titleContent
      class="m-0 align-content-center flex-grow-1 no-flex-basis mt-5"
    >
      {{ t('heading') }}
    </h4>
    <ng-container expandContent>
      <cq-message-editor-trigger-select
        class="pb-15"
        [entityName]="translationEntityName"
        [selectedTriggerType]="selectedTriggerType"
        (selectedTriggerTypeChange)="onTriggerTypeSelect($event)"
      >
        <ng-container triggerTypesWebPageContent>
          <cq-message-editor-opened-web-page-trigger-types
            [formControl]="openedWebPageTriggers"
            [entityName]="translationEntityName"
          ></cq-message-editor-opened-web-page-trigger-types>
        </ng-container>
        <ng-container triggerTypesSdkPageContent>
          <cq-message-editor-opened-sdk-page-trigger-types
            [formControl]="openedSdkPageTriggers"
            [entityName]="translationEntityName"
          ></cq-message-editor-opened-sdk-page-trigger-types>
        </ng-container>
        <ng-container anotherEventContent>
          <cq-message-editor-another-events
            [formControl]="triggers"
            [entityName]="translationEntityName"
          ></cq-message-editor-another-events>
        </ng-container>
      </cq-message-editor-trigger-select>

      <!-- Отправлять с задержкой -->
      <cq-message-editor-sending-delay
        class="pt-15"
        [ngClass]="{ 'pb-15': isMessageFilterableByUrl() && isDispatchUrlConfigAllowed() }"
        [formControl]="delay"
        [entityName]="translationEntityName"
      >
      </cq-message-editor-sending-delay>

      <!-- Ограничить отправку на страницах -->
      <cq-message-editor-dispatch-url
        class="pt-15"
        *ngIf="isMessageFilterableByUrl() && isDispatchUrlConfigAllowed()"
        [formControl]="form.controls.sendingFilters"
        [entityName]="translationEntityName"
        [disableSwitch]="isSwitchDisabled()"
      ></cq-message-editor-dispatch-url>
    </ng-container>
    <ng-container collapsedContent>
      <!-- Фраза для триггера КОГДА ОТПРАВЛЯТЬ -->
      <div class="font-weight-medium">
        <!-- Что отправлять лидбот/email/сообщение и т.д. -->
        <span>
          {{
            t('eventSendingTime.sendMessage', {
              translationEntityName: translationEntityName
            }) | capitalize
          }}
        </span>

        <!-- Через какой промежуток времени отправлять через N времени -->
        <span *ngIf="delay.value.isEnabled">
          {{
            t('eventSendingTime.afterTime', {
              time: timeUnitService.getTranslation(this.delay.value.value.time, this.timeUnits, undefined, true)
            })
          }}
        </span>

        <!-- Тип отправки посетил сайт/попытался уйти с сайта/открыл конкретные страницы/выбрал другое событие  -->
        <span [ngSwitch]="true">
          <span *ngSwitchCase="triggers.value.length === 1 && triggers.value[0] === sessionStartEventTypeId">
            {{ t('eventSendingTime.afterVisitedWebSite') }}
          </span>
          <span *ngSwitchCase="leaveSiteAttemptTrigger.value">
            {{ t('eventSendingTime.afterLeaveSiteAttempt') }}
          </span>
          <span *ngSwitchCase="!!openedWebPageTriggers.value.length">
            {{
              t('eventSendingTime.afterSpecificPage', {
                triggerTypesCount: openedWebPageTriggers.value.length
              })
            }}
          </span>
          <span *ngSwitchDefault>
            {{ t('eventSendingTime.afterAnyEvent') }}
          </span>
        </span>
      </div>

      <div *ngFor="let trigger of triggers.value">
        <span
          class="text-secondary"
          *ngIf="trigger && getEventTypeByEventId(trigger).visible && trigger !== sessionStartEventTypeId"
        >
          - {{ trigger && getEventTypeByEventId(trigger).prettyName }}
        </span>

        <span *ngIf="trigger && isMessageEventTriggerRemoved(trigger)">
          <i
            *ngIf="triggers.value.length > 1; else deleteOneTooltip"
            class="cqi-sm cqi-triangle-exclamation text-danger"
            placement="top"
            container="body"
            [ngbTooltip]="t('choseTrigger.severalDeletedEventsTooltip')"
          ></i>
          <ng-template #deleteOneTooltip>
            <i
              class="cqi-sm cqi-triangle-exclamation text-danger"
              placement="top"
              container="body"
              [ngbTooltip]="t('choseTrigger.oneDeletedEventTooltip')"
            ></i>
          </ng-template>

          <span class="text-secondary"> {{ trigger && getMessageEventTriggerPrettyName(trigger) }}</span>
        </span>
        <span *ngIf="!trigger">
          <i class="cqi-sm cqi-triangle-exclamation-o text-danger"></i>
          <span class="text-danger"> {{ t('choseTrigger.triggersNotSelect') }} </span>
        </span>
      </div>

      <div *ngFor="let openedPageTrigger of openedWebPageTriggers.value; trackBy: openedPageTriggerTrackByFn">
        <span class="text-secondary">{{ t('triggerType.comparison.' + openedPageTrigger.comparison) }}: </span>
        <span class="text-secondary">{{ openedPageTrigger.url }}</span>
      </div>
    </ng-container>
  </cq-message-editor-collapse>
</ng-container>

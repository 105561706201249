import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { EditSavedReplyModalComponent } from './edit-saved-reply-modal.component';

@NgModule({
  declarations: [EditSavedReplyModalComponent],
  exports: [EditSavedReplyModalComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, SharedModule, NgSelectModule, FormsModule],
})
export class EditSavedReplyModalModule {}

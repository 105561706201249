<div class="modal-header pb-10">
  <h3 class="modal-title">{{ 'modals.' + auditOrDemo + '.title' | transloco }}</h3>
  <button
    class="close"
    (click)="ngbActiveModal.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>

<div
  *cqHideInUs
  class="modal-body"
>
  <div
    class="mb-15"
    [innerHTML]="'modals.' + auditOrDemo + '.description' | transloco: { projectName: PROJECT_NAME }"
  ></div>

  <form
    autocomplete="off"
    novalidate
  >
    <!-- input с именем -->
    <div
      class="form-group"
      [cqShowError]="getDemoForm.controls.name"
    >
      <label>{{ 'modals.getDemo.getDemoForm.nameInput.label' | transloco }}</label>
      <input
        class="form-control"
        placeholder="{{ 'modals.getDemo.getDemoForm.nameInput.placeholder' | transloco }}"
        required
        [formControl]="getDemoForm.controls.name"
      />
      <cq-validation-messages [control]="getDemoForm.controls.name">
        <cq-validation-message errorName="required">
          {{ 'modals.getDemo.getDemoForm.nameInput.errors.required' | transloco }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>

    <!-- input с телефоном/телеграмом -->
    <div
      class="form-group"
      [cqShowError]="getDemoForm.controls.phoneOrTelegram"
    >
      <label>{{ 'modals.getDemo.getDemoForm.phoneOrTelegramInput.label' | transloco }}</label>
      <input
        class="form-control"
        placeholder="{{ 'modals.getDemo.getDemoForm.phoneOrTelegramInput.placeholder' | transloco }}"
        required
        [formControl]="getDemoForm.controls.phoneOrTelegram"
      />
      <cq-validation-messages [control]="getDemoForm.controls.phoneOrTelegram">
        <cq-validation-message errorName="required">
          {{ 'modals.getDemo.getDemoForm.phoneOrTelegramInput.errors.required' | transloco }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>

    <!-- ui-select с выбором сайтов -->
    <div
      *ngIf="isAppExists"
      class="form-group"
    >
      <label>{{ 'modals.getDemo.getDemoForm.selectedAppSelect.label' | transloco }}</label>
      <ng-select
        [clearable]="false"
        [formControl]="getDemoForm.controls.selectedApp"
        [items]="appList"
        bindLabel="name"
        bindValue="id"
        placeholder="{{ 'modals.getDemo.getDemoForm.selectedAppSelect.placeholder' | transloco }}"
      >
        <ng-container *ng-footer-tmp>
          <button
            class="btn btn-secondary btn-block"
            type="button"
            (click)="isAppExists = !isAppExists"
          >
            {{ 'modals.getDemo.getDemoForm.selectedAppSelect.actionText' | transloco }}
          </button>
        </ng-container>
      </ng-select>
    </div>

    <!-- input для ввода сайта -->
    <div
      *ngIf="!isAppExists"
      class="form-group"
    >
      <label>{{ 'modals.getDemo.getDemoForm.inputAppInput.label' | transloco }}</label>
      <div [ngClass]="{ 'input-group': !appListEmpty }">
        <input
          class="form-control"
          placeholder="{{ 'modals.getDemo.getDemoForm.inputAppInput.placeholder' | transloco }}"
          [formControl]="getDemoForm.controls.inputApp"
        />
        <span
          *ngIf="!appListEmpty"
          class="input-group-append"
        >
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="isAppExists = !isAppExists"
          >
            <i class="btn-icon cqi-sm cqi-times"></i>
          </button>
        </span>
      </div>
    </div>
  </form>
</div>

<div
  *cqHideInRu
  class="modal-body no-padding"
>
  <iframe
    frameborder="0"
    width="100%"
    height="679"
    src="https://calendar.app.google/tYSmpNV1nZMSXVMLA"
  ></iframe>
</div>

<div
  *cqHideInUs
  class="modal-footer"
>
  <button
    class="btn btn-primary"
    type="button"
    [disabled]="getDemoForm.invalid"
    (click)="getDemo(getDemoForm.valid)"
  >
    {{ 'modals.getDemo.confirmButton' | transloco }}
  </button>
</div>

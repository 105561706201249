<ng-container *transloco="let t; read: 'faqComponent'">
  <ng-container *tuiLet="subscriptionStore.billing$ | async as billing">
    <h4 class="mb-15 font-weight-bold">{{ t('title') }}</h4>

    <ngb-accordion
      #a="ngbAccordion"
      class="accordion"
    >
      <ngb-panel
        id="question1"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question1'); trackClickQuestion(1)"
          >
            <b>{{ t('items.item1.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>{{ t('items.item1.answer.part1.content') }}</div>
            <div>
              {{
                t('items.item1.answer.part2.content', {
                  costOverspendingUniqueUserPerOne: costOverspendingUniqueUserPerOne | toMoney | toFormattedMoney
                })
              }}
            </div>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        id="question2"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question2'); trackClickQuestion(2)"
          >
            <b>{{ t('items.item2.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>
              <div>{{ t('items.item2.answer.part1.title') }}</div>
              <ul class="pl-15 mb-0">
                <li>{{ t('items.item2.answer.part1.list.item1') }}</li>
                <li>{{ t('items.item2.answer.part1.list.item2') }}</li>
                <li>{{ t('items.item2.answer.part1.list.item3') }}</li>
                <li>{{ t('items.item2.answer.part1.list.item4') }}</li>
              </ul>
            </div>
            <div>
              {{
                t('items.item2.answer.part2.content', {
                  costOverspendingEmailPerOne: costOverspendingEmailPerOne | toMoney | toFormattedMoney
                })
              }}
            </div>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="isRusCountry && billing && billing.subscription.isActive"
        id="question3"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question3'); trackClickQuestion(3)"
          >
            <b>{{ t('items.item3.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>
              <div>
                <b>{{ t('items.item3.answer.part1.title') }}</b>
              </div>
              <div [innerHTML]="t('items.item3.answer.part1.content')"></div>
            </div>
            <div>
              <div>
                <b>{{ t('items.item3.answer.part2.title') }}</b>
              </div>
              <div [innerHTML]="t('items.item3.answer.part2.content', { emailSupport })"></div>
            </div>
            <div>
              <div>
                <b>{{ t('items.item3.answer.part3.title') }}</b>
              </div>
              <div [innerHTML]="t('items.item3.answer.part3.content', { emailSupport })"></div>
            </div>
            <div>
              <a
                [href]="t('items.item3.answer.part4.link.href')"
                target="_blank"
              >
                {{ t('items.item3.answer.part4.link.text') }}
              </a>
            </div>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        id="question4"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question4'); trackClickQuestion(4)"
          >
            <b>{{ t('items.item4.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div [innerHTML]="t('items.item4.answer.part1.content')"></div>
            <div [innerHTML]="t('items.item4.answer.part2.content', {nameProject})"></div>
            <div [innerHTML]="t('items.item4.answer.part3.content')"></div>

            <ng-container *ngIf="isRusCountry">
              <a
                [href]="t('items.item4.answer.part4.link.href')"
                target="_blank"
              >
                {{ t('items.item4.answer.part4.link.text') }}
              </a>
            </ng-container>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        id="question5"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question5'); trackClickQuestion(5)"
          >
            <b>{{ t('items.item5.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div [innerHTML]="t('items.item5.answer.part1.content')"></div>
            <div [innerHTML]="t('items.item5.answer.part2.content')"></div>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="isRusCountry"
        id="question6"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question6'); trackClickQuestion(6)"
          >
            <b>{{ t('items.item6.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>
              <div>{{ t('items.item6.answer.part1.title') }}</div>
              <ul class="pl-15 mb-0">
                <li>{{ t('items.item6.answer.part1.list.item1') }}</li>
                <li>{{ t('items.item6.answer.part1.list.item2') }}</li>
                <li>{{ t('items.item6.answer.part1.list.item3') }}</li>
              </ul>
            </div>
            <div>
              <div>{{ t('items.item6.answer.part2.title') }}</div>
              <ol class="pl-15 mb-0">
                <li>{{ t('items.item6.answer.part2.list.item1') }}</li>
                <li>{{ t('items.item6.answer.part2.list.item2', {nameProject}) }}</li>
                <li>{{ t('items.item6.answer.part2.list.item3') }}</li>
              </ol>
            </div>
            <a
              [href]="t('items.item6.answer.part3.link.href')"
              target="_blank"
            >
              {{ t('items.item6.answer.part3.link.text') }}
            </a>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="billing && billing.subscription.isActive"
        id="question7"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question7'); trackClickQuestion(7)"
          >
            <b>{{ t('items.item7.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>{{ t('items.item7.answer.part1.content', {nameProject}) }}</div>
            <div>{{ t('items.item7.answer.part2.content', {nameProject}) }}</div>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="billing && billing.subscription.isActive"
        id="question8"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question8'); trackClickQuestion(8)"
          >
            <b>{{ t('items.item8.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>{{ t('items.item8.answer.part1.content', {nameProject}) }}</div>
            <div>{{ t('items.item8.answer.part2.content') }}</div>
            <div>{{ t('items.item8.answer.part3.content') }}</div>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="billing && billing.subscription.isActive"
        id="question9"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question9'); trackClickQuestion(9)"
          >
            <b>{{ t('items.item9.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>{{ t('items.item9.answer.part1.content') }}</div>
            <div [innerHTML]="t('items.item9.answer.part2.content')"></div>

            <ng-container *ngIf="isRusCountry">
              <a
                [href]="t('items.item9.answer.part3.link.href')"
                target="_blank"
              >
                {{ t('items.item9.answer.part3.link.text') }}
              </a>
            </ng-container>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="billing && billing.subscription.isActive"
        id="question10"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question10'); trackClickQuestion(10)"
          >
            <b>{{ t('items.item10.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>{{ t('items.item10.answer.part1.content') }}</div>

            <div>
              <div>
                <b>{{ t('items.item10.answer.part2.title') }}</b>
              </div>
              <div>{{ t('items.item10.answer.part2.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item10.answer.part3.title') }}</b>
              </div>
              <div>{{ t('items.item10.answer.part3.content') }}</div>
            </div>

            <ng-container *ngIf="isRusCountry">
              <div>{{ t('items.item10.answer.part4.content') }}</div>
              <a
                [href]="t('items.item10.answer.part5.link.href')"
                target="_blank"
              >
                {{ t('items.item10.answer.part5.link.text') }}
              </a>
            </ng-container>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="billing && billing.subscription.isActive"
        id="question11"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question11'); trackClickQuestion(11)"
          >
            <b>{{ t('items.item11.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>
              <div [innerHTML]="t('items.item11.answer.part1.content', {nameProject})"></div>
            </div>
          </cq-item-content>
        </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="billing && billing.subscription.isActive"
        id="question12"
        [cardClass]="'card'"
      >
        <ng-template
          ngbPanelHeader
          let-opened="opened"
        >
          <cq-item-header
            [isOpened]="opened"
            (toggle)="a.toggle('question12'); trackClickQuestion(12)"
          >
            <b>{{ t('items.item12.question') }}</b>
          </cq-item-header>
        </ng-template>
        <ng-template ngbPanelContent>
          <cq-item-content>
            <div>
              <div>
                <b>{{ t('items.item12.answer.part1.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part1.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part2.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part2.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part3.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part3.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part4.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part4.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part5.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part5.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part6.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part6.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part7.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part7.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part8.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part8.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part9.title') }}</b>
              </div>
              <div>{{ t('items.item12.answer.part9.content') }}</div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part10.title') }}</b>
              </div>
              <div [innerHTML]="t('items.item12.answer.part10.content', { emailSupport })"></div>
            </div>

            <div>
              <div>
                <b>{{ t('items.item12.answer.part11.title') }}</b>
                <div>{{ t('items.item12.answer.part11.list.item1') }}</div>
                <div>{{ t('items.item12.answer.part11.list.item2') }}</div>
                <div>{{ t('items.item12.answer.part11.list.item3') }}</div>
                <ng-container *ngIf="isRusCountry">
                  <div>{{ t('items.item12.answer.part11.list.item4') }}</div>
                  <div>{{ t('items.item12.answer.part11.list.item5') }}</div>
                  <div>{{ t('items.item12.answer.part11.list.item6') }}</div>
                  <div>{{ t('items.item12.answer.part11.list.item7') }}</div>
                  <div>{{ t('items.item12.answer.part11.list.item8') }}</div>
                  <div>{{ t('items.item12.answer.part11.list.item9') }}</div>
                </ng-container>
              </div>
            </div>
          </cq-item-content>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-container>
</ng-container>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Output } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';
import { UserSystemProperty } from '@http/property/property.constants';
import { TagModel } from '@http/tag/tag.model';
import { User } from '@http/user/types/user.type';
import { UserModel } from '@http/user/user.model';
import { OnOpenConversationClick } from '@panel/app/pages/conversations/user-card/user-card.types';
import { BillingInfo } from '@panel/app/services/billing-info/billing-info.model';
import { LS_UNCOLLAPSED_USER_CARD_BLOCKS } from '@panel/app/shared/constants/localstorage.keys';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Component({
  selector: 'cq-conversations-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsUserCardComponent implements OnInit {
  /** ИД пользователя */
  _usedId: string = '';

  /** Информация биллинга */
  @Input()
  billingInfo!: BillingInfo;

  /** Текущий апп */
  @Input()
  currentApp!: App;

  /** Джанго юзер */
  @Input()
  djangoUser: any;

  @Input({ required: true })
  telegramIntegrations: TelegramIntegration[] = [];

  /** Установка значения _userId и обновление свойств */
  @Input()
  set userId(id: string) {
    this._usedId = id;
    this.updateProps();
  }

  /** Клик на переход в диалог */
  @Input()
  onOpenConversationClick!: OnOpenConversationClick;

  /** Колбек на обновление активного диалога */
  @Output()
  updateActiveConversation: Observable<string> = new Observable();

  FEATURES = FEATURES;

  /** Кастомные свойства пользователя */
  customProps: Array<{ key: string; value: string }> = [];

  /** Закрепленные свойства пользователя */
  pinnedProps: string[] = [];

  /** Пользователь */
  user!: User;

  /** Статус загрузки пользователя */
  userLoading: boolean = false;

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly cdr: ChangeDetectorRef,
    private readonly tagModel: TagModel,
    private readonly userModel: UserModel,
    private readonly uibModalService: UibModalService,
    public readonly featureModel: FeatureModel,
  ) {}

  ngOnInit() {
    firstValueFrom(this.userModel.getPinnedProps()).then((pinnedPropsArray: string[]) => {
      this.pinnedProps = pinnedPropsArray;
    });
  }

  /**
   * Изменение порядка закрепленных св-в
   *
   * @param pinnedPropsList
   */
  changePinnedPropsOrder(pinnedPropsList: Array<UserSystemProperty | '$carrotId' | '$userId' | string> = []) {
    this.pinnedProps = [...pinnedPropsList];
    firstValueFrom(this.userModel.setPinnedProps(this.pinnedProps, this.currentApp.id, this.djangoUser.id));
  }

  /**
   * Включены ли настройки о соглашениях
   */
  consentSettingsEnabled(): boolean {
    return (
      this.currentApp.settings.data_processing_policy || this.currentApp.settings.messenger_show_confirm_subscription
    );
  }

  openUserCard() {
    this.carrotquestHelper.track('Диалоги - открыл карточку пользователя', { App: this.currentApp.name });

    this.uibModalService.open({
      component: 'cqUserCardModal',
      size: 'md modal-dialog-centered',
      windowClass: 'user-card-modal',
      resolve: {
        modalWindowParams: () => {
          return {
            billingInfo: this.billingInfo,
            currentApp: this.currentApp,
            djangoUser: this.djangoUser,
            onOpenConversationClick: this.onOpenConversationClick,
            userId: this.user.id,
            updateActiveConversation: this.updateActiveConversation,
            telegramIntegrations: this.telegramIntegrations,
          };
        },
      },
    });
  }

  trackClickOpenEditPropModal() {
    this.carrotquestHelper.track('Диалоги - клик на "Изменить свойство"');
  }

  /**
   * Обновление свойств пользователя, по факту перезагрузка карточки
   */
  updateProps() {
    this.userLoading = true;
    this.userModel
      .get(this._usedId, null)
      .pipe(
        map((res: User) => {
          return this.getUserSuccess(res);
        }),
      )
      .subscribe();
  }

  /**
   * Колбек на успешный запрос получения пользователя
   * @param user
   */
  getUserSuccess(user: User) {
    this.user = user;
    this.user.props.$banned = this.user.props.$banned || false;

    for (let i = 0; i < this.user.tags.length; i++) {
      let tag = this.user.tags[i];
      tag.name = JSON.parse(tag.name);
    }

    this.sortProps();
  }

  /**
   * Заппинивание св-ва
   * @param prop
   */
  addPinnedProp(prop: string) {
    this.carrotquestHelper.track('Карточка пользователя - закрепил свойство');
    this.pinnedProps = [prop, ...this.pinnedProps];
    this.userModel.setPinnedProps(this.pinnedProps, this.currentApp.id, this.djangoUser.id);
  }

  /**
   * Удаление запиненного св-ва
   * @param prop
   */
  removePinnedProp(prop: string) {
    this.pinnedProps = this.pinnedProps.filter((propName: UserSystemProperty | string | string[]) => prop !== propName);
    firstValueFrom(this.userModel.setPinnedProps(this.pinnedProps, this.currentApp.id, this.djangoUser.id));
  }

  /**
   * Сортировка свойств пользователя
   */
  sortProps() {
    for (let key in this.user.propsCustom) {
      this.customProps.push({
        key,
        value: this.user.propsCustom[key] as string,
      });
    }
    this.userLoading = false;
    this.cdr.markForCheck();
  }

  /**
   * Переключение состояния свернутости блока в LS
   */
  toogleLSUncollapsedBlock(LSName: string) {
    let LSValue = this.windowRef.localStorage.getItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS);
    if (LSValue) {
      // Если есть значение, то убираем его
      if (LSValue.includes(LSName)) {
        LSValue = LSValue.replace(LSName, '');
        // Если нет, то добавляем
      } else {
        LSValue += ` ${LSName}`;
      }
      this.windowRef.localStorage.setItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS, LSValue.trim());
      // Если записи в LS нет, то добавляем ее
    } else {
      this.windowRef.localStorage.setItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS, LSName);
    }
  }
}

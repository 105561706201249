import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AMOCRM_STANDARD_PROPS_ARRAY } from '@http/integration/integrations/amo/constants/amocrm-integration.constants';
import { AmocrmMappingForm } from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { AmocrmField } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { DestroyService } from '@panel/app/services';
import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { PseudoMap } from '@panel/app/shared/types/pseudo-map.type';

/**
 * Компонент для работы данными, которые передаются в контакты AmoCRM
 */

@Component({
  selector: 'cq-amocrm-props-to-contact[integrationId][userEventsTypesGroup][userPropsGroup]',
  templateUrl: './amocrm-props-to-contact.component.html',
  styleUrls: ['./amocrm-props-to-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AmocrmPropsToContactComponent implements OnInit {
  /** ID интеграции */
  @Input()
  integrationId!: string;

  /** Контрол с типами событий, которые передаются из лида Carrot quest в контакт amoCRM */
  @Input()
  userEventsTypesGroup!: GenericFormArray<AmocrmMappingForm>;

  /** Контрол со свойствами, которые передаются из лида Carrot quest в контакт amoCRM */
  @Input()
  userPropsGroup!: GenericFormArray<AmocrmMappingForm>;

  /** Инстанс коллапса */
  @ViewChild(NgbCollapse)
  collapse!: NgbCollapse;

  /** Поля контакта amoCRM */
  amocrmContactFields: AmocrmField[] = [];

  /** Флаг свёрнутости списка данных, которые передаются в контакты amoCRM */
  isCollapsed: boolean = true;

  /** Название проекта */
  projectName = environment.projectName;

  /**
   * Выбранные имена полей контактов amoCRm
   */
  chosenPropFields: string[] = [];

  /** Свойства, которые по умолчанию отправляются из CQ в amoCRM */
  STANDARD_PROPS_DEFAULT = [
    {
      value: '$name',
      key: '$name',
    },
    {
      value: '$email',
      key: '$email',
    },
    {
      value: '$phone',
      key: '$phone',
    },
  ];

  /** Массив с переводами системных полей контакта amoCRM */
  standardPropsArray: AmocrmField[] = this.getStandardPropsArray();

  /** Форма со стандартными свойствами */
  standardPropsFormArray!: GenericFormArray<GenericFormGroup<PseudoMap>>;

  /** Получение названия интеграции */
  get integrationTypeName() {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  /** Получение количества выбранных свойств и событий */
  get userPropsCount() {
    return [
      ...this.standardPropsFormArray.controls,
      ...this.userEventsTypesGroup.controls,
      ...this.userPropsGroup.controls,
    ].length;
  }

  constructor(
    public readonly amocrmState: AmocrmStateService,
    @Inject(AmocrmService) private readonly amocrmService: AmocrmService,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
    private readonly destroy$: DestroyService,
    private readonly formBuilder: GenericFormBuilder,
  ) {
    this.standardPropsFormArray = formBuilder.array<GenericFormGroup<PseudoMap>>(
      this.STANDARD_PROPS_DEFAULT.map(
        (property) =>
          new GenericFormGroup({
            key: new GenericFormControl({
              value: property.key,
              disabled: true,
            }),
            value: new GenericFormControl({
              value: this.translocoService.translate(
                `amocrmPropsToContactComponent.standardPropsList.${property.value}`,
              )!,
              disabled: true,
            }),
          }),
      ),
    );
  }

  ngOnInit(): void {
    this.amocrmState.contactFields$.subscribe((fields) => {
      // Надо подмешать в пришедший список полей контакта системные поля
      this.amocrmContactFields = [...this.standardPropsArray, ...fields];
    });

    // Следим за изменениями статусов валидности и touched-статуса, чтобы раскрыть коллапс этого компонента,
    // если один из контролов невалидный
    combineLatest(
      extractTouchedChanges(this.userPropsGroup),
      extractTouchedChanges(this.userEventsTypesGroup),
      this.userPropsGroup.statusChanges.pipe(
        startWith(this.userPropsGroup.status),
        map((status) => status === 'INVALID'),
      ),
      this.userEventsTypesGroup.statusChanges.pipe(
        startWith(this.userEventsTypesGroup.status),
        map((status) => status === 'INVALID'),
      ),
    )
      .pipe(map((statuses) => [statuses[2], statuses[3]].some((value) => value)))
      .subscribe((invalid) => {
        if (invalid && this.collapse.collapsed) {
          this.collapse.toggle(true);
        }
      });

    combineLatest([
      this.userPropsGroup.valueChanges.pipe(startWith(this.userPropsGroup.value)),
      this.userEventsTypesGroup.valueChanges.pipe(startWith(this.userEventsTypesGroup.value)),
    ])
      .pipe(
        map(([userProps, userEventsTypes]) => {
          //@ts-ignore
          return [...userProps.map((v) => v.key), ...userEventsTypes.map((v) => v.key)];
        }),
      )
      .subscribe((chosenPropFields: string[]) => {
        this.chosenPropFields = chosenPropFields;
      });
  }

  /** Добавление группы с контролами события, которое отправляется в контакт amoCRM */
  addEvent(): void {
    const propertyControl = this.getFormGroup();

    this.userEventsTypesGroup.push(propertyControl);
  }

  /** Добавление группы с контролами свойства, которое отправляется в контакт amoCRM */
  addProperty(): void {
    const propertyControl = this.getFormGroup();

    this.userPropsGroup.push(propertyControl);
  }

  /** Создание группы AmocrmMappingForm с контролами */
  getFormGroup() {
    const controlData = {};

    return new AmocrmMappingForm(controlData);
  }

  /** Получение массива с переводами системных полей контакта amoCRM */
  getStandardPropsArray(): AmocrmField[] {
    return AMOCRM_STANDARD_PROPS_ARRAY.map((property) => {
      const name = this.translocoService.translate(
        `amocrmDataFromContactsComponent.standardFieldName.${property.name}`,
      );
      const fieldType = property.fieldType;
      return { name, fieldType };
    });
  }

  /** Удаление элемента из массива контролов */
  removeItem(formArray: GenericFormArray<AmocrmMappingForm>, index: number) {
    formArray.removeAt(index);
  }
}

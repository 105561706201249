import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';

@Component({
  selector: 'cq-move-start-guide-modal',
  templateUrl: './move-start-guide-modal.component.html',
  styleUrls: ['./move-start-guide-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveStartGuideModalComponent {
  @Input()
  ajsModalInstance?: any;

  STARTER_GUIDE_STEPS = STARTER_GUIDE_STEPS;

  constructor(private readonly ngbActiveModal: NgbActiveModal) {}

  closeModal() {
    this.ajsModalInstance ? this.ajsModalInstance.close() : this.ngbActiveModal.close();
  }
}

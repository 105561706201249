import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

/** Компонент для работы с шагом стартергайда "Создание лид-бота" */
@Component({
  selector: 'cq-starter-guide-creating-leadbot[isStepMade][intentToCreateLeadbot][intentToCreateLeadbotFormTemplate]',
  templateUrl: './starter-guide-creating-leadbot.component.html',
  styleUrls: ['./starter-guide-creating-leadbot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarterGuideCreatingLeadbotComponent {
  /** Пройден ли текущий шаг */
  @Input()
  isStepMade!: boolean;

  /** Callback на намеренье создать лид-бота */
  @Output()
  intentToCreateLeadbot: EventEmitter<void> = new EventEmitter<void>();

  /** Callback на намеренье создать лид-бота из шаблона */
  @Output()
  intentToCreateLeadbotFormTemplate: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly translocoService: TranslocoService) {}

  /** Возвращает путь до изображения */
  getSrc(): string {
    return `assets/img/${this.translocoService.getActiveLang()}/starter-guide/creating-leadbot/creating-leadbot.png`;
  }

  /** Обрабатывает клик на кнопку создания лид-бота */
  onClickCreatingLeadbotButton(): void {
    this.intentToCreateLeadbot.emit();
  }

  /** Обрабатывает клик на кнопку создания лид-бота с шаблона */
  onClickCreatingLeadbotFromTemplateButton(): void {
    this.intentToCreateLeadbotFormTemplate.emit();
  }
}

import { MESSENGER_POSITION } from '../../../../app/http/app/app.constants';

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqWidgetMobileIndentController', CqWidgetMobileIndentController);

  function CqWidgetMobileIndentController(validationHelper) {
    let vm = this;

    /**
     * Максимальный отступ кнопки, в пикселях
     *
     * @type {number}
     */
    const MAX_INDENT = 100;

    /**
     * Минимальный отступ кнопки, в пикселях
     *
     * @type {number}
     */
    const MIN_INDENT = 25;

    vm.$onInit = init;

    function init() {
      vm.MAX_INDENT = MAX_INDENT;
      vm.MESSENGER_POSITION = MESSENGER_POSITION;
      vm.MIN_INDENT = MIN_INDENT;
      vm.validationHelper = validationHelper;
    }
  }
})();

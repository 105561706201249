(function () {
  'use strict';

  require('./wizard.controller');

  angular.module('myApp.directives.wizard').directive('cqWizard', cqWizard);

  function cqWizard() {
    return {
      restrict: 'E',
      require: 'cqWizard',
      scope: {
        lastCompletedStepName: '=?lastCompletedStep',
        restricted: '=?',
        currentStepName: '=?currentStep',
        menuClass: '@?',
        newWizard: '=?',
      },
      controller: 'CqWizardController',
      controllerAs: 'vm',
      bindToController: true,
      transclude: true,
      link: link,
      template: require('./wizard.html'),
    };

    function link(scope, iElement, iAttrs, controller) {
      scope.$watch('vm.currentStepName', function (newValue, oldValue) {
        if (angular.isDefined(newValue) && newValue != null) {
          controller.goToStepWithoutValidation(controller.getStepByName(newValue));
        }
      });

      scope.$watch('vm.lastCompletedStepName', function (newValue, oldValue) {
        if (angular.isDefined(newValue) && newValue != null) {
          controller.setLastCompletedStep(controller.getStepByName(newValue));
        }
      });

      scope.$on('$destroy', controller.destroy);
    }
  }
})();

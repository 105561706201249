import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { NotAssignedComponent } from './not-assigned.component';

@NgModule({
  declarations: [NotAssignedComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [NotAssignedComponent],
})
export class NotAssignedModule {}

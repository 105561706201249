(function () {
  'use strict';

  require('./statistics-wrapper.controller');

  angular.module('myApp.chatBot').component('cqStatisticsWrapper', cqStatisticsWrapper());

  function cqStatisticsWrapper() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        botType: '=', // Тип бота NOTE lead | routing | telegram
        botEditId: '=', // ID бота, который используется для захода в редактор
        botName: '=', // Имя бота
      },
      controller: 'CqStatisticsWrapperController',
      controllerAs: 'vm',
      transclude: true,
      template: require('./statistics-wrapper.html'),
    };
  }
})();

/**
 * Контроллер выбора типа автособытия и типа параметров автособытия
 */
import {
  AUTO_EVENTS_PARAMS_TYPES,
  AUTO_EVENTS_PARAMS_TYPES_LIST,
  AUTO_EVENTS_TYPES_LIST,
} from '../../../../../app/http/track-master/track-master.constants';

(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventTypeController', CqAutoEventTypeController);

  function CqAutoEventTypeController(validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.AUTO_EVENTS_PARAMS_TYPES = AUTO_EVENTS_PARAMS_TYPES;
      vm.AUTO_EVENTS_PARAMS_TYPES_LIST = AUTO_EVENTS_PARAMS_TYPES_LIST;
      vm.AUTO_EVENTS_TYPES_LIST = AUTO_EVENTS_TYPES_LIST;
      vm.isShowDescription = getIsShowDescription(); // Показывать описание или нет
      vm.removeDescription = removeDescription;
      vm.validationHelper = validationHelper;
    }

    /**
     * Удаление описания
     */
    function removeDescription() {
      vm.description = '';
      vm.isShowDescription = false;
    }

    /**
     * Получение начального состояния отображения описания
     *
     * @returns {boolean}
     */
    function getIsShowDescription() {
      return !!vm.description;
    }
  }
})();

import { LS_PINNED_PROPS } from '../../../../app/shared/constants/localstorage.keys';
import { PSEUDO_CHANNEL_TYPES } from '../../../../app/http/channel/channel.constants';
import { firstValueFrom } from 'rxjs';
import { STARTER_GUIDE_STEPS } from '../../../../app/http/starter-guide/starter-guide.constants';
import { SYSTEM_LOG_MESSAGE_TYPES } from '../../../../app/http/system-log/system-log.constants';

(function () {
  'use strict';

  angular
    .module('myApp.conversations')
    .controller('CqConversationsGeneralController', CqConversationsGeneralController);

  function CqConversationsGeneralController(
    $filter,
    $scope,
    $state,
    $translate,
    $window,
    moment,
    CDN_ENDPOINT,
    CONVERSATION_WITH_SELF_WINDOW_PARAMS,
    FIRST_DIALOG_URL,
    carrotquestHelper,
    conversationTagsModel,
    conversationsStoreService,
    featureModel,
    l10nHelper,
    starterGuideModel,
    userModel,
    systemError,
    utilsService,
  ) {
    var vm = this;

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      trackEnterOnPage();
      conversationsStoreService.channelsCounter$.next(vm.channelCounters);
      conversationsStoreService.currentApp$.next(vm.currentApp);
      conversationsStoreService.djangoUser$.next(vm.djangoUser);
      conversationsStoreService.teamMembers$.next(vm.teamMembers);

      vm.activateConversation = activateConversation;
      vm.activeConversationId = undefined; // ID текущего диалога
      vm.activeUserId = null;
      vm.channel = $filter('filter')(vm.channels, { type: PSEUDO_CHANNEL_TYPES.ALL_CHANNELS }, true)[0];
      vm.darkThemeChange = darkThemeChange;
      vm.isDarkThemeActive = utilsService.isDarkThemeActive();
      vm.isFirstDialog = isShowFirstDialogScreen();
      vm.isHistoryShowed = false; // показывается ли сейчас история диалогов
      vm.onChannelChange = onChannelChange;
      vm.onHideHistory = onHideHistory;
      vm.onShowHistory = onShowHistory;
      vm.tags = null;
      vm.toggleUserListVisibility = toggleUserListVisibility;
      vm.trackOpenUserList = trackOpenUserList;
      vm.trackChoseUseInUserList = trackChoseUseInUserList;
      vm.trackUseSearchInUserList = trackUseSearchInUserList;
      vm.updateActiveConversation = updateActiveConversation;
      vm.conversationUserSelect = conversationUserSelect;
      vm.userRemoved = false;
      vm.isUserListVisible = false; // Видимость списка пользователей

      if (utilsService.isDarkThemeActive()) {
        carrotquestHelper.identify({ 'Тема диалогов': 'тёмная' });
      } else {
        carrotquestHelper.identify({ 'Тема диалогов': 'светлая' });
      }

      // Если пользователь начал переходить по другим страницам
      // и у него появлися первый диалог
      if (vm.conversations.conversations.length > 0) {
        vm.currentApp.activation.reply_user = moment();
      }

      getConversationTags();
      firstValueFrom(userModel.getPinnedProps(vm.currentApp.id, vm.djangoUser.id, true)).then((pinnedProps) => {
        localStorage.setItem(LS_PINNED_PROPS, JSON.stringify(pinnedProps));
      });

      $scope.$on('message', handleRts);

      // Добавляем слушателей на разрыв и восстановление интернет-соединения
      $window.addEventListener('online', onOnline);
      $window.addEventListener('offline', onOffline);

      function getConversationTags() {
        firstValueFrom(conversationTagsModel.getList(vm.currentApp.id)).then(getTagsListSuccess);

        function getTagsListSuccess(tags) {
          vm.tags = [];
          for (var i = 0; i < tags.length; i++) {
            vm.tags.push({ tag: tags[i] });
          }
          conversationsStoreService.tags$.next(vm.tags);
          $scope.$applyAsync();
        }
      }
    }

    function destroy() {
      systemError.offlineConversationsToast.hide();
      systemError.RTSConnectionProblemToast.hide();

      $window.removeEventListener('online', onOnline);
      $window.removeEventListener('offline', onOffline);

      // Удаляем слушатели связанные с window диалога с самим собой, если такой window закрыт
      if (!$window[CONVERSATION_WITH_SELF_WINDOW_PARAMS.NAME]) {
        removeEventListeners();
      }
    }

    function activateConversation(conversation) {
      vm.activeConversation = conversation;
      vm.activeConversationId = conversation ? conversation.id : null;
      vm.isHistoryShowed = false;
      vm.activeUserId = conversation ? conversation.user.id : -1; // HACK: на данный момент при удалении диалога карточку подругому не сбросить, при этом вылетит ошибка "Что-то пошло не так"
      vm.userRemoved = conversation ? false : vm.userRemoved;
      $scope.$applyAsync();
    }

    /**
     * Обработка изменения видимости админа в диалоге
     * @param data
     */
    function adminPresenceChangedHandler(data) {
      let callApply = false;

      for (let i = 0; i < data.length; i++) {
        const admin = $filter('filter')(vm.teamMembers, { id: data[i].admin }, true)[0];
        admin.presence = data[i].presence;
        callApply = true;
      }

      callApply && $scope.$applyAsync();
    }

    /** Закрытие window диалога с самим собой */
    function closeConversationWithSelfWindow() {
      $window[CONVERSATION_WITH_SELF_WINDOW_PARAMS.NAME] = null;

      removeEventListeners();

      trackCloseConversationWithSelfWindow();

      if (!isConversationsPage() && !isStarterGuidePage()) {
        redirectToConversationsPage();
      }
    }

    /**
     * Обработка начала диалога с пользователем
     * @param data
     */
    function conversationStartedUserHandler(data) {
      if (!vm.isFirstDialog) {
        return;
      }

      vm.isFirstDialog = isShowFirstDialogScreen();
      $scope.$applyAsync();
    }

    /**
     * Обработка добавления тега в диалог
     * @param data
     */
    function conversationTagAddedHandler(data) {
      let callApply = false;

      // Добавить в список тегов для поиска
      let found = false;
      for (let i = 0; i < vm.tags.length; i++) {
        if (vm.tags[i].tag === data.tag) {
          found = true;
        }
      }
      if (!found) {
        vm.tags.push({ tag: data.tag });
        conversationsStoreService.addTag(data.tag);
        callApply = true;
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Изменение темы
     *
     * @param {Boolean} isActive - Активировать темную тему или нет
     */
    function darkThemeChange(isActive) {
      $scope.$apply(() => {
        if (isActive) {
          vm.isDarkThemeActive = true;
          utilsService.darkThemeActivate();
          trackDarkThemeActivated();
        } else {
          vm.isDarkThemeActive = false;
          utilsService.darkThemeDeactivate();
          trackDarkThemeDeactivated();
        }
      });
    }

    /**
     * Обработчик RTS сообещений, выполняет роль switch для обработки каналов
     * @param event
     * @param info
     */
    function handleRts(event, info) {
      const { channel, data } = info;

      // @formatter:off
      switch (true) {
        case channel.includes('conversation_tag_added.'):
          conversationTagAddedHandler(data);
          break;
        case channel.includes('conversation_started_user'):
          conversationStartedUserHandler(data);
          break;
        case channel.includes('admin_presence_changed.'):
          adminPresenceChangedHandler(data);
          break;
        case channel.includes('user_merge_finished'):
          userMergedFinishedHandler(data);
          break;
        case channel.includes('users_removed'):
          usersRemovedHandler(data);
          break;
        case channel.includes('system_log_added'):
          systemLogAddedHandler(data);
          break;
      }
      // @formatter:on
    }

    /**
     * Является ли страница, на которой находится пользователь, страницей диалогов
     *
     * @return {boolean}
     */
    function isConversationsPage() {
      return $state.current.name === 'app.content.conversations.general';
    }

    /**
     * Проверка нужно ли показать экран с предложением создать первый диалога
     *
     * @returns {boolean}
     */
    function isShowFirstDialogScreen() {
      // У приложения в данный момент нет диалогов
      const isZeroConversations = vm.conversations.conversations.length === 0;
      // У приложения в данный момент нет активации в ответ пользователя как в чате, так и в интеграциях с соцсетями
      const isHasReplyActivation =
        !!vm.currentApp.activation.reply_user || !!vm.currentApp.activation.social_network_integrations_reply_user;

      return isZeroConversations && !isHasReplyActivation;
    }

    /**
     * Является ли страница, на которой находится пользователь, страницей стартергайда
     *
     * @return {boolean}
     */
    function isStarterGuidePage() {
      return $state.current.name === 'app.content.starterGuide';
    }

    function onChannelChange(newChannel) {
      vm.channel = newChannel;
      $scope.$applyAsync();
    }

    /**
     * Обработчик закрытия window диалога с самим собой
     *
     * @param event
     */
    function onCloseConversationWithSelfWindow(event) {
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data === 'close') {
          closeConversationWithSelfWindow();
        }
      }
    }

    function onHideHistory() {
      vm.isHistoryShowed = false;
    }

    /**
     * Колбэк на потерю интернет-соединения
     */
    function onOffline() {
      systemError.offlineConversationsToast.show();
    }

    /**
     * Колбэк на восстановление интернет-соединения
     */
    function onOnline() {
      systemError.offlineConversationsToast.hide();
      $state.reload();
    }

    /**
     * Обработчик открытия чата в window диалога с самим собой
     *
     * @param event
     */
    function onOpenChatWithSelf(event) {
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data === 'chatOpen') {
          trackOpenChatWithSelf();
        }
      }
    }

    /**
     * Обработчик старта диалога в window диалога с самим собой
     *
     * @param event
     */
    function onStartConversation(event) {
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data === 'startConversation') {
          starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.WRITE_SELF).subscribe();

          trackStarterGuideStepWriteSelfCompletion();
        }
      }
    }

    function onShowHistory() {
      vm.isHistoryShowed = true;
    }

    /**
     * Переключить видимость списка пользователей
     * @param {boolean} newVisibility - Статус видимости
     */
    function toggleUserListVisibility(newVisibility) {
      vm.isUserListVisible = newVisibility;
      if (!vm.isUserListVisible) {
        vm.onHideHistory();
      }
      $scope.$apply();
    }

    /** Перенаправление пользователя на страницу диалогов */
    function redirectToConversationsPage() {
      $state.go('app.content.conversations.general');
    }

    /** Удаление слушателей сообщений от window чата с самим собой */
    function removeEventListeners() {
      $window.removeEventListener('message', onCloseConversationWithSelfWindow);
      $window.removeEventListener('message', onOpenChatWithSelf);
      $window.removeEventListener('message', onStartConversation);
    }

    /**
     * Обработчик после добавления системных логов
     * @param data
     */
    function systemLogAddedHandler(data) {
      let callApply = false;

      // после удаления тега
      if (data.type === SYSTEM_LOG_MESSAGE_TYPES.CONVERSATION_TAG_REMOVED) {
        const tag = data.meta_data.tag;

        const tagsIndex = vm.tags.indexOf($filter('filter')(vm.tags, { tag: tag })[0]);
        if (tagsIndex !== -1) {
          vm.tags.splice(tagsIndex, 1);

          conversationsStoreService.removeTag(tag);

          callApply = true;
        }
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Затрекать закрытие дочернего окна чата с самим собой
     */
    function trackCloseConversationWithSelfWindow() {
      carrotquestHelper.track('Click', {
        type: 'test',
        test: '09012020',
        name: 'close_chat_window',
      });
    }

    /**
     * Трек включения тёмной темы
     */
    function trackDarkThemeActivated() {
      carrotquestHelper.track('Включил тёмную тему диалогов');
      carrotquestHelper.identify({ 'Тема диалогов': 'тёмная' });
    }

    /**
     * Трек выключения тёмной темы
     */
    function trackDarkThemeDeactivated() {
      carrotquestHelper.track('Выключил тёмную тему диалогов');
      carrotquestHelper.identify({ 'Тема диалогов': 'светлая' });
    }

    /**
     * Трек открытия списка пользователей
     */
    function trackOpenUserList() {
      carrotquestHelper.track('Диалоги - клик на список пользователей');
    }

    /**
     * Трек выбора пользователя в списке пользователей
     */
    function trackChoseUseInUserList() {
      carrotquestHelper.track('Диалоги - клик по пользователю в списке');
    }

    /**
     * Трек поиска в списке лидов
     */
    function trackUseSearchInUserList() {
      carrotquestHelper.track('Диалоги - клик на поиск по пользователям');
    }

    function trackEnterOnPage() {
      carrotquestHelper.track('Зашел на страницу диалогов', {
        App: vm.currentApp.name,
        New: 1,
      });
    }

    /**
     * Трекинг открытия чата в дочернем окне диалога с самим собой
     */
    function trackOpenChatWithSelf() {
      carrotquestHelper.track('Pageview', {
        test: '09012020',
        type: 'chat_opened',
      });
    }

    /** Трекинг завершения шага "Напиши себе сам" в стартергайде */
    function trackStarterGuideStepWriteSelfCompletion() {
      const eventName = 'Стартергайд - отправлено тестовое сообщение в чат';

      carrotquestHelper.track(eventName);
    }

    /**
     * Обновляет email в активном диалоге
     * @param newEmail
     */
    function updateActiveConversation(newEmail) {
      vm.activeConversation.user.props.$email = newEmail;
    }

    /**
     * Обработка после события слияния пользователей
     * @param data
     */
    function userMergedFinishedHandler(data) {
      if (data.removed != vm.activeUserId) {
        return;
      }

      vm.activeUserId = data.new;
      $scope.$applyAsync();
    }

    /**
     * Обработка после события удаления пользователя
     * @param data
     */
    function usersRemovedHandler(data) {
      let callApply = false;

      const removedUserIds = data.ids; // при удалении приходит массив ids с ID удалённых пользователей

      // поиск и удаление диалогов с пользователями, чьи ID пришли в RTS
      for (let i = 0; i < removedUserIds.length; i++) {
        if (vm.activeConversation && vm.activeConversation.user.id === removedUserIds[i]) {
          vm.userRemoved = true;
          activateConversation(null);
          callApply = true;
        }
      }

      callApply && $scope.$applyAsync();
    }

    /**
     * Выбранный пользователь
     * @param {User} user
     */
    function conversationUserSelect(user) {
      vm.activeUserId = user.id;
      !vm.isHistoryShowed && vm.onShowHistory();
      $scope.$apply();
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { TriggerChainDelayEditorModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-delay-editor/trigger-chain-delay-editor.module';
import { TriggerChainFilterEditorModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-filter-editor/trigger-chain-filter-editor.module';
import { TriggerChainReactionEditorModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-reaction-editor/trigger-chain-reaction-editor.module';

import { TriggerChainAutomessageEditorModule } from './components-per-step-type/trigger-chain-automessage-editor/trigger-chain-automessage-editor.module';
import { TriggerChainSendingConditionsEditorModule } from './components-per-step-type/trigger-chain-sending-conditions-editor/trigger-chain-sending-conditions-editor.module';
import { TriggerChainStepEditorComponent } from './trigger-chain-step-editor.component';

@NgModule({
  declarations: [TriggerChainStepEditorComponent],
  exports: [TriggerChainStepEditorComponent],
  imports: [
    CommonModule,
    TriggerChainAutomessageEditorModule,
    TriggerChainDelayEditorModule,
    TriggerChainSendingConditionsEditorModule,
    TriggerChainFilterEditorModule,
    TriggerChainReactionEditorModule,
    OverlayscrollbarsModule,
  ],
})
export class TriggerChainStepEditorModule {}

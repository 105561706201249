import { copy } from 'angular';
import { ChatBotBranchMapper } from 'app/http/chat-bot/mappers/branch.mapper';
import moment from 'moment';
import { generate } from 'short-uuid';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotModel } from '@http/chat-bot/chat-bot.model';
import { ApiChatBotBranchRequest } from '@http/chat-bot/types/branch-external.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import {
  ApiBotWithActiveStatusRequest,
  ApiBotWithActiveStatusResponse,
  ApiChatBotRequest,
  ApiChatBotResponse,
  ApiFacebookBotRequest,
  ApiFacebookBotResponse,
  ApiRoutingBotRequest,
  ApiRoutingBotResponse,
  ApiWidgetBotRequest,
  ApiWidgetBotResponse,
  CHAT_BOT_TYPE,
  ChatWidgetRequest,
} from '@http/chat-bot/types/chat-bot-external.types';
import {
  BotWithActiveStatus,
  ChatBot,
  FacebookBot,
  RoutingBot,
  WidgetBot,
} from '@http/chat-bot/types/chat-bot-internal.types';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { TgTriggerTypeExternal } from '@panel/app/partials/chat-bot/telegram-bot/trigger-types/tg-trigger.types';

export class ChatBotMapper {
  /**
   * Парсинг бота во внетренний формат
   *
   * @param chatBot - Чат-бот
   * V - id созданной на бэке ветки
   */
  static parseToInternalFormat<T extends CHAT_BOT_TYPE, K extends ChatBot<T>>(chatBot: ApiChatBotResponse<T>): K {
    // TODO: убрать as
    const parsedChatBot = {
      id: chatBot.id,
      name: chatBot.name,
      type: chatBot.type,
      created: chatBot.created,
      interruptBranchId: chatBot.interruptBranchId,
      startBranchId: chatBot.startBranchId,
      stats: chatBot.stats,
      allowUserReplies: chatBot.allowUserReplies,
    } as K;

    /**
     * @deprecated нужно избавиться от этого при удалении parentBranchIds
     * Формирует дерево веток
     */
    const createBranchesToTree = (branch: ChatBotBranch) => {
      branch.isInvalid = false;
      for (let i = 0; i < branch.actions.length; i++) {
        let branchId = branch.id;
        if (!branchId) {
          throw new Error('Could not get branch id');
        }
        let action = branch.actions[i];
        action.isInvalid = false;

        !branch.parentBranchIds && (branch.parentBranchIds = []);
        !~branch.parentBranchIds.indexOf(branchId) && branch.parentBranchIds.push(branchId);

        if (ChatBotModel.isConnectionSourceAction(action.type)) {
          action.nextBranch = parsedChatBot.branches.find((br) => br.id === action.nextBranchId);
          action.nextBranchLinkId = action.nextBranch ? action.nextBranch.linkId : null;

          //NOTE Заполнить parentBranchIds только если нет координат ветки
          // Надо чтобы в канвасе можно было собрать иерархию из веток
          // Если есть координаты значит ветки будут собираться по этим координтам
          if (action.nextBranch && !action.nextBranch.coordinates) {
            let curBranchId = action.nextBranch.id;
            if (!curBranchId) {
              throw new Error('Could not get branch id');
            }
            action.nextBranch.parentBranchIds = copy(branch.parentBranchIds);
            !~action.nextBranch.parentBranchIds.indexOf(branchId) && action.nextBranch.parentBranchIds.push(branchId);
            !~action.nextBranch.parentBranchIds.indexOf(curBranchId) &&
              action.nextBranch.parentBranchIds.push(curBranchId);

            createBranchesToTree(action.nextBranch);
          }
        }
      }
    };

    const interruptBlocks = BotScenariosHelper.getScenarioBlocksById(chatBot.branches, chatBot.interruptBranchId);

    parsedChatBot.branches = chatBot.branches.map((branch) => {
      const isInterruptedBranch = interruptBlocks.includes(branch.id);
      return ChatBotBranchMapper.parseToInternalFormat(branch, isInterruptedBranch);
    });

    /**
     * Далее происходит какой-то депрекейтед колхоз, который никто уже не помнит зачем нужен.
     * Единственное место, на которое это может повлиять - это рендер старых ботов, у которых нет в ветках нет координат
     */
    // Если с сервера приходит пустой бот без действий, добавляем свойство с родительской веткой,
    // чтобы можно было отображать добавленные действия в превью
    const startedBranch = parsedChatBot.branches.find((br) => br.id === parsedChatBot.startBranchId);
    if (startedBranch) {
      if (parsedChatBot.branches.length === 2 && !startedBranch.actions.length) {
        !startedBranch.parentBranchIds && (startedBranch.parentBranchIds = []);
        !~startedBranch.parentBranchIds.indexOf(startedBranch.id!) &&
          startedBranch.parentBranchIds.push(startedBranch.id!);
      }
      parsedChatBot.startBranchLinkId = startedBranch.linkId;
    } else {
      parsedChatBot.startBranchLinkId = null;
    }

    // Для ветки прерывания добавим свойство с родительской веткой,
    // чтобы можно было отображать добавленные действия в превью
    const interruptBranch = parsedChatBot.branches.find((br) => br.id === parsedChatBot.interruptBranchId);
    if (!interruptBranch) {
      throw new Error('Could not find started branch');
    }
    if (parsedChatBot.interruptBranchId && interruptBranch) {
      !interruptBranch.parentBranchIds && (interruptBranch.parentBranchIds = []);
      !~interruptBranch.parentBranchIds.indexOf(parsedChatBot.interruptBranchId) &&
        interruptBranch.parentBranchIds.push(parsedChatBot.interruptBranchId);
    }
    parsedChatBot.interruptBranchLinkId = interruptBranch.linkId;
    /**
     * Конец депрекейтед колхоза про parentBranchIds
     */

    //NOTE При наличии координат ветки форимировать дерево надо для каждой ветки
    // т.к. теперь можно удалять свзи между ветками и зацикливать бота
    // наличие координат достаточно проверить на любой одной ветке
    if (startedBranch) {
      if (startedBranch.coordinates) {
        parsedChatBot.branches.forEach(createBranchesToTree);
      } else {
        createBranchesToTree(startedBranch);
      }
    }

    //Преобразовываем строку время в нужный формат
    if (parsedChatBot.stats?.lastSent) {
      parsedChatBot.stats.lastSent = moment(parsedChatBot.stats.lastSent, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    }

    //Преобразования triggerType в удобный формат
    if (chatBot.triggerTypes) {
      parsedChatBot.triggerTypes = chatBot.triggerTypes.map((triggerType) => {
        return triggerType.kind;
      });
    }

    if (chatBot.integration) {
      parsedChatBot.integration = chatBot.integration;
    }

    if (chatBot.type === CHAT_BOT_TYPE.WIDGET) {
      parsedChatBot.chatWidget = {
        buttonText: chatBot.chatWidget?.buttonText || '',
        header: chatBot.chatWidget?.header || '',
        id: chatBot.chatWidget?.id,
        order: chatBot.chatWidget?.order || 0,
        subHeader: chatBot.chatWidget?.subHeader || '',
        visible: chatBot.chatWidget?.visible || false,
      };
    }

    return parsedChatBot;
  }

  /**
   * Парсим роутинг бота во внутренний формат
   *
   * @param bot - Facebook или Welcome боты
   */
  static parseBotWithActiveStatusToInternalFormat<T extends Exclude<CHAT_BOT_TYPE, 'lead'>>(
    bot: ApiBotWithActiveStatusResponse<T>,
  ): BotWithActiveStatus<T> {
    return {
      ...this.parseToInternalFormat(bot),
      active: bot.active,
    };
  }

  /**
   * Парсим Facebook-бота во внутренний формат
   */
  static parseFacebookBotToInternalFormat(facebookBot: ApiFacebookBotResponse): FacebookBot {
    return this.parseBotWithActiveStatusToInternalFormat<CHAT_BOT_TYPE.FACEBOOK>(facebookBot);
  }

  /**
   * Парсим роутинг бота во внутренний формат
   */
  static parseRoutingBotToInternalFormat(routingBot: ApiRoutingBotResponse): RoutingBot {
    return this.parseBotWithActiveStatusToInternalFormat<CHAT_BOT_TYPE.ROUTING>(routingBot);
  }

  /**
   * Парсим роутинг бота во внутренний формат
   */
  static parseWidgetBotToInternalFormat(routingBot: ApiWidgetBotResponse): WidgetBot {
    return this.parseBotWithActiveStatusToInternalFormat<CHAT_BOT_TYPE.WIDGET>(routingBot);
  }

  /**
   * Парсинг бота в серверный формат
   *
   * @param bot - Бот
   * @param checkIsValid - Включить/выключить проверку бота на беке
   */
  static parseToServerFormat<T extends CHAT_BOT_TYPE>(bot: ChatBot<T>, checkIsValid: any): ApiChatBotRequest<T> {
    let interruptedBranchId: string | null = null;
    let interruptedBranchLinkId: string | null = null;

    let startBranchId: string | null = null;
    let startBranchLinkId: string | null = null;

    let branches: ApiChatBotBranchRequest[] = [];

    let triggerTypes: TgTriggerTypeExternal[] = [];
    if (bot.type === CHAT_BOT_TYPE.TELEGRAM) {
      triggerTypes = bot.triggerTypes.map((triggerType) => {
        return {
          kind: triggerType,
          value: {
            external_service: CHAT_BOT_TYPE.TELEGRAM,
          },
        };
      });
    }

    let widget: ChatWidgetRequest | null = null;
    if (bot.type === CHAT_BOT_TYPE.WIDGET && bot.chatWidget) {
      if (bot.chatWidget.visible) {
        widget = {
          button_text: bot.chatWidget.buttonText,
          header: bot.chatWidget.header,
          order: bot.chatWidget.order,
          sub_header: bot.chatWidget.subHeader,
          visible: bot.chatWidget.visible,
        };
      } else {
        widget = {
          visible: bot.chatWidget.visible,
        };
      }
    }

    if (bot.interruptBranchId) {
      interruptedBranchId = bot.interruptBranchId;
    } else {
      interruptedBranchLinkId = bot.interruptBranchLinkId;
    }

    if (bot.startBranchId) {
      startBranchId = bot.startBranchId;
    } else {
      startBranchLinkId = bot.startBranchLinkId;
    }

    const interruptBlocks = BotScenariosHelper.getScenarioBlocksByLinkId(bot.branches, bot.interruptBranchLinkId);

    for (let i = 0; i < bot.branches.length; i++) {
      const isInterruptBranch = interruptBlocks.includes(bot.branches[i].linkId);
      const parsedBranch = ChatBotBranchMapper.parseToServerFormat(bot.branches[i], isInterruptBranch);
      branches.push(parsedBranch);
    }

    return {
      name: bot.name,
      allow_user_replies: bot.allowUserReplies,
      branches,
      type: bot.type,
      check_is_valid: checkIsValid,
      interrupt_branch_id: interruptedBranchId,
      interrupt_branch_link_id: interruptedBranchLinkId,
      start_branch_id: startBranchId,
      start_branch_link_id: startBranchLinkId,
      deleted_branches: [],
      include_branches__actions__attachments: true,
      trigger_types: triggerTypes,
      integration: bot.integration,
      chat_widget: widget || undefined,
    };
  }

  static parseBotWithActiveStatusToServerFormat<T extends Exclude<CHAT_BOT_TYPE, 'lead'>>(
    bot: BotWithActiveStatus<T>,
  ): ApiBotWithActiveStatusRequest<T> {
    return {
      ...this.parseToServerFormat(bot, bot.active),
      active: bot.active,
    };
  }

  static parseFacebookBotToServerFormat(bot: FacebookBot): ApiFacebookBotRequest {
    return ChatBotMapper.parseBotWithActiveStatusToServerFormat<CHAT_BOT_TYPE.FACEBOOK>(bot);
  }

  static parseRoutingBotToServerFormat(bot: RoutingBot): ApiRoutingBotRequest {
    return ChatBotMapper.parseBotWithActiveStatusToServerFormat<CHAT_BOT_TYPE.ROUTING>(bot);
  }

  static parseWidgetBotToServerFormat(bot: WidgetBot): ApiWidgetBotRequest {
    return ChatBotMapper.parseBotWithActiveStatusToServerFormat<CHAT_BOT_TYPE.WIDGET>(bot);
  }

  static parseBotToTelegramBot<T extends Exclude<CHAT_BOT_TYPE, 'facebook' | 'telegram' | 'widget'>>(
    bot: ChatBot<T>,
  ): ChatBot<CHAT_BOT_TYPE.TELEGRAM> {
    // Ключ - id с бэка, value - новый linkId
    const idToLinkId: { [key: string]: string } = {};
    let telegramBot = {
      ...bot,
      type: CHAT_BOT_TYPE.TELEGRAM,
      active: false,
      triggerTypes: [],
      allowUserReplies: true, // Для ТГ ботов ветка прерывания должна быть всегда включена
    };
    // Правим айдишники блоков/веток
    telegramBot.branches.forEach((branch) => {
      if (branch.id === bot.startBranchId) {
        branch.actions = branch.actions.filter((action) => {
          return ChatBotModel.isActionAllowedForStartBranch(action.type);
        });
      }

      branch.actions.forEach((action) => {
        if (action.type === CHAT_BOT_ACTIONS_TYPES.TEXT) {
          // Telegram бот не поддерживает теги списков, поэтому просто их удаляем, заменяя все li, кроме первого, <br/>,
          // чтобы не добавлять доп. перенос
          action.body = action.body.replace(/<li>/, '').replace(/<li>/g, '<br/>');
          action.body = action.body.replace(/<\/?(ol|ul|li)>/g, '');
        }
      });

      // Если для ветки еще не создан новый локальный айди, то создаем
      if (!idToLinkId[branch.id!]) {
        branch.linkId = generate();
        idToLinkId[branch.id!] = branch.linkId;
        // Если он есть, то записываем его (он тут может оказаться, если эта ветка была в экшенах предыдущих веток)
      } else if (branch.id) {
        branch.linkId = idToLinkId[branch.id];
      }
      branch.id = null;

      // Правим айдишники nextBranchId/nextBranchLinkId для действий
      branch.actions.forEach((action) => {
        delete action.id;
        action.linkId = generate();

        if (!ChatBotModel.isConnectionSourceAction(action.type)) {
          return;
        }

        const oldNextBranchId = action.nextBranchId;
        if (!oldNextBranchId) {
          return;
        }

        if (!idToLinkId[oldNextBranchId]) {
          idToLinkId[oldNextBranchId] = generate();
        }

        // Такие сложности из-за того, что если nextBranch - это стартовая ветка,
        // экшену надо записать nextBranchId, а не nextBranchLinkId, иначе бэк не даст сохранить
        action.nextBranchId = null;
        action.nextBranchLinkId = idToLinkId[oldNextBranchId];

        delete action.nextBranch;
      });
    });

    telegramBot.startBranchLinkId = idToLinkId[telegramBot.startBranchId!];
    delete telegramBot.startBranchId;

    telegramBot.interruptBranchLinkId = idToLinkId[telegramBot.interruptBranchId!];
    delete telegramBot.interruptBranchId;

    delete telegramBot.id;

    return telegramBot as ChatBot<CHAT_BOT_TYPE.TELEGRAM>;
  }
}

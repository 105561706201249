(function () {
  'use strict';

  require('./edit.controller');

  angular.module('myApp.chatBot.telegramBot').component('cqTelegramBotEdit', cqTelegramBotEdit());

  function cqTelegramBotEdit() {
    return {
      bindings: {
        activeChatBotsAmounts: '=', // Список активных чат-ботов
        billingInfo: '=', // Информация о биллинге
        channels: '=', // Каналы
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        emailNotificationIntegrationsExternal: '=', // Интеграции типа "Уведомление на Email" в внешнем формате
        amocrmIntegrationsExternal: '=', // Интеграции типа AmoCRM
        properties: '=', // Список свойств и типов событий
        telegramBot: '=',
        /**
         * Список всех Telegram-ботов
         * @type TelegramBot[]
         */
        telegramBotAllList: '=',
        telegramIntegrationList: '=', // Список активных интеграций Telegram
        tags: '=', // Теги пользователей
        teamMembers: '=', // члены команды
      },
      controller: 'cqTelegramBotEditController',
      controllerAs: 'vm',
      template: require('./edit.html'),
    };
  }
})();

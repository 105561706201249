import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IGNORE_ID_AS_STRING } from '@panel/app/shared/constants/http.constants';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Добавляет ко всем запросам параметр idAsString
 * Он нужен для того, чтобы все ID, которые возвращаются с сервера возвращались строкой, а не числом,
 * т.к. возвращаемые числа переполняют тип number в JS, они слишком большие
 */
@Injectable()
export class IdAsStringInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    if (request.context.has(IGNORE_ID_AS_STRING)) {
      return next.handle(request);
    }

    request = request.clone({
      setParams: {
        idAsString: 'true',
      },
    });

    return next.handle(request);
  }
}

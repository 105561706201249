import { OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  ExtendInteractionConfig,
  INTERACTION_ENTITY,
  InteractionConfig,
  InteractionConfigUtils,
} from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.types';

export type OfUnion<T extends { type: string }> = {
  [P in T['type']]: Extract<T, { type: P }>;
};

export function filterByInteractionViewType<
  TypeName extends INTERACTION_ENTITY,
  ViewType extends OfUnion<ExtendInteractionConfig>[TypeName]['view'],
  T extends InteractionConfigUtils<TypeName, ViewType> & ExtendInteractionConfig,
>(eventType: TypeName): OperatorFunction<ExtendInteractionConfig, T> {
  return (source$) =>
    source$.pipe(
      filter<InteractionConfig, T>((event): event is T => {
        return event.type === eventType;
      }),
    );
}

export function filterAndExtractView<
  TypeName extends INTERACTION_ENTITY,
  ViewType extends OfUnion<ExtendInteractionConfig>[TypeName]['view'],
  T extends InteractionConfigUtils<TypeName, ViewType> & ExtendInteractionConfig,
>(eventType: TypeName): OperatorFunction<ExtendInteractionConfig, ViewType> {
  return (source$) =>
    source$.pipe(
      filterByInteractionViewType<TypeName, ViewType, T>(eventType),
      map((event) => event.view),
    );
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import sortBy from 'lodash-es/sortBy';

import { EMAIL_NOTIFICATION_STATUS } from '@http/integration/integrations/email-notification/constants/email-notification-integration.constants';
import { EmailRecipient } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { TEAM_MEMBER_DEFAULT_AVATAR_URL } from '@http/team-member/team-member.constants';
import { TeamMember } from '@http/team-member/team-member.types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-email-notification-team-members-select[control][teamMembers][emailRecipients]',
  templateUrl: './team-members-select.component.html',
  styleUrls: ['./team-members-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationTeamMembersSelectComponent implements OnChanges {
  /** Control для настройки получателей, которым отправится уведомление */
  @Input() control!: UntypedFormControl;
  /** Все получатели уведомлений в App'е */
  @Input() emailRecipients!: EmailRecipient[];
  /** Список членов команды в App'е */
  @Input() teamMembers!: TeamMember[];
  /** Статусы подписки на уведомления у получателей */
  EMAIL_NOTIFICATION_STATUS = EMAIL_NOTIFICATION_STATUS;
  /** Полный список получателей, которых можно указать в качестве получателей уведомления */
  emailRecipientItems: EmailRecipient[] = [];
  /** Url-адрес дефотлной аватарки члена команды */
  TEAM_MEMBER_DEFAULT_AVATAR_URL = TEAM_MEMBER_DEFAULT_AVATAR_URL;
  /**
   * Время исчезновения тултипа об отписанном пользователе.
   * Необходимо для того, чтобы пользователь успел кликнуть на ссылку внутри тултипа
   */
  USER_UNSUBSCRIBED_TOOLTIP_CLOSE_DELAY = 2000;

  /** Получение членов команды как получателей */
  get teamMembersAsRecipients(): EmailRecipient[] {
    const configuredRecipientEmails = this.emailRecipients.map((emailRecipient) => emailRecipient.email);

    return this.teamMembers
      .filter((teamMember) => this.isTeamMemberNotAsEmailRecipient(teamMember, configuredRecipientEmails))
      .map(this.convertTeamMemberToEmailRecipient);
  }

  constructor(private readonly carrotquestHelper: CarrotquestHelper) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['emailRecipients'] && !!simpleChanges['emailRecipients'].currentValue) {
      this.emailRecipientItems = sortBy([...this.emailRecipients, ...this.teamMembersAsRecipients], 'email');
    }
  }

  /**
   * Указан ли член команды как получатель
   *
   * @param teamMember - Член команды
   * @param configuredRecipientEmails - Список настроенных для уведомления Email'ов
   */
  isTeamMemberNotAsEmailRecipient(teamMember: TeamMember, configuredRecipientEmails: EmailRecipient['email'][]) {
    return !configuredRecipientEmails.includes(teamMember.email);
  }

  /**
   * Конвертация члена команды в получателя
   *
   * @param teamMember - Член команды
   */
  convertTeamMemberToEmailRecipient(teamMember: TeamMember): EmailRecipient {
    return {
      fullName: teamMember.name + (teamMember.internalName ? ' &middot; ' + teamMember.internalName : ''),
      avatar: teamMember.avatar,
      email: teamMember.email,
      isTeamMember: true,
      status: null,
    };
  }

  /**
   * Добавление email'а получателя в список выбранных для уведомления email'ов
   *
   * @param email - Email, который пользователь ввел в ng-select
   */
  emailRecipientsAddTagFn(email: string): EmailRecipient {
    return {
      email: email,
    };
  }

  /**
   * Track-by функция для списка email'ов
   *
   * @param emailRecipient
   */
  emailRecipientsTrackFn(emailRecipient: EmailRecipient): string {
    return emailRecipient.email;
  }

  /** Открытие чата */
  openChat(): void {
    this.carrotquestHelper.open();
  }
}

<ng-container *transloco="let t; read: 'notAssignedComponent'">
  <div class="d-flex align-items-center">
    <i class="cqi-sm cqi-user-square text-danger"></i>
    <div class="name ml-10">
      <div
        *ngIf="!unsorted"
        class="text-truncate"
      >
        {{ t('notAssigned') }}
      </div>
      <div
        *ngIf="unsorted"
        class="text-truncate"
      >
        {{ t('unsorted') }}
      </div>
    </div>
  </div>
</ng-container>

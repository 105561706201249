import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Billing } from '@http/billing/billing';
import { BillingDto } from '@http/billing/billing.types';
import { Requisites } from '@http/requisites/requisites';
import { RequisitesDto } from '@http/requisites/requisites.types';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';
import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class RequisitesHttpService {
  constructor(private readonly http: HttpClient, private readonly instanceFactory: InstanceFactory) {}

  /**
   * Смена реквизитов компании
   *
   * @param requisites Реквизиты компании
   */
  editRequisites(requisites: Requisites): Observable<Billing> {
    const newRequisites: RequisitesDto = {};

    if (requisites.address) {
      newRequisites.organization_address = requisites.address;
    }

    if (requisites.fio) {
      newRequisites.organization_fio = requisites.fio;
    }

    if (requisites.inn) {
      newRequisites.organization_inn = requisites.inn;
    }

    if (requisites.kpp) {
      newRequisites.organization_kpp = requisites.kpp;
    }

    if (requisites.name) {
      newRequisites.organization_name = requisites.name;
    }

    if (requisites.ogrn) {
      newRequisites.organization_ogrn = requisites.ogrn;
    }

    if (requisites.postalAddress) {
      newRequisites.organization_postal_address = requisites.postalAddress;
    }

    return this.http
      .patch<BillingDto>(
        '/billing',
        { contract_data: newRequisites },
        {
          context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
        },
      )
      .pipe(
        map((response) => {
          return this.instanceFactory.getInstanceBilling(response);
        }),
      );
  }
}

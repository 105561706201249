(function () {
  'use strict';

  require('./header.controller');

  angular.module('myApp.trackMaster').component('cqTrackMasterHeader', cqTrackMasterHeader());

  function cqTrackMasterHeader() {
    return {
      controller: 'HeaderController',
      controllerAs: 'vm',
      template: require('./header.html'),
    };
  }
})();

import { ChatBotMapper } from '../../../../../app/http/chat-bot/mappers/chat-bot.mapper';
import { CHAT_BOT_URL_PATHS } from '../../chat-bot-routes.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.chatBot.telegramBot').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.messagesAjs.telegramBot', {
        url: CHAT_BOT_URL_PATHS.TELEGRAM_BOT,
        redirectTo: 'app.content.messagesAjs.telegramBot.create',
        template: '<div ui-view></div>',
      })

      .state('app.content.messagesAjs.telegramBot.create', {
        url: '/new',
        resolve: {
          activeChatBotsAmounts: getActiveChatBotsAmounts,
          channels: getChannels,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          telegramBotAllList: getTelegramBotAllList,
          telegramIntegrationList: getTelegramIntegrationList,
        },
        bindings: {
          activeChatBotsAmounts: 'activeChatBotsAmounts',
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          tags: 'tags',
          teamMembers: 'teamMembers',
          telegramIntegrationList: 'telegramIntegrationList',
        },
        component: 'cqTelegramBotEdit',
      })
      .state('app.content.messagesAjs.telegramBot.createFromLeadBot', {
        url: '/new',
        resolve: {
          activeChatBotsAmounts: getActiveChatBotsAmounts,
          channels: getChannels,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          telegramBot: getTelegramBotFromLeadBot,
          telegramBotAllList: getTelegramBotAllList,
          telegramIntegrationList: getTelegramIntegrationList,
        },
        bindings: {
          activeChatBotsAmounts: 'activeChatBotsAmounts',
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          tags: 'tags',
          teamMembers: 'teamMembers',
          telegramIntegrationList: 'telegramIntegrationList',
        },
        params: {
          leadBotId: null,
        },
        component: 'cqTelegramBotEdit',
      })
      .state('app.content.messagesAjs.telegramBot.createFromWelcomeBot', {
        url: '/new',
        resolve: {
          activeChatBotsAmounts: getActiveChatBotsAmounts,
          channels: getChannels,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          telegramBot: getTelegramBotFromWelcomeBot,
          telegramBotAllList: getTelegramBotAllList,
          telegramIntegrationList: getTelegramIntegrationList,
        },
        bindings: {
          activeChatBotsAmounts: 'activeChatBotsAmounts',
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          tags: 'tags',
          teamMembers: 'teamMembers',
          telegramIntegrationList: 'telegramIntegrationList',
        },
        component: 'cqTelegramBotEdit',
      })

      .state('app.content.messagesAjs.telegramBot.edit', {
        url: '/{telegramBotId:id}/edit',
        resolve: {
          activeChatBotsAmounts: getActiveChatBotsAmounts,
          channels: getChannels,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          telegramBot: getTelegramBot,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          telegramBotAllList: getTelegramBotAllList,
          telegramIntegrationList: getTelegramIntegrationList,
        },
        bindings: {
          activeChatBotsAmounts: 'activeChatBotsAmounts',
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          telegramBot: 'telegramBot',
          tags: 'tags',
          teamMembers: 'teamMembers',
          telegramIntegrationList: 'telegramIntegrationList',
        },
        component: 'cqTelegramBotEdit',
      })

      .state('app.content.messagesAjs.telegramBot.edit.copy', {
        url: '/copy',
      });

    /**
     * Получение количества активных чат-ботов
     * @param currentApp
     * @param chatBotModel
     * @return {*}
     */
    function getActiveChatBotsAmounts(currentApp, chatBotModel) {
      return firstValueFrom(chatBotModel.getActiveChatBotsAmounts(currentApp.id));
    }

    /**
     * Получение всех тегов пользователя
     *
     * @param currentApp
     * @param tagModel
     * @returns {Promise}
     */
    function getAllUserTags(currentApp, tagModel) {
      return firstValueFrom(tagModel.getList(currentApp.id));
    }

    /**
     * Получение списка каналов
     *
     * @param channelModel
     * @param currentApp
     * @return {Promise}
     */
    function getChannels(channelModel, currentApp) {
      return firstValueFrom(channelModel.getList(currentApp.id));
    }

    /**
     * Получение существующих интеграций "Уведомление на Email"
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getEmailNotificationIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.EMAIL_NOTIFICATION);
    }

    /**
     * Получение существующих интеграций AmoCRM
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getAmocrmIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.AMOCRM);
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение телеграм-бота
     *
     * @param $stateParams
     * @param chatBotModel
     * @returns {Promise}
     */
    function getTelegramBot($stateParams, chatBotModel) {
      return firstValueFrom(chatBotModel.getTelegramBot($stateParams.telegramBotId));
    }

    /**
     * Получение списка всех Telegram-ботов
     *
     * @param chatBotModel
     * @returns {Promise}
     */
    function getTelegramBotAllList(chatBotModel) {
      return firstValueFrom(chatBotModel.getTelegramBotList(true));
    }

    /**
     * Получение и парсинг лид-бота в Телеграм-бота
     *
     * @param $stateParams
     * @param currentApp
     * @param chatBotModel
     * @return {angular.IPromise<ChatBot<CHAT_BOT_TYPE.TELEGRAM>>}
     */
    function getTelegramBotFromLeadBot($stateParams, currentApp, chatBotModel) {
      return firstValueFrom(chatBotModel.getLeadBot(currentApp.id, $stateParams.leadBotId)).then((leadBot) =>
        ChatBotMapper.parseBotToTelegramBot(leadBot.bot),
      );
    }

    /**
     * Получение и парсинг welcome-бота в Телеграм-бота
     *
     * @param chatBotModel
     * @return {angular.IPromise<ChatBot<CHAT_BOT_TYPE.TELEGRAM>>}
     */
    function getTelegramBotFromWelcomeBot(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot()).then((welcomeBot) =>
        ChatBotMapper.parseBotToTelegramBot(welcomeBot),
      );
    }

    /**
     * Получение членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
    }

    /**
     * Получение списка активных интеграций Telegram
     *
     * @param INTEGRATION_TYPES
     * @param {App} currentApp
     * @param integrationModel
     * @returns {IPromise<TelegramIntegration[]>}
     */
    function getTelegramIntegrationList(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM).then((integrations) => {
        return integrations.filter((integration) => integration.active);
      });
    }
  }
})();

<div class="emoji-menu">
  <label
    *ngFor="let emojiGroup of emojiGroups; trackBy: trackByEmojiGroupFn"
    class="menu-item-container"
  >
    <input
      class="hidden"
      type="radio"
      (change)="changeEmojiGroup(emojiGroup)"
      [(ngModel)]="currentGroup"
      [value]="emojiGroup"
    />
    <div
      class="cqi-sm margin-auto"
      [classList]="getEmojiGroupIconClasses(emojiGroup)"
      [ngClass]="{ 'text-primary': currentGroup === emojiGroup }"
    ></div>
  </label>
</div>
<overlay-scrollbars
  #overlayScrollbars
  class="flex-grow os-host-flexbox"
>
  <div class="emoji-list">
    <cq-emoji
      *ngFor="let emoji of currentEmojiList"
      [name]="emoji.name"
      [isBig]="false"
      (click)="selectEmoji(emoji.name)"
    ></cq-emoji>
    <div
      class="emoji-stub"
      *ngFor="let stub of [].constructor(10); let i = index"
    ></div>
  </div>
</overlay-scrollbars>

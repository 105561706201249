/**
 * Удаляет весь js скрипт и опасные свойства
 */
(function () {
  'use strict';

  angular.module('myApp.filters.cleanJs').filter('cleanJs', cleanJs);

  function cleanJs() {
    return cleanJs;

    /**
     * Фильтр массива шаблонов по типу сообщения
     *
     * @param {String} text Текст сообщение
     * @returns {Array} Отфильтрованный массив шаблонов
     */
    function cleanJs(text) {
      if (typeof text != 'string') {
        return text;
      }
      var tags = text.replace(/<!--[\w\W]*?-->|<[/!]?([^>]*?)(\s[^>]*?)?>/gim, function (m, tag_name, attrs, pos) {
        if (tag_name == 'script') {
          return '';
        } else if (attrs != undefined) {
          return tagsReplace(m);
        } else {
          return m;
        }
      });

      function tagsReplace(str) {
        var attrsAlert = [
          'onblur',
          'onchange',
          'onclick',
          'ondblclick',
          'onfocus',
          'onkeydown',
          'onkeypress',
          'onkeyup',
          'onload',
          'onmousedown',
          'onmousemove',
          'onmouseout',
          'onmouseover',
          'onmouseup',
          'onreset',
          'onselect',
          'onsubmit',
          'onunload',
          'onerror',
        ];
        var attrs = str.replace(
          /\s*(\w+)\s*(\=\s*((\'([^\']\')*\')|(\"([^\"])*\")|([^\'\"\s][^\s]*)))?/gim,
          function (m, g1) {
            return attrsAlert.indexOf(g1.trim()) == -1 ? m : '';
          },
        );
        return attrs;
      }

      var attrs = text.match(/\s*(\w+)\s*(\=\s*((\'([^\']\')*\')|(\"([^\"])*\")|([^\'\"\s][^\s]*)))?/gim);

      return tags;
    }
  }
})();

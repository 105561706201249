import { AbstractControl, ValidatorFn } from '@angular/forms';

/** Валидатор по уникальному значению в массиве */
export function uniqueValidator(array: any[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (array.includes(value)) {
      return { unique: { value } };
    }
    return null;
  };
}

/** Расширения для файлов с аудио */
// @formatter:off
export enum AUDIO_EXTENSION {
  MP3 = 'mp3',
}

/** Расширения для файлов с документами */
// @formatter:off
export enum DOCS_EXTENSION {
  CSV = 'csv',
  DOC = 'doc',
  DOCX = 'docx',
  HTML = 'html',
  PDF = 'pdf',
  RTF = 'rtf',
  TXT = 'txt',
  XLS = 'xls',
  XLSX = 'xlsx',
}
// @formatter:on

/** Расширения для файлов с изображениями */

// @formatter:off
export enum IMAGE_EXTENSION {
  GIF = 'gif',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PNG = 'png',
  SVG = 'svg',
  TIF = 'tif',
  TIFF = 'tiff',
  WEBP = 'webp',
}
// @formatter:on

/** Расширения для файлов видео */

// @formatter:off
export enum VIDEO_EXTENSION {
  '3GPP' = '3gpp',
  M4V = 'm4v',
  MOV = 'mov',
  MP4 = 'mp4',
  MPEG = 'mpeg',
  WEBM = 'webm',
}
// @formatter:on

/** Расширения для остальных файлов */
export enum OTHER_FILE_EXTENSION {
  RAR = 'rar',
  SIG = 'sig',
  ZIP = 'zip',
}

/** Допустимые расширения для документов */
export const ACCEPTABLE_AUDIO_EXTENSIONS = [AUDIO_EXTENSION.MP3];

/** Допустимые расширения для документов */
export const ACCEPTABLE_DOCS_EXTENSIONS = [
  DOCS_EXTENSION.CSV,
  DOCS_EXTENSION.DOC,
  DOCS_EXTENSION.DOCX,
  DOCS_EXTENSION.HTML,
  DOCS_EXTENSION.PDF,
  DOCS_EXTENSION.RTF,
  DOCS_EXTENSION.TXT,
  DOCS_EXTENSION.XLS,
  DOCS_EXTENSION.XLSX,
];

/** Допустимые расширения для изображений */
export const ACCEPTABLE_IMAGES_EXTENSIONS = [
  IMAGE_EXTENSION.GIF,
  IMAGE_EXTENSION.JPEG,
  IMAGE_EXTENSION.JPG,
  IMAGE_EXTENSION.PNG,
  IMAGE_EXTENSION.SVG,
  IMAGE_EXTENSION.TIF,
  IMAGE_EXTENSION.TIFF,
];

/** Допустимые расширения для других типов файлов */
export const ACCEPTABLE_OTHER_FILES_EXTENSIONS = [
  OTHER_FILE_EXTENSION.RAR,
  OTHER_FILE_EXTENSION.SIG,
  OTHER_FILE_EXTENSION.ZIP,
];

/** Допустимые расширения для видео */
export const ACCEPTABLE_VIDEO_EXTENSIONS = [
  VIDEO_EXTENSION.M4V,
  VIDEO_EXTENSION.MOV,
  VIDEO_EXTENSION.MP4,
  VIDEO_EXTENSION.MPEG,
  VIDEO_EXTENSION.WEBM,
  VIDEO_EXTENSION['3GPP'],
];

/** Допустимый список валидных расширений */
export const ACCEPTABLE_EXTENSIONS = [
  ...ACCEPTABLE_AUDIO_EXTENSIONS,
  ...ACCEPTABLE_DOCS_EXTENSIONS,
  ...ACCEPTABLE_IMAGES_EXTENSIONS,
  ...ACCEPTABLE_OTHER_FILES_EXTENSIONS,
  ...ACCEPTABLE_OTHER_FILES_EXTENSIONS,
  ...ACCEPTABLE_VIDEO_EXTENSIONS,
];

(function () {
  'use strict';

  angular
    .module('myApp.modals.createUserProperty')
    .controller('CqCreateUserPropertyController', CqCreateUserPropertyController);

  function CqCreateUserPropertyController(validationHelper) {
    var vm = this;

    /**
     * Регулярное выражение для названия свойсва пользователя
     * Название не должно начинаться с $
     *
     * @type {RegExp}
     */
    var USER_PROPERTY_NAME_PATTERN = /^[^\$].*$/;

    vm.$onInit = init;

    function init() {
      vm.confirm = confirm;
      vm.USER_PROPERTY_NAME_PATTERN = USER_PROPERTY_NAME_PATTERN;
      vm.userPropertyName = ''; // название свойства пользователя
      vm.validationHelper = validationHelper;
    }

    /**
     * Закрытие модалки с подтверждением (снаружи вызовется successCallback)
     *
     * @param isValid
     */
    function confirm(isValid) {
      if (isValid) {
        vm.modalInstance.close(vm.userPropertyName);
      }
    }
  }
})();

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { AppModel } from '@http/app/app.model';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-firebase-key',
  templateUrl: './firebase-key.component.html',
  styleUrls: ['./firebase-key.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirebaseKeyComponent implements OnInit {
  /** текущее приложение */
  @Input() currentApp!: any;

  /** существует ли ключ firebase в сервисе */
  protected keyExist: boolean = false;

  protected firebaseKeyForm = this.fb.group({
    key: this.fb.control(null, [Validators.required]),
  });

  protected projectName = environment.projectName;

  constructor(
    protected readonly appModel: AppModel,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    protected readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.keyExist = !!this.currentApp.settings.messenger_firebase_account_credentials_project_id; // существует ли ключ firebase в сервисе
  }

  /**
   * Отправка ключа для Firebase Cloud Messaging
   */
  sendFirebaseKey(): void {
    if (this.firebaseKeyForm.invalid) {
      return;
    }

    let file = this.firebaseKeyForm.controls.key.getRawValue();
    if (file) {
      firstValueFrom(this.appModel.saveFirebaseKey(this.currentApp.id, file)).then(
        (projectId: any) => {
          this.currentApp.settings.messenger_firebase_account_credentials_project_id = projectId;
          this.keyExist = !!this.currentApp.settings.messenger_firebase_account_credentials_project_id;
          this.toastr.success(this.translocoService.translate('firebaseKey.toasts.success'));
          this.cdr.detectChanges();
        },
        (response: any) => {
          if (response.meta.error === 'ValidationError') {
            this.toastr.danger(this.translocoService.translate('firebaseKey.toasts.jsonFormatError'));
          }
        },
      );
    }
  }
}

import { Injectable } from '@angular/core';

import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import {
  ParsedWhatsAppTemplate,
  ParsedWhatsAppTemplateBodyJson,
} from '@http/whats-app-template/types/whats-app-template.types';
import {
  WhatsAppEdnaTemplate,
  WhatsAppEdnaTemplateButton,
} from '@http/whats-app-template/types/whats-app-templates.types';

@Injectable({
  providedIn: 'root',
})
export class WhatsAppTemplateModel {
  constructor(private readonly featureModel: FeatureModel) {}
  /**
   * Преобразование шаблона WhatsApp Edna в реплику диалога
   *
   * @param template Шаблон WhatsApp
   */
  parseToConversationReply(template: WhatsAppEdnaTemplate): ParsedWhatsAppTemplate {
    let body = '';

    if (template.content.header?.text) {
      body += `${template.content.header.text}<br><br>`;
    }

    if (template.content.text) {
      body += template.content.text;
    }

    if (template.content.footer?.text) {
      body += `<br><br>${template.content.footer.text}`;
    }

    let keyboardRow;
    let buttonsArray;
    let buttons;

    if (this.featureModel.hasAccess(FEATURES_ONLY.NEW_EDNA_API)) {
      //@ts-ignore
      keyboardRow = template.content.keyboard?.rows || [];
      buttons = keyboardRow.flatMap((buttonsArray: { buttons: WhatsAppEdnaTemplateButton[] }) => {
        return buttonsArray.buttons.map((button: WhatsAppEdnaTemplateButton) => {
          return {
            disabled: true,
            text: button.text,
          };
        });
      });
    } else {
      keyboardRow = template.content.keyboard?.row;
      buttonsArray = keyboardRow?.buttons || [];
      buttons = buttonsArray.map((whatsAppButton: WhatsAppEdnaTemplateButton) => {
        return {
          disabled: true,
          text: whatsAppButton.text,
        };
      });
    }

    let bodyJson: ParsedWhatsAppTemplateBodyJson = {
      whatsappEdnaTemplate: {
        templateId: template.id,
        variables: {},
      },
    };

    if (buttons.length) {
      bodyJson.buttons = buttons;
    }

    return {
      attachment: [],
      body,
      bodyJson,
    };
  }
}

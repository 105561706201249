import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';

import { App } from '@http/app/app.model';
import { LeadBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { AmocrmIntegrationExternal } from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { Message } from '@http/message/message.types';
import { Properties } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { AmocrmSettingsComponent } from '@panel/app/pages/integrations/content/amocrm/settings/amocrm-settings.component';

/**
 * Компонент-обёртка для настроек интеграции на конкретной странице с интеграцией AmoCRM
 */

@Component({
  selector: 'cq-amocrm-wrapper-settings[chatBots][currentApp][integrationExternal][properties][triggerMessages]',
  templateUrl: './amocrm-wrapper-settings.component.html',
  styleUrls: ['./amocrm-wrapper-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmWrapperSettingsComponent implements OnInit {
  /** Список автособытий */
  @Input()
  set autoEvents(atoEvents: AutoEvent[]) {
    this.amocrmState.autoEvents$.next(atoEvents);
  }

  /** Список с чат-ботами */
  @Input()
  chatBots!: LeadBot[];

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Текущая или новая интеграция во внешнем формате */
  @Input()
  integrationExternal!: AmocrmIntegrationExternal;

  constructor(private readonly amocrmState: AmocrmStateService) {}

  /** Список с триггерными сообщениями */
  @Input()
  triggerMessages!: Message[];

  /** Дочерний элемент настройки интеграции */
  @ViewChild(AmocrmSettingsComponent) amocrmSettingsComponent!: AmocrmSettingsComponent;

  /** Авторизован ли пользователь в amoCRM */
  isAuthorized!: boolean;

  /** Режим редактирования интеграции */
  isEdit!: boolean;

  /** Показываются ли компоненты из модального окна создания интеграции */
  isShowedFromModal: boolean = false;

  /** Список свойств и событий пользователей */
  @Input()
  set properties(properties: Properties) {
    this.amocrmState.eventTypeProps$.next(properties.eventTypeProps);
    this.amocrmState.eventTypes$.next(properties.eventTypes);
    this.amocrmState.userProps$.next(properties.userProps);
  }

  ngOnInit(): void {
    this.isAuthorized = !!this.integrationExternal.settings.oauthAccessToken;
    this.isEdit = !!this.integrationExternal.id;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Invoice } from '@http/invoice/invoice';
import { INVOICE_STATUS } from '@http/invoice/invoice.constants';

/** Компонент для работы со статусом счёта */
@Component({
  selector: 'cq-status[invoice]',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  /** Счёт */
  @Input()
  invoice!: Invoice;

  /**
   * Получение классов для стилизации статуса
   *
   * @param status - Статус
   */
  getClasses(status: string): string {
    let classes;

    switch (true) {
      case status === INVOICE_STATUS.PAID:
        classes = 'text-success';
        break;
      case status === INVOICE_STATUS.VOIDED:
        classes = 'text-muted';
        break;
      case status === INVOICE_STATUS.PAYMENT_DUE:
        classes = 'text-warning';
        break;
      default:
        classes = 'text-danger';
    }

    return classes;
  }
}

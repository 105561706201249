import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LinkEditorForm } from '@panel/app/pages/chat-bot/content/modals/link-editor/link-editor.form';

export enum MODAL_ACTIONS_TYPE {
  EDIT = 'edit',
  REMOVE = 'remove',
}

export type ReturnedLinkEditorModalParams = {
  text?: string;
  url?: string;
  action: MODAL_ACTIONS_TYPE;
};

@Component({
  selector: 'cq-link-editor-modal',
  templateUrl: './link-editor-modal.component.html',
  styleUrls: ['./link-editor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkEditorModalComponent implements OnInit {
  @Input() text!: string;
  @Input() url!: string | null;

  linkEditorForm!: LinkEditorForm;
  isEdit: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.isEdit = !!this.url;
    this.linkEditorForm = new LinkEditorForm(this.text, this.url);
  }

  onSubmit(): void {
    if (this.linkEditorForm.valid) {
      const params: ReturnedLinkEditorModalParams = {
        text: this.linkEditorForm.controls.text.value,
        url: this.linkEditorForm.controls.url.value,
        action: MODAL_ACTIONS_TYPE.EDIT,
      };

      this.activeModal.close(params);
    } else {
      this.linkEditorForm.markAllAsTouched();
    }
  }

  removeLink(): void {
    const params: ReturnedLinkEditorModalParams = {
      action: MODAL_ACTIONS_TYPE.REMOVE,
    };

    this.activeModal.close(params);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppService } from '@http/app/services/app.service';
import { UserSetPropertiesApiResponse } from '@http/user/types/user.types';

import { APIResponse } from '../../types';

/** Сервис для работы с пользователями */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly http: HttpClient, private readonly appService: AppService) {}

  /**
   * Установка свойств пользователю
   *
   * @param userId ID пользователя в нашей системе
   * @param props Массив записываемых свойств
   */
  setProperties(
    userId: string,
    props: Array<{ op: string; key: string; value: any }>,
  ): Observable<APIResponse<UserSetPropertiesApiResponse>> {
    const params = {
      app: this.appService.currentAppId,
      operations: JSON.stringify(props),
    };

    return this.http.post<APIResponse<UserSetPropertiesApiResponse>>(`/users/${userId}/props/`, params);
  }
}

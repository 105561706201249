import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { EMAIL_STATUSES, PSEUDO_EMAIL_STATUSES } from '@http/email-status/email-status.constants';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

export type EmailOperationForm = {
  type: FormControl<ELASTICSEARCH_OPERATION.STR_EQ | ELASTICSEARCH_OPERATION.STR_NOT_EQ>;
  value: FormControl<{ value: EMAIL_STATUSES | PSEUDO_EMAIL_STATUSES }>;
};

@Component({
  selector: 'cq-email-operation-select',
  templateUrl: './email-operation-select.component.html',
  styleUrls: ['./email-operation-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailOperationSelectComponent extends AbsCVAFormGroupBasedComponent<EmailOperationForm> implements OnInit {
  chosenEmailOperation: EMAIL_STATUSES | PSEUDO_EMAIL_STATUSES = EMAIL_STATUSES.CONFIRMED;

  readonly control = this.fb.group<EmailOperationForm>({
    type: this.fb.control<ELASTICSEARCH_OPERATION.STR_EQ | ELASTICSEARCH_OPERATION.STR_NOT_EQ>(
      ELASTICSEARCH_OPERATION.STR_EQ,
      {
        nonNullable: true,
      },
    ),
    value: this.fb.control<{ value: EMAIL_STATUSES | PSEUDO_EMAIL_STATUSES }>(
      {
        value: EMAIL_STATUSES.CONFIRMED,
      },
      { nonNullable: true },
    ),
  });

  OPERATIONS = [
    EMAIL_STATUSES.CONFIRMED,
    EMAIL_STATUSES.NOT_CONFIRMED,
    EMAIL_STATUSES.UNSUBSCRIBED,
    EMAIL_STATUSES.BOUNCED,
    EMAIL_STATUSES.SPAM,
    EMAIL_STATUSES.NOT_VALID,
    PSEUDO_EMAIL_STATUSES.NOT_SUBSCRIBED,
  ];

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const type = this.control.value.type;
    const value = this.control.value.value?.value;

    // Заполняет выбранную операцию
    if (type === ELASTICSEARCH_OPERATION.STR_NOT_EQ) {
      this.chosenEmailOperation = PSEUDO_EMAIL_STATUSES.NOT_SUBSCRIBED;
    } else if (value && this.OPERATIONS.includes(value)) {
      this.chosenEmailOperation = value;
    } else {
      // Если value не часть OPERATIONS, значит пользователь уже успел сохранить не правлиьно
      // Надо выставить правлиьные значение для формы
      this.setCorrectFilter(this.chosenEmailOperation);
    }
  }

  /**
   * Получить строку перевода для статуса подписки
   * @param op
   */
  getTranslationPath(op: EMAIL_STATUSES | PSEUDO_EMAIL_STATUSES): string {
    let path = 'models.emailStatus.';
    path += op === PSEUDO_EMAIL_STATUSES.NOT_SUBSCRIBED ? 'pseudoStatuses.' : 'statuses.';
    path += op;

    return path;
  }

  /**
   * Заполняет форму с фильтром корректными данными, в зависимости от статуса подписки на почту
   * @param status Значение статуса подписки
   */
  setCorrectFilter(status: EMAIL_STATUSES | PSEUDO_EMAIL_STATUSES) {
    if (status === PSEUDO_EMAIL_STATUSES.NOT_SUBSCRIBED) {
      this.control.setValue({
        type: ELASTICSEARCH_OPERATION.STR_NOT_EQ,
        value: { value: EMAIL_STATUSES.CONFIRMED },
      });
    } else {
      this.control.setValue({
        type: ELASTICSEARCH_OPERATION.STR_EQ,
        value: { value: status },
      });
    }
  }
}

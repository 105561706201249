(function () {
  'use strict';

  require('./auto-event-video-collapsing.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventVideoCollapsing', cqAutoEventVideoCollapsing());

  function cqAutoEventVideoCollapsing() {
    return {
      bindings: {
        heading: '@', // Заголовок
        videoUrl: '@', // Адрес видео
        initialCollapse: '=?', // Начальное значение свернутости
      },
      controller: 'CqAutoEventVideoController',
      controllerAs: 'vm',
      template: require('./auto-event-video-collapsing.html'),
    };
  }
})();

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { WizardService } from '@panel/app/partials/wizard/service/wizard.service';

@Component({
  selector: 'cq-wizard-item-new',
  templateUrl: './wizard-item.component.html',
  styleUrls: ['./wizard-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardItemComponent implements OnInit, OnDestroy {
  /** Заголовок шага */
  @Input() heading!: string;
  /** Название шага */
  @Input() name!: string;
  /** Флаг нового визарда */
  @Input() newWizard?: boolean;
  /** Callback для onEnter */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Input() onEnterCallback: any;
  /** Callback для onEnterFromNext */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Input() onEnterFromNextCallback: any;
  /** Callback для onEnterFromPrevious */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Input() onEnterFromPreviousCallback: any;
  /** Callback для onExit */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Input() onExitCallback: any;
  /** Callback для onExitToNext */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Input() onExitToNextCallback: any;
  /** Callback для onExitToPrevious */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Input() onExitToPreviousCallback: any;

  /** Callback для onEnter */
  @Output() enterCallback: any = new EventEmitter();
  /** Callback для onEnterFromNext */
  @Output() enterFromNextCallback: any = new EventEmitter();
  /** Callback для onEnterFromPrevious */
  @Output() enterFromPreviousCallback: any = new EventEmitter();
  /** Callback для onExit */
  @Output() exitCallback: any = new EventEmitter();
  /** Callback для onExitToNext */
  @Output() exitToNextCallback: any = new EventEmitter();
  /** Callback для onExitToPrevious */
  @Output() exitToPreviousCallback: any = new EventEmitter();

  /** флаг активности шага */
  public active: boolean = false;
  /** флаг завершённости шага */
  public complete: boolean = false;
  /** Заголовок шага */
  public show: boolean = false;

  constructor(protected readonly wizardService: WizardService, public cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.active = false; // флаг активности шага
    this.complete = false; // флаг завершённости шага
    this.show = false; // флаг завершённости шага

    this.wizardService.addStep(this);
  }

  ngOnDestroy() {
    this.wizardService.removeStep(this);
  }

  /**
   * Функция, которая выбирает необходимый колбэк onEnter или onEnterFromNext, превращает в Promise и вызывает его
   */
  onEnterFromNext(): Promise<any> {
    if (this.onEnterCallback !== undefined) {
      this.enterCallback.next();
      return Promise.resolve(this.onEnterCallback);
    } else if (this.onEnterFromNextCallback !== undefined) {
      this.enterFromNextCallback.next();
      return Promise.resolve(this.onEnterFromNextCallback);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Функция, которая выбирает необходимый колбэк onEnter или onEnterFromPrevious, превращает в Promise и вызывает его
   */
  onEnterFromPrevious(): Promise<any> {
    if (this.onEnterCallback !== undefined) {
      this.enterCallback.next();
      return Promise.resolve(this.onEnterCallback);
    } else if (this.onEnterFromPreviousCallback !== undefined) {
      this.enterFromPreviousCallback.next();
      return Promise.resolve(this.onEnterFromPreviousCallback);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Функция, которая выбирает необходимый колбэк onExit или onExitToPrevious, превращает в Promise и вызывает его.
   */
  onExitToPrevious(): Promise<any> {
    if (this.onExitCallback !== undefined) {
      this.exitCallback.next();
      return Promise.resolve(this.onExitCallback);
    } else if (this.onExitToPreviousCallback !== undefined) {
      this.exitToPreviousCallback.next();
      return Promise.resolve(this.onExitToPreviousCallback);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Функция, которая выбирает необходимый колбэк onExit или onExitToNext, превращает в Promise и вызывает его
   */
  public onExitToNext(): Promise<any> {
    if (this.onExitCallback !== undefined) {
      this.exitCallback.next();
      return Promise.resolve(this.onExitCallback);
    } else if (this.onExitToNextCallback !== undefined) {
      this.exitToNextCallback.next();
      return Promise.resolve(this.onExitToNextCallback);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Выбор этого шага в качестве текущего
   */
  selectStep(step: any): void {
    this.wizardService.goToStep(step);
  }
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { map, mergeMap } from 'rxjs/operators';

import { PSEUDO_DIRECTORY_IDS, PSEUDO_DIRECTORY_TYPES } from '@http/message-directory/message-directory.constants';

@Injectable({ providedIn: 'root' })
export class MessageDirectoryModel {
  DEFAULT_DIRECTORY = {
    id: null,
    name: '',
    isSystem: false,
  };

  constructor(private readonly transloco: TranslocoService, private readonly http: HttpClient) {}

  /**
   * Создание папки
   *
   * @param appId ID приложения
   * @param name Имя папки
   * @param errorsException Исключает ошибки, по которым не надо показывать системную ошибку
   */
  create(appId: string, name: string, errorsException: string | string[]) {
    const body = {
      app: appId,
      name: name,
      errorsException: errorsException,
    };
    return this.http
      .post<any>('/messagedirectories', body, { context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true) })
      .pipe(
        mergeMap((data) => {
          return this.getDirectory(appId, data.id);
        }),
      );
  }

  getWithoutDirectory() {
    return this.getPseudoDirectory(PSEUDO_DIRECTORY_TYPES.WITHOUT_DIRECTORY);
  }

  /**
   * Редактирование папки
   *
   * @param appId ID приложения
   * @param directory Папка
   * @param errorsException Исключает ошибки, по которым не надо показывать системную ошибку
   */
  edit(appId: string, directory: any, errorsException: string | string[]) {
    const body = {
      app: appId,
      name: directory.prettyName,
      errorsException: errorsException,
    };
    return this.http
      .put('/messagedirectories/' + directory.id, body, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        mergeMap(() => {
          return this.getDirectory(appId, directory.id);
        }),
      );
  }

  /**
   * Получение директории по ID
   *
   * @param appId ID приложения
   * @param directoryId ID директории
   */
  getDirectory(appId: string, directoryId: string) {
    const params = {
      app: appId,
    };
    return this.http
      .get<any>('/messagedirectories/' + directoryId, {
        params,
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((directory) => {
          this.parse(directory);
          return directory;
        }),
      );
  }

  /**
   * Получение папок
   *
   * @param appId ID приложения
   * @param includeAllDirectory Флаг добавления псевдопапки 'Все автосообщения' в список
   * @param includeWithoutDirectory Флаг добавления псевдопапки 'Без папки' в список
   */
  getList(appId: string, includeWithoutDirectory: boolean, includeAllDirectory: boolean) {
    return this.http.get<any>(`/apps/${appId}/messagedirectories`).pipe(
      map((directories) => {
        if (includeAllDirectory) {
          directories.push(this.getPseudoDirectory(PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY));
        }

        if (includeWithoutDirectory) {
          directories.push(this.getPseudoDirectory(PSEUDO_DIRECTORY_TYPES.WITHOUT_DIRECTORY));
        }

        for (let i = 0; i < directories.length; i++) {
          this.parse(directories[i]);
        }
        return directories;
      }),
    );
  }

  /**
   * Получение псевдопапки
   *
   * @param directoryType Тип псевдопапки
   */
  getPseudoDirectory(directoryType: typeof PSEUDO_DIRECTORY_TYPES[keyof typeof PSEUDO_DIRECTORY_TYPES]) {
    const pseudoDirectory: any = angular.copy(this.DEFAULT_DIRECTORY);

    pseudoDirectory.id = PSEUDO_DIRECTORY_IDS[directoryType];
    pseudoDirectory.name = directoryType;
    pseudoDirectory.isSystem = true;

    return pseudoDirectory;
  }

  /**
   * Парсинг папки
   *
   * @param {Object} directory Директория
   */
  parse(directory: any) {
    if (/^\$/.test(directory.name)) {
      directory.prettyName = this.transloco.translate(`models.directory.systemMessageDirectoryTypes.${directory.name}`);
    } else {
      directory.prettyName = directory.name;
    }
  }

  /**
   * Удаление папки
   *
   * @param appId ID приложения
   * @param directoryId Id папки
   */
  remove(appId: string, directoryId: string) {
    const body = {
      app: appId,
    };
    return this.http.delete(`/messagedirectories/${directoryId}`, {
      body,
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    });
  }
}

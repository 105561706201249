<ng-container *transloco="let t; read: 'triggerChainTableComponent'">
  <ng-container *ngIf="chainList$ | async as chainList">
    <div
      *ngIf="!isLoading; else noLoading"
      class="card position-sticky"
    >
      <div class="card-header">
        <div class="d-flex justify-space-between">
          <!--ВЫБОР СТАТУСА ТРИГГЕРНЫХ ЦЕПОЧЕК-->
          <div class="btn-group btn-group-toggle">
            <label
              class="btn btn-outline-primary"
              [ngClass]="{ active: selectedStatus === status }"
              *ngFor="let status of CHAIN_STATUS_LIST"
            >
              <input
                name="eventType"
                type="radio"
                [value]="status"
                [ngModel]="selectedStatus"
                (ngModelChange)="changeStatusSubj.next(status)"
              />
              <i
                *ngIf="status === CHAIN_STATUS.ACTIVE"
                class="btn-icon-left cqi-sm cqi-play-o"
              ></i>
              <i
                *ngIf="status === CHAIN_STATUS.STOPPED"
                class="btn-icon-left cqi-sm cqi-pause"
              ></i>
              {{ t('table.chainStatuses.' + status) }}
            </label>
          </div>

          <!--ОБНОВЛЕНИЕ СПИСКА-->
          <div class="d-flex">
            <button
              class="btn btn-outline-primary"
              type="button"
              (click)="refreshListSubj.next()"
            >
              <i class="btn-icon cqi-sm cqi-refresh-2"></i>
            </button>
          </div>
        </div>
      </div>

      <!--ТАБЛИЦА-->
      <table
        *ngIf="chainList.length !== 0; else zeroData"
        class="table table-align-middle table-sticky-header position-sticky"
      >
        <thead>
          <tr>
            <th>{{ t('table.headings.name') }}</th>
            <th>{{ t('table.headings.lastSent') }}</th>
            <th>{{ t('table.headings.sended') }}</th>
            <th>{{ t('table.headings.inProgress') }}</th>
            <th>{{ t('table.headings.droppedOff') }}</th>
            <th>{{ t('table.headings.finished') }}</th>
            <th></th>
          </tr>
        </thead>

        <!-- ЦЕПОЧКИ -->
        <tbody>
          <tr
            cq-trigger-chain-table-item
            *ngFor="let chain of chainList; trackBy: trackByChain"
            [chain]="chain"
            (chainChange)="changeStatusSubj.next(changeStatusSubj.getValue())"
          ></tr>
        </tbody>
      </table>

      <!-- Zero data -->
      <ng-template #zeroData>
        <div class="card-body py-40 text-center">
          <cq-zero-data class="mb-10">
            <span class="text-muted">{{ t('zeroData') }}</span>
          </cq-zero-data>
          <a
            class="btn btn-primary"
            uiSref="app.content.triggerChains.editor.create"
          >
            {{ t('create') }}
          </a>
        </div>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #noLoading>
    <cq-loader class="content-loader"></cq-loader>
  </ng-template>
</ng-container>

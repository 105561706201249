<ng-container *transloco="let t; read: 'aiDataSettingsBannerComponent'">
  <div class="card m-0">
    <div class="card-body d-flex align-items-center">
      <img
        ngSrc="assets/img/default/ai-data-sources/stars.svg"
        width="30"
        height="30"
      />
      <div class="ml-15">
        <h4 class="mb-5">{{ t('heading') }}</h4>
        <div>
          <span>{{ t('description.text') }}</span>
          <a uiSref="app.content.aiDataSources.botSettings">{{ t('description.link') }}</a>
        </div>
      </div>
      <button
        class="btn btn-borderless-primary ml-auto"
        type="button"
        (click)="onClickCloseButton()"
      >
        <i class="btn-icon cqi-sm cqi-times"></i>
      </button>
    </div>
  </div>
</ng-container>

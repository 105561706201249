import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { KnowledgeBaseActivateModule } from '@panel/app/pages/knowledge-base/shared/components/knowledge-base-activate/knowledge-base-activate.module';

import { KnowledgeBaseActivateWrapperComponent } from './knowledge-base-activate-wrapper.component';

@NgModule({
  declarations: [KnowledgeBaseActivateWrapperComponent],
  exports: [KnowledgeBaseActivateWrapperComponent],
  imports: [CommonModule, TranslocoModule, KnowledgeBaseActivateModule],
})
export class KnowledgeBaseActivateWrapperModule {}

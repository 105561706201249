import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cq-tc-block-onboarding-overlay',
  templateUrl: './tc-block-onboarding-overlay.component.html',
  styleUrls: ['./tc-block-onboarding-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TcBlockOnboardingOverlayComponent {
  @Input()
  @HostBinding('style.width.px')
  width: number = 0;

  @Input()
  @HostBinding('style.height.px')
  height: number = 0;

  @Input()
  @HostBinding('style.left.px')
  x = 0;

  @Input()
  @HostBinding('style.top.px')
  y = 0;

  @Input()
  @HostBinding('attr.id')
  id?: string;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AbstractTemplate } from '@panel/app/shared/types/abstract-template.type';

@Component({
  selector: 'cq-template-preview[isCreate][template][clickBackButton][clickSelectTemplate]',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatePreviewComponent {
  @Input()
  template!: AbstractTemplate;

  @Input()
  isCreate!: boolean;

  @Output()
  clickBackButton: EventEmitter<AbstractTemplate> = new EventEmitter<AbstractTemplate>();

  @Output()
  clickSelectTemplate: EventEmitter<AbstractTemplate> = new EventEmitter<AbstractTemplate>();

  onClickBackButton() {
    this.clickBackButton.emit(this.template);
  }

  onClickSelectTemplate() {
    this.clickSelectTemplate.emit(this.template);
  }
}

(function () {
  'use strict';

  require('../auto-event-setting-base.controller');

  angular.module('myApp.trackMaster').component('cqTrackMasterUrl', cqTrackMasterUrl());

  function cqTrackMasterUrl() {
    return {
      bindings: {
        autoEvent: '=', // Автособытие
      },
      controller: 'CqAutoEventSettingBaseController',
      controllerAs: 'vm',
      template: require('./track-master-url.html'),
    };
  }
})();

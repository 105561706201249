import { TranslocoService } from '@jsverse/transloco';
import sortBy from 'lodash-es/sortBy';

import { INTEGRATION_TYPES } from '../../constants/integration.constants';
import { SettingsIntegration } from '../../integration';
import { VkIntegrationForm } from './forms/vk-integration.form';
import {
  VkIntegrationExternal,
  VkIntegrationGroupExternal,
  VkIntegrationSettingsExternal,
} from './interfaces/vk-integration.interfaces';

export class VkIntegration extends SettingsIntegration<VkIntegrationSettingsExternal> {
  /** Форма настройки интеграции */
  form: VkIntegrationForm;
  /** Тип интеграции */
  type = INTEGRATION_TYPES.VK;
  /** Настройки интеграции */
  settings: VkIntegrationSettingsExternal = {
    apiToken: null,
    confirmCode: null,
    oauthAccessToken: null,
    groups: [],
    oauthCsrfToken: null,
    token: null,
  };

  constructor(translocoService: TranslocoService, integrationExternal?: VkIntegrationExternal) {
    super(integrationExternal);

    // Если интеграция создается из внешнего формата, обновим settings, иначе задаем дефолтные значения
    if (integrationExternal) {
      this.updateSettings(integrationExternal.settings);

      this.settings.groups = sortBy(this.settings.groups, (group) => group.name);
    } else {
      this.active = true;

      const integrationTypeName = translocoService.translate(`models.integration.types.${this.type}.name`);
      this.name = translocoService.translate('models.integration.defaultValues.name', { integrationTypeName });
    }

    // Данные о настроенной интеграции собираются с формы и отправляются на backend
    this.form = new VkIntegrationForm(this);
  }

  /**
   * Группы которые надо подписать
   */
  get groupsForSubscribing(): VkIntegrationGroupExternal[] {
    return this.externalFormat.settings.groups.filter((group) => group.subscribed);
  }

  /**
   * Обновляет занчения формы настроек
   * @private
   */
  private updateFormBySettings() {
    this.form.controls.settings.patchValue(this.settings);
  }

  /**
   * Обновление настроек интегарции
   *
   * @param settings - Настройки интеграции во внешнем формате
   * @param updateFormSettings=false - Флаг для обновления формы настроек
   */
  updateSettings(settings: VkIntegrationSettingsExternal, updateFormSettings: boolean = false): void {
    super.updateSettings(settings);

    if (updateFormSettings) {
      this.updateFormBySettings();
    }
  }

  get externalFormat(): VkIntegrationExternal {
    return {
      ...this.baseExternalFormat,
      ...this.form.value,
    };
  }
}

<ng-container *transloco="let t; read: 'notificationChannelSettingsComponent'">
  <div class="font-weight-bold mb-15">{{ t('heading') }}</div>
  <ng-select
    [formControl]="notMutedChannelsControl"
    [clearable]="false"
    [closeOnSelect]="false"
    [items]="channelList"
    [multiple]="true"
    (change)="onChangeNotMutedChannels()"
    placeholder="{{ t('selectPlaceholder') }}"
    data-test="channels-select"
  >
    <ng-template
      let-clear="clear"
      let-items="items"
      ng-multi-label-tmp
    >
      <div
        class="ng-value"
        *ngFor="let item of items"
      >
        <button
          class="btn btn-sm btn-outline-primary flex align-items-center overflow-hidden"
          type="button"
        >
          <ng-container>
            <div class="flex align-items-center">
              <cq-conversation-channel
                [channel]="item"
                md
              ></cq-conversation-channel>
            </div>
            <span
              class="flex"
              (click)="clear(item)"
            >
              <i class="cqi-xs cqi-times ml-5"></i>
            </span>
          </ng-container>
        </button>
      </div>
    </ng-template>

    <ng-template
      ng-option-tmp
      let-option="item"
    >
      <div class="flex align-items-center">
        <cq-conversation-channel
          [channel]="option"
          md
        ></cq-conversation-channel>
      </div>
    </ng-template>

    <ng-container *ng-footer-tmp>
      <button
        type="button"
        class="btn btn-borderless-primary btn-block"
        (click)="addAllChannels()"
        data-test="add-all-channels-button"
      >
        {{ t('addAllChannels') }}
      </button>
    </ng-container>
  </ng-select>
</ng-container>

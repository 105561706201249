import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';

import { AiDataSourceListComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-source-list.component';
import { AiDataSourceTableComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-source-table/ai-data-source-table.component';
import { AiDataSourceTableItemComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-source-table-item/ai-data-source-table-item.component';
import { CategoryFilterComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/category-filter/category-filter.component';
import { SearchComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/search/search.component';
import { SourceFilterComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/source-filter/source-filter.component';
import { AutoMessageStatusFilterModule } from '@panel/app/pages/auto-messages/components/list/status-filter/auto-message-status-filter.module';
import { SharedModule } from '@panel/app/shared/shared.module';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { AiDataSettingsBannerComponent } from './ai-data-settings-banner/ai-data-settings-banner.component';
import { StatusFilterComponent } from './status-filter/status-filter.component';

@NgModule({
  declarations: [
    AiDataSourceListComponent,
    AiDataSourceTableComponent,
    AiDataSourceTableItemComponent,
    StatusFilterComponent,
    SourceFilterComponent,
    CategoryFilterComponent,
    SearchComponent,
    AiDataSettingsBannerComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    UIRouterModule,
    FormsModule,
    LoaderModule,
    SharedModule,
    ReactiveFormsModule,
    MomentModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgSelectModule,
    NgOptimizedImage,
    AutoMessageStatusFilterModule,
  ],
})
export class AiDataSourceListModule {}

(function () {
  'use strict';

  require('./clean-template.controller');

  angular.module('myApp.messageTemplate').component('cqCleanTemplate', cqCleanTemplate());

  function cqCleanTemplate() {
    return {
      controllerAs: 'vm',
      controller: 'CqCleanTemplateController',
      template: require('./clean-template.html'),
    };
  }
})();

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { InteractionEvent, PointerPositionEvent } from './pixi-interaction.types';

@Injectable()
export class PixiInteractionService {
  private readonly hoverOverSubj: Subject<InteractionEvent> = new Subject();
  private readonly dragSubj: Subject<InteractionEvent> = new Subject();
  private readonly pointerDownSubj: Subject<InteractionEvent> = new Subject();
  private readonly pointerUpSubj: Subject<InteractionEvent> = new Subject();
  private readonly pointerPositionSubj: Subject<PointerPositionEvent> = new Subject();
  private readonly clickSubj: Subject<InteractionEvent> = new Subject();

  readonly hover$ = this.hoverOverSubj.asObservable().pipe(
    // Hover от Viewport это на самом деле mousemove, поэтому надо слать только один из таких ивентов
    distinctUntilChanged((ev1, ev2) => {
      return ev1.type === ev2.type && ev1.instance === ev2.instance;
    }),
  );
  readonly drag$ = this.dragSubj.asObservable();
  readonly pointerDown$ = this.pointerDownSubj.asObservable();
  readonly pointerUp$ = this.pointerUpSubj.asObservable();
  readonly pointerPosition$ = this.pointerPositionSubj.asObservable();
  readonly click$ = this.clickSubj.asObservable();

  registerHover(event: InteractionEvent) {
    this.hoverOverSubj.next(event);
  }
  registerDrag(event: InteractionEvent) {
    this.dragSubj.next(event);
  }
  registerPointerDown(event: InteractionEvent) {
    this.pointerDownSubj.next(event);
  }
  registerPointerUp(event: InteractionEvent) {
    this.pointerUpSubj.next(event);
  }
  registerPointerPosition(event: PointerPositionEvent) {
    this.pointerPositionSubj.next(event);
  }
  registerClick(event: InteractionEvent) {
    this.clickSubj.next(event);
  }
}

import { POPUP_BLOCK_TYPES } from '../../../../app/services/popup-block/popup-block.constants';

(function () {
  'use strict';

  angular.module('myApp.popupEditor').controller('CqPopupBlockSettingsController', CqPopupBlockSettingsController);

  function CqPopupBlockSettingsController($scope, caseStyleHelper) {
    var vm = this;

    /**
     * Функция компонента настроек блока, которая вызывается при невалидности формы компонента настроек блока
     * По сути нужна для выполнения различной логики, присущей компонентам настройки блока (например, открытие нужной вкладки с ошибкой)
     *
     * @type {Function|Null}
     */
    var blockSettingsErrorHandler = null;

    vm.$onInit = init;

    function init() {
      vm.caseStyleHelper = caseStyleHelper;
      vm.close = close;
      vm.POPUP_BLOCK_TYPES = POPUP_BLOCK_TYPES;
      vm.popupBlockSettingsWrapperForm = null; // форма настроек блока
      vm.setBlockSettingsErrorHandler = setBlockSettingsErrorHandler;

      vm.setErrorHandler && vm.setErrorHandler({ handler: handleError });
    }

    /**
     * Проверка валидности формы и скрытие враппера при её валидности
     *
     * @param {Boolean} isValid Валидна ли форма
     */
    function close(isValid) {
      if (isValid) {
        vm.onClose();
      } else {
        handleError();
      }
    }

    /**
     * Функция обработки ошибок формы при отправке формы снаружи компонента
     */
    function handleError() {
      // искусственно сабмитим форму (с ngForm по-другому не получится) на случай, если родительская форма не была засабмичена, чтобы отображались ошибки
      vm.popupBlockSettingsWrapperForm.$setSubmitted();

      // если открыта невалидная вкладка - переключаться на другую вкладку не нужно
      if (vm.popupBlockSettingsWrapperForm.$invalid && angular.isFunction(blockSettingsErrorHandler)) {
        blockSettingsErrorHandler();
      }
    }

    /**
     * Установка функции, которая будет вызываться в случае невалидности настроек блока
     *
     * @param {Function} handler
     */
    function setBlockSettingsErrorHandler(handler) {
      blockSettingsErrorHandler = handler;
    }
  }
})();

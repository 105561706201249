import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cq-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./article-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ArticlePreviewComponent {
  /** Контент статьи */
  @Input() content!: string;
  /** Описание статьи */
  @Input() description!: string;
  /** Имя статьи */
  @Input() name!: string;

  constructor() {}
}

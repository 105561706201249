import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Компонент для работы с номером шага в стартергайде */
@Component({
  selector: 'cq-step-number[isMadeStep][number]',
  templateUrl: './step-number.component.html',
  styleUrls: ['./step-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepNumberComponent {
  /** Пройден ли шаг */
  @Input()
  isMadeStep!: boolean;

  /** Номер шага */
  @Input()
  number!: string;

  /** Получение класса для номера шага */
  getClass(): string {
    return this.isMadeStep ? 'bg-light-success' : 'bg-light-secondary text-muted';
  }
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { provideTransloco, TranspileParams } from '@jsverse/transloco';
import {
  Translation,
  TRANSLOCO_TRANSPILER,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService,
} from '@jsverse/transloco';
import { MessageFormatTranspiler } from '@jsverse/transloco-messageformat';
import { firstValueFrom, Observable } from 'rxjs';

import { SKIP_INTERCEPTOR } from '@panel/app/shared/constants/http.constants';

import { environment } from '../environments/environment';

/**
 * Подгрузка переводов
 * Используется совместно с APP_INITIALIZER, чтобы загрузить все переводы до бутстрапинга нового ангуляра
 *
 * @param translocoService
 */
export function loadTranslations(translocoService: TranslocoService): () => Promise<Translation> {
  return function () {
    // настройваем переводы для новых компонентов. Определение языка и вообще вся основная движуха происходит пока что в старом ангуляре, а тут достаточно прочитать значение из localstorage
    //NOTE Тут сдлано не очень хорошо т.к. все свойста environment помечены как readonly
    // @rumyantsev_artem сказал пока сделать так
    //@ts-ignore
    environment.language =
      //@ts-ignore
      <typeof environment.language>localStorage.getItem('carrotquest_language') || environment.defaultLanguage;

    translocoService.setActiveLang(environment.language);
    return firstValueFrom(translocoService.load(environment.language));
  };
}

@Injectable({ providedIn: 'root' })
class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  /**
   * Получение файла переводов для языка lang
   *
   * @param lang Язык, для которого получается файл
   */
  getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`./assets/i18n/${lang}.json`, {
      context: new HttpContext().set(SKIP_INTERCEPTOR, true),
    });
  }
}

/**
 * Стандартный MessageFormatTranspiler не умеет работать с массивом, поэтому сделал вот так:
 */
@Injectable()
class MessageFormatArrayTranspiler extends MessageFormatTranspiler {
  transpile({ value, params, translation, key }: TranspileParams): any {
    if (Array.isArray(value)) {
      return value.map((val) =>
        super.transpile({
          value: val,
          params,
          translation,
          key,
        }),
      );
    }
    return super.transpile({
      value,
      params,
      translation,
      key,
    });
  }
}

@NgModule({
  imports: [],
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['ru', 'en'],
        defaultLang: 'ru',
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: TRANSLOCO_TRANSPILER,
      useClass: MessageFormatArrayTranspiler,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: loadTranslations,
      deps: [TranslocoService],
    },
  ],
})
export class TranslocoRootModule {}

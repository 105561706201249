(function () {
  'use strict';

  require('./upload-file-with-leads.controller');

  angular.module('myApp.users').component('cqUploadFileWithLeads', cqUploadFileWithLeads());

  function cqUploadFileWithLeads() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        importType: '<', // Тип импорта
        importTypes: '<', // Типы импорта
        onAttachFile: '&?', // Колбэк, срабатывающий при прикреплении файла
      },
      controller: 'CqUploadFileWithLeadsController',
      controllerAs: 'vm',
      template: require('./upload-file-with-leads.html'),
    };
  }
})();

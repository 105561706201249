import { TranslocoService } from '@jsverse/transloco';
import { last } from 'lodash-es';
import { firstValueFrom } from 'rxjs';

import { ADDON_STATUS } from '@http/addon/addon.constants';
import { AddonConstruct } from '@http/addon/addon.types';
import { AddonHttpService } from '@http/addon/http/addon-http.service';

/** Класс для работы с аддоном */
export class Addon {
  /** ID */
  id: string;

  /** Название */
  name: string;

  /** Описание */
  description: string;

  /** Цена, которая платится за месяц */
  priceForMonth?: number;

  /** Статус */
  status: ADDON_STATUS = ADDON_STATUS.DISCONNECTED;

  /** Стоимость тарифа по уникальным пользователям */
  tiers?: {
    price: number;
    endingUnit: number;
  }[];

  /** Количество доступных единиц аддона */
  quantity: number;

  /** Стоимость перерасхода за сущность аддона */
  overspendingPrice: number;

  /** Состояние подключенности */
  get isConnected(): boolean {
    return [ADDON_STATUS.CONNECTED, ADDON_STATUS.SUSPENDED].includes(this.status);
  }

  /** Переведённый статус */
  get translatedStatus(): string {
    return this.transloco.translate(`addons.status.${this.status}`);
  }

  constructor(
    private readonly httpService: AddonHttpService,
    private readonly transloco: TranslocoService,
    private readonly dto: AddonConstruct,
  ) {
    this.id = dto.id;
    this.name = dto.invoice_name;
    this.description = dto.description;
    this.priceForMonth = dto.amount;
    this.status = dto.status;
    this.quantity = dto.quantity;

    if (dto.status === ADDON_STATUS.CONNECTED) {
      this.priceForMonth = dto.amount;
    } else if (dto.price) {
      this.priceForMonth = +dto.price;
    } else if (dto.tiers) {
      this.tiers = dto.tiers.map(({ price, ending_unit }) => {
        return {
          price,
          endingUnit: ending_unit,
        };
      });
    }

    this.overspendingPrice = this.getOverspendingPrice();
  }

  /**
   * Подключение
   *
   * @param appId - ID приложения
   */
  connect(appId: string): Promise<Addon> {
    return firstValueFrom(this.httpService.connect(appId, this));
  }

  /** Подключения аддона через чат */
  connectViaChat(): void {
    const message = this.transloco.translate('addons.connectViaChatMessage', { name: this.name });

    this.httpService.connectViaChat(message);
  }

  /**
   * Отключение
   *
   * @param appId - ID приложения
   */
  disconnect(appId: string): Promise<Addon> {
    return firstValueFrom(this.httpService.disconnect(appId, this));
  }

  /** Получение цены, которая платится за месяц */
  getPricePerMonth(users: number): number {
    if (this.priceForMonth) {
      return this.priceForMonth;
    }

    if (this.tiers) {
      let tier =
        this.tiers.filter(({ endingUnit }) => endingUnit === users)[0] ||
        last(this.tiers.filter(({ endingUnit }) => endingUnit < users));

      return tier.price;
    }

    return 0;
  }

  /** Стоимость перерасхода за сущность аддона. Например, за каждый диалог. */
  private getOverspendingPrice() {
    if (!this.dto.body?.meta_data?.cost_overruns) {
      return 0;
    }

    return this.dto.body.meta_data.cost_overruns[this.quantity];
  }
}

import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { firstValueFrom } from 'rxjs';

// TODO реализовать функционал открытия соответствующего модального окна по переходу на url'ы /edit, /create и /copy
(function () {
  'use strict';

  angular.module('myApp.funnels').config(config).run(run);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.funnels', {
        url: '/funnels',
        redirectTo: 'app.content.funnels.unconfigured',
        resolve: {
          funnels: getFunnels,
          properties: getProperties,
        },
        template:
          '\
          <section id="content">\
            <div class="container-fluid limited-container" ui-view></div>\
          </section>\
        ',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
      })

      .state('app.content.funnels.unconfigured', {
        url: '',
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          funnel: 'funnel',
          funnels: 'funnels',
          properties: 'properties',
        },
        component: 'cqFunnels',
        resolve: {
          funnel: angular.bind(null, angular.identity, null),
        },
      })

      .state('app.content.funnels.configured', {
        url: '/{funnelId:id}',
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          funnel: 'funnel',
          funnels: 'funnels',
          properties: 'properties',
        },
        component: 'cqFunnels',
        params: {
          funnelId: {
            dynamic: true,
          },
          startDate: {
            dynamic: true, // передаем начальное значение датапикера при переходе между воронками
          },
          endDate: {
            dynamic: true, // передаем конечное значение датапикера при переходе между воронками
          },
        },
        resolve: {
          funnel: getFunnel,
        },
      })

      // DEPRECATED: старая версия воронок, которая была удалена, состояние оставлено для перенаправления на новые воронки
      .state('app.content.funnelsOld', {
        url: '/funnels2',
        redirectTo: 'app.content.funnels',
      })

      // DEPRECATED: вторая старая версия воронок, которая была удалена, состояние оставлено для перенаправления на новые воронки
      .state('app.content.funnelsOld2', {
        url: '/analytics/funnels',
        redirectTo: 'app.content.funnels',
      })

      // DEPRECATED: обработка старых ссылок на воронки
      .state('app.content.funnelsOldUrl', {
        url: '/new-funnels',
        redirectTo: 'app.content.funnels',
      })

      .state('app.content.funnelsOldUrlWithId', {
        url: '/new-funnels/{funnelId:id}',
        redirectTo: function (transition) {
          return {
            state: 'app.content.funnels.configured',
            params: transition.params(),
          };
        },
      });

    /**
     * Возвращает воронку по funnelId
     *
     * @param {Object} $filter
     * @param {Object} $stateParams
     * @param {Object} funnels
     */
    function getFunnel($filter, $stateParams, funnels) {
      return $filter('filter')(funnels, { id: $stateParams.funnelId }, true)[0];
    }

    /**
     * Получение списка воронок
     *
     * @param currentApp
     * @param funnelModel
     * @param planFeatureAccessService
     *
     * @return {Promise}
     */
    function getFunnels(currentApp, funnelModel, planFeatureAccessService) {
      if (planFeatureAccessService.getAccess(PLAN_FEATURE.EVENTS_FUNNELS, currentApp).hasAccess) {
        return firstValueFrom(funnelModel.getList(currentApp.id));
      } else {
        return new Promise((resolve) => resolve([]));
      }
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param propertyModel
     * @param currentApp
     * @return {Promise}
     */
    function getProperties(propertyModel, currentApp) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }
  }

  function run($filter, $transitions, $state) {
    $transitions.onFinish({ entering: 'app.content.funnels' }, funnelsOnFinish);
    $transitions.onFinish({ to: 'app.content.funnels.configured' }, configuredOnFinish);
    $transitions.onFinish({ to: 'app.content.funnels.unconfigured' }, unconfiguredOnFinish);

    /**
     * Переход в раздел новых воронок на определенную воронку
     *
     * @param {Object} transition
     */
    function configuredOnFinish(transition) {
      var funnels = transition.injector().get('funnels');
      var funnelId = transition.params().funnelId;

      // если список воронок пустой - перенаправляем на unconfigured
      if (funnels.length) {
        var targetFunnel = $filter('filter')(funnels, { id: funnelId }, true)[0];

        // если среди списка воронок нету воронки с ID, полученным из параметров - переходим на состояние unconfigured
        if (!targetFunnel) {
          return $state.target('app.content.funnels.unconfigured', transition.params());
        }
      } else {
        return $state.target('app.content.funnels.unconfigured', transition.params());
      }
    }

    /**
     * Обновляет связи воронок с типами событий
     * при первоначальной загрузке, при копировании и/или создании новой воронки
     *
     * @param {Object} transition
     */
    function funnelsOnFinish(transition) {
      var funnels = transition.injector().get('funnels');
      var properties = transition.injector().get('properties');
      const funnelModel = transition.injector().get('funnelModel');

      funnelModel.linkWithEventTypes(funnels, properties.eventTypes);
    }

    /**
     * Переход в раздел новых воронок без параметров
     *
     * @param {Object} transition
     */
    function unconfiguredOnFinish(transition) {
      var funnels = transition.injector().get('funnels');
      // HACK откуда в unconfigured состоянии взяться ID воронки? Всё просто, при переходе в воронки из навигации вызывается это состояние, и если чувак уже находится в воронках и нажимает на кнопку в навигации - ничего не должно измениться
      var funnelId = transition.params('from').funnelId;

      // если список воронок не пустой - нужно перейти на первую воронку с писке
      if (funnels.length) {
        if (!funnelId) {
          return $state.target(
            'app.content.funnels.configured',
            angular.extend({}, transition.params(), { funnelId: funnels[0].id }),
          );
        } else if (funnelId) {
          var targetFunnel = $filter('filter')(funnels, { id: funnelId }, true)[0];

          // если среди списка воронок нету воронки с ID, полученным из параметров - берём первую воронку из списка и переходим на неё, а иначе не переходим на это состяоние вообще
          if (!targetFunnel) {
            return $state.target(
              'app.content.funnels.configured',
              angular.extend({}, transition.params(), { funnelId: funnels[0].id }),
              { reload: 'app.content.funnels.configured' },
            );
          } else {
            return false;
          }
        }
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'navigationComponent'">
  <!-- Навигация -->
  <overlay-scrollbars
    class="full-height os-host-flexbox"
    [options]="{ scrollbars: { theme: 'cq-custom-scroll-gray' } }"
  >
    <div class="flex flex-col justify-space-between full-height">
      <div class="flex flex-col align-items-center margin-between-rows-10 margin-bottom-30">
        <!-- Напоминание о прохождении Starter guide -->
        <cq-navigation-logo
          *ngIf="!isStarterGuideTooltipHasBeenShown"
          class="pt-10"
          [djangoUser]="djangoUser"
          [states]="getStatesForSection(NAVIGATION_SECTION.DASHBOARD)"
          stateTo="app.content.dashboard"
          (hideStarterGuidTooltip)="isStarterGuideTooltipHasBeenShown = !isStarterGuideTooltipHasBeenShown"
        ></cq-navigation-logo>

        <!-- Раздел "Dashboard" -->
        <cq-navigation-item
          *ngIf="isStarterGuideTooltipHasBeenShown"
          class="pt-10"
          [states]="getStatesForSection(NAVIGATION_SECTION.DASHBOARD)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.DASHBOARD)"
          icon="cqi-home-o"
          stateTo="app.content.dashboard"
        ></cq-navigation-item>

        <!-- Раздел "Посетители" без ограничений -->
        <cq-navigation-item
          *ngIf="accessToLeads.hasAccess"
          [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.LEADS)"
          [states]="getStatesForSection(NAVIGATION_SECTION.LEADS)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.LEADS)"
          icon="cqi-users"
          stateTo="app.content.users"
        ></cq-navigation-item>
        <!-- Раздел "Посетители" с ограничениями -->
        <cq-navigation-item
          *ngIf="!accessToLeads.hasAccess"
          [disabledByNotHavingAccess]="true"
          [states]="getStatesForSection(NAVIGATION_SECTION.LEADS)"
          [tooltipText]="t('visitors.tooltip')"
          icon="cqi-users"
          (clicked)="paywallService.showPaywall(currentApp, accessToLeads.denialReason)"
          [tooltipText]="t('visitors.tooltip')"
        ></cq-navigation-item>

        <!-- Триггерные сообщения -->
        <cq-navigation-item
          *ngIf="accessToAutoMessagesPage.hasAccess"
          [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.AUTO_MESSAGES)"
          [states]="getStatesForSection(NAVIGATION_SECTION.AUTO_MESSAGES)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.AUTO_MESSAGES)"
          icon="cqi-paper-plane"
          stateTo="app.content.messages.auto"
        ></cq-navigation-item>

        <!-- Триггерные цепочки -->
        <cq-navigation-item
          *ngIf="hasAccessToTriggerChainFeature"
          [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.AUTO_MESSAGES)"
          [states]="getStatesForSection(NAVIGATION_SECTION.TRIGGER_CHAIN)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.TRIGGER_CHAIN)"
          icon="cqi-series"
          stateTo="app.content.triggerChains.list"
        >
          <cq-beta-label
            [featureLabel]="FEATURE_LABELS_ONLY.TRIGGER_CHAIN"
            class="label badge-light-warning top-5-neg right-5-neg"
          ></cq-beta-label>
        </cq-navigation-item>

        <!-- Раздел "Чат-боты" c ограничениями -->
        <cq-navigation-item
          *ngIf="!accessToAutoMessagesPage.hasAccess"
          [disabledByNotHavingAccess]="true"
          [states]="getStatesForSection(NAVIGATION_SECTION.AUTO_MESSAGES)"
          icon="cqi-paper-plane"
          (clicked)="paywallService.showPaywall(currentApp, accessToAutoMessagesPage.denialReason)"
          [tooltipText]="t('triggerMessages.tooltip')"
        ></cq-navigation-item>

        <!-- Раздел "Диалоги" -->
        <cq-navigation-item
          [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.CONVERSATIONS)"
          [states]="getStatesForSection(NAVIGATION_SECTION.CONVERSATIONS)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.CONVERSATIONS)"
          icon="cqi-conversation"
          stateTo="app.content.conversations.general"
        >
          <div
            *ngIf="hasNotRead"
            class="dot-indicator not-read-indicator"
          ></div>
          <div
            *ngIf="hasNotAssigned"
            class="label label-danger"
          >
            {{ notAssignedCounter }}
          </div>
        </cq-navigation-item>

        <!-- Раздел "Чат-боты" без ограничений -->
        <cq-navigation-item
          *ngIf="accessToChatBotsPage.hasAccess"
          [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.CHAT_BOTS)"
          [states]="getStatesForSection(NAVIGATION_SECTION.CHAT_BOTS)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.CHAT_BOTS)"
          icon="cqi-robot"
          stateTo="app.content.messagesAjs.chatBot"
        ></cq-navigation-item>
        <!-- Раздел "Чат-боты" c ограничениями -->
        <cq-navigation-item
          *ngIf="!accessToChatBotsPage.hasAccess"
          [disabledByNotHavingAccess]="true"
          [states]="getStatesForSection(NAVIGATION_SECTION.CHAT_BOTS)"
          icon="cqi-robot"
          (clicked)="paywallService.showPaywall(currentApp, accessToChatBotsPage.denialReason)"
          [tooltipText]="t('chatBots.tooltip')"
        ></cq-navigation-item>

        <!-- Раздел "База знаний" без ограничений -->
        <cq-navigation-item
          *ngIf="accessToKnowledgeBase.hasAccess"
          [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.KNOWLEDGE_BASE)"
          [states]="getStatesForSection(NAVIGATION_SECTION.KNOWLEDGE_BASE)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.KNOWLEDGE_BASE)"
          icon="cqi-mortarboard"
          stateTo="app.content.knowledgeBase"
        ></cq-navigation-item>
        <!-- Раздел "База знаний" с ограничениями -->
        <cq-navigation-item
          *ngIf="!accessToKnowledgeBase.hasAccess"
          [disabledByNotHavingAccess]="true"
          [states]="getStatesForSection(NAVIGATION_SECTION.KNOWLEDGE_BASE)"
          [tooltipText]="t('knowledgeBase.tooltip')"
          icon="cqi-mortarboard"
          (clicked)="paywallService.showPaywall(currentApp, accessToKnowledgeBase.denialReason)"
          [tooltipText]="t('knowledgeBase.tooltip')"
        ></cq-navigation-item>

        <!-- Раздел "Отчёты" без ограничений -->
        <div
          *ngIf="
            (accessToEventsFunnels.hasAccess ||
              accessToUsersEvents.hasAccess ||
              accessToManualMessagesStats.hasAccess ||
              accessToAnalyticsConversations.hasAccess) &&
            !isNavigationItemDisabled(NAVIGATION_SECTION.REPORTS)
          "
          [dropdownClass]="themeClass"
          container="body"
          ngbDropdown
          placement="right-top"
          (openChange)="reportsDropdownOpenChange($event)"
        >
          <cq-navigation-item
            [disableTooltip]="isReportsDropdownOpen"
            [states]="getStatesForSection(NAVIGATION_SECTION.REPORTS)"
            [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.REPORTS)"
            [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.REPORTS)"
            icon="cqi-graphs"
            ngbDropdownToggle
          ></cq-navigation-item>
          <div
            class="navigation-dropdown-menu"
            ngbDropdownMenu
          >
            <!-- Раздел "Воронки" без ограничений -->
            <a
              *ngIf="accessToEventsFunnels.hasAccess"
              class="dropdown-item"
              uiSref="app.content.funnels"
              uiSrefActive="active"
            >
              {{ t('reports.dropdown.funnels') }}
            </a>
            <!-- Раздел "Воронки" с ограничениями -->
            <button
              *ngIf="!accessToEventsFunnels.hasAccess"
              class="dropdown-item"
              type="button"
              (click)="paywallService.showPaywall(currentApp, accessToEventsFunnels.denialReason)"
            >
              <span>
                {{ t('reports.dropdown.funnels') }}
              </span>
              <cq-plan-capability-coin class="dropdown-item-icon-right"></cq-plan-capability-coin>
            </button>

            <!-- Раздел "Отчет по событиям" без ограничений -->
            <a
              *ngIf="accessToUsersEvents.hasAccess"
              class="dropdown-item"
              uiSref="app.content.analytics"
              uiSrefActive="active"
            >
              {{ t('reports.dropdown.eventReport') }}
            </a>
            <!-- Раздел "Отчет по событиям" c ограничениями -->
            <button
              *ngIf="!accessToUsersEvents.hasAccess"
              class="dropdown-item"
              type="button"
              (click)="paywallService.showPaywall(currentApp, accessToUsersEvents.denialReason)"
            >
              <span>
                {{ t('reports.dropdown.eventReport') }}
              </span>
              <cq-plan-capability-coin class="dropdown-item-icon-right"></cq-plan-capability-coin>
            </button>

            <!-- Раздел "Отчет по ручным рассылкам" без ограничений -->
            <a
              *ngIf="accessToManualMessagesStats.hasAccess"
              class="dropdown-item"
              uiSref="app.content.messagesAjs.manual"
              uiSrefActive="active"
            >
              {{ t('reports.dropdown.manualReport') }}
            </a>
            <!-- Раздел "Отчет по ручным рассылкам" c ограничениями -->
            <button
              *ngIf="!accessToManualMessagesStats.hasAccess"
              class="dropdown-item"
              type="button"
              (click)="paywallService.showPaywall(currentApp, accessToManualMessagesStats.denialReason)"
            >
              <span>
                {{ t('reports.dropdown.manualReport') }}
              </span>
              <cq-plan-capability-coin class="dropdown-item-icon-right"></cq-plan-capability-coin>
            </button>

            <!-- Раздел "Аналитика диалогов" без ограничений -->
            <a
              *ngIf="accessToAnalyticsConversations.hasAccess"
              class="dropdown-item"
              [cqStateActive]="['app.content.conversations.statistics', 'app.content.conversations.statisticsPaywall']"
              uiSref="app.content.conversations.statistics"
              [cqStateActive]="['app.content.conversations.statistics']"
            >
              {{ t('reports.dropdown.perfomanceReport') }}
            </a>
            <!-- Раздел "Аналитика диалогов" c ограничениями -->
            <button
              *ngIf="!accessToAnalyticsConversations.hasAccess"
              class="dropdown-item"
              type="button"
              (click)="paywallService.showPaywall(currentApp, accessToAnalyticsConversations.denialReason)"
            >
              <span>
                {{ t('reports.dropdown.perfomanceReport') }}
              </span>
              <cq-plan-capability-coin class="dropdown-item-icon-right"></cq-plan-capability-coin>
            </button>
          </div>
        </div>
        <!-- Раздел "Отчёты" с ограничениями -->
        <cq-navigation-item
          *ngIf="
            !accessToReports.hasAccess &&
            !accessToEventsFunnels.hasAccess &&
            !accessToUsersEvents.hasAccess &&
            !accessToManualMessagesStats.hasAccess &&
            !accessToAnalyticsConversations.hasAccess &&
            !isNavigationItemDisabled(NAVIGATION_SECTION.REPORTS)
          "
          [disabledByNotHavingAccess]="true"
          [states]="getStatesForSection(NAVIGATION_SECTION.REPORTS)"
          [tooltipText]="t('reports.tooltip')"
          icon="cqi-graphs"
          (clicked)="paywallService.showPaywall(currentApp, accessToReports.denialReason)"
          [tooltipText]="t('reports.tooltip')"
        ></cq-navigation-item>
        <!-- Раздел "Отчёты" - заблокированный -->
        <cq-navigation-item
          *ngIf="isNavigationItemDisabled(NAVIGATION_SECTION.REPORTS)"
          [disableTooltip]="isReportsDropdownOpen"
          [disable]="true"
          [states]="getStatesForSection(NAVIGATION_SECTION.REPORTS)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.REPORTS)"
          icon="cqi-graphs"
        ></cq-navigation-item>
      </div>

      <div class="flex flex-col align-items-center margin-top-auto margin-bottom-15 margin-between-rows-10">
        <!-- Раздел "Интеграции" без ограничений -->
        <cq-navigation-item
          *ngIf="accessToIntegrations.hasAccess"
          [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.INTEGRATIONS)"
          [states]="getStatesForSection(NAVIGATION_SECTION.INTEGRATIONS)"
          [tooltipText]="t('integrations.tooltip')"
          icon="cqi-puzzle"
          stateTo="app.content.integrations"
        >
          <div
            *ngIf="isShowNewFeatureLabelOnIntegrations()"
            class="label label-success"
          >
            {{ 'general.new' | transloco }}
          </div>
        </cq-navigation-item>
        <!-- Раздел "Интеграции" с ограниченями -->
        <cq-navigation-item
          *ngIf="!accessToIntegrations.hasAccess"
          [disabledByNotHavingAccess]="true"
          [states]="getStatesForSection(NAVIGATION_SECTION.INTEGRATIONS)"
          icon="cqi-puzzle"
          (clicked)="paywallService.showPaywall(currentApp, accessToIntegrations.denialReason)"
          [tooltipText]="t('integrations.tooltip')"
        ></cq-navigation-item>

        <!-- Раздел "Настройки" без ограничений -->
        <div
          *ngIf="!isNavigationItemDisabled(NAVIGATION_SECTION.SETTINGS)"
          [dropdownClass]="themeClass"
          container="body"
          ngbDropdown
          placement="right-bottom"
          (openChange)="settingsDropdownOpenChange($event)"
        >
          <cq-navigation-item
            [states]="getStatesForSection(NAVIGATION_SECTION.SETTINGS)"
            [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.SETTINGS)"
            icon="cqi-cog"
            [disableTooltip]="isSettingsDropdownOpen"
            [states]="getStatesForSection(NAVIGATION_SECTION.SETTINGS)"
            [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.SETTINGS)"
            icon="cqi-cog"
            ngbDropdownToggle
          ></cq-navigation-item>
          <div
            class="navigation-dropdown-menu"
            ngbDropdownMenu
          >
            <!-- Раздел "Настройки" -->
            <a
              class="dropdown-item"
              uiSref="app.content.settings.general"
              uiSrefActiveEq="active"
            >
              {{ t('settings.dropdown.settings') }}
            </a>

            <!-- Раздел "Мастер сбора данных" без ограничений -->
            <a
              *ngIf="accessToTrackMasterJsCode.hasAccess"
              class="dropdown-item"
              [cqStateActive]="['app.content.trackmaster', 'app.content.dataCollection']"
              uiSref="app.content.dataCollection"
            >
              {{ t('settings.dropdown.dataTracking') }}
            </a>
            <!-- Раздел "Мастер сбора данных" с ограничениями -->
            <button
              *ngIf="!accessToTrackMasterJsCode.hasAccess"
              class="dropdown-item"
              type="button"
              (click)="paywallService.showPaywall(currentApp, accessToTrackMasterJsCode.denialReason)"
            >
              <span>
                {{ t('settings.dropdown.dataTracking') }}
              </span>
              <cq-plan-capability-coin class="dropdown-item-icon-right"></cq-plan-capability-coin>
            </button>

            <!-- Раздел "Члены команды" -->
            <a
              class="dropdown-item"
              uiSref="app.content.teamMembers"
              uiSrefActive="active"
            >
              {{ t('settings.dropdown.teamMembers') }}
            </a>

            <!-- Раздел "Настройки диалогов" -->
            <a
              class="dropdown-item"
              uiSref="app.content.conversationsSettings"
              uiSrefActive="active"
            >
              {{ t('settings.dropdown.conversationsSettings') }}
              <cq-new-label
                class="ml-5"
                [featureLabel]="FEATURE_LABELS_ONLY.NEW_PAGE_INBOX_SETTINGS"
              ></cq-new-label>
            </a>

            <!-- Раздел "Разработчикам" без ограничений -->
            <a
              *ngIf="accessToDevelopers.hasAccess"
              class="dropdown-item"
              uiSref="app.content.settings.developers"
              uiSrefActive="active"
            >
              {{ t('settings.dropdown.developers') }}
            </a>
            <!-- Раздел "Разработчикам" с ограничениями -->
            <button
              *ngIf="
                !accessToDevelopers.hasAccess &&
                accessToDevelopers.denialReason.paidPlanVersion !== PAID_PLAN_VERSION.LTD
              "
              class="dropdown-item"
              type="button"
              (click)="paywallService.showPaywall(currentApp, accessToDevelopers.denialReason)"
            >
              <span>
                {{ t('settings.dropdown.developers') }}
              </span>
              <cq-plan-capability-coin class="dropdown-item-icon-right"></cq-plan-capability-coin>
            </button>
          </div>
        </div>
        <!-- Раздел "Настройки" заблокированный -->
        <cq-navigation-item
          *ngIf="isNavigationItemDisabled(NAVIGATION_SECTION.SETTINGS)"
          [disableTooltip]="isSettingsDropdownOpen"
          [disable]="true"
          [states]="getStatesForSection(NAVIGATION_SECTION.SETTINGS)"
          [tooltipText]="getNavigationItemTooltipText(NAVIGATION_SECTION.SETTINGS)"
          icon="cqi-cog"
        ></cq-navigation-item>

        <!-- Раздел "Оплата" -->
        <cq-navigation-item
          [disable]="isNavigationItemDisabled(NAVIGATION_SECTION.SUBSCRIPTION)"
          [states]="getStatesForSection(NAVIGATION_SECTION.SUBSCRIPTION)"
          [tooltipText]="t('payment.tooltip')"
          icon="cqi-card"
          [stateTo]="subscriptionState"
        >
          <ng-container *cqHideInRu>
            <div
              *ngIf="hasUnpaidInvoices"
              class="dot-indicator unpaid-indicator"
            ></div>
          </ng-container>
        </cq-navigation-item>
      </div>
    </div>
  </overlay-scrollbars>
</ng-container>

import { InjectionToken } from '@angular/core';

/**
 * Тип данных, передаваемых в модалку редактирования свойства пользователя
 */
export type EditUserPropertyModalData = {
  /** Название редактируемого свойства */
  propertyName: string;
  /** Значение редактируемого свойства */
  propertyValue: string | undefined;
  /** ID пользователя в нашей системе */
  userId: string;
};

/**
 * Токен для передачи данных через DI в модалку редактирования свойства пользователя
 */
export const EDIT_USER_PROPERTY_MODAL_DATA_TOKEN = new InjectionToken<EditUserPropertyModalData>(
  'EditUserPropertyModal data provider',
);

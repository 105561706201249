import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CaseStyleHelper, DestroyService } from 'app/services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { App, AppModel } from '@http/app/app.model';
import { ChannelModel } from '@http/channel/channel.model';
import {
  ACTIONS_GROUPS,
  CHAT_BOT_ACTIONS_LIMIT_COUNT,
  CHAT_BOT_ACTIONS_TYPES,
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP,
  CHAT_BOT_TARGET_ACTION,
} from '@http/chat-bot/chat-bot.constants';
import { ChatBotModel } from '@http/chat-bot/chat-bot.model';
import { DefaultActionHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-action.helper';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { BotBlocksOverlayService } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-blocks-overlay.service';
import { CreateBranchCb } from '@panel/app/pages/chat-bot/content/content.component';
import { ActionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { BaseActionABS } from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { BlockType, Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Properties } from '@http/property/property.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

import { BotScenariosHelper } from '../services/bot-scenarios.helper';

function isChatBotKeyAction(action: CHAT_BOT_ACTIONS_TYPES): action is CHAT_BOT_TARGET_ACTION {
  return CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[ACTIONS_GROUPS.TARGET_SHOW].includes(action);
}
export type BranchContainerCallbacks = {
  // Показывать или нет кнопку копиравания действия
  isShowCopyButton: (action: BotAction) => boolean; // Показывать или нет кнопку перемещения вниз
  isShowDownButton: (action: BotAction) => boolean; // Показывать или нет кнопку перемещения вверх
  isShowUpButton: (action: BotAction) => boolean; // Добавление копии действия
  isLastContentAction: (action: BotAction) => boolean; // Последнее действие в блоке контент
  createCopy: (action: BotAction) => void; // Удаление кнопки действия
  removeAction: (action: BotAction) => void; // Переместить действие выше
  moveActionUp: (action: BotAction) => void; // Переместить действие ниже
  moveActionDown: (action: BotAction) => void; // Выбор ветки для открытия
  openBranch: (linkId: Branch | string) => void; // Открыть ветку по linkId
  createNewBranch: (type: BlockType, action: BotAction) => void; // Добавить новую ветку
  createNewButton: () => void; // Добавить кнопку для дейстия BUTTONS_PROPERTY
};
export type BaseActionCallbacks = Pick<BranchContainerCallbacks, 'openBranch' | 'createNewBranch' | 'createNewButton'>;
export type BranchConditionCallbacks = BaseActionCallbacks;

@Component({
  selector:
    'cq-branch-editor[branch][chatBot][currentApp][nextBranchOptions][createBranchFn][properties][allowUserReplies]',
  templateUrl: './branch-editor.component.html',
  styleUrls: ['./branch-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class BotBranchEditorComponent<T extends CHAT_BOT_TYPE> implements OnDestroy {
  _branch!: Branch;
  @Input() set branch(value: Branch) {
    this._branch = value;
    this.showLoader = true;
    this.renameBranchHandler();
    this.renderActions();
    this.noticeOfTargetAction = this.getNoticeForTargetAction(this.branch.targetAction.value);
    this.buttonPropertyCheckboxControl.setValue(
      !!this.branch.actions.find((a) => a.type === CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY),
    );
  }

  get branch(): Branch {
    return this._branch;
  }

  private _chatBot!: ChatBot<T>;
  @Input()
  set chatBot(chatBot: ChatBot<T>) {
    this._chatBot = chatBot;
    this.stateService.chatBot$.next(chatBot);
  }
  get chatBot() {
    return this._chatBot;
  }
  @Input() currentApp!: App;
  @Input() fullscreen: boolean = false;
  @Input() nextBranchOptions!: Branch[];
  @Input() createBranchFn!: CreateBranchCb;
  @Input() properties!: Properties;
  @Input() allowUserReplies!: boolean;

  @Output() branchCreate: EventEmitter<BotAction> = new EventEmitter(); // Callback на создание векти
  @Output() branchRemove: EventEmitter<Branch> = new EventEmitter(); // Callback на удаление векти
  @Output() branchRename: EventEmitter<Branch> = new EventEmitter<Branch>();

  @Output() toBranch: EventEmitter<Branch | string> = new EventEmitter(); // Callback на переход к ветке

  @ViewChildren('popover') popover!: QueryList<NgbPopover>;

  actionsToRender: BotAction[] = [];
  actionGroups: {
    [k in ACTIONS_GROUPS]: BehaviorSubject<CHAT_BOT_ACTIONS_TYPES[]>;
  } = {
    [ACTIONS_GROUPS.CONTENT]: new BehaviorSubject<CHAT_BOT_ACTIONS_TYPES[]>([]),
    [ACTIONS_GROUPS.TARGET_SELECT]: new BehaviorSubject<CHAT_BOT_ACTIONS_TYPES[]>([]),
    [ACTIONS_GROUPS.TARGET_SHOW]: new BehaviorSubject<CHAT_BOT_ACTIONS_TYPES[]>([]),
  };

  CHAT_BOT_ACTIONS_TYPES = CHAT_BOT_ACTIONS_TYPES;
  actionsCollapse: { [key: string]: boolean } = {};
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP = CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP;
  buttonPropertyCheckboxControl: GenericFormControl<boolean> = new GenericFormControl<boolean>(false);
  changeBranch$ = new Subject<void>();
  isBranchNameInputFocused = false;
  noticeOfTargetAction: string = this.getNoticeForTargetAction(CHAT_BOT_ACTIONS_TYPES.CLOSE);

  /** Название проекта */
  projectName = environment.projectName;

  showLoader: boolean = true;

  constructor(
    public readonly appModel: AppModel,
    private readonly blocksOverlayService: BotBlocksOverlayService,
    private readonly carrotquestHelper: CarrotquestHelper,
    public readonly caseStyleHelper: CaseStyleHelper,
    public readonly chatBotModel: ChatBotModel,
    private readonly channelModel: ChannelModel,
    public readonly translocoService: TranslocoService,
    private readonly actionFactory: ActionFactory,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    private readonly ngZone: NgZone,
    private readonly scenariosHelper: BotScenariosHelper,
    public readonly stateService: StateService,
  ) {}

  ngOnDestroy() {
    this.popover.first?.close();
  }

  /**
   * обработчик события переименования ветки
   */
  renameBranchHandler(): void {
    this.changeBranch$.next();
    this.branch.name.valueChanges
      .pipe(takeUntil(this.changeBranch$), takeUntil(this.destroy$), debounceTime(500))
      .subscribe((v) => {
        this.branchRename.emit(this.branch);
      });
  }

  /**
   * Добавить действие бота
   *
   * @param action - Действия ветки бота
   * @param targetBlock - блок, в который добавлять
   */
  addBotAction(action: BotAction, targetBlock: Branch = this.branch): void {
    this.actionsCollapse[action.linkId] = true;
    const actions = targetBlock.actions;

    // NOTE Все действия типа BUTTON, OPERATOR и CHANNEL должны добавляться в конец.
    //  Остальные перед ними
    if (
      CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[ACTIONS_GROUPS.CONTENT].includes(action.type) ||
      action.type === CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY
    ) {
      let newActionIndex = 0; // Индекс места куда добавлять новый элемент

      newActionIndex -= actions.filter((actionToFilter) => isChatBotKeyAction(actionToFilter.type)).length;

      // Тут так надо делать, потому что contentHeight в branch.ts зависит от последовательности действий
      // костыль короче. Если не сделать, то в новых блоках next action на первом месте и contentHeight отдает высоту больше, чем надо
      if (targetBlock.blockType === 'action') {
        newActionIndex = targetBlock.actions.length - 1;
      }

      targetBlock.addAction(action, newActionIndex);
    } else {
      targetBlock.addAction(action);
    }

    // NOTE Без $timeout действия появляются резко. Лучше бы заменить на ангуляровскую анимацю появление этого добра
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.actionsCollapse[action.linkId] = false;
        this.ngZone.run(() => {
          this.changeDetectorRef.markForCheck();
        });
      }, 0);
    });
  }

  /**
   * Базовый набор операций, который можно произвести с людым действиями
   * @private
   */
  private baseActionCallbacks: BaseActionCallbacks = {
    openBranch: (linkId: Branch | string) => {
      this.toBranch.emit(linkId);
    },
    createNewBranch: (blockType: BlockType, action: BotAction) => {
      this.onCreateNewBranch(blockType, action);
    },
    createNewButton: () => {
      this.onCreateNewButton();
    },
  };

  /**
   * Проверка на количество лимитов для действий
   *
   * @param actionType - Тип действия ветки бота
   */
  checkActionsLimits(actionType: CHAT_BOT_ACTIONS_TYPES): boolean {
    const filteredActions = this.branch.actions.filter((action) => action.type === actionType);
    return (
      CHAT_BOT_ACTIONS_LIMIT_COUNT[actionType] !== -1 &&
      filteredActions.length >= CHAT_BOT_ACTIONS_LIMIT_COUNT[actionType]
    );
  }

  /**
   * Создает и добавляет ветку бота
   *
   * @param actionType - Тип действия ветки бота
   * @param targetBlock - блок, в который добавлять
   */
  createBotAction(actionType: CHAT_BOT_ACTIONS_TYPES, targetBlock: Branch): void {
    const modelAction = DefaultActionHelper.getDefaultAction(actionType);
    modelAction.nextBranch = null;
    modelAction.active = actionType === CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY;
    const action = this.actionFactory.create(modelAction, targetBlock);

    this.addBotAction(action, targetBlock);

    this.trackAddAction(actionType);
  }

  /**
   * Проверяет надо ли дизеблить кнопку
   *
   * @param actionType - Тип действия ветки бота
   */
  isButtonDisabled(actionType: CHAT_BOT_ACTIONS_TYPES): boolean {
    return this.checkActionsLimits(actionType);
  }

  /**
   * Операции с действиями для блока с условиями
   * @param actionIndex - Индекс действия
   * @param group - Тип группы действия
   */
  getCallbacks(actionIndex: number, group: ACTIONS_GROUPS): BranchContainerCallbacks {
    const actions = this.getRenderActionsByGroup(group);
    return {
      isShowCopyButton: (action: BotAction): boolean => {
        if (CHAT_BOT_ACTIONS_LIMIT_COUNT[action.type] === -1) {
          return true;
        }
        const sameActionsCount = actions.filter((item) => item.type === action.type).length;
        return sameActionsCount < CHAT_BOT_ACTIONS_LIMIT_COUNT[action.type];
      },
      isShowDownButton: (action: BotAction): boolean => {
        const nextAction = actions[actionIndex + 1];
        // Перемещать вниз можно только, если это не последнее действие
        // И следующее действие той же группы
        return actionIndex !== actions.length - 1;
      },
      isShowUpButton: (action: BotAction): boolean => {
        const prevAction = actions[actionIndex - 1];
        // Перемещать вверх можно только, если это не первое действие
        // И предыдущее действие той же группы
        if (prevAction && prevAction.type === CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY) {
          return false;
        }
        return actionIndex !== 0;
      },
      isLastContentAction: (action: BotAction): boolean => {
        if (this.branch.blockType !== 'branch') {
          return false;
        }

        const nonFullyDeletableActions = [CHAT_BOT_ACTIONS_TYPES.TEXT, CHAT_BOT_ACTIONS_TYPES.FILE];

        if (!nonFullyDeletableActions.includes(action.type)) {
          return false;
        }
        return this.branch.actions.filter(({ type }) => nonFullyDeletableActions.includes(type)).length === 1;
      },
      createCopy: (action: BotAction): void => {
        const actionCopy = action.makeCopy();
        return this.addBotAction(actionCopy);
      },
      removeAction: (action: BotAction): void => {
        this.removeAction(action);
      },
      moveActionUp: (action: BotAction) => {
        const realActionIndex = this.branch.actions.findIndex((el) => el.linkId === action.linkId);
        action.order -= 1;
        this.branch.actions[realActionIndex - 1].order += 1;
        this.branch.moveAction(realActionIndex, realActionIndex - 1);
      },
      moveActionDown: (action: BotAction) => {
        const realActionIndex = this.branch.actions.findIndex((el) => el.linkId === action.linkId);
        action.order += 1;
        this.branch.actions[realActionIndex + 1].order -= 1;
        this.branch.moveAction(realActionIndex, realActionIndex + 1);
      },
      ...this.baseActionCallbacks,
    };
  }

  /**
   * Операции с действиями для блока с условиями
   */
  getConditionsBLockCallbacks(): BranchConditionCallbacks {
    return this.baseActionCallbacks;
  }

  removeAction(action: BotAction): void {
    this.branch.deleteAction(action);
    if (action.type === CHAT_BOT_ACTIONS_TYPES.BUTTON) {
      //После удаления кнопки считаем что пользователь повзаисодейстровал с контролом
      // и выставляем actionsCount в touched
      this.branch.form.get('buttonsWereTouched').setValue(true);
    }
  }

  /**
   * Пересчет индекса для действия "ответ кнопками", но можно расширить на другие действия
   * пересчет необходим, когда есть первое действие БЕЗ header`а и возможностью удалить
   * @param actionType
   * @param index
   */
  getActionHeaderIndex(actionType: CHAT_BOT_ACTIONS_TYPES, index: number): number {
    if (actionType === CHAT_BOT_ACTIONS_TYPES.BUTTON && this.buttonPropertyCheckboxControl.value) {
      return index;
    }
    return ++index;
  }

  /**
   * Вызов по onBlur на поле с именем ветки бота
   */
  onBlurBranchNameInput(): void {
    this.isBranchNameInputFocused = false;
  }

  /**
   * Колбэк на создание новой ветки
   *
   * @param blockType
   * @param sourceAction — Действие ветки бота из которого создана ветка
   */
  onCreateNewBranch(blockType: BlockType, sourceAction: BotAction) {
    let newName: string = '';
    switch (sourceAction.type) {
      case CHAT_BOT_ACTIONS_TYPES.BUTTON:
        newName = sourceAction.form.value.body;
        break;
      case CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD:
        newName = this.translocoService.translate('models.chatBot.defaultSuccessBranchName');
        break;
    }

    let branch: Branch;

    if (
      blockType === 'branch' &&
      sourceAction instanceof BaseActionABS &&
      sourceAction.currentBranch.isInterruptScenario
    ) {
      branch = this.createBranchFn(blockType, this.branch, newName, 'interrupt');
    } else {
      branch = this.createBranchFn(blockType, this.branch, newName);
    }

    sourceAction.form.controls.nextBranchLinkId.setValue(branch.linkId);
  }

  onCreateNewButton(): void {
    this.createBotAction(CHAT_BOT_ACTIONS_TYPES.BUTTON, this.branch);
  }

  onBranchDelete(): void {
    this.blocksOverlayService.deleteOverlayAbleElement(this.branch);
    this.branch.destroy();
    if (this.branch.isInterruptScenario) {
      this.scenariosHelper.removeChainFromInterruptScenario(this.branch);
    } else if (this.branch.isDefaultScenario) {
      this.scenariosHelper.removeChainFromDefaultScenario(this.branch);
    }
  }

  renderActions() {
    const INTERVAL_IN_MS = 50;

    let itemsAdded = 0;
    let actions = this.branch.actions;

    this.ngZone.runOutsideAngular(() => {
      // разбиваем рендер экшенов ветки на этапы чтобы избавить браузер от фризов
      const interval = setInterval(() => {
        if (itemsAdded === actions.length) {
          clearInterval(interval);
          this.actionsToRender = actions as BotAction[];

          this.showLoader = false;

          // хотел избавиться от функций в шаблонах через rxjs, TODO не доделал
          Object.values(ACTIONS_GROUPS).forEach((key: ACTIONS_GROUPS) => {
            if (key === ACTIONS_GROUPS.TARGET_SHOW) {
              this.actionGroups[key].next(this.getRenderActionsByGroup(key).map((el) => el.type));
              return;
            }

            //NOTE для ветки прерывания не надо отображать некоторые целевые действия
            if (this.isPartOfInterruptScenario && key === ACTIONS_GROUPS.TARGET_SELECT) {
              const actionsForInterruptedBranch = this.CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[key].filter((type: any) => {
                return ![
                  CHAT_BOT_ACTIONS_TYPES.BUTTON,
                  CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY,
                  CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD,
                  CHAT_BOT_ACTIONS_TYPES.CLOSE,
                ].includes(type);
              });
              this.actionGroups[key].next(actionsForInterruptedBranch);
            } else {
              this.actionGroups[key].next(this.CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[key]);
            }
          });

          this.ngZone.run(() => {
            this.changeDetectorRef.markForCheck();
          });
        } else {
          this.actionsToRender = this.branch.actions.slice(0, itemsAdded);
        }
        itemsAdded++;
      }, INTERVAL_IN_MS);
    });
  }

  /**
   * Является ли текущий блок частью сценария прерывания
   */
  public get isPartOfInterruptScenario() {
    return this.branch.isInterruptScenario;
  }

  /**
   * Получение действий по группе
   * @param group
   */
  public getRenderActionsByGroup(group: ACTIONS_GROUPS): BotAction[] {
    return this.actionsToRender.filter((action) => {
      return this.CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[group].includes(action.type);
    });
  }

  /**
   * Получение текста для отображения под селектом выбора целевого действия
   *
   * @param action
   * hack еще и string потому что bot-branch.form.ts:14
   */
  getNoticeForTargetAction(action: CHAT_BOT_ACTIONS_TYPES | string): string {
    let baseTranslatePath: string = 'blockBranchEditorComponent.targetAction.description.';

    let result = baseTranslatePath + action;
    if (action === CHAT_BOT_ACTIONS_TYPES.BUTTON) {
      return this.translocoService.translate(result, {
        count: CHAT_BOT_ACTIONS_LIMIT_COUNT[action],
        projectName: this.projectName,
      });
    }

    return this.translocoService.translate(result);
  }

  /**
   * Добавление дополнительных действий в блок в заивисимости от целевого действия
   * @param actionType
   */
  changeTargetAction(actionType: CHAT_BOT_ACTIONS_TYPES) {
    this.branch.actions
      .filter((action) => CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[ACTIONS_GROUPS.TARGET_SHOW].includes(action.type))
      .forEach((action) => this.removeAction(action));

    this.noticeOfTargetAction = this.getNoticeForTargetAction(actionType);

    this.createBotAction(actionType, this.branch);
  }

  /**
   * Изменение активности чекбокса
   * @param event
   */
  changeCheckboxValue(event: Event) {
    // @ts-ignore
    if (event.target && event.target['checked']) {
      this.createBotAction(CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY, this.branch);
    } else {
      const buttonsPropertyAction = this.branch.actions.find(
        (action) => CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY === action.type,
      );
      this.removeAction(buttonsPropertyAction!);
      this.ngZone.run(() => {
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  /**
   * Трек добавления экшена
   *
   * @param actionType
   */
  trackAddAction(actionType: CHAT_BOT_ACTIONS_TYPES): void {
    if (actionType === CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION) {
      this.carrotquestHelper.track('Чат-бот - добавил Email-оповещения в блок', {
        'Название бота': this.chatBot.name,
      });
    }
  }

  get changedControlToButton$(): Observable<boolean> {
    return this.branch.targetAction.valueChanges.pipe(
      startWith(this.branch.targetAction.value),
      distinctUntilChanged(),
      map((type: CHAT_BOT_ACTIONS_TYPES) => type === CHAT_BOT_ACTIONS_TYPES.BUTTON),
    );
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

/** Компонент для работы с информацией о тарифе */
@Component({
  selector: 'cq-plan-info',
  templateUrl: './plan-info.component.html',
  styleUrls: ['./plan-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanInfoComponent {
  constructor(public readonly subscriptionStore: SubscriptionStore) {}
}

import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { from, merge } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { BILLING_ADD_ONS, V3_PLANS_LIMITS } from '@panel/app/services/billing-info/billing-info.constants';
import {
  ApiFeatureCounterActivitiesResponse,
  BillingInfoModel,
} from '@panel/app/services/billing-info/billing-info.model';
import { LS_AUTO_MESSAGES_DOWNGRADE, LS_LEAD_BOTS_DOWNGRADE } from '@panel/app/shared/constants/localstorage.keys';

import { BooleanActivitiesDowngrade, CounterActivitiesDowngrade } from './downgrade-abilities/downgrade-abilities.type';
import { PLAN_CHANGE_CONFIRM_MODAL_TOKEN, PlanChangeConfirmModalData } from './plan-change-confirm-modal.token';

@Injectable()
export class DowngradeAbilitiesService {
  selectedV3PlanLimits: {
    ACTIVE_TRIGGER_MESSAGES: number;
    ACTIVE_LEAD_BOTS: number;
    CONVERSATION_CHANNELS: number;
    KNOWLEDGE_BASE_ARTICLES: number;
    ADDONS: BILLING_ADD_ONS[];
  } = Object.assign({}, V3_PLANS_LIMITS[this.data.planId]);

  private activitiesDowngrade$ = from(
    this.billingInfoModel.getBillingFeatureActivitiesCounter(this.appService.currentAppId),
  ).pipe(shareReplay(1));

  counterActivities$ = this.activitiesDowngrade$.pipe(
    map((featureCounterActivities: ApiFeatureCounterActivitiesResponse) => {
      return this.getCounterFeatureActivities(featureCounterActivities);
    }),
  );

  booleanActivities$ = this.activitiesDowngrade$.pipe(
    map((featureBooleanActivities: ApiFeatureCounterActivitiesResponse) => {
      return this.getBooleanFeatureActivities(featureBooleanActivities);
    }),
  );

  hasDowngradeActivities$ = merge(this.counterActivities$, this.booleanActivities$).pipe(
    filter((featureActivitiesList) => {
      return featureActivitiesList.length > 0;
    }),
  );

  constructor(
    private readonly billingInfoModel: BillingInfoModel,
    //Из-за использования inject'а констекста модалки, сервис должен провайдиться в компонент модалки
    @Inject(PLAN_CHANGE_CONFIRM_MODAL_TOKEN)
    public readonly data: PlanChangeConfirmModalData,
    @Inject(LOCAL_STORAGE)
    private readonly localStorage: Storage,
    private readonly appService: AppService,
  ) {
    this.expandLimitsViaAddons();
  }

  /**
   * Получение логических активностей вкл/выкл
   */
  private getBooleanFeatureActivities(featureCounterActivities: ApiFeatureCounterActivitiesResponse) {
    let featureBooleanActivitiesDowngrade: BooleanActivitiesDowngrade[] = [];

    const currentAddons = [
      {
        nameTranslated: 'welcomeBot',
        addonName: BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING,
        addonStatus: featureCounterActivities.routingBotIsActive,
      },
      {
        nameTranslated: 'autoSetChannels',
        addonName: BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        addonStatus: featureCounterActivities.autoSetChannelsExist,
      },
      {
        nameTranslated: 'messengerVote',
        addonName: BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        addonStatus: featureCounterActivities.messengerVoteIsOn,
      },
    ];

    const currentAddonsList = currentAddons.filter((currentAddon) => currentAddon.addonStatus);

    const selectedAddonsList = this.data.addons.map((addon) => addon.addon);

    const selectedAddons = [...this.selectedV3PlanLimits.ADDONS, ...selectedAddonsList];

    featureBooleanActivitiesDowngrade = currentAddonsList.filter(
      ({ addonName }) => !selectedAddons.includes(addonName),
    );

    return featureBooleanActivitiesDowngrade;
  }

  /**
   * Получение количественных активностей
   */
  private getCounterFeatureActivities(featureCounterActivities: ApiFeatureCounterActivitiesResponse) {
    let featureCounterActivitiesDowngrade: CounterActivitiesDowngrade[] = [];

    const { activeAutoMessagesTotal, activeLeadBotsTotal, knowledgeBaseArticlesTotal, manualChannelsTotal } =
      featureCounterActivities;
    const {
      ACTIVE_TRIGGER_MESSAGES: availableAutoMessagesTotal,
      ACTIVE_LEAD_BOTS: availableLeadBotsTotal,
      KNOWLEDGE_BASE_ARTICLES: availableKnowledgeBaseArticlesTotal,
      CONVERSATION_CHANNELS: availableManualChannelsTotal,
    } = this.selectedV3PlanLimits;

    if (activeAutoMessagesTotal > availableAutoMessagesTotal) {
      featureCounterActivitiesDowngrade.push({
        nameTranslated: 'autoMessages',
        activeAndAvailable: [activeAutoMessagesTotal, availableAutoMessagesTotal],
      });
    }
    if (activeLeadBotsTotal > availableLeadBotsTotal) {
      featureCounterActivitiesDowngrade.push({
        nameTranslated: 'leadBots',
        activeAndAvailable: [activeLeadBotsTotal, availableLeadBotsTotal],
      });
    }
    if (knowledgeBaseArticlesTotal > availableKnowledgeBaseArticlesTotal) {
      featureCounterActivitiesDowngrade.push({
        nameTranslated: 'knowledgeBaseArticles',
        activeAndAvailable: [knowledgeBaseArticlesTotal, availableKnowledgeBaseArticlesTotal],
      });
    }
    if (manualChannelsTotal > availableManualChannelsTotal) {
      featureCounterActivitiesDowngrade.push({
        nameTranslated: 'manualChannels',
        activeAndAvailable: [manualChannelsTotal, availableManualChannelsTotal],
      });
    }

    return featureCounterActivitiesDowngrade;
  }

  /**
   * Проверяем произошел ли даунгрейд по лидботам или автосообщениям
   */
  checkDowngradeAutoMessageOrLeadBot(featureCounterActivitiesDowngrade: CounterActivitiesDowngrade[]) {
    featureCounterActivitiesDowngrade.forEach((featureCounterActivityDowngrade) => {
      if (featureCounterActivityDowngrade.nameTranslated === 'autoMessages') {
        this.localStorage.setItem(LS_AUTO_MESSAGES_DOWNGRADE, 'true');
      }
      if (featureCounterActivityDowngrade.nameTranslated === 'leadBots') {
        this.localStorage.setItem(LS_LEAD_BOTS_DOWNGRADE, 'true');
      }
    });
  }

  /**
   * Расширение лимитов при помощи аддонов, только для числовых ограничений
   */
  private expandLimitsViaAddons() {
    const addons = this.data.addons.map((addon) => addon.addon);

    if (addons.includes(BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING)) {
      this.selectedV3PlanLimits.ACTIVE_LEAD_BOTS = 40;
    }

    if (addons.includes(BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING)) {
      this.selectedV3PlanLimits.ACTIVE_TRIGGER_MESSAGES = 100;
    }

    if (addons.includes(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING)) {
      this.selectedV3PlanLimits.KNOWLEDGE_BASE_ARTICLES = Infinity;
    }
  }
}

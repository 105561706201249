(function () {
  'use strict';

  require('./ab-test-statistics/ab-test-statistics.module');
  require('./auto-fill-sync/auto-fill-sync.module');
  require('./autofocus/autofocus.module');
  require('./chart/chart.module');
  require('./checkbox/checkbox.module');
  require('./color-picker/color-picker.module');
  require('./copy-text/copy-text.module');
  require('./custom-scroll/custom-scroll.module');
  require('./popover-and-tooltip-custom-trigger/custom-trigger.module');
  require('./disable-validation/disable-validation.module');
  require('./domain-part/domain-part.module');
  require('./dynamic-iframe/dynamic-iframe.module');
  require('./dynamic-validation/dynamic-validation.module');
  require('./equal/equal.module');
  require('./file/file.module');
  require('./has-item-validation/has-item-validation.module');
  require('./hex-color/hex-color.module');
  require('./hide-in-ru/hide-in-ru.module');
  require('./hide-in-us/hide-in-us.module');
  require('./html-compile/html-compile.module');
  require('./html-max-size/html-max-size.module');
  require('./list-filter/list-filter.module');
  require('./number/number.module');
  require('./number/additions/additions.module');
  require('./overflow/overflow.module');
  require('./parse-props/parse-props.module');
  require('./prevent-submit-on-enter/prevent-submit-on-enter.module');
  require('./radio/radio.module');
  require('./split/split.module');
  require('./statistics-value/statistics-value.module');
  require('./sticky-scroll/sticky-scroll.module');
  require('./switch/switch.module');
  require('./time/time.module');
  require('./unsubscribe/unsubscribe.module');
  require('./uri/uri.module');
  require('./url/url.module');
  require('./url-with-params/url-with-params.module');
  require('./video-block-url/video-block-url.module');
  require('./wizard/wizard.module');

  angular.module('myApp.directives', [
    'myApp.directives.abTestStatistics',
    'myApp.directives.autofocus',
    'myApp.directives.chart',
    'myApp.directives.checkbox',
    'myApp.directives.colorPicker',
    'myApp.directives.copyText',
    'myApp.directives.customScroll',
    'myApp.directives.customTrigger',
    'myApp.directives.disableValidation',
    'myApp.directives.domainPart',
    'myApp.directives.dynamicIframe',
    'myApp.directives.dynamicValidation',
    'myApp.directives.equal',
    'myApp.directives.file',
    'myApp.directives.hasItemValidation',
    'myApp.directives.hexColorValidation',
    'myApp.directives.hideInRu',
    'myApp.directives.hideInUs',
    'myApp.directives.htmlCompile',
    'myApp.directives.htmlMaxSizeValidation',
    'myApp.directives.listFilter',
    'myApp.directives.number',
    'myApp.directives.number.additions',
    'myApp.directives.overflow',
    'myApp.directives.parseProps',
    'myApp.directives.preventSubmitOnEnter',
    'myApp.directives.radio',
    'myApp.directives.split',
    'myApp.directives.statisticsValue',
    'myApp.directives.stickyScroll',
    'myApp.directives.switch',
    'myApp.directives.time',
    'myApp.directives.unsubscribe',
    'myApp.directives.uri',
    'myApp.directives.url',
    'myApp.directives.urlWithParams',
    'myApp.directives.videoBlockUrl',
    'myApp.directives.wizard',
  ]);
})();

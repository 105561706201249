import { CHAT_THEMES_TYPES, CHAT_THEMES_TYPES_ARRAY } from '../../../../app/http/chat-settings/chat-settings.constants';

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqChatThemeController', CqChatThemeController);

  function CqChatThemeController() {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.CHAT_THEMES_TYPES = CHAT_THEMES_TYPES;
      vm.CHAT_THEMES_TYPES_ARRAY = CHAT_THEMES_TYPES_ARRAY;
    }
  }
})();

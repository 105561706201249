(function () {
  'use strict';

  require('./statistics.controller');
  require('./tabs/sendings.html');

  angular.module('myApp.chatBot.leadBot.statistics').component('cqChatBotStatistics', cqChatBotStatistics());

  function cqChatBotStatistics() {
    return {
      bindings: {
        chatBotMessage: '=', // Чат-бот
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
      },
      controller: 'CqChatBotStatisticsController',
      controllerAs: 'vm',
      template: require('./statistics.html'),
    };
  }
})();

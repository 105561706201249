import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SUBJECT_MAX_LENGTH } from '@http/integration/integrations/email-notification/constants/email-notification-integration.constants';
import { EmailNotificationIntegrationFormData } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-email-notification-subject[control]',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationSubjectComponent {
  /** Control для настройки темы письма */
  @Input() control!: GenericFormControl<EmailNotificationIntegrationFormData['settings']['subject']>;
  /** Максимальная длина темы письма */
  SUBJECT_MAX_LENGTH = SUBJECT_MAX_LENGTH;
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CancellationExactlyModalComponent } from '@panel/app/pages/subscription/general/signed/plan-info/name/cancellation-exactly-modal/cancellation-exactly-modal.component';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

/** Компонент для работы модальным окном для намеренья отменить подписку */
@Component({
  selector: 'cq-cancellation-prevent-modal',
  templateUrl: './cancellation-prevent-modal.component.html',
  styleUrls: ['./cancellation-prevent-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancellationPreventModalComponent {
  constructor(
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly ngbModal: NgbModal,
    protected readonly subscriptionStore: SubscriptionStore,
  ) {}

  /** Открытие модального окна для действительной отмены подписки */
  openCancellationExactlyModal(): void {
    this.ngbActiveModal.dismiss();

    this.ngbModal.open(CancellationExactlyModalComponent);
  }
}

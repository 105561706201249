import { Injectable } from '@angular/core';

import { AiData } from '@http/ai-data/internal-types/ai-data.internal.type';
import { AiDataActionModalTextService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal-text-service/ai-data-action-modal-text.service';
import { ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';

export enum AI_DATA_ACTIONS {
  RENAME = 'rename',
  DELETE = 'delete',
  SINGLE_ENABLE = 'singleEnable',
  SINGLE_DISABLE = 'singleDisable',
  MULTIPLE_ENABLE = 'multipleEnable',
  MULTIPLE_DISABLE = 'multipleDisable',
}

@Injectable()
export class AiDataActionModalService {
  constructor(
    private readonly aiDataActionModalTextService: AiDataActionModalTextService,
    private readonly modelHelperService: ModalHelperService,
  ) {}

  openDeleteItemModal(dataSource: AiData) {
    return this.modelHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.aiDataActionModalTextService.getModalHeadingText(AI_DATA_ACTIONS.DELETE),
        body: this.aiDataActionModalTextService.getModalBodyText(AI_DATA_ACTIONS.DELETE, dataSource),
        confirmButtonText: this.aiDataActionModalTextService.getConfirmButtonText(AI_DATA_ACTIONS.DELETE),
      })
      .open(ConfirmModalComponent);
  }

  openDisableSingleItemModal(dataSource: AiData) {
    return this.modelHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.aiDataActionModalTextService.getModalHeadingText(AI_DATA_ACTIONS.SINGLE_DISABLE),
        body: this.aiDataActionModalTextService.getModalBodyText(AI_DATA_ACTIONS.SINGLE_DISABLE, dataSource),
        confirmButtonText: this.aiDataActionModalTextService.getConfirmButtonText(AI_DATA_ACTIONS.SINGLE_DISABLE),
      })
      .open(ConfirmModalComponent);
  }

  openRenameItemModal(dataSource: AiData) {
    return this.modelHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        heading: this.aiDataActionModalTextService.getModalHeadingText(AI_DATA_ACTIONS.RENAME),
        body: this.aiDataActionModalTextService.getModalHeadingText(AI_DATA_ACTIONS.RENAME),
        text: dataSource.name,
        inputPlaceholder: this.aiDataActionModalTextService.getRenameModalInputPlaceholderText(),
        inputErrorText: this.aiDataActionModalTextService.getRenameModalInputRequiredErrorText(),
        confirmButtonText: this.aiDataActionModalTextService.getConfirmButtonText(AI_DATA_ACTIONS.RENAME),
      })
      .open(PromptModalComponent);
  }

  openEnableMultipleItemsModal() {
    return this.modelHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.aiDataActionModalTextService.getModalHeadingText(AI_DATA_ACTIONS.MULTIPLE_ENABLE),
        body: this.aiDataActionModalTextService.getModalBodyText(AI_DATA_ACTIONS.MULTIPLE_ENABLE),
        confirmButtonText: this.aiDataActionModalTextService.getConfirmButtonText(AI_DATA_ACTIONS.MULTIPLE_ENABLE),
      })
      .open(ConfirmModalComponent);
  }

  openDisableMultipleItemsModal() {
    return this.modelHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.aiDataActionModalTextService.getModalHeadingText(AI_DATA_ACTIONS.MULTIPLE_DISABLE),
        body: this.aiDataActionModalTextService.getModalBodyText(AI_DATA_ACTIONS.MULTIPLE_DISABLE),
        confirmButtonText: this.aiDataActionModalTextService.getConfirmButtonText(AI_DATA_ACTIONS.MULTIPLE_DISABLE),
      })
      .open(ConfirmModalComponent);
  }
}

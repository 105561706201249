(function () {
  'use strict';

  angular.module('myApp.messageTemplates', []);

  require('./message-templates.constants');
  require('./message-templates.routes');
  require('./message-templates.component');
  require('./edit/message-templates-edit.component');
  require('./grid/message-templates-grid.component');
})();

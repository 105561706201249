(function () {
  'use strict';

  angular.module('myApp.teamMembers', []);

  require('./team-member.routes');
  require('./editor/team-member-editor.controller');
  require('./editor/team-member-editor.html');
  require('./editor/team-member-editor-modal.html');
  require('./table/team-members-table.component');
  require('./table-wrapper/team-members-table-wrapper.component');
})();

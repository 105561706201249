import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { DownloadDocumentsComponent } from './download-documents.component';

@NgModule({
  declarations: [DownloadDocumentsComponent],
  exports: [DownloadDocumentsComponent],
  imports: [CommonModule, NgbDropdownModule, NgbTooltipModule, TranslocoModule],
})
export class DownloadDocumentsModule {}

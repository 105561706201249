import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment';
import { map } from 'rxjs/operators';

import { SENDING_TYPES } from '@http/message/message.constants';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { MESSAGE_STATISTICS } from '@http/message-statistics/message-statistics.constants';
import { MessageStatisticsModel } from '@http/message-statistics/message-statistics.model';
import { PaginationParamsRequest } from '@http/types';

/**
 * Сервис для работы с тест-группами сообщений
 */
@Injectable({ providedIn: 'root' })
export class MessageTestGroupModel {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly http: HttpClient,
    private readonly messagePartModel: MessagePartModel,
    private readonly messageStatisticsModel: MessageStatisticsModel,
  ) {}

  /**
   * Закрытие тест-группы
   *
   * @param testGroupId ID тест-группы
   * @param messagePartId ID варианта сообщения из тест-группы, который будет перенесён в новую тест группу, созданную сразу после закрытия этой
   */
  closeTestGroup(testGroupId: string, messagePartId: string) {
    const body = {
      selected_part: messagePartId,
    };

    return this.http.post('/messagetestgroups/' + testGroupId + '/close', body).pipe(
      map((data) => {
        this.parseTestGroup(data);
        return data;
      }),
    );
  }

  /**
   * Фильтрация только тех тест-групп, у которых больше 1 варианта сообщения (т.е. в которых проводится А/Б тест)
   */
  filterAbTests(testGroups: any[]) {
    return testGroups.filter((testGroup) => {
      if (testGroup.parts) {
        return this.messagePartModel.filterMessageParts(testGroup.parts).length > 1;
      } else {
        return false;
      }
    });
  }

  /**
   * Получение тест-групп для сообщения
   *
   * @param messageId ID сообщения
   * @param includeClosed Дополнительно получить закрытые (на текущий момент не действующие) тест-группы сообщения
   */
  getMessageTestGroups(messageId: string, includeClosed = true) {
    if (messageId === undefined) {
      throw Error('messageId must be specified');
    }

    const params = {
      include_closed: includeClosed,
    };

    return this.http.get<any>('/messages/' + messageId + '/testgroups', { params }).pipe(
      map((data) => {
        const testGroups = data.testGroups;

        for (let i = 0; i < testGroups.length; i++) {
          this.parseTestGroup(testGroups[i]);
        }

        return testGroups;
      }),
    );
  }

  getMessageTestGroupStatistics(
    entityId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    excludeControlGroup = true,
    rangeOverride: typeof MESSAGE_STATISTICS.RANGES[keyof typeof MESSAGE_STATISTICS.RANGES],
  ) {
    return this.messageStatisticsModel.getStatistics(
      'messagetestgroups',
      entityId,
      startDate,
      endDate,
      excludeControlGroup,
      rangeOverride,
    );
  }

  getMessageTestGroupSendings(
    entityId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    sendingType: SENDING_TYPES,
    paginatorParams?: PaginationParamsRequest,
    excludeControlGroup = true,
  ) {
    return this.messageStatisticsModel.getSendings(
      'messagetestgroups',
      entityId,
      startDate,
      endDate,
      sendingType,
      paginatorParams,
      excludeControlGroup,
    );
  }

  /**
   * Парсинг тест-группы
   *
   * @param testGroup Тест-группа
   */
  parseTestGroup(testGroup: any) {
    testGroup.name = this.transloco.translate('models.message.testGroupName');

    if (testGroup.created) {
      testGroup.created =
        testGroup.created != null ? moment(testGroup.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ') : testGroup.created;
      testGroup.name += ' ' + testGroup.created.format('L');
    }

    if (testGroup.closed) {
      testGroup.closed =
        testGroup.closed != null ? moment(testGroup.closed, 'YYYY-MM-DDTHH:mm:ss.SSSZ') : testGroup.closed;
      testGroup.name += ' - ' + testGroup.closed.format('L');
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Blur } from 'ngx-quill/lib/quill-editor.component';
import { Blot } from 'parchment/dist/src/blot/abstract/blot';
import Quill, { RangeStatic } from 'quill';
import Delta from 'quill-delta';
import { fromEvent, Subject } from 'rxjs';
import { filter, map, skipWhile, takeUntil } from 'rxjs/operators';

import {
  LinkEditorModalComponent,
  MODAL_ACTIONS_TYPE,
  ReturnedLinkEditorModalParams,
} from '@panel/app/pages/chat-bot/content/modals/link-editor/link-editor-modal.component';
import { DestroyService } from '@panel/app/services';

// Если использовать import Link from 'quill/formats/link',
//то метод descendant не будет работать ¯\_(ツ)_/¯
const Link = Quill.import('formats/link');

export type QuillEditorFormat = 'bold' | 'italic' | 'strike' | 'underline' | 'link' | 'list';

@Component({
  selector: 'cq-quill-text-editor[formControlName],cq-quill-text-editor[formControl],cq-quill-text-editor[ngModel]',
  templateUrl: './quill-text-editor.component.html',
  styleUrls: ['./quill-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillTextEditorComponent),
      multi: true,
    },
  ],
})
export class QuillTextEditorComponent implements ControlValueAccessor, OnInit {
  /**
   * Разрешенные форматы
   */
  @Input()
  set formats(formats: QuillEditorFormat[]) {
    this._formats = formats;
    this.setFormatButtonAvailableMap();
  }
  get formats() {
    return this._formats;
  }
  private _formats: QuillEditorFormat[] = [];

  @Input()
  placeholder: string = this.translocoService.translate('quillTexEditorComponent.quillEditor.placeholder');

  /** Событие blur редактора quill */
  blur$ = new Subject<Blur>();
  /** Флаг открытия модалки */
  linkEditorIsOpen: boolean = false;
  /** Экземпляр quill редактора */
  quill: Quill | null = null;
  /** Контент редактора */
  quillContent: string = '';
  /** Доступные кнопки для форматов */
  formatButtonAvailableMap: Record<QuillEditorFormat, boolean> = {
    bold: false,
    italic: false,
    strike: false,
    underline: false,
    link: false,
    list: false,
  };

  formControl = new FormControl();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    protected readonly ngbModal: NgbModal,
    protected readonly translocoService: TranslocoService,
  ) {}

  /**
   * Добавить форматирование
   * @param name Имя форматирования
   * @param value Значене для формата
   */
  format(name: string, value: boolean | string = true) {
    const valueToFormat = this.quill!.getFormat()[name] === value ? false : value;
    this.quill!.format(name, valueToFormat, 'user');
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => this.onChange(value));
  }

  /**
   * Добавление ссылок
   */
  setLink(): void {
    let selectionRange = this.quill!.getSelection();

    if (selectionRange!.length === 0) {
      selectionRange = this.getLinkRange(selectionRange!);
    }

    const text = this.quill!.getText(selectionRange!.index, selectionRange!.length);
    const url = this.quill!.getFormat().link ?? null;
    this.linkEditorIsOpen = true;
    this.openLinkEditorModal(text, url)
      .then((link) => {
        if (link.action === MODAL_ACTIONS_TYPE.EDIT) {
          const formats = this.quill!.getFormat(selectionRange!);
          const delta = new Delta()
            .retain(selectionRange!.index)
            .delete(selectionRange!.length)
            .insert(link.text!, { ...formats, link: link.url });
          //@ts-ignore
          //NOTE updateContents требует DeltaStatic, но у delta очевидно тип Delta
          // Это было исправлено, но для v2 https://github.com/DefinitelyTyped/DefinitelyTyped/pull/29340
          // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/29253
          this.quill.updateContents(delta, 'user');
        } else {
          this.quill!.formatText(selectionRange!, 'link', false, 'user');
        }
      })
      .catch(() => {})
      .finally(() => {
        this.linkEditorIsOpen = false;
        // Курсор сбрасывается поэтмоу возвращаю его на конец ссылки
        this.quill!.setSelection(selectionRange!.index + selectionRange!.length, 0);
      });
  }

  /**
   * Открытие модалки редактирования ссылок
   * @param text Текст ссылки
   * @param url Адрес ссылки
   * @private
   */
  private openLinkEditorModal(text: string, url: string | null): Promise<ReturnedLinkEditorModalParams> {
    const createNewPropertyModal = this.ngbModal.open(LinkEditorModalComponent);

    createNewPropertyModal.componentInstance.text = text;
    createNewPropertyModal.componentInstance.url = url;

    return createNewPropertyModal.result;
  }

  /**
   * Получить Range для ссылки по selection
   * @param range - Текущее положение курсора на ссылке
   * @private
   */
  private getLinkRange(range: RangeStatic): RangeStatic {
    const [link, offset] = this.quill!.scroll.scroll.descendant<Blot>(Link, range.index);

    return {
      index: link ? range.index - offset : range.index,
      length: link ? link.length() : 0,
    };
  }

  /**
   * Callback при создании редактора
   * @param quill Инстанс редактора
   */
  onEditorCreated(quill: Quill) {
    this.quillContent = this.formControl.value;
    this.quill = quill;
    this.initQuillSubscriptions();
  }

  /**
   * Инициализация слушателей для Quill
   */
  initQuillSubscriptions(): void {
    fromEvent<RangeStatic>(this.quill as any, 'selection-change')
      .pipe(
        takeUntil(this.destroy$),
        // @ts-ignore квилл кусок мусора, не умеет в типы, эта штука рабочая
        map((event) => event[0]),
      )
      .subscribe((range: RangeStatic) => {
        //Т.к. работа с формой идет не через HTML надо руками выставить touched, чтобы валидация начала отрабатывать
        if (!this.formControl.touched && !range) {
          this.formControl.markAsTouched();
          this.onTouched();
        }
        this.changeDetectorRef.detectChanges();
      });

    fromEvent<RangeStatic>(this.quill as any, 'text-change')
      .pipe(
        takeUntil(this.destroy$),
        map(() => (this.quillContent ? this.quillContent.replace(/<br>/gim, '<br/>') : '')),
        map((value) => value.replace(/<p>(\s+)<\/p>/gim, '')),
        skipWhile((value) => {
          return value === this.formControl.value;
        }),
      )
      .subscribe((value) => {
        this.formControl.setValue(value);
      });

    /**
     * HACK код ниже нужен для избавления от редкого бага Car-35030
     *  если НЕ выделяя текст, добавить его форматирование и ни чего не писать, то quill
     *  добавит span.ql-cursor, и убирает он его только если что нибудь отредактировать или изменить положение курсора
     *  НО если снять фокус с поля, ни чего не сделав, он оставит этот span
     */
    this.blur$
      .pipe(
        takeUntil(this.destroy$),
        filter((evt: Blur) => evt.source === 'user'),
      )
      .subscribe(() => {
        this.quill?.setSelection(0, 0);
        this.quill?.blur();
      });
  }

  /**
   * Определяет класс для кнопки в зависимости от того есть ли в месте курсора нужное форматирование
   * @param name Имя форматирования
   * @param value Значение для формата
   */
  getQuillButtonClass(name: string, value: boolean | string = true): string {
    if (
      this.quill &&
      this.quill.hasFocus() &&
      (this.quill.getFormat()[name] === value || (name === 'link' && this.quill.getFormat()[name]))
    ) {
      return 'active';
    }
    return '';
  }

  /**
   * Установка доступности кнопок форматирования
   */
  setFormatButtonAvailableMap() {
    for (let key in this.formatButtonAvailableMap) {
      //@ts-ignore
      this.formatButtonAvailableMap[key] = false;
    }
    for (let format of this.formats) {
      this.formatButtonAvailableMap[format] = true;
    }
  }

  /** ControlValueAccessor's methods implementation */

  registerOnChange(fn: (v: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: boolean): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.changeDetectorRef.markForCheck();
  }

  private onChange: (v: string) => void = (v: string) => {};

  private onTouched: () => void = () => {};
}

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqChatColorController', CqChatColorController);

  function CqChatColorController($scope, carrotquestHelper, validationHelper) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.trackChangeColor = trackChangeColor;
      vm.validationHelper = validationHelper;

      // пришлось отслеживать изменение цвета не через ng-change, а через $watch, потому что ng-change не работает с cq-color-picker, я не смог починить этот баг быстро
      $scope.$watch('vm.color', trackChangeColor);
    }

    /**
     * Трек изменения цвета
     */
    function trackChangeColor(newVal, oldVal) {
      if (newVal !== oldVal && oldVal === '#3a3a3a') {
        carrotquestHelper.track('Настройки чата – изменил стандартный цвет');
      }
    }
  }
})();

import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from 'lodash-es';

@Pipe({
  name: 'toNumber',
})
export class ToNumberPipe implements PipeTransform {
  transform(value: string | null): number {
    let number = 0;

    if (!isNull(value)) {
      number = parseFloat(value);
    }

    return number;
  }
}

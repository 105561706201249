import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import Moment from 'moment';

import { App } from '@http/app/app.model';
import { User } from '@http/user/types/user.type';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { UserSystemProperty } from '@http/property/property.constants';

@Component({
  selector: 'cq-user-customs-props[currentApp][user]',
  templateUrl: './user-customs-props.component.html',
  styleUrls: ['./user-customs-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCustomsPropsComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Кастомные св-ва пользователя */
  @Input()
  customsProps: Array<string> = [];

  /** Пользователь */
  @Input()
  user!: User;

  /** Закрепленные св-ва пользователя */
  @Input()
  pinnedProps: Partial<Array<UserSystemProperty | '$carrotId' | '$userId' | string>> = [];

  /**
   * Возможность закрепления св-ва.
   * если true, то будет закрепление св-в,
   * если false, то будет открепление св-в.
   */
  @Input()
  pinningPossibility: boolean = false;

  /**
   * Отключен ли класс property-body у детей кастомных и системных св-в.
   * Это необходимо, чтобы отключать hover у элементов при перетаскивании.
   * При использовании sortableJs сохраняется hover эффект на изначальной позиции.
   */
  @Input()
  disablePropertyBodyClass: boolean = false;

  /** Колбек для открытия модалки редактирования св-ва пользователя */
  @Output()
  openEditUserPropertyModal: EventEmitter<{ propertyName: string | undefined; propertyValue: string | undefined }> =
    new EventEmitter();

  /** Колбек для открытия модалки подтверждения удаления св-ва пользователя */
  @Output()
  removeUserProperty: EventEmitter<string> = new EventEmitter();

  /** Колбек для закрепления св-ва пользователя */
  @Output()
  pinProp: EventEmitter<string> = new EventEmitter();

  /** Колбек для открепления св-ва пользователя */
  @Output()
  unpinProp: EventEmitter<string> = new EventEmitter();

  /** Доступ до свойств пользователей */
  accessToUsersProps: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  constructor(
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    public readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.accessToUsersProps = this.planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_PROPS, this.currentApp);
  }

  /** Функция на успешное копирование элемента */
  copiedSuccess(): void {
    const text = this.translocoService.translate('userLocationComponent.copiedTooltip');
    this.toastService.success(text);
  }

  /** Открытие модалки редактирования св-ва */
  editUserPropertyModal(propName: string | undefined): void {
    if (propName) {
      let property: { propertyName: string; propertyValue: string | undefined } = {
        propertyName: propName,
        propertyValue: this.getUserCustomPropValue(propName),
      };

      this.openEditUserPropertyModal.emit(property);
    }
  }

  /** Получить перевод имени для кастомного св-ва пользователя */
  getUserCustomPropTitle(propName: string): string {
    if (propName === '$email' || propName === '$phone') {
      return this.translocoService.translate(`models.property.eventSystemProperties.${propName}`);
    }
    return propName;
  }

  /** Получить значение кастомного св-ва пользователя */
  getUserCustomPropValue(propName: UserSystemProperty | string): string | undefined {
    if (propName === '$email') {
      return this.user.props[propName];
    }
    if (propName === '$phone') {
      return this.user.props[propName];
    }
    if (this.user.propsCustom[propName] === 'true' || this.user.propsCustom[propName] === true) {
      return this.translocoService.translate('userCustomsPropsComponent.booleanTrue');
    }
    if (this.user.propsCustom[propName] === 'false' || this.user.propsCustom[propName] === false) {
      return this.translocoService.translate('userCustomsPropsComponent.booleanFalse');
    }
    if (checkValidFullDate(this.user.propsCustom[propName] as string)) {
      return Moment(`${this.user.propsCustom[propName]}Z`).format('lll');
    } else if (checkValidShortDate(this.user.propsCustom[propName] as string)) {
      return Moment(`${this.user.propsCustom[propName]}T00:00:00Z`).format('lll');
    }

    return this.user.propsCustom[propName] as string;

    function checkValidFullDate(value: string) {
      return Moment(value, 'YYYY-MM-DDTHH:mm:ss', true).isValid();
    }

    function checkValidShortDate(value: string) {
      return Moment(value, 'YYYY-MM-DD', true).isValid();
    }
  }

  /** Необходимость отображать свойство */
  needToShowProp(propName: string): boolean {
    const requiredProps = ['$email', '$phone'];

    if (!this.pinningPossibility && this.isPinned(propName)) {
      return true;
    }

    // Если кастомные св-ва не включены в план, то возвращаем false
    if (!this.accessToUsersProps.hasAccess && !requiredProps.includes(propName)) {
      return false;
    }

    const propValue = this.getUserCustomPropValue(propName);
    const hasValue = propValue || typeof propValue === 'boolean';

    // Если у закрепленного св-ва есть значение, то для:
    // не закреплено и вызвано с параметром возможности закрепления(pinningPossibility);
    // возвращаем true
    if (hasValue || requiredProps.includes(propName)) {
      if (this.pinningPossibility && !this.isPinned(propName)) {
        return true;
      }
    }

    return false;
  }

  /** Является значение св-ва ссылкой */
  isLink(propName: string): boolean {
    let propValue = this.getUserCustomPropValue(propName);
    let url;

    if (propValue) {
      try {
        url = new URL(propValue);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    }
    return false;
  }

  /**
   * Является ли св-во закрепленным
   * @param propName
   */
  isPinned(propName: string): boolean {
    return this.pinnedProps.includes(propName);
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { FirstPromoterService } from '@panel/app/services/first-promoter/first-promoter.service';

@Component({
  selector: 'cq-first-payment-discount-label',
  templateUrl: './first-payment-discount-label.component.html',
  styleUrls: ['./first-payment-discount-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstPaymentDiscountLabelComponent {
  /** Размер скидки по реферальной программе */
  get firstPaymentDiscount(): number {
    return this.firstPromoterService.getDiscount() * 100;
  }

  constructor(@Inject(FirstPromoterService) private readonly firstPromoterService: FirstPromoterService) {}
}

<ng-container *transloco="let t; read: 'memberActionsNotFoundZeroDataComponent'">
  <div class="my-50 py-50 mx-auto">
    <cq-zero-data
      [imgHeightPx]="160"
      [src]="'assets/img/default/users/default/zero-data-leads.png'"
    >
      <div class="text-secondary">{{ t('heading') }}</div>
    </cq-zero-data>
  </div>
</ng-container>

import { InjectionToken, Provider } from '@angular/core';
import { map, Observable, share, timer } from 'rxjs';

/**
 * Токен для получения глобального тикера
 */
export const GLOBAL_TICKER = new InjectionToken<Observable<void>>('Global tick every 30 sec');

function getGlobalTicker(): Observable<void> {
  return timer(30000, 30000).pipe(
    map(() => {
      // timer возвращает number, поэтому этот return написан тут специально, чтобы глобальный тикер возвращал void и не влиял на типы при его использовании
      return;
    }),
    share(),
  );
}

export const globalTickerProvider: Provider = {
  provide: GLOBAL_TICKER,
  useFactory: getGlobalTicker,
};

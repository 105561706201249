(function () {
  'use strict';

  require('./karma-hint.controller');

  angular.module('myApp.modals.sendManualMessage').component('cqKarmaHint', cqKarmaHint());

  function cqKarmaHint() {
    return {
      bindings: {
        currentAppId: '<', // Текущий id приложения
        onSuccess: '<', // callback вызываемый, если статусы кармы успешно получены
      },
      controller: 'CqKarmaHintController',
      controllerAs: 'vm',
      template: require('./karma-hint.html'),
    };
  }
})();

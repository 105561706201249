import { Injectable } from '@angular/core';
import { some } from 'lodash-es';

import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfoModel, BillingInfoSubscription } from '@panel/app/services/billing-info/billing-info.model';

/**
 * Сервис для работы с аддонами в тарифе
 */
@Injectable({ providedIn: 'root' })
export class PlanAddonService {
  constructor(private readonly billingInfoModel: BillingInfoModel) {}

  /**
   * Подключен ли аддон в тарифе
   *
   * @param addonId - ID аддона
   */
  isAddonConnected(addonId: BILLING_ADD_ONS): boolean {
    if (!this.billingInfoModel.billingInfo.subscription) {
      throw new Error('There is not subscription data');
    }

    if (!(this.billingInfoModel.billingInfo.subscription as BillingInfoSubscription).addons) {
      return false;
    }

    return some((this.billingInfoModel.billingInfo.subscription as BillingInfoSubscription).addons, { id: addonId });
  }
}

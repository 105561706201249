import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ArticleCategory } from '@http/article-category/internal-types/article-category.internal.type';
import { AiDataActionModalService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal.service';
import { AiDataActionModalTextService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-action-modal-text-service/ai-data-action-modal-text.service';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';
import { OneTimeFlagService } from '@panel/app/services';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';

@Component({
  selector: 'cq-ai-data-source-list',
  templateUrl: './ai-data-source-list.component.html',
  styleUrls: ['./ai-data-source-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AiDataSourcesListStateService, AiDataActionModalService, AiDataActionModalTextService],
})
export class AiDataSourceListComponent implements OnInit {
  @Input()
  knowledgeBaseCategories: ArticleCategory[] = [];

  isShowSettingsBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    !this.oneTimeFlagHelper.isSet(ONE_TIME_FLAGS.SHOW_AI_DATA_SETTINGS_BANNER),
  );

  constructor(private readonly oneTimeFlagHelper: OneTimeFlagService, readonly state: AiDataSourcesListStateService) {}

  ngOnInit() {
    this.state.knowledgeBaseCategories$.next(this.knowledgeBaseCategories);
  }

  closeSettingsBanner(): void {
    this.oneTimeFlagHelper.set(ONE_TIME_FLAGS.SHOW_AI_DATA_SETTINGS_BANNER);
    this.isShowSettingsBanner.next(false);
  }
}

import { StateService } from '@uirouter/core';
import { extend, IPromise, isUndefined } from 'angular';
import { TRACK_MESSAGE_INTERACTION_TYPES } from 'global';

import { environment } from '@environment';

import { ApiTrackRequest } from './carrotquest-helper.types';

/**
 * Сервис для работы с методами carrotquest
 */
export class CarrotquestHelper {
  //@ngInject
  constructor(private $http: any, private $state: StateService) {}

  /**
   * Установить callback
   *
   * @param topic - Тема. Когда вы хотите получать уведомление
   * @param callback - Функция, которая будет вызвана
   */
  public addCallback(topic: string, callback: () => void) {
    if (isUndefined(topic)) {
      throw Error('topic must be specified');
    }
    if (isUndefined(callback)) {
      throw Error('callback must be specified');
    }
    carrotquest.addCallback(topic, callback);
  }

  /**
   * Передача User ID
   *
   * @param userId - User ID
   * @param hash - хеш
   */
  public auth(userId: string, hash: string) {
    if (isUndefined(userId)) {
      throw Error('userId must be specified');
    }
    if (isUndefined(hash)) {
      throw Error('hash must be specified');
    }
    carrotquest.auth(userId, hash);
  }

  /**
   * Соединение с системой
   *
   * @param apiKey - API Key
   */
  public connect(apiKey: string) {
    if (isUndefined(apiKey)) {
      throw Error('apiKey must be specified');
    }
    carrotquest.connect(apiKey);
  }

  /**
   * Идентификация (запись свойства пользователя)
   *
   * @param data - Свойства пользователя
   */
  public identify(data: object) {
    if (isUndefined(data)) {
      throw Error('data must be specified');
    }
    carrotquest.identify(data);
  }

  /**
   * Получить carrotquest id
   */
  public getId(): string {
    return carrotquest.data.user.id;
  }

  /**
   * Поставить callback, который будет вызван после загрузки скрипта
   *
   * @param callback - Функция, которая будет вызвана
   */
  public onReady(callback: () => void) {
    if (isUndefined(callback)) {
      throw Error('callback must be specified');
    }
    carrotquest.onReady(callback);
  }

  /**
   * Открытие чата
   */
  public open() {
    carrotquest.open();
  }

  /**
   * Удалить callback
   *
   * @param topic - Тема, на которую вы подписывались
   * @param callback - Функция, которую вы подписывали
   */
  public removeCallback(topic: string, callback: () => void) {
    if (isUndefined(topic)) {
      throw Error('topic must be specified');
    }
    if (isUndefined(callback)) {
      throw Error('callback must be specified');
    }
    carrotquest.removeCallback(topic, callback);
  }

  /**
   * Отправка сообщения в чат
   *
   * @param text - Текст который напишется в чат
   */
  public sendChatMessage(text: string): IPromise<void> {
    const params = {
      //@ts-ignore
      auth_token: carrotquest.data.auth_token,
      body: text,
      id_as_string: true,
    };
    return this.$http({
      method: 'POST',
      url: `${environment.apiEndpoint}/users/${carrotquest.data.user.id}/startconversation`,
      data: params,
    }).then((response: any) => {
      const conversationId = response.data.data.id;
      carrotquest.messenger.toStateNo();
      carrotquest.messenger.toStateOpened({ conversation: { id: conversationId }, directConversation: true });
    });
  }

  /**
   * Скрывает чат со страницы
   */
  public hideChat() {
    if (isUndefined(carrotquest)) {
      throw Error('carrotquest is not defined');
    }

    if (isUndefined(carrotquest.messenger)) {
      throw Error('carrotquest.messenger is not defined');
    }

    carrotquest.messenger.toStateNo();
  }

  /**
   * Отслеживание события
   *
   * @param eventName - Название события
   * @param params - Свойства события
   */
  public track(eventName: string, params?: object) {
    if (isUndefined(eventName)) {
      throw Error('eventName must be specified');
    }

    let trackedParams: ApiTrackRequest = {};

    if (this.$state.params.appId) {
      trackedParams.app_id = this.$state.params.appId;
    }

    carrotquest.track(eventName, extend(trackedParams, params));
  }

  /**
   * Отслеживание взаимодействия с сообщением (прочтение, клик, ответ)
   *
   * @param sendingId - ID отправки
   * @param type - Тип взаимодействия.
   */
  public trackMessageInteraction(sendingId: string, type: TRACK_MESSAGE_INTERACTION_TYPES) {
    if (isUndefined(sendingId)) {
      throw Error('sendingId must be specified');
    }
    if (isUndefined(type)) {
      throw Error('type must be specified');
    }
    carrotquest.trackMessageInteraction(sendingId, type);
  }
}

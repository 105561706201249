import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cq-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input()
  containerHeight: string = '100%';

  /** Ширина лоадера */
  @Input()
  width: number = 30;

  /** Высота лоадера */
  @Input()
  height: number = 30;

  /** Отображать фон или нет */
  @Input()
  background: boolean = true;

  constructor() {}
}

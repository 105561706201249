import { Viewport } from 'pixi-viewport';

import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { BaseBadge } from '@panel/app/pages/chat-bot/content/views/badges';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { SwitchBuildingElement } from '@panel/app/pages/chat-bot/content/views/building-elements';

export const enum PIXI_INTERACTION_EVENT {
  BRANCH = 'branch',
  BADGE = 'badge',
  SWITCHER = 'switcher',
  VIEWPORT = 'viewport',
  CONNECTION_POINT = 'connection-point',
}

export type Interactive<SourceType extends PIXI_INTERACTION_EVENT, InstanceType extends object> = {
  type: SourceType;
  instance: InstanceType;
};

export type InteractionEvent =
  | Interactive<PIXI_INTERACTION_EVENT.BRANCH, Branch>
  | Interactive<PIXI_INTERACTION_EVENT.VIEWPORT, Viewport>
  | Interactive<PIXI_INTERACTION_EVENT.CONNECTION_POINT, BotAction>
  | Interactive<PIXI_INTERACTION_EVENT.CONNECTION_POINT, BaseBadge>
  | Interactive<PIXI_INTERACTION_EVENT.BADGE, BaseBadge>
  | Interactive<PIXI_INTERACTION_EVENT.SWITCHER, SwitchBuildingElement>;

export type PointerVerticalPosition = 'middle' | 'top' | 'bottom';
export type PointerHorizontalPosition = 'middle' | 'left' | 'right';

type OrientationInfo<T> = {
  position: T;
  // На сколько близко по шкале от 0 до 1. Калькулируется делением (расстояние до бордера)/(размер на котором курсор считается близким к бордеру)
  distanceCoefficient: number | null;
};

export type PointerPositionEvent = {
  vertical: OrientationInfo<PointerVerticalPosition>;
  horizontal: OrientationInfo<PointerHorizontalPosition>;
};

import { ConversationTagAdapterService } from '@panel/app-old/shared/entities/conversation-tag/adapter/conversation-tag-adapter.service';
import { ConversationTag } from '@panel/app-old/shared/entities/conversation-tag/conversation-tag';
import { ConversationTagDto } from '@panel/app-old/shared/entities/conversation-tag/types/conversation-tag-dto.types';

/** Factory-сервис для работы с тегами диалогов */
export class ConversationTagFactoryService {
  //@ngInject
  constructor(private $injector: any) {}

  /**
   * Создаёт инстанс из dto-формата
   *
   * @param dto - Dto-формат
   */
  getInstance(dto: ConversationTagDto): ConversationTag {
    const conversationTagHelpService = this.$injector.get('conversationTagHelpService');

    return new ConversationTag(conversationTagHelpService, dto);
  }

  /**
   * Создаёт инстанс из dtoApi-формата
   *
   * @param dtoApi - DtoApi-формат
   */
  getInstanceFromApi(dtoApi: string): ConversationTag {
    const adapter: ConversationTagAdapterService = this.$injector.get('conversationTagAdapterService');

    return this.getInstance(adapter.fromApi(dtoApi));
  }

  /**
   * Создаёт массив инстансов
   *
   * @param dtos - Массив dto-формата
   */
  getInstances(dtos: ConversationTagDto[]): ConversationTag[] {
    return dtos.map((dto) => this.getInstance(dto));
  }

  /**
   * Создаёт массив инстансов из dtoApi-формата
   *
   * @param dtosApi - Массив dtoApi-формата
   */
  getInstancesFromApi(dtosApi: string[]): ConversationTag[] {
    return dtosApi.map((dtoApi) => this.getInstanceFromApi(dtoApi));
  }
}

import { Environment } from '@environments/types';
import { overridesPartial } from '@environments/partials/overrides';
import { dockerLocationPartial } from '@environments/partials/locations/docker';
import { carrotQuestProjectPartial } from '@environments/partials/projects/carrot-quest';
import { devModePartial } from '@environments/partials/modes/dev';

export const environment: Environment = {
  ...dockerLocationPartial,
  ...carrotQuestProjectPartial,
  ...devModePartial,
  ...overridesPartial,
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AutoMessageUserStatusesComponent } from './auto-message-user-statuses.component';

@NgModule({
  declarations: [AutoMessageUserStatusesComponent],
  exports: [AutoMessageUserStatusesComponent],
  imports: [CommonModule, SharedModule, TranslocoModule, NgbTooltipModule, FormsModule, ReactiveFormsModule],
})
export class AutoMessageUserStatusesModule {}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { INSTALLATION_MODAL_SKIPPED } from '@http/app/app.constants';
import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BillingInfo } from '@panel/app/services/billing-info/billing-info.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-pricing-wrapper',
  templateUrl: './pricing-wrapper.component.html',
  styleUrls: ['./pricing-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingWrapperComponent implements OnInit {
  @Input()
  app!: App;

  @Input()
  billingInfo!: BillingInfo;

  @Input()
  djangoUser!: DjangoUser;

  protected showNewPricing = false;

  constructor(
    private readonly planVersionService: PlanVersionService,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly uiRouter: UIRouter,
  ) {}

  ngOnInit() {
    this.showNewPricing = !this.isActiveV2Plan();
  }

  private isActiveV2Plan(): boolean {
    return !this.planVersionService.isTrial() && this.planVersionService.isV2(this.app);
  }

  /** Перезагрузка страницы "Оплата" */
  private redirectToSubscriptionPage() {
    this.uiRouter.stateService.go('app.content.subscription', {}, { reload: true });
  }

  /**
   * Пропустить модальное окно с обязательной установкой кода
   *
   * NOTE:
   *  При пропуске установки кода, разделы админки раз блокируются, но механики, которые пушат на установку кода
   *  остаются работать
   */
  private skipInstallationModal() {
    setTimeout(() => {
      localStorage.setItem(INSTALLATION_MODAL_SKIPPED, 'true');

      this.carrotquestHelper.track('starterguide_skipped');
    }, 0);
  }
}

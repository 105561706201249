import { EmojiList, EmojiNative } from '@panel/app/shared/services/emoji/emoji.types';

/** Группы emoji */
export enum EMOJI_GROUP {
  PEOPLE = 'people',
  NATURE = 'nature',
  FOOD = 'food',
  ACTIVITY = 'activity',
  PLACES = 'places',
  OBJECTS = 'objects',
  SYMBOLS = 'symbols',
  FLAGS = 'flags',
}

/**
 * Иконки для групп emoji
 */
export const EMOJI_GROUP_ICON_CLASSES = {
  [EMOJI_GROUP.ACTIVITY]: 'cqi-ball',
  [EMOJI_GROUP.FLAGS]: 'cqi-flag',
  [EMOJI_GROUP.FOOD]: 'cqi-burger',
  [EMOJI_GROUP.NATURE]: 'cqi-tree',
  [EMOJI_GROUP.OBJECTS]: 'cqi-lightbulb',
  [EMOJI_GROUP.PEOPLE]: 'cqi-smile',
  [EMOJI_GROUP.PLACES]: 'cqi-car',
  [EMOJI_GROUP.SYMBOLS]: 'cqi-heart',
};

/**
 * Объект с emoji, которые поделены на 8 групп
 */
export const EMOJI_LIST: EmojiList = {
  [EMOJI_GROUP.PEOPLE]: [
    {
      name: ':grinning:',
      position: {
        x: 26,
        y: 14,
      },
    },
    {
      name: ':grimacing:',
      position: {
        x: 27,
        y: 17,
      },
    },
    {
      name: ':grin:',
      position: {
        x: 26,
        y: 15,
      },
    },
    {
      name: ':joy:',
      position: {
        x: 26,
        y: 16,
      },
    },
    {
      name: ':smiley:',
      position: {
        x: 26,
        y: 17,
      },
    },
    {
      name: ':smile:',
      position: {
        x: 26,
        y: 18,
      },
    },
    {
      name: ':sweat_smile:',
      position: {
        x: 26,
        y: 19,
      },
    },
    {
      name: ':laughing:',
      position: {
        x: 26,
        y: 20,
      },
    },
    {
      name: ':innocent:',
      position: {
        x: 26,
        y: 21,
      },
    },
    {
      name: ':wink:',
      position: {
        x: 26,
        y: 23,
      },
    },
    {
      name: ':blush:',
      position: {
        x: 26,
        y: 24,
      },
    },
    {
      name: ':slightly_smiling_face:',
      position: {
        x: 27,
        y: 39,
      },
    },
    {
      name: ':upside_down_face:',
      position: {
        x: 27,
        y: 40,
      },
    },
    {
      name: ':relaxed:',
      position: {
        x: 1,
        y: 24,
      },
    },
    {
      name: ':yum:',
      position: {
        x: 26,
        y: 25,
      },
    },
    {
      name: ':relieved:',
      position: {
        x: 26,
        y: 26,
      },
    },
    {
      name: ':heart_eyes:',
      position: {
        x: 26,
        y: 27,
      },
    },
    {
      name: ':kissing_heart:',
      position: {
        x: 26,
        y: 38,
      },
    },
    {
      name: ':kissing:',
      position: {
        x: 26,
        y: 37,
      },
    },
    {
      name: ':kissing_smiling_eyes:',
      position: {
        x: 26,
        y: 39,
      },
    },
    {
      name: ':kissing_closed_eyes:',
      position: {
        x: 26,
        y: 40,
      },
    },
    {
      name: ':stuck_out_tongue_winking_eye:',
      position: {
        x: 27,
        y: 1,
      },
    },
    {
      name: ':stuck_out_tongue_closed_eyes:',
      position: {
        x: 27,
        y: 2,
      },
    },
    {
      name: ':stuck_out_tongue:',
      position: {
        x: 27,
        y: 0,
      },
    },
    {
      name: ':money_mouth_face:',
      position: {
        x: 32,
        y: 1,
      },
    },
    {
      name: ':nerd_face:',
      position: {
        x: 32,
        y: 3,
      },
    },
    {
      name: ':sunglasses:',
      position: {
        x: 26,
        y: 28,
      },
    },
    {
      name: ':hugging_face:',
      position: {
        x: 32,
        y: 7,
      },
    },
    {
      name: ':smirk:',
      position: {
        x: 26,
        y: 29,
      },
    },
    {
      name: ':no_mouth:',
      position: {
        x: 27,
        y: 27,
      },
    },
    {
      name: ':neutral_face:',
      position: {
        x: 26,
        y: 30,
      },
    },
    {
      name: ':expressionless:',
      position: {
        x: 26,
        y: 31,
      },
    },
    {
      name: ':unamused:',
      position: {
        x: 26,
        y: 32,
      },
    },
    {
      name: ':face_with_rolling_eyes:',
      position: {
        x: 28,
        y: 0,
      },
    },
    {
      name: ':thinking_face:',
      position: {
        x: 32,
        y: 4,
      },
    },
    {
      name: ':flushed:',
      position: {
        x: 27,
        y: 24,
      },
    },
    {
      name: ':disappointed:',
      position: {
        x: 27,
        y: 3,
      },
    },
    {
      name: ':worried:',
      position: {
        x: 27,
        y: 4,
      },
    },
    {
      name: ':angry:',
      position: {
        x: 27,
        y: 5,
      },
    },
    {
      name: ':rage:',
      position: {
        x: 27,
        y: 6,
      },
    },
    {
      name: ':pensive:',
      position: {
        x: 26,
        y: 34,
      },
    },
    {
      name: ':confused:',
      position: {
        x: 26,
        y: 35,
      },
    },
    {
      name: ':slightly_frowning_face:',
      position: {
        x: 27,
        y: 38,
      },
    },
    {
      name: ':white_frowning_face:',
      position: {
        x: 1,
        y: 23,
      },
    },
    {
      name: ':persevere:',
      position: {
        x: 27,
        y: 8,
      },
    },
    {
      name: ':confounded:',
      position: {
        x: 26,
        y: 36,
      },
    },
    {
      name: ':tired_face:',
      position: {
        x: 27,
        y: 16,
      },
    },
    {
      name: ':weary:',
      position: {
        x: 27,
        y: 14,
      },
    },
    {
      name: ':triumph:',
      position: {
        x: 27,
        y: 9,
      },
    },
    {
      name: ':open_mouth:',
      position: {
        x: 27,
        y: 19,
      },
    },
    {
      name: ':scream:',
      position: {
        x: 27,
        y: 22,
      },
    },
    {
      name: ':fearful:',
      position: {
        x: 27,
        y: 13,
      },
    },
    {
      name: ':cold_sweat:',
      position: {
        x: 27,
        y: 21,
      },
    },
    {
      name: ':hushed:',
      position: {
        x: 27,
        y: 20,
      },
    },
    {
      name: ':frowning:',
      position: {
        x: 27,
        y: 11,
      },
    },
    {
      name: ':anguished:',
      position: {
        x: 27,
        y: 12,
      },
    },
    {
      name: ':cry:',
      position: {
        x: 27,
        y: 7,
      },
    },
    {
      name: ':disappointed_relieved:',
      position: {
        x: 27,
        y: 10,
      },
    },
    {
      name: ':sleepy:',
      position: {
        x: 27,
        y: 15,
      },
    },
    {
      name: ':sweat:',
      position: {
        x: 26,
        y: 33,
      },
    },
    {
      name: ':sob:',
      position: {
        x: 27,
        y: 18,
      },
    },
    {
      name: ':dizzy_face:',
      position: {
        x: 27,
        y: 26,
      },
    },
    {
      name: ':astonished:',
      position: {
        x: 27,
        y: 23,
      },
    },
    {
      name: ':zipper_mouth_face:',
      position: {
        x: 32,
        y: 0,
      },
    },
    {
      name: ':mask:',
      position: {
        x: 27,
        y: 28,
      },
    },
    {
      name: ':face_with_thermometer:',
      position: {
        x: 32,
        y: 2,
      },
    },
    {
      name: ':face_with_head_bandage:',
      position: {
        x: 32,
        y: 5,
      },
    },
    {
      name: ':sleeping:',
      position: {
        x: 27,
        y: 25,
      },
    },
    {
      name: ':zzz:',
      position: {
        x: 20,
        y: 15,
      },
    },
    {
      name: ':hankey:',
      position: {
        x: 20,
        y: 20,
      },
    },
    {
      name: ':smiling_imp:',
      position: {
        x: 26,
        y: 22,
      },
    },
    {
      name: ':imp:',
      position: {
        x: 18,
        y: 30,
      },
    },
    {
      name: ':japanese_ogre:',
      position: {
        x: 18,
        y: 19,
      },
    },
    {
      name: ':japanese_goblin:',
      position: {
        x: 18,
        y: 20,
      },
    },
    {
      name: ':skull:',
      position: {
        x: 18,
        y: 31,
      },
    },
    {
      name: ':ghost:',
      position: {
        x: 18,
        y: 21,
      },
    },
    {
      name: ':alien:',
      position: {
        x: 18,
        y: 28,
      },
    },
    {
      name: ':robot_face:',
      position: {
        x: 32,
        y: 6,
      },
    },
    {
      name: ':smiley_cat:',
      position: {
        x: 27,
        y: 31,
      },
    },
    {
      name: ':smile_cat:',
      position: {
        x: 27,
        y: 29,
      },
    },
    {
      name: ':joy_cat:',
      position: {
        x: 27,
        y: 30,
      },
    },
    {
      name: ':heart_eyes_cat:',
      position: {
        x: 27,
        y: 32,
      },
    },
    {
      name: ':smirk_cat:',
      position: {
        x: 27,
        y: 33,
      },
    },
    {
      name: ':kissing_cat:',
      position: {
        x: 27,
        y: 34,
      },
    },
    {
      name: ':scream_cat:',
      position: {
        x: 27,
        y: 37,
      },
    },
    {
      name: ':crying_cat_face:',
      position: {
        x: 27,
        y: 36,
      },
    },
    {
      name: ':pouting_cat:',
      position: {
        x: 27,
        y: 35,
      },
    },
    {
      name: ':raised_hands:',
      position: {
        x: 28,
        y: 28,
      },
    },
    {
      name: ':clap:',
      position: {
        x: 15,
        y: 20,
      },
    },
    {
      name: ':wave:',
      position: {
        x: 14,
        y: 37,
      },
    },
    {
      name: ':+1:',
      position: {
        x: 15,
        y: 8,
      },
    },
    {
      name: ':-1:',
      position: {
        x: 15,
        y: 14,
      },
    },
    {
      name: ':facepunch:',
      position: {
        x: 14,
        y: 31,
      },
    },
    {
      name: ':fist:',
      position: {
        x: 3,
        y: 8,
      },
    },
    {
      name: ':v:',
      position: {
        x: 3,
        y: 20,
      },
    },
    {
      name: ':ok_hand:',
      position: {
        x: 15,
        y: 2,
      },
    },
    {
      name: ':hand:',
      position: {
        x: 3,
        y: 14,
      },
    },
    {
      name: ':open_hands:',
      position: {
        x: 15,
        y: 26,
      },
    },
    {
      name: ':muscle:',
      position: {
        x: 20,
        y: 21,
      },
    },
    {
      name: ':pray:',
      position: {
        x: 29,
        y: 5,
      },
    },
    {
      name: ':point_up:',
      position: {
        x: 1,
        y: 9,
      },
    },
    {
      name: ':point_up_2:',
      position: {
        x: 14,
        y: 7,
      },
    },
    {
      name: ':point_down:',
      position: {
        x: 14,
        y: 13,
      },
    },
    {
      name: ':point_left:',
      position: {
        x: 14,
        y: 19,
      },
    },
    {
      name: ':point_right:',
      position: {
        x: 14,
        y: 25,
      },
    },
    {
      name: ':middle_finger:',
      position: {
        x: 25,
        y: 18,
      },
    },
    {
      name: ':raised_hand_with_fingers_splayed:',
      position: {
        x: 25,
        y: 12,
      },
    },
    {
      name: ':the_horns:',
      position: {
        x: 32,
        y: 8,
      },
    },
    {
      name: ':spock-hand:',
      position: {
        x: 25,
        y: 24,
      },
    },
    {
      name: ':writing_hand:',
      position: {
        x: 3,
        y: 26,
      },
    },
    {
      name: ':nail_care:',
      position: {
        x: 19,
        y: 10,
      },
    },
    {
      name: ':lips:',
      position: {
        x: 14,
        y: 5,
      },
    },
    {
      name: ':tongue:',
      position: {
        x: 14,
        y: 6,
      },
    },
    {
      name: ':ear:',
      position: {
        x: 13,
        y: 34,
      },
    },
    {
      name: ':nose:',
      position: {
        x: 13,
        y: 40,
      },
    },
    {
      name: ':eye:',
      position: {
        x: 13,
        y: 33,
      },
    },
    {
      name: ':eyes:',
      position: {
        x: 13,
        y: 32,
      },
    },
    {
      name: ':bust_in_silhouette:',
      position: {
        x: 16,
        y: 10,
      },
    },
    {
      name: ':busts_in_silhouette:',
      position: {
        x: 16,
        y: 11,
      },
    },
    {
      name: ':speaking_head_in_silhouette:',
      position: {
        x: 26,
        y: 4,
      },
    },
    {
      name: ':baby:',
      position: {
        x: 18,
        y: 1,
      },
    },
    {
      name: ':boy:',
      position: {
        x: 16,
        y: 12,
      },
    },
    {
      name: ':girl:',
      position: {
        x: 16,
        y: 18,
      },
    },
    {
      name: ':man:',
      position: {
        x: 16,
        y: 24,
      },
    },
    {
      name: ':woman:',
      position: {
        x: 16,
        y: 30,
      },
    },
    {
      name: ':person_with_blond_hair:',
      position: {
        x: 17,
        y: 12,
      },
    },
    {
      name: ':older_man:',
      position: {
        x: 17,
        y: 30,
      },
    },
    {
      name: ':older_woman:',
      position: {
        x: 17,
        y: 36,
      },
    },
    {
      name: ':man_with_gua_pi_mao:',
      position: {
        x: 17,
        y: 18,
      },
    },
    {
      name: ':man_with_turban:',
      position: {
        x: 17,
        y: 24,
      },
    },
    {
      name: ':cop:',
      position: {
        x: 16,
        y: 40,
      },
    },
    {
      name: ':construction_worker:',
      position: {
        x: 18,
        y: 7,
      },
    },
    {
      name: ':guardsman:',
      position: {
        x: 18,
        y: 38,
      },
    },
    {
      name: ':sleuth_or_spy:',
      position: {
        x: 25,
        y: 2,
      },
    },
    {
      name: ':santa:',
      position: {
        x: 8,
        y: 28,
      },
    },
    {
      name: ':angel:',
      position: {
        x: 18,
        y: 22,
      },
    },
    {
      name: ':princess:',
      position: {
        x: 18,
        y: 13,
      },
    },
    {
      name: ':bride_with_veil:',
      position: {
        x: 17,
        y: 6,
      },
    },
    {
      name: ':walking:',
      position: {
        x: 30,
        y: 39,
      },
    },
    {
      name: ':runner:',
      position: {
        x: 10,
        y: 8,
      },
    },
    {
      name: ':dancer:',
      position: {
        x: 19,
        y: 3,
      },
    },
    {
      name: ':dancers:',
      position: {
        x: 17,
        y: 5,
      },
    },
    {
      name: ':couple:',
      position: {
        x: 16,
        y: 37,
      },
    },
    {
      name: ':two_men_holding_hands:',
      position: {
        x: 16,
        y: 38,
      },
    },
    {
      name: ':two_women_holding_hands:',
      position: {
        x: 16,
        y: 39,
      },
    },
    {
      name: ':bow:',
      position: {
        x: 28,
        y: 13,
      },
    },
    {
      name: ':information_desk_person:',
      position: {
        x: 18,
        y: 32,
      },
    },
    {
      name: ':no_good:',
      position: {
        x: 28,
        y: 1,
      },
    },
    {
      name: ':ok_woman:',
      position: {
        x: 28,
        y: 7,
      },
    },
    {
      name: ':raising_hand:',
      position: {
        x: 28,
        y: 22,
      },
    },
    {
      name: ':person_with_pouting_face:',
      position: {
        x: 28,
        y: 40,
      },
    },
    {
      name: ':person_frowning:',
      position: {
        x: 28,
        y: 34,
      },
    },
    {
      name: ':haircut:',
      position: {
        x: 19,
        y: 22,
      },
    },
    {
      name: ':massage:',
      position: {
        x: 19,
        y: 16,
      },
    },
    {
      name: ':couple_with_heart:',
      position: {
        x: 19,
        y: 37,
      },
    },
    {
      name: ':woman-heart-woman:',
      position: {
        x: 39,
        y: 18,
      },
    },
    {
      name: ':man-heart-man:',
      position: {
        x: 39,
        y: 11,
      },
    },
    {
      name: ':couplekiss:',
      position: {
        x: 19,
        y: 35,
      },
    },
    {
      name: ':woman-kiss-woman:',
      position: {
        x: 39,
        y: 19,
      },
    },
    {
      name: ':man-kiss-man:',
      position: {
        x: 39,
        y: 12,
      },
    },
    {
      name: ':family:',
      position: {
        x: 16,
        y: 36,
      },
    },
    {
      name: ':man-woman-girl:',
      position: {
        x: 39,
        y: 8,
      },
    },
    {
      name: ':man-woman-girl-boy:',
      position: {
        x: 39,
        y: 9,
      },
    },
    {
      name: ':man-woman-boy-boy:',
      position: {
        x: 39,
        y: 7,
      },
    },
    {
      name: ':man-woman-girl-girl:',
      position: {
        x: 39,
        y: 10,
      },
    },
    {
      name: ':woman-woman-boy:',
      position: {
        x: 39,
        y: 13,
      },
    },
    {
      name: ':woman-woman-girl:',
      position: {
        x: 39,
        y: 15,
      },
    },
    {
      name: ':woman-woman-girl-boy:',
      position: {
        x: 39,
        y: 16,
      },
    },
    {
      name: ':woman-woman-boy-boy:',
      position: {
        x: 39,
        y: 14,
      },
    },
    {
      name: ':woman-woman-girl-girl:',
      position: {
        x: 39,
        y: 17,
      },
    },
    {
      name: ':man-man-boy:',
      position: {
        x: 39,
        y: 2,
      },
    },
    {
      name: ':man-man-girl:',
      position: {
        x: 39,
        y: 4,
      },
    },
    {
      name: ':man-man-girl-boy:',
      position: {
        x: 39,
        y: 5,
      },
    },
    {
      name: ':man-man-boy-boy:',
      position: {
        x: 39,
        y: 3,
      },
    },
    {
      name: ':man-man-girl-girl:',
      position: {
        x: 39,
        y: 6,
      },
    },
    {
      name: ':womans_clothes:',
      position: {
        x: 16,
        y: 0,
      },
    },
    {
      name: ':shirt:',
      position: {
        x: 15,
        y: 36,
      },
    },
    {
      name: ':jeans:',
      position: {
        x: 15,
        y: 37,
      },
    },
    {
      name: ':necktie:',
      position: {
        x: 15,
        y: 35,
      },
    },
    {
      name: ':dress:',
      position: {
        x: 15,
        y: 38,
      },
    },
    {
      name: ':bikini:',
      position: {
        x: 15,
        y: 40,
      },
    },
    {
      name: ':kimono:',
      position: {
        x: 15,
        y: 39,
      },
    },
    {
      name: ':lipstick:',
      position: {
        x: 19,
        y: 9,
      },
    },
    {
      name: ':kiss:',
      position: {
        x: 19,
        y: 31,
      },
    },
    {
      name: ':footprints:',
      position: {
        x: 16,
        y: 9,
      },
    },
    {
      name: ':high_heel:',
      position: {
        x: 16,
        y: 6,
      },
    },
    {
      name: ':sandal:',
      position: {
        x: 16,
        y: 7,
      },
    },
    {
      name: ':boot:',
      position: {
        x: 16,
        y: 8,
      },
    },
    {
      name: ':mans_shoe:',
      position: {
        x: 16,
        y: 4,
      },
    },
    {
      name: ':athletic_shoe:',
      position: {
        x: 16,
        y: 5,
      },
    },
    {
      name: ':womans_hat:',
      position: {
        x: 15,
        y: 33,
      },
    },
    {
      name: ':tophat:',
      position: {
        x: 9,
        y: 23,
      },
    },
    {
      name: ':helmet_with_white_cross:',
      position: {
        x: 2,
        y: 24,
      },
    },
    {
      name: ':mortar_board:',
      position: {
        x: 9,
        y: 6,
      },
    },
    {
      name: ':crown:',
      position: {
        x: 15,
        y: 32,
      },
    },
    {
      name: ':school_satchel:',
      position: {
        x: 9,
        y: 5,
      },
    },
    {
      name: ':pouch:',
      position: {
        x: 16,
        y: 3,
      },
    },
    {
      name: ':purse:',
      position: {
        x: 16,
        y: 1,
      },
    },
    {
      name: ':handbag:',
      position: {
        x: 16,
        y: 2,
      },
    },
    {
      name: ':briefcase:',
      position: {
        x: 21,
        y: 3,
      },
    },
    {
      name: ':eyeglasses:',
      position: {
        x: 15,
        y: 34,
      },
    },
    {
      name: ':dark_sunglasses:',
      position: {
        x: 25,
        y: 3,
      },
    },
    {
      name: ':ring:',
      position: {
        x: 19,
        y: 33,
      },
    },
    {
      name: ':closed_umbrella:',
      position: {
        x: 5,
        y: 22,
      },
    },
  ],
  [EMOJI_GROUP.NATURE]: [
    {
      name: ':dog:',
      position: {
        x: 13,
        y: 22,
      },
    },
    {
      name: ':cat:',
      position: {
        x: 13,
        y: 17,
      },
    },
    {
      name: ':mouse:',
      position: {
        x: 13,
        y: 13,
      },
    },
    {
      name: ':hamster:',
      position: {
        x: 13,
        y: 25,
      },
    },
    {
      name: ':rabbit:',
      position: {
        x: 13,
        y: 16,
      },
    },
    {
      name: ':bear:',
      position: {
        x: 13,
        y: 27,
      },
    },
    {
      name: ':panda_face:',
      position: {
        x: 13,
        y: 28,
      },
    },
    {
      name: ':koala:',
      position: {
        x: 13,
        y: 8,
      },
    },
    {
      name: ':tiger:',
      position: {
        x: 13,
        y: 15,
      },
    },
    {
      name: ':lion_face:',
      position: {
        x: 32,
        y: 15,
      },
    },
    {
      name: ':cow:',
      position: {
        x: 13,
        y: 14,
      },
    },
    {
      name: ':pig:',
      position: {
        x: 13,
        y: 23,
      },
    },
    {
      name: ':pig_nose:',
      position: {
        x: 13,
        y: 29,
      },
    },
    {
      name: ':frog:',
      position: {
        x: 13,
        y: 24,
      },
    },
    {
      name: ':octopus:',
      position: {
        x: 12,
        y: 34,
      },
    },
    {
      name: ':monkey_face:',
      position: {
        x: 13,
        y: 21,
      },
    },
    {
      name: ':see_no_evil:',
      position: {
        x: 28,
        y: 19,
      },
    },
    {
      name: ':hear_no_evil:',
      position: {
        x: 28,
        y: 20,
      },
    },
    {
      name: ':speak_no_evil:',
      position: {
        x: 28,
        y: 21,
      },
    },
    {
      name: ':monkey:',
      position: {
        x: 12,
        y: 27,
      },
    },
    {
      name: ':chicken:',
      position: {
        x: 12,
        y: 29,
      },
    },
    {
      name: ':penguin:',
      position: {
        x: 13,
        y: 7,
      },
    },
    {
      name: ':bird:',
      position: {
        x: 13,
        y: 6,
      },
    },
    {
      name: ':baby_chick:',
      position: {
        x: 13,
        y: 4,
      },
    },
    {
      name: ':hatching_chick:',
      position: {
        x: 13,
        y: 3,
      },
    },
    {
      name: ':hatched_chick:',
      position: {
        x: 13,
        y: 5,
      },
    },
    {
      name: ':wolf:',
      position: {
        x: 13,
        y: 26,
      },
    },
    {
      name: ':boar:',
      position: {
        x: 12,
        y: 32,
      },
    },
    {
      name: ':horse:',
      position: {
        x: 13,
        y: 20,
      },
    },
    {
      name: ':unicorn_face:',
      position: {
        x: 32,
        y: 18,
      },
    },
    {
      name: ':bee:',
      position: {
        x: 12,
        y: 38,
      },
    },
    {
      name: ':bug:',
      position: {
        x: 12,
        y: 36,
      },
    },
    {
      name: ':snail:',
      position: {
        x: 12,
        y: 21,
      },
    },
    {
      name: ':beetle:',
      position: {
        x: 12,
        y: 39,
      },
    },
    {
      name: ':ant:',
      position: {
        x: 12,
        y: 37,
      },
    },
    {
      name: ':spider:',
      position: {
        x: 25,
        y: 4,
      },
    },
    {
      name: ':scorpion:',
      position: {
        x: 32,
        y: 16,
      },
    },
    {
      name: ':crab:',
      position: {
        x: 32,
        y: 14,
      },
    },
    {
      name: ':snake:',
      position: {
        x: 12,
        y: 22,
      },
    },
    {
      name: ':turtle:',
      position: {
        x: 13,
        y: 2,
      },
    },
    {
      name: ':tropical_fish:',
      position: {
        x: 13,
        y: 0,
      },
    },
    {
      name: ':fish:',
      position: {
        x: 12,
        y: 40,
      },
    },
    {
      name: ':blowfish:',
      position: {
        x: 13,
        y: 1,
      },
    },
    {
      name: ':dolphin:',
      position: {
        x: 13,
        y: 12,
      },
    },
    {
      name: ':whale:',
      position: {
        x: 13,
        y: 19,
      },
    },
    {
      name: ':whale2:',
      position: {
        x: 12,
        y: 20,
      },
    },
    {
      name: ':crocodile:',
      position: {
        x: 12,
        y: 19,
      },
    },
    {
      name: ':leopard:',
      position: {
        x: 12,
        y: 15,
      },
    },
    {
      name: ':tiger2:',
      position: {
        x: 12,
        y: 14,
      },
    },
    {
      name: ':water_buffalo:',
      position: {
        x: 12,
        y: 12,
      },
    },
    {
      name: ':ox:',
      position: {
        x: 12,
        y: 11,
      },
    },
    {
      name: ':cow2:',
      position: {
        x: 12,
        y: 13,
      },
    },
    {
      name: ':dromedary_camel:',
      position: {
        x: 13,
        y: 10,
      },
    },
    {
      name: ':camel:',
      position: {
        x: 13,
        y: 11,
      },
    },
    {
      name: ':elephant:',
      position: {
        x: 12,
        y: 33,
      },
    },
    {
      name: ':goat:',
      position: {
        x: 12,
        y: 25,
      },
    },
    {
      name: ':ram:',
      position: {
        x: 12,
        y: 24,
      },
    },
    {
      name: ':sheep:',
      position: {
        x: 12,
        y: 26,
      },
    },
    {
      name: ':racehorse:',
      position: {
        x: 12,
        y: 23,
      },
    },
    {
      name: ':pig2:',
      position: {
        x: 12,
        y: 31,
      },
    },
    {
      name: ':rat:',
      position: {
        x: 12,
        y: 9,
      },
    },
    {
      name: ':mouse2:',
      position: {
        x: 12,
        y: 10,
      },
    },
    {
      name: ':rooster:',
      position: {
        x: 12,
        y: 28,
      },
    },
    {
      name: ':turkey:',
      position: {
        x: 32,
        y: 17,
      },
    },
    {
      name: ':dove_of_peace:',
      position: {
        x: 24,
        y: 10,
      },
    },
    {
      name: ':dog2:',
      position: {
        x: 12,
        y: 30,
      },
    },
    {
      name: ':poodle:',
      position: {
        x: 13,
        y: 9,
      },
    },
    {
      name: ':cat2:',
      position: {
        x: 12,
        y: 17,
      },
    },
    {
      name: ':rabbit2:',
      position: {
        x: 12,
        y: 16,
      },
    },
    {
      name: ':chipmunk:',
      position: {
        x: 13,
        y: 31,
      },
    },
    {
      name: ':feet:',
      position: {
        x: 13,
        y: 30,
      },
    },
    {
      name: ':dragon:',
      position: {
        x: 12,
        y: 18,
      },
    },
    {
      name: ':dragon_face:',
      position: {
        x: 13,
        y: 18,
      },
    },
    {
      name: ':cactus:',
      position: {
        x: 6,
        y: 30,
      },
    },
    {
      name: ':christmas_tree:',
      position: {
        x: 8,
        y: 27,
      },
    },
    {
      name: ':evergreen_tree:',
      position: {
        x: 6,
        y: 27,
      },
    },
    {
      name: ':deciduous_tree:',
      position: {
        x: 6,
        y: 28,
      },
    },
    {
      name: ':palm_tree:',
      position: {
        x: 6,
        y: 29,
      },
    },
    {
      name: ':seedling:',
      position: {
        x: 6,
        y: 26,
      },
    },
    {
      name: ':herb:',
      position: {
        x: 6,
        y: 40,
      },
    },
    {
      name: ':shamrock:',
      position: {
        x: 1,
        y: 8,
      },
    },
    {
      name: ':four_leaf_clover:',
      position: {
        x: 7,
        y: 0,
      },
    },
    {
      name: ':bamboo:',
      position: {
        x: 9,
        y: 0,
      },
    },
    {
      name: ':tanabata_tree:',
      position: {
        x: 8,
        y: 39,
      },
    },
    {
      name: ':leaves:',
      position: {
        x: 7,
        y: 3,
      },
    },
    {
      name: ':fallen_leaf:',
      position: {
        x: 7,
        y: 2,
      },
    },
    {
      name: ':maple_leaf:',
      position: {
        x: 7,
        y: 1,
      },
    },
    {
      name: ':ear_of_rice:',
      position: {
        x: 6,
        y: 39,
      },
    },
    {
      name: ':hibiscus:',
      position: {
        x: 6,
        y: 35,
      },
    },
    {
      name: ':sunflower:',
      position: {
        x: 6,
        y: 36,
      },
    },
    {
      name: ':rose:',
      position: {
        x: 6,
        y: 34,
      },
    },
    {
      name: ':tulip:',
      position: {
        x: 6,
        y: 32,
      },
    },
    {
      name: ':blossom:',
      position: {
        x: 6,
        y: 37,
      },
    },
    {
      name: ':cherry_blossom:',
      position: {
        x: 6,
        y: 33,
      },
    },
    {
      name: ':bouquet:',
      position: {
        x: 19,
        y: 36,
      },
    },
    {
      name: ':mushroom:',
      position: {
        x: 7,
        y: 4,
      },
    },
    {
      name: ':chestnut:',
      position: {
        x: 6,
        y: 25,
      },
    },
    {
      name: ':jack_o_lantern:',
      position: {
        x: 8,
        y: 26,
      },
    },
    {
      name: ':shell:',
      position: {
        x: 12,
        y: 35,
      },
    },
    {
      name: ':spider_web:',
      position: {
        x: 25,
        y: 5,
      },
    },
    {
      name: ':earth_americas:',
      position: {
        x: 5,
        y: 34,
      },
    },
    {
      name: ':earth_africa:',
      position: {
        x: 5,
        y: 33,
      },
    },
    {
      name: ':earth_asia:',
      position: {
        x: 5,
        y: 35,
      },
    },
    {
      name: ':full_moon:',
      position: {
        x: 6,
        y: 0,
      },
    },
    {
      name: ':waning_gibbous_moon:',
      position: {
        x: 6,
        y: 1,
      },
    },
    {
      name: ':last_quarter_moon:',
      position: {
        x: 6,
        y: 2,
      },
    },
    {
      name: ':waning_crescent_moon:',
      position: {
        x: 6,
        y: 3,
      },
    },
    {
      name: ':new_moon:',
      position: {
        x: 5,
        y: 37,
      },
    },
    {
      name: ':waxing_crescent_moon:',
      position: {
        x: 5,
        y: 38,
      },
    },
    {
      name: ':first_quarter_moon:',
      position: {
        x: 5,
        y: 39,
      },
    },
    {
      name: ':moon:',
      position: {
        x: 5,
        y: 40,
      },
    },
    {
      name: ':new_moon_with_face:',
      position: {
        x: 6,
        y: 5,
      },
    },
    {
      name: ':full_moon_with_face:',
      position: {
        x: 6,
        y: 8,
      },
    },
    {
      name: ':first_quarter_moon_with_face:',
      position: {
        x: 6,
        y: 6,
      },
    },
    {
      name: ':last_quarter_moon_with_face:',
      position: {
        x: 6,
        y: 7,
      },
    },
    {
      name: ':sun_with_face:',
      position: {
        x: 6,
        y: 9,
      },
    },
    {
      name: ':crescent_moon:',
      position: {
        x: 6,
        y: 4,
      },
    },
    {
      name: ':star:',
      position: {
        x: 4,
        y: 23,
      },
    },
    {
      name: ':star2:',
      position: {
        x: 6,
        y: 10,
      },
    },
    {
      name: ':dizzy:',
      position: {
        x: 20,
        y: 27,
      },
    },
    {
      name: ':sparkles:',
      position: {
        x: 3,
        y: 38,
      },
    },
    {
      name: ':comet:',
      position: {
        x: 1,
        y: 3,
      },
    },
    {
      name: ':sunny:',
      position: {
        x: 0,
        y: 40,
      },
    },
    {
      name: ':mostly_sunny:',
      position: {
        x: 6,
        y: 13,
      },
    },
    {
      name: ':partly_sunny:',
      position: {
        x: 2,
        y: 20,
      },
    },
    {
      name: ':barely_sunny:',
      position: {
        x: 6,
        y: 14,
      },
    },
    {
      name: ':partly_sunny_rain:',
      position: {
        x: 6,
        y: 15,
      },
    },
    {
      name: ':cloud:',
      position: {
        x: 1,
        y: 0,
      },
    },
    {
      name: ':rain_cloud:',
      position: {
        x: 6,
        y: 16,
      },
    },
    {
      name: ':thunder_cloud_and_rain:',
      position: {
        x: 2,
        y: 21,
      },
    },
    {
      name: ':lightning:',
      position: {
        x: 6,
        y: 18,
      },
    },
    {
      name: ':zap:',
      position: {
        x: 2,
        y: 12,
      },
    },
    {
      name: ':fire:',
      position: {
        x: 23,
        y: 25,
      },
    },
    {
      name: ':boom:',
      position: {
        x: 20,
        y: 16,
      },
    },
    {
      name: ':snowflake:',
      position: {
        x: 4,
        y: 0,
      },
    },
    {
      name: ':snow_cloud:',
      position: {
        x: 6,
        y: 17,
      },
    },
    {
      name: ':showman:',
      position: {
        x: 1,
        y: 2,
      },
    },
    {
      name: ':snowman:',
      position: {
        x: 2,
        y: 19,
      },
    },
    {
      name: ':wind_blowing_face:',
      position: {
        x: 6,
        y: 21,
      },
    },
    {
      name: ':dash:',
      position: {
        x: 20,
        y: 19,
      },
    },
    {
      name: ':tornado:',
      position: {
        x: 6,
        y: 19,
      },
    },
    {
      name: ':fog:',
      position: {
        x: 6,
        y: 20,
      },
    },
    {
      name: ':umbrella:',
      position: {
        x: 1,
        y: 1,
      },
    },
    {
      name: ':droplet:',
      position: {
        x: 20,
        y: 18,
      },
    },
    {
      name: ':sweat_drops:',
      position: {
        x: 20,
        y: 17,
      },
    },
    {
      name: ':ocean:',
      position: {
        x: 5,
        y: 30,
      },
    },
  ],
  [EMOJI_GROUP.FOOD]: [
    {
      name: ':green_apple:',
      position: {
        x: 7,
        y: 15,
      },
    },
    {
      name: ':apple:',
      position: {
        x: 7,
        y: 14,
      },
    },
    {
      name: ':pear:',
      position: {
        x: 7,
        y: 16,
      },
    },
    {
      name: ':tangerine:',
      position: {
        x: 7,
        y: 10,
      },
    },
    {
      name: ':lemon:',
      position: {
        x: 7,
        y: 11,
      },
    },
    {
      name: ':banana:',
      position: {
        x: 7,
        y: 12,
      },
    },
    {
      name: ':watermelon:',
      position: {
        x: 7,
        y: 9,
      },
    },
    {
      name: ':grapes:',
      position: {
        x: 7,
        y: 7,
      },
    },
    {
      name: ':strawberry:',
      position: {
        x: 7,
        y: 19,
      },
    },
    {
      name: ':melon:',
      position: {
        x: 7,
        y: 8,
      },
    },
    {
      name: ':cherries:',
      position: {
        x: 7,
        y: 18,
      },
    },
    {
      name: ':peach:',
      position: {
        x: 7,
        y: 17,
      },
    },
    {
      name: ':pineapple:',
      position: {
        x: 7,
        y: 13,
      },
    },
    {
      name: ':tomato:',
      position: {
        x: 7,
        y: 5,
      },
    },
    {
      name: ':eggplant:',
      position: {
        x: 7,
        y: 6,
      },
    },
    {
      name: ':hot_pepper:',
      position: {
        x: 6,
        y: 31,
      },
    },
    {
      name: ':corn:',
      position: {
        x: 6,
        y: 38,
      },
    },
    {
      name: ':sweet_potato:',
      position: {
        x: 7,
        y: 32,
      },
    },
    {
      name: ':honey_pot:',
      position: {
        x: 8,
        y: 6,
      },
    },
    {
      name: ':bread:',
      position: {
        x: 7,
        y: 30,
      },
    },
    {
      name: ':cheese_wedge:',
      position: {
        x: 32,
        y: 19,
      },
    },
    {
      name: ':poultry_leg:',
      position: {
        x: 7,
        y: 23,
      },
    },
    {
      name: ':meat_on_bone:',
      position: {
        x: 7,
        y: 22,
      },
    },
    {
      name: ':fried_shrimp:',
      position: {
        x: 7,
        y: 36,
      },
    },
    {
      name: ':egg:',
      position: {
        x: 8,
        y: 10,
      },
    },
    {
      name: ':hamburger:',
      position: {
        x: 7,
        y: 20,
      },
    },
    {
      name: ':fries:',
      position: {
        x: 7,
        y: 31,
      },
    },
    {
      name: ':hotdog:',
      position: {
        x: 6,
        y: 22,
      },
    },
    {
      name: ':pizza:',
      position: {
        x: 7,
        y: 21,
      },
    },
    {
      name: ':spaghetti:',
      position: {
        x: 7,
        y: 29,
      },
    },
    {
      name: ':taco:',
      position: {
        x: 6,
        y: 23,
      },
    },
    {
      name: ':burrito:',
      position: {
        x: 6,
        y: 24,
      },
    },
    {
      name: ':ramen:',
      position: {
        x: 7,
        y: 28,
      },
    },
    {
      name: ':stew:',
      position: {
        x: 8,
        y: 9,
      },
    },
    {
      name: ':fish_cake:',
      position: {
        x: 7,
        y: 37,
      },
    },
    {
      name: ':sushi:',
      position: {
        x: 7,
        y: 35,
      },
    },
    {
      name: ':bento:',
      position: {
        x: 8,
        y: 8,
      },
    },
    {
      name: ':curry:',
      position: {
        x: 7,
        y: 27,
      },
    },
    {
      name: ':rice_ball:',
      position: {
        x: 7,
        y: 25,
      },
    },
    {
      name: ':rice:',
      position: {
        x: 7,
        y: 26,
      },
    },
    {
      name: ':rice_cracker:',
      position: {
        x: 7,
        y: 24,
      },
    },
    {
      name: ':oden:',
      position: {
        x: 7,
        y: 34,
      },
    },
    {
      name: ':dango:',
      position: {
        x: 7,
        y: 33,
      },
    },
    {
      name: ':shaved_ice:',
      position: {
        x: 7,
        y: 39,
      },
    },
    {
      name: ':ice_cream:',
      position: {
        x: 7,
        y: 40,
      },
    },
    {
      name: ':icecream:',
      position: {
        x: 7,
        y: 38,
      },
    },
    {
      name: ':cake:',
      position: {
        x: 8,
        y: 7,
      },
    },
    {
      name: ':birthday:',
      position: {
        x: 8,
        y: 25,
      },
    },
    {
      name: ':custard:',
      position: {
        x: 8,
        y: 5,
      },
    },
    {
      name: ':candy:',
      position: {
        x: 8,
        y: 3,
      },
    },
    {
      name: ':lollipop:',
      position: {
        x: 8,
        y: 4,
      },
    },
    {
      name: ':chocolate_bar:',
      position: {
        x: 8,
        y: 2,
      },
    },
    {
      name: ':popcorn:',
      position: {
        x: 8,
        y: 22,
      },
    },
    {
      name: ':doughnut:',
      position: {
        x: 8,
        y: 0,
      },
    },
    {
      name: ':cookie:',
      position: {
        x: 8,
        y: 1,
      },
    },
    {
      name: ':beer:',
      position: {
        x: 8,
        y: 17,
      },
    },
    {
      name: ':beers:',
      position: {
        x: 8,
        y: 18,
      },
    },
    {
      name: ':wine_glass:',
      position: {
        x: 8,
        y: 14,
      },
    },
    {
      name: ':cocktail:',
      position: {
        x: 8,
        y: 15,
      },
    },
    {
      name: ':tropical_drink:',
      position: {
        x: 8,
        y: 16,
      },
    },
    {
      name: ':champagne:',
      position: {
        x: 8,
        y: 21,
      },
    },
    {
      name: ':sake:',
      position: {
        x: 8,
        y: 13,
      },
    },
    {
      name: ':tea:',
      position: {
        x: 8,
        y: 12,
      },
    },
    {
      name: ':coffee:',
      position: {
        x: 1,
        y: 7,
      },
    },
    {
      name: ':baby_bottle:',
      position: {
        x: 8,
        y: 19,
      },
    },
    {
      name: ':fork_and_knife:',
      position: {
        x: 8,
        y: 11,
      },
    },
    {
      name: ':knife_fork_plate:',
      position: {
        x: 8,
        y: 20,
      },
    },
  ],
  [EMOJI_GROUP.ACTIVITY]: [
    {
      name: ':soccer:',
      position: {
        x: 2,
        y: 17,
      },
    },
    {
      name: ':basketball:',
      position: {
        x: 10,
        y: 5,
      },
    },
    {
      name: ':football:',
      position: {
        x: 10,
        y: 28,
      },
    },
    {
      name: ':baseball:',
      position: {
        x: 2,
        y: 18,
      },
    },
    {
      name: ':tennis:',
      position: {
        x: 10,
        y: 3,
      },
    },
    {
      name: ':volleyball:',
      position: {
        x: 11,
        y: 5,
      },
    },
    {
      name: ':rugby_football:',
      position: {
        x: 10,
        y: 29,
      },
    },
    {
      name: ':8ball:',
      position: {
        x: 9,
        y: 31,
      },
    },
    {
      name: ':golf:',
      position: {
        x: 2,
        y: 32,
      },
    },
    {
      name: ':golfer:',
      position: {
        x: 11,
        y: 1,
      },
    },
    {
      name: ':table_tennis_paddle_and_ball:',
      position: {
        x: 11,
        y: 8,
      },
    },
    {
      name: ':badminton_racquet_and_shuttlecock:',
      position: {
        x: 12,
        y: 1,
      },
    },
    {
      name: ':ice_hockey_stick_and_puck:',
      position: {
        x: 11,
        y: 7,
      },
    },
    {
      name: ':field_hockey_stick_and_ball:',
      position: {
        x: 11,
        y: 6,
      },
    },
    {
      name: ':cricket_bat_and_ball:',
      position: {
        x: 11,
        y: 4,
      },
    },
    {
      name: ':ski:',
      position: {
        x: 10,
        y: 4,
      },
    },
    {
      name: ':skier:',
      position: {
        x: 2,
        y: 35,
      },
    },
    {
      name: ':snowboarder:',
      position: {
        x: 10,
        y: 7,
      },
    },
    {
      name: ':ice_skate:',
      position: {
        x: 2,
        y: 36,
      },
    },
    {
      name: ':bow_and_arrow:',
      position: {
        x: 12,
        y: 2,
      },
    },
    {
      name: ':fishing_pole_and_fish:',
      position: {
        x: 9,
        y: 17,
      },
    },
    {
      name: ':rowboat:',
      position: {
        x: 30,
        y: 5,
      },
    },
    {
      name: ':swimmer:',
      position: {
        x: 10,
        y: 30,
      },
    },
    {
      name: ':surfer:',
      position: {
        x: 10,
        y: 14,
      },
    },
    {
      name: ':bath:',
      position: {
        x: 31,
        y: 13,
      },
    },
    {
      name: ':person_with_ball:',
      position: {
        x: 2,
        y: 37,
      },
    },
    {
      name: ':weight_lifter:',
      position: {
        x: 10,
        y: 36,
      },
    },
    {
      name: ':bicyclist:',
      position: {
        x: 30,
        y: 27,
      },
    },
    {
      name: ':mountain_bicyclist:',
      position: {
        x: 30,
        y: 33,
      },
    },
    {
      name: ':horse_racing:',
      position: {
        x: 10,
        y: 22,
      },
    },
    {
      name: ':man_in_business_suit_levitating:',
      position: {
        x: 25,
        y: 1,
      },
    },
    {
      name: ':trophy:',
      position: {
        x: 10,
        y: 21,
      },
    },
    {
      name: ':running_shirt_with_sash:',
      position: {
        x: 10,
        y: 2,
      },
    },
    {
      name: ':sports_medal:',
      position: {
        x: 10,
        y: 20,
      },
    },
    {
      name: ':medal:',
      position: {
        x: 9,
        y: 7,
      },
    },
    {
      name: ':reminder_ribbon:',
      position: {
        x: 9,
        y: 8,
      },
    },
    {
      name: ':rosette:',
      position: {
        x: 11,
        y: 40,
      },
    },
    {
      name: ':ticket:',
      position: {
        x: 9,
        y: 25,
      },
    },
    {
      name: ':admission_tickets:',
      position: {
        x: 9,
        y: 13,
      },
    },
    {
      name: ':performing_arts:',
      position: {
        x: 9,
        y: 27,
      },
    },
    {
      name: ':art:',
      position: {
        x: 9,
        y: 22,
      },
    },
    {
      name: ':circus_tent:',
      position: {
        x: 9,
        y: 24,
      },
    },
    {
      name: ':microphone:',
      position: {
        x: 9,
        y: 18,
      },
    },
    {
      name: ':headphones:',
      position: {
        x: 9,
        y: 21,
      },
    },
    {
      name: ':musical_score:',
      position: {
        x: 10,
        y: 1,
      },
    },
    {
      name: ':musical_keyboard:',
      position: {
        x: 9,
        y: 39,
      },
    },
    {
      name: ':saxophone:',
      position: {
        x: 9,
        y: 37,
      },
    },
    {
      name: ':trumpet:',
      position: {
        x: 9,
        y: 40,
      },
    },
    {
      name: ':guitar:',
      position: {
        x: 9,
        y: 38,
      },
    },
    {
      name: ':violin:',
      position: {
        x: 10,
        y: 0,
      },
    },
    {
      name: ':clapper:',
      position: {
        x: 9,
        y: 26,
      },
    },
    {
      name: ':video_game:',
      position: {
        x: 9,
        y: 28,
      },
    },
    {
      name: ':space_invader:',
      position: {
        x: 18,
        y: 29,
      },
    },
    {
      name: ':dart:',
      position: {
        x: 9,
        y: 29,
      },
    },
    {
      name: ':game_die:',
      position: {
        x: 9,
        y: 32,
      },
    },
    {
      name: ':slot_machine:',
      position: {
        x: 9,
        y: 30,
      },
    },
    {
      name: ':bowling:',
      position: {
        x: 9,
        y: 33,
      },
    },
  ],
  [EMOJI_GROUP.PLACES]: [
    {
      name: ':car:',
      position: {
        x: 29,
        y: 34,
      },
    },
    {
      name: ':taxi:',
      position: {
        x: 29,
        y: 32,
      },
    },
    {
      name: ':blue_car:',
      position: {
        x: 29,
        y: 36,
      },
    },
    {
      name: ':bus:',
      position: {
        x: 29,
        y: 23,
      },
    },
    {
      name: ':trolleybus:',
      position: {
        x: 29,
        y: 25,
      },
    },
    {
      name: ':racing_car:',
      position: {
        x: 11,
        y: 3,
      },
    },
    {
      name: ':police_car:',
      position: {
        x: 29,
        y: 30,
      },
    },
    {
      name: ':ambulance:',
      position: {
        x: 29,
        y: 28,
      },
    },
    {
      name: ':fire_engine:',
      position: {
        x: 29,
        y: 29,
      },
    },
    {
      name: ':minibus:',
      position: {
        x: 29,
        y: 27,
      },
    },
    {
      name: ':truck:',
      position: {
        x: 29,
        y: 37,
      },
    },
    {
      name: ':articulated_lorry:',
      position: {
        x: 29,
        y: 38,
      },
    },
    {
      name: ':tractor:',
      position: {
        x: 29,
        y: 39,
      },
    },
    {
      name: ':racing_motorcycle:',
      position: {
        x: 11,
        y: 2,
      },
    },
    {
      name: ':bike:',
      position: {
        x: 30,
        y: 25,
      },
    },
    {
      name: ':rotating_light:',
      position: {
        x: 30,
        y: 15,
      },
    },
    {
      name: ':oncoming_police_car:',
      position: {
        x: 29,
        y: 31,
      },
    },
    {
      name: ':oncoming_bus:',
      position: {
        x: 29,
        y: 24,
      },
    },
    {
      name: ':oncoming_automobile:',
      position: {
        x: 29,
        y: 35,
      },
    },
    {
      name: ':oncoming_taxi:',
      position: {
        x: 29,
        y: 33,
      },
    },
    {
      name: ':aerial_tramway:',
      position: {
        x: 30,
        y: 3,
      },
    },
    {
      name: ':mountain_cableway:',
      position: {
        x: 30,
        y: 2,
      },
    },
    {
      name: ':suspension_railway:',
      position: {
        x: 30,
        y: 1,
      },
    },
    {
      name: ':railway_car:',
      position: {
        x: 29,
        y: 14,
      },
    },
    {
      name: ':train:',
      position: {
        x: 29,
        y: 22,
      },
    },
    {
      name: ':monorail:',
      position: {
        x: 29,
        y: 40,
      },
    },
    {
      name: ':bullettrain_side:',
      position: {
        x: 29,
        y: 15,
      },
    },
    {
      name: ':bullettrain_front:',
      position: {
        x: 29,
        y: 16,
      },
    },
    {
      name: ':light_rail:',
      position: {
        x: 29,
        y: 19,
      },
    },
    {
      name: ':mountain_railway:',
      position: {
        x: 30,
        y: 0,
      },
    },
    {
      name: ':steam_locomotive:',
      position: {
        x: 29,
        y: 13,
      },
    },
    {
      name: ':train2:',
      position: {
        x: 29,
        y: 17,
      },
    },
    {
      name: ':metro:',
      position: {
        x: 29,
        y: 18,
      },
    },
    {
      name: ':tram:',
      position: {
        x: 29,
        y: 21,
      },
    },
    {
      name: ':station:',
      position: {
        x: 29,
        y: 20,
      },
    },
    {
      name: ':helicopter:',
      position: {
        x: 29,
        y: 12,
      },
    },
    {
      name: ':small_airplane:',
      position: {
        x: 31,
        y: 36,
      },
    },
    {
      name: ':airplane:',
      position: {
        x: 3,
        y: 6,
      },
    },
    {
      name: ':airplane_departure:',
      position: {
        x: 31,
        y: 37,
      },
    },
    {
      name: ':airplane_arriving:',
      position: {
        x: 31,
        y: 38,
      },
    },
    {
      name: ':boat:',
      position: {
        x: 2,
        y: 34,
      },
    },
    {
      name: ':motor_boat:',
      position: {
        x: 31,
        y: 35,
      },
    },
    {
      name: ':speedboat:',
      position: {
        x: 30,
        y: 11,
      },
    },
    {
      name: ':ferry:',
      position: {
        x: 2,
        y: 33,
      },
    },
    {
      name: ':passenger_ship:',
      position: {
        x: 31,
        y: 40,
      },
    },
    {
      name: ':rocket:',
      position: {
        x: 29,
        y: 11,
      },
    },
    {
      name: ':satellite:',
      position: {
        x: 31,
        y: 39,
      },
    },
    {
      name: ':seat:',
      position: {
        x: 21,
        y: 1,
      },
    },
    {
      name: ':anchor:',
      position: {
        x: 2,
        y: 4,
      },
    },
    {
      name: ':construction:',
      position: {
        x: 30,
        y: 14,
      },
    },
    {
      name: ':fuelpump:',
      position: {
        x: 3,
        y: 3,
      },
    },
    {
      name: ':busstop:',
      position: {
        x: 29,
        y: 26,
      },
    },
    {
      name: ':vertical_traffic_light:',
      position: {
        x: 30,
        y: 13,
      },
    },
    {
      name: ':traffic_light:',
      position: {
        x: 30,
        y: 12,
      },
    },
    {
      name: ':checkered_flag:',
      position: {
        x: 10,
        y: 6,
      },
    },
    {
      name: ':ship:',
      position: {
        x: 30,
        y: 4,
      },
    },
    {
      name: ':ferris_wheel:',
      position: {
        x: 9,
        y: 15,
      },
    },
    {
      name: ':roller_coaster:',
      position: {
        x: 9,
        y: 16,
      },
    },
    {
      name: ':carousel_horse:',
      position: {
        x: 9,
        y: 14,
      },
    },
    {
      name: ':building_construction:',
      position: {
        x: 11,
        y: 12,
      },
    },
    {
      name: ':foggy:',
      position: {
        x: 5,
        y: 21,
      },
    },
    {
      name: ':tokyo_tower:',
      position: {
        x: 26,
        y: 10,
      },
    },
    {
      name: ':factory:',
      position: {
        x: 11,
        y: 34,
      },
    },
    {
      name: ':fountain:',
      position: {
        x: 2,
        y: 31,
      },
    },
    {
      name: ':rice_scene:',
      position: {
        x: 9,
        y: 4,
      },
    },
    {
      name: ':mountain:',
      position: {
        x: 2,
        y: 29,
      },
    },
    {
      name: ':snow_capped_mountain:',
      position: {
        x: 11,
        y: 9,
      },
    },
    {
      name: ':mount_fuji:',
      position: {
        x: 26,
        y: 9,
      },
    },
    {
      name: ':volcano:',
      position: {
        x: 5,
        y: 31,
      },
    },
    {
      name: ':japan:',
      position: {
        x: 26,
        y: 12,
      },
    },
    {
      name: ':camping:',
      position: {
        x: 11,
        y: 10,
      },
    },
    {
      name: ':tent:',
      position: {
        x: 3,
        y: 2,
      },
    },
    {
      name: ':national_park:',
      position: {
        x: 11,
        y: 19,
      },
    },
    {
      name: ':motorway:',
      position: {
        x: 31,
        y: 33,
      },
    },
    {
      name: ':railway_track:',
      position: {
        x: 31,
        y: 34,
      },
    },
    {
      name: ':sunrise:',
      position: {
        x: 5,
        y: 25,
      },
    },
    {
      name: ':sunrise_over_mountains:',
      position: {
        x: 5,
        y: 24,
      },
    },
    {
      name: ':desert:',
      position: {
        x: 11,
        y: 17,
      },
    },
    {
      name: ':beach_with_umbrella:',
      position: {
        x: 11,
        y: 11,
      },
    },
    {
      name: ':desert_island:',
      position: {
        x: 11,
        y: 18,
      },
    },
    {
      name: ':city_sunrise:',
      position: {
        x: 5,
        y: 27,
      },
    },
    {
      name: ':city_sunset:',
      position: {
        x: 5,
        y: 26,
      },
    },
    {
      name: ':cityscape:',
      position: {
        x: 11,
        y: 14,
      },
    },
    {
      name: ':night_with_stars:',
      position: {
        x: 5,
        y: 23,
      },
    },
    {
      name: ':bridge_at_night:',
      position: {
        x: 5,
        y: 29,
      },
    },
    {
      name: ':milky_way:',
      position: {
        x: 5,
        y: 32,
      },
    },
    {
      name: ':stars:',
      position: {
        x: 6,
        y: 11,
      },
    },
    {
      name: ':sparkler:',
      position: {
        x: 8,
        y: 35,
      },
    },
    {
      name: ':fireworks:',
      position: {
        x: 8,
        y: 34,
      },
    },
    {
      name: ':rainbow:',
      position: {
        x: 5,
        y: 28,
      },
    },
    {
      name: ':house_buildings:',
      position: {
        x: 11,
        y: 13,
      },
    },
    {
      name: ':european_castle:',
      position: {
        x: 11,
        y: 37,
      },
    },
    {
      name: ':japanese_castle:',
      position: {
        x: 11,
        y: 36,
      },
    },
    {
      name: ':stadium:',
      position: {
        x: 11,
        y: 20,
      },
    },
    {
      name: ':statue_of_liberty:',
      position: {
        x: 26,
        y: 11,
      },
    },
    {
      name: ':house:',
      position: {
        x: 11,
        y: 21,
      },
    },
    {
      name: ':house_with_garden:',
      position: {
        x: 11,
        y: 22,
      },
    },
    {
      name: ':derelict_house_building:',
      position: {
        x: 11,
        y: 15,
      },
    },
    {
      name: ':office:',
      position: {
        x: 11,
        y: 23,
      },
    },
    {
      name: ':department_store:',
      position: {
        x: 11,
        y: 33,
      },
    },
    {
      name: ':post_office:',
      position: {
        x: 11,
        y: 24,
      },
    },
    {
      name: ':european_post_office:',
      position: {
        x: 11,
        y: 25,
      },
    },
    {
      name: ':hospital:',
      position: {
        x: 11,
        y: 26,
      },
    },
    {
      name: ':bank:',
      position: {
        x: 11,
        y: 27,
      },
    },
    {
      name: ':hotel:',
      position: {
        x: 11,
        y: 29,
      },
    },
    {
      name: ':convenience_store:',
      position: {
        x: 11,
        y: 31,
      },
    },
    {
      name: ':school:',
      position: {
        x: 11,
        y: 32,
      },
    },
    {
      name: ':love_hotel:',
      position: {
        x: 11,
        y: 30,
      },
    },
    {
      name: ':wedding:',
      position: {
        x: 19,
        y: 38,
      },
    },
    {
      name: ':classical_building:',
      position: {
        x: 11,
        y: 16,
      },
    },
    {
      name: ':church:',
      position: {
        x: 2,
        y: 28,
      },
    },
    {
      name: ':mosque:',
      position: {
        x: 24,
        y: 12,
      },
    },
    {
      name: ':synagogue:',
      position: {
        x: 24,
        y: 13,
      },
    },
    {
      name: ':kaaba:',
      position: {
        x: 24,
        y: 11,
      },
    },
    {
      name: ':shinto_shrine:',
      position: {
        x: 2,
        y: 27,
      },
    },
  ],
  [EMOJI_GROUP.OBJECTS]: [
    {
      name: ':watch:',
      position: {
        x: 0,
        y: 14,
      },
    },
    {
      name: ':iphone:',
      position: {
        x: 22,
        y: 15,
      },
    },
    {
      name: ':calling:',
      position: {
        x: 22,
        y: 16,
      },
    },
    {
      name: ':computer:',
      position: {
        x: 21,
        y: 2,
      },
    },
    {
      name: ':keyboard:',
      position: {
        x: 0,
        y: 16,
      },
    },
    {
      name: ':desktop_computer:',
      position: {
        x: 25,
        y: 30,
      },
    },
    {
      name: ':printer:',
      position: {
        x: 25,
        y: 31,
      },
    },
    {
      name: ':three_button_mouse:',
      position: {
        x: 25,
        y: 32,
      },
    },
    {
      name: ':trackball:',
      position: {
        x: 25,
        y: 33,
      },
    },
    {
      name: ':joystick:',
      position: {
        x: 25,
        y: 6,
      },
    },
    {
      name: ':compression:',
      position: {
        x: 26,
        y: 0,
      },
    },
    {
      name: ':minidisc:',
      position: {
        x: 21,
        y: 4,
      },
    },
    {
      name: ':floppy_disk:',
      position: {
        x: 21,
        y: 5,
      },
    },
    {
      name: ':cd:',
      position: {
        x: 21,
        y: 6,
      },
    },
    {
      name: ':dvd:',
      position: {
        x: 21,
        y: 7,
      },
    },
    {
      name: ':vhs:',
      position: {
        x: 22,
        y: 26,
      },
    },
    {
      name: ':camera:',
      position: {
        x: 22,
        y: 21,
      },
    },
    {
      name: ':camera_with_flash:',
      position: {
        x: 22,
        y: 22,
      },
    },
    {
      name: ':video_camera:',
      position: {
        x: 22,
        y: 23,
      },
    },
    {
      name: ':movie_camera:',
      position: {
        x: 9,
        y: 19,
      },
    },
    {
      name: ':film_projector:',
      position: {
        x: 22,
        y: 27,
      },
    },
    {
      name: ':film_frames:',
      position: {
        x: 9,
        y: 12,
      },
    },
    {
      name: ':telephone_receiver:',
      position: {
        x: 21,
        y: 37,
      },
    },
    {
      name: ':phone:',
      position: {
        x: 1,
        y: 4,
      },
    },
    {
      name: ':pager:',
      position: {
        x: 21,
        y: 38,
      },
    },
    {
      name: ':fax:',
      position: {
        x: 21,
        y: 39,
      },
    },
    {
      name: ':tv:',
      position: {
        x: 22,
        y: 24,
      },
    },
    {
      name: ':radio:',
      position: {
        x: 22,
        y: 25,
      },
    },
    {
      name: ':studio_microphone:',
      position: {
        x: 9,
        y: 9,
      },
    },
    {
      name: ':level_slider:',
      position: {
        x: 9,
        y: 10,
      },
    },
    {
      name: ':control_knobs:',
      position: {
        x: 9,
        y: 11,
      },
    },
    {
      name: ':stopwatch:',
      position: {
        x: 0,
        y: 25,
      },
    },
    {
      name: ':timer_clock:',
      position: {
        x: 0,
        y: 26,
      },
    },
    {
      name: ':alarm_clock:',
      position: {
        x: 0,
        y: 24,
      },
    },
    {
      name: ':mantelpiece_clock:',
      position: {
        x: 24,
        y: 40,
      },
    },
    {
      name: ':hourglass_flowing_sand:',
      position: {
        x: 0,
        y: 27,
      },
    },
    {
      name: ':hourglass:',
      position: {
        x: 0,
        y: 15,
      },
    },
    {
      name: ':satellite_antenna:',
      position: {
        x: 21,
        y: 40,
      },
    },
    {
      name: ':battery:',
      position: {
        x: 22,
        y: 40,
      },
    },
    {
      name: ':electric_plug:',
      position: {
        x: 23,
        y: 0,
      },
    },
    {
      name: ':bulb:',
      position: {
        x: 20,
        y: 12,
      },
    },
    {
      name: ':flashlight:',
      position: {
        x: 23,
        y: 26,
      },
    },
    {
      name: ':candle:',
      position: {
        x: 24,
        y: 39,
      },
    },
    {
      name: ':wastebasket:',
      position: {
        x: 25,
        y: 38,
      },
    },
    {
      name: ':oil_drum:',
      position: {
        x: 31,
        y: 32,
      },
    },
    {
      name: ':money_with_wings:',
      position: {
        x: 20,
        y: 40,
      },
    },
    {
      name: ':dollar:',
      position: {
        x: 20,
        y: 37,
      },
    },
    {
      name: ':yen:',
      position: {
        x: 20,
        y: 36,
      },
    },
    {
      name: ':euro:',
      position: {
        x: 20,
        y: 38,
      },
    },
    {
      name: ':pound:',
      position: {
        x: 20,
        y: 39,
      },
    },
    {
      name: ':moneybag:',
      position: {
        x: 20,
        y: 32,
      },
    },
    {
      name: ':credit_card:',
      position: {
        x: 20,
        y: 35,
      },
    },
    {
      name: ':gem:',
      position: {
        x: 19,
        y: 34,
      },
    },
    {
      name: ':scales:',
      position: {
        x: 2,
        y: 6,
      },
    },
    {
      name: ':wrench:',
      position: {
        x: 23,
        y: 27,
      },
    },
    {
      name: ':hammer:',
      position: {
        x: 23,
        y: 28,
      },
    },
    {
      name: ':hammer_and_pick:',
      position: {
        x: 2,
        y: 3,
      },
    },
    {
      name: ':hammer_and_wrench:',
      position: {
        x: 31,
        y: 30,
      },
    },
    {
      name: ':pick:',
      position: {
        x: 2,
        y: 23,
      },
    },
    {
      name: ':nut_and_bolt:',
      position: {
        x: 23,
        y: 29,
      },
    },
    {
      name: ':gear:',
      position: {
        x: 2,
        y: 8,
      },
    },
    {
      name: ':chains:',
      position: {
        x: 2,
        y: 25,
      },
    },
    {
      name: ':gun:',
      position: {
        x: 23,
        y: 31,
      },
    },
    {
      name: ':bomb:',
      position: {
        x: 20,
        y: 14,
      },
    },
    {
      name: ':hocho:',
      position: {
        x: 23,
        y: 30,
      },
    },
    {
      name: ':dagger_knife:',
      position: {
        x: 26,
        y: 3,
      },
    },
    {
      name: ':crossed_swords:',
      position: {
        x: 2,
        y: 5,
      },
    },
    {
      name: ':shield:',
      position: {
        x: 31,
        y: 31,
      },
    },
    {
      name: ':smoking:',
      position: {
        x: 30,
        y: 19,
      },
    },
    {
      name: ':skull_and_crossbones:',
      position: {
        x: 1,
        y: 15,
      },
    },
    {
      name: ':coffin:',
      position: {
        x: 2,
        y: 15,
      },
    },
    {
      name: ':funeral_urn:',
      position: {
        x: 2,
        y: 16,
      },
    },
    {
      name: ':amphora:',
      position: {
        x: 12,
        y: 3,
      },
    },
    {
      name: ':crystal_ball:',
      position: {
        x: 23,
        y: 34,
      },
    },
    {
      name: ':prayer_beads:',
      position: {
        x: 22,
        y: 28,
      },
    },
    {
      name: ':barber:',
      position: {
        x: 19,
        y: 28,
      },
    },
    {
      name: ':alembic:',
      position: {
        x: 2,
        y: 7,
      },
    },
    {
      name: ':telescope:',
      position: {
        x: 23,
        y: 33,
      },
    },
    {
      name: ':microscope:',
      position: {
        x: 23,
        y: 32,
      },
    },
    {
      name: ':hole:',
      position: {
        x: 25,
        y: 0,
      },
    },
    {
      name: ':pill:',
      position: {
        x: 19,
        y: 30,
      },
    },
    {
      name: ':syringe:',
      position: {
        x: 19,
        y: 29,
      },
    },
    {
      name: ':thermometer:',
      position: {
        x: 6,
        y: 12,
      },
    },
    {
      name: ':label:',
      position: {
        x: 12,
        y: 0,
      },
    },
    {
      name: ':bookmark:',
      position: {
        x: 23,
        y: 10,
      },
    },
    {
      name: ':toilet:',
      position: {
        x: 31,
        y: 10,
      },
    },
    {
      name: ':shower:',
      position: {
        x: 31,
        y: 12,
      },
    },
    {
      name: ':bathtub:',
      position: {
        x: 31,
        y: 19,
      },
    },
    {
      name: ':key:',
      position: {
        x: 23,
        y: 5,
      },
    },
    {
      name: ':old_key:',
      position: {
        x: 26,
        y: 1,
      },
    },
    {
      name: ':couch_and_lamp:',
      position: {
        x: 31,
        y: 24,
      },
    },
    {
      name: ':sleeping_accommodation:',
      position: {
        x: 31,
        y: 25,
      },
    },
    {
      name: ':bed:',
      position: {
        x: 31,
        y: 28,
      },
    },
    {
      name: ':door:',
      position: {
        x: 30,
        y: 17,
      },
    },
    {
      name: ':bellhop_bell:',
      position: {
        x: 31,
        y: 27,
      },
    },
    {
      name: ':frame_with_picture:',
      position: {
        x: 25,
        y: 34,
      },
    },
    {
      name: ':world_map:',
      position: {
        x: 26,
        y: 8,
      },
    },
    {
      name: ':umbrella_on_ground:',
      position: {
        x: 2,
        y: 30,
      },
    },
    {
      name: ':moyai:',
      position: {
        x: 26,
        y: 13,
      },
    },
    {
      name: ':shopping_bags:',
      position: {
        x: 31,
        y: 26,
      },
    },
    {
      name: ':balloon:',
      position: {
        x: 8,
        y: 36,
      },
    },
    {
      name: ':flags:',
      position: {
        x: 9,
        y: 2,
      },
    },
    {
      name: ':ribbon:',
      position: {
        x: 8,
        y: 23,
      },
    },
    {
      name: ':gift:',
      position: {
        x: 8,
        y: 24,
      },
    },
    {
      name: ':confetti_ball:',
      position: {
        x: 8,
        y: 38,
      },
    },
    {
      name: ':tada:',
      position: {
        x: 8,
        y: 37,
      },
    },
    {
      name: ':dolls:',
      position: {
        x: 9,
        y: 1,
      },
    },
    {
      name: ':wind_chime:',
      position: {
        x: 9,
        y: 3,
      },
    },
    {
      name: ':crossed_flags:',
      position: {
        x: 8,
        y: 40,
      },
    },
    {
      name: ':izakaya_lantern:',
      position: {
        x: 11,
        y: 35,
      },
    },
    {
      name: ':email:',
      position: {
        x: 3,
        y: 7,
      },
    },
    {
      name: ':envelope_with_arrow:',
      position: {
        x: 22,
        y: 7,
      },
    },
    {
      name: ':incoming_envelope:',
      position: {
        x: 22,
        y: 6,
      },
    },
    {
      name: ':e-mail:',
      position: {
        x: 22,
        y: 5,
      },
    },
    {
      name: ':love_letter:',
      position: {
        x: 19,
        y: 32,
      },
    },
    {
      name: ':postbox:',
      position: {
        x: 22,
        y: 12,
      },
    },
    {
      name: ':mailbox_closed:',
      position: {
        x: 22,
        y: 8,
      },
    },
    {
      name: ':mailbox:',
      position: {
        x: 22,
        y: 9,
      },
    },
    {
      name: ':mailbox_with_mail:',
      position: {
        x: 22,
        y: 10,
      },
    },
    {
      name: ':mailbox_with_no_mail:',
      position: {
        x: 22,
        y: 11,
      },
    },
    {
      name: ':package:',
      position: {
        x: 22,
        y: 4,
      },
    },
    {
      name: ':postal_horn:',
      position: {
        x: 22,
        y: 13,
      },
    },
    {
      name: ':inbox_tray:',
      position: {
        x: 22,
        y: 3,
      },
    },
    {
      name: ':outbox_tray:',
      position: {
        x: 22,
        y: 2,
      },
    },
    {
      name: ':scroll:',
      position: {
        x: 21,
        y: 35,
      },
    },
    {
      name: ':page_with_curl:',
      position: {
        x: 21,
        y: 10,
      },
    },
    {
      name: ':bookmark_tabs:',
      position: {
        x: 21,
        y: 24,
      },
    },
    {
      name: ':bar_chart:',
      position: {
        x: 21,
        y: 17,
      },
    },
    {
      name: ':chart_with_upwards_trend:',
      position: {
        x: 21,
        y: 15,
      },
    },
    {
      name: ':chart_with_downwards_trend:',
      position: {
        x: 21,
        y: 16,
      },
    },
    {
      name: ':page_facing_up:',
      position: {
        x: 21,
        y: 11,
      },
    },
    {
      name: ':date:',
      position: {
        x: 21,
        y: 12,
      },
    },
    {
      name: ':calendar:',
      position: {
        x: 21,
        y: 13,
      },
    },
    {
      name: ':spiral_calendar_pad:',
      position: {
        x: 25,
        y: 40,
      },
    },
    {
      name: ':card_index:',
      position: {
        x: 21,
        y: 14,
      },
    },
    {
      name: ':card_file_box:',
      position: {
        x: 25,
        y: 36,
      },
    },
    {
      name: ':ballot_box_with_ballot:',
      position: {
        x: 26,
        y: 7,
      },
    },
    {
      name: ':file_cabinet:',
      position: {
        x: 25,
        y: 37,
      },
    },
    {
      name: ':clipboard:',
      position: {
        x: 21,
        y: 18,
      },
    },
    {
      name: ':spiral_note_pad:',
      position: {
        x: 25,
        y: 39,
      },
    },
    {
      name: ':file_folder:',
      position: {
        x: 21,
        y: 8,
      },
    },
    {
      name: ':open_file_folder:',
      position: {
        x: 21,
        y: 9,
      },
    },
    {
      name: ':card_index_dividers:',
      position: {
        x: 25,
        y: 35,
      },
    },
    {
      name: ':rolled_up_newspaper:',
      position: {
        x: 26,
        y: 2,
      },
    },
    {
      name: ':newspaper:',
      position: {
        x: 22,
        y: 14,
      },
    },
    {
      name: ':notebook:',
      position: {
        x: 21,
        y: 26,
      },
    },
    {
      name: ':closed_book:',
      position: {
        x: 21,
        y: 28,
      },
    },
    {
      name: ':green_book:',
      position: {
        x: 21,
        y: 30,
      },
    },
    {
      name: ':blue_book:',
      position: {
        x: 21,
        y: 31,
      },
    },
    {
      name: ':orange_book:',
      position: {
        x: 21,
        y: 32,
      },
    },
    {
      name: ':notebook_with_decorative_cover:',
      position: {
        x: 21,
        y: 27,
      },
    },
    {
      name: ':ledger:',
      position: {
        x: 21,
        y: 25,
      },
    },
    {
      name: ':books:',
      position: {
        x: 21,
        y: 33,
      },
    },
    {
      name: ':book:',
      position: {
        x: 21,
        y: 29,
      },
    },
    {
      name: ':link:',
      position: {
        x: 23,
        y: 11,
      },
    },
    {
      name: ':paperclip:',
      position: {
        x: 21,
        y: 21,
      },
    },
    {
      name: ':linked_paperclips:',
      position: {
        x: 25,
        y: 7,
      },
    },
    {
      name: ':scissors:',
      position: {
        x: 3,
        y: 4,
      },
    },
    {
      name: ':triangular_ruler:',
      position: {
        x: 21,
        y: 23,
      },
    },
    {
      name: ':straight_ruler:',
      position: {
        x: 21,
        y: 22,
      },
    },
    {
      name: ':pushpin:',
      position: {
        x: 21,
        y: 19,
      },
    },
    {
      name: ':round_pushpin:',
      position: {
        x: 21,
        y: 20,
      },
    },
    {
      name: ':triangular_flag_on_post:',
      position: {
        x: 30,
        y: 16,
      },
    },
    {
      name: ':waving_white_flag:',
      position: {
        x: 11,
        y: 38,
      },
    },
    {
      name: ':waving_black_flag:',
      position: {
        x: 11,
        y: 39,
      },
    },
    {
      name: ':closed_lock_with_key:',
      position: {
        x: 23,
        y: 4,
      },
    },
    {
      name: ':lock:',
      position: {
        x: 23,
        y: 6,
      },
    },
    {
      name: ':unlock:',
      position: {
        x: 23,
        y: 7,
      },
    },
    {
      name: ':lock_with_ink_pen:',
      position: {
        x: 23,
        y: 3,
      },
    },
    {
      name: ':lower_left_ballpoint_pen:',
      position: {
        x: 25,
        y: 8,
      },
    },
    {
      name: ':lower_left_fountain_pen:',
      position: {
        x: 25,
        y: 9,
      },
    },
    {
      name: ':black_nib:',
      position: {
        x: 3,
        y: 33,
      },
    },
    {
      name: ':memo:',
      position: {
        x: 21,
        y: 36,
      },
    },
    {
      name: ':pencil2:',
      position: {
        x: 3,
        y: 32,
      },
    },
    {
      name: ':lower_left_crayon:',
      position: {
        x: 25,
        y: 11,
      },
    },
    {
      name: ':lower_left_paintbrush:',
      position: {
        x: 25,
        y: 10,
      },
    },
    {
      name: ':mag:',
      position: {
        x: 23,
        y: 1,
      },
    },
    {
      name: ':mag_right:',
      position: {
        x: 23,
        y: 2,
      },
    },
  ],
  [EMOJI_GROUP.SYMBOLS]: [
    {
      name: ':heart:',
      position: {
        x: 4,
        y: 9,
      },
    },
    {
      name: ':yellow_heart:',
      position: {
        x: 20,
        y: 6,
      },
    },
    {
      name: ':green_heart:',
      position: {
        x: 20,
        y: 5,
      },
    },
    {
      name: ':blue_heart:',
      position: {
        x: 20,
        y: 4,
      },
    },
    {
      name: ':purple_heart:',
      position: {
        x: 20,
        y: 7,
      },
    },
    {
      name: ':broken_heart:',
      position: {
        x: 19,
        y: 40,
      },
    },
    {
      name: ':heavy_heart_exclamation_mark_ornament:',
      position: {
        x: 4,
        y: 8,
      },
    },
    {
      name: ':two_hearts:',
      position: {
        x: 20,
        y: 0,
      },
    },
    {
      name: ':revolving_hearts:',
      position: {
        x: 20,
        y: 9,
      },
    },
    {
      name: ':heartbeat:',
      position: {
        x: 19,
        y: 39,
      },
    },
    {
      name: ':heartpulse:',
      position: {
        x: 20,
        y: 2,
      },
    },
    {
      name: ':sparkling_heart:',
      position: {
        x: 20,
        y: 1,
      },
    },
    {
      name: ':cupid:',
      position: {
        x: 20,
        y: 3,
      },
    },
    {
      name: ':gift_heart:',
      position: {
        x: 20,
        y: 8,
      },
    },
    {
      name: ':heart_decoration:',
      position: {
        x: 20,
        y: 10,
      },
    },
    {
      name: ':peace_symbol:',
      position: {
        x: 1,
        y: 20,
      },
    },
    {
      name: ':latin_cross:',
      position: {
        x: 3,
        y: 36,
      },
    },
    {
      name: ':star_and_crescent:',
      position: {
        x: 1,
        y: 19,
      },
    },
    {
      name: ':om_symbol:',
      position: {
        x: 24,
        y: 9,
      },
    },
    {
      name: ':wheel_of_dharma:',
      position: {
        x: 1,
        y: 22,
      },
    },
    {
      name: ':star_of_david:',
      position: {
        x: 3,
        y: 37,
      },
    },
    {
      name: ':six_pointed_star:',
      position: {
        x: 23,
        y: 35,
      },
    },
    {
      name: ':menorah_with_nine_branches:',
      position: {
        x: 24,
        y: 14,
      },
    },
    {
      name: ':yin_yang:',
      position: {
        x: 1,
        y: 21,
      },
    },
    {
      name: ':orthodox_cross:',
      position: {
        x: 1,
        y: 18,
      },
    },
    {
      name: ':place_of_worship:',
      position: {
        x: 31,
        y: 29,
      },
    },
    {
      name: ':ophiuchus:',
      position: {
        x: 2,
        y: 22,
      },
    },
    {
      name: ':aries:',
      position: {
        x: 1,
        y: 25,
      },
    },
    {
      name: ':taurus:',
      position: {
        x: 1,
        y: 26,
      },
    },
    {
      name: ':gemini:',
      position: {
        x: 1,
        y: 27,
      },
    },
    {
      name: ':cancer:',
      position: {
        x: 1,
        y: 28,
      },
    },
    {
      name: ':leo:',
      position: {
        x: 1,
        y: 29,
      },
    },
    {
      name: ':virgo:',
      position: {
        x: 1,
        y: 30,
      },
    },
    {
      name: ':libra:',
      position: {
        x: 1,
        y: 31,
      },
    },
    {
      name: ':scorpius:',
      position: {
        x: 1,
        y: 32,
      },
    },
    {
      name: ':sagittarius:',
      position: {
        x: 1,
        y: 33,
      },
    },
    {
      name: ':capricorn:',
      position: {
        x: 1,
        y: 34,
      },
    },
    {
      name: ':aquarius:',
      position: {
        x: 1,
        y: 35,
      },
    },
    {
      name: ':pisces:',
      position: {
        x: 1,
        y: 36,
      },
    },
    {
      name: ':id:',
      position: {
        x: 4,
        y: 39,
      },
    },
    {
      name: ':atom_symbol:',
      position: {
        x: 2,
        y: 9,
      },
    },
    {
      name: ':u7a7a:',
      position: {
        x: 5,
        y: 10,
      },
    },
    {
      name: ':u5272:',
      position: {
        x: 5,
        y: 16,
      },
    },
    {
      name: ':radioactive_sign:',
      position: {
        x: 1,
        y: 16,
      },
    },
    {
      name: ':biohazard_sign:',
      position: {
        x: 1,
        y: 17,
      },
    },
    {
      name: ':mobile_phone_off:',
      position: {
        x: 22,
        y: 18,
      },
    },
    {
      name: ':vibration_mode:',
      position: {
        x: 22,
        y: 17,
      },
    },
    {
      name: ':u6709:',
      position: {
        x: 5,
        y: 13,
      },
    },
    {
      name: ':u7121:',
      position: {
        x: 5,
        y: 7,
      },
    },
    {
      name: ':u7533:',
      position: {
        x: 5,
        y: 15,
      },
    },
    {
      name: ':u55b6:',
      position: {
        x: 5,
        y: 17,
      },
    },
    {
      name: ':u6708:',
      position: {
        x: 5,
        y: 14,
      },
    },
    {
      name: ':eight_pointed_black_star:',
      position: {
        x: 3,
        y: 40,
      },
    },
    {
      name: ':vs:',
      position: {
        x: 5,
        y: 4,
      },
    },
    {
      name: ':accept:',
      position: {
        x: 5,
        y: 19,
      },
    },
    {
      name: ':white_flower:',
      position: {
        x: 20,
        y: 30,
      },
    },
    {
      name: ':ideograph_advantage:',
      position: {
        x: 5,
        y: 18,
      },
    },
    {
      name: ':secret:',
      position: {
        x: 4,
        y: 28,
      },
    },
    {
      name: ':congratulations:',
      position: {
        x: 4,
        y: 27,
      },
    },
    {
      name: ':u5408:',
      position: {
        x: 5,
        y: 11,
      },
    },
    {
      name: ':u6e80:',
      position: {
        x: 5,
        y: 12,
      },
    },
    {
      name: ':u7981:',
      position: {
        x: 5,
        y: 9,
      },
    },
    {
      name: ':a:',
      position: {
        x: 4,
        y: 31,
      },
    },
    {
      name: ':b:',
      position: {
        x: 4,
        y: 32,
      },
    },
    {
      name: ':ab:',
      position: {
        x: 4,
        y: 35,
      },
    },
    {
      name: ':cl:',
      position: {
        x: 4,
        y: 36,
      },
    },
    {
      name: ':o2:',
      position: {
        x: 4,
        y: 33,
      },
    },
    {
      name: ':sos:',
      position: {
        x: 5,
        y: 2,
      },
    },
    {
      name: ':no_entry:',
      position: {
        x: 2,
        y: 26,
      },
    },
    {
      name: ':name_badge:',
      position: {
        x: 21,
        y: 34,
      },
    },
    {
      name: ':no_entry_sign:',
      position: {
        x: 30,
        y: 18,
      },
    },
    {
      name: ':x:',
      position: {
        x: 4,
        y: 2,
      },
    },
    {
      name: ':o:',
      position: {
        x: 4,
        y: 24,
      },
    },
    {
      name: ':anger:',
      position: {
        x: 20,
        y: 13,
      },
    },
    {
      name: ':hotsprings:',
      position: {
        x: 2,
        y: 0,
      },
    },
    {
      name: ':no_pedestrians:',
      position: {
        x: 31,
        y: 4,
      },
    },
    {
      name: ':do_not_litter:',
      position: {
        x: 30,
        y: 22,
      },
    },
    {
      name: ':no_bicycles:',
      position: {
        x: 30,
        y: 26,
      },
    },
    {
      name: ':non-potable_water:',
      position: {
        x: 30,
        y: 24,
      },
    },
    {
      name: ':underage:',
      position: {
        x: 23,
        y: 18,
      },
    },
    {
      name: ':no_mobile_phones:',
      position: {
        x: 22,
        y: 19,
      },
    },
    {
      name: ':exclamation:',
      position: {
        x: 4,
        y: 7,
      },
    },
    {
      name: ':grey_exclamation:',
      position: {
        x: 4,
        y: 6,
      },
    },
    {
      name: ':question:',
      position: {
        x: 4,
        y: 4,
      },
    },
    {
      name: ':grey_question:',
      position: {
        x: 4,
        y: 5,
      },
    },
    {
      name: ':bangbang:',
      position: {
        x: 0,
        y: 2,
      },
    },
    {
      name: ':interrobang:',
      position: {
        x: 0,
        y: 3,
      },
    },
    {
      name: ':100:',
      position: {
        x: 20,
        y: 31,
      },
    },
    {
      name: ':low_brightness:',
      position: {
        x: 22,
        y: 34,
      },
    },
    {
      name: ':high_brightness:',
      position: {
        x: 22,
        y: 35,
      },
    },
    {
      name: ':trident:',
      position: {
        x: 23,
        y: 37,
      },
    },
    {
      name: ':fleur_de_lis:',
      position: {
        x: 2,
        y: 10,
      },
    },
    {
      name: ':part_alternation_mark:',
      position: {
        x: 4,
        y: 26,
      },
    },
    {
      name: ':warning:',
      position: {
        x: 2,
        y: 11,
      },
    },
    {
      name: ':children_crossing:',
      position: {
        x: 31,
        y: 5,
      },
    },
    {
      name: ':beginner:',
      position: {
        x: 23,
        y: 36,
      },
    },
    {
      name: ':recycle:',
      position: {
        x: 2,
        y: 1,
      },
    },
    {
      name: ':u6307:',
      position: {
        x: 5,
        y: 8,
      },
    },
    {
      name: ':chart:',
      position: {
        x: 21,
        y: 0,
      },
    },
    {
      name: ':sparkle:',
      position: {
        x: 4,
        y: 1,
      },
    },
    {
      name: ':eight_spoked_asterisk:',
      position: {
        x: 3,
        y: 39,
      },
    },
    {
      name: ':negative_squared_cross_mark:',
      position: {
        x: 4,
        y: 3,
      },
    },
    {
      name: ':white_check_mark:',
      position: {
        x: 3,
        y: 5,
      },
    },
    {
      name: ':diamond_shape_with_a_dot_inside:',
      position: {
        x: 20,
        y: 11,
      },
    },
    {
      name: ':cyclone:',
      position: {
        x: 5,
        y: 20,
      },
    },
    {
      name: ':loop:',
      position: {
        x: 4,
        y: 15,
      },
    },
    {
      name: ':globe_with_meridians:',
      position: {
        x: 5,
        y: 36,
      },
    },
    {
      name: ':m:',
      position: {
        x: 0,
        y: 31,
      },
    },
    {
      name: ':atm:',
      position: {
        x: 11,
        y: 28,
      },
    },
    {
      name: ':sa:',
      position: {
        x: 5,
        y: 6,
      },
    },
    {
      name: ':passport_control:',
      position: {
        x: 31,
        y: 20,
      },
    },
    {
      name: ':customs:',
      position: {
        x: 31,
        y: 21,
      },
    },
    {
      name: ':baggage_claim:',
      position: {
        x: 31,
        y: 22,
      },
    },
    {
      name: ':left_luggage:',
      position: {
        x: 31,
        y: 23,
      },
    },
    {
      name: ':wheelchair:',
      position: {
        x: 2,
        y: 2,
      },
    },
    {
      name: ':no_smoking:',
      position: {
        x: 30,
        y: 20,
      },
    },
    {
      name: ':wc:',
      position: {
        x: 31,
        y: 11,
      },
    },
    {
      name: ':parking:',
      position: {
        x: 4,
        y: 34,
      },
    },
    {
      name: ':potable_water:',
      position: {
        x: 30,
        y: 23,
      },
    },
    {
      name: ':mens:',
      position: {
        x: 31,
        y: 6,
      },
    },
    {
      name: ':womens:',
      position: {
        x: 31,
        y: 7,
      },
    },
    {
      name: ':baby_symbol:',
      position: {
        x: 31,
        y: 9,
      },
    },
    {
      name: ':restroom:',
      position: {
        x: 31,
        y: 8,
      },
    },
    {
      name: ':put_litter_in_its_place:',
      position: {
        x: 30,
        y: 21,
      },
    },
    {
      name: ':cinema:',
      position: {
        x: 9,
        y: 20,
      },
    },
    {
      name: ':signal_strength:',
      position: {
        x: 22,
        y: 20,
      },
    },
    {
      name: ':koko:',
      position: {
        x: 5,
        y: 5,
      },
    },
    {
      name: ':ng:',
      position: {
        x: 5,
        y: 0,
      },
    },
    {
      name: ':ok:',
      position: {
        x: 5,
        y: 1,
      },
    },
    {
      name: ':up:',
      position: {
        x: 5,
        y: 3,
      },
    },
    {
      name: ':cool:',
      position: {
        x: 4,
        y: 37,
      },
    },
    {
      name: ':new:',
      position: {
        x: 4,
        y: 40,
      },
    },
    {
      name: ':free:',
      position: {
        x: 4,
        y: 38,
      },
    },
    {
      name: ':zero:',
      position: {
        x: 32,
        y: 22,
      },
    },
    {
      name: ':one:',
      position: {
        x: 32,
        y: 23,
      },
    },
    {
      name: ':two:',
      position: {
        x: 32,
        y: 24,
      },
    },
    {
      name: ':three:',
      position: {
        x: 32,
        y: 25,
      },
    },
    {
      name: ':four:',
      position: {
        x: 32,
        y: 26,
      },
    },
    {
      name: ':five:',
      position: {
        x: 32,
        y: 27,
      },
    },
    {
      name: ':six:',
      position: {
        x: 32,
        y: 28,
      },
    },
    {
      name: ':seven:',
      position: {
        x: 32,
        y: 29,
      },
    },
    {
      name: ':eight:',
      position: {
        x: 32,
        y: 30,
      },
    },
    {
      name: ':nine:',
      position: {
        x: 32,
        y: 31,
      },
    },
    {
      name: ':keycap_ten:',
      position: {
        x: 23,
        y: 19,
      },
    },
    {
      name: ':keycap_star:',
      position: {
        x: 32,
        y: 21,
      },
    },
    {
      name: ':1234:',
      position: {
        x: 23,
        y: 22,
      },
    },
    {
      name: ':arrow_forward:',
      position: {
        x: 0,
        y: 34,
      },
    },
    {
      name: ':double_vertical_bar:',
      position: {
        x: 0,
        y: 28,
      },
    },
    {
      name: ':black_right_pointing_triangle_with_double_vertical_bar:',
      position: {
        x: 0,
        y: 23,
      },
    },
    {
      name: ':black_square_for_stop:',
      position: {
        x: 0,
        y: 29,
      },
    },
    {
      name: ':black_circle_for_record:',
      position: {
        x: 0,
        y: 30,
      },
    },
    {
      name: ':black_right_pointing_double_triangle_with_vertical_bar:',
      position: {
        x: 0,
        y: 21,
      },
    },
    {
      name: ':black_left_pointing_double_triangle_with_vertical_bar:',
      position: {
        x: 0,
        y: 22,
      },
    },
    {
      name: ':fast_forward:',
      position: {
        x: 0,
        y: 17,
      },
    },
    {
      name: ':rewind:',
      position: {
        x: 0,
        y: 18,
      },
    },
    {
      name: ':twisted_rightwards_arrows:',
      position: {
        x: 22,
        y: 29,
      },
    },
    {
      name: ':repeat:',
      position: {
        x: 22,
        y: 30,
      },
    },
    {
      name: ':repeat_one:',
      position: {
        x: 22,
        y: 31,
      },
    },
    {
      name: ':arrow_backward:',
      position: {
        x: 0,
        y: 35,
      },
    },
    {
      name: ':arrow_up_small:',
      position: {
        x: 24,
        y: 7,
      },
    },
    {
      name: ':arrow_down_small:',
      position: {
        x: 24,
        y: 8,
      },
    },
    {
      name: ':arrow_double_up:',
      position: {
        x: 0,
        y: 19,
      },
    },
    {
      name: ':arrow_double_down:',
      position: {
        x: 0,
        y: 20,
      },
    },
    {
      name: ':arrow_right:',
      position: {
        x: 4,
        y: 13,
      },
    },
    {
      name: ':arrow_left:',
      position: {
        x: 4,
        y: 18,
      },
    },
    {
      name: ':arrow_up:',
      position: {
        x: 4,
        y: 19,
      },
    },
    {
      name: ':arrow_down:',
      position: {
        x: 4,
        y: 20,
      },
    },
    {
      name: ':arrow_upper_right:',
      position: {
        x: 0,
        y: 9,
      },
    },
    {
      name: ':arrow_lower_right:',
      position: {
        x: 0,
        y: 10,
      },
    },
    {
      name: ':arrow_lower_left:',
      position: {
        x: 0,
        y: 11,
      },
    },
    {
      name: ':arrow_upper_left:',
      position: {
        x: 0,
        y: 8,
      },
    },
    {
      name: ':arrow_up_down:',
      position: {
        x: 0,
        y: 7,
      },
    },
    {
      name: ':left_right_arrow:',
      position: {
        x: 0,
        y: 6,
      },
    },
    {
      name: ':arrows_counterclockwise:',
      position: {
        x: 22,
        y: 33,
      },
    },
    {
      name: ':arrow_right_hook:',
      position: {
        x: 0,
        y: 13,
      },
    },
    {
      name: ':leftwards_arrow_with_hook:',
      position: {
        x: 0,
        y: 12,
      },
    },
    {
      name: ':arrow_heading_up:',
      position: {
        x: 4,
        y: 16,
      },
    },
    {
      name: ':arrow_heading_down:',
      position: {
        x: 4,
        y: 17,
      },
    },
    {
      name: ':hash:',
      position: {
        x: 32,
        y: 20,
      },
    },
    {
      name: ':information_source:',
      position: {
        x: 0,
        y: 5,
      },
    },
    {
      name: ':abc:',
      position: {
        x: 23,
        y: 24,
      },
    },
    {
      name: ':abcd:',
      position: {
        x: 23,
        y: 21,
      },
    },
    {
      name: ':capital_abcd:',
      position: {
        x: 23,
        y: 20,
      },
    },
    {
      name: ':symbols:',
      position: {
        x: 23,
        y: 23,
      },
    },
    {
      name: ':musical_note:',
      position: {
        x: 9,
        y: 35,
      },
    },
    {
      name: ':notes:',
      position: {
        x: 9,
        y: 36,
      },
    },
    {
      name: ':wavy_dash:',
      position: {
        x: 4,
        y: 25,
      },
    },
    {
      name: ':curly_loop:',
      position: {
        x: 4,
        y: 14,
      },
    },
    {
      name: ':heavy_check_mark:',
      position: {
        x: 3,
        y: 34,
      },
    },
    {
      name: ':arrows_clockwise:',
      position: {
        x: 22,
        y: 32,
      },
    },
    {
      name: ':heavy_plus_sign:',
      position: {
        x: 4,
        y: 10,
      },
    },
    {
      name: ':heavy_minus_sign:',
      position: {
        x: 4,
        y: 11,
      },
    },
    {
      name: ':heavy_division_sign:',
      position: {
        x: 4,
        y: 12,
      },
    },
    {
      name: ':heavy_multiplication_x:',
      position: {
        x: 3,
        y: 35,
      },
    },
    {
      name: ':heavy_dollar_sign:',
      position: {
        x: 20,
        y: 34,
      },
    },
    {
      name: ':currency_exchange:',
      position: {
        x: 20,
        y: 33,
      },
    },
    {
      name: ':copyright:',
      position: {
        x: 0,
        y: 0,
      },
    },
    {
      name: ':registered:',
      position: {
        x: 0,
        y: 1,
      },
    },
    {
      name: ':tm:',
      position: {
        x: 0,
        y: 4,
      },
    },
    {
      name: ':end:',
      position: {
        x: 23,
        y: 14,
      },
    },
    {
      name: ':back:',
      position: {
        x: 23,
        y: 13,
      },
    },
    {
      name: ':on:',
      position: {
        x: 23,
        y: 15,
      },
    },
    {
      name: ':top:',
      position: {
        x: 23,
        y: 17,
      },
    },
    {
      name: ':soon:',
      position: {
        x: 23,
        y: 16,
      },
    },
    {
      name: ':ballot_box_with_check:',
      position: {
        x: 1,
        y: 5,
      },
    },
    {
      name: ':radio_button:',
      position: {
        x: 23,
        y: 12,
      },
    },
    {
      name: ':white_circle:',
      position: {
        x: 2,
        y: 13,
      },
    },
    {
      name: ':black_circle:',
      position: {
        x: 2,
        y: 14,
      },
    },
    {
      name: ':red_circle:',
      position: {
        x: 23,
        y: 40,
      },
    },
    {
      name: ':large_blue_circle:',
      position: {
        x: 24,
        y: 0,
      },
    },
    {
      name: ':small_orange_diamond:',
      position: {
        x: 24,
        y: 3,
      },
    },
    {
      name: ':small_blue_diamond:',
      position: {
        x: 24,
        y: 4,
      },
    },
    {
      name: ':large_orange_diamond:',
      position: {
        x: 24,
        y: 1,
      },
    },
    {
      name: ':large_blue_diamond:',
      position: {
        x: 24,
        y: 2,
      },
    },
    {
      name: ':small_red_triangle:',
      position: {
        x: 24,
        y: 5,
      },
    },
    {
      name: ':black_small_square:',
      position: {
        x: 0,
        y: 32,
      },
    },
    {
      name: ':white_small_square:',
      position: {
        x: 0,
        y: 33,
      },
    },
    {
      name: ':black_large_square:',
      position: {
        x: 4,
        y: 21,
      },
    },
    {
      name: ':white_large_square:',
      position: {
        x: 4,
        y: 22,
      },
    },
    {
      name: ':small_red_triangle_down:',
      position: {
        x: 24,
        y: 6,
      },
    },
    {
      name: ':black_medium_square:',
      position: {
        x: 0,
        y: 37,
      },
    },
    {
      name: ':white_medium_square:',
      position: {
        x: 0,
        y: 36,
      },
    },
    {
      name: ':black_medium_small_square:',
      position: {
        x: 0,
        y: 39,
      },
    },
    {
      name: ':white_medium_small_square:',
      position: {
        x: 0,
        y: 38,
      },
    },
    {
      name: ':black_square_button:',
      position: {
        x: 23,
        y: 38,
      },
    },
    {
      name: ':white_square_button:',
      position: {
        x: 23,
        y: 39,
      },
    },
    {
      name: ':speaker:',
      position: {
        x: 22,
        y: 37,
      },
    },
    {
      name: ':sound:',
      position: {
        x: 22,
        y: 38,
      },
    },
    {
      name: ':loud_sound:',
      position: {
        x: 22,
        y: 39,
      },
    },
    {
      name: ':mute:',
      position: {
        x: 22,
        y: 36,
      },
    },
    {
      name: ':mega:',
      position: {
        x: 22,
        y: 1,
      },
    },
    {
      name: ':loudspeaker:',
      position: {
        x: 22,
        y: 0,
      },
    },
    {
      name: ':bell:',
      position: {
        x: 23,
        y: 8,
      },
    },
    {
      name: ':no_bell:',
      position: {
        x: 23,
        y: 9,
      },
    },
    {
      name: ':black_joker:',
      position: {
        x: 4,
        y: 30,
      },
    },
    {
      name: ':mahjong:',
      position: {
        x: 4,
        y: 29,
      },
    },
    {
      name: ':spades:',
      position: {
        x: 1,
        y: 37,
      },
    },
    {
      name: ':clubs:',
      position: {
        x: 1,
        y: 38,
      },
    },
    {
      name: ':hearts:',
      position: {
        x: 1,
        y: 39,
      },
    },
    {
      name: ':diamonds:',
      position: {
        x: 1,
        y: 40,
      },
    },
    {
      name: ':flower_playing_cards:',
      position: {
        x: 9,
        y: 34,
      },
    },
    {
      name: ':thought_balloon:',
      position: {
        x: 20,
        y: 29,
      },
    },
    {
      name: ':right_anger_bubble:',
      position: {
        x: 26,
        y: 6,
      },
    },
    {
      name: ':speech_balloon:',
      position: {
        x: 20,
        y: 28,
      },
    },
    {
      name: ':clock1:',
      position: {
        x: 24,
        y: 15,
      },
    },
    {
      name: ':clock2:',
      position: {
        x: 24,
        y: 16,
      },
    },
    {
      name: ':clock3:',
      position: {
        x: 24,
        y: 17,
      },
    },
    {
      name: ':clock4:',
      position: {
        x: 24,
        y: 18,
      },
    },
    {
      name: ':clock5:',
      position: {
        x: 24,
        y: 19,
      },
    },
    {
      name: ':clock6:',
      position: {
        x: 24,
        y: 20,
      },
    },
    {
      name: ':clock7:',
      position: {
        x: 24,
        y: 21,
      },
    },
    {
      name: ':clock8:',
      position: {
        x: 24,
        y: 22,
      },
    },
    {
      name: ':clock9:',
      position: {
        x: 24,
        y: 23,
      },
    },
    {
      name: ':clock10:',
      position: {
        x: 24,
        y: 24,
      },
    },
    {
      name: ':clock11:',
      position: {
        x: 24,
        y: 25,
      },
    },
    {
      name: ':clock12:',
      position: {
        x: 24,
        y: 26,
      },
    },
    {
      name: ':clock130:',
      position: {
        x: 24,
        y: 27,
      },
    },
    {
      name: ':clock230:',
      position: {
        x: 24,
        y: 28,
      },
    },
    {
      name: ':clock330:',
      position: {
        x: 24,
        y: 29,
      },
    },
    {
      name: ':clock430:',
      position: {
        x: 24,
        y: 30,
      },
    },
    {
      name: ':clock530:',
      position: {
        x: 24,
        y: 31,
      },
    },
    {
      name: ':clock630:',
      position: {
        x: 24,
        y: 32,
      },
    },
    {
      name: ':clock730:',
      position: {
        x: 24,
        y: 33,
      },
    },
    {
      name: ':clock830:',
      position: {
        x: 24,
        y: 34,
      },
    },
    {
      name: ':clock930:',
      position: {
        x: 24,
        y: 35,
      },
    },
    {
      name: ':clock1030:',
      position: {
        x: 24,
        y: 36,
      },
    },
    {
      name: ':clock1130:',
      position: {
        x: 24,
        y: 37,
      },
    },
    {
      name: ':clock1230:',
      position: {
        x: 24,
        y: 38,
      },
    },
  ],
  [EMOJI_GROUP.FLAGS]: [
    {
      name: ':flag-ac:',
      position: {
        x: 32,
        y: 32,
      },
    },
    {
      name: ':flag-ad:',
      position: {
        x: 32,
        y: 33,
      },
    },
    {
      name: ':flag-ae:',
      position: {
        x: 32,
        y: 34,
      },
    },
    {
      name: ':flag-af:',
      position: {
        x: 32,
        y: 35,
      },
    },
    {
      name: ':flag-ag:',
      position: {
        x: 32,
        y: 36,
      },
    },
    {
      name: ':flag-ai:',
      position: {
        x: 32,
        y: 37,
      },
    },
    {
      name: ':flag-al:',
      position: {
        x: 32,
        y: 38,
      },
    },
    {
      name: ':flag-am:',
      position: {
        x: 32,
        y: 39,
      },
    },
    {
      name: ':flag-ao:',
      position: {
        x: 32,
        y: 40,
      },
    },
    {
      name: ':flag-aq:',
      position: {
        x: 33,
        y: 0,
      },
    },
    {
      name: ':flag-ar:',
      position: {
        x: 33,
        y: 1,
      },
    },
    {
      name: ':flag-as:',
      position: {
        x: 33,
        y: 2,
      },
    },
    {
      name: ':flag-at:',
      position: {
        x: 33,
        y: 3,
      },
    },
    {
      name: ':flag-au:',
      position: {
        x: 33,
        y: 4,
      },
    },
    {
      name: ':flag-aw:',
      position: {
        x: 33,
        y: 5,
      },
    },
    {
      name: ':flag-ax:',
      position: {
        x: 33,
        y: 6,
      },
    },
    {
      name: ':flag-az:',
      position: {
        x: 33,
        y: 7,
      },
    },
    {
      name: ':flag-ba:',
      position: {
        x: 33,
        y: 8,
      },
    },
    {
      name: ':flag-bb:',
      position: {
        x: 33,
        y: 9,
      },
    },
    {
      name: ':flag-bd:',
      position: {
        x: 33,
        y: 10,
      },
    },
    {
      name: ':flag-be:',
      position: {
        x: 33,
        y: 11,
      },
    },
    {
      name: ':flag-bf:',
      position: {
        x: 33,
        y: 12,
      },
    },
    {
      name: ':flag-bg:',
      position: {
        x: 33,
        y: 13,
      },
    },
    {
      name: ':flag-bh:',
      position: {
        x: 33,
        y: 14,
      },
    },
    {
      name: ':flag-bi:',
      position: {
        x: 33,
        y: 15,
      },
    },
    {
      name: ':flag-bj:',
      position: {
        x: 33,
        y: 16,
      },
    },
    {
      name: ':flag-bl:',
      position: {
        x: 33,
        y: 17,
      },
    },
    {
      name: ':flag-bm:',
      position: {
        x: 33,
        y: 18,
      },
    },
    {
      name: ':flag-bn:',
      position: {
        x: 33,
        y: 19,
      },
    },
    {
      name: ':flag-bo:',
      position: {
        x: 33,
        y: 20,
      },
    },
    {
      name: ':flag-bq:',
      position: {
        x: 33,
        y: 21,
      },
    },
    {
      name: ':flag-br:',
      position: {
        x: 33,
        y: 22,
      },
    },
    {
      name: ':flag-bs:',
      position: {
        x: 33,
        y: 23,
      },
    },
    {
      name: ':flag-bt:',
      position: {
        x: 33,
        y: 24,
      },
    },
    {
      name: ':flag-bv:',
      position: {
        x: 33,
        y: 25,
      },
    },
    {
      name: ':flag-bw:',
      position: {
        x: 33,
        y: 26,
      },
    },
    {
      name: ':flag-by:',
      position: {
        x: 33,
        y: 27,
      },
    },
    {
      name: ':flag-bz:',
      position: {
        x: 33,
        y: 28,
      },
    },
    {
      name: ':flag-ca:',
      position: {
        x: 33,
        y: 29,
      },
    },
    {
      name: ':flag-cc:',
      position: {
        x: 33,
        y: 30,
      },
    },
    {
      name: ':flag-cd:',
      position: {
        x: 33,
        y: 31,
      },
    },
    {
      name: ':flag-cf:',
      position: {
        x: 33,
        y: 32,
      },
    },
    {
      name: ':flag-cg:',
      position: {
        x: 33,
        y: 33,
      },
    },
    {
      name: ':flag-ch:',
      position: {
        x: 33,
        y: 34,
      },
    },
    {
      name: ':flag-ci:',
      position: {
        x: 33,
        y: 35,
      },
    },
    {
      name: ':flag-ck:',
      position: {
        x: 33,
        y: 36,
      },
    },
    {
      name: ':flag-cl:',
      position: {
        x: 33,
        y: 37,
      },
    },
    {
      name: ':flag-cm:',
      position: {
        x: 33,
        y: 38,
      },
    },
    {
      name: ':flag-cn:',
      position: {
        x: 33,
        y: 39,
      },
    },
    {
      name: ':flag-co:',
      position: {
        x: 33,
        y: 40,
      },
    },
    {
      name: ':flag-cp:',
      position: {
        x: 34,
        y: 0,
      },
    },
    {
      name: ':flag-cr:',
      position: {
        x: 34,
        y: 1,
      },
    },
    {
      name: ':flag-cu:',
      position: {
        x: 34,
        y: 2,
      },
    },
    {
      name: ':flag-cv:',
      position: {
        x: 34,
        y: 3,
      },
    },
    {
      name: ':flag-cw:',
      position: {
        x: 34,
        y: 4,
      },
    },
    {
      name: ':flag-cx:',
      position: {
        x: 34,
        y: 5,
      },
    },
    {
      name: ':flag-cy:',
      position: {
        x: 34,
        y: 6,
      },
    },
    {
      name: ':flag-cz:',
      position: {
        x: 34,
        y: 7,
      },
    },
    {
      name: ':flag-de:',
      position: {
        x: 34,
        y: 8,
      },
    },
    {
      name: ':flag-dg:',
      position: {
        x: 34,
        y: 9,
      },
    },
    {
      name: ':flag-dj:',
      position: {
        x: 34,
        y: 10,
      },
    },
    {
      name: ':flag-dk:',
      position: {
        x: 34,
        y: 11,
      },
    },
    {
      name: ':flag-dm:',
      position: {
        x: 34,
        y: 12,
      },
    },
    {
      name: ':flag-do:',
      position: {
        x: 34,
        y: 13,
      },
    },
    {
      name: ':flag-dz:',
      position: {
        x: 34,
        y: 14,
      },
    },
    {
      name: ':flag-ea:',
      position: {
        x: 34,
        y: 15,
      },
    },
    {
      name: ':flag-ec:',
      position: {
        x: 34,
        y: 16,
      },
    },
    {
      name: ':flag-ee:',
      position: {
        x: 34,
        y: 17,
      },
    },
    {
      name: ':flag-eg:',
      position: {
        x: 34,
        y: 18,
      },
    },
    {
      name: ':flag-eh:',
      position: {
        x: 34,
        y: 19,
      },
    },
    {
      name: ':flag-er:',
      position: {
        x: 34,
        y: 20,
      },
    },
    {
      name: ':flag-es:',
      position: {
        x: 34,
        y: 21,
      },
    },
    {
      name: ':flag-et:',
      position: {
        x: 34,
        y: 22,
      },
    },
    {
      name: ':flag-eu:',
      position: {
        x: 34,
        y: 23,
      },
    },
    {
      name: ':flag-fi:',
      position: {
        x: 34,
        y: 24,
      },
    },
    {
      name: ':flag-fj:',
      position: {
        x: 34,
        y: 25,
      },
    },
    {
      name: ':flag-fk:',
      position: {
        x: 34,
        y: 26,
      },
    },
    {
      name: ':flag-fm:',
      position: {
        x: 34,
        y: 27,
      },
    },
    {
      name: ':flag-fo:',
      position: {
        x: 34,
        y: 28,
      },
    },
    {
      name: ':flag-fr:',
      position: {
        x: 34,
        y: 29,
      },
    },
    {
      name: ':flag-ga:',
      position: {
        x: 34,
        y: 30,
      },
    },
    {
      name: ':flag-gb:',
      position: {
        x: 34,
        y: 31,
      },
    },
    {
      name: ':flag-gd:',
      position: {
        x: 34,
        y: 32,
      },
    },
    {
      name: ':flag-ge:',
      position: {
        x: 34,
        y: 33,
      },
    },
    {
      name: ':flag-gf:',
      position: {
        x: 34,
        y: 34,
      },
    },
    {
      name: ':flag-gg:',
      position: {
        x: 34,
        y: 35,
      },
    },
    {
      name: ':flag-gh:',
      position: {
        x: 34,
        y: 36,
      },
    },
    {
      name: ':flag-gi:',
      position: {
        x: 34,
        y: 37,
      },
    },
    {
      name: ':flag-gl:',
      position: {
        x: 34,
        y: 38,
      },
    },
    {
      name: ':flag-gm:',
      position: {
        x: 34,
        y: 39,
      },
    },
    {
      name: ':flag-gn:',
      position: {
        x: 34,
        y: 40,
      },
    },
    {
      name: ':flag-gp:',
      position: {
        x: 35,
        y: 0,
      },
    },
    {
      name: ':flag-gq:',
      position: {
        x: 35,
        y: 1,
      },
    },
    {
      name: ':flag-gr:',
      position: {
        x: 35,
        y: 2,
      },
    },
    {
      name: ':flag-gs:',
      position: {
        x: 35,
        y: 3,
      },
    },
    {
      name: ':flag-gt:',
      position: {
        x: 35,
        y: 4,
      },
    },
    {
      name: ':flag-gu:',
      position: {
        x: 35,
        y: 5,
      },
    },
    {
      name: ':flag-gw:',
      position: {
        x: 35,
        y: 6,
      },
    },
    {
      name: ':flag-gy:',
      position: {
        x: 35,
        y: 7,
      },
    },
    {
      name: ':flag-hk:',
      position: {
        x: 35,
        y: 8,
      },
    },
    {
      name: ':flag-hm:',
      position: {
        x: 35,
        y: 9,
      },
    },
    {
      name: ':flag-hn:',
      position: {
        x: 35,
        y: 10,
      },
    },
    {
      name: ':flag-hr:',
      position: {
        x: 35,
        y: 11,
      },
    },
    {
      name: ':flag-ht:',
      position: {
        x: 35,
        y: 12,
      },
    },
    {
      name: ':flag-hu:',
      position: {
        x: 35,
        y: 13,
      },
    },
    {
      name: ':flag-ic:',
      position: {
        x: 35,
        y: 14,
      },
    },
    {
      name: ':flag-id:',
      position: {
        x: 35,
        y: 15,
      },
    },
    {
      name: ':flag-ie:',
      position: {
        x: 35,
        y: 16,
      },
    },
    {
      name: ':flag-il:',
      position: {
        x: 35,
        y: 17,
      },
    },
    {
      name: ':flag-im:',
      position: {
        x: 35,
        y: 18,
      },
    },
    {
      name: ':flag-in:',
      position: {
        x: 35,
        y: 19,
      },
    },
    {
      name: ':flag-io:',
      position: {
        x: 35,
        y: 20,
      },
    },
    {
      name: ':flag-iq:',
      position: {
        x: 35,
        y: 21,
      },
    },
    {
      name: ':flag-ir:',
      position: {
        x: 35,
        y: 22,
      },
    },
    {
      name: ':flag-is:',
      position: {
        x: 35,
        y: 23,
      },
    },
    {
      name: ':flag-it:',
      position: {
        x: 35,
        y: 24,
      },
    },
    {
      name: ':flag-je:',
      position: {
        x: 35,
        y: 25,
      },
    },
    {
      name: ':flag-jm:',
      position: {
        x: 35,
        y: 26,
      },
    },
    {
      name: ':flag-jo:',
      position: {
        x: 35,
        y: 27,
      },
    },
    {
      name: ':flag-jp:',
      position: {
        x: 35,
        y: 28,
      },
    },
    {
      name: ':flag-ke:',
      position: {
        x: 35,
        y: 29,
      },
    },
    {
      name: ':flag-kg:',
      position: {
        x: 35,
        y: 30,
      },
    },
    {
      name: ':flag-kh:',
      position: {
        x: 35,
        y: 31,
      },
    },
    {
      name: ':flag-ki:',
      position: {
        x: 35,
        y: 32,
      },
    },
    {
      name: ':flag-km:',
      position: {
        x: 35,
        y: 33,
      },
    },
    {
      name: ':flag-kn:',
      position: {
        x: 35,
        y: 34,
      },
    },
    {
      name: ':flag-kp:',
      position: {
        x: 35,
        y: 35,
      },
    },
    {
      name: ':flag-kr:',
      position: {
        x: 35,
        y: 36,
      },
    },
    {
      name: ':flag-kw:',
      position: {
        x: 35,
        y: 37,
      },
    },
    {
      name: ':flag-ky:',
      position: {
        x: 35,
        y: 38,
      },
    },
    {
      name: ':flag-kz:',
      position: {
        x: 35,
        y: 39,
      },
    },
    {
      name: ':flag-la:',
      position: {
        x: 35,
        y: 40,
      },
    },
    {
      name: ':flag-lb:',
      position: {
        x: 36,
        y: 0,
      },
    },
    {
      name: ':flag-lc:',
      position: {
        x: 36,
        y: 1,
      },
    },
    {
      name: ':flag-li:',
      position: {
        x: 36,
        y: 2,
      },
    },
    {
      name: ':flag-lk:',
      position: {
        x: 36,
        y: 3,
      },
    },
    {
      name: ':flag-lr:',
      position: {
        x: 36,
        y: 4,
      },
    },
    {
      name: ':flag-ls:',
      position: {
        x: 36,
        y: 5,
      },
    },
    {
      name: ':flag-lt:',
      position: {
        x: 36,
        y: 6,
      },
    },
    {
      name: ':flag-lu:',
      position: {
        x: 36,
        y: 7,
      },
    },
    {
      name: ':flag-lv:',
      position: {
        x: 36,
        y: 8,
      },
    },
    {
      name: ':flag-ly:',
      position: {
        x: 36,
        y: 9,
      },
    },
    {
      name: ':flag-ma:',
      position: {
        x: 36,
        y: 10,
      },
    },
    {
      name: ':flag-mc:',
      position: {
        x: 36,
        y: 11,
      },
    },
    {
      name: ':flag-md:',
      position: {
        x: 36,
        y: 12,
      },
    },
    {
      name: ':flag-me:',
      position: {
        x: 36,
        y: 13,
      },
    },
    {
      name: ':flag-mf:',
      position: {
        x: 36,
        y: 14,
      },
    },
    {
      name: ':flag-mg:',
      position: {
        x: 36,
        y: 15,
      },
    },
    {
      name: ':flag-mh:',
      position: {
        x: 36,
        y: 16,
      },
    },
    {
      name: ':flag-mk:',
      position: {
        x: 36,
        y: 17,
      },
    },
    {
      name: ':flag-ml:',
      position: {
        x: 36,
        y: 18,
      },
    },
    {
      name: ':flag-mm:',
      position: {
        x: 36,
        y: 19,
      },
    },
    {
      name: ':flag-mn:',
      position: {
        x: 36,
        y: 20,
      },
    },
    {
      name: ':flag-mo:',
      position: {
        x: 36,
        y: 21,
      },
    },
    {
      name: ':flag-mp:',
      position: {
        x: 36,
        y: 22,
      },
    },
    {
      name: ':flag-mq:',
      position: {
        x: 36,
        y: 23,
      },
    },
    {
      name: ':flag-mr:',
      position: {
        x: 36,
        y: 24,
      },
    },
    {
      name: ':flag-ms:',
      position: {
        x: 36,
        y: 25,
      },
    },
    {
      name: ':flag-mt:',
      position: {
        x: 36,
        y: 26,
      },
    },
    {
      name: ':flag-mu:',
      position: {
        x: 36,
        y: 27,
      },
    },
    {
      name: ':flag-mv:',
      position: {
        x: 36,
        y: 28,
      },
    },
    {
      name: ':flag-mw:',
      position: {
        x: 36,
        y: 29,
      },
    },
    {
      name: ':flag-mx:',
      position: {
        x: 36,
        y: 30,
      },
    },
    {
      name: ':flag-my:',
      position: {
        x: 36,
        y: 31,
      },
    },
    {
      name: ':flag-mz:',
      position: {
        x: 36,
        y: 32,
      },
    },
    {
      name: ':flag-na:',
      position: {
        x: 36,
        y: 33,
      },
    },
    {
      name: ':flag-nc:',
      position: {
        x: 36,
        y: 34,
      },
    },
    {
      name: ':flag-ne:',
      position: {
        x: 36,
        y: 35,
      },
    },
    {
      name: ':flag-nf:',
      position: {
        x: 36,
        y: 36,
      },
    },
    {
      name: ':flag-ng:',
      position: {
        x: 36,
        y: 37,
      },
    },
    {
      name: ':flag-ni:',
      position: {
        x: 36,
        y: 38,
      },
    },
    {
      name: ':flag-nl:',
      position: {
        x: 36,
        y: 39,
      },
    },
    {
      name: ':flag-no:',
      position: {
        x: 36,
        y: 40,
      },
    },
    {
      name: ':flag-np:',
      position: {
        x: 37,
        y: 0,
      },
    },
    {
      name: ':flag-nr:',
      position: {
        x: 37,
        y: 1,
      },
    },
    {
      name: ':flag-nu:',
      position: {
        x: 37,
        y: 2,
      },
    },
    {
      name: ':flag-nz:',
      position: {
        x: 37,
        y: 3,
      },
    },
    {
      name: ':flag-om:',
      position: {
        x: 37,
        y: 4,
      },
    },
    {
      name: ':flag-pa:',
      position: {
        x: 37,
        y: 5,
      },
    },
    {
      name: ':flag-pe:',
      position: {
        x: 37,
        y: 6,
      },
    },
    {
      name: ':flag-pf:',
      position: {
        x: 37,
        y: 7,
      },
    },
    {
      name: ':flag-pg:',
      position: {
        x: 37,
        y: 8,
      },
    },
    {
      name: ':flag-ph:',
      position: {
        x: 37,
        y: 9,
      },
    },
    {
      name: ':flag-pk:',
      position: {
        x: 37,
        y: 10,
      },
    },
    {
      name: ':flag-pl:',
      position: {
        x: 37,
        y: 11,
      },
    },
    {
      name: ':flag-pm:',
      position: {
        x: 37,
        y: 12,
      },
    },
    {
      name: ':flag-pn:',
      position: {
        x: 37,
        y: 13,
      },
    },
    {
      name: ':flag-pr:',
      position: {
        x: 37,
        y: 14,
      },
    },
    {
      name: ':flag-ps:',
      position: {
        x: 37,
        y: 15,
      },
    },
    {
      name: ':flag-pt:',
      position: {
        x: 37,
        y: 16,
      },
    },
    {
      name: ':flag-pw:',
      position: {
        x: 37,
        y: 17,
      },
    },
    {
      name: ':flag-py:',
      position: {
        x: 37,
        y: 18,
      },
    },
    {
      name: ':flag-qa:',
      position: {
        x: 37,
        y: 19,
      },
    },
    {
      name: ':flag-re:',
      position: {
        x: 37,
        y: 20,
      },
    },
    {
      name: ':flag-ro:',
      position: {
        x: 37,
        y: 21,
      },
    },
    {
      name: ':flag-rs:',
      position: {
        x: 37,
        y: 22,
      },
    },
    {
      name: ':flag-ru:',
      position: {
        x: 37,
        y: 23,
      },
    },
    {
      name: ':flag-rw:',
      position: {
        x: 37,
        y: 24,
      },
    },
    {
      name: ':flag-sa:',
      position: {
        x: 37,
        y: 25,
      },
    },
    {
      name: ':flag-sb:',
      position: {
        x: 37,
        y: 26,
      },
    },
    {
      name: ':flag-sc:',
      position: {
        x: 37,
        y: 27,
      },
    },
    {
      name: ':flag-sd:',
      position: {
        x: 37,
        y: 28,
      },
    },
    {
      name: ':flag-se:',
      position: {
        x: 37,
        y: 29,
      },
    },
    {
      name: ':flag-sg:',
      position: {
        x: 37,
        y: 30,
      },
    },
    {
      name: ':flag-sh:',
      position: {
        x: 37,
        y: 31,
      },
    },
    {
      name: ':flag-si:',
      position: {
        x: 37,
        y: 32,
      },
    },
    {
      name: ':flag-sj:',
      position: {
        x: 37,
        y: 33,
      },
    },
    {
      name: ':flag-sk:',
      position: {
        x: 37,
        y: 34,
      },
    },
    {
      name: ':flag-sl:',
      position: {
        x: 37,
        y: 35,
      },
    },
    {
      name: ':flag-sm:',
      position: {
        x: 37,
        y: 36,
      },
    },
    {
      name: ':flag-sn:',
      position: {
        x: 37,
        y: 37,
      },
    },
    {
      name: ':flag-so:',
      position: {
        x: 37,
        y: 38,
      },
    },
    {
      name: ':flag-sr:',
      position: {
        x: 37,
        y: 39,
      },
    },
    {
      name: ':flag-ss:',
      position: {
        x: 37,
        y: 40,
      },
    },
    {
      name: ':flag-st:',
      position: {
        x: 38,
        y: 0,
      },
    },
    {
      name: ':flag-sv:',
      position: {
        x: 38,
        y: 1,
      },
    },
    {
      name: ':flag-sx:',
      position: {
        x: 38,
        y: 2,
      },
    },
    {
      name: ':flag-sy:',
      position: {
        x: 38,
        y: 3,
      },
    },
    {
      name: ':flag-sz:',
      position: {
        x: 38,
        y: 4,
      },
    },
    {
      name: ':flag-ta:',
      position: {
        x: 38,
        y: 5,
      },
    },
    {
      name: ':flag-tc:',
      position: {
        x: 38,
        y: 6,
      },
    },
    {
      name: ':flag-td:',
      position: {
        x: 38,
        y: 7,
      },
    },
    {
      name: ':flag-tf:',
      position: {
        x: 38,
        y: 8,
      },
    },
    {
      name: ':flag-tg:',
      position: {
        x: 38,
        y: 9,
      },
    },
    {
      name: ':flag-th:',
      position: {
        x: 38,
        y: 10,
      },
    },
    {
      name: ':flag-tj:',
      position: {
        x: 38,
        y: 11,
      },
    },
    {
      name: ':flag-tk:',
      position: {
        x: 38,
        y: 12,
      },
    },
    {
      name: ':flag-tl:',
      position: {
        x: 38,
        y: 13,
      },
    },
    {
      name: ':flag-tm:',
      position: {
        x: 38,
        y: 14,
      },
    },
    {
      name: ':flag-tn:',
      position: {
        x: 38,
        y: 15,
      },
    },
    {
      name: ':flag-to:',
      position: {
        x: 38,
        y: 16,
      },
    },
    {
      name: ':flag-tr:',
      position: {
        x: 38,
        y: 17,
      },
    },
    {
      name: ':flag-tt:',
      position: {
        x: 38,
        y: 18,
      },
    },
    {
      name: ':flag-tv:',
      position: {
        x: 38,
        y: 19,
      },
    },
    {
      name: ':flag-tw:',
      position: {
        x: 38,
        y: 20,
      },
    },
    {
      name: ':flag-tz:',
      position: {
        x: 38,
        y: 21,
      },
    },
    {
      name: ':flag-ua:',
      position: {
        x: 38,
        y: 22,
      },
    },
    {
      name: ':flag-ug:',
      position: {
        x: 38,
        y: 23,
      },
    },
    {
      name: ':flag-um:',
      position: {
        x: 38,
        y: 24,
      },
    },
    {
      name: ':flag-us:',
      position: {
        x: 38,
        y: 25,
      },
    },
    {
      name: ':flag-uy:',
      position: {
        x: 38,
        y: 26,
      },
    },
    {
      name: ':flag-uz:',
      position: {
        x: 38,
        y: 27,
      },
    },
    {
      name: ':flag-va:',
      position: {
        x: 38,
        y: 28,
      },
    },
    {
      name: ':flag-vc:',
      position: {
        x: 38,
        y: 29,
      },
    },
    {
      name: ':flag-ve:',
      position: {
        x: 38,
        y: 30,
      },
    },
    {
      name: ':flag-vg:',
      position: {
        x: 38,
        y: 31,
      },
    },
    {
      name: ':flag-vi:',
      position: {
        x: 38,
        y: 32,
      },
    },
    {
      name: ':flag-vn:',
      position: {
        x: 38,
        y: 33,
      },
    },
    {
      name: ':flag-vu:',
      position: {
        x: 38,
        y: 34,
      },
    },
    {
      name: ':flag-wf:',
      position: {
        x: 38,
        y: 35,
      },
    },
    {
      name: ':flag-ws:',
      position: {
        x: 38,
        y: 36,
      },
    },
    {
      name: ':flag-xk:',
      position: {
        x: 38,
        y: 37,
      },
    },
    {
      name: ':flag-ye:',
      position: {
        x: 38,
        y: 38,
      },
    },
    {
      name: ':flag-yt:',
      position: {
        x: 38,
        y: 39,
      },
    },
    {
      name: ':flag-za:',
      position: {
        x: 38,
        y: 40,
      },
    },
    {
      name: ':flag-zm:',
      position: {
        x: 39,
        y: 0,
      },
    },
    {
      name: ':flag-zw:',
      position: {
        x: 39,
        y: 1,
      },
    },
  ],
};

/**
 * Список нативных unicode emoji
 * https://gist.github.com/dbgso/fb12233fc6360393b08e3e96cedfbf5b
 *
 * @type {Object.<String>}
 */
export const NATIVE_EMOJI: EmojiNative = {
  ':100:': '&#x1F4AF;',
  ':1234:': '&#x1F522;',
  ':hash:': '&#x0023;&#xFE0F;&#x20E3;',
  ':keycap_star:': '&#x002A;&#xFE0F;&#x20E3;',
  ':zero:': '&#x0030;&#xFE0F;&#x20E3;',
  ':one:': '&#x0031;&#xFE0F;&#x20E3;',
  ':two:': '&#x0032;&#xFE0F;&#x20E3;',
  ':three:': '&#x0033;&#xFE0F;&#x20E3;',
  ':four:': '&#x0034;&#xFE0F;&#x20E3;',
  ':five:': '&#x0035;&#xFE0F;&#x20E3;',
  ':six:': '&#x0036;&#xFE0F;&#x20E3;',
  ':seven:': '&#x0037;&#xFE0F;&#x20E3;',
  ':eight:': '&#x0038;&#xFE0F;&#x20E3;',
  ':nine:': '&#x0039;&#xFE0F;&#x20E3;',
  ':copyright:': '&#x00A9;&#xFE0F;',
  ':registered:': '&#x00AE;&#xFE0F;',
  ':mahjong:': '&#x1F004;',
  ':black_joker:': '&#x1F0CF;',
  ':a:': '&#x1F170;&#xFE0F;',
  ':b:': '&#x1F171;&#xFE0F;',
  ':o2:': '&#x1F17E;&#xFE0F;',
  ':parking:': '&#x1F17F;&#xFE0F;',
  ':ab:': '&#x1F18E;',
  ':cl:': '&#x1F191;',
  ':cool:': '&#x1F192;',
  ':free:': '&#x1F193;',
  ':id:': '&#x1F194;',
  ':new:': '&#x1F195;',
  ':ng:': '&#x1F196;',
  ':ok:': '&#x1F197;',
  ':sos:': '&#x1F198;',
  ':up:': '&#x1F199;',
  ':vs:': '&#x1F19A;',
  ':flag-ac:': '&#x1F1E6;&#x1F1E8;',
  ':flag-ad:': '&#x1F1E6;&#x1F1E9;',
  ':flag-ae:': '&#x1F1E6;&#x1F1EA;',
  ':flag-af:': '&#x1F1E6;&#x1F1EB;',
  ':flag-ag:': '&#x1F1E6;&#x1F1EC;',
  ':flag-ai:': '&#x1F1E6;&#x1F1EE;',
  ':flag-al:': '&#x1F1E6;&#x1F1F1;',
  ':flag-am:': '&#x1F1E6;&#x1F1F2;',
  ':flag-ao:': '&#x1F1E6;&#x1F1F4;',
  ':flag-aq:': '&#x1F1E6;&#x1F1F6;',
  ':flag-ar:': '&#x1F1E6;&#x1F1F7;',
  ':flag-as:': '&#x1F1E6;&#x1F1F8;',
  ':flag-at:': '&#x1F1E6;&#x1F1F9;',
  ':flag-au:': '&#x1F1E6;&#x1F1FA;',
  ':flag-aw:': '&#x1F1E6;&#x1F1FC;',
  ':flag-ax:': '&#x1F1E6;&#x1F1FD;',
  ':flag-az:': '&#x1F1E6;&#x1F1FF;',
  ':flag-ba:': '&#x1F1E7;&#x1F1E6;',
  ':flag-bb:': '&#x1F1E7;&#x1F1E7;',
  ':flag-bd:': '&#x1F1E7;&#x1F1E9;',
  ':flag-be:': '&#x1F1E7;&#x1F1EA;',
  ':flag-bf:': '&#x1F1E7;&#x1F1EB;',
  ':flag-bg:': '&#x1F1E7;&#x1F1EC;',
  ':flag-bh:': '&#x1F1E7;&#x1F1ED;',
  ':flag-bi:': '&#x1F1E7;&#x1F1EE;',
  ':flag-bj:': '&#x1F1E7;&#x1F1EF;',
  ':flag-bl:': '&#x1F1E7;&#x1F1F1;',
  ':flag-bm:': '&#x1F1E7;&#x1F1F2;',
  ':flag-bn:': '&#x1F1E7;&#x1F1F3;',
  ':flag-bo:': '&#x1F1E7;&#x1F1F4;',
  ':flag-bq:': '&#x1F1E7;&#x1F1F6;',
  ':flag-br:': '&#x1F1E7;&#x1F1F7;',
  ':flag-bs:': '&#x1F1E7;&#x1F1F8;',
  ':flag-bt:': '&#x1F1E7;&#x1F1F9;',
  ':flag-bv:': '&#x1F1E7;&#x1F1FB;',
  ':flag-bw:': '&#x1F1E7;&#x1F1FC;',
  ':flag-by:': '&#x1F1E7;&#x1F1FE;',
  ':flag-bz:': '&#x1F1E7;&#x1F1FF;',
  ':flag-ca:': '&#x1F1E8;&#x1F1E6;',
  ':flag-cc:': '&#x1F1E8;&#x1F1E8;',
  ':flag-cd:': '&#x1F1E8;&#x1F1E9;',
  ':flag-cf:': '&#x1F1E8;&#x1F1EB;',
  ':flag-cg:': '&#x1F1E8;&#x1F1EC;',
  ':flag-ch:': '&#x1F1E8;&#x1F1ED;',
  ':flag-ci:': '&#x1F1E8;&#x1F1EE;',
  ':flag-ck:': '&#x1F1E8;&#x1F1F0;',
  ':flag-cl:': '&#x1F1E8;&#x1F1F1;',
  ':flag-cm:': '&#x1F1E8;&#x1F1F2;',
  ':cn:': '&#x1F1E8;&#x1F1F3;',
  ':flag-co:': '&#x1F1E8;&#x1F1F4;',
  ':flag-cp:': '&#x1F1E8;&#x1F1F5;',
  ':flag-cr:': '&#x1F1E8;&#x1F1F7;',
  ':flag-cu:': '&#x1F1E8;&#x1F1FA;',
  ':flag-cv:': '&#x1F1E8;&#x1F1FB;',
  ':flag-cw:': '&#x1F1E8;&#x1F1FC;',
  ':flag-cx:': '&#x1F1E8;&#x1F1FD;',
  ':flag-cy:': '&#x1F1E8;&#x1F1FE;',
  ':flag-cz:': '&#x1F1E8;&#x1F1FF;',
  ':de:': '&#x1F1E9;&#x1F1EA;',
  ':flag-dg:': '&#x1F1E9;&#x1F1EC;',
  ':flag-dj:': '&#x1F1E9;&#x1F1EF;',
  ':flag-dk:': '&#x1F1E9;&#x1F1F0;',
  ':flag-dm:': '&#x1F1E9;&#x1F1F2;',
  ':flag-do:': '&#x1F1E9;&#x1F1F4;',
  ':flag-dz:': '&#x1F1E9;&#x1F1FF;',
  ':flag-ea:': '&#x1F1EA;&#x1F1E6;',
  ':flag-ec:': '&#x1F1EA;&#x1F1E8;',
  ':flag-ee:': '&#x1F1EA;&#x1F1EA;',
  ':flag-eg:': '&#x1F1EA;&#x1F1EC;',
  ':flag-eh:': '&#x1F1EA;&#x1F1ED;',
  ':flag-er:': '&#x1F1EA;&#x1F1F7;',
  ':es:': '&#x1F1EA;&#x1F1F8;',
  ':flag-et:': '&#x1F1EA;&#x1F1F9;',
  ':flag-eu:': '&#x1F1EA;&#x1F1FA;',
  ':flag-fi:': '&#x1F1EB;&#x1F1EE;',
  ':flag-fj:': '&#x1F1EB;&#x1F1EF;',
  ':flag-fk:': '&#x1F1EB;&#x1F1F0;',
  ':flag-fm:': '&#x1F1EB;&#x1F1F2;',
  ':flag-fo:': '&#x1F1EB;&#x1F1F4;',
  ':fr:': '&#x1F1EB;&#x1F1F7;',
  ':flag-ga:': '&#x1F1EC;&#x1F1E6;',
  ':gb:': '&#x1F1EC;&#x1F1E7;',
  ':flag-gd:': '&#x1F1EC;&#x1F1E9;',
  ':flag-ge:': '&#x1F1EC;&#x1F1EA;',
  ':flag-gf:': '&#x1F1EC;&#x1F1EB;',
  ':flag-gg:': '&#x1F1EC;&#x1F1EC;',
  ':flag-gh:': '&#x1F1EC;&#x1F1ED;',
  ':flag-gi:': '&#x1F1EC;&#x1F1EE;',
  ':flag-gl:': '&#x1F1EC;&#x1F1F1;',
  ':flag-gm:': '&#x1F1EC;&#x1F1F2;',
  ':flag-gn:': '&#x1F1EC;&#x1F1F3;',
  ':flag-gp:': '&#x1F1EC;&#x1F1F5;',
  ':flag-gq:': '&#x1F1EC;&#x1F1F6;',
  ':flag-gr:': '&#x1F1EC;&#x1F1F7;',
  ':flag-gs:': '&#x1F1EC;&#x1F1F8;',
  ':flag-gt:': '&#x1F1EC;&#x1F1F9;',
  ':flag-gu:': '&#x1F1EC;&#x1F1FA;',
  ':flag-gw:': '&#x1F1EC;&#x1F1FC;',
  ':flag-gy:': '&#x1F1EC;&#x1F1FE;',
  ':flag-hk:': '&#x1F1ED;&#x1F1F0;',
  ':flag-hm:': '&#x1F1ED;&#x1F1F2;',
  ':flag-hn:': '&#x1F1ED;&#x1F1F3;',
  ':flag-hr:': '&#x1F1ED;&#x1F1F7;',
  ':flag-ht:': '&#x1F1ED;&#x1F1F9;',
  ':flag-hu:': '&#x1F1ED;&#x1F1FA;',
  ':flag-ic:': '&#x1F1EE;&#x1F1E8;',
  ':flag-id:': '&#x1F1EE;&#x1F1E9;',
  ':flag-ie:': '&#x1F1EE;&#x1F1EA;',
  ':flag-il:': '&#x1F1EE;&#x1F1F1;',
  ':flag-im:': '&#x1F1EE;&#x1F1F2;',
  ':flag-in:': '&#x1F1EE;&#x1F1F3;',
  ':flag-io:': '&#x1F1EE;&#x1F1F4;',
  ':flag-iq:': '&#x1F1EE;&#x1F1F6;',
  ':flag-ir:': '&#x1F1EE;&#x1F1F7;',
  ':flag-is:': '&#x1F1EE;&#x1F1F8;',
  ':it:': '&#x1F1EE;&#x1F1F9;',
  ':flag-je:': '&#x1F1EF;&#x1F1EA;',
  ':flag-jm:': '&#x1F1EF;&#x1F1F2;',
  ':flag-jo:': '&#x1F1EF;&#x1F1F4;',
  ':flag-jp:': '&#x1F1EF;&#x1F1F5;',
  ':flag-ke:': '&#x1F1F0;&#x1F1EA;',
  ':flag-kg:': '&#x1F1F0;&#x1F1EC;',
  ':flag-kh:': '&#x1F1F0;&#x1F1ED;',
  ':flag-ki:': '&#x1F1F0;&#x1F1EE;',
  ':flag-km:': '&#x1F1F0;&#x1F1F2;',
  ':flag-kn:': '&#x1F1F0;&#x1F1F3;',
  ':flag-kp:': '&#x1F1F0;&#x1F1F5;',
  ':kr:': '&#x1F1F0;&#x1F1F7;',
  ':flag-kw:': '&#x1F1F0;&#x1F1FC;',
  ':flag-ky:': '&#x1F1F0;&#x1F1FE;',
  ':flag-kz:': '&#x1F1F0;&#x1F1FF;',
  ':flag-la:': '&#x1F1F1;&#x1F1E6;',
  ':flag-lb:': '&#x1F1F1;&#x1F1E7;',
  ':flag-lc:': '&#x1F1F1;&#x1F1E8;',
  ':flag-li:': '&#x1F1F1;&#x1F1EE;',
  ':flag-lk:': '&#x1F1F1;&#x1F1F0;',
  ':flag-lr:': '&#x1F1F1;&#x1F1F7;',
  ':flag-ls:': '&#x1F1F1;&#x1F1F8;',
  ':flag-lt:': '&#x1F1F1;&#x1F1F9;',
  ':flag-lu:': '&#x1F1F1;&#x1F1FA;',
  ':flag-lv:': '&#x1F1F1;&#x1F1FB;',
  ':flag-ly:': '&#x1F1F1;&#x1F1FE;',
  ':flag-ma:': '&#x1F1F2;&#x1F1E6;',
  ':flag-mc:': '&#x1F1F2;&#x1F1E8;',
  ':flag-md:': '&#x1F1F2;&#x1F1E9;',
  ':flag-me:': '&#x1F1F2;&#x1F1EA;',
  ':flag-mf:': '&#x1F1F2;&#x1F1EB;',
  ':flag-mg:': '&#x1F1F2;&#x1F1EC;',
  ':flag-mh:': '&#x1F1F2;&#x1F1ED;',
  ':flag-mk:': '&#x1F1F2;&#x1F1F0;',
  ':flag-ml:': '&#x1F1F2;&#x1F1F1;',
  ':flag-mm:': '&#x1F1F2;&#x1F1F2;',
  ':flag-mn:': '&#x1F1F2;&#x1F1F3;',
  ':flag-mo:': '&#x1F1F2;&#x1F1F4;',
  ':flag-mp:': '&#x1F1F2;&#x1F1F5;',
  ':flag-mq:': '&#x1F1F2;&#x1F1F6;',
  ':flag-mr:': '&#x1F1F2;&#x1F1F7;',
  ':flag-ms:': '&#x1F1F2;&#x1F1F8;',
  ':flag-mt:': '&#x1F1F2;&#x1F1F9;',
  ':flag-mu:': '&#x1F1F2;&#x1F1FA;',
  ':flag-mv:': '&#x1F1F2;&#x1F1FB;',
  ':flag-mw:': '&#x1F1F2;&#x1F1FC;',
  ':flag-mx:': '&#x1F1F2;&#x1F1FD;',
  ':flag-my:': '&#x1F1F2;&#x1F1FE;',
  ':flag-mz:': '&#x1F1F2;&#x1F1FF;',
  ':flag-na:': '&#x1F1F3;&#x1F1E6;',
  ':flag-nc:': '&#x1F1F3;&#x1F1E8;',
  ':flag-ne:': '&#x1F1F3;&#x1F1EA;',
  ':flag-nf:': '&#x1F1F3;&#x1F1EB;',
  ':flag-ng:': '&#x1F1F3;&#x1F1EC;',
  ':flag-ni:': '&#x1F1F3;&#x1F1EE;',
  ':flag-nl:': '&#x1F1F3;&#x1F1F1;',
  ':flag-no:': '&#x1F1F3;&#x1F1F4;',
  ':flag-np:': '&#x1F1F3;&#x1F1F5;',
  ':flag-nr:': '&#x1F1F3;&#x1F1F7;',
  ':flag-nu:': '&#x1F1F3;&#x1F1FA;',
  ':flag-nz:': '&#x1F1F3;&#x1F1FF;',
  ':flag-om:': '&#x1F1F4;&#x1F1F2;',
  ':flag-pa:': '&#x1F1F5;&#x1F1E6;',
  ':flag-pe:': '&#x1F1F5;&#x1F1EA;',
  ':flag-pf:': '&#x1F1F5;&#x1F1EB;',
  ':flag-pg:': '&#x1F1F5;&#x1F1EC;',
  ':flag-ph:': '&#x1F1F5;&#x1F1ED;',
  ':flag-pk:': '&#x1F1F5;&#x1F1F0;',
  ':flag-pl:': '&#x1F1F5;&#x1F1F1;',
  ':flag-pm:': '&#x1F1F5;&#x1F1F2;',
  ':flag-pn:': '&#x1F1F5;&#x1F1F3;',
  ':flag-pr:': '&#x1F1F5;&#x1F1F7;',
  ':flag-ps:': '&#x1F1F5;&#x1F1F8;',
  ':flag-pt:': '&#x1F1F5;&#x1F1F9;',
  ':flag-pw:': '&#x1F1F5;&#x1F1FC;',
  ':flag-py:': '&#x1F1F5;&#x1F1FE;',
  ':flag-qa:': '&#x1F1F6;&#x1F1E6;',
  ':flag-re:': '&#x1F1F7;&#x1F1EA;',
  ':flag-ro:': '&#x1F1F7;&#x1F1F4;',
  ':flag-rs:': '&#x1F1F7;&#x1F1F8;',
  ':ru:': '&#x1F1F7;&#x1F1FA;',
  ':flag-rw:': '&#x1F1F7;&#x1F1FC;',
  ':flag-sa:': '&#x1F1F8;&#x1F1E6;',
  ':flag-sb:': '&#x1F1F8;&#x1F1E7;',
  ':flag-sc:': '&#x1F1F8;&#x1F1E8;',
  ':flag-sd:': '&#x1F1F8;&#x1F1E9;',
  ':flag-se:': '&#x1F1F8;&#x1F1EA;',
  ':flag-sg:': '&#x1F1F8;&#x1F1EC;',
  ':flag-sh:': '&#x1F1F8;&#x1F1ED;',
  ':flag-si:': '&#x1F1F8;&#x1F1EE;',
  ':flag-sj:': '&#x1F1F8;&#x1F1EF;',
  ':flag-sk:': '&#x1F1F8;&#x1F1F0;',
  ':flag-sl:': '&#x1F1F8;&#x1F1F1;',
  ':flag-sm:': '&#x1F1F8;&#x1F1F2;',
  ':flag-sn:': '&#x1F1F8;&#x1F1F3;',
  ':flag-so:': '&#x1F1F8;&#x1F1F4;',
  ':flag-sr:': '&#x1F1F8;&#x1F1F7;',
  ':flag-ss:': '&#x1F1F8;&#x1F1F8;',
  ':flag-st:': '&#x1F1F8;&#x1F1F9;',
  ':flag-sv:': '&#x1F1F8;&#x1F1FB;',
  ':flag-sx:': '&#x1F1F8;&#x1F1FD;',
  ':flag-sy:': '&#x1F1F8;&#x1F1FE;',
  ':flag-sz:': '&#x1F1F8;&#x1F1FF;',
  ':flag-ta:': '&#x1F1F9;&#x1F1E6;',
  ':flag-tc:': '&#x1F1F9;&#x1F1E8;',
  ':flag-td:': '&#x1F1F9;&#x1F1E9;',
  ':flag-tf:': '&#x1F1F9;&#x1F1EB;',
  ':flag-tg:': '&#x1F1F9;&#x1F1EC;',
  ':flag-th:': '&#x1F1F9;&#x1F1ED;',
  ':flag-tj:': '&#x1F1F9;&#x1F1EF;',
  ':flag-tk:': '&#x1F1F9;&#x1F1F0;',
  ':flag-tl:': '&#x1F1F9;&#x1F1F1;',
  ':flag-tm:': '&#x1F1F9;&#x1F1F2;',
  ':flag-tn:': '&#x1F1F9;&#x1F1F3;',
  ':flag-to:': '&#x1F1F9;&#x1F1F4;',
  ':flag-tr:': '&#x1F1F9;&#x1F1F7;',
  ':flag-tt:': '&#x1F1F9;&#x1F1F9;',
  ':flag-tv:': '&#x1F1F9;&#x1F1FB;',
  ':flag-tw:': '&#x1F1F9;&#x1F1FC;',
  ':flag-tz:': '&#x1F1F9;&#x1F1FF;',
  ':flag-ua:': '&#x1F1FA;&#x1F1E6;',
  ':flag-ug:': '&#x1F1FA;&#x1F1EC;',
  ':flag-um:': '&#x1F1FA;&#x1F1F2;',
  ':flag-un:': '&#x1F1FA;&#x1F1F3;',
  ':flag-us:': '&#x1F1FA;&#x1F1F8;',
  ':flag-uy:': '&#x1F1FA;&#x1F1FE;',
  ':flag-uz:': '&#x1F1FA;&#x1F1FF;',
  ':flag-va:': '&#x1F1FB;&#x1F1E6;',
  ':flag-vc:': '&#x1F1FB;&#x1F1E8;',
  ':flag-ve:': '&#x1F1FB;&#x1F1EA;',
  ':flag-vg:': '&#x1F1FB;&#x1F1EC;',
  ':flag-vi:': '&#x1F1FB;&#x1F1EE;',
  ':flag-vn:': '&#x1F1FB;&#x1F1F3;',
  ':flag-vu:': '&#x1F1FB;&#x1F1FA;',
  ':flag-wf:': '&#x1F1FC;&#x1F1EB;',
  ':flag-ws:': '&#x1F1FC;&#x1F1F8;',
  ':flag-xk:': '&#x1F1FD;&#x1F1F0;',
  ':flag-ye:': '&#x1F1FE;&#x1F1EA;',
  ':flag-yt:': '&#x1F1FE;&#x1F1F9;',
  ':flag-za:': '&#x1F1FF;&#x1F1E6;',
  ':flag-zm:': '&#x1F1FF;&#x1F1F2;',
  ':flag-zw:': '&#x1F1FF;&#x1F1FC;',
  ':koko:': '&#x1F201;',
  ':sa:': '&#x1F202;&#xFE0F;',
  ':u7121:': '&#x1F21A;',
  ':u6307:': '&#x1F22F;',
  ':u7981:': '&#x1F232;',
  ':u7a7a:': '&#x1F233;',
  ':u5408:': '&#x1F234;',
  ':u6e80:': '&#x1F235;',
  ':u6709:': '&#x1F236;',
  ':u6708:': '&#x1F237;&#xFE0F;',
  ':u7533:': '&#x1F238;',
  ':u5272:': '&#x1F239;',
  ':u55b6:': '&#x1F23A;',
  ':ideograph_advantage:': '&#x1F250;',
  ':accept:': '&#x1F251;',
  ':cyclone:': '&#x1F300;',
  ':foggy:': '&#x1F301;',
  ':closed_umbrella:': '&#x1F302;',
  ':night_with_stars:': '&#x1F303;',
  ':sunrise_over_mountains:': '&#x1F304;',
  ':sunrise:': '&#x1F305;',
  ':city_sunset:': '&#x1F306;',
  ':city_sunrise:': '&#x1F307;',
  ':rainbow:': '&#x1F308;',
  ':bridge_at_night:': '&#x1F309;',
  ':ocean:': '&#x1F30A;',
  ':volcano:': '&#x1F30B;',
  ':milky_way:': '&#x1F30C;',
  ':earth_africa:': '&#x1F30D;',
  ':earth_americas:': '&#x1F30E;',
  ':earth_asia:': '&#x1F30F;',
  ':globe_with_meridians:': '&#x1F310;',
  ':new_moon:': '&#x1F311;',
  ':waxing_crescent_moon:': '&#x1F312;',
  ':first_quarter_moon:': '&#x1F313;',
  ':moon:': '&#x1F314;',
  ':full_moon:': '&#x1F315;',
  ':waning_gibbous_moon:': '&#x1F316;',
  ':last_quarter_moon:': '&#x1F317;',
  ':waning_crescent_moon:': '&#x1F318;',
  ':crescent_moon:': '&#x1F319;',
  ':new_moon_with_face:': '&#x1F31A;',
  ':first_quarter_moon_with_face:': '&#x1F31B;',
  ':last_quarter_moon_with_face:': '&#x1F31C;',
  ':full_moon_with_face:': '&#x1F31D;',
  ':sun_with_face:': '&#x1F31E;',
  ':star2:': '&#x1F31F;',
  ':stars:': '&#x1F320;',
  ':thermometer:': '&#x1F321;&#xFE0F;',
  ':mostly_sunny:': '&#x1F324;&#xFE0F;',
  ':barely_sunny:': '&#x1F325;&#xFE0F;',
  ':partly_sunny_rain:': '&#x1F326;&#xFE0F;',
  ':rain_cloud:': '&#x1F327;&#xFE0F;',
  ':snow_cloud:': '&#x1F328;&#xFE0F;',
  ':lightning:': '&#x1F329;&#xFE0F;',
  ':tornado:': '&#x1F32A;&#xFE0F;',
  ':fog:': '&#x1F32B;&#xFE0F;',
  ':wind_blowing_face:': '&#x1F32C;&#xFE0F;',
  ':hotdog:': '&#x1F32D;',
  ':taco:': '&#x1F32E;',
  ':burrito:': '&#x1F32F;',
  ':chestnut:': '&#x1F330;',
  ':seedling:': '&#x1F331;',
  ':evergreen_tree:': '&#x1F332;',
  ':deciduous_tree:': '&#x1F333;',
  ':palm_tree:': '&#x1F334;',
  ':cactus:': '&#x1F335;',
  ':hot_pepper:': '&#x1F336;&#xFE0F;',
  ':tulip:': '&#x1F337;',
  ':cherry_blossom:': '&#x1F338;',
  ':rose:': '&#x1F339;',
  ':hibiscus:': '&#x1F33A;',
  ':sunflower:': '&#x1F33B;',
  ':blossom:': '&#x1F33C;',
  ':corn:': '&#x1F33D;',
  ':ear_of_rice:': '&#x1F33E;',
  ':herb:': '&#x1F33F;',
  ':four_leaf_clover:': '&#x1F340;',
  ':maple_leaf:': '&#x1F341;',
  ':fallen_leaf:': '&#x1F342;',
  ':leaves:': '&#x1F343;',
  ':mushroom:': '&#x1F344;',
  ':tomato:': '&#x1F345;',
  ':eggplant:': '&#x1F346;',
  ':grapes:': '&#x1F347;',
  ':melon:': '&#x1F348;',
  ':watermelon:': '&#x1F349;',
  ':tangerine:': '&#x1F34A;',
  ':lemon:': '&#x1F34B;',
  ':banana:': '&#x1F34C;',
  ':pineapple:': '&#x1F34D;',
  ':apple:': '&#x1F34E;',
  ':green_apple:': '&#x1F34F;',
  ':pear:': '&#x1F350;',
  ':peach:': '&#x1F351;',
  ':cherries:': '&#x1F352;',
  ':strawberry:': '&#x1F353;',
  ':hamburger:': '&#x1F354;',
  ':pizza:': '&#x1F355;',
  ':meat_on_bone:': '&#x1F356;',
  ':poultry_leg:': '&#x1F357;',
  ':rice_cracker:': '&#x1F358;',
  ':rice_ball:': '&#x1F359;',
  ':rice:': '&#x1F35A;',
  ':curry:': '&#x1F35B;',
  ':ramen:': '&#x1F35C;',
  ':spaghetti:': '&#x1F35D;',
  ':bread:': '&#x1F35E;',
  ':fries:': '&#x1F35F;',
  ':sweet_potato:': '&#x1F360;',
  ':dango:': '&#x1F361;',
  ':oden:': '&#x1F362;',
  ':sushi:': '&#x1F363;',
  ':fried_shrimp:': '&#x1F364;',
  ':fish_cake:': '&#x1F365;',
  ':icecream:': '&#x1F366;',
  ':shaved_ice:': '&#x1F367;',
  ':ice_cream:': '&#x1F368;',
  ':doughnut:': '&#x1F369;',
  ':cookie:': '&#x1F36A;',
  ':chocolate_bar:': '&#x1F36B;',
  ':candy:': '&#x1F36C;',
  ':lollipop:': '&#x1F36D;',
  ':custard:': '&#x1F36E;',
  ':honey_pot:': '&#x1F36F;',
  ':cake:': '&#x1F370;',
  ':bento:': '&#x1F371;',
  ':stew:': '&#x1F372;',
  ':fried_egg:': '&#x1F373;',
  ':fork_and_knife:': '&#x1F374;',
  ':tea:': '&#x1F375;',
  ':sake:': '&#x1F376;',
  ':wine_glass:': '&#x1F377;',
  ':cocktail:': '&#x1F378;',
  ':tropical_drink:': '&#x1F379;',
  ':beer:': '&#x1F37A;',
  ':beers:': '&#x1F37B;',
  ':baby_bottle:': '&#x1F37C;',
  ':knife_fork_plate:': '&#x1F37D;&#xFE0F;',
  ':champagne:': '&#x1F37E;',
  ':popcorn:': '&#x1F37F;',
  ':ribbon:': '&#x1F380;',
  ':gift:': '&#x1F381;',
  ':birthday:': '&#x1F382;',
  ':jack_o_lantern:': '&#x1F383;',
  ':christmas_tree:': '&#x1F384;',
  ':santa:': '&#x1F385;',
  ':fireworks:': '&#x1F386;',
  ':sparkler:': '&#x1F387;',
  ':balloon:': '&#x1F388;',
  ':tada:': '&#x1F389;',
  ':confetti_ball:': '&#x1F38A;',
  ':tanabata_tree:': '&#x1F38B;',
  ':crossed_flags:': '&#x1F38C;',
  ':bamboo:': '&#x1F38D;',
  ':dolls:': '&#x1F38E;',
  ':flags:': '&#x1F38F;',
  ':wind_chime:': '&#x1F390;',
  ':rice_scene:': '&#x1F391;',
  ':school_satchel:': '&#x1F392;',
  ':mortar_board:': '&#x1F393;',
  ':medal:': '&#x1F396;&#xFE0F;',
  ':reminder_ribbon:': '&#x1F397;&#xFE0F;',
  ':studio_microphone:': '&#x1F399;&#xFE0F;',
  ':level_slider:': '&#x1F39A;&#xFE0F;',
  ':control_knobs:': '&#x1F39B;&#xFE0F;',
  ':film_frames:': '&#x1F39E;&#xFE0F;',
  ':admission_tickets:': '&#x1F39F;&#xFE0F;',
  ':carousel_horse:': '&#x1F3A0;',
  ':ferris_wheel:': '&#x1F3A1;',
  ':roller_coaster:': '&#x1F3A2;',
  ':fishing_pole_and_fish:': '&#x1F3A3;',
  ':microphone:': '&#x1F3A4;',
  ':movie_camera:': '&#x1F3A5;',
  ':cinema:': '&#x1F3A6;',
  ':headphones:': '&#x1F3A7;',
  ':art:': '&#x1F3A8;',
  ':tophat:': '&#x1F3A9;',
  ':circus_tent:': '&#x1F3AA;',
  ':ticket:': '&#x1F3AB;',
  ':clapper:': '&#x1F3AC;',
  ':performing_arts:': '&#x1F3AD;',
  ':video_game:': '&#x1F3AE;',
  ':dart:': '&#x1F3AF;',
  ':slot_machine:': '&#x1F3B0;',
  ':8ball:': '&#x1F3B1;',
  ':game_die:': '&#x1F3B2;',
  ':bowling:': '&#x1F3B3;',
  ':flower_playing_cards:': '&#x1F3B4;',
  ':musical_note:': '&#x1F3B5;',
  ':notes:': '&#x1F3B6;',
  ':saxophone:': '&#x1F3B7;',
  ':guitar:': '&#x1F3B8;',
  ':musical_keyboard:': '&#x1F3B9;',
  ':trumpet:': '&#x1F3BA;',
  ':violin:': '&#x1F3BB;',
  ':musical_score:': '&#x1F3BC;',
  ':running_shirt_with_sash:': '&#x1F3BD;',
  ':tennis:': '&#x1F3BE;',
  ':ski:': '&#x1F3BF;',
  ':basketball:': '&#x1F3C0;',
  ':checkered_flag:': '&#x1F3C1;',
  ':snowboarder:': '&#x1F3C2;',
  ':woman-running:': '&#x1F3C3;&#x200D;&#x2640;&#xFE0F;',
  ':man-running:': '&#x1F3C3;&#x200D;&#x2642;&#xFE0F;',
  ':runner:': '&#x1F3C3;',
  ':woman-surfing:': '&#x1F3C4;&#x200D;&#x2640;&#xFE0F;',
  ':man-surfing:': '&#x1F3C4;&#x200D;&#x2642;&#xFE0F;',
  ':surfer:': '&#x1F3C4;',
  ':sports_medal:': '&#x1F3C5;',
  ':trophy:': '&#x1F3C6;',
  ':horse_racing:': '&#x1F3C7;',
  ':football:': '&#x1F3C8;',
  ':rugby_football:': '&#x1F3C9;',
  ':woman-swimming:': '&#x1F3CA;&#x200D;&#x2640;&#xFE0F;',
  ':man-swimming:': '&#x1F3CA;&#x200D;&#x2642;&#xFE0F;',
  ':swimmer:': '&#x1F3CA;',
  ':woman-lifting-weights:': '&#x1F3CB;&#xFE0F;&#x200D;&#x2640;&#xFE0F;',
  ':man-lifting-weights:': '&#x1F3CB;&#xFE0F;&#x200D;&#x2642;&#xFE0F;',
  ':weight_lifter:': '&#x1F3CB;&#xFE0F;',
  ':woman-golfing:': '&#x1F3CC;&#xFE0F;&#x200D;&#x2640;&#xFE0F;',
  ':man-golfing:': '&#x1F3CC;&#xFE0F;&#x200D;&#x2642;&#xFE0F;',
  ':golfer:': '&#x1F3CC;&#xFE0F;',
  ':racing_motorcycle:': '&#x1F3CD;&#xFE0F;',
  ':racing_car:': '&#x1F3CE;&#xFE0F;',
  ':cricket_bat_and_ball:': '&#x1F3CF;',
  ':volleyball:': '&#x1F3D0;',
  ':field_hockey_stick_and_ball:': '&#x1F3D1;',
  ':ice_hockey_stick_and_puck:': '&#x1F3D2;',
  ':table_tennis_paddle_and_ball:': '&#x1F3D3;',
  ':snow_capped_mountain:': '&#x1F3D4;&#xFE0F;',
  ':camping:': '&#x1F3D5;&#xFE0F;',
  ':beach_with_umbrella:': '&#x1F3D6;&#xFE0F;',
  ':building_construction:': '&#x1F3D7;&#xFE0F;',
  ':house_buildings:': '&#x1F3D8;&#xFE0F;',
  ':cityscape:': '&#x1F3D9;&#xFE0F;',
  ':derelict_house_building:': '&#x1F3DA;&#xFE0F;',
  ':classical_building:': '&#x1F3DB;&#xFE0F;',
  ':desert:': '&#x1F3DC;&#xFE0F;',
  ':desert_island:': '&#x1F3DD;&#xFE0F;',
  ':national_park:': '&#x1F3DE;&#xFE0F;',
  ':stadium:': '&#x1F3DF;&#xFE0F;',
  ':house:': '&#x1F3E0;',
  ':house_with_garden:': '&#x1F3E1;',
  ':office:': '&#x1F3E2;',
  ':post_office:': '&#x1F3E3;',
  ':european_post_office:': '&#x1F3E4;',
  ':hospital:': '&#x1F3E5;',
  ':bank:': '&#x1F3E6;',
  ':atm:': '&#x1F3E7;',
  ':hotel:': '&#x1F3E8;',
  ':love_hotel:': '&#x1F3E9;',
  ':convenience_store:': '&#x1F3EA;',
  ':school:': '&#x1F3EB;',
  ':department_store:': '&#x1F3EC;',
  ':factory:': '&#x1F3ED;',
  ':izakaya_lantern:': '&#x1F3EE;',
  ':japanese_castle:': '&#x1F3EF;',
  ':european_castle:': '&#x1F3F0;',
  ':rainbow-flag:': '&#x1F3F3;&#xFE0F;&#x200D;&#x1F308;',
  ':waving_white_flag:': '&#x1F3F3;&#xFE0F;',
  ':flag-england:': '&#x1F3F4;&#xE0067;&#xE0062;&#xE0065;&#xE006E;&#xE0067;&#xE007F;',
  ':flag-scotland:': '&#x1F3F4;&#xE0067;&#xE0062;&#xE0073;&#xE0063;&#xE0074;&#xE007F;',
  ':flag-wales:': '&#x1F3F4;&#xE0067;&#xE0062;&#xE0077;&#xE006C;&#xE0073;&#xE007F;',
  ':waving_black_flag:': '&#x1F3F4;',
  ':rosette:': '&#x1F3F5;&#xFE0F;',
  ':label:': '&#x1F3F7;&#xFE0F;',
  ':badminton_racquet_and_shuttlecock:': '&#x1F3F8;',
  ':bow_and_arrow:': '&#x1F3F9;',
  ':amphora:': '&#x1F3FA;',
  ':skin-tone-2:': '&#x1F3FB;',
  ':skin-tone-3:': '&#x1F3FC;',
  ':skin-tone-4:': '&#x1F3FD;',
  ':skin-tone-5:': '&#x1F3FE;',
  ':skin-tone-6:': '&#x1F3FF;',
  ':rat:': '&#x1F400;',
  ':mouse2:': '&#x1F401;',
  ':ox:': '&#x1F402;',
  ':water_buffalo:': '&#x1F403;',
  ':cow2:': '&#x1F404;',
  ':tiger2:': '&#x1F405;',
  ':leopard:': '&#x1F406;',
  ':rabbit2:': '&#x1F407;',
  ':cat2:': '&#x1F408;',
  ':dragon:': '&#x1F409;',
  ':crocodile:': '&#x1F40A;',
  ':whale2:': '&#x1F40B;',
  ':snail:': '&#x1F40C;',
  ':snake:': '&#x1F40D;',
  ':racehorse:': '&#x1F40E;',
  ':ram:': '&#x1F40F;',
  ':goat:': '&#x1F410;',
  ':sheep:': '&#x1F411;',
  ':monkey:': '&#x1F412;',
  ':rooster:': '&#x1F413;',
  ':chicken:': '&#x1F414;',
  ':dog2:': '&#x1F415;',
  ':pig2:': '&#x1F416;',
  ':boar:': '&#x1F417;',
  ':elephant:': '&#x1F418;',
  ':octopus:': '&#x1F419;',
  ':shell:': '&#x1F41A;',
  ':bug:': '&#x1F41B;',
  ':ant:': '&#x1F41C;',
  ':bee:': '&#x1F41D;',
  ':beetle:': '&#x1F41E;',
  ':fish:': '&#x1F41F;',
  ':tropical_fish:': '&#x1F420;',
  ':blowfish:': '&#x1F421;',
  ':turtle:': '&#x1F422;',
  ':hatching_chick:': '&#x1F423;',
  ':baby_chick:': '&#x1F424;',
  ':hatched_chick:': '&#x1F425;',
  ':bird:': '&#x1F426;',
  ':penguin:': '&#x1F427;',
  ':koala:': '&#x1F428;',
  ':poodle:': '&#x1F429;',
  ':dromedary_camel:': '&#x1F42A;',
  ':camel:': '&#x1F42B;',
  ':dolphin:': '&#x1F42C;',
  ':mouse:': '&#x1F42D;',
  ':cow:': '&#x1F42E;',
  ':tiger:': '&#x1F42F;',
  ':rabbit:': '&#x1F430;',
  ':cat:': '&#x1F431;',
  ':dragon_face:': '&#x1F432;',
  ':whale:': '&#x1F433;',
  ':horse:': '&#x1F434;',
  ':monkey_face:': '&#x1F435;',
  ':dog:': '&#x1F436;',
  ':pig:': '&#x1F437;',
  ':frog:': '&#x1F438;',
  ':hamster:': '&#x1F439;',
  ':wolf:': '&#x1F43A;',
  ':bear:': '&#x1F43B;',
  ':panda_face:': '&#x1F43C;',
  ':pig_nose:': '&#x1F43D;',
  ':feet:': '&#x1F43E;',
  ':chipmunk:': '&#x1F43F;&#xFE0F;',
  ':eyes:': '&#x1F440;',
  ':eye-in-speech-bubble:': '&#x1F441;&#xFE0F;&#x200D;&#x1F5E8;&#xFE0F;',
  ':eye:': '&#x1F441;&#xFE0F;',
  ':ear:': '&#x1F442;',
  ':nose:': '&#x1F443;',
  ':lips:': '&#x1F444;',
  ':tongue:': '&#x1F445;',
  ':point_up_2:': '&#x1F446;',
  ':point_down:': '&#x1F447;',
  ':point_left:': '&#x1F448;',
  ':point_right:': '&#x1F449;',
  ':facepunch:': '&#x1F44A;',
  ':wave:': '&#x1F44B;',
  ':ok_hand:': '&#x1F44C;',
  ':+1:': '&#x1F44D;',
  ':-1:': '&#x1F44E;',
  ':clap:': '&#x1F44F;',
  ':open_hands:': '&#x1F450;',
  ':crown:': '&#x1F451;',
  ':womans_hat:': '&#x1F452;',
  ':eyeglasses:': '&#x1F453;',
  ':necktie:': '&#x1F454;',
  ':shirt:': '&#x1F455;',
  ':jeans:': '&#x1F456;',
  ':dress:': '&#x1F457;',
  ':kimono:': '&#x1F458;',
  ':bikini:': '&#x1F459;',
  ':womans_clothes:': '&#x1F45A;',
  ':purse:': '&#x1F45B;',
  ':handbag:': '&#x1F45C;',
  ':pouch:': '&#x1F45D;',
  ':mans_shoe:': '&#x1F45E;',
  ':athletic_shoe:': '&#x1F45F;',
  ':high_heel:': '&#x1F460;',
  ':sandal:': '&#x1F461;',
  ':boot:': '&#x1F462;',
  ':footprints:': '&#x1F463;',
  ':bust_in_silhouette:': '&#x1F464;',
  ':busts_in_silhouette:': '&#x1F465;',
  ':boy:': '&#x1F466;',
  ':girl:': '&#x1F467;',
  ':male-farmer:': '&#x1F468;&#x200D;&#x1F33E;',
  ':male-cook:': '&#x1F468;&#x200D;&#x1F373;',
  ':male-student:': '&#x1F468;&#x200D;&#x1F393;',
  ':male-singer:': '&#x1F468;&#x200D;&#x1F3A4;',
  ':male-artist:': '&#x1F468;&#x200D;&#x1F3A8;',
  ':male-teacher:': '&#x1F468;&#x200D;&#x1F3EB;',
  ':male-factory-worker:': '&#x1F468;&#x200D;&#x1F3ED;',
  ':man-boy-boy:': '&#x1F468;&#x200D;&#x1F466;&#x200D;&#x1F466;',
  ':man-boy:': '&#x1F468;&#x200D;&#x1F466;',
  ':man-girl-boy:': '&#x1F468;&#x200D;&#x1F467;&#x200D;&#x1F466;',
  ':man-girl-girl:': '&#x1F468;&#x200D;&#x1F467;&#x200D;&#x1F467;',
  ':man-girl:': '&#x1F468;&#x200D;&#x1F467;',
  ':man-man-boy:': '&#x1F468;&#x200D;&#x1F468;&#x200D;&#x1F466;',
  ':man-man-boy-boy:': '&#x1F468;&#x200D;&#x1F468;&#x200D;&#x1F466;&#x200D;&#x1F466;',
  ':man-man-girl:': '&#x1F468;&#x200D;&#x1F468;&#x200D;&#x1F467;',
  ':man-man-girl-boy:': '&#x1F468;&#x200D;&#x1F468;&#x200D;&#x1F467;&#x200D;&#x1F466;',
  ':man-man-girl-girl:': '&#x1F468;&#x200D;&#x1F468;&#x200D;&#x1F467;&#x200D;&#x1F467;',
  ':man-woman-boy:': '&#x1F468;&#x200D;&#x1F469;&#x200D;&#x1F466;',
  ':man-woman-boy-boy:': '&#x1F468;&#x200D;&#x1F469;&#x200D;&#x1F466;&#x200D;&#x1F466;',
  ':man-woman-girl:': '&#x1F468;&#x200D;&#x1F469;&#x200D;&#x1F467;',
  ':man-woman-girl-boy:': '&#x1F468;&#x200D;&#x1F469;&#x200D;&#x1F467;&#x200D;&#x1F466;',
  ':man-woman-girl-girl:': '&#x1F468;&#x200D;&#x1F469;&#x200D;&#x1F467;&#x200D;&#x1F467;',
  ':male-technologist:': '&#x1F468;&#x200D;&#x1F4BB;',
  ':male-office-worker:': '&#x1F468;&#x200D;&#x1F4BC;',
  ':male-mechanic:': '&#x1F468;&#x200D;&#x1F527;',
  ':male-scientist:': '&#x1F468;&#x200D;&#x1F52C;',
  ':male-astronaut:': '&#x1F468;&#x200D;&#x1F680;',
  ':male-firefighter:': '&#x1F468;&#x200D;&#x1F692;',
  ':male-doctor:': '&#x1F468;&#x200D;&#x2695;&#xFE0F;',
  ':male-judge:': '&#x1F468;&#x200D;&#x2696;&#xFE0F;',
  ':male-pilot:': '&#x1F468;&#x200D;&#x2708;&#xFE0F;',
  ':man-heart-man:': '&#x1F468;&#x200D;&#x2764;&#xFE0F;&#x200D;&#x1F468;',
  ':man-kiss-man:': '&#x1F468;&#x200D;&#x2764;&#xFE0F;&#x200D;&#x1F48B;&#x200D;&#x1F468;',
  ':man:': '&#x1F468;',
  ':female-farmer:': '&#x1F469;&#x200D;&#x1F33E;',
  ':female-cook:': '&#x1F469;&#x200D;&#x1F373;',
  ':female-student:': '&#x1F469;&#x200D;&#x1F393;',
  ':female-singer:': '&#x1F469;&#x200D;&#x1F3A4;',
  ':female-artist:': '&#x1F469;&#x200D;&#x1F3A8;',
  ':female-teacher:': '&#x1F469;&#x200D;&#x1F3EB;',
  ':female-factory-worker:': '&#x1F469;&#x200D;&#x1F3ED;',
  ':woman-boy-boy:': '&#x1F469;&#x200D;&#x1F466;&#x200D;&#x1F466;',
  ':woman-boy:': '&#x1F469;&#x200D;&#x1F466;',
  ':woman-girl-boy:': '&#x1F469;&#x200D;&#x1F467;&#x200D;&#x1F466;',
  ':woman-girl-girl:': '&#x1F469;&#x200D;&#x1F467;&#x200D;&#x1F467;',
  ':woman-girl:': '&#x1F469;&#x200D;&#x1F467;',
  ':woman-woman-boy:': '&#x1F469;&#x200D;&#x1F469;&#x200D;&#x1F466;',
  ':woman-woman-boy-boy:': '&#x1F469;&#x200D;&#x1F469;&#x200D;&#x1F466;&#x200D;&#x1F466;',
  ':woman-woman-girl:': '&#x1F469;&#x200D;&#x1F469;&#x200D;&#x1F467;',
  ':woman-woman-girl-boy:': '&#x1F469;&#x200D;&#x1F469;&#x200D;&#x1F467;&#x200D;&#x1F466;',
  ':woman-woman-girl-girl:': '&#x1F469;&#x200D;&#x1F469;&#x200D;&#x1F467;&#x200D;&#x1F467;',
  ':female-technologist:': '&#x1F469;&#x200D;&#x1F4BB;',
  ':female-office-worker:': '&#x1F469;&#x200D;&#x1F4BC;',
  ':female-mechanic:': '&#x1F469;&#x200D;&#x1F527;',
  ':female-scientist:': '&#x1F469;&#x200D;&#x1F52C;',
  ':female-astronaut:': '&#x1F469;&#x200D;&#x1F680;',
  ':female-firefighter:': '&#x1F469;&#x200D;&#x1F692;',
  ':female-doctor:': '&#x1F469;&#x200D;&#x2695;&#xFE0F;',
  ':female-judge:': '&#x1F469;&#x200D;&#x2696;&#xFE0F;',
  ':female-pilot:': '&#x1F469;&#x200D;&#x2708;&#xFE0F;',
  ':woman-heart-man:': '&#x1F469;&#x200D;&#x2764;&#xFE0F;&#x200D;&#x1F468;',
  ':woman-heart-woman:': '&#x1F469;&#x200D;&#x2764;&#xFE0F;&#x200D;&#x1F469;',
  ':woman-kiss-man:': '&#x1F469;&#x200D;&#x2764;&#xFE0F;&#x200D;&#x1F48B;&#x200D;&#x1F468;',
  ':woman-kiss-woman:': '&#x1F469;&#x200D;&#x2764;&#xFE0F;&#x200D;&#x1F48B;&#x200D;&#x1F469;',
  ':woman:': '&#x1F469;',
  ':family:': '&#x1F46A;',
  ':couple:': '&#x1F46B;',
  ':two_men_holding_hands:': '&#x1F46C;',
  ':two_women_holding_hands:': '&#x1F46D;',
  ':female-police-officer:': '&#x1F46E;&#x200D;&#x2640;&#xFE0F;',
  ':male-police-officer:': '&#x1F46E;&#x200D;&#x2642;&#xFE0F;',
  ':cop:': '&#x1F46E;',
  ':woman-with-bunny-ears-partying:': '&#x1F46F;&#x200D;&#x2640;&#xFE0F;',
  ':man-with-bunny-ears-partying:': '&#x1F46F;&#x200D;&#x2642;&#xFE0F;',
  ':dancers:': '&#x1F46F;',
  ':bride_with_veil:': '&#x1F470;',
  ':blond-haired-woman:': '&#x1F471;&#x200D;&#x2640;&#xFE0F;',
  ':blond-haired-man:': '&#x1F471;&#x200D;&#x2642;&#xFE0F;',
  ':person_with_blond_hair:': '&#x1F471;',
  ':man_with_gua_pi_mao:': '&#x1F472;',
  ':woman-wearing-turban:': '&#x1F473;&#x200D;&#x2640;&#xFE0F;',
  ':man-wearing-turban:': '&#x1F473;&#x200D;&#x2642;&#xFE0F;',
  ':man_with_turban:': '&#x1F473;',
  ':older_man:': '&#x1F474;',
  ':older_woman:': '&#x1F475;',
  ':baby:': '&#x1F476;',
  ':female-construction-worker:': '&#x1F477;&#x200D;&#x2640;&#xFE0F;',
  ':male-construction-worker:': '&#x1F477;&#x200D;&#x2642;&#xFE0F;',
  ':construction_worker:': '&#x1F477;',
  ':princess:': '&#x1F478;',
  ':japanese_ogre:': '&#x1F479;',
  ':japanese_goblin:': '&#x1F47A;',
  ':ghost:': '&#x1F47B;',
  ':angel:': '&#x1F47C;',
  ':alien:': '&#x1F47D;',
  ':space_invader:': '&#x1F47E;',
  ':imp:': '&#x1F47F;',
  ':skull:': '&#x1F480;',
  ':woman-tipping-hand:': '&#x1F481;&#x200D;&#x2640;&#xFE0F;',
  ':man-tipping-hand:': '&#x1F481;&#x200D;&#x2642;&#xFE0F;',
  ':information_desk_person:': '&#x1F481;',
  ':female-guard:': '&#x1F482;&#x200D;&#x2640;&#xFE0F;',
  ':male-guard:': '&#x1F482;&#x200D;&#x2642;&#xFE0F;',
  ':guardsman:': '&#x1F482;',
  ':dancer:': '&#x1F483;',
  ':lipstick:': '&#x1F484;',
  ':nail_care:': '&#x1F485;',
  ':woman-getting-massage:': '&#x1F486;&#x200D;&#x2640;&#xFE0F;',
  ':man-getting-massage:': '&#x1F486;&#x200D;&#x2642;&#xFE0F;',
  ':massage:': '&#x1F486;',
  ':woman-getting-haircut:': '&#x1F487;&#x200D;&#x2640;&#xFE0F;',
  ':man-getting-haircut:': '&#x1F487;&#x200D;&#x2642;&#xFE0F;',
  ':haircut:': '&#x1F487;',
  ':barber:': '&#x1F488;',
  ':syringe:': '&#x1F489;',
  ':pill:': '&#x1F48A;',
  ':kiss:': '&#x1F48B;',
  ':love_letter:': '&#x1F48C;',
  ':ring:': '&#x1F48D;',
  ':gem:': '&#x1F48E;',
  ':couplekiss:': '&#x1F48F;',
  ':bouquet:': '&#x1F490;',
  ':couple_with_heart:': '&#x1F491;',
  ':wedding:': '&#x1F492;',
  ':heartbeat:': '&#x1F493;',
  ':broken_heart:': '&#x1F494;',
  ':two_hearts:': '&#x1F495;',
  ':sparkling_heart:': '&#x1F496;',
  ':heartpulse:': '&#x1F497;',
  ':cupid:': '&#x1F498;',
  ':blue_heart:': '&#x1F499;',
  ':green_heart:': '&#x1F49A;',
  ':yellow_heart:': '&#x1F49B;',
  ':purple_heart:': '&#x1F49C;',
  ':gift_heart:': '&#x1F49D;',
  ':revolving_hearts:': '&#x1F49E;',
  ':heart_decoration:': '&#x1F49F;',
  ':diamond_shape_with_a_dot_inside:': '&#x1F4A0;',
  ':bulb:': '&#x1F4A1;',
  ':anger:': '&#x1F4A2;',
  ':bomb:': '&#x1F4A3;',
  ':zzz:': '&#x1F4A4;',
  ':boom:': '&#x1F4A5;',
  ':sweat_drops:': '&#x1F4A6;',
  ':droplet:': '&#x1F4A7;',
  ':dash:': '&#x1F4A8;',
  ':hankey:': '&#x1F4A9;',
  ':muscle:': '&#x1F4AA;',
  ':dizzy:': '&#x1F4AB;',
  ':speech_balloon:': '&#x1F4AC;',
  ':thought_balloon:': '&#x1F4AD;',
  ':white_flower:': '&#x1F4AE;',
  ':moneybag:': '&#x1F4B0;',
  ':currency_exchange:': '&#x1F4B1;',
  ':heavy_dollar_sign:': '&#x1F4B2;',
  ':credit_card:': '&#x1F4B3;',
  ':yen:': '&#x1F4B4;',
  ':dollar:': '&#x1F4B5;',
  ':euro:': '&#x1F4B6;',
  ':pound:': '&#x1F4B7;',
  ':money_with_wings:': '&#x1F4B8;',
  ':chart:': '&#x1F4B9;',
  ':seat:': '&#x1F4BA;',
  ':computer:': '&#x1F4BB;',
  ':briefcase:': '&#x1F4BC;',
  ':minidisc:': '&#x1F4BD;',
  ':floppy_disk:': '&#x1F4BE;',
  ':cd:': '&#x1F4BF;',
  ':dvd:': '&#x1F4C0;',
  ':file_folder:': '&#x1F4C1;',
  ':open_file_folder:': '&#x1F4C2;',
  ':page_with_curl:': '&#x1F4C3;',
  ':page_facing_up:': '&#x1F4C4;',
  ':date:': '&#x1F4C5;',
  ':calendar:': '&#x1F4C6;',
  ':card_index:': '&#x1F4C7;',
  ':chart_with_upwards_trend:': '&#x1F4C8;',
  ':chart_with_downwards_trend:': '&#x1F4C9;',
  ':bar_chart:': '&#x1F4CA;',
  ':clipboard:': '&#x1F4CB;',
  ':pushpin:': '&#x1F4CC;',
  ':round_pushpin:': '&#x1F4CD;',
  ':paperclip:': '&#x1F4CE;',
  ':straight_ruler:': '&#x1F4CF;',
  ':triangular_ruler:': '&#x1F4D0;',
  ':bookmark_tabs:': '&#x1F4D1;',
  ':ledger:': '&#x1F4D2;',
  ':notebook:': '&#x1F4D3;',
  ':notebook_with_decorative_cover:': '&#x1F4D4;',
  ':closed_book:': '&#x1F4D5;',
  ':book:': '&#x1F4D6;',
  ':green_book:': '&#x1F4D7;',
  ':blue_book:': '&#x1F4D8;',
  ':orange_book:': '&#x1F4D9;',
  ':books:': '&#x1F4DA;',
  ':name_badge:': '&#x1F4DB;',
  ':scroll:': '&#x1F4DC;',
  ':memo:': '&#x1F4DD;',
  ':telephone_receiver:': '&#x1F4DE;',
  ':pager:': '&#x1F4DF;',
  ':fax:': '&#x1F4E0;',
  ':satellite_antenna:': '&#x1F4E1;',
  ':loudspeaker:': '&#x1F4E2;',
  ':mega:': '&#x1F4E3;',
  ':outbox_tray:': '&#x1F4E4;',
  ':inbox_tray:': '&#x1F4E5;',
  ':package:': '&#x1F4E6;',
  ':e-mail:': '&#x1F4E7;',
  ':incoming_envelope:': '&#x1F4E8;',
  ':envelope_with_arrow:': '&#x1F4E9;',
  ':mailbox_closed:': '&#x1F4EA;',
  ':mailbox:': '&#x1F4EB;',
  ':mailbox_with_mail:': '&#x1F4EC;',
  ':mailbox_with_no_mail:': '&#x1F4ED;',
  ':postbox:': '&#x1F4EE;',
  ':postal_horn:': '&#x1F4EF;',
  ':newspaper:': '&#x1F4F0;',
  ':iphone:': '&#x1F4F1;',
  ':calling:': '&#x1F4F2;',
  ':vibration_mode:': '&#x1F4F3;',
  ':mobile_phone_off:': '&#x1F4F4;',
  ':no_mobile_phones:': '&#x1F4F5;',
  ':signal_strength:': '&#x1F4F6;',
  ':camera:': '&#x1F4F7;',
  ':camera_with_flash:': '&#x1F4F8;',
  ':video_camera:': '&#x1F4F9;',
  ':tv:': '&#x1F4FA;',
  ':radio:': '&#x1F4FB;',
  ':vhs:': '&#x1F4FC;',
  ':film_projector:': '&#x1F4FD;&#xFE0F;',
  ':prayer_beads:': '&#x1F4FF;',
  ':twisted_rightwards_arrows:': '&#x1F500;',
  ':repeat:': '&#x1F501;',
  ':repeat_one:': '&#x1F502;',
  ':arrows_clockwise:': '&#x1F503;',
  ':arrows_counterclockwise:': '&#x1F504;',
  ':low_brightness:': '&#x1F505;',
  ':high_brightness:': '&#x1F506;',
  ':mute:': '&#x1F507;',
  ':speaker:': '&#x1F508;',
  ':sound:': '&#x1F509;',
  ':loud_sound:': '&#x1F50A;',
  ':battery:': '&#x1F50B;',
  ':electric_plug:': '&#x1F50C;',
  ':mag:': '&#x1F50D;',
  ':mag_right:': '&#x1F50E;',
  ':lock_with_ink_pen:': '&#x1F50F;',
  ':closed_lock_with_key:': '&#x1F510;',
  ':key:': '&#x1F511;',
  ':lock:': '&#x1F512;',
  ':unlock:': '&#x1F513;',
  ':bell:': '&#x1F514;',
  ':no_bell:': '&#x1F515;',
  ':bookmark:': '&#x1F516;',
  ':link:': '&#x1F517;',
  ':radio_button:': '&#x1F518;',
  ':back:': '&#x1F519;',
  ':end:': '&#x1F51A;',
  ':on:': '&#x1F51B;',
  ':soon:': '&#x1F51C;',
  ':top:': '&#x1F51D;',
  ':underage:': '&#x1F51E;',
  ':keycap_ten:': '&#x1F51F;',
  ':capital_abcd:': '&#x1F520;',
  ':abcd:': '&#x1F521;',
  ':symbols:': '&#x1F523;',
  ':abc:': '&#x1F524;',
  ':fire:': '&#x1F525;',
  ':flashlight:': '&#x1F526;',
  ':wrench:': '&#x1F527;',
  ':hammer:': '&#x1F528;',
  ':nut_and_bolt:': '&#x1F529;',
  ':hocho:': '&#x1F52A;',
  ':gun:': '&#x1F52B;',
  ':microscope:': '&#x1F52C;',
  ':telescope:': '&#x1F52D;',
  ':crystal_ball:': '&#x1F52E;',
  ':six_pointed_star:': '&#x1F52F;',
  ':beginner:': '&#x1F530;',
  ':trident:': '&#x1F531;',
  ':black_square_button:': '&#x1F532;',
  ':white_square_button:': '&#x1F533;',
  ':red_circle:': '&#x1F534;',
  ':large_blue_circle:': '&#x1F535;',
  ':large_orange_diamond:': '&#x1F536;',
  ':large_blue_diamond:': '&#x1F537;',
  ':small_orange_diamond:': '&#x1F538;',
  ':small_blue_diamond:': '&#x1F539;',
  ':small_red_triangle:': '&#x1F53A;',
  ':small_red_triangle_down:': '&#x1F53B;',
  ':arrow_up_small:': '&#x1F53C;',
  ':arrow_down_small:': '&#x1F53D;',
  ':om_symbol:': '&#x1F549;&#xFE0F;',
  ':dove_of_peace:': '&#x1F54A;&#xFE0F;',
  ':kaaba:': '&#x1F54B;',
  ':mosque:': '&#x1F54C;',
  ':synagogue:': '&#x1F54D;',
  ':menorah_with_nine_branches:': '&#x1F54E;',
  ':clock1:': '&#x1F550;',
  ':clock2:': '&#x1F551;',
  ':clock3:': '&#x1F552;',
  ':clock4:': '&#x1F553;',
  ':clock5:': '&#x1F554;',
  ':clock6:': '&#x1F555;',
  ':clock7:': '&#x1F556;',
  ':clock8:': '&#x1F557;',
  ':clock9:': '&#x1F558;',
  ':clock10:': '&#x1F559;',
  ':clock11:': '&#x1F55A;',
  ':clock12:': '&#x1F55B;',
  ':clock130:': '&#x1F55C;',
  ':clock230:': '&#x1F55D;',
  ':clock330:': '&#x1F55E;',
  ':clock430:': '&#x1F55F;',
  ':clock530:': '&#x1F560;',
  ':clock630:': '&#x1F561;',
  ':clock730:': '&#x1F562;',
  ':clock830:': '&#x1F563;',
  ':clock930:': '&#x1F564;',
  ':clock1030:': '&#x1F565;',
  ':clock1130:': '&#x1F566;',
  ':clock1230:': '&#x1F567;',
  ':candle:': '&#x1F56F;&#xFE0F;',
  ':mantelpiece_clock:': '&#x1F570;&#xFE0F;',
  ':hole:': '&#x1F573;&#xFE0F;',
  ':man_in_business_suit_levitating:': '&#x1F574;&#xFE0F;',
  ':female-detective:': '&#x1F575;&#xFE0F;&#x200D;&#x2640;&#xFE0F;',
  ':male-detective:': '&#x1F575;&#xFE0F;&#x200D;&#x2642;&#xFE0F;',
  ':sleuth_or_spy:': '&#x1F575;&#xFE0F;',
  ':dark_sunglasses:': '&#x1F576;&#xFE0F;',
  ':spider:': '&#x1F577;&#xFE0F;',
  ':spider_web:': '&#x1F578;&#xFE0F;',
  ':joystick:': '&#x1F579;&#xFE0F;',
  ':man_dancing:': '&#x1F57A;',
  ':linked_paperclips:': '&#x1F587;&#xFE0F;',
  ':lower_left_ballpoint_pen:': '&#x1F58A;&#xFE0F;',
  ':lower_left_fountain_pen:': '&#x1F58B;&#xFE0F;',
  ':lower_left_paintbrush:': '&#x1F58C;&#xFE0F;',
  ':lower_left_crayon:': '&#x1F58D;&#xFE0F;',
  ':raised_hand_with_fingers_splayed:': '&#x1F590;&#xFE0F;',
  ':middle_finger:': '&#x1F595;',
  ':spock-hand:': '&#x1F596;',
  ':black_heart:': '&#x1F5A4;',
  ':desktop_computer:': '&#x1F5A5;&#xFE0F;',
  ':printer:': '&#x1F5A8;&#xFE0F;',
  ':three_button_mouse:': '&#x1F5B1;&#xFE0F;',
  ':trackball:': '&#x1F5B2;&#xFE0F;',
  ':frame_with_picture:': '&#x1F5BC;&#xFE0F;',
  ':card_index_dividers:': '&#x1F5C2;&#xFE0F;',
  ':card_file_box:': '&#x1F5C3;&#xFE0F;',
  ':file_cabinet:': '&#x1F5C4;&#xFE0F;',
  ':wastebasket:': '&#x1F5D1;&#xFE0F;',
  ':spiral_note_pad:': '&#x1F5D2;&#xFE0F;',
  ':spiral_calendar_pad:': '&#x1F5D3;&#xFE0F;',
  ':compression:': '&#x1F5DC;&#xFE0F;',
  ':old_key:': '&#x1F5DD;&#xFE0F;',
  ':rolled_up_newspaper:': '&#x1F5DE;&#xFE0F;',
  ':dagger_knife:': '&#x1F5E1;&#xFE0F;',
  ':speaking_head_in_silhouette:': '&#x1F5E3;&#xFE0F;',
  ':left_speech_bubble:': '&#x1F5E8;&#xFE0F;',
  ':right_anger_bubble:': '&#x1F5EF;&#xFE0F;',
  ':ballot_box_with_ballot:': '&#x1F5F3;&#xFE0F;',
  ':world_map:': '&#x1F5FA;&#xFE0F;',
  ':mount_fuji:': '&#x1F5FB;',
  ':tokyo_tower:': '&#x1F5FC;',
  ':statue_of_liberty:': '&#x1F5FD;',
  ':japan:': '&#x1F5FE;',
  ':moyai:': '&#x1F5FF;',
  ':grinning:': '&#x1F600;',
  ':grin:': '&#x1F601;',
  ':joy:': '&#x1F602;',
  ':smiley:': '&#x1F603;',
  ':smile:': '&#x1F604;',
  ':sweat_smile:': '&#x1F605;',
  ':laughing:': '&#x1F606;',
  ':innocent:': '&#x1F607;',
  ':smiling_imp:': '&#x1F608;',
  ':wink:': '&#x1F609;',
  ':blush:': '&#x1F60A;',
  ':yum:': '&#x1F60B;',
  ':relieved:': '&#x1F60C;',
  ':heart_eyes:': '&#x1F60D;',
  ':sunglasses:': '&#x1F60E;',
  ':smirk:': '&#x1F60F;',
  ':neutral_face:': '&#x1F610;',
  ':expressionless:': '&#x1F611;',
  ':unamused:': '&#x1F612;',
  ':sweat:': '&#x1F613;',
  ':pensive:': '&#x1F614;',
  ':confused:': '&#x1F615;',
  ':confounded:': '&#x1F616;',
  ':kissing:': '&#x1F617;',
  ':kissing_heart:': '&#x1F618;',
  ':kissing_smiling_eyes:': '&#x1F619;',
  ':kissing_closed_eyes:': '&#x1F61A;',
  ':stuck_out_tongue:': '&#x1F61B;',
  ':stuck_out_tongue_winking_eye:': '&#x1F61C;',
  ':stuck_out_tongue_closed_eyes:': '&#x1F61D;',
  ':disappointed:': '&#x1F61E;',
  ':worried:': '&#x1F61F;',
  ':angry:': '&#x1F620;',
  ':rage:': '&#x1F621;',
  ':cry:': '&#x1F622;',
  ':persevere:': '&#x1F623;',
  ':triumph:': '&#x1F624;',
  ':disappointed_relieved:': '&#x1F625;',
  ':frowning:': '&#x1F626;',
  ':anguished:': '&#x1F627;',
  ':fearful:': '&#x1F628;',
  ':weary:': '&#x1F629;',
  ':sleepy:': '&#x1F62A;',
  ':tired_face:': '&#x1F62B;',
  ':grimacing:': '&#x1F62C;',
  ':sob:': '&#x1F62D;',
  ':open_mouth:': '&#x1F62E;',
  ':hushed:': '&#x1F62F;',
  ':cold_sweat:': '&#x1F630;',
  ':scream:': '&#x1F631;',
  ':astonished:': '&#x1F632;',
  ':flushed:': '&#x1F633;',
  ':sleeping:': '&#x1F634;',
  ':dizzy_face:': '&#x1F635;',
  ':no_mouth:': '&#x1F636;',
  ':mask:': '&#x1F637;',
  ':smile_cat:': '&#x1F638;',
  ':joy_cat:': '&#x1F639;',
  ':smiley_cat:': '&#x1F63A;',
  ':heart_eyes_cat:': '&#x1F63B;',
  ':smirk_cat:': '&#x1F63C;',
  ':kissing_cat:': '&#x1F63D;',
  ':pouting_cat:': '&#x1F63E;',
  ':crying_cat_face:': '&#x1F63F;',
  ':scream_cat:': '&#x1F640;',
  ':slightly_frowning_face:': '&#x1F641;',
  ':slightly_smiling_face:': '&#x1F642;',
  ':upside_down_face:': '&#x1F643;',
  ':face_with_rolling_eyes:': '&#x1F644;',
  ':woman-gesturing-no:': '&#x1F645;&#x200D;&#x2640;&#xFE0F;',
  ':man-gesturing-no:': '&#x1F645;&#x200D;&#x2642;&#xFE0F;',
  ':no_good:': '&#x1F645;',
  ':woman-gesturing-ok:': '&#x1F646;&#x200D;&#x2640;&#xFE0F;',
  ':man-gesturing-ok:': '&#x1F646;&#x200D;&#x2642;&#xFE0F;',
  ':ok_woman:': '&#x1F646;',
  ':woman-bowing:': '&#x1F647;&#x200D;&#x2640;&#xFE0F;',
  ':man-bowing:': '&#x1F647;&#x200D;&#x2642;&#xFE0F;',
  ':bow:': '&#x1F647;',
  ':see_no_evil:': '&#x1F648;',
  ':hear_no_evil:': '&#x1F649;',
  ':speak_no_evil:': '&#x1F64A;',
  ':woman-raising-hand:': '&#x1F64B;&#x200D;&#x2640;&#xFE0F;',
  ':man-raising-hand:': '&#x1F64B;&#x200D;&#x2642;&#xFE0F;',
  ':raising_hand:': '&#x1F64B;',
  ':raised_hands:': '&#x1F64C;',
  ':woman-frowning:': '&#x1F64D;&#x200D;&#x2640;&#xFE0F;',
  ':man-frowning:': '&#x1F64D;&#x200D;&#x2642;&#xFE0F;',
  ':person_frowning:': '&#x1F64D;',
  ':woman-pouting:': '&#x1F64E;&#x200D;&#x2640;&#xFE0F;',
  ':man-pouting:': '&#x1F64E;&#x200D;&#x2642;&#xFE0F;',
  ':person_with_pouting_face:': '&#x1F64E;',
  ':pray:': '&#x1F64F;',
  ':rocket:': '&#x1F680;',
  ':helicopter:': '&#x1F681;',
  ':steam_locomotive:': '&#x1F682;',
  ':railway_car:': '&#x1F683;',
  ':bullettrain_side:': '&#x1F684;',
  ':bullettrain_front:': '&#x1F685;',
  ':train2:': '&#x1F686;',
  ':metro:': '&#x1F687;',
  ':light_rail:': '&#x1F688;',
  ':station:': '&#x1F689;',
  ':tram:': '&#x1F68A;',
  ':train:': '&#x1F68B;',
  ':bus:': '&#x1F68C;',
  ':oncoming_bus:': '&#x1F68D;',
  ':trolleybus:': '&#x1F68E;',
  ':busstop:': '&#x1F68F;',
  ':minibus:': '&#x1F690;',
  ':ambulance:': '&#x1F691;',
  ':fire_engine:': '&#x1F692;',
  ':police_car:': '&#x1F693;',
  ':oncoming_police_car:': '&#x1F694;',
  ':taxi:': '&#x1F695;',
  ':oncoming_taxi:': '&#x1F696;',
  ':car:': '&#x1F697;',
  ':oncoming_automobile:': '&#x1F698;',
  ':blue_car:': '&#x1F699;',
  ':truck:': '&#x1F69A;',
  ':articulated_lorry:': '&#x1F69B;',
  ':tractor:': '&#x1F69C;',
  ':monorail:': '&#x1F69D;',
  ':mountain_railway:': '&#x1F69E;',
  ':suspension_railway:': '&#x1F69F;',
  ':mountain_cableway:': '&#x1F6A0;',
  ':aerial_tramway:': '&#x1F6A1;',
  ':ship:': '&#x1F6A2;',
  ':woman-rowing-boat:': '&#x1F6A3;&#x200D;&#x2640;&#xFE0F;',
  ':man-rowing-boat:': '&#x1F6A3;&#x200D;&#x2642;&#xFE0F;',
  ':rowboat:': '&#x1F6A3;',
  ':speedboat:': '&#x1F6A4;',
  ':traffic_light:': '&#x1F6A5;',
  ':vertical_traffic_light:': '&#x1F6A6;',
  ':construction:': '&#x1F6A7;',
  ':rotating_light:': '&#x1F6A8;',
  ':triangular_flag_on_post:': '&#x1F6A9;',
  ':door:': '&#x1F6AA;',
  ':no_entry_sign:': '&#x1F6AB;',
  ':smoking:': '&#x1F6AC;',
  ':no_smoking:': '&#x1F6AD;',
  ':put_litter_in_its_place:': '&#x1F6AE;',
  ':do_not_litter:': '&#x1F6AF;',
  ':potable_water:': '&#x1F6B0;',
  ':non-potable_water:': '&#x1F6B1;',
  ':bike:': '&#x1F6B2;',
  ':no_bicycles:': '&#x1F6B3;',
  ':woman-biking:': '&#x1F6B4;&#x200D;&#x2640;&#xFE0F;',
  ':man-biking:': '&#x1F6B4;&#x200D;&#x2642;&#xFE0F;',
  ':bicyclist:': '&#x1F6B4;',
  ':woman-mountain-biking:': '&#x1F6B5;&#x200D;&#x2640;&#xFE0F;',
  ':man-mountain-biking:': '&#x1F6B5;&#x200D;&#x2642;&#xFE0F;',
  ':mountain_bicyclist:': '&#x1F6B5;',
  ':woman-walking:': '&#x1F6B6;&#x200D;&#x2640;&#xFE0F;',
  ':man-walking:': '&#x1F6B6;&#x200D;&#x2642;&#xFE0F;',
  ':walking:': '&#x1F6B6;',
  ':no_pedestrians:': '&#x1F6B7;',
  ':children_crossing:': '&#x1F6B8;',
  ':mens:': '&#x1F6B9;',
  ':womens:': '&#x1F6BA;',
  ':restroom:': '&#x1F6BB;',
  ':baby_symbol:': '&#x1F6BC;',
  ':toilet:': '&#x1F6BD;',
  ':wc:': '&#x1F6BE;',
  ':shower:': '&#x1F6BF;',
  ':bath:': '&#x1F6C0;',
  ':bathtub:': '&#x1F6C1;',
  ':passport_control:': '&#x1F6C2;',
  ':customs:': '&#x1F6C3;',
  ':baggage_claim:': '&#x1F6C4;',
  ':left_luggage:': '&#x1F6C5;',
  ':couch_and_lamp:': '&#x1F6CB;&#xFE0F;',
  ':sleeping_accommodation:': '&#x1F6CC;',
  ':shopping_bags:': '&#x1F6CD;&#xFE0F;',
  ':bellhop_bell:': '&#x1F6CE;&#xFE0F;',
  ':bed:': '&#x1F6CF;&#xFE0F;',
  ':place_of_worship:': '&#x1F6D0;',
  ':octagonal_sign:': '&#x1F6D1;',
  ':shopping_trolley:': '&#x1F6D2;',
  ':hammer_and_wrench:': '&#x1F6E0;&#xFE0F;',
  ':shield:': '&#x1F6E1;&#xFE0F;',
  ':oil_drum:': '&#x1F6E2;&#xFE0F;',
  ':motorway:': '&#x1F6E3;&#xFE0F;',
  ':railway_track:': '&#x1F6E4;&#xFE0F;',
  ':motor_boat:': '&#x1F6E5;&#xFE0F;',
  ':small_airplane:': '&#x1F6E9;&#xFE0F;',
  ':airplane_departure:': '&#x1F6EB;',
  ':airplane_arriving:': '&#x1F6EC;',
  ':satellite:': '&#x1F6F0;&#xFE0F;',
  ':passenger_ship:': '&#x1F6F3;&#xFE0F;',
  ':scooter:': '&#x1F6F4;',
  ':motor_scooter:': '&#x1F6F5;',
  ':canoe:': '&#x1F6F6;',
  ':sled:': '&#x1F6F7;',
  ':flying_saucer:': '&#x1F6F8;',
  ':zipper_mouth_face:': '&#x1F910;',
  ':money_mouth_face:': '&#x1F911;',
  ':face_with_thermometer:': '&#x1F912;',
  ':nerd_face:': '&#x1F913;',
  ':thinking_face:': '&#x1F914;',
  ':face_with_head_bandage:': '&#x1F915;',
  ':robot_face:': '&#x1F916;',
  ':hugging_face:': '&#x1F917;',
  ':the_horns:': '&#x1F918;',
  ':call_me_hand:': '&#x1F919;',
  ':raised_back_of_hand:': '&#x1F91A;',
  ':left-facing_fist:': '&#x1F91B;',
  ':right-facing_fist:': '&#x1F91C;',
  ':handshake:': '&#x1F91D;',
  ':crossed_fingers:': '&#x1F91E;',
  ':i_love_you_hand_sign:': '&#x1F91F;',
  ':face_with_cowboy_hat:': '&#x1F920;',
  ':clown_face:': '&#x1F921;',
  ':nauseated_face:': '&#x1F922;',
  ':rolling_on_the_floor_laughing:': '&#x1F923;',
  ':drooling_face:': '&#x1F924;',
  ':lying_face:': '&#x1F925;',
  ':woman-facepalming:': '&#x1F926;&#x200D;&#x2640;&#xFE0F;',
  ':man-facepalming:': '&#x1F926;&#x200D;&#x2642;&#xFE0F;',
  ':face_palm:': '&#x1F926;',
  ':sneezing_face:': '&#x1F927;',
  ':face_with_raised_eyebrow:': '&#x1F928;',
  ':star-struck:': '&#x1F929;',
  ':zany_face:': '&#x1F92A;',
  ':shushing_face:': '&#x1F92B;',
  ':face_with_symbols_on_mouth:': '&#x1F92C;',
  ':face_with_hand_over_mouth:': '&#x1F92D;',
  ':face_vomiting:': '&#x1F92E;',
  ':exploding_head:': '&#x1F92F;',
  ':pregnant_woman:': '&#x1F930;',
  ':breast-feeding:': '&#x1F931;',
  ':palms_up_together:': '&#x1F932;',
  ':selfie:': '&#x1F933;',
  ':prince:': '&#x1F934;',
  ':man_in_tuxedo:': '&#x1F935;',
  ':mrs_claus:': '&#x1F936;',
  ':woman-shrugging:': '&#x1F937;&#x200D;&#x2640;&#xFE0F;',
  ':man-shrugging:': '&#x1F937;&#x200D;&#x2642;&#xFE0F;',
  ':shrug:': '&#x1F937;',
  ':woman-cartwheeling:': '&#x1F938;&#x200D;&#x2640;&#xFE0F;',
  ':man-cartwheeling:': '&#x1F938;&#x200D;&#x2642;&#xFE0F;',
  ':person_doing_cartwheel:': '&#x1F938;',
  ':woman-juggling:': '&#x1F939;&#x200D;&#x2640;&#xFE0F;',
  ':man-juggling:': '&#x1F939;&#x200D;&#x2642;&#xFE0F;',
  ':juggling:': '&#x1F939;',
  ':fencer:': '&#x1F93A;',
  ':woman-wrestling:': '&#x1F93C;&#x200D;&#x2640;&#xFE0F;',
  ':man-wrestling:': '&#x1F93C;&#x200D;&#x2642;&#xFE0F;',
  ':wrestlers:': '&#x1F93C;',
  ':woman-playing-water-polo:': '&#x1F93D;&#x200D;&#x2640;&#xFE0F;',
  ':man-playing-water-polo:': '&#x1F93D;&#x200D;&#x2642;&#xFE0F;',
  ':water_polo:': '&#x1F93D;',
  ':woman-playing-handball:': '&#x1F93E;&#x200D;&#x2640;&#xFE0F;',
  ':man-playing-handball:': '&#x1F93E;&#x200D;&#x2642;&#xFE0F;',
  ':handball:': '&#x1F93E;',
  ':wilted_flower:': '&#x1F940;',
  ':drum_with_drumsticks:': '&#x1F941;',
  ':clinking_glasses:': '&#x1F942;',
  ':tumbler_glass:': '&#x1F943;',
  ':spoon:': '&#x1F944;',
  ':goal_net:': '&#x1F945;',
  ':first_place_medal:': '&#x1F947;',
  ':second_place_medal:': '&#x1F948;',
  ':third_place_medal:': '&#x1F949;',
  ':boxing_glove:': '&#x1F94A;',
  ':martial_arts_uniform:': '&#x1F94B;',
  ':curling_stone:': '&#x1F94C;',
  ':croissant:': '&#x1F950;',
  ':avocado:': '&#x1F951;',
  ':cucumber:': '&#x1F952;',
  ':bacon:': '&#x1F953;',
  ':potato:': '&#x1F954;',
  ':carrot:': '&#x1F955;',
  ':baguette_bread:': '&#x1F956;',
  ':green_salad:': '&#x1F957;',
  ':shallow_pan_of_food:': '&#x1F958;',
  ':stuffed_flatbread:': '&#x1F959;',
  ':egg:': '&#x1F95A;',
  ':glass_of_milk:': '&#x1F95B;',
  ':peanuts:': '&#x1F95C;',
  ':kiwifruit:': '&#x1F95D;',
  ':pancakes:': '&#x1F95E;',
  ':dumpling:': '&#x1F95F;',
  ':fortune_cookie:': '&#x1F960;',
  ':takeout_box:': '&#x1F961;',
  ':chopsticks:': '&#x1F962;',
  ':bowl_with_spoon:': '&#x1F963;',
  ':cup_with_straw:': '&#x1F964;',
  ':coconut:': '&#x1F965;',
  ':broccoli:': '&#x1F966;',
  ':pie:': '&#x1F967;',
  ':pretzel:': '&#x1F968;',
  ':cut_of_meat:': '&#x1F969;',
  ':sandwich:': '&#x1F96A;',
  ':canned_food:': '&#x1F96B;',
  ':crab:': '&#x1F980;',
  ':lion_face:': '&#x1F981;',
  ':scorpion:': '&#x1F982;',
  ':turkey:': '&#x1F983;',
  ':unicorn_face:': '&#x1F984;',
  ':eagle:': '&#x1F985;',
  ':duck:': '&#x1F986;',
  ':bat:': '&#x1F987;',
  ':shark:': '&#x1F988;',
  ':owl:': '&#x1F989;',
  ':fox_face:': '&#x1F98A;',
  ':butterfly:': '&#x1F98B;',
  ':deer:': '&#x1F98C;',
  ':gorilla:': '&#x1F98D;',
  ':lizard:': '&#x1F98E;',
  ':rhinoceros:': '&#x1F98F;',
  ':shrimp:': '&#x1F990;',
  ':squid:': '&#x1F991;',
  ':giraffe_face:': '&#x1F992;',
  ':zebra_face:': '&#x1F993;',
  ':hedgehog:': '&#x1F994;',
  ':sauropod:': '&#x1F995;',
  ':t-rex:': '&#x1F996;',
  ':cricket:': '&#x1F997;',
  ':cheese_wedge:': '&#x1F9C0;',
  ':face_with_monocle:': '&#x1F9D0;',
  ':adult:': '&#x1F9D1;',
  ':child:': '&#x1F9D2;',
  ':older_adult:': '&#x1F9D3;',
  ':bearded_person:': '&#x1F9D4;',
  ':person_with_headscarf:': '&#x1F9D5;',
  ':woman_in_steamy_room:': '&#x1F9D6;&#x200D;&#x2640;&#xFE0F;',
  ':man_in_steamy_room:': '&#x1F9D6;&#x200D;&#x2642;&#xFE0F;',
  ':person_in_steamy_room:': '&#x1F9D6;',
  ':woman_climbing:': '&#x1F9D7;&#x200D;&#x2640;&#xFE0F;',
  ':man_climbing:': '&#x1F9D7;&#x200D;&#x2642;&#xFE0F;',
  ':person_climbing:': '&#x1F9D7;',
  ':woman_in_lotus_position:': '&#x1F9D8;&#x200D;&#x2640;&#xFE0F;',
  ':man_in_lotus_position:': '&#x1F9D8;&#x200D;&#x2642;&#xFE0F;',
  ':person_in_lotus_position:': '&#x1F9D8;',
  ':female_mage:': '&#x1F9D9;&#x200D;&#x2640;&#xFE0F;',
  ':male_mage:': '&#x1F9D9;&#x200D;&#x2642;&#xFE0F;',
  ':mage:': '&#x1F9D9;',
  ':female_fairy:': '&#x1F9DA;&#x200D;&#x2640;&#xFE0F;',
  ':male_fairy:': '&#x1F9DA;&#x200D;&#x2642;&#xFE0F;',
  ':fairy:': '&#x1F9DA;',
  ':female_vampire:': '&#x1F9DB;&#x200D;&#x2640;&#xFE0F;',
  ':male_vampire:': '&#x1F9DB;&#x200D;&#x2642;&#xFE0F;',
  ':vampire:': '&#x1F9DB;',
  ':mermaid:': '&#x1F9DC;&#x200D;&#x2640;&#xFE0F;',
  ':merman:': '&#x1F9DC;&#x200D;&#x2642;&#xFE0F;',
  ':merperson:': '&#x1F9DC;',
  ':female_elf:': '&#x1F9DD;&#x200D;&#x2640;&#xFE0F;',
  ':male_elf:': '&#x1F9DD;&#x200D;&#x2642;&#xFE0F;',
  ':elf:': '&#x1F9DD;',
  ':female_genie:': '&#x1F9DE;&#x200D;&#x2640;&#xFE0F;',
  ':male_genie:': '&#x1F9DE;&#x200D;&#x2642;&#xFE0F;',
  ':genie:': '&#x1F9DE;',
  ':female_zombie:': '&#x1F9DF;&#x200D;&#x2640;&#xFE0F;',
  ':male_zombie:': '&#x1F9DF;&#x200D;&#x2642;&#xFE0F;',
  ':zombie:': '&#x1F9DF;',
  ':brain:': '&#x1F9E0;',
  ':orange_heart:': '&#x1F9E1;',
  ':billed_cap:': '&#x1F9E2;',
  ':scarf:': '&#x1F9E3;',
  ':gloves:': '&#x1F9E4;',
  ':coat:': '&#x1F9E5;',
  ':socks:': '&#x1F9E6;',
  ':bangbang:': '&#x203C;&#xFE0F;',
  ':interrobang:': '&#x2049;&#xFE0F;',
  ':tm:': '&#x2122;&#xFE0F;',
  ':information_source:': '&#x2139;&#xFE0F;',
  ':left_right_arrow:': '&#x2194;&#xFE0F;',
  ':arrow_up_down:': '&#x2195;&#xFE0F;',
  ':arrow_upper_left:': '&#x2196;&#xFE0F;',
  ':arrow_upper_right:': '&#x2197;&#xFE0F;',
  ':arrow_lower_right:': '&#x2198;&#xFE0F;',
  ':arrow_lower_left:': '&#x2199;&#xFE0F;',
  ':leftwards_arrow_with_hook:': '&#x21A9;&#xFE0F;',
  ':arrow_right_hook:': '&#x21AA;&#xFE0F;',
  ':watch:': '&#x231A;',
  ':hourglass:': '&#x231B;',
  ':keyboard:': '&#x2328;&#xFE0F;',
  ':eject:': '&#x23CF;&#xFE0F;',
  ':fast_forward:': '&#x23E9;',
  ':rewind:': '&#x23EA;',
  ':arrow_double_up:': '&#x23EB;',
  ':arrow_double_down:': '&#x23EC;',
  ':black_right_pointing_double_triangle_with_vertical_bar:': '&#x23ED;&#xFE0F;',
  ':black_left_pointing_double_triangle_with_vertical_bar:': '&#x23EE;&#xFE0F;',
  ':black_right_pointing_triangle_with_double_vertical_bar:': '&#x23EF;&#xFE0F;',
  ':alarm_clock:': '&#x23F0;',
  ':stopwatch:': '&#x23F1;&#xFE0F;',
  ':timer_clock:': '&#x23F2;&#xFE0F;',
  ':hourglass_flowing_sand:': '&#x23F3;',
  ':double_vertical_bar:': '&#x23F8;&#xFE0F;',
  ':black_square_for_stop:': '&#x23F9;&#xFE0F;',
  ':black_circle_for_record:': '&#x23FA;&#xFE0F;',
  ':m:': '&#x24C2;&#xFE0F;',
  ':black_small_square:': '&#x25AA;&#xFE0F;',
  ':white_small_square:': '&#x25AB;&#xFE0F;',
  ':arrow_forward:': '&#x25B6;&#xFE0F;',
  ':arrow_backward:': '&#x25C0;&#xFE0F;',
  ':white_medium_square:': '&#x25FB;&#xFE0F;',
  ':black_medium_square:': '&#x25FC;&#xFE0F;',
  ':white_medium_small_square:': '&#x25FD;',
  ':black_medium_small_square:': '&#x25FE;',
  ':sunny:': '&#x2600;&#xFE0F;',
  ':cloud:': '&#x2601;&#xFE0F;',
  ':umbrella:': '&#x2602;&#xFE0F;',
  ':snowman:': '&#x2603;&#xFE0F;',
  ':comet:': '&#x2604;&#xFE0F;',
  ':phone:': '&#x260E;&#xFE0F;',
  ':ballot_box_with_check:': '&#x2611;&#xFE0F;',
  ':umbrella_with_rain_drops:': '&#x2614;',
  ':coffee:': '&#x2615;',
  ':shamrock:': '&#x2618;&#xFE0F;',
  ':point_up:': '&#x261D;&#xFE0F;',
  ':skull_and_crossbones:': '&#x2620;&#xFE0F;',
  ':radioactive_sign:': '&#x2622;&#xFE0F;',
  ':biohazard_sign:': '&#x2623;&#xFE0F;',
  ':orthodox_cross:': '&#x2626;&#xFE0F;',
  ':star_and_crescent:': '&#x262A;&#xFE0F;',
  ':peace_symbol:': '&#x262E;&#xFE0F;',
  ':yin_yang:': '&#x262F;&#xFE0F;',
  ':wheel_of_dharma:': '&#x2638;&#xFE0F;',
  ':white_frowning_face:': '&#x2639;&#xFE0F;',
  ':relaxed:': '&#x263A;&#xFE0F;',
  ':female_sign:': '&#x2640;&#xFE0F;',
  ':male_sign:': '&#x2642;&#xFE0F;',
  ':aries:': '&#x2648;',
  ':taurus:': '&#x2649;',
  ':gemini:': '&#x264A;',
  ':cancer:': '&#x264B;',
  ':leo:': '&#x264C;',
  ':virgo:': '&#x264D;',
  ':libra:': '&#x264E;',
  ':scorpius:': '&#x264F;',
  ':sagittarius:': '&#x2650;',
  ':capricorn:': '&#x2651;',
  ':aquarius:': '&#x2652;',
  ':pisces:': '&#x2653;',
  ':spades:': '&#x2660;&#xFE0F;',
  ':clubs:': '&#x2663;&#xFE0F;',
  ':hearts:': '&#x2665;&#xFE0F;',
  ':diamonds:': '&#x2666;&#xFE0F;',
  ':hotsprings:': '&#x2668;&#xFE0F;',
  ':recycle:': '&#x267B;&#xFE0F;',
  ':wheelchair:': '&#x267F;',
  ':hammer_and_pick:': '&#x2692;&#xFE0F;',
  ':anchor:': '&#x2693;',
  ':crossed_swords:': '&#x2694;&#xFE0F;',
  ':medical_symbol:': '&#x2695;&#xFE0F;',
  ':scales:': '&#x2696;&#xFE0F;',
  ':alembic:': '&#x2697;&#xFE0F;',
  ':gear:': '&#x2699;&#xFE0F;',
  ':atom_symbol:': '&#x269B;&#xFE0F;',
  ':fleur_de_lis:': '&#x269C;&#xFE0F;',
  ':warning:': '&#x26A0;&#xFE0F;',
  ':zap:': '&#x26A1;',
  ':white_circle:': '&#x26AA;',
  ':black_circle:': '&#x26AB;',
  ':coffin:': '&#x26B0;&#xFE0F;',
  ':funeral_urn:': '&#x26B1;&#xFE0F;',
  ':soccer:': '&#x26BD;',
  ':baseball:': '&#x26BE;',
  ':snowman_without_snow:': '&#x26C4;',
  ':partly_sunny:': '&#x26C5;',
  ':thunder_cloud_and_rain:': '&#x26C8;&#xFE0F;',
  ':ophiuchus:': '&#x26CE;',
  ':pick:': '&#x26CF;&#xFE0F;',
  ':helmet_with_white_cross:': '&#x26D1;&#xFE0F;',
  ':chains:': '&#x26D3;&#xFE0F;',
  ':no_entry:': '&#x26D4;',
  ':shinto_shrine:': '&#x26E9;&#xFE0F;',
  ':church:': '&#x26EA;',
  ':mountain:': '&#x26F0;&#xFE0F;',
  ':umbrella_on_ground:': '&#x26F1;&#xFE0F;',
  ':fountain:': '&#x26F2;',
  ':golf:': '&#x26F3;',
  ':ferry:': '&#x26F4;&#xFE0F;',
  ':boat:': '&#x26F5;',
  ':skier:': '&#x26F7;&#xFE0F;',
  ':ice_skate:': '&#x26F8;&#xFE0F;',
  ':woman-bouncing-ball:': '&#x26F9;&#xFE0F;&#x200D;&#x2640;&#xFE0F;',
  ':man-bouncing-ball:': '&#x26F9;&#xFE0F;&#x200D;&#x2642;&#xFE0F;',
  ':person_with_ball:': '&#x26F9;&#xFE0F;',
  ':tent:': '&#x26FA;',
  ':fuelpump:': '&#x26FD;',
  ':scissors:': '&#x2702;&#xFE0F;',
  ':white_check_mark:': '&#x2705;',
  ':airplane:': '&#x2708;&#xFE0F;',
  ':email:': '&#x2709;&#xFE0F;',
  ':fist:': '&#x270A;',
  ':hand:': '&#x270B;',
  ':v:': '&#x270C;&#xFE0F;',
  ':writing_hand:': '&#x270D;&#xFE0F;',
  ':pencil2:': '&#x270F;&#xFE0F;',
  ':black_nib:': '&#x2712;&#xFE0F;',
  ':heavy_check_mark:': '&#x2714;&#xFE0F;',
  ':heavy_multiplication_x:': '&#x2716;&#xFE0F;',
  ':latin_cross:': '&#x271D;&#xFE0F;',
  ':star_of_david:': '&#x2721;&#xFE0F;',
  ':sparkles:': '&#x2728;',
  ':eight_spoked_asterisk:': '&#x2733;&#xFE0F;',
  ':eight_pointed_black_star:': '&#x2734;&#xFE0F;',
  ':snowflake:': '&#x2744;&#xFE0F;',
  ':sparkle:': '&#x2747;&#xFE0F;',
  ':x:': '&#x274C;',
  ':negative_squared_cross_mark:': '&#x274E;',
  ':question:': '&#x2753;',
  ':grey_question:': '&#x2754;',
  ':grey_exclamation:': '&#x2755;',
  ':exclamation:': '&#x2757;',
  ':heavy_heart_exclamation_mark_ornament:': '&#x2763;&#xFE0F;',
  ':heart:': '&#x2764;&#xFE0F;',
  ':heavy_plus_sign:': '&#x2795;',
  ':heavy_minus_sign:': '&#x2796;',
  ':heavy_division_sign:': '&#x2797;',
  ':arrow_right:': '&#x27A1;&#xFE0F;',
  ':curly_loop:': '&#x27B0;',
  ':loop:': '&#x27BF;',
  ':arrow_heading_up:': '&#x2934;&#xFE0F;',
  ':arrow_heading_down:': '&#x2935;&#xFE0F;',
  ':arrow_left:': '&#x2B05;&#xFE0F;',
  ':arrow_up:': '&#x2B06;&#xFE0F;',
  ':arrow_down:': '&#x2B07;&#xFE0F;',
  ':black_large_square:': '&#x2B1B;',
  ':white_large_square:': '&#x2B1C;',
  ':star:': '&#x2B50;',
  ':o:': '&#x2B55;',
  ':wavy_dash:': '&#x3030;&#xFE0F;',
  ':part_alternation_mark:': '&#x303D;&#xFE0F;',
  ':congratulations:': '&#x3297;&#xFE0F;',
  ':secret:': '&#x3299;&#xFE0F;',
};

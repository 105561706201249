import { Renderer, RenderTexture } from '@pixi/core';
import { Graphics, Texture } from 'pixi.js';

import { BOT_MESSAGE_BLOCK_PRIMARY_COLOR, BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';

import { CONNECTION_POINT_BORDER_SIZE, CONNECTION_POINT_DIAMETER } from './connect-action.abs';

let connectionPointTexture: Texture | null = null;
let hoveredConnectionPointTexture: Texture | null = null;

let lastRendererContextUID: number | null = null;

// Дополнительное пространство в текстуре, чтоб круг точно влез и не касался краёв
const TEXTURE_EXTRA_SPACE = 2;

export function getConnectionPoint(renderer: Renderer): Texture {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    connectionPointTexture = null;
  }
  lastRendererContextUID = renderer.CONTEXT_UID;
  if (connectionPointTexture) {
    return connectionPointTexture;
  }

  const graphics = new Graphics();

  graphics.lineStyle(CONNECTION_POINT_BORDER_SIZE, BOT_MESSAGE_BLOCK_PRIMARY_COLOR, 1);
  graphics.beginFill(BOT_WHITE_COLOR, 1);
  const connectionRadius = CONNECTION_POINT_DIAMETER * 2;
  graphics.drawCircle(connectionRadius, connectionRadius, connectionRadius);

  graphics.position.set(TEXTURE_EXTRA_SPACE / 2, TEXTURE_EXTRA_SPACE / 2);
  const renderTexture = RenderTexture.create({
    width: graphics.width + TEXTURE_EXTRA_SPACE,
    height: graphics.height + TEXTURE_EXTRA_SPACE,
    resolution: 5,
  });

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  connectionPointTexture = renderTexture;

  return connectionPointTexture;
}

export function getHoveredConnectionPoint(renderer: Renderer): Texture {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    hoveredConnectionPointTexture = null;
  }

  if (hoveredConnectionPointTexture) {
    return hoveredConnectionPointTexture;
  }

  const graphics = new Graphics();

  graphics.beginFill(0x5c6370, 1);
  const connectionRadius = CONNECTION_POINT_DIAMETER * 2;
  graphics.drawCircle(connectionRadius, connectionRadius, connectionRadius);
  graphics.endFill();

  graphics.position.set(TEXTURE_EXTRA_SPACE / 2, TEXTURE_EXTRA_SPACE / 2);
  const renderTexture = RenderTexture.create({
    width: graphics.width + TEXTURE_EXTRA_SPACE,
    height: graphics.height + TEXTURE_EXTRA_SPACE,
    resolution: 5,
  });

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  hoveredConnectionPointTexture = renderTexture;

  return hoveredConnectionPointTexture;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { DATA_COLLECTION_PAGE_ROUTES } from '@panel/app/pages/data-collection/data-collection-page.routes';

import { DataCollectionModule } from './pages/data-collection/data-collection.module';

@NgModule({
  imports: [
    CommonModule,
    DataCollectionModule,
    UIRouterUpgradeModule.forChild({ states: DATA_COLLECTION_PAGE_ROUTES }),
  ],
})
export class DataCollectionPageModule {}

import { Container, IPointData } from 'pixi.js';
import { Observable, Subscription } from 'rxjs';
import { generate } from 'short-uuid';

import { getActionsBackgroundRect } from '@panel/app/pages/chat-bot/content/views/actions/shared-textures';
import { BlockType, CARD_WIDTH } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { IPoint } from '@panel/app/pages/chat-bot/content/views/connection';
import {
  BOT_CONDITION_BLOCK_DARKEN_PRIMARY_COLOR,
  BOT_CONDITION_BLOCK_LIGHTEN_PRIMARY_COLOR,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasHTMLText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

import { ActionStyle, BaseActionABS, ConnectionSourceActionABS, MAX_ACTION_WIDTH } from './abstract';

export class BotDefaultConditionAction extends ConnectionSourceActionABS {
  blockType: BlockType = 'condition';

  rerenderConnection$!: Observable<void>;

  protected style!: ActionStyle;

  // Подписка на удаление nextBranch
  nextBranchDestroySub?: Subscription;

  uid!: string;

  constructor(private readonly canvasText: string, ...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);
    this.render();
    this.uid = generate();
    this.rerenderConnection$ = this.externalPositionChange;
  }

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  getConnectionPointCoords(element: Container | null = null): IPointData {
    if (!element) {
      return { x: 0, y: 0 };
    }
    return {
      x: element.width + this.style.padding.horizontal - this.connectionPoint.width / 2,
      y: element.height / 2 - this.connectionPoint.height / 2,
    };
  }

  get height(): number {
    return this.container.height;
  }

  get width(): number {
    return this.container.width;
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 7,
        horizontal: 10,
      },
      border: {
        size: 0,
        color: 0xc7cad1,
        radius: 10,
      },
      background: {
        color: BOT_CONDITION_BLOCK_LIGHTEN_PRIMARY_COLOR,
      },
    };
  }

  redraw(): void {
    return;
  }

  get renderReady(): boolean {
    return this.container.renderable && this.container.children.length > 0;
  }

  get connectionPointGlobalCoordinates(): IPoint {
    // TODO: Вот это обращение к parent.parent мне очень не нравится, хочется абстракции, но пока не удалось сообразить
    const branchPosition = this.container.parent.parent.position;
    return {
      x: branchPosition.x + CARD_WIDTH,
      y: branchPosition.y + this.container.y + this.container.height / 2,
    };
  }

  destroy() {
    this.connection?.dropConnection();
    super.destroy();
  }

  render(): Container {
    const padding = this.style.padding.horizontal * 2;
    const fontSize = 14;
    const textOptions = {
      fontSize,
      lineHeight: fontSize * 1.42857143,
      wordWrapWidth: MAX_ACTION_WIDTH - padding,
      fill: BOT_CONDITION_BLOCK_DARKEN_PRIMARY_COLOR,
    };

    const text = renderCanvasHTMLText(this.canvasText, textOptions);
    const bg = getActionsBackgroundRect(
      text.height + this.style.padding.vertical * 2,
      this.style.background.color,
      this.pixiApp.renderer,
    );
    bg.zIndex = 1;
    text.position.set(this.style.padding.horizontal, this.style.padding.vertical);
    bg.addChild(text);

    this.container.addChild(bg, this.connectionPoint);

    const { x: xConnectionPointPosition, y: yConnectionPointPosition } = this.getConnectionPointCoords(this.container);
    const scale = this.getConnectionPointScale();
    this.connectionPoint.setTransform(xConnectionPointPosition, yConnectionPointPosition, scale, scale);

    return bg;
  }
}

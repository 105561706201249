(function () {
  'use strict';

  angular
    .module('myApp.completionRegistration')
    .controller('cqCompletionRegistrationFormControlNameController', cqCompletionRegistrationFormControlNameController);

  function cqCompletionRegistrationFormControlNameController(validationHelper) {
    let vm = this;
    vm.$onInit = init;

    /**
     * Максимальная длинна имени пользователя
     *
     * @type {number}
     */
    const USER_NAME_MAX_LENGTH = 30;

    function init() {
      vm.getClassForControl = getClassForControl;
      vm.isRequiredMode = vm.isRequiredMode ?? null;
      vm.USER_NAME_MAX_LENGTH = USER_NAME_MAX_LENGTH;
    }

    /**
     * Получение класса для контрола
     */
    function getClassForControl() {
      return {
        'has-error': isValidName(),
      };
    }

    /**
     * Является ли имя пользователя валидным
     */
    function isValidName() {
      return validationHelper.getValidationResult(vm.completionRegistrationForm, 'name');
    }
  }
})();

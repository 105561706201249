import cloneDeep from 'lodash-es/cloneDeep';
import { Container, Text } from 'pixi.js';

import { PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { HEADING_INDENT } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { getIconHeading } from '@panel/app/pages/chat-bot/content/views/building-elements/block-heading/block-heading.textures';
import { renderCanvasText, spliceText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';

export type BlockHeadingStyle = {
  icon: {
    color: number;
    background: number;
  };
  textColor: number;
};

const BLOCK_VIEW_NAME_MAX_LENGTH = 25;

// Отспуп от иконки
const INDENT_BETWEEN_ICON_AND_TEXT = 5;

/**
 * Заголовок блока на канвасе
 */
export class BlockHeadingBuildingElement {
  readonly container = new Container();

  constructor(
    private blockName: string,
    private blockIcon: string,
    private scale: number,
    private style: BlockHeadingStyle,
    protected readonly pixiApp: PixiApplication,
  ) {
    this.renderInitialHeadingContent();
  }

  private get splicedBlockName(): string {
    return spliceText(this.blockName, BLOCK_VIEW_NAME_MAX_LENGTH / this.scale);
  }

  redraw() {
    this.container.removeChildAt(0);
    const roundedIcon = this.renderHeadingIcon();
    this.container.addChildAt(roundedIcon, 0);
    const textNode = this.container.getChildAt(1);
    if (textNode instanceof Text) {
      textNode.style.fill = this.style.textColor;
    }
  }

  private renderInitialHeadingContent() {
    const textNode = renderCanvasText(this.splicedBlockName, { wordWrap: false });
    const roundedIcon = this.renderHeadingIcon();
    this.container.addChild(roundedIcon);
    this.container.addChild(textNode);
    const textNodeY = (roundedIcon.height - textNode.height) / 2;
    textNode.position.set(roundedIcon.width + INDENT_BETWEEN_ICON_AND_TEXT, textNodeY);
  }

  private renderHeadingIcon(): Container {
    return getIconHeading(this.pixiApp.renderer, this.blockIcon, this.style.icon.color, this.style.icon.background);
  }

  updateBlockName(newName?: string): void {
    this.blockName = newName ?? this.blockName;
    const textNode = this.container.getChildAt(1);
    if (!(textNode instanceof Text)) {
      throw new Error('Text node has incorrect type');
    }
    textNode.text = this.splicedBlockName;
  }

  /**
   * Обновление иконки
   * @param icon - Новая иконка
   */
  public setBlockIcon(icon: string): void {
    this.blockIcon = icon;
    this.redraw();
  }

  setScale(scale: number): void {
    this.scale = scale;
    this.container.setTransform(this.container.x, this.container.y, scale, scale);
    const headingVerticalPosition = -(this.container.height + HEADING_INDENT);
    this.container.position.set(0, headingVerticalPosition);
    this.updateBlockName();
  }

  updateStyle(newStyle: Partial<BlockHeadingStyle>) {
    this.style = cloneDeep({
      ...this.style,
      ...newStyle,
    });
    this.redraw();
  }
}

(function () {
  'use strict';

  require('./push-settings.controller');

  angular.module('myApp.pushSettings').component('cqPushSettings', cqPushSettings());

  function cqPushSettings() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        pushSettings: '=', // настройки пушей
      },
      controller: 'CqPushSettingsController',
      controllerAs: 'vm',
      template: require('./push-settings.html'),
    };
  }
})();

(function () {
  'use strict';

  require('./auto-event-type.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventType', cqAutoEventType());

  function cqAutoEventType() {
    return {
      bindings: {
        isNew: '<', // Создание или редактирование
        paramsType: '=', // Тип параметров автособытия
        type: '=', // Тип автособытия
      },
      controller: 'CqAutoEventTypeController',
      controllerAs: 'vm',
      template: require('./auto-event-type.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from 'nxt-sortablejs';

import { ChatAudioMessageModule } from '@panel/app/pages/settings/chat/components/audio-message/chat-audio-message.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { VisibilitySettingsModule } from './components/visibility-settings/visibility-settings.module';

@NgModule({
  declarations: [],
  imports: [
    ChatAudioMessageModule,
    CommonModule,
    TranslocoModule,
    SharedModule,
    SortablejsModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    VisibilitySettingsModule,
  ],
})
export class ChatSettingsModule {}

(function () {
  'use strict';

  angular
    .module('myApp.triggerChains.detailedStatistics')
    .factory('CAMELED_SENDING_TYPES', getCameledSendingTypes)
    .factory('DATASET_TYPES', getDatasetTypes)
    .factory('MESSAGE_SENDING_TYPE_COLORS', getMessageSendingTypeColors)
    .factory('TRIGGER_CHAIN_DETAILED_STATISTICS_TABS', getTriggerChainDetailedStatisticsTabs);

  /** Типы датасетов для графиков */
  function getDatasetTypes() {
    return {
      GENERAL: 'general',
    };
  }

  function getCameledSendingTypes() {
    return {
      BOUNCED: 'bounced',
      CLICKED: 'clicked',
      GOAL_COMPLETED: 'goalCompleted',
      READ: 'read',
      REPLIED: 'replied',
      SENDED: 'sended',
      SPAM: 'spam',
      UNSUBSCRIBED: 'unsubscribed',
    };
  }

  /** Цвета для датасетов по сообщению */
  function getMessageSendingTypeColors(CAMELED_SENDING_TYPES) {
    return {
      [CAMELED_SENDING_TYPES.CLICKED]: '#5a9ede',
      [CAMELED_SENDING_TYPES.GOAL_COMPLETED]: '#377dc0',
      [CAMELED_SENDING_TYPES.READ]: '#4086a3',
      [CAMELED_SENDING_TYPES.REPLIED]: '#43a4cc',
      [CAMELED_SENDING_TYPES.SENDED]: '#3e6372',
    };
  }

  function getTriggerChainDetailedStatisticsTabs() {
    return {
      GENERAL: {
        ORDER: 0,
        TEMPLATE_URL: 'js/components/trigger-chains/detailed-statistics/tabs/general.html',
        VALUE: 'general',
      },
      SENDINGS: {
        ORDER: 2,
        TEMPLATE_URL: 'js/components/trigger-chains/detailed-statistics/tabs/sendings.html',
        VALUE: 'sendings',
      },
    };
  }
})();

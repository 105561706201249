import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { DowngradeAbilitiesComponent } from './downgrade-abilities.component';

@NgModule({
  declarations: [DowngradeAbilitiesComponent],
  exports: [DowngradeAbilitiesComponent],
  imports: [CommonModule, TranslocoModule],
})
export class DowngradeAbilitiesModule {}

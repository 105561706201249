<ng-container *transloco="let t; read: 'leadsPassingPreviewModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ heading }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
      data-test="close-button"
      type="button"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <img
      class="mb-15 w-100"
      [src]="imageSrc"
      data-test="leads-passing-preview-modal-body-image"
    />
    <div
      [innerHTML]="bodyText"
      data-test="leads-passing-preview-modal-body-text"
    ></div>
  </div>
  <div class="modal-footer flex flex-row">
    <ng-container *ngIf="integrationType === STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.LEAD_BOT">
      <button
        class="btn btn-primary"
        (click)="onClickCreateLeadBotButton()"
        data-test="leads-passing-preview-modal-chat-bot-btn"
        type="button"
      >
        {{ buttonText }}
      </button>
    </ng-container>
    <ng-container *ngIf="integrationType !== STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.LEAD_BOT">
      <button
        class="btn btn-primary"
        (click)="onClickSetUpIntegrationButton(integrationType)"
        data-test="leads-passing-preview-modal-integration-btn"
        type="button"
      >
        {{ buttonText }}
      </button>
    </ng-container>
  </div>
</ng-container>

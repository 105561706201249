(function () {
  'use strict';

  require('./lead-bot-settings.controller');

  angular.module('myApp.modals.leadBotSettings').component('cqLeadBotSettingsModal', cqLeadBotSettingsModal());

  function cqLeadBotSettingsModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp - Текущее приложение
        // sender - Отправитель ботов
      },
      controller: 'CqLeadBotSettingsController',
      controllerAs: 'vm',
      template: require('./lead-bot-settings.html'),
    };
  }
})();

(function () {
  'use strict';

  require('./party-is-over.controller');

  angular.module('myApp.modals.partyIsOver').component('cqPartyIsOverModal', cqPartyIsOverModal());

  function cqPartyIsOverModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=', // В resolve должны передаваться следующие параметры:
        // {Object} billingInfo - Информация о биллинге
        // {Object} currentApp - Текущее приложение
        // {Object} djangoUser - Текущий пользователь
      },
      controller: 'CqPartyIsOverController',
      controllerAs: 'vm',
      template: require('./party-is-over.html'),
    };
  }
})();

<cq-branch-action-app-online-condition
  [block]="branch"
  [actionForm]="getAppOnlineAction(branch).form"
  [nextBranchOptions]="nextBranchOptions"
  [currentApp]="currentApp"
  [chatBotType]="chatBotType"
  [chatBotId]="chatBotId"
  (branchCreate)="callbacks.createNewBranch($event, getAppOnlineAction(branch))"
></cq-branch-action-app-online-condition>
<hr class="mb-10" />
<cq-branch-action-app-offline-condition
  [block]="branch"
  [actionForm]="getAppOfflineAction(branch).form"
  [nextBranchOptions]="nextBranchOptions"
  [currentApp]="currentApp"
  [chatBotType]="chatBotType"
  [chatBotId]="chatBotId"
  (branchCreate)="callbacks.createNewBranch($event, getAppOfflineAction(branch))"
></cq-branch-action-app-offline-condition>

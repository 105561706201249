(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupIndentBlockSettingsController', CqPopupIndentBlockSettingsController);

  function CqPopupIndentBlockSettingsController(validationHelper) {
    var vm = this;

    /**
     * Максимальная высота разделителя
     *
     * @type {number}
     */
    var INDENT_MAX_HEIGHT = 1000;

    /**
     * Минимальная высота разделителя
     *
     * @type {number}
     */
    var INDENT_MIN_HEIGHT = 0;

    vm.$onInit = init;

    function init() {
      vm.INDENT_MAX_HEIGHT = INDENT_MAX_HEIGHT;
      vm.INDENT_MIN_HEIGHT = INDENT_MIN_HEIGHT;
      vm.indentBlockSettingsForm = null; // форма настроек блока Разделитель
      vm.validationHelper = validationHelper;
    }
  }
})();

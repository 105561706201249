import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TriggerChainBreadcrumbsModule } from '@panel/app/pages/trigger-chains/components/breadcrumbs/trigger-chain-breadcrumbs.module';

import { TriggerChainAdditionalActionsModule } from './components/trigger-chain-additional-actions/trigger-chain-additional-actions.module';
import { TriggerChainListComponent } from './trigger-chain-list.component';
import { TriggerChainTableModule } from './trigger-chain-table/trigger-chain-table.module';

@NgModule({
  declarations: [TriggerChainListComponent],
  exports: [TriggerChainListComponent],
  imports: [CommonModule, TriggerChainAdditionalActionsModule, TriggerChainBreadcrumbsModule, TriggerChainTableModule],
})
export class TriggerChainListModule {}

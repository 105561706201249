import { Injectable } from '@angular/core';

import { PLAN_FEATURE, PLAN_FEATURES } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureService } from '@panel/app/services/billing/plan-feature/plan-feature.service';
import { ProductFeatureDenialReason } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanSpecialDenialReasonService } from '@panel/app/services/billing/plan-special-denial-reason/plan-special-denial-reason.service';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';
import { App } from '@http/app/app.model';

/**
 * Сервис для работы с причиной отказа в доступе к фиче
 */
@Injectable({ providedIn: 'root' })
export class PlanDenialReasonService {
  constructor(
    private readonly planFeatureService: PlanFeatureService,
    private readonly planSpecialDenialReasonService: PlanSpecialDenialReasonService,
    private readonly planVersionService: PlanVersionService,
  ) {}

  /**
   * Получение причины отказа в доступе до фичи
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   */
  getReason(productFeature: PLAN_FEATURE, app: App, amount?: number): ProductFeatureDenialReason {
    if (this.planFeatureService.isSpecialFeature(productFeature)) {
      return this.planSpecialDenialReasonService.getReason(productFeature, app, amount);
    }

    switch (true) {
      case this.planVersionService.isFreemium():
        return {
          addOn: null,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.FREEMIUM,
          productFeature,
        };
      case this.planVersionService.isLTD():
        return {
          addOn: null,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.LTD,
          productFeature,
        };
      case this.planVersionService.isV1(app):
        return {
          addOn: PLAN_FEATURES[productFeature][PAID_PLAN_VERSION.V1].addOns[0] ?? null,
          capability: null,
          limitExceeded: false,
          limitAmount: null,
          needChat: true,
          paidPlanVersion: PAID_PLAN_VERSION.V1,
          productFeature,
        };
      case this.planVersionService.isV2(app):
        return {
          addOn: PLAN_FEATURES[productFeature][PAID_PLAN_VERSION.V2].addOns[0] ?? null,
          capability: PLAN_FEATURES[productFeature][PAID_PLAN_VERSION.V2].capabilities[0] ?? null,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V2,
          productFeature,
        };
      default:
        return {
          addOn: PLAN_FEATURES[productFeature][PAID_PLAN_VERSION.V3].addOns[0] ?? null,
          capability: PLAN_FEATURES[productFeature][PAID_PLAN_VERSION.V3].capabilities[0] ?? null,
          limitExceeded: false,
          limitAmount: null,
          needChat: false,
          paidPlanVersion: PAID_PLAN_VERSION.V3,
          productFeature,
        };
    }
  }
}

import { ChangeDetectorRef, Directive, HostBinding, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { isArray } from 'angular';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';

/**
 * Эта директива аналог uiSrefActiveEq и uiSrefActive, но за одним исключением
 *
 * в нее можно передать список состояний при которых элементу выставятся нужные классы
 */
@Directive({
  selector: '[cqStateActive]',
  providers: [DestroyService],
})
export class StateActiveDirective implements OnInit {
  /**
   * Список состояний
   * или объект, где ключ - это класс, а значение - список состояний
   */
  @Input()
  set cqStateActive(states: string[] | Record<string, string[]>) {
    if (isArray(states)) {
      states = {
        [this.DEFAULT_ACTIVE_CLASS]: states,
      };
    }

    this.classForStates = states;
  }

  /**
   * Разрешает выставлять несколько классов из объекта
   *
   * { class1: ['state.1', ...], class2: ['state.1', ...]}
   *
   * если true, то в случае совпадения в обоих массивах выставятся оба класса (class1 и class2)
   *
   * если false, то выставится самый первый в списке (class1)
   */
  @Input()
  multipleClasses: boolean = false;

  @HostBinding('class')
  classes = '';

  /** Объект, где ключ - это класс, а значение - список состояний */
  private classForStates!: Record<string, string[]>;

  /** Класс активности состояния по-умолчанию */
  private readonly DEFAULT_ACTIVE_CLASS = 'active';

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    private readonly uiRouter: UIRouter,
  ) {}

  ngOnInit() {
    if (!this.uiRouter.globals.success$) {
      throw new Error('Has no UIRouterRx plugin');
    }

    this.uiRouter.globals.success$
      .pipe(
        startWith(null),
        map(() => {
          return this.uiRouter.stateService.current.name;
        }),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        let classes = '';

        for (let elClass in this.classForStates) {
          if (this.classForStates[elClass].some((state) => this.uiRouter.stateService.includes(state))) {
            classes += ` ${elClass}`;
            if (!this.multipleClasses) {
              break;
            }
          }
        }

        this.classes = classes;
        this.cdr.markForCheck();
      });
  }
}

<ng-container *transloco="let t; read: 'navigationItemComponent'">
  <!-- тултип должен показываться даже если кнопка неактивна, поэтому он вынесен на родительский элемент -->
  <div
    [ngbTooltip]="tooltipText"
    [disableTooltip]="disableTooltip"
    container="body"
    tooltipClass="navigation-item-tooltip"
    placement="right"
  >
    <a
      class="btn btn-borderless-navigation"
      (click)="clicked.emit()"
      [uiSref]="disable ? '#' : stateTo"
      [cqStateActive]="states"
      [ngClass]="{
        disabled: disable,
        'not-access': disabledByNotHavingAccess,
        'new-year-icon': isDashboard && isShowNewYearLogo
      }"
    >
      <i
        *ngIf="!isDashboard; else dashboardIcon"
        class="btn-icon cqi-navigation"
        [ngClass]="icon"
      ></i>
      <ng-template #dashboardIcon>
        <ng-container *ngIf="!isShowNewYearLogo; else dashboardNeyYearIcon">
          <img
            *cqHideInUs
            class="logo carrot-quest"
            src="assets/img/default/logos/carrot-quest.svg"
          />
          <img
            *cqHideInRu
            class="logo dashly"
            src="assets/img/default/logos/dashly.svg"
          />
        </ng-container>
      </ng-template>

      <!-- Иконки для нового года -->
      <ng-template #dashboardNeyYearIcon>
        <img
          *cqHideInUs
          class="logo carrot-quest"
          src="assets/img/default/logos/carrot-quest-new-year.svg"
        />
        <img
          *cqHideInRu
          class="logo dashly"
          src="assets/img/default/logos/dashly-new-year.svg"
        />
      </ng-template>
      <!-- Можно прокидывать только лейблы (new/beta и счётчики) и индикаторы в виде точки -->
      <span class="content-wrapper">
        <ng-content select=".dot-indicator"></ng-content>
        <ng-content select=".label"></ng-content>
      </span>
    </a>

    <!-- иконки специально вынесены за кнопку, чтобы при disable кнопки они не уходили в opacity -->
    <span class="lock"></span>
    <cq-plan-capability-coin class="position-absolute no-pointer-events coin-xs coin-right"></cq-plan-capability-coin>
  </div>
</ng-container>

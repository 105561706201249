/* eslint simple-import-sort/imports: Off */
import { UrlService } from '@uirouter/core';
import angular from 'angular';
import { enableProdMode } from '@angular/core';
import { environment } from '@environment';
import { downgradedAppModule } from './app/app.module';
import './app-old/app.module';
import { utils as PixiUtils } from 'pixi.js';

if (environment.production) {
  PixiUtils.skipHello();
  enableProdMode();
} else {
  //@ts-ignore
  //(globalThis  as any).__PIXI_APP__ = app; // eslint-disable-line
}

/*
  Из-за выбранного нами способа работы гибридного приложения,
  мы вынуждены запускать сначала AngularJS, а потом Angular.
  Мы можем добавлять роуты, в Angular, но AngularJS их не видит.
  Чтобы исправить данную ситуацию, нам необходимо в ручную, после запуска,
  синхронизировать список роутов из AngularJS и Angular между собой
*/

/*
  Сборка cqPanelApp модуля и настройка перехвата запросов router'а,
  чтобы AngularJs мог перехватить обращение к Angular и корректно его обработать
*/
angular
  .module('cqPanelApp', ['myApp', downgradedAppModule])
  .config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);

// Запуск приложения
angular.bootstrap(document, ['cqPanelApp']);

// Синхронизация роутов из AngularJS и Angular между собой
const injector: angular.auto.IInjectorService = angular.element(document).injector();
const urlService: UrlService = injector.get('$urlService');
setTimeout(() => {
  urlService.listen();
  urlService.sync();
}, 0);

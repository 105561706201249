import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StepContentComponent } from './step-content.component';

@NgModule({
  declarations: [StepContentComponent],
  exports: [StepContentComponent],
  imports: [CommonModule],
})
export class StepContentModule {}

<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ heading }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div [innerHTML]="body"></div>
  </div>

  <div class="modal-footer">
    <button
      class="btn"
      [ngClass]="cancelButtonClass"
      type="button"
      (click)="activeModal.dismiss()"
    >
      {{ cancelButtonText }}
    </button>
    <button
      class="btn"
      [ngClass]="confirmButtonClass"
      type="button"
      (click)="activeModal.close(true)"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</ng-container>

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.directoryEditor.templates')
    .controller('CqTemplatesDirectoryEditorController', CqTemplatesDirectoryEditorController);

  function CqTemplatesDirectoryEditorController(
    $interpolate,
    $translate,
    $uibModal,
    toastr,
    validationHelper,
    ACTIONS_ON_DIRECTORY,
    carrotquestHelper,
    templateDirectoryModel,
  ) {
    var vm = this;

    var MAX_DIRECTORY_NAME_LENGTH = 50; //Максимальная длинна имени папки

    vm.$onInit = init;

    function init() {
      vm.apiMessageDirectoryAlreadyExists = false; // флаг уже существующей папки с таким именем
      vm.confirm = confirm;
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса
      vm.isEdit = false; //Создание или редактирование папки
      vm.MAX_DIRECTORY_NAME_LENGTH = MAX_DIRECTORY_NAME_LENGTH;
      vm.openRemoveDirectoryModal = openRemoveDirectoryModal;
      vm.validationHelper = validationHelper;

      if (angular.isDefined(vm.resolve.directory)) {
        vm.isEdit = true;
      } else {
        trackStartCreateDirectory();
      }
    }

    /**
     * Закрытие модалки с подтверждением (снаружи вызовется successCallback)
     *
     * @param {boolean} isValid - Валидностиь формы
     */
    function confirm(isValid) {
      if (isValid) {
        vm.isApiRequestPerformed = true;
        vm.apiMessageDirectoryAlreadyExists = false;

        if (vm.isEdit) {
          firstValueFrom(
            templateDirectoryModel.edit(
              vm.resolve.currentApp.id,
              vm.resolve.directory,
              'MessageTemplateDirectoryAlreadyExistsError',
            ),
          )
            .then(editDirectorySuccess, createOrEditDirectoryError)
            .finally(createOrEditDirectoryFinally);
        } else {
          firstValueFrom(
            templateDirectoryModel.create(
              vm.resolve.currentApp.id,
              vm.resolve.directory.prettyName,
              'MessageTemplateDirectoryAlreadyExistsError',
            ),
          )
            .then(createDirectorySuccess, createOrEditDirectoryError)
            .finally(createOrEditDirectoryFinally);
        }
      }

      /**
       * Папка успешно отредактирована
       *
       * @param directory
       */
      function editDirectorySuccess(directory) {
        trackDirectoryChanged(vm.resolve.directory.prettyName);
        toastr.success($translate.instant('modals.messageDirectoryEditor.toastr.directoryEditedSuccess'));
        vm.modalInstance.close({
          directory: directory,
          action: ACTIONS_ON_DIRECTORY.RENAME,
        });
      }

      /**
       * Папка успешно создана
       *
       * @param directory
       */
      function createDirectorySuccess(directory) {
        trackDirectoryCreated(vm.resolve.directory.prettyName);
        toastr.success(
          $translate.instant('modals.messageDirectoryEditor.toastr.directoryCreatedSuccess', {
            name: vm.resolve.directory.prettyName,
          }),
        );
        vm.modalInstance.close({
          directory: directory,
          action: ACTIONS_ON_DIRECTORY.CREATE,
        });
      }

      /**
       * Ошибка создания/редактирования папки
       *
       * @param response
       */
      function createOrEditDirectoryError(response) {
        if (response.meta && response.meta.error === 'MessageTemplateDirectoryAlreadyExistsError') {
          vm.apiMessageDirectoryAlreadyExists = true;
        }
      }

      /**
       * Финальная стадия удаления/редактирования папки
       */
      function createOrEditDirectoryFinally() {
        vm.isApiRequestPerformed = false;
      }
    }

    /**
     * Открытие модалки удаления папки
     */
    function openRemoveDirectoryModal() {
      var removeDirectoryModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.messageDirectoryEditor.removeDirectoryModal.heading'),
              body: $translate.instant('modals.messageDirectoryEditor.removeDirectoryModal.bodyTemplate'),
              confirmButtonText: $translate.instant(
                'modals.messageDirectoryEditor.removeDirectoryModal.confirmButtonText',
              ),
              needConfirmation: false,
            };
          },
        },
      });

      removeDirectoryModal.result.then(removeDirectoryModalSuccess);

      function removeDirectoryModalSuccess() {
        firstValueFrom(templateDirectoryModel.remove(vm.resolve.currentApp.id, vm.resolve.directory.id)).then(
          removeDirectorySuccess,
        );

        function removeDirectorySuccess() {
          vm.resolve.directory.prettyName = vm.resolve.directory.name;
          trackDirectoryRemoved(vm.resolve.directory.prettyName);
          toastr.success(
            $translate.instant('modals.messageDirectoryEditor.toastr.directoryRemovedSuccess', {
              name: vm.resolve.directory.prettyName,
            }),
          );
          vm.modalInstance.close({
            directory: vm.resolve.directory,
            action: ACTIONS_ON_DIRECTORY.DELETE,
          });
        }
      }
    }

    /**
     * Трек изменения папки
     *
     * @param {String} directoryName - Имя папки
     */
    function trackDirectoryChanged(directoryName) {
      carrotquestHelper.track('Шаблоны - переименовал папку', {
        app_id: vm.resolve.currentApp.id,
        'Название папки': directoryName,
      });
    }

    /**
     * Трек создания папки
     *
     * @param {String} directoryName - Имя папки
     */
    function trackDirectoryCreated(directoryName) {
      carrotquestHelper.track('Шаблоны - создал новую папку', {
        app_id: vm.resolve.currentApp.id,
        'Название папки': directoryName,
      });
    }

    /**
     * Трек удаления папки
     *
     * @param {String} directoryName - Имя папки
     */
    function trackDirectoryRemoved(directoryName) {
      carrotquestHelper.track('Шаблоны - удалил папку', {
        app_id: vm.resolve.currentApp.id,
        'Название папки': directoryName,
      });
    }

    /**
     * Событие создания папки
     */
    function trackStartCreateDirectory() {
      carrotquestHelper.track('Шаблоны - начал создавать новую папку', {
        app_id: vm.resolve.currentApp.id,
      });
    }
  }
})();

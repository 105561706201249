(function () {
  'use strict';

  angular.module('myApp.dashboard', []);

  require('./dashboard.routes');
  require('./dashboard.component');
  require('./domain-warming-up/domain-warming-up.component');
  require('./karma-notification/karma-notification.component');
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { INTEGRATION_NAME_MAX_LENGTH } from '@http/integration/constants/integration.constants';
import { IntegrationFormData } from '@http/integration/integration.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { ImmutableObject } from '@panel/app/shared/types/immutable.type';

export type IntegrationNameControlCustomTranslations = ImmutableObject<{
  heading?: string;
  input?: {
    placeholder?: string;
    errors?: {
      required?: string;
      maxLength?: string;
    };
  };
}>;

@Component({
  selector: 'cq-integration-name-control[control]',
  templateUrl: './integration-name-control.component.html',
  styleUrls: ['./integration-name-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationNameControlComponent {
  /** Control для настройки названия интеграции */
  @Input()
  control!: GenericFormControl<IntegrationFormData['name']>;

  /** Для некоторых типов интеграций (например, Оповещения) нужны свои тексты, но общей логики/валидации это не отменяет */
  @Input()
  customTranslations?: IntegrationNameControlCustomTranslations;

  /** Флаг блокировки input'а */
  @Input()
  disabled?: boolean = false;

  /** Скрыть дефолтный заголовок для контрола */
  @Input()
  hideDefaultHeading?: boolean = false;

  /** Сделать заголовок жирным */
  @Input()
  isBoldHeading?: boolean = false;

  /** Максимальная длина названия интеграции */
  INTEGRATION_NAME_MAX_LENGTH = INTEGRATION_NAME_MAX_LENGTH;
}

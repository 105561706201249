import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaginatorParameters } from './array-stream.types';

export class ElementAdded {}
export class ElementChanged {}
export class ElementDeleted {}
export class ElementsAdded {}
export class ElementsDeleted {}

/** Класс для работы с любыми данными, которые можно представить в виде массива */
export class ArrayStream<E> {
  /** Массив применённых фильтров */
  appliedFilters: ((...args: any) => {})[] = [];

  /** Возвращает элементы, которые могут быть подвержены фильтрации и сортировке */
  elements$: Observable<E[]>;

  /** Возвращает "чистые" элементы, которые всегда остаются без фильтрации и сортировки */
  elementsRaw$: Observable<E[]>;

  /** Возвращает true, если применены фильтры */
  isAppliedFilters$: Observable<boolean>;

  /** Возвращает true, если список "чистых" элементов пустой */
  isEmpty$: Observable<boolean>;

  /** Возвращает true, если список "чистых" элементов не пустой */
  isNotEmpty$: Observable<boolean>;

  /** Возвращает true, если данные еще подгружаются */
  isLoading$: Observable<boolean>;

  /** Возвращает параметры пагинации */
  paginatorParameters$: Observable<PaginatorParameters>;

  /** Возвращает события, которые происходят с элементами */
  public events: Subject<ElementAdded | ElementChanged | ElementDeleted | ElementsAdded | ElementsDeleted> =
    new Subject();

  protected elements$$: BehaviorSubject<E[]>;
  protected elementsRaw$$: BehaviorSubject<E[]>;
  protected isFiltering$$ = new BehaviorSubject<boolean>(false);
  protected isLoading$$ = new BehaviorSubject<boolean>(false);
  protected paginatorParameters$$: BehaviorSubject<PaginatorParameters>;

  constructor(elements: E[], paginatorParameters?: PaginatorParameters) {
    this.elements$$ = new BehaviorSubject<E[]>(elements);
    this.elements$ = this.elements$$.asObservable();

    this.elementsRaw$$ = new BehaviorSubject<E[]>(elements);
    this.elementsRaw$ = this.elementsRaw$$.asObservable();

    this.paginatorParameters$$ = new BehaviorSubject<PaginatorParameters>(paginatorParameters ?? {});
    this.paginatorParameters$ = this.paginatorParameters$$.asObservable();

    this.isEmpty$ = this.elementsRaw$.pipe(map((searches: E[]) => searches.length === 0));
    this.isNotEmpty$ = this.elementsRaw$.pipe(map((searches: E[]) => searches.length !== 0));

    this.isAppliedFilters$ = this.isFiltering$$.asObservable();
    this.isLoading$ = this.isLoading$$.asObservable();
  }

  /**
   * Добавляет элемент
   *
   * @param element - Элемент
   */
  public add(element: E): void;
  /**
   * Добавляет элемент по индексу
   *
   * @param element - Элемент
   * @param index - Индекс
   */
  public add(element: E, index: number): void;
  public add(element: E, index?: number): void {
    switch (typeof index) {
      case 'number':
        return this.addElementToStreamsByIndex(element, index);
      default:
        return this.addElementToStreams(element);
    }
  }

  /**
   * Добавляет несколько элементов
   *
   * @param elements - Элементы
   * @param paginatorParameters - Параметры пагинации
   */
  public addAll(elements: E[], paginatorParameters?: PaginatorParameters): void {
    this.addElementsToStream(elements, this.elementsRaw$$);

    if (this.hasFilters()) {
      let filteredElements = this.filterElements(elements, this.appliedFilters);
      this.addElementsToStream(filteredElements, this.elements$$);
    } else {
      this.addElementsToStream(elements, this.elements$$);
    }

    if (paginatorParameters) {
      this.paginatorParameters$$.next(paginatorParameters);
    }

    this.events.next(new ElementsAdded());
  }

  /** Очищает поток элементов */
  public clear(): void {
    this.elements$$.next([]);

    this.events.next(new ElementsDeleted());
  }

  /** Возвращает true, если список элементов пустой */
  public isEmpty(): boolean {
    return this.elements$$.getValue().length === 0;
  }

  /**
   * Удаляет элемент
   *
   * @param element - Элемент
   */
  public remove(element: E): void;
  /**
   * Удаляет элемент по индексу
   *
   * @param index - Индекс
   */
  public remove(index: number): void;
  public remove(o: number | E): void {
    switch (typeof o) {
      case 'object':
        return this.removeElementFromStreams(o);
      case 'number':
        return this.removeElementFromStreamsByIndex(o);
      default:
        throw new Error('Not handled case');
    }
  }

  /** Сбрасывает фильтры */
  public resetFilters(): void {
    this.appliedFilters = [];

    let elements = this.elementsRaw$$.getValue();
    this.elements$$.next(elements);

    this.setStreamAsApplyFilters(false);
  }

  /**
   * Добавляет новый элемент в поток по индексу вместо существующего
   *
   * @param index - Индекс
   * @param element - Элемент
   */
  public set(index: number, element: E): void {
    this.setElementToStream(element, index, this.elementsRaw$$);

    if (this.hasFilters()) {
      if (this.isElementPassedFilters(element, this.appliedFilters)) {
        this.addElementToStream(element, this.elements$$);
      }
    } else {
      this.setElementToStream(element, index, this.elements$$);
    }

    this.events.next(new ElementChanged());
  }

  /**
   * Добавляет новые элементы в поток вместо существующих
   *
   * @param elements - Элементы
   * @param paginatorParameters - Параметры пагинации
   */
  public setAll(elements: E[], paginatorParameters?: PaginatorParameters): void {
    this.setElementsToStream(elements, this.elementsRaw$$);

    if (this.hasFilters()) {
      let filteredElements = this.filterElements(elements, this.appliedFilters);
      this.setElementsToStream(filteredElements, this.elements$$);
    } else {
      this.setElementsToStream(elements, this.elements$$);
    }

    if (paginatorParameters) {
      this.paginatorParameters$$.next(paginatorParameters);
    }
  }

  /**
   * Устанавливает фильтр
   *
   * @param predicate - Фильтр
   */
  public setFilter(predicate: (element: E) => boolean): void {
    this.appliedFilters.push(predicate);

    let filteredElements = this.elementsRaw$$.getValue().filter(predicate);
    this.elements$$.next(filteredElements);

    this.setStreamAsApplyFilters(true);
  }

  /**
   * Устанавливает состояние ArrayStream'а как "Применены фильтры"
   *
   * @param state - Состояние
   */
  public setStreamAsApplyFilters(state: boolean): void {
    this.isFiltering$$.next(state);
  }

  /**
   * Устанавливает состояние ArrayStream'а, как "Элементы загружаются"
   *
   * @param state - Состояние
   */
  public setStreamAsLoading(state: boolean): void {
    this.isLoading$$.next(state);
  }

  /**
   * Возвращает количество элементов в потоке
   *
   * @param stream - Поток
   */
  public size(stream: BehaviorSubject<E[]>): number {
    return stream.getValue().length;
  }

  /** Возвращает массив элементов */
  public toArray(): E[] {
    return this.elements$$.getValue();
  }

  /** Возвращает массив элементов */
  public toFieldArray<K extends keyof E>(field: K): Array<E[K]> {
    return this.elements$$.getValue().map((e) => e[field]);
  }

  /**
   * Добавляет элементы в поток
   *
   * @param elements - Элементы
   * @param stream - Поток
   */
  private addElementsToStream(elements: E[], stream: BehaviorSubject<E[]>): void {
    const value = stream.getValue();
    stream.next([...value, ...elements]);
  }

  /**
   * Добавляет элемент в поток
   *
   * @param element - Элемент
   * @param stream - Поток
   */
  private addElementToStream(element: E, stream: BehaviorSubject<E[]>): void {
    const value = stream.getValue();
    stream.next([...value, element]);
  }

  /**
   * Добавляет элемент в поток по индексу
   *
   * @param element - Элемент
   * @param index - Индекс
   * @param stream - Поток
   */
  private addElementToStreamByIndex(element: E, index: number, stream: BehaviorSubject<E[]>): void {
    let isCorrectIndex = index! < 0 || index! >= this.size(stream);
    if (!isCorrectIndex) {
      throw new Error('IndexOutOfBoundsException: Index is out of range');
    }

    const value = stream.getValue();
    value.splice(index, 0, element);
    stream.next(value);
  }

  /**
   * Добавляет элемент в потоки
   *
   * @param element - Элемент
   */
  private addElementToStreams(element: E): void {
    this.addElementToStream(element, this.elementsRaw$$);

    if (this.hasFilters()) {
      if (this.isElementPassedFilters(element, this.appliedFilters)) {
        this.addElementToStream(element, this.elements$$);
      }
    } else {
      this.addElementToStream(element, this.elements$$);
    }

    this.events.next(new ElementAdded());
  }

  /**
   * Добавляет элемент в потоки по индексу
   *
   * @param element - Элемент
   * @param index - Индекс
   */
  private addElementToStreamsByIndex(element: E, index: number): void {
    this.addElementToStreamByIndex(element, index, this.elementsRaw$$);

    if (this.hasFilters()) {
      if (this.isElementPassedFilters(element, this.appliedFilters)) {
        this.addElementToStreamByIndex(element, index, this.elements$$);
      }
    } else {
      this.addElementToStreamByIndex(element, index, this.elements$$);
    }

    this.events.next(new ElementAdded());
  }

  /**
   * Возвращает элементы удовлетворяющие применённым фильтрам
   *
   * @param elements - Элементы
   * @param predicates - Фильтры
   */
  private filterElements(elements: E[], predicates: ((...args: any) => {})[]): E[] {
    let filteredElements: E[] = [...elements];

    predicates.forEach((filter: (...args: any) => {}) => {
      filteredElements = filteredElements.filter(filter);
    });

    return filteredElements;
  }

  /** Есть ли применённые фильтры */
  private hasFilters(): boolean {
    return this.appliedFilters.length !== 0;
  }

  /**
   * Прошёл ли элемент фильтры
   *
   * @param element - Элемент
   * @param predicates - Фильтры
   */
  private isElementPassedFilters(element: E, predicates: ((...args: any) => {})[]): boolean {
    return predicates.every((filter: (...args: any) => {}) => filter(element));
  }

  /**
   * Удаляет элемент из потока
   *
   * @param element - Элемент
   * @param stream - Поток
   */
  private removeElementFromStream(element: E, stream: BehaviorSubject<E[]>): void {
    const value = stream.getValue();
    const filteredElements = value.filter((e: E) => e !== element);

    if (filteredElements.length === value.length) {
      return;
    }

    stream.next(filteredElements);
  }

  /**
   * Удаляет элемент из потока по индексу
   *
   * @param index - Индекс
   * @param stream - Поток
   */
  private removeElementFromStreamByIndex(index: number, stream: BehaviorSubject<E[]>): void {
    if (index < 0 || index >= this.size(stream)) {
      throw new Error('IndexOutOfBoundsException: Index is out of range');
    }

    const value = stream.getValue();
    const filteredElements = value.splice(index, 1);

    if (filteredElements.length === value.length) {
      return;
    }

    stream.next(filteredElements);
  }

  /**
   * Удаляет элемент из потоков
   *
   * @param element - Элемент
   */
  private removeElementFromStreams(element: E): void {
    this.removeElementFromStream(element, this.elementsRaw$$);
    this.removeElementFromStream(element, this.elements$$);

    this.events.next(new ElementDeleted());
  }

  /**
   * Удаляет элемент по индексу
   *
   * @param index - Индекс
   */
  private removeElementFromStreamsByIndex(index: number): void {
    this.removeElementFromStreamByIndex(index, this.elementsRaw$$);
    this.removeElementFromStreamByIndex(index, this.elements$$);

    this.events.next(new ElementDeleted());
  }

  /**
   * Добавляет новый элемент в поток по индексу вместо существующего
   *
   * @param element - Элемент
   * @param index - Индекс
   */
  private setElementToStream(element: E, index: number, stream: BehaviorSubject<E[]>): void {
    if (index < 0 || index >= this.size(stream)) {
      throw new Error('IndexOutOfBoundsException: Index is out of range');
    }

    let value = stream.getValue();
    value[index] = element;
    stream.next(value);
  }

  /**
   * Добавляет новые элементы в поток вместо существующих
   *
   * @param elements - Элементы
   * @param stream - Поток
   */
  private setElementsToStream(elements: E[], stream: BehaviorSubject<E[]>): void {
    stream.next(elements);
  }
}

/**
 * Контроллер настройки "Продвинутый мастер сбора данных"
 */
import {
  AUTO_EVENTS_GROUPS,
  AUTO_EVENTS_PARAMS_TYPES,
  AUTO_EVENTS_TYPES,
} from '../../../../../app/http/track-master/track-master.constants';

(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqTrackMasterProController', CqTrackMasterProController);

  function CqTrackMasterProController($scope, trackMasterModel) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.AUTO_EVENTS_GROUPS = AUTO_EVENTS_GROUPS;
      vm.AUTO_EVENTS_PARAMS_TYPES = AUTO_EVENTS_PARAMS_TYPES;
      vm.AUTO_EVENTS_TYPES = AUTO_EVENTS_TYPES;
      vm.extraPropertiesIsCollapsed = getInitialExtraPropertiesCollapsedState(); // Флаг свернуточти дополнительных свойств

      if (!vm.autoEvent.id) {
        $scope.$watch('[vm.autoEvent.type, vm.autoEvent.paramsType]', typeChanged);
      }

      function typeChanged() {
        if (vm.autoEvent.type === AUTO_EVENTS_TYPES.FORM) {
          vm.autoEvent.paramsType = AUTO_EVENTS_PARAMS_TYPES.EVENT;
        }
        if (vm.autoEvent.type && vm.autoEvent.paramsType) {
          vm.autoEvent.group = trackMasterModel.getGroup(vm.autoEvent);
        }
      }
    }

    /**
     * Получение начального состояния свернутости дополнительных свойств
     *
     * @returns {boolean}
     */
    function getInitialExtraPropertiesCollapsedState() {
      return !(vm.autoEvent.params.length || vm.autoEvent.paramsSelectors.length);
    }
  }
})();

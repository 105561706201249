<ng-container *transloco="let t; read: 'downloadDocumentsComponent'">
  <div
    ngbDropdown
    placement="bottom-end"
    container="body"
  >
    <button
      class="btn btn-sm btn-borderless-primary"
      type="button"
      ngbDropdownToggle
      [ngbTooltip]="t('title')"
      container="body"
    >
      <i class="cqi-sm cqi-download"></i>
    </button>
    <div ngbDropdownMenu>
      <button
        ngbDropdownItem
        type="button"
        (click)="invoice.downloadDetalization()"
      >
        {{ t('details') }}
      </button>

      <ng-container *ngIf="isNeedToShowDownloadInvoiceButton(invoice)">
        <button
          ngbDropdownItem
          type="button"
          (click)="invoice.downloadInvoice()"
        >
          {{ t('inv') }}
        </button>
      </ng-container>

      <ng-container *ngIf="idNeedToShowDownloadActButton(invoice)">
        <button
          ngbDropdownItem
          type="button"
          (click)="invoice.downloadAct()"
        >
          {{ t('act') }}
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>

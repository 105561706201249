/**
 * Сферы применения готовых шаблонов
 */
export enum READY_MESSAGE_TEMPLATE_SCOPES_OF_USE {
  ANY = 'any', // любая
  LANDING = 'landing', // лендинг
  OTHER = 'other', // другое
  SAAS = 'saas', // SaaS
  SHOP = 'shop', // интернет-магазин
}

/**
 * Список сфер применения готовых шаблонов
 */
export const READY_MESSAGE_TEMPLATE_SCOPES_OF_USE_LIST = [
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY,
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.LANDING,
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.SHOP,
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.SAAS,
  READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.OTHER,
];

/**
 * Директива компилирует html из строки и вставляет в элемант
 */
(function () {
  'use strict';

  angular.module('myApp.directives.htmlCompile').directive('cqHtmlCompile', cqHtmlCompile);

  function cqHtmlCompile($compile) {
    return {
      restrict: 'A',
      link: link,
    };

    function link(scope, iElement, iAttrs) {
      scope.$watch(
        function () {
          return scope.$eval(iAttrs.cqHtmlCompile);
        },
        function (value) {
          iElement.append($compile(value)(scope));
        },
      );
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { BlockHeaderComponent } from './block-header.component';

@NgModule({
  declarations: [BlockHeaderComponent],
  exports: [BlockHeaderComponent],
  imports: [CommonModule, TranslocoModule, NgbDropdownModule],
})
export class BlockHeaderModule {}

import { CouponDto } from '@http/coupon/coupon.types';

/** Класс для работы с купоном */
export class Coupon {
  /** ID */
  id: string = '';

  constructor(dto: CouponDto) {
    this.id = dto.coupon_id;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TemplateCardComponent } from '@panel/app/partials/template/template-card/template-card.component';
import { TemplateListComponent } from '@panel/app/partials/template/template-list/template-list.component';
import { TemplatePreviewComponent } from '@panel/app/partials/template/template-preview/template-preview.component';

import { TemplateZeroComponent } from './template-zero/template-zero.component';

@NgModule({
  declarations: [TemplateCardComponent, TemplateListComponent, TemplatePreviewComponent, TemplateZeroComponent],
  exports: [TemplateCardComponent, TemplateListComponent, TemplatePreviewComponent],
  imports: [CommonModule, TranslocoModule],
})
export class TemplateModule {}

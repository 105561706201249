<ng-container *transloco="let t; read: 'operationsComponent'">
  <ng-select
    [clearable]="false"
    [formControl]="control.controls.type"
    [groupBy]="groupByFn"
    [items]="operations"
    [searchable]="false"
    [trackByFn]="trackByFn"
    bindLabel="operation"
    bindValue="operation"
  >
    <ng-template
      ng-label-tmp
      ng-option-tmp
      let-item="item.operation"
    >
      {{ 'elasticsearchOperationModel.operation.' + item | transloco }}
    </ng-template>
  </ng-select>

  <div class="mt-10">
    <ng-container [ngSwitch]="true">
      <cq-input-date
        *ngSwitchCase="isDateValue(control.controls.type.value)"
        [formControl]="control.controls.dateValue"
      ></cq-input-date>

      <cq-input-list
        *ngSwitchCase="isListValue(control.controls.type.value)"
        [formControl]="control.controls.defaultValue"
      ></cq-input-list>

      <cq-input-number
        *ngSwitchCase="isNumberValue(control.controls.type.value)"
        [formControl]="control.controls.defaultValue"
      ></cq-input-number>

      <cq-input-number-range
        *ngSwitchCase="isNumberRangeValue(control.controls.type.value)"
        [formControl]="control.controls.numberRangeValue"
      ></cq-input-number-range>

      <cq-input-str
        *ngSwitchCase="isStrValue(control.controls.type.value)"
        [formControl]="control.controls.defaultValue"
      ></cq-input-str>

      <cq-input-str-contains
        *ngSwitchCase="isStrContains(control.controls.type.value)"
        [formControl]="control.controls.defaultValue"
      ></cq-input-str-contains>
    </ng-container>
  </div>
</ng-container>

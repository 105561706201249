<ng-container *transloco="let t; read: 'urlInputComponent'">
  <!--АДРЕС СТРАНИЦЫ-->
  <div
    class="form-group"
    [cqShowError]="control"
  >
    <input
      class="form-control"
      [formControl]="control"
      [placeholder]="t('placeholder')"
      name="url"
    />
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">{{ t('errors.required') }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

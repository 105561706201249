<ng-container *transloco="let t">
  <div
    class="form-group"
    [cqShowError]="actionForm.controls.body"
  >
    <label class="font-weight-normal small">{{ t('chatBot.botActionOperator.actionHeading') }}:</label>
    <ng-select
      [clearable]="false"
      [formControl]="actionForm.controls.body"
      [required]="true"
      [trackByFn]="teamMemberSelect"
      placeholder="{{ t('chatBot.botActionOperator.operatorSelect.placeholder') }}"
      [cqShowError]="actionForm.controls.body"
      [classList]="['ng-touched']"
      [searchFn]="searchOperatorFn"
      [items]="(state.teamMembers$ | async) ?? []"
      bindValue="id"
    >
      <ng-template
        ng-label-tmp
        let-teamMember="item"
      >
        <ng-container *ngIf="!actionForm.controls.body.errors?.operatorRemoved; else removedItemError">
          <span class="align-middle">
            <img
              class="avatar margin-right-5"
              [src]="teamMember.avatar"
            />
            <span>{{ teamMember.name }}</span>
          </span>
        </ng-container>
        <ng-template #removedItemError>
          <span class="align-middle">
            <i class="margin-right-5 cqi-sm cqi-triangle-exclamation-o text-danger"></i>
            <span class="text-muted">{{ t('chatBot.botActionOperator.operatorSelect.removedItem') }}</span>
          </span>
        </ng-template>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-teamMember="item"
      >
        <span class="align-middle">
          <img
            class="avatar margin-right-5"
            [src]="teamMember.avatar"
          />
          <span>{{ teamMember.name }}</span>
        </span>
      </ng-template>
    </ng-select>
    <cq-validation-messages [control]="actionForm.controls.body">
      <cq-validation-message errorName="required">{{
        t('chatBot.botActionOperator.operatorSelect.errors.required')
      }}</cq-validation-message>
      <cq-validation-message errorName="operatorRemoved">{{
        t('chatBot.botActionOperator.operatorSelect.errors.required')
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./trigger-chain-all-time-stats.controller');

  angular
    .module('myApp.triggerChains.allTimeStats')
    .component('cqTriggerChainAllTimeStats', cqTriggerChainAllTimeStats());

  function cqTriggerChainAllTimeStats() {
    return {
      bindings: {
        currentMessage: '=', // Сообщение из триггерной цепочки
      },
      controller: 'CqTriggerChainAllTimeStatsController',
      controllerAs: 'vm',
      template: require('./trigger-chain-all-time-stats.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cq-change-routing-bot-active-status-confirm',
  templateUrl: './change-routing-bot-active-status-confirm.component.html',
  styleUrls: ['./change-routing-bot-active-status-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeRoutingBotActiveStatusConfirmComponent {
  @Input()
  ajsModalInstance?: any;

  /**
   * На какой статус будет меняться бот
   */
  private _statusToChange!: 'active' | 'pause';
  @Input()
  set statusToChange(status: boolean) {
    this._statusToChange = status ? 'active' : 'pause';
  }

  constructor(public activeModal: NgbActiveModal) {}

  /**
   * Закрывает модалку и отдает в callback созданную интеграцию
   * @param integration
   */
  closeModal() {
    this.ajsModalInstance ? this.ajsModalInstance.close(true) : this.activeModal.close(true);
  }

  /**
   * Закрывает модалку новую/старую в зависимости от того, откуда открыта
   */
  dismissModal() {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.activeModal.dismiss();
  }

  /**
   * Путь до перевода заголовка модалки
   */
  get headingTranslatePath(): string {
    return `changeRoutingBotActiveStatusConfirmComponent.${this._statusToChange}.heading`;
  }

  /**
   * Путь до перевода тела модалки
   */
  get bodyTranslatePath(): string {
    return `changeRoutingBotActiveStatusConfirmComponent.${this._statusToChange}.body`;
  }

  /**
   * Путь до перевода подтверждающей кнопки
   */
  get confirmButtonTranslatePath(): string {
    return `changeRoutingBotActiveStatusConfirmComponent.${this._statusToChange}.confirmButton`;
  }
}

<ng-container *transloco="let t; read: 'userPropsComponent'">
  <div class="user-props no-margin-bottom">
    <div
      (click)="collapse.toggle(); collapseStateChange.emit(userPropsLSName)"
      [attr.aria-expanded]="!isCollapsed"
      data-test="user-props-collapse-div"
      class="flex align-items-center cursor-pointer"
      aria-controls="collapse"
    >
      <div class="font-weight-bold flex-grow-1 padding-15">
        <span>{{ t('userProps') }}</span>
        <i
          class="cqi-sm cqi-question-circle margin-left-5"
          [ngbTooltip]="t('tooltip')"
        ></i>
      </div>
      <i
        [class]="{ 'cqi-chevron-down': isCollapsed, 'cqi-chevron-up': !isCollapsed }"
        class="btn-icon cqi-sm margin-right-15"
      ></i>
    </div>
    <div
      #collapse="ngbCollapse"
      [ngbCollapse]="isCollapsed"
      (ngbCollapseChange)="changeCollapsed()"
      class="no-padding card-body"
      data-test="user-props-card-body"
    >
      <div class="user-prop">
        <div class="padding-bottom-5 padding-left-15">
          <small class="font-weight-bold">{{ t('properties.customProps.title') }}</small>
        </div>

        <cq-user-customs-props
          [currentApp]="currentApp"
          [customsProps]="userSystemsPropsAsCustom"
          [user]="user"
          [pinnedProps]="pinnedProps"
          [pinningPossibility]="pinningPossibility"
          (openEditUserPropertyModal)="openEditUserPropertyModal($event)"
          (removeUserProperty)="removeUserProperty($event)"
          (pinProp)="addPinnedProp.emit($event)"
        >
        </cq-user-customs-props>
        <ng-container *ngIf="accessToUsersProps.hasAccess">
          <ng-container *ngFor="let prop of convertedCustomProps; trackBy: trackByFn">
            <cq-user-customs-props
              [currentApp]="currentApp"
              [customsProps]="[prop]"
              [user]="user"
              [pinnedProps]="pinnedProps"
              [pinningPossibility]="pinningPossibility"
              (openEditUserPropertyModal)="openEditUserPropertyModal($event)"
              (removeUserProperty)="removeUserProperty($event)"
              (pinProp)="addPinnedProp.emit($event)"
            ></cq-user-customs-props>
          </ng-container>
        </ng-container>
        <div class="padding-left-15 padding-right-15 padding-top-5 margin-bottom-15">
          <button
            *ngIf="accessToUsersProps.hasAccess"
            class="btn btn-secondary full-width"
            type="button"
            (click)="openAddUserPropertyModal()"
          >
            <i class="btn-icon-left cqi-sm cqi-plus"></i>
            <span>{{ t('properties.customProps.addPropButton') }}</span>
          </button>
          <button
            *ngIf="!accessToUsersProps.hasAccess"
            class="btn btn-secondary full-width"
            type="button"
            (click)="showPaywall(currentApp, accessToUsersProps.denialReason)"
          >
            <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
            <span>{{ t('properties.customProps.addPropButton') }}</span>
          </button>
        </div>
      </div>

      <div
        *ngIf="isUserHasCrmProps()"
        class="user-prop"
      >
        <small class="font-weight-bold padding-left-15">{{ t('properties.systemProps.crm.title') }}</small>

        <cq-user-systems-props
          [systemProps]="userSystemsCrmProps"
          [user]="user"
          [pinnedProps]="pinnedProps"
          [pinningPossibility]="pinningPossibility"
          (pinProp)="addPinnedProp.emit($event)"
        >
        </cq-user-systems-props>
      </div>

      <div
        *ngIf="isUserHasEcomProps()"
        class="user-prop"
      >
        <small class="font-weight-bold padding-left-15">{{ t('properties.systemProps.ecommerce.title') }}</small>

        <cq-user-systems-props
          [systemProps]="userSystemsEcomProps"
          [user]="user"
          [pinnedProps]="pinnedProps"
          [pinningPossibility]="pinningPossibility"
          (pinProp)="addPinnedProp.emit($event)"
        >
        </cq-user-systems-props>
      </div>

      <div class="user-prop">
        <small class="font-weight-bold padding-left-15">{{ t('properties.systemProps.title') }}</small>

        <cq-user-systems-props
          [systemProps]="requirementsSystemProps"
          [user]="user"
          [pinnedProps]="pinnedProps"
          [pinningPossibility]="pinningPossibility"
          (pinProp)="addPinnedProp.emit($event)"
        >
        </cq-user-systems-props>

        <cq-user-systems-props
          [systemProps]="['$google_client_ids']"
          [user]="user"
          [pinnedProps]="pinnedProps"
          [pinningPossibility]="pinningPossibility"
          (pinProp)="addPinnedProp.emit($event)"
        >
        </cq-user-systems-props>

        <div *ngIf="isUserHasInitialUtmProps()">
          <cq-user-systems-props
            [systemProps]="initialUtmProps"
            [user]="user"
            [pinnedProps]="pinnedProps"
            [pinningPossibility]="pinningPossibility"
            (pinProp)="addPinnedProp.emit($event)"
          >
          </cq-user-systems-props>
        </div>

        <div *ngIf="isUserHasLastUtmProps()">
          <cq-user-systems-props
            [systemProps]="lastUtmProps"
            [user]="user"
            [pinnedProps]="pinnedProps"
            [pinningPossibility]="pinningPossibility"
            (pinProp)="addPinnedProp.emit($event)"
          >
          </cq-user-systems-props>
        </div>

        <div *ngIf="isUserHasRoistatProps()">
          <cq-user-systems-props
            [systemProps]="roistatProps"
            [user]="user"
            [pinnedProps]="pinnedProps"
            [pinningPossibility]="pinningPossibility"
            (pinProp)="addPinnedProp.emit($event)"
          >
          </cq-user-systems-props>
        </div>

        <cq-user-systems-props
          [systemProps]="userSocials"
          [user]="user"
          [pinnedProps]="pinnedProps"
          [pinningPossibility]="pinningPossibility"
          (pinProp)="addPinnedProp.emit($event)"
        ></cq-user-systems-props>

        <cq-user-systems-props
          class="margin-bottom-10"
          [systemProps]="['$carrotId']"
          [user]="user"
          [pinnedProps]="pinnedProps"
          [pinningPossibility]="pinningPossibility"
          (pinProp)="addPinnedProp.emit($event)"
        >
        </cq-user-systems-props>
      </div>
    </div>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { DatepickerPageModule } from '@panel/app/pages/visual-components/datepicker/datepicker-page.module';
import { DaterangepickerPageModule } from '@panel/app/pages/visual-components/daterangepicker/daterangepicker-page.module';
import { GetStartedPageModule } from '@panel/app/pages/visual-components/get-started/get-started-page.module';
import { TimepickerPageModule } from '@panel/app/pages/visual-components/timepicker/timepicker-page.module';
import { VISUAL_COMPONENT_PAGE_ROUTES } from '@panel/app/pages/visual-components/visual-components-page.routes';

import { VisualComponentsPageComponent } from './visual-components-page.component';

@NgModule({
  declarations: [VisualComponentsPageComponent],
  imports: [
    CommonModule,
    DatepickerPageModule,
    DaterangepickerPageModule,
    GetStartedPageModule,
    TimepickerPageModule,
    UIRouterUpgradeModule.forChild({ states: VISUAL_COMPONENT_PAGE_ROUTES }),
  ],
})
export class VisualComponentsPageModule {}

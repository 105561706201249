import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';

import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import { TelegramComponent } from '@panel/app/pages/integrations/content/telegram/telegram.component';
import { App } from '@http/app/app.model';
import { TelegramBot } from '@http/chat-bot/types/chat-bot-internal.types';

@Component({
  selector: 'cq-telegram-wrapper[currentApp][integrationExternal]',
  templateUrl: './telegram-wrapper.component.html',
  styleUrls: ['./telegram-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramWrapperComponent {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Текущая или новая интеграция во внешнем формате */
  @Input()
  integrationExternal!: TelegramIntegrationExternal;

  /** Список всех Телеграм-ботов */
  @Input()
  telegramBotAllList: TelegramBot[] = [];

  /** Дочерний элемент настройки интеграции */
  @ViewChild(TelegramComponent)
  telegramComponent!: TelegramComponent;
}

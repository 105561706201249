<ngb-toast
  (click)="toast.closable && toastService.hide(toast)"
  (hidden)="toastService.remove(toast)"
  *ngFor="let toast of toastService.toasts$ | async; index as index"
  [autohide]="toast.autohide"
  [class]="toast.classname"
  [delay]="toast.delay"
>
  <ngb-alert
    class="text-center padding-top-10 padding-right-50 padding-bottom-10 padding-left-50 margin-bottom-15"
    [dismissible]="false"
    [ngClass]="{ 'cursor-pointer': toast.closable }"
    [type]="toast.type"
    (click)="toast.onTap && toast.onTap()"
  >
    <div class="font-weight-bold">{{ toast.heading }}</div>
    <div [innerHTML]="toast.text"></div>
    <div
      class="position-absolute top-0 right-0 padding-right-10 h-100 d-flex align-items-center"
      *ngIf="toast.closeButton"
    >
      <i
        class="cqi-sm cqi-times cursor-pointer"
        (click)="toastService.hide(toast)"
      ></i>
    </div>
  </ngb-alert>
</ngb-toast>

import { ChangeDetectionStrategy, Component, Input, NgZone, Optional, Self } from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { filter, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';
import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormGroupV2 } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { TIME_UNIT_MEASURES, TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

export type TimeSelectorConfig = {
  time: number;
  unit: TIME_UNITS;
};

/**
 * Минимальное значение времени
 */
const MIN_TIME_VALUE = 1;

@Component({
  selector: 'cq-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class TimeSelectorComponent extends AbstractCVAControl<TimeSelectorConfig> {
  readonly control: GenericFormGroupV2<TimeSelectorConfig>;

  /**
   * Доступные для выбора единицы времени
   */
  private _selectableUnits: TIME_UNITS[] = [TIME_UNITS.SECOND, TIME_UNITS.MINUTE, TIME_UNITS.HOUR, TIME_UNITS.DAY];

  get selectableUnits() {
    return this._selectableUnits;
  }

  @Input()
  set selectableUnits(units: TIME_UNITS[]) {
    this._selectableUnits = units;
    this.setMinErrorMessage();
  }

  private _minSeconds: number = MIN_TIME_VALUE;

  get minSeconds() {
    return this._minSeconds;
  }

  @Input()
  set minSeconds(minSeconds: number) {
    this._minSeconds = minSeconds;
    this.setMinErrorMessage();
  }

  private _maxSeconds: number = Number.MAX_SAFE_INTEGER;

  get maxSeconds() {
    return this._maxSeconds;
  }

  @Input()
  set maxSeconds(maxSeconds: number) {
    this._maxSeconds = maxSeconds;
    this.maxSecondsError = this.transloco.translate('timeSelectorComponent.timeInput.errors.max', {
      maxValue: this.secondsToUnitTime(maxSeconds, TIME_UNITS.DAY),
    });
  }

  @Input()
  maxSecondsError: string = this.transloco.translate('timeSelectorComponent.timeInput.errors.max', {
    maxValue: this.secondsToUnitTime(this.maxSeconds, TIME_UNITS.DAY),
  });

  @Input()
  set required(value: boolean) {
    if (!value) {
      this.control.controls.time.removeValidators(Validators.required);
    } else {
      this.control.controls.time.addValidators(Validators.required);
    }
  }

  minSecondsError: string = this.transloco.translate('timeSelectorComponent.timeInput.errors.min', {
    pluralUnits: this.transloco.translate(`services.timeUnit.unitsWithValue.${TIME_UNITS.SECOND}.full`, {
      count: this.minSeconds,
    }),
  });

  constructor(
    private readonly destroy$: DestroyService,
    private readonly transloco: TranslocoService,
    private readonly fb: GenericFormBuilder,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
    // Объявляем тут, потому что форма должна быть создана до добавления кастомных валидаторов
    this.control = this.fb.group<TimeSelectorConfig>({
      time: this.fb.control(0, Validators.required),
      unit: this.fb.control(TIME_UNITS.SECOND, Validators.required),
    });
    this.control.controls.time.addValidators([this.minTimeValidator, this.maxTimeValidator]);

    // TODO VALIDATION_MESSAGES_REFACTOR
    // Костыль, что перевалидировать поле ввода после изменения единицы измерения. После рефакторинга из туду выше можно удалять
    this.control.controls.unit.valueChanges.pipe(takeUntil(destroy$)).subscribe(() => {
      this.control.controls.time.updateValueAndValidity();
    });
  }

  /**
   * Получить перевод еденицы времени исходя из времени
   *
   * @param unit - Единица времени
   */
  getPluralTimeUnitTranslate(unit: TIME_UNITS): string {
    return this.transloco.translate(`services.timeUnit.units.${unit}.fullAlt`, {
      count: this.control.controls.time.value,
    });
  }

  writeValue(val: TimeSelectorConfig) {
    super.writeValue({
      ...val,
      time: this.secondsToUnitTime(val.time, val.unit),
    });
  }

  protected syncInternalChanges() {
    this.control.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(({ time }) => time !== ''),
      )
      .subscribe((value: TimeSelectorConfig) => {
        this.onChange({
          ...value,
          time: this.unitTimeToSeconds(value.time, value.unit),
        });
      });
  }

  /**
   * Инициализация времени из секунд и выбраных единиц
   */
  secondsToUnitTime(timeSec: number, unit: TIME_UNITS) {
    return Math.floor(timeSec / TIME_UNIT_MEASURES[unit]);
  }

  unitTimeToSeconds(unitTime: number, unit: TIME_UNITS) {
    return Number(unitTime) * TIME_UNIT_MEASURES[unit];
  }

  private get minTimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.unitTimeToSeconds(control.value, this.control.controls.unit.value) >= this.minSeconds
        ? null
        : { min: control.value };
    };
  }

  private get maxTimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.unitTimeToSeconds(control.value, this.control.controls.unit.value) > this.maxSeconds
        ? { max: control.value }
        : null;
    };
  }

  setMinErrorMessage() {
    const pluralUnits = this.transloco.translate(`services.timeUnit.unitsWithValue.${this.minAvailableUnit}.full`, {
      count: this.secondsToUnitTime(this.minSeconds, this.minAvailableUnit),
    });
    this.minSecondsError = this.transloco.translate('timeSelectorComponent.timeInput.errors.min', {
      pluralUnits: pluralUnits,
    });
  }

  /**
   * Получение минимальной единицы времени из доступных
   */
  get minAvailableUnit(): TIME_UNITS {
    if (this.selectableUnits.includes(TIME_UNITS.SECOND)) {
      return TIME_UNITS.SECOND;
    } else if (this.selectableUnits.includes(TIME_UNITS.MINUTE)) {
      return TIME_UNITS.MINUTE;
    } else if (this.selectableUnits.includes(TIME_UNITS.HOUR)) {
      return TIME_UNITS.HOUR;
    } else if (this.selectableUnits.includes(TIME_UNITS.DAY)) {
      return TIME_UNITS.DAY;
    } else {
      return TIME_UNITS.MONTH;
    }
  }
}

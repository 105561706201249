import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { StatsCollectorComponent } from './stats-collector.component';

@NgModule({
  declarations: [StatsCollectorComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [StatsCollectorComponent],
})
export class StatsCollectorModule {}

import { AfterViewInit, Directive, ElementRef, Input, NgZone } from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';

//fork JS code from https://github.com/github/textarea-autosize
@Directive({
  selector: '[cqAutosize]',
  providers: [DestroyService],
})
export class AutosizeTextareaDirective implements AfterViewInit {
  @Input() maxRows: number = 7;
  @Input() viewportMarginBottom = 10;

  previousValue: string | null = null;
  isUserResized = false;
  x: number = 0;
  y: number = 0;
  height: string = '';

  constructor(private elRef: ElementRef, private ngZone: NgZone, private destroy$: DestroyService) {}

  get elIsTextarea(): boolean {
    return (this.elRef.nativeElement as HTMLElement).tagName === 'textarea';
  }

  get textArea(): HTMLTextAreaElement | null {
    if (this.elIsTextarea) {
      console.error(`Host element for directive - '${this.elRef.nativeElement.tagNamel}', but expect 'textarea'`);
      return null;
    }
    return this.elRef.nativeElement;
  }

  ngAfterViewInit(): void {
    if (!this.textArea) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      fromEvent<MouseEvent>(this.textArea!, 'mousemove')
        .pipe(takeUntil(this.destroy$))
        .subscribe((e) => this.onUserResize(e));
      fromEvent(this.textArea!, 'input')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.sizeToFit());
      fromEvent(this.textArea!, 'change')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.sizeToFit());
      if (this.textArea!.form) {
        fromEvent(this.textArea!, 'reset')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.onFormReset());
      }
    });

    if (this.textArea.value) {
      this.sizeToFit();
    }
  }

  onUserResize(event: MouseEvent) {
    if (!this.elIsTextarea) {
      return;
    }
    if (this.x !== event.clientX || this.y !== event.clientY) {
      const newHeight = this.textArea!.style.height;
      if (this.height && this.height !== newHeight) {
        this.isUserResized = true;
        this.textArea!.style.maxHeight = '';
        this.textArea!.removeEventListener('mousemove', this.onUserResize);
      }
      this.height = newHeight;
    }

    this.x = event.clientX;
    this.y = event.clientY;
  }

  overflowOffset(): { top: number; bottom: number } {
    const document = this.textArea!.ownerDocument;
    const documentElement = document.documentElement;

    let offsetTop = 0;
    let el = this.textArea;

    while (el !== document.body && el !== null) {
      offsetTop += el.offsetTop || 0;
      el = el.offsetParent as any;
    }

    const top = offsetTop - document.defaultView!.pageYOffset;
    const bottom = documentElement.clientHeight - (top + this.textArea!.offsetHeight);
    return {
      top,
      bottom,
    };
  }

  rowCount(): number {
    const textareaStyle = getComputedStyle(this.textArea!);
    const tHeight = Number.parseFloat(textareaStyle.height);
    const { bottom } = this.overflowOffset();
    const pTop = Number.parseFloat(textareaStyle.paddingTop);
    const pBottom = Number.parseFloat(textareaStyle.paddingBottom);
    const lineHeight = Number.parseFloat(textareaStyle.lineHeight);

    return Math.round((tHeight + bottom - (pTop + pBottom)) / lineHeight);
  }

  sizeToFit() {
    if (
      this.isUserResized ||
      /*this.textArea.value === this.previousValue || */ (this.textArea!.offsetWidth <= 0 &&
        this.textArea!.offsetHeight <= 0)
    ) {
      return;
    }

    let { top, bottom } = this.overflowOffset();
    if (top < 0 || bottom < 0) {
      top = Math.abs(top);
      bottom = Math.abs(bottom);
    }

    const textareaStyle = getComputedStyle(this.textArea!);

    const topBorderWidth = Number(textareaStyle.borderTopWidth.replace(/px/, ''));
    const bottomBorderWidth = Number(textareaStyle.borderBottomWidth.replace(/px/, ''));

    const isBorderBox = textareaStyle.boxSizing === 'border-box';
    const borderAddOn = isBorderBox ? topBorderWidth + bottomBorderWidth : 0;

    const maxHeight = Number(textareaStyle.height.replace(/px/, '')) + bottom;
    const adjustedViewportMarginBottom = bottom < this.viewportMarginBottom ? bottom : this.viewportMarginBottom;

    this.textArea!.style.maxHeight =
      this.rowCount() <= this.maxRows ? `${maxHeight - adjustedViewportMarginBottom}px` : '';

    const container = this.textArea!.parentElement;
    if (container instanceof HTMLElement) {
      const containerHeight = container.style.height;
      container.style.height = getComputedStyle(container).height;
      this.textArea!.style.height = 'auto';
      this.textArea!.style.height = `${this.textArea!.scrollHeight + borderAddOn}px`;
      container.style.height = containerHeight;
      this.height = this.textArea!.style.height;
    }

    this.previousValue = this.textArea!.value;
  }

  onFormReset() {
    this.isUserResized = false;
    this.textArea!.style.height = '';
    this.textArea!.style.maxHeight = '';
  }
}

import { translate } from '@jsverse/transloco';
import { Assets, Graphics, Text, TextStyle, Texture } from 'pixi.js';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText, spliceText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

import { ActionStyle, BaseActionABS, MAX_ACTION_WIDTH, SimpleActionABS } from './abstract';

export class BotFileAction extends SimpleActionABS {
  private imageLoaded$: Subject<string> = new Subject();

  protected style!: ActionStyle;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);
    this.subscribeToImageLoad();
  }

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 10,
        horizontal: 10,
      },
      border: {
        size: 1,
        color: 0xc7cad1,
        radius: 10,
      },
      background: {
        color: BOT_WHITE_COLOR,
      },
    };
  }

  private getFileType(mimeType: string): string {
    mimeType = mimeType.split('/').pop()!.toUpperCase();
    const name = this.form.controls.attachments.value[0].name!;
    switch (mimeType) {
      case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET':
        return (mimeType = 'XLSX');
      case 'MSWORD':
        return (mimeType = 'DOC');
      case 'PLAIN':
        return (mimeType = 'TXT');
      case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT':
        return (mimeType = 'DOCX');
      case 'VND.MS-EXCEL':
        if (name.split('.').pop() === 'csv') {
          return (mimeType = 'CSV');
        }
        return (mimeType = 'XLS');
    }
    return mimeType;
  }

  /**
   * Отрисовка действия типа FILE
   * @private
   */
  render(): Graphics | null {
    const attachment = this.form.controls.attachments.value[0];
    if (!attachment) {
      return null;
    }
    const name = attachment.name || attachment.filename;
    if (this.fileHelperService.isAcceptedImageExtension(name)) {
      return this.renderImageAction();
    } else {
      return this.renderFileAction();
    }
  }

  /**
   * Отрисовка иозображения для действия типа FILE
   * @private
   */
  private renderImageAction(): Graphics {
    const element = this.element ?? new Graphics();
    const attachment = this.form.controls.attachments.value[0];
    // @ts-ignore
    const url = attachment.url ?? URL.createObjectURL(attachment);
    this.renderImage(element, url);
    element.zIndex = 1;
    return element;
  }

  private renderImage(element: Graphics, url: string) {
    const renderTexture = (texture: Texture): void => {
      if (texture.baseTexture.width > MAX_ACTION_WIDTH) {
        const aspectRatio = texture.baseTexture.height / texture.baseTexture.width;
        texture.baseTexture.setSize(MAX_ACTION_WIDTH, MAX_ACTION_WIDTH * aspectRatio);
      }
      element.beginTextureFill({ texture });
      element.drawRoundedRect(0, 0, texture.baseTexture.width, texture.baseTexture.height, this.style.border.radius);
      element.endFill();
    };
    Assets.load(url).then((texture) => {
      renderTexture(texture);
      this.imageLoaded$.next(url);
    });
  }

  /**
   * Отрисовка файла для действия типа FILE
   * @private
   */
  private renderFileAction(): Graphics {
    const element = this.element ?? new Graphics();
    const attachment = this.form.controls.attachments.value[0];
    const fileType = this.getFileType(attachment.type!);
    const padding = this.style.padding.horizontal * 4;
    const textOptions = {
      wordWrapWidth: MAX_ACTION_WIDTH - padding,
    };
    const sizeTextOptions = {
      wordWrapWidth: MAX_ACTION_WIDTH - padding,
      fill: '#9da3af',
    };

    const getRenderedIcon = (): Graphics => {
      const iconStyle = new TextStyle({
        align: 'center',
        fill: 0x5c5cd6,
        fontFamily: 'CQ-Icons-chat',
        fontSize: 15,
      });
      const arrow = new Text('\ue901', iconStyle);
      const icon = new Graphics();
      const circleRadius = 35 / 2;

      icon.lineStyle(0);
      icon.beginFill(0xebebfa);
      icon.drawCircle(circleRadius, circleRadius, circleRadius);
      icon.endFill();
      icon.position.set(this.style.padding.horizontal, this.style.padding.vertical);

      const xArrowPosition = (icon.width - arrow.width) / 2;
      const yArrowPosition = (icon.height - arrow.height) / 2;
      arrow.position.set(xArrowPosition, yArrowPosition);
      icon.addChild(arrow);

      return icon;
    };

    const name = renderCanvasText(spliceText(attachment.filename! || attachment.name!, 26), textOptions);
    const size = renderCanvasText(this.transformationByte(attachment.size!) + ' · ' + fileType, sizeTextOptions);
    const iconCircle = getRenderedIcon();

    element.lineStyle(this.style.border.size, this.style.border.color);
    element.beginFill(this.style.background.color);
    element.drawRoundedRect(
      0,
      0,
      MAX_ACTION_WIDTH,
      iconCircle.height + this.style.padding.vertical * 2,
      this.style.border.radius,
    );
    element.endFill();
    element.zIndex = 1;
    element.addChild(name);
    element.addChild(size);
    element.addChild(iconCircle);

    const xTextPosition = this.style.padding.horizontal + 5 + iconCircle.width; // 5 - отступ от иконки

    name.position.set(xTextPosition, this.style.padding.vertical);
    size.position.set(xTextPosition, this.style.padding.vertical + name.height + 5); // 5 - отступ от имени
    return element;
  }

  private transformationByte(byte: number) {
    const mb = (byte / 1024 ** 2).toFixed(2);
    const kb = (byte / 1024).toFixed(2);
    if (mb > '0.00') {
      return mb + ' ' + translate('classes.action.canvasText.megabit');
    }
    if (kb > '0.00') {
      return kb + ' ' + translate('classes.action.canvasText.kilobyte');
    } else {
      return byte + ' ' + translate('classes.action.canvasText.byte');
    }
  }

  /**
   * Подписка на загрузку изображения
   * NOTE Загрузка изображений использовала хак ниже для пересчета высоты ветки, но из-за этого действия циклично перерисовывались. Подписка ниже позволяет перерисовывать действие только если изображение изменилось
   * @private
   */
  private subscribeToImageLoad() {
    this.imageLoaded$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.currentBranch.redraw();
      // Это не очень очевидный, но все таки хак. Позволяет триггернуть перерисовку, не меняя при этом никаких значений
      // this.form.controls.nextBranchLinkId.setValue(null, {emitEvent: true});
    });
  }
}

(function () {
  'use strict';

  angular
    .module('myApp.modals.writeToIntegrationsWrapper')
    .component('cqWriteToIntegrationsWrapper', cqWriteToIntegrationsWrapper());

  function cqWriteToIntegrationsWrapper() {
    return {
      bindings: {
        modalInstance: '=',
      },
      controller: angular.noop,
      controllerAs: 'vm',
      template: require('./write-to-integrations-wrapper.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';

import { SharedModule } from '../../../shared/shared.module';
import { LeaveSiteAttemptSettingComponent } from './leave-site-attempt-setting.component';

@NgModule({
  declarations: [LeaveSiteAttemptSettingComponent],
  imports: [AccessDenialPopoverModule, CommonModule, SharedModule, FormsModule, TranslocoModule],
  exports: [LeaveSiteAttemptSettingComponent],
  providers: [],
})
export class LeaveSiteAttemptSettingModule {}

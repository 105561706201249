/**
 * Сервис для работы с А/Б тестированием через куки пользователя
 */
(function () {
  'use strict';

  angular.module('myApp.services.abTest').factory('abTestService', abTestService);

  function abTestService(ipCookie, AB_TEST_COOKIE_PREFIX) {
    return {
      createTest: createTest,
    };

    /**
     * Создание А/Б теста
     *
     * @param {String} testName Название А/Б теста
     * @param {String} startingDate Дата начала А/Б теста
     * @returns {Boolean} Если функция вернула false - это группа А, иначе - группа B
     */
    function createTest(testName, startingDate) {
      var cookieName = generateCookieName(testName, startingDate);
      return generateABTest(cookieName);
    }

    /**
     * Генерирование значения для А/Б
     *
     * @param {String} cookieName Название cookie, которое используется для тестирования
     * @returns {Boolean} Если функция вернула false - это группа А, иначе - группа B
     */
    function generateABTest(cookieName) {
      var currentCookieValue = ipCookie(cookieName);
      if (typeof currentCookieValue == 'undefined') {
        currentCookieValue = Math.random();
        ipCookie(cookieName, currentCookieValue, { expires: 365 });
      }
      return currentCookieValue > 0.5;
    }

    /**
     * Генерация названия cookie для А/Б теста по имени testName и дате начала тестирования startingDate
     *
     * @param {String} testName Название А/Б теста
     * @param {String} startingDate Дата начала А/Б теста
     */
    function generateCookieName(testName, startingDate) {
      return AB_TEST_COOKIE_PREFIX + testName + '_' + startingDate;
    }
  }
})();

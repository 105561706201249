import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { SUBSCRIPTION_STATUSES } from '../../../../app/services/billing-info/billing-info.constants';
import { PAID_PLAN_VERSION } from '../../../../app/services/billing-plan/billing-plan.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqPoweredByInscriptionController', CqPoweredByInscriptionController);

  function CqPoweredByInscriptionController(
    PROJECT_NAME,
    billingInfoModel,
    billingPlanService,
    carrotquestHelper,
    featureModel,
    paywallService,
    planFeatureAccessService,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToNoBranding = planFeatureAccessService.getAccess(PLAN_FEATURE.NO_BRANDING, vm.currentApp);

      vm.billingInfoModel = billingInfoModel;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.isCheckboxDisabled = isCheckboxDisabled;
      vm.isSubscriptionStatusIsActive = vm.subscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVE;
      vm.noBrandingAddOnId = billingPlanService.getNoBrandingBillingAddOnId(vm.currentApp);
      vm.openNoBrandingPaywall = openNoBrandingPaywall;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.trackClickShowPoweredBy = trackClickShowPoweredBy;
    }

    /**
     * Заблокирован чекбокс или нет
     *
     * @returns {boolean}
     */
    function isCheckboxDisabled() {
      if (featureModel.hasAccess(FEATURES.OLD_ADDONS_LIST)) {
        // Для приложений со старым набором аддонов нужно смотреть на статус подписки: если подписка активна - галочку отключить можно
        return vm.subscriptionStatus !== SUBSCRIPTION_STATUSES.ACTIVE;
      } else if (!featureModel.hasAccess(FEATURES.OLD_ADDONS_LIST)) {
        // Для приложений с новым набором аддонов (у которых есть No Branding) нужно смотреть на наличие No Branding и наличие подписки. Если чего-то не хватает - галочку снять нельзя
        return !(vm.accessToNoBranding.hasAccess && vm.isSubscriptionStatusIsActive);
      }
    }

    /**
     * Открытие пейвола No Branding
     */
    function openNoBrandingPaywall() {
      switch (vm.accessToNoBranding.denialReason.paidPlanVersion) {
        case PAID_PLAN_VERSION.V1:
        case PAID_PLAN_VERSION.V2:
          trackOpenNoBrandingPaywall();
      }

      paywallService.showPaywall(vm.currentApp, vm.accessToNoBranding.denialReason);
    }

    /**
     * Трек клика на "Показывать надпись Мы используем Carrot quest"
     */
    function trackClickShowPoweredBy() {
      carrotquestHelper.track('Настройки чата - кликнул на "Показывать надпись Работает на Carrot quest"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек открытия пейвола No Branding
     */
    function trackOpenNoBrandingPaywall() {
      carrotquestHelper.track('Показ пейвола Без брендинга', { 'Раздел настроек': 'Настройки чата/батарейка' });
    }
  }
})();

(function () {
  'use strict';

  angular.module('myApp.filters.toKebabCase').filter('cqToKebabCase', cqToKebabCase);

  function cqToKebabCase(caseStyleHelper) {
    return toKebabCaseFilter;

    /**
     * Преобразование строки в kebab case
     *
     * @param {String} value Строка для преобразования
     * @returns {String}
     */
    function toKebabCaseFilter(value) {
      return caseStyleHelper.toKebabCase(value);
    }
  }
})();

<ng-container *transloco="let t">
  <div class="padding-40 preview">
    <div class="margin-bottom-5 text-muted">{{ projectName }} <{{ notificationsEmail }}></div>
    <div
      class="font-weight-bold"
      *ngIf="subject"
    >
      {{ subject }}
    </div>
    <hr />
    <div
      class="margin-bottom-30"
      *ngIf="comment"
    >
      {{ comment }}
    </div>
    <h4 class="margin-bottom-20">{{ t('emailNotificationEmailPreview.userInformation.heading') }}</h4>
    <div class="margin-bottom-20 margin-between-rows-10">
      <div
        class="row"
        *ngFor="let prop of selectedUserProps"
      >
        <div class="col-4">
          <strong>{{ getTranslatedNameUserProperty(prop) }}</strong>
        </div>
        <div class="col-8">
          <span class="text-secondary">{{ getTranslatedValueUserProperty(prop) }}</span>
        </div>
      </div>
    </div>
    <a
      class="margin-bottom-30 inline-block"
      href="#"
      >{{ t('emailNotificationEmailPreview.openUserCard', { projectName: projectName }) }}</a
    >
    <div
      class="margin-bottom-30"
      [innerHtml]="
        t('emailNotificationEmailPreview.subscribe', {
          appName: appName,
          projectName: projectName,
          selectedEvent: getFirstSelectedEventTypePrettyName()
        })
      "
    ></div>
    <div [innerHtml]="t('emailNotificationEmailPreview.unsubscribe')"></div>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'stripePayModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ t('title', { planName: resolve.planName }) }}
    </h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body stripe-payments">
    <div class="margin-bottom-20">
      <h2
        *ngIf="resolve.payPeriod !== 12"
        class="no-margin-bottom text-primary"
      >
        {{ t('perMonth', { amount: resolve.getPriceWithSale(resolve.amount, resolve.payPeriod) | toFormattedMoney }) }}
      </h2>
      <h2
        *ngIf="resolve.payPeriod === 12"
        class="no-margin-bottom text-primary"
      >
        {{ t('perYear', { amount: resolve.getPriceWithSale(resolve.amount, resolve.payPeriod) | toFormattedMoney }) }}
      </h2>
      <div
        *ngIf="resolve.payPeriod === 12"
        class="margin-top-5"
      >
        <span class="small">
          {{ t('perYearDescription', { month: resolve.payPeriod }) }}
        </span>
        <span class="small">
          {{ t('at') }}
        </span>
        <span class="margin-right-5 small">
          {{ t('monthlyPayment', { amount: resolve.getPriceWithSale(resolve.amount) | toFormattedMoney }) }}
        </span>
        <div class="label label-light-secondary">
          {{ t('saleLabel', { sale: resolve.chosenDiscount * 100 | number }) }}
        </div>
        <cq-first-payment-discount-label
          *ngIf="hasFirstPaymentDiscount()"
          class="ml-5 d-inline"
        ></cq-first-payment-discount-label>
      </div>
    </div>
    <div
      *ngIf="!isStipeElementsReady"
      class="stripe-not-ready text-center"
    >
      <i class="cqi-sm cqi-3x cqi-spinner inline-block spin text-success"></i>
    </div>
    <div [hidden]="!isStipeElementsReady">
      <form
        [formGroup]="payByStripeForm"
        (ngSubmit)="payByStripe(payByStripeForm.valid)"
        autocomplete="off"
        novalidate
      >
        <div
          class="form-group no-margin-bottom"
          [cqShowError]="payByStripeForm"
        >
          <div class="form-control flex align-items-center padding-5">
            <div
              class="card-element full-width"
              [id]="STRIPE_CARD_ID"
            ></div>
          </div>
          <input
            class="hidden"
            formControlName="card"
            type="text"
          />
          <cq-validation-messages [control]="payByStripeForm.controls.card">
            <cq-validation-message errorName="cardError">
              {{ cardValidation.errorMessage }}
            </cq-validation-message>
            <cq-validation-message errorName="required">
              {{ t('errors.required') }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
        <input
          class="hidden"
          id="payByStripeFormSubmit"
          type="submit"
        />
      </form>
    </div>
  </div>
  <div class="modal-footer padding-top-10">
    <label
      class="btn btn-primary btn-block position-relative"
      for="payByStripeFormSubmit"
      [class.disabled]="!payByStripeForm.valid || !cardValidation.complete || isApiRequestPerformed"
    >
      <div *ngIf="!isAddCardRequest">
        {{
          t('payAndSubscribeButton', {
            amount: resolve.getPriceWithSale(resolve.amount, resolve.payPeriod) | toFormattedMoney
          })
        }}
      </div>
      <div
        *ngIf="isAddCardRequest"
        class="margin-top-20-neg"
      >
        <i class="btn-icon cqi-sm cqi-spinner d-inline-block spin"></i>
      </div>
    </label>
  </div>
</ng-container>

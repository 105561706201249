<ng-container *transloco="let t; read: 'starterGuideLeadsCollectionComponent'">
  <ngb-alert
    *ngIf="isStepMade"
    [dismissible]="false"
    class="mb-30"
    type="success"
  >
    <i class="cqi-sm cqi-check-circle mr-10"></i>
    {{ t('stepPassedAlert', {projectName}) }}
  </ngb-alert>
  <div class="d-flex justify-content-between">
    <div class="margin-between-rows-20">
      <div>
        <span
          class="font-weight-bold dashed"
          [ngbTooltip]="t('hint.tooltip')"
          >{{ t('hint.text') }}</span
        >
        <span class="font-weight-bold">{{ t('description') }}</span>
      </div>
      <ul class="steps-list">
        <li>
          <div>
            <span>{{ t('instructions.1.text') }}</span>
            <a
              target="_blank"
              [href]="t('instructions.1.link.href')"
              >{{ t('instructions.1.link.text') }}
              <i class="cqi-sm cqi-external-link"></i>
            </a>
          </div>
        </li>
        <li>
          <div>
            <span>{{ t('instructions.2.firstText') }}</span>
            <span
              class="dashed"
              [ngbTooltip]="t('instructions.2.hint.tooltip')"
              >{{ t('instructions.2.hint.text') }}</span
            >
            <span>{{ t('instructions.2.secondText') }}</span>
          </div>
        </li>
        <li>{{ t('instructions.3') }}</li>
        <li>{{ t('instructions.4') }}</li>
      </ul>
      <button
        (click)="goToBotCreation()"
        class="btn btn-primary"
        data-test="go-to-leadbot-create-page-button"
        type="button"
      >
        <i class="btn-icon-left cqi-sm cqi-external-link"></i>
        <span>{{ t('button.text') }}</span>
      </button>
    </div>
    <div>
      <img
        (click)="openViewVideoModal()"
        class="cursor-pointer"
        data-test="open-view-video-modal-img"
        src="assets/img/{{ activeLang }}/starter-guide/onboarding_v2_leads_collection.png"
      />
    </div>
  </div>
</ng-container>

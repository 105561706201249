import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { distinctUntilChanged, firstValueFrom, map, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';

@Component({
  selector: 'cq-integration-select',
  templateUrl: './integration-select.component.html',
  styleUrls: ['./integration-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationSelectComponent {
  readonly formControl = this.fb.control<string | null>(null, [Validators.required]);

  @Input()
  integrationList: any[] | null = [];

  @Input()
  set integrationId(value: string | null) {
    if (!value) {
      return;
    }
    this.formControl.setValue(value, { emitEvent: false });
  }

  @Output()
  integrationIdChange: Observable<string> = this.formControl.valueChanges.pipe(
    distinctUntilChanged(),
    map((telegramIntegrationId) => {
      return telegramIntegrationId!;
    }),
  );

  @Output()
  touchAndValidateCallback: Observable<ValidationCallback> = of(() => {
    this.formControl.markAsTouched();
    return this.formControl.status !== 'PENDING'
      ? Promise.resolve(this.formControl.valid)
      : firstValueFrom(
          this.formControl.statusChanges.pipe(
            filter((status) => status !== 'PENDING'),
            map(() => this.formControl.valid),
          ),
        );
  });

  constructor(private readonly fb: FormBuilder) {}
}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('ZapierController', ZapierController);

  function ZapierController(
    $state,
    $translate,
    toastr,
    PROJECT_NAME,
    eventTypeModel,
    integrationModel,
    validationHelper,
    currentApp,
    integration,
  ) {
    var vm = this;

    vm.$translate = $translate;
    vm.integration = integration;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.triggers = [];
    vm.isManualExpanded = angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.toggleManualVisibility = toggleManualVisibility;

    init();

    function init() {
      vm.triggers = convertMappingToArray(integration.settings.webhooks, 'url', 'eventTypes') || [];

      firstValueFrom(eventTypeModel.getList(currentApp.id)).then(function (eventTypes) {
        vm.eventTypes = {};
        for (var i = 0; i < eventTypes.length; i++) {
          vm.eventTypes[eventTypes[i].id] = eventTypes[i];
        }
      });
    }

    /**
     * Конвертация объекта (ключи и значения объекта должны быть примитивными типами) в массив объектов
     *
     * @example
     * // вернёт [{'newKey1': 'key1', 'newKey2': 'value1'}, {'newKey1': 'key2', 'newKey2': 'value2'}]
     * convertMappingToArray({'key1': 'value1', 'key2': 'value2'}, 'newKey1', 'newKey2');
     *
     * @param {Object} mapping Объект для конвертации
     * @param {String} keyName Название ключа элемента массива, значение которого будет являться ключом объекта
     * @param {String} valueName Название ключа элемента массива, значение которого будет являться значением объекта
     * @return {Array.<Object>}
     */
    function convertMappingToArray(mapping, keyName, valueName) {
      var array = [];

      for (var key in mapping) {
        if (mapping.hasOwnProperty(key)) {
          var arrayItem = {};

          arrayItem[keyName] = key;
          arrayItem[valueName] = mapping[key];

          array.push(arrayItem);
        }
      }

      return array;
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

(function () {
  'use strict';

  angular.module('myApp.conversations').component('cqHotkeysModalWrapper', cqHotkeysModalWrapper());

  function cqHotkeysModalWrapper() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // djangoUser - текущий пользователь
      },
      controller: angular.noop,
      controllerAs: 'vm',
      template: require('./hotkeys.html'),
    };
  }
})();

<ng-container *transloco="let t">
  <ng-select
    #tagSelect
    (add)="tagAdded.emit($event)"
    bindLabel="tag"
    [ngClass]="featureModel.hasAccess(FEATURES.TAGS_OPTIMIZED) ? 'cq-tags-new' : 'cq-tags'"
    [clearable]="false"
    [closeOnSelect]="false"
    [dropdownPosition]="featureModel.hasAccess(FEATURES.TAGS_OPTIMIZED) ? 'top' : 'bottom'"
    [hideSelected]="true"
    [items]="tags"
    [inputAttrs]="{ autocomplete: 'off' }"
    [keyDownFn]="keyDown.bind(this)"
    [multiple]="true"
    [disabled]="disabled"
    [(ngModel)]="selectedViewTags"
    [placeholder]="'+ ' + t('cqTags.addTag')"
    [virtualScroll]="featureModel.hasAccess(FEATURES.TAGS_OPTIMIZED)"
    (close)="showLess()"
    (focus)="selectFocused()"
    (blur)="selectBlurred()"
  >
    <ng-template
      ng-label-tmp
      let-tag="item"
      let-clear="clear"
    >
      <cq-tag-default
        class="full-max-width"
        [text]="tag.tag"
        [canActivate]="true"
        [canRemove]="true"
        (remove)="clear(tag); tagRemoved.emit(tag)"
      ></cq-tag-default>

      <div
        *ngIf="isLastViewTag(tag) && !select.focused && !this.select.searchTerm"
        class="text-muted inline-block margin-left-10"
      >
        <span>+</span>
        <span *ngIf="tagsLeftCount">{{ t('cqTags.tagsCount', { count: tagsLeftCount }) }} |</span>
        <span>{{ t('cqTags.addTag') }}</span>
      </div>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-tag="item"
    >
      <cq-tag-default
        class="full-max-width"
        [text]="tag.tag"
        [canActivate]="true"
      ></cq-tag-default>
    </ng-template>
    <ng-template ng-footer-tmp>
      <div class="flex flex-row justify-center align-items-center">
        <button
          class="btn btn-borderless-primary"
          (click)="openAddTagModal()"
        >
          <i class="btn-icon-left cqi-sm cqi-plus"></i>
          <span>{{ t('cqTags.addTagButton') }}</span>
          <span class="text-muted">{{ t('cqTags.hotKeys') }}</span>
        </button>
      </div>
    </ng-template>
  </ng-select>
</ng-container>

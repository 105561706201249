(function () {
  'use strict';

  angular.module('myApp.messageSenders', []);

  require('./editor/message-sender-editor.controller');
  require('./editor/message-sender-editor.html');
  require('./editor/message-sender-editor-modal.html');
  require('./table/message-senders-table.component');
})();

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CaseStyleHelper, SystemToastService } from '@panel/app/services';
import { EXTENDED_ERROR } from '@panel/app/shared/constants/http.constants';
import { SKIP_ERROR_HANDLE } from '@panel/app/shared/constants/http.constants';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Преобразует результат неудачно выполненных запросов
 * Бэкенд присылает результат таких запросов как {data: {}, meta: object}, а этот интерсептор выкидывает ошибку с meta
 */

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {
  constructor(private caseStyleHelper: CaseStyleHelper, private readonly systemToastService: SystemToastService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    // Сделано в основном для того, чтоб поддерживать старые catch в старых запросов без переписываний
    if (request.context.has(EXTENDED_ERROR)) {
      return next.handle(request).pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(err.error);
        }),
      );
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorData;
        if (error.error instanceof ErrorEvent) {
          errorData = error.message;
        } else {
          errorData = error.error?.meta ?? error.message;
        }

        if (!request.context.has(SKIP_ERROR_HANDLE)) {
          this.systemToastService.requestError();
        }

        // TODO Возможно, это надо вынести в другой интерсептор
        return throwError(this.caseStyleHelper.keysToCamelCase(errorData));
      }),
    );
  }
}

/**
 * Компонент для превью блока 'Картинка'
 */
(function () {
  'use strict';

  require('./popup-image-block-preview.controller');
  require('./image.html');

  angular.module('myApp.popupEditor').component('cqPopupImageBlockPreview', cqPopupImageBlockPreview());

  function cqPopupImageBlockPreview() {
    return {
      bindings: {
        params: '<', // параметры блока
      },
      controller: 'CqPopupImageBlockPreviewController',
      controllerAs: 'vm',
      template: require('./popup-image-block-preview.html'),
    };
  }
})();

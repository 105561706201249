import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

/** Компонент для работы с шапкой вопроса-ответа */
@Component({
  selector: 'cq-item-header[isOpened]',
  templateUrl: './item-header.component.html',
  styleUrls: ['./item-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemHeaderComponent {
  /** Вопрос-ответ открыт */
  @Input()
  isOpened!: boolean;

  /** Callback на toggle */
  @Output()
  toggle: EventEmitter<void> = new EventEmitter<void>();

  /** Обработчик клика */
  @HostListener('click')
  onClick() {
    this.toggle.emit();
  }
}

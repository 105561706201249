(function () {
  'use strict';

  angular
    .module('myApp.serviceInstallation')
    .constant('SERVICE_INSTALLATION_METHODS', getServiceInstallationMethods())
    .constant('SERVICE_INSTALLATION_PLATFORMS', getServiceInstallationPlatforms());

  /**
   * Способы установки скрипта сервиса
   *
   * @return {Object}
   */
  function getServiceInstallationMethods() {
    return {
      /** Установка скрипта вручную */
      MANUAL: 'manual',
      /** Установка скрипта через Google Tag Manager */
      GTM: 'gtm',
      /** Установка скрипта на CMS-платформы (1С-Битрикс, WordPress и т.д.) */
      CMS_PLATFORM: 'cmsPlatform',
    };
  }

  /**
   * CMS-платформы, на которые можно поставить наш скрипт
   *
   * @returns {{MOTTOR: string, PLATFORMA_LP: string, LPGENERATOR: string, TILDA: string, UCOZ: string}}
   */
  function getServiceInstallationPlatforms() {
    return {
      TILDA: 'tilda',
      MOTTOR: 'mottor',
      UCOZ: 'uCoz',
      LPGENERATOR: 'lpGenerator',
      PLATFORMA_LP: 'platformaLP',
    };
  }
})();

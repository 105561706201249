(function () {
  'use strict';

  require('./sticky-scroll.controller');

  angular.module('myApp.directives.stickyScroll').directive('cqStickyScroll', cqStickyScroll);

  function cqStickyScroll($timeout) {
    return {
      restrict: 'A',
      require: {
        cqStickyScrollController: 'cqStickyScroll',
      },
      scope: {
        cloneClass: '@', // класс, который будет установлен клону в фиксированном положении элемента
        container: '@', // селектор контейнера, который скроллится и в котором содержится элемент (если не указан - контейнером считается body)
        fixClass: '@', // класс, который будет установлен элементу
        widthElement: '@', // селектор элемента, по ширине которого будет подгоняться элемент (если не указан - будет взята ширина container)
        isFixed: '=?', // будет установлен в true когда элемент зафиксирован и в false когда элемент не зафиксирован
      },
      controller: 'CqStickyScrollController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      /*
      необходимо использовать $timeout, т.к. если селекторы элементов, которые переданы в container и widthElement динамические - надо, чтобы ангуляр их прорендерил.
      пример:
      
      предположим, что vm.something = 123;
      
      <div id="{{vm.something}}">
        <div cq-sticky-scroll container='#123'></div>
      </div>

      тогда без таймаута angular.element('#123') не вернёт ничего (!!!), т.к. {{vm.something}} ещё не прорендерился, рендеринг делается в конце $digest-цикла
      функция внутри таймаута будет вызвана только в следующий $digest-цикл, когда значения будет проинтерполированы
      */
      $timeout(controller.cqStickyScrollController.setup);
    }
  }
})();

import { Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';

import { Message } from '@http/message/message.types';
import { ELASTICSEARCH_OPERATION_TYPES, ELASTICSEARCH_PROPERTY_OPERATIONS } from '@http/property/property.constants';
import { Properties, PropertyModel } from '@http/property/property.model';
import { UserTag } from '@http/user/types/user.type';
import { CaseStyleHelper } from '@panel/app/services';
import { SENDING_FILTERS_GROUP_TYPES } from '@panel/app/services/conditions-sending/conditions-sending.constants';
import { ConditionsSendingModel } from '@panel/app/services/conditions-sending/conditions-sending.model';
import { FILTER_EVENT_TYPE, FILTER_LOGICAL_OPERATION } from '@panel/app/services/filter/filter.constants';
import {
  ExternalFiltersAJS,
  FiltersAJS,
  UserEventsFilterAJS,
  UserPropsFilterAJS,
  UserTagsFilterAJS,
  UserTagsFilterValueAJS,
} from '@panel/app/services/filter-ajs/filter-ajs.types';

@Injectable({
  providedIn: 'root',
})
export class FilterAjsModel {
  constructor(private readonly caseStyleHelper: CaseStyleHelper, private readonly propertyModel: PropertyModel) {}

  /** Получение дефолтного фильтра по типу */
  getDefault(filterTypes: FILTER_LOGICAL_OPERATION): FiltersAJS {
    return {
      type: filterTypes,
      filters: this.getFiltersOfFilter(),
    };
  }

  /** Получение дефолтных фильтров фильтра */
  private getFiltersOfFilter() {
    return {
      props: [],
      events: [],
      tags: [],
    };
  }

  /** Получение дефолтного события пользователя */
  getDefaultFilterEvent() {
    return {
      eventType: null,
      propertyType: FILTER_EVENT_TYPE.COUNT,
      type: ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN,
      value: {},
    };
  }

  /** Получение дефолтного свойства пользователя */
  getDefaultFilterProp(type = ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN) {
    return {
      userProp: null,
      value: {},
      type,
    } as any;
  }

  getDefaultAnd(): FiltersAJS {
    return this.getDefault(FILTER_LOGICAL_OPERATION.AND);
  }

  getDefaultOr(): FiltersAJS {
    return this.getDefault(FILTER_LOGICAL_OPERATION.OR);
  }

  /** Является ли фильтр дефолтным */
  isDefault(filter: FiltersAJS): boolean {
    return isEqual(this.getDefault(filter.type), filter);
  }

  /**
   * Проверка наличия фильтров в сообщении
   *
   * @param {Object} message Автосообщение или чат-бот
   * @returns {boolean}
   */
  isMessageHaveFilters(message: Message): boolean {
    return (
      message.filters.filters.props.length > 0 ||
      message.filters.filters.events.length > 0 ||
      message.filters.filters.tags.length > 0 ||
      message.jinjaFilterTemplate !== null
    );
  }

  //Желательно избавиться от мутаций, поэтому при использвании, берите данные, которые возвращает функция
  /** Функция связывания фильтра со свойствами и тегами */
  linkWithPropsAndTags(filter: ExternalFiltersAJS, props: Properties, tags: UserTag[]): FiltersAJS {
    let newFilters: FiltersAJS = this.getDefault(filter.type);

    for (var k = 0; k < filter.filters.length; k++) {
      //Если имя фильтра $tags то пушим фильтр в tags
      const foundTags = tags.find((tag) => tag.name === (filter.filters[k].value as UserTagsFilterValueAJS).viewValue);
      if (filter.filters[k].propertyName === '$tags' && foundTags) {
        newFilters.filters.tags.push({
          tag: cloneDeep(foundTags),
          type: filter.filters[k].type,
        });
        continue;
      }

      //Если фильтр есть в props.userProps, то пушим фильтр в props
      const foundUserProp = props.userProps.find((userProp) => userProp.name === filter.filters[k].propertyName);
      if (foundUserProp) {
        newFilters.filters.props.push({
          userProp: cloneDeep(foundUserProp),
          value: cloneDeep(filter.filters[k].value as UserPropsFilterAJS['value']),
          type: filter.filters[k].type,
        });
        continue;
      }

      //Если фильтр есть в props.eventTypeProps, то пушим фильтр в events
      const foundEventTypeProp = props.eventTypeProps.find(
        (eventTypeProp) => eventTypeProp.name === filter.filters[k].propertyName,
      );
      if (foundEventTypeProp) {
        newFilters.filters.events.push({
          eventType: cloneDeep(foundEventTypeProp.eventType),
          value: cloneDeep(filter.filters[k].value as UserEventsFilterAJS['value']),
          type: filter.filters[k].type,
          propertyType: filter.filters[k].propertyName!.split('_')[2],
          prettyName: foundEventTypeProp.prettyName,
        });
      }
    }

    //Делаю так потому что функция мутировала пришедший объект, и чтобы не менять везде ее использование,
    //Решил добавить игнор и вернуть объект наружу
    //@ts-ignore
    filter.filters = newFilters.filters;

    return newFilters;
  }

  normalizeFilter(filter: string | object): ExternalFiltersAJS {
    let filterExternal: ExternalFiltersAJS = {
      type: FILTER_LOGICAL_OPERATION.AND,
      filters: [],
    };

    if (typeof filter === 'string') {
      filterExternal = JSON.parse(filter);
      filter = filterExternal;
    }

    this.caseStyleHelper.keysToCamelCase(filterExternal);

    //NOTE: по какой то неведанной причине фильтры могут приходить срузу массивом
    //Потому что так исторически сложилось...
    if (Array.isArray(filter)) {
      const filtersOfFilter = cloneDeep(filter);
      filterExternal.filters = filtersOfFilter;
    }

    return filterExternal;
  }

  /** Парсинг фильтра */
  parse(filters: string | object): ExternalFiltersAJS {
    let filtersExternal: ExternalFiltersAJS = this.normalizeFilter(filters);

    this.caseStyleHelper.keysToCamelCase(filtersExternal);

    for (var i = 0; i < filtersExternal.filters.length; i++) {
      //NOTE Раньше ID выбранных тегов были числами, сейчас это строки. Поэтому надо переводить
      if (
        filtersExternal.filters[i].propertyName === '$tags' &&
        typeof filtersExternal.filters[i].value.value === 'number'
      ) {
        filtersExternal.filters[i].value.value = filtersExternal.filters[i].value.value + '';
        continue;
      }
      // Если пришел массив значений, его надо привести к строке т.к. вывод текущих и ввод новых значений осуществляется через input
      if (Array.isArray(filtersExternal.filters[i].value.value)) {
        filtersExternal.filters[i].value.value = (filtersExternal.filters[i].value.value as string[]).join(',');
      }
    }

    return filtersExternal;
  }

  /**
   * Парсинг Url-фильтров
   *
   * @param {Object | null} sendingFilters - URL-фильтры
   *
   * @return {Object}
   */
  parseSendingFiltersToInternalFormat(sendingFilters: any) {
    let parsedFilters = {
      exclusion: {
        type: FILTER_LOGICAL_OPERATION.AND,
        filters: [ConditionsSendingModel.getDefaultSendingFilter(SENDING_FILTERS_GROUP_TYPES.EXCLUSION)],
      },
      inclusion: {
        type: FILTER_LOGICAL_OPERATION.OR,
        filters: [ConditionsSendingModel.getDefaultSendingFilter(SENDING_FILTERS_GROUP_TYPES.INCLUSION)],
      },
    };

    if (sendingFilters?.type === FILTER_LOGICAL_OPERATION.OR) {
      parsedFilters.inclusion.filters = sendingFilters.filters;
    }
    if (sendingFilters?.type === FILTER_LOGICAL_OPERATION.AND) {
      parsedFilters.exclusion.filters = sendingFilters.filters;
    }

    return parsedFilters;
  }

  /** Парсинг фильтров для Message в серверный формат */
  parseToServerFormat(filter: FiltersAJS, isReturnString: false): ExternalFiltersAJS;
  parseToServerFormat(filter: FiltersAJS, isReturnString?: true): string;
  parseToServerFormat(filter: FiltersAJS, isReturnString = true): ExternalFiltersAJS | string {
    let serverFormatSegment: ExternalFiltersAJS = {
      type: filter.type,
      filters: [],
    };

    if (!Array.isArray(filter.filters)) {
      let unlinkedFilterUserEvents = [];
      let unlinkedFilterUserProps = [];
      let unlinkedFilterUserTags = [];

      unlinkedFilterUserEvents = filter.filters.events.map((event: UserEventsFilterAJS) =>
        this.userEventToServerFormat(event),
      );

      unlinkedFilterUserProps = filter.filters.props.map((prop: UserPropsFilterAJS) => {
        const filterUserProp = this.parseElasticsearchOperationTypeArrayToString(prop);
        return this.userPropToServerFormat(filterUserProp);
      });

      unlinkedFilterUserTags = filter.filters.tags.map((tag: UserTagsFilterAJS) => this.userTagsToServerFormat(tag));

      serverFormatSegment.filters = [
        ...unlinkedFilterUserEvents,
        ...unlinkedFilterUserProps,
        ...unlinkedFilterUserTags,
      ];
    }

    this.caseStyleHelper.keysToUnderscore(serverFormatSegment);

    const result = isReturnString ? JSON.stringify(serverFormatSegment) : serverFormatSegment;

    return result;
  }

  /** Парсинг фильтров для событий в серверный формат */
  private userEventToServerFormat(filterUserEvent: UserEventsFilterAJS) {
    return {
      value: filterUserEvent.value,
      type: filterUserEvent.type,
      propertyName: '$event' + '_' + filterUserEvent.eventType.id + '_' + filterUserEvent.propertyType,
    };
  }

  /** Парсинг фильтров для свойств в серверный формат */
  private userPropToServerFormat(filterUserProp: UserPropsFilterAJS) {
    return {
      value: filterUserProp.value,
      type: filterUserProp.type,
      propertyName: filterUserProp.userProp.name,
    };
  }

  /** Парсинг фильтров для тегов в серверный формат */
  private userTagsToServerFormat(filterUserTags: UserTagsFilterAJS) {
    return {
      value: {
        value: filterUserTags.tag.id,
        viewValue: filterUserTags.tag.name,
      },
      type: filterUserTags.type,
      propertyName: '$tags',
    };
  }

  /** Парсинг фильтров для Message в серверный формат */
  private parseElasticsearchOperationTypeArrayToString(userProp: any) {
    const isListUserProp =
      this.propertyModel.getElasticsearchOperationTypeByOperation(userProp.type) !==
        ELASTICSEARCH_OPERATION_TYPES.ARRAY ||
      userProp.type === ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_CONTAINS ||
      userProp.type === ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_NOT_CONTAINS;

    if (isListUserProp) {
      return userProp;
    }

    if (Array.isArray(userProp.value.value)) {
      return userProp;
    }

    userProp.value.value = userProp.value.value.split(',');

    return userProp;
  }
}

import {
  POPUP_BACKGROUND_POSITION_TYPES,
  POPUP_BACKGROUND_TYPES,
} from '../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.popupEditor').controller('CqPopupSettingsController', CqPopupSettingsController);

  function CqPopupSettingsController($scope, fileHelper, messagePartModel, shortUuid, validationHelper) {
    var vm = this;

    /**
     * Максимальный размер картинки
     *
     * @type {Number}
     */
    var BACKGROUND_IMAGE_MAX_SIZE = 1024 * 1024;

    /**
     * Уникальный идентификатор для корректной работы радиобаттонов при наличии нескольких компонентов на одной странице
     * Без этого индентификатора, ID у input'ов будут одинаковые, а это противоречит правилам HTML, поэтому при клике на чекбоксы/радио будут баги
     * Тот же самый баг будет с одинаковыми именами input[type="radio"], т.к. такие радио объединяются в radio group
     */
    const UUID = shortUuid();

    vm.$onInit = init;

    function init() {
      /**
       * Изначальная фоновая картинка
       *
       * @type {File|String|null}
       */
      var currentBackgroundImage = vm.popupParams.backgroundImage;

      vm.BACKGROUND_IMAGE_MAX_SIZE = BACKGROUND_IMAGE_MAX_SIZE;
      vm.backgroundPosition = messagePartModel.getBackgroundPositionType(vm.popupParams); // текущий тип позиционирования фона
      vm.currentBackgroundImagePreview =
        currentBackgroundImage instanceof File
          ? fileHelper.getObjectUrl(currentBackgroundImage)
          : currentBackgroundImage; // URL изначальной фоновой картинки (для вывода превью)
      vm.fileHelper = fileHelper;
      vm.newBackgroundImage = {
        // новая фоновая картинка
        file: null, // файл нового фона
        url: '', // URL нового фона (для вывода превью)
      };
      vm.POPUP_BACKGROUND_POSITION_TYPES = POPUP_BACKGROUND_POSITION_TYPES;
      vm.POPUP_BACKGROUND_TYPES = POPUP_BACKGROUND_TYPES;
      vm.messagePartModel = messagePartModel;
      vm.popupSettingsForm = null; // форма настроек поп-апа
      vm.UUID = UUID;
      vm.validationHelper = validationHelper;

      $scope.$watch('vm.newBackgroundImage.file', setBackgroundImage);

      /**
       * Установка фоновой картинки
       * Так пришлось извращаться, потому что поле vm.popupParams.backgroundImage напрямую не связано с моделью инпута
       *
       * @param {File|String|null} newValue
       */
      function setBackgroundImage(newValue) {
        if (newValue) {
          // если новая фоновая картинка присутствует (выбрана и валидна) - выставляем её в качестве нового фона
          vm.popupParams.backgroundImage = newValue;
        } else {
          // иначе - возвращаем старую картинку
          vm.popupParams.backgroundImage = currentBackgroundImage;
        }
      }
    }
  }
})();

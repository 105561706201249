import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UIRouterModule } from '@uirouter/angular';
import { ClipboardModule } from 'ngx-clipboard';

import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { AlbatoModule } from '@panel/app/pages/integrations/content/albato/albato.module';
import { AmocrmModule } from '@panel/app/pages/integrations/content/amocrm/amocrm.module';
import { CalendlyModule } from '@panel/app/pages/integrations/content/calendly/calendly.module';
import { EmailNotificationEventTriggersComponent } from '@panel/app/pages/integrations/content/email-notification/content/event-triggers/event-triggers.component';
import { EmailNotificationMessageTriggersReadonlyComponent } from '@panel/app/pages/integrations/content/email-notification/content/message-triggers-readonly/message-triggers-readonly.component';
import { EmailNotificationTeamMembersSelectComponent } from '@panel/app/pages/integrations/content/email-notification/content/team-members-select/team-members-select.component';
import { GoogleAnalyticsComponent } from '@panel/app/pages/integrations/content/google-analytics/google-analytics.component';
import { TelegramWrapperComponent } from '@panel/app/pages/integrations/content/telegram/wrapper/telegram-wrapper.component';
import { VkModule } from '@panel/app/pages/integrations/content/vk/vk.module';
import { VkFormsModule } from '@panel/app/pages/integrations/content/vk-forms/vk-forms.module';
import { WhatsAppEdnaModule } from '@panel/app/pages/integrations/content/whats-app-edna/whats-app-edna.module';
import { IntegrationCardModule } from '@panel/app/pages/integrations/integration-card/integration-card.module';
import { IntegrationIconModule } from '@panel/app/pages/integrations/integration-icon/integration-icon.module';
import { IntegrationListModule } from '@panel/app/pages/integrations/integration-list/integration-list.module';
import { IntegrationPreviewModule } from '@panel/app/pages/integrations/integration-preview/integration-preview.module';
import { IntegrationDescriptionModule } from '@panel/app/pages/integrations/partials/integration-description/integration-description.module';
import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { IntegrationsPartialsModule } from '@panel/app/pages/integrations/partials/integrations-partials.module';
import { WaChatIconModalModule } from '@panel/app/partials/modals/wa-chat-icon-modal/wa-chat-icon-modal.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AllokaComponent } from './content/alloka/alloka.component';
import { EmailNotificationChatBotsListReadonlyComponent } from './content/email-notification/content/chat-bots-list-readonly/chat-bots-list-readonly.component';
import { EmailNotificationChatBotsListTriggersComponent } from './content/email-notification/content/chat-bots-list-triggers/chat-bots-list-triggers.component';
import { EmailNotificationCommentComponent } from './content/email-notification/content/comment/comment.component';
import { EmailNotificationEmailPreviewComponent } from './content/email-notification/content/email-preview/email-preview.component';
import { EmailNotificationEventTriggersReadonlyComponent } from './content/email-notification/content/event-triggers-readonly/event-triggers-readonly.component';
import { EmailNotificationMessageTriggersComponent } from './content/email-notification/content/message-triggers/message-triggers.component';
import { EmailNotificationSubjectComponent } from './content/email-notification/content/subject/subject.component';
import { EmailNotificationTeamMembersSelectReadonlyComponent } from './content/email-notification/content/team-members-select-readonly/team-members-select-readonly.component';
import { EmailNotificationUserPropsComponent } from './content/email-notification/content/user-props/user-props.component';
import { EmailNotificationCreateModalWrapperComponent } from './content/email-notification/create-modal-wrapper/email-notification-create-modal-wrapper.component';
import { EmailNotificationComponent } from './content/email-notification/email-notification.component';
import { EmailNotificationSelectModalWrapperComponent } from './content/email-notification/select-modal-wrapper/email-notification-select-modal-wrapper.component';
import { EmailNotificationWrapperComponent } from './content/email-notification/wrapper/email-notification-wrapper.component';
import { TelegramBotTokenComponent } from './content/telegram/content/bot-token/bot-token.component';
import { TelegramManualComponent } from './content/telegram/content/manual/manual.component';
import { TelegramComponent } from './content/telegram/telegram.component';

@NgModule({
  declarations: [
    AllokaComponent,
    EmailNotificationChatBotsListReadonlyComponent,
    EmailNotificationChatBotsListTriggersComponent,
    EmailNotificationCommentComponent,
    EmailNotificationComponent,
    EmailNotificationCreateModalWrapperComponent,
    EmailNotificationEmailPreviewComponent,
    EmailNotificationEventTriggersComponent,
    EmailNotificationEventTriggersReadonlyComponent,
    EmailNotificationMessageTriggersComponent,
    EmailNotificationMessageTriggersComponent,
    EmailNotificationMessageTriggersReadonlyComponent,
    EmailNotificationMessageTriggersReadonlyComponent,
    EmailNotificationSelectModalWrapperComponent,
    EmailNotificationSubjectComponent,
    EmailNotificationTeamMembersSelectComponent,
    EmailNotificationTeamMembersSelectReadonlyComponent,
    EmailNotificationUserPropsComponent,
    EmailNotificationWrapperComponent,
    GoogleAnalyticsComponent,
    EmailNotificationMessageTriggersComponent,
    EmailNotificationMessageTriggersReadonlyComponent,
    TelegramBotTokenComponent,
    TelegramComponent,
    TelegramManualComponent,
    TelegramWrapperComponent,
  ],
  imports: [
    AlbatoModule,
    AmocrmModule,
    CalendlyModule,
    ClipboardModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    IntegrationCardModule,
    IntegrationDescriptionModule,
    IntegrationIconModule,
    IntegrationListModule,
    IntegrationNameControlModule,
    IntegrationPreviewModule,
    IntegrationsPartialsModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
    UIRouterModule,
    VkFormsModule,
    VkModule,
    WaChatIconModalModule,
    WhatsAppEdnaModule,
  ],
  providers: [IntegrationFactory, NgbActiveModal],
})
export class IntegrationsModule {}

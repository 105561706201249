import { UserColorsCalculationService } from '../../../../app/shared/services/user-colors-calculation/user-colors-calculation.service';
import { firstValueFrom } from 'rxjs';
import { STARTER_GUIDE_STEPS } from '../../../../app/http/starter-guide/starter-guide.constants';

(function () {
  'use strict';

  angular
    .module('myApp.starterGuide')
    .controller('CqStarterGuideChatSettingsController', CqStarterGuideChatSettingsController);

  function CqStarterGuideChatSettingsController(
    $scope,
    $translate,
    carrotquestHelper,
    chatSettingsModel,
    featureModel,
    l10nHelper,
    starterGuideModel,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.accentHexColor = vm.chatSettings.messenger_collapsed_color;
      vm.contrastColor = '#ffffff';
      vm.doAccentAndWhiteMatchGood = true;
      vm.iconTheme = 'default';
      vm.modeSettings = vm.chatSettings.messenger_mode_settings;
      vm.onModeChange = onModeChange;
      vm.onModeSettingsChange = onModeSettingsChange;
      vm.onValidationTrigger = onValidationTrigger;
      vm.saveSettings = saveSettings;
      vm.userColorAndThemeDependent = vm.accentHexColor;

      $scope.$watchGroup(
        ['vm.chatSettings.messenger_collapsed_color', 'vm.chatSettings.messenger_theme'],
        function (newValues, oldValues) {
          const [color, theme] = newValues;
          calcChatColors(color);
        },
      );
    }

    /**
     * Вся логика - копипаста из репо cqstatic. Смотреть файл getUserColorPalette.js
     */
    function calcChatColors(newColor) {
      // В статике есть логика того, что мы меняем его цвет, если цвет у клиента слишком светлый в светлой теме
      vm.accentHexColor = UserColorsCalculationService.getMainUserColor(newColor, vm.chatSettings.messenger_theme);

      const { isLightOnClientsColorLooksWell, contrastColor, userColorAndThemeDependent, iconTheme } =
        UserColorsCalculationService.getUserColorPalette(vm.accentHexColor, vm.chatSettings.messenger_theme);

      vm.contrastColor = contrastColor;
      vm.userColorAndThemeDependent = userColorAndThemeDependent;
      vm.doAccentAndWhiteMatchGood = isLightOnClientsColorLooksWell;
      vm.iconTheme = iconTheme;
    }

    /**
     * Колбэк при смене режима видимости чата
     *
     * @param {VISIBILITY_MODE} mode Режим видимости чата
     */
    function onModeChange(mode) {
      vm.chatSettings.messenger_mode = mode;
    }

    /**
     * Колбэк при смене расширенных настроек видимости чата
     *
     * @param {ModeSettingsExternal} settings Расширенные настройки
     */
    function onModeSettingsChange(settings) {
      vm.chatSettings.messenger_mode_settings = settings;
    }

    /**
     * Колбэк для вызова валидации из AJS, так как форма внутри A2
     *
     * @param fn { Function }
     */
    function onValidationTrigger(fn) {
      vm.getVisibilityValidity = fn;
    }

    /**
     * Сохранение настроек
     *
     * @param {Boolean} valid Флаг валидности формы
     */
    function saveSettings(valid) {
      if (!valid) {
        return;
      }

      if (vm.getVisibilityValidity()) {
        let settings = {
          messenger_collapsed_color: vm.chatSettings.messenger_collapsed_color.split('#')[1],
          messenger_mode: vm.chatSettings.messenger_mode,
          messenger_mode_settings: vm.chatSettings.messenger_mode_settings,
          messenger_name: vm.chatSettings.messenger_name,
          messenger_pattern: vm.chatSettings.messenger_pattern,
          messenger_theme: vm.chatSettings.messenger_theme,
        };

        firstValueFrom(chatSettingsModel.save(vm.currentApp.id, settings)).then(saveSuccess);
      }

      function saveSuccess() {
        starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.CHAT_SETTINGS).subscribe();

        angular.extend(vm.currentApp.settings, vm.chatSettings);

        trackStarterGuideStepChatSettingsCompletion();
      }
    }

    /** Трекинг завершения шага "Настройки чата" в стартергайде */
    function trackStarterGuideStepChatSettingsCompletion() {
      const eventName = 'Стартергайд - дизайн чата настроен';

      carrotquestHelper.track(eventName);
    }
  }
})();

(function () {
  'use strict';

  angular.module('myApp.integrations').constant('SEGMENT_INTEGRATION_STEPS', getSegmentIntegrationSteps());

  function getSegmentIntegrationSteps() {
    return {
      STEP_1: {
        ORDER: 0,
        TEMPLATE_URL: 'js/components/integrations/segment/steps/step1.html',
        VALUE: 'step1',
      },
      STEP_2: {
        ORDER: 1,
        TEMPLATE_URL: 'js/components/integrations/segment/steps/step2.html',
        VALUE: 'step2',
      },
    };
  }
})();

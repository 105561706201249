import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import {
  CarrotquestHelperProvider,
  L10nHelperProvider,
  UibModalServiceProvider,
} from '@panel/app/ajs-upgraded-providers';

import { QuizInRegistrationModalComponent } from './quiz-in-registration-modal.component';

@NgModule({
  declarations: [QuizInRegistrationModalComponent],
  imports: [CommonModule, TranslocoModule, NgbModalModule],
  providers: [CarrotquestHelperProvider, UibModalServiceProvider, L10nHelperProvider],
})
export class QuizInRegistrationModalModule {}

(function () {
  'use strict';

  require('./auto-event-css-selector.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventCssSelector', cqAutoEventCssSelector());

  function cqAutoEventCssSelector() {
    return {
      bindings: {
        cssSelector: '=', // CSS селектор
      },
      controller: 'CqAutoEventCssSelectorController',
      controllerAs: 'vm',
      template: require('./auto-event-css-selector.html'),
    };
  }
})();

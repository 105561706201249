import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { DefaultActionHelper } from 'app/http/chat-bot/helpers-for-gerenation-of-defaults/default-action.helper';
import { generate } from 'short-uuid';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { cloneObjectMutable } from '@panel/app/shared/functions/clone-object-mutable.function';
import { Immutable } from '@panel/app/shared/types/immutable.type';

@Injectable({ providedIn: 'root' })
export class DefaultBranchHelper {
  /**
   * Ветка бота по умолчанию
   */
  private readonly DEFAULT_CHAT_BOT_BRANCH: Immutable<ChatBotBranch> = {
    id: null,
    linkId: '', // Временный ID
    parentBranchIds: [], // Список ID родительских веток
    coordinates: null, // Координаты веток в канвасе
    name: '', // Имя будет такое же как и имя автосообщения
    actions: [],
    isInvalid: false, // Не валидность ветки NOTE используется только на фронте
  };

  constructor(private readonly transloco: TranslocoService) {}

  /**
   * Получение ветки бота по умолчанию
   *
   * @param name - Название ветки
   * @param addCloseAction - Флаг для добавления дейсвия завершения диалога
   * @param addTextAction
   */
  public getDefaultBranch(name?: string, addCloseAction: boolean = true, addTextAction: boolean = true): ChatBotBranch {
    let defaultChatBotBranch: ChatBotBranch = cloneObjectMutable(this.DEFAULT_CHAT_BOT_BRANCH);

    defaultChatBotBranch.linkId = generate();
    defaultChatBotBranch.parentBranchIds.push(defaultChatBotBranch.linkId);
    defaultChatBotBranch.name = name ?? this.transloco.translate('models.chatBot.defaultBranchName');
    addTextAction &&
      defaultChatBotBranch.actions.push(DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.TEXT));
    addCloseAction &&
      defaultChatBotBranch.actions.push(DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.CLOSE));

    return defaultChatBotBranch;
  }
}

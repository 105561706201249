/**
 * Директива, которая осуществляет проверку, что введённое в input число больше, чем число, заданное в директиву
 */
(function () {
  'use strict';

  angular.module('myApp.directives.number.additions').directive('cqMin', cqMin);

  function cqMin() {
    return {
      priority: 1010,
      restrict: 'A',
      require: ['ngModel', '?cqInteger', '?cqMoney'],
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var ngModelController = controller[0];
      var cqIntegerController = controller[1];
      var cqMoneyController = controller[2];

      if (!cqIntegerController && !cqMoneyController) {
        throw Error('cqMin expects either cqInteger or cqMoney to be defined');
      }

      var minVal;

      ngModelController.$validators.min = min;
      iAttrs.$observe('cqMin', observe);

      /**
       * Валидация значения из модели, что оно больше, чем minVal
       *
       * @param {Number} value Значение, переданное на вход функции валидации
       * @returns {Boolean} Если значение валидное число и оно больше, чем minVal - возвращается true, иначе - false
       */
      function min(value) {
        if (ngModelController.$isEmpty(value) && !isNaN(value)) {
          return true;
        } else {
          if (isNaN(value)) {
            return false;
          }
          return value >= minVal;
        }
      }

      /**
       * Динамическое получение значения из атрибута cq-min (чтобы можно было задавать в атрибут angular expression)
       *
       * @param {*} value Значение, переданное в атрибут
       */
      function observe(value) {
        if (angular.isDefined(value) && !angular.isNumber(value)) {
          value = parseFloat(value);
        }
        minVal = angular.isNumber(value) && !isNaN(value) ? value : undefined;

        ngModelController.$validate();
      }
    }
  }
})();

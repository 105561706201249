(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqMyTargetOAuthController', CqMyTargetOAuthController);

  function CqMyTargetOAuthController($stateParams, $window, integrationModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      const { appId, code, error, integrationId } = $stateParams;

      if (carrotquest && carrotquest.messenger) {
        carrotquest.messenger.toStateNo();
      }
      if (error) {
        $window.opener.postMessage(
          {
            status: 'error',
          },
          '*',
        );
      } else if (code) {
        authorize(appId, integrationId, code);
      }
    }

    /**
     * Авторизация в myTarget
     *
     * @param appId
     * @param integrationId
     * @param code
     */
    function authorize(appId, integrationId, code) {
      integrationModel.myTarget.authorize(integrationId, appId, code).then(authorizeSuccess).catch(authorizeError);

      function authorizeSuccess() {
        $window.opener.postMessage(
          {
            status: 'accepted',
          },
          '*',
        );
      }

      function authorizeError() {
        $window.opener.postMessage(
          {
            status: 'error',
          },
          '*',
        );
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'editRequisitesModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="text-secondary mb-15">{{ t('body') }}</div>
    <form
      (ngSubmit)="onSubmit()"
      [formGroup]="form"
    >
      <div
        class="form-group"
        [cqShowError]="form.controls.name"
      >
        <label for="name">{{ t('nameInput.label') }}</label>
        <input
          id="name"
          class="form-control"
          [formControl]="form.controls.name"
          [placeholder]="t('nameInput.placeholder')"
        />
        <cq-validation-messages [control]="form.controls.name">
          <cq-validation-message errorName="required">{{ t('nameInput.errors.required') }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <div
        class="form-group"
        [cqShowError]="form.controls.inn"
      >
        <label for="inn">{{ t('innInput.label') }}</label>
        <input
          id="inn"
          class="form-control"
          [formControl]="form.controls.inn"
          [placeholder]="t('innInput.placeholder')"
        />
        <cq-validation-messages [control]="form.controls.inn">
          <cq-validation-message errorName="required">{{ t('innInput.errors.required') }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <div
        class="form-group"
        [cqShowError]="form.controls.kpp"
      >
        <label for="kpp">{{ t('kppInput.label') }}</label>
        <input
          id="kpp"
          class="form-control"
          [formControl]="form.controls.kpp"
          [placeholder]="t('kppInput.placeholder')"
        />
      </div>
      <div
        class="form-group"
        [cqShowError]="form.controls.ogrn"
      >
        <label for="ogrn">{{ t('ogrnInput.label') }}</label>
        <input
          id="ogrn"
          class="form-control"
          [formControl]="form.controls.ogrn"
          [placeholder]="t('ogrnInput.placeholder')"
        />
      </div>
      <div
        class="form-group"
        [cqShowError]="form.controls.address"
      >
        <label for="address">{{ t('addressInput.label') }}</label>
        <input
          id="address"
          class="form-control"
          [formControl]="form.controls.address"
          [placeholder]="t('addressInput.placeholder')"
        />
        <cq-validation-messages [control]="form.controls.address">
          <cq-validation-message errorName="required">{{ t('addressInput.errors.required') }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <div
        class="form-group"
        [cqShowError]="form.controls.postalAddress"
      >
        <label for="postalAddress">{{ t('postalAddressInput.label') }}</label>
        <input
          id="postalAddress"
          class="form-control"
          [formControl]="form.controls.postalAddress"
          [placeholder]="t('postalAddressInput.placeholder')"
        />
      </div>
      <div
        class="form-group mb-0"
        [cqShowError]="form.controls.fio"
      >
        <label for="fio">{{ t('fioInput.label') }}</label>
        <input
          id="fio"
          class="form-control"
          [formControl]="form.controls.fio"
          [placeholder]="t('fioInput.placeholder')"
        />
        <cq-validation-messages [control]="form.controls.fio">
          <cq-validation-message errorName="required">{{ t('fioInput.errors.required') }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <input
        class="hidden"
        id="form.submit"
        type="submit"
        [disabled]="isApiRequestPerformed | async"
      />
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <label
      class="btn btn-primary"
      for="form.submit"
      [ngClass]="{ disabled: isApiRequestPerformed | async }"
      >{{ 'general.save' | transloco }}</label
    >
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CHANNEL_TYPES } from '@http/channel/channel.constants';
import { Channel } from '@http/channel/channel.model';
import { CaseStyleHelper } from '@panel/app/services';

@Component({
  selector: 'cq-channel-icon[channel]',
  templateUrl: './channel-icon.component.html',
  styleUrls: ['./channel-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelIconComponent {
  @Input()
  readonly channel!: Pick<Channel, 'name' | 'type' | 'avatar'>;

  @Input()
  readonly xs?: '';

  @Input()
  readonly md?: ''; // Дефолтный размер иконки

  @Input()
  readonly lg?: '';

  constructor(private readonly caseStyleHelper: CaseStyleHelper) {}

  CHANNEL_TYPES = CHANNEL_TYPES;

  get parsedName(): string {
    const name = this.channel.name;
    if (name.indexOf(' ') !== -1) {
      const words = name.split(/[ ]+/);
      return words[0][0] + words[1][0];
    } else {
      return name.slice(0, 2);
    }
  }

  get iconClass(): string {
    if (!this.channel.type) {
      return '';
    }
    return `cqi-${this.caseStyleHelper.toKebabCase(this.channel.type)}`;
  }
}

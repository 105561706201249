<ng-container *transloco="let t; read: 'chatWidgetOverheaderComponent'">
  <div [cqShowError]="control">
    <label for="overheader-value">{{ t('input.label') }}:</label>
    <input
      class="form-control"
      id="overheader-value"
      required
      type="text"
      [formControl]="control"
      placeholder="{{ t('input.placeholder') }}"
    />
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('input.errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('input.errors.maxlength', { maxLength: VALUE_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { App } from '@http/app/app.model';
import { EventType, UserProperty } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';

@Injectable()
export class MessageEditorTriggerState {
  readonly autoEvents$: BehaviorSubject<AutoEvent[]> = new BehaviorSubject<AutoEvent[]>([]);
  readonly eventTypes$: BehaviorSubject<EventType[]> = new BehaviorSubject<EventType[]>([]); // Типы событий
  readonly userProps$: BehaviorSubject<UserProperty[]> = new BehaviorSubject<UserProperty[]>([]); // Свойства пользователя
  readonly currentApp$: BehaviorSubject<App | null> = new BehaviorSubject<App | null>(null);
}

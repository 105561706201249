import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { PAID_PLAN_VERSION } from '../../../../app/services/billing-plan/billing-plan.constants';

(function () {
  'use strict';

  angular
    .module('myApp.chatSettings')
    .controller('CqChatOperatorsEvaluationController', CqChatOperatorsEvaluationController);

  function CqChatOperatorsEvaluationController(
    $scope,
    billingInfoModel,
    carrotquestHelper,
    billingPlanService,
    paywallService,
    planVersionService,
    planFeatureAccessService,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToAnalyticsConversationsVote = planFeatureAccessService.getAccess(
        PLAN_FEATURE.ANALYTICS_CONVERSATIONS_VOTE,
        vm.currentApp,
      );

      vm.billingPlanService = billingPlanService;
      vm.isCheckboxDisabled = isCheckboxDisabled;
      vm.onMessengerVoteChange = onMessengerVoteChange;
      vm.PLAN_FEATURE = PLAN_FEATURE;
      vm.paywallService = paywallService;
      vm.planVersionService = planVersionService;

      $scope.$watch('vm.supportVote', (newVal, oldVal) => {
        if (newVal !== oldVal && newVal === true) {
          trackEnableMessengerVote();
        }
      });
    }

    /**
     * Заблокирован чекбокс или нет
     *
     * @returns {boolean}
     */
    function isCheckboxDisabled() {
      return !planVersionService.isV1(vm.currentApp) && !vm.accessToAnalyticsConversationsVote.hasAccess;
    }

    /**
     * Вызывается при клике на чекбокс включения оценки работы операторов чата
     */
    function onMessengerVoteChange() {
      if (planVersionService.isFreemium()) {
        paywallService.showPaywall(vm.currentApp, vm.accessToAnalyticsConversationsVote.denialReason);
      }

      if (!vm.accessToAnalyticsConversationsVote.hasAccess) {
        vm.supportVote = false;
        paywallService
          .showPaywall(vm.currentApp, vm.accessToAnalyticsConversationsVote.denialReason)
          .result.then(() => {
            vm.supportVote = false;

            if (vm.accessToAnalyticsConversationsVote.denialReason.paidPlanVersion === PAID_PLAN_VERSION.V1) {
              vm.supportVote = vm.accessToAnalyticsConversationsVote.hasAccess;
            }
          });
      }
    }

    /**
     * Трекинг включения оценки операторов
     */
    function trackEnableMessengerVote() {
      carrotquestHelper.track('Настройки чата – включил оценку операторов');
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLetModule } from '@taiga-ui/cdk';

import { TcConnectionPointOverlayComponent } from './tc-connection-point-overlay.component';
import { TcConnectionPointOverlayOptionsFilterPipe } from './tc-connection-point-overlay-options-filter.pipe';

@NgModule({
  declarations: [TcConnectionPointOverlayComponent, TcConnectionPointOverlayOptionsFilterPipe],
  exports: [TcConnectionPointOverlayComponent],
  imports: [CommonModule, TranslocoModule, NgbDropdownModule, NgbTooltipModule, TuiLetModule],
})
export class TcConnectionPointOverlayModule {}

(function () {
  'use strict';

  require('./funnel-editor.controller');

  angular.module('myApp.modals.funnelEditor').component('cqFunnelEditorModal', cqFunnelEditorModal());

  function cqFunnelEditorModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp - Данные текущего приложения
        // currentFunnelName - Имя воронки в которой производится действие (используется для трека при копировании воронки)
        // funnel - Воронка
        // isCreateMain - Флаг создания основной воронки
        // modalAction - Диействие производимое с воронкой (копирование / редактирование / создание новой)
        // properties - Свойства пользователей, типы событий, свойства типов событий
      },
      controller: 'CqFunnelEditorModalController',
      controllerAs: 'vm',
      template: require('./funnel-editor.html'),
    };
  }
})();

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { isArray, upperFirst } from 'lodash-es';

import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureDenialReason } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';
import { App } from '@http/app/app.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { ProductFeatureTextService } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.service';
import { DenialReasonTexts } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.types';

/**
 * Компонент для работы с поповером отказа в доступе к фиче
 */

@Component({
  selector: 'cq-access-denial-popover[currentApp][denialReason]',
  templateUrl: './access-denial-popover.component.html',
  styleUrls: ['./access-denial-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccessDenialPopoverComponent implements OnInit {
  /**
   * Функция, которая вызывается перед нажатием на "Действие"
   *
   * NOTE:
   *  Необходимо, потому что в момент открытия могут быть открыты любые другие выпадающие/всплывающие элементы,
   *  а они могут накладываться друг на друга слоями.
   */
  @Input()
  beforeActionClickFn: Function | null = null;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Причина | Причины отказа */
  @Input()
  denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] = {
    addOn: BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS,
    capability: PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS,
    limitAmount: null,
    limitExceeded: false,
    needChat: false,
    paidPlanVersion: PAID_PLAN_VERSION.V1,
    productFeature: PLAN_FEATURE.ANALYTICS_CONVERSATIONS,
  };

  /** Автоматическое закрытие поповера */
  @Input()
  popoverAutoClose: boolean | 'inside' | 'outside' = true;

  /** Расположение поповера относительно body */
  @Input()
  popoverContainer: string = '';

  /** Включен ли показ поповера */
  @Input()
  popoverDisable: boolean = false;

  /** Расположение поповера относительно ng-content */
  @Input()
  popoverPlacement: PlacementArray = 'top';

  /** Display компонента (задаётся явно, т.к. компонент вызывается в AJS) */
  @HostBinding('style.display')
  display = 'inline-block';

  /** Position компонента (задаётся явно, т.к. компонент вызывается в AJS) */
  @HostBinding('style.position')
  position = 'relative';

  /** Обработчик клика по компоненту */
  @HostListener('click', ['$event'])
  onClick() {
    this.popoverRef.open();

    this.trackShowPopover(this.denialReason);
  }

  /** Ref на поповер */
  @ViewChild(NgbPopover)
  private readonly popoverRef!: NgbPopover;

  /** Тексты для причины отказа в доступе */
  denialReasonTexts: DenialReasonTexts = {
    action: '',
    description: '',
    message: '',
    title: '',
  };

  /** Получение заголовка причины отказа в доступе */
  get denialReasonTitleText() {
    return upperFirst(this.denialReasonTexts.title);
  }

  /** Получение описания причины отказа в доступе */
  get denialReasonDescriptionText() {
    return this.denialReasonTexts.description;
  }

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly paywallService: PaywallService,
    private readonly productFeatureTextService: ProductFeatureTextService,
  ) {}

  ngOnInit(): void {
    this.denialReasonTexts = this.productFeatureTextService.getDenialReasonTexts(this.denialReason);
  }

  /**
   * Обработчик клика на "Действие"
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа доступа
   */
  handleClickAction(currentApp: App, denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[]): void {
    if (this.beforeActionClickFn) {
      this.beforeActionClickFn();
    }

    this.popoverRef.close();

    if (this.denialReasonTexts.message) {
      this.sendMessageToChat(this.denialReasonTexts.message);
    } else {
      this.showPaywall(currentApp, denialReason);
    }
  }

  /**
   * Отправка сообщения в чат
   *
   * @param message - Сообщение, которое необходимо отправить в чат
   */
  sendMessageToChat(message: string): void {
    this.carrotquestHelper.sendChatMessage(message);
  }

  /**
   * Показ пейволла
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа доступа
   */
  showPaywall(currentApp: App, denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[]): void {
    this.paywallService.showPaywall(currentApp, denialReason);
  }

  /**
   * Трекинг показа поповера
   *
   * @param denialReason - Причина | Причины отказа доступа
   */
  trackShowPopover(denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[]): void {
    const eventName = 'Тариф коммуникации - показ поповера';
    let eventParams = {};

    if (isArray(denialReason)) {
      //@ts-ignore
      eventParams['Ограничение'] = PLAN_CAPABILITIES.DOWNGRADE;
    } else {
      const { addOn, capability } = denialReason;
      if (!!addOn) {
        //@ts-ignore
        eventParams['Модуль'] = addOn;
      }
      if (!!capability) {
        //@ts-ignore
        eventParams['Ограничение'] = capability;
      }
    }

    this.carrotquestHelper.track(eventName, eventParams);
  }
}

import {
  SYSTEM_LOG_MESSAGE_CLASSES,
  SYSTEM_LOG_MESSAGE_FORMAT,
  SYSTEM_LOG_MESSAGE_ICONS,
} from '../../../app/http/system-log/system-log.constants';

(function () {
  'use strict';

  angular.module('myApp.systemLogMessage').controller('CqSystemLogMessageController', CqSystemLogMessageController);

  function CqSystemLogMessageController($scope, $translate, moment, LANGUAGE, carrotquestHelper) {
    var vm = this;

    /**
     * Форматы дат
     *
     * @type {Object}
     */
    var TIME_FORMATS = getTimeFormats();

    vm.$onInit = init;

    function init() {
      vm.formattedTime = ''; // форматированное время сообщения
      vm.openChat = openChat;
      vm.SYSTEM_LOG_MESSAGE_CLASSES = SYSTEM_LOG_MESSAGE_CLASSES;
      vm.SYSTEM_LOG_MESSAGE_ICONS = SYSTEM_LOG_MESSAGE_ICONS;
      vm.SYSTEM_LOG_MESSAGE_FORMAT = SYSTEM_LOG_MESSAGE_FORMAT;
      vm.trackClickOnDownloadExportLink = trackClickOnDownloadExportLink;
      vm.trackClickOnShowStatistics = trackClickOnShowStatistics;

      // HACK FIXME: это полная копипаста из TimeSinceLastReplyComponent (бывшего conversation-list-item), всю историю со временем нужно вынести в сервис и сделать нормальный глобальный тик
      $scope.$on('refreshSystemLogMessagesInterval', formatTime);

      // вызываем первоначальное форматирование времени при инициализации компонента, т.к. нет никакой гарантии, что сообщение refreshSystemLogMessagesInterval придёт сразу после инициализации компонента
      formatTime();
    }

    /**
     * Форматирование времени сообщения системного лога
     */
    function formatTime() {
      var now = moment();

      if (now.diff(vm.message.created, 'minutes') == 0) {
        vm.formattedTime = $translate.instant('systemLog.dateFormat.minutesAgo', { count: 1 });
      } else if (now.diff(vm.message.created, 'minutes') < 30) {
        vm.formattedTime = $translate.instant('systemLog.dateFormat.minutesAgo', {
          count: now.diff(vm.message.created, 'minutes'),
        });
      } else if (now.isSame(vm.message.created, 'day')) {
        vm.formattedTime = vm.message.created.format(TIME_FORMATS[LANGUAGE].exactTime);
      } else if (now.isSame(vm.message.created, 'year')) {
        vm.formattedTime = vm.message.created.format(TIME_FORMATS[LANGUAGE].isLastDay);
      } else {
        vm.formattedTime = vm.message.created.format(TIME_FORMATS[LANGUAGE].isLastYear);
      }
    }

    /**
     * Получение форматов даты в заивисимости от выбранного языка
     */
    function getTimeFormats() {
      var timeFormats = {};

      timeFormats.ru = {
        exactTime: 'H:mm',
        isLastDay: 'D MMM',
        isLastYear: 'D MMM YYYY',
      };

      timeFormats.en = {
        exactTime: 'h:mm A',
        isLastDay: 'MMM D',
        isLastYear: 'MMM D, YYYY',
      };

      return timeFormats;
    }

    /**
     * Открытие чата
     */
    function openChat() {
      carrotquestHelper.open();
    }

    /**
     * Трек клика на ссылку скачивания экспорта
     */
    function trackClickOnDownloadExportLink() {
      carrotquestHelper.track('Панель оповещений - кликнул на ссылку "Скачать экспорт"', { App: vm.currentApp.name });
    }

    /**
     * Трек клика на ссылку скачивания экспорта
     */
    function trackClickOnShowStatistics() {
      carrotquestHelper.track('Панель оповещений - кликнул на ссылку "Статистика ручной рассылки"', {
        App: vm.currentApp.name,
      });
    }
  }
})();

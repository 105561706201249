import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AMOCRM_NOTE_MAX_LENGTH } from '@http/integration/integrations/amo/constants/amocrm-integration.constants';
import { AmocrmIntegrationExternal } from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { AmocrmLeadTag } from '@http/integration/integrations/amo/types/amocrm-integration.types';

export type AmocrmNoteEditorModalParams = {
  integration: AmocrmIntegrationExternal;
  integrationSource: 'bot' | 'message'; // К какой сущности подключается интеграция
};

@Component({
  selector: 'cq-amocrm-modal-lead-settings',
  templateUrl: './amocrm-modal-lead-settings.component.html',
  styleUrls: ['./amocrm-modal-lead-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmModalLeadSettingsComponent {
  @Input()
  set note(value: string) {
    this.pipelineNoteControl.setValue(value);
  }

  /** Список тегов сделки AmoCRM */
  @Input()
  set leadTags(value: AmocrmLeadTag[]) {
    this.controlLeadTags.setValue(value);
  }

  @Input()
  modalParams?: AmocrmNoteEditorModalParams;

  /** TODO: Удалить как переделаются автосообщения, нужна чтоб закрывтаь ajs модалку и прокидывать созданную интеграцию */
  @Input()
  ajsModalInstance?: any;

  /** Контрол для работы с тегами сделки в AmoCRM */
  readonly controlLeadTags = new UntypedFormControl(null, []);

  readonly pipelineNoteControl = new UntypedFormControl(null, [Validators.maxLength(AMOCRM_NOTE_MAX_LENGTH)]);

  /** Получение ID интеграции */
  get integrationId(): string {
    return this.modalParams?.integration?.id ?? '';
  }

  constructor(private readonly activeModal: NgbActiveModal) {}

  closeModal(value: { comment: string; leadTags: AmocrmLeadTag[] }) {
    this.ajsModalInstance ? this.ajsModalInstance.close(value) : this.activeModal.close(value);
  }

  dismissModal() {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.activeModal.dismiss();
  }

  done() {
    if (!this.pipelineNoteControl.valid) {
      return;
    }

    this.closeModal({
      comment: this.pipelineNoteControl.value,
      leadTags: this.controlLeadTags.value,
    });
  }

  /** Настраивается ли компонент на странице с чат-ботом */
  isChatBot(): boolean {
    return this.modalParams?.integrationSource === 'bot';
  }
}

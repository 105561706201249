<ng-container *transloco="let t; read: 'limitReachedModalModalComponent'">
  <div class="modal-header">
    <h3
      class="modal-title"
      [innerHTML]="'myApp.knowledgeBase.limitReachedModal.heading' | transloco"
    ></h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="margin-bottom-30">
      {{
        'myApp.knowledgeBase.limitReachedModal.description.withCapabilities'
          | transloco: { countLimit: ARTICLES_COUNT_LIMIT }
      }}
    </div>
    <div class="row margin-bottom-20">
      <div class="col-6 flex">
        <i class="cqi-sm cqi-check text-primary margin-right-10"></i>
        <span [innerHTML]="'myApp.knowledgeBase.limitReachedModal.features.unlimitedArticles' | transloco"></span>
      </div>
      <div class="col-6 flex">
        <i class="cqi-sm cqi-check text-primary margin-right-10"></i>
        <span>{{
          'myApp.knowledgeBase.limitReachedModal.features.whiteLabel' | transloco: { projectName: PROJECT_NAME }
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6 flex">
        <i class="cqi-sm cqi-check text-primary margin-right-10"></i>
        <span [innerHTML]="'myApp.knowledgeBase.limitReachedModal.features.analiticsCounters' | transloco"></span>
      </div>
      <div class="col-6 flex">
        <i class="cqi-sm cqi-check text-primary margin-right-10"></i>
        <span [innerHTML]="'myApp.knowledgeBase.limitReachedModal.features.indexed' | transloco"></span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-primary"
      (click)="activeModal.close()"
      [innerHTML]="'myApp.knowledgeBase.limitReachedModal.selectPlan' | transloco"
    ></button>
  </div>
</ng-container>

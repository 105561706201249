import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { WhatsAppTemplateService } from '@http/whats-app-template/services/whats-app-template.service';
import { ParsedWhatsAppTemplate } from '@http/whats-app-template/types/whats-app-template.types';
import { WhatsAppEdnaTemplate } from '@http/whats-app-template/types/whats-app-templates.types';
import { SendingWhatsAppTemplateComponent } from '@panel/app/partials/modals/sending-whats-app-template/sending-whats-app-template.component';

@Component({
  selector: 'cq-whats-app-templates-popover[integrationId]',
  templateUrl: './whats-app-templates-popover.component.html',
  styleUrls: ['./whats-app-templates-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsAppTemplatesPopoverComponent implements OnInit {
  /** Активирована или нет тёмная тема */
  @Input() darkTheme: boolean = false;

  /** ID интеграции WhatsApp Edna */
  @Input() integrationId!: string | null;

  /** Отправка реплики с шаблоном WhatsApp */
  @Output() sendReply: EventEmitter<ParsedWhatsAppTemplate> = new EventEmitter<ParsedWhatsAppTemplate>();

  /** Список отфильтрованных шаблонов WhatsApp */
  filteredTemplates$: BehaviorSubject<WhatsAppEdnaTemplate[]> = new BehaviorSubject<WhatsAppEdnaTemplate[]>([]);

  /** Флаг ошибки загрузки данных */
  isApiError: boolean = false;

  /** Флаг загрузки данных */
  isDataLoading: boolean = true;

  /** Поисковая фраза */
  searchPhrase: UntypedFormControl = new UntypedFormControl(null);

  /** Список шаблонов WhatsApp */
  templates: WhatsAppEdnaTemplate[] = [];

  constructor(
    @Inject(NgbModal) private readonly modalService: NgbModal,
    private readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
    private readonly whatsAppTemplateService: WhatsAppTemplateService,
  ) {}

  ngOnInit(): void {
    if (this.integrationId) {
      this.whatsAppTemplateService
        .getList(this.integrationId)
        .pipe(
          finalize(() => {
            this.isDataLoading = false;
            this.cdr.markForCheck();
          }),
        )
        .subscribe(
          (templates: WhatsAppEdnaTemplate[]) => {
            this.templates = templates;
            this.filteredTemplates$.next(templates);
          },
          () => {
            this.isApiError = true;
          },
        );
    }

    // Подписываемся на изменение значения searchPhrase, чтобы фильтровать список шаблонов по названию или тексту
    // шаблона
    this.searchPhrase.valueChanges
      .pipe(
        map((newValue) => {
          return !newValue
            ? this.templates
            : this.templates.filter((template) => {
                return (
                  template.name.toLowerCase().includes(newValue.toLowerCase()) ||
                  template.content.text.toLowerCase().includes(newValue.toLowerCase())
                );
              });
        }),
      )
      .subscribe((filteredTemplates) => this.filteredTemplates$.next(filteredTemplates));
  }

  /** Ссылка на шаблоны в ЛК edna */
  get ednaProfileLinkTranslate(): string {
    return this.translocoService.translate('whatsAppTemplatesPopover.zeroData.goToEdnaProfileLink.href');
  }

  /**
   * Колбек при выборе шаблона
   *
   * @param template Шаблон WhatsApp
   */
  openSendingWhatsAppModal(template: WhatsAppEdnaTemplate): void {
    const modal = this.modalService.open(SendingWhatsAppTemplateComponent, {
      centered: true,
      windowClass: this.darkTheme ? 'dark-theme' : '',
    });
    const componentInstance = modal.componentInstance as SendingWhatsAppTemplateComponent;

    componentInstance.template = template;

    modal.result
      .then((reply: ParsedWhatsAppTemplate) => {
        this.sendReply.emit(reply);
      })
      .catch(() => {});
  }
}

<ng-container *transloco="let t; read: 'blockHeaderComponent'">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <i
        class="cqi-sm mr-5"
        [ngClass]="iconsMap[blockType]"
      ></i>
      {{ t('blockTypeHeading.' + blockType) }}
    </div>

    <div
      class="inline-block"
      ngbDropdown
    >
      <button
        class="btn btn-borderless-primary"
        type="button"
        ngbDropdownToggle
      >
        <i class="btn-icon cqi-sm cqi-ellipsis"></i>
      </button>
      <div
        class="dropdown-menu-right"
        ngbDropdownMenu
      >
        <!--Переместить выше-->
        <button
          *ngIf="index !== 0"
          class="dropdown-item"
          type="button"
          (click)="moveUp.emit()"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-arrow-up"></i>
          {{ t('actionsDropdown.up') }}
        </button>

        <!--Переместить ниже-->
        <button
          *ngIf="lastIndex !== index"
          class="dropdown-item"
          type="button"
          (click)="moveDown.emit()"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-arrow-down"></i>
          {{ t('actionsDropdown.down') }}
        </button>

        <!--Дублировать-->
        <button
          class="dropdown-item"
          type="button"
          [disabled]="isDuplicateDisabled"
          (click)="!isDuplicateDisabled && duplicate.emit()"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-copy"></i>
          {{ t('actionsDropdown.copy') }}
        </button>

        <!--Удалить-->
        <button
          class="dropdown-item"
          type="button"
          (click)="delete.emit()"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-trash"></i>
          {{ t('actionsDropdown.delete') }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

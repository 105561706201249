(function () {
  'use strict';

  angular.module('myApp.emailTemplates').component('cqMessageEmailTemplate', cqMessageEmailTemplate());

  function cqMessageEmailTemplate() {
    return {
      bindings: {
        currentApp: '=', // приложение
        messageSender: '=', // отправитель письма
      }, //controller: 'CqMessageEmailTemplateController',
      controllerAs: 'vm',
      template: require('./message-email-template.html'),
    };
  }
})();

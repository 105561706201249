import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CarrotMessengerCollapsedFrameModule } from '@panel/app/partials/message-preview/previews/popup-chat/elements/carrot-messenger-collapsed-frame/carrot-messenger-collapsed-frame.module';
import { CarrotNotificationFrameModule } from '@panel/app/partials/message-preview/previews/popup-chat/elements/carrot-notification-frame/carrot-notification-frame.module';

import { MessagePreviewPopupChatComponent } from './message-preview-popup-chat.component';

@NgModule({
  declarations: [MessagePreviewPopupChatComponent],
  exports: [MessagePreviewPopupChatComponent],
  imports: [CarrotMessengerCollapsedFrameModule, CarrotNotificationFrameModule, CommonModule],
})
export class MessagePreviewPopupChatModule {}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';

import { OneTimeFlagService } from '@panel/app/services';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';

@Component({
  selector: 'cq-wa-chat-icon-modal',
  templateUrl: './wa-chat-icon-modal.component.html',
  styleUrls: ['./wa-chat-icon-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaChatIconModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private oneTimeFlagService: OneTimeFlagService,
    private uiRouter: UIRouter,
  ) {}

  ngOnInit() {
    this.oneTimeFlagService.set(ONE_TIME_FLAGS.WHATS_APP_INTEGRATION_ICON_HINT_MODAL);
  }

  goToChatSettings() {
    this.uiRouter.stateService.go('app.content.settings.general', { tab: 'chatSettings' });
    this.activeModal.close();
  }
}

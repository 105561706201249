import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment/moment';

@Component({
  selector: 'cq-datepicker-page',
  templateUrl: './datepicker-page.component.html',
  styleUrls: ['./datepicker-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerPageComponent {
  datepicker1: moment.Moment = moment().startOf('day');
  datepicker2: moment.Moment = moment().startOf('day');
  datepicker3: moment.Moment = moment();
}

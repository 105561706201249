import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UserSegment } from '@http/user/types/user.type';
import { LS_UNCOLLAPSED_USER_CARD_BLOCKS } from '@panel/app/shared/constants/localstorage.keys';

@Component({
  selector: 'cq-user-segments[userId][userSegments]',
  templateUrl: './user-segments.component.html',
  styleUrls: ['./user-segments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSegmentsComponent implements OnInit {
  /** ИД пользователя */
  @Input()
  userId: string = '';

  /** Сегменты пользователя */
  @Input()
  userSegments!: UserSegment[];

  /** Колбек на смену состояния коллапса */
  @Output()
  collapseStateChange: EventEmitter<string> = new EventEmitter();

  /** Состояние коллапса */
  isCollapsed: boolean = true;

  /** Имя для localeStorage */
  userSegmentsLSName = 'user_segments';

  ngOnInit(): void {
    if (localStorage.getItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS)?.includes(this.userSegmentsLSName)) {
      this.isCollapsed = false;
    }
  }
}

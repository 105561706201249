<ng-container *transloco="let t">
  <div class="row m-n10">
    <div
      *ngFor="let integration of INTEGRATIONS_HEADLINERS; let i = index"
      class="card col align-items-center p-20 m-10 cursor-pointer"
      [attr.data-test]="'to-integration-page-div-' + i"
      (click)="toIntegrationPage(integration)"
    >
      <cq-integration-icon
        class="integration-icon"
        [type]="integration"
      ></cq-integration-icon>
      <div>{{ t('models.integration.types.' + integration + '.name') }}</div>
    </div>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./trigger-chain-message-preview.controller');

  angular
    .module('myApp.triggerChains.messagePreview')
    .component('cqTriggerChainMessagePreview', cqTriggerChainMessagePreview());

  function cqTriggerChainMessagePreview() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        currentMessage: '=', // Сообщение из триггерной цепочки
        pushSettings: '=', // Настройки пушей
      },
      controller: 'CqTriggerChainMessagePreviewController',
      controllerAs: 'vm',
      template: require('./trigger-chain-message-preview.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cq-static-progressbar',
  templateUrl: './static-progressbar.component.html',
  styleUrls: ['./static-progressbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticProgressbarComponent implements OnInit {
  /** Максимальное значение */
  @Input() max?: number;
  /** Тип прогресс-бара */
  @Input() type?: string;
  /** Текущее значение */
  @Input() value!: number;

  protected DEFAULT_MAX: number = 100;

  protected percent: any;
  protected title: any;

  constructor() {}

  ngOnInit(): void {
    this.max = this.max === 0 || this.max === undefined ? this.DEFAULT_MAX : this.max;
    this.percent = +((100 * this.value) / this.max).toFixed(2);
    this.title = this.title !== undefined ? this.title : 'progressbar';
    this.type = this.type || 'primary';
  }
}

(function () {
  'use strict';

  angular.module('myApp.trackMaster').component('cqTrackMasterModalInput', cqTrackMasterModalInput());

  function cqTrackMasterModalInput() {
    return {
      bindings: {
        autoEvent: '=', // Автособытие
        properties: '=', // Свойства
      },
      controller: angular.noop,
      controllerAs: 'vm',
      template: require('./track-master-input.html'),
    };
  }
})();

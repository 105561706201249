(function () {
  'use strict';

  angular
    .module('myApp.completionRegistration')
    .controller('cqCompletionRegistrationHeaderController', cqCompletionRegistrationHeaderController);

  function cqCompletionRegistrationHeaderController(LANDING_URL, carrotquestHelper, l10nHelper) {
    let vm = this;
    vm.$onInit = init;

    function init() {
      vm.LANDING_URL = LANDING_URL;
      vm.logoPath = getLogoPath();
      vm.onClickLogo = onClickLogo;
    }

    /**
     * Получение пути до логотипа
     *
     * @return {string}
     */
    function getLogoPath() {
      return l10nHelper.isUsCountry()
        ? 'assets/img/en/questions/dashly-logo.svg'
        : 'assets/img/ru/questions/carrot-quest-logo.svg';
    }

    /** Обработчик клика по логотипу */
    function onClickLogo() {
      trackLogoClick();
    }

    /** Трек клика на логотип */
    function trackLogoClick() {
      carrotquestHelper.track('Регистрация - клик на логотип');
    }
  }
})();

import { generate } from 'short-uuid';

import { CHAT_BOT_ACTIONS_TYPES, CHAT_BOT_ACTIONS_TYPES_BY_IS_REPLY } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { cloneObjectMutable } from '@panel/app/shared/functions/clone-object-mutable.function';
import { Immutable } from '@panel/app/shared/types/immutable.type';

export class DefaultActionHelper {
  /**
   * Действие ветки по умолчанию
   */
  private static readonly DEFAULT_ACTION: Immutable<Omit<ChatBotAction, 'type'>> = {
    id: undefined,
    linkId: '',
    active: false,
    isReplyAction: false,
    bodyJson: {},
    body: '', // TEXT|channel_id|operator_id|tag_id|conversation_tag,
    attachments: [], // для типа file, сначала создаётся аттач, потом привязывается к ветке
    currentBranchLinkId: '',
    nextBranchId: null, // ссылка на следующую ветку, для типа BUTTON
    nextBranchLinkId: null, // ссылка на следующую ветку, для типа BUTTON
    nextBranch: null, // Следующая ветка NOTE используется только на фронте
    isInvalid: false, // Не валидность действия NOTE используется только на фронте
    keyName: '', // Название события|свойства
    order: 0,
  };

  /**
   * Назначение ветки на действия
   *
   * @param action - Действие, на которое нужно назначить ветку
   * @param branch - Ветка, которую нужно назначить на действие
   */
  static assignBranchToAction(action: ChatBotAction, branch: ChatBotBranch): ChatBotAction {
    action.nextBranch = branch;
    action.nextBranchLinkId = branch.linkId;

    return action;
  }

  /**
   * Создание нового действия "Текст"
   * @param text
   */
  static createTextAction(text: string): ChatBotAction {
    const textAction = this.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.TEXT);
    textAction.body = text;
    return textAction;
  }

  /**
   * Создание нового действия "Кнопка"
   */
  static createButtonAction(buttonText: string, nextBranch?: ChatBotBranch) {
    const buttonAction = this.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.BUTTON);
    buttonAction.body = buttonText;
    if (nextBranch) {
      this.assignBranchToAction(buttonAction, nextBranch);
    }
    return buttonAction;
  }

  /**
   * Создание нового действия "Поле ввода"
   */
  static createPropertyFieldAction(nextBranch?: ChatBotBranch) {
    const propertyFieldAction = this.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD);
    if (nextBranch) {
      this.assignBranchToAction(propertyFieldAction, nextBranch);
    }
    return propertyFieldAction;
  }

  /**
   * Создание нового действия "Условие"
   */
  static createConditionAction(
    type: CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION | CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION,
    nextBranch: ChatBotBranch,
  ) {
    const conditionAction = this.getDefaultAction(type);
    this.assignBranchToAction(conditionAction, nextBranch);
    return conditionAction;
  }

  /**
   * Получение действия/сообщения ветки по умолчанию
   */
  static getDefaultAction(type: CHAT_BOT_ACTIONS_TYPES): ChatBotAction {
    const defaultAction: ChatBotAction = Object.assign({}, cloneObjectMutable(this.DEFAULT_ACTION), { type });

    defaultAction.linkId = generate();
    defaultAction.type = type;
    defaultAction.isReplyAction = CHAT_BOT_ACTIONS_TYPES_BY_IS_REPLY[type];

    return defaultAction;
  }
}

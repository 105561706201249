/**
 * Компонент для отображения кролика (или любой другой картинки) и текста zero-data
 */
(function () {
  'use strict';

  angular.module('myApp.zeroData').component('cqZeroData', cqZeroData());

  function cqZeroData() {
    return {
      bindings: {
        src: '@?', // вместо стандартной картинки возможно вставить кастомную
      },
      controller: angular.noop,
      controllerAs: 'vm',
      template: require('./zero-data.html'),
      transclude: true,
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Channel } from '@http/channel/channel.model';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';

@Component({
  selector: 'cq-branch-action-channel[actionForm]',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionChannelComponent {
  @Input() actionForm!: BaseBotActionForm;

  constructor(public readonly state: StateService) {}

  channelSelectTrackFn(channel: Channel): string | null {
    return channel.id;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AiBotAddressForm } from '@http/ai-data/internal-types';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-address-form-control',
  templateUrl: './address-form-control.component.html',
  styleUrls: ['./address-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormControlComponent extends AbsCVAFormControlBasedComponent<AiBotAddressForm> {
  readonly control: FormControl<AiBotAddressForm> = new FormControl<AiBotAddressForm>(AiBotAddressForm.NEUTRAL, {
    nonNullable: true,
    validators: [Validators.required],
  });

  AiBotAddressForm = AiBotAddressForm;

  constructor() {
    super();
  }
}

<h2>Переключатели новый Angular</h2>
<cq-checkbox-control
  class="margin-top-10 margin-bottom-10"
  [(ngModel)]="switch.disabled"
>
  disable
</cq-checkbox-control>
<div class="row">
  <div class="col-4">
    <div class="flex flex-col">
      <h4>Check switch</h4>
      <cq-switch-new
        [(ngModel)]="switch.value"
        [disabled]="switch.disabled"
        goal="check"
      ></cq-switch-new>

      <cq-switch-new
        [(ngModel)]="switch.value"
        [disabled]="switch.disabled"
        [textPosition]="'right'"
        goal="check"
      >
        <ng-template #trueText>
          <span>Вкл</span>
        </ng-template>
        <ng-template #falseText>
          <span>Выкл</span>
        </ng-template>
      </cq-switch-new>

      <cq-switch-new
        [(ngModel)]="switch.value"
        [disabled]="switch.disabled"
        [textPosition]="'left'"
        goal="check"
      >
        <ng-template #trueText>
          <span class="font-weight-bold">Вкл</span>
        </ng-template>
        <ng-template #falseText>
          <span class="font-italic">Выкл</span>
        </ng-template>
      </cq-switch-new>

      <div class="margin-top-5">
        <cq-switch-new
          [(ngModel)]="switch.value"
          goal="theme"
        ></cq-switch-new>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="flex flex-col">
      <h4>Enable switch</h4>
      <cq-switch-new
        [(ngModel)]="switch.value"
        [disabled]="switch.disabled"
        goal="enable"
      ></cq-switch-new>

      <cq-switch-new
        [(ngModel)]="switch.value"
        [disabled]="switch.disabled"
        [textPosition]="'right'"
        goal="enable"
      >
        <ng-template #trueText>
          <span>Вкл</span>
        </ng-template>
        <ng-template #falseText>
          <span>Выкл</span>
        </ng-template>
      </cq-switch-new>

      <cq-switch-new
        [(ngModel)]="switch.value"
        [disabled]="switch.disabled"
        goal="enable"
        textPosition="left"
      >
        <ng-template #trueText>
          <span class="text-success">Вкл</span>
        </ng-template>
        <ng-template #falseText>
          <span class="text-danger">Выкл</span>
        </ng-template>
      </cq-switch-new>

      <div>
        <h5 class="no-margin-bottom margin-top-10">Forms support</h5>
        <div
          class="d-inline-block"
          [cqShowError]="switch.formSwitch"
        >
          <cq-switch-new
            [formControl]="switch.formSwitch"
            goal="enable"
          ></cq-switch-new>

          <cq-validation-messages [control]="switch.formSwitch">
            <cq-validation-message errorName="required">Надо включить!!</cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4">
    <h4>Switcher</h4>
    <cq-switcher
      [(ngModel)]="switcher.value"
      [disabled]="switch.disabled"
      [leftValue]="switcher.leftValue"
      [rightValue]="switcher.rightValue"
    >
      <ng-template #leftText>
        <span>Биба</span>
      </ng-template>
      <ng-template #rightText>Боба</ng-template>
    </cq-switcher>
    <pre
      class="margin-top-10"
      [textContent]="switcher.value | json"
    ></pre>
  </div>
</div>

(function () {
  'use strict';

  require('./switch.controller');

  angular.module('myApp.directives.switch').directive('cqSwitch', cqSwitch);

  function cqSwitch() {
    return {
      restrict: 'E',
      require: {
        ngModelController: 'ngModel',
      },
      scope: {
        ngModel: '=',
        name: '@?', // имя, которое будет назначено input в атрибут name
        goal: '@?', // цель, которую выполняет свитч (см. goals в контроллере)
        withText: '@?', // текст в свитче (см. setText)
        textPosition: '@?', // позиция текста (см. textPositions)
        ngChange: '&', // функция, выполняемая при нажатии на свитч (см. link)
        ngTrueValue: '@?', // работают так же, как и ангуляровские атрибуты для input[checkbox]
        ngFalseValue: '@?',
        disabled: '=', // включен свитч или нет
        // Эти свойства задаются атрибутами без значений (т.е. это Boolean атрибутами) и обрабатываются в контроллере
        // small размер свитча, если задан - будет маленький свитч
        // light цвет текста, если задан - текст будет белым
        // bold жирность текста, если задан - текст будет жирным
      },
      controller: 'CqSwitchController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
      template: getTemplate,
    };

    function link(scope, iElement, iAttrs, controller) {
      // вместо прямого использования ng-change в темплейте директивы необходимо использовать watch, т.к. функция, переданная в директиву в параметр ngChange
      // и повешанная в темплейте на ng-change будет вызываться ДО (!!!) передачи изменения в ngModel, т.е. ngChange будет выполняться со старым значением модели
      // есть и другие решения этой проблемы, но было принято решение использовать $watch
      scope.$watch('vm.ngModel', function (newValue, oldValue) {
        if (newValue != oldValue) {
          scope.vm.ngChange();
        }
      });
    }

    function getTemplate(tElement, tAttrs) {
      if (tAttrs.goal == 'select') {
        return require('./select.html');
      } else {
        return require('./basic.html');
      }
    }
  }
})();

<div class="carrot-frame-body feature_start-screen flex align-items-end">
  <button
    id="notification-close"
    class="btn btn-text-primary"
  >
    <span class="close-icon close-icon_notification"></span>
  </button>
  <div
    id="notification-container"
    class="notice-wrap flex align-items-start"
  >
    <div
      id="operator-container"
      class="flex flex-grow overflow-hidden"
    >
      <div
        id="avatar-container"
        class="flex"
        [style]="{
          'background-color': app.settings.messenger_collapsed_color,
          'border-radius': '9999px'
        }"
      >
        <img
          id="operator-avatar"
          [src]="getSenderAvatarPath()"
          [style]="{ 'border-radius': '9999px' }"
          alt=""
        />
      </div>
    </div>

    <div
      id="notification-content"
      class="notice-wrap__inner flex flex-col cursor-pointer"
    >
      <div
        id="notification-message"
        class="scroll-a"
        [style]="{
          'scrollbar-color':
            'rgba(' +
            getRgbFromHex(app.settings.messenger_collapsed_color) +
            ', 0.4) rgba(' +
            getRgbFromHex(app.settings.messenger_collapsed_color) +
            ', 0)'
        }"
      >
        <div class="flex notice-item start-part">
          <div
            data-id="1729494956617238268"
            class="notice-item__inner"
          >
            <div class="notice-item__height-indicator">
              <span
                class="part-text"
                [innerHTML]="content"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { ParamEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/components/param-editor/param-editor.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { HeadersEditorComponent } from './headers-editor.component';

@NgModule({
  declarations: [HeadersEditorComponent],
  exports: [HeadersEditorComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, ParamEditorModule, SharedModule],
})
export class HeadersEditorModule {}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import sortBy from 'lodash-es/sortBy';

import { PREINSTALLED_PROPERTIES } from '@http/integration/integrations/email-notification/constants/email-notification-integration.constants';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { App } from '@http/app/app.model';
import { Properties, UserProperty } from '@http/property/property.model';

@Component({
  selector: 'cq-email-notification-user-props[control][currentApp][properties]',
  templateUrl: './user-props.component.html',
  styleUrls: ['./user-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationUserPropsComponent implements OnInit {
  /** Control для настройки свойств пользователя, которые отправятся в уведомлении */
  @Input() control!: UntypedFormControl;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Список свойств и событий, которые есть в App'е */
  @Input() properties!: Properties;

  /** Доступ до кастомных свойств пользователей */
  accessToUsersCustomProperties: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  /** Список отфильтрованных свойств */
  filteredAndOrderedUserProperties: UserProperty[] = [];

  constructor(private readonly planFeatureAccessService: PlanFeatureAccessService) {}

  ngOnInit(): void {
    this.accessToUsersCustomProperties = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.USERS_CUSTOM_PROPERTIES,
      this.currentApp,
    );

    this.filteredAndOrderedUserProperties = this.filterAndOrderUserProperties(this.properties.userProps);
  }

  /**
   * Фильтрация пользовательских свойств
   *
   * @param userProperties Массив со свойствами пользователя
   */
  filterAndOrderUserProperties(userProperties: UserProperty[]): UserProperty[] {
    const availableProperties = this.getAvailableProperties(userProperties);
    const orderProperties = ['groupOrder', 'prettyName'];

    return sortBy(availableProperties, orderProperties);
  }

  /**
   * Получение свойств, доступных в тарифе
   *
   * @param userProperties Массив со свойствами пользователя
   */
  getAvailableProperties(userProperties: UserProperty[]): UserProperty[] {
    const hasAccessToCustomProperties = this.accessToUsersCustomProperties.hasAccess;

    // TODO Нужно предусмотреть случай, при котором в селекте были выбраны кастомные свойства, но доступ к ним пропал
    return userProperties.filter((userProp: UserProperty) => {
      return hasAccessToCustomProperties || userProp.groupOrder !== 5;
    });
  }

  /**
   * Не даем удалять свойства имя, телефон, емейл, так как они должны быть указаны всегда
   *
   * @param property Свойство пользователя
   */
  isPropertyNotRemovable(property: UserProperty): boolean {
    const notRemovablePropertyNames = PREINSTALLED_PROPERTIES.filter(
      (preinstalledProperty) => preinstalledProperty.removable === false,
    ).map((preinstalledProperty) => preinstalledProperty.name);

    return notRemovablePropertyNames.includes(property.name);
  }

  /**
   * Track-by функция для списка свойств
   *
   * @param userProp - Свойство пользователя
   */
  userPropsTrackFn(userProp: UserProperty): string {
    return userProp.name;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { emailValidator } from '@panel/app/shared/validators/email/email-validator';

interface EditBillingEmailFormGroup {
  email: FormControl<string>;
}
/** Компонент-модалка для редактирования биллингового емейла */
@Component({
  selector: 'cq-edit-billing-email-modal',
  templateUrl: './edit-billing-email-modal.component.html',
  styleUrls: ['./edit-billing-email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditBillingEmailModalComponent {
  /** Максимальная длина биллингового емейла */
  BILLING_EMAIL_MAX_LENGTH = 70;

  /** Форма редактирования биллингового емейла */
  form: FormGroup<EditBillingEmailFormGroup> = new FormGroup<EditBillingEmailFormGroup>({
    email: new FormControl<string>(this.subscriptionStore$.billing$.getValue()!.customer.email, {
      nonNullable: true,
      validators: [Validators.maxLength(this.BILLING_EMAIL_MAX_LENGTH), Validators.required, emailValidator],
    }),
  });

  /** Флаг выполнения запроса */
  isApiRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public readonly ngbActiveModal: NgbActiveModal, private readonly subscriptionStore$: SubscriptionStore) {}

  /** Обработчик отправки формы */
  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.isApiRequestPerformed.next(true);

    const billing = this.subscriptionStore$.billing$.getValue()!;

    billing
      .changeBillingEmail(this.form.controls.email.value)
      .then((response) => this.ngbActiveModal.close(response))
      .finally(() => this.isApiRequestPerformed.next(false));
  }
}

import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';

import { App } from '@http/app/app.model';
import { EmailNotificationIntegrationExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { EmailRecipient } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { IntegrationAutoMessage } from '@http/integration/types/integration.types';
import { TeamMember } from '@http/team-member/team-member.types';
import { EmailNotificationComponent } from '@panel/app/pages/integrations/content/email-notification/email-notification.component';
import { LeadBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { Properties } from '@http/property/property.model';

@Component({
  selector:
    'cq-email-notification-wrapper[autoMessages][chatBots][currentApp][emailRecipients][integrationExternal][properties][teamMembers]',
  templateUrl: './email-notification-wrapper.component.html',
  styleUrls: ['./email-notification-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationWrapperComponent {
  /** Автосообщения */
  @Input() autoMessages: IntegrationAutoMessage[] = [];
  /** Список чат-ботов, которые используют данную интеграцию */
  @Input() chatBots!: LeadBot[];
  /** Текущее приложение */
  @Input() currentApp!: App;
  /** Все получатели уведомлений в App'е */
  @Input() emailRecipients!: EmailRecipient[];
  /** Текущая или новая интеграция в внешнем формате */
  @Input() integrationExternal!: EmailNotificationIntegrationExternal;
  /** Список свойств и событий пользователей */
  @Input() properties!: Properties;
  /** Список членов команды */
  @Input() teamMembers!: TeamMember[];
  /** Дочерний элемент настройки интеграции */
  @ViewChild(EmailNotificationComponent) emailNotificationComponent!: EmailNotificationComponent;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CHANNEL_TYPES } from '@http/channel/channel.constants';
import { Channel } from '@http/channel/channel.model';

@Component({
  selector: 'cq-channel[channel]',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelComponent {
  /** Возможность активировать канал (например, в списке диалогов) */
  @Input()
  canActivate: boolean = false;

  /** Канал */
  @Input()
  channel!: Channel;

  /** Активен ли сейчас канал */
  @Input()
  isActive: boolean = false;

  /** Активен ли сейчас канал */
  @Input()
  mutedChannels!: string[];

  /** Количество неразобранных диалогов */
  @Input()
  notAssignedCount?: number;

  /** Количество неотвеченных диалогов */
  @Input()
  notReadCount?: number;

  get channelTooltip(): string {
    switch (this.channel.type) {
      case CHANNEL_TYPES.YANDEX_DIALOGS:
        return `Сервис Яндекс.Диалоги прекратил работу с 16 августа 2021 года`;
      default:
        return this.channel.name;
    }
  }

  /** Показывать ли тултип у канала */
  get isShowTooltip(): boolean {
    return this.canActivate;
  }
}

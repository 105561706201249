/**
 * Определитель браузера (взято отсюда: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser)
 */
(function () {
  'use strict';

  angular.module('myApp.services.browserDetector').factory('browserDetector', browserDetector);

  function browserDetector($document, $window, BROWSERS, SUPPORTED_BROWSERS_REG_EXP) {
    var currentBrowser;

    return {
      isBrowserSupported: isBrowserSupported,
      isChrome: isChrome,
      isEdge: isEdge,
      isFirefox: isFirefox,
      getBrowser: getBrowser,
      isIE: isIE,
      isOpera: isOpera,
      isSafari: isSafari,
    };

    /**
     * Проверка поддерживается ли браузер нашей админкой
     *
     * @returns {Boolean}
     */
    function isBrowserSupported() {
      return SUPPORTED_BROWSERS_REG_EXP.test($window.navigator.userAgent);
    }

    /**
     * Chrome 1+
     *
     * @returns {Boolean}
     */
    function isChrome() {
      if (!currentBrowser) {
        !!$window.chrome && !!$window.chrome.webstore ? (currentBrowser = BROWSERS.CHROME) : false;
      }
      return currentBrowser == BROWSERS.CHROME;
    }

    /**
     * Edge 20+
     *
     * @returns {Boolean}
     */
    function isEdge() {
      if (!currentBrowser) {
        !isIE() && !!$window.StyleMedia ? (currentBrowser = BROWSERS.EDGE) : false;
      }
      return currentBrowser == BROWSERS.EDGE;
    }

    /**
     * Firefox 1.0+
     *
     * @returns {Boolean}
     */
    function isFirefox() {
      if (!currentBrowser) {
        typeof InstallTrigger !== 'undefined' ? (currentBrowser = BROWSERS.FIREFOX) : false;
      }
      return currentBrowser == BROWSERS.FIREFOX;
    }

    /**
     * Получение браузера
     *
     * @returns {String|Boolean}
     */
    function getBrowser() {
      if (!currentBrowser) {
        // делаем много действий через ИЛИ, чтобы при первом true лишние не делались, упорядочены по популярности (https://www.netmarketshare.com)
        isChrome() || isIE() || isFirefox() || isEdge() || isSafari() || isOpera();
      }
      return currentBrowser;
    }

    /**
     * Internet Explorer 6-11
     *
     * @returns {Boolean}
     */
    function isIE() {
      if (!currentBrowser) {
        /*@cc_on!@*/
        false || !!$document.documentMode ? (currentBrowser = BROWSERS.IE) : false;
      }
      return currentBrowser == BROWSERS.IE;
    }

    /**
     * Opera 8.0+
     *
     * @returns {Boolean}
     */
    function isOpera() {
      if (!currentBrowser) {
        (!!$window.opr && !!opr.addons) || !!$window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
          ? (currentBrowser = BROWSERS.OPERA)
          : false;
      }
      return currentBrowser == BROWSERS.OPERA;
    }

    /**
     * Safari 3.0+
     *
     * @returns {Boolean}
     */
    function isSafari() {
      if (!currentBrowser) {
        Object.prototype.toString.call($window.HTMLElement).indexOf('Constructor') > 0 ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]';
        })(!$window.safari || safari.pushNotification)
          ? (currentBrowser = BROWSERS.SAFARI)
          : false;
      }
      return currentBrowser == BROWSERS.SAFARI;
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { TemplateModule } from '@panel/app/partials/template/template.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ChatBotTemplateComponent } from './chat-bot-template.component';

@NgModule({
  declarations: [ChatBotTemplateComponent],
  exports: [ChatBotTemplateComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, TemplateModule],
  providers: [NgbActiveModal, CarrotquestHelperProvider],
})
export class ChatBotTemplateModule {}

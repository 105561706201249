(function () {
  'use strict';

  require('./code-installed-success.controller');

  angular
    .module('myApp.modals.codeInstalledSuccess')
    .component('cqCodeInstalledSuccessModal', cqCodeInstalledSuccessModal());

  function cqCodeInstalledSuccessModal() {
    return {
      bindings: {
        modalInstance: '=',
      },
      controller: 'CqCodeInstalledSuccessModalController',
      controllerAs: 'vm',
      template: require('./code-installed-success.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Рендер списка тегов
 * Нужен так как у некоторых ползьзователей есть много тегов,
 * а если тегов больше 1000 Ajs начинает люто тормозить
 * TODO этот компонент можно убдет убрать когда на Ajs не надо будет рендерить списки тегов
 */
@Component({
  selector: 'cq-conversation-tag-list',
  templateUrl: './conversation-tag-list.component.html',
  styleUrls: ['./conversation-tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationTagListComponent {
  /** Можно удалять теги */
  @Input()
  canRemove: boolean = true;

  @Input() tags: any[] = [];
  @Input() includeNoTags: boolean = false;
  @Input() activateTagCallback: (tag: any) => void = () => {};
  @Input() removeTagCallback: (tag: any) => void = () => {};

  trackByFn(index: number, tag: any) {
    return tag.tag;
  }
}

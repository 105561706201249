import { ComponentRef, Inject, Injectable, NgZone } from '@angular/core';
import { Viewport } from 'pixi-viewport';
import { bufferTime, delay, fromEvent, merge, mergeMap } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UUID } from 'short-uuid';

import { TriggerChain, TriggerChainStep, TriggerChainStepSendingConditions } from '@http/trigger-chain/internal-types';
import { PIXI_VIEWPORT } from '@panel/app/pages/chat-bot/content/tokens';
import { TcBlockOnboardingOverlayComponent } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/overlays/tc-block-onboarding-overlay/tc-block-onboarding-overlay.component';
import { TcConnectionPointOverlayComponent } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/overlays/tc-connection-point-overlay/tc-connection-point-overlay.component';
import { TcSendingConditionOverlayComponent } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/overlays/tc-sending-condition-overlay/tc-sending-condition-overlay.component';
import { TcTypeActionsOverlayComponent } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/overlays/tc-type-actions-overlay/tc-type-actions-overlay.component';
import {
  TriggerChainOverlayEventService,
  TriggerChainOverlayService,
} from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/services';
import { DestroyService } from '@panel/app/services';
import { CanvasInteractionService } from '@panel/app/services/canvas/common/interaction/canvas-interaction.service';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { BlockViewService } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.service';
import { filterByInteractionViewType } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.helpers';
import { InteractionService } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.service';
import { INTERACTION_ENTITY } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.types';
import { enterZone } from '@panel/app/shared/functions/zone/enter-zone.function';

@Injectable()
export class CanvasOverlayHandlerService {
  constructor(
    private readonly interactionService: InteractionService,
    private readonly destroy$: DestroyService,
    private readonly triggerChainOverlayService: TriggerChainOverlayService,
    private readonly ngZone: NgZone,
    private readonly triggerChainOverlayEventService: TriggerChainOverlayEventService,
    private readonly canvasInteractionService: CanvasInteractionService,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    private readonly blockViewService: BlockViewService,
  ) {}

  initOverlays(chain: TriggerChain) {
    this.initConnectionPointOverlay();
    this.initOnboardingOverlays(chain.steps);
    this.initTriggerChainStepBlockOverlays();
  }

  initOnboardingOverlays(steps: TriggerChainStep[]) {
    const [sendingConditions, firstBlockView] = this.getFirstTwoBlocks(steps);

    if (!firstBlockView) {
      return;
    }

    const addSendingConditionsOverlay = () => {
      const overlay = this.triggerChainOverlayService.addOverlay(
        sendingConditions.graphicContainer,
        TcBlockOnboardingOverlayComponent,
      );
      overlay.instance.id = 'sending-conditions-overlay';
      overlay.changeDetectorRef.detectChanges();
      return overlay;
    };
    const addFirstBlockOverlay = () => {
      if (firstBlockView.graphicContainer.destroyed) {
        return null;
      }

      const overlay = this.triggerChainOverlayService.addOverlay(
        firstBlockView.graphicContainer,
        TcBlockOnboardingOverlayComponent,
      );
      overlay.instance.id = 'first-block-overlay';
      overlay.changeDetectorRef.detectChanges();
      return overlay;
    };

    let sendingConditionsOverlay: ComponentRef<TcBlockOnboardingOverlayComponent> | null =
      addSendingConditionsOverlay();
    let firstBlockOverlay: ComponentRef<TcBlockOnboardingOverlayComponent> | null = addFirstBlockOverlay();

    merge(
      this.canvasInteractionService.zoomed$,
      this.canvasInteractionService.moved$,
      this.interactionService.move$.pipe(
        filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
        filter((event) => {
          return event.view.uuid === sendingConditions.uuid || event.view.uuid === firstBlockView.uuid;
        }),
      ),
    )
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          if (sendingConditionsOverlay) {
            this.triggerChainOverlayService.closeOverlay(sendingConditionsOverlay);
            sendingConditionsOverlay = null;
          }
          if (firstBlockOverlay) {
            this.triggerChainOverlayService.closeOverlay(firstBlockOverlay);
            firstBlockOverlay = null;
          }
        }),
        debounceTime(150),
      )
      .subscribe(() => {
        sendingConditionsOverlay = addSendingConditionsOverlay();
        firstBlockOverlay = addFirstBlockOverlay();
      });
  }

  private initConnectionPointOverlay() {
    const openOverlay$ = this.interactionService.click$.pipe(
      takeUntil(this.destroy$),
      filterByInteractionViewType(INTERACTION_ENTITY.INTERACTIVE_BLOCK_PART),
      filter((event) => event.event.target === event.view.connectionPoint),
      filter((event) => !event.view.hasConnection()),
      enterZone(this.ngZone),
      map(({ view }) => {
        const connectionPoint = view.connectionPoint;
        const overlay = this.triggerChainOverlayService.addOverlay(connectionPoint, TcConnectionPointOverlayComponent);
        overlay.instance.overlaySource = view;
        return overlay;
      }),
    );

    const closeOverlay = <C>(overlay: ComponentRef<C>) => {
      return merge(
        this.triggerChainOverlayEventService.events$.pipe(filter(({ type }) => type === 'connection-point-overlay')),
        this.canvasInteractionService.click$,
        this.canvasInteractionService.moved$,
      ).pipe(
        take(1),
        tap(() => this.triggerChainOverlayService.closeOverlay(overlay)),
      );
    };

    openOverlay$.pipe(switchMap(closeOverlay)).subscribe();
  }

  initTriggerChainStepBlockOverlays(): void {
    const openedStepOverlays = new Set<UUID>();

    const openOverlay$ = merge(
      this.interactionService.mouseOver$,
      this.interactionService.move$.pipe(debounceTime(150)),
      zoomOverBlock$(this).pipe(debounceTime(150)),
    ).pipe(
      filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
      filter(({ view }) => !!view.blockOverlayContainer),
      filter(({ view }) => !openedStepOverlays.has(view.uuid)),
      enterZone(this.ngZone),
      map(({ view }) => {
        let overlay: ComponentRef<TcSendingConditionOverlayComponent | TcTypeActionsOverlayComponent>;

        switch (true) {
          case view.type === 'sending_condition':
            overlay = this.triggerChainOverlayService.addOverlay(
              view.blockOverlayContainer!,
              TcSendingConditionOverlayComponent,
            );
            break;
          default:
            overlay = this.triggerChainOverlayService.addOverlay(
              view.blockOverlayContainer!,
              TcTypeActionsOverlayComponent,
            );
        }

        overlay.instance.overlaySource = view;

        return { uuid: view.uuid, overlay };
      }),
      tap(({ uuid }) => openedStepOverlays.add(uuid)),
    );

    openOverlay$
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(({ uuid, overlay }) => closeOverlay$(uuid, overlay)),
      )
      .subscribe();

    const closeOverlay$ = <C>(overlayBlockUuid: UUID, overlay: ComponentRef<C>) =>
      merge(
        merge(
          mouseoutOverlay$(overlay),
          this.interactionService.mouseOut$.pipe(filterByInteractionViewType(INTERACTION_ENTITY.BLOCK)),
        ).pipe(mergeMap(() => cancelCloseOnCondition$(overlayBlockUuid, overlay))),
        this.interactionService.move$,
        this.canvasInteractionService.moved$,
        this.canvasInteractionService.zoomed$,
        blockDeleted$(overlayBlockUuid),
      ).pipe(
        take(1),
        tap(() => this.triggerChainOverlayService.closeOverlay(overlay)),
        tap(() => openedStepOverlays.delete(overlayBlockUuid)),
      );

    const cancelCloseOnCondition$ = <C>(overlayBlockUuid: UUID, overlay: ComponentRef<C>) =>
      merge(mouseoverBackToTheSameBlock$(overlayBlockUuid), mouseoverOverlay$(overlay)).pipe(
        take(1),
        bufferTime(500),
        filter((cancelEvents) => cancelEvents.length === 0),
      );

    const mouseoverBackToTheSameBlock$ = (uuid: UUID) =>
      this.interactionService.mouseOver$.pipe(
        filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
        filter((event) => event.view.uuid === uuid),
      );

    const mouseoverOverlay$ = <C>(overlay: ComponentRef<C>) => fromEvent(overlay.location.nativeElement, 'mouseover');

    const mouseoutOverlay$ = <C>(overlay: ComponentRef<C>) =>
      fromEvent(overlay.location.nativeElement, 'mouseout').pipe(delay(500));

    const blockDeleted$ = (overlayBlockUuid: UUID) =>
      this.blockViewService.blockChanges$.pipe(
        filter((event) => {
          return event.type === 'remove' && event.view.uuid === overlayBlockUuid;
        }),
      );

    /**
     * Сделали так по странно, чтоб не сбивать порядок чтения кода для оверлея действий
     * (если делать стрелочной функцией, чтоб не терялся контекст, то пришлось бы ставить её в самом начале, и сбился бы порядок чтения)
     */
    function zoomOverBlock$(actualThis: CanvasOverlayHandlerService) {
      return actualThis.interactionService.mouseOver$.pipe(
        mergeMap((mouseover) =>
          actualThis.canvasInteractionService.zoomed$.pipe(
            takeUntil(
              actualThis.interactionService.mouseOut$.pipe(filterByInteractionViewType(INTERACTION_ENTITY.BLOCK)),
            ),
            map(() => mouseover),
          ),
        ),
      );
    }
  }

  private getFirstTwoBlocks(steps: TriggerChainStep[]): [IBlockView, IBlockView | null] {
    const sendingConditionStep = steps.find(
      (step): step is TriggerChainStepSendingConditions => step.type === 'sendingConditions',
    );
    if (!sendingConditionStep) {
      throw new Error('Could not find the sending conditions block');
    }
    const sendingConditionView = this.blockViewService.getViewByUUID(sendingConditionStep.uuid);

    if (!sendingConditionStep.meta.nextStep) {
      return [sendingConditionView, null];
    }

    const firstBlockView = this.blockViewService.getViewByUUID(sendingConditionStep.meta.nextStep);
    return [sendingConditionView, firstBlockView];
  }
}

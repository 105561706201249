import { Addon } from '@http/addon/addon';
import { ADDON_STATUS } from '@http/addon/addon.constants';
import { AddonConstruct } from '@http/addon/addon.types';
import { PlanHelpService } from '@http/plan/help/plan-help.service';
import { PlanDto } from '@http/plan/plan.types';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';

/** Класс для работы с тарифным планом */
export class Plan {
  /** Рекомендованы аддоны */
  attachedAddons: Addon[] = [];

  /** Доступное количество emails */
  availableEmails: number;

  /** Доступное количество уникальных пользователей */
  availableUniqueUsers: number;

  /** Название тарифного плана */
  name: string;

  /** Стоимость перерасхода за email */
  overspendingEmailPrice: number;

  /** Стоимость перерасхода за уникального пользователя */
  overspendingUniqueUserPrice: number;

  /** Архивный ли тарифный план */
  get isArchive(): boolean {
    return this.helpService.isArchive();
  }

  constructor(private readonly helpService: PlanHelpService, private factory: InstanceFactory, dto: PlanDto) {
    this.availableEmails = dto.limits.emails;
    this.availableUniqueUsers = dto.limits.users;
    this.name = dto.invoice_name;
    this.overspendingEmailPrice = this.helpService.getEmailsCostInCaseOfOverspending(dto.limits.email_price);
    this.overspendingUniqueUserPrice = dto.limits.user_price;

    if (dto.attached_addons) {
      const dtos: AddonConstruct[] = dto.attached_addons;
      dtos.map((dto) => {
        dto.status = ADDON_STATUS.DISCONNECTED;
      });
      this.attachedAddons = this.factory.getInstanceAddons(dto.attached_addons);
    }
  }
}

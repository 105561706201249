(function () {
  'use strict';

  angular.module('myApp.integrations').controller('YandexMetricaController', YandexMetricaController);

  function YandexMetricaController($translate, API_OBJECT_NAME, integration) {
    var vm = this;

    vm.$translate = $translate;
    vm.API_OBJECT_NAME = API_OBJECT_NAME;
    vm.integration = integration;
  }
})();

<ng-container *transloco="let t; read: 'daterangepickerInputComponent'">
  <div class="has-feedback">
    <input
      class="form-control"
      [ngbPopover]="daterangepikcerPopover"
      popoverClass="daterangepicker-input-popover"
      [autoClose]="'outside'"
      [ngModel]="getDisplayDate()"
      container="body"
    />
    <span class="form-control-feedback">
      <i class="btn-icon cqi-sm cqi-calendar"></i>
    </span>
  </div>

  <ng-template #daterangepikcerPopover>
    <cq-daterangepicker
      [(ngModel)]="selectedRange"
      (ngModelChange)="onSelectDate()"
      [options]="options"
    >
      <div
        *ngIf="options && options.showApplyButtons"
        slot="applyButtons"
        class="d-flex margin-between-cols-10"
      >
        <button
          class="btn btn-primary"
          (click)="onClickApplyButton()"
          type="button"
        >
          {{ 'general.apply' | transloco }}
        </button>
        <button
          class="btn btn-outline-primary"
          (click)="onClickCancelButton()"
          type="button"
        >
          {{ 'general.cancel' | transloco }}
        </button>
      </div>
    </cq-daterangepicker>
  </ng-template>
</ng-container>

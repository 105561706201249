<ng-container *transloco="let t">
  <div class="flex justify-space-between margin-between-cols-20">
    <nav>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a uiSref="app.content.integrations">{{ t('integrations.heading.breadcrumbs.integrations') }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ integrationTypeName }}
        </li>
      </ol>
    </nav>
  </div>
  <div
    class="card text-white integration-card"
    [style.background-color]="integrationBackground"
    [style.border-color]="integrationBackground"
  >
    <div class="card-body padding-30">
      <div class="row">
        <div class="col-auto">
          <div class="icon-wrap">
            <cq-integration-icon [type]="integrationType"></cq-integration-icon>
          </div>
        </div>
        <div class="col padding-left-10">
          <h1>{{ integrationTypeName }}</h1>
          <div
            class="description margin-bottom-25"
            [innerHTML]="
              t('models.integration.types.' + integrationType + '.description', {
                projectName: projectName,
                integrationTypeName: integrationTypeName
              })
            "
          ></div>
          <ng-container *ngIf="integrationType !== INTEGRATION_TYPES.API">
            <a
              *ngIf="accessToCreateNew.hasAccess"
              class="btn btn-secondary"
              [uiParams]="{ integrationType: integrationType }"
              uiSref="app.content.integrations.details.unconfigured"
              (click)="trackClickToAddIntegrationButton(integrationType)"
              >{{ t('integrationPreview.integrationCard.addIntegrationButton') }}</a
            >
            <cq-access-denial-popover
              *ngIf="!accessToCreateNew.hasAccess"
              [currentApp]="currentApp"
              [denialReason]="accessToCreateNew.denialReason"
              [popoverPlacement]="'bottom-left'"
            >
              <button
                class="btn btn-secondary"
                (click)="trackClickToAddIntegrationButton(integrationType)"
              >
                <cq-plan-capability-coin></cq-plan-capability-coin>
                <span>{{ t('integrationPreview.integrationCard.addIntegrationButton') }}</span>
              </button>
            </cq-access-denial-popover>
          </ng-container>
          <a
            *ngIf="integrationType === INTEGRATION_TYPES.API"
            class="btn btn-secondary"
            [href]="knowledgeBaseLink"
            target="_blank"
            (click)="trackClickToAddIntegrationButton(integrationType)"
            >{{ t('integrationPreview.integrationCard.capabilities.instructionLink') }}</a
          >
        </div>
        <div class="col-auto capabilities no-padding-left no-padding-right">
          <h2 class="margin-left-20">{{ t('integrationPreview.integrationCard.capabilities.header') }}</h2>
          <ul class="margin-bottom-15 padding-between-rows-5">
            <li *ngFor="let capability of capabilities">
              {{ capability }}
            </li>
          </ul>
          <a
            *ngIf="instructionLink"
            class="margin-left-20 text-white"
            [href]="instructionLink"
            target="_blank"
          >
            {{ t('integrationPreview.integrationCard.capabilities.instructionLink') }}
            <i class="cqi-sm cqi-external-link"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="integrations.length > 0"
    class="card"
  >
    <div class="card-body padding-30">
      <h4 class="margin-bottom-25">{{ t('integrationPreview.configuredIntegrations.header') }}</h4>
      <div class="flex flex-wrap padding-between-cols-20">
        <ng-container *ngFor="let integration of integrations; let i = index; trackBy: trackByFn">
          <div class="col-xl-2 col-3 no-padding-left">
            <a
              uiSref="app.content.integrations.details.configured"
              [uiParams]="{ integrationType: integration.type, integrationId: integration.id }"
              class="card padding-15 padding-top-10 padding-right-10 overflow-hidden mini-card"
            >
              <div class="flex align-items-center justify-space-between margin-bottom-10">
                <div
                  class="badge badge-light-success"
                  *ngIf="integration.active"
                >
                  {{ t('integrationPreview.configuredIntegrations.status.enabled') }}
                </div>
                <div
                  class="badge badge-light-warning"
                  *ngIf="!integration.active"
                >
                  {{ t('integrationPreview.configuredIntegrations.status.disabled') }}
                </div>
                <div
                  (click)="$event.preventDefault(); $event.stopPropagation()"
                  ngbDropdown
                  container="body"
                  placement="bottom-right"
                >
                  <button
                    class="btn btn-borderless-primary"
                    ngbDropdownToggle
                  >
                    <i class="btn-icon cqi-sm cqi-ellipsis"></i>
                  </button>
                  <div ngbDropdownMenu>
                    <button
                      *ngIf="!integration.active"
                      ngbDropdownItem
                      (click)="activeIntegrationCallback(integration, i)"
                    >
                      <i
                        *ngIf="accessToIntegrationType.hasAccess; else hasNotPlanCapabilityAccess"
                        class="dropdown-item-icon-left cqi-sm cqi-play-o"
                      ></i>
                      <ng-template #hasNotPlanCapabilityAccess>
                        <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
                      </ng-template>
                      <span>{{ t('integrationPreview.configuredIntegrations.actions.enable') }}</span>
                    </button>
                    <button
                      *ngIf="integration.active"
                      ngbDropdownItem
                      (click)="pauseIntegrationCallback(integration, i)"
                    >
                      <i class="dropdown-item-icon-left cqi-sm cqi-pause"></i>
                      <span>{{ t('integrationPreview.configuredIntegrations.actions.disable') }}</span>
                    </button>
                    <button
                      ngbDropdownItem
                      (click)="removeIntegrationCallback(integration, i)"
                    >
                      <i class="dropdown-item-icon-left cqi-sm cqi-trash"></i>
                      <span>{{ t('integrationPreview.configuredIntegrations.actions.delete') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <h5 class="margin-bottom-10">{{ integration.name }}</h5>
              <div class="small">
                {{ t('integrationPreview.configuredIntegrations.created')
                }}{{ integration.created * 1000 | amDateFormat: 'LL' }}
              </div>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div
    *ngIf="knowledgeBaseLink"
    class="card"
  >
    <div class="card-body padding-30">
      <h4 class="margin-bottom-25">{{ t('integrationPreview.tutorials.header') }}</h4>
      <div class="flex flex-wrap">
        <div class="col-xl-2 col-3 no-padding-left">
          <a
            class="card padding-15 padding-right-10 overflow-hidden flex-grow no-margin-bottom mini-card"
            [href]="knowledgeBaseLink"
            target="_blank"
          >
            <div class="flex justify-space-between margin-bottom-15">
              <div class="flex justify-content-center align-items-center tutorial-icon-square">
                <i class="cqi-md cqi-03x cqi-book-o"></i>
              </div>
              <i class="cqi-sm cqi-external-link"></i>
            </div>
            <h5 class="margin-bottom-10">{{ t('integrationPreview.tutorials.setUp.header') }}</h5>
            <div class="small">{{ t('integrationPreview.tutorials.setUp.description') }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider, L10nHelperProvider, StateServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { QuestionModule } from '@panel/app/pages/questions/question/question.module';
import { QuestionPaginationModule } from '@panel/app/pages/questions/question-pagination/question-pagination.module';
import { QuestionsHeaderModule } from '@panel/app/pages/questions/questions-header/questions-header.module';

import { QuestionsComponent } from './questions.component';

@NgModule({
  declarations: [QuestionsComponent],
  imports: [
    CommonModule,
    NgbPaginationModule,
    QuestionsHeaderModule,
    QuestionModule,
    QuestionPaginationModule,
    TranslocoModule,
  ],
  providers: [CarrotquestHelperProvider, L10nHelperProvider, StateServiceProvider],
})
export class QuestionsModule {}

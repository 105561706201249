import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, map, takeUntil, throttleTime } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';
import { UserProperty } from '@http/property/property.model';

/**
 * Компонент для работы с фильтром столбцов таблицы лидов
 *
 * NOTE:
 *  Рендерит по N элементов, чтобы у пользователей с большим количеством свойств и событий не было тормозов
 */
@Component({
  selector: 'cq-leads-table-column-filter[defaultSelectedUserProperty][userPropsAndEvents][updateSelectedColumns]',
  templateUrl: './leads-table-column-filter.component.html',
  styleUrls: ['./leads-table-column-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class LeadsTableColumnFilterComponent implements OnInit, AfterViewInit {
  /** Выбранное по умолчанию свойство пользователя */
  @Input()
  defaultSelectedUserProperty!: UserProperty;

  /** Пользовательские свойства и события */
  @Input()
  userPropsAndEvents!: any[];

  /** Callback на обновление выбранных столбцов таблицы */
  @Output()
  updateSelectedColumns: EventEmitter<void> = new EventEmitter<void>();

  /** Скроллируемый контейнер */
  @ViewChild('scrollContainer', { read: ViewContainerRef }) scrollContainer!: ViewContainerRef;

  /** Индекс последнего отрендернного элемента */
  lastRenderedItemIndex = 0;

  /** Отрендеренные элементы */
  renderedItems: any[] = [];

  /** Количество элементов для рендеренига за один раз */
  renderItemsNumberAtTime: number = 500;

  /** Поисковая фраза */
  searchPhrase: string = '';

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly destroyService$: DestroyService,
  ) {}

  ngOnInit() {
    this.renderStartingItems();
  }

  ngAfterViewInit() {
    this.initScrollObserver();
  }

  /** Очищает отрендеренные элементы */
  clearRenderedItems(): void {
    this.renderedItems = [];
    this.lastRenderedItemIndex = 0;
    this.changeDetectorRef.markForCheck();
  }

  /** Инициализирует observer за скролом */
  initScrollObserver(): void {
    // Создаёт поток со скролла данного элемента
    fromEvent(this.scrollContainer.element.nativeElement, 'scroll')
      .pipe(
        // Наблюдает пока компонент существует
        takeUntil(this.destroyService$),
        // Тротлит количество срабатываний
        throttleTime(200),
        // Переводит поток только на необходимые нам атрибуты
        map(() => {
          return {
            scrollTop: this.scrollContainer.element.nativeElement.scrollTop,
            clientHeight: this.scrollContainer.element.nativeElement.clientHeight,
            scrollHeight: this.scrollContainer.element.nativeElement.scrollHeight,
          };
        }),
        // Фильтрует приближение к концу скролла
        filter(({ scrollTop, clientHeight, scrollHeight }) => {
          return scrollHeight - (scrollTop + clientHeight) < 5000;
        }),
      )
      .subscribe(() => {
        if (this.searchPhrase) {
          this.renderNextPartFoundItems();
        } else {
          this.renderNextPartItems();
        }
      });
  }

  /** Определяет, показывать ли дефолтное свойство */
  isNeedToShowDefaultProperty(): boolean {
    return this.searchPhrase === '';
  }

  /**
   * Обрабатывает ввод поисковой фразы
   *
   * @param searchPhrase - Поисковая фраза
   */
  onInputSearchPhrase(searchPhrase: string): void {
    this.clearRenderedItems();

    if (searchPhrase) {
      let foundItems = this.searchItems(searchPhrase, this.userPropsAndEvents);
      let items = this.sliceItems(this.lastRenderedItemIndex, this.renderItemsNumberAtTime, foundItems);

      this.renderItems(items);
    } else {
      this.renderStartingItems();
    }
  }

  /**
   * Обрабатывает клик по элементу
   *
   * @param item - Элемент
   * @param event - Событие клика
   */
  onClickItem(item: any, event: MouseEvent): void {
    // HACK: Если не остановить всплытие, dropdown закрывается
    event.stopPropagation();

    this.toggleCheckedProperty(item);

    this.updateSelectedColumns.emit();
  }

  /**
   * Переключает свойство checked
   *
   * @param item - Элемент
   */
  toggleCheckedProperty(item: any): void {
    item.checked = !item.checked;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Рендерит элементы
   *
   * @param items - Элементы, которые необходимо отрендерить
   */
  renderItems(items: any): void {
    this.renderedItems = [...this.renderedItems, ...items];
    this.changeDetectorRef.markForCheck();
  }

  /** Рендерит следующее количество найденных элементов */
  renderNextPartFoundItems(): void {
    let start = this.lastRenderedItemIndex;
    let end = start + this.renderItemsNumberAtTime;

    let foundItems = this.searchItems(this.searchPhrase, this.userPropsAndEvents);

    this.renderItemsFormTo(start, end, foundItems);
  }

  /** Рендерит следующее количество элементов */
  renderNextPartItems(): void {
    let start = this.lastRenderedItemIndex;
    let end = start + this.renderItemsNumberAtTime;

    this.renderItemsFormTo(start, end, this.userPropsAndEvents);
  }

  /** Рендерит стартовое количество элементов */
  renderStartingItems(): void {
    let start = 0;
    let end = this.renderItemsNumberAtTime;

    this.renderItemsFormTo(0, this.renderItemsNumberAtTime, this.userPropsAndEvents);
  }

  /**
   * Рендерит элементы с определённой по определённую позицию
   *
   * @param start - Стартовая позиция
   * @param end - Конечная позиция
   * @param items - Элементы для рендера
   */
  renderItemsFormTo(start: number, end: number, items: any) {
    let itemsForRendering = this.sliceItems(start, end, items);

    this.renderItems(itemsForRendering);

    this.lastRenderedItemIndex = end;
  }

  /**
   * Ищет элементы по поисковой фразе
   *
   * @param searchPhrase - Поисковая фраза
   * @param items - Элементы, среди которых осуществляется поиск
   */
  searchItems(searchPhrase: string, items: any): any {
    let regExp = new RegExp(searchPhrase, 'i');

    return items.filter((i: any) => regExp.test(i.prettyName));
  }

  /**
   * Срезает часть элементов
   *
   * @param start - Стартовая позиция
   * @param end - Конечная позиция
   * @param items - Элементы, с которых делается срез
   */
  sliceItems(start: number, end: number, items: any): any {
    let slicedItems: any = [];

    if (items.length < this.renderItemsNumberAtTime) {
      slicedItems = items;
    } else {
      slicedItems = items.slice(start, end);
    }

    return slicedItems;
  }

  /**
   * Трекает ngFor по свойству name
   *
   * @param index - Индекс
   * @param item - Элемент
   */
  trackBy(index: number, item: any): string {
    return item.name;
  }
}

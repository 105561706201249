/**
 * Контроллер компонента "Сработает на странице"
 */
(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventWorkUrlController', CqAutoEventWorkUrlController);

  function CqAutoEventWorkUrlController(validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.validationHelper = validationHelper;
    }
  }
})();

import { AsyncValidatorFn } from '@angular/forms';
import { of, switchMap } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { UtilsModel } from '@http/utils/utils.model';

/**
 * Дебаунс сделан через делей, потому что на изменение control ангуляр отменяет подписку на прошлый вызов валидатора
 */
export function getAsyncUrlValidatorFn(utilsService: UtilsModel, debounce: number = 500): AsyncValidatorFn {
  return (control) => {
    return of(control.value).pipe(
      delay(debounce),
      switchMap(() => {
        return utilsService.asyncValidate({
          value: control.value,
          validator: 'UrlField',
        });
      }),
      map(({ valid }: { valid: boolean }) => {
        return valid ? null : { invalidUrl: control.value };
      }),
    );
  };
}

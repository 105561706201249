(function () {
  'use strict';

  require('./popup-text-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupTextBlockSettings', cqPopupTextBlockSettings());

  function cqPopupTextBlockSettings() {
    return {
      bindings: {
        messagePartType: '=', // Тип сообщения
        popupBlockParams: '=', // настройки блока поп-апа
        setErrorHandler: '&?', // функция для установки обработчика ошибок
      },
      controller: 'CqPopupTextBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-text-block-settings.html'),
    };
  }
})();

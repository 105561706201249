(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqInsalesModalController', CqInsalesModalController);

  function CqInsalesModalController($state, $translate, toastr, PROJECT_NAME, integrationModel, validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.createOrUpdateIntegration = createOrUpdateIntegration;
      vm.isManualExpanded = angular.isUndefined(vm.resolve.integration.id); // раскрыта ли инструкция
      vm.onCopyCodeSuccess = onCopyCodeSuccess;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.updateStatus = updateStatus;
      vm.toggleManualVisibility = toggleManualVisibility;
      vm.validationHelper = validationHelper;
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function createOrUpdateIntegration(isValid) {
      if (isValid) {
        if (vm.resolve.integration.id) {
          integrationModel.save(vm.resolve.currentApp.id, vm.resolve.integration).then(saveSuccess, saveFail);
        } else {
          integrationModel.create(vm.resolve.currentApp.id, vm.resolve.integration).then(createSuccess, saveFail);
        }
      } else {
        // В случае провала валидации осуществляем скрол к форме, чтобы пользователь увидел невалидные поля
        angular.element('#insalesFormHeading')[0].scrollIntoView();
      }

      function createSuccess(integration) {
        var params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        var transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.insalesModal.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.insalesModal.toasts.saved'));
      }

      function saveFail() {
        vm.addError = true;
      }
    }

    /**
     * Тост при успешном копировании ссылки
     */
    function onCopyCodeSuccess() {
      toastr.success($translate.instant('integrations.insalesModal.toasts.codeCopied'));
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }

    function updateStatus() {
      if (vm.updating) {
        return;
      }

      vm.updating = true;
      if (vm.resolve.integration.id) {
        integrationModel.get(vm.resolve.integration.id, vm.resolve.integration.type).then(function (integration) {
          vm.resolve.integration = integration;
          vm.updating = false;
        });
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'userTagsComponent'">
  <div class="no-margin-bottom">
    <div
      (click)="collapse.toggle(); collapseStateChange.emit(userTagsLSName)"
      [attr.aria-expanded]="!isCollapsed"
      class="flex align-items-center cursor-pointer"
      aria-controls="collapse"
      data-test="user-tags-collapse"
    >
      <div class="font-weight-bold flex-grow-1 padding-15">
        <span>{{ t('userTags') }}</span>
        <i
          class="cqi-sm cqi-question-circle margin-left-5"
          [ngbTooltip]="t('tooltip')"
        ></i>
      </div>
      <div class="margin-right-5">{{ userTags.length }}</div>
      <i
        [class]="{ 'cqi-chevron-down': isCollapsed, 'cqi-chevron-up': !isCollapsed }"
        class="btn-icon cqi-sm margin-right-15"
      ></i>
    </div>
    <div
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
    >
      <div class="card-body no-padding-top padding-right-10 padding-bottom-15 padding-left-10">
        <div class="user-tags flex flex-wrap margin-bottom-15 padding-left-5 padding-right-5">
          <ng-container *ngIf="userTags.length; else zeroData">
            <ng-container *ngFor="let tag of userTags">
              <cq-user-tag
                class="text-truncate"
                (remove)="openRemoveTagModal(tag)"
                [canRemove]="true"
                [tag]="tag"
              ></cq-user-tag>
            </ng-container>
          </ng-container>
          <ng-template #zeroData>
            <div class="text-muted padding-left-5">{{ t('zeroData') }}</div>
          </ng-template>
        </div>
        <div class="padding-left-5 padding-right-5">
          <button
            *ngIf="accessToUsersTags.hasAccess"
            class="btn btn btn-secondary full-width"
            type="button"
            (click)="openAddTagModal()"
          >
            <i class="btn-icon-left cqi-sm cqi-plus"></i>
            <span>{{ t('addTagButton') }}</span>
          </button>
          <cq-access-denial-popover
            *ngIf="!accessToUsersTags.hasAccess"
            [currentApp]="currentApp"
            [denialReason]="accessToUsersTags.denialReason"
            [popoverPlacement]="'top-left'"
          >
            <button
              class="btn btn btn-secondary full-width"
              type="button"
            >
              <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
              <span>{{ t('addTagButton') }}</span>
            </button>
          </cq-access-denial-popover>
        </div>
      </div>
    </div>
  </div>
</ng-container>

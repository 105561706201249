import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Channel } from '@http/channel/channel.model';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { AmocrmIntegrationExternal } from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { CalendlyIntegrationExternal } from '@http/integration/integrations/calendly/interfaces/calendly-integration.interface';
import { EmailNotificationIntegrationExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { Tag } from '@http/tag/tag.types';
import { TeamMember } from '@http/team-member/team-member.types';
import { CalendlyEvent } from '@panel/app/pages/integrations/content/calendly/calendly.type';

/**
 * Сервис состояний нужен до тех пор, пока не приедет полноценный state manager (ngRx, etc)
 */
@Injectable()
export class StateService {
  readonly amocrmIntegrationsExternal$: BehaviorSubject<AmocrmIntegrationExternal[]> = new BehaviorSubject<
    AmocrmIntegrationExternal[]
  >([]);
  readonly channels$: BehaviorSubject<Channel[]> = new BehaviorSubject<Channel[]>([]);
  readonly chatBot$ = new BehaviorSubject<ChatBot<CHAT_BOT_TYPE> | null>(null);
  readonly calendlyEventList$ = new BehaviorSubject<CalendlyEvent[]>([]);
  readonly calendlyIntegrationList$ = new BehaviorSubject<CalendlyIntegrationExternal[]>([]);
  /** Список интеграций "Уведомление на Email" во внешнем формате */
  readonly emailNotificationIntegrationsExternal$: BehaviorSubject<EmailNotificationIntegrationExternal[]> =
    new BehaviorSubject<EmailNotificationIntegrationExternal[]>([]);
  readonly tags$: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);
  readonly teamMembers$: BehaviorSubject<TeamMember[]> = new BehaviorSubject<TeamMember[]>([]);
}

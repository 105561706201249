import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-vk-forms-confirm-code[control]',
  templateUrl: './vk-forms-confirm-code.component.html',
  styleUrls: ['./vk-forms-confirm-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsConfirmCodeComponent {
  /** Control для настройки confirm-cod'а интеграции */
  @Input()
  control!: GenericFormControl<string | null>;

  /** Флаг блокировки input'а */
  @Input()
  disabled: boolean = false;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiForModule } from '@taiga-ui/cdk';

import { TagDefaultModule } from '@panel/app/partials/tag-default/tag-default.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';

import { TagsFilterComponent } from './tags-filter.component';

@NgModule({
  declarations: [TagsFilterComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbDropdownModule,
    NgbTooltipModule,
    FormsModule,
    TuiForModule,
    ReactiveFormsModule,
    TagDefaultModule,
    DirectivesModule,
  ],
})
export class TagsFilterModule {}

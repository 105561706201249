/**
 * Контроллер для модального окна с полем ввода
 *
 * При создании модального окна в resolve можно передать modalWindowParams - объект следующего вида:
 *    {String=} heading Текст заголовка модального окна
 *    {String=} body Текст тела модального окна
 *    {String=} confirmButtonClass Класс для кнопки подтвеждения
 *    {String=} confirmButtonText Текст кнопки подтвеждения
 *    {String=} cancelButtonClass Класс для кнопки отмены
 *    {String=} cancelButtonText Текст кнопки отмены
 *    {String=} inputLabel Текст для подписи поля ввода
 *    {String=} inputPlaceholder Текст placeholder'а поля ввода
 *    {String=} inputInitialValue Начальное значение поля ввода
 *    {String=} inputErrorText Текст ошибки required
 */
(function () {
  'use strict';

  angular.module('myApp.modals.prompt').controller('PromptModalController', PromptModalController);

  function PromptModalController($interpolate, $translate, $uibModalInstance, validationHelper, modalWindowParams) {
    var vm = this;

    vm.cancelButtonClass = modalWindowParams.cancelButtonClass || 'btn-outline-primary';
    vm.cancelButtonText = modalWindowParams.cancelButtonText || $translate.instant('general.cancel');
    vm.confirm = confirm;
    vm.confirmButtonClass = modalWindowParams.confirmButtonClass || 'btn-primary';
    vm.confirmButtonText = modalWindowParams.confirmButtonText || $translate.instant('modals.prompt.confirmButtonText');
    vm.inputErrorText = modalWindowParams.inputErrorText || $translate.instant('modals.prompt.inputErrorText');
    vm.inputPatternErrorText =
      modalWindowParams.inputPatternErrorText || $translate.instant('modals.prompt.inputErrorText');
    vm.inputLabel = modalWindowParams.inputLabel;
    vm.inputMaxLength = modalWindowParams.inputMaxLength || '';
    vm.inputMaxLengthErrorText =
      modalWindowParams.inputMaxLengthErrorText || $translate.instant('modals.prompt.inputMaxLengthErrorText');
    vm.inputPlaceholder = modalWindowParams.inputPlaceholder || '';
    vm.inputPatternValidator = modalWindowParams.inputPatternValidator || '';
    vm.modalWindowParams = modalWindowParams;
    vm.text = modalWindowParams.inputInitialValue || ''; // значение поля ввода
    vm.validationHelper = validationHelper;

    init();

    function init() {
      modalWindowParams.heading = modalWindowParams.heading ?? '';
      modalWindowParams.body = modalWindowParams.body ?? '';
    }

    /**
     * Закрытие модалки с подтверждением (снаружи вызовется successCallback)
     *
     * @param isValid
     */
    function confirm(isValid) {
      if (isValid) {
        $uibModalInstance.close(vm.text);
      }
    }
  }
})();

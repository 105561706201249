import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SelectorsPropertiesComponent } from '@panel/app/partials/track-master/selectors-properties/selectors-properties.component';
import { WritePropertyValueModule } from '@panel/app/partials/track-master/write-property-value/write-property-value.module';

@NgModule({
  declarations: [SelectorsPropertiesComponent],
  imports: [CommonModule, TranslocoModule, WritePropertyValueModule, ReactiveFormsModule],
  exports: [SelectorsPropertiesComponent],
  providers: [],
})
export class SelectorsPropertiesModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AutoMessageEmailNotificationComponent } from './auto-message-email-notification.component';

@NgModule({
  declarations: [AutoMessageEmailNotificationComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    TranslocoModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbPopoverModule,
  ],
})
export class AutoMessageEmailNotificationModule {}

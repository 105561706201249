<a
  class="logo-container"
  uiSref="account.content"
>
  <img
    *cqHideInUs
    class="logo carrot-quest"
    src="assets/img/default/logos/carrot-quest.svg"
  />
  <img
    *cqHideInRu
    class="logo dashly"
    src="assets/img/default/logos/dashly.svg"
  />
</a>

<a
  [href]="'header.whatsNew.link' | transloco"
  class="btn btn-secondary position-relative"
  target="_blank"
  (click)="trackClickWhatsNew(); updateLastClickOnWhatsNewTime()"
>
  <span>{{ 'header.whatsNew.buttonText' | transloco }}</span>
  <span
    *ngIf="unreadNewsCount > 0"
    class="badge badge-pill badge-secondary small-counter"
    >{{ unreadNewsCount }}</span
  >
</a>

<a
  class="btn btn-secondary margin-left-auto"
  [uiSref]="'logout'"
  >{{ 'accountHeader.logout' | transloco }}</a
>

(function () {
  'use strict';

  require('./funnels.controller');

  angular.module('myApp.funnels').component('cqFunnels', cqFunnels());

  function cqFunnels() {
    return {
      bindings: {
        billingInfo: '=', // Информация по биллингу
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        funnel: '=', // Воронка
        funnels: '=', // Список воронок
        properties: '=', // Свойства пользователей, типы событий, свойства типов событий
      },
      controller: 'CqFunnelsController',
      controllerAs: 'vm',
      template: require('./funnels.html'),
    };
  }
})();

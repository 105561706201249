(function () {
  'use strict';

  angular
    .module('myApp.completionRegistration')
    .controller(
      'cqCompletionRegistrationFormControlPhoneController',
      cqCompletionRegistrationFormControlPhoneController,
    );

  function cqCompletionRegistrationFormControlPhoneController(l10nHelper, validationHelper) {
    let vm = this;
    vm.$onInit = init;

    /**
     * Максимальное длина номера телефона
     *
     * @type {number}
     */
    const MAX_PHONE_LENGTH = 50;

    /**
     * Регулярное выражение для проверки корректности номера телефона
     *
     * @type {string}
     */
    const PHONE_REG_EXP = '^[^A-zА-я]{5,}';

    function init() {
      vm.getClassForControl = getClassForControl;
      vm.isRequiredPhone = isRequiredPhone();
      vm.MAX_PHONE_LENGTH = MAX_PHONE_LENGTH;
      vm.PHONE_REG_EXP = PHONE_REG_EXP;
    }

    /**
     * Является ли телефон обязательным полем
     *
     * @return {boolean|null}
     */
    function isRequiredPhone() {
      if (l10nHelper.isUsCountry()) {
        return null;
      }

      return vm.isRequiredMode;
    }

    /**
     * Получение класса для контрола
     *
     * @return {object}
     */
    function getClassForControl() {
      return {
        'has-error': isValidPhone(),
      };
    }

    /**
     * Является ли телефон пользователя валидным
     *
     * @return {boolean}
     */
    function isValidPhone() {
      return validationHelper.getValidationResult(vm.completionRegistrationForm, 'phone');
    }
  }
})();

/**
 * Компонент для редактирования вида и содержания поп-апа
 */
(function () {
  'use strict';

  require('./popup-editor.controller');
  require('./templates-popover.html');

  angular.module('myApp.popupEditor').component('cqPopupEditor', cqPopupEditor());

  function cqPopupEditor() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        djangoUser: '=', // текущий пользователь
        messagePart: '=', // вариант сообщения
        properties: '=', // свойства, события, типы событий
        openCreateTemplateModal: '&?', // Открытие модалки создания шаблона
        setErrorHandler: '&?', // функция для установки обработчика ошибок
      },
      controller: 'CqPopupEditorController',
      controllerAs: 'vm',
      template: require('./popup-editor.html'),
    };
  }
})();

import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom } from 'rxjs';

import { ApiCredentialsModel } from '@http/api-credentials/api-credentials.model';
import { App } from '@http/app/app.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { ToDevelopersComponent } from '@panel/app/pages/to-developers/page/to-developers/to-developers.component';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';

export const TO_DEVELOPERS_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.settings.developers',
    url: '/developers',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
    },
    component: ToDevelopersComponent,
    resolve: [
      {
        token: 'apiCredentials',
        deps: [ApiCredentialsModel, 'currentApp', 'planFeatureAccessService'],
        resolveFn: getApiCredentials,
      },
    ],
  },
  // DEPRECATED: Возможно где-то еще остались ссылки на старый url, поэтому делаем redirect с него на developers
  {
    name: 'app.content.settings.credentials',
    url: '/credentials',
    redirectTo: 'app.content.settings.developers',
  },
];

/**
 * Получение API ключей
 *
 * @param apiCredentialsModel
 * @param currentApp
 * @param planFeatureAccessService
 *
 * @returns {Promise}
 */
function getApiCredentials(
  apiCredentialsModel: ApiCredentialsModel,
  currentApp: App,
  planFeatureAccessService: PlanFeatureAccessService,
) {
  const accessToDevelopers = planFeatureAccessService.getAccess(PLAN_FEATURE.DEVELOPERS, currentApp);

  if (accessToDevelopers.hasAccess) {
    return firstValueFrom(apiCredentialsModel.get(currentApp.id));
  } else {
    return new Promise((resolve) => resolve({}));
  }
}

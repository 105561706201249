<ng-container *transloco="let t; read: 'memberInvitationModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>

    <button
      class="close"
      (click)="dismissModal()"
      type="button"
      data-test="member-invitation-modal-close-button"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="text-muted mb-20">{{ t('description') }}</div>

    <div class="margin-between-rows-30 mb-10">
      <div *ngFor="let memberInvitationForm of memberInvitationForms; index as index">
        <div class="font-weight-bold mb-15">{{ getHumanizedIndex(index) }}. {{ t('invitation.title') }}</div>

        <div class="d-flex align-items-start margin-between-cols-10">
          <cq-member-invitation
            class="flex-grow-1"
            [currentTeamMember]="currentTeamMember"
            [memberInvitationForm]="memberInvitationForm"
          ></cq-member-invitation>

          <button
            *ngIf="isMultipleMemberInvitations()"
            class="btn btn-borderless-primary"
            (click)="removeMemberInvitation(index)"
          >
            <i class="cqi-sm cqi-03x cqi-times"></i>
          </button>
        </div>
      </div>
    </div>

    <button
      *ngIf="isMultipleMode"
      class="btn btn-borderless-primary"
      (click)="addMemberInvitation()"
      type="button"
    >
      <i class="cqi-sm cqi-plus cqi-align-left"></i>
      <span>{{ t('buttons.addInvitation.text') }}</span>
    </button>
  </div>

  <div class="modal-footer bordered-top">
    <button
      class="btn btn-outline-secondary"
      type="button"
      (click)="dismissModal()"
      data-test="member-invitation-modal-cancel-button"
    >
      {{ 'general.cancel' | transloco }}
    </button>

    <button
      [disabled]="isDisabledSendInvitationButton()"
      class="btn btn-primary"
      data-test="member-invitation-modal-send-invitation-button"
      type="button"
      (click)="closeModal()"
    >
      {{ t('buttons.sendInvitation.text') }}
    </button>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DestroyService } from '@panel/app/services';

import { ModalComponent } from './modal.component';

@NgModule({
  declarations: [ModalComponent],
  exports: [ModalComponent],
  imports: [CommonModule],
  providers: [NgbActiveModal, DestroyService],
})
export class ModalModule {}

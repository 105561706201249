<ng-container *transloco="let t; read: 'amocrmTriggerMessagesForLeadComponent'">
  <div
    class="d-flex align-items-center cursor-pointer"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
    (click)="collapse.toggle()"
  >
    <h4 class="m-0">
      <span>{{ t('heading') }}</span>
      <i
        class="cqi-sm cqi-question-circle cursor-default"
        [ngbTooltip]="t('tooltip')"
      ></i>
    </h4>
    <div class="ml-auto">{{ activeTriggerMessages.length }}</div>
    <i
      class="cqi-sm ml-10"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>

  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="pt-15">
      <div class="mb-15">
        <span
          *ngIf="isEmptyActiveTriggerMessages"
          data-test="trigger-messages-zero-data-text-span"
          >{{ t('zeroData', {integrationTypeName}) }}</span
        >
        <span
          *ngIf="!isEmptyActiveTriggerMessages"
          data-test="trigger-messages-description-text-span"
          >{{ t('description') }}</span
        >
      </div>

      <cq-integration-trigger-messages
        [integrationType]="INTEGRATION_TYPES.AMOCRM"
        [triggerMessages]="activeTriggerMessages"
      ></cq-integration-trigger-messages>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import findIndex from 'lodash-es/findIndex';
import isEqual from 'lodash-es/isEqual';
import { combineLatest } from 'rxjs';
import { filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AMOCRM_STANDARD_PROPS_ARRAY } from '@http/integration/integrations/amo/constants/amocrm-integration.constants';
import { AmocrmMappingForm } from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { AmocrmField } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { DestroyService } from '@panel/app/services';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { PseudoMap } from '@panel/app/shared/types/pseudo-map.type';
import { UserProperty } from '@http/property/property.model';

/**
 * Компонент для работы с данными, которые передаются из контактов AmoCRM
 */

@Component({
  selector: 'cq-amocrm-data-from-contacts[controlAmocrmPropsMappingInternal][integrationId]',
  templateUrl: './amocrm-data-from-contacts.component.html',
  styleUrls: ['./amocrm-data-from-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AmocrmDataFromContactsComponent implements OnInit {
  /** Контрол со свойствами, которые передаются из контакта amoCRM в CQ|Dashly */
  @Input()
  controlAmocrmPropsMappingInternal!: GenericFormArray<AmocrmMappingForm>;

  /** ID интеграции */
  @Input()
  integrationId!: string;

  /** Инстанс коллапса */
  @ViewChild(NgbCollapse)
  collapse!: NgbCollapse;

  /** Поля контакта amoCRM */
  amocrmContactFields: AmocrmField[] = [];

  /** Список пользовательских свойств проекта */
  customProperties: UserProperty[] = [];

  /** Флаг свёрнутости списка данных, которые передаются из контактов amoCRM */
  isCollapsed: boolean = true;

  /** Название проекта */
  projectName = environment.projectName;

  /** Массив с переводами системных полей контакта amoCRM */
  standardPropsArray: AmocrmField[] = this.getStandardPropsArray();

  /** Record с соответствием перевода системного поля контакта amoCRM к его псевдо-значению */
  standardPropsMatch: Record<string, string> = this.getStandardPropsMatch();

  /** Список системных свойств проекта */
  systemProperties: UserProperty[] = [];

  /** Количества выбранных свойств для передачи */
  get amoCrmDataCount() {
    return this.controlAmocrmPropsMappingInternal.controls.length;
  }

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    public readonly amocrmState: AmocrmStateService,
    @Inject(AmocrmService) private readonly amocrmService: AmocrmService,
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.customProperties = this.getCustomProperties(this.amocrmState.userProps$.getValue());
    this.systemProperties = this.getSystemProperties(this.amocrmState.userProps$.getValue());

    this.amocrmState.contactFields$.subscribe((fields) => {
      // Надо подмешать в пришедший список полей контакта системные поля
      this.amocrmContactFields = [...this.standardPropsArray, ...fields];
    });

    this.controlAmocrmPropsMappingInternal.valueChanges
      .pipe(
        startWith(this.controlAmocrmPropsMappingInternal.value),
        pairwise(),
        takeUntil(this.destroy$),
        // Нам нужны только изменения внутри форм, удаление или добавление - не интересно
        filter(([prev, curr]: [PseudoMap[], PseudoMap[]]) => {
          return prev.length === curr.length;
        }),
        // Ищем индекс изменившегося элемента
        map(([prev, curr]: [PseudoMap[], PseudoMap[]]) => {
          return findIndex(curr, (element: PseudoMap) => {
            return !prev.some((el) => isEqual(element, el));
          });
        }),
        filter((index: number) => index > -1),
        // Индекс превращаем в AmocrmMappingForm
        map((index: number): [number, AmocrmMappingForm] => [index, this.controlAmocrmPropsMappingInternal.at(index)]),
      )
      .subscribe(([index, formGroup]: [number, AmocrmMappingForm]) => {
        const value = formGroup.controls.key.value;

        // Если значение в селекте поменялось на одно из системных полей контакта, то выставляем соответствующее значение
        // в селекте напротив
        if (this.standardPropsArray.map((field) => field.name).includes(value)) {
          formGroup.controls.value.setValue(this.standardPropsMatch[value]);
        } else {
          // Если в селекте с выбором свойства лида было одно из системных свойств, то обнуляем значение
          if (['$email', '$name', '$phone'].includes(formGroup.controls.value.value)) {
            formGroup.controls.value.setValue(null!);
          }
        }
      });

    // Следим за изменениями статусов валидности и touched-статуса, чтобы раскрыть коллапс этого компонента,
    // если один из контролов невалидный
    combineLatest(
      extractTouchedChanges(this.controlAmocrmPropsMappingInternal),
      this.controlAmocrmPropsMappingInternal.statusChanges.pipe(
        startWith(this.controlAmocrmPropsMappingInternal.status),
        map((status) => status === 'INVALID'),
      ),
    )
      .pipe(map((statuses) => [statuses[1]].some((value) => value)))
      .subscribe((invalid) => {
        if (invalid && this.collapse.collapsed) {
          this.collapse.toggle(true);
        }
      });
  }

  /** Добавление группы с контролами свойства, которое отправляется из контакта amoCRM в лида Carrot quest */
  addProperty(): void {
    const propertyControl = this.getFormGroup();

    this.controlAmocrmPropsMappingInternal.push(propertyControl);
  }

  /** Получение списка пользовательских свойств проекта */
  getCustomProperties(userProps: UserProperty[]) {
    return userProps.filter((property) => property.groupOrder === 5);
  }

  /** Создание группы AmocrmMappingForm с контролами */
  getFormGroup() {
    const controlData = {};

    return new AmocrmMappingForm(controlData);
  }

  /** Получение массива с переводами системных полей контакта amoCRM */
  getStandardPropsArray() {
    return AMOCRM_STANDARD_PROPS_ARRAY.map((property) => {
      const name: string = this.translocoService.translate(
        `amocrmDataFromContactsComponent.standardFieldName.${property.name}`,
      );
      const fieldType = property.fieldType;
      return { name, fieldType };
    });
  }

  /**
   * Получение Record'а с соответствием перевода системного поля контакта amoCRM к его псевдо-значению.
   * Необходимо для выставления правильного значения в селекте с выбором свойств пользователя проекта
   */
  getStandardPropsMatch() {
    return AMOCRM_STANDARD_PROPS_ARRAY.reduce((map: { [index: string]: string }, item: AmocrmField) => {
      const key = this.translocoService.translate(`amocrmDataFromContactsComponent.standardFieldName.${item.name}`);
      const value = item.name;

      map[key] = value;
      return map;
    }, {});
  }

  /** Получение списка системных свойств проекта */
  getSystemProperties(userProps: UserProperty[]) {
    return userProps.filter((property) => ['$email', '$name', '$phone'].includes(property.name));
  }

  /**
   * Необходимо показать селект с выбором системных свойств, если в селекте с выбором полей контакта
   * выбрано системное поле контакта amoCRM
   */
  isSelectedAmoSystemField(amocrmControl: UntypedFormControl) {
    return this.standardPropsArray.some((prop) => prop.name === amocrmControl.value);
  }

  /** Удаление элемента из массива контролов */
  removeItem(index: number) {
    this.controlAmocrmPropsMappingInternal.removeAt(index);
  }
}

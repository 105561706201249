<ng-container *transloco="let t; read: 'conversationPartAiContentModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="(isDataLoading$ | async) === false; else loader">
      <ng-container *ngFor="let dataSource of aiContent$ | async">
        <cq-data-source
          class="bordered-bottom py-20"
          [dataSource]="dataSource"
        ></cq-data-source>
      </ng-container>
    </ng-container>

    <ng-template #loader>
      <cq-loader></cq-loader>
    </ng-template>
  </div>
</ng-container>

import { Observable } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map, withLatestFrom } from 'rxjs/operators';

/**
 * Запускает параллельный поток, который работает, пока работает source$ поток
 */
export function parallelWith(parallelSource$: Observable<any>) {
  return <T>(source$: Observable<T>): Observable<T> => {
    return source$.pipe(
      withLatestFrom(parallelSource$.pipe(startWith(null))),
      map(([v, _]) => v),
    );
  };
}

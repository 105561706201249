import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { Message } from '@http/message/message.types';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { Immutable } from '@panel/app/shared/types/immutable.type';

import { MessageEditorTriggerParams } from '../trigger/message-editor-trigger.component';
import { ValidationCallback } from '../validation-callback.type';
import { MessageEditorTriggerState } from './message-editor-trigger-state.service';

/** Делаем обертку что бы не срать в новых компонентах */
@Component({
  selector: 'cq-message-editor-trigger-wrapper',
  template: `<cq-message-editor-trigger
    [triggerParams]="triggerParams"
    (triggerParamsChange)="triggerParamsChange.next($event)"
    [currentMessage]="currentMessage"
    [translationEntityName]="translationEntityName"
    (ajsValidationFn)="ajsValidationFn.next($event)"
  ></cq-message-editor-trigger>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageEditorTriggerState, ...formSubmitTokenProviders],
})
export class MessageEditorTriggerWrapperComponent implements OnInit {
  @Input()
  triggerParams!: MessageEditorTriggerParams;

  @Output()
  triggerParamsChange: Subject<MessageEditorTriggerParams> = new Subject();

  /**
   * В нормальной ситуации мы бы не стали передавать весь currentMessage, чтоб проверить какие-то на нем условия,
   * но т.к. корневой компонент на старом ангуляре и мы не можем привезти какой-то нормальный
   * state management - передаем вот так в ридонли виде
   */
  @Input()
  currentMessage!: Immutable<Message>;

  @Input()
  translationEntityName!: string;

  @Output()
  stateLoaded: Subject<MessageEditorTriggerState> = new Subject();

  @Output()
  ajsValidationFn: Subject<ValidationCallback> = new Subject();

  @Output()
  formSubmitSourceLoaded: Subject<Subject<void>> = new Subject();

  constructor(
    private readonly messageEditorTriggerState: MessageEditorTriggerState,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSource: Subject<void>,
  ) {}

  ngOnInit() {
    this.stateLoaded.next(this.messageEditorTriggerState);
    this.formSubmitSourceLoaded.next(this.formSubmitSource);
  }
}

import { translate } from '@jsverse/transloco';

import {
  Branch,
  BranchStyle,
  DEFAULT_CARD_STYLE,
  NOT_VALID_CARD_STYLE,
} from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import {
  BOT_CONDITION_BLOCK_DARKEN_PRIMARY_COLOR,
  BOT_CONDITION_BLOCK_PRIMARY_COLOR,
  BOT_WHITE_COLOR,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { cloneObjectMutable } from '@panel/app/shared/functions/clone-object-mutable.function';
import { Immutable } from '@panel/app/shared/types/immutable.type';

const CONDITION_BLOCK_STYLE: Immutable<BranchStyle> = {
  ...DEFAULT_CARD_STYLE,
  heading: {
    icon: {
      color: BOT_CONDITION_BLOCK_DARKEN_PRIMARY_COLOR,
      background: BOT_WHITE_COLOR,
    },
  },
};

export class ConditionBlockView extends Branch {
  protected get headingIcon(): string {
    return '\ue9c7';
  }

  get description(): string | null {
    return translate('classes.block.condition.type.appOnlineCondition.description');
  }

  protected getCurrentStyle(): BranchStyle {
    const style: BranchStyle = cloneObjectMutable(CONDITION_BLOCK_STYLE);

    if (this.active) {
      style.border.color = BOT_CONDITION_BLOCK_PRIMARY_COLOR;
      style.heading.icon.color = BOT_WHITE_COLOR;
      style.heading.icon.background = BOT_CONDITION_BLOCK_PRIMARY_COLOR;
    } else if (!this.active && !this.valid) {
      style.border.color = NOT_VALID_CARD_STYLE.border.color;
      style.text.color = NOT_VALID_CARD_STYLE.text.color;
    }

    if (this.hovered) {
      style.border.color = BOT_CONDITION_BLOCK_PRIMARY_COLOR;
      style.text.color = 0x22252a;
    }

    return style;
  }
}

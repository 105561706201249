import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { ArticleCategoryModel } from '@http/article-category/article-category.model';
import { ACTIONS_ON_ARTICLES_CATEGORY } from '@panel/app/pages/knowledge-base/shared/components/articles-category-editor-modal/articles-category-editor-modal.constnats';
import { DestroyService } from '@panel/app/services';
import { EmojiService } from '@panel/app/shared/services/emoji/emoji.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-articles-category-editor-modal',
  templateUrl: './articles-category-editor-modal.component.html',
  styleUrls: ['./articles-category-editor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ArticlesCategoryEditorModalComponent implements OnInit {
  /** Параметры, передаваемые в модальное окно */
  _modalWindowParams: any;

  @Input() set modalWindowParams(value: any) {
    this._modalWindowParams = value;

    this.categoryForm.setValue(
      {
        categoryName: value.category ? value.category.name : '',
        icon: value.category ? value.category.icon : '',
      },
      { emitEvent: false },
    );
  }

  get modalWindowParams(): any {
    return this._modalWindowParams;
  }

  @ViewChild(NgbDropdown) ngbDropdown: any;

  /**
   * Максимальная длина названия категории
   *
   * @type {Number}
   */
  protected CATEGORY_NAME_MAX_LENGTH: number = 100;

  /** Флаг выполнения запроса */
  protected isApiRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** Создание или редактирование категории */
  protected isEdit: boolean = false;
  /** Новая иконка */
  protected newIcon: { name: string; icon: string | null } = {
    name: '',
    icon: '',
  };
  /** Начальная иконка категории */
  protected initialIcon: string = '';
  /** Начальное имя категории */
  protected initialName: string = '';

  protected DEFAULT_EMOJI: any;
  protected DEFAULT_EMOJI_NAME: any;

  protected categoryForm = this.fb.group({
    categoryName: this.fb.control('', [Validators.required, Validators.maxLength(this.CATEGORY_NAME_MAX_LENGTH)]),
    icon: this.fb.control(''),
  });

  constructor(
    protected readonly destroy$: DestroyService,
    protected readonly activeModal: NgbActiveModal,
    protected readonly articleCategoryModel: ArticleCategoryModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly emojiService: EmojiService,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    protected readonly uiRouter: UIRouter,
    protected readonly fb: FormBuilder,
  ) {
    this.categoryForm.valueChanges.subscribe((value) => {
      //@ts-ignore
      this.newIcon.icon = value.icon;
      this.modalWindowParams.category.name = value.categoryName;
    });
  }

  ngOnInit(): void {
    this.DEFAULT_EMOJI = this.articleCategoryModel.getDefaultEmoji();
    this.DEFAULT_EMOJI_NAME = this.articleCategoryModel.getDefaultEmojiName();

    if (!!this.modalWindowParams.category) {
      this.isEdit = true;
    } else {
      this.modalWindowParams.category = this.articleCategoryModel.getDefault();
    }

    this.initialIcon = this.modalWindowParams.category.icon;
    this.initialName = this.modalWindowParams.category.name;
    this.newIcon.icon = this.modalWindowParams.category.icon;
  }

  /**
   * Закрытие модалки с подтверждением (снаружи вызовется successCallback)
   *
   * @param isValid - Валидностиь формы
   */
  confirm(isValid: any): void {
    if (isValid) {
      this.isApiRequestPerformed.next(true);
      this.modalWindowParams.category.icon =
        this.newIcon.icon?.length && this.newIcon.icon !== this.modalWindowParams.category.icon
          ? this.newIcon.icon
          : this.modalWindowParams.category.icon;

      if (this.isEdit) {
        this.articleCategoryModel
          .save(this.modalWindowParams.currentApp.id, this.modalWindowParams.category)
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => this.isApiRequestPerformed.next(false)),
          )
          .subscribe({
            next: (category: any) => {
              this.trackCategoryChanged(this.modalWindowParams.category.name);
              this.toastr.success(
                this.translocoService.translate('modals.articlesCategoryEditor.toasts.categoryEditedSuccess', {
                  categoryName: category.name,
                }),
              );
              this.activeModal.close({
                action: ACTIONS_ON_ARTICLES_CATEGORY.RENAME,
                category: category,
              });
            },
            error: (meta) => {
              if (meta && meta.error === 'CategoryAlreadyExistsError') {
                this.categoryForm.controls.categoryName.setErrors({ apiMessageArticlesCategoryExistsError: true });
                this.categoryForm.controls.categoryName.markAsTouched();
              }
            },
          });
      } else {
        this.articleCategoryModel
          .create(this.modalWindowParams.currentApp.id, this.modalWindowParams.category)
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => this.isApiRequestPerformed.next(false)),
          )
          .subscribe({
            next: (category: any) => {
              this.trackCategoryCreated(category.name);
              this.toastr.success(
                this.translocoService.translate('modals.articlesCategoryEditor.toasts.categoryCreatedSuccess', {
                  categoryName: category.name,
                }),
              );
              category = { ...this.articleCategoryModel.getDefault(), ...category };
              this.activeModal.close({
                action: ACTIONS_ON_ARTICLES_CATEGORY.CREATE,
                category: category,
              });
            },
            error: (meta) => {
              if (meta && meta.error === 'CategoryAlreadyExistsError') {
                this.categoryForm.controls.categoryName.setErrors({ apiMessageArticlesCategoryExistsError: true });
                this.categoryForm.controls.categoryName.markAsTouched();
              }
            },
          });
      }
    }
  }

  /**
   * Функция для проверки изменения данных
   *
   * @return {boolean}
   */
  isDataChanged(): boolean {
    return this.isIconChanged() || this.isNameChanged();
  }

  /**
   * Функция для проверки изменения иконки
   *
   * @return {boolean}
   */
  private isIconChanged(): boolean {
    return this.isEdit && this.initialIcon !== this.newIcon.icon;
  }

  /**
   * Функция для проверки изменения имени
   *
   * @return {boolean}
   */
  private isNameChanged(): boolean {
    return this.modalWindowParams.category.name !== this.initialName;
  }

  /**
   * Выбор emoji
   *
   * @param emojiName - Имя emoji
   */
  selectEmoji(emojiName: any): void {
    this.newIcon.name = emojiName.emojiName;
    this.newIcon.icon = this.emojiService.getNativeEmojiByName(emojiName.emojiName);
    this.categoryForm.controls.icon.setValue(this.newIcon.icon);
    this.ngbDropdown.close();
  }

  /**
   * Установить стандартную иконку
   */
  setDefaultIcon(): void {
    this.newIcon.name = this.DEFAULT_EMOJI_NAME;
    this.newIcon.icon = this.DEFAULT_EMOJI;
    this.categoryForm.controls.icon.setValue(this.newIcon.icon);
    this.ngbDropdown.close();
  }

  /**
   * Трек клика на "Выбрать другой вариант эмоджи"
   */
  trackClickOnChooseAnotherEmoji(): void {
    this.carrotquestHelper.track('База знаний - клик на "Выбрать другой вариант эмоджи"');
  }

  /**
   * Трек изменения группы
   *
   * @param categoryName - Имя группы
   */
  trackCategoryChanged(categoryName: any): void {
    this.carrotquestHelper.track('База знаний - переименовал категорию', {
      'Название категории': categoryName,
    });
  }

  /**
   * Трек создания группы
   *
   * @param categoryName - Имя группы
   */
  trackCategoryCreated(categoryName: any): void {
    this.carrotquestHelper.track('База знаний - добавил категорию', {
      'Название категории': categoryName,
    });
  }
}

import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';

function tryParse(val: any): any {
  if (typeof val !== 'string') {
    return val;
  }
  try {
    return JSON.parse(val);
  } catch (err) {
    return val;
  }
}

/**
 * Сервис, предназначеный для поиска и отправки на бэк данных об измененных caseStyleHelper-ом данных (в случаях, когда этого происходить не должно было)
 * По задаче https://favro.com/organization/6fcfbd57a2b44fae2a92e7ea/67cf7916416c0133b25bb9df?card=Car-51343
 */
export class IntegrationLoggerHelper {
  static valueByKeyWereChanged(integrationBefore: any, beforeKey: any, integrationAfter: any, afterKey: any): any {
    const valueBefore = integrationBefore.settings[beforeKey];
    // в половине сценариев тут строка, поэтому надо попробовать распарсить
    const valueAfter = tryParse(integrationAfter.settings)[afterKey];
    if (isEmpty(valueBefore) && isEmpty(valueAfter)) {
      return false;
    }
    return !isEqual(valueBefore, valueAfter);
  }

  /**
   * @param {string} sentFrom
   * @param {UtilsModel} utilsModel
   * @param {Object} integrationBefore
   * @param {Object} integrationAfter
   * @returns {Promise}
   */
  static sendLogs(sentFrom: any, utilsModel: any, integrationBefore: any, integrationAfter: any): any {
    return utilsModel
      .saveLog(
        `integration-save-from-${sentFrom}`,
        `integration-save-from-${sentFrom}`,
        {
          integrationId: integrationBefore.id,
          integrationType: integrationBefore.type,
        },
        {
          integrationBefore: integrationBefore.settings,
          integrationAfter: integrationAfter.settings,
        },
      )
      .subscribe();
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { TEAM_MEMBER_PERMISSIONS, TEAM_MEMBER_PERMISSIONS_ARRAY } from '@http/team-member/team-member.constants';
import { TeamMemberModel } from '@http/team-member/team-member.model';
import { TeamMember } from '@http/team-member/team-member.types';
import {
  MemberInvitation,
  MemberInvitationFormData,
} from '@http/team-member-invitation/types/member-invitations.types';
import { TeamMemberHelpService } from '@panel/app/services/team-member/team-member-help.service';

/**
 * Компонент для работы с приглашением в команду
 */

@Component({
  selector: 'cq-member-invitation[currentTeamMember][memberInvitationForm]',
  templateUrl: './member-invitation.component.html',
  styleUrls: ['./member-invitation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberInvitationComponent implements OnInit {
  /** Текущий член команды, который выполняет приглашение */
  @Input()
  currentTeamMember!: TeamMember;

  /** Форма приглашения */
  @Input()
  memberInvitationForm!: FormGroup<MemberInvitationFormData>;

  /** Доступные для выбора права члена команды */
  availableTeamMemberPermissions: TEAM_MEMBER_PERMISSIONS[] = [];

  /** Получение контрола для работы с email'ом */
  get controlEmail(): FormControl<MemberInvitation['email']> {
    return this.memberInvitationForm.controls.email;
  }

  /** Получение контрола для работы с правами */
  get controlPermissions(): FormControl<MemberInvitation['permissions']> {
    return this.memberInvitationForm.controls.permissions;
  }

  /** Получение контрола для работы с правами на экспорт */
  get controlPermissionsExport(): FormControl<MemberInvitation['permissionsExport']> {
    return this.memberInvitationForm.controls.permissionsExport;
  }

  /** Получение контрола для работы с правами на массовую рассылку */
  get controlPermissionsSendBulk(): FormControl<MemberInvitation['permissionsSendBulk']> {
    return this.memberInvitationForm.controls.permissionsSendBulk;
  }

  /** Получение контрола для работы с правами на показ списка пользователей в диалогах */
  get controlPermissionsConversationUserList(): FormControl<MemberInvitation['permissionsConversationUserList']> {
    return this.memberInvitationForm.controls.permissionsConversationUserList;
  }

  constructor(
    private readonly teamMemberHelpService: TeamMemberHelpService,
    private readonly teamMemberModel: TeamMemberModel,
  ) {}

  ngOnInit(): void {
    this.availableTeamMemberPermissions = this.getAvailableTeamMemberPermissions();

    this.updateDisabledStates();
  }

  /** Получение доступных для выбора прав члена команды */
  getAvailableTeamMemberPermissions(): TEAM_MEMBER_PERMISSIONS[] {
    const availableTeamMemberPermissions = TEAM_MEMBER_PERMISSIONS_ARRAY.filter((permission) =>
      this.teamMemberModel.hasPermissions(this.currentTeamMember.permissions, permission),
    );

    return availableTeamMemberPermissions;
  }

  /**
   * Получение класса для иконки обозначающей права пользователя
   *
   * @param permission - Права пользователя
   */
  getDescriptionForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.teamMemberHelpService.getDescriptionForPermission(permission);
  }

  /**
   * Получение класса для иконки обозначающей права пользователя
   *
   * @param permission - Права пользователя
   */
  getIconClassForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.teamMemberHelpService.getIconClassForPermission(permission);
  }

  /**
   * Получение названия для прав пользователя
   *
   * @param permission - Права пользователя
   */
  getNameForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.teamMemberHelpService.getNameForPermission(permission);
  }

  /**
   * Доступно ли разрешение на экспорт
   *
   * NOTE:
   *  Данная функция возвращает true|null, т.к. используется для определение disabled состояния чек-бокса,
   *  а [attr.disabled] ожидает true|null.
   *
   * @param teamMemberPermission - Права доступа
   */
  isDisabledPermissionExport(teamMemberPermission: TEAM_MEMBER_PERMISSIONS): boolean | null {
    return this.teamMemberModel.hasAccessToEditPermissionExport(teamMemberPermission) ? null : true;
  }

  /**
   * Доступно ли разрешение на массовую рассылку
   *
   * NOTE:
   *  Данная функция возвращает true|null, т.к. используется для определение disabled состояния чек-бокса,
   *  а [attr.disabled] ожидает true|null.
   *
   * @param teamMemberPermission - Права доступа
   */
  isDisabledPermissionSendBulk(teamMemberPermission: TEAM_MEMBER_PERMISSIONS): boolean | null {
    return this.teamMemberModel.hasAccessToEditPermissionSendBulk(teamMemberPermission) ? null : true;
  }

  /**
   * Доступно ли разрешение на показ списка пользователей в диалогах
   *
   * NOTE:
   *  Данная функция возвращает true|null, т.к. используется для определение disabled состояния чек-бокса,
   *  а [attr.disabled] ожидает true|null.
   *
   * @param teamMemberPermission - Права доступа
   */
  isDisabledPermissionConversationUserList(teamMemberPermission: TEAM_MEMBER_PERMISSIONS): boolean | null {
    return this.teamMemberModel.isDisabledPermissionConversationUserList(teamMemberPermission) ? null : true;
  }

  /**
   * Обработчик изменений селекта с выбором прав
   *
   * @param memberInvitationForm - Форма приглашения
   */
  onChangePermissionSelect(): void {
    const selectedPermission = this.controlPermissions.value;
    const permissions = {
      [TEAM_MEMBER_PERMISSIONS.OPERATOR]: {
        permissionsExport: false,
        permissionsSendBulk: false,
        permissionsConversationUserList: true,
      },
      [TEAM_MEMBER_PERMISSIONS.ADMIN]: {
        permissionsExport: false,
        permissionsSendBulk: false,
        permissionsConversationUserList: true,
      },
      [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN]: {
        permissionsExport: true,
        permissionsSendBulk: true,
        permissionsConversationUserList: true,
      },
    };

    const { permissionsExport, permissionsSendBulk, permissionsConversationUserList } = permissions[selectedPermission];

    this.controlPermissionsExport.setValue(permissionsExport);
    this.controlPermissionsSendBulk.setValue(permissionsSendBulk);
    this.controlPermissionsConversationUserList.setValue(permissionsConversationUserList);

    this.updateDisabledStates();
  }

  /** Обновляет состояния disabled у чек-боксов с правами действий */
  private updateDisabledStates(): void {
    const selectedPermission = this.controlPermissions.value;

    if (this.isDisabledPermissionConversationUserList(selectedPermission)) {
      this.controlPermissionsConversationUserList.disable();
    } else {
      this.controlPermissionsConversationUserList.enable();
    }

    if (this.isDisabledPermissionExport(selectedPermission)) {
      this.controlPermissionsExport.disable();
    } else {
      this.controlPermissionsExport.enable();
    }

    if (this.isDisabledPermissionSendBulk(selectedPermission)) {
      this.controlPermissionsSendBulk.disable();
    } else {
      this.controlPermissionsSendBulk.enable();
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
} from '@angular/core';

import { TAG_DEFAULT_COMPONENT_MAX_WIDTH_PX } from '@panel/app/partials/tag-default/tag-default.constants';

/**
 * Компонент для работы с простым тегом
 */

@Component({
  selector: 'cq-tag-default[text]',
  templateUrl: './tag-default.component.html',
  styleUrls: ['./tag-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagDefaultComponent {
  /** Можно ли активировать тег */
  @Input()
  canActivate: boolean = false;

  /** Можно ли удалить тег */
  @Input()
  canRemove: boolean = false;

  /** Заблокировал ли тег */
  @Input()
  disabled: boolean = false;

  /** Выделен ли тег как активный */
  @Input()
  isActive: boolean = false;

  /** Выделен ли тег как удалённый */
  @Input()
  isRemoved: boolean = false;

  /** Текст тега */
  @Input()
  text!: string;

  /** Callback, который вызывается при активации тега */
  @Output()
  activate: EventEmitter<void> = new EventEmitter<void>();

  /** Callback, который вызывается при удалении тега */
  @Output()
  remove: EventEmitter<string> = new EventEmitter<string>();

  /** Максимальная ширина компонента */
  @HostBinding('style.maxWidth.px')
  maxWidthPX = TAG_DEFAULT_COMPONENT_MAX_WIDTH_PX;

  /** Является ли тег длинны */
  get isLongTag(): boolean {
    return this.elementRef.nativeElement.clientWidth >= TAG_DEFAULT_COMPONENT_MAX_WIDTH_PX;
  }

  constructor(@Inject(ElementRef) private readonly elementRef: ElementRef) {}

  /** Обработчик клика на удаление тега */
  onClickRemove(): void {
    if (!this.disabled) {
      this.remove.emit(this.text);
    }
  }

  /** Обработчик клика на тег */
  onClickTag() {
    if (!this.disabled && this.canActivate) {
      this.activate.emit();
    }
  }
}

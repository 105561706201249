/** Сервис для работы с модальным окном "Вечеринка закончилась" */
(function () {
  'use strict';

  angular.module('myApp.services.partyIsOverModalService').service('partyIsOverModalService', partyIsOverModalService);

  function partyIsOverModalService($uibModal) {
    /** Инстанс модального окна */
    let partyIsOverModalServiceInstance;

    return {
      hideModal: hideModal,
      showModal: showModal,
    };

    /** Скрытие модального окна */
    function hideModal() {
      if (partyIsOverModalServiceInstance) {
        partyIsOverModalServiceInstance.close();
        partyIsOverModalServiceInstance = null;
      }
    }

    /**
     * Открытие модального окна
     *
     * @param {Object=} currentApp - Текущее приложение
     * @param {Object} djangoUser - Текущий django-пользователь
     * @param {Object} billingInfo - Информация о биллинге
     */
    function showModal(currentApp, djangoUser, billingInfo) {
      if (!partyIsOverModalServiceInstance) {
        partyIsOverModalServiceInstance = $uibModal.open({
          component: 'cqPartyIsOverModal',
          keyboard: false,
          resolve: {
            billingInfo: angular.bind(null, angular.identity, billingInfo),
            currentApp: angular.bind(null, angular.identity, currentApp),
            djangoUser: angular.bind(null, angular.identity, djangoUser),
          },
          backdrop: 'static',
          size: 'sm modal-dialog-centered',
          windowClass: 'party-is-over',
        });

        partyIsOverModalServiceInstance.result.finally(() => {
          partyIsOverModalServiceInstance = null;
        });
      }
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-auto-message-loop-modal',
  templateUrl: './auto-message-loop-modal.component.html',
  styleUrls: ['./auto-message-loop-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageLoopModalComponent implements OnInit {
  @Input()
  modalWindowParams: any;

  protected currentMessageIds: any;
  protected loops: any;
  protected saveActivateButtonText: any;
  protected saveDeactivateButtonText: any;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly ngbActive: NgbActiveModal,
    protected readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.init();

    this.currentMessageIds = this.modalWindowParams.currentMessageIds;
    this.loops = this.modalWindowParams.loops;
    this.saveActivateButtonText =
      this.modalWindowParams.saveActivateButton ||
      this.translocoService.translate('modals.autoMessageLoop.saveActivateButton');
    this.saveDeactivateButtonText =
      this.modalWindowParams.saveDeactivateButton ||
      this.translocoService.translate('modals.autoMessageLoop.saveDeactivateButton');
  }

  init(): void {
    this.trackOpenModal();
  }

  /**
   * Обработка нажатия на кнопку сохранения с включением
   *
   */
  saveActivate(): void {
    this.ngbActive.close(true);
  }

  /**
   * Обработка нажатия на кнопку сохранения с отключением
   *
   */
  saveDeactivate(): void {
    this.ngbActive.close(false);
  }

  /**
   * Трек клика на "Включить"/"Сохранить и включить"
   */
  trackClickActivate(): void {
    this.carrotquestHelper.track('Автосообщения - клик на "Включить" в поп-апе "Возможно зацикливание сообщения"', {
      Название: this.modalWindowParams.currentMessageName,
    });
  }

  /**
   * Трек клика на "Не включать"/"Сохранить и не включать"
   */
  trackClickDeactivate(): void {
    this.carrotquestHelper.track('Автосообщения - клик на "Не включать" в поп-апе "Возможно зацикливание сообщения"', {
      Название: this.modalWindowParams.currentMessageName,
    });
  }

  /**
   * Трек открытия поп-апа
   */
  trackOpenModal(): void {
    this.carrotquestHelper.track('Автосообщения - показался поп-ап "Возможно зацикливание сообщения"');
  }
}

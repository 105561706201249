import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { BranchActionAmocrmNotificationModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/amocrm-notification/amocrm-notification.module';
import { BranchActionAppOfflineConditionModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/app-offline-condition/app-offline-condition.module';
import { BranchActionAppOnlineConditionModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/app-online-condition/app-online-condition.module';
import { BranchActionAssistantModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/assistant/assistant.module';
import { BranchActionButtonModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/button/button.module';
import { BranchActionButtonsPropertyModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/buttons-property/buttons-property.module';
import { BranchActionChannelModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/channel/channel.module';
import { BranchActionEmailNotificationModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/email-notification/email-notification.module';
import { BranchActionEventModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/event/event.module';
import { BranchActionFileModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/file/file.module';
import { BranchActionMeetingModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/meeting/meeting.module';
import { BranchActionOperatorModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/operator/operator.module';
import { BranchActionPropertyModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/property/property.module';
import { BranchActionPropertyFieldModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/property-field/property-field.module';
import { BranchActionTextModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/text/text.module';
import { BranchActionUserTagModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/user-tag/user-tag.module';

import { BranchActionContainerComponent } from './container.component';

@NgModule({
  declarations: [BranchActionContainerComponent],
  imports: [
    CommonModule,

    // Модули экшенов
    BranchActionAmocrmNotificationModule,
    BranchActionAppOnlineConditionModule,
    BranchActionAppOfflineConditionModule,
    BranchActionButtonModule,
    BranchActionButtonsPropertyModule,
    BranchActionPropertyFieldModule,
    BranchActionAmocrmNotificationModule,
    BranchActionChannelModule,
    BranchActionEmailNotificationModule,
    BranchActionEventModule,
    BranchActionFileModule,
    BranchActionAssistantModule,
    BranchActionOperatorModule,
    BranchActionPropertyModule,
    BranchActionTextModule,
    BranchActionUserTagModule,
    BranchActionMeetingModule,
    TranslocoModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbPopoverModule,
  ],
  exports: [BranchActionContainerComponent],
})
export class BranchActionContainerModule {}

import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_STATUSES } from '../../../app/http/message/message.constants';
import { MESSAGE_PART_SENDER_TYPES, MESSAGE_PART_TYPES } from '../../../app/http/message-part/message-part.constants';
import { CHAT_BOT_TYPE } from '../../../app/http/chat-bot/types/chat-bot-external.types';

(function () {
  'use strict';

  angular.module('myApp.dashboard').config(config).run(run);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.dashboard', {
        url: '/dashboard',
        bindings: {
          activeLeadBotsAmount: 'activeLeadBotsAmount',
          activeMessagesAmounts: 'activeMessagesAmounts',
          appBlocks: 'appBlocks',
          billingInfo: 'billingInfo',
          chatSettings: 'chatSettings',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          djangoUserTempData: 'djangoUserTempData',
          funnels: 'funnels',
          messageSenders: 'messageSenders',
          properties: 'properties',
          readyAutoMessagesTemplates: 'readyAutoMessagesTemplates',
          starterGuide: 'starterGuide',
          templates: 'templates',
        },
        component: 'cqDashboard',
        resolve: {
          activeLeadBotsAmount: getDataAboutActiveLeadBots,
          activeMessagesAmounts: getActiveMessagesAmounts,
          chatSettings: getChatSettings,
          funnels: getFunnels,
          messageSenders: getMessageSenders,
          properties: getProperties,
          readyAutoMessagesTemplates: getReadyAutoMessagesTemplates,
          templates: getTemplates,
        },
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.OPERATOR],
        },
        params: {
          starterGuideStep: null,
        },
      })
      // Страниц onboarding и starter-guide больше нет, поэтому открывает dashboard
      .state('app.content.onboarding', {
        url: '/onboarding',
        redirectTo: 'app.content.dashboard',
      })
      .state('app.content.starterGuide', {
        url: '/starter-guide?{step}',
        redirectTo: 'app.content.dashboard',
      });

    /**
     * Получение количества активных автосообщений
     *
     * @param messageModel
     *
     * @return {*}
     */
    function getActiveMessagesAmounts(messageModel) {
      return firstValueFrom(
        messageModel.getAutoMessages(MESSAGE_PART_TYPES.ALL, MESSAGE_STATUSES.ACTIVE, null, '', false),
      ).then(getSuccessMessages);

      function getSuccessMessages(response) {
        let activeMessagesIds = [];

        response.messages.forEach((message) => {
          activeMessagesIds.push(message.id);
        });

        return {
          amount: response.messages.length,
          ids: activeMessagesIds,
        };
      }
    }

    /**
     * Получение настроек чата
     *
     * @param currentApp - Текущее приложение
     */
    function getChatSettings(currentApp) {
      return angular.copy(currentApp.settings);
    }

    /**
     * Получение информации об активных Lead ботах
     *
     * @param currentApp
     * @param chatBotModel
     */
    function getDataAboutActiveLeadBots(currentApp, chatBotModel) {
      return firstValueFrom(chatBotModel.getList(currentApp.id, MESSAGE_STATUSES.ACTIVE)).then((response) => {
        return {
          ids: response.chatBots.map((bot) => bot.id),
          amount: response.chatBots.length,
        };
      });
    }

    /**
     * Получение основной воронки, в случае ее отсутствия возвращает список воронок
     *
     * @param djangoUser
     * @param funnelModel
     * @param currentApp
     * @param planFeatureAccessService
     * @return {Promise}
     */
    function getFunnels(djangoUser, currentApp, funnelModel, planFeatureAccessService) {
      const userRole = djangoUser.prefs[currentApp.id].permissions;

      // Не делаем запросов к воронкам для операторов
      if (userRole === 'operator') {
        return;
      }

      return (
        planFeatureAccessService.getAccess(PLAN_FEATURE.EVENTS_FUNNELS, currentApp).hasAccess &&
        firstValueFrom(funnelModel.getMain(currentApp.id)).then(getMainSuccess).catch(getMainError)
      );

      function getMainError() {
        return firstValueFrom(funnelModel.getList(currentApp.id));
      }

      function getMainSuccess(mainFunnel) {
        return [mainFunnel]; // HACK т.к. в переменной может содержаться как дефолтная воронка так и все воронки, для удобства помещаем дефолтную в начало массива
      }
    }

    /**
     * Получение отправителей
     *
     * @param $transition$
     * @param currentApp
     */
    function getMessageSenders($transition$, currentApp) {
      let messageSenderModel = $transition$.injector().get('messageSenderModel');

      return firstValueFrom(messageSenderModel.getList(currentApp.id)).then(getMessageSendersListSuccess);

      function getMessageSendersListSuccess(messageSenders) {
        for (let i = 0; i < messageSenders.length; i++) {
          messageSenders[i].type = MESSAGE_PART_SENDER_TYPES.MESSAGE_SENDER;
        }

        return messageSenders;
      }
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param propertyModel
     * @param currentApp
     * @return {Promise}
     */
    function getProperties(propertyModel, currentApp) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение готовых сценариев
     *
     * @param MESSAGE_PART_TYPES
     * @param currentApp
     * @param readyMessageTemplateModel
     * @param planFeatureAccessService
     *
     * @returns {*}
     */
    function getReadyAutoMessagesTemplates(currentApp, readyMessageTemplateModel, planFeatureAccessService) {
      const accessToReadyAutoMessage = planFeatureAccessService.getAccess(PLAN_FEATURE.READY_AUTO_MESSAGES, currentApp);

      return accessToReadyAutoMessage.hasAccess
        ? firstValueFrom(readyMessageTemplateModel.getList(currentApp.id, MESSAGE_PART_TYPES.ALL)).then(
            (response) => response.templates,
          )
        : [];
    }

    /**
     * Получение шаблонов чат-бота
     * @param currentApp
     * @param chatBotTemplateModel
     * @returns {*}
     */
    function getTemplates(currentApp, chatBotTemplateModel) {
      return firstValueFrom(chatBotTemplateModel.getList(currentApp.id, CHAT_BOT_TYPE.LEAD));
    }
  }

  function run($filter, $transitions, $state) {
    $transitions.onFinish({ entering: 'app.content.dashboard' }, funnelsOnSuccess);

    /**
     * Обновляет связи воронок с типами событий
     * при первоначальной загрузке, при копировании и/или создании новой воронки
     *
     * @param {Object} transition
     */
    function funnelsOnSuccess(transition) {
      var funnels = transition.injector().get('funnels');
      var properties = transition.injector().get('properties');
      const funnelModel = transition.injector().get('funnelModel');

      if (funnels) {
        funnelModel.linkWithEventTypes(funnels, properties.eventTypes);
      }
    }
  }
})();

import { CustomerDto } from '@http/customer/customer.types';

/** Класс для работы с customer */
export class Customer {
  /** Дата создания сущности плательщика в Chargebee */
  createdAt: number = 0;
  /** Email плательщика */
  email: string = '';
  /** Неиспользованные средства */
  excessPayments: number = 0;
  /** Баланс плательщика */
  promotionalCredits: number = 0;
  /** Средства, который были возвращены плательщику */
  refundableCredits: number = 0;
  /** Версия сущности плательщика в Chargebee */
  resourceVersion: number = 0;
  /** Дата обновления сущности плательщика в Chargebee */
  updatedAt: number = 0;

  constructor(private readonly dto: CustomerDto) {
    this.createdAt = dto.created_at;

    if (dto.email) {
      this.email = dto.email;
    }

    this.excessPayments = dto.excess_payments;
    this.promotionalCredits = dto.promotional_credits;
    this.refundableCredits = dto.refundable_credits;

    if (dto.resource_version) {
      this.resourceVersion = dto.resource_version;
    }

    this.updatedAt = dto.updated_at;
  }

  /** Сумма на балансе */
  get balance(): number {
    return this.promotionalCredits + this.refundableCredits + this.excessPayments;
  }
}

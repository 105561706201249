import { OverlayScrollbars } from 'overlayscrollbars';

(function () {
  'use strict';

  // преобразование переменных окружения в константы в MACRO_CASE
  let caseStyleHelper = angular.injector(['myApp.services.caseStyleHelper']).get('caseStyleHelper');
  let environment = angular.copy(require('@environment').environment);
  let macroCaseEnvironment = caseStyleHelper.keysToMacroCase(environment);
  let environmentEntries = Object.entries(macroCaseEnvironment);

  angular.module('myApp.config', []);

  for (let i = 0; i < environmentEntries.length; i++) {
    angular.module('myApp.config').constant(environmentEntries[i][0], environmentEntries[i][1]);
  }

  angular
    .module('myApp')
    .constant('LANGUAGE', getLanguage())
    .constant('OverlayScrollbars', OverlayScrollbars)
    .constant('Papa', Papa); // Парсер CSV файлов

  function getLanguage() {
    var ipCookie = angular.injector(['ipCookie']).get('ipCookie');
    var DEFAULT_LANGUAGE = angular.injector(['myApp.config']).get('DEFAULT_LANGUAGE');

    if (ipCookie('carrotquest_language')) {
      localStorage.setItem('carrotquest_language', ipCookie('carrotquest_language'));
      ipCookie.remove('carrotquest_language', { path: '/' });
    }

    return localStorage.getItem('carrotquest_language') || DEFAULT_LANGUAGE;
  }
})();

import { Validators } from '@angular/forms';
import { translate } from '@jsverse/transloco';
import { startWith } from 'rxjs/operators';

import { BotMeetingActionBodyJson } from '@http/chat-bot/actions';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';

import { BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

export class BotMeetingActionForm extends BaseBotActionForm<BotMeetingActionBodyJson> {
  get allTouchedChanges$() {
    return extractTouchedChanges(this.controls.bodyJson).pipe(startWith(this.controls.bodyJson.touched));
  }

  getCustomControls(
    action: ChatBotAction<BotMeetingActionBodyJson>,
  ): CustomControlsWithBodyJsonRequired<BotMeetingActionBodyJson> {
    const textButton = translate('classes.action.canvasText.meeting.bookButton');

    return {
      body: new GenericFormControl(textButton),
      bodyJson: new GenericFormGroup<BotMeetingActionBodyJson>({
        href: new GenericFormControl(action.bodyJson.href || null, Validators.required),
        name: new GenericFormControl(action.bodyJson.name || null),
        integration: new GenericFormControl(action.bodyJson.integration || null),
      }),
    };
  }
}

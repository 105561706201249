import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { TriggerChainStep } from '@panel/app/http/trigger-chain/internal-types';

/**
 * Сервис предназначен для того, чтоб не прокидывать указанные ивенты через все "дерево"
 * до основного редактора из каждого отдельного компонента
 */
@Injectable()
export class TriggerChainStepEditorInteractionsService {
  readonly copyClick: Subject<TriggerChainStep> = new Subject<TriggerChainStep>();

  readonly closeClick: Subject<void> = new Subject<void>();

  readonly deleteClick: Subject<TriggerChainStep> = new Subject<TriggerChainStep>();
}

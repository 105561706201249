import { TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Container, Text } from 'pixi.js';
import { UUID } from 'short-uuid';

import { spliceText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import {
  TRIGGER_CHAIN_BLOCK_NAME_MAX_LENGTH,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { BlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';

/**
 * Абстрактный класс view шага
 */
export abstract class AbsBlockBaseView<Type extends TRIGGER_CHAIN_BLOCK_TYPE = any> implements IBlockView {
  uuid: UUID;

  data: Extract<BlockViewData, { type: Type }>;

  graphicContainer: Container = new Container();

  connectionPoint: Container | null = null;

  blockOverlayContainer: Container | null = null;

  blockOverlayWidthCut?: number;

  interactiveBlockParts: AbsInteractiveBlockPartView[] = [];

  interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory;

  zoomScalableContainer: Container | null = null;

  get type(): Type {
    return this.data.type;
  }

  abstract render(): void;

  protected constructor(
    data: Extract<BlockViewData, { type: Type }>,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    protected readonly transloco: TranslocoService,
    protected readonly renderer: Renderer,
  ) {
    this.data = data;
    this.uuid = this.data.uuid;

    this.graphicContainer.name = `StepBlockView :: ${this.data.type} :: ${this.data.uuid}`;

    this.interactiveBlockPartViewFactory = interactiveBlockPartViewFactory;
    this.render.call(this);
  }

  rerender(): void {
    this.graphicContainer.removeChildren();

    this.render();
  }

  scaleScalableContainer(scale: number): void {
    if (!this.zoomScalableContainer) {
      return;
    }

    let oldContainerHeight = this.zoomScalableContainer.height;

    this.zoomScalableContainer.scale.set(scale);

    let newX = this.zoomScalableContainer.x;
    let newY = this.zoomScalableContainer!.y - (this.zoomScalableContainer.height - oldContainerHeight);
    this.zoomScalableContainer.position.set(newX, newY);

    let textNode = this.zoomScalableContainer.getChildAt(1);
    if (textNode instanceof Text) {
      textNode.text = spliceText(this.data.name, TRIGGER_CHAIN_BLOCK_NAME_MAX_LENGTH / scale);
    }
  }
}

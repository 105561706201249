<ng-container *transloco="let t">
  <div class="card align-items-center">
    <div class="card-body">
      <ngb-alert
        [dismissible]="false"
        type="secondary"
      >
        <h4>{{ t('vkOldComponent.alert.heading') }}</h4>
        <div
          [innerHTML]="
            t('vkOldComponent.alert.description', {
              integrationTypeName: integrationTypeName,
              projectName: projectName
            })
          "
        ></div>
      </ngb-alert>

      <cq-vk-forms-api-token
        [control]="apiTokenControl"
        [disabled]="true"
      ></cq-vk-forms-api-token>

      <hr />
      <cq-vk-forms-confirm-code
        [control]="confirmCodeControl"
        [disabled]="true"
      ></cq-vk-forms-confirm-code>

      <hr />
      <cq-vk-forms-webhook-url [webhookUrl]="webhookUrl"></cq-vk-forms-webhook-url>

      <hr />
      <cq-integration-name-control
        [control]="nameControl"
        [disabled]="true"
      ></cq-integration-name-control>
    </div>
  </div>
</ng-container>

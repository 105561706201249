(function () {
  'use strict';

  angular.module('myApp.chatSettings').component('cqSoundNotification', cqSoundNotification());

  function cqSoundNotification() {
    return {
      bindings: {
        chatNotifications: '=', // Звуковые уведомления для сообщений в чате
        triggerMessagesNotifications: '=', // Звуковые уведомления для автосообщений и ручных рассылок
      },
      controllerAs: 'vm',
      template: require('./sound-notification.html'),
    };
  }
})();

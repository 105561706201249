<div class="modal-header">
  <h3
    *ngIf="!isEdit"
    class="modal-title"
  >
    {{ 'modals.messageDirectoryEditor.heading.creating' | transloco }}
  </h3>
  <h3
    *ngIf="isEdit"
    class="modal-title"
  >
    {{ 'modals.messageDirectoryEditor.heading.renaming' | transloco }}
  </h3>
  <button
    class="close"
    (click)="ngbActiveModal.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body">
  <div
    *ngIf="!isEdit"
    class="margin-bottom-20"
  >
    {{ 'modals.messageDirectoryEditor.setNameForNewDirectory' | transloco }}
  </div>
  <div
    *ngIf="isEdit"
    class="margin-bottom-20"
  >
    {{
      'modals.messageDirectoryEditor.setNameForDirectory' | transloco: { name: modalWindowParams.directory.prettyName }
    }}
  </div>
  <form
    autocomplete="off"
    novalidate
  >
    <div
      class="form-group no-margin-bottom"
      [cqShowError]="directoryForm.controls.directoryName"
    >
      <input
        class="form-control"
        autofocus
        [placeholder]="'modals.messageDirectoryEditor.directoryNameInput.placeholder' | transloco"
        [formControl]="directoryForm.controls.directoryName"
      />
      <cq-validation-messages [control]="directoryForm.controls.directoryName">
        <cq-validation-message errorName="required">
          {{ 'modals.messageDirectoryEditor.directoryNameInput.errors.required' | transloco }}
        </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{
            'modals.messageDirectoryEditor.directoryNameInput.errors.maxlength'
              | transloco: { maxLength: MAX_DIRECTORY_NAME_LENGTH }
          }}
        </cq-validation-message>
        <cq-validation-message errorName="apiMessageDirectoryAlreadyExists">
          {{ 'modals.messageDirectoryEditor.directoryNameInput.errors.apiMessageDirectoryAlreadyExists' | transloco }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    *ngIf="isEdit"
    class="btn btn-outline-primary"
    (click)="openRemoveDirectoryModal()"
    [disabled]="directoryForm.invalid || isApiRequestPerformed"
    type="button"
  >
    {{ 'modals.messageDirectoryEditor.removeButton' | transloco }}
  </button>
  <button
    *ngIf="!isEdit"
    class="btn btn-primary"
    (click)="confirm()"
    [disabled]="directoryForm.invalid || isApiRequestPerformed"
    type="button"
  >
    {{ 'modals.messageDirectoryEditor.createButton' | transloco }}
  </button>
  <button
    *ngIf="isEdit"
    class="btn btn-primary"
    (click)="confirm()"
    [disabled]="directoryForm.invalid || isApiRequestPerformed"
    type="button"
  >
    {{ 'modals.messageDirectoryEditor.renameButton' | transloco }}
  </button>
</div>

import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment/moment';
import { merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { environment } from '@environment';
import { TIME_FORMATS } from '@panel/app/pages/conversations/conversations-list/time-since-last-reply/time-since-last-reply.constants';
import { GLOBAL_TICKER } from '@panel/app/shared/tokens/global-ticker.token';

/** Компонент для отображения времени, прошедшего с последнего сообщения */
@Component({
  selector: 'cq-time-since-last-reply[replyTime]',
  templateUrl: './time-since-last-reply.component.html',
  styleUrls: ['./time-since-last-reply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSinceLastReplyComponent implements OnInit, OnChanges {
  /** Время отправки реплики */
  @Input()
  replyTime!: moment.Moment | number;

  /** Эмитит replyTime при его изменении */
  replyTimeChange$: Subject<moment.Moment | number> = new Subject<moment.Moment | number>();

  /** Количество времени, прошедшее с отправки реплики */
  timeSinceLastReply$!: Observable<string>;

  constructor(
    private readonly translocoService: TranslocoService,
    @Inject(GLOBAL_TICKER)
    private readonly globalTicker$: Observable<void>,
  ) {}

  ngOnInit() {
    const replyTimeTicker$ = this.globalTicker$.pipe(map(() => this.replyTime));

    this.timeSinceLastReply$ = merge(this.replyTimeChange$, replyTimeTicker$).pipe(
      startWith(this.replyTime),
      map((replyTime) => {
        let time = moment.isMoment(replyTime) ? replyTime : moment(replyTime * 1000);

        return this.prettifyTime(time);
      }),
      distinctUntilChanged(),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.replyTime.currentValue) {
      this.replyTimeChange$.next(changes.replyTime.currentValue);
    }
  }

  /**
   * Приведение времени к необходимому формату отображения
   *
   * @param time Время отправки реплики
   * @private
   */
  private prettifyTime(time: moment.Moment): string {
    /** Текущее время */
    const now = moment();
    /** Разница в минутах между переданным временем и текущим */
    const diffByMinutes = now.diff(time, 'minutes');

    if (diffByMinutes === 0) {
      return this.translocoService.translate('timeSinceLastReplyComponent.minutesAgo', { count: 1 });
    } else if (diffByMinutes < 30) {
      return this.translocoService.translate('timeSinceLastReplyComponent.minutesAgo', {
        count: diffByMinutes,
      });
    } else if (now.isSame(time, 'day')) {
      return time.format(TIME_FORMATS[environment.language].exactTime);
    } else if (now.isSame(time, 'year')) {
      return time.format(TIME_FORMATS[environment.language].isLastDay);
    } else {
      return time.format(TIME_FORMATS[environment.language].isLastYear);
    }
  }
}

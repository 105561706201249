import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { ComponentsPreviewForm } from '@panel/app/shared/visual-components/preview-wrapper/preview-wrapper.form';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

/** Компонент-обёртка для просмотра компонентов из Angular */
@Component({
  selector: 'cq-preview-wrapper',
  templateUrl: './preview-wrapper.component.html',
  styleUrls: ['./preview-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewWrapperComponent {
  form!: ComponentsPreviewForm;

  constructor(readonly fileHelper: FileHelperService) {
    this.form = new ComponentsPreviewForm(null);
  }

  get fileControl() {
    return this.form.controls.file;
  }

  /**
   * Stepper
   */
  validationStepValue = false;

  get stepperFalseValidator() {
    return () => false;
  }

  get stepperOnExit() {
    return () =>
      of(null).pipe(
        delay(1000),
        tap(() => console.log('Выполнен хук onExit')),
      );
  }

  get stepperAsyncValidator() {
    return () => of(this.validationStepValue).pipe(delay(1000));
  }

  get stepperOnEnter() {
    return () =>
      of(null).pipe(
        delay(1000),
        tap(() => console.log('Выполнен хук onEnter')),
      );
  }
  /**
   * Stepper end
   */

  /**
   * DatePicker
   */

  datePickerModel = moment();
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { copy } from 'angular';
import filter from 'lodash-es/filter';
import isEqual from 'lodash-es/isEqual';
import moment from 'moment';
import Moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  BILLING_ADD_ONS,
  BILLING_ADDONS_VERSION_POSTFIX,
  SUBSCRIPTION_STATUSES,
} from '@panel/app/services/billing-info/billing-info.constants';
import {
  ApiChangePlanRequest,
  ApiDefaultResponse,
  ApiGenerateTmpInvoiceParams,
  ApiGenerateTmpInvoiceRequest,
  ApiGenerateTmpInvoiceResponse,
  ApiGetAppQuotasRequest,
  ApiGetTariffInfoRequest,
  ApiSendStripePaymentIntentRequest,
} from '@panel/app/services/billing-info/billing-info.types';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

/**
 * Информация о блокировках приложения
 *
 * TODO Вынести тип в отдельный файл
 */
export type AppBlocks = {
  /** Максимальное количество членов команды (суперадминов + админов + операторов) */
  adminsLimit: number;
  /** Блокировка вообще всех емейлов, включая системные (для ручного и эвристического отключения спамеров, не автоматикой) */
  blockAllEmails: boolean;
  /** Блокировка массовых рассылок */
  blockBulkEmails: boolean;
  /** Отправка email заблокирована из-за достижения hard-квоты */
  blockEmails: boolean;
  /** Блокировать ли отправку email после достижения hard-квоты по тарифу */
  blockEmailsHardQuota: boolean;
  /** Блокировать ли отправку email после достижения обычной квоты по тарифу */
  blockEmailsOverQuota: boolean;
  /** Сбор лидов заблокирован из-за достижения hard-квоты */
  blockUsers: boolean;
  /** Нужно ли блокировать сбор уников и панельку после достижения hard-квоты по тарифу */
  blockUsersHardQuota: boolean;
  /**
   * Блокировать ли сбор уников после достижения обычной квоты по тарифу
   * IMPORTANT! Согласно инфо от бэкендеров, флаг там нигде не меняется и всегда будет false
   */
  blockUsersOverQuota: boolean;
  /** Hard-квота для писем по тарифу */
  emailHardLimit: number;
  /** Обычная квота для писем по тарифу */
  emailPlanLimit: number;
  /** Количество израсходованных на данный момент емейлов в текущем биллинговом периоде */
  emailUsed: number;
  /** Есть ли у пользователя доступ к дашборду */
  hasDashboard: boolean;
  /** У аппа кастомный домен и большой уровень баунса за последние 30 дней */
  hasHighBounceRate: boolean;
  /** У аппа кастомный домен и большой уровень спама за последние 30 дней */
  hasHighComplaintRate: boolean;
  /** У пользователя кастомный домен и он всё ещё на прогреве */
  hasUntrustedDomain: boolean;
  /** Максимальное количество интеграций */
  integrationsLimit: number;
  /** Информация о непрогретом домене */
  untrustedDomainStats: {
    /** Количество bounce (возможно spam тоже, как сказал Миша) с прогреваемого домена */
    emailBounceCount: number;
    /** Количество емейлов, отправленных с прогреваемого домена */
    emailsCount: number;
    /** Количество емейлов, которые нужно набрать, чтобы закончить прогрев домена */
    unblockEmailLimitForUntrustedDomain: number;
  };
  /** Обычная квота уников по тарифу */
  usersPlanLimit: number;
  /** Количество собранных на данный момент уников в текущем биллинговом периоде */
  usersUsed: number;
};

/** TODO Вынести тип в отдельный файл */
export type BillingInfoCustomer = {
  allow_direct_debit: boolean;
  auto_collection: string;
  balances: Balance[];
  card_status: string;
  cf_referal: string;
  cf_tid: string;
  created_at: Moment.Moment;
  deleted: boolean;
  email: string;
  excess_payments: number;
  first_name: string;
  id: string;
  net_term_days: number;
  object: string;
  phone: string;
  pii_cleared: string;
  preferred_currency_code: string;
  promotional_credits: number;
  refundable_credits: number;
  resource_version: Moment.Moment;
  taxability: string;
  unbilled_charges: number;
  updated_at: Moment.Moment;
};

/** TODO Вынести тип в отдельный файл */
type Balance = {
  balance_currency_code: string;
  currency_code: string;
  excess_payments: number;
  object: string;
  promotional_credits: number;
  refundable_credits: number;
  unbilled_charges: number;
};

/** TODO Вынести тип в отдельный файл */
type Requisites = {
  organization_address: string;
  organization_fio: string;
  organization_inn: string;
  organization_kpp: string;
  organization_name: string;
  organization_ogrn: string;
};

/** TODO Вынести тип в отдельный файл */
export type BillingInfoSubscription = {
  activated_at?: Moment.Moment;
  /** Подключенные аддоны  */
  addons?: BillingInfoAddOn[];
  auto_collection?: string;
  base_currency_code?: string;
  /** Платёжный период */
  billing_period: number;
  billing_period_unit: string;
  channel?: string;
  coupon?: string;
  coupons?: BillingInfoCoupon[];
  created_at: Moment.Moment;
  currency_code: string;
  /** Дата окончания платёжного периода */
  current_term_end?: Moment.Moment;
  current_term_start?: Moment.Moment;
  customer_id: string;
  deleted: boolean;
  due_invoices_count: number;
  due_since?: Moment.Moment;
  exchange_rate?: number;
  has_scheduled_changes: boolean;
  id: string;
  /** Ежемесячная стоимость подписки (тариф/мес + аддоны/мес) с учётом действующих скидок, которую платит пользователь */
  mrr?: number;
  next_billing_at: Moment.Moment;
  object: string;
  plan_amount?: number;
  plan_free_quantity: number;
  plan_id: string;
  plan_quantity: number;
  plan_unit_price: number;
  previous_term_end?: number;
  previous_term_start?: number;
  resource_version: Moment.Moment;
  started_at: Moment.Moment;
  /** Статус подписки */
  status: SUBSCRIPTION_STATUSES;
  total_dues?: number;
  trial_end: Moment.Moment;
  trial_start: Moment.Moment;
  updatedAt: number;
};

/** TODO Вынести тип в отдельный файл */
/** Аддон */
export type BillingInfoAddOn = {
  /** Стоимость аддона, которую платит пользователь за месяц */
  amount: number;
  /** Id аддона */
  id: BILLING_ADD_ONS;
  /** Количество единиц */
  quantity: number;
  /** Стоимость единицы */
  unit_price: number;
};

/** TODO Вынести тип в отдельный файл */
export type BillingInfoCoupon = {
  applied_count: number;
  coupon_id: string;
  object: 'coupon';
};

/**
 * Тип биллинга
 *
 * TODO Вынести тип в отдельный файл
 */
export type BillingInfo = {
  addons: BILLING_ADD_ONS[];
  booker_emails: string[];
  card_email: string;
  card_last4: string;
  contract_data: null | string | Requisites;
  customer: {} | BillingInfoCustomer;
  id: string;
  subscription: {} | BillingInfoSubscription;
};

export type ApiFeatureCounterActivitiesResponse = {
  activeAutoMessagesTotal: number;
  activeLeadBotsTotal: number;
  knowledgeBaseArticlesTotal: number;
  manualChannelsTotal: number;
  autoSetChannelsExist: boolean;
  messengerVoteIsOn: boolean;
  routingBotIsActive: boolean;
};

/**
 * Типа ID тарифов
 *
 * TODO Вынести тип в отдельный файл
 */
export type BillingPlanIds = {
  AUTOMATION: string;
  BUSINESS_CHAT: string;
  FREEMIUM: string;
  FREE_TRIAL: string;
  FREE_FOREVER: string;
  LIFE_TIME_DEAL: string;
  LIFE_TIME_DEAL_PRO: string;
  LIFE_TIME_DEAL_BUSINESS: string;
  LIFE_TIME_DEAL_15K: string;
  LIFE_TIME_DEAL_20K: string;
  LIFE_TIME_DEAL_V2023_CONVERSATION_1: string;
  LIFE_TIME_DEAL_V2023_CONVERSATION_2: string;
  LIFE_TIME_DEAL_V2023_MARKETING_1: string;
  LIFE_TIME_DEAL_V2023_MARKETING_2: string;
  LIFE_TIME_DEAL_V2023_MARKETING_3: string;
  CONVERSATION: string;
  SUPPORT: string;
  MARKETING: string;
};

/**
 * Информация о тарифе плане
 *
 * TODO Вынести тип в отдельный файл
 */
export type TariffInfo = {
  id: string;
  name: string;
  status: string;
  isLifetimeDeal: boolean;
  isKnowledgeBaseEnabled: boolean;
  invoiceName: string;
  limits: {
    emailsHard: number;
    users: number;
    emails: number;
    /** Стоимость перерасхода за уникального пользователя */
    userPrice: number;
    /** Стоимость перерасхода за email */
    emailPrice: number;
    usersHard: number;
  };
  price: number;
  period: number;
  periodUnit: string;
  currencyCode: string;
};

/**
 * Тип квоты
 *
 * TODO ANGULAR_TS сделать описание квот
 * TODO Вынести тип в отдельный файл
 */
export type Quotas = {
  users: number;
  usersMerged: number;
  usersTotal: number;
  activeLeads: number;
  activeLeadsMerged: number;
  activeLeadsTotal: number;
  endTermTotalLeads: number;
  /** Израсходованное количество emails для триггерных сообщений */
  emailsAuto: number;
  /** Израсходованное количество emails для ручных сообщений */
  emailsManual: number;
  /** Израсходованное количество emails для уведомлений членам команды о диалогах */
  emailsAdminNotifications: number;
  /** Израсходованное количество emails для уведомлений о лидах */
  emailsNotificationsLeads: number;
  /** Израсходованное количество emails для уведомлений о непрочитанных диалогах в чате пользователям */
  emailsUserNotifications: number;
  /** Израсходованное количество emails для уведомлений о Double Opt-In писем */
  emailsUserConfirmationNotifications: number;
  /** Израсходованное количество emails */
  emailsTotal: number;
  pushesAuto: number;
  pushesManual: number;
  pushesTotal: number;
  users1: number;
  users7: number;
  users30: number;
  usersExceptMerged: number; // usersExceptMerged === usersTotal
  emails: number; // emails === emailsTotal
  users1ExceptMerged: number; // users1ExceptMerged === users1
  users7ExceptMerged: number; // users7ExceptMerged === users7
};

/**
 * Сервис для работы с системой биллинга (апами, сайтами)
 */
@Injectable({ providedIn: 'root' })
export class BillingInfoModel {
  // HACK: не понятно что делать с обновлением объектов и референсов, поэтому сделал так
  public billingInfo = {} as BillingInfo;

  // Информация о тарифе
  public planInfo = {} as TariffInfo;

  /** Информация о квотах */
  public quotas = {} as Quotas;

  /**
   * Нужен, чтоб при обновлении BillingInfo или его внутреностей
   * можно было сообщить об этом всем компонентам в новом ангуляре и они подтянули изменения
   *
   * Использование: Если что-то обновил, вызови appModel.refresh$.next();
   */
  readonly refresh$ = new Subject();

  constructor(private http: HttpClient) {}

  /**
   * Добавление карты
   */
  public addCard(appId: string): Observable<ApiDefaultResponse> {
    return this.http.post<ApiDefaultResponse>(`/apps/${appId}/billing/addcard`, undefined, {
      context: new HttpContext().set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Смена тарифного плана
   * !!! Пока что только с триала на фримиум или с фримиума на платный тариф
   *
   * @param appId ID приложения
   * @param planId ID плана, на который нужно переключить апп
   */
  public changePlan(appId: string, planId: string): Observable<ApiDefaultResponse> {
    let params: ApiChangePlanRequest = {
      plan: planId,
    };

    return this.http.post<ApiDefaultResponse>(`/apps/${appId}/billing/changeplan`, params, {
      context: new HttpContext().set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Обновление информации об аппе в Chargebee
   * HACK Это костыль, связанный с быстрой регистрацией: при регистрации мы заполянем название аппа за пользователя, но далее в онбординге он его вводит сам.
   *  После этого ввода нам нужно обновить информацию в Chargebee
   * TODO Выпилить в будущем и заменить на appModel.saveSettings
   *
   * @param appId ID приложения
   */
  public customerSync(appId: string): Observable<ApiDefaultResponse> {
    return this.http.post<ApiDefaultResponse>(`/apps/${appId}/billing/forcecustomersync/`, undefined, {
      context: new HttpContext().set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Комментарий из cqapi:
   * Генерирует временный invoice. Реальный Invoice в ChargeBee не создается
   *
   * @param appId ID приложения
   * @param params
   */
  public generateTmpInvoice(
    appId: string,
    params: ApiGenerateTmpInvoiceParams,
  ): Observable<ApiGenerateTmpInvoiceResponse> {
    let requestParams: ApiGenerateTmpInvoiceRequest = {
      amount: params.amount,
      initial: params.initial,
      plan_id: params.planId,
    };

    if (params.addons) {
      requestParams.addons = params.addons;
    }

    return this.http.post<ApiGenerateTmpInvoiceResponse>(`/apps/${appId}/billing/generatetmpinvoice`, requestParams, {
      context: new HttpContext().set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Проверка наличия модуля
   *
   * @param billingInfo Информация о биллинге
   * @param addOn Модуль
   * @param soft Флаг для мягкого сравнения аддонов.
   *  Напр. есть аддон welcome-bots-units-pricing(1) и welcome-bots-units-pricing-v0224(2).
   *  Если указать soft=true, то при подключенном аддоне (1), аддон (2) будет считаться тоже подключенным
   */
  public hasAddOn(billingInfo: BillingInfo, addOn: BILLING_ADD_ONS, soft: boolean = false): boolean {
    const postfixList = soft ? ['', ...BILLING_ADDONS_VERSION_POSTFIX] : [''];

    return !!filter(billingInfo.addons, (addon) => postfixList.some((postfix) => isEqual(`${addon}${postfix}`, addOn)))
      .length;
  }

  /**
   * Получение информации о биллинге
   *
   * @param appId ID приложения
   */
  public get(appId: string): Observable<BillingInfo> {
    return this.http
      .get<any>(`/apps/${appId}/billing`, {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response) => {
          // HACK: не понятно что делать с обновлением объектов и референсов, поэтому сделал так
          this.parse(response.data);

          copy(response.data, this.billingInfo);

          return this.billingInfo;
        }),
      );
  }

  /**
   * Получение информации о блокировках приложения по его ID
   *
   * @param appId ID приложения
   */
  public getAppBlocks(appId: string): Observable<AppBlocks> {
    return this.http
      .get<any>(`/apps/${appId}/billing/blocks`, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(map((response) => response.data));
  }

  /**
   * Получение счётчиков по приложению
   *
   * @param appId ID приложения
   * @param ignoreLoadingBar Игнорировать или нет loading bar
   */
  public getAppQuotas(appId: string, ignoreLoadingBar: boolean): Observable<Quotas> {
    let params: ApiGetAppQuotasRequest = {
      ignoreLoadingBar: ignoreLoadingBar,
    };

    return this.http
      .get<any>(`/apps/${appId}/billing/usage`, {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response) => {
          let quotas = response.data;

          this.parseQuotas(quotas);

          copy(quotas, this.quotas);

          return this.quotas;
        }),
      );
  }

  /**
   * Отправка платежного намерения в Stripe
   *
   * @param appId - Id приложения
   * @param code - Code от Invoice или TmpInvoice
   */
  public sendStripePaymentIntent(appId: string, code: string): Observable<ApiDefaultResponse> {
    let params: ApiSendStripePaymentIntentRequest = {
      code: code,
    };

    return this.http.post<ApiDefaultResponse>(`/apps/${appId}/billing/initpayment`, params, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Получение счетчиков и активностей
   * Количество триггерных сообщений, лидботов, статей в бз, каналов для диалогов
   *
   * @param appId - Id приложения
   */
  public getBillingFeatureActivitiesCounter(appId: string): Observable<ApiFeatureCounterActivitiesResponse> {
    return this.http.get<ApiFeatureCounterActivitiesResponse>('/billing/feature_activities_counter', {
      params: { app: appId },
    });
  }

  /**
   * Получить информацию по тарифу по ID приложения
   *
   * @param appId ID приложения
   * @param ignoreLoadingBar Игнорировать или нет loading bar
   */
  public getTariffInfo(appId: string, ignoreLoadingBar: boolean): Observable<TariffInfo> {
    let params: ApiGetTariffInfoRequest = {
      ignoreLoadingBar: ignoreLoadingBar,
    };

    return this.http
      .get<any>(`/apps/${appId}/billing/plan`, {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response) => {
          copy(response.data, this.planInfo);

          return response.data;
        }),
      );
  }

  /**
   * Парсинг информации о биллинге
   *
   * @param billingInfo Информация о биллинге
   */
  // TODO ANGULAR_TS Добавить тип для billingInfo
  parse(billingInfo: any) {
    // FIXME: пока решено не переводить ключи в camelCase, слишком много изменений за раз, боюсь что-нибудь сломать
    //this.caseStyleHelper.keysToCamelCase(billingInfo);

    // пока закомментил парсинг, он ломает логику работы
    //parseAddOns(billingInfo.addons);

    // contract_data иногда может не прийти, если биллинг не подключен (на локалке и бетах)
    if (!billingInfo.contract_data) {
      billingInfo.contract_data = null;
    }

    // а на какой-то из веток, в которой не обновлён серриалайзер, может прийти JSON, а не объект
    if (typeof billingInfo.contract_data === 'string') {
      billingInfo.contract_data = JSON.parse(billingInfo.contract_data);
    }

    // customer иногда может не прийти, если биллинг не подключен (на локалке и бетах)
    if (Object.keys(billingInfo.customer).length) {
      billingInfo.customer.created_at = moment(billingInfo.customer.created_at * 1000);
      billingInfo.customer.resource_version = moment(billingInfo.customer.resource_version);
      billingInfo.customer.updated_at = moment(billingInfo.customer.updated_at * 1000);
    } else {
      billingInfo.customer = {};
    }

    // subscription иногда может не прийти, если биллинг не подключен (на локалке и бетах)
    if (Object.keys(billingInfo.subscription).length) {
      billingInfo.subscription.activated_at = moment(billingInfo.subscription.activated_at * 1000);
      billingInfo.subscription.created_at = moment(billingInfo.subscription.created_at * 1000);
      billingInfo.subscription.current_term_end = moment(billingInfo.subscription.current_term_end * 1000);
      billingInfo.subscription.current_term_start = moment(billingInfo.subscription.current_term_start * 1000);
      billingInfo.subscription.due_since = moment(billingInfo.subscription.due_since * 1000);
      billingInfo.subscription.next_billing_at = moment(billingInfo.subscription.next_billing_at * 1000);
      billingInfo.subscription.resource_version = moment(billingInfo.subscription.resource_version);
      billingInfo.subscription.started_at = moment(billingInfo.subscription.started_at * 1000);
      billingInfo.subscription.trial_end = moment(billingInfo.subscription.trial_end * 1000);
      billingInfo.subscription.trial_start = moment(billingInfo.subscription.trial_start * 1000);
      billingInfo.subscription.updatedAt = moment(billingInfo.subscription.trial_start * 1000);

      // пока закомментил парсинг, он ломает логику работы
      // модули в подписке не приходят на триале
      /*if (billingInfo.subscription.addons) {
        parseAddOns(billingInfo.subscription.addons);
      }*/
    } else {
      billingInfo.subscription = {};
    }
  }

  /**
   * Парсинг квоты приложения
   *
   * @param quotas Квоты приложения
   */
  private parseQuotas(quotas: Quotas) {
    quotas.usersExceptMerged = quotas.usersTotal;
    quotas.emails = quotas.emailsTotal;
    quotas.users1ExceptMerged = quotas.users1;
    quotas.users7ExceptMerged = quotas.users7;
  }

  get planId() {
    if (!this.subscription.plan_id) {
      throw new Error('There is no plan_id');
    }

    return this.subscription.plan_id;
  }

  get subscription(): BillingInfoSubscription {
    if (!this.billingInfo.subscription) {
      throw new Error('There is no subscription data');
    }

    return this.billingInfo.subscription as BillingInfoSubscription;
  }
}

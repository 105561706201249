/**
 * Валидатор ссылок с параметрами
 */
(function () {
  'use strict';

  angular.module('myApp.directives.urlWithParams').directive('cqUrlWithParams', cqUrlWithParams);

  function cqUrlWithParams() {
    return {
      priority: 2,
      restrict: 'A',
      require: 'ngModel',
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$validators.urlWithParams = urlWithParams;

      /**
       * Валидация значения input
       *
       * @param modelValue Значение, которое записано в модель
       * @param viewValue Значение, которое отображается в input
       * @returns {Boolean}
       */
      function urlWithParams(modelValue, viewValue) {
        // делаем валидацию только тогда, когда значение, которое будет записано в модель не пустое
        return controller.$isEmpty(modelValue) || !~modelValue.search(/[\?#]/);
      }
    }
  }
})();

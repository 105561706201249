import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessagePreviewBrowserWrapperComponent } from './message-preview-browser-wrapper.component';

@NgModule({
  declarations: [MessagePreviewBrowserWrapperComponent],
  exports: [MessagePreviewBrowserWrapperComponent],
  imports: [CommonModule],
})
export class MessagePreviewBrowserWrapperModule {}

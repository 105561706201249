<ng-container *transloco="let t; read: 'aiContentMakerComponent'">
  <ace
    style="height: 300px"
    [config]="aceEditorOptions"
    [value]="control.value"
    (valueChange)="control.setValue($event)"
  ></ace>

  <p class="mt-10">
    Поставьте значение <strong>null</strong> <strong>variables</strong> и <strong>constants</strong>, если они не
    заполнены
  </p>

  <div class="w-100 text-right">
    <button
      class="btn btn-primary"
      type="button"
      (click)="generateContent()"
    >
      Сгенерировать
    </button>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

import { CancellationPreventModalComponent } from './cancellation-prevent-modal.component';

@NgModule({
  declarations: [CancellationPreventModalComponent],
  exports: [CancellationPreventModalComponent],
  imports: [CommonModule, TranslocoModule, MomentModule],
  providers: [NgbActiveModal, NgbModal, SubscriptionStore],
})
export class CancellationPreventModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { SetArticleCategoryModalComponent } from './set-article-category-modal.component';

@NgModule({
  declarations: [SetArticleCategoryModalComponent],
  exports: [SetArticleCategoryModalComponent],
  imports: [CommonModule, SharedModule, TranslocoModule, NgSelectModule, ReactiveFormsModule],
})
export class SetArticleCategoryModalModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/** Компонент для работы с действием "Удалить тег" */
@Component({
  selector: 'cq-tag-remove[intentToRemoveTag]',
  templateUrl: './tag-remove.component.html',
  styleUrls: ['./tag-remove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagRemoveComponent {
  /** Callback на намеренье удалить тег */
  @Output()
  intentToRemoveTag: EventEmitter<void> = new EventEmitter<void>();

  /** Обрабатывает намеренье удалить тег */
  onIntentToRemoveTag(): void {
    this.intentToRemoveTag.emit();
  }
}

(function () {
  'use strict';

  angular.module('myApp.trackMaster').component('cqTrackMasterModalClick', cqTrackMasterModalClick());

  function cqTrackMasterModalClick() {
    return {
      bindings: {
        autoEvent: '=', // Автособытие
      },
      controller: angular.noop,
      controllerAs: 'vm',
      template: require('./track-master-click.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchActionPropertyComponent } from './property.component';

@NgModule({
  declarations: [BranchActionPropertyComponent],
  imports: [CommonModule, SharedModule, PropertySelectorModule, ReactiveFormsModule, TranslocoModule],
  exports: [BranchActionPropertyComponent],
})
export class BranchActionPropertyModule {}

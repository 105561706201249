import { InjectionToken, Provider } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Viewport } from 'pixi-viewport';

import { PIXI_APP, PixiApplication } from './pixi.token';

export const enum VIEWPORT_ZOOM_SETTINGS {
  MIN_PERCENT = 20,
  MAX_PERCENT = 200,
  STEP_PERCENT = 20,
}

export const PIXI_VIEWPORT = new InjectionToken<Viewport>('Wrapper for pixi-viewport');

export const pixiViewportProvider: Provider = {
  provide: PIXI_VIEWPORT,
  useFactory: (app: PixiApplication, window: Window) => {
    const viewport = new Viewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      events: app.renderer.events,
      ticker: app.ticker,
    });
    viewport.sortableChildren = true;
    viewport
      .wheel()
      .drag()
      .clampZoom({
        minScale: VIEWPORT_ZOOM_SETTINGS.MIN_PERCENT / 100,
        maxScale: VIEWPORT_ZOOM_SETTINGS.MAX_PERCENT / 100,
      });
    app.stage.addChild(viewport);

    return viewport;
  },
  deps: [PIXI_APP, WINDOW],
};

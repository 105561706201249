import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  InjectionToken,
  NgZone,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { tuiPure } from '@taiga-ui/cdk';
import { Subject } from 'rxjs';

import { AUTO_EVENTS_GROUPS, AUTO_EVENTS_GROUPS_ICONS } from '@http/track-master/track-master.constants';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

export type SelectableEventType = Exclude<AUTO_EVENTS_GROUPS, AUTO_EVENTS_GROUPS.PRO>;

/**
 * Позволяет передать кастомный набор Event-ов для селекторе
 */
export const EVENT_TYPE_SELECTOR_OPTIONS = new InjectionToken<SelectableEventType[]>(
  'Список опций, которые доступны для выбора в EventTypeSelector компоненте',
);

@Component({
  selector: 'cq-event-type-selector',
  templateUrl: './event-type-selector.component.html',
  styleUrls: ['./event-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTypeSelectorComponent extends AbstractCVAControl<SelectableEventType> {
  control = new GenericFormControl<SelectableEventType>(null, Validators.required);

  eventTypeSelectorOptions: SelectableEventType[] = [
    AUTO_EVENTS_GROUPS.URL,
    AUTO_EVENTS_GROUPS.URL_SCROLL,
    AUTO_EVENTS_GROUPS.CLICK,
    AUTO_EVENTS_GROUPS.INPUT,
  ];

  constructor(
    @Optional()
    @Inject(EVENT_TYPE_SELECTOR_OPTIONS)
    eventTypeSelectorOptions: SelectableEventType[] | null,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);

    if (eventTypeSelectorOptions !== null) {
      this.eventTypeSelectorOptions = eventTypeSelectorOptions;
    }
  }

  @Output()
  eventTypeChange = new Subject<SelectableEventType>();

  @tuiPure
  getIconForEventGroup(group: SelectableEventType): string {
    return AUTO_EVENTS_GROUPS_ICONS[group];
  }
}

/**
 * Контроллер для универсального модального окна подтверждения
 *
 * При создании модального окна в resolve можно передать modalWindowParams - объект следующего вида:
 *    {String=} heading Текст заголовка модального окна
 *    {String=} body Текст тела модального окна
 *    {String=} imgUrl Ссылка на картинку
 *    {Boolean=} needConfirmation Нужно ли от пользователя подтвеждение его действия (отображает чекбокс, который надо отметить)
 *    {String=} confirmationText Текст рядом с чекбоксом
 *    {String=} confirmButtonClass Класс для кнопки подтвеждения
 *    {String=} confirmButtonText Текст кнопки подтвеждения
 *    {String=} cancelButtonClass Класс для кнопки отмены
 *    {String=} cancelButtonText Текст кнопки отмены
 *    {String=} requestMethod Метод запроса (GET, POST и т.д.)
 *    {String=} requestUrl URL запроса
 *    {Object=} requestParams Параметры запроса
 *    {Function=} confirmHandler Используется как замена предыдущим полям для APIRequest (например, в случае, когда есть готовый сервис для работы с сущностью) и имеет приоритет над ними
 *    {Array=} confirmHandlerParams Параметры для вызова confirmHandler
 * При нажатии на кнопку подтверждения - выполняется функция confirmHandler, и результат, который она вернёт, возвращается в value в successCallback в result.then
 */
(function () {
  'use strict';

  angular.module('myApp.modals.confirm').controller('ConfirmModalController', ConfirmModalController);

  function ConfirmModalController(
    $interpolate,
    $q,
    $translate,
    $uibModalInstance,
    APIRequest,
    validationHelper,
    modalWindowParams,
  ) {
    var vm = this;

    vm.cancelButtonClass = modalWindowParams.cancelButtonClass || 'btn-outline-primary';
    vm.cancelButtonText = modalWindowParams.cancelButtonText || $translate.instant('general.cancel');
    vm.confirm = confirm;
    vm.confirmButtonClass = modalWindowParams.confirmButtonClass || 'btn-primary';
    vm.confirmButtonText = modalWindowParams.confirmButtonText || $translate.instant('general.remove');
    vm.modalWindowParams = modalWindowParams;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      modalWindowParams.heading = modalWindowParams.heading ?? '';
      modalWindowParams.body = modalWindowParams.body ?? '';
    }

    /**
     * Обработка нажатия на кнопку подтверждения
     *
     * @param isValid Валидация формы
     */
    function confirm(isValid) {
      if (isValid) {
        if (modalWindowParams.confirmHandler) {
          // тут происходит следующая магия:
          // при помощи apply вызывается функция confirmHandler от параметров confirmHandlerParams
          // затем при помощи $q.resolve результат этой функции превращается в Promise (если фунуция сама вернула Promise - ничего не произойдёт, читать доки ангуляра)
          // successCallback в then выполнится только тогда, когда свежесозданный Promise при помощи $q.resolve будет resolve
          $q.resolve(
            modalWindowParams.confirmHandler.apply(null, modalWindowParams.confirmHandlerParams.slice(0)),
          ).then(function (data) {
            $uibModalInstance.close(data);
          });
        } else if (modalWindowParams.requestMethod && modalWindowParams.requestUrl) {
          APIRequest(
            modalWindowParams.requestMethod,
            modalWindowParams.requestUrl,
            modalWindowParams.requestParams,
          ).then((response) => {
            $uibModalInstance.close(response.data);
          });
        } else {
          $uibModalInstance.close();
        }
      }
    }
  }
})();

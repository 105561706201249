import { Directive, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[cqFile]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileDirective),
      multi: true,
    },
  ],
})
export class FileDirective implements ControlValueAccessor {
  onChange = (file: File | null) => {};
  onTouched = () => {};

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.renderer.listen(this.elementRef.nativeElement, 'change', this.changeListener.bind(this));
  }

  writeValue(value: any): void {
    // Если значение равно null, обнуляем input
    if (value === null) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
    }
  }

  registerOnChange(fn: (file: File | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  private changeListener(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;

    // Устанавливаем новое значение
    this.onChange(file);

    // Маркируем поле как 'touched'
    this.onTouched();
  }
}

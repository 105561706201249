import { ComponentRef, Inject, Injectable, Type, ViewContainerRef } from '@angular/core';
import { Container } from 'pixi.js';
import { Viewport } from 'pixi-viewport';

import { PIXI_APP, PIXI_VIEWPORT, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { OverlayHostDirective } from '@panel/app/pages/trigger-chains/editor/components/canvas/modules/trigger-chain-overlay/directives/overlay-host.directive';
import { ConnectionService } from '@panel/app/services/canvas/tirgger-chain/connections/connection.service';

export type OverlayComponent<T> = Type<
  T & {
    width: number;
    height: number;
    x: number;
    y: number;
  }
>;

@Injectable()
export class TriggerChainOverlayService {
  private _overlayHost?: OverlayHostDirective;

  constructor(
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
  ) {}

  addOverlay<T>(el: Container, component: OverlayComponent<T>): ComponentRef<T> {
    const overlayStartingPosition = ConnectionService.getRelativePosition(el, this.pixiApp.stage, this.viewport);
    const componentRef = this.viewContainerRef.createComponent(component);
    componentRef.instance.height = el.height * this.viewport.scaled;
    componentRef.instance.width = el.width * this.viewport.scaled;

    if (overlayStartingPosition) {
      componentRef.instance.x = overlayStartingPosition.x;
      componentRef.instance.y = overlayStartingPosition.y;
    }

    this.overlayHost.cdr.detectChanges();
    componentRef.changeDetectorRef.markForCheck();
    return componentRef;
  }

  closeOverlay<T>(overlayRef: ComponentRef<T>) {
    const index = this.viewContainerRef.indexOf(overlayRef.hostView);
    this.viewContainerRef.remove(index);
    overlayRef.changeDetectorRef.detectChanges();
  }

  registerOverlayHost(viewContainerRef: OverlayHostDirective) {
    this._overlayHost = viewContainerRef;
  }

  private get overlayHost(): OverlayHostDirective {
    if (!this._overlayHost) {
      throw new Error('ViewContainerRef is not registered yet');
    }
    return this._overlayHost;
  }

  private get viewContainerRef(): ViewContainerRef {
    return this.overlayHost.viewContainerRef;
  }
}

(function () {
  'use strict';

  require('./chat-color.controller');

  angular.module('myApp.chatSettings').component('cqChatColor', cqChatColor());

  function cqChatColor() {
    return {
      bindings: {
        color: '=', // Цвет бейджа чата
      },
      controller: 'CqChatColorController',
      controllerAs: 'vm',
      template: require('./chat-color.html'),
    };
  }
})();

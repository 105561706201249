import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, pairwise, startWith } from 'rxjs/operators';

import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import { UserProperty } from '@http/property/property.model';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { PropertyFilter } from '@panel/app/services/filter/types/filter.internal-types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

@Component({
  selector: 'cq-property-filter',
  templateUrl: './property-filter.component.html',
  styleUrls: ['./property-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyFilterComponent extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<PropertyFilter>> {
  @Input({ required: true })
  index!: number;

  @Input({ required: true })
  props!: UserProperty[];

  @Input({ required: true })
  telegramIntegrations: TelegramIntegrationExternal[] = [];

  _showOperationSelect: boolean = true;
  @Input()
  get showOperationSelect(): boolean {
    return this._showOperationSelect && !this.isPropertyNameNull();
  }
  set showOperationSelect(value: boolean) {
    this._showOperationSelect = value;
  }

  @Input()
  showPropertyCounter: boolean = true;

  @Input()
  showRemoveButton: boolean = true;

  @Output()
  removeFilter: EventEmitter<number> = new EventEmitter<number>();

  readonly control: FormGroup<ToFormGroupControls<PropertyFilter>> = this.fb.group<ToFormGroupControls<PropertyFilter>>(
    {
      propertyName: this.fb.control(null, { nonNullable: true }),
      operation: this.fb.control(
        {
          type: ELASTICSEARCH_OPERATION.KNOWN,
          value: {
            value: '',
          },
        },
        { nonNullable: true },
      ),
    },
  );

  constructor(private readonly fb: FormBuilder) {
    super();
    this.control.controls.propertyName.valueChanges
      .pipe(
        startWith(this.control.controls.propertyName.value),
        pairwise(),
        filter((val): val is [string | null, string] => {
          const [prev, current] = val;
          return current !== null;
        }),
      )
      .subscribe(([prev, current]) => this.updateFilterValuesOnPropertyChange(prev, current));
  }

  protected getPositionByIndex(index: number): number {
    return index + 1;
  }

  protected onRemoveButtonClick(index: number): void {
    this.removeFilter.emit(index);
  }

  private isPropertyNameNull(): boolean {
    return this.control.controls.propertyName.value === null;
  }

  isCustomOperationSelect(): boolean {
    return this.isTelegramSubscriptionsFilter() || this.isEmailSubscriptionsFilter;
  }

  isTelegramSubscriptionsFilter() {
    return this.control.controls.propertyName.value === '$telegram_subscriptions';
  }

  get isEmailSubscriptionsFilter() {
    return this.control.controls.propertyName.value === '$email_status';
  }

  updateFilterValuesOnPropertyChange(prev: string | null, current: string): void {
    const isTelegramSubscriptions = (prop: string | null) => prop === '$telegram_subscriptions';
    const isEmailSubscriptions = (prop: string | null) => prop === '$email_status';

    const isSubscriptions = (prop: string | null) => isTelegramSubscriptions(prop) || isEmailSubscriptions(prop);

    let type:
      | ELASTICSEARCH_OPERATION.STR_EQ
      | ELASTICSEARCH_OPERATION.LIST_CONTAINS
      | ELASTICSEARCH_OPERATION.KNOWN
      | undefined;

    if (isSubscriptions(current)) {
      if (isEmailSubscriptions(current) && !isEmailSubscriptions(prev)) {
        type = ELASTICSEARCH_OPERATION.STR_EQ;
      }

      if (isTelegramSubscriptions(current) && !isTelegramSubscriptions(prev)) {
        type = ELASTICSEARCH_OPERATION.LIST_CONTAINS;
      }
    } else if (isSubscriptions(prev)) {
      type = ELASTICSEARCH_OPERATION.KNOWN;
    }

    if (type) {
      this.control.controls.operation.setValue({
        type: type,
        value: {
          value: '',
        },
      });
    }
  }
}

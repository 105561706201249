import { InjectionToken, NgZone, Provider } from '@angular/core';
import { Container } from 'pixi.js';
import { Simple as SimpleCull } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';

import { PIXI_APP, PixiApplication } from './pixi.token';
import { PIXI_VIEWPORT } from './pixi-viewport.token';

export const PIXI_CULL = new InjectionToken<SimpleCull>('Viewport culling wrapper');

export const pixiCullProvider: Provider = {
  provide: PIXI_CULL,
  useFactory: (app: PixiApplication, viewport: Viewport, ngZone: NgZone) => {
    return ngZone.runOutsideAngular(() => {
      const cull = new SimpleCull({ dirtyTest: true });

      // Эта штука нужна, потому что типизация пикси говорит, что там InteractionEvent,
      // хотя по факту там контейнер
      function isContainer(c: any): c is Container {
        return c instanceof Container;
      }

      cull.addList(viewport.children);

      viewport.on('childAdded', (container) => {
        if (isContainer(container)) {
          cull.add(container);
        } else {
          throw new Error('The argument of Cull.add must be a container');
        }
      });
      viewport.on('childRemoved', (container) => {
        if (isContainer(container)) {
          cull.remove(container);
        } else {
          throw new Error('The argument of Cull.remove must be a container');
        }
      });

      app.ticker.add(() => {
        if (viewport.dirty) {
          cull.cull(viewport.getVisibleBounds());
          viewport.dirty = false;
        }
      });

      return cull;
    });
  },
  deps: [PIXI_APP, PIXI_VIEWPORT, NgZone],
};

import { BaseTexture, Renderer, RenderTexture } from '@pixi/core';
import { Container, Rectangle, Texture } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
  TRIGGER_CHAIN_BLOCK_TYPE,
  TRIGGER_CHAIN_BLOCK_WIDTH,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UnicodeIcon } from '@panel/app/shared/constants/unicode-icon.constants';

import {
  buildBorderInnerBaseTexture,
  buildBorderSideBaseTexture,
  buildConnectionPointBlank,
  buildConnectionPointPlus,
  buildExitBlock,
  buildExitBlockBorder,
  buildInnerBaseTexture,
  buildSideBaseTexture,
} from './block-builder.utils';
import { GrowingPacker, GrowingPackerBlock } from './growing-packer';

type TextureMeta = {
  baseTexture: BaseTexture;
  items: Record<
    AtlasTexture,
    {
      x: number;
      y: number;
      w: number;
      h: number;
    }
  >;
};

const IconSize = 15;

type Icon<iconName extends UnicodeIcon> = `${iconName}-${typeof IconSize}`;

/* eslint-disable prettier/prettier */
export type AtlasTexture =
  | 'background-danger950-272-inner'
  | 'background-danger950-272-side'
  | 'background-primary950-272-inner'
  | 'background-primary950-272-side'
  | 'background-success950-272-inner'
  | 'background-success950-272-side'
  | 'background-white-292-inner'
  | 'background-white-292-side'
  | 'border-danger600-292-inner'
  | 'border-danger600-292-side'
  | 'border-gray800-272-inner'
  | 'border-gray800-272-side'
  | 'border-primary600-292-inner'
  | 'border-primary600-292-side'
  | 'circle-background-white-15'
  | 'circle-background-primary600-15'
  | 'circle-background-gray400-15'
  | 'circle-border-danger600-15'
  | 'connection-point-blank'
  | 'connection-point-plus-gray400'
  | 'connection-point-plus-danger400'
  | 'block-exit-success'
  | 'block-exit'
  | 'border-exit-success-danger600'
  | 'border-exit-danger600'
  ;
/* eslint-enable prettier/prettier */

export class AtlasBuilderService {
  private static _textureMeta: TextureMeta | null = null;

  static get textureMeta(): TextureMeta {
    if (this._textureMeta === null) {
      throw new Error('You should build BaseTexture first');
    }
    return this._textureMeta;
  }

  static set textureMeta(value: TextureMeta | null) {
    this._textureMeta = value;
  }

  static buildTexture(renderer: Renderer): TextureMeta {
    const rawTextures = this.buildRawTextures();

    type Blocks = (GrowingPackerBlock & { textureName: AtlasTexture })[];

    const blocks: Blocks = (Object.keys(rawTextures) as AtlasTexture[]).map((textureName) => {
      const texture = rawTextures[textureName];
      return {
        w: texture.width,
        h: texture.height,
        place: null,
        textureName,
      };
    });

    blocks.sort((a, b) => Math.max(b.h, b.w) - Math.max(a.h, a.w));

    GrowingPacker.fit(blocks, 1);

    const container = new Container();

    blocks.forEach((block) => {
      if (!block.place) {
        throw new Error('Could not find a fit');
      }
      const texture = rawTextures[block.textureName];
      texture.position.set(block.place.x, block.place.y);
      texture.name = block.textureName;
      container.addChild(texture);
    });

    const renderTexture = RenderTexture.create({
      width: container.width + container.position.x,
      height: container.height + container.position.y,
      // цифра на глаз, смотрел как выглядят скругления
      resolution: 4,
    });

    renderer.render(container, {
      renderTexture,
    });

    container.destroy({ texture: true, baseTexture: true, children: true });

    const baseTexture = renderTexture.castToBaseTexture();

    const twoDimensional: [AtlasTexture, { x: number; y: number; w: number; h: number }][] = blocks.map((block) => [
      block.textureName,
      {
        x: block.place!.x,
        y: block.place!.y,
        w: block.w,
        h: block.h,
      },
    ]);

    const textureMeta: TextureMeta = {
      baseTexture,
      items: Object.fromEntries(twoDimensional) as TextureMeta['items'],
    };

    this.textureMeta = textureMeta;

    return textureMeta;
  }

  private static buildRawTextures(): Record<AtlasTexture, Container> {
    /* eslint-disable prettier/prettier */
    return {
      ['background-danger950-272-inner']: buildInnerBaseTexture(TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX, HEX_COLOR.DANGER_950),
      ['background-danger950-272-side']: buildSideBaseTexture(TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX, HEX_COLOR.DANGER_950),
      ['background-primary950-272-inner']: buildInnerBaseTexture(TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX, HEX_COLOR.PRIMARY_950),
      ['background-primary950-272-side']: buildSideBaseTexture(TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX, HEX_COLOR.PRIMARY_950),
      ['background-success950-272-inner']: buildInnerBaseTexture(TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX, HEX_COLOR.SUCCESS_950),
      ['background-success950-272-side']: buildSideBaseTexture(TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX, HEX_COLOR.SUCCESS_950),
      ['background-white-292-inner']: buildInnerBaseTexture(TRIGGER_CHAIN_BLOCK_WIDTH, HEX_COLOR.WHITE),
      ['background-white-292-side']: buildSideBaseTexture(TRIGGER_CHAIN_BLOCK_WIDTH, HEX_COLOR.WHITE),
      ['border-danger600-292-inner']: buildBorderInnerBaseTexture(TRIGGER_CHAIN_BLOCK_WIDTH, HEX_COLOR.DANGER_600),
      ['border-danger600-292-side']: buildBorderSideBaseTexture(TRIGGER_CHAIN_BLOCK_WIDTH, HEX_COLOR.DANGER_600),
      ['border-gray800-272-inner']: buildBorderInnerBaseTexture(TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX, HEX_COLOR.GRAY_800),
      ['border-gray800-272-side']: buildBorderSideBaseTexture(TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX, HEX_COLOR.GRAY_800),
      ['border-primary600-292-inner']: buildBorderInnerBaseTexture(TRIGGER_CHAIN_BLOCK_WIDTH, HEX_COLOR.PRIMARY_600),
      ['border-primary600-292-side']: buildBorderSideBaseTexture(TRIGGER_CHAIN_BLOCK_WIDTH, HEX_COLOR.PRIMARY_600),
      ['circle-background-white-15']: CanvasRenderService.renderCircle({ radius: 15, backgroundColor: HEX_COLOR.WHITE }),
      ['circle-background-primary600-15']: CanvasRenderService.renderCircle({ radius: 15, backgroundColor: HEX_COLOR.PRIMARY_600 }),
      ['circle-background-gray400-15']: CanvasRenderService.renderCircle({ radius: 15, backgroundColor: HEX_COLOR.GRAY_400 }),
      ['circle-border-danger600-15']: CanvasRenderService.renderCircle({ radius: 15, backgroundColor: 0, backgroundAlpha: 0, borderColor: HEX_COLOR.DANGER_600, borderWidth: 1 }),
      ['connection-point-blank']: buildConnectionPointBlank(),
      ['connection-point-plus-gray400']: buildConnectionPointPlus(HEX_COLOR.GRAY_400),
      ['connection-point-plus-danger400']: buildConnectionPointPlus(HEX_COLOR.DANGER_400),
      ['block-exit-success']: buildExitBlock(TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS),
      ['block-exit']: buildExitBlock(TRIGGER_CHAIN_BLOCK_TYPE.EXIT),
      ['border-exit-success-danger600']: buildExitBlockBorder(TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS),
      ['border-exit-danger600']: buildExitBlockBorder(TRIGGER_CHAIN_BLOCK_TYPE.EXIT),
    };
    /* eslint-enable prettier/prettier */
  }

  static getTexture(name: AtlasTexture, height?: number): Texture {
    const itemMeta = this.textureMeta.items[name];

    return new Texture(
      this.textureMeta.baseTexture,
      // -1, потому что пиксель в координате x/y тоже учитывается
      new Rectangle(itemMeta.x, itemMeta.y, itemMeta.w - 1, (height ? Math.ceil(height) : itemMeta.h) - 1),
    );
  }

  static destroyBaseTexture() {
    this.textureMeta.baseTexture.destroy();
    this.textureMeta = null;
  }
}

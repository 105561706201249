import { Renderer, RenderTexture, Texture } from '@pixi/core';
import { Sprite } from '@pixi/sprite';
import { Graphics } from 'pixi.js';

let squareBackgroundTexture: Texture | null = null;

let lastRendererContextUID: number | null = null;

export const CHANNEL_ACTION_RECT_SIZE = 25;

export function getChannelActionSquareBackground(renderer: Renderer): Sprite {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    squareBackgroundTexture = null;
  }
  lastRendererContextUID = renderer.CONTEXT_UID;

  if (squareBackgroundTexture) {
    return new Sprite(squareBackgroundTexture);
  }

  const square = new Graphics();

  square.beginFill(0xe3e5e8).drawRoundedRect(0, 0, CHANNEL_ACTION_RECT_SIZE, CHANNEL_ACTION_RECT_SIZE, 2.1).endFill();

  const renderTexture = RenderTexture.create({ width: CHANNEL_ACTION_RECT_SIZE, height: CHANNEL_ACTION_RECT_SIZE });

  renderer.render(square, { renderTexture });

  square.destroy();

  squareBackgroundTexture = renderTexture;

  return new Sprite(squareBackgroundTexture);
}

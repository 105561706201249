<ng-container *transloco="let t; read: 'amocrmDomainSelectionComponent'">
  <div class="mb-10">
    <span [innerHTML]="t('text', {integrationTypeName})"></span>
    <span
      class="dashed text-primary"
      [ngbTooltip]="hintTooltipTemplate"
      >{{ t('hint.text') }}</span
    >
  </div>
  <div class="btn-group btn-group-toggle">
    <label
      class="btn btn-outline-primary"
      [class.active]="control.value === domain"
      *ngFor="let domain of amocrmAccountDomainsList"
    >
      <input
        type="radio"
        [formControl]="control!"
        [value]="domain"
      />
      <span [innerHTML]="t('domainBtnGroup.' + domain)"></span>
    </label>
  </div>

  <ng-template #hintTooltipTemplate>
    <div [innerHTML]="t('hint.tooltip', {integrationTypeName})"></div>
  </ng-template>
</ng-container>

import { animate, sequence, style, transition, trigger } from '@angular/animations';

export function screenshotAnimation() {
  return trigger('screenshotAnimation', [
    transition(':enter', [
      sequence([
        style({ opacity: 1, backgroundColor: 'rgb(255, 255, 255)' }),
        animate('0.1s ease-in', style({ opacity: 1, backgroundColor: 'rgb(255, 255, 255)' })),
        animate('2s ease-in', style({ opacity: 0.85, backgroundColor: 'rgb(213, 216, 221)' })),
      ]),
    ]),
    transition(':leave', [animate('2s ease-in', style({ opacity: 0 }))]),
  ]);
}

(function () {
  'use strict';

  require('./starter-guide.controller');

  angular.module('myApp.starterGuide').component('cqStarterGuide', cqStarterGuide());

  function cqStarterGuide() {
    return {
      bindings: {
        activeLeadBotsAmount: '=', // Информация об активных Lead ботах
        activeMessagesAmounts: '=', // Массив с количеством активных триггерных сообщений по типам
        billingInfo: '=', // Информация о биллинге
        chatSettings: '=', // Настройки чата
        currentApp: '=', // Текущее приложение
        djangoUserTempData: '=', // Временные данные django-пользователя
        knowledgeBase: '=', // Настройки БЗ
        messageSenders: '=', // Список отправителей
        routingBotId: '=', // ID routing бота
        starterGuideStep: '=', // Шаг, который разворачиваем
        templates: '=', // Шаблоны чат-ботов
      },
      controller: 'CqStarterGuideController',
      controllerAs: 'vm',
      template: require('./starter-guide.html'),
    };
  }
})();

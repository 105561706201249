(function () {
  'use strict';

  angular.module('myApp.serviceInstallationForDeveloper').config(configUiRouter).config(configStates);

  /**
   * Конфигурация uiRouter
   *
   * @param $locationProvider
   * @param $urlServiceProvider
   */
  function configUiRouter($locationProvider, $urlServiceProvider) {
    // HACK strict mode: строки с настройками незначащих слешей и регистронезависимыми ссылками приходится дублировать там, где объявляются корневые состояния
    //  Так сделано, потому что эти настройки должны применяться до объявления корневых состояний, но блоки config никак не упорядочить, они отрабатывают не скорневого модуля, а по мере объявления файлов в html
    //  Гуглёж не помог решить эту проблему, есть только общее правило объявлять эти настройки до создания состояний https://github.com/angular-ui/ui-router/issues/1866#issuecomment-417176766
    $urlServiceProvider.config.caseInsensitive(true);
    $urlServiceProvider.config.strictMode(false);
  }

  /**
   * Конфигурация состояния кода для программиста
   *
   * @param $stateProvider
   */
  function configStates($stateProvider) {
    $stateProvider.state('code', {
      url: '/code?apiKey&appId&appName&appOrigin',
      component: 'cqServiceInstallationForDeveloper',
    });
  }
})();

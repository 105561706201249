import { Injectable } from '@angular/core';

import { AUTO_EVENTS_TYPES } from '@http/track-master/track-master.constants';
import { AutoEvent } from '@http/track-master/track-master.model';
import { AutoEventDuplicate } from '@panel/app/partials/track-master/duplicates-errors/duplicates-errors.component';

@Injectable()
export class AutoEventsDuplicatesHelperService {
  hadDuplicatesLastTime = false;

  private readonly AUTO_EVENTS_TYPES = AUTO_EVENTS_TYPES;

  private checkName(a: AutoEvent, b: AutoEvent): boolean {
    return a.name?.toLowerCase() === b.name?.toLowerCase();
  }

  private checkSelector(a: AutoEvent, b: AutoEvent): boolean {
    return a.selector === b.selector && ((!a.urlHas && !b.urlHas) || (a.urlHas && b.urlHas && this.checkUrl(a, b)));
  }

  private checkUrl(a: AutoEvent, b: AutoEvent): boolean {
    return a.url === b.url && a.urlContains === b.urlContains;
  }

  public getDuplicates(newEvent: AutoEvent, events: AutoEvent[]) {
    const duplicates: AutoEventDuplicate[] = [];

    events.forEach((event) => {
      const duplicateEvent: AutoEventDuplicate = { event, duplicateBy: [] };
      const isNewEventUrlTypes = [this.AUTO_EVENTS_TYPES.URL, this.AUTO_EVENTS_TYPES.URL_SCROLL].includes(
        newEvent.type,
      );
      const isEventUrlTypes = [this.AUTO_EVENTS_TYPES.URL, this.AUTO_EVENTS_TYPES.URL_SCROLL].includes(event.type);

      this.checkName(newEvent, event) && duplicateEvent.duplicateBy.push('name');

      if (!isEventUrlTypes) {
        this.checkSelector(newEvent, event) && duplicateEvent.duplicateBy.push('selector');
      }

      if (isEventUrlTypes && isNewEventUrlTypes) {
        this.checkUrl(newEvent, event) && duplicateEvent.duplicateBy.push('url');
      }

      !!duplicateEvent.duplicateBy.length && duplicates.push(duplicateEvent);
    });

    this.hadDuplicatesLastTime = duplicates.length > 0;

    return duplicates;
  }
}

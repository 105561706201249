<ng-container *transloco="let t; read: 'tgTriggerComponent'">
  <cq-message-editor-collapse [collapse]="false">
    <h4
      titleContent
      class="m-0 align-content-center flex-grow-1 no-flex-basis mt-5"
    >
      {{ t('heading') }}
    </h4>
    <ng-container expandContent>
      <cq-tg-trigger-types
        [tgTriggerTypes]="tgTriggerTypes"
        (tgTriggerTypesChange)="tgTriggerTypesChange.next($event)"
        (touchAndValidateCallback)="ajsValidationFn.next($event)"
      ></cq-tg-trigger-types>
    </ng-container>
    <ng-container collapsedContent>
      <div>-</div>
    </ng-container>
  </cq-message-editor-collapse>
</ng-container>

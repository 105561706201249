import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { UtilsModel } from '@http/utils/utils.model';
import {
  ModeSettingsExternal,
  VisibilitySettingsMapper,
} from '@panel/app/pages/settings/chat/components/visibility-settings/visibility-settings-mapper';
import { UrlFilter } from '@panel/app/partials/url-filter-configurator/single-url-filter-configurator/single-url-filter-configurator.component';
import {
  URL_FILTER_TYPE,
  UrlFilterConfiguratorComponent,
  UrlFilterConfiguratorOptions,
} from '@panel/app/partials/url-filter-configurator/url-filter-configurator.component';
import { DestroyService } from '@panel/app/services';

/**
 * Основная настройка видимости чата
 */
export enum VISIBILITY_MODE {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  HAS_DIALOGS = 'has_dialogs',
  ADVANCED = 'advanced',
}

/**
 * Опции отображания в зависимости от типа устройства
 */
export enum VISIBILITY_OPTIONS {
  ALL_VISIBLE = 'all_visible',
  ALL_VISIBLE_EXCEPT = 'all_visible_except',
  ALL_HIDDEN = 'all_hidden',
  ALL_HIDDEN_EXCEPT = 'all_hidden_except',
}

export type ModeSettings = {
  mobile: VISIBILITY_OPTIONS;
  mobileFilters: UrlFilter[];
  desktop: VISIBILITY_OPTIONS;
  desktopFilters: UrlFilter[];
};

type Device = 'desktop' | 'mobile';

@Component({
  selector: 'cq-visibility-settings[mode][modeSettings]',
  templateUrl: './visibility-settings.component.html',
  styleUrls: ['./visibility-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class VisibilitySettingsComponent implements OnInit {
  // По каким-то неизвестным мне причинам, если в названии есть большие буквы - инпуты и аутпуты перестают работать, поэтому сделано вот так
  @Input() mode!: VISIBILITY_MODE;
  @Input() modeSettings: ModeSettingsExternal = {
    desktop: null,
    mobile: null,
  };

  @Output() modeChange: EventEmitter<VISIBILITY_MODE> = new EventEmitter<VISIBILITY_MODE>();
  @Output() modeSettingsChange: EventEmitter<ModeSettingsExternal> = new EventEmitter<ModeSettingsExternal>();

  // Для старого ангуляра, чтоб оттуда триггернуть валидацию
  @Output() validationTrigger: EventEmitter<() => boolean> = new EventEmitter();

  visibilityFormControl!: UntypedFormControl;
  modeSettingsFormGroup: UntypedFormGroup | null = null;

  mode$!: Observable<VISIBILITY_MODE>;

  modeSettingsChange$: Subject<void> = new Subject<void>();

  readonly visibilityModes: ReadonlyArray<VISIBILITY_MODE> = [
    VISIBILITY_MODE.VISIBLE,
    VISIBILITY_MODE.HIDDEN,
    VISIBILITY_MODE.HAS_DIALOGS,
    VISIBILITY_MODE.ADVANCED,
  ];

  readonly visibilityOptions: ReadonlyArray<VISIBILITY_OPTIONS> = [
    VISIBILITY_OPTIONS.ALL_VISIBLE,
    VISIBILITY_OPTIONS.ALL_VISIBLE_EXCEPT,
    VISIBILITY_OPTIONS.ALL_HIDDEN_EXCEPT,
    VISIBILITY_OPTIONS.ALL_HIDDEN,
  ];

  readonly urlFilterConfiguratorOptions: UrlFilterConfiguratorOptions = {
    track: {
      eventName: 'Ограничение показа чата на страницах - показ поповера про параметры',
    },
    enableOnlyOptions: [URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS, URL_FILTER_TYPE.CONTAINS],
    addDefaultFilterOnInit: true,
  };

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly destroy$: DestroyService,
    private readonly utilsService: UtilsModel,
  ) {}

  ngOnInit(): void {
    this.visibilityFormControl = this.fb.control(this.mode);
    this.updateModeSettingsFormGroup(this.mode);

    this.visibilityFormControl.valueChanges
      .pipe(startWith(this.visibilityFormControl.value), takeUntil(this.destroy$))
      .subscribe((mode: VISIBILITY_MODE) => {
        this.updateModeSettingsFormGroup(mode);
        this.modeChange.emit(mode);
      });

    this.mode$ = this.visibilityFormControl.valueChanges.pipe(startWith(this.mode));

    this.validationTrigger.emit(() => {
      this.modeSettingsFormGroup?.markAllAsTouched();
      return this.modeSettingsFormGroup ? this.modeSettingsFormGroup.valid : true;
    });
  }

  /**
   * Метод отделен в большей степени для удобства тестов.
   * Создает форм группу настроек для ADVANCED visibility мода
   */
  createAdvancedModeSettingsFormGroup(): UntypedFormGroup {
    return VisibilitySettingsComponent.getNewAdvancedForm({
      utilsService: this.utilsService,
      modeSettings: VisibilitySettingsMapper.modeSettingsToLocal(VISIBILITY_MODE.ADVANCED, this.modeSettings)!,
    });
  }

  /**
   * Парсит текущее значение настроек видимости и эмитит в @Output
   */
  emitModeSettings(mode: VISIBILITY_MODE, modeSettings: ModeSettings | null) {
    if (mode === VISIBILITY_MODE.ADVANCED && !modeSettings) {
      throw new Error('You must provide mode settings with advanced visibility mode');
    }

    let modeSettingsExternal: ModeSettingsExternal = VisibilitySettingsMapper.modeSettingsToExternal(
      mode,
      modeSettings,
    );

    this.modeSettingsChange.emit(modeSettingsExternal);
  }

  /**
   * Получение фильтров для указанного типа девайса
   */
  getFiltersByDeviceType(type: 'desktop' | 'mobile'): UntypedFormArray | null {
    return this.modeSettingsFormGroup?.get(`${type}Filters`) as UntypedFormArray | null;
  }

  getIconClassName(mode: VISIBILITY_MODE): string {
    const classList: string[] = ['cqi-sm', 'margin-right-10'];
    switch (mode) {
      case VISIBILITY_MODE.VISIBLE:
        classList.push('cqi-eye');
        break;
      case VISIBILITY_MODE.HIDDEN:
        classList.push('cqi-eye-closed');
        break;
      case VISIBILITY_MODE.HAS_DIALOGS:
        classList.push('cqi-comments');
        break;
      case VISIBILITY_MODE.ADVANCED:
        classList.push('cqi-cog');
        break;
    }
    return classList.join(' ');
  }

  /**
   * Явлется ли текущий visibilityMode ADVANCED
   */
  get isAdvancedBlockVisible(): boolean {
    return this.visibilityModes[3] === this.visibilityFormControl.value;
  }

  /**
   * Проверяет явлется ли текущий тип видимости для устройства одним из тех, к которым дополнительно нужно указывать фильтры.
   * Например, VISIBILITY_OPTIONS.ALL_VISIBLE - не требует фильтров. ALL_VISIBLE_EXCEPT - требует фильтров.
   */
  isAdvancedWithUrlFilters(device: Device): boolean {
    return [VISIBILITY_OPTIONS.ALL_VISIBLE_EXCEPT, VISIBILITY_OPTIONS.ALL_HIDDEN_EXCEPT].includes(
      this.modeSettingsFormGroup!.get(device)!.value,
    );
  }

  /**
   * Обновляет значение modeSettingsFormGroup
   * Добавляет подписчика на отправку изменений в Output, если modeSettingsFormGroup не равно null
   */
  updateModeSettingsFormGroup(mode: VISIBILITY_MODE) {
    this.modeSettingsChange$.next();

    if (mode === VISIBILITY_MODE.ADVANCED) {
      this.modeSettingsFormGroup = this.createAdvancedModeSettingsFormGroup();

      this.modeSettingsFormGroup.valueChanges
        .pipe(
          startWith(this.modeSettingsFormGroup.value),
          takeUntil(this.destroy$),
          takeUntil(this.modeSettingsChange$),
        )
        .subscribe((modeSettings: ModeSettings) => {
          this.emitModeSettings(mode, modeSettings);
        });
    } else {
      this.modeSettingsFormGroup = null;
      this.emitModeSettings(mode, this.modeSettingsFormGroup);
    }
  }

  /**
   * Очищает все фильтры для типа девайся и добавляет новые, если новый тип того требует
   * @param device
   */
  onDeviceVisibilitySelect(device: Device) {
    this.getFiltersByDeviceType(device)?.clear();

    if (this.isAdvancedWithUrlFilters(device)) {
      this.getFiltersByDeviceType(device)?.push(
        UrlFilterConfiguratorComponent.getNewUrlFilter(this.utilsService, URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS),
      );
    }
  }

  static getNewAdvancedForm(config?: { modeSettings: ModeSettings; utilsService: UtilsModel }): UntypedFormGroup {
    const filtersToForms = (filters: UrlFilter[] | null): UntypedFormGroup[] => {
      return filters
        ? filters.map((filter) =>
            UrlFilterConfiguratorComponent.getNewUrlFilter(config!.utilsService, filter.type, filter.match),
          )
        : [];
    };

    return new UntypedFormGroup({
      desktop: new UntypedFormControl(config ? config.modeSettings.desktop : VISIBILITY_OPTIONS.ALL_VISIBLE_EXCEPT),
      desktopFilters: new UntypedFormArray(config ? filtersToForms(config.modeSettings.desktopFilters) : []),
      mobile: new UntypedFormControl(config ? config.modeSettings.mobile : VISIBILITY_OPTIONS.ALL_VISIBLE_EXCEPT),
      mobileFilters: new UntypedFormArray(config ? filtersToForms(config.modeSettings.mobileFilters) : []),
    });
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { tuiPure } from '@taiga-ui/cdk';

import { App } from '@http/app/app.model';
import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { BranchConditionCallbacks } from '@panel/app/pages/chat-bot/content/branch-editor/branch-editor.component';
import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';

@Component({
  selector: 'cq-app-online-condition-editor[branch][callbacks][currentApp][nextBranchOptions]',
  templateUrl: './app-online-condition-editor.component.html',
  styleUrls: ['./app-online-condition-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppOnlineConditionEditorComponent {
  @Input()
  branch!: Branch;

  @Input()
  callbacks!: BranchConditionCallbacks;

  @Input()
  currentApp!: App;

  @Input()
  nextBranchOptions!: Branch[];

  @Input()
  chatBotType: CHAT_BOT_TYPE | null = null;

  @Input()
  chatBotId: string | null = null;

  ACTION_TYPE = CHAT_BOT_ACTIONS_TYPES;

  @tuiPure
  getAppOnlineAction(branch: Branch): BotAction {
    const action = branch.actions.find((a) => a.type === CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION);
    if (!action) {
      throw new Error(`Couldn't find action`);
    }
    return action;
  }

  @tuiPure
  getAppOfflineAction(branch: Branch): BotAction {
    const action = branch.actions.find((a) => a.type === CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION);
    if (!action) {
      throw new Error(`Couldn't find action`);
    }
    return action;
  }
}

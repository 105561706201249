/**
 * Контроллер для модального окна редактирования связки Статус сделки Битрикс24 - Событие Carrot quest
 */
(function () {
  'use strict';

  angular
    .module('myApp.modals.editBitrix24Status')
    .controller('EditBitrix24StatusModalController', EditBitrix24StatusModalController);

  function EditBitrix24StatusModalController(
    $filter,
    $uibModalInstance,
    $translate,
    INTEGRATION_TYPES,
    PROJECT_NAME,
    validationHelper,
    bitrix24Props,
    eventTypes,
    pipeline,
    pipelineType,
  ) {
    var vm = this;

    vm.addEventProp = addEventProp;
    vm.bitrix24Props = bitrix24Props;
    vm.eventTypes = eventTypes;
    vm.INTEGRATION_TYPES = INTEGRATION_TYPES;
    vm.pipeline = pipeline;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.removeEventProp = removeEventProp;
    vm.resetStatus = resetStatus;
    vm.savePipeline = savePipeline;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      // в списке событий может не оказаться события, которое по умолчанию настроено для сделки, а так же события, которое введено вручную, поэтому их надо добавить
      var defaultEventName =
        $translate.instant('modals.editBitrix24Status.defaultEventName.crmName') +
        ': ' +
        pipelineType +
        ' - ' +
        pipeline.name +
        ': ' +
        pipeline.statusName;
      var defaultEvent = $filter('filter')(eventTypes, { name: defaultEventName }, true)[0];

      if (angular.isUndefined(defaultEvent)) {
        eventTypes.push({
          name: defaultEventName,
          prettyName: defaultEventName,
          group: $translate.instant('models.property.groups.events'),
        });
      }

      var currentEventName = pipeline.leadsEvent.event.prop;
      var currentEvent = $filter('filter')(eventTypes, { name: currentEventName }, true)[0];

      if (angular.isUndefined(currentEvent)) {
        eventTypes.push({
          name: currentEventName,
          prettyName: currentEventName,
          group: $translate.instant('models.property.groups.events'),
        });
      }
    }

    /**
     * Добавление свойств к связке Статус сделки - Событие
     */
    function addEventProp() {
      pipeline.leadsEvent.eventProps.push({});
    }

    /**
     * Удаление свойств из связки Статус сделки - Событие
     *
     * @param eventProp
     */
    function removeEventProp(eventProp) {
      pipeline.leadsEvent.eventProps.splice(pipeline.leadsEvent.eventProps.indexOf(eventProp), 1);
    }

    /**
     * Сброс события к первоначальному состоянию
     */
    function resetStatus() {
      pipeline.leadsEvent.checked = false;
      pipeline.leadsEvent.event.prop =
        $translate.instant('modals.editBitrix24Status.defaultEventName.crmName') +
        ': ' +
        pipelineType +
        ' - ' +
        pipeline.name;
    }

    /**
     * Сохранение данных сделки
     *
     * @param isValid
     */
    function savePipeline(isValid) {
      if (isValid) {
        $uibModalInstance.close(pipeline);
      }
    }
  }
})();

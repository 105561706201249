(function () {
  'use strict';

  angular
    .module('myApp.chatSettings')
    .controller('CqEmailNotificationTimeoutController', CqEmailNotificationTimeoutController);

  function CqEmailNotificationTimeoutController(validationHelper) {
    let vm = this;

    /**
     * Опции таймаута дублирования на почту, в секундах
     *
     * @type {number[]}
     */
    const timeouts = [0, 60, 120, 240, 480, 960];

    vm.$onInit = init;

    function init() {
      vm.timeouts = timeouts;
      vm.validationHelper = validationHelper;
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPromise } from 'angular';
import cloneDeep from 'lodash-es/cloneDeep';
import { combineLatest, firstValueFrom, Observable, Subject, switchMap } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { AppActivation } from '@http/app/app.types';
import { AppService } from '@http/app/services/app.service';
import { MESSAGE_PART_TYPES, MESSAGE_PART_TYPES_ARRAY } from '@http/message-part/message-part.constants';
import { READY_MESSAGE_TEMPLATE_SCOPES_OF_USE } from '@http/ready-message-template/ready-message-template.constants';
import { ReadyMessageTemplateModel } from '@http/ready-message-template/ready-message-template.model';
import {
  ApiReadyMessageTemplateList,
  ReadyMessageTemplate,
} from '@http/ready-message-template/ready-message-template.types';
import { APP_USAGE_GOAL, APP_USAGE_GOAL_LIST } from '@http/starter-guide/starter-guide.types';
import { PaginationParamsRequest } from '@http/types';
import {
  READY_AUTO_MESSAGES_MODAL_FILTER,
  READY_AUTO_MESSAGES_MODAL_FILTER_TO_MESSAGE_TYPE,
} from '@panel/app/partials/modals/ready-auto-messages-modal/ready-auto-messages-modal.const';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

type ReadyAutoMessagesModalParams = {
  appActivation: AppActivation;
  selectedUseCase?: APP_USAGE_GOAL;
};

/**
 * Возможные варианты выбора в селекторе юзкейса
 */
type UseCaseOptions = APP_USAGE_GOAL | 'any';

@Component({
  selector: 'cq-ready-auto-messages-modal',
  templateUrl: './ready-auto-messages-modal.component.html',
  styleUrls: ['./ready-auto-messages-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyAutoMessagesModalComponent implements OnInit {
  @Input()
  modalWindowParams!: ReadyAutoMessagesModalParams;

  /**
   * Эмиттер нажатия на кнопку "Догрузить след пачку шаблонов"
   */
  downloadNextSubject: Subject<void> = new Subject();

  /**
   * Контрол для селектора юзкейса
   */
  useCaseControl = new FormControl<UseCaseOptions>('any', { nonNullable: true });

  /**
   * Контрол для селектора типа сообщения
   */
  messageTypeControl = new FormControl<READY_AUTO_MESSAGES_MODAL_FILTER>(READY_AUTO_MESSAGES_MODAL_FILTER.ALL, {
    nonNullable: true,
  });

  private templatesCache: ReadyMessageTemplate[] = [];

  /**
   * Поток шаблонов, с подгрузкой след пачек и обновлениями по фильтрам
   */
  templates$: Observable<any> = combineLatest([
    this.downloadNextSubject.pipe(
      startWith(null),
      // Скидываем tocuhed, чтоб в конце понять, что шаблоны пришли для добавления в список, а не замены
      tap(() => this.markFiltersUntouched()),
    ),
    this.useCaseControl.valueChanges.pipe(
      startWith(this.useCaseControl.value),
      // Пагинацию скидываем при установке фильтров
      tap(() => (this.paginatorParams = undefined)),
    ),
    this.messageTypeControl.valueChanges.pipe(
      startWith(this.messageTypeControl.value),
      // Пагинацию скидываем при установке фильтров
      tap(() => (this.paginatorParams = undefined)),
    ),
  ]).pipe(
    switchMap(([_, useCase, filter]) => {
      return this.getTemplates(useCase, filter);
    }),
    tap(({ paginatorParams }) => this.updatePaginationParams(paginatorParams)),
    map(({ templates }) => {
      if (this.useCaseControl.touched || this.messageTypeControl.touched) {
        this.templatesCache = templates;
        return templates;
      }

      this.templatesCache = [...this.templatesCache, ...templates];
      return this.templatesCache;
    }),
  );

  private paginatorParams?: PaginationParamsRequest;

  /**
   * Список юзкейсов аппа, выбирают во время реги
   */
  APP_USAGE_GOAL_LIST = APP_USAGE_GOAL_LIST;

  /**
   * Типы варианта сообщения
   */
  MESSAGE_PART_TYPES_ARRAY: MESSAGE_PART_TYPES[] = [];

  READY_AUTO_MESSAGES_MODAL_FILTER: READY_AUTO_MESSAGES_MODAL_FILTER[] = [
    READY_AUTO_MESSAGES_MODAL_FILTER.ALL,
    READY_AUTO_MESSAGES_MODAL_FILTER.POPUPS,
    READY_AUTO_MESSAGES_MODAL_FILTER.CHAT_MESSAGE,
    READY_AUTO_MESSAGES_MODAL_FILTER.EMAIL,
  ];

  constructor(
    private readonly ngbActiveModal: NgbActiveModal,
    private readonly appService: AppService,
    private readonly readyMessageTemplateModel: ReadyMessageTemplateModel,
    private readonly l10nHelper: L10nHelperService,
    private readonly carrotquestHelper: CarrotquestHelper,
  ) {}

  ngOnInit(): void {
    this.MESSAGE_PART_TYPES_ARRAY = this.filterMessagePartTypesArray(MESSAGE_PART_TYPES_ARRAY);

    if (this.modalWindowParams.selectedUseCase) {
      this.useCaseControl.setValue(this.modalWindowParams.selectedUseCase);
    }

    this.trackOpenedModal();
  }

  /**
   * Нужно ли показывать кнопку догрузки шаблонов
   */
  get canLoadMore(): boolean {
    return !!this.paginatorParams && this.paginatorParams.paginatePosition !== null;
  }

  closeModal(): void {
    this.ngbActiveModal.close(true);
  }

  dismissModal(): void {
    this.ngbActiveModal.dismiss();
  }

  /**
   * Фильтрует список доступных для выбора типов автосообщений
   */
  filterMessagePartTypesArray(messagePartTypesArray: MESSAGE_PART_TYPES[]): MESSAGE_PART_TYPES[] {
    const filteredMessagePartTypesArray = cloneDeep(messagePartTypesArray);

    for (let i = filteredMessagePartTypesArray.length - 1; i >= 0; i--) {
      const messagePartType = filteredMessagePartTypesArray[i];

      if (
        ~[
          MESSAGE_PART_TYPES.JS,
          MESSAGE_PART_TYPES.PUSH,
          MESSAGE_PART_TYPES.WEBHOOK,
          MESSAGE_PART_TYPES.CONTROL_GROUP,
        ].indexOf(messagePartType)
      ) {
        filteredMessagePartTypesArray.splice(i, 1);
      }

      // NOTE: пуши отключены для США
      if (~[MESSAGE_PART_TYPES.PUSH].indexOf(messagePartType) && this.l10nHelper.isUsCountry()) {
        filteredMessagePartTypesArray.splice(i, 1);
      }

      const userActivatedInSdk =
        Boolean(this.modalWindowParams.appActivation.installed_sdk_ios) ||
        Boolean(this.modalWindowParams.appActivation.installed_sdk_android);
      if (
        !!~[
          MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
          MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
          MESSAGE_PART_TYPES.SDK_PUSH,
        ].indexOf(messagePartType) &&
        !userActivatedInSdk
      ) {
        filteredMessagePartTypesArray.splice(i, 1);
      }
      // Нет шаблонов для Telegram
      if (messagePartType === MESSAGE_PART_TYPES.TELEGRAM) {
        filteredMessagePartTypesArray.splice(i, 1);
      }
    }

    return filteredMessagePartTypesArray;
  }

  /**
   * Получение списка темплейтов
   */
  getTemplates(
    useCase: UseCaseOptions,
    filter: READY_AUTO_MESSAGES_MODAL_FILTER,
  ): IPromise<ApiReadyMessageTemplateList> {
    return firstValueFrom(
      this.readyMessageTemplateModel.getList(
        this.appService.currentAppId,
        this.filterToMessagePartTypes(filter),
        READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY,
        true,
        this.paginatorParams,
        undefined,
        useCase === 'any' ? null : useCase,
      ),
    );
  }

  filterToMessagePartTypes(filter: READY_AUTO_MESSAGES_MODAL_FILTER): MESSAGE_PART_TYPES[] {
    return READY_AUTO_MESSAGES_MODAL_FILTER_TO_MESSAGE_TYPE[filter];
  }

  /**
   * Отмечает шаблоны как нетронутые, нужно для понимания тогО, пришел ответ для второй пачки пагинации или новый по фильтрам
   */
  private markFiltersUntouched(): void {
    this.useCaseControl.markAsUntouched();
    this.messageTypeControl.markAsUntouched();
  }

  trackByFn(index: number, value: ReadyMessageTemplate): string {
    return value.id;
  }

  private trackOpenedModal() {
    this.carrotquestHelper.track('Триггерные сообщения - Рекомендованные сценарии - увидел модалку');
  }

  trackSelectedTemplate(template: ReadyMessageTemplate) {
    this.carrotquestHelper.track('Триггерные сообщения - Рекомендованные сценарии - выбрал', {
      templateName: template.name,
    });
  }

  /**
   * @param paginatorParams тип any, потому что пагинация тупо типизированна и надо править отдельно её типы
   */
  private updatePaginationParams(paginatorParams: any): void {
    this.paginatorParams = {
      ...paginatorParams,
      paginatePosition: paginatorParams.paginatePosition ? [paginatorParams.paginatePosition] : null,
    };
  }
}

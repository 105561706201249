import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { BotActionNextBranchSelectorModule } from '@panel/app/pages/chat-bot/content/branch-editor/partials/bot-action-next-branch-selector/bot-action-next-branch-selector.module';
import { PipesModule } from '@panel/app/shared/pipes/pipes.module';

import { BranchActionAppOnlineConditionComponent } from './app-online-condition.component';

@NgModule({
  declarations: [BranchActionAppOnlineConditionComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbPopoverModule,
    BotActionNextBranchSelectorModule,
    ReactiveFormsModule,
    PipesModule,
    UIRouterModule,
  ],
  exports: [BranchActionAppOnlineConditionComponent],
})
export class BranchActionAppOnlineConditionModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CanvasOptionsComponent } from './canvas-options.component';

@NgModule({
  declarations: [CanvasOptionsComponent],
  imports: [CommonModule],
  exports: [CanvasOptionsComponent],
})
export class CanvasOptionsModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

import { PeriodComponent } from './period.component';

@NgModule({
  declarations: [PeriodComponent],
  exports: [PeriodComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [SubscriptionStore],
})
export class PeriodModule {}

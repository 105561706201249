import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { EMPTY_QUERY } from '@taiga-ui/cdk';
import { Viewport } from 'pixi-viewport';
import { fromEvent, merge } from 'rxjs';
import { startWith, takeUntil, withLatestFrom } from 'rxjs/operators';

import { BotCanvasOverlayAbleElement } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-blocks-overlay.interfaces';
import { BotBlocksOverlayService } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-blocks-overlay.service';
import { BotSingleBlockOverlayComponent } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-single-block-overlay/bot-single-block-overlay.component';
import { PIXI_VIEWPORT } from '@panel/app/pages/chat-bot/content/tokens';
import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-bot-blocks-overlay',
  templateUrl: './bot-blocks-overlay.component.html',
  styleUrls: ['./bot-blocks-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class BotBlocksOverlayComponent implements AfterViewInit {
  private readonly viewportMoved$ = fromEvent<void>(this.viewport, 'moved');

  private readonly viewportZoomed = fromEvent<void>(this.viewport, 'zoomed');

  @ViewChildren(BotSingleBlockOverlayComponent)
  overlayComponents: QueryList<BotSingleBlockOverlayComponent> = EMPTY_QUERY;

  constructor(
    readonly blocksOverlayService: BotBlocksOverlayService,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    private readonly destroy$: DestroyService,
  ) {
    this.blocksOverlayService.elements$.pipe(takeUntil(destroy$)).subscribe(() => {
      this.overlayObservablesCache.clear();
    });
  }

  ngAfterViewInit() {
    // Обновляем список компонентов
    this.overlayComponents.changes
      .pipe(
        startWith(this.overlayComponents.toArray()),
        takeUntil(this.destroy$),
        withLatestFrom(this.blocksOverlayService.elements$),
      )
      .subscribe(([_, elements]) => {
        this.blocksOverlayService.overlayComponentsMap.clear();

        elements.forEach(({ uid }, index) => {
          const overlayComponent = this.overlayComponents.get(index);
          if (!overlayComponent) {
            throw new Error('Could not find overlay component');
          }
          this.blocksOverlayService.overlayComponentsMap.set(uid, overlayComponent);
        });
      });
  }

  trackByUid(index: number, element: BotCanvasOverlayAbleElement): string {
    return element.uid;
  }

  /**
   * Без кеша merge каждый раз создает новый observable и переподписывается, хотя источники такие же.
   */
  private overlayObservablesCache = new Map();

  /**
   * Собиратем observable, который говорит об необходимости изменений для каждого отдельного оверлея.
   * Сделано кеширование, чтоб не пресоздавать каждый раз новый оь
   */
  updateOverlay$(element: BotCanvasOverlayAbleElement) {
    if (this.overlayObservablesCache.has(element.uid)) {
      return this.overlayObservablesCache.get(element.uid);
    }

    const sub = merge(element.positionChange$, element.sizeChange$, this.viewportZoomed, this.viewportMoved$);
    this.overlayObservablesCache.set(element.uid, sub);
    return sub;
  }
}

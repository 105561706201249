/** Статусы аддонов */
export enum ADDON_STATUS {
  /** Подключен */
  CONNECTED = 'connected',
  /** Включён в стоимость тарифа */
  INCLUDED_IN_PLAN = 'includedInPlan',
  /** Не подключен */
  DISCONNECTED = 'disconnected',
  /** Приостановлен */
  SUSPENDED = 'suspended',
}

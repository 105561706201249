import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

/** Компонент для работы с квотами по уникальным пользователям */
@Component({
  selector: 'cq-unique-users',
  templateUrl: './unique-users.component.html',
  styleUrls: ['./unique-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniqueUsersComponent {
  constructor(
    private readonly l10nHelperService: L10nHelperService,
    protected readonly subscriptionStore: SubscriptionStore,
  ) {}
}

import { TranslocoService } from '@jsverse/transloco';
import { Container, Graphics, Text, TextStyle } from 'pixi.js';
import { Simple } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';

import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ConnectionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { OverlayBootstrapElementsHelper } from '@panel/app/pages/chat-bot/content/services/overlay-bootstrap-elements-helper.service';
import { BranchViewMap, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { DescriptionPopoverTemplateComponent } from '@panel/app/pages/chat-bot/content/views/badges/description-popover-template/description-popover-template.component';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { SwitchBuildingElement } from '@panel/app/pages/chat-bot/content/views/building-elements';
import { Connection, IPoint } from '@panel/app/pages/chat-bot/content/views/connection';
import { BOT_GREY, BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { PixiGeometryService } from '@panel/app/shared/services/pixi/geometry/pixi-geometry.service';
import { Immutable } from '@panel/app/shared/types/immutable.type';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';

import {
  BADGE_ELEMENTS_INDENT,
  BADGE_HORIZONTAL_PADDING,
  BADGE_VERTICAL_PADDING,
  BadgeStyle,
  BaseBadge,
  DEFAULT_BADGE_STYLE,
} from './base-badge';

const INTERRUPT_BADGE_STYLE: Immutable<BadgeStyle> = {
  border: {
    ...DEFAULT_BADGE_STYLE.border,
    color: BOT_GREY,
  },
  background: {
    ...DEFAULT_BADGE_STYLE.background,
    color: BOT_GREY,
  },
  text: {
    ...DEFAULT_BADGE_STYLE.text,
    color: BOT_WHITE_COLOR,
  },
};

export class InterruptBadge extends BaseBadge {
  public switch?: SwitchBuildingElement;

  constructor(
    botScenariosHelper: BotScenariosHelper,
    cull: Simple,
    interactionService: PixiInteractionService,
    document: Document,
    connectionFactory: ConnectionFactory,
    transloco: TranslocoService,
    targetBlock: Branch | undefined,
    botType: CHAT_BOT_TYPE,
    canvasBranches: BranchViewMap,
    viewport: Viewport,
    bootstrapElementsHelper: OverlayBootstrapElementsHelper,
    pixiApp: PixiApplication,
    initialSwitchStatus?: boolean,
  ) {
    super(
      botScenariosHelper,
      cull,
      interactionService,
      document,
      connectionFactory,
      transloco,
      targetBlock,
      botType,
      canvasBranches,
      viewport,
      bootstrapElementsHelper,
      pixiApp,
    );
    this.container.name = 'Interrupt badge';
    botType !== CHAT_BOT_TYPE.FACEBOOK && (this.container.cursor = 'pointer');
    this.switch && (this.switch.status = initialSwitchStatus || false);

    this.initConnectionListeners();
  }

  addConnectionInfo(connection: Connection) {
    super.addConnectionInfo(connection);
  }

  get apiReadyData(): {
    interruptBranchId: string | null;
    interruptBranchLinkId: string | null;
  } {
    return {
      interruptBranchId: this.form.nextBranchId,
      interruptBranchLinkId: this.form.controls.nextBranchLinkId.value,
    };
  }

  /**
   * Стили бейджа
   */
  get style() {
    return INTERRUPT_BADGE_STYLE;
  }

  drawCard(): Graphics {
    const extraElements = [];
    if (this.botType !== CHAT_BOT_TYPE.FACEBOOK && this.botType !== CHAT_BOT_TYPE.TELEGRAM) {
      this.switch = new SwitchBuildingElement(this.interactionService);
      extraElements.push(this.switch.container);
    }

    return this.drawBaseCard(extraElements);
  }

  /**
   * Отрисовка карточки
   * @param extraElements - Дополнительные элементы для отрисовки
   * @private
   */
  private drawBaseCard(extraElements: (Text | Container)[] = []): Graphics {
    const fontSize = this.style.text.size;
    const textOptions: Partial<TextStyle> = {
      fontSize,
      lineHeight: fontSize * 1.4,
      wordWrap: false,
      fill: this.style.text.color,
      align: 'left',
      fontStyle: 'normal',
      fontWeight: '400',
    };
    const text = renderCanvasText(this.transloco.translate('chatBot.badges.interruptBadge'), textOptions);
    const contentHeight = Math.max(...extraElements.map((el) => el.height), text.height);
    const elementsWidth = extraElements.reduce((prev, current) => {
      return prev + BADGE_ELEMENTS_INDENT + current.width;
    }, 0);
    const rectWidth = text.width + elementsWidth + BADGE_HORIZONTAL_PADDING * 2;
    const rectHeight = contentHeight + BADGE_VERTICAL_PADDING * 2;
    const rect = PixiGeometryService.renderRectangle(rectWidth, rectHeight, {
      border: this.style.border,
      backgroundColor: this.style.background.color,
    });

    rect.addChild(text, ...extraElements);

    this.updateContentPosition(rect, [text, ...extraElements]);

    return rect;
  }

  /**
   * Коллбек для события клика
   */
  protected mouseoverCallback() {
    if (this.botType !== CHAT_BOT_TYPE.FACEBOOK && this.botType !== CHAT_BOT_TYPE.TELEGRAM) {
      this.bootstrapElementsHelper.createPopover(this.uid, DescriptionPopoverTemplateComponent, {
        placement: 'bottom',
      });

      this.bootstrapElementsHelper.openPopover(this.uid);
    }
  }

  /**
   * Коллбек для mouseout события
   */
  protected mouseoutCallback() {
    this.bootstrapElementsHelper.closePopover(this.uid);
  }

  deleteConnectionInfo(connection: Connection) {
    super.deleteConnectionInfo(connection);
  }

  protected get initPosition(): IPoint {
    return {
      x: this.initPosition.x,
      y: this.initPosition.y + this.height + 30,
    };
  }

  /**
   * Коллбек для mouseover события
   */
  protected clickCallback() {}
}

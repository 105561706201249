<ng-container *transloco="let t; read: 'pricingAddonsComponent'">
  <h2 class="ml-20 mb-20">{{ t('title') }}</h2>
  <div class="margin-between-rows-15">
    <cq-pricing-addon
      *ngFor="let addon of ADDONS"
      [addonConfigPerPlan]="getAddonConfigPerPlan(addon)"
      [addon]="addon"
      [currentApp]="currentApp"
      [expectedVisitors]="expectedVisitors"
      [isAddonConnected]="!planVersionService.isTrial() && isAddonConnected(addon)"
      [needsPersonalPlan]="needsPersonalPlan"
      (addonConfigPerPlanChange)="updateAddonAvailabilityPerPlanForAddon(addon, $event)"
    ></cq-pricing-addon>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'createEventTypeModalComponent'">
  <div class="modal-header flex-column align-items-baseline">
    <div class="d-flex w-100">
      <h3 class="modal-title">{{ t('heading') }}</h3>
      <button
        class="close"
        (click)="activeModal.dismiss()"
      >
        <i class="cqi-sm cqi-03x cqi-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <cq-event-name-input [formControl]="nameControl"></cq-event-name-input>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <button
      (click)="create()"
      class="btn btn-primary"
      data-test="submit-create"
    >
      {{ t('createButton') }}
    </button>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionComponent } from 'app/pages/subscription/general/subscription.component';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { EditAccountingEmailsModalModule } from '@panel/app/pages/subscription/general/edit-accounting-emails-modal/edit-accounting-emails-modal.module';
import { EditBillingEmailModalModule } from '@panel/app/pages/subscription/general/edit-billing-email-modal/edit-billing-email-modal.module';
import { EditRequisitesModalModule } from '@panel/app/pages/subscription/general/edit-requisites-modal/edit-requisites-modal.module';
import { FirstPaymentDiscountLabelModule } from '@panel/app/pages/subscription/general/first-payment-discount-label/first-payment-discount-label.module';
import { SignedModule } from '@panel/app/pages/subscription/general/signed/signed.module';
import { StripeEditCardModalModule } from '@panel/app/pages/subscription/general/stripe-edit-card-modal/stripe-edit-card-modal.module';
import { StripeInputModule } from '@panel/app/pages/subscription/general/stripe-input/stripe-input.module';
import { StripeThreeDSModalModule } from '@panel/app/pages/subscription/general/stripe-three-d-s-modal/stripe-three-d-s-modal.module';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [SubscriptionComponent],
  imports: [
    CommonModule,
    FirstPaymentDiscountLabelModule,
    NgbAlertModule,
    OverlayscrollbarsModule,
    ReactiveFormsModule,
    SharedModule,
    SignedModule,
    TranslocoModule,
    StripeEditCardModalModule,
    StripeInputModule,
    StripeThreeDSModalModule,
    EditBillingEmailModalModule,
    EditAccountingEmailsModalModule,
    EditRequisitesModalModule,
  ],
  providers: [CarrotquestHelperProvider, SubscriptionStore],
})
export class SubscriptionModule {}

<ng-container *transloco="let t; read: 'conversationUserListComponent'">
  <div class="card full-height overflow-y-hidden">
    <!--Поле поиска-->
    <div class="flex p-10 pl-15 pr-15 bordered-bottom">
      <button
        class="btn btn-secondary mr-15"
        type="button"
        (click)="backwardClick.emit()"
        [disabled]="isDataLoading$ | async"
        data-test="backward-button"
      >
        <i class="cqi-sm cqi-arrow-left"></i>
      </button>
      <form
        class="form-group m-0 flex-grow-1"
        autocomplete="off"
        (ngSubmit)="submitSearchForm()"
      >
        <div [cqShowError]="searchControl">
          <input
            class="form-control"
            maxlength="15"
            [formControl]="searchControl"
            [placeholder]="t('searchInput.placeholder')"
            type="text"
          />
          <cq-validation-messages
            position="bottom"
            [control]="searchControl"
          >
            <cq-validation-message errorName="minlength">
              {{ t('searchInput.errors.length', { max: QUERY_MAX_LENGTH, min: QUERY_MIN_LENGTH }) }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
      </form>
    </div>

    <div
      class="flex flex-col custom-scroll position-relative content-wrapper"
      [ngClass]="{ 'overflow-y-auto': (isDataLoading$ | async) === false }"
    >
      <!--Список пользователей-->
      <div
        *ngFor="let user of users$ | async; trackBy: trackByUserId"
        class="conversation-user p-15 flex align-items-center bordered-bottom cursor-pointer"
        [ngClass]="{ active: isUserSelected(user) }"
        (click)="selectUser(user)"
      >
        <cq-user-avatar
          class="mr-10"
          [avatar]="user.avatar"
        ></cq-user-avatar>
        <div class="full-width text-truncate">
          <span
            [cqHighlight]="highlightedText"
            [attr.title]="user.name"
            customClasses="p-0"
          >
            {{ user.name }}
          </span>
          <div class="text-muted flex flex-row">
            <small
              class="text-truncate"
              [attr.title]="user.props['$email']"
              [cqHighlight]="highlightedText"
              customClasses="p-0"
            >
              {{ user.props['$email'] }}
            </small>
            <small
              *ngIf="user.props['$email'] && user.props['$phone']"
              class="mx-5"
              >·</small
            >
            <small
              class="text-truncate"
              [attr.title]="user.props['$phone']"
              [cqHighlight]="highlightedText"
              customClasses="p-0"
            >
              {{ user.props['$phone'] }}
            </small>
          </div>
        </div>
      </div>

      <!--Показать еще-->
      <button
        *ngIf="((users$ | async)?.length || 0) < totalUsersAmount"
        class="btn btn-outline-primary align-self-center mt-20 mb-20"
        (click)="loadMoreUsers()"
        data-test="load-more-button"
      >
        {{ 'general.showMore' | transloco }}
      </button>

      <!--ZERO DATA-->
      <div
        *ngIf="!(users$ | async)?.length"
        class="align-items-center flex flex-col full-height justify-center p-40"
      >
        <img
          class="mb-20 zero-data-users-rabbit"
          [attr.src]="pathToZeroDataImg"
        />
        <div class="text-center">{{ t('zeroData.noUsers') }}</div>
      </div>

      <!--LOADER-->
      <div
        *ngIf="isDataLoading$ | async"
        class="position-absolute top-0 right-0 bottom-0 left-0 card border-0 mb-0"
      >
        <cq-loader></cq-loader>
      </div>
    </div>
  </div>
</ng-container>

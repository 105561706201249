<ng-container *transloco="let t; read: 'inputDateComponent'">
  <div class="flex align-items-center">
    <ng-select
      class="mr-5"
      [clearable]="false"
      [formControl]="control.controls.value"
      [items]="numbers"
    ></ng-select>

    <ng-select
      class="flex-grow"
      [clearable]="false"
      [formControl]="control.controls.units"
      [items]="units"
    >
      <ng-template
        ng-label-tmp
        ng-option-tmp
        let-item="item"
      >
        {{ t('units.' + item, { value: control.value.value }) }}
      </ng-template>
    </ng-select>
  </div>
</ng-container>

/**
 * Вызов $sce.trustAsResourceUrl() при помощи фильтра
 */
(function () {
  'use strict';

  angular.module('myApp.filters.trustUrl').filter('trustUrl', trustUrl);

  function trustUrl($sce) {
    return $sce.trustAsResourceUrl;
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';

import { ConversationChannelComponent } from './conversation-channel.component';

@NgModule({
  declarations: [ConversationChannelComponent],
  imports: [CommonModule, ChannelIconModule],
  exports: [ConversationChannelComponent],
})
export class ConversationChannelModule {}

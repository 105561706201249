import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { FirstPaymentDiscountLabelComponent } from './first-payment-discount-label.component';

@NgModule({
  declarations: [FirstPaymentDiscountLabelComponent],
  imports: [CommonModule, NgbTooltipModule, TranslocoModule],
  providers: [],
  exports: [FirstPaymentDiscountLabelComponent],
})
export class FirstPaymentDiscountLabelModule {}

(function () {
  'use strict';

  angular.module('myApp.chatBot', [
    'myApp.chatBot.facebookBot',
    'myApp.chatBot.leadBot',
    'myApp.chatBot.routingBot',
    'myApp.chatBot.telegramBot',
    'myApp.chatBot.widgetBot',
  ]);

  require('./edit/facebook-bot/facebook-bot.module');
  require('./edit/lead-bot/lead-bot.module');
  require('./edit/routing-bot/routing-bot.module');
  require('./edit/telegram-bot/telegram-bot.module');
  require('./edit/widget-bot/widget-bot.module');
  require('./chat-bot.routes');
  require('./list/list.constants');
  require('./list/list.component');
  require('./statistics/lead-bot/statistics.component');
  require('./statistics/statistics-wrapper/statistics-wrapper.component');
})();

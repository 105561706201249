import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ConversationReplyTimerComponent } from './conversation-reply-timer.component';

@NgModule({
  declarations: [ConversationReplyTimerComponent],
  exports: [ConversationReplyTimerComponent],
  imports: [CommonModule, TranslocoModule, NgbTooltipModule],
})
export class ConversationReplyTimerModule {}

(function () {
  'use strict';

  require('./article-edit.controller');

  angular.module('myApp.knowledgeBase').component('cqArticleEdit', cqArticleEdit());

  function cqArticleEdit() {
    return {
      bindings: {
        article: '=', // Статьи
        articlesCount: '=', // Количество статей
        billingInfo: '=', // Информация о биллинге
        currentApp: '=', // Текущий App,
        currentCategory: '=', // Текущяя категория
        djangoUser: '=',
        isKnowledgeBaseProActivated: '=', // Подключён ли модуль с продвинутой базой знаний
        settings: '=', // Настройки БЗ
      },
      controller: 'CqArticleEditController',
      controllerAs: 'vm',
      template: require('./article-edit.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'userCardHeadComponent'">
  <div class="card m-0">
    <div class="card-body padding-20 d-flex justify-space-between align-items-center">
      <!--Аватарка с именем-->
      <div class="d-flex align-items-center user-info-wrapper">
        <cq-user-avatar
          [avatar]="user.avatar"
          [status]="user.presence"
          size="lg"
          class="mr-10"
        ></cq-user-avatar>

        <h3 class="m-0 mr-10 text-truncate">{{ user.name }}</h3>

        <button
          (click)="openEditUserNameModal()"
          class="btn btn-borderless-primary"
          data-test="user-name-change-modal-button"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-pencil"></i>
        </button>
      </div>

      <!--Кнопки-->
      <div class="flex-shrink-0 margin-between-cols-10">
        <!-- Меню отправки сообщений -->
        <div class="d-inline-block">
          <div
            ngbDropdown
            placement="bottom-right"
          >
            <button
              class="btn btn-secondary"
              ngbDropdownToggle
              type="button"
              data-test="write-to-user"
            >
              <i class="btn-icon cqi-sm cqi-paper-plane-o"></i>
              <span class="inner-label">{{ t('messagesDropdown.sendButton') }}</span>
            </button>

            <div ngbDropdownMenu>
              <!-- Сообщение в чат -->
              <ng-container
                *tuiLet="accessToPartTypesManual(MESSAGE_PART_TYPES.POPUP_CHAT) as accessToManualMessagesPopupChat"
              >
                <button
                  (click)="writeToUser.emit(MESSAGE_PART_TYPES.POPUP_CHAT)"
                  class="btn btn-secondary"
                  data-test="write-popup-chat-button"
                  type="button"
                  ngbDropdownItem
                >
                  <i
                    *ngIf="accessToManualMessagesPopupChat.hasAccess"
                    class="btn-icon-left cqi-sm cqi-comment-out-о"
                  ></i>
                  <cq-plan-capability-coin
                    *ngIf="!accessToManualMessagesPopupChat.hasAccess"
                    class="btn-icon-left"
                  ></cq-plan-capability-coin>
                  <span>{{ t('messagesDropdown.popupChat') }}</span>
                </button>
              </ng-container>

              <!-- Поп-ап -->
              <ng-container *ngIf="isWebApp">
                <ng-container
                  *tuiLet="accessToPartTypesManual(MESSAGE_PART_TYPES.POPUP_SMALL) as accessToManualMessagesPopupSmall"
                >
                  <button
                    (click)="writeToUser.emit(MESSAGE_PART_TYPES.POPUP_SMALL)"
                    data-test="write-popup-small-button"
                    type="button"
                    ngbDropdownItem
                  >
                    <i
                      *ngIf="accessToManualMessagesPopupSmall.hasAccess"
                      class="btn-icon-left cqi-sm cqi-popup-small"
                    ></i>
                    <cq-plan-capability-coin
                      *ngIf="!accessToManualMessagesPopupSmall.hasAccess"
                      class="btn-icon-left"
                    ></cq-plan-capability-coin>
                    <span>{{ t('messagesDropdown.popupSmall') }}</span>
                  </button>
                </ng-container>
              </ng-container>

              <!-- Письмо -->
              <ng-container *ngIf="isWebApp">
                <ng-container
                  *tuiLet="accessToPartTypesManual(MESSAGE_PART_TYPES.EMAIL) as accessToManualMessagesEmail"
                >
                  <button
                    (click)="writeToUser.emit(MESSAGE_PART_TYPES.EMAIL)"
                    data-test="write-email-button"
                    type="button"
                    ngbDropdownItem
                  >
                    <i
                      *ngIf="accessToManualMessagesEmail.hasAccess"
                      class="btn-icon-left cqi-sm cqi-envelope-o"
                    ></i>
                    <cq-plan-capability-coin
                      *ngIf="!accessToManualMessagesEmail.hasAccess"
                      class="btn-icon-left"
                    ></cq-plan-capability-coin>
                    <span>{{ t('messagesDropdown.email') }}</span>
                  </button>
                </ng-container>
              </ng-container>

              <!-- Web Push -->
              <ng-container *ngIf="isWebApp && hasAccessToWebPush">
                <button
                  (click)="writeToUser.emit(MESSAGE_PART_TYPES.PUSH)"
                  data-test="write-push-button"
                  ngbDropdownItem
                  type="button"
                >
                  <i
                    *ngIf="accessToPartTypesManual(MESSAGE_PART_TYPES.PUSH).hasAccess"
                    class="btn-icon-left cqi-sm cqi-web-push"
                  ></i>
                  <cq-plan-capability-coin
                    *ngIf="!accessToPartTypesManual(MESSAGE_PART_TYPES.PUSH).hasAccess"
                    class="btn-icon-left"
                  ></cq-plan-capability-coin>
                  <span>{{ t('messagesDropdown.webPush') }}</span>
                </button>
              </ng-container>

              <!--Сообщение в Telegram-->
              <ng-container
                *tuiLet="accessToPartTypesManual(MESSAGE_PART_TYPES.TELEGRAM) as accessToManualMessagesTelegram"
              >
                <button
                  (click)="writeToUser.emit(MESSAGE_PART_TYPES.TELEGRAM)"
                  data-test="write-telegram-button"
                  type="button"
                  ngbDropdownItem
                >
                  <i
                    *ngIf="accessToManualMessagesTelegram.hasAccess"
                    class="btn-icon-left cqi-sm cqi-telegram"
                  ></i>
                  <cq-plan-capability-coin
                    *ngIf="!accessToManualMessagesTelegram.hasAccess"
                    class="btn-icon-left"
                  ></cq-plan-capability-coin>
                  <span>{{ t('messagesDropdown.telegram') }}</span>
                </button>
              </ng-container>

              <!--SDK-->
              <ng-container *ngIf="isUserActivatedInSdk">
                <div class="dropdown-divider"></div>
                <!-- Сообщение в приложении -->
                <button
                  (click)="writeToUser.emit(MESSAGE_PART_TYPES.SDK_POPUP_CHAT)"
                  ngbDropdownItem
                  data-test="write-sdk-popup-chat-button"
                  type="button"
                >
                  <i class="btn-icon-left cqi-sm cqi-mobile-comment-out"></i>
                  {{ t('messagesDropdown.SDKChat') }}
                </button>
                <!-- Поп-ап в приложении -->
                <button
                  (click)="writeToUser.emit(MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL)"
                  ngbDropdownItem
                  data-test="write-sdk-block-popup-small-button"
                  type="button"
                >
                  <i class="btn-icon-left cqi-sm cqi-mobile-block-popup"></i>
                  {{ t('messagesDropdown.SDKPopup') }}
                </button>
                <!-- Push в приложении -->
                <button
                  (click)="writeToUser.emit(MESSAGE_PART_TYPES.SDK_PUSH)"
                  ngbDropdownItem
                  data-test="write-sdk-push-button"
                  type="button"
                >
                  <i class="btn-icon-left cqi-sm cqi-mobile-push"></i>
                  {{ t('messagesDropdown.SDKPush') }}
                </button>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Копирование ссылки на карточку пользователя -->
        <button
          (click)="copyUserCardUrl()"
          class="btn btn-secondary"
          data-test="copy-user-url-button"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-link"></i>
        </button>

        <!-- Меню с доп. действиями -->
        <div class="d-inline-block">
          <div
            ngbDropdown
            placement="bottom-right"
          >
            <button
              class="btn btn-secondary"
              ngbDropdownToggle
              type="button"
            >
              <i class="btn-icon cqi-sm cqi-ellipsis"></i>
            </button>

            <div ngbDropdownMenu>
              <!-- Скопировать ссылку на карточку -->
              <button
                (click)="copyUserCardUrl()"
                ngbDropdownItem
                type="button"
              >
                <i class="btn-icon-left cqi-sm cqi-link"></i>
                {{ t('actionsDropdown.copyLinkOnUserCard') }}
              </button>
              <!-- Отписать пользователя от писем -->
              <div
                [ngbTooltip]="t('actionsDropdown.unsubscribe.tooltips.' + userEmailStatus)"
                placement="left"
              >
                <button
                  (click)="trackClickUnsubscribeUserFromEmail(); unsubscribeUser.emit()"
                  [disabled]="isEmailButtonsDisabled"
                  ngbDropdownItem
                  data-test="unsubscribe-user-button"
                  type="button"
                >
                  <i class="btn-icon-left cqi-sm cqi-envelope-ban"></i>
                  {{ t('actionsDropdown.unsubscribe.text') }}
                </button>
              </div>
              <!-- вариации пункта "Выслать подтверждение подписки" -->
              <!-- Double Opt-In отключён -->
              <div
                *ngIf="!currentApp.settings.double_opt_in; else isDoubleOptIn"
                [ngbTooltip]="t('actionsDropdown.sendConfirmationEmail.tooltips.doubleOptInDisabled')"
                placement="left"
              >
                <button
                  [disabled]="true"
                  ngbDropdownItem
                  type="button"
                >
                  <i class="btn-icon-left cqi-sm cqi-envelope-o"></i>
                  {{ t('actionsDropdown.sendConfirmationEmail.text') }}
                </button>
              </div>
              <!-- Double Opt-In влючён -->
              <ng-template #isDoubleOptIn>
                <div
                  [ngbTooltip]="t('actionsDropdown.sendConfirmationEmail.tooltips.' + userEmailStatus)"
                  placement="left"
                >
                  <button
                    (click)="trackClickSendSubscriptionConfirmationEmail(); doubleOptIn.emit()"
                    [disabled]="isEmailButtonsDisabled"
                    ngbDropdownItem
                    data-test="double-opt-in-button"
                    type="button"
                  >
                    <i class="btn-icon-left cqi-sm cqi-envelope-o"></i>
                    {{ t('actionsDropdown.sendConfirmationEmail.text') }}
                  </button>
                </div>
              </ng-template>
              <!-- Снова показывать чат этому пользователю -->
              <button
                *ngIf="user.props.$banned"
                class="dropdown-item"
                type="button"
                (click)="trackClickHideChat(); hideChat.emit(false)"
              >
                <i class="btn-icon-left cqi-sm cqi-eye"></i>
                {{ t('actionsDropdown.showChat') }}
              </button>
              <!-- Скрыть чат от этого пользоваетля -->
              <button
                *ngIf="!user.props.$banned"
                class="dropdown-item"
                type="button"
                (click)="trackClickHideChat(); hideChat.emit(true)"
              >
                <i class="btn-icon-left cqi-sm cqi-eye-closed"></i>
                {{ t('actionsDropdown.hideChat') }}
              </button>
              <!-- Скрыть пользователя -->
              <button
                (click)="trackClickHideUser(); hideUser.emit()"
                *ngIf="hasRemovePermissions"
                data-test="hide-user-button"
                ngbDropdownItem
                type="button"
              >
                <i class="btn-icon-left cqi-sm cqi-trash"></i>
                {{ t('actionsDropdown.hideUser') }}
              </button>
              <!-- Полностью удалить пользователя -->
              <button
                (click)="trackClickRemoveUser(); removeUser.emit()"
                *ngIf="hasRemovePermissions"
                class="text-danger"
                ngbDropdownItem
                data-test="remove-user-button"
                type="button"
              >
                <i class="btn-icon-left cqi-sm cqi-fire-o"></i>
                {{ t('actionsDropdown.deleteUser') }}
              </button>
            </div>
          </div>
        </div>

        <!-- Закрытие модалки (если компонент используется в модалке) -->
        <button
          (click)="closeBtnClick.emit()"
          *ngIf="isClosable"
          class="btn btn-borderless-primary"
          data-test="close-modal-button"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>

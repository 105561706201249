(function () {
  'use strict';

  angular.module('myApp.integrations').controller('FacebookLeadsController', FacebookLeadsController);

  function FacebookLeadsController(
    $scope,
    $filter,
    $q,
    $location,
    $state,
    $stateParams,
    $translate,
    $window,
    toastr,
    FACEBOOK_LEADS_INTEGRATION_STEPS,
    FACEBOOK_LEADS_STANDARD_FIELDS_TYPES,
    FACEBOOK_LEADS_STANDARD_PROPS_DEFAULT,
    PROJECT_NAME,
    integrationModel,
    l10nHelper,
    propertyModel,
    validationHelper,
    wizardHelper,
    currentApp,
    integration,
  ) {
    let vm = this;

    vm.$translate = $translate;
    vm.addForm = addForm;
    vm.authorize = authorize;
    vm.checkMapping = checkMapping;
    vm.checkOAuth = checkOAuth;
    vm.checkQuestions = checkQuestions;
    vm.createEventCustomName = createEventCustomName;
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.currentStep = angular.isDefined(integration.settings.lastCompletedStep)
      ? getStepByIndex(FACEBOOK_LEADS_INTEGRATION_STEPS, integration.settings.lastCompletedStep + 1)
      : getStepByIndex(FACEBOOK_LEADS_INTEGRATION_STEPS, 0); // текущий шаг визарда
    vm.FACEBOOK_LEADS_INTEGRATION_STEPS = FACEBOOK_LEADS_INTEGRATION_STEPS;
    vm.FACEBOOK_LEADS_STANDARD_FIELDS_TYPES = FACEBOOK_LEADS_STANDARD_FIELDS_TYPES;
    vm.FACEBOOK_LEADS_STANDARD_PROPS_DEFAULT = FACEBOOK_LEADS_STANDARD_PROPS_DEFAULT;
    vm.forms = [];
    vm.formsErrors = {
      noForms: false,
    };
    vm.integration = integration;
    vm.initForms = initForms;
    vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
      ? vm.currentStep.ORDER > integration.settings.lastCompletedStep
      : angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.oAuthErrors = {
      required: false,
      auth: false,
    };
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.props = [];
    vm.removeItem = removeItem;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.updateUserPages = updateUserPages;
    vm.validationHelper = validationHelper;
    vm.wizard = null; // инстанс визарда

    init();

    function init() {
      // пока Facebook заблокирован в РФ - будем принудительно показывать предупреждение
      if (l10nHelper.isRusCountry()) {
        integration.warnings = integration.warnings || [];
        integration.warnings.push('blocking');
      }

      firstValueFrom(propertyModel.getList(currentApp.id)).then(getPropertyListSuccess);
      // initForms();

      if (
        angular.equals(integration.settings.lastCompletedStep, vm.currentStep.ORDER) &&
        angular.equals(integration.settings.lastCompletedStep, 3)
      ) {
        initForms();
      }

      $scope.$watch('vm.currentStep', function (newValue) {
        //обновляем состояние инструкции в зависимости от текущего шага
        vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
          ? newValue.ORDER > integration.settings.lastCompletedStep
          : angular.isUndefined(integration.id);
      });

      $scope.$watchCollection('[vm.forms, vm.integration.settings.forms]', function (newValues) {
        // если изменились формы, надо записать новые в настройки
        if (newValues[0].length && newValues[1].length) {
          for (let i = 0; i < newValues[1].length; i++) {
            vm.checkQuestions($filter('filter')(newValues[0], { id: newValues[1][i].id })[0], newValues[1][i]);
          }
        }
      });

      wizardHelper.getWizard().then(getWizardSuccess);

      function getPropertyListSuccess(properties) {
        vm.props = properties.userProps;
      }

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }
    }

    /**
     * Добавление новой формы в массив форм
     *
     * @param {Array} array Массив, в который добавляется объект
     */
    function addForm(array) {
      array.push({
        customEvent: false,
        eventName: '',
        id: '',
        mapping: {},
      });
    }

    /**
     * Авторизация в Facebook
     */
    function authorize() {
      let w = 350;
      let h = 250;

      let wLeft = $window.screenLeft ? $window.screenLeft : $window.screenX;
      let wTop = $window.screenTop ? $window.screenTop : $window.screenY;

      let left = wLeft + $window.innerWidth / 2 - w / 2;
      let top = wTop + $window.innerHeight / 2 - h / 2;

      // чтобы всплывающее окно корректно открылось - его вначале надо открыть без url, а затем, когда url получен с сервера - заменить location.href на этот url
      let authWindow = $window.open(
        '',
        '_blank',
        'toolbar=0, menubar=0,height=' + h + ',width=' + w + ',top=' + top + ',left=' + left,
      );

      integrationModel.facebookLeads.getOAuthUrl(currentApp.id, integration.id).then(function (url) {
        authWindow.location.href = url;
      });

      /**
       * Удаление слушателя сообщений из дочернего окна
       */
      function destroyMessageListener() {
        $window.removeEventListener('message', messageListener);
      }

      $window.addEventListener('message', messageListener, false);

      function messageListener(message) {
        $scope.$apply(function () {
          let origin = $('<a>', { href: message.origin })[0];

          if (origin.hostname === $location.host()) {
            if (message.data.status === 'accepted') {
              vm.oAuthErrors.auth = false;
              vm.oAuthErrors.required = false;
            } else if (message.data.status === 'error') {
              vm.oAuthErrors.auth = true;
            }
          }

          authWindow.close();
          destroyMessageListener();
          vm.wizard.nextStep();
        });
      }
    }

    function checkMapping(mapping, key, checked) {
      if (!checked) {
        delete mapping[key];
      }
    }

    /**
     * Проверяет авторизацию в Facebook, и если человек авторизован - делает запрос на получение страниц
     *
     * @return {Promise}
     */
    function checkOAuth() {
      if (!(vm.oAuthErrors.required || vm.oAuthErrors.auth)) {
        if (integration.settings.accessToken) {
          // если accessToken присутствует - запрашиваем актуальные страницы и переходим на следующий шаг
          return getUserPages(integration).then(getUserPagesSuccess);
        } else {
          // если accessToken отсутствует - проверяем его наличие в самой актуальной интеграции, и если он там есть - запрашиваем страницы Facebook
          return integrationModel.get(integration.id, integration.type).then(getSuccess).then(getUserPagesSuccess);
        }
      } else {
        return $q.reject();
      }

      function getSuccess(refreshedIntegration) {
        angular.extend(integration, refreshedIntegration);

        if (!integration.settings.accessToken) {
          vm.oAuthErrors.required = true;
          return $q.reject();
        } else {
          return getUserPages(integration);
        }
      }

      function getUserPagesSuccess() {
        createOrUpdateIntegration();
      }
    }

    function checkQuestions(item, form) {
      for (let i = 0; i < item.questions.length; i++) {
        if (angular.isDefined(form.mapping[item.questions[i].key])) {
          item.questions[i].checked = true;
        } else {
          item.questions[i].checked = false;
        }
      }
    }

    /**
     * Автоматическое создание кастомного имени события eventName для формы
     *
     * @param form
     */
    function createEventCustomName(form) {
      if (form.customEvent && !form.eventName) {
        let fbForm = $filter('filter')(vm.forms, { id: form.id }, true)[0] || null;
        if (fbForm) {
          let defaultEvent = $translate.instant('models.eventType.systemEventTypes.$filled_facebook_lead_ad');
          let name = $translate.instant('integrations.facebookLeads.step4.step4Form.forms.eventName.customTemplate', {
            defaultEvent: defaultEvent,
            formName: fbForm.name,
          });
          form.eventName = name;
        }
      }
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {form.FormController=} form Контроллер формы
     */
    function createOrUpdateIntegration(form) {
      if (form) {
        form.$commitViewValue();
        form.$setSubmitted();

        if (form.$invalid) {
          return $q.reject();
        }
      }

      integration.settings.lastCompletedStep =
        integration.settings.lastCompletedStep > vm.currentStep.ORDER
          ? integration.settings.lastCompletedStep
          : vm.currentStep.ORDER;

      if (integration.id) {
        return integrationModel.save(currentApp.id, integration).then(saveSuccess);
      } else {
        return integrationModel.create(currentApp.id, integration).then(createSuccess);
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }

      function createSuccess(integration) {
        let params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        let transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка "Назад" вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }
    }

    /**
     * Получение шага из массива/объекта шагов steps по индексу index
     *
     * @param {Object|Array.<Object>} steps Массив/объект шагов, из которых осуществляется выборка
     * @param {Number} index Индекс шага (шаги упорядочены по ORDER, т.е. этот индекс и является ORDER)
     * @return {Object}
     */
    function getStepByIndex(steps, index) {
      let stepsArray = $filter('toArray')(steps);

      // если номер искомого шага больше, чем количество всех шагов - полагаем, что надо найти последний шаг
      index = index > stepsArray.length - 1 ? stepsArray.length - 1 : index;

      let step = $filter('filter')(stepsArray, { ORDER: index }, true)[0];
      if (step) {
        return step;
      } else {
        return $filter('filter')(stepsArray, { ORDER: 0 }, true)[0];
      }
    }

    /**
     * Получение страниц пользователя для интеграции с Facebook
     *
     * @param {Object} integration Интеграция
     * @return {Promise}
     */
    function getUserPages(integration) {
      return integrationModel.facebookLeads.getUserPages(integration.id).then(getUserPagesSuccess);

      function getUserPagesSuccess(pages) {
        integration.settings.pages = pages;
      }
    }

    /**
     * Удаление объекта из массива
     *
     * @param array
     * @param item
     */
    function removeItem(array, item) {
      let index = array.indexOf(item);

      if (~index) {
        array.splice(index, 1);
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }

    /**
     * Обновление состояний пользовательских страниц
     */
    function updateUserPages() {
      return integrationModel.facebookLeads.subscribe(integration).then(subscribeSuccess);

      function subscribeSuccess(pages) {
        integration.settings.pages = pages;
        return initForms();
      }
    }

    function initForms() {
      if (angular.isDefined(integration.settings.pages) && Object.keys(integration.settings.pages).length > 0) {
        if (angular.isUndefined(integration.settings.forms)) {
          integration.settings.forms = [];
          addForm(integration.settings.forms);
        }

        if (integration.id) {
          return integrationModel.facebookLeads.getForms(integration.id).then(getFormsSuccess);
        }
      }
      function getFormsSuccess(forms) {
        if (forms.length > 0) {
          vm.forms = forms;
        } else {
          vm.formsErrors.noForms = true;
          return $q.reject();
        }
      }
    }
  }
})();

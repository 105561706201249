(function () {
  'use strict';

  require('./team-members-table-wrapper.controller');

  angular.module('myApp.teamMembers').component('cqTeamMembersTableWrapper', cqTeamMembersTableWrapper());

  function cqTeamMembersTableWrapper() {
    return {
      bindings: {
        appBlocks: '=', // данные о блокировках приложения
        billingInfo: '=', // Информация по биллингу
        currentApp: '=', // текущее приложение
        djangoUser: '=', // текущий пользователь
        memberInvitations: '=', // приглашения членов команды
        teamMembers: '=', // члены команды
        teamMembersGroups: '=', // Группы членов команд
      },
      controller: 'CqTeamMembersTableWrapperController',
      controllerAs: 'vm',
      template: require('./team-members-table-wrapper.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { ChatWidgetInsertingIntoChatComponent } from '@panel/app/pages/widget-bot/editor/components/inserting-into-chat-toggle/chat-widget-inserting-into-chat.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [ChatWidgetInsertingIntoChatComponent],
  exports: [ChatWidgetInsertingIntoChatComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, FormsModule, ReactiveFormsModule],
})
export class ChatWidgetInsertingIntoChatModule {}

(function () {
  'use strict';

  require('./conversations-conversation.controller');
  require('./close-hotkey-onboarding-popover.html');
  require('./slash-commands-dropdown.html');

  angular.module('myApp.conversations').component('cqConversationsConversation', cqConversationsConversation());

  function cqConversationsConversation() {
    return {
      bindings: {
        activeConversation: '=', // текущий диалог
        appBlocks: '=', // данные о блокировках приложения
        billingInfo: '=', // информация о биллинге
        channels: '=', // список каналов
        currentApp: '=', // текущее приложение
        djangoUser: '=', // django-пользователь
        isDarkThemeActive: '=', // Активна ли темная тема
        knowledgeBaseSettings: '=', // настройки БЗ
        onConversationClose: '&?', // функция, вызываемая при закрытии текущего диалога
        onOpenConversationClick: '<?', // функция, вызываемая при нажатии на кнопку перехода в диалог
        onShowHistory: '&?', // функция, вызываемая при клике на показ истории
        onShowUseCalendlyPopover: '&', // Функция, вызываемая для показа поповера Use Calendly
        telegramIntegrations: '=',
        showUseCalendlyPopover: '=',
        tags: '=', // список тегов для диалогов
        teamMembers: '=', // список членов команды
        updateActiveConversation: '&?', // функция, обновление данных в активном диалоге
      },
      controller: 'CqConversationsConversationController',
      controllerAs: 'vm',
      template: require('./conversations-conversation.html'),
    };
  }
})();

(function () {
  'use strict';

  require('./add-event-modal.controller');

  angular.module('myApp.userCard').component('cqAddEventModal', cqAddEventModal());

  function cqAddEventModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp - Текущее приложение
        // eventTypes — все доступные события
        // userId - id пользователя
      },
      controller: 'CqAddEventModalController',
      controllerAs: 'vm',
      template: require('./add-event-modal.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';

import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { TriggersPopoverTemplateComponent } from '@panel/app/pages/chat-bot/content/views/badges/triggers-popover-template/triggers-popover-template.component';
import { ChatBotTemplateModule } from '@panel/app/partials/modals/chat-bot-template/chat-bot-template.module';
import { PixiStats } from '@panel/app/services/pixi/stats';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BotBranchEditorModule } from './branch-editor/branch-editor.module';
import { BotCanvasEditorModule } from './canvas-editor/bot-canvas-editor.module';
import { ContentComponent } from './content.component';
import { DescriptionPopoverTemplateComponent } from './views/badges/description-popover-template/description-popover-template.component';

@NgModule({
  declarations: [ContentComponent, DescriptionPopoverTemplateComponent, TriggersPopoverTemplateComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbTooltipModule,
    NgbDropdownModule,
    BotCanvasEditorModule,
    ChatBotTemplateModule,
    BotBranchEditorModule,
    BrowserAnimationsModule,
    SharedModule,
  ],
  exports: [ContentComponent],
  providers: [ClipboardService, StateService, PixiInteractionService, PixiStats],
})
export class ContentModule {}

/**
 * Контроллер для модального окна завершения А/Б теста
 */
import { MESSAGE_STATISTICS } from '../../../../app/http/message-statistics/message-statistics.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.completeAbTest')
    .controller('CompleteAbTestModalController', CompleteAbTestModalController);

  function CompleteAbTestModalController(
    $scope,
    $filter,
    $uibModalInstance,
    moment,
    messageModel,
    messagePartModel,
    messageTestGroupModel,
    validationHelper,
    selectedMessagePart,
    testGroup,
  ) {
    var vm = this;

    vm.abTestName = testGroup.name; // название тест-группы (по сути это и есть название А/Б теста)
    vm.completeAbTest = completeAbTest;
    vm.completeAbTestForm = null; // форма завершения А/Б теста
    vm.messageParts = messagePartModel.filterMessageParts(testGroup.parts);
    vm.messagePartStatistics = []; // статистика тест-группы
    vm.selectedMessagePart = selectedMessagePart || null; // выбранный вариант сообщения для завершения (может прийти снаружи)
    vm.validationHelper = validationHelper;

    init();

    function init() {
      // т.к. статистика у каждого варианта сообщения в тест-группе взята не пойми за какой период, либо её там вообще нету - надо запросить статистику за последние 90 дней и в темплейте
      // для подсчёта конверсии и дохода уже исходить из неё
      firstValueFrom(
        messageTestGroupModel.getMessageTestGroupStatistics(
          testGroup.id,
          moment().subtract(90, 'days'),
          moment(),
          undefined,
          MESSAGE_STATISTICS.RANGES.NO,
        ),
      ).then(getMessageTestGroupStatisticsSuccess);

      function getMessageTestGroupStatisticsSuccess(statistics) {
        vm.messagePartStatistics = statistics;
      }
    }

    /**
     * Нажатие на кнопку 'Завершить тест'
     *
     * @param {Boolean} isValid Текущая валидность формы
     */
    function completeAbTest(isValid) {
      if (isValid) {
        var messagePartToContinue = $filter('filter')(
          vm.messageParts,
          { id: '!' + vm.selectedMessagePart.id },
          true,
        )[0];

        firstValueFrom(messageTestGroupModel.closeTestGroup(testGroup.id, messagePartToContinue.id)).then(
          closeTestGroupSuccess,
        );
      }

      function closeTestGroupSuccess(newTestGroup) {
        testGroup.closed = moment();
        $uibModalInstance.close({
          selectedMessagePart: vm.selectedMessagePart,
          newTestGroup: newTestGroup,
        });
      }
    }
  }
})();

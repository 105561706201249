import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { MESSAGE_TYPES } from '@http/message/message.constants';
import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';

/** Список ограничений */
export enum PLAN_CAPABILITIES {
  /** Аналитика диалогов */
  ANALYTICS_CONVERSATIONS = 'analytics_conversations',
  /** Аналитика диалогов по каналам */
  ANALYTICS_CONVERSATIONS_CHANNELS = 'analytics_conversations_channels',
  /** Аналитика диалогов по операторам */
  ANALYTICS_CONVERSATIONS_OPERATORS = 'analytics_conversations_operators',
  /** Аналитика диалогов по тегам */
  ANALYTICS_CONVERSATIONS_TAGS = 'analytics_conversations_tags',
  /** Аналитика диалогов по источникам */
  ANALYTICS_CONVERSATIONS_SOURCE = 'analytics_conversations_source',
  /** Аналитика диалогов по качеству поддержки */
  ANALYTICS_CONVERSATIONS_VOTE = 'analytics_conversations_vote',
  /** Группы членов команды */
  APPS_ADMIN_GROUPS = 'apps_admin_groups',
  /** AB тесты для триггерных сообщений */
  AUTO_MESSAGES_AB_TESTING = 'auto_messages_ab_testing',
  /** Триггерные сообщения типа "Конструктор поп-апов" */
  AUTO_MESSAGES_BLOCK_POPUP = 'auto_messages_block_popup',
  /** Триггерные сообщения типа "Чат" */
  AUTO_MESSAGES_CHAT = 'auto_messages_chat',
  /** Контрольные группы для триггерных сообщений */
  AUTO_MESSAGES_CONTROL_GROUP = 'auto_messages_control_group',
  /** Триггерные сообщения типа "Email" */
  AUTO_MESSAGES_EMAIL = 'auto_messages_email',
  /** События для цепочек сообщений */
  AUTO_MESSAGES_EVENTS_FOR_CHAINS = 'auto_messages_events_for_chains',
  /** Массовая активация триггерных сообщений */
  AUTO_MESSAGES_MASS_ACTIVATION = 'auto_messages_mass_activation',
  /** Триггерные сообщения типа "Поп-ап" */
  AUTO_MESSAGES_POPUP = 'auto_messages_popup',
  /** Триггерные сообщения типа "Web push" */
  AUTO_MESSAGES_PUSH = 'auto_messages_push',
  /** Триггерные сообщения типа "JS" */
  AUTO_MESSAGES_RUN_SCRIPT = 'auto_messages_run_script',
  /** Триггерные сообщения типа "SDK" */
  AUTO_MESSAGES_SDK_RECIPIENT = 'auto_messages_sdk_recipient',
  /** Максимальное количество триггерных сообщений */
  AUTO_MESSAGES_TOTAL = 'auto_messages_total',
  /** Триггерные сообщения типа "Webhook" */
  AUTO_MESSAGES_WEBHOOK = 'auto_messages_webhook',
  /** Триггерные сообщения типа "Telegram" */
  AUTO_MESSAGES_TELEGRAM = 'auto_messages_telegram',
  /** Конструктор писем */
  BEE_EDITOR = 'bee_editor',
  /** Массовая активация чат-ботов */
  CHAT_BOTS_MASS_ACTIVATION = 'chat_bots_mass_activation',
  /** Количество чат-ботов */
  CHAT_BOTS_TOTAL = 'chat_bots_total',
  /** Автоназначение диалогов */
  CONVERSATIONS_AUTO_ASSIGNMENT = 'conversations_auto_assignment',
  /** Dashboard */
  DASHBOARD = 'dashboard',
  /** Разработчикам */
  DEVELOPERS = 'developers',
  /** Downgrade */
  DOWNGRADE = 'downgrade',
  /** Кастомные события пользователей */
  EVENTS_EVENT_TYPES_CUSTOM = 'events_event_types_custom',
  /** Аналитика воронок */
  EVENTS_FUNNELS = 'events_funnels',
  /** Аналитические интеграции */
  INTEGRATIONS_ANALYTICS = 'integrations_analytics',
  /** CRM интеграции */
  INTEGRATIONS_CRM = 'integrations_crm',
  /** JS интеграции */
  INTEGRATIONS_JSSCRIPT = 'integrations_jsscript',
  /** Интеграция "Уведомление о лиде на email" */
  INTEGRATIONS_EMAIL_NOTIFICATION = 'integrations_email_notification',
  /** Количество интеграций*/
  INTEGRATIONS_TOTAL = 'integrations_total',
  /** Webhook интеграции */
  INTEGRATIONS_WEBHOOK = 'integrations_webhook',
  /** База знаний */
  KNOWLEDGE_BASE = 'knowledge_base',
  /** База знаний PRO */
  KNOWLEDGE_BASE_PRO = 'knowledge_base_pro',
  /** Количество лид-ботов */
  LEAD_BOTS_TOTAL = 'lead_bots_total',
  /** Лиды */
  LEADS = 'leads',
  /** Пользователи онлайн */
  LIVE = 'live',
  /** Ручные сообщения типа "Конструктор поп-апов" */
  MANUAL_MESSAGES_BLOCK_POPUP = 'manual_messages_block_popup',
  /** Возможность множественной отправки ручных сообщений */
  MANUAL_MESSAGES_BULK_SEND = 'manual_messages_bulk_send',
  /** Ручные сообщения типа "Чат" */
  MANUAL_MESSAGES_CHAT = 'manual_messages_chat',
  /** Отложенная отправка ручных сообщений */
  MANUAL_MESSAGES_DELAYED_DELIVERY = 'manual_messages_delayed_delivery',
  /** Ручные сообщения типа "Email" */
  MANUAL_MESSAGES_EMAIL = 'manual_messages_email',
  /** Ручные сообщения типа "Поп-ап" */
  MANUAL_MESSAGES_POPUP = 'manual_messages_popup',
  /** Ручные сообщения типа "Web Push" */
  MANUAL_MESSAGES_PUSH = 'manual_messages_push',
  /** Ручные сообщения в "SDK" */
  MANUAL_MESSAGES_SDK_RECIPIENT = 'manual_messages_sdk_recipient',
  /** Ручные рассылки в "Telegram" */
  MANUAL_MESSAGES_TELEGRAM = 'manual_messages_telegram',
  /** Статистика по ручным рассылкам */
  MANUAL_MESSAGES_STATS = 'manual_messages_stats',
  /** Ручное создание каналов */
  MESSAGES_CHANNELS = 'messages_channels',
  /** Автоматическое перемещение диалогов в каналы */
  MESSAGES_CHANNELS_AUTO_SET = 'messages_channels_auto_set',
  /** Шаблоны триггерных сообщений */
  MESSAGES_TEMPLATES = 'messages_templates',
  /** Готовые сценарии */
  READY_AUTO_MESSAGES = 'ready_auto_messages',
  /** Отчёты */
  REPORTS = 'reports',
  /** Члены команды */
  TEAM_MEMBERS = 'team_members',
  /** Кол-во телеграм ботов */
  TELEGRAM_BOTS_TOTAL = 'telegram_bots_total',
  /** Мастер сбора данных */
  TRACK_MASTER_JS_CODE = 'track_master_js_code',
  /** Снятие назначения с диалога */
  UNASSIGN_CONVERSATIONS = 'unassign_conversations',
  /** Кастомные свойства пользователей */
  USERS_CUSTOM_PROPERTIES = 'users_custom_properties',
  /** События пользователей */
  USERS_EVENTS = 'users_events',
  /** Экспорт пользователей */
  USERS_EXPORT = 'users_export',
  /** Свойства пользователя */
  USERS_PROPS = 'users_props',
  /** Теги пользователей */
  USERS_TAGS = 'users_tags',
  /** Интеграция WhatsApp */
  INTEGRATIONS_WHATSAPP = 'integrations_whatsapp',
  /** Расшифровка аудиосообщений */
  TRANSCRIBE_AUDIO = 'transcribe_audio',
}

/** Массив ограничений, которые необходимы для имитации работы механизма ограничений */
export const FAKE_PLAN_CAPABILITIES = [PLAN_CAPABILITIES.INTEGRATIONS_EMAIL_NOTIFICATION];

/**
 * Получения списка причин ограничений
 */
export enum PLAN_CAPABILITIES_REASON {
  EXCESS_NUMBER = 'excessNumber', // Превышение количества созданных автосообщений данного типа
  NOT_AVAILABLE = 'notAvailable', // Недоступность выбранного типа автосообщения в тарифе
  OTHER = 'other', // Недоступность нескольких функций одновременно
}

/**
 * Получение даты релиза блокировок по тарифам
 */
export enum PLAN_CAPABILITIES_RELEASE_DATE {
  RU = '2021-05-17',
  US = '2020-11-23',
}

/**
 * Получение названия ограничения по типу действия чат-бота
 */
export const PLAN_CAPABILITY_BY_CHAT_BOT_ACTION_TYPE: any = {
  [CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION]: PLAN_CAPABILITIES.INTEGRATIONS_EMAIL_NOTIFICATION,
  [CHAT_BOT_ACTIONS_TYPES.EVENT]: PLAN_CAPABILITIES.EVENTS_EVENT_TYPES_CUSTOM,
  [CHAT_BOT_ACTIONS_TYPES.USER_TAG]: PLAN_CAPABILITIES.USERS_TAGS,
};

/**
 * Получение названия ограничения по типу сообщения
 */
export const PLAN_CAPABILITY_BY_MESSAGE_PART_TYPE = {
  [MESSAGE_TYPES.AUTO]: {
    [MESSAGE_PART_TYPES.BLOCK_POPUP_BIG]: PLAN_CAPABILITIES.AUTO_MESSAGES_BLOCK_POPUP,
    [MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL]: PLAN_CAPABILITIES.AUTO_MESSAGES_BLOCK_POPUP,
    [MESSAGE_PART_TYPES.EMAIL]: PLAN_CAPABILITIES.AUTO_MESSAGES_EMAIL,
    [MESSAGE_PART_TYPES.JS]: PLAN_CAPABILITIES.AUTO_MESSAGES_RUN_SCRIPT,
    [MESSAGE_PART_TYPES.POPUP_BIG]: PLAN_CAPABILITIES.AUTO_MESSAGES_POPUP,
    [MESSAGE_PART_TYPES.POPUP_CHAT]: PLAN_CAPABILITIES.AUTO_MESSAGES_CHAT,
    [MESSAGE_PART_TYPES.POPUP_SMALL]: PLAN_CAPABILITIES.AUTO_MESSAGES_POPUP,
    [MESSAGE_PART_TYPES.PUSH]: PLAN_CAPABILITIES.AUTO_MESSAGES_PUSH,
    [MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL]: PLAN_CAPABILITIES.AUTO_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.SDK_POPUP_CHAT]: PLAN_CAPABILITIES.AUTO_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.SDK_PUSH]: PLAN_CAPABILITIES.AUTO_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.WEBHOOK]: PLAN_CAPABILITIES.AUTO_MESSAGES_WEBHOOK,
    [MESSAGE_PART_TYPES.TELEGRAM]: PLAN_CAPABILITIES.AUTO_MESSAGES_TELEGRAM,
  },
  [MESSAGE_TYPES.MANUAL]: {
    [MESSAGE_PART_TYPES.BLOCK_POPUP_BIG]: PLAN_CAPABILITIES.MANUAL_MESSAGES_BLOCK_POPUP,
    [MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL]: PLAN_CAPABILITIES.MANUAL_MESSAGES_BLOCK_POPUP,
    [MESSAGE_PART_TYPES.EMAIL]: PLAN_CAPABILITIES.MANUAL_MESSAGES_EMAIL,
    [MESSAGE_PART_TYPES.POPUP_BIG]: PLAN_CAPABILITIES.MANUAL_MESSAGES_POPUP,
    [MESSAGE_PART_TYPES.POPUP_CHAT]: PLAN_CAPABILITIES.MANUAL_MESSAGES_CHAT,
    [MESSAGE_PART_TYPES.POPUP_SMALL]: PLAN_CAPABILITIES.MANUAL_MESSAGES_POPUP,
    [MESSAGE_PART_TYPES.PUSH]: PLAN_CAPABILITIES.MANUAL_MESSAGES_PUSH,
    [MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL]: PLAN_CAPABILITIES.MANUAL_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.SDK_POPUP_CHAT]: PLAN_CAPABILITIES.MANUAL_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.SDK_PUSH]: PLAN_CAPABILITIES.MANUAL_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.TELEGRAM]: PLAN_CAPABILITIES.MANUAL_MESSAGES_TELEGRAM,
  },
};

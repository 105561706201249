import { InjectionToken, Provider } from '@angular/core';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { DestroyService } from '@panel/app/services';

// Do not! call BranchCollection constructor because of issue with extending Map. See create() method comment
export class BranchViewMap extends Map<string, Branch> {
  private _update?: Subject<void>;

  getParentsForEveryBlock(): Record<string, Branch[]> {
    const blocksToItsParents: Record<string, Branch[]> = {};

    for (const branch of this.values()) {
      branch.actions.forEach((action) => {
        const nextBranchLinkId = action.nextBranchLinkId.value;
        if (!nextBranchLinkId) {
          return;
        }
        if (!blocksToItsParents.hasOwnProperty(nextBranchLinkId)) {
          blocksToItsParents[nextBranchLinkId] = [];
        }
        blocksToItsParents[nextBranchLinkId].push(branch);
      });
    }

    return blocksToItsParents;
  }

  get update$() {
    this.createUpdateSubjectOrNone();
    return this._update!.asObservable();
  }

  set(key: string, value: Branch): this {
    this.createUpdateSubjectOrNone();
    this._update!.next();
    return super.set(key, value);
  }

  delete(key: string): boolean {
    this.createUpdateSubjectOrNone();
    this._update!.next();
    return super.delete(key);
  }

  clear() {
    this.createUpdateSubjectOrNone();
    this._update!.next();
    return super.clear();
  }

  private createUpdateSubjectOrNone() {
    if (this._update) {
      return;
    }
    this._update = new Subject();
  }

  // There is an issue with extending base JS views https://github.com/microsoft/TypeScript/issues/10853
  static create(): BranchViewMap {
    const map = new Map();
    // @ts-ignore
    map['__proto__'] = BranchViewMap.prototype;
    return map as BranchViewMap;
  }
}

export const BRANCH_VIEW_MAP = new InjectionToken<BranchViewMap>(
  'Map to collect and share active branches between components and services',
);

export const branchViewMapProvider: Provider = {
  provide: BRANCH_VIEW_MAP,
  useFactory: (destroy$: DestroyService) => {
    let collection: BranchViewMap | null = BranchViewMap.create();
    destroy$.pipe(first()).subscribe(() => {
      collection?.clear();
      collection = null;
    });
    return collection;
  },
  deps: [DestroyService],
};

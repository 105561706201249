import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';

import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { LS_ONE_TIME_FLAGS } from '@panel/app/shared/constants/localstorage.keys';

/**
 * Сервис для работы с функционалом, который нужно выполнить один раз за время жизни пользователя.
 * Например, нужно показать онбординговую модалку только один раз.
 * Работы сервиса основана на хранении флагов в localStorage.
 * Список всех таких флагов находится в ONE_TIME_FLAGS
 *
 * NOTE Такой же сервис есть в AngularJS. Если вносишь сюда правки, то не забудь внести и в AngularJS
 */
@Injectable({
  providedIn: 'root',
})
export class OneTimeFlagService {
  constructor(@Inject(LOCAL_STORAGE) readonly localStorage: Storage) {}

  /**
   * Получить список выставленных флагов
   *
   * @private
   */
  public getList(): Array<ONE_TIME_FLAGS | null> {
    return JSON.parse(this.localStorage.getItem(LS_ONE_TIME_FLAGS) || '[]');
  }

  /**
   * Проверка содержится ли флаг в общем списке выставленных флагов
   *
   * @param flag Выставляемый флаг
   * @private
   */
  public isSet(flag: ONE_TIME_FLAGS): boolean {
    let currentValues = this.getList();

    return currentValues.includes(flag);
  }

  /**
   * Записать флаг в список выставленных флагов
   *
   * @param flag Выставляемый флаг
   */
  public set(flag: ONE_TIME_FLAGS): void {
    if (!this.isSet(flag)) {
      let currentValues = this.getList();

      currentValues.push(flag);
      this.localStorage.setItem(LS_ONE_TIME_FLAGS, JSON.stringify(currentValues));
    }
  }
}

(function () {
  'use strict';

  angular
    .module('myApp.services.validationHelper')
    .constant('VALIDATION_EXPRESSION', getValidationExpression())
    .constant('VALIDATION_SUBMIT_EXPRESSION', getValidationSubmitExpression());

  /**
   * Выражение для валидации, используемое в $parse
   *
   * @returns {String}
   */
  function getValidationExpression() {
    return '\
      form.FIELD_NAME.$invalid && (\
        form.$submitted || (\
          form.FIELD_NAME.$dirty && form.FIELD_NAME.$touched\
        )\
      )';
  }

  /**
   * Выражение для валидации, используемое в $parse по сабмиту формы
   *
   * @returns {String}
   */
  function getValidationSubmitExpression() {
    return 'form.FIELD_NAME.$invalid && form.$submitted';
  }
})();

<ng-container *transloco="let t; read: 'chatWidgetInsertingIntoChatComponent'">
  <cq-switch-new [formControl]="control">
    <ng-template
      #trueText
      #falseText
    >
      <h4 class="mb-0 inline align-middle">{{ t('switch.text') }}</h4>
    </ng-template>
  </cq-switch-new>
  <div class="mt-10 text-secondary">{{ t('description') }}</div>
</ng-container>

import isEqual from 'lodash-es/isEqual';

import {
  ModeSettings,
  VISIBILITY_MODE,
  VISIBILITY_OPTIONS,
} from '@panel/app/pages/settings/chat/components/visibility-settings/visibility-settings.component';
import { UrlFilter } from '@panel/app/partials/url-filter-configurator/single-url-filter-configurator/single-url-filter-configurator.component';
import { UrlFilterExternal, UrlFilterMapper } from '@panel/app/partials/url-filter-configurator/url-filter-mapper';
import { ELASTICSEARCH_PROPERTY_OPERATIONS } from '@http/property/property.constants';

type DeviceModeSettingsExternal = {
  type: 'and' | 'or';
  filters: UrlFilterExternal[];
};

export type ModeSettingsExternal = {
  mobile: DeviceModeSettingsExternal | null;
  desktop: DeviceModeSettingsExternal | null;
};

export class VisibilitySettingsMapper {
  private static ALL_HIDDEN_FILTERS: UrlFilterExternal[] = [
    {
      type: ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN,
      property_name: '$url',
    },
    {
      type: ELASTICSEARCH_PROPERTY_OPERATIONS.UNKNOWN,
      property_name: '$url',
    },
  ];

  static modeSettingsToExternal(mode: VISIBILITY_MODE, modeSettings: ModeSettings | null): ModeSettingsExternal {
    const localSettingsToExternal = (
      deviceVisibility: VISIBILITY_OPTIONS,
      filters: UrlFilter[],
    ): DeviceModeSettingsExternal | null => {
      switch (deviceVisibility) {
        case VISIBILITY_OPTIONS.ALL_HIDDEN:
          return {
            type: 'and',
            filters: this.ALL_HIDDEN_FILTERS,
          };
        case VISIBILITY_OPTIONS.ALL_VISIBLE:
          return null;
        case VISIBILITY_OPTIONS.ALL_VISIBLE_EXCEPT:
          return {
            type: 'and',
            filters: UrlFilterMapper.filtersToExternal(filters, true),
          };
        case VISIBILITY_OPTIONS.ALL_HIDDEN_EXCEPT:
          return {
            type: 'or',
            filters: UrlFilterMapper.filtersToExternal(filters, false),
          };
      }
    };

    switch (mode) {
      case VISIBILITY_MODE.VISIBLE:
      case VISIBILITY_MODE.HIDDEN:
      case VISIBILITY_MODE.HAS_DIALOGS:
        return {
          desktop: null,
          mobile: null,
        };
      case VISIBILITY_MODE.ADVANCED:
        return {
          desktop: localSettingsToExternal(modeSettings!.desktop, modeSettings!.desktopFilters),
          mobile: localSettingsToExternal(modeSettings!.mobile, modeSettings!.mobileFilters),
        };
    }
  }

  static modeSettingsToLocal(
    mode: VISIBILITY_MODE,
    modeSettingsExternal: ModeSettingsExternal | null,
  ): ModeSettings | null {
    const externalSettingsToLocal = (
      modeSettingsExternal: DeviceModeSettingsExternal | null,
    ): { device: VISIBILITY_OPTIONS; filters: UrlFilter[] } => {
      let device: VISIBILITY_OPTIONS;
      let filters: UrlFilter[] | null;

      // Выбрана опция "Показывать на всех страницах"
      if (modeSettingsExternal === null) {
        device = VISIBILITY_OPTIONS.ALL_VISIBLE;
        filters = [];
        // Выбрана опция "Скрывать на всех страницах" или "Показывать на всех страницах, кроме"
      } else if (modeSettingsExternal.type === 'and') {
        // Тут проверяем, что фильтры от ALL_HIDDEN опции находятся в filters
        const isHiddenForDevice =
          modeSettingsExternal.filters.length === 2 &&
          this.ALL_HIDDEN_FILTERS.every((filter) => {
            return modeSettingsExternal.filters.some((v) => isEqual(v, filter));
          });
        // Если находятся, то это ALL_HIDDEN
        if (isHiddenForDevice) {
          device = VISIBILITY_OPTIONS.ALL_HIDDEN;
          filters = [];
          // Иначе ALL_VISIBLE_EXCEPT
        } else {
          device = VISIBILITY_OPTIONS.ALL_VISIBLE_EXCEPT;
          filters = UrlFilterMapper.filtersToLocal(modeSettingsExternal.filters);
        }
        // Выбрана опция "Скрывать на всех страницах, кроме"
      } else {
        device = VISIBILITY_OPTIONS.ALL_HIDDEN_EXCEPT;
        filters = UrlFilterMapper.filtersToLocal(modeSettingsExternal.filters);
      }

      return { device, filters };
    };

    switch (mode) {
      case VISIBILITY_MODE.VISIBLE:
      case VISIBILITY_MODE.HIDDEN:
      case VISIBILITY_MODE.HAS_DIALOGS:
        return null;
      case VISIBILITY_MODE.ADVANCED:
        let desktop: VISIBILITY_OPTIONS;
        let desktopFilters: UrlFilter[];
        let mobile: VISIBILITY_OPTIONS;
        let mobileFilters: UrlFilter[];

        if (modeSettingsExternal !== null) {
          const { desktop: desktopExternal, mobile: mobileExternal } = modeSettingsExternal;
          const desktopLocal = externalSettingsToLocal(desktopExternal);
          desktop = desktopLocal.device;
          desktopFilters = desktopLocal.filters;
          const mobileLocal = externalSettingsToLocal(mobileExternal);
          mobile = mobileLocal.device;
          mobileFilters = mobileLocal.filters;
        } else {
          desktop = VISIBILITY_OPTIONS.ALL_VISIBLE;
          desktopFilters = [];
          mobile = VISIBILITY_OPTIONS.ALL_VISIBLE;
          mobileFilters = [];
        }

        return {
          desktop,
          desktopFilters,
          mobile,
          mobileFilters,
        };
    }
  }
}

/**
 * Контроллер для статистики чат-ботов сообщений
 */
import { CHAT_BOT_TYPE } from '../../../../../app/http/chat-bot/types/chat-bot-external.types';

(function () {
  'use strict';

  angular.module('myApp.chatBot').controller('CqStatisticsWrapperController', CqStatisticsWrapperController);

  function CqStatisticsWrapperController() {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.botLink = getEditBotLink();
    }

    /**
     * Получение ссылки до редактора бота
     * @returns {string}
     */
    function getEditBotLink() {
      if (vm.botType === CHAT_BOT_TYPE.LEAD) {
        return 'app.content.messagesAjs.chatBot.edit({chatBotId: vm.botEditId})';
      }
      if (vm.botType === CHAT_BOT_TYPE.ROUTING) {
        return 'app.content.messagesAjs.routingBot.edit({routingBotId: vm.botEditId})';
      }
      if (vm.botType === CHAT_BOT_TYPE.TELEGRAM) {
        return 'app.content.messagesAjs.telegramBot.edit({telegramBotId: vm.botEditId})';
      }
    }
  }
})();

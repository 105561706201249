<label>
  <input
    type="radio"
    [attr.name]="name"
    [value]="value"
    [(ngModel)]="modelValue"
    (ngModelChange)="onModelChange($event)"
  />
  <ng-content></ng-content>
</label>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { App } from '@http/app/app.model';

@Component({
  selector: 'cq-message-preview-sdk-popup-chat',
  templateUrl: './message-preview-sdk-popup-chat.component.html',
  styleUrls: ['./message-preview-sdk-popup-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagePreviewSdkPopupChatComponent {
  @Input({ required: true })
  app!: App;

  @Input({ required: true })
  content!: string;

  @Input({ required: true })
  language!: string;

  @Input({ required: true })
  sender!: object;

  constructor() {}
}

(function () {
  'use strict';

  angular.module('myApp.serviceSection').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.service', {
        url: '/service',
        redirectTo: 'app.content.service.visualComponents',
        template:
          '\
          <section id="content">\
            <div class="container-fluid" ui-view=""></div>\
          </section>',
      })

      .state('app.content.service.visualComponents', {
        url: '/visual-components',
        templateUrl: 'js/components/service-section/visual-components/visual-components.html',
        controller: 'VisualComponentsController',
        controllerAs: 'vm',
      });
  }
})();

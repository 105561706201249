(function () {
  'use strict';

  angular
    .module('myApp.serviceInstallationForDeveloper')
    .controller('CqServiceInstallationForDeveloperController', CqServiceInstallationForDeveloperController);

  function CqServiceInstallationForDeveloperController($stateParams, PROJECT_NAME) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.$stateParams = $stateParams;
      vm.PROJECT_NAME = PROJECT_NAME;
    }
  }
})();

(function () {
  'use strict';

  angular.module('myApp.maintenance').config(config);

  function config($stateProvider) {
    $stateProvider.state('app.content.maintenance', {
      url: '/maintenance',
      component: 'cqMaintenance',
    });
  }
})();

<ng-container *transloco="let t; read: 'amocrmLeadTagsComponent'">
  <div class="form-control-container mb-15">
    <label>{{ t('label') }}</label>

    <div class="mb-10 text-secondary">{{t('description', {integrationTypeName})}}</div>

    <ng-select
      #leadsTagsSelect
      class="mb-10"
      [items]="leadsTags"
      [formControl]="controlLeadTags"
      [hideSelected]="true"
      [clearable]="false"
      [multiple]="true"
      [placeholder]="t('select.placeholder', {integrationTypeName})"
      (add)="onAddItem($event)"
    >
      <ng-template
        let-leadTag="item"
        ng-label-tmp
      >
        <cq-tag-default
          [canRemove]="true"
          [text]="getLeadTagText(leadTag)"
          [ngbTooltip]="defaultLeadTagTooltip"
          #leadTagTooltip="ngbTooltip"
          container="body"
          triggers="manual"
          placement="bottom"
          [disableTooltip]="!isDefaultLeadTag(leadTag)"
          (remove)="onRemoveTag(leadTag)"
        ></cq-tag-default>
      </ng-template>

      <ng-template ng-footer-tmp>
        <div class="flex flex-row justify-center align-items-center">
          <button
            class="btn btn-borderless-primary"
            (click)="openCreateLeadTagModal()"
          >
            <i class="btn-icon-left cqi-sm cqi-plus"></i>
            <span>{{t('select.addTag', {integrationTypeName})}}</span>
          </button>
        </div>
      </ng-template>
    </ng-select>
  </div>

  <ng-template #defaultLeadTagTooltip>
    {{ getDefaultLeadTagTooltipText() }}
  </ng-template>
</ng-container>

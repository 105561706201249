import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { tuiPure } from '@taiga-ui/cdk';

import { CHAT_BOT_TYPE_TOKEN } from '@panel/app/pages/chat-bot/content/tokens';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { QuillEditorFormat } from '@panel/app/partials/quill-text-editor/quill-text-editor.component';
import { DestroyService } from '@panel/app/services';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';

@Component({
  selector: 'cq-branch-action-text[actionForm]',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class BranchActionTextComponent {
  get actionForm(): BaseBotActionForm {
    return this._actionForm;
  }

  @Input()
  set actionForm(value: BaseBotActionForm) {
    this._actionForm = value;
    this._actionForm.controls.body.addValidators(this.getBodyValidator(this.chatBotType));
  }
  private _actionForm!: BaseBotActionForm; // Форма с действием

  TEXT_MAX_LENGTH?: number;

  constructor(
    @Inject(CHAT_BOT_TYPE_TOKEN)
    private readonly chatBotType: CHAT_BOT_TYPE,
  ) {}

  /** Разрешенные форматы  */
  @tuiPure
  get availableTextFormats(): QuillEditorFormat[] {
    if (
      this.chatBotType === CHAT_BOT_TYPE.LEAD ||
      this.chatBotType === CHAT_BOT_TYPE.ROUTING ||
      this.chatBotType === CHAT_BOT_TYPE.WIDGET
    ) {
      return ['bold', 'italic', 'strike', 'underline', 'link', 'list'];
    }
    if (this.chatBotType === CHAT_BOT_TYPE.TELEGRAM) {
      return ['bold', 'italic', 'strike', 'underline', 'link'];
    }
    return [];
  }

  /** Ограничение максимальной длинны символов в зависимости от типа бота */
  getBodyValidator(type: CHAT_BOT_TYPE) {
    const TEXT_MAX_LENGTH_OF_TYPE = {
      [CHAT_BOT_TYPE.FACEBOOK]: 2000,
      [CHAT_BOT_TYPE.TELEGRAM]: 4096,
      [CHAT_BOT_TYPE.LEAD]: Infinity,
      [CHAT_BOT_TYPE.ROUTING]: Infinity,
      [CHAT_BOT_TYPE.WIDGET]: Infinity,
    };
    this.TEXT_MAX_LENGTH = TEXT_MAX_LENGTH_OF_TYPE[type];
    // При валидации длины учитывается тег <p></p>, поэтому + 7
    return Validators.maxLength(this.TEXT_MAX_LENGTH + 7);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CarrotMessengerCollapsedFrameComponent } from './carrot-messenger-collapsed-frame.component';

@NgModule({
  declarations: [CarrotMessengerCollapsedFrameComponent],
  exports: [CarrotMessengerCollapsedFrameComponent],
  imports: [CommonModule],
})
export class CarrotMessengerCollapsedFrameModule {}

<ng-container *transloco="let t">
  <ng-select
    class="custom"
    #ngSelect
    (change)="onSelectEventType($event)"
    (close)="onOpenChange(false)"
    (open)="onOpenChange(true)"
    [bindValue]="bindValue"
    [clearable]="false"
    [formControl]="control"
    [items]="eventTypes | filterAndSortEventTypes: accessToEventsEventTypesCustom.hasAccess"
    [multiple]="multiple"
    [placeholder]="placeholderText"
    [required]="required"
    [searchFn]="searchEventTypeFn.bind(this)"
    [closeOnSelect]="closeOnSelect"
    bindLabel="prettyName"
    data-test="event-type-select"
    groupBy="group"
  >
    <ng-container *ngIf="showHeader">
      <ng-container *ng-header-tmp>
        <small class="text-muted mb-10 d-inline-block">
          <i
            class="cqi-sm"
            [ngClass]="$any(EVENT_TYPE_GROUPS_ICONS)[EVENT_TYPE_GROUP.POPULAR]"
          ></i>
          <span>{{ t('models.eventType.groups.' + EVENT_TYPE_GROUP.POPULAR) }}</span>
        </small>
        <div class="mb-5">
          <div
            class="badge badge-pill badge-light-secondary cursor-pointer"
            (click)="onClickSessionStart()"
            *ngIf="isSessionStartBadgeVisible()"
            data-test="session-start-badge"
            transloco="models.eventType.systemEventTypes.$session_start"
          ></div>
          <div
            class="badge badge-pill badge-light-secondary cursor-pointer"
            (click)="onClickUrlEventType()"
            *ngIf="accessToEventsEventTypesCustom.hasAccess"
            data-test="url-event-type-badge"
          >
            {{ openPageText }}
          </div>
          <cq-access-denial-popover
            *ngIf="!accessToEventsEventTypesCustom.hasAccess"
            [currentApp]="currentApp"
            [denialReason]="accessToEventsEventTypesCustom.denialReason"
            [popoverPlacement]="'right'"
            [popoverAutoClose]="'outside'"
            [beforeActionClickFn]="ngSelect.close.bind(ngSelect)"
            data-test="custom-event-activation-badge"
          >
            <div class="badge badge-pill badge-light-secondary cursor-pointer d-inline-flex align-items-center">
              <span>{{ openPageText }}</span>
              <cq-plan-capability-coin class="ml-5"></cq-plan-capability-coin>
            </div>
          </cq-access-denial-popover>
        </div>
      </ng-container>
    </ng-container>

    <ng-template
      let-clear="clear"
      let-items="items"
      ng-multi-label-tmp
    >
      <div
        class="ng-value"
        *ngFor="let event of items"
      >
        <button
          class="btn btn-sm btn-outline-primary d-flex align-items-center overflow-hidden"
          type="button"
        >
          <ng-container *ngIf="event.visible">
            <i
              class="btn-icon btn-icon-left cqi-sm cqi-triangle-exclamation margin-right-5 text-danger"
              *ngIf="!event.active"
              [ngbTooltip]="tooltipText"
              container="body"
              placement="top"
            ></i>
            <span class="text-truncate">{{ $any(event).prettyName }}</span>
            <span
              class="d-flex"
              (click)="clear(event)"
            >
              <i class="cqi-xs cqi-times margin-left-5"></i>
            </span>
          </ng-container>
          <ng-container *ngIf="!event.visible">
            <i class="cqi-sm cqi-triangle-exclamation margin-right-5 text-danger"></i>
            <span class="text-muted">{{ t('general.deletedEvent') }}</span>
          </ng-container>
        </button>
      </div>
    </ng-template>

    <ng-template
      let-eventType="item"
      ng-label-tmp
    >
      <span
        class="text-muted"
        *ngIf="!eventType.visible"
      >
        <i class="cqi-sm cqi-triangle-exclamation margin-right-5 text-danger"></i>
        {{ t('general.deletedEvent') }}
      </span>
      <span
        class="d-flex align-items-center"
        *ngIf="eventType.visible"
      >
        <span class="ng-value-label flex-grow-1 text-truncate">{{ eventType.prettyName }}</span>
        <i
          class="cqi-sm cqi-triangle-exclamation-o margin-left-5 text-danger"
          *ngIf="!eventType.active"
          [ngbTooltip]="tooltipText"
          container="body"
          placement="top"
        ></i>
      </span>
    </ng-template>

    <ng-template
      let-eventType="item"
      ng-option-tmp
    >
      <span
        class="text-muted white-space-normal"
        *ngIf="!eventType.visible"
      >
        <i class="cqi-sm cqi-triangle-exclamation margin-right-5 text-danger"></i>
        {{ t('general.deletedEvent') }}
      </span>
      <span
        class="d-flex align-items-center white-space-normal"
        *ngIf="eventType.visible"
      >
        <span class="ng-value-label">{{ eventType.prettyName }}</span>
        <i
          class="cqi-sm cqi-triangle-exclamation-o margin-left-5 text-danger"
          *ngIf="!eventType.active"
          [ngbTooltip]="tooltipText"
          container="body"
          placement="top"
        ></i>
      </span>
    </ng-template>

    <ng-template
      let-eventType="item"
      ng-optgroup-tmp
    >
      <i
        class="cqi-sm"
        [ngClass]="$any(EVENT_TYPE_GROUPS_ICONS)[eventType.group]"
      ></i>
      {{ t('models.eventType.groups.' + eventType.group) }}
    </ng-template>

    <ng-container *ngIf="showFooter">
      <ng-container *ng-footer-tmp>
        <ng-container *ngIf="accessToEventsEventTypesCustom.hasAccess; else activationFooterButton">
          <button
            type="button"
            class="btn btn-secondary btn-block"
            (click)="openCreateEventModal()"
            data-test="create-event-type-button"
          >
            <i class="btn-icon cqi-sm cqi-plus"></i>
            {{ t('eventTypeSelectComponent.createNewEventButton') }}
          </button>
        </ng-container>
        <ng-template #activationFooterButton>
          <cq-access-denial-popover
            class="full-width"
            *ngIf="!accessToEventsEventTypesCustom.hasAccess"
            [currentApp]="currentApp"
            [denialReason]="accessToEventsEventTypesCustom.denialReason"
            [popoverAutoClose]="'outside'"
            [beforeActionClickFn]="ngSelect.close.bind(ngSelect)"
            data-test="custom-event-activation-button"
          >
            <button
              type="button"
              class="btn btn-secondary btn-block"
            >
              <i class="cqi-sm cqi-plus"></i>
              <span>{{ t('eventTypeSelectComponent.createNewEventButton') }}</span>
              <cq-plan-capability-coin class="ml-5"></cq-plan-capability-coin>
            </button>
          </cq-access-denial-popover>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-select>
</ng-container>

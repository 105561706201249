(function () {
  'use strict';

  angular.module('myApp.manualMessages', []);

  require('./manual-messages.routes');
  require('./statistics/manual-message-statistics.constants');
  require('./statistics/manual-message-statistics.component');
  require('./table/manual-messages-table.component');
})();

<section id="content">
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-2 p-25 border-right margin-between-rows-10">
        <div>
          <h3>Guide</h3>
          <div class="d-flex flex-column align-items-start margin-between-rows-5">
            <button
              class="btn btn-text-primary"
              type="button"
              (click)="goToState('getStarted')"
            >
              Get started
            </button>
          </div>
        </div>

        <div>
          <h3>Components</h3>
          <div class="d-flex flex-column align-items-start margin-between-rows-5">
            <button
              class="btn btn-text-primary"
              type="button"
              (click)="goToState('datepicker')"
            >
              Datepicker
            </button>

            <button
              class="btn btn-text-primary"
              type="button"
              (click)="goToState('daterangepicker')"
            >
              Daterangepicker
            </button>

            <button
              class="btn btn-text-primary"
              type="button"
              (click)="goToState('timepicker')"
            >
              Timepicker
            </button>
          </div>
        </div>
      </div>
      <div class="col-10 p-25">
        <ui-view></ui-view>
      </div>
    </div>
  </div>
</section>

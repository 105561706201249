<ng-container *transloco="let t; read: 'triggerChainActionsComponent'">
  <div class="margin-between-cols-10">
    <ng-container *ngFor="let button of getOrderedButtons()">
      <ng-container [ngSwitch]="button">
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.ACTIVATE_ACCESS_ACCENT">
          <!--Активировать-->
          <button
            class="btn btn-primary"
            type="button"
            (click)="onChangeStatusButtonClick()"
          >
            <i class="btn-icon-left cqi-sm cqi-play"></i>
            <span>{{ t('activate') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.ACTIVATE_NOT_ACCESS_ACCENT">
          <!--Активировать. С ограничениями-->
          <button
            class="btn btn-primary"
            type="button"
            (click)="clickOnShowPaywall.next(); trackClickOnPaywallButton()"
          >
            <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
            <span>{{ t('activate') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.COPY_ACCESS_NOT_ACCENT">
          <!--Сделать копию-->
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="onClickCopyButton()"
          >
            <i class="btn-icon-left cqi-sm cqi-copy"></i>
            <span>{{ t('copy') }}</span>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.PAUSE_AND_SAVE_NOT_ACCESS_NOT_ACCENT">
          <!--Приостановить и сохранить-->
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="onClickStopAndSave()"
          >
            <i class="btn-icon-left cqi-sm cqi-pause"></i>
            <span>{{ t('pauseAndSafe') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.PAUSE_ACCESS_NOT_ACCENT">
          <!--Приостановить-->
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="onChangeStatusButtonClick()"
          >
            <i class="btn-icon-left cqi-sm cqi-pause"></i>
            <span>{{ t('pause') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.RUN_ACCESS_ACCENT">
          <!--Запустить-->
          <button
            class="btn btn-primary"
            type="button"
            (click)="onClickRunButton()"
          >
            <i class="btn-icon-left cqi-sm cqi-play"></i>
            <span>{{ t('run') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.RUN_NOT_ACCESS_NOT_ACCENT">
          <!--Запустить-->
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="clickOnShowPaywall.next()"
          >
            <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
            <span>{{ t('run') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.SAVE_ACCESS_ACCENT">
          <!--Сохранить-->
          <button
            class="btn btn-primary"
            type="button"
            (click)="onClickSaveButton()"
          >
            <i class="btn-icon-left cqi-sm cqi-save"></i>
            <span>{{ t('save') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.SAVE_ACCESS_NOT_ACCENT">
          <!--Сохранить-->
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="onClickSaveButton()"
          >
            <i class="btn-icon-left cqi-sm cqi-save"></i>
            <span>{{ t('save') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.SAVE_NOT_ACCESS_ACCENT">
          <!--Сохранить. С ограничениями-->
          <button
            class="btn btn-primary"
            type="button"
            (click)="clickOnShowPaywall.next()"
          >
            <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
            <span>{{ t('save') }}</span>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TRIGGER_CHAIN_ACTION_BUTTON.SAVE_NOT_ACCESS_NOT_ACCENT">
          <!--Сохранить. С ограничениями-->
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="clickOnShowPaywall.next()"
          >
            <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
            <span>{{ t('save') }}</span>
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

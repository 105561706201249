(function () {
  'use strict';

  require('./auto-message-statistics.controller');
  require('./tabs/ab-test.html');
  require('./tabs/general.html');
  require('./tabs/sendings.html');

  angular.module('myApp.autoMessages').component('cqAutoMessageStatistics', cqAutoMessageStatistics());

  function cqAutoMessageStatistics() {
    return {
      bindings: {
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // текущее приложение
        currentMessage: '=', // сообщение
        djangoUser: '=', // текущий пользователь
        pushSettings: '=', // настройки пушей
        telegramIntegrations: '=',
      },
      controller: 'CqAutoMessageStatisticsController',
      controllerAs: 'vm',
      template: require('./auto-message-statistics.html'),
    };
  }
})();

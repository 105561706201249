<div class="flex align-items-center">
  <span class="flex-shrink-0 mr-10 d-none d-xl-inline">{{
    'autoMessages.table.messageTypeSelect.label' | transloco
  }}</span>

  <ng-select
    class="w-100 mr-30"
    [items]="FILTERED_MESSAGE_PART_TYPES_ARRAY"
    [searchable]="false"
    [clearable]="false"
    [(ngModel)]="currentType"
    (change)="onChangeType($event)"
  >
    <ng-template
      ng-label-tmp
      ng-option-tmp
      let-item="item"
    >
      <i
        *ngIf="MESSAGE_PART_TYPE_ICON_CLASSES[item] !== ''"
        class="cqi-sm mr-10"
        [ngClass]="[MESSAGE_PART_TYPE_ICON_CLASSES[item]]"
      ></i>
      <span [ngClass]="{ 'always-visible': item === MESSAGE_PART_TYPES.ALL }">{{
        'models.message.messagePartTypes.' + item | transloco
      }}</span>
    </ng-template>
  </ng-select>
</div>

<ng-container *transloco="let t; read: 'quizInRegistrationModalComponent'">
  <div
    [hidden]="!isStartQuiz"
    id="typeform-container"
    class="m-auto"
  ></div>

  <div
    *ngIf="!isStartQuiz"
    class="start-popup m-auto"
  >
    <div class="modal-body p-30">
      <img
        class="pb-30"
        [src]="'assets/img/' + activeLang + '/modals/quiz-in-registration/quiz-in-registration.svg'"
      />
      <h3 class="mb-15">{{ t('title') }}</h3>
      <h4
        class="text-secondary font-weight-normal"
        [ngClass]="{ 'mb-15': l10nHelper.isRusCountry(), 'mb-0': !l10nHelper.isRusCountry() }"
      >
        {{ t('paragraph-1') }}
      </h4>
      <h4
        *ngIf="l10nHelper.isRusCountry()"
        class="text-secondary font-weight-normal mb-0"
      >
        {{ t('paragraph-2') }}
      </h4>
    </div>
    <div class="modal-footer flex justify-content-center px-30 pb-30 pt-0">
      <button
        class="btn btn-outline-primary mr-10"
        type="button"
        (click)="skipQuiz()"
      >
        {{ t('buttons.skip') }}
      </button>
      <button
        class="btn btn-primary"
        type="button"
        (click)="startQuizInTypeform()"
      >
        {{ t('buttons.pickUp') }}
      </button>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Inject, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Subject } from 'rxjs';

import { environment } from '@environment';
import { MESSAGE_PAGE_TYPES } from '@http/message/message.constants';
import { MESSAGE_PAGE_TYPE_TOKEN } from '@panel/app/partials/message-editor/tokens';
import { MessageEditorTriggerState } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-state.service';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { EventType } from '@http/property/property.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

const CUSTOM_EVENTS_WITH_KB_INSTRUCTION = [
  '$authorized',
  '$cart_added',
  '$cart_viewed',
  '$order_completed',
  '$order_paid',
  '$order_started',
  '$product_viewed',
  '$registered',
];

export type MessageEditorAnotherEventForm = string | null;

@Component({
  selector: 'cq-message-editor-another-event',
  templateUrl: './message-editor-another-event.component.html',
  styleUrls: ['./message-editor-another-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorAnotherEventComponent extends AbsCVAFormControlBasedComponent<MessageEditorAnotherEventForm> {
  readonly control = new FormControl<MessageEditorAnotherEventForm>(null, {
    validators: [Validators.required],
  });

  @Input()
  isLast: boolean = false;

  @Input()
  isOnlyEvent: boolean = false;

  @Output()
  deleteTrigger: Subject<void> = new Subject();

  get projectName() {
    return environment.projectName;
  }

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly transloco: TranslocoService,
    protected readonly messageEditorTriggerState: MessageEditorTriggerState,
    @Inject(MESSAGE_PAGE_TYPE_TOKEN)
    protected readonly messagePageType: MESSAGE_PAGE_TYPES,
  ) {
    super();
  }

  /** Получение статьи по ID типа события */
  getArticleByEventTypeId(eventTypeId: string): string {
    const eventType = this.getEventTypeByEventId(eventTypeId);
    if (!CUSTOM_EVENTS_WITH_KB_INSTRUCTION.includes(eventType.name)) {
      return '';
    }

    return this.transloco.translate('messageEditorAnotherEventComponent.eventTypesArticles.' + eventType.name);
  }

  /** Возвращает событие по ID */
  getEventTypeByEventId(eventTypeId: string): EventType {
    const eventTypes = this.messageEditorTriggerState.eventTypes$.getValue();
    const eventType = eventTypes.find(({ id }) => id === eventTypeId);
    if (!eventType) {
      throw new Error(`Couldn't find that event`);
    }
    return eventType;
  }

  /** Надо ли отображать сообщение о том что это событие не использовалось */
  isUnusedEventMessageVisible(eventTypeId: string | null): boolean {
    // Если eventTypeId отсутствует, то в селекте не выбрано значение.
    //  Показывать сообщение в этом случае не надо.
    if (!eventTypeId) {
      return false;
    }

    const eventType = this.getEventTypeByEventId(eventTypeId);

    // Если есть eventTypeId, но среди типов событий его нет, то значит событие удалено.
    //  Показывать сообщение в этом случае не надо.
    if (!eventType) {
      return false;
    }

    // Если есть eventTypeId, но событие удалено и не успело ни разу выполниться, то не показываем сообщение
    if (!eventType.visible && !eventType.active) {
      return false;
    }

    return !eventType.active;
  }

  /** Трек клика удаления триггера */
  trackClickRemoveTrigger() {
    this.carrotquestHelper.track(
      this.transloco.translate(this.messagePageType + '.breadcrumbs.title') + ' - триггер - удалил событие из тригера',
    );
  }
}

(function () {
  'use strict';

  require('./popup-image-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupImageBlockSettings', cqPopupImageBlockSettings());

  function cqPopupImageBlockSettings() {
    return {
      bindings: {
        popupBlockParams: '=',
      },
      controller: 'CqPopupImageBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-image-block-settings.html'),
    };
  }
})();

/**
 * Контроллер блока с кодом
 */
(function () {
  'use strict';

  angular.module('myApp.serviceInstallation').controller('CqScriptInsertionController', CqScriptInsertionController);

  function CqScriptInsertionController(
    $translate,
    $timeout,
    $uibModal,
    toastr,
    API_OBJECT_NAME,
    CDN_ENDPOINT,
    INTEGRATION_TYPES,
    PROJECT_NAME,
    SERVICE_INSTALLATION_METHODS,
    SERVICE_INSTALLATION_PLATFORMS,
    appModel,
    carrotquestHelper,
    l10nHelper,
    serviceScriptService,
    validationHelper,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.API_OBJECT_NAME = API_OBJECT_NAME;
      vm.appModel = appModel;
      vm.CDN_ENDPOINT = CDN_ENDPOINT;
      vm.inputsForDeveloperCollapsed = true;
      vm.INSERTION_TYPES = {
        MANUAL: 'manual',
        GTM: 'gtm',
        WORDPRESS: 'cmsPlatform.wordpress',
      };
      vm.INTEGRATION_TYPES = INTEGRATION_TYPES;
      vm.openInsertScriptInstructionModal = openInsertScriptInstructionModal;
      vm.openSendScriptInstructionsModal = openSendScriptInstructionsModal;
      vm.platforms = getPlatforms();
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.selectedInstallationVariant = ''; // Выбранный вариант установки скрипта
      vm.selectedPlatform = vm.platforms[0]; // Выбранная платформа
      vm.selectInstallationVariant = selectInstallationVariant;
      vm.serviceScript = serviceScriptService.getScript(vm.apiKey);
      vm.SERVICE_INSTALLATION_METHODS = SERVICE_INSTALLATION_METHODS;
      vm.tutorialEmail = ''; // email для отправки кода
      vm.validationHelper = validationHelper;
      vm.trackApiKeyCopied = trackApiKeyCopied;
      vm.trackClickCopyScript = trackClickCopyScript;
      vm.trackClickGtmCabinet = trackClickGtmCabinet;
      vm.trackClickGtmManual = trackClickGtmManual;
      vm.trackClickInstructions = trackClickInstructions;
    }

    /** Список платформ, на которые можно установить наш сервис */
    function getPlatforms() {
      const platforms = [];

      if (!l10nHelper.isUsCountry()) {
        platforms.push.apply(platforms, [
          {
            type: INTEGRATION_TYPES.BITRIX1C,
            name: $translate.instant(`models.integration.types.${INTEGRATION_TYPES.BITRIX1C}.name`),
            steps: 3,
            description: true,
          },
          {
            type: INTEGRATION_TYPES.OPENCART,
            name: $translate.instant(`models.integration.types.${INTEGRATION_TYPES.OPENCART}.name`),
            steps: 3,
            description: true,
          },
          {
            type: INTEGRATION_TYPES.WORDPRESS,
            name: $translate.instant(`models.integration.types.${INTEGRATION_TYPES.WORDPRESS}.name`),
            steps: 3,
            description: true,
          },
          {
            type: INTEGRATION_TYPES.INSALES,
            name: $translate.instant(`models.integration.types.${INTEGRATION_TYPES.INSALES}.name`),
            steps: 3,
            description: true,
          },
          {
            type: SERVICE_INSTALLATION_PLATFORMS.TILDA,
            copyScriptButton: true,
            name: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.TILDA}.name`),
            steps: 4,
            link: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.TILDA}.link`),
          },
          {
            type: SERVICE_INSTALLATION_PLATFORMS.MOTTOR,
            copyScriptButton: true,
            name: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.MOTTOR}.name`),
            steps: 4,
            link: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.MOTTOR}.link`),
          },
          {
            type: SERVICE_INSTALLATION_PLATFORMS.UCOZ,
            copyScriptButton: true,
            name: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.UCOZ}.name`),
            steps: 3,
            link: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.UCOZ}.link`),
          },
          {
            type: SERVICE_INSTALLATION_PLATFORMS.LPGENERATOR,
            copyScriptButton: true,
            name: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.LPGENERATOR}.name`),
            steps: 3,
            link: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.LPGENERATOR}.link`),
          },
          {
            type: SERVICE_INSTALLATION_PLATFORMS.PLATFORMA_LP,
            copyScriptButton: true,
            name: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.PLATFORMA_LP}.name`),
            steps: 3,
            link: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.PLATFORMA_LP}.link`),
          },
        ]);
      } else {
        platforms.push.apply(platforms, [
          {
            type: INTEGRATION_TYPES.WORDPRESS,
            name: $translate.instant(`scriptInsertion.cmsPlatform.${INTEGRATION_TYPES.WORDPRESS}.name`),
            steps: 3,
            description: true,
          },
          {
            type: INTEGRATION_TYPES.PRESTASHOP,
            name: $translate.instant(`models.integration.types.${INTEGRATION_TYPES.PRESTASHOP}.name`),
            steps: 4,
            description: true,
          },
          {
            type: INTEGRATION_TYPES.SHOPIFY,
            name: $translate.instant(`models.integration.types.${INTEGRATION_TYPES.SHOPIFY}.name`),
            steps: 3,
            description: true,
            copyScriptButton: true,
            link: $translate.instant(`scriptInsertion.cmsPlatform.${SERVICE_INSTALLATION_PLATFORMS.SHOPIFY}.link`),
          },
        ]);
      }

      return platforms;
    }

    /**
     * Открытие модального окна с видео о том, как установить код за 30 секунд
     */
    function openInsertScriptInstructionModal(insertionType) {
      $uibModal.open({
        controller: 'VideoModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/video/video.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant(`scriptInsertion.${insertionType}.instructionModal.heading`, {
                projectName: PROJECT_NAME,
              }),
              videoUrl: $translate.instant(`scriptInsertion.${insertionType}.instructionModal.videoUrl`),
            };
          },
        },
      });
      trackClickInsertScriptInstructionModal();
    }

    /**
     * Открытие модального окна отправки инструкции по установке скрипта
     */
    function openSendScriptInstructionsModal() {
      $uibModal.open({
        component: 'cqSendScriptInstructionsWrapperModal',
        resolve: {
          modalWindowParams: function () {
            return {
              apiKey: vm.apiKey,
              appId: vm.appId,
              appName: vm.appName,
              appOrigin: vm.appOrigin,
            };
          },
        },
        size: 'md modal-dialog-centered',
      });
    }

    /**
     * Выбор способа установки скрипта
     *
     * @param {SERVICE_INSTALLATION_METHODS} installationVariant
     */
    function selectInstallationVariant(installationVariant) {
      vm.selectedInstallationVariant = installationVariant;
    }

    /**
     * Трек клика по кнопке копирования ID сайта в инструкции с GTM
     */
    function trackApiKeyCopied() {
      carrotquestHelper.track('Установка сервиса - GTM - кликнул на "Скопировать ID сайта"', { app: vm.appName });
    }

    /**
     * Трек клика на "Скопировать код"
     */
    function trackClickCopyScript() {
      carrotquestHelper.track('Установка сервиса - кликнул на "Скопировать код"', { app: vm.appName });
    }

    /** Трек клика по ссылке на личный кабинет GTM */
    function trackClickGtmCabinet() {
      carrotquestHelper.track('Установка сервиса - GTM - перешел в аккаунт GTM', { app: vm.appName });
    }

    /**
     * Трек клика по ссылке на инструкцию в базе знаний с GTM
     */
    function trackClickGtmManual() {
      carrotquestHelper.track('Установка сервиса - GTM - перешел в БЗ на инструкцию', { app: vm.appName });
    }

    /**
     * Трек клика на "Подробная инструкция по установке сервиса"
     */
    function trackClickInstructions() {
      carrotquestHelper.track('Установка сервиса - кликнул на "Подробная инструкция по установке сервиса"', {
        app: vm.appName,
      });
    }

    /**
     * Трек клика на открытие модалок с инструкциями
     */
    function trackClickInsertScriptInstructionModal() {
      carrotquestHelper.track('Установка кода - инструкция просмотрена', { app_id: vm.appId });
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { App } from '@http/app/app.model';
import { TriggerChain, TriggerChainMessageStep } from '@http/trigger-chain/internal-types';
import { TriggerChainGeneralStatisticsStateService } from '@panel/app/pages/trigger-chains/general-statistics/trigger-chain-general-statistics-state.service';

@Component({
  selector: 'cq-general-statistics',
  templateUrl: './trigger-chain-general-statistics.component.html',
  styleUrls: ['./trigger-chain-general-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TriggerChainGeneralStatisticsStateService],
})
export class TriggerChainGeneralStatisticsComponent implements OnInit {
  /** Приложение */
  @Input({ required: true })
  app!: App;

  /** Цепочка */
  @Input({ required: true })
  chain!: TriggerChain;

  /** Сообщения из триггерной цепочки */
  @Input({ required: true })
  chainStepList!: TriggerChainMessageStep[];

  constructor(private readonly triggerChainGeneralStatisticsStateService: TriggerChainGeneralStatisticsStateService) {}

  ngOnInit() {
    this.triggerChainGeneralStatisticsStateService.chain$.next(this.chain);
    this.triggerChainGeneralStatisticsStateService.chainStepList$.next(this.chainStepList);
  }
}

<ng-container *transloco="let t; read: 'alreadyActiveTelegramBotModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t(actionType + '.heading', {integrationName}) }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div [innerHTML]="t(actionType + '.body', {appName, botCount, integrationName, telegramBotListUrl})"></div>
    <form
      *ngIf="actionType === 'remove'"
      class="mt-10"
      autocomplete="off"
      novalidate
      (ngSubmit)="confirmRemove()"
    >
      <div
        class="form-group no-margin"
        [cqShowError]="confirmNameControl"
      >
        <input
          class="form-control"
          required
          type="text"
          [formControl]="confirmNameControl"
        />
      </div>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="activeModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <button
      class="btn btn-primary"
      (click)="onClickConfirmModal()"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</ng-container>

(function () {
  'use strict';

  angular
    .module('myApp.modals.changeRoutingBotActiveStatusConfirmWrapper')
    .component(
      'cqChangeRoutingBotActiveStatusConfirmWrapperModal',
      cqChangeRoutingBotActiveStatusConfirmWrapperModal(),
    );

  function cqChangeRoutingBotActiveStatusConfirmWrapperModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=', // В resolve должны передаваться следующие параметры:
        // statusToChange - На какой статус будет меняться роутинг-бот
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./change-routing-bot-active-status-confirm.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'amocrmDataFromLeadsComponent'">
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="amoCrmDataCollapse.toggle()"
    [attr.aria-expanded]="!isAmoCrmDataCollapsed"
    aria-controls="collapse"
  >
    <h4 class="no-margin">
      {{t('heading', {
      projectName,
      integrationTypeName
    })}}
    </h4>
    <div class="ml-auto">{{ countBunch }}</div>
    <i
      class="cqi-sm ml-10"
      [ngClass]="{ 'cqi-chevron-up': !isAmoCrmDataCollapsed, 'cqi-chevron-down': isAmoCrmDataCollapsed }"
    ></i>
  </div>

  <div
    #amoCrmDataCollapse="ngbCollapse"
    [(ngbCollapse)]="isAmoCrmDataCollapsed"
  >
    <div class="pt-20">
      <!-- СТАТУСЫ СДЕЛКИ, ПОСЛЕ ОБНОВЛЕНИЯ КОТОРЫХ В ЛИДА ЗАПИШУТСЯ СОБЫТИЯ -->
      <div class="mb-20">
        {{t('pipelineToEvent.description', {
        projectName,
        integrationTypeName
      })}}
      </div>

      <div
        *ngIf="leadsEventsMappingControl.controls.length"
        class="d-flex align-items-center"
      >
        <p class="flex-grow no-flex-basis font-weight-bolder">
          {{t('pipelineToEvent.pipeline.title', {integrationTypeName})}}
        </p>

        <div class="mx-10 invisible">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>

        <p class="flex-grow no-flex-basis font-weight-bolder">{{t('pipelineToEvent.event.title', {projectName})}}</p>

        <button
          class="btn btn-outline-primary ml-10 invisible"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-ellipsis-v"></i>
        </button>
      </div>

      <div
        class="mb-15"
        *ngFor="let formGroup of leadsEventsMappingControl.controls; index as index"
      >
        <div class="d-flex align-items-center">
          <div
            class="flex-grow min-width-0 no-flex-basis"
            [cqShowError]="formGroup.controls.event.controls.key"
          >
            <ng-select
              [clearable]="false"
              [formControl]="formGroup.controls.event.controls.key"
              [items]="pipelines"
              [placeholder]="t('pipelineToEvent.pipeline.selectStatus.placeholder', {integrationTypeName})"
              [required]="true"
              [searchable]="true"
              [searchFn]="findPipelineByLabel.bind(this)"
              bindLabel="name"
              bindValue="additionalId"
              groupBy="name"
            >
              <ng-template
                let-pipeline="item"
                ng-label-tmp
              >
                <span *ngIf="isRemovedPipeline(pipeline)">
                  <i
                    class="cqi-sm cqi-triangle-exclamation position-relative text-danger margin-right-5"
                    [ngbTooltip]="tooltipRemovedLeadStatus"
                    container="body"
                  ></i>
                  {{ t('pipelineToEvent.pipeline.selectStatus.removed') }}
                  <ng-template #tooltipRemovedLeadStatus>
                    <div [innerHTML]="t('tooltips.removedLeadStatus')"></div>
                  </ng-template>
                </span>

                <span *ngIf="!isRemovedPipeline(pipeline)">{{ pipeline.name + ': ' + pipeline.statusName }}</span>
              </ng-template>

              <ng-template
                let-group="item"
                ng-optgroup-tmp
              >
                <span>{{ group.name }}</span>
              </ng-template>

              <ng-template
                let-pipeline="item"
                ng-option-tmp
              >
                <span class="text-wrap">{{ getPipelineLabel(pipeline) }}</span>
              </ng-template>
            </ng-select>
            <cq-validation-messages [control]="formGroup.controls.event.controls.key">
              <cq-validation-message errorName="required">
                {{ t('pipelineToEvent.pipeline.selectStatus.errors.required', {integrationTypeName}) }}
              </cq-validation-message>
            </cq-validation-messages>
          </div>

          <div class="d-flex align-items-center mx-10">
            <i class="cqi-sm cqi-arrow-right"></i>
          </div>

          <div
            class="flex-grow min-width-0 no-flex-basis"
            [cqShowError]="formGroup.controls.event.controls.value"
          >
            <cq-event-type-select
              [autoEvents]="(amocrmState.autoEvents$ | async) ?? []"
              (autoEventsChange)="onAutoEventsChange($event)"
              [currentApp]="currentApp"
              [eventTypes]="(amocrmState.eventTypes$ | async) ?? []"
              (eventTypesChange)="onEventTypesChange($event)"
              [formControl]="formGroup.controls.event.controls.value"
              [required]="true"
              [bindValue]="'prettyName'"
              [createTrackMasterEvent]="false"
              [placeholderText]="t('pipelineToEvent.event.selectEvent.placeholder')"
              [showHeader]="false"
            ></cq-event-type-select>
            <cq-validation-messages [control]="formGroup.controls.event.controls.value">
              <cq-validation-message errorName="required">
                {{ t('pipelineToEvent.event.selectEvent.errors.required') }}
              </cq-validation-message>
            </cq-validation-messages>
          </div>

          <div
            class="ml-10"
            ngbDropdown
          >
            <button
              class="btn btn-outline-primary"
              [id]="'actions-dropdown-' + index"
              ngbDropdownToggle
              type="button"
            >
              <i class="btn-icon cqi-sm cqi-ellipsis-v"></i>
            </button>
            <div
              [attr.aria-labelledby]="'actions-dropdown-' + index"
              ngbDropdownMenu
            >
              <button
                (click)="addLeadProperty(formGroup)"
                ngbDropdownItem
                type="button"
              >
                <i class="btn-icon cqi-sm cqi-plus mr-5"></i>
                {{ t('actionsDropdown.addEventPropertyButton') }}
              </button>
              <button
                (click)="removeItem(leadsEventsMappingControl, index)"
                ngbDropdownItem
                type="button"
              >
                <i class="btn-icon cqi-sm cqi-times mr-5"></i>
                {{ t('actionsDropdown.removeLineButton') }}
              </button>
            </div>
          </div>
        </div>

        <div
          class="d-flex align-items-center mt-15"
          *ngFor="
            let eventPropsGroups of formGroup.controls.eventProps.controls;
            first as first;
            index as eventPropIndex
          "
        >
          <div
            class="flex-grow min-width-0 no-flex-basis line-left-top"
            [ngClass]="{ long: !first }"
          >
            <cq-amocrm-fields-select
              [fields]="(amocrmState.leadFields$ | async) ?? []"
              [formControl]="eventPropsGroups.controls.key"
              [integrationId]="integrationId"
              [type]="'lead'"
            ></cq-amocrm-fields-select>
          </div>

          <div class="d-flex align-items-center mx-10 invisible">
            <i class="cqi-sm cqi-arrow-right"></i>
          </div>

          <div
            class="flex-grow min-width-0 no-flex-basis line-left-top"
            [cqShowError]="eventPropsGroups.controls.value"
            [ngClass]="{ long: !first }"
          >
            <input
              class="form-control"
              [formControl]="eventPropsGroups.controls.value"
              [placeholder]="t('pipelineToEvent.event.selectEventProp.placeholder')"
              [required]="true"
              type="text"
            />
            <cq-validation-messages [control]="eventPropsGroups.controls.value">
              <cq-validation-message errorName="required">
                {{ t('pipelineToEvent.event.selectEventProp.errors.required') }}
              </cq-validation-message>
            </cq-validation-messages>
          </div>

          <button
            class="btn btn-borderless-primary ml-10"
            (click)="removeEventPropertyItem(formGroup, eventPropIndex)"
            type="button"
          >
            <i class="btn-icon cqi-sm cqi-times"></i>
          </button>
        </div>
      </div>

      <button
        class="btn btn-borderless-primary mt-5"
        (click)="addProperty()"
        type="button"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        {{t('pipelineToEvent.addPipelineStatusButton', {integrationTypeName})}}
      </button>

      <!-- ПОЛЯ СДЕЛКИ, КОТОРЫЕ ЗАПИШУТСЯ В СВОЙСТВО ЛИДА -->
      <div class="mt-20 mb-20">
        {{t('pipelineToProp.description', {
        projectName,
        integrationTypeName
      })}}
      </div>

      <div
        *ngIf="controlAmocrmLeadsPropsMapping.controls.length"
        class="d-flex align-items-center"
      >
        <p class="flex-grow no-flex-basis font-weight-bolder">
          {{t('pipelineToProp.pipeline.title', {integrationTypeName})}}
        </p>

        <div class="mx-10 invisible">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>

        <p class="flex-grow no-flex-basis font-weight-bolder">{{t('pipelineToProp.prop.title', {projectName})}}</p>

        <button
          class="btn btn-outline-primary ml-10 invisible"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-ellipsis-v"></i>
        </button>
      </div>

      <div
        class="d-flex align-items-center mb-15"
        *ngFor="let formGroup of controlAmocrmLeadsPropsMapping.controls; index as index"
      >
        <div class="flex-grow min-width-0 no-flex-basis">
          <cq-amocrm-fields-select
            [fields]="(amocrmState.leadFields$ | async) ?? []"
            [formControl]="formGroup.controls.key"
            [integrationId]="integrationId"
            [type]="'lead'"
          ></cq-amocrm-fields-select>
        </div>

        <div class="d-flex align-items-center mx-10">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>

        <div
          class="flex-grow min-width-0 no-flex-basis"
          [cqShowError]="formGroup.controls.value"
        >
          <ng-select
            [clearable]="false"
            [formControl]="formGroup.controls.value"
            [items]="customProperties"
            [placeholder]="t('pipelineToProp.prop.select.placeholder')"
            [required]="true"
            bindLabel="prettyName"
            bindValue="name"
            groupBy="group"
          >
            <ng-template
              let-item="item"
              ng-option-tmp
            >
              <span class="text-wrap">{{ item.prettyName }}</span>
            </ng-template>
          </ng-select>
          <cq-validation-messages [control]="formGroup.controls.value">
            <cq-validation-message errorName="required">
              {{ t('pipelineToProp.prop.select.errors.required') }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>

        <button
          class="btn btn-borderless-primary ml-10"
          (click)="removeItem(controlAmocrmLeadsPropsMapping, index)"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </div>

      <button
        class="btn btn-borderless-primary mt-5"
        (click)="addLeadField()"
        type="button"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        {{t('pipelineToProp.addPipelineFieldButton', {integrationTypeName})}}
      </button>
    </div>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'memberActionsComponent'">
  <ng-container *ngIf="membersActions$ | async as membersActions">
    <!-- Сюда нужно перенести шапку после обновления datapicker на A2-->

    <div *ngIf="isDataLoading; else loader">
      <div class="card-body p-0">
        <!-- Таблица -->
        <cq-member-actions-table
          *ngIf="membersActions.length; else zeroData"
          [logs]="membersActions"
        ></cq-member-actions-table>
        <!-- Zero data не найдено действий -->
        <ng-template #zeroData>
          <cq-member-actions-not-found-zero-data></cq-member-actions-not-found-zero-data>
        </ng-template>
      </div>

      <!-- Пагинация -->
      <div
        *ngIf="paginatePosition"
        class="card-footer"
      >
        <button
          [disabled]="!isMoreDataLoading"
          (click)="loadMore$.next()"
          type="button"
          class="btn btn-secondary w-100"
        >
          <span *ngIf="isMoreDataLoading; else loaderButton">{{ t('loadMore') }}</span>
          <ng-template #loaderButton>
            <div class="d-flex position-relative justify-center my-10">
              <cq-loader
                [width]="15"
                [height]="15"
                [background]="false"
              ></cq-loader>
            </div>
          </ng-template>
        </button>
      </div>
    </div>
    <!-- Лоадер -->
    <ng-template #loader>
      <div class="d-flex position-relative justify-center my-50 py-50">
        <cq-loader></cq-loader>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

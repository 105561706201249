<div class="carrot-frame-body feature_start-screen">
  <div class="container svelte-1nwj0fz">
    <div
      class="container svelte-1qe9gqs"
      [style]="{
        '--animation-duration': '5000ms',
        '--animation-url': 'url(' + environment.cdnEndpoint + '/img/ru/collapsed-chat/default/icon-collapsed-chat.png)',
        '--background-image-url':
          'url(' + environment.cdnEndpoint + '/img/ru/collapsed-chat/default/icon-collapsed-chat.png)',
        'background-color': app.settings.messenger_collapsed_color,
        '-webkit-box-shadow':
          '0 2px 8px 0px rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(' +
          getRgbFromHex(app.settings.messenger_collapsed_color) +
          ', 0.2)',
        'box-shadow':
          '0 2px 8px 0px rgba(0, 0, 0, 0.15), 0 4px 6px 0 rgba(' +
          getRgbFromHex(app.settings.messenger_collapsed_color) +
          ', 0.2)'
      }"
    ></div>
    <div class="counter svelte-1nwj0fz">
      <div class="svelte-s2bryu">1</div>
    </div>
  </div>
</div>

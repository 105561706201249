import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { PLAN_CAPABILITIES_RELEASE_DATE } from '@http/plan-capability/plan-capability.constants';
import { PlanCapabilityModel } from '@http/plan-capability/plan-capability.model';
import { PlanIdService } from '@panel/app/services/billing/plan-id/plan-id.service';
import { BILLING_UPDATE_DATE } from '@panel/app/services/billing-plan/billing-plan.constants';
import { App } from '@http/app/app.model';

/**
 * Сервис для работы с версиями тарифов
 */
@Injectable({ providedIn: 'root' })
export class PlanVersionService {
  constructor(private planCapabilityModel: PlanCapabilityModel, private readonly planIdService: PlanIdService) {}

  /** Относится ли тариф приложения к Freemium версиям */
  isFreemium(): boolean {
    return this.planIdService.isFreemium();
  }

  /** Относится ли тариф приложения к LTD версиям */
  isLTD() {
    return this.planIdService.isLTD2023();
  }

  /** Относится ли тариф приложения к Trial версиям */
  isTrial(): boolean {
    return this.planIdService.isTrial();
  }

  /** Относится ли тариф приложения к V0421 версиям */
  isV0421(app: App): boolean {
    return (
      this.planIdService.isV0421() ||
      (app.created.isBetween(PLAN_CAPABILITIES_RELEASE_DATE[environment.country], BILLING_UPDATE_DATE.Y2021_M10_D26) &&
        this.isTrial())
    );
  }

  /** Относится ли тариф приложения к V1021 версиям */
  isV1021(app: App): boolean {
    return (
      this.planIdService.isV1021() ||
      (app.created.isBetween(BILLING_UPDATE_DATE.Y2021_M10_D26, BILLING_UPDATE_DATE.Y2022_M11_D02) && this.isTrial())
    );
  }

  /** Относится ли тариф приложения к V1120 версиям */
  isV1120(): boolean {
    return this.planIdService.isV1120();
  }

  /** Относится ли тариф приложения к V1122 версиям */
  isV1122(app: App): boolean {
    return this.planIdService.isV1122() || (app.created.isAfter(BILLING_UPDATE_DATE.Y2022_M11_D02) && this.isTrial());
  }

  /** Относится ли тариф приложения к COVID-FREE версиям */
  isCovidFree() {
    return this.planIdService.isCovidFree();
  }

  /** Относится ли тариф приложения к версиям первой тарифной сетки */
  isV1(app: App): boolean {
    return !this.planCapabilityModel.isCapabilityMode(app) || this.isCovidFree();
  }

  /** Относится ли тариф приложения к версиям второй тарифной сетки */
  isV2(app: App): boolean {
    // Оставляю так, потому что так удобно дебажить, чтоб сразу видеть где что отдает
    const isV1120 = this.isV1120();
    const isV0421 = this.isV0421(app);
    const isV1021 = this.isV1021(app);
    const isCapMode = this.planCapabilityModel.isCapabilityMode(app);
    const isCovidFree = this.isCovidFree();
    const isV3 = this.isV3(app);
    const isLTD = this.isLTD();

    return isV1120 || isV0421 || isV1021 || (isCapMode && !isV3 && !isLTD && !isCovidFree);
  }

  /** Относится ли тариф приложения к версиям третей тарифной сетки */
  isV3(app: App): boolean {
    return this.isV1122(app) || this.planIdService.isSpecialV1122();
  }
}

/** Максимальная длина комментария */
export const COMMENT_MAX_LENGTH = 500;

/** Статусы подписки на уведомления у получателей */
export enum EMAIL_NOTIFICATION_STATUS {
  NOT_CONFIRMED = 'not_confirmed', // Не подписан на уведомления
  CONFIRMED = 'confirmed', // Подписан на уведомления
  UNSUBSCRIBED = 'unsubscribed', // Отписан от уведомлений
}

/**
 * Предустановленные по умолчанию свойства пользователя, которые отправляются в уведмолении
 *
 * !!! Порядок расположения объектов в массиве важен
 */
export const PREINSTALLED_PROPERTIES = [
  {
    name: '$name',
    removable: false,
  },
  {
    name: '$phone',
    removable: false,
  },
  {
    name: '$email',
    removable: false,
  },
  {
    name: '$country',
    removable: true,
  },
  {
    name: '$region',
    removable: true,
  },
  {
    name: '$city',
    removable: true,
  },
];

/** Максимальная длина темы письма */
export const SUBJECT_MAX_LENGTH = 255;

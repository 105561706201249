import { Inject, Injectable } from '@angular/core';
import { USER_AGENT } from '@ng-web-apis/common';
import drop from 'lodash-es/drop';
import mean from 'lodash-es/mean';
import round from 'lodash-es/round';
import { filter, map, mergeMap, skipWhile, takeUntil } from 'rxjs/operators';

import { ChatBotForm } from '@panel/app/pages/chat-bot/forms/bot.form';
import { DestroyService } from '@panel/app/services';
import { PixiPerformanceLog, PixiStats } from '@panel/app/services/pixi/stats';
import { HttpUtilsService } from '@panel/app/shared/services/utils/http-utils.service';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';

// Именование по особым правилам, https://www.notion.so/carrotquest/9b55ad19959e406db901227a0d18ad35
type SessionLogData = {
  sessionTimeSeconds: number;
  maxFPS: number;
  minFPS: number;
  avgFPS: number; // Среднее от значений ФПС
  percentile5: number; // Значение 5-ого пецентиля
  avgAbovePercentile5: number; // Среднее от значений выше 5-ого перцентиля
  percentile10: number; // Значение 10-ого пецентиля
  avgAbovePercentile10: number; // Среднее от значений выше 10-ого перцентиля
  fpsList: number[];
  botIdStr: string | undefined;
  ua: string;
  blocksMin: number;
  blocksMax: number;
  djangoUserId: string;
  currentAppId: string;
};

@Injectable()
export class PixiStatsLoggerService {
  private sessionStartTime: number | null = null;

  private minFPS: number | null = null;

  private maxFPS: number | null = null;

  private fpsList: number[] = [];

  private blocksMin: number | null = null;

  private blocksMax: number | null = null;

  constructor(
    @Inject(HttpUtilsService) private readonly utilsService: HttpUtilsService,
    @Inject(PixiStats) private readonly pixiStats: PixiStats,
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    @Inject(USER_AGENT) private readonly userAgent: string,
  ) {}

  initLogging<T extends CHAT_BOT_TYPE>(
    currentAppID: string,
    djangoUserID: string,
    chatBot: ChatBotForm<T>,
    getBlocksCount: () => number,
  ) {
    const initTime = Date.now();

    this.pixiStats.log$
      .pipe(
        takeUntil(this.destroy$),
        skipWhile(() => {
          // Чтоб скипнуть пролаги, которые случаются при первой отрисовке
          const currentTime = Date.now();
          if (currentTime - initTime < 5 * 1000) {
            this.sessionStartTime = currentTime;
            return true;
          }
          return false;
        }),
        filter((log) => !log.isResizedDocument),
      )
      .subscribe((log: PixiPerformanceLog) => {
        const fps = Math.round(log.fps);
        this.minFPS = this.minFPS === null ? fps : Math.min(this.minFPS, fps);
        this.maxFPS = this.maxFPS === null ? fps : Math.max(this.maxFPS, fps);
        const blocksCount = getBlocksCount();
        this.blocksMin = this.blocksMin === null ? blocksCount : Math.min(this.blocksMin, blocksCount);
        this.blocksMax = this.blocksMax === null ? blocksCount : Math.max(this.blocksMax, blocksCount);
        this.fpsList.push(fps);
      });

    this.destroy$
      .pipe(
        filter(() => {
          return this.minFPS !== null && this.maxFPS !== null && this.sessionStartTime !== null;
        }),
        map(() => {
          return round(mean(this.fpsList), 2);
        }),
        mergeMap((avgFPS) => {
          const sortedFpsList = [...this.fpsList].sort((a, b) => a - b);

          const calcPercentileRank = (percentile: number, numberOfValues: number) =>
            Math.ceil((percentile / 100) * numberOfValues);
          // Считаем 5 и 10 перцентили и получаем среднее от значений больше перцентиля
          // https://www.thoughtco.com/what-is-a-percentile-3126238
          // https://www.translatorscafe.com/unit-converter/ru-RU/calculator/percentile/
          const percentile5Rank = calcPercentileRank(5, sortedFpsList.length);
          const avgAbovePercentile5 = round(mean(drop(sortedFpsList, percentile5Rank)), 2);
          const percentile10Rank = calcPercentileRank(10, sortedFpsList.length);
          const avgAbovePercentile10 = round(mean(drop(sortedFpsList, percentile10Rank)), 2);

          return this.log({
            sessionTimeSeconds: Math.round((Date.now() - this.sessionStartTime!) / 1000),
            minFPS: this.minFPS!,
            maxFPS: this.maxFPS!,
            avgFPS,
            percentile5: sortedFpsList[percentile5Rank - 1],
            avgAbovePercentile5,
            percentile10: sortedFpsList[percentile10Rank - 1],
            avgAbovePercentile10,
            fpsList: this.fpsList,
            ua: this.userAgent,
            currentAppId: currentAppID,
            botIdStr: chatBot.initialData.id,
            blocksMin: this.blocksMin!,
            blocksMax: this.blocksMax!,
            djangoUserId: djangoUserID,
          });
        }),
      )
      .subscribe();
  }

  log(data: SessionLogData) {
    const PIXI_STATS_MESSAGE_TYPE = 'canvas-bot-performance-v2';

    const logsPayload = {
      messageType: PIXI_STATS_MESSAGE_TYPE,
      message: PIXI_STATS_MESSAGE_TYPE,
      data,
      data_plain: data,
    };

    return this.utilsService.sendLogs(logsPayload);
  }
}

import drop from 'lodash-es/drop';
import { Container, Sprite } from 'pixi.js';

import { AtlasBuilderService, AtlasTexture } from './atlas-builder/atlas-builder.service';

type PlacementParams = {
  /** Расположение элементов по аналогии с flex */
  alignItems: 'start' | 'center' | 'end';
  /** Прозрачность */
  alpha: number;
  /** Направление дочерних блоков по аналогии с flex */
  flexDirection: 'column' | 'row';
  /** Расположение элементов по аналогии с flex */
  justifyContent: 'start' | 'center' | 'end' | 'space-between';
  /** Расстояние между дочерними элементами если они расположены как cols */
  marginBetweenCols: number;
  /** Расстояние между дочерними элементами если они расположены как rows */
  marginBetweenRows: number;
  /** Внутренние отступы */
  padding: {
    x: number;
    y: number;
  };
  /** Высота */
  height: number | 'auto';
};

export type ElementBuilderRectangleConfig = {
  background?: {
    sideTextureName: AtlasTexture;
    innerTextureName: AtlasTexture;
  };
  border?: {
    sideTextureName: AtlasTexture;
    innerTextureName: AtlasTexture;
  };
  placement?: Partial<PlacementParams>;
};

type ElementBuilderCircleConfig = {
  backgroundTextureName: AtlasTexture;
  borderTextureName?: AtlasTexture;
};

export class ElementBuilder {
  static wrapElementsInRectangle(elements: Container[], config: ElementBuilderRectangleConfig): Container {
    const placementConfig = this.getFinalPlacementParams(config.placement);
    const height = this.getHeight(elements, placementConfig);

    const container = new Container();
    if (config.background) {
      const background = this.buildBurgerTexture(
        config.background.sideTextureName,
        config.background.innerTextureName,
        height,
      );
      container.addChild(background);
    }

    if (config.border) {
      const border = this.buildBurgerTexture(config.border.sideTextureName, config.border.innerTextureName, height);
      container.addChild(border);
    }

    if (placementConfig.flexDirection === 'column') {
      this.arrangeVerticalInContainer(container, elements, placementConfig);
    } else {
      this.arrangeHorizontalInContainer(container, elements, placementConfig);
    }

    return container;
  }

  static wrapElementInCircle(element: Container, config: ElementBuilderCircleConfig) {
    const container = new Container();

    const background = new Sprite(AtlasBuilderService.getTexture(config.backgroundTextureName));
    container.addChild(background);

    if (config.borderTextureName) {
      const border = new Sprite(AtlasBuilderService.getTexture(config.borderTextureName));
      container.addChild(border);
    }

    container.addChild(element);
    const backgroundsCenterX = (background.width - element.width) / 2;
    const backgroundsCenterY = (background.height - element.height) / 2;

    element.position.set(backgroundsCenterX, backgroundsCenterY);

    return container;
  }

  static buildBurgerTexture(sideTextureName: AtlasTexture, innerTextureName: AtlasTexture, height: number): Container {
    const sideTexture = AtlasBuilderService.getTexture(sideTextureName);
    const topSprite = new Sprite(sideTexture);
    const bottomSprite = new Sprite(sideTexture);
    bottomSprite.pivot.set(bottomSprite.width, bottomSprite.height / 2);
    bottomSprite.angle = 180;

    const innerSprites: Sprite[] = [];

    const innerHeight = height - sideTexture.height * 2;

    const innerTexture = AtlasBuilderService.getTexture(innerTextureName);

    const numberOfFullTextures = Math.floor(innerHeight / innerTexture.height);

    for (let i = 0; i < numberOfFullTextures; i++) {
      const innerSprite = new Sprite(innerTexture);
      innerSprites.push(innerSprite);
    }

    const remainder = innerHeight % innerTexture.height;
    if (remainder > 0) {
      const heightMultiplier = remainder / innerTexture.height;
      const remainderTexture = AtlasBuilderService.getTexture(innerTextureName, innerTexture.height * heightMultiplier);
      innerSprites.push(new Sprite(remainderTexture));
    }

    const container = new Container();

    container.addChild(topSprite);

    let lastY = topSprite.height;
    innerSprites.forEach((sprite) => {
      container.addChild(sprite);
      sprite.position.y = lastY;
      lastY = lastY + sprite.height;
    });
    bottomSprite.position.y = lastY + bottomSprite.height / 2;
    container.addChild(bottomSprite);

    return container;
  }

  /**
   * Получает высоту контейнера относительно внутренних элементов
   */
  private static getHeight(elements: Container[], placementParams: PlacementParams): number {
    let height: number;

    if (placementParams.flexDirection === 'row') {
      height = calcHeightForRow();
    } else {
      height = calcHeightForColumn();
    }

    if (placementParams.padding.y) {
      height += placementParams.padding.y * 2;
    }

    return height;

    function calcHeightForRow() {
      return placementParams.height === 'auto'
        ? Math.max(...elements.map((element: Container) => element.height))
        : placementParams.height;
    }

    function calcHeightForColumn() {
      let baseHeight =
        placementParams.height === 'auto'
          ? elements.map((e) => e.height).reduce((sum, value) => sum + value)
          : placementParams.height;

      if (placementParams.marginBetweenRows && elements.length > 1) {
        baseHeight += placementParams.marginBetweenRows * (elements.length - 1);
      }

      return baseHeight;
    }
  }

  private static getFinalPlacementParams(configPlacementParams?: Partial<PlacementParams>): PlacementParams {
    const defaultPlacementParams: PlacementParams = {
      alignItems: 'start',
      flexDirection: 'row',
      justifyContent: 'start',
      marginBetweenCols: 0,
      marginBetweenRows: 0,
      padding: {
        x: 0,
        y: 0,
      },
      height: 'auto',
      alpha: 1,
    };

    if (configPlacementParams) {
      Object.assign(defaultPlacementParams, configPlacementParams);
    }

    return defaultPlacementParams;
  }

  /**
   * Расставляет элементы в контейнере по горизонтали
   */
  private static arrangeHorizontalInContainer(
    container: Container,
    elements: Container[],
    containerStyle: PlacementParams,
  ): void {
    let totalElementWidth: number = elements.reduce((width: number, element: Container) => width + element.width, 0);

    elements.reduce((elementX: number, element: Container, index: number) => {
      let x: number;
      let y: number;

      const isFirstElement = index === 0;

      switch (containerStyle.alignItems) {
        case 'start':
          y = containerStyle.padding.y;
          break;
        case 'center':
          // Паддинги уже учтены в высоте, так что мы просто вертикально центрируем элементы
          let elementWidthWithBorder = 0;
          //@ts-ignore
          if (element.line) {
            //@ts-ignore
            elementWidthWithBorder = element.line.width;
          }
          if (element.height < container.height) {
            y = (container.height - element.height) / 2 + elementWidthWithBorder;
          } else {
            y = 0;
          }
          break;
        case 'end':
          if (element.height < container.height) {
            y = container.height - element.height;
          } else {
            y = 0;
          }
          break;
      }

      switch (containerStyle.justifyContent) {
        case 'start':
          x = elementX;
          break;
        case 'center':
          x = elementX + (container.width - totalElementWidth) / 2;
          break;
        case 'end':
          let remainingElements: Container[] = drop(elements, index);
          let remainingElementWidth: number = remainingElements.reduce(
            (width: number, element: Container) => width + element.width,
            0,
          );
          x = container.width - remainingElementWidth;
          break;
        case 'space-between':
          let s = container.width - totalElementWidth;
          switch (index) {
            case 0:
              x = elementX + containerStyle.padding.x;
              break;
            case elements.length - 1:
              x = elementX + s - containerStyle.padding.x;
              break;
            default:
              x = elementX + s;
          }
          break;
      }

      x += isFirstElement ? containerStyle.padding.x : containerStyle.marginBetweenCols;

      container.addChild(element);
      element.position.set(x, y);

      return x + element.width;
    }, 0);
  }

  /**
   * Расставляет элементы в контейнере по вертикали
   */
  private static arrangeVerticalInContainer(
    container: Container,
    elements: Container[],
    containerStyle: PlacementParams,
  ): void {
    let totalElementHeight: number = elements.reduce(
      (height: number, element: Container) => height + element.height,
      0,
    );

    elements.reduce((elementY: number, element: Container, index: number) => {
      let x: number;
      let y: number;

      const isFirstElement = index === 0;

      switch (containerStyle.alignItems) {
        case 'start':
          // Нет возможности протестировать, поэтому реализуйте по мере необходимости
          y = elementY;
          break;
        case 'center':
          y = elementY + (container.height - containerStyle.padding.y * 2 - totalElementHeight) / 2;
          break;
        case 'end':
          // Нет возможности протестировать, поэтому реализуйте по мере необходимости
          throw new Error('Not implemented');
      }

      switch (containerStyle.justifyContent) {
        case 'start':
          x = containerStyle.padding.x;
          break;
        case 'center':
          // Паддинги уже учтены в высоте, так что мы просто вертикально центрируем элементы
          let elementHeightWithBorder = 0;
          //@ts-ignore
          if (element.line) {
            //@ts-ignore
            elementHeightWithBorder = element.line.height;
          }
          if (element.width < container.width) {
            x = (container.width - element.width) / 2 + elementHeightWithBorder;
          } else {
            x = 0;
          }
          break;
        case 'end':
          // Нет возможности протестировать, поэтому реализуйте по мере необходимости
          throw new Error('Not implemented');
        case 'space-between':
          // Нет возможности протестировать, поэтому реализуйте по мере необходимости
          throw new Error('Not implemented');
      }

      y += isFirstElement ? containerStyle.padding.y : containerStyle.marginBetweenRows;

      container.addChild(element);
      element.position.set(x, y);

      return y + element.height;
    }, 0);
  }
}

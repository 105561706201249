import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Options as SliderOptions } from '@angular-slider/ngx-slider';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import cloneDeep from 'lodash-es/cloneDeep';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { PlanChangeConfirmModalComponent } from '@panel/app/pages/subscription/pricing/plan-change-confirm-modal/plan-change-confirm-modal.component';
import { PLAN_CHANGE_CONFIRM_MODAL_TOKEN } from '@panel/app/pages/subscription/pricing/plan-change-confirm-modal/plan-change-confirm-modal.token';
import { PlanTotalPrices, PricingService } from '@panel/app/pages/subscription/pricing/pricing.service';
import { PricingStore } from '@panel/app/pages/subscription/pricing/pricing.store';
import { DEFAULT_PRICING_ADDONS } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.constants';
import {
  AddonsConfiguration,
  V3_PLAN,
  V3_PLANS,
} from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.types';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { UNIQUE_BILLING_PLAN_ID } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfo, BillingInfoModel, TariffInfo } from '@panel/app/services/billing-info/billing-info.model';
import {
  DISCOUNTS_BY_PERIOD_DEFAULT,
  MONTHS_BY_PERIOD,
  PLANS_PERIODS,
} from '@panel/app/services/billing-plan/billing-plan.constants';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-pricing-plan-heading[currentApp][djangoUser]',
  templateUrl: './pricing-plan-heading.component.html',
  styleUrls: ['./pricing-plan-heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class PricingPlanHeadingComponent implements OnInit {
  @Input()
  currentApp!: App;

  @Input()
  djangoUser!: DjangoUser;

  @Input()
  expectedVisitors: number = 10000;

  @Input()
  readonly addonsConfiguration: AddonsConfiguration = cloneDeep(DEFAULT_PRICING_ADDONS[environment.country]);

  @Output()
  readonly expectedVisitorsChange = new Subject<number>();

  @ViewChild('heightRef')
  heightRef?: ElementRef;

  @HostBinding('style.top.px')
  get topStyle() {
    return -this.heightRef?.nativeElement.offsetHeight - 1;
  }

  currentPlanName: string = '';

  readonly paymentRateOptions = this.getPaymentRateOptions();

  paymentPeriod!: PLANS_PERIODS;

  readonly PLANS = V3_PLANS;

  readonly projectName = environment.projectName;

  expectedVisitorsLimit = 1;

  @Input()
  needsPersonalPlan = false;

  PLAN_PERIODS = PLANS_PERIODS;

  @Output()
  readonly needsPersonalPlanChange = new Subject<boolean>();

  constructor(
    private readonly destroy$: DestroyService,
    private readonly featureModel: FeatureModel,
    public readonly pricingService: PricingService,
    private readonly modalHelperService: ModalHelperService,
    private readonly billingPlanService: BillingPlanService,
    private readonly billingInfoModel: BillingInfoModel,
    private readonly translocoService: TranslocoService,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly planVersionService: PlanVersionService,
    private readonly pricingStore: PricingStore,
  ) {
    pricingStore.paymentPeriod
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentPeriod) => (this.paymentPeriod = paymentPeriod));
  }

  ngOnInit() {
    this.expectedVisitorsLimit = this.billingPlanService.getCurrentLimitUniqueUsers(
      this.currentApp,
      this.billingInfoModel.billingInfo,
    );

    this.getTariffInfo();
  }

  get sliderOptions(): SliderOptions {
    return {
      stepsArray: this.getSliderSteps(this.currentApp, this.billingInfoModel.billingInfo),
      ceil: this.expectedVisitorsLimit,
      hidePointerLabels: true,
      hideLimitLabels: true,
      showSelectionBar: true,
    };
  }

  /**
   * @param plan - выбранный план
   * @param pricing - цены на выбранный план
   */
  handleClickOnCalculatePayment(plan: V3_PLAN, pricing: PlanTotalPrices): void {
    if (this.planVersionService.isTrial()) {
      this.openModal(plan, pricing);
      return;
    }

    this.sendChatMessage(plan);
    this.trackIntentionToChangeNewPlan(plan, pricing);
  }

  getDiscountValue(period: PLANS_PERIODS): number {
    return this.billingPlanService.getCurrentDiscountsByPeriod(this.currentApp, this.billingInfoModel.billingInfo)[
      period
    ];
  }

  /** Список доступных периодов оплаты */
  getPaymentRateOptions() {
    const needToHideOneMonthPaymentPeriod = this.featureModel.hasAccess(FEATURES_ONLY.HIDE_ONE_MONTH_PAYMENT_PERIOD);

    if (needToHideOneMonthPaymentPeriod) {
      return Object.keys(DISCOUNTS_BY_PERIOD_DEFAULT[environment.country]).filter(
        (period) => period !== PLANS_PERIODS.MONTHLY,
      );
    }

    return Object.keys(DISCOUNTS_BY_PERIOD_DEFAULT[environment.country]);
  }

  /**
   * Получение информации о тарифном плане
   *
   * @return {Promise}
   */
  getTariffInfo() {
    firstValueFrom(this.billingInfoModel.getTariffInfo(this.currentApp.id, false)).then(
      (tariffInfo: TariffInfo): void => {
        this.currentPlanName = tariffInfo.invoiceName;
      },
    );
  }

  onPaymentPeriodChange(paymentPeriod: PLANS_PERIODS): void {
    this.pricingStore.paymentPeriod.next(paymentPeriod);
  }

  openModal(plan: V3_PLAN, prices: PlanTotalPrices): void {
    this.trackIntentionToChangePlan(plan);

    this.modalHelperService
      .provide(PLAN_CHANGE_CONFIRM_MODAL_TOKEN, {
        planId: plan,
        currentApp: this.currentApp,
        addons: prices.addons,
        price: prices,
        paymentPeriod: this.paymentPeriod,
        djangoUser: this.djangoUser,
      })
      .open(PlanChangeConfirmModalComponent, {
        centered: true,
      });
  }

  @tuiPure
  getSliderSteps(app: App, billingInfo: BillingInfo) {
    return this.billingPlanService.getCurrentScaleUniqueUsers(app, billingInfo);
  }

  onExpectedVisitorsChange(expectedVisitors: number) {
    if (expectedVisitors === this.expectedVisitorsLimit) {
      this.needsPersonalPlan = true;
      this.needsPersonalPlanChange.next(this.needsPersonalPlan);
      return;
    }
    this.needsPersonalPlan = false;
    this.needsPersonalPlanChange.next(this.needsPersonalPlan);
    this.expectedVisitorsChange.next(expectedVisitors);
  }

  planName(planId: UNIQUE_BILLING_PLAN_ID): string {
    return this.translocoService.translate(`models.billingInfo.billingPlansNames.${planId}`);
  }

  /**
   * Отправка сообщения в чат
   *
   * @param {String} newPlan - Название плана, на который нужно переключиться
   */
  sendChatMessage(newPlan: UNIQUE_BILLING_PLAN_ID): void {
    const params = {
      currentPlan: this.currentPlanName,
      newPlan: this.planName(newPlan),
      users: this.expectedVisitors,
      appId: this.currentApp.id,
      payPeriod: MONTHS_BY_PERIOD[this.paymentPeriod],
    };
    const translateMessage = this.translocoService.translate('pricingOldComponent.sendMessageText', params);

    this.carrotquestHelper.sendChatMessage(translateMessage);
  }

  trackIntentionToChangePlan(planId: UNIQUE_BILLING_PLAN_ID) {
    const addonsEnabled = Object.keys(this.addonsConfiguration)
      .filter((addon) => {
        //@ts-ignore
        return this.addonsConfiguration[addon][planId].value;
      })
      .map((addon) => {
        return addon;
      })
      .join(',');

    const params = {
      'Период подписки': this.translocoService.translate(
        'pricingPlanHeadingComponent.planConfiguration.paymentRateSelector.' + this.paymentPeriod,
        { discount: this.getDiscountValue(this.paymentPeriod) * 100 + '%' },
      ),
      Аддоны: addonsEnabled,
      'Название тарифа': this.planName(planId),
      Трафик: this.expectedVisitors,
    };

    this.carrotquestHelper.track('Тариф коммуникации - клик на тариф страница оплаты', params);
  }

  trackIntentionToContactUs(planId: UNIQUE_BILLING_PLAN_ID) {
    const addonsEnabled = Object.keys(this.addonsConfiguration)
      .filter((addon) => {
        //@ts-ignore
        return this.addonsConfiguration[addon][planId].value;
      })
      .map((addon) => {
        return this.translocoService.translate('models.billingInfo.billingAddOns.' + addon);
      })
      .join(',');

    const params = {
      'Период подписки': this.translocoService.translate(
        'pricingPlanHeadingComponent.planConfiguration.paymentRateSelector.' + this.paymentPeriod,
        { discount: this.getDiscountValue(this.paymentPeriod) * 100 + '%' },
      ),
      Аддоны: addonsEnabled,
      'Название тарифа': this.planName(planId),
      Трафик: this.expectedVisitors,
    };

    this.carrotquestHelper.track('Тариф коммуникации - связаться с продажами', params);
  }

  /**
   * Трек изменения нового тарифного плана
   *
   * @param plan - выбранный план
   * @param pricing - цены выбранного плана
   * @return {void}
   */
  trackIntentionToChangeNewPlan(plan: V3_PLAN, pricing: PlanTotalPrices) {
    this.carrotquestHelper.track('Оплата V1122 - выбрал тариф на замену существующему', {
      'app id': this.currentApp.id,
      'Текущий тариф': this.currentPlanName,
      'Новый тариф': this.planName(plan),
      Модули: this.billingPlanService.getTranslatedAddOnsName(pricing.addons),
    });
  }
}

import { BaseTexture, Renderer, RenderTexture } from '@pixi/core';

import { PixiGeometryService } from '@panel/app/shared/services/pixi/geometry/pixi-geometry.service';

//Текстура окружности для иконки с подложкой
let iconBackgroundCircleBaseTextures: Record<string, BaseTexture> = {};
let lastRendererContextUID: number | null = null;

export function getIconBackgroundCircleBaseTexture(renderer: Renderer, color: number, radius: number): BaseTexture {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    clearCache();
  }
  lastRendererContextUID = renderer.CONTEXT_UID;

  const idx = `${color}:${radius}`;
  if (iconBackgroundCircleBaseTextures[idx]) {
    return iconBackgroundCircleBaseTextures[idx];
  }

  const graphics = PixiGeometryService.renderCircle(radius, { backgroundColor: color });

  const renderTexture = RenderTexture.create({
    width: graphics.width,
    height: graphics.height,
  });

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  iconBackgroundCircleBaseTextures[idx] = renderTexture.castToBaseTexture();

  return iconBackgroundCircleBaseTextures[idx];
}

function clearCache() {
  iconBackgroundCircleBaseTextures = {};
}

<ng-container *transloco="let t; read: 'userConsentComponent'">
  <div
    (click)="collapse.toggle(); collapseStateChange.emit(userConsentLSName)"
    [attr.aria-expanded]="!isCollapsed"
    class="d-flex align-items-center cursor-pointer"
    aria-controls="collapse"
  >
    <div class="font-weight-bold flex-grow-1 p-15">{{ t('title') }}</div>
    <i
      [class]="{ 'cqi-chevron-down': isCollapsed, 'cqi-chevron-up': !isCollapsed }"
      class="btn-icon cqi-sm mr-15"
    ></i>
  </div>
  <div
    #collapse="ngbCollapse"
    [ngbCollapse]="isCollapsed"
    (ngbCollapseChange)="changeCollapsed()"
  >
    <div class="px-15 pb-10">
      <div class="mb-5">
        <div class="text-muted">{{ t('phoneStorageIsAllowed') }}</div>
        <div>{{ t('values.' + (user.props['$phone_storage_is_allowed'] || false)) }}</div>
      </div>
      <div class="mb-5">
        <div class="text-muted">{{ t('emailStorageIsAllowed') }}</div>
        <div>{{ t('values.' + (user.props['$email_storage_is_allowed'] || false)) }}</div>
      </div>
      <div class="mb-10">
        <div class="text-muted">{{ t('dataProcessingPolicyIsAllowed') }}</div>
        <div>{{ t('values.' + (user.props['$data_processing_policy_is_allowed'] || false)) }}</div>
      </div>
      <button
        *ngIf="thereIsAnyConsent | async"
        class="btn btn-secondary full-width"
        (click)="openConsentHistory()"
        type="button"
      >
        {{ t('openConsentHistory') }}
      </button>
    </div>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '../../../shared/shared.module';
import { UrlInputModule } from '../../url-input/url-input.module';
import { PageUrlComponent } from './page-url.component';

@NgModule({
  declarations: [PageUrlComponent],
  imports: [CommonModule, TranslocoModule, UrlInputModule, ReactiveFormsModule, SharedModule],
  exports: [PageUrlComponent],
})
export class PageUrlModule {}

/**
 *  Директива для обработки кастомных тригеров для tooltip и popover
 */
(function () {
  'use strict';

  angular
    .module('myApp.directives.customTrigger')
    .directive('cqTooltipTrigger', cqCustomTrigger)
    .directive('cqPopoverTrigger', cqCustomTrigger);

  function cqCustomTrigger($document) {
    return {
      restrict: 'A',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      // superHack - подписываемся на нажатие элемента с поповером/тултипом
      // полсле нажатия снимаем с элемента обработчик клика, чтобы поповер/тултип по клику на элемент мог закрыться
      // и подписываемся на любой клик в документе, что бы его закрыть
      // в нем же повторно подписываем элемент на событие клика по нему
      iElement.on('click', clickOnElement);
      iElement.on('$destroy', removeHandlers);

      function clickOnElement(e) {
        iElement.off('click', clickOnElement);
        e.stopPropagation();

        iElement.trigger('cqClick');
        $document.on('click', close);

        function close() {
          iElement.trigger('cqAnyClick');
          iElement.on('click', clickOnElement);
          removeDocumentHandler();
        }

        function removeDocumentHandler() {
          $document.off('click', close);
        }
      }

      function removeHandlers() {
        iElement.off('click', clickOnElement);
        $document.off('click', close);
      }
    }
  }
})();

import { Injectable } from '@angular/core';

import { CHAT_THEMES_TYPES } from '@http/chat-settings/chat-settings.constants';
import {
  chatDarkColor,
  chatLightColor,
} from '@panel/app/shared/services/user-colors-calculation/user-colors-calculation.constants';
import { ColorPalette } from '@panel/app/shared/services/user-colors-calculation/user-colors-calculation.type';

@Injectable()
export class UserColorsCalculationService {
  /**
   * Меняет аватарку на темную, если это дефолтная аватарка чата/бота и клиентский цвет светлый
   */
  static changeDefaultAvatarByCondition(userColor: string, theme: CHAT_THEMES_TYPES, avatarUrl: string): string {
    const { isLightOnClientsColorLooksWell } = UserColorsCalculationService.getUserColorPalette(userColor, theme);
    if (isLightOnClientsColorLooksWell) {
      return avatarUrl;
    }
    avatarUrl = avatarUrl.replace('avatars/default-chat-bot-avatar.png', 'avatars/dark-chat-bot-avatar.png');
    avatarUrl = avatarUrl.replace('avatars/default-v3.png', 'avatars/dark-v3.png');
    return avatarUrl;
  }

  /**
   * Получаем пользовательские цвета. Цвета текста могут меняться в зависимости от "яркости" accent цвета и.
   * Хотел калькулировать сразу в константу, но в тот момент carrotquest.data равна undefined. Поэтому чтоб не калькулировать десять раз пишу в "кэш"
   */
  static getUserColorPalette(userColor: string, theme: CHAT_THEMES_TYPES): ColorPalette {
    const isDefaultTheme = theme === 'default';
    const accentHexColor = userColor;

    /** @type boolean **/
    let isContrastGood;

    /** @type string */
    let userColorAndThemeDependent;

    /** @type string */
    let contrastColor;

    /** @type string */
    let iconTheme;

    const doLightAndUserColorsMatchWell = this.isColorMatchWellWithTheme(accentHexColor, true, 1.95);

    if (isDefaultTheme) {
      isContrastGood = doLightAndUserColorsMatchWell;
      userColorAndThemeDependent = isContrastGood ? accentHexColor : chatDarkColor;
      contrastColor = isContrastGood ? chatLightColor : chatDarkColor;
    } else {
      isContrastGood = this.isColorMatchWellWithTheme(accentHexColor, false, 5.1);
      userColorAndThemeDependent = isContrastGood ? accentHexColor : chatLightColor;
      contrastColor = isContrastGood ? chatDarkColor : chatLightColor;
    }

    iconTheme = contrastColor === chatLightColor ? 'default' : 'dark';

    const accentHoveredHexColor = this.lightenDarkenColor(accentHexColor, isContrastGood ? 5 : -5);

    const userColorBrightnessDependent = doLightAndUserColorsMatchWell ? accentHexColor : chatDarkColor;

    return {
      accentHexColor,
      accentHoveredHexColor,
      contrastColor,
      iconTheme,
      userColorAndThemeDependent,
      userColorBrightnessDependent,

      isContrastGood,
      isDefaultTheme,
      isLightOnClientsColorLooksWell: contrastColor === chatLightColor,
    };
  }

  /**
   * Логика из статики. Цвета клиента могут быть слишком темными в темной теме или слишком светолыми в светлой теме. Чтоб добавить какого-то контраста,
   * мы не позволяем выбирать свои цвета и выставляем свои пограничные.
   */
  static getMainUserColor(color: string, theme: CHAT_THEMES_TYPES): string {
    if (theme === 'default' && !this.isColorMatchWellWithTheme(color, true, 1.2)) {
      return `#E8E8E8`;
      // или слишком темный в темной
    } else if (theme === 'dark' && !this.isColorMatchWellWithTheme(color, false, 1.2)) {
      return `#404040`;
    } else {
      return color;
    }
  }

  /**
   * Проверяет, нормально ли цвет "A" читается на фоне цвета "Б". Взято отсюда https://ux.stackexchange.com/a/107319
   * @param {string} color
   * @param {boolean=true} isDefaultTheme
   * @param {number} coolContrastRatio - коэф от которого цвета считать приемлемыми, вычисляются Жекой Шохиревым
   * @return boolean
   */
  private static isColorMatchWellWithTheme(color: string, isDefaultTheme = true, coolContrastRatio: number) {
    const colorL = this.getL(color);
    const LTheme = isDefaultTheme ? this.getL(chatLightColor) : this.getL(chatDarkColor);
    const ratio = this.calcRatio(colorL, LTheme);
    return ratio >= coolContrastRatio;
  }

  /**
   * Осветляет или затемняет цвет
   *
   * @param color Цвет в HEX-формате
   * @param percent Проценты, на сколько надо изменить цвет: положительное число осветляет, отрицательное — затемняет
   * @returns Цвет в HEX-формате
   */
  private static lightenDarkenColor(color: string, percent: number): string {
    const num = parseInt(color.replace('#', ''), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (
      '#' +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  /**
   * Функции конвертации цветов вытащены из сорцов отсюда: https://webaim.org/resources/contrastchecker/
   * L - Коэффициент светлости от 0 до 255 https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
   */
  private static getRGB(c: any) {
    try {
      return parseInt(c, 16);
    } catch (err) {
      return false;
    }
  }
  private static getsRGB(c: any) {
    // @ts-ignore
    c = this.getRGB(c) / 255;
    c = c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    return c;
  }
  private static getL(c: any) {
    return (
      0.2126 * this.getsRGB(c.substr(1, 2)) +
      0.7152 * this.getsRGB(c.substr(3, 2)) +
      0.0722 * this.getsRGB(c.substr(-2))
    );
  }
  private static calcRatio(L1: any, L2: any) {
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
  }
}

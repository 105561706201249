import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';

export const enum READY_AUTO_MESSAGES_MODAL_FILTER {
  ALL = 'all',
  POPUPS = 'popups',
  CHAT_MESSAGE = 'chat_message',
  EMAIL = 'email',
}

export const READY_AUTO_MESSAGES_MODAL_FILTER_TO_MESSAGE_TYPE: Record<
  READY_AUTO_MESSAGES_MODAL_FILTER,
  MESSAGE_PART_TYPES[]
> = {
  [READY_AUTO_MESSAGES_MODAL_FILTER.ALL]: [
    MESSAGE_PART_TYPES.POPUP_SMALL,
    MESSAGE_PART_TYPES.POPUP_BIG,
    MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
    MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
    MESSAGE_PART_TYPES.POPUP_CHAT,
    MESSAGE_PART_TYPES.EMAIL,
  ],
  [READY_AUTO_MESSAGES_MODAL_FILTER.POPUPS]: [
    MESSAGE_PART_TYPES.POPUP_SMALL,
    MESSAGE_PART_TYPES.POPUP_BIG,
    MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
    MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
  ],
  [READY_AUTO_MESSAGES_MODAL_FILTER.CHAT_MESSAGE]: [MESSAGE_PART_TYPES.POPUP_CHAT],
  [READY_AUTO_MESSAGES_MODAL_FILTER.EMAIL]: [MESSAGE_PART_TYPES.EMAIL],
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BotSingleBlockOverlayComponent } from './bot-single-block-overlay.component';

@NgModule({
  declarations: [BotSingleBlockOverlayComponent],
  exports: [BotSingleBlockOverlayComponent],
  imports: [CommonModule],
})
export class BotSingleBlockOverlayModule {}

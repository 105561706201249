(function () {
  'use strict';

  require('./email-notification-timeout.controller');

  angular.module('myApp.chatSettings').component('cqEmailNotificationTimeout', cqEmailNotificationTimeout());

  function cqEmailNotificationTimeout() {
    return {
      bindings: {
        timeout: '=', // Таймаут дублирования сообщений на почту пользователю
      },
      controller: 'CqEmailNotificationTimeoutController',
      controllerAs: 'vm',
      template: require('./email-notification-timeout.html'),
    };
  }
})();

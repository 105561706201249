<ng-container *transloco="let t; read: 'whatsAppEdna'">
  <div class="card align-items-center">
    <div class="card-body">
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="integration.form"
      >
        <ng-container *ngIf="!isEdit">
          <h3 class="margin-bottom-10">{{ t('steps.1.heading') }}</h3>
          <p>{{ t('steps.1.description') }}</p>
          <cq-integration-name-control
            class="margin-bottom-20"
            [control]="nameControl"
            [hideDefaultHeading]="true"
          ></cq-integration-name-control>
          <button
            class="btn btn-primary w-100"
            (click)="create()"
            [attr.disabled]="nameControl.invalid || ((isRequestPerformed | async) ? true : null)"
            type="button"
          >
            {{ 'general.forward' | transloco }}
          </button>
        </ng-container>

        <ng-container *ngIf="isEdit">
          <h3 class="margin-bottom-10">{{ t('steps.1.heading') }}</h3>
          <p>{{ t('steps.1.description') }}</p>
          <cq-integration-name-control
            class="margin-bottom-20"
            [control]="nameControl"
            [hideDefaultHeading]="true"
          ></cq-integration-name-control>

          <hr />
          <h3>{{ t('steps.2.heading') }}</h3>
          <div class="margin-bottom-30">{{ t('steps.2.description.part1') }}</div>
          <div
            class="margin-bottom-10"
            [innerHTML]="t('steps.2.description.part2')"
          ></div>
          <a
            class="btn btn-secondary full-width"
            [attr.href]="
              t('steps.2.description.signUpLink.href', {
                integrationId: integration.id,
                hash: hashValue
              })
            "
            target="_blank"
            translate
          >
            <i class="btn-icon-left cqi-sm cqi-external-link"></i>
            <span>{{ t('steps.2.description.signUpLink.text') }}</span>
          </a>

          <hr />
          <h3>{{ t('steps.3.heading') }}</h3>
          <img
            class="margin-bottom-10 full-max-width"
            src="assets/img/{{ activeLang }}/integrations/whatsapp-edna/whatsapp-edna-1.png"
          />
          <p [innerHTML]="t('steps.3.description')"></p>
          <cq-whats-app-edna-access-token [formControl]="accessTokenControl"></cq-whats-app-edna-access-token>

          <hr />
          <h3>{{ t('steps.4.heading') }}</h3>
          <p>{{ t('steps.4.description') }}</p>
          <cq-whats-app-edna-account-phone [formControl]="accountPhoneControl"></cq-whats-app-edna-account-phone>

          <ng-container *ngIf="hasAccessToNewApi()">
            <hr />
            <h3>{{ t('steps.5.heading') }}</h3>
            <img
              class="margin-bottom-10 full-max-width"
              src="assets/img/{{ activeLang }}/integrations/whatsapp-edna/whatsapp-edna-2.png"
            />
            <p [innerHTML]="t('steps.5.description')"></p>
            <cq-whats-app-edna-cascade-id [formControl]="cascadeIdControl"></cq-whats-app-edna-cascade-id>
          </ng-container>
        </ng-container>
        <input
          id="submit"
          class="hidden"
          type="submit"
        />
      </form>
    </div>
  </div>
  <div class="text-right">
    <label
      *ngIf="integrationExternal.id"
      class="btn btn-primary"
      [ngClass]="{ disabled: isRequestPerformed | async }"
      for="submit"
    >
      {{ 'models.integration.actions.save' | transloco }}
    </label>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./statistics.controller');
  require('./tabs/sendings.html');

  angular.module('myApp.chatBot.telegramBot').component('cqTelegramBotStatistics', cqTelegramBotStatistics());

  function cqTelegramBotStatistics() {
    return {
      bindings: {
        telegramBot: '=', // Telegram-бот
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
      },
      controller: 'CqTelegramBotStatisticsController',
      controllerAs: 'vm',
      template: require('./statistics.html'),
    };
  }
})();

/**
 * Фильтр удаления переменных Jinja
 */
(function () {
  'use strict';

  angular.module('myApp.filters.removeJinja').filter('removeJinja', removeJinja);

  function removeJinja($translate, $sce) {
    return removeJinjaFilter;

    /**
     * Удаление переменных Jinja из входной строки
     *
     * @param {String} value Строка для преобразования
     * @return {String}
     */
    function removeJinjaFilter(value) {
      if (typeof value == 'string') {
        value = value.replace(/{{user\['(.*?)'\]\|default\('(.*?)'\)}}/gi, (match, propName, defaultValue) =>
          $sce.trustAsHtml(`<span class="badge badge-light-secondary text-muted">${defaultValue}</span>`),
        );
        value = value.replace(
          /{{[ ]*user\[('|&#39;)\$name('|&#39;)\][ ]*\|[ ]*default\(('|&#39;)[^}]*?('|&#39;)\)[ ]*}}/g,
          $translate.instant('filters.removeJinja.userName'),
        );
        value = value.replace(
          /{{[ ]*user\[('|&#39;)\$name('|&#39;)\][ ]*}}/g,
          $translate.instant('filters.removeJinja.userName'),
        );
        value = value.replace(
          /{{[ ]*user\[('|&#39;)\$email('|&#39;)\][ ]*}}/g,
          $translate.instant('filters.removeJinja.userEmail'),
        );
        value = value.replace(
          /{{[ ]*user\[('|&#39;)\$phone('|&#39;)\][ ]*}}/g,
          $translate.instant('filters.removeJinja.userPhone'),
        );
        value = value.replace(
          /{{[ ]*user\[('|&#39;)[^}]*?('|&#39;)\][ ]*\|[ ]*default\(('|&#39;)[^}]*?('|&#39;)\)[ ]*}}/g,
          $translate.instant('filters.removeJinja.userProperty'),
        );
        value = value.replace(
          /{{[ ]*user\[('|&#39;)[^}]*?('|&#39;)\][ ]*}}/g,
          $translate.instant('filters.removeJinja.userProperty'),
        );
      }

      return value;
    }
  }
})();

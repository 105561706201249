import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule, NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgArrayPipesModule, NgMathPipesModule } from 'ngx-pipes';

import { FirstPaymentDiscountLabelModule } from '@panel/app/pages/subscription/general/first-payment-discount-label/first-payment-discount-label.module';
import { StripePayModalModule } from '@panel/app/pages/subscription/modals/stripe-pay-modal/stripe-pay-modal.module';
import { PricingOldComponent } from '@panel/app/pages/subscription/pricing-old/pricing-old.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [PricingOldComponent],
  exports: [PricingOldComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbCollapseModule,
    NgxSliderModule,
    NgbTooltipModule,
    FormsModule,
    FirstPaymentDiscountLabelModule,
    NgMathPipesModule,
    NgbAlertModule,
    SharedModule,
    NgArrayPipesModule,
    StripePayModalModule,
  ],
})
export class PricingOldModule {}

(function () {
  'use strict';

  angular.module('myApp.serviceInstallation').controller('cqCopyScriptController', cqCopyScriptController);

  function cqCopyScriptController(PROJECT_NAME) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.isCopied = false; // скопирован текст или нет
      vm.scriptCopied = scriptCopied;
    }

    /**
     * Скрипт успешно скопирован
     */
    function scriptCopied() {
      vm.isCopied = true;

      vm.onCopy && vm.onCopy();
    }
  }
})();

import { Pipe, PipeTransform } from '@angular/core';

import { AmocrmField } from '@http/integration/integrations/amo/types/amocrm-integration.types';

@Pipe({
  name: 'duplicatesFields',
})
export class DuplicatesFieldsPipe implements PipeTransform {
  transform(leadFields: AmocrmField[], exeption: string | null, chosenLeadFields: string[]): AmocrmField[] {
    return leadFields.filter((leadField) => {
      return !(chosenLeadFields.includes(leadField.name) && leadField.name !== exeption);
    });
  }
}

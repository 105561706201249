import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/** Компонент для работы с баннером раннего доступа */
@Component({
  selector: 'cq-banner-early-access',
  templateUrl: './banner-early-access.component.html',
  styleUrls: ['./banner-early-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerEarlyAccessComponent {
  /** Callback на клик по кнопке закрытия баннера */
  @Output()
  clickCloseButton: EventEmitter<void> = new EventEmitter<void>();

  /** Обработчик клика по кнопке закрытия */
  onClickCloseButton(): void {
    this.clickCloseButton.emit();
  }
}

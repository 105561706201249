import { Injectable } from '@angular/core';

import {
  TriggerChainParseToInternalExtraData,
  TriggerChainStepMapper,
} from '@http/trigger-chain/mappers/trigger-chain-step.mapper';
import { TriggerChainTemplateExternal } from '@http/trigger-chain-template/external-types/trigger-chain-template.external.type';
import { TriggerChainTemplate } from '@http/trigger-chain-template/internal-types/trigger-chain-template.internal.type';

@Injectable({ providedIn: 'root' })
export class TriggerChainTemplateMapper {
  constructor(private readonly triggerChainStepMapper: TriggerChainStepMapper) {}

  parseToInternal(
    triggerChainTemplateExternal: TriggerChainTemplateExternal,
    extraData: TriggerChainParseToInternalExtraData,
  ): TriggerChainTemplate {
    return {
      ...triggerChainTemplateExternal,
      steps: triggerChainTemplateExternal.steps.map((triggerChainStepExternal) =>
        this.triggerChainStepMapper.parseToInternal(triggerChainStepExternal, extraData),
      ),
    };
  }
}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.limitOfUniqueVisitors')
    .controller('CqLimitOfUniqueVisitorsController', CqLimitOfUniqueVisitorsController);

  function CqLimitOfUniqueVisitorsController($state, PROJECT_NAME, appModel, djangoUserModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.currentTermEnd = vm.resolve.billingInfo.subscription.current_term_end;
      vm.goToApps = goToApps;
      vm.goToPay = goToPay;
      vm.isShowChangeOtherAppsButton = false; // Показывать ли кнопки для выбора другого app'а
      vm.isSuperAdmin = djangoUserModel.isSuperAdmin(vm.resolve.currentApp.id, vm.resolve.djangoUser); // Является ли пользователь супер админом
      vm.PROJECT_NAME = PROJECT_NAME;

      // при открытии модального окна, проверим есть ли другие app'ы, чтобы дать возможность вернуться к их выбору
      firstValueFrom(appModel.getList()).then((apps) => {
        // Вычитание 1 исключает текущий App из кол-ва App'ов пользователя
        let countApps = apps.length - 1;

        vm.isShowChangeOtherAppsButton = countApps >= 1;
      });
    }

    /**
     * Переход на состояние со списком app'ов
     */
    function goToApps() {
      $state.go('account.content', { tab: 'apps' });
    }

    /**
     * Переход на состояние для оплаты подписки
     */
    function goToPay() {
      $state.go('app.content.subscription.general', {}, { reload: true });
    }
  }
})();

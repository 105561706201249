/**
 * Провека того, что строка является ссылкой
 */
(function () {
  'use strict';

  angular.module('myApp.filters.isUrl').filter('isUrl', isUrl);

  function isUrl() {
    return isUrlFilter;

    /**
     * Если переданное значение - это ссылка, то вернётся true, иначе false
     *
     * @param {*} value Значение для проверки
     * @return {Boolean}
     */
    function isUrlFilter(value) {
      if (value) {
        var stringValue = value.toString();

        return (
          stringValue.indexOf('http://') == 0 || stringValue.indexOf('https://') == 0 || stringValue.indexOf('//') == 0
        );
      } else {
        return false;
      }
    }
  }
})();

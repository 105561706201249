/** Направление изменений значения zoom */
export enum CANVAS_ZOOM_DIRECTION {
  /** Уменьшение значения zoom */
  DOWN = 'down',
  /** Увеличение значения zoom */
  UP = 'up',
}

/** Настройки zoom */
export enum CANVAS_ZOOM_SETTINGS {
  /** Значение по умолчанию */
  DEFAULT_VALUE = 100,
  /** Минимальное значение */
  MIN_PERCENT = 20,
  /** Максимальное значение */
  MAX_PERCENT = 200,
  /** Шаг изменения */
  STEP_PERCENT = 20,
}

export enum CANVAS_SCALE_WORK_ZOOM_VALUE {
  UPPER = 100,
  LOWER = 40,
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { isNull } from 'lodash-es';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import {
  ProductFeatureAccess,
  ProductFeatureDenialReason,
} from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { App } from '@http/app/app.model';

@Component({
  selector: 'cq-integration-card[currentApp][type]',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationCardComponent implements OnInit {
  @HostBinding('class')
  class = 'card';

  /**
   * Счётчик настроенных интеграций данного типа
   */
  @Input()
  readonly count: number = 0;

  /**
   * Передаётся сюда исключительно из-за того, что нужно передавать текущий апп в пейвол
   */
  @Input()
  currentApp!: App;

  /**
   * Тип интеграции
   */
  @Input()
  readonly type!: INTEGRATION_TYPES;

  /** Доступ до интеграции */
  accessToIntegrationType: ProductFeatureAccess = {
    hasAccess: true,
    denialReason: null,
  };

  /**
   * Переведённое название типа интеграции
   */
  typeName!: string;
  readonly projectName = environment.projectName;

  constructor(
    private readonly integrationService: IntegrationService,
    private readonly paywallService: PaywallService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.accessToIntegrationType = this.integrationService.getAccessToIntegrationType(this.type, this.currentApp);

    this.typeName = this.translocoService.translate(`models.integration.types.${this.type}.name`, {
      projectName: this.projectName,
    });
  }

  /**
   * Показ пейвола с покупкой тарифа, который включает в себя planCapability
   *
   * @param currentApp
   * @param denialReason - Причина отказа в доступе
   */
  showPaywall(currentApp: App, denialReason: ProductFeatureDenialReason | null) {
    if (!isNull(denialReason)) {
      this.paywallService.showPaywall(currentApp, denialReason);
    }
  }
}

<ng-container *transloco="let t; read: 'buttonBlockEditorComponent'">
  <div class="mb-5 d-flex justify-content-between align-items-center">
    <label>
      {{ t('heading', { index: index + 1 }) }}
    </label>

    <div
      class="inline-block"
      ngbDropdown
    >
      <button
        class="btn btn-borderless-primary"
        type="button"
        ngbDropdownToggle
      >
        <i class="btn-icon cqi-sm cqi-ellipsis"></i>
      </button>
      <div
        class="dropdown-menu-right"
        ngbDropdownMenu
      >
        <!--Переместить выше-->
        <button
          *ngIf="index !== 0"
          class="dropdown-item"
          type="button"
          (click)="moveUp.emit()"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-arrow-up"></i>
          {{ t('actionsDropdown.up') }}
        </button>

        <!--Переместить ниже-->
        <button
          *ngIf="lastIndex !== index"
          class="dropdown-item"
          type="button"
          (click)="moveDown.emit()"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-arrow-down"></i>
          {{ t('actionsDropdown.down') }}
        </button>

        <!--Дублировать-->
        <button
          class="dropdown-item"
          type="button"
          (click)="!isDuplicateDisabled && duplicate.emit()"
          [disabled]="isDuplicateDisabled"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-copy"></i>
          {{ t('actionsDropdown.copy') }}
        </button>

        <!--Удалить-->
        <button
          class="dropdown-item"
          type="button"
          (click)="delete.emit()"
        >
          <i class="dropdown-item-icon-left cqi-sm cqi-trash"></i>
          {{ t('actionsDropdown.delete') }}
        </button>
      </div>
    </div>
  </div>

  <div class="mb-20 ml-n10 mr-n10 row">
    <div class="col-6 pl-10 pr-10">
      <div
        class="form-group mb-0"
        [cqShowError]="control.controls.text"
      >
        <label class="font-weight-normal small"> {{ t('buttonTextInput.label') }}: </label>
        <input
          class="form-control"
          name="button"
          type="text"
          placeholder="{{ t('buttonTextInput.placeholder') }}"
          [formControl]="control.controls.text"
          required
        />
        <cq-validation-messages [control]="control.controls.text">
          <cq-validation-message errorName="required">
            {{ t('buttonTextInput.errors.required') }}
          </cq-validation-message>
          <cq-validation-message errorName="maxlength">
            {{ t('buttonTextInput.errors.maxLength', { maxLength: BUTTON_TEXT_MAX_LENGTH }) }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
    </div>

    <div class="col-6 px-10">
      <div
        class="form-group mb-0"
        [cqShowError]="control.controls.actionType"
      >
        <label class="font-weight-normal small"> {{ t('buttonActionSelect.label') }}: </label>
        <ng-select
          [items]="buttonActionTypes"
          [formControl]="control.controls.actionType"
          (change)="actionTypeChanged($event)"
          [clearable]="false"
          required
        >
          <ng-template
            let-item="item"
            ng-option-tmp
            ng-label-tmp
          >
            {{ t('buttonActionSelect.items.' + item) }}
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>

  <ng-container *ngIf="control.controls.actionType.value === 'url'">
    <div
      class="form-group has-feedback mt-10 mb-0"
      [cqShowError]="control.controls.url"
    >
      <input
        class="form-control"
        placeholder="{{ t('urlInput.placeholder') }}"
        [formControl]="control.controls.url"
        type="url"
      />

      <cq-validation-messages [control]="control.controls.url">
        <cq-validation-message errorName="pattern">
          {{ t('urlInput.errors.url') }}
        </cq-validation-message>
        <cq-validation-message errorName="whitespace">
          {{ t('urlInput.errors.required') }}
        </cq-validation-message>
        <cq-validation-message errorName="required">
          {{ t('urlInput.errors.required') }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </ng-container>

  <div [cqShowError]="control.controls.eventToWrite">
    <cq-checkbox-control
      [(ngModel)]="setEventType"
      (ngModelChange)="setEventTypeChanged($event)"
      class="my-10"
    >
      {{ t('writeToEventCheckbox.label') }}
    </cq-checkbox-control>

    <cq-event-type-select
      *ngIf="setEventType"
      [currentApp]="currentApp"
      [eventTypes]="(messageEditorTriggerState.eventTypes$ | async) ?? []"
      (eventTypesChange)="messageEditorTriggerState.eventTypes$.next($event)"
      [formControl]="control.controls.eventToWrite"
      [showHeader]="false"
      [createTrackMasterEvent]="false"
    ></cq-event-type-select>

    <cq-validation-messages [control]="control.controls.eventToWrite">
      <cq-validation-message errorName="required">
        {{ t('urlInput.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

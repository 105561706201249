import { FactoryProvider, Injector } from '@angular/core';
import { StateService } from '@uirouter/core';

import { TriggerChainContentEditorModal } from '@panel/app-old/components/trigger-chains/content-editor/modal/trigger-chain-content-editor.modal';
import { ConversationTagAdapterService } from '@panel/app-old/shared/entities/conversation-tag/adapter/conversation-tag-adapter.service';
import { ConversationTagFactoryService } from '@panel/app-old/shared/entities/conversation-tag/factory/conversation-tag-factory.service';
import { ConversationTagHelpService } from '@panel/app-old/shared/entities/conversation-tag/help/conversation-tag-help.service';
import { ConversationTagHttpService } from '@panel/app-old/shared/entities/conversation-tag/http/conversation-tag-http.service';
import { IntegrationModel } from '@panel/app-old/shared/models/integration/integration.model';
import { BlockPanelHelper } from '@panel/app-old/shared/services/block-panel-helper/block-panel-helper.service';
import { BlockingService } from '@panel/app-old/shared/services/blocking/blocking.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';
import { ProductFeatureTextService } from '@panel/app-old/shared/services/product-feature-text/product-feature-text.service';
import { ServiceInstallationToastService } from '@panel/app-old/shared/services/service-installation-toast/service-installation-toast.service';
import { SystemErrorService } from '@panel/app-old/shared/services/system-error/system-error.service';
import { TelegramBotStoreService } from '@panel/app-old/shared/services/telegram-bot-editor-store/telegram-bot-store.service';
import { TimeUnitService } from '@panel/app-old/shared/services/time-unit/time-unit.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';
import { UtilsService } from '@panel/app-old/shared/services/utils/utils.service';

const createServiceProvider = (ajsServiceName: string, service: object): FactoryProvider => {
  return {
    provide: service,
    useFactory: (injector: Injector) => injector.get(ajsServiceName),
    deps: ['$injector'],
  };
};

export const BlockingServiceProvider: FactoryProvider = createServiceProvider('blockingService', BlockingService);
export const CarrotquestHelperProvider: FactoryProvider = createServiceProvider('carrotquestHelper', CarrotquestHelper);

export const ConversationTagAdapterServiceProvider: FactoryProvider = createServiceProvider(
  'conversationTagAdapterService',
  ConversationTagAdapterService,
);
export const ConversationTagFactoryServiceProvider: FactoryProvider = createServiceProvider(
  'conversationTagFactoryService',
  ConversationTagFactoryService,
);

export const ConversationTagHelpServiceProvider: FactoryProvider = createServiceProvider(
  'conversationTagHelpService',
  ConversationTagHelpService,
);

export const ConversationTagHttpServiceProvider: FactoryProvider = createServiceProvider(
  'conversationTagHttpService',
  ConversationTagHttpService,
);
export const FileHelperProvider: FactoryProvider = createServiceProvider('fileHelper', FileHelperService);
export const L10nHelperProvider: FactoryProvider = createServiceProvider('l10nHelper', L10nHelperService);
export const ProductFeatureTextServiceProvider: FactoryProvider = createServiceProvider(
  'productFeatureTextService',
  ProductFeatureTextService,
);
export const StateServiceProvider: FactoryProvider = createServiceProvider('$state', StateService);
export const TelegramBotStoreProvider: FactoryProvider = createServiceProvider(
  'telegramBotStore',
  TelegramBotStoreService,
);
export const UibModalServiceProvider: FactoryProvider = createServiceProvider('uibModalService', UibModalService);
export const TimeUnitServiceProvider: FactoryProvider = createServiceProvider('timeUnitService', TimeUnitService);
export const TriggerChainContentEditorModalProvider = createServiceProvider(
  'triggerChainContentEditorModal',
  TriggerChainContentEditorModal,
);
export const UtilsServiceProvider: FactoryProvider = createServiceProvider('utilsService', UtilsService);

export const SystemErrorServiceProvider: FactoryProvider = createServiceProvider('systemError', SystemErrorService);
export const ServiceInstallationToastServiceProvider: FactoryProvider = createServiceProvider(
  'serviceInstallationToast',
  ServiceInstallationToastService,
);

export const BlockPanelHelperProvider: FactoryProvider = createServiceProvider('blockPanelHelper', BlockPanelHelper);
export const IntegrationModelProvider: FactoryProvider = createServiceProvider('integrationModel', IntegrationModel);

export const AjsServiceProviders: FactoryProvider[] = [
  IntegrationModelProvider,
  BlockPanelHelperProvider,
  ServiceInstallationToastServiceProvider,
  SystemErrorServiceProvider,
  BlockingServiceProvider,
  CarrotquestHelperProvider,
  ConversationTagAdapterServiceProvider,
  ConversationTagFactoryServiceProvider,
  ConversationTagHelpServiceProvider,
  ConversationTagHttpServiceProvider,
  FileHelperProvider,
  L10nHelperProvider,
  ProductFeatureTextServiceProvider,
  TelegramBotStoreProvider,
  TimeUnitServiceProvider,
  TriggerChainContentEditorModalProvider,
  UibModalServiceProvider,
  UtilsServiceProvider,
];

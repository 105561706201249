import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ManageTagsStore } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.store';
import { uniqueValidator } from '@panel/app/shared/validators/unique/unique-validator';
import { ConversationTag } from '@panel/app-old/shared/entities/conversation-tag/conversation-tag';
import { ConversationTagFactoryService } from '@panel/app-old/shared/entities/conversation-tag/factory/conversation-tag-factory.service';
import { ConversationTagCreateForm } from '@panel/app-old/shared/entities/conversation-tag/types/conversation-tag-create-form.types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Компонент для работы с созданием тега */
@Component({
  selector: 'cq-create-tag[createTag]',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTagComponent {
  /** Callback на добавление тега */
  @Output()
  createTag: EventEmitter<ConversationTag> = new EventEmitter<ConversationTag>();

  /** Форма для создания нового тега */
  form: FormGroup<ConversationTagCreateForm> = new FormGroup<ConversationTagCreateForm>({
    text: new FormControl('', [Validators.required, uniqueValidator(this.manageTagsStore.tags$.toFieldArray('text'))]),
  });

  /** Инстанс нового тега */
  tag: ConversationTag;

  /** Возвращает значение контрола с текстом тега */
  get formTextValue(): string {
    return this.form.value.text ?? '';
  }

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly conversationTagFactoryService: ConversationTagFactoryService,
    private readonly manageTagsStore: ManageTagsStore,
  ) {
    this.tag = this.initTag();
  }

  /** Инициализирует переменную tag */
  initTag(): ConversationTag {
    const dto = {
      text: this.formTextValue,
    };

    return this.conversationTagFactoryService.getInstance(dto);
  }

  /** Возвращает disable-состояние кнопки */
  isDisableCreateTagButton(): boolean {
    return this.form.invalid;
  }

  /** Обрабатывает клик по кнопке отмены */
  onClickCancelButton(): void {
    this.stopCreateTagProcess();
  }

  /** Обработчик отправки формы */
  onSubmit(event: Event) {
    event.preventDefault();

    if (!this.form.valid) {
      return;
    }

    if (!this.formTextValue) {
      return;
    }

    this.tag.text = this.formTextValue;

    this.createTag.emit(this.tag);
  }

  /** Останавливает процесс создания тега */
  stopCreateTagProcess(): void {
    this.tag.stopCreateProcess();
  }

  /** Запускает валидацию */
  validate(): void {
    this.form.updateValueAndValidity();
  }
}

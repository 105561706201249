import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { INTEGRATIONS_HEADLINERS } from '@panel/app/partials/service-installation/service-installation-integrations/service-installation-integrations.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-service-installation-integrations',
  templateUrl: './service-installation-integrations.component.html',
  styleUrls: ['./service-installation-integrations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceInstallationIntegrationsComponent {
  /** Интеграции, которые можно настроить без установки кода */
  INTEGRATIONS_HEADLINERS = INTEGRATIONS_HEADLINERS[environment.country];

  constructor(private readonly carrotquestHelper: CarrotquestHelper, private readonly uiRouter: UIRouter) {}

  /**
   * Переход на страницу preview интеграции
   *
   * @param integrationType - Тип интеграции, на preview страницу которой нужно перейти
   */
  toIntegrationPage(integrationType: INTEGRATION_TYPES): void {
    this.trackToIntegrationPage(integrationType);
    this.uiRouter.stateService.go('app.content.integrations.preview', { integrationType });
  }

  /**
   * Трекинг перехода на страницу preview интеграции
   *
   * @param integrationType - Тип интеграции, на preview страницу которой нужно перейти
   */
  trackToIntegrationPage(integrationType: INTEGRATION_TYPES): void {
    const eventName = `Установка сервиса - кликнул на "Настроить интеграцию с ${integrationType}`;

    this.carrotquestHelper.track(eventName);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DJANGO_USER_INTEGRATION_TYPES } from '@http/django-user-integration/django-user-integration.constants';
import {
  ApiCreateRequest,
  ApiGetCalendlyUserEventsRequest,
  ApiGetRequest,
  DjangoUserIntegration,
  DjangoUserIntegrationUnparsed,
} from '@http/django-user-integration/django-user-integration.types';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';

/**
 * Сервис для работы с интеграциями для конкретного Django-пользователя
 */

@Injectable({ providedIn: 'root' })
export class DjangoUserIntegrationModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Создание интеграции Django-пользователя
   *
   * @param appId ID аппа
   * @param type Тип создаваемой интеграции
   * @param settings Параметры для интеграции
   */
  public create(appId: string, type: DJANGO_USER_INTEGRATION_TYPES, settings?: any): Observable<DjangoUserIntegration> {
    const body: ApiCreateRequest = {
      app: appId,
      settings: settings,
      ignoreErrors: true,
    };

    return this.http
      .post<DjangoUserIntegrationUnparsed>(`/user_integrations/${type}`, body)
      .pipe(map((data) => this.parseDjangoUserIntegrationToInternalFormat(data)));
  }

  /**
   * Получение списка интеграций django-пользователя по её типу
   *
   * @param type Тип интеграции
   * @param djangoUserId ID django-пользователя. Если не передавать - получатся все интеграции django-пользователя в аппе типа type
   */
  public get(type: DJANGO_USER_INTEGRATION_TYPES, djangoUserId?: string): Observable<DjangoUserIntegration[]> {
    const params: ApiGetRequest = {
      active: true,
      django_user: djangoUserId,
      include_django_user: true,
      integration: 1,
    };

    return this.http.get<DjangoUserIntegrationUnparsed[]>(`/user_integrations/${type}`, { params }).pipe(
      map((data) => {
        let integrations: DjangoUserIntegration[] = [];

        // в ответе получается массив, т.к. если не указывать djangoUserId - может прийти несколько интеграций
        for (let i = 0; i < data.length; i++) {
          integrations.push(this.parseDjangoUserIntegrationToInternalFormat(data[i]));
        }

        return integrations;
      }),
    );
  }

  /**
   * Получение списка событий Сalendly django-пользователя
   *
   * @param id ID интеграции с Calendly у django-пользователя
   * @param active Получить только активные события Calendly
   */
  public getCalendlyUserEvents(id: string, active?: boolean): Observable<unknown> {
    const params: ApiGetCalendlyUserEventsRequest = {
      active: active,
      ignoreErrors: true,
    };

    return this.http.get(`/user_integrations/${INTEGRATION_TYPES.CALENDLY}/${id}/event_types`, { params });
  }

  /**
   * Получение списка интеграций Django-пользователя по нескольким типам интеграций
   *
   * @param types Типы интеграций, которые нужно получить
   * @param djangoUserId ID django-пользователя. Если не передавать - получатся все интеграции django-пользователя в аппе типов types
   */
  public getList(types: DJANGO_USER_INTEGRATION_TYPES[], djangoUserId?: string): Observable<DjangoUserIntegration[]> {
    const observables = types.map((type) => this.get(type, djangoUserId));

    return forkJoin(observables).pipe(map((integrations) => integrations.flat()));
  }

  /**
   * Парсинг интеграции Django-пользователя во внутренний формат админки
   *
   * @param integration
   */
  public parseDjangoUserIntegrationToInternalFormat(integration: any): DjangoUserIntegration {
    integration.created = moment(integration.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ');

    return integration;
  }

  /**
   * Удаление интеграции django-пользователя
   *
   * @param id ID интеграции django-пользователя
   * @param type Тип интеграции
   */
  public remove(id: string, type: DJANGO_USER_INTEGRATION_TYPES): Observable<void> {
    return this.http.delete<void>(`/user_integrations/${type}/${id}`);
  }
}

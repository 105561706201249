/**
 * Директива для корректного помещения файла в модель
 *
 * @example с использованием bootstrap.css
 * <input class="form-control" readonly ng-model="vm.file.name">
 * <label class="input-group-append">
 *   <span class="btn btn-primary">
 *     Выбрать<input name="file" type="file" cq-file ng-model="vm.file">
 *   </span>
 * </label>
 */
(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('cqFile', cqFile);

  function cqFile($parse) {
    return {
      restrict: 'A',
      require: 'ngModel',
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller, transcludeFn) {
      //var modelSetter = $parse(iAttrs.ngModel).assign;

      // при каждом изменении input[cq-file] помещаем в модель файл
      iElement.bind('change', change);

      // при каждом ПРОГРАММНОМ изменении меняем значение input[cq-file]
      controller.$render = render;

      function change() {
        scope.$apply(function () {
          controller.$setViewValue(iElement[0].files[0]);
        });
      }

      /*function change() {
        scope.$apply(function () {
          modelSetter(scope, iElement[0].files[0]);
          // после смены
          controller.$setTouched();
          controller.$setDirty();
        });
      }*/

      function render() {
        // поскольку программно при помощи JS файл сгенерировать нельзя, единственное, на что нужно реагировать - обнуление модели
        // например, это нужно для возвращения дефолтной аватарки
        if (controller.$isEmpty(controller.$modelValue)) {
          iElement.val(null);
        }
      }
    }
  }
})();

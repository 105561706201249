import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import {
  AmocrmLeadsEventsMappingForm,
  AmocrmMappingForm,
} from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import { IntegrationService } from '@http/integration/services/integration.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { App } from '@http/app/app.model';

/**
 * Компонент для работы с данными, которые передаются из AmoCRM
 */

@Component({
  selector:
    'cq-amocrm-data-from[controlAmocrmLeadsPropsMapping][controlAmocrmPropsMappingInternal][currentApp][integrationId][leadsEventsMappingControl]',
  templateUrl: './amocrm-data-from.component.html',
  styleUrls: ['./amocrm-data-from.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmDataFromComponent {
  /** Контрол с полями сделки amoCRM, после изменения которой это поле будет записано в свойство лида в Carrot quest */
  @Input()
  controlAmocrmLeadsPropsMapping!: GenericFormArray<AmocrmMappingForm>;

  /** Контрол со свойствами, которые передаются из контакта amoCRM в CQ|Dashly */
  @Input()
  controlAmocrmPropsMappingInternal!: GenericFormArray<AmocrmMappingForm>;

  /** Текущее приложения */
  @Input()
  currentApp!: App;

  /** ID интеграции */
  @Input()
  integrationId!: string;

  /** Контрол со статусами сделки amoCRM, после изменения которых будет создаваться событие в CQ|Dashly */
  @Input()
  leadsEventsMappingControl!: GenericFormArray<AmocrmLeadsEventsMappingForm>;

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(@Inject(IntegrationService) private readonly integrationService: IntegrationService) {}
}

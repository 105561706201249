import { Injectable } from '@angular/core';

import { BillingInfoModel, BillingInfoSubscription } from '@panel/app/services/billing-info/billing-info.model';
import {
  FIRST_PROMOTER_COUPON_NAME,
  FIRST_PROMOTER_DISCOUNT_AMOUNT,
} from '@panel/app/services/first-promoter/first-promoter.constants';
import { DjangoUserModel } from '@http/django-user/django-user.model';

/** Сервис для работы с реферальной программой FirstPromoter */
@Injectable({ providedIn: 'root' })
export class FirstPromoterService {
  constructor(private readonly billingInfoModel: BillingInfoModel, private readonly djangoUserModel: DjangoUserModel) {}

  /** Размер скидки по реферальной программе */
  getDiscount(): number {
    return FIRST_PROMOTER_DISCOUNT_AMOUNT;
  }

  /** Была ли первая оплата со скидкой по реферальной программе */
  hasFirstPaymentDiscount(): boolean {
    const billingInfoSubscription = this.billingInfoModel.billingInfo.subscription as BillingInfoSubscription;
    const firstPromoterCoupon =
      billingInfoSubscription.coupon && billingInfoSubscription.coupon === FIRST_PROMOTER_COUPON_NAME;

    return this.isLead() && !!firstPromoterCoupon;
  }

  /** Является ли пользователь приглашённым клиентом промоутера */
  isLead(): boolean {
    const djangoUser = this.djangoUserModel.djangoUser as any;
    const djangoUserFirstPromoterTid = djangoUser.first_promoter_tid;

    return !!djangoUserFirstPromoterTid;
  }
}

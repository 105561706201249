import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { TriggerChainEditorModule } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.module';
import { TriggerChainGeneralStatisticsModule } from '@panel/app/pages/trigger-chains/general-statistics/trigger-chain-general-statistics.module';
import { TriggerChainListModule } from '@panel/app/pages/trigger-chains/list/trigger-chain-list.module';
import { TriggerChainPageComponent } from '@panel/app/pages/trigger-chains/trigger-chain-page.component';
import { TRIGGER_CHAIN_PAGE_ROUTES } from '@panel/app/pages/trigger-chains/trigger-chain-page.routes';

@NgModule({
  declarations: [TriggerChainPageComponent],
  imports: [
    CommonModule,
    TriggerChainEditorModule,
    TriggerChainGeneralStatisticsModule,
    TriggerChainListModule,
    UIRouterUpgradeModule.forChild({ states: TRIGGER_CHAIN_PAGE_ROUTES }),
  ],
  providers: [],
})
export class TriggerChainsModule {}

/**
 * Фильтр для добавления дополнительного значения в ng-options (ВНИМАНИЕ! Референс на массив/объект после использования теряется, создаётся новый массив/объект!)
 */
(function () {
  'use strict';

  angular.module('myApp.filters.addOption').filter('addOption', addOption);

  function addOption() {
    return addOptionFilter;

    /**
     * Добавление в массив (или объект) нового элемента (или свойства)
     *
     * @param {Array|Object|*} value Массив или объект, в который надо добавить элемент
     * @param {*} structureToAdd Элемент (если добавление производится в объект - должен иметь поля key и value)
     * @returns {*} Новая структура с добавленным элементом
     */
    function addOptionFilter(value, structureToAdd) {
      if (value.constructor == Array) {
        // нужно клонировать массив или объект, т.к. если сохранять референс - каждый digest-цикл ангуляра будет добавляться новое значение structureToAdd в эту же структуру
        var newArray = value.slice(0);
        newArray.unshift(structureToAdd);
        return newArray;
      } else if (value.constructor == Object) {
        var newObject = angular.copy(value);

        // если ключа, либо значения нет в добавляемом элементе - не добавляем его вообще
        if (angular.isDefined(structureToAdd.key) && angular.isDefined(structureToAdd.value)) {
          newObject[structureToAdd.key] = structureToAdd.value;
        }

        return newObject;
      } else {
        return value;
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';

import { StripeThreeDSModalComponent } from './stripe-three-d-s-modal.component';

@NgModule({
  declarations: [StripeThreeDSModalComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [CarrotquestHelperProvider, NgbActiveModal],
})
export class StripeThreeDSModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentModule } from '@panel/app/pages/chat-bot/content/content.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ContentModule],
  providers: [],
})
export class ChatBotModule {}

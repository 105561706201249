import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { AceConfigInterface } from 'ngx-ace-wrapper';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-ace-editor',
  templateUrl: './ace-editor.component.html',
  styleUrls: ['./ace-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AceEditorComponent extends AbsCVAFormControlBasedComponent<string> {
  public control: FormControl<string> = this.formBuilder.control('', { nonNullable: true });

  protected config: AceConfigInterface = {
    mode: 'python',
    theme: 'monokai',
  };

  constructor(private formBuilder: FormBuilder) {
    super();
  }
}

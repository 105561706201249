/**
 * Валидатор для ссылки на видео в блкое с видео в конструкторе поп-апов
 */
import {
  POPUP_VIDEO_BLOCK_REGEXP,
  POPUP_VIDEO_BLOCK_TYPES,
} from '../../../../app/services/popup-block/popup-block.constants';

(function () {
  'use strict';

  angular.module('myApp.directives.videoBlockUrl').directive('cqVideoBlockUrl', cqVideoBlockUrl);

  function cqVideoBlockUrl() {
    return {
      priority: 2,
      restrict: 'A',
      require: 'ngModel',
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$validators.notValidUrl = notValidUrl;

      /**
       * Валидация значения input
       *
       * @param modelValue Значение, которое записано в модель
       * @param viewValue Значение, которое отображается в input
       * @returns {Boolean}
       */
      function notValidUrl(modelValue, viewValue) {
        return (
          controller.$isEmpty(modelValue) ||
          POPUP_VIDEO_BLOCK_REGEXP[POPUP_VIDEO_BLOCK_TYPES.VIMEO].test(modelValue) ||
          POPUP_VIDEO_BLOCK_REGEXP[POPUP_VIDEO_BLOCK_TYPES.YOU_TUBE].test(modelValue)
        );
      }
    }
  }
})();

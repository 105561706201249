import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MessageWebhookParam } from '@http/message-part/message-part.types';
import { checkWebhookDefaultHeaderValidator } from '@panel/app/pages/trigger-messages/webhook-part-editor/components/param-editor/param-editor.validators';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

@Component({
  selector: 'cq-param-editor',
  templateUrl: './param-editor.component.html',
  styleUrls: ['./param-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParamEditorComponent extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<MessageWebhookParam>> {
  @Input()
  set paramsDisabled(disabled: boolean) {
    this._paramsDisabled = disabled;
    if (disabled) {
      this.keyForm.disable();
      this.valueForm.disable();
    } else {
      this.keyForm.enable();
      this.valueForm.enable();
    }
  }
  _paramsDisabled: boolean = false;

  @Input()
  paramInfo?: string;

  readonly PARAM_MAX_LENGTH = 500;

  readonly control: FormGroup<ToFormGroupControls<MessageWebhookParam>> = this.fb.group<
    ToFormGroupControls<MessageWebhookParam>
  >({
    key: this.fb.control<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.maxLength(this.PARAM_MAX_LENGTH),
        checkWebhookDefaultHeaderValidator,
      ],
    }),
    value: this.fb.control<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(this.PARAM_MAX_LENGTH)],
    }),
  });

  @Output()
  readonly delete = new EventEmitter<void>();

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  get keyForm() {
    return this.control.controls.key;
  }

  get valueForm() {
    return this.control.controls.value;
  }
}

// !!! Это не библиотека! Это самописный файл, который какого-то хрена лежал внутри библиотеки flag-icon
//  Естественно нужно этот файл переписать на нормальный сервис, а пока пусть лежит тут
window.COUNTRY2CODE = {
  'Sierra Leone': 'sl',
  'Lithuania': 'lt',
  'Mongolia': 'mn',
  'Estonia': 'ee',
  'Sri Lanka': 'lk',
  'Montserrat': 'ms',
  'Aruba': 'aw',
  'Sint Maarten': 'sx',
  'Eritrea': 'er',
  'Malta': 'mt',
  'Portugal': 'pt',
  'Vatican': 'va',
  'Cook Islands': 'ck',
  'Cyprus': 'cy',
  'Congo': 'cg',
  'Philippines': 'ph',
  'Norfolk Island': 'nf',
  'Falkland Islands': 'fk',
  'Oman': 'om',
  'Saudi Arabia': 'sa',
  'Madagascar': 'mg',
  'Burkina Faso': 'bf',
  'Puerto Rico': 'pr',
  'Albania': 'al',
  'Kiribati': 'ki',
  'Bahrain': 'bh',
  'Mauritius': 'mu',
  'Angola': 'ao',
  'Christmas Island': 'cx',
  'Martinique': 'mq',
  'Finland': 'fi',
  'Singapore': 'sg',
  'Australia': 'au',
  'Nouvelle-Caledonie': 'nc',
  'Venezuela': 've',
  'Denmark': 'dk',
  'United Kingdom': 'gb',
  'Japan': 'jp',
  'Nicaragua': 'ni',
  'Canada': 'ca',
  'Taiwan': 'tw',
  'Peru': 'pe',
  'Belarus': 'by',
  'Germany': 'de',
  'Solomon Islands': 'sb',
  'Moldova': 'md',
  'Botswana': 'bw',
  'Barbados': 'bb',
  'Cabo Verde': 'cv',
  'United States': 'us',
  'Slovakia': 'sk',
  'Pakistan': 'pk',
  'Yemen': 'ye',
  'Indonesia': 'id',
  'Gambia': 'gm',
  'Nepal': 'np',
  'Kyrgyzstan': 'kg',
  'South Africa': 'za',
  'Saint Kitts and Nevis': 'kn',
  'Croatia': 'hr',
  'Italia': 'it',
  'Jersey': 'je',
  'Ireland': 'ie',
  'Somalia': 'so',
  'Liechtenstein': 'li',
  'Haiti': 'ht',
  'South Georgia and the South Sandwich Islands': 'gs',
  'Liberia': 'lr',
  'Azerbaijan': 'az',
  'Serbia': 'rs',
  'Belgium': 'be',
  'Faroe Islands': 'fo',
  'Zambia': 'zm',
  'Reunion': 're',
  'Malawi': 'mw',
  'Federated States of Micronesia': 'fm',
  'Costa Rica': 'cr',
  'Niger': 'ne',
  'Mozambique': 'mz',
  'Dominican Republic': 'do',
  'Wallis and Futuna': 'wf',
  'Djibouti': 'dj',
  'Belize': 'bz',
  'Northern Mariana Islands': 'mp',
  'India': 'in',
  'Kosovo': 'xk',
  'Cambodia': 'kh',
  'Lesotho': 'ls',
  'Chad': 'td',
  'Andorra': 'ad',
  'San Marino': 'sm',
  'Iran': 'ir',
  'Burma': 'mm',
  'Ukraine': 'ua',
  'Netherlands': 'nl',
  'Slovenia': 'si',
  'Ecuador': 'ec',
  'Guyane': 'gf',
  'Cameroon': 'cm',
  'Bosnia and Herzegovina': 'ba',
  'Guinea-Bissau': 'gw',
  'Gibraltar': 'gi',
  'Tokelau': 'tk',
  'British Virgin Islands': 'vg',
  'North Korea': 'kp',
  'Morocco': 'ma',
  'Georgia': 'ge',
  'Latvia': 'lv',
  'Saint Lucia': 'lc',
  'Bolivia': 'bo',
  'Saint Vincent and the Grenadines': 'vc',
  'Argentina': 'ar',
  'El Salvador': 'sv',
  'Laos': 'la',
  'Saint-Pierre et Miquelon': 'pm',
  'Benin': 'bj',
  'Nigeria': 'ng',
  'Norway': 'no',
  'Greenland': 'gl',
  'Monaco': 'mc',
  'Kazakhstan': 'kz',
  'Montenegro': 'me',
  'Tajikistan': 'tj',
  'Central African Republic': 'cf',
  'France': 'fr',
  'Comoros': 'km',
  'Tunisia': 'tn',
  'Ghana': 'gh',
  'Maldives': 'mv',
  'Libya': 'ly',
  'Jamaica': 'jm',
  'Curacao': 'cw',
  'United Arab Emirates': 'ae',
  'Guadeloupe': 'gp',
  'Territory of the French Southern and Antarctic Lands': 'tf',
  'Cote d\'Ivoire': 'ci',
  'Kuwait': 'kw',
  'Greece': 'gr',
  'Palau': 'pw',
  'Antarctica': 'aq',
  'Burundi': 'bi',
  'Hong Kong': 'hk',
  'East Timor': 'tl',
  'Antigua and Barbuda': 'ag',
  'Grenada': 'gd',
  'Austria': 'at',
  'New Zealand': 'nz',
  'American Samoa': 'as',
  'Uganda': 'ug',
  'Uzbekistan': 'uz',
  'Macedonia': 'mk',
  'Hungary': 'hu',
  'Malaysia': 'my',
  'Vanuatu': 'vu',
  'Bonaire, Saint Eustatius and Saba': 'bq',
  'Virgin Islands of the United States': 'vi',
  'Mali': 'ml',
  'Uruguay': 'uy',
  'Guinea': 'gn',
  'Afghanistan': 'af',
  'Turkmenistan': 'tm',
  'South Korea': 'kr',
  'Seychelles': 'sc',
  'Tonga': 'to',
  'Sweden': 'se',
  'Aland Islands': 'ax',
  'Togo': 'tg',
  'Iceland': 'is',
  'Svalbard and Jan Mayen': 'sj',
  'Ethiopia': 'et',
  'Saint-Barthelemy': 'bl',
  'Bulgaria': 'bg',
  'Isle of Man': 'im',
  'Panama': 'pa',
  'Mauritania': 'mr',
  'Israel': 'il',
  'Armenia': 'am',
  'Thailand': 'th',
  'Fiji': 'fj',
  'Guam': 'gu',
  'Guatemala': 'gt',
  'Tuvalu': 'tv',
  'Chile': 'cl',
  'Suriname': 'sr',
  'Paraguay': 'py',
  'Papua New Guinea': 'pg',
  'Sudan': 'sd',
  'Nauru': 'nr',
  'Iraq': 'iq',
  'Zimbabwe': 'zw',
  'Brazil': 'br',
  'Gabon': 'ga',
  'Guernsey': 'gg',
  'Spain': 'es',
  'Turkey': 'tr',
  'Sao Tome and Principe': 'st',
  'Dominica': 'dm',
  'British Indian Ocean Territory': 'io',
  'Turks and Caicos Islands': 'tc',
  'Ascension, and Tristan da Cunha Saint Helena': 'sh',
  'Rwanda': 'rw',
  'Bangladesh': 'bd',
  'South Sudan': 'ss',
  'Syria': 'sy',
  'Marshall Islands': 'mh',
  'Tanzania': 'tz',
  'Algeria': 'dz',
  'United States Minor Outlying Islands': 'um',
  'Trinidad and Tobago': 'tt',
  'China': 'cn',
  'Egypt': 'eg',
  'Poland': 'pl',
  'Swaziland': 'sz',
  'Cuba': 'cu',
  'Democratic Republic of the Congo': 'cd',
  'Bahamas': 'bs',
  'Namibia': 'na',
  'Cayman Islands': 'ky',
  'Romania': 'ro',
  'Equatorial Guinea': 'gq',
  'Switzerland': 'ch',
  'Polynesie Francaise': 'pf',
  'Macau': 'mo',
  'Czech Republic': 'cz',
  'Cocos (Keeling) Islands': 'cc',
  'Samoa': 'ws',
  'Senegal': 'sn',
  'Palestine': 'ps',
  'Brunei': 'bn',
  'Luxembourg': 'lu',
  'Qatar': 'qa',
  'Vietnam': 'vn',
  'Mexico': 'mx',
  'Colombia': 'co',
  'Pitcairn Islands': 'pn',
  'Saint-Martin': 'mf',
  'Russia': 'ru',
  'Bhutan': 'bt',
  'Kenya': 'ke',
  'Niue': 'nu',
  'Jordan': 'jo',
  'Guyana': 'gy',
  'Anguilla': 'ai',
  'Mayotte': 'yt',
  'Bermuda': 'bm',
  'Lebanon': 'lb',
  'Honduras': 'hn'
};

import { translate } from '@jsverse/transloco';

/**
 * Создаёт строку с перечислением элементов через запятую из массива
 * При этом перед последним элементом ставится не запятая, а союз
 *
 * @param items Массив, элементы которого нужно перечислить через запятую
 * @returns Строка перечисленных элементов из items через запятую
 */
export function makeSequenceFromArray(items: string[]): string {
  if (items.length === 0) {
    return '';
  }

  const itemsWithQuotes = items.map((value) => `"${value}"`);
  const conjunction = translate<string>('general.andConjunction').toLowerCase();
  return itemsWithQuotes.reduce((a, b, i, array) => a + (i < array.length - 1 ? ', ' : ` ${conjunction} `) + b);
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';

import { ValidationMessageComponent } from './validation-message.component';

type Position = 'top' | 'bottom' | 'left' | 'right';

/**
 * Временная (а может быть и нет) замена ng-messages
 *
 * TODO VALIDATION_MESSAGES_REFACTOR
 * Надо это переписать на ngZone, потому что в текущем виде он не всегда подхватывает, что надо проверить валидацию.
 * Код уже написан, был откачен в коммите 1be5ed9b, потому что ломались тесты. В целом все работало ок.
 */
@Component({
  selector: 'cq-validation-messages[control]',
  styleUrls: ['./validation-messages.scss'],
  template: `
    <div class="validation-messages">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ValidationMessagesComponent implements AfterViewInit {
  private controlChange$ = new Subject<void>();

  private _control: AbstractControl | null = null;
  /**
   * При изменении контрола скрываем все ошибки и подписываемся на ошибки заново
   */
  @Input() set control(value: AbstractControl | null) {
    this.controlChange$.next();
    this._control = value;
    if (!value) {
      return;
    }
    if (this.messageComponents) {
      this.messageComponents.forEach((messageComponent) => (messageComponent.show = false));
      this.sub();
    }
  }
  get control(): AbstractControl | null {
    return this._control;
  }

  @HostBinding('class')
  @Input()
  position: Position = 'top';

  @ContentChildren(ValidationMessageComponent) messageComponents!: QueryList<ValidationMessageComponent>;

  constructor(private readonly destroy$: DestroyService) {}

  ngAfterViewInit() {
    this.sub();
  }

  private sub() {
    if (!this.control) {
      return;
    }
    // Достаём из контрола изменение статуса валидности и изменение статуса touched
    combineLatest([
      this.control.statusChanges.pipe(startWith(this.control.status)),
      extractTouchedChanges(this.control).pipe(startWith(this.control.touched)),
    ])
      .pipe(takeUntil(this.destroy$), takeUntil(this.controlChange$))
      .subscribe(([_, touched]) => {
        if (!this.messageComponents.length) {
          return;
        }
        this.messageComponents.forEach((messageComponent) => (messageComponent.show = false));

        // this.control.errors может быть false(null) только для formGroup,
        // если ошибка не из валидаторов группы, а из валидаторов дочерних контролов
        if (this.control!.invalid && this.control!.errors && touched) {
          const errorsList = Object.keys(this.control!.errors!);

          const firstErrorMessageComponent = this.messageComponents.find((messageComponent) => {
            return errorsList.includes(messageComponent.errorName);
          });

          if (firstErrorMessageComponent) {
            firstErrorMessageComponent.show = true;
          } else {
            throw new Error(`Couldn't find components matching the specified errors: ${errorsList}`);
          }
        }
      });
  }
}

<ng-container *transloco="let t; read: 'amocrmDataFromComponent'">
  <h3 class="font-weight-bolder">{{t('heading', {integrationTypeName})}}</h3>

  <hr />
  <cq-amocrm-data-from-leads
    [controlAmocrmLeadsPropsMapping]="controlAmocrmLeadsPropsMapping"
    [currentApp]="currentApp"
    [integrationId]="integrationId"
    [leadsEventsMappingControl]="leadsEventsMappingControl"
  ></cq-amocrm-data-from-leads>

  <hr />
  <cq-amocrm-data-from-contacts
    [controlAmocrmPropsMappingInternal]="controlAmocrmPropsMappingInternal"
    [integrationId]="integrationId"
  ></cq-amocrm-data-from-contacts>
</ng-container>

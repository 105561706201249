import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { MEMBER_INVITATION_STATUS } from '@http/team-member-invitation/constants/member-invitations.constants';
import { MemberInvitationsService } from '@http/team-member-invitation/services/member-invitations.service';
import { MemberInvitation } from '@http/team-member-invitation/types/member-invitations.types';
import { DestroyService } from '@panel/app/services';
import { TeamMemberHelpService } from '@panel/app/services/team-member/team-member-help.service';

/**
 * Компонент для работы с таблицей приглашений членов команды
 */

@Component({
  selector: 'cq-member-invitations-table[memberInvitations][resendInvitationFn]',
  templateUrl: './member-invitations-table.component.html',
  styleUrls: ['./member-invitations-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class MemberInvitationsTableComponent {
  /** Приглашения членов команды */
  @Input()
  memberInvitations!: MemberInvitation[];

  /** Callback после повторной отправки приглашений */
  @Output()
  resendInvitationFn: EventEmitter<MemberInvitation> = new EventEmitter<MemberInvitation>();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    private readonly memberInvitationsService: MemberInvitationsService,
    private readonly teamMemberHelpService: TeamMemberHelpService,
  ) {}

  /**
   * Получение класса для badg'а статуса приглашения
   *
   * @param inviteExpired - Истёк ли срок действия приглашения
   */
  getClassForInvitationStatusBadge(inviteExpired: MemberInvitation['inviteExpired']): string {
    return inviteExpired ? 'badge-light-danger' : 'badge-light-success';
  }

  /**
   * Получение класса для иконки обозначающей права пользователя
   *
   * @param permission - Права пользователя
   */
  getIconClassForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.teamMemberHelpService.getIconClassForPermission(permission);
  }

  /** Получение класса для иконки прав на экспорт */
  getIconClassForPermissionExport(): string {
    return this.teamMemberHelpService.getIconClassForPermissionExport();
  }

  /** Получение класса для иконки прав на массовой рассылки */
  getIconClassForPermissionSendBulk(): string {
    return this.teamMemberHelpService.getIconClassForPermissionSendBulk();
  }

  /**
   * Получение текста тултипа для иконок прав пользователей
   *
   * @param permission - Права пользователя
   */
  getNameForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.teamMemberHelpService.getNameForPermission(permission);
  }

  /** Получение названия для права на экспорт */
  getNameForPermissionExport(): string {
    return this.teamMemberHelpService.getNameForPermissionExport();
  }

  /** Получение названия для права на массовую рассылку */
  getNameForPermissionSendBulk(): string {
    return this.teamMemberHelpService.getNameForPermissionSendBulk();
  }

  /**
   * Получение ключа перевода для badg'а статуса перевода
   *
   * @param inviteExpired - Истёк ли срок действия приглашения
   */
  getTranslateKeyForInvitationStatusBadge(inviteExpired: MemberInvitation['inviteExpired']): string {
    return inviteExpired ? MEMBER_INVITATION_STATUS.EXPIRED : MEMBER_INVITATION_STATUS.SENT;
  }

  /** Приглашения не найдены */
  isInvitationsNotFound(): boolean {
    return this.memberInvitations.length === 0;
  }

  /** Обработчик клика по кнопке повторной отправки приглашения */
  onClickResendInvitationButton(invitationIndex: number): void {
    this.resendInvitation(invitationIndex);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Повторная отправка приглашения члена команды
   *
   * @param memberInvitationIndex - Приглашение, которое необходимо повторно отправить
   */
  resendInvitation(memberInvitationIndex: number): void {
    const invitation = this.memberInvitations[memberInvitationIndex];

    this.memberInvitationsService
      .resend(invitation)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.resendInvitationFn.emit(response);
      });
  }
}

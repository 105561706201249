<ng-container *transloco="let t; read: 'amocrmModalCreateLeadTagComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{t('heading', {integrationTypeName})}}</h3>

    <button
      class="close"
      (click)="onClickCloseModalButton()"
      type="button"
      aria-label="Close"
      data-test="amocrm-modal-create-lead-tag-close-button"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div
      class="form-group"
      [cqShowError]="controlLeadTag"
      data-test="amocrm-modal-create-lead-tag-errors-container"
    >
      <label>{{ t('input.label') }}</label>
      <input
        class="form-control"
        [formControl]="controlLeadTag"
        [placeholder]="t('input.placeholder')"
        type="text"
        data-test="amocrm-modal-create-lead-tag-input"
      />

      <cq-validation-messages [control]="controlLeadTag">
        <cq-validation-message errorName="required">{{ t('input.errors.required') }}</cq-validation-message>
      </cq-validation-messages>
    </div>

    <div class="d-flex align-items-center text-secondary mb-15">
      <i class="cqi-sm cqi-lightbulb mr-5"></i>
      <span>{{t('note', {integrationTypeName})}}</span>
    </div>
  </div>

  <div class="modal-footer bordered">
    <button
      class="btn btn-outline-primary"
      (click)="onClickDismissModalButton()"
      type="button"
      data-test="amocrm-modal-create-lead-tag-dismiss-button"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <button
      class="btn btn-primary"
      (click)="onClickCreateButton()"
      type="button"
      data-test="amocrm-modal-create-lead-tag-create-button"
    >
      {{ 'general.create' | transloco }}
    </button>
  </div>
</ng-container>

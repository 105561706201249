import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';

import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

export const AUTO_EVENT_NAME_MAX_LENGTH = 255;

@Component({
  selector: 'cq-event-name-input',
  templateUrl: './event-name-input.component.html',
  styleUrls: ['./event-name-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventNameInputComponent extends AbstractCVAControl<string> {
  readonly AUTO_EVENT_NAME_MAX_LENGTH = AUTO_EVENT_NAME_MAX_LENGTH;

  readonly control = new GenericFormControl<string>(null, [
    Validators.required,
    Validators.maxLength(this.AUTO_EVENT_NAME_MAX_LENGTH),
  ]);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmEventTriggersForContactsComponent } from './amocrm-event-triggers-for-contacts.component';

@NgModule({
  declarations: [AmocrmEventTriggersForContactsComponent],
  exports: [AmocrmEventTriggersForContactsComponent],
  imports: [CommonModule, EventTypeSelectModule, NgbCollapseModule, SharedModule, TranslocoModule, ReactiveFormsModule],
})
export class AmocrmEventTriggersForContactsModule {}

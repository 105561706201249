import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/** Токен для скипа всех интерсепторов */
export const SKIP_APP_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

/**
 * Добавляет параметр app ко всем запросам, которые посылаются на бэкенд
 */
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private uiRouter: UIRouter) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    if (request.context.has(SKIP_APP_INTERCEPTOR)) {
      return next.handle(request);
    }

    let appId = this.uiRouter.globals.params.appId as string;

    if (!appId) {
      // эта штука отрабатывает только тогда, когда грузится впервые апп и в параметрах еще нет appId
      appId =
        this.uiRouter.stateService.transition && this.uiRouter.stateService.transition.targetState().params().appId;
    }

    if (appId) {
      request = request.clone({
        setParams: {
          app: appId,
        },
      });
    }

    return next.handle(request);
  }
}

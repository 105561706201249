(function () {
  'use strict';

  angular.module('myApp.emailTemplates', []);

  require('./email-templates.component');
  require('./conversation/conversation-email-template.component');
  require('./conversation/lightweight-conversation-email-template.component');
  require('./message/lightweight-message-email-template.component');
  require('./message/message-email-template.component');
})();

import { LS_PINNED_PROPS } from '../../../app/shared/constants/localstorage.keys';
import { uniqBy } from 'lodash-es';
import { SUBSCRIPTION_STATUSES } from '../../../app/services/billing-info/billing-info.constants';
import {
  PLAN_FEATURE,
  PLAN_FEATURE_BY_MESSAGE_PART_TYPE,
} from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { PERMISSIONS_FOR_ACTIONS } from '../../../app/http/django-user/django-user.constants';
import { MESSAGE_TYPES } from '../../../app/http/message/message.constants';
import { MESSAGE_PART_TYPES } from '../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../app/http/feature/feature.constants';
import { ELASTICSEARCH_PROPERTY_OPERATIONS } from '../../../app/http/property/property.constants';
import { STARTER_GUIDE_STEPS } from '../../../app/http/starter-guide/starter-guide.constants';
import { firstValueFrom } from 'rxjs';

/**
 * Контроллер списка пользователей
 */

(function () {
  'use strict';

  angular.module('myApp.users').controller('UsersController', UsersController);

  function UsersController(
    $filter,
    $scope,
    $state,
    $translate,
    $uibModal,
    $window,
    $timeout,
    toastr,
    FILTER_SEGMENTS_PAGE_TYPE,
    appModel,
    carrotquestHelper,
    djangoUserModel,
    featureModel,
    filterAjsModel,
    messageSenderModel,
    paywallService,
    planFeatureAccessService,
    properties,
    propertyModel,
    tagModel,
    userModel,
    validationHelper,
    activeUsers,
    activeUsersCount,
    appBlocks,
    billingInfo,
    currentApp,
    djangoUser,
    telegramIntegrations,
    tags,
  ) {
    var vm = this;

    var DEFAULT_CHECKED_PROPERTIES = {
      //стандартные столбцы для таблицы пользователей TODO: переделать в массив
      $name: 1,
      $last_seen: 1,
      $email: 1,
      $score: 1,
      $sessions: 1,
      $initial_referrer_domain: 1,
      $country: 1,
      $region: 1,
      $city: 1,
    };

    /**
     * Интервал для получения virtual scroll элемента для таблицы с пользователями
     */
    let fixScrollForUserTableInterval;

    vm.currentApp = currentApp;
    vm.selectedUsersCounter = 0; //количество выделенных пользователей

    vm.accessToLeads = planFeatureAccessService.getAccess(PLAN_FEATURE.LEADS, vm.currentApp);
    vm.accessToManualMessagesBulkSend = planFeatureAccessService.getAccess(
      PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND,
      vm.currentApp,
      vm.selectedUsersCounter,
    );
    vm.accessToUsersExport = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_EXPORT, vm.currentApp);
    vm.accessToUsersTags = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, vm.currentApp);

    vm.activeUsers = activeUsers;
    vm.activeUsersCount = activeUsersCount;
    vm.appBlocks = appBlocks;
    vm.appModel = appModel;
    vm.billingInfo = billingInfo;
    vm.changeOrder = changeOrder;
    vm.currentApp = currentApp;
    vm.djangoUser = djangoUser;
    vm.exportUsers = exportUsers;
    vm.featureModel = featureModel;
    vm.FEATURES = FEATURES;
    vm.FILTER_SEGMENTS_PAGE_TYPE = FILTER_SEGMENTS_PAGE_TYPE;
    vm.filteredUserPropsAndEvents = [];
    vm.filterAjsModel = filterAjsModel;
    vm.filters = filterAjsModel.getDefaultAnd(); //фильтры сегментов
    vm.getCssClassSortIcon = getCssClassSortIcon;
    vm.getLeadsCount = getLeadsCount;
    vm.hasAccessByMessageType = hasAccessByMessageType;
    vm.isSelectedUsersHasEmail = false; // сущеструют ли у выбранных пользователей емейлы
    vm.isUserPropertiesDropdownOpen = false; // открыт ли дропдаун со свойствами пользователей
    vm.lastUsersCount = 0; //количество пользователей, загруженных последний раз
    vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
    vm.MESSAGE_TYPES = MESSAGE_TYPES;
    vm.openAddTag = openAddTag;
    vm.openCard = openCard;
    vm.openManualUserMergeModal = openManualUserMergeModal;
    vm.openSendMessageModal = openSendMessageModal;
    vm.openSendSubscriptionConfirmationEmailModal = openSendSubscriptionConfirmationEmailModal;
    vm.paywallService = paywallService;
    vm.redirectToStarterGuide = redirectToStarterGuide;
    vm.refreshSelectedUsersCount = refreshSelectedUsersCount;
    vm.refreshUsers = refreshUsers;
    vm.refreshUsersButton = refreshUsersButton;
    vm.removeUsers = removeUsers;
    vm.searchFilters = filterAjsModel.getDefaultOr(); //фильтры поиска
    vm.selectedUsersAll = false; //выделены ли все пользователи
    vm.showMore = showMore;
    vm.sortOrder = ''; //тип сортировки (возрастание/убывание)
    vm.sortProp = ''; //имя свойства по которому осртируется
    vm.SUBSCRIPTION_STATUSES = SUBSCRIPTION_STATUSES;
    vm.tags = tags; //Теги
    vm.trackClickAddTag = trackClickAddTag;
    vm.trackClickExportUsers = trackClickExportUsers;
    vm.trackClickImportUsers = trackClickImportUsers;
    vm.trackClickInstallCode = trackClickInstallCode;
    vm.trackClickManualUserMerge = trackClickManualUserMerge;
    vm.trackClickOnSearch = trackClickOnSearch;
    vm.trackClickRefreshUsers = trackClickRefreshUsers;
    vm.trackClickRemoveUsers = trackClickRemoveUsers;
    vm.trackClickSendMessage = trackClickSendMessage;
    vm.trackClickShowMoreClick = trackClickShowMoreClick;
    vm.trackClickUser = trackClickUser;
    vm.updateSavedCheckedProps = updateSavedCheckedProps;
    vm.userActivatedInSdk =
      Boolean(vm.currentApp.activation.installed_sdk_ios) || Boolean(vm.currentApp.activation.installed_sdk_android);
    vm.userPropsMeta = []; //свойства опльзователя
    vm.users = []; //массив пользователей
    vm.usersLoading = true; //момент когда пользователи грузятся
    vm.usersTotal = 0; //общее число пользователей
    vm.validationHelper = validationHelper; //валидатор для поиска
    vm.messageTypesPopoverMap = {};

    init();

    function init() {
      trackViewUserPage();
      getCheckedProperties(properties);
      getOrder();
      firstValueFrom(userModel.getPinnedProps(vm.currentApp.id, vm.djangoUser.id, true)).then((pinnedProps) => {
        localStorage.setItem(LS_PINNED_PROPS, JSON.stringify(pinnedProps));
      });
      fixScrollForUserTable();

      $scope.$watch('vm.search', watchSearch);
      $scope.$watch('vm.selectedUsersAll', setSelectForAllUsers);
      $scope.$watch('vm.selectedUsersCounter', setIsSelectedUsersHasEmail);
      $scope.$on('$destroy', onDestroy);

      /**
       * Уничтожение всего
       */
      function onDestroy() {
        clearInterval(fixScrollForUserTableInterval);
      }

      /**
       * поиск пользователей
       *
       * @param {String} newValue
       * @param {String} oldValue
       */
      function watchSearch(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (angular.isDefined(newValue) && newValue.length <= 15 && newValue.length >= 3) {
            vm.searchFilters.filters.props = [];

            var propertyNames = ['$name', '$email', '$phone'];

            for (var i = 0; i < propertyNames.length; i++) {
              var searchProp = filterAjsModel.getDefaultFilterProp();

              searchProp.type = ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS;
              searchProp.value.value = newValue;
              searchProp.userProp = $filter('filter')(vm.userPropsMeta, { name: propertyNames[i] })[0];

              vm.searchFilters.filters.props.push(searchProp);
            }
          } else {
            vm.searchFilters = filterAjsModel.getDefaultOr();
          }
          vm.refreshUsers();
        }
      }

      function setIsSelectedUsersHasEmail() {
        if (vm.selectedUsersAll) {
          // если выбраны все пользователи - считаем, что email хотя бы у одного пользователя есть (даже если на самом деле его нету ни у одного из пользователей)
          vm.isSelectedUsersHasEmail = true;
        } else {
          vm.isSelectedUsersHasEmail = !!$filter('filter')(vm.users, { checked: true, props: { $email: '!!' } }).length;
        }
      }
    }

    /**
     * Изменение сортировки
     *
     * @param {String} sortProp Имя совйства по которому производиться сортировка
     */
    function changeOrder(sortProp) {
      if (sortProp == vm.sortProp) {
        vm.sortOrder = vm.sortOrder == 'desc' ? 'asc' : 'desc';
      } else {
        vm.sortOrder = 'desc';
        vm.sortProp = sortProp;
      }
      localStorage.setItem(
        'savedOrder:' + currentApp.id,
        JSON.stringify({
          sortOrder: vm.sortOrder,
          sortProp: vm.sortProp,
        }),
      );
      refreshUsers();
    }

    /**
     * Открытие модалки экспорта пользователей
     */
    function exportUsers() {
      if (!djangoUserModel.hasAccess(vm.currentApp.id, djangoUser, PERMISSIONS_FOR_ACTIONS.PERMISSIONS_EXPORT)) {
        openAccessDeniedModal();
        return;
      }

      var exportUsersModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('users.export.modal.heading'),
              body: $translate.instant('users.export.modal.body', { selectedUsersCounter: vm.selectedUsersCounter }),
              confirmButtonText: $translate.instant('users.export.modal.confirmButton'),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      exportUsersModal.result.then(runExport);

      function runExport() {
        trackStartExportUsers();

        var props = [],
          idsOrFilters;

        for (var i = 0; i < vm.userPropsMeta.length; i++) {
          if (vm.userPropsMeta[i].checked) {
            props.push(vm.userPropsMeta[i].name);
          }
        }

        if (vm.selectedUsersAll) {
          if (filterAjsModel.isDefault(vm.filters)) {
            idsOrFilters = vm.searchFilters;
          } else {
            idsOrFilters = vm.filters;
          }
        } else {
          idsOrFilters = $filter('map')($filter('filter')(vm.users, { checked: true }), 'id');
        }

        return firstValueFrom(userModel.exportUsers(currentApp.id, props, idsOrFilters)).then(
          exportSuccess,
          exportError,
        );

        function exportSuccess(response) {
          toastr.success($translate.instant('users.export.toasts.exportStarted'));
        }

        function exportError(response) {
          if (response.meta.error == 'HasExportInProcess') {
            toastr.warning($translate.instant('users.export.toasts.exportInProgress'));
          }
        }
      }
    }

    /**
     * Фикс виртуального скрола таблицы с лидами
     *
     * NOTE:
     *  Проблема:
     *  При скроле таблицы с лидами, возникает "дискотека" из покрашенных и не покрашенных строк.
     *  Это происходит из-за того, что таблица с лидами имеет класс table-striped, в котором закрышенные строки - это
     *  чётные строки. При скролинге таблицы из неё исчезают невидимые пользователю строки, соответсвенно
     *  нарушается их чётность и не чётность.
     *  Решение:
     *  При исчезновении элемента из таблицы лидов, добавлять/убирать фейковый tr-элемент, тем самым компинсируя
     *  изменения чётности.
     */
    function fixScrollForUserTable() {
      const vsScrollWrapperId = 'vs-scroll-wrapper';
      const vsBeforeContentSelector = `#${vsScrollWrapperId} .vs-repeat-before-content`;

      let vsBeforeContentNode = document.querySelector(vsBeforeContentSelector);
      let vsBeforeContentHeight = 0;

      // Таблица с лидами появляется не сразу, поэтому дожидаемся, когда она появится
      fixScrollForUserTableInterval = setInterval(() => {
        if (vsBeforeContentNode) {
          vsBeforeContentHeight = vsBeforeContentNode.offsetHeight;

          document.getElementById(vsScrollWrapperId).addEventListener('scroll', () => {
            let fakeTrClass = 'fake-tr-for-vs';

            // Если произошло изменение чётности и не чётности, добавляем или удаляем фейковый tr-элемент
            if (vsBeforeContentHeight !== vsBeforeContentNode.offsetHeight) {
              if (!document.querySelector(`tr.${fakeTrClass}`)) {
                let fixTr = document.createElement('tr');

                fixTr.className = fakeTrClass;

                vsBeforeContentNode.after(fixTr);
              } else {
                document.querySelector(`tr.${fakeTrClass}`).remove();
              }

              vsBeforeContentHeight = vsBeforeContentNode.offsetHeight;
            }
          });

          clearInterval(fixScrollForUserTableInterval);
        } else {
          vsBeforeContentNode = document.querySelector(vsBeforeContentSelector);
        }
      }, 500);
    }

    /**
     * Получение CSS-класса для иконки сортировки по свойству
     *
     * @param property Свойство
     * @returns {string}
     */
    function getCssClassSortIcon(property) {
      if (vm.sortOrder === 'desc' && property.name === vm.sortProp) {
        return 'cqi-sort-descending';
      }

      if (vm.sortOrder === 'asc' && property.name === vm.sortProp) {
        return 'cqi-sort-ascending';
      }

      return 'cqi-sort-descending';
    }

    /**
     * Получение столбцов таблицы
     *
     * @param {Object} properties - Свойства
     */
    function getCheckedProperties(properties) {
      vm.userPropsMeta = vm.userPropsMeta.concat(properties.userProps, properties.eventTypeProps);
      vm.filteredUserPropsAndEvents = getOrderedUserPropsAndEvents();

      var savedCheckedProps = localStorage.getItem('savedCheckedProps:' + currentApp.id);

      if (savedCheckedProps) {
        try {
          savedCheckedProps = JSON.parse(savedCheckedProps);
        } catch (e) {
          savedCheckedProps = DEFAULT_CHECKED_PROPERTIES;
        }
      } else {
        savedCheckedProps = DEFAULT_CHECKED_PROPERTIES;
      }

      for (var i = 0; i < vm.userPropsMeta.length; i++) {
        var prop = vm.userPropsMeta[i];

        if (prop.name === '$name' || savedCheckedProps[prop.name]) {
          prop.checked = true;
        } else {
          prop.checked = false;
        }
      }
    }

    /**
     * Получение строки с количеством выделеных пользователй из всех
     *
     * @returns {String} например: '2 / 10'
     */
    function getLeadsCount() {
      if (!vm.usersLoading) {
        if (vm.selectedUsersCounter) {
          return vm.selectedUsersCounter + ` ${$translate.instant('users.usersCounter')} ` + vm.usersTotal;
        } else if (vm.selectedUsersAll) {
          return vm.usersTotal + ` ${$translate.instant('users.usersCounter')} ` + vm.usersTotal;
        } else {
          return vm.usersTotal;
        }
      } else {
        return '';
      }
    }

    /**
     * Получение сортировки пользователей
     */
    function getOrder() {
      var defaultOrder = {
        sortProp: $filter('filter')(vm.userPropsMeta, { name: '$last_seen' })[0].checked ? '$last_seen' : '$name',
        sortOrder: 'desc',
      };
      var order = localStorage.getItem('savedOrder:' + currentApp.id) || defaultOrder;

      if (order) {
        try {
          order = JSON.parse(order);
          var isCheckedProp = $filter('filter')(vm.userPropsMeta, { name: order.sortProp })[0].checked;

          if (!isCheckedProp) {
            throw new Error();
          }
        } catch (e) {
          order = defaultOrder;
        }
      } else {
        order = defaultOrder;
      }

      vm.sortProp = order.sortProp;
      vm.sortOrder = order.sortOrder;
    }

    /** Сортирует пользовательские свойства и события по prettyName */
    function getOrderedUserPropsAndEvents() {
      return $filter('orderBy')(vm.userPropsMeta, 'prettyName');
    }

    /**
     * Получение пользователей
     *
     * @returns {Boolean} отмечены все пользователи или нет
     *
     * vm.selectedUsersAll надо возвращать т.к. getUsers() вызывается после загрузки пользователей  в ф-ии showMore()
     */
    function getUsers() {
      var params = {
        ignoreLoadingBar: true,
        sortProp: vm.sortProp,
        sortOrder: vm.sortOrder,
        limit: 20,
        offset: vm.offset,
        convertPropsTypes: false,
      };

      if (filterAjsModel.isDefault(vm.filters)) {
        params.filters = vm.searchFilters;
      } else {
        params.filters = vm.filters;
      }
      vm.usersLoading = true;

      return firstValueFrom(userModel.getList(currentApp.id, params)).then(getListSuccess);

      function getListSuccess(response) {
        vm.users = vm.users.concat(response.users);
        vm.users = uniqBy(vm.users, 'id');
        vm.usersTotal = response.total;
        vm.lastUsersCount = response.users.length;
        vm.usersLoading = false;
        vm.offset = vm.users.length;
        $timeout();
      }
    }

    /**
     * Открытие модалки "нет доступа"
     */
    function openAccessDeniedModal() {
      $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              imgUrl: 'assets/img/default/no-access.png',
              heading: $translate.instant('users.accessDeniedModal.heading'),
              body: $translate.instant('users.accessDeniedModal.body'),
              confirmButtonText: 'OK',
              cancelButtonClass: 'hide',
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });
    }

    /**
     * Открытие модалки уведомляющей о превышении ручной рассылки
     */
    function openCapabilitySendBulkModal() {
      const limitAmount = planFeatureAccessService.getAccess(
        PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND,
        vm.currentApp,
        vm.selectedUsersCounter,
      ).denialReason.limitAmount;

      const modal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('users.planCapability.manualMessagesBulkSendModal.heading'),
              body: $translate.instant(
                'users.planCapability.manualMessagesBulkSendModal.body',
                {
                  amount: limitAmount,
                },
                'messageformat',
              ),
              confirmButtonText: $translate.instant(
                'users.planCapability.manualMessagesBulkSendModal.confirmButtonText',
              ),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      modal.result.then(() => {
        vm.paywallService.showPaywall(vm.currentApp, vm.accessToManualMessagesBulkSend.denialReason);
      });
    }

    /**
     * Открытие модалки с добавлением тега для выбраных пользователей
     */
    function openAddTag() {
      var addTagDialog = $uibModal.open({
        controller: 'AddTagModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              body: $translate.instant('users.addTag.modal.body'),
            };
          },
          tagList: function () {
            return $filter('filter')(vm.tags, { removed: '!' });
          },
        },
        templateUrl: 'js/shared/modals/add-tag/add-tag.html',
      });

      addTagDialog.result.then(setTagToUsers);

      function setTagToUsers(tag) {
        if (vm.selectedUsersAll) {
          if (filterAjsModel.isDefault(vm.filters)) {
            firstValueFrom(tagModel.setToUsers(currentApp.id, vm.searchFilters, tag.name, vm.tags))
              .then(setToUsersSuccess)
              .then(updateTagsList);
          } else {
            firstValueFrom(tagModel.setToUsers(currentApp.id, vm.filters, tag.name, vm.tags))
              .then(setToUsersSuccess)
              .then(updateTagsList);
          }
        } else if (vm.selectedUsersCounter) {
          firstValueFrom(
            tagModel.setToUsers(
              currentApp.id,
              $filter('map')($filter('filter')(vm.users, { checked: true }), 'id'),
              tag.name,
              vm.tags,
            ),
          )
            .then(setToUsersSuccess)
            .then(updateTagsList);
        }

        function setToUsersSuccess() {
          trackTagAdded();
          toastr.success($translate.instant('users.addTag.toasts.tagAdded'));
        }

        function updateTagsList() {
          firstValueFrom(tagModel.getList(currentApp.id)).then(getTagListSuccess);

          function getTagListSuccess(tags) {
            vm.tags = tags;
          }
        }
      }
    }

    /**
     * Открытие карточки пользователя
     *
     * @param {String} userId ID пользователя
     */
    function openCard(userId) {
      var userCardModal = $uibModal.open({
        component: 'cqUserCardModal',
        resolve: {
          modalWindowParams: () => {
            return {
              billingInfo: billingInfo,
              currentApp: currentApp,
              djangoUser: djangoUser,
              onRedirectConversationClick: true,
              userId: userId,
              telegramIntegrations: telegramIntegrations,
            };
          },
        },
        size: 'lg',
        windowClass: 'user-card-modal',
      });

      userCardModal.result.then(userCardModalClosed);

      function userCardModalClosed(removedUserID) {
        // если пришло значение - значит, это userId, и значит, что пользователя с таким userId удалили в модалке. Поэтому его нужно удалить из общего списка пользователей
        if (removedUserID) {
          var removedUserIndex = vm.users.indexOf($filter('filter')(vm.users, { id: data.value }, true)[0]);
          if (~removedUserIndex) {
            vm.users.splice(removedUserIndex, 1);
          }
        }
      }
    }

    /**
     * Открытие модалки ручной склейки
     */
    function openManualUserMergeModal(users) {
      var filteredUsers = $filter('filter')(users, { checked: true });
      var modalInstance = $uibModal.open({
        component: 'cqManualUserMerge',
        resolve: {
          currentApp: angular.bind(null, angular.identity, currentApp),
          users: angular.bind(null, angular.identity, filteredUsers),
        },
        size: 'lg modal-dialog-scrollable',
      });

      modalInstance.result.then(function (newUserId) {
        if (angular.isDefined(newUserId)) {
          toastr.success(
            $translate.instant('modals.manualUserMerge.toasts.success', {
              url: $state.href('app.content.users.detail', { userId: newUserId }),
            }),
            { allowHtml: true },
          );
          trackUsersMerged();
          refreshUsers();
        }
      });
    }

    /**
     * Открытие модалки с отправкой одного из типа сообщений пользователю
     *
     * @param {String} type тип отправляемого сообщения
     */
    function openSendMessageModal(type) {
      if (!djangoUserModel.hasAccess(vm.currentApp.id, djangoUser, PERMISSIONS_FOR_ACTIONS.PERMISSIONS_SEND_BULK)) {
        openAccessDeniedModal();
        return;
      }

      if (!vm.accessToManualMessagesBulkSend.hasAccess) {
        openCapabilitySendBulkModal();
        return;
      }

      if (vm.selectedUsersAll && !vm.searchForm.$valid) {
        return;
      }

      var names = [],
        writeManualIds,
        writeUserCounterHint,
        writeUserNamesHint,
        writeFilters;

      if (vm.selectedUsersAll) {
        for (var i = 0; i < 3; i++) {
          if (vm.users[i]) {
            names.push(vm.users[i].name);
          }
        }
        if (filterAjsModel.isDefault(vm.filters)) {
          writeFilters = vm.searchFilters;
        } else {
          writeFilters = vm.filters;
        }

        writeManualIds = [];
        writeUserCounterHint = vm.usersTotal;
        writeUserNamesHint = names.length > 1 ? names.join(', ') + ', ...' : names[0];
      } else {
        var filteredUsers = $filter('filter')(vm.users, { checked: true });

        names = $filter('map')(filteredUsers, 'name');

        writeManualIds = $filter('map')(filteredUsers, 'id');
        writeFilters = '{}';
        writeUserCounterHint = writeManualIds.length;
        writeUserNamesHint = names.join(', ');
      }

      var modalInstance = $uibModal.open({
        backdrop: 'static',
        controller: 'SendManualMessageModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/send-manual-message/send-manual-message.html',
        resolve: {
          billingInfo: angular.bind(null, angular.identity, billingInfo),
          currentApp: angular.bind(null, angular.identity, currentApp),
          djangoUser: angular.bind(null, angular.identity, djangoUser),
          manualMessageParams: function () {
            return {
              filters: writeFilters,
              manualIds: writeManualIds,
              type: type,
              usersCount: writeUserCounterHint,
              userNames: writeUserNamesHint,
              telegramIntegrations: telegramIntegrations,
            };
          },
        },
        size: 'right',
        windowClass: 'manual-message',
      });

      modalInstance.result.then(function (data) {
        toastr.success($translate.instant('users.sendMessageModal.toasts.messageSent'));
      });
    }

    /**
     * Проверка доступа по продуктовой фиче
     * @param {MESSAGE_PART_TYPES} partType
     * @returns {boolean}
     */
    function hasAccessByMessageType(partType) {
      const productFeature = PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.MANUAL][partType];
      const hasAccessToMessageType = planFeatureAccessService.getAccess(productFeature, vm.currentApp).hasAccess;
      const hasAccessToBulkSend = planFeatureAccessService.getAccess(
        PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND,
        vm.currentApp,
        vm.selectedUsersCounter,
      ).hasAccess;

      return hasAccessToMessageType && hasAccessToBulkSend;
    }

    /**
     * Открытие модалки отправки пользователям письма подтверждения подписки
     */
    function openSendSubscriptionConfirmationEmailModal() {
      var modalInstance, users;

      if (vm.selectedUsersAll) {
        if (filterAjsModel.isDefault(vm.filters)) {
          users = vm.searchFilters;
        } else {
          users = vm.filters;
        }
      } else {
        users = $filter('filter')(vm.users, { checked: true });
      }

      modalInstance = $uibModal.open({
        controller: 'SendSubscriptionConfirmationEmailModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/send-subscription-confirmation-email/send-subscription-confirmation-email.html',
        resolve: {
          currentApp: angular.bind(null, angular.identity, currentApp),
          messageSender: angular.bind(messageSenderModel, messageSenderModel.getNoReply, currentApp.language),
          users: angular.bind(null, angular.identity, users),
          usersCount: angular.bind(null, angular.identity, vm.selectedUsersCounter),
        },
      });

      modalInstance.result.then(showSuccessToast);

      function showSuccessToast() {
        toastr.success(
          $translate.instant('users.sendSubscriptionConfirmationModal.toasts.subscriptionConfirmationEmailSent'),
        );
      }
    }

    /** Редирект на стартергайд */
    function redirectToStarterGuide() {
      $state.go('app.content.dashboard', {
        step: STARTER_GUIDE_STEPS.INSTALLATION_SERVICE,
      });
    }

    /**
     * Изменение количества выделенных пользователей
     */
    function refreshSelectedUsersCount() {
      if (vm.selectedUsersAll) {
        vm.selectedUsersCounter = vm.usersTotal;
      } else {
        vm.selectedUsersCounter = $filter('filter')(vm.users, { checked: true }).length;
      }

      vm.accessToManualMessagesBulkSend = planFeatureAccessService.getAccess(
        PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND,
        vm.currentApp,
        vm.selectedUsersCounter,
      );
    }

    /**
     * Клик на кнопке обновления пользователей
     */
    function refreshUsersButton() {
      if (!vm.usersLoading) {
        vm.refreshUsers();
      }
    }

    /**
     * Обновление пользователей
     */
    function refreshUsers() {
      vm.offset = 0;
      vm.users = [];
      vm.selectedUsersAll = false;
      vm.selectedUsersCounter = 0;
      return getUsers();
    }

    /**
     * Открытие модалки удаления пользователей
     */
    function removeUsers() {
      var count = vm.selectedUsersCounter;

      var removeUsersModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('users.removeUsers.modal.heading', { count: count }, 'messageformat'),
              body: $translate.instant('users.removeUsers.modal.body', { count: count }, 'messageformat'),
              confirmButtonText: $translate.instant(
                'users.removeUsers.modal.confirmButton',
                { count: count },
                'messageformat',
              ),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      removeUsersModal.result.then(runRemove);

      function runRemove() {
        var usersIdsOrFilters;

        if (vm.selectedUsersAll) {
          if (filterAjsModel.isDefault(vm.filters)) {
            usersIdsOrFilters = vm.searchFilters;
          } else {
            usersIdsOrFilters = vm.filters;
          }
        } else {
          usersIdsOrFilters = $filter('map')($filter('filter')(vm.users, { checked: true }), 'id');
        }

        return firstValueFrom(userModel.remove(currentApp.id, usersIdsOrFilters)).then(removeUsersSuccess);

        function removeUsersSuccess() {
          toastr.success($translate.instant('users.removeUsers.toasts.usersRemoved'));
          trackUsersRemoved();
          refreshUsers();
        }
      }
    }

    /**
     * Отметка или снятие галочек с пользователей
     *
     * @param {Boolean} isChecked
     */
    function setSelectForAllUsers(isChecked) {
      angular.forEach(vm.users, function (user) {
        user.checked = isChecked;
      });
      vm.refreshSelectedUsersCount();
    }

    /**
     * Загрузка дополнительных пользователей
     */
    function showMore() {
      getUsers().then(function () {
        if (vm.selectedUsersAll) {
          setSelectForAllUsers(vm.selectedUsersAll);
        }
      });
    }

    /**
     * Трек клика на "Добавить теги пользователям"
     */
    function trackClickAddTag() {
      carrotquestHelper.track('Пользователи - клик на "Добавить тег"');
    }

    /**
     * Трек клика на "Экспортировать пользователей"
     */
    function trackClickExportUsers() {
      carrotquestHelper.track('Пользователи - клик на "Экспорт"');
    }

    /**
     * Трек клика на "Импортировать пользователей"
     */
    function trackClickImportUsers() {
      carrotquestHelper.track('Пользователи - клик на "Импорт лидов "', {
        App: vm.currentApp.name,
      });
    }

    /**
     * Трек клика по кнопке «Установить скрипт»
     */
    function trackClickInstallCode() {
      carrotquestHelper.track('button_clicked', {
        page_url: $window.location.pathname,
        type: 'install code',
      });
    }

    /**
     * Трек клика на "Склеить двух пользователей"
     */
    function trackClickManualUserMerge() {
      carrotquestHelper.track('Пользователи - кликнул на "Склеить двух пользователей"', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }

    /**
     * Трек клика на строку поиска
     */
    function trackClickOnSearch() {
      carrotquestHelper.track('Пользователи - кликнул на поиск');
    }

    /**
     * Трек клика на кнопку обновления пользователей
     */
    function trackClickRefreshUsers() {
      carrotquestHelper.track('Пользователи - обновить');
    }

    /**
     * Трек клика на "Скрыть пользователей"
     */
    function trackClickRemoveUsers() {
      carrotquestHelper.track('Пользователи - кликнул на "Скрыть пользователей"', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }

    /**
     * Трек клика отправки одного из типа сообщений пользователю
     *
     * @param {MESSAGE_PART_TYPES} type тип отправляемого сообщения
     */
    function trackClickSendMessage(type) {
      switch (type) {
        case MESSAGE_PART_TYPES.POPUP_CHAT:
          carrotquestHelper.track('Пользователи - кликнул "Написать в чат"');
          break;
        case MESSAGE_PART_TYPES.POPUP_SMALL:
          carrotquestHelper.track('Пользователи - кликнул "Показать попап"');
          break;
        case MESSAGE_PART_TYPES.EMAIL:
          carrotquestHelper.track('Пользователи - кликнул "Написать емейл"');
          break;
        case MESSAGE_PART_TYPES.PUSH:
          carrotquestHelper.track('Пользователи - кликнул "Отправить Web Push"');
          break;
      }
    }

    /**
     * Трек клика на "Запустить экспорт"
     */
    function trackStartExportUsers() {
      carrotquestHelper.track('Пользователи - клик на "Запустить экспорт"', {
        users_counter: vm.selectedUsersCounter,
      });
    }

    /**
     * Трек посещения раздела Лиды
     */
    function trackViewUserPage() {
      carrotquestHelper.track('Пользователи - зашел');
    }

    /**
     * Трек клика на показать еще
     */
    function trackClickShowMoreClick() {
      carrotquestHelper.track('Пользователи - показать еще');
    }

    /**
     * трек клика на пользователя
     */
    function trackClickUser() {
      carrotquestHelper.track('Лиды - клик на карточку пользователя');
    }

    /**
     * Трек добавления тега
     */
    function trackTagAdded() {
      carrotquestHelper.track('Пользователи - добавил тег');
    }

    /**
     * Трек склейки пользователей
     */
    function trackUsersMerged() {
      carrotquestHelper.track('Пользователи - склеил двух пользователей', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }

    /**
     * Трек скрытия пользователей
     */
    function trackUsersRemoved() {
      carrotquestHelper.track('Пользователи - скрыл пользователей', {
        app: vm.currentApp.name,
        'app id': vm.currentApp.id,
      });
    }

    /**
     * Обновление выбранных столбцов в localStorage
     */
    function updateSavedCheckedProps() {
      var savedCheckedProps = {};

      for (var i = 0; i < vm.userPropsMeta.length; i++) {
        if (vm.userPropsMeta[i].checked) {
          savedCheckedProps[vm.userPropsMeta[i].name] = 1;
        }
      }

      localStorage.setItem('savedCheckedProps:' + currentApp.id, JSON.stringify(savedCheckedProps));
      $scope.$apply();
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { ChatWidgetOverheaderComponent } from './chat-widget-overheader.component';

@NgModule({
  declarations: [ChatWidgetOverheaderComponent],
  exports: [ChatWidgetOverheaderComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, SharedModule, FormsModule],
})
export class ChatWidgetOverheaderModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { SharedModule } from '@panel/app/shared/shared.module';

import { EditBillingEmailModalComponent } from './edit-billing-email-modal.component';

@NgModule({
  declarations: [EditBillingEmailModalComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, SharedModule],
  providers: [NgbActiveModal, SubscriptionStore],
})
export class EditBillingEmailModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from '@panel/app/shared/shared.module';

import { UserSystemsPropsComponent } from './user-systems-props.component';

@NgModule({
  declarations: [UserSystemsPropsComponent],
  exports: [UserSystemsPropsComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, NgbCollapseModule, ClipboardModule, NgbTooltipModule],
})
export class UserSystemsPropsModule {}

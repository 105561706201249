import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cq-ai-data-settings-banner',
  templateUrl: './ai-data-settings-banner.component.html',
  styleUrls: ['./ai-data-settings-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiDataSettingsBannerComponent {
  @Output()
  clickCloseButton: EventEmitter<void> = new EventEmitter();

  constructor() {}

  onClickCloseButton(): void {
    this.clickCloseButton.emit();
  }
}

<ng-container *transloco="let t; read: 'carrotNotificationFrameComponent'">
  <div class="carrot-frame-body feature_start-screen flex align-items-end">
    <div
      id="notification-container"
      class="notice-wrap"
    >
      <div
        #screenshotArea
        id="notification-content"
        class="notice-wrap__inner flex flex-col cursor-pointer"
      >
        <div
          id="notification-header"
          class="flex align-items-start"
        >
          <div
            id="operator-container"
            class="flex flex-grow overflow-hidden"
          >
            <div
              id="avatar-container"
              class="flex"
              [style]="{
                'background-color': app.settings.messenger_collapsed_color,
                'border-radius': '9999px'
              }"
            >
              <img
                id="operator-avatar"
                [src]="getSenderAvatarPath()"
                [style]="{ 'border-radius': '9999px' }"
                alt=""
              />
            </div>
            <div
              id="operator-info"
              class="flex flex-col overflow-hidden"
            >
              <div
                id="operator-name"
                class="text-truncate"
              >
                {{ getSenderName() }}
              </div>
              <div id="message-time">09:41</div>
            </div>
          </div>
          <button
            id="notification-close"
            class="btn btn-text-primary"
          >
            <span class="close-icon close-icon_notification"></span>
          </button>
        </div>

        <div
          id="notification-message"
          class="scroll-a"
          [style]="{
            'scrollbar-color':
              'rgba(' +
              getRgbFromHex(app.settings.messenger_collapsed_color) +
              ', 0.4) rgba(' +
              getRgbFromHex(app.settings.messenger_collapsed_color) +
              ', 0)'
          }"
        >
          <div class="flex notice-item start-part">
            <div
              data-id="1729494956617238268"
              class="notice-item__inner"
            >
              <div class="notice-item__height-indicator">
                <span
                  class="part-text"
                  [innerHTML]="content"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="notification-input-container"
        style=""
        class="notice-footer"
      >
        <div id="notification-textarea-container">
          <div
            id="notification-textarea-wrap"
            [style]="{ 'line-height': '0' }"
          >
            <div id="carrotquest-file-preview-list"></div>
            <textarea
              id="message-input"
              class="scroll-a"
              [value]="t('messageInputPlaceholder.' + language + '.inputPlaceholder')"
            ></textarea>
            <div class="message_sending">Сообщение отправляется…</div>
            <div class="message_sent">Сообщение отправлено</div>
            <div class="carrotquest-messenger-reply-fake-message-input scroll-a"></div>
            <label
              id="attach-file-icon"
              class="icon-button cqi-chat cqi-attach"
            >
              <input
                id="attach-file-input"
                hidden=""
                class="textfield__attach-input"
                type="file"
                accept=".csv, .doc, .docx, .gif, .html, .jpeg, .jpg, .m4v, .mov, .mp3, .mp4, .mpeg, .pdf, .png, .rar, .rtf, .sig, .tif, .tiff, .txt, .xls, .xlsx, .zip"
              />
            </label>

            <button
              class="button button_xs hidden accent"
              id="send-message"
            >
              <span class="button__icon cqi-chat cqi-arrow-up"></span>
            </button>

            <div id="message-sending-loader">
              <i class="cqi-chat cqi-spinner"></i>
            </div>
            <div id="message-sent-icon">
              <i class="cqi-chat cqi-check-circle-o"></i>
            </div>
          </div>
          <div
            id="notification-actions-wrap"
            style="padding: 0px"
          ></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

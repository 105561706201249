import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil, takeWhile } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { DjangoUser } from '@http/django-user/django-user.types';
import { DestroyService, OneTimeFlagService } from '@panel/app/services';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { UtilsService } from '@panel/app-old/shared/services/utils/utils.service';

/**
 * Компонент логотипа в навигации
 */
@Component({
  selector: 'cq-navigation-logo[djangoUser]',
  templateUrl: './navigation-logo.component.html',
  styleUrls: ['./navigation-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class NavigationLogoComponent implements AfterViewInit {
  /** Текущий пользователь */
  @Input()
  djangoUser!: DjangoUser;

  /**
   * Инстанс тултипа про настройку сервиса
   * NOTE Для операторов инстанса не будет
   */
  @ViewChild('starterGuideTooltip')
  starterGuideTooltip?: NgbTooltip;

  /** Состояния при которых пункт меню будет подсвечиваться как активный */
  @Input()
  states: string[] = [];

  /** Состояние, на которое нужно перейти при нажатии на пункт меню */
  @Input()
  stateTo: string = '';

  /** Callback на скрытие тултипа */
  @Output()
  hideStarterGuidTooltip: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly appService: AppService,
    private readonly destroy$: DestroyService,
    private readonly oneTimeFlagService: OneTimeFlagService,
    private readonly utilsService: UtilsService,
  ) {}

  ngAfterViewInit() {
    if (this.starterGuideTooltip) {
      // Был ли уже когда-то показан тултип про настройку сервиса
      const isStarterGuideTooltipHasBeenShown: boolean = this.oneTimeFlagService.isSet(
        ONE_TIME_FLAGS.STARTER_GUIDE_LOGO_TOOLTIP,
      );

      this.utilsService.isStarterGuideTooltipOpened
        .pipe(
          takeUntil(this.destroy$),
          takeWhile(() => !isStarterGuideTooltipHasBeenShown),
        )
        .subscribe((value) => {
          if (value) {
            this.starterGuideTooltip?.open();
          } else {
            this.starterGuideTooltip?.close();
          }
        });
    }
  }

  /** Обработчик на закрытие тултипа про настройку сервиса */
  onHideStarterGuideTooltip() {
    this.oneTimeFlagService.set(ONE_TIME_FLAGS.STARTER_GUIDE_LOGO_TOOLTIP);
    this.utilsService.isStarterGuideTooltipOpened.next(false);
    this.hideStarterGuidTooltip.emit();
  }
}

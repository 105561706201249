<ng-container *transloco="let t; read: 'chatBotTemplateComponent'">
  <button
    class="close position-absolute right-0 p-20"
    (click)="ajsModalInstance.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>

  <ng-container *ngIf="viewStateService.state$ | async as state">
    <ng-container [ngSwitch]="state.view">
      <ng-container *ngSwitchCase="'preview'">
        <cq-template-preview
          *ngIf="state.template"
          (clickBackButton)="onClickBackButton($event)"
          (clickSelectTemplate)="onClickSelectTemplate($event)"
          [template]="state.template"
          [isCreate]="modalWindowParams.isNewBot"
        ></cq-template-preview>
      </ng-container>
      <ng-container *ngSwitchCase="'list'">
        <div class="modal-body">
          <h3 class="modal-title mb-15">{{ t('title') }}</h3>
          <p class="text-secondary mb-20">{{ t('subtitle') }}</p>
          <cq-template-list
            [templates]="modalWindowParams.templates"
            (clickTemplate)="onClickTemplate($event)"
            (clickTemplateZero)="onClickTemplateZero()"
          ></cq-template-list>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { PricingOldModule } from '@panel/app/pages/subscription/pricing-old/pricing-old.module';

import { PricingModule } from '../pricing/pricing.module';
import { PricingWrapperComponent } from './pricing-wrapper.component';

@NgModule({
  declarations: [PricingWrapperComponent],
  imports: [CommonModule, TranslocoModule, UIRouterModule, PricingModule, PricingOldModule],
  exports: [PricingWrapperComponent],
})
export class PricingWrapperModule {}

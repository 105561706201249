/**
 * Преобразование массива из объектов в map
 *
 * @example
 * // вернёт {'amoFieldValue1': 'cqFieldValue1', 'amoFieldValue2': 'cqFieldValue2'}
 * arrayToMap([
 *  {amoName: 'amoFieldValue1', cqName: 'cqFieldValue1'},
 *  {amoName: 'amoFieldValue2', cqName: 'cqFieldValue2'},
 * ])
 *
 * @param array Массив объектов
 * @param keyName Название ключа с названием ключа в объектах массивов
 * @param valueName Название ключа со значением в объектах массивов
 */
export const arrayToMap = (array: any[], keyName = 'key', valueName = 'value') => {
  return array.reduce((map: any, item: any) => {
    const key = item[keyName];
    const value = item[valueName];

    map[key] = value;

    return map;
  }, {});
};

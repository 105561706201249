import { TranslocoService } from '@jsverse/transloco';
import { FederatedPointerEvent } from '@pixi/events/lib/FederatedPointerEvent';
import { Container, Graphics, LINE_CAP, LINE_JOIN } from 'pixi.js';
import { Simple } from 'pixi-cull';
import { combineLatest, fromEvent, merge, Observable, partition, Subject, switchMap } from 'rxjs';
import { filter, map, startWith, takeUntil, tap, throttleTime, timestamp } from 'rxjs/operators';
import { generate } from 'short-uuid';

import { ConnectionValidatorService } from '@panel/app/pages/chat-bot/content/services/connection-validator.service';
import {
  filterAndExtractInstance,
  pickOnlyEvent,
} from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.helpers';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { PIXI_INTERACTION_EVENT } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.types';
import { PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { BaseActionABS } from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { BlockType, Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { BOT_MESSAGE_BLOCK_PRIMARY_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { decreaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/decrease-ticker-max-fps.function';
import { increaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/increase-ticker-max-fps.function';
import { ZoomedEventData } from '@panel/app/shared/types/zoomed-event-data.type';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

export interface IPoint {
  x: number;
  y: number;
}

export const VIEWPORT_ON_ZOOM_THROTTLE = 500;

export interface Connectable {
  readonly coordinates: IPoint;
  destroy$: Observable<void>;
  rerenderConnection$?: Observable<void>;
  destroyed: boolean;
  height: number;
  uid?: string;
  width: number;
  /**
   * Основной тип блока, для определения могут ли друг к другу коннектиться блоки (пока так)
   */
  blockType: BlockType;
}

export interface ConnectionTarget extends Connectable {
  readonly connectionPoint: IPoint;
}

export interface ConnectionSource extends Connectable {
  connectionPoint?: Container;
  connectionPointGlobalCoordinates?: IPoint;

  addConnectionInfo?(connection: Connection): void;
  deleteConnectionInfo?(connection: Connection): void;
  updateConnectionTarget?(uid: string | null): void;
}

// TODO: Сделать класс абстрактным, когда понадобиться соединять что-то кроме действий и веток.
//  По большому счету тут все и так уже абстрактно, кроме метода startDrawing (ну и скорее еще где-нибудь найдется)
export class Connection extends Graphics {
  source?: ConnectionSource;
  target?: ConnectionTarget;
  /**
   * Создание связи в процессе (клиент тянет стрелку куда-то)
   */
  private drawing = false;

  /**
   * Эмиттер уничтожения канвас связи
   */
  destroy$ = new Subject<void>();

  /**
   * Т.к. при движении курсора линия перерисовывается нужно сохранять старую, чтоб вызвать у нее destroy()
   */
  private connLine?: Graphics;

  /**
   * Триггер для rxjs пайпа takeUntil()
   */
  private stopDraw$ = new Subject<void>();

  uid = generate();

  private _inProcessOfRedrawing: boolean = false;

  get inProcessOfRedrawing(): boolean {
    return this._inProcessOfRedrawing;
  }

  constructor(
    private readonly container: Container,
    private readonly cull: Simple,
    private readonly connections: Connection[],
    private readonly document: Document,
    private readonly interactionService: PixiInteractionService,
    private readonly connectionValidatorService: ConnectionValidatorService,
    private readonly toastService: ToastService,
    private readonly transloco: TranslocoService,
    private readonly pixiApp: PixiApplication,
  ) {
    super();
    connections.push(this);
  }

  /**
   * Добавляем ветку в связь, если есть source, то значит связь "закрываем"
   */
  addConnectionSide(element: ConnectionSource | ConnectionTarget): void {
    function isConnectionSource(el: any): el is ConnectionSource {
      // NOTE не очень надежный метод т.к. если у target появится такое же поле, все сломается
      return 'connectionPointGlobalCoordinates' in el;
    }

    if (isConnectionSource(element)) {
      this.source = element;
      this.startDrawing(element.connectionPoint!);
    } else {
      this.target = element;
      this.finalizeLine();
      this.initChangesListener();
    }
  }

  /**
   * Заканчиваем отрисовку, удаляем существующие связи
   */
  dropConnection(): void {
    this.source?.deleteConnectionInfo!(this);
    this.stopDrawing();
    this.connLine?.destroy();
    this.source = undefined;
    this.target = undefined;
    this.destroy$.next();
    this.destroy$.complete();
    const index = this.connections.findIndex((conn) => conn.uid === this.uid);
    this.connections.splice(index, 1);
  }

  startDrawToNewTarget() {
    this._inProcessOfRedrawing = true;
    this.startDrawing(this.source?.connectionPoint!);
  }

  /**
   * Удаляем предыдущую линию, рисуем новую по двум точкам.
   * Bezier кривая по 4 точкам, понятно объяснено тут https://javascript.info/bezier-curve.
   * Pixi's bezierCurveTo позволяет указать только три точки, подразумевая, что рисуется всегда от x:0 y:0,
   * поэтому добавлена такая штука как getCoord, которая пересчитывает [x,y] точки так, будто график рисуется от "0, 0"
   */
  private drawLine(from: IPoint, to: IPoint): void {
    const arrowWidth = 4;
    const arrowHeight = 3;

    if (this.connLine) {
      this.connLine?.clear();
    } else {
      this.connLine = new Graphics();
      this.connLine.zIndex = 0;
      this.container.addChild(this.connLine);
    }

    const getCoord = (point: IPoint): [x: number, y: number] => {
      return [point.x - from.x, point.y - from.y];
    };
    const bezierIndent = Math.max(Math.abs(to.x - from.x) * 0.3, 75);
    const bezierP2: IPoint = {
      x: from.x + bezierIndent,
      y: from.y,
    };
    const bezierP3: IPoint = {
      x: to.x - bezierIndent,
      y: to.y,
    };
    const arrowTopSide: IPoint = {
      x: to.x - arrowWidth,
      y: to.y - arrowHeight,
    };
    const arrowBottomSide: IPoint = {
      x: to.x - arrowWidth,
      y: to.y + arrowHeight,
    };
    // Рисует линию между ветками
    this.connLine.lineTextureStyle({
      width: 1,
      color: BOT_MESSAGE_BLOCK_PRIMARY_COLOR,
      cap: LINE_CAP.ROUND,
      join: LINE_JOIN.ROUND,
    });
    this.connLine.bezierCurveTo(...getCoord(bezierP2), ...getCoord(bezierP3), ...getCoord(to));
    // Рисует стрелку в конце
    this.connLine.moveTo(...getCoord(arrowTopSide));
    this.connLine.lineTo(...getCoord(to));
    this.connLine.lineTo(...getCoord(arrowBottomSide));

    this.connLine.position.x = from.x;
    this.connLine.position.y = from.y;
    this.cull.updateObject(this.connLine);
  }

  /**
   * Заканчиваем отрисовку, перерисовывам связь с учетом координат двух блоков
   */
  private finalizeLine(): void {
    this.stopDrawing();
    this.source?.addConnectionInfo!(this);
    this.target?.destroy$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dropConnection();
    });
    this.redraw();
  }

  /**
   * Инициализация подписки на изменения для которых нужна перерисовка связи
   */
  private initChangesListener(): void {
    if (!this.source?.rerenderConnection$ || !this.target?.rerenderConnection$) {
      return;
    }
    const [withoutThrottle$, withThrottle$] = partition(
      fromEvent<ZoomedEventData>(this.container, 'zoomed'),
      (val: ZoomedEventData) => !!val.preventThrottle,
    );

    merge(
      this.source.rerenderConnection$,
      this.target.rerenderConnection$,
      merge(
        withThrottle$.pipe(throttleTime(VIEWPORT_ON_ZOOM_THROTTLE, undefined, { trailing: true })),
        withoutThrottle$,
      ),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.redraw();
      });
  }

  /**
   * Перерисовать связь по двум известным концам связи
   */
  redraw() {
    const sourcePoint: IPoint = {
      x: this.source!.connectionPointGlobalCoordinates!.x,
      y: this.source!.connectionPointGlobalCoordinates!.y,
    };

    if (!this.target || this.target.destroyed) {
      return;
    }
    const targetPoint: IPoint = {
      x: this.target.connectionPoint.x,
      y: this.target.connectionPoint.y,
    };
    this.drawLine(sourcePoint, targetPoint);
  }

  /**
   * Начинаем "слушать" движения курсора, на каждый emit перерисовываем
   */
  private startDrawing(connectionSource: Container): void {
    this.drawing = true;

    if (!this.source) {
      throw new Error('Could not find connection source');
    }

    const resetBlockState = (block: Branch) => {
      this.document.body.style.cursor = 'auto';
      block.hover = false;
      block.valid = block.form.untouched || block.form.valid;
    };

    let lastHoveredBlock: Branch | null = null;
    /**
     * Собираем поток, который говорит нам о последней ветке, которая была в hover, при этом говорит, hovered ли она сейчас.
     * То есть, если branchChanged = true - то сейчас ховер над веткой, если нет - то над viewport
     */
    const currentHoveredBlock$ = combineLatest([
      this.interactionService.hover$.pipe(filterAndExtractInstance(PIXI_INTERACTION_EVENT.BRANCH)).pipe(
        // т.к. в самом начале мы ни над какой веткой не ховерим - кидаем null для combineLatest
        startWith(null, null),
        timestamp(),
      ),
      this.interactionService.hover$.pipe(filterAndExtractInstance(PIXI_INTERACTION_EVENT.VIEWPORT)).pipe(timestamp()),
    ]).pipe(
      increaseTickerMaxFPS(this.pixiApp),
      map(([branchData, viewportData]) => {
        const { value: lastHoveredBranch, timestamp: branchTS } = branchData;
        const { timestamp: viewportTS } = viewportData;
        return branchTS > viewportTS ? lastHoveredBranch : null;
      }),
      tap((currentHoveredBlock) => {
        if (lastHoveredBlock) {
          resetBlockState(lastHoveredBlock);
        }

        if (!currentHoveredBlock) {
          return;
        }

        if (this.connectionValidatorService.elementsAreConnectable(this.source!, currentHoveredBlock)) {
          currentHoveredBlock.hover = true;
        } else {
          currentHoveredBlock.valid = false;
          this.document.body.style.cursor = 'not-allowed';
        }
        lastHoveredBlock = currentHoveredBlock;
      }),
    );

    let lastEvent: FederatedPointerEvent;
    /**
     * Слушаем перемещения курсора и изменения ховера
     */
    combineLatest([
      fromEvent(connectionSource, 'pointermove').pipe(
        switchMap(() => fromEvent<FederatedPointerEvent>(this.container, 'pointermove')),
      ),
      currentHoveredBlock$,
    ])
      .pipe(
        takeUntil(this.stopDraw$),
        map(([event, _]) => event ?? lastEvent),
        filter((event) => Boolean(event)),
      )
      .pipe(takeUntil(this.stopDraw$))
      .subscribe((event) => {
        const sourcePoint: IPoint = {
          x: this.source!.connectionPointGlobalCoordinates!.x,
          y: this.source!.connectionPointGlobalCoordinates!.y,
        };
        const targetPoint: IPoint = this.container.toLocal(event.global);
        this.drawLine(sourcePoint, targetPoint);
        lastEvent = event;
      });

    /**
     * Дропаем connection если был pointerUp на viewport, на ветке прерывания или на ветке источнике связи
     */
    merge(
      this.interactionService.pointerUp$.pipe(pickOnlyEvent(PIXI_INTERACTION_EVENT.VIEWPORT)),
      this.interactionService.pointerUp$.pipe(
        filterAndExtractInstance(PIXI_INTERACTION_EVENT.BRANCH),
        tap((block) => resetBlockState(block)),
        tap((block) => this.showError(block)),
        filter((branch) => {
          return !this.connectionValidatorService.elementsAreConnectable(this.source!, branch);
        }),
      ),
      this.interactionService.pointerUp$.pipe(pickOnlyEvent(PIXI_INTERACTION_EVENT.BADGE)),
    )
      .pipe(takeUntil(this.stopDraw$))
      .subscribe(() => {
        this.source!.updateConnectionTarget!(null);
      });

    this.interactionService.pointerUp$
      .pipe(
        takeUntil(this.stopDraw$),
        filterAndExtractInstance(PIXI_INTERACTION_EVENT.BRANCH),
        filter((branch) => {
          return this.connectionValidatorService.elementsAreConnectable(this.source!, branch);
        }),
      )
      .pipe(decreaseTickerMaxFPS(this.pixiApp))
      .subscribe((branch) => {
        resetBlockState(branch);
        this.source!.updateConnectionTarget!(branch.linkId);
        this._inProcessOfRedrawing = false;
      });
  }

  /**
   * Показать ошибку, в случае невозможности создания связи
   * @param branch - Блок бота, к которому идет связь
   * @private
   */
  private showError(branch: Branch) {
    if (this.source instanceof BaseActionABS && this.source.currentBranch === branch) {
      return;
    }

    if (
      !this.connectionValidatorService.isValidToBeFirstBlockOfTriggerScenarioForWelcomeBot(this.source!, branch) ||
      this.connectionValidatorService.isTargetFirstConditionInWelcomeBlockAndTargetIsAction(this.source!, branch)
    ) {
      this.toastService.danger(this.transloco.translate('classes.errors.actionIsNotAllowedToBeFirstBlockOfWelcomeBot'));
      return;
    }

    if (this.connectionValidatorService.areElementsFromDifferentScenarios(this.source!, branch)) {
      this.toastService.danger(this.transloco.translate('classes.errors.scenariosIntersection'));
      return;
    }

    const errorName = this.connectionValidatorService.validateTargetToBePartOfSourceScenario(this.source!, branch);
    if (errorName !== null) {
      this.toastService.danger(this.transloco.translate(`classes.errors.${errorName}`));
      return;
    }
  }

  /**
   * Действия, необходимые при остановки рисования связи по любому сценарию
   */
  private stopDrawing(): void {
    this.drawing = false;
    this.stopDraw$.next();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { NgArrayPipesModule } from 'ngx-pipes';

import { AccessDenialBannerModule } from '@panel/app/partials/access-denial/banner/access-denial-banner.module';
import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ChannelsTableComponent } from './channels-table.component';

@NgModule({
  declarations: [ChannelsTableComponent],
  imports: [
    CommonModule,
    UIRouterModule,
    AccessDenialBannerModule,
    TranslocoModule,
    SharedModule,
    NgbTooltipModule,
    NgArrayPipesModule,
    ChannelIconModule,
  ],
  exports: [ChannelsTableComponent],
})
export class ChannelsTableModule {}

/**
 * Фильтр, превращающий число в деньги
 */
(function () {
  'use strict';

  angular.module('myApp.filters.money').filter('money', money);

  function money(accounting) {
    return moneyFilter;

    /**
     * Функция, превращающая чило в деньги
     * Значения по умолчанию уже заданы в конциругации accounting.js
     *
     * @param {Number|String} value Числовое значение, будет разбито на целую и дробную часть, целая часть через каждые 3 знака будет разбита пробелами, в конце числа будет добавлен знак валюты
     * @param {String=} symbol=CURRENCY.SYMBOL Знак валюты
     * @param {Number=} precision=CURRENCY.PRECISION Точность (десятичная часть)
     * @param {String=} thousand=CURRENCY.THOUSAND Разделитель тысяч
     * @param {String=} decimal=CURRENCY.DECIMAL Разделитель десятичной части
     * @param {String=} format=CURRENCY.FORMAT Формат вывода
     * @return {String}
     */
    function moneyFilter(value, symbol, precision, thousand, decimal, format) {
      symbol = angular.isDefined(symbol) ? symbol : undefined;
      precision = angular.isDefined(precision) ? precision : undefined;
      thousand = angular.isDefined(thousand) ? thousand : undefined;
      decimal = angular.isDefined(decimal) ? decimal : undefined;
      format = angular.isDefined(format) ? format : undefined;

      return accounting.formatMoney(value, symbol, precision, thousand, decimal, format);
    }
  }
})();

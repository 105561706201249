<ng-container *transloco="let t; read: 'amocrmDataToLeadsComponent'">
  <h3 class="d-flex align-items-center font-weight-bolder">
    <i class="cqi-sm cqi-circle-dollar mr-10"></i>
    <span [innerHTML]="t('heading.beforeLead')"></span>
    <span
      class="dashed"
      [ngbTooltip]="tooltip"
      [innerHTML]="t('heading.lead')"
    ></span>
    <span [innerHTML]="t('heading.afterLead', {integrationTypeName})"></span>
    <ng-template #tooltip>
      <div [innerHTML]="t('tooltip', {integrationTypeName})"></div>
    </ng-template>
  </h3>

  <cq-amocrm-pipeline
    class="my-20"
    [integrationId]="integrationId"
    [controlLeadDefaultPipeline]="controlLeadDefaultPipeline"
    [controlLeadDefaultStatus]="controlLeadDefaultStatus"
  ></cq-amocrm-pipeline>

  <hr />
  <cq-amocrm-chat-bots-for-lead
    [chatBots]="chatBots"
    [integrationId]="integrationId"
    [isShowedFromModal]="isShowedFromModal"
  ></cq-amocrm-chat-bots-for-lead>

  <hr />
  <cq-amocrm-trigger-messages-for-lead
    [isShowedFromModal]="isShowedFromModal"
    [triggerMessages]="triggerMessages"
  ></cq-amocrm-trigger-messages-for-lead>

  <hr />
  <cq-amocrm-event-triggers-for-lead
    [controlLeadsEventTypeIds]="controlLeadsEventTypeIds"
    [currentApp]="currentApp"
  ></cq-amocrm-event-triggers-for-lead>

  <hr />
  <cq-amocrm-props-to-lead
    [controlLeadEventTypes]="controlLeadEventTypes"
    [controlLeadProps]="controlLeadProps"
    [integrationId]="integrationId"
  ></cq-amocrm-props-to-lead>
</ng-container>

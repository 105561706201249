import { Validators } from '@angular/forms';

import { INTEGRATION_NAME_MAX_LENGTH } from '@http/integration/constants/integration.constants';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

import { CalendlyIntegration } from '../calendly-integration';
import { CalendlyIntegrationSettingsExternal } from '../interfaces/calendly-integration.interface';
import { CalendlyIntegrationFormData } from '../types/calendly-integration.types';

/** Класс для работы с формой представления Calendly-интеграции */
export class CalendlyIntegrationForm extends GenericFormGroup<CalendlyIntegrationFormData> {
  constructor(integration: CalendlyIntegration) {
    let controls: Controls<CalendlyIntegrationFormData> = {
      name: new GenericFormControl(integration.name, [
        Validators.required,
        Validators.maxLength(INTEGRATION_NAME_MAX_LENGTH),
      ]),
      settings: new CalendlySettingsFormGroup(integration.settings),
    };

    super(controls, null, null);
  }
}

class CalendlySettingsFormGroup extends GenericFormGroup<CalendlyIntegrationSettingsExternal> {
  constructor(settings: CalendlyIntegrationSettingsExternal) {
    const controls: Controls<CalendlyIntegrationSettingsExternal> = {
      oauthCsrfToken: new GenericFormControl(settings.oauthCsrfToken),
      oauthAccessToken: new GenericFormControl(settings.oauthAccessToken),
    };

    super(controls, null, null);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ItemHeaderComponent } from './item-header.component';

@NgModule({
  declarations: [ItemHeaderComponent],
  exports: [ItemHeaderComponent],
  imports: [CommonModule],
})
export class ItemHeaderModule {}

/**
 * Компонент для отображения header'а в завершении регистрации
 */

(function () {
  'use strict';

  require('./completion-registration-header.controller');

  angular
    .module('myApp.completionRegistration')
    .component('cqCompletionRegistrationHeader', cqCompletionRegistrationHeader());

  function cqCompletionRegistrationHeader() {
    return {
      bindings: {},
      controller: 'cqCompletionRegistrationHeaderController',
      controllerAs: 'vm',
      template: require('./completion-registration-header.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { CookieService } from 'ngx-cookie-service';

import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { UtilsService } from '@panel/app-old/shared/services/utils/utils.service';

@Component({
  selector: 'cq-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly cookieService: CookieService,
    private readonly utilsService: UtilsService,
    @Inject(WINDOW) readonly window: Window,
  ) {}

  ngOnInit(): void {
    this.trackLogout();

    this.clearCookies();

    this.redirectToLogin();
  }

  private clearCookies() {
    // удаление куков для входа в панель
    this.cookieService.delete('carrotquest_auth_token_panel', '/');

    // удаление куки с django user id, которая записана для fullstory
    this.cookieService.delete('carrotquest_django_user_id', '/');
  }

  private redirectToLogin() {
    this.window.location.href = this.utilsService.getExternalPagesAbsoluteUrl('login');
  }

  private trackLogout() {
    const eventName = 'Вышел из системы';

    this.carrotquestHelper.track(eventName);
  }
}

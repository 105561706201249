import { BaseTexture, Renderer, RenderTexture } from '@pixi/core';
import { Container } from '@pixi/display';
import { Graphics } from '@pixi/graphics';

import { MAX_ACTION_WIDTH } from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { BOT_GREY } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { PixiTextureBuilderService } from '@panel/app/shared/services/pixi/texture-builder/pixi-texture-builder.service';

const ZERO = 0;
const TEXTURE_WIDTH = MAX_ACTION_WIDTH;
const BORDER_COLOR = BOT_GREY;
const SIDE_TEXTURE_HEIGHT = 12;
const SIRE_TEXTURE_ANGLE_LENGTH = 10;
const SIDE_TEXTURE_ANGLE_RADIUS = 10;
// От этой высоты зависит то, во сколько кусков будет собираться один задник для текста.
// Слишком маленьким его делать не стоит, потому что придется много повторений делать.
// Слишком большим, кажется, тоже, потому что тогда из него каждый раз будет собираться новая текстура
const INNER_TEXTURE_HEIGHT = 700;
const BORDER_SIZE = 1;

function getSideRenderTexture() {
  return RenderTexture.create({
    width: TEXTURE_WIDTH,
    height: SIDE_TEXTURE_HEIGHT,
    resolution: 5,
  });
}

function getInnerRenderTexture() {
  return RenderTexture.create({
    width: TEXTURE_WIDTH,
    height: INNER_TEXTURE_HEIGHT,
    resolution: 2,
  });
}

let sideBorderTexture: BaseTexture | null = null;
let innerBorderTexture: BaseTexture | null = null;

function getBorderSideBaseTexture(color: number, renderer: Renderer): BaseTexture {
  if (sideBorderTexture) {
    return sideBorderTexture;
  }

  const graphics = new Graphics();

  graphics
    .moveTo(ZERO, SIDE_TEXTURE_HEIGHT)
    .lineStyle(BORDER_SIZE, color, 1, 0)
    .lineTo(ZERO, SIRE_TEXTURE_ANGLE_LENGTH)
    .arcTo(ZERO, ZERO, SIRE_TEXTURE_ANGLE_LENGTH, ZERO, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(TEXTURE_WIDTH - SIRE_TEXTURE_ANGLE_LENGTH, ZERO)
    .arcTo(TEXTURE_WIDTH, ZERO, TEXTURE_WIDTH, SIRE_TEXTURE_ANGLE_LENGTH, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(TEXTURE_WIDTH, SIDE_TEXTURE_HEIGHT);

  // resolution нужно, чтоб скругленные углы не шакалились
  const renderTexture = getSideRenderTexture();

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  sideBorderTexture = renderTexture.castToBaseTexture();

  return sideBorderTexture;
}

function getBorderInnerBaseTexture(color: number, renderer: Renderer): BaseTexture {
  if (innerBorderTexture) {
    return innerBorderTexture;
  }

  const graphics = new Graphics();

  graphics
    .lineStyle(BORDER_SIZE, color, 1, 0)
    .moveTo(TEXTURE_WIDTH, ZERO)
    .lineTo(TEXTURE_WIDTH, INNER_TEXTURE_HEIGHT)
    .moveTo(ZERO, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, ZERO);

  const renderTexture = getInnerRenderTexture();

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  innerBorderTexture = renderTexture.castToBaseTexture();

  return innerBorderTexture;
}

let lastRendererContextUID: number | null = null;

export function getButtonBackground(renderer: Renderer, width: number, height: number): Container {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    clearCache();
  }
  lastRendererContextUID = renderer.CONTEXT_UID;

  const container = new Container();

  const sideBorderBaseTexture = getBorderSideBaseTexture(BORDER_COLOR, renderer);
  const innerBorderBaseTexture = getBorderInnerBaseTexture(BORDER_COLOR, renderer);

  const border = PixiTextureBuilderService.buildBurgerTextureWithWidthReduced(
    sideBorderBaseTexture,
    innerBorderBaseTexture,
    width,
    height,
  );

  container.addChild(border);

  return container;
}

function clearCache() {
  sideBorderTexture = null;
  innerBorderTexture = null;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { MemberInvitationsNotExistZeroDataComponent } from './member-invitations-not-exist-zero-data.component';

@NgModule({
  declarations: [MemberInvitationsNotExistZeroDataComponent],
  exports: [MemberInvitationsNotExistZeroDataComponent],
  imports: [CommonModule, TranslocoModule],
})
export class MemberInvitationsNotExistZeroDataModule {}

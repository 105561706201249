import { AbstractControl, ValidationErrors } from '@angular/forms';

export function floatValidator(control: AbstractControl): ValidationErrors | null {
  const FLOAT_REGEXP = /^\-?\d+((,)\d+)?$/;

  if (control.value === null || control.value === '') {
    return null;
  }

  // Заменяем запятую на точку для корректного тестирования регулярного выражения,
  // так как стандартный parseFloat и регулярное выражение работают с точкой.
  const valid = FLOAT_REGEXP.test(control.value.toString());

  return valid ? null : { float: true };
}

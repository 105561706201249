import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

const STEP_NAME_MAX_LENGTH = 70;

@Component({
  selector: 'cq-trigger-chain-step-name-editor',
  templateUrl: './trigger-chain-step-name-editor.component.html',
  styleUrls: ['./trigger-chain-step-name-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainStepNameEditorComponent extends AbsCVAFormControlBasedComponent<string> {
  readonly control: FormControl<string> = new FormControl<string>('', {
    validators: [Validators.required, Validators.maxLength(STEP_NAME_MAX_LENGTH)],
    nonNullable: true,
  });

  protected isNameFieldFocused: boolean = false;

  protected readonly STEP_NAME_MAX_LENGTH = STEP_NAME_MAX_LENGTH;
}

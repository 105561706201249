<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">
      {{
        t('emailNotificationSelectModalWrapper.heading', {
          integrationName: modalWindowParams.integrationExternal.name
        })
      }}
    </h3>
    <button
      class="close"
      (click)="dismissModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body custom-scroll">
    <cq-email-notification-event-triggers-readonly
      [class.margin-bottom-25]="selectedEventTriggersPrettyNames.length > 0"
      [currentApp]="modalWindowParams.currentApp"
      [properties]="modalWindowParams.properties"
      [selectedEventTriggers]="selectedEventTriggersPrettyNames"
    ></cq-email-notification-event-triggers-readonly>

    <cq-email-notification-chat-bots-list-readonly
      [class.margin-bottom-25]="(chatBotsList | async)?.length"
      [chatBotsList]="$any(chatBotsList | async)"
      [integrationId]="modalWindowParams.integrationExternal.id"
    ></cq-email-notification-chat-bots-list-readonly>

    <cq-email-notification-message-triggers-readonly
      [class.margin-bottom-25]="(autoMessagesList | async)?.length"
      [messages]="$any(autoMessagesList | async)"
    ></cq-email-notification-message-triggers-readonly>

    <cq-email-notification-team-members-select-readonly
      [class.margin-bottom-25]="(emailRecipients | async)?.length"
      [emailRecipientList]="$any(emailRecipients | async)"
      [integrationExternal]="modalWindowParams.integrationExternal"
      [teamMembers]="$any(stateService.teamMembers$ | async)"
    ></cq-email-notification-team-members-select-readonly>

    <div class="margin-bottom-15 font-weight-bold">{{ t('emailNotificationSelectModalWrapper.emailPreview') }}</div>
    <cq-email-notification-email-preview
      [appName]="modalWindowParams.currentApp.name"
      [comment]="modalWindowParams.integrationExternal.settings.comment"
      [properties]="modalWindowParams.properties"
      [selectedEventTypesIds]="modalWindowParams.integrationExternal.settings.eventTypeIds"
      [selectedUserProps]="modalWindowParams.integrationExternal.settings.props"
      [subject]="modalWindowParams.integrationExternal.settings.subject"
    ></cq-email-notification-email-preview>
  </div>
  <div class="modal-footer border-top">
    <button
      class="btn btn-outline-primary"
      (click)="dismissModal()"
      type="button"
    >
      {{ t('general.cancel') }}
    </button>
    <label
      class="btn btn-primary"
      (click)="closeModal()"
      >{{ t('emailNotificationSelectModalWrapper.selectIntegrationButton') }}</label
    >
  </div>
</ng-container>

<ng-container *transloco="let t">
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="collapse.toggle()"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
  >
    <h4 class="no-margin">{{ t('emailNotificationMessageTriggers.heading') }}</h4>
    <i
      class="cqi-sm ml-auto"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>
  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="padding-top-15">
      <ng-container *ngIf="messages.length > 0; else zeroData">
        <div class="margin-bottom-15">{{ t('emailNotificationMessageTriggers.description') }}</div>
        <div class="chat-bots-list margin-bottom-20 margin-between-rows-10">
          <div *ngFor="let message of messages">
            <a
              uiSref="app.content.messages.auto.edit"
              [uiParams]="{ messageId: message.id }"
              class="font-weight-bold"
              >{{ message.name }}</a
            >
          </div>
        </div>
        <div class="text-secondary">
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-lightbulb margin-right-5"></i>
            <div>
              <span>{{ t('emailNotificationMessageTriggers.note') }}</span>
              <a uiSref="app.content.messages.auto.table">{{
                t('emailNotificationMessageTriggers.goToMessagesLink')
              }}</a>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #zeroData>
        <div class="margin-bottom-20">{{ t('emailNotificationMessageTriggers.zeroData.text') }}</div>
        <a
          class="btn btn-secondary"
          uiSref="app.content.messages.auto.table"
          >{{ t('emailNotificationMessageTriggers.zeroData.goToMessagesButton') }}</a
        >
      </ng-template>
    </div>
  </div>
</ng-container>

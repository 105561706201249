import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import moment from 'moment';
import Moment from 'moment';
import { Observable } from 'rxjs';

import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { SUBSCRIPTION_STATUSES } from '@panel/app/services/billing-info/billing-info.constants';

export interface Subscription {
  due_invoices_count: number;
  plan_id: string;
  status: SUBSCRIPTION_STATUSES;
  trial_end: Moment.Moment;
}

@Component({
  selector: 'cq-subscription-status[subscription]',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionStatusComponent {
  @Input() isUsCountry?: boolean; // Определение страны США
  @Input() subscription!: Subscription;
  @Output() clickOnSubscriptionStatus = new EventEmitter<void>(); // Callback по клику на статус подписки

  now = new Observable<moment.Moment>((observer) => {
    setInterval(() => observer.next(moment()), 1000 * 60);
  });

  constructor(private readonly planVersionService: PlanVersionService, public uiRouter: UIRouter) {}

  get isTrial() {
    return this.subscription.status === SUBSCRIPTION_STATUSES.IN_TRIAL;
  }

  get isEndedTrial() {
    return this.subscription.status === SUBSCRIPTION_STATUSES.CANCELLED && this.planVersionService.isTrial();
  }

  get hasUnpaidInvoice() {
    return !this.isTrial && !this.isEndedTrial && this.subscription.due_invoices_count > 0;
  }

  /**
   * Трекинг клика на статус подписки и переход на страницу ее оплату подписки
   */
  onClickSubscriptionStatus() {
    this.uiRouter.stateService.go(this.isTrial ? 'app.content.subscription.pricing' : 'app.content.subscription');
    this.clickOnSubscriptionStatus.emit();
  }
}

<ng-container *transloco="let t">
  <div ngbDropdown>
    <button
      class="btn btn-secondary w-100"
      type="button"
      ngbDropdownToggle
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ t('otherActionsDropdownComponent.addAction') }}</span>
    </button>
    <div
      class="dropdown-menu-right margin-5"
      ngbDropdownMenu
    >
      <div class="dropdown-header">
        {{ t('models.chatBot.moreActionsButton.subGroups.' + caseStyleHelper.toCamelCase(ACTIONS_SUB_GROUPS.RECORD)) }}
      </div>
      <ng-container *ngFor="let action of getActionSubGroupActions(ACTIONS_SUB_GROUPS.RECORD)">
        <ng-container *tuiLet="accessToChatBotAction(action) as accessToChatBotAction">
          <div
            [ngbTooltip]="
              'chatBot.branchEditor.countLimitTooltip' | transloco: { limitCount: CHAT_BOT_ACTIONS_LIMIT_COUNT[action] }
            "
            [disableTooltip]="!isButtonDisabled(action)"
            triggers="mouseenter:mouseleave"
          >
            <button
              *ngIf="accessToChatBotAction.hasAccess"
              class="dropdown-item"
              type="button"
              (click)="botActionCreate.emit(action)"
              [disabled]="isButtonDisabled(action)"
            >
              <i
                class="dropdown-item-icon-left cqi-sm"
                [ngClass]="CHAT_BOT_ACTIONS_TYPES_ICONS[action]"
              ></i>
              <span>{{ t('models.chatBot.actionsTypes.' + caseStyleHelper.toCamelCase(action)) }}</span>
            </button>
            <button
              *ngIf="!accessToChatBotAction.hasAccess"
              class="dropdown-item"
              type="button"
              (click)="paywallService.showPaywall(currentApp, accessToChatBotAction.denialReason)"
            >
              <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
              <span>{{ t('models.chatBot.actionsTypes.' + caseStyleHelper.toCamelCase(action)) }}</span>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isTransferActionsVisible">
        <div class="dropdown-header">
          {{
            t('models.chatBot.moreActionsButton.subGroups.' + caseStyleHelper.toCamelCase(ACTIONS_SUB_GROUPS.TRANSFER))
          }}
        </div>
        <ng-container *ngFor="let action of getActionSubGroupActions(ACTIONS_SUB_GROUPS.TRANSFER)">
          <ng-container *tuiLet="accessToChatBotAction(action) as accessToChatBotAction">
            <div
              *ngIf="hasTransferActionAccess(action)"
              [disableTooltip]="!isButtonDisabled(action)"
              [ngbTooltip]="
                'chatBot.branchEditor.countLimitTooltip'
                  | transloco: { limitCount: CHAT_BOT_ACTIONS_LIMIT_COUNT[action] }
              "
              triggers="mouseenter:mouseleave"
            >
              <button
                class="dropdown-item"
                (click)="botActionCreate.emit(action)"
                *ngIf="accessToChatBotAction.hasAccess"
                [disabled]="isButtonDisabled(action)"
                type="button"
              >
                <i
                  class="dropdown-item-icon-left cqi-sm"
                  [ngClass]="CHAT_BOT_ACTIONS_TYPES_ICONS[action]"
                ></i>
                <span>{{ t('models.chatBot.actionsTypes.' + caseStyleHelper.toCamelCase(action)) }}</span>
              </button>
              <button
                class="dropdown-item"
                (click)="paywallService.showPaywall(currentApp, accessToChatBotAction.denialReason)"
                *ngIf="!accessToChatBotAction.hasAccess"
                type="button"
              >
                <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
                <span>{{ t('models.chatBot.actionsTypes.' + caseStyleHelper.toCamelCase(action)) }}</span>
              </button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *transloco="let t">
  <h3>{{ t('vkGroupsChoiceComponent.heading') }}</h3>
  <div class="margin-bottom-20">{{ t('vkGroupsChoiceComponent.description', { projectName: projectName }) }}</div>

  <ng-container *ngIf="vkGroupForms.length === 0">
    <div
      class="text-secondary"
      data-test="vk-groups-zero-data-text"
      [innerHTML]="t('vkGroupsChoiceComponent.zeroData', { projectName: projectName })"
    ></div>
  </ng-container>

  <ng-container *ngIf="vkGroupForms.length > 0">
    <div
      class="margin-between-rows-10"
      data-test="vk-groups-list"
    >
      <cq-checkbox-control
        *ngFor="let group of vkGroupForms.controls"
        [formControl]="group.controls.subscribed"
        (change)="onChangeGroup()"
      >
        {{ group.controls.name.value }}
      </cq-checkbox-control>
    </div>
  </ng-container>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDateAdapter, NgbDatepickerI18n, NgbDatepickerModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { DatetimePickerAdapter } from '@panel/app/shared/components/datetime-picker/services/datetime-picker.adapter';
import { DatetimePickerService } from '@panel/app/shared/components/datetime-picker/services/datetime-picker.service';
import { DatetimePickerI18nService } from '@panel/app/shared/components/datetime-picker/services/datetime-picker-i18n.service';
import { DatepickerComponent } from '@panel/app/shared/components/datetime-picker/ui/datepicker/datepicker.component';
import { DatepickerInputComponent } from '@panel/app/shared/components/datetime-picker/ui/datepicker-input/datepicker-input.component';
import { DatepickerMonthNavigationComponent } from '@panel/app/shared/components/datetime-picker/ui/datepicker-month-navigation/datepicker-month-navigation.component';
import { DaterangepickerComponent } from '@panel/app/shared/components/datetime-picker/ui/daterangepicker/daterangepicker.component';
import { DaterangepickerInputComponent } from '@panel/app/shared/components/datetime-picker/ui/daterangepicker-input/daterangepicker-input.component';
import { TimepickerComponent } from '@panel/app/shared/components/datetime-picker/ui/timepicker/timepicker.component';

@NgModule({
  declarations: [
    DatepickerComponent,
    DatepickerInputComponent,
    DatepickerMonthNavigationComponent,
    DaterangepickerComponent,
    DaterangepickerInputComponent,
    TimepickerComponent,
  ],
  exports: [
    DatepickerComponent,
    DatepickerInputComponent,
    DatepickerMonthNavigationComponent,
    DaterangepickerComponent,
    DaterangepickerInputComponent,
    TimepickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  providers: [
    DatetimePickerService,
    { provide: NgbDateAdapter, useClass: DatetimePickerAdapter },
    { provide: NgbDatepickerI18n, useClass: DatetimePickerI18nService },
  ],
})
export class DatetimePickerModule {}

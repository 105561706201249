import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { NewsHelperService } from '@http/news-helper/news-helper.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountHeaderComponent implements OnInit {
  /** текущее приложение */
  @Input() djangoUser!: any;

  protected newestUndeadNewsId: any = null;
  /** Количество непрочитанных новостей */
  protected unreadNewsCount: number = 0;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly newsHelperService: NewsHelperService,
    protected readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.newsHelperService.getUnreadNewsCounterByUser(this.djangoUser).then((count) => {
      this.unreadNewsCount = count;
      this.cdr.detectChanges();
    });
  }

  /**
   * Трек клик на кнопке что нового
   */
  trackClickWhatsNew(): void {
    this.carrotquestHelper.track('Клик на "Что нового"');
  }

  /**
   * Обновление значения в LS времени нажатия на кнопку "Что нового"
   */
  updateLastClickOnWhatsNewTime(): void {
    this.newsHelperService.markNewsAsReadForUser(this.djangoUser);
    setTimeout(() => {
      this.unreadNewsCount = 0;
    }, 2000);
  }
}

<ng-container *transloco="let t; read: 'eventFilterComponent'">
  <div class="margin-between-rows-10">
    <div
      class="text-muted small"
      *ngIf="showEventCounter"
    >
      {{ t('event', { position: getPositionByIndex(index) }) }}
    </div>

    <div class="d-flex margin-between-cols-5">
      <cq-event-select
        class="form-group min-width-0 flex-grow mb-0"
        [events]="events"
        [formControl]="control.controls.eventId"
      ></cq-event-select>

      <cq-event-type-select
        *ngIf="showEventTypeSelect"
        [formControl]="control.controls.eventType"
      ></cq-event-type-select>

      <button
        class="btn btn-outline-primary"
        *ngIf="showRemoveButton"
        (click)="onRemoveButtonClick(index)"
        type="button"
      >
        <i class="btn-icon cqi-sm cqi-times"></i>
      </button>
    </div>

    <cq-operation-select
      *ngIf="showOperationSelect"
      [formControl]="control.controls.operation"
    ></cq-operation-select>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { DjangoUserService } from '@http/django-user/services/django-user.service';
import { SharedModule } from '@panel/app/shared/shared.module';

import { DeleteDjangoUserModalComponent } from './delete-django-user-modal.component';

@NgModule({
  declarations: [DeleteDjangoUserModalComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, FormsModule, SharedModule],
  exports: [DeleteDjangoUserModalComponent],
  providers: [DjangoUserService],
})
export class DeleteDjangoUserModalModule {}

import { CHAT_BOT_URL_PATHS } from './chat-bot-routes.constants';
import { MESSAGE_STATUSES } from '../../../app/http/message/message.constants';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.chatBot').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.messagesAjs.chatBot', {
        url: CHAT_BOT_URL_PATHS.LEAD_BOT,
        redirectTo: 'app.content.messagesAjs.chatBot.list',
        template: '<div ui-view></div>',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
        },
      })

      .state('app.content.messagesAjs.chatBot.list', {
        url: '?{fromStarterGuideStep}&{filterType}',
        bindings: {
          billingInfo: 'billingInfo',
          botSender: 'botSender',
          chatBotsAll: 'chatBotsAll',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveTelegramBots: 'dataAboutActiveTelegramBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          knowledgeBaseCategories: 'knowledgeBaseCategories',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          routingBot: 'routingBot',
          tags: 'tags',
        },
        resolve: {
          botSender: getBotSender,
          chatBotsAll: getChatBotsAll,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveTelegramBots: getDataAboutActiveTelegramBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          dataAboutActiveWidgetBots: getDataAboutActiveWidgetBots,
          properties: getProperties,
          facebookActiveIntegrations: getFacebookActiveIntegrations,
          facebookBot: getFacebookBot,
          knowledgeBaseCategories: getKnowledgeBaseCategories,
          routingBot: getRoutingBot,
          tags: getAllUserTags,
          telegramBotAllList: getTelegramBotAllList,
          activeChatWidgetList: getActiveChatWidgetList,
          telegramIntegrationList: getTelegramIntegrationList,
        },
        component: 'cqChatBotList',
      });

    /**
     * Получение всех тегов пользователя
     *
     * @param currentApp
     * @param tagModel
     * @returns {Promise}
     */
    function getAllUserTags(currentApp, tagModel) {
      return firstValueFrom(tagModel.getList(currentApp.id));
    }

    /**
     * Получение отправителя бота
     *
     * @return {Promise}
     * @param $transition$
     */
    function getBotSender($transition$) {
      let messageSenderModel = $transition$.injector().get('messageSenderModel');

      return firstValueFrom(messageSenderModel.getBotSender());
    }

    /**
     * Получение всех созданных лид-ботов
     *
     * @param MESSAGE_STATUSES
     * @param chatBotModel
     * @param currentApp
     * @returns {*}
     */
    function getChatBotsAll(chatBotModel, currentApp) {
      return firstValueFrom(chatBotModel.getList(currentApp.id, MESSAGE_STATUSES.ALL));
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение Facebook-бота
     *
     * @param chatBotModel
     * @returns {Promise}
     */
    function getFacebookBot(chatBotModel) {
      return firstValueFrom(chatBotModel.getFacebookBot());
    }

    function getKnowledgeBaseCategories(currentApp, articleCategoryModel) {
      return articleCategoryModel.getList(currentApp.id);
    }

    /**
     * Получение списка всех Telegram-ботов
     *
     * @param chatBotModel
     * @returns {Promise}
     */
    function getTelegramBotAllList(chatBotModel) {
      return firstValueFrom(chatBotModel.getTelegramBotList(true));
    }

    /**
     * Получение списка активных Widget ботов
     *
     * @param chatBotModel
     * @returns {Promise<WidgetBot[]>}
     */
    function getActiveChatWidgetList(chatBotModel) {
      return firstValueFrom(chatBotModel.getWidgetBotList(false, MESSAGE_STATUSES.ACTIVE)).then((data) => data.bots);
    }

    /**
     * Получение списка активных интеграций Facebook
     *
     * @param INTEGRATION_TYPES
     * @param integrationModel
     * @param currentApp
     * @returns {Promise}
     */
    function getFacebookActiveIntegrations(INTEGRATION_TYPES, integrationModel, currentApp) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.FACEBOOK).then((integrations) => {
        return integrations.filter((integration) => integration.active);
      });
    }

    /**
     * Получение информации об активных Facebook ботах
     *
     * @param {FacebookBot | null} facebookBot
     */
    function getDataAboutActiveFacebookBots(facebookBot) {
      const facebookBots = facebookBot ? [facebookBot].filter((bot) => bot.active) : [];
      return {
        ids: facebookBots.map((bot) => bot.id),
        amount: facebookBots.length,
      };
    }

    /**
     * Получение информации об активных Lead ботах
     *
     * @param currentApp
     * @param {ChatBotModel} chatBotModel
     */
    function getDataAboutActiveLeadBots(currentApp, chatBotModel) {
      return firstValueFrom(chatBotModel.getList(currentApp.id, MESSAGE_STATUSES.ACTIVE)).then((response) => {
        return {
          ids: response.chatBots.map((bot) => bot.id),
          amount: response.chatBots.length,
        };
      });
    }

    /**
     * Получение информации об активных Telegram ботах
     *
     * @param {TelegramBot[]} telegramBotAllList
     */
    function getDataAboutActiveTelegramBots(telegramBotAllList) {
      const telegramBots = telegramBotAllList.filter((bot) => bot.active);
      return {
        ids: telegramBots.map((bot) => bot.id),
        amount: telegramBots.length,
      };
    }

    /**
     * Получение информации об активных Welcome ботах
     *
     * @param {RoutingBot | null} routingBot
     */
    function getDataAboutActiveWelcomeBots(routingBot) {
      const welcomeBots = routingBot ? [routingBot].filter((bot) => bot.active) : [];
      return {
        ids: welcomeBots.map((bot) => bot.id),
        amount: welcomeBots.length,
      };
    }

    /**
     * Получение информации об активных Welcome ботах
     *
     * @param {WidgetBot[]} activeChatWidgetList
     */
    function getDataAboutActiveWidgetBots(activeChatWidgetList) {
      return {
        ids: activeChatWidgetList.map((bot) => bot.id),
        amount: activeChatWidgetList.length,
      };
    }

    /**
     * Получение роутинг-бота
     *
     * @param chatBotModel
     * @returns {Promise}
     */
    function getRoutingBot(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot(true));
    }

    /**
     * Получение списка интеграций Telegram вместе с псевдоинтеграцией: она нужна для выбора опции в селекте с фильтром
     * по ботам
     *
     * @param INTEGRATION_TYPES
     * @param {App} currentApp
     * @param integrationModel
     * @returns {IPromise<TelegramIntegration[]>}
     */
    function getTelegramIntegrationList(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM).then((integrations) => {
        integrations.push(integrationModel.telegram.getPseudoIntegration());

        return integrations;
      });
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { SortablejsModule } from 'nxt-sortablejs';

import { SocialNetworkModule } from '@panel/app/pages/settings/chat/components/social-network/social-network.module';

import { SocialSettingsComponent } from './social-settings.component';

@NgModule({
  declarations: [SocialSettingsComponent],
  imports: [SocialNetworkModule, CommonModule, TranslocoModule, SortablejsModule, ReactiveFormsModule, FormsModule],
  exports: [SocialSettingsComponent],
})
export class SocialSettingsModule {}

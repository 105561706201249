(function () {
  'use strict';

  require('./service-installation-checker.controller');

  angular.module('myApp.serviceInstallation').component('cqServiceInstallationChecker', cqServiceInstallationChecker());

  function cqServiceInstallationChecker() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        modalInstance: '=', // модальное окно, в котором вызван компонент
      },
      controller: 'CqServiceInstallationCheckerController',
      controllerAs: 'vm',
      template: require('./service-installation-checker.html'),
    };
  }
})();

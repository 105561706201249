import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { SocialNetworkComponent } from './social-network.component';

@NgModule({
  declarations: [SocialNetworkComponent],
  imports: [CommonModule, TranslocoModule, NgbCollapseModule, FormsModule, ReactiveFormsModule, SharedModule],
  exports: [SocialNetworkComponent],
})
export class SocialNetworkModule {}

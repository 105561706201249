<ng-container *transloco="let t; read: 'removeTagComponent'">
  <div class="margin-between-rows-30">
    <div class="margin-between-rows-10">
      <div>{{ t('content.part1') }}</div>
      <div>
        <span [innerHtml]="t('content.part2', { tag: displayedTagName })"></span>
        <b>{{ t('content.part3') }}</b>
      </div>
    </div>

    <div class="d-flex justify-content-end margin-between-cols-10">
      <button
        class="btn btn-outline-primary"
        (click)="onClickCancelButton()"
        type="button"
      >
        {{ t('buttons.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        (click)="onClickRemoveButton()"
        type="button"
      >
        {{ t('buttons.remove') }}
      </button>
    </div>
  </div>
</ng-container>

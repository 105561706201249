import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SlashCommand } from '@http/slash-command/slash-command.types';

@Injectable({ providedIn: 'root' })
export class SlashCommandModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Получение списка Slash-команд
   *
   * @param appId ID приложения
   */
  getList(appId: string): Observable<SlashCommand[]> {
    const params = {
      app: appId,
    };

    return this.http.get<SlashCommand[]>('/integrations/slash_commands', { params });
  }
}

(function () {
  'use strict';

  angular.module('myApp.emailPreview').component('cqEmailPreview', cqEmailPreview());

  function cqEmailPreview() {
    return {
      bindings: {
        currentApp: '=', // приложение
        messageSender: '=', // отправитель письма
        showAppName: '=', // показывать название приложения или нет
        subject: '@', // тема письма
      },
      controllerAs: 'vm',
      template: require('./email-preview.html'),
      transclude: true,
    };
  }
})();

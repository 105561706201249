(function () {
  'use strict';

  angular.module('myApp.chatSettings').constant('WIDGET_BADGE_TYPES', getWidgetBadgeTypes());

  /**
   * Вкладки
   *
   * @return {Object}
   */
  function getWidgetBadgeTypes() {
    return {
      AVATAR: 'avatar',
      DEFAULT: 'default',
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';

import { VkFormsIntegrationExternal } from '@http/integration/integrations/vk-forms/interfaces/vk-forms-integration.interfaces';
import { VkFormsComponent } from '@panel/app/pages/integrations/content/vk-forms/vk-forms.component';
import { App } from '@http/app/app.model';
import { Properties } from '@http/property/property.model';

@Component({
  selector: 'cq-vk-forms-wrapper[currentApp][integrationExternal][properties]',
  templateUrl: './vk-forms-wrapper.component.html',
  styleUrls: ['./vk-forms-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsWrapperComponent {
  /** Текущее приложение */
  @Input() currentApp!: App;
  /** Текущая или новая интеграция в внешнем формате */
  @Input() integrationExternal!: VkFormsIntegrationExternal;
  /** Список свойств и событий пользователей */
  @Input() properties!: Properties;
  /** Дочерний элемент настройки интеграции */
  @ViewChild(VkFormsComponent) vkFormsComponent!: VkFormsComponent;
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';

import { AppService } from '@http/app/services/app.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';
import { StarterGuideModel } from '@http/starter-guide/starter-guide.model';
import {
  LEADS_PASSING_PREVIEW_MODAL_DATA_TOKEN,
  LeadsPassingPreviewModalData,
} from '@panel/app/pages/starter-guide/leads-passing/preview-modal/leads-passing-preview-modall.token';
import { STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES } from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.constants';
import { STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE } from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Компонент модального окна для превью выбранной интеграции в стартергайде
 */
@Component({
  selector: 'cq-leads-passing-preview-modal',
  templateUrl: './leads-passing-preview-modal.component.html',
  styleUrls: ['./leads-passing-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadsPassingPreviewModalComponent implements OnInit {
  /** Текущий язык */
  activeLang!: string;

  /** Текст в теле модалки */
  bodyText!: string;

  /** Текст для кнопки в футере */
  buttonText!: string;

  /** Заголовок модалки */
  heading!: string;

  /** Ссылка на картинку для превью интеграции */
  imageSrc!: string;

  /** Тип интеграции, для которого показывается превью */
  integrationType!: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE;

  /** Перевод типа интеграции */
  integrationTypeName!: string;

  /** Список типов интеграций */
  STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES = STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES;

  constructor(
    private readonly appService: AppService,
    public activeModal: NgbActiveModal,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly integrationService: IntegrationService,
    @Inject(LEADS_PASSING_PREVIEW_MODAL_DATA_TOKEN)
    private readonly modalParams: LeadsPassingPreviewModalData,
    private readonly starterGuideModel: StarterGuideModel,
    private readonly stateService: StateService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.activeLang = this.translocoService.getActiveLang();
    this.integrationType = this.modalParams.integrationType;
    this.integrationTypeName = this.getIntegrationTypeNameTranslate(this.integrationType);

    this.bodyText = this.getBodyText();
    this.buttonText = this.getButtonText();
    this.heading = this.modalParams.heading;
    this.imageSrc = this.getImageSrc();
  }

  /** Текст для тела модалки */
  getBodyText() {
    return this.translocoService.translate(`leadsPassingPreviewModalComponent.body.${this.integrationType}`, {
      integrationTypeName: this.integrationTypeName,
    });
  }

  /** Текст для кнопки в футере модалки */
  getButtonText() {
    switch (this.integrationType) {
      case STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.AMOCRM:
      case STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.BITRIX24:
        return this.translocoService.translate('leadsPassingPreviewModalComponent.footer.setUpIntegrationButton');
      case STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.EMAIL_NOTIFICATION:
        return this.translocoService.translate('leadsPassingPreviewModalComponent.footer.setUpNotificationButton');
      case STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.LEAD_BOT:
        return this.translocoService.translate('leadsPassingPreviewModalComponent.footer.setUpBotButton');
      default:
        return '';
    }
  }

  /** Ссылка на картинку для тела модалки */
  getImageSrc(): string {
    return `assets/img/${this.activeLang}/starter-guide/leads-passing/${this.integrationType}.png`;
  }

  /**
   * Получение перевода для типа интеграции
   *
   * @param integrationType Тип интеграции
   */
  getIntegrationTypeNameTranslate(integrationType: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE): string {
    switch (integrationType) {
      // HACK Так как такого типа интеграции нет, то просто беру перевод из заголовка модалки
      case STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.LEAD_BOT:
        return this.heading;
      default:
        return this.integrationService.getTranslatedIntegrationType(this.integrationType as INTEGRATION_TYPES);
    }
  }

  /** Обработчик клика по кнопке на создание бота */
  onClickCreateLeadBotButton(): void {
    this.starterGuideModel.setStepIsMade(this.appService.currentAppId, STARTER_GUIDE_STEPS.LEADS_PASSING).subscribe();

    this.trackGoToChatBotCreatePage();

    this.stateService.go('app.content.messagesAjs.chatBot.create.new');

    this.activeModal.close();
  }

  /**
   * Обработчик клика по кнопке на создание интеграции
   *
   * @param integrationType Тип создаваемой интеграции
   */
  onClickSetUpIntegrationButton(integrationType: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE): void {
    this.trackGoToIntegrationPage(integrationType);

    this.stateService.go('app.content.integrations.details.unconfigured', { integrationType });

    this.activeModal.close();
  }

  /** Трек клика на кнопку перехода на создание бота */
  trackGoToChatBotCreatePage(): void {
    const eventName = 'Стартергайд - перешел из модалки на страницу создания бота';

    this.carrotquestHelper.track(eventName);
  }

  /** Трек клика на кнопку перехода на создание бота */
  trackGoToIntegrationPage(integrationType: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE): void {
    const integrationTypeName = this.integrationService.getTranslatedIntegrationType(
      integrationType as INTEGRATION_TYPES,
    );
    const eventName = `Стартергайд - перешел в интеграцию «${integrationTypeName}»`;

    this.carrotquestHelper.track(eventName);
  }
}

<ng-container *transloco="let t; read: 'timePickerComponent'">
  <div class="d-flex align-items-center">
    <ng-select
      class="time-select w-100"
      [items]="TIME_H_OPTIONS"
      [clearable]="false"
      bindValue="value"
      bindLabel="label"
      [(ngModel)]="timeH"
      (ngModelChange)="onChangeTime()"
    ></ng-select>
    <span class="mx-5">:</span>
    <ng-select
      class="time-select w-100"
      [items]="TIME_M_OPTIONS"
      bindValue="value"
      bindLabel="label"
      [clearable]="false"
      [(ngModel)]="timeM"
      (ngModelChange)="onChangeTime()"
    ></ng-select>
  </div>
</ng-container>

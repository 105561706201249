import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'cq-popover-template',
  templateUrl: './description-popover-template.component.html',
  styleUrls: ['./description-popover-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionPopoverTemplateComponent {
  @ViewChild('templateRef')
  public readonly templateRef!: TemplateRef<any>;
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntil } from 'rxjs/operators';

import { BotPropertyFieldActionForm } from '@panel/app/pages/chat-bot/forms/actions';
import { DestroyService } from '@panel/app/services';

export enum OPTIONS {
  CUSTOM = 'custom',
  DEFAULT = 'default',
  EMAIL = 'email',
  NAME = 'name',
  PHONE = 'phone',
}

export const CUSTOM_PLACEHOLDER_MAX_LENGTH = 25;

@Component({
  selector: 'cq-bot-custom-placeholder[actionForm]',
  templateUrl: './bot-custom-placeholder.component.html',
  styleUrls: ['./bot-custom-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class BotCustomPlaceholderComponent implements OnInit {
  @Input() actionForm!: BotPropertyFieldActionForm; // Форм

  constructor(private readonly destroy$: DestroyService, private readonly translocoService: TranslocoService) {}

  protected readonly CUSTOM_PLACEHOLDER_MAX_LENGTH = CUSTOM_PLACEHOLDER_MAX_LENGTH;

  protected readonly OPTIONS = OPTIONS;

  protected readonly options: OPTIONS[] = Object.values(this.OPTIONS);

  ngOnInit() {
    if (!this.value.valid) {
      const translateDefault = this.translocoService.translate('botCustomPlaceholderComponent.options.default');
      this.value.setValue(translateDefault);
    }
    this.type.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((type) => {
      const text: string = type === OPTIONS.CUSTOM ? this.value.value : this.optionTranslate;

      this.value.setValue(text);
    });
  }

  protected get optionTranslate() {
    return this.translocoService.translate('botCustomPlaceholderComponent.options.' + this.type.value);
  }

  protected get isCustom() {
    return this.type.value === OPTIONS.CUSTOM;
  }

  protected get type() {
    return this.actionForm.controls.bodyJson.controls.placeholder.controls.type;
  }

  protected get value() {
    return this.actionForm.controls.bodyJson.controls.placeholder.controls.value;
  }
}

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { WHATS_APP_EDNA_API_ERRORS } from '@http/integration/integrations/whats-app-edna/constants/whats-app-edna-integration.constants';
import { WhatsAppEdnaIntegrationExternal } from '@http/integration/integrations/whats-app-edna/interfaces/whats-app-edna-integration.interfaces';
import { WhatsAppEdnaIntegration } from '@http/integration/integrations/whats-app-edna/whats-app-edna-integration';
import { IntegrationService } from '@http/integration/services/integration.service';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { WaChatIconModalComponent } from '@panel/app/partials/modals/wa-chat-icon-modal/wa-chat-icon-modal.component';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { OneTimeFlagService } from '@panel/app/services/one-time-flag/one-time-flag.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@Component({
  selector: 'cq-whats-app-edna[currentApp][integrationExternal]',
  templateUrl: './whats-app-edna.component.html',
  styleUrls: ['./whats-app-edna.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...formSubmitTokenProviders],
})
export class WhatsAppEdnaComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Текущая или новая интеграция во внешнем формате */
  @Input()
  integrationExternal!: WhatsAppEdnaIntegrationExternal;

  /** Текущий язык */
  activeLang: string;

  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: WhatsAppEdnaIntegration;

  /** Режим редактирования интеграции */
  isEdit!: boolean;

  /** Режим первой настройки конкретной интеграции */
  isFirstConfigure: boolean = false;

  /** Флаг выполнения запроса */
  isRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly featureModel: FeatureModel,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSource: Subject<void>,
    private readonly integrationFactory: IntegrationFactory,
    private readonly integrationService: IntegrationService,
    private readonly modalService: NgbModal,
    private readonly oneTimeFlagService: OneTimeFlagService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly uiRouter: UIRouter,
  ) {
    this.activeLang = translocoService.getActiveLang();
  }

  /** Получение контрола с access_token'ом интеграции */
  get accessTokenControl() {
    return this.integration.form.controls.settings.controls.accessToken;
  }

  /** Получение контрола с телефоном */
  get accountPhoneControl() {
    return this.integration.form.controls.settings.controls.accountPhone;
  }

  /** Получение контрола с ID каскада */
  get cascadeIdControl() {
    return this.integration.form.controls.settings.controls.cascadeId;
  }

  /** Получение контрола с названием интеграции */
  get nameControl() {
    return this.integration.form.controls.name;
  }

  /** Получение значения контрола с названием интеграции */
  get hashValue() {
    return this.integration.form.controls.settings.controls.hash.value;
  }

  ngOnInit(): void {
    this.isEdit = !!this.integrationExternal.id;
    this.isFirstConfigure =
      !!this.integrationExternal.name &&
      !this.integrationExternal.settings.accessToken &&
      !this.integrationExternal.settings.accountPhone;

    if (this.isEdit) {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.WHATS_APP_EDNA, this.integrationExternal);
    } else {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.WHATS_APP_EDNA);
    }
  }

  /** Создание интеграции */
  create(): void {
    this.isRequestPerformed.next(true);

    this.integrationService
      .create(this.currentApp.id, this.integration)
      .pipe(finalize(() => this.isRequestPerformed.next(false)))
      .subscribe(
        (integration) => {
          this.goToConfiguredIntegration(integration);
        },
        () => {},
      );
  }

  /**
   * Перенаправление на настроенную интеграцию, после её создания
   *
   * @param integration - Созданная интеграция
   */
  goToConfiguredIntegration(integration: WhatsAppEdnaIntegration) {
    const params = {
      integrationType: integration.type,
      integrationId: integration.id,
    };

    this.uiRouter.stateService.go(`app.content.integrations.details.configured.${integration.type}`, params, {
      location: 'replace', // Чтобы кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
    });
  }

  /** Наличие доступа до нового API edna */
  hasAccessToNewApi(): boolean {
    return this.featureModel.hasAccess(FEATURES_ONLY.NEW_EDNA_API);
  }

  /** Отправка формы с настройками интеграции */
  onSubmit(): void {
    if (this.integration.form.valid) {
      this.save();
    } else {
      this.formSubmitSource.next();
    }
  }

  /** Сохранение интеграции */
  save(): void {
    this.isRequestPerformed.next(true);

    if (this.isFirstConfigure) {
      this.integration.active = true;
    }

    this.integrationService
      .save(this.currentApp.id, this.integration)
      .pipe(finalize(() => this.isRequestPerformed.next(false)))
      .subscribe(
        () => {
          if (this.isFirstConfigure) {
            this.uiRouter.stateService.reload().then(() => {
              this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));

              // Показываем модальное окно про возможность включить иконку WA в настройках чата
              if (
                !this.oneTimeFlagService.isSet(ONE_TIME_FLAGS.WHATS_APP_INTEGRATION_ICON_HINT_MODAL) &&
                !this.currentApp.settings.messenger_icon_whatsapp_show
              ) {
                this.modalService.open(WaChatIconModalComponent);
              }
            });
          } else {
            this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));
          }
        },
        (error) => {
          switch (error.error) {
            case WHATS_APP_EDNA_API_ERRORS.INVALID_CASCADE_ID:
              this.cascadeIdControl.setErrors({
                [WHATS_APP_EDNA_API_ERRORS.INVALID_CASCADE_ID]: true,
              });
              break;
            case WHATS_APP_EDNA_API_ERRORS.VALIDATION_ERROR:
            case WHATS_APP_EDNA_API_ERRORS.INVALID_ACCESS_TOKEN:
              this.accessTokenControl.setErrors({
                [WHATS_APP_EDNA_API_ERRORS.VALIDATION_ERROR]: true,
              });
              break;
          }
        },
      );
  }
}

import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/** Стандартное поведение аттрибута autofocus в браузере нам не подходит, т.к. оно не работает с *ngIf, например, поэтому пришлось писать свою директиву */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private readonly host: ElementRef) {}

  @Input()
  set autofocus(value: boolean | '') {
    // string в value указано для того, чтобы TS не ругался на использование стандартного аттрибута autofocus, т.к. при его использовании по умолчанию передаётся пустая строка
    if (value === '') {
      this.focus();
      return;
    }

    if (value) {
      this.focus();
      return;
    }

    this.needToFocus = false;
  }

  /** Нужно ли фокусить host-элемент */
  needToFocus: boolean = true;

  ngAfterViewInit(): void {
    if (this.needToFocus) {
      this.focus();
    }
  }

  /**
   * Фокус host-элемента
   *
   * @param value Нужно ли фокусить
   */
  focus() {
    // setTimeout нужен для того, чтобы focus выполнился в следующей итерации change detection, иначе в случае с дропдаунами автофокус не работает
    setTimeout(() => {
      this.host.nativeElement.focus();
    });
  }
}

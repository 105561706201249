<ng-container *transloco="let t">
  <div class="flex align-items-start padding-left-5 padding-right-5">
    <div class="flex flex-grow flex-wrap">
      <div
        class="user-note-body text-break"
        [innerText]="note.body"
        data-test="user-note-body-container"
      ></div>
      <small
        class="text-muted"
        [innerText]="note.author.name"
        data-test="user-note-author-name-container"
      ></small>
      <small
        class="text-muted margin-left-auto"
        [innerText]="userNoteCreatedDateFormatted"
        data-test="user-note-created-date-container"
      ></small>
    </div>
    <button
      class="btn btn-text-primary margin-left-5"
      (click)="removeUserNote()"
      type="button"
      data-test="user-note-remove-note-button"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
</ng-container>

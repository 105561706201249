import { Inject, Injectable } from '@angular/core';
import { Simple } from 'pixi-cull';
import { merge, mergeMap } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { PIXI_CULL } from '@panel/app/pages/chat-bot/content/tokens';
import { DestroyService } from '@panel/app/services';
import { BlockViewService } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.service';
import { ConnectionService } from '@panel/app/services/canvas/tirgger-chain/connections/connection.service';
import { filterByInteractionViewType } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.helpers';
import { InteractionService } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.service';
import { INTERACTION_ENTITY } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.types';

@Injectable()
export class TriggerChainCullingService {
  constructor(
    @Inject(PIXI_CULL)
    protected readonly cull: Simple,
    private readonly connectionService: ConnectionService,
    private readonly interactionService: InteractionService,
    private readonly blockViewService: BlockViewService,
    private readonly destroy$: DestroyService,
  ) {}

  initCulling() {
    merge(
      this.blockViewService.blockChanges$.pipe(map((update) => update.view.graphicContainer)),
      this.connectionService.connectionChange$.pipe(
        map((update) => update.connectionInfo?.connection),
        filter(Boolean),
      ),
      this.interactionService.move$.pipe(
        filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
        map((event) => {
          const connections = this.connectionService.getConnectionsByView(event.view);
          return [event.view.graphicContainer, ...connections.map((c) => c.connection)];
        }),
        mergeMap((t) => t),
      ),
    )
      .pipe(
        takeUntil(this.destroy$),
        filter((container) => !container.destroyed),
      )
      .subscribe((container) => {
        this.cull.updateObject(container);
      });
  }
}

<label
  [ngClass]="['cq-switch-' + goal, isDisabled ? 'disabled' : '']"
  (click)="markAsTouched()"
  data-test="switch-label"
>
  <input
    [formControl]="switchControl"
    type="checkbox"
  />
  <ng-template
    *ngIf="textPosition === 'left'"
    [ngTemplateOutlet]="textTemplate"
  ></ng-template>
  <div></div>
  <ng-template
    *ngIf="textPosition === 'right'"
    [ngTemplateOutlet]="textTemplate"
  ></ng-template>
</label>

/**
 * Контроллер для отображения графика работы сообщения из триггерной цепочки за период времени
 */
import { SENDING_TYPES } from '../../../../../../app/http/message/message.constants';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_PART_TYPES } from '../../../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular
    .module('myApp.triggerChains.workingDynamics')
    .controller('CqTriggerChainWorkingDynamicsController', CqTriggerChainWorkingDynamicsController);

  function CqTriggerChainWorkingDynamicsController(
    $filter,
    $scope,
    $translate,
    CAMELED_SENDING_TYPES,
    DATASET_TYPES,
    MESSAGE_SENDING_TYPE_COLORS,
    carrotquestHelper,
    chartHelper,
    dateRangePickerHelper,
    messageModel,
    messagePartModel,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.CAMELED_SENDING_TYPES = CAMELED_SENDING_TYPES;
      vm.DATASET_TYPES = DATASET_TYPES;
      vm.getBlockPopupCount = getBlockPopupCount;
      vm.MESSAGE_SENDING_TYPE_COLORS = MESSAGE_SENDING_TYPE_COLORS;
      vm.messageParts = messagePartModel.filterMessageParts(vm.currentMessage.activeTestGroup.parts); // варианты сообщения
      vm.onClickShowSendings = onClickShowSendings;
      vm.SENDING_TYPES = SENDING_TYPES;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.statisticsChart = {
        // график для вывода статистики
        type: 'line', // тип графика
        instance: null, // текущий инстанс графика
        datasetsShown: [
          // типы датасетов, которые показываются на графике в текущий момент
          CAMELED_SENDING_TYPES.SENDED,
        ],
        datasetsToShow: [
          // типы датасетов, которые могут показываться на графике (из них строятся чекбоксы)
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.READ,
          CAMELED_SENDING_TYPES.REPLIED,
          CAMELED_SENDING_TYPES.CLICKED,
        ],
        defaultDataset: {
          // датасет по умолчанию, на основе которого создаются все датасеты для графика
          datasetSendingType: CAMELED_SENDING_TYPES.SENDED, // тип датасета по действию получателей сообщения
          datasetType: DATASET_TYPES.GENERAL, // тип датасета по типу варианта сообщения
          data: [],
          fill: false,
          hidden: false,
          label: '',
          lineTension: 0,
          sum: 0,
        },
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          elements: {
            line: {
              borderWidth: 2,
            },
          },
          layout: {
            padding: {
              top: 20,
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkipPadding: 10,
                  maxRotation: 0,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: chartHelper.removeDecimalLabels,
                },
              },
            ],
          },
          tooltips: {
            position: 'nearest',
          },
          zeroData: {
            text: $translate.instant('triggerChainsDetailedStatistics.zeroData.dataset'),
          },
        },
      };
      vm.toggleDatasetsToShow = toggleDatasetsToShow;
      vm.trackClickShowSendings = trackClickShowSendings;

      if (vm.currentMessage.type === MESSAGE_PART_TYPES.TELEGRAM) {
        // Удаляет статистику по READ для TG сообщений
        vm.statisticsChart.datasetsToShow.splice(1, 1);
      }

      // общая статистика обновляется если изменён период отображения
      $scope.$watchCollection('vm.statisticsRange.dates', function (newValue, oldValue) {
        // используется для отслеживания применения датапикера вместо события "apply.daterangepicker", т.к. это событие отрабатывает неправильно при выборе дат и клике за пределы датапикера (даты применятся, а событие не вызовется)
        if (newValue != oldValue) {
          trackApplyDatepicker();
        }

        vm.statisticsRange.description = generateStatisticsRangeDescription(newValue.startDate, newValue.endDate);

        getMessageStatistics(vm.currentMessage.id, newValue.startDate, newValue.endDate).then((statistics) => {
          setDatasets(vm.currentMessage, vm.statisticsChart.datasetsToShow, vm.statisticsChart.datasetsShown);

          vm.statisticsChart.data.labels = statistics[Object.keys(statistics)[0]].labels;

          chartHelper.updateChart(vm.statisticsChart.instance);
        });
      });
    }

    /**
     * Генерация текстового представление периода
     *
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @returns {String}
     */
    function generateStatisticsRangeDescription(startDate, endDate) {
      const resultString = [$translate.instant('triggerChainsDatePeriodStats.heading.statisticFor')];
      const isEndDateSame = endDate.isSame(moment(), 'day');
      const diffInDays = endDate.diff(startDate, 'days');

      if (isEndDateSame && diffInDays == 0) {
        resultString.push(
          $translate.instant('triggerChainsDatePeriodStats.heading.today', { days: diffInDays + 1 }, 'messageformat'),
        );
      } else {
        if (isEndDateSame) {
          resultString.push(
            $translate.instant('triggerChainsDatePeriodStats.heading.last', { days: diffInDays + 1 }, 'messageformat'),
          );
        }
        resultString.push(
          $translate.instant('triggerChainsDatePeriodStats.heading.days', { days: diffInDays + 1 }, 'messageformat'),
        );
      }

      return resultString.join(' ');
    }

    /**
     * Подсчёт количества блочных поп-апов в сообщении
     *
     * @returns {number}
     */
    function getBlockPopupCount() {
      return $filter('filter')(vm.messageParts, filterBlockPopups).length;

      function filterBlockPopups(messagePart) {
        return (
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
        );
      }
    }

    /**
     * Получение статистики сообщения за период
     *
     * @param {String} messageId ID сообщения
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @returns {Promise}
     */
    function getMessageStatistics(messageId, startDate, endDate) {
      return firstValueFrom(messageModel.getMessageStatistics(messageId, startDate, endDate)).then(
        getMessageStatisticsSuccess,
      );

      function getMessageStatisticsSuccess(statistics) {
        vm.currentMessage.statistics = statistics;
        return vm.currentMessage.statistics;
      }
    }

    /**
     * Обработчик клика по кнопке показа подробной статистики по типу
     *
     * @param {SENDING_TYPES} sendingType
     */
    function onClickShowSendings(sendingType) {
      vm.setSendingsTab({
        messagePart: vm.sendings.currentMessagePart,
        startDate: vm.statisticsRange.dates.startDate,
        endDate: vm.statisticsRange.dates.endDate,
        sendingType: sendingType,
      });
      vm.trackClickShowSendings(sendingType);
    }

    /**
     * Заполнение датасетов графика
     *
     * @param {Array.<Object>} messagePart Варианты сообщения
     * @param {Array.<SENDING_TYPES>} datasetsToShow Датасеты, которые будут показываться на графике
     * @param {Array.<SENDING_TYPES>} alreadyShown Датасеты, которые уже показываются в текущий момент
     */
    function setDatasets(messagePart, datasetsToShow, alreadyShown) {
      var dataset, datasetType, datasetSendingType, datasetLabel, datasetColors;

      for (var i = 0; i < datasetsToShow.length; i++) {
        datasetSendingType = datasetsToShow[i];
        const translatedDatasetSendingType = $translate.instant(`models.message.statisticsTypes.${datasetSendingType}`);
        datasetLabel = translatedDatasetSendingType;
        datasetType = DATASET_TYPES.GENERAL;

        if (
          !(dataset = $filter('filter')(
            vm.statisticsChart.data.datasets,
            {
              datasetSendingType: datasetSendingType,
              datasetType: datasetType,
            },
            true,
          )[0])
        ) {
          dataset =
            vm.statisticsChart.data.datasets[
              vm.statisticsChart.data.datasets.push(angular.copy(vm.statisticsChart.defaultDataset)) - 1
            ];

          datasetColors = chartHelper.generateColors(MESSAGE_SENDING_TYPE_COLORS[datasetSendingType]);
          chartHelper.mergeDatasetColors(dataset, datasetColors);
        }

        dataset.datasetSendingType = datasetSendingType;
        dataset.datasetType = datasetType;
        dataset.data = messagePart.statistics[datasetSendingType].data;
        dataset.hidden = alreadyShown.indexOf(datasetSendingType) == -1;
        dataset.label = datasetLabel;
        dataset.sum = messagePart.statistics[datasetSendingType].sum;
      }
    }

    /**
     * Переключение показа датасета на графике
     *
     * @param {SENDING_TYPES} datasetSendingType Датасет, который включается/отключается
     * @param {DATASET_TYPES} datasetType Тип датасета
     * @param {Array.<SENDING_TYPES>} alreadyShown Датасеты, которые показываются в текущий момент
     */
    function toggleDatasetsToShow(datasetSendingType, datasetType, alreadyShown) {
      trackClickChartLegend();

      const filteredDatasets = $filter('filter')(
        vm.statisticsChart.instance.data.datasets,
        {
          datasetType: datasetType,
          datasetSendingType: datasetSendingType,
        },
        true,
      );
      const datasetsShownIndex = alreadyShown.indexOf(datasetSendingType);

      if (datasetsShownIndex != -1) {
        alreadyShown.splice(datasetsShownIndex, 1);
      } else {
        alreadyShown.push(datasetSendingType);
      }

      for (let i = 0; i < filteredDatasets.length; i++) {
        const dataset = filteredDatasets[i];
        dataset.hidden = datasetsShownIndex != -1;
      }

      chartHelper.updateChart(vm.statisticsChart.instance);
    }

    /**
     * Трек клика на легенду графика на вкладке "Общая статистика"
     */
    function trackClickChartLegend() {
      carrotquestHelper.track('Цепочки сообщений - клик на легенду графика на "Общая статистика"', {
        Название: vm.currentMessage.name,
      });
    }

    /**
     * Трек клика на кнопку "Посмотреть"
     *
     * @param {SENDING_TYPES} sendingType Действие получателя сообщения
     */
    function trackClickShowSendings(sendingType) {
      const sendingTypeTranslate = $translate.instant(`models.message.sendingTypes.${sendingType}`);

      carrotquestHelper.track('Цепочки сообщений - клик на "Посмотреть"', {
        'Тип получателей': sendingTypeTranslate,
      });
    }

    /**
     * Трек применения датапикера
     */
    function trackApplyDatepicker() {
      carrotquestHelper.track('Цепочки сообщений - применил датапикер', { Название: vm.currentMessage.name });
    }
  }
})();

(function () {
  'use strict';

  angular.module('myApp.chatSettings', []);

  require('./chat-settings.constants');
  require('./chat-settings.component');
  require('./chat-auto-reply/chat-auto-reply.component');
  require('./chat-color/chat-color.component');
  require('./chat-default-name/chat-default-name.component');
  require('./chat-operators-evaluation/chat-operators-evaluation.component');
  require('./chat-operators-status/chat-operators-status.component');
  require('./chat-patterns/chat-patterns.component');
  require('./chat-preview/chat-preview.component');
  require('./chat-settings-section-header/chat-settings-section-header.component');
  require('./chat-theme/chat-theme.component');
  require('./conversation-final-close-timeout/conversation-final-close-timeout.component');
  require('./email-notification-timeout/email-notification-timeout.component');
  require('./powered-by-inscription/powered-by-inscription.component');
  require('./sound-notification/sound-notification.component');
  require('./welcome-message/welcome-message.component');
  require('./widget-animation/widget-animation.component');
  require('./widget-avatar/widget-avatar.component');
  require('./widget-badge-type/widget-badge-type.component');
  require('./widget-indent/widget-indent.component');
  require('./widget-tooltip/widget-tooltip.component');
  require('./widget-mobile-indent/widget-mobile-indent.component');
  require('./working-hours/working-hours.component');
})();

import { PLAN_CAPABILITIES } from '../../../../app/http/plan-capability/plan-capability.constants';
import { isArray } from 'lodash-es';

(function () {
  'use strict';

  angular
    .module('myApp.planCapability')
    .controller('CqPlanCapabilityPaywallModalController', CqPlanCapabilityPaywallModalController);

  function CqPlanCapabilityPaywallModalController(carrotquestHelper) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      trackShowing(vm.resolve.denialReason);
    }

    /**
     * Трекает событие с параметрами
     *
     * @param eventName - Название события
     * @param eventParams - Параметры события
     */
    function track(eventName, eventParams) {
      return eventParams ? carrotquestHelper.track(eventName, eventParams) : carrotquestHelper.track(eventName);
    }

    /**
     * Трекает показ
     *
     * @param denialReasons - Причины отказа в доступе
     */
    function trackShowing(denialReasons) {
      const eventName = 'Тариф коммуникации - перешел на пейволл';

      if (denialReasons === null) {
        return trackShowingDefault(eventName);
      }

      if (denialReasons.length) {
        return trackShowingDowngrade(eventName);
      }

      const { addon, capability } = isArray(denialReasons) ? denialReasons[0] : denialReasons;
      if (addon) {
        return trackShowingWithAddonDenialReason(eventName, addon);
      }
      if (capability) {
        return trackShowingWithCapabilityDenialReason(eventName, capability);
      }
    }

    /**
     * Трекает простой показ
     *
     * @param eventName - Название события
     */
    function trackShowingDefault(eventName) {
      return track(eventName);
    }

    /**
     * Трекает показ при необходимости даунгрейда
     *
     * @param eventName - Название события
     */
    function trackShowingDowngrade(eventName) {
      const eventParam = {
        Ограничение: PLAN_CAPABILITIES.DOWNGRADE,
      };

      return track(eventName, eventParam);
    }

    /**
     * Трекает показ с причиной ограничения в виде не подключенного аддона
     *
     * @param eventName - Название события
     * @param addon - Аддон
     */
    function trackShowingWithAddonDenialReason(eventName, addon) {
      const eventParam = {
        Модуль: addon,
      };

      return track(eventName, eventParam);
    }

    /**
     * Трекает показ с причиной ограничения в виде не подключенной капабилити
     *
     * @param eventName - Название события
     * @param capability - Капабилити
     */
    function trackShowingWithCapabilityDenialReason(eventName, capability) {
      const eventParam = {
        Ограничение: capability,
      };

      return track(eventName, eventParam);
    }
  }
})();

import { AmocrmField } from '../types/amocrm-integration.types';

/** Название переменных, которые отправляются на бэк в качестве дефолтного названия тега сделки */
export enum AMOCRM_DEFAULT_LEAD_TAG_NAME {
  CHAT_BOT = '$chat_bot_name',
  TRIGGER_MESSAGE = '$message_name',
}

/** Максимальная длинна примечания AmoCRM */
export const AMOCRM_NOTE_MAX_LENGTH: number = 500;

/** Типы полей для списка свойств AmoCRM */
export enum AMOCRM_PROPS_FIELDS_TYPE {
  TEXT = 'text',
  NUMERIC = 'numeric',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  DATE = 'date',
  URL = 'url',
  MULTITEXT = 'multitext',
  TEXTAREA = 'textarea',
  RADIOBUTTON = 'radiobutton',
}

export enum AMOCRM_STANDARD_PROPS {
  NAME = '$name',
  EMAIL = '$email',
  PHONE = '$phone',
}

/** Массив стандартных свойств полей AmoCRM */
export const AMOCRM_STANDARD_PROPS_ARRAY: AmocrmField[] = [
  {
    name: AMOCRM_STANDARD_PROPS.NAME,
    fieldType: AMOCRM_PROPS_FIELDS_TYPE.TEXT,
  },
  {
    name: AMOCRM_STANDARD_PROPS.EMAIL,
    fieldType: AMOCRM_PROPS_FIELDS_TYPE.MULTITEXT,
  },
  {
    name: AMOCRM_STANDARD_PROPS.PHONE,
    fieldType: AMOCRM_PROPS_FIELDS_TYPE.MULTITEXT,
  },
];

/**
 * Тип полей из Amocrm
 *
 * - lead - сделка
 * - contact - контакт
 */
export type AMOCRM_FIELDS_TYPE = 'lead' | 'contact';

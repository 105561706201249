import { Pipe, PipeTransform } from '@angular/core';

import { EmojiService } from '@panel/app/shared/services/emoji/emoji.service';

@Pipe({
  name: 'replaceDivEmoji',
})
export class ReplaceDivEmojiPipe implements PipeTransform {
  constructor(private readonly emojiService: EmojiService) {}

  transform(value: string): string {
    return this.emojiService.replaceDivEmojiToNativeEmoji(value);
  }
}

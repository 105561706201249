import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CustomPriceCapModule } from '@panel/app/pages/subscription/pricing/custom-price-cap/custom-price-cap.module';
import { FeatureAddonModule } from '@panel/app/pages/subscription/pricing/plans-comparison/feature-addon/feature-addon.module';
import { FeatureValueModule } from '@panel/app/pages/subscription/pricing/plans-comparison/feature-value/feature-value.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { PlansComparisonComponent } from './plans-comparison.component';

@NgModule({
  declarations: [PlansComparisonComponent],
  exports: [PlansComparisonComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    CustomPriceCapModule,
    FeatureAddonModule,
    FeatureValueModule,
    NgbTooltipModule,
    SharedModule,
    FormsModule,
  ],
})
export class PlansComparisonModule {}

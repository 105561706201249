import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject } from 'rxjs';

import { Billing } from '@http/billing/billing';
import { EditAccountingEmailsModalComponent } from '@panel/app/pages/subscription/general/edit-accounting-emails-modal/edit-accounting-emails-modal.component';
import { EditBillingEmailModalComponent } from '@panel/app/pages/subscription/general/edit-billing-email-modal/edit-billing-email-modal.component';
import { EditRequisitesModalComponent } from '@panel/app/pages/subscription/general/edit-requisites-modal/edit-requisites-modal.component';
import { PaymentModalService } from '@panel/app/pages/subscription/general/services/payment-modal/payment-modal.service';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToFormattedMoneyPipe } from '@panel/app/shared/pipes/to-formatted-money/to-formatted-money.pipe';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

import { PaymentCardService } from '../../services/payment-card/payment-card.service';

/**
 * Компонент для работы с платёжной информацией
 */

@Component({
  selector: 'cq-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInfoComponent {
  /** Флаг выполнения запроса на подключение карты */
  isAddCardRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly l10nHelperService: L10nHelperService,
    private readonly modalHelperService: ModalHelperService,
    private readonly paymentModalService: PaymentModalService,
    private readonly paymentService: PaymentCardService,
    public readonly subscriptionStore: SubscriptionStore,
    private readonly toastService: ToastService,
    private readonly toFormattedMoneyPipe: ToFormattedMoneyPipe,
    public readonly translocoService: TranslocoService,
  ) {}

  /** Обработчик клика на добавление бухгалтерских емейлов */
  onAddAccountingEmails(): void {
    this.openEditAccountingEmailsModal();
  }

  /** Обработчик клика на добавления платёжной карты */
  onAddCard(): void {
    this.trackClickPlugCard();

    this.openConfirmAddCardModal();
  }

  /** Обработчик клика на добавление биллингового емейла */
  onAddBillingEmail(): void {
    this.openEditBillingEmailModal();
  }

  /** Обработчик клика на добавление реквизитов компании */
  onAddRequisites(): void {
    this.openEditRequisitesModal();
  }

  /** Обработчик клика на изменение платёжной карты */
  onChangeCard(): void {
    this.trackClickEditCard();

    this.openConfirmChangeCardModal();
  }

  /** Обработчик клика на изменение бухгалтерских емейлов */
  onEditAccountingEmails(): void {
    this.openEditAccountingEmailsModal();
  }

  /** Обработчик клика на редактирование биллингового емейла */
  onEditBillingEmail(): void {
    this.trackClickEditBillingEmail();

    this.openEditBillingEmailModal();
  }

  /** Обработчик клика на редактирование реквизитов компании */
  onEditRequisites(): void {
    this.openEditRequisitesModal();
  }

  /** Удаление привязанной платёжной каты */
  private deleteCard(): void {
    this.paymentService.deleteCard().then((response) => {
      this.updateBilling(response);

      this.toastService.success(this.translocoService.translate('paymentInfoComponent.toasts.deleteCardSuccess'));
    });
  }

  /** Открытие модалки редактирования бухгалтерских емейлов */
  private openEditAccountingEmailsModal(): void {
    const modal = this.modalHelperService.open(EditAccountingEmailsModalComponent);

    modal.result
      .then((response: Billing) => {
        this.updateBilling(response);

        this.trackEditAccountingEmailsSuccess();

        this.toastService.success(this.translocoService.translate('general.toasts.dataSaved'));
      })
      .catch(() => {});
  }

  /** Открытие модалки редактирования биллингового емейла */
  private openEditBillingEmailModal(): void {
    const modal = this.modalHelperService.open(EditBillingEmailModalComponent);

    modal.result
      .then((response: Billing) => {
        this.updateBilling(response);

        this.trackClickEditBillingEmailInModal();

        this.toastService.success(this.translocoService.translate('general.toasts.dataSaved'));
      })
      .catch(() => {});
  }

  /** Редактирование привязанной карты для оплаты */
  private editCard(isNew: boolean = false): void {
    this.isAddCardRequestPerformed.next(true);

    if (isNew) {
      this.paymentService.addCard().finally(() => this.isAddCardRequestPerformed.next(false));
    } else {
      this.paymentService.editCard().finally(() => this.isAddCardRequestPerformed.next(false));
    }
  }

  /** Открытие модалки редактирования реквизитов компании */
  private openEditRequisitesModal(): void {
    const modal = this.modalHelperService.open(EditRequisitesModalComponent);

    modal.result
      .then((response: Billing) => {
        this.updateBilling(response);

        this.trackEditRequisitesSuccess();

        this.toastService.success(this.translocoService.translate('general.toasts.dataSaved'));
      })
      .catch(() => {});
  }

  /** Открытие модалки подтверждения смены платёжной карты */
  private openConfirmAddCardModal(): void {
    const withdrawingAmount = this.l10nHelperService.isUsCountry() ? 1 : 15;
    const formattedWithdrawingAmount = this.toFormattedMoneyPipe.transform(withdrawingAmount);

    const modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('paymentInfoComponent.addCardConfirmModal.heading'),
        body: this.translocoService.translate('paymentInfoComponent.addCardConfirmModal.body', {
          cardLast4: this.subscriptionStore.billing$.getValue()!.cardLast4,
          withdrawingAmount: formattedWithdrawingAmount,
        }),
        confirmButtonText: this.translocoService.translate('paymentInfoComponent.addCardConfirmModal.confirmButton'),
      })
      .open(ConfirmModalComponent);

    modal.result.then(() => this.editCard(true)).catch(() => {});
  }

  /** Открытие модалки подтверждения смены платёжной карты */
  private openConfirmChangeCardModal(): void {
    const withdrawingAmount = this.l10nHelperService.isUsCountry() ? 1 : 15;
    const formattedWithdrawingAmount = this.toFormattedMoneyPipe.transform(withdrawingAmount);

    const modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('paymentInfoComponent.changeCardConfirmModal.heading'),
        body: this.translocoService.translate('paymentInfoComponent.changeCardConfirmModal.body', {
          cardLast4: this.subscriptionStore.billing$.getValue()!.cardLast4,
          withdrawingAmount: formattedWithdrawingAmount,
        }),
        confirmButtonText: this.translocoService.translate('paymentInfoComponent.changeCardConfirmModal.confirmButton'),
      })
      .open(ConfirmModalComponent);

    modal.result.then(() => this.editCard()).catch(() => {});
  }

  /** Открытие модалки подтверждения удаления платёжной карты */
  private openConfirmDeleteCardModal(): void {
    const modal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('paymentInfoComponent.deleteCardConfirmModal.heading'),
        body: this.translocoService.translate('paymentInfoComponent.deleteCardConfirmModal.body', {
          cardLast4: this.subscriptionStore.billing$.getValue()!.cardLast4,
        }),
        confirmButtonText: this.translocoService.translate('paymentInfoComponent.deleteCardConfirmModal.confirmButton'),
        confirmButtonClass: 'btn-outline-danger',
      })
      .open(ConfirmModalComponent);

    modal.result.then(() => this.deleteCard()).catch(() => {});
  }

  /** Обработчик клика на удаление платёжной карты */
  onRemoveCard(): void {
    this.trackClickDeleteCard();

    this.openConfirmDeleteCardModal();
  }

  /** Трек клика по кнопке "Удалить карту" */
  private trackClickDeleteCard() {
    const eventName = 'Оплата - клик по Удалить карту';

    this.carrotquestHelper.track(eventName);
  }

  /** Трек клика по кнопке изменения биллингового емейла */
  private trackClickEditBillingEmail(): void {
    const eventName = 'Оплата - клик по Изменить емейл';

    this.carrotquestHelper.track(eventName);
  }

  /** Трек успешной смены биллингового емейла (да, название события не совпадает) */
  private trackClickEditBillingEmailInModal(): void {
    const eventName = 'Оплата - клик в попапе Заменить емейл';

    this.carrotquestHelper.track(eventName);
  }

  /** Трек клика по кнопке "Изменить карту" */
  private trackClickEditCard() {
    const eventName = 'Оплата - клик по Изменить карту';

    this.carrotquestHelper.track(eventName);
  }

  /** Трек клика по кнопке добавления платёжной карты */
  private trackClickPlugCard(): void {
    const eventName = 'Оплата - клик по Подключить карту';

    this.carrotquestHelper.track(eventName);
  }

  /** Трек успешного изменения бухгалтерских емейлов */
  private trackEditAccountingEmailsSuccess(): void {
    const eventName = 'Оплата - клик в попапе "Изменение бухгалтерского емейла"';

    this.carrotquestHelper.track(eventName);
  }

  /** Трек успешного сохранения реквизитов */
  private trackEditRequisitesSuccess(): void {
    const eventName = 'Оплата - клик в попапе Сохранить реквизиты';

    this.carrotquestHelper.track(eventName);
  }

  /**
   * Обновление инстанса биллинга в subscriptionStore
   *
   * @param billing Новый инстанс биллинга
   * @private
   */
  private updateBilling(billing: Billing): void {
    this.subscriptionStore.billing$.next(billing);
  }
}

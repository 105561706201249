import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { EditEventTypeComponent } from '@panel/app/partials/modals/edit-event-type/edit-event-type.component';
import { EDIT_EVENT_TYPE_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/edit-event-type/edit-event-type.token';
import { ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { EventType } from '@http/property/property.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-event-types',
  templateUrl: './event-types.component.html',
  styleUrls: ['./event-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTypesComponent implements OnInit {
  @Input()
  currentApp!: App;

  @Input()
  eventTypes: EventType[] = [];

  hasCustomEvents = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
    private readonly modalHelperService: ModalHelperService,
    private readonly eventTypeModel: EventTypeModel,
    private readonly carrotquestHelper: CarrotquestHelper,
  ) {}

  ngOnInit(): void {
    this.trackViewEventsList();
    this.checkCustomEvents();
  }

  checkCustomEvents() {
    this.hasCustomEvents = this.eventTypes.some((eventType) => !eventType.name.startsWith('$'));
  }

  openEditEventTypeModal(eventType: any) {
    this.modalHelperService
      .provide(EDIT_EVENT_TYPE_MODAL_DATA_TOKEN, {
        eventType,
      })
      .open(EditEventTypeComponent)
      .result.then((updatedEventType) => {
        this.updateEventTypesList(updatedEventType);
      });
  }

  openRemoveEventTypeModal(eventType: any) {
    this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('eventTypesComponent.removeEventTypeModal.heading'),
        body: this.translocoService.translate('eventTypesComponent.removeEventTypeModal.body', {
          name: eventType.prettyName,
        }),
        confirmButtonText: this.translocoService.translate(
          'eventTypesComponent.removeEventTypeModal.confirmButtonText',
        ),
      })
      .open(ConfirmModalComponent)
      .result.then(() => {
        this.removeEventType(eventType);
      });
  }

  removeEventType(eventType: any) {
    eventType.visible = false;
    this.updateEventTypesList(eventType);
  }

  updateEventTypesList(eventType: any) {
    firstValueFrom(this.eventTypeModel.update(eventType)).then(() => {
      const index = this.eventTypes.findIndex((et) => et.id === eventType.id);
      if (index !== -1) {
        this.eventTypes[index] = eventType;
        this.cdr.markForCheck();
      }
    });
  }

  trackViewEventsList() {
    const eventName = 'Зашел на страницу событий';
    const eventParams = { App: this.currentApp.name };

    this.carrotquestHelper.track(eventName, eventParams);
  }

  trackById(index: number, item: EventType) {
    return item.id;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import orderBy from 'lodash-es/orderBy';
import without from 'lodash-es/without';
import { firstValueFrom } from 'rxjs';

import { UserNoteModel } from '@http/user-note/user-note.model';
import { UserNote } from '@http/user-note/user-note.types';
import { LS_UNCOLLAPSED_USER_CARD_BLOCKS } from '@panel/app/shared/constants/localstorage.keys';
import { noWhitespaceValidator } from '@panel/app/shared/validators/no-whitespace/no-whitespace-validator';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Компонент для отображения заметок о пользователе */
@Component({
  selector: 'cq-user-notes-list[userId][notesList]',
  templateUrl: './user-notes-list.component.html',
  styleUrls: ['./user-notes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotesListComponent implements OnInit {
  /** ID пользователя */
  @Input()
  userId!: string;

  /** Заметки о пользователе */
  @Input()
  set notesList(notes: UserNote[]) {
    this.orderedNotesList = orderBy(notes, ['created'], ['desc']);
  }

  /** Колбэк на добавление заметки о пользователе */
  @Output()
  addUserNoteFn: EventEmitter<UserNote> = new EventEmitter<UserNote>();

  /** Колбэк на удаление заметки о пользователе */
  @Output()
  removeUserNoteFn: EventEmitter<UserNote> = new EventEmitter<UserNote>();

  /** Колбек на смену состояния коллапса */
  @Output()
  collapseStateChange: EventEmitter<string> = new EventEmitter();

  /** Контрол для добавления новой заметки о пользователе */
  controlUserNote: UntypedFormControl = new UntypedFormControl(null, [Validators.required, noWhitespaceValidator]);

  /** Флаг свернутого состояния */
  isCollapsed: boolean = true;

  /** Флаг выполнения запроса на добавление заметки о пользователе */
  isRequestPerformed: boolean = false;

  /** Показываются ли все заметки */
  isShowAllNotes: boolean = false;

  /** Заметки о пользователе, отсортированные по дате создания */
  orderedNotesList: UserNote[] = [];

  /** Максимальное количество отображаемых заметок по умолчанию */
  VISIBLE_USER_NOTES_LENGTH = 3;

  /** Текущее ограничение по количеству отображаемых заметок */
  visibleUserNotesLimit: number | undefined = this.VISIBLE_USER_NOTES_LENGTH;

  /** Имя для localeStorage */
  userNotesLSName = 'user_notes';

  get userNotesCount(): number {
    return this.orderedNotesList.length;
  }

  ngOnInit() {
    if (localStorage.getItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS)?.includes(this.userNotesLSName)) {
      this.isCollapsed = false;
    }
  }

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly cdr: ChangeDetectorRef,
    private readonly userNoteModel: UserNoteModel,
  ) {}

  /** Добавление заметки о пользователе */
  addUserNote(): void {
    if (this.controlUserNote.valid) {
      this.isRequestPerformed = true;

      firstValueFrom(this.userNoteModel.create(this.userId, this.controlUserNote.value))
        .then((userNote: UserNote) => {
          this.orderedNotesList = orderBy([...this.orderedNotesList, userNote], ['created'], ['desc']);
          this.addUserNoteFn.emit(userNote);

          this.trackAddUserNote(this.controlUserNote.value);
        })
        .finally(() => {
          this.isRequestPerformed = false;
          this.controlUserNote.reset();
          this.cdr.detectChanges();
        });
    }
  }

  /** Необходимо показать кнопку «Показать все» [заметки], если количество заметок больше обозначенного числа */
  isShowShowAllNotesButton(): boolean {
    return this.userNotesCount > this.VISIBLE_USER_NOTES_LENGTH;
  }

  /** Удаление заметки о пользователе */
  onRemoveNote(userNote: UserNote): void {
    firstValueFrom(this.userNoteModel.remove(userNote.id)).then(() => {
      this.orderedNotesList = without(this.orderedNotesList, userNote);
      this.removeUserNoteFn.emit(userNote);
      this.cdr.detectChanges();
    });
  }

  /** Показ и скрытие заметок о пользователе */
  toggleVisibleUserNotesFilter(): void {
    this.isShowAllNotes = !this.isShowAllNotes;

    if (this.isShowAllNotes) {
      this.visibleUserNotesLimit = undefined;
    } else {
      this.visibleUserNotesLimit = this.VISIBLE_USER_NOTES_LENGTH;
    }
  }

  /**
   * Трек добавления заметки пользователю
   *
   * @param noteText Текст заметки
   */
  trackAddUserNote(noteText: UserNote['body']): void {
    const event = 'Пользователи - оставил заметку';
    const eventProps = {
      'Текст заметки': noteText,
    };

    this.carrotquestHelper.track(event, eventProps);
  }
}

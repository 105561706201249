/**
 * Контроллер для универсального модального окна безопасного удаления
 *
 * При создании модального окна в resolve можно передать слудющие параметры:
 *    {String=} body Текст тела модального окна
 *    {String=} confirmButtonText Текст кнопки подтвеждения удаления
 *    {String} confirmedItemName Что надо вести для подтверждения
 *    {String=} confirmText Текст описания того что надо сделать
 *    {String=} heading Текст заголовка модального окна
 */
(function () {
  'use strict';

  angular.module('myApp.modals.safeRemove').controller('SafeRemoveModalController', SafeRemoveModalController);

  function SafeRemoveModalController(
    $interpolate,
    $q,
    $translate,
    $uibModalInstance,
    validationHelper,
    body,
    confirmButtonText,
    confirmedItemName,
    confirmText,
    heading,
  ) {
    var vm = this;

    vm.body = body;
    vm.confirm = confirm;
    vm.confirmButtonText = confirmButtonText || $translate.instant('general.remove');
    vm.confirmedItemName = confirmedItemName;
    vm.confirmText =
      confirmText || $translate.instant('modals.safeRemove.confirmText', { itemName: confirmedItemName });
    vm.heading = heading || $translate.instant('modals.safeRemove.title');
    vm.validationHelper = validationHelper;

    init();

    function init() {}

    /**
     * Обработка нажатия на кнопку подтверждения
     *
     * @param isValid Валидация формы
     */
    function confirm(isValid) {
      if (isValid) {
        $uibModalInstance.close();
      }
    }
  }
})();

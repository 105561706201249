import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BranchActionAppOfflineConditionModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/app-offline-condition/app-offline-condition.module';
import { BranchActionAppOnlineConditionModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/app-online-condition/app-online-condition.module';

import { AppOnlineConditionEditorComponent } from './app-online-condition-editor.component';

@NgModule({
  declarations: [AppOnlineConditionEditorComponent],
  imports: [CommonModule, BranchActionAppOfflineConditionModule, BranchActionAppOnlineConditionModule],
  exports: [AppOnlineConditionEditorComponent],
})
export class AppOnlineConditionEditorModule {}

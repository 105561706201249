(function () {
  'use strict';

  require('./auto-event-properties.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventProperties', cqAutoEventProperties());

  function cqAutoEventProperties() {
    return {
      bindings: {
        params: '=', // Свойста автособытия
        type: '@', // Тип автособытия
      },
      controller: 'CqAutoEventPropertiesController',
      controllerAs: 'vm',
      template: require('./auto-event-properties.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { KnowledgeBaseSettingsModalComponent } from './knowledge-base-settings-modal.component';

@NgModule({
  declarations: [KnowledgeBaseSettingsModalComponent],
  exports: [KnowledgeBaseSettingsModalComponent],
  imports: [CommonModule, FormsModule, NgbNavModule, TranslocoModule, SharedModule, ReactiveFormsModule],
})
export class KnowledgeBaseSettingsModalModule {}

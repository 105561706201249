import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

import { DATA_SOURCE, DATA_SOURCE_FILTERED } from '@http/ai-data/ai-data.constants';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-source-filter',
  templateUrl: './source-filter.component.html',
  styleUrls: ['./source-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceFilterComponent extends AbsCVAFormControlBasedComponent<DATA_SOURCE> {
  @ViewChild(NgSelectComponent)
  sourceSelect!: NgSelectComponent;

  readonly control: FormControl<DATA_SOURCE> = new FormControl<DATA_SOURCE>(DATA_SOURCE.ALL, {
    nonNullable: true,
    validators: [Validators.required],
  });

  readonly DATA_SOURCE = DATA_SOURCE;
  readonly DATA_SOURCE_FILTERED = DATA_SOURCE_FILTERED;

  getSourceIcon(status: DATA_SOURCE) {
    switch (status) {
      case DATA_SOURCE.ALL:
        return '';
      case DATA_SOURCE.KNOWLEDGE_BASE:
        return 'cqi-book';
    }
  }
}

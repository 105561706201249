import './directory-editor/directory-editor.module';

import { module } from 'angular';

require('./access-denial-modal-wrapper/access-denial-wrapper.module');
require('./add-answer-template/add-answer-template.module');
require('./add-tag/add-tag.module');
require('./add-user-property/add-user-property.module');
require('./assign-new-default-message-sender/assign-new-default-message-sender.module');
require('./bee-editor/bee-editor.module');
require('./change-routing-bot-active-status-confirm-wrapper/change-routing-bot-active-status-confirm.module');
require('./chat-bot-template-wrapper/chat-bot-template-wrapper.module');
require('./code-installed-success/code-installed-success.module');
require('./complete-ab-test/complete-ab-test.module');
require('./completion-registration-modal-wrapper/completion-registration-modal-wrapper.module');
require('./confirm/confirm.module');
require('./create-url-auto-event-wrapper/create-url-auto-event-wrapper.module');
require('./create-user-property/create-user-property.module');
require('./remove-user-property/remove-user-property.module');
require('./double-opt-in-email-preview/double-opt-in-email-preview.module');
require('./double-opt-in-subscription-confirm-preview/double-opt-in-subscription-confirm-preview.module');
require('./downgrade-abilities-wrapper/downgrade-abilities-wrapper.module');
require('./edit-amocrm-status/edit-amocrm-status.module');
require('./edit-bitrix24-status/edit-bitrix24-status.module');
require('./edit-retailcrm-status/edit-retailcrm-status.module');
require('./edit-user-property/edit-user-property.module');
require('./email-template-preview/email-template-preview.module');
require('./file-conversation/file-conversation.module');
require('./funnel-editor/funnel-editor.module');
require('./insert-props-into-text-wrapper/insert-props-into-text-wrapper.module');
require('./lead-bot-settings/lead-bot-settings.module');
require('./leave-site-attempt-setting-wrapper/leave-site-attempt-setting-wrapper.module');
require('./lightweight-email-template-preview/lightweight-email-template-preview.module');
require('./limit-of-unique-visitors/limit-of-unique-visitors.module');
require('./manual-user-merge/manual-user-merge.module');
require('./member-invitation-modal-wrapper/member-invitation-modal-wrapper.module');
require('./message-planning/message-planning.module');
require('./modal-wrapper/modal-wrapper.module');
require('./move-start-guide-modal-wrapper/move-start-guide-modal-wrapper.module');
require('./order-data-collection-settings/order-data-collection-settings.module');
require('./original-inbound-email-view-modal-wrapper/original-inbound-email-view-modal-wrapper.module');
require('./party-is-over/party-is-over.module');
require('./picture-preview/picture-preview.module');
require('./prompt/prompt.module');
require('./remove-conversation-part/remove-conversation-part.module');
require('./quiz-in-registration-modal-wrapper/quiz-in-registration-modal-wrapper.module');
require('./safe-remove/safe-remove.module');
require('./save-message-part-as-template/save-message-part-as-template.module');
require('./save-ready-message-template-as-template/save-ready-message-template-as-template.module');
require('./save-segment/save-segment.module');
require('./send-manual-message/send-manual-message.module');
require('./send-script-instructions-wrapper/send-script-instructions-wrapper.module');
require('./send-subscription-confirmation-email/send-subscription-confirmation-email.module');
require('./set-template-directory/set-template-directory.module');
require('./team-members-group-editor/team-members-group-editor.module');
require('./unsubscribe-from-emails/unsubscribe-from-emails.module');
require('./unsubscribe-sdk-push/unsubscribe-sdk-push.module');
require('./unsubscribe-web-push/unsubscribe-web-push.module');
require('./video/video.module');
require('./visual-components-test/visual-components-test.module');
require('./write-to-integrations-wrapper/write-to-integrations-wrapper.module');

module('myApp.modals', [
  'myApp.modals.accessDenialModalWrapper',
  'myApp.modals.addAnswerTemplate',
  'myApp.modals.addTag',
  'myApp.modals.addUserProperty',
  'myApp.modals.assignNewDefaultMessageSender',
  'myApp.modals.beeEditor',
  'myApp.modals.changeRoutingBotActiveStatusConfirmWrapper',
  'myApp.modals.chatBotTemplateModalWrapper',
  'myApp.modals.codeInstalledSuccess',
  'myApp.modals.completeAbTest',
  'myApp.modals.completionRegistrationModalWrapper',
  'myApp.modals.confirm',
  'myApp.modals.createUrlAutoEventWrapper',
  'myApp.modals.createUserProperty',
  'myApp.modals.directoryEditor',
  'myApp.modals.downgradeAbilitiesWrapper',
  'myApp.modals.doubleOptInEmailPreview',
  'myApp.modals.doubleOptInSubscriptionConfirmPreview',
  'myApp.modals.editAmocrmStatus',
  'myApp.modals.editBitrix24Status',
  'myApp.modals.editRetailcrmStatus',
  'myApp.modals.editUserProperty',
  'myApp.modals.emailTemplatePreview',
  'myApp.modals.fileConversation',
  'myApp.modals.funnelEditor',
  'myApp.modals.insertPropsIntoTextWrapper',
  'myApp.modals.leadBotSettings',
  'myApp.modals.leaveSiteAttemptSettingWrapper',
  'myApp.modals.lightweightEmailTemplatePreview',
  'myApp.modals.limitOfUniqueVisitors',
  'myApp.modals.manualUserMerge',
  'myApp.modals.memberInvitationModalWrapper',
  'myApp.modals.messagePlanning',
  'myApp.modals.modalWrapper',
  'myApp.modals.moveStartGuideModalWrapper',
  'myApp.modals.orderDataCollectionSettings',
  'myApp.modals.originalInboundEmailViewModalWrapper',
  'myApp.modals.partyIsOver',
  'myApp.modals.picturePreview',
  'myApp.modals.prompt',
  'myApp.modals.removeConversationPart',
  'myApp.modals.removeUserProperty',
  'myApp.modals.quizInRegistrationModalWrapper',
  'myApp.modals.safeRemove',
  'myApp.modals.saveMessagePartAsTemplate',
  'myApp.modals.saveReadyMessageTemplateAsTemplateModal',
  'myApp.modals.saveSegment',
  'myApp.modals.sendManualMessage',
  'myApp.modals.sendScriptInstructionsWrapper',
  'myApp.modals.sendSubscriptionConfirmationEmail',
  'myApp.modals.teamMembersGroupEditor',
  'myApp.modals.templateSetDirectory',
  'myApp.modals.unsubscribeFromEmails',
  'myApp.modals.unsubscribeSdkPush',
  'myApp.modals.unsubscribeWebPush',
  'myApp.modals.video',
  'myApp.modals.visualComponentsTest',
  'myApp.modals.writeToIntegrationsWrapper',
]);

<ng-container>
  <h1
    class="font-weight-bold mb-30"
    [innerHTML]="question.title | transloco: { projectName: projectName }"
  ></h1>
  <button
    *ngFor="let answer of question.answers"
    (click)="this.answerSelect.next(answer)"
    [ngClass]="{ active: savedAnswer?.id === answer.id }"
    class="btn btn-outline-primary btn-lg text-left w-100 mb-20"
    type="button"
  >
    {{ answer.text | transloco }}
  </button>
</ng-container>

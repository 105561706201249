<ng-container *transloco="let t; read: 'deleteDjangoUserModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="mb-20">{{ t('body.description1', {projectName}) }}</div>
    <ul>
      <li *ngFor="let item of whatWillHappen">{{ item }}</li>
    </ul>
    <div class="mb-20">{{ t('body.description2') }}</div>
    <form
      (ngSubmit)="deleteDjangoUser()"
      [formGroup]="deleteDjangoUserForm"
    >
      <div
        class="form-group"
        [cqShowError]="deleteDjangoUserForm.controls.password"
      >
        <input
          class="form-control"
          type="password"
          [formControl]="deleteDjangoUserForm.controls.password"
          [placeholder]="t('body.passwordInput.placeholder')"
        />
        <cq-validation-messages [control]="deleteDjangoUserForm.controls.password">
          <cq-validation-message errorName="required">{{
            t('body.passwordInput.errors.required')
          }}</cq-validation-message>
          <cq-validation-message errorName="wrongPassword">{{
            t('body.passwordInput.errors.wrongPassword')
          }}</cq-validation-message>
          <cq-validation-message errorName="forbiddenApps">{{
            t('body.passwordInput.errors.forbiddenApps', {
              appCount: forbiddenApps.length,
              appNames: forbiddenAppNames
            })
          }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <input
        class="hidden"
        id="deleteDjangoUserForm.submit"
        type="submit"
        [disabled]="isDeleteRequestPerformed"
      />
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <label
      class="btn btn-danger"
      for="deleteDjangoUserForm.submit"
      [ngClass]="{ disabled: isDeleteRequestPerformed }"
      >{{ t('footer.deleteMyAccountButton') }}</label
    >
  </div>
</ng-container>

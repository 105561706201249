<ng-container *transloco="let t; read: 'memberActionsTableComponent'">
  <table class="table table-align-middle table-sticky-header">
    <thead>
      <tr>
        <th>{{ t('table.head.data') }}</th>
        <th>{{ t('table.head.action') }}</th>
        <th>{{ t('table.head.user') }}</th>
        <th>{{ t('table.head.userId') }}</th>
        <th>{{ t('table.head.ip') }}</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let log of logs; index as logIndex">
        <!-- Дата и время -->
        <td class="py-10">
          <div>{{ $any(log.created) | amDateFormat: 'L' }}</div>
          <small class="text-muted">{{ $any(log.created) | amDateFormat: 'LT' }}</small>
        </td>

        <!-- Действие -->
        <td class="py-10">
          <div>{{ getActionsText(log.type, log.metaData) }}</div>
        </td>

        <!-- Пользователь -->
        <td class="py-10">
          <!-- Имя -->
          <div *ngIf="log.adminName; else nameUndefined">
            {{ log.adminName ? log.adminName : t('table.body.user.nameUndefined') }}
          </div>
          <ng-template #nameUndefined>
            <div class="text-muted">{{ t('table.body.user.nameUndefined') }}</div>
          </ng-template>
          <!-- Email -->
          <small
            *ngIf="isEmail(log.adminEmailOrTokenLast4); else apiKey"
            class="text-muted"
          >
            {{ log.adminEmailOrTokenLast4 }}
          </small>
          <ng-template #apiKey>
            <small>{{ t('table.body.apiKey') + ' ' + log.adminEmailOrTokenLast4 }}</small>
          </ng-template>
        </td>

        <!-- User ID -->
        <td class="py-10">
          <div *ngIf="log.adminId; else emptyUserId">{{ log.adminId }}</div>
          <ng-template #emptyUserId>
            <div class="text-muted">-</div>
          </ng-template>
        </td>

        <!-- IP адрес -->
        <td class="py-10">
          <div>{{ log.ipAddress }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { WritePropertyValueForm } from '@panel/app/partials/track-master/write-property-value/write-property-value.component';
import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { App } from '@http/app/app.model';
import { UserProperty } from '@http/property/property.model';

@Component({
  selector: 'cq-selectors-properties[currentApp][properties]',
  templateUrl: './selectors-properties.component.html',
  styleUrls: ['./selectors-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectorsPropertiesComponent extends AbstractCVAControl<WritePropertyValueForm[]> {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  @Input()
  properties: UserProperty[] = [];

  @Output()
  propertiesChange = new Subject<UserProperty[]>();

  control = this.fb.array<GenericFormControl<WritePropertyValueForm>>([]);

  constructor(
    private readonly fb: GenericFormBuilder,
    private readonly cdr: ChangeDetectorRef,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }

  /**
   * Программно обновляем список контролов.
   * Сначала чистим, потом поштучно добавляем.
   */
  writeValue(values: WritePropertyValueForm[]) {
    while (this.control.length) {
      this.control.removeAt(0);
    }
    if (!values) {
      return;
    }
    values.forEach((val) => {
      const control = this.getNewControl(val);
      this.control.push(control);
    });
    this.cdr.markForCheck();
  }

  /**
   * Добавляет новый контрол в форму
   */
  addControl() {
    const control = this.getNewControl();
    this.control.push(control);
  }

  /**
   * Получаем чистый контрол если нет данных, и с данными, если их передать
   */
  private getNewControl(data?: WritePropertyValueForm): GenericFormControl<WritePropertyValueForm> {
    const controlData: WritePropertyValueForm = {
      propertyName: data ? data.propertyName : null,
      value: data ? data.value : null,
    };
    return this.fb.control(controlData);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdown, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLetModule } from '@taiga-ui/cdk';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { CancellationExactlyModalModule } from '@panel/app/pages/subscription/general/signed/plan-info/name/cancellation-exactly-modal/cancellation-exactly-modal.module';
import { CancellationPreventModalModule } from '@panel/app/pages/subscription/general/signed/plan-info/name/cancellation-prevent-modal/cancellation-prevent-modal.module';
import { SubscribeIntentModule } from '@panel/app/pages/subscription/general/signed/plan-info/name/subscribe-intent/subscribe-intent.module';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

import { NameComponent } from './name.component';

@NgModule({
  declarations: [NameComponent],
  exports: [NameComponent],
  imports: [
    CancellationExactlyModalModule,
    CancellationPreventModalModule,
    CommonModule,
    NgbDropdownModule,
    NgbTooltipModule,
    TranslocoModule,
    TuiLetModule,
    SubscribeIntentModule,
  ],
  providers: [CarrotquestHelperProvider, NgbDropdown, StateService, SubscriptionStore],
})
export class NameModule {}

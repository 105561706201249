import { Inject, Injectable, NgZone } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Viewport } from 'pixi-viewport';
import { merge, Observable } from 'rxjs';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ConnectionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { PIXI_APP, PIXI_VIEWPORT, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { BRANCH_VIEW_MAP, BranchViewMap } from '@panel/app/pages/chat-bot/content/tokens/branch-collection.token';
import {
  BotAmocrmNotificationAction,
  BotAppOnlineConditionAction,
  BotButtonAction,
  BotButtonsPropertyAction,
  BotChannelAction,
  BotCloseAction,
  BotDefaultConditionAction,
  BotEmailNotificationAction,
  BotEventAction,
  BotFileAction,
  BotMarkConversationVisibleAction,
  BotOperatorAction,
  BotPropertyAction,
  BotPropertyFieldAction,
  BotTextAction,
  BotUserTagAction,
} from '@panel/app/pages/chat-bot/content/views/actions';
import { BaseActionABS } from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { BotAssistantAction } from '@panel/app/pages/chat-bot/content/views/actions/assistant/assistant.action';
import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { BotMeetingAction } from '@panel/app/pages/chat-bot/content/views/actions/meeting-action/meeting-action';
import { BotNextAction } from '@panel/app/pages/chat-bot/content/views/actions/next.action';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

/**
 * Фабрика нужна, чтоб избавиться от сквозных зависимостей через ветку. Ветке совсем не обязательно знать,
 * какие параметры нужны действию для создания
 */
@Injectable()
export class ActionFactory {
  constructor(
    private readonly botScenariosHelper: BotScenariosHelper,
    private readonly connectionFactory: ConnectionFactory,
    private readonly fileHelperService: FileHelperService,
    private readonly stateService: StateService,
    @Inject(BRANCH_VIEW_MAP)
    private readonly branchesMap: BranchViewMap,
    private readonly ngZone: NgZone,
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
    private readonly interactionService: PixiInteractionService,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    private readonly translocoService: TranslocoService,
  ) {}

  create(action: ChatBotAction, branch: Branch): BotAction {
    const externalPositionChanges: Observable<void> = merge(branch.events.redraw$, branch.events.move$);
    action.currentBranchLinkId = branch.linkId;
    return this.ngZone.runOutsideAngular(() => {
      const args: AbstractConstructorParameters<typeof BaseActionABS> = [
        action,
        this.botScenariosHelper,
        this.connectionFactory,
        this.fileHelperService,
        this.branchesMap,
        externalPositionChanges,
        {
          amocrmIntegrations: this.stateService.amocrmIntegrationsExternal$.getValue(),
          channels: this.stateService.channels$.getValue(),
          teamMembers: this.stateService.teamMembers$.getValue(),
        },
        this.interactionService,
        this.viewport,
        this.pixiApp,
      ];

      switch (action.type) {
        case CHAT_BOT_ACTIONS_TYPES.TEXT:
          return new BotTextAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.BUTTON:
          return new BotButtonAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.EVENT:
          return new BotEventAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION:
          return new BotEmailNotificationAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.USER_TAG:
          return new BotUserTagAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.PROPERTY:
          return new BotPropertyAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION:
          return new BotAmocrmNotificationAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.FILE:
          return new BotFileAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD:
          return new BotPropertyFieldAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE:
          return new BotMarkConversationVisibleAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.MEET:
          return new BotMeetingAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.ASSISTANT:
          return new BotAssistantAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.OPERATOR:
          return new BotOperatorAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.CHANNEL:
          return new BotChannelAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY:
          return new BotButtonsPropertyAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.CLOSE:
          return new BotCloseAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION:
          return new BotAppOnlineConditionAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.NEXT:
          return new BotNextAction(...args);
        case CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION:
          let canvasText: string;
          if (branch.branchType === 'appOnlineCondition') {
            canvasText = this.translocoService.translate(
              'classes.action.canvasText.defaultCondition.appOfflineCondition',
            );
          } else {
            throw new Error('Specify canvas text for default condition');
          }
          return new BotDefaultConditionAction(canvasText, ...args);
        default:
          throw new Error(`Could not find action constructor for specified type: ${action.type}`);
      }
    });
  }
}

<ng-container *transloco="let t; read: 'cqButtonBlocksComponent'">
  <ng-container *ngFor="let button of control.controls; last as isLast; index as i">
    <cq-button-block-editor
      [index]="i"
      [lastIndex]="lastIndex"
      [currentApp]="currentApp"
      [formControl]="button"
      (moveUp)="moveButtonBlock(i, i - 1)"
      (moveDown)="moveButtonBlock(i, i + 1)"
      (duplicate)="copyButtonBlock(i)"
      (delete)="deleteButtonBlock(i)"
      [isDuplicateDisabled]="!isAllowToAddBlock"
      class="mb-20"
    ></cq-button-block-editor>
    <hr
      *ngIf="!isLast"
      class="mb-20"
    />
  </ng-container>

  <button
    class="btn btn-secondary btn-block"
    type="button"
    (click)="addButtonBlock()"
    [disabled]="!isAllowToAddBlock"
  >
    <i class="btn-icon-left cqi-sm cqi-plus"></i>
    <span>{{ control.value.length ? t('addAnotherButton') : t('addButton') }}</span>
  </button>
</ng-container>

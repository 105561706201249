import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

export const COMPANY_INFO_MAX_LENGTH = 150;

@Component({
  selector: 'cq-company-info-control',
  templateUrl: './company-info-control.component.html',
  styleUrls: ['./company-info-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyInfoControlComponent extends AbsCVAFormControlBasedComponent<string | null> {
  readonly control: FormControl<string | null> = new FormControl<string | null>('', {
    nonNullable: false,
    validators: [Validators.required, Validators.maxLength(COMPANY_INFO_MAX_LENGTH)],
  });

  COMPANY_INFO_MAX_LENGTH = COMPANY_INFO_MAX_LENGTH;

  constructor() {
    super();
  }
}

import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Injectable()
export class DatetimePickerI18nService extends NgbDatepickerI18n {
  getWeekdayLabel(weekday: number): string {
    return moment()
      .weekday(weekday - 1)
      .format('dd');
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getMonthShortName(month: number, year?: number): string {
    return moment().month(month).format('MMM');
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from '@environment';
import { STARTER_GUIDE_SOCIAL_INTEGRATION_SKIPPED } from '@http/app/app.constants';

import { CarrotquestHelper } from '../../../../app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-starter-guide-social-integrations[isStepMade]',
  templateUrl: './starter-guide-social-integrations.component.html',
  styleUrls: ['./starter-guide-social-integrations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarterGuideSocialIntegrationsComponent {
  /** Пройден ли текущий шаг стартергайда */
  @Input() isStepMade!: boolean;
  /** Установка текущего шага - пройденным */
  @Output() setCurrentStepIsMade: EventEmitter<void> = new EventEmitter<void>();
  /** Текущее название проекта */
  projectName = environment.projectName;

  constructor(private readonly carrotquestHelper: CarrotquestHelper) {}

  /** Показывать ли ссылку для пропуска стартергайда*/
  isShowSkippedLink(): boolean {
    const isCurrentStepSkipped = this.isStepSkipped;
    const isCurrentStepComplete = this.isStepMade;

    if (isCurrentStepSkipped || isCurrentStepComplete) {
      return false;
    }

    return true;
  }

  /** Пропустить шаг "Настройка социальных сетей" */
  skipStarterGuideSocialIntegrationsStep() {
    localStorage.setItem(STARTER_GUIDE_SOCIAL_INTEGRATION_SKIPPED, 'true');

    this.trackSkipStarterGuideSocialIntegrationsStep();

    this.setCurrentStepIsMade.emit();
  }

  /**
   * Был ли шаг пропущен
   */
  get isStepSkipped(): boolean {
    /*
     * TODO вместо получения данных из localStorage следует завязаться на активации по полю social_network_integrations_reply_user
     *  т.к. если пользователь пропустит шаг, а потом настроит соцсеть, сообщение о пройденом шаге будет для пропущенного шага
     *  не стал делать это сейчас чтобы не плодить новые запросы на получение активации или
     *  передачу и актуализацию данных об активациях между приложениями
     */
    return !!localStorage.getItem(STARTER_GUIDE_SOCIAL_INTEGRATION_SKIPPED);
  }

  /** Трекинг пропуска шага "Настройка социальных сетей" */
  trackSkipStarterGuideSocialIntegrationsStep() {
    const event = 'Стартергайд - пропустил шаг с настройкой соцсетей';

    this.carrotquestHelper.track(event);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { copy, extend, isObject, isUndefined } from 'angular';
import moment from 'moment';
import { map, tap } from 'rxjs/operators';

import { UserTag } from '@http/user/types/user.type';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';

/**
 * Сервис для работы с тегами пользователей
 */
@Injectable({ providedIn: 'root' })
export class TagModel {
  constructor(private readonly http: HttpClient, private readonly filterAjsModel: FilterAjsModel) {}

  tagList: UserTag[] = [];

  /**
   * Добавление массива созданных тегов в общий массив тегов
   *
   * @param createdTags Массив созданных тегов
   * @param tagListReference Общий массив тегов (reference на него)
   * @returns Массив созданных тегов createdTags
   */
  addCreatedTags(createdTags: UserTag[], tagListReference: any[]) {
    let needToReSort = false;

    // в response.data вернутся добавленные теги
    for (let i = 0; i < createdTags.length; i++) {
      if (!tagListReference.filter((tag) => tag.id === createdTags[i].id).length) {
        this.parseToInternalFormat(createdTags[i]);
        tagListReference.push(createdTags[i] as never);
        needToReSort = true;
      }
    }

    if (needToReSort) {
      tagListReference.sort(this.sortTagListByTagName);
    }

    return createdTags;
  }

  /**
   * Получение тега по его ID
   *
   * @param tagId ID тега
   */
  getById(tagId: string) {
    return this.http.get<UserTag>('/tags/' + tagId).pipe(
      map((tag) => {
        this.parseToInternalFormat(tag);

        return tag;
      }),
    );
  }

  /**
   * Получение всех тегов, присвоенных пользователю по его ID
   *
   * @param userId ID пользователя
   * @param withRemoved=false Если не задан - возвращает все
   *    Если true - удаленные
   */
  getByUserId(userId: string, withRemoved: boolean = false) {
    let params = {
      removed: withRemoved,
    };

    return this.http.get<{ tags: UserTag[] }>('/users/' + userId + '/tags', { params }).pipe(
      map(({ tags }) => {
        for (let i = 0; i < tags.length; i++) {
          this.parseToInternalFormat(tags[i]);
        }

        tags.sort((a, b) => a.name.localeCompare(b.name));

        return tags;
      }),
    );
  }

  /**
   * Получение списка тегов для текущего сайта
   *
   * @param appId ID приложения
   * @param withRemoved Если не задан - возвращает все
   *    Если true - удаленные
   */
  getList(appId: string, withRemoved: boolean = false) {
    let params = {
      removed: withRemoved,
    };

    return this.http.get<{ tags: UserTag[] }>('/apps/' + appId + '/tags', { params }).pipe(
      map(({ tags }) => {
        for (let i = 0; i < tags.length; i++) {
          this.parseToInternalFormat(tags[i]);
        }

        // затираем предыдущие теги, сохраняя при этом reference
        this.tagList.length = 0;
        extend(this.tagList, tags);

        this.tagList.sort(this.sortTagListByTagName);

        return this.tagList;
      }),
    );
  }

  /**
   * Парсинг тега во внутреннее преставление в админке
   *
   * @param {Object} tag Тег для парсинга
   */
  parseToInternalFormat(tag: UserTag) {
    if (tag.removed) {
      // @ts-ignore
      tag.removed = moment(tag.removed * 1000);
    }
    tag.name = JSON.parse(tag.name);
  }

  /**
   * Парсит масив с тегами во внутренний формат сервера.
   * Бэк принимает теги как массив строк [""element1"", ""element2"", ... ]
   *
   * @param tags - массив тегов пользователей
   */
  parseToServerFormat(tags: Array<UserTag>) {
    let parsedTags = [];

    for (let i = 0; i < tags.length; i++) {
      parsedTags.push('"' + tags[i].name + '"');
    }

    return parsedTags;
  }

  /**
   * Удаление тега из системы по его ID
   *
   * @param tagId ID тега
   */
  removeById(tagId: string) {
    return this.http.delete('/tags/' + tagId).pipe(tap(() => this.removeByIdSuccess(tagId)));
  }

  removeByIdSuccess(tagId: string) {
    let removedTag = this.tagList.filter((tag) => tag.id === tagId);
    if (removedTag.length == 1) {
      this.tagList.splice(this.tagList.indexOf(removedTag[0] as never), 1);
    }
  }

  /**
   * Удаление тега (списка тегов) у пользователя по его ID
   *
   * @param userId ID пользователя
   * @param tagId ID тега (список ID тегов), которые надо удалить у пользователя
   */
  removeByUserId(userId: string, tagId: string | string[]) {
    let body: { tags: string } = {
      tags: '',
    };

    if (tagId.constructor == Array) {
      body.tags = JSON.stringify(tagId);
    } else if (tagId.constructor == Number || tagId.constructor == String) {
      body.tags = JSON.stringify([tagId]);
    } else {
      throw TypeError('tagId must be an Array of Numbers or Strings of digits, a Number or a String');
    }

    return this.http.delete('/users/' + userId + '/tags', { body });
  }

  /**
   * Массовое удаление тега (списка тегов) по ID у списка пользователей
   *
   * @param appId ID приложения
   * @param userIdList Список ID пользователей, либо ID одного пользователя, либо строка-фильр Elasticsearch, которым следует присвоить тег
   * @param tagId ID тега (массив ID тегов), которые надо удалить у пользователей
   */
  removeFromUsers(appId: string, userIdList: string | Array<string>, tagId: string) {
    let body: any = {};

    if (userIdList.constructor == Array) {
      body.users = JSON.stringify(userIdList);
    } else if (userIdList.constructor == Number) {
      body.users = JSON.stringify([userIdList]);
    } else if (userIdList.constructor == String) {
      body.filters = userIdList;
    } else {
      throw TypeError('userIdList must be an Array of Numbers or Strings of digits, a Number or a String');
    }

    if (tagId.constructor == Array) {
      body.tags = JSON.stringify(tagId);
    } else if (tagId.constructor == Number || tagId.constructor == String) {
      body.tags = JSON.stringify([tagId]);
    } else {
      throw TypeError('tagId must be an Array of Numbers or Strings of digits, a Number or a String');
    }

    return this.http.delete('/apps/' + appId + '/tags_bulk', { body });
  }

  /**
   * Установка тега всем пользователям, которым было отправлено сообщение с начала периода до конца периода
   *
   * @param tag Строковый тег, либо массив строк-тегов
   * @param messageId ID сообщения, получателям которого надо добавить тег
   * @param sendingType Фильтр пользователей в сообщении
   */
  setBySendingType(tag: string | Array<string>, messageId: string, sendingType: any) {
    let tags = [];

    if (tag.constructor == String) {
      tags.push(JSON.stringify(tag));
    } else if (tag.constructor == Array) {
      tags = tag;

      for (let i = 0; i < tags.length; i++) {
        tags[i] = JSON.stringify(tags[i]);
      }
    } else if (tag.constructor != Array) {
      throw Error('tag must be an Array or a String');
    }

    if (isUndefined(messageId)) {
      throw Error('messageId must be specified');
    }

    if (isUndefined(sendingType)) {
      throw Error('sendingType must be specified');
    }

    let body = {
      filter: sendingType,
      tags: JSON.stringify(tag),
    };

    return this.http.put<{ tags: Array<UserTag> }>('/messages/' + messageId + '/tag_users', body).pipe(
      map(({ tags }) => {
        return this.addCreatedTags(tags, this.tagList);
      }),
    );
  }

  /**
   * Установка тега (списка тегов) пользователю по его ID
   *
   * @param userId ID пользователя
   * @param tag Строковый тег, либо массив строк-тегов
   * @param tagListReference Ссылка на массив, который надо будет обновить после успешной установки тегов пользователю
   */
  setByUserId(userId: string, tag: string | string[], tagListReference: []) {
    let tags: any = [];

    if (typeof tag === 'string') {
      tags.push(JSON.stringify(tag));
    } else if (Array.isArray(tag)) {
      tags = tag;

      for (let i = 0; i < tags.length; i++) {
        tags[i] = JSON.stringify(tags[i]);
      }
    } else {
      throw TypeError('tag must be an Array or a String');
    }

    let body = {
      tags: JSON.stringify(tags),
    };

    return this.http.put<{ tags: Array<UserTag> }>('/users/' + userId + '/tags', body).pipe(
      map(({ tags }) => {
        if (tagListReference) {
          // функция addCreatedTags работает с объектами массива по ссылке, меняет их
          // из-за этого нужно копировать объект с тегами, потому что эта функция далее вызывается повторно
          this.addCreatedTags(copy(tags), tagListReference);
        }
        return this.addCreatedTags(tags, this.tagList);
      }),
    );
  }

  /**
   * Устанавливка тега (списка тегов) списку пользователей
   * @param appId ID приложения
   * @param userIdList Список ID пользователей, либо ID одного пользователя, которым следует присвоить тег, либо строка-фильр Elasticsearch
   * @param tag Строковый тег, либо массив строк-тегов
   * @param tagListReference Ссылка на массив, который надо будет обновить после успешной установки тегов пользователю
   */
  setToUsers(
    appId: string,
    userIdList: string | Array<string>,
    tag: string | Array<string>,
    tagListReference: Array<UserTag>,
  ) {
    let body: any = {};
    let tags: Array<any> = [];

    if (Array.isArray(userIdList)) {
      // принудительно конвертируем все элементы массива в int
      body.users = JSON.stringify(userIdList);
    } else if (typeof userIdList === 'number') {
      body.users = JSON.stringify([userIdList]);
    } else if (typeof userIdList === 'string') {
      //TODO При полном переходе на новые сегменты эту проверку надо удалить
      body.filters = userIdList;
    } else if (isObject(userIdList)) {
      body.filters = this.filterAjsModel.parseToServerFormat(userIdList);
    } else {
      throw TypeError('userIdList must be an Array of Numbers or Strings of digits, a Number or a String');
    }

    if (tag.constructor == String) {
      tags.push(JSON.stringify(tag));
    } else if (tag.constructor == Array) {
      tags = tag;

      for (let i = 0; i < tags.length; i++) {
        tags[i] = JSON.stringify(tags[i]);
      }
    } else if (tag.constructor != Array) {
      throw TypeError('tag must be an Array or a String');
    }

    body.tags = JSON.stringify(tags);

    return this.http.put<{ tags: Array<UserTag> }>('/apps/' + appId + '/tags_bulk', body).pipe(
      map(({ tags }) => {
        if (tagListReference) {
          // функция addCreatedTags работает с объектами массива по ссылке, меняет их
          // из-за этого нужно копировать объект с тегами, потому что эта функция далее вызывается повторно
          this.addCreatedTags(copy(tags), tagListReference);
        }
        return this.addCreatedTags(tags, this.tagList);
      }),
    );
  }

  /**
   * Сортировка списка тегов по имени
   *
   * @param {Object} tagA Тег А
   * @param {Object} tagB Тег Б
   */
  sortTagListByTagName(tagA: UserTag, tagB: UserTag) {
    if (tagA.name > tagB.name) {
      return 1;
    }
    if (tagA.name < tagB.name) {
      return -1;
    }
    return 0;
  }
}

/**
 * Компонент для редактирования вида и содержания сообщения
 */
(function () {
  'use strict';

  require('./message-part-editor.controller');
  require('./bee-editor-popover.html');
  require('./templates-popover.html');
  require('./sdk-activation-popover.html');

  angular.module('myApp.messagePartEditor').component('cqMessagePartEditor', cqMessagePartEditor());

  function cqMessagePartEditor() {
    return {
      require: 'cqMessagePreviewAjs',
      bindings: {
        activeMessagesAmounts: '<', // Массив с количеством активых автоособщений по типам
        telegramIntegrations: '=', // список Telegram интеграций
        billingInfo: '=', // информация о биллинге
        canSendPush: '=', // можно ли отправлять пуши (показывать или нет этот тип сообщений)
        hideHidePreviewButton: '<?', // нужно ли скрыть кнопку "скрыть превью"
        djangoUser: '=', // текущий пользователь
        isManualMessage: '=', // Вызывается ли компонент в ручных сообщениях
        isTemplateEditor: '=', // Вызывается ли компонент в редакторе шаблонов CAR-63952
        messagePart: '=', // вариант сообщения для редактирования
        showAdvancedMessagePartTypes: '=?', // показывать или нет дополнительные типы сообщений
        toggleMessagePartTypeCallback: '&?', // Коллбэк при смене типа сообщения
        hideTypeSelection: '<', // Скрывать ли выбор типа сообщения (нужно скрывать в триггерных цепочках)
      },
      controller: 'CqMessagePartEditorController',
      controllerAs: 'vm',
      template: require('./message-part-editor.html'),
    };
  }
})();

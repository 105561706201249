<ng-container *transloco="let t; read: 'triggerChainAdditionalActionsComponent'">
  <div class="d-flex align-items-center">
    <!-- Обучающие статьи -->
    <a
      *ngIf="isRusCountry"
      class="btn btn-text-primary ml-20"
      target="_blank"
      [href]="t('helpArticlesLink.href')"
      (click)="trackClick()"
    >
      <i class="btn-icon-left cqi-sm cqi-book"></i>
      <span>{{ t('helpArticlesLink.text') }}</span>
    </a>
    <!-- Создать цепочку -->
    <a
      class="btn btn-primary ml-30"
      uiSref="app.content.triggerChains.editor.create"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ t('create') }}</span>
    </a>
  </div>
</ng-container>

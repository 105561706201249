import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ChatBotTemplateModalData } from '@panel/app/partials/modals/chat-bot-template/chat-bot-template.token';
import { ViewStateService } from '@panel/app/partials/template/services/view-state.service';
import { AbstractTemplate } from '@panel/app/shared/types/abstract-template.type';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-chat-bot-template',
  templateUrl: './chat-bot-template.component.html',
  styleUrls: ['./chat-bot-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ViewStateService],
})
export class ChatBotTemplateComponent implements OnInit {
  // TODO Удалить оба инпута когда модалка будет вызываться только из A2+ компонентов
  /**
   * Инстанс AJS модалки. Нужен для закрытия.
   */
  @Input()
  ajsModalInstance!: any;

  /** Параметры, передаваемые в модалку */
  @Input() modalWindowParams!: ChatBotTemplateModalData;

  constructor(
    public readonly viewStateService: ViewStateService,
    // TODO раскоментить когда модалка будет вызваться только из A2+ компонентов
    //  public readonly activeModal: NgbActiveModal,
    //  @Inject(CHAT_BOT_TEMPLATE_MODAL_DATA_TOKEN) //public data: ChatBotTemplateModalData,
    private readonly carrotquestHelper: CarrotquestHelper,
  ) {}

  ngOnInit() {
    // Если есть templateId, то сразу открываем превью
    if (this.modalWindowParams.templateId) {
      const template = this.modalWindowParams.templates.find((temp) => temp.id === this.modalWindowParams.templateId)!;
      this.viewStateService.setState(template);
    }
  }

  /**
   * Обработка клика по кнопке возврата из превью шаблона в общий список
   *
   * @param template Шаблон чат-бота
   */
  onClickBackButton(template: AbstractTemplate): void {
    this.viewStateService.setState(null);
    this.trackClickBack(template);
  }

  /**
   * Обработка клика по карточке превью шаблона
   *
   * @param template Шаблон чат-бота
   */
  onClickTemplate(template: AbstractTemplate): void {
    this.viewStateService.setState(template);

    this.trackClickTemplate(template);
  }

  onClickTemplateZero(): void {
    this.trackClickTemplateZero();

    this.ajsModalInstance.close();
  }

  /**
   * Обработка клика по выбору шаблона в превью шаблона
   *
   * @param template Шаблон чат-бота
   */
  onClickSelectTemplate(template: AbstractTemplate): void {
    this.ajsModalInstance.close(template);
    this.viewStateService.setState(null);
    this.trackSelectTemplate(template);
  }

  /**
   * Трек возврата к списку шаблонов
   */
  trackClickBack(template: AbstractTemplate) {
    this.carrotquestHelper.track('Чат-бот - Шаблоны - возврат в каталог', {
      'название шаблона': template.name,
      'тип бота': this.modalWindowParams.botType,
    });
  }

  /**
   * Трек выбора шаблона
   */
  private trackSelectTemplate(template: AbstractTemplate) {
    this.carrotquestHelper.track('Чат-бот - Шаблоны - выбран шаблон', {
      'название шаблона': template.name,
      'тип бота': this.modalWindowParams.botType,
    });
  }

  /**
   * Трек клика по шаблону
   */
  trackClickTemplate(template: AbstractTemplate) {
    this.carrotquestHelper.track('Чат-бот - Шаблоны - переход на превью', {
      'название шаблона': template.name,
      'тип бота': this.modalWindowParams.botType,
    });
  }

  trackClickTemplateZero(): void {
    const eventName = 'Чат-бот - Шаблоны - выбран шаблон c нуля';

    this.carrotquestHelper.track(eventName);
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input } from '@angular/core';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

@Component({
  selector: 'cq-integration-icon[type]',
  template: '',
  styleUrls: ['./integration-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationIconComponent {
  @Input()
  @HostBinding('class')
  type!: INTEGRATION_TYPES;

  /**
   * Костыль для Dashly, в америке WordPress называется WooCommerce, у него другая иконка
   */
  @HostBinding('class.us')
  isUsCountry: boolean = this.l10nHelper.isUsCountry();

  constructor(@Inject(L10nHelperService) private readonly l10nHelper: L10nHelperService) {}
}

<ng-container *transloco="let t; read: 'planChangeConfirmModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body pb-5 pt-0 px-0">
    <div class="text-secondary mb-20 px-20">
      <span>{{ t('paymentPeriod', { months: paymentRate }) }}</span>
      <span>&bull;</span>
      <span>{{ t('nextPaymentDate', { date: nextPaymentDate }) }}</span>
    </div>
    <table class="table table-sm">
      <tr>
        <td>{{ t('plan', { planName: planIdTranslate(data.planId) }) }}</td>
        <td class="shrink-by-content">
          <s
            *ngIf="discountValue"
            class="text-muted mr-5"
            >{{ data.price.priceWithoutAddonsPerMonth | toFormattedMoney }}</s
          >
          <span>{{
            t('perMonth', { amount: data.price.discountedPriceWithoutAddonsPerMonth | toFormattedMoney })
          }}</span>
        </td>
        <td class="shrink-by-content text-right">
          {{ data.price.totalDiscountedPriceWithoutAddons | toFormattedMoney }}
        </td>
      </tr>
      <tr *ngFor="let addonInfo of data.addons">
        <td>{{ t('module', { moduleName: addOnTranslate(addonInfo.addon) }) }}</td>
        <td class="text-right">
          <span>{{ t('perMonth', { amount: addonInfo.scaledPrice | toFormattedMoney }) }}</span>
        </td>
        <td class="shrink-by-content text-right">
          {{ addonInfo.scaledPrice * paymentRate | toFormattedMoney }}
        </td>
      </tr>
      <tr class="table-secondary">
        <td class="font-weight-bold border-top-0">{{ t('total') }}</td>
        <td class="shrink-by-content text-right border-top-0">
          <s
            *ngIf="discountValue"
            class="text-muted mr-5"
            >{{ data.price.totalPriceWithAddonsPerMonth | toFormattedMoney }}</s
          >
          <span>{{ data.price.totalDiscountedPriceWithAddonsPerMonth | toFormattedMoney }}</span>
          <span>{{ t('perMonth') }}</span>
        </td>
        <td class="font-weight-bold shrink-by-content text-right border-top-0">
          {{ data.price.totalDiscountedPriceWithAddons | toFormattedMoney }}
        </td>
      </tr>
    </table>

    <cq-downgrade-abilities *ngIf="downgradeAbilitiesService.hasDowngradeActivities$ | async"></cq-downgrade-abilities>
  </div>

  <div class="modal-footer">
    <a
      class="mr-20"
      *cqHideInUs
      [class.disabled]="isApiRequestPerformed$ | async"
      href="#"
      (click)="payByOther()"
    >
      {{ t('payByOther') }}
    </a>
    <ng-container *tuiLet="(downgradeAbilitiesService.counterActivities$ | async) ?? [] as counterActivities">
      <button
        class="btn btn-primary"
        [disabled]="isApiRequestPerformed$ | async"
        (click)="openPaymentModal(); markDowngradeAutoMessageOrLeadBot(counterActivities)"
      >
        {{ t('goToPaymentButton') }}
      </button>
    </ng-container>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./track-master-pro.controller');

  angular.module('myApp.trackMaster').component('cqTrackMasterPro', cqTrackMasterPro());

  function cqTrackMasterPro() {
    return {
      bindings: {
        autoEvent: '=', // Автособытие
        properties: '=', // Свойства
      },
      controller: 'CqTrackMasterProController',
      controllerAs: 'vm',
      template: require('./track-master-pro.html'),
    };
  }
})();

/**
 * Контроллер для статистики чат-ботов сообщений
 */
import { PERMISSIONS_FOR_ACTIONS } from '../../../../../app/http/django-user/django-user.constants';
import {
  CHAT_BOT_ACTIONS_TYPES,
  CHAT_BOT_ACTIONS_TYPES_ICONS,
} from '../../../../../app/http/chat-bot/chat-bot.constants';
import { firstValueFrom } from 'rxjs';
import {
  CHAT_BOT_EXPORT_HEADER,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION,
  CHAT_BOT_TYPES_SENDINGS_INTERACTION_ARRAY,
} from '../../../../../app/http/chat-bot-statistics/chat-bot-statistics.constant';

(function () {
  'use strict';

  angular
    .module('myApp.chatBot.leadBot.statistics')
    .controller('CqChatBotStatisticsController', CqChatBotStatisticsController);

  function CqChatBotStatisticsController(
    $scope,
    $state,
    $translate,
    $uibModal,
    moment,
    toastr,
    USER_FILES_URL,
    carrotquestHelper,
    caseStyleHelper,
    chatBotStatisticsModel,
    dateRangePickerHelper,
    djangoUserModel,
    userCardModalService,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.caseStyleHelper = caseStyleHelper;
      vm.conversationUrlCopied = conversationUrlCopied;
      vm.CHAT_BOT_TYPES_SENDINGS_INTERACTION_ARRAY = CHAT_BOT_TYPES_SENDINGS_INTERACTION_ARRAY;
      vm.CHAT_BOT_TYPES_SENDINGS_INTERACTION = CHAT_BOT_TYPES_SENDINGS_INTERACTION;
      vm.CHAT_BOT_ACTIONS_TYPES_ICONS = CHAT_BOT_ACTIONS_TYPES_ICONS;
      vm.CHAT_BOT_ACTIONS_TYPES = CHAT_BOT_ACTIONS_TYPES;
      vm.USER_FILES_URL = USER_FILES_URL;
      vm.djangoUserModel = djangoUserModel;
      vm.exportSendings = exportSendings;
      vm.getConversationUrl = getConversationUrl;
      vm.getSendings = getSendings;
      vm.getLastEmail = getLastContact.bind(vm, '$email');
      vm.getLastPhone = getLastContact.bind(vm, '$phone');
      vm.openSendingModal = openSendingModal;
      vm.tableData = getDataForSendingsTab(); // Данные таблицы статистики
      vm.isRequestPerformed = false; // Выполнение запроса по статистике получателей

      getSendingsStatistics().then(setSendingsWatchers);
    }

    /**
     * Экспорт данных о получателях
     */
    function exportSendings() {
      if (!djangoUserModel.hasAccess(vm.currentApp.id, vm.djangoUser, PERMISSIONS_FOR_ACTIONS.PERMISSIONS_EXPORT)) {
        $uibModal
          .open({
            controller: 'ConfirmModalController',
            controllerAs: 'vm',
            resolve: {
              modalWindowParams: function () {
                return {
                  imgUrl: 'assets/img/default/no-access.png',
                  heading: $translate.instant('chatBots.statistics.sendings.accessDeniedModal.heading'),
                  body: $translate.instant('chatBots.statistics.sendings.accessDeniedModal.body'),
                  confirmButtonText: $translate.instant('general.ok'),
                  cancelButtonClass: 'd-none',
                };
              },
            },
            templateUrl: 'js/shared/modals/confirm/confirm.html',
          })
          .result.then.catch(() => {});
        return;
      }

      trackStartExportSendings();

      const chatBotId = vm.chatBotMessage.body;
      const params = {
        appId: vm.currentApp.id,
        startDate: vm.tableData.range.startDate,
        endDate: vm.tableData.range.endDate,
        typeInteraction: vm.tableData.currentTypeInteraction,
        headers: [
          CHAT_BOT_EXPORT_HEADER.USER_NAME,
          CHAT_BOT_EXPORT_HEADER.USER_LINK,
          CHAT_BOT_EXPORT_HEADER.SENT,
          CHAT_BOT_EXPORT_HEADER.STARTED_CONVERSATION,
          CHAT_BOT_EXPORT_HEADER.IN_PROGRESS,
          CHAT_BOT_EXPORT_HEADER.INTERRUPTED,
          CHAT_BOT_EXPORT_HEADER.LAST_PHONE,
          CHAT_BOT_EXPORT_HEADER.LAST_EMAIL,
          CHAT_BOT_EXPORT_HEADER.FINISHED_BRANCH_NAME,
          CHAT_BOT_EXPORT_HEADER.FINISHED_ACTION_TYPE,
          CHAT_BOT_EXPORT_HEADER.CONVERSATION_LINK,
        ],
      };

      chatBotStatisticsModel.exportSendings(chatBotId, params);
    }

    /**
     * Получение данных для вкладки с получателями
     *
     * @return {Object}
     */
    function getDataForSendingsTab() {
      const currentTypeInteraction = CHAT_BOT_TYPES_SENDINGS_INTERACTION_ARRAY[0];
      return {
        currentStatistics: {}, // Текущая статистика
        currentTypeInteraction, // Текущий тип получателей
        dateRangePickerOptions: getDataPickerOptions(), // Настройки DatePicker
        sendingsPaginator: null, // Id последнего загруженного получателя (для пагинации)
        range: getRange(), // Период статистики
        sendings: [], // Получатели
      };
    }

    /**
     * Получение настроек для DatePicker
     *
     * @return {Object}
     */
    function getDataPickerOptions() {
      const defaultDatePickerOptions = dateRangePickerHelper.getOptions();
      const customDatePickerOptions = {
        dateLimit: {
          days: 90,
        },
        maxDate: moment().endOf('day'),
        minDate:
          // Данные о получателях больше 1.5 лет назад были удалены, поэтому ограничиваем дату
          moment().diff(moment(vm.chatBotMessage.bot.created), 'months') >= 18
            ? moment().subtract(18, 'months').startOf('day')
            : moment(vm.chatBotMessage.bot.created).startOf('day'),
        opens: 'right',
      };

      return angular.extend(defaultDatePickerOptions, customDatePickerOptions);
    }

    /**
     * Получить из finishedMetaData данные для формирования канала
     * @param {FinishedChatBotChannelMetaData} channelFinishedMeta
     * @returns {{}}
     */
    function getChannelData(channelFinishedMeta) {
      return {
        name: channelFinishedMeta.channelName,
        type: channelFinishedMeta.channelType,
      };
    }

    /**
     * Получение получателей по типу действий с чат-ботом
     *
     * @param {Boolean} forceReload - Если true, принудительно очистяться все старые данные
     *
     * @return {Promise}
     */
    function getSendings(forceReload) {
      const chatBotId = vm.chatBotMessage.body;
      let params = {
        appId: vm.currentApp.id,
        startDate: vm.tableData.range.startDate,
        endDate: vm.tableData.range.endDate,
        typeInteraction: vm.tableData.currentTypeInteraction,
      };

      // Если идёт полная перезагрузка таблицы получателей - всё очищаем
      if (forceReload) {
        vm.tableData.sendings = [];
        vm.tableData.sendingsPaginator = null;
      }

      if (vm.tableData.sendingsPaginator) {
        params.paginatorParams = vm.tableData.sendingsPaginator;
      }

      vm.isRequestPerformed = true;

      return firstValueFrom(chatBotStatisticsModel.getSendings(chatBotId, params))
        .then(getSendingsSuccess)
        .finally(getSendingsFinally);

      function getSendingsSuccess(response) {
        parseSendingsForRender(response.sendings);
        vm.tableData.sendings.push.apply(vm.tableData.sendings, response.sendings);
        vm.tableData.sendingsPaginator = response.paginatorParams;
      }

      function getSendingsFinally() {
        vm.isRequestPerformed = false;
      }
    }

    /**
     * Парсинг статистики для корректного отображения во view
     * @param sendings
     */
    function parseSendingsForRender(sendings) {
      sendings.forEach((sending) => {
        if (sending.leftContacts.length > 0) {
          sending.leftContacts = [vm.getLastPhone(sending.leftContacts), vm.getLastEmail(sending.leftContacts)];
        }

        if (sending.finishedMetaData.actionType === CHAT_BOT_ACTIONS_TYPES.CHANNEL && sending.finishedMetaData) {
          sending.finishedMetaData = {
            ...sending.finishedMetaData,
            ...getChannelData(sending.finishedMetaData),
          };
        }
      });
    }

    /**
     * Получение последнего контакта по его типу из списка оставленных контактов
     *
     * @param {'$email' | '$phone'} type
     * @param {ChatBotSendingLeftContact[]} contacts
     *
     * @returns {string}
     */
    function getLastContact(type, contacts) {
      for (let i = contacts.length - 1; i >= 0; i--) {
        if (contacts[i].type === type) {
          return contacts[i].value;
        }
      }
    }

    /**
     * Получение статистики по чат-боту
     *
     * @return {Promise}
     */
    function getSendingsStatistics() {
      const chatBotId = vm.chatBotMessage.body;
      const params = {
        appId: vm.currentApp.id,
        startDate: vm.tableData.range.startDate,
        endDate: vm.tableData.range.endDate,
      };

      return firstValueFrom(chatBotStatisticsModel.getSendingsStatistics(chatBotId, params)).then((response) => {
        vm.tableData.currentStatistics = response;
      });
    }

    /**
     * Получение начала и конца выбранного периода
     *
     * @return {Object}
     */
    function getRange() {
      let startDate, endDate;
      const chatBotCreateDate = moment(vm.chatBotMessage.bot.created).startOf('day');

      // Если чат-бот был создан больше, чем неделю назад - вначале должна отобразится статистика только за неделю,
      // а если меньше, чем неделю назад - статистика должна отразиться с даты создания чат-бота
      if (isChatBotCreatedMoreWeekAgo(chatBotCreateDate)) {
        startDate = moment().subtract(6, 'days').startOf('day');
      } else {
        startDate = chatBotCreateDate;
      }

      endDate = moment().endOf('day');

      return {
        startDate,
        endDate,
      };
    }

    /**
     * Чат-бот создан неделю назад
     */
    function isChatBotCreatedMoreWeekAgo(chatBotCreateDate) {
      const startDayCurrent = moment().startOf('day');

      return startDayCurrent.diff(chatBotCreateDate, 'days') > 6;
    }

    /**
     * Открытие карточки получателя
     *
     * @param {String} sendingId - Id получателя
     */
    function openSendingModal(sendingId) {
      userCardModalService.showModal(vm.billingInfo, vm.currentApp, vm.djangoUser, sendingId);
    }

    /**
     * Получение ссылки на диалог по его ID
     * @param {string} conversationId - ID диалога
     * @returns {string}
     */
    function getConversationUrl(conversationId) {
      return $state.href('app.content.conversations.detail', { conversationId: conversationId }, { absolute: true });
    }

    /**
     * Показывает тост об успешном копировании ссылки
     */
    function conversationUrlCopied() {
      toastr.success($translate.instant('general.linkCopied'));
    }

    /**
     * Установка вотчеров для фильтров получаетелей
     *
     * !!!
     *  Здесь используется имеено $watchCollection, а не $watchGroup
     *  https://github.com/angular/angular.js/issues/8671
     */
    function setSendingsWatchers() {
      // Изменение любого параметра влечёт за собой полную перезагрузку таблицы получателей
      const sendingsWatchCollection = [
        'vm.tableData.range.startDate',
        'vm.tableData.range.endDate',
        'vm.tableData.currentTypeInteraction',
      ];

      $scope.$watchCollection(`[${sendingsWatchCollection.join(',')}]`, (newValues, oldValues) => {
        getSendings(true);

        if (newValues[0] != oldValues[0] || newValues[1] != oldValues[1]) {
          getSendingsStatistics();
        }
      });
    }

    /**
     * Трек клика на "Запустить экспорт"
     */
    function trackStartExportSendings() {
      const params = {
        Название: vm.chatBotMessage.name,
        'Тип бота': 'leadbot',
      };

      carrotquestHelper.track('Чат-боты - запустил экспорт', params);
    }
  }
})();

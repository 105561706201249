import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { MessageEditorCollapseModule } from '@panel/app/partials/message-editor/shared/message-editor-collapse/message-editor-collapse.module';
import { MessageEditorAnotherEventsModule } from '@panel/app/partials/message-editor/trigger/another-events/message-editor-another-events.module';
import { MessageEditorDispatchUrlModule } from '@panel/app/partials/message-editor/trigger/dispatch-url/message-editor-dispatch-url.module';
import { MessageEditorOpenedSdkPageTriggerTypesModule } from '@panel/app/partials/message-editor/trigger/opened-sdk-page-trigger-type/message-editor-opened-sdk-page-trigger-types.module';
import { MessageEditorOpenedWebPageTriggerTypesModule } from '@panel/app/partials/message-editor/trigger/opened-web-page-trigger-type/message-editor-opened-web-page-trigger-types.module';
import { MessageEditorSendingDelayModule } from '@panel/app/partials/message-editor/trigger/sending-delay/message-editor-sending-delay.module';
import { MessageEditorTriggerComponent } from '@panel/app/partials/message-editor/trigger/trigger/message-editor-trigger.component';
import { MessageEditorTriggerSelectModule } from '@panel/app/partials/message-editor/trigger/trigger-select/message-editor-trigger-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [MessageEditorTriggerComponent],
  exports: [MessageEditorTriggerComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    MessageEditorCollapseModule,
    MessageEditorSendingDelayModule,
    MessageEditorDispatchUrlModule,
    MessageEditorTriggerSelectModule,
    MessageEditorOpenedWebPageTriggerTypesModule,
    MessageEditorAnotherEventsModule,
    NgbTooltipModule,
    SharedModule,
    MessageEditorOpenedSdkPageTriggerTypesModule,
  ],
})
export class MessageEditorTriggerModule {}

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { generate } from 'short-uuid';

import { OpenedSdkPageTriggerType } from '@http/message/trigger.types';
import { UtilsModel } from '@http/utils/utils.model';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';
import { TupleUnion } from '@panel/app/shared/types/tuple-union.type';

type Comparison = OpenedSdkPageTriggerType['comparison'];

export type MessageEditorOpenedSdkPageTriggerTypesForm = OpenedSdkPageTriggerType;

@Component({
  selector: 'cq-message-editor-opened-sdk-page-trigger-type',
  templateUrl: './message-editor-opened-sdk-page-trigger-type.component.html',
  styleUrls: ['./message-editor-opened-sdk-page-trigger-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorOpenedSdkPageTriggerTypeComponent extends AbsCVAFormGroupBasedComponent<
  ToFormGroupControls<MessageEditorOpenedSdkPageTriggerTypesForm>
> {
  @Input()
  isOnlyEvent: boolean = false;

  protected readonly URL_MAX_LENGTH = 255;

  protected readonly URL_MIN_LENGTH = 3;

  readonly control = this.fb.group<ToFormGroupControls<MessageEditorOpenedSdkPageTriggerTypesForm>>({
    // этот добавлен в форму, чтоб не заморачиваться его удалением из объекта перед передачей в компонент, можно потом будет подумать над решением
    localId: this.fb.control(generate(), { nonNullable: true }),
    comparison: this.fb.control<Comparison>('eq', { nonNullable: true }),
    sdkPage: this.fb.control<string>('', {
      nonNullable: true,
      validators: [
        Validators.maxLength(this.URL_MAX_LENGTH),
        Validators.minLength(this.URL_MIN_LENGTH),
        Validators.required,
      ],
    }),
  });

  get comparison() {
    return this.control.controls.comparison;
  }

  get sdkPage() {
    return this.control.controls.sdkPage;
  }

  @Output()
  deleteTriggerType: Subject<void> = new Subject();

  protected readonly COMPARISON_OPTIONS: TupleUnion<Comparison> = ['eq', 'neq', 'contains', 'not_contains'];

  constructor(private readonly fb: FormBuilder, private readonly utilsService: UtilsModel) {
    super();
  }
}

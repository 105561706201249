import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { IntegrationService } from '@http/integration/services/integration.service';
import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { AmocrmDataFromModule } from '@panel/app/pages/integrations/content/amocrm/data-from/amocrm-data-from.module';
import { AmocrmDataToContactsModule } from '@panel/app/pages/integrations/content/amocrm/data-to-contacts/amocrm-data-to-contacts.module';
import { AmocrmDataToLeadsModule } from '@panel/app/pages/integrations/content/amocrm/data-to-leads/amocrm-data-to-leads.module';
import { AmocrmOAuthContainerModule } from '@panel/app/pages/integrations/content/amocrm/o-auth-container/amocrm-o-auth-container.module';
import { AmocrmSettingsComponent } from '@panel/app/pages/integrations/content/amocrm/settings/amocrm-settings.component';
import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

@NgModule({
  declarations: [AmocrmSettingsComponent],
  exports: [AmocrmSettingsComponent],
  imports: [
    AmocrmDataFromModule,
    AmocrmDataToContactsModule,
    AmocrmDataToLeadsModule,
    AmocrmOAuthContainerModule,
    CommonModule,
    IntegrationNameControlModule,
    ReactiveFormsModule,
    TranslocoModule,
    UIRouterModule,
  ],
  providers: [CarrotquestHelperProvider, IntegrationFactory, IntegrationService, SystemToastService, ToastService],
})
export class AmocrmSettingsModule {}

import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';

import { Channel } from '@http/channel/channel.model';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

import { ActionValidationExtra, BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

function removedChannel(channels: Channel[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return channels.map((c) => c.id).includes(control.value) ? null : { channelRemoved: { value: control.value } };
  };
}
const getChannelBodyValidators = (channels: Channel[]): ValidatorFn[] => [
  Validators.required,
  removedChannel(channels),
];

export class BotChannelActionForm extends BaseBotActionForm {
  get allTouchedChanges$() {
    return extractTouchedChanges(this.controls.body).pipe(startWith(this.controls.body.touched));
  }

  getCustomControls(action: ChatBotAction, validationExtra: ActionValidationExtra): CustomControlsWithBodyJsonRequired {
    return {
      bodyJson: new GenericFormGroup({}),
      body: new GenericFormControl(action.body || null, getChannelBodyValidators(validationExtra.channels)),
    };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PropertySelectModule } from '@panel/app/partials/filters/components/property-select/property-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { InsertPropsIntoTextModalComponent } from './insert-props-into-text-modal.component';

@NgModule({
  declarations: [InsertPropsIntoTextModalComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, SharedModule, ReactiveFormsModule, PropertySelectModule],
  providers: [NgbActiveModal],
})
export class InsertPropsIntoTextModalModule {}

<ng-container *transloco="let t; read: 'timepickerPageComponent'">
  <div class="margin-between-rows-50">
    <h1>Timepicker</h1>

    <div>
      <h3>Timepicker</h3>
      <div class="card">
        <div class="card-body">
          <cq-timepicker [(ngModel)]="timepicker1"></cq-timepicker>
          <hr />
          <div>Model:</div>
          <div>{{ timepicker1.format('LLLL') }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbDateAdapter, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';

@Component({
  selector: 'cq-datepicker-month-navigation[datepicker][initialDate]',
  templateUrl: './datepicker-month-navigation.component.html',
  styleUrls: ['./datepicker-month-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerMonthNavigationComponent implements OnInit {
  @Input()
  datepicker!: NgbDatepicker;

  @Input()
  readonly initialDate!: moment.Moment;

  constructor(private readonly dateAdapter: NgbDateAdapter<moment.Moment>) {}

  ngOnInit() {
    const navigationDifference = this.initialDate
      .clone()
      .startOf('month')
      .diff(moment().startOf('month'), 'months', false);
    this.navigate(navigationDifference);
  }

  get navigationDate(): string {
    const ngbDate = this.datepicker.state.months[0];
    const momentDate = this.dateAdapter.toModel(ngbDate)!;
    return momentDate.format('MMM YYYY');
  }

  navigate(number: number) {
    const { state, calendar } = this.datepicker;
    this.datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }
}

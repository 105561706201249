(function () {
  'use strict';

  require('./general-privacy-policy-settings.controller');

  angular.module('myApp.privacySettings').component('cqGeneralPrivacyPolicySettings', cqGeneralPrivacyPolicySettings());

  function cqGeneralPrivacyPolicySettings() {
    return {
      bindings: {
        currentApp: '=', // приложение
        settings: '=', // настройки обработки персональных данных
      },
      controller: 'CqGeneralPrivacyPolicySettingsController',
      controllerAs: 'vm',
      template: require('./general-privacy-policy-settings.html'),
    };
  }
})();

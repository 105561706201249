import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { UserAvatarModule } from '@panel/app/partials/user-card/user-avatar/user-avatar.module';
import { SharedModule } from '@panel/app/shared/shared.module';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { ConversationUserListComponent } from './conversation-user-list.component';

@NgModule({
  declarations: [ConversationUserListComponent],
  exports: [ConversationUserListComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    LoaderModule,
    ReactiveFormsModule,
    TranslocoModule,
    UserAvatarModule,
  ],
})
export class ConversationUserListModule {}

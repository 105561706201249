(function () {
  'use strict';

  angular.module('myApp.users').controller('CqUploadFileWithLeadsController', CqUploadFileWithLeadsController);

  function CqUploadFileWithLeadsController(carrotquestHelper) {
    var vm = this;

    /**
     * Валидные mime типы файлов
     *
     * @type {string}
     */
    var VALID_MIME_TYPES = 'text/csv,application/vnd.ms-excel';

    vm.$onInit = init;

    function init() {
      vm.trackClickChooseFile = trackClickChooseFile;
      vm.VALID_MIME_TYPES = VALID_MIME_TYPES;
    }

    /**
     * Получение имени типа импорта для трека событий
     *
     * @param {IMPORT_TYPES} importType - Тип импорта
     */
    function getImportNameForTrack(importType) {
      return importType === vm.importTypes.LEADS ? 'Импорт лидов' : 'Импорт пользователей';
    }

    /**
     * Трек выбора файла
     */
    function trackClickChooseFile() {
      carrotquestHelper.track('Импорт лидов - клик по ссылке - выбор файла ', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }
  }
})();

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TriggerChainTrackService } from '@panel/app/pages/trigger-chains/shared/services/track-service/trigger-chain-track.service';
import {
  TRIGGER_CHAIN_TEMPLATE_MODAL_DATA_TOKEN,
  TriggerChainTemplateModalData,
} from '@panel/app/partials/modals/trigger-chain-template/trigger-chain-template.token';
import { ViewStateService } from '@panel/app/partials/template/services/view-state.service';
import { AbstractTemplate } from '@panel/app/shared/types/abstract-template.type';

@Component({
  selector: 'cq-trigger-chain-template',
  templateUrl: './trigger-chain-template.component.html',
  styleUrls: ['./trigger-chain-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ViewStateService],
})
export class TriggerChainTemplateComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    @Inject(TRIGGER_CHAIN_TEMPLATE_MODAL_DATA_TOKEN)
    public readonly modalParams: TriggerChainTemplateModalData,
    private readonly triggerChainTrackServie: TriggerChainTrackService,
    public readonly viewStateService: ViewStateService,
  ) {}

  ngOnInit() {
    this.viewStateService.setState(null);
  }

  onClickBackButton(template: AbstractTemplate): void {
    this.viewStateService.setState(null);
  }

  onClickSelectTemplate(template: AbstractTemplate): void {
    this.activeModal.close(template);
  }

  onClickTemplate(template: AbstractTemplate): void {
    this.viewStateService.setState(template);
  }

  onClickTemplateZero(): void {
    this.triggerChainTrackServie.trackTemplateZeroSelection();

    this.activeModal.close();
  }
}

import { Injectable } from '@angular/core';

import { EventTypeModel } from '@http/event-type/event-type.model';
import { MESSAGE_TYPES } from '@http/message/message.constants';
import { MessageModel } from '@http/message/message.model';
import {
  TriggerChainExternal,
  TriggerChainListItemExternal,
  TriggerChainStepsListExternal,
} from '@panel/app/http/trigger-chain/external-types';
import {
  TriggerChain,
  TriggerChainListItem,
  TriggerChainMessageStep,
} from '@panel/app/http/trigger-chain/internal-types';
import {
  TriggerChainParseToInternalExtraData,
  TriggerChainStepMapper,
} from '@panel/app/http/trigger-chain/mappers/trigger-chain-step.mapper';
import { CHAIN_STATUS } from '@panel/app/pages/trigger-chains/list/trigger-chain-list.constants';
import { TriggerChainTrackService } from '@panel/app/pages/trigger-chains/shared/services/track-service/trigger-chain-track.service';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';
import { ExternalFiltersAJS } from '@panel/app/services/filter-ajs/filter-ajs.types';

@Injectable({ providedIn: 'root' })
export class TriggerChainMapper {
  constructor(
    private readonly filterAjsModel: FilterAjsModel,
    private readonly triggerChainStepMapper: TriggerChainStepMapper,
    private readonly messageModel: MessageModel,
    private readonly triggerChainTrackService: TriggerChainTrackService,
  ) {
    this.triggerChainTrackService.trackActivateChain();
  }

  parseToInternal(
    triggerChainExternal: TriggerChainExternal,
    extraData: TriggerChainParseToInternalExtraData,
  ): TriggerChain {
    return {
      ...triggerChainExternal,
      steps: triggerChainExternal.steps.map((triggerChainStepExternal) =>
        this.triggerChainStepMapper.parseToInternal(triggerChainStepExternal, extraData),
      ),
    };
  }

  parseToExternal(triggerChain: TriggerChain): TriggerChainExternal {
    return {
      ...triggerChain,
      steps: triggerChain.steps.map((triggerChainStep) =>
        this.triggerChainStepMapper.parseToExternal(triggerChainStep),
      ),
    };
  }

  parseChainListToInternal(
    triggerChainExternal: TriggerChainListItemExternal,
    extraData: TriggerChainParseToInternalExtraData,
  ): TriggerChainListItem {
    const { properties, userTags } = extraData;
    const { filters, triggerTypes } = triggerChainExternal.parameters;

    return {
      ...triggerChainExternal,
      sendingConditions: {
        ...triggerChainExternal.parameters,
        eventTypes: triggerChainExternal.parameters.eventTypes.map((eventType) =>
          EventTypeModel.findEventTypeById(eventType, properties.eventTypes),
        ),
        filters: this.filterAjsModel.linkWithPropsAndTags(filters, properties, userTags),
        triggerTypes: this.messageModel.parseTriggerTypeToInternalFormat(triggerTypes),
      },
    };
  }

  parseChainListToExternal(triggerChain: TriggerChainListItem): TriggerChainListItemExternal {
    const { filters, triggerTypes } = triggerChain.sendingConditions;

    return {
      ...triggerChain,
      parameters: {
        ...triggerChain.sendingConditions,
        eventTypes: triggerChain.sendingConditions.eventTypes.map((eventType) => eventType.id),
        filters: this.filterAjsModel.parseToServerFormat(filters, false) as ExternalFiltersAJS,
        triggerTypes: this.messageModel.parseTriggerTypesToServeFormat(triggerTypes),
      },
    };
  }

  parseChainStatusToExternal(status: CHAIN_STATUS): boolean | null {
    switch (status) {
      case CHAIN_STATUS.ACTIVE:
        return true;
      case CHAIN_STATUS.STOPPED:
        return false;
      case CHAIN_STATUS.ALL:
        return null;
    }
  }

  parseChainStatusToInternal(status: boolean | null): CHAIN_STATUS {
    switch (status) {
      case true:
        return CHAIN_STATUS.ACTIVE;
      case false:
        return CHAIN_STATUS.STOPPED;
      case null:
        return CHAIN_STATUS.ALL;
    }
  }

  parseChainStepListToInternal(stepsList: TriggerChainStepsListExternal): TriggerChainMessageStep[] {
    return stepsList.map((step) => this.messageModel.parseMessage(step, step.messageType as MESSAGE_TYPES));
  }
}

<ng-container *transloco="let t; read: 'editEventTypeComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('title') }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <form
      (ngSubmit)="updateEventType()"
      novalidate
    >
      <div class="form-group row">
        <label class="col-md-2">{{ t('editEventTypeForm.id') }}</label>
        <div class="col-md-10">
          <span>{{ data.eventType.id }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-2">{{ t('editEventTypeForm.name') }}</label>
        <div class="col-md-10">
          <div class="text-truncate">{{ data.eventType.prettyName }}</div>
          <div
            *ngIf="data.eventType.name.startsWith('$')"
            class="muted"
          >
            {{ data.eventType.name }}
          </div>
        </div>
      </div>
      <div
        class="form-group row no-margin-bottom"
        [cqShowError]="scoreFormControl"
      >
        <label class="col-md-2 col-form-label">{{ t('editEventTypeForm.scoreInput.label') }}</label>
        <div class="col-md-10">
          <input
            class="form-control"
            required
            cqNumber
            [formControl]="scoreFormControl"
          />
          <cq-validation-messages [control]="scoreFormControl">
            <cq-validation-message errorName="required">{{
              t('editEventTypeForm.scoreInput.errors.required')
            }}</cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>
      <input
        class="hidden"
        id="editEventTypeFormSubmit"
        type="submit"
      />
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <label
      class="btn btn-primary"
      for="editEventTypeFormSubmit"
      >{{ 'general.save' | transloco }}</label
    >
  </div>
</ng-container>

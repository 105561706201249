<ng-container *transloco="let t">
  <h3>{{ t('emailNotificationUserProps.heading') }}</h3>
  <div class="margin-bottom-20">{{ t('emailNotificationUserProps.description') }}</div>
  <ng-select
    [clearable]="false"
    [formControl]="control"
    [hideSelected]="true"
    [items]="filteredAndOrderedUserProperties"
    [multiple]="true"
    [trackByFn]="userPropsTrackFn"
    bindLabel="prettyName"
    bindValue="name"
    groupBy="group"
    placeholder="{{ t('emailNotificationUserProps.userPropsSelect.placeholder') }}"
  >
    <ng-template
      let-clear="clear"
      let-property="item"
      ng-label-tmp
    >
      <button
        class="btn btn-sm btn-outline-primary d-flex align-items-center overflow-hidden"
        [attr.disabled]="isPropertyNotRemovable(property) ? true : null"
        type="button"
      >
        <span class="text-truncate">{{ property.prettyName }}</span>
        <span
          class="d-flex"
          (click)="clear(property)"
          *ngIf="!isPropertyNotRemovable(property)"
        >
          <i class="cqi-xs cqi-times margin-left-5"></i>
        </span>
      </button>
    </ng-template>
  </ng-select>
</ng-container>

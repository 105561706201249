(function () {
  'use strict';

  angular.module('myApp.maintenance').component('cqMaintenance', cqMaintenance());

  function cqMaintenance() {
    return {
      template: require('./maintenance.html'),
    };
  }
})();

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { App } from '@http/app/app.model';
import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import { EventType, Properties } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';
import { TriggerChain } from '@http/trigger-chain/internal-types';
import { UserSegment, UserTag } from '@http/user/types/user.type';

@Injectable()
export class TriggerChainEditorStore {
  readonly autoEvents$: BehaviorSubject<AutoEvent[]> = new BehaviorSubject<AutoEvent[]>([]);
  readonly eventTypes$: BehaviorSubject<EventType[]> = new BehaviorSubject<EventType[]>([]); // Типы событий
  readonly properties$: BehaviorSubject<Properties> = new BehaviorSubject<Properties>({
    userProps: [],
    eventTypeProps: [],
    eventTypes: [],
  }); // Свойства пользователя
  readonly currentApp$: BehaviorSubject<App | null> = new BehaviorSubject<App | null>(null);
  readonly segments$: BehaviorSubject<UserSegment[]> = new BehaviorSubject<UserSegment[]>([]);
  readonly tags$: BehaviorSubject<UserTag[]> = new BehaviorSubject<UserTag[]>([]);
  readonly triggerChain$: BehaviorSubject<TriggerChain | null> = new BehaviorSubject<TriggerChain | null>(null);
  readonly telegramIntegrations$: BehaviorSubject<TelegramIntegrationExternal[]> = new BehaviorSubject<
    TelegramIntegrationExternal[]
  >([]);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgStringPipesModule } from 'ngx-pipes';

import { StatusComponent } from './status.component';

@NgModule({
  declarations: [StatusComponent],
  exports: [StatusComponent],
  imports: [CommonModule, TranslocoModule, NgStringPipesModule],
})
export class StatusModule {}

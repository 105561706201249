import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Addon } from '@http/addon/addon';
import { ADDON_STATUS } from '@http/addon/addon.constants';
import { AddonAPIRequestParams, AddonConstruct, AddonDto } from '@http/addon/addon.types';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Http-сервис для работы с аддонами */
@Injectable({ providedIn: 'root' })
export class AddonHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly instanceFactory: InstanceFactory,
  ) {}

  /**
   * Подключение
   *
   * @param appId - ID приложения
   * @param addon - Аддон
   */
  connect(appId: string, addon: Addon): Observable<Addon> {
    const params: AddonAPIRequestParams = {
      addons: [addon.id],
      ignoreErrors: true,
    };

    return this.http.post<AddonDto>(`/apps/${appId}/billing/addons`, params).pipe(
      map((response) => {
        let addonConstruct: AddonConstruct = {
          ...response,
          status: ADDON_STATUS.CONNECTED,
        };

        return this.instanceFactory.getInstanceAddon(addonConstruct);
      }),
    );
  }

  /**
   * Подключение аддона через чат
   *
   * @param message - Сообщение
   */
  connectViaChat(message: string): void {
    this.carrotquestHelper.sendChatMessage(message);
  }

  /**
   * Отключение
   *
   * @param appId - ID приложения
   * @param addon - Аддон
   */
  disconnect(appId: string, addon: Addon): Observable<Addon> {
    const params: AddonAPIRequestParams = {
      addons: [addon.id],
      ignoreErrors: true,
    };

    return this.http.delete<AddonDto>(`/apps/${appId}/billing/addons`, { body: { params } }).pipe(
      map((response) => {
        let addonConstruct: AddonConstruct = {
          ...response,
          status: ADDON_STATUS.DISCONNECTED,
        };

        return this.instanceFactory.getInstanceAddon(addonConstruct);
      }),
    );
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { takeUntil } from 'rxjs/operators';

import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { TeamMemberModel } from '@http/team-member/team-member.model';
import { DestroyService } from '@panel/app/services';
import { EqualValidator } from '@panel/app/shared/validators/equal/equal-validator';

@Component({
  selector: 'cq-assign-new-super-admin-modal',
  templateUrl: './assign-new-super-admin-modal.component.html',
  styleUrls: ['./assign-new-super-admin-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AssignNewSuperAdminModalComponent implements OnInit {
  @Input() modalWindowParams!: any;

  @ViewChild(NgSelectComponent, { static: false })
  ngSelectComponent?: NgSelectComponent;

  newSuperAdminForm = this.fb.group({
    newSuperAdmin: this.fb.control(null, [Validators.required]),
    confirmation: this.fb.control(null, [Validators.required]),
  });

  availableTeamMembers: any = [];

  constructor(
    protected readonly ngbActive: NgbActiveModal,
    protected readonly teamMemberModel: TeamMemberModel,
    protected readonly fb: FormBuilder,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.availableTeamMembers = this.modalWindowParams.teamMembers.filter(
      (teamMember: any) => teamMember.id !== this.modalWindowParams.teamMember.id,
    );

    this.newSuperAdminForm.controls.newSuperAdmin.setValue(this.availableTeamMembers[0].id);
    this.newSuperAdminForm.controls.confirmation.setValidators(
      EqualValidator.equal(this.modalWindowParams.currentAppName),
    );
  }

  /**
   * Назначение нового суперадмина
   */
  setSuperAdmin(): void {
    this.teamMemberModel
      .setPermissions(
        this.modalWindowParams.appId,
        this.newSuperAdminForm.controls.newSuperAdmin.getRawValue()!,
        TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN,
        this.modalWindowParams.currentApp,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.ngbActive.close();
      });
  }
}

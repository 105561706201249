import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotModel } from '@http/chat-bot/chat-bot.model';
import { ApiChatBotBranchResponse } from '@http/chat-bot/types/branch-external.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { BOT_CONNECTIONS_COLLECTION, BRANCH_VIEW_MAP, BranchViewMap } from '@panel/app/pages/chat-bot/content/tokens';
import { BaseActionABS } from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { InterruptBadge, StartBadge } from '@panel/app/pages/chat-bot/content/views/badges';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { Connection, ConnectionSource, ConnectionTarget } from '@panel/app/pages/chat-bot/content/views/connection';

@Injectable()
export class BotScenariosHelper {
  private interruptScenarioBlocks: string[] = []; // ID блоков из сценария прерывания
  private defaultScenarioBlocks: string[] = []; // ID блоков из дефолтного сценария

  private interruptScenarioUpdatedSubj = new Subject<void>();
  interruptScenarioUpdated$ = this.interruptScenarioUpdatedSubj.asObservable();

  constructor(
    @Inject(BRANCH_VIEW_MAP)
    private readonly branchesMap: BranchViewMap,
    @Inject(BOT_CONNECTIONS_COLLECTION)
    private readonly connections: Connection[],
  ) {}

  private addChainToScenario(firstBlock: Branch, scenarioType: 'default' | 'interrupt'): void {
    const addBlockToScenario = (block: Branch): boolean => {
      const scenarioBlocks = scenarioType === 'default' ? this.defaultScenarioBlocks : this.interruptScenarioBlocks;

      // Если блок уже содержится в цепочке, то все его дочерние блоки тоже
      if (scenarioBlocks.includes(block.linkId)) {
        return false;
      }
      if (scenarioType === 'default') {
        block.isDefaultScenario = true;
      } else {
        block.isInterruptScenario = true;
      }
      scenarioBlocks.push(block.linkId);
      return true;
    };
    BotScenariosHelper.runFnOnBlocksChain(firstBlock, addBlockToScenario, this.branchesMap);
  }

  addChainToInterruptScenario(chainBlock: Branch): void {
    this.addChainToScenario(chainBlock, 'interrupt');
    this.interruptScenarioUpdatedSubj.next();
  }

  addChainToDefaultScenario(chainBlock: Branch): void {
    this.addChainToScenario(chainBlock, 'default');
  }

  addTargetToScenarioOfSource(source: ConnectionSource, target: ConnectionTarget) {
    if (!(target instanceof Branch)) {
      throw new Error('Target must be a block');
    }

    if (source instanceof StartBadge || (source instanceof BaseActionABS && source.currentBranch.isDefaultScenario)) {
      return this.addChainToDefaultScenario(target);
    }

    if (
      source instanceof InterruptBadge ||
      (source instanceof BaseActionABS && source.currentBranch.isInterruptScenario)
    ) {
      return this.addChainToInterruptScenario(target);
    }
  }

  blockItselfIsPartOfInterruptScenario(linkId: string) {
    return this.interruptScenarioBlocks.includes(linkId);
  }

  blockItselfIsPartOfDefaultScenario(linkId: string) {
    return this.defaultScenarioBlocks.includes(linkId);
  }

  blockIsPartOfDefaultScenario(firstBlock: Branch) {
    let isPartOfDefaultScenario = false;

    const isPartOfDefaultScenarioChecker = (block: Branch) => {
      if (this.defaultScenarioBlocks.includes(block.linkId)) {
        isPartOfDefaultScenario = true;
        return false;
      }
      return true;
    };

    BotScenariosHelper.runFnOnBlocksChain(firstBlock, isPartOfDefaultScenarioChecker, this.branchesMap);

    return isPartOfDefaultScenario;
  }

  blockIsPartOfInterruptScenario(firstBlock: Branch) {
    let isPartOfInterruptScenario = false;

    const isPartOfInterruptScenarioChecker = (block: Branch) => {
      if (this.interruptScenarioBlocks.includes(block.linkId)) {
        isPartOfInterruptScenario = true;
        return false;
      }
      return true;
    };

    BotScenariosHelper.runFnOnBlocksChain(firstBlock, isPartOfInterruptScenarioChecker, this.branchesMap);

    return isPartOfInterruptScenario;
  }

  getNumberOfParentsFromInterruptScenario(block: Branch): number {
    const parentsOfBlock = this.connections.filter((conn) => conn.target?.uid === block.uid);

    return parentsOfBlock.filter((conn) => {
      if (conn.inProcessOfRedrawing) {
        return false;
      }

      const source = conn.source;
      if (source instanceof InterruptBadge) {
        return true;
      }
      if (source instanceof BaseActionABS) {
        return source.currentBranch.isInterruptScenario;
      }
      return false;
    }).length;
  }

  /**
   * Получение LinkID блоков из сценария
   *
   * @param blocks - Список блоков
   * @param firstBLockId - ID первого блока из сценария
   * @private
   */
  static getScenarioBlocksByLinkId<T extends CHAT_BOT_TYPE>(
    blocks: ChatBotBranch[],
    firstBLockId: string | null,
  ): string[] {
    const scenarioBlocks: string[] = [];

    const firstBlock = blocks.find((block) => block.linkId === firstBLockId);

    if (!firstBlock) {
      return [];
    }

    findBlocksInScenario(firstBlock);

    function findBlocksInScenario(scenariosFirstBlock: ChatBotBranch) {
      if (scenarioBlocks.includes(scenariosFirstBlock.linkId)) {
        return;
      }
      scenarioBlocks.push(scenariosFirstBlock.linkId);

      scenariosFirstBlock.actions.forEach((action) => {
        const nextBlock = blocks.find((block) => block.linkId === action.nextBranchLinkId);
        if (nextBlock) {
          findBlocksInScenario(nextBlock);
        }
      });
    }

    return scenarioBlocks;
  }

  /**
   * Нужен для использования при парсинге ботов
   * Получение LinkID блоков из сценария
   *
   * @param blocks - Список блоков
   * @param firstBLockId - ID первого блока из сценария
   * @private
   */
  static getScenarioBlocksById<T extends CHAT_BOT_TYPE>(
    blocks: ApiChatBotBranchResponse[],
    firstBLockId: string,
  ): string[] {
    const scenarioBlocks: string[] = [];

    const firstBlock = blocks.find((block) => block.id === firstBLockId);

    if (!firstBlock) {
      throw new Error(`Could not find scenario's first block`);
    }

    findBlocksInScenario(firstBlock);

    function findBlocksInScenario(scenariosFirstBlock: ApiChatBotBranchResponse) {
      if (scenarioBlocks.includes(scenariosFirstBlock.id)) {
        return;
      }
      scenarioBlocks.push(scenariosFirstBlock.id);

      scenariosFirstBlock.actions.forEach((action) => {
        const nextBlock = blocks.find((block) => block.id === action.nextBranchId);
        if (nextBlock) {
          findBlocksInScenario(nextBlock);
        }
      });
    }

    return scenarioBlocks;
  }

  updateScenarios<T extends CHAT_BOT_TYPE>(chatBot: ChatBot<T>) {
    this.interruptScenarioBlocks = BotScenariosHelper.getScenarioBlocksByLinkId(
      chatBot.branches,
      chatBot.interruptBranchLinkId,
    );
    this.defaultScenarioBlocks = BotScenariosHelper.getScenarioBlocksByLinkId(
      chatBot.branches,
      chatBot.startBranchLinkId,
    );
  }

  /**
   * Проверяем, что блок валидный для сценария прерывания
   */
  blockItselfIsValidForInterruptScenario(block: Branch): boolean {
    return (
      block.blockType !== 'branch' ||
      block.actions.every(({ type }) => ChatBotModel.isActionAllowedForInterruptScenario(type))
    );
  }

  /**
   * Проверяем, что блок и его "дети" валидны для сценария прерывания
   */
  deepValidateBlockForInterruptScenario(block: Branch): string | null {
    let errorName: string | null = null;

    const checkChildConnectivity = (item: Branch): boolean => {
      if (errorName !== null) {
        return false;
      }

      if (!this.blockItselfIsValidForInterruptScenario(item)) {
        const blockContainsCloseAction = item.actions.some(({ type }) => type === CHAT_BOT_ACTIONS_TYPES.CLOSE);
        if (blockContainsCloseAction) {
          errorName = 'closingConversationIsNotAllowed';
        } else {
          errorName = 'continuingConversationIsNotAllowed';
        }

        return false;
      }
      return true;
    };

    BotScenariosHelper.runFnOnBlocksChain(block, checkChildConnectivity, this.branchesMap);

    return errorName;
  }

  /**
   * Рекурсивно погружается "вглубь" цепочки блоков, вызывая метод до тех пор,
   * пока не будет пройдена вся цепочка, или fn не вернет false
   *
   * @param firstBlock - блок, с которого начинать "погружение"
   * @param fn - функция, которую вызывать для каждого последующего блока.
   *             Возвращает boolean, котороый говорит о том, нужно ли "погружаться" дальше
   * @param branchesMap мапа всех блоков
   */
  static runFnOnBlocksChain(firstBlock: Branch, fn: (block: Branch) => boolean, branchesMap: BranchViewMap) {
    const viewedBlocksIds: string[] = [];

    const recursiveFn = (block: Branch) => {
      const keepDeepDive = fn(block);

      if (!keepDeepDive) {
        return;
      }

      viewedBlocksIds.push(block.linkId);
      block.actions.forEach((action) => {
        if (!action.nextBranchLinkId.value) {
          return;
        }
        if (viewedBlocksIds.includes(action.nextBranchLinkId.value)) {
          return;
        }
        const nextBranch = branchesMap.get(action.nextBranchLinkId.value);
        if (!nextBranch) {
          return;
        }
        recursiveFn(nextBranch);
      });
    };

    recursiveFn(firstBlock);
  }

  private removeChainFromScenario(chainFirstBlock: Branch, scenarioType: 'default' | 'interrupt') {
    const removeBlockFromScenario = (block: Branch): boolean => {
      const numberOfInterruptScenarioParents = this.getNumberOfParentsFromInterruptScenario(block);
      if (numberOfInterruptScenarioParents > 0) {
        return false;
      }

      const scenarioBlocks = scenarioType === 'default' ? this.defaultScenarioBlocks : this.interruptScenarioBlocks;

      if (scenarioType === 'default') {
        block.isDefaultScenario = false;
      } else {
        block.isInterruptScenario = false;
      }

      const indexInScenarioBLocks = scenarioBlocks.findIndex((item) => item === block.linkId);
      scenarioBlocks.splice(indexInScenarioBLocks, 1);

      return true;
    };

    BotScenariosHelper.runFnOnBlocksChain(chainFirstBlock, removeBlockFromScenario, this.branchesMap);
  }

  removeChainFromDefaultScenario(chainFirstBlock: Branch) {
    return this.removeChainFromScenario(chainFirstBlock, 'default');
  }

  removeChainFromInterruptScenario(chainFirstBlock: Branch) {
    this.removeChainFromScenario(chainFirstBlock, 'interrupt');
    this.interruptScenarioUpdatedSubj.next();
  }

  removeTargetFromScenarioOfSource(source: ConnectionSource, target: ConnectionTarget) {
    if (!(target instanceof Branch)) {
      throw new Error('Target must be a block');
    }

    if (source instanceof StartBadge || (source instanceof BaseActionABS && source.currentBranch.isDefaultScenario)) {
      return this.removeChainFromDefaultScenario(target);
    }

    if (
      source instanceof InterruptBadge ||
      (source instanceof BaseActionABS && source.currentBranch.isInterruptScenario)
    ) {
      return this.removeChainFromInterruptScenario(target);
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { TriggerChainAdditionalActionsComponent } from './trigger-chain-additional-actions.component';

@NgModule({
  declarations: [TriggerChainAdditionalActionsComponent],
  exports: [TriggerChainAdditionalActionsComponent],
  imports: [CommonModule, TranslocoModule, UIRouterModule],
})
export class TriggerChainAdditionalActionsModule {}

import { BehaviorSubject } from 'rxjs';

import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';
import { TelegramBot } from '@http/chat-bot/types/chat-bot-internal.types';

/** Вспомогательный store для Телеграм-ботов */
export class TelegramBotStoreService {
  /** Редактируемый Телеграм-бот */
  editableTelegramBot$: BehaviorSubject<TelegramBot | null> = new BehaviorSubject<TelegramBot | null>(null);

  /** ID интеграции в редактируемом боте */
  editableTelegramBotIntegrationId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  /** Список всех Телеграм-ботов */
  telegramBotAllList$: BehaviorSubject<TelegramBot[]> = new BehaviorSubject<TelegramBot[]>([]);

  /** Список всех интеграций с Телеграм */
  telegramIntegrationList$: BehaviorSubject<TelegramIntegration[]> = new BehaviorSubject<TelegramIntegration[]>([]);

  //@ngInject
  constructor() {}
}

import { Validators } from '@angular/forms';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

interface IComponentsPreviewForm {
  file: File[];
}

/** Класс для работы с формой для демонстрации компонентов */
export class ComponentsPreviewForm extends GenericFormGroup<IComponentsPreviewForm> {
  constructor(file: File[] | null) {
    const controls: Controls<IComponentsPreviewForm> = {
      file: new GenericFormControl(file ?? [], [Validators.required]),
    };

    super(controls, null, null);
  }
}

<ng-container *transloco="let t; read: 'starterGuideCreatingLeadbotComponent'">
  <ngb-alert
    *ngIf="isStepMade"
    [dismissible]="false"
    class="mb-30"
    type="success"
  >
    <i class="cqi-sm cqi-check-circle mr-10"></i>
    {{ t('alert') }}
  </ngb-alert>

  <div class="d-flex justify-content-between">
    <div class="margin-between-rows-20">
      <div
        class="font-weight-bold"
        [innerHTML]="t('heading')"
      ></div>
      <ul class="steps-list">
        <li>
          <span [innerHTML]="t('list.item1.content')"></span>
          <button
            class="btn btn-text-primary active"
            (click)="onClickCreatingLeadbotFromTemplateButton()"
          >
            <span>{{ t('list.item1.button.text') }}</span>
            <i class="cqi-sm cqi-external-link"></i>
          </button>
        </li>
        <li [innerHTML]="t('list.item2.content')"></li>
        <li [innerHTML]="t('list.item3.content')"></li>
      </ul>
      <button
        class="btn btn-primary"
        type="button"
        (click)="onClickCreatingLeadbotButton()"
      >
        <i class="btn-icon-left cqi-sm cqi-external-link"></i>
        <span>{{ t('button.text') }}</span>
      </button>
    </div>

    <div>
      <img
        [ngSrc]="getSrc()"
        width="360"
        height="235"
      />
    </div>
  </div>
</ng-container>

/**
 * Компонент для превью блока 'Поле с кнопкой'
 */
(function () {
  'use strict';

  require('./popup-input-with-button-block-preview.controller');
  require('./input-with-button.html');
  // HACK: костыль, это должно делать не так. CSS в этом случае никак не обрабатывается angular-cli при билде/серве, потому что стоит ! перед ng-cache-loader
  require('!ng-cache-loader?prefix=panel/src:js/**&url=false!./input-with-button.scss');

  angular
    .module('myApp.popupEditor')
    .component('cqPopupInputWithButtonBlockPreview', cqPopupInputWithButtonBlockPreview());

  function cqPopupInputWithButtonBlockPreview() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        params: '=', // параметры блока
      },
      controller: 'CqPopupInputWithButtonBlockPreviewController',
      controllerAs: 'vm',
      template: require('./popup-input-with-button-block-preview.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { VkFormsIntegrationQuestionExternal } from '@http/integration/integrations/vk-forms/interfaces/vk-forms-integration.interfaces';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-vk-forms-question-name[control]',
  templateUrl: './vk-forms-question-name.component.html',
  styleUrls: ['./vk-forms-question-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsQuestionNameComponent {
  /** Control для настройки названия вопроса */
  @Input() control!: GenericFormControl<VkFormsIntegrationQuestionExternal['k']>;
}

(function () {
  'use strict';

  require('./auto-event-modal.controller');

  angular.module('myApp.trackMaster').component('cqTrackMasterModal', cqTrackMasterModal());

  function cqTrackMasterModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // autoEvent - Автособытие
        // currentApp - текущее приложение
        // properties - Свойства
        // onCopyScript - callback копирования скрипта
      },
      controller: 'cqTrackMasterModalController',
      controllerAs: 'vm',
      template: require('./auto-event-modal.html'),
    };
  }
})();

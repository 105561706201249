<ng-container *transloco="let t">
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="integration.form"
  >
    <cq-amocrm-o-auth-container
      [integrationId]="integration.id"
      [isAuthorized]="isAuthorized"
      [accountDomain]="accountDomain"
      (authorizeError)="onAuthorizeError()"
      (authorizeSuccess)="onAuthorizeSuccess()"
    ></cq-amocrm-o-auth-container>

    <ng-container *ngIf="isAuthorized">
      <hr />
      <cq-amocrm-data-to-leads
        class="mt-40"
        [currentApp]="currentApp"
        [chatBots]="chatBots"
        [controlLeadDefaultPipeline]="controlLeadDefaultPipeline"
        [controlLeadDefaultStatus]="controlLeadDefaultStatus"
        [controlLeadEventTypes]="controlLeadEventTypes"
        [controlLeadProps]="controlLeadPropsMapping"
        [controlLeadsEventTypeIds]="controlLeadsEventTypeIds"
        [integrationId]="integration.id!"
        [isShowedFromModal]="isShowedFromModal"
        [triggerMessages]="triggerMessages"
      ></cq-amocrm-data-to-leads>

      <hr />
      <cq-amocrm-data-to-contacts
        class="mt-40"
        [currentApp]="currentApp"
        [eventTypeIdsControl]="controlEventTypeIds"
        [integrationId]="integration.id!"
        [userEventsTypesGroup]="controlUserEventsTypesMapping"
        [userPropsGroup]="controlUserPropsMapping"
      ></cq-amocrm-data-to-contacts>

      <hr />
      <cq-amocrm-data-from
        class="mt-40"
        [controlAmocrmLeadsPropsMapping]="controlAmocrmLeadsPropsMapping"
        [controlAmocrmPropsMappingInternal]="controlAmocrmPropsMappingInternal"
        [currentApp]="currentApp"
        [leadsEventsMappingControl]="controlLeadsEventsMapping"
        [integrationId]="integration.id!"
      >
      </cq-amocrm-data-from>

      <hr />
      <cq-integration-name-control
        class="mt-40"
        [control]="controlName"
        [isBoldHeading]="true"
      ></cq-integration-name-control>
    </ng-container>
  </form>
</ng-container>

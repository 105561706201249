import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { MessageNameEditorModule } from '@panel/app/partials/message-editor/message-name-editor/message-name-editor.module';

import { TriggerChainBreadcrumbsComponent } from './trigger-chain-breadcrumbs.component';

@NgModule({
  declarations: [TriggerChainBreadcrumbsComponent],
  exports: [TriggerChainBreadcrumbsComponent],
  imports: [CommonModule, TranslocoModule, MessageNameEditorModule, NgbTooltipModule, UIRouterModule],
})
export class TriggerChainBreadcrumbsModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { MomentModule } from 'ngx-moment';

import { SubscriptionStatusComponent } from './subscription-status.component';

@NgModule({
  declarations: [SubscriptionStatusComponent],
  exports: [SubscriptionStatusComponent],
  imports: [CommonModule, TranslocoModule, MomentModule],
  providers: [],
})
export class SubscriptionStatusModule {}

import { Injectable, Injector } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { Addon } from '@http/addon/addon';
import { AddonConstruct } from '@http/addon/addon.types';
import { AddonHttpService } from '@http/addon/http/addon-http.service';
import { Billing } from '@http/billing/billing';
import { BillingDto } from '@http/billing/billing.types';
import { BillingHttpService } from '@http/billing/http/billing-http.service';
import { Coupon } from '@http/coupon/coupon';
import { CouponDto } from '@http/coupon/coupon.types';
import { Customer } from '@http/customer/customer';
import { CustomerDto } from '@http/customer/customer.types';
import { InvoiceHttpService } from '@http/invoice/http/invoice-http.service';
import { Invoice } from '@http/invoice/invoice';
import { InvoiceDto } from '@http/invoice/invoice.types';
import { PlanHelpService } from '@http/plan/help/plan-help.service';
import { Plan } from '@http/plan/plan';
import { PlanDto } from '@http/plan/plan.types';
import { Quotas } from '@http/quotas/quotas';
import { QuotasDto } from '@http/quotas/quotas.types';
import { RequisitesHttpService } from '@http/requisites/http/requisites-http.service';
import { Requisites } from '@http/requisites/requisites';
import { RequisitesDto } from '@http/requisites/requisites.types';
import { SubscriptionHelpService } from '@http/subscription/help/subscription-help.service';
import { Subscription } from '@http/subscription/subscription';
import { SubscriptionDto } from '@http/subscription/subscription.types';

@Injectable({ providedIn: 'root' })
export class InstanceFactory {
  constructor(private readonly transloco: TranslocoService, private readonly injector: Injector) {}

  /**
   * Получение инстанса аддона
   */
  getInstanceAddon(dto: AddonConstruct): Addon {
    const httpService: AddonHttpService = this.injector.get(AddonHttpService);

    return new Addon(httpService, this.transloco, dto);
  }

  /**
   * Получение инстансов аддонов
   */
  getInstanceAddons(dtos: AddonConstruct[]): Addon[] {
    return dtos.map((dto) => this.getInstanceAddon(dto));
  }

  /**
   * Получение инстанса биллинга
   */
  getInstanceBilling(dto: BillingDto): Billing {
    const httpService: BillingHttpService = this.injector.get(BillingHttpService);

    return new Billing(httpService, this, dto);
  }

  /**
   * Получение инстанса купона
   */
  getInstanceCoupon(dto: CouponDto): Coupon {
    return new Coupon(dto);
  }

  /**
   * Получение инстансов купонов
   */
  getInstanceCoupons(dtos: CouponDto[]): Coupon[] {
    return dtos.map((dto) => this.getInstanceCoupon(dto));
  }

  /**
   * Получение инстанса плательщика
   */
  getInstanceCustomer(dto: CustomerDto): Customer {
    return new Customer(dto);
  }

  /**
   * Получение инстанса счёта
   */
  getInstanceInvoice(dto: InvoiceDto): Invoice {
    const httpService: InvoiceHttpService = this.injector.get(InvoiceHttpService);

    return new Invoice(httpService, this.transloco, dto);
  }

  /**
   * Получение инстансов счётов
   */
  getInstanceInvoices(dtos: InvoiceDto[]): Invoice[] {
    return dtos.map((dto) => this.getInstanceInvoice(dto));
  }

  /**
   * Получение инстанса тарифного плана
   */
  getInstancePlan(dto: PlanDto): Plan {
    const helpService: PlanHelpService = this.injector.get(PlanHelpService);

    return new Plan(helpService, this, dto);
  }

  /**
   * Получение инстанса квот
   */
  getInstanceQuotas(dto: QuotasDto): Quotas {
    return new Quotas(dto);
  }

  /**
   * Получение инстанса реквизитов компании
   */
  getInstanceRequisites(dto: RequisitesDto): Requisites {
    const httpService: RequisitesHttpService = this.injector.get(RequisitesHttpService);

    return new Requisites(httpService, dto);
  }

  /**
   * Получение инстанса подписки
   */
  getInstanceSubscription(dto: SubscriptionDto): Subscription {
    const helpService: SubscriptionHelpService = this.injector.get(SubscriptionHelpService);

    return new Subscription(this, helpService, dto);
  }
}

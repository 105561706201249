// FIXME избавиться от этого фильтра и использовать аналогичный из angular-filter
/**
 * Фильтр, превращающий число в проценты
 */
(function () {
  'use strict';

  angular.module('myApp.filters.percent').filter('cqPercent', cqPercent);

  function cqPercent($filter, accounting) {
    return percentFilter;

    /**
     * Функция, превращающая любое значение в проценты.
     * Если значение не удалось распарсить как число - вернётся 0,00%
     *
     * @param {Number|String} value Значение, которое нужно превратить в проценты
     * @param {*} defaultValue Значение по умолчанию
     * @returns {String} Число от 0 до 100 с двумя незначащими нулями и знаком процента на конце
     */
    function percentFilter(value, defaultValue) {
      // после выполнения этого выражения parsedValue будет либо null (если value было кривое), либо корректным числом
      var parsedValue = $filter('handleIncorrectValue')(parseFloat(value));
      // после выполнения этого выражения defaultValue будет либо 0 (если оно не передавалось), либо null (если оно было кривое), либо корректным числом
      defaultValue = angular.isDefined(defaultValue) ? $filter('handleIncorrectValue')(parseFloat(defaultValue)) : 0;

      if (parsedValue === null) {
        if (defaultValue === null) {
          return defaultValue;
        } else {
          parsedValue = defaultValue;
        }
      }

      return accounting.formatMoney(parsedValue * 100, '%', 2, undefined, undefined, '%v%s');
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Компонент для работы с заголовком шага в стартергайде */
@Component({
  selector: 'cq-step-title[title]',
  templateUrl: './step-title.component.html',
  styleUrls: ['./step-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepTitleComponent {
  /** Заголовок */
  @Input()
  title!: string;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { DirectoryEditorModalComponent } from './directory-editor-modal.component';

@NgModule({
  declarations: [DirectoryEditorModalComponent],
  exports: [DirectoryEditorModalComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, SharedModule],
})
export class DirectoryEditorModalModule {}

<ng-container *transloco="let t; read: 'moveStartGuideModalComponent'">
  <div class="modal-body pt-30 text-center m-auto">
    <img
      class="mb-20"
      src="assets/img/default/chat-bots/modals/napping-chatbot.svg"
    />
    <h4 class="text-center">{{ t('title') }}</h4>
    <div class="text-center">{{ t('text') }}</div>
  </div>
  <div class="modal-footer flex justify-content-center py-20">
    <a
      uiSref="app.content.dashboard"
      [uiParams]="{ starterGuideStep: STARTER_GUIDE_STEPS.CHAT_SETTINGS }"
      (click)="closeModal()"
      class="btn btn-primary"
    >
      {{ t('setupCode') }}
    </a>
  </div>
</ng-container>

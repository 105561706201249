(function () {
  'use strict';

  require('./chat-operators-evaluation.controller');

  angular.module('myApp.chatSettings').component('cqChatOperatorsEvaluation', cqChatOperatorsEvaluation());

  function cqChatOperatorsEvaluation() {
    return {
      bindings: {
        billingInfo: '=', // Информация по биллингу
        color: '=', // Цвет чата
        theme: '=', // Тема чата
        currentApp: '=', // Текущее приложение
        supportVote: '=', // Включение/выключение оценки работы поддержки
      },
      controller: 'CqChatOperatorsEvaluationController',
      controllerAs: 'vm',
      template: require('./chat-operators-evaluation.html'),
    };
  }
})();

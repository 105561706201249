(function () {
  'use strict';

  require('./system-log-message.controller');

  angular.module('myApp.systemLogMessage').component('cqSystemLogMessage', cqSystemLogMessage());

  function cqSystemLogMessage() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        message: '=', // сообщение системного лога
      },
      controller: 'CqSystemLogMessageController',
      controllerAs: 'vm',
      template: require('./system-log-message.html'),
    };
  }
})();

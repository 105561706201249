import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import { values } from 'lodash-es';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { AppModel } from '@http/app/app.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { AddAppModalComponent } from '@panel/app/pages/account/shared/add-app-modal/add-app-modal.component';
import { GetDemoModalComponent } from '@panel/app/pages/account/shared/get-demo-modal/get-demo-modal.component';
import { NotificationSettingsMapper } from '@panel/app/pages/account/shared/notification-settings/mappers';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { UtilsService } from '@panel/app-old/shared/services/utils/utils.service';

import { ACCOUNT_TABS } from './account.constants';

@Component({
  selector: 'cq-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AccountComponent implements OnInit {
  /** список приложений текущего пользователя */
  @Input() appList!: any;
  /** текущий пользователь */
  @Input() djangoUser!: any;

  protected ACCOUNT_TABS = ACCOUNT_TABS;

  protected availableLanguages: any = environment.panelLanguages;
  /** текущая выбранная вкладка */
  protected currentTab: any = ACCOUNT_TABS.APPS;
  /** Является ли пользователь админом */
  protected isWebApp: any = this.utilsService.webApp;
  /** оповещения */
  protected notifications: any;
  protected notificationSettingsMapper: any = NotificationSettingsMapper;
  /** выбранный язык панели администратора */
  protected selectedLanguage: any = environment.language;
  /** NOTE: указан $state.params вместо $stateParams т.к. после инжекта $stateParams не меняет своего значения */
  protected stateParams: any = this.uiRouter.stateService.params;
  protected isAdmin: any;
  protected isSuperAdmin: any;

  constructor(
    protected readonly appModel: AppModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly djangoUserModel: DjangoUserModel,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    protected readonly uiRouter: UIRouter,
    protected readonly utilsService: UtilsService,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.carrotquestHelper.track('Зашел на страницу аккаунт');

    this.availableLanguages = environment.panelLanguages;
    this.isAdmin = (appId: any) => this.djangoUserModel.isAdmin(appId, this.djangoUser); // Является ли пользователь админом
    this.isSuperAdmin = (appId: any) => this.djangoUserModel.isSuperAdmin(appId, this.djangoUser); // Является ли пользователь супер админом
    this.notifications = this.djangoUser.prefs; // оповещения
    this.notificationSettingsMapper = NotificationSettingsMapper;
    this.selectedLanguage = environment.language; // выбранный язык панели администратора

    // если у пользователя нет ни одного сайта - надо открыть модалку создания сайта без возможности закрыть её
    if (this.isWebApp && this.appList && this.appList.length == 0) {
      this.openCreateAppModal(false);
    }

    // склейка пользователей
    if (this.stateParams.django_user_id && this.stateParams.hash) {
      this.carrotquestHelper.auth(this.stateParams.django_user_id, this.stateParams.hash);
      // Параметры, которые нужно удалить, устанавливаем в null
      const params = {
        django_user_id: null,
        hash: null,
      };

      // Обновляем текущее состояние, удаляя указанные параметры запроса
      this.uiRouter.stateService.go('.', params, { inherit: true, reload: false });
    }

    if (this.stateParams.tab) {
      var tabsArray = values(ACCOUNT_TABS); // Преобразовываем объект ACCOUNT_TABS в массив
      var filteredTab = tabsArray.find((i) => i === this.stateParams.tab);
      this.currentTab = filteredTab ? filteredTab : this.currentTab;
    }
  }

  /**
   * Функция получения описания вкладки
   * ВНИМАНИЕ! Это описание не является заменой ACCOUNT_TABS.TAB.DESCRIPTION, а всего лишь используется в событиях, т.к. события не будут подвержаны переводу, а константы - будут!
   *
   * @param tab Вкладка
   */
  getTabDescription(tab: any): string {
    switch (tab.VALUE) {
      case ACCOUNT_TABS.APPS: {
        return 'Мои сайты';
      }
      case ACCOUNT_TABS.NOTIFICATIONS: {
        return 'Оповещения';
      }
      case ACCOUNT_TABS.PASSWORD_CHANGE: {
        return 'Пароль';
      }
      case ACCOUNT_TABS.PROFILE: {
        return 'Личные данные';
      }
      default:
        throw new Error('There is not handled case');
    }
  }

  /**
   * Настройки сохраняются внтури компонента, но на всякий случай передаю и сюда, чтоб убедиться, что тут ничего не сломается
   * @param settings
   */
  onNotificationSettingsChange(settings: any): void {
    const externalSettings = NotificationSettingsMapper.toExternalResponseFormat(settings);
    this.notifications = {
      ...this.notifications,
      ...externalSettings,
    };
  }

  /**
   * Создание нового приложения
   *
   * @param closable Можно ли закрыть модалку для создания приложения
   */
  openCreateAppModal(closable: any): void {
    let backdrop: any = true;

    if (closable !== undefined && !closable) {
      backdrop = 'static';
    }

    let modal = this.modalHelperService.open(AddAppModalComponent, {
      backdrop: backdrop,
      size: 'lg',
    });

    modal.result
      .then((appId: any) => firstValueFrom(this.appModel.get(appId)))
      .then((app: any) => {
        this.openApp(app.id);
      })
      .catch(() => {});
  }

  /**
   * Открытие модалки с предложением записи на демо
   */
  openGetDemoModal(): void {
    let modal = this.modalHelperService.open(GetDemoModalComponent, {
      backdrop: 'static',
    });

    modal.componentInstance.modalWindowParams = {
      appList: this.appList,
      djangoUser: this.djangoUser,
      isAudit: false,
    };

    modal.result.then(() => {
      this.toastr.success(this.translocoService.translate('account.toasts.requestWasSent'));
    });
  }

  /**
   * Открытие приложения по его ID
   *
   * @param appId ID приложения
   */
  openApp(appId: any): void {
    this.uiRouter.stateService.go('app', { appId: appId });
  }

  /**
   * Клик на кнопку 'Запросить демо'
   */
  trackClickGetDemo(): void {
    this.carrotquestHelper.track('Аккаунт - клик на "Запросить демо"');
  }

  /**
   * Трек изменения вкладки
   *
   * @param tab Вкладка
   */
  trackTabChange(tab: any): void {
    this.carrotquestHelper.track('Аккаунт - зашёл на вкладку "' + this.getTabDescription(tab) + '"');
  }
}

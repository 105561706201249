import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';

import { IntegrationFormGroup } from '@http/integration/integration.types';
import { WHATS_APP_EDNA_PHONE_REGEXP } from '@http/integration/integrations/whats-app-edna/constants/whats-app-edna-integration.constants';

import { INTEGRATION_TYPES } from '../../constants/integration.constants';
import { SettingsIntegration } from '../../integration';
import {
  WhatsAppEdnaIntegrationExternal,
  WhatsAppEdnaIntegrationSettingsExternal,
} from './interfaces/whats-app-edna-integration.interfaces';

export class WhatsAppEdnaIntegration extends SettingsIntegration<WhatsAppEdnaIntegrationSettingsExternal> {
  /** Форма настройки интеграции */
  form: IntegrationFormGroup<WhatsAppEdnaIntegrationSettingsExternal>;
  /** Тип интеграции */
  type = INTEGRATION_TYPES.WHATS_APP_EDNA;
  /** Настройки интеграции */
  settings: WhatsAppEdnaIntegrationSettingsExternal = {
    accessToken: null,
    accountPhone: null,
    cascadeId: null,
    hash: null,
  };

  constructor(translocoService: TranslocoService, integrationExternal?: WhatsAppEdnaIntegrationExternal) {
    super(integrationExternal);

    // Если интеграция создается из внешнего формата, обновим settings, иначе задаем дефолтные значения
    if (integrationExternal) {
      this.updateSettings(integrationExternal.settings);
    } else {
      this.active = false;

      const integrationTypeName = translocoService.translate(`models.integration.types.${this.type}.name`);
      this.name = translocoService.translate('models.integration.defaultValues.name', { integrationTypeName });
    }

    // Данные о настроенной интеграции собираются с формы и отправляются на backend
    this.form = new FormGroup({
      name: new FormControl(this.name, { validators: [Validators.required], nonNullable: true }),
      settings: new FormGroup({
        accessToken: new FormControl(this.settings.accessToken, {
          validators: [Validators.required],
          nonNullable: false,
        }),
        accountPhone: new FormControl(this.settings.accountPhone, {
          validators: [Validators.required, Validators.pattern(WHATS_APP_EDNA_PHONE_REGEXP)],
          nonNullable: false,
        }),
        hash: new FormControl(this.settings.hash, { nonNullable: false }),
        cascadeId: new FormControl(this.settings.cascadeId, { nonNullable: false }),
      }),
    });
  }

  get externalFormat(): WhatsAppEdnaIntegrationExternal {
    return {
      ...this.baseExternalFormat,
      name: this.form.controls.name.value,
      settings: {
        accessToken: this.form.controls.settings.controls.accessToken.value,
        accountPhone: this.form.controls.settings.controls.accountPhone.value,
        hash: this.form.controls.settings.controls.hash.value,
        cascadeId: this.form.controls.settings.controls.cascadeId.value
          ? +this.form.controls.settings.controls.cascadeId.value
          : null,
      },
    };
  }
}

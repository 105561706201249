import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgSelectComponent } from '@ng-select/ng-select';

import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { ModalHelperService } from '@panel/app/services';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';

//TODO ANGULAR_TS надо перенести в conversation.model.js
export type ConversationTag = {
  tag: string;
};

/**
 * Компонент для работы с тегами диалога
 */

@Component({
  selector: 'cq-conversation-tags',
  templateUrl: './conversation-tags.component.html',
  styleUrls: ['./conversation-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationTagsComponent implements OnChanges {
  /** Выключены ли теги */
  @Input()
  disabled: boolean = false;

  /** Список выбранных тегов */
  @Input()
  selectedTags: ConversationTag[] = [];

  /** Список выбранных тегов, отображаемых в представлении */
  @Input()
  selectedViewTags: ConversationTag[] = [];

  /** Список тегов */
  @Input()
  tags: ConversationTag[] = [];

  /** Callback, который срабатывает при добавлении тега */
  @Output()
  tagAdded: EventEmitter<ConversationTag> = new EventEmitter<ConversationTag>();

  /** Callback, который срабатывает при удалении тега */
  @Output()
  tagRemoved: EventEmitter<ConversationTag> = new EventEmitter<ConversationTag>();

  /** Инстанс ng-select */
  @ViewChild('tagSelect')
  select!: NgSelectComponent;

  /** Количество скрытых тегов */
  tagsLeftCount: number = 0;

  protected readonly FEATURES = FEATURES;

  constructor(
    protected readonly featureModel: FeatureModel,
    private readonly modalHelperService: ModalHelperService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {
    this.tagAdded.subscribe(() => {
      /*
        Необходимо затирать поле ввода, т.к. после добавления тега в AngularJS компонент переписывает
        activeViewsTag из-за чего ng-select заново пересчитывает все теги, игнорирую searchTerm
       */
      this.select.searchTerm = '';
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTags) {
      if (this.select?.focused || this.select?.isOpen) {
        this.showMore();
      } else {
        this.showLess();
      }
    }
  }

  /**
   * Является ли тег последним в представлении
   *
   * @param tag - Тег, которые необходимо проверить
   */
  isLastViewTag(tag: ConversationTag): boolean {
    return this.selectedViewTags.slice(-1)[0]?.tag === tag.tag;
  }

  /** Обработчик focus ng-select'а */
  selectFocused(): void {
    // Если тегов совсем нет, то dropdown открываться не будет, открываем его принудительно
    this.select.isOpen = true;
    this.tagsLeftCount && this.showMore();
  }

  /** Обработчик blur ng-select'а */
  selectBlurred(): void {
    if (!this.select.isOpen) {
      this.showLess();
    }
  }

  /** Отображение всех тегов */
  showMore(): void {
    this.selectedViewTags = [...this.selectedTags];
    this.tagsLeftCount = 0;
  }

  /** Скрытие лишних тегов */
  showLess(): void {
    this.selectedViewTags = this.selectedTags.slice(0, 5);
    this.tagsLeftCount = this.selectedTags.length - this.selectedViewTags.length;
  }

  /** Открытие модального окна для создания тега */
  openAddTagModal(): void {
    const addNewTagModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        confirmButtonText: this.translocoService.translate('cqTags.addNewTagModal.confirmButtonText'),
        heading: this.translocoService.translate('cqTags.addNewTagModal.heading'),
        inputErrorText: this.translocoService.translate('cqTags.addNewTagModal.inputErrorText'),
        inputLabel: this.translocoService.translate('cqTags.addNewTagModal.inputLabel'),
        inputPlaceholder: this.translocoService.translate('cqTags.addNewTagModal.inputPlaceholder'),
        inputMaxLength: 255,
        text: this.select.searchTerm,
      })
      .open(PromptModalComponent);

    this.select.searchTerm = '';

    addNewTagModal.result
      .then((tag) => {
        const newTag: ConversationTag = { tag: tag };
        this.tagAdded.emit(newTag);
        this.selectedViewTags = [...this.selectedViewTags, newTag];
      })
      .catch(() => {});
  }

  /**
   * Обработчик встроенных в ng-select keyDown событий
   *
   * @param e - событие keyDown
   */
  keyDown(e: KeyboardEvent): boolean {
    if (
      (e.code === 'Enter' && e.ctrlKey) ||
      (e.code === 'Enter' && e.metaKey) // Обработка cmd+Enter
    ) {
      this.openAddTagModal();
      return false;
    } else if (e.code === 'Backspace' && !this.select.searchTerm && this.select.hasValue) {
      this.tagRemoved.emit(this.selectedViewTags[this.selectedViewTags.length - 1]);
      this.select.unselect(this.select.itemsList.lastSelectedItem);
      return false;
    }

    return true;
  }
}

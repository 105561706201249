import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { Message } from '@http/message/message.types';

/**
 * Компонент для триггерных сообщений, при ответе на которые создаётся сделка в AmoCRM
 */

@Component({
  selector: 'cq-amocrm-trigger-messages-for-lead[isShowedFromModal][triggerMessages]',
  templateUrl: './amocrm-trigger-messages-for-lead.component.html',
  styleUrls: ['./amocrm-trigger-messages-for-lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmTriggerMessagesForLeadComponent {
  /** Показываются ли компоненты из модального окна создания интеграции */
  @Input()
  isShowedFromModal!: boolean;

  /** Список триггерных сообщений */
  @Input()
  triggerMessages: readonly Partial<Message>[] = [];

  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;

  /** Свёрнут ли блок с триггерными сообщениями */
  isCollapsed: boolean = true;

  /** Получение списка активных триггерных сообщений */
  get activeTriggerMessages(): Partial<Message>[] {
    return this.triggerMessages.filter((message) => message.active);
  }

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  /** Является ли список активных триггерных сообщений пустым */
  get isEmptyActiveTriggerMessages(): boolean {
    return this.activeTriggerMessages.length === 0;
  }

  constructor(@Inject(IntegrationService) private readonly integrationService: IntegrationService) {}
}

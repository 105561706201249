import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';
import { takeUntil } from 'rxjs/operators';

import { KnowledgeBaseModel } from '@http/knowledge-base/knowledge-base.model';
import { DestroyService } from '@panel/app/services';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-knowledge-base-activate',
  templateUrl: './knowledge-base-activate.component.html',
  styleUrls: ['./knowledge-base-activate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class KnowledgeBaseActivateComponent implements OnInit {
  @Input() modalWindowParams: any;
  @Input() currentApp!: any;
  /** Настройки БЗ */
  @Input() settings!: any;

  constructor(
    protected readonly activeModal: NgbActiveModal,
    protected readonly destroy$: DestroyService,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly knowledgeBaseModel: KnowledgeBaseModel,
    protected readonly uiRouter: UIRouter,
  ) {}

  ngOnInit(): void {
    this.trackOnEnterCreateKnowledgeBasePage();
  }

  /**
   *  Создание базы знаний
   */
  createKnowledgeBase(): any {
    const curretAppId: any = this.modalWindowParams ? this.modalWindowParams.currentApp.id : this.currentApp.id;
    return this.knowledgeBaseModel
      .create(curretAppId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings: any) => {
        // Если компонент вызывается в модальном окне - закрываем его
        if (this.activeModal) {
          this.activeModal.dismiss();
        }

        // Если компонент вызывается внутри раздела передаем возвращаемые настройки
        if (!this.activeModal) {
          Object.assign(this.settings, settings);
        }

        this.trackOnCreateKnowledgeBase();

        this.uiRouter.stateService.go('app.content.knowledgeBase.categories');
      });
  }

  /**
   * Трек на создание Базы знаний
   */
  trackOnCreateKnowledgeBase(): any {
    this.carrotquestHelper.track('База знаний - Активировал БЗ');
  }

  /**
   * Трек входа на страницу создания Базы знаний
   */
  trackOnEnterCreateKnowledgeBasePage(): any {
    this.carrotquestHelper.track('База знаний - Зашел на страницу активации');
  }

  range(start: number, end: number): number[] {
    return Array.from({ length: end - start + 1 }, (_, index) => index + start);
  }

  trackBy(item: any): any {
    return item; // или что-то, что уникально идентифицирует элемент, например index
  }
}

(function () {
  'use strict';

  angular.module('myApp.directives.switch').controller('CqSwitchController', CqSwitchController);

  function CqSwitchController($attrs, $scope, $translate) {
    var vm = this;

    /**
     * Цели свитчей
     * Необходимы для приминения стилей и изменения внешнего вида свитчей
     * 'check' - используется как вкл/выкл. Свитч серый и с крестиком при false, зелёный и с галочкой при true
     * 'select' - используется для выбора из двух параметров. Свитч всегда синий, меняется только цвет текста в зависимости от значения свитча
     * 'enable' - используется как да/нет. Свитч красный при false и зелёный при true
     * 'theme' - используется для смены темы диалогов
     *
     * @type {Array.<String>}
     */
    var goals = ['check', 'select', 'enable', 'theme'];

    /**
     * Массив со стандартными текстами свитча
     * switchTexts[0] - текст, который выводится если свитч в состоянии true
     * switchTexts[1] - текст, который выводится если свитч в состоянии false
     * Вместо этих стандартных текстов можно использовать свои (см. атрибут withText и функцию setText)
     *
     * @type {Array.<String>}
     */
    var switchTexts = ['directives.switch.texts.enabled', 'directives.switch.texts.disabled'];

    /**
     * Позиции текста относительно свитча. Не актуально для цели свитча 'select' (для него тексты всегда выводятся с обеих сторон)
     *
     * @type {Array.<String>}
     */
    var textPositions = ['right', 'left'];

    vm.getText = getText;

    vm.$onInit = init;

    function init() {
      setGoal();
      setSize();
      setText();
      setTextColor();
      setTextPosition();
      setTextWeight();
      setValues();
    }

    /**
     * Функция, возвращающая определённый текст в зависимости от состояния свитча checkboxState (true/false)
     *
     * @param checkboxState
     * @returns {String}
     */
    function getText(checkboxState) {
      if (angular.isUndefined(checkboxState)) {
        return vm.falseText;
      } else if (checkboxState == vm.evaluatedNgTrueValue) {
        return vm.trueText;
      } else if (checkboxState == vm.evaluatedNgFalseValue) {
        return vm.falseText;
      }
    }

    /**
     * Определение назначения свитча
     * Если параметр goal содержит значение из массива goals - будет выбрано соответствующее состояние свитча
     * Если параметр отсутствует - будет выбрано первое назначение из массива
     * Если параметр задан неверно - будет брошено исключение
     */
    function setGoal() {
      vm.customModel = vm.ngModel;

      if (angular.isDefined(vm.goal)) {
        if (goals.indexOf(vm.goal) == -1) {
          throw Error('goal does not exist: ', vm.goal);
        }
      } else {
        vm.goal = goals[0];
      }
    }

    /**
     * Определение размера свитча (маленький/большой)
     */
    function setSize() {
      vm.isSmall = $attrs.hasOwnProperty('small');
    }

    /**
     * Определение текста для состояний свитча (true/false)
     * Если параметр withText Boolean и true - будут взяты тексты по умолчанию
     * Если параметр Boolean и false - свитч будет без текстов
     * Если параметр array и состоит из двух элементов - первый элемент будет текстом для состояния true, второй для false
     * Если параметр не указан - будут взяты тексты по умолчанию
     * Если параметр указан неверно - будет брошено исключение
     */
    function setText() {
      if (angular.isDefined(vm.withText)) {
        vm.withText = $scope.$eval(vm.withText);
        if (typeof vm.withText == 'boolean') {
          if (vm.withText) {
            vm.trueText = $translate.instant(switchTexts[0]);
            vm.falseText = $translate.instant(switchTexts[0]);
          } else {
            vm.trueText = '';
            vm.falseText = '';
          }
        } else if (vm.withText.constructor == Array && vm.withText.length == 2) {
          vm.trueText = vm.withText[0];
          vm.falseText = vm.withText[1];
        } else {
          throw Error('withText must be Array of 2 elements or Boolean, ' + vm.withText.constructor.name + ' given');
        }
      } else {
        vm.trueText = $translate.instant(switchTexts[0]);
        vm.falseText = $translate.instant(switchTexts[1]);
      }
    }

    /**
     * Определение цвета надписи свитча (светлая/тёмная)
     */
    function setTextColor() {
      vm.isLight = $attrs.hasOwnProperty('light');
    }

    /**
     * Определение позиции текста относительно свитча
     * Если параметр textPosition содержит значние из массива textPositions - будет выбрана соответствующая позиция текста
     * Если позиция не указана - берётся первая позиция из массива
     * Если позиция уканаза неверно (не содержится в массиве textPositions) - будет брошено исключение
     */
    function setTextPosition() {
      if (angular.isDefined(vm.textPosition)) {
        if (textPositions.indexOf(vm.textPosition) == -1) {
          throw new Error('Text position does not exist: ', vm.textPosition);
        }
      } else {
        vm.textPosition = textPositions[0];
      }
    }

    /**
     * Определение цвета надписи свитча (светлая/тёмная)
     */
    function setTextWeight() {
      vm.isBold = $attrs.hasOwnProperty('bold');
    }

    /**
     * Установка значений ngTrueValue и ngFalseValue для инпута
     */
    function setValues() {
      if (angular.isUndefined(vm.ngTrueValue)) {
        vm.ngTrueValue = true;
        vm.evaluatedNgTrueValue = true;
      } else {
        vm.evaluatedNgTrueValue = $scope.$eval(vm.ngTrueValue);
      }

      if (angular.isUndefined(vm.ngFalseValue)) {
        vm.ngFalseValue = false;
        vm.evaluatedNgFalseValue = false;
      } else {
        vm.evaluatedNgFalseValue = $scope.$eval(vm.ngFalseValue);
      }
    }
  }
})();

<!-- TODO: все условия нужно вынести в typescript, и сделать вывод сообщений аналогично выводу статусов в MessageStatusComponent -->
<ng-container *transloco="let t; read: 'messageComponent'">
  <!-- Автоответ -->
  <ng-container
    *ngIf="!correctPart.removed && correctPart.type === 'auto_reply'"
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{
      icon: 'auto-reply',
      name: t('autoReply'),
      message: correctPart.body | removeHtmlTags
    }"
  >
  </ng-container>

  <!--Статья из базы знаний-->
  <!--NOTE так как не реализован поиск - не производится проверка по vm.conversation.isSearching-->
  <ng-container
    *ngIf="!correctPart.removed && correctPart.type === 'article'"
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{ message: correctPart.body_json.name }"
  >
  </ng-container>

  <!-- Сообщение от бота -->
  <ng-container
    *ngIf="
      !correctPart.removed &&
      !correctPart.attachments &&
      correctPart.type !== 'article' &&
      correctPart.type !== 'note' &&
      correctPart.type !== 'auto_reply' &&
      correctPart.type === 'chat_bot_admin'
    "
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{ name: t('bot'), message: correctPart.body | replaceDivEmoji | removeHtmlTags }"
  >
  </ng-container>

  <!-- Сообщение от пользователя -->
  <ng-container
    *ngIf="
      !correctPart.removed &&
      !correctPart.attachments &&
      correctPart.type !== 'article' &&
      correctPart.type !== 'note' &&
      correctPart.type !== 'auto_reply' &&
      correctPart.from.type !== 'admin' &&
      correctPart.type !== 'chat_bot_admin'
    "
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{ message: correctPart.body | replaceDivEmoji | removeHtmlTags }"
  >
  </ng-container>

  <!-- Сообщение от админа -->
  <ng-container
    *ngIf="
      !correctPart.removed &&
      !correctPart.attachments &&
      correctPart.type !== 'article' &&
      correctPart.type !== 'note' &&
      correctPart.type !== 'auto_reply' &&
      correctPart.from.type === 'admin'
    "
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{
      name: correctPart.from.name,
      message: correctPart.body | replaceDivEmoji | removeHtmlTags
    }"
  >
  </ng-container>

  <!-- Заметка -->
  <ng-container
    *ngIf="!correctPart.removed && !correctPart.attachments && correctPart.type === 'note'"
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{
      icon: 'note',
      name: correctPart.from.name,
      message: correctPart.body | removeHtmlTags
    }"
  >
  </ng-container>

  <!-- Сообщение с прикреплением от пользователя -->
  <ng-container
    *ngIf="correctPart.attachments && correctPart.from.type !== 'admin'"
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{
      icon: attachmentType,
      message: correctPart.body ? (correctPart.body | removeHtmlTags) : correctPart.attachments['0'].filename
    }"
  >
  </ng-container>

  <!-- Сообщение с прикреплением от админа -->
  <ng-container
    *ngIf="correctPart.attachments && correctPart.from.type === 'admin' && correctPart.type !== 'note'"
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{
      icon: attachmentType,
      name: correctPart.from.name,
      message: correctPart.body ? (correctPart.body | removeHtmlTags) : correctPart.attachments['0'].filename
    }"
  >
  </ng-container>

  <!-- Заметка с прикреплением от админа -->
  <ng-container
    *ngIf="correctPart.attachments && correctPart.from.type === 'admin' && correctPart.type === 'note'"
    [ngTemplateOutlet]="messageTemplate"
    [ngTemplateOutletContext]="{
      icon: 'note',
      name: correctPart.from.name,
      message: correctPart.body ? (correctPart.body | removeHtmlTags) : correctPart.attachments['0'].filename
    }"
  >
  </ng-container>

  <!-- Удалённое сообщение от пользователя-->
  <div class="text-muted">
    <ng-container
      *ngIf="correctPart.removed && correctPart.type !== 'note' && correctPart.from.type !== 'admin'"
      [ngTemplateOutlet]="messageTemplate"
      [ngTemplateOutletContext]="{ message: t('removedMessage') }"
    >
    </ng-container>
  </div>

  <!-- Удалённое сообщение от админа-->
  <div class="text-muted">
    <ng-container
      *ngIf="correctPart.removed && correctPart.type !== 'note' && correctPart.from.type === 'admin'"
      [ngTemplateOutlet]="messageTemplate"
      [ngTemplateOutletContext]="{
        name: correctPart.from.name,
        message: t('removedMessage')
      }"
    >
    </ng-container>
  </div>

  <!-- Удалённая заметка -->
  <div class="text-muted">
    <ng-container
      *ngIf="correctPart.removed && correctPart.type === 'note'"
      [ngTemplateOutlet]="messageTemplate"
      [ngTemplateOutletContext]="{
        icon: 'note',
        name: correctPart.from.name,
        message: t('removedNote')
      }"
    >
    </ng-container>
  </div>

  <ng-template
    #messageTemplate
    let-icon="icon"
    let-name="name"
    let-message="message"
  >
    <i
      *ngIf="icon"
      class="icon"
      [class]="icon + '-icon'"
    ></i>
    <span
      *ngIf="name"
      class="text-muted"
      >{{ name + ':' }}</span
    >
    <span>{{ message }}</span>
  </ng-template>
</ng-container>

export type GrowingPackerBlock = {
  w: number;
  h: number;
  place: Node | null;
};

type Node =
  | {
      x: number;
      y: number;
      w: number;
      h: number;
    } & (
      | {
          used?: false;
          right?: Node;
          down?: Node;
        }
      | {
          right: Node;
          down: Node;
          used: true;
        }
    );

/**
 * https://github.com/jakesgordon/bin-packing/blob/master/js/packer.growing.js
 */
export class GrowingPacker {
  private static root: Node = { x: 0, y: 0, w: 0, h: 0, used: false };

  static fit(blocks: GrowingPackerBlock[], spaceBetweenBlocks = 0) {
    let node: Node | null;
    let block: GrowingPackerBlock;
    const w = blocks.length > 0 ? blocks[0].w + spaceBetweenBlocks : 0;
    const h = blocks.length > 0 ? blocks[0].h + spaceBetweenBlocks : 0;
    this.root = { x: 0, y: 0, w: w, h: h, used: false };
    for (let n = 0; n < blocks.length; n++) {
      block = blocks[n];
      block.w += spaceBetweenBlocks;
      block.h += spaceBetweenBlocks;
      if ((node = this.findNode(this.root, block.w, block.h))) {
        const resultNode = this.splitNode(node, block.w, block.h);
        block.place = {
          x: resultNode.x,
          y: resultNode.y,
          w: resultNode.w - spaceBetweenBlocks,
          h: resultNode.h - spaceBetweenBlocks,
        };
      } else {
        const resultNode = this.growNode(block.w, block.h);
        if (resultNode) {
          block.place = {
            x: resultNode.x,
            y: resultNode.y,
            w: resultNode.w - spaceBetweenBlocks,
            h: resultNode.h - spaceBetweenBlocks,
          };
        }
      }
    }
  }

  private static findNode(node: Node, w: number, h: number): Node | null {
    if (node.used) {
      return this.findNode(node.right, w, h) || this.findNode(node.down, w, h);
    } else if (w <= node.w && h <= node.h) {
      return node;
    } else {
      return null;
    }
  }

  private static splitNode(node: Node, w: number, h: number): Node {
    node.used = true;
    node.down = { x: node.x, y: node.y + h, w: node.w, h: node.h - h };
    node.right = { x: node.x + w, y: node.y, w: node.w - w, h: h };
    return node;
  }

  private static growNode(w: number, h: number): Node | null {
    const canGrowDown = w <= this.root.w;
    const canGrowRight = h <= this.root.h;

    const shouldGrowRight = canGrowRight && this.root.h >= this.root.w + w; // attempt to keep square-ish by growing right when height is much greater than width
    const shouldGrowDown = canGrowDown && this.root.w >= this.root.h + h; // attempt to keep square-ish by growing down  when width  is much greater than height

    if (shouldGrowRight) return this.growRight(w, h);
    else if (shouldGrowDown) return this.growDown(w, h);
    else if (canGrowRight) return this.growRight(w, h);
    else if (canGrowDown) return this.growDown(w, h);
    else return null; // need to ensure sensible root starting size to avoid this happening
  }

  private static growRight(w: number, h: number): Node | null {
    this.root = {
      used: true,
      x: 0,
      y: 0,
      w: this.root.w + w,
      h: this.root.h,
      down: this.root,
      right: { x: this.root.w, y: 0, w: w, h: this.root.h },
    };

    let node: Node | null;
    if ((node = this.findNode(this.root, w, h))) {
      return this.splitNode(node, w, h);
    }
    return null;
  }

  private static growDown(w: number, h: number): Node | null {
    this.root = {
      used: true,
      x: 0,
      y: 0,
      w: this.root.w,
      h: this.root.h + h,
      down: { x: 0, y: this.root.h, w: this.root.w, h: h },
      right: this.root,
    };
    let node: Node | null;
    if ((node = this.findNode(this.root, w, h))) {
      return this.splitNode(node, w, h);
    }
    return null;
  }
}

/**
 * Директива валидации URL
 */
(function () {
  'use strict';

  angular.module('myApp.directives.url').directive('cqUrl', cqUrl);

  function cqUrl($q, validationHelper) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$asyncValidators.url = url;

      /**
       * Серверная валидация URL
       *
       * @param modelValue Значение модели для валидации
       * @returns {Promise}
       */
      function url(modelValue) {
        // если значение модели пустое - валидация не требуется
        if (controller.$isEmpty(modelValue)) {
          return $q.resolve();
        }

        // раскомментить этот кусок кода и заменить UrlField на валидатор поддомена когда он будет написан на бэкэнде
        return validationHelper.validateAsync(modelValue, 'UrlField');
      }
    }
  }
})();

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TeamMemberModel } from '@http/team-member/team-member.model';
import { ApiCreateUserNoteResponse, ApiGetUserNoteResponse, UserNote } from '@http/user-note/user-note.types';

/**
 * Модель заметки пользователя
 */
@Injectable({ providedIn: 'root' })
export class UserNoteModel {
  constructor(private readonly http: HttpClient, private readonly teamMemberModel: TeamMemberModel) {}

  /**
   * Создание заметки у пользователя
   *
   * @param userId ID пользователя
   * @param text Текст заметки
   */
  create(userId: string, text: string): Observable<UserNote> {
    const body = {
      body: text,
      user: userId,
    };

    return this.http.post<ApiCreateUserNoteResponse>(`/users/notes`, body).pipe(
      map((note) => {
        return this.parse(note);
      }),
    );
  }

  /**
   * Получение заметки
   *
   * @param noteId ID заметки
   */
  get(noteId: string): Observable<UserNote> {
    // почему это POST? метод не используется, возможно просто копипаста
    return this.http.post<ApiGetUserNoteResponse>(`/users/notes/${noteId}`, {}).pipe(
      map((note) => {
        return this.parse(note);
      }),
    );
  }

  /**
   * Парсинг заметки
   *
   * @param note Заметка
   */
  parse(note: ApiCreateUserNoteResponse | UserNote): UserNote {
    const parsedNote = note as unknown as UserNote;

    if (typeof note.created === 'number') {
      parsedNote.created = moment(note.created * 1000);
    }

    this.teamMemberModel.parse(parsedNote.author);

    return parsedNote;
  }

  /**
   * Удаление заметки
   *
   * @param noteId ID заметки
   */
  remove(noteId: string): Observable<unknown> {
    return this.http.delete(`/users/notes/${noteId}`);
  }
}

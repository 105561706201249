import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ConversationPartPreviewVideoComponent } from './conversation-part-preview-video.component';

@NgModule({
  declarations: [ConversationPartPreviewVideoComponent],
  exports: [ConversationPartPreviewVideoComponent],
  imports: [CommonModule, TranslocoModule],
})
export class ConversationPartPreviewVideoModule {}

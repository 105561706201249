import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';
import { isNull } from 'lodash-es';

import { environment } from '@environment';
import { Billing } from '@http/billing/billing';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { App } from '@http/app/app.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Компонент для работы с модальным окном отмены подписки */
@Component({
  selector: 'cq-cancellation-exactly-modal',
  templateUrl: './cancellation-exactly-modal.component.html',
  styleUrls: ['./cancellation-exactly-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancellationExactlyModalComponent {
  /** Control для работы с причиной отмены подписки */
  formControl: FormControl<string | null> = new FormControl<string | null>(null, [Validators.required]);

  /** Выполняется ли запрос */
  isRequestPerformed: boolean = false;

  /** Название текущего проекта */
  projectName = environment.projectName;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly subscriptionStore: SubscriptionStore,
    private readonly uiRouter: UIRouter,
  ) {}

  /**
   * Отмена подписки в конце биллинг периода
   *
   * @param app - Текущее приложение
   * @param billing - Биллинг
   * @param reason - Причина отмены подписки
   */
  cancelSubscriptionAtEndBillingPeriod(app: App, billing: Billing, reason: string): void {
    this.requestStart();

    billing
      .cancelSubscriptionAtEndBillingPeriod()
      .then((billing) => {
        this.updateBilling(billing);

        this.trackCancelAtCurrentTermEndDate(app, reason);
      })
      .finally(() => {
        this.requestEnd();
      });
  }

  /**
   * Отмена подписки прямо сейчас
   *
   * @param app - Текущее приложение
   * @param billing - Биллинг
   * @param reason - Причина отмены подписки
   */
  cancelAtNow(app: App, billing: Billing, reason: string): void {
    this.requestStart();

    billing
      .cancelSubscriptionAtNow()
      .then((subscription) => {
        this.updateBilling(subscription);

        this.trackCancelRightNow(app, reason);
      })
      .finally(() => {
        this.requestEnd();
      });
  }

  /**
   * Обработчик клика по кнопкам отмены подписки
   *
   * @param app - Текущее приложение
   * @param billing - Биллинг
   * @param reason - Причина отмены подписки
   * @param isEndOfTerm - Отменять ли подписку в конце платёжного периода
   */
  onClickCancellationButton(app: App, billing: Billing, reason: string | null, isEndOfTerm: boolean): void {
    if (isNull(reason)) {
      this.formControl.markAllAsTouched();
      return;
    }

    if (isEndOfTerm) {
      this.cancelSubscriptionAtEndBillingPeriod(app, billing, reason);
    } else {
      this.cancelAtNow(app, billing, reason);
    }
  }

  /** Окончание запроса на отмену подписки */
  requestEnd(): void {
    this.isRequestPerformed = false;

    this.ngbActiveModal.dismiss();

    this.uiRouter.stateService.reload('app.content.subscription');
  }

  /** Начало запроса на отмену подписки */
  requestStart(): void {
    this.isRequestPerformed = true;
  }

  /**
   * Трекинг отмены подписки текущего платёжного периода
   *
   * @param app - Текущее приложение
   * @param reason - Причина отмены подписки
   */
  trackCancelAtCurrentTermEndDate(app: App, reason: string): void {
    const eventName = 'Отменил подписку';
    const params = {
      Причина: reason,
      Когда: 'В конце периода',
      Сайт: app.name,
    };

    this.carrotquestHelper.track(eventName, params);
  }

  /**
   * Трекинг отмены подписки прямо сейчас
   *
   * @param app - Текущее приложение
   * @param reason - Причина отмены подписки
   */
  trackCancelRightNow(app: App, reason: string): void {
    const eventName = 'Отменил подписку';
    const params = {
      Причина: reason,
      Когда: 'Прямо сейчас',
      Сайт: app.name,
    };

    this.carrotquestHelper.track(eventName, params);
  }

  /**
   * Обновление информации о биллинге
   *
   * @param billing - Биллинг
   */
  updateBilling(billing: Billing): void {
    this.subscriptionStore.billing$.next(billing);
  }
}

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqWorkingHoursController', CqWorkingHoursController);

  function CqWorkingHoursController($translate, moment, carrotquestHelper, validationHelper) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.addWorkingPeriod = addWorkingPeriod;
      vm.listStartTimes = []; // Список объектов допустимого времени для начала рабочего периода (от 00:00 до 23:45).
      vm.listEndTimes = []; // Список объектов допустимого времени для окончания рабочего периода (от 00:15 до 00:00)
      vm.listWorkingDays = []; // Допустимые варианты рабочих дней (дни недели, группы дней, представлены объектами со строковыми константами)
      vm.listWorkingPeriods = []; // Настроенные периоды рабочего времени
      vm.removeWorkingPeriod = removeWorkingPeriod;
      vm.trackClickAddWorkingPeriod = trackClickAddWorkingPeriod;
      vm.trackClickOfflineIfNoOperators = trackClickOfflineIfNoOperators;
      vm.trackClickSettingsLink = trackClickSettingsLink;
      vm.validationHelper = validationHelper;
      vm.valueIsAfterStartTime = valueIsAfterStartTime;
      vm.valueIsBeforeEndTime = valueIsBeforeEndTime;

      initTimePeriods();

      if (angular.isUndefined(vm.workingPeriods) || vm.workingPeriods.length === 0) {
        vm.workingPeriods = [];
        addWorkingPeriod(vm.workingPeriods);
      }
    }

    /**
     * Добавляет новый рабочий период в список
     *
     * @param {Array} workingPeriods массив периодов рабочего времени
     */
    function addWorkingPeriod(workingPeriods) {
      let item = {
        days: undefined,
        start: undefined,
        end: undefined,
      };
      workingPeriods.push(item);
    }

    /**
     * Заполняются массивы-справочники с рабочими днями и рабочими часами
     */
    function initTimePeriods() {
      /*
       * TODO когда компонент будет переписываться на новый ангуляр
       *  listStartTimes и listEndTimes можно заменить на массивы чисел, а в шаблоне заюзать WorkTimePipe
       * */
      let indent_val = 0;
      for (let i = 0; i < 97; i++) {
        if (i < 96) {
          vm.listStartTimes.push({
            value: indent_val,
            display: moment('00:00', 'H:mm').set('minute', indent_val).format('LT'),
          });
        }

        if (i > 0) {
          vm.listEndTimes.push({
            value: indent_val,
            display: moment('00:00', 'H:mm').set('minute', indent_val).format('LT'),
          });
        }

        indent_val += 15;
      }

      vm.listWorkingDays.push(
        {
          value: 'everyday',
          name: $translate.instant('workingHours.workingDays.groups.everyday'),
          group: '',
        },
        {
          value: 'workdays',
          name: $translate.instant('workingHours.workingDays.groups.workdays'),
          group: '',
        },
        {
          value: 'weekend',
          name: $translate.instant('workingHours.workingDays.groups.weekend'),
          group: '',
        },
        {
          value: 'monday',
          name: $translate.instant('workingHours.workingDays.weekdays.monday'),
          group: $translate.instant('workingHours.workingDays.groups.weekdays'),
        },
        {
          value: 'tuesday',
          name: $translate.instant('workingHours.workingDays.weekdays.tuesday'),
          group: $translate.instant('workingHours.workingDays.groups.weekdays'),
        },
        {
          value: 'wednesday',
          name: $translate.instant('workingHours.workingDays.weekdays.wednesday'),
          group: $translate.instant('workingHours.workingDays.groups.weekdays'),
        },
        {
          value: 'thursday',
          name: $translate.instant('workingHours.workingDays.weekdays.thursday'),
          group: $translate.instant('workingHours.workingDays.groups.weekdays'),
        },
        {
          value: 'friday',
          name: $translate.instant('workingHours.workingDays.weekdays.friday'),
          group: $translate.instant('workingHours.workingDays.groups.weekdays'),
        },
        {
          value: 'saturday',
          name: $translate.instant('workingHours.workingDays.weekdays.saturday'),
          group: $translate.instant('workingHours.workingDays.groups.weekdays'),
        },
        {
          value: 'sunday',
          name: $translate.instant('workingHours.workingDays.weekdays.sunday'),
          group: $translate.instant('workingHours.workingDays.groups.weekdays'),
        },
      );
    }

    /**
     * Удаляет рабочий период из списка
     *
     * @param {Array} workingPeriods массив периодов рабочего времени
     * @param {Number} index индекс удаляемого периода
     */
    function removeWorkingPeriod(workingPeriods, index) {
      workingPeriods.splice(index, 1);
    }

    /**
     * Трек клика на кнопку "+ Добавить период" в настройках рабочих часов операторов
     */
    function trackClickAddWorkingPeriod() {
      carrotquestHelper.track('Рабочие часы - добавил период', { app_id: vm.currentApp.id });
    }

    /**
     * Трек клика на чекбокс "Включать офлайн-режим, если в рабочее время все операторы в офлайне"
     */
    function trackClickOfflineIfNoOperators() {
      carrotquestHelper.track(
        'Рабочие часы - включать/выключать офлайн-режим, если в рабочее время все операторы в офлайне',
        { app_id: vm.currentApp.id },
      );
    }

    /**
     * Трек клика на ссылку "в настройках" в описании Рабочего времени чата
     */
    function trackClickSettingsLink() {
      carrotquestHelper.track('Рабочие часы - клик по ссылке "Настройки"', { app_id: vm.currentApp.id });
    }

    /**
     * Время окончания рабочего периода можно выбрать только позже времени начала
     *
     * @param {Number} startTime время начала рабочего периода (целое число, сдвиг от полуночи)
     * @returns {Function}
     */
    function valueIsAfterStartTime(startTime) {
      return function (item) {
        if (startTime) {
          return item.value > startTime;
        } else {
          return true;
        }
      };
    }

    /**
     * Время начала рабочего периода можно выбрать только раньше времени начала
     *
     * @param {Number} endTime время окончания рабочего периода (целое число, сдвиг от полуночи)
     * @returns {Function}
     */
    function valueIsBeforeEndTime(endTime) {
      return function (item) {
        if (endTime) {
          return item.value < endTime;
        } else {
          return true;
        }
      };
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { EVENTS_ANALYTICS_PAGE_ROUTES } from '@panel/app/pages/events-analytics/events-analytics-page.routes';
import { EventsAnalyticsModule } from '@panel/app/pages/events-analytics/pages/events-analytics/events-analytics.module';

import { EventsAnalyticsPageComponent } from './events-analytics-page.component';

@NgModule({
  declarations: [EventsAnalyticsPageComponent],
  imports: [
    CommonModule,
    EventsAnalyticsModule,
    UIRouterUpgradeModule.forChild({ states: EVENTS_ANALYTICS_PAGE_ROUTES }),
  ],
})
export class EventsAnalyticsPageModule {}

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { combineLatest, distinctUntilChanged, firstValueFrom, map, Observable, of } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';

import { environment } from '@environment';
import { AppService } from '@http/app/services/app.service';
import { TRIGGER_TYPE_KIND } from '@http/message/message.constants';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';
import { DestroyService } from '@panel/app/services';
import { TelegramBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { TelegramBotStoreService } from '@panel/app-old/shared/services/telegram-bot-editor-store/telegram-bot-store.service';

import { TgTriggerType } from './tg-trigger.types';

@Component({
  selector: 'cq-tg-trigger-types',
  templateUrl: './tg-trigger-types.component.html',
  styleUrls: ['./tg-trigger-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class TgTriggerTypesComponent {
  readonly formControl = this.fb.control<TgTriggerType | null>(null, [Validators.required]);

  public tgBotTriggerTypeKinds = [TRIGGER_TYPE_KIND.BOT_MANUAL_START, TRIGGER_TYPE_KIND.BOT_FIRST_USER_REPLY];

  @Input()
  set tgTriggerTypes(value: TgTriggerType[]) {
    if (!value) {
      return;
    }
    this.formControl.setValue(value[0]);
  }

  @Output()
  tgTriggerTypesChange: Observable<TgTriggerType[]> = this.formControl.valueChanges.pipe(
    distinctUntilChanged(),
    map((kind) => {
      return kind ? [kind] : ([] as TgTriggerType[]);
    }),
  );

  @Output()
  touchAndValidateCallback: Observable<ValidationCallback> = of(() => {
    this.formControl.markAsTouched();
    return this.formControl.status !== 'PENDING'
      ? Promise.resolve(this.formControl.valid)
      : firstValueFrom(
          this.formControl.statusChanges.pipe(
            filter((status) => status !== 'PENDING'),
            map(() => this.formControl.valid),
          ),
        );
  });

  constructor(
    private readonly appService: AppService,
    private readonly destroy$: DestroyService,
    private readonly fb: FormBuilder,
    private readonly telegramBotStoreService: TelegramBotStoreService,
  ) {
    /** Нужно заблокировать радио-кнопки, пока не будет выбрана интеграция, с которой будет работать бот */
    combineLatest([
      telegramBotStoreService.editableTelegramBot$,
      telegramBotStoreService.editableTelegramBotIntegrationId$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        takeWhile(([editableTelegramBot, _]) => !editableTelegramBot!.id),
      )
      .subscribe(([_, integrationId]) => {
        if (integrationId) {
          this.formControl.enable();
          this.formControl.setValue(TRIGGER_TYPE_KIND.BOT_MANUAL_START);
        } else {
          this.formControl.disable();
        }
      });
  }

  /**
   * Получение Телеграм-бота, который уже использует данный триггер
   *
   * @param triggerType
   */
  alreadyUsedTriggerTypeBot(triggerType: TRIGGER_TYPE_KIND) {
    return combineLatest(
      this.telegramBotStoreService.telegramBotAllList$,
      this.telegramBotStoreService.editableTelegramBot$,
      this.telegramBotStoreService.editableTelegramBotIntegrationId$,
    ).pipe(
      map(
        ([telegramBotList, editableTelegramBot, editableTelegramBotIntegrationId]) =>
          telegramBotList.filter(
            (bot) =>
              bot.active &&
              bot.id !== editableTelegramBot?.id &&
              bot.triggerTypes[0] === triggerType &&
              bot.integration === editableTelegramBotIntegrationId,
          )[0],
      ),
    );
  }

  getTelegramBotUrl(telegramBot: TelegramBot): string {
    return `${environment.panelUrl}/${this.appService.currentAppId}/messages/telegram-bot/${telegramBot.id}/edit`;
  }
}

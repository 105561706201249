import { of } from 'rxjs';

import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

import { ActionValidationExtra, BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

export class BotMarkConversationVisibleActionForm extends BaseBotActionForm {
  allTouchedChanges$ = of(false);

  getCustomControls(action: ChatBotAction, validationExtra: ActionValidationExtra): CustomControlsWithBodyJsonRequired {
    return {
      bodyJson: new GenericFormGroup({}),
    };
  }
}

import { InjectionToken } from '@angular/core';

import { EventType } from '@http/property/property.model';

/**
 * Тип данных, передаваемых в модалку редактирования события
 */
export type EditEventTypeModalData = {
  /** Редактируемое событие */
  eventType: EventType;
};

/**
 * Токен для передачи данных через DI в модалку редактирования события
 */
export const EDIT_EVENT_TYPE_MODAL_DATA_TOKEN = new InjectionToken<EditEventTypeModalData>(
  'EditEventTypeModal data provider',
);

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';

/**
 * Уменьшает maxFPS тикера до минимального значения.
 * Именно pipe, потому что изменять фпс нужно только во время интеракций, интеракции = observable
 */
export function decreaseTickerMaxFPS(pixiApp: PixiApplication) {
  return <T>(source: Observable<T>): Observable<T> => source.pipe(tap(() => (pixiApp.ticker.maxFPS = 1)));
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { PropertyFilterModule } from '@panel/app/partials/filters/components/property-filter/property-filter.module';

import { PropertyFiltersComponent } from './property-filters.component';

@NgModule({
  declarations: [PropertyFiltersComponent],
  exports: [PropertyFiltersComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    PropertyFilterModule,
    NgbCollapseModule,
  ],
})
export class PropertyFiltersModule {}

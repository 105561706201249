import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { InstallScriptModalComponent } from '../../../../app/shared/modals/install-script-modal/install-script-modal.component';
import { firstValueFrom } from 'rxjs';
import {
  CLICKHOUSE_SYSTEM_PROPERTIES_BY_EVENT_TYPE,
  CLICKHOUSE_USER_SYSTEM_PROPERTIES,
} from '../../../../app/http/property/property.constants';

/**
 * Контроллер для главной страницы воронок
 */
(function () {
  'use strict';

  angular.module('myApp.funnels').controller('CqFunnelsController', CqFunnelsController);

  function CqFunnelsController(
    $filter,
    $scope,
    $state,
    $translate,
    $uibModal,
    moment,
    toastr,
    appModel,
    carrotquestHelper,
    dateRangePickerHelper,
    funnelModel,
    l10nHelper,
    planFeatureAccessService,
    modalHelperService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToEventsFunnels = planFeatureAccessService.getAccess(PLAN_FEATURE.EVENTS_FUNNELS, vm.currentApp);

      trackEnterOnPage();

      vm.counters = []; // результат построения воронки (счётчики)
      vm.currentSegmentation = {
        // текущая сегментация
        data: [], // данные с сервера
        sorting: {
          // настройка сортировки колонок таблицы сегментации
          index: 1, // номер колонки в таблице для сортировки
          reverse: true, // обратный порядок (от большего к меньшему)
        },
        segmentProperty: '', // свойство, по которому сегментирована воронка
        step: null, // шаг, по которому сегментирована воронка
      };
      vm.currentSegmentProperty = ''; // текущее свойство для сегментации
      vm.currentStep = null; // текущий шаг воронки
      vm.funnelRange = {
        // настройки периода отображения воронки
        dates: {
          // период отображения воронки
          startDate: moment().subtract(1, 'months').startOf('day'),
          endDate: moment().endOf('day'),
        },
        dateRangePickerOptions: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          dateLimit: {
            quarters: 1,
          },
          minDate: moment().subtract(2, 'years').startOf('day'),
          maxDate: moment().endOf('day'),
          opens: 'left',
          parentEl: '#funnel-datepicker-holder',
        }),
      };
      vm.isSegmentRequestPerformed = false; // выполняется ли в данный момент запрос сегментации воронки
      vm.openCopyFunnelModal = openCopyFunnelModal;
      vm.openCreateFunnelModal = openCreateFunnelModal;
      vm.openEditFunnelModal = openEditFunnelModal;
      vm.openRemoveFunnelModal = openRemoveFunnelModal;
      vm.segment = segment;
      vm.segmentationTableSortingFunction = segmentationTableSortingFunction;
      vm.segmentProperties = []; // свойства, по которым можно провести сегментацию воронки
      vm.sortSegmentationTable = sortSegmentationTable;
      vm.trackClickCreateFunnelButton = trackClickCreateFunnelButton;
      vm.trackClickFunnelTip = trackClickFunnelTip;
      vm.trackOnFunnelSegmentation = trackOnFunnelSegmentation;

      $scope.$watchCollection('[vm.funnelRange.dates.startDate, vm.funnelRange.dates.endDate]', watchDates);
      $scope.$watch('vm.currentStep', watchCurrentStep);
      $scope.$watch('vm.funnel', watchFunnel);

      //!!! Это стоит подумать как омжно сделать лучше
      if ($state.params.startDate) {
        vm.funnelRange.dates.startDate = $state.params.startDate;
      }
      if ($state.params.endDate) {
        vm.funnelRange.dates.endDate = $state.params.endDate;
      }

      /**
       * Обновление списка свойств, доступных для сегментации при изменении текущего шага
       *
       * @param step
       */
      function watchCurrentStep(step) {
        vm.currentStep = step;
        var segmentProperties = [];

        segmentProperties.push.apply(segmentProperties, CLICKHOUSE_USER_SYSTEM_PROPERTIES);

        if (step) {
          for (var j = 0; j < step.length; j++) {
            var condition = step[j];

            segmentProperties.push.apply(
              segmentProperties,
              CLICKHOUSE_SYSTEM_PROPERTIES_BY_EVENT_TYPE[condition.eventType.name],
            );
          }
        }

        vm.segmentProperties = $filter('unique')(segmentProperties);

        // если в новом списке свойств нет свойства, которое было выбрано ранее - это свойство надо затереть
        if (!~vm.segmentProperties.indexOf(vm.currentSegmentProperty)) {
          vm.currentSegmentProperty = null;
        }
      }

      /**
       * Переход на состояние другой воронки при изменении текущей воронки
       *
       * @param newValue
       * @param oldValue
       */
      function watchFunnel(newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          $state.go(
            'app.content.funnels.configured',
            {
              funnelId: vm.funnel.id,
              startDate: vm.funnelRange.dates.startDate,
              endDate: vm.funnelRange.dates.endDate,
            },
            { reload: 'app.content.funnels.configured' },
          );
        }
      }

      /**
       * Запросы к серверу при изменении дат
       *
       * @param newValues
       * @param oldValues
       */
      function watchDates(newValues, oldValues) {
        if (newValues[0] && newValues[1] && vm.funnel) {
          // дальше - сложно
          // если счётчики и сегментация уже получены - счётчики по новой получать не надо, их можно вытащить из сегментации путём арифметических операций
          // иначе, если счётчики получены, а сегментация нет - получаем только счётчики
          // иначе, если даже счётчики не получены - получаем только счётчики
          if (vm.counters.length && vm.currentSegmentation.data.length) {
            segment();
          } else if (vm.counters.length && !vm.currentSegmentation.data.length) {
            countFunnel(vm.funnel, newValues[0], newValues[1]);
          } else {
            // эта процедура делается намерено чтобы акцентировать внимание на том,
            // что если не получены ни счетчики ни сегментация необходимо получить только счётчики
            countFunnel(vm.funnel, newValues[0], newValues[1]);
          }

          $state.go('.', {
            startDate: newValues[0],
            endDate: newValues[1],
          });
        }
      }
    }

    /**
     * Построение воронки
     *
     * @param {Object} funnel Воронка
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     */
    function countFunnel(funnel, startDate, endDate) {
      firstValueFrom(funnelModel.count(funnel.id, startDate, endDate)).then(countSuccess);

      function countSuccess(counters) {
        vm.counters = counters;
      }
    }

    /**
     * Открытие модалки копирования воронки
     *
     * @param {Object} funnel Воронка
     */
    function openCopyFunnelModal(funnel) {
      var funnelCopy = angular.copy(funnel);

      delete funnelCopy.id;
      delete funnelCopy.name;

      openFunnelEditorModal(funnelCopy, 'copy').then(copyFunnelSuccess);

      function copyFunnelSuccess(funnelId) {
        toastr.success($translate.instant('funnels.general.toasts.copyFunnelSuccess'));
        reloadCurrentState(funnelId).then(function () {
          if (appModel.isAppBlocked(vm.currentApp)) {
            openInstallScriptModal();
          }
        });
      }
    }

    /**
     * Открытие модалки создания воронки
     */
    function openCreateFunnelModal() {
      var defaultFunnel = funnelModel.getDefault();

      openFunnelEditorModal(defaultFunnel, 'create').then(createFunnelSuccess);

      function createFunnelSuccess(funnelId) {
        toastr.success($translate.instant('funnels.general.toasts.createFunnelSuccess'));

        // HACK при создании первой воронки, чтобы модалка активации больше не показывалась, надо обновить created_funnel. Пока это сделано тут. В идеале надо сделать это в модели
        if (!vm.currentApp.activation.created_funnel) {
          vm.currentApp.activation.created_funnel = moment();
        }

        reloadCurrentState(funnelId).then(function () {
          if (appModel.isAppBlocked(vm.currentApp)) {
            openInstallScriptModal();
          }
        });
      }
    }

    /**
     * Открытие модалки редактирования воронки
     *
     * @param funnel
     */
    function openEditFunnelModal(funnel) {
      var funnelCopy = angular.copy(funnel);

      openFunnelEditorModal(funnelCopy, 'edit').then(saveFunnelSuccess);

      function saveFunnelSuccess(funnelId) {
        toastr.success($translate.instant('funnels.general.toasts.saveFunnelSuccess'));
        reloadCurrentState(funnelId).then(function () {
          if (appModel.isAppBlocked(vm.currentApp)) {
            openInstallScriptModal();
          }
        });
      }
    }

    /**
     * Открытие модалки с редактором воронки
     *
     * @param {Object} funnelToEdit Воронка
     * @param {String} modalAction Состояние для заголовка и конпки модального окна
     * @return {Promise}
     */
    function openFunnelEditorModal(funnelToEdit, modalAction) {
      var funnelEditorModal = $uibModal.open({
        backdrop: 'static',
        component: 'cqFunnelEditorModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          currentFunnelName: angular.bind(null, angular.identity, vm.funnel ? vm.funnel.name : ''),
          funnel: angular.bind(null, angular.identity, funnelToEdit),
          modalAction: angular.bind(null, angular.identity, modalAction),
          properties: angular.bind(null, angular.identity, vm.properties),
        },
        size: 'xl modal-dialog-scrollable',
        windowClass: 'funnel-editor-modal',
      });

      return funnelEditorModal.result;
    }

    /**
     * Открытие модалки удаления воронки
     */
    function openRemoveFunnelModal() {
      var removeFunnelModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('funnels.general.removeFunnelModal.heading'),
              body: $translate.instant('funnels.general.removeFunnelModal.body', { name: vm.funnel.name }),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      removeFunnelModal.result.then(removeFunnel);

      function removeFunnel() {
        return firstValueFrom(funnelModel.remove(vm.funnel.id)).then(removeFunnelSuccess);

        function removeFunnelSuccess() {
          trackRemoveFunnel();
          vm.funnels.splice(vm.funnels.indexOf(vm.funnel), 1);
          toastr.success($translate.instant('funnels.general.toasts.removeFunnelSuccess'));
          $state.go('app.content.funnels.unconfigured');
        }
      }
    }

    /**
     * Перезагрузка текущего состояния
     * FIXME: не знал как назвать функцию, в будущем переназвать
     *
     * @param {String} funnelId ID воронки
     */
    function reloadCurrentState(funnelId) {
      if (funnelId) {
        // нужно принудительно перезагрузить состояние воронок, чтобы обновить список воронок
        return $state.go('app.content.funnels.configured', { funnelId: funnelId }, { reload: 'app.content.funnels' });
      } else {
        return $state.go('app.content.funnels.unconfigured');
      }
    }

    /**
     * Сегментация воронки
     */
    function segment() {
      vm.isSegmentRequestPerformed = true;

      firstValueFrom(
        funnelModel.segment(
          vm.funnel.id,
          vm.funnelRange.dates.startDate,
          vm.funnelRange.dates.endDate,
          vm.currentSegmentProperty,
          vm.funnel.steps.indexOf(vm.currentStep),
        ),
      )
        .then(segmentSuccess)
        .finally(segmentFinally);

      function segmentSuccess(segmentation) {
        vm.counters = segmentation.counters; // при каждой сегментации надо обновлять данные воронки, вдруг цифры изменились
        vm.currentSegmentation.data = segmentation.segmentation;
        vm.currentSegmentation.segmentProperty = vm.currentSegmentProperty;
        vm.currentSegmentation.step = vm.currentStep;
        vm.sortSegmentationTable(null); // сбрасываем сортировку
      }

      function segmentFinally() {
        vm.isSegmentRequestPerformed = false;
      }
    }

    /**
     * Функция-getter для фильтра orderBy в таблице сегментации
     *
     * @param {Array.<*>} propertyData Данные сегментации по свойству
     * @return {*}
     */
    function segmentationTableSortingFunction(propertyData) {
      return propertyData[vm.currentSegmentation.sorting.index];
    }

    /**
     * Показ модалки установки скрипта
     */
    function openInstallScriptModal() {
      let modal = modalHelperService.open(InstallScriptModalComponent);

      modal.componentInstance.modalWindowParams = {
        body: $translate.instant('funnels.general.installScriptModal.body'),
        currentApp: vm.currentApp,
        djangoUser: vm.djangoUser,
        heading: $translate.instant('funnels.general.installScriptModal.heading'),
      };
    }

    /**
     * Сортировка таблицы сегментации
     *
     * @param {Number|null} index Номер столбца, по которому сотрируется таблица
     */
    function sortSegmentationTable(index) {
      // если индекс после предыдущей сортировки не совпал или сортировка не была сброшена - по умолчанию сортировка отработает от большего к меньшему
      if (vm.currentSegmentation.sorting.index === index && index !== null) {
        vm.currentSegmentation.sorting.reverse = !vm.currentSegmentation.sorting.reverse;
      } else {
        vm.currentSegmentation.sorting.reverse = true;
      }

      // по умолчанию таблица сортируется по второму слолбцу
      vm.currentSegmentation.sorting.index = index === null ? 1 : index;
    }

    /**
     * Трек на клик на "Создать воронку" (открытие модального окна)
     */
    function trackClickCreateFunnelButton() {
      carrotquestHelper.track('Воронки 2018 - клик на "Создать воронку"');
    }

    /**
     * Трек на клик по подсказкам
     */
    function trackClickFunnelTip() {
      carrotquestHelper.track('Воронки 2018 - клик на подсказки');
    }

    /**
     * Трек захода на страницу воронок
     */
    function trackEnterOnPage() {
      carrotquestHelper.track('Зашел на воронки 2018', {
        App: vm.currentApp.name,
        'App ID': vm.currentApp.id,
      });
    }

    /**
     * Трек на использование сегментации
     *
     * @param {Object} property названия свойств событий
     */
    function trackOnFunnelSegmentation(property) {
      carrotquestHelper.track('Воронки 2018 - применил сегментацию', {
        'Названия свойств событий': property,
      });
    }

    /**
     * Трек на удаление воронки
     */
    function trackRemoveFunnel() {
      carrotquestHelper.track('Воронки 2018 - удалил воронку');
    }
  }
})();

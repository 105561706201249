import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-vk-forms-api-token[control]',
  templateUrl: './vk-forms-api-token.component.html',
  styleUrls: ['./vk-forms-api-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsApiTokenComponent {
  /** Control для настройки API-token'а интеграции */
  @Input()
  control!: GenericFormControl<string | null>;

  /** Флаг блокировки input'а */
  @Input()
  disabled: boolean = false;
}

<cq-event-type-select
  [autoEvents]="autoEvents"
  (autoEventsChange)="autoEventsChange.next($event)"
  (eventTypesChange)="eventTypesChange.next($event)"
  [eventTypes]="eventTypes"
  [currentApp]="currentApp"
  [placeholderText]="placeholderText"
  [ngModel]="value"
  (ngModelChange)="valueChange.next($event)"
  [userProps]="userProps"
  (selectedEventTypeChange)="selectedEventTypeChange.next($event)"
  (openChange)="openChange.next($event)"
  (sessionStartAdd)="sessionStartAdd.next($event)"
  (urlEventTypeAdd)="urlEventTypeAdd.next($event)"
>
</cq-event-type-select>

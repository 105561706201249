<ng-container *transloco="let t; read: 'articlesListComponent'">
  <div
    *ngIf="articles.length !== 0 || isSearch"
    class="card-header"
  >
    <div
      *ngIf="selectedArticles.length === 0"
      class="align-items-center justify-space-between"
      [ngClass]="{ flex: !selectedArticles.length }"
    >
      <div class="flex align-items-center">
        <!--СОЗДАТЬ СТАТЬЮ-->
        <a
          *ngIf="(accessToKnowledgeBasePro.hasAccess || articlesLeftOnStandart > 0) && !isSearch"
          class="btn btn-outline-primary"
          uiSref="app.content.knowledgeBase.articleCreate"
          [uiParams]="{ categoryId: currentCategory.id }"
          (click)="trackClickCreateArticle()"
        >
          <i class="btn-icon-left cqi-sm cqi-plus"></i>
          <span>{{ 'knowledgeBase.articlesList.createArticleButton' | transloco }}</span>
        </a>
        <button
          *ngIf="!accessToKnowledgeBasePro.hasAccess && articlesLeftOnStandart <= 0 && !isSearch"
          class="btn btn-outline-primary"
          (click)="openLimitReachedModal()"
        >
          <i class="btn-icon-left cqi-sm cqi-plus"></i>
          <span>{{ 'knowledgeBase.articlesList.createArticleButton' | transloco }}</span>
        </button>
        <span
          *ngIf="!accessToKnowledgeBasePro.hasAccess"
          class="margin-left-10 small text-muted"
          [innerHTML]="
            'knowledgeBase.articlesList.limitationInfo'
              | transloco: { articlesLeft: articlesLeftOnStandart < 0 ? 0 : articlesLeftOnStandart }
          "
        >
        </span>
      </div>
      <div class="flex align-items-center">
        <!--ФИЛЬТРЫ-->
        <div
          class="margin-right-20"
          ngbDropdown
          autoClose="outsideClick"
        >
          <button
            class="btn btn-outline-primary"
            ngbDropdownToggle
          >
            <i class="btn-icon-left cqi-sm cqi-filter"></i>
            <span
              *ngIf="!filtersCount"
              [innerHTML]="'knowledgeBase.articlesList.table.headings.filterButton.noFilters' | transloco"
            ></span>
            <span *ngIf="filtersCount">
              {{
                'knowledgeBase.articlesList.table.headings.filterButton.hasFilters' | transloco: { count: filtersCount }
              }}
            </span>
          </button>
          <div
            class="dropdown-menu-right padding-left-10 padding-right-10 text-nowrap margin-between-rows-10"
            ngbDropdownMenu
          >
            <div
              class="custom-control custom-checkbox"
              *ngFor="let status of ARTICLES_STATUSES_LIST"
            >
              <input
                class="custom-control-input"
                type="checkbox"
                [id]="'status-' + status"
                (change)="trackSetFilterPublicStatus()"
                [ngModel]="filters[status]"
                (ngModelChange)="onChangeFilter(status)"
              />
              <label
                class="custom-control-label"
                [for]="'status-' + status"
                [innerHTML]="'models.article.publishStatuses.' + status | transloco"
              ></label>
            </div>
            <div
              class="custom-control custom-checkbox"
              *ngFor="let status of ARTICLES_INDEXED_STATUSES_LIST"
            >
              <input
                class="custom-control-input"
                type="checkbox"
                [id]="'indexed-status-' + status"
                (change)="trackSetFilterPublicStatus()"
                [ngModel]="filters[status]"
                (ngModelChange)="onChangeFilter(status)"
              />
              <label
                class="custom-control-label"
                [for]="'indexed-status-' + status"
                [innerHTML]="'models.article.indexedStatuses.' + status | transloco"
              ></label>
            </div>
          </div>
        </div>
        <!--ПОИСК-->
        <ng-content></ng-content>
      </div>
    </div>
    <div
      *ngIf="selectedArticles.length"
      class="text-center"
    >
      <!--УДАЛЕНИЕ-->
      <button
        class="btn btn-borderless-primary"
        type="button"
        (click)="trackRemoveArticles(selectedArticles.length); openRemoveArticlesModal(selectedArticles)"
      >
        <i class="btn-icon-left cqi-sm cqi-trash"></i>
        <span>{{ 'knowledgeBase.articlesList.removeButton' | transloco }}</span>
      </button>
      <!--ПЕРЕМЕЩЕНИЕ-->
      <button
        class="btn btn-borderless-primary"
        type="button"
        (click)="trackSetCategory(); openSetCategoryModal(selectedArticles)"
      >
        <i class="btn-icon-left cqi-sm cqi-move"></i>
        <span>{{ 'knowledgeBase.articlesList.setCategoryButton' | transloco }}</span>
      </button>
      <!--СОЗДАНИЕ КОПИИ-->
      <button
        class="btn btn-borderless-primary"
        type="button"
        (click)="trackCopy(); createCopy(selectedArticles[0])"
        [disabled]="selectedArticles.length > 1"
      >
        <i class="btn-icon-left cqi-sm cqi-copy"></i>
        <span>{{ 'knowledgeBase.articlesList.createCopyButton' | transloco }}</span>
      </button>
    </div>
  </div>
  <table
    *ngIf="filteredArticles.length"
    class="table table-sticky-header position-sticky"
  >
    <thead>
      <tr>
        <th>
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input"
              id="selected-all-articles"
              type="checkbox"
              (change)="setSelectForAllArticles()"
              [disabled]="!articles.length"
              [(ngModel)]="selectedAllArticles"
            />
            <label
              class="custom-control-label"
              for="selected-all-articles"
            ></label>
          </div>
        </th>
        <th>{{ 'knowledgeBase.articlesList.table.headings.name' | transloco }}</th>
        <th>{{ 'knowledgeBase.articlesList.table.headings.description' | transloco }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let article of filteredArticles; trackBy: trackByFn; index as index">
        <!--CHECKBOX-->
        <td class="shrink-by-content">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input"
              type="checkbox"
              [id]="'checked-' + article.id"
              (change)="refreshSelectedArticles()"
              [disabled]="selectedAllArticles"
              [(ngModel)]="article.checked"
            />
            <label
              class="custom-control-label"
              [for]="'checked-' + article.id"
            ></label>
          </div>
        </td>

        <!--НАЗВАНИЕ-->
        <td>
          <a
            class="text-break inline-block"
            uiSref="app.content.knowledgeBase.articleEdit"
            [uiParams]="{ articleId: article.id, categoryId: article.category.id }"
          >
            <b [attr.data-test]="'cq-articles-list-name-text-' + index">{{ article.name }}</b>
          </a>
          <!--КАТЕГОРИЯ-->
          <div
            *ngIf="isSearch && !currentCategory"
            class="small text-muted"
          >
            {{ article.category.name }}
          </div>
          <!--СОЗДАНО-->
          <div class="small text-nowrap">
            <span *ngIf="article.created.isSame(article.updated)">
              {{
                'knowledgeBase.articlesList.table.body.name.created'
                  | transloco
                    : {
                        createdDate: (article.created | amDateFormat: 'L'),
                        createdTime: (article.created | amDateFormat: 'LT')
                      }
              }}</span
            >
            <span *ngIf="article.created.isBefore(article.updated)">
              {{
                'knowledgeBase.articlesList.table.body.name.updated'
                  | transloco
                    : {
                        updatedDate: (article.updated | amDateFormat: 'L'),
                        updatedTime: (article.updated | amDateFormat: 'LT')
                      }
              }}</span
            >
          </div>
          <!--СТАТУС-->
          <div class="flex margin-between-cols-10 margin-top-15 margin-bottom-10">
            <span
              *ngIf="article.public"
              class="label label-light-success"
              >{{ 'knowledgeBase.articlesList.table.body.name.publicStatus.published' | transloco }}</span
            >
            <span
              *ngIf="!article.public"
              class="label label-light-danger"
              >{{ 'knowledgeBase.articlesList.table.body.name.publicStatus.notPublished' | transloco }}</span
            >
            <!--ИНДЕКСАЦИЯ-->
            <span
              *ngIf="article.robotsIndex"
              class="label label-light-secondary"
              >{{ 'knowledgeBase.articlesList.table.body.name.indexedStatuses.indexed' | transloco }}</span
            >
            <span
              *ngIf="!article.robotsIndex"
              class="label label-light-secondary"
              >{{ 'knowledgeBase.articlesList.table.body.name.indexedStatuses.noIndexed' | transloco }}</span
            >
          </div>
        </td>

        <!--ОПИСАНИЕ-->
        <td>
          <div
            *ngIf="article.shortDescription"
            class="text-break"
            [attr.data-test]="'cq-articles-list-description-text-' + index"
          >
            {{ article.shortDescription }}
          </div>
          <div
            *ngIf="article.quote"
            class="text-break"
          >
            {{ article.quote }}
          </div>
        </td>

        <!--КНОПКИ-->
        <td class="shrink-by-content">
          <div class="flex align-items-center justify-end">
            <!--Переместить вниз-->
            <button
              *ngIf="isShowArrowDown(article, index)"
              class="btn btn-borderless-primary"
              (click)="changePriority(article.id, filteredArticles[index + 1].priority)"
            >
              <i class="btn-icon cqi-sm cqi-arrow-down"></i>
            </button>
            <!--Переместить вверх-->
            <button
              *ngIf="isShowArrowUp(article, index)"
              class="btn btn-borderless-primary"
              (click)="changePriority(article.id, filteredArticles[index - 1].priority)"
            >
              <i class="btn-icon cqi-sm cqi-arrow-up"></i>
            </button>
            <div
              class="inline-block"
              ngbDropdown
            >
              <button
                class="btn btn-borderless-primary"
                type="button"
                ngbDropdownToggle
                [attr.data-test]="'cq-articles-ellipsis-button-' + index"
              >
                <i class="btn-icon cqi-sm cqi-ellipsis"></i>
              </button>
              <div
                class="dropdown-menu-right"
                ngbDropdownMenu
              >
                <!--Снять с публикации-->
                <button
                  *ngIf="article.public"
                  class="dropdown-item"
                  type="button"
                  (click)="trackUnpublish(); changePublicStatus(article, false)"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-eye-closed"></i>
                  <span>{{
                    'knowledgeBase.articlesList.table.body.articleActionsDropdown.unpublishButton' | transloco
                  }}</span>
                </button>
                <!--Опубликовать статью-->
                <button
                  *ngIf="!article.public"
                  class="dropdown-item"
                  type="button"
                  (click)="trackPublish(); changePublicStatus(article, true)"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-eye"></i>
                  <span>{{
                    'knowledgeBase.articlesList.table.body.articleActionsDropdown.publishButton' | transloco
                  }}</span>
                </button>
                <!-- Индексирование с подключенным модуль расширенной базы знаний -->
                <div *ngIf="accessToKnowledgeBasePro.hasAccess">
                  <!-- На опубликованной статье разрешаем управлять индексацией -->
                  <div *ngIf="article.public">
                    <!-- Статья не индексируется, показываем кнопку включения индексирования и разрешаем включать индексацию -->
                    <button
                      *ngIf="!article.robotsIndex"
                      class="dropdown-item"
                      type="button"
                      (click)="changeIndexed(article, true)"
                    >
                      <i class="dropdown-item-icon-left cqi-sm cqi-lightning"></i>
                      <span>{{
                        'knowledgeBase.articlesList.table.body.articleActionsDropdown.indexed.text' | transloco
                      }}</span>
                    </button>
                    <!-- Статья индексируется, показываем кнопку выключения индексирования и разрешаем включать индексацию -->
                    <button
                      *ngIf="article.robotsIndex"
                      class="dropdown-item"
                      type="button"
                      (click)="changeIndexed(article, false)"
                    >
                      <i class="dropdown-item-icon-left cqi-sm cqi-lightning-slash"></i>
                      <span>{{
                        'knowledgeBase.articlesList.table.body.articleActionsDropdown.noIndexed' | transloco
                      }}</span>
                    </button>
                  </div>
                  <!-- На не опубликованной статье не разрешаем управлять индексацией -->
                  <div *ngIf="!article.public">
                    <!-- Статья не индексируется, показываем кнопку включения индексирования, но не разрешаем включать индексацию -->
                    <a
                      *ngIf="!article.robotsIndex"
                      class="dropdown-item disabled"
                    >
                      <i class="dropdown-item-icon-left cqi-sm cqi-lightning"></i>
                      <span>{{
                        'knowledgeBase.articlesList.table.body.articleActionsDropdown.indexed.text' | transloco
                      }}</span>
                    </a>
                  </div>
                </div>
                <!-- Индексирование без подключенного модуля расширенной базы знаний -->
                <div *ngIf="!accessToKnowledgeBasePro.hasAccess">
                  <!-- Статья не индексируется, показываем кнопку включения индексирования -->
                  <a
                    *ngIf="!article.robotsIndex"
                    class="dropdown-item disabled"
                    type="button"
                  >
                    <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
                    <span>{{
                      'knowledgeBase.articlesList.table.body.articleActionsDropdown.indexed.text' | transloco
                    }}</span>
                  </a>
                  <!-- Статья индексируется, показываем кнопку выключения индексирования -->
                  <a
                    *ngIf="article.robotsIndex"
                    class="dropdown-item"
                    type="button"
                    (click)="paywallService.showPaywall(currentApp, accessToKnowledgeBasePro.denialReason)"
                  >
                    <cq-plan-capability-coin class="dropdown-item-icon-left"></cq-plan-capability-coin>
                    <span>{{
                      'knowledgeBase.articlesList.table.body.articleActionsDropdown.noIndexed' | transloco
                    }}</span>
                  </a>
                </div>
                <!--Удалить-->
                <button
                  class="dropdown-item"
                  type="button"
                  (click)="trackRemoveArticle(article.id); openRemoveArticlesModal([article])"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-trash"></i>
                  <span [attr.data-test]="'cq-articles-list-remove-button-span-' + index">{{
                    'knowledgeBase.articlesList.table.body.articleActionsDropdown.remove' | transloco
                  }}</span>
                </button>
                <!--Переместить в категорию-->
                <button
                  class="dropdown-item"
                  type="button"
                  (click)="trackSetCategory(); openSetCategoryModal([article])"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-move"></i>
                  <span>{{
                    'knowledgeBase.articlesList.table.body.articleActionsDropdown.setCategory' | transloco
                  }}</span>
                </button>
                <!--Создать копию-->
                <button
                  class="dropdown-item"
                  type="button"
                  (click)="trackCopy(); createCopy(article)"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-copy"></i>
                  <span>{{
                    'knowledgeBase.articlesList.table.body.articleActionsDropdown.createCopy' | transloco
                  }}</span>
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!--НЕТ СТАТЕЙ-->
  <div
    *ngIf="!articles.length && !isSearch"
    class="margin-60 text-center"
  >
    <img
      class="margin-bottom-20"
      src="assets/img/default/book.png"
    />
    <h4>{{ 'knowledgeBase.articlesList.zeroData.noArticles.heading' | transloco }}</h4>
    <div class="margin-bottom-20">{{ 'knowledgeBase.articlesList.zeroData.noArticles.description' | transloco }}</div>
    <a
      *ngIf="accessToKnowledgeBasePro.hasAccess || articlesLeftOnStandart > 0"
      class="btn btn-outline-primary"
      uiSref="app.content.knowledgeBase.articleCreate"
      [uiParams]="{ categoryId: currentCategory.id }"
      data-test="cq-articles-list-create-button"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ 'knowledgeBase.articlesList.zeroData.noArticles.createButton' | transloco }}</span>
    </a>
    <button
      *ngIf="!accessToKnowledgeBasePro.hasAccess && articlesLeftOnStandart <= 0"
      class="btn btn-outline-primary"
      (click)="openLimitReachedModal()"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ 'knowledgeBase.articlesList.zeroData.noArticles.createButton' | transloco }}</span>
    </button>
  </div>

  <!--НЕТ СТАТЕЙ ПО ПОИСКУ-->
  <div
    *ngIf="!articles.length && isSearch"
    class="margin-60 text-center"
  >
    <img
      class="margin-bottom-20"
      src="assets/img/default/not-found.png"
    />
    <h4>{{ 'knowledgeBase.articlesList.zeroData.noSearchedArticles.heading' | transloco }}</h4>
    <div>
      <span>{{ 'knowledgeBase.articlesList.zeroData.noSearchedArticles.description' | transloco }}</span>
      <a
        class="inline-block cursor-pointer"
        (click)="onResetSearch.emit()"
        >{{ 'knowledgeBase.articlesList.zeroData.noSearchedArticles.resetSearch' | transloco }}</a
      >
    </div>
  </div>

  <!--НЕТ СТАТЕЙ С ТЕКУЩИМ СТАТУСОМ-->
  <div
    *ngIf="!filteredArticles.length && articles.length"
    class="margin-60 text-center"
  >
    <img
      class="margin-bottom-20"
      src="assets/img/default/book.png"
    />
    <h4>{{ 'knowledgeBase.articlesList.zeroData.noFilteredArticles.heading' | transloco }}</h4>
    <div class="margin-bottom-20">
      {{ 'knowledgeBase.articlesList.zeroData.noFilteredArticles.description' | transloco }}
    </div>
    <button
      class="btn btn-outline-primary"
      (click)="clearFilters(filters)"
    >
      {{ 'knowledgeBase.articlesList.zeroData.noFilteredArticles.clearFiltersButton' | transloco }}
    </button>
  </div>
</ng-container>

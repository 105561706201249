import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';

(function () {
  'use strict';

  angular.module('myApp.knowledgeBase').config(config).run();

  function config($stateProvider) {
    $stateProvider
      .state('app.content.knowledgeBaseArticleEditAjs', {
        url: '/knowledge-base/{categoryId}/{articleId}',
        component: 'cqArticleEdit',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          settings: 'settings',
        },
        resolve: {
          settings: getSettings,
          article: getArticle,
          currentCategory: getCurrentCategory,
          isKnowledgeBaseProActivated: isKnowledgeBaseProActivated,
        },
      })

      .state('app.content.knowledgeBaseArticleEditAjs.copy', {
        url: '/copy',
      })

      .state('app.content.knowledgeBaseArticleCreateAjs', {
        url: '/knowledge-base/{categoryId}/new',
        component: 'cqArticleEdit',
        resolve: {
          settings: getSettings,
          article: getArticle,
          articlesCount: getArticlesCount,
          currentCategory: getCurrentCategory,
          isKnowledgeBaseProActivated: isKnowledgeBaseProActivated,
        },
      });

    /**
     * Получение статьи
     *
     * @param $stateParams
     * @param $state
     * @param articleModel
     * @param currentApp
     *
     * @return {Promise}
     */
    function getArticle($stateParams, $state, articleModel, currentApp) {
      if ($stateParams.articleId) {
        return firstValueFrom(articleModel.get(currentApp.id, $stateParams.articleId)).catch(getArticleError);
      } else {
        return articleModel.getDefault();
      }

      function getArticleError() {
        //$state.go('app.content.knowledgeBaseAjs.articleCreate', angular.extend({}, $stateParams, { articleId: null }));
      }
    }

    /**
     * Получение количетсва статей
     *
     * @param articleModel
     * @param currentApp
     *
     * @returns {Promise}
     */
    function getArticlesCount(articleModel, currentApp) {
      return firstValueFrom(articleModel.getListCount(currentApp.id));
    }

    /**
     * Получение текущей категории
     *
     * @param $stateParams
     * @param $state
     * @param articleCategoryModel
     * @param currentApp
     *
     * @returns {Promise}
     */
    function getCurrentCategory($stateParams, $state, articleCategoryModel, currentApp) {
      return firstValueFrom(articleCategoryModel.get(currentApp.id, $stateParams.categoryId)).catch(
        getCurrentCategoryError,
      );

      function getCurrentCategoryError() {
        $state.go('app.content.knowledgeBase.categories', angular.extend({}, $stateParams, { categoryId: null }));
      }
    }
    /**
     * Получение настроек БЗ
     *
     * @param knowledgeBaseModel
     * @param currentApp
     *
     * @returns {Promise}
     */
    function getSettings(knowledgeBaseModel, currentApp) {
      return firstValueFrom(knowledgeBaseModel.get(currentApp.id)).then((response) => {
        return response === 'KnowledgeBaseAppDoesNotExist' ? {} : response;
      });
    }

    /**
     * Проверка, подключена ли расширенная версия базы знаний
     */
    function isKnowledgeBaseProActivated(billingInfo, currentApp, planVersionService, planFeatureAccessService) {
      return (
        planVersionService.isTrial() ||
        planFeatureAccessService.getAccess(PLAN_FEATURE.KNOWLEDGE_BASE_PRO, currentApp).hasAccess
      );
    }
  }
})();

import { translate } from '@jsverse/transloco';

import { BotAmocrmActionBodyJson } from 'app/http/chat-bot/actions';

import { BotHiddenAction } from './hidden-action/hidden.action';

/**
 * Hidden action подразумевает действия EVENT, USER_TAG, PROPERTY, которые не отображаются клиентам,
 * но имеют идентичную структуру рендера, поэтому их можно объединить.
 */
export class BotAmocrmNotificationAction extends BotHiddenAction<BotAmocrmActionBodyJson> {
  protected get translation(): string {
    return translate('classes.action.canvasText.amocrmNotification');
  }

  get apiReadyBodyJson(): BotAmocrmActionBodyJson {
    return {
      comment: this.comment.value,
      integration: this.integration.value,
      tags: this.tags.value,
    };
  }

  protected get icon(): string {
    return '\ue9a9';
  }

  get comment() {
    return this.form.controls.bodyJson.controls.comment;
  }

  get integration() {
    return this.form.controls.bodyJson.controls.integration;
  }

  /** Получение контрола с тегами сделки AmoCRM */
  get tags() {
    return this.form.controls.bodyJson.controls.tags;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Компонент-обёртка для настроек интеграции в модальном окне выбора интеграции
 */

@Component({
  selector: 'cq-amocrm-wrapper-select-modal',
  templateUrl: './amocrm-wrapper-select-modal.component.html',
  styleUrls: ['./amocrm-wrapper-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmWrapperSelectModalComponent {}

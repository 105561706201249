import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { map } from 'rxjs/operators';

import {
  Operation,
  OperationForm,
} from '@panel/app/partials/filters/components/operation-select/operation-select.types';
import {
  ELASTICSEARCH_OPERATION,
  ELASTICSEARCH_OPERATION_GROUP,
  ELASTICSEARCH_OPERATIONS_BY_GROUP,
} from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { FILTER_DATE_VALUE_UNIT } from '@panel/app/services/filter/filter.constants';
import { FilterService } from '@panel/app/services/filter/filter.service';
import { FilterOperation, FilterOperationDefaultValue } from '@panel/app/services/filter/types/filter.internal-types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

@Component({
  selector: 'cq-operation-select',
  templateUrl: './operation-select.component.html',
  styleUrls: ['./operation-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationSelectComponent extends AbsCVAFormGroupBasedComponent<OperationForm> {
  readonly control: FormGroup<OperationForm> = this.fb.group<OperationForm>({
    type: this.fb.control(ELASTICSEARCH_OPERATION.KNOWN, { nonNullable: true }),
    numberRangeValue: this.fb.control(
      {
        value1: 0,
        value2: 0,
      },
      { nonNullable: true },
    ),
    dateValue: this.fb.control(
      {
        value: 1,
        units: FILTER_DATE_VALUE_UNIT.MINUTES,
      },
      { nonNullable: true },
    ),
    defaultValue: this.fb.control<FilterOperationDefaultValue>(
      {
        value: '',
      },
      { nonNullable: true },
    ),
  });

  get operations(): Operation[] {
    let operations: Operation[] = [];

    for (let group of Object.values(ELASTICSEARCH_OPERATION_GROUP)) {
      ELASTICSEARCH_OPERATIONS_BY_GROUP[group].forEach((operation: ELASTICSEARCH_OPERATION) => {
        operations.push({
          operation: operation,
          group: this.translocoService.translate(`elasticsearchOperationModel.group.${group}`),
        });
      });
    }

    return operations;
  }

  constructor(private readonly fb: FormBuilder, private readonly translocoService: TranslocoService) {
    super();
  }

  writeValue(value: FilterOperation): void {
    this.control.controls.type.setValue(value.type);

    switch (value.type) {
      case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN:
      case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN:
      case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN:
      case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN:
        this.control.controls.dateValue.setValue(value.value);
        break;
      case ELASTICSEARCH_OPERATION.NUMBER_RANGE:
        this.control.controls.numberRangeValue.setValue(value.value);
        break;
      default:
        this.control.controls.defaultValue.setValue(value.value);
    }
  }

  syncInternalChanges() {
    this.control.valueChanges
      .pipe(
        map(() => this.control.getRawValue()),
        map((value): FilterOperation => {
          switch (value.type) {
            case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN:
            case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN:
            case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN:
            case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN:
              return {
                type: value.type,
                value: value.dateValue,
              };
            case ELASTICSEARCH_OPERATION.NUMBER_RANGE:
              return {
                type: value.type,
                value: value.numberRangeValue,
              };
            case ELASTICSEARCH_OPERATION.KNOWN:
            case ELASTICSEARCH_OPERATION.UNKNOWN:
              return {
                type: value.type,
                value: {
                  value: '',
                },
              };
            default:
              return {
                type: value.type,
                value: value.defaultValue,
              };
          }
        }),
      )
      .subscribe((value: FilterOperation) => this.onChange(value));
  }

  protected groupByFn(operation: Operation): string {
    return operation.group;
  }

  protected isNumberRangeValue(type: ELASTICSEARCH_OPERATION): boolean {
    return FilterService.isNumberRangeValue(type);
  }

  protected isNumberValue(type: ELASTICSEARCH_OPERATION): boolean {
    return FilterService.isNumberValue(type);
  }

  protected isDateValue(type: ELASTICSEARCH_OPERATION): boolean {
    return FilterService.isDateValue(type);
  }

  protected isListValue(type: ELASTICSEARCH_OPERATION): boolean {
    return FilterService.isListValue(type);
  }

  protected isStrContains(type: ELASTICSEARCH_OPERATION): boolean {
    return FilterService.isStrContains(type);
  }

  protected isStrValue(type: ELASTICSEARCH_OPERATION): boolean {
    return FilterService.isStrValue(type);
  }

  protected trackByFn(operation: Operation): string {
    return operation.operation;
  }
}

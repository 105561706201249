/**
 * Компонент редактора сообщения для триггерных цепочек
 */
(function () {
  'use strict';

  require('./trigger-chain-sending-conditions-editor.controller');

  angular
    .module('myApp.triggerChains.sendingConditionsEditor')
    .component('cqTriggerChainSendingConditionsEditor', cqTriggerChainSendingConditionsEditor());

  function cqTriggerChainSendingConditionsEditor() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // sendingConditions
      },
      controller: 'CqTriggerChainSendingConditionsEditorController',
      controllerAs: 'vm',
      template: require('./trigger-chain-sending-conditions-editor.html'),
    };
  }
})();

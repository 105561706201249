(function () {
  'use strict';

  require('./funnel-editor.controller');

  angular.module('myApp.funnels').component('cqFunnelEditor', cqFunnelEditor());

  function cqFunnelEditor() {
    return {
      bindings: {
        currentApp: '=', // Данные текущего приложения
        currentFunnelName: '=', // Имя воронки в которой производится действие (используется для трека при копировании воронки)
        funnel: '=', // Воронка
        isCreateMain: '=', // Флаг создания основной воронки
        onSubmit: '=', // Функция создания/сохранения воронки
        modalAction: '=', // Диействие производимое с воронкой (копирование / редактирование / создание новой)
        properties: '=', // Свойства пользователей, типы событий, свойства типов событий
      },
      controller: 'CqFunnelEditorController',
      controllerAs: 'vm',
      template: require('./funnel-editor.html'),
    };
  }
})();

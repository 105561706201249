<ng-container *transloco="let t; read: 'deleteDjangoUserComponent'">
  <h3 class="mb-15">{{ t('heading') }}</h3>
  <button
    class="btn btn-danger"
    type="button"
    (click)="openDeleteDjangoUserModal()"
    [disabled]="isCheckDeleteRequestPerformed"
    [ngbTooltip]="tooltipTemplate"
    placement="right"
    [autoClose]="'inside'"
    triggers="manual"
    #tooltip="ngbTooltip"
    data-test="open-modal-button"
  >
    <i class="cqi-sm cqi-fire-o"></i>
    <span>{{ t('button') }}</span>
  </button>

  <!-- пришлось сделать тултип через ng-template, иначе forbiddenAppNames не подставлялись в перевод после изменения в subscription -->
  <ng-template #tooltipTemplate>{{
    t('errors.forbiddenApps', { appCount: forbiddenApps.length, appNames: forbiddenAppNames })
  }}</ng-template>
</ng-container>

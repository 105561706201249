import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { PERMISSION_DENIED_PAGE_ROUTES } from '@panel/app/pages/permission-denied/permission-denied.routes';

import { PermissionDeniedComponent } from './permission-denied.component';

@NgModule({
  declarations: [PermissionDeniedComponent],
  exports: [PermissionDeniedComponent],
  imports: [TranslocoModule, UIRouterUpgradeModule.forChild({ states: PERMISSION_DENIED_PAGE_ROUTES })],
})
export class PermissionDeniedModule {}

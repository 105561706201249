import { Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { ConversationPartAiContentModalData } from '@panel/app/partials/modals/conversation-part-ai-content-modal/conversation-part-ai-content-modal.token';

@Injectable()
export class ConversationPartAiContentModalStore {
  readonly activeModal$: BehaviorSubject<NgbActiveModal | null> = new BehaviorSubject<NgbActiveModal | null>(null);
  readonly modalData$: BehaviorSubject<ConversationPartAiContentModalData | null> =
    new BehaviorSubject<ConversationPartAiContentModalData | null>(null);
}

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AmocrmIntegrationFormData } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { EventType } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';

/**
 * Компонент для работы с событиями, при совершении которых создаётся сделка в AmoCRM
 */

@Component({
  selector: 'cq-amocrm-event-triggers-for-lead[controlLeadsEventTypeIds][currentApp]',
  templateUrl: './amocrm-event-triggers-for-lead.component.html',
  styleUrls: ['./amocrm-event-triggers-for-lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmEventTriggersForLeadComponent implements OnInit {
  /** Контрол с ID событий, после срабатывания которых создаётся сделка в amoCRM */
  @Input()
  controlLeadsEventTypeIds!: GenericFormControl<AmocrmIntegrationFormData['settings']['leadsEventTypeIds']>;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Инстанс коллапса */
  @ViewChild(NgbCollapse)
  eventTypesCollapse!: NgbCollapse;

  /** Флаг свёрнутости списка событий, после которых передаются данные в amoCRM */
  isCollapsed: boolean = true;

  /** Получение количества выбранных событий */
  get eventsCount(): number {
    return this.controlLeadsEventTypeIds.value.length;
  }

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    public readonly amocrmState: AmocrmStateService,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
  ) {}

  ngOnInit() {
    // Следим за изменениями статусов валидности и touched-статуса, чтобы раскрыть коллапс этого компонента,
    // если один из контролов невалидный
    combineLatest(
      extractTouchedChanges(this.controlLeadsEventTypeIds),
      this.controlLeadsEventTypeIds.statusChanges.pipe(
        startWith(this.controlLeadsEventTypeIds.status),
        map((status) => status === 'INVALID'),
      ),
    )
      .pipe(map((statuses) => [statuses[1]].some((value) => value)))
      .subscribe((invalid) => {
        if (invalid && this.eventTypesCollapse.collapsed) {
          this.eventTypesCollapse.toggle(true);
        }
      });
  }

  /**
   * Обработчик добавления нового события
   *
   * @param autoEvents - Новые автособытия
   */
  onAutoEventsChange(autoEvents: AutoEvent[]): void {
    this.amocrmState.autoEvents$.next([...autoEvents]);
  }

  /**
   * Обработчик добавления нового события
   *
   * @param eventTypes - Новые свойства и события
   */
  onEventTypesChange(eventTypes: EventType[]): void {
    this.amocrmState.eventTypes$.next([...eventTypes]);
  }
}

(function () {
  'use strict';

  angular
    .module('myApp')
    .config(configAccountingJs)
    .config(configAngularToastr)
    .config(configAngularTranslate)
    .config(configAnimate)
    .config(configCfpLoadingBar)
    .config(configCompileProvider)
    .config(configHttpBackend)
    .config(configMoment) // !!! конфигурация moment.js идёт до конфигурации configDateRangePicker из-за того, что daterangepicker использует moment, и к этому моменту он должен быть сконфигурирован
    .config(configDateRangePicker)
    .config(configOverlayScrollbars)
    // NOTE: Этот конфиг влияет вот на это https://www.codelord.net/2017/08/20/angular-1-dot-6-s-possibly-unhandled-rejection-errors/
    //  Поэтому я его отключил, пусть лучше ошибки валятся в консоль и будет понятно что где-то что-то свалилось, нежели ошибок не будет вообще, даже нужных, которые будут говорить ореальных багах
    //.config(configQ)
    .config(configQuill)
    .config(configUiBootstrapProgressbar)
    .config(configUiSelect)
    .config(configUiTooltipAndPopover)
    .run(runDesktopApp)
    .run(run1)
    .run(run2)
    .run(runAngularTranslate)
    .run(runChartJsCqDoughnut)
    .run(runChartJsDoughnutText)
    .run(runChartJsGlobalConfig)
    .run(runChartJsHeatMap)
    .run(runChartJsZeroData)
    .run(runUiBootstrapAccordion)
    .run(runUiBootstrapAccordionGroup)
    .run(runUiBootstrapProgressBar)
    .run(runUiBootstrapNavs)
    .run(runUiSelect);

  /**
   * Конфигурация accounting.js
   *
   * @param CURRENCY
   * @param accounting
   */
  function configAccountingJs(CURRENCY, accounting) {
    accounting.settings.currency.decimal = CURRENCY.DECIMAL;
    accounting.settings.currency.format = CURRENCY.FORMAT;
    accounting.settings.currency.precision = CURRENCY.PRECISION;
    accounting.settings.currency.symbol = CURRENCY.SYMBOL;
    accounting.settings.currency.thousand = CURRENCY.THOUSAND;
  }

  /**
   * Конфигурация angular toastr
   *
   * @param toastrConfig
   */
  function configAngularToastr(toastrConfig) {
    // !!! ВНИМАНИЕ! Чтобы сделать тосты анимированными с учётом classNameFilter - пришлось редактировать саму библиотеку (да, это грустно, но что поделать)! Ищи <div class="animate" toast>
    angular.extend(toastrConfig, {
      closeHtml: '<i class="cqi-sm cqi-times"></i>',
      extendedTimeOut: 1000,
      iconClasses: {
        error: 'alert-danger',
        info: 'alert-info',
        success: 'alert-success',
        warning: 'alert-warning',
      },
      newestOnTop: false,
      positionClass: 'toast-top-center',
      timeOut: 3000,
      toastClass: 'alert animate',
    });
  }

  /**
   * Конфигурация angular translate
   *
   * @param ipCookieProvider
   * @param $translateProvider
   * @param $translateSanitizationProvider
   * @param LANGUAGE
   */
  function configAngularTranslate(ipCookieProvider, $translateProvider, $translateSanitizationProvider, LANGUAGE) {
    $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
    $translateProvider.useLoader('$translatePartialLoader', {
      urlTemplate: '{part}/i18n/{lang}.json',
    });
    $translateProvider.fallbackLanguage('ru');
    $translateProvider.preferredLanguage(LANGUAGE);
    angular.element('html').attr('lang', LANGUAGE);
    $translateSanitizationProvider.useStrategy('escapeParameters');
  }

  /**
   * Конфигурация сервиса $animate из ngAnimate
   *
   * @param $animateProvider
   */
  function configAnimate($animateProvider) {
    // todo animate: добавлены классы, при добавлении которых к элементу на нём будет отрабатывать ng-animate, но не очень понятно как эта настройка коррелирует с использованием, например, $animate.addClass и $animateCss
    // note: раньше что-то не так было с ui-select, если отключить у него анимацию. Сейчас вроде как всё норм, но лучше старый кусок кода не удалять
    //$animateProvider.classNameFilter(/animate|ui-select-/);
    $animateProvider.classNameFilter(/animate/);
  }

  /**
   * Конфигурация компилятора
   *
   * @param $compileProvider
   * @param DEBUG_MODE_ENABLED
   */
  function configCompileProvider($compileProvider, DEBUG_MODE_ENABLED) {
    $compileProvider.debugInfoEnabled(DEBUG_MODE_ENABLED);
  }

  /**
   * Конфигурация полосы загрузки из angular-loading-bar
   *
   * @param cfpLoadingBarProvider
   */
  function configCfpLoadingBar(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.latencyThreshold = 0;
    cfpLoadingBarProvider.includeSpinner = false;
  }

  /**
   * Конфигурация date range picker из daterangepicker
   *
   * @param dateRangePickerConfig
   * @param moment
   */
  function configDateRangePicker(dateRangePickerConfig, moment) {
    angular.extend(dateRangePickerConfig, {
      locale: {
        format: moment.localeData().longDateFormat('L'),
        separator: ' - ',
      },
      alwaysShowCalendars: true,
      linkedCalendars: false,
      applyClass: 'btn-primary',
      cancelClass: 'btn-outline-primary',
      parentEl: '#content',
    });
  }

  /**
   * Конфигурация $httpBackend
   *
   * @param $provide
   */
  function configHttpBackend($provide) {
    $provide.decorator('$httpBackend', httpBackendDecorator);

    function httpBackendDecorator($delegate) {
      return function (
        method,
        url,
        post,
        callback,
        headers,
        timeout,
        withCredentials,
        responseType,
        eventHandlers,
        uploadEventHandlers,
      ) {
        // HACK ангуляр по умолчанию не кодирует точку с запятой, чтобы соответствовать старой спецификации HTML и ещё чему-то там. Поэтому приходится делать это ручками
        //  Почему? Потому что в некотоых в GET-запросах (на тех же пользователей в сегментации) должна быть возможность посылать точку с запятой, которую пользователь ввёл в инпут
        //  Обоснование тут https://github.com/angular/angular.js/issues/9224#issuecomment-73889281
        //  Описание метода исправления тут https://stackoverflow.com/a/26865036
        //  Возможно, это не самый правильный способ с точки зрения реализации, и стоит модифицировать $httpParamSerializer, но я решил довериться решению со stackoverflow
        url = url.replace(/;/g, '%3B');
        $delegate(
          method,
          url,
          post,
          callback,
          headers,
          timeout,
          withCredentials,
          responseType,
          eventHandlers,
          uploadEventHandlers,
        );
      };
    }
  }

  /**
   * Конфигурация moment.js
   *
   * @param moment
   * @param LANGUAGE
   */
  function configMoment(moment, LANGUAGE) {
    // нужно всегда отображать секунды, поэтому longDateFormat у каждой локали редактируется так, чтобы у него были секунды
    moment.updateLocale('en', {
      longDateFormat: {
        LLL: 'MMMM D, YYYY h:mm:ss A',
        LLLL: 'dddd, MMMM D, YYYY h:mm:ss A',
      },
    });

    moment.updateLocale('ru', {
      longDateFormat: {
        LLL: 'D MMMM YYYY г., H:mm:ss',
        LLLL: 'dddd, D MMMM YYYY г., H:mm:ss',
      },
    });

    moment.locale(LANGUAGE);
  }

  /**
   * Конфигурация OverlayScrollbars
   */
  function configOverlayScrollbars(OverlayScrollbars) {
    OverlayScrollbars.env().setDefaultOptions({
      scrollbars: {
        theme: 'cq-custom-scroll-default',
      },
      showNativeOverlaidScrollbars: true,
    });
  }

  /**
   * Конфиг $q в связи с ошибками о необработанных промисах Possibly unhandled rejection
   * Подробнее - тут https://www.codelord.net/2017/08/20/angular-1-dot-6-s-possibly-unhandled-rejection-errors/
   *
   * @param $qProvider
   */
  function configQ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
  }

  /**
   * Конфигурация редактора Quill
   *
   * @param ngQuillConfigProvider
   */
  function configQuill(ngQuillConfigProvider) {
    ngQuillConfigProvider.set({
      formats: [],
      modules: {
        // NOTE: в документации указано, что чтобы отключить toolbar нужно в модулях написать toolbar: false, но это, почему-то, не работает, а передача пустого объекта - работает
        //toolbar: false
        history: {
          userOnly: true, // пользователь может отменять (Ctrl+Z и метод undo/Ctrl+Shift+Z и метод redo) только свои действия, но не те, которые вносятся из контроллера путём изменения модели
        },
      },
      placeholder: '',
      theme: 'default',
    });
  }

  /**
   * Конфигурация pagination из ui-bootstrap
   *
   * @param $provide
   */
  function configUiBootstrapProgressbar($provide) {
    $provide.decorator('uibProgressbarDirective', uibProgressbarDecorator);

    function uibProgressbarDecorator($delegate, $controller) {
      var directive = $delegate[0];
      var controllerName = directive.controller;
      var scope = {
        active: '=?',
        backgroundColor: '@',
        leftTitle: '@',
        rightTitle: '@',
        striped: '=?',
        tooltip: '@',
      };
      var transclude = {
        leftSlot: '?uibProgressbarLeft', // HTML-надпись с левого края
        rightSlot: '?uibProgressbarRight', // HTML-надпись с правого края
      };

      directive.transclude = transclude;
      angular.extend(directive.scope, scope);
      directive.compile = compileDecorator;
      directive.controller = controllerDecorator;

      return $delegate;

      function compileDecorator() {
        return function (scope, iElement, iAttrs, controller) {
          controller.addBar(scope, iElement.find('.progress-bar'), { title: iAttrs.title });
        };
      }

      /* @ngInject */
      function controllerDecorator($scope, $attrs, $transclude, uibProgressConfig) {
        var controller = $controller(controllerName, {
          $scope: $scope,
          $attrs: $attrs,
          uibProgressConfig: uibProgressConfig,
        });

        $scope.hasLeftTitle = angular.isDefined($attrs.leftTitle);
        $scope.hasRightTitle = angular.isDefined($attrs.rightTitle);
        $scope.hasLeftSlot = $transclude.isSlotFilled('leftSlot');
        $scope.hasRightSlot = $transclude.isSlotFilled('rightSlot');

        return controller;
      }
    }
  }

  /**
   * Конфигурация мультиселекта из ui.select
   *
   * @param $provide
   * @param uiSelectConfig
   */
  function configUiSelect($provide, uiSelectConfig) {
    uiSelectConfig.theme = 'cqSelect';
    $provide.decorator('uiSelectDirective', uiSelectDecorator);

    function uiSelectDecorator($delegate, $controller, $parse) {
      var directive = $delegate[0];
      var compile = directive.compile;
      var controllerName = directive.controller;

      directive.compile = compileDecorator;
      directive.controller = controllerDecorator;

      return $delegate;

      function compileDecorator(tElement, tAttrs) {
        var link = compile.apply(this, arguments);

        return function (scope, iElement, iAttrs, controller, transcludeFn) {
          link.apply(this, arguments);

          var $select = controller[0];

          iAttrs.$observe('action', function (action) {
            $select.actionCallback = action;
          });

          iAttrs.$observe('actionText', function (actionText) {
            $select.actionText = actionText;
          });
        };
      }

      /* @ngInject */
      function controllerDecorator(
        $scope,
        $element,
        $timeout,
        $filter,
        $$uisDebounce,
        uisRepeatParser,
        uiSelectMinErr,
        uiSelectConfig,
        $parse,
        $injector,
        $window,
      ) {
        var controller = $controller(controllerName, {
          $scope: $scope,
          $element: $element,
          $timeout: $timeout,
          $filter: $filter,
          $$uisDebounce: $$uisDebounce,
          uisRepeatParser: uisRepeatParser,
          uiSelectMinErr: uiSelectMinErr,
          uiSelectConfig: uiSelectConfig,
          $parse: $parse,
          $injector: $injector,
          $window: $window,
        });

        controller.action = function () {
          $scope.$eval(controller.actionCallback);
          controller.close();
        };

        return controller;
      }
    }
  }

  /**
   * Конфигурация tooltip и popover в ui.bootstrap
   * @param $uibTooltipProvider
   *
   * HACK Для полноценной обработки на элемент надо добавить атрибут cq-tooltip-trigger или cq-popover-trigger
   */
  function configUiTooltipAndPopover($uibTooltipProvider) {
    $uibTooltipProvider.setTriggers({
      cqClick: 'cqAnyClick',
    });
  }

  /**
   * Блок, который будет выполняться только внутри Desktop-приложения
   *
   * @param carrotquestHelper
   * @param electronApi
   */
  function runDesktopApp(carrotquestHelper, electronApi) {
    if (!electronApi.desktopApp) {
      return;
    }

    // для нового Desktop-приложения нужно посылать событие, в старом приложении оно посылается из электрона
    if (electronApi.newDesktopApp) {
      carrotquestHelper.track('Зашел через Desktop-приложение', {
        OS: electronApi.os,
      });
    }
  }

  function run1($state, electronApi) {
    // вешаем слушатель клика по нотификациям из Desktop-приложения
    electronApi.onConversationNotificationClick((event, conversationId) => {
      // если пользователь не в диалогах, редиректим в них, чтобы сделать активацию на закрытие диалога
      if ($state.current.name !== 'app.content.conversations.general') {
        $state.go('app.content.conversations.general', { conversationId: conversationId });
      }
    });
  }

  function run2(
    $filter,
    $rootScope,
    $translate,
    $state,
    $uibModal,
    $timeout,
    $transitions,
    $window,
    LongPollConnection,
    $interval,
    moment,
    carrotquestHelper,
    desktopNotification,
    systemError,
    authModel,
    utilsService,
  ) {
    let globalTimerTickInterval; // Интервал с проверкой RTS-соединения
    $rootScope.isActive = function (globs) {
      for (var i = 0; i < globs.length; i++) {
        var glob = globs[i];

        if ($state.includes(glob)) {
          return true;
        }
      }

      return false;
    };

    $rootScope.longPollSetup = function (transition) {
      const channelModel = transition.injector().get('channelModel');
      $window.addEventListener('online', onOnline);
      $window.addEventListener('offline', onOffline);
      // Добавляем слушатель на смену видимости вкладки с админкой
      // Это призвано убрать баг с тостом о проблемах с соединением, когда вкладка долго неактивна
      document.addEventListener('visibilitychange', onVisibilityChange);

      var events = [
        // @formatter:off
        'activation.' + $rootScope.currentApp.id,
        'admin_presence_changed.' + $rootScope.currentApp.id,
        'billing_updated.' + $rootScope.currentApp.id,
        'channel_not_assigned_changed.' + $rootScope.currentApp.id,
        'channel_not_read_changed.' + $rootScope.currentApp.id,
        'conversation_started_user.' + $rootScope.currentApp.id,
        'conversation_assigned.' + $rootScope.currentApp.id,
        'conversation_channel_changed.' + $rootScope.currentApp.id,
        'conversation_closed.' + $rootScope.currentApp.id,
        'conversation_delay_finished.' + $rootScope.currentApp.id,
        'conversation_delayed.' + $rootScope.currentApp.id,
        'conversation_parts_batch.' + $rootScope.currentApp.id,
        'conversation_replied_by_user_read.' + $rootScope.currentApp.id,
        'conversation_reply.' + $rootScope.currentApp.id,
        'conversation_reply_changed.' + $rootScope.currentApp.id, // Изменение парта (используется в vote партах)
        'conversation_tag_added.' + $rootScope.currentApp.id,
        'conversation_tag_deleted.' + $rootScope.currentApp.id,
        'event_types_activated.' + $rootScope.currentApp.id, // Изменение статуса активности события
        'event_types_created.' + $rootScope.currentApp.id, // Создание события
        'message_template_updated.' + $rootScope.currentApp.id, // Превью шаблона загружено
        'ping',
        'system_log_added.' + $rootScope.currentApp.id,
        'user_merge_failed.' + $rootScope.currentApp.id,
        'user_merge_finished.' + $rootScope.currentApp.id,
        'user_presence_changed.' + $rootScope.currentApp.id,
        'users_removed.' + $rootScope.currentApp.id,
        // @formatter:on
      ];

      $rootScope.longPollCancel = LongPollConnection(events, function (channel, data) {
        globalTimerTick();

        systemError.changeRTSStatus(false);

        systemError.RTSConnectionProblemToast.hide();

        let mutedChannels = channelModel.getMutedChannelsFromLocalStorage(); // Список замьюченных каналов

        // Если нет канала, проверяем замьючен ли канал без каналов
        let isChannelMuted = data.channel ? mutedChannels.includes(data.channel.id) : mutedChannels.includes('0');

        // FIXME Нужно переделать, когда появится метод в appModal по записи ключа активации
        if (channel.indexOf('activation.') === 0) {
          let callApply = false;

          switch (data.flag) {
            case 'reply_user':
              $rootScope.currentApp.activation.reply_user = moment();
              callApply = true;
              break;
            case 'social_network_integrations_reply_user':
              $rootScope.currentApp.activation.social_network_integrations_reply_user = moment();
              callApply = true;
              break;
          }

          callApply && $rootScope.$applyAsync();
        }

        // Desktop notification & sound
        if (channel.indexOf('conversation_started_user.') == 0 && data.message == null) {
          var channelReadPermission;
          let callApply = false;
          if (data.channel) {
            channelReadPermission = channelModel.hasPermissions(data.channel.id);
          }

          // нужно показывать уведомление только тогда,
          // когда диалог начался в канале, на который есть доступ у оператора и канал не замьючен
          // либо когда он начался во "Все каналы"
          if ((!data.channel || channelReadPermission) && !isChannelMuted) {
            let message = '';

            if (data.part_last.body) {
              message = data.part_last.body.replace(/<(?:.|\n)*?>/gm, ' ');
            } else if (data.part_last.attachments && data.part_last.attachments[0]) {
              // Если отправляется только файл, без текста, то подставляем в нотификацию название файла
              message = data.part_last.attachments[0]?.filename;
            }

            desktopNotification.showForConversation(data.assignee, message, data.id);
            callApply = true;
          }

          callApply && $rootScope.$applyAsync();
        }

        if (channel.indexOf('conversation_reply.') == 0) {
          //Не показывать сплывайку если реплика первая, так как есть отдельное сообщение в ртс для начала диалога. Так исторически сложилось
          if (data.first) {
            return;
          }

          let callApply = false;

          // Уведомлять только если активность была ЧУЖОЙ (не от меня)
          if ($rootScope.djangoUser.id && (!data.from || data.from.id != $rootScope.djangoUser.id)) {
            var channelReadPermission;
            if (data.channel) {
              channelReadPermission = channelModel.hasPermissions(data.channel.id);
            }

            // нужно показывать уведомление только тогда,
            // когда диалог начался в канале, на который есть доступ у оператора и канал не замьючен
            // либо когда он начался во "Все каналы"
            // И флаг silent_reply не выставлен в true
            if ((!data.channel || channelReadPermission) && !isChannelMuted && !data.meta_data.silent_reply) {
              var msg = '';
              var body = '';
              if (typeof data.body == 'string') {
                body = data.body.replace(/<(?:.|\n)*?>/gm, ' ');
              }

              if (data.type == 'reply_user' || data.type == 'reply_admin') {
                if (body) {
                  msg = body;
                } else if (data.attachments && data.attachments[0]) {
                  // Если отправляется только файл, без текста, то подставляем в нотификацию название файла
                  msg = data.attachments[0]?.filename;
                }
              } else if (data.type == 'note') {
                msg = '[ ' + body + ' ]';
              } else if (data.type == 'service') {
                msg = '--- ' + data.from.name + ' ' + body + ' ---';
              } else if (data.type == 'service') {
                msg = '--- ' + body + ' ---';
              } else if (data.type == 'tag_added') {
                msg =
                  '--- ' +
                  $translate.instant('services.longPollConnection.tagAdded', {
                    userName: data.from.name,
                    tagName: data.tag,
                  }) +
                  ' ---';
              } else if (data.type == 'tag_deleted') {
                msg =
                  '--- ' +
                  $translate.instant('services.longPollConnection.tagDeleted', {
                    userName: data.from.name,
                    tagName: data.tag,
                  }) +
                  ' ---';
              } else if (data.type == 'assigned') {
                if (data.assignee && data.assignee.id == $rootScope.djangoUser.id) {
                  msg =
                    '--- ' +
                    $translate.instant('services.longPollConnection.assigned.me', { userName: data.from.name }) +
                    ' ---';
                }
              } else if (data.type == 'opened') {
                msg = '--- ' + $translate.instant('services.longPollConnection.opened') + ' ---';
              }

              if (msg != '') {
                desktopNotification.showForConversation(data.assignee, msg, data.conversation);
                callApply = true;
              }
            }
          }

          callApply && $rootScope.$applyAsync();
        } else if (channel.indexOf('channel_not_assigned_changed') == 0) {
          channelModel.setNotAssignCounter(data);
          $rootScope.$applyAsync();
        } else if (channel.indexOf('channel_not_read_changed') == 0) {
          channelModel.setNotReadCounter(data);
          $rootScope.$applyAsync();
        } else if (channel.indexOf('conversation_channel_changed.') == 0) {
          let callApply = false;
          var channelReadPermission;
          if (data.channel) {
            channelReadPermission = channelModel.hasPermissions(data.channel.id);
          }

          // нужно показывать уведомление только тогда,
          // когда диалог начался в канале, на который есть доступ у оператора и канал не замьючен
          // либо когда он начался во "Все каналы"
          if ((!data.channel || channelReadPermission) && !isChannelMuted) {
            if (data.channel) {
              msg =
                '--- ' +
                $translate.instant('services.longPollConnection.channelChanged', {
                  channelName: data.channel.name,
                }) +
                ' ---';
            } else {
              msg = '--- ' + $translate.instant('services.longPollConnection.removedFromChannel') + ' ---';
            }
            desktopNotification.showForConversation(data.assignee, msg, data.conversation);
            callApply = true;
          }

          callApply && $rootScope.$applyAsync();
        } else if (channel.indexOf('conversation_delay_finished') == 0) {
          let callApply = false;

          for (var i = 0; i < data.conversations.length; i++) {
            var conversation = data.conversations[i];
            var channelReadPermission;
            if (conversation.channel) {
              channelReadPermission = channelModel.hasPermissions(conversation.channel);
            }

            // нужно показывать уведомление только тогда,
            // когда диалог начался в канале, на который есть доступ у оператора и канал не замьючен
            // либо когда он начался во "Все каналы"
            if ((!conversation.channel || channelReadPermission) && !isChannelMuted) {
              var msg = '--- ' + $translate.instant('services.longPollConnection.delayFinished') + ' ---';
              desktopNotification.showForConversation(conversation.assignee, msg, conversation.message);
              callApply = true;
            }
          }

          callApply && $rootScope.$applyAsync();
        }
        $rootScope.$broadcast('message', {
          channel: channel,
          data: data,
        });
      });
    };

    /**
     * Колбэк на потерю интернет-соединения
     */
    function onOffline() {
      systemError.changeOfflineStatus(false);
    }

    /**
     * Колбэк на восстановление интернет-соединения
     */
    function onOnline() {
      systemError.changeOfflineStatus(true);
      $rootScope.longPollCancel.reset();
    }

    /**
     * Колбэк на смену активности вкладки
     */
    function onVisibilityChange() {
      if (document.hidden) {
        $interval.cancel(globalTimerTickInterval);
      } else {
        globalTimerTick();
        globalTimerTickInterval = $interval(globalTimerTick, 20000);
      }
    }

    $rootScope.longPollDestroy = function () {
      if ($rootScope.longPollCancel) {
        $window.removeEventListener('online', onOnline);
        $window.removeEventListener('offline', onOffline);
        document.removeEventListener('visibilitychange', onVisibilityChange);

        $rootScope.longPollCancel.destroy.resolve();
        $rootScope.longPollCancel = null;
      }
    };

    $rootScope.updateState = function () {
      if (!$rootScope.cqIdentified && $rootScope.djangoUser && $rootScope.apps) {
        $rootScope.cqIdentified = true;

        var apps = [];
        var appsUrl = [];
        for (var i = 0; i < $rootScope.apps.length; i++) {
          apps.push($rootScope.apps[i].name);
          appsUrl.push($rootScope.apps[i].origin);
        }

        if (utilsService.webApp) {
          authModel.getHash().then(getHashSuccess);
        }
      }

      function getHashSuccess(response) {
        carrotquestHelper.auth($rootScope.djangoUser.id, response.data.hash);
        carrotquestHelper.identify({
          apps: apps,
          appsUrl: appsUrl,
        });

        carrotquestHelper.identify({ $name: $rootScope.djangoUser.name });
      }
    };

    const RTS_IDLE_TIMEOUT = 2 * 60 * 1000; // Таймаут с последнего сообщения из РТС
    const RTS_HARD_IDLE_TIMEOUT = 5 * 60 * 1000; // Хардовый таймаут с последнего сообщения из РТС, когда считаем, что всё плохо

    /**
     * Проверка как давно приходило последнее сообщение по RTS
     * Если это время больше RTS_HARD_IDLE_TIMEOUT, то показываем тост о проблемах с RTS
     */
    function globalTimerTick() {
      const currentTimestamp = Date.now();

      if ($rootScope.currentApp && $rootScope.currentApp.id && $rootScope.longPollCancel) {
        if (
          currentTimestamp - $rootScope.longPollCancel.lastConnectionTime() > 2 * 60 * 1000 &&
          currentTimestamp - $rootScope.longPollCancel.lastMessageTime() > RTS_IDLE_TIMEOUT
        ) {
          $rootScope.longPollCancel.reset();
        }

        if (
          !systemError.RTSConnectionProblemToast.isOpen() &&
          currentTimestamp - $rootScope.longPollCancel.lastMessageTime() > RTS_HARD_IDLE_TIMEOUT
        ) {
          const showTimeoutValue = Math.floor(50 + Math.random() * 51); // Рандомное целое число от 50 до 100. Небольшой костыль для того, чтобы не показывалось несколько тостов одновременно
          if ($state.includes('app.content.conversations')) {
            $timeout(() => {
              systemError.RTSConnectionProblemToast.show();
            }, showTimeoutValue);
          }
          systemError.changeRTSStatus(true);
        }
      }
    }

    globalTimerTickInterval = $interval(globalTimerTick, 20000);
  }

  /**
   * Настройка angular-translate
   */
  function runAngularTranslate($transitions, $translate, $translatePartialLoader) {
    // используется именно onEnter, т.к. он отрабатывает для всей цепочки состояний, от родителей до текущего состояния
    $transitions.onEnter({}, loadTranslationsForComponents);
    $transitions.onBefore({}, loadTranslationsOnBefore);

    /**
     * Загрузка переводов для компонентов
     *
     * @param transition
     * @param state
     */
    function loadTranslationsForComponents(transition, state) {
      // HACK переводы сейчас грузятся из одного файла, который формируется вебпаком
      //  В будущем надо вообще переделать всю схему, чтобы переводы были привязаны к компонентам, сами разбивались по модулям и т.п.
      //  Подробнее можно почитать тут https://habr.com/ru/company/ispsystem/blog/512008/
      $translatePartialLoader.addPart('assets');
      return $translate.refresh();
    }

    /**
     * Загрузка переводов для моделей/сервисов/модалок и всяких штук, которые должны быть загружены до перехода на состояние
     *
     * @param transition
     */
    function loadTranslationsOnBefore(transition) {
      // HACK переводы сейчас грузятся из одного файла, который формируется вебпаком
      //  В будущем надо вообще переделать всю схему, чтобы переводы были привязаны к компонентам, сами разбивались по модулям и т.п.
      //  Подробнее можно почитать тут https://habr.com/ru/company/ispsystem/blog/512008/
      $translatePartialLoader.addPart('assets');
      return $translate.refresh();
    }

    // NOTE: не удалять этот комментарий, чтобы можно было легко посмотреть порядок вызова callback'ов
    /*$transitions.onBefore({}, function(transition) {
      logHook(transition, 'onBefore');
    });
    $transitions.onCreate({}, function(transition) {
      logHook(transition, 'onCreate');
    });
    $transitions.onEnter({}, function(transition) {
      logHook(transition, 'onEnter');
    });
    $transitions.onError({}, function(transition) {
      logHook(transition, 'onError');
    });
    $transitions.onExit({}, function(transition) {
      logHook(transition, 'onExit');
    });
    $transitions.onFinish({}, function(transition) {
      logHook(transition, 'onFinish');
    });
    $transitions.onRetain({}, function(transition) {
      logHook(transition, 'onRetain');
    });
    $transitions.onStart({}, function(transition) {
      logHook(transition, 'onStart');
    });
    $transitions.onSuccess({}, function(transition) {
      logHook(transition, 'onSuccess');
    });

    function logHook(transition, hookName) {
      console.log('----------------------------------------');
      console.log('hook: ', hookName);
      console.log('from: ', transition.$from());
      console.log('to: ', transition.$to());
      console.log('----------------------------------------');
    }*/
  }

  /**
   * Создание нового типа графиков ChartJs CqDoughnut
   * Этот тип основан на стандартном doughnut, создан он только ради zero data для бублика
   */
  function runChartJsCqDoughnut() {
    // ради zero data в пончике пришлось создавать новый тип графика
    Chart.defaults.cqDoughnut = Chart.defaults.doughnut;
    Chart.defaults.cqDoughnut.tooltips.mode = 'point';

    Chart.controllers.cqDoughnut = Chart.controllers.doughnut.extend({
      updateElement: function (arc, index, reset) {
        var me = this;
        var meta = me.getMeta();
        var chart = me.chart;

        meta.tooltipCurrentState = meta.tooltipCurrentState || chart.options.tooltips.enabled;

        // если в пончике 0 данных - делаем из нулевого элемента датасета zero data, а остальные отрисовываем стандартным методом, который сделает их невидимыми
        if (meta.total == 0) {
          chart.options.tooltips.enabled = false;
          if (index == 0) {
            var chartArea = chart.chartArea,
              opts = chart.options,
              animationOpts = opts.animation,
              centerX = (chartArea.left + chartArea.right) / 2,
              centerY = (chartArea.top + chartArea.bottom) / 2,
              startAngle = opts.rotation, // non reset case handled later
              endAngle = opts.rotation, // non reset case handled later
              dataset = me.getDataset(),
              circumference = reset && animationOpts.animateRotate ? 0 : opts.circumference,
              innerRadius = reset && animationOpts.animateScale ? 0 : me.innerRadius,
              outerRadius = reset && animationOpts.animateScale ? 0 : me.outerRadius,
              valueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;

            Chart.helpers.extend(arc, {
              // Utility
              _datasetIndex: me.index,
              _index: index,

              // Desired view properties
              _model: {
                x: centerX + chart.offsetX,
                y: centerY + chart.offsetY,
                startAngle: startAngle,
                endAngle: endAngle,
                circumference: circumference,
                outerRadius: outerRadius,
                innerRadius: innerRadius,
                label: valueAtIndexOrDefault(dataset.label, index, chart.data.labels[index]),
              },
            });

            var model = arc._model;
            // Resets the visual styles
            this.removeHoverStyle(arc);

            // Set correct angles if not resetting
            if (!reset || !animationOpts.animateRotate) {
              if (index === 0) {
                model.startAngle = opts.rotation;
              } else {
                model.startAngle = me.getMeta().data[index - 1]._model.endAngle;
              }

              model.endAngle = model.startAngle + model.circumference;
            }

            arc.pivot();
          } else {
            Chart.controllers.doughnut.prototype.updateElement.call(this, arc, index, reset);
          }
        } else {
          chart.options.tooltips.enabled = meta.tooltipCurrentState;
          Chart.controllers.doughnut.prototype.updateElement.call(this, arc, index, reset);
        }
      },

      removeHoverStyle: function (arc) {
        var dataset = this.chart.data.datasets[arc._datasetIndex],
          index = arc._index,
          model = arc._model,
          custom = arc.custom || {},
          elementOpts = this.chart.options.elements.arc;

        var meta = this.getMeta();

        if (meta.total == 0) {
          model.backgroundColor = '#e3e5e8';
          model.borderColor = '#e3e5e8';
          model.borderWidth = 0;
        } else {
          Chart.controllers.doughnut.prototype.removeHoverStyle.call(this, arc);
        }
      },

      setHoverStyle: function (arc) {
        var dataset = this.chart.data.datasets[arc._datasetIndex],
          index = arc._index,
          getHoverColor = Chart.helpers.getHoverColor,
          custom = arc.custom || {},
          model = arc._model;

        var meta = this.getMeta();

        if (meta.total == 0) {
          model.backgroundColor = getHoverColor('#e3e5e8');
          model.borderColor = getHoverColor('#e3e5e8');
          model.borderWidth = 0;
        } else {
          Chart.controllers.doughnut.prototype.setHoverStyle.call(this, arc);
        }
      },
    });

    // Текст внутри doughnut-чарта
    Chart.plugins.register({
      afterUpdate: function (chartInstance) {
        var dataSum = 0;
        var countConfig = chartInstance.config.options.count;

        if (countConfig && countConfig.display == true) {
          countConfig.sum = 0;
          for (var i = 0; i < chartInstance.config.data.datasets.length; i++) {
            countConfig.sum += chartInstance.getDatasetMeta(i).total;
          }
        }
      },
      afterDatasetsDraw: function (chartInstance) {
        var countConfig = chartInstance.config.options.count;
        var helpers = Chart.helpers;
        var innerRadius = chartInstance.innerRadius;

        if (countConfig && countConfig.display == true) {
          var ctx = chartInstance.chart.ctx;

          ctx.save();

          var fontSize = (innerRadius / 1.5).toFixed(2);
          var fontStyle = Chart.defaults.global.defaultFontStyle;
          var fontFamily = Chart.defaults.global.defaultFontFamily;

          ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
          ctx.textBaseline = 'middle';
          ctx.fillStyle = helpers.getValueOrDefault(countConfig.fontColor, countConfig.defaultFontColor);

          var textWidth = ctx.measureText(countConfig.sum).width;

          while (textWidth > innerRadius * 2 - 10) {
            fontSize--;
            ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
            textWidth = ctx.measureText(countConfig.sum).width;
          }

          var centerX =
            (chartInstance.chartArea.left + chartInstance.chartArea.right - ctx.measureText(countConfig.sum).width) / 2;
          var centerY = (chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2;

          ctx.fillText(countConfig.sum, centerX, centerY);
          ctx.restore();
        }
      },
    });
  }

  /**
   * Создание плагина ChartJs для показа общего количества данных внутри бублика
   */
  function runChartJsDoughnutText() {
    Chart.plugins.register({
      afterUpdate: function (chartInstance) {
        var dataSum = 0;
        var countConfig = chartInstance.config.options.count;

        if (countConfig && countConfig.display == true) {
          countConfig.sum = 0;
          for (var i = 0; i < chartInstance.config.data.datasets.length; i++) {
            countConfig.sum += chartInstance.getDatasetMeta(i).total;
          }
        }
      },
      afterDraw: function (chartInstance) {
        var countConfig = chartInstance.config.options.count;
        var helpers = Chart.helpers;
        var innerRadius = chartInstance.innerRadius;

        if (countConfig && countConfig.display == true) {
          var ctx = chartInstance.chart.ctx;

          ctx.save();

          var fontSize = (innerRadius / 1.5).toFixed(2);
          var fontStyle = Chart.defaults.global.defaultFontStyle;
          var fontFamily = Chart.defaults.global.defaultFontFamily;

          ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
          ctx.textBaseline = 'middle';
          ctx.fillStyle = helpers.getValueOrDefault(countConfig.fontColor, countConfig.defaultFontColor);

          var textWidth = ctx.measureText(countConfig.sum).width;

          while (textWidth > innerRadius * 2 - 10) {
            fontSize--;
            ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
            textWidth = ctx.measureText(countConfig.sum).width;
          }

          var centerX =
            (chartInstance.chartArea.left + chartInstance.chartArea.right - ctx.measureText(countConfig.sum).width) / 2;
          var centerY = (chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2;

          ctx.fillText(countConfig.sum, centerX, centerY);
          ctx.restore();
        }
      },
    });
  }

  /**
   * Установка стандартных настроек для ChartJs
   */
  function runChartJsGlobalConfig() {
    Chart.defaults.global.defaultFontColor = '#9DA3AF';
    Chart.defaults.global.defaultFontFamily = 'PT Root UI';
    Chart.defaults.global.elements.arc.borderWidth = 1;
    Chart.defaults.global.elements.line.borderWidth = 1;
    //Chart.defaults.global.elements.line.cubicInterpolationMode = 'monotone';
    Chart.defaults.global.elements.rectangle.borderWidth = 1;
    Chart.defaults.global.legend.display = false;
    Chart.defaults.global.tooltips.cornerRadius = 0;
    Chart.defaults.global.tooltips.intersect = false;
    Chart.defaults.global.tooltips.mode = 'index';
    Chart.defaults.global.tooltips.xPadding = 8;
    Chart.defaults.global.tooltips.yPadding = 6;
  }

  /**
   * Создание нового типа графиков ChartJs HeatMap
   */
  function runChartJsHeatMap($translate, moment) {
    var now = moment().hours(0).minutes(0);

    // Новая шкала для частотного графика
    var dayGroups = Chart.Scale.extend({
      //customTicks: ['00:00 \u2014 03:00', '03:00 \u2014 06:00', '06:00 \u2014 09:00', '09:00 \u2014 12:00', '12:00 \u2014 15:00', '15:00 \u2014 18:00', '18:00 \u2014 21:00', '21:00 \u2014 00:00'],

      // TODO: код для вывода локализованных часов, но в нём есть проблема: часы выводятся без незначащих нулей, и получается не красиво
      customTicks: [
        //@formatter:off
        now.format('LT') + ' \u2014 ' + now.add(3, 'hours').format('LT'),
        now.format('LT') + ' \u2014 ' + now.add(3, 'hours').format('LT'),
        now.format('LT') + ' \u2014 ' + now.add(3, 'hours').format('LT'),
        now.format('LT') + ' \u2014 ' + now.add(3, 'hours').format('LT'),
        now.format('LT') + ' \u2014 ' + now.add(3, 'hours').format('LT'),
        now.format('LT') + ' \u2014 ' + now.add(3, 'hours').format('LT'),
        now.format('LT') + ' \u2014 ' + now.add(3, 'hours').format('LT'),
        now.format('LT') + ' \u2014 ' + now.add(3, 'hours').format('LT'),
        //@formatter:on
      ],

      buildTicks: function () {
        var me = this;
        me.ticks = me.customTicks;
      },

      determineDataLimits: function () {
        var me = this;

        me.min = me.customTicks[0];
        me.max = me.customTicks[me.customTicks.length - 1];
      },

      getLabelForIndex: function (index, datasetIndex) {
        return this.chart.data.datasets[datasetIndex].data[index];
      },

      getPixelForTick: function (index, includeOffset) {
        var me = this;

        var innerHeight = me.height;
        var tickHeight = innerHeight / me.ticks.length;
        var pixel = tickHeight * index;

        if (includeOffset) {
          pixel += tickHeight / 2;
        }

        return me.top + pixel;
      },

      getPixelForValue: angular.noop,

      getValueForPixel: angular.noop,
    });
    Chart.scaleService.registerScaleType('dayGroups', dayGroups);

    // Тепловая карта
    Chart.defaults.heatMap = {
      maxValue: undefined,
      minValue: undefined,
      zeroValue: 0,
      fromBackgroundColor: '#ecf9ec',
      toBackgroundColor: '#66cc66',
      maxBackgroundColor: '#ff7733',
      zeroBackgroundColor: '#f1f2f4',
      borderWidth: 1,
      legend: {
        display: false,
        position: 'bottom',
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: true,
              zeroLineColor: 'rgba(0,0,0,0)',
            },
            type: 'category',
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              offsetGridLines: true,
              zeroLineColor: 'rgba(0,0,0,0)',
            },
            type: 'dayGroups',
          },
        ],
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItems, data) {
            return data.labels[tooltipItems[0].datasetIndex];
          },
          label: function (tooltipItem, data) {
            var chart = this._chartInstance;
            return (
              chart.scales['y-axis-0'].ticks[tooltipItem.index] +
              ': ' +
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
            );
          },
        },
        mode: 'nearest',
      },
      legendCallback: function (chart) {
        return (
          '\
          <div class="flex align-items-center justify-center margin-between-cols-15">\
            <small class="flex align-items-center margin-between-cols-5">\
              <span class="legend-box" style="background:' +
          chart.options.fromBackgroundColor +
          ';"></span>\
              <span>' +
          $translate.instant('services.chartHelper.heatMap.legend.leastLoaded') +
          '</span>\
            </small>\
            <small class="flex align-items-center margin-between-cols-5">\
              <span class="legend-box" style="background:' +
          chart.options.toBackgroundColor +
          ';"></span>\
              <span>' +
          $translate.instant('services.chartHelper.heatMap.legend.mostLoaded') +
          '</span>\
            </small>\
            <small class="flex align-items-center margin-between-cols-5">\
              <span class="legend-box" style="background:' +
          chart.options.maxBackgroundColor +
          ';"></span>\
              <span>' +
          $translate.instant('services.chartHelper.heatMap.legend.busiest') +
          '</span>\
            </small>\
          </div>\
        '
        );
      },
    };

    Chart.controllers.heatMap = Chart.DatasetController.extend({
      helpers: Chart.helpers,
      dataElementType: Chart.elements.Rectangle,

      calculateCellX: function (index, includeOffset) {
        var me = this;
        var meta = me.getMeta();
        var xScale = me.getScaleForId(meta.xAxisID);
        return xScale.getPixelForTick(index, includeOffset);
      },

      calculateCellY: function (index) {
        var me = this;
        var meta = me.getMeta();
        var yScale = me.getScaleForId(meta.yAxisID);
        return yScale.getPixelForTick(index);
      },

      update: function (reset) {
        var me = this;
        var meta = me.getMeta();

        var boundaryValues = me.getBoundaryValues();
        me.chart.data.maxValue = me.helpers.getValueOrDefault(me.chart.options.maxValue, boundaryValues.max);
        me.chart.data.minValue = me.helpers.getValueOrDefault(me.chart.options.maxValue, boundaryValues.min);
        me.chart.data.zeroValue = me.helpers.getValueOrDefault(me.chart.options.zeroValue, me.chart.options.zeroValue);
        me.helpers.each(
          meta.data,
          function (rectangle, index) {
            me.updateElement(rectangle, index, reset);
          },
          me,
        );
      },

      getBoundaryValues: function () {
        var me = this;
        var max, min;

        for (var i = 0; i < me.chart.data.datasets.length; i++) {
          var localMax = me.chart.data.datasets[i].data.reduce(function (max, value) {
            if (!isNaN(value)) {
              return Math.max(max, value);
            }
            return max;
          }, 0);
          var localMin = me.chart.data.datasets[i].data.reduce(function (min, value) {
            if (!isNaN(value)) {
              return Math.min(min, value);
            }
            return min;
          }, 0);

          if (max) {
            max = localMax > max ? localMax : max;
          } else {
            max = localMax;
          }

          if (min) {
            min = localMin < min ? localMin : min;
          } else {
            min = localMin;
          }
        }

        return {
          max: max,
          min: min,
        };
      },

      updateElement: function (rectangle, index, reset) {
        var me = this;

        var meta = me.getMeta();
        var xScale = me.getScaleForId(meta.xAxisID);
        var yScale = me.getScaleForId(meta.yAxisID);
        var rectangleElementOptions = me.chart.options.elements.rectangle;
        var dataset = me.getDataset();
        var data = me.chart.data;
        var options = me.chart.options;
        var backgroundColor = '#ffffff';

        if (dataset.data[index] != data.zeroValue) {
          if (dataset.data[index] == data.maxValue) {
            backgroundColor = me.helpers.color(options.maxBackgroundColor);
          } else {
            backgroundColor = me.helpers
              .color(options.fromBackgroundColor)
              .mix(
                me.helpers.color(options.toBackgroundColor),
                (data.maxValue - dataset.data[index]) / (data.maxValue - data.minValue),
              );
          }
        } else {
          backgroundColor = me.helpers.color(options.zeroBackgroundColor);
        }

        rectangle._xScale = xScale;
        rectangle._yScale = yScale;
        rectangle._datasetIndex = me.index;
        rectangle._index = index;

        rectangle._model = {
          x: me.calculateCellX(me.index, xScale.options.gridLines.offsetGridLines) + options.borderWidth,
          y: me.calculateCellY(index) + options.borderWidth,

          label: me.chart.data[index],
          datasetLabel: dataset.label,

          base: me.calculateCellY(index) + yScale.height / yScale.ticks.length - options.borderWidth,
          width: xScale.width / xScale.ticks.length - options.borderWidth,
          height: yScale.height / yScale.ticks.length - options.borderWidth,
          backgroundColor: backgroundColor.rgbString(),
          borderColor: 'rgba(255,255,255,0)',
          borderWidth: options.borderWidth,
        };

        rectangle.pivot();
      },

      removeHoverStyle: function (rectangle, elementOpts) {
        /*var dataset = this.chart.data.datasets[rectangle._datasetIndex];
        var index = rectangle._index;
        var custom = rectangle.custom || {};
        var model = rectangle._model;
        var rectangleElementOptions = this.chart.options.elements.rectangle;

        model.backgroundColor = custom.backgroundColor ? custom.backgroundColor : Chart.helpers.getValueAtIndexOrDefault(dataset.backgroundColor, index, rectangleElementOptions.backgroundColor);
        model.borderColor = custom.borderColor ? custom.borderColor : Chart.helpers.getValueAtIndexOrDefault(dataset.borderColor, index, rectangleElementOptions.borderColor);
        model.borderWidth = custom.borderWidth ? custom.borderWidth : Chart.helpers.getValueAtIndexOrDefault(dataset.borderWidth, index, rectangleElementOptions.borderWidth);*/
      },

      setHoverStyle: function (rectangle) {
        /*var dataset = this.chart.data.datasets[rectangle._datasetIndex];
        var index = rectangle._index;

        var custom = rectangle.custom || {};
        var model = rectangle._model;
        model.backgroundColor = custom.hoverBackgroundColor ? custom.hoverBackgroundColor : Chart.helpers.getValueAtIndexOrDefault(dataset.hoverBackgroundColor, index, Chart.helpers.getHoverColor(model.backgroundColor));
        model.borderColor = custom.hoverBorderColor ? custom.hoverBorderColor : Chart.helpers.getValueAtIndexOrDefault(dataset.hoverBorderColor, index, Chart.helpers.getHoverColor(model.borderColor));
        model.borderWidth = custom.hoverBorderWidth ? custom.hoverBorderWidth : Chart.helpers.getValueAtIndexOrDefault(dataset.hoverBorderWidth, index, model.borderWidth);*/
      },
    });
  }

  /**
   * Создание плагина ChartJs для zero data на графиках
   *
   * @param $translate
   * @param canvasHelper
   */
  function runChartJsZeroData($translate, canvasHelper) {
    Chart.defaults.global.zeroData = {
      display: true,
      boxHeight: 60,
      boxWidth: 200,
      boxPadding: 10,
      boxBorder: 1,
      boxBorderRadius: 4,
      boxBorderColor: '#5c5cd6',
      boxBorderOpacity: 1,
      boxBackground: '#5c5cd6',
      boxOpacity: 0.1,
      fontColor: '#5c5cd6',
      fontStyle: 'Normal',
      fontSize: 12,
      fontFamily: 'PT Root UI',
      lineHeight: 16,
      text: 'services.chartHelper.zeroData.text',
    };

    Chart.plugins.register({
      beforeInit: function (chartInstance) {
        if (chartInstance.config.type == 'doughnut' || chartInstance.config.type == 'cqDoughnut') {
          chartInstance.options.zeroData.display = false;
        }
      },
      afterUpdate: function (chartInstance) {
        var zeroDataConfig = chartInstance.options.zeroData;
        var ctx = chartInstance.chart.ctx;

        var dataCount = 0;

        if (zeroDataConfig && zeroDataConfig.display == true) {
          for (var i = 0; i < chartInstance.config.data.datasets.length; i++) {
            if (chartInstance.isDatasetVisible(i)) {
              for (var j = 0; j < chartInstance.config.data.datasets[i].data.length; j++) {
                if (chartInstance.config.data.datasets[i].data[j] != 0) {
                  dataCount++;
                }
              }
            }
          }

          zeroDataConfig.visible = !dataCount;
        }
      },
      afterDraw: function (chartInstance) {
        var zeroDataConfig = chartInstance.config.options.zeroData;
        var globalConfig = Chart.defaults.global;
        var ctx = chartInstance.chart.ctx;

        if (zeroDataConfig && zeroDataConfig.visible) {
          var centerX = (chartInstance.chartArea.left + chartInstance.chartArea.right) / 2;
          var centerY = (chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2;

          var fontColor = Chart.helpers.getValueOrDefault(zeroDataConfig.fontColor, globalConfig.defaultFontColor);
          var fontStyle = Chart.helpers.getValueOrDefault(zeroDataConfig.fontStyle, globalConfig.defaultFontStyle);
          var fontSize = Chart.helpers.getValueOrDefault(zeroDataConfig.fontSize, globalConfig.defaultFontSize);
          var fontFamily = Chart.helpers.getValueOrDefault(zeroDataConfig.fontFamily, globalConfig.defaultFontFamily);
          var labelFont = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

          ctx.save();
          canvasHelper.drawRoundedRect(
            ctx,
            centerX - zeroDataConfig.boxWidth / 2,
            centerY - zeroDataConfig.boxHeight / 2,
            zeroDataConfig.boxWidth,
            zeroDataConfig.boxHeight,
            zeroDataConfig.boxBorderRadius,
          );
          ctx.fillStyle = zeroDataConfig.boxBackground;
          ctx.globalAlpha = zeroDataConfig.boxOpacity;
          ctx.fill();

          ctx.strokeStyle = zeroDataConfig.boxBorderColor;
          ctx.globalAlpha = zeroDataConfig.boxBorderOpacity;
          ctx.stroke();

          ctx.globalAlpha = 1;
          ctx.font = labelFont;
          ctx.fillStyle = fontColor;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'top';
          var height = canvasHelper.getWrappedTextHeight(
            ctx,
            $translate.instant(zeroDataConfig.text),
            zeroDataConfig.boxWidth - zeroDataConfig.boxPadding,
            zeroDataConfig.lineHeight,
          );
          canvasHelper.drawWrappedText(
            ctx,
            $translate.instant(zeroDataConfig.text),
            centerX,
            centerY - height / 2,
            zeroDataConfig.boxWidth - zeroDataConfig.boxPadding,
            zeroDataConfig.lineHeight,
          );
          ctx.restore();
        }
      },
    });
  }

  /**
   * Замена стандартного темплейта контейнера аккордеона из ui-bootstrap, т.к. в Bootstrap 4 уже не используется класс panel-group, который использовался в 3 версии. Вместо него теперь нужно использовать класс accordion
   *
   * @param $templateCache
   */
  function runUiBootstrapAccordion($templateCache) {
    $templateCache.put(
      'uib/template/accordion/accordion.html',
      '\
      <div class="accordion" ng-transclude></div>\
    ',
    );
  }

  /**
   * Замена стандартного темплейта пункта аккордеона из ui-bootstrap, т.к. потребовалось изменить его поведение (открытие не по клику на заголовок, а по клику в любом месте пункта, например)
   * Так же он максимально приведён к аккордеону в Bootstrap 4
   *
   * @param $templateCache
   */
  function runUiBootstrapAccordionGroup($templateCache) {
    $templateCache.put(
      'uib/template/accordion/accordion-group.html',
      '\
      <div class="card-header bg-light-secondary text-body accordion-toggle" role="tab" id="{{::headingId}}" aria-selected="{{isOpen}}" ng-click="toggleOpen()" ng-disabled="isDisabled" ng-keypress="toggleOpen($event)">\
        <a data-toggle="collapse" href aria-expanded="{{isOpen}}" aria-controls="{{::panelId}}" tabindex="0" uib-accordion-transclude="heading" uib-tabindex-toggle>\
          <span uib-accordion-header>{{heading}}</span>\
        </a>\
      </div>\
      <div class="collapse" id="{{::panelId}}" aria-labelledby="{{::headingId}}" aria-hidden="{{!isOpen}}" role="tabpanel" uib-collapse="!isOpen">\
        <div class="card-body" ng-transclude></div>\
      </div>\
    ',
    );
  }

  /**
   * Замена стандартных темплейтов прогресс-бара из ui-bootstrap
   *
   * @param $templateCache
   */
  function runUiBootstrapProgressBar($templateCache) {
    $templateCache.put(
      'uib/template/progressbar/bar.html',
      '\
      <div class="progress-bar" ng-class="type && \'bg-\' + type" role="progressbar" aria-valuenow="{{value}}" aria-valuemin="0" aria-valuemax="{{max}}" ng-style="{width: (percent < 100 ? percent : 100) + \'%\', \'background-color\': backgroundColor}" aria-valuetext="{{percent | number:0}}%" aria-labelledby="{{::title}}" ng-transclude></div>\
    ',
    );

    $templateCache.put(
      'uib/template/progressbar/progress.html',
      '\
      <div class="progress" ng-transclude aria-labelledby="{{::title}}"></div>\
    ',
    );

    $templateCache.put(
      'uib/template/progressbar/progressbar.html',
      '\
      <div class="progress-wrapper">\
        <div ng-if="::hasLeftTitle || hasLeftSlot || hasRightTitle || hasRightSlot" class="d-flex align-items-center margin-bottom-5">\
          <span ng-if="::hasLeftTitle || hasLeftSlot" ng-transclude="leftSlot">{{leftTitle}}</span>\
          <i ng-if="::tooltip" class="cqi-sm cqi-question-circle padding-left-5 padding-right-5" uib-tooltip="{{::tooltip}}"></i>\
          <span ng-if="::hasRightTitle || hasRightSlot" class="margin-left-auto" ng-transclude="rightSlot">{{rightTitle}}</span>\
        </div>\
        <div class="progress">\
          <div class="progress-bar" ng-class="[type && \'bg-\' + type, active && \'progress-bar-animated\', striped && \'progress-bar-striped\']" role="progressbar" aria-valuenow="{{value}}" aria-valuemin="0" aria-valuemax="{{max}}" ng-style="{width: (percent < 100 ? percent : 100) + \'%\', \'background-color\': backgroundColor}" aria-valuetext="{{percent | number:0}}%" aria-labelledby="{{::title}}" ng-transclude></div>\
        </div>\
      </div>\
      ',
    );
  }

  /**
   * Замена стандартных темплейтов навигации из ui-bootstrap, т.к. стандартные немного не подходят к bootstrap 4
   *
   * @param $templateCache
   */
  function runUiBootstrapNavs($templateCache) {
    $templateCache.put(
      'uib/template/tabs/tab.html',
      '\
      <li class="nav-item">\
        <a href class="nav-link" ng-class="[{active: active, disabled: disabled}, classes]" ng-click="select($event)" uib-tab-heading-transclude>{{heading}}</a>\
      </li>\
      ',
    );

    $templateCache.put(
      'uib/template/tabs/tabset.html',
      '\
      <div>\
        <ul class="nav nav-{{tabset.type || \'tabs\'}}" ng-class="{\'flex-col\': vertical, \'nav-justified\': justified}" ng-transclude></ul>\
        <div class="tab-content">\
          <div class="tab-pane" ng-class="{active: tabset.active === tab.index}" ng-repeat="tab in tabset.tabs" uib-tab-content-transclude="tab"></div>\
        </div>\
      </div>\
      ',
    );
  }

  /**
   * Правка бага в ui-select, при котором не срабатывает фокус на поле ввода: https://github.com/angular-ui/ui-select/issues/1560
   *
   * @param $animate
   * @param $templateCache
   */
  function runUiSelect($animate, $templateCache) {
    var overridden = $animate.enabled;

    $animate.enabled = function (element, enabled) {
      // просто принудительно возвращаем false, при включенном classNameFilter, т.к. метод enabled не предназначен для проверки, удовлетворяет ли элемент classNameFilter, но всё равно используется внутри ui-select для этого
      if (element && angular.element(element).hasClass('ui-select-choices')) {
        return false;
      }

      return overridden(element, enabled);
    };

    $templateCache.put(
      'cqSelect/choices.tpl.html',
      '\
      <div class="ui-select-choices ui-select-dropdown dropdown-menu">\
        <ul class="ui-select-choices-content custom-scroll" ng-show="$select.open && $select.items.length > 0">\
          <li class="ui-select-choices-group" id="ui-select-choices-{{ $select.generatedId }}">\
            <div class="dropdown-divider" ng-show="$select.isGrouped && $index > 0"></div>\
            <div ng-show="$select.isGrouped" class="ui-select-choices-group-label dropdown-header" ng-bind="$group.name"></div>\
            <div ng-attr-id="ui-select-choices-row-{{ $select.generatedId }}-{{$index}}" class="ui-select-choices-row" ng-class="{active: $select.isActive(this), disabled: $select.isDisabled(this)}" role="option">\
              <span class="ui-select-choices-row-inner"></span>\
            </div>\
          </li>\
        </ul>\
        <button ng-show="$select.action && $select.actionText" class="ui-select-action btn btn-block btn-borderless-primary bordered-top" ng-click="$select.action()" type="button" ng-bind="$select.actionText"></button>\
      </div>',
    );

    $templateCache.put(
      'cqSelect/match-multiple.tpl.html',
      '\
      <div class="ui-select-match margin-between-cols-5">\
        <span class="no-space inline-block margin-bottom-5" ng-repeat="$item in $select.selected track by $index">\
          <span class="ui-select-match-item btn btn-outline-primary btn-sm" tabindex="-1" ng-disabled="$select.disabled" ng-click="$selectMultiple.activeMatchIndex = $index;" ng-class="{\'select-locked\':$select.isLocked(this, $index)}" ui-select-sort="$select.selected">\
            <span uis-transclude-append=""></span>\
            <span ng-hide="$select.disabled" ng-click="$selectMultiple.removeChoice($index)">\
              <i class="cqi-sm cqi-times" style="font-size: 12px"></i>\
            </span>\
          </span>\
        </span>\
      </div>',
    );

    $templateCache.put(
      'cqSelect/match.tpl.html',
      '\
      <div class="ui-select-match" ng-hide="$select.open && $select.searchEnabled" ng-disabled="$select.disabled">\
        <span tabindex="-1" class="form-control ui-select-toggle" aria-label="{{ $select.baseTitle }} activate" ng-disabled="$select.disabled" ng-click="$select.activate()">\
          <span ng-show="$select.isEmpty()" class="ui-select-placeholder text-muted">\
            <span>{{$select.placeholder}}</span>\
          </span>\
          <span ng-hide="$select.isEmpty()" class="ui-select-match-text pull-left" ng-class="{\'ui-select-allow-clear\': $select.allowClear && !$select.isEmpty()}" ng-transclude=""></span>\
          <i class="caret pull-right" ng-click="$select.toggle($event)"></i>\
          <a ng-show="$select.allowClear && !$select.isEmpty() && ($select.disabled !== true)" class="btn btn-text-primary" aria-label="{{ $select.baseTitle }} clear" ng-click="$select.clear($event)">\
            <i class="btn-icon cqi-sm cqi-times" aria-hidden="true"></i>\
          </a>\
        </span>\
      </div>',
    );

    $templateCache.put(
      'cqSelect/no-choice.tpl.html',
      '\
      <div class="ui-select-no-choice ui-select-dropdown dropdown-menu" ng-show="$select.items.length == 0">\
        <ul class="ui-select-no-choice-content">\
          <li class="ui-select-no-choice-row-inner" ng-transclude=""></li>\
        </ul>\
        <button ng-show="$select.action && $select.actionText" class="ui-select-action btn btn-block btn-borderless-primary bordered-top" ng-click="$select.action()" type="button" ng-bind="$select.actionText"></button>\
      </div>',
    );

    $templateCache.put(
      'cqSelect/select-multiple.tpl.html',
      '\
      <div class="ui-select-container ui-select-multiple ui-select-bootstrap dropdown form-control" ng-class="{open: $select.open}">\
        <div class="no-space">\
          <div class="ui-select-match"></div>\
          <input type="text" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" class="ui-select-search input-xs" placeholder="{{$selectMultiple.getPlaceholder()}}" ng-disabled="$select.disabled" ng-click="$select.activate()" ng-model="$select.search" role="combobox" aria-expanded="{{$select.open}}" aria-label="{{$select.baseTitle}}" ng-class="{spinner: $select.refreshing}" ondrop="return false;">\
        </div>\
        <div class="ui-select-choices"></div>\
        <div class="ui-select-no-choice"></div>\
      </div>',
    );

    $templateCache.put(
      'cqSelect/select.tpl.html',
      '\
      <div class="ui-select-container ui-select-bootstrap dropdown" ng-class="{open: $select.open}">\
        <div class="ui-select-match"></div>\
        <span ng-show="$select.open && $select.refreshing && $select.spinnerEnabled" class="ui-select-refreshing {{$select.spinnerClass}}"></span>\
        <input type="text" autocomplete="off" tabindex="-1" aria-expanded="true" aria-label="{{ $select.baseTitle }}" aria-owns="ui-select-choices-{{ $select.generatedId }}" class="form-control ui-select-search" ng-class="{ \'ui-select-search-hidden\' : !$select.searchEnabled }" placeholder="{{$select.placeholder}}" ng-model="$select.search" ng-show="$select.open">\
        <div class="ui-select-choices"></div>\
        <div class="ui-select-no-choice"></div>\
      </div>',
    );
  }
})();

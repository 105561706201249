/**
 * Системные типы событий
 */
export const SYSTEM_EVENT_TYPES = [
  '$allowed_data_processing_policy',
  '$allowed_cookie_processing_policy',
  '$authorized',
  '$cart_added',
  '$cart_viewed',
  '$calendly_invitee_created',
  '$calendly_invitee_rescheduled',
  '$calendly_invitee_canceled',
  '$chat_bot_finished',
  '$chat_bot_read',
  '$chat_bot_replied',
  '$chat_bot_interrupted',
  '$chat_bot_sent',
  '$conversation_part_group_closed',
  '$conversation_user_started',
  '$double_opt_in_confirmed',
  '$double_opt_in_send',
  '$email_changed',
  '$filled_facebook_lead_ad',
  '$form_sended',
  '$goal_completed',
  '$input_filled',
  '$leave_site_attempt',
  '$logout',
  '$message_bounced',
  '$message_clicked',
  '$message_control_group',
  '$message_read',
  '$message_replied',
  '$message_sended',
  '$message_spam',
  '$message_unsubscribed',
  '$name_changed',
  '$order_cancelled',
  '$order_closed',
  '$order_completed',
  '$order_paid',
  '$order_refunded',
  '$order_started',
  '$page_view',
  '$phone_changed',
  '$product_viewed',
  '$push_subscribed',
  '$push_unsubscribed',
  '$registered',
  '$session_start',
  '$user_banned',
  '$user_merged',
  '$user_unbanned',
  '$utm_hit',
  '$zoom_meeting_joined',
  '$allowed_phone_collection',
  '$allowed_email_collection',
];

/**
 * Группы для событий
 */
export enum EVENT_TYPE_GROUP {
  POPULAR = 'popular', // Популярные события NOTE будет заполнятся нами. На текущий момент выглядит как набор тегов
  CUSTOM = 'custom', // Созданные события
  ACTION = 'action', // Действия пользователя
  INFORMATION = 'information', // Информация о пользователе
  COMMUNICATION = 'communication', // Коммуникация с пользователем
  ONLINE = 'online', // Онлайн-покупки
  ARCHIVE = 'archive', // Архивные
}

/**
 * Группировка событий по их группам
 */
export const SYSTEM_EVENT_TYPES_BY_GROUPS: { [group in EVENT_TYPE_GROUP]: string[] } = {
  [EVENT_TYPE_GROUP.POPULAR]: [],
  [EVENT_TYPE_GROUP.CUSTOM]: [],
  [EVENT_TYPE_GROUP.ACTION]: [
    '$session_start',
    '$leave_site_attempt',
    '$authorized',
    '$logout',
    '$registered',
    '$input_filled',
    '$page_view',
  ],
  [EVENT_TYPE_GROUP.INFORMATION]: [
    '$phone_changed',
    '$name_changed',
    '$email_changed',
    '$user_banned',
    '$user_unbanned',
    '$utm_hit',
    '$user_merged',
    '$form_sended',
    '$double_opt_in_confirmed',
    '$filled_facebook_lead_ad',
    '$allowed_data_processing_policy',
    '$allowed_cookie_processing_policy',
  ],
  [EVENT_TYPE_GROUP.COMMUNICATION]: [
    '$conversation_user_started',
    '$message_replied',
    '$chat_bot_replied',
    '$chat_bot_read',
    '$message_clicked',
    '$chat_bot_finished',
    '$message_sended',
    '$chat_bot_sent',
    '$message_read',
    '$goal_completed',
    '$message_control_group',
    '$push_subscribed',
    '$message_spam',
    '$message_unsubscribed',
    '$push_unsubscribed',
    '$message_bounced',
    '$zoom_meeting_joined',
    '$conversation_part_group_closed',
    '$calendly_invitee_created',
    '$calendly_invitee_rescheduled',
    '$calendly_invitee_canceled',
    '$chat_bot_interrupted',
    '$double_opt_in_send',
  ],
  [EVENT_TYPE_GROUP.ONLINE]: [
    '$order_paid',
    '$cart_added',
    '$order_started',
    '$order_refunded',
    '$order_completed',
    '$order_cancelled',
    '$product_viewed',
    '$cart_viewed',
    '$order_closed',
  ],
  [EVENT_TYPE_GROUP.ARCHIVE]: [],
};

/**
 * Порядок групп событий
 */
export const EVENT_TYPE_GROUPS_ORDER: { [group in EVENT_TYPE_GROUP]: number } = {
  [EVENT_TYPE_GROUP.POPULAR]: 0,
  [EVENT_TYPE_GROUP.CUSTOM]: 1,
  [EVENT_TYPE_GROUP.ACTION]: 2,
  [EVENT_TYPE_GROUP.INFORMATION]: 3,
  [EVENT_TYPE_GROUP.COMMUNICATION]: 4,
  [EVENT_TYPE_GROUP.ONLINE]: 5,
  [EVENT_TYPE_GROUP.ARCHIVE]: 6,
};

/**
 * Порядок групп событий
 */
export const EVENT_TYPE_GROUPS_ICONS: { [group in EVENT_TYPE_GROUP]: string } = {
  [EVENT_TYPE_GROUP.POPULAR]: 'cqi-flash-o',
  [EVENT_TYPE_GROUP.CUSTOM]: 'cqi-code',
  [EVENT_TYPE_GROUP.ACTION]: 'cqi-hand-pointer',
  [EVENT_TYPE_GROUP.INFORMATION]: 'cqi-user-o',
  [EVENT_TYPE_GROUP.COMMUNICATION]: 'cqi-comments',
  [EVENT_TYPE_GROUP.ONLINE]: 'cqi-credit-card',
  [EVENT_TYPE_GROUP.ARCHIVE]: 'cqi-archive',
};

/**
 * Компонент для работы с адресом сайта в завершении регистрации
 */

(function () {
  'use strict';

  require('./completion-registration-form-control-app-url.controller');

  angular
    .module('myApp.completionRegistration')
    .component('cqCompletionRegistrationFormControlAppUrl', cqCompletionRegistrationFormControlAppUrl());

  function cqCompletionRegistrationFormControlAppUrl() {
    return {
      bindings: {
        /** Форма завершения регистрации */
        completionRegistrationForm: '=',
        /** Будет ли поле обязательным */
        isRequiredMode: '=',
        /** Адрес сайта пользователя */
        userAppUrl: '=',
      },
      controller: 'cqCompletionRegistrationFormControlAppUrlController',
      controllerAs: 'vm',
      template: require('./completion-registration-form-control-app-url.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'cq-ace-editor-wrapper-for-ajs',
  templateUrl: './ace-editor-wrapper-for-ajs.component.html',
  styleUrls: ['./ace-editor-wrapper-for-ajs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AceEditorWrapperForAjsComponent implements OnInit {
  @Input()
  public value: string = '';

  @Output()
  public valueChanged: EventEmitter<string> = new EventEmitter<string>();

  protected control = new FormControl<string>('', { nonNullable: true });

  constructor() {}

  public ngOnInit(): void {
    this.control.setValue(this.value);

    this.control.valueChanges.subscribe((value) => {
      this.valueChanged.emit(value);
    });
  }
}

import { ProjectPartial } from '@environments/types';

export const carrotQuestProjectPartial: ProjectPartial = {
  apiObjectName: 'carrotquest',
  appleAppId: '1259157795',
  chatLanguages: ['ru', 'en'],
  country: 'RU',
  currency: {
    currency: 'RUB',
    decimal: ',',
    format: '%v %s',
    precision: 2,
    symbol: '₽',
    thousand: ' ',
  },
  defaultLanguage: 'ru',
  faviconsSubPath: 'carrot-quest',
  landingUrl: 'https://www.carrotquest.io',
  language: 'ru',
  panelLanguages: ['ru', 'en'],
  privacyPolicyChatUrl: 'https://www.carrotquest.io/terms-and-policies/soglasie-4/',
  privacyPolicyEmailUrl: 'https://www.carrotquest.io/terms-and-policies/soglasie-3/',
  privacyPolicyPhoneUrl: 'https://www.carrotquest.io/terms-and-policies/soglasie-5/',
  privacyPolicyUrl: 'https://www.carrotquest.io/terms-and-policies/',
  projectName: 'Carrot quest',
  supportAccountEmail: 'support@carrotquest.io',
  userFilesUrl: 'https://files.carrotquest.io',
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { UserConsentModule } from '@panel/app/partials/user-card/user-consent/user-consent.module';
import { UserLocationModule } from '@panel/app/partials/user-card/user-location/user-location.module';
import { UserMainPropsModule } from '@panel/app/partials/user-card/user-main-props/user-main-props.module';
import { UserNotesListModule } from '@panel/app/partials/user-card/user-notes-list/user-notes-list.module';
import { UserPropsModule } from '@panel/app/partials/user-card/user-props/user-props.module';
import { UserSegmentsModule } from '@panel/app/partials/user-card/user-segments/user-segments.module';
import { UserSubscriptionsModule } from '@panel/app/partials/user-card/user-subscriptions/user-subscriptions.module';
import { UserTagsModule } from '@panel/app/partials/user-card/user-tags/user-tags-module';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { ConversationsUserCardComponent } from './user-card.component';

@NgModule({
  declarations: [ConversationsUserCardComponent],
  imports: [
    CommonModule,
    LoaderModule,
    TranslocoModule,
    UserLocationModule,
    UserMainPropsModule,
    UserNotesListModule,
    UserPropsModule,
    UserSegmentsModule,
    UserSubscriptionsModule,
    UserTagsModule,
    UserConsentModule,
  ],
  exports: [ConversationsUserCardComponent],
})
export class ConversationsUserCardModule {}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('Bitrix24Controller', Bitrix24Controller);

  function Bitrix24Controller(
    $filter,
    $location,
    $q,
    $scope,
    $state,
    $stateParams,
    $translate,
    $uibModal,
    $window,
    moment,
    toastr,
    BITRIX24_INTEGRATION_STEPS,
    PROJECT_NAME,
    caseStyleHelper,
    eventTypeModel,
    integrationModel,
    propertyModel,
    validationHelper,
    wizardHelper,
    currentApp,
    integration,
  ) {
    var vm = this;

    var STANDARD_BITRIX24_PROPS_DEFAULT = [
      {
        checked: false,
        cqName: '$name',
      },
      {
        checked: false,
        cqName: '$email',
      },
      {
        checked: false,
        cqName: '$phone',
      },
    ];

    var STANDARD_PROPS_DEFAULT = [
      {
        bitrixName: 'TITLE',
        cqName: '$name',
      },
      {
        bitrixName: 'EMAIL',
        cqName: '$email',
      },
      {
        bitrixName: 'PHONE',
        cqName: '$phone',
      },
    ];

    vm.$translate = $translate;
    vm.authorize = authorize;
    vm.authorizationExpired = true;
    vm.addMappingItem = addMappingItem;
    vm.BITRIX24_INTEGRATION_STEPS = BITRIX24_INTEGRATION_STEPS;
    vm.bitrix24DealsPropsMapping = []; //соответствие между свойствами сделки и свойствами кэррота (при передаче из Битрикс24 в cq)
    vm.bitrix24DealsProps = [];
    vm.bitrix24PropsMapping = []; //соответствие между свойствами контакта и свойствами кэррота (при передаче из Битрикс24 в cq)
    vm.bitrix24Props = [];
    vm.checkOAuth = checkOAuth;
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.currentStep = angular.isDefined(integration.settings.lastCompletedStep)
      ? getStepByIndex(BITRIX24_INTEGRATION_STEPS, integration.settings.lastCompletedStep + 1)
      : getStepByIndex(BITRIX24_INTEGRATION_STEPS, 0); // текущий шаг визарда
    vm.dealsEventsMapping = []; // соответствие статусов сделок Битрикс24 событиям в кэрроте (после смены статуса сделки в Битрикс24)
    vm.dealsPipelines = []; // воронки сделок в Битрикс24 + соответствующие статусам события в кэрроте
    vm.dealsStandardProps = [];
    vm.eventsPropsMapping = []; //соответствие между свойствами событий (первое, последнее, счетчик) и свойствами лида в Битрикс24 (при передаче из cq в Битрикс24)
    vm.eventTypes = []; // справочник типов событий
    vm.eventTypesFilter = ''; // поиск в списке событий-триггеров для отправки контактов
    vm.filterEvents = filterEvents;
    vm.filterProps = filterProps;
    vm.integration = integration;
    vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
      ? vm.currentStep.ORDER > integration.settings.lastCompletedStep
      : angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.leadsEventsMapping = []; // соответствие статусов лидов Битрикс24 событиям в кэрроте (после смены статуса лида в Битрикс24)
    vm.leadsEventTypesFilter = ''; // поиск в списке событий-триггеров для отправки сделок
    vm.oAuthErrors = {
      required: false,
      auth: false,
    };
    vm.onCopyUrlSuccess = onCopyUrlSuccess;
    vm.openEditStatus = openEditStatus;
    vm.pipelines = []; // воронки лидов Битрикс24 + соответствующие статусам события в кэрроте
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.props = []; // свойства
    vm.propsEvents = []; // свойства событий (первое, последнее, счетчик)
    vm.propsMapping = []; // соответствие свойств кэррота свойствам лида в Битрикс24 (из cq в Битрикс24)
    vm.removeMappingItem = removeMappingItem;
    vm.STANDARD_PROPS_DEFAULT = STANDARD_PROPS_DEFAULT; // имя, емейл, телефон из кэррота в Битрикс24 (неизменяемое)
    vm.standardProps = []; // имя, емейл, телефон из Битрикс24 в кэррот (можно отключить, нельзя изменить)
    vm.statusEvents = []; // справочник системных событий
    vm.statusEventsNames = []; // справочник названий системных событий
    vm.toggleDealsLeadsEvent = toggleDealsLeadsEvent;
    vm.toggleLeadsEvent = toggleLeadsEvent;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.validationHelper = validationHelper;
    vm.wizard = null; // инстанс визарда
    vm.wizardHelper = wizardHelper;

    init();

    function init() {
      parseBitrix24IntegrationToInternalFormat(integration);

      integration.settings.useSsl =
        typeof integration.settings.useSsl === 'undefined' ? 1 : integration.settings.useSsl;

      if ($stateParams.integrationId) {
        integration.settings.bitrix24AppUrl = $state.href(
          'oAuth.integrations.' + integration.type,
          { integrationType: integration.type },
          { absolute: true },
        );

        if (integration.settings.bitrix24Expires) {
          vm.authorizationExpired = Math.abs(moment().diff(integration.settings.bitrix24Expires, 'days')) >= 30;
        } else {
          vm.authorizationExpired = true;
        }

        if (integration.settings.bitrix24AccessToken && !vm.authorizationExpired) {
          loadBitrix24Data();
        }
      }

      // По умолчанию ставим опцию "only_not_closed" - Создается новый лид, если лида нет или есть со статусом "Закрыт"
      if (!integration.settings.leadCreation) {
        vm.integration.settings.leadCreation = 'only_not_closed';
      }

      firstValueFrom(propertyModel.getList(currentApp.id)).then(getPropertyListSuccess);

      wizardHelper.getWizard().then(getWizardSuccess);

      $scope.$watchCollection('[vm.pipelines, vm.leadsEventsMapping]', function (newValues, oldValues) {
        // если изменились воронки, или их сопоставление с событиями - надо их заново сопоставить
        if (newValues[0].length) {
          matchPipelinesWithLeadsEvents(
            newValues[0],
            newValues[1],
            $translate.instant('integrations.bitrix24.step4.step4Form.leadsEventsMapping.pipelines.eventTemplate'),
          );
        }
      });
      $scope.$watchCollection('[vm.dealsPipelines, vm.dealsEventsMapping]', function (newValues, oldValues) {
        // если изменились воронки, или их сопоставление с событиями - надо их заново сопоставить
        if (newValues[0].length) {
          matchPipelinesWithLeadsEvents(
            newValues[0],
            newValues[1],
            $translate.instant('integrations.bitrix24.step5.step5Form.dealsEventsMapping.dealsPipelines.eventTemplate'),
          );
        }
      });
      $scope.$watch('vm.currentStep', function (newValue, oldValue) {
        //обновляем состояние инструкции в зависимости от текущего шага
        vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
          ? newValue.ORDER > integration.settings.lastCompletedStep
          : angular.isUndefined(integration.id);
      });

      function getPropertyListSuccess(properties) {
        vm.eventTypes = properties.eventTypes;
        vm.props = properties.userProps;
        vm.propsEvents = properties.eventTypeProps;
        vm.statusEvents = properties.eventTypes.filter(filterEventTypesByName);
        vm.statusEventsNames = vm.statusEvents.map(mapStatusEventsToStatusEventsNames);

        function filterEventTypesByName(eventType) {
          return eventType.name != eventType.prettyName;
        }

        function mapStatusEventsToStatusEventsNames(eventType) {
          return eventType.name;
        }
      }

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }
    }

    /**
     * Добавление объекта в массив
     *
     * @param {Array} array Массив, в который добавляется объект
     */
    function addMappingItem(array) {
      array.push({});
    }

    /**
     * Авторизация в Битрикс24
     */
    function authorize() {
      var w = 350;
      var h = 250;

      var wLeft = $window.screenLeft ? $window.screenLeft : $window.screenX;
      var wTop = $window.screenTop ? $window.screenTop : $window.screenY;

      var left = wLeft + $window.innerWidth / 2 - w / 2;
      var top = wTop + $window.innerHeight / 2 - h / 2;

      // чтобы всплывающее окно корректно открылось - его вначале надо открыть без url, а затем, когда url получен с сервера - заменить location.href на этот url
      var authWindow = $window.open(
        '',
        '_blank',
        'toolbar=0, menubar=0,height=' + h + ',width=' + w + ',top=' + top + ',left=' + left,
      );

      integrationModel.bitrix24.getOAuthUrl(currentApp.id, integration.id).then(function (url) {
        authWindow.location.href = url;
      });

      $window.addEventListener('message', messageListener, false);

      /**
       * Удаление слушателя сообщений из дочернего окна
       */
      function destroyMessageListener() {
        $window.removeEventListener('message', messageListener);
      }

      function messageListener(message) {
        $scope.$apply(function () {
          var origin = $('<a>', { href: message.origin })[0];

          if (origin.hostname === $location.host()) {
            if (message.data.status === 'accepted') {
              vm.oAuthErrors.auth = false;
              vm.oAuthErrors.required = false;
            } else if (message.data.status === 'error') {
              vm.oAuthErrors.auth = true;
            }
          }

          authWindow.close();
          destroyMessageListener();
          vm.wizard.nextStep();
        });
      }
    }

    /**
     * Проверяет авторизацию в Битрикс24
     *
     * @return {Promise}
     */
    function checkOAuth() {
      if (!(vm.oAuthErrors.required || vm.oAuthErrors.auth)) {
        if (!vm.integration.settings.bitrix24AccessToken || vm.authorizationExpired) {
          return integrationModel
            .get(integration.id, integration.type)
            .then(getSuccess)
            .then(loadBitrix24Data)
            .then(createOrUpdateIntegration);
        }
      } else {
        return $q.reject();
      }

      function getSuccess(refreshedIntegration) {
        angular.extend(integration, refreshedIntegration);

        if (integration.settings.bitrix24Expires) {
          vm.authorizationExpired = Math.abs(moment().diff(integration.settings.bitrix24Expires, 'days')) >= 30;
        }

        if (!vm.integration.settings.bitrix24AccessToken || vm.authorizationExpired) {
          vm.oAuthErrors.required = true;
          return $q.reject();
        }
      }
    }

    /**
     * Конвертация массива объектов (ключи и значения этих объектов должны быть примитивными типами) в объект
     *
     * @example
     * // вернёт {'value1': 'value2', 'value3': 'value4'}
     * convertArrayToMapping([{'key1': 'value1', 'key2': 'value2'}, {'key1': 'value3', 'key2': 'value4'}], 'key1', 'key2');
     *
     * @param {Array.<Object>} array Массив объектов для конвертации
     * @param {String} keyName Название ключа объекта массива, значение которого будет являться ключом в результирующем объекте
     * @param {String} valueName Название ключа объекта массива, значение которого будет являться значением в результирующем объекте
     * @return {Object}
     */
    function convertArrayToMapping(array, keyName, valueName) {
      var mapping = {};

      for (var i = 0; i < array.length; i++) {
        var item = array[i];

        mapping[item[keyName]] = item[valueName];
      }

      return mapping;
    }

    /**
     * Конвертация объекта (ключи и значения объекта должны быть примитивными типами) в массив объектов
     *
     * @example
     * // вернёт [{'newKey1': 'key1', 'newKey2': 'value1'}, {'newKey1': 'key2', 'newKey2': 'value2'}]
     * convertMappingToArray({'key1': 'value1', 'key2': 'value2'}, 'newKey1', 'newKey2');
     *
     * @param {Object} mapping Объект для конвертации
     * @param {String} keyName Название ключа элемента массива, значение которого будет являться ключом объекта
     * @param {String} valueName Название ключа элемента массива, значение которого будет являться значением объекта
     * @return {Array.<Object>}
     */
    function convertMappingToArray(mapping, keyName, valueName) {
      var array = [];

      for (var key in mapping) {
        if (mapping.hasOwnProperty(key)) {
          var arrayItem = {};

          arrayItem[keyName] = key;
          arrayItem[valueName] = mapping[key];

          array.push(arrayItem);
        }
      }

      return array;
    }

    /**
     * Создание новых типов событий
     *
     * @param {Array.<Object>} leadsEvents Типы событий, которые надо создать
     */
    function createEventTypes(leadsEvents) {
      var eventTypesNames = leadsEvents.map(mapEventTypes);

      firstValueFrom(eventTypeModel.create(currentApp.id, eventTypesNames)).then();

      function mapEventTypes(leadsEvent) {
        return leadsEvent.event.prop;
      }
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {form.FormController} form Контроллер формы
     */
    function createOrUpdateIntegration(form) {
      if (form) {
        form.$commitViewValue();
        form.$setSubmitted();

        if (!form.$valid) {
          return $q.reject();
        }
      }

      integration.settings.lastCompletedStep =
        integration.settings.lastCompletedStep > vm.currentStep.ORDER
          ? integration.settings.lastCompletedStep
          : vm.currentStep.ORDER;

      parseBitrix24IntegrationToServerFormat(integration);

      if (integration.id) {
        return integrationModel.save(currentApp.id, integration).then(saveSuccess);
      } else {
        return integrationModel.create(currentApp.id, integration).then(createSuccess);
      }

      function createSuccess(integration) {
        var params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        var transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess(integration) {
        parseBitrix24IntegrationToInternalFormat(integration);
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Фильтрация событий из общего списка свойств и событий
     *
     * @param propMapping
     * @return {Boolean}
     */
    function filterEvents(propMapping) {
      if (angular.equals({}, propMapping) || propMapping.cqName.indexOf('$event_') == 0) {
        return true;
      }
    }

    /**
     * Фильтрация свойств из общего списка свойств и событий
     *
     * @param propMapping
     * @return {Boolean}
     */
    function filterProps(propMapping) {
      if (angular.equals({}, propMapping) || propMapping.cqName.indexOf('$event_') == -1) {
        return true;
      }
    }

    /**
     * Получение шага из массива/объекта шагов steps по индексу index
     *
     * @param {Object|Array.<Object>} steps Массив/объект шагов, из которых осуществялется выборка
     * @param {Number} index Индекс шага (шаги упорядочены по ORDER, т.е. этот индекс и является ORDER)
     * @return {Object}
     */
    function getStepByIndex(steps, index) {
      var stepsArray = $filter('toArray')(steps);

      // если номер искомого шага больше, чем количество всех шагов - полагаем, что надо найти последний шаг
      index = index > stepsArray.length - 1 ? stepsArray.length - 1 : index;

      var step = $filter('filter')(stepsArray, { ORDER: index }, true)[0];
      if (step) {
        return step;
      } else {
        return $filter('filter')(stepsArray, { ORDER: 0 }, true)[0];
      }
    }

    /**
     * Загрузка из Б24 данные по свойствам и воронкам
     */
    function loadBitrix24Data() {
      return $q
        .all([
          integrationModel.bitrix24.getDealPipelines(integration.id),
          integrationModel.bitrix24.getDealProps(integration.id),
          integrationModel.bitrix24.getLeadPipelines(integration.id),
          integrationModel.bitrix24.getLeadProps(integration.id),
        ])
        .then(loadBitrix24DataSuccess);

      function loadBitrix24DataSuccess(result) {
        vm.dealsPipelines = result[0];
        vm.bitrix24DealsProps = result[1];
        vm.pipelines = result[2];
        vm.bitrix24Props = result[3];
      }
    }

    /**
     * Создание соотношений между воронками и событиями
     *
     * @param {Array.<Object>} pipelines Воронки
     * @param {Array.<Object>} cqEvents События
     * @param {String} pipelineType Тип воронок (сделка или лид)
     */
    function matchPipelinesWithLeadsEvents(pipelines, leadsEvents, pipelineType) {
      for (var i = 0; i < pipelines.length; i++) {
        var pipeline = pipelines[i];
        // вначале пытаемся найти событие, соответствующее воронке
        var leadsEvent = $filter('filter')(
          leadsEvents,
          {
            event: {
              statusId: pipeline.id,
            },
          },
          true,
        )[0];

        // если такого не нашлось - создаём его
        if (angular.isUndefined(leadsEvent)) {
          leadsEvent = {
            event: {
              statusId: pipeline.id,
              prop:
                $translate.instant('integrations.bitrix24.controller.pipelines.eventsTemplate.crmName') +
                ': ' +
                pipelineType +
                ' - ' +
                pipeline.name,
            },
            eventProps: [],
            checked: false,
            alreadyCreated: false,
          };
        }

        pipeline.leadsEvent = leadsEvent;
      }
    }

    /**
     * Тост при успешном копировании ссылки на приложение
     */
    function onCopyUrlSuccess() {
      toastr.success($translate.instant('general.linkCopied'));
    }

    /**
     * Открытие модалки для редактирования соответствия статуса лида/сделки Битрикс24 и события в кэрроте.
     * (Свойства событий можно задать только в модальном окне)
     *
     * @param pipeline Статус лида/сделки Битрикс24, для коорого настраивается соответствие
     * @param pipelineType Тип статусов (лид или сделка)
     */
    function openEditStatus(pipeline, pipelineType) {
      var modalInstance = $uibModal.open({
        controller: 'EditBitrix24StatusModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/edit-bitrix24-status/edit-bitrix24-status.html',
        size: 'lg',
        resolve: {
          bitrix24Props:
            pipelineType ==
            $translate.instant('integrations.bitrix24.step4.step4Form.leadsEventsMapping.pipelines.eventTemplate')
              ? angular.bind(null, angular.identity, angular.copy(vm.bitrix24Props))
              : angular.bind(null, angular.identity, angular.copy(vm.bitrix24DealsProps)), // список событий копируется из-за того, что в модалке в этот список добавляются фиктивные элементы
          eventTypes: angular.bind(null, angular.identity, angular.copy(vm.eventTypes)), // список воронок копируется из-за того, что применяться изменения должны не сразу, а только после подтверждения в модалке
          pipeline: angular.bind(null, angular.identity, angular.copy(pipeline)),
          pipelineType: angular.bind(null, angular.identity, angular.copy(pipelineType)),
        },
      });

      modalInstance.result.then(editStatusSuccess);

      function editStatusSuccess(resultPipeline) {
        // просто так мерджить нельзя, потому что событие и набор свойств могли измениться полностью, в т.ч. свойства могли удалить
        pipeline.leadsEvent.checked = resultPipeline.leadsEvent.checked;
        pipeline.leadsEvent.event = resultPipeline.leadsEvent.event;
        pipeline.leadsEvent.eventProps = resultPipeline.leadsEvent.eventProps;
      }
    }

    /**
     * Парсинг интеграции во внутренний формат админки
     *
     * @param {Object} integration Интеграция
     */
    function parseBitrix24IntegrationToInternalFormat(integration) {
      integration.settings.eventTypeIds = angular.isDefined(integration.settings.eventTypeIds)
        ? integration.settings.eventTypeIds
        : [];
      vm.propsMapping = angular.isDefined(integration.settings.propsMapping)
        ? $filter('filter')(
            convertMappingToArray(integration.settings.propsMapping, 'cqName', 'bitrixName'),
            filterProps,
          )
        : [];
      vm.eventsPropsMapping = angular.isDefined(integration.settings.propsMapping)
        ? $filter('filter')(
            convertMappingToArray(integration.settings.propsMapping, 'cqName', 'bitrixName'),
            filterEvents,
          )
        : [];
      vm.standardProps = angular.isDefined(integration.settings.bitrix24StandardProps)
        ? convertMappingToArray(integration.settings.bitrix24StandardProps, 'cqName', 'checked')
        : angular.copy(STANDARD_BITRIX24_PROPS_DEFAULT);
      vm.dealsStandardProps = angular.isDefined(integration.settings.bitrix24DealsStandardProps)
        ? convertMappingToArray(integration.settings.bitrix24DealsStandardProps, 'cqName', 'checked')
        : angular.copy(STANDARD_BITRIX24_PROPS_DEFAULT);
      vm.bitrix24PropsMapping = angular.isDefined(integration.settings.bitrix24PropsMapping)
        ? convertMappingToArray(integration.settings.bitrix24PropsMapping, 'cqName', 'bitrixName')
        : [];
      vm.bitrix24DealsPropsMapping = angular.isDefined(integration.settings.bitrix24DealsPropsMapping)
        ? convertMappingToArray(integration.settings.bitrix24DealsPropsMapping, 'cqName', 'bitrixName')
        : [];
      vm.leadsEventsMapping = angular.isDefined(integration.settings.eventsMapping)
        ? parseEventsMappingToInternalFormat(integration.settings.eventsMapping)
        : [];
      vm.dealsEventsMapping = angular.isDefined(integration.settings.dealsEventsMapping)
        ? parseEventsMappingToInternalFormat(integration.settings.dealsEventsMapping)
        : [];
    }

    /**
     * Парсинг интеграции в формат сервера
     *
     * @param {Object} integration Интеграция
     */
    function parseBitrix24IntegrationToServerFormat(integration) {
      integration.settings.propsMapping = angular.extend(
        {},
        convertArrayToMapping(vm.propsMapping, 'cqName', 'bitrixName'),
        convertArrayToMapping(vm.eventsPropsMapping, 'cqName', 'bitrixName'),
      );
      integration.settings.bitrix24DealsStandardProps = convertArrayToMapping(
        vm.dealsStandardProps,
        'cqName',
        'checked',
      );
      integration.settings.bitrix24StandardProps = convertArrayToMapping(vm.standardProps, 'cqName', 'checked');
      integration.settings.bitrix24PropsMapping = convertArrayToMapping(
        vm.bitrix24PropsMapping,
        'cqName',
        'bitrixName',
      );
      integration.settings.bitrix24DealsPropsMapping = convertArrayToMapping(
        vm.bitrix24DealsPropsMapping,
        'cqName',
        'bitrixName',
      );
      integration.settings.eventsMapping = parseEventsMappingToServerFormat(vm.leadsEventsMapping);
      integration.settings.dealsEventsMapping = parseEventsMappingToServerFormat(vm.dealsEventsMapping);
      integration.settings.bitrix24DealsPropsMapping = convertArrayToMapping(
        vm.bitrix24DealsPropsMapping,
        'cqName',
        'bitrixName',
      );

      // нужно создать события для всех отмеченных статусов bitrix24
      var newLeadsEvents = $filter('filter')(vm.leadsEventsMapping, { checked: true }, true);
      if (newLeadsEvents.length) {
        createEventTypes(newLeadsEvents);
      }

      var newDealsEvents = $filter('filter')(vm.dealsEventsMapping, { checked: true }, true);
      if (newDealsEvents.length) {
        createEventTypes(newDealsEvents);
      }
    }

    /**
     * Парсинг событий во внутренний формат админки
     *
     * @return {Array}
     */
    function parseEventsMappingToInternalFormat(eventsMapping) {
      var result = [];

      for (var i = 0; i < eventsMapping.length; i++) {
        var event = eventsMapping[i];
        var parsedEvent = {
          event: {},
          eventProps: [],
          checked: false,
          alreadyCreated: true,
        };

        for (var key in event.event) {
          if (event.event.hasOwnProperty(key)) {
            parsedEvent.event.statusId = key;
            parsedEvent.event.prop = event.event[key];
          }
        }

        for (var key in event.eventProps) {
          if (event.eventProps.hasOwnProperty(key)) {
            parsedEvent.eventProps.push({
              cqName: key,
              bitrixName: event.eventProps[key],
            });
          }
        }

        parsedEvent.checked = event.checked;

        result.push(parsedEvent);
      }
      return result;
    }

    /**
     * Парсинг событий в формат сервера
     *
     * @return {Array}
     */
    function parseEventsMappingToServerFormat(eventsMapping) {
      var result = [];

      for (var i = 0; i < eventsMapping.length; i++) {
        var eventMapping = eventsMapping[i];
        var parsedEventsMapping = {
          event: {},
          eventProps: {},
          checked: false,
        };

        parsedEventsMapping.event[eventMapping.event.statusId] = eventMapping.event.prop;

        for (var j = 0; j < eventMapping.eventProps.length; j++) {
          var eventProp = eventMapping.eventProps[j];
          parsedEventsMapping.eventProps[eventProp.cqName] = eventProp.bitrixName;
        }

        parsedEventsMapping.checked = eventMapping.checked;

        result.push(parsedEventsMapping);
      }

      return result;
    }

    /**
     * Удаление объекта из массива
     *
     * @param array
     * @param item
     */
    function removeMappingItem(array, item) {
      var index = array.indexOf(item);

      if (~index) {
        array.splice(index, 1);
      }
    }

    /**
     * Вкд/выкл отправку события в кэррот при переходе лида б24 в выбранный статус
     *
     * @param leadsEvent Соответствие статуса и события в кэрроте, которое будет отправлено при переходе в этот статус
     */
    function toggleLeadsEvent(leadsEvent) {
      if (leadsEvent.checked && !leadsEvent.alreadyCreated) {
        vm.leadsEventsMapping.push(leadsEvent);
      } else if (!leadsEvent.alreadyCreated && !leadsEvent.alreadyCreated) {
        vm.leadsEventsMapping.splice(vm.leadsEventsMapping.indexOf(leadsEvent), 1);
      }
    }

    /**
     * Вкд/выкл отправку события в кэррот при переходе сделки б24 в выбранный статус
     *
     * @param leadsEvent Соответствие статуса и события в кэрроте, которое будет отправлено при переходе в этот статус
     */
    function toggleDealsLeadsEvent(leadsEvent) {
      if (leadsEvent.checked && !leadsEvent.alreadyCreated) {
        vm.dealsEventsMapping.push(leadsEvent);
      } else if (!leadsEvent.alreadyCreated && !leadsEvent.alreadyCreated) {
        vm.dealsEventsMapping.splice(vm.dealsEventsMapping.indexOf(leadsEvent), 1);
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

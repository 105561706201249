(function () {
  'use strict';

  require('./widget-avatar.controller');

  angular.module('myApp.chatSettings').component('cqWidgetAvatar', cqWidgetAvatar());

  function cqWidgetAvatar() {
    return {
      bindings: {
        currentAppName: '=', // Имя текущего аппа
        newAvatar: '=', // новый аватар загруженный пользователем
        newAvatarBlob: '=', // blob пользовательсого аватара
        newAvatarSrc: '=', // src пользовательсого аватара
      },
      controller: 'CqWidgetAvatarController',
      controllerAs: 'vm',
      template: require('./widget-avatar.html'),
    };
  }
})();

import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  UntypedFormBuilder,
  ValidatorFn,
} from '@angular/forms';

import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroupV2 } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

type DeepControlsConfig<T extends object> = {
  [K in keyof T]: T[K] extends object ? (T[K] extends Array<infer E> ? T[K] : DeepControlsConfig<T[K]>) : T[K] | null;
};

export type ControlsConfig<T extends object = {}> = {
  [K in keyof T]: T[K] extends object
    ? T[K] extends Array<infer E>
      ? T[K]
      : DeepControlsConfig<T[K]>
    : T[K] | GenericFormControl<T[K]> | null;
};

@Injectable({
  providedIn: 'root',
})
export class GenericFormBuilder extends UntypedFormBuilder {
  constructor() {
    super();
  }

  group<T extends object>(
    controlsConfig: ControlsConfig<T>,
    options?: AbstractControlOptions | null,
  ): GenericFormGroupV2<T> {
    return super.group(controlsConfig, options) as GenericFormGroupV2<T>;
  }

  control<T>(
    formState: T | null,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ): GenericFormControl<T> {
    return super.control(formState, validatorOrOpts, asyncValidator) as GenericFormControl<T>;
  }

  array<T extends AbstractControl>(
    controlsConfig: any[],
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ): GenericFormArray<T> {
    return super.array(controlsConfig, validatorOrOpts, asyncValidator) as GenericFormArray<T>;
  }
}

import { InjectionToken } from '@angular/core';

import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { PlanTotalPrices, ScaledAddonPrice } from '@panel/app/pages/subscription/pricing/pricing.service';
import { V3_PLAN } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.types';
import { PLANS_PERIODS } from '@panel/app/services/billing-plan/billing-plan.constants';

export type PlanChangeConfirmModalData = {
  addons: ScaledAddonPrice[];
  currentApp: App;
  djangoUser: DjangoUser;
  planId: V3_PLAN;
  price: PlanTotalPrices;
  paymentPeriod: PLANS_PERIODS;
};

export const PLAN_CHANGE_CONFIRM_MODAL_TOKEN = new InjectionToken<PlanChangeConfirmModalData>(
  'Information to be shown in preview before committing to subscription',
);

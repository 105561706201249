import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { StarterGuideCreatingLeadbotComponent } from './starter-guide-creating-leadbot.component';

@NgModule({
  declarations: [StarterGuideCreatingLeadbotComponent],
  exports: [StarterGuideCreatingLeadbotComponent],
  imports: [CommonModule, NgbAlertModule, NgOptimizedImage, TranslocoModule],
})
export class StarterGuideCreatingLeadbotModule {}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class EqualValidator {
  static equal(compareToField: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Получаем значение для сравнения из родительской формы
      if (!control.parent || !control.parent.get(compareToField)) {
        // Проверяем, равны ли значения
        if (control.value !== compareToField) {
          // Возвращаем объект ошибки, если значения не равны
          return { equal: false };
        }

        return null;
      }

      const compareToValue = control.parent.get(compareToField)?.value;
      const controlValue = control.value;

      // Проверяем, равны ли значения
      if (controlValue !== compareToValue) {
        // Возвращаем объект ошибки, если значения не равны
        return { equal: false };
      }

      return null; // Возвращаем null, если значения равны (нет ошибки)
    };
  }
}

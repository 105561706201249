/**
 * Компонент для работы с телефоном пользователя в завершении регистрации
 */

(function () {
  'use strict';

  require('./completion-registration-form-control-phone.controller');

  angular
    .module('myApp.completionRegistration')
    .component('cqCompletionRegistrationFormControlPhone', cqCompletionRegistrationFormControlPhone());

  function cqCompletionRegistrationFormControlPhone() {
    return {
      bindings: {
        /** Форма завершения регистрации */
        completionRegistrationForm: '=',
        /** Будет ли поле обязательным */
        isRequiredMode: '=',
        /** Телефон пользователя */
        userPhone: '=',
      },
      controller: 'cqCompletionRegistrationFormControlPhoneController',
      controllerAs: 'vm',
      template: require('./completion-registration-form-control-phone.html'),
    };
  }
})();

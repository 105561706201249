<ng-container *transloco="let t; read: 'userNotesListComponent'">
  <div class="no-margin-bottom">
    <div
      (click)="collapse.toggle(); collapseStateChange.emit(userNotesLSName)"
      [attr.aria-expanded]="!isCollapsed"
      class="flex align-items-center cursor-pointer"
      aria-controls="collapse"
    >
      <div class="font-weight-bold flex-grow-1 padding-15">{{ t('userNotes') }}</div>
      <div class="margin-right-5">{{ userNotesCount }}</div>
      <i
        [class]="{ 'cqi-chevron-down': isCollapsed, 'cqi-chevron-up': !isCollapsed }"
        class="btn-icon cqi-sm margin-right-15"
      ></i>
    </div>
    <div
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
    >
      <div class="card-body no-padding-top padding-right-10 padding-bottom-15 padding-left-10">
        <div
          class="flex align-items-center mb-5"
          *ngIf="isShowShowAllNotesButton()"
        >
          <button
            class="btn btn-text-primary ml-auto"
            (click)="toggleVisibleUserNotesFilter()"
            type="button"
            data-test="user-notes-list-show-all-button"
          >
            <span>{{ t('showAllButton') }}</span>
            <i
              class="btn-icon btn-icon-right cqi-sm cqi-chevron-down"
              [ngClass]="{ 'cqi-chevron-down': !isShowAllNotes, 'cqi-chevron-up': isShowAllNotes }"
            ></i>
          </button>
        </div>
        <div class="margin-between-rows-10 mb-10">
          <ng-container *ngIf="orderedNotesList.length; else zeroData">
            <cq-user-note
              (removeNote)="onRemoveNote($event)"
              *ngFor="let note of orderedNotesList | slice: 0:visibleUserNotesLimit"
              [note]="note"
            ></cq-user-note>
          </ng-container>
          <ng-template #zeroData>
            <div class="text-muted padding-left-5">{{ t('zeroData') }}</div>
          </ng-template>
        </div>
        <form
          (ngSubmit)="addUserNote()"
          autocomplete="off"
        >
          <div class="form-group no-margin-bottom padding-left-5">
            <input
              class="form-control form-control-sm"
              [attr.disabled]="isRequestPerformed ? true : null"
              [formControl]="controlUserNote"
              [placeholder]="t('addNoteForm.noteInput.placeholder')"
              name="note"
              required
            />
          </div>
          <input
            class="hidden"
            type="submit"
          />
        </form>
      </div>
    </div>
  </div>
</ng-container>

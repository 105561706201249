import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VOTES } from 'app/http/vote/vote.constants';
import moment from 'moment';
import { defaultIfEmpty, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { UserModel } from '@http/user/user.model';
import { CaseStyleHelper } from '@panel/app/services';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

/**
 * Сервис для работы с оценкой диалогов
 */
@Injectable({ providedIn: 'root' })
export class VoteModel {
  constructor(
    private readonly http: HttpClient,
    private readonly userModel: UserModel,
    private readonly caseStyleHelper: CaseStyleHelper,
  ) {}

  /**
   * Получение дефолтной статистики
   */
  getDefault() {
    const voteStats: any = {};
    for (let vote in VOTES) {
      if (VOTES.hasOwnProperty(vote)) {
        voteStats[VOTES[vote as keyof typeof VOTES]] = 0;
      }
    }
    return voteStats;
  }

  /**
   * Получение списка оценок операторов
   *
   * @param appId ID аппа
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param vote Оценка
   * @param channelsIds ID'ы каналов
   * @param teamMemberId ID оператора
   * @param paginatorParams Параметры пагинации
   */
  getList(
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    vote: string,
    channelsIds: string[],
    teamMemberId: string,
    paginatorParams: any,
  ) {
    const {
      paginateDirection = 'after',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    const params: any = {
      django_user: teamMemberId,
      end_date: endDate.format('YYYY-MM-DD'),
      start_date: startDate.format('YYYY-MM-DD'),
      vote: vote,
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition.join() || null,
      paginate_page_order: paginatePageOrder,
    };

    // если статистика запрашивается для псевдоканала "Все каналы" - на бэк ничего посылать не нужно, тогда он вернёт стату по всем каналам
    if (!(channelsIds.length === 1 && channelsIds[0] === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS])) {
      params.channels = JSON.stringify(channelsIds);
    }

    return this.http
      .get<any>('/apps/' + appId + '/votes', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
      })
      .pipe(
        mergeMap((response) => {
          // доверим парсинг пользователя модели userModel
          this.caseStyleHelper.keysToCamelCase(response, ['user']);
          const parsePromises = [];

          for (let i = 0; i < response.data.length; i++) {
            parsePromises.push(this.parse(response.data[i]));
          }

          return forkJoin(parsePromises).pipe(
            defaultIfEmpty([]),
            map((votes) => {
              return {
                votes: votes,
                paginatorParams: {
                  paginateDirection,
                  paginateCount,
                  paginateIncluding,
                  paginatePageOrder,
                  paginatePosition: response.meta.nextAfterPosition,
                },
              };
            }),
          );
        }),
      );
  }

  /**
   * Получение статистики по оценкам
   *
   * @param appId ID аппа
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param channelsIds ID'ы каналов
   * @param teamMemberId ID оператора
   */
  getStatistics(
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    channelsIds: string[],
    teamMemberId: string,
  ) {
    const params: any = {
      django_user: teamMemberId,
      end_date: endDate.format('YYYY-MM-DD'),
      start_date: startDate.format('YYYY-MM-DD'),
    };

    // если статистика запрашивается для псевдоканала "Все каналы" - на бэк ничего посылать не нужно, тогда он вернёт стату по всем каналам
    if (!(channelsIds.length === 1 && channelsIds[0] === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS])) {
      params.channels = JSON.stringify(channelsIds);
    }

    return this.http.get<any>('/apps/' + appId + '/vote_stats', { params }).pipe(
      map((data) => {
        return this.parseStatistics(data);
      }),
    );
  }

  /**
   * Парсинг оценки
   *
   * @param {Object} vote Оценка
   */
  private parse(vote: any) {
    vote.created = moment(vote.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    // NOTE: тут ещё должен быть парсинг vote.participants, в котором, судя по всему, должен приходить список членов команды, но сейчас там приходит какая-то чушь, поэтому парсинга тут нет

    return this.userModel.parseUser(vote.user).pipe(
      map((user) => {
        vote.user = user;

        return vote;
      }),
    );
  }

  /**
   * Парсинг статистики по оценкам
   *
   * @param voteStats
   */
  private parseStatistics(voteStats: any) {
    const defaultVoteStats = this.getDefault();

    for (let vote in VOTES) {
      if (VOTES.hasOwnProperty(vote)) {
        defaultVoteStats[VOTES[vote as keyof typeof VOTES]] = angular.isDefined(
          voteStats[VOTES[vote as keyof typeof VOTES]],
        )
          ? voteStats[VOTES[vote as keyof typeof VOTES]]
          : defaultVoteStats[VOTES[vote as keyof typeof VOTES]];
      }
    }

    return defaultVoteStats;
  }
}

import { Injectable } from '@angular/core';
import moment from 'moment';

import { CaseStyleHelper } from '@panel/app/services';

@Injectable({ providedIn: 'root' })
export class EmailStatusModel {
  constructor(private readonly caseStyleHelper: CaseStyleHelper) {}

  /**
   * Парсинг статуса емейла
   *
   * @param emailStatus Статус емейла
   *
   * TODO Сменить any на не распаршенный UserEmailStatus
   */
  public parse(emailStatus: any): void {
    this.caseStyleHelper.keysToCamelCase(emailStatus);

    emailStatus.updated = moment(emailStatus.updated, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  }
}

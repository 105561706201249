import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';

import { IntegrationIconModule } from '../../../pages/integrations/integration-icon/integration-icon.module';
import { ServiceInstallationIntegrationsComponent } from './service-installation-integrations.component';

@NgModule({
  declarations: [ServiceInstallationIntegrationsComponent],
  exports: [ServiceInstallationIntegrationsComponent],
  imports: [CommonModule, TranslocoModule, IntegrationIconModule],
  providers: [CarrotquestHelperProvider],
})
export class ServiceInstallationIntegrationsModule {}

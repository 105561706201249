<ng-container *transloco="let t; read: 'stripe3DSModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ t('title') }}
    </h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="stripe-payments">
      <div
        *ngIf="!is3DSReady"
        class="text-center"
      >
        <i class="cqi-sm cqi-spinner inline-block spin text-success"></i>
      </div>
      <div
        *ngIf="is3DSReady"
        [id]="THREE_DS_ID"
      ></div>
    </div>
  </div>
</ng-container>

import { AppModel } from '@http/app/app.model';

/**
 * Сервис, для того, чтобы не приходили уведомления о диалогах в iOS и Android-приложениях, а также на почту
 * Логика старого варианта не продумывалась, а почти подчистую перенесена из app.config.js
 * В будущем нужно будет сделать оптимальнее/по-другому
 */
export class MuteNotificationsService {
  //@ngInject
  constructor(
    private readonly $window: Window,
    private readonly appModel: AppModel,
    private readonly desktopNotification: any, // тут тип должен быть не any, но сервис desktopNotification ещё не переписан на typescript
  ) {
    this.boundOnActivity = this.onActivity.bind(this);
  }

  private appId!: string;

  /**
   * функция для передачи в addEventListener и removeEventListener. По-другому из классов по-нормальному слушателя не провесить
   * https://medium.com/@bigcatplichta/javascript-use-bind-to-dynamically-add-and-remove-event-listeners-d6b443877a73
   */
  private boundOnActivity: () => void;

  /**
   * массив с ID диалогов, для которых нужно замьютить уведомления
   */
  private conversationsIds: readonly string[] = [];

  /**
   * список событий, при возникновении которых нужно мьютить уведомления
   */
  private readonly events: readonly string[] = ['mousemove', 'click', 'scroll', 'keydown', 'touchstart'];

  /**
   * функция, вызываемая при возникновении событий из this.events
   */
  private onActivity() {
    this.desktopNotification.turnOffInfiniteSound();
    this.desktopNotification.setReadTitle();

    if (this.conversationsIds.length > 0) {
      // кажется, этот apply тут не нужен, т.к. никаких изменений в scope не происходит и триггерить digest-цикл не нужно
      // $rootScope.$apply(function () {
      this.appModel.muteNotifications(this.appId, this.conversationsIds).subscribe();
      // });
    }

    // можно очистить список ID диалогов для которых уведомления замьючены
    this.conversationsIds = [];
  }

  /**
   * начать слушать события для мьюта уведомлений
   *
   * @param appId ID приложения
   */
  start(appId: string) {
    this.appId = appId;

    for (let i = 0; i < this.events.length; i++) {
      this.$window.addEventListener(this.events[i], this.boundOnActivity);
    }
  }

  /**
   * закончить слушать события для мьюта уведомлений
   */
  stop() {
    for (let i = 0; i < this.events.length; i++) {
      this.$window.removeEventListener(this.events[i], this.boundOnActivity);
    }
  }

  /**
   * Добавление ID диалога для последующего мьюта нотификаций
   *
   * @param id ID диалога
   */
  addConversationId(id: string) {
    this.conversationsIds = [...this.conversationsIds, id];
  }
}

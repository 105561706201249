import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationService } from '@http/integration/services/integration.service';
import { OAuthButtonModule } from '@panel/app/pages/integrations/partials/o-auth-button/o-auth-button.module';
import { SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

import { VkOAuthContainerComponent } from './vk-o-auth-container.component';

@NgModule({
  declarations: [VkOAuthContainerComponent],
  exports: [VkOAuthContainerComponent],
  imports: [CommonModule, TranslocoModule, OAuthButtonModule],
  providers: [IntegrationService, SystemToastService, ToastService],
})
export class VkOAuthContainerModule {}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп для трансформации числа в денежное значение
 *
 * Пример:
 *  12 => 0.12
 *  1234 = > 12.34
 */

@Pipe({
  name: 'toMoney',
})
export class ToMoneyPipe implements PipeTransform {
  transform(value: number | string): number {
    return +value / 100;
  }
}

/**
 * Контроллер для таблицы членов команды
 */
import { firstValueFrom } from 'rxjs';
import {
  REMOVE_TEAM_MEMBER_ERRORS,
  TEAM_MEMBER_PERMISSIONS,
} from '../../../../app/http/team-member/team-member.constants';
import { AssignNewSuperAdminModalComponent } from '../../../../app/pages/account/shared/assign-new-super-admin-modal/assign-new-super-admin-modal.component';

(function () {
  'use strict';

  angular.module('myApp.teamMembers').controller('CqTeamMembersTableController', CqTeamMembersTableController);

  function CqTeamMembersTableController(
    $filter,
    $q,
    $state,
    $translate,
    $uibModal,
    $window,
    ipCookie,
    toastr,
    PROJECT_NAME,
    carrotquestHelper,
    teamMemberHelpService,
    teamMemberModel,
    utilsService,
    modalHelperService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.currentTeamMember = $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true)[0]; // текущий пользователь админки FIXME currentApp.admins: в будущем, когда работа внутри app будет осуществляться не через djangoUser, а через teamMember - выпилить это, т.к. teamMember будет резолвиться в состоянии app
      vm.getIconClassForPermission = getIconClassForPermission;
      vm.getIconClassForPermissionExport = getIconClassForPermissionExport;
      vm.getIconClassForPermissionSendBulk = getIconClassForPermissionSendBulk;
      vm.getNameForPermission = getNameForPermission;
      vm.getNameForPermissionExport = getNameForPermissionExport;
      vm.getNameForPermissionSendBulk = getNameForPermissionSendBulk;
      vm.openEditTeamMemberModal = openEditTeamMemberModal;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.removeTeamMember = removeTeamMember;
      vm.TEAM_MEMBER_PERMISSIONS = TEAM_MEMBER_PERMISSIONS;
      vm.teamMemberModel = teamMemberModel;
      vm.trackClickEditTeamMember = trackClickEditTeamMember;
      vm.trackClickRemoveTeamMember = trackClickRemoveTeamMember;
    }

    /**
     * Получение класса для иконки обозначающей права пользователя
     *
     * @param {TEAM_MEMBER_PERMISSIONS} permission - Права пользователя
     *
     * @return {string}
     */
    function getIconClassForPermission(permission) {
      return teamMemberHelpService.getIconClassForPermission(permission);
    }

    /**
     * Получение класса для иконки прав на экспорт
     *
     * @return {string}
     */
    function getIconClassForPermissionExport() {
      return teamMemberHelpService.getIconClassForPermissionExport();
    }

    /**
     * Получение класса для иконки прав на массовой рассылки
     *
     * @return {string}
     */
    function getIconClassForPermissionSendBulk() {
      return teamMemberHelpService.getIconClassForPermissionSendBulk();
    }

    /**
     * Получение названия для прав пользователя
     *
     * @param {TEAM_MEMBER_PERMISSIONS} permission - Права пользователя
     *
     * @return {string}`
     */
    function getNameForPermission(permission) {
      return teamMemberHelpService.getNameForPermission(permission);
    }

    /**
     * Получение названия для права на экспорт
     *
     * @return {string}
     */
    function getNameForPermissionExport() {
      return teamMemberHelpService.getNameForPermissionExport();
    }

    /**
     * Получение названия для права на массовую рассылку
     *
     * @return {string}
     */
    function getNameForPermissionSendBulk() {
      return teamMemberHelpService.getNameForPermissionSendBulk();
    }

    /**
     * Открытие модалки редактирования члена команды
     *
     * @param {Object} teamMember Член команды
     */
    function openEditTeamMemberModal(teamMember) {
      var teamMemberCopy = angular.copy(teamMember);

      var teamMemberEditorModal = $uibModal.open({
        backdrop: 'static',
        controller: 'TeamMemberEditorController',
        controllerAs: 'vm',
        resolve: {
          billingInfo: angular.bind(null, angular.identity, vm.billingInfo),
          callback: angular.bind(null, angular.identity, saveTeamMember),
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          currentTeamMember: angular.bind(null, angular.identity, vm.currentTeamMember),
          teamMember: angular.bind(null, angular.identity, teamMemberCopy),
          teamMembers: angular.bind(null, angular.identity, vm.teamMembers),
          teamMembersGroups: angular.bind(null, angular.identity, vm.teamMembersGroups),
        },
        templateUrl: 'js/components/team-members/editor/team-member-editor-modal.html',
      });

      teamMemberEditorModal.result.then(saveTeamMemberSuccess);

      function saveTeamMember(editedTeamMember) {
        return firstValueFrom(teamMemberModel.save(vm.currentApp.id, editedTeamMember, vm.currentApp));
      }

      function saveTeamMemberSuccess(teamMember) {
        trackTeamMemberSaved();

        var oldTeamMember = $filter('filter')(vm.teamMembers, { id: teamMember.id }, true)[0];

        angular.extend(oldTeamMember, teamMember);
        toastr.success(
          $translate.instant('teamMembersTable.toasts.teamMemberChanged', { teamMemberName: teamMember.name }),
        );
      }
    }

    /**
     * Удаление члена команды
     *
     * @param {Object} teamMember Член команды
     * @param {Boolean} force - Удаление без проверки
     */
    function removeTeamMember(teamMember, force) {
      firstValueFrom(
        teamMemberModel.remove(vm.currentApp.id, teamMember, vm.currentApp, vm.teamMembers, vm.djangoUser, force),
      )
        .catch(removeError)
        .then(removeSuccess);

      function removeError(error) {
        if (error === REMOVE_TEAM_MEMBER_ERRORS.NO_MORE_SUPER_ADMINS) {
          let assignNewSuperAdminModal = modalHelperService.open(AssignNewSuperAdminModalComponent);

          assignNewSuperAdminModal.componentInstance.modalWindowParams = {
            body:
              '\
                <div class="margin-bottom-20">' +
              $translate.instant('teamMembersTable.removeSelfAsLastSuperAdminModal.body.removeSelf', {
                originCurrentApp: vm.currentApp.origin,
              }) +
              '</div>\
                <div class="margin-bottom-20">' +
              $translate.instant('teamMembersTable.removeSelfAsLastSuperAdminModal.body.chooseNewSuperAdmin') +
              '</div>',
            appId: vm.currentApp.id,
            currentApp: vm.currentApp,
            teamMember: teamMember,
            teamMembers: vm.teamMembers,
            currentAppName: vm.currentApp.name,
          };

          assignNewSuperAdminModal.result.then(angular.bind(null, removeTeamMember, teamMember, true));
        } else if (error === REMOVE_TEAM_MEMBER_ERRORS.NO_MORE_TEAM_MEMBERS) {
          var removeSelfAsLastTeamMemberModal = $uibModal.open({
            controller: 'SafeRemoveModalController',
            controllerAs: 'vm',
            resolve: {
              heading: angular.bind(
                null,
                angular.identity,
                $translate.instant('teamMembersTable.removeSelfAsLastTeamMemberModal.heading'),
              ),
              body: angular.bind(
                null,
                angular.identity,
                '\
                    <div class="margin-bottom-20">' +
                  $translate.instant('teamMembersTable.removeSelfAsLastTeamMemberModal.body.removeSelf', {
                    originCurrentApp: vm.currentApp.origin,
                  }) +
                  '</div>\
                    <div class="margin-bottom-20">' +
                  $translate.instant('teamMembersTable.removeSelfAsLastTeamMemberModal.body.noOneCanAccess', {
                    projectName: PROJECT_NAME,
                  }) +
                  '</div>\
                    <div>' +
                  $translate.instant('teamMembersTable.removeSelfAsLastTeamMemberModal.body.areYouSure') +
                  '</div>\
                  ',
              ),
              confirmButtonText: angular.bind(
                null,
                angular.identity,
                $translate.instant('teamMembersTable.removeSelfAsLastTeamMemberModal.confirmButtonText'),
              ),
              confirmText: angular.bind(
                null,
                angular.identity,
                $translate.instant('teamMembersTable.removeSelfAsLastTeamMemberModal.confirmText', {
                  currentAppName: vm.currentApp.name,
                }),
              ),
              confirmedItemName: angular.bind(null, angular.identity, vm.currentApp.name),
            },
            templateUrl: 'js/shared/modals/safe-remove/safe-remove.html',
          });

          removeSelfAsLastTeamMemberModal.result.then(angular.bind(null, removeTeamMember, teamMember, true));
        } else if (error === REMOVE_TEAM_MEMBER_ERRORS.REMOVE_TEAM_MEMBER) {
          var removeTeamMemberModal = $uibModal.open({
            controller: 'ConfirmModalController',
            controllerAs: 'vm',
            resolve: {
              modalWindowParams: function () {
                return {
                  heading: $translate.instant('teamMembersTable.removeTeamMemberModal.heading'),
                  body: $translate.instant('teamMembersTable.removeTeamMemberModal.body', {
                    teamMemberName: teamMember.name,
                  }),
                  confirmButtonText: $translate.instant('general.remove'),
                };
              },
            },
            templateUrl: 'js/shared/modals/confirm/confirm.html',
          });

          removeTeamMemberModal.result.then(angular.bind(null, removeTeamMember, teamMember, true));
        } else if (error === REMOVE_TEAM_MEMBER_ERRORS.REMOVE_SELF) {
          var removeSelfModal = $uibModal.open({
            controller: 'SafeRemoveModalController',
            controllerAs: 'vm',
            resolve: {
              heading: angular.bind(
                null,
                angular.identity,
                $translate.instant('teamMembersTable.removeSelfModal.heading'),
              ),
              body: angular.bind(
                null,
                angular.identity,
                '\
                    <div>' +
                  $translate.instant('teamMembersTable.removeSelfModal.body.removeSelf', {
                    originCurrentApp: vm.currentApp.origin,
                    projectName: PROJECT_NAME,
                  }) +
                  '</div>\
                    <div>' +
                  $translate.instant('teamMembersTable.removeSelfModal.body.areYouSure') +
                  '</div>\
                  ',
              ),
              confirmButtonText: angular.bind(
                null,
                angular.identity,
                $translate.instant('teamMembersTable.removeSelfModal.confirmButtonText'),
              ),
              confirmText: angular.bind(
                null,
                angular.identity,
                $translate.instant('teamMembersTable.removeSelfModal.confirmText', {
                  currentAppName: vm.currentApp.name,
                }),
              ),
              confirmedItemName: angular.bind(null, angular.identity, vm.currentApp.name),
            },
            templateUrl: 'js/shared/modals/safe-remove/safe-remove.html',
          });

          removeSelfModal.result.then(angular.bind(null, removeTeamMember, teamMember, true));
        }
        return $q.reject();
      }

      function removedSelf() {
        trackSelfRemoved();
        toastr.success(
          $translate.instant('teamMembersTable.toasts.selfRemoved', { originCurrentApp: vm.currentApp.origin }),
        );

        // NOTE: При удалении себя из аппа на бэкенде изменяются права пользователя, и, как следствие, api token
        //  Единственное быстрое решение перезапросить токен - просто выкинуть пользователя, пусть логинится снова, благо операция самоудаления из аппа не такая частая
        // удаление куков для входа в панель
        ipCookie.remove('carrotquest_auth_token_panel', { path: '/' });

        // удаление куки с django user id, которая записана для fullstory
        ipCookie.remove('carrotquest_django_user_id', { path: '/' });

        // перенаправление на страницу логина
        $window.location.href = utilsService.getExternalPagesAbsoluteUrl('login');
      }

      function removedTeamMember() {
        trackTeamMemberRemoved();
        toastr.success(
          $translate.instant('teamMembersTable.toasts.teamMemberRemoved', { teamMemberName: teamMember.name }),
        );
      }

      function removeSuccess() {
        vm.teamMembers.splice(vm.teamMembers.indexOf(teamMember), 1);
        vm.currentTeamMembersCount = vm.teamMembers.length;

        if (teamMember.id === vm.djangoUser.id) {
          removedSelf();
        } else {
          removedTeamMember();
        }
      }
    }

    /**
     * Трек клика на редактирование администратора
     */
    function trackClickEditTeamMember() {
      carrotquestHelper.track('Добавление членов команды - кликнул на "Редактировать администратора"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на удаление администратора
     */
    function trackClickRemoveTeamMember() {
      carrotquestHelper.track('Добавление членов команды - кликнул на "Удалить администратора"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек удаления администратора
     */
    function trackTeamMemberRemoved() {
      carrotquestHelper.track('Добавление членов команды - удалил администратора', { app: vm.currentApp.name });
    }

    /**
     * Трек удаления себя
     */
    function trackSelfRemoved() {
      carrotquestHelper.track('Добавление членов команды - удалил себя', { app: vm.currentApp.name });
    }

    /**
     * Трек сохранения данных об администраторе
     */
    function trackTeamMemberSaved() {
      carrotquestHelper.track('Добавление членов команды - отредактировал администратора', { app: vm.currentApp.name });
    }
  }
})();

<div
  class="scroll-zone position-sticky w-100 top-0"
  #topScrollZone
></div>
<div *ngIf="!isSearch">
  <div *ngIf="savedReplies.length !== 0">
    <table
      class="saved-replies-table table table-align-middle unavailable"
      #savedRepliesTable
      [class]="{ in: !isApiRequestPerformed }"
    >
      <tbody
        [config]="sortablejsOptions"
        [nxtSortablejs]="savedReplies"
      >
        <tr *ngFor="let savedReply of savedReplies; index as i; trackBy: trackById">
          <td class="shrink-by-content">
            <i class="cqi-sm cqi-grip-vertical handle-icon"></i>
          </td>
          <td>
            <div class="flex align-items-center">
              <div class="flex-grow">
                <div
                  class="margin-bottom-5 font-weight-bold text-break"
                  [innerHTML]="savedReply.header"
                ></div>
                <div
                  class="saved-reply-body text-break"
                  [innerHTML]="savedReply.body"
                ></div>
              </div>
              <div
                class="label label-light-secondary margin-left-10"
                *ngIf="i < 9"
                [innerText]="'Ctrl + ' + (i + 1)"
              ></div>
            </div>
          </td>
          <td class="shrink-by-content">
            <button
              class="btn btn-borderless-primary"
              (click)="editSavedReply(savedReply)"
            >
              <i class="btn-icon cqi-sm cqi-pencil"></i>
            </button>
            <button
              class="btn btn-borderless-primary"
              (click)="removeSavedReply(savedReply)"
            >
              <i class="btn-icon cqi-sm cqi-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    class="zero-data-container padding-top-80 margin-right-auto padding-bottom-80 margin-left-auto text-center"
    *ngIf="savedReplies.length === 0"
  >
    <h4 transloco="settings.savedReplies.zeroData.noSavedReplies.title"></h4>
    <div
      class="margin-bottom-20"
      [innerHTML]="'settings.savedReplies.zeroData.noSavedReplies.description' | transloco"
    ></div>
    <button
      class="btn btn-primary"
      (click)="createSavedReply()"
      transloco="settings.savedReplies.zeroData.noSavedReplies.addSavedReplyButton"
    ></button>
  </div>
</div>
<div *ngIf="isSearch">
  <div *ngIf="searchedSavedReplies.length !== 0">
    <table
      class="saved-replies-table table table-align-middle unavailable"
      #savedRepliesTable
      [class]="{ in: !isApiRequestPerformed }"
    >
      <tbody>
        <tr *ngFor="let savedReply of searchedSavedReplies; index as i; trackBy: trackById">
          <td class="shrink-by-content">
            <i class="cqi-sm cqi-grip-vertical"></i>
          </td>
          <td>
            <div
              class="margin-bottom-5 font-weight-bold text-break"
              [innerHTML]="savedReply.headerHeadline"
            ></div>
            <div
              class="saved-reply-body text-break"
              [innerHTML]="savedReply.bodyHeadline"
            ></div>
          </td>
          <td class="shrink-by-content">
            <button
              class="btn btn-borderless-primary"
              (click)="editSavedReply(savedReply)"
            >
              <i class="btn-icon cqi-sm cqi-pencil"></i>
            </button>
            <button
              class="btn btn-borderless-primary"
              (click)="removeSavedReply(savedReply)"
            >
              <i class="btn-icon cqi-sm cqi-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    class="zero-data-container padding-top-80 margin-right-auto padding-bottom-80 margin-left-auto text-center"
    *ngIf="searchedSavedReplies.length === 0"
  >
    <img
      class="margin-bottom-20"
      src="assets/img/default/not-found.png"
    />
    <h4 transloco="settings.savedReplies.zeroData.noSearchedSavedReplies.title"></h4>
    <div>
      <span transloco="settings.savedReplies.zeroData.noSearchedSavedReplies.description"></span>
      <span
        class="text-primary cursor-pointer"
        (click)="resetSearch()"
        transloco="settings.savedReplies.zeroData.noSearchedSavedReplies.resetSearch"
      ></span>
    </div>
  </div>
</div>
<div
  class="scroll-zone position-sticky w-100 bottom-0 margin-top-20-neg"
  #bottomScrollZone
></div>

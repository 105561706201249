import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TgTriggerTypesModule } from '@panel/app/partials/chat-bot/telegram-bot/trigger-types/tg-trigger-types.module';
import { IntegrationSelectModule } from '@panel/app/partials/integration-select/integration-select.module';
import { MessageEditorCollapseModule } from '@panel/app/partials/message-editor/shared/message-editor-collapse/message-editor-collapse.module';

import { TgIntegrationComponent } from './tg-integration.component';

@NgModule({
  declarations: [TgIntegrationComponent],
  imports: [CommonModule, MessageEditorCollapseModule, TgTriggerTypesModule, IntegrationSelectModule, TranslocoModule],
})
export class TgIntegrationModule {}

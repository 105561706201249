(function () {
  'use strict';

  angular.module('myApp.services.myHttpParamSerializer').service('myHttpParamSerializer', myHttpParamSerializer);

  function myHttpParamSerializer() {
    return function serializeData(data) {
      if (!angular.isObject(data)) {
        return data == null ? '' : data.toString();
      }

      var buffer = [];
      for (var name in data) {
        if (!data.hasOwnProperty(name)) {
          continue;
        }

        var value = data[name];
        buffer.push(encodeURIComponent(name) + '=' + encodeURIComponent(value == null ? '' : value));
      }

      // Serialize the buffer and clean it up for transportation.
      var source = buffer.join('&').replace(/%20/g, '+');
      return source;
    };
  }
})();

import { BaseTexture, Renderer, RenderTexture } from '@pixi/core';
import { Container } from '@pixi/display';
import { Graphics } from '@pixi/graphics';

import { BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { PixiTextureBuilderService } from '@panel/app/shared/services/pixi/texture-builder/pixi-texture-builder.service';

import { CARD_WIDTH } from './branch';

const ZERO = 0;
const CARD_BG_COLOR = BOT_WHITE_COLOR;
const SIDE_TEXTURE_HEIGHT = 12;
const SIRE_TEXTURE_ANGLE_LENGTH = 10;
const SIDE_TEXTURE_ANGLE_RADIUS = 10;
// От этой высоты зависит то, во сколько кусков будет собираться один задник для текста.
// Слишком маленьким его делать не стоит, потому что придется много повторений делать.
// Слишком большим, кажется, тоже, потому что тогда из него каждый раз будет собираться новая текстура
const INNER_TEXTURE_HEIGHT = 700;
const BORDER_SIZE = 1;

let cardSideTexture: BaseTexture | null = null;
let cardInnerTexture: BaseTexture | null = null;

function getSideRenderTexture() {
  return RenderTexture.create({
    width: CARD_WIDTH + BORDER_SIZE * 2,
    height: SIDE_TEXTURE_HEIGHT + BORDER_SIZE,
    resolution: 5,
  });
}

function getInnerRenderTexture() {
  return RenderTexture.create({
    width: CARD_WIDTH + BORDER_SIZE * 2,
    height: INNER_TEXTURE_HEIGHT,
    resolution: 2,
  });
}

function getSideBaseTexture(renderer: Renderer): BaseTexture {
  if (cardSideTexture) {
    return cardSideTexture;
  }

  const graphics = new Graphics();

  graphics
    .beginFill(CARD_BG_COLOR)
    .lineStyle(ZERO)
    .moveTo(ZERO, SIRE_TEXTURE_ANGLE_LENGTH)
    .arcTo(ZERO, ZERO, SIRE_TEXTURE_ANGLE_LENGTH, ZERO, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(CARD_WIDTH - SIRE_TEXTURE_ANGLE_LENGTH, ZERO)
    .arcTo(CARD_WIDTH, ZERO, CARD_WIDTH, SIRE_TEXTURE_ANGLE_LENGTH, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(CARD_WIDTH, SIDE_TEXTURE_HEIGHT)
    .lineTo(ZERO, SIDE_TEXTURE_HEIGHT)
    .lineTo(ZERO, SIRE_TEXTURE_ANGLE_LENGTH)
    .endFill();

  graphics.position.set(BORDER_SIZE, BORDER_SIZE);

  // resolution нужно, чтоб скругленные углы не шакалились
  const renderTexture = getSideRenderTexture();

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  cardSideTexture = renderTexture.castToBaseTexture();

  return cardSideTexture;
}

function getInnerBaseTexture(renderer: Renderer): BaseTexture {
  if (cardInnerTexture) {
    return cardInnerTexture;
  }

  const graphics = new Graphics();

  graphics
    .beginFill(CARD_BG_COLOR)
    .lineStyle(ZERO)
    .lineTo(CARD_WIDTH, ZERO)
    .lineTo(CARD_WIDTH, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, ZERO)
    .endFill();

  graphics.position.set(BORDER_SIZE, ZERO);

  const renderTexture = getInnerRenderTexture();

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  cardInnerTexture = renderTexture.castToBaseTexture();

  return cardInnerTexture;
}

let sideBorderTextures: Record<number, BaseTexture> = {};
let innerBorderTextures: Record<number, BaseTexture> = {};

function getBorderSideBaseTexture(color: number, renderer: Renderer): BaseTexture {
  if (sideBorderTextures[color]) {
    return sideBorderTextures[color];
  }

  const graphics = new Graphics();

  graphics
    .moveTo(ZERO, SIDE_TEXTURE_HEIGHT)
    .lineStyle(BORDER_SIZE, color, 1, 1)
    .lineTo(ZERO, SIRE_TEXTURE_ANGLE_LENGTH)
    .arcTo(ZERO, ZERO, SIRE_TEXTURE_ANGLE_LENGTH, ZERO, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(CARD_WIDTH - SIRE_TEXTURE_ANGLE_LENGTH, ZERO)
    .arcTo(CARD_WIDTH, ZERO, CARD_WIDTH, SIRE_TEXTURE_ANGLE_LENGTH, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(CARD_WIDTH, SIDE_TEXTURE_HEIGHT);

  graphics.position.set(BORDER_SIZE, BORDER_SIZE);

  // resolution нужно, чтоб скругленные углы не шакалились
  const renderTexture = getSideRenderTexture();

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  sideBorderTextures[color] = renderTexture.castToBaseTexture();

  return sideBorderTextures[color];
}

function getBorderInnerBaseTexture(color: number, renderer: Renderer): BaseTexture {
  if (innerBorderTextures[color]) {
    return innerBorderTextures[color];
  }

  const graphics = new Graphics();

  graphics
    .lineStyle(BORDER_SIZE, color, 1, 1)
    .moveTo(CARD_WIDTH, ZERO)
    .lineTo(CARD_WIDTH, INNER_TEXTURE_HEIGHT)
    .moveTo(ZERO, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, ZERO);

  graphics.position.set(BORDER_SIZE, ZERO);

  const renderTexture = getInnerRenderTexture();

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  innerBorderTextures[color] = renderTexture.castToBaseTexture();

  return innerBorderTextures[color];
}

let lastRendererContextUID: number | null = null;

export function getBlockCard(renderer: Renderer, height: number, borderColor?: number): Container {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    clearCache();
  }

  lastRendererContextUID = renderer.CONTEXT_UID;

  const sideCardBaseTexture = getSideBaseTexture(renderer);
  const innerCardBaseTexture = getInnerBaseTexture(renderer);

  const container = new Container();

  const card = PixiTextureBuilderService.buildBurgerTexture(sideCardBaseTexture, innerCardBaseTexture, height);
  container.addChild(card);

  if (borderColor) {
    const sideBorderBaseTexture = getBorderSideBaseTexture(borderColor, renderer);
    const innerBorderBaseTexture = getBorderInnerBaseTexture(borderColor, renderer);

    const border = PixiTextureBuilderService.buildBurgerTexture(sideBorderBaseTexture, innerBorderBaseTexture, height);
    container.addChild(border);
  }

  return container;
}

function clearCache() {
  cardSideTexture = null;
  cardInnerTexture = null;
  sideBorderTextures = {};
  innerBorderTextures = {};
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AmocrmFieldsSelectModule } from '@panel/app/pages/integrations/content/amocrm/fields-select/amocrm-fields-select.module';
import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmDataFromLeadsComponent } from './amocrm-data-from-leads.component';

@NgModule({
  declarations: [AmocrmDataFromLeadsComponent],
  exports: [AmocrmDataFromLeadsComponent],
  imports: [
    AmocrmFieldsSelectModule,
    CommonModule,
    DirectivesModule,
    EventTypeSelectModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
  ],
})
export class AmocrmDataFromLeadsModule {}

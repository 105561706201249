/**
 * Директива-обёртка для быстрого создания radio-элементов
 */
(function () {
  'use strict';

  angular.module('myApp.directives.radio').directive('cqRadio', cqRadio);

  function cqRadio() {
    /**
     * Атрибуты, которые не надо копировать во внутренний input
     *
     * @type {Array.<String>}
     */
    /* @formatter:off */
    var NOT_PASSED_ATTRIBUTES = [
      'class',
      'cq-radio',
      'ng-class',
      'ng-class-even',
      'ng-class-odd',
      'ng-repeat',
      'ng-repeat-end',
      'ng-repeat-start',
    ];
    /* @formatter:on */

    return {
      restrict: 'AE',
      require: ['ngModel'],
      transclude: true,
      compile: compile,
      template: require('./radio.html'),
    };

    function compile(tElement, tAttrs) {
      var attributes = tElement.prop('attributes');
      var input = tElement.find('input');

      // все атрибуты, за исключением NOT_PASSED_ATTRIBUTES, переносятся с элемента с директивой cq-radio на внутренний input
      for (var i = attributes.length - 1; i >= 0; i--) {
        var attribute = attributes[i];
        if (!~NOT_PASSED_ATTRIBUTES.indexOf(attribute.name)) {
          input.attr(attribute.name, attribute.value);
          tElement.removeAttr(attribute.name);
        }
      }

      return angular.noop;
    }
  }
})();

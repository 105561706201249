import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { StateParams } from '@uirouter/core';
import { ResolveTypes } from '@uirouter/core/lib/state/interface';
import { IQService } from 'angular';
import FontFaceObserver from 'fontfaceobserver';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { Integration } from '@http/integration/integration';
import { MessageModel } from '@http/message/message.model';
import { MessageSenderModel } from '@http/message-sender/message-sender.model';
import { EventType, Properties, PropertyModel } from '@http/property/property.model';
import { SegmentModel } from '@http/segment/segment.model';
import { TagModel } from '@http/tag/tag.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { TrackMasterModel } from '@http/track-master/track-master.model';
import { TriggerChainFactory } from '@http/trigger-chain/factory';
import { TriggerChain } from '@http/trigger-chain/internal-types';
import { TriggerChainModel } from '@http/trigger-chain/trigger-chain.model';
import { TriggerChainTemplate } from '@http/trigger-chain-template/internal-types/trigger-chain-template.internal.type';
import { TriggerChainTemplateModel } from '@http/trigger-chain-template/trigger-chain-template.model';
import { UserTag } from '@http/user/types/user.type';
import { TriggerChainEditorComponent } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.component';
import { TriggerChainGeneralStatisticsComponent } from '@panel/app/pages/trigger-chains/general-statistics/trigger-chain-general-statistics.component';
import { TriggerChainListComponent } from '@panel/app/pages/trigger-chains/list/trigger-chain-list.component';
import { TriggerChainTrackService } from '@panel/app/pages/trigger-chains/shared/services/track-service/trigger-chain-track.service';
import { TriggerChainPageComponent } from '@panel/app/pages/trigger-chains/trigger-chain-page.component';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';

export const TRIGGER_CHAIN_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.triggerChains',
    component: TriggerChainPageComponent,
    url: '/trigger-chains',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
    },
    redirectTo: redirectWithoutFeatureAccess,
  },
  {
    name: 'app.content.triggerChains.list',
    component: TriggerChainListComponent,
    url: '',
    onEnter: onEnterTriggerChains,
    resolve: [
      {
        token: 'activeTriggerMessagesCount',
        deps: [MessageModel],
        resolveFn: getActiveTriggerMessagesCount,
      },
      {
        token: 'properties',
        deps: [PropertyModel, 'currentApp'],
        resolveFn: getProperties,
      },
      {
        token: 'tags',
        deps: ['currentApp', TagModel],
        resolveFn: getTags,
      },
      {
        token: 'app',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'teamMembers',
        deps: ['teamMembers'],
        resolveFn: (teamMembers: any) => teamMembers,
      },
    ],
  },
  {
    name: 'app.content.triggerChains.editor',
    component: TriggerChainPageComponent,
    url: '/editor',
    redirectTo: 'app.content.triggerChains.editor.create',
  },
  {
    name: 'app.content.triggerChains.editor.create',
    url: '',
    component: TriggerChainEditorComponent,
    onEnter: onEnterTriggerChainsEditor,
    resolve: [
      ...getTriggerChainEditorCommonResolves(),
      {
        token: 'chain',
        deps: [TriggerChainFactory, 'eventTypes'],
        resolveFn: getChainDefault,
      },
    ],
  },
  {
    name: 'app.content.triggerChains.editor.createFromTemplate',
    url: '?{templateId}',
    component: TriggerChainEditorComponent,
    onEnter: onEnterTriggerChainsEditorCreateFromTemplate,
    resolve: [
      ...getTriggerChainEditorCommonResolves(),
      {
        token: 'chain',
        deps: [
          '$stateParams',
          '$q',
          'currentApp',
          TriggerChainTemplateModel,
          MessageSenderModel,
          TriggerChainFactory,
          'eventTypes',
          'properties',
          'tags',
        ],
        resolveFn: getChainFromTemplate,
      },
    ],
  },
  {
    name: 'app.content.triggerChains.editor.edit',
    url: '/:id',
    component: TriggerChainEditorComponent,
    onEnter: onEnterTriggerChainsEditorEdit,
    resolve: [
      ...getTriggerChainEditorCommonResolves(),
      {
        token: 'chain',
        deps: ['$stateParams', TriggerChainModel, 'properties', 'tags', 'teamMembers'],
        resolveFn: getChain,
      },
    ],
  },
  {
    name: 'app.content.triggerChains.editor.copy',
    url: '/:id/copy',
    component: TriggerChainEditorComponent,
    onEnter: onEnterTriggerChainsEditorEdit,
    resolve: [
      ...getTriggerChainEditorCommonResolves(),
      {
        token: 'chain',
        deps: ['$stateParams', TriggerChainModel, 'properties', 'tags', 'teamMembers'],
        resolveFn: getChain,
      },
    ],
  },
  {
    name: 'app.content.triggerChains.statistics',
    url: '/statistics',
    component: TriggerChainPageComponent,
    redirectTo: 'app.content.triggerChains.statistics.general',
  },
  {
    name: 'app.content.triggerChains.statistics.general',
    url: '/:id',
    component: TriggerChainGeneralStatisticsComponent,
    onEnter: onEnterTriggerChainsStatisticsGeneral,
    resolve: [
      {
        token: 'app',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'chain',
        deps: ['$stateParams', TriggerChainModel, 'properties', 'tags', 'teamMembers'],
        resolveFn: getChain,
      },
      {
        token: 'chainStepList',
        deps: ['$stateParams', TriggerChainModel],
        resolveFn: getChainStepList,
      },
      {
        token: 'properties',
        deps: [PropertyModel, 'currentApp'],
        resolveFn: getProperties,
      },
      {
        token: 'tags',
        deps: ['currentApp', TagModel],
        resolveFn: getTags,
      },
    ],
  },
  {
    name: 'app.content.triggerChains.statistics.detailed',
    url: '/{id:id}/{messageId:id}',
    redirectTo: 'app.content.triggerChainsAjs',
  },
];

function getTriggerChainEditorCommonResolves(): ResolveTypes[] {
  return [
    {
      token: 'app',
      deps: ['currentApp'],
      resolveFn: (currentApp: any) => currentApp,
    },
    {
      token: 'activeTriggerMessagesCount',
      deps: [MessageModel],
      resolveFn: getActiveTriggerMessagesCount,
    },
    {
      token: 'autoEvents',
      deps: ['currentApp', TrackMasterModel],
      resolveFn: getAutoEvents,
    },
    {
      token: 'eventTypes',
      deps: [EventTypeModel, 'currentApp'],
      resolveFn: getEventTypes,
    },
    {
      token: 'properties',
      deps: [PropertyModel, 'currentApp'],
      resolveFn: getProperties,
    },
    {
      token: 'segments',
      deps: [SegmentModel, FilterAjsModel, 'currentApp', 'tags', 'properties'],
      resolveFn: getSegments,
    },
    {
      token: 'tags',
      deps: ['currentApp', TagModel],
      resolveFn: getTags,
    },
    {
      token: 'templates',
      deps: [TriggerChainTemplateModel, 'properties', 'tags'],
      resolveFn: getTemplates,
    },
    {
      token: 'preloadFonts',
      deps: [],
      resolveFn: preloadFont,
    },
    {
      token: 'telegramIntegrations',
      deps: ['integrationModel', 'currentApp'],
      resolveFn: getTelegramIntegrations,
    },
  ];
}

function redirectWithoutFeatureAccess(Transition: any) {
  const featureModel = Transition.injector().get('featureModel');

  return Promise.all([Transition.injector().getAsync('currentApp'), Transition.injector().getAsync('features')]).then(
    redirect,
  );

  function redirect() {
    if (featureModel.hasAccess(FEATURES_ONLY.TRIGGER_CHAIN)) {
      return 'app.content.triggerChains.list';
    }
    return 'app.content.dashboard';
  }
}

/**
 * Получение количества активных триггерных сообщений
 */
function getActiveTriggerMessagesCount(messageModel: MessageModel) {
  return firstValueFrom(messageModel.getActiveTriggerMessageCount());
}

function getProperties(propertyModel: PropertyModel, currentApp: App) {
  return firstValueFrom(propertyModel.getList(currentApp.id));
}

function getTags(currentApp: App, tagModel: TagModel) {
  return firstValueFrom(tagModel.getList(currentApp.id));
}

/**
 *  Получает дефолтную цепочку
 */
function getChainDefault(triggerChainFactory: TriggerChainFactory, eventTypes: EventType[]): TriggerChain {
  return triggerChainFactory.generateStartedTemplate({ eventTypes });
}

/**
 * Получение автособытий по группе
 */
function getAutoEvents(currentApp: App, trackMasterModel: TrackMasterModel) {
  return firstValueFrom(trackMasterModel.getList(currentApp.id));
}

/**
 * Получение списка событий
 */
function getEventTypes(eventTypeModel: EventTypeModel, currentApp: App) {
  return firstValueFrom(eventTypeModel.getList(currentApp.id));
}

function getSegments(
  segmentModel: SegmentModel,
  filterAjsModel: FilterAjsModel,
  currentApp: App,
  tags: UserTag[],
  properties: Properties,
) {
  return firstValueFrom(segmentModel.getList(currentApp.id, true)).then((segments: any) => {
    for (let i = 0; i < segments.length; i++) {
      filterAjsModel.linkWithPropsAndTags(segments[i].filters, properties, tags);
    }
    return segments;
  });
}

/**
 * Получает список шаблонов цепочек
 */
function getTemplates(triggerChainTemplateModel: TriggerChainTemplateModel, properties: Properties, tags: UserTag[]) {
  return firstValueFrom(triggerChainTemplateModel.getList({ properties, userTags: tags }));
}

/**
 * Неочевидно, че оно делает тут, но перед тем, как рисовать иконки в канвасе, надо предзагрузить иконочный шрифт,
 * иначе на первую отрисовку будет баг, что отрисуется кривые размеры и будут уродские смещения иконок
 */
function preloadFont() {
  return new FontFaceObserver('CQ-Icons-sm').load();
}

function getChainFromTemplate(
  $stateParams: StateParams,
  $q: IQService,
  currentApp: App,
  triggerChainTemplateModel: TriggerChainTemplateModel,
  messageSenderModel: MessageSenderModel,
  triggerChainFactory: TriggerChainFactory,
  eventTypes: EventType[],
  properties: Properties,
  tags: UserTag[],
) {
  return $q
    .all([
      firstValueFrom(messageSenderModel.getList(currentApp.id, false)),
      triggerChainTemplateModel.get($stateParams.templateId, { properties, userTags: tags }),
    ])
    .then(async (response: any) => {
      let teamMembers = response[0];
      let template = (await firstValueFrom(response[1])) as TriggerChainTemplate;

      template.steps.forEach((step: any) => {
        if (step.type === 'sendingConditions') {
          step.meta.triggers = step.meta.triggers.map((eventName: any) => {
            if (!eventName.startsWith('$')) {
              throw new Error('Sending conditions block should not contain any non-system events');
            }

            let event;
            try {
              event = EventTypeModel.findEventByName(eventName, eventTypes);
            } catch (_) {
              event = EventTypeModel.findEventByName('$session_start', eventTypes);
            }

            return event.id;
          });
        }

        if (step.type === 'reaction') {
          if (step.meta.event) {
            if (!step.meta.event.startsWith('$')) {
              throw new Error('Reaction block should not contain any non-system events');
            }

            const event = EventTypeModel.findEventByName(step.meta.event, eventTypes);
            step.meta.event = event.id;
          }
        }

        if (step.type === 'autoMessage' && step.meta.message.parts[0].type === 'email') {
          let teamMember = teamMembers.filter((member: any) => member.isDefault === true)[0];
          step.meta.message.parts[0][step.meta.message.parts[0].type].messageSendFromId = teamMember.id;
          step.meta.message.parts[0][step.meta.message.parts[0].type].sender = {
            ...teamMember,
            type: 'messageSender',
          };
        }
      });
      return triggerChainFactory.generate({ name: template.name, steps: template.steps }, { eventTypes });
    });
}

/**
 * Получает цепочку
 */
function getChain($stateParams: any, triggerChainModel: TriggerChainModel, properties: any, tags: any) {
  let { id } = $stateParams;

  return firstValueFrom(triggerChainModel.get(id, { properties, userTags: tags }));
}

function getTelegramIntegrations(integrationModel: any, currentApp: App) {
  return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM).then((response: any) => {
    return response.filter((integration: Integration) => integration.active);
  });
}

/**
 * Получает список сообщенйи в цепочке
 */
function getChainStepList($stateParams: any, triggerChainModel: TriggerChainModel) {
  const { id } = $stateParams;

  return firstValueFrom(triggerChainModel.getStepList(id));
}

function onEnterTriggerChains(triggerChainTrackService: TriggerChainTrackService) {
  triggerChainTrackService.trackTransitionOnChainList();
}
onEnterTriggerChains.$inject = ['triggerChainTrackService'];

function onEnterTriggerChainsEditor(triggerChainTrackService: TriggerChainTrackService) {
  triggerChainTrackService.trackTransitionOnCreateChain();
}
onEnterTriggerChainsEditor.$inject = ['triggerChainTrackService'];

function onEnterTriggerChainsEditorCreateFromTemplate(triggerChainTrackService: TriggerChainTrackService) {
  triggerChainTrackService.trackTransitionOnCreateChain();
}
onEnterTriggerChainsEditorCreateFromTemplate.$inject = ['triggerChainTrackService'];

function onEnterTriggerChainsEditorEdit(triggerChainTrackService: TriggerChainTrackService) {
  triggerChainTrackService.trackTransitionOnEditChain();
}
onEnterTriggerChainsEditorEdit.$inject = ['triggerChainTrackService'];

function onEnterTriggerChainsStatisticsGeneral(triggerChainTrackService: TriggerChainTrackService) {
  triggerChainTrackService.trackTransitionOnChainStatistics();
}
onEnterTriggerChainsStatisticsGeneral.$inject = ['triggerChainTrackService'];

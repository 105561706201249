<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('leaveSiteAttemptSettingComponent.heading') }}</h3>

    <button
      class="close"
      (click)="dismissModal()"
      aria-label="Close"
      type="button"
      data-test="close-modal-btn"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="mb-15">{{ t('leaveSiteAttemptSettingComponent.description') }}</div>
    <!--ЗАПИСЫВАТЬ СОБЫТИЕ ПОПЫТКИ УХОДА С САЙТА-->
    <div class="form-group">
      <cq-checkbox-control [(ngModel)]="leaveSiteAttempt">
        {{
          t('leaveSiteAttemptSettingComponent.leaveSiteCheckbox', {
            leaveSiteAttempt: t('models.eventType.systemEventTypes.$leave_site_attempt')
          })
        }}
      </cq-checkbox-control>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <small
      class="text-muted"
      [innerHTML]="'leaveSiteAttemptSettingComponent.howToDescription' | transloco"
    ></small>
    <div>
      <button
        class="btn btn-outline-secondary mr-10"
        (click)="dismissModal()"
        type="button"
        data-test="cancel-modal-btn"
      >
        {{ t('leaveSiteAttemptSettingComponent.cancelButton') }}
      </button>
      <button
        *ngIf="accessToTrackMasterJsCode.hasAccess"
        class="btn btn-primary"
        (click)="startTrackLeaveSiteAttempt()"
        [disabled]="!leaveSiteAttempt"
        data-test="start-track-button"
      >
        {{ t('leaveSiteAttemptSettingComponent.startButton') }}
      </button>
      <cq-access-denial-popover
        *ngIf="!accessToTrackMasterJsCode.hasAccess"
        [currentApp]="modalWindowParams.currentApp"
        [denialReason]="accessToTrackMasterJsCode.denialReason"
        [popoverAutoClose]="'outside'"
        [beforeActionClickFn]="dismissModal.bind(this)"
        data-test="custom-event-activation-button"
      >
        <button
          type="button"
          class="btn btn-primary"
        >
          {{ t('leaveSiteAttemptSettingComponent.startButton') }}
          <cq-plan-capability-coin class="ml-5"></cq-plan-capability-coin>
        </button>
      </cq-access-denial-popover>
    </div>
  </div>
</ng-container>

/**
 * Общий контроллер настроек мастера сбора данных
 */
(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventSettingBaseController', CqAutoEventSettingBaseController);

  function CqAutoEventSettingBaseController() {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.extraPropertiesIsCollapsed = getInitialExtraPropertiesCollapsedState(); // Флаг свернуточти дополнительных свойств
    }

    /**
     * Получение начального состояния свернутости дополнительных свойств
     *
     * @returns {boolean}
     */
    function getInitialExtraPropertiesCollapsedState() {
      return !(vm.autoEvent.params.length || vm.autoEvent.paramsSelectors.length);
    }
  }
})();

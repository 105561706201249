/**
 * Контроллер шаблона
 */
import { PSEUDO_TEMPLATE_DIRECTORY_TYPES } from '../../../app/http/template-directory/template-directory.constants';

import { firstValueFrom } from 'rxjs';

import { MESSAGE_PART_TYPE_ICON_CLASSES } from '../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.messageTemplate').controller('CqMessageTemplateController', CqMessageTemplateController);

  function CqMessageTemplateController(
    $translate,
    $uibModal,
    toastr,
    USER_FILES_URL,
    carrotquestHelper,
    messagePartModel,
    messageTemplateModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.changeStatus = changeStatus;
      vm.isOpen = false; // Флаг состояния дропдауна (Открыт/закрыт)
      vm.MESSAGE_PART_TYPE_ICON_CLASSES = MESSAGE_PART_TYPE_ICON_CLASSES;
      vm.messagePartModel = messagePartModel;
      vm.openRemoveModal = openRemoveModal;
      vm.openSetDirectoryModal = openSetDirectoryModal;
      vm.PSEUDO_TEMPLATE_DIRECTORY_TYPES = PSEUDO_TEMPLATE_DIRECTORY_TYPES;
      vm.SetDirectoryModal = openSetDirectoryModal;
      vm.trackClickCreateAutoMessage = trackClickCreateAutoMessage;
      vm.trackClickCreateCopy = trackClickCreateCopy;
      vm.USER_FILES_URL = USER_FILES_URL;
    }

    /**
     * Изменение статуса шаблона
     *
     * @param {Object} template - Шаблон
     */
    function changeStatus(template) {
      firstValueFrom(messageTemplateModel.changeStatus(template)).then(changedSuccess);

      function changedSuccess() {
        trackChangeActive(!template.active);
        toastr.success($translate.instant('messageTemplate.toasts.changedSuccess'));
        template.active = !template.active;
        if (vm.onChanged) {
          vm.onChanged();
        }
      }
    }

    /**
     * Открытие модалки удаления шаблона
     *
     * @param {Object} template - Шаблон
     */
    function openRemoveModal(template) {
      var removeModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('messageTemplate.removeModal.heading'),
              body: $translate.instant('messageTemplate.removeModal.body', { templateName: template.name }),
              confirmButtonText: $translate.instant('general.remove'),
            };
          },
        },
      });

      removeModal.result.then(angular.bind(null, remove, template));
    }

    /**
     * Открытие модалки удаления шаблона
     */
    function openSetDirectoryModal() {
      var setDirectoryModal = $uibModal.open({
        component: 'cqSetTemplateDirectoryModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          directories: angular.bind(null, angular.identity, vm.directories),
          template: angular.bind(null, angular.identity, vm.template),
        },
      });

      setDirectoryModal.result.then(setDirectory);

      function setDirectory(response) {
        trackSetDirectory();
        vm.onChanged && vm.onChanged();
      }
    }

    /**
     * Удаление шаблона
     *
     * @param {Object} template - Шаблон
     */
    function remove(template) {
      firstValueFrom(messageTemplateModel.remove(template.id)).then(removeSuccess);

      function removeSuccess() {
        trackRemoved();
        toastr.success($translate.instant('messageTemplate.toasts.removedSuccess'));
        vm.onRemove && vm.onRemove();
      }
    }

    /**
     * Трек клика на кнопку "создать автосообщение"
     */
    function trackClickCreateAutoMessage() {
      carrotquestHelper.track('Шаблоны - клик на создать автосообщение из шаблона', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
        id_шаблона: vm.template.id,
      });
    }

    /**
     * Трек клика на кнопку "Создать копию"
     */
    function trackClickCreateCopy() {
      carrotquestHelper.track('Шаблоны - клик на создать копию', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
      });
    }

    /**
     * Трек смена активности
     *
     * @param {Boolean} active - Статус активности
     */
    function trackChangeActive(active) {
      var trackText = active ? 'Шаблоны - используется' : 'Шаблоны - не используется';
      carrotquestHelper.track(trackText, {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
        id_шаблона: vm.template.id,
      });
    }

    /**
     * Трек клика удаления шаблона
     */
    function trackRemoved() {
      carrotquestHelper.track('Шаблоны - удалил Шаблон', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
        id_шаблона: vm.template.id,
      });
    }

    /**
     * Трек перемещения шаблона в другую папку
     */
    function trackSetDirectory() {
      carrotquestHelper.track('Шаблоны - переместил шаблон в папку', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Тип: vm.template.type,
      });
    }
  }
})();

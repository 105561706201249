import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { InputDateModule } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-date/input-date.module';
import { InputListModule } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-list/input-list.module';
import { InputNumberModule } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-number/input-number.module';
import { InputNumberRangeModule } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-number-range/input-number-range.module';
import { InputStrModule } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-str/input-str.module';
import { InputStrContainsModule } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-str-contains/input-str-contains.module';

import { OperationSelectComponent } from './operation-select.component';

@NgModule({
  declarations: [OperationSelectComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    TranslocoModule,
    ReactiveFormsModule,
    InputNumberModule,
    InputNumberRangeModule,
    InputDateModule,
    InputListModule,
    InputStrContainsModule,
    InputStrModule,
  ],
  exports: [OperationSelectComponent],
})
export class OperationSelectModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConversationsStoreService } from '@panel/app/pages/conversations/conversations.store';
import { AssigneeFilterModule } from '@panel/app/pages/conversations/conversations-list/assignee-filter/assignee-filter.module';
import { SearchFilterModule } from '@panel/app/pages/conversations/conversations-list/search-filter/search-filter.module';
import { StatusFilterModule } from '@panel/app/pages/conversations/conversations-list/status-filter/status-filter.module';
import { TagsFilterModule } from '@panel/app/pages/conversations/conversations-list/tags-filter/tags-filter.module';
import { UnansweredFilterModule } from '@panel/app/pages/conversations/conversations-list/unanswered-filter/unanswered-filter.module';

import { ButtonMenuModule } from './button-menu/button-menu.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ButtonMenuModule,
    UnansweredFilterModule,
    StatusFilterModule,
    AssigneeFilterModule,
    TagsFilterModule,
    SearchFilterModule,
  ],
  providers: [ConversationsStoreService],
})
export class ConversationsListModule {}

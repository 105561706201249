import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Компонент для показа/скрытия чат-виджета в чате
 */
@Component({
  selector: 'cq-chat-widget-inserting-into-chat[value]',
  templateUrl: './chat-widget-inserting-into-chat.component.html',
  styleUrls: ['./chat-widget-inserting-into-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWidgetInsertingIntoChatComponent {
  control: FormControl<boolean> = new FormControl<boolean>(false, {
    nonNullable: true,
    validators: [Validators.required],
  });

  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  @Input()
  set value(value: boolean) {
    this.control.setValue(value);
  }

  @Output()
  valueChanges = this.control.valueChanges.pipe(distinctUntilChanged());
}

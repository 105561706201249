import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { PropertySelectComponent } from './property-select.component';

@NgModule({
  declarations: [PropertySelectComponent],
  exports: [PropertySelectComponent],
  imports: [CommonModule, TranslocoModule, NgSelectModule, ReactiveFormsModule],
})
export class PropertySelectModule {}

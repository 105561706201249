import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { EventFilter, PropertyFilter } from '@panel/app/services/filter/types/filter.internal-types';

export function getTriggerChainEmptyFiltersValidator(): ValidatorFn {
  return <T extends { eventFilters: EventFilter[]; propertyFilters: PropertyFilter[] }>(
    filterStepEditorForm: AbstractControl<T>,
  ): ValidationErrors | null => {
    const propertyFiltersControl = filterStepEditorForm.get('propertyFilters');
    const eventFiltersControl = filterStepEditorForm.get('eventFilters');

    if (propertyFiltersControl?.disabled || eventFiltersControl?.disabled) {
      return null;
    }

    let { propertyFilters, eventFilters } = filterStepEditorForm.value;

    let propertyFiltersAreEmpty = propertyFilters.every((filter) => filter.propertyName === null);
    let eventFiltersAreEmpty = eventFilters.every((filter) => filter.eventId === null);

    const formIsInvalid = propertyFiltersAreEmpty && eventFiltersAreEmpty;

    return formIsInvalid ? { filtersAreEmpty: filterStepEditorForm.value } : null;
  };
}

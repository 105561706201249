import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { EventsAnalyticsPageComponent } from '@panel/app/pages/events-analytics/events-analytics-page.component';
import { EventsAnalyticsComponent } from '@panel/app/pages/events-analytics/pages/events-analytics/events-analytics.component';
import { PropertyModel } from '@http/property/property.model';

export const EVENTS_ANALYTICS_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.analytics',
    url: '/analytics',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
    },
    component: EventsAnalyticsPageComponent,
    redirectTo: 'app.content.analytics.events',
  },
  {
    name: 'app.content.analytics.events',
    url: '/events',
    component: EventsAnalyticsComponent,
    resolve: [
      {
        token: 'billingInfo',
        deps: ['billingInfo'],
        resolveFn: (billingInfo: any) => billingInfo,
      },
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'djangoUser',
        deps: ['djangoUser'],
        resolveFn: (djangoUser: any) => djangoUser,
      },
      {
        token: 'eventTypes',
        deps: [PropertyModel, 'currentApp'],
        resolveFn: getEventTypes,
      },
    ],
  },
];

/**
 * Получение типов событий
 *
 * @param propertyModel
 * @param currentApp
 */
function getEventTypes(propertyModel: PropertyModel, currentApp: App) {
  return firstValueFrom(propertyModel.getList(currentApp.id)).then((props: any) => {
    return props.eventTypes;
  });
}

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

import { environment } from '@environment';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { VkIntegrationExternal } from '@http/integration/integrations/vk/interfaces/vk-integration.interfaces';
import { VkIntegration } from '@http/integration/integrations/vk/vk-integration';
import { IntegrationService } from '@http/integration/services/integration.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

/**
 * Настройка старой (до перехода на OAuth) интеграции с ВКонтакте
 */

@Component({
  selector: 'cq-vk-old[currentAppId][integrationExternal]',
  templateUrl: './vk-old.component.html',
  styleUrls: ['./vk-old.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkOldComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentAppId!: string;

  /** Текущая или новая интеграция во внешнем формате */
  @Input()
  integrationExternal!: VkIntegrationExternal;

  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: VkIntegration;

  /** Флаг выполнения запроса */
  isRequestPerformed: boolean = false;

  /** Название проекта */
  projectName: string = environment.projectName;

  /** Адрес для вставки в настройки Callback API */
  webhookUrl!: string;

  /** Получение контрола с API-token'ом интеграции */
  get apiTokenControl(): GenericFormControl<string | null> {
    return this.integration.form.controls.settings.controls.apiToken;
  }

  /** Получение контрола с confirm-cod'ом интеграции */
  get confirmCodeControl(): GenericFormControl<string | null> {
    return this.integration.form.controls.settings.controls.confirmCode;
  }

  /** Получение переведённого названия сервиса */
  get integrationTypeName() {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.VK);
  }

  /** Получение контрола с названием интеграции */
  get nameControl() {
    return this.integration.form.controls.name;
  }

  /** Получение контрола с токеном для вебхука */
  get tokenControl() {
    return this.integration.form.controls.settings.controls.token;
  }

  constructor(
    @Inject(WINDOW) private readonly windowRef: Window,
    @Inject(IntegrationFactory) private readonly integrationFactory: IntegrationFactory,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
  ) {}

  ngOnInit(): void {
    this.integration = this.integrationFactory.create(INTEGRATION_TYPES.VK, this.integrationExternal);

    this.webhookUrl = this.getWebhookUrl();
  }

  /** Получение строки с адресом сервера */
  getWebhookUrl(): string {
    return `${environment.webhooksEndpoint}/vk/${this.integrationExternal.id}/${this.tokenControl.value}`;
  }
}

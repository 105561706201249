/**
 * Получение типов псевдопапок
 */
export const PSEUDO_TEMPLATE_DIRECTORY_TYPES = {
  ALL_DIRECTORY: '$allDirectory', // псевдопапка "Все автосообщения"
  WITHOUT_DIRECTORY: '$withoutDirectory', // псевдопапка "Без папки"
};

/**
 * Псевдопапки и соответствующие им ID
 */
export const PSEUDO_TEMPLATE_DIRECTORY_IDS = {
  [PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY]: 'all', //NOTE в автосообщениях используетя ui-select для вывода папок а для него пустая строка это равнозначно тому что ни чего не выбрано. Поэтому пришлось изменит�� null на 'all'
  [PSEUDO_TEMPLATE_DIRECTORY_TYPES.WITHOUT_DIRECTORY]: '0',
};

import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';

import { MessageFiltersModule } from '@panel/app/partials/message-editor/message-filters/message-filters.module';
import { UserTagModule } from '@panel/app/partials/user-tag/user-tag.module';
import { SharedModule } from '@panel/app/shared/shared.module';
import { ProgressbarModule } from '@panel/app/shared/visual-components/progressbar/progressbar.module';

import { TriggerChainTableItemComponent } from './trigger-chain-table-item.component';

@NgModule({
  declarations: [TriggerChainTableItemComponent],
  exports: [TriggerChainTableItemComponent],
  imports: [
    CommonModule,
    MessageFiltersModule,
    MomentModule,
    NgbPopoverModule,
    NgbTooltipModule,
    TranslocoModule,
    UIRouterModule,
    UserTagModule,
    ProgressbarModule,
    SharedModule,
    NgbDropdownModule,
  ],
  providers: [PercentPipe],
})
export class TriggerChainTableItemModule {}

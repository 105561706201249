import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { environment } from '@environment';
import { AmocrmMappingForm } from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { AmocrmField } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';

/**
 * Компонент для работы данными, которые передаются в сделку AmoCRM
 */

@Component({
  selector: 'cq-amocrm-props-to-lead[controlLeadEventTypes][controlLeadProps][integrationId]',
  templateUrl: './amocrm-props-to-lead.component.html',
  styleUrls: ['./amocrm-props-to-lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmPropsToLeadComponent implements OnInit {
  /** Контрол с событиями, которые передаются из лида в сделку amoCRM */
  @Input()
  controlLeadEventTypes!: GenericFormArray<AmocrmMappingForm>;

  /** Контрол со свойствами, которые передаются из лида в сделку amoCRM */
  @Input()
  controlLeadProps!: GenericFormArray<AmocrmMappingForm>;

  /** ID текущей интеграции */
  @Input()
  integrationId!: string;

  /** Инстанс коллапса */
  @ViewChild(NgbCollapse)
  collapse!: NgbCollapse;

  amocrmLeadFields: AmocrmField[] = [];

  /** Свёрнут ли блок */
  isCollapsed: boolean = true;

  /** Название проекта */
  projectName: string = environment.projectName;

  /**
   * Выбранные имена сделок лидов
   */
  chosenLeadFields: string[] = [];

  /** Получение количества настроенных связок событий и свойств лида с полями сделки в amoCRM  */
  get countBunch(): number {
    return this.controlLeadEventTypes.controls.length + this.controlLeadProps.controls.length;
  }

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    public readonly amocrmState: AmocrmStateService,
    @Inject(AmocrmService) private readonly amocrmService: AmocrmService,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
  ) {}

  ngOnInit(): void {
    // Следим за изменениями статусов валидности и touched-статуса, чтобы раскрыть коллапс этого компонента,
    // если один из контролов невалидный
    combineLatest(
      extractTouchedChanges(this.controlLeadEventTypes),
      extractTouchedChanges(this.controlLeadProps),
      this.controlLeadEventTypes.statusChanges.pipe(
        startWith(this.controlLeadEventTypes.status),
        map((status) => status === 'INVALID'),
      ),
      this.controlLeadProps.statusChanges.pipe(
        startWith(this.controlLeadProps.status),
        map((status) => status === 'INVALID'),
      ),
    )
      .pipe(map((statuses) => [statuses[2], statuses[3]].some((value) => value)))
      .subscribe((invalid) => {
        if (invalid && this.collapse.collapsed) {
          this.collapse.toggle(true);
        }
      });

    combineLatest([
      this.controlLeadEventTypes.valueChanges.pipe(startWith(this.controlLeadEventTypes.value)),
      this.controlLeadProps.valueChanges.pipe(startWith(this.controlLeadProps.value)),
    ])
      .pipe(
        map(([leadEventTypes, leadProps]) => {
          //@ts-ignore
          return [...leadEventTypes.map((v) => v.key), ...leadProps.map((v) => v.key)];
        }),
      )
      .subscribe((chosenLeadFields: string[]) => {
        this.chosenLeadFields = chosenLeadFields;
      });
  }

  /** Добавление связки события лида и поля сделки в amoCRM */
  addEventTypeToAmocrmBunch(): void {
    const control = this.createAmocrmBunch();

    this.controlLeadEventTypes.push(control);
  }

  /** Добавление связки свойства лида и поля сделки в amoCRM */
  addPropToAmocrmBunch(): void {
    const control = this.createAmocrmBunch();

    this.controlLeadProps.push(control);
  }

  /** Создание связки свойства или события лида и поля сделки в amoCRM */
  createAmocrmBunch() {
    const bunch = {};

    return new AmocrmMappingForm(bunch);
  }

  /***
   * Удаления связки события лида и поля сделки в amoCRM
   *
   * @param index - Порядковый номер связки
   */
  removeEventTypeToAmocrmBunch(index: number): void {
    this.controlLeadEventTypes.removeAt(index);
  }

  /** Удаление связки свойства лида и поля сделки в amoCRM */
  removePropToAmocrmBunch(index: number): void {
    this.controlLeadProps.removeAt(index);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isUndefined } from 'angular';
import { DASHBOARD_DATE_GROUPING_TYPES, DASHBOARD_STATISTICS_TYPES } from 'app/http/dashboard/dashboard.constants';
import { DashboardStatistics, ParsedGroupingStatistics } from 'app/http/dashboard/dashboard.types';
import Moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Сервис для работы со статистикой на странице дашборда
 */
@Injectable({ providedIn: 'root' })
export class DashboardModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Получение и сохранение даты начала и конца статистики и полученные данные
   *
   * @param appId ID аппа
   * @param startDate Дата начала статистики
   * @param endDate Дата конца статистики
   */
  public getDashboardStatistics(
    appId: string,
    startDate: Moment.Moment,
    endDate: Moment.Moment,
  ): Observable<DashboardStatistics[]> {
    const params = {
      // NOTE: флаг специально выставлен в false, чтобы возвращались только счётчики пользователей и лидов, а не списки
      //  иначе бэк их долго получает, и ответ становится огромным
      users_as_list: false,
      response_as_array: true,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
    };

    return this.http.get<DashboardStatistics[]>(`/apps/${appId}/dashboard_stats`, { params }).pipe(
      map((response) => {
        return this.parseDashboardStatistics(response);
      }),
    );
  }

  /**
   * Парсинг сохраненных данных в днях
   *
   * @param dashboardStatistics Статистика
   * @param statisticsType Тип статистики
   * @returns {Object}
   */
  getParsedDaysGroupingStatistics(
    dashboardStatistics: DashboardStatistics[],
    statisticsType: DASHBOARD_STATISTICS_TYPES,
  ): ParsedGroupingStatistics {
    const statistics: ParsedGroupingStatistics = {
      count: 0,
      labels: [],
      data: [],
    };
    let count;

    dashboardStatistics.forEach((item) => {
      count = item[statisticsType];
      statistics.labels.push(Moment(item.periodStart).format('L'));
      statistics.data.push(count);
      statistics.count += count;
    });

    return statistics;
  }

  /**
   * Парсинг сохраненных данных в зависимости от типа группировки
   *
   * @param dashboardStatistics Статистика
   * @param statisticsType Тип статистики
   * @param dateGrouping Тип группировки данных
   * @param startDate Дата начала статистики
   * @param endDate Дата конца статистики
   */
  getParsedAnotherGroupingStatistics(
    dashboardStatistics: DashboardStatistics[],
    statisticsType: DASHBOARD_STATISTICS_TYPES,
    dateGrouping: DASHBOARD_DATE_GROUPING_TYPES,
    startDate: Moment.Moment,
    endDate: Moment.Moment,
  ): ParsedGroupingStatistics {
    const statistics: ParsedGroupingStatistics = {
      count: 0,
      labels: [],
      data: [],
    };
    const firstDays = []; //массив с первыми днями
    let count;

    for (let i = 0; i < dashboardStatistics.length; i++) {
      const firstDay = Moment(dashboardStatistics[i].periodStart).startOf(dateGrouping); //Первый день
      const lastDay = Moment(dashboardStatistics[i].periodStart).endOf(dateGrouping); //Последний день

      //Дата должна быть равна дате начала запрашиваемой статистики или первый день не содержится в массиве с первыми днями и он не равен дате начала запрашиваемой статистики
      if (
        dashboardStatistics[i].periodStart.isSame(startDate, 'day') ||
        (!~firstDays.indexOf(firstDay.format('L')) && !firstDay.isSame(Moment(startDate).startOf(dateGrouping), 'day'))
      ) {
        firstDays.push(dashboardStatistics[i].periodStart.format('L'));
        //Если конец даты запрашиваемой статистики позже чем последний день
        if (endDate.isAfter(lastDay)) {
          statistics.labels.push(dashboardStatistics[i].periodStart.format('L') + ' - ' + lastDay.format('L'));
        } else {
          statistics.labels.push(dashboardStatistics[i].periodStart.format('L') + ' - ' + endDate.format('L'));
        }
      }

      count = dashboardStatistics[i][statisticsType];
      //Если в массиве нет данных о периоде присваиваем ему 0
      if (isUndefined(statistics.data[statistics.labels.length - 1])) {
        statistics.data[statistics.labels.length - 1] = 0;
      }
      statistics.data[statistics.labels.length - 1] += count;
      statistics.count += count;
    }
    return statistics;
  }

  /**
   * Получение статистики в зависимости от типа группировки и типа статистики
   *
   * @param dashboardStatistics Статистика
   * @param statisticsType Тип статистики
   * @param dateGrouping Тип группировки данных
   * @param startDate Дата начала статистики
   * @param endDate Дата конца статистики
   */
  getParsedStatistics(
    dashboardStatistics: DashboardStatistics[],
    statisticsType: DASHBOARD_STATISTICS_TYPES,
    dateGrouping: DASHBOARD_DATE_GROUPING_TYPES,
    startDate: Moment.Moment,
    endDate: Moment.Moment,
  ): ParsedGroupingStatistics {
    if (dateGrouping === DASHBOARD_DATE_GROUPING_TYPES.WEEK || dateGrouping === DASHBOARD_DATE_GROUPING_TYPES.MONTH) {
      return this.getParsedAnotherGroupingStatistics(
        dashboardStatistics,
        statisticsType,
        dateGrouping,
        startDate,
        endDate,
      );
    }

    return this.getParsedDaysGroupingStatistics(dashboardStatistics, statisticsType);
  }

  /**
   * Парсит полученную статистику
   *
   * @param dashboardStatistics Статистика
   */
  parseDashboardStatistics(dashboardStatistics: DashboardStatistics[]): DashboardStatistics[] {
    for (let i = 0; i < dashboardStatistics.length; i++) {
      dashboardStatistics[i].periodStart = Moment(dashboardStatistics[i].periodStart, 'YYYY-MM-DD');
    }

    return dashboardStatistics;
  }
}

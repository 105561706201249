<ng-container *transloco="let t; read: 'whatsAppTemplatesPopover'">
  <div
    class="d-flex flex-col flex-grow full-height"
    [ngClass]="{ 'dark-theme': darkTheme }"
  >
    <ng-container *ngIf="!isDataLoading; else loader">
      <ng-container *ngIf="!isApiError; else apiError">
        <ng-container *ngIf="templates.length > 0; else zeroData">
          <div class="d-flex align-items-center padding-right-10 border-bottom">
            <div class="form-group has-feedback has-feedback-left m-0 flex-grow">
              <i class="cqi-sm cqi-search form-control-feedback text-right text-muted"></i>
              <input
                class="form-control border-0"
                [attr.placeholder]="t('searchPhraseInput.placeholder')"
                [formControl]="searchPhrase"
                autocomplete="off"
                autofocus
                name="searchPhrase"
              />
            </div>
            <button
              class="btn btn-text-primary"
              (click)="$event.stopPropagation(); searchPhrase.reset()"
              *ngIf="searchPhrase.value"
              type="button"
            >
              <i class="btn-icon cqi-sm cqi-times"></i>
            </button>
          </div>
          <div class="flex-grow overflow-y-auto overflow-x-hidden custom-scroll">
            <div
              class="template padding-20 cursor-pointer"
              (click)="openSendingWhatsAppModal(template)"
              *ngFor="let template of filteredTemplates$ | async; let index = index; first as isFirst; last as isLast"
              [ngClass]="{ 'bordered-bottom': !isLast }"
            >
              <div>
                <strong>{{ template.name }}</strong>
                <span>&bull;</span>
                {{ template.content.text }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #apiError>
    <div class="response-container text-center">
      <h4>{{ t('apiError.heading') }}</h4>
      <div>{{ t('apiError.text') }}</div>
    </div>
  </ng-template>

  <ng-template #loader>
    <cq-loader></cq-loader>
  </ng-template>

  <ng-template #zeroData>
    <div class="response-container text-center">
      <h4>{{ t('zeroData.heading') }}</h4>
      <div class="margin-bottom-15">{{ t('zeroData.text') }}</div>
      <a
        class="btn btn-secondary"
        [attr.href]="ednaProfileLinkTranslate"
        target="_blank"
      >
        <i class="btn-icon-left cqi-sm cqi-external-link"></i>
        <span>{{ t('zeroData.goToEdnaProfileLink.text') }}</span>
      </a>
    </div>
  </ng-template>
</ng-container>

import { create, each, enforce, group, omitWhen, test } from 'vest';

import { TriggerChainStepSendingConditions } from '@panel/app/http/trigger-chain/internal-types';

export const triggerChainSendingConditionStepValidator = create((step: TriggerChainStepSendingConditions) => {
  group(step.uuid, () => {
    test('name', () => {
      enforce(step.name).isNotEmpty();
    });

    test('meta.nextStep', () => {
      enforce(step.meta.nextStep).isNotNull();
    });

    const hasTriggerType =
      step.meta.triggerTypes.leaveSiteAttemptTrigger ||
      step.meta.triggerTypes.openedWebPageTriggers.length > 0 ||
      step.meta.triggerTypes.openedSdkPageTriggers.length > 0;

    omitWhen(hasTriggerType, () => {
      test('meta.triggers', () => {
        enforce(step.meta.triggers).isNotEmpty();
      });

      each(step.meta.triggers, (trigger) => {
        test('meta.triggers', () => {
          enforce(trigger).isNotEmpty();
        });
      });
    });

    const hasEventTriggers = step.meta.triggers.length > 0;
    const hasLeaveSiteAttemptTrigger = step.meta.triggerTypes.leaveSiteAttemptTrigger;
    const hasOpenedWebPageTriggers = step.meta.triggerTypes.openedWebPageTriggers.length > 0;
    const hasOpenedSdkPageTriggers = step.meta.triggerTypes.openedSdkPageTriggers.length > 0;

    omitWhen(hasEventTriggers || hasLeaveSiteAttemptTrigger || hasOpenedSdkPageTriggers, () => {
      test('meta.triggerTypes', () => {
        enforce(step.meta.triggerTypes.openedWebPageTriggers).isNotEmpty();
      });

      each(step.meta.triggerTypes.openedWebPageTriggers, (trigger) => {
        test('meta.triggerTypes', () => {
          enforce(trigger.url).isNotEmpty();
        });
      });
    });

    omitWhen(hasEventTriggers || hasLeaveSiteAttemptTrigger || hasOpenedWebPageTriggers, () => {
      test('meta.triggerTypes', () => {
        enforce(step.meta.triggerTypes.openedSdkPageTriggers).isNotEmpty();
      });

      each(step.meta.triggerTypes.openedSdkPageTriggers, (trigger) => {
        test('meta.triggerTypes', () => {
          enforce(trigger.sdkPage).isNotEmpty();
        });
      });
    });
  });
});

import './integration/integration.module';

import { module } from 'angular';

require('./auth/auth.module');
require('./conversation-frame/conversation-frame.module');

module('myApp.models', [
  'myApp.models.auth',
  'myApp.models.conversationFrame',
  'myApp.models.integration',
]);

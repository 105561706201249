/**
 * Контроллер для статистики сообщения из триггерной цепочки
 */
import { SENDING_TYPES } from '../../../../app/http/message/message.constants';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular
    .module('myApp.triggerChains.detailedStatistics')
    .controller('CqTriggerChainDetailedStatisticsController', CqTriggerChainDetailedStatisticsController);

  function CqTriggerChainDetailedStatisticsController(
    $filter,
    $scope,
    $translate,
    $uibModal,
    moment,
    CAMELED_SENDING_TYPES,
    DATASET_TYPES,
    TRIGGER_CHAIN_DETAILED_STATISTICS_TABS,
    caseStyleHelper,
    chartHelper,
    dateRangePickerHelper,
    messageModel,
    messagePartModel,
    triggerChainTrackService,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.TRIGGER_CHAIN_DETAILED_STATISTICS_TABS = TRIGGER_CHAIN_DETAILED_STATISTICS_TABS;
      vm.currentTab = TRIGGER_CHAIN_DETAILED_STATISTICS_TABS.GENERAL; // выбранная вкладка
      vm.getBlockPopupCount = getBlockPopupCount;
      vm.getSendings = getSendings;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.messageParts = messagePartModel.filterMessageParts(vm.currentMessage.activeTestGroup.parts); // варианты сообщения
      vm.onSetSendingsTab = onSetSendingsTab;
      vm.openUser = openUser;
      vm.SENDING_TYPES = SENDING_TYPES;
      vm.statisticsRange = {
        // датапикер на вкладке общей статистики
        dates: {
          // период отображения статистики
          startDate:
            moment().startOf('day').diff(moment(vm.currentMessage.created).startOf('day'), 'days') > 6
              ? moment().subtract(6, 'days').startOf('day')
              : moment(vm.currentMessage.created).startOf('day'),
          endDate: moment().endOf('day'),
        },
        options: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          dateLimit: {
            quarters: 1,
          },
          eventHandlers: {
            'show.daterangepicker': trackClickDatepicker,
          },
          maxDate: moment().endOf('day'),
          minDate: vm.currentMessage.created.isSameOrBefore('2017-06-05', 'day')
            ? moment('2017-06-05')
            : moment(vm.currentMessage.created).startOf('day'),
          opens: 'left',
          parentEl: '.datepicker-holder',
        }),
      };
      vm.trackClickSendingFilter = trackClickSendingFilter;
      vm.trackEnterInTab = trackEnterInTab;
      vm.sendings = {
        // данные для вкладки 'Пользователи'
        currentStatistics: {}, // статистика для выбранного варианта сообщения из выбранной тест группы за выбранный промежуток времени
        currentMessagePart: messagePartModel.getDefault(), // выбранный вариант сообщения в фильтре
        currentSendingType: SENDING_TYPES.SENDED, // выбранный тип действий получателей сообщения
        dateRangePickerOptions: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          dateLimit: {
            quarters: 1,
          },
          maxDate: moment().endOf('day'),
          minDate: moment(vm.currentMessage.created).startOf('day'),
          opens: 'right',
        }),
        forceReload: false, // флаг принудительной перезагрузки таблицы пользователей
        isRequestPerformed: false, // флаг выполнения запроса на получения пользователей в текущий момент времени
        paginatorParams: null, // параметры пагинации
        range: {
          // выбранный промежуток времени
          startDate:
            moment().startOf('day').diff(moment(vm.currentMessage.created).startOf('day'), 'days') > 6
              ? moment().subtract(6, 'days').startOf('day')
              : moment(vm.currentMessage.created).startOf('day'),
          endDate: moment().endOf('day'),
        },
        sendings: [], // список пользователей для таблицы
      };

      // изначально в currentMessage.statistics содержится статистика за всё время, её сохраняем в другое поле, потому что в это, для универсализации работы в этом контроллере, будет записана статистика за период
      vm.currentMessage.allTimeStatistics = vm.currentMessage.statistics;

      // получаем получателей по всему сообщению
      getSendingsStatistics(vm.sendings.range.startDate, vm.sendings.range.endDate);

      // !!! здесь используется имеено $watchCollection, а не $watchGroup! Почему - читать тут: https://github.com/angular/angular.js/issues/8671
      // !!! просто использовать watch тоже нельзя, т.к. обработчик почему-то начинает вызываться бесконечно
      // если изменился хотя бы один из параметров - надо по-новой тянуть пользователей. Если изменились даты или вариант сообщения - надо ещё и статистику по этому варианту получать
      $scope.$watchCollection(
        '[vm.sendings.currentMessagePart, vm.sendings.range.startDate, vm.sendings.range.endDate, vm.sendings.currentSendingType]',
        function (newValues, oldValues) {
          // изменение любого параметра влечёт за собой полную перезагрузку страницы пользователей
          vm.sendings.forceReload = true;

          getSendings(newValues[0], newValues[1], newValues[2], newValues[3]);
          if (newValues[0] != oldValues[0] || newValues[1] != oldValues[1] || newValues[2] != oldValues[2]) {
            getSendingsStatistics(newValues[1], newValues[2]);
          }
        },
      );
    }
    /**
     * Получение пользователей
     *
     * @param {Object} messagePart Вариант сообщения
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @param {SENDING_TYPES} sendingType Тип действия получателя сообщения
     * @return {Promise}
     */
    function getSendings(messagePart, startDate, endDate, sendingType) {
      vm.sendings.currentSendingType = sendingType;
      vm.sendings.isRequestPerformed = true;

      // если идёт перезагрукзка таблицы пользователей - всё обнуляем
      if (vm.sendings.forceReload) {
        vm.sendings.sendings = [];
        vm.sendings.paginatorParams = null;
        vm.sendings.forceReload = false;
      }

      return firstValueFrom(
        messageModel.getMessageSendings(
          vm.currentMessage.id,
          startDate,
          endDate,
          sendingType,
          vm.sendings.paginatorParams,
          true,
        ),
      )
        .then(getSendingsSuccess)
        .finally(getSendingsFinally);

      function getSendingsSuccess(data) {
        // защита от быстрого кликания пользователем по значениям фильтра
        if (
          vm.sendings.currentMessagePart.id == messagePart.id &&
          startDate.isSame(vm.sendings.range.startDate) &&
          endDate.isSame(vm.sendings.range.endDate) &&
          sendingType == vm.sendings.currentSendingType
        ) {
          vm.sendings.sendings.push.apply(vm.sendings.sendings, data.sendings);
          vm.sendings.paginatorParams = data.paginatorParams;
        }

        return data;
      }

      function getSendingsFinally() {
        vm.sendings.isRequestPerformed = false;
      }
    }

    /**
     * Получение статистики для вкладки с пользователями, для вывода её в большие кнопки
     *
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @return {Promise}
     */
    function getSendingsStatistics(startDate, endDate) {
      return firstValueFrom(messageModel.getMessageStatistics(vm.currentMessage.id, startDate, endDate)).then(
        getStatisticsSuccess,
      );

      function getStatisticsSuccess(statistics) {
        vm.sendings.currentStatistics = statistics;
        return vm.sendings.currentStatistics;
      }
    }

    /**
     * Подсчёт количества блочных поп-апов в сообщении
     *
     * @returns {number}
     */
    function getBlockPopupCount() {
      return $filter('filter')(vm.messageParts, filterBlockPopups).length;

      function filterBlockPopups(messagePart) {
        return (
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
        );
      }
    }

    /**
     * Открытие модалки с пользователем
     *
     * @param {String} userId ID пользователя
     */
    function openUser(userId) {
      $uibModal.open({
        component: 'cqUserCardModal',
        resolve: {
          modalWindowParams: () => {
            return {
              billingInfo: vm.billingInfo,
              currentApp: vm.currentApp,
              djangoUser: vm.djangoUser,
              onRedirectConversationClick: true,
              userId: userId,
              telegramIntegrations: vm.telegramIntegrations,
            };
          },
        },
        size: 'lg',
        windowClass: 'user-card-modal',
      });
    }

    /**
     * Функция перехода на вкладку с пользователями
     *
     * @param {Object} messagePart Вариант сообщения из тест-группы
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @param {SENDING_TYPES} sendingType Тип действия получателя сообщения
     */
    function onSetSendingsTab(messagePart, startDate, endDate, sendingType) {
      vm.sendings.currentMessagePart = messagePart;
      vm.sendings.range.startDate = startDate;
      vm.sendings.range.endDate = endDate;
      vm.sendings.currentSendingType = sendingType;

      vm.currentTab = TRIGGER_CHAIN_DETAILED_STATISTICS_TABS.SENDINGS;
    }

    /**
     * Трек клика на датапикер
     */
    function trackClickDatepicker() {
      triggerChainTrackService.trackClickOnDatePickerInChainMessageStatistics(vm.currentMessage.name);
    }

    /**
     * Трек клика на любой из фильтров по пользователям
     */
    function trackClickSendingFilter() {
      triggerChainTrackService.trackClickOnFilterByUserInChainMessageStatistics(vm.currentMessage.name);
    }

    /**
     * Трек захода на вкладку
     *
     * @param {TRIGGER_CHAIN_DETAILED_STATISTICS_TABS} tab Вкладка, на которую зашёл пользователь
     */
    function trackEnterInTab(tab) {
      var tabDescriptions = {};

      tabDescriptions[TRIGGER_CHAIN_DETAILED_STATISTICS_TABS.GENERAL.VALUE] = 'Общая статистика';
      tabDescriptions[TRIGGER_CHAIN_DETAILED_STATISTICS_TABS.SENDINGS.VALUE] = 'Пользователи';

      triggerChainTrackService.trackTransitionOnTabInStatistics(tabDescriptions[tab.VALUE], vm.currentMessage.name);
    }
  }
})();

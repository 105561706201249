import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { IPromise } from 'angular';
import { filter } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import { firstValueFrom } from 'rxjs';
import URI from 'urijs';

import { environment } from '@environment';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { POPUP_BACKGROUND_TYPES } from '@http/message-part/message-part.constants';
import { MessageUtilsModel } from '@http/message-utils/message-utils.model';
import {
  POPUP_BLOCK_BACKGROUND_POSITION_TYPES,
  POPUP_BLOCK_BACKGROUND_TYPES,
  POPUP_BLOCK_CURRENCY_POSITION_TYPES,
  POPUP_BLOCK_CURRENCY_POSITIONS_BY_CODE,
  POPUP_BLOCK_CURRENCY_SYMBOLS_BY_CODE,
  POPUP_BLOCK_TYPES,
  POPUP_BUTTON_BLOCK_TYPES,
} from '@panel/app/services/popup-block/popup-block.constants';
import { PropertyModel } from '@http/property/property.model';

@Injectable({
  providedIn: 'root',
})
export class PopupBlockModel {
  constructor(
    private readonly eventTypeModel: EventTypeModel,
    private readonly messageUtilsModel: MessageUtilsModel,
    private readonly propertyModel: PropertyModel,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Получение позиционирования фона у блока
   */
  getBackgroundPositionType(popupBlockParams: any): any {
    const backgroundPositionParams = this.getBackgroundPositionParams();
    let backgroundPositionType: any;

    for (let key in backgroundPositionParams) {
      if (backgroundPositionParams.hasOwnProperty(key)) {
        if (
          backgroundPositionParams[key].backgroundPosition == popupBlockParams.backgroundPosition &&
          backgroundPositionParams[key].backgroundRepeat == popupBlockParams.backgroundRepeat &&
          backgroundPositionParams[key].backgroundSize == popupBlockParams.backgroundSize
        ) {
          backgroundPositionType = key;
          break;
        }
      }
    }

    return backgroundPositionType;
  }

  /**
   * Получение блока со стандартными настройками по его типу
   */
  getDefault(popupBlockType: any, popupBlockParams: any = {}): any {
    return {
      type: popupBlockType,
      params: { ...this.getDefaultPopupBlockParams(popupBlockType), ...cloneDeep(popupBlockParams) },
    };
  }

  /**
   * Получение дефолтной валюты
   */
  getDefaultCurrency(): any {
    return {
      position: POPUP_BLOCK_CURRENCY_POSITION_TYPES.RIGHT,
      symbol: '',
    };
  }

  /**
   * Получение стандартных настроек инпута
   */
  getDefaultInputParams(): any {
    return this.getDefaultPopupBlockParams(POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON).inputs[0];
  }

  /**
   * Связывание блока поп-апа с типами событий
   */
  linkWithEventTypes(popupBlock: any, eventTypes: any[]): void {
    let eventType: any; // найденный тип события по имени

    switch (popupBlock.type) {
      case POPUP_BLOCK_TYPES.BUTTON:
      case POPUP_BLOCK_TYPES.FOOTER:
      case POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON:
      case POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON:
        if (popupBlock.params.eventType) {
          eventType = filter(eventTypes, { name: popupBlock.params.eventType })[0];
          // NOTE: в теории не может быть такого, чтобы eventType отсутствовал, но проверку на всякий случай всё равно поставил
          if (eventType) {
            popupBlock.params.eventType = eventType;
          } else {
            popupBlock.params.eventType = this.eventTypeModel.getDefault(popupBlock.params.eventType);
          }
        }
        break;
    }
  }

  /**
   * Связывание блока поп-апа со свойствами пользователя
   */
  linkWithUserProperties(popupBlock: any, userProperties: any[]): void {
    let userProperty: any; // найденное свойство пользователя по имени

    switch (popupBlock.type) {
      case POPUP_BLOCK_TYPES.FOOTER:
      case POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON:
        userProperty = filter(userProperties, { name: popupBlock.params.userProperty })[0];
        // NOTE: в теории не может быть такого, чтобы userProperty отсутствовал, но проверку на всякий случай всё равно поставил
        if (userProperty) {
          popupBlock.params.userProperty = userProperty;
        } else {
          popupBlock.params.userProperty = this.propertyModel.getDefaultUserProperty(popupBlock.params.userProperty);
        }
        break;
      case POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON:
        for (let i = 0; i < popupBlock.params.inputs.length; i++) {
          let input = popupBlock.params.inputs[i];
          userProperty = filter(userProperties, { name: input.userProperty })[0];
          // NOTE: в теории не может быть такого, чтобы userProperty отсутствовал, но проверку на всякий случай всё равно поставил
          if (userProperty) {
            input.userProperty = userProperty;
          } else {
            input.userProperty = this.propertyModel.getDefaultUserProperty(input.userProperty);
          }
        }
        break;
    }
  }

  /**
   * Парсинг блока поп-апа во внутренний формат админки
   */
  parseToInternalFormat(popupBlock: any, properties?: any): any {
    let parsedPopupBlock = JSON.parse(JSON.stringify(popupBlock));

    switch (parsedPopupBlock.type) {
      case POPUP_BLOCK_TYPES.IMAGE:
        parsedPopupBlock.params.backgroundImage =
          this.messageUtilsModel.getPathToImages(popupBlock.params.backgroundImage) + popupBlock.params.backgroundImage;
        break;
      case POPUP_BLOCK_TYPES.TEXT:
        if (parsedPopupBlock.params.backgroundImage) {
          parsedPopupBlock.params.backgroundImage =
            this.messageUtilsModel.getPathToImages(popupBlock.params.backgroundImage) +
            popupBlock.params.backgroundImage;
        }
        break;
      case POPUP_BLOCK_TYPES.VIDEO:
        parsedPopupBlock.params.url = new URI(popupBlock.params.url);
        break;
    }

    if (properties) {
      this.linkWithEventTypes(parsedPopupBlock, properties.eventTypes);
      this.linkWithUserProperties(parsedPopupBlock, properties.userProps);
    }

    return parsedPopupBlock;
  }

  /**
   * Парсинг блока поп-апа в формат сервера
   */
  parseToServerFormat(popupBlock: any): any {
    let parsedPopupBlock = JSON.parse(JSON.stringify(popupBlock));

    switch (parsedPopupBlock.type) {
      case POPUP_BLOCK_TYPES.IMAGE:
        // FIXME: по-хорошему тут бы надо проверять instanceof File и в зависимости от этого за��ружать картинку на сервер
        parsedPopupBlock.params.backgroundImage = popupBlock.params.backgroundImage.split(
          this.messageUtilsModel.getPathToImages(popupBlock.params.backgroundImage),
        )[1];
        break;
      case POPUP_BLOCK_TYPES.TEXT:
        if (popupBlock.params.backgroundImage && !(popupBlock.params.backgroundImage instanceof File)) {
          // если ссылка на ка��тинку уже была в блоке - нужно её остави��ь
          parsedPopupBlock.params.backgroundImage = popupBlock.params.backgroundImage.split(
            this.messageUtilsModel.getPathToImages(popupBlock.params.backgroundImage),
          )[1];
        } else {
          parsedPopupBlock.params.backgroundImage = '';
        }
        break;
      case POPUP_BLOCK_TYPES.VIDEO:
        parsedPopupBlock.params.url = popupBlock.params.url.toString();
        break;
    }

    this.unlinkEventTypes(parsedPopupBlock);
    this.unlinkUserProperties(parsedPopupBlock);

    return parsedPopupBlock;
  }

  /**
   * Сохранение дополнительных данных блоков, для которых нужно делать отдельные запросы на сервер
   */
  saveAdditionalData(appId: any, popupBlock: any): Promise<any> {
    const promises = [];

    switch (popupBlock.type) {
      case POPUP_BLOCK_TYPES.BUTTON:
        promises.push(this.saveButtonBlockData(appId, popupBlock.params));
        break;
      case POPUP_BLOCK_TYPES.FOOTER:
        promises.push(this.saveFooterBlockData(appId, popupBlock.params));
        break;
      case POPUP_BLOCK_TYPES.IMAGE:
        promises.push(this.saveImageBlockData(popupBlock.params));
        break;
      case POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON:
        promises.push(this.saveInputWithBigButtonBlockData(appId, popupBlock.params));
        break;
      case POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON:
        promises.push(this.saveInputWithButtonBlockData(appId, popupBlock.params));
        break;
      case POPUP_BLOCK_TYPES.TEXT:
        promises.push(this.saveTextBlockData(popupBlock.params));
        break;
    }

    if (promises.length) {
      return Promise.all(promises);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Создание типа события для блока
   */
  private createEventType(appId: any, popupBlockParams: any): IPromise<any> {
    if (angular.isObject(popupBlockParams.eventType)) {
      return firstValueFrom(this.eventTypeModel.create(appId, popupBlockParams.eventType.name)).then(
        (eventType: any) => {
          popupBlockParams.eventType = eventType;
        },
      );
    } else if (angular.isString(popupBlockParams.eventType)) {
      return firstValueFrom(this.eventTypeModel.create(appId, popupBlockParams.eventType)).then((eventType: any) => {
        popupBlockParams.eventType = eventType;
      });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Создание свойства пользователя для блока
   */
  private createUserProperty(appId: any, popupBlockParams: any): IPromise<any> {
    if (angular.isObject(popupBlockParams.userProperty)) {
      return firstValueFrom(this.propertyModel.createUserProperty(appId, popupBlockParams.userProperty.name)).then(
        (userProperty) => {
          popupBlockParams.userProperty = userProperty;
        },
      );
    } else if (angular.isString(popupBlockParams.userProperty)) {
      return firstValueFrom(this.propertyModel.createUserProperty(appId, popupBlockParams.userProperty)).then(
        (userProperty: any) => {
          popupBlockParams.userProperty = userProperty;
        },
      );
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Сохранение данных в блоке Кнопка
   */
  private saveButtonBlockData(appId: any, popupBlockParams: any): IPromise<any> {
    return this.createEventType(appId, popupBlockParams);
  }

  /**
   * Сохранение данных в блоке Футер
   */
  private saveFooterBlockData(appId: any, popupBlockParams: any): Promise<any> {
    const promises = [];
    promises.push(this.createEventType(appId, popupBlockParams));
    promises.push(this.createUserProperty(appId, popupBlockParams));
    return Promise.all(promises);
  }

  /**
   * Сохранение данных в блоке Картинка
   */
  private saveImageBlockData(popupBlockParams: any): Promise<any> {
    if (popupBlockParams.backgroundImage instanceof File) {
      return firstValueFrom(this.messageUtilsModel.saveImage(popupBlockParams.backgroundImage)).then(
        (imageUrl: any) => {
          popupBlockParams.backgroundImage = imageUrl;
        },
      );
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Сохранение данных в блоке Инпут с большой кнопкой
   */
  private saveInputWithBigButtonBlockData(appId: any, popupBlockParams: any): Promise<any> {
    const promises = [];
    promises.push(this.createEventType(appId, popupBlockParams));
    for (let i = 0; i < popupBlockParams.inputs.length; i++) {
      const input = popupBlockParams.inputs[i];
      promises.push(this.createUserProperty(appId, input));
    }
    return Promise.all(promises);
  }

  /**
   * Сохранение данных в блоке Инпут с кнопкой
   */
  private saveInputWithButtonBlockData(appId: any, popupBlockParams: any): Promise<any> {
    const promises = [];
    promises.push(this.createEventType(appId, popupBlockParams));
    promises.push(this.createUserProperty(appId, popupBlockParams));
    return Promise.all(promises);
  }

  /**
   * Сохранение данных в блоке Текст
   */
  private saveTextBlockData(popupBlockParams: any): Promise<any> {
    if (
      popupBlockParams.backgroundType === POPUP_BLOCK_BACKGROUND_TYPES.IMAGE &&
      popupBlockParams.backgroundImage instanceof File
    ) {
      return firstValueFrom(this.messageUtilsModel.saveImage(popupBlockParams.backgroundImage)).then(
        (imageUrl: any) => {
          popupBlockParams.backgroundImage = imageUrl;
        },
      );
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Сохранение фоновой картинки всего попапа
   */
  saveBackgroundImage(popupParams: any): Promise<any> {
    if (popupParams.backgroundType === POPUP_BACKGROUND_TYPES.IMAGE && popupParams.backgroundImage instanceof File) {
      return firstValueFrom(this.messageUtilsModel.saveImage(popupParams.backgroundImage)).then((imageUrl: any) => {
        popupParams.backgroundImage = imageUrl;
      });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Установка блоку параметров позиционирования фона
   */
  setBackgroundPosition(popupBlockParams: any, backgroundPosition: any): void {
    const backgroundPositionParams = this.getBackgroundPositionParams();

    popupBlockParams.backgroundPosition = backgroundPositionParams[backgroundPosition].backgroundPosition;
    popupBlockParams.backgroundRepeat = backgroundPositionParams[backgroundPosition].backgroundRepeat;
    popupBlockParams.backgroundSize = backgroundPositionParams[backgroundPosition].backgroundSize;
  }

  /**
   * Получение CSS-стилей для каждого типа фона
   */
  getBackgroundPositionParams(): any {
    const backgroundPositionParams: any = {};

    backgroundPositionParams[POPUP_BLOCK_BACKGROUND_POSITION_TYPES.CONTAIN] = {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    };

    backgroundPositionParams[POPUP_BLOCK_BACKGROUND_POSITION_TYPES.COVER] = {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };

    backgroundPositionParams[POPUP_BLOCK_BACKGROUND_POSITION_TYPES.REPEAT] = {
      // NOTE: по идее, это initial-значения свойств, но они всё равно прописаны на всякий случай, может быть в некоторых браузерах initial-значения другие
      backgroundPosition: 'top left',
      backgroundRepeat: 'repeat',
      backgroundSize: 'auto',
    };

    backgroundPositionParams[POPUP_BLOCK_BACKGROUND_POSITION_TYPES.STRETCH] = {
      backgroundPosition: 'top left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
    };

    return backgroundPositionParams;
  }

  /**
   * Настройки по умолчанию для каждого типа блоков поп-апа
   */
  getDefaultPopupBlockParams(popupBlockType: any): any {
    const defaultPopupBlockParams: any = {};

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.BUTTON] = {
      autoWidth: true,
      backgroundColor: '#000000',
      eventType: null,
      fullWidth: false,
      link: '',
      text: this.translocoService.translate('models.popupBlock.defaultParams.' + POPUP_BLOCK_TYPES.BUTTON + '.text'),
      textColor: '#ffffff',
      type: POPUP_BUTTON_BLOCK_TYPES.LINK,
      width: '100',
    };

    const defaultCurrency = this.getDefaultCurrency();
    defaultCurrency.position = POPUP_BLOCK_CURRENCY_POSITIONS_BY_CODE[environment.currency.currency];
    defaultCurrency.symbol = POPUP_BLOCK_CURRENCY_SYMBOLS_BY_CODE[environment.currency.currency];
    defaultPopupBlockParams[POPUP_BLOCK_TYPES.CART] = {
      currency: defaultCurrency,
      itemsCount: 3,
      priceAndNameColor: '#000000',
      buttonsColor: '#ffffff',
    };

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.FOOTER] = {
      borderTopColor: '#e6e6e6',
      buttonBackgroundColor: '#000000',
      buttonText: this.translocoService.translate(
        'models.popupBlock.defaultParams.' + POPUP_BLOCK_TYPES.FOOTER + '.buttonText',
      ),
      buttonTextColor: '#ffffff',
      dataCollectionCheckboxColor: '#000000',
      dataCollectionConsentColor: '#777777',
      eventType: null,
      inputBackgroundColor: '#ffffff',
      inputTextColor: '#000000',
      placeholder: this.translocoService.translate(
        'models.popupBlock.defaultParams.' + POPUP_BLOCK_TYPES.FOOTER + '.placeholder',
      ),
      privacyPolicyUrlColor: '#0000ee',
      showDataCollectionConsent: false,
      userProperty: null,
    };

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.HTML] = {
      html: '',
    };

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.IMAGE] = {
      backgroundImage: '',
      height: '100',
    };
    this.setBackgroundPosition(
      defaultPopupBlockParams[POPUP_BLOCK_TYPES.IMAGE],
      POPUP_BLOCK_BACKGROUND_POSITION_TYPES.CONTAIN,
    );

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.INDENT] = {
      height: '0',
    };

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON] = {
      buttonBackgroundColor: '#000000',
      buttonText: this.translocoService.translate(
        'models.popupBlock.defaultParams.' + POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON + '.buttonText',
      ),
      buttonTextColor: '#ffffff',
      dataCollectionCheckboxColor: '#000000',
      dataCollectionConsentColor: '#777777',
      eventType: null,
      inputBackgroundColor: '#ffffff',
      inputBorderColor: '#e6e6e6',
      inputTextColor: '#000000',
      inputs: [
        {
          placeholder: this.translocoService.translate(
            'models.popupBlock.defaultParams.' + POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON + '.inputs.placeholder',
          ),
          userProperty: null,
        },
      ],
      privacyPolicyUrlColor: '#0000ee',
      showDataCollectionConsent: false,
    };

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON] = {
      buttonBackgroundColor: '#000000',
      buttonText: this.translocoService.translate(
        'models.popupBlock.defaultParams.' + POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON + '.buttonText',
      ),
      buttonTextColor: '#ffffff',
      dataCollectionCheckboxColor: '#000000',
      dataCollectionConsentColor: '#777777',
      eventType: null,
      inputBackgroundColor: '#ffffff',
      inputBorderColor: '#e6e6e6',
      inputTextColor: '#000000',
      placeholder: this.translocoService.translate(
        'models.popupBlock.defaultParams.' + POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON + '.placeholder',
      ),
      privacyPolicyUrlColor: '#0000ee',
      showDataCollectionConsent: false,
      userProperty: null,
    };

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.TEXT] = {
      autoMinHeight: true,
      backgroundColor: '#ffffff',
      backgroundImage: '',
      backgroundType: POPUP_BLOCK_BACKGROUND_TYPES.TRANSPARENT,
      minHeight: '200',
      text: '',
    };
    this.setBackgroundPosition(
      defaultPopupBlockParams[POPUP_BLOCK_TYPES.TEXT],
      POPUP_BLOCK_BACKGROUND_POSITION_TYPES.CONTAIN,
    );

    defaultPopupBlockParams[POPUP_BLOCK_TYPES.VIDEO] = {
      autoplay: false,
      embeddableUrl: '',
      url: '',
    };

    return defaultPopupBlockParams[popupBlockType];
  }

  /**
   * "Отвязывание" типов событий, замена их на имена
   */
  unlinkEventTypes(popupBlock: any): void {
    switch (popupBlock.type) {
      case POPUP_BLOCK_TYPES.BUTTON:
      case POPUP_BLOCK_TYPES.FOOTER:
      case POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON:
      case POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON:
        if (angular.isObject(popupBlock.params.eventType)) {
          popupBlock.params.eventType = popupBlock.params.eventType.name;
        }
        break;
    }
  }

  /**
   * "Отвязывание" свойств пользователей, замена их на имена
   */
  unlinkUserProperties(popupBlock: any): void {
    switch (popupBlock.type) {
      case POPUP_BLOCK_TYPES.FOOTER:
      case POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON:
        if (angular.isObject(popupBlock.params.userProperty)) {
          popupBlock.params.userProperty = popupBlock.params.userProperty.name;
        }
        break;
      case POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON:
        for (let i = 0; i < popupBlock.params.inputs.length; i++) {
          const input = popupBlock.params.inputs[i];

          if (angular.isObject(input.userProperty)) {
            input.userProperty = input.userProperty.name;
          }
        }
        break;
    }
  }
}

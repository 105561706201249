import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cq-message-filters[filter]',
  templateUrl: './message-filters.component.html',
  styleUrls: ['./message-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageFiltersComponent {
  @Input()
  filter!: any;
}

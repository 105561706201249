import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '@ng-web-apis/common';
import { UIRouter } from '@uirouter/core';

import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { ServiceInstallationToastService } from '@panel/app-old/shared/services/service-installation-toast/service-installation-toast.service';

@Component({
  selector: 'cq-install-script-modal',
  templateUrl: './install-script-modal.component.html',
  styleUrls: ['./install-script-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallScriptModalComponent implements OnInit {
  @Input()
  modalWindowParams: any;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly serviceInstallationToast: ServiceInstallationToastService,
    protected readonly uiRouter: UIRouter,
    @Inject(WINDOW) private readonly windowRef: Window,
  ) {}

  ngOnInit(): void {
    this.trackShowInstallScriptModal();

    this.serviceInstallationToast.hideToast();
  }

  /** Редирект на стартергайд */
  redirectToStarterGuide(): void {
    this.uiRouter.stateService
      .go('app.content.dashboard', {
        starterGuideStep: STARTER_GUIDE_STEPS.INSTALLATION_SERVICE,
      })
      .then(() => {
        this.ngbActiveModal.dismiss();
      });
  }

  /**
   * Трек клика по кнопке «Установить скрипт»
   */
  trackClickInstallCode(): void {
    this.carrotquestHelper.track('popup_clicked', {
      page_url: this.windowRef.location.pathname,
      type: 'install code to start',
    });
  }

  /**
   * Трек показа модалки с предложенеим установить скрипт
   */
  trackShowInstallScriptModal(): void {
    this.carrotquestHelper.track('popup_viewed', {
      page_url: this.windowRef.location.pathname,
      type: 'install code to start',
    });
  }
}

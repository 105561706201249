/**
 * Директива, используемая для ограничения минимальной ширины загружаемого изображения в пикселях
 */
(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('cqImageMinWidth', cqImageMinWidth);

  function cqImageMinWidth($q, fileHelper) {
    return {
      restrict: 'A',
      require: ['ngModel', 'cqFile'],
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var minWidth;
      var ngModelController = controller[0];

      if (ngModelController) {
        iAttrs.$observe('cqImageMinWidth', observe);
        ngModelController.$asyncValidators.minWidth = minWidthValidator;
      }

      /**
       * Возвращает ширину изображения
       *
       * @param {Object} imageSize размеры изображения
       */
      function getImageWidth(imageSize) {
        var deferred = $q.defer();

        if (minWidth && imageSize.width < minWidth) {
          deferred.reject();
        } else {
          deferred.resolve();
        }

        return deferred.promise;
      }

      /**
       * Валидатор, проверяющий соответствие минимальной допустимую ширину и ширину загружаемого изображения
       *
       * @param viewValue
       * @returns {Boolean}
       */
      function minWidthValidator(viewValue) {
        if (ngModelController.$isEmpty(viewValue)) {
          return $q.resolve();
        } else {
          return fileHelper.getImageSize(viewValue).then(getImageWidth);
        }
      }

      /**
       * Возвращает значение атрибута minWidth
       *
       * @param {String} newValue Значение атрибута minWidth
       */
      function observe(newValue) {
        minWidth = newValue;

        // нужно вызывать валидацию принудительно, т.к. $validators могут отработать раньше $observe
        ngModelController.$validate();
      }
    }
  }
})();

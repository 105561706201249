import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgMathPipesModule } from 'ngx-pipes';

import { CustomPriceCapModule } from '@panel/app/pages/subscription/pricing/custom-price-cap/custom-price-cap.module';
import { PricingAddonComponent } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addon/pricing-addon.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [PricingAddonComponent],
  exports: [PricingAddonComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, FormsModule, NgMathPipesModule, CustomPriceCapModule],
})
export class PricingAddonModule {}

(function () {
  'use strict';

  require('./team-members-group-editor.controller');

  angular
    .module('myApp.modals.teamMembersGroupEditor')
    .component('cqTeamMembersGroupEditorModal', cqTeamMembersGroupEditorModal());

  function cqTeamMembersGroupEditorModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp - текущее приложение
        // {Object=} group - Группа операторов
      },
      controller: 'CqTeamMembersGroupEditorController',
      controllerAs: 'vm',
      template: require('./team-members-group-editor.html'),
    };
  }
})();

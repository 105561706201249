/**
 * Фильтр, убирающий значения из исходного массива в том случае, если они присутствуют в другом массиве
 */
(function () {
  'use strict';

  angular.module('myApp.filters.notInArray').filter('notInArray', notInArray);

  function notInArray($filter) {
    return notInArrayFilter;

    /**
     * Фильтр значений в массиве valuesList, присутствующих в arrayForFilter
     *
     * @param {Array} valuesList Исходный массив
     * @param {Array} arrayForFilter Массив со значениями, которые необходимо убрать из valuesList
     * @param {String} propertyName Имя свойства в объекте, значение которого надо сравнивать
     *    Если присутствует - будет осуществляться проверка valuesList[].propertyName и arrayForFilter[].propertyName
     *    Если отсутствует - будет осуществляться проверка valuesList[] и arrayForFilter[].propertyName
     * @param {Boolean} onlyPropertyValues Флаг, показывающий хранятся ли в arrayForFilter только значения ключа propertyName
     *    Если присутствует - будет осуществляться проверка valuesList[].propertyName и arrayForFilter[].propertyName
     *    Если отсутствует - будет осуществляться проверка valuesList[].propertyName и arrayForFilter[]
     * @returns {Array} Отфильтрованный массив
     */
    function notInArrayFilter(valuesList, arrayForFilter, propertyName, onlyPropertyValues) {
      if (arrayForFilter) {
        return $filter('filter')(valuesList, function (value) {
          for (var i = 0; i < arrayForFilter.length; i++) {
            if (propertyName) {
              if (onlyPropertyValues && arrayForFilter[i] == value[propertyName]) {
                return false;
              } else if (
                !onlyPropertyValues &&
                arrayForFilter[i] &&
                arrayForFilter[i][propertyName] == value[propertyName]
              ) {
                return false;
              }
            } else {
              if (arrayForFilter[i] == value) {
                return false;
              }
            }
          }
          return true;
        });
      }
      return valuesList;
    }
  }
})();

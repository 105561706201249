<ng-container *tuiLet="subscriptionStore.billing$ | async as billing">
  <cq-name class="mb-20"></cq-name>

  <ul class="list-group">
    <li
      *ngIf="
        billing &&
        (billing.subscription.isActive || (billing.subscription.isCancelled && !billing.subscription.isTrial))
      "
      class="list-group-item"
    >
      <cq-cost></cq-cost>
    </li>
    <li
      *ngIf="
        billing &&
        (billing.subscription.isActive || (billing.subscription.isCancelled && !billing.subscription.isTrial))
      "
      class="list-group-item"
    >
      <cq-period></cq-period>
    </li>
    <li
      *ngIf="billing"
      class="list-group-item"
    >
      <cq-subscription-status
        [status]="billing.subscription.status"
        [statusText]="billing.subscription.getStatusText()"
      ></cq-subscription-status>
    </li>
    <li class="list-group-item">
      <cq-unique-users></cq-unique-users>
    </li>
    <li class="list-group-item">
      <cq-emails></cq-emails>
    </li>
  </ul>
</ng-container>

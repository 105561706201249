<ng-container *transloco="let t; read: 'quillTexEditorComponent'">
  <quill-editor
    class="cq-quill"
    [sanitize]="true"
    [(ngModel)]="quillContent"
    [placeholder]="placeholder"
    customToolbarPosition="bottom"
    (onEditorCreated)="onEditorCreated($event)"
    [formats]="formats"
    (onBlur)="blur$.next($event)"
  >
    <div quill-editor-toolbar>
      <div
        *ngIf="formats.length"
        class="card"
        [ngbCollapse]="!((quill && quill.hasFocus()) || linkEditorIsOpen)"
      >
        <div class="card-footer flex justify-content-around padding-5 no-border-top bg-light-secondary">
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['bold']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('bold')"
            (click)="format('bold')"
            [ngbTooltip]="t('boldButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-bold"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['italic']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('italic')"
            (click)="format('italic')"
            [ngbTooltip]="t('italicButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-italic"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['underline']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('underline')"
            (click)="format('underline')"
            [ngbTooltip]="t('underlineButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-underline"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['strike']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('strike')"
            (click)="format('strike')"
            [ngbTooltip]="t('strikeButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-strikethrough"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['link']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('link')"
            (click)="setLink()"
            [ngbTooltip]="t('linkButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-link"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['list']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('list', 'ordered')"
            (click)="format('list', 'ordered')"
            [ngbTooltip]="t('orderedButtonTooltip')"
            placement="top"
          >
            <i class="btn-icon cqi-sm cqi-list-ol"></i>
          </button>
          <button
            type="button"
            *ngIf="formatButtonAvailableMap['list']"
            class="btn btn-secondary btn-sm margin-top-5"
            [ngClass]="getQuillButtonClass('list', 'bullet')"
            (click)="format('list', 'bullet')"
            [ngbTooltip]="t('bulletButtonTooltip')"
            placement="top-right"
          >
            <i class="btn-icon cqi-sm cqi-list-ul"></i>
          </button>
        </div>
      </div>
    </div>
  </quill-editor>
</ng-container>

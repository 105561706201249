(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqChatDefaultNameController', CqChatDefaultNameController);

  function CqChatDefaultNameController(validationHelper) {
    let vm = this;

    /**
     * Максимальная длинна имени по умолчанию
     *
     * @type {number}
     */
    const NAME_MAX_LENGTH = 200;

    vm.$onInit = init;

    function init() {
      vm.DEFAULT_NAME_MAX_LENGTH = NAME_MAX_LENGTH;
      vm.validationHelper = validationHelper;
    }
  }
})();

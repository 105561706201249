import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { Stripe3dsModalComponent } from '@panel/app/pages/subscription/modals/stripe-3ds-modal/stripe-3ds-modal.component';

@NgModule({
  declarations: [Stripe3dsModalComponent],
  exports: [Stripe3dsModalComponent],
  imports: [CommonModule, TranslocoModule],
})
export class Stripe3dsModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AnswerToUserPropModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/answer-to-user-prop/answer-to-user-prop.module';
import { ButtonBlocksModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/button-blocks/button-blocks.module';
import { ContentBlocksModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/components/content-blocks/content-blocks.module';
import { TelegramPartEditorComponent } from '@panel/app/pages/trigger-messages/telegram-part-editor/telegram-part-editor.component';
import { IntegrationSelectModule } from '@panel/app/partials/integration-select/integration-select.module';
import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [TelegramPartEditorComponent],
  exports: [TelegramPartEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    NgbTooltipModule,
    NgSelectModule,
    SharedModule,
    AnswerToUserPropModule,
    ContentBlocksModule,
    ButtonBlocksModule,
    IntegrationSelectModule,
    PropertySelectorModule,
    ReactiveFormsModule,
  ],
})
export class TelegramPartEditorModule {}

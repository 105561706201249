(function () {
  'use strict';

  require('./trigger-chain-date-period-stats.controller');

  angular
    .module('myApp.triggerChains.datePeriodStats')
    .component('cqTriggerChainDatePeriodStats', cqTriggerChainDatePeriodStats());

  function cqTriggerChainDatePeriodStats() {
    return {
      bindings: {
        currentMessage: '=', // Сообщение из триггерной цепочки
        statisticsRange: '=', // Данные для датапикера HACK Этот объект — помойка из разных настроек, оставил так, как есть
      },
      controller: 'CqTriggerChainDatePeriodStatsController',
      controllerAs: 'vm',
      template: require('./trigger-chain-date-period-stats.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { WritePropertyValueComponent } from './write-property-value.component';

@NgModule({
  declarations: [WritePropertyValueComponent],
  imports: [CommonModule, ReactiveFormsModule, PropertySelectorModule, SharedModule],
  exports: [WritePropertyValueComponent],
})
export class WritePropertyValueModule {}

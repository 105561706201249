/**
 * Преобразование map в массив объектов
 *
 * @example
 * // вернёт [{key: 'amoFieldValue1', value: 'cqFieldValue1'}, {key: 'amoFieldValue2', value: 'cqFieldValue2'}]
 * mapToArray({'amoFieldValue1': 'cqFieldValue1', 'amoFieldValue2': 'cqFieldValue2'})
 *
 * @param map Map, необходимый преобразовать
 * @param keyName Название ключа, в котором будет храниться ключ map-а
 * @param valueName Название ключа, в котором будет храниться значение map-а
 */
export const mapToArray = (map: Record<string, any>, keyName = 'key', valueName = 'value') => {
  const arr: Array<Record<typeof keyName, typeof valueName>> = [];

  Object.keys(map).forEach((key) => {
    const newObj = {
      [keyName]: key,
      [valueName]: map[key],
    };

    arr.push(newObj);
  });

  return arr;
};

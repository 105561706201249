import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, finalize, take, takeUntil } from 'rxjs/operators';

import { App, AppModel } from '@http/app/app.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { DjangoUserTempData } from '@http/django-user/types/django-user-settings.type';
import { TeamMember } from '@http/team-member/team-member.types';
import { AutoAssignmentSettingsForm } from '@panel/app/pages/conversations-settings/shared/components/auto-assignment/auto-assignment.types';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { DestroyService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-auto-assignment',
  templateUrl: './auto-assignment.component.html',
  styleUrls: ['./auto-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...formSubmitTokenProviders, DestroyService],
})
export class AutoAssignmentComponent implements OnInit {
  /** Приложение */
  @Input({ required: true }) currentApp!: App;
  /** Django-пользователь */
  @Input({ required: true }) djangoUser!: DjangoUser;
  /** Временные данные django-пользователя */
  @Input({ required: true }) djangoUserTempData!: DjangoUserTempData;
  /** Члены команды */
  @Input({ required: true }) teamMembers!: TeamMember[];

  isApiRequestPerformed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  settingsForm!: FormGroup<AutoAssignmentSettingsForm>;

  constructor(
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSource: Subject<void>,
    private readonly appModel: AppModel,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly destroy$: DestroyService,
    private readonly djangoUserModel: DjangoUserModel,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.settingsForm = new FormGroup<AutoAssignmentSettingsForm>({
      active: new FormControl(this.getActiveState(), {
        nonNullable: true,
      }),
      teamMembers: new FormControl(this.teamMemberWithAutoAssignmentIds(), {
        nonNullable: true,
        validators: [Validators.required],
      }),
      type: new FormControl(this.getAssignmentTypeValue(), {
        nonNullable: true,
      }),
    });

    this.activeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((newValue) => {
      this.trackActiveValueChange(newValue);
    });
  }

  get activeControl() {
    return this.settingsForm.controls.active;
  }

  get teamMembersControl() {
    return this.settingsForm.controls.teamMembers;
  }

  get typeControl() {
    return this.settingsForm.controls.type;
  }

  saveSettings(): void {
    if (!this.activeControl.value) {
      this.isApiRequestPerformed$.next(true);

      this.saveAppSettings()
        .pipe(finalize(() => this.isApiRequestPerformed$.next(false)))
        .subscribe(() => {
          //@ts-ignore
          this.currentApp.settings.messenger_conversations_auto_assign_type = this.activeControl.value
            ? this.typeControl.value
            : 0;

          const readyForAutoAssignMembers = this.teamMembers.filter(
            (member) => member.conversationsAutoAssign?.readyForAutoAssign,
          );
          readyForAutoAssignMembers.forEach((member) => {
            member.conversationsAutoAssign = {
              openConversationsCounter: member.conversationsAutoAssign?.openConversationsCounter ?? 0,
              readyForAutoAssign: false,
            };
          });
          this.teamMembersControl.setValue([]);

          this.toastService.success(
            this.translocoService.translate('autoAssignmentComponent.saveSettingsSuccessToast'),
          );
        });
    } else {
      if (!this.settingsForm.valid) {
        this.formSubmitSource.next();
        return;
      }

      this.isApiRequestPerformed$.next(true);

      /** Важно сначала сохранить статус активности автораспределения,
       * а потом уже включать и выключать его у членов команды */
      this.saveAppSettings()
        .pipe(
          concatMap(() => this.saveDjangoUserSettings()),
          finalize(() => this.isApiRequestPerformed$.next(false)),
        )
        .subscribe(() => {
          //@ts-ignore
          this.currentApp.settings.messenger_conversations_auto_assign_type = this.activeControl.value
            ? this.typeControl.value
            : 0;

          this.toastService.success(
            this.translocoService.translate('autoAssignmentComponent.saveSettingsSuccessToast'),
          );
        });
    }
  }

  /** Включение/выключение автораспределения в аппе */
  private saveAppSettings(): Observable<void> {
    const params: any = {
      messenger_conversations_auto_assign_type: this.activeControl.value ? this.typeControl.value : 0,
    };

    return this.appModel.saveSettings(this.currentApp.id, params).pipe(take(1));
  }

  /** Включение/выключение автораспределения у членов команды */
  private saveDjangoUserSettings() {
    const selectedTeamMembersWithAutoAssignment = this.teamMembersControl.value; // IDs from the form control
    const currentAutoAssignedIds = this.teamMemberWithAutoAssignmentIds();

    const idsToEnable = selectedTeamMembersWithAutoAssignment.filter((id) => !currentAutoAssignedIds.includes(id));
    const idsToDisable = currentAutoAssignedIds.filter((id) => !selectedTeamMembersWithAutoAssignment.includes(id));

    const observables = [];

    if (idsToEnable.length > 0) {
      const enableObservable = this.djangoUserModel.enableAutoAssignBulk(idsToEnable).pipe(
        take(1),
        finalize(() => {
          idsToEnable.forEach((id) => {
            const member = this.teamMembers.find((member) => member.id === id);
            if (member) {
              member.conversationsAutoAssign = {
                openConversationsCounter: member.conversationsAutoAssign?.openConversationsCounter ?? 0,
                readyForAutoAssign: true,
              };
            }
          });
        }),
      );
      observables.push(enableObservable);
    }

    if (idsToDisable.length > 0) {
      const disableObservable = this.djangoUserModel.disableAutoAssignBulk(idsToDisable).pipe(
        take(1),
        finalize(() => {
          idsToDisable.forEach((id) => {
            const member = this.teamMembers.find((member) => member.id === id);
            if (member) {
              member.conversationsAutoAssign = {
                openConversationsCounter: member.conversationsAutoAssign?.openConversationsCounter ?? 0,
                readyForAutoAssign: false,
              };
            }
          });
        }),
      );
      observables.push(disableObservable);
    }

    /** Записываем активацию django-пользователя в фичу */
    const saveTempDataObservable = !this.djangoUserTempData.autoAssignActivated
      ? this.djangoUserModel
          .saveTempData(this.djangoUser.id, {
            ...this.djangoUserTempData,
            autoAssignActivated: true,
          })
          .pipe(take(1))
      : of(null);

    observables.push(saveTempDataObservable);

    return forkJoin(observables).pipe(take(1));
  }

  /** Включено или выключено автораспределение в аппе */
  private getActiveState() {
    if (!this.currentApp.settings.messenger_conversations_auto_assign_type) {
      return false;
    }

    if ([1, 2].includes(this.currentApp.settings.messenger_conversations_auto_assign_type)) {
      return true;
    }

    return false;
  }

  /** Получение типа автораспределения */
  private getAssignmentTypeValue() {
    if (!this.currentApp.settings.messenger_conversations_auto_assign_type) {
      return 1;
    }

    return this.currentApp.settings.messenger_conversations_auto_assign_type;
  }

  /** Получение всех членов команды с включенным автораспределением */
  private teamMemberWithAutoAssignmentIds() {
    return this.teamMembers
      .filter((member) => member.conversationsAutoAssign?.readyForAutoAssign)
      .map((teamMember) => teamMember.id);
  }

  private trackActiveValueChange(newValue: boolean) {
    const eventName = newValue
      ? 'Настройки диалогов - включил автораспределение'
      : 'Настройки диалогов - выключил автораспределение';

    this.carrotquestHelper.track(eventName);
  }
}

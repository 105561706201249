<ng-container *transloco="let t; read: 'originalInboundEmailViewModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="onClickHeaderCloseButton()"
      type="button"
      data-test="original-inbound-email-view-modal-header-close-button"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="margin-between-rows-5">
      <div>
        <strong>{{ t('sender') }} </strong>
        <span data-test="original-inbound-email-view-modal-sender">{{ sender }}</span>
      </div>
      <div>
        <strong>{{ t('recipient') }}</strong>
        <span data-test="original-inbound-email-view-modal-recipient">{{ recipient }}</span>
      </div>
      <div>
        <strong>{{ t('messageId') }}</strong>
        <span data-test="original-inbound-email-view-modal-message-id">{{ messageId }}</span>
      </div>
      <div>
        <strong>{{ t('subject') }}</strong>
        <span data-test="original-inbound-email-view-modal-subject">{{ subject }}</span>
      </div>
      <pre data-test="original-inbound-email-view-modal-body">{{ body }}</pre>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-primary"
      (click)="onClickFooterCloseButton()"
      type="button"
      data-test="original-inbound-email-view-modal-footer-close-button"
    >
      {{ 'general.close' | transloco }}
    </button>
  </div>
</ng-container>

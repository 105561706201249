import { InjectionToken } from '@angular/core';

import { TriggerChainTemplate } from '@http/trigger-chain-template/internal-types/trigger-chain-template.internal.type';

export type TriggerChainTemplateModalData = {
  isCreate: boolean;
  templates: TriggerChainTemplate[];
};

export const TRIGGER_CHAIN_TEMPLATE_MODAL_DATA_TOKEN = new InjectionToken<TriggerChainTemplateModalData>(
  'TriggerChainTemplateModal data provider',
);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { IntegrationService } from '@http/integration/services/integration.service';
import { VkFormsModule } from '@panel/app/pages/integrations/content/vk-forms/vk-forms.module';
import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { IntegrationsPartialsModule } from '@panel/app/pages/integrations/partials/integrations-partials.module';
import { SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

import { VkOldComponent } from './vk-old.component';

@NgModule({
  declarations: [VkOldComponent],
  exports: [VkOldComponent],
  imports: [
    CommonModule,
    IntegrationNameControlModule,
    IntegrationsPartialsModule,
    NgbAlertModule,
    TranslocoModule,
    VkFormsModule,
  ],
  providers: [IntegrationFactory, IntegrationService, SystemToastService, ToastService],
})
export class VkOldModule {}

/**
 * Директива превью браузера для настроек чата
 */
(function () {
  'use strict';

  require('./browser-frame.controller');

  angular.module('myApp.browserFrame').component('cqBrowserFrameAjs', cqBrowserFrame());

  function cqBrowserFrame() {
    return {
      bindings: {
        messagePartType: '<', // Тип сообщения
        theme: '@?', // Цветовая схема браузера
      },
      transclude: true,
      controller: 'CqBrowserFrameController',
      controllerAs: 'vm',
      template: require('./browser-frame.html'),
    };
  }
})();

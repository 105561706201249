import {
  PLAN_FEATURE,
  PLAN_FEATURE_BY_MESSAGE_PART_TYPE,
} from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { SUBSCRIPTION_STATUSES } from '../../../../app/services/billing-info/billing-info.constants';
import { PLAN_CAPABILITY_BY_MESSAGE_PART_TYPE } from '../../../../app/http/plan-capability/plan-capability.constants';
import { MESSAGE_TYPES } from '../../../../app/http/message/message.constants';
import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';
import { EMAIL_KARMA_STATUSES } from '../../../../app/http/email-karma/email-karma.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular
    .module('myApp.modals.sendManualMessage')
    .controller('SendManualMessageModalController', SendManualMessageModalController);

  function SendManualMessageModalController(
    $filter,
    $q,
    $scope,
    $translate,
    $uibModal,
    $uibModalInstance,
    USER_FILES_URL,
    billingInfoModel,
    carrotquestHelper,
    featureModel,
    messageModel,
    messageUtilsModel,
    messagePartModel,
    planFeatureAccessService,
    popupBlockModel,
    pushSettingsModel,
    utilsModel,
    billingInfo,
    currentApp,
    djangoUser,
    manualMessageParams,
  ) {
    const vm = this;
    const LIMIT_USERS_COUNT = featureModel.hasAccess(FEATURES.MANUAL_MESSAGE_EXTENDED_SIZE) ? 800000 : 100000; //Ограничение на отправку сообщения по количеству лидов
    const LIMIT_USERS_COUNT_FOR_TELEGRAM = 5000;

    vm.currentApp = currentApp;

    vm.accessToManualMessagesDelayedDelivery = planFeatureAccessService.getAccess(
      PLAN_FEATURE.MANUAL_MESSAGES_DELAYED_DELIVERY,
      vm.currentApp,
    );

    vm.appBlocks = {}; // информация о блокировках email-рассылок
    vm.billingInfo = billingInfo;
    vm.checkPermission = checkPermission;
    vm.checkSendEmailsCapability = checkSendEmailsCapability;
    vm.checkSendTelegramCapability = checkSendTelegramCapability;
    vm.disableSubmitButtonByKarma = disableSubmitButtonByKarma;
    vm.djangoUser = djangoUser;
    vm.featureModel = featureModel;
    vm.FEATURES = FEATURES;
    vm.isKarmaStatusSuccess = false; // флаг блокировки отправки email-сообщений
    vm.isSendMessagePerformed = true; // флаг выполнения запроса NOTE: изначально кнопку надо заблокировать, а при получении информации о блокировках - разблокировать, и проверять блокировку кнопки уже по полученным данным
    vm.message = messagePartModel.getDefault(); // контент сообщения
    vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
    vm.MESSAGE_TYPES = MESSAGE_TYPES;
    vm.messageReceiversCount = 0;
    vm.PLAN_CAPABILITY_BY_MESSAGE_PART_TYPE = PLAN_CAPABILITY_BY_MESSAGE_PART_TYPE;
    vm.popoverMessageReceiversMessage = popoverMessageReceiversMessage;
    vm.pushSettings = null;
    vm.scheduleMessage = scheduleMessage;
    vm.SUBSCRIPTION_STATUSES = SUBSCRIPTION_STATUSES;
    vm.userNames = ''; // имена пользователей
    vm.usersCount = 0; // количество пользователей для получения ручного сообщения
    vm.telegramIntegrations = manualMessageParams.telegramIntegrations || [];
    vm.isWhoCounterPopoverIsOpen = isWhoCounterPopoverIsOpen;
    vm.getWhoCounterPopover = getWhoCounterPopover;

    init();

    function init() {
      firstValueFrom(billingInfoModel.getAppBlocks(currentApp.id)).then(getAppBlocksSuccess);
      firstValueFrom(pushSettingsModel.get(currentApp.id)).then(getPushSettingsSuccess);

      $scope.$watch('vm.message.type', approximateMessageReceiversCount);
      $scope.$watch('vm.message.telegram.bodyJson.integration', () => {
        vm.message.type === MESSAGE_PART_TYPES.TELEGRAM && approximateMessageReceiversCount(vm.message.type);
      });
      $scope.$watch('vm.message.type', unDisableSubmitButtonWatch);
      $scope.$watch(
        'vm.message.type',
        () => {
          vm.accessToManualMessagesType = planFeatureAccessService.getAccess(
            PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.MANUAL][vm.message.type],
            vm.currentApp,
          );
        },
        true,
      );

      vm.message.type = manualMessageParams.type || vm.message.type;
      vm.userNames = manualMessageParams.userNames || vm.userNames;
      vm.usersCount = manualMessageParams.usersCount || vm.usersCount;
      vm.messageReceiversCount = angular.copy(vm.usersCount);

      //approximateMessageReceiversCount();

      function getAppBlocksSuccess(appBlocks) {
        vm.appBlocks = appBlocks;
        vm.isSendMessagePerformed = false;

        // watcher'ов надо вешать только после получения информации о блокировках, чтобы checkSendEmailsCapability выполнялась исходя из полученных данных
        $scope.$watch(
          'vm.message.type',
          function (newValue) {
            if (!checkSendEmailsCapability('blockEmails')) {
              openEmailsBlockedModal();
            } else if (!checkSendEmailsCapability('blockEmailsHardQuota')) {
              openEmailsBlockedBecauseOfHardQuotaModal();
            } else if (!checkSendEmailsCapability('hasHighBounceRate')) {
              openEmailsBlockedBecauseOfBounceRateModal();
            } else if (!checkSendEmailsCapability('hasHighComplaintRate')) {
              openEmailsBlockedBecauseOfComplaintRateModal();
            } else if (!checkSendEmailsCapability('blockBulkEmails')) {
              openBulkEmailsBlockedModal();
            } else if (!checkSendEmailsCapability('blockEmailsOverQuota')) {
              openEmailsBlockedBecauseOfOverQuotaModal();
            }
          },
          true,
        );
      }

      function getPushSettingsSuccess(pushSettings) {
        vm.pushSettings = pushSettings;
      }
    }

    function approximateMessageReceiversCount(messageType) {
      let tgIntegrationId;

      if (vm.message.type === MESSAGE_PART_TYPES.TELEGRAM) {
        tgIntegrationId = vm.message.telegram.bodyJson.integration;

        if (!tgIntegrationId) {
          // Без выбранной интеграции мы не сможем подсчитать количество
          vm.messageReceiversCount = 0;
          return;
        }
      }
      // HACK: временный костыль, связанный с тем, что этот массив раньше отсылался как строка ID'шников через запятую
      var manualIds = manualMessageParams.manualIds ? manualMessageParams.manualIds.join(',') : '';
      firstValueFrom(
        messageModel.getMessageReceiversCount(
          currentApp.id,
          messageType,
          manualIds,
          manualMessageParams.filters,
          tgIntegrationId,
        ),
      ).then(getMessageReceiversCountSuccess);

      function getMessageReceiversCountSuccess(receiversCount) {
        vm.messageReceiversCount = receiversCount.total;
      }
    }

    /**
     * Проверить удовлетворяет правилам о квоте, рассылка и количесве лидов за раз
     *
     * @param {Array.<String>|String} rules список правил
     * @return {Boolean} Вернётся true, если письма можно отправлять
     */
    function checkSendEmailsCapability(rules) {
      var emailsBlocked = false;

      if (vm.message.type == MESSAGE_PART_TYPES.EMAIL) {
        !angular.isArray(rules) && (rules = [rules]);

        for (var i = 0; i < rules.length && !emailsBlocked; i++) {
          var rule = rules[i];

          switch (rule) {
            case 'blockEmails':
              emailsBlocked = vm.appBlocks.blockEmails;
              break;
            case 'blockEmailsHardQuota':
              emailsBlocked =
                vm.appBlocks.blockEmailsHardQuota &&
                vm.appBlocks.emailHardLimit < vm.appBlocks.emailUsed + vm.usersCount;
              break;
            case 'hasHighBounceRate':
              emailsBlocked = vm.appBlocks.hasHighBounceRate && vm.appBlocks.blockAllEmails;
              break;
            case 'hasHighComplaintRate':
              emailsBlocked = vm.appBlocks.hasHighComplaintRate && vm.appBlocks.blockAllEmails;
              break;
            case 'blockBulkEmails':
              emailsBlocked = vm.appBlocks.blockBulkEmails && vm.usersCount > 1;
              break;
            case 'blockEmailsOverQuota':
              emailsBlocked =
                vm.appBlocks.blockEmailsOverQuota &&
                vm.appBlocks.emailPlanLimit < vm.appBlocks.emailUsed + vm.usersCount;
              break;
            case 'limitUsersCount':
              emailsBlocked = vm.usersCount > LIMIT_USERS_COUNT;
              break;
          }
        }
      }

      return !emailsBlocked;
    }

    /**
     * Проверить удовлетворяет правилам о квоте, рассылка и количесве лидов за раз
     *
     * @param {Array.<String>|String} rules список правил
     * @return {Boolean} Вернётся true, если письма можно отправлять
     */
    function checkSendTelegramCapability(rules) {
      let telegramBlocked = false;

      if (vm.message.type === MESSAGE_PART_TYPES.TELEGRAM) {
        !angular.isArray(rules) && (rules = [rules]);

        for (var i = 0; i < rules.length && !telegramBlocked; i++) {
          var rule = rules[i];

          switch (rule) {
            case 'limitUsersCount':
              telegramBlocked = vm.usersCount > LIMIT_USERS_COUNT_FOR_TELEGRAM;
              break;
          }
        }
      }

      return !telegramBlocked;
    }

    /**
     * Проверка формы на валидность и количество лидов для отправки
     *
     * @param {Boolean} valid Флаг валидности формы
     */
    function checkPermission(valid) {
      if (!valid) {
        vm.message.handleError && vm.message.handleError();
        return;
      }

      if (!checkSendEmailsCapability('limitUsersCount')) {
        trackOpenLimitUsersCountModal();
        openLimitUsersCountModal().then(confirmedSendMessage);
      } else if (!checkSendTelegramCapability('limitUsersCount')) {
        openLimitUsersCountModal('telegram', LIMIT_USERS_COUNT_FOR_TELEGRAM).then(sendMessage);
      } else {
        sendMessage();
      }

      function confirmedSendMessage() {
        trackMessageSentWithLimitUsersCount();
        sendMessage();
      }
    }

    /**
     * Открыт ли поповер количества отправляемых соощений
     * @return {boolean}
     */
    function isWhoCounterPopoverIsOpen() {
      return (
        !vm.checkSendEmailsCapability('limitUsersCount') ||
        !vm.checkSendTelegramCapability('limitUsersCount') ||
        vm.usersCount !== vm.messageReceiversCount
      );
    }

    /**
     * Получить текст для поповера количества отправок
     * @return {string}
     */
    function getWhoCounterPopover() {
      switch (true) {
        case !vm.checkSendEmailsCapability('limitUsersCount'):
          return $translate.instant(
            'modals.sendManualMessage.popoverLimitUsersMessage.email',
            { limitUsersCount: LIMIT_USERS_COUNT },
            'messageformat',
          );
        case !vm.checkSendTelegramCapability('limitUsersCount'):
          return $translate.instant(
            'modals.sendManualMessage.popoverLimitUsersMessage.telegram',
            { limitUsersCount: LIMIT_USERS_COUNT_FOR_TELEGRAM },
            'messageformat',
          );
        default:
          return vm.popoverMessageReceiversMessage(vm.message.type);
      }
    }

    /**
     * Дисейблит кнопку 'отправить', при заблокированной отправке email-сообщений
     * @param karmaStatus
     */
    function disableSubmitButtonByKarma(karmaStatus) {
      if (vm.message.type === vm.MESSAGE_PART_TYPES.EMAIL) {
        vm.isKarmaStatusSuccess = karmaStatus !== EMAIL_KARMA_STATUSES.BLOCKED;
      }
    }

    /**
     * Убирает дисейбл с кнопки 'отправить', для всех типов сообщений кроме 'email'
     * работает при переключении типа сообщения
     * @param messagePartType
     */
    function unDisableSubmitButtonWatch(messagePartType) {
      if (messagePartType !== vm.MESSAGE_PART_TYPES.EMAIL) {
        vm.isKarmaStatusSuccess = true;
      }
    }

    /**
     * Открыть поп-ап о блокиоровки рассылок без потверждения
     */
    function openBulkEmailsBlockedModal() {
      carrotquestHelper.track('Показан попап - "Ограничение массовых рассылок"');

      var bulkEmailsBlockedModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.sendManualMessage.bulkEmailsBlockedModal.heading'),
              body:
                '\
                <div class="margin-bottom-10">\
                ' +
                $translate.instant('modals.sendManualMessage.bulkEmailsBlockedModal.body.bulkEmails') +
                '\
                </div>\
                <div>\
                  ' +
                $translate.instant('modals.sendManualMessage.bulkEmailsBlockedModal.body.writeUs') +
                '\
                </div>\
              ',
              imgUrl: 'assets/img/default/users/image-barier.png',
              confirmButtonText: $translate.instant(
                'modals.sendManualMessage.bulkEmailsBlockedModal.confirmButtonText',
              ),
              cancelButtonClass: 'hidden',
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      bulkEmailsBlockedModal.result.then(
        angular.bind(
          null,
          carrotquestHelper.sendChatMessage,
          $translate.instant('modals.sendManualMessage.bulkEmailsBlockedModal.chatMessage'),
        ),
      );
    }

    /**
     * Открыть поп-ап о том, что емейлы заблокированы
     */
    function openEmailsBlockedModal() {
      carrotquestHelper.track('Показан попап - "Отправка писем была приостановлена"');

      var emailsBlockedModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.sendManualMessage.emailsBlockedModal.heading'),
              body: $translate.instant('modals.sendManualMessage.emailsBlockedModal.body'),
              imgUrl: 'assets/img/default/users/image-barier.png',
              confirmButtonText: $translate.instant('modals.sendManualMessage.emailsBlockedModal.confirmButtonText'),
              cancelButtonClass: 'hidden',
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      emailsBlockedModal.result.then(
        angular.bind(
          null,
          carrotquestHelper.sendChatMessage,
          $translate.instant('modals.sendManualMessage.emailsBlockedModal.chatMessage'),
        ),
      );
    }

    /**
     * Открыть поп-ап о блокиоровке емейл-рассылок из-за высокого bounce rate
     */
    function openEmailsBlockedBecauseOfBounceRateModal() {
      carrotquestHelper.track('Показан попап - "Емейл-рассылки заблокированы (bounce)"');

      var emailsBlockedBecauseOfBounceRateModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfBounceRateModal.heading'),
              body:
                '\
                <div class="margin-bottom-20">\
                  ' +
                $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfBounceRateModal.body') +
                '\
                </div>\
                <div>\
                  ' +
                $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfBounceRateModal.importantNote') +
                '\
                </div>',
              imgUrl: 'assets/img/default/users/image-barier.png',
              confirmButtonText: $translate.instant(
                'modals.sendManualMessage.emailsBlockedBecauseOfBounceRateModal.confirmButtonText',
              ),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      emailsBlockedBecauseOfBounceRateModal.result.then(hasHighBounceRateModalSuccess);

      function hasHighBounceRateModalSuccess() {
        carrotquestHelper.track('Попап "Емейл-рассылки заблокированы (bounce)" - клик "Открыть чат"');
        carrotquestHelper.open();
      }
    }

    /**
     * Открыть поп-ап о блокиоровкe емейл-рассылок из-за высокого complaint rate
     */
    function openEmailsBlockedBecauseOfComplaintRateModal() {
      carrotquestHelper.track('Показан попап - "Емейл-рассылки заблокированы"');

      var emailsBlockedBecauseOfComplaintRateModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfComplaintRateModal.heading'),
              body:
                '\
                <div class="margin-bottom-20">\
                  ' +
                $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfComplaintRateModal.body') +
                '\
                </div>\
                <div>\
                  ' +
                $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfComplaintRateModal.importantNote') +
                '\
                </div>',
              imgUrl: 'assets/img/default/users/image-barier.png',
              confirmButtonText: $translate.instant(
                'modals.sendManualMessage.emailsBlockedBecauseOfComplaintRateModal.confirmButtonText',
              ),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      emailsBlockedBecauseOfComplaintRateModal.result.then(emailsBlockedBecauseOfComplaintRateModalSuccess);

      function emailsBlockedBecauseOfComplaintRateModalSuccess() {
        carrotquestHelper.track('Попап "Емейл-рассылки заблокированы" - клик "Открыть чат"');
        carrotquestHelper.open();
      }
    }

    /**
     * Открыть поп-ап о том, что превышена хард квота
     */
    function openEmailsBlockedBecauseOfHardQuotaModal() {
      carrotquestHelper.track('Показан попап - "Превышение расширенной квоты"');

      var quotaUsers = vm.appBlocks.emailHardLimit - vm.appBlocks.emailUsed;
      quotaUsers = quotaUsers < 0 ? 0 : quotaUsers;

      var emailsBlockedBecauseOfHardQuotaModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfHardQuotaModal.heading'),
              body:
                '\
                <div class="margin-bottom-10">\
                  ' +
                $translate.instant(
                  'modals.sendManualMessage.emailsBlockedBecauseOfHardQuotaModal.body.hardQuota',
                  {
                    emailHardLimit: vm.appBlocks.emailHardLimit,
                    quotaUsers: quotaUsers,
                  },
                  'messageformat',
                ) +
                '\
                </div>\
                <div class="margin-bottom-10">\
                  ' +
                $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfHardQuotaModal.body.writeUs') +
                '\
                </div>\
                <div>\
                  ' +
                $translate.instant(
                  'modals.sendManualMessage.emailsBlockedBecauseOfHardQuotaModal.body.yourReputation',
                ) +
                '\
                </div>\
              ',
              imgUrl: 'assets/img/default/users/image-barier.png',
              confirmButtonText: $translate.instant(
                'modals.sendManualMessage.emailsBlockedBecauseOfHardQuotaModal.confirmButtonText',
              ),
              cancelButtonClass: 'hidden',
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      emailsBlockedBecauseOfHardQuotaModal.result.then(
        angular.bind(
          null,
          carrotquestHelper.sendChatMessage,
          $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfHardQuotaModal.chatMessage'),
        ),
      );
    }

    /**
     * Открыть поп-ап о блокиоровке рассылок сверх квоты
     */
    function openEmailsBlockedBecauseOfOverQuotaModal() {
      carrotquestHelper.track('Показан попап - "Ограничение отправки писем сверх квоты"');

      var quotaUsers = vm.appBlocks.emailPlanLimit - vm.appBlocks.emailUsed;
      quotaUsers = quotaUsers < 0 ? 0 : quotaUsers;

      var emailsBlockedBecauseOfOverQuotaModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfOverQuotaModal.heading'),
              body:
                '\
                <div class="margin-bottom-10">\
                  ' +
                $translate.instant(
                  'modals.sendManualMessage.emailsBlockedBecauseOfOverQuotaModal.body.overQuota',
                  {
                    emailPlanLimit: vm.appBlocks.emailPlanLimit,
                    quotaUsers: quotaUsers,
                  },
                  'messageformat',
                ) +
                '\
                </div>\
                <div class="margin-bottom-10">\
                  ' +
                $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfOverQuotaModal.body.extraPay') +
                '\
                </div>\
                <div>\
                  ' +
                $translate.instant(
                  'modals.sendManualMessage.emailsBlockedBecauseOfOverQuotaModal.body.yourReputation',
                ) +
                '\
                </div>\
              ',
              imgUrl: 'assets/img/default/users/image-barier.png',
              confirmButtonText: $translate.instant(
                'modals.sendManualMessage.emailsBlockedBecauseOfOverQuotaModal.confirmButtonText',
              ),
              cancelButtonClass: 'hidden',
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      emailsBlockedBecauseOfOverQuotaModal.result.then(
        angular.bind(
          null,
          carrotquestHelper.sendChatMessage,
          $translate.instant('modals.sendManualMessage.emailsBlockedBecauseOfOverQuotaModal.chatMessage'),
        ),
      );
    }

    /**
     * Открыть модалку с предупреждением отправки сообщения более usersLimit пользователям
     *
     * @param {'telegram'|'email'} type
     * @param {number=LIMIT_USERS_COUNT} usersLimit
     * @returns {Promise}
     */
    function openLimitUsersCountModal(type = 'email', usersLimit = LIMIT_USERS_COUNT) {
      var bodyText =
        '<div class="margin-bottom-10 margin-top-10">' +
        $translate.instant(
          `modals.sendManualMessage.limitUsersCountModal.body.maxCount.${type}`,
          { limitUsersCount: usersLimit },
          'messageformat',
        ) +
        '</div>';
      var cancelButtonText = '';
      var confirmButtonText = '';

      if (!checkSendEmailsCapability('blockEmailsOverQuota')) {
        var quotaUsers = vm.appBlocks.emailPlanLimit - vm.appBlocks.emailUsed;
        quotaUsers = quotaUsers < 0 ? 0 : quotaUsers;
        bodyText +=
          '<div>' +
          $translate.instant('modals.sendManualMessage.limitUsersCountModal.body.extraPay', {
            usersCount: vm.usersCount,
            quotaUsers: quotaUsers,
          }) +
          '</div>';
      }

      cancelButtonText = $translate.instant('modals.sendManualMessage.limitUsersCountModal.cancelButtonText');
      confirmButtonText = $translate.instant('modals.sendManualMessage.limitUsersCountModal.confirmButtonText');

      var limitUsersCountModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant(
                'modals.sendManualMessage.limitUsersCountModal.heading',
                {
                  limitUsersCount: usersLimit,
                },
                'messageformat',
              ),
              imgUrl: 'assets/img/default/users/max-emails.gif',
              body: bodyText,
              cancelButtonClass: 'btn-primary order-1',
              cancelButtonText: cancelButtonText,
              confirmButtonClass: 'btn-outline-primary',
              confirmButtonText: confirmButtonText,
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      return limitUsersCountModal.result;
    }

    /**
     * Показать поп-ап с отправкой сообещния в заданное время
     */
    function openMessagePlanningModal() {
      var limitUsersCountModal = $uibModal.open({
        component: 'cqMessagePlanning',
        resolve: {
          appID: angular.bind(null, angular.identity, currentApp.id),
          appName: angular.bind(null, angular.identity, currentApp.name),
          appTimezone: angular.bind(null, angular.identity, currentApp.settings.timezone),
          appTimezoneOffset: angular.bind(null, angular.identity, currentApp.settings.timezone_offset),
          currentMessage: angular.bind(null, angular.identity, vm.message),
          currentTimestamp: () => firstValueFrom(utilsModel.getTimestamp()),
          messageParams: angular.bind(null, angular.identity, manualMessageParams),
          pushSettings: angular.bind(null, angular.identity, vm.pushSettings),
        },
      });

      limitUsersCountModal.result.then(messagePlanSuccess);

      function messagePlanSuccess() {
        vm.message = messagePartModel.getDefault();
        $uibModalInstance.dismiss();
      }
    }

    /**
     * Запланировать отпарвку ручного сообщения
     *
     * @param {Object} form - Форма
     */
    function scheduleMessage(form) {
      form.$setSubmitted();
      if (form.$valid) {
        if (!checkSendEmailsCapability('limitUsersCount')) {
          trackOpenLimitUsersCountModal();
          openLimitUsersCountModal().then(confirmedScheduleMessage);
        } else if (!checkSendTelegramCapability('limitUsersCount')) {
          openLimitUsersCountModal('telegram', LIMIT_USERS_COUNT_FOR_TELEGRAM).then(openMessagePlanningModal);
        } else {
          openMessagePlanningModal();
        }
      }

      function confirmedScheduleMessage() {
        trackMessageSentWithLimitUsersCount();
        openMessagePlanningModal();
      }
    }

    /**
     * Сообщение о количестве реально отправляемых сообщений
     */
    function popoverMessageReceiversMessage(messageType) {
      switch (messageType) {
        case MESSAGE_PART_TYPES.PUSH:
          return $translate.instant('modals.sendManualMessage.popoverMessageReceiversMessage.push', {
            messageReceiversCount: vm.messageReceiversCount,
          });
        case MESSAGE_PART_TYPES.SDK_PUSH:
          return $translate.instant('modals.sendManualMessage.popoverMessageReceiversMessage.sdk_push', {
            messageReceiversCount: vm.messageReceiversCount,
          });
        case MESSAGE_PART_TYPES.TELEGRAM:
          return $translate.instant('modals.sendManualMessage.popoverMessageReceiversMessage.telegram', {
            messageReceiversCount: vm.messageReceiversCount,
          });
        default:
          return $translate.instant('modals.sendManualMessage.popoverMessageReceiversMessage.email', {
            messageReceiversCount: vm.messageReceiversCount,
          });
      }
    }

    /**
     * Отправка ручного сообщения
     */
    function sendMessage() {
      if (!vm.isSendMessagePerformed) {
        vm.isSendMessagePerformed = true;

        uploadPushIcon().then(saveAdditionalData).then(sendManualMessage).finally(sendManualMessageFinally);
      }

      function sendManualMessage() {
        return firstValueFrom(messageModel.sendManualMessage(currentApp.id, manualMessageParams, vm.message)).then(
          sendManualMessageSuccess,
        );

        function sendManualMessageSuccess() {
          trackMessageSent();

          vm.message = messagePartModel.getDefault();
          $uibModalInstance.close();
        }
      }

      function sendManualMessageFinally() {
        vm.isSendMessagePerformed = false;
      }

      function saveAdditionalData() {
        // HACK FIXME: дальше идёт говнокод, при помощи которого грузятся картинки и создаются события в блочных поп-апах
        var preprocessingTasks = [];

        if (
          !!~[
            MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
            MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
            MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
          ].indexOf(vm.message.type)
        ) {
          var blockPopup = vm.message[vm.message.type];
          var popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

          blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

          for (var j = 0; j < popupBlocks.length; j++) {
            var popupBlock = popupBlocks[j];

            preprocessingTasks.push(popupBlockModel.saveAdditionalData(vm.currentApp.id, popupBlock));
          }
        } else if (vm.message.type === MESSAGE_PART_TYPES.TELEGRAM) {
          for (let content of vm.message[vm.message.type].bodyJson.contents) {
            preprocessingTasks.push(messagePartModel.saveAdditionalDataForTelegramMessage(content));
          }
        }

        preprocessingTasks.push(messagePartModel.saveAdditionalData(vm.message));

        return $q.all(preprocessingTasks);
      }

      function uploadPushIcon() {
        if (vm.message.type == MESSAGE_PART_TYPES.PUSH && vm.message[MESSAGE_PART_TYPES.PUSH].newIcon) {
          return firstValueFrom(
            messageModel.uploadPushIcon(currentApp.id, vm.message[MESSAGE_PART_TYPES.PUSH].newIcon),
          ).then(setIcon);
        } else {
          vm.message.icon = vm.pushSettings.icon;
          return $q.resolve();
        }

        function setIcon(iconUrls) {
          vm.message[MESSAGE_PART_TYPES.PUSH].newIconUrl = USER_FILES_URL + '/push-icons/' + iconUrls.filename;
        }
      }
    }

    /**
     * Трек отправки ручного сообщения
     */
    function trackMessageSent() {
      carrotquestHelper.track('Отправка сообщения - отправил сообщение', {
        App: currentApp.name,
        Тип: vm.message.type,
      });

      /* HACK: У Яглы постоянно падают рассылки. Это событие для генерации сообщения в Slack */
      if (currentApp.id == 2805 && vm.message.type == MESSAGE_PART_TYPES.EMAIL) {
        carrotquestHelper.track('Yagla начала рассылку');
      }
    }

    /**
     * Трек открытия модалки с предупреждением об отправке сообщения 100 000 пользователям
     */
    function trackOpenLimitUsersCountModal() {
      if (featureModel.hasAccess(FEATURES.MANUAL_MESSAGE_EXTENDED_SIZE)) {
        carrotquestHelper.track('Пользователи - показан поп-ап "Ограничение отправки в 800 000 писем"', {
          App: currentApp.name,
        });
      } else {
        carrotquestHelper.track('Пользователи - показан поп-ап "Ограничение отправки в 100 000 писем"', {
          App: currentApp.name,
        });
      }
    }

    /**
     * Трек отправки сообщения после модалки с предупреждением
     */
    function trackMessageSentWithLimitUsersCount() {
      carrotquestHelper.track(
        'Пользователи - кликнул "все равно отправить" в поп-апе "Ограничение отправки в 100 000 писем"',
        { App: currentApp.name },
      );
    }
  }
})();

import { Pipe, PipeTransform } from '@angular/core';
import sortBy from 'lodash-es/sortBy';

import { EventType } from '@http/property/property.model';

@Pipe({
  name: 'filterAndSortEventTypes',
})
export class FilterAndSortEventTypesPipe implements PipeTransform {
  transform(eventTypes: EventType[], hasAccessToEventsEventTypesCustom: boolean): EventType[] {
    const filteredEvents = eventTypes.filter((event) => {
      return event.visible && (event.name.includes('$') || hasAccessToEventsEventTypesCustom);
    });
    return sortBy(filteredEvents, ['groupOrder', 'prettyName']);
  }
}

<ng-container *transloco="let t; read: 'messageEditorOpenedSdkPageTriggerTypeComponent'">
  <div class="d-flex">
    <ng-select
      [formControl]="comparison"
      [items]="COMPARISON_OPTIONS"
      [clearable]="false"
      [searchable]="false"
      class="mr-10"
    >
      <ng-template
        let-option="item"
        ng-option-tmp
        ng-label-tmp
      >
        {{ t('comparisonOptions.' + option, { isOnlyEvent }) }}
      </ng-template>
    </ng-select>
    <div
      class="form-group has-feedback flex-grow-1 mb-0"
      [cqShowError]="sdkPage"
    >
      <input
        class="form-control"
        [formControl]="sdkPage"
        placeholder="{{ t('input.' + comparison.value + '.placeholder') }}"
      />
      <span class="form-control-feedback">
        <i
          class="cqi-sm cqi-question-circle"
          ngbTooltip="{{ t('input.' + comparison.value + '.tooltip') }}"
          container="body"
          placement="top"
          tooltipClass="url-filter-tooltip"
        ></i>
      </span>
      <cq-validation-messages [control]="sdkPage">
        <cq-validation-message errorName="required">{{ t('input.errors.required') }}</cq-validation-message>
        <cq-validation-message errorName="minlength">
          {{ t('input.errors.minlength', { minLength: URL_MIN_LENGTH }) }}
        </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('input.errors.maxlength', { maxLength: URL_MAX_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <button
      *ngIf="!isOnlyEvent"
      class="btn btn-outline-primary margin-left-10"
      type="button"
      (click)="deleteTriggerType.next()"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
</ng-container>

import moment from 'moment';

import { Message } from '@http/message/message.types';
import { TgTriggerTypeExternal } from '@panel/app/partials/chat-bot/telegram-bot/trigger-types/tg-trigger.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';

import { ApiChatBotBranchRequest, ApiChatBotBranchResponse } from 'app/http/chat-bot/types/branch-external.types';

export enum CHAT_BOT_TYPE {
  LEAD = 'lead',
  ROUTING = 'routing',
  FACEBOOK = 'facebook',
  TELEGRAM = 'telegram',
  WIDGET = 'widget',
}

export type ApiChatBotResponse<T extends CHAT_BOT_TYPE> = {
  id: string;
  name: string;
  type: T;
  created?: moment.Moment;
  interruptBranchId: string;
  startBranchId: string;
  allowUserReplies: boolean;
  stats?: ApiRoutingBotStatsResponse;
  branches: ApiChatBotBranchResponse[];
  triggerTypes: TgTriggerTypeExternal[];
  integration?: string | null;
  chatWidget?: ChatWidgetResponse;
};

export type ApiLeadBotResponse = ApiChatBotResponse<CHAT_BOT_TYPE.LEAD>;

export type ApiBotWithActiveStatusResponse<T extends Exclude<CHAT_BOT_TYPE, 'lead'>> = ApiChatBotResponse<T> & {
  active: boolean;
};

export type ApiFacebookBotResponse = ApiBotWithActiveStatusResponse<CHAT_BOT_TYPE.FACEBOOK>;

export type ApiRoutingBotResponse = ApiBotWithActiveStatusResponse<CHAT_BOT_TYPE.ROUTING>;

export type ApiWidgetBotResponse = ApiBotWithActiveStatusResponse<CHAT_BOT_TYPE.WIDGET>;

// REQUEST типы бота
export type ApiChatBotRequest<T extends CHAT_BOT_TYPE> = {
  id?: string;
  name: string;
  type: T;
  interrupt_branch_id: string | null;
  interrupt_branch_link_id: string | null;
  start_branch_id: string | null;
  start_branch_link_id: string | null;
  allow_user_replies: boolean;
  branches?: ApiChatBotBranchRequest[];
  deleted_branches: string[];
  check_is_valid: boolean;
  include_branches__actions__attachments: boolean;
  ignoreErrors?: boolean;
  trigger_types: TgTriggerTypeExternal[];
  integration: string | null;
  chat_widget?: ChatWidgetRequest;
};

export type ApiLeadBotRequest = ApiChatBotRequest<CHAT_BOT_TYPE.LEAD>;

export type ApiBotWithActiveStatusRequest<T extends Exclude<CHAT_BOT_TYPE, 'lead'>> = ApiChatBotRequest<T> & {
  active: boolean;
};

export type ApiFacebookBotRequest = ApiBotWithActiveStatusRequest<CHAT_BOT_TYPE.FACEBOOK>;

export type ApiRoutingBotRequest = ApiBotWithActiveStatusRequest<CHAT_BOT_TYPE.ROUTING>;

export type ApiTelegramBotRequest = ApiBotWithActiveStatusRequest<CHAT_BOT_TYPE.TELEGRAM>;

export type ApiWidgetBotRequest = ApiBotWithActiveStatusRequest<CHAT_BOT_TYPE.WIDGET>;

export type ApiCreateResponse<T> = {
  messageId: string;
  chatBot: T;
};

export type ApiGetListResponse = {
  chatBots: Message[];
  paginatorParams: any;
};

export type ApiSaveResponse<T extends CHAT_BOT_TYPE> = {
  chatBot: ChatBot<T>;
  mapIds: object;
};

export type ApiRoutingBotStatsResponse = {
  sent: number;
  startedConversation: number;
  finished: number;
  interrupted: number;
  leftContacts: number;
  lastSent: string | null;
};

/** Чат-виджет, который является частью embedded-бота */
export type ChatWidgetResponse = {
  buttonText: string;
  header: string;
  id: string;
  order: number;
  subHeader: string;
  visible: boolean;
};

/** Чат-виджет, который является частью embedded-бота */
export type ChatWidgetRequest = {
  button_text?: string;
  header?: string;
  id?: string;
  order?: number;
  sub_header?: string;
  visible: boolean;
};

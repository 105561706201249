<ng-container *transloco="let t; read: 'duplicatesErrorsComponent'">
  <div class="card border-danger">
    <div class="card-header flex flex-row pl-10 pr-10">
      <i class="cqi-sm cqi-triangle-exclamation text-danger mr-10"></i>
      <div class="flex flex-column">
        <div class="font-weight-bold">
          {{ t('foundDuplicatesHeading', { duplicatesCount: eventDuplicates.length }) }}
        </div>
        <small class="text-muted">{{ t('foundDuplicatesDescription') }}</small>
      </div>
    </div>

    <div
      *ngFor="let duplicateInfo of eventDuplicates"
      class="pt-15 pb-15 pl-10 pr-30 border-bottom flex flex-row"
      data-test="auto-event-duplicate"
    >
      <div class="col-4 p-0">
        <span
          [ngClass]="{ 'bg-light-danger': duplicateInfo.duplicateBy.includes('name') }"
          class="pl-5 pr-5"
          data-test="duplicate-name"
        >
          {{ duplicateInfo.event.name }}
        </span>
      </div>
      <div class="col-5 p-0">
        <span
          *ngIf="isInputOrClick(duplicateInfo.event); else autoEventWithUrl"
          [ngClass]="{ 'bg-light-danger': duplicateInfo.duplicateBy.includes('selector') }"
          class="pl-5 pr-5"
          data-test="duplicate-selector"
        >
          {{ duplicateInfo.event.selector }}
        </span>
        <ng-template #autoEventWithUrl>
          <div
            [ngClass]="{ 'bg-light-danger': duplicateInfo.duplicateBy.includes('url') }"
            class="inline-block pl-5 pr-5 full-max-width"
            data-test="duplicate-url"
          >
            <div class="text-truncate">{{ duplicateInfo.event.url }}</div>
            <small class="text-muted">{{ t('subpages.' + duplicateInfo.event.urlContains) }}</small>
          </div>
        </ng-template>
      </div>
      <div class="col-3 p-0 flex justify-end">
        <button
          (click)="selectEvent.next(duplicateInfo.event)"
          class="btn btn-secondary"
          type="button"
          data-test="duplicate-select-auto-event"
        >
          {{ t('selectButton') }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

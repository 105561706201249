<ng-container *transloco="let t; read: 'textPropertiesComponent'">
  <div class="margin-between-rows-10">
    <div class="d-flex align-items-center">
      <strong>{{ t('heading') }}</strong>
    </div>
    <div
      *ngFor="let item of control.controls; let i = index"
      class="flex"
    >
      <cq-write-property-value
        class="flex-grow-1"
        [currentApp]="currentApp"
        [formControl]="item"
        [(properties)]="properties"
        [inputPlaceholder]="t('selectorValueInput.placeholder')"
        [inputError]="t('selectorValueInput.errors.required')"
      ></cq-write-property-value>
      <button
        (click)="control.removeAt(i)"
        type="button"
        class="btn btn-outline-primary ml-10"
      >
        <i class="btn-icon cqi-sm cqi-times"></i>
      </button>
    </div>
    <button
      (click)="addControl()"
      type="button"
      class="btn btn-borderless-primary"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ t('addPropertyButton') }}</span>
    </button>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';

import { BranchActionButtonsPropertyComponent } from './buttons-property.component';

@NgModule({
  declarations: [BranchActionButtonsPropertyComponent],
  imports: [CommonModule, PropertySelectorModule, ReactiveFormsModule],
  exports: [BranchActionButtonsPropertyComponent],
})
export class BranchActionButtonsPropertyModule {}

<ng-container *transloco="let t; read: 'socialNetworkComponent'">
  <div>
    <div class="d-flex margin-bottom-10">
      <div>
        <i class="cqi-sm cqi-grip-vertical handle-icon"></i>
      </div>
      <div class="custom-control custom-checkbox margin-left-10">
        <input
          class="custom-control-input"
          [id]="socialName"
          type="checkbox"
          [formControl]="control.controls.show"
        />
        <label
          class="custom-control-label"
          [for]="socialName"
          >{{ t(socialName + '.checkbox.label') }}</label
        >
      </div>
    </div>
    <div
      [ngbCollapse]="!control.controls.show.value"
      class="margin-left-25"
    >
      <div class="margin-bottom-10 text-secondary">{{ t(socialName + '.description') }}</div>
      <div
        class="form-control-container inline-block padding-bottom-10"
        [cqShowError]="control.controls.text"
      >
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text text-secondary">{{ t(socialName + '.link') }}</div>
          </div>
          <input
            class="form-control"
            [formControl]="control.controls.text"
          />
        </div>
        <cq-validation-messages [control]="control.controls.text">
          <cq-validation-message errorName="required">
            <div>{{ t('errors.required') }}</div>
          </cq-validation-message>
          <cq-validation-message errorName="maxlength">
            <div>{{ t('errors.maxlength', { maxLength: SOCIAL_SETTING_TEXT_MAX_LENGTH }) }}</div>
          </cq-validation-message>
        </cq-validation-messages>
      </div>
    </div>
  </div>
</ng-container>

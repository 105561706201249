import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MessageWebhookParam } from '@http/message-part/message-part.types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

@Component({
  selector: 'cq-event-prop-editor',
  templateUrl: './event-prop-editor.component.html',
  styleUrls: ['./event-prop-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPropEditorComponent extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<MessageWebhookParam>> {
  readonly PARAM_MAX_LENGTH = 100;

  readonly control: FormGroup<ToFormGroupControls<MessageWebhookParam>> = this.fb.group<
    ToFormGroupControls<MessageWebhookParam>
  >({
    key: this.fb.control<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(this.PARAM_MAX_LENGTH)],
    }),
    value: this.fb.control<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(this.PARAM_MAX_LENGTH)],
    }),
  });

  @Output()
  readonly delete = new EventEmitter<void>();

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  get keyForm() {
    return this.control.controls.key;
  }

  get valueForm() {
    return this.control.controls.value;
  }
}

import { Renderer, RenderTexture } from '@pixi/core';
import { Container, Sprite } from 'pixi.js';

import { PixiGeometryService } from '@panel/app/shared/services/pixi/geometry/pixi-geometry.service';
import { BOT_DEFAULT_ICON_BG_RADIUS } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.constants';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';

let headingIcons: Record<string, RenderTexture> = {};
let lastRendererContextUID: number | null = null;

function getIconRenderTexture(container: Container) {
  return RenderTexture.create({
    width: container.width,
    height: container.height,
    resolution: 2,
  });
}

export function getIconHeading(renderer: Renderer, iconCode: string, color: number, bgColor: number): Container {
  const index = `${iconCode}:${color}:${bgColor}`;

  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    clearCache();
  }

  lastRendererContextUID = renderer.CONTEXT_UID;

  if (headingIcons[index]) {
    return new Sprite(headingIcons[index]);
  }

  const circle = PixiGeometryService.renderCircle(BOT_DEFAULT_ICON_BG_RADIUS, { backgroundColor: bgColor });
  const iconText = PixiIconRenderService.renderIcon(iconCode, { fill: color });

  const icon = new Container();

  iconText.position.set((circle.width - iconText.width) / 2, (circle.height - iconText.height) / 2);

  icon.addChild(circle, iconText);

  const renderTexture = getIconRenderTexture(icon);

  renderer.render(icon, { renderTexture });

  headingIcons[index] = renderTexture;

  icon.name = 'icon-heading';

  return new Sprite(headingIcons[index]);
}

function clearCache() {
  headingIcons = {};
}

(function () {
  'use strict';

  require('./manual-message-statistics.controller');
  require('./tabs/answers.html');
  require('./tabs/general.html');
  require('./tabs/sendings.html');

  angular.module('myApp.manualMessages').component('cqManualMessageStatistics', cqManualMessageStatistics());

  function cqManualMessageStatistics() {
    return {
      bindings: {
        channels: '=', // Список каналов
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // текущее приложение
        currentMessage: '=', // текущее сообщение
        djangoUser: '=', // текущий пользователь
        pushSettings: '=', // настройки пушей
        teamMembers: '=', // Список членов команды
        telegramIntegrations: '=',
      },
      controller: 'CqManualMessageStatisticsController',
      controllerAs: 'vm',
      template: require('./manual-message-statistics.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'notificationSettingsGeneralComponent'">
  <div class="custom-control custom-checkbox margin-bottom-10">
    <input
      class="custom-control-input"
      id="desktop-sound"
      type="checkbox"
      data-test="enable-notification-sound-checkbox"
      [(ngModel)]="notificationSettingsGeneral.enableNotificationSound"
      (ngModelChange)="notificationSettingsGeneralChange.next(notificationSettingsGeneral)"
    />
    <label
      class="custom-control-label"
      for="desktop-sound"
      >{{ t('enableNotificationSound.label') }}</label
    >
  </div>
  <div
    *ngIf="isWebApp"
    class="custom-control custom-checkbox margin-bottom-10"
  >
    <input
      class="custom-control-input"
      id="desktop-infinite-sound"
      type="checkbox"
      data-test="repeat-notification-sound-checkbox"
      [disabled]="!notificationSettingsGeneral.enableNotificationSound"
      [(ngModel)]="notificationSettingsGeneral.repeatSoundNotification"
      (ngModelChange)="notificationSettingsGeneralChange.next(notificationSettingsGeneral)"
    />
    <label
      class="custom-control-label"
      for="desktop-infinite-sound"
      >{{ t('repeatSoundNotification.label') }}</label
    >
  </div>

  <div
    *ngIf="isWebApp"
    class="custom-control custom-checkbox margin-bottom-30"
  >
    <input
      class="custom-control-input"
      id="desktop-notification-opened"
      type="checkbox"
      data-test="keep-desktop-notification-opened"
      [(ngModel)]="notificationSettingsGeneral.keepDesktopNotificationOpened"
      (ngModelChange)="notificationSettingsGeneralChange.next(notificationSettingsGeneral)"
    />
    <label
      class="custom-control-label"
      for="desktop-notification-opened"
      >{{ t('keepDesktopNotificationOpened.label') }}</label
    >
  </div>
</ng-container>

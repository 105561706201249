import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Customer } from '@http/customer/customer';

/** Компонент для работы с биллинговым емейлом */
@Component({
  selector: 'cq-billing-email[customer]',
  templateUrl: './billing-email.component.html',
  styleUrls: ['./billing-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingEmailComponent {
  @Input()
  customer!: Customer;

  /** Добавление емейла */
  @Output()
  addEmail: EventEmitter<void> = new EventEmitter<void>();

  /** Редактирование емейла */
  @Output()
  editEmail: EventEmitter<void> = new EventEmitter<void>();

  /** Обработчик добавления емейла */
  onAddEmail(): void {
    this.addEmail.emit();
  }

  /** Обработчик редактирования емейла */
  onEditEmail(): void {
    this.editEmail.emit();
  }
}

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqChatOperatorsStatusController', CqChatOperatorsStatusController);

  function CqChatOperatorsStatusController(carrotquestHelper, validationHelper) {
    let vm = this;

    /**
     * Максимальная длина текста статуса в шапке чата
     *
     * @type {number}
     */
    const MAX_STATUS_TEXT_LENGTH = 100;

    vm.$onInit = init;

    function init() {
      vm.MAX_STATUS_TEXT_LENGTH = MAX_STATUS_TEXT_LENGTH;
      vm.validationHelper = validationHelper;
    }
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDatepickerConfig, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { tuiPure } from '@taiga-ui/cdk';
import Moment from 'moment';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';
import { Daterange } from '@panel/app/shared/components/datetime-picker/types/daterange.type';
import { DaterangepickerOptions } from '@panel/app/shared/components/datetime-picker/types/daterangepicker-options.type';

@Component({
  selector: 'cq-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class SearchFilterComponent {
  readonly dateRangeControl: FormControl<Daterange> = new FormControl<Daterange>(
    {
      from: moment().subtract(90, 'days'),
      to: moment(),
    },
    { nonNullable: true },
  );
  readonly searchPhraseControl: FormControl<string> = new FormControl('', { nonNullable: true });

  _dateRange!: Daterange;

  @Input()
  set dateRange(value: { startDate: Moment.Moment; endDate: Moment.Moment }) {
    this._dateRange = {
      from: value.startDate,
      to: value.endDate,
    };
  }

  @Output()
  dateRangeChange: EventEmitter<Daterange> = new EventEmitter();

  @Output()
  searchPhraseChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(NgbPopover)
  ngbPopover: NgbPopover | null = null;

  dateRangePickerOptions: DaterangepickerOptions = {
    showRangeNavigation: true,
    dateLimit: {
      month: 3,
    },
  };

  private dateRangeChangeSubject: Subject<Daterange> = new Subject<Daterange>();
  private searchPhraseChangeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(ngbDatepickerConfig: NgbDatepickerConfig, private readonly destroy$: DestroyService) {
    const now = moment().endOf('day');
    ngbDatepickerConfig.maxDate = {
      year: now.year(),
      month: now.month() + 1,
      day: now.date(),
    };

    this.dateRangeChangeSubject
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.dateRangeChange.emit(value);
      });

    this.searchPhraseChangeSubject
      .pipe(
        distinctUntilChanged(),
        filter((value) => Boolean(value.trim())),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.searchPhraseChange.emit(value);
      });
  }

  get popover(): NgbPopover {
    if (this.ngbPopover === null) {
      throw new Error('Popover is null');
    }

    return this.ngbPopover;
  }

  get tooltipText(): string {
    const dateRangeControlValue = this.dateRangeControl.value;
    return this.getFormattedDateRangeValue(dateRangeControlValue.from!, dateRangeControlValue.to!);
  }

  @tuiPure
  getFormattedDateRangeValue(from: Moment.Moment, to?: Moment.Moment) {
    return `${from.format('L')} - ${(to ?? from).format('L')}`;
  }

  onClickApplyFilter(): void {
    this.dateRangeChangeSubject.next(this.dateRangeControl.value);
    this.popover.close();
  }

  onClickCancelButton(): void {
    this.popover.close();
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.searchPhraseChangeSubject.next(this.searchPhraseControl.value);
    }
  }
}

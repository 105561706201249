<ng-container *transloco="let t; read: 'amocrmPipelineComponent'">
  <div class="font-weight-bold mb-10">
    <span>{{ t('description') }}</span>
    <i
      class="cqi-sm cqi-question-circle"
      [ngbTooltip]="t('tooltip', {integrationTypeName})"
    ></i>
  </div>

  <div class="w-100">
    <ng-select
      [classList]="['ng-touched']"
      [(ngModel)]="selectedPipeline"
      [clearable]="false"
      [items]="filteredPipelines"
      [placeholder]="t('select.placeholder')"
      [searchable]="true"
      [searchFn]="findPipeline.bind(this)"
      groupBy="name"
      (change)="onChangeSelect($event)"
    >
      <ng-template
        let-pipeline="item"
        ng-label-tmp
      >
        <span>{{ pipeline.name + ': ' + pipeline.statusName }}</span>
      </ng-template>

      <ng-template
        let-group="item"
        ng-optgroup-tmp
      >
        <span>{{ group.name }}</span>
      </ng-template>

      <ng-template
        let-pipeline="item"
        ng-option-tmp
      >
        <span>{{ pipeline.name + ': ' + pipeline.statusName }}</span>
      </ng-template>
    </ng-select>
    <cq-validation-messages [control]="controlLeadDefaultPipeline">
      <cq-validation-message errorName="required">
        {{ t('select.errors.required', {integrationTypeName}) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FEATURE_LABELS_ONLY } from '@http/feature-label/feature-label.constants';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-enabling-feature-control',
  templateUrl: './enabling-feature-control.component.html',
  styleUrls: ['./enabling-feature-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnablingFeatureControlComponent extends AbsCVAFormControlBasedComponent<boolean> {
  readonly control: FormControl<boolean> = new FormControl<boolean>(false, {
    nonNullable: true,
  });

  protected readonly FEATURE_LABELS_ONLY = FEATURE_LABELS_ONLY;

  constructor() {
    super();
  }
}

<ng-container *transloco="let t; read: 'pricingComponent'">
  <cq-pricing-plan-heading
    [(expectedVisitors)]="expectedVisitors"
    [(needsPersonalPlan)]="needsPersonalPlan"
    [addonsConfiguration]="addonsConfiguration"
    [currentApp]="currentApp"
    [djangoUser]="djangoUser"
  ></cq-pricing-plan-heading>
  <cq-pricing-plan-description
    class="mb-20"
    [(isShowFeaturesAvailabilityByRatePlans)]="isVisibleComparisonComponent"
    [currentApp]="currentApp"
  ></cq-pricing-plan-description>
  <cq-plans-comparison
    *ngIf="isVisibleComparisonComponent"
    [expectedVisitors]="expectedVisitors"
    [ratePlansConfig]="ratePlansConfig"
  ></cq-plans-comparison>
  <cq-pricing-addons
    class="mt-20"
    [(addonsConfigPerPlan)]="addonsConfiguration"
    [currentApp]="currentApp"
    [expectedVisitors]="expectedVisitors"
    [needsPersonalPlan]="needsPersonalPlan"
  ></cq-pricing-addons>
</ng-container>

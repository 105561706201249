import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { App } from '@http/app/app.model';

@Component({
  selector: 'cq-message-preview-popup-chat',
  templateUrl: './message-preview-popup-chat.component.html',
  styleUrls: ['./message-preview-popup-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagePreviewPopupChatComponent implements OnChanges, AfterViewInit {
  @Input({ required: true })
  app!: App;

  @Input({ required: true })
  content!: string;

  @Input({ required: true })
  language!: string;

  @Input({ required: true })
  sender!: object;

  @ViewChild('container', { static: false })
  container!: ElementRef<HTMLElement>;
  @ViewChild('carrotMessengerCollapsedFrameContainer', { static: true })
  carrotMessengerCollapsedFrameContainer!: ElementRef<HTMLElement>;
  @ViewChild('carrotNotificationFrameContainer', { static: true })
  carrotNotificationFrameContainer!: ElementRef<HTMLElement>;

  constructor() {}

  ngAfterViewInit() {
    this.initVisibleObserver();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['content'].isFirstChange()) {
      setTimeout(() => {
        this.updateCommonContainerHeight();
      }, 0);
    }
  }

  initVisibleObserver(): void {
    let visibleObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.updateCommonContainerHeight();
          }
        });
      },
      { threshold: [0] },
    );

    if (this.container) {
      visibleObserver.observe(this.container.nativeElement);
    }
  }

  updateCommonContainerHeight(): void {
    let carrotMessengerCollapsedFrameContainerHeight =
      this.carrotMessengerCollapsedFrameContainer.nativeElement.offsetHeight;
    let carrotNotificationFrameContainerHeight = this.carrotNotificationFrameContainer.nativeElement.offsetHeight;

    this.container.nativeElement.style.height = `${
      carrotMessengerCollapsedFrameContainerHeight + carrotNotificationFrameContainerHeight
    }px`;
  }
}

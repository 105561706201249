import { TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Container, Sprite, Texture } from 'pixi.js';

import { AbsBlockBaseView } from '@panel/app/services/canvas/tirgger-chain/blocks/block-base.view';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ExitBlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { AtlasBuilderService } from '@panel/app/services/canvas/tirgger-chain/builders/atlas-builder/atlas-builder.service';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';

export class ExitView extends AbsBlockBaseView<TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS | TRIGGER_CHAIN_BLOCK_TYPE.EXIT> {
  constructor(
    data: ExitBlockViewData,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    transloco: TranslocoService,
    renderer: Renderer,
  ) {
    super(data, interactiveBlockPartViewFactory, transloco, renderer);
  }

  render(): void {
    const content = this.renderView();

    this.graphicContainer.addChild(content);
    this.blockOverlayContainer = content;
    this.connectionPoint = content;
  }

  private renderView() {
    const container = new Container();

    const view = AtlasBuilderService.getTexture(
      this.type === TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS ? 'block-exit-success' : 'block-exit',
    );
    container.addChild(new Sprite(view));
    const border = this.getViewBorderTexture();

    if (border) {
      container.addChild(new Sprite(border));
    }

    return container;
  }

  private getViewBorderTexture(): Texture | undefined {
    switch (true) {
      case !this.data.incomingConnectionsValid:
        return AtlasBuilderService.getTexture(
          this.type === TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS
            ? 'border-exit-success-danger600'
            : 'border-exit-danger600',
        );
      default:
        return undefined;
    }
  }
}

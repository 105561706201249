import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { ModalHelperService } from '@panel/app/services';
import { PromptModalModule } from '@panel/app/shared/modals/prompt/prompt-modal.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchActionUserTagComponent } from './user-tag.component';

@NgModule({
  declarations: [BranchActionUserTagComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, NgSelectModule, ReactiveFormsModule, PromptModalModule],
  exports: [BranchActionUserTagComponent],
  providers: [ModalHelperService],
})
export class BranchActionUserTagModule {}

(function () {
  'use strict';

  require('./service-installation-for-developer.controller');

  angular
    .module('myApp.serviceInstallationForDeveloper')
    .component('cqServiceInstallationForDeveloper', cqServiceInstallationForDeveloper());

  function cqServiceInstallationForDeveloper() {
    return {
      controller: 'CqServiceInstallationForDeveloperController',
      controllerAs: 'vm',
      template: require('./service-installation-for-developer.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'messageInputComponent'">
  <quill-editor
    format="text"
    [formats]="[]"
    [maxLength]="maxLength"
    [modules]="{ toolbar: false }"
    [placeholder]="placeholder"
    [readOnly]="readOnly"
    [sanitize]="true"
    [scrollingContainer]="scrollingContainer"
    [(ngModel)]="quillValue"
  ></quill-editor>
</ng-container>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cq-radio-new',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent {
  @Input() name!: string;
  @Input() value!: any;
  @Input() model!: any;
  @Output() modelChange = new EventEmitter<any>();

  get modelValue() {
    return this.model;
  }

  set modelValue(value) {
    this.model = value;
    this.modelChange.emit(this.model);
  }

  onModelChange(event: any) {
    this.model = event;
  }
}

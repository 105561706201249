<ng-container *transloco="let t">
  <div
    class="form-group"
    [cqShowError]="actionForm.controls.body"
  >
    <label class="font-weight-normal small">{{ t('chatBot.botActionChannel.actionHeading') }}:</label>
    <ng-select
      [items]="(state.channels$ | async) ?? []"
      bindLabel="name"
      bindValue="id"
      [required]="true"
      [clearable]="false"
      placeholder="{{ t('chatBot.botActionChannel.channelSelect.placeholder') }}"
      [virtualScroll]="true"
      [formControl]="actionForm.controls.body"
      [trackByFn]="channelSelectTrackFn"
      [cqShowError]="actionForm.controls.body"
      [classList]="['ng-touched']"
    >
      <ng-template
        ng-label-tmp
        let-option="item"
      >
        <ng-container *ngIf="!actionForm.controls.body.errors?.channelRemoved; else removedItemError">
          <cq-channel-icon
            [channel]="option"
            xs
          ></cq-channel-icon>
          {{ option.name }}
        </ng-container>
        <ng-template #removedItemError>
          <span>
            <i class="margin-right-5 cqi-sm cqi-triangle-exclamation-o text-danger"></i>
            <span class="text-muted">{{ t('chatBot.botActionChannel.channelSelect.removedItem') }}</span>
          </span>
        </ng-template>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-option="item"
      >
        <span>
          <cq-channel-icon
            [channel]="option"
            xs
          ></cq-channel-icon>
          <span>{{ option.name }}</span>
        </span>
      </ng-template>
    </ng-select>
    <cq-validation-messages [control]="actionForm.controls.body">
      <cq-validation-message errorName="required">
        {{ t('chatBot.botActionChannel.channelSelect.errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="channelRemoved">
        {{ t('chatBot.botActionChannel.channelSelect.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

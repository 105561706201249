import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/angular';

import { MESSAGE_PAGE_TYPES } from '@http/message/message.constants';
import { MessageModel } from '@http/message/message.model';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { floatMax } from '@panel/app/pages/auto-messages/validators/float-max.validator';
import { floatMin } from '@panel/app/pages/auto-messages/validators/float-min.validator';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { SENDING_FILTERS_GROUP_TYPES } from '@panel/app/services/conditions-sending/conditions-sending.constants';
import { floatValidator } from '@panel/app/shared/validators/float/float-validator';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-auto-message-control-group',
  templateUrl: './auto-message-control-group.component.html',
  styleUrls: ['./auto-message-control-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageControlGroupComponent implements OnInit {
  /** Текущее приложение */
  @Input() currentApp!: any;
  /** Сообщение */
  @Input() currentMessage!: any;
  /** Тип страницы, на котором размещен компонент, необходимо для корректного трекинга событий и переводов */
  @Input() messagePageType?: any;

  protected accessToAutoMessagesControlGroup: any;
  protected MESSAGE_PAGE_TYPES: any = MESSAGE_PAGE_TYPES;
  protected messagePartTypeName: any;
  protected controlGroupSwitch: any;
  protected SENDING_FILTERS_GROUP_TYPES: any = SENDING_FILTERS_GROUP_TYPES;

  messageForm = this.fb.group({
    controlGroupProportion: this.fb.control<number | null>(null, [
      Validators.required,
      floatMin(0.0001),
      floatMax(100),
      floatValidator,
    ]),
  });

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly fb: FormBuilder,
    protected readonly messageModel: MessageModel,
    protected readonly messagePartModel: MessagePartModel,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly translocoService: TranslocoService,
    protected readonly uiRouter: UIRouter,
  ) {}

  ngOnInit(): void {
    this.accessToAutoMessagesControlGroup = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_CONTROL_GROUP,
      this.currentApp,
    );

    this.messagePageType = this.messagePageType || MESSAGE_PAGE_TYPES.AUTO_MESSAGES; // Тип старницы, на которой размещен компоненет, необходимо для корректного трекинга событий и переводов
    this.messagePartTypeName = this.messageModel.getMessagePartTypeName(this.currentMessage.parts);
    this.controlGroupSwitch = this.currentMessage.isControlGroupEnabled;

    this.messageForm.controls.controlGroupProportion.setValue(
      this.transformValueToInternal(this.currentMessage.controlGroup.proportion),
      { emitEvent: false },
    );

    this.messageForm.valueChanges.subscribe((value: any) => {
      if (value.controlGroupProportion === '0' || value.controlGroupProportion.toString().includes('.')) {
        this.currentMessage.controlGroup.proportion = null;
      } else {
        this.currentMessage.controlGroup.proportion = this.transformValueToExternal(value.controlGroupProportion);
      }
    });
  }

  /**
   * Изменения значения свича контрольной группы
   * @param value
   */
  changeControlGroupSwitch(value: any): void {
    this.currentMessage.isControlGroupEnabled = value;
  }

  transformValueToInternal(value: any): any {
    return (value * 100).toString().replace('.', ',');
  }

  transformValueToExternal(value: any): number {
    return parseFloat(value.toString().replace(',', '.')) * 0.01;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';

import { MessageFiltersModule } from '@panel/app/partials/message-editor/message-filters/message-filters.module';
import { UserTagModule } from '@panel/app/partials/user-tag/user-tag.module';
import { StaticProgressbarModule } from '@panel/app/shared/components/static-progressbar/static-progressbar.module';
import { PipesModule } from '@panel/app/shared/pipes/pipes.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AutoMessageTableComponent } from './auto-message-table.component';

@NgModule({
  declarations: [AutoMessageTableComponent],
  exports: [AutoMessageTableComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    UIRouterModule,
    MomentModule,
    NgbTooltipModule,
    NgbPopoverModule,
    StaticProgressbarModule,
    PipesModule,
    SharedModule,
    NgbDropdownModule,
    UserTagModule,
    MessageFiltersModule,
  ],
})
export class AutoMessageTableModule {}

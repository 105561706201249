import { TranslocoService } from '@jsverse/transloco';

import { TelegramIntegrationForm } from '@http/integration/integrations/telegram/forms/telegram-integration.form';

import { INTEGRATION_TYPES } from '../../constants/integration.constants';
import { SettingsIntegration } from '../../integration';
import {
  TelegramIntegrationExternal,
  TelegramIntegrationSettingsExternal,
} from './interfaces/telegram-integration.interfaces';

/** Класс для работы с интеграцией "Уведомление на почту" */
export class TelegramIntegration extends SettingsIntegration<TelegramIntegrationSettingsExternal> {
  /** Форма настройки интеграции */
  form: TelegramIntegrationForm;
  /** Тип интеграции */
  type = INTEGRATION_TYPES.TELEGRAM;
  /** Настройки интеграции */
  settings: TelegramIntegrationSettingsExternal = {
    botToken: '',
    botName: '',
    botId: '',
  };

  constructor(translocoService: TranslocoService, integrationExternal?: TelegramIntegrationExternal) {
    super(integrationExternal);

    // Если интеграция создается из внешнего формата, обновим settings, иначе задаем дефолтные значения
    if (integrationExternal) {
      this.updateSettings(integrationExternal.settings);
    } else {
      this.active = true;

      const integrationTypeName = translocoService.translate(`models.integration.types.${this.type}.name`);
      this.name = translocoService.translate('models.integration.defaultValues.name', { integrationTypeName });
    }

    // Данные о настроенной интеграции собираются с формы и отправляются на backend
    this.form = new TelegramIntegrationForm(this);
  }

  /** Получение внешнего формата интеграции */
  get externalFormat(): TelegramIntegrationExternal {
    return {
      ...this.baseExternalFormat,
      ...this.form.value,
      settings: {
        ...this.settings,
        ...this.form.value.settings,
      },
    };
  }

  get botLink(): string {
    // Удаляем символ @ в начале, если он есть
    const cleanBotName = this.settings.botName.replace(/^@/, '');
    return `https://t.me/${cleanBotName}`;
  }

  /**
   * Обновление settings
   *
   * @param settings - Настройки интеграции в внешнем формате
   */
  updateSettings(settings: any): void {
    this.settings = settings;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { UserAvatarModule } from '@panel/app/partials/user-card/user-avatar/user-avatar.module';

import { UserMainPropsComponent } from './user-main-props.component';

@NgModule({
  declarations: [UserMainPropsComponent],
  exports: [UserMainPropsComponent],
  imports: [CommonModule, TranslocoModule, UserAvatarModule],
})
export class UserMainPropsModule {}

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { ActiveChatWidget } from '@http/chat-bot/types/chat-bot-internal.types';

/**
 * Компонент для отображения активных чат-виджетов в превью чата
 */
@Component({
  selector: 'cq-chat-widget-list[accentColor][contrastColor][activeChatWidgetList]',
  templateUrl: './chat-widget-list.component.html',
  styleUrls: ['./chat-widget-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWidgetListComponent {
  @Input()
  accentColor!: string;

  @Input()
  contrastColor!: string;

  @Input()
  editableChatWidget?: ActiveChatWidget;

  @Input()
  activeChatWidgetList: ActiveChatWidget[] = [];

  /** Оверлэй над превью чата для выделения редактируемого чат-виджета */
  @HostBinding('class.show-overlay')
  isShowOverlay: boolean = this.uiRouter.stateService.includes('app.content.messagesAjs.widgetBot');

  constructor(private readonly uiRouter: UIRouter) {}

  isCurrent(activeChatWidget: ActiveChatWidget): boolean {
    return !!this.editableChatWidget && this.editableChatWidget.action.chatBotId === activeChatWidget.action.chatBotId;
  }
}

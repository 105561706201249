import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { InstallScriptModalComponent } from '../../../../app/shared/modals/install-script-modal/install-script-modal.component';

/**
 * Контроллер настройки JavaScript кода
 */
(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('JsCodeController', JsCodeController);

  function JsCodeController(
    $state,
    $scope,
    toastr,
    $translate,
    $uibModal,
    API_OBJECT_NAME,
    PROJECT_NAME,
    appModel,
    carrotquestHelper,
    planFeatureAccessService,
    modalHelperService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToTrackMasterJsCode = planFeatureAccessService.getAccess(
        PLAN_FEATURE.TRACK_MASTER_JS_CODE,
        vm.currentApp,
      );

      trackEnterInSetting();

      vm.aceEditorSetting = {
        //Настройки для редактора
        theme: 'monokai',
        mode: 'javascript',
        fontSize: '15px',
        highlightActiveLine: false,
        printMarginColumn: 100,
      };
      vm.API_OBJECT_NAME = API_OBJECT_NAME;
      vm.code = vm.currentApp.settings.js_code; // Код
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.requestIsExecuted = false; // Флаг выполнения запроса
      vm.save = save;
      vm.settingType = $state.params.autoEventGroup;
      vm.applyChanges = () => $scope.$applyAsync();
    }

    /**
     * Сохранение настройки js кода
     */
    function save() {
      if (vm.requestIsExecuted) {
        return;
      }
      vm.requestIsExecuted = true;

      var settings = {
        js_code: vm.code,
      };

      firstValueFrom(appModel.saveSettings(vm.currentApp.id, settings)).then(saveSuccess).finally(saveFinally);

      function saveSuccess() {
        trackJsCodeSaved();
        vm.currentApp.settings.js_code = vm.code;
        toastr.success($translate.instant('trackMaster.jsCode.toastr.saveSuccess'));
      }

      function saveFinally() {
        vm.requestIsExecuted = false;

        if (appModel.isAppBlocked(vm.currentApp)) {
          let modal = modalHelperService.open(InstallScriptModalComponent);

          modal.componentInstance.modalWindowParams = {
            body: $translate.instant('trackMaster.jsCode.installScriptModal.body'),
            currentApp: vm.currentApp,
            djangoUser: vm.djangoUser,
            heading: $translate.instant('trackMaster.jsCode.installScriptModal.heading'),
          };
        }
      }
    }

    /**
     * Трек входа на страницу настройки
     */
    function trackEnterInSetting() {
      carrotquestHelper.track('Мастер сбора данных - JS код - открыл');
    }

    /**
     * Трек сохранения JS кода
     */
    function trackJsCodeSaved() {
      carrotquestHelper.track('Мастер сбора данных - JS код - сохранил');
    }
  }
})();

import { Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';

/* eslint-disable change-detection-strategy/on-push */
@Component({
  selector: 'cq-vk-forms-manual[isManualExpanded]',
  templateUrl: './vk-forms-manual.component.html',
  styleUrls: ['./vk-forms-manual.component.scss'],
})
export class VkFormsManualComponent {
  /** Свёрнута или нет инструкция по настройке интеграции */
  @Input() isManualExpanded!: boolean;
  /** Текущий язык */
  activeLang: string;
  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;
  /** Название проекта */
  projectName = environment.projectName;

  // @formatter:off
  constructor(private readonly translocoService: TranslocoService) {
    this.activeLang = translocoService.getActiveLang();
  }
  // @formatter:on
}

import moment from 'moment';

/**
 * Ключи операций над свойствами склеиваемых пользователей. Определяют какое из двух свойств должно быть выбрано.
 * (Используются для работы со справочником правил, получаемым с бэка)
 */
export const MERGE_OPERATIONS_KEYS = {
  AND: 'and', // логическое умножение (для bool)
  LIST_CONCAT: 'list_concat', // конкатенация списков
  MAX: 'max', // максимальное из двух чисел
  MAX_STR: 'max_str', // максимальная строка (используется для сравнения дат)
  MIN: 'min', // минимальное из двух чисел
  MIN_STR: 'min_str', // минимальная строка (используется для сравнения дат)
  NEW: 'new', // свойство нового пользователя, независимо от значения
  NEW_IF_EXISTS: 'new_if_exists', // свойство нового пользователя, если есть значение, в противном случае - свойство удаляемого пользователя
  OR: 'or', // логическая сумма (для bool)
  REMOVED: 'removed', // свойство удаляемого пользователя, независимо от значения
  REMOVED_IF_EXISTS: 'removed_if_exists', // свойство удаляемого пользователя, если есть значение, в противном случае - свойство нового пользователя
  SET_INTERSECTION: 'set_intersection', // пересечение свойств списков (только уникальные значения, присутствующие в обоих списках) !!!: не реализовано на фронте, ибо хз как
  SET_UNION: 'set_union', // объединение свойств списков (только уникальные значения)
  SUM: 'sum', // сумма обоих свойств (для чисел)
};

/**
 * Операции, которые могут проводиться над свойствами лидов при склейке
 */
function getMergeOperations() {
  const mergeOperations: any = {};

  mergeOperations[MERGE_OPERATIONS_KEYS.AND] = function (n: any, r: any) {
    return n && r;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.LIST_CONCAT] = function (n: any, r: any) {
    if (n === undefined) {
      n = [];
    }
    if (r === undefined) {
      r = [];
    }
    return n.concat(r).splice(0, 30);
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.MAX] = function (n: any, r: any) {
    if (n === undefined) {
      return r;
    }
    if (r === undefined) {
      return n;
    }
    // Так как сейчас (26.12.2019) свойства возвращаются строками, то конвертим их в int
    const int_n = parseInt(n);
    const int_r = parseInt(r);
    return Math.max(int_n, int_r);
  };
  // NOTE: на самом деле эта операция выполняется на датах
  mergeOperations[MERGE_OPERATIONS_KEYS.MAX_STR] = function (n: any, r: any) {
    return n !== undefined && (!r !== undefined || moment(n).isAfter(r)) ? n : r;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.MIN] = function (n: any, r: any) {
    if (n === undefined) {
      return r;
    }
    if (r === undefined) {
      return n;
    }
    // Так как сейчас (26.12.2019) свойства возвращаются строками, то конвертим их в int
    const int_n = parseInt(n);
    const int_r = parseInt(r);
    return Math.min(int_n, int_r);
  };
  // NOTE: и эта тоже на датах
  mergeOperations[MERGE_OPERATIONS_KEYS.MIN_STR] = function (n: any, r: any) {
    return n !== undefined && (!r !== undefined || moment(n).isBefore(r)) ? n : r;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.NEW] = function (n: any, r: any) {
    return n;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.NEW_IF_EXISTS] = function (n: any, r: any) {
    return n !== undefined ? n : r;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.OR] = function (n: any, r: any) {
    return n || r;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.REMOVED] = function (n: any, r: any) {
    return r;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.REMOVED_IF_EXISTS] = function (n: any, r: any) {
    return r !== undefined ? r : n;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.SET_UNION] = function (n: any, r: any) {
    if (n === undefined) {
      n = [];
    }
    if (r === undefined) {
      r = [];
    }
    let result = n.concat(r);
    result = Array.from(new Set(result));
    return result.slice(0, 30);
  };
  //!!!: Не видела использований в правилах и хз как реализовать на js
  mergeOperations[MERGE_OPERATIONS_KEYS.SET_INTERSECTION] = function (n: any, r: any) {
    return n;
  };
  mergeOperations[MERGE_OPERATIONS_KEYS.SUM] = function (n: any, r: any) {
    if (n === undefined) {
      n = 0;
    }
    if (r === undefined) {
      r = 0;
    }
    // Так как сейчас (26.12.2019) свойства возвращаются строками, то конвертим их в int
    const int_n = parseInt(n);
    const int_r = parseInt(r);
    return int_n + int_r;
  };

  return mergeOperations;
}

export const MERGE_OPERATIONS = getMergeOperations();

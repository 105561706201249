import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CaseStyleHelper } from '@panel/app/services';
import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Преобразует результаты выполнения всех запросов в camelCase
 * Так делается, поскольку внутри админки все параметры используются в camelCase, а бэкенд присылает результаты и принимает на вход всё в _underscore
 */
@Injectable()
export class ResponseToCamelCaseInterceptor implements HttpInterceptor {
  constructor(private caseStyleHelper: CaseStyleHelper) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    const ignoreFields = request.context.get(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS);

    // Если передан true, то пропускаем преобразование
    if (ignoreFields === true) {
      return next.handle(request);
    }

    let ignoredFields: string[] = Array.isArray(ignoreFields) ? ignoreFields.filter((v) => !!v) : [];

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({
            body: this.caseStyleHelper.keysToCamelCase(event.body, ignoredFields),
          });
        }

        return event;
      }),
    );
  }
}

/**
 * Компонент страницы шаблонов сообщений
 */
(function () {
  'use strict';

  require('./message-templates.controller');

  angular.module('myApp.messageTemplates').component('cqMessageTemplates', cqMessageTemplates());

  function cqMessageTemplates() {
    return {
      bindings: {
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // Текущее приложение
        directories: '=', // Список папок
        djangoUser: '=', // Текущий пользователь
      },
      controller: 'CqMessageTemplatesController',
      controllerAs: 'vm',
      template: require('./message-templates.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { App } from '@http/app/app.model';
import {
  ACTIONS_SUB_GROUPS,
  CHAT_BOT_ACTIONS_LIMIT_COUNT,
  CHAT_BOT_ACTIONS_TYPES,
  CHAT_BOT_ACTIONS_TYPES_ICONS,
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_SUB_GROUP,
} from '@http/chat-bot/chat-bot.constants';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { CaseStyleHelper } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE_BY_CHAT_BOT_ACTION_TYPE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';

@Component({
  selector: 'cq-other-actions-dropdown[currentApp][isButtonDisabled]',
  templateUrl: './other-actions-dropdown.component.html',
  styleUrls: ['./other-actions-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherActionsDropdownComponent {
  @Input()
  currentApp!: App;

  @Input()
  isButtonDisabled!: (actionType: CHAT_BOT_ACTIONS_TYPES) => boolean;

  @Output()
  botActionCreate: EventEmitter<CHAT_BOT_ACTIONS_TYPES> = new EventEmitter<CHAT_BOT_ACTIONS_TYPES>();

  ACTIONS_SUB_GROUPS = ACTIONS_SUB_GROUPS;
  CHAT_BOT_ACTIONS_LIMIT_COUNT = CHAT_BOT_ACTIONS_LIMIT_COUNT;
  CHAT_BOT_ACTIONS_TYPES_ICONS = CHAT_BOT_ACTIONS_TYPES_ICONS;

  constructor(
    public readonly caseStyleHelper: CaseStyleHelper,
    public readonly paywallService: PaywallService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly featureModel: FeatureModel,
  ) {}

  /**
   * Получает объект для использования в ng-template. Смысл в том,
   * что раньше кнопки рендерились через цикл по CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP и кучей ng-if,
   * а сейчас цикл я убрал (потому что зачаем столько телодвижений, чтоб отрендерить кнопку).
   * Но чтоб не пришлось переделывать template каждый раз при изменении группы список actions для группы передается
   * вот таким вот контекстом (см. использование).
   */
  getActionSubGroupActions(
    actionSubGroup: ACTIONS_SUB_GROUPS.RECORD | ACTIONS_SUB_GROUPS.TRANSFER,
  ): CHAT_BOT_ACTIONS_TYPES[] {
    return CHAT_BOT_ACTIONS_TYPES_LIST_BY_SUB_GROUP[actionSubGroup];
  }

  /**
   * Есть ли доступ к экшену чат-бота
   *
   * @param action Экшен чат-бота
   */
  accessToChatBotAction(action: CHAT_BOT_ACTIONS_TYPES): ProductFeatureAccess {
    const productFeature = PLAN_FEATURE_BY_CHAT_BOT_ACTION_TYPE[action];

    if (!productFeature) {
      return {
        hasAccess: true,
        denialReason: null,
      };
    }

    return this.planFeatureAccessService.getAccess(productFeature, this.currentApp);
  }

  /**
   * Есть ли доступ к действию с отправкой данных
   * @param actionType - Тип действия бота
   */
  hasTransferActionAccess(actionType: CHAT_BOT_ACTIONS_TYPES): boolean {
    switch (actionType) {
      case CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION:
        return this.featureModel.hasAccess(FEATURES.EMAIL_NOTIFICATION);
      case CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION:
        return this.featureModel.hasAccess(FEATURES.AMOCRM_NOTIFICATION);
      default:
        return false;
    }
  }

  /**
   * Надо ли показывать действаия с отправкой данных
   */
  get isTransferActionsVisible(): boolean {
    return (
      this.featureModel.hasAccess(FEATURES.EMAIL_NOTIFICATION) ||
      this.featureModel.hasAccess(FEATURES.AMOCRM_NOTIFICATION)
    );
  }
}

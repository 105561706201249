import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'cq-bot-single-block-overlay',
  templateUrl: './bot-single-block-overlay.component.html',
  styleUrls: ['./bot-single-block-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotSingleBlockOverlayComponent {
  @Input()
  @HostBinding('style.width.px')
  width: number = 0;

  @Input()
  @HostBinding('style.height.px')
  height: number = 0;

  @Input()
  x = 0;

  @Input()
  y = 0;

  @Input()
  @HostBinding('attr.id')
  id?: string;

  constructor(public readonly changeDetectorRef: ChangeDetectorRef, private readonly viewRef: ViewContainerRef) {}

  @HostBinding('style.transform')
  get translateStyle() {
    return `translate(${this.x}px, ${this.y}px)`;
  }

  public get element(): ElementRef {
    return this.viewRef.element;
  }

  public get viewContainerRef(): ViewContainerRef {
    return this.viewRef;
  }
}

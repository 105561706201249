import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { DATA_SOURCE, DATA_SOURCE_STATUS } from '@http/ai-data/ai-data.constants';
import { AiDataBotSettingsExternal, AiDataExternal } from '@http/ai-data/external-types';
import { AiDataBotSettings } from '@http/ai-data/internal-types';
import { AiDataMapper } from '@http/ai-data/mappers/ai-data.mapper';
import { APIPaginatableResponse, PaginationParamsRequest } from '@http/types';
import { EXTENDED_RESPONSE } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class AiDataModel {
  constructor(private readonly http: HttpClient, private readonly aiDataMapper: AiDataMapper) {}

  getList(source?: DATA_SOURCE, status?: DATA_SOURCE_STATUS, paginatorParams?: PaginationParamsRequest) {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    const params: Record<string, any> = {
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition?.join() || null,
      paginate_page_order: paginatePageOrder,
    };

    if (source) {
      params.kind = this.aiDataMapper.parseDataSourceToExternal(source);
    }

    if (status) {
      params.removed = this.aiDataMapper.parseDataStatusToExternal(status);
    }

    return this.http
      .get<APIPaginatableResponse<AiDataExternal[]>>('/integrations/chat_gpt/content', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response) => {
          return {
            dataSourceList: response.data.map((data) => this.aiDataMapper.parseToInternal(data)),
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.nextBeforePosition,
            },
          };
        }),
      );
  }

  getSettings() {
    return this.http
      .get<AiDataBotSettingsExternal>(`/integrations/chat_gpt`, {})
      .pipe(map((response) => this.aiDataMapper.parseAiBotSettingsToInternal(response)));
  }

  saveSettings(settings: AiDataBotSettings) {
    const params = this.aiDataMapper.parseAiBotSettingsToExternal(settings);

    return this.http.patch(`/integrations/chat_gpt`, params);
  }
}

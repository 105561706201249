/**
 * Компонент редактора шаблона
 */
(function () {
  'use strict';

  require('./message-templates-edit.controller');

  angular.module('myApp.messageTemplates').component('cqMessageTemplatesEdit', cqMessageTemplatesEdit());

  function cqMessageTemplatesEdit() {
    return {
      bindings: {
        billingInfo: '=', // Текущее приложение
        currentApp: '=', // Текущее приложение
        directories: '=', // Список папок
        djangoUser: '=', // Текущий пользователь
        messagePart: '=', // Шаблон сообщения
        properties: '=', // Список свойств и типов событий
        /*NOTE надо получапть и шаблон и вариант сообщения т.к. в компоненте используется name, active и id из шаблона*/
        template: '=', // Шаблон сообщения
      },
      controller: 'CqMessageTemplatesEditController',
      controllerAs: 'vm',
      template: require('./message-templates-edit.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'quizSkipConfirmModalComponent'">
  <div class="modal-header align-items-start">
    <h3 class="modal-title">{{ t('title') }}</h3>
    <button
      class="close"
      (click)="closeModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div
    class="modal-body"
    [innerHTML]="t('text')"
  ></div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="skipQuizConfirm()"
    >
      {{ t('skip') }}
    </button>
    <button
      class="btn btn-primary"
      type="button"
      (click)="closeModal()"
    >
      {{ t('pickUp') }}
    </button>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'propertySelectComponent'">
  <ng-select
    [clearable]="false"
    [groupBy]="groupByFn"
    [formControl]="control"
    [items]="properties"
    [placeholder]="t('placeholder')"
    [trackByFn]="trackByFn"
    [searchFn]="searchFn"
    [virtualScroll]="true"
    bindLabel="prettyName"
    bindValue="name"
  >
    <ng-template
      ng-option-tmp
      let-option="item"
    >
      <span class="white-space-normal">{{ option.prettyName }}</span>
    </ng-template>
  </ng-select>
</ng-container>

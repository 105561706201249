export type UnicodeIcon = keyof typeof UNICODE_ICON;

/**
 * Стоит переименовать в CQI_SM_UNICODE_ICON
 */
export const UNICODE_ICON = {
  'cqi-arrow-from-top-to-right': '\ue9c0',
  'cqi-ban': '\ue905',
  'cqi-bell': '\ue9da',
  'cqi-bell-o': '\ue97f',
  'cqi-calendar': '\ue9df',
  'cqi-calendar-o': '\ue907',
  'cqi-check-circle': '\ue951',
  'cqi-check-circle-o': '\ue952',
  'cqi-check-read': '\ue966',
  'cqi-circle': '\ue981',
  'cqi-clock': '\ue992',
  'cqi-code': '\ue90d',
  'cqi-comment-out-filled': '\ue99f',
  'cqi-comment-out-o': '\ue910',
  'cqi-cursor-arrow': '\ue99e',
  'cqi-envelope': '\ue9d6',
  'cqi-envelope-o': '\ue912',
  'cqi-exit': '\ue9db',
  'cqi-external-link': '\ue914',
  'cqi-facebook': '\ue984',
  'cqi-image': '\ue96b',
  'cqi-instagram': '\ue9a0',
  'cqi-link': '\ue918',
  'cqi-mobile-push': '\ue996',
  'cqi-paper-plane-o': '\ue9d8',
  'cqi-phone': '\ue91b',
  'cqi-plus': '\ue91c',
  'cqi-plus-circle': '\ue972',
  'cqi-popup-big': '\ue9d7',
  'cqi-stopwatch': '\ue9d9',
  'cqi-telegram': '\ue985',
  'cqi-triangle-exclamation-o': '\ue929',
  'cqi-user': '\ue97d',
  'cqi-user-list': '\ue978',
  'cqi-user-o': '\ue928',
  'cqi-viber': '\ue9cd',
  'cqi-vk': '\ue987',
  'cqi-webhook': '\ue92b',
  'cqi-whatsapp': '\ue9ce',
} as const;

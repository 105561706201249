/**
 * Компонент страницы шаблонов сообщений
 */
(function () {
  'use strict';

  require('./message-templates-grid.controller');

  angular.module('myApp.messageTemplates').component('cqMessageTemplatesGrid', cqMessageTemplatesGrid());

  function cqMessageTemplatesGrid() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        directories: '=', // Список папок
        djangoUser: '=', // Текущий пользователь
        onLoad: '&?', // Колбэк при загрузке шаблонов
      },
      controller: 'CqMessageTemplatesGridController',
      controllerAs: 'vm',
      template: require('./message-templates-grid.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-close-timeout-control',
  templateUrl: './close-timeout-control.component.html',
  styleUrls: ['./close-timeout-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseTimeoutControlComponent extends AbsCVAFormControlBasedComponent<number> {
  readonly control: FormControl<number> = new FormControl<number>(15, {
    nonNullable: true,
    validators: [Validators.required],
  });

  constructor() {
    super();
  }
}

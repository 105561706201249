import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { IntegrationNameControlComponent } from './integration-name-control.component';

@NgModule({
  declarations: [IntegrationNameControlComponent],
  exports: [IntegrationNameControlComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule, TranslocoModule],
})
export class IntegrationNameControlModule {}

/**
 * Фильтр для добавления target="_blank" ко всем ссылкам (используется в iframe, чтобы при клике на ссылке не происходило смены страницы внутри iframe)
 */
(function () {
  'use strict';

  angular.module('myApp.filters.addTargetBlank').filter('addTargetBlank', addTargetBlank);

  function addTargetBlank() {
    var findLinksToAddTargetBlankRegexp = /<a(.*?)>[\s\S]*?<\/a>/gim;
    var findOldTargetRegexp = /target=".*?"/gim;

    return addTargetBlankFilter;

    /**
     * Функция, которая выполняет поиск ссылок в строке и добавляет им target="_blank"
     *
     * @param {String} value Строка, в которой будут искаться ссылки
     * @returns {String}
     */
    function addTargetBlankFilter(value) {
      if (value.constructor == String) {
        value = value.replace(findLinksToAddTargetBlankRegexp, function (entry, match) {
          // в найденной ссылке ищем другие target, чтобы убрать их (на случай, если пользователь руками в коде задал target=".*")
          var matchWithoutOldTarget = match.replace(findOldTargetRegexp, '');
          return entry.replace(match, matchWithoutOldTarget + ' target="_blank"');
        });
      }
      return value;
    }
  }
})();

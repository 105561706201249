import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { find } from 'lodash-es';
import { Observable } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

import { AppModel } from '@http/app/app.model';
import { FeatureModel } from '@http/feature/feature.model';
import { DestroyService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-events-analytics',
  templateUrl: './events-analytics.component.html',
  styleUrls: ['./events-analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class EventsAnalyticsComponent implements OnInit, AfterViewInit {
  /** информация о биллинге */
  @Input() billingInfo!: any;
  /** текущее приложение */
  @Input() currentApp!: any;
  /** текущий пользователь */
  @Input() djangoUser!: any;
  /** типы событий */
  @Input() eventTypes!: any;

  protected GRAPH_TYPES: { EVENTS: string; USERS: string } = {
    EVENTS: 'events',
    USERS: 'users',
  };

  protected GRAPH_TYPES_ARRAY = [this.GRAPH_TYPES.EVENTS, this.GRAPH_TYPES.USERS];

  protected selectedEventTypeIdsControl = new FormControl<any>([], []);
  /**
   * Максимальная длина имени события
   * используется в легенде графика и в селекторе событий
   *
   * @type {number}
   */
  protected EVENT_NAME_MAX_LENGTH: number = 50;

  protected accessToUsersEvents: any;
  protected chart: any;
  protected graphType: any = this.GRAPH_TYPES.EVENTS;
  protected loaded: boolean = false;

  constructor(
    protected readonly appModel: AppModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly destroy$: DestroyService,
    protected readonly featureModel: FeatureModel,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.accessToUsersEvents = this.planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_EVENTS, this.currentApp);

    this.selectedEventTypeIdsControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.update(value);
    });

    this.trackEnterOnPage();
  }

  ngAfterViewInit(): void {
    //@ts-ignore
    this.chart = AmCharts.makeChart('chart-div', {
      type: 'serial',
      theme: 'light',
      pathToImages: 'assets/img/amcharts/',
      legend: {
        useGraphSettings: true,
        align: 'left',
        position: 'right',
      },
      chartScrollbar: {
        autoGridCount: true,
        scrollbarHeight: 40,
        minimun: 50,
      },
      marginTop: 7,
      dataProvider: [],
      valueAxes: [
        {
          integersOnly: true,
          axisAlpha: 0,
          dashLength: 5,
          gridCount: 10,
          gridAlpha: 0,
          position: 'left',
          title: '',
        },
      ],
      categoryAxis: {
        parseDates: true,
        position: 'top',
        dashLength: 0,
      },
      balloon: {
        enabled: true,
      },
      startDuration: 0.0,
      graphs: [],
      chartCursor: {
        cursorAlpha: 0,
        zoomable: true,
      },
      categoryField: '__label__',
      zoomOutText: this.translocoService.translate('eventsAnalytics.chartSettings.showAll'),
    });

    this.chart.addListener('dataUpdated', () => {
      this.chart.zoomToIndexes(this.chart.dataProvider.length - 31, this.chart.dataProvider.length - 1);
    });

    var startSessionEventType = find(this.eventTypes, { name: '$session_start' });

    if (startSessionEventType) {
      this.selectedEventTypeIdsControl.setValue([
        ...this.selectedEventTypeIdsControl.getRawValue(),
        startSessionEventType.id,
      ]);
      this.update(this.selectedEventTypeIdsControl.getRawValue());
    }

    this.cdr.detectChanges();
  }

  update(eventTypeIds: any): Observable<any> | void {
    if (!eventTypeIds.length) {
      return;
    }

    this.loaded = false;

    this.appModel
      .getReport(this.currentApp.id, eventTypeIds, true)
      .pipe(
        takeUntil(this.destroy$),
        map((response: any) => {
          let data: any = [];
          let eventTypeId;
          let graphs = [];

          for (let i = 0; i < eventTypeIds.length; i++) {
            eventTypeId = eventTypeIds[i];

            if (response.data[eventTypeId]) {
              let eventType = find(this.eventTypes, { id: eventTypeId });
              let title = eventType ? eventType.prettyName : undefined;
              if (title.length > this.EVENT_NAME_MAX_LENGTH) {
                title = title.substring(0, this.EVENT_NAME_MAX_LENGTH) + '...';
              }
              graphs.push({
                title: title,
                valueField: eventTypeId,
                fillAlphas: 0,
                bullet: 'round',
                type: 'smoothedLine',
              });
            }
          }
          this.chart.graphs = graphs;

          for (let i = 0; i < eventTypeIds.length; i++) {
            eventTypeId = eventTypeIds[i];

            if (response.data[eventTypeId]) {
              for (let j = 0; j < response.data[eventTypeId].length; j++) {
                data.push({ __label__: response.data[eventTypeId][j][0] });
              }
              break;
            }
          }

          for (let i = 0; i < eventTypeIds.length; i++) {
            eventTypeId = eventTypeIds[i];

            if (response.data[eventTypeId]) {
              for (let j = 0; j < response.data[eventTypeId].length; j++) {
                if (this.graphType == this.GRAPH_TYPES.USERS) {
                  data[j][eventTypeId] = response.data[eventTypeId][j][1][1];
                } else {
                  data[j][eventTypeId] = response.data[eventTypeId][j][1][0];
                }
              }
            }
          }

          this.chart.dataProvider = data;
          this.chart.validateData();
        }),
        finalize(() => {
          this.loaded = true;
          this.cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  trackEnterOnPage(): void {
    this.carrotquestHelper.track('Аналитика событий - зашел');
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cq-trigger-chain-general-statistics-breadcrumbs',
  templateUrl: './trigger-chain-general-statistics-breadcrumbs.component.html',
  styleUrls: ['./trigger-chain-general-statistics-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainGeneralStatisticsBreadcrumbsComponent {
  @Input({ required: true })
  chainName!: string;

  constructor() {}
}

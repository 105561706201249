import { chatBotTypeTokenProvider } from '@panel/app/pages/chat-bot/content/tokens/chat-bot-type.token';
import { connectionsCollectionProvider } from '@panel/app/pages/chat-bot/content/tokens/connection-collection.token';

import { branchViewMapProvider } from './branch-collection.token';
import { pixiTokenProvider } from './pixi.token';
import { pixiViewportProvider } from './pixi-viewport.token';
import { pixiCullProvider } from './viewport-culling.token';

export * from './branch-collection.token';
export * from './chat-bot-type.token';
export * from './connection-collection.token';
export * from './pixi.token';
export * from './pixi-viewport.token';
export * from './viewport-culling.token';

export const botTokenProviders = [
  branchViewMapProvider,
  chatBotTypeTokenProvider,
  connectionsCollectionProvider,
  pixiTokenProvider,
  pixiViewportProvider,
  pixiCullProvider,
];

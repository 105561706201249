import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';

// !!! используйте компоненты NewLabelComponent и BetaLabelComponent для показа фичалейблов, не верстайте отсебятину
export enum FEATURE_LABELS_ONLY {
  /** Скриншотилка схем бота и триггерных цепочек */
  CANVAS_SCREENSHOT = 'canvas_screenshot',
  /** Автораспределение диалогов */
  CONVERSATIONS_AUTO_ASSIGNMENT = 'conversations_auto_assignment',
  /** Ранний доступ до Facebook и Telegram ботов */
  EARLY_ACCESS_FOR_FACEBOOK_AND_TELEGRAM_BOT = 'early_access_for_facebook_and_telegram_bot',
  /** Новый раздел "Настройки диалогов" */
  NEW_PAGE_INBOX_SETTINGS = 'new_inbox_settings',
  ROUTING_BOT = 'routing_bot',
  TRIGGER_CHAIN = 'trigger_chain',
}

export const FEATURE_LABELS = {
  ...FEATURE_LABELS_ONLY,
  ...INTEGRATION_TYPES,
};

export type FEATURE_LABELS = FEATURE_LABELS_ONLY | INTEGRATION_TYPES;

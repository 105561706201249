import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgArrayPipesModule } from 'ngx-pipes';

import { LeadsTableColumnFilterComponent } from './leads-table-column-filter.component';

@NgModule({
  declarations: [LeadsTableColumnFilterComponent],
  exports: [LeadsTableColumnFilterComponent],
  imports: [CommonModule, FormsModule, NgArrayPipesModule, TranslocoModule],
})
export class LeadsTableColumnFilterModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ConversationsSettingsBreadcrumbsComponent } from './conversations-settings-breadcrumbs.component';

@NgModule({
  declarations: [ConversationsSettingsBreadcrumbsComponent],
  exports: [ConversationsSettingsBreadcrumbsComponent],
  imports: [CommonModule, TranslocoModule],
})
export class ConversationsSettingsBreadcrumbsModule {}

import { module } from 'angular';

import {
  AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS,
  CONFIGURABLE_INTEGRATION_TYPES,
  INTEGRATION_CLASSES,
  INTEGRATION_CLASSES_ARRAY,
  INTEGRATION_TYPES,
  INTEGRATIONS_BY_CLASS,
  INTEGRATIONS_ONE_TO_APP_ARRAY,
  INTEGRATIONS_WARNINGS_BY_TYPE,
} from '@http/integration/constants/integration.constants';
import { IntegrationModel } from '@panel/app-old/shared/models/integration/integration.model';

module('myApp.models.integration', [])
  .service('integrationModel', IntegrationModel)
  .constant('CONFIGURABLE_INTEGRATION_TYPES', CONFIGURABLE_INTEGRATION_TYPES)
  .constant('INTEGRATION_TYPES', INTEGRATION_TYPES)
  .constant('INTEGRATION_CLASSES', INTEGRATION_CLASSES)
  .constant('INTEGRATION_CLASSES_ARRAY', INTEGRATION_CLASSES_ARRAY)
  .constant('INTEGRATIONS_BY_CLASS', INTEGRATIONS_BY_CLASS)
  .constant('INTEGRATIONS_ONE_TO_APP_ARRAY', INTEGRATIONS_ONE_TO_APP_ARRAY)
  .constant('INTEGRATIONS_WARNINGS_BY_TYPE', INTEGRATIONS_WARNINGS_BY_TYPE)
  .constant('AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS', AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS);

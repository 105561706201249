import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AmocrmField } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { EventType, EventTypeProperty, UserProperty } from '@http/property/property.model';
import { AutoEvent } from '@http/track-master/track-master.model';

@Injectable()
export class AmocrmStateService {
  readonly autoEvents$: BehaviorSubject<AutoEvent[]> = new BehaviorSubject<AutoEvent[]>([]);
  readonly contactFields$: BehaviorSubject<AmocrmField[]> = new BehaviorSubject<AmocrmField[]>([]); // Список полей контакта (contact)
  readonly eventTypeProps$: BehaviorSubject<EventTypeProperty[]> = new BehaviorSubject<EventTypeProperty[]>([]); // Свойства типов событий
  readonly eventTypes$: BehaviorSubject<EventType[]> = new BehaviorSubject<EventType[]>([]); // Типы событий
  readonly leadFields$: BehaviorSubject<AmocrmField[]> = new BehaviorSubject<AmocrmField[]>([]); // Список полей сделки (lead)
  readonly userProps$: BehaviorSubject<UserProperty[]> = new BehaviorSubject<UserProperty[]>([]); // Свойства пользователя
}

<ng-container *transloco="let t; read: 'triggerChainDelayEditorComponent'">
  <cq-trigger-chain-step-editor-wrap
    [formControl]="form.controls.name"
    (deleteBlockClick)="deleteStepChain()"
    (copyBlockClick)="copyStepChain()"
  >
    <div class="p-15 pt-25">
      <div class="margin-between-rows-15">
        <div class="btn-group w-100">
          <button
            type="button"
            class="btn btn-outline-primary"
            [ngClass]="{ active: selectedDelayGroup === DELAY_GROUP.FIXED }"
            (click)="onDelayGroupChange(DELAY_GROUP.FIXED)"
          >
            {{ t('group.fixed') }}
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            [ngClass]="{ active: selectedDelayGroup === DELAY_GROUP.DYNAMIC }"
            (click)="onDelayGroupChange(DELAY_GROUP.DYNAMIC)"
          >
            {{ t('group.dynamic') }}
          </button>
        </div>

        <ng-container *ngIf="selectedDelayGroup === DELAY_GROUP.FIXED">
          <div class="text-secondary">{{ t('heading.fixed') }}</div>

          <div class="custom-control custom-radio">
            <input
              class="custom-control-input"
              name="radio"
              type="radio"
              [id]="DELAY_TYPE.DELAY"
              [value]="DELAY_TYPE.DELAY"
              [(ngModel)]="selectedDelayType"
              (ngModelChange)="onDelayTypeChange($event)"
            />
            <label
              class="custom-control-label"
              [for]="DELAY_TYPE.DELAY"
            >
              {{ t('type.seconds') }}
            </label>
          </div>

          <ng-container *ngIf="selectedDelayType === DELAY_TYPE.DELAY">
            <cq-time-selector
              [formControl]="form.controls.delay"
              [maxSeconds]="MAX_DELAY"
            ></cq-time-selector>
          </ng-container>

          <div class="custom-control custom-radio">
            <input
              class="custom-control-input"
              name="radio"
              type="radio"
              [id]="DELAY_TYPE.WAIT_FOR_DATE"
              [value]="DELAY_TYPE.WAIT_FOR_DATE"
              [(ngModel)]="selectedDelayType"
              (ngModelChange)="onDelayTypeChange($event)"
            />
            <label
              class="custom-control-label"
              [for]="DELAY_TYPE.WAIT_FOR_DATE"
            >
              {{ t('type.date') }}
            </label>
          </div>
          <ng-container *ngIf="selectedDelayType === DELAY_TYPE.WAIT_FOR_DATE">
            <cq-datepicker-input
              [formControl]="form.controls.waitForDate"
              [options]="{
                showApplyButtons: true,
                showTimepicker: true,
                format: t('dateFormat')
              }"
            ></cq-datepicker-input>

            <div class="d-flex flex-column text-secondary margin-between-rows-10">
              <small>{{ t('description.fixed.timezoneText', { timezone: this.app.settings.timezone }) }}</small>
              <ngb-alert
                type="secondary"
                [dismissible]="false"
              >
                <div class="d-flex margin-between-cols-10">
                  <div>
                    <i class="cqi-sm cqi-info"></i>
                  </div>
                  <div>
                    <small>{{ t('description.fixed.failOutcomeText') }}</small>
                  </div>
                </div>
              </ngb-alert>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="selectedDelayGroup === DELAY_GROUP.DYNAMIC">
          <div>
            <div class="text-secondary">{{ t('heading.dynamic') }}</div>
            <div class="input-group w-100 mt-10">
              <div
                *ngIf="l10nHelper.isRusCountry()"
                class="input-group-prepend"
              >
                <div class="input-group-text">{{ t('description.dynamic.before') }}</div>
              </div>
              <cq-time-selector
                class="flex-grow-1"
                [formControl]="form.controls.delay"
                [maxSeconds]="MAX_DELAY"
              ></cq-time-selector>
            </div>
          </div>

          <div>
            <div class="text-secondary">{{ t('description.dynamic.labelProperty') }}</div>
            <cq-property-selector
              class="mt-10"
              [currentApp]="app"
              [formControl]="form.controls.propertyName"
              [properties]="(triggerChainEditorStore.properties$ | async)?.userProps ?? []"
              (propertiesChange)="onPropertiesChange($event)"
            >
              <ng-template
                cqPropertyOption
                let-option="option"
              >
                <div class="d-flex">
                  <span class="flex-grow-1 white-space-normal">
                    {{ option.prettyName }}
                  </span>
                  <i
                    *ngIf="option.cls === CLICKHOUSE_PROPERTY_TYPE.DT"
                    [ngbTooltip]="t('property.tooltipSuccess')"
                    placement="top-right"
                    container="body"
                    class="cqi-sm cqi-check-circle text-success"
                  ></i>
                  <i
                    *ngIf="option.cls !== CLICKHOUSE_PROPERTY_TYPE.DT"
                    [ngbTooltip]="t('property.tooltipDanger')"
                    placement="top-right"
                    container="body"
                    class="cqi-sm cqi-circle-exclamation text-danger"
                  ></i>
                </div>
              </ng-template>
            </cq-property-selector>
          </div>

          <ngb-alert
            type="secondary"
            [dismissible]="false"
          >
            <div class="d-flex margin-between-cols-10">
              <div>
                <i class="cqi-sm cqi-info"></i>
              </div>
              <div>
                <small>{{ t('description.dynamic.failOutcomeText') }}</small>
              </div>
            </div>
          </ngb-alert>
        </ng-container>
      </div>
    </div>
  </cq-trigger-chain-step-editor-wrap>
</ng-container>

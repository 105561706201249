<ng-container *transloco="let t">
  <div class="flex align-items-center margin-between-cols-20">
    <div class="flex-grow no-flex-basis">
      <div class="icon mb-20">
        <cq-integration-icon [type]="INTEGRATION_TYPES.VK"></cq-integration-icon>
      </div>
      <div class="text-uppercase">{{ t('vkFormsStandardQuestionsComponent.keyInput.description') }}</div>
    </div>
    <div>
      <i class="cqi-sm cqi-arrow-right invisible"></i>
    </div>
    <div class="flex-grow no-flex-basis margin-right-10">
      <img
        class="margin-bottom-20"
        *ngIf="isUsCountry"
        height="50"
        src="assets/img/default/logos/dashly.svg"
      />
      <img
        class="margin-bottom-20"
        *ngIf="!isUsCountry"
        height="50"
        src="assets/img/default/logos/carrot-quest.svg"
      />
      <div class="text-uppercase">{{ t('vkFormsStandardQuestionsComponent.valueSelect.description') }}</div>
    </div>
    <button
      class="btn btn-borderless-primary invisible"
      type="button"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
  <div
    class="flex align-items-center margin-between-cols-20 margin-bottom-10"
    *ngFor="let question of standardQuestionsControls"
  >
    <div class="flex-grow no-flex-basis">
      <cq-checkbox-control [formControl]="question.controls.checked">
        {{ t('vkFormsStandardQuestionsComponent.keyInput.' + question.controls.v.value) }}
      </cq-checkbox-control>
    </div>
    <div>
      <i class="cqi-sm cqi-arrow-right"></i>
    </div>
    <cq-vk-forms-standard-questions-property
      class="flex-grow no-flex-basis margin-right-10"
      [control]="question.controls.v"
      [currentApp]="currentApp"
      [properties]="properties"
    ></cq-vk-forms-standard-questions-property>
    <button
      class="btn btn-borderless-primary invisible"
      type="button"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
</ng-container>

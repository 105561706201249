import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';

import { EMOJI_GROUP_ICON_CLASSES } from '@panel/app/shared/services/emoji/emoji.constants';
import { EmojiService } from '@panel/app/shared/services/emoji/emoji.service';

@Component({
  selector: 'cq-emoji-selector',
  templateUrl: './emoji-selector.component.html',
  styleUrls: ['./emoji-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiSelectorComponent {
  /** Callback функция выбора emoji */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEmojiSelect: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('overlayScrollbars') scrollbar!: OverlayScrollbarsComponent;
  /**
   * Список групп emoji
   *
   * @type {Array}
   */
  protected emojiGroups: any[] = this.emojiService.getEmojiGroupList();

  protected currentEmojiList = this.emojiService.getEmojiListByGroup(this.emojiGroups[0]); // текущий список emoji для вывода
  protected currentGroup: any = this.emojiGroups[0];

  constructor(protected readonly emojiService: EmojiService) {}

  /**
   * Изменение группы отображаемых emoji
   *
   * @param group Группа emoji
   */
  changeEmojiGroup(group: any): void {
    this.currentEmojiList = this.emojiService.getEmojiListByGroup(group);
    // при смене группы нужно прокрутить кастомный скролл наверх
    this.scrollbar.osInstance()!.elements().scrollOffsetElement.scrollTo({ top: 0 });
  }

  /**
   * Функция, вызываемая при клике на emoji
   */
  selectEmoji(emojiName: any): void {
    this.onEmojiSelect.emit({ emojiName: emojiName });
  }

  trackByEmojiGroupFn(group: any): any {
    return group;
  }

  getEmojiGroupIconClasses(emojiGroup: any): any {
    // @ts-ignore
    return EMOJI_GROUP_ICON_CLASSES[emojiGroup];
  }
}

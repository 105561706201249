<ng-container *transloco="let t; read: 'leadsTableColumnFilterComponent'">
  <div class="d-flex flex-col flex-grow no-flex-basis">
    <div class="form-group has-feedback margin-10">
      <input
        class="form-control"
        [(ngModel)]="searchPhrase"
        (ngModelChange)="onInputSearchPhrase($event)"
        type="text"
        [placeholder]="t('searchInput.placeholder')"
      />
      <span class="form-control-feedback">
        <i class="cqi-sm cqi-search"></i>
      </span>
    </div>

    <div
      #scrollContainer
      class="custom-scroll overflow-y-auto margin-between-rows-5"
    >
      <div
        *ngIf="isNeedToShowDefaultProperty()"
        class="d-flex px-10 margin-between-cols-10"
      >
        <div class="cursor-pointer text-muted">
          <i class="cqi-sm cqi-check-circle cqi-check-square"></i>
        </div>
        <div class="cursor-pointer text-break text-muted">
          {{ defaultSelectedUserProperty.prettyName }}
        </div>
      </div>

      <ng-container *ngFor="let item of renderedItems; trackBy: trackBy">
        <div
          class="d-flex px-10 margin-between-cols-10"
          (click)="onClickItem(item, $event)"
        >
          <div class="cursor-pointer">
            <i
              *ngIf="item.checked"
              class="cqi-sm cqi-check-circle cqi-check-square"
            ></i>
            <i
              *ngIf="!item.checked"
              class="cqi-sm cqi-circle cqi-square-o"
            ></i>
          </div>
          <div class="cursor-pointer text-break">
            {{ item.prettyName }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

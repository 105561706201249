import { Injectable } from '@angular/core';
import { ui } from 'angular';
import { PLAN_CAPABILITY_POPOVER_OPENING_STATE } from 'app/services/plan-capability-helper/plan-capability-helper.constants';

import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import { ProductFeatureDenialReason } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { App } from '@http/app/app.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

/**
 * Помощник для работы с ограничениями по тарифу
 */
@Injectable({ providedIn: 'root' })
export class PlanCapabilityHelper {
  constructor(
    private readonly uibModalService: UibModalService,
    private readonly billingInfoModel: BillingInfoModel,
    private readonly djangoUserModel: DjangoUserModel,
  ) {
    this.initPlanCapabilityState();
  }

  public states: any = {};

  /**
   * Смена статуса открытия поповера с ограничениями
   *
   * @param capabilityName - Название ограничения
   */
  public changePopoverOpeningState(capabilityName: PLAN_CAPABILITIES): void {
    this.states[capabilityName] = !this.states[capabilityName];
  }

  /**
   * Закрытие всех открытых поповеров с ограничениями
   */
  public closeAllPlanCapabilityPopovers(): void {
    for (let state in this.states) {
      this.states[state] = false;
    }
  }

  /**
   * Получение статуса открытия поповера с ограничениями
   *
   * @param capabilityName - Название ограничения
   */
  public getPopoverOpeningState(capabilityName: PLAN_CAPABILITIES): boolean {
    return PLAN_CAPABILITY_POPOVER_OPENING_STATE[capabilityName];
  }

  /**
   * Инициализация состояний открытости поповеров ограничений
   */
  public initPlanCapabilityState(): void {
    for (let capability in PLAN_CAPABILITY_POPOVER_OPENING_STATE) {
      this.states[capability] = PLAN_CAPABILITY_POPOVER_OPENING_STATE[capability] || false;
    }
  }

  /**
   * Показ стандартного пейволла ограничений по тарифу
   *
   * @param currentApp - Текущее приложение
   * @param denialReason - Причина | Причины отказа в доступе к фиче
   */
  showPaywall(
    currentApp: App,
    denialReason: ProductFeatureDenialReason | ProductFeatureDenialReason[] | null,
  ): ui.bootstrap.IModalInstanceService {
    return this.uibModalService.open({
      size: 'xl',
      component: 'cqPlanCapabilityPaywallModal',
      resolve: {
        billingInfo: ((value: any) => value).bind(null, this.billingInfoModel.billingInfo),
        currentApp: ((value: any) => value).bind(null, currentApp),
        denialReason: ((value: any) => value).bind(null, denialReason),
        djangoUser: ((value: any) => value).bind(null, this.djangoUserModel.djangoUser),
      },
    });
  }
}

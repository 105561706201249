import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { ACCOUNT_TABS } from '@panel/app/pages/account/pages/account/account.constants';
import { DATA_COLLECTION_TABS } from '@panel/app/pages/data-collection/pages/data-collection/data-collection.constants';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';

@Component({
  selector: 'cq-data-collection',
  templateUrl: './data-collection.component.html',
  styleUrls: ['./data-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataCollectionComponent implements OnInit {
  /** автособытия */
  @Input() autoEvents!: any;
  /** информация о биллинге */
  @Input() billingInfo!: any;
  /** текущее приложение */
  @Input() currentApp!: any;
  /** текущий пользователь */
  @Input() djangoUser!: any;
  /** типы событий */
  @Input() eventTypes!: any;

  protected accessToTrackMasterJsCode: any;
  protected currentTab = DATA_COLLECTION_TABS.TRACK_MASTER;
  protected DATA_COLLECTION_TABS = DATA_COLLECTION_TABS;

  constructor(
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly uiRouter: UIRouter,
  ) {}

  ngOnInit(): void {
    this.accessToTrackMasterJsCode = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.TRACK_MASTER_JS_CODE,
      this.currentApp,
    );

    // выбираем нужную вкладку, если был передан параметр tab
    if (
      this.uiRouter.stateService.params.tab &&
      Object.values(DATA_COLLECTION_TABS).includes(this.uiRouter.stateService.params.tab)
    ) {
      this.currentTab = this.uiRouter.stateService.params.tab;
    }
  }

  protected readonly ACCOUNT_TABS = ACCOUNT_TABS;
}

(function () {
  'use strict';

  require('./edit.controller');

  angular.module('myApp.chatBot.routingBot').component('cqRoutingBotEdit', cqRoutingBotEdit());

  function cqRoutingBotEdit() {
    return {
      bindings: {
        billingInfo: '=', // Информация о биллинге
        branchesIdsToDelete: '=?',
        channels: '=', // Каналы
        currentApp: '=', // Текущее приложение
        calendlyEventList: '=', // Список календарей в интеграциях Calendly
        calendlyIntegrationList: '=', // Список интеграций Calendly
        dataAboutActiveFacebookBots: '=', // Информация об активных Facebook ботах
        dataAboutActiveLeadBots: '=', // Информация об активных Lead ботах
        dataAboutActiveWelcomeBots: '=', // Информация об активных Welcome ботах
        djangoUser: '=', // Текущий пользователь
        emailNotificationIntegrationsExternal: '=', // Интеграции типа "Уведомление на Email" в внешнем формате
        isCopyFromLeadBot: '=?', // является ли копией от лидбота
        amocrmIntegrationsExternal: '=', // Интеграции типа AmoCRM
        properties: '=', // Список свойств и типов событий
        templates: '=', // Список шаблонов
        routingBot: '=',
        tags: '=', // Теги пользователей
        teamMembers: '=', // члены команды
      },
      controller: 'cqRoutingBotEditController',
      controllerAs: 'vm',
      template: require('./edit.html'),
    };
  }
})();

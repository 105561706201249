<div
  class="position-relative"
  [ngClass]="size"
>
  <div
    [innerHTML]="trustedAvatar"
    [ngClass]="{ 'has-status': !!status }"
    class="avatar rounded-circle overflow-hidden"
  ></div>
  <div
    *ngIf="status"
    [ngClass]="statusCssClass"
    class="status"
    data-test="presence-status"
  ></div>
</div>

import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';

import { LogoutComponent } from '@panel/app/pages/logout/logout.component';

export const LOGOUT_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'logout',
    component: LogoutComponent,
    url: '/logout',
  },
];

import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { IPromise } from 'angular';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { GetUserListParams, User } from '@http/user/types/user.type';
import { UserModel } from '@http/user/user.model';
import { DestroyService } from '@panel/app/services';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { UtilsService } from '@panel/app-old/shared/services/utils/utils.service';

@Component({
  selector: 'cq-conversation-user-list',
  templateUrl: './conversation-user-list.component.html',
  styleUrls: ['./conversation-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ConversationUserListComponent implements OnInit {
  /**
   * Еммитер клика на кнопку "назад"
   */
  @Output()
  backwardClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Еммитер выбора пользователя
   */
  @Output()
  userSelect: EventEmitter<User> = new EventEmitter<User>();

  /**
   * Еммитер поиска по пользователям
   */
  @Output()
  searchUse: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Выбранный пользователь
   */
  selectedUser?: User;

  /**
   * Список пользователей
   */
  users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  QUERY_MIN_LENGTH = 3;

  QUERY_MAX_LENGTH = 15;

  /**
   * Флаг загрузки данных
   */
  isDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Форма поиска лидов
   */
  searchControl: GenericFormControl<string> = new GenericFormControl<string>(null, [
    Validators.minLength(this.QUERY_MIN_LENGTH),
  ]);

  /**
   * Общее количество пользователей для текущего поискового запроса
   */
  totalUsersAmount!: number;

  highlightedText: string = '';

  constructor(
    private readonly destroy$: DestroyService,
    private readonly userModel: UserModel,
    private readonly appService: AppService,
    public readonly utilsService: UtilsService,
  ) {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        filter(
          (query) => (query.length >= this.QUERY_MIN_LENGTH && query.length <= this.QUERY_MAX_LENGTH) || !query.length,
        ),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((query) => {
        this.searchUse.emit(query);
        this.highlightedText = query ?? '';
        this.getUsers(query).then((users) => {
          this.users$.next(users);
        });
      });
  }

  /**
   * Сабмит формы поиска
   *
   * по сути нужен чтобы если пользователь ввел в инпут символов меньше QUERY_MIN_LENGTH и нажал enter,
   * ему показалась ошибка
   */
  submitSearchForm() {
    !this.searchControl.touched && this.searchControl.markAsTouched();
  }

  ngOnInit(): void {
    this.getUsers().then((users) => {
      this.users$.next(users);
    });
  }

  /**
   * Выбрать пользователя
   * @param user - Выбранный пользователь
   */
  selectUser(user: User) {
    this.selectedUser = user;
    this.userSelect.emit(user);
  }

  isUserSelected(user: User): boolean {
    return user.id === this.selectedUser?.id;
  }

  /**
   * Подгрузка пользователей
   */
  loadMoreUsers() {
    this.getUsers(this.searchControl.value, this.users$.value.length).then((users) => {
      this.users$.next([...this.users$.value, ...users]);
    });
  }

  /**
   * Обновления списка пользователей
   * @param query - Поисковой запрос
   * @param offset - Сдвиг для подзугрузки пользователей
   * @private
   */
  private getUsers(query?: string | null, offset?: number): IPromise<User[]> {
    this.isDataLoading$.next(true);
    const props: GetUserListParams = {
      query: query ?? undefined,
      offset,
    };
    return firstValueFrom(this.userModel.getListWithConversations(this.appService.currentAppId, props))
      .then(({ users, total }) => {
        this.totalUsersAmount = total;
        return users;
      })
      .catch(() => {
        this.totalUsersAmount = 0;
        return [];
      })
      .finally(() => {
        this.isDataLoading$.next(false);
      });
  }

  /**
   * Трекинг списка пользователей по ID
   * @param index - Индекс в списке
   * @param user - Пользователь
   */
  trackByUserId(index: number, user: User) {
    return user.id;
  }

  /**
   * Путь до zero-data изображения
   */
  get pathToZeroDataImg(): string {
    return (
      'assets/img/default/users/' +
      (this.utilsService.isDarkThemeActive() ? 'dark' : 'default') +
      '/zero-data-leads.png'
    );
  }
}

/**
 * Фильтр, красиво выводящий дату
 */
(function () {
  'use strict';

  angular.module('myApp.filters.prettyDate').filter('cqPrettyDate', cqPrettyDate);

  function cqPrettyDate($translate, moment) {
    return prettyDateFilter;

    /**
     * Формирование красивой даты для вывода
     *
     * @param {moment|Date} value Объект даты
     * @returns {String}
     */
    function prettyDateFilter(value) {
      var now = moment();

      // FIXME: пока что на вход потенциально может прийти не moment. Потом надо от этого условия избавиться
      if (!(value instanceof moment)) {
        value = moment(value);
      }

      if (now.diff(value, 'seconds') >= 0 && now.diff(value, 'seconds') < 20) {
        return $translate.instant('filters.prettyDate.justNow');
      } else if (now.diff(value, 'seconds') >= 20 && now.diff(value, 'seconds') < 60) {
        return $translate.instant(
          'filters.prettyDate.secondsAgo',
          { count: now.diff(value, 'seconds') },
          'messageformat',
        );
      } else if (now.diff(value, 'minutes') >= 1 && now.diff(value, 'minutes') < 60) {
        return $translate.instant(
          'filters.prettyDate.minutesAgo',
          { count: now.diff(value, 'minutes') },
          'messageformat',
        );
      } else {
        return value.format('lll');
      }
    }
  }
})();

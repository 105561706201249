import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { ToDevelopersModule } from '@panel/app/pages/to-developers/page/to-developers/to-developers.module';
import { TO_DEVELOPERS_ROUTES } from '@panel/app/pages/to-developers/to-developers-page.routes';

@NgModule({
  declarations: [],
  imports: [CommonModule, ToDevelopersModule, UIRouterUpgradeModule.forChild({ states: TO_DEVELOPERS_ROUTES })],
})
export class ToDevelopersPageModule {}

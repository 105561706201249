<ng-container *transloco="let t; read: 'propertyFilterComponent'">
  <div class="margin-between-rows-10">
    <div
      class="text-muted small"
      *ngIf="showPropertyCounter"
    >
      {{ t('property', { position: getPositionByIndex(index) }) }}
    </div>

    <div class="d-flex margin-between-cols-5">
      <cq-property-select
        class="form-group min-width-0 flex-grow mb-0"
        [properties]="props"
        [formControl]="control.controls.propertyName"
      ></cq-property-select>

      <button
        class="btn btn-outline-primary"
        *ngIf="showRemoveButton"
        (click)="onRemoveButtonClick(index)"
        type="button"
      >
        <i class="btn-icon cqi-sm cqi-times"></i>
      </button>
    </div>

    <ng-container *ngIf="showOperationSelect">
      <cq-operation-select
        *ngIf="!isCustomOperationSelect()"
        [formControl]="control.controls.operation"
      ></cq-operation-select>

      <cq-email-operation-select
        *ngIf="isEmailSubscriptionsFilter"
        [formControl]="control.controls.operation"
      ></cq-email-operation-select>

      <cq-telegram-operation-select
        *ngIf="isTelegramSubscriptionsFilter()"
        [formControl]="control.controls.operation"
        [telegramIntegrations]="telegramIntegrations"
      ></cq-telegram-operation-select>
    </ng-container>
  </div>
</ng-container>

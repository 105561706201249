import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cq-edit-saved-reply-modal',
  templateUrl: './edit-saved-reply-modal.component.html',
  styleUrls: ['./edit-saved-reply-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSavedReplyModalComponent implements OnInit {
  @Input() modalWindowParams: any;

  /**
   * Доступные варианты куда можно добавить новый сохранённый ответ: в начало (false) или в конец (true) списка
   *
   * @type {boolean[]}
   */
  protected ADD_TO_THE_END_OPTIONS: boolean[] = [true, false];

  /**
   * Максимальная длина текста сохранённого ответа
   *
   * @type {number}
   */
  protected MAX_BODY_LENGTH: number = 20000;

  /**
   * Максимальная длина заголовка сохранённого ответа
   *
   * @type {number}
   */
  protected MAX_HEADER_LENGTH: number = 255;

  /** Флаг добавления ответа в конец списка */
  protected addToTheEnd: boolean = true;
  /** Флаг выполнения запроса */
  protected isApiRequestPerformed: boolean = false;
  /** Определение режима: редактирование или создание */
  protected isEdit: boolean = false;

  protected savedReplyForm = this.fb.group({
    header: this.fb.control(null, [Validators.maxLength(this.MAX_HEADER_LENGTH)]),
    body: this.fb.control(null, [Validators.required, Validators.maxLength(this.MAX_BODY_LENGTH)]),
  });

  constructor(protected readonly ngbActiveModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.isEdit = !!this.modalWindowParams.savedReply.id; // Определение режима: редактирование или создание
    this.modalWindowParams.savedReply.body = this.decodeMsg(this.modalWindowParams.savedReply.body);

    this.savedReplyForm.setValue({
      header: this.modalWindowParams.savedReply ? this.modalWindowParams.savedReply.header : '',
      body: this.modalWindowParams.savedReply ? this.modalWindowParams.savedReply.body : '',
    });
  }

  createOrSave(isValid: any): void {
    if (isValid) {
      this.isApiRequestPerformed = true;

      this.modalWindowParams.savedReply = {
        ...this.modalWindowParams.savedReply,
        ...this.savedReplyForm.getRawValue(),
      };

      this.ngbActiveModal.close({
        reply: this.modalWindowParams.savedReply,
        addToTheEnd: this.isEdit ? null : this.addToTheEnd,
      });
    }
  }

  /**
   * Преобразует html-символы в специсимволы
   *
   * @param body — Декодируемое сообщение
   * @returns {String}
   */
  decodeMsg(body: any): any {
    if (!body) {
      return '';
    }

    const tagsToReplace: { [key: string]: string } = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
    };

    return body.replace(/(&amp;|&lt;|&gt;)/g, (tag: string) => tagsToReplace[tag] || tag);
  }

  protected readonly Array = Array;
}

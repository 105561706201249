<div class="templates d-flex flex-wrap space-between">
  <cq-template-zero (click)="onClickTemplateZero()"></cq-template-zero>

  <cq-template-card
    *ngFor="let template of templates; trackBy: trackByItem"
    [style.order]="template.order"
    [template]="template"
    (click)="onClickTemplate(template)"
  ></cq-template-card>
</div>

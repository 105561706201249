import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ConversationModel } from '@http/conversation/conversation.model';
import { Conversation } from '@http/conversation/conversation.types';
import {
  CONVERSATION_PART_SENT_VIA,
  CONVERSATION_PART_TYPES,
} from '@http/conversation-part/conversation-part.constants';

/** Возможные статусы диалога */
type ConversationStatus = 'read' | 'sent' | 'closed' | 'delayed' | 'returnFromDelayed' | 'notReplied';

@Component({
  selector: 'cq-message-status[conversation]',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageStatusComponent {
  @Input()
  conversation!: Conversation;

  /**
   * Статус диалога. Исходя из него показывается соответствующая иконка
   * Все проверки расположены по приоритету важности иконок
   * Не факт, что всё сделано так, как должно работать. Я сделал всё так, как было в старом теле диалога, не более
   */
  get conversationStatus(): ConversationStatus | undefined {
    if (this.isClosed(this.conversation)) {
      return 'closed';
    }

    if (this.isReturnFromDelayed(this.conversation)) {
      return 'returnFromDelayed';
    }

    if (this.isDelayed(this.conversation)) {
      return 'delayed';
    }

    if (this.isNotReplied(this.conversation)) {
      return 'notReplied';
    }

    if (this.isRepliedAndNotRead(this.conversation)) {
      return 'sent';
    }

    if (this.isRepliedAndRead(this.conversation)) {
      return 'read';
    }

    return;
  }

  constructor(readonly conversationModel: ConversationModel) {}

  /** Диалог закрыт */
  isClosed(conversation: Conversation): boolean {
    if (!conversation.closed) {
      return false;
    }

    return true;
  }

  /** Диалог отложен */
  isDelayed(conversation: Conversation): boolean {
    if (!conversation.delayed_until) {
      return false;
    }

    if (conversation.delayed_until <= conversation.last_update) {
      return false;
    }

    return true;
  }

  /** Отложенный диалог напомнил о себе */
  isReturnFromDelayed(conversation: Conversation): boolean {
    if (!conversation.delayed_until) {
      return false;
    }

    if (conversation.delayed_until > conversation.last_update) {
      return false;
    }

    return true;
  }

  /** Пользователь ждёт ответа */
  isNotReplied(conversation: Conversation): boolean {
    if (
      conversation.reply_last.type !== CONVERSATION_PART_TYPES.REPLY_USER &&
      conversation.reply_last.type !== CONVERSATION_PART_TYPES.AUTO_REPLY &&
      conversation.important_part_last.type !== CONVERSATION_PART_TYPES.CHAT_BOT_ADMIN &&
      conversation.important_part_last.sent_via !== CONVERSATION_PART_SENT_VIA.CHAT_GPT
    ) {
      return false;
    }

    return true;
  }

  /** Пользователю ответили, но ответ он ещё не прочитал */
  isRepliedAndNotRead(conversation: Conversation): boolean {
    if (conversation.reply_last?.type !== CONVERSATION_PART_TYPES.REPLY_ADMIN) {
      return false;
    }

    if (conversation.reply_last?.read) {
      return false;
    }

    return true;
  }

  /** Пользователю ответили и он увидел ответ */
  isRepliedAndRead(conversation: Conversation): boolean {
    if (conversation.reply_last?.type !== CONVERSATION_PART_TYPES.REPLY_ADMIN) {
      return false;
    }

    if (!conversation.reply_last?.read) {
      return false;
    }

    return true;
  }
}

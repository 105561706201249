<ng-container *transloco="let t; read: 'conversationsChannelListComponent'">
  <div
    class="channel-container margin-between-rows-10"
    [ngClass]="{ 'bordered-bottom': isChannelsOverflow$ | async, 'pb-0': (isChannelsOverflow$ | async) === false }"
  >
    <ng-container
      [ngTemplateOutlet]="channelsTemplate"
      [ngTemplateOutletContext]="{ channels: pseudoChannels, showNotAssignedCount: true }"
    >
    </ng-container>
  </div>

  <overlay-scrollbars
    *ngIf="userChannels.length"
    (osUpdated)="onOsUpdate($event)"
    class="os-host-flexbox"
  >
    <div class="channel-container margin-between-rows-10">
      <ng-container
        [ngTemplateOutlet]="channelsTemplate"
        [ngTemplateOutletContext]="{ channels: userChannels, showNotAssignedCount: false }"
      >
      </ng-container>
    </div>
  </overlay-scrollbars>

  <!-- все поповеры должны быть приколочены к низу списка каналов -->
  <ng-template
    #channelsTemplate
    let-channels="channels"
    let-showNotAssignedCount="showNotAssignedCount"
  >
    <div
      *ngFor="let channel of channels; trackBy: trackByChannelId"
      class="channel-inner"
    >
      <div
        *ngIf="channel === activeChannel"
        class="active-channel-indicator position-absolute"
        [@fade]
      ></div>
      <!-- из-за того, что поповер для WhatsApp и Telegram нужно показывать с привязкой к каналу - пришлось положить его сюда -->
      <cq-whats-app-channel-popover-wrapper
        *ngIf="channel === latestWhatsAppChannel"
        [currentApp]="currentApp"
        [djangoUser]="djangoUser"
        placement="right-top right right-bottom"
      ></cq-whats-app-channel-popover-wrapper>
      <cq-telegram-channel-popover-wrapper
        *ngIf="channel === latestTelegramChannel"
        [currentApp]="currentApp"
        [djangoUser]="djangoUser"
        placement="right-top right right-bottom"
      ></cq-telegram-channel-popover-wrapper>
      <cq-channel
        [canActivate]="true"
        [channel]="channel"
        [isActive]="channel === activeChannel"
        [notAssignedCount]="showNotAssignedCount ? notAssignedCounters[channel.id!] : 0"
        [notReadCount]="notReadCounters[channel.id!]"
        (click)="activateChannel(channel)"
      ></cq-channel>
      <cq-channel-mute
        *ngIf="channel.type !== PSEUDO_CHANNEL_TYPES.ALL_CHANNELS"
        [channel]="channel"
        [djangoUserId]="djangoUser.id"
      ></cq-channel-mute>
    </div>
  </ng-template>
</ng-container>

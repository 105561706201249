import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { generate as shortUUID } from 'short-uuid';

import { DUMMY_FUNCTION } from '@panel/app/shared/functions/dummy.function';

/**
 * В тестовом режиме сюда добавляено "Interminate" состояние чекбокса.
 * Сейчас оно работате так, что при его установке, checked, остается таким же.
 * Если тебе понадобится его использовать, и окажется, что решение не удобное - можно поменять поведение.
 */
@Component({
  selector: 'cq-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxControlComponent implements OnInit, ControlValueAccessor {
  /**
   * Задаем кастомный id нативных label и input
   */
  @Input()
  id: string = shortUUID();

  private _checked: boolean = false;

  disabled: boolean = false;

  /**
   * Колбэки, полученные от ControlValueAccessor
   */
  onTouched: Function = DUMMY_FUNCTION;
  onChange: Function = DUMMY_FUNCTION;

  @ViewChild('nativeInput')
  private readonly nativeInput?: ElementRef<HTMLInputElement>;

  constructor(
    @Self()
    @Optional()
    private readonly ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    } else {
      throw new Error('Use [formControl]/formControlName/[(ngModel)] for correct work of app-checkbox-control');
    }
  }

  ngOnInit() {
    if (!this.ngControl || !this.ngControl.valueChanges) {
      return;
    }
    /**
     * Когда мы меняем значение в контроле снаружи Ангуляр не знает об этом. Подсказываем
     */
    this.ngControl.valueChanges.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  set checked(value: boolean) {
    // Перед сменой checked отключаем interminate флаг
    this.indeterminate = false;
    this._checked = value;
  }

  get checked() {
    return this._checked;
  }

  /**
   * Интерминейт - нейтральное состояние чекбокса, google it
   */
  set indeterminate(value: boolean) {
    if (!this.nativeInput) {
      return;
    }

    this.renderer.setProperty(this.nativeInput.nativeElement, 'indeterminate', value);
  }

  get indeterminate(): boolean {
    return this.nativeInput ? this.nativeInput.nativeElement.indeterminate : false;
  }

  registerOnChange(onChange: (value: boolean) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCheckedChange(event: Event) {
    if (!this.ngControl) {
      return;
    }
    this.checked = (event.target as HTMLInputElement).checked;
    this.ngControl.control?.setValue(this.checked);
  }

  get showInvalidFeedback() {
    return this.ngControl.touched && this.ngControl.invalid;
  }

  writeValue(value: boolean): void {
    if (this.disabled) {
      return;
    }
    this.checked = value;
  }
}

<ng-container *transloco="let t; read: 'cancellationPreventModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('title') }}</h3>
    <button
      class="close"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="subscriptionStore.billing$ | async as billing">
      <div class="mb-5 font-weight-bold">
        {{ t('content.title', { date: billing.subscription.currentTermEnd | amDateFormat: 'L' }) }}
      </div>
    </ng-container>
    <div>
      {{ t('content.description') }}
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="openCancellationExactlyModal()"
    >
      {{ t('buttons.cancel') }}
    </button>
    <button
      class="btn btn-primary"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      {{ t('buttons.dontCancel') }}
    </button>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { filter } from 'lodash-es';

import { MESSAGE_STATUSES } from '@http/message/message.constants';
import { SYSTEM_DIRECTORIES } from '@http/message-directory/message-directory.constants';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';

@Component({
  selector: 'cq-mass-action-panel',
  templateUrl: './mass-action-panel.component.html',
  styleUrls: ['./mass-action-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MassActionPanelComponent {
  @Input()
  accessToSelectedAutoMessages: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  @Input()
  accessToSelectedTelegramAutoMessages: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  @Input()
  activeMessagesAmounts: any;

  @Input()
  currentApp: any;

  @Input()
  currentDirectory: any;

  @Input()
  currentStatus: any;

  @Input()
  messagesPaginator: any;

  @Input()
  selectedMessages: any;

  @Input()
  selectedMessagesAll: any;

  @Output()
  clickActivateButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickArchiveButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickChangeDirectoryButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickCopyButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickPauseButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  clickReestablishButton: EventEmitter<any> = new EventEmitter<any>();

  protected SYSTEM_DIRECTORIES: any = SYSTEM_DIRECTORIES;

  constructor(protected readonly paywallService: PaywallService) {}

  /**
   * Проверка статуса выделенных сообщений на идентичность
   *
   * @param {Boolean} active флаг для актывных/приостановленных сообщений
   * @returns {Boolean}
   */
  checkStatusMessagesToDisable(active: any): boolean {
    //если выбранны активные сообщения то действие с активацией должно быть задизеблено и наоборот
    if (
      (this.currentStatus == MESSAGE_STATUSES.ACTIVE && active) ||
      (this.currentStatus == MESSAGE_STATUSES.STOPPED && !active)
    ) {
      return true;
    } else {
      //если выделены все сообщения и загружены не все сообщения, то дизейблить ничего не надо т.к. мы не можем знать какие статусы у незагруженных сообщений
      if (this.selectedMessagesAll && this.messagesPaginator?.paginatePosition) {
        return false;
      }
      //если кол-во выделенных сообщений со статусом "active" соответствует общему количеству выделенных сообщений, то надо задизеблить
      let activeMessagesLength = filter(this.selectedMessages, { active: active }).length;
      return this.selectedMessages.length == activeMessagesLength;
    }
  }

  /**
   * Открыть одно из биллинговых модальных сообщений
   */
  openSomeAutoMessageBillingModal() {
    if (!this.accessToSelectedAutoMessages.hasAccess) {
      this.paywallService.showAutoMessageTotalPaywall(
        this.currentApp,
        this.accessToSelectedAutoMessages.denialReason,
        this.selectedMessages.length,
        this.activeMessagesAmounts,
      );
      return;
    }

    this.paywallService.showPaywallForAccessDenial(
      this.currentApp,
      this.accessToSelectedTelegramAutoMessages.denialReason,
    );
  }
}

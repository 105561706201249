import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CarrotquestHelperProvider, ProductFeatureTextServiceProvider } from '@panel/app/ajs-upgraded-providers';

import { AccessDenialBannerComponent } from './access-denial-banner.component';

@NgModule({
  declarations: [AccessDenialBannerComponent],
  exports: [AccessDenialBannerComponent],
  imports: [CommonModule],
  providers: [CarrotquestHelperProvider, ProductFeatureTextServiceProvider],
})
export class AccessDenialBannerModule {}

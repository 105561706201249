import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { BillingEmailComponent } from './billing-email.component';

@NgModule({
  declarations: [BillingEmailComponent],
  exports: [BillingEmailComponent],
  imports: [CommonModule, TranslocoModule, NgbTooltipModule],
})
export class BillingEmailModule {}

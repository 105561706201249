(function () {
  'use strict';

  require('./settings.controller');

  angular.module('myApp.settings').component('cqSettings', cqSettings());

  function cqSettings() {
    return {
      bindings: {
        activeChatWidgetList: '=', // Список активных чат-виджетов
        billingInfo: '=', // информация о биллинге
        botSender: '=', // Отправитель бота
        currentApp: '=', // текущее приложение
        djangoUser: '=', // текущий пользователь
        knowledgeBase: '=', // Настройки БЗ
        messageSenders: '=', // список отправителей
        privacySettings: '=', // настройки для обработки персональных данных FIXME: их можно не передавать снаружи, а создавать внутри компонента, т.к. по сути это копия currentApp.settings
        pushSettings: '=', // настройки пушей
        routingBot: '=', // ID роутинг бота
        savedReplies: '=', // сохранённые ответы
      },
      controller: 'CqSettingsController',
      controllerAs: 'vm',
      template: require('./settings.html'),
    };
  }
})();

<button
  class="close"
  (click)="ngbActiveModal.dismiss()"
>
  <i class="cqi-sm cqi-03x cqi-times"></i>
</button>

<div class="modal-body padding-50 text-center">
  <img
    class="margin-bottom-40 full-max-width"
    src="assets/img/default/install-script/team.png"
  />
  <h3 class="margin-bottom-30">{{ modalWindowParams.heading }}</h3>
  <button
    class="btn btn-primary full-width"
    (click)="trackClickInstallCode(); redirectToStarterGuide()"
  >
    {{ 'modals.installScript.installScriptButton' | transloco }}
  </button>
</div>

/**
 * Директива, используемая для ограничения максимального размера загружаемого файла в байтах
 */
(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('cqMaxSize', cqMaxSize);

  function cqMaxSize() {
    return {
      restrict: 'A',
      require: ['ngModel', 'cqFile'],
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller[0].$validators.maxSize = maxSize;

      /**
       * Функция для валидации максимального размера файла
       *
       * @param modelValue Значение модели для валидации
       * @returns {Boolean}
       */
      function maxSize(modelValue) {
        // если у значения модели нет поля с размером size - валидация не требуется
        if (controller[0].$isEmpty(modelValue) || controller[0].$isEmpty(modelValue.size)) {
          return true;
        }

        // у файла размер измеряется в байтах, поэтому можно напрямую сравнить
        // FIXME: когда-нибудь получение значения из атрибута cqMaxSize нужно сделать при помощи observe
        return parseFloat(modelValue.size) <= parseFloat(iAttrs.cqMaxSize);
      }
    }
  }
})();

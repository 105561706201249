<div class="container-fluid limited-container">
  <cq-conversations-settings-breadcrumbs></cq-conversations-settings-breadcrumbs>

  <ul
    #nav="ngbNav"
    ngbNav
    [(activeId)]="activeTab"
    class="nav nav-tabs"
  >
    <li [ngbNavItem]="CONVERSATIONS_SETTINGS_TABS.CHANNELS">
      <a ngbNavLink>{{ 'conversationsSettingsComponent.tabs.channels' | transloco }}</a>
      <ng-template ngbNavContent>
        <cq-channels-table
          [billingInfo]="billingInfo"
          [channels]="channels"
          [currentApp]="app"
          [djangoUser]="djangoUser"
        ></cq-channels-table>
      </ng-template>
    </li>

    <li [ngbNavItem]="CONVERSATIONS_SETTINGS_TABS.SAVED_REPLIES">
      <a ngbNavLink>{{ 'conversationsSettingsComponent.tabs.savedReplies' | transloco }}</a>
      <ng-template ngbNavContent>
        <cq-saved-replies
          [currentApp]="app"
          [savedReplies]="savedReplies"
        ></cq-saved-replies>
      </ng-template>
    </li>

    <ng-container *ngIf="accessToConversationsAutoAssignment.hasAccess">
      <li [ngbNavItem]="CONVERSATIONS_SETTINGS_TABS.AUTO_ASSIGNMENT">
        <a ngbNavLink
          >{{ 'conversationsSettingsComponent.tabs.autoAssignment' | transloco }}
          <cq-beta-label
            class="badge badge-light-success ml-10"
            [featureLabel]="FEATURE_LABELS_ONLY.CONVERSATIONS_AUTO_ASSIGNMENT"
          ></cq-beta-label>
        </a>
        <ng-template ngbNavContent>
          <cq-auto-assignment
            [currentApp]="app"
            [djangoUser]="djangoUser"
            [djangoUserTempData]="djangoUserTempData"
            [teamMembers]="teamMembers"
          ></cq-auto-assignment>
        </ng-template>
      </li>
    </ng-container>
    <ng-container *ngIf="!accessToConversationsAutoAssignment.hasAccess">
      <cq-access-denial-popover
        [currentApp]="app"
        [denialReason]="accessToConversationsAutoAssignment.denialReason"
        [popoverPlacement]="'bottom'"
      >
        <li
          [ngbNavItem]="CONVERSATIONS_SETTINGS_TABS.AUTO_ASSIGNMENT"
          [disabled]="true"
        >
          <a ngbNavLink>
            <cq-plan-capability-coin class="mr-5"></cq-plan-capability-coin
            >{{ 'conversationsSettingsComponent.tabs.autoAssignment' | transloco }}
            <cq-beta-label
              class="badge badge-light-success ml-10"
              [featureLabel]="FEATURE_LABELS_ONLY.CONVERSATIONS_AUTO_ASSIGNMENT"
            ></cq-beta-label>
          </a>
        </li>
      </cq-access-denial-popover>
    </ng-container>

    <li [ngbNavItem]="CONVERSATIONS_SETTINGS_TABS.MANAGE_TAGS">
      <a ngbNavLink>{{ 'conversationsSettingsComponent.tabs.tags' | transloco }}</a>
      <ng-template ngbNavContent>
        <cq-manage-tags></cq-manage-tags>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</div>

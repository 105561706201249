<ng-container *transloco="let t; read: 'workingTimeReplyControlComponent'">
  <div
    class="form-control-container"
    [cqShowError]="control"
  >
    <label for="workingTimeReply">{{ t('label') }}</label>
    <textarea
      class="form-control no-resize"
      [formControl]="control"
      [placeholder]="t('textarea.placeholder')"
      id="workingTimeReply"
      rows="4"
    ></textarea>

    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('textarea.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

/**
 * Валидация, проверяющая есть ли выбранный элемент в списке
 *
 * @param array cqHasItem - Список элементов в котором надо проверить удаленный элемент
 */
(function () {
  'use strict';
  //!!!!ИСПОЛЬЗОВАТЬ ВАЛИДАТОР НАДО С КРАЙНЕЙ ОСТОРОЖНОСТЬЮ И ОЧЕНЬ ОСОЗНАННО, А ЛУЧШЕ ВООБЩЕ НЕ ИСПОЛЬЗОВАТЬ
  // ПРИ БОЛЬШОМ МАССИВЕ С БОЛЬШОИМ КОЛИЧЕСТВОМ СВОЙСТВ В ОБЬЕКТЕ МОЖЕТ ЧИЛЬНО ЛАГАТЬ
  // ИЗ-ЗА ИСОЛЬЗОВАТНИЯ $filter с {$: value}
  angular.module('myApp.directives.hasItemValidation').directive('cqHasItem', cqHasItem);

  function cqHasItem($filter) {
    return {
      restrict: 'A',
      require: 'ngModel',
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$validators.hasItem = hasItemValidator;

      /**
       * Функция для валидации на удаленный элемент из селекта
       *
       * @param modelValue Значение модели для валидации
       * @returns {Boolean}
       */
      function hasItemValidator(modelValue) {
        // если значение модели пустое - валидация не требуется
        if (controller.$isEmpty(modelValue)) {
          return true;
        }

        const evaluatedValue = scope.$eval(iAttrs.cqHasItem);

        if (evaluatedValue.constructor === Array) {
          return !!$filter('filter')(evaluatedValue, { $: modelValue }, true).length;
        } else {
          throw Error('cq-has-item attribute must be an Array, ' + evaluatedValue.constructor.name + ' given');
        }
      }
    }
  }
})();

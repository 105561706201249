import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { ArticlesLimitationBannerModule } from '@panel/app/pages/knowledge-base/shared/components/articles-limitation-banner/articles-limitation-banner.module';
import { PermissionDeniedModule } from '@panel/app/pages/permission-denied/permission-denied.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { KnowledgeBaseComponent } from './knowledge-base.component';

@NgModule({
  declarations: [KnowledgeBaseComponent],
  exports: [KnowledgeBaseComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ArticlesLimitationBannerModule,
    PermissionDeniedModule,
    SharedModule,
    UIRouterModule,
  ],
})
export class KnowledgeBaseModule {}

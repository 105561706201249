import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { AiDataBotSettingsModule } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings.module';
import { AiDataSourcePageComponent } from '@panel/app/pages/ai-data-sources/ai-data-source-page.component';
import { AI_DATA_SOURCE_PAGE_ROUTES } from '@panel/app/pages/ai-data-sources/ai-data-source-page.routes';
import { AiDataSourceListModule } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-source-list.module';

@NgModule({
  declarations: [AiDataSourcePageComponent],
  imports: [
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: AI_DATA_SOURCE_PAGE_ROUTES }),
    AiDataSourceListModule,
    AiDataBotSettingsModule,
  ],
})
export class AiDataSourcesModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';
import { firstValueFrom, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { ArticleModel } from '@http/article/article.model';
import { ArticleCategoryModel } from '@http/article-category/article-category.model';
import { CONVERSATION_PART_TYPES } from '@http/conversation-part/conversation-part.constants';
import { ArticlePreviewModalComponent } from '@panel/app/pages/knowledge-base/shared/components/article-preview-modal/article-preview-modal.component';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-knowledge-base-popover',
  templateUrl: './knowledge-base-popover.component.html',
  styleUrls: ['./knowledge-base-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class KnowledgeBasePopoverComponent implements OnInit, OnChanges {
  /** Текущий апп */
  @Input() currentApp!: any;
  /** Callback вызываемый при клике на статью */
  @Output() sendArticle: EventEmitter<any> = new EventEmitter();

  @ViewChild('overlayScrollbars') overlayScrollbarInstance!: OverlayScrollbarsComponent;

  /**
   * Максимальная длинна поисковай фразы
   *
   * @type {Number}
   */
  protected MAX_SEARCH_LENGTH: number = 250;

  /**
   * Минимальная длинна поисковай фразы
   *
   * @type {Number}
   */
  protected MIN_SEARCH_LENGTH: number = 3;

  /**
   * Сохраненная позиция скролла
   *
   * @type {number}
   */
  protected savedScrollPosition: number = 0;

  /** Список статей */
  protected articlesList: any[] = [];
  /** Список статей для текущей категории */
  protected articlesListByCategory: any[] = [];
  /** Фраза поиска по статьям */
  protected articlesSearch: string = '';
  /** Список категорий статей */
  protected categoriesList: any[] = [];
  /** Выбранная категория */
  protected currentCategory: any = null;
  /** Флаг говорящий о том что данные загружаются */
  protected dataIsLoad: boolean = false;
  /** Флаг говорящий о том что поиск включен */
  protected isSearch: boolean = false;
  protected isArticleLoad: boolean = false;
  /** Список найденных статей */
  protected searchedArticlesList: any[] = [];
  /** Фраза поиска по статьям */
  protected searchPattern: string = '';

  searchForm = new FormGroup({
    search: new FormControl('', [
      Validators.minLength(this.MIN_SEARCH_LENGTH),
      Validators.maxLength(this.MAX_SEARCH_LENGTH),
    ]),
  });

  constructor(
    protected readonly destroy$: DestroyService,
    protected readonly translocoService: TranslocoService,
    protected readonly toastr: ToastService,
    protected readonly articleCategoryModel: ArticleCategoryModel,
    protected readonly articleModel: ArticleModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    this.searchForm.valueChanges.subscribe((value) => {
      this.articlesSearch = value.search ?? '';
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges) {
    //NOTE при переключение на поиск и обратно надо скроллить наверх
    if (changes.isSearch && changes.isSearch.currentValue !== changes.isSearch.previousValue) {
      this.overlayScrollbarInstance.osInstance()!.elements().scrollOffsetElement.scrollTo({ top: 0 });
    }
  }

  /**
   * Получение данных (статьи, категории)
   */
  getData(): void {
    this.dataIsLoad = true;

    Promise.all([
      firstValueFrom(this.articleModel.getList(this.currentApp.id, false)),
      firstValueFrom(this.articleCategoryModel.getList(this.currentApp.id, true, false)),
    ])
      .then(([articlesList, categoriesList]: any) => {
        this.articlesList = articlesList;
        this.categoriesList = categoriesList;
      })
      .finally(() => {
        this.dataIsLoad = false;
        this.cdr.detectChanges();
      });
  }

  /**
   * Открытие модалки с превью статьи
   */
  openPreviewModal(articleId: any): void {
    if (this.isArticleLoad) {
      return;
    }
    this.isArticleLoad = true;
    this.articleModel
      .get(this.currentApp.id, articleId, false)
      .pipe(
        takeUntil(this.destroy$),
        catchError((response: any) => {
          if (response.meta.error === 'LookupError') {
            switch (response.meta.model_name) {
              case 'Article':
                this.toastr.danger(
                  this.translocoService.translate('knowledgeBasePopover.toasts.articleDoesNotExistError'),
                );
                break;
            }
          }
          this.isArticleLoad = false;

          return of();
        }),
      )
      .subscribe((article: any) => {
        let modal = this.modalHelperService.open(ArticlePreviewModalComponent, {
          size: 'lg modal-dialog-scrollable',
        });

        modal.componentInstance.modalWindowParams = {
          content: article.content,
          description: article.shortDescription,
          name: article.name,
        };

        modal.result.then(() => {
          this.sendArticle.emit({
            type: CONVERSATION_PART_TYPES.ARTICLE,
            body: article.id,
            bodyJson: {
              article: article.id,
              category: article.category,
              name: article.name,
              short_description: article.shortDescription,
            },
          });

          this.isArticleLoad = false;
        });
      });
  }

  /**
   * Сбросить поиск
   */
  resetSearch(): void {
    this.isSearch = false;
    this.articlesSearch = '';
    this.searchPattern = '';
    this.searchedArticlesList = [];
  }

  /**
   * Поиск статей
   *
   * @param isValid - Валидность формы
   * @param searchPhrase - Фраза для поиска
   */
  search(isValid: any, searchPhrase: any): void {
    if (isValid && searchPhrase !== this.searchPattern) {
      this.articleModel
        .search(this.currentApp.id, searchPhrase, false, false, false)
        .pipe(takeUntil(this.destroy$))
        .subscribe((articles: any) => {
          this.trackClickSearch();
          this.isSearch = true;
          this.searchPattern = searchPhrase;
          this.searchedArticlesList = articles;
          this.cdr.detectChanges();
        });
    }
  }

  /**
   * Сохранение текущей позиции скролла
   */
  saveScrollPosition(): void {
    this.savedScrollPosition = this.overlayScrollbarInstance.osInstance()!.elements().scrollOffsetElement.offsetTop;
  }

  /**
   * Выбор категории
   *
   * @param category - Категория
   */
  selectCategory(category: any): void {
    this.currentCategory = category;

    if (category === null) {
      setTimeout(
        () =>
          this.overlayScrollbarInstance
            .osInstance()!
            .elements()
            .scrollOffsetElement.scrollTo({ top: this.savedScrollPosition }),
        150,
      );
    } else {
      this.updateArticlesByCategory(category.id);
      this.saveScrollPosition();
      this.overlayScrollbarInstance.osInstance()!.elements().scrollOffsetElement.scrollTo({ top: 0 });
    }
  }

  /**
   * Обновление списка статей по выбранной категории
   *
   * @param categoryId - ID категории
   */
  updateArticlesByCategory(categoryId: any): void {
    this.articlesListByCategory = this.articlesList.filter((article: any) => article.category === categoryId);
  }

  /**
   * Трек клика на статье
   *
   * @param articleName - Имя статьи
   */
  trackClickArticle(articleName: any): void {
    this.carrotquestHelper.track('Диалоги - выбор статьи базы знаний', {
      'название статьи': articleName,
    });
  }

  /**
   * Трек клика на категории
   *
   * @param categoryName - Имя категории
   */
  trackClickCategory(categoryName: any): void {
    this.carrotquestHelper.track('Диалоги - выбор категории статей базы знаний', {
      категория: categoryName,
    });
  }

  /**
   * Трек клика на кнопку создания статьи
   */
  trackClickCreateArticle(): void {
    this.carrotquestHelper.track('Диалоги - клик по "Создать статью"');
  }

  /**
   * Трек клика на поле поиска
   */
  trackClickSearchInput(): void {
    this.carrotquestHelper.track('Диалоги - клик по поиску по статьям базы знаний');
  }

  /**
   * Трек поиска
   */
  trackClickSearch(): void {
    this.carrotquestHelper.track('Диалоги - поиск по статьям базы знаний ');
  }

  trackByCategoryId(category: any): any {
    return category.id;
  }

  trackByArticleId(article: any): any {
    return article.id;
  }
}

(function () {
  'use strict';

  require('./conversation-final-close-timeout.controller');

  angular.module('myApp.chatSettings').component('cqConversationFinalCloseTimeout', cqConversationFinalCloseTimeout());

  function cqConversationFinalCloseTimeout() {
    return {
      bindings: {
        timeout: '=', // Время, после которого диалог станет решённым
      },
      controller: 'CqConversationFinalCloseTimeoutController',
      controllerAs: 'vm',
      template: require('./conversation-final-close-interval.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AbstractTemplate } from '@panel/app/shared/types/abstract-template.type';

@Component({
  selector: 'cq-template-list[templates][clickTemplate][clickTemplateZero]',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateListComponent {
  @Input()
  templates!: AbstractTemplate[];

  @Output()
  clickTemplate: EventEmitter<AbstractTemplate> = new EventEmitter<AbstractTemplate>();

  @Output()
  clickTemplateZero: EventEmitter<void> = new EventEmitter<void>();

  onClickTemplate(template: AbstractTemplate): void {
    this.clickTemplate.emit(template);
  }

  onClickTemplateZero(): void {
    this.clickTemplateZero.next();
  }

  trackByItem(index: number, item: AbstractTemplate): string {
    return item.id;
  }
}

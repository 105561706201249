import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { EMAIL_NOTIFICATION_STATUS } from '@http/integration/integrations/email-notification/constants/email-notification-integration.constants';
import { EmailNotificationIntegrationExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { EmailRecipient } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { TeamMember } from '@http/team-member/team-member.types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-email-notification-team-members-select-readonly[emailRecipientList][integrationExternal][teamMembers]',
  templateUrl: './team-members-select-readonly.component.html',
  styleUrls: ['./team-members-select-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationTeamMembersSelectReadonlyComponent implements OnChanges {
  /** Выбранные Email'ы, на которые будет уходить уведомление */
  @Input() emailRecipientList!: EmailRecipient[];
  /** Текущая интеграция во внешнем формате */
  @Input() integrationExternal!: EmailNotificationIntegrationExternal;
  /** Выбранные Email'ы, на которые будет уходить уведомление */
  @Input() teamMembers!: TeamMember[];
  /** Статусы подписки на уведомления у получателей */
  EMAIL_NOTIFICATION_STATUS = EMAIL_NOTIFICATION_STATUS;
  /** Список получателей оповещений для данной интеграции */
  selectedEmailRecipient: EmailRecipient[] = [];
  /**
   * Время исчезновения тултипа об отписанном пользователе.
   * Необходимо для того, чтобы пользователь успел кликнуть на ссылку внутри тултипа
   */
  USER_UNSUBSCRIBED_TOOLTIP_CLOSE_DELAY = 2000;

  constructor(private readonly carrotquestHelper: CarrotquestHelper) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['emailRecipientList'] && !!simpleChanges['emailRecipientList'].currentValue) {
      this.selectedEmailRecipient = this.emailRecipientList?.filter((emailRecipient) =>
        this.integrationExternal.settings.emailRecipients.includes(emailRecipient.email),
      );
    }
  }

  /** Открытие чата */
  openChat(): void {
    this.carrotquestHelper.open();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { AppService } from '@http/app/services/app.service';
import {
  ConversationTagHelpServiceProvider,
  ConversationTagHttpServiceProvider,
} from '@panel/app/ajs-upgraded-providers';
import { CreateTagModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/create-tag/create-tag.module';
import { RemoveTagModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/remove-tag/remove-tag.module';
import { SearchModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/search/search.module';
import { TagCreateModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/tag-create/tag-create.module';
import { TagListModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/tag-list/tag-list.module';
import { TagListEmptyModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/tag-list-empty/tag-list-empty.module';
import { TagListNotFoundModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/tag-list-not-found/tag-list-not-found.module';
import { ManageTagsStore } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.store';

import { ManageTagsComponent } from './manage-tags.component';

@NgModule({
  declarations: [ManageTagsComponent],
  exports: [ManageTagsComponent],
  imports: [
    CommonModule,
    CreateTagModule,
    RemoveTagModule,
    SearchModule,
    TagCreateModule,
    TagListModule,
    TranslocoModule,
    TagListEmptyModule,
    TagListNotFoundModule,
  ],
  providers: [AppService, ConversationTagHelpServiceProvider, ConversationTagHttpServiceProvider, ManageTagsStore],
})
export class ManageTagsModule {}

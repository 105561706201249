import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';

import { MemberInvitation } from '../types/member-invitations.types';

/** Статусы приглашений членов команды */
export enum MEMBER_INVITATION_STATUS {
  /** Срок действия приглашения истёк */
  EXPIRED = 'expired',
  /** Приглашение отправлено */
  SENT = 'sent',
}

/** Массив статусов приглашений членов команды */
export const MEMBER_INVITATION_STATUS_ARRAY = [MEMBER_INVITATION_STATUS.SENT, MEMBER_INVITATION_STATUS.EXPIRED];

/** Дефолтное приглашение в команду */
export const DEFAULT_MEMBER_INVITATION: MemberInvitation = {
  email: '',
  inviteExpired: false,
  notifications: {
    desktop: {
      assignedMe: true,
      assignedNobody: true,
      assignedOtherAdmin: false,
      mentioned: true,
    },
    email: {
      assignedMe: true,
      assignedNobody: true,
      assignedOtherAdmin: false,
      mentioned: true,
    },
    push: {
      assignedMe: true,
      assignedNobody: true,
      assignedOtherAdmin: false,
      mentioned: true,
    },
    stats: false,
  },
  permissions: TEAM_MEMBER_PERMISSIONS.OPERATOR,
  permissionsExport: false,
  permissionsSendBulk: false,
  permissionsConversationUserList: true,
};

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { ApiTokenModel } from '@http/api-token/api-token.model';
import { App } from '@http/app/app.model';
import { DestroyService } from '@panel/app/services';

/**
 * Компонент для работы с Auth Token'ом при настройке интеграции с Albato
 */

@Component({
  selector: 'cq-albato-auth-token[currentApp]',
  templateUrl: './albato-auth-token.component.html',
  styleUrls: ['./albato-auth-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AlbatoAuthTokenComponent {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Callback на генерацию Auth Token'а */
  @Output()
  authTokenGenerate: EventEmitter<string> = new EventEmitter();

  constructor(
    @Inject(ApiTokenModel) private readonly apiTokenModel: ApiTokenModel,
    private readonly destroy$: DestroyService,
  ) {}

  /** Обработчик клика на кнопку создания Auth Token'a */
  onClickButton(): void {
    this.apiTokenModel
      .generate(this.currentApp.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.authTokenGenerate.emit(response.token);
      });
  }
}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.completionRegistration')
    .controller('cqCompletionRegistrationController', cqCompletionRegistrationController);

  function cqCompletionRegistrationController(
    $location,
    $q,
    $state,
    ipCookie,
    API_OBJECT_NAME,
    PRIVACY_POLICY_URL,
    PROJECT_NAME,
    appModel,
    billingInfoModel,
    carrotquestHelper,
    djangoUserModel,
  ) {
    let vm = this;
    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.completionRegistrationForm = null;
      vm.isApiRequestPerformed = false; // Выполняется ли запрос на backend
      vm.isRequiredMode = true; // Режим, в котором поля становятся обязательными для заполнения
      vm.onSubmitCompletionRegistrationForm = onSubmitCompletionRegistrationForm;
      vm.PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.registrationUserData = {
        appId: vm.currentApp.id, // ID приложения пользователя
        appName: '', // Название приложения пользователя
        appOrigin: '', // Origin url сайта пользователя
        appUrl: '', // Url сайта пользователя
        name: '', // Имя пользователя
        phone: '', // Телефон пользователя
      };

      // Установка свойства overflow тегу body для корректного отображения согласно дизайну
      setOverflowBody('auto');
    }

    function destroy() {
      // Возврата свойства overflow в исходное состояние, для корректной работы после ухода из компонента =
      setOverflowBody('inherit');
    }

    /** Завершение регистрации */
    function completionRegistration() {
      vm.isApiRequestPerformed = true;

      const params = {
        origin: vm.registrationUserData.appUrl,
        referral_hash: ipCookie('ref'),
        referral_source: ipCookie('ref_source'),
      };
      firstValueFrom(appModel.saveSettings(vm.currentApp.id, params))
        // Обработка ошибок после неуспешного завершения регистрации
        .catch((error) => completionRegistrationSaveSettingsError(error))
        // Синхронизация App'а
        .then(() => completionRegistrationAppSync())
        // Обновление свойств Django пользователя
        .then(() => completionRegistrationUpdatingDjangoUserProperties())
        // Обновление свойств пользователя
        .then(() => completionRegistrationUpdatingUserProperties())
        // Отправка аналитики в другие источники
        .then(() => completionRegistrationSendingAnalyticsToOtherSources())
        // Перенаправление пользователя на вопросы о сервисе
        .then(() => completionRegistrationRedirectToQuestions())
        // Завершение регистрации
        .finally(() => completionRegistrationFinally());
    }

    /**
     * Функция для принудительной синхронизации
     *
     * NOTE:
     *  Необходимо для backend'а
     */
    function completionRegistrationAppSync() {
      return firstValueFrom(billingInfoModel.customerSync(vm.registrationUserData.appId));
    }

    /** Удаление следов регистрации через Google в процессе завершения регистрации */
    function completionRegistrationFinally() {
      vm.isApiRequestPerformed = false;
    }

    /** Перенаправление пользователя в процессе завершения регистрации */
    function completionRegistrationRedirectToQuestions() {
      $state.go(
        'app',
        {
          appId: vm.registrationUserData.appId,
          fromOnboarding: true,
        },
        {
          location: 'replace',
        },
      );
    }

    /**
     * Неудача в процессе завершения регистрации
     *
     * @param error - Ответ сервера в процессе завершения регистрации
     *
     * @return {Promise}
     */
    function completionRegistrationSaveSettingsError(error) {
      return $q.reject();
    }

    /** Отправка данных о пользователе в другие источники в процессе завершения регистрации */
    function completionRegistrationSendingAnalyticsToOtherSources() {
      if (typeof gaGlobal === 'object') {
        // Отправка данных в GA
        gtag('event', 'submit', {
          category: 'onboarding',
          subject: 'reg.final_form',
          with_google: 'true',
          userId: ipCookie(`${API_OBJECT_NAME}_uid`),
          app_id: vm.registrationUserData.appId,
          site: vm.registrationUserData.appUrl,
        });
      }
    }

    /** Обновление свойства Django пользователя в процессе завершения регистрации */
    function completionRegistrationUpdatingDjangoUserProperties() {
      // При создании пользователя записываем имя главному администратору и администратору приложения
      const prefs = {
        ['name__' + vm.registrationUserData.appId]: vm.registrationUserData.name,
      };

      const params = {
        prefs: JSON.stringify(prefs),
        name: vm.registrationUserData.name,
        telephone: vm.registrationUserData.phone,
      };

      return firstValueFrom(djangoUserModel.save(params));
    }

    /** Обновление свойств пользователя в процессе регистрации */
    function completionRegistrationUpdatingUserProperties() {
      identifyUserProperties({ $name: vm.registrationUserData.name });
      identifyUserProperties({ $phone: vm.registrationUserData.phone });
      identifyUserProperties({ 'Последний созданный app': vm.registrationUserData.appId });

      identifyUserProperties([
        // Данные о созданном App'е
        {
          op: 'delete',
          key: 'apps',
          value: 0,
        },
        {
          op: 'delete',
          key: 'appsUrl',
          value: 0,
        },
        {
          op: 'union',
          key: 'apps',
          value: vm.registrationUserData.appName,
        },
        {
          op: 'union',
          key: 'appsUrl',
          value: vm.registrationUserData.appOrigin,
        },
        {
          op: 'union',
          key: 'appsUrl',
          value: vm.registrationUserData.appUrl,
        },

        // Для AMO
        {
          op: 'update_or_create',
          key: 'Тип заявки',
          value: 'Регистрация',
        },
        {
          op: 'update_or_create',
          key: 'Источник заявки',
          value: $location.host() + $location.path(),
        },
      ]);
    }

    /**
     * Изменение свойств пользователя
     *
     * @param {object|array} properties - Свойства, которые необходимо изменить
     */
    function identifyUserProperties(properties) {
      carrotquestHelper.identify(properties);
    }

    /** Обработчик отправки формы завершения регистрации */
    function onSubmitCompletionRegistrationForm() {
      completionRegistration();
    }

    /**
     * Установка свойства overflow для body
     *
     * @param overflowValue - Свойство, которое необходимо установить
     */
    function setOverflowBody(overflowValue) {
      document.querySelector('body').style.overflow = overflowValue;
    }
  }
})();

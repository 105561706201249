import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  EDIT_EVENT_TYPE_MODAL_DATA_TOKEN,
  EditEventTypeModalData,
} from '@panel/app/partials/modals/edit-event-type/edit-event-type.token';
import { EventType } from '@http/property/property.model';

@Component({
  selector: 'cq-edit-event-type',
  templateUrl: './edit-event-type.component.html',
  styleUrls: ['./edit-event-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditEventTypeComponent {
  scoreFormControl: FormControl<number> = new FormControl<number>(this.data.eventType.score, {
    nonNullable: true,
    validators: [Validators.required],
  });

  constructor(
    public readonly activeModal: NgbActiveModal,
    @Inject(EDIT_EVENT_TYPE_MODAL_DATA_TOKEN)
    public readonly data: EditEventTypeModalData,
  ) {}

  updateEventType() {
    if (this.scoreFormControl.valid) {
      this.activeModal.close({
        ...this.data.eventType,
        score: this.scoreFormControl.value,
      } as EventType);
    }
  }
}

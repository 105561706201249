import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { ParamEditorComponent } from './param-editor.component';

@NgModule({
  declarations: [ParamEditorComponent],
  exports: [ParamEditorComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, SharedModule, NgbTooltipModule],
})
export class ParamEditorModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import orderBy from 'lodash-es/orderBy';
import { takeUntil } from 'rxjs/operators';

import { ApiTokenModel } from '@http/api-token/api-token.model';
import { DestroyService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-to-developers',
  templateUrl: './to-developers.component.html',
  styleUrls: ['./to-developers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ToDevelopersComponent implements OnInit {
  /** API ключи */
  @Input() apiCredentials!: any;
  /** информация о биллинге */
  @Input() billingInfo!: any;
  /** текущее приложение */
  @Input() currentApp!: any;
  /** текущий пользователь */
  @Input() djangoUser!: any;

  protected accessToDevelopers: any;

  get tokens(): any {
    return orderBy(this.apiCredentials.apiTokens, 'created', 'desc');
  }

  constructor(
    protected readonly apiTokenModel: ApiTokenModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    private readonly cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.accessToDevelopers = this.planFeatureAccessService.getAccess(PLAN_FEATURE.DEVELOPERS, this.currentApp);

    this.trackEnterOnPage();
  }

  /**
   * Генерация новго API токена
   */
  generateApiToken(): void {
    this.apiTokenModel
      .generate(this.currentApp.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((generatedToken: any) => {
        this.apiCredentials.apiTokens.push(generatedToken);
        this.cdr.detectChanges();
      });
  }

  /**
   * Отзыв токена
   *
   * @param apiToken API токен
   */
  revokeApiToken(apiToken: any): void {
    // сразу удаляем токен из массива, чтобы пользователь не ждал окончания запроса
    this.apiCredentials.apiTokens.splice(this.apiCredentials.apiTokens.indexOf(apiToken), 1);
    this.apiTokenModel.revoke(apiToken.id).pipe(takeUntil(this.destroy$)).subscribe();
  }

  /**
   * Трек захода на страницу
   */
  trackEnterOnPage(): void {
    this.carrotquestHelper.track('Настройки разработчикам - зашел');
  }

  trackByApiTokenId(item: any): any {
    return item.id;
  }
}

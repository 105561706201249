import { Inject, Injectable } from '@angular/core';

import {
  BOT_CONNECTIONS_COLLECTION,
  BRANCH_VIEW_MAP,
  BranchViewMap,
  CHAT_BOT_TYPE_TOKEN,
} from '@panel/app/pages/chat-bot/content/tokens';
import { BaseActionABS } from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { BaseBadge, InterruptBadge, StartBadge } from '@panel/app/pages/chat-bot/content/views/badges';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';

import { Branch } from '../views/blocks/base-block/branch';
import { Connection, ConnectionSource, ConnectionTarget } from '../views/connection';
import { BotScenariosHelper } from './bot-scenarios.helper';

@Injectable()
export class ConnectionValidatorService {
  constructor(
    @Inject(BRANCH_VIEW_MAP)
    private readonly branchesMap: BranchViewMap,
    private readonly botScenariosHelper: BotScenariosHelper,
    @Inject(BOT_CONNECTIONS_COLLECTION)
    private readonly connections: Connection[],
    @Inject(CHAT_BOT_TYPE_TOKEN)
    private readonly chatBotType: CHAT_BOT_TYPE,
  ) {}

  elementsAreConnectable(source: ConnectionSource, target: ConnectionTarget): boolean {
    // В данный момент нет возможности соединить с чем-то другим, так что такая проверка справедлива
    if (!(target instanceof Branch)) {
      return false;
    }

    if (!this.isValidToBeFirstBlockOfTriggerScenarioForWelcomeBot(source, target)) {
      return false;
    }

    if (this.isTargetFirstConditionInWelcomeBlockAndTargetIsAction(source, target)) {
      return false;
    }

    // Тот же блок
    if (target.actions.map((a) => a.uid).includes(source.uid)) {
      return false;
    }
    // Оба блока - условия
    if (source.blockType === 'condition' && target.blockType === 'condition') {
      return false;
    }

    // Сценарии пересекаются
    if (this.areElementsFromDifferentScenarios(source, target)) {
      return false;
    }

    // Блок содержит запрещенные действия
    if (this.validateTargetToBePartOfSourceScenario(source, target)) {
      return false;
    }

    return true;
  }

  /**
   * Проверка на пересечение сценариев
   * @param source - Элемент от которого начинается стрелка
   * @param target - Элемент к которому идет стрелка
   * @private
   */
  public areElementsFromDifferentScenarios(source: ConnectionSource, target: ConnectionTarget): boolean {
    if (!(target instanceof Branch)) {
      return false;
    }

    const targetBlockIsPartOfDefaultScenario = this.botScenariosHelper.blockIsPartOfDefaultScenario(target);

    const targetBlockIsPartOfInterruptScenario = this.botScenariosHelper.blockIsPartOfInterruptScenario(target);

    // Связь от стартового бейджа к сценарию прерывания
    if (source instanceof StartBadge && targetBlockIsPartOfInterruptScenario) {
      return true;
    }

    // Связь от бейджа прерывания к дефолтному сценарию
    if (source instanceof InterruptBadge && targetBlockIsPartOfDefaultScenario) {
      return true;
    }

    // Такая проверка сделана, чтоб вытащить потом sourceBlock
    if (source instanceof BaseActionABS) {
      const sourceBlock = source.currentBranch;
      // Сценарии пересекаются
      if (sourceBlock.isDefaultScenario && targetBlockIsPartOfInterruptScenario) {
        return true;
      }
      // Сценарии пересекаются
      if (sourceBlock.isInterruptScenario && targetBlockIsPartOfDefaultScenario) {
        return true;
      }
    }

    return false;
  }

  public isValidToBeFirstBlockOfTriggerScenarioForWelcomeBot(source: ConnectionSource, target: ConnectionTarget) {
    if (this.chatBotType === CHAT_BOT_TYPE.ROUTING && source instanceof StartBadge) {
      if (!(target instanceof Branch)) {
        throw new Error('Target must be a block');
      }

      let isBlockValidForWelcomeBotStart = true;

      const check = (block: Branch): boolean => {
        // Проверять ничего не нужно, если цепочка уже невалидная
        if (!isBlockValidForWelcomeBotStart) {
          return false;
        }

        switch (block.blockType) {
          case 'branch':
            return false;
          case 'condition':
            return true;
          case 'action':
            isBlockValidForWelcomeBotStart = false;
            return false;
          case 'meeting':
            return false;
        }
      };

      BotScenariosHelper.runFnOnBlocksChain(target, check, this.branchesMap);

      return isBlockValidForWelcomeBotStart;
    }
    return true;
  }

  public isTargetFirstConditionInWelcomeBlockAndTargetIsAction(
    source: ConnectionSource,
    target: ConnectionTarget,
  ): boolean {
    if (!(source instanceof BaseActionABS)) {
      return false;
    }
    return this.isFirstBlockAfterWelcomeBadgeAndItsCondition(source.currentBranch) && target.blockType === 'action';
  }

  public isFirstBlockAfterWelcomeBadgeAndItsCondition(block: Branch): boolean {
    if (this.chatBotType !== CHAT_BOT_TYPE.ROUTING) {
      return false;
    }

    if (block.blockType !== 'condition') {
      return false;
    }

    // Кастую тут тип, потому что это костыль, коннекшены переделывать надо. Плюс торопится релиз, уже некогда чет нормальное делать
    const parentsOfBlock = this.connections
      .filter((conn) => conn.target?.uid === block.uid)
      .map((conn) => conn.source) as Array<Branch | BaseBadge>;

    if (!parentsOfBlock || parentsOfBlock.length === 0) {
      return false;
    }

    return parentsOfBlock.some((parent) => parent instanceof StartBadge);
  }

  /**
   * Проверка на наличие запрещенных действий
   * @param source - Элемент от которого начинается стрелка
   * @param target - Элемент к которому идет стрелка
   *
   * validateTargetToBePartOfSourceScenario
   */
  public validateTargetToBePartOfSourceScenario(source: ConnectionSource, target: ConnectionTarget): string | null {
    if (!(target instanceof Branch)) {
      return null;
    }

    const validationError = this.botScenariosHelper.deepValidateBlockForInterruptScenario(target);

    if (source instanceof InterruptBadge && validationError) {
      return validationError;
    }

    if (source instanceof BaseActionABS) {
      const sourceBlock = source.currentBranch;

      // Сценарий прерывания пытается соединиться с блоком, у которого есть запрещенные для сценария действия
      if (sourceBlock.isInterruptScenario && validationError) {
        return validationError;
      }
    }

    return null;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { TriggerChainCanvasActionsComponent } from '@panel/app/pages/trigger-chains/editor/components/canvas/components/actions/trigger-chain-canvas-actions.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [TriggerChainCanvasActionsComponent],
  exports: [TriggerChainCanvasActionsComponent],
  imports: [CommonModule, TranslocoModule, NgbDropdownModule, NgbPopoverModule, NgbTooltipModule, SharedModule],
})
export class TriggerChainCanvasActionsModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TagCreateComponent } from './tag-create.component';

@NgModule({
  declarations: [TagCreateComponent],
  exports: [TagCreateComponent],
  imports: [CommonModule, TranslocoModule],
})
export class TagCreateModule {}

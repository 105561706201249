/**
 * Фильтр, переводящий названия системных свойств пользователей
 * FIXME: удалить этот фильтр, когда все свойства будут обрабатываться в одном месте в модели
 */
(function () {
  'use strict';

  angular.module('myApp.filters.prettyUserPropName').filter('prettyUserPropName', prettyUserPropName);

  function prettyUserPropName(propertyModel) {
    return prettyUserPropNameFilter;

    /**
     * Получение названия свойства пользователя
     *
     * @param {String} userSystemPropertyName Название свойства пользователя
     * @returns {String}
     */
    function prettyUserPropNameFilter(userSystemPropertyName) {
      return propertyModel.parseUserPropertyName(userSystemPropertyName);
    }
  }
})();

import { Injectable } from '@angular/core';
import { some } from 'lodash-es';

import { PLAN_FEATURE, SPECIAL_PLAN_FEATURES } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { SpecialProductFeatures } from '@panel/app/services/billing/plan-feature/plan-feature.types';

/**
 * Сервис для работы с фичами тарифных планов
 */
@Injectable({ providedIn: 'root' })
export class PlanFeatureService {
  /**
   * Является ли фича специальной
   *
   * @param productFeature - Фича
   * @private
   */
  isSpecialFeature(productFeature: PLAN_FEATURE): productFeature is SpecialProductFeatures {
    return some(SPECIAL_PLAN_FEATURES, (o) => o === productFeature);
  }
}

import { Directive, ElementRef, forwardRef, Input, Renderer2 } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError } from 'rxjs/operators';

import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

@Directive({
  selector: '[cqImageMaxHeight]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => ImageMaxHeightDirective), multi: true }],
})
export class ImageMaxHeightDirective {
  @Input('cqImageMaxHeight') maxHeight!: number;

  constructor(private fileHelper: FileHelperService, private el: ElementRef, private renderer: Renderer2) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const file = control.value;

    if (!file || !(file instanceof File)) {
      return of(null); // если нет файла или значение не является файлом, валидация не требуется
    }

    return fromPromise(this.fileHelper.getImageSize(file)).pipe(
      map((size) => {
        return size.height > this.maxHeight
          ? { maxHeight: { maxHeight: this.maxHeight, actualHeight: size.height } }
          : null;
      }),
      catchError(() => of(null)), // В случае ошибки при получении размеров изображения, валидация не проводится
    );
  }
}

<ng-container *transloco="let t; read: 'descriptionPopoverTemplateComponent'">
  <ng-template #templateRef>
    <!--Прерывание бота включено-->
    <div class="margin-bottom-25">
      <div class="mb-10 d-flex align-items-center">
        <div class="mr-10 bg-success circle-status"></div>
        <div>{{ t('allowUserReplies.heading') }}</div>
      </div>
      <div class="text-secondary">
        <small>
          {{ t('allowUserReplies.description') }}
        </small>
      </div>
    </div>
    <!--Прерывание бота выключено-->
    <div>
      <div class="mb-10 d-flex align-items-center">
        <div class="mr-10 bg-danger circle-status"></div>
        <div>{{ t('disallowUserReplies.heading') }}</div>
      </div>
      <div class="text-secondary">
        <small>
          {{ t('disallowUserReplies.description') }}
        </small>
      </div>
    </div>

    <hr />

    <div class="text-secondary">
      <small>
        {{ t('description') }}
      </small>
    </div>
  </ng-template>
</ng-container>

import { DenialReasonTexts } from '../../../shared/services/product-feature-text/product-feature-text.types';

(function () {
  'use strict';

  angular
    .module('myApp.planCapability')
    .controller(
      'CqPlanCapabilityAutoMessageTotalPaywallModalController',
      CqPlanCapabilityAutoMessageTotalPaywallModalController,
    );

  function CqPlanCapabilityAutoMessageTotalPaywallModalController(paywallService, productFeatureTextService) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.showPaywall = showPaywall;

      /** Тексты для причины отказа в доступе */
      vm.denialReasonTexts = productFeatureTextService.getDenialReasonTexts(vm.resolve.modalWindowParams.denialReason);
    }

    function showPaywall() {
      paywallService.showPaywall(vm.resolve.modalWindowParams.currentApp, vm.resolve.modalWindowParams.denialReason);
    }
  }
})();

<ng-container *transloco="let t; read: 'stopPhrasesControlComponent'">
  <h4 class="mb-5">{{ t('heading') }}</h4>
  <div class="mb-10 text-secondary">{{ t('description') }}</div>
  <div [cqShowError]="control">
    <ng-select
      [addTag]="true"
      [formControl]="control"
      [clearable]="false"
      [hideSelected]="true"
      [multiple]="true"
      [isOpen]="false"
      [placeholder]="t('select.placeholder')"
      [items]="control.value"
    >
      <ng-template
        let-clear="clear"
        let-item="item"
        ng-label-tmp
      >
        <button
          class="btn btn-sm btn-outline-primary d-flex align-items-center overflow-hidden"
          type="button"
        >
          <span class="text-truncate">{{ item }}</span>
          <span
            class="d-flex margin-left-5"
            (click)="clear(item)"
          >
            <i class="cqi-xs cqi-times"></i>
          </span>
        </button>
      </ng-template>
    </ng-select>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="wordCountExceeded">
        {{ t('select.errors.wordCountExceeded', { maxCount: STOP_PHRASES_MAX_COUNT }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

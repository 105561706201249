import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

export type TelegramOperationForm = {
  type: FormControl<ELASTICSEARCH_OPERATION>;
  value: FormControl<{ value: string }>;
};

@Component({
  selector: 'cq-telegram-operation-select',
  templateUrl: './telegram-operation-select.component.html',
  styleUrls: ['./telegram-operation-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramOperationSelectComponent extends AbsCVAFormGroupBasedComponent<TelegramOperationForm> {
  @Input({ required: true })
  telegramIntegrations: TelegramIntegrationExternal[] = [];

  private readonly fb = inject(FormBuilder);

  TELEGRAM_OPERATIONS = [
    ELASTICSEARCH_OPERATION.LIST_CONTAINS,
    ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS,
    ELASTICSEARCH_OPERATION.LIST_CONTAINS_ANY,
    ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ANY,
    ELASTICSEARCH_OPERATION.LIST_CONTAINS_ALL,
    ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ALL,
  ];

  readonly control = this.fb.group<TelegramOperationForm>({
    type: this.fb.control<ELASTICSEARCH_OPERATION>(ELASTICSEARCH_OPERATION.LIST_CONTAINS, {
      nonNullable: true,
    }),
    value: this.fb.control<{ value: string }>(
      {
        value: '',
      },
      { nonNullable: true },
    ),
  });
}

(function () {
  'use strict';

  angular.module('myApp.conversations', []);

  require('./conversations.routes');
  require('./general/conversations-general.component');
  require('./general/conversation/conversations-conversation.component');
  require('./general/conversation/hotkeys-modal-wrapper/hotkeys.component');
  require('./general/conversation-frame/conversation-frame.component');
  require('./general/conversation-history/conversations-conversation-history.component');
  require('./general/conversation-list/conversations-conversation-list.component');
  require('./single/conversations-single.component');
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TeamMemberAvatarComponent } from './team-member-avatar.component';

@NgModule({
  declarations: [TeamMemberAvatarComponent],
  imports: [CommonModule],
  exports: [TeamMemberAvatarComponent],
})
export class TeamMemberAvatarModule {}

/**
 * Директива для парсинга свойств вставленных через jinja в редактируемых элементах
 */
import { generate } from 'short-uuid';

(function () {
  'use strict';
  angular.module('myApp.directives.parseProps').directive('cqParseProps', function ($sce, $window, $document) {
    return {
      link: link,
      restrict: 'A',
      require: 'ngModel',
      transclude: true,
      scope: {
        /**
         * @param {Object} parsePropsOption
         * @param {boolean} parsePropsOption.clearValueFromHtml
         * @param {Function} parsePropsOption.onBadgeClick
         * @param {Object} parsePropsOption.userPropList
         * @param {string} parsePropsOption.placeholder.text
         * @param {string} parsePropsOption.placeholder.color
         * */
        parsePropsOption: '=cqParseProps',
        parseOuterChange: '=parseOuterChange',
        ngModel: '=',
      },
    };

    function link(scope, elem, attrs, ngModel) {
      const classNameList = elem[0].className.replace(/ng-.*?(?=\s|$)/g, '').trim();
      const wrapperDiv = angular.element(`<div class="${classNameList} white-space-nowrap overflow-hidden"></div>`);
      /** Создаем редактируемый элемент div */
      const div = angular.element(
        `<div style="position:relative;z-index:2;outline:none;" class="white-space-nowrap overflow-hidden" contenteditable="true"></div>`,
      );

      const elemStyle = window.getComputedStyle(elem[0]);
      const placeholderStyle = `position:absolute;top:0;left:0;z-index:1;padding:${elemStyle.padding};color:${scope.parsePropsOption.placeholder.color}`;
      /** Создаем элемент для placeholder'а */
      const placeholder = angular.element(
        `<span style="${placeholderStyle}">${scope.parsePropsOption.placeholder.text}</span>`,
      );

      wrapperDiv.append(div, placeholder);
      elem.before(wrapperDiv);
      elem.addClass('d-none');

      scope.$watchCollection('[parseOuterChange, parsePropsOption.userPropList]', function (newValue) {
        const [subject, userPropList] = newValue;
        if (userPropList.length <= 0) {
          div[0].innerHTML = '';
          return;
        } else {
          div[0].innerHTML = subject;
        }

        handleDivValue();
      });

      div.on('input ', handleDivValue);
      div.on('click', openPropsEditorModal);
      div.on('paste', breakPaste);

      // Очищаем обработчики, когда элемент удаляется
      scope.$on('$destroy', function () {
        div.off('input', handleDivValue);
        div.off('click', openPropsEditorModal);
        div.off('paste', breakPaste);
      });

      //Лишний раз не обновляем инпут
      // ngModel.$overrideModelOptions({
      //   debounce: 300,
      // });

      function breakPaste(e) {
        e.preventDefault();
        const processedText = e.originalEvent.clipboardData.getData('text');
        const range = document.getSelection().getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(processedText));
        handleDivValue();
      }

      /** Открыть модалку с выбором свойства */
      function openPropsEditorModal(e) {
        const badge = e.target.closest('[data-parsed-badge]');
        if (!badge) {
          return;
        }

        e.preventDefault();
        scope.parsePropsOption
          .onBadgeClick(badge.dataset.propName, badge.dataset.defaultValue)
          .then((data) => {
            const divHtml = div[0].innerHTML;
            const oldBadgeHtml = badge.outerHTML;

            badge.dataset.propName = data.property;
            badge.dataset.defaultValue = data.defaultValue;
            badge.textContent = findPrettyPropName(data.property, scope);

            const newBadgeHtml = badge.outerHTML;

            div[0].innerHTML = divHtml.replace(oldBadgeHtml, newBadgeHtml);
            updateInputValue(div[0].innerHTML);
          })
          .catch(() => {});
      }

      function updateInputValue(val) {
        // HACK Багфикс для Firefox. Предположительно, из-за наличия атрибута contenteditable этот браузер вставляет в конце <br>.
        //  Из-за этого в модель уходит значение вместе с этим тегом.
        val = val.replace(/<br>$/g, '');

        const parsedTextForInput = parsePropsForData(val);

        ngModel.$setViewValue(parsedTextForInput);
      }

      function handleDivValue() {
        let divHtml = div[0].innerHTML.replace(/&nbsp;/g, ' ');

        checkEmpty(divHtml);

        if (!isNeedToParse(divHtml)) {
          updateInputValue(divHtml);
          return;
        }

        divHtml = parsePropsForView.call(scope, null, divHtml);
        div.html(divHtml);

        updateInputValue(divHtml);
      }

      function checkEmpty(val) {
        if (val.length === 0) {
          placeholder.removeClass('d-none');
        } else {
          placeholder.addClass('d-none');
        }
      }
    }

    /** Нужно ли парсить выражение */
    function isNeedToParse(val) {
      return /{{\s*user\['(.*?)'\]\s*\|\s*default\('(.*?)'\)\s*}}/gi.test(val);
    }

    /** Очищаем поле ввода от ненужных тегов */
    function clearDiv(val) {
      return val.replace(/<(span|div|font).*?>|<\/(div|font|span)>/gi, '');
    }

    /** Поиск красивого имени свойства */
    function findPrettyPropName(propName, scope) {
      const prop = scope.parsePropsOption.userPropList.find((userProp) => userProp.name === propName);
      return prop?.prettyName || '';
    }

    /** Парсим вставку свойств через джинджу */
    function parsePropsForView(idx, text) {
      const uid = generate();
      const span = (match, propName, defaultPropValue) => {
        const prettyName = findPrettyPropName(propName, this);
        return $sce.trustAsHtml(
          `<a data-parsed-badge="${uid}" data-prop-name="${propName}" data-default-value="${defaultPropValue}" class="badge badge-light-secondary cursor-pointer" contenteditable="false">${prettyName}</a>`,
        );
      };

      const isNeedClear =
        this.parsePropsOption.clearValueFromHtml === undefined ? false : this.parsePropsOption.clearValueFromHtml;
      const clearedDiv = isNeedClear ? clearDiv(text) : text;

      return clearedDiv.replace(/{{\s*user\['(.*?)'\]\s*\|\s*default\('(.*?)'\)\s*}}/gi, span);
    }

    /** Парсим span в jinja выражение */
    function parsePropsForData(text) {
      const jinja = (match, propName, defaultValue) => `{{user['${propName}']|default('${defaultValue}')}}`;
      return text.replace(/<a.*?data-prop-name="(.*?)".*?data-default-value="(.*?)".*?>(.*?)<\/a>/gi, jinja);
    }
  });
})();

import { translate } from '@jsverse/transloco';
import { Graphics, IPointData, TextStyle } from 'pixi.js';
import { merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { generate } from 'short-uuid';

import { OPTIONS } from '@panel/app/pages/chat-bot/content/branch-editor/actions/property-field/bot-custom-placeholder/bot-custom-placeholder.component';
import { BlockType } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { IPoint } from '@panel/app/pages/chat-bot/content/views/connection';
import {
  BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR,
  BOT_GREY,
  BOT_LIGHTEST_GREY,
  BOT_WHITE_COLOR,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { PixiGeometryService } from '@panel/app/shared/services/pixi/geometry/pixi-geometry.service';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';
import { BotPropertyFieldActionBodyJson } from 'app/http/chat-bot/actions';

import { ActionStyle, BaseActionABS, ConnectionSourceActionABS, MAX_ACTION_WIDTH } from '../abstract';
import { getIcon } from './property-field-action.texture';

const indentBetween = 10;

export class BotPropertyFieldAction extends ConnectionSourceActionABS<BotPropertyFieldActionBodyJson> {
  blockType: BlockType = 'branch';

  inputElement: Graphics | null = null;

  propertyElement: Graphics | null = null;

  rerenderConnection$!: Observable<void>;

  protected style!: ActionStyle;

  uid!: string;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);
    this.uid = generate();
    this.rerenderConnection$ = this.externalPositionChange;

    merge(this.placeholderType.valueChanges, this.placeholderValue.valueChanges)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.redraw());

    this.render();
  }

  get apiReadyBodyJson(): BotPropertyFieldActionBodyJson {
    return {
      placeholder: {
        type: this.placeholderType.value,
        value: this.placeholderValue.value,
      },
    };
  }

  get placeholderType() {
    return this.form.controls.bodyJson.controls.placeholder.controls.type;
  }

  get placeholderValue() {
    return this.form.controls.bodyJson.controls.placeholder.controls.value;
  }

  getConnectionPointCoords(element: Graphics | null = this.inputElement): IPointData {
    if (!element) {
      return { x: 0, y: 0 };
    }
    return {
      x: element.width + 10 - this.connectionPoint.width / 2,
      y: this.container.height - element.height / 2 - this.connectionPoint.height / 2,
    };
  }

  get connectionPointGlobalCoordinates(): IPoint {
    // TODO Вот это обращение к parent.parent мне очень не нравится, хочется абстракции, но пока не удалось сообразить
    const branchPosition = this.container.parent.parent.position;
    return {
      x: branchPosition.x + this.container.x + this.inputElement!.width,
      y: branchPosition.y + this.container.y + this.container.height - this.inputElement!.height / 2,
    };
  }

  destroy() {
    this.connection?.dropConnection();
    super.destroy();
  }

  get height(): number {
    if (this.active.value) {
      return this.inputElement!.height + this.propertyElement!.height + indentBetween;
    }
    return this.inputElement!.height;
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 10,
        horizontal: 15,
      },
      border: {
        size: 1,
        color: 0xe6e6e6,
        radius: 20,
      },
      background: {
        color: BOT_WHITE_COLOR,
      },
    };
  }

  redraw(): void {
    if (this.destroyed) {
      return;
    }
    this.container.removeChildren();
    if (isDefined(this.inputElement)) {
      this.inputElement.removeChildren();
    }
    if (isDefined(this.propertyElement)) {
      this.propertyElement.clear();
      this.propertyElement.removeChildren();
    }
    this.render();
  }

  render(): void {
    this.propertyElement = this.active.value ? this.renderProperty() : null;
    this.inputElement = this.renderInput();

    let inputElementY = 0;
    if (this.propertyElement) {
      this.container.addChild(this.propertyElement);
      inputElementY = this.propertyElement.height + indentBetween;
    }

    this.inputElement.position.set(0, inputElementY);
    this.container.addChild(this.inputElement);

    this.container.addChild(this.connectionPoint);
    const { x: xConnectionPointPosition, y: yConnectionPointPosition } = this.getConnectionPointCoords(
      this.inputElement,
    );
    const scale = this.getConnectionPointScale();
    this.connectionPoint.setTransform(xConnectionPointPosition, yConnectionPointPosition, scale, scale);
  }

  private renderInput(): Graphics {
    const text = renderCanvasText(this.textPlaceholder, {
      fill: 0x999999,
      wordWrap: false,
      padding: 0,
    });
    const icon = PixiIconRenderService.renderRounded('\ue904', BOT_LIGHTEST_GREY, { fill: BOT_GREY });
    const input = PixiGeometryService.renderRectangle(MAX_ACTION_WIDTH, 40, {
      border: this.style.border,
      backgroundColor: this.style.background.color,
    });

    input.addChild(text);
    input.addChild(icon);

    const yTextPosition = (input.height - text.height) / 2;
    text.position.set(this.style.padding.horizontal, yTextPosition);
    const iconXMargin = 10;
    const xIconPosition = input.width - icon.width - iconXMargin;
    const yIconPosition = (input.height - icon.height) / 2;
    icon.position.set(xIconPosition, yIconPosition);

    return input;
  }

  private renderProperty(): Graphics {
    const element = this.propertyElement ?? new Graphics();
    const translation = translate('classes.action.canvasText.targetActionProperty', {
      property: this.form.prettyKeyName,
    });

    const indentBetween = 10;
    const icon = getIcon(this.pixiApp.renderer);
    const fontSize = 14;
    const textOptions: Partial<TextStyle> = {
      wordWrapWidth: MAX_ACTION_WIDTH - icon.width - indentBetween,
      fontSize,
      lineHeight: fontSize * 1.4,
      fill: BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR,
      align: 'left',
    };
    const text = renderCanvasText(translation, textOptions);
    element.addChild(icon, text);
    const contentHeight = Math.max(text.height, icon.height);
    icon.position.set(0, icon.height > contentHeight ? 0 : (contentHeight - icon.height) / 2);
    text.position.set(icon.width + indentBetween, text.height > contentHeight ? 0 : (contentHeight - text.height) / 2);

    element.name = 'property';
    return element;
  }

  get renderReady(): boolean {
    return isDefined(this.inputElement) && this.container.renderable;
  }

  get textPlaceholder(): string {
    let text = translate(`classes.action.canvasText.propertyField.default`);

    if (this.active.value && [OPTIONS.EMAIL, OPTIONS.NAME, OPTIONS.PHONE].includes(this.placeholderType.value)) {
      text = translate(`classes.action.canvasText.propertyField.${this.placeholderType.value}`);
    }

    if (this.active.value && this.placeholderType.value === OPTIONS.CUSTOM) {
      text = this.placeholderValue.value;
    }

    return text;
  }

  get width(): number {
    return this.inputElement!.width;
  }
}

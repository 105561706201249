import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { App } from '@http/app/app.model';

/**
 * Компонент для настройки интеграции с Albato
 */

@Component({
  selector: 'cq-albato-settings[currentApp]',
  templateUrl: './albato-settings.component.html',
  styleUrls: ['./albato-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlbatoSettingsComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Текущий язык */
  activeLang!: string;

  /** Auth Token приложения */
  authToken: string = '';

  /** Текущее имя проекта */
  projectName = environment.projectName;

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.ALBATO);
  }

  constructor(
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.activeLang = this.translocoService.getActiveLang();
  }

  /** Callback на генерацию Auth Token'а */
  onAuthTokenGenerate(authToken: string): void {
    this.authToken = authToken;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';

import { BotSingleBlockOverlayModule } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-single-block-overlay/bot-single-block-overlay.module';

import { BotBlocksOverlayComponent } from './bot-blocks-overlay.component';

@NgModule({
  declarations: [BotBlocksOverlayComponent],
  exports: [BotBlocksOverlayComponent],
  imports: [CommonModule, BotSingleBlockOverlayModule, TuiLetModule],
})
export class BotBlocksOverlayModule {}

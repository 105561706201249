(function () {
  'use strict';

  angular.module('myApp.integrations').controller('Bitrix1cController', Bitrix1cController);

  function Bitrix1cController($translate, PROJECT_NAME, carrotquestHelper, integration) {
    var vm = this;

    vm.$translate = $translate;
    vm.integration = integration;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.trackClickInstallModule = trackClickInstallModule;

    /**
     * Трек клика на 'Установить модуль'
     */
    function trackClickInstallModule() {
      carrotquestHelper.track('Интеграции - ' + integration.type + ' - клик на "Установить модуль"');
    }
  }
})();

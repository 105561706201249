import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import angular from 'angular';
import { filter, find, flattenDeep, isString, truncate } from 'lodash-es';
import moment from 'moment';
import { firstValueFrom, map, Observable } from 'rxjs';
import { Partial } from 'split.js';

import { environment } from '@environment';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { SENDING_TYPES } from '@http/message/message.constants';
import {
  MessageAttachment,
  MessageAttachmentTemporary,
  MessagePart,
  MessagePartExternal,
  MessageTelegramContent,
  MessageWebhookBodyJson,
  MessageWebhookParam,
} from '@http/message-part/message-part.types';
import { MessageSenderModel } from '@http/message-sender/message-sender.model';
import { MessageStatisticsModel } from '@http/message-statistics/message-statistics.model';
import { MessageUtilsModel } from '@http/message-utils/message-utils.model';
import { Properties, PropertyModel } from '@http/property/property.model';
import { TeamMemberModel } from '@http/team-member/team-member.model';
import { CaseStyleHelper } from '@panel/app/services';
import { POPUP_BLOCK_TYPES } from '@panel/app/services/popup-block/popup-block.constants';
import { PopupBlockModel } from '@panel/app/services/popup-block/popup-block.model';
import { UtmMarkModel } from '@panel/app/services/utm-mark/utm-mark.model';
import { EXTENDED_RESPONSE } from '@panel/app/shared/constants/http.constants';

import {
  BEE_EDITOR_BUGFIX_REGEXP,
  CONTROL_GROUP_COLOR_CLASS,
  EMAIL_TYPES,
  MESSAGE_PART_COLOR_CLASSES,
  MESSAGE_PART_SENDER_TYPES,
  MESSAGE_PART_TYPES,
  POPUP_BACKGROUND_POSITION_TYPES,
  POPUP_BACKGROUND_TYPES,
  POPUP_COMPOSITION_TYPES,
  POPUP_COMPOSITIONS,
  POPUP_REPLY_TYPES,
  RECIPIENT_TYPES,
} from './message-part.constants';

@Injectable({
  providedIn: 'root',
})
export class MessagePartModel {
  constructor(
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly eventTypeModel: EventTypeModel,
    private readonly httpClient: HttpClient,
    private readonly messageSenderModel: MessageSenderModel,
    private readonly messageStatisticsModel: MessageStatisticsModel,
    private readonly messageUtilsModel: MessageUtilsModel,
    private readonly popupBlockModel: PopupBlockModel,
    private readonly propertyModel: PropertyModel,
    private readonly teamMemberModel: TeamMemberModel,
    private readonly translateService: TranslocoService,
    private readonly utmMarkModel: UtmMarkModel,
  ) {}

  /**
   * Добавления нового блока типа popupBlockType в конец списка блоков в поп-апе
   *
   * @param popupSettings Настройки поп-апа
   * @param {POPUP_BLOCK_TYPES} popupBlockType Тип блока
   * @param popupBlockParams Дополнительные параметры для блока
   * @param coordinates Координаты вставки нового блока
   * @returns Добавленный блок
   */
  addBlock(popupSettings: any, popupBlockType: any, popupBlockParams: any, coordinates?: number[]) {
    if (!this.canAddBlock(popupSettings, popupBlockType)) {
      return false;
    }

    let newBlock;

    if (popupBlockType == POPUP_BLOCK_TYPES.FOOTER) {
      // футер добавляется в отдельное поле, а не в общий массив блоков
      newBlock = popupSettings.bodyJson.footer =
        popupSettings.bodyJson.footer || this.popupBlockModel.getDefault(popupBlockType, popupBlockParams);
    } else {
      const lastBlockCoordinates = this.findLastBlock(popupSettings.bodyJson.blocks); // координаты последнего блока в поп-апе

      // координаты для вставки нового блока, нули присвоены потому, что если в поп-апе нету блоков - нужно добавить первый блок в начало
      let popupBlocksRowIndex = 0;
      let popupBlocksColIndex = 0;
      let popupBlockIndex = 0;

      // в общем случае новый блок добавляется сразу после последнего блока
      if (angular.isArray(lastBlockCoordinates)) {
        popupBlocksRowIndex = lastBlockCoordinates[0];
        popupBlocksColIndex = lastBlockCoordinates[1];
        popupBlockIndex = lastBlockCoordinates[2] + 1; // новый блок вставляется именно после последнего блока
      }

      if (coordinates && angular.isNumber(coordinates[0]) && coordinates[0] <= popupBlocksRowIndex) {
        popupBlocksRowIndex = coordinates[0];
      }
      if (coordinates && angular.isNumber(coordinates[1]) && coordinates[1] <= popupBlocksColIndex) {
        popupBlocksColIndex = coordinates[1];
      }
      if (coordinates && angular.isNumber(coordinates[2]) && coordinates[2] <= popupBlocksColIndex) {
        popupBlockIndex = coordinates[2];
      }

      newBlock = this.popupBlockModel.getDefault(popupBlockType, popupBlockParams);

      popupSettings.bodyJson.blocks[popupBlocksRowIndex][popupBlocksColIndex].splice(popupBlockIndex, 0, newBlock);
    }

    return newBlock;
  }

  /**
   * Можно ли добавить блок типа popupBlockType в структуру блоков
   *
   * @param popupSettings Настройки поп-апа
   * @param {POPUP_BLOCK_TYPES} popupBlockType Тип блока, для которого проверяется блокировка
   * @returns Если true - значит блок типа popupSettings можно добавить в поп-ап
   */
  canAddBlock(popupSettings: any, popupBlockType: any): boolean {
    /**
     * Блокирует ли блок block вставку другого блока с типом popupBlockType
     *
     * @param {Object} block Блок
     * @returns Заблокирована ли вставка блока с типом popupBlockType блоком block
     */
    const isBlockBlocked = (block: any): boolean => {
      return !!~this.getBlockedByTypes(popupBlockType).indexOf(block.type);
    };

    // если тип блока popupBlockType блокируется какими-либо типами блоков - мы должны проверить, существуют ли блоки с такими типами в поп-апе
    if (this.getBlockedByTypes(popupBlockType).length) {
      const blocksArray = flattenDeep(popupSettings.bodyJson.blocks);

      // если есть футер - добавляем его в массив блоков для проверки
      popupSettings.bodyJson.footer && blocksArray.push(popupSettings.bodyJson.footer);

      if (filter(blocksArray, isBlockBlocked).length) {
        return false;
      }
    }

    return true;
  }

  /**
   * Можно ли переместить блок вниз
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок для перемещения
   * @param currentCoordinates Текущие координаты блока
   */
  canMoveBlockDown(popupBlocks: any, popupBlock: any, currentCoordinates?: number[]): boolean {
    if (popupBlock.type == POPUP_BLOCK_TYPES.FOOTER) {
      return false;
    } else {
      const coordinates = currentCoordinates || this.findBlock(popupBlocks, popupBlock);

      return popupBlocks[coordinates[0]][coordinates[1]].length > coordinates[2] + 1;
    }
  }

  /**
   * Можно ли переместить блок влево
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок для перемещения
   * @param currentCoordinates Текущие координаты блока
   */
  canMoveBlockLeft(popupBlocks: any, popupBlock: any, currentCoordinates?: number[]) {
    if (popupBlock.type == POPUP_BLOCK_TYPES.FOOTER) {
      return false;
    } else {
      const coordinates = currentCoordinates || this.findBlock(popupBlocks, popupBlock);

      return coordinates[1] - 1 >= 0;
    }
  }

  /**
   * Можно ли переместить блок вправо
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок для перемещения
   * @param currentCoordinates Текущие координаты блока
   */
  canMoveBlockRight(popupBlocks: any, popupBlock: any, currentCoordinates?: number[]) {
    if (popupBlock.type == POPUP_BLOCK_TYPES.FOOTER) {
      return false;
    } else {
      const coordinates = currentCoordinates || this.findBlock(popupBlocks, popupBlock);

      return popupBlocks[coordinates[0]].length > coordinates[1] + 1;
    }
  }

  /**
   * Можно ли переместить блок вверх
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок для перемещения
   * @param currentCoordinates Текущие координаты блока
   */
  canMoveBlockUp(popupBlocks: any, popupBlock: any, currentCoordinates?: number[]) {
    if (popupBlock.type == POPUP_BLOCK_TYPES.FOOTER) {
      return false;
    } else {
      const coordinates = currentCoordinates || this.findBlock(popupBlocks, popupBlock);

      return coordinates[2] - 1 >= 0;
    }
  }

  /**
   * Изменение композиции поп-апа
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupSettings Настройки поп-апа
   * @param newCompositionType Новая композиция, к которой нужно преобразовать поп-ап
   */
  changeComposition(popupSettings: any, newCompositionType: POPUP_COMPOSITION_TYPES) {
    const twoColumnsToOneColumn = (popupBlocks: any) => {
      popupBlocks[0][1].reverse();

      for (let i = popupBlocks[0][1].length - 1; i >= 0; i--) {
        const block = popupBlocks[0][1][i];
        this.moveBlockLeft(popupBlocks, block);
      }

      popupBlocks[0].splice(1, 1);
    };

    const oneColumnToTwoColumns = (popupBlocks: any) => {
      popupBlocks[0].push([]);
    };

    const oldCompositionType = popupSettings.bodyJson.params.composition;

    if (
      oldCompositionType == POPUP_COMPOSITION_TYPES.ONE_COLUMN &&
      newCompositionType == POPUP_COMPOSITION_TYPES.TWO_COLUMNS
    ) {
      oneColumnToTwoColumns(popupSettings.bodyJson.blocks);
    } else if (
      oldCompositionType == POPUP_COMPOSITION_TYPES.TWO_COLUMNS &&
      newCompositionType == POPUP_COMPOSITION_TYPES.ONE_COLUMN
    ) {
      twoColumnsToOneColumn(popupSettings.bodyJson.blocks);
    }

    popupSettings.bodyJson.params.composition = newCompositionType;
  }

  /**
   * Заполнение внутреннего представления варианта сообщения данными о варианте с сервера
   *
   * @param internalMessagePart Вариант сообщения
   * @param serverMessagePart Шаблон сообщения
   * @param {Properties=} properties
   */
  convertPartFromServerToInternal(
    internalMessagePart: any,
    serverMessagePart: MessagePartExternal,
    properties?: Properties,
  ) {
    let bodyJson: any;

    // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
    // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
    // HACK Поэтому показываем frontend, что это сообщение SDK-типа.
    if (
      serverMessagePart.recipientType === RECIPIENT_TYPES.SDK &&
      internalMessagePart.type !== MESSAGE_PART_TYPES.SDK_PUSH
    ) {
      internalMessagePart.type = RECIPIENT_TYPES.SDK + '_' + internalMessagePart.type;
      internalMessagePart[internalMessagePart.type].send_sdk_push = serverMessagePart.sendSdkPush;
    }
    const removeHtmlTags = (value: string): string => {
      if (isString(value)) {
        value = value.replace(/<(?:.|\n)*?>/gm, ' '); // удаление тегов
        value = value.replace(/&nbsp;/gm, ' '); // Удаление неразрывных пробелов
        value = value.trim(); // трим
      }

      return value || '...';
    };

    // todo: выпилить это дерьмо отсюда, кастомное имя нужно только для таблицы и статистики ручных рассылок, вместо этого надо генерировать имя на этапе создания ручной рассылки
    if (
      ~[MESSAGE_PART_TYPES.EMAIL, MESSAGE_PART_TYPES.PUSH, MESSAGE_PART_TYPES.SDK_PUSH].indexOf(serverMessagePart.type)
    ) {
      internalMessagePart.customName = truncate(serverMessagePart.subject!, { length: 100 });
    } else if (
      ~[
        MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
        MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
        MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
      ].indexOf(serverMessagePart.type)
    ) {
      internalMessagePart.customName = 'ID ' + internalMessagePart.id;
    } else if (serverMessagePart.type === MESSAGE_PART_TYPES.TELEGRAM) {
      const bodyJson = JSON.parse(serverMessagePart.bodyJson);
      internalMessagePart[internalMessagePart.type].bodyJson = bodyJson;

      let firstTextContent = null;

      for (let i = 0; i < bodyJson.contents.length; i++) {
        let content = bodyJson.contents[i];
        if (content.type === 'text') {
          firstTextContent = content;
          break;
        }
      }

      if (firstTextContent) {
        internalMessagePart.customName = removeHtmlTags(firstTextContent.value);
        internalMessagePart.customName = truncate(internalMessagePart.customName, { length: 100 });
      } else {
        internalMessagePart.customName = this.translateService.translate('models.messagePart.customName.telegram');
      }
    } else {
      internalMessagePart.customName = removeHtmlTags(serverMessagePart.body);
      internalMessagePart.customName = truncate(internalMessagePart.customName, { length: 100 });
    }

    internalMessagePart[internalMessagePart.type].replyType = serverMessagePart.replyType;

    // для блочных и обычных поп-апов нужно заполнять lastSelectedBlockPopupType, чтобы в редакторе был выбран правильный тип поп-апа
    if (~[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].indexOf(serverMessagePart.type)) {
      internalMessagePart.lastSelectedBlockPopupType = serverMessagePart.type;
    }

    if (~[MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL].indexOf(serverMessagePart.type)) {
      internalMessagePart.lastSelectedPopupType = serverMessagePart.type;
    }

    // заполнение body и других параметров
    if (~[MESSAGE_PART_TYPES.JS, MESSAGE_PART_TYPES.SDK_POPUP_CHAT].indexOf(internalMessagePart.type)) {
      internalMessagePart[internalMessagePart.type].body = serverMessagePart.body;
    } else if (internalMessagePart.type === MESSAGE_PART_TYPES.WEBHOOK) {
      internalMessagePart[internalMessagePart.type].body = serverMessagePart.body;

      let parsedBodyJson = null;

      if (serverMessagePart.bodyJson) {
        const bodyJson = JSON.parse(serverMessagePart.bodyJson);
        parsedBodyJson = {
          headers: this.parseWebhookParamsToInternalFormat(bodyJson.headers),
          queryParams: this.parseWebhookParamsToInternalFormat(bodyJson.query_params),
          body: bodyJson.body ? bodyJson.body : '{}',
          method: bodyJson.method,
          eventType: bodyJson.event_type,
          eventProps: this.parseWebhookParamsToInternalFormat(bodyJson.event_props),
        };
      }

      internalMessagePart[internalMessagePart.type].bodyJson = parsedBodyJson;
    } else if (internalMessagePart.type === MESSAGE_PART_TYPES.POPUP_CHAT) {
      internalMessagePart[internalMessagePart.type].body = serverMessagePart.body;

      if (serverMessagePart.msender) {
        this.messageSenderModel.parse(serverMessagePart.msender);
        internalMessagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender = serverMessagePart.msender;
        internalMessagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender.type = MESSAGE_PART_SENDER_TYPES.MESSAGE_SENDER;
      } else if (serverMessagePart.muser) {
        this.teamMemberModel.parse(serverMessagePart.muser);
        internalMessagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender = this.messageSenderModel.convertFromTeamMember(
          serverMessagePart.muser,
        );
        internalMessagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender.type = MESSAGE_PART_SENDER_TYPES.TEAM_MEMBER;
      }
    } else if (~[MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL].indexOf(internalMessagePart.type)) {
      internalMessagePart[internalMessagePart.type].body = serverMessagePart.body;

      if (serverMessagePart.bodyJson) {
        bodyJson = this.caseStyleHelper.keysToCamelCase(JSON.parse(serverMessagePart.bodyJson));

        internalMessagePart[internalMessagePart.type].replyButtonAction = bodyJson.replyButtonAction;
        internalMessagePart[internalMessagePart.type].replyButtonText = bodyJson.replyButtonText;
      }
    } else if (
      ~[
        MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
        MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
        MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
      ].indexOf(internalMessagePart.type)
    ) {
      // NOTE Роман Е. Для поп-ап в SDK есть возможность кастомизировать push-уведомление.
      // NOTE Тема push-уведомления записывается в subject, a текст push-уведомления в body.
      if (internalMessagePart.type === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        internalMessagePart[internalMessagePart.type].subject = serverMessagePart.subject;
      }
      internalMessagePart[internalMessagePart.type].body = serverMessagePart.body;
      if (serverMessagePart.bodyJson) {
        let bodyJson: any = {
          blocks: [],
          footer: undefined,
          params: {},
        };

        let bodyJsonFromServer = JSON.parse(serverMessagePart.bodyJson);

        // парсинг блоков
        let popupBlocks = bodyJsonFromServer.blocks;

        for (let rowIndex = 0; rowIndex < popupBlocks.length; rowIndex++) {
          bodyJson.blocks[rowIndex] = [];

          for (let colIndex = 0; colIndex < popupBlocks[rowIndex].length; colIndex++) {
            bodyJson.blocks[rowIndex][colIndex] = [];

            for (let blockIndex = 0; blockIndex < popupBlocks[rowIndex][colIndex].length; blockIndex++) {
              bodyJson.blocks[rowIndex][colIndex][blockIndex] = this.popupBlockModel.parseToInternalFormat(
                popupBlocks[rowIndex][colIndex][blockIndex],
                properties,
              );
            }
          }
        }

        if (bodyJsonFromServer.footer) {
          bodyJson.footer = this.popupBlockModel.parseToInternalFormat(bodyJsonFromServer.footer);
        }

        // парсинг параметров поп-апа
        bodyJson.params = JSON.parse(JSON.stringify(bodyJsonFromServer.params));

        if (bodyJsonFromServer.params.backgroundImage) {
          bodyJson.params.backgroundImage =
            this.messageUtilsModel.getPathToImages(bodyJsonFromServer.params.backgroundImage) +
            bodyJsonFromServer.params.backgroundImage;
        }

        internalMessagePart[internalMessagePart.type].bodyJson = bodyJson;
      }
    } else if (internalMessagePart.type === MESSAGE_PART_TYPES.EMAIL) {
      internalMessagePart[MESSAGE_PART_TYPES.EMAIL].pureHtml = serverMessagePart.pureHtml;
      internalMessagePart[MESSAGE_PART_TYPES.EMAIL].subject = serverMessagePart.subject;

      if (serverMessagePart.utmMarks) {
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled = true;
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].utmMarks = this.utmMarkModel.parseToInternalFormat(
          serverMessagePart.utmMarks,
        );
      }

      if (!internalMessagePart[MESSAGE_PART_TYPES.EMAIL].pureHtml) {
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].type = EMAIL_TYPES.DEFAULT;
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.DEFAULT].body = serverMessagePart.body;
      } else if (internalMessagePart[MESSAGE_PART_TYPES.EMAIL].pureHtml && !serverMessagePart.bodyJson) {
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].type = EMAIL_TYPES.HTML;
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.HTML].body = serverMessagePart.body;
      } else {
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].type = EMAIL_TYPES.BEE;
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.BEE].body = serverMessagePart.body;
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.BEE].bodyJson = serverMessagePart.bodyJson;
      }

      if (!serverMessagePart.body) {
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].type = EMAIL_TYPES.AI;
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.AI].body = '';
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.AI].bodyJson =
          this.parseAIEmailBodyJsonToInternalFormat(serverMessagePart.bodyJson);
      }

      if (serverMessagePart.msender) {
        this.messageSenderModel.parse(serverMessagePart.msender);
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].sender = serverMessagePart.msender;
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].sender.type = MESSAGE_PART_SENDER_TYPES.MESSAGE_SENDER;
      } else if (serverMessagePart.muser) {
        this.teamMemberModel.parse(serverMessagePart.muser);
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].sender = this.messageSenderModel.convertFromTeamMember(
          serverMessagePart.muser,
        );
        internalMessagePart[MESSAGE_PART_TYPES.EMAIL].sender.type = MESSAGE_PART_SENDER_TYPES.TEAM_MEMBER;
      }
    } else if (internalMessagePart.type === MESSAGE_PART_TYPES.PUSH) {
      if (serverMessagePart.bodyJson) {
        bodyJson = this.caseStyleHelper.keysToCamelCase(JSON.parse(serverMessagePart.bodyJson));
        internalMessagePart[internalMessagePart.type].clickAction = bodyJson.clickAction;
        internalMessagePart[internalMessagePart.type].icon = environment.userFilesUrl + '/push-icons/' + bodyJson.icon;
      }

      internalMessagePart[internalMessagePart.type].body = serverMessagePart.body;
      internalMessagePart[internalMessagePart.type].subject = serverMessagePart.subject;
    } else if (internalMessagePart.type === MESSAGE_PART_TYPES.SDK_PUSH) {
      if (serverMessagePart.bodyJson) {
        bodyJson = this.caseStyleHelper.keysToCamelCase(JSON.parse(serverMessagePart.bodyJson));
        internalMessagePart[internalMessagePart.type].clickAction = bodyJson.clickAction;
      }

      internalMessagePart[internalMessagePart.type].body = serverMessagePart.body;
      internalMessagePart[internalMessagePart.type].subject = serverMessagePart.subject;
    } else if (internalMessagePart.type === MESSAGE_PART_TYPES.TELEGRAM) {
      bodyJson = this.caseStyleHelper.keysToCamelCase(JSON.parse(serverMessagePart.bodyJson));
      bodyJson.integration = bodyJson.integration.id;
      internalMessagePart[internalMessagePart.type].bodyJson = bodyJson;
    }
  }

  /**
   * Парсинг шаблона в вариант сообщения
   *
   * @param template - Шаблон
   * @param messagePart - Вариант сообщения
   *
   * @returns messagePart
   */
  parseFromTemplate(template: any, messagePart: any) {
    messagePart = angular.isUndefined(messagePart) ? this.getDefault() : messagePart;

    let bodyJson: any;
    messagePart.type = template.type;
    messagePart[template.type].replyType = template.replyType;

    // для блочных и обычных поп-апов нужно заполнять lastSelectedBlockPopupType, чтобы в редакторе был выбран правильный тип поп-апа
    if (~[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].indexOf(template.type)) {
      messagePart.lastSelectedBlockPopupType = template.type;
    }

    // NOTE Роман Е. Для "Чат в SDK" и "Поп-ап в SDK" есть возможность отправить push-уведомление вместе с сообщением.
    if (~[MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].indexOf(template.type)) {
      messagePart[template.type].send_sdk_push = template.sendSdkPush;
    }

    if (
      ~[MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].indexOf(
        template.type,
      )
    ) {
      messagePart.lastSelectedPopupType = template.type;
    }

    // заполнение body и других параметров
    if (MESSAGE_PART_TYPES.POPUP_CHAT === template.type) {
      messagePart[template.type].body = template.body;
    } else if (MESSAGE_PART_TYPES.SDK_POPUP_CHAT === template.type) {
      messagePart[template.type].body = template.body;
    } else if (~[MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL].indexOf(template.type)) {
      messagePart[template.type].body = template.body;

      if (template.bodyJson) {
        bodyJson = this.caseStyleHelper.keysToCamelCase(JSON.parse(template.bodyJson));

        messagePart[template.type].replyButtonAction = bodyJson.replyButtonAction;
        messagePart[template.type].replyButtonText = bodyJson.replyButtonText;
      }
    } else if (
      ~[
        MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
        MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
        MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
      ].indexOf(template.type)
    ) {
      // NOTE Роман Е. Для поп-ап в SDK есть возможность кастомизировать push-уведомление.
      // NOTE Тема push-уведомления записывается в subject, a текст push-уведомления в body.
      if (template.type === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        messagePart[template.type].subject = template.subject;
      }

      messagePart[template.type].body = template.body;
      if (template.bodyJson) {
        let bodyJson: any = {
          blocks: [],
          footer: undefined,
          params: {},
        };

        let bodyJsonFromTemplate = JSON.parse(template.bodyJson);

        // парсинг блоков
        let popupBlocks = bodyJsonFromTemplate.blocks;

        for (let rowIndex = 0; rowIndex < popupBlocks.length; rowIndex++) {
          bodyJson.blocks[rowIndex] = [];

          for (let colIndex = 0; colIndex < popupBlocks[rowIndex].length; colIndex++) {
            bodyJson.blocks[rowIndex][colIndex] = [];

            for (let blockIndex = 0; blockIndex < popupBlocks[rowIndex][colIndex].length; blockIndex++) {
              bodyJson.blocks[rowIndex][colIndex][blockIndex] = this.popupBlockModel.parseToInternalFormat(
                popupBlocks[rowIndex][colIndex][blockIndex],
              );
            }
          }
        }

        if (bodyJsonFromTemplate.footer) {
          bodyJson.footer = this.popupBlockModel.parseToInternalFormat(bodyJsonFromTemplate.footer);
        }

        // парсинг параметров поп-апа
        bodyJson.params = JSON.parse(JSON.stringify(bodyJsonFromTemplate.params));

        // парсинг параметров поп-апа
        if (bodyJsonFromTemplate.params.backgroundImage) {
          bodyJson.params.backgroundImage =
            this.messageUtilsModel.getPathToImages(bodyJsonFromTemplate.params.backgroundImage) +
            bodyJsonFromTemplate.params.backgroundImage;
        }

        messagePart[template.type].bodyJson = bodyJson;
      }
    } else if (template.type === MESSAGE_PART_TYPES.EMAIL) {
      messagePart[MESSAGE_PART_TYPES.EMAIL].pureHtml = template.pureHtml;
      messagePart[MESSAGE_PART_TYPES.EMAIL].subject = template.subject;

      if (!messagePart[MESSAGE_PART_TYPES.EMAIL].pureHtml) {
        messagePart[MESSAGE_PART_TYPES.EMAIL].type = EMAIL_TYPES.DEFAULT;
        messagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.DEFAULT].body = template.body;
      } else if (messagePart[MESSAGE_PART_TYPES.EMAIL].pureHtml && !template.bodyJson) {
        messagePart[MESSAGE_PART_TYPES.EMAIL].type = EMAIL_TYPES.HTML;
        messagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.HTML].body = template.body;
      } else {
        /*HACK. Я накосячилл и любой bodyJson парсил в строку. Из-за этого bodyJson из емейла мог парситься по несоклько раз. Поэтому чтобы заработали эти шаблоны надо привести их к первоначальному виду*/
        /*TODO через какое то время ее можно убрать*/
        while (angular.isString(template.bodyJson)) {
          template.bodyJson = JSON.parse(template.bodyJson);
        }
        template.bodyJson = JSON.stringify(template.bodyJson);
        messagePart[MESSAGE_PART_TYPES.EMAIL].type = EMAIL_TYPES.BEE;
        /** Фикс бага редактора BEE https://favro.com/organization/6fcfbd57a2b44fae2a92e7ea/82b511342275b939d5555602?card=Car-28087
         * Суть бага в том, что класс .big не позволял адаптироваться картинке под ширину экрана на mail.ru
         * Этой регуляркой этот класс вырезается из атрибут class в html письма
         * */
        messagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.BEE].body = template.body.replace(
          BEE_EDITOR_BUGFIX_REGEXP,
          '$1',
        );
        messagePart[MESSAGE_PART_TYPES.EMAIL][EMAIL_TYPES.BEE].bodyJson = template.bodyJson;
      }

      if (template.utmMarks) {
        messagePart[MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled = true;
        messagePart[MESSAGE_PART_TYPES.EMAIL].utmMarks = this.utmMarkModel.parseToInternalFormat(template.utmMarks);
      } else {
        messagePart[MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled = false;
        messagePart[MESSAGE_PART_TYPES.EMAIL].utmMarks = this.utmMarkModel.getPredefined();
      }
    } else if (template.type === MESSAGE_PART_TYPES.PUSH) {
      bodyJson = this.caseStyleHelper.keysToCamelCase(JSON.parse(template.bodyJson));

      messagePart[template.type].body = template.body;
      messagePart[template.type].subject = template.subject;
      messagePart[template.type].clickAction = bodyJson.clickAction;
      messagePart[template.type].icon = environment.userFilesUrl + '/push-icons/' + bodyJson.icon;
    } else if (template.type === MESSAGE_PART_TYPES.SDK_PUSH) {
      bodyJson = this.caseStyleHelper.keysToCamelCase(JSON.parse(template.bodyJson));

      messagePart[template.type].body = template.body;
      messagePart[template.type].subject = template.subject;
      messagePart[template.type].clickAction = bodyJson.clickAction;
    } else if (template.type === MESSAGE_PART_TYPES.WEBHOOK) {
      messagePart[template.type].body = template.body;
      let parsedBodyJson = null;

      const bodyJson = JSON.parse(template.bodyJson);
      if (bodyJson) {
        parsedBodyJson = {
          headers: this.parseWebhookParamsToInternalFormat(bodyJson.headers),
          queryParams: this.parseWebhookParamsToInternalFormat(bodyJson.query_params),
          body: bodyJson.body ? bodyJson.body : '{}',
          method: bodyJson.method,
          eventType: bodyJson.event_type,
          eventProps: this.parseWebhookParamsToInternalFormat(bodyJson.event_props),
        };
      }
      messagePart[template.type].bodyJson = parsedBodyJson;
    }

    return messagePart;
  }

  /**
   * Добавление недостающих типов событий и свойств пользователя из варианта сообщения
   *
   * @param messagePart Вариант сообщения
   * @param eventTypes Типы событий
   * @param userProperties Свойства пользователя
   */
  extendEventTypesAndUserProperties(messagePart: any, eventTypes: any[], userProperties: any[]) {
    /**
     * Проверка существования типа события в общем списке по названию, и добавление при отсутствии
     *
     * @param eventTypeName Название типа события
     */
    const extendEventTypes = (eventTypeName: string) => {
      if (eventTypeName && !find(eventTypes, { name: eventTypeName })) {
        eventTypes.push(this.eventTypeModel.getDefault(eventTypeName));
      }
    };

    /**
     * Проверка существования свойства пользователя в общем списке по названию, и добавление при отсутствии
     *
     * @param userPropertyName Название свойства пользователя
     */
    const extendUserProperties = (userPropertyName: string) => {
      if (userPropertyName && !find(userProperties, { name: userPropertyName })) {
        userProperties.push(this.propertyModel.getDefaultUserProperty(userPropertyName));
      }
    };

    /**
     * Обработка блока поп-апа
     *
     * @param popupBlock Блок поп-апа
     */
    const processPopupBlock = (popupBlock: any) => {
      if (popupBlock.type == POPUP_BLOCK_TYPES.BUTTON) {
        extendEventTypes(popupBlock.params.eventType);
      }

      if (popupBlock.type == POPUP_BLOCK_TYPES.FOOTER) {
        extendEventTypes(popupBlock.params.eventType);
        extendUserProperties(popupBlock.params.userProperty);
      }

      if (popupBlock.type == POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON) {
        extendEventTypes(popupBlock.params.eventType);

        for (let i = 0; i < popupBlock.params.inputs.length; i++) {
          extendUserProperties(popupBlock.params.inputs[i].userProperty);
        }
      }

      if (popupBlock.type == POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON) {
        extendEventTypes(popupBlock.params.eventType);
        extendUserProperties(popupBlock.params.userProperty);
      }
    };

    // вытаскиваем типы событий и свойства пользователя из блоков большого поп-апа
    for (
      let rowIndex = 0;
      rowIndex < messagePart[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG].bodyJson.blocks.length;
      rowIndex++
    ) {
      const row = messagePart[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG].bodyJson.blocks[rowIndex];

      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const column = row[colIndex];

        for (let blockIndex = 0; blockIndex < column.length; blockIndex++) {
          const block = column[blockIndex];

          processPopupBlock(block);
        }
      }
    }

    // вытаскиваем типы событий и свойства пользователя из блоков маленького поп-апа
    for (
      let rowIndex = 0;
      rowIndex < messagePart[MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].bodyJson.blocks.length;
      rowIndex++
    ) {
      const row = messagePart[MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].bodyJson.blocks[rowIndex];

      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const column = row[colIndex];

        for (let blockIndex = 0; blockIndex < column.length; blockIndex++) {
          const block = column[blockIndex];

          processPopupBlock(block);
        }
      }
    }

    // вытаскиваем типы событий и свойства пользователя из блоков маленького поп-апа
    for (
      let rowIndex = 0;
      rowIndex < messagePart[MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].bodyJson.blocks.length;
      rowIndex++
    ) {
      const row = messagePart[MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].bodyJson.blocks[rowIndex];

      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        const column = row[colIndex];

        for (let blockIndex = 0; blockIndex < column.length; blockIndex++) {
          const block = column[blockIndex];

          processPopupBlock(block);
        }
      }
    }
  }

  /**
   * Фильтрация вариантов сообщения по типу
   *
   * @param parts Варианты сообщения
   * @param messagePartType Тип варианта сообщения
   */
  filterByMessagePartType(parts: any, messagePartType: MESSAGE_PART_TYPES) {
    return filter(parts, { type: messagePartType });
  }

  /**
   * Фильтрация контрольной группы из массива вариантов сообщения
   *
   * @param parts Варианты сообщения
   * @returns
   */
  filterControlGroup(parts: any[]) {
    return find(parts, { type: MESSAGE_PART_TYPES.CONTROL_GROUP });
  }

  /**
   * Фильтрация вариантов сообщения от контрольной группы из массива вариантов сообщения
   *
   * @param parts Варианты сообщения
   * @returns
   */
  filterMessageParts(parts: any[]) {
    return filter(parts, (part: any) => part.type !== MESSAGE_PART_TYPES.CONTROL_GROUP);
  }

  /**
   * Поиск блока в поп-апе
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок
   * @return Координаты блока в трёхмерном массиве блоков
   */
  findBlock(popupBlocks: any[], popupBlock: any) {
    const coordinates = [];
    let popupBlocksArray;
    let popupBlockIndex = -1;

    findBlock: for (let i = 0; i < popupBlocks.length; i++) {
      for (let j = 0; j < popupBlocks[i].length; j++) {
        popupBlocksArray = popupBlocks[i][j];

        popupBlockIndex = popupBlocksArray.indexOf(popupBlock);

        if (~popupBlockIndex) {
          coordinates[0] = i;
          coordinates[1] = j;
          coordinates[2] = popupBlockIndex;
          break findBlock;
        }
      }
    }

    return coordinates;
  }

  /**
   * Поиск последнего блока в поп-апе
   *
   * @param popupBlocks Блоки поп-апа
   * @return Координаты блока в трёхмерном массиве блоков, либо, если блоков нет, вернётся -1 (по аналогии с indexOf)
   */
  findLastBlock(popupBlocks: any[]) {
    const coordinates = [];

    findBlock: for (let i = popupBlocks.length - 1; i >= 0; i--) {
      for (let j = popupBlocks[i].length - 1; j >= 0; j--) {
        if (popupBlocks[i][j].length) {
          coordinates[0] = i;
          coordinates[1] = j;
          coordinates[2] = popupBlocks[i][j].length - 1;
          break findBlock;
        }
      }
    }

    return coordinates.length ? coordinates : -1;
  }

  /**
   * Генерация имён для вариантов сообщений
   * При получении вариантов сообщения с сервера у них уже есть имена, но в админке их надо выводить так, как надо админке
   *
   * @param parts Варианты сообщения
   */
  generatePartNames(parts: any[]) {
    const controlGroup: any = this.filterControlGroup(parts);
    const messageParts = this.filterMessageParts(parts);

    if (angular.isDefined(controlGroup)) {
      controlGroup.name = this.translateService.translate('models.message.partNames.controlGroup');
    }

    for (let i = 0; i < messageParts.length; i++) {
      const messagePart = messageParts[i];

      // начальные и конечные символы алфавитов разных языков
      const ALPHABET_LETTERS: any = {
        en: ['A', 'Z'],
        ru: ['А', 'Я'],
      };

      function letter(value: any, language: any, fallbackLanguage: any) {
        const parsedValue = parseInt(value);
        let firstLetterCode;
        let lastLetterCode;
        let numberOfLetters;

        if (isFinite(parsedValue)) {
          const alphabet = ALPHABET_LETTERS[language] || ALPHABET_LETTERS[fallbackLanguage];
          firstLetterCode = alphabet[0].charCodeAt(0);
          lastLetterCode = alphabet[1].charCodeAt(0);
          numberOfLetters = lastLetterCode - firstLetterCode + 1;
          let result = '';

          let tempValue = parsedValue;
          while (tempValue >= 0) {
            result = String.fromCharCode((tempValue % numberOfLetters) + firstLetterCode) + result;
            tempValue = Math.floor(tempValue / numberOfLetters) - 1;
          }

          return result;
        } else {
          return value;
        }
      }

      // Использование функции letter с Lodash
      const language = environment.language; // текущий язык
      const fallbackLanguage = 'en'; // запасной язык
      messagePart.name = this.translateService.translate('models.message.partNames.messagePart', {
        messagePartLetter: letter(i, language, fallbackLanguage),
      });
    }
  }

  /**
   * Получение CSS-стилей для каждого типа фона
   *
   * @return {Object}
   */
  getBackgroundPositionParams() {
    const backgroundPositionParams: any = {};

    backgroundPositionParams[POPUP_BACKGROUND_POSITION_TYPES.CONTAIN] = {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    };

    backgroundPositionParams[POPUP_BACKGROUND_POSITION_TYPES.COVER] = {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };

    backgroundPositionParams[POPUP_BACKGROUND_POSITION_TYPES.REPEAT] = {
      // NOTE: по идее, это initial-значения свойств, но они всё равно прописаны на всякий случай, может быть в некоторых браузерах initial-значения другие
      backgroundPosition: 'top left',
      backgroundRepeat: 'repeat',
      backgroundSize: 'auto',
    };

    backgroundPositionParams[POPUP_BACKGROUND_POSITION_TYPES.STRETCH] = {
      backgroundPosition: 'top left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
    };

    return backgroundPositionParams;
  }

  /**
   * Получение позиционирования фона у поп-апа
   * NOTE: Не путать с popupBlockModel.getBackgroundPositionType(), та функция получает тип фона у блока
   *
   * @param popupParams Параметры блока
   */
  getBackgroundPositionType(popupParams: any) {
    const backgroundPositionParams = this.getBackgroundPositionParams();

    let backgroundPositionTypeReturn;

    for (let backgroundPositionType in backgroundPositionParams) {
      if (backgroundPositionParams.hasOwnProperty(backgroundPositionType)) {
        if (
          backgroundPositionParams[backgroundPositionType].backgroundPosition == popupParams.backgroundPosition &&
          backgroundPositionParams[backgroundPositionType].backgroundRepeat == popupParams.backgroundRepeat &&
          backgroundPositionParams[backgroundPositionType].backgroundSize == popupParams.backgroundSize
        ) {
          backgroundPositionTypeReturn = backgroundPositionType;
          break;
        }
      }
    }

    return backgroundPositionTypeReturn;
  }

  /**
   * Получение данных о том, какие типы блоков блокируют блок типа popupBlockType
   *
   * @param {POPUP_BLOCK_TYPES} popupBlockType Тип блока
   */
  getBlockedByTypes(popupBlockType: any) {
    const blockedByTypes: any = {};

    // футер блокируется вот этими типами блоков (т.е. его нельзя вставить в поп-ап, если в поп-апе уже есть блоки с типами, которые перечислены в массиве) и т.п.
    blockedByTypes[POPUP_BLOCK_TYPES.FOOTER] = [
      // @formatter:off
      POPUP_BLOCK_TYPES.FOOTER,
      POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON,
      POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON, // @formatter:on
    ];

    blockedByTypes[POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON] = [
      // @formatter:off
      POPUP_BLOCK_TYPES.FOOTER,
      POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON,
      POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON, // @formatter:on
    ];

    blockedByTypes[POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON] = [
      // @formatter:off
      POPUP_BLOCK_TYPES.FOOTER,
      POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON,
      POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON, // @formatter:on
    ];

    return blockedByTypes[popupBlockType] || [];
  }

  /**
   * Генерация варианта сообщения по умолчанию
   */
  getDefault(bodyJson?: any) {
    // FIXME: тут сделано говно, очень много переменных, используемых не понятно для чего. Их нужно создавать и использовать в компоненте, а не тут, в модели
    const defaultMessagePart: MessagePart = {
      color: MESSAGE_PART_COLOR_CLASSES[0],
      isContentOverflowed: false, // выходит ли DOM за пределы контента (контент - та часть, куда вводятся данные)
      isEditorOverflowed: false, // выходит ли DOM за пределы редактора (редактор включает в себя контент и превью)
      isPreviewOverflowed: false, // выходит ли DOM за пределы превью (превью - та часть, куда выводится внешний вид автосообщения)
      handleError: null, // функция-обработчик ошибок
      lastSelectedBlockPopupType: MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
      lastSelectedPopupType: MESSAGE_PART_TYPES.POPUP_SMALL,
      lastSent: null, // дата последней отправки варианта сообщения
      name: '', // имя варианта
      proportion: 0, // пропорция (напирмер, если вариантов сообщения 3 - значение proportion у варианта А будет 0.33, у варианта Б - 0.66, у варианта C - 1. Если при этом задана пропорция контрольной группы 0.5, то proportion у варианта А будет 0.66, у варианта Б - 0.83, у варианта В - 1)
      showContent: true, // показывается ли контент редактируемого варианта автосообщения (используеся в ui-collapsed)
      showPreview: false, // если true - показывается превью, и наче показывается контент (работает только в том случае, если isEditorOverflowed || isContentOverflowed || isPreviewOverflowed)
      type: MESSAGE_PART_TYPES.POPUP_CHAT,
      [MESSAGE_PART_TYPES.BLOCK_POPUP_BIG]: {
        body: '',
        bodyJson: {
          blocks: this.getPopupComposition(POPUP_COMPOSITION_TYPES.TWO_COLUMNS),
          footer: null,
          params: {
            backgroundColor: '#ffffff', // цвет фона
            backgroundImage: null, // фоновая картинка
            backgroundType: POPUP_BACKGROUND_TYPES.COLOR, // тип фона
            composition: POPUP_COMPOSITION_TYPES.TWO_COLUMNS, // композиция
          },
        },
        replyType: POPUP_REPLY_TYPES.NO,
      },
      [MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL]: {
        body: '',
        bodyJson: bodyJson ?? {
          blocks: this.getPopupComposition(POPUP_COMPOSITION_TYPES.ONE_COLUMN),
          footer: null,
          params: {
            backgroundColor: '#ffffff', // цвет фона
            backgroundImage: null, // фоновая картинка
            backgroundType: POPUP_BACKGROUND_TYPES.COLOR, // тип фона
            composition: POPUP_COMPOSITION_TYPES.ONE_COLUMN, // композиция (у маленького поп-апа не меняется)
          },
        },
        replyType: POPUP_REPLY_TYPES.NO,
      },
      [MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL]: {
        subject: '', // тема Push
        body: '',
        bodyJson: {
          blocks: this.getPopupComposition(POPUP_COMPOSITION_TYPES.ONE_COLUMN),
          footer: null,
          params: {
            backgroundColor: '#ffffff', // цвет фона
            backgroundImage: null, // фоновая картинка
            backgroundType: POPUP_BACKGROUND_TYPES.COLOR, // тип фона
            composition: POPUP_COMPOSITION_TYPES.ONE_COLUMN, // композиция (у маленького поп-апа не меняется)
          },
        },
        replyType: POPUP_REPLY_TYPES.NO,
      },
      [MESSAGE_PART_TYPES.CONTROL_GROUP]: {
        body: '',
        replyType: POPUP_REPLY_TYPES.NO,
      },
      [MESSAGE_PART_TYPES.EMAIL]: {
        isUtmMarksEnabled: false,
        type: EMAIL_TYPES.DEFAULT, // тип email (default, html или bee)
        replyType: POPUP_REPLY_TYPES.TEXT,
        subject: '', // тема email
        pureHtml: false,
        utmMarks: this.utmMarkModel.getPredefined(),
        [EMAIL_TYPES.BEE]: {
          body: '',
          bodyJson: '',
          aceEditorOptions: {
            mode: 'html',
            theme: 'monokai',
            highlightActiveLine: true,
            showPrintMargin: false,
            showGutter: true,
            readOnly: true,
          },
        },
        [EMAIL_TYPES.DEFAULT]: {
          body: '',
        },
        [EMAIL_TYPES.HTML]: {
          body: '',
          aceEditorOptions: {
            // опции для редактора кода ace editor
            mode: 'html',
            theme: 'monokai',
            highlightActiveLine: true,
            showPrintMargin: false,
            showGutter: true,
          },
        },
        [EMAIL_TYPES.AI]: {
          body: '',
          bodyJson:
            bodyJson ??
            `{
  "ai_content_maker_integration": 0,
  "test_lead": "123",
  "message_context": "",
  "variables": [{ "property_name": "", "default": "", "context": "" }],
  "constants": [{ "value": "", "context": "" }]
}`,
        },
      },
      [MESSAGE_PART_TYPES.JS]: {
        body: '',
        replyType: POPUP_REPLY_TYPES.TEXT,
        aceEditorOptions: {
          mode: 'javascript',
          theme: 'monokai',
          highlightActiveLine: true,
          showPrintMargin: false,
          showGutter: true,
        },
      },
      [MESSAGE_PART_TYPES.POPUP_BIG]: {
        body: '',
        replyType: POPUP_REPLY_TYPES.TEXT,
      },
      [MESSAGE_PART_TYPES.POPUP_SMALL]: {
        body: '',
        replyType: POPUP_REPLY_TYPES.TEXT,
      },
      [MESSAGE_PART_TYPES.POPUP_CHAT]: {
        body: '',
        replyType: POPUP_REPLY_TYPES.TEXT,
      },
      [MESSAGE_PART_TYPES.SDK_POPUP_CHAT]: {
        body: '',
        replyType: POPUP_REPLY_TYPES.TEXT,
      },
      [MESSAGE_PART_TYPES.PUSH]: {
        subject: '', // тема Push
        body: '',
        clickAction: '', // действие по клику на пуш (сейчас - ссылка)
        icon: '', // ссылка на иконку
        newIcon: null, // файл новой иконки
        newIconPreview: '', // новая иконка после парсинга при помощи cq-file-as-data-url
        newIconPreviewBlob: null, // новая иконка после парсинга при помощи cq-file-as-blob (для вставку в превью в iframe)
        newIconUrl: '', // ссылка на новую иконку
        replyType: POPUP_REPLY_TYPES.NO,
      },
      [MESSAGE_PART_TYPES.SDK_PUSH]: {
        subject: '', // тема Push
        body: '',
        clickAction: '', // действие по клику на пуш (сейчас - ссылка)
        icon: '', // ссылка на иконку
        newIcon: null, // файл новой иконки
        newIconPreview: '', // новая иконка после парсинга при помощи cq-file-as-data-url
        newIconPreviewBlob: null, // новая иконка после парсинга при помощи cq-file-as-blob (для вставку в превью в iframe)
        newIconUrl: '', // ссылка на новую иконку
        replyType: POPUP_REPLY_TYPES.NO,
      },

      [MESSAGE_PART_TYPES.WEBHOOK]: {
        body: '',
        bodyJson: bodyJson ?? this.getDefaultWebhookBodyJson(),
        replyType: POPUP_REPLY_TYPES.TEXT,
      },

      [MESSAGE_PART_TYPES.TELEGRAM]: {
        body: '',
        bodyJson: bodyJson ?? {
          contents: [
            {
              type: 'text',
              value: '',
              attachment: null,
            },
          ],
          buttons: [],
          propToWrite: null,
          integration: null,
        },
        replyType: POPUP_REPLY_TYPES.NO,
      },
    };

    this.setBackgroundPosition(
      defaultMessagePart[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG].bodyJson.params,
      POPUP_BACKGROUND_POSITION_TYPES.CONTAIN,
    );
    this.setBackgroundPosition(
      defaultMessagePart[MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].bodyJson.params,
      POPUP_BACKGROUND_POSITION_TYPES.CONTAIN,
    );
    this.setBackgroundPosition(
      defaultMessagePart[MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].bodyJson.params,
      POPUP_BACKGROUND_POSITION_TYPES.CONTAIN,
    );

    this.generatePartNames([defaultMessagePart]);

    return defaultMessagePart;
  }

  /**
   * Получение дефолтного bodyJson для нового webhook
   */
  getDefaultWebhookBodyJson(): MessageWebhookBodyJson {
    return {
      headers: [],
      queryParams: [{ key: '', value: '' }],
      body: `{\n    \n}`,
      method: 'post',
      eventType: null,
      eventProps: [],
    };
  }

  /**
   * Поулчение парта для бота
   */
  getDefaultForBot() {
    return {
      name: '',
      type: MESSAGE_PART_TYPES.LEAD_BOT,
      proportion: 1,
      reply_type: 'no',
      subject: '',
      pure_html: false,
      body_json: '',
      body: '', // Тут должен лежать IDбота
    };
  }

  /**
   * Подсчёт количества боай, которое занимает письмо
   *
   * @param messagePartBody Строка с соджержимым письма
   * @returns Вес письма в байтах
   */
  getEmailSize(messagePartBody: string): number {
    return countUtf8Bytes(messagePartBody);

    /**
     * Функция подсчёта количества байт, которая занимает строка
     * Взято отсюда: https://stackoverflow.com/a/25994411
     *
     * @param s Строка, у которой нужно подсчитать количество байт
     */
    function countUtf8Bytes(s: string): number {
      let b = 0,
        i = 0,
        c;
      for (; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1) {}
      return b;
    }
  }

  getMessagePartSendings(
    entityId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    sendingType: SENDING_TYPES,
    paginatorParams?: any,
    excludeControlGroup?: boolean,
  ) {
    return this.messageStatisticsModel.getSendings(
      'messageparts',
      entityId,
      startDate,
      endDate,
      sendingType,
      paginatorParams,
      excludeControlGroup,
    );
  }

  getMessagePartStatistics(
    entityId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    excludeControlGroup: boolean = true,
    rangeOverride?: any,
  ) {
    return this.messageStatisticsModel.getStatistics(
      'messageparts',
      entityId,
      startDate,
      endDate,
      excludeControlGroup,
      rangeOverride,
    );
  }

  /**
   * Получение вариантов сообщения для тест-группы
   *
   * @param testGroupId ID тест-группы
   * @param includeClosed=false Дополнительно получить закрытые (на текущий момент не действующие) варианты сообщения
   * @param includeLastSent=true Дополнительно получить дату последней отправки для каждого варианта
   */
  getMessageTestGroupParts(testGroupId: string, includeClosed = false, includeLastSent = true): Observable<any> {
    if (angular.isUndefined(testGroupId)) {
      throw Error('testGroupId must be specified');
    }

    includeClosed = angular.isDefined(includeClosed) ? includeClosed : true;
    includeLastSent = angular.isDefined(includeLastSent) ? includeLastSent : true;

    const params: any = {
      include_closed: includeClosed,
      include_last_sent: includeLastSent,
    };

    return this.httpClient
      .get('/messagetestgroups/' + testGroupId + '/messageparts', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          this.caseStyleHelper.keysToCamelCase(response);

          const testGroupParts = response.data.parts;

          for (let i = 0; i < testGroupParts.length; i++) {
            testGroupParts[i] = this.parseMessagePartToInternalFormat(testGroupParts[i]);
          }

          this.generatePartNames(testGroupParts);
          this.setColors(testGroupParts);

          return testGroupParts;
        }),
      );
  }

  /**
   * Получение композиции поп-апа
   * @param {POPUP_COMPOSITION_TYPES} compositionType Тип композиции
   */
  getPopupComposition(compositionType: POPUP_COMPOSITION_TYPES) {
    return angular.copy(POPUP_COMPOSITIONS[compositionType]);
  }

  /**
   * Является ли тип варианта сообщения удаляемым, если пользователь на него не ответил
   *
   * @param messagePartType Тип сообщения
   */
  isDeletableType(messagePartType: MESSAGE_PART_TYPES): boolean {
    return !!~[
      MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
      MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
      MESSAGE_PART_TYPES.POPUP_BIG,
      MESSAGE_PART_TYPES.POPUP_CHAT,
      MESSAGE_PART_TYPES.POPUP_SMALL,
      MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
      MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
    ].indexOf(messagePartType);
  }

  /**
   * Проверка на возможность фильтровать отправку по URL у заданного варианта типа сообщения
   *
   * @param {MESSAGE_PART_TYPES} messagePartType Тип сообщения
   */
  isFilterableByUrlType(messagePartType: MESSAGE_PART_TYPES): boolean {
    return !!~[
      MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
      MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
      MESSAGE_PART_TYPES.JS,
      MESSAGE_PART_TYPES.POPUP_BIG,
      MESSAGE_PART_TYPES.POPUP_CHAT,
      MESSAGE_PART_TYPES.POPUP_SMALL,
      MESSAGE_PART_TYPES.WEBHOOK,
      MESSAGE_PART_TYPES.LEAD_BOT,
    ].indexOf(messagePartType);
  }

  /**
   * Перемещение блока вниз
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок, который нужно переместить
   */
  moveBlockDown(popupBlocks: any[], popupBlock: any) {
    const blockCoordinates = this.findBlock(popupBlocks, popupBlock);

    if (this.canMoveBlockDown(popupBlocks, popupBlock, blockCoordinates)) {
      const tempBlock = popupBlocks[blockCoordinates[0]][blockCoordinates[1]][blockCoordinates[2] + 1];

      popupBlocks[blockCoordinates[0]][blockCoordinates[1]][blockCoordinates[2] + 1] = popupBlock;
      popupBlocks[blockCoordinates[0]][blockCoordinates[1]][blockCoordinates[2]] = tempBlock;
    }
  }

  /**
   * Перемещение блока влево
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок, который нужно переместить
   */
  moveBlockLeft(popupBlocks: any[], popupBlock: any) {
    const blockCoordinates = this.findBlock(popupBlocks, popupBlock);

    if (this.canMoveBlockLeft(popupBlocks, popupBlock, blockCoordinates)) {
      popupBlocks[blockCoordinates[0]][blockCoordinates[1] - 1].splice(
        popupBlocks[blockCoordinates[0]][blockCoordinates[1] - 1].length,
        0,
        popupBlocks[blockCoordinates[0]][blockCoordinates[1]].splice(blockCoordinates[2], 1)[0],
      );
    }
  }

  /**
   * Перемещение блока вправо
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок, который нужно переместить
   */
  moveBlockRight(popupBlocks: any[], popupBlock: any) {
    const blockCoordinates = this.findBlock(popupBlocks, popupBlock);

    if (this.canMoveBlockRight(popupBlocks, popupBlock, blockCoordinates)) {
      popupBlocks[blockCoordinates[0]][blockCoordinates[1] + 1].splice(
        popupBlocks[blockCoordinates[0]][blockCoordinates[1] + 1].length,
        0,
        popupBlocks[blockCoordinates[0]][blockCoordinates[1]].splice(blockCoordinates[2], 1)[0],
      );
    }
  }

  /**
   * Перемещение блока вверх
   * NOTE: функция написана не для общего случая, не универсально, а только для ONE_COLUMN и TWO_COLUMNS, для увеличения скорости разработки
   *
   * @param popupBlocks Блоки поп-апа
   * @param popupBlock Блок, который нужно переместить
   */
  moveBlockUp(popupBlocks: any[], popupBlock: any) {
    const blockCoordinates = this.findBlock(popupBlocks, popupBlock);

    if (this.canMoveBlockUp(popupBlocks, popupBlock, blockCoordinates)) {
      let tempBlock = popupBlocks[blockCoordinates[0]][blockCoordinates[1]][blockCoordinates[2] - 1];

      popupBlocks[blockCoordinates[0]][blockCoordinates[1]][blockCoordinates[2] - 1] = popupBlock;
      popupBlocks[blockCoordinates[0]][blockCoordinates[1]][blockCoordinates[2]] = tempBlock;
    }
  }

  /**
   * Парсинг варианта сообщения во внутренний формат админки
   *
   * @param {Object} messagePart Вариант сообщения
   * @param {Properties} properties
   * @returns {Object}
   */
  parseMessagePartToInternalFormat(messagePart: MessagePartExternal, properties?: Properties): MessagePart {
    const internalMessagePart: Partial<MessagePart> = angular.copy(this.getDefault());

    internalMessagePart.id = messagePart.id;
    internalMessagePart.name = messagePart.name;
    internalMessagePart.type = messagePart.type;
    internalMessagePart.proportion = messagePart.proportion;
    internalMessagePart.created =
      messagePart.created != null
        ? moment(messagePart.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        : internalMessagePart.created;
    internalMessagePart.lastSent =
      messagePart.lastSent != null
        ? moment(messagePart.lastSent, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        : internalMessagePart.lastSent;

    this.convertPartFromServerToInternal(internalMessagePart, messagePart, properties);

    return internalMessagePart as MessagePart;
  }

  /**
   * Парсинг варианта сообщения во внутренний формат сервера
   *
   * @param messagePart Вариант сообщения
   */
  parseMessagePartToServerFormat(messagePart: any) {
    const parsedMessagePart: any = {};
    parsedMessagePart.id = messagePart.id;
    parsedMessagePart.name = messagePart.name;
    parsedMessagePart.type = messagePart.type;
    parsedMessagePart.proportion = messagePart.proportion;
    parsedMessagePart.reply_type = messagePart[messagePart.type].replyType;
    parsedMessagePart.subject =
      messagePart.type === MESSAGE_PART_TYPES.EMAIL ? messagePart[MESSAGE_PART_TYPES.EMAIL].subject : '';
    parsedMessagePart.pure_html =
      messagePart.type === MESSAGE_PART_TYPES.EMAIL &&
      (messagePart[MESSAGE_PART_TYPES.EMAIL].type === EMAIL_TYPES.HTML ||
        messagePart[MESSAGE_PART_TYPES.EMAIL].type === EMAIL_TYPES.BEE);
    parsedMessagePart.body_json =
      messagePart.type === MESSAGE_PART_TYPES.EMAIL && messagePart.email.type === EMAIL_TYPES.BEE
        ? messagePart.email.bee.bodyJson
        : messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL ||
          messagePart.type === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL ||
          messagePart.type === MESSAGE_PART_TYPES.TELEGRAM ||
          messagePart.type === MESSAGE_PART_TYPES.WEBHOOK
        ? messagePart[messagePart.type].bodyJson
        : '';

    if (~[MESSAGE_PART_TYPES.JS, MESSAGE_PART_TYPES.SDK_POPUP_CHAT].indexOf(messagePart.type)) {
      parsedMessagePart.body = messagePart[messagePart.type].body;
    } else if (messagePart.type === MESSAGE_PART_TYPES.WEBHOOK) {
      parsedMessagePart.body = messagePart[messagePart.type].body;
      const messagePartBodyJson = messagePart[messagePart.type].bodyJson;
      let parsedBodyJson = '';

      if (messagePartBodyJson) {
        parsedBodyJson = angular.toJson({
          headers: this.parseWebhookParamsToServerFormat(messagePartBodyJson.headers),
          query_params: this.parseWebhookParamsToServerFormat(messagePartBodyJson.queryParams),
          body: messagePartBodyJson.method === 'get' ? null : messagePartBodyJson.body,
          method: messagePartBodyJson.method,
          event_type: messagePartBodyJson.eventType,
          event_props: messagePartBodyJson.eventType
            ? this.parseWebhookParamsToServerFormat(messagePartBodyJson.eventProps)
            : {},
        });
      }

      parsedMessagePart.body_json = parsedBodyJson;
    } else if (messagePart.type === MESSAGE_PART_TYPES.POPUP_CHAT) {
      parsedMessagePart.body = messagePart[messagePart.type].body;

      /*NOTE Тут надо проверить наличие сендера т.к. не у всех он может быть*/
      if (messagePart[messagePart.type].sender) {
        if (messagePart[messagePart.type].sender.type === MESSAGE_PART_SENDER_TYPES.MESSAGE_SENDER) {
          parsedMessagePart.msender = messagePart[messagePart.type].sender.id;
        } else if (messagePart[messagePart.type].sender.type === MESSAGE_PART_SENDER_TYPES.TEAM_MEMBER) {
          parsedMessagePart.muser = messagePart[messagePart.type].sender.id;
        }
      }
    } else if (~[MESSAGE_PART_TYPES.POPUP_SMALL, MESSAGE_PART_TYPES.POPUP_BIG].indexOf(messagePart.type)) {
      parsedMessagePart.body = messagePart[messagePart.type].body;
      if (~[POPUP_REPLY_TYPES.PUSH, POPUP_REPLY_TYPES.BUTTON].indexOf(messagePart[messagePart.type].replyType)) {
        /*NOTE Артем: "в body_json можно хранить что угодно и поэтому я решил хранить сразу в удобном формате. И еще потому что в чате нет методов преобразование объектов в разные нотации"*/
        parsedMessagePart.body_json = angular.toJson({
          replyButtonText: messagePart[messagePart.type].replyButtonText,
          replyButtonAction: messagePart[messagePart.type].replyButtonAction || '',
        });
      }
    } else if (
      ~[
        MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
        MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
        MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
      ].indexOf(messagePart.type)
    ) {
      let bodyJson: any = {
        blocks: [],
        footer: undefined,
        params: {},
      };

      // NOTE Роман Е. Для поп-ап в SDK есть возможность кастомизировать push-уведомление.
      // NOTE Тема push-уведомления записывается в subject, a текст push-уведомления в body.
      if (messagePart.type === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        parsedMessagePart.subject = messagePart[messagePart.type].subject;
      }

      parsedMessagePart.body = messagePart[messagePart.type].body;

      // парсинг блоков

      const popupBlocks = messagePart[messagePart.type].bodyJson.blocks;

      for (let rowIndex = 0; rowIndex < popupBlocks.length; rowIndex++) {
        bodyJson.blocks[rowIndex] = [];

        for (let colIndex = 0; colIndex < popupBlocks[rowIndex].length; colIndex++) {
          bodyJson.blocks[rowIndex][colIndex] = [];

          for (let blockIndex = 0; blockIndex < popupBlocks[rowIndex][colIndex].length; blockIndex++) {
            bodyJson.blocks[rowIndex][colIndex][blockIndex] = this.popupBlockModel.parseToServerFormat(
              popupBlocks[rowIndex][colIndex][blockIndex],
            );
          }
        }
      }

      if (messagePart[messagePart.type].bodyJson.footer) {
        bodyJson.footer = this.popupBlockModel.parseToServerFormat(messagePart[messagePart.type].bodyJson.footer);
      }

      // парсинг параметров поп-апа
      /*NOTE Артем: "в body_json можно хранить что угодно и поэтому я решил хранить сразу в удобном формате. И еще потому что в чате нет методов преобразование объектов в разные нотации"*/
      bodyJson.params = JSON.parse(JSON.stringify(messagePart[messagePart.type].bodyJson.params));

      if (
        messagePart[messagePart.type].bodyJson.params.backgroundImage &&
        !(messagePart[messagePart.type].bodyJson.params.backgroundImage instanceof File)
      ) {
        // если ссылка на картинку уже была в блоке - нужно её оставить
        bodyJson.params.backgroundImage = messagePart[messagePart.type].bodyJson.params.backgroundImage.split(
          this.messageUtilsModel.getPathToImages(messagePart[messagePart.type].bodyJson.params.backgroundImage),
        )[1];
      } else {
        bodyJson.params.backgroundImage = '';
      }

      parsedMessagePart.body_json = angular.toJson(bodyJson);
    } else if (messagePart.type === MESSAGE_PART_TYPES.EMAIL) {
      parsedMessagePart.body = messagePart.email[messagePart.email.type].body;

      if (messagePart.email.type === EMAIL_TYPES.AI) {
        parsedMessagePart.body = '';
        parsedMessagePart.body_json = this.parseAIEmailBodyJsonToServerFormat(
          messagePart.email[EMAIL_TYPES.AI].bodyJson,
        );
      }

      if (messagePart[messagePart.type].sender) {
        if (messagePart[messagePart.type].sender.type === MESSAGE_PART_SENDER_TYPES.MESSAGE_SENDER) {
          parsedMessagePart.msender = messagePart[messagePart.type].sender.id;
        } else if (messagePart[messagePart.type].sender.type === MESSAGE_PART_SENDER_TYPES.TEAM_MEMBER) {
          parsedMessagePart.muser = messagePart[messagePart.type].sender.id;
        }
      }

      if (messagePart[messagePart.type].isUtmMarksEnabled) {
        parsedMessagePart.utm_marks = this.utmMarkModel.parseToServerFormat(messagePart[messagePart.type].utmMarks);
      }
    } else if (messagePart.type === MESSAGE_PART_TYPES.PUSH) {
      parsedMessagePart.body = messagePart[MESSAGE_PART_TYPES.PUSH].body;
      parsedMessagePart.subject = messagePart[MESSAGE_PART_TYPES.PUSH].subject;

      const bodyJson: any = {
        click_action: messagePart[MESSAGE_PART_TYPES.PUSH].clickAction,
        icon: (messagePart[MESSAGE_PART_TYPES.PUSH].newIconUrl || messagePart[MESSAGE_PART_TYPES.PUSH].icon).split(
          environment.userFilesUrl + '/push-icons/',
        )[1],
      };

      parsedMessagePart.body_json = angular.toJson(bodyJson);
    } else if (messagePart.type === MESSAGE_PART_TYPES.SDK_PUSH) {
      parsedMessagePart.body = messagePart[MESSAGE_PART_TYPES.SDK_PUSH].body;
      parsedMessagePart.subject = messagePart[MESSAGE_PART_TYPES.SDK_PUSH].subject;

      const bodyJson: any = {
        click_action: messagePart[MESSAGE_PART_TYPES.SDK_PUSH].clickAction,
      };

      parsedMessagePart.body_json = angular.toJson(bodyJson);
    } else if (messagePart.type === MESSAGE_PART_TYPES.TELEGRAM) {
      let bodyJson: any = {
        contents: [],
        buttons: [],
        prop_to_write: messagePart[messagePart.type].bodyJson.propToWrite,
        integration: {
          type: 'telegram',
          id: messagePart[messagePart.type].bodyJson.integration,
        },
      };

      for (let button of messagePart[messagePart.type].bodyJson.buttons) {
        bodyJson.buttons.push({
          action_type: button.actionType,
          text: button.text,
          url: button.actionType === 'url' ? button.url : null,
          event_to_write: button.eventToWrite || null,
        });
      }

      for (let content of messagePart[messagePart.type].bodyJson.contents) {
        bodyJson.contents.push({
          type: content.type,
          value: content.type === 'file' ? null : content.value,
          attachment: content.type === 'file' ? this.caseStyleHelper.keysToUnderscore({ ...content.attachment }) : null,
        });
      }

      parsedMessagePart.body_json = angular.toJson(bodyJson);
    }

    // NOTE Роман Е. Для "Чат в SDK" и "Поп-ап в SDK" есть возможность отправить push-уведомление вместе с сообщением.
    if (!!~[MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].indexOf(messagePart.type)) {
      parsedMessagePart.send_sdk_push = messagePart[messagePart.type].send_sdk_push;
    }

    // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
    // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
    // HACK Поэтому показываем backend, что это сообщение SDK-типа.
    if (
      !!~[
        MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
        MESSAGE_PART_TYPES.SDK_PUSH,
        MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
      ].indexOf(messagePart.type)
    ) {
      parsedMessagePart.recipient_type = RECIPIENT_TYPES.SDK;
    }

    // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
    // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
    // HACK Поэтому скрываем от backend, что это сообщение SDK-типа.
    if (!!~[MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].indexOf(messagePart.type)) {
      parsedMessagePart.type = messagePart.type.replace(RECIPIENT_TYPES.SDK + '_', '');
    }

    return parsedMessagePart;
  }

  /**
   * Парсинг парта из парта для бота
   *
   * @param messagePart
   */
  parseMessagePartToServerFormatFromBot(messagePart: any) {
    const parsedPart: any = {
      name: messagePart.name,
      type: messagePart.type,
      proportion: messagePart.proportion,
      reply_type: 'no',
      subject: '',
      pure_html: false,
      body_json: '',
      body: messagePart.body,
    };

    if (messagePart.id) {
      parsedPart.id = messagePart.id;
    }

    return parsedPart;
  }

  /**
   * Удаление блока из поп-апа
   *
   * @param popupSettings Настройки поп-апа
   * @param popupBlock Блок для удаления
   */
  removeBlock(popupSettings: any, popupBlock: any) {
    if (popupBlock.type == POPUP_BLOCK_TYPES.FOOTER) {
      popupSettings.bodyJson.footer = null;
    } else {
      const blockCoordinates = this.findBlock(popupSettings.bodyJson.blocks, popupBlock);

      popupSettings.bodyJson.blocks[blockCoordinates[0]][blockCoordinates[1]].splice(blockCoordinates[2], 1);
    }
  }

  /**
   * Сохранение дополнительных данных для ТГ сообщений
   *
   * @param {MessageTelegramContent} content
   * @returns {*}
   */
  saveAdditionalDataForTelegramMessage(content: MessageTelegramContent) {
    const saveTelegramFile = (content: MessageTelegramContent) => {
      // TODO Car-64627
      //if (content.type === 'file' && content.attachment instanceof File) {
      if (content.type === 'file' && (content.attachment as MessageAttachmentTemporary).fileBlob) {
        return firstValueFrom<MessageAttachment>(
          this.messageUtilsModel.saveFile(content.attachment as MessageAttachmentTemporary),
        ).then(saveFileSuccess);
      } else {
        return Promise.resolve();
      }

      function saveFileSuccess(file: MessageAttachment) {
        content.attachment = file;
      }
    };

    const promises = [];
    promises.push(saveTelegramFile(content));

    if (promises.length) {
      return Promise.all(promises);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Сохранение дополнительных данных вариантов сообщений
   *
   * @param messagePart
   * @returns {*}
   */
  saveAdditionalData(messagePart: any) {
    /**
     * Сохранение картинки, используемой в блочном попапе
     *
     * @param popupParams Параметры блока
     */
    const saveBackgroundImage = (popupParams: any) => {
      if (popupParams.backgroundType === POPUP_BACKGROUND_TYPES.IMAGE && popupParams.backgroundImage instanceof File) {
        return firstValueFrom<string>(this.messageUtilsModel.saveImage(popupParams.backgroundImage)).then(
          saveBackgroundImageSuccess,
        );
      } else {
        return Promise.resolve();
      }

      function saveBackgroundImageSuccess(imageUrl: string) {
        popupParams.backgroundImage = imageUrl;
      }
    };

    const promises = [];

    switch (messagePart.type) {
      case MESSAGE_PART_TYPES.ALL:
      case MESSAGE_PART_TYPES.CONTROL_GROUP:
      case MESSAGE_PART_TYPES.EMAIL:
      case MESSAGE_PART_TYPES.JS:
      case MESSAGE_PART_TYPES.POPUP_BIG:
      case MESSAGE_PART_TYPES.POPUP_CHAT:
      case MESSAGE_PART_TYPES.POPUP_SMALL:
      case MESSAGE_PART_TYPES.PUSH:
      case MESSAGE_PART_TYPES.TELEGRAM:
      case MESSAGE_PART_TYPES.WEBHOOK:
        break;
      case MESSAGE_PART_TYPES.BLOCK_POPUP_BIG:
      case MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL:
      case MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL:
        // FIXME: запилить сюда вызов popupBlock.saveAdditionalData()
        promises.push(saveBackgroundImage(messagePart[messagePart.type].bodyJson.params));
        break;
    }

    if (promises.length) {
      return Promise.all(promises);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Установка поп-апу параметров позиционирования фона
   * Эта функция нужна из-за того, что параметров фона несколько, присвоить их одной строчкой в контроллере (в селекте выбора позиции картинки) не получится
   * NOTE: Не путать с popupBlockModel.setBackgroundPosition(), та функция работает для параметров блока
   *
   * @param popupParams Параметры поп-апа
   * @param backgroundPosition Позиция, которую надо установить фоновой картинке
   */
  setBackgroundPosition(popupParams: any, backgroundPosition: POPUP_BACKGROUND_POSITION_TYPES) {
    const backgroundPositionParams = this.getBackgroundPositionParams();

    popupParams.backgroundPosition = backgroundPositionParams[backgroundPosition].backgroundPosition;
    popupParams.backgroundRepeat = backgroundPositionParams[backgroundPosition].backgroundRepeat;
    popupParams.backgroundSize = backgroundPositionParams[backgroundPosition].backgroundSize;
  }

  /**
   * Установка цветов вариантам сообщения
   *
   * @param parts Варианты сообщения
   */
  setColors(parts: any[]) {
    const controlGroup = this.filterControlGroup(parts);
    const messageParts = this.filterMessageParts(parts);

    // todo: убрать этот фикс когда в базе каждое сообщение будет гарантировано содержать контрольную группу
    if (controlGroup) {
      controlGroup.colorClass = CONTROL_GROUP_COLOR_CLASS;
    }

    for (let i = 0; i < messageParts.length; i++) {
      const messagePart = messageParts[i];

      messagePart.colorClass = MESSAGE_PART_COLOR_CLASSES[i];
    }
  }

  private parseAIEmailBodyJsonToServerFormat(bodyJson: string) {
    return JSON.stringify(JSON.parse(bodyJson));
  }

  private parseAIEmailBodyJsonToInternalFormat(bodyJson: string) {
    return JSON.stringify(JSON.parse(bodyJson), null, 2);
  }

  /**
   * Парсинг параметров вебхуков из [{key: 'k', value: 'v'}] в {k: v} для работы на беке
   * @param params
   * @private
   */
  private parseWebhookParamsToServerFormat(params: MessageWebhookParam[]): Record<string, string> {
    let result: Record<string, string> = {};
    for (let param of params) {
      result[param.key] = param.value;
    }
    return result;
  }

  /**
   * Парсинг параметров вебхуков из {k: v} в [{key: 'k', value: 'v'}] для работы на фронте
   * @param params
   * @private
   */
  private parseWebhookParamsToInternalFormat(params: Record<string, string>): MessageWebhookParam[] {
    let result: MessageWebhookParam[] = [];
    for (let key in params) {
      result.push({ key: key, value: params[key] });
    }
    return result;
  }
}

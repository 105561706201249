<div class="margin-bottom-20">{{ 'account.passwordChange.howChangePassword' | transloco }}</div>
<div class="flex">
  <form
    class="account-password-form"
    novalidate
    (ngSubmit)="changePassword()"
  >
    <div
      class="form-group row"
      [cqShowError]="oldPasswordControl"
    >
      <label class="col-4 col-form-label">{{
        'account.passwordChange.passwordChangeForm.oldPasswordInput.label' | transloco
      }}</label>
      <div class="col-8">
        <input
          class="form-control"
          type="password"
          [formControl]="oldPasswordControl"
        />
        <cq-validation-messages [control]="oldPasswordControl">
          <cq-validation-message errorName="required">
            {{ 'account.passwordChange.passwordChangeForm.oldPasswordInput.errors.required' | transloco }}
          </cq-validation-message>
          <cq-validation-message errorName="wrongOldPassword">
            {{ 'account.passwordChange.passwordChangeForm.oldPasswordInput.errors.wrongOldPassword' | transloco }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
    </div>
    <div
      class="form-group row"
      [cqShowError]="newPasswordControl"
    >
      <label class="col-4 col-form-label">{{
        'account.passwordChange.passwordChangeForm.newPasswordInput.label' | transloco
      }}</label>
      <div class="col-8">
        <input
          class="form-control"
          type="password"
          [formControl]="newPasswordControl"
        />
      </div>
      <cq-validation-messages [control]="newPasswordControl">
        <cq-validation-message errorName="required">
          {{ 'account.passwordChange.passwordChangeForm.newPasswordInput.errors.required' | transloco }}
        </cq-validation-message>
        <cq-validation-message errorName="minlength">
          {{
            'account.passwordChange.passwordChangeForm.newPasswordInput.errors.minlength' | transloco: { minLength: 6 }
          }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <div
      class="form-group row"
      [cqShowError]="newPasswordConfirmControl"
    >
      <label class="col-4 col-form-label">{{
        'account.passwordChange.passwordChangeForm.newPasswordConfirmInput.label' | transloco
      }}</label>
      <div class="col-8">
        <input
          class="form-control"
          type="password"
          [formControl]="newPasswordConfirmControl"
        />
      </div>
      <cq-validation-messages [control]="newPasswordConfirmControl">
        <cq-validation-message errorName="required">
          {{ 'account.passwordChange.passwordChangeForm.newPasswordConfirmInput.errors.required' | transloco }}
        </cq-validation-message>
        <cq-validation-message errorName="equal">
          {{ 'account.passwordChange.passwordChangeForm.newPasswordConfirmInput.errors.equal' | transloco }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <div class="form-group row no-margin-bottom">
      <div class="col-8 offset-4">
        <button
          class="btn btn-lg btn-primary"
          type="submit"
        >
          {{ 'account.passwordChange.passwordChangeForm.changePasswordButton' | transloco }}
        </button>
      </div>
    </div>
  </form>
  <div class="account-password-right card no-margin-bottom margin-left-auto">
    <div class="card-body">
      <h4>{{ 'account.passwordChange.googleInfo.title' | transloco }}</h4>
      <div>{{ 'account.passwordChange.googleInfo.description' | transloco }}</div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';
import { TelegramBotStoreService } from '@panel/app-old/shared/services/telegram-bot-editor-store/telegram-bot-store.service';

@Component({
  selector: 'cq-tg-integration[telegramIntegrationId]',
  templateUrl: './tg-integration.component.html',
  styleUrls: ['./tg-integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TgIntegrationComponent {
  @Input()
  telegramIntegrationId!: string | null;

  @Output()
  telegramIntegrationIdChange: Subject<string> = new Subject();

  @Output()
  ajsValidationFn: Subject<ValidationCallback> = new Subject();

  constructor(private readonly telegramBotStoreService: TelegramBotStoreService) {}

  onTelegramIntegrationIdChange(telegramIntegrationId: string) {
    this.telegramIntegrationIdChange.next(telegramIntegrationId);
    this.telegramBotStoreService.editableTelegramBotIntegrationId$.next(telegramIntegrationId);
  }

  get telegramIntegrationList(): BehaviorSubject<TelegramIntegration[]> {
    return this.telegramBotStoreService.telegramIntegrationList$;
  }
}

import { NgModule } from '@angular/core';

import { AiContentMakerModule } from '@panel/app/pages/trigger-messages/email-part-editor/components/ai-content-maker/ai-content-maker.module';
import { LegacyWebhookPartEditorModule } from '@panel/app/pages/trigger-messages/legacy-webhook-part-editor/legacy-webhook-part-editor.module';
import { TelegramPartEditorModule } from '@panel/app/pages/trigger-messages/telegram-part-editor/telegram-part-editor.module';
import { WebhookPartEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/webhook-part-editor.module';

@NgModule({
  declarations: [],
  imports: [AiContentMakerModule, LegacyWebhookPartEditorModule, TelegramPartEditorModule, WebhookPartEditorModule],
})
export class TriggerMessagesModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { FilterService } from '@panel/app/services/filter/filter.service';
import { PropertyFilter } from '@panel/app/services/filter/types/filter.internal-types';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';
import { UserProperty } from '@http/property/property.model';

/**
 * TODO:
 *  - Добавить ограничение по тарифу
 */
@Component({
  selector: 'cq-property-filters',
  templateUrl: './property-filters.component.html',
  styleUrls: ['./property-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyFiltersComponent extends AbsCVAFormArrayBasedComponent<PropertyFilter, FormControl> {
  @Input({ required: true })
  app!: App;

  @Input()
  defaultFilters: PropertyFilter[] = [];

  @Input({ required: true })
  props!: UserProperty[];

  @Input({ required: true })
  telegramIntegrations: TelegramIntegrationExternal[] = [];

  /** TODO сделать дефолтный access в константу */
  accessToUsersCustomProperties: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  readonly control: FormArray<FormControl<PropertyFilter>> = this.fb.array<FormControl<PropertyFilter>>([]);

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  protected addFilter(filter?: PropertyFilter): void {
    let control: FormControl<PropertyFilter> = this.getNewControl(filter);

    this.control.push(control);
  }

  protected filterNumber(): number {
    if (this.hasEmptyFilters()) {
      return 0;
    }

    return this.control.length;
  }

  protected hasEmptyFilters(): boolean {
    return this.control.getRawValue()[0].propertyName === null;
  }

  protected hasManyFilters(): boolean {
    return this.control.length > 1;
  }

  protected removeFilter(index: number): void {
    if (this.control.controls.length === 1) {
      this.control.controls[0].patchValue(FilterService.getDefaultPropertyFilter());
    } else {
      this.control.removeAt(index);
    }
  }

  protected trackBy(index: number, value: FormControl<PropertyFilter>): string {
    let propertyName = value.value.propertyName;

    if (!propertyName) {
      throw new Error('Property name is null');
    }

    return propertyName;
  }

  writeValue(values: PropertyFilter[]): void {
    if (values.length === 0) {
      values = [FilterService.getDefaultPropertyFilter()];
    }

    super.writeValue(values);
  }

  syncInternalChanges() {
    this.control.valueChanges
      .pipe(
        map(() => this.control.getRawValue()),
        map((value) => {
          return value.filter((filter) => filter.propertyName !== null);
        }),
      )
      .subscribe((value) => this.onChange(value));
  }

  getNewControl(filter?: PropertyFilter): FormControl<PropertyFilter> {
    if (!filter) {
      filter = FilterService.getDefaultPropertyFilter();
    }

    return this.fb.control<PropertyFilter>(filter, { nonNullable: true });
  }
}

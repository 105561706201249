import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@environment';
import { ARTICLES_COUNT_LIMIT } from '@http/knowledge-base/knowledge-base.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-limit-reached-modal',
  templateUrl: './limit-reached-modal.component.html',
  styleUrls: ['./limit-reached-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LimitReachedModalComponent implements OnInit {
  @Input() modalWindowParams!: any;

  protected PROJECT_NAME: string = environment.projectName;
  protected ARTICLES_COUNT_LIMIT = ARTICLES_COUNT_LIMIT;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.carrotquestHelper.track('База знаний - Увидел модалку про ограничения standard БЗ');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';

import { DataSourceComponent } from './data-source.component';

@NgModule({
  declarations: [DataSourceComponent],
  imports: [CommonModule, TranslocoModule, UIRouterModule, NgbTooltipModule],
  exports: [DataSourceComponent],
})
export class DataSourceModule {}

import cloneDeep from 'lodash-es/cloneDeep';

import { Immutable, ImmutableObject } from '@panel/app/shared/types/immutable.type';

/**
 * Перегрузки есть не все, дописывайте при надобности
 */
export function cloneObjectMutable<T>(value: ImmutableObject<T>): T;
export function cloneObjectMutable<T>(value: Immutable<T>): T {
  return cloneDeep(value as T);
}

<ng-container *transloco="let t">
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="control"
  >
    <ng-select
      [clearable]="false"
      [hideSelected]="true"
      [formControl]="control"
      [items]="filteredAndOrderedUserProperties"
      [trackByFn]="userPropsTrackFn"
      bindLabel="prettyName"
      bindValue="name"
      groupBy="group"
      placeholder="{{ t('vkFormsQuestionPropertyComponent.select.placeholder') }}"
    ></ng-select>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">{{
        t('vkFormsQuestionPropertyComponent.select.errors.required')
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

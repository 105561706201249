<ng-container *transloco="let t; read: 'conversationCardComponent'">
  <!-- Аватар пользователя -->
  <cq-user-avatar
    class="mr-10"
    [avatar]="conversation.user.avatar"
    [status]="conversation.user.presence"
  ></cq-user-avatar>
  <div class="flex-grow overflow-hidden">
    <div class="d-flex align-items-center">
      <!-- Имя пользователя -->
      <div
        class="flex-grow font-weight-bold text-truncate"
        [class]="{ muted: !conversation.user.props.$name }"
      >
        {{ conversation.user.name }}
      </div>

      <!-- Аватар последнего ответившего админа / иконка неразобранного диалога -->
      <div class="ml-5">
        <i
          *ngIf="!conversation.assignee"
          class="cqi-sm cqi-user-square text-danger"
          [title]="t('notAssigned.title')"
        ></i>
        <img
          *ngIf="conversation.assignee"
          class="assignee-avatar"
          [title]="conversation.assignee.name"
          [src]="conversation.assignee.avatar"
        />
      </div>

      <!-- Статус сообщения -->
      <cq-message-status
        class="ml-10"
        [conversation]="conversation"
      ></cq-message-status>

      <!-- Время сообщения -->
      <cq-time-since-last-reply
        class="ml-5 text-muted small white-space-nowrap"
        [replyTime]="correctPart.created"
      ></cq-time-since-last-reply>
    </div>
    <div class="d-flex mt-5">
      <div class="flex-grow overflow-hidden">
        <!-- Сообщение -->
        <cq-message [conversation]="conversation"></cq-message>

        <!-- Теги диалога -->
        <div
          *ngIf="conversation.tags?.length"
          class="tags"
        >
          <cq-tag-default
            class="mr-10"
            *ngFor="let tag of conversation.tags | slice: 0:TAGS_LIMIT; trackBy: trackByTag"
            [text]="tag"
          ></cq-tag-default>
        </div>
      </div>

      <!-- Иконка канала -->
      <cq-channel-icon
        *ngIf="conversation.channel && conversation.channel.type !== PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL"
        class="no-flex-shrink align-self-end ml-15"
        [channel]="conversation.channel"
        xs
      ></cq-channel-icon>
    </div>
  </div>
</ng-container>

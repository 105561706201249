(function () {
  'use strict';

  angular.module('myApp.integrations').constant('RETAILCRM_INTEGRATION_STEPS', getRetailcrmIntegrationSteps());

  function getRetailcrmIntegrationSteps() {
    return {
      STEP_1: {
        ORDER: 0,
        TEMPLATE_URL: 'js/components/integrations/retailcrm/steps/step1.html',
        VALUE: 'step1',
      },
      STEP_2: {
        ORDER: 1,
        TEMPLATE_URL: 'js/components/integrations/retailcrm/steps/step2.html',
        VALUE: 'step2',
      },
      STEP_3: {
        ORDER: 2,
        TEMPLATE_URL: 'js/components/integrations/retailcrm/steps/step3.html',
        VALUE: 'step3',
      },
    };
  }
})();

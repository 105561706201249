import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MessageModel } from '@http/message/message.model';
import { MessageDirectoryModel } from '@http/message-directory/message-directory.model';
import { DirectoryEditorModalComponent } from '@panel/app/pages/auto-messages/modals/shared/directory-editor/directory-editor-modal.component';
import { directoriesStore$ } from '@panel/app/pages/auto-messages/stores/directories.store';
import { ModalHelperService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { ACTIONS_ON_DIRECTORY } from '@panel/app-old/shared/modals/directory-editor/directory-editor.constants';

@Component({
  selector: 'cq-auto-message-set-directory-modal',
  templateUrl: './auto-message-set-directory-modal.component.html',
  styleUrls: ['./auto-message-set-directory-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageSetDirectoryModalComponent implements OnInit {
  @Input()
  modalWindowParams: any;

  @ViewChild(NgSelectComponent, { static: false })
  ngSelectComponent?: NgSelectComponent;

  protected isAllSetDirectory: boolean = false;
  protected isApiRequestPerformed: boolean = false;
  protected directoriesStore$ = directoriesStore$.pipe(filter((dir: any) => dir.id !== 'all'));

  protected setDirectoryForm = this.fb.group({
    directory: this.fb.control(null, [Validators.required]),
  });

  constructor(
    protected readonly fb: FormBuilder,
    protected readonly messageDirectoryModel: MessageDirectoryModel,
    protected readonly messageModel: MessageModel,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.init();

    this.setDirectoryForm.controls.directory.setValue(
      this.modalWindowParams.messageDirectory ? this.modalWindowParams.messageDirectory.id : null,
      { emitEvent: false },
    );
    this.setDirectoryForm.controls.directory.valueChanges.subscribe((value) => {
      this.modalWindowParams.messageDirectory = {
        ...this.modalWindowParams.messageDirectory,
        id: value,
      };
    });
  }

  init(): void {
    // если нет массива сообщений, значит перемещаем все сообщения по фильтрам
    if (this.modalWindowParams.messageIds === undefined) {
      this.isAllSetDirectory = true;
    }
  }

  /**
   * Закрытие модалки с перемещением сообщений
   *
   * @param isValid
   */
  confirm(): void {
    this.isApiRequestPerformed = true;
    let executedFunction: any;

    if (this.isAllSetDirectory) {
      executedFunction = () => {
        return this.messageModel.setDirectory(
          this.modalWindowParams.currentApp.id,
          this.modalWindowParams.messageDirectory.id,
          '',
          this.modalWindowParams.currentStatus,
          this.modalWindowParams.currentMessageType,
          this.modalWindowParams.currentDirectoryId,
        );
      };
    } else {
      executedFunction = () => {
        return this.messageModel.setDirectory(
          this.modalWindowParams.currentApp.id,
          this.modalWindowParams.messageDirectory.id,
          this.modalWindowParams.messageIds,
        );
      };
    }

    firstValueFrom(executedFunction())
      .then((response: any) => {
        let dir: any = directoriesStore$
          .getValue()
          .find((dir: any) => dir.id === this.modalWindowParams.messageDirectory.id);

        if (response.updated > 1) {
          this.toastr.success(
            this.translocoService.translate('modals.setMessageDirectory.toastr.messagesMovedSuccess', {
              countUpdate: response.updated,
              newDirectory: dir.prettyName,
            }),
          );
        } else {
          this.toastr.success(
            this.translocoService.translate('modals.setMessageDirectory.toastr.messageMovedSuccess', {
              newDirectory: dir.prettyName,
            }),
          );
        }
        this.ngbActiveModal.close({
          data: response,
          newDirectory: this.modalWindowParams.messageDirectory,
        });
      })
      .finally(() => () => {
        this.isApiRequestPerformed = false;
      });
  }

  /**
   * Открытие модалки для создания папки
   */
  openCreateDirectoryModal(): void {
    this.ngSelectComponent?.close();

    let createDirectoryModal = this.modalHelperService.open(DirectoryEditorModalComponent);

    createDirectoryModal.componentInstance.modalWindowParams = {
      currentApp: this.modalWindowParams.currentApp,
    };

    createDirectoryModal.result
      .then((createdDirectory: any) => {
        if (ACTIONS_ON_DIRECTORY.CREATE === createdDirectory.action) {
          directoriesStore$.next([...directoriesStore$.getValue(), ...[createdDirectory.directory]]);
          this.setDirectoryForm.controls.directory.setValue(createdDirectory.directory.id);
          this.modalWindowParams.messageDirectory = createdDirectory.directory;
        }
      })
      .catch(() => {});
  }
}

<ng-container *transloco="let t; read: 'statusFilterComponent'">
  <button
    class="btn btn-outline-primary btn-group dropdown-toggle conversation-status-button d-flex align-items-center justify-space-between"
    type="button"
    (click)="$event.stopPropagation(); statusFilterDropdown.toggle()"
    [ngbTooltip]="t('tooltip')"
    [disabled]="disabled"
    container="body"
  >
    <span class="text-truncate">{{ t('statuses.abbreviations.' + control.value) }}</span>
    <i class="btn-icon-right cqi-sm cqi-chevron-down"></i>
  </button>
  <div
    ngbDropdown
    #statusFilterDropdown="ngbDropdown"
  >
    <div ngbDropdownMenu>
      <ng-container *ngFor="let statusItem of STATUSES_ARRAY">
        <label
          class="d-flex align-items-center justify-content-between mb-0"
          [disabled]="isStatusItemDisabled(statusItem)"
          ngbDropdownItem
        >
          <input
            class="hidden"
            type="radio"
            [value]="statusItem"
            [formControl]="control"
          />
          <span>{{ t('statuses.' + statusItem) }}</span>
          <i
            *ngIf="statusItem === control.value"
            class="cqi-sm cqi-check"
          ></i>
        </label>
      </ng-container>
    </div>
  </div>
</ng-container>

(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('HeaderController', HeaderController);

  function HeaderController($state) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.autoEventGroup = $state.params.autoEventGroup;
    }
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { UserTag } from '@http/user/types/user.type';

/** Компонент тега пользователя */
@Component({
  selector: 'cq-user-tag[tag]',
  templateUrl: './user-tag.component.html',
  styleUrls: ['./user-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTagComponent {
  /** Можно ли выделить тег */
  @Input()
  readonly canActivate: boolean = false;

  /** Можно ли удалить тег */
  @Input()
  readonly canRemove: boolean = false;

  /** Выделен ли тег */
  @Input()
  readonly isActive: boolean = false;

  /** Включён или исключён тег */
  @Input()
  readonly isContains: boolean = false;

  /** Тег пользователя */
  @Input()
  readonly tag!: UserTag;

  /** Колбэк на включение или исключение тега */
  @Output()
  containsChange: EventEmitter<void> = new EventEmitter<void>();

  /** Колбэк на удаление тега */
  @Output()
  remove: EventEmitter<void> = new EventEmitter<void>();

  /** Колбэк на выбор тега */
  @Output()
  selectChange: EventEmitter<void> = new EventEmitter<void>();

  /** Удалён ли тег */
  get isRemoved(): boolean {
    return !!this.tag.removed;
  }

  /**
   * Клик по кнопке включения-исключения тега
   *
   * @param event Событие клика
   */
  onClickContainsButton(event: MouseEvent): void {
    event.stopPropagation();
    this.containsChange.emit();
  }

  /**
   * Клик по кнопке удаления тега
   *
   * @param event Событие клика
   */
  onClickRemoveButton(event: MouseEvent): void {
    event.stopPropagation();
    this.remove.emit();
  }

  /** Клик по тегу */
  onClickTag(): void {
    if (this.canActivate) {
      this.selectChange.emit();
    }
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

@Injectable()
export class FormDataInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    if (!this.containsFile(request.body)) {
      return next.handle(request);
    }

    const formData = new FormData();
    const body = request.body;

    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        const keyValue = body[key];
        if (keyValue instanceof File) {
          formData.append(key, keyValue);
        } else {
          formData.append(key, typeof keyValue === 'string' ? keyValue : JSON.stringify(keyValue));
        }
      }
    }

    const updatedRequest = request.clone({
      body: formData,
    });

    return next.handle(updatedRequest);
  }

  private containsFile(body: any): body is Record<string, unknown> {
    if (!body || typeof body !== 'object') {
      return false;
    }

    for (const key in body) {
      if (body.hasOwnProperty(key) && body[key] instanceof File) {
        return true;
      }
    }

    return false;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { InputStrComponent } from './input-str.component';

@NgModule({
  declarations: [InputStrComponent],
  imports: [CommonModule, DirectivesModule, FormsModule, SharedModule, TranslocoModule, ReactiveFormsModule],
  exports: [InputStrComponent],
})
export class InputStrModule {}

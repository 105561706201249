<ng-container *transloco="let t">
  <h4 class="font-weight-bold">{{ t('vkFormsCustomEventComponent.heading') }}</h4>
  <div class="margin-bottom-20">{{ t('vkFormsCustomEventComponent.description', { projectName: projectName }) }}</div>
  <div
    class="form-group flex-grow no-flex-basis margin-bottom-10"
    [cqShowError]="control"
  >
    <label for="custom-event-name">{{ t('vkFormsCustomEventComponent.input.label') }}</label>
    <input
      class="form-control"
      [formControl]="control"
      id="custom-event-name"
      placeholder="{{ t('vkFormsCustomEventComponent.input.placeholder') }}"
    />
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">{{
        t('vkFormsCustomEventComponent.input.errors.required')
      }}</cq-validation-message>
      <cq-validation-message errorName="maxlength">{{
        t('vkFormsCustomEventComponent.input.errors.maxlength', { maxLength: CUSTOM_EVENT_TYPE_NAME_MAX_LENGTH })
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>
  <cq-switch-new
    (ngModelChange)="toggleHandler($event)"
    [ngModel]="hasName"
    goal="check"
  >
    <ng-template
      #trueText
      #falseText
    >
      {{ t('vkFormsCustomEventComponent.switchText') }}
    </ng-template>
  </cq-switch-new>
</ng-container>

<ng-container *transloco="let t; read: 'upgradeComponent'">
  <ngb-alert
    class="border-0 px-20 mb-0"
    [type]="'primary'"
    [dismissible]="false"
  >
    <div class="text-body">
      <span>{{ t('title') }}</span>
      <button
        class="btn btn-text-primary text-primary"
        (click)="redirectToPricingPage()"
      >
        {{ t('cta') }}
      </button>
    </div>
  </ngb-alert>
</ng-container>

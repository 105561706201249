<ng-container *transloco="let t">
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="actionForm.controls.keyName"
  >
    <ng-select
      [clearable]="false"
      (change)="onChangeEvent($event)"
      [formControl]="actionForm.controls.keyName"
      [items]="eventTypes"
      [required]="true"
      [trackByFn]="eventSelectTrackFn"
      bindLabel="prettyName"
      bindValue="name"
      placeholder="{{ t('chatBot.botActionEvent.eventSelect.placeholder') }}"
      [cqShowError]="actionForm.controls.keyName"
      [classList]="['ng-touched']"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
      >
        <span class="white-space-normal">{{ item.prettyName }}</span>
      </ng-template>
      <ng-container *ngIf="accessToEventsEventTypesCustom.hasAccess">
        <ng-container *ng-footer-tmp>
          <button
            class="btn btn-secondary btn-block"
            (click)="openCreateNewEventModal()"
            type="button"
          >
            {{ t('chatBot.botActionEvent.eventSelect.actionText') }}
          </button>
        </ng-container>
      </ng-container>
    </ng-select>
    <cq-validation-messages [control]="actionForm.controls.keyName">
      <cq-validation-message errorName="required">
        {{ t('chatBot.botActionEvent.eventSelect.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

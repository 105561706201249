import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { Subject } from 'rxjs';

import { AppService } from '@http/app/services/app.service';
import { BotBranchSelectService } from '@panel/app/pages/chat-bot/content/services/bot-branch-select.service';
import { BlockType, Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: `
    cq-bot-action-next-branch-selector[formControlName][nextBranchOptions],
    cq-bot-action-next-branch-selector[formControl][nextBranchOptions],
    cq-bot-action-next-branch-selector[ngModel][nextBranchOptions],
  `,
  templateUrl: './bot-action-next-branch-selector.component.html',
  styleUrls: ['./bot-action-next-branch-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotActionNextBranchSelectorComponent extends AbstractCVAControl<string> {
  @Input()
  readonly nextBranchOptions: Branch[] = [];

  @Input()
  readonly canSelectActionAsNextBranch: boolean = true;

  @Input()
  readonly canSelectConditionAsNextBranch: boolean = false;

  @Input()
  chatBotType: CHAT_BOT_TYPE | null = null;

  @Input()
  chatBotId: string | null = null;

  @Output()
  readonly addBranchClick: Subject<BlockType> = new Subject();

  @Output()
  readonly branchSelect = new Subject<Branch>();

  readonly control = new GenericFormControl<string>(null, Validators.required);

  @ViewChild(NgSelectComponent)
  boBlockSelect!: NgSelectComponent;

  constructor(
    private readonly botBranchSelectService: BotBranchSelectService,
    @Inject(LOCAL_STORAGE)
    private readonly localStorage: Storage,
    private readonly elementRef: ElementRef,
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly appService: AppService,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }

  branchSelectTrackFn(branch: Branch): Branch['linkId'] {
    return branch.linkId;
  }

  writeValue(val: any) {
    // Стандартное поведение AbstractCVAControl { emitEvent: false } не подходит,
    // так как изменения приходят от обработчиков с канваса, то есть снаружи.
    // Они не вызывают changeDetection, поэтому все child компоненты не узнают об изменении (селектор, validation-messages, etc)
    if (val !== undefined) {
      this.control.setValue(val);
    }
  }

  getBranchByLinkId(linkId: string): Branch {
    const branch = this.nextBranchOptions.find((branch: Branch) => {
      return branch.linkId === linkId;
    });
    if (!branch) {
      throw new Error(`Couldn't find a branch with linkId ${linkId}`);
    }
    return branch;
  }

  emitBranchSelect() {
    const branch = this.getBranchByLinkId(this.control.value);
    this.botBranchSelectService.next(branch);
  }

  trackAddActionClick() {
    this.carrotquestHelper.track('Чат-бот - добавил блок действия', {
      app_id: this.appService.currentAppId,
      chatBotId: this.chatBotId ?? '',
      botType: this.chatBotType,
      source: 'next step',
    });
  }

  trackAddConditionClick() {
    this.carrotquestHelper.track('Чат-бот - добавил условие из настроек блока', {
      app_id: this.appService.currentAppId,
      chatBotId: this.chatBotId ?? '',
      botType: this.chatBotType,
      source: 'next step',
    });
  }

  getIconForBlockType(type: BlockType) {
    switch (type) {
      case 'branch':
        return 'cqi-comment-out-filled';
      case 'condition':
        return 'cqi-split';
      case 'action':
        return 'cqi-flash-filled';
      case 'meeting':
        return 'cqi-calendar';
    }
  }
}

import { Validators } from '@angular/forms';

import { INTEGRATION_NAME_MAX_LENGTH } from '@http/integration/constants/integration.constants';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

import { VkIntegrationGroupExternal, VkIntegrationSettingsExternal } from '../interfaces/vk-integration.interfaces';
import { VkIntegrationFormData, VkSettingsFormData } from '../types/vk-integration.types';
import { VkIntegration } from '../vk-integration';

/** Класс для работы с формой представления VK-интеграции */
export class VkIntegrationForm extends GenericFormGroup<VkIntegrationFormData> {
  constructor(integration: VkIntegration) {
    let controls: Controls<VkIntegrationFormData> = {
      name: new GenericFormControl(integration.name, [
        Validators.required,
        Validators.maxLength(INTEGRATION_NAME_MAX_LENGTH),
      ]),
      settings: new VkSettingsFormGroup(integration.settings),
    };

    super(controls, null, null);
  }
}

/** Класс для работы с формой представления настроек VK-интеграции */
export class VkSettingsFormGroup extends GenericFormGroup<VkSettingsFormData> {
  constructor(settings: VkIntegrationSettingsExternal) {
    let groupForms: VkGroupForm[] = [];
    settings.groups?.forEach((group) => {
      groupForms.push(new VkGroupForm(group));
    });

    const controls: Controls<VkSettingsFormData> = {
      // Контролы для старых интеграций, без OAuth авторизации
      apiToken: new GenericFormControl(settings.apiToken),
      confirmCode: new GenericFormControl(settings.confirmCode),
      token: new GenericFormControl(settings.token),
      // Контролы для новых интеграций с OAuth авторизацией
      groups: new GenericFormArray(groupForms),
      oauthCsrfToken: new GenericFormControl(settings.oauthCsrfToken),
      oauthAccessToken: new GenericFormControl(settings.oauthAccessToken),
    };

    super(controls, null, null);
  }
}

/** Класс для работы с формой представления настроек группы в VK-интеграции */
export class VkGroupForm extends GenericFormGroup<VkIntegrationGroupExternal> {
  constructor(group: VkIntegrationGroupExternal) {
    const controls: Controls<VkIntegrationGroupExternal> = {
      accessToken: new GenericFormControl(group.accessToken),
      callbackServerId: new GenericFormControl(group.callbackServerId),
      confirmationCode: new GenericFormControl(group.confirmationCode),
      description: new GenericFormControl(group.description),
      groupId: new GenericFormControl(group.groupId),
      id: new GenericFormControl(group.id),
      name: new GenericFormControl(group.name),
      screenName: new GenericFormControl(group.screenName),
      subscribed: new GenericFormControl(group.subscribed),
    };

    super(controls, null, null);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ConversationPartPreviewFileComponent } from './conversation-part-preview-file.component';

@NgModule({
  declarations: [ConversationPartPreviewFileComponent],
  exports: [ConversationPartPreviewFileComponent],
  imports: [CommonModule, TranslocoModule],
})
export class ConversationPartPreviewFileModule {}

<ng-container *transloco="let t; read: 'userEventControlComponent'">
  <ng-container *tuiLet="state.properties$ | async as state">
    <div class="mb-10">{{ t('label') }}</div>
    <ng-select
      [formControl]="control"
      [items]="state?.eventTypes || []"
      bindLabel="name"
      [placeholder]="t('select.placeholder')"
    >
      <ng-container *ng-footer-tmp>
        <button
          (click)="openAddEventModal()"
          class="btn btn-secondary btn-block"
        >
          {{ t('select.addEventButton') }}
        </button>
      </ng-container>
    </ng-select>
  </ng-container>
</ng-container>

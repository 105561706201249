import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { UibModalServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { UserCustomsPropsModule } from '@panel/app/partials/user-card/user-customs-props/user-customs-props.module';
import { UserSystemsPropsModule } from '@panel/app/partials/user-card/user-systems-props/user-systems-props.module';

import { SharedModule } from '../../../shared/shared.module';
import { UserPropsComponent } from './user-props.component';

@NgModule({
  declarations: [UserPropsComponent],
  exports: [UserPropsComponent],
  imports: [
    CommonModule,
    UserSystemsPropsModule,
    TranslocoModule,
    SharedModule,
    UserCustomsPropsModule,
    NgbCollapseModule,
    ClipboardModule,
    NgbTooltipModule,
  ],
  providers: [UibModalServiceProvider],
})
export class UserPropsModule {}

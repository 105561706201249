import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TriggerChainParseToInternalExtraData } from '@http/trigger-chain/mappers';
import { TriggerChainTemplateExternal } from '@http/trigger-chain-template/external-types/trigger-chain-template.external.type';
import { TriggerChainTemplate } from '@http/trigger-chain-template/internal-types/trigger-chain-template.internal.type';
import { TriggerChainTemplateMapper } from '@http/trigger-chain-template/mappers';

/**
 * Сервис для работы с шаблонами триггерных цепочек
 */
@Injectable({ providedIn: 'root' })
export class TriggerChainTemplateModel {
  constructor(private http: HttpClient, private triggerChainTemplateMapper: TriggerChainTemplateMapper) {}

  /**
   * Получение шаблона
   */
  public get(templateId: string, extraData: TriggerChainParseToInternalExtraData): Observable<TriggerChainTemplate> {
    return this.http.get<TriggerChainTemplateExternal>(`/trigger_chains/templates/${templateId}`).pipe(
      map((response) => {
        return this.triggerChainTemplateMapper.parseToInternal(response, extraData);
      }),
    );
  }

  /**
   * Получение списка шаблонов
   */
  public getList(extraData: TriggerChainParseToInternalExtraData): Observable<TriggerChainTemplate[]> {
    return this.http.get<TriggerChainTemplateExternal[]>(`/trigger_chains/templates`).pipe(
      map((response) => {
        return response.map((template) => this.triggerChainTemplateMapper.parseToInternal(template, extraData));
      }),
    );
  }
}

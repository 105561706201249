import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';

import { CarrotquestHelperProvider, L10nHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { DownloadDocumentsModule } from '@panel/app/pages/subscription/general/signed/invoices/list/download-documents/download-documents.module';
import { StatusModule } from '@panel/app/pages/subscription/general/signed/invoices/list/status/status.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ListComponent } from './list.component';

@NgModule({
  declarations: [ListComponent],
  exports: [ListComponent],
  imports: [
    CommonModule,
    DownloadDocumentsModule,
    MomentModule,
    NgbTooltipModule,
    SharedModule,
    StatusModule,
    TranslocoModule,
  ],
  providers: [CarrotquestHelperProvider, L10nHelperProvider],
})
export class ListModule {}

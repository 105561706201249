import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AppsComponent } from './apps.component';

@NgModule({
  declarations: [AppsComponent],
  exports: [AppsComponent],
  imports: [CommonModule, FormsModule, TranslocoModule, UIRouterModule, SharedModule],
})
export class AppsModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addTargetBlank',
})
export class AddTargetBlankPipe implements PipeTransform {
  private findLinksToAddTargetBlankRegexp = /<a(.*?)>[\s\S]*?<\/a>/gim;
  private findOldTargetRegexp = /target=".*?"/gim;

  transform(value: string): string {
    if (typeof value === 'string') {
      value = value.replace(this.findLinksToAddTargetBlankRegexp, (entry, match) => {
        // в найденной ссылке ищем другие target, чтобы убрать их (на случай, если пользователь руками в коде задал target=".*")
        const matchWithoutOldTarget = match.replace(this.findOldTargetRegexp, '');
        return entry.replace(match, `${matchWithoutOldTarget} target="_blank"`);
      });
    }
    return value;
  }
}

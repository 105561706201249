import { NgModule } from '@angular/core';

import { AmocrmDataFromModule } from '@panel/app/pages/integrations/content/amocrm/data-from/amocrm-data-from.module';
import { AmocrmDataToContactsModule } from '@panel/app/pages/integrations/content/amocrm/data-to-contacts/amocrm-data-to-contacts.module';
import { AmocrmDataToLeadsModule } from '@panel/app/pages/integrations/content/amocrm/data-to-leads/amocrm-data-to-leads.module';
import { AmocrmEventTriggersForContactsModule } from '@panel/app/pages/integrations/content/amocrm/event-triggers-for-contact/amocrm-event-triggers-for-contacts.module';
import { AmocrmEventTriggersForLeadModule } from '@panel/app/pages/integrations/content/amocrm/event-triggers-for-lead/amocrm-event-triggers-for-lead.module';
import { AmocrmFieldsSelectModule } from '@panel/app/pages/integrations/content/amocrm/fields-select/amocrm-fields-select.module';
import { AmocrmLeadNoteModule } from '@panel/app/pages/integrations/content/amocrm/lead-note/amocrm-lead-note.module';
import { AmocrmLeadTagsModule } from '@panel/app/pages/integrations/content/amocrm/lead-tags/amocrm-lead-tags.module';
import { AmocrmLeadTagsReadonlyModule } from '@panel/app/pages/integrations/content/amocrm/lead-tags-readonly/amocrm-lead-tags-readonly.module';
import { AmocrmModalCreateLeadTagModule } from '@panel/app/pages/integrations/content/amocrm/modal-create-lead-tag/amocrm-modal-create-lead-tag.module';
import { AmocrmModalLeadSettingsModule } from '@panel/app/pages/integrations/content/amocrm/modal-lead-settings/amocrm-modal-lead-settings.module';
import { AmocrmOAuthContainerModule } from '@panel/app/pages/integrations/content/amocrm/o-auth-container/amocrm-o-auth-container.module';
import { AmocrmPipelineModule } from '@panel/app/pages/integrations/content/amocrm/pipeline/amocrm-pipeline.module';
import { AmocrmPropsToContactModule } from '@panel/app/pages/integrations/content/amocrm/props-to-contact/amocrm-props-to-contact.module';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { AmocrmSettingsModule } from '@panel/app/pages/integrations/content/amocrm/settings/amocrm-settings.module';
import { AmocrmWrapperCreateModalModule } from '@panel/app/pages/integrations/content/amocrm/wrapper-create-modal/amocrm-wrapper-create-modal.module';
import { AmocrmWrapperSelectModalModule } from '@panel/app/pages/integrations/content/amocrm/wrapper-select-modal/amocrm-wrapper-select-modal.module';
import { AmocrmWrapperSettingsModule } from '@panel/app/pages/integrations/content/amocrm/wrapper-settings/amocrm-wrapper-settings.module';

@NgModule({
  imports: [
    AmocrmDataFromModule,
    AmocrmDataToContactsModule,
    AmocrmDataToLeadsModule,
    AmocrmEventTriggersForContactsModule,
    AmocrmEventTriggersForLeadModule,
    AmocrmFieldsSelectModule,
    AmocrmLeadNoteModule,
    AmocrmLeadTagsModule,
    AmocrmLeadTagsReadonlyModule,
    AmocrmModalCreateLeadTagModule,
    AmocrmModalLeadSettingsModule,
    AmocrmOAuthContainerModule,
    AmocrmPipelineModule,
    AmocrmPropsToContactModule,
    AmocrmSettingsModule,
    AmocrmWrapperCreateModalModule,
    AmocrmWrapperSelectModalModule,
    AmocrmWrapperSettingsModule,
  ],
  providers: [AmocrmStateService],
})
export class AmocrmModule {}

<ng-container *transloco="let t; read: 'channelEditorComponent'">
  <cq-access-denial-banner
    *ngIf="channel.type === CHANNEL_TYPES.MANUAL && !!channel.id && !accessToMessagesChannels.hasAccess"
    [currentApp]="currentApp"
    [denialReason]="accessToMessagesChannels.denialReason"
  >
  </cq-access-denial-banner>

  <div class="container-fluid limited-container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a uiSref="app.content.conversationsSettings">
            {{ 'channelEditor.heading.breadcrumbs.channels' | transloco }}
          </a>
        </li>
        <li
          *ngIf="channel.id"
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ 'channelEditor.heading.breadcrumbs.channelEditing' | transloco }}
        </li>
        <li
          *ngIf="!channel.id"
          class="breadcrumb-item active"
          aria-current="page"
        >
          {{ 'channelEditor.heading.breadcrumbs.channelCreating' | transloco }}
        </li>
      </ol>
    </nav>
    <div class="card">
      <form
        [formGroup]="channelForm"
        class="card-body"
        id="channelForm"
        novalidate
      >
        <!-- Тип канала -->
        <div class="row">
          <div class="col-3">
            <label>{{ 'channelEditor.channelForm.typeSelect.label' | transloco }}</label>
            <div>{{ 'channelEditor.channelForm.typeSelect.subLabel' | transloco }}</div>
          </div>
          <div class="col-9">
            <div
              class="form-group no-margin-bottom"
              [ngClass]="{ 'has-error': channelForm.controls.type.touched && channelForm.controls.type.invalid }"
            >
              <ng-select
                formControlName="type"
                (change)="refreshNewChannelPriority(); setDefaultPages(); clearApiChannelTypeAlreadyExistsError()"
                [readonly]="!!channel.id"
                [clearable]="false"
              >
                <ng-option
                  *ngFor="let channelType of getFilteredChannelTypes(CHANNEL_TYPES_ARRAY)"
                  [value]="channelType"
                >
                  {{ 'models.channel.types.' + channelType + '.name' | transloco }}
                </ng-option>
                <ng-template
                  ng-option-tmp
                  let-option="item"
                >
                  <div>{{ 'models.channel.types.' + option + '.name' | transloco }}</div>
                  <small class="text-muted">{{ 'models.channel.types.' + option + '.description' | transloco }}</small>
                </ng-template>
              </ng-select>
              <cq-validation-messages [control]="channelForm.controls.type">
                <cq-validation-message errorName="required">
                  {{ 'channelEditor.channelForm.typeSelect.errors.required' | transloco }}
                </cq-validation-message>
                <cq-validation-message errorName="channelTypeAlreadyExists">
                  {{ 'channelEditor.channelForm.typeSelect.errors.channelTypeAlreadyExists' | transloco }}
                </cq-validation-message>
              </cq-validation-messages>
            </div>
            <div
              *ngIf="channelForm.controls.type.value === CHANNEL_TYPES.EMAIL"
              class="margin-top-10"
            >
              <div
                [innerHTML]="
                  'channelEditor.channelForm.typeSelect.emailTypeWarning' | transloco: { projectName: PROJECT_NAME }
                "
              ></div>
              <div class="margin-bottom-10">
                {{ 'channelEditor.channelForm.typeSelect.configureEmailsForwarding' | transloco }}
              </div>
              <input
                class="form-control margin-bottom-10"
                readonly
                [value]="currentApp.forwarding_email"
              />
              <div>
                {{ 'channelEditor.channelForm.typeSelect.afterReceive' | transloco: { projectName: PROJECT_NAME } }}
              </div>
            </div>
            <div
              *ngIf="channelForm.controls.type.value === CHANNEL_TYPES.FACEBOOK"
              class="margin-top-10"
            >
              <span
                [innerHTML]="
                  'channelEditor.channelForm.typeSelect.facebookTypeWarning'
                    | transloco
                      : {
                          integrationTypeName:
                            ('models.integration.types.' + INTEGRATION_TYPES.FACEBOOK + '.name' | transloco)
                        }
                "
              >
              </span>
            </div>
            <div
              *ngIf="channelForm.controls.type.value === CHANNEL_TYPES.INSTAGRAM"
              class="margin-top-10"
            >
              <span>
                {{
                  'channelEditor.channelForm.typeSelect.instagramTypeWarning'
                    | transloco
                      : {
                          integrationTypeName:
                            ('models.integration.types.' + INTEGRATION_TYPES.INSTAGRAM + '.name' | transloco)
                        }
                }}
              </span>
            </div>
            <div
              *ngIf="channelForm.controls.type.value === CHANNEL_TYPES.TELEGRAM"
              class="margin-top-10"
            >
              <span
                [innerHTML]="
                  'channelEditor.channelForm.typeSelect.telegramTypeWarning'
                    | transloco
                      : {
                          integrationTypeName:
                            ('models.integration.types.' + INTEGRATION_TYPES.TELEGRAM + '.name' | transloco)
                        }
                "
              >
              </span>
            </div>
            <div
              *ngIf="channelForm.controls.type.value === CHANNEL_TYPES.VK"
              class="margin-top-10"
            >
              <span
                [innerHTML]="
                  'channelEditor.channelForm.typeSelect.vkTypeWarning'
                    | transloco
                      : {
                          integrationTypeName:
                            ('models.integration.types.' + INTEGRATION_TYPES.VK + '.name' | transloco)
                        }
                "
              >
              </span>
            </div>
          </div>
        </div>
        <hr />

        <!-- Название канала -->
        <div class="row">
          <div class="col-3">
            <label>{{ 'channelEditor.channelForm.nameInput.label' | transloco }}</label>
          </div>
          <div class="col-9">
            <div
              class="form-group no-margin-bottom"
              [cqShowError]="channelForm.controls.name"
            >
              <input
                class="form-control"
                formControlName="name"
                (change)="clearApiChannelNameAlreadyExistsError()"
                [placeholder]="'channelEditor.channelForm.nameInput.placeholder' | transloco"
              />
              <cq-validation-messages [control]="channelForm.controls.name">
                <cq-validation-message errorName="required">
                  {{ 'channelEditor.channelForm.nameInput.errors.required' | transloco }}
                </cq-validation-message>
                <cq-validation-message errorName="maxlength">
                  {{
                    'channelEditor.channelForm.nameInput.errors.maxlength'
                      | transloco: { maxLength: CHANNEL_NAME_MAX_LENGTH }
                  }}
                </cq-validation-message>
                <cq-validation-message errorName="channelNameAlreadyExists">
                  {{ 'channelEditor.channelForm.nameInput.errors.channelNameAlreadyExists' | transloco }}
                </cq-validation-message>
              </cq-validation-messages>
            </div>
          </div>
        </div>
        <hr />

        <!-- Приоритет -->
        <div class="row">
          <div class="col-3">
            <label>{{ 'channelEditor.channelForm.priority.label' | transloco }}</label>
            <div>{{ 'channelEditor.channelForm.priority.subLabel' | transloco }}</div>
          </div>
          <div class="col-9">
            <div
              *ngIf="!channel.id"
              class="form-group no-margin-bottom"
              [cqShowError]="channelForm.controls.isPrior"
            >
              <ng-select
                formControlName="isPrior"
                [clearable]="false"
              >
                <ng-option
                  *ngFor="let newChannelPriority of NEW_CHANNEL_PRIORITY"
                  [value]="newChannelPriority"
                >
                  {{ 'channelEditor.channelForm.priority.isPriorSelect.' + newChannelPriority | transloco }}
                </ng-option>
              </ng-select>
            </div>
            <div
              *ngIf="channel.id"
              class="form-group no-margin-bottom"
            >
              <input
                class="form-control"
                readonly
                [value]="channels.length - channel.priority"
              />
            </div>
          </div>
        </div>
        <hr />

        <div class="row">
          <div class="col-3">
            <label>{{ 'channelEditor.channelForm.avatarSelect.label' | transloco }}</label>
          </div>
          <div class="col-9">
            <cq-channel-icon
              [channel]="channel"
              lg
            ></cq-channel-icon>
          </div>
        </div>
        <hr />

        <!-- Кто имеет доступ к каналу? -->
        <div class="row">
          <div class="col-3">
            <label>{{ 'channelEditor.channelForm.operatorsSelect.label' | transloco }}</label>
            <div>{{ 'channelEditor.channelForm.operatorsSelect.subLabel' | transloco }}</div>
          </div>
          <div class="col-9">
            <div
              class="form-group no-margin-bottom"
              [cqShowError]="channelForm.controls.operators"
            >
              <ng-select
                #ngSelect
                [items]="teamMembers"
                [multiple]="true"
                [searchFn]="searchOperatorFn"
                [clearable]="false"
                bindValue="id"
                [hideSelected]="true"
                placeholder="{{ 'channelEditor.channelForm.operatorsSelect.placeholder' | transloco }}"
                formControlName="operators"
              >
                <ng-template
                  let-clear="clear"
                  let-items="items"
                  ng-multi-label-tmp
                >
                  <div
                    class="ng-value"
                    *ngFor="let item of items"
                  >
                    <button
                      class="btn btn-sm btn-outline-primary flex align-items-center overflow-hidden"
                      type="button"
                    >
                      <div class="flex align-items-center">
                        <img
                          class="match-operator-avatar margin-right-5"
                          [src]="item.avatar"
                        />
                        <span class="margin-right-5">{{ item.name }}</span>
                      </div>
                      <span
                        class="flex"
                        (click)="clear(item)"
                      >
                        <i class="cqi-xs cqi-times ml-5"></i>
                      </span>
                    </button>
                  </div>
                </ng-template>
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                >
                  <img
                    class="match-operator-avatar margin-right-5"
                    [ngSrc]="item.avatar"
                    [width]="20"
                    [height]="20"
                  />
                  <span class="margin-right-5">{{ item.name }}</span>
                </ng-template>
                <ng-container *ng-footer-tmp>
                  <button
                    class="btn btn-secondary btn-block"
                    (click)="addAllOperators(); ngSelect.close()"
                    type="button"
                  >
                    {{ 'channelEditor.channelForm.operatorsSelect.actionText' | transloco }}
                  </button>
                </ng-container>
              </ng-select>
              <cq-validation-messages [control]="channelForm.controls.operators">
                <cq-validation-message errorName="required">
                  {{ 'channelEditor.channelForm.operatorsSelect.errors.required' | transloco }}
                </cq-validation-message>
              </cq-validation-messages>
            </div>
          </div>
        </div>
        <hr *ngIf="isShowDelimiter()" />

        <div
          *ngIf="channel.type === CHANNEL_TYPES.MANUAL"
          class="row"
        >
          <div class="col-3">
            <div>
              <label>{{ 'channelEditor.channelForm.autoSetChannel.label' | transloco }}</label>
            </div>
            <div>{{ 'channelEditor.channelForm.autoSetChannel.subLabel' | transloco }}</div>
          </div>
          <div class="col-9">
            <div
              class="form-control-container margin-bottom-30 padding-right-10 inline-block"
              [cqShowError]="channelForm.controls.autoSet"
            >
              <ng-container *ngIf="accessToMessageChannelAutoSet.hasAccess; else accessDenied">
                <cq-switch-new
                  goal="enable"
                  (change)="trackChangeAutoSet()"
                  [formControl]="channelForm.controls.autoSet"
                >
                  <ng-template #trueText>{{
                    'channelEditor.channelForm.autoSetChannel.switchTexts.enabled' | transloco
                  }}</ng-template>
                  <ng-template #falseText>{{
                    'channelEditor.channelForm.autoSetChannel.switchTexts.disabled' | transloco
                  }}</ng-template>
                </cq-switch-new>
              </ng-container>
              <ng-template #accessDenied>
                <cq-access-denial-popover
                  [currentApp]="currentApp"
                  [denialReason]="accessToMessageChannelAutoSet.denialReason!"
                >
                  <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
                  <cq-switch-new
                    goal="enable"
                    [formControl]="channelForm.controls.autoSet"
                    [disabled]="true"
                  ></cq-switch-new>
                </cq-access-denial-popover>
              </ng-template>

              <cq-validation-messages [control]="channelForm.controls.autoSet">
                <cq-validation-message errorName="autoSet">
                  {{ 'channelEditor.channelForm.autoSetChannel.autoSetPages.autoSetSwitch.errors.autoSet' | transloco }}
                </cq-validation-message>
              </cq-validation-messages>
            </div>

            <div
              *ngIf="channelForm.controls.autoSet.value"
              class="margin-left-20"
            >
              <div class="custom-control custom-checkbox line line-top margin-bottom-10">
                <input
                  class="custom-control-input"
                  id="referrer-enabled"
                  type="checkbox"
                  formControlName="referrerEnabled"
                />
                <label
                  class="custom-control-label h4"
                  for="referrer-enabled"
                  >{{ 'channelEditor.channelForm.autoSetChannel.autoSetPages.autoSetPagesCheckbox' | transloco }}</label
                >
              </div>

              <div
                class="padding-bottom-50 position-relative line unavailable"
                [ngClass]="{ in: channelForm.controls.referrerEnabled.value }"
              >
                <div
                  class="margin-bottom-20"
                  [innerHTML]="'channelEditor.channelForm.autoSetChannel.autoSetPages.description' | transloco"
                ></div>

                <cq-url-filter-configurator
                  [formArray]="channelForm.controls.referrerFilters"
                  [options]="urlFilterConfiguratorOptions"
                ></cq-url-filter-configurator>

                <small class="line-letter">{{
                  'channelEditor.channelForm.autoSetChannel.autoSetPages.and' | transloco
                }}</small>
              </div>

              <div class="line line-bottom form-control-container inline-block margin-bottom-10">
                <div class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input"
                    id="user-filters-enabled"
                    type="checkbox"
                    formControlName="userFiltersEnabled"
                    [ngClass]="{ 'is-invalid': channelForm.controls.userFiltersEnabled.errors?.emptyFilter }"
                  />
                  <label
                    class="custom-control-label h4"
                    for="user-filters-enabled"
                  >
                    {{
                      'channelEditor.channelForm.autoSetChannel.autoSetFilters.autoSetFiltersCheckbox.label' | transloco
                    }}
                  </label>
                  <cq-validation-messages [control]="channelForm.controls.userFiltersEnabled">
                    <cq-validation-message errorName="emptyFilter">
                      {{
                        'channelEditor.channelForm.autoSetChannel.autoSetFilters.autoSetFiltersCheckbox.errors.emptyFilter'
                          | transloco
                      }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
              </div>

              <div
                class="position-relative unavailable"
                [ngClass]="{ in: channelForm.controls.userFiltersEnabled.value }"
              >
                <div>{{ 'channelEditor.channelForm.autoSetChannel.autoSetFilters.description' | transloco }}</div>
                <div class="card-body flex-grow p-0">
                  <div class="flex py-25 bordered-bottom">
                    <div>{{ 'channelEditor.channelForm.autoSetChannel.filterTypeSwitch.label' | transloco }}</div>
                    <cq-switcher
                      class="margin-left-auto"
                      formControlName="selectedFilterType"
                      [leftValue]="false"
                      [rightValue]="true"
                    >
                      <ng-template #rightText>
                        {{ 'channelEditor.channelForm.autoSetChannel.filterTypeSwitch.any' | transloco }}
                      </ng-template>
                      <ng-template #leftText>{{
                        'channelEditor.channelForm.autoSetChannel.filterTypeSwitch.all' | transloco
                      }}</ng-template>
                    </cq-switcher>
                  </div>
                  <cq-property-filters
                    class="py-25"
                    [app]="currentApp"
                    [props]="properties.userProps"
                    [telegramIntegrations]="telegramIntegrations"
                    formControlName="propertyFilters"
                  ></cq-property-filters>
                  <cq-event-filters
                    class="py-25"
                    [app]="currentApp"
                    [events]="properties.eventTypes"
                    formControlName="eventFilters"
                  ></cq-event-filters>
                  <cq-tag-filters
                    class="py-20"
                    [app]="currentApp"
                    [defaultFilters]="channelForm.controls.tagFilters.value"
                    [canRemoveTags]="false"
                    [showRemoved]="true"
                    [tags]="tags"
                    (defaultFiltersChange)="channelForm.controls.tagFilters.setValue($event)"
                  ></cq-tag-filters>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--С каких почтовых адресов происходит переадресация-->
        <div
          *ngIf="channel.type === CHANNEL_TYPES.EMAIL"
          class="row"
        >
          <div class="col-3">
            <label>{{ 'channelEditor.channelForm.emailForwarding.label' | transloco }}</label>
            <div>{{ 'channelEditor.channelForm.emailForwarding.subLabel' | transloco }}</div>
          </div>
          <div class="col-9">
            <div class="btn-group btn-group-toggle margin-bottom-10">
              <label
                class="btn btn-outline-primary"
                [class.active]="!channelForm.controls.emailForwardingEnabled.value"
              >
                <input
                  type="radio"
                  [value]="false"
                  formControlName="emailForwardingEnabled"
                />
                {{ 'channelEditor.channelForm.emailForwarding.emailForwardingRadio.false' | transloco }}
              </label>
              <label
                *ngIf="accessToMessageChannelAutoSet.hasAccess"
                class="btn btn-outline-primary"
                [class.active]="channelForm.controls.emailForwardingEnabled.value"
              >
                <input
                  type="radio"
                  [value]="true"
                  formControlName="emailForwardingEnabled"
                />
                {{ 'channelEditor.channelForm.emailForwarding.emailForwardingRadio.true' | transloco }}
              </label>
              <!-- TODO V3 paywall -->
              <cq-access-denial-popover
                *ngIf="!accessToMessageChannelAutoSet.hasAccess"
                class="btn-group"
                [currentApp]="currentApp"
                [denialReason]="accessToMessageChannelAutoSet.denialReason"
              >
                <label
                  class="btn btn-outline-primary rounded-right"
                  [class.active]="channelForm.controls.emailForwardingEnabled.value"
                >
                  <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
                  {{ 'channelEditor.channelForm.emailForwarding.emailForwardingRadio.true' | transloco }}
                </label>
              </cq-access-denial-popover>
            </div>
            <div *ngIf="!channelForm.controls.emailForwardingEnabled.value">
              <div class="margin-bottom-10">
                {{ 'channelEditor.channelForm.emailForwarding.anyEmails.description' | transloco }}
              </div>
            </div>
            <div *ngIf="channelForm.controls.emailForwardingEnabled.value">
              <div class="margin-bottom-10">
                {{ 'channelEditor.channelForm.emailForwarding.certainEmails.description' | transloco }}
              </div>
              <div
                class="flex flex-nowrap align-items-center margin-bottom-10"
                *ngFor="let emailControl of channelForm.controls.emailFilters.controls; let i = index"
              >
                <div
                  class="form-group flex-grow no-margin-bottom"
                  [cqShowError]="emailControl"
                >
                  <input
                    class="form-control"
                    [formControl]="emailControl"
                    [placeholder]="
                      'channelEditor.channelForm.emailForwarding.certainEmails.emailInput.placeholder' | transloco
                    "
                    type="email"
                  />
                  <cq-validation-messages [control]="emailControl">
                    <cq-validation-message errorName="required">
                      {{
                        'channelEditor.channelForm.emailForwarding.certainEmails.emailInput.errors.required' | transloco
                      }}
                    </cq-validation-message>
                    <cq-validation-message errorName="email">
                      {{
                        'channelEditor.channelForm.emailForwarding.certainEmails.emailInput.errors.email' | transloco
                      }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
                <button
                  *ngIf="channelForm.controls.emailFilters.controls.length > 1"
                  class="btn btn-text-primary margin-left-20"
                  type="button"
                  (click)="removeEmailFilter(i)"
                >
                  <i class="btn-icon cqi-sm cqi-times"></i>
                </button>
              </div>
              <button
                class="btn btn-borderless-primary"
                type="button"
                (click)="addEmailFilterControl()"
              >
                <i class="btn-icon-left cqi-sm cqi-plus"></i>
                {{ 'channelEditor.channelForm.emailForwarding.certainEmails.addEmailButton' | transloco }}
              </button>
            </div>
          </div>
        </div>

        <!--С каких страниц FB диалоги попадают в этот канал-->
        <div
          *ngIf="channel.type === CHANNEL_TYPES.FACEBOOK"
          class="row"
        >
          <div class="col-3">
            <label>{{
              'channelEditor.channelForm.facebookPages.label'
                | transloco
                  : {
                      integrationTypeName:
                        'models.integration.types.' + INTEGRATION_TYPES.FACEBOOK + '.name' | transloco
                    }
            }}</label>
            <div>
              {{
                'channelEditor.channelForm.facebookPages.subLabel'
                  | transloco
                    : {
                        integrationTypeName:
                          'models.integration.types.' + INTEGRATION_TYPES.FACEBOOK + '.name' | transloco
                      }
              }}
            </div>
          </div>
          <div class="col-9">
            <div *ngIf="facebookPages.length === 0">
              <div>
                {{
                  'channelEditor.channelForm.facebookPages.zeroData.description'
                    | transloco
                      : {
                          integrationTypeName:
                            'models.integration.types.' + INTEGRATION_TYPES.FACEBOOK + '.name' | transloco
                        }
                }}
              </div>
              <a
                target="_blank"
                uiSref="app.content.integrations.preview"
                [uiParams]="{ integrationType: INTEGRATION_TYPES.FACEBOOK }"
                >{{ 'channelEditor.channelForm.facebookPages.zeroData.integrationLink.text' | transloco }}</a
              >
            </div>
            <div *ngIf="facebookPages.length > 0">
              <div
                class="custom-control custom-checkbox margin-bottom-5"
                *ngFor="let page of facebookPages; trackBy: trackById"
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  [id]="'facebook-page-' + page.id"
                  [checked]="pageIsChecked(page.id)"
                  (click)="togglePage(page.id)"
                />
                <label
                  class="custom-control-label"
                  [for]="'facebook-page-' + page.id"
                >
                  <span class="d-block">{{ page.name }}</span>
                  <span class="text-muted">{{ page.about }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!--С каких страниц Instagram диалоги попадают в этот канал-->
        <div
          *ngIf="channel.type === CHANNEL_TYPES.INSTAGRAM"
          class="row"
        >
          <div class="col-3">
            <label>{{
              'channelEditor.channelForm.instagramPages.label'
                | transloco
                  : {
                      integrationTypeName:
                        'models.integration.types.' + INTEGRATION_TYPES.INSTAGRAM + '.name' | transloco
                    }
            }}</label>
            <div>
              {{
                'channelEditor.channelForm.instagramPages.subLabel'
                  | transloco
                    : {
                        integrationTypeName:
                          'models.integration.types.' + INTEGRATION_TYPES.INSTAGRAM + '.name' | transloco
                      }
              }}
            </div>
          </div>
          <div class="col-9">
            <div *ngIf="instagramPages.length === 0">
              <div>
                {{
                  'channelEditor.channelForm.instagramPages.zeroData.description'
                    | transloco
                      : {
                          integrationTypeName:
                            ('models.integration.types.' + INTEGRATION_TYPES.INSTAGRAM + '.name' | transloco)
                        }
                }}
              </div>
              <a
                target="_blank"
                uiSref="app.content.integrations.preview"
                [uiParams]="{ integrationType: INTEGRATION_TYPES.INSTAGRAM }"
              >
                {{ 'channelEditor.channelForm.instagramPages.zeroData.integrationLink.text' | transloco }}
              </a>
            </div>
            <div *ngIf="instagramPages.length > 0">
              <div
                class="custom-control custom-checkbox margin-bottom-5"
                *ngFor="let page of instagramPages; trackBy: trackById"
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  [id]="'instagram-page-' + page.id"
                  [checked]="pageIsChecked(page.id)"
                  (click)="togglePage(page.id)"
                />
                <label
                  class="custom-control-label"
                  [for]="'instagram-page-' + page.id"
                >
                  <span class="d-block">{{ page.name }}</span>
                  <span class="text-muted">{{ page.about }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!--С каких сообществ VK диалоги попадают в этот канал-->
        <div
          *ngIf="isShowVkSettings()"
          class="row"
        >
          <div class="col-3">
            <label>{{
              'channelEditor.channelForm.vkGroups.label'
                | transloco
                  : { integrationTypeName: 'models.integration.types.' + INTEGRATION_TYPES.VK + '.name' | transloco }
            }}</label>
            <div>
              {{
                'channelEditor.channelForm.vkGroups.subLabel'
                  | transloco
                    : { integrationTypeName: 'models.integration.types.' + INTEGRATION_TYPES.VK + '.name' | transloco }
              }}
            </div>
          </div>
          <div class="col-9">
            <div *ngIf="vkGroups.length === 0">
              <div>
                {{
                  'channelEditor.channelForm.vkGroups.zeroData.description'
                    | transloco
                      : {
                          integrationTypeName: 'models.integration.types.' + INTEGRATION_TYPES.VK + '.name' | transloco
                        }
                }}
              </div>
              <a
                target="_blank"
                uiSref="app.content.integrations.preview"
                [uiParams]="{ integrationType: INTEGRATION_TYPES.VK }"
                >{{ 'channelEditor.channelForm.vkGroups.zeroData.integrationLink.text' | transloco }}</a
              >
            </div>
            <div *ngIf="vkGroups.length > 0">
              <div
                class="custom-control custom-checkbox margin-bottom-5"
                *ngFor="let group of vkGroups; trackBy: trackById"
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  [id]="'vk-group-' + group.id"
                  [checked]="vkGroupIsChecked(group.id)"
                  (click)="toggleVkGroup(group.id)"
                />
                <label
                  class="custom-control-label"
                  [for]="'vk-group-' + group.id"
                >
                  <span class="d-block">{{ group.name }}</span>
                  <span class="text-muted">{{ group.about }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="channel.type === CHANNEL_TYPES.WHATS_APP"
          class="row"
        >
          <div class="col-3">
            <label>{{
              'channelEditor.channelForm.whatsapp.label'
                | transloco
                  : {
                      integrationTypeName:
                        'models.integration.types.' + INTEGRATION_TYPES.WHATS_APP_EDNA + '.name' | transloco
                    }
            }}</label>
            <div>
              {{
                'channelEditor.channelForm.whatsapp.subLabel'
                  | transloco
                    : {
                        integrationTypeName:
                          'models.integration.types.' + INTEGRATION_TYPES.WHATS_APP_EDNA + '.name' | transloco
                      }
              }}
            </div>
          </div>
          <div class="col-9">
            <div *ngIf="whatsAppIntegrations.length === 0">
              <div>
                {{
                  'channelEditor.channelForm.whatsapp.zeroData.description'
                    | transloco
                      : {
                          integrationTypeName:
                            'models.integration.types.' + INTEGRATION_TYPES.WHATS_APP_EDNA + '.name' | transloco
                        }
                }}
              </div>
              <a
                target="_blank"
                uiSref="app.content.integrations.preview"
                [uiParams]="{ integrationType: INTEGRATION_TYPES.WHATS_APP_EDNA }"
                >{{ 'channelEditor.channelForm.facebookPages.zeroData.integrationLink.text' | transloco }}</a
              >
            </div>
            <div *ngIf="whatsAppIntegrations.length > 0">
              <div
                class="custom-control custom-checkbox margin-bottom-5"
                *ngFor="let integration of whatsAppIntegrations; trackBy: trackById"
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  [id]="'whats-app-integration-' + integration.id"
                  [checked]="integrationWhatsAppIsChecked(integration.settings.accountPhone)"
                  (click)="toggleWhatsAppIntegration(integration.settings.accountPhone)"
                />
                <label
                  class="custom-control-label"
                  [for]="'whats-app-integration-' + integration.id"
                >
                  <span class="d-block">{{ integration.name }}</span>
                  <span class="text-muted">{{
                    'channelEditor.channelForm.whatsapp.accountPhoneLabel'
                      | transloco: { accountPhone: integration.settings.accountPhone }
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="channel.type === CHANNEL_TYPES.TELEGRAM"
          class="row"
        >
          <div class="col-3">
            <label>{{ 'channelEditor.channelForm.telegram.label' | transloco }}</label>
            <div>{{ 'channelEditor.channelForm.telegram.subLabel' | transloco }}</div>
          </div>
          <div class="col-9">
            <div *ngIf="telegramIntegrations.length === 0">
              <div>{{ 'channelEditor.channelForm.telegram.zeroData.description' | transloco }}</div>
              <a
                target="_blank"
                uiSref="app.content.integrations.preview"
                [uiParams]="{ integrationType: INTEGRATION_TYPES.TELEGRAM }"
                >{{ 'channelEditor.channelForm.facebookPages.zeroData.integrationLink.text' | transloco }}</a
              >
            </div>
            <div *ngIf="telegramIntegrations.length > 0">
              <div
                class="custom-control custom-checkbox margin-bottom-5"
                *ngFor="let integration of telegramIntegrations; trackBy: trackById"
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  [id]="'telegram-integration-' + integration.id"
                  [checked]="integrationTelegramIsChecked(integration.id)"
                  (click)="toggleTelegramAppIntegration(integration.id)"
                />
                <label
                  class="custom-control-label"
                  [for]="'telegram-integration-' + integration.id"
                >
                  <span class="d-block">{{ integration.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="text-right">
      <ng-container *ngIf="!channel.id">
        <label
          *ngIf="accessToMessagesChannels.hasAccess"
          class="btn btn-primary"
          (click)="createChannel()"
        >
          {{ 'channelEditor.createChannelButton' | transloco }}
        </label>
        <cq-access-denial-popover
          *ngIf="!accessToMessagesChannels.hasAccess"
          [currentApp]="currentApp"
          [denialReason]="accessToMessagesChannels.denialReason"
          [popoverPlacement]="'top-right'"
        >
          <button class="btn btn-primary">
            <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
            <span>{{ 'channelEditor.createChannelButton' | transloco }}</span>
          </button>
        </cq-access-denial-popover>
      </ng-container>

      <ng-container *ngIf="!!channel.id">
        <button
          class="btn btn-outline-danger margin-right-10"
          (click)="openRemoveChannel()"
        >
          {{ 'general.remove' | transloco }}
        </button>
        <!-- Сохранение канала без ограничений -->
        <button
          type="button"
          class="btn btn-primary"
          (click)="saveChannel()"
        >
          {{ 'general.save' | transloco }}
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>

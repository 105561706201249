/**
 * Интеграции
 */
export enum INTEGRATION_TYPES {
  ALBATO = 'albato',
  ALLOKA = 'alloka',
  AMOCRM = 'amocrm',
  API = 'api',
  BITRIX1C = 'bitrix1c',
  BITRIX24 = 'bitrix24',
  CALENDLY = 'calendly',
  DIALOGFLOW = 'dialogflow',
  ECWID = 'ecwid',
  EMAIL = 'email',
  EMAIL_NOTIFICATION = 'email_notification',
  FACEBOOK = 'facebook',
  FACEBOOK_LEADS = 'facebook_leads',
  GOOGLE_ANALYTICS = 'google_analytics',
  INSALES = 'insales',
  INSTAGRAM = 'instagram',
  JSSCRIPT = 'jsscript',
  LAMBDASCRIPT = 'lambdascript',
  MIXPANEL = 'mixpanel',
  MYTARGET = 'mytarget',
  OMNIDESK = 'omnidesk',
  OPENCART = 'opencart',
  PRESTASHOP = 'prestashop',
  RETAILCRM = 'retailcrm',
  ROISTAT = 'roistat',
  SEGMENT = 'segment',
  SENDSAY = 'sendsay',
  SHOPIFY = 'shopify',
  SLACK = 'slack',
  TELEGRAM = 'telegram',
  USEDESK = 'usedesk',
  VIBER = 'viber',
  VK = 'vk',
  VK_FORMS = 'vk_forms',
  WEBHOOK = 'webhook',
  WHATS_APP_EDNA = 'whatsapp_edna',
  WORDPRESS = 'wordpress',
  YANDEX_DIALOGS = 'yandex_dialogs',
  YANDEX_METRICA = 'yandex_metrica',
  ZAPIER = 'zapier',
  ZENDESK = 'zendesk',
}

/**
 * Интеграции, у которых есть возможность настройки
 */
export const CONFIGURABLE_INTEGRATION_TYPES = [
  INTEGRATION_TYPES.AMOCRM,
  INTEGRATION_TYPES.LAMBDASCRIPT,
  INTEGRATION_TYPES.BITRIX24,
  INTEGRATION_TYPES.CALENDLY,
  INTEGRATION_TYPES.DIALOGFLOW,
  INTEGRATION_TYPES.ECWID,
  INTEGRATION_TYPES.EMAIL_NOTIFICATION,
  INTEGRATION_TYPES.FACEBOOK,
  INTEGRATION_TYPES.FACEBOOK_LEADS,
  INTEGRATION_TYPES.INSALES,
  INTEGRATION_TYPES.INSTAGRAM,
  INTEGRATION_TYPES.JSSCRIPT,
  INTEGRATION_TYPES.MIXPANEL,
  INTEGRATION_TYPES.MYTARGET,
  INTEGRATION_TYPES.OMNIDESK,
  INTEGRATION_TYPES.RETAILCRM,
  INTEGRATION_TYPES.ROISTAT,
  INTEGRATION_TYPES.SEGMENT,
  INTEGRATION_TYPES.SENDSAY,
  INTEGRATION_TYPES.SHOPIFY,
  INTEGRATION_TYPES.SLACK,
  INTEGRATION_TYPES.TELEGRAM,
  INTEGRATION_TYPES.USEDESK,
  INTEGRATION_TYPES.VIBER,
  INTEGRATION_TYPES.VK,
  INTEGRATION_TYPES.VK_FORMS,
  INTEGRATION_TYPES.WHATS_APP_EDNA,
  INTEGRATION_TYPES.WEBHOOK,
  INTEGRATION_TYPES.ZAPIER,
  INTEGRATION_TYPES.ZENDESK,
];

/**
 * Специальные поля интеграций, которые не нужно парсить для использования в frontend или отправки на backend
 *
 * TODO:
 *  - Разнести эту константу по разным интеграциям
 */
export const EXCEPT_PARSE_FIELD = {
  [INTEGRATION_TYPES.ALBATO]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.ALLOKA]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.AMOCRM]: {
    EXTERNAL: [
      'amocrm_leads_props_mapping',
      'amocrm_props_mapping',
      'event',
      'event_props',
      'leads_props_mapping',
      'props_mapping',
      'standard_props',
    ],
    INTERNAL: [
      'amocrmLeadsPropsMapping',
      'amocrmPropsMapping',
      'event',
      'eventProps',
      'leadsPropsMapping',
      'propsMapping',
      'standardProps',
    ],
  },
  [INTEGRATION_TYPES.API]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.LAMBDASCRIPT]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.BITRIX24]: {
    EXTERNAL: [
      'bitrix24_deals_props_mapping',
      'bitrix24_props_mapping',
      'event',
      'event_props',
      'props_mapping',
      'standard_props',
    ],
    INTERNAL: [
      'bitrix24DealsPropsMapping',
      'bitrix24PropsMapping',
      'event',
      'eventProps',
      'propsMapping',
      'standardProps',
    ],
  },
  [INTEGRATION_TYPES.BITRIX1C]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.CALENDLY]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.DIALOGFLOW]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.ECWID]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.EMAIL]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.EMAIL_NOTIFICATION]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.FACEBOOK]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.FACEBOOK_LEADS]: {
    EXTERNAL: ['mapping'],
    INTERNAL: ['mapping'],
  },
  [INTEGRATION_TYPES.GOOGLE_ANALYTICS]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.INSALES]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.INSTAGRAM]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.JSSCRIPT]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.MIXPANEL]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.MYTARGET]: {
    EXTERNAL: ['questions', 'standard_questions'],
    INTERNAL: ['questions', 'standardQuestions'],
  },
  [INTEGRATION_TYPES.OMNIDESK]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.OPENCART]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.PRESTASHOP]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.RETAILCRM]: {
    EXTERNAL: [
      'retailcrm_orders_props_mapping',
      'retailcrm_props_mapping',
      'event',
      'event_props',
      'props_mapping',
      'standard_props',
    ],
    INTERNAL: [
      'retailcrmOrdersPropsMapping',
      'retailcrmPropsMapping',
      'event',
      'eventProps',
      'propsMapping',
      'standardProps',
    ],
  },
  [INTEGRATION_TYPES.ROISTAT]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.SEGMENT]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.SENDSAY]: {
    EXTERNAL: ['props_mapping'],
    INTERNAL: ['propsMapping'],
  },
  [INTEGRATION_TYPES.SHOPIFY]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.SLACK]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.TELEGRAM]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.USEDESK]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.VIBER]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.VK]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.VK_FORMS]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.WEBHOOK]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.WHATS_APP_EDNA]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.WORDPRESS]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.YANDEX_DIALOGS]: {
    EXTERNAL: ['props_mapping'],
    INTERNAL: ['propsMapping'],
  },
  [INTEGRATION_TYPES.YANDEX_METRICA]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
  [INTEGRATION_TYPES.ZAPIER]: {
    EXTERNAL: ['webhooks'],
    INTERNAL: [], // Данные для ZAPIER не сохраняются на клиентской стороне
  },
  [INTEGRATION_TYPES.ZENDESK]: {
    EXTERNAL: [],
    INTERNAL: [],
  },
};

/**
 * Классы интеграций
 */
export enum INTEGRATION_CLASSES {
  // @formatter:off
  ANALYTICS = 'analytics',
  CALL_TRACKING = 'callTracking',
  CMS = 'cms',
  COMMUNICATIONS = 'communications',
  CRM = 'crm',
  HANDMADE = 'handmade',
  OTHER = 'other',
  // @formatter:on
}

/**
 * Упорядоченный массив классов интеграций
 */
export const INTEGRATION_CLASSES_ARRAY = [
  INTEGRATION_CLASSES.COMMUNICATIONS,
  INTEGRATION_CLASSES.CMS,
  INTEGRATION_CLASSES.CRM,
  INTEGRATION_CLASSES.ANALYTICS,
  INTEGRATION_CLASSES.CALL_TRACKING,
  INTEGRATION_CLASSES.OTHER,
  INTEGRATION_CLASSES.HANDMADE,
];

/** Максимальная длина названия интеграции */
export const INTEGRATION_NAME_MAX_LENGTH = 100;

/**
 * Интеграции по классам
 */
export const INTEGRATIONS_BY_CLASS = {
  [INTEGRATION_CLASSES.ANALYTICS]: [
    INTEGRATION_TYPES.GOOGLE_ANALYTICS,
    INTEGRATION_TYPES.MIXPANEL,
    INTEGRATION_TYPES.ROISTAT,
    INTEGRATION_TYPES.YANDEX_METRICA,
  ],

  [INTEGRATION_CLASSES.CALL_TRACKING]: [INTEGRATION_TYPES.ALLOKA],

  [INTEGRATION_CLASSES.CMS]: [
    INTEGRATION_TYPES.BITRIX1C,
    INTEGRATION_TYPES.ECWID,
    INTEGRATION_TYPES.INSALES,
    INTEGRATION_TYPES.OPENCART,
    INTEGRATION_TYPES.PRESTASHOP,
    INTEGRATION_TYPES.SHOPIFY,
    INTEGRATION_TYPES.WORDPRESS,
  ],

  [INTEGRATION_CLASSES.COMMUNICATIONS]: [
    INTEGRATION_TYPES.EMAIL,
    INTEGRATION_TYPES.FACEBOOK,
    INTEGRATION_TYPES.INSTAGRAM,
    INTEGRATION_TYPES.VK,
    INTEGRATION_TYPES.WHATS_APP_EDNA,
    INTEGRATION_TYPES.TELEGRAM,
    INTEGRATION_TYPES.VIBER,
    INTEGRATION_TYPES.SLACK,
    INTEGRATION_TYPES.ZENDESK,
    INTEGRATION_TYPES.OMNIDESK,
    INTEGRATION_TYPES.USEDESK,
    INTEGRATION_TYPES.CALENDLY,
  ],

  [INTEGRATION_CLASSES.CRM]: [
    INTEGRATION_TYPES.AMOCRM,
    INTEGRATION_TYPES.BITRIX24,
    INTEGRATION_TYPES.RETAILCRM,
    INTEGRATION_TYPES.EMAIL_NOTIFICATION,
  ],

  [INTEGRATION_CLASSES.HANDMADE]: [
    INTEGRATION_TYPES.API,
    INTEGRATION_TYPES.DIALOGFLOW,
    INTEGRATION_TYPES.LAMBDASCRIPT,
    INTEGRATION_TYPES.JSSCRIPT,
    INTEGRATION_TYPES.WEBHOOK,
    INTEGRATION_TYPES.ZAPIER,
    INTEGRATION_TYPES.ALBATO,
  ],

  [INTEGRATION_CLASSES.OTHER]: [
    INTEGRATION_TYPES.SEGMENT,
    INTEGRATION_TYPES.FACEBOOK_LEADS,
    INTEGRATION_TYPES.MYTARGET,
    INTEGRATION_TYPES.SENDSAY,
    INTEGRATION_TYPES.VK_FORMS,
  ],
};

/**
 * Интеграции, количество которых ограниченно: 1 интеграция на App
 */
export const INTEGRATIONS_ONE_TO_APP_ARRAY = [INTEGRATION_TYPES.CALENDLY, INTEGRATION_TYPES.SEGMENT];

/**
 * Предупреждения в интеграциях по типу интеграции (на данный момент предупреждений нет)
 *
 * NOTE:
 *  В значение вписывается последний ключ предупреждения в переводе.
 *  Пример: [INTEGRATION_TYPES.VIBER] = ['publicAccountsCreation']
 */
export const INTEGRATIONS_WARNINGS_BY_TYPE: any = {};

/**
 * Цвета карточек интеграций
 */
export const INTEGRATION_COLORS: Record<INTEGRATION_TYPES, string> = {
  [INTEGRATION_TYPES.ALBATO]: '#E17352',
  [INTEGRATION_TYPES.ALLOKA]: '#52BD00',
  [INTEGRATION_TYPES.AMOCRM]: '#339DC7',
  [INTEGRATION_TYPES.API]: '#FFAA00',
  [INTEGRATION_TYPES.LAMBDASCRIPT]: '#F19C4A',
  [INTEGRATION_TYPES.BITRIX24]: '#005893',
  [INTEGRATION_TYPES.BITRIX1C]: '#D91935',
  [INTEGRATION_TYPES.CALENDLY]: '#006BFF',
  [INTEGRATION_TYPES.DIALOGFLOW]: '#EF6C00',
  [INTEGRATION_TYPES.ECWID]: '#000',
  [INTEGRATION_TYPES.EMAIL]: '#5C5CD6',
  [INTEGRATION_TYPES.EMAIL_NOTIFICATION]: '#F48F01',
  [INTEGRATION_TYPES.FACEBOOK]: '#0A7CFF',
  [INTEGRATION_TYPES.FACEBOOK_LEADS]: '#1877F2',
  [INTEGRATION_TYPES.GOOGLE_ANALYTICS]: '#E37400',
  [INTEGRATION_TYPES.INSALES]: '#C01F3C',
  [INTEGRATION_TYPES.INSTAGRAM]: '#AD1F7E',
  [INTEGRATION_TYPES.JSSCRIPT]: '#22252A',
  [INTEGRATION_TYPES.MIXPANEL]: '#7856FF',
  [INTEGRATION_TYPES.MYTARGET]: '#FC2C38',
  [INTEGRATION_TYPES.OMNIDESK]: '#008CFF',
  [INTEGRATION_TYPES.OPENCART]: '#21C0F0',
  [INTEGRATION_TYPES.PRESTASHOP]: '#34219E',
  [INTEGRATION_TYPES.RETAILCRM]: '#0068FF',
  [INTEGRATION_TYPES.ROISTAT]: '#00ADEF',
  [INTEGRATION_TYPES.SEGMENT]: '',
  [INTEGRATION_TYPES.SENDSAY]: '#A02140',
  [INTEGRATION_TYPES.SHOPIFY]: '',
  [INTEGRATION_TYPES.SLACK]: '#4A154B',
  [INTEGRATION_TYPES.TELEGRAM]: '#26A5E4',
  [INTEGRATION_TYPES.USEDESK]: '#FF4060',
  [INTEGRATION_TYPES.VIBER]: '#7360F2',
  [INTEGRATION_TYPES.VK]: '#0077FF',
  [INTEGRATION_TYPES.VK_FORMS]: '#4988D0',
  [INTEGRATION_TYPES.WEBHOOK]: '#C73A63',
  [INTEGRATION_TYPES.WHATS_APP_EDNA]: '#25D366',
  [INTEGRATION_TYPES.WORDPRESS]: '#464342',
  [INTEGRATION_TYPES.YANDEX_DIALOGS]: '',
  [INTEGRATION_TYPES.YANDEX_METRICA]: '#EA4F32',
  [INTEGRATION_TYPES.ZAPIER]: '#FF4A00',
  [INTEGRATION_TYPES.ZENDESK]: '#03363D',
};

/**
 * Ошибки, появляющиеся при проверки валидности автосообщения для отправки нотификаций
 */
export const AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS = {
  MESSAGE_HAS_NO_BUTTONS: 'messageHasNoButtons',
  INVALID_MESSAGE_TYPE: 'invalidMessageType',
};

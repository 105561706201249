import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TgIntegrationModule } from '@panel/app/partials/chat-bot/telegram-bot/integration/tg-integration.module';

import { TgTriggerModule } from './trigger/tg-trigger.module';
import { TgTriggerTypesModule } from './trigger-types/tg-trigger-types.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, TgIntegrationModule, TgTriggerModule, TgTriggerTypesModule],
})
export class TelegramBotModule {}

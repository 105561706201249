import { FederatedPointerEvent } from '@pixi/events/lib/FederatedPointerEvent';
import { Container } from 'pixi.js';

import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { PIXI_INTERACTION_EVENT } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.types';
import {
  BOT_ERROR_COLOR,
  BOT_SUCCESS_COLOR,
  BOT_WHITE_COLOR,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { PixiGeometryService } from '@panel/app/shared/services/pixi/geometry/pixi-geometry.service';
import Timeout = NodeJS.Timeout;

export const SWITCH_WIDTH = 35;
export const SWITCH_HEIGHT = 17;
export const SWITCH_TOGGLER_RADIUS = 6.5;
export const SWITCH_PADDING = 2;

export class SwitchBuildingElement {
  readonly container = new Container();
  clickOnPointerUp: boolean = false;
  private body = PixiGeometryService.renderRectangle(SWITCH_WIDTH, SWITCH_HEIGHT, this.style);
  private toggler = PixiGeometryService.renderCircle(SWITCH_TOGGLER_RADIUS, {
    border: {
      size: 0,
      color: BOT_WHITE_COLOR,
    },
    backgroundColor: BOT_WHITE_COLOR,
  });
  private animationInterval?: Timeout;

  constructor(interactionService: PixiInteractionService) {
    this.container.eventMode = 'static';
    this.container.name = 'switcher';
    this.container.cursor = 'pointer';
    this.renderInitialSwitch();
    this.initListeners(interactionService);
  }

  private _status = false;

  public get status() {
    return this._status;
  }

  public set status(status: boolean) {
    this._status = status;
    this.body.clear();
    PixiGeometryService.renderRectangle(SWITCH_WIDTH, SWITCH_HEIGHT, this.style, this.body);
    this.startTogglerAnimation();
  }

  get width(): number {
    return this.container.width;
  }

  get height(): number {
    return this.container.height;
  }

  get style() {
    if (this._status) {
      return {
        border: {
          size: 0,
          color: BOT_SUCCESS_COLOR,
          radius: SWITCH_HEIGHT / 2,
        },
        backgroundColor: BOT_SUCCESS_COLOR,
      };
    } else {
      return {
        border: {
          size: 0,
          color: BOT_ERROR_COLOR,
          radius: SWITCH_HEIGHT / 2,
        },
        backgroundColor: BOT_ERROR_COLOR,
      };
    }
  }

  /**
   *
   * @private
   */
  private get togglerOnPosition() {
    return {
      x: this.body.width - this.toggler.width - SWITCH_PADDING,
      y: (this.body.height - this.toggler.height) / 2,
    };
  }

  private get togglerOffPosition() {
    return {
      x: SWITCH_PADDING,
      y: (this.body.height - this.toggler.height) / 2,
    };
  }

  private toggle() {
    this.status = !this._status;
  }

  private renderInitialSwitch() {
    this.container.addChild(this.body);
    this.container.addChild(this.toggler);

    this.setTogglerFinalPosition();
  }

  private initListeners(interactionService: PixiInteractionService) {
    this.initialClickListeners(interactionService);
  }

  private initialClickListeners(interactionService: PixiInteractionService) {
    this.container.on('pointerdown', (event: FederatedPointerEvent) => {
      event.stopPropagation(); // чтоб ивент нажатия на свитч не прокидывался на бейдж
      this.clickOnPointerUp = true;
      interactionService.registerPointerDown({ type: PIXI_INTERACTION_EVENT.SWITCHER, instance: this });
    });
    this.container.on('pointerup', () => {
      interactionService.registerPointerUp({ type: PIXI_INTERACTION_EVENT.SWITCHER, instance: this });
      if (this.clickOnPointerUp) {
        this.clickOnPointerUp = false;
        this.toggle();
        interactionService.registerClick({ type: PIXI_INTERACTION_EVENT.SWITCHER, instance: this });
      }
    });
  }

  /**
   * Задачает тогглеру финальную позицию
   * @private
   */
  private setTogglerFinalPosition() {
    const finalPosition = this._status ? this.togglerOnPosition : this.togglerOffPosition;
    this.toggler.position.set(finalPosition.x, finalPosition.y);
  }

  /**
   * Начинает анимацию тоггрела
   * @private
   */
  private startTogglerAnimation() {
    // Отменяем прошлую анимацию
    this.animationInterval && clearInterval(this.animationInterval);

    let pixelsPerFrame = this.getPixelsPerFrame();
    if (!this._status) {
      pixelsPerFrame = 0 - pixelsPerFrame;
    }

    this.animationInterval = setInterval(() => {
      const currentTogglerPosition = this.toggler.position;

      if (
        (this._status && currentTogglerPosition.x >= this.togglerOnPosition.x) ||
        (!this._status && currentTogglerPosition.x <= this.togglerOffPosition.x)
      ) {
        this.animationInterval && clearInterval(this.animationInterval);
        return;
      }

      this.toggler.position.set(currentTogglerPosition.x + pixelsPerFrame, currentTogglerPosition.y);
    }, 1000 / 60);
  }

  /**
   * Число пиклесей на которое надо двигать тоггрел каждй кадр, для достижения 60fps
   * @private
   */
  private getPixelsPerFrame(): number {
    const fps = 60; // Кадров в секунду
    const animationTime = 200; // Время анимации в ms NOTE 200ms взято из CSS
    const timeRelation = 1000 / animationTime;
    const totalFrames = fps / timeRelation; // Итоговое количество кадров для заданного времени анимации
    const pathPx = this.togglerOnPosition.x - this.togglerOffPosition.x; // Сколько пикселей надо пройти тогглеру

    return pathPx / totalFrames;
  }
}

import { HashMap, TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Container, Text } from 'pixi.js';

import { TRIGGER_CHAIN_REACTION_TYPE } from '@http/trigger-chain/trigger-chain.constants';
import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { TextStyleForRender } from '@panel/app/services/canvas/common/render/canvas-render.types';
import { AbsBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.view';
import {
  TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
  TRIGGER_CHAIN_BLOCK_TYPE,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ReactionBlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { AtlasTexture, ElementBuilder } from '@panel/app/services/canvas/tirgger-chain/builders';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

/** View шага "Ожидание реакции" */
export class ReactionView extends AbsBlockView<TRIGGER_CHAIN_BLOCK_TYPE.REACTION> {
  constructor(
    data: ReactionBlockViewData,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    transloco: TranslocoService,
    renderer: Renderer,
  ) {
    super(data, interactiveBlockPartViewFactory, transloco, renderer);
  }

  /** Рендерит контент блок */
  renderContent(): Container {
    let text = CanvasRenderService.renderText(this.t('description'), {
      color: 0x5c6370,
      wordWrapWidth: 252,
    });

    let renderedElements: any[] = [];

    renderedElements.push(text);

    if (this.data.reactionType) {
      renderedElements.push(this.renderReactionTypeEl(this.data.reactionType), ...this.renderInteractiveBlocks());
    } else {
      renderedElements.push(this.renderSelectBlockType());
    }

    return ElementBuilder.wrapElementsInRectangle(renderedElements, {
      placement: {
        flexDirection: 'column',
        marginBetweenRows: 10,
      },
    });
  }

  private renderInteractiveBlocks(): Container[] {
    if (this.interactiveBlockParts.length === 0) {
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.REACTION, this);
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.NO_REACTION, this);
    } else {
      this.interactiveBlockParts.forEach((el) => el.rerender());
    }

    return this.interactiveBlockParts.map((el) => el.graphicContainer);
  }

  getTitleIconCode(): string {
    return UNICODE_ICON['cqi-bell'];
  }
  getTitleIconColorIfDefault(): number {
    return 0x5c5cd6;
  }

  getTitleIconColorIfSelected(): number {
    return 0xffffff;
  }

  getTitleIconBackgroundTextureIfDefault(): AtlasTexture {
    return 'circle-background-white-15';
  }

  getTitleIconBackgroundTextureIfSelected(): AtlasTexture {
    return 'circle-background-primary600-15';
  }

  getTitleIconBorderTextureIfDefault(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfSelected(): AtlasTexture | undefined {
    return undefined;
  }

  getTitleIconBorderTextureIfIncomingConnectionsInvalid(): AtlasTexture {
    return 'circle-border-danger600-15';
  }

  private renderSelectBlockType(): Container {
    let text = this.renderSelectBlockTypeText();

    return ElementBuilder.wrapElementsInRectangle([text], {
      border: {
        sideTextureName: 'border-gray800-272-side',
        innerTextureName: 'border-gray800-272-inner',
      },
      placement: {
        flexDirection: 'column',
        padding: {
          x: 10,
          y: 10,
        },
      },
    });
  }

  private renderSelectBlockTypeText(): Container {
    let text: string = this.t('placeholder');
    let style: TextStyleForRender = {
      color: HEX_COLOR.GRAY_650,
      wordWrapWidth: TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX,
    };

    return CanvasRenderService.renderText(text, style);
  }

  private t(key: string, params?: HashMap): string {
    return this.transloco.translate(`triggerChainBlock.views.reaction.${key}`, params);
  }

  private getReactionTypeEl(type: TRIGGER_CHAIN_REACTION_TYPE): Container {
    let typeName = this.transloco.translate(`triggerChainBlock.views.reaction.reactionType.${type}`);

    switch (type) {
      case TRIGGER_CHAIN_REACTION_TYPE.CLICKED:
      case TRIGGER_CHAIN_REACTION_TYPE.READ:
      case TRIGGER_CHAIN_REACTION_TYPE.REPLIED:
        return CanvasRenderService.renderText(typeName, {
          wordWrap: false,
        });
      case TRIGGER_CHAIN_REACTION_TYPE.EVENT_TYPE:
        if (!this.data.eventName) {
          return CanvasRenderService.renderText(typeName, {
            wordWrap: false,
          });
        }

        return CanvasRenderService.renderText(this.data.eventName, {
          wordWrap: true,
          wordWrapWidth: 232,
        });
    }
  }

  private getReactionTypeIcon(type: TRIGGER_CHAIN_REACTION_TYPE): Text {
    let icon;

    switch (type) {
      case TRIGGER_CHAIN_REACTION_TYPE.READ:
        icon = UNICODE_ICON['cqi-check-read'];
        break;
      case TRIGGER_CHAIN_REACTION_TYPE.CLICKED:
        icon = UNICODE_ICON['cqi-link'];
        break;
      case TRIGGER_CHAIN_REACTION_TYPE.REPLIED:
        icon = UNICODE_ICON['cqi-arrow-from-top-to-right'];
        break;
      case TRIGGER_CHAIN_REACTION_TYPE.EVENT_TYPE:
        icon = UNICODE_ICON['cqi-code'];
    }

    return CanvasRenderService.renderIcon(icon);
  }

  private renderReactionTypeEl(reactionType: TRIGGER_CHAIN_REACTION_TYPE) {
    const icon = this.getReactionTypeIcon(reactionType);
    const text = this.getReactionTypeEl(reactionType);

    return ElementBuilder.wrapElementsInRectangle([icon, text], {
      border: {
        sideTextureName: 'border-gray800-272-side',
        innerTextureName: 'border-gray800-272-inner',
      },
      placement: {
        flexDirection: 'row',
        marginBetweenCols: 5,
        padding: {
          x: 10,
          y: 10,
        },
      },
    });
  }
}

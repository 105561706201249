import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DataSourceModule } from '@panel/app/partials/modals/conversation-part-ai-content-modal/data-source/data-source.module';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { ConversationPartAiContentModalComponent } from './conversation-part-ai-content-modal.component';

@NgModule({
  declarations: [ConversationPartAiContentModalComponent],
  imports: [CommonModule, DataSourceModule, TranslocoModule, LoaderModule],
  providers: [NgbActiveModal],
})
export class ConversationPartAiContentModalModule {}

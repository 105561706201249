import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';

import { MessageWebhookParam } from '@http/message-part/message-part.types';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';

@Component({
  selector: 'cq-event-props-editor',
  templateUrl: './event-props-editor.component.html',
  styleUrls: ['./event-props-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPropsEditorComponent extends AbsCVAFormArrayBasedComponent<MessageWebhookParam, FormControl> {
  readonly control: FormArray<FormControl<MessageWebhookParam>> = this.fb.array<FormControl<MessageWebhookParam>>([]);

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  addHeader() {
    this.control.push(this.getNewControl());
  }

  deleteProp(index: number): void {
    this.control.removeAt(index);
  }

  getNewControl(val?: MessageWebhookParam): FormControl {
    if (!val) {
      val = { key: '', value: '' };
    }
    return this.fb.control<MessageWebhookParam>(val, {
      nonNullable: true,
    });
  }
}

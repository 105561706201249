import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationService } from '@http/integration/services/integration.service';
import { IntegrationChatBotsTriggerBlocksModule } from '@panel/app/pages/integrations/partials/integration-chat-bots-trigger-blocks/integration-chat-bots-trigger-blocks.module';

import { AmocrmChatBotsForLeadComponent } from './amocrm-chat-bots-for-lead.component';

@NgModule({
  declarations: [AmocrmChatBotsForLeadComponent],
  exports: [AmocrmChatBotsForLeadComponent],
  imports: [CommonModule, IntegrationChatBotsTriggerBlocksModule, NgbCollapseModule, TranslocoModule],
  providers: [IntegrationService],
})
export class AmocrmChatBotsForLeadModule {}

import { environment } from '@environment';

/**
 * Категории аппов
 */
export enum APP_CATEGORY {
  SAAS = 'saas',
  SHOP = 'shop',
  LANDING = 'landing',
  OTHER = 'other',
}

/** Список задач, которые будет решать наш сервис. Выбираются при регистрации пользователя */
export const APP_TASKS = getAppTasks();

/**
 * Возможные типы собираемых в автоответе контактов
 */
export enum AUTO_REPLY_TYPE {
  EMAIL = 'email',
  PHONE = 'phone',
  NO = 'no',
}

/**
 * Строка для записи в localStorage, указывающая на то, что
 * шаг установки кода на Dashly был пропущен
 */
export const INSTALLATION_MODAL_SKIPPED = 'installation_modal_skipped';

/** Ключ в localStorage, показывающий, что стартергайд был пропущен */
export const STARTER_GUIDE_SKIPPED = 'starter_guide_skipped';

/** Ключ в localStorage, показывающий, что шаг "Настройка социальных сетей" в стартергайде был пропущен */
export const STARTER_GUIDE_SOCIAL_INTEGRATION_SKIPPED = 'starter_guide_social_integration_skipped';

/** Ключ в localStorage, означающий просмотр информации о готовых сценариях чат-ботов */
export const READY_SCENARIOS_FOR_CHAT_BOTS_SHOWING = 'ready_scenarios_for_chat_bots_showing';

/**
 * Позиции кнопки чата
 */
export enum MESSENGER_POSITION {
  LEFT_BOTTOM = 'left_bottom',
  RIGHT_BOTTOM = 'right_bottom',
}

/** Получение списка задач, которые решает наш сервис */
function getAppTasks() {
  const appTasks = {
    RU: {
      TALK_TO_CUSTOMERS: 'talkToCustomers',
      RAISE_PURCHASE_CONVERSION: 'raisePurchaseConversion',
      RAISE_LEAD_CONVERSION: 'raiseLeadConversion',
      SUPPORT_CUSTOMERS: 'supportCustomers',
      OTHER: 'other',
    },
    US: {
      TALK_TO_CUSTOMERS: 'talkToCustomers',
      RAISE_PURCHASE_CONVERSION: 'raisePurchaseConversion',
      RAISE_LEAD_CONVERSION: 'raiseLeadConversion',
      SUPPORT_CUSTOMERS: 'supportCustomers',
      OTHER: 'other',
    },
  };

  return appTasks[environment.country];
}

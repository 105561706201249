import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { EngagementInConversationComponent } from './engagement-in-conversation.component';

@NgModule({
  declarations: [EngagementInConversationComponent],
  exports: [EngagementInConversationComponent],
  imports: [CommonModule, TranslocoModule, NgbAlertModule, AccessDenialPopoverModule, SharedModule],
  providers: [CarrotquestHelperProvider],
})
export class EngagementInConversationModule {}

<ng-container *transloco="let t; read: 'messageEditorSendingHoursComponent'">
  <cq-message-editor-switch
    [heading]="t('heading')"
    [switched]="isEnabled"
    (switchedChange)="isEnabled = $event; isEnabledChange.next($event)"
  >
    <div class="mb-15 text-secondary">
      {{ t('atTime.description', { entityName: entityName }) }}
    </div>
    <div class="flex align-items-center">
      <div class="flex align-items-center mr-10">
        <div
          class="mr-10"
          [cqShowError]="sendTimeFormGroup.controls.sendTimeValueFromH"
        >
          <ng-select
            [formControl]="sendTimeFormGroup.controls.sendTimeValueFromH"
            [required]="true"
            [items]="listTimeH"
            [clearable]="false"
          >
          </ng-select>
        </div>
        <div
          class="mr-5"
          [cqShowError]="sendTimeFormGroup.controls.sendTimeValueFromM"
        >
          <ng-select
            [formControl]="sendTimeFormGroup.controls.sendTimeValueFromM"
            [required]="true"
            [items]="listTimeM"
            [clearable]="false"
          >
          </ng-select>
        </div>
        <span class="mr-5">&mdash;</span>
        <div
          class="mr-10"
          [cqShowError]="sendTimeFormGroup.controls.sendTimeValueToH"
        >
          <ng-select
            [formControl]="sendTimeFormGroup.controls.sendTimeValueToH"
            [required]="true"
            [items]="listTimeH"
            [clearable]="false"
          >
          </ng-select>
        </div>
        <div [cqShowError]="sendTimeFormGroup.controls.sendTimeValueToM">
          <ng-select
            [formControl]="sendTimeFormGroup.controls.sendTimeValueToM"
            [required]="true"
            [items]="listTimeM"
            [clearable]="false"
          >
          </ng-select>
        </div>
      </div>
      <div
        class="text-muted small"
        [innerHTML]="t('atTime.timeZone',  { timeZone })"
      ></div>
    </div>
  </cq-message-editor-switch>
</ng-container>

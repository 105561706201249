import { Injectable } from '@angular/core';

import {
  BILLING_PLAN_IDS,
  V3_PLAN_IDS,
  V2023_LTD_PLAN_IDS,
} from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';

/**
 * Сервис для работы с ID тарифами
 */

@Injectable({ providedIn: 'root' })
export class PlanIdService {
  constructor(private readonly billingInfoModel: BillingInfoModel) {}

  /** Является ли тариф приложения Freemium тарифом */
  isFreemium(): boolean {
    return this.billingInfoModel.planId === BILLING_PLAN_IDS.FREEMIUM;
  }

  /** Является ли тариф приложения LTD 2023 тарифом */
  isLTD2023(): boolean {
    return V2023_LTD_PLAN_IDS.includes(this.billingInfoModel.planId);
  }

  /** Является ли тариф приложения Trial тарифом */
  isTrial(): boolean {
    return this.billingInfoModel.planId === BILLING_PLAN_IDS.FREE_TRIAL;
  }

  /** Является ли тариф приложения V0421 тарифом */
  isV0421(): boolean {
    return this.billingInfoModel.planId.includes('V0421');
  }

  /** Является ли тариф приложения V1021 тарифом */
  isV1021(): boolean {
    return this.billingInfoModel.planId.includes('V1021');
  }

  /** Является ли тариф приложения V1120 тарифом */
  isV1120(): boolean {
    return this.billingInfoModel.planId.includes('11-20');
  }

  /** Является ли тариф приложения V1121 тарифом */
  isV1122(): boolean {
    return V3_PLAN_IDS.some((id) => {
      return this.billingInfoModel.planId.includes(id);
    });
  }

  /** Является ли тариф спецтарифом ковидных времен */
  isCovidFree() {
    return this.billingInfoModel.planId.includes('covid-free');
  }

  /** Является ли тариф спецтарифом */
  isSpecialV1122() {
    const planId = this.billingInfoModel.planId;
    switch (true) {
      case planId === 'marketing-special-bnovo-V1122-60000-12month':
        return true;
      default:
        return false;
    }
  }
}

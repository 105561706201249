import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Subject } from 'rxjs';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { DEFAULT_PRICING_ADDONS } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.constants';
import {
  AddonAvailabilityPerPlan,
  AddonsConfiguration,
  V3_ADDONS,
} from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.types';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';

/**
 * Компонент для работы с аддонами в зависимости от тарифных планов
 */

@Component({
  selector: 'cq-pricing-addons',
  templateUrl: './pricing-addons.component.html',
  styleUrls: ['./pricing-addons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingAddonsComponent {
  @Input()
  currentApp!: App;

  /** Конфигурация аддонов по тарифным планам */
  @Input()
  addonsConfigPerPlan: AddonsConfiguration = cloneDeep(DEFAULT_PRICING_ADDONS[environment.country]);

  /** Предполагаемое количество уникальных пользователей */
  @Input()
  expectedVisitors: number = 1000;

  /** Callback на изменение конфигурации аддонов по тарифным планам */
  @Output()
  public addonsConfigPerPlanChange = new Subject<AddonsConfiguration>();

  /** Аддоны */
  public ADDONS: BILLING_ADD_ONS[] = V3_ADDONS[environment.country];

  @Input()
  needsPersonalPlan = false;

  constructor(
    private readonly billingInfoModel: BillingInfoModel,
    protected readonly planVersionService: PlanVersionService,
  ) {}

  /**
   * Подключен ли аддон
   *
   * @param addon - Аддон, который необходимо проверить на подключение
   */
  isAddonConnected(addon: BILLING_ADD_ONS): boolean {
    /*Т.к. это страница прайсинга свежих тарифов
    будем показывать новые цены относительно его текущих подключенных аддонов
    для этого надо понять какие подключены старые аддоны и показать новую цену с его текущими подключениями*/
    return this.billingInfoModel.hasAddOn(this.billingInfoModel.billingInfo, addon, true);
  }

  /**
   * Получение конфига аддона для
   * @param addon Аддон
   */
  getAddonConfigPerPlan(addon: BILLING_ADD_ONS): AddonAvailabilityPerPlan {
    const addonConfig = this.addonsConfigPerPlan[addon];
    if (!addonConfig) {
      throw Error('There is no addon in configuration');
    }
    return addonConfig;
  }

  /**
   * Обновление конфигурации аддона
   *
   * @param addon - Аддон, конфигурацию которого необходимо обновить
   * @param newConfiguration - Новая конфигурация, на которую необходимо заменить старую конфигурацию
   */
  public updateAddonAvailabilityPerPlanForAddon(
    addon: BILLING_ADD_ONS,
    newConfiguration: AddonAvailabilityPerPlan,
  ): void {
    this.addonsConfigPerPlanChange.next({
      ...this.addonsConfigPerPlan,
      [addon]: newConfiguration,
    });
  }
}

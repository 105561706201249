import { translate } from '@jsverse/transloco';
import { Graphics } from '@pixi/graphics';
import { Text } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { HEX_COLOR, HexColor } from '@panel/app/shared/constants/hex-color.constants';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';

const ZERO = 0;
const SIDE_TEXTURE_HEIGHT = 12;
const SIRE_TEXTURE_ANGLE_LENGTH = 10;
const SIDE_TEXTURE_ANGLE_RADIUS = 10;
// От этой высоты зависит то, во сколько кусков будет собираться один задник для текста.
// Слишком маленьким его делать не стоит, потому что придется много повторений делать.
// Слишком большим, кажется, тоже, потому что тогда из него каждый раз будет собираться новая текстура
const INNER_TEXTURE_HEIGHT = 300;
const BORDER_SIZE = 1;
// Линии должны быть внутрь, чтоб они "накладывались" на блоки 1 к 1.
// Если будет не внутрь, то рамки будут шире, чем блоки и будут косяки
const LINE_ALIGNMENT = 0;

/**
 * RECTANGLE BACKGROUNDS
 */

export function buildSideBaseTexture(width: number, bgColor: HexColor) {
  const graphics = new Graphics();

  graphics
    .beginFill(bgColor)
    .lineStyle(ZERO, undefined, undefined, LINE_ALIGNMENT)
    .moveTo(ZERO, SIRE_TEXTURE_ANGLE_LENGTH)
    .arcTo(ZERO, ZERO, SIRE_TEXTURE_ANGLE_LENGTH, ZERO, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(width - SIRE_TEXTURE_ANGLE_LENGTH, ZERO)
    .arcTo(width, ZERO, width, SIRE_TEXTURE_ANGLE_LENGTH, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(width, SIDE_TEXTURE_HEIGHT)
    .lineTo(ZERO, SIDE_TEXTURE_HEIGHT)
    .lineTo(ZERO, SIRE_TEXTURE_ANGLE_LENGTH)
    .endFill();

  return graphics;
}

export function buildInnerBaseTexture(width: number, bgColor: HexColor) {
  const graphics = new Graphics();

  graphics
    .beginFill(bgColor)
    .lineStyle(ZERO, undefined, undefined, LINE_ALIGNMENT)
    .lineTo(width, ZERO)
    .lineTo(width, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, ZERO)
    .endFill();

  return graphics;
}

/**
 * RECTANGLE BORDERS
 */

export function buildBorderSideBaseTexture(width: number, color: HexColor) {
  const graphics = new Graphics();

  graphics
    .moveTo(ZERO, SIDE_TEXTURE_HEIGHT)
    .lineStyle(BORDER_SIZE, color, undefined, LINE_ALIGNMENT)
    .lineTo(ZERO, SIRE_TEXTURE_ANGLE_LENGTH)
    .arcTo(ZERO, ZERO, SIRE_TEXTURE_ANGLE_LENGTH, ZERO, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(width - SIRE_TEXTURE_ANGLE_LENGTH, ZERO)
    .arcTo(width, ZERO, width, SIRE_TEXTURE_ANGLE_LENGTH, SIDE_TEXTURE_ANGLE_RADIUS)
    .lineTo(width, SIDE_TEXTURE_HEIGHT);

  return graphics;
}

export function buildBorderInnerBaseTexture(width: number, color: HexColor) {
  const graphics = new Graphics();

  graphics
    .lineStyle(BORDER_SIZE, color, undefined, LINE_ALIGNMENT)
    .moveTo(width, ZERO)
    .lineTo(width, INNER_TEXTURE_HEIGHT)
    .moveTo(ZERO, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, ZERO);

  return graphics;
}

/**
 * CONNECTION POINT
 */

const CONNECTION_POINT_RADIUS = 6;
const CONNECTION_POINT_BORDER_WIDTH = 1;
const CONNECTION_POINT_BG_COLOR = HEX_COLOR.WHITE;

export function buildConnectionPointBlank() {
  return CanvasRenderService.renderCircle({
    backgroundColor: CONNECTION_POINT_BG_COLOR,
    borderWidth: CONNECTION_POINT_BORDER_WIDTH,
    borderColor: HEX_COLOR.GRAY_400,
    radius: CONNECTION_POINT_RADIUS,
  });
}

export function buildConnectionPointPlus(color: typeof HEX_COLOR.GRAY_400 | typeof HEX_COLOR.DANGER_400) {
  const plusSize = 7;
  const plus = new Graphics();
  plus
    .lineStyle(1, color, 1)
    .moveTo(plusSize / 2, 0)
    .lineTo(plusSize / 2, plusSize)
    .moveTo(0, plusSize / 2)
    .lineTo(plusSize, plusSize / 2);

  const graphics: Graphics = new Graphics();

  graphics
    .beginFill(CONNECTION_POINT_BG_COLOR)
    .lineStyle(CONNECTION_POINT_BORDER_WIDTH, color, undefined, ZERO)
    .drawCircle(CONNECTION_POINT_RADIUS, CONNECTION_POINT_RADIUS, CONNECTION_POINT_RADIUS)
    .endFill();

  graphics.addChild(plus);

  const backgroundsCenterX = (graphics.width - plus.width) / 2;
  const backgroundsCenterY = (graphics.height - plus.height) / 2;

  plus.position.set(backgroundsCenterX, backgroundsCenterY);

  return graphics;
}

/**
 * EXIT BLOCK
 */

function renderLabel(type: TRIGGER_CHAIN_BLOCK_TYPE.EXIT | TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS): Text {
  return CanvasRenderService.renderText(translate(`triggerChainBlock.views.${type}.label`), {
    color: HEX_COLOR.WHITE,
    wordWrap: false,
  });
}

function renderIcon(type: TRIGGER_CHAIN_BLOCK_TYPE.EXIT | TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS) {
  const iconCode =
    type === TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS ? UNICODE_ICON['cqi-check-circle'] : UNICODE_ICON['cqi-exit'];
  const iconColor = type === TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS ? HEX_COLOR.SUCCESS_400 : HEX_COLOR.DANGER_400;

  const icon = CanvasRenderService.renderIcon(iconCode, {
    color: iconColor,
    fontSize: 15,
    trim: true,
  });

  return CanvasRenderService.renderElementInCircle(icon, {
    radius: 12,
    backgroundColor: HEX_COLOR.WHITE,
  });
}

const EXIT_BLOCK_PADDING = 5;
// 5 это доп отступ от правого края. Сделано так, потому что паддинг левый должен быть 5, а правый 10,
// но инструмент пока не позволяет сделать так
const EXIT_BLOCK_EXTRA_RIGHT_PADDING = 5;
const EXIT_BLOCK_MARGIN_BETWEEN_COLS = 5;

export function buildExitBlock(type: TRIGGER_CHAIN_BLOCK_TYPE.EXIT | TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS) {
  const icon = renderIcon(type);
  const label = renderLabel(type);

  return CanvasRenderService.renderElementsInRectangle([icon, label], {
    borderRadius: 50,
    backgroundColor: type === TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS ? HEX_COLOR.SUCCESS_400 : HEX_COLOR.DANGER_400,
    flexDirection: 'row',
    alignItems: 'center',
    padding: { x: EXIT_BLOCK_PADDING, y: EXIT_BLOCK_PADDING },
    marginBetweenCols: EXIT_BLOCK_MARGIN_BETWEEN_COLS,
    width: label.width + EXIT_BLOCK_MARGIN_BETWEEN_COLS + icon.width + EXIT_BLOCK_EXTRA_RIGHT_PADDING,
  });
}

export function buildExitBlockBorder(type: TRIGGER_CHAIN_BLOCK_TYPE.EXIT | TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS) {
  const label = renderLabel(type);
  const icon = renderIcon(type);

  const borderWidth =
    EXIT_BLOCK_PADDING +
    label.width +
    EXIT_BLOCK_MARGIN_BETWEEN_COLS +
    icon.width +
    EXIT_BLOCK_PADDING +
    EXIT_BLOCK_EXTRA_RIGHT_PADDING;
  const borderHeight = Math.max(label.height, icon.height) + EXIT_BLOCK_PADDING * 2;

  label.destroy({ texture: true, baseTexture: true });
  icon.destroy({ texture: true, baseTexture: true });

  const graphics = new Graphics();

  graphics
    .beginFill(0, 0)
    .lineStyle(1, HEX_COLOR.DANGER_600, undefined, 0)
    .drawRoundedRect(ZERO, ZERO, borderWidth, borderHeight, 50)
    .endFill();

  return graphics;
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { ConversationsStoreService } from '@panel/app/pages/conversations/conversations.store';

@Component({
  selector: 'cq-unanswered-filter',
  templateUrl: './unanswered-filter.component.html',
  styleUrls: ['./unanswered-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnansweredFilterComponent {
  readonly control: FormControl<boolean> = new FormControl<boolean>(false, {
    nonNullable: true,
  });

  /** Этот класс обязательно должен быть на host-элементе, без него вёрстка будет некорректной */
  @HostBinding('class')
  class = 'btn-group-toggle';

  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  @Input()
  set value(value: boolean) {
    this.control.setValue(value, { emitEvent: false });
  }

  @Output()
  valueChanges = this.control.valueChanges.pipe(distinctUntilChanged());

  /** Количество диалогов без ответа */
  protected count$ = this.conversationsStoreService.channelsCounter$.pipe(
    map((counters) => counters?.notRead[PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]!] ?? 0),
  );

  constructor(public readonly conversationsStoreService: ConversationsStoreService) {}
}

import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { InstallScriptModalComponent } from '../../../../app/shared/modals/install-script-modal/install-script-modal.component';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

/**
 * Контроллер настройки автотрекинга
 */
(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('AutoTrackingController', AutoTrackingController);

  function AutoTrackingController(
    $translate,
    $state,
    $uibModal,
    PROJECT_NAME,
    appModel,
    carrotquestHelper,
    featureModel,
    planFeatureAccessService,
    toastr,
    modalHelperService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToTrackMasterJsCode = planFeatureAccessService.getAccess(
        PLAN_FEATURE.TRACK_MASTER_JS_CODE,
        vm.currentApp,
      );

      trackEnterInSetting();

      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.requestIsExecuted = false; // Флаг выполнения запроса
      vm.save = save;
      vm.settingType = $state.params.autoEventGroup; // Тип настройки
      vm.trackingEmails = vm.currentApp.settings.track_emails;
      vm.trackingLeaveSiteAttempt = vm.currentApp.settings.track_leave_site_attempt;
      vm.trackingPhones = vm.currentApp.settings.track_phones;
    }

    /**
     * Сохранение настройки js кода
     */
    function save() {
      if (vm.requestIsExecuted) {
        return;
      }
      vm.requestIsExecuted = true;

      var settings = {
        track_emails: vm.trackingEmails,
        track_phones: vm.trackingPhones,
        track_leave_site_attempt: vm.trackingLeaveSiteAttempt,
      };

      firstValueFrom(appModel.saveSettings(vm.currentApp.id, settings)).then(saveSuccess).finally(saveFinally);

      function saveSuccess() {
        trackAutoTrackSaved();
        vm.currentApp.settings.track_emails = vm.trackingEmails;
        vm.currentApp.settings.track_phones = vm.trackingPhones;
        vm.currentApp.settings.track_leave_site_attempt = vm.trackingLeaveSiteAttempt;
        toastr.success($translate.instant('trackMaster.autoTracking.toastr.saveSuccess'));
      }

      function saveFinally() {
        vm.requestIsExecuted = false;

        if (appModel.isAppBlocked(vm.currentApp)) {
          let modal = modalHelperService.open(InstallScriptModalComponent);

          modal.componentInstance.modalWindowParams = {
            body: $translate.instant('trackMaster.autoTracking.installScriptModal.body'),
            currentApp: vm.currentApp,
            djangoUser: vm.djangoUser,
            heading: $translate.instant('trackMaster.autoTracking.installScriptModal.heading'),
          };
        }
      }
    }

    /**
     * Трек входа на страницу настройки
     */
    function trackEnterInSetting() {
      carrotquestHelper.track('Мастер сбора данных - автотрекинг - открыл');
    }

    /**
     * Трек сохранения автотрекинг
     */
    function trackAutoTrackSaved() {
      carrotquestHelper.track('Мастер сбора данных - автотрекинг - сохранил');
    }
  }
})();

import { HashMap, TranslocoService } from '@jsverse/transloco';
import { Renderer } from '@pixi/core';
import { Container, Graphics, Text } from 'pixi.js';

import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { AbsBlockBaseView } from '@panel/app/services/canvas/tirgger-chain/blocks/block-base.view';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { SendingConditionBlockViewData } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { InteractiveBlockPartViewFactory } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.factory';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';

export class SendingConditionsView extends AbsBlockBaseView<TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION> {
  constructor(
    data: SendingConditionBlockViewData,
    interactiveBlockPartViewFactory: InteractiveBlockPartViewFactory,
    transloco: TranslocoService,
    renderer: Renderer,
  ) {
    super(data, interactiveBlockPartViewFactory, transloco, renderer);
  }

  render(): Graphics {
    const elements: Container[] = [];

    const text = this.renderLabel();
    const firstTrigger = this.renderTriggerText(this.data.firstTriggerName);
    elements.push(text, firstTrigger);

    if (this.data.additionalSendingConditionNumber) {
      elements.push(this.renderTriggerText(`+${this.data.additionalSendingConditionNumber}`));
    }

    const view = this.renderView(elements);

    const connectionPoint = this.renderConnectionPoint(view);

    view.addChild(connectionPoint.graphicContainer);

    this.graphicContainer.addChild(view);

    this.blockOverlayContainer = view;

    return view;
  }

  private renderConnectionPoint(view: Container) {
    // connectionPoint добавляем отдельно, а-ля позиционируем его "абсолютно"
    if (!this.interactiveBlockParts[0]) {
      this.interactiveBlockPartViewFactory.create(INTERACTIVE_BLOCK_PART.SENDING_CONDITION_TRIGGERS, this);
    } else {
      this.interactiveBlockParts[0].rerender();
    }

    const connectionPoint = this.interactiveBlockParts[0];

    connectionPoint.graphicContainer.position.set(
      view.width - connectionPoint.graphicContainer.width / 2,
      (view.height - connectionPoint.graphicContainer.height) / 2,
    );

    return connectionPoint;
  }

  private renderView(elements: Container[]) {
    return CanvasRenderService.renderElementsInRectangle(elements, {
      borderRadius: 50,
      backgroundColor: HEX_COLOR.PRIMARY_600,
      flexDirection: 'row',
      alignItems: 'center',
      padding: { x: 15, y: 7 },
      marginBetweenCols: 5,
    });
  }

  private renderLabel(): Text {
    return CanvasRenderService.renderText(this.t('label'), {
      color: HEX_COLOR.WHITE,
      wordWrap: false,
    });
  }

  private renderTriggerText(text: string): Graphics {
    const textTrigger = CanvasRenderService.renderText(text, { color: HEX_COLOR.GRAY_150, wordWrap: false });
    return CanvasRenderService.renderElementsInRectangle([textTrigger], {
      alignItems: 'center',
      justifyContent: 'start',
      borderRadius: 50,
      backgroundColor: HEX_COLOR.WHITE,
      padding: { x: 10, y: 0 },
    });
  }

  private t(key: string, params?: HashMap): string {
    return this.transloco.translate(`triggerChainBlock.views.sendingConditions.${key}`, params);
  }
}

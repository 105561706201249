import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '../../../shared/shared.module';
import { EventTypeSelectorComponent } from './event-type-selector.component';

@NgModule({
  declarations: [EventTypeSelectorComponent],
  imports: [CommonModule, TranslocoModule, NgSelectModule, SharedModule, ReactiveFormsModule],
  exports: [EventTypeSelectorComponent],
})
export class EventTypeSelectorModule {}

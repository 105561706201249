<ng-container *transloco="let t; read: 'userConsentModalComponent'">
  <div>
    <div class="modal-header">
      <h3 class="modal-title">{{ t('title') }}</h3>
      <button
        class="close"
        (click)="activeModal.dismiss()"
      >
        <i class="cqi-sm cqi-03x cqi-times"></i>
      </button>
    </div>
    <div class="pl-20 pr-20 pb-20">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>{{ t('heads.type') }}</th>
            <th class="padding-right-0">{{ t('heads.contact') }}</th>
            <th>{{ t('heads.date') }}</th>
            <th>{{ t('heads.ip') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let consent of userConsents">
            <td class="shrink-by-content pt-20 pb-20">
              {{ t('types.' + consent.type) }}
            </td>
            <td class="shrink-by-content pt-20 pb-20">{{ consent.contact || '-' }}</td>
            <td class="shrink-by-content pt-20 pb-20">{{ consent.created | amDateFormat: 'LLL' }}</td>
            <td class="shrink-by-content pt-20 pb-20">{{ consent.ip }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>

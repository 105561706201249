(function () {
  'use strict';

  angular
    .module('myApp.modals.teamMembersGroupEditor')
    .constant('ACTIONS_ON_TEAM_MEMBERS_GROUP', getActionsOnTeamMembersGroup());

  /**
   * Действия над группой членов команды
   *
   * @returns {String}
   */
  function getActionsOnTeamMembersGroup() {
    return {
      CREATE: 'create',
      REMOVE: 'remove',
      RENAME: 'rename',
    };
  }
})();

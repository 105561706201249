(function () {
  'use strict';

  angular.module('myApp.userCard', []);

  require('./modal/add-event-modal/add-event-modal.component');
  require('./user-card.component');
  require('./user-events-list/user-events-list.constants');
  require('./user-events-list/user-events-list.component');
  require('./event/user-event.component');
  require('./modal/user-card-modal.component');
  require('./wrapper/user-card-wrapper.component');
})();

/**
 * Директива валидации URI (Читать URI RFC)
 */
(function () {
  'use strict';

  angular.module('myApp.directives.uri').directive('cqUri', cqUri);

  function cqUri($q, validationHelper) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$asyncValidators.uri = uri;

      /**
       * Серверная валидация URI
       *
       * @param modelValue Значение модели для валидации
       * @returns {Promise}
       */
      function uri(modelValue) {
        // если значение модели пустое - валидация не требуется
        if (controller.$isEmpty(modelValue)) {
          return $q.resolve();
        }

        // HACK Роман Е. Input-поле у которого проставлена данная директива, имеет возможность по мимо URL схемы принять DeepLink.
        // HACK DeepLink может быть любым (иметь любое количество символов и любую сигнатуру, которая зависит от разработчиков приложения, чей DeepLink будут указывать в данном input).
        // HACK Пытаясь максимально сохранить валидацию URL, ловим http/https ссылки и валидируем их.
        if (modelValue.search(/^(http|https)/) === -1) {
          return $q.resolve();
        } else {
          return validationHelper.validateAsync(modelValue, 'UrlField');
        }
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'assignmentTypeControlComponent'">
  <h4 class="mb-15">{{ t('heading') }}</h4>
  <div class="custom-control custom-radio mb-10">
    <input
      class="custom-control-input"
      id="radio-1"
      type="radio"
      [value]="1"
      [formControl]="control"
    />
    <label
      class="custom-control-label"
      for="radio-1"
      >{{ t('controls.1.name') }}</label
    >
    <div class="text-secondary">{{ t('controls.1.description') }}</div>
  </div>
  <div class="custom-control custom-radio">
    <input
      class="custom-control-input"
      id="radio-2"
      type="radio"
      [value]="2"
      [formControl]="control"
    />
    <label
      class="custom-control-label"
      for="radio-2"
      >{{ t('controls.2.name') }}</label
    >
    <div class="text-secondary">{{ t('controls.2.description') }}</div>
  </div>
</ng-container>

import { HttpRequest } from '@angular/common/http';

import { SKIP_INTERCEPTOR } from '@panel/app/shared/constants/http.constants';

/**
 * Возвращает true, если нужно скипнуть интерсепторы, false, если не нужно
 *
 * @param request http-запрос
 */
export function needToSkipInterceptors(request: HttpRequest<unknown>) {
  return request.context.has(SKIP_INTERCEPTOR);
}

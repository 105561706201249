import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { BranchActionNextComponent } from '@panel/app/pages/chat-bot/content/branch-editor/actions/next/next.component';
import { BotActionNextBranchSelectorModule } from '@panel/app/pages/chat-bot/content/branch-editor/partials/bot-action-next-branch-selector/bot-action-next-branch-selector.module';

@NgModule({
  declarations: [BranchActionNextComponent],
  imports: [CommonModule, BotActionNextBranchSelectorModule, ReactiveFormsModule],
  exports: [BranchActionNextComponent],
})
export class BranchActionNextModule {}

<ng-container *transloco="let t">
  <div
    class="row"
    *ngIf="selectedEmailRecipient?.length"
  >
    <div class="col-5">
      <strong>{{ t('teamMembersSelectReadonly.heading') }}</strong>
    </div>
    <div class="col-7">
      <div
        class="margin-bottom-5"
        *ngFor="let emailRecipient of selectedEmailRecipient"
      >
        <!-- Пользователь не подписан на уведомления, показывается только при редактировании интгерации -->
        <i
          class="cqi-sm cqi-triangle-exclamation-o text-warning"
          *ngIf="emailRecipient.status === EMAIL_NOTIFICATION_STATUS.NOT_CONFIRMED"
          [ngbTooltip]="notConfirmedEmailTooltip"
          container="body"
        ></i>
        <!-- Пользователь отписался от уведомлений -->
        <i
          class="cqi-sm cqi-triangle-exclamation-o text-danger"
          *ngIf="emailRecipient.status === EMAIL_NOTIFICATION_STATUS.UNSUBSCRIBED"
          [closeDelay]="USER_UNSUBSCRIBED_TOOLTIP_CLOSE_DELAY"
          [ngbTooltip]="userUnsubscribedTooltip"
          container="body"
        ></i>
        <span>{{ emailRecipient.email }}</span>
      </div>
    </div>
  </div>

  <ng-template #notConfirmedEmailTooltip>
    <span>{{ t('teamMembersSelectReadonly.notConfirmedEmailTooltip') }}</span>
  </ng-template>
  <ng-template #userUnsubscribedTooltip>
    <span>{{ t('teamMembersSelectReadonly.userUnsubscribedTooltip.text') }}</span>
    <span
      class="cursor-pointer text-decoration-underline"
      (click)="openChat()"
      >{{ t('teamMembersSelectReadonly.userUnsubscribedTooltip.writeUs') }}</span
    >
  </ng-template>
</ng-container>

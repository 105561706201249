import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { environment } from '@environments/carrot-quest/environment.docker';
import { AnswersOnQuestion, Question } from '@panel/app/pages/questions/questions.type';

@Component({
  selector: 'cq-question[question]',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionComponent {
  @Input()
  question!: Question;

  @Input()
  savedAnswer: AnswersOnQuestion | undefined;

  @Output()
  answerSelect: Subject<AnswersOnQuestion> = new Subject();

  readonly projectName = environment.projectName;
}

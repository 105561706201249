import { IPromise, ui } from 'angular';

import { DjangoUserModel } from '@http/django-user/django-user.model';
import { TriggerChainStepAutoMessage, TriggerChainStepSendingConditions } from '@http/trigger-chain/internal-types';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { TRIGGER_CHAIN_REPEAT_MIN_DELAY } from '@panel/app/services/conditions-sending/conditions-sending.constants';

export class TriggerChainContentEditorModal {
  //@ngInject
  constructor(
    private readonly $uibModal: ui.bootstrap.IModalService,
    private readonly billingInfoModel: BillingInfoModel,
    private readonly djangoUserModel: DjangoUserModel,
  ) {}

  openAutomessageEditorModal(
    autoMessageStep: TriggerChainStepAutoMessage,
    editorInteractions: TriggerChainStepEditorInteractionsService,
    triggerChainState: TriggerChainEditorStore,
  ): IPromise<TriggerChainStepAutoMessage> {
    return this.$uibModal.open({
      backdrop: 'static',
      component: 'cqTriggerChainAutomessageEditor',
      controllerAs: 'vm',
      resolve: {
        currentApp: angular.bind(null, angular.identity, triggerChainState.currentApp$.getValue()!),
        djangoUser: angular.bind(null, angular.identity, this.djangoUserModel.djangoUser),
        billingInfo: angular.bind(null, angular.identity, this.billingInfoModel.billingInfo),
        telegramIntegrations: angular.bind(null, angular.identity, triggerChainState.telegramIntegrations$.getValue()!),
        autoMessageStep: angular.bind(null, angular.identity, autoMessageStep),
        copyClick: editorInteractions.copyClick,
        removeClick: editorInteractions.deleteClick,
      },
      size: 'right',
      windowClass: 'manual-message fade-right',
    }).result;
  }

  openSendingConditionsEditorModal(
    sendingConditionsStep: TriggerChainStepSendingConditions,
    triggerChainState: TriggerChainEditorStore,
  ) {
    return this.$uibModal.open({
      backdrop: 'static',
      component: 'cqTriggerChainSendingConditionsEditor',
      controllerAs: 'vm',
      resolve: {
        sendingConditionsStep: () => sendingConditionsStep,
        autoEvents: () => triggerChainState.autoEvents$.getValue(),
        eventTypes: () => triggerChainState.eventTypes$.getValue(),
        properties: () => triggerChainState.properties$.getValue(),
        currentApp: () => triggerChainState.currentApp$.getValue()!,
        segments: () => triggerChainState.segments$.getValue(),
        tags: () => triggerChainState.tags$.getValue(),
        telegramIntegrations: () => triggerChainState.telegramIntegrations$.getValue()!,
        minRepeatDelay: TRIGGER_CHAIN_REPEAT_MIN_DELAY,
      },
      size: 'right',
      windowClass: 'manual-message fade-right',
    }).result;
  }
}

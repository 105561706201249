import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { translate } from '@jsverse/transloco';
import { combineLatest } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import {
  CUSTOM_PLACEHOLDER_MAX_LENGTH,
  OPTIONS,
} from '@panel/app/pages/chat-bot/content/branch-editor/actions/property-field/bot-custom-placeholder/bot-custom-placeholder.component';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { BotPropertyFieldActionBodyJson } from 'app/http/chat-bot/actions';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

import { ActionValidationExtra, BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

const propertyFieldKeyNameValidators: ValidatorFn[] = [Validators.required];
const propertyFieldNextBranchIdValidators: ValidatorFn[] = [Validators.required];
const propertyFieldBodyJsonPlaceholderValueValidators: ValidatorFn[] = [
  Validators.required,
  Validators.maxLength(CUSTOM_PLACEHOLDER_MAX_LENGTH),
];

export class BotPropertyFieldActionForm extends BaseBotActionForm<BotPropertyFieldActionBodyJson> {
  constructor(
    action: ChatBotAction,
    validationExtra: ActionValidationExtra,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(action, validationExtra, validatorOrOpts, asyncValidator);
    this.initActiveChangeSubscription();
  }

  get allTouchedChanges$() {
    return combineLatest([
      extractTouchedChanges(this.controls.bodyJson).pipe(startWith(this.controls.bodyJson.touched)),
      extractTouchedChanges(this.controls.keyName).pipe(startWith(this.controls.keyName.touched)),
      extractTouchedChanges(this.controls.nextBranchLinkId).pipe(startWith(this.controls.nextBranchLinkId.touched)),
    ]).pipe(map(([t1, t2, t3]) => t1 && t2 && t3));
  }

  getCustomControls(
    action: ChatBotAction<BotPropertyFieldActionBodyJson>,
  ): CustomControlsWithBodyJsonRequired<BotPropertyFieldActionBodyJson> {
    return {
      bodyJson: new GenericFormGroup<BotPropertyFieldActionBodyJson>({
        placeholder: new GenericFormGroup<BotPropertyFieldActionBodyJson['placeholder']>({
          type: new GenericFormControl(action.bodyJson.placeholder?.type ?? OPTIONS.DEFAULT),
          value: new GenericFormControl(
            action.bodyJson.placeholder?.value ?? translate('botCustomPlaceholderComponent.options.default'),
            propertyFieldBodyJsonPlaceholderValueValidators,
          ),
        }),
      }),
      nextBranchLinkId: new GenericFormControl(action.nextBranchLinkId ?? null, propertyFieldNextBranchIdValidators),
      keyName: new GenericFormControl(action.keyName || null, propertyFieldKeyNameValidators),
    };
  }

  /**
   * Инициализация подписки на изменения поля active, для изменния валидации
   * @private
   */
  private initActiveChangeSubscription(): void {
    this.get('active')
      .valueChanges.pipe(startWith(this.get('active').value), takeUntil(this.destroy$))
      .subscribe((active: boolean) => {
        if (active) {
          this.get('keyName').setValidators(propertyFieldKeyNameValidators);
        } else {
          this.get('keyName').clearValidators();
        }
        this.get('keyName').updateValueAndValidity({ emitEvent: false });
      });
  }
}

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { AppService } from '@http/app/services/app.service';
import { LeadBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { AmocrmIntegration } from '@http/integration/integrations/amo/amocrm-integration';
import { AmocrmMappingForm } from '@http/integration/integrations/amo/forms/amocrm-integration.form';
import {
  AmocrmIntegrationExternal,
  AmocrmPipeline,
} from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { IntegrationService } from '@http/integration/services/integration.service';
import { Message } from '@http/message/message.types';
import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';
import { StarterGuideModel } from '@http/starter-guide/starter-guide.model';
import { AMOCRM_ACCOUNT_DOMAIN } from '@panel/app/pages/integrations/content/amocrm/domain-selection/amocrm-domain-selection.constants';
import { AmocrmStateService } from '@panel/app/pages/integrations/content/amocrm/services/state.service';
import { SystemToastService } from '@panel/app/services';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

/**
 * Компонент для настройки интеграции с AmoCRM
 */

@Component({
  selector: 'cq-amocrm-settings[chatBots][currentApp][integrationExternal][isShowedFromModal][triggerMessages]',
  templateUrl: './amocrm-settings.component.html',
  styleUrls: ['./amocrm-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmSettingsComponent implements OnInit {
  /** Список с чат-ботами */
  @Input()
  chatBots!: LeadBot[];

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Текущая или новая интеграция во внешнем формате */
  @Input()
  integrationExternal!: AmocrmIntegrationExternal;

  /** Показываются ли компоненты из модального окна создания интеграции */
  @Input()
  isShowedFromModal!: boolean;

  /** Список с триггерными сообщениями */
  @Input()
  triggerMessages!: Message[];

  /** Выбранный домен по умолчанию */
  accountDomain!: AMOCRM_ACCOUNT_DOMAIN;

  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: AmocrmIntegration;

  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;

  /** Авторизован ли пользователь в amoCRM */
  isAuthorized!: boolean;

  /** Режим редактирования интеграции */
  isEdit!: boolean;

  /** Получение контрола с полями сделки, которые передаются из сделки amoCRM в Carrot quest */
  get controlAmocrmLeadsPropsMapping() {
    return this.integration.form.controls.settings.controls.amocrmLeadsPropsMapping;
  }

  /** Получение контрола со свойствами, которые передаются из контакта amoCRM в проект */
  get controlAmocrmPropsMappingInternal() {
    return this.integration.form.controls.settings.controls.amocrmPropsMappingInternal;
  }

  /** Получение контрола с ID событий, после срабатывания которых данные из проекта передаются в контакт amoCRM */
  get controlEventTypeIds() {
    return this.integration.form.controls.settings.controls.eventTypeIds;
  }

  /** Получение контрола с названием интеграции */
  get controlName() {
    return this.integration.form.controls.name;
  }

  /** Получение контрола для ID воронки, в которую будет добавлена сделка */
  get controlLeadDefaultPipeline(): GenericFormControl<AmocrmPipeline['id']> {
    return this.integration.form.controls.settings.controls.leadDefaultPipeline;
  }

  /** Получение контрола для ID статуса сделки в amoCRM, с которым сделка будет создана */
  get controlLeadDefaultStatus(): GenericFormControl<AmocrmPipeline['statusId']> {
    return this.integration.form.controls.settings.controls.leadDefaultStatus;
  }

  /** Получение контрола с событиями, которые передаются из лида в сделку amoCRM */
  get controlLeadEventTypes(): GenericFormArray<AmocrmMappingForm> {
    return this.integration.form.controls.settings.controls.leadEventTypesMapping;
  }

  /** Получение контрола со свойствами, которые передаются из лида в сделку amoCRM */
  get controlLeadPropsMapping(): GenericFormArray<AmocrmMappingForm> {
    return this.integration.form.controls.settings.controls.leadPropsMapping;
  }

  /** Получение контрола со статусами сделки amoCRM, после изменения которых будет создаваться событие в проекте */
  get controlLeadsEventsMapping() {
    return this.integration.form.controls.settings.controls.leadsEventsMapping;
  }

  /** Получение контрола с ID событий, после срабатывания которых данные создаётся сделка в amoCRM */
  get controlLeadsEventTypeIds() {
    return this.integration.form.controls.settings.controls.leadsEventTypeIds;
  }

  /** Получение контрола с типами событий, которые передаются из лида проекта в контакт amoCRM */
  get controlUserEventsTypesMapping() {
    return this.integration.form.controls.settings.controls.userEventsTypesMapping;
  }

  /** Получение контрола со свойствами, которые передаются из лида проекта в контакт amoCRM */
  get controlUserPropsMapping() {
    return this.integration.form.controls.settings.controls.userPropsMapping;
  }

  constructor(
    private readonly amocrmState: AmocrmStateService,
    private readonly amocrmService: AmocrmService,
    @Inject(CarrotquestHelper) private readonly carrotquestHelper: CarrotquestHelper,
    private readonly appService: AppService,
    private readonly featureModel: FeatureModel,
    @Inject(IntegrationFactory) private readonly integrationFactory: IntegrationFactory,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    private readonly l10nHelper: L10nHelperService,
    private readonly starterGuideModel: StarterGuideModel,
    @Inject(SystemToastService) private readonly systemToastService: SystemToastService,
    @Inject(ToastService) private readonly toastService: ToastService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
    @Inject(UIRouter) private readonly uiRouter: UIRouter,
  ) {}

  ngOnInit(): void {
    this.isEdit = !!this.integrationExternal.id;

    if (this.isEdit) {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.AMOCRM, this.integrationExternal);
    } else {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.AMOCRM);
    }

    this.accountDomain = this.getAmocrmAccountDomainDefaultValue();

    this.isAuthorized = !!this.integrationExternal.settings.oauthAccessToken;

    this.isAuthorized && this.getCustomFields();
  }

  /** Создание интеграции */
  create(): Observable<AmocrmIntegration> {
    return this.integrationService.create(this.appService.currentAppId, this.integration).pipe(
      catchError((err) => {
        this.systemToastService.requestError();
        throw err;
      }),
      tap((integration: AmocrmIntegration) => {
        this.integration = integration;
      }),
    );
  }

  /** Получение выбранного по умолчанию домена */
  private getAmocrmAccountDomainDefaultValue(): AMOCRM_ACCOUNT_DOMAIN {
    let domain = this.l10nHelper.isUsCountry() ? AMOCRM_ACCOUNT_DOMAIN.COM : AMOCRM_ACCOUNT_DOMAIN.RU;

    // Убрать после тестирования com домена на бою (CAR-45590)
    if (!this.featureModel.hasAccess(FEATURES.AMOCRM_DOMAINS)) {
      domain = AMOCRM_ACCOUNT_DOMAIN.RU;
    }

    if (this.integration.settings.amocrmAddress) {
      domain = this.integration.settings.amocrmAddress.includes(AMOCRM_ACCOUNT_DOMAIN.COM)
        ? AMOCRM_ACCOUNT_DOMAIN.COM
        : AMOCRM_ACCOUNT_DOMAIN.RU;
    }

    return domain;
  }

  /**
   * Получение кастомных полей AmoCRM
   */
  private getCustomFields(): void {
    this.amocrmService
      .getLeadFields(this.integration.id!)
      .pipe(take(1))
      .subscribe((response) => {
        this.amocrmState.leadFields$.next(response);
      });

    this.amocrmService
      .getContactFields(this.integration.id!)
      .pipe(take(1))
      .subscribe((response) => {
        this.amocrmState.contactFields$.next(response);
      });
  }

  /** Callback на неудачную авторизацию пользователя в AmoCRM */
  onAuthorizeError(): void {
    this.trackAuthorizeError();
  }

  /** Callback на успешную авторизацию пользователя в AmoCRM */
  onAuthorizeSuccess() {
    this.trackAuthorizeSuccess();

    this.toastService.success(
      this.translocoService.translate('integrations.integration.toasts.created', {
        integrationTypeName: this.translocoService.translate(
          'models.integration.types.' + this.integration.type + '.name',
        ),
      }),
    );

    this.starterGuideModel.setStepIsMade(this.currentApp.id, STARTER_GUIDE_STEPS.LEADS_PASSING).subscribe();
  }

  /** Отправка формы с настройками интеграции */
  onSubmit(): void {
    if (this.integration.form.valid) {
      this.isEdit ? this.save() : this.create();

      this.trackAddedLeadsPropsOrEvents();
    } else {
      this.integration.form.markAllAsTouched();
    }
  }

  /** Сохранение интеграции */
  save(): void {
    this.integrationService.save(this.appService.currentAppId, this.integration).subscribe((integration) => {
      this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));
    });
  }

  /** Трек создания или сохранения интеграции, в которую было добавлено свойства или события лида */
  trackAddedLeadsPropsOrEvents(): void {
    const eventName = 'Интеграции - amocrm - настроена передача данных в сделку';
    const params = {
      'Название интеграции': this.integration.name,
      'ID интеграции': this.integration.id,
    };

    this.carrotquestHelper.track(eventName, params);
  }

  /** Трек неудачной авторизации пользователя в AmoCRM */
  trackAuthorizeError(): void {
    this.carrotquestHelper.track(`Интеграции - ${INTEGRATION_TYPES.AMOCRM} - аккаунт - ошибка`);
  }

  /** Трек успешной авторизации пользователя в AmoCRM */
  trackAuthorizeSuccess(): void {
    this.carrotquestHelper.track(`Интеграции - ${INTEGRATION_TYPES.AMOCRM} - аккаунт - авторизован`);
  }
}

import { translate } from '@jsverse/transloco';
import { Graphics, TextStyle } from 'pixi.js';

import {
  ActionStyle,
  MAX_ACTION_WIDTH,
  SimpleActionABS,
} from '@panel/app/pages/chat-bot/content/views/actions/abstract';
import { getIcon } from '@panel/app/pages/chat-bot/content/views/actions/buttons-property/buttons-property-action.texture';
import { BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';

export class BotButtonsPropertyAction extends SimpleActionABS {
  get translation(): string {
    return translate('classes.action.canvasText.targetActionProperty', { property: this.form.prettyKeyName });
  }

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected style!: Pick<ActionStyle, 'padding'>;

  protected getStyle(): Pick<ActionStyle, 'padding'> {
    return {
      padding: {
        vertical: 0,
        horizontal: 0,
      },
    };
  }

  render(): Graphics | null {
    const element = this.element ?? new Graphics();
    const padding = this.style.padding.horizontal * 2;

    const indentBetween = 10;
    const icon = getIcon(this.pixiApp.renderer);
    const fontSize = 14;
    const textOptions: Partial<TextStyle> = {
      wordWrapWidth: MAX_ACTION_WIDTH - icon.width - indentBetween - padding,
      fontSize,
      lineHeight: fontSize * 1.4,
      fill: BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR,
      align: 'left',
    };
    const text = renderCanvasText(this.translation, textOptions);
    element.addChild(icon, text);
    const contentHeight = Math.max(text.height, icon.height);
    icon.position.set(0, icon.height > contentHeight ? 0 : (contentHeight - icon.height) / 2);
    text.position.set(icon.width + indentBetween, text.height > contentHeight ? 0 : (contentHeight - text.height) / 2);

    element.name = 'property';
    return element;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY_QUERY } from '@taiga-ui/cdk';
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators';

import { UtilsModel } from '@http/utils/utils.model';
import {
  SingleUrlFilterConfiguratorComponent,
  UrlFilter,
} from '@panel/app/partials/url-filter-configurator/single-url-filter-configurator/single-url-filter-configurator.component';
import { DestroyService } from '@panel/app/services';

export enum URL_FILTER_TYPE {
  FULLY_MATCHED = 'urlEq',
  MATCHED_PATH_WITH_PARAMS = 'urlEqWithParams',
  CONTAINS = 'urlContains',
}

export type UrlFilterFormGroup = FormGroup<{
  type: FormControl<UrlFilter['type']>;
  match: FormControl<UrlFilter['match']>;
}>;

export type UrlFilterForm = FormArray<UrlFilterFormGroup>;

export type UrlFilterConfiguratorOptions = {
  // Трекать события внутри компонента
  track?: {
    eventName: string;
    eventParams?: Record<string, any>;
  };
  // Если нужно показать только некоторые опции
  enableOnlyOptions?: URL_FILTER_TYPE[];
  // Нужно, так как добавление некоторые компоненты сами контролируют добавление/удаление фильтров
  addDefaultFilterOnInit?: boolean;
};

@Component({
  selector: 'cq-url-filter-configurator[formArray]',
  templateUrl: './url-filter-configurator.component.html',
  styleUrls: ['./url-filter-configurator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class UrlFilterConfiguratorComponent implements OnInit, OnDestroy {
  @Input()
  formArray!: UntypedFormArray;

  @Input()
  options?: UrlFilterConfiguratorOptions;

  @ViewChildren(NgbPopover)
  ngbPopovers: QueryList<NgbPopover> = EMPTY_QUERY;

  @Input()
  filtersMaxCount = Number.MAX_SAFE_INTEGER;

  /**
   * Флаг, говорящий о том, что formArray пришел пустой, это значит что на destroy надо убрать FormGroup,
   * если клиент не менял ничего в нем
   */
  private formArrayPassedEmpty: boolean = false;

  constructor(
    private readonly utilsService: UtilsModel,
    private readonly destroy$: DestroyService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // Если передали пустой FormArray - добавляем стартовый элемент
    if (this.formArray.length === 0 && (!this.options || this.options?.addDefaultFilterOnInit)) {
      this.addUrlFilter();
      this.formArrayPassedEmpty = true;
    }

    // Когда меняешь что-то в форме снаружи - надо задетектить изменения. Покрывает не все кейсы, можно расширять
    this.formArray.valueChanges
      .pipe(
        startWith(this.formArray.value),
        pairwise(),
        takeUntil(this.destroy$),
        filter(([prev, next]: [UrlFilter[], UrlFilter[]]) => {
          return prev.length !== next.length;
        }),
      )
      .subscribe(() => {
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy() {
    if (this.formArrayPassedEmpty && !this.formArray.touched) {
      this.formArray.removeAt(0);
    }
  }

  dropFilter(index: number) {
    this.formArray.removeAt(index);
  }

  addUrlFilter(type: URL_FILTER_TYPE = URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS): void {
    this.formArray.push(UrlFilterConfiguratorComponent.getNewUrlFilter(this.utilsService, type));
  }

  /**
   * @param utilsService нужен для создания бэкенд валидаторов
   * @param filterType нужен всегда, так как не все типы фильтров могут присутствовать в компоненте (из-за enableOnlyOptions), поэтому дефолт не сделать
   * @param filterMatch
   */
  static getNewUrlFilter(
    utilsService: UtilsModel,
    filterType: UrlFilter['type'],
    filterMatch: UrlFilter['match'] = '',
  ): UrlFilterFormGroup {
    let syncValidators: ValidatorFn[];
    let asyncValidators: AsyncValidatorFn[];

    const { syncValidators: sync, asyncValidators: async } =
      SingleUrlFilterConfiguratorComponent.getValidatorsByFilterType(filterType, utilsService);
    syncValidators = [...sync];
    asyncValidators = [...async];

    return new UntypedFormGroup({
      type: new UntypedFormControl(filterType),
      match: new UntypedFormControl(filterMatch, syncValidators, asyncValidators),
    });
  }
}

import { animate, animation, style, transition, trigger, useAnimation } from '@angular/animations';

export const fadeAnimation = animation(
  [
    style({
      opacity: '{{ start }}',
    }),
    animate(
      '{{ time }} ease',
      style({
        opacity: '{{ end }}',
      }),
    ),
  ],
  {
    params: {
      start: 0,
      end: 1,
      time: '0.3s',
    },
  },
);

export const fadeTrigger = trigger('fade', [
  transition(':enter', [useAnimation(fadeAnimation)]),
  transition(':leave', [
    useAnimation(fadeAnimation, {
      params: {
        start: 1,
        end: 0,
      },
    }),
  ]),
]);

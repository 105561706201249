/**
 * Контроллер модального окна для перемещения шаблона
 *
 * {Object} currentApp Текст тела модального окна
 * {Array} directories Массив с папками
 *
 */
import { SYSTEM_DIRECTORIES } from '../../../../app/http/message-directory/message-directory.constants';
import {
  PSEUDO_TEMPLATE_DIRECTORY_IDS,
  PSEUDO_TEMPLATE_DIRECTORY_TYPES,
} from '../../../../app/http/template-directory/template-directory.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.templateSetDirectory')
    .controller('CqSetTemplateDirectoryModalController', CqSetTemplateDirectoryModalController);

  function CqSetTemplateDirectoryModalController(
    $filter,
    $translate,
    $uibModal,
    toastr,
    ACTIONS_ON_DIRECTORY,
    validationHelper,
    messageTemplateModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.confirm = confirm;
      vm.SYSTEM_DIRECTORIES = SYSTEM_DIRECTORIES;
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса
      vm.openCreateDirectoryModal = openCreateDirectoryModal;
      vm.PSEUDO_TEMPLATE_DIRECTORY_IDS = PSEUDO_TEMPLATE_DIRECTORY_IDS;
      vm.PSEUDO_TEMPLATE_DIRECTORY_TYPES = PSEUDO_TEMPLATE_DIRECTORY_TYPES;
      vm.validationHelper = validationHelper;
      vm.directory = $filter('filter')(
        vm.resolve.directories,
        {
          id:
            (vm.resolve.template.directory && vm.resolve.template.directory.id) ||
            PSEUDO_TEMPLATE_DIRECTORY_IDS[PSEUDO_TEMPLATE_DIRECTORY_TYPES.WITHOUT_DIRECTORY],
        },
        true,
      )[0];
    }

    /**
     * Закрытие модалки с перемещением сообщений
     *
     * @param isValid
     */
    function confirm(isValid) {
      if (isValid) {
        vm.isApiRequestPerformed = true;

        firstValueFrom(messageTemplateModel.changeDirectory(vm.resolve.template.id, vm.directory.id))
          .then(setDirectorySuccess)
          .finally(setDirectoryFinally);
      }

      function setDirectorySuccess() {
        toastr.success(
          $translate.instant('modals.setTemplateDirectory.toastr.messageMovedSuccess', {
            newDirectory: vm.directory.prettyName,
          }),
        );

        vm.modalInstance.close({
          templateId: vm.resolve.template.id,
          newDirectory: vm.directory,
        });
      }

      function setDirectoryFinally() {
        vm.isApiRequestPerformed = false;
      }
    }

    /**
     * Открытие модалки для создания папки
     */
    function openCreateDirectoryModal() {
      var createDirectoryModal = $uibModal.open({
        component: 'cqTemplatesDirectoryEditorModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.resolve.currentApp),
        },
      });

      createDirectoryModal.result.then(createDirectorySuccess);

      function createDirectorySuccess(createdDirectory) {
        if (ACTIONS_ON_DIRECTORY.CREATE === createdDirectory.action) {
          vm.directory = createdDirectory.directory;
          vm.resolve.directories.push(createdDirectory.directory);
        }
      }
    }
  }
})();

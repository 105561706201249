<ng-container *transloco="let t; read: 'createTagComponent'">
  <form (submit)="onSubmit($event)">
    <div class="margin-between-rows-30">
      <div
        class="form-group"
        [cqShowError]="form.controls.text"
      >
        <label
          class="mb-10"
          for="tagText"
          >{{ t('inputs.tagText.label') }}</label
        >
        <input
          id="tagText"
          class="form-control"
          [formControl]="form.controls.text"
          [placeholder]="t('inputs.tagText.placeholder')"
          (keyup)="validate()"
          type="text"
        />
        <cq-validation-messages [control]="form.controls.text">
          <cq-validation-message errorName="required">
            {{ t('inputs.tagText.validators.required') }}
          </cq-validation-message>
          <cq-validation-message errorName="unique">
            {{ t('inputs.tagText.validators.unique') }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>

      <div class="d-flex justify-content-end margin-between-cols-10">
        <button
          class="btn btn-outline-primary"
          (click)="onClickCancelButton()"
          type="button"
        >
          {{ t('buttons.cancel.text') }}
        </button>

        <button
          [disabled]="isDisableCreateTagButton()"
          class="btn btn-primary"
          type="submit"
        >
          {{ t('buttons.create.text') }}
        </button>
      </div>
    </div>
  </form>
</ng-container>

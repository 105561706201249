import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { CarrotquestHelperProvider } from '../../../ajs-upgraded-providers';
import { UserNoteModule } from '../user-note/user-note.module';
import { UserNotesListComponent } from './user-notes-list.component';

@NgModule({
  declarations: [UserNotesListComponent],
  exports: [UserNotesListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    UserNoteModule,
    SharedModule,
    NgbCollapseModule,
  ],
  providers: [CarrotquestHelperProvider],
})
export class UserNotesListModule {}

(function () {
  'use strict';

  require('./manual-user-merge.controller');

  angular.module('myApp.modals.manualUserMerge').component('cqManualUserMerge', cqManualUserMerge());

  function cqManualUserMerge() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp - текущее приложение
        // users - массив пользователей, которые будут мерджиться
      },
      controller: 'CqManualUserMergeModalController',
      controllerAs: 'vm',
      template: require('./manual-user-merge.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AmocrmLeadTag } from '@http/integration/integrations/amo/types/amocrm-integration.types';

@Component({
  selector: 'cq-amocrm-lead-tags-readonly[leadTags]',
  templateUrl: './amocrm-lead-tags-readonly.component.html',
  styleUrls: ['./amocrm-lead-tags-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmLeadTagsReadonlyComponent {
  /** Теги сделки amoCRM */
  @Input()
  leadTags: AmocrmLeadTag[] = [];

  /**
   * Получение отформатированного списка тегов сделки amoCRM
   *
   * NOTE:
   *  Пример: "«Название тега 1», «Название тега 2»..."
   *
   * @param leadTags Теги сделки amoCRM
   */
  getFormattedLeadTags(leadTags: AmocrmLeadTag[]): string {
    return leadTags.map((leadTag) => `«${leadTag}»`).join(', ');
  }
}

import { LocationPartial } from '@environments/types';

export const dockerLocationPartial: LocationPartial = {
  apiEndpoint: 'http://localhost:8002/api',
  awsLambdaScriptsUrl: 'https://lambda-script.carrotquest.io',
  cdnEndpoint: 'http://localhost:8002/cdn',
  cloudPaymentsPublicId: 'pk_ebc352f41bae1c5bff34a99c29b28',
  externalPagesUrl: 'http://localhost:8002/panel/unauthorized',
  firstDialogUrl: 'http://localhost:8002/first-dialog',
  froalaEditorKey: 'gcg1pqkqwrmzB-9E4eb==',
  googleClientId: '44569780138-5ttc4sulbmgb0hupmmvp6sa62embufnk.apps.googleusercontent.com',
  notAvailableFromMobileUrl: 'http://localhost:8002/not-available-from-mobile',
  oauthRedirectUrl: 'https://carrotquest.io/oauthcallback',
  panelUrl: 'http://localhost:8002/panel',
  paymentUrl: 'https://carrotquest.io/paynow',
  previewFrameUrl: 'http://localhost:8002/panel/preview',
  realTimeServicesHost: '//localhost:8002',
  realTimeServicesHostNew: '//localhost:8002',
  recaptchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  stripePaymentsPublicKey:
    'pk_test_51H4lc0EUZ4yc3sIEjmVLyjH1YD9mfapfJMSl4mNXB8RqB4gdrr5iNBwiUOQfYAMl21vxpRWQ2zJowkK6RK2mcJ0O000FrrpjuT',
  useNewRts: false,
  webhooksEndpoint: 'http://localhost:8002/api/hooks',
};

<ng-container *transloco="let t; read: 'emptyComponent'">
  <div class="text-center">
    <img
      class="mb-10"
      src="assets/img/default/subscription/invoices-zero-data.svg"
      width="40"
      height="40"
    />
    <div class="text-secondary mb-5">{{ t('heading') }}</div>
    <small
      class="text-muted"
      [innerHTML]="t('description')"
    ></small>
  </div>
</ng-container>

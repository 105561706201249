import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Addon } from '@http/addon/addon';
import { Billing } from '@http/billing/billing';
import { Invoice } from '@http/invoice/invoice';
import { Plan } from '@http/plan/plan';
import { Quotas } from '@http/quotas/quotas';
import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { ArrayStream } from '@panel/app-old/shared/patterns/array-stream/array-stream';

/** Хранилище данных для подписки */
@Injectable()
export class SubscriptionStore {
  /** Доступные аддоны */
  readonly availableAddons$: ArrayStream<Addon> = new ArrayStream<Addon>([]);

  /** Приложения */
  readonly app$: BehaviorSubject<App | null> = new BehaviorSubject<App | null>(null);

  /** Биллинг */
  readonly billing$: BehaviorSubject<Billing | null> = new BehaviorSubject<Billing | null>(null);

  /** Подключённые аддоны */
  readonly connectedAddons$: ArrayStream<Addon> = new ArrayStream<Addon>([]);

  /** Django пользователь */
  readonly djangoUser$: BehaviorSubject<DjangoUser | null> = new BehaviorSubject<DjangoUser | null>(null);

  /** Счета */
  readonly invoices$: ArrayStream<Invoice> = new ArrayStream<Invoice>([]);

  /** Тарифный план */
  readonly plan$: BehaviorSubject<Plan | null> = new BehaviorSubject<Plan | null>(null);

  /** Квоты*/
  readonly quotas$: BehaviorSubject<Quotas | null> = new BehaviorSubject<Quotas | null>(null);
}

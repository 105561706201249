import { Injectable } from '@angular/core';

import { ConversationTag } from '@panel/app-old/shared/entities/conversation-tag/conversation-tag';
import { ArrayStream } from '@panel/app-old/shared/patterns/array-stream/array-stream';

/** Хранилище для работы с тегами диалогов */
@Injectable()
export class ManageTagsStore {
  /** Теги */
  readonly tags$: ArrayStream<ConversationTag> = new ArrayStream<ConversationTag>([]);
}

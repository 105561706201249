/**
 * Директива, предотвращающая действие по нажатию на Enter
 * Подразумевается, что она должна использоваться в случае, когда не нужно отправлять форму по нажатию на Enter
 * Код взят отсюда https://gist.github.com/rafaell-lycan/f841018c5118c39d8845 Единственное, что изменено - добавлена проверка на TEXTAREA, чтобы в ней вставлялись пустые строки
 */
(function () {
  'use strict';

  angular.module('myApp.directives.preventSubmitOnEnter').directive('cqPreventSubmitOnEnter', cqPreventSubmitOnEnter);

  function cqPreventSubmitOnEnter() {
    return {
      restrict: 'A',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      iElement.bind('keydown', function (event) {
        if (event.which == 13 && event.target.nodeName != 'TEXTAREA') {
          event.preventDefault(); // Doesn't work at all NOTE: этот коммент написал автор, но на самом деле эта строка работает как минимум в хроме и FF
          window.stop(); // Works in all browsers but IE...
          document.execCommand('Stop'); // Works in IE
          return false; // Don't even know why it's here. Does nothing.
        }
      });
    }
  }
})();

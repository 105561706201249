(function () {
  'use strict';

  angular.module('myApp.directives.copyText').controller('cqCopyTextController', cqCopyTextController);

  function cqCopyTextController($document, $scope, $translate, $window, toastr) {
    var vm = this;

    vm.copyText = copyText;

    /**
     * Копирование текста из элемента хранящагося в vm.cqCopyText в буфер обмена
     */
    function copyText() {
      if ($document[0].createRange && $document[0].execCommand) {
        var copyTextElement;
        if (angular.isDefined(vm.elementId)) {
          copyTextElement = $document[0].querySelector(/^\#/.test(vm.elementId) ? vm.elementId : '#' + vm.elementId);
        } else {
          copyTextElement = $document[0].createElement('textarea');
          copyTextElement.value = vm.text;
          copyTextElement.textContent = vm.text;
          $document[0].body.appendChild(copyTextElement);
        }

        var selection = $document[0].getSelection();
        selection.removeAllRanges();

        var range = $document[0].createRange();
        range.selectNode(copyTextElement);
        selection.addRange(range);

        if (copyTextElement.select) {
          copyTextElement.select();
        }

        if (copyTextElement.nodeName == 'INPUT' || copyTextElement.nodeName == 'TEXTAREA') {
          copyTextElement.setSelectionRange(0, 999999);
        }

        $document[0].execCommand('copy');

        if (angular.isDefined(vm.text)) {
          $document[0].body.removeChild(copyTextElement);
        }

        if (angular.isDefined(vm.onCopied) && angular.isFunction(vm.onCopied)) {
          $scope.$evalAsync(vm.onCopied());
        }

        if (!vm.isHideSuccessText) {
          if (angular.isDefined(vm.copySuccessText)) {
            toastr.success(vm.copySuccessText);
          } else {
            toastr.success($translate.instant('directives.copyText.toasts.textCopied'));
          }
        }

        selection.removeAllRanges();
      } else {
        toastr.warning($translate.instant('directives.copyText.toasts.textNotCopied'));
      }
    }
  }
})();

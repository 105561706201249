import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDoctype',
})
export class AddDoctypePipe implements PipeTransform {
  private findDoctypeRegexp = /<!doctype html/im;

  transform(value: string): string {
    if (typeof value === 'string' && this.findDoctypeRegexp.test(value) === false) {
      return `<!DOCTYPE html> ${value}`;
    }
    return value;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { InvoiceHttpService } from '@http/invoice/http/invoice-http.service';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { PaginatorParameters } from '@panel/app-old/shared/patterns/array-stream/array-stream.types';

/** Компонент для работы со счетами */
@Component({
  selector: 'cq-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesComponent {
  /** Счета */
  invoices$ = this.subscriptionStore.invoices$;

  /** Нужно ли показывать кнопку дополнительной загрузки */
  isNeedToShowShowMoreButton: boolean = true;

  constructor(
    private readonly invoiceHttpService: InvoiceHttpService,
    private readonly subscriptionStore: SubscriptionStore,
  ) {}

  /**
   * Показ дополнительного количества
   *
   * @param paginatorParameters - Параметры пагинации
   */
  showMore(paginatorParameters: PaginatorParameters): void {
    this.subscriptionStore.invoices$.setStreamAsLoading(true);

    firstValueFrom(this.invoiceHttpService.getList(true, paginatorParameters, true))
      .then(({ elements, paginatorParameters }) => {
        this.subscriptionStore.invoices$.addAll(elements, paginatorParameters);

        if (paginatorParameters.paginatePosition) {
          this.isNeedToShowShowMoreButton = true;
        }
      })
      .finally(() => {
        this.subscriptionStore.invoices$.setStreamAsLoading(false);
      });
  }
}

/**
 * Контроллер для модального окна отправки подтверждения подписки на письма (Double Opt-In)
 */
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.sendSubscriptionConfirmationEmail')
    .controller('SendSubscriptionConfirmationEmailModalController', SendSubscriptionConfirmationEmailModalController);

  function SendSubscriptionConfirmationEmailModalController(
    $filter,
    $uibModal,
    $uibModalInstance,
    userModel,
    currentApp,
    messageSender,
    users,
    usersCount,
  ) {
    var vm = this;

    vm.currentApp = currentApp;
    vm.messageSender = messageSender;
    vm.isArray = angular.isArray;
    vm.isObject = angular.isObject;
    vm.openEmailPreviewModal = openEmailPreviewModal;
    vm.sendEmail = sendEmail;
    vm.users = users;
    vm.usersCount = usersCount;

    /**
     * Открытие модалки с превью письма подтверждения
     */
    function openEmailPreviewModal() {
      $uibModal.open({
        controller: 'DoubleOptInEmailPreviewModalController',
        controllerAs: 'vm',
        resolve: {
          currentApp: angular.bind(null, angular.identity, currentApp),
          messageSender: angular.bind(null, angular.identity, messageSender),
          logo: angular.bind(null, angular.identity, currentApp.settings.double_opt_in_logo),
        },
        templateUrl: 'js/shared/modals/double-opt-in-email-preview/double-opt-in-email-preview.html',
      });
    }

    /**
     * Отправка письма подтверждения подписки
     */
    function sendEmail() {
      firstValueFrom(userModel.sendConfirmEmail(currentApp.id, users)).then(sendConfirmationSuccess);

      function sendConfirmationSuccess() {
        $uibModalInstance.close();
      }
    }
  }
})();

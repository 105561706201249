import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { generate } from 'short-uuid';

import { AppService } from '@http/app/services/app.service';
import { ConversationModel } from '@http/conversation/conversation.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AutoMessageRequestParams, Message } from '@http/message/message.types';
import { PSEUDO_DIRECTORY_IDS, PSEUDO_DIRECTORY_TYPES } from '@http/message-directory/message-directory.constants';
import { MessageDirectoryModel } from '@http/message-directory/message-directory.model';
import { MESSAGE_PART_TYPES, RECIPIENT_TYPES } from '@http/message-part/message-part.constants';
import { MessagePartModel } from '@http/message-part/message-part.model';
import { MessageStatisticsModel } from '@http/message-statistics/message-statistics.model';
import { MessageTestGroupModel } from '@http/message-test-group/message-test-group.model';
import { EventType, Properties } from '@http/property/property.model';
import { PaginationParamsRequest } from '@http/types';
import { UserTag } from '@http/user/types/user.type';
import { UrlFilterMapper } from '@panel/app/partials/url-filter-configurator/url-filter-mapper';
import { CaseStyleHelper } from '@panel/app/services';
import {
  SENDING_FILTERS_GROUP_TYPES,
  SENDING_FILTERS_TYPES,
} from '@panel/app/services/conditions-sending/conditions-sending.constants';
import { ConditionsSendingModel } from '@panel/app/services/conditions-sending/conditions-sending.model';
import { FILTER_LOGICAL_OPERATION } from '@panel/app/services/filter/filter.constants';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';
import { ExternalFiltersAJS, FiltersAJS } from '@panel/app/services/filter-ajs/filter-ajs.types';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';
import { TimeUnitService } from '@panel/app-old/shared/services/time-unit/time-unit.service';

import {
  MESSAGE_DELETING_TYPES,
  MESSAGE_STATUSES,
  MESSAGE_TYPES,
  SENDING_TYPES,
  TRIGGER_TYPE_KIND,
} from './message.constants';
import {
  AutoMessageTriggerType,
  AutoMessageTriggerTypeExternal,
  LeaveSiteAttemptTriggerTypeExternal,
  OpenedSdkPageTriggerTypeExternal,
  OpenedWebPageTriggerTypeExternal,
} from './trigger.types';

@Injectable({ providedIn: 'root' })
export class MessageModel {
  constructor(
    private readonly http: HttpClient,
    private readonly transloco: TranslocoService,
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly conversationModel: ConversationModel,
    private readonly filterAjsModel: FilterAjsModel,
    private readonly messageDirectoryModel: MessageDirectoryModel,
    private readonly messagePartModel: MessagePartModel,
    private readonly messageStatisticsModel: MessageStatisticsModel,
    private readonly messageTestGroupModel: MessageTestGroupModel,
    private readonly timeUnitService: TimeUnitService,
    private readonly appService: AppService,
  ) {}

  // Тут все функции, которые раньше преобразовывались в return модели через bind.
  // Сделать через bind и тут не вышло (хз почему, разбиратсья не стал)
  // Сделано через стрелочные, чтоб не терялся контекст в местах вызова из старого ангуляра

  readonly archiveAutoMessages = (
    appId: string,
    messageIds: string | string[],
    messageStatus?: MESSAGE_STATUSES,
    messagePartType?: MESSAGE_PART_TYPES,
    directoryId?: string,
  ) => this._archiveAutoMessages(false, appId, messageIds, messageStatus, messagePartType, directoryId);

  readonly getAggregatedControlGroupSendings = this.messageStatisticsModel.getAggregatedControlGroupSendings.bind(
    this.messageStatisticsModel,
  );

  readonly getAggregatedControlGroupStatistics = this.messageStatisticsModel.getAggregatedControlGroupStatistics.bind(
    this.messageStatisticsModel,
  );

  readonly getAutoMessages = (
    messagePartType?: MESSAGE_PART_TYPES,
    status?: MESSAGE_STATUSES,
    paginatorParams?: any,
    directoryId?: string,
    includeActiveTestGroup?: boolean,
  ) => this.getList(MESSAGE_TYPES.AUTO, messagePartType, status, paginatorParams, directoryId, includeActiveTestGroup);

  readonly getChatBotMessages = (
    messagePartType?: MESSAGE_PART_TYPES,
    status?: MESSAGE_STATUSES,
    paginatorParams?: any,
    directoryId?: string,
    includeActiveTestGroup?: boolean,
  ) =>
    this.getList(MESSAGE_TYPES.CHAT_BOT, messagePartType, status, paginatorParams, directoryId, includeActiveTestGroup);

  readonly getAutoMessage = (
    messageId: string,
    includeStats?: boolean,
    includeActiveTestGroup?: boolean,
    includeDirectory?: boolean,
    includeParts?: boolean,
    includeNotificationIntegrations?: boolean,
  ) =>
    this.getMessage(
      MESSAGE_TYPES.AUTO,
      messageId,
      includeStats,
      includeActiveTestGroup,
      includeDirectory,
      includeParts,
      includeNotificationIntegrations,
    );

  readonly getChatBotMessage = (
    messageId: string,
    includeStats?: boolean,
    includeActiveTestGroup?: boolean,
    includeDirectory?: boolean,
    includeParts?: boolean,
    includeNotificationIntegrations?: boolean,
  ) =>
    this.getMessage(
      MESSAGE_TYPES.CHAT_BOT,
      messageId,
      includeStats,
      includeActiveTestGroup,
      includeDirectory,
      includeParts,
      includeNotificationIntegrations,
    );

  getActiveTriggerMessageCount(): Observable<number> {
    return this.http.get<any>('/messages/active_auto_messages').pipe(
      map((response) => {
        return response.count;
      }),
    );
  }

  readonly getManualMessages = (
    messagePartType?: MESSAGE_PART_TYPES,
    status?: MESSAGE_STATUSES,
    paginatorParams?: any,
    directoryId?: string,
    includeActiveTestGroup?: boolean,
  ) =>
    this.getList(MESSAGE_TYPES.MANUAL, messagePartType, status, paginatorParams, directoryId, includeActiveTestGroup);

  readonly getManualMessage = (
    messageId: string,
    includeStats?: boolean,
    includeActiveTestGroup?: boolean,
    includeDirectory?: boolean,
    includeParts?: boolean,
    includeNotificationIntegrations?: boolean,
  ) =>
    this.getMessage(
      MESSAGE_TYPES.MANUAL,
      messageId,
      includeStats,
      includeActiveTestGroup,
      includeDirectory,
      includeParts,
      includeNotificationIntegrations,
    );

  readonly getMessageSendings = (
    entityId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    sendingType: SENDING_TYPES,
    paginatorParams?: any,
    excludeControlGroup?: boolean,
  ) =>
    this.messageStatisticsModel.getSendings(
      'messages',
      entityId,
      startDate,
      endDate,
      sendingType,
      paginatorParams,
      excludeControlGroup,
    );

  readonly getMessageStatistics = (
    entityId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    excludeControlGroup: boolean = true,
    rangeOverride?: any,
  ) =>
    this.messageStatisticsModel.getStatistics(
      'messages',
      entityId,
      startDate,
      endDate,
      excludeControlGroup,
      rangeOverride,
    );

  readonly reestablishAutoMessages = (
    appId: string,
    messageIds: string | string[],
    messageStatus?: MESSAGE_STATUSES,
    messagePartType?: MESSAGE_PART_TYPES,
    directoryId?: string,
  ) => this._archiveAutoMessages(true, appId, messageIds, messageStatus, messagePartType, directoryId);

  readonly setActive = (
    appId: string,
    testAutoMessageGraph?: boolean,
    messageIds?: string | string[],
    messageStatus?: MESSAGE_STATUSES,
    messagePartType?: MESSAGE_PART_TYPES,
    directory?: string,
  ) => this.setActivity(true, appId, testAutoMessageGraph, messageIds, messageStatus, messagePartType, directory);

  readonly setPaused = (
    appId: string,
    testAutoMessageGraph?: boolean,
    messageIds?: string | string[],
    messageStatus?: MESSAGE_STATUSES,
    messagePartType?: MESSAGE_PART_TYPES,
    directory?: string,
  ) => this.setActivity(false, appId, testAutoMessageGraph, messageIds, messageStatus, messagePartType, directory);

  /**
   * Архивация/восстановление одного, нескольких или всех сообщений
   *
   * @param isReestablish Флаг архиврования или восстановления
   * @param appId ID аппа
   *
   * Для архивации/восстановления одного или несокльких:
   * @param messageIds ID сообщения или массив с ID сообщений
   *
   * Для архивации/восстановления всех сообщений:
   * @param messageStatus Статус активности сообщений
   * @param messagePartType Тип сообщений
   * @param directoryId ID папки
   *
   */
  _archiveAutoMessages(
    isReestablish: boolean,
    appId: string,
    messageIds: string | string[],
    messageStatus?: MESSAGE_STATUSES,
    messagePartType?: MESSAGE_PART_TYPES,
    directoryId?: string,
  ): Observable<any> {
    const body: any = {
      app: appId,
      reverse: isReestablish,
    };
    //елси нет messageIds => операция массовая
    if (!messageIds) {
      if (!isReestablish) {
        body.active = this.getParsedActiveStatus(messageStatus!);
      }
      body.directory =
        directoryId === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY] ? undefined : directoryId;
      body.message_type = messagePartType === MESSAGE_PART_TYPES.ALL ? undefined : messagePartType;
    } else {
      body.messages = Array.isArray(messageIds) ? messageIds : [messageIds];
    }

    return this.http.post<any>('/messages/archive', body);
  }

  /**
   * Отменить рассылку (можно при статусах SENDING_STATUSES.ACTIVE, SENDING_STATUSES.PAUSED)
   *
   * @param messageId ID сообщения, у которого необходимо поменять статус
   */
  cancelSending(messageId: string): Observable<any> {
    return this.http.post('/messages/' + messageId + '/cancel', undefined, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /**
   * Создание авто-сообщения
   *
   * @param params - Параметры запроса
   * @param ignoreLoadingBar - Отображать Loading Bar
   */
  createAutoMessage(params: AutoMessageRequestParams, ignoreLoadingBar?: boolean): Observable<any> {
    const serverFormatParams = {
      active: params.active,
      after_delay: params.afterDelay,
      app: params.app,
      close_test: params.closeTest,
      delivery_time_end: params.deliveryTimeEnd,
      delivery_time_start: params.deliveryTimeStart,
      directory: params.directory,
      notification_integrations: params.notificationIntegrations,
      event_clicked: params.eventClicked,
      event_read: params.eventRead,
      event_replied: params.eventReplied,
      event_sended: params.eventSended,
      event_unsubscribed: params.eventUnsubscribed,
      expiration_interval: params.expirationInterval,
      expiration_time: params.expirationTime,
      filters: params.filters,
      goal_event_cost: params.goalEventCost,
      goal_event_cost_prop: params.goalEventCostProp,
      goal_event_timeout: params.goalEventTimeout,
      goal_event_type: params.goalEventType,
      message_type: params.messageType,
      name: params.name,
      not_send_replied: params.notSendReplied,
      parts: params.parts,
      repeat_delay: params.repeatDelay,
      sending_filters: params.sendingFilters,
      test_automessage_graph: params.testAutomessageGraph,
      triggers: params.triggers,
      trigger_types: params.triggerTypes,
      user_statuses: params.userStatuses,
    };

    return this.http.post('/messages', serverFormatParams, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar ?? true),
    });
  }

  /**
   * Создание первого триггерного сообщения. Используется в starter-guide
   *
   * @param appId ID приложения
   * @param text Текст сообщения
   * @param defaultMessageSender Отправитель по-умолчанию
   * @param sessionStartedEventId ID типа события «Зашел на сайт»
   */
  createFirstTriggerMessage(
    appId: string,
    text: string,
    defaultMessageSender: object,
    sessionStartedEventId: string,
  ): Observable<any> {
    const unparsedMessageParts = [];
    const parsedMessageParts = [];

    const controlGroup = this.messagePartModel.getDefault();
    controlGroup.type = MESSAGE_PART_TYPES.CONTROL_GROUP;
    controlGroup.proportion = 0;

    const defaultMessagePart = this.messagePartModel.getDefault();
    defaultMessagePart[MESSAGE_PART_TYPES.POPUP_CHAT].body = text;
    defaultMessagePart[MESSAGE_PART_TYPES.POPUP_CHAT].sender = defaultMessageSender;
    unparsedMessageParts.push(defaultMessagePart);

    refreshProportions(unparsedMessageParts, controlGroup.proportion);

    for (let i = 0; i < unparsedMessageParts.length; i++) {
      parsedMessageParts.push(this.messagePartModel.parseMessagePartToServerFormat(unparsedMessageParts[i]));
    }

    parsedMessageParts.push(this.messagePartModel.parseMessagePartToServerFormat(controlGroup));

    const params: AutoMessageRequestParams = {
      active: true,
      afterDelay: 0,
      app: appId,
      deliveryTimeEnd: '23:59:59',
      deliveryTimeStart: '00:00:00',
      //@ts-ignore
      filters: this.filterAjsModel.parseToServerFormat(this.filterAjsModel.getDefaultAnd()),
      messageType: MESSAGE_TYPES.AUTO,
      name: this.transloco.translate('models.message.firstTriggerMessageName'),
      notSendReplied: true,
      parts: parsedMessageParts,
      closeTest: false,
      directory: null,
      eventClicked: '',
      eventRead: '',
      eventReplied: '',
      eventSended: '',
      eventUnsubscribed: '',
      goalEventTimeout: null,
      goalEventType: null,
      repeatDelay: 86400,
      sendingFilters: null,
      testAutomessageGraph: true,
      triggers: sessionStartedEventId,
      triggerTypes: [],
      userStatuses: null,
    };

    this.messagePartModel.generatePartNames(params.parts as unknown as any[]);

    return this.createAutoMessage(params);

    /**
     * Обновление пропорций вариантов автосообщения
     *
     * @param messageParts Варианты автосообщения
     * @param controlGroupProportion Пропорций контрольной группы
     */
    function refreshProportions(messageParts: any[], controlGroupProportion: number) {
      for (let i = 0; i < messageParts.length; i++) {
        messageParts[i].proportion =
          controlGroupProportion + ((1 - controlGroupProportion) / messageParts.length) * (i + 1);
      }
    }
  }

  /**
   * Редактирование авто-сообщения PATCH запросом
   * Note: на текущий момент поддерживается только поле name
   *
   * @param messageId - ID сообещения
   * @param params - Параметры запроса
   * @param ignoreLoadingBar - Отображать Loading Bar
   * */
  edit(messageId: string, params: Pick<AutoMessageRequestParams, 'name'>, ignoreLoadingBar?: boolean): Observable<any> {
    return this.http.patch('/messages/' + messageId, params, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar ?? true),
    });
  }

  /**
   * Фильтрация массива сообщений/тест-групп/вариантов сообщения, даты которых попадают в период времени
   *
   * @param entities Массив сообщений/тест-групп/вариантов сообщения
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   */
  filterByTimeRange<T extends { created: moment.Moment; closed: moment.Moment }>(
    entities: T[],
    startDate: moment.Moment,
    endDate: moment.Moment,
  ): T[] {
    if (startDate > endDate) {
      throw Error('startDate must be lower than endDate');
    }

    return entities.filter(filter);

    function filter(entity: any) {
      if (entity.created <= endDate) {
        if (entity.closed) {
          return entity.closed >= startDate;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  /**
   * Получение не пустых фильтров из фильтров по URL
   * @param filter — Фильтры
   */
  filterEmptySendingFilters(filter: any): boolean {
    return !!filter.value.value;
  }

  /**
   * Получение текущего активного фильтра
   *
   * @param sendingFilters - Настройки фильтра
   */
  getCurrentFilterType(sendingFilters: any | null): SENDING_FILTERS_GROUP_TYPES {
    if (sendingFilters === null) {
      return SENDING_FILTERS_GROUP_TYPES.NO;
    }

    switch (sendingFilters.type) {
      case FILTER_LOGICAL_OPERATION.AND:
        return SENDING_FILTERS_GROUP_TYPES.EXCLUSION;
      case FILTER_LOGICAL_OPERATION.OR:
        return SENDING_FILTERS_GROUP_TYPES.INCLUSION;
      default:
        return SENDING_FILTERS_GROUP_TYPES.NO;
    }
  }

  /**
   * Получение имени сгруппированных типов
   *
   * - все поп-апы
   * - все сообщения в чат
   * @param parts - список партов сообщения
   */
  getMessagePartTypeName(parts: any[]): string {
    if (parts.length === 1) {
      if (parts[0].type.includes('chat') || parts[0].type === MESSAGE_PART_TYPES.TELEGRAM) {
        return this.transloco.translate('models.message.messagePartTypesGroups.chat');
      } else if (parts[0].type.includes('popup')) {
        return this.transloco.translate('models.message.messagePartTypesGroups.popup');
      } else if (parts[0].type.includes('push')) {
        return this.transloco.translate('models.message.messagePartTypesGroups.push');
      } else {
        return this.transloco.translate(`models.message.messagePartTypesGroups.${parts[0].type}`);
      }
    }
    return this.transloco.translate('models.message.messagePartTypesGroups.ab');
  }

  /**
   * Получение приближенного количества действительных получателей ручного сообщения
   * FIXME: очень странно... С одной стороны, проверка идёт по messagePartType, а с другой стороны идёт проверка получения СООБЩЕНИЯ, то есть message... Надо подумать, перенести ли это в messagePartModel
   * FIXME: ну нахера так писать? какие рутскоупы ещё?? у сообщения нету типа, тип есть у варианта сообщения (не messageType, а messagePartType)! Откуда двойное определение params, неужели нельзя написать всё по-нормальному??? бомбануло
   */
  getMessageReceiversCount(
    appId: string,
    messageType: MESSAGE_PART_TYPES,
    manualIds: string[],
    filters: FiltersAJS | string,
    integrationId?: string,
  ): Observable<any> {
    let params: any = {
      app: appId,
      type: messageType,
    };

    if (!!~[MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].indexOf(messageType)) {
      params.recipient_type = RECIPIENT_TYPES.SDK;
      params.type = messageType.replace(RECIPIENT_TYPES.SDK + '_', '');
    }

    if (manualIds.length > 0) {
      params.manual_ids = manualIds;
    } else {
      params.filters = typeof filters === 'string' ? filters : this.filterAjsModel.parseToServerFormat(filters); // пока не работают
    }

    if (messageType === MESSAGE_PART_TYPES.TELEGRAM) {
      if (!integrationId) {
        throw Error('You must provide integrationId for messageType == "telegram" for correct calculation');
      }
      params.integration_id = integrationId;
    }

    // специально сделан POST-запрос, т.к. иногда превышался максимальный размер GET-запроса из-за кучи параметров
    return this.http.post('/messages/count_receivers', params);
  }

  /**
   * Получение диалогов по типу действия получаетелей сообщения
   *
   * @param messageId ID сообщения
   * @param sendingType Тип действия получателей сообщения
   * @param paginatorParams Параметры пагинации
   */
  getConversations(messageId: string, sendingType: SENDING_TYPES, paginatorParams?: any) {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    }: PaginationParamsRequest = paginatorParams ?? {};

    const params = {
      include_channel: true,
      include_important_part_last: true,
      type: sendingType,
      conversation_extended: true,
      paginatorParams: JSON.stringify({
        paginate_direction: paginateDirection!,
        paginate_count: paginateCount,
        paginate_including: paginateIncluding,
        paginate_position: (paginatePosition ?? []).join() || null,
        paginate_page_order: paginatePageOrder,
      }),
    };

    return this.http
      .get('/messages/' + messageId + '/conversations', {
        params,
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(EXTENDED_RESPONSE, true)
          .set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((response: any) => {
          // todo: для совместимости с директивой conversations не надо преобразовывать ключи в camelCase, когда будет переделываться директива - сделать всё в camelCase
          //caseStyleHelper.keysToCamelCase(response);

          const conversations = response.data;

          for (let i = 0; i < conversations.length; i++) {
            const conversation = conversations[i];
            this.conversationModel.parse(conversation);
          }

          return {
            conversations: conversations,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.next_before_position,
            },
          };
        }),
      );
  }

  /**
   * Получение списка автосообщений для текущего сайта
   *
   * @param messageType Тип получаемых
   * @param messagePartType Тип вариантов получаемых сообщений
   * @param status Статус получаемых сообщений
   * @param paginatorParams Параметры пагинации
   * @param directoryId ID директории
   * @param includeActiveTestGroup Включать или нет в response варианты АБ-тестов
   */
  getList(
    messageType?: MESSAGE_TYPES,
    messagePartType?: MESSAGE_PART_TYPES,
    status?: MESSAGE_STATUSES,
    paginatorParams?: any,
    directoryId?: string,
    includeActiveTestGroup?: boolean,
  ): Observable<any> {
    let withSystemDirectories = undefined; //Сервер принимает либо ID директории, либо параметр with_system_directories, который возвращает сообщения включая сисемные директории

    if (messageType === undefined) {
      throw Error('messageType must be specified');
    }

    if (messagePartType === undefined) {
      throw Error('messagePartType must be specified');
    }

    if (status === undefined) {
      throw Error('status must be specified');
    }

    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    if (messageType === MESSAGE_TYPES.AUTO || messageType === MESSAGE_TYPES.CHAT_BOT) {
      switch (directoryId) {
        case PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY]: {
          //т.к. ID директории не передается with_system_directories должен быть равен false, чтобы сервер не вернул нам архивные сообщения
          directoryId = undefined;
          withSystemDirectories = false;
          break;
        }
      }
    }

    switch (messagePartType) {
      case MESSAGE_PART_TYPES.ALL: {
        // @ts-ignore хз что за логика тут, но типы менять не хочется
        messagePartType = null;
        break;
      }
    }

    const params: any = {
      active: this.getParsedActiveStatus(status!),
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition.join() || null,
      paginate_page_order: paginatePageOrder,
      include_active_test_group: includeActiveTestGroup,
      message_type: messageType,
      type: messagePartType,
      recipient_type: RECIPIENT_TYPES.WEB,
    };

    if (messageType == MESSAGE_TYPES.AUTO) {
      params.directory = directoryId;
      params.with_system_directories = withSystemDirectories;
    }

    // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
    // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
    if (
      !!~[
        MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
        MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
        MESSAGE_PART_TYPES.SDK_PUSH,
      ].indexOf(params.type)
    ) {
      params.recipient_type = RECIPIENT_TYPES.SDK;
    }

    // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
    // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
    // HACK Поэтому скрываем от backend, что сообщение SDK-типа.
    if (!!~[MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].indexOf(params.type)) {
      params.type = params.type.replace(RECIPIENT_TYPES.SDK + '_', '');
    }

    return this.http
      .get('/apps/' + this.appService.currentAppId + '/messages', {
        params,
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(EXTENDED_RESPONSE, true)
          .set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((response: any) => {
          this.caseStyleHelper.keysToCamelCase(response);

          const messages = response.data;

          for (let i = 0; i < messages.length; i++) {
            let message = messages[i];

            message = this.parseMessage(message, messageType!);
            //HACk убрать
            if (message.activeTestGroup) {
              for (let j = 0; j < message.activeTestGroup.parts.length; j++) {
                const messagePart = message.activeTestGroup.parts[j];
                // HACK: у ручных сообщений с бэк-энда возвращается контрольная группа среди вариантов сообщения, а фронт-энд рассчитан на один вариант, без контрольной группы. Поэтому удаляем её на этапе парсинга
                if (messageType == MESSAGE_TYPES.MANUAL && messagePart.type == MESSAGE_PART_TYPES.CONTROL_GROUP) {
                  message.activeTestGroup.parts.splice(j, 1);
                  j--;
                  continue;
                }
                const statistics = messagePart.stats;
                message.activeTestGroup.parts[j] = this.messagePartModel.parseMessagePartToInternalFormat(
                  message.activeTestGroup.parts[j],
                );
                message.activeTestGroup.parts[j].statistics = statistics;
              }

              this.messagePartModel.generatePartNames(message.activeTestGroup.parts);
              this.messagePartModel.setColors(message.activeTestGroup.parts);
            }
          }

          return {
            messages: messages,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.nextBeforePosition,
            },
          };
        }),
      );
  }

  /**
   * Получение сообщения по ID
   *
   * @param messageType Тип получаемых сообщений
   * @param messageId ID сообщения
   * @param includeStats=true Дополнительно получить статистику
   * @param includeActiveTestGroup=true Дополнительно получить активную тест-группу
   * @param includeDirectory=false Дополнительно поулчить системные папки
   * @param includeParts=false Дополнительно поулчить парты
   * @param includeNotificationIntegrations=false Дополнительно поулчить интеграции оповещения
   */
  getMessage(
    messageType: MESSAGE_TYPES,
    messageId: string,
    includeStats?: boolean,
    includeActiveTestGroup?: boolean,
    includeDirectory?: boolean,
    includeParts?: boolean,
    includeNotificationIntegrations?: boolean,
  ): Observable<any> {
    if (messageId === undefined) {
      throw Error('messageId must be specified');
    }

    includeStats = includeStats !== undefined ? includeStats : true;
    includeActiveTestGroup = includeActiveTestGroup !== undefined ? includeActiveTestGroup : true;
    includeParts = includeParts !== undefined ? includeParts : false;
    includeDirectory = includeDirectory !== undefined ? includeDirectory : false;

    const params: any = {
      include_stats: includeStats,
      include_active_test_group: includeActiveTestGroup,
      include_active_test_group_parts: includeParts,
      include_directory: includeDirectory,
      include_notification_integrations: includeNotificationIntegrations,
    };

    return this.http.get('/messages/' + messageId, { params }).pipe(
      map((data: any) => {
        this.parseMessage(data, messageType);

        // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
        // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
        // HACK Поэтому показываем frontend, что это сообщение SDK-типа.
        if (data.recipientType === RECIPIENT_TYPES.SDK && data.type !== MESSAGE_PART_TYPES.SDK_PUSH) {
          data.type = RECIPIENT_TYPES.SDK + '_' + data.type;
        }

        return data;
      }),
    );
  }

  /**
   * Возвращает параметр необходимый для запроса исходя из статуса
   *
   * @param status Статус сообщения
   */
  getParsedActiveStatus(status?: MESSAGE_STATUSES): boolean | undefined {
    switch (status) {
      case MESSAGE_STATUSES.ALL:
        return undefined;
      case MESSAGE_STATUSES.ACTIVE:
        return true;
      case MESSAGE_STATUSES.STOPPED:
        return false;
      default:
        return undefined;
    }
  }

  /**
   * Связывает сообщения с тригерамми
   *
   * @param {Array} messages Массив сообщений
   * @param {Array} eventTypes Массив типов событий
   */
  linkWithTriggers(messages: any[], eventTypes: EventType[]) {
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];

      for (let j = 0; j < message.triggers.length; j++) {
        message.triggers[j] = eventTypes.find((eventType) => eventType.id === message.triggers[j]);
      }
    }
  }

  /**
   * Парсит нотификации в локальный формат
   */
  parseNotificationsToInternalFormat(notificationIntegrations?: any[]) {
    const emailNotification =
      notificationIntegrations?.find((n) => n.integration.type === INTEGRATION_TYPES.EMAIL_NOTIFICATION) || null;
    const amocrmNotification =
      notificationIntegrations?.find((n) => n.integration.type === INTEGRATION_TYPES.AMOCRM) || null;

    return {
      emailNotification: emailNotification && this.caseStyleHelper.keysToCamelCase(emailNotification),
      amocrm: amocrmNotification && this.caseStyleHelper.keysToCamelCase(amocrmNotification),
    };
  }

  /**
   * Парсит нотификации в API формат
   */
  parseNotificationToExternalFormat(notificationIntegrations: any, messageId: string) {
    if (!notificationIntegrations) {
      return null;
    }
    const notificationIntegrationsExternal: any = {};
    if (notificationIntegrations.emailNotification) {
      notificationIntegrationsExternal[notificationIntegrations.emailNotification.integration.id] = {
        comment: null,
        message: messageId,
      };
    }
    if (notificationIntegrations.amocrm) {
      const messageConfig: any = this.caseStyleHelper.keysToUnderscore(notificationIntegrations.amocrm.messageConfig);
      if (messageConfig.comment?.length === 0) {
        messageConfig.comment = null;
      }
      notificationIntegrationsExternal[notificationIntegrations.amocrm.integration.id] = messageConfig;
    }
    return notificationIntegrationsExternal;
  }

  /**
   * Парсинг URL-фильтра
   * NOTE:
   *  Если в настройках URL-фильтра пристуствует звездочка, то нужно незаметно для пользователья заменить тип фильтра.
   *  Необходимо это только при значении фильтра "Содержат в адресе"
   *
   * @param  filters - Массив с фильтрами
   */
  parseSendingFilters(filters: any[]): any[] {
    filters.forEach((filter) => {
      if (filter.value.value.includes('*')) {
        if (filter.type === SENDING_FILTERS_TYPES.URL_CONTAINS) {
          filter.type = SENDING_FILTERS_TYPES.URL_CONTAINS_WILDCARD;
        } else if (filter.type === SENDING_FILTERS_TYPES.URL_NOT_CONTAINS) {
          filter.type = SENDING_FILTERS_TYPES.URL_NOT_CONTAINS_WILDCARD;
        } else if (filter.type === SENDING_FILTERS_TYPES.URL_PATH_EQ) {
          filter.type = SENDING_FILTERS_TYPES.URL_CONTAINS_WILDCARD;
        } else if (filter.type === SENDING_FILTERS_TYPES.URL_PATH_NOT_EQ) {
          filter.type = SENDING_FILTERS_TYPES.URL_NOT_CONTAINS_WILDCARD;
        }
      }
    });

    return filters;
  }

  /**
   * Парсинг фильтров автосообщений
   * Из строки филтров делает обьект, заменяет дефолтные имена на "prettyName", добавляет свойство "group" для удобного вывода
   *
   * @param {Array} messages Массив сообщений
   * @param {Array} properties Массив свойств и свойств событий
   * @param {Array} tags Массив тегов пользователей
   */
  parseMessageFilters(messages: Message[], properties: Properties, tags: UserTag[]) {
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];

      // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
      // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
      // HACK Поэтому показываем frontend, что это сообщение SDK-типа.
      if (message.recipientType === RECIPIENT_TYPES.SDK && message.type !== MESSAGE_PART_TYPES.SDK_PUSH) {
        message.type = RECIPIENT_TYPES.SDK + '_' + message.type;
      }

      if (message.filters.filters === undefined) {
        message.filters = this.filterAjsModel.getDefault(FILTER_LOGICAL_OPERATION.AND);
      }

      this.filterAjsModel.linkWithPropsAndTags(message.filters as unknown as ExternalFiltersAJS, properties, tags);
      message.isMessageHaveFilters = this.filterAjsModel.isMessageHaveFilters(message);
    }
  }

  /**
   * Парсинг сообщения в серверный формат
   * TODO рефакторинг автосообщений
   * !!! Используется только для чат-ботов. в автосообщениях все это делается в конроллере
   * !!! Когда будет рефакторинг автосообщений это надо использовать и там
   * !!! ОБРАТИТЕ ВНИМАНИЕ ЧТО ЭТО ФУНКЦИЯ НОРМАЛЬНО БУДЕТ ПАРСИТЬ ТОЛЬКО ЧАТ-БОТ СООБЩЕНИЯ, НАДО БУДЕТ СДЕЛАТЬ ЕЕ УНИВЕРСАЛЬНОЙ
   *
   * @param appId - Сообщение
   * @param message - Сообщение
   * @param botId - ID бота !!! ЭТОГО ТУТ БЫТЬ НЕДОЛЖНО. ТАК КОСТЫЛЯЮ ИЗ-ЗА ОГРАНИЧЕННОГО ВРЕМЕНИ
   * @param active - Статус активности
   * @param deleteMessage - Параметры удаления сообщений !!! ЭТОГО ТУТ БЫТЬ НЕДОЛЖНО. ТАК КОСТЫЛЯЮ ИЗ-ЗА ОГРАНИЧЕННОГО ВРЕМЕНИ
   *
   * @return {Object}
   */
  parseMessageToServerFormat(appId: string, message: any, botId: string, active: boolean, deleteMessage: any) {
    let dateFrom, dateTo;

    if (!message.isSendAtTime) {
      dateFrom = '00:00:00';
      dateTo = '23:59:59';
    } else {
      dateFrom = message.sendTimeValueFromH + ':' + message.sendTimeValueFromM + ':' + '00';
      dateTo = message.sendTimeValueToH + ':' + message.sendTimeValueToM + ':' + '59';
    }

    const triggers = message.triggers.join(',');

    const triggerTypes = this.parseTriggerTypesToServeFormat(message.triggerTypes);

    const filters = this.filterAjsModel.parseToServerFormat(
      message.isMessageHaveFilters && this.filterAjsModel.isMessageHaveFilters(message)
        ? message.filters
        : this.filterAjsModel.getDefaultAnd(),
    );

    const parsedMessage: any = {
      app: appId,
      name: message.name,
      active: active,
      filters: filters,
      jinja_filter_template: message.jinjaFilterTemplate ?? null,
      triggers: triggers ? triggers : null,
      after_delay: !message.isAfterTime ? 0 : message.afterTimeValue,
      repeat_delay: !message.isRepeat ? 1000000000 : message.repeatDelay,
      not_send_replied: message.notSendReplied,
      user_statuses: message.userStatuses,
      delivery_time_start: dateFrom,
      delivery_time_end: dateTo,
      message_type: MESSAGE_TYPES.CHAT_BOT,
      parts: [],
      close_test: false,
      goal_event_type: message.hasGoal ? message.goalEventType : null,
      goal_event_timeout: message.hasGoal ? message.goalEventTimeout : null,
      event_sended: '', // События для создания цепочки. В боте они не нужны
      event_read: '', // События для создания цепочки. В боте они не нужны
      event_replied: '', // События для создания цепочки. В боте они не нужны
      event_clicked: '', // События для создания цепочки. В боте они не нужны
      event_unsubscribed: '', // События для создания цепочки. В боте они не нужны
      directory: null,
      sending_filters: message.sendingFilters,
      test_automessage_graph: false,
      trigger_types: triggerTypes,
    };

    // удаление сообщения (протухание)
    if (deleteMessage.deleteType === MESSAGE_DELETING_TYPES.CERTAIN_DATE) {
      parsedMessage.expiration_time = deleteMessage.time.time.unix();
    } else if (deleteMessage.deleteType === MESSAGE_DELETING_TYPES.TIME_INTERVAL) {
      parsedMessage.expiration_interval = deleteMessage.interval.value;
    }

    // Если у сообщения включены фильтры по URL, то оставим только не пустые фильтры и проставим трекинг Url у выбранных событий
    const isOpenedPageTriggerTypes = !!(
      triggerTypes.length &&
      triggerTypes.every(
        (
          triggerType: AutoMessageTriggerTypeExternal,
        ): triggerType is OpenedWebPageTriggerTypeExternal | OpenedSdkPageTriggerTypeExternal =>
          triggerType.kind === TRIGGER_TYPE_KIND.URL || triggerType.kind === TRIGGER_TYPE_KIND.SDK_PAGE,
      )
    );
    if (
      message.sendingFilters.filters.length &&
      message.sendingFilters.type !== SENDING_FILTERS_GROUP_TYPES.NO &&
      !isOpenedPageTriggerTypes
    ) {
      const inverseFilters = message.sendingFilters.type === SENDING_FILTERS_GROUP_TYPES.EXCLUSION;

      parsedMessage.sending_filters = {
        type: inverseFilters ? 'and' : 'or',
        filters: UrlFilterMapper.filtersToExternal(message.sendingFilters.filters, inverseFilters),
      };
    } else if (isOpenedPageTriggerTypes) {
      parsedMessage.sending_filters = UrlFilterMapper.externalSendingFiltersForOpenedPageTriggerTypes(triggerTypes);
    } else {
      parsedMessage.sending_filters = null;
    }

    // FIXME ApiRequest: отдельная обработка цели, т.к. сейчас ApiRequest посылает параметр как пустую строку, если ему присвоен null, а чтобы вообще не посылать параметр (как и требуется в данном случае) надо вообще не задавать параметр
    if (message.hasGoal && message.goalEventCostType === 'manual') {
      parsedMessage.goal_event_cost = message.goalEventCost;
    } else if (message.hasGoal && message.goalEventCostType === 'eventField') {
      parsedMessage.goal_event_cost_prop = message.goalEventCostProp;
    }

    message.controlGroup.proportion = message.isControlGroupEnabled ? message.controlGroup.proportion : 0;
    message.parts[0].body = botId;

    parsedMessage.parts.push(this.messagePartModel.parseMessagePartToServerFormatFromBot(message.parts[0]));
    parsedMessage.parts.push(this.messagePartModel.parseMessagePartToServerFormatFromBot(message.controlGroup));

    return parsedMessage;
  }

  parseTriggerTypesToServeFormat(triggerTypes: AutoMessageTriggerType): AutoMessageTriggerTypeExternal[] {
    if (triggerTypes.leaveSiteAttemptTrigger) {
      return [
        {
          kind: TRIGGER_TYPE_KIND.LEAVE_SITE_ATTEMPT,
          value: {},
        },
      ];
    }

    if (triggerTypes.openedWebPageTriggers.length >= 1) {
      return triggerTypes.openedWebPageTriggers.map((openedPageTrigger) => {
        return {
          kind: TRIGGER_TYPE_KIND.URL,
          value: {
            comparison: openedPageTrigger.comparison,
            url: openedPageTrigger.url,
          },
        };
      });
    }

    if (triggerTypes.openedSdkPageTriggers.length >= 1) {
      return triggerTypes.openedSdkPageTriggers.map((openedPageTrigger) => {
        return {
          kind: TRIGGER_TYPE_KIND.SDK_PAGE,
          value: {
            comparison: openedPageTrigger.comparison,
            sdkPage: openedPageTrigger.sdkPage,
          },
        };
      });
    }

    return [];
  }

  parseTriggerTypeToInternalFormat(triggerTypes: AutoMessageTriggerTypeExternal[]): AutoMessageTriggerType {
    const isUrlTriggerTypes = triggerTypes.every<OpenedWebPageTriggerTypeExternal>(
      (triggerType: AutoMessageTriggerTypeExternal): triggerType is OpenedWebPageTriggerTypeExternal =>
        triggerType.kind === TRIGGER_TYPE_KIND.URL,
    );

    if (isUrlTriggerTypes) {
      return {
        openedWebPageTriggers: triggerTypes.map((triggerType: OpenedWebPageTriggerTypeExternal) => {
          return {
            localId: generate(),
            comparison: triggerType.value.comparison,
            url: triggerType.value.url,
          };
        }),
        openedSdkPageTriggers: [],
        leaveSiteAttemptTrigger: false,
      };
    }

    const isSdkTriggerTypes = triggerTypes.every<OpenedSdkPageTriggerTypeExternal>(
      (triggerType: AutoMessageTriggerTypeExternal): triggerType is OpenedSdkPageTriggerTypeExternal =>
        triggerType.kind === TRIGGER_TYPE_KIND.SDK_PAGE,
    );

    if (isSdkTriggerTypes) {
      return {
        openedWebPageTriggers: [],
        openedSdkPageTriggers: triggerTypes.map((triggerType: OpenedSdkPageTriggerTypeExternal) => {
          return {
            localId: generate(),
            comparison: triggerType.value.comparison,
            sdkPage: triggerType.value.sdkPage,
          };
        }),
        leaveSiteAttemptTrigger: false,
      };
    }

    const isLeaveSiteAttemptTriggerType = triggerTypes.every<LeaveSiteAttemptTriggerTypeExternal>(
      (triggerType: AutoMessageTriggerTypeExternal): triggerType is LeaveSiteAttemptTriggerTypeExternal =>
        triggerType.kind === TRIGGER_TYPE_KIND.LEAVE_SITE_ATTEMPT,
    );
    if (isLeaveSiteAttemptTriggerType) {
      return {
        openedWebPageTriggers: [],
        openedSdkPageTriggers: [],
        leaveSiteAttemptTrigger: true,
      };
    }

    return {
      openedWebPageTriggers: [],
      openedSdkPageTriggers: [],
      leaveSiteAttemptTrigger: false,
    };
  }

  /**
   * Парсинг сообщения
   *
   * @param message Сообщение
   * @param messageType Тип получаемых сообщений
   */
  parseMessage(message: any, messageType: MESSAGE_TYPES) {
    message.created = message.created != null ? moment(message.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ') : message.created;

    if (message.activeTestGroup) {
      this.messageTestGroupModel.parseTestGroup(message.activeTestGroup);
    }

    if (message.expirationTime) {
      message.expirationTime = moment(message.expirationTime).utcOffset(this.appService.app.settings.timezone_offset);
    }

    //Только в автосообщениях есть возможность выделить сообщения и директория
    if (messageType === MESSAGE_TYPES.AUTO) {
      message.checked = false;

      if (message.directory === undefined || message.directory === null) {
        message.directory = this.messageDirectoryModel.getWithoutDirectory();
      }
      this.messageDirectoryModel.parse(message.directory);

      message.filters = this.filterAjsModel.parse(message.filters);
    } else if (messageType === MESSAGE_TYPES.MANUAL) {
      // Только в ручных сообщениях есть время запланированной отправки
      if (message.deliveryTime !== undefined) {
        //Запланированное время надо показывать в выбраном часавом поясе
        message.deliveryTime = moment(message.deliveryTime).utcOffset(this.appService.app.settings.timezone_offset);
      }
    } else if (messageType === MESSAGE_TYPES.CHAT_BOT) {
      message.checked = false;
      message.filters = this.filterAjsModel.parse(message.filters);
      if (message.activeTestGroup) {
        message.parts = this.messagePartModel.filterMessageParts(message.activeTestGroup.parts);
        message.controlGroup = this.messagePartModel.filterControlGroup(message.activeTestGroup.parts);
      }
      Object.assign(message, Object.assign(ConditionsSendingModel.getDefault(), message));
      this.parseEditableMessage(message);
    }

    // статистика по сообщению в общем тянется не из ClickHouse, а из PostgreSQL (т.е. это старая статистика), поэтому её надо немного преобразовать
    if (message.stats) {
      message.statistics = message.stats;

      // в старой статистике вместо sended приходит sent
      message.statistics.sended = message.statistics.sent;
      delete message.statistics.sent;

      // так же в старой статистике приходит lastSent, его надо перенести в свойства сообщения
      if (message.statistics.lastSent) {
        message.lastSent = message.statistics.lastSent;
        message.lastSent =
          message.lastSent != null ? moment(message.lastSent, 'YYYY-MM-DDTHH:mm:ss.SSSZ') : message.lastSent;

        delete message.statistics.lastSent;
      }

      delete message.stats;
    }

    // Фильтры по URL
    message.sendingFilters = {
      type: this.getCurrentFilterType(message.sendingFilters),
      filters: UrlFilterMapper.filtersToLocal(message.sendingFilters?.filters ?? []),
    };

    // Новые триггеры
    message.triggerTypes = this.parseTriggerTypeToInternalFormat(message.triggerTypes);

    return message;
  }

  /**
   * @param deliveryTimeStart
   * @param deliveryTimeEnd
   */
  parseSendTimeToInternal({
    deliveryTimeStart,
    deliveryTimeEnd,
  }: {
    deliveryTimeStart: string;
    deliveryTimeEnd: string;
  }) {
    const isSendAtTime = !(deliveryTimeStart === '00:00:00' && deliveryTimeEnd === '23:59:59');

    if (!isSendAtTime) {
      return {
        isSendAtTime,
        sendTimeValueFromH: '09',
        sendTimeValueFromM: '00',
        sendTimeValueToH: '18',
        sendTimeValueToM: '00',
      };
    }

    const sendTimeFrom = new Date();
    sendTimeFrom.setHours(Number(deliveryTimeStart.substr(0, 2)));
    sendTimeFrom.setMinutes(Number(deliveryTimeStart.substr(3, 2)));

    const sendTimeTo = new Date();
    sendTimeTo.setHours(Number(deliveryTimeEnd.substr(0, 2)));
    sendTimeTo.setMinutes(Number(deliveryTimeEnd.substr(3, 2)));

    return {
      isSendAtTime,
      sendTimeValueFromH: pad(sendTimeFrom.getHours(), 2),
      sendTimeValueFromM: pad(sendTimeFrom.getMinutes(), 2),
      sendTimeValueToH: pad(sendTimeTo.getHours(), 2),
      sendTimeValueToM: pad(sendTimeTo.getMinutes(), 2),
    };

    function pad(num: number, size: number) {
      return ('000000000' + num).substr(-size);
    }
  }

  parseSendTimeToExternal({
    isSendAtTime,
    sendTimeValueFromH,
    sendTimeValueFromM,
    sendTimeValueToH,
    sendTimeValueToM,
  }: {
    isSendAtTime: boolean;
    sendTimeValueFromH: string;
    sendTimeValueFromM: string;
    sendTimeValueToH: string;
    sendTimeValueToM: string;
  }) {
    let deliveryTimeStart: string, deliveryTimeEnd: string;

    if (!isSendAtTime) {
      deliveryTimeStart = '00:00:00';
      deliveryTimeEnd = '23:59:59';
    } else {
      deliveryTimeStart = sendTimeValueFromH + ':' + sendTimeValueFromM + ':' + '00';
      deliveryTimeEnd = sendTimeValueToH + ':' + sendTimeValueToM + ':' + '59';
    }

    return {
      deliveryTimeStart,
      deliveryTimeEnd,
    };
  }

  parseEditableMessage(editableMessage: any) {
    // ТРИГГЕР
    editableMessage.isAfterTime = !!editableMessage.afterDelay;
    if (editableMessage.isAfterTime) {
      editableMessage.afterTimeValue = editableMessage.afterDelay;
      editableMessage.afterTimeTimeUnit = this.timeUnitService.getByValue(
        editableMessage.afterTimeValue,
        editableMessage.afterTimeTimeUnits,
      );
    }

    // Парсим время отправки
    Object.assign(editableMessage, this.parseSendTimeToInternal(editableMessage));

    // УСЛОВИЯ ОТПРАВКИ
    editableMessage.isRepeat = !(editableMessage.repeatDelay >= 1000000000);
    if (editableMessage.isRepeat) {
      editableMessage.repeatDelayTimeUnit = this.timeUnitService.getByValue(
        editableMessage.repeatDelay,
        editableMessage.repeatDelayTimeUnits,
      );
    }
    if (editableMessage.controlGroup) {
      editableMessage.isControlGroupEnabled = editableMessage.controlGroup.proportion > 0;
    }

    // ЦЕЛЬ
    editableMessage.hasGoal = !!(editableMessage.goalEventType || editableMessage.goalEventTypeName);
    if (editableMessage.goalEventCost != null) {
      editableMessage.goalEventCostType = 'manual';
    } else if (editableMessage.goalEventCostProp) {
      editableMessage.goalEventCostType = 'eventField';
    } else {
      editableMessage.goalEventCostType = 'none';
    }
    editableMessage.goalEventCost =
      editableMessage.goalEventCost != null ? editableMessage.goalEventCost : editableMessage.goalEventCost;
    editableMessage.goalEventCostProp =
      editableMessage.goalEventCostProp != null ? editableMessage.goalEventCostProp : editableMessage.goalEventCostProp;
    editableMessage.goalEventTimeout =
      editableMessage.goalEventTimeout != null ? editableMessage.goalEventTimeout : editableMessage.goalEventTimeout;
    editableMessage.goalEventTimeoutTimeUnit = this.timeUnitService.getByValue(
      editableMessage.goalEventTimeout,
      editableMessage.goalEventTimeoutTimeUnits,
    );
  }

  /**
   * Остановить рассылку
   *
   * @param messageId ID сообщения, у которого необходимо поменять статус
   */
  pauseSending(messageId: string): Observable<any> {
    return this.http.post('/messages/' + messageId + '/pause', undefined, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /**
   * Возобновить рассылку (можно только при статусе SENDING_STATUSES.PAUSED)
   *
   * @param messageId ID сообщения, у которого необходимо поменять статус
   */
  resumeSending(messageId: string): Observable<any> {
    return this.http.post('/messages/' + messageId + '/resume', undefined, {
      context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /**
   * Сохаранение авто-сообщения
   *
   * @param messageId - ID сообещения
   * @param params - Параметры запроса
   * @param ignoreLoadingBar - Отображать Loading Bar
   */
  saveAutoMessage(messageId: string, params: AutoMessageRequestParams, ignoreLoadingBar?: boolean): Observable<any> {
    const serverFormatBody = {
      active: params.active,
      after_delay: params.afterDelay,
      app: params.app,
      close_test: params.closeTest,
      delivery_time_end: params.deliveryTimeEnd,
      delivery_time_start: params.deliveryTimeStart,
      directory: params.directory,
      event_clicked: params.eventClicked,
      event_read: params.eventRead,
      event_replied: params.eventReplied,
      event_sended: params.eventSended,
      event_unsubscribed: params.eventUnsubscribed,
      expiration_interval: params.expirationInterval,
      expiration_time: params.expirationTime,
      filters: params.filters,
      goal_event_cost: params.goalEventCost,
      goal_event_cost_prop: params.goalEventCostProp,
      goal_event_timeout: params.goalEventTimeout,
      goal_event_type: params.goalEventType,
      jinja_filter_template: params.jinjaFilterTemplate ?? null,
      message_type: params.messageType,
      name: params.name,
      not_send_replied: params.notSendReplied,
      notification_integrations: params.notificationIntegrations,
      parts: params.parts,
      repeat_delay: params.repeatDelay,
      sending_filters: params.sendingFilters,
      test_automessage_graph: params.testAutomessageGraph,
      trigger_types: params.triggerTypes,
      triggers: params.triggers,
      user_statuses: params.userStatuses,
    };

    return this.http.put('/messages/' + messageId, serverFormatBody, {
      context: new HttpContext()
        .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
        .set(EXTENDED_RESPONSE, true)
        .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar ?? true),
    });
  }

  /**
   * Отправка ручного сообщения
   *
   * @param {String} appId - ID аппа
   * @param {Object} messageParams - Параметры сообещния
   * @param {Object} message - Сообщение
   * @param {moment=} deliveryTime - Время отправки (для запланированной отправки)
   * @param {moment|number=} expiration - Время удаления сообщения (время или интервал)
   * @returns {Promise}
   */
  sendManualMessage(
    appId: string,
    messageParams: any,
    message: any,
    deliveryTime?: moment.Moment,
    expiration?: moment.Moment,
  ) {
    const body: any = {
      app: appId,
      message_type: 'manual',
      name: 'Manual Message',
      manual_ids: messageParams.manualIds,
      filters:
        typeof messageParams.filters === 'string'
          ? messageParams.filters
          : this.filterAjsModel.parseToServerFormat(messageParams.filters),
      ignoreErrors: true,
    };

    if (deliveryTime !== undefined) {
      body.delivery_time = deliveryTime!.unix();
    }

    if (expiration !== undefined) {
      if (expiration instanceof moment) {
        body.expiration_time = expiration.unix();
      } else {
        body.expiration_interval = expiration;
      }
    }

    // обработка вариантов автосообщения
    Object.assign(body, this.messagePartModel.parseMessagePartToServerFormat(cloneDeep(message)));

    return this.http.post('/messages', body);
  }

  /**
   * Изменения статуса одного, нескольких или всех сообщений
   *
   * @param status Статус сообещния
   * @param appId ID аппа
   * @param testAutoMessageGraph=true Проверка на зацикливание
   *
   * Для архивации/восстановления одного или несокльких:
   * @param messageIds ID сообщения или массив с ID сообщений
   *
   * Для архивации/восстановления всех сообщений:
   * @param messageStatus Статус активности сообщений
   * @param messagePartType Тип сообщений
   * @param directory ID папки
   */
  setActivity(
    status: boolean,
    appId: string,
    testAutoMessageGraph?: boolean,
    messageIds?: string | string[],
    messageStatus?: MESSAGE_STATUSES,
    messagePartType?: MESSAGE_PART_TYPES,
    directory?: string,
  ): Observable<any> {
    const params: any = {
      app: appId,
      test_automessage_graph: testAutoMessageGraph !== undefined ? testAutoMessageGraph : true,
      new_active: status,
      ignoreErrors: true,
    };
    //елси нет messageIds => операция массовая
    if (!messageIds) {
      params.active = this.getParsedActiveStatus(messageStatus!);
      params.message_type = messagePartType === MESSAGE_PART_TYPES.ALL ? null : messagePartType;
      params.directory = directory === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY] ? null : directory;
    } else {
      params.messages = Array.isArray(messageIds) ? messageIds : [messageIds];
    }

    return this.http.post('/messages/setactive', params);
  }

  /**
   * Перемещение однго, нескольких или всех сообщений
   *
   * @param appId ID аппа
   * @param newDirectoryId ID директории в которую надо переместить сообщения
   *
   * Для перемещения одного или несокльких:
   * @param messageIds ID сообщения или массив с ID сообщений
   *
   * Для перемещения всех сообщений, передать фильтры автосообщений:
   * @param messageStatus Статус активности сообщений
   * @param messagePartType Тип сообщений
   * @param directoryId ID папки
   */
  setDirectory(
    appId: string,
    newDirectoryId: string,
    messageIds: string | string[],
    messageStatus?: MESSAGE_STATUSES,
    messagePartType?: MESSAGE_PART_TYPES,
    directoryId?: string,
  ): Observable<any> {
    const body: any = {
      app: appId,
      new_directory:
        newDirectoryId === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.WITHOUT_DIRECTORY] ? null : newDirectoryId,
    };
    //елси нет messageIds => операция массовая
    if (!messageIds) {
      body.active = this.getParsedActiveStatus(messageStatus);
      body.directory =
        directoryId === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY] ? undefined : directoryId;
      body.message_type = messagePartType === MESSAGE_PART_TYPES.ALL ? undefined : messagePartType;
    } else {
      body.messages = Array.isArray(messageIds) ? messageIds : [messageIds];
    }

    return this.http.post('/messages/setdirectory/', body);
  }

  /**
   * Загрузка иконки для пушей
   *
   * @param appId ID приложения
   * @param icon Файл иконки
   */
  uploadPushIcon(appId: string, icon: File) {
    const body: any = {
      icon: icon,
    };

    return this.http.post('/apps/' + appId + '/uploadpushicon', body);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { InputNumberForm } from '@panel/app/partials/filters/components/operation-select/value-inputs/input-number/input-number.types';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';

@Component({
  selector: 'cq-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberComponent extends AbsCVAFormGroupBasedComponent<InputNumberForm> {
  MIN_VALUE: number = 0;

  readonly control: FormGroup<InputNumberForm> = this.fb.group<InputNumberForm>({
    value: this.fb.control(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern(/^\d+$/), Validators.min(this.MIN_VALUE)],
    }),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}

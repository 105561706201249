import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { NAVIGATION_SECTION, NAVIGATIONS_STATES } from '@panel/app/services/block-panel/block-panel.constants';

@Injectable({
  providedIn: 'root',
})
export class BlockPanelService {
  constructor(private readonly djangoUserModel: DjangoUserModel) {}

  public isStateBlocked(state: string, currentApp: App, djangoUser: DjangoUser): boolean {
    if (environment.sandboxEnabled) {
      return false;
    }

    const isOperator = this.djangoUserModel.isOperator(currentApp.id, djangoUser);
    const isSuperAdmin = this.djangoUserModel.isSuperAdmin(currentApp.id, djangoUser);

    switch (true) {
      case [
        ...NAVIGATIONS_STATES[NAVIGATION_SECTION.DASHBOARD],
        ...NAVIGATIONS_STATES[NAVIGATION_SECTION.AUTO_MESSAGES],
        ...NAVIGATIONS_STATES[NAVIGATION_SECTION.TRIGGER_CHAIN],
        ...NAVIGATIONS_STATES[NAVIGATION_SECTION.CHAT_BOTS],
        ...NAVIGATIONS_STATES[NAVIGATION_SECTION.KNOWLEDGE_BASE],
        ...NAVIGATIONS_STATES[NAVIGATION_SECTION.REPORTS],
        ...NAVIGATIONS_STATES[NAVIGATION_SECTION.SETTINGS],
        ...NAVIGATIONS_STATES[NAVIGATION_SECTION.INTEGRATIONS],
      ].includes(state):
        return isOperator;
      case [...NAVIGATIONS_STATES[NAVIGATION_SECTION.SUBSCRIPTION]].includes(state):
        return !isSuperAdmin;
      default:
        return false;
    }
  }
}

<ng-container *transloco="let t; read: 'pricingPlanHeadingComponent'">
  <div
    #heightRef
    class="card-group"
  >
    <div class="card border-bottom-0">
      <div class="card-body pb-0">
        <div class="text-secondary">{{ t('planConfiguration.description') }}</div>
      </div>
    </div>
    <ng-container *ngFor="let plan of PLANS">
      <div class="card no-border-bottom">
        <div class="card-body pb-0">
          <span class="text-secondary">{{ t('planInfo.description.' + plan) }}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="card-group sticky"
    cqStickyStuck
  >
    <div class="card border-top-0 border-bottom-0">
      <div class="card-body pt-20 pb-10">
        <ngx-slider
          class="mt-0 mb-15"
          [options]="sliderOptions"
          [value]="expectedVisitors"
          (valueChange)="onExpectedVisitorsChange($event)"
        ></ngx-slider>
        <div class="mb-15">
          <span
            *ngIf="!needsPersonalPlan"
            [innerHTML]="
              t('planConfiguration.expectedVisitorsSlider.selectedInRange', {
                expectedVisitors: expectedVisitors | number
              })
            "
          ></span>
          <span
            *ngIf="needsPersonalPlan"
            [innerHTML]="
              t('planConfiguration.expectedVisitorsSlider.selectedOverLimit', {
                expectedVisitors: expectedVisitorsLimit - 1 | number
              })
            "
          ></span>
        </div>
        <ng-select
          class="light-secondary"
          [ngModel]="paymentPeriod"
          (ngModelChange)="onPaymentPeriodChange($event)"
          [clearable]="false"
          [items]="paymentRateOptions"
          [searchable]="false"
        >
          <ng-template
            let-option="item"
            ng-label-tmp
          >
            <div>
              {{ t('planConfiguration.paymentRateSelector.' + option) }}
            </div>
          </ng-template>
          <ng-template
            let-option="item"
            ng-option-tmp
          >
            <div>
              {{ t('planConfiguration.paymentRateSelector.' + option) }}
            </div>
            <div
              *ngIf="getDiscountValue(option) > 0"
              class="small text-muted"
            >
              {{
                t('planConfiguration.discount', {
                  discountValue: getDiscountValue(option) | percent
                })
              }}
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <ng-container *ngFor="let plan of PLANS">
      <ng-container
        *tuiLet="
          pricingService.calcPlanPrices(
            plan,
            addonsConfiguration,
            expectedVisitors,
            paymentPeriod,
            currentApp
          ) as pricing
        "
      >
        <div class="card border-top-0 border-bottom-0">
          <div class="card-body pt-10">
            <div class="d-flex align-items-center mb-10">
              <h3 class="mb-0 font-weight-bold">{{ planName(plan) }}</h3>
              <span
                *ngIf="pricing.addons.length > 0"
                class="badge badge-light-primary ml-10"
                >{{ t('planInfo.additionalModules', { numberOfModules: pricing.addons.length }) }}</span
              >
            </div>

            <ng-container *ngIf="needsPersonalPlan || !pricing.priceWithoutAddonsPerMonth">
              <cq-custom-price-cap class="mb-20"></cq-custom-price-cap>
              <button
                class="btn btn-primary"
                (click)="sendChatMessage(plan); trackIntentionToContactUs(plan)"
              >
                {{ t('planInfo.confirmButton.contactUs') }}
              </button>
            </ng-container>

            <ng-container *ngIf="!needsPersonalPlan && pricing.priceWithoutAddonsPerMonth">
              <div class="mb-15">
                <h3 class="mb-0 inline">
                  {{ pricing.discountedPriceWithAddonsPerMonth | toFormattedMoney }}
                </h3>
                <s *ngIf="pricing.discountedPriceWithAddonsPerMonth !== pricing.priceWithAddonsPerMonth">
                  {{ pricing.priceWithAddonsPerMonth | toFormattedMoney }}
                </s>
                <span class="ml-5">{{ t('planInfo.perMonth') }}</span>
              </div>

              <button
                class="btn btn-primary"
                (click)="handleClickOnCalculatePayment(plan, pricing)"
              >
                {{ t('planInfo.confirmButton.selectPlan') }}
              </button>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { CardComponent } from '@panel/app/pages/subscription/general/signed/payment-info/card/card.component';

@NgModule({
  declarations: [CardComponent],
  imports: [CommonModule, TranslocoModule, NgbDropdownModule],
  exports: [CardComponent],
})
export class CardModule {}

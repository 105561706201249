<ng-container *transloco="let t; read: 'stripeInputComponent'">
  <div>
    <div #stripeContainerRef></div>
    <div [cqShowError]="control">
      <input
        class="hidden"
        [formControl]="control"
      />
      <cq-validation-messages
        class="top"
        [control]="control"
      >
        <cq-validation-message errorName="required">{{ t('errors.required') }}</cq-validation-message>
        <cq-validation-message errorName="isError">{{ errorMessage | async }}</cq-validation-message>
      </cq-validation-messages>
    </div>
  </div>

  <div
    *ngIf="(isStipeElementsReady | async) === false"
    class="position-absolute top-0 left-0 right-0 bg-white text-center"
  >
    <i class="cqi-sm cqi-3x cqi-spinner inline-block spin text-success"></i>
  </div>
</ng-container>

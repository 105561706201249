import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import accounting from 'accounting';
import { copy } from 'angular';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { PLAN_CAPABILITIES_RELEASE_DATE } from '@http/plan-capability/plan-capability.constants';
import { ScaledAddonPrice } from '@panel/app/pages/subscription/pricing/pricing.service';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BILLING_ADD_ONS, BILLING_PLAN_IDS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import {
  BILLING_ADD_ON_PRICES_DEFAULT,
  BILLING_ADD_ON_PRICES_UPDATED,
  BILLING_UPDATE_DATE,
  CHOSEN_DISCOUNT_AFFILIATE,
  CHOSEN_DISCOUNT_DEFAULT,
  CHOSEN_DISCOUNT_UPDATED,
  DESCRIPTION_TRANSLATE_KEY_DEFAULT,
  DESCRIPTION_TRANSLATE_KEY_UPDATED,
  DISCOUNTS_BY_PERIOD_AFFILIATE,
  DISCOUNTS_BY_PERIOD_DEFAULT,
  DISCOUNTS_BY_PERIOD_UPDATED,
  LIMIT_UNIQUE_USERS_DEFAULT,
  LIMIT_UNIQUE_USERS_UPDATED,
  MONTHS_IN_DISCOUNTS_BY_PERIOD_AFFILIATE,
  MONTHS_IN_DISCOUNTS_BY_PERIOD_DEFAULT,
  MONTHS_IN_DISCOUNTS_BY_PERIOD_UPDATED,
  PLANS_SETTINGS_DEFAULT,
  PLANS_SETTINGS_UPDATED,
  PLANS_UPDATED,
  SCALE_UNIQUE_USERS_DEFAULT,
  SCALE_UNIQUE_USERS_UPDATED,
} from '@panel/app/services/billing-plan/billing-plan.constants';
import { FirstPromoterService } from '@panel/app/services/first-promoter/first-promoter.service';

/** Сервис для работы с тарифами */
@Injectable({ providedIn: 'root' })
export class BillingPlanService {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly billingInfoModel: BillingInfoModel,
    private readonly firstPromoterService: FirstPromoterService,
    private readonly planVersionService: PlanVersionService,
  ) {}

  /**
   * Получение стоимости аддона
   *
   * @param addOnId ID аддона, у которого нужно получить стоимость
   * @param currentApp Текущее приложение
   * @param uniqueUsers Количество уникальных пользователей в тарифе
   * @return addOnPrice Стоимость аддона
   */
  getAddOnPrice(addOnId: string, currentApp: App, uniqueUsers?: number): number {
    //@ts-ignore
    if (BILLING_ADD_ON_PRICES_DEFAULT[environment.country][addOnId] === undefined) {
      throw Error('Addon ID not found in BILLING_ADD_ON_PRICES_DEFAULT');
    }

    //@ts-ignore
    if (typeof BILLING_ADD_ON_PRICES_DEFAULT[environment.country][addOnId] === 'number') {
      //@ts-ignore
      return BILLING_ADD_ON_PRICES_DEFAULT[environment.country][addOnId];
    }

    //@ts-ignore
    if (BILLING_ADD_ON_PRICES_DEFAULT[environment.country][addOnId][uniqueUsers] === undefined) {
      throw Error(`Addon config with addon id ${addOnId} by ${uniqueUsers} uniqueUsers is undefined`);
    }

    if (currentApp.created.isAfter(BILLING_UPDATE_DATE.Y2024_M04_D17)) {
      //@ts-ignore
      return BILLING_ADD_ON_PRICES_UPDATED[BILLING_UPDATE_DATE.Y2024_M04_D17][environment.country][addOnId][
        uniqueUsers
      ];
    } else {
      //@ts-ignore
      return BILLING_ADD_ON_PRICES_DEFAULT[environment.country][addOnId][uniqueUsers];
    }
  }

  /**
   * Получение отформатированной стоимости аддона
   * Будет возвращено значение в виде «20 124 ₽»
   *
   * @param addOnId ID аддона
   * @param currentApp Текущее приложение
   * @param uniqueUsersPerMonth Количество уникальных пользователей в месяц
   * @param precision Количество знаков после запятой
   * @return parsedAddOnPrice Отформатированная стоимость аддона
   */
  getAddOnFormattedPrice(
    addOnId: string,
    currentApp: App,
    uniqueUsersPerMonth?: number,
    precision: number = 0,
  ): string {
    let _uniqueUsersPerMonth = 0;
    if (uniqueUsersPerMonth) {
      _uniqueUsersPerMonth = uniqueUsersPerMonth;
    } else if (this.billingInfoModel.planInfo?.limits?.users) {
      _uniqueUsersPerMonth = this.billingInfoModel.planInfo.limits.users / this.billingInfoModel.planInfo.period;
    }

    const addOnPrice = this.getAddOnPrice(addOnId, currentApp, _uniqueUsersPerMonth);
    return accounting.formatMoney(addOnPrice, undefined, precision);
  }

  /**
   * Получение объекта аддона, который используется внутри админки
   *
   * @param billingAddOnId ID аддона
   * @param currentApp Текущее приложение
   * @param uniqueUsers Количество уникальных пользователей в тарифе
   */
  getBillingAddOn(billingAddOnId: BILLING_ADD_ONS, currentApp: App, uniqueUsers?: number) {
    return {
      id: billingAddOnId,
      active: this.billingInfoModel.hasAddOn(this.billingInfoModel.billingInfo, billingAddOnId),
      name: this.transloco.translate('models.billingInfo.billingAddOns.' + billingAddOnId),
      price: this.getAddOnPrice(billingAddOnId, currentApp, uniqueUsers),
      img: `assets/img/default/subscription/add-ons/${billingAddOnId}.svg`,
    };
  }

  /**
   * Получение ID аддона «Без брендинга».
   * В зависимости от даты создания приложения ID аддонов будут отличаться
   *
   * @param currentApp Текущее приложение
   */
  getNoBrandingBillingAddOnId(
    currentApp: App,
  ): BILLING_ADD_ONS.NO_BRANDING | BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING {
    if (this.planVersionService.isV3(currentApp)) {
      return BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING;
    } else {
      return BILLING_ADD_ONS.NO_BRANDING;
    }
  }

  /**
   * Получение актуальной скидки, выбранной за пользователя
   *
   * @param currentApp - Текущий App
   * @param billingInfo - Текущий биллинг
   */
  getCurrentChosenDiscount(currentApp: App, billingInfo: any): any {
    let discount;

    switch (true) {
      case this.firstPromoterService.hasFirstPaymentDiscount():
        discount = CHOSEN_DISCOUNT_AFFILIATE[environment.country];
        break;
      case this.planVersionService.isV0421(currentApp):
        discount = CHOSEN_DISCOUNT_DEFAULT[environment.country];
        break;
      case this.planVersionService.isV1021(currentApp):
      case currentApp.created.isBefore(PLAN_CAPABILITIES_RELEASE_DATE[environment.country]):
        discount = CHOSEN_DISCOUNT_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26][environment.country];
        break;
      default:
        discount = CHOSEN_DISCOUNT_DEFAULT[environment.country];
    }

    return discount;
  }

  /**
   * Получение актуальных скидок при разных периодах оплаты на capability-тарифах
   *
   * @param currentApp - Текущий App
   * @param billingInfo - Текущий биллинг
   */
  getCurrentDiscountsByPeriod(currentApp: App, billingInfo: any): any {
    let currentDiscounts;

    switch (true) {
      case this.firstPromoterService.hasFirstPaymentDiscount():
        currentDiscounts = DISCOUNTS_BY_PERIOD_AFFILIATE[environment.country];
        break;
      case this.planVersionService.isV0421(currentApp):
        currentDiscounts = DISCOUNTS_BY_PERIOD_UPDATED[BILLING_UPDATE_DATE.Y2021_M04_Dxx][environment.country];
        break;
      case this.planVersionService.isV1021(currentApp):
        currentDiscounts = DISCOUNTS_BY_PERIOD_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26][environment.country];
        break;
      default:
        currentDiscounts = DISCOUNTS_BY_PERIOD_DEFAULT[environment.country];
    }

    return currentDiscounts;
  }

  /***
   * Получение количества месяцев в зависимости от размера скидки за период
   *
   * @param currentApp - Текущий App
   * @param billingInfo - Текущий биллинг
   */
  getCurrentMonthsInDiscountsByPeriod(currentApp: App, billingInfo: any): any {
    let currentMonths;

    switch (true) {
      case this.firstPromoterService.hasFirstPaymentDiscount():
        currentMonths = MONTHS_IN_DISCOUNTS_BY_PERIOD_AFFILIATE[environment.country];
        break;
      case this.planVersionService.isV0421(currentApp):
        currentMonths = MONTHS_IN_DISCOUNTS_BY_PERIOD_DEFAULT[environment.country];
        break;
      case this.planVersionService.isV1021(currentApp):
      case currentApp.created.isBefore(PLAN_CAPABILITIES_RELEASE_DATE[environment.country]):
        currentMonths = MONTHS_IN_DISCOUNTS_BY_PERIOD_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26][environment.country];
        break;
      default:
        currentMonths = MONTHS_IN_DISCOUNTS_BY_PERIOD_DEFAULT[environment.country];
    }

    return currentMonths;
  }

  /**
   * Получение актуального лимита уникальных пользователей на capability-тарифах
   *
   * @param currentApp - Текущий App
   * @param billingInfo - Текущий биллинг
   */
  getCurrentLimitUniqueUsers(currentApp: App, billingInfo: any): number {
    let currentLimit;

    switch (true) {
      case this.planVersionService.isV1(currentApp):
      case this.planVersionService.isV3(currentApp):
        currentLimit = LIMIT_UNIQUE_USERS_UPDATED[BILLING_UPDATE_DATE.Y2022_M11_D02][environment.country];
        break;
      case this.planVersionService.isV0421(currentApp):
        currentLimit = LIMIT_UNIQUE_USERS_DEFAULT[environment.country];
        break;
      case this.planVersionService.isV1021(currentApp):
        currentLimit = LIMIT_UNIQUE_USERS_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26][environment.country];
        break;
      default:
        currentLimit = LIMIT_UNIQUE_USERS_DEFAULT[environment.country];
    }

    return currentLimit;
  }

  /**
   * Получение актуального набора capability-тарифов
   *
   * HACK:
   *  Намеренно не указал тип, потому что данное решение - это костыль.
   *  Информацию о тарифах и их стоимости необходимо хранить на backend'е.
   *
   * @param currentApp - Текущий App
   * @param billingInfo - Текущий биллинг
   */
  getCurrentPlans(currentApp: App, billingInfo: any): any {
    let currentPlans;

    switch (true) {
      case this.planVersionService.isV0421(currentApp):
        currentPlans = BILLING_PLAN_IDS;
        break;
      case this.planVersionService.isV1021(currentApp):
      case currentApp.created.isBefore(PLAN_CAPABILITIES_RELEASE_DATE[environment.country]):
        currentPlans = PLANS_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26][environment.country];
        break;
      default:
        currentPlans = BILLING_PLAN_IDS;
    }

    return currentPlans;
  }

  /**
   * Получение актуальной конфигурации capability-тарифов
   *
   * HACK:
   *  Намеренно не указал тип, потому что данное решение - это костыль.
   *  Информацию о тарифах и их стоимости необходимо хранить на backend'е.
   *
   * @param currentApp - Текущий App
   * @param billingInfo - Текущий биллинг
   */
  getCurrentPlansSettings(currentApp: App, billingInfo: any): any {
    let currentSettings;

    switch (true) {
      case this.planVersionService.isV1(currentApp):
      case this.planVersionService.isV3(currentApp):
      case this.planVersionService.isTrial():
        if (currentApp.created.isAfter(BILLING_UPDATE_DATE.Y2024_M04_D17)) {
          currentSettings = PLANS_SETTINGS_UPDATED[BILLING_UPDATE_DATE.Y2024_M04_D17][environment.country];
        } else {
          currentSettings = PLANS_SETTINGS_DEFAULT[environment.country];
        }
        break;
      case this.planVersionService.isV0421(currentApp):
        currentSettings = PLANS_SETTINGS_DEFAULT[environment.country];
        break;
      case this.planVersionService.isV1021(currentApp):
        currentSettings = PLANS_SETTINGS_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26][environment.country];
        break;
      default:
        currentSettings = PLANS_SETTINGS_DEFAULT[environment.country];
    }

    return currentSettings;
  }

  /**
   * Получение актуальной шкалы уникальных пользователей на capability-тарифах
   *
   * HACK:
   *  Намеренно не указал тип, потому что данное решение - это костыль.
   *  Информацию о тарифах и их стоимости необходимо хранить на backend'е.
   *
   * @param currentApp - Текущий App
   * @param billingInfo - Текущий биллинг
   */
  getCurrentScaleUniqueUsers(currentApp: App, billingInfo: any): any {
    let currentScale;

    switch (true) {
      case this.planVersionService.isV1(currentApp):
      case this.planVersionService.isV3(currentApp):
        if (currentApp.created.isAfter(BILLING_UPDATE_DATE.Y2024_M04_D17)) {
          currentScale = copy(SCALE_UNIQUE_USERS_UPDATED[BILLING_UPDATE_DATE.Y2024_M04_D17][environment.country]);
        } else {
          currentScale = copy(SCALE_UNIQUE_USERS_UPDATED[BILLING_UPDATE_DATE.Y2022_M11_D02][environment.country]);
        }
        break;
      case this.planVersionService.isV0421(currentApp):
        currentScale = copy(SCALE_UNIQUE_USERS_DEFAULT[environment.country]);
        break;
      case this.planVersionService.isV1021(currentApp):
        currentScale = copy(SCALE_UNIQUE_USERS_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26][environment.country]);
        break;
      default:
        currentScale = copy(SCALE_UNIQUE_USERS_DEFAULT[environment.country]);
    }

    currentScale.push({
      value: this.getCurrentLimitUniqueUsers(currentApp, billingInfo),
    });

    return currentScale;
  }

  /**
   * Получение актуального ключа перевода описания capability-тарифов
   *
   * @param currentApp - Текущий App
   * @param billingInfo - Текущий биллинг
   */
  getCurrentPlanDescriptionTranslateKey(currentApp: App, billingInfo: any): string {
    let currentDescriptionTranslateKey;

    switch (true) {
      case this.planVersionService.isV0421(currentApp):
        currentDescriptionTranslateKey = DESCRIPTION_TRANSLATE_KEY_DEFAULT[environment.country];
        break;
      case this.planVersionService.isV1021(currentApp):
      case currentApp.created.isBefore(PLAN_CAPABILITIES_RELEASE_DATE[environment.country]):
        currentDescriptionTranslateKey =
          DESCRIPTION_TRANSLATE_KEY_UPDATED[BILLING_UPDATE_DATE.Y2021_M10_D26][environment.country];
        break;
      default:
        currentDescriptionTranslateKey = DESCRIPTION_TRANSLATE_KEY_DEFAULT[environment.country];
    }

    return currentDescriptionTranslateKey;
  }

  /**
   * Получение переведённого названия аддона
   *
   * @param billingAddOn - аддон
   * @return {String}
   */
  getTranslatedAddOnName(billingAddOn: BILLING_ADD_ONS): string {
    return this.transloco.translate(`models.billingInfo.billingAddOns.${billingAddOn}`);
  }

  /**
   * Получение переведённых названий аддонов
   *
   * @param billingAddOns - массив аддонов
   * @return {String}
   */
  getTranslatedAddOnsName(billingAddOns: ScaledAddonPrice[]): string {
    return billingAddOns.length
      ? billingAddOns.map((addOn) => this.getTranslatedAddOnName(addOn.addon)).join(', ')
      : '';
  }
}

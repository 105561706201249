import { PLAN_FEATURE } from '../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';

/**
 * Контроллер для блока с событием пользователя
 */
(function () {
  'use strict';

  angular.module('myApp.userCard').controller('CqAddEventModalController', CqAddEventModalController);

  function CqAddEventModalController(
    $filter,
    $translate,
    $uibModal,
    carrotquestHelper,
    planFeatureAccessService,
    userModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToEventsEventTypesCustom = planFeatureAccessService.getAccess(
        PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
        vm.resolve.currentApp,
      );

      vm.addEvent = addEvent;
      vm.addEventProp = addEventProp;
      vm.carrotquestHelper = carrotquestHelper;
      vm.closeModal = closeModal;
      vm.deleteEventProp = deleteEventProp;
      vm.doAddEvent = doAddEvent;
      vm.eventProps = [];
      vm.eventTypes = vm.resolve.eventTypes;
      vm.newEvent = {};
      vm.userId = vm.resolve.userId;
    }

    function addEvent() {
      var addEventModal = $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('addEventModal.createEventModal.heading'),
              confirmButtonText: $translate.instant('addEventModal.createEventModal.confirmButtonText'),
              inputLabel: $translate.instant('addEventModal.createEventModal.inputLabel'),
              inputErrorText: $translate.instant('addEventModal.createEventModal.inputErrorText'),
            };
          },
        },
      });

      addEventModal.result.then(addEvent);

      function addEvent(eventName) {
        var newEvent = {
          group: $translate.instant('models.property.groups.events'),
          name: eventName,
          prettyName: eventName,
          active: true,
          groupOrder: 0,
          score: 0,
          visible: true,
        };

        // при добавлении нового события надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
        var existingEventByTypeName = $filter('filter')(vm.eventTypes, { name: eventName }, true)[0];
        var existingEventByFullName = $filter('filter')(vm.eventTypes, { prettyName: eventName }, true)[0];

        if (existingEventByTypeName || existingEventByFullName) {
          vm.newEvent.value = existingEventByTypeName?.name || existingEventByFullName?.name;
        } else {
          vm.eventTypes.push(newEvent);
          vm.newEvent.value = newEvent.name;
        }
      }
    }

    function addEventProp() {
      vm.eventProps.push({});
    }

    function deleteEventProp(index) {
      vm.eventProps.splice(index, 1);
    }

    function doAddEvent() {
      if (!vm.newEvent.value) {
        return;
      }

      carrotquestHelper.track('Пользователи - клик на "Добавить событие"', { Событие: vm.newEvent.value });

      var eventProps = {};
      for (var i = 0; i < vm.eventProps.length; i++) {
        eventProps[vm.eventProps[i].name] = vm.eventProps[i].value;
      }

      firstValueFrom(userModel.addEvent(vm.userId, vm.newEvent.value, eventProps)).then(vm.closeModal);
    }

    function closeModal(item) {
      vm.eventProps = [];
      vm.newEvent.value = '';
      vm.modalInstance.close(item);
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { TriggerChainBreadcrumbsModule } from '@panel/app/pages/trigger-chains/components/breadcrumbs/trigger-chain-breadcrumbs.module';
import { TriggerChainActionsModule } from '@panel/app/pages/trigger-chains/editor/components/actions/trigger-chain-actions.module';
import { TriggerChainCanvasModule } from '@panel/app/pages/trigger-chains/editor/components/canvas/trigger-chain-canvas.module';
import { TriggerChainStepEditorModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor.module';
import { TriggerChainTemplateModule } from '@panel/app/partials/modals/trigger-chain-template/trigger-chain-template.module';

import { TriggerChainEditorComponent } from './trigger-chain-editor.component';

@NgModule({
  declarations: [TriggerChainEditorComponent],
  exports: [TriggerChainEditorComponent],
  imports: [
    CommonModule,
    NgbTooltipModule,
    TranslocoModule,
    TriggerChainActionsModule,
    TriggerChainBreadcrumbsModule,
    TriggerChainCanvasModule,
    TriggerChainStepEditorModule,
    TriggerChainTemplateModule,
  ],
  providers: [],
})
export class TriggerChainEditorModule {}

<div class="modal-header">
  <h3 class="modal-title">{{ 'adminParamsModal.heading' | transloco }}</h3>
  <button
    class="close"
    (click)="ngbActiveModal.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>

<div class="modal-body">
  <form novalidate>
    <!--ВИДИМОЕ ИМЯ-->
    <div class="form-group row margin-bottom-30">
      <label class="col-3 col-form-label">{{ 'adminParamsModal.nameInput.label' | transloco }}</label>
      <div class="col-9">
        <div
          class="position-relative"
          [cqShowError]="adminParamsForm.controls.name"
        >
          <input
            class="form-control"
            [placeholder]="'adminParamsModal.nameInput.placeholder' | transloco"
            required
            [formControl]="adminParamsForm.controls.name"
          />

          <cq-validation-messages [control]="adminParamsForm.controls.name">
            <cq-validation-message errorName="required">
              {{ 'adminParamsModal.nameInput.errors.required' | transloco }}
            </cq-validation-message>
            <cq-validation-message errorName="maxlength">
              {{ 'adminParamsModal.nameInput.errors.maxlength' | transloco: { nameMaxLength: NAME_MAX_LENGTH } }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
        <small class="form-text">{{ 'adminParamsModal.nameInput.help' | transloco }}</small>
      </div>
    </div>

    <!--ВНУТРЕННЕЕ ИМЯ-->
    <div class="form-group row margin-bottom-30">
      <label class="col-3 col-form-label">{{ 'adminParamsModal.internalNameInput.label' | transloco }}</label>
      <div class="col-9">
        <div
          class="position-relative"
          [cqShowError]="adminParamsForm.controls.internalName"
        >
          <input
            class="form-control"
            [placeholder]="'adminParamsModal.internalNameInput.placeholder' | transloco"
            [formControl]="adminParamsForm.controls.internalName"
          />

          <cq-validation-messages [control]="adminParamsForm.controls.internalName">
            <cq-validation-message errorName="maxlength">
              {{
                'adminParamsModal.internalNameInput.errors.maxlength'
                  | transloco: { internalNameMaxLength: INTERNAL_NAME_MAX_LENGTH }
              }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
        <small class="form-text">{{ 'adminParamsModal.internalNameInput.help' | transloco }}</small>
      </div>
    </div>

    <!--АВАТАРКА-->
    <div class="form-group row margin-bottom-30 avatar-input">
      <label class="col-3 col-form-label">{{ 'adminParamsModal.changeAvatar.input.label' | transloco }}</label>
      <div class="col-9">
        <div class="margin-between-cols-15">
          <img
            class="avatar"
            [src]="newAvatar.preview || modalWindowParams.avatar"
          />
          <div
            class="position-relative inline-block"
            [cqShowError]="adminParamsForm.controls.avatar"
          >
            <label class="btn btn-sm btn-outline-primary">
              <span>{{ 'adminParamsModal.changeAvatar.input.changeAvatarButton' | transloco }}</span>
              <input
                class="hidden"
                type="file"
                [formControl]="adminParamsForm.controls.avatar"
                [attr.accept]="fileHelper.getAcceptableImagesExtensions(IMAGE_EXTENSION.GIF)"
                cqFile
                [(cqFileAsDataUrl)]="newAvatar.preview"
                [cqMaxSize]="AVATAR_MAX_SIZE"
              />
            </label>

            <cq-validation-messages [control]="adminParamsForm.controls.avatar">
              <cq-validation-message errorName="accept">
                {{ 'adminParamsModal.changeAvatar.input.errors.accept' | transloco }}
              </cq-validation-message>
              <cq-validation-message errorName="maxSize">
                {{
                  'adminParamsModal.changeAvatar.input.errors.maxSize'
                    | transloco: { maxFileSize: AVATAR_MAX_SIZE / 1000000 | number: '1.0-0' }
                }}
              </cq-validation-message>
              <cq-validation-message errorName="uploadAvatarError">
                {{ 'adminParamsModal.changeAvatar.input.errors.uploadAvatarError' | transloco }}
              </cq-validation-message>
            </cq-validation-messages>
          </div>
          <button
            *ngIf="newAvatar.file"
            class="btn btn-sm btn-borderless-primary"
            type="button"
            (click)="setDefaultAvatar()"
          >
            {{ 'adminParamsModal.changeAvatar.setPrevAvatar' | transloco }}
          </button>
        </div>
        <small
          class="form-text"
          [innerHTML]="
            'adminParamsModal.changeAvatar.help'
              | transloco: { maxFileSize: AVATAR_MAX_SIZE / 1000000 | number: '1.0-0' }
          "
        >
        </small>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button
    class="btn btn-outline-primary"
    type="button"
    (click)="ngbActiveModal.dismiss()"
  >
    {{ 'general.cancel' | transloco }}
  </button>
  <button
    class="btn btn-primary"
    type="button"
    [ngClass]="{ disabled: isApiRequestPerformed }"
    (click)="saveAdminParams(adminParamsForm.valid)"
  >
    {{ 'adminParamsModal.changeButton' | transloco }}
  </button>
</div>

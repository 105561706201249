<ng-container *transloco="let t">
  <h3
    class="mb-20"
    *ngIf="!hideDefaultHeading"
    [ngClass]="{ 'font-weight-bolder': isBoldHeading }"
  >
    {{ customTranslations?.heading ?? t('integrationNameControl.heading') }}
  </h3>
  <div
    class="form-group mb-0"
    [cqShowError]="control"
  >
    <input
      class="form-control"
      [attr.disabled]="disabled ? disabled : null"
      [formControl]="control"
      [placeholder]="customTranslations?.input?.placeholder ?? t('integrationNameControl.input.placeholder')"
      type="text"
    />
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ customTranslations?.input?.errors?.required ?? t('integrationNameControl.input.errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{
          customTranslations?.input?.errors?.maxLength ??
            t('integrationNameControl.input.errors.maxLength', { maxLength: INTEGRATION_NAME_MAX_LENGTH })
        }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { Injectable } from '@angular/core';

import { environment } from '@environment';
import { APP_TASKS } from '@http/app/app.constants';
import { AnswerToIntermediateQuestion, Question } from '@panel/app/pages/questions/questions.type';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  readonly country = environment.country;

  readonly schema: Record<'RU' | 'US', Question[]> = {
    RU: [
      {
        id: 1,
        title: 'questionsComponent.question1.heading',
        track: {
          event: 'Onboarding v2 - Объем трафика',
          prop: 'Объем трафика',
        },
        answers: [
          {
            id: 1,
            text: 'questionsComponent.question1.answers.answer1',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 2,
            text: 'questionsComponent.question1.answers.answer2',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 2,
            text: 'questionsComponent.question1.answers.answer3',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 3,
            text: 'questionsComponent.question1.answers.answer4',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 4,
            text: 'questionsComponent.question1.answers.answer5',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 5,
            text: 'questionsComponent.question1.answers.answer6',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 6,
            text: 'questionsComponent.question1.answers.answer7',
            nextQuestionId: 2,
            isLast: false,
          },
        ],
      },
      {
        id: 2,
        title: 'questionsComponent.question2.heading',
        answers: [
          {
            id: 1,
            text: 'models.appModel.appTasks.talkToCustomers.text',
            task: APP_TASKS.TALK_TO_CUSTOMERS,
            isLast: true,
            event: 'Onboarding v2 - выбрал задачу',
          },
          {
            id: 2,
            text: 'models.appModel.emptyQuestion.raiseWebsiteConversation',
            nextQuestionId: 3,
            isLast: false,
          },
          {
            id: 3,
            text: 'models.appModel.appTasks.supportCustomers.text',
            task: APP_TASKS.SUPPORT_CUSTOMERS,
            isLast: true,
            event: 'Onboarding v2 - выбрал задачу',
          },
          {
            id: 4,
            text: 'models.appModel.appTasks.other.text',
            isLast: true,
            task: APP_TASKS.OTHER,
            event: 'Onboarding v2 - выбрал задачу',
          },
        ],
      },
      {
        id: 3,
        title: 'questionsComponent.question3.heading',
        answers: [
          {
            id: 1,
            text: 'models.appModel.appTasks.raisePurchaseConversion.text',
            task: APP_TASKS.RAISE_PURCHASE_CONVERSION,
            isLast: true,
            event: 'Onboarding v2 - выбрал самостоятельную покупку',
          },
          {
            id: 2,
            text: 'models.appModel.appTasks.raiseLeadConversion.text',
            task: APP_TASKS.RAISE_LEAD_CONVERSION,
            isLast: true,
            event: 'Onboarding v2 - выбрал покупку через менеджера',
          },
        ],
      },
    ],
    US: [
      {
        id: 1,
        title: 'questionsComponent.question1.heading',
        track: {
          event: 'Qualification — department',
          prop: 'Qualification — department',
        },
        answers: [
          {
            id: 1,
            text: 'questionsComponent.question1.answers.answer1',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 2,
            text: 'questionsComponent.question1.answers.answer2',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 3,
            text: 'questionsComponent.question1.answers.answer3',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 4,
            text: 'questionsComponent.question1.answers.answer4',
            nextQuestionId: 2,
            isLast: false,
          },
          {
            id: 5,
            text: 'questionsComponent.question1.answers.answer5',
            nextQuestionId: 2,
            isLast: false,
          },
        ],
      },
      {
        id: 2,
        title: 'questionsComponent.question2.heading',
        track: {
          event: 'Qualification — industry',
          prop: 'Qualification — industry',
        },
        answers: [
          {
            id: 1,
            text: 'questionsComponent.question2.answers.answer1',
            nextQuestionId: 3,
            isLast: false,
          },
          {
            id: 2,
            text: 'questionsComponent.question2.answers.answer2',
            nextQuestionId: 3,
            isLast: false,
          },
          {
            id: 3,
            text: 'questionsComponent.question2.answers.answer3',
            nextQuestionId: 3,
            isLast: false,
          },
          {
            id: 4,
            text: 'questionsComponent.question2.answers.answer4',
            nextQuestionId: 3,
            isLast: false,
          },
          {
            id: 5,
            text: 'questionsComponent.question2.answers.answer5',
            nextQuestionId: 3,
            isLast: false,
          },
          {
            id: 6,
            text: 'questionsComponent.question2.answers.answer6',
            nextQuestionId: 3,
            isLast: false,
          },
        ],
      },
      {
        id: 3,
        title: 'questionsComponent.question3.heading',
        track: {
          event: 'Qualification — budget',
          prop: 'Qualification — budget',
        },
        answers: [
          {
            id: 1,
            text: 'questionsComponent.question3.answers.answer1',
            nextQuestionId: 4,
            isLast: false,
          },
          {
            id: 2,
            text: 'questionsComponent.question3.answers.answer2',
            nextQuestionId: 4,
            isLast: false,
          },
          {
            id: 3,
            text: 'questionsComponent.question3.answers.answer3',
            nextQuestionId: 4,
            isLast: false,
          },
          {
            id: 4,
            text: 'questionsComponent.question3.answers.answer4',
            nextQuestionId: 4,
            isLast: false,
          },
          {
            id: 5,
            text: 'questionsComponent.question3.answers.answer5',
            nextQuestionId: 4,
            isLast: false,
          },
          {
            id: 6,
            text: 'questionsComponent.question3.answers.answer6',
            nextQuestionId: 4,
            isLast: false,
          },
        ],
      },
      {
        id: 4,
        title: 'questionsComponent.question4.heading',
        track: {
          event: 'Qualification — sales managers',
          prop: 'Qualification — sales managers',
        },
        answers: [
          {
            id: 1,
            text: 'questionsComponent.question4.answers.answer1',
            task: APP_TASKS.TALK_TO_CUSTOMERS,
            isLast: true,
          },
          {
            id: 2,
            text: 'questionsComponent.question4.answers.answer2',
            task: APP_TASKS.TALK_TO_CUSTOMERS,
            isLast: true,
          },
          {
            id: 3,
            text: 'questionsComponent.question4.answers.answer3',
            task: APP_TASKS.TALK_TO_CUSTOMERS,
            isLast: true,
          },
          {
            id: 4,
            text: 'questionsComponent.question4.answers.answer4',
            task: APP_TASKS.TALK_TO_CUSTOMERS,
            isLast: true,
          },
          {
            id: 5,
            text: 'questionsComponent.question4.answers.answer5',
            task: APP_TASKS.TALK_TO_CUSTOMERS,
            isLast: true,
          },
        ],
      },
    ],
  };

  /** Схема вопроса для конкретного региона */
  get questionsSchema(): Question[] {
    return this.schema[this.country];
  }

  /** Имеет ли опросник линейную структуру вопросов */
  isLinearQuestionsStructure(questions: Question[]): boolean {
    return !questions.some((question, index) => {
      const answers = question.answers;

      const isIntermediateAnswerExist = !!answers.find((answer) => !answer.isLast);
      const isLastAnswerExist = !!answers.find((answer) => answer.isLast);

      return isIntermediateAnswerExist && isLastAnswerExist;
    });
  }

  /** Получение следующего по id */
  getNextQuestion(answer: AnswerToIntermediateQuestion): Question {
    return this.questionsSchema.find((question) => question.id === answer.nextQuestionId)!;
  }

  /** Получение вопроса по id */
  getQuestionById(id: number): Question | undefined {
    return this.questionsSchema.find((question) => question.id === id);
  }
}

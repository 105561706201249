import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';

import { DatepickerPageComponent } from '@panel/app/pages/visual-components/datepicker/datepicker-page.component';
import { DaterangepickerPageComponent } from '@panel/app/pages/visual-components/daterangepicker/daterangepicker-page.component';
import { GetStartedPageComponent } from '@panel/app/pages/visual-components/get-started/get-started-page.component';
import { TimepickerPageComponent } from '@panel/app/pages/visual-components/timepicker/timepicker-page.component';
import { VisualComponentsPageComponent } from '@panel/app/pages/visual-components/visual-components-page.component';

export const VISUAL_COMPONENT_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    parent: 'app.content',
    name: 'visualComponents',
    url: '/visual-components',
    component: VisualComponentsPageComponent,
  },
  {
    parent: 'visualComponents',
    name: 'datepicker',
    url: '/datepicker',
    component: DatepickerPageComponent,
  },
  {
    parent: 'visualComponents',
    name: 'daterangepicker',
    url: '/daterangepicker',
    component: DaterangepickerPageComponent,
  },
  {
    parent: 'visualComponents',
    name: 'getStarted',
    url: '/get-started',
    component: GetStartedPageComponent,
  },
  {
    parent: 'visualComponents',
    name: 'timepicker',
    url: '/timepicker',
    component: TimepickerPageComponent,
  },
];

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TagTextComponent } from './tag-text.component';

@NgModule({
  declarations: [TagTextComponent],
  exports: [TagTextComponent],
  imports: [CommonModule],
})
export class TagTextModule {}

import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { LS_ONE_TIME_FLAGS } from '@panel/app/shared/constants/localstorage.keys';

/**
 * Сервис для работы с функционалом, который нужно выполнить один раз за время жизни пользователя.
 * Например, нужно показать онбординговую модалку только один раз.
 * Работы сервиса основана на хранении флагов в localStorage.
 * Список всех таких флагов находится в ONE_TIME_FLAGS
 *
 * !!! Это дубль сервиса из нового Angular. Это сделано из-за того, что сервис используется ещё и в AngularJS
 */
export class OneTimeFlagHelper {
  /**
   * Получить список выставленных флагов
   *
   * @private
   */
  public getList(): Array<ONE_TIME_FLAGS | null> {
    return JSON.parse(localStorage.getItem(LS_ONE_TIME_FLAGS) || '[]');
  }

  /**
   * Проверка содержится ли флаг в общем списке выставленных флагов
   *
   * @param flag Выставляемый флаг
   * @private
   */
  public isSet(flag: ONE_TIME_FLAGS): boolean {
    let currentValues = this.getList();

    return currentValues.includes(flag);
  }

  /**
   * Записать флаг в список выставленных флагов
   *
   * @param flag Выставляемый флаг
   */
  public set(flag: ONE_TIME_FLAGS): void {
    if (!this.isSet(flag)) {
      let currentValues = this.getList();

      currentValues.push(flag);
      localStorage.setItem(LS_ONE_TIME_FLAGS, JSON.stringify(currentValues));
    }
  }
}

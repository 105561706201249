<ng-container *transloco="let t; read: 'closeTimeoutControlComponent'">
  <h4 class="mb-5">{{ t('heading') }}</h4>
  <div class="mb-10 text-secondary">{{ t('description') }}</div>
  <div class="d-flex align-items-center margin-between-cols-10">
    <span>{{ t('text1') }}</span>
    <div [cqShowError]="control">
      <input
        class="form-control"
        cqNumber
        [formControl]="control"
      />
      <cq-validation-messages [control]="control">
        <cq-validation-message errorName="required">
          {{ t('input.errors.required') }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <span>{{ t('text2', { value: control.value }) }}</span>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./leads-import.controller');
  require('./rows-with-errors-popover.html');

  angular.module('myApp.users').component('cqLeadsImport', cqLeadsImport());

  function cqLeadsImport() {
    return {
      bindings: {
        currentApp: '=', // Приложение
        djangoUser: '=', // Текущий пользователь
        userProps: '=', // Свойства пользователя
        tags: '=', // теги
        telegramIntegrations: '=', // Список Telegram интеграций
      },
      controller: 'CqLeadsImportController',
      controllerAs: 'vm',
      template: require('./leads-import.html'),
    };
  }
})();

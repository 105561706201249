<ng-container *transloco="let t">
  <h3>{{ t('emailNotificationComment.heading') }}</h3>
  <div class="margin-bottom-20">{{ t('emailNotificationComment.description') }}</div>
  <div
    class="form-control-container"
    [cqShowError]="control"
  >
    <textarea
      class="form-control no-resize"
      [formControl]="control"
      cqAutosize
      placeholder="{{ t('emailNotificationComment.textarea.placeholder') }}"
      rows="3"
    ></textarea>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="maxlength">{{
        t('emailNotificationComment.textarea.errors.maxlength', { maxLength: COMMENT_MAX_LENGTH })
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

/**
 * Интерполяция, ищет {{key}}, заменяет на значение переданное в params по ключу key.
 * Еслт нет значения для замены, оставляет как есть
 */
export function interpolate(template: string, params: { [key: string]: any }): string {
  let response = template;

  Object.keys(params).forEach((findString) => {
    response = response.replace(/\{\{(.*?)\}\}/g, (match, key) => {
      return params[key.trim()] ?? match;
    });
  });

  return response;
}

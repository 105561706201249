import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { VK_FORMS_CUSTOM_EVENT_TYPE_NAME_MAX_LENGTH } from '@http/integration/integrations/vk-forms/constants/vk-forms-integration.constants';
import { VkFormsIntegrationFormExternal } from '@http/integration/integrations/vk-forms/interfaces/vk-forms-integration.interfaces';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

/* eslint-disable change-detection-strategy/on-push */
@Component({
  selector: 'cq-vk-forms-custom-event[control][formIndex]',
  templateUrl: './vk-forms-custom-event.component.html',
  styleUrls: ['./vk-forms-custom-event.component.scss'],
})
export class VkFormsCustomEventComponent implements OnInit {
  /** Control для настройки кастомного события */
  @Input() control!: GenericFormControl<VkFormsIntegrationFormExternal['eventTypeName']>;
  /** Порядковый индекс формы сбора заявок */
  @Input() formIndex!: number;
  /** Максимальная длина названия для события при появлении лида */
  CUSTOM_EVENT_TYPE_NAME_MAX_LENGTH = VK_FORMS_CUSTOM_EVENT_TYPE_NAME_MAX_LENGTH;
  /** Название проекта */
  projectName = environment.projectName;

  constructor(private readonly translocoService: TranslocoService) {}

  /** Получение имени кастомного события */
  get eventTypeName(): VkFormsIntegrationFormExternal['eventTypeName'] {
    return this.control.value;
  }

  /**
   * Установлено название собития или нет
   */
  get hasName(): boolean {
    return !!this.control.value;
  }

  ngOnInit(): void {
    if (this.eventTypeName === null) {
      this.control.disable();
    }
  }

  /** Получение кастомного имени события */
  getCustomEventTypeName(): string {
    let customEventName = '';
    let defaultEventName = this.translocoService.translate('vkFormsCustomEventComponent.defaultEventTypeName');

    customEventName = this.translocoService.translate('vkFormsCustomEventComponent.customTemplate', {
      defaultEvent: defaultEventName,
      formIndex: this.formIndex + 1,
    });

    return customEventName;
  }

  /**
   * Обработчик переключения свитча включения/выключения отправки кастомного события при заполнении формы
   *
   * @param toggleValue - Значение в зависимости от положения свитча
   */
  toggleHandler(toggleValue: boolean): void {
    if (toggleValue) {
      this.control.setValue(this.getCustomEventTypeName());
      this.control.enable();
    } else {
      this.control.setValue(null);
      this.control.disable();
    }
  }
}

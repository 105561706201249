import { inject } from '@angular/core';
import { IPromise } from 'angular';

import { CreateTagComponent } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/create-tag/create-tag.component';
import { RemoveTagComponent } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/remove-tag/remove-tag.component';
import { ManageTagsStore } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.store';
import { ModalData } from '@panel/app/shared/modals/modal/modal.token';
import { ConversationTag } from '@panel/app-old/shared/entities/conversation-tag/conversation-tag';
import { ConversationTagHttpService } from '@panel/app-old/shared/entities/conversation-tag/http/conversation-tag-http.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Help-сервис для работы с тегами диалогов */
export class ConversationTagHelpService {
  /** Инстанс модального окна для работы с созданием тега */
  private createTagModal: any;

  /** Store-сервис из Angular, поэтому inject в обход конструктора */
  private readonly manageTagsStore: ManageTagsStore = inject(ManageTagsStore);

  /** Инстанс модального окна для работы с удалением тега */
  private removeTagModal: any;

  //@ngInject
  constructor(
    private readonly $translate: any,
    private readonly $uibModal: any,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly conversationTagHttpService: ConversationTagHttpService,
  ) {}

  /** Закрывает модальное окно создания тега */
  closeCreateTagModal(): void {
    this.createTagModal.close();
    this.createTagModal = null;
  }

  /** Закрывает модальное окно удаления тега */
  closeRemoveTagModal(): void {
    this.removeTagModal.close();
    this.removeTagModal = null;
  }

  /**
   * Создаёт тег
   *
   * @param tag - Тег
   */
  createTag(tag: ConversationTag): IPromise<void> {
    return this.conversationTagHttpService.create(tag);
  }

  /** Открывает модальное окно создания тега */
  openCreateTagModal(): void {
    const data: ModalData = {
      component: CreateTagComponent,
      outputs: {
        createTag: (tag: ConversationTag) => {
          this.createTag(tag).then(() => {
            this.closeCreateTagModal();

            this.manageTagsStore.tags$.add(tag);

            this.trackAddingTag();
          });
        },
      },
      title: this.$translate.instant('conversationTag.modals.createTagModal.title'),
    };

    return this.$uibModal.open({
      component: 'cqModalWrapper',
      resolve: {
        data: data,
      },
    });
  }

  /**
   * Открывает модальное окно удаления тега
   *
   * @param tag - Тег
   */
  openRemoveTagModal(tag: ConversationTag): void {
    const data: ModalData = {
      component: RemoveTagComponent,
      inputs: {
        tag: tag,
      },
      outputs: {
        removeTag: (removedTag: ConversationTag) => {
          this.removeTag(removedTag).then(() => {
            this.closeRemoveTagModal();

            this.manageTagsStore.tags$.remove(removedTag);

            this.trackRemovingTag();
          });
        },
      },
      title: this.$translate.instant('conversationTag.modals.removeTagModal.title'),
    };

    return this.$uibModal.open({
      component: 'cqModalWrapper',
      resolve: {
        data: data,
      },
    });
  }

  /**
   * Удаляет тег
   *
   * @param tag - Тег
   */
  removeTag(tag: ConversationTag): IPromise<void> {
    return this.conversationTagHttpService.remove(tag);
  }

  /** Запускает процесс создания тега */
  startCreateTagProcess(): void {
    this.createTagModal = this.openCreateTagModal();
  }

  /**
   * Запускает процесс удаления тега
   *
   * @param tag - Тег
   */
  startRemoveTagProcess(tag: ConversationTag): void {
    this.removeTagModal = this.openRemoveTagModal(tag);
  }

  /** Отменяет процесс создания тега */
  stopCreateTagProcess(): void {
    this.closeCreateTagModal();
  }

  /** Останавливает процесс удаления тега */
  stopRemoveTagProcess(): void {
    this.closeRemoveTagModal();
  }

  /** Трекает добавление тега */
  trackAddingTag(): void {
    const eventName = 'Настройки диалогов - добавил тег';

    this.carrotquestHelper.track(eventName);
  }

  /** Трекает удаление тега */
  trackRemovingTag(): void {
    const eventName = 'Настройки диалогов - удалил тег';

    this.carrotquestHelper.track(eventName);
  }
}

<ng-container *transloco="let t; read: 'amocrmDataToContactsComponent'">
  <h3 class="d-flex align-items-center font-weight-bolder">
    <i class="cqi-sm cqi-user-o mr-10"></i>
    <span [innerHTML]="t('heading.beforeContact', {integrationTypeName})"></span>
    <span
      class="dashed"
      [ngbTooltip]="tooltip"
      [innerHTML]="t('heading.contact')"
    ></span>
    <span [innerHTML]="t('heading.afterContact', {integrationTypeName})"></span>
    <ng-template #tooltip>
      <div [innerHTML]="t('tooltip', {integrationTypeName, projectName})"></div>
    </ng-template>
  </h3>

  <!-- При совершении каких событий передаются данные -->
  <hr />
  <cq-amocrm-event-triggers-for-contacts
    [control]="eventTypeIdsControl"
    [currentApp]="currentApp"
  ></cq-amocrm-event-triggers-for-contacts>

  <!-- Какие данные из Carrot quest передаются в контакты amoCRM -->
  <hr />
  <cq-amocrm-props-to-contact
    [integrationId]="integrationId"
    [userEventsTypesGroup]="userEventsTypesGroup"
    [userPropsGroup]="userPropsGroup"
  ></cq-amocrm-props-to-contact>
</ng-container>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AppService } from '@http/app/services/app.service';
import { Channel, ChannelModel } from '@http/channel/channel.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-channel-mute[channel]',
  templateUrl: './channel-mute.component.html',
  styleUrls: ['./channel-mute.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelMuteComponent implements OnInit {
  /** Канал */
  @Input()
  channel!: Channel;

  /** ID django-пользователя */
  @Input()
  djangoUserId!: string;

  /** Если канал замьючен - ему выставляется атрибут muted */
  @HostBinding('attr.muted')
  get muted() {
    if (this.isMuted) {
      return '';
    }

    return null;
  }

  /** Замьючен ли канал */
  isMuted: boolean = false;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly cdr: ChangeDetectorRef,
    private readonly channelModel: ChannelModel,
    private readonly appService: AppService,
  ) {}

  ngOnInit(): void {
    let mutedChannels: string[] = this.channelModel.getMutedChannelsFromLocalStorage();
    this.isMuted = mutedChannels.includes(this.channel.id || '');
  }

  /** Включить/выключить уведомление от канала */
  toggleChannelNotification(): void {
    let channelId = this.channel.id || '';
    let realMuteStatus = this.isMuted;

    // для правильного UX сразу мьютим канал, чтобы поменялась иконка
    this.isMuted = !this.isMuted;

    firstValueFrom(
      this.channelModel.toggleChannelNotification(
        channelId,
        realMuteStatus,
        this.appService.currentAppId,
        this.djangoUserId,
      ),
    )
      .then(() => {
        if (realMuteStatus) {
          this.trackClickUnmuteChannel();
        } else {
          this.trackClickMuteChannel();
        }
      })
      .catch(() => {
        // если во время запроса произошла ошибка - возвращаем предыдущий статус
        this.isMuted = realMuteStatus;
      })
      .finally(() => this.cdr.markForCheck());
  }

  /**
   * Трек клика по иконке выключения оповещений в канале
   */
  trackClickMuteChannel(): void {
    this.carrotquestHelper.track('Выключил оповещения о диалогах из канала', {
      app_id: this.appService.currentAppId,
      channel: this.channel.name,
    });
  }

  /**
   * Трек клика по иконке включения оповещений в канале
   */
  trackClickUnmuteChannel(): void {
    this.carrotquestHelper.track('Включил оповещения о диалогах из канала', {
      app_id: this.appService.currentAppId,
      channel: this.channel.name,
    });
  }
}

(function () {
  'use strict';

  angular.module('myApp.filters.toSentenceCase').filter('cqToSentenceCase', cqToSentenceCase);

  function cqToSentenceCase(caseStyleHelper) {
    return toSentenceCaseFilter;

    /**
     * Преобразование строки в sentence case
     *
     * @param {String} value Строка для преобразования
     * @returns {String}
     */
    function toSentenceCaseFilter(value) {
      return caseStyleHelper.toSentenceCase(value);
    }
  }
})();

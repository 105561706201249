<ng-container *transloco="let t; read: 'messageEditorAnotherEventsComponent'">
  <div class="mb-15">{{ t('anotherEventsDescription', { typeMessageName: entityName }) | capitalize }}</div>

  <div
    class="form-group no-space mb-0"
    *ngFor="let triggerControl of control.controls; index as index; last as last"
  >
    <cq-message-editor-another-event
      [ngClass]="!last ? 'pb-15' : 'pb-0'"
      [formControl]="triggerControl"
      [isOnlyEvent]="control.length === 1"
      (deleteTrigger)="removeTrigger(index)"
    ></cq-message-editor-another-event>
  </div>

  <button
    *ngIf="lastEventSelected()"
    class="btn btn-secondary w-100 mt-10"
    type="button"
    (click)="addTrigger(); trackAddTrigger()"
  >
    <i class="btn-icon-left cqi-sm cqi-plus"></i>
    <span> {{ t('addEventButton') }}</span>
  </button>

  <div class="text-muted mt-15">
    <i class="cqi-sm cqi-lightbulb"></i>
    <span>{{ t('chatUs.note') }}</span>
    <span
      class="text-primary cursor-pointer"
      (click)="openChat(); trackProblemsWithSelectOfTrigger()"
    >
      {{ t('chatUs.chat') }}
    </span>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

import { ManageTagsStore } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.store';
import { ConversationTag } from '@panel/app-old/shared/entities/conversation-tag/conversation-tag';
import { ConversationTagHelpService } from '@panel/app-old/shared/entities/conversation-tag/help/conversation-tag-help.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Компонент для работы с удалением тега */
@Component({
  selector: 'cq-remove-tag[tag][removeTag]',
  templateUrl: './remove-tag.component.html',
  styleUrls: ['./remove-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TranslocoDirective],
})
export class RemoveTagComponent implements OnInit {
  /** Тег */
  @Input()
  tag!: ConversationTag;

  /** Callback на удаление тега */
  @Output()
  removeTag: EventEmitter<ConversationTag> = new EventEmitter<ConversationTag>();

  displayedTagName: string = '';

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly conversationTagHelpService: ConversationTagHelpService,
    private readonly manageTagsStore: ManageTagsStore,
  ) {}

  ngOnInit() {
    // Заменяем { и } на коды символов для корректного отображения
    this.displayedTagName = this.tag.text.replace(/{/g, '&#123;').replace(/}/g, '&#125;');
  }

  /** Обрабатывает клик по кнопке отмены */
  onClickCancelButton(): void {
    this.stopTagRemoveProcess();
  }

  /** Обрабатывает клик по кнопке удаления */
  onClickRemoveButton(): void {
    this.removeTag.emit(this.tag);
  }

  /** Отменяет удаление тега */
  stopTagRemoveProcess(): void {
    this.conversationTagHelpService.stopRemoveTagProcess();
  }
}

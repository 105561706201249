<ng-container *transloco="let t">
  <div class="modal-header">
    <h3
      *ngIf="heading"
      class="modal-title"
      data-test="video-modal-heading"
    >
      {{ heading }}
    </h3>
    <button
      class="close"
      data-test="video-modal-close-button"
      type="button"
      (click)="closeModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div
      *ngIf="videoUrl"
      class="embed-responsive embed-responsive-16by9"
      data-test="video-modal-iframe-container"
    >
      <iframe
        class="embed-responsive-item"
        [attr.src]="videoUrl"
        allowfullscreen
        frameborder="0"
      ></iframe>
    </div>
  </div>

  <div class="modal-footer"></div>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';

import { App } from '@http/app/app.model';
import { NotificationSettings } from '@panel/app/pages/account/shared/notification-settings/types';

@Pipe({
  name: 'filterAppsWithNoAccess',
})
export class FilterAppsWithNoAccessPipe implements PipeTransform {
  transform(value: NotificationSettings['apps'], apps: App[]): NotificationSettings['apps'] {
    return value.filter((appSettings) => apps.some((app) => app.id === appSettings.appId));
  }
}

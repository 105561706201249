(function () {
  'use strict';

  require('./segment.controller');
  require('./steps/step1.html');
  require('./steps/step2.html');

  angular.module('myApp.integrations').component('cqSegment', cqSegment());

  function cqSegment() {
    return {
      bindings: {
        currentApp: '=', // текущий App
        integration: '=', // текущая интеграция
      },
      controller: 'CqSegmentController',
      controllerAs: 'vm',
      template: require('./segment.html'),
    };
  }
})();

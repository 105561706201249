import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ArticlesLimitationBannerComponent } from './articles-limitation-banner.component';

@NgModule({
  declarations: [ArticlesLimitationBannerComponent],
  exports: [ArticlesLimitationBannerComponent],
  imports: [CommonModule, TranslocoModule],
})
export class ArticlesLimitationBannerModule {}

(function () {
  'use strict';

  angular
    .module('myApp.emailTemplates')
    .component('cqLightweightConversationEmailTemplate', cqLightweightConversationEmailTemplate());

  function cqLightweightConversationEmailTemplate() {
    return {
      bindings: {
        currentApp: '=', // приложение
        messageSender: '=', // отправитель письма
      }, //controller: 'CqLightweightConversationEmailTemplateController',
      controllerAs: 'vm',
      template: require('./lightweight-conversation-email-template.html'),
    };
  }
})();

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { translate } from '@jsverse/transloco';
import { takeUntil } from 'rxjs/operators';

import {
  MEMBER_INVITATION_STATUS,
  MEMBER_INVITATION_STATUS_ARRAY,
} from '@http/team-member-invitation/constants/member-invitations.constants';
import { DestroyService } from '@panel/app/services';

/**
 * Компонент для работы с фильтрами приглашений членов команды
 */

@Component({
  selector: 'cq-member-invitations-filters[resetAllFiltersEmitter][filterByEmail][filterByStatus]',
  templateUrl: './member-invitations-filters.component.html',
  styleUrls: ['./member-invitations-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class MemberInvitationsFiltersComponent implements OnInit {
  /** Сброс всех фильтров */
  @Input()
  resetAllFiltersEmitter!: EventEmitter<void>;

  /** Callback на фильтр по Email */
  @Output()
  filterByEmail: EventEmitter<string | null> = new EventEmitter<string | null>();

  /** Callback на фильтр по статусу */
  @Output()
  filterByStatus: EventEmitter<MEMBER_INVITATION_STATUS | null> = new EventEmitter<MEMBER_INVITATION_STATUS | null>();

  /** Input ввода Email для фильтрации */
  @ViewChild('inputEmailPhrase', { static: false }) set element(element: ElementRef<HTMLElement>) {
    if (element) {
      this.inputEmailPhraseEl = element;
    }
  }

  /** Текущая фраза для фильтра по Email */
  currentFilterEmailPhrase: string | null = null;

  /** Текущий статус для фильтрации по статусу */
  currentFilterStatus: MEMBER_INVITATION_STATUS | null = null;

  /** Input ввода Email для фильтрации */
  inputEmailPhraseEl!: ElementRef<HTMLElement>;

  /** Активен ли режим фильтра по Email */
  isFilterByEmailMode: boolean = false;

  /** Массив статусов приглашений */
  MEMBER_INVITATION_STATUS_ARRAY = MEMBER_INVITATION_STATUS_ARRAY;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private readonly destroy$: DestroyService) {}

  ngOnInit() {
    this.resetAllFiltersEmitter.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.resetAllFilters();
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Получение активного класса для кнопки выбора статуса
   *
   * @param status - Статус
   */
  getActiveClassForStatusButton(status: MEMBER_INVITATION_STATUS | null): string {
    return this.currentFilterStatus === status ? 'active' : '';
  }

  /**
   * Получение перевода для статуса
   *
   * @param invitationStatus - Статус
   */
  getStatusTranslate(invitationStatus: MEMBER_INVITATION_STATUS): string {
    return translate(`memberInvitationsTableComponent.table.body.status.${invitationStatus}`);
  }

  /** Обработчик клика на кнопку фильтрации приглашений по Email */
  onClickFilterInvitationByEmailButton(): void {
    this.filterByEmail.emit(this.currentFilterEmailPhrase);
  }

  /**
   * Обработчик клика по кнопке фильтрации по статусу
   *
   * @param status - Статус
   */
  onClickFilterByStatusButton(status: MEMBER_INVITATION_STATUS | null): void {
    this.currentFilterStatus = status;
    this.filterByStatus.emit(this.currentFilterStatus);
  }

  /** Обработчик клика по кнопке переключения режима фильтрации по Email */
  onClickToggleFilterByEmailModeButton(): void {
    this.toggleFilterByEmailMode();

    this.changeDetectorRef.detectChanges();

    this.inputEmailPhraseEl.nativeElement.focus();
  }

  /** Переключение режима фильтрации по Email */
  toggleFilterByEmailMode(): void {
    if (this.isFilterByEmailMode) {
      this.resetFilterByEmail();
    } else {
      this.isFilterByEmailMode = true;
    }
  }

  /** Сброс всех фильтров */
  resetAllFilters(): void {
    this.resetFilterByStatus();
    this.resetFilterByEmail();
  }

  /** Сброс фильтра по Email'у */
  resetFilterByEmail(): void {
    this.isFilterByEmailMode = false;
    this.currentFilterEmailPhrase = null;
  }

  /** Сброс фильтра по статусу */
  resetFilterByStatus(): void {
    this.currentFilterStatus = null;
  }
}

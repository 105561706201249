import { Injectable, NgZone, TemplateRef, Type } from '@angular/core';
import { NgbPopover, NgbPopoverConfig, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Partial } from 'split.js';

import { BotBlocksOverlayService } from '../canvas-editor/canvas-overlay/bot-blocks-overlay.service';
import { BootstrapElementsFactory } from './factories/bootstrap-elements.factory';

@Injectable()
export class OverlayBootstrapElementsHelper {
  private errorTooltipsMap: Map<string, NgbTooltip> = new Map();
  private popoversMap: Map<string, NgbPopover> = new Map();
  private tooltipsMap: Map<string, NgbTooltip> = new Map();

  constructor(
    private readonly bootstrapElementsFactory: BootstrapElementsFactory,
    private readonly botBlocksOverlayService: BotBlocksOverlayService,
    private readonly ngZone: NgZone,
  ) {}

  /*
   * Error tooltips methods
   */
  public openErrorTooltip(uid: string) {
    this.errorTooltipsMap.get(uid)?.open();
  }

  public closeErrorTooltip(uid: string) {
    this.errorTooltipsMap.get(uid)?.close();
  }

  public createErrorTooltip(uid: string, tooltipContent: string | TemplateRef<any>) {
    if (this.errorTooltipsMap.get(uid)) return;

    const tooltip = this.createBaseTooltip(uid);
    if (!tooltip) return;

    tooltip.ngbTooltip = tooltipContent;
    tooltip.tooltipClass = 'tooltip-danger';
    tooltip.autoClose = false;

    this.errorTooltipsMap.set(uid, tooltip);
  }

  /*
   * Default tooltips methods
   */
  public openTooltip(uid: string) {
    this.tooltipsMap.get(uid)?.open();
  }

  public closeTooltip(uid: string) {
    this.tooltipsMap.get(uid)?.close();
  }

  public createTooltip(uid: string, tooltipContent: string | TemplateRef<any>) {
    if (this.tooltipsMap.get(uid)) return;

    const tooltip = this.createBaseTooltip(uid);
    if (!tooltip) return;

    tooltip.ngbTooltip = tooltipContent;
    tooltip.placement = 'bottom';

    this.tooltipsMap.set(uid, tooltip);
  }

  /*
   * Popovers methods
   */
  public openPopover(uid: string) {
    const popover = this.popoversMap.get(uid);

    if (!popover) {
      throw new Error('Could not find popover');
    }

    //NOTE Это надо для немедленного появления поповера
    this.ngZone.run(() => {
      popover.open();
    });
  }

  public closePopover(uid: string) {
    this.popoversMap.get(uid)?.close();
  }

  public createPopover<T extends { templateRef: TemplateRef<any> } & Record<string, any>>(
    uid: string,
    popoverContent: string | Type<T>,
    customConfig?: Partial<NgbPopoverConfig>,
    inputs: Partial<{ [K in keyof T]: T[K] }> = {},
  ): NgbPopover | undefined {
    if (this.popoversMap.get(uid)) {
      return;
    }

    const overlayComponent = this.botBlocksOverlayService.getOverlayComponent(uid);
    let content: string | TemplateRef<any>;

    if (!overlayComponent) return;

    const popover = this.bootstrapElementsFactory.createPopover(
      overlayComponent.element,
      overlayComponent.viewContainerRef,
      customConfig,
    );

    if (typeof popoverContent !== 'string') {
      const popoverTemplate = overlayComponent.viewContainerRef.createComponent<T>(popoverContent);

      Object.keys(inputs).forEach((key) => {
        //@ts-ignore
        popoverTemplate.instance[key] = inputs[key];
      });

      //NOTE без этого не произойдет onInit PopoverTemplateComponent
      this.ngZone.run(() => {
        overlayComponent.changeDetectorRef.markForCheck();
      });

      content = popoverTemplate.instance.templateRef;
    } else {
      content = popoverContent;
    }

    popover.ngbPopover = content;

    this.popoversMap.set(uid, popover);

    return popover;
  }

  private createBaseTooltip(uid: string): NgbTooltip | undefined {
    const overlayComponent = this.botBlocksOverlayService.getOverlayComponent(uid);

    if (!overlayComponent) return;

    const tooltip = this.bootstrapElementsFactory.createTooltip(
      overlayComponent.element,
      overlayComponent.viewContainerRef,
    );

    return tooltip;
  }

  destroyPopover(uid: string) {
    this.popoversMap.delete(uid);
  }
}

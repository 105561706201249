import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { TriggerChainStepNameEditorModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-name-editor/trigger-chain-step-name-editor.module';

import { TriggerChainStepEditorWrapComponent } from './trigger-chain-step-editor-wrap.component';

@NgModule({
  declarations: [TriggerChainStepEditorWrapComponent],
  exports: [TriggerChainStepEditorWrapComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, TriggerChainStepNameEditorModule, NgbDropdownModule],
})
export class TriggerChainStepEditorWrapModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { UserPresenceStatus } from '@http/user/types/user.type';

@Component({
  selector: 'cq-user-avatar[avatar]',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  /**
   * Аватар пользователя
   */
  @Input()
  avatar!: any;

  /**
   * Статус пользователя
   */
  @Input()
  status?: UserPresenceStatus;

  /**
   * Размер аватарки
   */
  @Input()
  size: 'lg' | 'md' | 'xs' = 'md';

  constructor(private readonly sanitizer: DomSanitizer) {}

  /**
   * Получение "надежного" HTML аватарки
   */
  get trustedAvatar(): SafeHtml | void {
    // HACK: в this.avatar может прийти undefined из-за того, что аватарка получается асинхронно, но в старом коде вокруг эта асинхронность не учитывается
    if (!this.avatar) {
      return;
    }
    if (typeof this.avatar === 'string') {
      return this.sanitizer.bypassSecurityTrustHtml(this.avatar);
    }
    return this.avatar;
  }

  /**
   * Получение CSS класса для соответствующего статуса
   */
  get statusCssClass(): string {
    switch (this.status) {
      case 'online':
        return 'bg-success';
      case 'idle':
        return 'bg-warning';
      case 'offline':
        return 'bg-danger';
      default:
        return '';
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ArticlePreviewComponent } from './article-preview.component';

@NgModule({
  declarations: [ArticlePreviewComponent],
  exports: [ArticlePreviewComponent],
  imports: [CommonModule],
})
export class ArticlePreviewModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';
import { NgArrayPipesModule } from 'ngx-pipes';

import { EventTypesComponent } from '@panel/app/pages/data-collection/shared/event-types/event-types.component';

@NgModule({
  declarations: [EventTypesComponent],
  imports: [CommonModule, TranslocoModule, NgArrayPipesModule, UIRouterModule],
  exports: [EventTypesComponent],
})
export class EventTypesModule {}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

/** Максимальная длина текста правила */
export const BOT_RULE_MAX_LENGTH = 150;

@Component({
  selector: 'cq-bot-rule-control',
  templateUrl: './bot-rule-control.component.html',
  styleUrls: ['./bot-rule-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotRuleControlComponent extends AbsCVAFormControlBasedComponent<string | null> {
  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
    validators: [Validators.maxLength(BOT_RULE_MAX_LENGTH)],
  });

  BOT_RULE_MAX_LENGTH = BOT_RULE_MAX_LENGTH;

  constructor() {
    super();
  }
}

<div>
  <div class="d-flex align-items-center py-15">
    <cq-switch-wrapper
      class="mr-15"
      *ngIf="accessToAutoMessagesControlGroup.hasAccess"
      [(value)]="controlGroupSwitch"
      (valueChange)="changeControlGroupSwitch($event)"
    ></cq-switch-wrapper>
    <cq-access-denial-popover
      *ngIf="!accessToAutoMessagesControlGroup.hasAccess"
      [currentApp]="currentApp"
      [denialReason]="accessToAutoMessagesControlGroup.denialReason"
      [popoverPlacement]="'bottom'"
    >
      <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
      <cq-switch-wrapper
        class="mr-15"
        [disabled]="true"
        [value]="false"
      ></cq-switch-wrapper>
    </cq-access-denial-popover>
    <div class="white-space-nowrap">
      <h5
        class="d-inline-block mb-0 font-weight-bold"
        [innerHTML]="messagePageType + '.editor.controlGroup.heading' | transloco"
      ></h5>
      <i
        class="cqi-sm cqi-question-circle"
        [ngbTooltip]="
          messagePageType + '.editor.controlGroup.headingTooltip' | transloco: { messageTypeGroup: messagePartTypeName }
        "
      ></i>
    </div>
  </div>

  <div
    *ngIf="currentMessage.isControlGroupEnabled === true"
    class="ml-50 mb-15"
  >
    <div
      class="pb-15 text-secondary"
      [innerHTML]="
        messagePageType + '.editor.controlGroup.description' | transloco: { messageTypeGroup: messagePartTypeName }
      "
    ></div>
    <div class="flex align-items-center">
      <div
        class="form-group percent flex align-items-center mr-0 mb-0"
        [cqShowError]="messageForm.controls.controlGroupProportion"
      >
        <input
          class="form-control percent-input"
          [formControl]="messageForm.controls.controlGroupProportion"
        />
        <span class="ml-10">%</span>
        <cq-validation-messages [control]="messageForm.controls.controlGroupProportion">
          <cq-validation-message errorName="required">
            {{ messagePageType + '.editor.controlGroup.enabled.proportionInput.errors.required' | transloco }}
          </cq-validation-message>
          <cq-validation-message errorName="float">
            {{ messagePageType + '.editor.controlGroup.enabled.proportionInput.errors.float' | transloco }}
          </cq-validation-message>
          <cq-validation-message errorName="min">
            {{ messagePageType + '.editor.controlGroup.enabled.proportionInput.errors.min' | transloco }}
          </cq-validation-message>
          <cq-validation-message errorName="max">
            {{ messagePageType + '.editor.controlGroup.enabled.proportionInput.errors.max' | transloco }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';

/**
 * При посещении каких блоков чат-ботов создаётся сделка в amoCRM
 */

@Component({
  selector: 'cq-amocrm-chat-bots-for-lead[chatBots][integrationId][isShowedFromModal]',
  templateUrl: './amocrm-chat-bots-for-lead.component.html',
  styleUrls: ['./amocrm-chat-bots-for-lead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmChatBotsForLeadComponent<T extends CHAT_BOT_TYPE> {
  /** Список с ботами */
  @Input()
  chatBots!: ChatBot<T>[];

  /** ID текущей интеграции */
  @Input()
  integrationId!: string;

  /** Показываются ли компоненты из модального окна создания интеграции */
  @Input()
  isShowedFromModal!: boolean;

  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;

  /** Свёрнут ли блок с ботами */
  isCollapsed: boolean = true;

  /** Текущее название проекта */
  projectName = environment.projectName;

  /** Получение списка активных чат-ботов */
  get activeChatBots(): ChatBot<T>[] {
    return this.chatBots.filter((chatBot) => {
      if (chatBot.type === CHAT_BOT_TYPE.ROUTING) {
        return true;
      }

      return chatBot.message?.active;
    });
  }

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  /** Является ли список ботов пустым */
  get isEmptyChatBots(): boolean {
    return this.chatBots.length === 0;
  }

  constructor(@Inject(IntegrationService) private readonly integrationService: IntegrationService) {}
}

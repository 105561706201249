<ng-container *transloco="let t; read: 'sendingDelayComponent'">
  <cq-message-editor-switch
    [heading]="t('heading')"
    [switched]="control.controls.isEnabled.value"
    (switchedChange)="control.controls.isEnabled.setValue($event)"
    [tooltip]="t('headingTooltip', { entityName: entityName })"
  >
    <cq-time-selector
      [formControl]="control.controls.value"
      [maxSeconds]="MAX_DELAY"
    ></cq-time-selector>
  </cq-message-editor-switch>
</ng-container>

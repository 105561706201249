import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { IntegrationService } from '@http/integration/services/integration.service';
import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { TagDefaultModule } from '@panel/app/partials/tag-default/tag-default.module';
import { DestroyService } from '@panel/app/services';

import { AmocrmLeadTagsComponent } from './amocrm-lead-tags.component';

@NgModule({
  declarations: [AmocrmLeadTagsComponent],
  exports: [AmocrmLeadTagsComponent],
  imports: [CommonModule, NgbTooltipModule, NgSelectModule, ReactiveFormsModule, TagDefaultModule, TranslocoModule],
  providers: [AmocrmService, DestroyService, IntegrationService, CarrotquestHelperProvider],
})
export class AmocrmLeadTagsModule {}

<ng-container *transloco="let t; read: 'aiDataSourceTableItemComponent'">
  <!--<td class="shrink-by-content">
    <div class="custom-control custom-checkbox">
      <input
        class="custom-control-input"
        [id]="'select-source-' + dataSource.contentId"
        type="checkbox"
        [ngModel]="dataSource.checked"
        (ngModelChange)="onCheckedChange($event)"
        [disabled]="(state.isSelectedAll$ | async)!"
      />
      <label
        class="custom-control-label"
        [for]="'select-source-' + dataSource.contentId"
      ></label>
    </div>
  </td>-->
  <td>
    <a
      *ngIf="!dataSource.removed"
      href="#"
      (click)="onClickDataSourceName()"
    >
      <i
        class="btn-icon-left cqi-sm"
        [ngClass]="sourceIconCssClass"
      ></i>
      <span
        class="text-break"
        title="{{ dataSource.name }}"
      >
        {{ dataSource.name }}
      </span>
    </a>
    <div *ngIf="dataSource.removed">
      <i
        class="btn-icon-left cqi-sm"
        [ngClass]="sourceIconCssClass"
      ></i>
      <span
        class="text-break"
        title="{{ dataSource.name }}"
      >
        {{ dataSource.name }}
      </span>
    </div>
  </td>

  <td>
    <span
      class="label"
      [ngClass]="sourceStatusLabelCssClass"
    >
      {{ t('status.' + dataSource.status) }}
    </span>
  </td>

  <td>
    <div class="source-url text-truncate">
      <span>{{ sourceUrl }}</span>
    </div>
  </td>

  <td>
    {{ $any(dataSource.lastAiSync) | amDateFormat: 'LLL' }}
  </td>

  <!--<td class="shrink-by-content">
    <div
      ngbDropdown
      class="d-inline-block"
    >
      <button
        type="button"
        class="btn btn-borderless-primary"
        ngbDropdownToggle
      >
        <i class="btn-icon cqi-sm cqi-ellipsis"></i>
      </button>
      <div ngbDropdownMenu>
        <ng-container *ngFor="let action of availableActions">
          <button
            *ngIf="action === AI_DATA_SOURCE_ITEM_ACTIONS.RENAME"
            (click)="rename()"
            class="dropdown-item"
            type="button"
          >
            <i class="dropdown-item-icon-left cqi-sm cqi-pencil"></i>
            <span>{{ 'general.rename' | transloco }}</span>
          </button>

          <ng-container *ngIf="action === AI_DATA_SOURCE_ITEM_ACTIONS.ACTIVATE">
            <button
              *ngIf="dataSource.status !== DATA_SOURCE_STATUS.ACTIVE"
              (click)="changeStatus()"
              class="dropdown-item"
              type="button"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-lightning"></i>
              <span>{{ t('actionsDropdown.enable') }}</span>
            </button>

            <button
              *ngIf="dataSource.status === DATA_SOURCE_STATUS.ACTIVE"
              class="dropdown-item"
              type="button"
              (click)="changeStatus()"
            >
              <i class="dropdown-item-icon-left cqi-sm cqi-lightning-slash"></i>
              <span>{{ t('actionsDropdown.disable') }}</span>
            </button>
          </ng-container>

          <button
            *ngIf="action === AI_DATA_SOURCE_ITEM_ACTIONS.DELETE"
            (click)="delete()"
            class="dropdown-item"
            type="button"
          >
            <i class="dropdown-item-icon-left cqi-sm cqi-trash"></i>
            <span>{{ 'general.remove' | transloco }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </td>-->
</ng-container>

import { TRIGGER_TYPE_KIND } from '@http/message/message.constants';
import { OpenedSdkPageTriggerTypeExternal, OpenedWebPageTriggerTypeExternal } from '@http/message/trigger.types';
import { ELASTICSEARCH_PROPERTY_OPERATIONS } from '@http/property/property.constants';
import { UrlFilter } from '@panel/app/partials/url-filter-configurator/single-url-filter-configurator/single-url-filter-configurator.component';
import { URL_FILTER_TYPE } from '@panel/app/partials/url-filter-configurator/url-filter-configurator.component';
import { URL_FILTERS_OPERATIONS } from '@panel/app/services/conditions-sending/conditions-sending.constants';

/**
 * Тип данных для отиравки фильтров на бэк, такой формат потом так бэк придумал :)
 */
export type UrlFilterExternal = {
  type:
    | ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN
    | ELASTICSEARCH_PROPERTY_OPERATIONS.UNKNOWN
    | ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS
    | ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_CONTAINS
    | `str_notcontains_wildcard`
    | 'str_contains_wildcard'
    | 'url_eq'
    | 'url_path_eq'
    | 'url_neq'
    | 'url_path_neq';
  property_name: '$url'; // всегда такой, так с бэком сложилось
  value?: { value: string }; // Опционально, т.к. при значении ALL_HIDDEN в visibility settings фильтр не будет содержать value
};

export type TriggerTypeSendingFilters = {
  condition: 'and' | 'or';
  sending_filter_groups: {
    type: 'and' | 'or';
    filters: {
      type:
        | URL_FILTERS_OPERATIONS.URL_EQ
        | URL_FILTERS_OPERATIONS.URL_NOT_EQ
        | ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS
        | ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_CONTAINS
        | 'sdk_page_eq'
        | 'sdk_page_neq'
        | 'sdk_page_contains'
        | 'sdk_page_not_contains';
      property_name: '$url' | '$sdk_page';
      value: { value: string };
    }[];
  }[];
};

export class UrlFilterMapper {
  static filtersToLocal(externalFilters: UrlFilterExternal[]): UrlFilter[] {
    return externalFilters.map((filter) => {
      let type: URL_FILTER_TYPE;
      let match: string = filter.value!.value;

      switch (filter.type) {
        case ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN:
        case ELASTICSEARCH_PROPERTY_OPERATIONS.UNKNOWN:
          // Хоть такие опции и заявлены в типе, они могут быть только в одной ситуации, которая в UI никак не фигурирует
          throw new Error(`Unexpected filter type: ${filter}`);
        case ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS:
        case ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_CONTAINS:
        case 'str_contains_wildcard':
        case 'str_notcontains_wildcard':
          // Тут все опции вместе, потому что wildcard и просто contains на фронте одна опция, так проще
          type = URL_FILTER_TYPE.CONTAINS;
          break;
        case 'url_eq':
        case 'url_neq':
          type = URL_FILTER_TYPE.FULLY_MATCHED;
          break;
        case 'url_path_eq':
        case 'url_path_neq':
          type = URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS;
      }

      return { type, match };
    });
  }

  /**
   * @param filters - фильтры, которые переделываем в API формат
   * @param inverse - Флаг для того чтоб понимаь нужны ли "прямые" операции или инвертированные. Прим: Вместо url_eq (===) нужно выбрать url_neq (!==)
   */
  static filtersToExternal(filters: UrlFilter[], inverse: boolean): UrlFilterExternal[] {
    return filters.map((filter) => {
      let type: UrlFilterExternal['type'];

      switch (filter.type) {
        case URL_FILTER_TYPE.FULLY_MATCHED:
          type = inverse ? 'url_neq' : 'url_eq';
          break;
        case URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS:
          type = inverse ? 'url_path_neq' : 'url_path_eq';
          break;
        case URL_FILTER_TYPE.CONTAINS:
          type = inverse
            ? ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_CONTAINS
            : ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS;
          if (filter.match.search(/\*/) > -1) {
            type = `${type}_wildcard` as UrlFilterExternal['type'];
          }
          break;
      }

      return {
        type,
        property_name: '$url',
        value: { value: filter.match },
      };
    });
  }

  static filtersForOpenedPageTriggerTypes(triggerTypes: OpenedWebPageTriggerTypeExternal[]): UrlFilter[] {
    let filters: UrlFilter[] = [];

    triggerTypes.forEach((triggerType) => {
      const type =
        triggerType.value.comparison === 'contains' ? URL_FILTER_TYPE.CONTAINS : URL_FILTER_TYPE.FULLY_MATCHED;

      filters.push({
        type: type,
        match: triggerType.value.url,
      });
    });

    return filters;
  }

  static externalSendingFiltersForOpenedPageTriggerTypes(
    triggerTypes: Array<OpenedWebPageTriggerTypeExternal | OpenedSdkPageTriggerTypeExternal>,
  ): TriggerTypeSendingFilters {
    const isWeb = triggerTypes.every((t): t is OpenedWebPageTriggerTypeExternal => {
      return t.kind === TRIGGER_TYPE_KIND.URL;
    });

    if (isWeb) {
      return this.externalSendingFiltersForOpenedWebPageTriggerTypes(triggerTypes);
    }

    const isSDK = triggerTypes.every((t): t is OpenedSdkPageTriggerTypeExternal => {
      return t.kind === TRIGGER_TYPE_KIND.SDK_PAGE;
    });

    if (isSDK) {
      return this.externalSendingFiltersForOpenedSdkPageTriggerTypes(triggerTypes);
    }

    throw new Error('Invalid triggerType kind');
  }

  private static externalSendingFiltersForOpenedWebPageTriggerTypes(
    triggerTypes: OpenedWebPageTriggerTypeExternal[],
  ): TriggerTypeSendingFilters {
    const excludeComparisons: OpenedWebPageTriggerTypeExternal['value']['comparison'][] = ['neq', 'not_contains'];

    const excludePages = triggerTypes.filter((t) => excludeComparisons.includes(t.value.comparison));
    const includePages = triggerTypes.filter((t) => !excludeComparisons.includes(t.value.comparison));

    return {
      condition: 'and',
      sending_filter_groups: [
        //{type: "url_eq", property_name: "$url", value: {value: "cart"}}
        {
          type: 'or',
          filters: includePages.map((t) => {
            return {
              type:
                t.value.comparison === 'eq'
                  ? URL_FILTERS_OPERATIONS.URL_EQ
                  : ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS,
              property_name: '$url',
              value: { value: t.value.url },
            };
          }),
        },
        {
          type: 'and',
          filters: excludePages.map((t) => {
            return {
              type:
                t.value.comparison === 'neq'
                  ? URL_FILTERS_OPERATIONS.URL_NOT_EQ
                  : ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_CONTAINS,
              property_name: '$url',
              value: { value: t.value.url },
            };
          }),
        },
      ],
    };
  }

  private static externalSendingFiltersForOpenedSdkPageTriggerTypes(
    triggerTypes: OpenedSdkPageTriggerTypeExternal[],
  ): TriggerTypeSendingFilters {
    const excludeComparisons: OpenedSdkPageTriggerTypeExternal['value']['comparison'][] = ['neq', 'not_contains'];

    const excludePages = triggerTypes.filter((t) => excludeComparisons.includes(t.value.comparison));
    const includePages = triggerTypes.filter((t) => !excludeComparisons.includes(t.value.comparison));

    return {
      condition: 'and',
      sending_filter_groups: [
        //{type: "url_eq", property_name: "$url", value: {value: "cart"}}
        {
          type: 'or',
          filters: includePages.map((t) => {
            return {
              type: t.value.comparison === 'eq' ? 'sdk_page_eq' : 'sdk_page_contains',
              property_name: '$sdk_page',
              value: { value: t.value.sdkPage },
            };
          }),
        },
        {
          type: 'and',
          filters: excludePages.map((t) => {
            return {
              type: t.value.comparison === 'neq' ? 'sdk_page_neq' : 'sdk_page_not_contains',
              property_name: '$sdk_page',
              value: { value: t.value.sdkPage },
            };
          }),
        },
      ],
    };
  }
}

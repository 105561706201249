import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmFieldsSelectModule } from '@panel/app/pages/integrations/content/amocrm/fields-select/amocrm-fields-select.module';
import { DestroyService } from '@panel/app/services';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmDataFromContactsComponent } from './amocrm-data-from-contacts.component';

@NgModule({
  declarations: [AmocrmDataFromContactsComponent],
  exports: [AmocrmDataFromContactsComponent],
  imports: [
    AmocrmFieldsSelectModule,
    CommonModule,
    DirectivesModule,
    NgbCollapseModule,
    NgSelectModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
  ],
  providers: [AmocrmService, DestroyService, IntegrationService],
})
export class AmocrmDataFromContactsModule {}

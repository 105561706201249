import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { POPUP_BLOCK_TYPES, POPUP_BLOCK_TYPES_ARRAY } from '../../../app/services/popup-block/popup-block.constants';
import { MESSAGE_PART_TYPES, POPUP_COMPOSITION_TYPES } from '../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.popupEditor').controller('CqPopupEditorController', CqPopupEditorController);

  function CqPopupEditorController(
    $filter,
    $scope,
    $state,
    carrotquestHelper,
    caseStyleHelper,
    djangoUserModel,
    messagePartModel,
    planFeatureAccessService,
    popupBlockModel,
    shortUuid,
    validationHelper,
  ) {
    var vm = this;

    /**
     * Максимальная длина содержания push-уведомления, которое можно отправить с поп-апом в SDK
     *
     * @type {number}
     */
    var PUSH_BODY_MAX_LENGTH = 500;

    /**
     * Максимальная длина заголовка push-уведомления, которое можно отправить с поп-апом в SDK
     *
     * @type {number}
     */
    var PUSH_SUBJECT_MAX_LENGTH = 100;

    var trackingPageName = $state.includes('app.content.messagesAjs')
      ? 'Автосообщения'
      : $state.includes('app.content.messagesAjs.templates')
      ? 'Шаблоны'
      : 'Ручная рассылка';

    /**
     * Функция компонента настроек блока, которая вызывается при невалидности формы компонента настроек блока
     * По сути нужна для выполнения различной логики, присущей компонентам настройки блока (например, открытие нужной вкладки с ошибкой)
     *
     * @type {Function|null}
     */
    var popupBlockSettingsErrorHandler = null;

    /**
     * Второй массив, нужен для разделения блоков на категории
     *
     * @type {Array.<POPUP_BLOCK_TYPES>}
     */
    var secondPopupBlockTypesArray = [POPUP_BLOCK_TYPES.FOOTER];

    /**
     * Вкладки
     *
     * @type {{BLOCKS: String, POPUP_SETTINGS: String}}
     */
    var TABS = {
      BLOCKS: 'blocks',
      POPUP_SETTINGS: 'popupSettings',
    };

    /**
     * Уникальный идентификатор для корректной работы радиобаттонов при наличии нескольких компонентов на одной странице
     * Без этого индентификатора, ID у input'ов будут одинаковые, а это противоречит правилам HTML, поэтому при клике на чекбоксы/радио будут баги
     * Тот же самый баг будет с одинаковыми именами input[type="radio"], т.к. такие радио объединяются в radio group
     */
    const UUID = shortUuid();

    /**
     * Блоки, которые не допустимы для разных типов поп-апов
     *
     * @type {Object}
     */
    var INVALID_BLOCKS_FOR_BLOCK_POPUP = {};

    /**
     * Блоки, которые не допустимы для блочного поп-апа в SDK
     *
     * @type {Array}
     */
    INVALID_BLOCKS_FOR_BLOCK_POPUP[MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL] = [
      POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON,
      POPUP_BLOCK_TYPES.HTML,
      POPUP_BLOCK_TYPES.CART,
      POPUP_BLOCK_TYPES.FOOTER,
    ];

    /**
     * Блоки, которые не допустимы для маленького блочного
     *
     * @type {Array}
     */
    INVALID_BLOCKS_FOR_BLOCK_POPUP[MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL] = [POPUP_BLOCK_TYPES.HTML];

    /**
     * Блоки, которые не допустимы для большого блочного
     *
     * @type {Array}
     */
    INVALID_BLOCKS_FOR_BLOCK_POPUP[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG] = [POPUP_BLOCK_TYPES.HTML];

    vm.$onInit = init;

    function init() {
      vm.accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, vm.currentApp);

      vm.$state = $state;
      vm.addBlock = addBlock;
      vm.blocksCount = 0; // общее количество блоков в поп-апе
      vm.caseStyleHelper = caseStyleHelper;
      vm.clearPushInputs = clearPushInputs;
      vm.closePopover = closePopover;
      vm.currentPopupBlock = null; // текущий блок
      vm.currentTab = TABS.BLOCKS; // текущая вкладка
      vm.djangoUserModel = djangoUserModel;
      vm.duplicateBlock = duplicateBlock;
      vm.isPreviewShown = false; // флаг отображения превью
      vm.isTemplatePage = $state.includes('app.content.messagesAjs.templates'); // Текущая страница является страницей шаблонов
      vm.isTemplatesPopoverOpen = false; // Флаг показывающий открыт поповер с шаблонами или нет
      vm.isValidBlockForBlockPopupType = isValidBlockForBlockPopupType;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.messagePartModel = messagePartModel;
      vm.POPUP_BLOCK_TYPES = POPUP_BLOCK_TYPES;
      vm.POPUP_BLOCK_TYPES_ARRAY = POPUP_BLOCK_TYPES_ARRAY;
      vm.POPUP_COMPOSITION_TYPES = POPUP_COMPOSITION_TYPES;
      vm.popupBlockSettingsForm = null; // форма настроек блока поп-апа
      vm.popupBlockTypesArrayOfArrays = redistributePopupBlockTypesArray();
      vm.popupEditorForm = null; // общая форма редактора поп-апов
      vm.popupSettingsForm = null; // форма настроек поп-апа
      vm.PUSH_BODY_MAX_LENGTH = PUSH_BODY_MAX_LENGTH;
      vm.PUSH_SUBJECT_MAX_LENGTH = PUSH_SUBJECT_MAX_LENGTH;
      vm.selectPopupBlock = selectPopupBlock;
      vm.setLastSelectedBlockPopupType = setLastSelectedBlockPopupType;
      vm.setPopupBlockSettingsErrorHandler = setPopupBlockSettingsErrorHandler;
      vm.setTemplateParams = setTemplateParams;
      vm.showPoweredBy = vm.currentApp.settings.messenger_show_powered_by;
      vm.TABS = TABS;
      vm.trackChangePopupSize = trackChangePopupSize;
      vm.trackChangeTemplate = trackChangeTemplate;
      vm.trackClickOnMoveBlock = trackClickOnMoveBlock;
      vm.trackClickOnPopupComposition = trackClickOnPopupComposition;
      vm.trackClickOnPopupSettings = trackClickOnPopupSettings;
      vm.trackClickOnPreviewToggle = trackClickOnPreviewToggle;
      vm.trackClickOnRemoveBlock = trackClickOnRemoveBlock;
      vm.trackClickOnInsertFromTemplateDropdown = trackClickOnInsertFromTemplateDropdown;
      vm.UUID = UUID;
      vm.validatePopupBlockSettingsForm = validatePopupBlockSettingsForm;
      vm.validationHelper = validationHelper;

      vm.setErrorHandler && vm.setErrorHandler({ handler: handleError });

      $scope.$watchCollection(getBlocksCount, onBlockCountChange);

      /**
       * Получение количества блоков, добавленных в поп-ап
       *
       * @returns {Number}
       */
      function getBlocksCount() {
        var defaultType = vm.messagePart.lastSelectedBlockPopupType;

        // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
        // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
        // HACK Поэтому у messagePart, в свойстве lastSelectedBlockPopupType, не появиться отдельного SDK-типа, но нам как-то обработать эту ситуацию.
        if (vm.messagePart.type === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
          defaultType = MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL;
        }

        var blocksCount = $filter('flatten')(vm.messagePart[defaultType].bodyJson.blocks).length;
        if (vm.messagePart[defaultType].bodyJson.footer) {
          blocksCount++;
        }

        return blocksCount;
      }

      /**
       * Обработка изменения количества блоков в поп-апе
       *
       * @param {Number} blocksCount Количество блоков
       */
      function onBlockCountChange(blocksCount) {
        vm.blocksCount = blocksCount;

        // если во время показа превью тыкнули на "Удалить блок" - нужно выйти из режима превью
        if (!vm.blocksCount) {
          vm.isPreviewShown = false;
        }
      }
    }

    /**
     * Добавление нового блока в поп-ап
     *
     * @param popupBlockType
     */
    function addBlock(popupBlockType) {
      trackAddBlock(popupBlockType, vm.messagePart.type);

      var coordinates = [];
      var popupBlockParams;

      // если выделен текущий блок - вставка осуществляется после него
      // NOTE: сейчас это не работает, т.к. нельзя вставить новый блок, не развыделив старый. Но на всякий случай пусть это будет тут
      if (vm.currentPopupBlock) {
        coordinates = messagePartModel.findBlock(
          vm.messagePart[vm.messagePart.type].bodyJson.blocks,
          vm.currentPopupBlock,
        );
      }

      // установка дополнительных параметров некоторым блокам
      switch (popupBlockType) {
        case POPUP_BLOCK_TYPES.BUTTON:
          popupBlockParams = {
            backgroundColor: vm.currentApp.settings.messenger_collapsed_color,
          };
          break;
        case POPUP_BLOCK_TYPES.CART:
          popupBlockParams = {
            buttonsColor: vm.currentApp.settings.messenger_collapsed_color,
          };
          break;
        case POPUP_BLOCK_TYPES.FOOTER:
          popupBlockParams = {
            buttonBackgroundColor: vm.currentApp.settings.messenger_collapsed_color,
            dataCollectionCheckboxColor: vm.currentApp.settings.messenger_collapsed_color, // цвет чекбокса на согласие обработки данных
            privacyPolicyUrlColor: vm.currentApp.settings.messenger_collapsed_color, // цвет ссылки на условия обработки данных
          };
          break;
        case POPUP_BLOCK_TYPES.INPUT_WITH_BIG_BUTTON:
          popupBlockParams = {
            buttonBackgroundColor: vm.currentApp.settings.messenger_collapsed_color,
            dataCollectionCheckboxColor: vm.currentApp.settings.messenger_collapsed_color, // цвет чекбокса на согласие обработки данных
            privacyPolicyUrlColor: vm.currentApp.settings.messenger_collapsed_color, // цвет ссылки на условия обработки данных
          };
          break;
        case POPUP_BLOCK_TYPES.INPUT_WITH_BUTTON:
          popupBlockParams = {
            buttonBackgroundColor: vm.currentApp.settings.messenger_collapsed_color,
            dataCollectionCheckboxColor: vm.currentApp.settings.messenger_collapsed_color, // цвет чекбокса на согласие обработки данных
            privacyPolicyUrlColor: vm.currentApp.settings.messenger_collapsed_color, // цвет ссылки на условия обработки данных
          };
          break;
      }

      selectPopupBlock(
        messagePartModel.addBlock(vm.messagePart[vm.messagePart.type], popupBlockType, popupBlockParams, coordinates),
      );
    }

    /**
     * Очищение полей отвечающих за push-уведомление о сообщении с блочным попап-апом в SDK
     */
    function clearPushInputs() {
      if (
        vm.messagePart.type === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL &&
        vm.messagePart[vm.messagePart.type].send_sdk_push === false
      ) {
        vm.messagePart[vm.messagePart.type].subject = '';
        vm.messagePart[vm.messagePart.type].body = '';
      }
    }

    /**
     * Закрывает поповер с шаблонами
     */
    function closePopover() {
      vm.isTemplatesPopoverOpen = false;
    }

    /**
     * Дублирование блока
     *
     * @param {Object} popupBlock Блок поп-апа
     */
    function duplicateBlock(popupBlock) {
      var coordinates = messagePartModel.findBlock(vm.messagePart[vm.messagePart.type].bodyJson.blocks, popupBlock);

      coordinates[2]++;

      selectPopupBlock(
        messagePartModel.addBlock(vm.messagePart[vm.messagePart.type], popupBlock.type, popupBlock.params, coordinates),
      );
    }

    /**
     * Функция обработки ошибок форм при отправке формы снаружи компонента
     */
    function handleError() {
      // искусственно сабмитим формы (с ngForm по-другому не получится) на случай, если родительская форма не была засабмичена, чтобы отображались ошибки
      vm.popupEditorForm.$setSubmitted();

      validatePopupBlockSettingsForm();

      if (vm.popupSettingsForm.$valid && vm.popupEditorForm.$invalid) {
        vm.currentTab = TABS.BLOCKS;
      } else if (vm.popupSettingsForm.$invalid) {
        vm.currentTab = TABS.POPUP_SETTINGS;
      }
    }

    /**
     * Проверка блока на валидность для типа поп-апа
     *
     * @param typeBlock - тип блока
     * @returns {boolean}
     */
    function isValidBlockForBlockPopupType(typeBlock) {
      return !~INVALID_BLOCKS_FOR_BLOCK_POPUP[vm.messagePart.type].indexOf(typeBlock);
    }

    /**
     * Связывание блоков поп-апа с типами событий и свойствами пользователя
     */
    function linkBlockPopupWithData(messagePart) {
      var blockPopup = messagePart[messagePart.type];
      var popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

      blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

      for (var j = 0; j < popupBlocks.length; j++) {
        var popupBlock = popupBlocks[j];

        popupBlockModel.linkWithEventTypes(popupBlock, vm.properties.eventTypes);
        popupBlockModel.linkWithUserProperties(popupBlock, vm.properties.userProps);
      }
    }

    /**
     * Разделение типов блоков поп-апа на два массива (в данный момент во втором массиве находится только футер)
     *
     * @return {Array.<Array>}
     */
    function redistributePopupBlockTypesArray() {
      var firstArray = $filter('xor')(POPUP_BLOCK_TYPES_ARRAY, secondPopupBlockTypesArray);

      return [firstArray, secondPopupBlockTypesArray];
    }

    /**
     * Выбор блока popupBlock в качестве текущего
     *
     * @param {Object} popupBlock Блок
     */
    function selectPopupBlock(popupBlock) {
      // если форма настроек блока невалидна - нельзя выбирать другой блок
      if (!validatePopupBlockSettingsForm()) {
        return;
      }

      // если выбирается текущий блок - по сути нужно сделать toggle, то есть 'развыбрать' блок
      if (vm.currentPopupBlock == popupBlock) {
        vm.currentPopupBlock = null;
      } else {
        vm.currentPopupBlock = popupBlock;
      }
    }

    /**
     * Установка функции, которая будет вызываться в случае невалидности настроек блока
     *
     * @param {Function} handler
     */
    function setPopupBlockSettingsErrorHandler(handler) {
      popupBlockSettingsErrorHandler = handler;
    }

    /**
     * Заполнение варианта автосообщения в соответствии с выбранным шаблоном
     *
     * @param {Object} messagePart - Вариант сообщения
     * @param {Object} template - Шаблон автосообщения
     */
    function setTemplateParams(messagePart, template) {
      messagePartModel.parseFromTemplate(template, messagePart);
      linkBlockPopupWithData(messagePart);
    }

    /**
     * Установка последнего выбранного типа поп-апа для варианта автосообщения
     *
     * @param {Object} messagePart Вариант автосообщения
     * @param {String} blockPopupType Тип поп-апа
     */
    function setLastSelectedBlockPopupType(messagePart, blockPopupType) {
      messagePart.lastSelectedBlockPopupType = blockPopupType;
    }

    /**
     * Трек добавления блока в попап
     *
     * @param {POPUP_BLOCK_TYPES} popupBlockType Тип блока
     * @param {MESSAGE_PART_TYPES} messagePartType Тип сообщения
     */
    function trackAddBlock(popupBlockType, messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }
      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - добавил блок', { Название: popupBlockType });
    }

    /**
     * Трек клика на размер поп-апа
     *
     * @param {MESSAGE_PART_TYPES} popupSize Размер поп-апа
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackChangePopupSize(popupSize, messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на тип поп-апа', { Тип: popupSize });
    }

    /**
     * Трек клика на перемещение блока (вверх, вниз, влево, вправо)
     *
     * @param {POPUP_BLOCK_TYPES} popupBlockType Тип поп-апа
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnMoveBlock(popupBlockType, messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - переместил блок', { Название: popupBlockType });
    }

    /**
     * Трек клика на композицию поп-апа
     *
     * @param {POPUP_COMPOSITION_TYPES} popupComposition Композиция поп-апа
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnPopupComposition(popupComposition, messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на композицию', { Тип: popupComposition });
    }

    /**
     * Трек клика на вкладку 'Настройки поп-апа'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnPopupSettings(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на настройки поп-апа');
    }

    /**
     * Трек клика на кнопку включения/отключения предпросмотра
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnPreviewToggle(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на предпросмотр');
    }

    /**
     * Трек клика на кнопку 'Удалить блок'
     *
     * @param {POPUP_BLOCK_TYPES} popupBlockType Тип поп-апа
     * @param {MESSAGE_PART_TYPES} messagePartType Тип сообщения
     */
    function trackClickOnRemoveBlock(popupBlockType, messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - удалил блок', { Название: popupBlockType });
    }

    /**
     * Валидация формы с настройками блока
     *
     * @returns {Boolean} Если форма невалидна - вернётся false, иначе true
     */
    function validatePopupBlockSettingsForm() {
      if (vm.popupBlockSettingsForm.$invalid) {
        // если форма невалидна - вызываем обработчик ошибок
        popupBlockSettingsErrorHandler && popupBlockSettingsErrorHandler();

        return false;
      } else {
        return true;
      }
    }

    /**
     * Трек выбора шаблона в 'Вставить содержимое из шаблона'
     *
     * @param {Object} template - Вставляемый шаблон
     * @param {Object} messageTemplateSource Источник, из которого был вставлен шаблон
     */
    function trackChangeTemplate(template, messageTemplateSource) {
      carrotquestHelper.track(trackingPageName + ' - вставил содержание из шаблона', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        page: trackingPageName,
        id_шаблона: template.id,
        Название: template.name,
        Тип: template.type,
        'Источник шаблона': messageTemplateSource,
        'Место вставки': vm.isTemplatesPopoverOpen ? 'popover' : 'new',
      });
    }

    /**
     * Трек по кнопке "Вставить содержание из шаблона"
     */
    function trackClickOnInsertFromTemplateDropdown() {
      carrotquestHelper.track(trackingPageName + ' - клик по "Вставить содержание из шаблона"', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        page: trackingPageName,
      });
    }
  }
})();

import { InjectionToken } from '@angular/core';

/**
 * Тип данных, передаваемых в модалку
 */
export type StripeThreeDSModalData = {
  iframeSrc: string;
};

/**
 * Токен для передачи данных через DI в модалку для 3DS верификации карты Stripe
 */
export const STRIPE_THREE_DS_MODAL_DATA_TOKEN = new InjectionToken<StripeThreeDSModalData>(
  'StripeThreeDSModalComponent data provider',
);

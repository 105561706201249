/**
 * Компонент для работы с именем пользователя в завершении регистрации
 */

(function () {
  'use strict';

  require('./completion-registration-form-control-name.controller');

  angular
    .module('myApp.completionRegistration')
    .component('cqCompletionRegistrationFormControlName', cqCompletionRegistrationFormControlName());

  function cqCompletionRegistrationFormControlName() {
    return {
      bindings: {
        /** Форма завершения регистрации */
        completionRegistrationForm: '=',
        /** Будет ли поле обязательным */
        isRequiredMode: '=',
        /** Имя пользователя */
        userName: '=',
      },
      controller: 'cqCompletionRegistrationFormControlNameController',
      controllerAs: 'vm',
      template: require('./completion-registration-form-control-name.html'),
    };
  }
})();

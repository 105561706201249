<ng-container *transloco="let t">
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <div class="icon mr-auto mb-15">
        <cq-integration-icon [type]="type"></cq-integration-icon>
      </div>
      <label
        *ngIf="count"
        class="badge badge-light-secondary align-self-start mb-15"
        >{{ t('integrationCardComponent.count', { count: count }) }}</label
      >
    </div>
    <div class="mb-10 margin-between-cols-5">
      <h5 class="inline">{{ typeName }}</h5>
      <cq-plan-capability-coin *ngIf="!accessToIntegrationType.hasAccess"></cq-plan-capability-coin>
      <cq-new-label [featureLabel]="type"></cq-new-label>
      <cq-beta-label [featureLabel]="type"></cq-beta-label>
    </div>
    <div class="text-secondary">
      {{
        t('models.integration.types.' + type + '.description', {
          projectName: projectName,
          integrationTypeName: typeName
        })
      }}
    </div>
  </div>
  <!-- так сделано, чтобы можно было открывать ссылку в новой вкладке по клику колёсиком мыши. Повешать uiSref на host невозможно https://github.com/angular/angular/issues/8785 -->
  <a
    *ngIf="accessToIntegrationType.hasAccess; else paywallBlock"
    class="stretched-link"
    uiSref="app.content.integrations.preview"
    [uiParams]="{ integrationType: type }"
  ></a>
  <ng-template #paywallBlock>
    <a
      class="stretched-link"
      href="#"
      (click)="showPaywall(currentApp, accessToIntegrationType.denialReason)"
    ></a>
  </ng-template>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ConversationPart, ConversationPartAttachment } from '@http/conversation-part/internal-types';
import {
  CONVERSATION_PART_PREVIEW_COMPONENT,
  EXPECTED_ATTACHMENT,
  MEDIA_ATTACHMENT_TYPES,
  MEDIA_ATTACHMENT_TYPES_ARRAY,
} from '@panel/app/partials/conversation-part-preview/conversation-part-preview.constants';

/** Компонент для отображения preview реплики диалога */
@Component({
  selector: 'cq-conversation-part-preview[attachments][body][bodyJson]',
  templateUrl: './conversation-part-preview.component.html',
  styleUrls: ['./conversation-part-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPartPreviewComponent implements OnInit {
  /** Прикрепления */
  @Input() attachments!: ConversationPartAttachment[];
  /** Содержимое (содержимое некоторых реплик записывается в body, а не в bodyJson) */
  @Input() body!: ConversationPart['body'];
  /** Содержимое (содержимое некоторых реплик записывается в bodyJson, а не в body) */
  @Input() bodyJson!: ConversationPart['bodyJson'];
  /** Ожидаемое вложение (необходимо, чтобы показать заглушку для прикрепления, пока его не прикрепили) */
  @Input() expectedAttachment: EXPECTED_ATTACHMENT | null = null;
  /** Типы компонентов из которых состоит preview реплики */
  CONVERSATION_PART_PREVIEW_COMPONENT = CONVERSATION_PART_PREVIEW_COMPONENT;
  /** Готовые к рендеру компоненты preview реплики */
  readyPartPreviewComponents!: CONVERSATION_PART_PREVIEW_COMPONENT[];

  /** Прикрепление */
  get partAttachment(): ConversationPartAttachment | null {
    let attachment = null;

    if (this.attachments[0]) {
      attachment = this.attachments[0];
    }

    return attachment;
  }

  /** Кнопки */
  get partButtons(): ConversationPart['bodyJson']['buttons'] {
    return this.bodyJson.buttons;
  }

  /** Текст */
  get partText(): ConversationPart['body'] {
    return this.body;
  }

  ngOnInit(): void {
    this.readyPartPreviewComponents = this.getReadyPartPreviewComponents({
      body: this.body,
      bodyJson: this.bodyJson,
      attachments: this.attachments,
    });
  }

  /**
   * Получение типа прикрепления
   *
   * @param attachment - Прикрепление, у которого нужно получить тип
   */
  getAttachmentType(attachment: ConversationPartAttachment): string {
    return attachment.mimeType.split('/')[0];
  }

  /***
   * Получение прикрепления реплики
   *
   * @param part - Реплика, в которой нужно получить прикрепление
   */
  getPartAttachment(part: ConversationPart): ConversationPartAttachment {
    return part.attachments[0];
  }

  /**
   * Получение элементов из которых собирается preview реплики
   *
   * @param part - Реплика, превью которой нужно собрать
   */
  getReadyPartPreviewComponents(part: ConversationPart): CONVERSATION_PART_PREVIEW_COMPONENT[] {
    let previewComponents: CONVERSATION_PART_PREVIEW_COMPONENT[] = [];

    if (this.partHasText(part)) {
      previewComponents = [
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.TEXT,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
      ];
    }

    if (
      this.partHasText(part) &&
      (this.partHasImageAttachment(part) || this.isExpectedImageAttachment(this.expectedAttachment))
    ) {
      previewComponents = [
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.TEXT,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.IMAGE,
      ];
    }

    if (
      this.partHasText(part) &&
      (this.partHasVideoAttachment(part) || this.isExpectedVideoAttachment(this.expectedAttachment))
    ) {
      previewComponents = [
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.TEXT,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.VIDEO,
      ];
    }

    if (
      this.partHasText(part) &&
      (this.partHasFileAttachment(part) || this.isExpectedFileAttachment(this.expectedAttachment))
    ) {
      previewComponents = [
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.TEXT,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.DELIMITER,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.FILE,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
      ];
    }

    if (this.partHasText(part) && this.partHasButtons(part)) {
      previewComponents = [
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.TEXT,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.BUTTONS,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
      ];
    }

    if (
      this.partHasText(part) &&
      this.partHasButtons(part) &&
      (this.partHasFileAttachment(part) || this.isExpectedFileAttachment(this.expectedAttachment))
    ) {
      previewComponents = [
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.TEXT,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.DELIMITER,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.BUTTONS,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.FILE,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
      ];
    }

    if (
      this.partHasText(part) &&
      this.partHasButtons(part) &&
      (this.partHasImageAttachment(part) || this.isExpectedImageAttachment(this.expectedAttachment))
    ) {
      previewComponents = [
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.TEXT,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.BUTTONS,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.IMAGE,
      ];
    }

    if (
      this.partHasText(part) &&
      this.partHasButtons(part) &&
      (this.partHasVideoAttachment(part) || this.isExpectedVideoAttachment(this.expectedAttachment))
    ) {
      previewComponents = [
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.TEXT,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.BUTTONS,
        CONVERSATION_PART_PREVIEW_COMPONENT.SPACE,
        CONVERSATION_PART_PREVIEW_COMPONENT.VIDEO,
      ];
    }

    return previewComponents;
  }

  /**
   * Ожидается ли в реплике прикрепление в виде изображения
   *
   * @param expectedAttachment - Ожидаемый тип прикрепления
   */
  isExpectedFileAttachment(expectedAttachment: EXPECTED_ATTACHMENT | null): boolean {
    return expectedAttachment === EXPECTED_ATTACHMENT.FILE;
  }

  /**
   * Ожидается ли в реплике прикрепление в виде изображения
   *
   * @param expectedAttachment - Ожидаемый тип прикрепления
   */
  isExpectedImageAttachment(expectedAttachment: EXPECTED_ATTACHMENT | null): boolean {
    return expectedAttachment === EXPECTED_ATTACHMENT.IMAGE;
  }

  /**
   * Ожидается ли в реплике прикрепление в виде изображения
   *
   * @param expectedAttachment - Ожидаемый тип прикрепления
   */
  isExpectedVideoAttachment(expectedAttachment: EXPECTED_ATTACHMENT | null): boolean {
    return expectedAttachment === EXPECTED_ATTACHMENT.VIDEO;
  }

  /**
   * Является ли прикрепление отличным от медиа-прикреплением
   *
   * @param attachment - Прикрепление, которое нужно проверить на отличимость от медиа-прикрепления
   */
  isFileAttachment(attachment: ConversationPartAttachment): boolean {
    const type = this.getAttachmentType(attachment);

    return !MEDIA_ATTACHMENT_TYPES_ARRAY.includes(type);
  }

  /**
   * Является ли прикрепление изображением
   *
   * @param attachment - Прикрепление, которое нужно проверить на содержание изображения
   */
  isImageAttachment(attachment: ConversationPartAttachment): boolean {
    const type = this.getAttachmentType(attachment);

    return type === MEDIA_ATTACHMENT_TYPES.IMAGE;
  }

  /**
   * Является ли прикрепление видео
   *
   * @param attachment - Прикрепление, которое нужно проверить на содержание видео контента
   */
  isVideoAttachment(attachment: ConversationPartAttachment): boolean {
    const type = this.getAttachmentType(attachment);

    return type === MEDIA_ATTACHMENT_TYPES.VIDEO;
  }

  /**
   * Имеет ли реплика прикрепление
   *
   * @param part - Реплика, в котрой нужно проверить наличие прикрепления
   */
  partHasAttachment(part: ConversationPart): boolean {
    return !!part.attachments.length;
  }

  /**
   * Имеет ли реплика кнопки
   *
   * @param part - Реплика, которую нужно проверить на наличие кнопок
   */
  partHasButtons(part: ConversationPart): boolean {
    return !!part.bodyJson?.buttons;
  }

  /**
   * Имеет ли реплика отличное от медиа-перекрепления прикрепление
   *
   * @param part - Реплика, в которой нужно проверить отличное от медиа-перекрепления прикрепление
   */
  partHasFileAttachment(part: ConversationPart): boolean {
    let hasOtherAttachment = false;

    if (this.partHasAttachment(part)) {
      const attachment = this.getPartAttachment(part);

      hasOtherAttachment = this.isFileAttachment(attachment);
    }

    return hasOtherAttachment;
  }

  /**
   * Имеет ли реплика прикрепление в виде изображения
   *
   * @param part - Реплика, в которой нужно проверить наличие изображения
   */
  partHasImageAttachment(part: ConversationPart): boolean {
    let hasImageAttachment = false;

    if (this.partHasAttachment(part)) {
      const attachment = this.getPartAttachment(part);

      hasImageAttachment = this.isImageAttachment(attachment);
    }

    return hasImageAttachment;
  }

  /**
   * Имеет ли реплика текст
   *
   * @param part - Реплика, в которой нужно проверить наличие текста
   */
  partHasText(part: ConversationPart): boolean {
    return !!part.body;
  }

  /**
   * Имеет ли реплика прикрепление в виде видео
   *
   * @param part - Реплика, в которой нужно проверить наличие видео
   */
  partHasVideoAttachment(part: ConversationPart): boolean {
    let hasVideoAttachment = false;

    if (this.partHasAttachment(part)) {
      const attachment = this.getPartAttachment(part);

      hasVideoAttachment = this.isVideoAttachment(attachment);
    }

    return hasVideoAttachment;
  }
}

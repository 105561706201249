import { QuotasHelpService } from '@http/quotas/help/quotas-help.service';
import { QuotasDto } from '@http/quotas/quotas.types';

/** Класс для работы с квотами */
export class Quotas {
  /** Израсходованное количество emails */
  spentEmails: number = 0;

  /** Израсходованное количество emails для уведомлений членам команды о диалогах */
  spentEmailsForAdminNotifications: number = 0;

  /** Израсходованное количество emails для уведомлений о Double Opt-In писем */
  spentEmailsForDoubleOptIn: number = 0;

  /** Израсходованное количество emails для ручных сообщений */
  spentEmailsForManualMessages: number = 0;

  /** Израсходованное количество emails для уведомлений о лидах */
  spentEmailsForForNotificationsLeads: number = 0;

  /** Израсходованное количество emails для триггерных сообщений */
  spentEmailsForTriggerMessages: number = 0;

  /** Израсходованное количество emails для уведомлений о непрочитанных диалогах в чате пользователям */
  spentEmailsForUnreadConversationsNotifications: number = 0;

  /** Израсходованное количество уникальных пользователей */
  spentUniqueUsers: number = 0;

  /** Количество отправленных сообщений в телеграм (не считая сообщений от операторов) */
  integrationMessagesTelegram: number = 0;

  /** Количество диалогов, в которых принимал участие AI-бот */
  conversationPartGroupsChatGpt: number = 0;

  constructor(dto: QuotasDto) {
    this.spentEmails = dto.emails_total;
    this.spentEmailsForAdminNotifications = dto.emails_admin_notifications;
    this.spentEmailsForDoubleOptIn = dto.emails_user_confirmation_notifications;
    this.spentEmailsForForNotificationsLeads = dto.emails_notifications_leads;
    this.spentEmailsForManualMessages = dto.emails_manual;
    this.spentEmailsForTriggerMessages = dto.emails_auto;
    this.spentEmailsForUnreadConversationsNotifications = dto.emails_user_notifications;
    this.spentUniqueUsers = dto.users_total;
    this.integrationMessagesTelegram = dto.integration_messages_telegram;
    this.conversationPartGroupsChatGpt = dto.conversation_part_groups_chat_gpt;
  }

  /**
   * Получение количества перерасхода emails
   *
   * @param availableEmails - Доступное количество
   */
  getOverspendingEmails(availableEmails: number): number {
    return QuotasHelpService.getOverspendingEmails(availableEmails, this.spentEmails);
  }

  /**
   * Получение количества перерасхода уникальных пользователей
   *
   * @param availableUniqueUsers - Доступное количество
   */
  getOverspendingUniqueUsers(availableUniqueUsers: number): number {
    return QuotasHelpService.getOverspendingUniqueUsers(availableUniqueUsers, this.spentUniqueUsers);
  }
}

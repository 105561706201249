import { Injectable } from '@angular/core';
import {
  loadStripe,
  SetupIntentResult,
  Stripe,
  StripeCardElement,
  StripeElementBase,
  StripeElements,
} from '@stripe/stripe-js';

import { environment } from '@environment';

/** Сервис для работы с Stripe */
@Injectable({
  providedIn: 'root',
})
export class StripeService {
  /** Экземпляр Stripe */
  stripe!: Stripe;

  constructor() {}

  /**
   * Подтверждение добавления карты в Stripe
   *
   * @param stripeCardElement Элемент StripeCardElement
   * @param clientSecret Секретный ключ
   */
  confirmCardSetup(stripeCardElement: StripeCardElement, clientSecret: string): Promise<SetupIntentResult> {
    if (!this.stripe) {
      throw new Error('Stripe is not initialized');
    }

    const confirmCardSetupParams = {
      // Параметры запроса привязки карты
      payment_method: {
        card: stripeCardElement,
      },
      return_url: `${environment.panelUrl}/3ds/stripe`,
    };
    const confirmCardSetupOptions = {
      handleActions: false,
    };

    return this.stripe.confirmCardSetup(clientSecret, confirmCardSetupParams, confirmCardSetupOptions);
  }

  /**
   * Создание экземпляра CardElement
   *
   * @param stripeElements Экземпляр StripeElements
   */
  createCardElement(stripeElements: StripeElements): StripeCardElement {
    const style = {
      // Стили для создаваемых Stripe элементов
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"PT Root UI"',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    };

    return stripeElements.create('card', style);
  }

  /**
   * Создание экземпляра elements, который управляет группой элементов
   *
   * @param stripe Экземпляр Stripe
   */
  createElementsInstance(stripe: Stripe): StripeElements {
    return stripe.elements({
      fonts: [
        {
          family: 'PT Root UI',
          src: `url(${environment.panelUrl}/assets/fonts/PTRootUICQ-Regular.woff2)`,
          weight: '400',
        },
        {
          family: 'PT Root UI',
          src: `url(${environment.panelUrl}/assets/fonts/PTRootUICQ-Regular.woff)`,
          weight: '400',
        },
      ],
      locale: 'en',
    });
  }

  /** Загрузка Stripe */
  async getStripeInstance(): Promise<Stripe> {
    this.stripe = (await loadStripe(environment.stripePaymentsPublicKey)) as Stripe;

    return this.stripe;
  }

  /**
   * Монтирование элемента Stripe в DOM элемент
   *
   * @param stripeElement Экземпляр StripeElement
   * @param domElement DOM-элемент или селектор, в который будет монтироваться StripeElement
   */
  mountElement(stripeElement: StripeElementBase, domElement: string | HTMLElement) {
    stripeElement.mount(domElement);
  }

  /**
   * Проверка результата повторного подтверждения добавления карты в Stripe
   *
   * @param clientSecret Секретный ключ
   */
  retrieveSetupIntent(clientSecret: string): Promise<SetupIntentResult> {
    if (!this.stripe) {
      throw new Error('Stripe is not initialized');
    }

    return this.stripe.retrieveSetupIntent(clientSecret);
  }
}

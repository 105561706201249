import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { DestroyService } from '@panel/app/services';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';

/**
 * ПО классике куча говна тут, потому что надо чтоб как-то работало со старым ангуляром
 */
@Component({
  selector: 'cq-telegram-operation-select-wrap',
  templateUrl: './telegram-operation-select-wrap.component.html',
  styleUrls: ['./telegram-operation-select-wrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, ...formSubmitTokenProviders],
})
export class TelegramOperationSelectWrapComponent implements OnInit {
  @Input()
  telegramIntegrations: TelegramIntegrationExternal[] = [];

  @Input()
  set selectedIntegrations(value: string | undefined) {
    if (!value) {
      return;
    }

    this.control.setValue({
      ...this.control.value,
      value: { value },
    });
  }

  @Input()
  set operation(val: ELASTICSEARCH_OPERATION) {
    this.control.setValue({
      ...this.control.value,
      type: val,
    });
  }

  @Output()
  valueChange: Subject<{ type: ELASTICSEARCH_OPERATION; value: { value: string } }> = new Subject();

  @Output()
  telegramFilterValid = new Subject<() => boolean>();

  control = new FormControl(
    {
      type: ELASTICSEARCH_OPERATION.LIST_CONTAINS,
      value: {
        value: '',
      },
    },
    { nonNullable: true },
  );

  constructor(
    private readonly destroy$: DestroyService,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSubject: Subject<void>,
  ) {}

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.valueChange.next(this.control.value);
    });
    this.telegramFilterValid.next(() => {
      this.formSubmitSubject.next();
      return this.control.valid;
    });
  }
}

/**
 * Кнопки действий на триггерной цепочкой в редакторе
 *
 * Читать так:
 * ДЕЙСТВИТЕ_БИЛИНГ-ДОСТУП_АКЦЕНТ
 */
export enum TRIGGER_CHAIN_ACTION_BUTTON {
  ACTIVATE_ACCESS_ACCENT = 'activate_access_accent',
  ACTIVATE_NOT_ACCESS_ACCENT = 'activate_not_access_accent',
  COPY_ACCESS_NOT_ACCENT = 'copy_access_not_accent',
  PAUSE_AND_SAVE_NOT_ACCESS_NOT_ACCENT = 'pause_and_save_not_access_not_accent',
  PAUSE_ACCESS_NOT_ACCENT = 'pause_access_not_accent',
  RUN_ACCESS_ACCENT = 'run_access_accent',
  RUN_NOT_ACCESS_NOT_ACCENT = 'run_not_access_not_accent',
  SAVE_ACCESS_ACCENT = 'save_access_accent',
  SAVE_ACCESS_NOT_ACCENT = 'save_access_not_accent',
  SAVE_NOT_ACCESS_ACCENT = 'save_not_access_accent',
  SAVE_NOT_ACCESS_NOT_ACCENT = 'save_not_access_not_accent',
}

<ng-container *transloco="let t; read: 'inputStrComponent'">
  <div
    class="form-group mb-0"
    [cqShowError]="control.controls.value"
  >
    <input
      class="form-control"
      [formControl]="control.controls.value"
      autofocus
      required
      type="text"
    />
    <cq-validation-messages [control]="control.controls.value">
      <cq-validation-message errorName="required">{{ t('error.required') }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'blockBranchEditorComponent'">
  <ng-container *ngFor="let group of ACTIONS_GROUPS_LIST; let lastGroup = last; trackBy: trackByGroup">
    <!--ЦЕЛЕВЫЕ ДЕЙСТВИЯ-->
    <div *ngIf="group === ACTIONS_GROUPS.TARGET_SHOW">
      <ng-container *ngIf="!getMeetingAction(branch); else nextAction">
        <div class="btn-group btn-group-toggle w-100 mb-10">
          <label
            class="btn btn-outline-primary"
            [ngClass]="{
              active: selectedTargetGroup === TARGET_GROUP.CONTINUE_CONVERSATION,
              disabled: isPartOfInterruptScenario
            }"
          >
            <input
              type="radio"
              [disabled]="isPartOfInterruptScenario"
              (click)="onTargetGroupSelect(CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD, TARGET_GROUP.CONTINUE_CONVERSATION)"
              [(ngModel)]="selectedTargetGroup"
              [value]="TARGET_GROUP.CONTINUE_CONVERSATION"
            />
            <span>{{ t('targetAction.targetGroupButtons.continueConversation') }}</span>
          </label>
          <label
            class="btn btn-outline-primary"
            [class.active]="selectedTargetGroup === TARGET_GROUP.END_CONVERSATION"
          >
            <input
              type="radio"
              (click)="onTargetGroupSelect(CHAT_BOT_ACTIONS_TYPES.CLOSE, TARGET_GROUP.END_CONVERSATION)"
              [(ngModel)]="selectedTargetGroup"
              [value]="TARGET_GROUP.END_CONVERSATION"
            />
            <span>{{ t('targetAction.targetGroupButtons.endConversation') }}</span>
          </label>
        </div>
        <ng-select
          class="mb-5"
          [searchable]="false"
          [clearable]="false"
          placeholder="{{ t('targetAction.actionSelect.placeholder') }}"
          [items]="targetActionsGrouping[selectedTargetGroup] | filterTargetActionOptions: branch.isInterruptScenario"
          [formControl]="branch.targetAction"
          (change)="changeTargetAction($event)"
        >
          <ng-template
            ng-option-tmp
            let-action="item"
          >
            <span class="align-middle">
              <i
                class="cqi-sm margin-right-5"
                [ngClass]="[CHAT_BOT_ACTIONS_TYPES_ICONS[action]]"
              ></i>
              <span>{{ t('targetActionSelect.' + caseStyleHelper.toCamelCase(action)) }}</span>
            </span>
          </ng-template>
          <ng-template
            ng-label-tmp
            let-action="item"
          >
            <span class="align-middle">
              <i
                class="cqi-sm margin-right-5"
                [ngClass]="[CHAT_BOT_ACTIONS_TYPES_ICONS[action]]"
              ></i>
              <span>{{ t('targetActionSelect.' + caseStyleHelper.toCamelCase(action)) }}</span>
            </span>
          </ng-template>
        </ng-select>

        <small
          class="d-inline-block text-muted margin-bottom-15"
          [innerHTML]="noticeOfTargetAction"
        ></small>

        <!--CHECKBOX для включения записи свойств для кнопок-->
        <ng-container *ngIf="changedControlToButton$ | async">
          <cq-checkbox-control
            class="margin-bottom-20"
            [formControl]="buttonPropertyCheckboxControl"
            (change)="checkboxValueChange.emit($event)"
          >
            {{ t('propertyCheckbox') }}
          </cq-checkbox-control>
        </ng-container>
      </ng-container>

      <ng-template #nextAction>
        <cq-branch-action-next
          [actionForm]="getMeetingAction(branch).form"
          [nextBranchOptions]="nextBranchOptions"
          [currentApp]="currentApp"
          [chatBotType]="chatBotType"
          [chatBotId]="chatBotId"
          (branchCreate)="getCallbacks(0, ACTIONS_GROUPS.TARGET_SHOW).createNewBranch($event, getMeetingAction(branch))"
        ></cq-branch-action-next>
      </ng-template>
    </div>

    <!--ДЕЙСТВИЯ БОТА-->
    <ng-container
      *ngFor="
        let action of getRenderActionsByGroup(group);
        let actionIndex = index;
        let first = first;
        let last = last;
        trackBy: trackByActionLinkId
      "
    >
      <div [ngbCollapse]="actionsCollapse[action.linkId]">
        <ng-container *ngIf="action.type !== CHAT_BOT_ACTIONS_TYPES.MEET; else meeting">
          <cq-branch-action-container
            class="padding-bottom-20"
            *ngIf="!(isMeetingBlock && action.type === CHAT_BOT_ACTIONS_TYPES.PROPERTY)"
            [action]="action"
            [boldHeader]="group === ACTIONS_GROUPS.TARGET_SHOW"
            [callbacks]="getCallbacks(actionIndex, group)"
            [currentApp]="currentApp"
            [chatBotType]="chatBotType"
            [chatBotId]="chatBotId"
            [index]="getActionHeaderIndex(action.type, actionIndex)"
            [isTargetAction]="group === ACTIONS_GROUPS.TARGET_SHOW"
            [nextBranchOptions]="nextBranchOptions"
            [propertyOptions]="properties"
            [showHeader]="showActionHeader(action)"
          ></cq-branch-action-container>

          <ng-container *ngIf="last">
            <ng-container *ngTemplateOutlet="mainActions"></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #meeting>
          <ng-container *ngTemplateOutlet="mainActions"></ng-container>

          <div [ngbCollapse]="actionsCollapse[action.linkId]">
            <cq-branch-action-container
              class="pt-20"
              [action]="action"
              [boldHeader]="false"
              [callbacks]="getCallbacks(actionIndex, group)"
              [currentApp]="currentApp"
              [chatBotType]="chatBotType"
              [chatBotId]="chatBotId"
              [index]="getActionHeaderIndex(action.type, actionIndex)"
              [isTargetAction]="false"
              [nextBranchOptions]="nextBranchOptions"
              [propertyOptions]="properties"
              [showHeader]="showActionHeader(action)"
            ></cq-branch-action-container>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <!--КНОПКА ДОБАВЛЕНИЯ ДЕЙСТВИЯ BUTTON-->
    <ng-container *ngIf="group === ACTIONS_GROUPS.TARGET_SHOW && (changedControlToButton$ | async)">
      <div
        [ngbTooltip]="
          'chatBot.branchEditor.countLimitTooltip'
            | transloco: { limitCount: CHAT_BOT_ACTIONS_LIMIT_COUNT[CHAT_BOT_ACTIONS_TYPES.BUTTON] }
        "
        [disableTooltip]="!isButtonDisabled(CHAT_BOT_ACTIONS_TYPES.BUTTON)"
        triggers="mouseenter:mouseleave"
      >
        <button
          class="btn btn-block btn-secondary"
          (click)="newButtonCreate.emit()"
          [disabled]="isButtonDisabled(CHAT_BOT_ACTIONS_TYPES.BUTTON)"
          type="button"
        >
          <i class="btn-icon-left cqi-sm cqi-plus"></i>
          <span>{{ t('addButtonButton') }}</span>
        </button>
        <cq-validation-messages
          position="top"
          [control]="branch.form"
        >
          <cq-validation-message errorName="buttonRequired">
            {{ t('actionsButtonRequiredError') }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
      <div class="padding-10"></div>
    </ng-container>

    <ng-template #mainActions>
      <!--ОСНОВНЫЕ ДЕЙТСВИЯ-->
      <div class="margin-5-neg">
        <div
          class="d-flex align-items-center flex-wrap"
          *ngIf="group === ACTIONS_GROUPS.CONTENT"
        >
          <!--Кнопка ТЕКСТ-->
          <div
            class="padding-5"
            [ngbTooltip]="
              'chatBot.branchEditor.countLimitTooltip'
                | transloco: { limitCount: CHAT_BOT_ACTIONS_LIMIT_COUNT[CHAT_BOT_ACTIONS_TYPES.TEXT] }
            "
            container="body"
            [disableTooltip]="!isButtonDisabled(CHAT_BOT_ACTIONS_TYPES.TEXT)"
            triggers="mouseenter:mouseleave"
          >
            <button
              class="btn btn-secondary"
              type="button"
              (click)="botActionCreate.emit(CHAT_BOT_ACTIONS_TYPES.TEXT)"
              [disabled]="isButtonDisabled(CHAT_BOT_ACTIONS_TYPES.TEXT)"
            >
              <i class="btn-icon-left cqi-sm cqi-align-left"></i>
              <span>{{
                'models.chatBot.actionsTypes.' + caseStyleHelper.toCamelCase(CHAT_BOT_ACTIONS_TYPES.TEXT) | transloco
              }}</span>
            </button>
          </div>

          <!--Кнопка ФАЙЛ-->
          <div
            class="padding-5"
            [ngbTooltip]="showAttachTextTooltip()"
            [disableTooltip]="!isButtonDisabled(CHAT_BOT_ACTIONS_TYPES.FILE)"
            triggers="mouseenter:mouseleave"
          >
            <button
              class="btn btn-secondary"
              type="button"
              (click)="botActionCreate.emit(CHAT_BOT_ACTIONS_TYPES.FILE)"
              [disabled]="isButtonDisabled(CHAT_BOT_ACTIONS_TYPES.FILE)"
            >
              <i class="btn-icon-left cqi-sm cqi-paperclip"></i>
              <span>{{
                'models.chatBot.actionsTypes.' + caseStyleHelper.toCamelCase(CHAT_BOT_ACTIONS_TYPES.FILE) | transloco
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <hr
      *ngIf="!lastGroup"
      class="margin-left-20-neg margin-right-20-neg margin-bottom-15"
    />
  </ng-container>
</ng-container>

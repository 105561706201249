import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BlockType, Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions';
import { App } from '@http/app/app.model';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';

@Component({
  selector: 'cq-branch-action-next[actionForm][nextBranchOptions][currentApp]',
  templateUrl: './next.component.html',
  styleUrls: ['./next.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionNextComponent {
  @Input()
  readonly actionForm!: BaseBotActionForm; // Форма с действием

  @Input()
  readonly nextBranchOptions: Branch[] = [];

  @Input()
  currentApp!: App;

  @Input()
  chatBotType: CHAT_BOT_TYPE | null = null;

  @Input()
  chatBotId: string | null = null;

  @Output()
  branchCreate: EventEmitter<BlockType> = new EventEmitter(); // Callback на создание ветки

  /**
   * Записывает id выбранной ветки
   */
  onChangeBranch(branch: Branch): void {
    this.actionForm.nextBranchId = branch.id;
  }
}

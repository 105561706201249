import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { QuillModule } from 'ngx-quill';

import { MessageInputComponent } from './message-input.component';

@NgModule({
  declarations: [MessageInputComponent],
  imports: [CommonModule, FormsModule, QuillModule, TranslocoModule],
  exports: [MessageInputComponent],
})
export class MessageInputModule {}

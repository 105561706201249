import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StepNumberComponent } from './step-number.component';

@NgModule({
  declarations: [StepNumberComponent],
  exports: [StepNumberComponent],
  imports: [CommonModule],
})
export class StepNumberModule {}

(function () {
  'use strict';

  require('./widget-mobile-indent.controller');

  angular.module('myApp.chatSettings').component('cqWidgetMobileIndent', cqWidgetMobileIndent());

  function cqWidgetMobileIndent() {
    return {
      bindings: {
        horizontalIndent: '=', // Горизонтальный отступ бейджа чата в мобильной версии
        position: '=', // Расположение бейджа чата
        verticalIndent: '=', // Вертикальный отступ бейджа чата в мобильной версии
      },
      controller: 'CqWidgetMobileIndentController',
      controllerAs: 'vm',
      template: require('./widget-mobile-indent.html'),
    };
  }
})();

(function () {
  'use strict';

  angular
    .module('myApp.planCapability')
    .controller('CqPlanCapabilityPaywallWrapperController', CqPlanCapabilityPaywallWrapperController);

  function CqPlanCapabilityPaywallWrapperController() {
    var vm = this;

    vm.$onInit = init;

    function init() {}
  }
})();

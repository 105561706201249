import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlbatoSettingsModule } from '@panel/app/pages/integrations/content/albato/settings/albato-settings.module';

import { AlbatoWrapperSettingsComponent } from './albato-wrapper-settings.component';

@NgModule({
  declarations: [AlbatoWrapperSettingsComponent],
  exports: [AlbatoWrapperSettingsComponent],
  imports: [AlbatoSettingsModule, CommonModule],
})
export class AlbatoWrapperSettingsModule {}

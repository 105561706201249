<div id="common-container">
  <div
    id="carrotquest-messenger-collapsed-container"
    class="carrotquest-css-reset"
    [ngClass]="'carrotquest-messenger-' + app.settings.messenger_position"
  >
    <div class="carrot-notification-container">
      <cq-carrot-notification-frame-sdk
        id="carrot-messenger-frame"
        class="carrot-messenger-frame"
        [app]="app"
        [content]="content"
        [language]="language"
        [sender]="sender"
      ></cq-carrot-notification-frame-sdk>
    </div>
    <div id="chat-container">
      <div id="icon-container">
        <cq-carrot-messenger-collapsed-frame
          id="carrot-messenger-collapsed-frame"
          class="carrot-messenger-collapsed-frame"
          [app]="app"
        ></cq-carrot-messenger-collapsed-frame>
      </div>
    </div>
  </div>
</div>

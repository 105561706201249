import { firstValueFrom, Subject } from 'rxjs';
import { CHAT_BOT_ACTIONS_TYPES } from '../../../../../app/http/chat-bot/chat-bot.constants';
import { ChatBotModel } from '../../../../../app/http/chat-bot/chat-bot.model';
import { ChatBotActionMapper } from '../../../../../app/http/chat-bot/mappers/action.mapper';
import { FEATURE_BY_REGISTRATION_TASK, FEATURES } from '../../../../../app/http/feature/feature.constants';
import {
  AUDIENCE_FILTER_TYPE,
  MESSAGE_DELETING_TYPES,
  MESSAGE_PAGE_TYPES,
} from '../../../../../app/http/message/message.constants';
import { STARTER_GUIDE_STEPS } from '../../../../../app/http/starter-guide/starter-guide.constants';
import { URL_FILTER_TYPE } from '../../../../../app/partials/url-filter-configurator/url-filter-configurator.component';
import { PLAN_FEATURE } from '../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { SENDING_FILTERS_GROUP_TYPES } from '../../../../../app/services/conditions-sending/conditions-sending.constants';
import { ConditionsSendingModel } from '../../../../../app/services/conditions-sending/conditions-sending.model';
import { LS_MOVE_STARTER_GUIDE_MODAL } from '../../../../../app/shared/constants/localstorage.keys';

const letters = 'a-z\u0430-\u044F\u0451';

// https://xn--80aesfpebagmfblc0a.xn--p1ai/ https://стопкороновирус.рф https://google.com
// По хорошему надо отделить каждый отделбный вариант, но пока все в кашу прост
// паттер для валидации URL
const URL_PATTERN = new RegExp(
  '^(http(s)?:\\/\\/)?' + // protocol
    `((([${letters}\\d]([${letters}\\d-]*[${letters}\\d])*)\\.)+[${letters}\\d-]{2,}|` + // domain name
    `(localhost)|` + // OR localhost
    `((\\d{1,3}\\.){3}\\d{1,3}))` + // OR ip (v4) address
    `(:\\d+)?(\\/[-${letters}\\d%_.~+]*)*` + // port and path
    `(\\?[;&${letters}\\d%_.~+=-]*)?` + // query string
    `(#[#-${letters}\\d_]*)?$`,
  'i',
); // fragment locator
(function () {
  'use strict';

  angular.module('myApp.chatBot.leadBot').controller('cqChatBotEditController', cqChatBotEditController);

  function cqChatBotEditController(
    $filter,
    $timeout,
    $translate,
    $scope,
    $state,
    $stateParams,
    $q,
    $window,
    $uibModal,
    toastr,
    PROJECT_NAME,
    carrotquestHelper,
    chatBotModel,
    chatBotTemplateModel,
    eventTypeModel,
    featureModel,
    filterAjsModel,
    messagePartModel,
    paywallService,
    planVersionService,
    planFeatureAccessService,
    systemError,
    starterGuideModel,
    timeUnitService,
    wizardHelper,
  ) {
    /** Действия над лид-ботом */
    const ACTION_ON_LEAD_BOT = {
      /** Активация/Приостановка */
      CHANGE_STATUS: 'change_status',
      /** Создание копии */
      COPY: 'copy',
      /** Создание и активация */
      CREATE_AND_ACTIVATE: 'create_and_activate',
      /** Создание и активация позже */
      CREATE_AND_ACTIVATE_LATER: 'create_and_activate_later',
      /** Далее */
      NEXT: 'next',
      /** Назад */
      PREV: 'prev',
      /** Сохранение или создание с выходом из редактора */
      SAVE_OR_CRATE_AND_LEAVE: 'save_or_crate_and_leave',
      /** Сохранение или создание без выхода из редактора */
      SAVE_OR_CRATE_AND_STAY: 'save_or_crate_and_stay',
      /** Примеры сценариев */
      TEMPLATES: 'templates',
      /** Тестирование */
      TEST: 'test',
    };

    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.activeBotsAmount = getActiveBotsAmount(vm.currentApp);
      vm.activeBotsIds = getActiveBotsIds(vm.currentApp);

      vm.accessToLeadBots = planFeatureAccessService.getAccess(
        PLAN_FEATURE.LEAD_BOTS,
        vm.currentApp,
        vm.activeBotsAmount,
      );

      vm.accessToAutoMessagesAbTesting = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_AB_TESTING,
        vm.currentApp,
      );
      vm.accessToAutoMessagesControlGroup = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_CONTROL_GROUP,
        vm.currentApp,
      );
      vm.accessToChatBotsMassActivation = planFeatureAccessService.getAccess(
        PLAN_FEATURE.CHAT_BOTS_MASS_ACTIVATION,
        vm.currentApp,
      );
      vm.accessToEventsEventTypesCustom = planFeatureAccessService.getAccess(
        PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
        vm.currentApp,
      );
      vm.accessToUsersCustomProperties = planFeatureAccessService.getAccess(
        PLAN_FEATURE.USERS_CUSTOM_PROPERTIES,
        vm.currentApp,
      );
      vm.accessToUsersTags = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, vm.currentApp);

      trackEnterOnStep(1);

      vm.ACTION_ON_LEAD_BOT = ACTION_ON_LEAD_BOT;
      vm.addTrigger = addTrigger;
      vm.startBadgeCanvasClicked = startBadgeCanvasClicked;
      vm.changeBotStatusStatus = changeBotStatusStatus;
      vm.chatBotForm = null; // Форма чат-бота
      vm.chatBotSnapshot = angular.copy(vm.chatBotMessage.bot); // Чат-бот до сохранения NOTE надо для формирования диффа для передачи на бек
      vm.createCopy = createCopy;
      vm.createCopyPerforming = false; // выполняется ли переход на состояние копирования сообщения
      vm.customEventTypeCreated = false; // Флаг созданного события
      vm.customEventTypeCreating = false; // Флаг создания кастомного события
      vm.deletedBranchesList = []; // Список ID удаленных веток NOTE Надо для удаления на бекенде
      vm.deleteMessage = ConditionsSendingModel.getDefaultDeleteMessageForTriggerStep(
        vm.currentApp.settings.timezone_offset,
      ); // Параметры удаления сообщений
      vm.deleteMessageForm = null; // Форма протухания автосообщений
      vm.enterToTriggerWizardStep = enterToTriggerWizardStep;
      vm.eventTypes = vm.properties.eventTypes; //События пользователя
      vm.expirationTimeError = {
        // Объект для показа ошибки о прошедшей дате протухания
        expirationTime: false,
      };
      vm.featureModel = featureModel;
      vm.getActionOnLeadBot = getActionOnLeadBot;
      vm.getDenialReasonsForActivateAndPause = getDenialReasonsForActivateAndPause;
      vm.getSessionStartEventTypeId = getSessionStartEventTypeId;
      vm.FEATURES = FEATURES;
      vm.filtersForm = null; // Объект формы фильтров
      vm.firstStepOnExitCallback = firstStepOnExitCallback;
      vm.onBotFormChange = function () {
        vm.formIsSaved = false;
        $timeout();
      };
      vm.hasAccessToUserGuidingOnboarding = featureModel.hasAccess(FEATURES.USERGUIDING_BOTS_ONBOARDING);
      vm.hasDenialReasonsForActivateAndPause = hasDenialReasonsForActivateAndPause;
      vm.hasDeletableMessagePart = angular.bind(null, angular.identity, true); // Есть ли среди настраиваемых вариантов сообщений хотя бы одно удаляемое NOTE Тут только один тип сообщения и он удаляемый
      vm.initUpdateBotFn = initUpdateBotFn;
      vm.isAudienceValid = isAudienceValid;
      vm.isCollapsedDisplaySettings = false;
      vm.isEditing = $state.is('app.content.messagesAjs.chatBot.edit'); // Бот открыт на редактирование
      vm.formIsSaved = vm.isEditing;
      vm.formSubmitSource = null; // Subject, чтоб уведомлять контролы триггеров о сабмите формы
      vm.isRequestPerforming = false; // Флаг выполнения запроса
      vm.isStepFormValid = isStepFormValid;
      vm.isPreventHasDenialReasonsForActivateAndPause = isPreventHasDenialReasonsForActivateAndPause;
      vm.moveStep = moveStep;
      vm.needToSetDefaultTrigger = needToSetDefaultTrigger;
      vm.openTemplatesModal = openTemplatesModal;
      vm.onValidationCallbackReady = onValidationCallbackReady;
      vm.openPaywallLeadbotModal = openPaywallLeadbotModal;
      vm.onTriggerParamsChange = onTriggerParamsChange;
      vm.onTriggerWrapperStateLoaded = onTriggerWrapperStateLoaded;
      vm.markStarterGuideStepMade = markStarterGuideStepMade;
      vm.MESSAGE_PAGE_TYPES = MESSAGE_PAGE_TYPES;
      vm.paywallService = paywallService;
      vm.shouldAddInitialTrigger = true; // Флаг для добавления триггера по-умолчанию
      vm.saveOrCreateAndStay = saveOrCreateAndStay;
      vm.showBotTestingPopup = showBotTestingPopup;
      vm.setBotSubject = new Subject();
      vm.setBot$ = vm.setBotSubject.asObservable();
      vm.showedSendingFiltersPopoverInAudience = null; // Статус показа поповера с условиями отправки по URL в "Аудитория"
      vm.step = 1; // Текущий шаг создания/редактирования чат-бота
      vm.submitRequestPerforming = false; // выполняется ли запрос на создание/сохранение сообщения
      vm.trackEnterOnStep = trackEnterOnStep;
      vm.trackClickCreateCopy = trackClickCreateCopy;
      vm.trackClickOnCloseTemplateChatBotModal = trackClickOnCloseTemplateChatBotModal;
      vm.trackClickShowTips = trackClickShowTips;
      vm.triggerForm = null; // Форма настройки триггера
      vm.translationEntityName = $translate.instant('models.chatBot.labelLeadBot').toLowerCase();
      // Костыль для валидации новых компонентов при попытке выхода из вкладки триггеров
      // Валидаторы по дефолту возвращают true, чтоб клиента не останавливало, когда он пролистывает вкладки.
      // Если он только зашел на вкладку мы подразумеваем, что у него все валидно
      vm.triggerNewComponentValidators = {
        areSendingHoursValid: () => Promise.resolve(true),
        isDispatchValid: () => Promise.resolve(true),
        isSendingDelayValid: () => Promise.resolve(true),
        isSendingRepeatValid: () => Promise.resolve(true),
      };
      vm.updateBot = () => {}; // Обновление бота. В нее присваивается функция из Angular, которая обновляет
      vm.validateOnTriggerStep = validateOnTriggerStep;
      vm.validateTriggerFn = null;
      vm.wizard = null; //Визард
      vm.shownAudienceFilterType =
        vm.chatBotMessage.jinjaFilterTemplate === null ? AUDIENCE_FILTER_TYPE.DEFAULT : AUDIENCE_FILTER_TYPE.JINJA;
      vm.jinjaFilterTemplateCheckingResult = undefined;

      wizardHelper.getWizard().then(getWizardSuccess);

      if ($state.is('app.content.messagesAjs.chatBot.edit.copy')) {
        prepareChatBotMessageCopy();
        prepareChatBotCopy();
      }

      if ($state.params.step) {
        vm.step = Number($state.params.step);
      }

      $scope.$on('message', handleRts);

      ChatBotActionMapper.parseActionsKeyName(vm.chatBotMessage.bot.branches, vm.properties);
      ChatBotActionMapper.parseActionsIntegrationName(
        vm.chatBotMessage.bot.branches,
        vm.emailNotificationIntegrationsExternal,
      );

      if (vm.isEditing || $state.is('app.content.messagesAjs.chatBot.edit.copy')) {
        vm.isEditing && trackOpenEditBotPage();
        // АУДИТОРИЯ
        // NOTE порядок следования действий с тегами крайне важен
        filterAjsModel.linkWithPropsAndTags(vm.chatBotMessage.filters, vm.properties, vm.tags);

        vm.chatBotMessage.isMessageHaveFilters = filterAjsModel.isMessageHaveFilters(vm.chatBotMessage);

        vm.tags = $filter('filter')(vm.tags, { removed: '!' }); // Из всех тегов получаем только не удаленные
        // NOTE Трогать с осторожностью! Такая хитрая фильтрация тегов для того, чтобы показывать только не удаленные теги и удаленные, но которые находятся в фильтрах
        vm.tags.push.apply(
          vm.tags,
          $filter('map')($filter('filter')(vm.chatBotMessage.filters.filters.tags, { tag: { removed: '!!' } }), 'tag'),
        ); // Из фильтров автосообщения получаем теги (именно теги, не объект фильтра), которые были удалены/*
        if (vm.chatBotMessage.expirationInterval) {
          vm.deleteMessage.deleteType = MESSAGE_DELETING_TYPES.TIME_INTERVAL;
          vm.deleteMessage.interval.value = vm.chatBotMessage.expirationInterval;
          vm.deleteMessage.interval.unit = timeUnitService.getByValue(
            vm.chatBotMessage.expirationInterval,
            vm.deleteMessage.interval.units,
          );
        } else if (vm.chatBotMessage.expirationTime) {
          vm.deleteMessage.deleteType = MESSAGE_DELETING_TYPES.CERTAIN_DATE;
          vm.deleteMessage.time.date = vm.chatBotMessage.expirationTime;
          vm.deleteMessage.time.hours = vm.chatBotMessage.expirationTime.hours();
          vm.deleteMessage.time.minutes = vm.chatBotMessage.expirationTime.minutes();
          vm.deleteMessage.time.time = vm.chatBotMessage.expirationTime;
        }
      } else {
        trackOpenCreateBotPage();
        vm.deleteMessage.deleteType = MESSAGE_DELETING_TYPES.TIME_INTERVAL;
        vm.tags = $filter('filter')(vm.tags, { removed: '!' }); // Из всех тегов получаем только не удаленные
      }

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }

      // Показывать выбор шаблонов если:
      if (
        !!vm.templates?.length && // есть шаблоны не показывать модалку
        $state.is('app.content.messagesAjs.chatBot.create.new') && // это состояние создания бота
        !$stateParams.hideTemplateModal // Параметр скрытия модалки не указан или false
      ) {
        openTemplatesModal();
      }

      vm.chatBotMessage.triggers = vm.needToSetDefaultTrigger()
        ? [vm.getSessionStartEventTypeId()]
        : vm.chatBotMessage.triggers;

      vm.triggerParams = {
        triggers: vm.chatBotMessage.triggers,
        triggerTypes: vm.chatBotMessage.triggerTypes,
        delay: {
          isEnabled: vm.chatBotMessage.isAfterTime,
          value: {
            time: vm.chatBotMessage.afterTimeValue,
            unit: vm.chatBotMessage.afterTimeTimeUnit,
          },
        },
        sendingFilters: vm.chatBotMessage.sendingFilters,
      };
    }

    /**
     * Добавление триггера автосообщения
     */
    function addTrigger() {
      vm.chatBotMessage.triggers = [...vm.chatBotMessage.triggers, null];
    }

    /**
     * Добоавить в бота начальный триггер
     */
    function addInitialTrigger() {
      const sessionStartEvent = $filter('filter')(vm.properties.eventTypes, { name: '$session_start' }, true)[0];
      // надо заменить триггер null
      // т.к. это триггер поумолчанию он точно будет первый
      vm.chatBotMessage.triggers[0] = sessionStartEvent ? sessionStartEvent.id : null;
    }

    function getSessionStartEventTypeId() {
      const sessionStartEventType = vm.properties.eventTypes.find((eventType) => eventType.name === '$session_start');
      if (!sessionStartEventType) {
        throw new Error('Could not find session start event type');
      }
      return sessionStartEventType.id;
    }

    /**
     * Переходит на 2ой шаг визарда
     */
    function startBadgeCanvasClicked() {
      vm.firstStepOnExitCallback()
        .then(() => {
          $scope.$apply(() => {
            vm.step = 2;
          });
        })
        .catch(() => {});
    }

    /**
     * Установка статуса боту
     */
    function changeBotStatusStatus() {
      const statusToChange = !vm.chatBotMessage.active;

      if (statusToChange) {
        increaseActiveBotsAmount(vm.currentApp, 1);
        vm.activeBotsIds.push(vm.chatBotMessage.id);
        trackSetActiveBot();
      } else {
        decreaseActiveBotsAmount(vm.currentApp, 1);
        vm.activeBotsIds.pop(vm.chatBotMessage.id);
        trackSetPauseBot();
      }

      saveOrCreateAndStay(statusToChange);

      vm.accessToLeadBots = planFeatureAccessService.getAccess(
        PLAN_FEATURE.LEAD_BOTS,
        vm.currentApp,
        vm.activeBotsAmount,
      );
    }

    /**
     * Создание чат-бота
     *
     * @param {Boolean} active - Будет ли включен чат-бот после сохранения
     *
     * @return {Promise}
     */
    function createChatBot(active) {
      if (vm.shouldAddInitialTrigger) {
        addInitialTrigger();
      }
      return firstValueFrom(chatBotModel.createLeadBot(vm.currentApp.id, vm.chatBotMessage, active, vm.deleteMessage));
    }

    /**
     * Переход на состояние создания копии
     */
    function createCopy() {
      vm.createCopyPerforming = true;

      $state
        .go(
          $state.current.name + '.copy',
          {
            fromStarterGuideStep: $state.params.fromStarterGuideStep,
          },
          {
            reload: $state.current.name,
            location: 'replace',
          },
        )
        .finally(createCopyFinally);

      function createCopyFinally() {
        vm.createCopyPerforming = false;
      }
    }

    /**
     * Уменьшение количества активных ботов
     *
     * @param currentApp - Текущее приложение
     * @param decreaseAmount - Количество на которое уменьшить
     */
    function decreaseActiveBotsAmount(currentApp, decreaseAmount) {
      vm.activeBotsAmount = vm.activeBotsAmount - decreaseAmount;
    }

    /**
     * Пользователь перешел на шаг визарда с выбором триггера
     */
    function enterToTriggerWizardStep() {
      // Когда пользователь при создании перешел на шаг с выбором триггера дефолтный триггре выбирать за него НЕ надо
      vm.shouldAddInitialTrigger = false;
    }

    function firstStepOnExitCallback() {
      return isStepFormValid(vm.chatBotForm);
    }

    /**
     * Получение набора действий над лид-ботом
     *
     * @param step - Шаг, для которого необходимо получить набор действий над лид-ботом
     */
    function getActionOnLeadBot(step) {
      switch (step) {
        case 1:
          return [
            vm.ACTION_ON_LEAD_BOT.TEMPLATES,
            vm.ACTION_ON_LEAD_BOT.SAVE_OR_CRATE_AND_STAY,
            vm.ACTION_ON_LEAD_BOT.TEST,
            vm.ACTION_ON_LEAD_BOT.NEXT,
          ];
        case 2:
          return vm.isEditing
            ? [
                vm.ACTION_ON_LEAD_BOT.CHANGE_STATUS,
                vm.ACTION_ON_LEAD_BOT.TEMPLATES,
                vm.ACTION_ON_LEAD_BOT.COPY,
                vm.ACTION_ON_LEAD_BOT.SAVE_OR_CRATE_AND_LEAVE,
              ]
            : [
                vm.ACTION_ON_LEAD_BOT.TEMPLATES,
                vm.ACTION_ON_LEAD_BOT.CREATE_AND_ACTIVATE_LATER,
                vm.ACTION_ON_LEAD_BOT.CREATE_AND_ACTIVATE,
              ];
        default:
          return [];
      }
    }

    /**
     * Получение количества активных ботов
     *
     * @param currentApp - Текущее приложение
     *
     * @return {number}
     */
    function getActiveBotsAmount(currentApp) {
      switch (true) {
        case planVersionService.isV1(currentApp):
        case planVersionService.isV2(currentApp):
          return (
            vm.dataAboutActiveFacebookBots.amount +
            vm.dataAboutActiveLeadBots.amount +
            vm.dataAboutActiveWelcomeBots.amount
          );
        default:
          return vm.dataAboutActiveLeadBots.amount + vm.dataAboutActiveFacebookBots.amount;
      }
    }

    /**
     * Получение активных ID's ботов
     *
     * @param currentApp - Текущее приложение
     *
     * @return {number[]}
     */
    function getActiveBotsIds(currentApp) {
      switch (true) {
        case planVersionService.isV1(currentApp):
        case planVersionService.isV2(currentApp):
          return [
            ...vm.dataAboutActiveFacebookBots.ids,
            ...vm.dataAboutActiveLeadBots.ids,
            ...vm.dataAboutActiveTelegramBots.ids,
            ...vm.dataAboutActiveWelcomeBots.ids,
          ];
        default:
          return [
            ...vm.dataAboutActiveFacebookBots.ids,
            ...vm.dataAboutActiveLeadBots.ids,
            ...vm.dataAboutActiveTelegramBots.ids,
          ];
      }
    }

    /**
     * Получение веток, которые изменились после последнего сохранения
     *
     * @return {Array}
     */
    function getChangedBotBranches() {
      var changedBot = angular.copy(vm.chatBotMessage.bot);
      changedBot.branches = [];

      for (let i = 0; i < vm.chatBotMessage.bot.branches.length; i++) {
        const branch = vm.chatBotMessage.bot.branches[i];

        const branchSnapshot = $filter('filter')(vm.chatBotSnapshot.branches, { id: branch.id }, true)[0]; // Ветка до сохранения

        //Если нет id => это новая ветка
        // или имя веток отличается
        // или количество действий изменилось
        // или изменились координаты положения
        // => ветка поменялась
        if (
          !branch.id ||
          branchSnapshot.name !== branch.name ||
          branch.actions.length !== branchSnapshot.actions.length ||
          isCoordinatesChanged(branch.coordinates, branchSnapshot.coordinates)
        ) {
          changedBot.branches.push({ ...branch });
          continue;
        }

        for (let k = 0; k < branch.actions.length; k++) {
          const action = branch.actions[k];
          const filterQuery = action.id ? { id: action.id } : { linkId: action.linkId };
          const actionBranchSnapshot = $filter('filter')(branchSnapshot.actions, filterQuery, true)[0]; // Действие ветки до сохранения

          // сервер ничего не знает о CLOSE, поэтому убираем из DTO
          if (action.type === CHAT_BOT_ACTIONS_TYPES.CLOSE) {
            continue;
          }
          // У ветки прерывания нарисован такой экшен, на самом деле его нет (для бека)
          if (action.type === CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE) {
            continue;
          }

          // bodyJson действий отличается
          const actionsBodyJsonAreDifferent =
            !angular.equals(action.bodyJson, actionBranchSnapshot?.bodyJson) &&
            action.bodyJson !== null &&
            // Если bodyJson snapshot'а пустой, а в оригинальном bodyJson,
            // enableUrl == false => считать что изменений не было
            !(angular.equals(actionBranchSnapshot?.bodyJson, {}) && !action.bodyJson.enableUrl);

          // body действий отличается
          const actionsBodyAreDifferent =
            // костыль: дополнительная проверка, потому что initial value данных с бэка и формы не совпадают
            action.body !== actionBranchSnapshot?.body && action.body !== null && actionBranchSnapshot?.body !== '';

          // Был добавлен файл
          const hasNewFile = action.type === CHAT_BOT_ACTIONS_TYPES.FILE && !action.attachments[0]?.id;

          // keyName был изменен
          const keyNameChanged =
            action.keyName !== actionBranchSnapshot?.keyName &&
            // костыль: дополнительная проверка, потому что initial value данных с бэка и формы не совпадают
            action.keyName !== null &&
            actionBranchSnapshot?.keyName !== '';

          //Если есть нет id => это новое действие
          // или body отличается
          // или key_name отличается
          // или у прикрепления нет ID
          // или отличается порядок
          // или изменлось свойство NextBranch
          // => ветка поменялась
          if (
            !action.id ||
            actionsBodyAreDifferent ||
            actionsBodyJsonAreDifferent ||
            keyNameChanged ||
            hasNewFile ||
            action.order !== actionBranchSnapshot?.order ||
            action.nextBranchLinkId !== actionBranchSnapshot.nextBranchLinkId
          ) {
            changedBot.branches.push({ ...branch });
            break;
          }
        }
      }

      return changedBot;

      function isCoordinatesChanged(branchCoords, branchSnapshotCoords) {
        // Считам что что-то поменялось если
        // branchSnapshot не имеет координат, а branch их получила
        // или branchSnapshot и branch имеет координаты и они не совпадают
        return (
          (!branchSnapshotCoords && branchCoords) ||
          // костыль: "> 3" сделано потому, что там при отрисовке происходит небольшое смещение, которое я не хочу править)
          (branchSnapshotCoords && branchCoords && Math.abs(branchSnapshotCoords.x - branchCoords.x) > 3) ||
          Math.abs(branchSnapshotCoords.y - branchCoords.y) > 3
        );
      }
    }

    /**
     * Получение причин отказа в доступе для Активации|Приостановки лид-бота
     *
     * @return {ProductFeatureDenialReason|ProductFeatureDenialReason[]}
     */
    function getDenialReasonsForActivateAndPause() {
      switch (true) {
        case !vm.accessToLeadBots.hasAccess:
          return vm.accessToLeadBots.denialReason;
        default:
          return [
            vm.accessToAutoMessagesControlGroup,
            vm.accessToEventsEventTypesCustom,
            vm.accessToUsersCustomProperties,
            vm.accessToUsersTags,
          ]
            .filter((access) => !access.hasAccess)
            .filter((access) => {
              switch (access) {
                case vm.accessToAutoMessagesControlGroup:
                  return vm.chatBotMessage.isControlGroupEnabled;
                case vm.accessToEventsEventTypesCustom:
                  return (
                    hasEventsEventTypesCustomInChatBotContent() ||
                    $filter('filter')(vm.chatBotMessage.filters.filters.events, { eventType: { name: '!$' } }, false)
                      .length > 0
                  );
                case vm.accessToUsersCustomProperties:
                  return (
                    hasUsersCustomPropertiesInChatBotContent() ||
                    $filter('filter')(vm.chatBotMessage.filters.filters.props, { userProp: { groupOrder: 5 } }, false)
                      .length > 0
                  );
                case vm.accessToUsersTags:
                  return vm.chatBotMessage.filters.filters.tags.length > 0;
                default:
                  return true;
              }
            })
            .map((access) => access.denialReason);
      }
    }

    /**
     * Возвращает имя события по ID
     *
     * @param {String} eventId - ID события
     * @returns {String}
     */
    function getEventTypeByEventId(eventId) {
      return $filter('filter')(vm.eventTypes, { id: eventId }, true)[0];
    }

    /**
     * Получение количества фильтров по URL с типом «С точным адресом»
     *
     * @returns {Number}
     */
    function getExactUrlFiltersLength() {
      return $filter('filter')(
        vm.chatBotMessage.sendingFilters.filters,
        {
          type: URL_FILTER_TYPE.FULLY_MATCHED,
          match: '!',
        },
        true,
      ).length;
    }

    /**
     * Получение количества фильтров по URL с типом «Содержит в адресе»
     *
     * @returns {Number}
     */
    function getUrlContainsFiltersLength() {
      return $filter('filter')(
        vm.chatBotMessage.sendingFilters.filters,
        {
          type: URL_FILTER_TYPE.CONTAINS,
          match: '!',
        },
        true,
      ).length;
    }

    /**
     * Получение количества фильтров по URL с типом «С точным адресом и параметрами»
     *
     * @returns {Number}
     */
    function getUrlPathEqFiltersLength() {
      return $filter('filter')(
        vm.chatBotMessage.sendingFilters.filters,
        {
          type: URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS,
          match: '!',
        },
        true,
      ).length;
    }

    /**
     * Обработка RTS сообщений
     *
     * @param event
     * @param info
     */
    function handleRts(event, info) {
      var channel = info.channel,
        data = info.data;

      if (channel.indexOf('event_types_activated.') === 0) {
        let callApply = false;

        for (var i = 0; i < data.ids.length; i++) {
          getEventTypeByEventId(data.ids[i]).active = true;
          callApply = true;
        }

        callApply && $scope.$applyAsync();
      } else if (channel.indexOf('event_types_created.') === 0) {
        let callApply = false;

        for (var i = 0; i < data.event_types.length; i++) {
          eventTypeModel.parse(data.event_types[i]);
          vm.eventTypes = vm.properties.eventTypes = [...vm.eventTypes, data.event_types[i]];
          callApply = true;
        }

        callApply && $scope.$applyAsync();
      }
    }

    /**
     * Увеличение количества активных ботов
     *
     * @param currentApp - Текущее приложение
     * @param increaseAmount - Количество на которое увеличить
     */
    function increaseActiveBotsAmount(currentApp, increaseAmount) {
      vm.activeBotsAmount = vm.activeBotsAmount + increaseAmount;
    }

    /**
     * Инициализация функции по обновлению бота
     *
     * @param {Function} cb
     */
    function initUpdateBotFn(cb) {
      vm.updateBot = cb;
    }

    /**
     * Валидна ли настройка аудитории
     *
     * @return {Promise}
     */
    function isAudienceValid() {
      if (vm.filtersForm.$valid) {
        return $q.resolve();
      } else {
        return $q.reject();
      }
    }

    /**
     * Есть ли причины отказа для Активации|Приостановки лид-бота
     *
     * @return {boolean}
     */
    function hasDenialReasonsForActivateAndPause() {
      const denialReasons = getDenialReasonsForActivateAndPause();

      return angular.isArray(denialReasons) ? !!denialReasons.length : !!denialReasons;
    }

    /**
     * Используются ли в содержимом лид-бота кастомные свойства
     *
     * @return {boolean}
     */
    function hasUsersCustomPropertiesInChatBotContent() {
      return vm.chatBotMessage.bot.branches.some((branch) => {
        return branch.actions.some((action) => {
          return (
            [CHAT_BOT_ACTIONS_TYPES.PROPERTY, CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD].includes(action.type) &&
            !action.keyName.includes('$')
          );
        });
      });
    }

    /**
     * Используются ли в содержимом лид-бота кастомные события
     */
    function hasEventsEventTypesCustomInChatBotContent() {
      return vm.chatBotMessage.bot.branches.forEach((branch) => {
        return branch.actions.some((action) => {
          return action.type === CHAT_BOT_ACTIONS_TYPES.EVENT && !action.keyName.includes('$');
        });
      });
    }

    function isShowMoveStarterGuideModal() {
      const selectedTask = vm.djangoUserTempData?.task;
      const featureBySelectedTask = FEATURE_BY_REGISTRATION_TASK[selectedTask];

      return (
        featureModel.hasAccess(featureBySelectedTask) &&
        vm.isFirstLeadBot &&
        localStorage.getItem(LS_MOVE_STARTER_GUIDE_MODAL)
      );
    }

    /**
     * Нужно ли отменять работу hasDenialReasonsForActivateAndPause
     *
     * NOTE:
     *  Пользователь должен иметь возможность деактивировать лид-бота, даже если у него нет доступа до фичи "Лид-боты"
     *
     *  @return {boolean}
     */
    function isPreventHasDenialReasonsForActivateAndPause() {
      if (vm.chatBotMessage?.id && vm.activeBotsIds.includes(vm.chatBotMessage.id)) {
        return true;
      }

      return false;
    }

    /**
     * Проверка валидности формы шага
     *
     * @param {form.FormController} form Контроллер формы
     */
    function isStepFormValid(form) {
      if (angular.isDefined(form)) {
        var deferred = $q.defer();

        form.$commitViewValue();
        form.$setSubmitted();

        $timeout(function () {
          if (form.$invalid) {
            deferred.reject();
          } else {
            deferred.resolve();
          }
        }, 0);

        return deferred.promise;
      } else {
        return $q.resolve();
      }
    }

    /**
     * Сливает бота полученного с сервера с текущим ботом
     *
     * @param {Object} chatBot - Чат-бот
     * @param {Object} mapIds - Сопоставление linkId и id
     */
    function mergeBotFromServer(chatBot, mapIds) {
      for (var linkId in mapIds) {
        var branch = $filter('filter')(vm.chatBotMessage.bot.branches, { linkId: linkId }, true)[0];

        branch.id = mapIds[linkId];

        branch.parentBranchIds.splice(-1, 1, branch.id);
      }

      for (var i = 0; i < vm.chatBotMessage.bot.branches.length; i++) {
        var branch = $filter('filter')(chatBot.branches, { id: vm.chatBotMessage.bot.branches[i].id }, true)[0];

        // Чтобы код ниже работал нормально, надо гарантировать, что в момент получения бота с созданными действиями
        // их количество и порядок был таким же как и в момент сохранения, т.е. пользователь не производил ни каких действий с действиями (сорян за тавтологию)
        // на текущий меомент это гарантируется лоадером
        // таким образом i-ое действие текущего бота и равно i-му действию бота, пришедшего с бекенда
        for (var g = 0; g < vm.chatBotMessage.bot.branches[i].actions.length; g++) {
          var action = vm.chatBotMessage.bot.branches[i].actions[g];
          action.id = branch.actions[g].id;

          if (ChatBotModel.isConnectionSourceAction(action.type) && action.nextBranchLinkId) {
            action.nextBranchId = branch.actions[g].nextBranchId;
          }
        }
      }
    }

    /**
     * Отметить шаг стартергайда как пройденый
     */
    function markStarterGuideStepMade() {
      if (vm.isEditing) {
        return;
      }
      if (
        !$stateParams.fromStarterGuideStep ||
        $stateParams.fromStarterGuideStep !== STARTER_GUIDE_STEPS.LEADS_COLLECTION
      ) {
        return;
      }
      starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.LEADS_COLLECTION).subscribe();
    }

    /**
     * Переход на шаг с номером stepNumber
     *
     * @param {Number} stepNumber Номер шага
     */
    function moveStep(stepNumber) {
      //var trackedParams;
      //if (vm.chatBotMessage.name) {
      //  trackedParams = {
      //    'Название': vm.chatBotMessage.name
      //  };
      //}

      vm.wizard.goToStep(vm.wizard.getStepByName(stepNumber));
    }

    function needToSetDefaultTrigger() {
      return !(
        vm.chatBotMessage.triggers.length ||
        vm.chatBotMessage.triggerTypes.openedWebPageTriggers.length ||
        vm.chatBotMessage.triggerTypes.openedSdkPageTriggers.length ||
        vm.chatBotMessage.triggerTypes.leaveSiteAttemptTrigger
      );
    }

    /**
     * Открыть модалку для перехода на стратергайд
     */
    function openMoveStarterGuideModal() {
      $uibModal.open({
        component: 'cqMoveStartGuideModalWrapper',
        size: 'md modal-dialog-centered',
        backdrop: 'static',
      });
    }

    /**
     * Обработчик клика на кнопку с примерами готовых сценариев чат-бота
     *
     * @return void
     */
    function openTemplatesModal() {
      trackTransitionToReadyScenariosChatBotsLink();
      const templateModal = $uibModal.open({
        component: 'cqChatBotTemplateWrapperModal',
        resolve: {
          modalWindowParams: function () {
            return {
              templates: vm.templates,
              botType: vm.chatBotMessage.bot.type,
              isNewBot: !vm.isEditing,
            };
          },
        },
        size: 'lg modal-dialog-centered',
      });

      templateModal.result
        .then(
          (template) => {
            if (vm.isEditing) {
              const createChatBotFromTemplateUrl = $state.href(
                'app.content.messagesAjs.chatBot.create.fromTemplate',
                { chatBotTemplateId: template.id },
                { absolute: true },
              );
              $window.open(createChatBotFromTemplateUrl, '_blank');
            } else {
              vm.chatBotMessage.bot = chatBotTemplateModel.getChatBotFromTemplate(template, vm.chatBotMessage.bot.type);
              vm.chatBotMessage.name = template.name;
              vm.chatBotSnapshot = angular.copy(vm.chatBotMessage.bot);
              vm.setBotSubject.next({
                chatBot: vm.chatBotMessage.bot,
                resetViewportPosition: true,
              });
            }
          },
          () => {
            vm.trackClickOnCloseTemplateChatBotModal();
          },
        )
        .catch(() => {});
    }

    /**
     * Компонент с формой нового ангуляра по готовности отправляет callback, которым можно проверять валидность формы
     * @param cb { Function }
     */
    function onValidationCallbackReady(cb) {
      vm.firstStepOnExitCallback = cb;
    }

    /**
     * Открыть пейволл Лид-бота
     */
    function openPaywallLeadbotModal() {
      paywallService.showPaywall(vm.currentApp, vm.accessToLeadBots.denialReason);
    }

    /**
     *
     * @param {MessageEditorTriggerParams} triggerParams
     */
    function onTriggerParamsChange(triggerParams) {
      // обновляем currentMessage
      vm.chatBotMessage.triggers = triggerParams.triggers;
      vm.chatBotMessage.triggerTypes = triggerParams.triggerTypes;
      vm.chatBotMessage.isAfterTime = triggerParams.delay.isEnabled;
      vm.chatBotMessage.afterTimeValue = triggerParams.delay.value.time;
      vm.chatBotMessage.afterTimeTimeUnit = triggerParams.delay.value.unit;
      vm.chatBotMessage.sendingFilters = triggerParams.sendingFilters;
    }

    /**
     * @param {MessageEditorTriggerState} state
     */
    function onTriggerWrapperStateLoaded(state) {
      state.autoEvents$.next(vm.autoEvents);
      state.eventTypes$.next(vm.eventTypes);
      state.userProps$.next(vm.properties.userProps);
      state.currentApp$.next(vm.currentApp);
    }

    /**
     * Подготовка чат-бота к копированию
     */
    function prepareChatBotCopy() {
      vm.chatBotMessage.bot.id = null;
      vm.chatBotMessage.bot.startBranchLinkId = vm.chatBotMessage.bot.startBranchId;
      vm.chatBotMessage.bot.interruptBranchLinkId = vm.chatBotMessage.bot.interruptBranchId;
      vm.chatBotMessage.bot.startBranchId = null;
      vm.chatBotMessage.bot.interruptBranchId = null;

      for (var i = 0; i < vm.chatBotMessage.bot.branches.length; i++) {
        var branch = vm.chatBotMessage.bot.branches[i];
        branch.linkId = branch.id;
        branch.id = null;

        for (var g = 0; g < vm.chatBotMessage.bot.branches[i].actions.length; g++) {
          var action = vm.chatBotMessage.bot.branches[i].actions[g];
          action.linkId = action.id;
          delete action.id;

          if (ChatBotModel.isConnectionSourceAction(action.type)) {
            action.nextBranchLinkId = action.nextBranchId;
            action.nextBranchId = null;
          }
        }
      }
    }

    /**
     * Подготовка чат-бот сообщения к копированию
     */
    function prepareChatBotMessageCopy() {
      delete vm.chatBotMessage.id;
      vm.chatBotMessage.name = vm.chatBotMessage.name + ' (' + $translate.instant('chatBot.edit.copy') + ')';
      for (var i = 0; i < vm.chatBotMessage.parts.length; i++) {
        delete vm.chatBotMessage.parts[i].id;
      }
      delete vm.chatBotMessage.controlGroup.id;
    }

    /**
     * Сохранение чат-бота
     *
     * @param {Array} changedBranches - Список измененных веток
     * @param {Boolean} isActive - Флаг активности бота
     *
     * @return {Promise}
     */
    function saveChatBot(changedBranches, isActive) {
      return firstValueFrom(
        chatBotModel.saveLeadBot(
          vm.currentApp.id,
          vm.chatBotMessage,
          changedBranches,
          isActive,
          vm.deleteMessage,
          vm.deletedBranchesList,
        ),
      );
    }

    /**
     * Сохраняет или создает чат-бота и остается на текущей странице
     */
    function saveOrCreateAndStay(withStatus) {
      const activeStatus = withStatus ?? vm.chatBotMessage.active;
      const firstStepValidationPromise = activeStatus ? vm.firstStepOnExitCallback('save') : Promise.resolve();

      const validators = [];

      if (vm.step === 1) {
        validators.push(firstStepValidationPromise);
      } else {
        validators.push(vm.validateOnTriggerStep(vm.triggerForm), vm.isAudienceValid());
      }

      if (vm.chatBotMessage.isMessageHaveFilters) {
        if (vm.shownAudienceFilterType === AUDIENCE_FILTER_TYPE.DEFAULT) {
          vm.chatBotMessage.jinjaFilterTemplate = null;
        } else {
          vm.chatBotMessage.filters = filterAjsModel.getDefaultAnd();
        }
      } else {
        vm.chatBotMessage.jinjaFilterTemplate = null;
        vm.chatBotMessage.filters = filterAjsModel.getDefaultAnd();
      }

      Promise.all(validators)
        .then(() => {
          vm.isRequestPerforming = true;
          if (vm.isEditing) {
            // Валидация будет только у активного бота
            saveChatBot(getChangedBotBranches(), activeStatus)
              .then(saveSuccess)
              .catch(saveOrCreateError)
              .finally(saveOrCreateFinally);
          } else {
            vm.formIsSaved = false;
            $timeout();
            createChatBot(activeStatus).then(createSuccess).catch(saveOrCreateError).finally(saveOrCreateFinally);
          }
        })
        .catch(() => {});

      function saveSuccess(response) {
        vm.jinjaFilterTemplateCheckingResult = undefined;

        toastr.success($translate.instant('chatBot.toasts.botHasBeenSaved'));
        trackSaveChatBot({}, vm.chatBotMessage.sendingFilters.type);

        mergeBotFromServer(response.chatBot, response.mapIds);
        vm.chatBotSnapshot = angular.copy(vm.chatBotMessage.bot);
        vm.deletedBranchesList = [];
        vm.chatBotMessage.active = withStatus ?? vm.chatBotMessage.active;
        vm.setBotSubject.next({ chatBot: vm.chatBotMessage.bot, mapIds: response.mapIds, doNotRedrawCanvas: true });
        return $q.resolve();
      }

      function createSuccess(response) {
        trackCreateChatBot({ active: false }, vm.chatBotMessage.sendingFilters.type);

        $state.go('app.content.messagesAjs.chatBot.edit', {
          chatBotId: response.messageId,
          fromStarterGuideStep: $state.params.fromStarterGuideStep,
          step: vm.step,
        });
        toastr.success($translate.instant('chatBot.toasts.botHasBeenSaved'));

        if (isShowMoveStarterGuideModal()) {
          localStorage.removeItem(LS_MOVE_STARTER_GUIDE_MODAL);
          openMoveStarterGuideModal();
        }

        return $q.resolve();
      }

      function saveOrCreateError(response) {
        response = JSON.parse(response);

        if (response.error.error === 'ValidationError') {
          if (response.error.errorFields && response.error.errorFields.expirationTime) {
            toastr.error($translate.instant('chatBot.edit.toasts.expirationTimeError'));
            mergeBotFromServer(response.chatBot, response.mapIds);
            vm.chatBotSnapshot = angular.copy(vm.chatBotMessage.bot);
            vm.setBotSubject.next({ chatBot: vm.chatBotMessage.bot, mapIds: response.mapIds, doNotRedrawCanvas: true });
          } else if (response.error.errorFields && response.error.errorFields['jinjaFilterTemplate']) {
            vm.jinjaFilterTemplateCheckingResult = response.error.errorMessage;
          } else {
            toastr.error($translate.instant('chatBot.edit.toasts.chatBotValidationError'));
          }
        } else {
          systemError.somethingWentWrongToast.show();
        }
        return $q.reject();
      }

      function saveOrCreateFinally() {
        vm.isRequestPerforming = false;
        vm.formIsSaved = true;
      }
    }

    /**
     * Показать попап с вводом сайта на котором протестировать скрипт чата
     *
     * @param {Event} $event Событие клика на кнопку тестирования бота
     * @param {String} botMessagePartId ID парта, который в итоге должен запустить бота
     */
    function showBotTestingPopup($event, botMessagePartId) {
      if (!vm.formIsSaved) {
        return;
      }

      /**
       * Функция получения настроек тестирования бота из сторы браузера
       * @param storageKey
       * @param appKey
       * @returns {any}
       */
      function getBotTestStorage(storageKey, appKey) {
        let pagesForBotTest = localStorage.getItem(storageKey);
        if (!pagesForBotTest) {
          pagesForBotTest = {
            [appKey]: {
              url: vm.currentApp.origin,
            },
          };
          localStorage.setItem(storageKey, JSON.stringify(pagesForBotTest));
        } else {
          pagesForBotTest = JSON.parse(pagesForBotTest);
        }

        if (!Object.prototype.hasOwnProperty.call(pagesForBotTest, appKey)) {
          pagesForBotTest[appKey] = {
            url: vm.currentApp.origin,
          };
        }

        return pagesForBotTest;
      }
      const botTestStorageKey = 'pages_for_bot_test';
      const botTestAppPageKey = `app_${vm.currentApp.id}`;
      const botTestStorage = getBotTestStorage(botTestStorageKey, botTestAppPageKey);

      const inputInitialValue = botTestStorage[botTestAppPageKey].url;

      var modalInstance = $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('chatBot.edit.botTestingPopup.heading'),
              body: $translate.instant('chatBot.edit.botTestingPopup.body', { projectName: PROJECT_NAME }),
              inputLabel: '',
              inputPlaceholder: $translate.instant('chatBot.edit.botTestingPopup.inputPlaceholder'),
              inputInitialValue: inputInitialValue,
              inputErrorText: $translate.instant('chatBot.edit.botTestingPopup.inputErrorText'),
              inputPatternValidator: URL_PATTERN,
              inputPatternErrorText: $translate.instant('chatBot.edit.botTestingPopup.inputPatternErrorText'),
              confirmButtonText: $translate.instant('chatBot.edit.botTestingPopup.confirmButtonText'),
              cancelButtonText: $translate.instant('chatBot.edit.botTestingPopup.cancelButtonText'),
            };
          },
        },
      });

      modalInstance.result.then(openModalSuccess).catch(openModalError);

      function openModalSuccess(value) {
        botTestStorage[botTestAppPageKey].url = value;
        localStorage.setItem(botTestStorageKey, JSON.stringify(botTestStorage));
        const urlHasParams = String(value).includes('?');
        const paramsForOpen = ['cq_mode=message-testing', `cq_message_part=${botMessagePartId}`].join('&');
        let urlForOpen = urlHasParams ? `${value}&${paramsForOpen}` : `${value}?${paramsForOpen}`;
        const width = window.outerWidth * 0.8;
        const height = window.outerHeight * 0.8;
        if (!urlForOpen.match(/^https?:\/\//i)) {
          urlForOpen = 'https://' + urlForOpen;
        }
        const openedWindow = window.open(urlForOpen, '_blank', `width=${width},height=${height}`);
        openedWindow.moveTo(window.outerWidth * 0.1, window.outerHeight * 0.1);
      }

      function openModalError(error) {
        console.error('Error callback закрытия модального окна', error);
      }
    }

    /**
     * Отдельная функция валидации для второго шага
     * Нужна из-за того, что есть случаи, когда второй шаг не валиден, но пользователю всё равно надо дать перейти на другие шаги
     *
     * @param {form.FormController} form
     * @return {Promise}
     */
    function validateOnTriggerStep(form) {
      const isHasEmptyFilter = vm.chatBotMessage.sendingFilters.filters.find((value) => value.match === '');
      const isEnabledSendingFilters = vm.chatBotMessage.sendingFilters.type !== SENDING_FILTERS_GROUP_TYPES.NO;
      //Если не выбрал никакой триггер, добавить инпут для валидации
      if (vm.chatBotMessage.triggers.length === 0) {
        vm.addTrigger();
      }
      if (isEnabledSendingFilters && isHasEmptyFilter) {
        vm.isCollapsedDisplaySettings = true;
      }
      const areNewComponentsValid = Promise.all(
        Object.values(vm.triggerNewComponentValidators).map((touchAndValidate) => {
          return touchAndValidate();
        }),
      ).then(([...args]) => {
        return args.every((valid) => valid) ? Promise.resolve() : Promise.reject();
      });

      vm.formSubmitSource.next();

      const isTriggerValid = vm.validateTriggerFn().then((valid) => {
        return valid ? Promise.resolve() : Promise.reject();
      });

      return Promise.all([isTriggerValid, areNewComponentsValid]).catch(onError);

      function onError() {
        var promise = $q.reject;

        // HACK: отдельно валидируем (а по сути сабмитим, внутри вызывается $setSubmitted) форму удаления сообщения, чтобы показались сообщения об ошибках
        //  Если этот вызов отсюда убрать, то если выполнится условие ниже хотя бы 1 раз - ошибки показываться перестанут
        //  т.к. внутри формы есть скрытый инпут, у которого всегда $pristine === true и validationHelper вернёт результат, при котором ошибки не будут показываться
        //  поэтому приходится постоянно сабмитить форму
        isStepFormValid(vm.deleteMessageForm);

        // если сообщение редактируется, выбрано протухание в конкретную дату и пользователь не вносил изменений в настройки удаления сообщения - пропускаем его на следующий шаг
        if (
          vm.chatBotMessage.id &&
          vm.deleteMessage.deleteType === MESSAGE_DELETING_TYPES.CERTAIN_DATE &&
          vm.deleteMessageForm.$pristine
        ) {
          var allErrors = $filter('flatten')(form.$error);
          // вот тут хитрость: если среди ошибок родительской формы нету ошибок, кроме формы vm.deleteMessageForm (дочерней), то все остальные поля валидны, и можно переходить к следующему/предыдущему шагу
          var errorsOnlyInDeleteMessageForm = allErrors.every(angular.bind(null, angular.equals, vm.deleteMessageForm));

          // HACK: Если у родительской формы вызвался $setSubmitted, то он вызовется и удочерних форм, выставляя флаг $submitted в true
          //  Когда флаг $submitted выставлен, то в соответствии с validationHelper начинают показываться ошибки. А нам не нужно их показывать, если чувак не менял настройки протухания,
          //  поэтому принудительно вызываем $setPristine, чтобы выставить $submitted в false ($setPristine делает это внутри себя)
          vm.deleteMessageForm.$setPristine();

          if (errorsOnlyInDeleteMessageForm) {
            promise = $q.resolve;
          }
        }

        return promise();
      }
    }

    /**
     * !!! Возможно это тут не надо
     * Трек перехода на шаг визарда
     *
     * @param {Number} step - Шаг визарда
     */
    function trackEnterOnStep(step) {
      var params = { Шаг: step };
      var text = 'Перешел на шаг настройки чат-бота';

      if (step === 1) {
        text = 'Перешел на шаг настройки содержания чат-бота';
      }

      carrotquestHelper.track(text, params);
    }

    /**
     * Трек клика на 'Создать копию'
     */
    function trackClickCreateCopy() {
      carrotquestHelper.track(
        $translate.instant(vm.MESSAGE_PAGE_TYPES.CHAT_BOTS + '.breadcrumbs.title') + ' - кликнул "Создать копию"',
      );
    }

    /** Трек клика на 'Чат-бот - закрыл модалку с шаблонами' */
    function trackClickOnCloseTemplateChatBotModal() {
      carrotquestHelper.track('Чат-бот - закрыл модалку с шаблонами');
    }

    /** Трек клика на 'Чат-бот - Показал подсказки' */
    function trackClickShowTips() {
      if (vm.step === 1) {
        carrotquestHelper.track('Чат-бот - показал подсказки на содержании', { App: vm.currentApp.name });
      }
      if (vm.step === 2) {
        carrotquestHelper.track('Чат-бот - показал подсказки на настройках', { App: vm.currentApp.name });
      }
    }

    /**
     * Трекинг создания лид-бота
     *
     * @param {Object} params - Параметры трекинга
     * @param {SENDING_FILTERS_GROUP_TYPES} currentSendingFiltersType - Текущий тип фильтров по URL (отсутствует, включение или исключение)
     */
    function trackCreateChatBot(params, currentSendingFiltersType) {
      const parameters = {
        App: vm.currentApp.name,
        AppId: vm.currentApp.id,
        app_id: vm.currentApp.id,
        exact_url: getExactUrlFiltersLength(),
        url_containing: getUrlContainsFiltersLength(),
        url_parameters: getUrlPathEqFiltersLength(),
        Название: vm.chatBotMessage.name,
        Шаг: vm.step === 1 ? 'Содержание' : 'Проверка и запуск',
        ...params,
      };

      carrotquestHelper.track('Лид-бот - создал', parameters);

      if (currentSendingFiltersType !== SENDING_FILTERS_GROUP_TYPES.NO) {
        carrotquestHelper.track('Лид-бот - включено ограничение отправки на страницах', params);
      }
    }

    /**
     * Трекинг сохранения лид-бота
     *
     * @param {Object} params - Параметры трекинга
     * @param {SENDING_FILTERS_GROUP_TYPES} currentSendingFiltersType - Текущий тип фильтров по URL (отсутствует, включение или исключение)
     */
    function trackSaveChatBot(params, currentSendingFiltersType) {
      const parameters = {
        App: vm.currentApp.name,
        AppId: vm.currentApp.id,
        app_id: vm.currentApp.id,
        exact_url: getExactUrlFiltersLength(),
        url_containing: getUrlContainsFiltersLength(),
        url_parameters: getUrlPathEqFiltersLength(),
        Название: vm.chatBotMessage.name,
        Шаг: vm.step === 1 ? 'Содержание' : 'Проверка и запуск',
        active: vm.chatBotMessage.active,
        ...params,
      };

      carrotquestHelper.track('Лид-бот - сохранил', parameters);

      if (currentSendingFiltersType !== SENDING_FILTERS_GROUP_TYPES.NO) {
        carrotquestHelper.track('Лид-бот - включено ограничение отправки на страницах', params);
      }
    }

    /** Трекинг открытия страница создания бота */
    function trackOpenCreateBotPage() {
      const eventName = 'Лид-бот - зашел на страницу создания';

      carrotquestHelper.track(eventName);
    }

    /**
     * Трекинг открытия страницы редактирования бота
     */
    function trackOpenEditBotPage() {
      carrotquestHelper.track('Лид-бот - зашел на страницу редактирования');
    }

    /**
     * Трек смены статуса бота на "Активен"
     */
    function trackSetActiveBot() {
      carrotquestHelper.track('Лид-бот - активировал');
    }

    /**
     * Трек смены статуса бота на "Приостановлен"
     */
    function trackSetPauseBot() {
      carrotquestHelper.track('Лид-бот - приостановил');
    }

    /**
     * Трекинг перехода на страницу с готовыми сценариями чат-ботов
     *
     * @return void
     */
    function trackTransitionToReadyScenariosChatBotsLink() {
      carrotquestHelper.track('Чат-бот - перешел на готовые сценарии из меню', {
        type: vm.chatBotMessage.bot.type,
      });
    }
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { tuiPure } from '@taiga-ui/cdk';
import { Observable, startWith, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { ConversationsStoreService } from '@panel/app/pages/conversations/conversations.store';
import { ConversationTag } from '@panel/app/partials/conversation-tags/conversation-tags.component';
import { DestroyService } from '@panel/app/services';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-tags-filter',
  templateUrl: './tags-filter.component.html',
  styleUrls: ['./tags-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class TagsFilterComponent {
  /** Контрол с выбранными тегами */
  readonly selectedTagsControl: FormControl<ConversationTag[]> = new FormControl<ConversationTag[]>([], {
    nonNullable: true,
  });

  /** Контрол флага показа диалогов без тегов */
  readonly withoutTagsControl: FormControl<boolean> = new FormControl<boolean>(false, {
    nonNullable: true,
  });

  /** Контрол для поисковой фразы */
  readonly searchPhraseControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  /** Теги, которые в данный момент выбраны в модели */
  _selectedTags: ConversationTag[] = [];

  /** Флаг модели показа диалогов без тегов */
  _withoutTags: boolean = false;

  /** Флаг блокировки кнопки открытия фильтра по тегам */
  @Input()
  disabled: boolean = false;

  @Input()
  set withoutTags(value: boolean) {
    this._withoutTags = value;
    this.withoutTagsControl.setValue(value, { emitEvent: false });
  }

  @Input()
  set selectedTags(value: ConversationTag[]) {
    this._selectedTags = [...value];
    this.selectedTagsControl.setValue(value, { emitEvent: false });
  }

  private _valueChanges: Subject<{ selectedTags: ConversationTag[]; withoutTags: boolean }> = new Subject<{
    selectedTags: ConversationTag[];
    withoutTags: boolean;
  }>();
  @Output()
  valueChanges: EventEmitter<{ selectedTags: ConversationTag[]; withoutTags: boolean }> = new EventEmitter();

  @ViewChild('dropdown')
  dropdown!: NgbDropdown;

  /** Теги, отфильтрованные по поисковой фразе */
  tagsFiltered$: Observable<ConversationTag[]> = this.searchPhraseControl.valueChanges.pipe(
    debounceTime(200),
    startWith(''),
    distinctUntilChanged(),
    switchMap((searchText) =>
      this.conversationsStoreService.tags$.pipe(map((tags) => this.filterByName(tags, searchText))),
    ),
  );

  constructor(
    private readonly appService: AppService,
    private readonly carrotquestHelper: CarrotquestHelper,
    public readonly conversationsStoreService: ConversationsStoreService,
    private readonly destroy$: DestroyService,
  ) {
    this._valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.valueChanges.emit(value);
    });

    /** Если имеются выбранные теги, то нужно заблокировать чек-бокс выбора тегов без диалогов */
    this.selectedTagsControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value) => {
          value.length ? this.withoutTagsControl.disable() : this.withoutTagsControl.enable();
        }),
      )
      .subscribe();
  }

  /** Колбэк на выбор тега */
  addTag(tag: ConversationTag) {
    this.selectedTagsControl.setValue([...this.selectedTagsControl.value, tag]);
  }

  /** Применение выбранных фильтров */
  applyFilter(): void {
    this._selectedTags = this.selectedTagsControl.value;
    this._withoutTags = this.withoutTagsControl.value;

    this._valueChanges.next({
      selectedTags: this.selectedTagsControl.value,
      withoutTags: this.withoutTagsControl.value,
    });
  }

  /** Очистка фильтров */
  clearFilters(): void {
    this.selectedTagsControl.setValue([]);
    this._valueChanges.next({
      selectedTags: [],
      withoutTags: false,
    });
  }

  /** Закрытие дропдауна с фильтрами */
  closeDropdown() {
    this.dropdown.close();
  }

  get filterButtonIconClass() {
    if (this._withoutTags) {
      return 'cqi-tag-strikethrough';
    }

    if (this._selectedTags.length && !this._withoutTags) {
      return 'cqi-tag';
    }

    return 'cqi-tag-o';
  }

  /** Колбэк на клик по кнопке «Применить» */
  onClickApplyFilterButton() {
    this.applyFilter();
    this.closeDropdown();
    this.trackApplyFilter();
  }

  /** Колбэк на клик по кнопке «Очистить» */
  onClickClearFilterButton() {
    this.clearFilters();
    this.closeDropdown();
  }

  /** Колбэк на открытие/закрытие дропдауна */
  onToggleDropdown(open: boolean) {
    if (!open) {
      this.selectedTagsControl.setValue(this._selectedTags, { emitEvent: false });
      this.searchPhraseControl.setValue('', { emitEvent: false });
      this.withoutTagsControl.setValue(this._withoutTags, { emitEvent: false });
    }
  }

  /** Колбэк на активацию/деактивацию тега */
  onToggleTag(tag: ConversationTag) {
    if (this.isTagActive(tag)) {
      this.removeTag(tag);
    } else {
      this.addTag(tag);
    }
  }

  /** Выбран ли в данный момент тег */
  isTagActive(tag: ConversationTag) {
    return this.selectedTagsControl.value.some((t) => t.tag === tag.tag);
  }

  /** Удаление тега из списка выбранных */
  removeTag(tag: ConversationTag) {
    this.selectedTagsControl.setValue(this.selectedTagsControl.value.filter((t) => t.tag !== tag.tag));
  }

  /**
   * Case insensitive фильтрация списка тегов по имени
   *
   * @param tags Список тегов
   * @param filter Значение фильтра
   */
  @tuiPure
  filterByName(tags: ConversationTag[], filter: string): ConversationTag[] {
    return tags.filter((tag) => tag.tag.toLowerCase().includes(filter.toLowerCase()));
  }

  /** Трек события применения фильтров */
  trackApplyFilter() {
    const eventName = 'Диалоги - Применил  фильтр по тегам';
    const eventParams = {
      App: this.conversationsStoreService.currentApp$.getValue()!.name,
      'Права пользователя':
        this.conversationsStoreService.djangoUser$.getValue().prefs[this.appService.currentAppId].permissions,
    };

    this.carrotquestHelper.track(eventName, eventParams);
  }

  trackByFn(index: number, tag: ConversationTag) {
    return tag.tag;
  }
}

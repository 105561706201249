<div class="d-flex py-15 px-20 cursor-pointer">
  <ng-content></ng-content>

  <button
    class="btn btn-text-secondary ml-auto"
    type="button"
  >
    <i
      class="cqi-sm"
      [class.cqi-chevron-up]="isOpened"
      [class.cqi-chevron-down]="!isOpened"
    ></i>
  </button>
</div>

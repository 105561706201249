/**
 * Компонент-обёртка для отображения модального окна завершения регистрации на Angular в AngularJS
 */

(function () {
  'use strict';

  angular
    .module('myApp.modals.completionRegistrationModalWrapper')
    .component('cqCompletionRegistrationModalWrapper', cqCompletionRegistrationModalWrapper());

  function cqCompletionRegistrationModalWrapper() {
    return {
      bindings: {
        modalInstance: '=',
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./completion-registration-modal-wrapper.html'),
    };
  }
})();

(function () {
  'use strict';

  angular.module('myApp.userCard').constant('USER_EVENTS_LIST_TABS', getUserEventsListTabs());

  /**
   * Вкладки событий пользователя
   *
   * @return {Object}
   */
  function getUserEventsListTabs() {
    return {
      EVENTS_LIST: 'eventsList',
      EVENTS_GROUPING: 'eventsGrouping',
    };
  }
})();

import { INTEGRATION_TYPES } from '../../../../../app/http/integration/constants/integration.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.chatBot.leadBot.statistics').config(config);

  function config($stateProvider) {
    $stateProvider.state('app.content.messagesAjs.chatBot.statistics', {
      url: '/{chatBotId:id}?{fromStarterGuideStep}',
      bindings: {
        chatBotMessage: 'chatBotMessage',
        currentApp: 'currentApp',
        djangoUser: 'djangoUser',
        telegramIntegrations: 'telegramIntegrations',
      },
      component: 'cqChatBotStatistics',
      resolve: {
        chatBotMessage: getChatBot,
        telegramIntegrations: getTelegramIntegrations,
      },
    });

    /**
     * Получение чат-бота
     *
     * @param $state
     * @param $stateParams
     * @param currentApp
     * @param chatBotModel
     * @return {{}}
     */
    function getChatBot($state, $stateParams, currentApp, chatBotModel) {
      if ($stateParams.chatBotId) {
        const bot = firstValueFrom(chatBotModel.getLeadBot(currentApp.id, $stateParams.chatBotId));

        if (bot) {
          return bot;
        } else {
          $state.go('app.content.messagesAjs.chatBot.create', angular.extend({}, $stateParams, { chatBotId: null }));
        }
      } else {
        $state.go('app.content.messagesAjs.chatBot.create', angular.extend({}, $stateParams, { chatBotId: null }));
      }
    }

    function getTelegramIntegrations(integrationModel, currentApp) {
      return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
        return [...response[0]].filter((integration) => {
          return integration.active === true;
        });
      });
    }
  }
})();

import { BaseTexture, Renderer, RenderTexture } from '@pixi/core';
import { Sprite, Texture } from 'pixi.js';

import { BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';

let hiddenIconTexture: Record<string, BaseTexture> = {};
let lastRendererContextUID: number | null = null;

function getHiddenIconRenderTexture(graphics: any) {
  return RenderTexture.create({
    width: graphics.width,
    height: graphics.height,
    resolution: 2,
  });
}

function getHiddenIconBaseTexture(renderer: Renderer, iconCode: string): BaseTexture {
  if (hiddenIconTexture[iconCode]) {
    return hiddenIconTexture[iconCode];
  }

  const icon = PixiIconRenderService.renderIcon(iconCode, { fill: BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR });

  const renderTexture = getHiddenIconRenderTexture(icon);

  renderer.render(icon, { renderTexture });

  icon.destroy();

  hiddenIconTexture[iconCode] = renderTexture.castToBaseTexture();

  return hiddenIconTexture[iconCode];
}

export function getHiddenIcon(renderer: Renderer, iconCode: string): Sprite {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    clearCache();
  }

  lastRendererContextUID = renderer.CONTEXT_UID;

  const iconBaseTexture = getHiddenIconBaseTexture(renderer, iconCode);
  const iconTexture = new Texture(iconBaseTexture);

  return new Sprite(iconTexture);
}

function clearCache() {
  hiddenIconTexture = {};
}

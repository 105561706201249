import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AbstractTemplate } from '@panel/app/shared/types/abstract-template.type';

@Component({
  selector: 'cq-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateCardComponent {
  @Input()
  template!: AbstractTemplate;
}

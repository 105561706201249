(function () {
  'use strict';

  angular
    .module('myApp.modals.funnelEditor')
    .controller('CqFunnelEditorModalController', CqFunnelEditorModalController);

  function CqFunnelEditorModalController() {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.onSubmitFunnelEditorForm = null; // Функция создания/сохранения воронки
      vm.submitFunnelEditorForm = submitFunnelEditorForm;
    }

    /**
     * Валидация формы редактирования/копирования/создания воронки в модальном окне.
     * После успешной валидации модальное окно закрывается
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function submitFunnelEditorForm(isValid) {
      if (isValid) {
        vm.onSubmitFunnelEditorForm(isValid).then(vm.modalInstance.close);
      }
    }
  }
})();

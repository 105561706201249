(function () {
  'use strict';

  require('../auto-event-setting-base.controller');

  angular.module('myApp.trackMaster').component('cqTrackMasterUrlScroll', cqTrackMasterUrlScroll());

  function cqTrackMasterUrlScroll() {
    return {
      bindings: {
        autoEvent: '=', // Автособытие
      },
      controller: 'CqAutoEventSettingBaseController',
      controllerAs: 'vm',
      template: require('./track-master-url-scroll.html'),
    };
  }
})();

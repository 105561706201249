<ng-container *transloco="let t">
  <div
    class="cq-channel-content position-relative"
    [disableTooltip]="!isShowTooltip"
    [ngClass]="{ active: isActive, 'can-activate': canActivate }"
    [ngbTooltip]="channelNameTooltip"
    container="body"
    placement="right"
  >
    <div
      *ngIf="notReadCount"
      class="cq-channel-not-read-count position-absolute bg-primary"
    ></div>

    <cq-channel-icon
      [channel]="channel"
      lg
    ></cq-channel-icon>
  </div>

  <div
    *ngIf="notAssignedCount"
    class="cq-channel-not-assigned-count position-absolute"
  >
    <div
      class="label label-danger"
      [innerText]="notAssignedCount > 99 ? 99 + '+' : notAssignedCount"
      [ngbTooltip]="t('general.notAssignedCounter', { counter: notAssignedCount })"
      container="body"
      placement="right"
    ></div>
  </div>

  <ng-template #channelNameTooltip>
    <div [innerHTML]="channelTooltip"></div>
  </ng-template>
</ng-container>

import { translate } from '@jsverse/transloco';
import { Assets, Container, Graphics, Sprite, TextStyle, Texture } from 'pixi.js';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { TeamMember } from '@http/team-member/team-member.types';
import { getHorizontalSplitter } from '@panel/app/pages/chat-bot/content/views/actions/shared-textures';
import { BOT_GREY, BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

import { ActionStyle, BaseActionABS, MAX_ACTION_WIDTH, SimpleActionABS } from './abstract';

export class BotOperatorAction extends SimpleActionABS {
  private imageLoaded$: Subject<string> = new Subject();

  protected style!: ActionStyle;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);
    this.subscribeToImageLoad();
  }

  get apiReadyBodyJson(): EMPTY_BODY_JSON {
    return {};
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 10,
        horizontal: 10,
      },
      border: {
        size: 1,
        color: 0xc7cad1,
        radius: 10,
      },
      background: {
        color: BOT_WHITE_COLOR,
      },
    };
  }

  private getOperator(): TeamMember | null {
    const operatorId = this.form.controls.body.value;
    return this.validationExtra.teamMembers.find((teamMember) => teamMember.id === operatorId) ?? null;
  }

  render(): Graphics | null {
    const operator = this.getOperator();
    if (!operator) {
      return null;
    }

    const element = this.element ?? new Graphics();

    const textOptionsTop: Partial<TextStyle> = {
      wordWrapWidth: MAX_ACTION_WIDTH,
      align: 'center',
      fontWeight: '100',
      fill: BOT_GREY,
    };

    const operatorTranslation = translate('classes.action.canvasText.operator');
    const text1 = renderCanvasText(operatorTranslation, textOptionsTop);
    const line = getHorizontalSplitter(this.pixiApp.renderer);

    const iconSize = 25;

    const drawIconMask = () => {
      const mask = new Graphics();

      mask.name = 'operator icon mask';
      mask.beginFill(BOT_WHITE_COLOR);
      mask.drawCircle(0, 0, iconSize / 2);
      mask.endFill();

      mask.position.set(MAX_ACTION_WIDTH / 2, iconSize / 2);
      return mask;
    };

    const mask = drawIconMask();
    element.addChild(mask);

    this.renderOperatorIcon(element, mask, operator.avatar, iconSize);

    const textPadding = 30;
    text1.position.set((MAX_ACTION_WIDTH - text1.width) / 2, textPadding);
    line.position.set((MAX_ACTION_WIDTH - line.width) / 2, iconSize / 2);

    element.addChild(line);
    element.addChild(text1);

    return element;
  }

  private renderOperatorIcon(element: Graphics, mask: Container, operatorAvatar: string, size: number) {
    const renderAvatar = (texture: Texture) => {
      const icon = new Sprite(texture);

      icon.name = 'operator icon';
      icon.width = size;
      icon.height = size;
      icon.mask = mask;

      element.addChild(icon);

      icon.position.set(MAX_ACTION_WIDTH / 2 - icon.width / 2, 0);
    };
    Assets.load(operatorAvatar).then((texture) => {
      renderAvatar(texture);
      this.imageLoaded$.next(operatorAvatar);
    });
  }

  /**
   * Подписка на загрузку изображения
   * NOTE Загрузка изображений использовала хак ниже для пересчета высоты ветки, но из-за этого действия циклично перерисовывались. Подписка ниже позволяет перерисовывать действие только если изображение изменилось
   * @private
   */
  private subscribeToImageLoad() {
    this.imageLoaded$.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      // Это не очень очевидный, но все таки хак. Позволяет триггернуть перерисовку, не меняя при этом никаких значений
      this.form.controls.nextBranchLinkId.setValue(null, { emitEvent: false });
    });
  }
}

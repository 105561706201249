(function () {
  'use strict';

  angular
    .module('myApp.services.browserDetector')
    .constant('BROWSERS', getBrowsers())
    .constant('SUPPORTED_BROWSERS_REG_EXP', getSupportedBrowsersRegExp());

  /**
   * Получение списка браузеров
   *
   * @return {{CHROME: String, EDGE: String, FIREFOX: String, IE: String, OPERA: String, SAFARI: String}}
   */
  function getBrowsers() {
    return {
      CHROME: 'Chrome',
      EDGE: 'Edge',
      FIREFOX: 'Firefox',
      IE: 'IE',
      OPERA: 'Opera',
      SAFARI: 'Safari',
    };
  }

  /**
   * Получение regexp для определения поддерживает ли админка браузер пользователя
   * NOTE Этот regexp получен с помощью библиотеки https://github.com/browserslist/browserslist-useragent-regexp
   * NOTE А затем к этому regexp добавлена поддержка Yandex Browser Mobile в конец (сделанный по примеру Chrome), т.к. у него совершенно другой userAgent, который не поддерживается Browserslist
   * NOTE вот пример Mozilla/5.0 (Linux; Android 9) AppleWebKit/537.36 (KHTML, like Gecko) YaBrowser/22.7.0.1863 YaBrowser/22.7.0.1863 Yowser/2.5 Mobile Safari/537.36
   *
   * Последний раз regexp обновлялся 29.06.2022 со следующими параметрами:
   *
   * caniuse-lite version: 1.0.30001237
   * .browserslist: last 2 years, unreleased versions
   * npx browserslist-useragent-regexp --allowHigherVersions (флаг нужен для того, чтобы учесть будущие версии браузеров)
   *
   * @returns {RegExp}
   */
  function getSupportedBrowsersRegExp() {
    return /((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(14[_.]0|14[_.]([1-9]|\d{2,})|14[_.]4|14[_.]([5-9]|\d{2,})|14[_.]8|14[_.](9|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})[_.]\d+|15[_.]0|15[_.]([1-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})[_.]\d+|16[_.]0|16[_.]([1-9]|\d{2,})|(1[7-9]|[2-9]\d|\d{3,})[_.]\d+)(?:[_.]\d+)?)|(Opera\/.+Opera Mobi.+Version\/(64\.0|64\.([1-9]|\d{2,})|(6[5-9]|[7-9]\d|\d{3,})\.\d+))|(Opera\/(64\.0|64\.([1-9]|\d{2,})|(6[5-9]|[7-9]\d|\d{3,})\.\d+).+Opera Mobi)|(Opera Mobi.+Opera(?:\/|\s+)(64\.0|64\.([1-9]|\d{2,})|(6[5-9]|[7-9]\d|\d{3,})\.\d+))|((?:Chrome).*OPR\/(70\.0|70\.([1-9]|\d{2,})|(7[1-9]|[8-9]\d|\d{3,})\.\d+)\.\d+)|(SamsungBrowser\/(12\.0|12\.([1-9]|\d{2,})|(1[3-9]|[2-9]\d|\d{3,})\.\d+))|(Edge\/(84(?:\.0)?|84(?:\.([1-9]|\d{2,}))?|(8[5-9]|9\d|\d{3,})(?:\.\d+)?))|((Chromium|Chrome)\/(84\.0|84\.([1-9]|\d{2,})|(8[5-9]|9\d|\d{3,})\.\d+)(?:\.\d+)?)|(Version\/(14\.0|14\.([1-9]|\d{2,})|(1[5-9]|[2-9]\d|\d{3,})\.\d+|15\.0|15\.([1-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})\.\d+|16\.0|16\.([1-9]|\d{2,})|(1[7-9]|[2-9]\d|\d{3,})\.\d+)(?:\.\d+)? Safari\/)|(Firefox\/(78\.0|78\.([1-9]|\d{2,})|(79|[8-9]\d|\d{3,})\.\d+)\.\d+)|(Firefox\/(78\.0|78\.([1-9]|\d{2,})|(79|[8-9]\d|\d{3,})\.\d+)(pre|[ab]\d+[a-z]*)?)|((YaBrowser)\/(20\.[7-9]|20\.\d{2,}|(2[1-9]|[3-9]\d|\d{3,})\.\d+)(?:\.\d+)?)/;
  }
})();

<div
  *ngIf="leftContent || tooltip || rightContent"
  class="flex align-items-baseline margin-bottom-5"
>
  <ng-template [ngTemplateOutlet]="leftContent"></ng-template>
  <i
    class="cqi-sm cqi-question-circle padding-left-5 padding-right-5"
    *ngIf="tooltip"
    [ngbTooltip]="tooltip"
  ></i>
  <div class="margin-left-auto">
    <ng-template [ngTemplateOutlet]="rightContent"></ng-template>
  </div>
</div>
<ngb-progressbar
  [animated]="animated"
  [height]="height"
  [max]="max"
  [showValue]="showValue"
  [striped]="striped"
  [textType]="textType"
  [type]="type"
  [value]="value"
></ngb-progressbar>

<hr class="row" />

<cq-file-upload-dropzone
  [accept]="fileHelper.getAcceptableImagesExtensions()"
  [control]="fileControl"
  [multiple]="false"
>
  <h5 class="margin-bottom-5 font-weight-normal text-primary">Компонент для drag-and-drop загрузки файлов</h5>
  <div class="small text-secondary">
    Здесь можно вписать любой текст, как и в заголовке.
    <br />
    В инпуте <code>accept</code> можно указать mime-типы, который можно прикрепить.
  </div>
</cq-file-upload-dropzone>

<!-- STEPPER START -->
<hr class="row" />
<h2>Stepper (Новый визард)</h2>
<cq-stepper
  #s
  [selectedIndex]="2"
>
  <cq-step>
    <ng-template cqStepLabel>
      Шаг с <span style="color: lightseagreen"> кастомной версткой</span>
      <div class="badge badge-light-success">Новинка!</div>
    </ng-template>
    Контент шага с кастомной версткой
    <ng-template cqStepActions>
      <button
        [disabled]="s.isSelectedFirst()"
        (click)="s.stepBackward()"
        class="btn btn-outline-primary margin-right-10"
      >
        Назад
      </button>
      <button
        (click)="s.stepForward()"
        class="btn btn-primary"
      >
        Вперед
      </button>
    </ng-template>
  </cq-step>

  <cq-step
    label="Невалидный шаг"
    [validator]="stepperFalseValidator"
  >
    Невалидный шаг
    <ng-template cqStepActions>
      <button
        (click)="s.stepBackward()"
        class="btn btn-outline-primary margin-right-10"
      >
        Назад
      </button>
      <button
        (click)="s.stepForward()"
        class="btn btn-primary"
      >
        Вперед
      </button>
    </ng-template>
  </cq-step>

  <cq-step label="Выбранный шаг">
    Шаг, выбранный для открытия программно.
    <br />
    При открытиии визарда на определенном шагу, все шаги до него будут провалидированы (предыдущий шаг).
    <br />
    Новый шаг (N) становится доступен при переход на шаг N-1.
    <ng-template cqStepActions>
      <button
        (click)="s.stepBackward()"
        class="btn btn-outline-primary margin-right-10"
      >
        Назад
      </button>
      <button
        (click)="s.stepForward()"
        class="btn btn-primary"
      >
        Вперед
      </button>
    </ng-template>
  </cq-step>

  <cq-step
    label="Шаг с хуком onExit"
    [onExit]="stepperOnExit"
  >
    Контент шага с хуком onExit (задержка в 1с). Хук пишет лог в консоль.
    <ng-template cqStepActions>
      <button
        (click)="s.stepBackward()"
        class="btn btn-outline-primary margin-right-10"
      >
        Назад
      </button>
      <button
        (click)="s.stepForward()"
        class="btn btn-primary"
      >
        Вперед
      </button>
    </ng-template>
  </cq-step>

  <cq-step
    label="Шаг с валидацией"
    [validator]="stepperAsyncValidator"
  >
    Контент шага с асинхронной валидацией (с задержкой в 1с)
    <cq-switch-new
      [(ngModel)]="validationStepValue"
      goal="enable"
    >
      <ng-template #trueText>
        <span>Valid</span>
      </ng-template>
      <ng-template #falseText>
        <span>Invalid</span>
      </ng-template>
    </cq-switch-new>
    <ng-template cqStepActions>
      <button
        (click)="s.stepBackward()"
        class="btn btn-outline-primary margin-right-10"
      >
        Назад
      </button>
      <button
        (click)="s.stepForward()"
        class="btn btn-primary"
      >
        Вперед
      </button>
    </ng-template>
  </cq-step>

  <cq-step
    label="Шаг с хуком onEnter"
    [onEnter]="stepperOnEnter"
  >
    Контент шага с хуком onEnter (задержка в 1с). Хук пишет лог в консоль.
    <ng-template cqStepActions>
      <button
        (click)="s.stepBackward()"
        class="btn btn-outline-primary margin-right-10"
      >
        Назад
      </button>
      <button
        [disabled]="s.isSelectedLast()"
        (click)="s.stepForward()"
        class="btn btn-primary"
      >
        Вперед
      </button>
    </ng-template>
  </cq-step>
</cq-stepper>

<!-- STEPPER END -->

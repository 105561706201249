import { Injectable } from '@angular/core';
import moment from 'moment';

import { EventTypeModel } from '@http/event-type/event-type.model';
import { PropertyModel } from '@http/property/property.model';

/**
 * Модель события
 */
@Injectable({ providedIn: 'root' })
export class EventModel {
  constructor(private readonly eventTypeModel: EventTypeModel, private readonly propertyModel: PropertyModel) {}

  /**
   * Парсинг события
   *
   * @param {Object} event Событие
   */
  parse(event: any) {
    event.created = moment(event.created * 1000);

    if (event.props) {
      this.propertyModel.parseEventProperties(event.props);
    }

    if (event.type) {
      this.eventTypeModel.parse(event.type);
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'cq-question-pagination[collectionQuestionSize][currentQuestionNumber]',
  templateUrl: './question-pagination.component.html',
  styleUrls: ['./question-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionPaginationComponent implements OnInit {
  /** Количество вопросов */
  @Input()
  collectionQuestionSize!: number;

  /** Текущий номер вопроса */
  @Input()
  set currentQuestionNumber(value: number) {
    this._currentQuestionNumber = value;
    this.cacheNumberAnsweredQuestion(value);
  }
  get currentQuestionNumber() {
    return this._currentQuestionNumber;
  }
  private _currentQuestionNumber!: number;

  @Output()
  currentQuestionNumberChange: Subject<number> = new Subject();

  /** Массив с номерами вопросов */
  questionNumbers!: number[];

  /** Последний отвеченный вопрос */
  cacheLastAnsweredQuestionNumber: number = 0;

  ngOnInit() {
    //Создаем массив для кнопок пагинации
    this.questionNumbers = Array.from(Array(this.collectionQuestionSize), (v, i) => i + 1);
  }

  /** Запоминаем наибольший номер вопроса, на который ответили */
  cacheNumberAnsweredQuestion(numberQuestion: number) {
    if (this.cacheLastAnsweredQuestionNumber > numberQuestion) {
      return;
    }

    this.cacheLastAnsweredQuestionNumber = numberQuestion;
  }

  /** Клик ответа на вопрос */
  changeQuestionNumber(numberQuestion: number) {
    this.cacheNumberAnsweredQuestion(numberQuestion);

    this.currentQuestionNumberChange.next(numberQuestion);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { App } from '@http/app/app.model';
import { UserTag } from '@http/user/types/user.type';
import { TriggerChainListItem } from '@panel/app/http/trigger-chain/internal-types/trigger-chain.internal.type';
import { Properties } from '@http/property/property.model';

@Injectable()
export class TriggerChainListState {
  readonly activeTriggerMessagesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  readonly chainList$: BehaviorSubject<TriggerChainListItem[]> = new BehaviorSubject<TriggerChainListItem[]>([]);
  readonly currentApp$: BehaviorSubject<App | null> = new BehaviorSubject<App | null>(null);
  readonly properties$: BehaviorSubject<Properties> = new BehaviorSubject<Properties>({
    eventTypeProps: [],
    eventTypes: [],
    userProps: [],
  });
  readonly tags$: BehaviorSubject<UserTag[]> = new BehaviorSubject<UserTag[]>([]);
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import { MessageDirectoryModel } from '@http/message-directory/message-directory.model';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { ACTIONS_ON_DIRECTORY } from '@panel/app-old/shared/modals/directory-editor/directory-editor.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-directory-editor-modal',
  templateUrl: './directory-editor-modal.component.html',
  styleUrls: ['./directory-editor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class DirectoryEditorModalComponent implements OnInit {
  @Input()
  modalWindowParams: any;

  /**
   * Максимальная длинна имени папки
   *
   * @type {number}
   */
  protected MAX_DIRECTORY_NAME_LENGTH = 50;

  protected isApiRequestPerformed = false;
  protected isEdit = false;

  protected directoryForm = this.fb.group({
    directoryName: this.fb.control('', [Validators.required, Validators.maxLength(this.MAX_DIRECTORY_NAME_LENGTH)]),
  });

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly destroy$: DestroyService,
    protected readonly fb: FormBuilder,
    protected readonly messageDirectoryModel: MessageDirectoryModel,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if (this.modalWindowParams && this.modalWindowParams.directory) {
      this.isEdit = true;
      this.directoryForm.controls.directoryName.setValue(this.modalWindowParams.directory.prettyName);
    } else {
      this.trackStartCreateDirectory();
    }
  }

  /**
   * Закрытие модалки с подтверждением (снаружи вызовется successCallback)
   */
  confirm(): void {
    this.isApiRequestPerformed = true;

    if (this.isEdit) {
      this.modalWindowParams.directory = {
        ...this.modalWindowParams.directory,
        prettyName: this.directoryForm.controls.directoryName.getRawValue(),
      };

      this.messageDirectoryModel
        .edit(
          this.modalWindowParams.currentApp.id,
          this.modalWindowParams.directory,
          'MessageDirectoryAlreadyExistsError',
        )
        .pipe(
          takeUntil(this.destroy$),
          catchError((response: any) => {
            this.createOrEditDirectoryError(response);

            return of();
          }),
          finalize(() => {
            this.createOrEditDirectoryFinally();
          }),
        )
        .subscribe((directory: any) => {
          this.editDirectorySuccess(directory);
        });
    } else {
      this.messageDirectoryModel
        .create(
          this.modalWindowParams.currentApp.id,
          this.directoryForm.controls.directoryName.getRawValue()!,
          'MessageDirectoryAlreadyExistsError',
        )
        .pipe(
          takeUntil(this.destroy$),
          catchError((response: any) => {
            this.createOrEditDirectoryError(response);

            return of();
          }),
          finalize(() => {
            this.createOrEditDirectoryFinally();
          }),
        )
        .subscribe((directory: any) => {
          this.createDirectorySuccess(directory);
        });
    }
  }

  /**
   * Папка успешно отредактирована
   *
   * @param directory
   */
  editDirectorySuccess(directory: any): void {
    this.trackDirectoryChanged(directory.prettyName);

    this.toastr.success(this.translocoService.translate('modals.messageDirectoryEditor.toastr.directoryEditedSuccess'));

    this.ngbActiveModal.close({
      directory: directory,
      action: ACTIONS_ON_DIRECTORY.RENAME,
    });
  }

  /**
   * Папка успешно создана
   *
   * @param directory
   */
  createDirectorySuccess(directory: any): void {
    this.trackDirectoryCreated(directory.prettyName);

    this.toastr.success(
      this.translocoService.translate('modals.messageDirectoryEditor.toastr.directoryCreatedSuccess', {
        name: directory.prettyName,
      }),
    );

    this.ngbActiveModal.close({
      directory: directory,
      action: ACTIONS_ON_DIRECTORY.CREATE,
    });
  }

  /**
   * Ошибка создания/редактирования папки
   *
   * @param response
   */
  createOrEditDirectoryError(response: any): void {
    if (response && response.error === 'MessageDirectoryAlreadyExistsError') {
      this.directoryForm.controls.directoryName.setErrors({ apiMessageDirectoryAlreadyExists: true });
      this.cdr.detectChanges();
    }
  }

  /**
   * Финальная стадия удаления/редактирования папки
   */
  createOrEditDirectoryFinally(): void {
    this.isApiRequestPerformed = false;
  }

  /**
   * Открытие модалки удаления папки
   */
  openRemoveDirectoryModal(): void {
    let removeDirectoryModal = this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('modals.messageDirectoryEditor.removeDirectoryModal.heading'),
        body: this.translocoService.translate('modals.messageDirectoryEditor.removeDirectoryModal.bodyAutoMessage'),
        confirmButtonText: this.translocoService.translate(
          'modals.messageDirectoryEditor.removeDirectoryModal.confirmButtonText',
        ),
      })
      .open(ConfirmModalComponent);

    removeDirectoryModal.result.then(() => {
      this.messageDirectoryModel
        .remove(this.modalWindowParams.currentApp.id, this.modalWindowParams.directory.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.trackDirectoryRemoved(this.modalWindowParams.directory.prettyName);

          this.toastr.success(
            this.translocoService.translate('modals.messageDirectoryEditor.toastr.directoryRemovedSuccess', {
              name: this.modalWindowParams.directory.prettyName,
            }),
          );

          this.ngbActiveModal.close({
            directory: this.modalWindowParams.directory,
            action: ACTIONS_ON_DIRECTORY.DELETE,
          });
        });
    });
  }

  /**
   * Трек изменения папки
   *
   * @param {String} directoryName Имя папки
   */
  trackDirectoryChanged(directoryName: any): void {
    this.carrotquestHelper.track('Автосообщения - переименовал папку', { Название: directoryName });
  }

  /**
   * Трек создания папки
   *
   * @param {String} directoryName Имя папки
   */
  trackDirectoryCreated(directoryName: any): void {
    this.carrotquestHelper.track('Автосообщения - создал новую папку', { Название: directoryName });
  }

  /**
   * Трек удаления папки
   *
   * @param {String} directoryName Имя папки
   */
  trackDirectoryRemoved(directoryName: any): void {
    this.carrotquestHelper.track('Автосообщения - удалил папку', { Название: directoryName });
  }

  /**
   * Событие создания папки
   */
  trackStartCreateDirectory(): void {
    this.carrotquestHelper.track('Автосообщения - начал создавать новую папку');
  }
}

import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { isInteger } from 'lodash-es';

import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';

/**
 * Компонент для работы с аддоном фичи в сравнении тарифных планов
 */

@Component({
  selector: 'cq-feature-addon[addon][rowCount]',
  templateUrl: './feature-addon.component.html',
  styleUrls: ['./feature-addon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureAddonComponent implements AfterViewInit {
  /** Аддон, который необходимо подключить для доступа к фиче  */
  @Input()
  addon: BILLING_ADD_ONS = BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS;

  /** Количество строк в таблице, на которое должна растягиваться карточка с аддоном*/
  private _rowCount: number = 2;

  @Input()
  set rowCount(rowCount: number) {
    if (!isInteger(rowCount)) {
      throw Error('@Input rowCount is not integer');
    }

    if (rowCount === 0) {
      throw Error('@Input rowCount can not be 0');
    }

    if (rowCount === 1) {
      this.componentHeight = 'inherit';
    }

    /*
      Высота компонента не должна влиять на отображение карточки аддона,
      если карточку аддона необходимо растянуть больше чем на 1 строку таблицы сравнения тарифных планов
    */
    if (rowCount > 1) {
      this.componentHeight = '0px';
    }

    this._rowCount = rowCount;
  }

  get rowCount(): number {
    return this._rowCount;
  }

  /** Атрибут height текущего компонента  */
  @HostBinding('style.height')
  componentHeight: string = 'inherit';

  /** Ссылка на карточку аддона  */
  @ViewChild('addonCardRef', { static: false })
  addonCardRef: ElementRef | undefined = undefined;

  /** Компенсация высоты карточки аддона, которая ровна padding у ячейки в таблице */
  CARD_HEIGHT_CORRECTION_PX = 20;

  /** Компенсация скролла до аддона, которая ровна margin между аддонами */
  SCROLL_CORRECTION_PX = 50;

  constructor(@Inject(DOCUMENT) private readonly documentRef: Document) {}

  ngAfterViewInit(): void {
    if (!this.addonCardRef) {
      throw Error('addonCardRef is undefined');
    }

    if (this.rowCount > 1) {
      // Карточка адона должна становиться position-absolute, чтобы иметь возможность растянуться на всю высоту строк
      this.addonCardRef.nativeElement.classList.add('position-absolute', 'margin-top-20-neg');
      this.addonCardRef.nativeElement.style.height = this.getHeightCard(this.addonCardRef);
    }
  }

  /**
   * Получение высоты карточки аддона
   *
   * @param cardRef - Ссылка на node карточки аддона
   */
  getHeightCard(cardRef: ElementRef): string {
    let parentTRNode = cardRef.nativeElement.closest('tr');
    if (!parentTRNode) {
      throw new Error('tr-node is undefined');
    }

    let heightParentTRNode = parentTRNode.clientHeight;

    for (let i = 0; i < this.rowCount - 1; i++) {
      let nextElementSibling = parentTRNode.nextElementSibling;
      if (nextElementSibling) {
        heightParentTRNode = heightParentTRNode + nextElementSibling.clientHeight;
      }
    }

    return `${heightParentTRNode - this.CARD_HEIGHT_CORRECTION_PX}px`;
  }

  /**
   * Скролл до аддона
   *
   * @param addon - Аддон, до которого необходимо проскролить
   */
  scrollToAddon(addon: BILLING_ADD_ONS): void {
    let scrollableNode =
      this.documentRef.getElementsByClassName('modal')[0] ?? this.documentRef.getElementById('content');

    if (!scrollableNode) {
      throw Error('scrollableNode is undefined');
    }

    let addonNode = this.documentRef.querySelector(`[id^="${addon}"]`);

    if (!addonNode || !(addonNode instanceof HTMLElement)) {
      throw Error('addonNode is undefined');
    }

    scrollableNode.scrollTo({
      top: addonNode.offsetTop - addonNode.clientHeight - this.SCROLL_CORRECTION_PX,
      behavior: 'smooth',
    });
  }
}

(function () {
  'use strict';

  angular.module('myApp.privacySettings', []);

  require('./privacy-settings.component');
  require('./general-privacy-policy-settings/general-privacy-policy-settings.component');
  require('./chat-data-collection-settings/chat-data-collection-settings.component');
  require('./chatbot-and-popup-data-collection-settings/chatbot-and-popup-data-collection-settings.component');
  require('./cookie-banner-settings/cookie-banner-settings.component');
  require('./double-opt-in-settings/double-opt-in-settings.component');
  require('./double-opt-in-settings/email-preview/double-opt-in-email-preview.component');
  require('./double-opt-in-settings/subscription-confirm-preview/double-opt-in-subscription-confirm-preview.component');
  require('./popup-data-collection-settings/popup-data-collection-settings.component');
})();

<ng-container *transloco="let t; read: 'eventPropsEditorComponent'">
  <ng-container *ngFor="let prop of control.controls; let i = index">
    <small class="text-muted mb-10 d-block">{{ t('attrLabel', { idx: i + 1 }) }}</small>
    <cq-event-prop-editor
      class="mb-10"
      [formControl]="prop"
      (delete)="deleteProp(i)"
    ></cq-event-prop-editor>
  </ng-container>

  <small class="text-secondary d-block mb-5">{{ t('description.warn') }}</small>
  <small
    class="text-secondary d-block"
    [innerHTML]="t('description.info')"
  ></small>

  <button
    class="btn btn-block btn-secondary mt-15"
    (click)="addHeader()"
    type="button"
  >
    <i class="btn-icon-left cqi-sm cqi-plus"></i>
    <span>{{ t('addAttrButton') }}</span>
  </button>
</ng-container>

(function () {
  'use strict';

  angular.module('myApp.services.pageTitleHelper').service('pageTitleHelper', pageTitleHelper);

  /**
   * FIXME: быстро написанный функционал, в будущем нужно сделать круче (динамическая смена title в зависимости от текущей страницы, цикличные title для уведомления о новых сообщениях и т.д.)
   */
  function pageTitleHelper($document) {
    return {
      set: set,
    };

    /**
     * Установка title вкладки
     *
     * @param {String} title Строка, которая будет записана в title
     */
    function set(title) {
      $document[0].title = title;
    }
  }
})();

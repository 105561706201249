import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';

/**
 * Юзкейс использования аппа, который выбирают во время реги
 */
export enum APP_USAGE_GOAL {
  TALK_TO_CUSTOMERS = 'talk_to_customers',
  RAISE_PURCHASE_CONVERSION = 'raise_purchase_conversion',
  RAISE_LEAD_CONVERSION = 'raise_lead_conversion',
  SUPPORT_CUSTOMERS = 'support_customers',
  OTHER = 'other',
}

/**
 * Список юзкейсов аппа, выбирают во время реги
 */
export const APP_USAGE_GOAL_LIST = [
  APP_USAGE_GOAL.TALK_TO_CUSTOMERS,
  APP_USAGE_GOAL.RAISE_PURCHASE_CONVERSION,
  APP_USAGE_GOAL.RAISE_LEAD_CONVERSION,
  APP_USAGE_GOAL.SUPPORT_CUSTOMERS,
  APP_USAGE_GOAL.OTHER,
];

/** Данные о стартергайде, которые приходят с бэка */
export type StarterGuide = {
  app: string;
  appUsageGoal: APP_USAGE_GOAL;
  [STARTER_GUIDE_STEPS.ABOUT_SERVICE]: boolean;
  [STARTER_GUIDE_STEPS.CHAT_SETTINGS]: boolean;
  [STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE]: boolean;
  [STARTER_GUIDE_STEPS.LEADS_COLLECTION]: boolean;
  [STARTER_GUIDE_STEPS.GET_MORE]: boolean;
  [STARTER_GUIDE_STEPS.INSTALLATION_SERVICE]: boolean;
  [STARTER_GUIDE_STEPS.SOCIAL_INTEGRATIONS]: boolean;
  [STARTER_GUIDE_STEPS.WARM_UP]: boolean;
  [STARTER_GUIDE_STEPS.WORK_TIME]: boolean;
  [STARTER_GUIDE_STEPS.WRITE_SELF]: boolean;
  task: null;
};

/** Описание шага стартергайда */
export type StarterGuideStep = {
  /** Название шага */
  name: STARTER_GUIDE_STEPS;
  /** Пройденность шага */
  passed: boolean;
};

/** Данные стартергайда, которые уходят на бэк */
export type ApiStarterGuideSetStepIsMadeRequest = {
  [key in STARTER_GUIDE_STEPS]?: boolean;
};

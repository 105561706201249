import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[cqFileAsDataUrl]',
})
export class FileAsDataUrlDirective {
  @Input('cqFileAsDataUrl') set model(value: string) {
    // Если значение изменилось, обновляем представление
    if (value === null || value === '') {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
    }
  }
  @Output('cqFileAsDataUrlChange') changeEmitter = new EventEmitter<string>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('change', ['$event.target.files'])
  onChange(files: FileList): void {
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        // Вызываем событие с новым Data URL
        this.changeEmitter.emit(e.target?.result as string);
      };

      reader.readAsDataURL(file);
    } else {
      // Если файл не выбран, отправляем пустое значение
      this.changeEmitter.emit('');
    }
  }
}

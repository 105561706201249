/**
 * Тип свойства в Clickhouse
 */
export enum CLICKHOUSE_PROPERTY_TYPE {
  STR = 'str',
  DT = 'dt',
  BOOL = 'bool',
  INT = 'int',
  LIST = 'list',
}

/**
 * Операции, которые можно производить со свойствами в Clickhouse
 */
export enum CLICKHOUSE_PROPERTY_OPERATIONS {
  EQ = 'eq', // равно
  NOT_EQ = 'not_eq', // не равно
  CONTAINS = 'contains', // содержит
  NOT_CONTAINS = 'not_contains', // не содержит
  KNOWN = 'known', // определено
  KNOWN_NOT_EQ = 'known_not_eq', // определено и не равно
  KNOWN_NOT_CONTAINS = 'known_not_contains', // определено и не содержит
  UNKNOWN = 'unknown', // не определено
}

/**
 * Массив операций над свойствами в Clickhouse, которым можно задавать значения
 */
export const CLICKHOUSE_PROPERTY_OPERATIONS_WITH_VALUES = [
  CLICKHOUSE_PROPERTY_OPERATIONS.EQ,
  CLICKHOUSE_PROPERTY_OPERATIONS.NOT_EQ,
  CLICKHOUSE_PROPERTY_OPERATIONS.CONTAINS,
  CLICKHOUSE_PROPERTY_OPERATIONS.NOT_CONTAINS,
  CLICKHOUSE_PROPERTY_OPERATIONS.KNOWN_NOT_EQ,
  CLICKHOUSE_PROPERTY_OPERATIONS.KNOWN_NOT_CONTAINS,
];

/**
 * Массив операций над свойствами в Clickhouse
 */
export const CLICKHOUSE_PROPERTY_OPERATIONS_ARRAY = [
  CLICKHOUSE_PROPERTY_OPERATIONS.EQ,
  CLICKHOUSE_PROPERTY_OPERATIONS.NOT_EQ,
  CLICKHOUSE_PROPERTY_OPERATIONS.CONTAINS,
  CLICKHOUSE_PROPERTY_OPERATIONS.NOT_CONTAINS,
  CLICKHOUSE_PROPERTY_OPERATIONS.KNOWN,
  CLICKHOUSE_PROPERTY_OPERATIONS.KNOWN_NOT_EQ,
  CLICKHOUSE_PROPERTY_OPERATIONS.KNOWN_NOT_CONTAINS,
  CLICKHOUSE_PROPERTY_OPERATIONS.UNKNOWN,
];

/**
 * TODO ANGULAR_TS ДОБАВИТЬ ОПИСАНИЕ
 */
export const CLICKHOUSE_SYSTEM_PROPERTIES_BY_EVENT_TYPE = {
  $cart_added: ['$name', '$amount'],
  $message_clicked: ['$message_id', '$message_name', '$type'],
  $message_read: ['$message_id', '$message_name', '$type'],
  $message_replied: ['$message_id', '$message_name', '$type'],
  $message_sended: ['$message_id', '$message_name', '$type'],
  $order_completed: ['$order_id_human', '$order_amount'],
  $product_viewed: ['$name', '$amount'],
  $session_start: ['$referrer_domain', '$browser', '$os', '$device', '$resolution'],
  $utm_hit: ['$utm_source', '$utm_campaign', '$utm_medium', '$utm_content', '$utm_term'],
};

/**
 * Системные свойства пользователей в Clickhouse
 */
export const CLICKHOUSE_USER_SYSTEM_PROPERTIES = [
  '$conversations_emails',
  '$conversations_chats',
  '$conversations_chatbots',
  '$conversations_popups',
  '$sessions',
  '$initial_referrer_domain',
  '$last_session_referrer',
  '$device_type',
  '$initial_utm_campaign',
  '$initial_utm_source',
  '$initial_utm_medium',
  '$initial_utm_content',
  '$initial_utm_term',
  '$last_utm_campaign',
  '$last_utm_source',
  '$last_utm_medium',
  '$last_utm_content',
  '$last_utm_term',
  '$country',
  '$region',
  '$city',
  '$cart_amount',
  '$last_payment',
  '$revenue',
  '$profit',
];

/**
 * Системные свойства пользователя, которые можно редактировать
 */
export const EDITABLE_USER_PROPERTIES = [
  '$email',
  '$name',
  '$phone',
  // NOTE Эти свойства закомменчены, т.к. не вписываются в логику, что пользовател можно записывать в них данные из блочных поп-апов
  /*'$cart_amount',
  '$cart_items',
  '$comment',
  '$discount',
  '$email_unsubscribed',
  '$google_client_ids',
  '$group',
  '$initial_referrer',
  '$initial_referrer_domain',
  '$initial_utm_campaign',
  '$initial_utm_content',
  '$initial_utm_medium',
  '$initial_utm_source',
  '$initial_utm_term',
  '$last_utm_campaign',
  '$last_utm_content',
  '$last_utm_medium',
  '$last_utm_source',
  '$last_utm_term',
  '$last_order_status',
  '$last_payment',
  '$last_session_referrer',
  '$lead_cost',
  '$ordered_categories',
  '$ordered_items',
  '$orders_count',
  '$profit',
  '$push_ids',
  '$push_unsubscribed',
  '$revenue',
  '$roistat_visit',
  '$social_facebook',
  '$social_foursquare',
  '$social_googleplus',
  '$social_pinterest',
  '$social_skype',
  '$social_twitter',
  '$social_vk',
  '$viewed_categories',
  '$viewed_products'*/
];

/**
 * Имена псевдо групп операций со свойствами
 */
export enum ELASTICSEARCH_OPERATION_PSEUDO_TYPES {
  WEB_PUSH = 'webPush',
}

/**
 * Имена групп операций со свойствами
 */
export enum ELASTICSEARCH_OPERATION_TYPES {
  GENERAL = 'general',
  NUMBER = 'number',
  STRING = 'string',
  ARRAY = 'array',
  DATE = 'date',
  BOOLEAN = 'boolean',
}

/**
 * Операции со свойствами, которые можно производить в Elasticsearch
 */
export enum ELASTICSEARCH_PROPERTY_OPERATIONS {
  // общие операции
  KNOWN = 'known', // определено
  UNKNOWN = 'unknown', // неопределено
  // операции с числами
  NUMBER_GT = 'gt', // больше, чем
  NUMBER_LT = 'lt', // меньше, чем
  NUMBER_GT_OR_UNKNOWN = 'gt_or_unknown', // больше или не определено
  NUMBER_LT_OR_UNKNOWN = 'lt_or_unknown', // меньше или не определено
  NUMBER_EQ = 'eq', // равно
  NUMBER_NOT_EQ = 'neq', // не равно
  NUMBER_RANGE = 'range', // в диапазоне
  // операции со строками
  STR_CONTAINS = 'str_contains', // содержит
  STR_NOT_CONTAINS = 'str_notcontains', // не содержит
  STR_EQ = 'str_eq', // равно
  STR_NOT_EQ = 'str_neq', // не равно
  // операции с массивами
  LIST_CONTAINS = 'lcontains', // содержит
  LIST_NOT_CONTAINS = 'lnotcontains', // не содержит
  LIST_CONTAINS_ANY = 'lcontains_any', // содержит любой
  LIST_NOT_CONTAINS_ANY = 'lnotcontains_any', // не содержит любой
  LIST_CONTAINS_ALL = 'lcontains_all', // содержит всё
  LIST_NOT_CONTAINS_ALL = 'lnotcontains_all', // не содержит всё
  // операции с датами
  DAYS_MORE_THAN = 'daysmore', // больше, чем
  DAYS_LESS_THAN = 'daysless', // меньше, чем
  DAYS_MORE_THAN_OR_UNKNOWN = 'daysmore_or_unknown', // больше или не определено
  DAYS_LESS_THAN_OR_UNKNOWN = 'daysless_or_unknown', // меньше или не определено
  // операции с boolean
  BOOLEAN_TRUE = 'bool_true', // да
  BOOLEAN_FALSE = 'bool_false', // нет
}

/**
 * Сгруппированные операции со свойствами по псевдо типу
 */
export const ELASTICSEARCH_PROPERTY_OPERATIONS_BY_PSEUDO_TYPE = {
  [ELASTICSEARCH_OPERATION_PSEUDO_TYPES.WEB_PUSH]: [
    ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN,
    ELASTICSEARCH_PROPERTY_OPERATIONS.UNKNOWN,
    ELASTICSEARCH_PROPERTY_OPERATIONS.BOOLEAN_FALSE,
    ELASTICSEARCH_PROPERTY_OPERATIONS.BOOLEAN_TRUE,
  ],
};

/**
 * Сгруппированные операции со свойствами по типу
 */
export const ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE: any = {
  [ELASTICSEARCH_OPERATION_TYPES.GENERAL]: [
    ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN,
    ELASTICSEARCH_PROPERTY_OPERATIONS.UNKNOWN,
  ],
  [ELASTICSEARCH_OPERATION_TYPES.STRING]: [
    ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS,
    ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_CONTAINS,
    ELASTICSEARCH_PROPERTY_OPERATIONS.STR_EQ,
    ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_EQ,
  ],
  [ELASTICSEARCH_OPERATION_TYPES.NUMBER]: [
    ELASTICSEARCH_PROPERTY_OPERATIONS.NUMBER_GT,
    ELASTICSEARCH_PROPERTY_OPERATIONS.NUMBER_LT,
    ELASTICSEARCH_PROPERTY_OPERATIONS.NUMBER_GT_OR_UNKNOWN,
    ELASTICSEARCH_PROPERTY_OPERATIONS.NUMBER_LT_OR_UNKNOWN,
    ELASTICSEARCH_PROPERTY_OPERATIONS.NUMBER_EQ,
    ELASTICSEARCH_PROPERTY_OPERATIONS.NUMBER_NOT_EQ,
    ELASTICSEARCH_PROPERTY_OPERATIONS.NUMBER_RANGE,
  ],
  [ELASTICSEARCH_OPERATION_TYPES.ARRAY]: [
    ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_CONTAINS,
    ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_NOT_CONTAINS,
    ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_CONTAINS_ANY,
    ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_NOT_CONTAINS_ANY,
    ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_CONTAINS_ALL,
    ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_NOT_CONTAINS_ALL,
  ],
  [ELASTICSEARCH_OPERATION_TYPES.DATE]: [
    ELASTICSEARCH_PROPERTY_OPERATIONS.DAYS_MORE_THAN,
    ELASTICSEARCH_PROPERTY_OPERATIONS.DAYS_LESS_THAN,
    ELASTICSEARCH_PROPERTY_OPERATIONS.DAYS_MORE_THAN_OR_UNKNOWN,
    ELASTICSEARCH_PROPERTY_OPERATIONS.DAYS_LESS_THAN_OR_UNKNOWN,
  ],
  [ELASTICSEARCH_OPERATION_TYPES.BOOLEAN]: [
    ELASTICSEARCH_PROPERTY_OPERATIONS.BOOLEAN_TRUE,
    ELASTICSEARCH_PROPERTY_OPERATIONS.BOOLEAN_FALSE,
  ],
};

/**
 * TODO ANGULAR_TS ДОБАВИТЬ ОПИСАНИЕ
 * NOTE: полного списка свойств системных событий не существует, не понятно как актуализировать этот список
 */
export const EVENT_SYSTEM_PROPERTIES = [
  '$amount',
  '$body',
  '$browser',
  '$call_records',
  '$chat_bot_id',
  '$comment',
  '$conversation_id',
  '$device',
  '$device_guid',
  '$device_type',
  '$email',
  '$event_source_id',
  '$event_source_type',
  '$img',
  '$ip',
  '$items',
  '$message_id',
  '$message_name',
  '$message_part_id',
  '$message_part_name',
  '$message_part_recipient_type',
  '$message_type',
  '$name',
  '$new_value',
  '$old_value',
  '$order_amount',
  '$order_id',
  '$order_id_human',
  '$os',
  '$referrer',
  '$referrer_domain',
  '$resolution',
  '$sending_id',
  '$type',
  '$url',
  '$utm_campaign',
  '$utm_content',
  '$utm_medium',
  '$utm_source',
  '$utm_term',
];

/**
 * Системные свойства пользователя
 */
export const USER_SYSTEM_PROPERTIES = [
  '$active_device_guid',
  '$active_device_push_subscribed',
  '$avatar',
  '$banned',
  '$bitrix24_id',
  '$bitrix24_responsible',
  '$browser',
  '$cart_amount',
  '$cart_items',
  '$city',
  '$conversations_chats',
  '$conversations_chatbots',
  '$conversations_emails',
  '$conversations_popups',
  '$conversations_pushes',
  '$country',
  '$device',
  '$device_type',
  '$discount',
  '$email',
  '$email_status',
  '$google_client_ids',
  '$group',
  '$initial_referrer',
  '$initial_referrer_domain',
  '$initial_utm_campaign',
  '$initial_utm_content',
  '$initial_utm_medium',
  '$initial_utm_source',
  '$initial_utm_term',
  '$ip',
  '$last_contacted',
  '$last_order_status',
  '$last_payment',
  '$last_reply',
  '$last_seen',
  '$last_session_referrer',
  '$last_utm_campaign',
  '$last_utm_content',
  '$last_utm_medium',
  '$last_utm_source',
  '$last_utm_term',
  '$latitude',
  '$lead_cost',
  '$longitude',
  '$name',
  '$name_placeholder',
  '$ordered_categories',
  '$ordered_items',
  '$orders_count',
  '$os',
  '$phone',
  '$profit',
  '$push_unsubscribed',
  '$region',
  '$retailcrm_id',
  '$revenue',
  '$roistat_visit',
  '$score',
  '$sdk_push_subscribed',
  '$sdk_push_notifications_subscribed',
  '$sdk_push_campaigns_subscribed',
  '$sessions',
  '$social_facebook',
  '$social_instagram',
  '$social_foursquare',
  '$social_googleplus',
  '$social_pinterest',
  '$social_skype',
  '$social_telegram',
  '$social_twitter',
  '$social_vk',
  '$social_whatsapp',
  '$social_telegram_id',
  '$tags',
  '$user_id',
  '$viewed_categories',
  '$viewed_products',
  '$phone_storage_is_allowed',
  '$email_storage_is_allowed',
  '$data_processing_policy_is_allowed',
  '$telegram_subscriptions',
] as const;

/** Тип системного свойства пользователя */
export type UserSystemProperty = typeof USER_SYSTEM_PROPERTIES[number];

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbModal, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLetModule } from '@taiga-ui/cdk';
import { MomentModule } from 'ngx-moment';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { OverlayScrollbarHelperService } from '@panel/app/pages/chat-bot/content/branch-editor/services/overlay-scrollbar-helper.service';
import { TopUpBalanceModalModule } from '@panel/app/pages/subscription/general/signed/balance/top-up-balance-modal/top-up-balance-modal.module';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { DestroyService } from '@panel/app/services';
import { SharedModule } from '@panel/app/shared/shared.module';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

import { BalanceComponent } from './balance.component';

@NgModule({
  declarations: [BalanceComponent],
  exports: [BalanceComponent],
  imports: [
    CommonModule,
    MomentModule,
    NgbPopoverModule,
    SharedModule,
    TopUpBalanceModalModule,
    TranslocoModule,
    TuiLetModule,
    ReactiveFormsModule,
  ],
  providers: [
    CarrotquestHelperProvider,
    DestroyService,
    NgbModal,
    OverlayScrollbarHelperService,
    SubscriptionStore,
    L10nHelperService,
  ],
})
export class BalanceModule {}

import { Injectable } from '@angular/core';

import { ConversationPartAiContentModalComponent } from '@panel/app/partials/modals/conversation-part-ai-content-modal/conversation-part-ai-content-modal.component';
import {
  CONVERSATION_PART_AI_CONTENT_MODAL_DATA_TOKEN,
  ConversationPartAiContentModalData,
} from '@panel/app/partials/modals/conversation-part-ai-content-modal/conversation-part-ai-content-modal.token';
import { ModalHelperService } from '@panel/app/services';

/** Сервис для управления модалками диалогов */
@Injectable({
  providedIn: 'root',
})
export class ConversationModalsService {
  constructor(private readonly modalHelperService: ModalHelperService) {}

  /**
   * Открытие модалки с просмотра контента, который использовал AI-бот для ответа пользователю
   *
   * @param modalData
   */
  openAIContentModal(modalData: ConversationPartAiContentModalData) {
    this.modalHelperService
      .provide(CONVERSATION_PART_AI_CONTENT_MODAL_DATA_TOKEN, {
        ...modalData,
      })
      .open(ConversationPartAiContentModalComponent, {
        size: 'right',
        modalDialogClass: 'full-width',
      });
  }
}

import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { INTEGRATION_TYPES } from '../../../app/http/integration/constants/integration.constants';

/**
 * Роуты для работы с триггерными цепочками
 */
(function () {
  'use strict';

  angular.module('myApp.triggerChains').config(config).run(run);

  function config($stateProvider) {
    $stateProvider.state('app.content.triggerChainsAjs', {
      url: '/trigger-chains/statistics/{id:id}/{messageId:id}',
      component: 'cqTriggerChainDetailedStatistics',
      data: {
        roles: [TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
      },
      bindings: {
        billingInfo: 'billingInfo',
        chain: 'chain',
        currentApp: 'currentApp',
        currentMessage: 'currentMessage',
        djangoUser: 'djangoUser',
        pushSettings: 'pushSettings',
        properties: 'properties',
        tags: 'tags',
        telegramIntegrations: 'telegramIntegrations',
      },
      resolve: {
        chain: getChain,
        currentMessage: getMessage,
        properties: getProperties,
        pushSettings: getPushSettings,
        tags: getTags,
        telegramIntegrations: getTelegramIntegrations,
      },
    });
  }

  function run($transitions) {
    $transitions.onSuccess({ to: 'app.content.triggerChainsAjs' }, (transition) => {
      transition
        .injector()
        .getAsync('triggerChainTrackService')
        .then((triggerChainTrackService) => {
          triggerChainTrackService.trackTransitionOnChainMessageStatistics();
        });
    });
  }

  /**
   * Получает цепочку
   *
   * @param $stateParams
   * @param {TriggerChainModel} triggerChainModel
   * @param {Properties} properties
   * @param {UserTag[]} tags
   */
  function getChain($stateParams, triggerChainModel, properties, tags, teamMembers) {
    let { id } = $stateParams;

    return firstValueFrom(triggerChainModel.get(id, { properties, userTags: tags }));
  }

  function getTelegramIntegrations(integrationModel, currentApp) {
    return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
      return [...response[0]].filter((integration) => {
        return integration.active === true;
      });
    });
  }

  /**
   * Получение автосообщения по messageId
   *
   * @param $stateParams
   * @param $transition$
   * @param messageModel
   * @returns {Promise}
   */
  function getMessage($stateParams, $transition$, messageModel) {
    let messagePartModel = $transition$.injector().get('messagePartModel');

    const { messageId } = $stateParams;
    return firstValueFrom(messageModel.getAutoMessage(messageId, true, true, true, false, true)).then(getMessageParts);

    function getMessageParts(currentMessage) {
      // флаг includeClosed выставляется в false, т.к редактировать и сохранять можно только открытые варианты сообщения
      return firstValueFrom(messagePartModel.getMessageTestGroupParts(currentMessage.activeTestGroup.id, false)).then(
        getMessageTestGroupPartsSuccess,
      );

      function getMessageTestGroupPartsSuccess(parts) {
        currentMessage.activeTestGroup.parts = parts;
        return currentMessage;
      }
    }
  }

  function getProperties(propertyModel, currentApp) {
    return firstValueFrom(propertyModel.getList(currentApp.id));
  }

  /**
   * Получение настроек пушей
   *
   * @param $transition$
   * @param currentApp
   * @returns {Promise}
   */
  function getPushSettings($transition$, currentApp) {
    let pushSettingsModel = $transition$.injector().get('pushSettingsModel');

    return firstValueFrom(pushSettingsModel.get(currentApp.id));
  }

  function getTags(currentApp, tagModel) {
    return firstValueFrom(tagModel.getList(currentApp.id));
  }
})();

<ng-container *transloco="let t; read: 'pricingPlanDescriptionComponent'">
  <div class="card-group">
    <div class="card border-top-0 border-bottom-0">
      <div class="card-body pt-0">
        <div class="margin-between-rows-10 text-secondary">
          <ng-container *tuiLet="this.pricingStore.paymentPeriod | async as paymentPeriod">
            <div
              *ngIf="paymentPeriod && getDiscountValue(paymentPeriod) > 0"
              class="d-flex align-items-baseline font-weight-bold text-success"
            >
              <i class="cqi-sm cqi-tag"></i>
              <span class="ml-10">{{
                t('info.discount', {
                  discountValue: getDiscountValue(paymentPeriod) | percent
                })
              }}</span>
            </div>
          </ng-container>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-users-o"></i>
            <span
              class="ml-10"
              [innerHTML]="t('info.operators')"
            ></span>
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-tachometer"></i>
            <span
              class="ml-10"
              [innerHTML]="t('info.limit', {projectName})"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card border-top-0 border-bottom-0">
      <div class="card-body pt-0 margin-between-rows-20">
        <div class="margin-between-rows-10">
          <div class="d-flex align-items-baseline">
            <span class="num-container text-primary mr-5">{{ t('availability.conversation.chatBot') }}</span>
            <span
              class="custom-dashed"
              [ngbTooltip]="
                t('tooltips.chatBot', {
                  amount: t('availability.conversation.chatBot')
                })
              "
              >{{ t('info.chatBot', { amount: t('availability.conversation.chatBot') }) }}</span
            >
          </div>
          <div class="d-flex align-items-baseline">
            <span class="num-container text-primary mr-5">{{ t('availability.conversation.triggeredMessage') }}</span>
            <span
              class="custom-dashed"
              [ngbTooltip]="
                t('tooltips.triggeredMessage', {
                  amount: t('availability.conversation.triggeredMessage')
                })
              "
              >{{ t('info.triggeredMessage', { amount: t('availability.conversation.triggeredMessage') }) }}</span
            >
          </div>
        </div>
        <div class="margin-between-rows-10">
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            <div>
              {{ t('availability.conversation.onlineChat') }}
            </div>
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.conversation.teamSpace') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.conversation.autoAnswers') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.conversation.collectUserData') }}
          </div>
        </div>
      </div>
    </div>
    <div class="card border-top-0 border-bottom-0">
      <div class="card-body pt-0 margin-between-rows-20">
        <div class="margin-between-rows-10">
          <div class="d-flex align-items-baseline">
            <span class="num-container text-primary mr-5">{{ t('availability.support.chatBot') }}</span>
            <span
              class="custom-dashed"
              [ngbTooltip]="
                t('tooltips.chatBot', {
                  amount: t('availability.support.chatBot')
                })
              "
              >{{ t('info.chatBot', { amount: t('availability.support.chatBot') }) }}</span
            >
          </div>
          <div class="d-flex align-items-baseline">
            <span class="num-container text-primary mr-5">{{ t('availability.support.triggeredMessage') }}</span>
            <span
              class="custom-dashed"
              [ngbTooltip]="
                t('tooltips.triggeredMessage', {
                  amount: t('availability.support.triggeredMessage')
                })
              "
              >{{ t('info.triggeredMessage', { amount: t('availability.support.triggeredMessage') }) }}</span
            >
          </div>
        </div>
        <div class="margin-between-rows-10">
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            <div [innerHTML]="t('availability.support.allFromConversation')"></div>
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.support.operatorVotes') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.support.knowledgeBase') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.support.conversationToChannel') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.support.manualSendBulk') }}
          </div>
        </div>
      </div>
    </div>
    <div class="card border-top-0 border-bottom-0">
      <div class="card-body pt-0 margin-between-rows-20">
        <div class="margin-between-rows-10">
          <div class="d-flex align-items-baseline">
            <span class="num-container text-primary mr-5">{{ t('availability.marketing.chatBot') }}</span>
            <span
              class="custom-dashed"
              [ngbTooltip]="
                t('tooltips.chatBot', {
                  amount: t('availability.marketing.chatBot')
                })
              "
              >{{ t('info.chatBot', { amount: t('availability.marketing.chatBot') }) }}</span
            >
          </div>
          <div class="d-flex align-items-baseline">
            <span class="num-container text-primary mr-5">{{ t('availability.marketing.triggeredMessage') }}</span>
            <span
              class="custom-dashed"
              [ngbTooltip]="
                t('tooltips.triggeredMessage', {
                  amount: t('availability.marketing.triggeredMessage')
                })
              "
              >{{ t('info.triggeredMessage', { amount: t('availability.marketing.triggeredMessage') }) }}</span
            >
          </div>
        </div>
        <div class="margin-between-rows-10">
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            <div [innerHTML]="t('availability.marketing.allFromConversation')"></div>
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.marketing.abTests') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.marketing.controlGroups') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.marketing.beeEditor') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.marketing.manualSendBulk') }}
          </div>
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-check mr-5 text-primary"></i>
            {{ t('availability.marketing.funnel') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="isShowFeaturesAvailabilityByRatePlansToggleControl"
    class="card"
  >
    <div class="row no-gutters">
      <div class="col-3 border-right"></div>
      <div class="col-9 p-20 text-center">
        <button
          class="btn btn-text-primary"
          type="button"
          (click)="onClickToggleDifferenceTariffPlans()"
        >
          <i
            class="btn-icon-left cqi-sm"
            [ngClass]="getToggleIcon()"
          ></i>
          <span>{{ getToggleText() }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>

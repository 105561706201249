import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';
import { catchError, finalize } from 'rxjs/operators';

import { environment } from '@environment';
import { DjangoUserService } from '@http/django-user/services/django-user.service';
import { DeleteImpossibleError } from '@http/django-user/types/django-user.types';
import { APIError } from '@http/types';
import { makeSequenceFromArray } from '@panel/app/shared/functions/make-sequence-from-array.function';

import { DeleteDjangoUserForm } from './delete-django-user-modal.form';

@Component({
  selector: 'cq-delete-django-user-modal',
  templateUrl: './delete-django-user-modal.component.html',
  styleUrls: ['./delete-django-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDjangoUserModalComponent {
  deleteDjangoUserForm = new DeleteDjangoUserForm();

  /**
   * Список аппов, которые препятствуют удалению django-пользователя
   */
  forbiddenApps: string[] = [];

  /**
   * Перечисление названий аппов, препятствующих удалению django-пользователя
   */
  forbiddenAppNames = '';

  /**
   * Выполняется ли в данный момент запрос удаления django-пользователя
   */
  isDeleteRequestPerformed = false;

  projectName = environment.projectName;

  /**
   * Список того, что случится в случае удаления django-пользователя
   */
  whatWillHappen!: ReadonlyArray<string>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly djangoUserService: DjangoUserService,
    public readonly ngbActiveModal: NgbActiveModal,
    private readonly transloco: TranslocoService,
    private readonly uiRouter: UIRouter,
  ) {
    this.whatWillHappen = this.transloco.translate('deleteDjangoUserModalComponent.body.whatWillHappen', {
      projectName: this.projectName,
    });
  }

  /**
   * Удаление django-пользователя
   */
  deleteDjangoUser(): void {
    if (this.deleteDjangoUserForm.invalid) {
      this.deleteDjangoUserForm.markAllAsTouched();
      return;
    }

    this.isDeleteRequestPerformed = true;

    this.djangoUserService
      .delete(this.deleteDjangoUserForm.controls.password.value)
      .pipe(
        catchError((error: APIError<'InvalidCredentialsError', string>) => {
          // на самом деле, тут могут быть и другие ошибки, например, при отсутствии интернета
          // но из-за плохо написанного интерсептора ErrorResponseInterceptor предсказать структуру ошибки невозможно
          // поэтому пришлось при выставлении типа APIError всё равно писать error?.error
          if (error?.error === 'InvalidCredentialsError') {
            this.deleteDjangoUserForm.controls.password.setErrors({
              wrongPassword: true,
            });
          }

          throw error;
        }),
        catchError((error: APIError<'DjangoUserForbiddenApps', DeleteImpossibleError>) => {
          if (error?.error === 'DjangoUserForbiddenApps') {
            this.forbiddenApps = error.errorMessage.forbiddenApps.map((app) => app.name);
            this.forbiddenAppNames = makeSequenceFromArray(this.forbiddenApps);
            this.deleteDjangoUserForm.controls.password.setErrors({
              forbiddenApps: true,
            });
          }

          throw error;
        }),
        finalize(() => {
          this.isDeleteRequestPerformed = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(() => {
        this.ngbActiveModal.close();
        this.uiRouter.stateService.go(`logout`);
      });
  }
}

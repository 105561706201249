<ng-container *transloco="let t; read: 'operatorAssignmentComponent'">
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
  >
    <button
      class="btn btn-borderless-primary d-inline-flex align-items-center w-100"
      type="button"
      [disabled]="disabled"
      ngbDropdownToggle
      [ngbTooltip]="t('tooltip')"
      [autoClose]="false"
      placement="top"
      container="body"
    >
      <cq-not-assigned *ngIf="assignedTeamMember === null"></cq-not-assigned>
      <cq-conversation-operator
        *ngIf="assignedTeamMember !== null"
        [teamMember]="assignedTeamMember"
        [showInternalName]="false"
      ></cq-conversation-operator>
      <i class="cqi-sm cqi-chevron-down flex-shrink-0 margin-left-5"></i>
    </button>

    <div
      class="overflow-hidden"
      ngbDropdownMenu
    >
      <div class="dropdown-menu-inner">
        <div class="has-feedback has-feedback-left m-0 bordered-bottom">
          <input
            class="form-control form-control-lg"
            [autofocus]="dropdown.isOpen()"
            [formControl]="nameFilter"
            [placeholder]="t('dropdown.searchOperatorInput.placeholder')"
          />
          <span class="form-control-feedback">
            <i class="cqi-sm cqi-search"></i>
          </span>
        </div>
        <div class="custom-scroll overflow-y-auto py-5">
          <ng-container *ngIf="!filtered">
            <ng-container
              [ngTemplateOutlet]="dropdownItemTemplate"
              [ngTemplateOutletContext]="{ $implicit: notAssignedTemplate, teamMember: null }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="dropdownItemTemplate"
              [ngTemplateOutletContext]="{ $implicit: conversationOperatorTemplate, teamMember: me }"
            ></ng-container>
          </ng-container>

          <ng-container
            *ngFor="
              let item of groupedTeamMembers$ | async | keyvalue;
              first as first;
              trackBy: trackByGroupName;
              empty: blank
            "
          >
            <div
              *ngIf="!(first && filtered)"
              class="dropdown-divider no-margin-bottom"
            ></div>
            <span
              class="dropdown-header"
              ng-bind="groupName"
              >{{ item.key.name }}</span
            >
            <ng-container
              *ngFor="let teamMember of item.value; trackBy: trackByTeamMemberId"
              [ngTemplateOutlet]="dropdownItemTemplate"
              [ngTemplateOutletContext]="{ $implicit: conversationOperatorTemplate, teamMember: teamMember }"
            ></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-template
    #dropdownItemTemplate
    let-teamMember="teamMember"
    let-param
  >
    <button
      type="button"
      ngbDropdownItem
      (click)="assignTo(teamMember)"
    >
      <ng-container
        [ngTemplateOutlet]="param"
        [ngTemplateOutletContext]="{ teamMember: teamMember }"
      ></ng-container>
      <i
        *ngIf="assignedTeamMember === teamMember"
        class="dropdown-item-icon-right cqi-sm cqi-check"
      ></i>
    </button>
  </ng-template>

  <ng-template #notAssignedTemplate>
    <cq-not-assigned></cq-not-assigned>
  </ng-template>

  <ng-template
    #conversationOperatorTemplate
    let-teamMember="teamMember"
  >
    <cq-conversation-operator [teamMember]="teamMember"></cq-conversation-operator>
  </ng-template>

  <ng-template #blank>
    <h4 class="m-auto p-15 text-center text-muted">
      {{ t('dropdown.zeroData.noOperatorFound') }}
    </h4>
  </ng-template>
</ng-container>

<div class="flex align-items-center">
  <form
    class="flex-grow"
    [formGroup]="searchForm"
    novalidate
    (submit)="onSubmitSearch.emit(searchForm.valid)"
  >
    <div
      class="form-group search-input has-feedback has-feedback-left no-margin"
      [cqShowError]="searchForm.controls.search"
    >
      <input
        class="form-control"
        autocomplete="off"
        type="text"
        [formControl]="searchForm.controls.search"
        placeholder="{{ 'knowledgeBase.search.searchForm.searchInput.placeholder' | transloco }}"
      />
      <cq-validation-messages [control]="searchForm.controls.search">
        <cq-validation-message errorName="maxlength">
          {{
            'knowledgeBase.search.searchForm.searchInput.errors.maxlength' | transloco: { maxLength: MAX_SEARCH_LENGTH }
          }}
        </cq-validation-message>
        <cq-validation-message errorName="minlength">
          {{
            'knowledgeBase.search.searchForm.searchInput.errors.minlength' | transloco: { minLength: MIN_SEARCH_LENGTH }
          }}
        </cq-validation-message>
      </cq-validation-messages>
      <span class="form-control-feedback">
        <i class="cqi-sm cqi-search"></i>
      </span>
    </div>
  </form>
  <button
    *ngIf="isSearch"
    class="btn btn-text-primary margin-left-10"
    type="button"
    (click)="onResetSearch.emit()"
  >
    <i class="btn-icon cqi-sm cqi-times"></i>
  </button>
</div>

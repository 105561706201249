(function () {
  'use strict';

  require('./data-collection-for-developer.controller');

  angular
    .module('myApp.serviceInstallationForDeveloper')
    .component('cqDataCollectionInstructionsForDeveloper', cqDataCollectionInstructionsForDeveloper());

  function cqDataCollectionInstructionsForDeveloper() {
    return {
      controller: 'CqDataCollectionInstructionsForDeveloperController',
      controllerAs: 'vm',
      template: require('./data-collection-for-developer.html'),
    };
  }
})();

/**
 * Директива, используемая для ограничения минимальной высоты загружаемого изображения в пикселях
 */
(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('cqImageMinHeight', cqImageMinHeight);

  function cqImageMinHeight($q, fileHelper) {
    return {
      restrict: 'A',
      require: ['ngModel', 'cqFile'],
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var minHeight;
      var ngModelController = controller[0];

      if (ngModelController) {
        iAttrs.$observe('cqImageMinHeight', observe);
        ngModelController.$asyncValidators.minHeight = minHeightValidator;
      }

      /**
       * Возвращает высоту изображения
       *
       * @param {Object} imageSize размеры изображения
       */
      function getImageHeight(imageSize) {
        var deferred = $q.defer();

        if (minHeight && imageSize.height < minHeight) {
          deferred.reject();
        } else {
          deferred.resolve();
        }

        return deferred.promise;
      }

      /**
       * Валидатор, проверяющий соответствие минимальной допустимую высоту и высоту загружаемого изображения
       *
       * @param viewValue
       * @returns {Boolean}
       */
      function minHeightValidator(viewValue) {
        if (ngModelController.$isEmpty(viewValue)) {
          return $q.resolve();
        } else {
          return fileHelper.getImageSize(viewValue).then(getImageHeight);
        }
      }

      /**
       * Возвращает значение атрибута minHeight
       *
       * @param {String} newValue Значение атрибута minHeight
       */
      function observe(newValue) {
        minHeight = newValue;

        // нужно вызывать валидацию принудительно, т.к. $validators могут отработать раньше $observe
        ngModelController.$validate();
      }
    }
  }
})();

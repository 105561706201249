import { AbstractControl, ValidationErrors } from '@angular/forms';

import { MessageTelegramButton, MessageTelegramContent } from '@http/message-part/message-part.types';

export function messageTelegramEmptyBlocksValidator<
  T extends { contents: MessageTelegramContent[]; buttons: MessageTelegramButton[] },
>(telegramBodyJsonForm: AbstractControl<T>): ValidationErrors | null {
  let { contents, buttons } = telegramBodyJsonForm.value;

  let contentsAreEmpty = !contents.length;
  let buttonsAreEmpty = !buttons.length;

  return contentsAreEmpty && buttonsAreEmpty ? { contentAreEmpty: true } : null;
}

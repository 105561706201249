import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { AceModule } from 'ngx-ace-wrapper';

import { AceEditorComponent } from './ace-editor.component';

@NgModule({
  declarations: [AceEditorComponent],
  exports: [AceEditorComponent],
  imports: [CommonModule, TranslocoDirective, AceModule, ReactiveFormsModule],
})
export class AceEditorModule {}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TriggerChainGeneralStatisticsStateService } from '@panel/app/pages/trigger-chains/general-statistics/trigger-chain-general-statistics-state.service';

@Component({
  selector: 'cq-trigger-chain-general-statistics-table',
  templateUrl: './trigger-chain-general-statistics-table.component.html',
  styleUrls: ['./trigger-chain-general-statistics-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainGeneralStatisticsTableComponent {
  constructor(private readonly triggerChainGeneralStatisticsStateService: TriggerChainGeneralStatisticsStateService) {}

  get chainSteps() {
    return this.triggerChainGeneralStatisticsStateService.chainStepList$.getValue();
  }
}

<ng-container *transloco="let t; read: 'daterangepickerPageComponent'">
  <div class="margin-between-rows-50">
    <h1>Daterangepicker</h1>

    <div>
      <h3>Basic daterangepicker</h3>
      <div class="card">
        <div class="card-body">
          <cq-daterangepicker
            class="d-inline-block border rounded"
            [(ngModel)]="daterangepicker1"
          ></cq-daterangepicker>
          <hr />
          <div>Model:</div>
          <div>{{ daterangepicker1.from?.format('LLLL') }} - {{ daterangepicker1.to?.format('LLLL') }}</div>
        </div>
      </div>
    </div>

    <div>
      <h3>Daterangepicker with range navigation</h3>
      <div class="card">
        <div class="card-body">
          <cq-daterangepicker
            class="d-inline-block border rounded"
            [(ngModel)]="daterangepicker2"
            [options]="{
              showRangeNavigation: true
            }"
          ></cq-daterangepicker>
          <hr />
          <div>Model:</div>
          <div>{{ daterangepicker2.from?.format('LLLL') }} - {{ daterangepicker2.to?.format('LLLL') }}</div>
        </div>
      </div>
    </div>

    <div>
      <h3>Daterangepicker in popover with apply selection</h3>
      <div class="card">
        <div class="card-body">
          <cq-daterangepicker-input
            [(ngModel)]="daterangepicker3"
            [options]="{
              showRangeNavigation: true,
              showApplyButtons: true
            }"
          ></cq-daterangepicker-input>
          <hr />
          <div>Model:</div>
          <div>{{ daterangepicker3.from?.format('LLLL') }} - {{ daterangepicker3.to?.format('LLLL') }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./chat-data-collection-settings.controller');

  angular.module('myApp.privacySettings').component('cqChatDataCollectionSettings', cqChatDataCollectionSettings());

  function cqChatDataCollectionSettings() {
    return {
      bindings: {
        chatSettings: '=', // Настройки чата
        currentApp: '=', // Текущее приложение
        settings: '=', // настройки обработки персональных данных
      },
      controller: 'CqChatDataCollectionSettingsController',
      controllerAs: 'vm',
      template: require('./chat-data-collection-settings.html'),
    };
  }
})();

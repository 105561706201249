<div class="custom-control custom-checkbox">
  <input
    #nativeInput
    id="{{ id }}"
    class="custom-control-input"
    [class.is-invalid]="showInvalidFeedback"
    type="checkbox"
    [(ngModel)]="checked"
    [disabled]="disabled"
    (change)="onCheckedChange($event)"
    (click)="onTouched()"
  />
  <label
    class="custom-control-label"
    for="{{ id }}"
    (click)="onTouched()"
  >
    <ng-content></ng-content>
  </label>
  <div
    *ngIf="showInvalidFeedback"
    class="invalid-feedback"
  >
    <ng-content select="[invalid-feedback]"></ng-content>
  </div>
</div>

<ng-container *transloco="let t; read: 'memberInvitationsTableComponent'">
  <table class="table table-align-middle">
    <thead>
      <tr>
        <th>{{ t('table.head.email') }}</th>
        <th>{{ t('table.head.permissions') }}</th>
        <th>{{ t('table.head.status') }}</th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let memberInvitation of memberInvitations; index as memberInvitationIndex">
        <td class="text-break">{{ memberInvitation.email }}</td>

        <td class="margin-between-cols-10">
          <i
            class="cqi-sm"
            [ngClass]="getIconClassForPermission(memberInvitation.permissions)"
            [ngbTooltip]="getNameForPermission(memberInvitation.permissions)"
            container="body"
          ></i>

          <i
            *ngIf="memberInvitation.permissionsExport"
            class="cqi-sm"
            [ngClass]="getIconClassForPermissionExport()"
            [ngbTooltip]="getNameForPermissionExport()"
            container="body"
          ></i>
          <i
            *ngIf="memberInvitation.permissionsSendBulk"
            class="cqi-sm"
            [ngClass]="getIconClassForPermissionSendBulk()"
            [ngbTooltip]="getNameForPermissionSendBulk()"
            container="body"
          ></i>
        </td>

        <td>
          <div
            class="badge"
            [ngClass]="getClassForInvitationStatusBadge(memberInvitation.inviteExpired)"
          >
            {{ t('table.body.status.' + getTranslateKeyForInvitationStatusBadge(memberInvitation.inviteExpired)) }}
          </div>
        </td>

        <td class="shrink-by-content">
          <button
            *ngIf="memberInvitation.inviteExpired"
            class="btn btn-secondary"
            type="button"
            (click)="onClickResendInvitationButton(memberInvitationIndex)"
            [attr.data-test]="'member-invitations-table-resend-button-' + memberInvitationIndex"
          >
            <i class="cqi-sm cqi-refresh btn-icon-left"></i>
            <span>{{ t('table.body.action') }}</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <cq-member-invitations-not-found-zero-data
    *ngIf="isInvitationsNotFound()"
    data-test="cq-member-invitations-not-found-zero-data"
  ></cq-member-invitations-not-found-zero-data>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { MemberActionsModule } from '@panel/app/pages/admins/actions/member-actions.module';
import { MemberInvitationsModule } from '@panel/app/pages/admins/invitations/member-invitations.module';
import { MemberInvitationsFiltersModule } from '@panel/app/pages/admins/invitations-filters/member-invitations-filters.module';
import { MemberInvitationsNotExistZeroDataModule } from '@panel/app/pages/admins/invitations-not-exist-zero-data/member-invitations-not-exist-zero-data.module';
import { MemberInvitationsNotFoundZeroDataModule } from '@panel/app/pages/admins/invitations-not-found-zero-data/member-invitations-not-found-zero-data.module';
import { MemberInvitationsTableModule } from '@panel/app/pages/admins/invitations-table/member-invitations-table.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MemberActionsModule,
    MemberInvitationsFiltersModule,
    MemberInvitationsModule,
    MemberInvitationsNotExistZeroDataModule,
    MemberInvitationsNotFoundZeroDataModule,
    MemberInvitationsTableModule,
    SharedModule,
    TranslocoModule,
  ],
})
export class AdminsModule {}

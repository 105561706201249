<ng-container *transloco="let t; read: 'amocrmLeadNoteComponent'">
  <div
    class="form-control-container mb-15"
    [cqShowError]="controlNote"
    data-test="amocrm-lead-note-control-container-div"
  >
    <label for="pipelineNote">{{ t('label') }}</label>
    <textarea
      class="form-control no-resize"
      [formControl]="controlNote"
      [placeholder]="t('textarea.placeholder')"
      id="pipelineNote"
      rows="3"
      data-test="amocrm-lead-note-textarea"
    ></textarea>

    <cq-validation-messages [control]="controlNote">
      <cq-validation-message errorName="maxlength">
        {{ t('textarea.errors.maxLength', { maxLength: AMOCRM_NOTE_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>

  <div
    *ngIf="isChatBot"
    class="d-flex align-items-baseline text-secondary"
    data-test="amocrm-lead-note-note-div"
  >
    <i class="cqi-sm cqi-lightbulb mr-5"></i>
    <span>{{ t('note') }}</span>
  </div>
</ng-container>

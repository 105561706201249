import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TriggerChainStepNameEditorModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-name-editor/trigger-chain-step-name-editor.module';

import { TriggerChainStepNameEditorAjsComponent } from './trigger-chain-step-name-editor-ajs.component';

@NgModule({
  declarations: [TriggerChainStepNameEditorAjsComponent],
  exports: [TriggerChainStepNameEditorAjsComponent],
  imports: [CommonModule, TriggerChainStepNameEditorModule, FormsModule],
})
export class TriggerChainStepNameEditorAjsModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { LegacyWebhookPartEditorComponent } from './legacy-webhook-part-editor.component';

@NgModule({
  declarations: [LegacyWebhookPartEditorComponent],
  exports: [LegacyWebhookPartEditorComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, SharedModule],
})
export class LegacyWebhookPartEditorModule {}

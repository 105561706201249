import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ModalHelperService } from '@panel/app/services';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';

@Component({
  selector: 'cq-trigger-chain-step-editor-wrap',
  templateUrl: './trigger-chain-step-editor-wrap.component.html',
  styleUrls: ['./trigger-chain-step-editor-wrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainStepEditorWrapComponent extends AbsCVAFormControlBasedComponent<string> {
  readonly control: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
  });

  @Input()
  editable: boolean = false;

  @Input()
  deletable: boolean = false;

  @Input()
  @HostBinding('style.width.px')
  width: number = 365;

  @Output()
  nameChange: Observable<string> = this.control.valueChanges.pipe(debounceTime(500));

  @Output()
  deleteBlockClick: Subject<void> = new Subject<void>();

  @Output()
  copyBlockClick: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalHelperService: ModalHelperService,
    private readonly translocoService: TranslocoService,
  ) {
    super();
  }

  /**
   * Хук на нажатие кнопки удаления шага
   */
  onDeleteBlockClick() {
    this.modalHelperService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate(
          'triggerChainsEditorComponent.confirmDeleteTriggerChainStepModal.heading',
        ),
        body: this.translocoService.translate('triggerChainsEditorComponent.confirmDeleteTriggerChainStepModal.body'),
        cancelButtonText: this.translocoService.translate('general.cancel'),
        confirmButtonText: this.translocoService.translate('general.remove'),
      })
      .open(ConfirmModalComponent, { centered: true })
      .result.then(
        () => {
          this.deleteBlockClick.next();
        },
        () => {},
      );
  }

  /**
   * Хук на нажатие кнопки копирования шага
   */
  onCopyBlockClick() {
    this.copyBlockClick.next();
  }
}

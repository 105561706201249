<ng-container *transloco="let t; read: 'enablingFeatureComponent'">
  <div class="d-flex mb-10">
    <cq-switch-new [formControl]="control"></cq-switch-new>
    <h4 class="mb-0 ml-10">{{ t('heading') }}</h4>
    <cq-beta-label
      class="badge badge-light-success ml-10"
      [featureLabel]="FEATURE_LABELS_ONLY.CONVERSATIONS_AUTO_ASSIGNMENT"
    ></cq-beta-label>
  </div>
  <div class="text-secondary">{{ t('description') }}</div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { EventSelectComponent } from './event-select.component';

@NgModule({
  declarations: [EventSelectComponent],
  imports: [CommonModule, NgSelectModule, TranslocoModule, ReactiveFormsModule, NgbTooltipModule],
  exports: [EventSelectComponent],
})
export class EventSelectModule {}

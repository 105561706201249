import { Container } from 'pixi.js';
import { Observable, Subject } from 'rxjs';

import { getInnerHeight, spliceText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';

import { IPoint } from './connection';

export type Style = {
  [key: string]: number | Style;
};

export interface CanvasElement {
  container: Container;
  coordinates: IPoint;
  destroyed: boolean;
  destroy$: Observable<void>;
  height: number;
  width: number;
  redraw(): void;
  render(): void;
  destroy(): void;
}

export abstract class ABSCard implements CanvasElement {
  public abstract readonly container: Container;
  protected abstract readonly style: Style;

  public readonly destroy$ = new Subject<void>();
  public destroyed = false;

  /**
   * Создаем контейнер, в котором будет содержаться весь контент элемента.
   */
  protected createContainer(): Container {
    const container = new Container();
    container.eventMode = 'static';
    container.sortableChildren = true;
    container.zIndex = 2;
    return container;
  }

  /**
   * Удаление контейнера элемента с канваса, emit удаления, чтоб подписавшийся мог отрабатывать у себя удаление ветки
   */
  public destroy() {
    this.destroyed = true;
    this.container.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Метод, который позволяет получить размеры контента, по котором нужно отрисовать "карточку".
   */
  abstract getContentHeight(): number;

  /**
   * Возвращает "высоту" карточки. Формально равна ширине карточки, т.к. контейнер виртуальная штука и не имеет своих размеров.
   */
  abstract get height(): number;

  /**
   * Возвращает "ширину" карточки. Формально равна ширине карточки, т.к. контейнер виртуальная штука и не имеет своих размеров.
   */
  abstract get width(): number;

  abstract redraw(): void;

  abstract render(): Container;

  /**
   * { X,Y } координаты карточки. Не учитывает размер рамки, поэтому вычитаем.
   */
  get coordinates(): IPoint {
    // @ts-ignore
    const borderSize: number = this.style['border']['size'];
    return {
      x: this.container.x - borderSize,
      y: this.container.y - borderSize,
    };
  }

  protected get utils() {
    return {
      /**
       * Утилитк для обрезания слишком длинного текста с троеточием на конце
       */
      spliceText: spliceText.bind(null),
      getInnerHeight: getInnerHeight.bind(null),
    };
  }
}

/**
 * Получение места в котором показывается тарифная сетка
 */
export enum PLAN_CAPABILITIES_PRICING_PLACE {
  BILLING = 'billing', // в биллинге
  PAYWALL = 'paywall', // в пейволе
}

/**
 * Получение opening-состояния popover'а с ограничениями
 * Вписываются true значения только для тех ограничений, для которых поповер должен быть сразу открытым на странице.
 * Например: [PLAN_CAPABILITIES.AUTO_MESSAGES_AB_TESTING]: true
 */
export const PLAN_CAPABILITY_POPOVER_OPENING_STATE: any = {
  //[PLAN_CAPABILITIES.AUTO_MESSAGES_AB_TESTING]: true NOTE ПРИМЕР
};

(function () {
  'use strict';

  require('./widget-badge-type.controller.js');

  angular.module('myApp.chatSettings').component('cqWidgetBadgeType', cqWidgetBadgeType());

  function cqWidgetBadgeType() {
    return {
      bindings: {
        currentAppName: '=', // название аппа, для событий
        type: '=', // тип значка
        typeChange: '<', // коллбэк на изменение типа значка
      },
      controller: 'CqWidgetBadgeTypeController',
      controllerAs: 'vm',
      template: require('./widget-badge-type.html'),
    };
  }
})();

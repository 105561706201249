import { MessageBlockView } from '@panel/app/pages/chat-bot/content/views/blocks/message-block-view';

/*
 * Блок который может быть дефолтныйм и блоком прерывания
 */
export class HybridBlockView extends MessageBlockView {
  protected get headingIcon(): string {
    return this.isInterruptScenario ? '\ue9c8' : '\ue99f';
  }
}

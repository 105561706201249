import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { MomentModule } from 'ngx-moment';

import { DatetimePickerModule } from '@panel/app/shared/components/datetime-picker/datetime-picker.module';

import { DaterangepickerPageComponent } from './daterangepicker-page.component';

@NgModule({
  declarations: [DaterangepickerPageComponent],
  exports: [DaterangepickerPageComponent],
  imports: [CommonModule, TranslocoModule, DatetimePickerModule, FormsModule, MomentModule],
})
export class DaterangepickerPageModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/** Компонент для работы not-found-data в тегах диалогах */
@Component({
  selector: 'cq-tag-list-not-found[resetSearch]',
  templateUrl: './tag-list-not-found.component.html',
  styleUrls: ['./tag-list-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListNotFoundComponent {
  /** Callback на сброс поиска */
  @Output()
  resetSearch: EventEmitter<void> = new EventEmitter<void>();

  /** Обрабатывает сброс поиска */
  onResetSearch() {
    this.resetSearch.emit();
  }
}

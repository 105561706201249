(function () {
  'use strict';

  angular.module('myApp.integrations').controller('OpenCartController', OpenCartController);

  function OpenCartController($translate, PROJECT_NAME, carrotquestHelper, integration) {
    var vm = this;

    vm.$translate = $translate;
    vm.integration = integration;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.trackClickDownloadModule = trackClickDownloadModule;

    /**
     * Трек клика на 'Скачать модуль'
     */
    function trackClickDownloadModule() {
      carrotquestHelper.track('Интеграции - ' + integration.type + ' - клик на "Скачать модуль"');
    }
  }
})();

<ng-container *transloco="let t; read: 'amocrmChatBotsForLeadComponent'">
  <div
    class="d-flex align-items-center cursor-pointer"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
    (click)="collapse.toggle()"
  >
    <h4 class="no-margin">{{ t('heading') }}</h4>
    <div class="ml-auto">{{ activeChatBots.length }}</div>
    <i
      class="cqi-sm ml-10"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>

  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="pt-15">
      <div class="mb-25">
        <span
          *ngIf="isEmptyChatBots"
          data-test="chat-bots-trigger-blocks-zero-data-text-span"
          >{{ t('zeroData', {integrationTypeName}) }}</span
        >
        <span
          *ngIf="!isEmptyChatBots"
          data-test="chat-bots-trigger-blocks-description-text-span"
          >{{ t('description', {integrationTypeName, projectName})}}</span
        >
      </div>

      <cq-integration-chat-bots-trigger-blocks
        class="mt-15"
        [chatBots]="activeChatBots"
        [integrationId]="integrationId"
        [integrationType]="INTEGRATION_TYPES.AMOCRM"
        [isShowedFromModal]="isShowedFromModal"
      ></cq-integration-chat-bots-trigger-blocks>
    </div>
  </div>
</ng-container>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { AppService } from '@http/app/services/app.service';
import { TagModel } from '@http/tag/tag.model';
import { User, UserTag } from '@http/user/types/user.type';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { LS_UNCOLLAPSED_USER_CARD_BLOCKS } from '@panel/app/shared/constants/localstorage.keys';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Component({
  selector: 'cq-user-tags[currentApp]',
  templateUrl: './user-tags.component.html',
  styleUrls: ['./user-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTagsComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** ИД пользователя */
  @Input()
  userId: User['id'] = '';

  /** Теги пользователя */
  @Input()
  userTags!: UserTag[];

  /** Колбек на смену состояния коллапса */
  @Output()
  collapseStateChange: EventEmitter<string> = new EventEmitter();

  _tagList!: Array<UserTag>;

  /** Доступ до тегов пользователей */
  accessToUsersTags: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  /** Состояние коллапса */
  isCollapsed: boolean = true;

  /** Имя для localeStorage */
  userTagsLSName = 'user_tags';

  constructor(
    private readonly appService: AppService,
    private readonly $uibModal: UibModalService,
    private readonly modalService: NgbModal,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly tagModel: TagModel,
    private readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.accessToUsersTags = this.planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, this.currentApp);

    if (localStorage.getItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS)?.includes(this.userTagsLSName)) {
      this.isCollapsed = false;
    }

    firstValueFrom(this.tagModel.getList(this.appService.currentAppId, false)).then((tagsList: Array<UserTag>) => {
      this._tagList = tagsList;
    });
  }

  /**
   * Открытие модалки добавления тега пользователю
   */
  openAddTagModal() {
    const bodyText = this.translocoService.translate('userTagsComponent.addTagModal.body');

    const addTagModal = this.$uibModal.open({
      controller: 'AddTagModalController',
      controllerAs: 'vm',
      resolve: {
        modalWindowParams: function () {
          return {
            body: bodyText,
          };
        },
        tagList: () => {
          return this._tagList;
        },
      },
      size: 'md modal-dialog-centered',
      templateUrl: 'js/shared/modals/add-tag/add-tag.html',
    });

    addTagModal.result.then((res: UserTag) => {
      return this.addTagToUserSuccess(res);
    });
  }

  addTagToUserSuccess(newTag: UserTag) {
    firstValueFrom(this.tagModel.setByUserId(this.userId, newTag.name, this.userTags as [])).then(() => {
      this.changeDetectorRef.detectChanges();
      this.carrotquestHelper.track('Карточка пользователя - добавил тег');
    });
  }

  /**
   * Открытие модалки удаления тега пользователя
   *
   * @param tag Удаляемый тег
   */
  openRemoveTagModal(tag: UserTag) {
    let headingText = this.translocoService.translate('userTagsComponent.removeTagModal.heading');
    let bodyText = this.translocoService.translate('userTagsComponent.removeTagModal.body', { tagName: tag.name });

    let removeTagModal = this.$uibModal.open({
      controller: 'ConfirmModalController',
      controllerAs: 'vm',
      templateUrl: 'js/shared/modals/confirm/confirm.html',
      resolve: {
        modalWindowParams: function () {
          return {
            heading: headingText,
            body: bodyText,
          };
        },
      },
    });

    removeTagModal.result.then(() => {
      return this.removeTag(tag);
    });
  }

  removeTag(tag: UserTag) {
    this.carrotquestHelper.track('Карточка пользователя - удалил тег');

    firstValueFrom(this.tagModel.removeByUserId(this.userId, tag.id)).then(() => {
      this.userTags.splice(this.userTags.indexOf(tag), 1);
      this.changeDetectorRef.detectChanges();
    });
  }
}

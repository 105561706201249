(function () {
  'use strict';

  require('./conversations-statistics.controller');
  require('./tabs/ai-bot.html');
  require('./tabs/channels.html');
  require('./tabs/general.html');
  require('./tabs/operators.html');
  require('./tabs/tags.html');
  require('./tabs/source.html');
  require('./tabs/vote.html');
  require('./tabs/working-periods-popover.html');

  angular.module('myApp.conversationsStatistics').component('cqConversationsStatistics', cqConversationsStatistics());

  function cqConversationsStatistics() {
    return {
      bindings: {
        billingInfo: '=', // Информация о биллинге
        channels: '=', // Список каналов
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        teamMembers: '=', // Список операторов
      },
      controller: 'ConversationsStatisticsController',
      controllerAs: 'vm',
      template: require('./conversations-statistics.html'),
    };
  }
})();

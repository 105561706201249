import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AccountHeaderModule } from '@panel/app/pages/account/shared/account-header/account-header.module';
import { AddAppModalModule } from '@panel/app/pages/account/shared/add-app-modal/add-app-modal.module';
import { AdminParamsModalModule } from '@panel/app/pages/account/shared/admin-params-modal/admin-params-modal.module';
import { AppsModule } from '@panel/app/pages/account/shared/apps/apps.module';
import { AssignNewSuperAdminModalModule } from '@panel/app/pages/account/shared/assign-new-super-admin-modal/assign-new-super-admin-modal.module';
import { DeleteDjangoUserModule } from '@panel/app/pages/account/shared/delete-django-user/delete-django-user.module';
import { GetDemoModalModule } from '@panel/app/pages/account/shared/get-demo-modal/get-demo-modal.module';
import { NotificationSettingsModule } from '@panel/app/pages/account/shared/notification-settings/notification-settings.module';
import { PasswordChangeModule } from '@panel/app/pages/account/shared/password-change/password-change.module';
import { ProfileModule } from '@panel/app/pages/account/shared/profile/profile.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AccountComponent } from './account.component';

@NgModule({
  declarations: [AccountComponent],
  exports: [AccountComponent],
  imports: [
    AccountHeaderModule,
    AddAppModalModule,
    AdminParamsModalModule,
    AppsModule,
    AssignNewSuperAdminModalModule,
    CommonModule,
    DeleteDjangoUserModule,
    GetDemoModalModule,
    NgbNavModule,
    NgbTooltipModule,
    NotificationSettingsModule,
    PasswordChangeModule,
    ProfileModule,
    SharedModule,
    TranslocoModule,
  ],
})
export class AccountModule {}

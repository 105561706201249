<ng-container *transloco="let t; read: 'googleAnalyticsComponent'">
  <div class="card align-items-center">
    <div class="card-body margin-between-rows-20">
      <div>
        <div class="font-weight-bold">{{ t('manual.heading') }}</div>
        <p>{{ t('manual.description.text1', { integrationTypeName: integrationTypeTranslation }) }}</p>
        <p>{{ t('manual.description.text2.text') }}</p>
        <ul>
          <li>{{ t('manual.description.text2.list.1') }}</li>
          <li>{{ t('manual.description.text2.list.2') }}</li>
        </ul>
      </div>
      <div>
        <h3>{{ t('manual.section1.heading') }}</h3>
        <p [innerHTML]="t('manual.section1.text1')"></p>
        <p>{{ t('manual.section1.text2') }}</p>
        <img
          class="w-100"
          [src]="'assets/img/' + activeLang + '/integrations/google-analytics/img-1.png'"
          alt=""
        />
        <p [innerHTML]="t('manual.section1.text3')"></p>
        <div [innerHTML]="t('manual.section1.code1')"></div>
        <p [innerHTML]="t('manual.section1.text4')"></p>
        <div [innerHTML]="t('manual.section1.code2')"></div>
        <p [innerHTML]="t('manual.section1.text5')"></p>
        <p [innerHTML]="t('manual.section1.text6')"></p>
        <div [innerHTML]="t('manual.section1.code3')"></div>
        <p [innerHTML]="t('manual.section1.text7')"></p>
        <p [innerHTML]="t('manual.section1.text8')"></p>
        <div [innerHTML]="t('manual.section1.code4')"></div>
        <p [innerHTML]="t('manual.section1.text9')"></p>
        <p [innerHTML]="t('manual.section1.text10')"></p>
        <div [innerHTML]="t('manual.section1.code5')"></div>
      </div>
      <div>
        <h3>{{ t('manual.section2.heading') }}</h3>
        <p [innerHTML]="t('manual.section2.text1')"></p>
        <p [innerHTML]="t('manual.section2.text2')"></p>
        <p>{{ t('manual.section2.text3') }}</p>
        <img
          class="w-100"
          [src]="'assets/img/' + activeLang + '/integrations/google-analytics/img-2.png'"
          alt=""
        />
        <p>{{ t('manual.section2.text4') }}</p>
        <div [innerHTML]="t('manual.section2.code1')"></div>
        <p [innerHTML]="t('manual.section2.text5')"></p>
        <p>{{ t('manual.section2.text6', { integrationTypeName: integrationTypeTranslation }) }}</p>
        <img
          class="w-100"
          [src]="'assets/img/' + activeLang + '/integrations/google-analytics/img-3.png'"
          alt=""
        />
        <p>{{ t('manual.section2.text7', { integrationTypeName: integrationTypeTranslation }) }}</p>
        <p>{{ t('manual.section2.text8') }}</p>
        <p>{{ t('manual.section2.text9') }}</p>
        <p [innerHTML]="t('manual.section2.text10')"></p>
        <p [innerHTML]="t('manual.section2.text11')"></p>
      </div>
      <div>
        <h3>{{ t('manual.section3.heading') }}</h3>
        <p>{{ t('manual.section3.text1', { integrationTypeName: integrationTypeTranslation }) }}</p>
        <div [innerHTML]="t('manual.section3.code1')"></div>
        <p [innerHTML]="t('manual.section3.text2')"></p>
        <p [innerHTML]="t('manual.section3.text3', { integrationTypeName: integrationTypeTranslation })"></p>
        <p>{{ t('manual.section3.text4') }}</p>
        <p [innerHTML]="t('manual.section3.text5')"></p>
        <p [innerHTML]="t('manual.section3.text6')"></p>
      </div>
      <div>
        <h3>{{ t('manual.section4.heading', { integrationTypeName: integrationTypeTranslation }) }}</h3>
        <p>{{ t('manual.section4.text1', { integrationTypeName: integrationTypeTranslation }) }}</p>
        <p [innerHTML]="t('manual.section4.text2', { integrationTypeName: integrationTypeTranslation })"></p>
        <p [innerHTML]="t('manual.section4.text3')"></p>
        <img
          class="w-100"
          [src]="'assets/img/' + activeLang + '/integrations/google-analytics/img-4.png'"
          alt=""
        />
        <p>{{ t('manual.section4.text4') }}</p>
        <div [innerHTML]="t('manual.section4.code1')"></div>
        <p class="font-weight-bold">{{ t('manual.section4.text5') }}</p>
        <p [innerHTML]="t('manual.section4.text6', { integrationTypeName: integrationTypeTranslation })"></p>
        <p [innerHTML]="t('manual.section4.text7')"></p>
        <p [innerHTML]="t('manual.section4.text8')"></p>
        <p [innerHTML]="t('manual.section4.text9')"></p>
        <p [innerHTML]="t('manual.section4.text10')"></p>
        <p [innerHTML]="t('manual.section4.text11')"></p>
        <p [innerHTML]="t('manual.section4.text12')"></p>
        <p [innerHTML]="t('manual.section4.text13')"></p>
        <p [innerHTML]="t('manual.section4.text14')"></p>
        <p [innerHTML]="t('manual.section4.text15')"></p>
        <p [innerHTML]="t('manual.section4.text16')"></p>
        <p [innerHTML]="t('manual.section4.text17')"></p>
        <p [innerHTML]="t('manual.section4.text18')"></p>
        <p class="font-weight-bold">{{ t('manual.section4.text19') }}</p>
        <div [innerHTML]="t('manual.section4.code2')"></div>
        <p [innerHTML]="t('manual.section4.text20')"></p>
        <p>{{ t('manual.section4.text21') }}</p>
        <img
          class="w-100"
          [src]="'assets/img/' + activeLang + '/integrations/google-analytics/img-5.jpg'"
          alt=""
        />
      </div>
    </div>
  </div>
  <div class="text-right">
    <a
      class="btn btn-primary"
      [href]="t('aboutCallbacksLink.href')"
      target="_blank"
      >{{ t('aboutCallbacksLink.text') }}</a
    >
  </div>
</ng-container>

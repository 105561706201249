(function () {
  'use strict';

  require('./powered-by-inscription.controller');

  angular.module('myApp.chatSettings').component('cqPoweredByInscription', cqPoweredByInscription());

  function cqPoweredByInscription() {
    return {
      bindings: {
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // Приложение
        show: '=', // Флаг отображения напдписи "Мы используем ..."
        subscriptionStatus: '=', // Статус подписки
      },
      controller: 'CqPoweredByInscriptionController',
      controllerAs: 'vm',
      template: require('./powered-by-inscription.html'),
    };
  }
})();

/** Статусы счёта */
export enum INVOICE_STATUS {
  /** Не оплачен */
  NOT_PAID = 'not_paid',
  /** Оплачен */
  PAID = 'paid',
  /** Ожидает оплаты */
  PAYMENT_DUE = 'payment_due',
  /** Аннулирован */
  VOIDED = 'voided',
}

/** Причины оплаты */
export enum PAYMENT_REASON {
  /** Оплата счёта */
  PAY_SCORE = 'pay_score',
  /** Оплата подписки */
  PAY_SUBSCRIPTION = 'pay_subscription',
  /** Пополнение баланса */
  REFILL_BALANCE = 'refill_balance',
}

/** Количество подгружаемых счетов с backend'а */
export enum INVOICE_PAGE_COUNT {
  FOR_SUBSCRIPTION_PAGE = 4,
}

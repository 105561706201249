import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';

@Component({
  selector: 'cq-navigation-item[tooltipText][icon]',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent implements OnInit {
  /**
   * Состояние, на которое нужно перейти при нажатии на пункт меню
   */
  @Input()
  stateTo: string = '';

  /**
   * Состояния при которых пункт меню будет подсвечиваться как активный
   */
  @Input()
  states: string[] = [];

  /**
   * Текст для тултипа
   */
  @Input()
  tooltipText!: string;

  /**
   * Запрет на показ тултипа
   */
  @Input()
  disableTooltip: boolean = false;

  /**
   * Иконка cqi-navigation
   */
  @Input()
  icon!: string;

  /**
   * Заблокирована ли кнопка из-за отсутствия доступа в раздел
   */
  @Input()
  disabledByNotHavingAccess: boolean = false;

  /**
   * Задизейблена ли кнопка
   */
  @Input()
  disable: boolean = false;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();

  isDashboard: boolean = false;

  constructor(private readonly featureModel: FeatureModel) {}

  ngOnInit() {
    this.isDashboard = !!this.stateTo?.includes('dashboard');
  }

  get isShowNewYearLogo(): boolean {
    return this.featureModel.hasAccess(FEATURES.NEW_YEAR);
  }
}

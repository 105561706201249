/**
 * Фильтр, транслитирирующий строку с русского языка на английский. Автоматически приводит в нижний регистр
 */
(function () {
  'use strict';

  angular.module('myApp.filters.transliterate').filter('transliterate', transliterate);

  function transliterate() {
    return transliterateFilter;

    /**
     * Функция транслитирирует строку с русского языка на английский. Автоматически приводит в нижний регистр
     *
     * @param {String} text Текст, который нужно транслитирировать
     * @returns {String}
     */
    function transliterateFilter(text) {
      var default_symbol = '-';
      var result = '';
      var replacements = {
        // @formatter:off
        //Русский буквы
        А: 'a',
        Б: 'b',
        В: 'v',
        Г: 'g',
        Д: 'd',
        Е: 'e',
        Ё: 'e',
        Ж: 'zh',
        З: 'z',
        И: 'i',
        Й: 'y',
        К: 'k',
        Л: 'l',
        М: 'm',
        Н: 'n',
        О: 'o',
        П: 'p',
        Р: 'r',
        С: 's',
        Т: 't',
        У: 'u',
        Ф: 'f',
        Х: 'h',
        Ц: 'ts',
        Ч: 'ch',
        Ш: 'sh',
        Щ: 'sch',
        Ъ: '',
        Ы: 'y',
        Ь: '',
        Э: 'e',
        Ю: 'eu',
        Я: 'ya',
        а: 'a',
        б: 'b',
        в: 'v',
        г: 'g',
        д: 'd',
        е: 'e',
        ё: 'e',
        ж: 'zh',
        з: 'z',
        и: 'i',
        й: 'y',
        к: 'k',
        л: 'l',
        м: 'm',
        н: 'n',
        о: 'o',
        п: 'p',
        р: 'r',
        с: 's',
        т: 't',
        у: 'u',
        ф: 'f',
        х: 'h',
        ц: 'ts',
        ч: 'ch',
        ш: 'sh',
        щ: 'sch',
        ъ: '',
        ы: 'y',
        ь: '',
        э: 'e',
        ю: 'yu',
        я: 'ya',
        //Украинские буквы (дополнительно)
        Ї: 'yi',
        І: 'i',
        Є: 'ye',
        Ґ: 'g',
        ї: 'yi',
        і: 'i',
        є: 'ye',
        ґ: 'g',
        // @formatter:on
      };
      if (!text) {
        return text;
      }

      text = text.toLowerCase();

      for (var i = 0; i < text.length; i++) {
        if (/[а-яёїієґ']/.test(text.charAt(i))) {
          // заменяем символы на русском и украинском
          result += replacements[text.charAt(i)];
        } else if (/[a-z0-9]/.test(text.charAt(i))) {
          // символы на английском и циры оставляем как есть
          result += text.charAt(i);
        } else if (result.slice(-1) !== default_symbol) {
          // прочие символы заменяем на default_symbol
          result += default_symbol;
        }
      }

      return result;
    }
  }
})();

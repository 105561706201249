import { IMAGE_EXTENSION } from '../../shared/services/file-helper/file-helper.constants';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_PART_TYPES } from '../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.pushSettings').controller('CqPushSettingsController', CqPushSettingsController);

  function CqPushSettingsController(
    $translate,
    toastr,
    pushSettingsModel,
    validationHelper,
    USER_FILES_URL,
    appModel,
    fileHelper,
    carrotquestHelper,
  ) {
    var vm = this;

    var BODY_MAX_LENGTH = 255; // максимальная длина тела страницы подтверждения подписки на уведомления
    var DOMAIN_MAX_LENGTH = 64; // максимальная длина домена
    var TITLE_MAX_LENGTH = 255; // максимальная длина заголовка страницы подтверждения подписки на уведомления

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.apiDomainAlreadyRegistered = false; // флаг уже существующего домена
      vm.BODY_MAX_LENGTH = BODY_MAX_LENGTH;
      vm.DOMAIN_MAX_LENGTH = DOMAIN_MAX_LENGTH;
      vm.fileHelper = fileHelper;
      vm.IMAGE_EXTENSION = IMAGE_EXTENSION;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.newIcon = null; //Новая иконка
      vm.newIconPreview = null; //Путь загруженной иконки
      vm.savePushSettings = savePushSettings;
      vm.setDefaultPushImage = setDefaultPushImage;
      vm.TITLE_MAX_LENGTH = TITLE_MAX_LENGTH;
      vm.trackClickChangeImage = trackClickChangeImage;
      vm.trackClickSetDefaultPushImage = trackClickSetDefaultPushImage;
      vm.USER_FILES_URL = USER_FILES_URL;
      vm.validationHelper = validationHelper;
    }

    /**
     * Сохранение настроек пушей
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function savePushSettings(isValid) {
      if (isValid) {
        vm.apiDomainAlreadyRegistered = false;

        if (vm.newIcon) {
          vm.pushSettings.icon = vm.newIcon;
        }
        vm.pushSettings.setDefaultIcon = vm.pushSettings.icon === USER_FILES_URL + '/push-icons/default.png';

        firstValueFrom(pushSettingsModel.save(vm.pushSettings))
          .then(savePushSettingsSuccess)
          .catch(savePushSettingsError);
      }

      function savePushSettingsError(response) {
        if (response.meta.error == 'DomainAlreadyRegisteredError') {
          vm.apiDomainAlreadyRegistered = true;
        }
      }

      function savePushSettingsSuccess() {
        trackClickSave();
        toastr.success($translate.instant('pushSettings.toasts.pushSettingsSaveSuccess'));
      }
    }

    /**
     * Установка стандартной иконки
     */
    function setDefaultPushImage() {
      vm.pushSettings.icon = USER_FILES_URL + '/push-icons/default.png';
      vm.newIcon = null;
    }

    /**
     * Трек клика на изменение картинки
     */
    function trackClickChangeImage() {
      carrotquestHelper.track('Настройка Web Push - кликнул "Изменить картинку"', { app: vm.currentApp.name });
    }

    /**
     * Трек клика на кнопку 'Сохранить'
     */
    function trackClickSave() {
      carrotquestHelper.track('Настройка Web Push - кликнул "Сохранить"', { app: vm.currentApp.name });
    }

    /**
     * Трек клика на 'Вернуть стандартную'
     */
    function trackClickSetDefaultPushImage() {
      carrotquestHelper.track('Настройка Web Push - кликнул "Вернуть стандартную аватарку"', {
        app: vm.currentApp.name,
      });
    }
  }
})();

/**
 * Контроллер для модального окна отписки пользователя от писем
 * NOTE: эта модалка написана с рассчётом на то, что отписать можно несколько пользователей, но API пока что этого не позволяет
 */
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.unsubscribeFromEmails')
    .controller('UnsubscribeFromEmailsModalController', UnsubscribeFromEmailsModalController);

  function UnsubscribeFromEmailsModalController(
    $filter,
    $uibModal,
    $uibModalInstance,
    userModel,
    currentApp,
    users,
    usersCount,
  ) {
    var vm = this;

    vm.currentApp = currentApp;
    vm.isArray = angular.isArray;
    vm.isObject = angular.isObject;
    vm.unsubscribe = unsubscribe;
    vm.users = users;
    vm.usersCount = usersCount;

    /**
     * Отписка пользователей от писем
     */
    function unsubscribe() {
      // как сказано выше, отписать пока что можно только одного пользователя, поэтому берём первого
      firstValueFrom(userModel.unsubscribe(users[0].id)).then(unsubscribeSuccess);

      function unsubscribeSuccess() {
        $uibModalInstance.close();
      }
    }
  }
})();

<section id="content">
  <div class="container-fluid limited-container">
    <cq-permission-denied *ngIf="!accessToDevelopers.hasAccess"></cq-permission-denied>
    <div *ngIf="accessToDevelopers.hasAccess">
      <nav
        class="no-flex-shrink"
        aria-label="breadcrumb"
      >
        <ol class="breadcrumb">
          <li
            class="breadcrumb-item active"
            aria-current="page"
          >
            {{ 'toDevelopers.breadcrumbs.developers' | transloco }}
          </li>
        </ol>
      </nav>

      <!-- SDK -->
      <cq-firebase-key [currentApp]="currentApp"></cq-firebase-key>

      <!-- API-ключи -->
      <div class="card no-margin-bottom">
        <div class="card-body">
          <h2>{{ 'toDevelopers.apiCredentials.title' | transloco }}</h2>
          <div class="margin-bottom-20">
            <a
              target="_blank"
              [href]="'toDevelopers.apiCredentials.developerGuideLink.href' | transloco"
              >{{ 'toDevelopers.apiCredentials.developerGuideLink.text' | transloco }}</a
            >
          </div>
          <div class="margin-bottom-10">
            <label>{{ 'toDevelopers.apiCredentials.name' | transloco }}</label>
            <div>
              <code>{{ currentApp.name }}</code>
            </div>
          </div>
          <div class="margin-bottom-10">
            <label>{{ 'toDevelopers.apiCredentials.id' | transloco }}</label>
            <div>
              <code>{{ currentApp.id }}</code>
            </div>
          </div>
          <div class="margin-bottom-10">
            <label>{{ 'toDevelopers.apiCredentials.apiKey' | transloco }}</label>
            <div>
              <code>{{ apiCredentials.apiKey }}</code>
            </div>
          </div>
          <div class="margin-bottom-10">
            <label>{{ 'toDevelopers.apiCredentials.apiSecret' | transloco }}</label>
            <div>
              <code>{{ apiCredentials.apiSecret }}</code>
            </div>
          </div>
          <div>
            <label>{{ 'toDevelopers.apiCredentials.userAuthKey' | transloco }}</label>
            <div>
              <code>{{ apiCredentials.userAuthKey }}</code>
            </div>
          </div>
        </div>
        <table class="table table-sm table-align-middle">
          <thead>
            <tr>
              <th>{{ 'toDevelopers.apiCredentials.table.headings.token' | transloco }}</th>
              <th>{{ 'toDevelopers.apiCredentials.table.headings.created' | transloco }}</th>
              <th>{{ 'toDevelopers.apiCredentials.table.headings.permissions' | transloco }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let apiToken of tokens; trackBy: trackByApiTokenId">
              <td>{{ apiToken.token }}</td>
              <td>{{ apiToken.created | amDateFormat: 'LLL' }}</td>
              <td>{{ 'toDevelopers.apiCredentials.table.body.fullAccess' | transloco }}</td>
              <td class="shrink-by-content">
                <button
                  class="btn btn-borderless-primary"
                  type="button"
                  (click)="revokeApiToken(apiToken)"
                >
                  <i class="btn-icon cqi-sm cqi-times"></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="tokens.length === 0">
              <td
                class="text-center"
                colspan="4"
              >
                {{ 'toDevelopers.apiCredentials.table.body.zeroData' | transloco }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card-body text-center">
          <button
            class="btn btn-primary"
            type="button"
            (click)="generateApiToken()"
          >
            {{ 'toDevelopers.apiCredentials.createTokenButton' | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

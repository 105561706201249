<ng-container *transloco="let t; read: 'completionRegistrationModalComponent'">
  <div class="modal-header">
    <button
      class="close"
      (click)="closeModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body text-center">
    <img
      class="mb-30"
      src="assets/img/default/completion-registration/completion-registration.svg"
    />
    <h4 class="mb-10">{{ t('heading') }}</h4>
    <div [innerHTML]="t('description', {projectName})"></div>
  </div>

  <div class="modal-footer justify-center">
    <button
      class="btn btn-primary"
      (click)="closeModal()"
      type="button"
    >
      {{ t('button') }}
    </button>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./popup-footer-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupFooterBlockSettings', cqPopupFooterBlockSettings());

  function cqPopupFooterBlockSettings() {
    return {
      bindings: {
        currentApp: '=', // текущий app
        eventTypes: '=', // типы событий
        popupBlockParams: '=', // настройки блока поп-апа
        setErrorHandler: '&?', // функция для установки обработчика ошибок
        userProperties: '=', // свойства пользователя
      },
      controller: 'CqPopupFooterBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-footer-block-settings.html'),
    };
  }
})();

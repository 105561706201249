import { AUTO_REPLY_TYPE } from '../../../../app/http/app/app.constants';
import { TEAM_MEMBER_DEFAULT_AVATAR_URL } from '../../../../app/http/team-member/team-member.constants';
import { CHAT_THEMES_TYPES } from '../../../../app/http/chat-settings/chat-settings.constants';

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqChatPreviewController', CqChatPreviewController);

  function CqChatPreviewController(l10nHelper, USER_FILES_URL) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.AUTO_REPLY_TYPES = AUTO_REPLY_TYPE;
      vm.CHAT_THEMES_TYPES = CHAT_THEMES_TYPES;
      vm.disableLeftArrow = true;
      vm.disableRightArrow = false;
      vm.getUserAvatar = getUserAvatar;
      vm.hasNotDefaultAvatar = hasNotDefaultAvatar;
      vm.l10nHelper = l10nHelper;
      vm.setArrowsState = setArrowsState;
      vm.slide = slide;
      vm.TEAM_MEMBER_DEFAULT_AVATAR_URL = TEAM_MEMBER_DEFAULT_AVATAR_URL;
      vm.USER_FILES_URL = USER_FILES_URL;
    }

    /**
     * Меняет состояние стрелок на активное/задизейбленное
     * !!!: Копипаста из cqstatic, руками не менять
     */
    function setArrowsState(event) {
      const carousel = event.target;

      // Дизейблим кнопку "Влево" если скролл прижат к левому краю, и наоборот
      vm.disableLeftArrow = carousel.scrollLeft === 0;

      // Дизейблим кнопку "Вправо" если скролл прижат к правому  краю, и наоборот
      // NOTE: при использовании масштаба в браузере, пиксели становятся не целыми
      // поэтому приходится сравнивать не с 0, а с 1, да ещё и по модулю, т.к. выражение в скобках может быть отрицательным
      vm.disableRightArrow = Math.abs(carousel.scrollWidth - carousel.scrollLeft - carousel.offsetWidth) < 1;
    }

    /**
     * Получение аватарки в зависимости от того, на сколько ок смотрится цвет клиента и цвет иконки.
     * @return {string}
     */
    function getUserAvatar() {
      let avatar = `${vm.USER_FILES_URL}/avatars/${vm.avatar}`;
      if (!vm.doAccentAndWhiteMatchGood) {
        avatar = avatar.replace('default-v3', 'dark-v3');
      }
      return avatar;
    }

    /**
     * Проверка: содержится ли в массиве не стандартная аватаров
     *
     * @param {[]} admins - Массив администраторов
     * @returns {*}
     */
    function hasNotDefaultAvatar(admins) {
      const defaultAvatarName = 'default-v4.png';

      return admins.some((admin) => !admin.avatar.contains(defaultAvatarName));
    }

    /**
     * Перемещает слайды в карусельке
     * !!!: Копипаста из cqstatic, руками не менять
     *
     * @param {boolean} isBackDirection - Шаблон для кнопки "назад" (по умолчанию рендерится кнопка "вперед"
     */
    function slide(isBackDirection = false) {
      const carousel = angular.element('.svelte-carousel .item-container')[0];

      /**
       * Размер сдвига карусели
       * HACK: размер элемента внутри карусели 70px, поэтому на это значение и будем скроллить
       * Так пришлось сделать из-за того, что svelte не даёт получить доступ к DOM-элементу, если вешать bind:this на компонент
       * Из-за этого ограничения я не понял как получить ширину первого CarouselItem, поэтому пришлось захардкодить
       */
      let scrollBy = 70;

      // Если скролл немного недоскроллен до конца, доскроллим его
      if (!isBackDirection && carousel.scrollWidth - carousel.scrollLeft - carousel.offsetWidth < scrollBy * 1.5) {
        scrollBy = scrollBy * 1.5;
      }

      if (isBackDirection && carousel.scrollLeft < scrollBy * 1.5) {
        scrollBy = scrollBy * 1.5;
      }

      if (isBackDirection) {
        scrollBy = -scrollBy;
      }

      carousel.scrollBy({
        left: scrollBy,
        behavior: 'smooth',
      });
    }
  }
})();

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { map } from 'rxjs/operators';

import { PlanDto } from '@http/plan/plan.types';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';
import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

/** Http-сервис для работы с тарифным планом */
@Injectable({ providedIn: 'root' })
export class PlanHttpService {
  constructor(private readonly http: HttpClient, private readonly instanceFactory: InstanceFactory) {}

  get(appId: string, ignoreLoadingBar: boolean = false) {
    return this.http
      .get<unknown>(`/apps/${appId}/billing/plan`, {
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((response) => {
          return this.instanceFactory.getInstancePlan(response as PlanDto);
        }),
      );
  }
}

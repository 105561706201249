import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cq-message-preview-phone-bottom-wrapper',
  templateUrl: './message-preview-phone-bottom-wrapper.component.html',
  styleUrls: ['./message-preview-phone-bottom-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagePreviewPhoneBottomWrapperComponent {
  constructor() {}
}

export type SOCIAL_TYPE = 'vk' | 'facebook' | 'instagram' | 'telegram' | 'viber' | 'whatsapp';

export const DEFAULT_ORDER_SOCIAL_NETWORKS: SOCIAL_TYPE[] = [
  'vk',
  'facebook',
  'instagram',
  'telegram',
  'viber',
  'whatsapp',
];

<div class="modal-header">
  <h3 class="modal-title">{{ 'modals.autoMessageLoop.title' | transloco }}</h3>
  <button
    class="close"
    (click)="ngbActive.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body auto-message-loop margin-between-rows-20">
  <div class="text-center">
    <img
      class="loop-img"
      src="../../../../../assets/img/default/auto-message-loop/loop.svg"
    />
  </div>
  <div *ngIf="currentMessageIds.length === 1">{{ 'modals.autoMessageLoop.descriptions.forOne' | transloco }}</div>
  <div *ngIf="currentMessageIds.length >= 1">{{ 'modals.autoMessageLoop.descriptions.forMore' | transloco }}</div>
  <div>
    <a
      target="_blank"
      [attr.href]="'modals.autoMessageLoop.moreLoopLink.href' | transloco"
      >{{ 'modals.autoMessageLoop.moreLoopLink.text' | transloco }}
    </a>
  </div>
  <div
    *ngIf="currentMessageIds.length === 1"
    class="loop-map"
  >
    <div class="margin-bottom-20 text-center">{{ 'modals.autoMessageLoop.map' | transloco }}</div>
    <div class="padding-right-30 padding-left-30">
      <div
        class="loop-item position-relative margin-bottom-30 padding-15 text-center"
        *ngFor="let message of loops; first as first"
      >
        <span
          *ngIf="first"
          class="loop-arrow-right"
        ></span>
        <strong *ngIf="message.id === currentMessageIds[0]">{{ message.name }} </strong>
        <span *ngIf="message.id !== currentMessageIds[0]">{{ message.name }} </span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-outline-primary"
    (click)="trackClickDeactivate(); saveDeactivate()"
  >
    {{ saveDeactivateButtonText }}
  </button>
  <button
    class="btn btn-primary"
    (click)="trackClickActivate(); saveActivate()"
  >
    {{ saveActivateButtonText }}
  </button>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { MessageEditorSwitchModule } from '@panel/app/partials/message-editor/shared/message-editor-switch/message-editor-switch.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { TimeSelectorModule } from '../../shared/time-selector/time-selector.module';
import { MessageEditorSendingDelayComponent } from './message-editor-sending-delay.component';

@NgModule({
  declarations: [MessageEditorSendingDelayComponent],
  exports: [MessageEditorSendingDelayComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    SharedModule,
    NgbTooltipModule,
    TimeSelectorModule,
    ReactiveFormsModule,
    MessageEditorSwitchModule,
  ],
})
export class MessageEditorSendingDelayModule {}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl, Validators } from '@angular/forms';
import { Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeElements } from '@stripe/stripe-js';
import { BehaviorSubject } from 'rxjs';

import { StripeService } from '@panel/app/pages/subscription/general/services/stripe/stripe.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';

/** Компонент для ввода данных карты Stripe */
@Component({
  selector: 'cq-stripe-input',
  templateUrl: './stripe-input.component.html',
  styleUrls: ['./stripe-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeInputComponent extends AbstractCVAControl<string | null> implements AfterViewInit {
  /** Элемент, в который будет монтироваться Stripe */
  @ViewChild('stripeContainerRef', { static: false })
  stripeContainerRef!: ElementRef<HTMLElement>;

  /** Экземпляр CardElement */
  card!: StripeCardElement;

  /** Контрол, который нужен для показа ошибок валидации карты */
  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
    validators: [Validators.required],
  });

  /** Экземпляр StripeElements */
  elements!: StripeElements;

  /** Текст сообщения об ошибки валидации карты, который приходит от Stripe */
  errorMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /** Флаг готовности к использованию Stripe */
  isStipeElementsReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
    private readonly stripeService: StripeService,
  ) {
    super(ngControl, ngZone);
  }

  ngAfterViewInit(): void {
    this.stripeService.getStripeInstance().then((stripeInstance: Stripe) => {
      this.elements = this.stripeService.createElementsInstance(stripeInstance);
      this.card = this.stripeService.createCardElement(this.elements);
      this.stripeService.mountElement(this.card, this.stripeContainerRef?.nativeElement);

      this.card.on('ready', () => this.onReadyStripe());
      this.card.on('change', (event) => this.onChangeStripe(event));
    });
  }

  /** Обработчик изменений Stripe элементов */
  private onChangeStripe(event: StripeCardElementChangeEvent) {
    if (event.error) {
      this.errorMessage.next(event.error.message);
      this.control.setErrors({ isError: !!event.error });
    }

    if (event.empty) {
      this.control.setErrors({ empty: event.empty });
    }

    if (event.complete) {
      this.control.setValue('complete');
    }

    this.control.markAsTouched();
  }

  /** Обработчик на готовность Stripe элементов */
  private onReadyStripe() {
    this.isStipeElementsReady.next(true);
    this.card.focus();
  }
}

(function () {
  'use strict';

  require('./auto-event-name.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventName', cqAutoEventName());

  function cqAutoEventName() {
    return {
      bindings: {
        description: '=', // Описание
        name: '=', // Имя,
        paramsType: '=', // // Тип параметров
      },
      controller: 'CqAutoEventNameController',
      controllerAs: 'vm',
      template: require('./auto-event-name.html'),
    };
  }
})();

import { Container, Sprite, Text, TextStyle } from 'pixi.js';

import { getActionsBackgroundRect } from '@panel/app/pages/chat-bot/content/views/actions/shared-textures';
import {
  BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR,
  BOT_ACTION_BLOCK_LIGHTEN_PRIMARY_COLOR,
  BOT_WHITE_COLOR,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

import { ActionStyle, BaseActionABS, MAX_ACTION_WIDTH } from '../abstract';
import { getHiddenIcon } from './hidden-action.texture';

const ICON_PADDING_RIGHT = 10;
const RECT_PADDING = 12;

/**
 * Hidden action подразумевает действия EVENT, USER_TAG, PROPERTY, которые не отображаются клиентам,
 * но имеют идентичную структуру рендера, поэтому их можно объединить.
 */

export abstract class BotHiddenAction<BodyJson extends object = EMPTY_BODY_JSON> extends BaseActionABS<BodyJson> {
  protected style!: ActionStyle;

  protected get icon(): string {
    throw new Error(`Provide icon for ${this.constructor.name}`);
  }

  protected get translation(): string {
    throw new Error(`Provide translation for ${this.constructor.name}`);
  }

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);

    const textWithBg = this.render();
    if (textWithBg) {
      this.container.addChild(textWithBg);
    }
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 0,
        horizontal: 0,
      },
      border: {
        size: 0,
        radius: 10,
        color: BOT_WHITE_COLOR,
      },
      background: {
        color: BOT_ACTION_BLOCK_LIGHTEN_PRIMARY_COLOR,
      },
    };
  }

  redraw() {
    if (this.destroyed) {
      return;
    }
    this.container.removeChildren();
    const actionContainer = this.render();
    this.container.addChild(actionContainer);
  }

  render(): Container {
    const icon = getHiddenIcon(this.pixiApp.renderer, this.icon);
    const contentInsideMaxWidth = MAX_ACTION_WIDTH - RECT_PADDING * 2;
    const textMaxWidth = contentInsideMaxWidth - icon.width - ICON_PADDING_RIGHT;
    const fontSize = 14;
    const textOptions: Partial<TextStyle> = {
      wordWrapWidth: textMaxWidth,
      fontSize,
      lineHeight: fontSize * 1.4,
      fill: BOT_ACTION_BLOCK_DARKEN_PRIMARY_COLOR,
      align: 'left',
      fontStyle: 'normal',
      fontWeight: '400',
    };
    const text = renderCanvasText(this.translation, textOptions);
    const contentHeight = Math.max(icon.height, text.height);
    const rectHeight = contentHeight + RECT_PADDING * 2;
    const rect = getActionsBackgroundRect(rectHeight, this.style.background.color, this.pixiApp.renderer);
    rect.addChild(icon);
    rect.addChild(text);
    this.updateContentPositioning(rect, icon, text);
    return rect;
  }

  get width(): number {
    return this.container.width;
  }

  get height(): number {
    return this.container.height;
  }

  get renderReady(): boolean {
    return this.container.renderable && this.container.children.length > 0;
  }

  protected updateContentPositioning(rect: Container, icon: Sprite, textNode: Text): void {
    const iconX = RECT_PADDING;
    const iconY = (rect.height - icon.height) / 2;
    icon.position.set(iconX, iconY);
    const textX = RECT_PADDING + icon.width + ICON_PADDING_RIGHT;
    const textY = (rect.height - textNode.height) / 2;
    textNode.position.set(textX, textY);
  }
}

import { Inject, Injectable } from '@angular/core';
import { Container } from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { Subject } from 'rxjs';
import { UUID } from 'short-uuid';

import { TriggerChainStep } from '@http/trigger-chain/internal-types';
import { PIXI_VIEWPORT } from '@panel/app/pages/chat-bot/content/tokens';
import { CanvasBaseService } from '@panel/app/services/canvas/common/base/canvas-base.service';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { BlockViewFactory } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.factory';
import { BlockViewParser } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.parser';
import {
  BlockViewData,
  TriggerChainViewStates,
} from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.types';
import { ConnectionService } from '@panel/app/services/canvas/tirgger-chain/connections/connection.service';

type StoreChange = {
  view: IBlockView;
  type: 'add' | 'remove' | 'update';
};

/**
 * Сервис для работы с view шагов
 */
@Injectable()
export class BlockViewService {
  /** Store views */
  readonly store: IBlockView[] = [];

  private blockUpdateSubj: Subject<StoreChange> = new Subject();

  readonly blockChanges$ = this.blockUpdateSubj.asObservable();

  constructor(
    private readonly canvasBaseService: CanvasBaseService,
    private readonly blockViewFactory: BlockViewFactory,
    private readonly blockViewParser: BlockViewParser,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
  ) {}

  /**
   * Добавление view по шагу в триггерной цепочки
   */
  addViewByStep(step: TriggerChainStep, moveToViewportCorner: boolean = false): void {
    let view = this.blockViewFactory.create(step);

    this.addView(view);

    if (moveToViewportCorner) {
      this.moveViewToViewportCorner(view);
    }
  }

  /**
   * Возвращает view по шагу
   */
  getViewByUUID(uuid: UUID): IBlockView {
    let view = this.store.find((s) => s.uuid === uuid);
    if (!view) {
      throw new Error('Could not find a step');
    }

    return view;
  }

  /**
   * Обновляет view по шагу в триггерной цепочки
   */
  updateViewByStep(step: TriggerChainStep, states?: Partial<TriggerChainViewStates>): IBlockView {
    let view = this.store.find((v) => v.uuid === step.uuid);
    if (!view) {
      throw new Error('Could not find a stepView');
    }

    const parsedStates: TriggerChainViewStates = {
      selected: states?.selected ?? view.data.selected,
      contentValid: states?.contentValid ?? view.data.contentValid,
      hoveredValid: states?.hoveredValid ?? view.data.hoveredValid,
      incomingConnectionsValid: states?.incomingConnectionsValid ?? view.data.incomingConnectionsValid,
      connectionsValid: states?.connectionsValid ?? view.data.connectionsValid,
      hoveredInvalid: states?.hoveredInvalid ?? view.data.hoveredInvalid,
    };

    const data = this.blockViewParser.getParsedData(step, parsedStates);

    return this.updateView(view, data);
  }

  /**
   * Удаляет view по шагу в триггерной цепочки
   */
  removeViewByStep(step: TriggerChainStep): void {
    let view = this.store.find((v) => v.uuid === step.uuid);
    if (!view) {
      throw new Error('Could not find a stepView');
    }

    this.removeView(view);
  }

  private addView(view: IBlockView) {
    this.addViewOnViewport(view);

    this.scaleScalableContainerInBlock(view);

    this.addViewInStore(view);

    this.blockUpdateSubj.next({ view, type: 'add' });
  }

  private addViewInStore(view: IBlockView): void {
    this.store.push(view);
  }

  private addViewOnViewport(view: IBlockView): void {
    this.viewport.addChild(view.graphicContainer);
  }

  updateView(view: IBlockView, data: BlockViewData): IBlockView {
    view.data = data;

    view.rerender();

    this.scaleScalableContainerInBlock(view);

    this.blockUpdateSubj.next({ view, type: 'update' });

    return view;
  }

  private removeView(view: IBlockView): void {
    this.removeViewFromViewport(view);
    this.removeViewFromStore(view);

    this.blockUpdateSubj.next({ view, type: 'remove' });
  }

  private removeViewFromStore(view: IBlockView): void {
    this.store.splice(this.store.indexOf(view), 1);
  }

  private removeViewFromViewport(view: IBlockView): void {
    this.viewport.removeChild(view.graphicContainer);
    view.graphicContainer.destroy();
  }

  private moveViewToViewportCorner(view: IBlockView): void {
    view.graphicContainer.position.set(this.viewport.corner.x + 20, this.viewport.corner.y + 10);
  }

  moveViewNextToContainer(viewToMove: IBlockView, container: Container) {
    const containerPositionOnViewport = ConnectionService.getRelativePosition(container, this.viewport);

    viewToMove.graphicContainer.position.set(
      containerPositionOnViewport.x + container.width + 50,
      containerPositionOnViewport.y,
    );
    this.blockUpdateSubj.next({ view: viewToMove, type: 'update' });
  }

  scaleScalableContainerInBlock(view: IBlockView): void {
    view.scaleScalableContainer(this.canvasBaseService.scaleValue);
  }
}

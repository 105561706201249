import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmLeadNoteComponent } from './amocrm-lead-note.component';

@NgModule({
  declarations: [AmocrmLeadNoteComponent],
  exports: [AmocrmLeadNoteComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule, TranslocoModule],
})
export class AmocrmLeadNoteModule {}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { MESSAGE_PART_TYPE_ICON_CLASSES } from '@http/message-part/message-part.constants';
import { ReadyMessageTemplate } from '@http/ready-message-template/ready-message-template.types';

/**
 * Компонент для отображения карточки готового шаблона
 */
@Component({
  selector: 'cq-ready-message-template',
  templateUrl: './ready-message-template.component.html',
  styleUrls: ['./ready-message-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadyMessageTemplateComponent implements OnInit {
  /** Готовый шаблон */
  @Input({ required: true }) template!: ReadyMessageTemplate;

  /** Ссылка на скриншот с превью шаблона */
  previewSrc!: string;
  /** CSS-класс иконки, отображающей тип шаблона */
  templateTypeIconCssClass!: string;
  /** Текст с типом шаблона */
  templateTypeText!: string;

  constructor(private readonly translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.previewSrc = environment.userFilesUrl + '/ready_message_templates_preview/' + this.template.preview;
    //@ts-ignore
    this.templateTypeIconCssClass = MESSAGE_PART_TYPE_ICON_CLASSES[this.template.type];
    this.templateTypeText = this.translocoService.translate(`models.message.messagePartTypes.${this.template.type}`);
  }
}

(function () {
  'use strict';

  require('./edit.controller');

  angular.module('myApp.chatBot.facebookBot').component('cqFacebookBotEdit', cqFacebookBotEdit());

  function cqFacebookBotEdit() {
    return {
      bindings: {
        billingInfo: '=', // Информация о биллинге
        channels: '=', // Каналы
        currentApp: '=', // Текущее приложение
        dataAboutActiveFacebookBots: '=', // Информация об активных Facebook ботах
        dataAboutActiveLeadBots: '=', // Информация об активных Lead ботах
        dataAboutActiveWelcomeBots: '=', // Информация об активных Welcome ботах
        djangoUser: '=', // Текущий пользователь
        emailNotificationIntegrationsExternal: '=', // Интеграции типа "Уведомление на Email" в внешнем формате
        amocrmIntegrationsExternal: '=', // Интеграции типа AmoCRM
        properties: '=', // Список свойств и типов событий
        facebookBot: '=',
        tags: '=', // Теги пользователей
        teamMembers: '=', // члены команды
      },
      controller: 'cqFacebookBotEditController',
      controllerAs: 'vm',
      template: require('./edit.html'),
    };
  }
})();

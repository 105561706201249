<ng-container *transloco="let t">
  <label class="margin-bottom-10">{{ t('visibilitySettings.heading') }}</label>
  <ng-select
    [formControl]="visibilityFormControl"
    [searchable]="false"
    [clearable]="false"
    (change)="updateModeSettingsFormGroup($event)"
  >
    <ng-template
      ng-label-tmp
      let-mode="item"
    >
      <div class="flex align-items-center">
        <i [className]="getIconClassName(mode)"></i>
        <span>{{ t('visibilitySettings.modeSelect.' + mode + '.heading') }}</span>
      </div>
    </ng-template>
    <ng-option
      [value]="mode"
      *ngFor="let mode of visibilityModes"
    >
      <div class="flex">
        <div>
          <i [className]="getIconClassName(mode)"></i>
        </div>
        <div>
          <div class="text-truncate">{{ t('visibilitySettings.modeSelect.' + mode + '.heading') }}</div>
          <div class="text-truncate small text-secondary">
            {{ t('visibilitySettings.modeSelect.' + mode + '.description') }}
          </div>
        </div>
      </div>
    </ng-option>
  </ng-select>
  <div
    class="text-truncate form-text text-secondary"
    transloco="visibilitySettings.modeSelect.{{ mode$ | async }}.description"
  ></div>
  <div
    class="d-none"
    [class.d-block]="isAdvancedBlockVisible"
  >
    <ng-container *ngTemplateOutlet="advancedSettingsPerDevice; context: { $implicit: 'desktop' }"></ng-container>
    <ng-container *ngTemplateOutlet="advancedSettingsPerDevice; context: { $implicit: 'mobile' }"></ng-container>
  </div>

  <ng-template
    #advancedSettingsPerDevice
    let-device
  >
    <div class="margin-top-20">
      <i class="cqi-sm cqi-{{ device }}"></i>
      <label>{{ t('visibilitySettings.advanced.' + device + '.heading') }}</label>
    </div>
    <div class="text-truncate text-secondary margin-bottom-10">
      {{ t('visibilitySettings.advanced.' + device + '.description') }}
    </div>
    <div
      *ngIf="modeSettingsFormGroup"
      [formGroup]="modeSettingsFormGroup"
    >
      <ng-select
        formControlName="{{ device }}"
        [searchable]="false"
        [clearable]="false"
        (change)="onDeviceVisibilitySelect(device)"
      >
        <ng-option
          *ngFor="let option of visibilityOptions"
          [value]="option"
        >
          {{ t('visibilitySettings.advanced.selector.' + option) }}
        </ng-option>
      </ng-select>
      <cq-url-filter-configurator
        *ngIf="isAdvancedWithUrlFilters(device)"
        [formArray]="getFiltersByDeviceType(device)!"
        [options]="urlFilterConfiguratorOptions"
        [filtersMaxCount]="20"
      ></cq-url-filter-configurator>
    </div>
  </ng-template>
</ng-container>

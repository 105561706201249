/**
 * Компонент превью поп-апа
 */
(function () {
  'use strict';

  require('./popup-preview.controller');

  angular.module('myApp.popupEditor').component('cqPopupPreview', cqPopupPreview());

  function cqPopupPreview() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        currentPopupBlock: '=', // текущий выделенный блок
        isPreviewShown: '=', // показывается ли превью поп-апа в даннный момент
        locale: '=', // язык чатика
        onPopupBlockClick: '&', // колбэк на клик на блок внутри превью
        popup: '=popupData', // поп-ап
        popupType: '@', // тип поп-апа
        privacyPolicyUrl: '<', // ссылка на условия обработки данных
        showDataCollectionConsent: '<', // показывается ли согласие на сбор данных
        showDataCollectionConsentWithCheckbox: '<', // показывается ли чекбокс в согласии на сбор данных
        showPoweredBy: '=', // показывается ли надпись "Мы используем..."
      },
      controller: 'CqPopupPreviewController',
      controllerAs: 'vm',
      template: require('./popup-preview.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';

import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { DaterangepickerFormGroup } from '@panel/app/shared/components/datetime-picker/types/daterangepicker-form-group.type';
import { DaterangepickerOptions } from '@panel/app/shared/components/datetime-picker/types/daterangepicker-options.type';

import { Daterange } from '../../types/daterange.type';

@Component({
  selector: 'cq-daterangepicker-input',
  templateUrl: './daterangepicker-input.component.html',
  styleUrls: ['./daterangepicker-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaterangepickerInputComponent
  extends AbsCVAFormGroupBasedComponent<DaterangepickerFormGroup>
  implements OnInit
{
  @Input()
  options: DaterangepickerOptions | null = null;

  @ViewChild(NgbPopover)
  ngbPopover: NgbPopover | null = null;

  get popover(): NgbPopover {
    if (this.ngbPopover === null) {
      throw new Error('Popover is null');
    }

    return this.ngbPopover;
  }

  readonly control: FormGroup<DaterangepickerFormGroup> = this.fb.group<DaterangepickerFormGroup>({
    from: this.fb.control(moment()),
    to: this.fb.control(moment().add(1, 'month').startOf('month')),
  });

  selectedRange: Daterange = {
    from: moment(),
    to: moment().add(1, 'month').startOf('month'),
  };

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    let dateForm = this.control.controls.from.getRawValue();
    let dateTo = this.control.controls.from.getRawValue();

    this.control.controls.from.setValue(dateForm && dateForm.startOf('day'));
    this.control.controls.to.setValue(dateTo && dateTo.startOf('day'));
  }

  getDisplayDate(): string {
    let format = (this.options && this.options.format) ?? 'L';
    let dateForm = this.control.controls.from.getRawValue() ?? moment();
    let dateTo = this.control.controls.to.getRawValue() ?? moment();

    return dateForm.format(format) + ' - ' + dateTo.format(format);
  }

  onClickApplyButton(): void {
    this.control.controls.from.setValue(this.selectedRange.from);
    this.control.controls.to.setValue(this.selectedRange.to);

    this.popover.close();
  }

  onClickCancelButton(): void {
    this.popover.close();
  }

  onSelectDate(): void {
    if (this.options && this.options.showApplyButtons) {
      return;
    }

    this.control.controls.from.setValue(this.selectedRange.from);
    this.control.controls.to.setValue(this.selectedRange.to);

    this.popover.close();
  }
}

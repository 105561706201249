import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import isBoolean from 'lodash-es/isBoolean';

/**
 * Компонент для работы со значением фичи в сравнении тарифных планов
 */

@Component({
  selector: 'cq-feature-value[value]',
  templateUrl: './feature-value.component.html',
  styleUrls: ['./feature-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureValueComponent {
  /** Значение фичи */
  private _value: boolean | number | string = false;

  @Input()
  set value(value: boolean | number | string) {
    this._value = value;

    if (isBoolean(value)) {
      this.isBooleanValue = true;
    }
  }

  get value(): boolean | number | string {
    return this._value;
  }

  /** Является ли значение типа boolean */
  isBooleanValue: boolean = false;
}

/**
 * Директива для валидации даты в формате moment
 */
(function () {
  'use strict';

  angular.module('myApp.directives.time').directive('cqMaxTime', cqMaxTime);

  function cqMaxTime() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var maxTime;

      scope.$watch(getEvaluatedMaxTimeValue, watcher);
      controller.$validators.maxTime = maxTimeValidator;

      /**
       * Получение объекта moment из атрибута cq-max-time
       *
       * @returns {moment|undefined}
       */
      function getEvaluatedMaxTimeValue() {
        return scope.$eval(iAttrs.cqMaxTime);
      }

      /**
       * Проверка равенства значений модели и cqEqual
       * NOTE: считается, что в ngModel и cqMaxTime передан объект moment, поэтому преобразований внутри валидатора не выполняется. Это сделано для того, чтобы валидатор отрабатывал максимально быстро, не тратя время на создание объектов moment
       *
       * @param modelValue
       * @return {Boolean}
       */
      function maxTimeValidator(modelValue) {
        var parsedModelValue = modelValue && modelValue.hasOwnProperty('endDate') ? modelValue.endDate : modelValue;

        if (controller.$isEmpty(parsedModelValue) || !maxTime) {
          return true;
        } else {
          return parsedModelValue.isBefore(maxTime);
        }
      }

      /**
       * Вызывается при изменении moment в атрибуте cq-max-time
       *
       * @param {String} newValue Значение атрибута maxHeight
       */
      function watcher(newValue) {
        maxTime = newValue;

        // нужно вызывать валидацию принудительно, т.к. $validators могут отработать раньше $observe
        controller.$validate();
      }
    }
  }
})();

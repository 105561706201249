import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TriggerChainGeneralStatisticsTableItemModule } from '@panel/app/pages/trigger-chains/general-statistics/components/table/components/trigger-chain-general-statistics-table-item/trigger-chain-general-statistics-table-item.module';
import { TriggerChainGeneralStatisticsStateService } from '@panel/app/pages/trigger-chains/general-statistics/trigger-chain-general-statistics-state.service';

import { TriggerChainGeneralStatisticsTableComponent } from './trigger-chain-general-statistics-table.component';

@NgModule({
  declarations: [TriggerChainGeneralStatisticsTableComponent],
  exports: [TriggerChainGeneralStatisticsTableComponent],
  imports: [CommonModule, TranslocoModule, TriggerChainGeneralStatisticsTableItemModule],
  providers: [TriggerChainGeneralStatisticsStateService],
})
export class TriggerChainGeneralStatisticsTableModule {}

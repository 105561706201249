import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { ConversationStatisticChannelSelectComponent } from './conversation-statistic-channel-select.component';

@NgModule({
  declarations: [ConversationStatisticChannelSelectComponent],
  imports: [
    NgSelectModule,
    FormsModule,
    CommonModule,
    NgbTooltipModule,
    TranslocoModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  exports: [ConversationStatisticChannelSelectComponent],
})
export class ConversationStatisticChannelSelectModule {}

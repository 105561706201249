/** Типы ботов в диалогах */
export enum CONVERSATION_ASSISTANT_TYPES {
  CHAT_GPT = 'chat_gpt',
  DIALOGFLOW = 'dialogflow',
  FACEBOOK_BOT = 'facebook_bot',
  YANDEX_AI = 'yandex_ai',
  LEAD_BOT = 'lead_bot',
  ROUTING_BOT = 'routing_bot',
  TELEGRAM_BOT = 'telegram_bot',
}

/** Типы получателей */
export enum CONVERSATION_RECIPIENTS_TYPES {
  ADMIN = 'admin',
  USER = 'user',
}

/** Типы сообщений, которые инициируют диалог */
export enum CONVERSATION_TYPES {
  /** Большой блочный поп-ап */
  BLOCK_POPUP_BIG = 'block_popup_big',
  /** Маленький блочный поп-ап */
  BLOCK_POPUP_SMALL = 'block_popup_small',
  /** Письмо на почту пользователю */
  EMAIL = 'email',
  /** Диалог инициирован пользователем через его письмо на почту */
  INCOMING_EMAIL = 'incoming_email',
  /** Лид-бот */
  LEAD_BOT = 'lead_bot',
  /** Большой поп-ап */
  POPUP_BIG = 'popup_big',
  /** Этот тип используется не только при отправке ручного/триггерного сообщения в чат, но и если диалог инициировал пользователь */
  POPUP_CHAT = 'popup_chat',
  /** Маленький поп-ап */
  POPUP_SMALL = 'popup_small',
  /** Web-push */
  PUSH = 'push',
  /** Роутинг-бот */
  ROUTING_BOT = 'routing_bot',
  /** Push-уведомление в SDK */
  SDK_PUSH = 'sdk_push',
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import cloneDeep from 'lodash-es/cloneDeep';
import { CookieService } from 'ngx-cookie-service';
import { map, mergeMap, Observable } from 'rxjs';

import { APIResponse } from '@http/types';
import { CaseStyleHelper } from '@panel/app/services';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

import { MESSAGE_SENDER_DEFAULT_AVATAR_URL } from './message-sender.constants';

@Injectable({
  providedIn: 'root',
})
export class MessageSenderModel {
  /**
   * Отправитель по умолчанию
   *
   * @type {Object}
   */
  DEFAULT_MESSAGE_SENDER = {
    avatar: MESSAGE_SENDER_DEFAULT_AVATAR_URL, // URL аватарки
    emailName: '', // адрес почты в рассылках
    isDefault: false, // является ли отправитель отправителем, который вставляется в рассылки по умолчанию (при создании рассылки)
    isRemoved: false, // TODO: пока не понятно что это, спросить у Серёги
    name: '', // имя отправителя
    setDefaultAvatar: false, // флаг возвращения аватарки по умолчанию
  };

  NO_REPLY_EMAIL_NAME = 'noreply';

  constructor(
    protected readonly caseStyleHelper: CaseStyleHelper,
    protected readonly cookieService: CookieService,
    protected readonly httpClient: HttpClient,
    protected readonly translocoService: TranslocoService,
  ) {}

  /**
   * Конвертация члена команды в отправителя
   */
  convertFromTeamMember(teamMember: any): any {
    let messageSender = this.getDefault();

    messageSender.avatar = teamMember.avatar;
    messageSender.emailName = teamMember.emailFrom;
    messageSender.name = teamMember.name;

    if (teamMember.id) {
      messageSender.id = teamMember.id;
    }

    return messageSender;
  }

  /**
   * Создание отправителя
   */
  create(appId: any, messageSender: any): Observable<any> {
    let params = this.parseToServerFormat(messageSender);

    return this.httpClient
      .post(`/apps/${appId}/messagesenders`, params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        mergeMap((response: any) => {
          return this.get(response.data.id);
        }),
      );
  }

  /**
   * Получение получателя по ID
   */
  get(messageSenderId: any): Observable<APIResponse<any>> {
    return this.httpClient
      .get<APIResponse<any>>(`/apps/message_senders/${messageSenderId}`, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          return response.data;
        }),
      );
  }

  /**
   * Получение отправителя бота
   */
  getBotSender(): Observable<any> {
    return this.httpClient
      .get('/apps/message_senders/default_chat_bot', {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          return response.data;
        }),
      );
  }

  /**
   * Получение отправителя по умолчанию
   */
  getDefault(): any {
    return cloneDeep(this.DEFAULT_MESSAGE_SENDER);
  }

  /**
   * Получение списка отправителе�� для текущего сайта
   */
  getList(appId: any, ignoreLoadingBar: any): Observable<any> {
    return this.httpClient
      .get(`/apps/${appId}/messagesenders`, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((response: any) => {
          return response.data.senders;
        }),
      );
  }

  /**
   * Получение отправителя для писем, на которые ненужно отвечать (noreply)
   */
  getNoReply(appLanguage: any): any {
    let noReplyMessageSender = cloneDeep(this.DEFAULT_MESSAGE_SENDER);

    noReplyMessageSender.emailName = this.NO_REPLY_EMAIL_NAME;
    noReplyMessageSender.name = this.translocoService.translate(
      'models.messageSender.noReplySenderName.' + appLanguage,
    );

    return noReplyMessageSender;
  }

  /**
   * Парсинг отправителя
   */
  parse(messageSender: any): void {
    this.caseStyleHelper.keysToCamelCase(messageSender);
  }

  /**
   * Парсинг отправителя в формат сервера
   */
  parseToServerFormat(messageSender: any): any {
    let parsedMessageSender = cloneDeep(messageSender);

    if (parsedMessageSender.avatar instanceof File) {
      parsedMessageSender.avatar = messageSender.avatar;
      delete parsedMessageSender.setDefaultAvatar;
    } else if (parsedMessageSender.setDefaultAvatar) {
      delete parsedMessageSender.avatar;
    } else {
      delete parsedMessageSender.avatar;
      delete parsedMessageSender.setDefaultAvatar;
    }

    this.caseStyleHelper.keysToUnderscore(parsedMessageSender);

    return parsedMessageSender;
  }

  /**
   * Удаление получателя
   */
  remove(messageSenderId: any): Observable<any> {
    return this.httpClient
      .delete(`/apps/messagesenders/${messageSenderId}`, {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
      })
      .pipe(map(() => messageSenderId));
  }

  /**
   * Сохранение получателя
   */
  save(messageSender: any): Observable<any> {
    let body: any = this.parseToServerFormat(messageSender);

    return this.httpClient
      .patch(`/apps/message_senders/${messageSender.id}`, body, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        mergeMap((response: any) => {
          return this.get(messageSender.id);
        }),
      );
  }

  /**
   * Установка отправителя по умолчанию по его ID
   */
  setAsDefault(messageSenderId: any): Observable<any> {
    let messageSenderFields = {
      id: messageSenderId,
      isDefault: true,
    };

    return this.save(messageSenderFields);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { FirstPaymentDiscountLabelModule } from '@panel/app/pages/subscription/general/first-payment-discount-label/first-payment-discount-label.module';
import { Stripe3dsModalModule } from '@panel/app/pages/subscription/modals/stripe-3ds-modal/stripe-3ds-modal.module';
import { StripePayModalComponent } from '@panel/app/pages/subscription/modals/stripe-pay-modal/stripe-pay-modal.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [StripePayModalComponent],
  exports: [StripePayModalComponent],
  imports: [
    CommonModule,
    Stripe3dsModalModule,
    TranslocoModule,
    SharedModule,
    ReactiveFormsModule,
    FirstPaymentDiscountLabelModule,
  ],
})
export class StripePayModalModule {}

(function () {
  'use strict';

  require('./popup-video-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupVideoBlockSettings', cqPopupVideoBlockSettings());

  function cqPopupVideoBlockSettings() {
    return {
      bindings: {
        popupBlockParams: '=', // настройки блока поп-апа
      },
      controller: 'CqPopupVideoBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-video-block-settings.html'),
    };
  }
})();

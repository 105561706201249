import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';

import { LinkEditorModalModule } from '@panel/app/pages/chat-bot/content/modals/link-editor/link-editor-modal.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { QuillTextEditorComponent } from './quill-text-editor.component';

@NgModule({
  declarations: [QuillTextEditorComponent],
  imports: [
    CommonModule,
    SharedModule,
    QuillModule.forRoot(),
    FormsModule,
    NgbCollapseModule,
    NgbTooltipModule,
    TranslocoModule,
    LinkEditorModalModule,
  ],
  exports: [QuillTextEditorComponent],
})
export class QuillTextEditorModule {}

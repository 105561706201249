import { Injectable } from '@angular/core';
import { Graphics } from 'pixi.js';

import { GeometryStyle } from '@panel/app/shared/services/pixi/geometry/pixi-geometry.type';

/**
 * Утилитки для рисования простой геометрии инструментами pixi.js
 */
@Injectable()
export class PixiGeometryService {
  static renderCircle(radius: number, style: GeometryStyle, existedGraphicObject?: Graphics) {
    const graphics = new Graphics();
    graphics.beginFill(style.backgroundColor);
    graphics.drawCircle(radius, radius, radius);
    graphics.endFill();
    return graphics;
  }

  static renderRectangle(width: number, height: number, style: GeometryStyle = {}, existedGraphicObject?: Graphics) {
    const graphics = existedGraphicObject ? existedGraphicObject : new Graphics();
    graphics.lineStyle(style.border?.size ?? 0, style.border?.color, style.border?.alpha);
    graphics.beginFill(style.backgroundColor, style.alpha);
    if (style.border?.radius) {
      graphics.drawRoundedRect(0, 0, width, height, style.border.radius);
    } else {
      graphics.drawRect(0, 0, width, height);
    }
    graphics.endFill();
    return graphics;
  }
}

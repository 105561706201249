import { IPromise } from 'angular';

import { ConversationTagHelpService } from '@panel/app-old/shared/entities/conversation-tag/help/conversation-tag-help.service';
import { ConversationTagDto } from '@panel/app-old/shared/entities/conversation-tag/types/conversation-tag-dto.types';

/** Класс для работы с тегом диалога */
export class ConversationTag {
  /** Текст тега */
  text: string;

  /** Возвращает dto-формат */
  get dto(): ConversationTagDto {
    return {
      text: this.text,
    };
  }

  constructor(private readonly conversationTagHelpService: ConversationTagHelpService, dto: ConversationTagDto) {
    this.text = dto.text;
  }

  /** Создаёт тег */
  create(): IPromise<void> {
    return this.conversationTagHelpService.createTag(this);
  }

  /** Удаляет тег */
  remove(): IPromise<void> {
    return this.conversationTagHelpService.removeTag(this);
  }

  /** Запускает процесс удаления тега */
  startRemoveProcess(): void {
    this.conversationTagHelpService.startRemoveTagProcess(this);
  }

  /** Останавливает процесс создания тега */
  stopCreateProcess(): void {
    this.conversationTagHelpService.stopCreateTagProcess();
  }
}

(function () {
  'use strict';

  require('./save-ready-message-template-as-template.controller');

  angular
    .module('myApp.modals.saveReadyMessageTemplateAsTemplateModal')
    .component('cqSaveReadyMessageTemplateAsTemplateModal', cqSaveReadyMessageTemplateAsTemplateModal());

  function cqSaveReadyMessageTemplateAsTemplateModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // {Object} currentApp - текущее приложение
        // {Array} directories - Список Папок
        // {Object} readyMessageTemplate - Готовый шаблон
      },
      controller: 'CqSaveReadyMessageTemplateAsTemplateModalController',
      controllerAs: 'vm',
      template: require('./save-ready-message-template-as-template.html'),
    };
  }
})();

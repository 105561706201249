import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TriggerChainAutomessageEditorComponent } from './trigger-chain-automessage-editor.component';

@NgModule({
  declarations: [TriggerChainAutomessageEditorComponent],
  exports: [TriggerChainAutomessageEditorComponent],
  imports: [CommonModule],
})
export class TriggerChainAutomessageEditorModule {}

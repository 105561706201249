<ng-container *transloco="let t; read: 'emailOperationSelectComponent'">
  <ng-select
    [clearable]="false"
    [ngModel]="chosenEmailOperation"
    (ngModelChange)="setCorrectFilter($event)"
    [items]="OPERATIONS"
    [searchable]="false"
  >
    <ng-template
      ng-label-tmp
      ng-option-tmp
      let-item="item"
    >
      {{ getTranslationPath(item) | transloco }}
    </ng-template>
  </ng-select>
</ng-container>

/**
 * Директива, используемая для ограничения минимальной высоты загружаемого изображения в пикселях
 */
(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('cqImageMaxHeight', cqImageMaxHeight);

  function cqImageMaxHeight($q, fileHelper) {
    return {
      restrict: 'A',
      require: ['ngModel', 'cqFile'],
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var maxHeight;
      var ngModelController = controller[0];

      if (ngModelController) {
        iAttrs.$observe('cqImageMaxHeight', observe);
        ngModelController.$asyncValidators.maxHeight = maxHeightValidator;
      }

      /**
       * Возвращает высоту изображения
       *
       * @param {Object} imageSize - Размеры изображения
       */
      function getImageHeight(imageSize) {
        var deferred = $q.defer();

        if (maxHeight && imageSize.height > maxHeight) {
          deferred.reject();
        } else {
          deferred.resolve();
        }

        return deferred.promise;
      }

      /**
       * Валидатор, проверяющий соответствие минимальной допустимую высоту и высоту загружаемого изображения
       *
       * @param viewValue
       *
       * @returns {Boolean}
       */
      function maxHeightValidator(viewValue) {
        if (ngModelController.$isEmpty(viewValue)) {
          return $q.resolve();
        } else {
          return fileHelper.getImageSize(viewValue).then(getImageHeight);
        }
      }

      /**
       * Возвращает значение атрибута maxHeight
       *
       * @param {String} newValue - Значение атрибута maxHeight
       */
      function observe(newValue) {
        maxHeight = newValue;

        // нужно вызывать валидацию принудительно, т.к. $validators могут отработать раньше $observe
        ngModelController.$validate();
      }
    }
  }
})();

<ng-container>
  <button
    *ngFor="let questionNumber of questionNumbers"
    (click)="changeQuestionNumber(questionNumber)"
    [disabled]="cacheLastAnsweredQuestionNumber < questionNumber"
    [ngClass]="{ active: currentQuestionNumber === questionNumber }"
    class="btn btn-outline-primary mx-5"
    type="button"
  >
    {{ questionNumber }}
  </button>
</ng-container>

(function () {
  'use strict';

  require('./chatbot-and-popup-data-collection-settings.controller');

  angular
    .module('myApp.privacySettings')
    .component('cqChatbotAndPopupDataCollectionSettings', cqChatbotAndPopupDataCollectionSettings());

  function cqChatbotAndPopupDataCollectionSettings() {
    return {
      bindings: {
        currentApp: '=', // приложение
        settings: '=', // настройки обработки персональных данных
      },
      controller: 'CqChatbotAndPopupDataCollectionSettingsController',
      controllerAs: 'vm',
      template: require('./chatbot-and-popup-data-collection-settings.html'),
    };
  }
})();

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { MessagePreviewScreenshot } from '@panel/app/services/message-preview-screenshot/message-preview-screenshot.types';

@Injectable({
  providedIn: 'root',
})
export class MessagePreviewScreenshotService {
  screenshotComplete$: Subject<MessagePreviewScreenshot> = new Subject();
  screenshotRequest$: Subject<void> = new Subject();
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-assignment-type-control',
  templateUrl: './assignment-type-control.component.html',
  styleUrls: ['./assignment-type-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignmentTypeControlComponent extends AbsCVAFormControlBasedComponent<number> {
  readonly control: FormControl<number> = new FormControl<number>(1, {
    nonNullable: true,
  });

  constructor() {
    super();
  }
}

/**
 * Директива для превью автосообщений
 */
(function () {
  'use strict';

  require('./message-preview.controller');
  require('./message-templates/email-ai.html');
  require('./message-templates/email-ai.wrapper.html');
  require('./message-templates/email-ai.wrapper-external.html');
  require('./message-templates/email-bee.html');
  require('./message-templates/email-bee.wrapper.html');
  require('./message-templates/email-bee.wrapper-external.html');
  require('./message-templates/email-default.html');
  require('./message-templates/email-default.wrapper.html');
  require('./message-templates/email-default.wrapper-external.html');
  require('./message-templates/email-html.html');
  require('./message-templates/email-html.wrapper.html');
  require('./message-templates/email-html.wrapper-external.html');
  require('./message-templates/email-lightweight.html');
  require('./message-templates/email-lightweight.wrapper.html');
  require('./message-templates/email-lightweight.wrapper-external.html');
  require('./message-templates/popup-big.html');
  require('./message-templates/popup-big.wrapper.html');
  require('./message-templates/popup-big.wrapper-external.html');
  require('./message-templates/popup-chat.html');
  require('./message-templates/popup-chat.wrapper.html');
  require('./message-templates/popup-chat.wrapper-external.html');
  require('./message-templates/popup-small.html');
  require('./message-templates/popup-small.wrapper.html');
  require('./message-templates/popup-small.wrapper-external.html');
  require('./message-templates/push.html');
  require('./message-templates/push.wrapper.html');
  require('./message-templates/push.wrapper-external.html');
  require('./message-templates/run-script.html');
  require('./message-templates/run-script.wrapper.html');
  require('./message-templates/sdk-popup-chat.wrapper.html');
  require('./message-templates/sdk-popup-chat.wrapper-external.html');
  require('./message-templates/sdk-push.html');
  require('./message-templates/sdk-push.wrapper.html');
  require('./message-templates/sdk-push.wrapper-external.html');
  require('./message-templates/telegram.html');
  require('./message-templates/telegram.wrapper.html');
  require('./message-templates/telegram.wrapper-external.html');
  require('./message-templates/webhook.html');
  require('./message-templates/webhook.wrapper.html');

  angular.module('myApp.messagePreview').directive('cqMessagePreviewAjs', cqMessagePreview);

  function cqMessagePreview() {
    return {
      restrict: 'E',
      require: 'cqMessagePreviewAjs',
      scope: {
        content: '=',
        currentApp: '=?',
        emailSubject: '@?',
        isExternal: '=?', // Флаг, говорящий, что превью используется для скринов шаблонов
        language: '=',
        messagePartType: '@',
        messageSender: '=?',
        parameters: '=',
        typeOs: '=', // Тип OS для отображения нужного preview
      },
      controller: 'CqMessagePreviewController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
      template: require('./message-preview.html'),
    };

    function link(scope, iElement, iAttrs, controller) {
      // при получении/смене типа сообщения необходимо получить контент этого сообщения
      iAttrs.$observe('messagePartType', function (interpolatedValue) {
        controller.getContent(interpolatedValue);
      });
    }
  }
})();

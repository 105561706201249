import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';

/** Интеграции, которые можно настроить без установки кода */
export const INTEGRATIONS_HEADLINERS = {
  RU: [
    INTEGRATION_TYPES.TELEGRAM,
    INTEGRATION_TYPES.VK,
    INTEGRATION_TYPES.INSTAGRAM,
    INTEGRATION_TYPES.FACEBOOK,
    INTEGRATION_TYPES.VIBER,
  ],
  US: [INTEGRATION_TYPES.FACEBOOK, INTEGRATION_TYPES.INSTAGRAM, INTEGRATION_TYPES.TELEGRAM],
};

import { Injectable } from '@angular/core';
import { NgbDate, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';

import { DATERANGE } from '@panel/app/shared/components/datetime-picker/constants/daterange.constant';
import { Daterange } from '@panel/app/shared/components/datetime-picker/types/daterange.type';

@Injectable({
  providedIn: 'root',
})
export class DatetimePickerService {
  constructor(private readonly ngbDateAdapter: NgbDateAdapter<moment.Moment>) {}

  fromMomentToNgb(momentDate: moment.Moment): NgbDateStruct {
    let dateFromModel: NgbDateStruct | null = this.ngbDateAdapter.fromModel(momentDate);
    if (dateFromModel === null) {
      throw new Error('Date from modal is null');
    }

    return dateFromModel;
  }

  fromNgbToMoment(ngbDate: NgbDate | NgbDateStruct): moment.Moment {
    let dateFromModel: moment.Moment | null = this.ngbDateAdapter.toModel(ngbDate);
    if (dateFromModel === null) {
      throw new Error('Date from modal is null');
    }

    return dateFromModel;
  }

  getPreparedRange(range: DATERANGE): Daterange {
    switch (range) {
      case DATERANGE.LAST_7_DAYS:
        return {
          from: moment().subtract(7, 'day').startOf('day'),
          to: moment().startOf('day'),
        };
      case DATERANGE.LAST_MONTH:
        return {
          from: moment().subtract(1, 'month').startOf('day'),
          to: moment().startOf('day'),
        };
      case DATERANGE.PAST_MONTH:
        return {
          from: moment().startOf('month').subtract(1, 'day').startOf('month').startOf('day'),
          to: moment().startOf('month').subtract(1, 'day').startOf('day'),
        };
      case DATERANGE.THIS_MONTH:
        return {
          from: moment().startOf('month').startOf('day'),
          to: moment().startOf('day'),
        };
      case DATERANGE.TODAY:
        return {
          from: moment().startOf('day'),
          to: moment().startOf('day'),
        };
      case DATERANGE.YESTERDAY:
        return {
          from: moment().subtract(1, 'day').startOf('day'),
          to: moment().subtract(1, 'day').startOf('day'),
        };
      default:
        throw new Error('There is not handled case');
    }
  }
}

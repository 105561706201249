import { Pipe, PipeTransform } from '@angular/core';
import accounting from 'accounting';

@Pipe({
  name: 'accountingPercent',
})
export class AccountingPercent implements PipeTransform {
  constructor() {}

  transform(value: any, defaultValue: any = 0): string {
    let parsedValue = this.handleIncorrectValue(parseFloat(value));
    defaultValue = defaultValue !== undefined ? this.handleIncorrectValue(parseFloat(defaultValue)) : 0;

    if (parsedValue === null) {
      if (defaultValue === null) {
        return defaultValue;
      } else {
        parsedValue = defaultValue;
      }
    }

    if (parsedValue === null) {
      return value;
    }

    // Assuming 'accounting.formatMoney' is a method from a service or a standalone function
    // You would need to implement the equivalent in Angular or find an npm package that does it
    return accounting.formatMoney(parsedValue * 100, '%', 2, undefined, undefined, '%v%s');
  }

  private handleIncorrectValue(value: number): number | null {
    // Implement the logic that was previously in the 'handleIncorrectValue' filter
    // For example, return null if value is not a number, otherwise return the number
    if (isNaN(value)) {
      return null;
    }
    return value;
  }
}

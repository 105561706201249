import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { CONVERSATION_ASSISTANT_TYPES } from '@http/conversation/conversation.constants';
import {
  CONVERSATION_OPERATOR_STATISTICS_TYPES,
  CONVERSATION_STATISTICS,
  CONVERSATION_STATISTICS_EXPORT_ENCODINGS,
  CONVERSATION_STATISTICS_TYPES,
} from '@http/conversation-statistics/conversation-statistics.constants';
import { TeamMemberModel } from '@http/team-member/team-member.model';
import { EXTENDED_RESPONSE } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class ConversationStatisticsModel {
  constructor(
    private readonly cookieService: CookieService,
    private readonly http: HttpClient,
    private readonly teamMemberModel: TeamMemberModel,
  ) {}

  get getAnswerSpeed() {
    return this.getStatistics.bind(
      this,
      CONVERSATION_STATISTICS.TYPES.ANSWER_SPEED,
      CONVERSATION_STATISTICS.FORMATS.ARRAYS,
      CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
    );
  }

  get getClosed() {
    return this.getStatistics.bind(
      this,
      CONVERSATION_STATISTICS.TYPES.CLOSED,
      CONVERSATION_STATISTICS.FORMATS.ARRAYS,
      CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
    );
  }

  get getClosedByUser() {
    return this.getStatistics.bind(
      this,
      CONVERSATION_STATISTICS.TYPES.CLOSED_BY_USER,
      CONVERSATION_STATISTICS.FORMATS.ARRAYS,
      CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
    );
  }

  get getConversationSize() {
    return this.getStatistics.bind(
      this,
      CONVERSATION_STATISTICS.TYPES.SIZE,
      CONVERSATION_STATISTICS.FORMATS.ARRAYS,
      CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
    );
  }

  get getLost() {
    return this.getStatistics.bind(
      this,
      CONVERSATION_STATISTICS.TYPES.LOST,
      CONVERSATION_STATISTICS.FORMATS.ARRAYS,
      CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
    );
  }

  get getNotAnswered() {
    return this.getStatistics.bind(
      this,
      CONVERSATION_STATISTICS.TYPES.NOT_ANSWERED,
      CONVERSATION_STATISTICS.FORMATS.ARRAYS,
      CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
    );
  }

  get getOpened() {
    return this.getStatistics.bind(
      this,
      CONVERSATION_STATISTICS.TYPES.OPENED,
      CONVERSATION_STATISTICS.FORMATS.ARRAYS,
      CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
    );
  }

  get getOpenedByUser() {
    return this.getStatistics.bind(
      this,
      CONVERSATION_STATISTICS.TYPES.OPENED_BY_USER,
      CONVERSATION_STATISTICS.FORMATS.ARRAYS,
      CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
    );
  }

  get aiBot() {
    return {
      getClosed: this.getAiBotStatistics.bind(this, true),
      getAssignToOperators: this.getAiBotStatistics.bind(this, false),
      getConversations: this.getAiBotConversations.bind(this),
    };
  }

  get operators() {
    return {
      exportStatisticsAsCsv: this.getOperatorsStatistics.bind(this, undefined),
      getAnswerSpeedNotWorkingTime: this.getOperatorsStatistics.bind(
        this,
        CONVERSATION_OPERATOR_STATISTICS_TYPES.ANSWER_SPEED_NOT_WORKING_TIME,
      ),
      getAnswerSpeedWorkingTime: this.getOperatorsStatistics.bind(
        this,
        CONVERSATION_OPERATOR_STATISTICS_TYPES.ANSWER_SPEED_WORKING_TIME,
      ),
      getClosed: this.getOperatorsStatistics.bind(this, CONVERSATION_OPERATOR_STATISTICS_TYPES.CLOSED),
      getLost: this.getOperatorsStatistics.bind(this, CONVERSATION_OPERATOR_STATISTICS_TYPES.LOST_CUSTOMERS),
      getNotAnswered: this.getOperatorsStatistics.bind(this, CONVERSATION_OPERATOR_STATISTICS_TYPES.NOT_ANSWERED),
      getParticipated: this.getOperatorsStatistics.bind(this, CONVERSATION_OPERATOR_STATISTICS_TYPES.PARTICIPATED),
      getVote: this.getOperatorsStatistics.bind(this, CONVERSATION_OPERATOR_STATISTICS_TYPES.VOTE),
    };
  }

  get tags() {
    return {
      exportStatisticsAsCsv: this.getTagsStatistics.bind(this),
      getStatistics: this.getTagsStatistics.bind(this),
    };
  }
  get sources() {
    return {
      getClosed: this.getSourceStatistics.bind(this, CONVERSATION_STATISTICS.TYPES.CLOSED),
      getOpened: this.getSourceStatistics.bind(this, CONVERSATION_STATISTICS.TYPES.OPENED),
    };
  }

  /**
   * Получение статистики по источникам
   *
   * @param {Boolean} closed - Закрыт ботом или переведен на оператора
   * @param {String} appId - Id приложения
   * @param {Moment} startDate - Начало периода
   * @param {Moment} endDate - Конец периода
   * @param {CONVERSATION_ASSISTANT_TYPES=} assistantType тип ассистента диалога
   * @param {CONVERSATION_STATISTICS.RANGES=} range Переопределение группировки
   */
  private getAiBotStatistics(
    closed: boolean,
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    assistantType?: CONVERSATION_STATISTICS_TYPES,
    range?: typeof CONVERSATION_STATISTICS.RANGES[keyof typeof CONVERSATION_STATISTICS.RANGES],
  ) {
    const definedRange = this.defineRangeByDates(startDate, endDate, range);

    const params: any = {
      app: appId,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      group_range: definedRange,
      closed: closed,
      ai_assistant: assistantType,
    };

    return this.http
      .get('/apps/' + appId + '/ai_assistant_stats', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response) => {
          return this.parseStatistics(response, definedRange, endDate);
        }),
      );
  }

  /**
   * Получение статистики по источникам
   *
   * @param appId - Id приложения
   * @param startDate - Начало периода
   * @param endDate - Конец периода
   * @param assistantType тип ассистента диалога
   */
  private getAiBotConversations(
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    assistantType: CONVERSATION_ASSISTANT_TYPES,
  ) {
    const params = {
      app: appId,
      auth_token: 'appm-' + appId + '-' + this.cookieService.get('carrotquest_auth_token_panel'),
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      ai_assistant: assistantType,
      csv_delimiter: ';',
      csv_encoding: 'utf-8-sig',
    };

    window.open(
      environment.apiEndpoint +
        '/apps/' +
        appId +
        '/ai_assistant_conversations?' +
        new URLSearchParams(params).toString(),
      '_blank',
    );
  }

  /**
   * Получение статистики по операторам
   *
   * @param statType Данные, по которым получается статистика
   * @param appId ID сайта
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param channelsIds ID'ы каналов
   * @param speedAggregation Агрегация по скорости ответа
   * @param voteAggregation Агрегация по оценке
   * @param exportAsCsv Нужно ли экспортировать полученные результаты в CSV
   * @param csvHeaders Массив массивов с заголовками для CSV-файла
   * @param csvDelimiter Разделитель записей в CSV
   * @param csvEncoding Кодировка CSV-файла
   */
  private getOperatorsStatistics(
    statType: string | undefined,
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    channelsIds: string[],
    speedAggregation: typeof CONVERSATION_STATISTICS.AGGREGATIONS[keyof typeof CONVERSATION_STATISTICS.AGGREGATIONS],
    voteAggregation: typeof CONVERSATION_STATISTICS.AGGREGATIONS[keyof typeof CONVERSATION_STATISTICS.AGGREGATIONS],
    exportAsCsv: false,
    csvHeaders: any[],
    csvDelimiter: string,
    csvEncoding: CONVERSATION_STATISTICS_EXPORT_ENCODINGS,
  ): Observable<unknown>;
  private getOperatorsStatistics(
    statType: string | undefined,
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    channelsIds: string[],
    speedAggregation: typeof CONVERSATION_STATISTICS.AGGREGATIONS[keyof typeof CONVERSATION_STATISTICS.AGGREGATIONS],
    voteAggregation: typeof CONVERSATION_STATISTICS.AGGREGATIONS[keyof typeof CONVERSATION_STATISTICS.AGGREGATIONS],
    exportAsCsv: true,
    csvHeaders: any[],
    csvDelimiter: string,
    csvEncoding: CONVERSATION_STATISTICS_EXPORT_ENCODINGS,
  ): void;
  private getOperatorsStatistics(
    statType: string | undefined,
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    channelsIds: string[],
    speedAggregation: typeof CONVERSATION_STATISTICS.AGGREGATIONS[keyof typeof CONVERSATION_STATISTICS.AGGREGATIONS] = CONVERSATION_STATISTICS
      .AGGREGATIONS.AVG,
    voteAggregation: typeof CONVERSATION_STATISTICS.AGGREGATIONS[keyof typeof CONVERSATION_STATISTICS.AGGREGATIONS] = CONVERSATION_STATISTICS
      .AGGREGATIONS.AVG,
    exportAsCsv: boolean = false,
    csvHeaders: any[] = [],
    csvDelimiter: string = ';',
    csvEncoding: CONVERSATION_STATISTICS_EXPORT_ENCODINGS = CONVERSATION_STATISTICS_EXPORT_ENCODINGS.WINDOWS_1251,
  ) {
    const params: any = {
      end_date: endDate.format('YYYY-MM-DD'),
      speed_aggregate_function: speedAggregation || CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
      vote_aggregate_function: voteAggregation || CONVERSATION_STATISTICS.AGGREGATIONS.AVG,
      start_date: startDate.format('YYYY-MM-DD'),
      ignoreErrors: true,
    };

    // если статистика запрашивается для псевдоканала "Все каналы" - на бэк ничего посылать не нужно, тогда он вернёт стату по всем каналам
    if (!(channelsIds.length === 1 && channelsIds[0] === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS])) {
      params.channels = JSON.stringify(channelsIds);
    }

    if (statType) {
      params.stat_type = statType;
    }

    if (exportAsCsv) {
      params.auth_token = 'appm-' + appId + '-' + this.cookieService.get('carrotquest_auth_token_panel');
      params.as_csv = exportAsCsv;
      params.csv_headers = JSON.stringify(csvHeaders);
      params.csv_delimiter = csvDelimiter || ';';
      params.csv_encoding = csvEncoding || CONVERSATION_STATISTICS_EXPORT_ENCODINGS.UTF_8_SIG;

      window.open(
        environment.apiEndpoint + '/apps/' + appId + '/operators_stats?' + new URLSearchParams(params).toString(),
        '_blank',
      );
      return;
    } else {
      return this.http
        .get('/apps/' + appId + '/operators_stats', { params, context: new HttpContext().set(EXTENDED_RESPONSE, true) })
        .pipe(map(this.parseOperatorsStatistics.bind(this)));
    }
  }

  /**
   * Получение статистики диалогов
   *
   * @param statType Тип статистики
   * @param format Формат ответа
   * @param aggregation Агрегация
   * @param appId ID сайта
   * @param workingTime Агрегация
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param channelsIds ID'ы каналов
   * @param rangeOverride Переопределение группировки
   * @param formatOverride Переопределение формата ответа
   * @param aggregationOverride Переопределение агрегации
   * @param assistantType assistantType диалога
   */
  private getStatistics(
    statType: typeof CONVERSATION_STATISTICS.TYPES[keyof typeof CONVERSATION_STATISTICS.TYPES],
    format: typeof CONVERSATION_STATISTICS.FORMATS[keyof typeof CONVERSATION_STATISTICS.FORMATS],
    aggregation: typeof CONVERSATION_STATISTICS.AGGREGATIONS[keyof typeof CONVERSATION_STATISTICS.AGGREGATIONS],
    appId: string,
    workingTime: boolean,
    startDate: moment.Moment,
    endDate: moment.Moment,
    channelsIds: string[],
    rangeOverride?: typeof CONVERSATION_STATISTICS.RANGES[keyof typeof CONVERSATION_STATISTICS.RANGES],
    formatOverride?: typeof CONVERSATION_STATISTICS.FORMATS[keyof typeof CONVERSATION_STATISTICS.FORMATS],
    aggregationOverride?: typeof CONVERSATION_STATISTICS.AGGREGATIONS[keyof typeof CONVERSATION_STATISTICS.AGGREGATIONS],
    assistantType?: CONVERSATION_ASSISTANT_TYPES,
  ) {
    const definedRange = this.defineRangeByDates(startDate, endDate, rangeOverride);
    const params: any = {
      aggregate_func: aggregationOverride || aggregation,
      assistant_type: assistantType,
      end_date: endDate.format('YYYY-MM-DD'),
      group_range: definedRange,
      response_format: formatOverride || format,
      stat_type: statType,
      start_date: startDate.format('YYYY-MM-DD'),
      working_time: workingTime,
    };

    // если статистика запрашивается для псевдоканала "Все каналы" - на бэк ничего посылать не нужно, тогда он вернёт стату по всем каналам
    if (!(channelsIds.length === 1 && channelsIds[0] === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS])) {
      params.channels = JSON.stringify(channelsIds);
    }

    return this.http
      .get('/conversations/stats/general', { params, context: new HttpContext().set(EXTENDED_RESPONSE, true) })
      .pipe(map((response) => this.parseStatistics(response, definedRange, endDate)));
  }

  /**
   * Получение статистики по тегам
   *
   * @param appId - Id приложения
   * @param startDate - Начало периода
   * @param endDate - Конец периода
   * @param channelsIds - Id'ы каналов
   * @param teamMemberId - Id оператора
   * @param tagContains - Поисковый запрос
   * @param exportAsCsv - Нужно ли экспортировать полученные результаты в CSV
   * @param orderByField - Поле, по которому будет происходить сортировка
   * @param orderBy - Тип сортировки
   * @param csvDelimiter=';' Разделитель записей в CSV
   * @param csvEncoding=CONVERSATION_STATISTICS_EXPORT_ENCODINGS.WINDOWS_1251 Кодировка CSV-файла
   */
  private getTagsStatistics(
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    channelsIds: string[],
    teamMemberId: number,
    tagContains: string,
    exportAsCsv: false,
    orderByField?: string,
    orderBy?: string,
    csvDelimiter?: string,
    csvEncoding?: CONVERSATION_STATISTICS_EXPORT_ENCODINGS,
  ): Observable<unknown>;
  private getTagsStatistics(
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    channelsIds: string[],
    teamMemberId: number,
    tagContains: string,
    exportAsCsv: true,
    orderByField?: string,
    orderBy?: string,
    csvDelimiter?: string,
    csvEncoding?: CONVERSATION_STATISTICS_EXPORT_ENCODINGS,
  ): void;
  private getTagsStatistics(
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    channelsIds: string[],
    teamMemberId: number,
    tagContains: string,
    exportAsCsv: boolean,
    orderByField?: string,
    orderBy?: string,
    csvDelimiter: string = ';',
    csvEncoding: CONVERSATION_STATISTICS_EXPORT_ENCODINGS = CONVERSATION_STATISTICS_EXPORT_ENCODINGS.WINDOWS_1251,
  ) {
    let params: any = {
      end_date: endDate.format('YYYY-MM-DD'),
      start_date: startDate.format('YYYY-MM-DD'),
      ignoreErrors: true,
    };

    // если статистика запрашивается для псевдоканала "Все каналы" - на бэк ничего посылать не нужно, тогда он вернёт стату по всем каналам
    if (!(channelsIds.length === 1 && channelsIds[0] === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS])) {
      params.channels = JSON.stringify(channelsIds);
    }

    if (teamMemberId) {
      params.django_user = teamMemberId;
    }

    if (tagContains) {
      params.tag_contains = tagContains;
    }

    if (orderByField) {
      params.order_by_field = orderByField;
    }

    if (orderBy) {
      params.order_by = orderBy;
    }

    if (exportAsCsv) {
      params.auth_token = 'appm-' + appId + '-' + this.cookieService.get('carrotquest_auth_token_panel');
      params.as_csv = exportAsCsv;
      params.csv_delimiter = csvDelimiter || ';';
      params.csv_encoding = csvEncoding || CONVERSATION_STATISTICS_EXPORT_ENCODINGS.UTF_8_SIG;

      window.open(
        environment.apiEndpoint + '/apps/' + appId + '/conversationtag_stats?' + new URLSearchParams(params).toString(),
        '_blank',
      );
      return;
    } else {
      return this.http.get<unknown>('/apps/' + appId + '/conversationtag_stats', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      });
    }
  }

  /**
   * Получение статистики по источникам
   *
   * @param {CONVERSATION_STATISTICS.TYPES.CLOSED | CONVERSATION_STATISTICS.TYPES.OPENED} statType Тип статистики
   * @param {String} appId - Id приложения
   * @param {Moment} startDate - Начало периода
   * @param {Moment} endDate - Конец периода
   * @param {CONVERSATION_STATISTICS.SOURCES[]=} sources - Список источников. Пустое значение - все источники
   * @param {CONVERSATION_ASSISTANT_TYPES=} assistantType тип ассистента диалога
   * @param {CONVERSATION_STATISTICS.RANGES=} range Переопределение группировки
   */
  private getSourceStatistics(
    statType: typeof CONVERSATION_STATISTICS.TYPES.CLOSED | typeof CONVERSATION_STATISTICS.TYPES.OPENED,
    appId: string,
    startDate: moment.Moment,
    endDate: moment.Moment,
    sources?: typeof CONVERSATION_STATISTICS.SOURCES[keyof typeof CONVERSATION_STATISTICS.SOURCES][],
    assistantType?: CONVERSATION_ASSISTANT_TYPES,
    range?: typeof CONVERSATION_STATISTICS.RANGES[keyof typeof CONVERSATION_STATISTICS.RANGES],
  ) {
    const definedRange = this.defineRangeByDates(startDate, endDate, range);

    const params: any = {
      app: appId,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      group_range: definedRange,
      assistant_type: assistantType,
      stat_type: statType,
    };

    if (angular.isArray(sources) && !sources.includes(CONVERSATION_STATISTICS.SOURCES.ALL)) {
      params['sources'] = JSON.stringify(sources);
    }

    return this.http
      .get('/apps/' + appId + '/conversation_source_stats', {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(map((response) => this.parseStatistics(response, definedRange, endDate)));
  }

  /**
   * Парсинг статистики по операторам
   *
   * @param response Ответ от сервера
   */
  private parseOperatorsStatistics(response: any) {
    response.data.forEach((operator: any) => {
      this.teamMemberModel.parse(operator.user);
    });

    return response;
  }

  /**
   * Парсинг статистики диалогов
   *
   * @param response Ответ от сервера
   * @param range Группировка
   * @param endDate Дата конца периода (нужна для вычисления конца последней недели в понедельной статистике)
   */
  private parseStatistics(
    response: any,
    range: typeof CONVERSATION_STATISTICS.RANGES[keyof typeof CONVERSATION_STATISTICS.RANGES],
    endDate: moment.Moment,
  ) {
    const labelsArray = response.data[0];
    const dataArray = response.data[1];

    response.data = {
      labels: this.parseLabelsByRange(range, labelsArray, endDate),
      data: dataArray,
      range: range,
    };

    return response;
  }

  /**
   * Парсинг списка лейблов. Для каждой из группировок - свой формат ответа с сервера, а так же свои подписи на графике
   *
   * @param range Группировка
   * @param labelsArray
   * @param endDate Дата конца периода (нужна для вычисления конца последней недели в понедельной статистике)
   */
  private parseLabelsByRange(
    range: typeof CONVERSATION_STATISTICS.RANGES[keyof typeof CONVERSATION_STATISTICS.RANGES],
    labelsArray: string[],
    endDate: moment.Moment,
  ) {
    let format, currentTime, startTime, endTime;
    let parsedLabelsArray = [];
    switch (range) {
      case CONVERSATION_STATISTICS.RANGES.HOUR: {
        format = 'YYYY-MM-DD HH';
        for (let i = 0; i < labelsArray.length; i++) {
          currentTime = moment(labelsArray[i], format);
          parsedLabelsArray[i] = currentTime.format('LT');
        }
        break;
      }
      case CONVERSATION_STATISTICS.RANGES.DAY: {
        format = 'YYYY-MM-DD';
        for (let i = 0; i < labelsArray.length; i++) {
          currentTime = moment(labelsArray[i], format);
          parsedLabelsArray[i] = currentTime.format('L');
        }
        break;
      }
      case CONVERSATION_STATISTICS.RANGES.WEEK: {
        format = 'YYYY-MM-DD';
        for (let i = 0; i < labelsArray.length; i++) {
          startTime = moment(labelsArray[i], format);
          if (labelsArray[i + 1]) {
            endTime = moment(labelsArray[i + 1], format).subtract(1, 'days');
          } else {
            endTime = endDate;
          }
          parsedLabelsArray[i] = startTime.format('L') + ' - ' + endTime.format('L');
        }
        break;
      }
    }

    return parsedLabelsArray;
  }

  /**
   * Определение типа группировки по датам
   */
  private defineRangeByDates(
    startDate: moment.Moment,
    endDate: moment.Moment,
    range?: typeof CONVERSATION_STATISTICS.RANGES[keyof typeof CONVERSATION_STATISTICS.RANGES],
  ): typeof CONVERSATION_STATISTICS.RANGES[keyof typeof CONVERSATION_STATISTICS.RANGES] {
    if (range === undefined) {
      // Разницы датой начала и датой конца в днях и месяцах
      let diffInDays = endDate.diff(startDate, 'days');
      let diffInMonths = endDate.diff(startDate, 'months');
      if (diffInDays < 1) {
        range = CONVERSATION_STATISTICS.RANGES.HOUR;
      } else if (diffInMonths < 1) {
        range = CONVERSATION_STATISTICS.RANGES.DAY;
      } else {
        range = CONVERSATION_STATISTICS.RANGES.WEEK;
      }
    }
    return range;
  }
}

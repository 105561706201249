/**
 * Фильтр, преобразующий отсутсвие перевода в пустую строку
 */
(function () {
  'use strict';

  angular.module('myApp.filters.translate').filter('cqTranslate', cqTranslate);

  function cqTranslate($translate) {
    return cqTranslateFilter;

    /**
     * Преобразовывает отсутсвие перевода в пустую строку
     *
     * @param {String} key - Ключ перевода
     * @returns {String}
     */
    function cqTranslateFilter(key) {
      var translation = $translate.instant(key);

      return translation === key ? '' : translation;
    }
  }
})();

(function () {
  'use strict';

  require('./working-hours.controller');

  angular.module('myApp.chatSettings').component('cqWorkingHours', cqWorkingHours());

  function cqWorkingHours() {
    return {
      bindings: {
        autoOffline: '=', // Флаг включения офлайн-режима, если в рабочее время все операторы в офлайне
        currentApp: '=', // Текущее приложение
        timezone: '=', // Временная зона
        workingPeriods: '=', // Периоды работы
      },
      controller: 'CqWorkingHoursController',
      controllerAs: 'vm',
      template: require('./working-hours.html'),
    };
  }
})();

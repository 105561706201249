/**
 * Компонент для превью блока 'Видео'
 */
(function () {
  'use strict';

  require('./popup-video-block-preview.controller');

  angular.module('myApp.popupEditor').component('cqPopupVideoBlockPreview', cqPopupVideoBlockPreview());

  function cqPopupVideoBlockPreview() {
    return {
      bindings: {
        params: '=', // параметры блока
      },
      controller: 'CqPopupVideoBlockPreviewController',
      controllerAs: 'vm',
      template: require('./popup-video-block-preview.html'),
    };
  }
})();

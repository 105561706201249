(function () {
  'use strict';

  angular
    .module('myApp.privacySettings')
    .controller('CqChatDataCollectionSettingsController', CqChatDataCollectionSettingsController);

  function CqChatDataCollectionSettingsController(PRIVACY_POLICY_CHAT_URL, validationHelper) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.chatDataCollectionPreview = {
        CONVERSATION: 'conversation',
        NOTIFICATION: 'notification',
      };

      vm.chatDataCollectionSettingsForm = null;
      vm.changeChatDataCollectionPreview = changeChatDataCollectionPreview;
      vm.PRIVACY_POLICY_CHAT_URL = PRIVACY_POLICY_CHAT_URL;
      vm.stateChatDataCollectionPreview = vm.chatDataCollectionPreview.NOTIFICATION;
      vm.validationHelper = validationHelper;

      function changeChatDataCollectionPreview() {
        if (vm.stateChatDataCollectionPreview === vm.chatDataCollectionPreview.NOTIFICATION) {
          vm.stateChatDataCollectionPreview = vm.chatDataCollectionPreview.CONVERSATION;
          return;
        }
        if (vm.stateChatDataCollectionPreview === vm.chatDataCollectionPreview.CONVERSATION) {
          vm.stateChatDataCollectionPreview = vm.chatDataCollectionPreview.NOTIFICATION;
          return;
        }
      }
    }
  }
})();

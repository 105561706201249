<cq-property-selector
  class="flex-1 mr-10"
  [currentApp]="currentApp"
  [formControl]="propertyName"
  [(properties)]="properties"
></cq-property-selector>
<div
  [cqShowError]="value"
  class="flex-1"
>
  <input
    class="form-control"
    [formControl]="value"
    [placeholder]="inputPlaceholder"
  />
  <cq-validation-messages [control]="value">
    <cq-validation-message errorName="required">{{ inputError }}</cq-validation-message>
  </cq-validation-messages>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { SharedModule } from '@panel/app/shared/shared.module';

import { KnowledgeBasePopoverComponent } from './knowledge-base-popover.component';

@NgModule({
  declarations: [KnowledgeBasePopoverComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    SharedModule,
    UIRouterUpgradeModule,
    ReactiveFormsModule,
    OverlayscrollbarsModule,
  ],
})
export class KnowledgeBasePopoverModule {}

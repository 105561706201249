import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';
import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

/**
 * Контроллер редактора шаблонов
 */

(function () {
  'use strict';

  angular
    .module('myApp.messageTemplates')
    .controller('CqMessageTemplatesEditController', CqMessageTemplatesEditController);

  function CqMessageTemplatesEditController(
    $filter,
    $state,
    $uibModal,
    $translate,
    $q,
    ACTIONS_ON_DIRECTORY,
    MESSAGE_TEMPLATES_TABS,
    USER_FILES_URL,
    carrotquestHelper,
    clipboard,
    featureModel,
    messageModel,
    messagePartModel,
    messageTemplateModel,
    planFeatureAccessService,
    popupBlockModel,
    toastr,
    validationHelper,
  ) {
    var vm = this;

    var MAX_NAME_LENGTH = 255; //Максимальная длинна имени шаблона

    vm.$onInit = init;

    function init() {
      vm.accessToMessagesTemplates = planFeatureAccessService.getAccess(PLAN_FEATURE.MESSAGES_TEMPLATES, vm.currentApp);

      trackEnterOnPage();
      vm.copy = copy;
      vm.copyStructureForTemplate = copyStructureForTemplate;
      vm.FEATURES = FEATURES;
      vm.featureModel = featureModel;
      vm.initTemplateActive = vm.template.active; // Начальное состояние активности шаблона NOTE его нужно сохранить чтобы при сохранении, если он изменен, вызывать событие
      vm.MAX_NAME_LENGTH = MAX_NAME_LENGTH;
      vm.openCreateDirectoryModal = openCreateDirectoryModal;
      vm.requestIsPerforming = false; // Флаг выполнения запроса
      vm.saveOrCreate = saveOrCreate;
      vm.trackClickCreateAutoMessage = trackClickCreateAutoMessage;
      vm.trackClickCreateCopy = trackClickCreateCopy;
      vm.validationHelper = validationHelper;

      if ($state.is('app.content.messagesAjs.templates.detail.copy')) {
        preparingCreateCopy();
      }

      initLinkBlockPopupWithData();
    }

    /**
     * Переход на состояние создания копии
     */
    function copy() {
      $state.go('app.content.messagesAjs.templates.detail.copy', {}, { reload: true });
    }

    /**
     * Копирование структуры автосообщения для создания шаблона
     */
    function copyStructureForTemplate() {
      clipboard.copyText(messageTemplateModel.getTemplateFromMessage(vm.messagePart, vm.template));
    }

    /**
     * Связывание блоков поп-апа с типами событий и свойствами пользователя
     */
    function initLinkBlockPopupWithData() {
      if (!!~[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].indexOf(vm.messagePart.type)) {
        var blockPopup = vm.messagePart[vm.messagePart.type];
        var popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

        blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

        for (var j = 0; j < popupBlocks.length; j++) {
          var popupBlock = popupBlocks[j];

          popupBlockModel.linkWithEventTypes(popupBlock, vm.properties.eventTypes);
          popupBlockModel.linkWithUserProperties(popupBlock, vm.properties.userProps);
        }
      }
    }

    /**
     * Открытие модалки создания папки
     */
    function openCreateDirectoryModal() {
      var createDirectoryModal = $uibModal.open({
        component: 'cqTemplatesDirectoryEditorModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
        },
      });

      createDirectoryModal.result.then(editDirectorySuccess);

      function editDirectorySuccess(response) {
        switch (response.action) {
          case ACTIONS_ON_DIRECTORY.CREATE: {
            vm.directories.push(response.directory);
            vm.template.directory = response.directory;
            break;
          }
        }
      }
    }

    /**
     * Подготавливает к созданию его копии
     */
    function preparingCreateCopy() {
      vm.template.name = vm.template.name + ' (' + $translate.instant('messageTemplatesEdit.copy') + ')';
      vm.template.id = null;
    }

    /**
     * Сохранение или создание шаблона
     *
     * @param {Boolean} valid - Валидность формы
     */
    function saveOrCreate(valid) {
      if (!valid) {
        vm.messagePart.handleError && vm.messagePart.handleError();
        return;
      }

      if (vm.requestIsPerforming) {
        return;
      }

      vm.requestIsPerforming = true;

      if (vm.template.id) {
        messageTemplateModel
          .saveFromMessagePart(vm.currentApp.id, vm.messagePart, vm.template)
          .then(saveSuccess)
          .finally(saveOrCreateFinally);
      } else {
        messageTemplateModel
          .createFromMessagePart(vm.currentApp.id, vm.messagePart, vm.template)
          .then(createSuccess)
          .finally(saveOrCreateFinally);
      }

      function createSuccess(response) {
        if ($state.is('app.content.messagesAjs.templates.detail.copy')) {
          trackCreateCopy();
        } else {
          trackCreate();
        }

        $state.go('app.content.messagesAjs.templates.detail', {
          templateId: response.id,
        });

        toastr.success($translate.instant('messageTemplatesEdit.toasts.templateHasBeenCreate'));
      }

      function saveSuccess() {
        trackSave();
        vm.initTemplateActive !== vm.template.active && trackChangeActive(vm.template.active);
        toastr.success($translate.instant('messageTemplatesEdit.toasts.templateHasBeenSaved'));
      }

      function saveOrCreateFinally() {
        vm.requestIsPerforming = false;
      }
    }

    /**
     * Трек клика на кнопку "создать автосообщение"
     */
    function trackClickCreateAutoMessage() {
      carrotquestHelper.track('Шаблоны - клик на создать автосообщение из шаблона', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
        id_шаблона: vm.template.id,
      });
    }

    /**
     * Трек клика на кнопку "Создать копию"
     */
    function trackClickCreateCopy() {
      carrotquestHelper.track('Шаблоны - клик на создать копию', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
      });
    }

    /**
     * Трек создания шаблона
     */
    function trackCreate() {
      carrotquestHelper.track('Шаблоны - создал шаблон', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
      });
    }

    /**
     * Трек создания копии шаблона
     */
    function trackCreateCopy() {
      carrotquestHelper.track('Шаблоны - создал копию', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
        id_шаблона: vm.template.id,
      });
    }

    /**
     * Трек смена активности
     *
     * @param {Boolean} active - Статус активности
     */
    function trackChangeActive(active) {
      var trackText = active ? 'Шаблоны - используется' : 'Шаблоны - не используется';
      carrotquestHelper.track(trackText, {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
        id_шаблона: vm.template.id,
      });
    }

    /**
     * Трек захода на страницу
     */
    function trackEnterOnPage() {
      carrotquestHelper.track('Шаблоны - зашел на шаблон', {
        App: vm.currentApp.name,
        'Источник шаблона': MESSAGE_TEMPLATES_TABS.SAVED_TEMPLATES,
        id_шаблона: vm.template.id,
      });
    }

    /**
     * Трек сохранения шаблона
     */
    function trackSave() {
      carrotquestHelper.track('Шаблоны - сохранил шаблон', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
        id_шаблона: vm.template.id,
      });
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TriggerChainActionsComponent } from '@panel/app/pages/trigger-chains/editor/components/actions/trigger-chain-actions.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [TriggerChainActionsComponent],
  exports: [TriggerChainActionsComponent],
  imports: [CommonModule, TranslocoModule, SharedModule],
})
export class TriggerChainActionsModule {}

import { ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

import { BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

const appOnlineConditionNextBranchIdValidators: ValidatorFn[] = [Validators.required];

export class BotAppOnlineConditionActionForm extends BaseBotActionForm {
  get allTouchedChanges$(): Observable<boolean> {
    return extractTouchedChanges(this.controls.nextBranchLinkId).pipe(
      startWith(this.controls.nextBranchLinkId.touched),
    );
  }

  getCustomControls(action: ChatBotAction): CustomControlsWithBodyJsonRequired {
    return {
      bodyJson: new GenericFormGroup({}),
      nextBranchLinkId: new GenericFormControl(
        action.nextBranchLinkId ?? null,
        appOnlineConditionNextBranchIdValidators,
      ),
    };
  }
}

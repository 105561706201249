import { Injectable } from '@angular/core';

import { App } from '@http/app/app.model';
import {
  AddonsConfiguration,
  V3_PLAN,
} from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.types';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { MONTHS_BY_PERIOD, PLANS_PERIODS } from '@panel/app/services/billing-plan/billing-plan.constants';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';

export type ScaledAddonPrice = {
  addon: BILLING_ADD_ONS;
  scaledPrice: number;
};

export type PlanTotalPrices = {
  expectedVisitors: number;
  priceWithAddonsPerMonth: number;
  priceWithoutAddonsPerMonth: number;
  discountedPriceWithAddonsPerMonth: number;
  discountedPriceWithoutAddonsPerMonth: number;
  totalPriceWithAddonsPerMonth: number;
  totalPriceWithAddons: number;
  totalDiscountedPriceWithAddonsPerMonth: number;
  totalDiscountedPriceWithAddons: number;
  totalDiscountedPriceWithoutAddons: number;
  addons: ScaledAddonPrice[];
};

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  constructor(
    private readonly billingInfoModel: BillingInfoModel,
    private readonly billingPlanService: BillingPlanService,
  ) {}

  calcPlanPrices(
    plan: V3_PLAN,
    addonsConfiguration: AddonsConfiguration,
    expectedVisitors: number,
    paymentRate: PLANS_PERIODS,
    currentApp: App,
  ): PlanTotalPrices {
    const billingPlansSettings = this.billingPlanService.getCurrentPlansSettings(
      currentApp,
      this.billingInfoModel.billingInfo,
    );
    const monthsInPeriod = MONTHS_BY_PERIOD[paymentRate];
    const priceWithoutAddonsPerMonth = billingPlansSettings[plan][expectedVisitors].PRICE;
    const { totalPrice: addonsPrice, addons } = this.calcAddonsPrice(
      currentApp,
      addonsConfiguration,
      plan,
      expectedVisitors,
    );
    const priceWithAddonsPerMonth = priceWithoutAddonsPerMonth + addonsPrice;

    const discountedPriceWithAddonsPerMonth =
      this.calcDiscountedPlanPrice(priceWithoutAddonsPerMonth, paymentRate, currentApp) + addonsPrice;
    const discountedPriceWithoutAddonsPerMonth = this.calcDiscountedPlanPrice(
      priceWithoutAddonsPerMonth,
      paymentRate,
      currentApp,
    );

    const totalPriceWithAddonsPerMonth = priceWithoutAddonsPerMonth + addonsPrice;
    const totalPriceWithAddons = totalPriceWithAddonsPerMonth * monthsInPeriod;

    const totalDiscountedPriceWithAddonsPerMonth =
      this.calcDiscountedPlanPrice(priceWithoutAddonsPerMonth, paymentRate, currentApp) + addonsPrice;
    const totalDiscountedPriceWithAddons = totalDiscountedPriceWithAddonsPerMonth * monthsInPeriod;
    const totalDiscountedPriceWithoutAddons =
      this.calcDiscountedPlanPrice(priceWithoutAddonsPerMonth, paymentRate, currentApp) * monthsInPeriod;

    return {
      expectedVisitors,
      priceWithAddonsPerMonth,
      priceWithoutAddonsPerMonth,
      discountedPriceWithAddonsPerMonth,
      discountedPriceWithoutAddonsPerMonth,
      totalPriceWithAddonsPerMonth,
      totalPriceWithAddons,
      totalDiscountedPriceWithAddonsPerMonth,
      totalDiscountedPriceWithAddons,
      totalDiscountedPriceWithoutAddons,
      addons,
    };
  }

  private calcDiscountedPlanPrice(planPrice: number, paymentRate: PLANS_PERIODS, currentApp: App): number {
    const currentDiscounts = this.billingPlanService.getCurrentDiscountsByPeriod(
      currentApp,
      this.billingInfoModel.billingInfo,
    );
    // @ts-ignore
    const discountMultiplier: number = currentDiscounts[paymentRate];
    return planPrice * (1 - discountMultiplier);
  }

  private calcAddonsPrice(
    currentApp: App,
    addonsConfiguration: AddonsConfiguration,
    plan: V3_PLAN,
    expectedVisitors: number,
  ): { totalPrice: number; addons: ScaledAddonPrice[] } {
    let totalPrice = 0;
    const addons: ScaledAddonPrice[] = [];

    for (let [addon, addonAvailabilityPerPlan] of Object.entries(addonsConfiguration)) {
      const planAvailability = addonAvailabilityPerPlan[plan];
      if (planAvailability.availability === 'available' && planAvailability.value) {
        const scaledPrice = this.billingPlanService.getAddOnPrice(addon, currentApp, expectedVisitors);

        totalPrice += scaledPrice;

        addons.push({
          addon: addon as keyof typeof addonsConfiguration,
          scaledPrice,
        });
      }
    }

    return { totalPrice, addons };
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TagDefaultModule } from '../tag-default/tag-default.module';
import { ConversationTagListComponent } from './conversation-tag-list.component';

@NgModule({
  declarations: [ConversationTagListComponent],
  exports: [ConversationTagListComponent],
  imports: [CommonModule, TagDefaultModule],
})
export class ConversationTagListModule {}

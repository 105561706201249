<ng-container *transloco="let t; read: 'pageUrlComponent'">
  <div>
    <div class="d-flex align-items-center mb-10">
      <strong>{{ t('heading') }}</strong>
    </div>
    <cq-url-input [formControl]="control.controls.url"></cq-url-input>
    <cq-checkbox-control [formControl]="control.controls.hasSubpages">
      {{ t('hasSubpagesCheckbox') }}
    </cq-checkbox-control>
  </div>
</ng-container>

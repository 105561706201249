(function () {
  'use strict';

  require('./create-user-property.controller');

  angular.module('myApp.modals.createUserProperty').component('cqCreateUserProperty', cqCreateUserProperty());

  function cqCreateUserProperty() {
    return {
      bindings: {
        modalInstance: '=',
      },
      controller: 'CqCreateUserPropertyController',
      controllerAs: 'vm',
      template: require('./create-user-property.html'),
    };
  }
})();

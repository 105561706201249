import { DOCS_EXTENSION } from '../../../shared/services/file-helper/file-helper.constants';
import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.users').controller('CqLeadsImportController', CqLeadsImportController);

  function CqLeadsImportController(
    $filter,
    $q,
    $translate,
    $uibModal,
    moment,
    toastr,
    Papa,
    PROJECT_NAME,
    tagModel,
    userModel,
    carrotquestHelper,
    featureModel,
    fileHelper,
    paywallService,
    planFeatureAccessService,
    validationHelper,
  ) {
    var vm = this;

    var uploadedFile = null; // Загруженный файл

    /**
     * Типы ошибок при импорте
     *
     * @type {Object.<String>}}
     */
    var IMPORT_ERROR_TYPES = {
      DIFFERENT_NUMBER_OF_COLUMNS: 'differentNumberOfColumns',
      HAS_NO_ACCESS_TO_CUSTOM_PROPS: 'hasNoAccessToCustomProps', // Нет доступа в тарифе к импорту пользователей с кастомными свойствами
      HAS_NOT_USER_ID: 'hasNotUserId',
      HAS_USER_ID: 'hasUserId',
      NO_DATA_IMPORT: 'noDataImport',
      HAS_NOT_TELEGRAM_ID: 'hasNotTelegramId',
    };

    /**
     * Типы импорта
     * NOTE Эту переменную можно было бы вынести в константы Ангуляра в модели userModel, но в качестве эксперимента оставили её здесь.
     * NOTE Один из аргументов почему она тут: она нигде, кроме этого раздела, не будет больше использоваться
     *
     * @type {{LEADS: string, USERS: string}}
     */
    var IMPORT_TYPES = {
      LEADS: '$email',
      USERS: '$user_id',
      TELEGRAM: '$social_telegram_id',
    };

    const IMPORT_TYPE_ICONS = {
      [IMPORT_TYPES.LEADS]: 'cqi-envelope',
      [IMPORT_TYPES.USERS]: 'cqi-user',
      [IMPORT_TYPES.TELEGRAM]: 'cqi-telegram',
    };

    /**
     * Список типов импорта
     * NOTE Эту переменную можно было бы вынести в константы Ангуляра в модели userModel, но в качестве эксперимента оставили её здесь.
     * NOTE Один из аргументов почему она тут: она нигде, кроме этого раздела, не будет больше использоваться
     *
     * @type {*[]}
     */
    var IMPORT_TYPES_LIST = [IMPORT_TYPES.USERS, IMPORT_TYPES.LEADS, IMPORT_TYPES.TELEGRAM];

    /**
     * Максимальное количество выводимых ошибок для превью
     *
     * @type {number}
     */
    var MAX_ERROR_ROWS_PREVIEW = 30;

    /**
     * Максимальный размер файла
     *
     * @type {number}
     */
    var MAX_FILE_SIZE = 30 * 1024 * 1024;

    /**
     * Время показа тостов с ошибками
     *
     * @type {number}
     */
    var TOASTR_TIME_OUT = 7 * 1000;

    vm.$onInit = init;

    function init() {
      vm.accessToUsersCustomProperties = planFeatureAccessService.getAccess(
        PLAN_FEATURE.USERS_CUSTOM_PROPERTIES,
        vm.currentApp,
      );
      vm.accessToUsersTags = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, vm.currentApp);

      vm.addNewTagToUser = addNewTagToUser;
      vm.attachFile = attachFile;
      vm.clearImport = clearImport;
      vm.csvParsingFinished = false; // Парсинг файла закончен
      vm.csvParsingInProcess = false; // Парсинг файла в процессе
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.IMPORT_TYPES = IMPORT_TYPES;
      vm.IMPORT_TYPE_ICONS = IMPORT_TYPE_ICONS;
      vm.IMPORT_TYPES_LIST = IMPORT_TYPES_LIST;
      vm.importFile = importFile;
      vm.importFinished = false; // Импорт файла завершен
      vm.importTags = []; // теги для импорта лидов
      vm.importType = null; // Выбранный тип импорта
      vm.invalidRows = []; // Строки в которых есть ошибки
      vm.MAX_ERROR_ROWS_PREVIEW = MAX_ERROR_ROWS_PREVIEW;
      vm.removeTag = removeTag;
      vm.openRowsWithErrorsModal = openRowsWithErrorsModal;
      vm.parsedHeaders = []; // Распаршенные заголовки
      vm.parsedResults = []; // Результат парсинга
      vm.previewParsedData = []; // Предпросмотр парсинга
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.rowsWithErrorsPopoverInfo = {};
      vm.setImportType = setImportType;
      vm.trackChangeImportType = trackChangeImportType;
      vm.trackClickBackwardButton = trackClickBackwardButton;
      vm.trackClickChooseAnotherFile = trackClickChooseAnotherFile;
      vm.trackClickImport = trackClickImport;
      vm.trackClickMoreAboutImport = trackClickMoreAboutImport;
      vm.trackClickShowErrors = trackClickShowErrors;
      vm.updatesPlanned = null; // Количество лидов, которые будут загружены (приходит из бека)
      vm.integrationForm = null;
      vm.telegramIntegration = null;
      vm.validationHelper = validationHelper;
      vm.getBotName = getBotName;
    }

    /**
     * Добавление нового тега пользователям
     */
    function addNewTagToUser(newTag) {
      var existingTagByName = $filter('filter')(vm.importTags, newTag, true)[0]; // проверка является ли тег уже добавленным

      if (!existingTagByName) {
        vm.importTags = vm.importTags.concat(newTag);
      }
    }

    /**
     *  Прикрепление файла
     *
     * @param {Array.<File>} files
     */
    function attachFile(files) {
      vm.integrationForm?.$setSubmitted();
      if (vm.importType === vm.IMPORT_TYPES.TELEGRAM && !vm.integrationForm.$valid) {
        return;
      }
      vm.importTags = []; // Сбрасываем импортируемые теги

      if (vm.csvParsingInProcess || !files || files.length != 1) {
        return;
      }

      var file = files[0];
      if (!fileHelper.isFileHasExtensions(file.name, DOCS_EXTENSION.CSV)) {
        toastr.error($translate.instant('leadsImport.toasts.fileExtension'), {
          timeOut: TOASTR_TIME_OUT,
        });
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        trackMaxFileSizeError();
        toastr.error(
          $translate.instant('leadsImport.toasts.maxFileSize', { maxFileSize: MAX_FILE_SIZE / 1024 / 1024 }),
          {
            timeOut: TOASTR_TIME_OUT,
          },
        );
        return;
      }

      uploadedFile = file;
      vm.csvParsingInProcess = true;

      Papa.parse(file, {
        skipEmptyLines: true, // Пропускает пустые строки
        complete: csvParseSuccess, // Коллбек по окончании парсинга
      });

      /**
       * Парсинг из CSV файла завершен
       *
       * @param {Object.<Object>} results
       * @param {File} file
       */
      function csvParseSuccess(results, file) {
        let toast; // Инстанс тоста
        $q.all([
          isHeadersValid(results.data, vm.importType),
          isCsvHasData(results.data),
          isHasCustomPropsPlanCapability(results.data),
          validateCsvData(results.data),
        ])
          .then(checkDataErrorsSuccess)
          .catch(checkDataErrorsError)
          .finally(checkDataErrorsFinally);

        /**
         * Валидация прошла успешно
         */
        function checkDataErrorsSuccess() {
          trackFinishParse(results.data.length - 1);
          countInvalidRows(results.data, vm.importType);
          vm.parsedResults = results;
          parseHeaders(vm.parsedResults.data[0]); // Первая строка это список заголовков
          vm.previewParsedData = vm.parsedResults.data.slice(1, 6); // Для превью взять первые 5 элементов, не влючая заголовков
          vm.csvParsingFinished = true;
        }

        /**
         * Валидация завершилась ошибкой
         *
         * @param {IMPORT_ERROR_TYPES} importErrorType - Тип ошибки
         */
        function checkDataErrorsError(importErrorType) {
          let toastrConfig = {
            allowHtml: true,
            timeOut: TOASTR_TIME_OUT,
          };
          if (importErrorType === IMPORT_ERROR_TYPES.NO_DATA_IMPORT) {
            trackNoDataImport();
          } else if (importErrorType === IMPORT_ERROR_TYPES.HAS_USER_ID) {
            trackLeadsImportError();
          } else if (importErrorType === IMPORT_ERROR_TYPES.HAS_NOT_USER_ID) {
            trackUsersImportError();
          } else if (importErrorType === IMPORT_ERROR_TYPES.HAS_NOT_TELEGRAM_ID) {
            trackTelegramImportError();
          } else if (importErrorType === IMPORT_ERROR_TYPES.DIFFERENT_NUMBER_OF_COLUMNS) {
            trackDifferentNumberOfColumns();
          }

          if (importErrorType === IMPORT_ERROR_TYPES.HAS_NO_ACCESS_TO_CUSTOM_PROPS) {
            toastrConfig = {
              ...toastrConfig,
              onTap: openPlanCapabilityPaywallModal,
              tapToDismiss: false,
            };
          }
          toast = toastr.error($translate.instant('leadsImport.toasts.' + importErrorType), toastrConfig);
          clearImport();
        }

        /**
         * Финальная стадия валидации данных
         */
        function checkDataErrorsFinally() {
          vm.csvParsingInProcess = false;
        }

        /**
         * Открытие пейволла с plan capabilities
         */
        function openPlanCapabilityPaywallModal() {
          toastr.clear(toast);
          paywallService.showPaywall(vm.currentApp, vm.accessToUsersCustomProperties.denialReason);
        }
      }
    }

    /**
     * Очистка данных до инициального состояния
     */
    function clearImport() {
      uploadedFile = null;
      vm.invalidRows = [];
      vm.csvParsingFinished = false;
      vm.csvParsingInProcess = false;
      vm.parsedHeaders = [];
      vm.updatesPlanned = null;
      vm.importFinished = false;
    }

    /**
     * Получение имени типа импорта для трека событий
     *
     * @param {IMPORT_TYPES} importType - Тип импорта
     */
    function getImportNameForTrack(importType) {
      return importType === IMPORT_TYPES.LEADS ? 'Импорт лидов' : 'Импорт пользователей';
    }

    /**
     * Загрузка файла на сервер
     */
    function importFile() {
      if (!vm.csvParsingInProcess || uploadedFile) {
        var tags = tagModel.parseToServerFormat(vm.importTags);

        firstValueFrom(
          userModel.importUsers(
            vm.currentApp.id,
            vm.importType,
            uploadedFile,
            vm.parsedResults.meta.delimiter,
            tags,
            vm.telegramIntegration?.id,
          ),
        )
          .then(importFileSuccess)
          .catch(importFileError)
          .finally(importFileFinally);
      }

      /**
       * Успешная загрузка файла
       *
       * @param response
       */
      function importFileSuccess(response) {
        vm.updatesPlanned = response.updatesPlanned;
      }

      /**
       * Загрузка файла завершилось ошибкой
       */
      function importFileError() {
        vm.updatesPlanned = null;
      }

      function importFileFinally() {
        trackFinishImport();
        vm.importFinished = true;
      }
    }

    /**
     * Проверка на наличие данных
     *
     * @param {Array} csvData - Данные из файла
     *
     * @returns {Promise}
     */
    function isCsvHasData(csvData) {
      if (csvData.length > 1) {
        return $q.resolve();
      } else {
        return $q.reject(IMPORT_ERROR_TYPES.NO_DATA_IMPORT);
      }
    }

    /**
     * Проверка на доступ в тарифе к импорту кастомных свойств
     *
     * @param {Array} csvData - Данные из файла
     *
     * @returns {Promise}
     */
    function isHasCustomPropsPlanCapability(csvData) {
      if (!vm.accessToUsersCustomProperties.hasAccess) {
        for (var i = 0; i < csvData[0].length; i++) {
          if (!~csvData[0][i].indexOf('$')) {
            return $q.reject(IMPORT_ERROR_TYPES.HAS_NO_ACCESS_TO_CUSTOM_PROPS);
          }
        }
      }

      return $q.resolve();
    }

    /**
     * Проверка заголовков на валидность
     *
     * @param {Array} csvData - Данные из файла
     * @param {IMPORT_TYPES} importType - Тип импорта
     *
     * @returns {Promise}
     */
    function isHeadersValid(csvData, importType) {
      if (csvData.length === 0) {
        return $q.reject(IMPORT_ERROR_TYPES.NO_DATA_IMPORT);
      }

      let existEmailField = csvData[0].includes('$email');
      let existUserIdField = csvData[0].includes('$user_id');
      let existTelegramIdField = csvData[0].includes('$social_telegram_id');

      switch (importType) {
        case IMPORT_TYPES.LEADS:
          if ((!existEmailField && existUserIdField) || (!existTelegramIdField && existUserIdField)) {
            return $q.reject(IMPORT_ERROR_TYPES.HAS_USER_ID);
          }
          break;
        case IMPORT_TYPES.USERS:
          if (!existUserIdField) {
            return $q.reject(IMPORT_ERROR_TYPES.HAS_NOT_USER_ID);
          }
          break;
        case IMPORT_TYPES.TELEGRAM:
          if (!existTelegramIdField) {
            return $q.reject(IMPORT_ERROR_TYPES.HAS_NOT_TELEGRAM_ID);
          }
          break;
      }

      return $q.resolve();
    }

    /**
     * Открытие модалки со строками в которых есть ошибки
     */
    function openRowsWithErrorsModal() {
      $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('leadsImport.rowsWithErrorsModal.heading'),
              body: $translate.instant('leadsImport.rowsWithErrorsModal.body', {
                rowsWithErrors: $filter('join')(vm.invalidRows, ', '),
              }),
              cancelButtonClass: 'btn-primary',
              cancelButtonText: $translate.instant('general.close'),
              confirmButtonClass: 'hidden',
            };
          },
        },
      });
    }

    /**
     * Парсинг заголовков
     *
     * @param {Array} headers - Заголовки
     */
    function parseHeaders(headers) {
      vm.parsedHeaders = [];

      for (var i = 0; i < headers.length; i++) {
        // Если свойство системное - пытаемся найти его в списке свойств пользователя и получить красивое имя
        if (headers[i].indexOf('$') === 0) {
          var userProp = $filter('filter')(vm.userProps, { name: headers[i] })[0];
          vm.parsedHeaders.push((userProp && userProp.prettyName) || headers[i]);
        } else {
          vm.parsedHeaders.push(headers[i]);
        }
      }
    }

    /**
     * Проверка строк на валидность
     *
     * @param {Array} csvData - Данные из файла
     *
     * @returns {Promise}
     */
    function validateCsvData(csvData) {
      for (var i = 1; i < csvData.length; i++) {
        // Проверяет чтобы количество данных равнялось количеству заголовков
        if (csvData[0].length !== csvData[i].length) {
          return $q.reject(IMPORT_ERROR_TYPES.DIFFERENT_NUMBER_OF_COLUMNS);
        }
      }
      return $q.resolve();
    }

    /**
     * Считает строки с ошибками
     *
     * @param {Array} csvData - Данные из файла
     * @param {IMPORT_TYPES} importType - Тип импорта
     *
     */
    function countInvalidRows(csvData, importType) {
      vm.invalidRows = [];

      for (var i = 1; i < csvData.length; i++) {
        // Если данные из строки не валидны => увеличить счетчик ошибок
        if (!isCsvRowDataValid(csvData[i], csvData[0], importType)) {
          vm.invalidRows.push(i + 1);
        }
      }

      /**
       * Валидация данных из строки CSV
       *
       * @param {Object} row - Строка с данными
       * @param  {Array} headers - Заголовки
       * @param  {IMPORT_TYPES} importType - Тип импорта
       *
       * @returns {boolean}
       */
      function isCsvRowDataValid(row, headers, importType) {
        if (importType === IMPORT_TYPES.LEADS) {
          // Супер простая регулярка проверки емейла
          return /^\S+@\S+.\S+$/.test(row[headers.indexOf('$email')]);
        } else if (importType === IMPORT_TYPES.USERS) {
          return !!row[headers.indexOf('$user_id')].length;
        } else if (importType === IMPORT_TYPES.TELEGRAM) {
          return !!row[headers.indexOf('$social_telegram_id')].length;
        }
      }
    }

    /**
     * Удаление тега из списка импортируемых тегов
     * @param tag
     */
    function removeTag(tag) {
      vm.importTags.splice(vm.importTags.indexOf(tag), 1);
    }

    /**
     * Получение имени бота ТГ интеграции
     * @param integration
     * @return {string}
     */
    function getBotName(integration) {
      return (integration && integration.settings && integration.settings.botName) || '';
    }

    /**
     * Выставление типа импорта
     * @param {String} importType Тип импорта
     */
    function setImportType(importType) {
      vm.importType = importType;
    }

    /**
     * Трек смены типа импорта
     * @param {String} importType Тип импорта
     */
    function trackChangeImportType(importType) {
      carrotquestHelper.track('Импорт лидов - выбор импорта пользователей', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(importType),
      });
    }

    /**
     * Трек клика на кпоку "Назад"
     */
    function trackClickBackwardButton() {
      carrotquestHelper.track('Импорт лидов - клик по ссылке - назад', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек выбора другого файла
     */
    function trackClickChooseAnotherFile() {
      carrotquestHelper.track('Импорт лидов - клик по ссылке - выбрать другой файл', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек выбора файла
     */
    function trackClickChooseFile() {
      carrotquestHelper.track('Импорт лидов - клик по ссылке - выбор файла ', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек импорта
     *
     * @param {Number} usersCount - Количество лидов
     */
    function trackClickImport(usersCount) {
      carrotquestHelper.track('Импорт лидов - импортировать лиды', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
        'Количество лидов': usersCount,
      });
    }

    /**
     * Трек клика по ссылке  "Подробнее об импорте лидов"
     */
    function trackClickMoreAboutImport() {
      carrotquestHelper.track('Импорт лидов - клик по ссылке "Подробнее об импорте лидов"', {
        App: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на "Показать ошибки"
     */
    function trackClickShowErrors() {
      carrotquestHelper.track('Импорт лидов - клик по ссылке - показать ошибки', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
        'Количество ошибок': vm.invalidRows.length,
      });
    }

    /**
     * Трек открытия шаблона
     */
    function trackClickTemplateImportFile() {
      carrotquestHelper.track('Импорт лидов - открыть шаблон файла для импорта', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек ошибки разного количества столбцов
     */
    function trackDifferentNumberOfColumns() {
      carrotquestHelper.track('Импорт лидов - ошибка - в заголовке и строках разное количество столбцов', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек конца запроса на импорт
     */
    function trackFinishImport() {
      carrotquestHelper.track('Импорт лидов - импорт запущен - переход', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек конца парсинга
     *
     * @param {Number} usersCount - Количество лидов
     */
    function trackFinishParse(usersCount) {
      carrotquestHelper.track('Импорт лидов - переход в превью', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
        'Количество лидов': usersCount,
      });
    }

    /**
     * Трек ошибки импорта лидов
     */
    function trackLeadsImportError() {
      carrotquestHelper.track('Импорт лидов - ошибка - в файле не должно быть столбца $user_id', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек ошибки превышения размера файла
     */
    function trackMaxFileSizeError() {
      carrotquestHelper.track('Импорт лидов - ошибка - файл больше 30 мегабайт', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек ошибки "нет данных для импорта"
     */
    function trackNoDataImport() {
      carrotquestHelper.track('Импорт лидов - ошибка - в файле не найдены данные для импорта', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек ошибки импорта пользователей
     */
    function trackUsersImportError() {
      carrotquestHelper.track('Импорт лидов - ошибка - нет столбца User_ID,  при импорте пользователей', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }

    /**
     * Трек ошибки импорта пользователей
     */
    function trackTelegramImportError() {
      carrotquestHelper.track('Импорт лидов - ошибка - нет столбца Social_telegram_ID,  при импорте пользователей', {
        App: vm.currentApp.name,
        'Тип импорта': getImportNameForTrack(vm.importType),
      });
    }
  }
})();

import { Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';

type BadgeFormData = {
  nextBranchLinkId: string | null;
};

export class BadgeForm extends GenericFormGroup<BadgeFormData> {
  nextBranchId: string | null;

  constructor(targetBlock?: Branch) {
    const controls: Controls<BadgeFormData> = {
      nextBranchLinkId: new GenericFormControl(targetBlock?.linkId ?? null, Validators.required),
    };
    super(controls);

    this.nextBranchId = targetBlock?.id ?? null;
  }

  public get showError$(): Observable<boolean> {
    return combineLatest([this.nextBranchIdTouchedInvalidChanges$]).pipe(
      map((statuses) => statuses.reduce((acc, val) => acc || val, false)),
      distinctUntilChanged(),
    );
  }

  /**
   * Валидация
   * @private
   */
  private get nextBranchIdTouchedInvalidChanges$(): Observable<boolean> {
    return combineLatest([
      extractTouchedChanges(this.controls.nextBranchLinkId).pipe(startWith(this.controls.nextBranchLinkId.touched)),
      this.controls.nextBranchLinkId.statusChanges.pipe(
        startWith(this.controls.nextBranchLinkId.status),
        map((status) => status === 'INVALID'),
      ),
    ]).pipe(map((statuses) => statuses.reduce((acc, val) => acc && val, true)));
  }
}

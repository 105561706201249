<ng-container *transloco="let t; read: 'companyInfoControlComponent'">
  <div
    class="form-control-container"
    [cqShowError]="control"
  >
    <label for="companyInfo">{{ t('label') }}</label>
    <div class="position-relative">
      <textarea
        class="form-control no-resize custom-scroll"
        [formControl]="control"
        [placeholder]="t('textarea.placeholder')"
        id="companyInfo"
        rows="2"
      ></textarea>
      <span class="counter text-secondary">{{ control.value?.length || '0' }}/{{ COMPANY_INFO_MAX_LENGTH }}</span>
    </div>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('textarea.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

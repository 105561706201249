import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationService } from '@http/integration/services/integration.service';
import { IntegrationIconModule } from '@panel/app/pages/integrations/integration-icon/integration-icon.module';
import { IntegrationChatBotsTriggerBlocksModule } from '@panel/app/pages/integrations/partials/integration-chat-bots-trigger-blocks/integration-chat-bots-trigger-blocks.module';
import { IntegrationDescriptionActionsModule } from '@panel/app/pages/integrations/partials/integration-description-actions/integration-description-actions.module';
import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { IntegrationOAuthHandlerModule } from '@panel/app/pages/integrations/partials/integration-o-auth-handler/integration-o-auth-handler.module';
import { IntegrationTriggerMessagesModule } from '@panel/app/pages/integrations/partials/integration-trigger-messages/integration-trigger-messages.module';
import { OAuthButtonModule } from '@panel/app/pages/integrations/partials/o-auth-button/o-auth-button.module';
import { DestroyService } from '@panel/app/services';
import { OAuthWindowService } from '@panel/app/services/oauth-window/o-auth-window.service';
import { ConfirmModalModule } from '@panel/app/shared/modals/confirm-modal/confirm-modal.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ConfirmModalModule,
    IntegrationChatBotsTriggerBlocksModule,
    IntegrationDescriptionActionsModule,
    IntegrationIconModule,
    IntegrationNameControlModule,
    IntegrationOAuthHandlerModule,
    IntegrationTriggerMessagesModule,
    OAuthButtonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
  ],
  providers: [DestroyService, IntegrationService, OAuthWindowService],
})
export class IntegrationsPartialsModule {}

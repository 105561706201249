import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { WINDOW } from '@ng-web-apis/common';
import { UIRouter } from '@uirouter/core';
import { finalize } from 'rxjs/operators';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { VK_FORMS_INTEGRATION_DEFAULT_FORM } from '@http/integration/integrations/vk-forms/constants/vk-forms-integration.constants';
import { VkFormsFormForm } from '@http/integration/integrations/vk-forms/forms/vk-forms-integration.form';
import { VkFormsIntegrationExternal } from '@http/integration/integrations/vk-forms/interfaces/vk-forms-integration.interfaces';
import { VkFormsIntegration } from '@http/integration/integrations/vk-forms/vk-forms-integration';
import { IntegrationService } from '@http/integration/services/integration.service';
import { SystemToastService } from '@panel/app/services';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { App } from '@http/app/app.model';
import { Properties } from '@http/property/property.model';

@Component({
  selector: 'cq-vk-forms[currentApp][integrationExternal][properties]',
  templateUrl: './vk-forms.component.html',
  styleUrls: ['./vk-forms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Текущая или новая интеграция во внешнем формате */
  @Input() integrationExternal!: VkFormsIntegrationExternal;
  /** Список свойств и событий пользователей */
  @Input() properties!: Properties;
  /** Инстанс текущей или новой интеграции во внутреннем формате */
  integration!: VkFormsIntegration;
  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;
  /** Режим редактирования интеграции */
  isEdit!: boolean;
  /** Свёрнута или нет инструкция по настройке интеграции */
  isManualExpanded!: boolean;
  /** Флаг выполнения запроса */
  isRequestPerformed: boolean = false;
  /** Адрес для вставки в настройки Callback API */
  webhookUrl!: string;

  // @formatter:off
  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private readonly cdr: ChangeDetectorRef,
    private readonly integrationFactory: IntegrationFactory,
    private readonly integrationService: IntegrationService,
    private readonly systemToastService: SystemToastService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly uiRouter: UIRouter,
  ) {}
  // @formatter:on

  /** Получение контрола с API-token'ом интеграции */
  get apiTokenControl(): GenericFormControl<string | null> {
    return this.integration.form.controls.settings.controls.apiToken;
  }

  /** Получение контрола с confirm-cod'ом интеграции */
  get confirmCodeControl(): GenericFormControl<string | null> {
    return this.integration.form.controls.settings.controls.confirmCode;
  }

  /** Получение контрола с формами интеграции */
  get formsArray() {
    return this.integration.form.controls.settings.controls.forms;
  }

  /** Получение контрола с названием интеграции */
  get nameControl() {
    return this.integration.form.controls.name;
  }

  /** Получение контрола с настройками интеграции */
  get settingsControl() {
    return this.integration.form.controls.settings;
  }

  /** Получение контрола с токеном для вебхука */
  get tokenControl() {
    return this.integration.form.controls.settings.controls.token;
  }

  ngOnInit(): void {
    this.isEdit = !!this.integrationExternal.id;
    this.isManualExpanded = !!this.integrationExternal.id;

    if (this.isEdit) {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.VK_FORMS, this.integrationExternal);
    } else {
      this.integration = this.integrationFactory.create(INTEGRATION_TYPES.VK_FORMS);
    }

    this.webhookUrl = this.integrationExternal.id ? this.getWebhookUrl() : '';
  }

  /** Добавление формы */
  addForm() {
    this.formsArray.push(new VkFormsFormForm(VK_FORMS_INTEGRATION_DEFAULT_FORM));
  }

  /** Создание интеграции */
  create(): void {
    this.isRequestPerformed = true;

    this.integrationService
      .create(this.currentApp.id, this.integration)
      .pipe(
        finalize(() => {
          this.isRequestPerformed = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        (integration) => {
          this.toastService.success(
            this.translocoService.translate('integrations.integration.toasts.created', {
              integrationTypeName: this.translocoService.translate(
                'models.integration.types.' + integration.type + '.name',
              ),
            }),
          );
          this.goToConfiguredIntegration(integration);
        },
        () => {
          this.systemToastService.requestError();
        },
      );
  }

  /**
   * Получение строки с адресом сервера
   */
  getWebhookUrl(): string {
    return `${environment.webhooksEndpoint}/vk_forms/${this.integrationExternal.id}/${this.tokenControl.value}`;
  }

  /**
   * Перенаправление на настроенную интеграцию, после её создания
   *
   * @param integration - Созданная интеграция
   */
  goToConfiguredIntegration(integration: VkFormsIntegration) {
    const params = {
      integrationType: integration.type,
      integrationId: integration.id,
    };

    this.uiRouter.stateService.go(`app.content.integrations.details.configured.${integration.type}`, params, {
      location: 'replace', // Чтобы кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
    });
  }

  /** Отправка формы с настройками интеграции */
  onSubmit(): void {
    if (this.integration.form.valid) {
      this.isEdit ? this.save() : this.create();
    } else {
      this.integration.form.markAllAsTouched();
    }
  }

  /**
   * Удаление формы
   *
   * @param index - Индекс формы в массиве форм
   */
  removeForm(index: number): void {
    this.formsArray.removeAt(index);
  }

  /** Сохранение интеграции */
  save(): void {
    this.isRequestPerformed = true;

    this.integrationService
      .save(this.currentApp.id, this.integration)
      .pipe(
        finalize(() => {
          this.isRequestPerformed = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        () => {
          this.toastService.success(this.translocoService.translate('integrations.integration.toasts.saved'));
        },
        () => {
          this.systemToastService.requestError();
        },
      );
  }
}

<ng-container *transloco="let t; read: 'legacyWebhookPartEditorComponent'">
  <!--URL-адрес, куда отправлять запрос-->
  <div
    class="form-group my-25"
    [cqShowError]="urlForm"
  >
    <h4 class="mb-10">
      {{ t('urlInput.label') }}
    </h4>
    <div class="mb-15">{{ t('urlInput.labelDescription') }}</div>
    <input
      [formControl]="urlForm"
      class="form-control"
      [placeholder]="t('urlInput.placeholder')"
    />
    <cq-validation-messages [control]="urlForm">
      <cq-validation-message errorName="required">
        {{ t('urlInput.errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('urlInput.errors.maxlength', { maxLength: URL_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

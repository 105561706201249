<ng-container *transloco="let t">
  <div class="card align-items-center">
    <div class="card-body">
      <cq-vk-forms
        #vkFormsComponent
        [currentApp]="currentApp"
        [integrationExternal]="integrationExternal"
        [properties]="properties"
      ></cq-vk-forms>
    </div>
  </div>
  <div class="text-right">
    <button
      class="btn btn-primary"
      [attr.disabled]="vkFormsComponent.isRequestPerformed ? true : null"
      type="button"
      (click)="vkFormsComponent.onSubmit()"
    >
      {{ t('models.integration.actions.save') }}
    </button>
  </div>
</ng-container>

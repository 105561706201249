(function () {
  'use strict';

  require('./list.controller');
  require('./triggers-popover.html');

  angular.module('myApp.chatBot').component('cqChatBotList', cqChatBotList());

  function cqChatBotList() {
    return {
      bindings: {
        billingInfo: '=', // Информация о биллинге
        botSender: '=', // Отправитель бота
        chatBotsAll: '=', // Список всех лид-ботов
        chatSettings: '=', // Настройки чата
        currentApp: '=', // Текущее приложение
        dataAboutActiveFacebookBots: '=', // Информация об активных Facebook ботах
        dataAboutActiveLeadBots: '=', // Информация об активных Lead ботах
        dataAboutActiveTelegramBots: '=', // Информация об активных Telegram ботах
        dataAboutActiveWelcomeBots: '=', // Информация об активных Welcome ботах
        dataAboutActiveWidgetBots: '=', // Информация об активных Widget ботах
        djangoUser: '=', // Текущий пользователь
        facebookActiveIntegrations: '=', // Список активных интеграций Facebook
        facebookBot: '=', // Facebook-бот
        properties: '=', // список свойств и типов событий
        routingBot: '=', // Роутинг бот
        tags: '=', // список тегов пользователей
        /**
         * Список активных Telegram-ботов
         * @type TelegramBot[]
         */
        telegramBotAllList: '=',
        /**
         * Список интеграций Telegram
         * @type TelegramIntegration[]
         */
        telegramIntegrationList: '=',
        knowledgeBaseCategories: '=',
      },
      controller: 'cqChatBotListController',
      controllerAs: 'vm',
      template: require('./list.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from '@environment';
import {
  VkFormsFormForm,
  VkFormsQuestionForm,
  VkFormsStandardQuestionForm,
} from '@http/integration/integrations/vk-forms/forms/vk-forms-integration.form';
import { VkFormsIntegrationFormExternal } from '@http/integration/integrations/vk-forms/interfaces/vk-forms-integration.interfaces';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { App } from '@http/app/app.model';
import { Properties } from '@http/property/property.model';

@Component({
  selector: 'cq-vk-forms-form[currentApp][form][formIndex][properties][removeForm][showRemoveFormButton]',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsFormComponent {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Форма сбора заявок */
  @Input() form!: VkFormsFormForm;
  /** Порядковый индекс формы сбора заявок */
  @Input() formIndex!: number;
  /** Список свойств и событий пользователей */
  @Input() properties!: Properties;
  /** Показывать ли кнопку удаления формы*/
  @Input() showRemoveFormButton!: boolean;
  /** Callback на удаление формы */
  @Output() removeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Название проекта */
  projectName = environment.projectName;

  /** Получение контрола с названием кастомного события */
  get eventTypeNameControl(): GenericFormControl<VkFormsIntegrationFormExternal['eventTypeName']> {
    return this.form.controls.eventTypeName;
  }

  /** Получение контрола с ссылкой форму */
  get formLinkControl(): GenericFormControl<VkFormsIntegrationFormExternal['formLink']> {
    return this.form.controls.formLink;
  }

  /** Получение контрола с стандартными вопросами */
  get standardQuestionsControl(): GenericFormArray<VkFormsStandardQuestionForm> {
    return this.form.controls.standardQuestions;
  }

  /** Получение контрола с кастомными вопросами */
  get questionsControl(): GenericFormArray<VkFormsQuestionForm> {
    return this.form.controls.questions;
  }
}

(function () {
  'use strict';

  require('./set-template-directory.controller');

  angular
    .module('myApp.modals.templateSetDirectory')
    .component('cqSetTemplateDirectoryModal', cqSetTemplateDirectoryModal());

  function cqSetTemplateDirectoryModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // {Object} currentApp - текущее приложение
        // {Array} directories - Список Папок
        // {String} templateId - ID шаблона
      },
      controller: 'CqSetTemplateDirectoryModalController',
      controllerAs: 'vm',
      template: require('./set-template-directory.html'),
    };
  }
})();

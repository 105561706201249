import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { InstallScriptModalComponent } from '../../../../app/shared/modals/install-script-modal/install-script-modal.component';
import { firstValueFrom } from 'rxjs';

/**
 * Общий контроллер настроек мастера сбора данных
 */

(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventSettingController', CqAutoEventSettingController);

  function CqAutoEventSettingController(
    $translate,
    $state,
    $uibModal,
    toastr,
    carrotquestHelper,
    appModel,
    planFeatureAccessService,
    trackMasterModel,
    modalHelperService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToTrackMasterJsCode = planFeatureAccessService.getAccess(
        PLAN_FEATURE.TRACK_MASTER_JS_CODE,
        vm.currentApp,
      );

      vm.autoEventRemoved = autoEventRemoved;
      vm.requestIsExecuted = false; // Флаг выполнения запроса
      vm.saveOrCreate = saveOrCreate;
      vm.videoIsCollapsed = !!vm.autoEvent.id; // Стостояние свернутости блока с видео
    }

    /**
     * Автособытие удалено
     */
    function autoEventRemoved() {
      trackRemovedAutoEvent();
      toastr.success($translate.instant('autoEventSetting.toastr.removedSuccess'));
      $state.go('app.content.trackmaster');
    }

    /**
     * Сохранение или создание автособытия
     *
     * @param {Boolean} isValid
     */
    function saveOrCreate(isValid) {
      if (isValid && !vm.requestIsExecuted) {
        vm.requestIsExecuted = true;
        if (vm.autoEvent.id) {
          firstValueFrom(trackMasterModel.save(vm.currentApp.id, vm.autoEvent))
            .then(saveSuccess)
            .finally(saveOrCreateFinally);
        } else {
          firstValueFrom(trackMasterModel.create(vm.currentApp.id, vm.autoEvent))
            .then(createSuccess)
            .finally(saveOrCreateFinally);
        }
      }

      function createSuccess() {
        trackCreateAutoEvent();
        toastr.success($translate.instant('autoEventSetting.toastr.createSuccess'));
        $state.go('app.content.trackmaster', { createdInGroup: trackMasterModel.getGroup(vm.autoEvent) });
      }

      function saveSuccess() {
        trackSaveAutoEvent();
        toastr.success($translate.instant('autoEventSetting.toastr.saveSuccess'));
      }

      function saveOrCreateFinally() {
        vm.requestIsExecuted = false;

        if (appModel.isAppBlocked(vm.currentApp)) {
          let modal = modalHelperService.open(InstallScriptModalComponent);

          modal.componentInstance.modalWindowParams = {
            body: $translate.instant('autoEventSetting.installScriptModal.body'),
            currentApp: vm.currentApp,
            djangoUser: vm.djangoUser,
            heading: $translate.instant('autoEventSetting.installScriptModal.heading'),
          };
        }
      }
    }

    /**
     * Трек создания автособытия
     */
    function trackCreateAutoEvent() {
      carrotquestHelper.track('Мастер сбора данных - создал волендеморт');
    }

    /**
     * Трек удаления автособытия
     */
    function trackRemovedAutoEvent() {
      carrotquestHelper.track('Мастер сбора данных - удалить');
    }

    /**
     * Трек сохранения автособытия
     */
    function trackSaveAutoEvent() {
      carrotquestHelper.track('Мастер сбора данных - пересохранил волендеморт');
    }
  }
})();

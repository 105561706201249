import { Component } from '@angular/core';

@Component({
  selector: 'cq-select-preview',
  templateUrl: './select-preview.component.html',
  styleUrls: [],
})
export class SelectPreviewComponent {
  items = [
    {
      id: 1,
      name: 'Первый элемент',
      disabled: true,
      group: 'Группа А',
    },
    {
      id: 2,
      name: 'Второй элемент',
      disabled: true,
      group: 'Группа Б',
    },
    {
      id: 3,
      name: 'Третий элемент',
      group: 'Группа А',
    },
    {
      id: 4,
      name: 'Четвёртый элемент',
      group: 'Группа Б',
    },
    {
      id: 5,
      name: 'Пятый элемент',
      group: 'Группа А',
    },
    {
      id: 6,
      name: 'Шестой элемент',
      group: 'Группа Б',
    },
    {
      id: 7,
      name: 'Седьмой элемент',
      group: 'Группа А',
    },
    {
      id: 8,
      name: 'Восьмой элемент',
      group: 'Группа Б',
    },
    {
      id: 9,
      name: 'Девятый элемент',
      group: 'Группа А',
    },
    {
      id: 10,
      name: 'Десятый элемент',
      group: 'Группа Б',
    },
  ];
  selectedItem: any;
  selectedItems = [this.items[0]];

  constructor() {}
}

<ng-container *transloco="let t; read: 'tgTriggerTypesComponent'">
  <div class="mb-10">{{ t('description') }}</div>
  <div class="margin-between-rows-10">
    <ng-container *ngFor="let triggerTypeKind of tgBotTriggerTypeKinds">
      <div>
        <div class="custom-control custom-radio">
          <input
            class="custom-control-input"
            type="radio"
            [formControl]="formControl"
            [value]="triggerTypeKind"
            [id]="'triggerTypeKind' + triggerTypeKind"
          />
          <label
            class="custom-control-label"
            [for]="'triggerTypeKind' + triggerTypeKind"
          >
            {{ t('radio.' + triggerTypeKind) }}
          </label>
        </div>
        <ng-container *tuiLet="alreadyUsedTriggerTypeBot(triggerTypeKind) | async as alreadyUsedTriggerTypeBot">
          <ng-container *ngIf="alreadyUsedTriggerTypeBot">
            <div
              class="small text-secondary"
              [innerHTML]="
                t('alreadyUsed', {
                  url: getTelegramBotUrl(alreadyUsedTriggerTypeBot),
                  botName: alreadyUsedTriggerTypeBot.name
                })
              "
            ></div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { QuestionPaginationComponent } from './question-pagination.component';

@NgModule({
  declarations: [QuestionPaginationComponent],
  exports: [QuestionPaginationComponent],
  imports: [CommonModule],
})
export class QuestionPaginationModule {}

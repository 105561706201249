import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLetModule } from '@taiga-ui/cdk';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { SharedModule } from '@panel/app/shared/shared.module';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

import { EmailsComponent } from './emails.component';

@NgModule({
  declarations: [EmailsComponent],
  exports: [EmailsComponent],
  imports: [
    CommonModule,
    NgbCollapseModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    SharedModule,
    TranslocoModule,
    TuiLetModule,
  ],
  providers: [CarrotquestHelperProvider, L10nHelperService, SubscriptionStore],
})
export class EmailsModule {}

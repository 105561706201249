<ng-container *transloco="let t">
  <div class="d-flex align-content-center justify-content-center bg-white video">
    <video
      *ngIf="attachment"
      class="full-max-width align-middle"
      controls
    >
      <source [attr.src]="videoUrl" />
    </video>
    <div
      *ngIf="!attachment"
      class="d-flex justify-content-center align-items-center w-100 zero-data"
    >
      {{ t('conversationPartPreviewVideoComponent.zeroData') }}
    </div>
  </div>
</ng-container>

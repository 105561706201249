import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Preview пустого места в реплике */
@Component({
  selector: 'cq-conversation-part-preview-space',
  templateUrl: './conversation-part-preview-space.component.html',
  styleUrls: ['./conversation-part-preview-space.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPartPreviewSpaceComponent {}

import { PSEUDO_CHANNEL_IDS, PSEUDO_CHANNEL_TYPES } from '../../../../app/http/channel/channel.constants';

(function () {
  'use strict';

  angular.module('myApp.services.faviconNotification').factory('faviconNotification', faviconNotification);

  function faviconNotification($translate, l10nHelper, utilsService) {
    return {
      setFavicon: setFavicon,
    };

    /**
     * Задает favicon в зависомости
     *
     * @param {Object<Object>} channelCounters - Счётчики непрочтённых сообщений и неназначенных диалогов для каждого канала
     */
    function setFavicon(channelCounters) {
      if (utilsService.webApp) {
        let faviconNotificationType = ''; // Тип фавиконки уведомления
        const project = l10nHelper.isUsCountry() ? 'dashly' : 'carrot-quest'; // имя проекта

        if (channelCounters && channelCounters.notAssigned[PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]] > 0) {
          faviconNotificationType =
            '-' +
            (channelCounters.notAssigned[PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]] > 99
              ? '99+'
              : channelCounters.notAssigned[PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]]);
        } else if (
          channelCounters &&
          channelCounters.notRead[PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]] > 0
        ) {
          faviconNotificationType = '-noreplied';
        }

        document.querySelector(
          "link[rel*='icon']",
        ).href = `assets/img/default/favicons/${project}/favicon${faviconNotificationType}.png`;
      }
    }
  }
})();

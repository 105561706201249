import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';

@Injectable()
export class OverlayScrollbarHelperService {
  isScrollStart$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Эмитит на скролл внтруи overlay-scrollbars компонента, данные - DOMRect элемента
   */
  overlayScroll$?: Observable<DOMRect>;

  constructor(
    @Inject(DOCUMENT)
    document: Document,
    @Inject(DestroyService)
    destroy$: DestroyService,
  ) {
    const overlayScrollEl = document.querySelector(
      'cq-branch-editor div.os-viewport.os-viewport-native-scrollbars-overlaid',
    );
    if (!overlayScrollEl) {
      return;
    }
    this.overlayScroll$ = fromEvent(overlayScrollEl, 'scroll').pipe(
      startWith(null),
      map(() => overlayScrollEl.getBoundingClientRect()),
      takeUntil(destroy$),
    );
  }

  /**
   *
   */
  visibilityChange$({ nativeElement }: ElementRef): Observable<boolean> {
    if (!this.overlayScroll$) {
      return of(true);
    }
    return this.overlayScroll$.pipe(
      map(({ height: overlayHeight, top: overlayTop }) => {
        const { height: nHeight, top: nTop } = nativeElement.getBoundingClientRect();
        return overlayHeight + overlayTop > nHeight + nTop;
      }),
      distinctUntilChanged(),
    );
  }
}

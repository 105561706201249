<ng-container *transloco="let t; read: 'botCustomPlaceholderComponent'">
  <label class="font-weight-normal small">{{ t('label') }}</label>
  <ng-select
    [formControl]="type"
    [items]="options"
    [clearable]="false"
    [searchable]="false"
  >
    <ng-template
      let-option="item"
      ng-option-tmp
      ng-label-tmp
    >
      <!-- Когда маска для телефона раскатиться для всех, раскомментировать -->
      <!--      <i-->
      <!--        *ngIf="option === OPTIONS.PHONE"-->
      <!--        class="cqi-chat cqi-chevron-down"-->
      <!--      ></i>-->
      {{ t('options.' + option) }}
    </ng-template>
  </ng-select>

  <div
    [hidden]="!isCustom"
    [cqShowError]="value"
  >
    <input
      [formControl]="value"
      [placeholder]="t('customOption.placeholder')"
      [ngClass]="{ 'margin-top-10': isCustom }"
      class="form-control"
      type="text"
    />
    <cq-validation-messages [control]="value">
      <cq-validation-message errorName="required">{{ t('customOption.errors.required') }}</cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('customOption.errors.maxlength', { maxLength: CUSTOM_PLACEHOLDER_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>

  <small class="text-muted d-inline-block margin-top-5">{{ t('hint') }}</small>
</ng-container>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { UserNote } from '@http/user-note/user-note.types';

/** Компонент заметки пользователя */
@Component({
  selector: 'cq-user-note[note]',
  templateUrl: './user-note.component.html',
  styleUrls: ['./user-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNoteComponent {
  /** Заметка о пользователе */
  @Input() note!: UserNote;

  /** Колбэк на удаление заметки */
  @Output() removeNote: EventEmitter<UserNote> = new EventEmitter<UserNote>();

  /** Отформатированная дата создания заметки */
  get userNoteCreatedDateFormatted(): string {
    return this.note.created.format('lll');
  }

  /** Удаление заметки */
  removeUserNote(): void {
    this.removeNote.emit(this.note);
  }
}

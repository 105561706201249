import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { App } from '@http/app/app.model';
import { Billing } from '@http/billing/billing';
import { DjangoUser } from '@http/django-user/django-user.types';
import { Invoice } from '@http/invoice/invoice';
import { Plan } from '@http/plan/plan';
import { Quotas } from '@http/quotas/quotas';
import { PaginatableElements } from '@http/types';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Компонент для работы со страницей оплаты */
@Component({
  selector: 'cq-subscription-new[billing][currentApp][djangoUser][invoices][plan][quotas]',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionComponent implements OnInit {
  /** Биллинг */
  @Input()
  billing!: Billing;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Django пользователь */
  @Input()
  djangoUser!: DjangoUser;

  /** Счета */
  @Input()
  invoices!: PaginatableElements<Invoice>;

  /** Тарифный план */
  @Input()
  plan!: Plan;

  /** Квоты */
  @Input()
  quotas!: Quotas;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly planVersionService: PlanVersionService,
    private readonly subscriptionStore: SubscriptionStore,
  ) {}

  ngOnInit() {
    this.trackEnteringPage();

    this.subscriptionStore.app$.next(this.currentApp);
    this.subscriptionStore.availableAddons$.setAll(this.plan.attachedAddons);
    this.subscriptionStore.billing$.next(this.billing);
    this.subscriptionStore.connectedAddons$.setAll(this.billing.subscription.connectedAddons);
    this.subscriptionStore.djangoUser$.next(this.djangoUser);
    this.subscriptionStore.invoices$.setAll(this.invoices.elements, this.invoices.paginatorParameters);
    this.subscriptionStore.plan$.next(this.plan);
    this.subscriptionStore.quotas$.next(this.quotas);
  }

  /** Трекинг захода на страницу */
  trackEnteringPage() {
    const eventName1 = 'Оплата - зашел на страницу';

    this.carrotquestHelper.track(eventName1);

    if (this.planVersionService.isV0421(this.currentApp)) {
      const eventName2 = 'оплата - зашел на страницу с тарифом v0421';

      this.carrotquestHelper.track(eventName2);
    }
  }
}

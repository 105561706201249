import { ChatBotActionMapper } from 'app/http/chat-bot/mappers/action.mapper';
import orderBy from 'lodash-es/orderBy';
import { generate } from 'short-uuid';

import {
  ACTIONS_GROUPS,
  CHAT_BOT_ACTIONS_TYPES,
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP,
} from '@http/chat-bot/chat-bot.constants';
import { DefaultActionHelper } from '@http/chat-bot/helpers-for-gerenation-of-defaults/default-action.helper';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { ApiChatBotBranchRequest, ApiChatBotBranchResponse } from '@http/chat-bot/types/branch-external.types';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';

export class ChatBotBranchMapper {
  /**
   * Парсинг ветки бота в серверный формат
   *
   * @param branch - Ветка бота
   * @param isInterruptedBranch - является ли ветка перерывающей
   */
  static parseToServerFormat(branch: ChatBotBranch, isInterruptedBranch: boolean = false): ApiChatBotBranchRequest {
    let parsedBranch: ApiChatBotBranchRequest = {
      name: branch.name || '',
      actions: [],
      coordinates: branch.coordinates
        ? {
            x: branch.coordinates.x,
            y: branch.coordinates.y,
          }
        : undefined,
    };

    if (branch.id) {
      parsedBranch.id = branch.id;
    } else {
      parsedBranch.link_id = branch.linkId;
    }

    for (let i = 0; i < branch.actions.length; i++) {
      const action: ChatBotAction = branch.actions[i];
      // сервер ничего не знает о CLOSE, поэтому убираем из DTO
      if (action.type === CHAT_BOT_ACTIONS_TYPES.CLOSE) {
        continue;
      }
      // У ветки прерывания нарисован такой экшен, на самом деле его нет (для бека)
      if (action.type === CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE && isInterruptedBranch) {
        continue;
      }

      parsedBranch.actions.push(ChatBotActionMapper.parseToRequestFormat(action));
    }

    return parsedBranch;
  }

  static parseToInternalFormat(branch: ApiChatBotBranchResponse, isInterruptedBranch: boolean = false): ChatBotBranch {
    // Парсим действия и сортируем их в нужном порядке
    const parsedAndOrderedActions = orderBy(
      branch.actions.map((value) => ChatBotActionMapper.parseToInternalFormat(value)),
      ['isReplyAction', 'order'],
    );

    const parsedBranch: ChatBotBranch = {
      id: branch.id,
      linkId: branch.id ?? generate(),
      name: branch.name,
      parentBranchIds: [],
      actions: parsedAndOrderedActions,
      isInvalid: false,
      coordinates: branch.coordinates === undefined ? null : branch.coordinates,
    };

    /**
     * Если у блока нет "целевого действия", то ему нужно такое добавить для правильной работы/отображения на фронте
     */
    const hasTargetActions = !!parsedBranch.actions.find((a) => {
      return CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[ACTIONS_GROUPS.TARGET_SHOW].includes(a.type);
    });

    if (hasTargetActions) {
      return parsedBranch;
    }

    // Для блоков условий и действий и встречи не нужно добавлять фронтовое действие
    if (['condition', 'action', 'meeting'].includes(Branch.getBlockType(parsedBranch))) {
      return parsedBranch;
    }

    // Если в боте нет целевых действий => это последний/прерывания блок и надо добавить фронтовое действие
    if (isInterruptedBranch) {
      parsedBranch.actions.push(DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE));
    } else {
      parsedBranch.actions.push(DefaultActionHelper.getDefaultAction(CHAT_BOT_ACTIONS_TYPES.CLOSE));
    }

    return parsedBranch;
  }
}

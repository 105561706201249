export enum EMAIL_KARMA_STATUSES {
  GOOD = 'good',
  NOT_GOOD = 'not_good',
  ALMOST_BAD = 'almost_bad',
  BLOCKED = 'blocked',
}

export enum EMAIL_KARMA_STATUSES_TYPES {
  EQUAL = 'equal',
  BOUNCE_RATE = 'bounceRate',
  COMPLAINTS_RATE = 'complaintsRate',
}

export const EMAIL_KARMA_STATUSES_ORDER: Record<EMAIL_KARMA_STATUSES, number> = {
  [EMAIL_KARMA_STATUSES.GOOD]: 1,
  [EMAIL_KARMA_STATUSES.NOT_GOOD]: 2,
  [EMAIL_KARMA_STATUSES.ALMOST_BAD]: 3,
  [EMAIL_KARMA_STATUSES.BLOCKED]: 4,
};

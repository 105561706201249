import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  UntypedFormArray,
  ValidatorFn,
} from '@angular/forms';

/**
 * @deprecated используй новые CVA-классы
 */
export class GenericFormArray<T extends AbstractControl> extends UntypedFormArray {
  controls!: T[];

  constructor(
    controls: T[],
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(controls, validatorOrOpts, asyncValidator);
  }

  push(control: T): void {
    super.push(control);
  }

  at(index: number): T {
    return super.at(index) as T;
  }

  insert(index: number, control: T): void {
    super.insert(index, control);
  }
}

<ng-container *transloco="let t; read: 'albatoSettingsComponent'">
  <div>
    <h3 class="mb-10">{{ t('steps.1.title') }}</h3>
    <div
      class="mb-15"
      [innerHTML]="t('steps.1.description', {integrationTypeName})"
    ></div>
    <img
      class="full-max-width rounded"
      [src]="'assets/img/' + activeLang + '/integrations/albato/albato-1.jpg'"
    />
  </div>

  <hr />
  <div>
    <h3 class="mb-10">{{ t('steps.2.title') }}</h3>
    <div class="mb-15">
      {{t('steps.2.description', {
      integrationTypeName,
      projectName
    })}}
    </div>
    <img
      class="full-max-width rounded"
      [src]="'assets/img/' + activeLang + '/integrations/albato/albato-2.jpg'"
    />
  </div>

  <hr />
  <div>
    <h3 class="mb-10">{{ t('steps.3.title') }}</h3>
    <div class="mb-15">{{t('steps.3.description', {integrationTypeName})}}</div>
    <img
      class="full-max-width rounded mb-15"
      [src]="'assets/img/' + activeLang + '/integrations/albato/albato-3.jpg'"
    />

    <div class="form-group">
      <label class="mb-10">{{ t('steps.3.settings.appId.label') }}</label>
      <cq-input-copy-button
        [inputValue]="currentApp.id"
        data-test="input-copy-button-for-app-id"
      ></cq-input-copy-button>
    </div>

    <div class="form-group">
      <label class="mb-10">{{ t('steps.3.settings.authToken.label') }}</label>
      <cq-albato-auth-token
        *ngIf="!authToken"
        [currentApp]="currentApp"
        (authTokenGenerate)="onAuthTokenGenerate($event)"
        data-test="albato-auth-token"
      ></cq-albato-auth-token>

      <cq-input-copy-button
        *ngIf="authToken"
        [inputValue]="authToken"
        data-test="input-copy-button-for-auth-token"
      ></cq-input-copy-button>
    </div>
  </div>

  <hr />
  <div>
    <h3 class="mb-10">{{ t('steps.4.title') }}</h3>
    <div [innerHTML]="t('steps.4.description', {integrationTypeName, projectName})"></div>
  </div>
</ng-container>

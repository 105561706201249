(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupVideoBlockPreviewController', CqPopupVideoBlockPreviewController);

  function CqPopupVideoBlockPreviewController($scope) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.parsedParams = null; // скорректированные параметры поп-апа

      $scope.$watchCollection('vm.params', parseParams);
    }

    /**
     * Парсинг исходных параметров блока
     *
     * @param {Object} params Параметры блока
     */
    function parseParams(params) {
      if (params) {
        vm.parsedParams = angular.copy(params);
        if (params.embeddableUrl) {
          var parsedEmbeddableUrl = new URI(params.embeddableUrl);
          // Убираю из превью автоплей, чтобы сервис, которые делает скриншоты, смог его сделать.
          // Без этого он не может его сделать, т.к. ждёт в течение 5 секунд окончания всех запросов.
          // А с автовоспроизведением запросы не заканчиваются
          parsedEmbeddableUrl.removeSearch('autoplay');
          parsedEmbeddableUrl.removeSearch('mute');
          parsedEmbeddableUrl.removeSearch('muted');
          vm.parsedParams.embeddableUrl = parsedEmbeddableUrl.toString();
        }
      }
    }
  }
})();

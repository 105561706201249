import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '@environment';

/**
 * Пайп для трансформации числа в денежную стоимость
 */

@Pipe({
  name: 'toFormattedMoney',
})
export class ToFormattedMoneyPipe implements PipeTransform {
  transform(value: number, digits?: number): string {
    return new Intl.NumberFormat(environment.country, {
      style: 'currency',
      currency: environment.currency.currency,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: digits ?? 2,
    }).format(value);
  }
}

import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

/**
 * Контроллер редактора шаблонов
 */

(function () {
  'use strict';

  angular
    .module('myApp.readyMessageTemplates')
    .controller('CqReadyMessageTemplateEditorController', CqReadyMessageTemplateEditorController);

  function CqReadyMessageTemplateEditorController(
    $filter,
    $state,
    $uibModal,
    $translate,
    $q,
    toastr,
    ACTIONS_ON_DIRECTORY,
    MESSAGE_TEMPLATES_TABS,
    USER_FILES_URL,
    carrotquestHelper,
    featureModel,
    messageModel,
    messagePartModel,
    messageTemplateModel,
    planFeatureAccessService,
    popupBlockModel,
    utilsModel,
    validationHelper,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToReadyAutoMessage = planFeatureAccessService.getAccess(PLAN_FEATURE.READY_AUTO_MESSAGES, vm.currentApp);

      trackEnterOnPage();
      vm.createAutoMessage = createAutoMessage;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.openCreateTemplateModal = openCreateTemplateModal;
      vm.trackClickCreateAutoMessage = trackClickCreateAutoMessage;
      vm.validationHelper = validationHelper;

      initLinkBlockPopupWithData();
    }

    /**
     * Связывание блоков поп-апа с типами событий и свойствами пользователя
     */
    function initLinkBlockPopupWithData() {
      if (!!~[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].indexOf(vm.messagePart.type)) {
        var blockPopup = vm.messagePart[vm.messagePart.type];
        var popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

        blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

        for (var j = 0; j < popupBlocks.length; j++) {
          var popupBlock = popupBlocks[j];

          popupBlockModel.linkWithEventTypes(popupBlock, vm.properties.eventTypes);
          popupBlockModel.linkWithUserProperties(popupBlock, vm.properties.userProps);
        }
      }
    }

    /**
     * Открытие модалки сохранения готового шаблона в простой шаблон
     */
    function openCreateTemplateModal() {
      if (vm.messagePart) {
        var createTemplateModal = $uibModal.open({
          component: 'cqSaveReadyMessageTemplateAsTemplateModal',
          resolve: {
            currentApp: angular.bind(null, angular.identity, vm.currentApp),
            directories: angular.bind(null, angular.identity, vm.directories),
            messagePart: angular.bind(null, angular.identity, vm.messagePart),
          },
        });
        createTemplateModal.result.then(createTemplate);
      }

      function createTemplate(template) {
        trackSaveAsTemplate(template.name);
        toastr.success($translate.instant('readyMessageTemplateEditor.toasts.createTemplateSuccess'));
      }
    }

    /**
     * Создание автосообщения на основе готового шаблона
     *
     * @param {Boolean} valid - Валидность формы
     */
    function createAutoMessage(valid) {
      if (!valid) {
        vm.messagePart.handleError && vm.messagePart.handleError();
        return;
      }

      trackClickCreateAutoMessage();
      $state.go('app.content.messagesAjs.createOnReadyTemplate', { readyTemplateId: vm.template.id });
    }

    /**
     * Трек клика на кнопку "создать автосообщение"
     */
    function trackClickCreateAutoMessage() {
      carrotquestHelper.track('Шаблоны - клик на создать автосообщение из готового шаблона', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
      });
    }

    /**
     * Трек создания шаблона
     */
    function trackCreate() {
      carrotquestHelper.track('Шаблоны - создал шаблон', {
        App: vm.currentApp.name,
        app_id: vm.currentApp.id,
        Название: vm.template.name,
        Тип: vm.template.type,
      });
    }

    /**
     * Трек захода на страницу
     */
    function trackEnterOnPage() {
      carrotquestHelper.track('Шаблоны - зашел на шаблон', {
        App: vm.currentApp.name,
        'Источник шаблона': MESSAGE_TEMPLATES_TABS.TEMPLATE_GALLERY,
        'Тип сайта': vm.template.appType,
        id_шаблона: vm.template.id,
      });
    }

    /**
     * Трек добавления готового шаблона в сохраненные шаблоны
     *
     * @param {String} templateName - Имя шаблона
     */
    function trackSaveAsTemplate(templateName) {
      carrotquestHelper.track('Шаблоны - сохранить готовый шаблон в сохраненные шаблоны', {
        App: vm.currentApp.name,
        Название: templateName,
        Тип: vm.messagePart.type,
        id_шаблона: vm.template.id,
      });
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { MemberInvitationModule } from '@panel/app/partials/modals/member-invitation/member-invitation/member-invitation.module';

import { MemberInvitationModalComponent } from './member-invitation-modal.component';

@NgModule({
  declarations: [MemberInvitationModalComponent],
  exports: [MemberInvitationModalComponent],
  imports: [CommonModule, MemberInvitationModule, TranslocoModule],
  providers: [NgbActiveModal],
})
export class MemberInvitationModalModule {}

(function () {
  'use strict';

  require('./zendesk.controller');

  angular.module('myApp.integrations').component('cqZendesk', cqZendesk());

  function cqZendesk() {
    return {
      bindings: {
        currentApp: '=', // Текущий App
        integration: '=', // Текущая интеграция
        properties: '=', // Свойства
      },
      controller: 'CqZendeskController',
      controllerAs: 'vm',
      template: require('./zendesk.html'),
    };
  }
})();

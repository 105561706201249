import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { WaChatIconModalComponent } from './wa-chat-icon-modal.component';

@NgModule({
  declarations: [WaChatIconModalComponent],
  exports: [WaChatIconModalComponent],
  imports: [CommonModule, TranslocoModule],
})
export class WaChatIconModalModule {}

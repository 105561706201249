import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { TransitionOptions, UIRouter } from '@uirouter/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { CHECK_INTERVAL_CLOSING_OAUTH_WINDOW_MS } from '@panel/app/pages/integrations/partials/o-auth-button/o-auth-button.constants';
import { DestroyService } from '@panel/app/services';
import { OAuthWindowService } from '@panel/app/services/oauth-window/o-auth-window.service';

/**
 * Компонент-кнопка для работы с OAuth авторизацией
 */

@Component({
  selector: 'cq-o-auth-button[oAuthUrl]',
  templateUrl: './o-auth-button.component.html',
  styleUrls: ['./o-auth-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class OAuthButtonComponent {
  /** Размер кнопки */
  @Input()
  buttonSize?: 'sm' | 'lg';

  /** Авторизован ли пользователь */
  @Input()
  isAuthorized: boolean = false;

  /** OAuth Url для авторизации */
  @Input()
  oAuthUrl!: string;

  /** Callback на неудачную авторизацию */
  @Output()
  authorizeError: EventEmitter<string> = new EventEmitter<string>();

  /** Callback на успешную авторизацию */
  @Output()
  authorizeSuccess: EventEmitter<void> = new EventEmitter<void>();

  /** Прослушивание сообщений из окна c OAuth авторизацией */
  @HostListener('window:message', ['$event'])
  onWindowMessage(event: MessageEvent): void {
    if (this.isAuthorized) {
      return;
    }

    if (!event.data) {
      this.window?.close();

      throw Error('event.data is undefined');
    }

    const { status, error, integrationType, integrationId } = event.data;

    if (status === 'error') {
      this.window?.close();

      if (!error) {
        throw Error('error is undefined');
      }
      this.authorizeError.emit(error);

      this.window = null;
    }

    if (status === 'accepted') {
      this.window?.close();

      if (!integrationType) {
        throw Error('integrationType is undefined');
      }

      if (!integrationId) {
        throw Error('integrationId is undefined');
      }

      this.authorizeSuccess.emit();
      this.goToIntegration(integrationType, integrationId);

      this.window = null;
    }
  }

  /** Инстанс окна, в котором будет открыта OAuth авторизация */
  window: Window | null = null;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly oAuthWindowService: OAuthWindowService,
    private readonly uiRouter: UIRouter,
  ) {}

  /** Получение аттрибута disabled для кнопки авторизации */
  getBtnDisabledAttr(): boolean | null {
    return this.isOAuthWindowOpened() ? true : null;
  }

  /** Получение CSS-класса в зависимости от указанного размера кнопки */
  getBtnSizeClass() {
    return this.buttonSize ? `btn-${this.buttonSize}` : '';
  }

  /**
   * Перенаправление пользователя на созданную интеграцию
   *
   * @param integrationType
   * @param integrationId
   */
  goToIntegration(integrationType: INTEGRATION_TYPES, integrationId: string): void {
    const state = `app.content.integrations.details.configured.${integrationType}`;
    const params = {
      integrationType,
      integrationId,
    };
    const options: TransitionOptions = {
      location: 'replace',
      reload: true,
    };

    this.uiRouter.stateService.go(state, params, options);
  }

  /** Открыто ли окно с OAuth авторизацией */
  isOAuthWindowOpened(): boolean {
    if (this.window === null) {
      return false;
    }

    if (this.window.closed) {
      return false;
    }

    if (!this.window.closed) {
      return true;
    }

    return false;
  }

  /** Обработчик нажатия на кнопку OAuth авторизации */
  onClickOAuthButton(): void {
    if (this.isAuthorized) {
      return;
    }

    if (this.isOAuthWindowOpened()) {
      return;
    }

    this.window = this.oAuthWindowService.open();
    this.oAuthWindowService.setOAuthUrlToWindow(this.window, this.oAuthUrl);

    // Обработка закрытия пользователем окна с OAuth авторизацией
    interval(CHECK_INTERVAL_CLOSING_OAUTH_WINDOW_MS)
      .pipe(takeWhile(() => !this.window?.closed, true))
      .subscribe(() => {
        if (this.window?.closed) {
          this.window = null;
          this.changeDetectorRef.detectChanges();
        }
      });
  }
}

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqFacebookLeadsOAuthController', CqFacebookLeadsOAuthController);

  function CqFacebookLeadsOAuthController($stateParams, $window, integrationModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      const { appId, auth, code, error, integrationId } = $stateParams;

      if (carrotquest && carrotquest.messenger) {
        carrotquest.messenger.toStateNo();
      }
      if (error) {
        $window.opener.postMessage(
          {
            status: 'error',
          },
          '*',
        );
      } else if (auth) {
        getOAuthUrl(appId, integrationId);
      } else if (code) {
        authorize(appId, integrationId, code);
      }
    }

    /**
     * Авторизация в Facebook
     *
     * @param appId
     * @param integrationId
     * @param code
     */
    function authorize(appId, integrationId, code) {
      integrationModel.facebookLeads.authorize(integrationId, appId, code).then(authorizeSuccess).catch(authorizeError);

      function authorizeSuccess() {
        $window.opener.postMessage(
          {
            status: 'accepted',
          },
          '*',
        );
      }

      function authorizeError() {
        $window.opener.postMessage(
          {
            status: 'error',
          },
          '*',
        );
      }
    }

    /**
     * Получение URL для авторизации в Facebook
     *
     * @param appId
     * @param integrationId
     */
    function getOAuthUrl(appId, integrationId) {
      integrationModel.facebookLeads.getOAuthUrl(appId, integrationId).then(getOAuthUrlSuccess).catch(getOAuthUrlError);

      function getOAuthUrlSuccess(oAuthLink) {
        $window.location.href = oAuthLink;
      }

      function getOAuthUrlError() {
        $window.opener.postMessage(
          {
            status: 'error',
          },
          '*',
        );
      }
    }
  }
})();

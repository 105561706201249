/** Типы составляющих preview реплики компонентов */
export enum CONVERSATION_PART_PREVIEW_COMPONENT {
  FILE,
  BUTTONS,
  DELIMITER,
  IMAGE,
  SPACE,
  TEXT,
  VIDEO,
}

/** Ожидаемый тип прикрепления (необходимо, чтобы показать zero-дату в реплике, пока прикрепление не загружено) */
export enum EXPECTED_ATTACHMENT {
  FILE,
  IMAGE,
  VIDEO,
}

/** Типы, которые считаются медиа-типами */
export const MEDIA_ATTACHMENT_TYPES = {
  AUDIO: 'audio',
  IMAGE: 'image',
  VIDEO: 'video',
};

/** Массив типов, которые считаются медиа-типами */
export const MEDIA_ATTACHMENT_TYPES_ARRAY = [
  MEDIA_ATTACHMENT_TYPES.AUDIO,
  MEDIA_ATTACHMENT_TYPES.IMAGE,
  MEDIA_ATTACHMENT_TYPES.VIDEO,
];

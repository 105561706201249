(function () {
  'use strict';

  require('./general-settings.controller');

  angular.module('myApp.settings').component('cqGeneralSettings', cqGeneralSettings());

  function cqGeneralSettings() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        djangoUser: '=', // текущий пользователь
      },
      controller: 'CqGeneralSettingsController',
      controllerAs: 'vm',
      template: require('./general-settings.html'),
    };
  }
})();

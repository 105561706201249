<ng-container *transloco="let t; read: 'searchFilterComponent'">
  <div class="flex-grow-1 position-relative d-flex align-items-center">
    <div class="form-group has-feedback has-feedback-left m-0 flex-grow-1">
      <input
        class="search-conversation-input form-control pr-30"
        autocomplete="off"
        name="search"
        required
        type="text"
        [autofocus]="true"
        [formControl]="searchPhraseControl"
        [placeholder]="t('searchInput.placeholder')"
        (keyup)="onKeyUp($event)"
      />
      <span class="form-control-feedback">
        <i class="cqi-sm cqi-search"></i>
      </span>
    </div>
    <div
      class="position-absolute right-0 mr-10"
      [ngbTooltip]="tooltipText"
      placement="top"
    >
      <button
        class="btn btn-text-primary"
        type="button"
        [ngbPopover]="dateRangePickerPopover"
        [autoClose]="'outside'"
        popoverClass="daterangepicker-input-popover"
        placement="bottom-left"
        container="body"
      >
        <i class="btn-icon cqi-sm cqi-calendar-o"></i>
      </button>
    </div>
  </div>

  <ng-template #dateRangePickerPopover>
    <cq-daterangepicker
      [formControl]="dateRangeControl"
      [options]="dateRangePickerOptions"
    >
      <div
        slot="applyButtons"
        class="d-flex margin-between-cols-10"
      >
        <button
          class="btn btn-primary btn-sm"
          type="button"
          (click)="onClickApplyFilter()"
        >
          {{ 'general.apply' | transloco }}
        </button>
        <button
          class="btn btn-outline-primary btn-sm"
          type="button"
          (click)="onClickCancelButton()"
        >
          {{ 'general.cancel' | transloco }}
        </button>
      </div>
    </cq-daterangepicker>
  </ng-template>
</ng-container>

import { BaseTexture, Renderer, RenderTexture } from '@pixi/core';
import { Container } from '@pixi/display';
import { Graphics } from '@pixi/graphics';

import { PixiTextureBuilderService } from '@panel/app/shared/services/pixi/texture-builder/pixi-texture-builder.service';

import { MAX_ACTION_WIDTH } from '../abstract';

// Size constants
const TEXT_BG_WIDTH = MAX_ACTION_WIDTH;
const ZERO = 0;

// Текстурка для краев с закругленными углами
let sideBaseTextures: Record<number, BaseTexture> = {};

/**
 * Рисуем текстурку для нижней и верхней части с закруглениями
 */
function getSideBaseTexture(renderer: Renderer, color: number): BaseTexture {
  if (sideBaseTextures[color]) {
    return sideBaseTextures[color];
  }
  const graphics = new Graphics();

  const HEIGHT = 12;
  const ANGLE_LENGTH = 10;
  const ANGLE_RADIUS = 10;

  graphics
    .lineStyle(ZERO)
    .beginFill(color)
    .moveTo(ZERO, ANGLE_LENGTH)
    .arcTo(ZERO, ZERO, ANGLE_LENGTH, ZERO, ANGLE_RADIUS)
    .lineTo(TEXT_BG_WIDTH - ANGLE_LENGTH, ZERO)
    .arcTo(TEXT_BG_WIDTH, ZERO, TEXT_BG_WIDTH, ANGLE_LENGTH, ANGLE_RADIUS)
    .lineTo(TEXT_BG_WIDTH, HEIGHT)
    .lineTo(ZERO, HEIGHT)
    .lineTo(ZERO, ANGLE_LENGTH)
    .endFill();

  // resolution нужно, чтоб скругленные углы не шакалились
  const renderTexture = RenderTexture.create({ width: TEXT_BG_WIDTH, height: HEIGHT, resolution: 10 });

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  sideBaseTextures[color] = renderTexture.castToBaseTexture();

  return sideBaseTextures[color];
}

// Внутренняя текстурка (прямоугольная)
let innerBaseTextures: Record<number, BaseTexture> = {};

/**
 * Рисуем внутреннюю, повторяющаюся, прямоугольную часть
 */
function getInnerBaseTexture(renderer: Renderer, color: number): BaseTexture {
  if (innerBaseTextures[color]) {
    return innerBaseTextures[color];
  }

  const graphics = new Graphics();

  // От этой высоты зависит то, во сколько кусков будет собираться один задник для текста.
  // Слишком маленьким его делать не стоит, потому что придется много повторений делать.
  // Слишком большим, кажется, тоже, потому что тогда из него каждый раз будет собираться новая текстура
  const INNER_TEXTURE_HEIGHT = 500;

  graphics
    .lineStyle(0)
    .beginFill(color)
    .lineTo(TEXT_BG_WIDTH, ZERO)
    .lineTo(TEXT_BG_WIDTH, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, INNER_TEXTURE_HEIGHT)
    .lineTo(ZERO, ZERO)
    .endFill();

  const renderTexture = RenderTexture.create({ width: TEXT_BG_WIDTH, height: INNER_TEXTURE_HEIGHT });

  renderer.render(graphics, { renderTexture });

  graphics.destroy();

  innerBaseTextures[color] = renderTexture.castToBaseTexture();

  return innerBaseTextures[color];
}

let lastRendererContextUID: number | null = null;

export function getActionsBackgroundRect(height: number, color: number, renderer: Renderer): Container {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    clearCache();
  }
  lastRendererContextUID = renderer.CONTEXT_UID;

  const sideTexture = getSideBaseTexture(renderer, color);
  const innerTexture = getInnerBaseTexture(renderer, color);

  return PixiTextureBuilderService.buildBurgerTexture(sideTexture, innerTexture, height);
}

function clearCache() {
  sideBaseTextures = {};
  innerBaseTextures = {};
}

<ng-container>
  <cq-question-header></cq-question-header>

  <div class="mx-auto">
    <div class="mb-30">
      <cq-question
        [question]="currentQuestion"
        [savedAnswer]="savedAnswerToCurrentQuestions"
        (answerSelect)="setQuestion($event)"
      ></cq-question>

      <cq-question-pagination
        *ngIf="this.isLinearQuestionStructure"
        [currentQuestionNumber]="currentQuestion.id"
        [collectionQuestionSize]="questions.length"
        (currentQuestionNumberChange)="setQuestionById($event)"
        class="d-flex justify-content-center"
      ></cq-question-pagination>
    </div>
  </div>
</ng-container>

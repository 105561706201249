<ng-container *transloco="let t; read: 'eventTypeSelectorComponent'">
  <div
    class="w-100"
    [cqShowError]="control"
  >
    <ng-select
      [clearable]="false"
      [formControl]="control"
      [placeholder]="t('placeholder')"
      [cqShowError]="control"
      [searchable]="false"
      [classList]="['ng-touched']"
      (change)="eventTypeChange.next($event)"
    >
      <ng-container *ngFor="let option of eventTypeSelectorOptions">
        <ng-option [value]="option">
          <div class="d-flex align-items-center">
            <i
              class="cqi-sm"
              [ngClass]="getIconForEventGroup(option)"
            ></i>
            <span class="ml-10">{{ t('options.' + option) }}</span>
          </div>
        </ng-option>
      </ng-container>
    </ng-select>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

(function () {
  'use strict';

  angular
    .module('myApp.chatSettings')
    .controller('CqChatSettingsSectionHeaderController', CqChatSettingsSectionHeaderController);

  function CqChatSettingsSectionHeaderController(caseStyleHelper) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.iconName = caseStyleHelper.toKebabCase(vm.type); // название SVG-иконки
    }
  }
})();

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Simple } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';

import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { ChatBot } from '@http/chat-bot/types/chat-bot-internal.types';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ConnectionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { OverlayBootstrapElementsHelper } from '@panel/app/pages/chat-bot/content/services/overlay-bootstrap-elements-helper.service';
import {
  BRANCH_VIEW_MAP,
  BranchViewMap,
  PIXI_APP,
  PIXI_CULL,
  PIXI_VIEWPORT,
  PixiApplication,
} from '@panel/app/pages/chat-bot/content/tokens';
import { BaseBadge, InterruptBadge, StartBadge } from '@panel/app/pages/chat-bot/content/views/badges';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

@Injectable()
export class BadgeFactory {
  constructor(
    private readonly botScenariosHelper: BotScenariosHelper,
    private readonly connectionFactory: ConnectionFactory,
    protected readonly interactionService: PixiInteractionService,
    private readonly ngZone: NgZone,
    private readonly transloco: TranslocoService,
    private readonly overlayTooltipsHelper: OverlayBootstrapElementsHelper,
    @Inject(BRANCH_VIEW_MAP)
    private readonly branchesMap: BranchViewMap,
    @Inject(PIXI_CULL)
    protected readonly cull: Simple,
    @Inject(DOCUMENT)
    protected readonly document: Document,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
  ) {}

  /**
   * Создает бейдж прерывания
   * @param chatBot
   * @param targetBranch
   */
  public createInterrupt(chatBot: ChatBot<CHAT_BOT_TYPE>, targetBranch?: Branch): InterruptBadge {
    return this.ngZone.runOutsideAngular(() => {
      return new InterruptBadge(...this.getConstructorArguments(chatBot, targetBranch), chatBot.allowUserReplies);
    });
  }

  /**
   * Создает стартовый бейдж
   */
  public createStart(
    chatBot: ChatBot<CHAT_BOT_TYPE>,
    triggers: string[],
    openWebPageTriggerAmount: number,
    openSdkPageTriggerAmount: number,
    leaveSiteAttemptTrigger: boolean,
    targetBranch?: Branch,
  ): StartBadge {
    return this.ngZone.runOutsideAngular(() => {
      return new StartBadge(
        ...this.getConstructorArguments(chatBot, targetBranch),
        triggers,
        openWebPageTriggerAmount,
        openSdkPageTriggerAmount,
        leaveSiteAttemptTrigger,
      );
    });
  }

  private getConstructorArguments(
    chatBot: ChatBot<CHAT_BOT_TYPE>,
    targetBranch?: Branch,
  ): AbstractConstructorParameters<typeof BaseBadge> {
    return [
      this.botScenariosHelper,
      this.cull,
      this.interactionService,
      this.document,
      this.connectionFactory,
      this.transloco,
      targetBranch,
      chatBot.type,
      this.branchesMap,
      this.viewport,
      this.overlayTooltipsHelper,
      this.pixiApp,
    ];
  }
}

/**
 * Округление числа в бо́льшую сторону
 */
(function () {
  'use strict';

  angular.module('myApp.filters.roundUp').filter('roundUp', roundUp);

  function roundUp() {
    return roundUp;

    function roundUp(value) {
      return Math.ceil(value);
    }
  }
})();

<ng-container *transloco="let t">
  <h3>{{ t('emailNotificationTeamMembersSelect.heading') }}</h3>
  <div class="margin-bottom-20">{{ t('emailNotificationTeamMembersSelect.description') }}</div>

  <div
    class="form-group margin-bottom-10"
    [cqShowError]="control"
  >
    <ng-select
      [addTag]="emailRecipientsAddTagFn"
      [clearable]="false"
      [formControl]="control"
      [hideSelected]="true"
      [items]="emailRecipientItems"
      [multiple]="true"
      [trackByFn]="emailRecipientsTrackFn"
      bindLabel="email"
      bindValue="email"
      placeholder="{{ t('emailNotificationTeamMembersSelect.teamMembersSelect.placeholder') }}"
    >
      <ng-template
        let-clear="clear"
        let-emailRecipient="item"
        ng-label-tmp
      >
        <button
          class="btn btn-sm btn-outline-primary d-flex align-items-center overflow-hidden"
          type="button"
        >
          <!-- Пользователь не подписан на уведомления, показывается только при редактировании интеграции -->
          <i
            class="btn-icon-left cqi-sm cqi-triangle-exclamation-o text-warning"
            *ngIf="emailRecipient.status === EMAIL_NOTIFICATION_STATUS.NOT_CONFIRMED"
            [ngbTooltip]="notConfirmedEmailTooltip"
            container="body"
          ></i>
          <!-- Пользователь отписался от уведомлений -->
          <i
            class="btn-icon-left cqi-sm cqi-triangle-exclamation-o text-danger"
            *ngIf="emailRecipient.status === EMAIL_NOTIFICATION_STATUS.UNSUBSCRIBED"
            [closeDelay]="USER_UNSUBSCRIBED_TOOLTIP_CLOSE_DELAY"
            [ngbTooltip]="userUnsubscribedTooltip"
            container="body"
          ></i>
          <span class="text-truncate">{{ emailRecipient.email }}</span>
          <span
            class="d-flex margin-left-5"
            (click)="clear(emailRecipient)"
          >
            <i class="cqi-xs cqi-times"></i>
          </span>
        </button>
      </ng-template>
      <ng-template
        let-emailRecipient="item"
        ng-option-tmp
      >
        <!-- Член команды -->
        <div
          class="d-flex align-items-center"
          *ngIf="emailRecipient.isTeamMember"
        >
          <img
            class="avatar"
            [src]="emailRecipient.avatar"
          />
          <div class="margin-left-10">
            <div
              class="text-truncate"
              [innerHTML]="emailRecipient.fullName"
            ></div>
            <div class="text-muted">{{ emailRecipient.email }}</div>
          </div>
        </div>
        <!-- Получатель -->
        <div
          class="d-flex align-items-center"
          *ngIf="!emailRecipient.isTeamMember"
        >
          <img
            class="avatar"
            [src]="TEAM_MEMBER_DEFAULT_AVATAR_URL"
          />
          <div class="margin-left-10">{{ emailRecipient.email }}</div>
        </div>
      </ng-template>
    </ng-select>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">{{
        t('emailNotificationTeamMembersSelect.teamMembersSelect.errors.required')
      }}</cq-validation-message>
      <cq-validation-message errorName="email">{{
        t('emailNotificationTeamMembersSelect.teamMembersSelect.errors.email')
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>

  <small class="text-muted">{{ t('emailNotificationTeamMembersSelect.notification') }}</small>

  <ng-template #notConfirmedEmailTooltip>
    <span>{{ t('emailNotificationTeamMembersSelect.notConfirmedEmailTooltip') }}</span>
  </ng-template>
  <ng-template #userUnsubscribedTooltip>
    <span>{{ t('emailNotificationTeamMembersSelect.userUnsubscribedTooltip.text') }}</span>
    <span
      class="cursor-pointer text-decoration-underline"
      (click)="openChat()"
      >{{ t('emailNotificationTeamMembersSelect.userUnsubscribedTooltip.writeUs') }}</span
    >
  </ng-template>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { AiBotAddressForm } from '@http/ai-data/internal-types';
import { MessageEditorTriggerState } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-state.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';

export enum MESSAGE_EDITOR_SELECTED_TRIGGER {
  VISITED_WEBSITE = 'visited_website',
  OPENED_WEB_PAGE = 'opened_web_page',
  OPENED_SDK_PAGE = 'opened_sdk_page',
  LEAVE_SITE_ATTEMPT = 'leave_site_attempt',
  ANOTHER_EVENT = 'another_event',
}

@Component({
  selector: 'cq-message-editor-trigger-select',
  templateUrl: './message-editor-trigger-select.component.html',
  styleUrls: ['./message-editor-trigger-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorTriggerSelectComponent {
  @Input()
  entityName: string = '';

  @Input()
  selectedTriggerType: MESSAGE_EDITOR_SELECTED_TRIGGER = MESSAGE_EDITOR_SELECTED_TRIGGER.VISITED_WEBSITE;

  @Output()
  selectedTriggerTypeChange: Subject<MESSAGE_EDITOR_SELECTED_TRIGGER> = new Subject();

  MESSAGE_EDITOR_SELECTED_TRIGGER = MESSAGE_EDITOR_SELECTED_TRIGGER;

  accessToEventsEventTypesCustom: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  readonly sdkActive: boolean;

  constructor(
    planFeatureAccessService: PlanFeatureAccessService,
    protected readonly messageEditorTriggerState: MessageEditorTriggerState,
  ) {
    const currentApp = messageEditorTriggerState.currentApp$.getValue()!;
    this.sdkActive = currentApp.activation.auto_msg_sdk !== null;
    this.accessToEventsEventTypesCustom = planFeatureAccessService.getAccess(
      PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
      currentApp,
    );
  }

  protected readonly AiBotAddressForm = AiBotAddressForm;

  selectOpenedPageTrigger() {
    if (
      this.selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE ||
      this.selectedTriggerType === MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_SDK_PAGE
    ) {
      return;
    }
    this.selectedTriggerType = MESSAGE_EDITOR_SELECTED_TRIGGER.OPENED_WEB_PAGE;
    this.selectedTriggerTypeChange.next(this.selectedTriggerType);
  }
}

(function () {
  'use strict';

  require('./add-user-property.controller');

  angular.module('myApp.modals.addUserProperty').component('cqAddUserPropertyModal', cqAddUserPropertyModal());

  function cqAddUserPropertyModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp — Текущее приложение
        // propertyList — Список всех свойств
        // userId — ID пользователя CQ
      },
      controller: 'CqAddUserPropertyModalController',
      controllerAs: 'vm',
      template: require('./add-user-property.html'),
    };
  }
})();

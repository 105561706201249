import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';

/**
 * Модель онбординга
 */
export class OnboardingModel {
  /**
   * При регистрации через Google в CQ и Dashly, либо при обычной регистрации на Dashly, пользователь заполняет о себе не все данные
   * В этих ситуациях, origin у его аппа равен его почте. Когда это условие выполняется - мы должны собрать с него дополнительные данные
   */
  static isBaseDataChanged(currentApp: App, djangoUser: DjangoUser): boolean {
    return currentApp.origin !== djangoUser.email;
  }
}

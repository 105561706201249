import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Компонент для работы с хлебными крошками в разделе "Настройки диалогов" */
@Component({
  selector: 'cq-conversations-settings-breadcrumbs',
  templateUrl: './conversations-settings-breadcrumbs.component.html',
  styleUrls: ['./conversations-settings-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsSettingsBreadcrumbsComponent {}

<ng-container *transloco="let t">
  <div>
    <div class="d-flex align-items-center margin-bottom-10">
      <h3 class="mb-0">{{ t('telegramManual.heading') }}</h3>
      <button
        class="btn btn-outline-secondary btn-sm ml-auto"
        (click)="manualCollapse.toggle()"
        [attr.aria-expanded]="!isManualExpanded"
        aria-controls="manualCollapse"
        type="button"
      >
        {{ t('models.integration.other.toggleManualVisibilityButtonText') }}
        <i
          class="btn-icon-right cqi-sm"
          [ngClass]="{ 'cqi-chevron-up': !isManualExpanded, 'cqi-chevron-down': isManualExpanded }"
        ></i>
      </button>
    </div>
    <p
      [innerHTML]="
        t('telegramManual.description1', { integrationTypeName: integrationTypeName, projectName: projectName })
      "
    ></p>
    <p>
      {{
        t('telegramManual.description2', {
          integrationTypeName: integrationTypeName,
          projectName: projectName
        })
      }}
    </p>
  </div>

  <div
    #manualCollapse="ngbCollapse"
    [(ngbCollapse)]="isManualExpanded"
  >
    <h3>{{ t('telegramManual.step1.heading') }}</h3>
    <p [innerHTML]="t('telegramManual.step1.description', { integrationTypeName: integrationTypeName })"></p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/telegram/telegram-1.png"
    />

    <hr />
    <h3>{{ t('telegramManual.step2.heading') }}</h3>
    <p [innerHTML]="t('telegramManual.step2.description1')"></p>
    <img
      class="w-100 margin-bottom-10"
      src="assets/img/{{ activeLang }}/integrations/telegram/telegram-2.png"
    />
    <p [innerHTML]="t('telegramManual.step2.description2')"></p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/telegram/telegram-3.png"
    />

    <hr />
    <h3>{{ t('telegramManual.step3.heading') }}</h3>
    <p [innerHTML]="t('telegramManual.step3.description1')"></p>
    <p>{{ t('telegramManual.step3.description2') }}</p>
    <ul class="margin-bottom-10">
      <li>{{ t('telegramManual.step3.list.item1') }}</li>
      <li>{{ t('telegramManual.step3.list.item2', { integrationTypeName: integrationTypeName }) }}</li>
    </ul>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/telegram/telegram-4.png"
    />

    <hr />
    <h3>{{ t('telegramManual.step4.heading') }}</h3>
    <p>{{ t('telegramManual.step4.description1') }}</p>
    <img
      class="w-100 margin-bottom-10"
      src="assets/img/{{ activeLang }}/integrations/telegram/telegram-5.png"
    />
    <p>{{ t('telegramManual.step4.description2') }}</p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/telegram/telegram-6.png"
    />

    <hr />
    <h3>{{ t('telegramManual.step5.heading') }}</h3>
    <p>{{ t('telegramManual.step5.description1') }}</p>
    <p [innerHTML]="t('telegramManual.step5.description2')"></p>
    <img
      class="w-100"
      src="assets/img/{{ activeLang }}/integrations/telegram/telegram-7.png"
    />
  </div>
</ng-container>

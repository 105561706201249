import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cq-conversations-settings-page',
  templateUrl: './conversations-settings-page.component.html',
  styleUrls: ['./conversations-settings-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationsSettingsPageComponent {
  constructor() {}
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { DestroyService } from '@panel/app/services';
import { App } from '@http/app/app.model';
import { Properties, UserProperty } from '@http/property/property.model';

@Component({
  selector: 'cq-buttons-property[actionForm][currentApp][propertyOptions]',
  templateUrl: './buttons-property.component.html',
  styleUrls: ['./buttons-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class BranchActionButtonsPropertyComponent {
  @Input()
  actionForm!: BaseBotActionForm;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  @Input()
  propertyOptions!: Properties;

  onChangeProperty(event: UserProperty) {
    this.actionForm.prettyKeyName = event.prettyName;
    // TODO: Это хак, чтоб сказать канвасу, что надо перерисовать, так как изменился prettyKeyName
    // Надо избавляться от этого
    this.actionForm.controls.nextBranchLinkId.setValue(null);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { AutoEventsDuplicatesErrorsComponent } from './duplicates-errors.component';

@NgModule({
  declarations: [AutoEventsDuplicatesErrorsComponent],
  exports: [AutoEventsDuplicatesErrorsComponent],
  imports: [CommonModule, TranslocoModule],
})
export class AutoEventsDuplicatesErrorsModule {}

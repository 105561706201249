import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TuiLetModule } from '@taiga-ui/cdk';

import { CarrotquestHelperProvider, L10nHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { ItemContentModule } from '@panel/app/pages/subscription/general/signed/faq/item-content/item-content.module';
import { ItemHeaderModule } from '@panel/app/pages/subscription/general/signed/faq/item-header/item-header.module';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { SharedModule } from '@panel/app/shared/shared.module';

import { FaqComponent } from './faq.component';

@NgModule({
  declarations: [FaqComponent],
  exports: [FaqComponent],
  imports: [
    CommonModule,
    ItemContentModule,
    ItemHeaderModule,
    NgbAccordionModule,
    TranslocoModule,
    SharedModule,
    TuiLetModule,
  ],
  providers: [CarrotquestHelperProvider, L10nHelperProvider, SubscriptionStore],
})
export class FaqModule {}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.emailTemplates').controller('CqEmailTemplatesController', CqEmailTemplatesController);

  function CqEmailTemplatesController($translate, $uibModal, toastr, PROJECT_NAME, carrotquestHelper, appModel) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.openEmailTemplatePreviewModal = openEmailTemplatePreviewModal;
      vm.openLightweightEmailTemplatePreviewModal = openLightweightEmailTemplatePreviewModal;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.trackClickUpdateMessageTemplate = trackClickUpdateMessageTemplate;
      vm.updateMessagesLightweightTemplate = updateMessagesLightweightTemplate;
    }

    /**
     * Открытие модалки с превью шаблонов писем
     */
    function openEmailTemplatePreviewModal() {
      $uibModal.open({
        component: 'cqEmailTemplatePreviewModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          messageSender: angular.bind(null, angular.identity, vm.messageSender),
        },
      });
    }

    /**
     * Открытие модалки с превью облегчённых шаблонов писем
     */
    function openLightweightEmailTemplatePreviewModal() {
      $uibModal.open({
        component: 'cqLightweightEmailTemplatePreviewModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          messageSender: angular.bind(null, angular.identity, vm.messageSender),
        },
      });
    }

    /**
     * Трек смены типа шаблона письма
     *
     * @param {Boolean} isLightweightTemplate Флаг использования облегченного шаблона письма
     */
    function trackClickUpdateMessageTemplate(isLightweightTemplate) {
      if (isLightweightTemplate) {
        carrotquestHelper.track('Настройки отправки email - выбор внешнего вида писем - клик по "Облегчённый"');
      } else {
        carrotquestHelper.track('Настройки отправки email - выбор внешнего вида писем - клик по "Стандартный"');
      }
    }

    /**
     * Обновление настройки шаблона для писем
     */
    function updateMessagesLightweightTemplate() {
      var params = {
        messages_lightweight_template: vm.currentApp.settings.messages_lightweight_template,
      };

      firstValueFrom(appModel.saveSettings(vm.currentApp.id, params)).then(updateSuccess);

      function updateSuccess() {
        toastr.success($translate.instant('emailTemplates.toasts.messagesTemplateUpdated'));
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'dataSourceComponent'">
  <ng-container *ngIf="!this.isSourceDeleted; else deletedSource">
    <div class="d-flex">
      <div>
        <a
          [href]="sourceUrl"
          class="d-block mb-5"
          target="_blank"
        >
          <i
            class="cqi-sm"
            [ngClass]="sourceTypeIcon"
          ></i>
          {{ dataSource.name }}
        </a>
        <div class="text-secondary mb-10">{{ dataSource.description }}</div>
        <div class="small text-secondary">{{ sourceActualityTranslate }}</div>
      </div>
      <div class="ml-auto">
        <button
          *ngIf="!isOperator"
          class="btn btn-borderless-primary"
          type="button"
          (click)="onClickEditDataButton()"
          placement="left"
          [ngbTooltip]="editDataTooltipTranslate"
        >
          <i class="btn-icon cqi-sm cqi-pencil"></i>
        </button>
        <button
          *ngIf="isOperator"
          class="btn btn-borderless-primary disabled"
          type="button"
          placement="left"
          [ngbTooltip]="noAccessTooltipTranslate"
        >
          <i class="btn-icon cqi-sm cqi-pencil"></i>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #deletedSource>
    <div class="d-flex">
      <div>
        <div class="d-block mb-5 text-disabled">
          <i
            class="cqi-sm"
            [ngClass]="sourceTypeIcon"
          ></i>
          {{ dataSource.name }}
        </div>
        <div class="mb-10 text-disabled">{{ dataSource.description }}</div>
        <div class="small text-disabled">{{ sourceActualityTranslate }}</div>
      </div>
    </div>
  </ng-template>
</ng-container>

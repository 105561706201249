import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import {
  BlockingServiceProvider,
  CarrotquestHelperProvider,
  UtilsServiceProvider,
} from '@panel/app/ajs-upgraded-providers';
import { NavigationLogoModule } from '@panel/app/partials/navigation-logo/navigation-logo.module';
import { OneTimeFlagService, SystemToastService } from '@panel/app/services';
import { SharedModule } from '@panel/app/shared/shared.module';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

import { NavigationComponent } from './navigation.component';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';

@NgModule({
  declarations: [NavigationComponent, NavigationItemComponent],
  exports: [NavigationComponent],
  imports: [
    CommonModule,
    NavigationLogoModule,
    NgbDropdownModule,
    NgbTooltipModule,
    OverlayscrollbarsModule,
    SharedModule,
    TranslocoModule,
    UIRouterModule,
  ],
  providers: [
    BlockingServiceProvider,
    CarrotquestHelperProvider,
    SystemToastService,
    ToastService,
    UtilsServiceProvider,

    OneTimeFlagService,
  ],
})
export class NavigationModule {}

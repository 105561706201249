/**
 * Компонент редактора сообщения для триггерных цепочек
 */
(function () {
  'use strict';

  require('./trigger-chain-automessage-editor.controller');

  angular
    .module('myApp.triggerChains.automessageEditor')
    .component('cqTriggerChainAutomessageEditor', cqTriggerChainAutomessageEditor());

  function cqTriggerChainAutomessageEditor() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // djangoUser - список приложений
        // billingInfo - текущий пользователь
      },
      controller: 'CqTriggerChainAutomessageEditorController',
      controllerAs: 'vm',
      template: require('./trigger-chain-automessage-editor.html'),
    };
  }
})();

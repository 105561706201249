import { URL_FILTER_TYPE } from '../../../../app/partials/url-filter-configurator/url-filter-configurator.component';
import { UrlFilterMapper } from '../../../../app/partials/url-filter-configurator/url-filter-mapper';
import { STARTER_GUIDE_STEPS } from '../../../../app/http/starter-guide/starter-guide.constants';
import {
  PLAN_FEATURE,
  PLAN_FEATURE_BY_MESSAGE_PART_TYPE,
} from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { DirectoryEditorModalComponent } from '../../../../app/pages/auto-messages/modals/shared/directory-editor/directory-editor-modal.component';
import { InstallScriptModalComponent } from '../../../../app/shared/modals/install-script-modal/install-script-modal.component';
import { checkMessagePartValidityForNotification } from './utils/email-notification–availability.validator';
import {
  SENDING_FILTERS_GROUP_TYPES,
  SENDING_FILTERS_TYPES,
} from '../../../../app/services/conditions-sending/conditions-sending.constants';
import {
  PSEUDO_DIRECTORY_IDS,
  PSEUDO_DIRECTORY_TYPES,
  SYSTEM_DIRECTORIES,
} from '../../../../app/http/message-directory/message-directory.constants';
import { firstValueFrom } from 'rxjs';
import {
  AUDIENCE_FILTER_TYPE,
  MESSAGE_DELETING_TYPES,
  MESSAGE_TYPES,
  TRIGGER_TYPE_KIND,
} from '../../../../app/http/message/message.constants';
import { ConditionsSendingModel } from '../../../../app/services/conditions-sending/conditions-sending.model';
import { FILTER_LOGICAL_OPERATION } from '../../../../app/services/filter/filter.constants';
import { AutoMessageLoopModalComponent } from '../../../../app/pages/auto-messages/modals/list/loop/auto-message-loop-modal.component';
import {
  EMAIL_TYPES,
  HTML_MAX_SIZE,
  MESSAGE_PART_TYPES,
  POPUP_CHAT_TYPES,
  RECIPIENT_TYPES,
} from '../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.autoMessages').controller('CqAutoMessageEditorController', CqAutoMessageEditorController);

  function CqAutoMessageEditorController(
    $filter,
    $q,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $translate,
    $uibModal,
    $window,
    moment,
    toastr,
    ACTIONS_ON_DIRECTORY,
    TIME_UNIT_MEASURES,
    TIME_UNITS,
    USER_FILES_URL,
    appModel,
    carrotquestHelper,
    caseStyleHelper,
    eventTypeModel,
    featureModel,
    filterAjsModel,
    l10nHelper,
    messageModel,
    messagePartModel,
    paywallService,
    planFeatureAccessService,
    popupBlockModel,
    systemError,
    starterGuideModel,
    timeUnitService,
    utmMarkModel,
    validationHelper,
    wizardHelper,
    modalHelperService,
  ) {
    let vm = this;

    let originalMessageParts = [];
    let isMessageSaved = false; // Было ли сохранено сообщение. Используется для показа модального окна об уходе в другой раздел
    const beforeunloadListener = (event) => {
      const returnValue = $translate.instant('autoMessages.editor.confirmExitModal.body');

      event.preventDefault();
      event.returnValue = returnValue;
      return returnValue;
    };

    vm.$onInit = init;

    function init() {
      trackEnterInEditor(vm.editableMessage);

      vm.addMessagePart = addMessagePart;
      vm.addTrigger = addTrigger;
      vm.archiveMessage = angular.bind(null, archiveOrReestablishMessage, false);
      vm.archiveOrReestablishRequestPerforming = false; // выполняется ли запрос на архивацию/восстановление триггерного сообщения
      vm.checkValidityForNotification = () => {}; // колбек для дизейбла свитчеров для передачи данных на шаге "условия отправки"
      vm.changeMessageStatus = changeMessageStatus;
      vm.collapseChange = collapseChange;
      vm.createAutoMessage = createAutoMessage;
      vm.createCopy = createCopy;
      vm.createCopyPerforming = false; // выполняется ли переход на состояние копирования сообщения
      vm.currentMessage = angular.extend(
        {
          // ВИД И СОДЕРЖАНИЕ
          closePreviousTestGroup: false, // закрывать предыдущую тест-группу или нет
          generateName: true, // использовать сгенерированное имя сообщения

          // ПРОВЕРКА И ЗАПУСК
          name: $translate.instant('autoMessages.editor.defaultMessageName'), // имя триггерного сообщения
          eventShow: false,
          eventMessage1: false,
          eventMessage2: false,
          eventMessage3: false,
          eventMessage4: false,
          eventMessage5: false,
          eventSended: '',
          eventRead: '',
          eventReplied: '',
          eventClicked: '',
          eventUnsubscribed: '',
          active: false,
          directory: $filter('filter')(
            vm.directories,
            { id: PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.WITHOUT_DIRECTORY] },
            true,
          )[0],
        },
        ConditionsSendingModel.getDefault(),
      );

      vm.customEventTypeCreated = false; // Флаг созданного события
      vm.customEventTypeCreating = false; // Флаг создания кастомного события
      vm.createEventCustomName = createEventCustomName;
      vm.deleteMessage = ConditionsSendingModel.getDefaultDeleteMessageForTriggerStep(
        vm.currentApp.settings.timezone_offset,
      ); // Параметры удаления сообщений
      vm.deleteMessageForm = null; // Форма протухания триггерного сообщения
      vm.EMAIL_TYPES = EMAIL_TYPES;
      vm.eventTypes = vm.properties.eventTypes; //События пользователя
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.contentForm = null; // Объект формы содержимого
      vm.triggerForm = null; // Объект формы триггера
      vm.filtersForm = null; // Объект формы фильтров
      vm.formSubmitSource = null; // Subject, чтоб уведомлять контролы триггеров о сабмите формы
      vm.getDenialReasonsForActivateAndPause = getDenialReasonsForActivateAndPause;
      vm.getDenialReasonsForCreateAndStart = getDenialReasonsForCreateAndStart;
      vm.goalForm = null; // Объект формы целей
      vm.sendingConditionsForm = null; // Объект формы условий отправки
      vm.getCssClassForCreateAndStartLaterButton = getCssClassForCreateAndStartLaterButton;
      vm.getEventTypeByEventId = getEventTypeByEventId;
      vm.getJsCount = getJsCount;
      vm.getTranslationOfUndeletableMessagePartTypes = getTranslationOfUndeletableMessagePartTypes;
      vm.getTranslationEntityName = getTranslationEntityName;
      vm.getUndeletableMessageParts = getUndeletableMessageParts;
      vm.getWebhookCount = getWebhookCount;
      vm.hasAccessToUserGuidingOnboarding = featureModel.hasAccess(FEATURES.USERGUIDING_MESSAGES_ONBOARDING);
      vm.hasDenialReasonsForActivatedAndPause = hasDenialReasonsForActivatedAndPause;
      vm.hasDenialReasonsForCreateAndStart = hasDenialReasonsForCreateAndStart;
      vm.hasDenialReasonsForSave = hasDenialReasonsForSave;
      vm.hasDeletableMessagePart = hasDeletableMessagePart;
      vm.hasUndeletableMessagePart = hasUndeletableMessagePart;
      vm.isAllEventsForChainsInDefaultState = isAllEventsForChainsInDefaultState;
      vm.isCollapsedDisplaySettings = false;
      vm.isEventSelectOpen = false; // Флаг указывающий на открытость/закрытость селекта с событиями
      vm.isFilterValid = isFilterValid;
      vm.isFormValid = isFormValid;
      vm.isMessageGoalFormValid = isMessageGoalFormValid;
      vm.isPreventHasDenialReasonsForCreateAndStart = isPreventHasDenialReasonsForCreateAndStart;
      vm.isShowCustomEventStuff = isShowCustomEventStuff;
      vm.isShowInstructionForSystemEventType = isShowInstructionForSystemEventType;
      vm.isShowNameInputError = false; // Флаг показа ошибки у поля с названием сообщения
      vm.isShowUrlEqualFilterPopover = true; // Флаг показа предупреждающего поповера про фильтр «С точным адресом» на шаге «Условия отправки»
      vm.isTypeLastForStep = false; // Флаг говорящий о том является ли текущая подсказка последней для текущего шага
      vm.isUnbeforeunloadSet = false;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.MESSAGE_TYPES = MESSAGE_TYPES;
      vm.moveStep = moveStep;
      vm.markStarterGuideStepMade = markStarterGuideStepMade;
      vm.onTriggerParamsChange = onTriggerParamsChange;
      vm.openDenialReasonBillingModal = openDenialReasonBillingModal;
      // Костыль для валидации новых компонентов при попытке выхода из вкладки триггеров
      // Валидаторы по дефолту возвращают true, чтоб клиента не останавливало, когда он пролистывает вкладки.
      // Если он только зашел на вкладку мы подразумеваем, что у него все валидно
      vm.triggerNewComponentValidators = {
        areSendingHoursValid: () => Promise.resolve(true),
        isDispatchValid: () => Promise.resolve(true),
        isSendingDelayValid: () => Promise.resolve(true),
        isSendingRepeatValid: () => Promise.resolve(true),
      };
      vm.onChangeMessagePartType = onChangeMessagePartType;
      vm.openCreateDirectoryModal = openCreateDirectoryModal;
      vm.onTriggerWrapperStateLoaded = onTriggerWrapperStateLoaded;
      vm.paywallService = paywallService;
      vm.planCapabilityBanner = {
        isShow: false, // Флаг показа баннера ограничений тарифа
        reason: null, // Причина показа баннера
        messageType: null, // Тип сообщения, из-за которого показывается баннер
      };
      vm.PSEUDO_DIRECTORY_IDS = PSEUDO_DIRECTORY_IDS;
      vm.PSEUDO_DIRECTORY_TYPES = PSEUDO_DIRECTORY_TYPES;
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.processMessagePartForms = processMessagePartForms;
      vm.reestablishMessage = angular.bind(null, archiveOrReestablishMessage, true);
      vm.refreshProportions = refreshProportions;
      vm.showedSendingFiltersPopoverInAudience = null; // Статус показа поповера с условиями отправки по URL в "Аудитория"
      vm.step = 1; // текущий шаг создания/редактирования триггерного сообщения
      vm.submitRequestPerforming = false; // выполняется ли запрос на создание/сохранение сообщения
      vm.SYSTEM_DIRECTORIES = SYSTEM_DIRECTORIES;
      vm.TIME_UNIT_MEASURES = TIME_UNIT_MEASURES;
      vm.TIME_UNITS = TIME_UNITS;
      vm.trackClickArchive = trackClickArchive;
      vm.trackClickCreateCopy = trackClickCreateCopy;
      vm.trackClickDetermineGoal = trackClickDetermineGoal;
      vm.trackClickShowTips = trackClickShowTips;
      vm.trackEnterOnStep = trackEnterOnStep;
      vm.trackChangeEventShow = trackChangeEventShow;
      vm.trackCreateMessageAndActivateLater = trackCreateMessageAndActivateLater;
      vm.toggleAllEventsForChainsInDefaultState = toggleAllEventsForChainsInDefaultState;
      vm.updateAutoMessage = updateAutoMessage;
      vm.validateOnTriggerStep = validateOnTriggerStep;
      vm.validationHelper = validationHelper;
      vm.validateTriggerFn = null;
      vm.wizard = null;
      vm.shownAudienceFilterType = vm.currentMessage.jinjaFilterTemplate
        ? AUDIENCE_FILTER_TYPE.JINJA
        : AUDIENCE_FILTER_TYPE.DEFAULT;
      vm.jinjaFilterTemplateCheckingResult = undefined;

      vm.setBackwardHandler && vm.setBackwardHandler({ handler: backwardHandler });
      vm.setForwardHandler && vm.setForwardHandler({ handler: forwardHandler });

      $scope.$on('message', handleRts);

      $state.$current.onExit = onExitFromEditing;

      wizardHelper.getWizard().then(getWizardSuccess);

      if (vm.isEdit) {
        if ($stateParams.step) {
          vm.step = $stateParams.step;
          $state.go('.', { step: null }, { location: 'replace' });
        } else {
          vm.step = 2;
        }
        parseEditableMessage(vm.editableMessage);
      } else {
        vm.currentMessage.isMessageHaveFilters = false;
        addControlGroup();
        // первоначальный процент контрольной группы - 10%, если пользователь её не активирует - при отправке этот процент аннулируется
        vm.currentMessage.controlGroup.proportion = 0.1;
        ($state.is('app.content.messagesAjs.createOnTemplate') ||
          $state.is('app.content.messagesAjs.createOnReadyTemplate')) &&
        vm.usedTemplate
          ? addMessagePart(true, vm.usedTemplate)
          : addMessagePart();
        vm.tags = $filter('filter')(vm.tags, { removed: '!' }); // Из всех тегов получаем только не удаленные
      }

      if ($state.is('app.content.messagesAjs.edit.copy')) {
        preparingCreateCopy();
      }

      let blockPopupMessageParts = messagePartModel
        .filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.BLOCK_POPUP_BIG)
        .concat(messagePartModel.filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL))
        .concat(
          messagePartModel.filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL),
        );

      for (let i = 0; i < blockPopupMessageParts.length; i++) {
        let blockPopup = blockPopupMessageParts[i][blockPopupMessageParts[i].type];
        let popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

        blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

        for (let j = 0; j < popupBlocks.length; j++) {
          let popupBlock = popupBlocks[j];

          popupBlockModel.linkWithEventTypes(popupBlock, vm.eventTypes);
          popupBlockModel.linkWithUserProperties(popupBlock, vm.properties.userProps);
        }
      }

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }

      const messageChangeUnregister = $scope.$watch(
        'vm.currentMessage',
        () => {
          if (isFormControlChangedByUser()) {
            messageChangeUnregister();
            updateOnbeforeunloadCallback(true);
          }
        },
        true,
      );

      vm.accessToAutoMessages = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
        vm.currentApp,
        vm.activeMessagesAmounts,
      );
      vm.accessToAutoMessagesType = planFeatureAccessService.getAccess(
        PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.AUTO][vm.currentMessage.parts[0].type],
        vm.currentApp,
      );
      vm.accessToAutoMessagesAbTesting = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_AB_TESTING,
        vm.currentApp,
      );
      vm.accessToAutoMessagesControlGroup = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_CONTROL_GROUP,
        vm.currentApp,
      );
      vm.accessToAutoMessagesEventsForChains = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_EVENTS_FOR_CHAINS,
        vm.currentApp,
      );
      vm.accessToEventsEventTypesCustom = planFeatureAccessService.getAccess(
        PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
        vm.currentApp,
      );
      vm.accessToUsersCustomProperties = planFeatureAccessService.getAccess(
        PLAN_FEATURE.USERS_CUSTOM_PROPERTIES,
        vm.currentApp,
      );
      vm.accessToUsersTags = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, vm.currentApp);
      vm.accesses = [
        vm.accessToAutoMessages,
        vm.accessToAutoMessagesType,
        vm.accessToAutoMessagesAbTesting,
        vm.accessToAutoMessagesControlGroup,
        vm.accessToAutoMessagesEventsForChains,
        vm.accessToEventsEventTypesCustom,
        vm.accessToUsersCustomProperties,
        vm.accessToUsersTags,
      ];

      vm.triggerParams = {
        triggers: vm.currentMessage.triggers,
        triggerTypes: vm.currentMessage.triggerTypes,
        delay: {
          isEnabled: vm.currentMessage.isAfterTime,
          value: {
            time: vm.currentMessage.afterTimeValue,
            unit: vm.currentMessage.afterTimeTimeUnit,
          },
        },
        sendingFilters: vm.currentMessage.sendingFilters,
      };
    }

    /**
     * Установить callback на onbeforeunload
     * @param {boolean} newState
     */
    function updateOnbeforeunloadCallback(newState) {
      if (newState && !vm.isUnbeforeunloadSet) {
        $window.addEventListener('beforeunload', beforeunloadListener);
        vm.isUnbeforeunloadSet = true;
      } else if (!newState) {
        $window.removeEventListener('beforeunload', beforeunloadListener);
        vm.isUnbeforeunloadSet = false;
      }
    }

    function addControlGroup(customControlGroup) {
      let controlGroup = customControlGroup || messagePartModel.getDefault();
      messagePartModel.generatePartNames([controlGroup]);
      controlGroup.type = MESSAGE_PART_TYPES.CONTROL_GROUP;

      vm.currentMessage.controlGroup = controlGroup;
    }

    /**
     * Добавление триггера триггерного сообщения
     */
    function addTrigger() {
      vm.currentMessage.triggers.push(null);
    }

    /**
     * Добавление нового варианта триггерного сообщения (для А/Б тестирования)
     *
     * @param {Boolean=} hideOther Сворачивать остальные триггерные сообщения или нет
     * @param {Object=} customMessagePart Вариант сообщения
     */
    function addMessagePart(hideOther, customMessagePart) {
      let newMessagePart = customMessagePart || messagePartModel.getDefault();

      vm.currentMessage.parts.push(newMessagePart);
      messagePartModel.generatePartNames(vm.currentMessage.parts);
      refreshProportions(vm.currentMessage.parts);

      if (hideOther) {
        for (let i = 0; i < vm.currentMessage.parts.length; i++) {
          if (vm.currentMessage.parts[i] != newMessagePart) {
            vm.currentMessage.parts[i].showContent = false;
          }
        }
      }
    }

    /**
     * Обработчик для перемещения назад по шагам визарда из вне
     */
    function backwardHandler() {
      if (vm.step === 1) {
        vm.firstStepCallback && vm.firstStepCallback();
      } else {
        vm.wizard.previousStep();
      }
    }

    /**
     * Установка статуса триггерному сообщению
     */
    function changeMessageStatus() {
      Promise.all([vm.validateOnTriggerStep(vm.triggerForm)]).then(() => {
        submit(!vm.currentMessage.active);
      });
    }

    function collapseChange() {
      //Нужно чтобы отработало сразу
      $scope.$apply(() => {
        vm.currentMessage.eventShow = !vm.currentMessage.eventShow;
      });
    }

    /**
     * Сравнение одного массива вариантов сообщения с другим
     *
     * @param {Array.<Object>} messageParts1
     * @param {Array.<Object>} messageParts2
     * @returns {Boolean} Если количество вариантов не совпадает - варианты считаются разными, вернётся false
     *  Если тип варианта не совпадает - они считаются заведомо разными, вернётся false
     *  Если количество вариантов, тип и контент типа у каждого из вариантов одинаковый - они одинаковые, вернётся true
     */
    function compareMessageParts(messageParts1, messageParts2) {
      let equals = true;

      // если количество вариантов не совпадает - варианты не равны
      if (messageParts1.length === messageParts2.length) {
        for (let i = 0; i < messageParts1.length && equals; i++) {
          // если типы вариантов не равны - варианты не равны
          if (messageParts1[i].type === messageParts2[i].type) {
            let partToCompare1 = messageParts1[i][messageParts1[i].type];
            let partToCompare2 = messageParts2[i][messageParts2[i].type];

            if (messageParts1[i].type === MESSAGE_PART_TYPES.EMAIL) {
              // если варианты типа email и тип этого самого email'а не совпадает - варианты не равны
              if (partToCompare1.type === partToCompare2.type) {
                equals =
                  partToCompare1.isUtmMarksEnabled === partToCompare2.isUtmMarksEnabled &&
                  partToCompare1.subject === partToCompare2.subject &&
                  partToCompare1.sender.id === partToCompare2.sender.id &&
                  angular.equals(partToCompare1.utmMarks, partToCompare2.utmMarks);

                partToCompare1 = partToCompare1[partToCompare1.type];
                partToCompare2 = partToCompare2[partToCompare2.type];
              } else {
                equals = false;
              }
            }

            // если хотя бы один из вариантов не равен другому - варианты не равны
            equals = equals && angular.equals(partToCompare1, partToCompare2);
          } else {
            equals = false;
          }
        }
      } else {
        equals = false;
      }
      return equals;
    }

    /**
     * Создание нового триггерного сообщения
     *
     * @param {Boolean} isActivate Активировать сообщение или нет после создания
     */
    function createAutoMessage(isActivate) {
      Promise.all([vm.validateOnTriggerStep(vm.triggerForm)]).then(() => {
        submit(isActivate);
      });
    }

    /**
     * Переход на состояние создания копии
     */
    function createCopy() {
      vm.createCopyPerforming = true;

      $state
        .go(
          $state.current.name + '.copy',
          {},
          {
            reload: $state.current.name,
            location: 'replace',
          },
        )
        .finally(createCopyFinally);

      function createCopyFinally() {
        vm.createCopyPerforming = false;
      }
    }

    /**
     * Автоматическое создание кастомного имени события eventName для цепочек сообщений
     *
     * @param {String} eventName Название события
     */
    function createEventCustomName(eventName) {
      if (!vm.currentMessage[eventName]) {
        let name = $translate.instant('autoMessages.editor.eventCustomNames.' + eventName);
        let id = 0;
        switch (eventName) {
          case 'eventSended':
            id = 1;
            break;
          case 'eventRead':
            id = 2;
            break;
          case 'eventReplied':
            id = 3;
            break;
          case 'eventClicked':
            id = 4;
            break;
          case 'eventUnsubscribed':
            id = 5;
            break;
        }

        if (!vm.currentMessage[eventName] && vm.currentMessage['eventMessage' + id]) {
          vm.currentMessage[eventName] = name + (vm.currentMessage.name ? ' - ' + vm.currentMessage.name : '');
        }
      }
    }

    /**
     * Обработчик для перемещения вперед по шагам визарда из вне
     */
    function forwardHandler() {
      if (vm.step < 2) {
        vm.wizard.nextStep();
      } else {
        createAutoMessage(true);
      }
    }

    /**
     * Получение не пустых фильтров из фильтров по URL
     *
     * @param {Object} filter — Фильтры
     *
     * @returns {boolean}
     */
    function filterEmptySendingFilters(filter) {
      return !!filter.value.value;
    }

    /**
     * Получение текущего активного фильтра
     *
     * @param {Object | null} sendingFilters - Настройки фильтра
     *
     * @return {String}
     */
    function getCurrentFilterType(sendingFilters) {
      if (!sendingFilters) {
        return SENDING_FILTERS_GROUP_TYPES.NO;
      }

      switch (sendingFilters.type) {
        case FILTER_LOGICAL_OPERATION.AND:
          return SENDING_FILTERS_GROUP_TYPES.EXCLUSION;
        case FILTER_LOGICAL_OPERATION.OR:
          return SENDING_FILTERS_GROUP_TYPES.INCLUSION;
        default:
          return SENDING_FILTERS_GROUP_TYPES.NO;
      }
    }

    /**
     * Получение css-класса для кнопки "Создать и запустить позже"
     *
     * @return {Object}
     */
    function getCssClassForCreateAndStartLaterButton() {
      let exceededMessageLimit = !planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
        vm.currentApp,
        vm.activeMessagesAmounts,
      ).hasAccess;
      let hasAccessToMessagePartType = !planFeatureAccessService.getAccess(
        PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.AUTO][vm.currentMessage.parts[0].type],
        vm.currentApp,
      ).hasAccess;
      return {
        'btn-outline-primary': !hasAccessToMessagePartType && !exceededMessageLimit,
        'btn-primary': hasAccessToMessagePartType || exceededMessageLimit,
      };
    }

    /**
     * Получение причин отказа в доступе
     *
     * @return {ProductFeatureDenialReason|ProductFeatureDenialReason[]}
     */
    function getDenialReasonsForActivateAndPause() {
      switch (true) {
        case !vm.accessToAutoMessages.hasAccess:
          return vm.accessToAutoMessages.denialReason;
        case !vm.accessToAutoMessagesType.hasAccess:
          return vm.accessToAutoMessagesType.denialReason;
        default:
          return [
            vm.accessToAutoMessagesAbTesting,
            vm.accessToAutoMessagesControlGroup,
            vm.accessToEventsEventTypesCustom,
            vm.accessToUsersCustomProperties,
            vm.accessToUsersTags,
          ]
            .filter((access) => !access.hasAccess)
            .filter((access) => {
              switch (access) {
                case vm.accessToAutoMessagesAbTesting:
                  return vm.currentMessage.parts.length > 1;
                case vm.accessToAutoMessagesControlGroup:
                  return vm.currentMessage.isControlGroupEnabled;
                case vm.accessToEventsEventTypesCustom:
                  return (
                    $filter('filter')(vm.currentMessage.filters.filters.events, { eventType: { name: '!$' } }, false)
                      .length > 0
                  );
                case vm.accessToUsersCustomProperties:
                  return (
                    $filter('filter')(vm.currentMessage.filters.filters.props, { userProp: { groupOrder: 5 } }, false)
                      .length > 0
                  );
                case vm.accessToUsersTags:
                  return vm.currentMessage.filters.filters.tags.length > 0;
                default:
                  return true;
              }
            })
            .map((access) => access.denialReason);
      }
    }

    /**
     * Получение причины отказа доступа к фиче для биллинговой модалки
     *
     * Сделан отдельный метод т.к. модалка showAutoMessageTotalPaywall не расчитана что кроме ограничений по количеству будут другие ограничения
     *
     * @return {null|ProductFeatureDenialReason}
     */
    function getDenialReasonForBillingModal() {
      switch (true) {
        case !vm.accessToAutoMessages.hasAccess:
          return vm.accessToAutoMessages.denialReason;
        case !vm.accessToAutoMessagesType.hasAccess:
          return vm.accessToAutoMessagesType.denialReason;
        case !vm.accessToAutoMessagesAbTesting.hasAccess && vm.currentMessage.parts.length > 1:
          return vm.accessToAutoMessagesAbTesting.denialReason;
        case !vm.accessToAutoMessagesControlGroup.hasAccess && vm.currentMessage.isControlGroupEnabled:
          return vm.accessToAutoMessagesControlGroup.denialReason;
        case !vm.accessToEventsEventTypesCustom.hasAccess &&
          $filter('filter')(vm.currentMessage.filters.filters.events, { eventType: { name: '!$' } }, false).length > 0:
          return vm.accessToEventsEventTypesCustom.denialReason;
        case !vm.accessToUsersCustomProperties.hasAccess &&
          $filter('filter')(vm.currentMessage.filters.filters.props, { userProp: { groupOrder: 5 } }, false).length > 0:
          return vm.accessToUsersCustomProperties.denialReason;
        case !vm.accessToUsersTags.hasAccess && vm.currentMessage.filters.filters.tags.length > 0:
          return vm.accessToUsersTags.denialReason;
        default:
          return null;
      }
    }

    /**
     * Получение причин отказа в доступе до создания и запуска триггерного сообщения
     *
     * @return {ProductFeatureDenialReason|ProductFeatureDenialReason[]}
     */
    function getDenialReasonsForCreateAndStart() {
      switch (true) {
        case !vm.accessToAutoMessages.hasAccess:
          return vm.accessToAutoMessages.denialReason;
        case !vm.accessToAutoMessagesType.hasAccess:
          return vm.accessToAutoMessagesType.denialReason;
        default:
          return [];
      }
    }

    /**
     * Получение причин отказа в доступе до сохранения триггерного сообщения
     *
     * @return {ProductFeatureDenialReason|ProductFeatureDenialReason[]}
     */
    function getDenialReasonsForSave() {
      switch (true) {
        case !vm.accessToAutoMessagesType.hasAccess:
          return vm.accessToAutoMessagesType.denialReason;
        default:
          return [];
      }
    }

    /**
     * Возвращает имя события по ID
     *
     * @param {String} eventId - ID события
     * @returns {String}
     */
    function getEventTypeByEventId(eventId) {
      return $filter('filter')(vm.eventTypes, { id: eventId }, true)[0];
    }

    /**
     * Получение количества JS-скриптов среди вариантов сообщения
     *
     * @param {Array.<Object>} messageParts Варианты сообщения
     */
    function getJsCount(messageParts) {
      return messagePartModel.filterByMessagePartType(messageParts, MESSAGE_PART_TYPES.JS).length;
    }

    /**
     * Получение параметров для трека
     *
     * @param {Object} params
     */
    function getTrackSaveOrCreateParams(params) {
      let messagePart = messagePartModel.filterMessageParts(params.parts);
      let messagePartType = messagePart.length < 2 ? messagePart[0].type : 'ab_test';
      let platform = RECIPIENT_TYPES.WEB;

      // HACK Роман Е. SDK-типы - это не отдельные типы шаблонов сообщений на backend, а те же самые (кроме Push в SDK).
      // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
      // HACK Сообщаем backend'у, что это сообщение SDK-типа.
      if (angular.isDefined(messagePart[0].recipient_type) && messagePart[0].recipient_type === RECIPIENT_TYPES.SDK) {
        messagePartType = RECIPIENT_TYPES.SDK + '_' + messagePartType;
        platform = RECIPIENT_TYPES.SDK;
      }

      const exactUrlFiltersLength = getExactUrlFiltersLength();
      const urlContainsFiltersLength = getUrlContainsFiltersLength();
      const urlPathEqFiltersLength = getUrlPathEqFiltersLength();

      let trackParams = {
        App: vm.currentApp.name,
        AppId: vm.currentApp.id, // для "Автосообщения - создал" было именно это свойство.
        app_id: vm.currentApp.id,
        Название: params.name,
        Тип: messagePartType,
        platform: platform,
        exact_url: exactUrlFiltersLength,
        url_containing: urlContainsFiltersLength,
        url_parameters: urlPathEqFiltersLength,
      };

      if (~[MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL].indexOf(messagePartType)) {
        trackParams['Тип ответа'] = messagePart[0].reply_type;
      }

      return trackParams;

      /**
       * Получение количества фильтров по URL с типом «С точным адресом»
       *
       * @returns {Number}
       */
      function getExactUrlFiltersLength() {
        return $filter('filter')(
          vm.currentMessage.sendingFilters.filters,
          {
            type: URL_FILTER_TYPE.FULLY_MATCHED,
            match: '!',
          },
          true,
        ).length;
      }

      /**
       * Получение количества фильтров по URL с типом «Содержит в адресе»
       *
       * @returns {Number}
       */
      function getUrlContainsFiltersLength() {
        return $filter('filter')(
          vm.currentMessage.sendingFilters.filters,
          {
            type: URL_FILTER_TYPE.CONTAINS,
            match: '!',
          },
          true,
        ).length;
      }

      /**
       * Получение количества фильтров по URL с типом «С точным адресом и параметрами»
       *
       * @returns {Number}
       */
      function getUrlPathEqFiltersLength() {
        return $filter('filter')(
          vm.currentMessage.sendingFilters.filters,
          {
            type: URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS,
            match: '!',
          },
          true,
        ).length;
      }
    }

    /**
     * Получение переведённых перечисленных через запятую типов вариантов сообщения, которые не поддерживают удаление после отправки
     *
     * @returns {String}
     */
    function getTranslationOfUndeletableMessagePartTypes() {
      let undeletableMessageParts = getUndeletableMessageParts();
      let undeletableMessagePartTypes = $filter('unique')($filter('map')(undeletableMessageParts, 'type'));
      let undeletableMessagePartTypesTranslations = $filter('map')(
        undeletableMessagePartTypes,
        translateMessagePartType,
      );

      return undeletableMessagePartTypesTranslations.join(', ');

      function translateMessagePartType(messagePartType) {
        // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
        // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
        // HACK Перевод для этих типов, выглядит немного не так (отличаются формулировки).
        // HACK Это необходимо, только для "Чат в SDK" и "Поп-ап в SDK".
        let translateInstant = 'models.message.messagePartTypes.';
        if (!!~[MESSAGE_PART_TYPES.SDK_POPUP_CHAT, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL].indexOf(messagePartType)) {
          translateInstant = 'autoMessages.editor.trigger.deleteMessage.deleteMessageForm.notWorkForMessagePartsSdk.';
        }

        return $translate.instant(translateInstant + messagePartType);
      }
    }

    function getTranslationEntityName() {
      return messageModel.getMessagePartTypeName(vm.currentMessage.parts);
    }

    /**
     * Получение вариантов сообщения, которые нельзя удалить, если они были отправлены пользователю
     *
     * @returns {[]}
     */
    function getUndeletableMessageParts() {
      let undeletableMessageParts = [];

      for (let i = 0; i < vm.currentMessage.parts.length; i++) {
        // HACK Роман Е. SDK-типы - это не отдельные типы сообщений на backend, а те же самые (кроме Push в SDK).
        // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
        // HACK Но их "протухание" не заданно жестко и зависит от галки "Отправлять Push-уведомление вместе с сообщением".
        // HACK Если галка выставлена, то тип триггерного сообщения не допустим к протуханию.
        if (
          vm.currentMessage.parts[i][vm.currentMessage.parts[i].type].send_sdk_push ||
          !messagePartModel.isDeletableType(vm.currentMessage.parts[i].type)
        ) {
          undeletableMessageParts.push(vm.currentMessage.parts[i]);
        }
      }

      return undeletableMessageParts;
    }

    /**
     * Получение количества веб хуков среди вариантов сообщения
     *
     * @param {Array.<Object>} messageParts Варианты сообщения
     */
    function getWebhookCount(messageParts) {
      return messagePartModel.filterByMessagePartType(messageParts, MESSAGE_PART_TYPES.WEBHOOK).length;
    }

    /**
     * Есть ли причины отказа в доступе для активации и паузы триггерного сообщения
     *
     * @return {boolean}
     */
    function hasDenialReasonsForActivatedAndPause() {
      const denialReasons = getDenialReasonsForActivateAndPause();

      return angular.isArray(denialReasons) ? !!denialReasons.length : !!denialReasons;
    }

    /**
     * Есть ли причины отказа в доступе для создания и запуска триггерного сообщения
     *
     * @return {boolean}
     */
    function hasDenialReasonsForCreateAndStart() {
      const denialReasons = getDenialReasonsForCreateAndStart();

      return angular.isArray(denialReasons) ? !!denialReasons.length : !!denialReasons;
    }

    /**
     * Есть ли причины отказа в доступе для сохранения триггерного сообщения
     *
     * Идет проверка по типу сообщения.
     * Нельзя сохранять активное сообщение с недоступным типом
     *
     * @return {boolean}
     */
    function hasDenialReasonsForSave() {
      const denialReasons = getDenialReasonsForSave();

      return angular.isArray(denialReasons) ? !!denialReasons.length : !!denialReasons;
    }

    /**
     * Есть ли среди настраиваемых вариантов сообщений хотя бы одно удаляемое
     *
     * @return {Boolean}
     */
    function hasDeletableMessagePart() {
      return getUndeletableMessageParts().length !== vm.currentMessage.parts.length;
    }

    /**
     * Есть ли среди настраиваемых вариантов сообщений хотя бы одно не удаляемое
     *
     * @return {Boolean}
     */
    function hasUndeletableMessagePart() {
      return !!getUndeletableMessageParts().length;
    }

    /**
     * Обработка RTS сообщений
     *
     * @param event
     * @param info
     */
    function handleRts(event, info) {
      let channel = info.channel,
        data = info.data;

      if (channel.indexOf('event_types_activated.') === 0) {
        let callApply = false;

        for (let i = 0; i < data.ids.length; i++) {
          getEventTypeByEventId(data.ids[i]).active = true;
          callApply = true;
        }

        callApply && $scope.$applyAsync();
      } else if (channel.indexOf('event_types_created.') === 0) {
        let callApply = false;

        for (let i = 0; i < data.event_types.length; i++) {
          // NOTE Событие уже может быть добавлено через модальное окно
          //  Надо проверить существование события
          //  т.к. событие может создаться и добавится из модального окна
          if (!getEventTypeByEventId(data.event_types[i].id)) {
            eventTypeModel.parse(data.event_types[i]);
            vm.eventTypes = vm.properties.eventTypes = [...vm.eventTypes, data.event_types[i]];

            callApply = true;
          }

          //NOTE надо проверить существование vm.editableMessage.customTrigger
          // т.к. РТС отрабатывает при любом создании события в админке. А при создании/редактировании триггерного сообщения
          // Данного поля нет. Оно есть только при создании триггерного сообщения из готового триггерного сообщения
          if (vm.editableMessage.customTrigger && data.event_types[i].name === vm.editableMessage.customTrigger.name) {
            vm.currentMessage.triggers = $filter('filter')(vm.currentMessage.triggers, '');
            vm.currentMessage.triggers.push(data.event_types[i].id);
            vm.customEventTypeCreated = true;
            vm.customEventTypeCreating = false;
            vm.editableMessage.customTrigger = null;

            callApply = true;
          }
        }

        callApply && $scope.$applyAsync();
      }
    }

    function isFilterValid() {
      if (vm.filtersForm.$valid) {
        return $q.resolve();
      } else {
        return $q.reject();
      }
    }

    /**
     * Проверка валидности формы
     *
     * @param {form.FormController} form Контроллер формы
     */
    function isFormValid(form) {
      if (angular.isDefined(form)) {
        form.$commitViewValue();
        form.$setSubmitted();

        if (form.$invalid) {
          return $q.reject();
        } else {
          return $q.resolve();
        }
      } else {
        return $q.resolve();
      }
    }

    /**
     * Проверка валидности формы цели сообщения
     * Сделана отдельной функцией, т.к. нужно трекать событие, если установлена цель
     *
     * @param goalForm Форма цели
     * @returns {*}
     */
    function isMessageGoalFormValid(goalForm) {
      return isFormValid(goalForm)
        .catch(() => {
          return $q.reject();
        })
        .then(() => {
          if (vm.currentMessage.goalEventType) {
            trackClickDetermineGoal();
          }
        });
    }

    /**
     * Нужно ли отменять работу hasDenialReasonsForCreateAndStart
     *
     * NOTE:
     *  Пользователь должен иметь возможность деактивировать триггерное сообщение,
     *  даже если у него нет доступа до фичи "Триггерные сообщения"
     *
     *  @return {boolean}
     */
    function isPreventHasDenialReasonsForCreateAndStart() {
      return vm.currentMessage.active;
    }

    /**
     * Показывать или нет штуки связанные с кастомным событием
     *
     * @returns {Boolean}
     */
    function isShowCustomEventStuff() {
      return (
        vm.editableMessage.customTrigger &&
        vm.currentMessage.triggers.length === 1 &&
        vm.currentMessage.triggers[0] === null
      );
    }

    /**
     * Проверяет показывать или нет ссылку на статью и инструкцией по настройке события
     *
     * @param {String} eventTypeId - ID типа события
     * @returns {Boolean}
     */
    function isShowInstructionForSystemEventType(eventTypeId) {
      let eventType = getEventTypeByEventId(eventTypeId);
      return (
        eventType &&
        !eventType.active &&
        eventType.name.indexOf('$') === 0 &&
        !!$filter('cqTranslate')('autoMessages.editor.eventTypesArticles.' + eventType.name)
      );
    }

    /**
     * Переход на шаг с номером stepNumber
     *
     * @param {Number} stepNumber Номер шага
     */
    function moveStep(stepNumber) {
      let trackedParams;
      if (vm.currentMessage.name) {
        trackedParams = {
          Название: vm.currentMessage.name,
        };
      }
      carrotquestHelper.track('Автосообщения - клик на "Изменить" (настройки автосообщения)', trackedParams);

      vm.wizard.goToStep(vm.wizard.getStepByName(stepNumber));
    }

    /**
     * Отметить шаг стартергайда как пройденый
     */
    function markStarterGuideStepMade() {
      if (vm.isEdit) {
        return;
      }
      if (
        !$stateParams.fromStarterGuideStep ||
        $stateParams.fromStarterGuideStep !== STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE
      ) {
        return;
      }
      starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE).subscribe();
    }

    /**
     *
     * @param {MessageEditorTriggerParams} triggerParams
     */
    function onTriggerParamsChange(triggerParams) {
      // обновляем currentMessage
      vm.currentMessage.triggers = triggerParams.triggers;
      vm.currentMessage.triggerTypes = triggerParams.triggerTypes;
      vm.currentMessage.isAfterTime = triggerParams.delay.isEnabled;
      vm.currentMessage.afterTimeValue = triggerParams.delay.value.time;
      vm.currentMessage.afterTimeTimeUnit = triggerParams.delay.value.unit;
      vm.currentMessage.sendingFilters = triggerParams.sendingFilters;
    }

    /**
     * Открыть одно из биллинговых модальных сообщений
     */
    function openDenialReasonBillingModal() {
      const denialReason = getDenialReasonForBillingModal();

      if (denialReason.productFeature === PLAN_FEATURE.AUTO_MESSAGES_TOTAL) {
        return vm.paywallService.showAutoMessageTotalPaywall(vm.currentApp, denialReason, 1, vm.activeMessagesAmounts);
      }

      vm.paywallService.showPaywallForAccessDenial(vm.currentApp, denialReason);
    }

    /**
     * Callback при смене типа варианта сообщения
     *
     * @param {MESSAGE_PART_TYPES} messagePartType
     */
    function onChangeMessagePartType(messagePartType) {
      if (
        messagePartType === vm.MESSAGE_PART_TYPES.EMAIL ||
        messagePartType === vm.MESSAGE_PART_TYPES.JS ||
        messagePartType === vm.MESSAGE_PART_TYPES.WEBHOOK
      ) {
        vm.deleteMessage.deleteType = null;
      }
      // Если тип варианта сообщения поменялся на тот, у которого нельзя выставить фильтр по URL, то нужно принудительно выставить эту настройку в false
      if (!messagePartModel.isFilterableByUrlType(messagePartType)) {
        vm.currentMessage.sendingFilters.type = SENDING_FILTERS_GROUP_TYPES.NO;
      }

      vm.accessToAutoMessagesType = planFeatureAccessService.getAccess(
        PLAN_FEATURE_BY_MESSAGE_PART_TYPE[MESSAGE_TYPES.AUTO][messagePartType],
        vm.currentApp,
      );

      // вызов колбека для дизейбла свитчеров для передачи данных на шаге "условия отправки"
      vm.checkValidityForNotification();
    }

    /**
     * Открытие модального окна для создания папки
     */
    function openCreateDirectoryModal() {
      let createDirectoryModal = modalHelperService.open(DirectoryEditorModalComponent);

      createDirectoryModal.componentInstance.modalWindowParams = {
        currentApp: vm.currentApp,
      };

      createDirectoryModal.result.then(createDirectorySuccess).catch(() => {});

      function createDirectorySuccess(response) {
        if (ACTIONS_ON_DIRECTORY.CREATE === response.action) {
          vm.directories.push(response.directory);
          vm.currentMessage.directory = response.directory;
        }
      }
    }

    /**
     * @param {MessageEditorTriggerState} state
     */
    function onTriggerWrapperStateLoaded(state) {
      // Надо скрыть стандартное событие попытки ухода с сайта, если:
      // - выключен сбор этого события в настройках
      vm.eventTypes.forEach((e) => {
        if (e.name === '$leave_site_attempt' && !vm.currentApp.settings.track_leave_site_attempt) {
          e.visible = false;
        }
      });
      state.autoEvents$.next(vm.autoEvents);
      state.eventTypes$.next(vm.eventTypes);
      state.userProps$.next(vm.properties.userProps);
      state.currentApp$.next(vm.currentApp);
    }

    /**
     * Callback ухода в другой раздел
     * @returns {boolean}
     */
    function onExitFromEditing() {
      if (vm.isUnbeforeunloadSet && !isMessageSaved) {
        const isExit = openConfirmExitModal();
        isExit && updateOnbeforeunloadCallback(false);
        return isExit;
      }
      updateOnbeforeunloadCallback(false);
      return true;
    }

    /**
     * Открыть модальное окно подтверждения ухода в другой раздел
     * @returns {boolean}
     */
    function openConfirmExitModal() {
      return confirm($translate.instant('autoMessages.editor.confirmExitModal.body'));
    }

    /**
     * Проверка форм на изменения данных от пользователя
     * @returns {boolean}
     */
    function isFormControlChangedByUser() {
      const formControlList = [
        vm.triggerForm,
        vm.filtersForm,
        vm.sendingConditionsForm,
        vm.goalForm,
        vm.deleteMessageForm,
      ];
      /*
       NOTE суть такая. Есть директивы (cq-integer), которые меняют $dirty у форм, поэтому надо проверить $dirty, вместе с $touched
        т.к. $touched меняется только от пользовательских действий
        у contentForm надо проверить только $dirty потому что froala при изменении поля не меняет $touched
       */
      return (
        vm.contentForm?.$dirty ||
        formControlList.some((formController) => {
          return (
            formController?.$dirty &&
            formController?.$getControls().some((modelController) => {
              return modelController.$dirty && modelController.$touched;
            })
          );
        })
      );
    }

    /**
     * Архивация/восстановление сообщения
     *
     * @param {Boolean} reverse Флаг архивация или восстановление сообщения
     */
    function archiveOrReestablishMessage(reverse) {
      let heading, body, confirmButtonText;

      vm.archiveOrReestablishRequestPerforming = true;

      if (reverse) {
        heading = $translate.instant('autoMessages.editor.archiveOrReestablishModal.reestablish.heading');
        body = $translate.instant('autoMessages.editor.archiveOrReestablishModal.reestablish.body');
        confirmButtonText = $translate.instant(
          'autoMessages.editor.archiveOrReestablishModal.reestablish.confirmButtonText',
        );
      } else {
        heading = $translate.instant('autoMessages.editor.archiveOrReestablishModal.archive.heading');
        body = $translate.instant('autoMessages.editor.archiveOrReestablishModal.archive.body');
        confirmButtonText = $translate.instant(
          'autoMessages.editor.archiveOrReestablishModal.archive.confirmButtonText',
        );
      }

      let archiveOrReestablishModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: heading,
              body: body,
              confirmButtonText: confirmButtonText,
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      archiveOrReestablishModal.result.then(archiveOrReestablish).finally(archiveOrReestablishFinally);

      function archiveOrReestablish() {
        if (reverse) {
          return firstValueFrom(messageModel.reestablishAutoMessages(vm.currentApp.id, vm.currentMessage.id)).then(
            archiveOrReestablishSuccess,
          );
        } else {
          return firstValueFrom(messageModel.archiveAutoMessages(vm.currentApp.id, vm.currentMessage.id)).then(
            archiveOrReestablishSuccess,
          );
        }

        function archiveOrReestablishSuccess() {
          if (reverse) {
            toastr.success($translate.instant('autoMessages.editor.toasts.autoMessageReestablished'));
          } else {
            trackArchived();
            toastr.success($translate.instant('autoMessages.editor.toasts.autoMessageArchived'));
          }
          isMessageSaved = true;
        }
      }

      function archiveOrReestablishFinally() {
        vm.archiveOrReestablishRequestPerforming = false;
      }
    }

    /**
     * Добивка строки нулями и возвращения его в виде строки
     *
     * @param {String|Number} num Число для добивки
     * @param {String|Number} size Сколькими нулями добивать
     * @returns {String}
     */
    function pad(num, size) {
      return ('000000000' + num).substr(-size);
    }

    /**
     * Парсинг пришедшего с сервера триггерного сообщения (или сценария, из которого создаётся триггерное сообщение)
     *
     * @param {Object} editableMessage Триггерное сообщения для парсинга
     */
    function parseEditableMessage(editableMessage) {
      vm.currentMessage.id = editableMessage.id;
      vm.currentMessage.notificationIntegrations = messageModel.parseNotificationsToInternalFormat(
        editableMessage.notificationIntegrations,
      );

      // ТРИГГЕР
      vm.currentMessage.triggers = editableMessage.triggers;
      vm.currentMessage.triggerTypes = editableMessage.triggerTypes;
      vm.currentMessage.isAfterTime = !!editableMessage.afterDelay;
      if (vm.currentMessage.isAfterTime) {
        vm.currentMessage.afterTimeValue = editableMessage.afterDelay;
        vm.currentMessage.afterTimeTimeUnit = timeUnitService.getByValue(
          vm.currentMessage.afterTimeValue,
          vm.currentMessage.afterTimeTimeUnits,
        );
      } else {
        vm.currentMessage.afterTimeValue = 5;
        vm.currentMessage.afterTimeTimeUnit = TIME_UNITS.SECOND;
      }
      vm.currentMessage.isSendAtTime = !(
        editableMessage.deliveryTimeStart === '00:00:00' && editableMessage.deliveryTimeEnd === '23:59:59'
      );
      if (vm.currentMessage.isSendAtTime) {
        let sendTimeFrom = new Date();
        sendTimeFrom.setHours(editableMessage.deliveryTimeStart.substr(0, 2));
        sendTimeFrom.setMinutes(editableMessage.deliveryTimeStart.substr(3, 2));

        let sendTimeTo = new Date();
        sendTimeTo.setHours(editableMessage.deliveryTimeEnd.substr(0, 2));
        sendTimeTo.setMinutes(editableMessage.deliveryTimeEnd.substr(3, 2));

        vm.currentMessage.sendTimeValueFromH = pad(sendTimeFrom.getHours(), 2);
        vm.currentMessage.sendTimeValueFromM = pad(sendTimeFrom.getMinutes(), 2);
        vm.currentMessage.sendTimeValueToH = pad(sendTimeTo.getHours(), 2);
        vm.currentMessage.sendTimeValueToM = pad(sendTimeTo.getMinutes(), 2);
      }
      vm.currentMessage.expirationTime = editableMessage.expirationTime;
      vm.currentMessage.expirationInterval = editableMessage.expirationInterval;

      if (editableMessage.expirationInterval) {
        vm.deleteMessage.deleteType = MESSAGE_DELETING_TYPES.TIME_INTERVAL;
        vm.deleteMessage.interval.value = editableMessage.expirationInterval;
        vm.deleteMessage.interval.unit = timeUnitService.getByValue(
          editableMessage.expirationInterval,
          vm.deleteMessage.interval.units,
        );
      } else if (editableMessage.expirationTime) {
        vm.deleteMessage.deleteType = MESSAGE_DELETING_TYPES.CERTAIN_DATE;
        vm.deleteMessage.time.date = editableMessage.expirationTime;
        vm.deleteMessage.time.hours = editableMessage.expirationTime.hours();
        vm.deleteMessage.time.minutes = editableMessage.expirationTime.minutes();
        vm.deleteMessage.time.time = editableMessage.expirationTime;
      }

      // АУДИТОРИЯ
      vm.currentMessage.filters = editableMessage.filters;
      vm.currentMessage.jinjaFilterTemplate = editableMessage.jinjaFilterTemplate;
      vm.shownAudienceFilterType = editableMessage.jinjaFilterTemplate
        ? AUDIENCE_FILTER_TYPE.JINJA
        : AUDIENCE_FILTER_TYPE.DEFAULT;

      filterAjsModel.linkWithPropsAndTags(vm.currentMessage.filters, vm.properties, vm.tags);
      vm.currentMessage.isMessageHaveFilters =
        filterAjsModel.isMessageHaveFilters(editableMessage) || vm.currentMessage.jinjaFilterTemplate !== null;
      // NOTE Трогать с осторожностью! Такая хитрая фильтрация тегов для того, чтобы показывать только не удаленные теги и удаленные, но которые находятся в фильтрах
      vm.tags = $filter('filter')(vm.tags, { removed: '!' }); // Из всех тегов получаем только не удаленные
      vm.tags.push.apply(
        vm.tags,
        $filter('map')($filter('filter')(vm.currentMessage.filters.filters.tags, { tag: { removed: '!!' } }), 'tag'),
      ); // Из фильтров триггерного сообщения получаем теги (именно теги, не объект фильтра), которые были удалены

      // УСЛОВИЯ ОТПРАВКИ
      vm.currentMessage.isRepeat = !(editableMessage.repeatDelay >= 1000000000);
      if (vm.currentMessage.isRepeat) {
        vm.currentMessage.repeatDelay = editableMessage.repeatDelay;
        vm.currentMessage.repeatDelayTimeUnit = timeUnitService.getByValue(
          vm.currentMessage.repeatDelay,
          vm.currentMessage.repeatDelayTimeUnits,
        );
      }
      vm.currentMessage.notSendReplied = editableMessage.notSendReplied;

      addControlGroup(messagePartModel.filterControlGroup(editableMessage.activeTestGroup.parts));
      if (vm.currentMessage.controlGroup.proportion == 0) {
        // если процент контрольной группы - 0, то выставляем его в 10% (значение по умолчанию)
        vm.currentMessage.controlGroup.proportion = 0.1;
      } else {
        vm.currentMessage.isControlGroupEnabled = true;
      }
      refreshProportions(vm.currentMessage.parts);
      vm.currentMessage.userStatuses = editableMessage.userStatuses;

      // Фильтры по URL
      vm.currentMessage.sendingFilters = editableMessage.sendingFilters;

      // СОДЕРЖАНИЕ
      let filteredParts = messagePartModel.filterMessageParts(editableMessage.activeTestGroup.parts);
      for (let i = 0; i < filteredParts.length; i++) {
        addMessagePart(false, filteredParts[i]);
      }
      // сохраняем оригинальные варианты сообщения, которые пришли с сервера
      originalMessageParts = angular.copy(vm.currentMessage.parts);

      // ЦЕЛЬ
      vm.currentMessage.hasGoal = !!(editableMessage.goalEventType || editableMessage.goalEventTypeName);
      vm.currentMessage.goalEventType = editableMessage.goalEventType;
      if (editableMessage.goalEventCost != null) {
        vm.currentMessage.goalEventCostType = 'manual';
      } else if (editableMessage.goalEventCostProp) {
        vm.currentMessage.goalEventCostType = 'eventField';
      } else {
        vm.currentMessage.goalEventCostType = 'none';
      }
      vm.currentMessage.goalEventCost =
        editableMessage.goalEventCost != null ? editableMessage.goalEventCost : vm.currentMessage.goalEventCost;
      vm.currentMessage.goalEventCostProp =
        editableMessage.goalEventCostProp != null
          ? editableMessage.goalEventCostProp
          : vm.currentMessage.goalEventCostProp;
      vm.currentMessage.goalEventTimeout =
        editableMessage.goalEventTimeout != null
          ? editableMessage.goalEventTimeout
          : vm.currentMessage.goalEventTimeout;
      vm.currentMessage.goalEventTimeoutTimeUnit = timeUnitService.getByValue(
        vm.currentMessage.goalEventTimeout,
        vm.currentMessage.goalEventTimeoutTimeUnits,
      );

      // ПРОВЕРКА И ЗАПУСК
      vm.currentMessage.generateName = false; // у сообщения уже задано имя и генерировать новое не надо
      vm.currentMessage.name = editableMessage.name;
      vm.currentMessage.eventSended = editableMessage.eventSended;
      vm.currentMessage.eventRead = editableMessage.eventRead;
      vm.currentMessage.eventReplied = editableMessage.eventReplied;
      vm.currentMessage.eventClicked = editableMessage.eventClicked;
      vm.currentMessage.eventUnsubscribed = editableMessage.eventUnsubscribed;

      vm.currentMessage.eventMessage1 = !!vm.currentMessage.eventSended;
      vm.currentMessage.eventMessage2 = !!vm.currentMessage.eventRead;
      vm.currentMessage.eventMessage3 = !!vm.currentMessage.eventReplied;
      vm.currentMessage.eventMessage4 = !!vm.currentMessage.eventClicked;
      vm.currentMessage.eventMessage5 = !!vm.currentMessage.eventUnsubscribed;

      vm.currentMessage.active = editableMessage.active;
      vm.currentMessage.directory = editableMessage.directory ? editableMessage.directory : vm.currentMessage.directory;
    }

    /**
     * Парсинг URL-фильтра
     * NOTE:
     *  Если в настройках URL-фильтра присутствует звездочка, то нужно незаметно для пользователя заменить тип фильтра.
     *  Необходимо это только при значении фильтра "Содержат в адресе"
     *
     * @param {Array} filters - Массив с фильтрами
     *
     * @return {Array}
     */
    function parseSendingFilters(filters) {
      filters.forEach((filter) => {
        if (filter.value.value.includes('*')) {
          if (filter.type === SENDING_FILTERS_TYPES.URL_CONTAINS) {
            filter.type = SENDING_FILTERS_TYPES.URL_CONTAINS_WILDCARD;
          } else if (filter.type === SENDING_FILTERS_TYPES.URL_NOT_CONTAINS) {
            filter.type = SENDING_FILTERS_TYPES.URL_NOT_CONTAINS_WILDCARD;
          } else if (filter.type === SENDING_FILTERS_TYPES.URL_PATH_EQ) {
            filter.type = SENDING_FILTERS_TYPES.URL_CONTAINS_WILDCARD;
          } else if (filter.type === SENDING_FILTERS_TYPES.URL_PATH_NOT_EQ) {
            filter.type = SENDING_FILTERS_TYPES.URL_NOT_CONTAINS_WILDCARD;
          }
        }
      });

      return filters;
    }

    /**
     * Подготавливает сообщение к созданию его копии(удаляет все id)
     */
    function preparingCreateCopy() {
      delete vm.currentMessage.id;

      vm.currentMessage.name = vm.currentMessage.name + ' (' + $translate.instant('autoMessages.editor.copy') + ')';
      for (let i = 0; i < vm.currentMessage.parts.length; i++) {
        delete vm.currentMessage.parts[i].id;
      }

      vm.currentMessage.eventMessage1 = false;
      vm.currentMessage.eventMessage2 = false;
      vm.currentMessage.eventMessage3 = false;
      vm.currentMessage.eventMessage4 = false;
      vm.currentMessage.eventMessage5 = false;

      delete vm.currentMessage.eventRead;
      delete vm.currentMessage.eventReplied;
      delete vm.currentMessage.eventSended;
      delete vm.currentMessage.eventUnsubscribed;
      delete vm.currentMessage.eventClicked;

      if (vm.currentMessage.controlGroup) {
        delete vm.currentMessage.controlGroup.id;
      }

      if (vm.currentMessage.active) {
        vm.currentMessage.active = false;
      }

      if (vm.currentMessage.directory.name === SYSTEM_DIRECTORIES.ARCHIVE) {
        vm.currentMessage.directory = $filter('filter')(
          vm.directories,
          { id: PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.WITHOUT_DIRECTORY] },
          true,
        )[0];
      }

      vm.isEdit = false;
    }

    /**
     * Обработка форм всех вариантов сообщений.
     * Это сделано потому, что ng-form (в которую обёрнуты все варианты триггерного сообщения) нельзя сабмитить, поэтому это надо сделать искусственно на submit родительской формы
     *
     * @param {form.FormController} form Валидность формы
     * @param {Array.<Object>} messageParts Варианты триггерного сообщения
     */
    function processMessagePartForms(form, messageParts) {
      return isFormValid(form)
        .catch(isFormValidError)
        .then(checkMessageValidityForNotifications)
        .then(toggleUtmMarks)
        .then(openClosePreviousTestGroupModal)
        .then(openClosePreviousTestGroupModalSuccess);

      function isFormValidError() {
        // если форма с вариантами сообщения оказалась невалидна - значит какой-то вариант(ы) невалиден
        // индекс messagePart совпадает с индексом формы для messagePart
        // поэтому можно легко свернуть валидные и развернуть невалидные варианты
        for (let i = 0; i < messageParts.length; i++) {
          messageParts[i].showContent = vm.contentForm.$getControls()[i].$invalid;
          messageParts[i].handleError && messageParts[i].handleError();
        }

        return $q.reject();
      }

      /**
       * После возможной смены типов нужно проверить, остался ли он валидным для отправки нотификаций.
       * Если стал невалидным - удаляем нотификацию из сообщения.
       * Если нотификации нет, то можно и не проверять.
       */
      function checkMessageValidityForNotifications() {
        const notifications = vm.currentMessage.notificationIntegrations;

        if (!notifications?.emailNotification && !notifications?.amocrm) {
          return;
        }

        const { aSideError, bSideError } = checkMessagePartValidityForNotification(messageParts, vm.MESSAGE_PART_TYPES);

        if ((messageParts.length === 1 && aSideError) || (messageParts.length === 2 && aSideError && bSideError)) {
          notifications.emailNotification = null;
          notifications.amocrm = null;
        }
      }

      /**
       * Отключение UTM меток, если в них ничего не заполнено, и заполнение их нашими стандартными значениями
       */
      function toggleUtmMarks() {
        for (let i = 0; i < messageParts.length; i++) {
          if (
            messageParts[i][MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled &&
            utmMarkModel.isEmpty(messageParts[i][MESSAGE_PART_TYPES.EMAIL].utmMarks)
          ) {
            messageParts[i][MESSAGE_PART_TYPES.EMAIL].isUtmMarksEnabled = false;
            messageParts[i][MESSAGE_PART_TYPES.EMAIL].utmMarks = utmMarkModel.getPredefined();
          }
        }
      }

      function openClosePreviousTestGroupModal() {
        let originalMessagePartsCount = originalMessageParts.length;

        // Модальное окно не показывается и осуществляется переход на следующий шаг (с сохранением текущего значения переменной vm.currentMessage.closePreviousTestGroup) в следующих случаях:
        // 1) А/Б тест вообще не проводился
        // 2) А/Б тест проводился, но варианты А/Б теста никак не менялись
        // 3) А/Б тест проводился, варианты А/Б теста менялись, и пользователь подтвердил завершение предыдущего А/Б теста (закрытие предыдущей тест-группы)
        if (
          originalMessagePartsCount >= 2 &&
          !compareMessageParts(messageParts, originalMessageParts) &&
          !vm.currentMessage.closePreviousTestGroup
        ) {
          let closePreviousTestGroupModal = $uibModal.open({
            templateUrl: 'js/components/auto-messages/editor/close-test-group.html',
          });

          return closePreviousTestGroupModal.result;
        } else {
          return vm.currentMessage.closePreviousTestGroup;
        }
      }

      function openClosePreviousTestGroupModalSuccess(result) {
        vm.currentMessage.closePreviousTestGroup = result;
      }
    }

    /**
     * Обновление пропорций вариантов триггерного сообщения
     *
     * @param {Array.<Object>} messageParts Варианты триггерного сообщения
     */
    function refreshProportions(messageParts) {
      let controlGroupProportion = vm.currentMessage.controlGroup.proportion;

      for (let i = 0; i < messageParts.length; i++) {
        messageParts[i].proportion =
          controlGroupProportion + ((1 - controlGroupProportion) / messageParts.length) * (i + 1);
      }
    }

    /**
     * Все ли события для цепочек сообщения в состоянии по умолчанию
     *
     * @return {Boolean}
     */
    function isAllEventsForChainsInDefaultState() {
      return [
        vm.currentMessage.eventMessage1,
        vm.currentMessage.eventMessage2,
        vm.currentMessage.eventMessage3,
        vm.currentMessage.eventMessage4,
        vm.currentMessage.eventMessage5,
      ].every((value) => value === false);
    }

    /**
     * Функция отправки информации об триггерном сообщении на сервер
     *
     * @param {Boolean} active Будет ли включено триггерное сообщение после сохранения
     * @param {Boolean?} testAutomessageGraph Проверять ли сообщения на зацикливание
     */
    function submit(active, testAutomessageGraph) {
      $timeout(() => {
        vm.submitRequestPerforming = true;
      }, 10);

      testAutomessageGraph = angular.isDefined(testAutomessageGraph) ? testAutomessageGraph : active;
      let i;
      function successCallback(response) {
        vm.jinjaFilterTemplateCheckingResult = undefined;

        // HACK при создании первого триггерного сообщения, чтобы модальное окно активации больше не показывалась, надо обновить created_auto_message. Пока это сделано тут. В идеале надо сделать это в модели
        if (!vm.currentApp.activation.created_auto_message) {
          vm.currentApp.activation.created_auto_message = moment();
        }

        isMessageSaved = true;

        vm.currentMessage.active = active;

        if (!vm.isEdit) {
          $state.go('app.content.messagesAjs.edit', { messageId: response.data.id });
        }
        toastr.success($translate.instant('autoMessages.editor.toasts.messageHasBeenSaved'));

        if (appModel.isAppBlocked(vm.currentApp) && active) {
          let modal = modalHelperService.open(InstallScriptModalComponent);

          modal.componentInstance.modalWindowParams = {
            body: $translate.instant('autoMessages.editor.installScriptModal.body'),
            currentApp: vm.currentApp,
            djangoUser: vm.djangoUser,
            heading: $translate.instant('autoMessages.editor.installScriptModal.heading'),
          };
        }
      }

      function failedCallback(response) {
        let exception = caseStyleHelper.keysToCamelCase(response);
        if (exception.error == 'EventTypeLoopError') {
          let detectedMessageLoop = modalHelperService.open(AutoMessageLoopModalComponent);

          detectedMessageLoop.componentInstance.modalWindowParams = {
            currentMessageIds: exception.currentMessageIds,
            currentMessageName: vm.currentMessage.name,
            saveActivateButton: vm.isEdit
              ? $translate.instant('autoMessages.editor.detectedMessageLoopModal.saveAndTurnOn')
              : $translate.instant('autoMessages.editor.detectedMessageLoopModal.createAndStart'),
            saveDeactivateButton: vm.isEdit
              ? $translate.instant('autoMessages.editor.detectedMessageLoopModal.saveAndTurnOff')
              : $translate.instant('autoMessages.editor.detectedMessageLoopModal.createAndStartLater'),
            loops: exception.loops[0],
          };

          detectedMessageLoop.result.then(function (result) {
            submit(result, false);
          });
        } else if (exception.error === 'ValidationError' && exception.errorFields['jinjaFilterTemplate']) {
          vm.jinjaFilterTemplateCheckingResult = exception.errorMessage;
        } else if (exception.error == 'EmailMessageTooBig') {
          toastr.error(
            $translate.instant('autoMessages.editor.toasts.emailMessageTooBig', { htmlMaxSize: HTML_MAX_SIZE / 1000 }),
          );
        } else if (exception.errorFields.expirationTime) {
          toastr.error($translate.instant('autoMessages.editor.toasts.expirationTimeError'));
        } else {
          systemError.somethingWentWrongToast.show();
        }
      }

      function finallyCallback() {
        $timeout(() => {
          vm.submitRequestPerforming = false;
        }, 10);
      }

      if (!vm.currentMessage.isControlGroupEnabled) {
        vm.currentMessage.controlGroup.proportion = 0;
        refreshProportions(vm.currentMessage.parts);
      }

      let triggers = vm.currentMessage.triggers.join(',');

      if (vm.currentMessage.isMessageHaveFilters) {
        if (vm.shownAudienceFilterType === AUDIENCE_FILTER_TYPE.DEFAULT) {
          vm.currentMessage.jinjaFilterTemplate = null;
        } else {
          vm.currentMessage.filters = filterAjsModel.getDefaultAnd();
        }
      } else {
        vm.currentMessage.jinjaFilterTemplate = null;
        vm.currentMessage.filters = filterAjsModel.getDefaultAnd();
      }

      let filters = filterAjsModel.parseToServerFormat(
        vm.currentMessage.isMessageHaveFilters ? vm.currentMessage.filters : filterAjsModel.getDefaultAnd(),
      );

      let params = {
        app: vm.currentApp.id,
        name: vm.currentMessage.name,
        active: active,
        filters: filters,
        triggers: triggers ? triggers : null,
        triggerTypes: messageModel.parseTriggerTypesToServeFormat(vm.currentMessage.triggerTypes),
        afterDelay: !vm.currentMessage.isAfterTime ? 0 : vm.currentMessage.afterTimeValue,
        repeatDelay: !vm.currentMessage.isRepeat ? 1000000000 : vm.currentMessage.repeatDelay,
        notSendReplied: vm.currentMessage.notSendReplied,
        userStatuses: vm.currentMessage.userStatuses,
        messageType: 'auto', // для триггерных сообщений всегда auto
        parts: [],
        closeTest: vm.currentMessage.closePreviousTestGroup,
        goalEventType: vm.currentMessage.goalEventType ? vm.currentMessage.goalEventType : null,
        goalEventTimeout: vm.currentMessage.goalEventType ? vm.currentMessage.goalEventTimeout : null,
        jinjaFilterTemplate: vm.currentMessage.jinjaFilterTemplate,
        eventSended: vm.currentMessage.eventMessage1 ? vm.currentMessage.eventSended : '',
        eventRead: vm.currentMessage.eventMessage2 ? vm.currentMessage.eventRead : '',
        eventReplied: vm.currentMessage.eventMessage3 ? vm.currentMessage.eventReplied : '',
        eventClicked: vm.currentMessage.eventMessage4 ? vm.currentMessage.eventClicked : '',
        eventUnsubscribed: vm.currentMessage.eventMessage5 ? vm.currentMessage.eventUnsubscribed : '',
        directory: vm.currentMessage.directory ? vm.currentMessage.directory.id : null,
        notificationIntegrations: messageModel.parseNotificationToExternalFormat(
          vm.currentMessage.notificationIntegrations,
          vm.currentMessage.id,
        ),
        testAutomessageGraph,
        ...messageModel.parseSendTimeToExternal(vm.currentMessage),
      };

      // удаление сообщения (протухание)
      if (hasDeletableMessagePart()) {
        if (vm.deleteMessage.deleteType === MESSAGE_DELETING_TYPES.CERTAIN_DATE) {
          params.expirationTime = vm.deleteMessage.time.time.unix();
        } else if (vm.deleteMessage.deleteType === MESSAGE_DELETING_TYPES.TIME_INTERVAL) {
          params.expirationInterval = vm.deleteMessage.interval.value;
        }
      }

      const isOpenedPageTriggerTypes =
        params.triggerTypes.length &&
        params.triggerTypes.every(
          (triggerType) =>
            triggerType.kind === TRIGGER_TYPE_KIND.URL || triggerType.kind === TRIGGER_TYPE_KIND.SDK_PAGE,
        );
      if (
        vm.currentMessage.sendingFilters.filters.length &&
        vm.currentMessage.sendingFilters.filters.type !== SENDING_FILTERS_GROUP_TYPES.NO &&
        !isOpenedPageTriggerTypes
      ) {
        const inverseFilters = vm.currentMessage.sendingFilters.type === SENDING_FILTERS_GROUP_TYPES.EXCLUSION;

        params.sendingFilters = {
          type: vm.currentMessage.sendingFilters.type === SENDING_FILTERS_GROUP_TYPES.EXCLUSION ? 'and' : 'or',
          filters: UrlFilterMapper.filtersToExternal(vm.currentMessage.sendingFilters.filters, inverseFilters),
        };
      } else if (isOpenedPageTriggerTypes) {
        params.sendingFilters = UrlFilterMapper.externalSendingFiltersForOpenedPageTriggerTypes(params.triggerTypes);
      } else {
        params.sendingFilters = null;
      }

      //проверка должна быть в модели вместе с созданием и редактированием сообщения
      if (params.directory === PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.WITHOUT_DIRECTORY]) {
        params.directory = null;
      }

      // FIXME ApiRequest: отдельная обработка цели, т.к. сейчас ApiRequest посылает параметр как пустую строку, если ему присвоен null, а чтобы вообще не посылать параметр (как и требуется в данном случае) надо вообще не задавать параметр
      if (vm.currentMessage.goalEventType && vm.currentMessage.goalEventCostType == 'manual') {
        params.goalEventCost = vm.currentMessage.goalEventCost;
      } else if (vm.currentMessage.goalEventType && vm.currentMessage.goalEventCostType == 'eventField') {
        params.goalEventCostProp = vm.currentMessage.goalEventCostProp;
      }

      // HACK FIXME: дальше идёт говнокод, при помощи которого грузятся иконки push'ей на сервер, если такие были выбраны
      let preprocessingTasks = [];

      let pushWithNewIconPredicate = {};
      pushWithNewIconPredicate[MESSAGE_PART_TYPES.PUSH] = {
        newIcon: {
          name: '!!',
        },
      };
      let pushesWithNewIcon = $filter('filter')(
        messagePartModel.filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.PUSH),
        pushWithNewIconPredicate,
      );

      if (pushesWithNewIcon.length) {
        let uploadIconRequests = [];

        for (i = 0; i < pushesWithNewIcon.length; i++) {
          let push = pushesWithNewIcon[i];

          uploadIconRequests.push(
            firstValueFrom(messageModel.uploadPushIcon(vm.currentApp.id, push[MESSAGE_PART_TYPES.PUSH].newIcon)),
          );
        }

        preprocessingTasks.push($q.all(uploadIconRequests).then(setNewIcons));
      }

      // HACK FIXME: дальше идёт говнокод, при помощи которого грузятся картинки и создаются события в блочных поп-апах
      let blockPopupMessageParts = messagePartModel
        .filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.BLOCK_POPUP_BIG)
        .concat(messagePartModel.filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL))
        .concat(
          messagePartModel.filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL),
        );
      let imagesBeforeLoad = []; // HACK: пытаюсь отловить баг, при котором на сервер уходит блок типа Картинка без поля backgroundImage

      if (blockPopupMessageParts.length) {
        for (i = 0; i < blockPopupMessageParts.length; i++) {
          let blockPopup = blockPopupMessageParts[i][blockPopupMessageParts[i].type];
          let popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

          blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

          for (let j = 0; j < popupBlocks.length; j++) {
            let popupBlock = popupBlocks[j];

            if (popupBlock.type == 'image') {
              imagesBeforeLoad.push(popupBlock);
            }

            preprocessingTasks.push(popupBlockModel.saveAdditionalData(vm.currentApp.id, popupBlock));
          }
        }
      }

      // HACK FIXME: дальше идёт говнокод, при помощи которого грузятся дополнительные данные в вариантах сообщения
      for (i = 0; i < vm.currentMessage.parts.length; i++) {
        preprocessingTasks.push(messagePartModel.saveAdditionalData(vm.currentMessage.parts[i]));
      }

      // HACK FIXME: дальше повторяю говнокод, при помощи которого грузятся картинки для телеги
      let telegramMessageParts = messagePartModel.filterByMessagePartType(
        vm.currentMessage.parts,
        MESSAGE_PART_TYPES.TELEGRAM,
      );

      for (let part of telegramMessageParts) {
        for (let content of part[part.type].bodyJson.contents) {
          preprocessingTasks.push(messagePartModel.saveAdditionalDataForTelegramMessage(content));
        }
      }

      $q.all(preprocessingTasks)
        .then(checkBlockPopupBackgroundImageUrl)
        .then(createCustomEventTypes)
        .then(parsePartsAndSaveMessage)
        .finally(submitFinished);

      // HACK: пытаюсь отловить баг, при котором на сервер уходит блок типа Картинка без поля backgroundImage
      function checkBlockPopupBackgroundImageUrl() {
        let blockPopupMessageParts = messagePartModel
          .filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.BLOCK_POPUP_BIG)
          .concat(
            messagePartModel.filterByMessagePartType(vm.currentMessage.parts, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL),
          );

        if (blockPopupMessageParts.length) {
          for (let i = 0; i < blockPopupMessageParts.length; i++) {
            let blockPopup = blockPopupMessageParts[i][blockPopupMessageParts[i].type];
            let popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

            blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

            for (let j = 0; j < popupBlocks.length; j++) {
              let popupBlock = popupBlocks[j];

              if (popupBlock.type == 'image' && !popupBlock.params.backgroundImage) {
                systemError.somethingWentWrongToast.show();
                return $q.reject();
              }
            }
          }
        }

        return $q.resolve();
      }

      function submitFinished() {
        $timeout(() => {
          vm.submitRequestPerforming = false;
        }, 10);
      }

      function setNewIcons(icons) {
        for (let i = 0; i < icons.length; i++) {
          let icon = icons[i];

          pushesWithNewIcon[i][MESSAGE_PART_TYPES.PUSH].newIconUrl = USER_FILES_URL + '/push-icons/' + icon.filename;
        }
      }

      function parsePartsAndSaveMessage() {
        // обработка вариантов триггерного сообщения
        for (let i = 0; i < vm.currentMessage.parts.length; i++) {
          // Нужно проверить есть ли у парта отправитель и, если его нет, то выбрать дефолтного. Это нужно для фикса бага:
          //  у некоторых триггерных сообщений по какой-то причине отсутствует отправитель
          if (vm.currentMessage.parts[i].type == MESSAGE_PART_TYPES.EMAIL) {
            if (!vm.currentMessage.parts[i][MESSAGE_PART_TYPES.EMAIL].sender) {
              vm.currentMessage.parts[i][MESSAGE_PART_TYPES.EMAIL].sender = $filter('filter')(
                vm.messageSenders,
                { isDefault: true },
                true,
              )[0];
            }
          } else if (vm.currentMessage.parts[i].type === MESSAGE_PART_TYPES.POPUP_CHAT) {
            // если у сообщения задан отправитель - надо проверить, есть ли он в списке отправителей, и если его нет - добавить (такое может быть, если этот отправитель удалён, но остался закреплённым за сообщением)
            if (!vm.currentMessage.parts[i][MESSAGE_PART_TYPES.POPUP_CHAT].sender) {
              vm.currentMessage.parts[i][MESSAGE_PART_TYPES.POPUP_CHAT].sender = $filter('filter')(
                vm.messageSenders,
                { isDefault: true },
                true,
              )[0];
            }
          }

          params.parts.push(messagePartModel.parseMessagePartToServerFormat(vm.currentMessage.parts[i]));
        }

        params.parts.push(messagePartModel.parseMessagePartToServerFormat(vm.currentMessage.controlGroup));

        if (vm.currentMessage.closePreviousTestGroup) {
          for (let i = 0; i < params.parts.length; i++) {
            delete params.parts[i].id;
          }
        }

        messagePartModel.generatePartNames(params.parts);

        if (!vm.currentMessage.id) {
          trackCreate(params);
        } else {
          trackSave(params);
        }

        if (vm.currentMessage.id) {
          firstValueFrom(messageModel.saveAutoMessage(vm.currentMessage.id, params))
            .then(successCallback)
            .catch(failedCallback)
            .finally(finallyCallback);
        } else {
          firstValueFrom(messageModel.createAutoMessage(params))
            .then(successCallback)
            .catch(failedCallback)
            .finally(finallyCallback);
        }
      }

      function createCustomEventTypes() {
        let params2 = [];
        if (vm.currentMessage.eventSended && vm.currentMessage.eventMessage1) {
          params2.push(vm.currentMessage.eventSended);
        }

        if (
          getJsCount(vm.currentMessage.parts) + getWebhookCount(vm.currentMessage.parts) <
          vm.currentMessage.parts.length
        ) {
          if (vm.currentMessage.eventRead && vm.currentMessage.eventMessage2) {
            params2.push(vm.currentMessage.eventRead);
          }
          if (vm.currentMessage.eventReplied && vm.currentMessage.eventMessage3) {
            params2.push(vm.currentMessage.eventReplied);
          }
          if (vm.currentMessage.eventClicked && vm.currentMessage.eventMessage4) {
            params2.push(vm.currentMessage.eventClicked);
          }
          if (vm.currentMessage.eventUnsubscribed && vm.currentMessage.eventMessage5) {
            params2.push(vm.currentMessage.eventUnsubscribed);
          }
        }

        if (params2.length > 0 && vm.accessToEventsEventTypesCustom.hasAccess) {
          return firstValueFrom(eventTypeModel.create(vm.currentApp.id, params2));
        }
      }
    }

    /**
     * Трек переноса триггерного сообщения в архив
     */
    function trackArchived() {
      carrotquestHelper.track('Автосообщение - переместил автосообщение в архив', { App: vm.currentApp.name });
    }

    /**
     * Трек клика на 'Определить цель'
     */
    function trackClickDetermineGoal() {
      carrotquestHelper.track('Автосообщение - клик на "Определить цель"');
    }

    /**
     * Трек клика на 'Показать подсказки'
     */
    function trackClickShowTips() {
      if (vm.step === 1) {
        carrotquestHelper.track('Автосообщения - показал подсказки на содержании', { App: vm.currentApp.name });
      }
      if (vm.step === 2) {
        carrotquestHelper.track('Автосообщения - показал подсказки на настройках', { App: vm.currentApp.name });
      }
    }

    /**
     * Трек создания триггерного сообщения
     */
    function trackCreate(params) {
      let trackParams = getTrackSaveOrCreateParams(params);
      carrotquestHelper.track('Автосообщения - создал', trackParams);

      // NOTE Роман Е. При создании сообщения "SDK-типа", необходимо дополнительно отследить и трекнуть активацию
      if (trackParams.platform === RECIPIENT_TYPES.SDK && !vm.currentApp.activation.auto_msg_sdk) {
        vm.currentApp.activation.auto_msg_sdk = moment();
        carrotquestHelper.track('activation__created_sdk_automessage', trackParams.app_id);
      }

      trackUsePropsIntoEmailTitle(params);

      if (params.sendingFilters) {
        carrotquestHelper.track('Автосообщения - включено ограничение отправки на страницах', trackParams);
      }
    }

    /**
     * Трек перехода на шаг визарда
     *
     * @param {Number} step - Шаг визарда
     */
    function trackEnterOnStep(step) {
      let params = { Шаг: step };

      carrotquestHelper.track('Перешел на шаг настройки автосообщения', params);
    }

    /**
     * Трек клика на 'Архивировать'
     */
    function trackClickArchive() {
      carrotquestHelper.track($translate.instant('autoMessages.breadcrumbs.title') + ' - кликнул "Архивировать"', {
        App: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на 'Создать копию'
     */
    function trackClickCreateCopy() {
      carrotquestHelper.track($translate.instant('autoMessages.breadcrumbs.title') + ' - кликнул "Создать копию"');
    }

    /**
     * Трек сохранения триггерного сообщения
     */
    function trackSave(params) {
      let trackParams = getTrackSaveOrCreateParams(params);
      carrotquestHelper.track('Автосообщения - сохранил', trackParams);

      trackUsePropsIntoEmailTitle(params);

      if (params.sendingFilters) {
        carrotquestHelper.track('Автосообщения - включено ограничение отправки на страницах', trackParams);
      }
    }

    function trackUsePropsIntoEmailTitle(params) {
      const emailMessagePart = params.parts.find((part) => part.type === MESSAGE_PART_TYPES.EMAIL);
      const isEmailPartMessageUseInsertProps = emailMessagePart ? /\{\{.*\}\}/g.test(emailMessagePart.subject) : false;

      if (isEmailPartMessageUseInsertProps) {
        carrotquestHelper.track('Автосообщения - Вставил свойство в тему письма');
      }
    }

    /**
     * Трек захода на страницу создания/редактирования триггерного сообщения
     *
     * @param {Object} editableMessage Редактируемое сообщение
     */
    function trackEnterInEditor(editableMessage) {
      let trackedParams;

      if (editableMessage.name) {
        trackedParams = { 'Название сообщения': editableMessage.name };
      }

      carrotquestHelper.track('Автосообщения - зашел на сообщение', trackedParams);
    }

    /**
     * Трек клика на 'События для цепочек сообщений'
     */
    function trackChangeEventShow() {
      carrotquestHelper.track(
        $translate.instant(vm.messagePageType + '.breadcrumbs.title') + ' - клик на "События для цепочек сообщений"',
      );
    }

    /**
     * Нажатие на создание триггерного сообщения
     */
    function trackCreateMessageAndActivateLater() {
      carrotquestHelper.track('Триггерные сообщения - создал');
    }

    /** Переключение всех событий для цепочек сообщений в состояние по умолчанию */
    function toggleAllEventsForChainsInDefaultState() {
      vm.currentMessage.eventMessage1 = false;
      vm.currentMessage.eventMessage2 = false;
      vm.currentMessage.eventMessage3 = false;
      vm.currentMessage.eventMessage4 = false;
      vm.currentMessage.eventMessage5 = false;
    }

    /**
     * Обновление редактируемого триггерного сообщения
     */
    function updateAutoMessage() {
      Promise.all([vm.validateOnTriggerStep(vm.triggerForm)]).then(() => {
        submit(vm.currentMessage.active);
      });
    }

    /**
     * Отдельная функция валидации для второго шага
     * Нужна из-за того, что есть случаи, когда второй шаг не валиден, но пользователю всё равно надо дать перейти на другие шаги
     *
     * @param {form.FormController} form
     * @return {Promise}
     */
    function validateOnTriggerStep(form) {
      const isHasEmptyFilter = vm.currentMessage.sendingFilters.filters.find((value) => value.match === '');
      const isEnabledSendingFilters = vm.currentMessage.sendingFilters.type !== SENDING_FILTERS_GROUP_TYPES.NO;
      //Если не выбрал никакой триггер, добавить инпут для валидации
      if (vm.currentMessage.triggers.length === 0) {
        vm.addTrigger();
      }
      if (isEnabledSendingFilters && isHasEmptyFilter) {
        vm.isCollapsedDisplaySettings = true;
      }
      const areNewComponentsValid = Promise.all(
        Object.values(vm.triggerNewComponentValidators).map((touchAndValidate) => {
          return touchAndValidate();
        }),
      ).then(([...args]) => {
        return args.every((valid) => valid) ? Promise.resolve() : Promise.reject();
      });

      vm.formSubmitSource.next();

      const isTriggerValid = vm.validateTriggerFn().then((valid) => {
        return valid ? Promise.resolve() : Promise.reject();
      });

      return Promise.all([isTriggerValid, areNewComponentsValid]).catch(onError);

      function onError() {
        let promise = $q.reject;

        // HACK: отдельно валидируем (а по сути сабмитим, внутри вызывается $setSubmitted) форму удаления сообщения, чтобы показались сообщения об ошибках
        //  Если этот вызов отсюда убрать, то если выполнится условие ниже хотя бы 1 раз - ошибки показываться перестанут
        //  т.к. внутри формы есть скрытый input, у которого всегда $pristine === true и validationHelper вернёт результат, при котором ошибки не будут показываться
        //  поэтому приходится постоянно сабмитить форму
        isFormValid(vm.deleteMessageForm);

        // если сообщение редактируется, выбрано протухание в конкретную дату и пользователь не вносил изменений в настройки удаления сообщения - пропускаем его на следующий шаг
        if (
          vm.currentMessage.id &&
          vm.deleteMessage.deleteType === MESSAGE_DELETING_TYPES.CERTAIN_DATE &&
          vm.deleteMessageForm.$pristine
        ) {
          let allErrors = $filter('flatten')(form.$error);
          // вот тут хитрость: если среди ошибок родительской формы нет ошибок, кроме формы vm.deleteMessageForm (дочерней), то все остальные поля валидны, и можно переходить к следующему/предыдущему шагу
          let errorsOnlyInDeleteMessageForm = allErrors.every(angular.bind(null, angular.equals, vm.deleteMessageForm));

          // HACK: Если у родительской формы вызвался $setSubmitted, то он вызовется и у дочерних форм, выставляя флаг $submitted в true
          //  Когда флаг $submitted выставлен, то в соответствии с validationHelper начинают показываться ошибки. А нам не нужно их показывать, если чувак не менял настройки протухания,
          //  поэтому принудительно вызываем $setPristine, чтобы выставить $submitted в false ($setPristine делает это внутри себя)
          vm.deleteMessageForm.$setPristine();

          if (errorsOnlyInDeleteMessageForm) {
            promise = $q.resolve;
          }
        }

        return promise();
      }
    }
  }
})();

import { Pipe, PipeTransform } from '@angular/core';

import { UserSystemProperty } from '@http/property/property.constants';

@Pipe({
  name: 'userPropToSystem',
})
export class UserPropToSystemPipe implements PipeTransform {
  transform(
    value: Array<UserSystemProperty | '$carrotId' | '$userId' | string>,
  ): Array<UserSystemProperty | '$carrotId' | '$userId'> {
    return value as Array<UserSystemProperty | '$carrotId' | '$userId'>;
  }
}

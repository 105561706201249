(function () {
  'use strict';

  angular
    .module('myApp.modals.codeInstalledSuccess')
    .controller('CqCodeInstalledSuccessModalController', CqCodeInstalledSuccessModalController);

  function CqCodeInstalledSuccessModalController($state, PROJECT_NAME) {
    var vm = this;

    init();

    function init() {
      vm.goToDialogs = goToDialogs;
      vm.PROJECT_NAME = PROJECT_NAME;
    }

    function goToDialogs() {
      $state.go('app.content.conversations');
    }
  }
})();

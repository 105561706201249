/**
 * Директива для валидации равенства значений
 */
(function () {
  'use strict';

  angular.module('myApp.directives.equal').directive('cqEqual', cqEqual);

  function cqEqual() {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        cqEqual: '=',
      },
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$validators.equal = equal;

      /**
       * Проверка равенства значений модели и cqEqual
       * Проверка на равенство будет осуществляться только в том случае, если значение модели не пустое и значение cqEqual определено
       *
       * @param modelValue
       * @return {Boolean}
       */
      function equal(modelValue) {
        return controller.$isEmpty(modelValue) || angular.isUndefined(scope.cqEqual) || modelValue === scope.cqEqual;
      }
    }
  }
})();

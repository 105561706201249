<ng-container *transloco="let t; read: 'integrationChatBotsTriggerBlocksComponent'">
  <ng-container *ngIf="isShowedFromModal">
    <ngb-alert
      [dismissible]="false"
      type="secondary"
    >
      <div class="d-flex align-items-baseline">
        <i class="cqi-sm cqi-lightbulb mr-5"></i>
        <div>{{ t('alert') }}</div>
      </div>
    </ngb-alert>
  </ng-container>

  <ng-container *ngIf="!isShowedFromModal">
    <ng-container *ngIf="isEmptyChatBots">
      <a
        class="btn btn-secondary"
        data-test="go-to-chat-bot-page-a"
        target="_blank"
        uiSref="app.content.messagesAjs.chatBot.list"
      >
        <i class="cqi-sm cqi-external-link"></i>
        <span>{{ t('goToChatBotPage') }}</span>
      </a>
    </ng-container>

    <ng-container *ngIf="!isEmptyChatBots">
      <div class="mb-20 margin-between-rows-15">
        <div
          *ngFor="let chatBot of chatBots; let i = index"
          [attr.data-test]="'integration-chat-bot-div-' + i"
        >
          <div class="d-flex align-items-center">
            <i class="cqi-sm cqi-robot text-primary mr-5"></i>
            <a
              *ngIf="isLeadBot(chatBot)"
              class="font-weight-bold"
              [uiParams]="{ chatBotId: chatBot.message!.id! }"
              uiSref="app.content.messagesAjs.chatBot.edit"
              target="_blank"
              >{{ chatBot.name }}</a
            >

            <a
              *ngIf="isWelcomeBot(chatBot)"
              class="font-weight-bold"
              [uiParams]="{ routingBotId: chatBot.id }"
              uiSref="app.content.messagesAjs.routingBot.edit"
              target="_blank"
              >{{ 'routingBot.header.name' | transloco }}</a
            >

            <a
              *ngIf="isWidgetBot(chatBot)"
              class="font-weight-bold"
              [uiParams]="{ widgetBotId: chatBot.id }"
              uiSref="app.content.messagesAjs.widgetBot.edit"
              target="_blank"
              >{{ 'widgetBot.header.name' | transloco }}</a
            >
          </div>

          <div
            class="mt-10"
            *ngFor="let branch of getChatBotBranches(chatBot)"
          >
            <div>
              <span class="font-weight-bold">{{ t('block') }}</span>
              <span>«{{ branch.name }}»</span>
            </div>

            <div *ngIf="integrationType === INTEGRATION_TYPES.AMOCRM">
              <!-- Теги сделки -->
              <div
                *ngIf="isChatBotBranchAmocrmLeadTagsNotEmpty(branch)"
                data-test="integration-chat-bot-lead-tags-div"
              >
                <span class="font-weight-bold">{{
                  t('leadTags', { leadTags: getChatBotBranchAmocrmLeadTags(branch).length, integrationTypeName })
                }}</span>
                <cq-amocrm-lead-tags-readonly
                  class="d-inline-block"
                  [leadTags]="getChatBotBranchAmocrmLeadTags(branch)"
                ></cq-amocrm-lead-tags-readonly>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-secondary">
        <div class="d-flex align-items-baseline">
          <i class="cqi-sm cqi-lightbulb margin-right-5"></i>
          <div>
            <span>{{ t('note') }}</span>
            <a
              target="_blank"
              uiSref="app.content.messagesAjs.chatBot.list"
              >{{ t('goToChatBotPage') }}</a
            >
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

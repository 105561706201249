import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AiDataExternal } from '@http/ai-data/external-types';
import { AiDataMapper } from '@http/ai-data/mappers/ai-data.mapper';
import { CONVERSATION_PART_TYPES } from '@http/conversation-part/conversation-part.constants';
import { ApiGetTranscribeAudio, ConversationPart, InboundEmail } from '@http/conversation-part/conversation-part.types';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';
import { EmojiService } from '@panel/app/shared/services/emoji/emoji.service';

@Injectable({ providedIn: 'root' })
export class ConversationPartModel {
  constructor(
    private readonly http: HttpClient,
    private readonly emojiService: EmojiService,
    private readonly aiDataMapper: AiDataMapper,
  ) {}

  /**
   * Редактирование сообщения
   *
   * @param appId ID приложения
   * @param partId ID редактируемого сообщения
   * @param body Новый текст сообщения
   */
  edit(appId: string, partId: string, body: string): Observable<unknown> {
    const requestBody = {
      app: appId,
      body,
    };

    return this.http.put(`/conversationparts/${partId}`, requestBody);
  }

  /**
   * Получение информации о реплике, которую клиент отправил из почты
   *
   * @param inboundEmailId ID письма
   */
  getInboundEmail(inboundEmailId: string): Observable<InboundEmail> {
    return this.http.get<InboundEmail>(`/inboundemails/${inboundEmailId}`);
  }

  /**
   * Получение списка сообщений диалога
   *
   * @param conversationId ID диалога
   * @param conversationPartTypes Типы реплик, которые должны прийти в ответе. Если не задано - приходят все реплики
   * @param paginatorParams Параметры пагинации
   */
  getList(
    conversationId: string,
    conversationPartTypes: CONVERSATION_PART_TYPES[],
    paginatorParams: any = {},
  ): Observable<{ parts: ConversationPart[]; firstId: string; lastId: string }> {
    const params = {
      admin_request_part_types:
        conversationPartTypes && conversationPartTypes.length ? JSON.stringify(conversationPartTypes) : null,
      paginate_page_order: 'asc', // по умолчанию получать сообщения от старых к новым
      ...paginatorParams,
    };

    return this.http
      .get(`/conversations/${conversationId}/conversationparts`, {
        params,
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(EXTENDED_RESPONSE, true)
          .set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((response: any) => {
          response.data.forEach((conversationPart: any) => {
            this.parse(conversationPart);
          });

          return {
            parts: response.data,
            lastId: response.meta.next_after_position,
            firstId: response.meta.next_before_position,
          };
        }),
      );
  }

  /**
   * Контент, который использовался AI-ботом для ответа пользователю
   *
   * @param partId ID реплики с ответом бота
   */
  getAIContent(partId: string) {
    const params = {
      conversation_part: partId,
    };

    return this.http.get<AiDataExternal[]>('/integrations/chat_gpt/conversation_part_contents/', { params }).pipe(
      map((response) => {
        return response.map((data) => this.aiDataMapper.parseToInternal(data));
      }),
    );
  }

  /**
   * Парсинг реплики
   *
   * @param conversationPart Реплика
   */
  parse(conversationPart: ConversationPart): void {
    conversationPart.created = moment.isMoment(conversationPart.created)
      ? conversationPart.created
      : moment(conversationPart.created * 1000);
    conversationPart.body = this.emojiService.replaceEmojis(conversationPart.body);

    if (conversationPart.meta_data) {
      if (conversationPart.meta_data.answer_time) {
        conversationPart.meta_data.answer_time = moment.isMoment(conversationPart.meta_data.answer_time)
          ? conversationPart.meta_data.answer_time
          : moment(conversationPart.meta_data.answer_time * 1000);
      }

      if (conversationPart.type === CONVERSATION_PART_TYPES.DELAYED) {
        conversationPart.meta_data.delayed_until = moment.isMoment(conversationPart.meta_data.delayed_until)
          ? conversationPart.meta_data.delayed_until
          : moment(conversationPart.meta_data.delayed_until * 1000);
      }

      conversationPart.body_json = conversationPart.body_json === '' ? {} : conversationPart.body_json;
    }
  }

  /**
   * Удаление сообщения
   *
   * @param appId ID приложения
   * @param partId ID удаляемого сообщения
   */
  remove(appId: string, partId: string): Observable<unknown> {
    const body = {
      app: appId,
    };

    return this.http.delete(`/conversationparts/${partId}`, { body });
  }

  /**
   * Распознавание текста из аудиофайла
   *
   * @param conversationPartId ID реплики, содержащей аудиофайл
   */
  transcribeAudio(conversationPartId: string): Observable<string> {
    return this.http
      .get<ApiGetTranscribeAudio>(`/conversationparts/${conversationPartId}/voice_recognize/`, {
        params: { ignoreErrors: true },
      })
      .pipe(map((data) => data.text));
  }
}

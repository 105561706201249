import { Directive, inject } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

import { AbsBaseCVAComponent } from './abstract-base-cva-component';

@Directive()
export abstract class AbsCVAFormControlBasedComponent<T> extends AbsBaseCVAComponent {
  abstract readonly control: FormControl<T>;

  protected readonly ngControl = inject(NgControl, { optional: true, self: true });

  constructor() {
    super();
  }
}

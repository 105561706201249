import { ChangeDetectorRef, ElementRef, Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { IPointData } from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

import { PIXI_APP, PIXI_VIEWPORT, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { DestroyService } from '@panel/app/services';
import {
  CANVAS_SCALE_WORK_ZOOM_VALUE,
  CANVAS_ZOOM_SETTINGS,
} from '@panel/app/services/canvas/common/base/canvas-base.constants';
import { ZoomedEventData } from '@panel/app/shared/types/zoomed-event-data.type';

/**
 * Сервис для базовой работы с canvas
 */
@Injectable()
export class CanvasBaseService {
  /** Контейнер, в который будет инициализироваться canvas */
  container: ElementRef<HTMLElement> | null = null;

  /** Полноэкранный режим */
  isFullscreen: boolean = false;

  /** Значение zoom */
  zoomValue: number = CANVAS_ZOOM_SETTINGS.DEFAULT_VALUE;

  zoomValue$ = fromEvent<ZoomedEventData>(this.viewport, 'zoomed').pipe(
    takeUntil(this.destroy$),
    filter((event) => event.type === 'wheel'),
    map((event: ZoomedEventData) => Math.ceil(event.viewport.scale.x * 100)),
    distinctUntilChanged(),
    map((percent) => {
      if (percent <= CANVAS_ZOOM_SETTINGS.MIN_PERCENT) {
        return CANVAS_ZOOM_SETTINGS.MIN_PERCENT;
      } else if (percent >= CANVAS_ZOOM_SETTINGS.MAX_PERCENT) {
        return CANVAS_ZOOM_SETTINGS.MAX_PERCENT;
      } else {
        return percent;
      }
    }),
    tap((percent) => (this.zoomValue = percent)),
    startWith(CANVAS_ZOOM_SETTINGS.DEFAULT_VALUE),
  );

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly destroy$: DestroyService,
    @Inject(PIXI_APP)
    public readonly pixi: PixiApplication,
    @Inject(PIXI_VIEWPORT)
    public readonly viewport: Viewport,
    @Inject(WINDOW)
    private readonly window: Window,
  ) {
    this.pixi.stage.eventMode = 'static';
    this.pixi.stage.hitArea = this.pixi.screen;
  }

  clear(): void {
    this.pixi.renderer.clear();
  }

  /**
   * Монтирует canvas в container
   *
   * @param container - Контейнер, в который необходимо монтировать canvas
   */
  mount(container: ElementRef<HTMLElement>): void {
    this.container = container;
    // TODO: pixi.js update - поправить типы, чтоб тут не отдавалось непонятного типа view
    this.container.nativeElement.appendChild(this.pixi.renderer.view as HTMLCanvasElement);

    fromEvent(this.window, 'resize')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.resize();
      });
  }

  moveViewportTo({ x, y }: IPointData) {
    const xIndent = 200; // отступ от краев при перемещении
    const yIndent = 150; // отступ от краев при перемещении
    this.viewport.moveCorner(x - xIndent / this.viewport.scaled, y - yIndent / this.viewport.scaled);
  }

  /** Адаптирует размер canvas под изменившиеся размеры контейнера */
  resize(): void {
    if (!this.container) {
      throw new Error('Container is not initialized');
    }

    const containerW = this.container.nativeElement.clientWidth;
    const containerH = this.container.nativeElement.clientHeight;
    const canvas = this.pixi.renderer.view;

    canvas.width = containerW;
    canvas.height = containerH;

    this.pixi.renderer.resize(containerW, containerH);
    this.viewport.resize(containerW, containerH);
  }

  /** Уменьшает значение zoom */
  zoomDown(): void {
    let newZoomValue = this.zoomValue - CANVAS_ZOOM_SETTINGS.STEP_PERCENT;

    if (newZoomValue < CANVAS_ZOOM_SETTINGS.MIN_PERCENT) {
      newZoomValue = CANVAS_ZOOM_SETTINGS.MIN_PERCENT;
    }

    this.zoomSet(newZoomValue);
  }

  /**
   * Устанавливает значение zoom
   *
   * @param value - Значение, которое необходимо установить zoom
   * @param preventThrottle
   */
  zoomSet(value: number, preventThrottle: boolean = false): void {
    this.viewport.setZoom(value / 100);

    const zoomEvent: ZoomedEventData = {
      type: 'wheel',
      viewport: this.viewport,
      preventThrottle,
    };
    this.viewport.emit('zoomed', zoomEvent);

    this.zoomValue = value;
  }

  /** Увеличивает значение zoom */
  zoomUp(): void {
    let newZoomValue = this.zoomValue + CANVAS_ZOOM_SETTINGS.STEP_PERCENT;

    if (newZoomValue > CANVAS_ZOOM_SETTINGS.MAX_PERCENT) {
      newZoomValue = CANVAS_ZOOM_SETTINGS.MAX_PERCENT;
    }

    this.zoomSet(newZoomValue);
  }

  /** Переключает fullscreen режим */
  toggleFullscreen(): void {
    this.isFullscreen = !this.isFullscreen;

    this.resize();

    this.cdr.detectChanges();
  }

  get scaleValue() {
    let zoomValue;

    switch (true) {
      case this.zoomValue <= CANVAS_SCALE_WORK_ZOOM_VALUE.UPPER && this.zoomValue >= CANVAS_SCALE_WORK_ZOOM_VALUE.LOWER:
        zoomValue = this.zoomValue;
        break;
      case this.zoomValue <= CANVAS_SCALE_WORK_ZOOM_VALUE.LOWER:
        zoomValue = CANVAS_SCALE_WORK_ZOOM_VALUE.LOWER;
        break;
      case this.zoomValue >= CANVAS_SCALE_WORK_ZOOM_VALUE.UPPER:
        zoomValue = CANVAS_SCALE_WORK_ZOOM_VALUE.UPPER;
        break;
      default:
        zoomValue = this.zoomValue;
    }

    return 1 / (zoomValue / 100);
  }
}

<ng-container *transloco="let t">
  <div class="no-margin-bottom padding-top-10 padding-bottom-10">
    <div>
      <div
        [nxtSortablejs]="pinnedProps"
        [config]="sortablejsOptions"
      >
        <ng-container *ngFor="let pinnedProp of pinnedProps">
          <div class="drag">
            <ng-container *ngIf="isSystemProp(pinnedProp)">
              <cq-user-systems-props
                [systemProps]="[pinnedProp] | userPropToSystem"
                [user]="user"
                [pinnedProps]="pinnedProps"
                [pinningPossibility]="pinningPossibility"
                [disablePropertyBodyClass]="disablePropertyBodyClass"
                (unpinProp)="removePinnedProp.emit($event)"
              >
              </cq-user-systems-props>
            </ng-container>

            <div *ngIf="!isSystemProp(pinnedProp)">
              <cq-user-customs-props
                [currentApp]="currentApp"
                [customsProps]="[pinnedProp]"
                [user]="user"
                [pinnedProps]="pinnedProps"
                [pinningPossibility]="pinningPossibility"
                [disablePropertyBodyClass]="disablePropertyBodyClass"
                (openEditUserPropertyModal)="openEditUserPropertyModal($event)"
                (removeUserProperty)="removeUserProperty($event)"
                (unpinProp)="removePinnedProp.emit($event)"
              ></cq-user-customs-props>
            </div>
          </div>
        </ng-container>
      </div>

      <div
        class="property-body padding-left-15 padding-right-15"
        ngxClipboard
        (cbOnSuccess)="copiedSuccess()"
        [cbContent]="getUserTimeWithLocation()"
      >
        <div
          [ngbTooltip]="t('userLocationComponent.copyTooltip')"
          [disableTooltip]="!getUserTimeWithLocation()"
          placement="top-left"
          container="body"
        >
          <div class="property-title padding-top-5">{{ t('userLocationComponent.currentLocation.label') }}</div>
          <div
            *ngIf="userLocation"
            class="padding-bottom-5"
            [ngbTooltip]="t('userLocationComponent.currentLocation.tooltip')"
            container="body"
            data-test="user-location-current-location-div"
            placement="bottom"
          >
            <div class="inline-block margin-right-5">
              <i class="cqi-sm userLocation"></i>
              <span [innerText]="userLocation"></span>
            </div>
            <div
              *ngIf="userTime"
              class="inline-block"
              data-test="user-location-user-time-div"
            >
              <i class="cqi-sm cqi-clock"></i>
              <span [innerText]="userTime"></span>
            </div>
          </div>
          <div
            *ngIf="!userLocation"
            class="muted padding-bottom-5"
            data-test="user-location-undefined-location-span"
          >
            <span>{{ 'general.unknown' | transloco }}</span>
          </div>
        </div>
      </div>

      <div
        class="property-body padding-left-15 padding-right-15"
        ngxClipboard
        (cbOnSuccess)="copiedSuccess()"
        [cbContent]="user.presenceDetails.page || undefined"
      >
        <div
          [ngbTooltip]="t('userLocationComponent.copyTooltip')"
          placement="top-left"
          container="body"
        >
          <div class="padding-top-5 property-title">{{ t('userLocationComponent.currentPage.label') }}</div>
          <div
            *ngIf="user.presenceDetails.presence !== 'offline'"
            class="text-truncate padding-bottom-5"
            data-test="user-location-current-page-div"
          >
            <a
              [attr.href]="user.presenceDetails.url"
              [attr.title]="user.presenceDetails.url"
              [innerText]="user.presenceDetails.page || t('general.unknown')"
              target="_blank"
            ></a>
          </div>
          <div
            *ngIf="user.presenceDetails.presence === 'offline'"
            class="text-muted padding-bottom-5"
            data-test="user-location-undefined-page-div"
          >
            <span>{{ t('general.unknown') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

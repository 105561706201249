import { firstValueFrom } from 'rxjs';
import { BILLING_PLAN_IDS } from '../../../app/services/billing-info/billing-info.constants';
import { SETTINGS_TABS } from '../settings/settings.constants';

(function () {
  'use strict';

  angular.module('myApp.privacySettings').controller('CqPrivacySettingsController', CqPrivacySettingsController);

  function CqPrivacySettingsController(
    $filter,
    $q,
    $scope,
    $timeout,
    $translate,
    l10nHelper,
    toastr,
    appModel,
    carrotquestHelper,
    messageSenderModel,
    planVersionService,
    systemError,
  ) {
    var vm = this;

    var initialDoubleOptIn, initialMessengerShowAllowCookies, initialMessengerShowConfirmSubscription; // первоначальные значения настроек приватности, используются исключительно для трекинга событий

    vm.$onInit = init;

    function init() {
      initialDoubleOptIn = vm.settings.double_opt_in;
      initialMessengerShowAllowCookies = vm.settings.messenger_show_allow_cookies;
      initialMessengerShowConfirmSubscription = vm.settings.messenger_show_confirm_subscription;

      vm.apiAppAlreadyExists = false; // флаг ошибки существования сайта с таким именем HACK: передаётся в компонент cqDoubleOptInSettings
      vm.appName = vm.currentApp.name; // новый логотип
      vm.BILLING_PLAN_IDS = BILLING_PLAN_IDS;
      vm.changeShowChatDataCollectionSwitch = changeShowChatDataCollectionSwitch;
      vm.changeShowChatPrivacyPolicyLinkSwitch = changeShowChatPrivacyPolicyLinkSwitch;
      vm.doubleOptInMessageSender = messageSenderModel.getNoReply.call(messageSenderModel, vm.currentApp.language);
      vm.newLogo = null; // новый логотип
      vm.isChatDataCollectionSettingsShowed = false; // Состояние настроек обработки данных в чате (открыты/закрыты)
      vm.isCookieBannerSettingsShowed = false; // Состояние настроек бампера (открыты/закрыты)
      vm.isDoubleOptInSettingsShowed = false; // Состояние настроек Double Opt-In (открыты/закрыты)
      vm.isPopupDataCollectionSettingsShowed = false; // Состояние настроек чатботов и поп-апов (открыты/закрыты)
      vm.currentCountry = l10nHelper.isRusCountry() ? 'ru' : 'en';
      vm.planVersionService = planVersionService;
      vm.privacySettingsForm = null; // форма с настройками обработки ПД
      vm.savePrivacySettings = savePrivacySettings;
      vm.SETTINGS_TABS = SETTINGS_TABS;
      vm.setSettingsStatus = setSettingsStatus;
      vm.changeShowAllowCookiesSwitch = changeShowAllowCookiesSwitch;
      vm.changeDoubleOptInSwitch = changeDoubleOptInSwitch;
      vm.changeShowConfirmSubscriptionSwitch = changeShowConfirmSubscriptionSwitch;

      setSettingsStatus();
    }

    /**
     * Сохранение настроек обработки ПД
     *
     * @param isValid
     */
    function savePrivacySettings(isValid) {
      if (isValid) {
        var params = {
          messenger_allow_cookies_policy: vm.settings.messenger_allow_cookies_policy,
          messenger_show_privacy_policy: vm.settings.messenger_show_privacy_policy,
          data_processing_policy: vm.settings.data_processing_policy,
          messenger_show_allow_cookies: vm.settings.messenger_show_allow_cookies,
          messenger_show_confirm_subscription: vm.settings.messenger_show_confirm_subscription,
          messenger_confirm_subscription_policy: '',
        };

        if (!planVersionService.isTrial()) {
          params.double_opt_in = vm.settings.double_opt_in;
        }

        if (params.data_processing_policy) {
          params.data_processing_policy_url = vm.settings.data_processing_policy_url;
        }

        if (params.double_opt_in) {
          // FIXME HACK: костыль, т.к. не понятно как по-человечески работать с новым загружаемым файлом
          if (vm.newLogo instanceof File) {
            params.double_opt_in_logo = vm.newLogo;
            delete vm.newLogo;
          }
          params.name = vm.appName;
        }

        if (params.messenger_show_confirm_subscription) {
          params.terms_of_email_storage = vm.settings.terms_of_email_storage;
          params.terms_of_phone_storage = vm.settings.terms_of_phone_storage;
          params.messenger_confirm_subscription_with_checkbox =
            vm.settings.messenger_confirm_subscription_with_checkbox;
        }

        vm.apiAppAlreadyExists = false;

        firstValueFrom(appModel.saveSettings(vm.currentApp.id, params))
          .then(savePrivacySettingsSuccess)
          .catch(savePrivacySettingsError);
      }

      function savePrivacySettingsSuccess() {
        trackDoubleOptIn(params.double_opt_in, initialDoubleOptIn);
        trackShowAllowCookies(params.messenger_show_allow_cookies, initialMessengerShowAllowCookies);
        trackShowConfirmSubscription(
          params.messenger_show_confirm_subscription,
          initialMessengerShowConfirmSubscription,
        );

        initialDoubleOptIn = params.double_opt_in;
        initialMessengerShowAllowCookies = params.messenger_show_allow_cookies;
        initialMessengerShowConfirmSubscription = params.messenger_show_confirm_subscription;

        //Ссылка на политику конфиденциальности
        vm.currentApp.settings.messenger_allow_cookies_policy = params.messenger_allow_cookies_policy;

        vm.currentApp.settings.data_processing_policy = params.data_processing_policy;
        vm.currentApp.settings.double_opt_in = params.double_opt_in;
        vm.currentApp.settings.messenger_show_allow_cookies = params.messenger_show_allow_cookies;
        vm.currentApp.settings.messenger_show_confirm_subscription = params.messenger_show_confirm_subscription;

        if (params.data_processing_policy) {
          vm.currentApp.settings.data_processing_policy_url = params.data_processing_policy_url;
        }

        if (params.double_opt_in) {
          if (params.double_opt_in_logo) {
            vm.currentApp.settings.double_opt_in_logo = params.double_opt_in_logo;
          }

          vm.currentApp.name = params.name;
        }

        if (params.messenger_show_confirm_subscription) {
          vm.currentApp.settings.terms_of_email_storage = params.terms_of_email_storage;
          vm.currentApp.settings.terms_of_phone_storage = params.terms_of_phone_storage;
          vm.currentApp.settings.messenger_confirm_subscription_with_checkbox =
            params.messenger_confirm_subscription_with_checkbox;
        }

        toastr.success($translate.instant('privacySettings.toasts.settingsSaved'));
      }

      function savePrivacySettingsError(meta) {
        if (meta.error == 'AppAlreadyExistsError') {
          vm.apiAppAlreadyExists = true;
        } else {
          systemError.somethingWentWrongToast.show();
        }
      }
    }

    /**
     * Показ/скрытие опций (используется для удаления опций из DOM, чтобы валидация отключалась у закрытых блоков настроек)
     *
     * @return {Promise}
     */
    function setSettingsStatus() {
      var deferred = $q.defer();

      vm.isChatPrivacyPolicyLinkSettingsShowed = vm.settings.messenger_show_privacy_policy;
      vm.isChatDataCollectionSettingsShowed = vm.settings.data_processing_policy;
      vm.isCookieBannerSettingsShowed = vm.settings.messenger_show_allow_cookies;
      vm.isDoubleOptInSettingsShowed = vm.settings.double_opt_in;
      vm.isPopupDataCollectionSettingsShowed = vm.settings.messenger_show_confirm_subscription;

      // используется для того, чтобы uiRouter вставил DOM до того, как отработает uibCollapse, чтобы uibCollapse взял правильную высоту внутрненнего контента
      $timeout(function () {
        deferred.resolve();
      }, 0);

      return deferred.promise;
    }

    /**
     * Изменения значения свича контрольной группы
     * @param value
     */
    function changeShowAllowCookiesSwitch(value) {
      $scope.$apply(() => {
        vm.settings.messenger_show_allow_cookies = value;
      });
    }

    /**
     * Изменения значения свича контрольной группы
     * @param value
     */
    function changeDoubleOptInSwitch(value) {
      $scope.$apply(() => {
        vm.settings.double_opt_in = value;
      });
    }

    function changeShowChatDataCollectionSwitch(value) {
      $scope.$apply(() => {
        vm.settings.data_processing_policy = value;
      });
    }

    function changeShowChatPrivacyPolicyLinkSwitch(value) {
      $scope.$apply(() => {
        vm.settings.messenger_show_privacy_policy = value;
      });
    }

    /**
     * Изменения значения свича контрольной группы
     * @param value
     */
    function changeShowConfirmSubscriptionSwitch(value) {
      $scope.$apply(() => {
        vm.settings.messenger_show_confirm_subscription = value;
      });
    }

    /**
     * Трек изменения Double Opt-In
     *
     * @param {Boolean} newValue Новое значение
     * @param {Boolean} oldValue Старое значение
     */
    function trackDoubleOptIn(newValue, oldValue) {
      if (newValue != oldValue) {
        carrotquestHelper.track(
          newValue
            ? 'Настройки обработки персональных данных - Включил Double Opt-In'
            : 'Настройки обработки персональных данных - Выключил Double Opt-In',
          { App: vm.currentApp.name },
        );
      }
    }

    /**
     * Трек изменения показа бампера
     *
     * @param {Boolean} newValue Новое значение
     * @param {Boolean} oldValue Старое значение
     */
    function trackShowAllowCookies(newValue, oldValue) {
      if (newValue != oldValue) {
        carrotquestHelper.track(
          newValue
            ? 'Настройки обработки персональных данных - Включил бампер'
            : 'Настройки обработки персональных данных - Выключил бампер',
          { App: vm.currentApp.name },
        );
      }
    }

    /**
     * Трек изменения показа галочки в поп-апе
     *
     * @param {Boolean} newValue Новое значение
     * @param {Boolean} oldValue Старое значение
     */
    function trackShowConfirmSubscription(newValue, oldValue) {
      if (newValue != oldValue) {
        carrotquestHelper.track(
          newValue
            ? 'Настройки обработки персональных данных - Включил согласие в попапах'
            : 'Настройки обработки персональных данных - Выключил согласие в попапах',
          { App: vm.currentApp.name },
        );
      }
    }
  }
})();

/**
 * RexExp для вырезания CSS-класса big из атрибута style
 *
 * Нужен для решения бага редактора писем BEE:
 * https://favro.com/organization/6fcfbd57a2b44fae2a92e7ea/82b511342275b939d5555602?card=Car-28087
 */
export const BEE_EDITOR_BUGFIX_REGEXP: RegExp = /(<\w+?\s+?class\s*=\s*['\"][^'\"]*?\b)big\b/gim;

/**
 * Получение цвета для контрольной группы
 */
export const CONTROL_GROUP_COLOR_CLASS = 'text-control-group';

/**
 * Получение типов email из MESSAGE_PART_TYPES
 */
export enum EMAIL_TYPES {
  BEE = 'bee', // вручную созданный HTML email при помощи конструктора писем Bee
  DEFAULT = 'default', // базовый email каррота
  HTML = 'html', // вручную созданный HTML email
  AI = 'ai', // email созданный AI
}

/**
 * Максимальный вес письма (в байтах)
 */
export const HTML_MAX_SIZE = 500 * 1000;

/**
 * Рекомендуемый максимальный вес письма (в байтах)
 */
export const HTML_RECOMMENDED_MAX_SIZE = 90 * 1000;

/**
 * Получение цветов вариантов сообщения
 */
export const MESSAGE_PART_COLOR_CLASSES = ['text-message-part-1', 'text-message-part-2'];

/**
 * Получение типов отправителей варианта сообщения
 */
export enum MESSAGE_PART_SENDER_TYPES {
  MESSAGE_SENDER = 'messageSender', // отправитель
  TEAM_MEMBER = 'teamMember', // член команды
}

/**
 * Получение типов варианта сообщения
 */
export enum MESSAGE_PART_TYPES {
  ALL = 'all', // все (необходимо для получения всех сообщений без фильтрации типов вариантов этих сообщений) NOTE в автосообщениях используетя ui-select для вывода типов сообщения, а для него пустая строка это равнозначно тому что ни чего не выбрано. Поэтому пришлось изменить '' на 'all'
  BLOCK_POPUP_BIG = 'block_popup_big', // большой блочный поп-ап
  BLOCK_POPUP_SMALL = 'block_popup_small', // маленький блочный поп-ап
  CONTROL_GROUP = 'control_group', // контрольная группа
  EMAIL = 'email', // email
  JS = 'run_script', // JS-скрипт
  LEAD_BOT = 'lead_bot', // Чат-бот NOTE не используется на странице с автосообщениями, поэтому его нет в MESSAGE_PART_TYPES_ARRAY
  POPUP_BIG = 'popup_big', // большой поп-ап
  POPUP_CHAT = 'popup_chat', // чат
  POPUP_SMALL = 'popup_small', // маленький поп-ап
  PUSH = 'push', // web-push
  SDK_BLOCK_POPUP_SMALL = 'sdk_block_popup_small', // маленький блочный поп-ап в sdk
  SDK_POPUP_CHAT = 'sdk_popup_chat', // сообщение в sdk
  SDK_PUSH = 'sdk_push', // push-увдемление в sdk без сообщения в чат, ведущее в приложение или в какой-либо раздел (deep link)
  WEBHOOK = 'webhook', // webhook
  TELEGRAM = 'telegram', // Telegram
}

/**
 * Получение классов иконок для типов вариантов сообщения
 */
export const MESSAGE_PART_TYPE_ICON_CLASSES = {
  [MESSAGE_PART_TYPES.ALL]: '',
  [MESSAGE_PART_TYPES.BLOCK_POPUP_BIG]: 'cqi-block-popup-big',
  [MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL]: 'cqi-block-popup-small',
  [MESSAGE_PART_TYPES.CONTROL_GROUP]: '',
  [MESSAGE_PART_TYPES.EMAIL]: 'cqi-envelope-o',
  [MESSAGE_PART_TYPES.JS]: 'cqi-code',
  [MESSAGE_PART_TYPES.POPUP_BIG]: 'cqi-popup-big-o',
  [MESSAGE_PART_TYPES.POPUP_CHAT]: 'cqi-comment-out-о',
  [MESSAGE_PART_TYPES.POPUP_SMALL]: 'cqi-popup-small',
  [MESSAGE_PART_TYPES.PUSH]: 'cqi-web-push',
  [MESSAGE_PART_TYPES.WEBHOOK]: 'cqi-webhook',
  [MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL]: 'cqi-mobile-block-popup',
  [MESSAGE_PART_TYPES.SDK_POPUP_CHAT]: 'cqi-mobile-comment-out',
  [MESSAGE_PART_TYPES.SDK_PUSH]: 'cqi-mobile-push',
  [MESSAGE_PART_TYPES.TELEGRAM]: 'cqi-telegram',
};

/**
 * Получение массива типов варианта сообщения
 */
export const MESSAGE_PART_TYPES_ARRAY = [
  MESSAGE_PART_TYPES.ALL,
  MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
  MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
  MESSAGE_PART_TYPES.CONTROL_GROUP,
  MESSAGE_PART_TYPES.EMAIL,
  MESSAGE_PART_TYPES.JS,
  MESSAGE_PART_TYPES.POPUP_BIG,
  MESSAGE_PART_TYPES.POPUP_CHAT,
  MESSAGE_PART_TYPES.POPUP_SMALL,
  MESSAGE_PART_TYPES.PUSH,
  MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
  MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
  MESSAGE_PART_TYPES.SDK_PUSH,
  MESSAGE_PART_TYPES.WEBHOOK,
  MESSAGE_PART_TYPES.TELEGRAM,
];

/**
 * Возможные позиции фона в поп-апе
 *
 * NOTE: Не путать с POPUP_BLOCK_BACKGROUND_POSITION_TYPES, та константа используется в блоках поп-апа
 */
export enum POPUP_BACKGROUND_POSITION_TYPES {
  CONTAIN = 'contain', // во весь поп-ап без обрезания картинки (во весь поп-ап)
  COVER = 'cover', // во весь поп-ап с обрезанием картинки (заполнить)
  REPEAT = 'repeat', // повторять
  STRETCH = 'stretch', // растянуть
}

/**
 * Типы фонов в поп-апе
 *
 * NOTE: Не путать с POPUP_BLOCK_BACKGROUND_TYPES, та константа используется в блоках поп-апа
 */
export enum POPUP_BACKGROUND_TYPES {
  COLOR = 'color', // цвет
  IMAGE = 'image', // картинка
}

/**
 * Получение типов свернутого чата
 */
export enum POPUP_CHAT_TYPES {
  CLOSED = 'closed', // без ответа
  OPENED = 'opened', // текст
}

/**
 * Получение возможных композиций поп-апа
 */
export enum POPUP_COMPOSITION_TYPES {
  ONE_COLUMN = 'oneColumn', // одна колонка (базовая композиция)
  TWO_COLUMNS = 'twoColumns', // две колонки
}

/**
 * Получение возможных композиций поп-апа
 */
export const POPUP_COMPOSITIONS = {
  [POPUP_COMPOSITION_TYPES.ONE_COLUMN]: [[[]]],
  [POPUP_COMPOSITION_TYPES.TWO_COLUMNS]: [[[], []]],
};

/**
 * Получение типов ответов на сообщение
 */
export enum POPUP_REPLY_TYPES {
  NO = 'no', // без ответа
  TEXT = 'text', // текст
  EMAIL = 'email', // email
  PHONE = 'phone', // телефон
  PUSH = 'push', // push
  BUTTON = 'button', // кнопка
}

/**
 * Получение типов получателей сообщений
 */
export enum RECIPIENT_TYPES {
  SDK = 'sdk',
  WEB = 'web',
}

/**
 * Получение типов операционных систем для превью "Push в SDK"
 */
export enum TYPE_OS_PREVIEW {
  IOS = 'ios',
  ANDROID = 'android',
}

/**
 * Ключи для сохраненных данных в local/session storage
 */
export enum STORAGE_KEYS {
  STORAGE_BEE_EDITOR = 'storageBeeEditor', // Сохраненный шаблон би редактора
}

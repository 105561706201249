import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { RESIZE_OPTION_BOX, ResizeObserverService } from '@ng-web-apis/resize-observer';
import { interval, Observable, Subject, switchMap } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { UtilsModel } from '@http/utils/utils.model';
import {
  URL_FILTER_TYPE,
  UrlFilterConfiguratorOptions,
  UrlFilterFormGroup,
} from '@panel/app/partials/url-filter-configurator/url-filter-configurator.component';
import { DestroyService } from '@panel/app/services';
import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';
import { getAsyncUrlValidatorFn } from '@panel/app/shared/validators/async/async-validator';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

const URL_MIN_LENGTH = 3;

const fullyMatchedValidators: ValidatorFn[] = [Validators.minLength(URL_MIN_LENGTH), Validators.required];

const matchedPathWithParamsValidators: ValidatorFn[] = [
  Validators.minLength(URL_MIN_LENGTH),
  Validators.required,
  (control) => (control.value && control.value.search(/[\?#]/) !== -1 ? { invalidUrlWithParams: control.value } : null),
];

const containsValidators: ValidatorFn[] = [Validators.minLength(URL_MIN_LENGTH), Validators.required];

export const COMPONENT_IS_WIDE_WIDTH = 530; // Ширина, при которой компонент считается широким или узким, в зависимости от этого меняется верстка

export type UrlFilter = {
  type: URL_FILTER_TYPE;
  match: string;
};

let popoverHasBeenShown = false;

@Component({
  selector: 'cq-single-url-filter-configurator',
  templateUrl: './single-url-filter-configurator.component.html',
  styleUrls: ['./single-url-filter-configurator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    ResizeObserverService,
    {
      provide: RESIZE_OPTION_BOX,
      useValue: 'border-box',
    },
  ],
})
export class SingleUrlFilterConfiguratorComponent implements OnInit {
  @Input()
  index: number = 0;

  @Input()
  isOnlyFilter = false;

  @Input()
  options?: UrlFilterConfiguratorOptions;

  @Input()
  formGroup!: UrlFilterFormGroup;

  @Output()
  dropFilterClick = new Subject<void>();

  @ViewChild(NgbPopover)
  popover: NgbPopover | null = null;

  filterOptions: ReadonlyArray<URL_FILTER_TYPE> = [
    URL_FILTER_TYPE.FULLY_MATCHED,
    URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS,
    URL_FILTER_TYPE.CONTAINS,
  ];

  readonly URL_MIN_LENGTH = URL_MIN_LENGTH;

  // Подписываемся на изменения ширины компонента (по border-box, поменять можно в providers)
  readonly wide$: Observable<boolean> = this.entries$.pipe(
    takeUntil(this.destroy$),
    map((entries) => entries[0].contentRect.width),
    map((width) => {
      return width > COMPONENT_IS_WIDE_WIDTH;
    }),
  );

  constructor(
    private readonly utilsService: UtilsModel,
    private readonly fb: GenericFormBuilder,
    private readonly destroy$: DestroyService,
    @Inject(ResizeObserverService)
    private readonly entries$: ResizeObserverService,
    private readonly cqHelper: CarrotquestHelper,
  ) {}

  ngOnInit() {
    if (this.options?.enableOnlyOptions) {
      this.filterOptions = [...new Set(this.options.enableOnlyOptions)];
    }
    this.showOnboardingPopover();
  }

  showOnboardingPopover() {
    if (popoverHasBeenShown) {
      return;
    }

    this.formGroup.valueChanges
      .pipe(
        // Поповеры показываем только у форм с типом "полное совпадение url"
        filter(() => {
          return this.formGroup.controls.type.value === URL_FILTER_TYPE.FULLY_MATCHED;
        }),
        // Правильно было использовать statusChange, но с асинхронным валидатором выходит бага
        // https://github.com/angular/angular/issues/41519
        switchMap(() => {
          return interval(100).pipe(
            filter(() => {
              return this.formGroup.valid;
            }),
            take(1),
          );
        }),
        // Проверяем еще раз, что эта штука не изменилась, пока отрабатывала асинхронная валидация
        filter(() => {
          return this.formGroup.controls.type.value === URL_FILTER_TYPE.FULLY_MATCHED;
        }),
        // Больше одного раза нам точно не надо
        take(1),
      )
      // Показываем поповер
      .subscribe(() => {
        if (popoverHasBeenShown) {
          return;
        }
        if (!this.popover) {
          throw new Error('Popover not found (show)');
        }
        this.popover.open({ url: this.formGroup.controls.match.value });
        popoverHasBeenShown = true;

        // Если есть текст для трека события - отправляем
        if (this.options?.track) {
          this.cqHelper.track(this.options.track.eventName, this.options.track.eventParams);
        }
      });
  }

  static getValidatorsByFilterType(
    type: URL_FILTER_TYPE,
    utilsService: UtilsModel,
  ): { syncValidators: ValidatorFn[]; asyncValidators: AsyncValidatorFn[] } {
    let syncValidators: ValidatorFn[] = [];
    let asyncValidators: AsyncValidatorFn[] = [];

    switch (type) {
      case URL_FILTER_TYPE.CONTAINS:
        syncValidators = [...containsValidators];
        break;
      case URL_FILTER_TYPE.FULLY_MATCHED:
        syncValidators = [...fullyMatchedValidators];
        asyncValidators = [getAsyncUrlValidatorFn(utilsService)];
        break;
      case URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS:
        syncValidators = [...matchedPathWithParamsValidators];
        asyncValidators = [getAsyncUrlValidatorFn(utilsService)];
        break;
    }

    return { syncValidators, asyncValidators };
  }

  onFilterTypeChange(urlFilterType: URL_FILTER_TYPE, formControl: AbstractControl) {
    formControl.clearValidators();
    formControl.clearAsyncValidators();
    const { syncValidators, asyncValidators } = SingleUrlFilterConfiguratorComponent.getValidatorsByFilterType(
      urlFilterType,
      this.utilsService,
    );
    formControl.setValidators(syncValidators);
    formControl.setAsyncValidators(asyncValidators);
    formControl.updateValueAndValidity();
  }

  changeFilterTypeToMatchedWithParams() {
    this.formGroup.controls.type.setValue(URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS);
    this.onFilterTypeChange(URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS, this.formGroup.controls.match);
    this.closePopover();
  }

  closePopover() {
    if (!this.popover) {
      throw new Error('Popover not found (close)');
    }
    this.popover.close();
  }

  isMatchWithParams(type: URL_FILTER_TYPE): boolean {
    return type === URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS;
  }

  isFullOrPartialMatch(type: URL_FILTER_TYPE): boolean {
    return [URL_FILTER_TYPE.MATCHED_PATH_WITH_PARAMS, URL_FILTER_TYPE.FULLY_MATCHED].includes(type);
  }

  dropFilter() {
    this.dropFilterClick.next();
  }
}

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupHtmlBlockSettingsController', CqPopupHtmlBlockSettingsController);

  function CqPopupHtmlBlockSettingsController($scope, $translate, validationHelper) {
    var vm = this;

    /**
     * Настройки для редактора ace
     *
     * @type {Object}
     */
    var ACE_EDITOR_OPTIONS = {
      mode: 'html',
      theme: 'monokai',
      highlightActiveLine: true,
      showPrintMargin: false,
      showGutter: true,
      hScrollBarAlwaysVisible: false,
      vScrollBarAlwaysVisible: false,
    };

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.ACE_EDITOR_OPTIONS = ACE_EDITOR_OPTIONS;
      vm.htmlBlockSettingsForm = null; // форма настроек блока HTML
      vm.validationHelper = validationHelper;
      vm.applyChanges = () => $scope.$applyAsync();
    }

    function destroy() {
      // при уничтожении компонента нужно выставить значения необязательным полям, т.к. все поля должны быть заполнены
      if (!vm.popupBlockParams.html) {
        vm.popupBlockParams.html = $translate.instant('popupHtmlBlockSettings.defaultHtml');
      }
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Placement, PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { StateService } from '@uirouter/core';
import { EMPTY, Observable, throwError } from 'rxjs';

import { App } from '@http/app/app.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { OneTimeFlagService } from '@panel/app/services';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';

@Component({
  selector: 'cq-telegram-channel-popover-wrapper[currentApp][djangoUser]',
  templateUrl: './telegram-channel-popover-wrapper.component.html',
  styleUrls: ['./telegram-channel-popover-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramChannelPopoverWrapperComponent implements OnInit {
  @Input()
  currentApp!: App;

  @Input()
  djangoUser!: DjangoUser;

  /** Тип взят из ng-bootstrap */
  @Input()
  placement: string | Placement | PlacementArray = 'auto';

  @ViewChild(NgbPopover)
  popover!: NgbPopover;

  /** Является ли текущий djangoUser оператором */
  operator!: boolean;

  constructor(
    private readonly djangoUserModel: DjangoUserModel,
    private readonly oneTimeFlagService: OneTimeFlagService,
    private readonly $state: StateService,
  ) {}

  ngOnInit(): void {
    this.operator = this.djangoUserModel.isOperator(this.currentApp.id, this.djangoUser);
  }

  /**
   * Показ поповера
   */
  showPopover(): Observable<void> {
    if (this.oneTimeFlagService.isSet(ONE_TIME_FLAGS.TELEGRAM_CHANNEL_POPOVER)) {
      return throwError(() => new Error('Popover has already been shown'));
    }

    this.oneTimeFlagService.set(ONE_TIME_FLAGS.TELEGRAM_CHANNEL_POPOVER);
    this.popover.open();

    return EMPTY;
  }

  /** Перенаправляет пользователя в раздел "Настройки каналов" */
  goToConversationsSettingsPageFormTelegramPopover(): void {
    this.$state.go('app.content.conversationsSettings');
    this.popover.close();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BannerEarlyAccessComponent } from './banner-early-access.component';

@NgModule({
  declarations: [BannerEarlyAccessComponent],
  exports: [BannerEarlyAccessComponent],
  imports: [CommonModule],
})
export class BannerEarlyAccessModule {}

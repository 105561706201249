import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { StripeInputComponent } from '@panel/app/pages/subscription/general/stripe-input/stripe-input.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [StripeInputComponent],
  exports: [StripeInputComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, SharedModule],
})
export class StripeInputModule {}

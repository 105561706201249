import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[cqIntegerNew]',
})
export class IntegerDirective {
  private regex: RegExp = new RegExp(/^\d+$/);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Control',
    'v',
    'c',
    'x',
    'a',
  ];

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      (event.ctrlKey && ['v', 'c', 'x', 'a'].indexOf(event.key.toLowerCase()) !== -1)
    ) {
      return;
    }

    if (!this.regex.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (window as any)['clipboardData'];
    let pastedInput = clipboardData.getData('text');

    if (!/^\d+$/.test(pastedInput)) {
      event.preventDefault();
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment/moment';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { DatepickerOptions } from '@panel/app/shared/components/datetime-picker/types/datepicker-options.type';

@Component({
  selector: 'cq-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  styleUrls: ['./datepicker-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerInputComponent extends AbsCVAFormControlBasedComponent<moment.Moment> implements OnInit {
  @Input()
  initialDate: moment.Moment = moment();

  @Input()
  options: DatepickerOptions | null = null;

  @ViewChild(NgbPopover)
  ngbPopover: NgbPopover | null = null;

  readonly control: FormControl<moment.Moment> = this.fb.control<moment.Moment>(moment(), {
    nonNullable: true,
  });

  selectedDate: moment.Moment = moment();

  get controlValue(): moment.Moment {
    return this.control.getRawValue() ?? this.control.defaultValue;
  }

  get popover(): NgbPopover {
    if (this.ngbPopover === null) {
      throw new Error('Popover is null');
    }

    return this.ngbPopover;
  }

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.selectedDate = this.controlValue;
  }

  getDisplayDate(): string {
    let date = this.controlValue;

    if (this.options && this.options.format) {
      return date.format(this.options.format);
    }

    return date.format('L');
  }

  onBlurInput({ target }: Event): void {
    const input = target as HTMLInputElement;
    const momentDate = moment(input.value, moment.localeData().longDateFormat('L'), true);

    if (!this.popover.isOpen()) {
      if (momentDate.isValid()) {
        this.selectedDate = momentDate;
        this.control.setValue(this.selectedDate);
      } else {
        this.control.setValue(moment());
      }
    }
  }

  onClickApplyButton(): void {
    if (this.options && this.options.showTimepicker) {
      this.control.setValue(this.selectedDate);
    } else {
      this.control.setValue(this.selectedDate.startOf('day'));
    }

    this.popover.close();
  }

  onClickCancelButton(): void {
    this.popover.close();
  }

  onSelectDate(): void {
    if (this.options && this.options.showApplyButtons) {
      return;
    }

    if (this.options && this.options.showTimepicker) {
      this.control.setValue(this.selectedDate);
    } else {
      this.control.setValue(this.selectedDate.startOf('day'));
    }

    this.popover.close();
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TeamMember } from '@http/team-member/team-member.types';

@Component({
  selector: 'cq-conversation-operator[teamMember]',
  templateUrl: './conversation-operator.component.html',
  styleUrls: ['./conversation-operator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationOperatorComponent {
  @Input()
  showInternalName: boolean = true;

  @Input()
  teamMember!: TeamMember;
}

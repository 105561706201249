import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { StateServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { TelegramChannelPopoverWrapperComponent } from '@panel/app/pages/conversations/conversations-channel-list/telegram-channel-popover-wrapper/telegram-channel-popover-wrapper.component';

@NgModule({
  declarations: [TelegramChannelPopoverWrapperComponent],
  imports: [CommonModule, NgbPopoverModule, TranslocoModule],
  exports: [TelegramChannelPopoverWrapperComponent],
  providers: [StateServiceProvider],
})
export class TelegramChannelPopoverWrapperModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../../../../../shared/shared.module';
import { BranchNameEditorComponent } from './branch-name-editor.component';

@NgModule({
  declarations: [BranchNameEditorComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, NgbTooltipModule, ReactiveFormsModule],
  exports: [BranchNameEditorComponent],
})
export class BranchNameEditorModule {}

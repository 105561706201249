import { AbstractControl, ValidationErrors } from '@angular/forms';

export function googleIdValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value === null || control.value === '') {
    return null;
  }

  const valid = /^(UA-).+/i.test(control.value) || /^(G-).+/i.test(control.value);

  return valid ? null : { googleId: true };
}

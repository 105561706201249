import { environment } from '@environment';

/**
 * URL аватарки бота по умолчанию
 */
export const BOT_SENDER_DEFAULT_AVATAR_URL = environment.userFilesUrl + '/avatars/default-chat-bot-avatar.png';

/**
 * URL аватарки отправителя по умолчанию
 */
export const MESSAGE_SENDER_DEFAULT_AVATAR_URL = environment.userFilesUrl + '/avatars/default-v3.png';

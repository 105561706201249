import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { ChannelMuteModule } from '@panel/app/partials/channel-mute/channel-mute.module';

import { ChannelComponent } from './channel.component';

@NgModule({
  declarations: [ChannelComponent],
  exports: [ChannelComponent],
  imports: [CommonModule, TranslocoModule, NgbTooltipModule, ChannelMuteModule, ChannelIconModule],
})
export class ChannelModule {}

import { Container } from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { merge, Observable, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { generate } from 'short-uuid';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ConnectionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { BranchViewMap, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { BlockType, Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { Connection, IPoint } from '@panel/app/pages/chat-bot/content/views/connection';
import { CanvasElement, Style } from '@panel/app/pages/chat-bot/content/views/element.abs';
import { ActionValidationExtra, BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { ActionFormFactory } from '@panel/app/pages/chat-bot/forms/actions/form-factory/action-form.factory';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

import { BotAction } from '../interfaces';

export type ActionStyle = {
  padding: {
    vertical: number;
    horizontal: number;
  };
  border: {
    size: number;
    color: number;
    radius: number;
  };
  background: {
    color: number;
  };
};

export const MAX_ACTION_WIDTH = 250;

export type BotActionConstructorParams = AbstractConstructorParameters<typeof BaseActionABS>;

/**
 * Основной абстрактный класс который подходит для всех actions.
 * Реализует все основные необходимые интерфейсы.
 */
export abstract class BaseActionABS<BodyJson extends object> implements BotAction, CanvasElement {
  blockType: BlockType = 'branch';
  connection?: Connection;
  container: Container;
  destroy$ = new Subject<void>();
  destroyed = false;
  form: BaseBotActionForm<BodyJson>;
  id?: string;
  readonly initialAPIData: Readonly<ChatBotAction>;
  /**
   * @deprecated
   * использовалось для логики перемещения экшенов, сейчас экшены перегруппированы
   */
  isReplyAction!: boolean;
  linkId!: string;
  order: number;
  protected style!: Style;
  type: CHAT_BOT_ACTIONS_TYPES;
  currentBranchLinkId: string;

  constructor(
    action: ChatBotAction,
    protected readonly botScenariosHelper: BotScenariosHelper,
    protected readonly connectionFactory: ConnectionFactory,
    protected readonly fileHelperService: FileHelperService,
    protected readonly canvasBranches: BranchViewMap,
    protected readonly externalPositionChange: Observable<void>,
    protected readonly validationExtra: ActionValidationExtra,
    protected readonly pixiInteractionService: PixiInteractionService,
    protected readonly viewport: Viewport,
    protected readonly pixiApp: PixiApplication,
  ) {
    /**
     * Assign branch data to properties, create Actions
     */
    this.id = action.id;
    // Каст типов не совсем правильно, но пока не получилось решить вопрос по чистому.
    this.form = ActionFormFactory.create(action, validationExtra) as BaseBotActionForm<BodyJson>;
    this.linkId = action.linkId ?? generate();
    this.order = action.order;
    this.type = action.type;
    this.initialAPIData = action;
    this.currentBranchLinkId = action.currentBranchLinkId;
    /**
     * Canvas
     */
    this.style = this.getStyle();
    this.container = this.createContainer();
    this.container.name = `Action ${action.type} ${action.linkId}`;

    this.initSubscriptions();
  }

  get apiReadyData(): ChatBotAction {
    return {
      id: this.id,
      linkId: this.linkId,
      active: this.active.value,
      isReplyAction: this.isReplyAction,
      type: this.type,
      body: this.body.value,
      bodyJson: this.apiReadyBodyJson,
      keyName: this.keyName.value,
      currentBranchLinkId: this.currentBranchLinkId,
      prettyKeyName: this.form.prettyKeyName,
      integrationName: this.form.integrationName,
      nextBranchLinkId: this.nextBranchLinkId.value,
      nextBranchId: this.form.nextBranchId,
      attachments: [...this.attachments.value],
      order: this.order,
      isInvalid: this.form.invalid,
    };
  }

  abstract get apiReadyBodyJson(): BodyJson;

  /**
   * Создаем контейнер, в котором будет содержаться весь контент элемента.
   */
  protected createContainer(): Container {
    const container = new Container();
    container.eventMode = 'static';
    container.sortableChildren = true;
    container.zIndex = 1;
    return container;
  }

  get coordinates(): IPoint {
    return this.container.position;
  }

  destroy(): void {
    this.destroyed = true;
    this.form.destroy();
    this.container.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  get currentBranch(): Branch {
    const branch = this.canvasBranches.get(this.currentBranchLinkId);
    if (!branch) {
      throw new Error('Could not find current branch');
    }
    return branch;
  }

  protected initSubscriptions() {
    this.subToValueChanges();
  }

  protected abstract getStyle(): Style;

  makeCopy(): BotAction {
    const callConstructor = (...args: AbstractConstructorParameters<typeof BaseActionABS>): BotAction => {
      return new (this.constructor as any)(...args);
    };
    return callConstructor(
      this.makeJSONCopy(),
      this.botScenariosHelper,
      this.connectionFactory,
      this.fileHelperService,
      this.canvasBranches,
      this.externalPositionChange,
      this.validationExtra,
      this.pixiInteractionService,
      this.viewport,
      this.pixiApp,
    );
  }

  makeJSONCopy(): ChatBotAction {
    return {
      linkId: generate(),
      active: this.active.value,
      isReplyAction: this.isReplyAction,
      type: this.type,
      body: this.body.value,
      bodyJson: this.bodyJson.value,
      keyName: this.keyName.value,
      currentBranchLinkId: this.currentBranchLinkId,
      prettyKeyName: this.form.prettyKeyName,
      nextBranchId: null,
      nextBranchLinkId: null,
      attachments: [...this.attachments.value],
      order: this.order,
      isInvalid: this.form.invalid,
    };
  }

  abstract redraw(): void;

  abstract render(): void;

  abstract get renderReady(): boolean;

  protected subToValueChanges() {
    merge(
      this.active.valueChanges,
      this.body.valueChanges,
      this.attachments.valueChanges,
      this.keyName.valueChanges,
      this.nextBranchLinkId.valueChanges,
    )
      .pipe(throttleTime(500, undefined, { trailing: true }), takeUntil(this.destroy$))
      .subscribe(() => {
        this.redraw();
      });
  }

  abstract get height(): number;
  abstract get width(): number;

  /**
   * Геттеры для формы для более удобного обращения к ним
   */
  get active() {
    return this.form.controls.active;
  }

  get body() {
    return this.form.controls.body;
  }

  get bodyJson(): GenericFormGroup<BodyJson> {
    return this.form.controls.bodyJson as GenericFormGroup<BodyJson>;
  }

  get attachments() {
    return this.form.controls.attachments;
  }

  get nextBranchLinkId() {
    return this.form.controls.nextBranchLinkId;
  }

  get keyName() {
    return this.form.controls.keyName;
  }
}

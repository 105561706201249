(function () {
  'use strict';

  angular
    .module('myApp.modals.downgradeAbilitiesWrapper')
    .component('cqDowngradeAbilitiesWrapperModal', downgradeAbilitiesWrapperModal());

  function downgradeAbilitiesWrapperModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // modalWindowParams
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./downgrade-abilities-wrapper.html'),
    };
  }
})();

{
  /**
   * Компонент редактора шаблона
   */
  (function () {
    'use strict';

    require('./ready-message-template-editor.controller');

    angular
      .module('myApp.readyMessageTemplates')
      .component('cqReadyMessageTemplateEditor', cqReadyMessageTemplateEditor());

    function cqReadyMessageTemplateEditor() {
      return {
        bindings: {
          billingInfo: '=', // информация о биллинге
          currentApp: '=', // Текущее приложение
          directories: '=', // Список папок
          djangoUser: '=', // Текущий пользователь
          messagePart: '=', // Вариант сообщения, созданный на основе шаблона
          properties: '=', // Список свойств и типов событий
          /*NOTE надо получапть и шаблон и вариант сообщения т.к. в компоненте используется name, active и id из шаблона*/
          template: '=', // Шаблон сообщения
        },
        controller: 'CqReadyMessageTemplateEditorController',
        controllerAs: 'vm',
        template: require('./ready-message-template-editor.html'),
      };
    }
  })();
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { VideoViewModalService } from '@panel/app/services/video-view/video-view-modal.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Компонент шага стартергайда про разогрев лидов
 */
@Component({
  selector: 'cq-starter-guide-warm-up[isStepMade]',
  templateUrl: './starter-guide-warm-up.component.html',
  styleUrls: ['./starter-guide-warm-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarterGuideWarmUpComponent {
  /** Выполненность шага */
  @Input()
  isStepMade: boolean = false;

  /** Текущий язык */
  activeLang!: string;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly translocoService: TranslocoService,
    private readonly videoViewModalService: VideoViewModalService,
  ) {
    this.activeLang = translocoService.getActiveLang();
  }

  /** Обработчик клика перехода по ссылке на список триггерных сообщений */
  onClickTriggerMessagesLink(): void {
    this.trackGoToTriggerMessagesList();
  }

  /**
   * Открытие модального окна для просмотра видео
   */
  openViewVideoModal(): void {
    this.videoViewModalService
      .open({
        heading: this.translocoService.translate('starterGuideWarmUpComponent.modal.heading'),
        videoUrl: this.translocoService.translate('starterGuideWarmUpComponent.modal.src'),
      })
      .result.then(null, () => {
        this.trackVideoViewed();
      });
  }

  /** Трек перехода на список триггерных сообщений */
  trackGoToTriggerMessagesList(): void {
    const eventName = 'Стартергайд - перешел в триггерные сообщения';
    this.carrotquestHelper.track(eventName);
  }

  /**
   * Трек просмотра видео
   */
  trackVideoViewed() {
    this.carrotquestHelper.track('Стартергайд - видео про триггерную рассылку просмотрено');
  }
}

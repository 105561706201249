import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';

import { EventTypeModel } from '@http/event-type/event-type.model';
import { AiDataBotSettingsStateService } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings-state.service';
import { ModalHelperService } from '@panel/app/services';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';
import { EventType } from '@http/property/property.model';

@Component({
  selector: 'cq-user-event-control',
  templateUrl: './user-event-control.component.html',
  styleUrls: ['./user-event-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEventControlComponent extends AbsCVAFormControlBasedComponent<string | null> {
  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
  });

  constructor(
    private readonly eventTypeModel: EventTypeModel,
    private readonly modalHelperService: ModalHelperService,
    public readonly state: AiDataBotSettingsStateService,
    private readonly translocoService: TranslocoService,
  ) {
    super();
  }

  /**
   * Добавление нового события
   *
   * @param eventTypeName Название события
   */
  addEventType(eventTypeName: EventType['name']): void | boolean {
    if (!eventTypeName) {
      return false;
    }

    const newEventType: EventType = this.eventTypeModel.getDefault(eventTypeName);

    // при добавлении нового события надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
    const existingEventTypeByName = this.state.properties$
      .getValue()
      .eventTypes.filter((eventType: EventType) => eventType.name === newEventType.name)[0];
    const existingEventTypeByPrettyName = this.state.properties$
      .getValue()
      .eventTypes.filter((eventType: EventType) => eventType.prettyName === newEventType.prettyName)[0];

    if (!existingEventTypeByName && !existingEventTypeByPrettyName) {
      this.state.properties$.next({
        ...this.state.properties$.getValue(),
        eventTypes: [...this.state.properties$.getValue().eventTypes, newEventType],
      });
    }
    this.control.setValue(newEventType.name);
  }

  /**
   * Открытие модалки создания нового типа события
   */
  openAddEventModal(): void {
    const createNewEventModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('userEventControlComponent.addEventModal.heading'),
        body: this.translocoService.translate('userEventControlComponent.addEventModal.body'),
        inputPlaceholder: this.translocoService.translate('userEventControlComponent.addEventModal.inputPlaceholder'),
        inputErrorText: this.translocoService.translate('userEventControlComponent.addEventModal.inputErrorText'),
        confirmButtonText: this.translocoService.translate('userEventControlComponent.addEventModal.confirmButtonText'),
      })
      .open(PromptModalComponent);

    createNewEventModal.result.then((newEventTypeName: string) => this.addEventType(newEventTypeName));
  }
}

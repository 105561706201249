import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '@environment';
import { PLANS_PERIODS, PRICING_DEFAULT_PAYMENT_PERIOD } from '@panel/app/services/billing-plan/billing-plan.constants';

/** Хранилище данных для прайсинга */
@Injectable()
export class PricingStore {
  /** Период оплаты */
  readonly paymentPeriod: BehaviorSubject<PLANS_PERIODS> = new BehaviorSubject<PLANS_PERIODS>(
    PRICING_DEFAULT_PAYMENT_PERIOD[environment.country],
  );
}

(function () {
  'use strict';

  require('./opencart.controller');

  angular.module('myApp.integrations').component('cqOpencartModal', cqOpencartModal());

  function cqOpencartModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // apiCredentials - ключи API
        // integration - текущая интеграция
      },
      controller: 'CqOpencartModalController',
      controllerAs: 'vm',
      template: require('./opencart.html'),
    };
  }
})();

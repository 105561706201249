(function () {
  'use strict';

  angular.module('myApp.integrations').controller('EcwidController', EcwidController);

  function EcwidController(
    $state,
    $translate,
    ipCookie,
    toastr,
    PROJECT_NAME,
    integrationModel,
    validationHelper,
    currentApp,
    integration,
  ) {
    let vm = this;

    vm.$translate = $translate;
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.integration = integration;
    vm.isManualExpanded = angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.updateStatus = updateStatus;
    vm.updateStatusButtonEnabled = true;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      vm.integration.settings.code = ipCookie('ecwid_code');
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function createOrUpdateIntegration(isValid) {
      if (isValid) {
        if (integration.id) {
          integrationModel.save(currentApp.id, integration).then(saveSuccess);
        } else {
          ipCookie.remove('ecwid_code', { path: '/' });
          console.log('ecwid_code cookie', ipCookie('ecwid_code'));
          vm.integration.settings.status = 'pending';

          integrationModel.create(currentApp.id, integration).then(createSuccess);
        }
      }

      function createSuccess(integration) {
        let params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        let transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }

    /**
     * Получить актуальное состояние интеграции
     *
     */
    function updateStatus() {
      vm.updateStatusButtonEnabled = false;
      integrationModel.get(integration.id, integration.type).then(updateSuccess);

      function updateSuccess(integration) {
        vm.integration = integration;
        vm.updateStatusButtonEnabled = true;
      }
    }
  }
})();

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { MESSAGE_TYPES } from '@http/message/message.constants';
import { MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import {
  ProductFeatureAccessConfig,
  ProductFeatures,
} from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { PAID_PLAN_VERSION } from '@panel/app/services/billing-plan/billing-plan.constants';

/** Продуктовые фичи */
export enum PLAN_FEATURE {
  /** AI-бот */
  AI_BOT = 'ai_bot',
  /** Аналитика диалогов */
  ANALYTICS_CONVERSATIONS = 'analytics_conversations',
  /** Аналитика диалогов по каналам */
  ANALYTICS_CONVERSATIONS_CHANNELS = 'analytics_conversations_channels',
  /** Аналитика диалогов по операторам */
  ANALYTICS_CONVERSATIONS_OPERATORS = 'analytics_conversations_operators',
  /** Аналитика диалогов по тегам */
  ANALYTICS_CONVERSATIONS_TAGS = 'analytics_conversations_tags',
  /** Аналитика диалогов по источникам */
  ANALYTICS_CONVERSATIONS_SOURCE = 'analytics_conversations_source',
  /** Аналитика диалогов по качеству поддержки */
  ANALYTICS_CONVERSATIONS_VOTE = 'analytics_conversations_vote',
  /** Группы членов команды */
  APPS_ADMIN_GROUPS = 'apps_admin_groups',
  /** AB тесты для триггерных сообщений */
  AUTO_MESSAGES_AB_TESTING = 'auto_messages_ab_testing',
  /** Триггерные сообщения типа "Конструктор поп-апов" */
  AUTO_MESSAGES_BLOCK_POPUP = 'auto_messages_block_popup',
  /** Триггерные сообщения типа "Чат" */
  AUTO_MESSAGES_CHAT = 'auto_messages_chat',
  /** Контрольные группы для триггерных сообщений */
  AUTO_MESSAGES_CONTROL_GROUP = 'auto_messages_control_group',
  /** Триггерные сообщения типа "Email" */
  AUTO_MESSAGES_EMAIL = 'auto_messages_email',
  /** События для цепочек сообщений */
  AUTO_MESSAGES_EVENTS_FOR_CHAINS = 'auto_messages_events_for_chains',
  /** Массовая активация триггерных сообщений */
  AUTO_MESSAGES_MASS_ACTIVATION = 'auto_messages_mass_activation',
  /** Страница триггерных сообщений */
  AUTO_MESSAGES_PAGE = 'auto_messages_page',
  /** Триггерные сообщения типа "Поп-ап" */
  AUTO_MESSAGES_POPUP = 'auto_messages_popup',
  /** Триггерные сообщения типа "Web push" */
  AUTO_MESSAGES_PUSH = 'auto_messages_push',
  /** Триггерные сообщения типа "JS" */
  AUTO_MESSAGES_RUN_SCRIPT = 'auto_messages_run_script',
  /** Триггерные сообщения типа "SDK" */
  AUTO_MESSAGES_SDK_RECIPIENT = 'auto_messages_sdk_recipient',
  /** Максимальное количество триггерных сообщений */
  AUTO_MESSAGES_TOTAL = 'auto_messages_total',
  /** Триггерные сообщения типа "Webhook" */
  AUTO_MESSAGES_WEBHOOK = 'auto_messages_webhook',
  /** Триггерные сообщения типа "Telegram" */
  AUTO_MESSAGES_TELEGRAM = 'auto_messages_telegram',
  /** Конструктор писем */
  BEE_EDITOR = 'bee_editor',
  /** Сообщения типа "Telegram" в цепочках */
  CHAIN_MESSAGES_TELEGRAM = 'chain_messages_telegram',
  /** Массовая активация чат-ботов */
  CHAT_BOTS_MASS_ACTIVATION = 'chat_bots_mass_activation',
  /** Страница чат-ботов */
  CHAT_BOTS_PAGE = 'chat_bots_page',
  /** Автоназначение диалогов */
  CONVERSATIONS_AUTO_ASSIGNMENT = 'conversations_auto_assignment',
  /** Доступность создания интеграции WA. Сделано для LTD2023 тарифов, чтоб они писали для подключения интеграции */
  INTEGRATIONS_WHATSAPP = 'integrations_whatsapp',
  /** Dashboard */
  DASHBOARD = 'dashboard',
  /** Разработчикам */
  DEVELOPERS = 'developers',
  /** Downgrade TODO */
  DOWNGRADE = 'downgrade',
  /** Кастомные события пользователей */
  EVENTS_EVENT_TYPES_CUSTOM = 'events_event_types_custom',
  /** Аналитика воронок */
  EVENTS_FUNNELS = 'events_funnels',
  /** Facebook-боты */
  FACEBOOK_BOTS = 'facebook_bots',
  /** Аналитические интеграции */
  INTEGRATIONS_ANALYTICS = 'integrations_analytics',
  /** CRM интеграции */
  INTEGRATIONS_CRM = 'integrations_crm',
  /** JS интеграции */
  INTEGRATIONS_JSSCRIPT = 'integrations_jsscript',
  /** Интеграция "Уведомление о лиде на email" */
  INTEGRATIONS_EMAIL_NOTIFICATION = 'integrations_email_notification',
  /** Интеграции (обарабыватеся в PLAN_FEATURES, т.к. у данной фичи не учитывается лимит) */
  INTEGRATIONS = 'integrations',
  /** Webhook интеграции */
  INTEGRATIONS_WEBHOOK = 'integrations_webhook',
  /** База знаний */
  KNOWLEDGE_BASE = 'knowledge_base',
  /** База знаний PRO */
  KNOWLEDGE_BASE_PRO = 'knowledge_base_pro',
  /** Lead-боты */
  LEAD_BOTS = 'lead_bots',
  /** Лиды */
  LEADS = 'leads',
  /** Пользователи онлайн */
  LIVE = 'live',
  /** Индивидуальный почтовый домен */
  MAIL_DOMAIN = 'mail_domain',
  /** Ручные сообщения типа "Конструктор поп-апов" */
  MANUAL_MESSAGES_BLOCK_POPUP = 'manual_messages_block_popup',
  /** Возможность множественной отправки ручных сообщений */
  MANUAL_MESSAGES_BULK_SEND = 'manual_messages_bulk_send',
  /** Ручные сообщения типа "Чат" */
  MANUAL_MESSAGES_CHAT = 'manual_messages_chat',
  /** Отложенная отправка ручных сообщений */
  MANUAL_MESSAGES_DELAYED_DELIVERY = 'manual_messages_delayed_delivery',
  /** Ручные сообщения типа "Email" */
  MANUAL_MESSAGES_EMAIL = 'manual_messages_email',
  /** Ручные сообщения типа "Поп-ап" */
  MANUAL_MESSAGES_POPUP = 'manual_messages_popup',
  /** Ручные сообщения типа "Web Push" */
  MANUAL_MESSAGES_PUSH = 'manual_messages_push',
  /** Ручные сообщения в "SDK" */
  MANUAL_MESSAGES_SDK_RECIPIENT = 'manual_messages_sdk_recipient',
  /** Статистика по ручным рассылкам */
  MANUAL_MESSAGES_STATS = 'manual_messages_stats',
  /** Триггерные сообщения типа "Telegram" */
  MANUAL_MESSAGES_TELEGRAM = 'manual_messages_telegram',
  /** Персональная настройка и сопровождение */
  MARKETING = 'marketing',
  /** Ручное создание каналов */
  MESSAGES_CHANNELS = 'messages_channels',
  /** Автоматическое перемещение диалогов в каналы */
  MESSAGES_CHANNELS_AUTO_SET = 'messages_channels_auto_set',
  /** Шаблоны триггерных сообщений */
  MESSAGES_TEMPLATES = 'messages_templates',
  /** Без брендинга + индивидуальный почтовый домен */
  NO_BRANDING = 'no_branding',
  /** Готовые сценарии */
  READY_AUTO_MESSAGES = 'ready_auto_messages',
  /** Отчёты */
  REPORTS = 'reports',
  /** Безопасность */
  SECURITY = 'security',
  /** Члены команды */
  TEAM_MEMBERS = 'team_members',
  /** Телеграм боты */
  TELEGRAM_BOTS = 'telegram_bots',
  /** Мастер сбора данных */
  TRACK_MASTER_JS_CODE = 'track_master_js_code',
  /** Снятие назначения с диалога */
  UNASSIGN_CONVERSATIONS = 'unassign_conversations',
  /** Кастомные свойства пользователей */
  USERS_CUSTOM_PROPERTIES = 'users_custom_properties',
  /** События пользователей */
  USERS_EVENTS = 'users_events',
  /** Экспорт пользователей */
  USERS_EXPORT = 'users_export',
  /** Свойства пользователя */
  USERS_PROPS = 'users_props',
  /** Теги пользователей */
  USERS_TAGS = 'users_tags',
  /** Welcome-боты */
  WELCOME_BOTS = 'welcome_bots',
  /** Расшифровка аудиосообщений */
  TRANSCRIBE_AUDIO = 'transcribe_audio',
}

/**
 * Специальные продуктовые фичи
 *
 * NOTE:
 *  Фичи, доступ к которым можно предоставить не только через проверку соответствующих addons или capabilities,
 *  но и через проверку дополнительных и исторических логических условий.
 *  Например: Доступ до welcome-бота на самых старых тарифах можно определить по наличию addon CHAT_BOTS,
 *  на средних по наличию addon CHAT_BOTS и доступного лимита по capability CHAT_BOTS_TOTAL,
 *  а самых новых по наличию addon WELCOME_BOTS_UNITS_PRICING.
 *
 */
export const SPECIAL_PLAN_FEATURES = [
  PLAN_FEATURE.AI_BOT,
  PLAN_FEATURE.AUTO_MESSAGES_MASS_ACTIVATION,
  PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
  PLAN_FEATURE.AUTO_MESSAGES_PAGE,
  PLAN_FEATURE.CHAT_BOTS_MASS_ACTIVATION,
  PLAN_FEATURE.CHAT_BOTS_PAGE,
  PLAN_FEATURE.FACEBOOK_BOTS,
  PLAN_FEATURE.KNOWLEDGE_BASE,
  PLAN_FEATURE.KNOWLEDGE_BASE_PRO,
  PLAN_FEATURE.LEAD_BOTS,
  PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND,
  PLAN_FEATURE.MESSAGES_CHANNELS,
  PLAN_FEATURE.NO_BRANDING,
  PLAN_FEATURE.TELEGRAM_BOTS,
  PLAN_FEATURE.WELCOME_BOTS,
] as const;

/**
 * Простые продуктовые фичи
 *
 * NOTE:
 *  Фичи, доступ к которым можно предоставить однозначно, проверив соответствующие addons или capabilities.
 *  Например: Доступ до конструктора писем можно определить по наличию addon BEE_EDITOR или по capability BEE_EDITOR.
 *
 *  Как заполняем:
 *  для тарифов v1 заполняем только аддоны
 *  для тарифов v2 либо капабилити, либо аддон
 *  для тарифов v3 либо капабилити, либо аддон
 */
export const PLAN_FEATURES: Record<ProductFeatures, Record<PAID_PLAN_VERSION, ProductFeatureAccessConfig>> = {
  [PLAN_FEATURE.ANALYTICS_CONVERSATIONS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.LTD_OPTIMIZATION_SUPPORT],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS],
    },
  },
  [PLAN_FEATURE.ANALYTICS_CONVERSATIONS_CHANNELS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_CHANNELS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.LTD_OPTIMIZATION_SUPPORT],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_CHANNELS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_CHANNELS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_CHANNELS],
    },
  },
  [PLAN_FEATURE.ANALYTICS_CONVERSATIONS_OPERATORS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_OPERATORS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.LTD_OPTIMIZATION_SUPPORT],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_OPERATORS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_OPERATORS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_OPERATORS],
    },
  },
  [PLAN_FEATURE.ANALYTICS_CONVERSATIONS_TAGS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_TAGS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.LTD_OPTIMIZATION_SUPPORT],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_TAGS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_TAGS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_TAGS],
    },
  },
  [PLAN_FEATURE.ANALYTICS_CONVERSATIONS_SOURCE]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_SOURCE],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.LTD_OPTIMIZATION_SUPPORT],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_SOURCE],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_SOURCE],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_SOURCE],
    },
  },
  [PLAN_FEATURE.ANALYTICS_CONVERSATIONS_VOTE]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_VOTE],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.LTD_OPTIMIZATION_SUPPORT],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_VOTE],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_VOTE],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.ANALYTICS_CONVERSATIONS_VOTE],
    },
  },
  [PLAN_FEATURE.APPS_ADMIN_GROUPS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.APPS_ADMIN_GROUPS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.APPS_ADMIN_GROUPS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.APPS_ADMIN_GROUPS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.APPS_ADMIN_GROUPS],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_AB_TESTING]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_AB_TESTING],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_AB_TESTING],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_AB_TESTING],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_AB_TESTING],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_BLOCK_POPUP]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_BLOCK_POPUP],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_BLOCK_POPUP],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_BLOCK_POPUP],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_BLOCK_POPUP],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_CHAT]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_CHAT],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_CHAT],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_CHAT],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_CHAT],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_CONTROL_GROUP]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_CONTROL_GROUP],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_CONTROL_GROUP],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_CONTROL_GROUP],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_CONTROL_GROUP],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_EMAIL]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_EMAIL],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_EMAIL],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_EMAIL],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_EVENTS_FOR_CHAINS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_EVENTS_FOR_CHAINS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_EVENTS_FOR_CHAINS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_EVENTS_FOR_CHAINS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_EVENTS_FOR_CHAINS],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_POPUP]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_POPUP],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_POPUP],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_POPUP],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_POPUP],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_PUSH]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_PUSH],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_PUSH],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_PUSH],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_PUSH],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_RUN_SCRIPT]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_RUN_SCRIPT],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_RUN_SCRIPT],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_RUN_SCRIPT],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_RUN_SCRIPT],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_SDK_RECIPIENT]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_SDK_RECIPIENT],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_SDK_RECIPIENT],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_SDK_RECIPIENT],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_SDK_RECIPIENT],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_WEBHOOK]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_WEBHOOK],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_WEBHOOK],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_WEBHOOK],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_WEBHOOK],
    },
  },
  [PLAN_FEATURE.AUTO_MESSAGES_TELEGRAM]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_TELEGRAM],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_TELEGRAM],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM_FOR_OLD_PLANS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_TELEGRAM],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.AUTO_MESSAGES_TELEGRAM],
    },
  },
  [PLAN_FEATURE.CHAIN_MESSAGES_TELEGRAM]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM_FOR_OLD_PLANS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM],
      capabilities: [],
    },
  },
  [PLAN_FEATURE.BEE_EDITOR]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.BEE_EDITOR],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.BEE_EDITOR_V2, BILLING_ADD_ONS.BEE_EDITOR],
      capabilities: [PLAN_CAPABILITIES.BEE_EDITOR],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.BEE_EDITOR],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [BILLING_ADD_ONS.BEE_EDITOR],
      capabilities: [PLAN_CAPABILITIES.BEE_EDITOR],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [BILLING_ADD_ONS.BEE_EDITOR_V2, BILLING_ADD_ONS.BEE_EDITOR],
      capabilities: [PLAN_CAPABILITIES.BEE_EDITOR],
    },
  },
  [PLAN_FEATURE.CONVERSATIONS_AUTO_ASSIGNMENT]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.CONVERSATIONS_AUTO_ASSIGNMENT],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.LTD_OPTIMIZATION_SUPPORT],
      capabilities: [PLAN_CAPABILITIES.CONVERSATIONS_AUTO_ASSIGNMENT],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.CONVERSATIONS_AUTO_ASSIGNMENT],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.CONVERSATIONS_AUTO_ASSIGNMENT],
    },
  },
  [PLAN_FEATURE.DASHBOARD]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DASHBOARD],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DASHBOARD],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DASHBOARD],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DASHBOARD],
    },
  },
  [PLAN_FEATURE.DEVELOPERS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DEVELOPERS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DEVELOPERS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DEVELOPERS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DEVELOPERS],
    },
  },
  [PLAN_FEATURE.DOWNGRADE]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DOWNGRADE],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.DOWNGRADE],
    },
  },
  [PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.EVENTS_EVENT_TYPES_CUSTOM],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.EVENTS_EVENT_TYPES_CUSTOM],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.EVENTS_EVENT_TYPES_CUSTOM],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.EVENTS_EVENT_TYPES_CUSTOM],
    },
  },
  [PLAN_FEATURE.EVENTS_FUNNELS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.EVENTS_FUNNELS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.EVENTS_FUNNELS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.EVENTS_FUNNELS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.EVENTS_FUNNELS],
    },
  },
  [PLAN_FEATURE.INTEGRATIONS_ANALYTICS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_ANALYTICS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_ANALYTICS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_ANALYTICS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_ANALYTICS],
    },
  },
  [PLAN_FEATURE.INTEGRATIONS_CRM]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_CRM],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_CRM],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_CRM],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_CRM],
    },
  },
  [PLAN_FEATURE.INTEGRATIONS_JSSCRIPT]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_JSSCRIPT],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_JSSCRIPT],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_JSSCRIPT],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_JSSCRIPT],
    },
  },
  [PLAN_FEATURE.INTEGRATIONS_EMAIL_NOTIFICATION]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_EMAIL_NOTIFICATION],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_EMAIL_NOTIFICATION],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_EMAIL_NOTIFICATION],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_EMAIL_NOTIFICATION],
    },
  },
  [PLAN_FEATURE.INTEGRATIONS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_TOTAL],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_TOTAL],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_TOTAL],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_TOTAL],
    },
  },
  [PLAN_FEATURE.INTEGRATIONS_WEBHOOK]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_WEBHOOK],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_WEBHOOK],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_WEBHOOK],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_WEBHOOK],
    },
  },
  [PLAN_FEATURE.LEADS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.LEADS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.LEADS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.LEADS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.LEADS],
    },
  },
  [PLAN_FEATURE.LIVE]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.LIVE],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.LIVE],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.LIVE],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.LIVE],
    },
  },
  [PLAN_FEATURE.MAIL_DOMAIN]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.MAIL_DOMAIN],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_BLOCK_POPUP]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_BLOCK_POPUP],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_BLOCK_POPUP],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_BLOCK_POPUP],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_BLOCK_POPUP],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_CHAT]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_CHAT],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_CHAT],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_CHAT],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_CHAT],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_DELAYED_DELIVERY]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_DELAYED_DELIVERY],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_DELAYED_DELIVERY],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_DELAYED_DELIVERY],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_DELAYED_DELIVERY],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_EMAIL]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_EMAIL],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_EMAIL],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_EMAIL],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_EMAIL],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_POPUP]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_POPUP],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_POPUP],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_POPUP],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_POPUP],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_PUSH]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_PUSH],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_PUSH],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_PUSH],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_PUSH],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_SDK_RECIPIENT]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_SDK_RECIPIENT],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_SDK_RECIPIENT],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_SDK_RECIPIENT],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_SDK_RECIPIENT],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_TELEGRAM]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_TELEGRAM],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_TELEGRAM],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM_FOR_OLD_PLANS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_TELEGRAM],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_TELEGRAM],
    },
  },
  [PLAN_FEATURE.MANUAL_MESSAGES_STATS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_STATS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_STATS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_STATS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MANUAL_MESSAGES_STATS],
    },
  },
  [PLAN_FEATURE.MARKETING]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.MARKETING],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [],
    },
  },
  [PLAN_FEATURE.MESSAGES_CHANNELS_AUTO_SET]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MESSAGES_CHANNELS_AUTO_SET],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MESSAGES_CHANNELS_AUTO_SET],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MESSAGES_CHANNELS_AUTO_SET],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.MESSAGES_CHANNELS_AUTO_SET],
    },
  },
  [PLAN_FEATURE.MESSAGES_TEMPLATES]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MESSAGES_TEMPLATES],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MESSAGES_TEMPLATES],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MESSAGES_TEMPLATES],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.MESSAGES_TEMPLATES],
    },
  },
  [PLAN_FEATURE.READY_AUTO_MESSAGES]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.READY_AUTO_MESSAGES],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.READY_AUTO_MESSAGES],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.READY_AUTO_MESSAGES],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.READY_AUTO_MESSAGES],
    },
  },
  [PLAN_FEATURE.REPORTS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.REPORTS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.REPORTS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.REPORTS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.REPORTS],
    },
  },
  [PLAN_FEATURE.SECURITY]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [BILLING_ADD_ONS.SECURITY],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.SECURITY],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.SECURITY],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [BILLING_ADD_ONS.SECURITY],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [BILLING_ADD_ONS.SECURITY],
      capabilities: [],
    },
  },
  [PLAN_FEATURE.TEAM_MEMBERS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TEAM_MEMBERS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TEAM_MEMBERS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TEAM_MEMBERS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TEAM_MEMBERS],
    },
  },
  [PLAN_FEATURE.TRACK_MASTER_JS_CODE]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TRACK_MASTER_JS_CODE],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TRACK_MASTER_JS_CODE],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TRACK_MASTER_JS_CODE],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TRACK_MASTER_JS_CODE],
    },
  },
  [PLAN_FEATURE.UNASSIGN_CONVERSATIONS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.UNASSIGN_CONVERSATIONS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [BILLING_ADD_ONS.LTD_OPTIMIZATION_SUPPORT],
      capabilities: [PLAN_CAPABILITIES.UNASSIGN_CONVERSATIONS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.ANALYTICS_CONVERSATIONS],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.UNASSIGN_CONVERSATIONS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.UNASSIGN_CONVERSATIONS],
    },
  },
  [PLAN_FEATURE.USERS_CUSTOM_PROPERTIES]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_CUSTOM_PROPERTIES],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_CUSTOM_PROPERTIES],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_CUSTOM_PROPERTIES],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_CUSTOM_PROPERTIES],
    },
  },
  [PLAN_FEATURE.USERS_EVENTS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_EVENTS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_EVENTS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_EVENTS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_EVENTS],
    },
  },
  [PLAN_FEATURE.USERS_EXPORT]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_EXPORT],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_EXPORT],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_EXPORT],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_EXPORT],
    },
  },
  [PLAN_FEATURE.USERS_PROPS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_PROPS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_PROPS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_PROPS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_PROPS],
    },
  },
  [PLAN_FEATURE.USERS_TAGS]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_TAGS],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_TAGS],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_TAGS],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.USERS_TAGS],
    },
  },
  [PLAN_FEATURE.INTEGRATIONS_WHATSAPP]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_WHATSAPP],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_WHATSAPP],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_WHATSAPP],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.INTEGRATIONS_WHATSAPP],
    },
  },
  [PLAN_FEATURE.TRANSCRIBE_AUDIO]: {
    [PAID_PLAN_VERSION.FREEMIUM]: {
      addOns: [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING],
      capabilities: [PLAN_CAPABILITIES.TRANSCRIBE_AUDIO],
    },
    [PAID_PLAN_VERSION.LTD]: {
      addOns: [],
      capabilities: [PLAN_CAPABILITIES.TRANSCRIBE_AUDIO],
    },
    [PAID_PLAN_VERSION.V1]: {
      addOns: [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING],
      capabilities: [],
    },
    [PAID_PLAN_VERSION.V2]: {
      addOns: [BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING],
      capabilities: [PLAN_CAPABILITIES.TRANSCRIBE_AUDIO],
    },
    [PAID_PLAN_VERSION.V3]: {
      addOns: [
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
        BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
      ],
      capabilities: [PLAN_CAPABILITIES.TRANSCRIBE_AUDIO],
    },
  },
};

/**
 * Получение названия продуктовой фичи по типу действия чат-бота
 */
export const PLAN_FEATURE_BY_CHAT_BOT_ACTION_TYPE: Partial<Record<CHAT_BOT_ACTIONS_TYPES, PLAN_FEATURE>> = {
  [CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION]: PLAN_FEATURE.INTEGRATIONS_CRM,
  [CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION]: PLAN_FEATURE.INTEGRATIONS_EMAIL_NOTIFICATION,
  [CHAT_BOT_ACTIONS_TYPES.USER_TAG]: PLAN_FEATURE.USERS_TAGS,
};

/**
 * Названия фичи тарифа по типу сообщения
 */
export const PLAN_FEATURE_BY_MESSAGE_PART_TYPE = {
  [MESSAGE_TYPES.AUTO]: {
    [MESSAGE_PART_TYPES.BLOCK_POPUP_BIG]: PLAN_FEATURE.AUTO_MESSAGES_BLOCK_POPUP,
    [MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL]: PLAN_FEATURE.AUTO_MESSAGES_BLOCK_POPUP,
    [MESSAGE_PART_TYPES.EMAIL]: PLAN_FEATURE.AUTO_MESSAGES_EMAIL,
    [MESSAGE_PART_TYPES.JS]: PLAN_FEATURE.AUTO_MESSAGES_RUN_SCRIPT,
    [MESSAGE_PART_TYPES.POPUP_BIG]: PLAN_FEATURE.AUTO_MESSAGES_POPUP,
    [MESSAGE_PART_TYPES.POPUP_CHAT]: PLAN_FEATURE.AUTO_MESSAGES_CHAT,
    [MESSAGE_PART_TYPES.POPUP_SMALL]: PLAN_FEATURE.AUTO_MESSAGES_POPUP,
    [MESSAGE_PART_TYPES.PUSH]: PLAN_FEATURE.AUTO_MESSAGES_PUSH,
    [MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL]: PLAN_FEATURE.AUTO_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.SDK_POPUP_CHAT]: PLAN_FEATURE.AUTO_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.SDK_PUSH]: PLAN_FEATURE.AUTO_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.WEBHOOK]: PLAN_FEATURE.AUTO_MESSAGES_WEBHOOK,
    [MESSAGE_PART_TYPES.TELEGRAM]: PLAN_FEATURE.AUTO_MESSAGES_TELEGRAM,
  },
  [MESSAGE_TYPES.MANUAL]: {
    [MESSAGE_PART_TYPES.BLOCK_POPUP_BIG]: PLAN_FEATURE.MANUAL_MESSAGES_BLOCK_POPUP,
    [MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL]: PLAN_FEATURE.MANUAL_MESSAGES_BLOCK_POPUP,
    [MESSAGE_PART_TYPES.EMAIL]: PLAN_FEATURE.MANUAL_MESSAGES_EMAIL,
    [MESSAGE_PART_TYPES.POPUP_BIG]: PLAN_FEATURE.MANUAL_MESSAGES_POPUP,
    [MESSAGE_PART_TYPES.POPUP_CHAT]: PLAN_FEATURE.MANUAL_MESSAGES_CHAT,
    [MESSAGE_PART_TYPES.POPUP_SMALL]: PLAN_FEATURE.MANUAL_MESSAGES_POPUP,
    [MESSAGE_PART_TYPES.PUSH]: PLAN_FEATURE.MANUAL_MESSAGES_PUSH,
    [MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL]: PLAN_FEATURE.MANUAL_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.SDK_POPUP_CHAT]: PLAN_FEATURE.MANUAL_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.SDK_PUSH]: PLAN_FEATURE.MANUAL_MESSAGES_SDK_RECIPIENT,
    [MESSAGE_PART_TYPES.TELEGRAM]: PLAN_FEATURE.MANUAL_MESSAGES_TELEGRAM,
  },
};

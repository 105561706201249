import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { MESSAGE_STATUSES } from '@http/message/message.constants';
import { SYSTEM_DIRECTORIES } from '@http/message-directory/message-directory.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-auto-message-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusFilterComponent {
  @Input()
  currentDirectory: any;

  @Input()
  currentStatus: any;

  @Output()
  changeStatus: EventEmitter<any> = new EventEmitter<any>();

  protected MESSAGE_STATUSES = MESSAGE_STATUSES;
  protected MESSAGE_STATUSES_ARRAY = Array.from(Object.values(MESSAGE_STATUSES));
  protected SYSTEM_DIRECTORIES = SYSTEM_DIRECTORIES;

  constructor(private readonly carrotquestHelper: CarrotquestHelper) {}

  onClick(status: any): void {
    this.trackClickOnCurrentStatus();

    this.changeStatus.next(status);
  }

  trackByFn(status: any) {
    return status;
  }

  /**
   * Трек клика на фильтрацию по статусу
   */
  trackClickOnCurrentStatus(): any {
    this.carrotquestHelper.track('Автосообщения - клик на фильтр');
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { App } from '@http/app/app.model';
import { MessageTelegramButton } from '@http/message-part/message-part.types';
import { EventType } from '@http/property/property.model';
import { MessageEditorTriggerState } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-state.service';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';
import { noWhitespaceValidator } from '@panel/app/shared/validators/no-whitespace/no-whitespace-validator';
import { URL_REGEXP } from '@panel/app/shared/validators/url/url';

@Component({
  selector: 'cq-button-block-editor',
  templateUrl: './button-block-editor.component.html',
  styleUrls: ['./button-block-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonBlockEditorComponent
  extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<MessageTelegramButton>>
  implements OnInit
{
  @Input()
  index: number = 0;

  @Input()
  lastIndex: number = 0;

  @Input()
  isDuplicateDisabled: boolean = true;

  @Input()
  eventTypes: EventType[] = [];

  @Input()
  currentApp!: App;

  href: string = '';

  setEventType = false;

  buttonActionTypes = ['none', 'url'];

  BUTTON_TEXT_MAX_LENGTH = 50;

  @Output()
  readonly moveUp = new EventEmitter<void>();

  @Output()
  readonly moveDown = new EventEmitter<void>();

  @Output()
  readonly duplicate = new EventEmitter<void>();

  @Output()
  readonly delete = new EventEmitter<void>();

  readonly control: FormGroup<ToFormGroupControls<MessageTelegramButton>> = this.fb.group<
    ToFormGroupControls<MessageTelegramButton>
  >({
    actionType: this.fb.control<'none' | 'url'>('none', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    text: this.fb.control<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(this.BUTTON_TEXT_MAX_LENGTH)],
    }),
    url: this.fb.control<string | null>({ value: null, disabled: true }, [
      Validators.pattern(URL_REGEXP),
      noWhitespaceValidator,
      Validators.required,
    ]),
    eventToWrite: this.fb.control<string | null>(null),
  });

  constructor(
    private readonly fb: FormBuilder,
    protected readonly messageEditorTriggerState: MessageEditorTriggerState,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setEventType = !!this.control.value.eventToWrite;

    if (this.control.controls.actionType.value === 'url') {
      this.control.controls.url.enable();
    }
  }

  actionTypeChanged(buttonActionTypes: 'none' | 'url') {
    if (buttonActionTypes === 'url') {
      this.control.controls.url.enable();
    } else {
      this.control.controls.url.setValue(null, { emitEvent: false });
      this.control.controls.url.disable();
    }
  }

  setEventTypeChanged(value: boolean) {
    !value && this.control.controls.eventToWrite.setValue(null);
  }
}

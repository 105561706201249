import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmModalComponent } from './confirm-modal.component';

@NgModule({
  declarations: [ConfirmModalComponent],
  exports: [ConfirmModalComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [NgbActiveModal],
})
export class ConfirmModalModule {}

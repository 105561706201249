import cloneDeep from 'lodash-es/cloneDeep';
import { generate } from 'short-uuid';
import { Branch } from '../../../../../app/pages/chat-bot/content/views/blocks/base-block/branch';
import { CHAT_BOT_URL_PATHS } from '../../chat-bot-routes.constants';
import { firstValueFrom, forkJoin } from 'rxjs';
import { MESSAGE_STATUSES } from '../../../../../app/http/message/message.constants';
import { CHAT_BOT_TYPE } from '../../../../../app/http/chat-bot/types/chat-bot-external.types';
import { CHAT_BOT_ACTIONS_TYPES } from '../../../../../app/http/chat-bot/chat-bot.constants';
import { ChatBotModel } from '../../../../../app/http/chat-bot/chat-bot.model';
import { FEATURES } from '../../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.chatBot.routingBot').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.messagesAjs.routingBot', {
        url: CHAT_BOT_URL_PATHS.WELCOME_BOT,
        redirectTo: 'app.content.messagesAjs.routingBot.create',
        template: '<div ui-view></div>',
      })

      .state('app.content.messagesAjs.routingBot.create', {
        url: '/new',
        resolve: {
          channels: getChannels,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          templates: getTemplates,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
        },
        bindings: {
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          templates: 'templates',
          tags: 'tags',
          teamMembers: 'teamMembers',
        },
        component: 'cqRoutingBotEdit',
      })

      .state('app.content.messagesAjs.routingBot.createFromLeadBot', {
        url: '/new',
        resolve: {
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          calendlyEventList: getCalendlyEventList,
          calendlyIntegrationList: getCalendlyIntegrationList,
          channels: getChannels,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          isCopyFromLeadBot: () => true,
          properties: getProperties,
          templates: getTemplates,
          routingBot: getLeadBotCopyForCreate,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
        },
        bindings: {
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          isCopyFromLeadBot: 'isCopyFromLeadBot',
          properties: 'properties',
          templates: 'templates',
          routingBot: 'routingBot',
          tags: 'tags',
          teamMembers: 'teamMembers',
        },
        params: {
          chatBotMessageId: null,
        },
        component: 'cqRoutingBotEdit',
      })

      .state('app.content.messagesAjs.routingBot.createFromTemplate', {
        url: '/template/{templateId:id}',
        resolve: {
          activeChatBotsAmounts: getActiveChatBotsAmounts,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          calendlyEventList: getCalendlyEventList,
          calendlyIntegrationList: getCalendlyIntegrationList,
          channels: getChannels,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          properties: getProperties,
          templates: getTemplates,
          routingBot: getBotTemplateForCreate,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
        },
        bindings: {
          activeChatBotsAmounts: 'activeChatBotsAmounts',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          properties: 'properties',
          templates: 'templates',
          routingBot: 'routingBot',
          tags: 'tags',
          teamMembers: 'teamMembers',
        },
        params: {
          templateId: null,
        },
        component: 'cqRoutingBotEdit',
      })

      .state('app.content.messagesAjs.routingBot.edit', {
        url: '/{routingBotId:id}/edit',
        resolve: {
          calendlyEventList: getCalendlyEventList,
          calendlyIntegrationList: getCalendlyIntegrationList,
          channels: getChannels,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          routingBot: getRoutingBot,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
        },
        bindings: {
          billingInfo: 'billingInfo',
          channels: 'channels',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          properties: 'properties',
          routingBot: 'routingBot',
          tags: 'tags',
          teamMembers: 'teamMembers',
        },
        component: 'cqRoutingBotEdit',
      })

      .state('app.content.messagesAjs.routingBot.editFromLeadBot', {
        url: '/{routingBotId:id}/edit',
        resolve: {
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          branchesIdsToDelete: getBranchesIdsToDelete,
          calendlyEventList: getCalendlyEventList,
          calendlyIntegrationList: getCalendlyIntegrationList,
          channels: getChannels,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          isCopyFromLeadBot: () => true,
          properties: getProperties,
          routingBot: getLeadBotCopyForEdit,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
        },
        bindings: {
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          billingInfo: 'billingInfo',
          branchesIdsToDelete: 'branchesIdsToDelete',
          channels: 'channels',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          isCopyFromLeadBot: 'isCopyFromLeadBot',
          properties: 'properties',
          routingBot: 'routingBot',
          tags: 'tags',
          teamMembers: 'teamMembers',
        },
        params: {
          chatBotMessageId: null,
        },
        component: 'cqRoutingBotEdit',
      });

    /**
     * Получение количества активных чат-ботов
     * @param currentApp
     * @param chatBotModel
     * @return {*}
     */
    function getActiveChatBotsAmounts(currentApp, chatBotModel) {
      return firstValueFrom(chatBotModel.getActiveChatBotsAmounts(currentApp.id));
    }

    /**
     * Получение всех тегов пользователя
     *
     * @param currentApp
     * @param tagModel
     * @returns {Promise}
     */
    function getAllUserTags(currentApp, tagModel) {
      return firstValueFrom(tagModel.getList(currentApp.id));
    }

    /**
     * Получение списка каналов
     *
     * @param channelModel
     * @param currentApp
     * @return {Promise}
     */
    function getChannels(channelModel, currentApp) {
      return firstValueFrom(channelModel.getList(currentApp.id));
    }

    /**
     * Получение информации об активных Facebook ботах
     *
     * @param chatBotModel
     */
    function getDataAboutActiveFacebookBots(chatBotModel) {
      return firstValueFrom(chatBotModel.getFacebookBot()).then((response) => {
        const facebookBots = response ? [response].filter((bot) => bot.active) : [];
        return {
          ids: facebookBots.map((bot) => bot.id),
          amount: facebookBots.length,
        };
      });
    }

    /**
     * Получение информации об активных Lead ботах
     *
     * @param currentApp
     * @param {ChatBotModel} chatBotModel
     */
    function getDataAboutActiveLeadBots(currentApp, chatBotModel) {
      return firstValueFrom(chatBotModel.getList(currentApp.id, MESSAGE_STATUSES.ACTIVE)).then((response) => {
        return {
          ids: response.chatBots.map((bot) => bot.id),
          amount: response.chatBots.length,
        };
      });
    }

    /**
     * Получение информации об активных Welcome ботах
     *
     * @param chatBotModel
     */
    function getDataAboutActiveWelcomeBots(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot()).then((response) => {
        const welcomeBots = response ? [response].filter((bot) => bot.active) : [];
        return {
          ids: welcomeBots.map((bot) => bot.id),
          amount: welcomeBots.length,
        };
      });
    }

    /**
     * Получение существующих интеграций "Уведомление на Email"
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getEmailNotificationIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.EMAIL_NOTIFICATION);
    }

    /**
     * Получение существующих интеграций AmoCRM
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getAmocrmIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.AMOCRM);
    }

    /**
     * Функция возвращает ИД всех веток, кроме стартовой и прерывания.
     * Такой список нужен для ситуации полной замены одного бота другим (в данном случае лид-ботом);
     * Стартовая и прерывание не меняются.
     * @param chatBotModel
     * @returns {angular.IPromise<string[]>}
     */
    function getBranchesIdsToDelete(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot()).then((routingBot) => {
        return routingBot.branches
          .filter((branch) => {
            return branch.id !== routingBot.startBranchId && branch.id !== routingBot.interruptBranchId;
          })
          .map((branch) => branch.id);
      });
    }

    /** Получения списка интеграций Calendly
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     * @return {Promise}
     * */
    function getCalendlyIntegrationList(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel
        .getByType(currentApp.id, INTEGRATION_TYPES.CALENDLY)
        .then((integrations) => integrations.filter((integration) => integration.active));
    }

    /** Получения списка календарей в Calendly
     * @return {Promise}
     * */
    function getCalendlyEventList(integrationModel, calendlyIntegrationList, featureModel) {
      if (!calendlyIntegrationList.length) {
        return Promise.resolve([]);
      }

      const integrationId = calendlyIntegrationList[0].id;

      return integrationModel.getCalendlyEventList(integrationId);
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение шаблонов чат-бота
     * @param currentApp
     * @param chatBotTemplateModel
     * @returns {*}
     */
    function getTemplates(currentApp, chatBotTemplateModel) {
      return firstValueFrom(chatBotTemplateModel.getList(currentApp.id, CHAT_BOT_TYPE.ROUTING));
    }

    /**
     * Получение роутинг-бота
     *
     * @param chatBotModel
     * @returns {Promise}
     */
    function getRoutingBot(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot());
    }

    /**
     * Получение локальной копии лид-бота с замененными айдишниками
     */
    function getLeadBotCopyForCreate($stateParams, chatBotModel, currentApp) {
      return firstValueFrom(chatBotModel.getLeadBot(currentApp.id, $stateParams.chatBotMessageId)).then(
        (chatBotMessage) => {
          return copyLeadBotToBeRoutingBot(chatBotMessage.bot);
        },
      );
    }

    /**
     * Получение роутинг-бота из шаблона
     *
     * @param $stateParams
     * @param chatBotModel
     * @param chatBotTemplateModel
     * @param currentApp
     * @returns {*}
     */
    function getBotTemplateForCreate($stateParams, chatBotModel, chatBotTemplateModel, currentApp) {
      return firstValueFrom(chatBotTemplateModel.get($stateParams.templateId, currentApp.id)).then((template) =>
        chatBotTemplateModel.getChatBotFromTemplate(template, CHAT_BOT_TYPE.ROUTING),
      );
    }

    /**
     * Получение локальной копии лид-бота с замененными айдишниками для всех веток, кроме стартовой и прерывания
     */
    function getLeadBotCopyForEdit($translate, $stateParams, chatBotModel, currentApp) {
      return firstValueFrom(
        forkJoin([chatBotModel.getLeadBot(currentApp.id, $stateParams.chatBotMessageId), chatBotModel.getRoutingBot()]),
      ).then(([leadBotMessage, routingBot]) => {
        if (!routingBot) {
          throw new Error('Could not get routing bot');
        }

        const startedBranchId = routingBot.startBranchLinkId;
        const interruptedBranchId = routingBot.interruptBranchLinkId;
        const leadBotCopy = copyLeadBotToBeRoutingBot(leadBotMessage.bot, startedBranchId, interruptedBranchId);
        leadBotCopy.id = routingBot.id;
        leadBotCopy.name = $translate.instant('chatBot.list.routingBot.name');
        leadBotCopy.startBranchId = startedBranchId;
        leadBotCopy.branches.find((branch) => branch.linkId === startedBranchId).id = startedBranchId;
        leadBotCopy.interruptBranchId = interruptedBranchId;
        leadBotCopy.branches.find((branch) => branch.linkId === interruptedBranchId).id = interruptedBranchId;
        return leadBotCopy;
      });
    }

    /**
     * Обновляем айдишнихи всех веток и nextBranch в экшенах, чтоб бот был как-будто новый :)
     * @param {ChatBot} leadBot
     * @param {string | undefined} currentRoutingBotStartedBranchId - айдишник стартовой ветки от существующего роутинг бота
     * @param {string | undefined} currentRoutingBotInterruptedBranchId - айдишник ветки прерывания от существующего роутинг бота
     * @returns {ChatBot}
     */
    function copyLeadBotToBeRoutingBot(
      leadBot,
      currentRoutingBotStartedBranchId = undefined,
      currentRoutingBotInterruptedBranchId = undefined,
    ) {
      // Ключ - id с бэка, value - новый linkId
      const idToLinkId = {};

      const routingBot = cloneDeep(leadBot);

      routingBot.type = 'routing';

      if (currentRoutingBotStartedBranchId && currentRoutingBotInterruptedBranchId) {
        idToLinkId[routingBot.startBranchId] = currentRoutingBotStartedBranchId;
        idToLinkId[routingBot.interruptBranchId] = currentRoutingBotInterruptedBranchId;
      }

      // Правим айдишники блоков/веток
      routingBot.branches.forEach((branch) => {
        if (branch.id === leadBot.startBranchId) {
          branch.actions = branch.actions.filter((action) => {
            return ChatBotModel.isActionAllowedForStartBranch(action.type);
          });
        }

        // Если для ветки еще не создан новый локальный айди, то создаем
        if (!idToLinkId[branch.id]) {
          branch.linkId = generate();
          idToLinkId[branch.id] = branch.linkId;
          // Если он есть, то записываем его (он тут может оказаться, если эта ветка была в экшенах предыдущих веток)
        } else {
          branch.linkId = idToLinkId[branch.id];
        }
        delete branch.id;
        delete branch.chatBot;

        // Правим айдишники nextBranchId/nextBranchLinkId для действий
        branch.actions.forEach((action) => {
          delete action.id;
          action.linkId = generate();

          if (!ChatBotModel.isConnectionSourceAction(action.type)) {
            return;
          }

          const oldNextBranchId = action.nextBranchId;
          if (!oldNextBranchId) {
            return;
          }

          if (!idToLinkId[oldNextBranchId]) {
            idToLinkId[oldNextBranchId] = generate();
          }

          // Такие сложности из-за того, что если nextBranch - это стартовая ветка,
          // экшену надо записать nextBranchId, а не nextBranchLinkId, иначе бэк не даст сохранить
          if (idToLinkId[oldNextBranchId] === currentRoutingBotStartedBranchId) {
            action.nextBranchId = currentRoutingBotStartedBranchId;
          } else {
            delete action.nextBranchId;
          }
          action.nextBranchLinkId = idToLinkId[oldNextBranchId];

          delete action.branch;
          delete action.nextBranch;
        });
      });

      routingBot.startBranchLinkId = idToLinkId[routingBot.startBranchId];
      delete routingBot.startBranchId;

      routingBot.interruptBranchLinkId = idToLinkId[routingBot.interruptBranchId];
      delete routingBot.interruptBranchId;

      delete routingBot.id;

      return removeForbiddenConnectionsInLeadBotCopy(routingBot, CHAT_BOT_ACTIONS_TYPES);
    }

    /**
     * @param {ChatBot} bot
     * @param {CHAT_BOT_ACTIONS_TYPES} CHAT_BOT_ACTIONS_TYPES
     * @returns ChatBot
     */
    function removeForbiddenConnectionsInLeadBotCopy(bot, CHAT_BOT_ACTIONS_TYPES) {
      const firstBlock = bot.branches.find((br) => br.linkId === bot.startBranchLinkId);
      if (!firstBlock) {
        return bot;
      }

      switch (Branch.getBlockType(firstBlock)) {
        case 'branch':
          return bot;
        case 'action':
          bot.startBranchLinkId = null;
          return bot;
        case 'condition':
          firstBlock.actions.forEach((action) => {
            if (!ChatBotModel.isConnectionSourceAction(action.type)) {
              return;
            }
            const nextBlock = bot.branches.find((br) => br.linkId === action.nextBranchLinkId);
            if (!nextBlock) {
              return;
            }
            if (Branch.getBlockType(nextBlock) === 'action') {
              action.nextBranchLinkId = null;
            }
          });
          return bot;
      }
    }

    /**
     * Получение членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
    }
  }
})();

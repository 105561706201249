import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UIRouter } from '@uirouter/core';
import { toArray } from 'lodash-es';
import { take, takeUntil } from 'rxjs/operators';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AMOCRM_DEFAULT_LEAD_TAG_NAME } from '@http/integration/integrations/amo/constants/amocrm-integration.constants';
import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { AmocrmLeadTag } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmModalCreateLeadTagComponent } from '@panel/app/pages/integrations/content/amocrm/modal-create-lead-tag/amocrm-modal-create-lead-tag.component';
import { AmocrmModalCreateLeadTagParams } from '@panel/app/pages/integrations/content/amocrm/modal-create-lead-tag/amocrm-modal-create-lead-tag.types';
import { DestroyService } from '@panel/app/services';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Компонент для работы с тегами сделок AmoCRM
 */

@Component({
  selector: 'cq-amocrm-lead-tags[controlLeadTags][integrationId]',
  templateUrl: './amocrm-lead-tags.component.html',
  styleUrls: ['./amocrm-lead-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AmocrmLeadTagsComponent implements OnInit {
  /** Контрол для настройки тегов сделки в AmoCRM */
  @Input()
  controlLeadTags!: UntypedFormControl;

  /** ID интеграции */
  @Input()
  integrationId!: string;

  /** Настраивается ли компонент на странице с чат-ботом */
  @Input()
  isChatBot: boolean = false;

  /** Список тултипов на элементах тегов сделки */
  @ViewChildren('leadTagTooltip')
  leadTagTooltipQL!: QueryList<NgbPopover>;

  /** Инстанс ng-select с выбором тегов сделки AmoCRM */
  @ViewChild('leadsTagsSelect')
  leadsTagsSelect!: NgSelectComponent;

  /** Режим редактирования сообщения */
  isEdit!: boolean;

  /** Теги сделок AmoCRM */
  leadsTags: AmocrmLeadTag[] = [];

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    @Inject(AmocrmService) private readonly amocrmService: AmocrmService,
    @Inject(CarrotquestHelper) private readonly carrotquestHelper: CarrotquestHelper,
    @Inject(ChangeDetectorRef) private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
    @Inject(NgbModal) private readonly ngbModal: NgbModal,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
    @Inject(UIRouter) private readonly uiRouter: UIRouter,
  ) {}

  ngOnInit(): void {
    this.isEdit = this.isEditMessage();

    this.getLeadsTags();
  }

  /**
   * Добавление тега
   *
   * @param addedTag - Добавляемый тег
   */
  addTag(addedTag: AmocrmLeadTag): void {
    const existedTags = toArray(this.controlLeadTags.value); // Без toArray в тестах падает ошибка "is not iterable"

    this.controlLeadTags.setValue([...existedTags, addedTag]);
  }

  /**
   * Создание тега
   *
   * @param createdTag - Созданный тег
   */
  createTag(createdTag: AmocrmLeadTag): void {
    this.leadsTags = [...this.leadsTags, createdTag];
  }

  /** Получение названия дефолтного тега сделки */
  getDefaultLeadTagName(): string {
    if (this.isChatBot) {
      return AMOCRM_DEFAULT_LEAD_TAG_NAME.CHAT_BOT;
    } else {
      return AMOCRM_DEFAULT_LEAD_TAG_NAME.TRIGGER_MESSAGE;
    }
  }

  /** Получение текста тултипа для элемента дефолтного тега сделки */
  getDefaultLeadTagTooltipText(): string {
    if (this.isChatBot) {
      return this.translocoService.translate('amocrmLeadTagsComponent.defaultLeadTagTooltip.chatBot');
    } else {
      return this.translocoService.translate('amocrmLeadTagsComponent.defaultLeadTagTooltip.triggerMessage');
    }
  }

  /** Получение тегов сделки AmoCRM */
  getLeadsTags(): void {
    this.amocrmService
      .getLeadsTags(this.integrationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((leadsTags) => {
        this.leadsTags = leadsTags;

        this.changeDetectorRef.detectChanges();

        // Добавляем дефолтный тег сделки для новых сообщений
        if (!this.isEdit && toArray(this.controlLeadTags.value).length === 0) {
          const defaultLeadTagName = this.getDefaultLeadTagName();
          this.addTag(defaultLeadTagName);

          // После добавления дефолтного тега сделки необходимо показать тултип
          this.leadTagTooltipQL.changes.pipe(take(1)).subscribe(() => this.openDefaultLeadTagTooltip());
        }
      });
  }

  /**
   * Получение текста тега сделки, который будет отображаться в темплейте
   *
   * @param leadTag Тег сделки
   */
  getLeadTagText(leadTag: AmocrmLeadTag): string {
    if (this.isDefaultLeadTag(leadTag)) {
      return this.translocoService.translate(`amocrmIntegration.defaultLeadTag.${leadTag}`);
    } else {
      return leadTag;
    }
  }

  /**
   * Является ли тег дефолтным тегом сделки
   *
   * @param leadTag Тег сделки
   */
  isDefaultLeadTag(leadTag: AmocrmLeadTag): boolean {
    return leadTag === this.getDefaultLeadTagName();
  }

  /** Находимся ли в режиме редактирования сообщения */
  isEditMessage(): boolean {
    if (this.isChatBot) {
      return !!this.uiRouter.globals.params['chatBotId'];
    } else {
      return !!this.uiRouter.globals.params['messageId'];
    }
  }

  /**
   * Обработчик добавления тега в ng-select
   *
   * @param addedTag - Добавленный тег
   */
  onAddItem(addedTag: AmocrmLeadTag): void {
    this.trackAddTag(addedTag);
  }

  /**
   * Обработчик удаления тега
   *
   * @param deletedTag - Удаляемый тег
   */
  onRemoveTag(deletedTag: AmocrmLeadTag): void {
    this.removeTag(deletedTag);

    this.trackRemoveTag(deletedTag);
  }

  /** Открытие модального окна для создания тега сделки AmoCRM */
  openCreateLeadTagModal(): void {
    const modal = this.ngbModal.open(AmocrmModalCreateLeadTagComponent, {
      centered: true,
    });

    (modal.componentInstance.modalWindowParams as AmocrmModalCreateLeadTagParams) = {
      leadTag: this.leadsTagsSelect.searchTerm,
    };

    this.leadsTagsSelect.searchTerm = '';

    modal.result
      .then((createdTag: AmocrmLeadTag) => {
        this.createTag(createdTag);

        this.addTag(createdTag);

        this.trackAddTag(createdTag, true);
      })
      .catch(() => {});
  }

  /** Открытие тултипа у элемента с дефолтным тегом сделки */
  openDefaultLeadTagTooltip(): void {
    this.leadTagTooltipQL.first.open();
  }

  /**
   * Удаление тега
   *
   * @param deletedTag - Удаляемый тег
   */
  removeTag(deletedTag: AmocrmLeadTag): void {
    const existedTags = toArray(this.controlLeadTags.value); // Без toArray в тестах падает ошибка "is not iterable"
    const filteredTags = existedTags.filter((tag: AmocrmLeadTag) => {
      return tag !== deletedTag;
    });

    this.controlLeadTags.setValue(filteredTags);
  }

  /**
   * Трекинг добавления тега
   *
   * @param addedTag - Добавленный тег
   * @param isCreatedTag - Созданный ли тег
   */
  trackAddTag(addedTag: AmocrmLeadTag, isCreatedTag: boolean = false): void {
    const eventName = 'Интеграции - amoCRM - добавлен тег сделки';
    const params = {
      Инструмент: this.isChatBot ? 'чат-бот' : 'триггерное сообщение',
      'ID интеграции с amoCRM': this.integrationId,
      Тег: addedTag,
      'Тег был создан': isCreatedTag ? 'да' : 'нет',
    };

    this.carrotquestHelper.track(eventName, params);
  }

  /**
   * Трекинг удаления тега
   *
   * @param deletedTag - Удаляемый тег
   */
  trackRemoveTag(deletedTag: AmocrmLeadTag): void {
    const eventName = 'Интеграции - amoCRM - удален тег сделки';
    const params = {
      Инструмент: this.isChatBot ? 'чат-бот' : 'триггерное сообщение',
      'ID интеграции с amoCRM': this.integrationId,
      Тег: deletedTag,
    };

    this.carrotquestHelper.track(eventName, params);
  }
}

/**
 * Директива, которая ограничивает ввод в input целыми числами
 */
(function () {
  'use strict';

  angular.module('myApp.directives.number').directive('cqInteger', cqInteger);

  function cqInteger() {
    return {
      priority: 1000,
      restrict: 'A',
      require: 'ngModel',
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var INTEGER_REGEXP = /^\-?\d+$/;

      controller.$parsers.push(parser);
      controller.$validators.integer = integer;

      /**
       * Валидация значения input как integer
       *
       * @param modelValue Значение, которое записано в модель
       * @param viewValue Значение, которое отображается в input
       * @returns {Boolean} Если чисто является целым - вернётся true, иначе false
       */
      function integer(modelValue, viewValue) {
        // делаем валидацию только тогда, когда значение, которое будет записано в модель не пустое и не NaN
        return (controller.$isEmpty(modelValue) && !isNaN(modelValue)) || INTEGER_REGEXP.test(viewValue);
      }

      /**
       * Парсер, который не даёт вводить ничего, кроме цифр
       *
       * @param viewValue Значение, переданное из представления
       * @returns {*}
       */
      function parser(viewValue) {
        if (viewValue) {
          var value = viewValue;

          var isMinus = value.indexOf('-') == 0;

          if (isMinus) {
            value = value.substr(1);
          }

          value = value.replace(/[^\d]/g, '');

          if (isMinus) {
            value = '-' + value;
          }

          if (value != viewValue) {
            controller.$setViewValue(value);
            controller.$render();
          }

          // в модель должен быть записан int
          return parseInt(value, 10);
        } else {
          return viewValue;
        }
      }
    }
  }
})();

import { Pipe, PipeTransform } from '@angular/core';
import { isString, toNumber } from 'lodash-es';

import { environment } from '@environment';

/**
 * Пайп для трансформации числа в форматированный вид с учётом страны
 *
 * Пример:
 *  1120 => 1 120
 *  1,120 => 1 120
 */

@Pipe({
  name: 'toFormattedNumber',
})
export class ToFormattedNumberPipe implements PipeTransform {
  transform(value: string | number): string {
    if (isString(value)) {
      value = toNumber(value);
    }

    return new Intl.NumberFormat(environment.country).format(value);
  }
}

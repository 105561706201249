<ng-container *transloco="let t; read: 'answerToUserPropComponent'">
  <cq-checkbox-control
    [(ngModel)]="setUserProp"
    class="my-10"
    (change)="setUserPropChanged()"
  >
    {{ t('checkboxLabel') }}
  </cq-checkbox-control>

  <cq-property-selector
    *ngIf="setUserProp"
    [currentApp]="currentApp"
    [(properties)]="userProps"
    [(ngModel)]="selectedProp"
    (ngModelChange)="onChange($event)"
  ></cq-property-selector>
</ng-container>

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { environment } from '@environment';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({
  providedIn: 'root',
})
export class PushSettingsModel {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Получение настроек push page
   */
  get(appId: any): Observable<any> {
    return this.httpClient
      .get(`/apps/${appId}/pushpage`, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          this.parsePushSettingsToInternalFormat(response.data);

          return response.data;
        }),
      );
  }

  /**
   * Сохранение настроек push page
   */
  save(pushSettings: any): Observable<any> {
    const parsedPushSettings = this.parsePushSettingsToServerFormat(pushSettings);

    const body: any = {
      body: parsedPushSettings.body,
      click_action: parsedPushSettings.clickAction,
      domain: parsedPushSettings.domain,
      icon: parsedPushSettings.icon,
      set_default_icon: parsedPushSettings.setDefaultIcon,
      title: parsedPushSettings.title,
    };

    return this.httpClient.patch(`/pushpages/${pushSettings.id}`, body, {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true).set(EXTENDED_RESPONSE, true),
    });
  }

  /**
   * Парсинг настроек пушей в формат сервера
   */
  parsePushSettingsToServerFormat(pushSettings: any): any {
    const parsedPushSettings = { ...pushSettings };

    parsedPushSettings.domain =
      parsedPushSettings.domain.protocol +
      '://' +
      parsedPushSettings.domain.changeable +
      '.' +
      parsedPushSettings.domain.unchangeable;

    // NOTE: файлы просто так скопировать нельзя, приходится после angular.copy обращаться к старому объекту
    // иконку надо посылать только тогда, когда флаг setDefaultIcon = false
    parsedPushSettings.icon = !pushSettings.setDefaultIcon && pushSettings.icon ? pushSettings.icon : null;

    return parsedPushSettings;
  }

  /**
   * Парсинг настроек пушей во внутренний формат
   */
  parsePushSettingsToInternalFormat(pushSettings: any): void {
    const domain = pushSettings.domain.split('://');

    const protocol = domain[0];
    const unchangeable = domain[1].split('.').slice(-2).join('.');
    const changeable = domain[1].split('.' + unchangeable)[0];

    pushSettings.domain = {
      protocol: protocol,
      changeable: changeable,
      unchangeable: unchangeable,
    };

    pushSettings.icon = `${environment.userFilesUrl}/push-icons/${pushSettings.icon}`;
  }
}

(function () {
  'use strict';

  require('./conversation-in-history.controller');

  angular.module('myApp.conversationInHistory').component('cqConversationInHistory', cqConversationInHistory());

  function cqConversationInHistory() {
    return {
      bindings: {
        conversation: '=', // диалог
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        isDarkThemeActive: '=?', // теманя тема чата
        onModalClose: '<?', // функция закрытия модального окна
        onOpenConversationClick: '<?', // функция, вызываемая при нажатии на кнопку перехода в диалог
        onRedirectConversationClick: '<?', // нужно ли после нажатия на кнопку перенаправить в раздел диалоги
        paginationParams: '=?', // Параметры пагинации для запроса получения реплик диалогов
      },
      controller: 'CqConversationInHistoryController',
      controllerAs: 'vm',
      template: require('./conversation-in-history.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { CHANNEL_TYPES } from '@http/channel/channel.constants';
import { Channel, ChannelModel } from '@http/channel/channel.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { BillingInfo } from '@panel/app/services/billing-info/billing-info.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-channels-table',
  templateUrl: './channels-table.component.html',
  styleUrls: ['./channels-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelsTableComponent implements OnInit {
  /** информация о биллинге */
  @Input()
  billingInfo!: BillingInfo;

  /** список каналов */
  @Input()
  channels!: Channel[];

  /** текущее приложение */
  @Input()
  currentApp!: App;

  /** текущий пользователь */
  @Input()
  djangoUser!: DjangoUser;

  /** Лимит операторов для вывода в таблицу */
  OPERATORS_LIMIT = 10;

  /** Количество ручных каналов */
  manualChannelsAmount!: number;

  /** Доступ к интеграциям в тарифе */
  accessToIntegrations: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  /** Доступ к ручному созданию каналов в тарифе */
  accessToMessagesChannels: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  /** Флаг выполнения запроса */
  isChannelsRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly channelModel: ChannelModel,
    public readonly paywallService: PaywallService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.manualChannelsAmount = this.channels.filter((channel) => channel.type === CHANNEL_TYPES.MANUAL).length;

    this.accessToIntegrations = this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS, this.currentApp);
    this.accessToMessagesChannels = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.MESSAGES_CHANNELS,
      this.currentApp,
      this.manualChannelsAmount,
    );

    this.trackEnterOnPage();

    this.prepareChannels(this.channels);
  }

  /**
   * Смена приоритета у канала
   *
   * @param channel Канал
   * @param newPriority Смещение поля ввода, с которым будет меняться местами input, относительно input
   */
  changeChannelPriority(channel: Channel, newPriority: number): void {
    this.isChannelsRequestPerformed.next(true);

    firstValueFrom(this.channelModel.setPriority(channel.id!, newPriority))
      .then(() => this.updateChannelsList())
      .finally(() => this.setPriorityFinally());
  }

  private updateChannelsList() {
    return firstValueFrom(this.channelModel.getList(this.currentApp.id)).then((channels: Channel[]) =>
      this.prepareChannels(channels),
    );
  }

  private setPriorityFinally(): void {
    this.isChannelsRequestPerformed.next(false);
  }

  /**
   * Обработка каналов перед выводом в таблицу
   */
  prepareChannels(channels: Channel[]): void {
    // HACK: приходится вначале копировать список каналов, т.к. список каналов расшаривается по всей админке, а потом уже менять разрешение на чтение
    this.channels = [...channels];
  }

  trackByChannel(index: number, channel: Channel) {
    return channel.id;
  }

  /**
   * Трек клика на "Создать новый канал"
   */
  trackClickCreateChannel(): void {
    const eventName = 'Каналы - клик на "Создать новый канал"';
    this.carrotquestHelper.track(eventName);
  }

  /**
   * Трек захода на страницу
   */
  trackEnterOnPage(): void {
    const eventName = 'Настройки каналы - зашел';
    this.carrotquestHelper.track(eventName);
  }
}

<ng-container *transloco="let t; read: 'queryParamsEditorComponent'">
  <cq-param-editor
    class="mb-10"
    *ngFor="let param of control.controls; let i = index"
    [formControl]="param"
    (delete)="deleteParam(i)"
  ></cq-param-editor>

  <small
    class="text-secondary mb-20 d-block"
    [innerHTML]="t('description')"
  ></small>

  <button
    class="btn btn-block btn-secondary"
    (click)="addQueryParam()"
    type="button"
  >
    <i class="btn-icon-left cqi-sm cqi-plus"></i>
    <span>{{ t('addParamButton') }}</span>
  </button>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { WhatsAppEdnaAccessTokenComponent } from '@panel/app/pages/integrations/content/whats-app-edna/content/access-token/access-token.component';
import { WhatsAppEdnaAccountPhoneComponent } from '@panel/app/pages/integrations/content/whats-app-edna/content/account-phone/account-phone.component';
import { WhatsAppEdnaCascadeIdComponent } from '@panel/app/pages/integrations/content/whats-app-edna/content/cascade-id/cascade-id.component';
import { WhatsAppEdnaComponent } from '@panel/app/pages/integrations/content/whats-app-edna/whats-app-edna.component';
import { IntegrationNameControlModule } from '@panel/app/pages/integrations/partials/integration-name-control/integration-name-control.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [
    WhatsAppEdnaAccessTokenComponent,
    WhatsAppEdnaAccountPhoneComponent,
    WhatsAppEdnaCascadeIdComponent,
    WhatsAppEdnaComponent,
  ],
  exports: [
    WhatsAppEdnaAccessTokenComponent,
    WhatsAppEdnaAccountPhoneComponent,
    WhatsAppEdnaCascadeIdComponent,
    WhatsAppEdnaComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, IntegrationNameControlModule, TranslocoModule, SharedModule],
  providers: [IntegrationFactory],
})
export class WhatsAppEdnaModule {}

(function () {
  'use strict';

  require('./starter-guide-first-trigger-message.controller');

  angular
    .module('myApp.starterGuide')
    .component('cqStarterGuideFirstTriggerMessage', cqStarterGuideFirstTriggerMessage());

  function cqStarterGuideFirstTriggerMessage() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        messageSenders: '=', // Список отправителей
        isStepMade: '=', // Пройденность шага
      },
      controller: 'CqStarterGuideFirstTriggerMessageController',
      controllerAs: 'vm',
      template: require('./starter-guide-first-trigger-message.html'),
    };
  }
})();

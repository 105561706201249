<ng-container *transloco="let t">
  <cq-vk-old
    *ngIf="isOldIntegration"
    [currentAppId]="currentAppId"
    [integrationExternal]="integrationExternal"
  ></cq-vk-old>

  <cq-vk-new
    *ngIf="!isOldIntegration"
    [currentAppId]="currentAppId"
    [integrationExternal]="integrationExternal"
    [isEdit]="isEdit"
  ></cq-vk-new>
</ng-container>

<ng-container *transloco="let t">
  <div class="d-flex align-content-center justify-content-center bg-white image">
    <img
      *ngIf="attachment"
      class="full-max-width align-middle"
      [src]="imageUrl"
    />
    <div
      *ngIf="!attachment"
      class="d-flex justify-content-center align-items-center w-100 zero-data"
    >
      {{ t('conversationPartPreviewImageComponent.zeroData') }}
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CONFIRM_MODAL_DATA_TOKEN, ConfirmModalData } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';

/**
 * Модальное окно подтверждения действия (любого)
 */

@Component({
  selector: 'cq-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent {
  constructor(
    public readonly activeModal: NgbActiveModal,
    @Inject(CONFIRM_MODAL_DATA_TOKEN)
    public readonly data: ConfirmModalData,
    private readonly translocoService: TranslocoService,
  ) {}

  /** Текст тела модалки */
  get body(): string {
    return this.data.body;
  }

  /** CSS-класс кнопки отмены */
  get cancelButtonClass(): string {
    return this.data.cancelButtonClass || 'btn-outline-primary';
  }

  /** Текст в кнопке отмены */
  get cancelButtonText(): string {
    return this.data.cancelButtonText || this.translocoService.translate('general.cancel');
  }

  /** CSS-класс подтверждающей кнопки */
  get confirmButtonClass(): string {
    return this.data.confirmButtonClass || 'btn-primary';
  }

  /** Текст в подтверждающей кнопке */
  get confirmButtonText(): string {
    return this.data.confirmButtonText;
  }

  /** Заголовок модалки */
  get heading(): string {
    return this.data.heading;
  }
}

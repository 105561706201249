import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ClipboardService } from 'ngx-clipboard';

import { DestroyService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

import { InputCopyButtonComponent } from './input-copy-button.component';

@NgModule({
  declarations: [InputCopyButtonComponent],
  exports: [InputCopyButtonComponent],
  imports: [CommonModule, TranslocoModule],
  providers: [ClipboardService, DestroyService, ToastService],
})
export class InputCopyButtonModule {}

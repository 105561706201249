/**
 * Контроллер для статистики сообщения из триггерной цепочки за всё время
 */
import { MESSAGE_PART_TYPES } from '../../../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular
    .module('myApp.triggerChains.allTimeStats')
    .controller('CqTriggerChainAllTimeStatsController', CqTriggerChainAllTimeStatsController);

  function CqTriggerChainAllTimeStatsController($filter, messagePartModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.getBlockPopupCount = getBlockPopupCount;
      vm.messageParts = messagePartModel.filterMessageParts(vm.currentMessage.activeTestGroup.parts); // варианты сообщения
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
    }

    /**
     * Подсчёт количества блочных поп-апов в сообщении
     *
     * @returns {number}
     */
    function getBlockPopupCount() {
      return $filter('filter')(vm.messageParts, filterBlockPopups).length;

      function filterBlockPopups(messagePart) {
        return (
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
        );
      }
    }
  }
})();

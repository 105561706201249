import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-creativity-level-control',
  templateUrl: './creativity-level-control.component.html',
  styleUrls: ['./creativity-level-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreativityLevelControlComponent extends AbsCVAFormControlBasedComponent<number> {
  readonly control: FormControl<number> = new FormControl<number>(20, {
    nonNullable: true,
    validators: [Validators.required],
  });

  protected sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    step: 10,
    hideLimitLabels: true,
  };

  constructor() {
    super();
  }
}

<cq-bot-action-next-branch-selector
  [formControl]="actionForm.controls.nextBranchLinkId!"
  [canSelectConditionAsNextBranch]="true"
  [chatBotType]="chatBotType"
  [chatBotId]="chatBotId"
  [nextBranchOptions]="nextBranchOptions"
  (branchSelect)="onChangeBranch($event)"
  (addBranchClick)="branchCreate.next($event)"
>
</cq-bot-action-next-branch-selector>

<ng-container *transloco="let t; read: 'memberInvitationsNotExistZeroDataComponent'">
  <div class="mx-auto my-50 text-center">
    <h4 class="mb-20">{{ t('heading') }}</h4>

    <button
      class="btn btn-primary"
      type="button"
      (click)="onClickAddTeamMembersButton()"
      data-test="member-invitations-not-exist-zero-data-add-team-members-button"
    >
      {{ t('addTeamMembersButton') }}
    </button>
  </div>
</ng-container>

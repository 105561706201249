<ng-container *transloco="let t">
  <ng-container *ngIf="hasAccess; else permissionDenied">
    <div class="flex align-items-center">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">{{ t('integrationListComponent.heading.breadcrumbs.integrations') }}</li>
      </ol>
      <span
        *ngIf="remainingIntegrationsCount > 0 && remainingIntegrationsCount < remainingIntegrationCountForWarning"
        class="badge badge-light-warning ml-20"
        >{{
          t('integrationListComponent.heading.remainingIntegrationsCountLabel', {
            count: remainingIntegrationsCount
          })
        }}</span
      >
      <div *ngIf="remainingIntegrationsCount === 0">
        <span class="badge badge-light-danger ml-20">{{
          t('integrationListComponent.heading.integrationsLimitLabel')
        }}</span>
        <i
          class="cqi-sm cqi-info"
          [ngbTooltip]="t('integrationListComponent.heading.integrationsLimitTooltip')"
          placement="right"
        ></i>
      </div>

      <button
        class="btn btn-secondary ml-auto"
        type="button"
        (click)="openChat()"
      >
        <i class="btn-icon-left cqi-sm cqi-lightbulb"></i>
        <span>{{ t('integrationListComponent.heading.suggestIntegrationButton', { projectName: projectName }) }}</span>
      </button>
    </div>

    <div class="card">
      <div class="card-header d-flex">
        <div class="btn-group btn-group-toggle mr-20">
          <label
            class="btn btn-outline-primary"
            [class.active]="integrationTypeStateFilter === IntegrationTypeState.All"
          >
            <input
              type="radio"
              [(ngModel)]="integrationTypeStateFilter"
              [value]="IntegrationTypeState.All"
            />
            <span>{{ t('integrationListComponent.filterByConfiguration.' + IntegrationTypeState.All) }}</span>
          </label>
          <label
            class="btn btn-outline-primary"
            [class.active]="integrationTypeStateFilter === IntegrationTypeState.Configured"
          >
            <input
              type="radio"
              [(ngModel)]="integrationTypeStateFilter"
              [value]="IntegrationTypeState.Configured"
            />
            <span>{{ t('integrationListComponent.filterByConfiguration.' + IntegrationTypeState.Configured) }}</span>
          </label>
        </div>
        <div class="d-flex flex-grow-1 align-items-center justify-content-end">
          <div class="mr-10">{{ t('integrationListComponent.filterByIntegrationClassSelect.label') }}</div>
          <ng-select
            class="integration-class-filter"
            [clearable]="false"
            [searchable]="false"
            [(ngModel)]="integrationClassFilter"
          >
            <ng-option [value]="allIntegrationClasses">{{
              t('integrationListComponent.filterByIntegrationClassSelect.all')
            }}</ng-option>
            <ng-option
              *ngFor="let integrationClass of accessibleIntegrationClasses"
              [value]="integrationClass"
              >{{ t('models.integration.classes.' + integrationClass) }}</ng-option
            >
          </ng-select>
        </div>
      </div>
      <div class="card-body margin-between-rows-40">
        <div *ngFor="let integrationClass of filteredIntegrationClasses">
          <h4 class="margin-bottom-20">{{ t('models.integration.classes.' + integrationClass) }}</h4>
          <div
            class="d-flex flex-wrap"
            [style.margin.px]="-gapBetweenCards / 2"
          >
            <ng-container *ngFor="let integrationType of INTEGRATIONS_BY_CLASS[integrationClass]">
              <cq-integration-card
                *ngIf="filteredIntegrationTypes.includes(integrationType)"
                [style.margin.px]="gapBetweenCards / 2"
                [style.flexBasis]="flexBasis$ | async"
                [count]="getConfiguredIntegrationTypeCount(integrationType)"
                [currentApp]="currentApp"
                [type]="integrationType"
              ></cq-integration-card>
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="!filteredIntegrationClasses.length"
          class="my-50 py-50 text-center"
        >
          <h2 class="margin-bottom-20">{{ t('integrationListComponent.zeroData.heading') }}</h2>
          <button
            class="btn btn-primary"
            type="button"
            (click)="resetFilters()"
          >
            {{ t('integrationListComponent.zeroData.resetFiltersButton') }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #permissionDenied>
    <cq-permission-denied></cq-permission-denied>
  </ng-template>
</ng-container>

import {
  POPUP_BLOCK_CURRENCY_CODES,
  POPUP_BLOCK_CURRENCY_POSITIONS_BY_CODE,
  POPUP_BLOCK_CURRENCY_SYMBOLS_BY_CODE,
  POPUP_BLOCK_TYPES,
} from '../../../../../app/services/popup-block/popup-block.constants';

import { MESSAGE_PART_TYPES } from '../../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupCartBlockSettingsController', CqPopupCartBlockSettingsController);

  function CqPopupCartBlockSettingsController(
    $filter,
    $scope,
    $translate,
    popupBlockModel,
    carrotquestHelper,
    validationHelper,
  ) {
    var vm = this;

    /**
     * Группы валюты
     *
     * @type {{SYSTEM: string, USER: string}}
     */
    var CURRENCY_GROUPS = {
      SYSTEM: 'system',
      USER: 'user',
    };

    /**
     * Код другой валюты
     *
     * @type {string}
     */
    var OTHER_CURRENCY_CODE = 'other';

    /**
     * Валюты
     *
     * @type {Array}
     */
    var CURRENCIES = [];

    /**
     * Максимальное количство выводимых товаров
     *
     * @type {Number}
     */
    var ITEMS_COUNT_MAX = 30;

    /**
     * Минимальное количство выводимых товаров
     *
     * @type {Number}
     */
    var ITEMS_COUNT_MIN = 1;

    /**
     * Максимальная длинна валюты
     *
     * @type {Number}
     */
    var OTHER_CURRENCY_MAX_LENGTH = 5;

    /**
     * Вкладки
     *
     * @type {{CONTENT: String, STYLE: String}}
     */
    var TABS = {
      CONTENT: 'content', // содержимое
      STYLE: 'style', // стиль
    };

    vm.$onInit = init;

    function init() {
      CURRENCIES = getCurrencies();

      vm.contentForm = null; // форма вкладки "Содержимое"
      vm.CURRENCIES = CURRENCIES;
      vm.currentCurrency = getCurrentCurrency(); // Выбранная валюта
      vm.currentTab = TABS.CONTENT; // текущая вкладка
      vm.hasCartAddedEvent = hasCartAddedEvent;
      vm.ITEMS_COUNT_MAX = ITEMS_COUNT_MAX;
      vm.ITEMS_COUNT_MIN = ITEMS_COUNT_MIN;
      vm.OTHER_CURRENCY_CODE = OTHER_CURRENCY_CODE;
      vm.OTHER_CURRENCY_MAX_LENGTH = OTHER_CURRENCY_MAX_LENGTH;
      vm.setCurrency = setCurrency;
      vm.styleForm = null; // форма вкладки "Стиль"
      vm.TABS = TABS;
      vm.trackClickOnContent = trackClickOnContent;
      vm.trackClickOnStyle = trackClickOnStyle;
      vm.validationHelper = validationHelper;

      vm.setErrorHandler && vm.setErrorHandler({ handler: handleError });
    }

    /**
     * Получение валют
     */
    function getCurrencies() {
      var currencies = [];
      var currenciesCodeKeys = Object.keys(POPUP_BLOCK_CURRENCY_CODES);
      var isDefaultCurrencyChosen = false; // Выбрана одна из дефолтных валют
      for (var i = 0; i < currenciesCodeKeys.length; i++) {
        var currency = popupBlockModel.getDefaultCurrency();

        currency.code = POPUP_BLOCK_CURRENCY_CODES[currenciesCodeKeys[i]];
        currency.group = CURRENCY_GROUPS.SYSTEM;
        currency.position = POPUP_BLOCK_CURRENCY_POSITIONS_BY_CODE[currenciesCodeKeys[i]];
        currency.prettyName = $translate.instant(
          'models.popupBlock.currencyCodes.' + POPUP_BLOCK_CURRENCY_CODES[currenciesCodeKeys[i]],
        );
        currency.symbol = POPUP_BLOCK_CURRENCY_SYMBOLS_BY_CODE[currenciesCodeKeys[i]];

        currencies.push(currency);

        /*Проверяет есть ли выбранный символ валюты среди дефолтных. Это надо для того чтобы в случае выбора дргугой валюты этот символ записался в нее, в противном слуае символ другой валюты должнен быть пустым*/
        isDefaultCurrencyChosen = isDefaultCurrencyChosen || currency.symbol === vm.popupBlockParams.currency.symbol;
      }
      var otherCurrency = popupBlockModel.getDefaultCurrency();

      otherCurrency.code = OTHER_CURRENCY_CODE;
      otherCurrency.prettyName = $translate.instant('popupCartBlockSettings.style.styleForm.otherCurrency');
      otherCurrency.symbol = isDefaultCurrencyChosen ? '' : vm.popupBlockParams.currency.symbol;
      otherCurrency.group = CURRENCY_GROUPS.USER;

      currencies.push(otherCurrency);

      return currencies;
    }

    /**
     * Получение выбранной валюты
     *
     * @returns {Object}
     */
    function getCurrentCurrency() {
      return $filter('filter')(CURRENCIES, { symbol: vm.popupBlockParams.currency.symbol }, true)[0];
    }

    /**
     * Функция открытия вкладки с ошибкой при отправке формы снаружи компонента
     */
    function handleError() {
      // искусственно сабмитим формы (с ngForm по-другому не получится) на случай, если родительская форма не была засабмичена, чтобы отображались ошибки
      vm.contentForm.$setSubmitted();
      vm.styleForm.$setSubmitted();

      // если открыта невалидная вкладка - переключаться на другую вкладку не нужно
      if (vm.contentForm.$invalid && vm.styleForm.$valid) {
        vm.currentTab = TABS.CONTENT;
      } else if (vm.styleForm.$invalid && vm.contentForm.$valid) {
        vm.currentTab = TABS.STYLE;
      }
    }

    /**
     * Проверяет существует и настроено ли событие «Добавил товар в корзину»
     *
     * @returns {Boolean}
     */
    function hasCartAddedEvent() {
      var cartAddedEvent = $filter('filter')(vm.eventTypes, { name: '$cart_added' }, true)[0];
      return cartAddedEvent && cartAddedEvent.active;
    }

    /**
     * Задает свойству currency данные
     */
    function setCurrency() {
      var defaultCurrency = popupBlockModel.getDefaultCurrency();
      defaultCurrency.position = vm.currentCurrency.position;
      defaultCurrency.symbol = vm.currentCurrency.symbol;
      vm.popupBlockParams.currency = defaultCurrency;
    }

    /**
     * Трек клика на вкладку 'Содержимое'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnContent(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на содержимое блока', {
        Название: POPUP_BLOCK_TYPES.CART,
      });
    }

    /**
     * Трек клика на вкладку 'Стиль'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnStyle(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на стиль блока', {
        Название: POPUP_BLOCK_TYPES.CART,
      });
    }
  }
})();

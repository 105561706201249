import { APP_TASKS, STARTER_GUIDE_SKIPPED } from '@http/app/app.constants';
import { ParsedAppActivation } from '@http/app/app.model';
import { DjangoUserTempData } from '@http/django-user/types/django-user-settings.type';

/** Сервис для работы с блокировками чего угодно */
export class BlockingService {
  //@ngInject
  constructor() {}

  /**
   * Проверяет, имеются ли блокировки чего угодно для кейса маркетолога
   *
   * @param activations - Активации приложения
   * @param djangoUserTempData - Временные данные django-пользователя
   */
  hasBlockingForMarketerCase(activations: ParsedAppActivation, djangoUserTempData: DjangoUserTempData): boolean {
    const isCodeInstalled = activations.installed_code;
    const isMarketerTaskChosen = djangoUserTempData.task === APP_TASKS.RAISE_LEAD_CONVERSION;
    const isStarterGuideSkipped = !!localStorage.getItem(STARTER_GUIDE_SKIPPED);

    if (!isMarketerTaskChosen) {
      return false;
    }

    if (isStarterGuideSkipped || isCodeInstalled) {
      return false;
    }

    return true;
  }
}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('SendsayController', SendsayController);

  function SendsayController(
    $filter,
    $translate,
    $state,
    toastr,
    PROJECT_NAME,
    integrationModel,
    propertyModel,
    validationHelper,
    currentApp,
    integration,
  ) {
    var vm = this;

    vm.$translate = $translate;
    vm.addMappingItem = addMappingItem;
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.integration = integration;
    vm.isManualExpanded = angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.props = [];
    vm.propsEvents = [];
    vm.propsEventsMapping = [];
    vm.propsMapping = [];
    vm.removeMappingItem = removeMappingItem;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      parseSendsayIntegrationToInternalFormat(integration);

      firstValueFrom(propertyModel.getList(currentApp.id)).then(getUserPropsMetaSuccess);
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function createOrUpdateIntegration(isValid) {
      if (isValid) {
        parseSendsayIntegrationToServerFormat(integration);

        if (integration.id) {
          integrationModel.save(currentApp.id, integration).then(saveSuccess);
        } else {
          integrationModel.create(currentApp.id, integration).then(createSuccess);
        }
      }

      function createSuccess(integration) {
        var params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        var transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess(integration) {
        parseSendsayIntegrationToInternalFormat(integration);
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }

    function getUserPropsMetaSuccess(properties) {
      vm.eventTypes = properties.eventTypes;
      vm.props = properties.userProps;
      vm.propsEvents = properties.eventTypeProps;

      if (typeof integration.settings.eventTypeIds === 'undefined') {
        integration.settings.eventTypeIds = [];
      }
      for (var i = 0; i < vm.eventTypes.length; i++) {
        if (integration.settings.eventTypeIds.indexOf(vm.eventTypes[i].id) != -1) {
          vm.eventTypes[i].checked = true;
        }
      }
    }

    /**
     * Добавление объекта в массив
     *
     * @param {Array} array Массив, в который добавляется объект
     */
    function addMappingItem(array) {
      array.push({});
    }

    /**
     * Удаление объекта из массива
     *
     * @param array
     * @param item
     */
    function removeMappingItem(array, item) {
      var index = array.indexOf(item);

      if (~index) {
        array.splice(index, 1);
      }
    }

    /**
     * Конвертация массива объектов (ключи и значения этих объектов должны быть примитивными типами) в объект
     *
     * @example
     * // вернёт {'value1': 'value2', 'value3': 'value4'}
     * convertArrayToMapping([{'key1': 'value1', 'key2': 'value2'}, {'key1': 'value3', 'key2': 'value4'}], 'key1', 'key2');
     *
     * @param {Array.<Object>} array Массив объектов для конвертации
     * @param {String} keyName Название ключа объекта массива, значение которого будет являться ключом в результирующем объекте
     * @param {String} valueName Название ключа объекта массива, значение которого будет являться значением в результирующем объекте
     * @return {Object}
     */
    function convertArrayToMapping(array, keyName, valueName) {
      var mapping = {};

      for (var i = 0; i < array.length; i++) {
        var item = array[i];

        mapping[item[keyName]] = item[valueName];
      }

      return mapping;
    }

    /**
     * Конвертация объекта (ключи и значения объекта должны быть примитивными типами) в массив объектов
     *
     * @example
     * // вернёт [{'newKey1': 'key1', 'newKey2': 'value1'}, {'newKey1': 'key2', 'newKey2': 'value2'}]
     * convertMappingToArray({'key1': 'value1', 'key2': 'value2'}, 'newKey1', 'newKey2');
     *
     * @param {Object} mapping Объект для конвертации
     * @param {String} keyName Название ключа элемента массива, значение которого будет являться ключом объекта
     * @param {String} valueName Название ключа элемента массива, значение которого будет являться значением объекта
     * @return {Array.<Object>}
     */
    function convertMappingToArray(mapping, keyName, valueName) {
      var array = [];

      for (var key in mapping) {
        if (mapping.hasOwnProperty(key)) {
          var arrayItem = {};

          arrayItem[keyName] = key;
          arrayItem[valueName] = mapping[key];

          array.push(arrayItem);
        }
      }

      return array;
    }

    /**
     * Фильтрация свойств из общего списка свойств и событий
     *
     * @param propMapping
     * @return {Boolean}
     */
    function filterProps(propMapping) {
      if (angular.equals({}, propMapping) || propMapping.cqName.indexOf('$event_') == -1) {
        return true;
      }
    }

    /**
     * Фильтрация событий из общего списка свойств и событий
     *
     * @param propMapping
     * @return {Boolean}
     */
    function filterEvents(propMapping) {
      if (angular.equals({}, propMapping) || propMapping.cqName.indexOf('$event_') == 0) {
        return true;
      }
    }

    /**
     * Парсинг интеграции во внутренний формат админки
     *
     * @param {Object} integration Интеграция
     */
    function parseSendsayIntegrationToInternalFormat(integration) {
      integration.settings.eventTypeIds = angular.isDefined(integration.settings.eventTypeIds)
        ? integration.settings.eventTypeIds
        : [];
      vm.propsMapping = angular.isDefined(integration.settings.propsMapping)
        ? $filter('filter')(
            convertMappingToArray(integration.settings.propsMapping, 'cqName', 'sendsayName'),
            filterProps,
          )
        : [];
      vm.propsEventsMapping = angular.isDefined(integration.settings.propsMapping)
        ? $filter('filter')(
            convertMappingToArray(integration.settings.propsMapping, 'cqName', 'sendsayName'),
            filterEvents,
          )
        : [];
    }

    /**
     * Парсинг интеграции в формат сервера
     *
     * @param {Object} integration Интеграция
     */
    function parseSendsayIntegrationToServerFormat(integration) {
      integration.settings.propsMapping = angular.extend(
        {},
        convertArrayToMapping(vm.propsMapping, 'cqName', 'sendsayName'),
        convertArrayToMapping(vm.propsEventsMapping, 'cqName', 'sendsayName'),
      );
    }
  }
})();

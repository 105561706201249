(function () {
  'use strict';

  require('./header.controller');
  require('./templates/apps-popover.html');
  require('./templates/auto-assign-popover.html');
  require('./templates/help-popover.html');
  require('./templates/profile-popover.html');
  require('./templates/system-log-popover.html');

  angular.module('myApp.header').component('cqHeader', cqHeader());

  function cqHeader() {
    return {
      bindings: {
        appBlocks: '=', // данные о блокировках приложения
        appList: '=', // список приложений
        billingInfo: '=', // информация по биллингу
        currentApp: '=', // текущее приложение
        djangoUser: '=', // django-пользователь
        djangoUserTempData: '=', // Временные данные django-пользователя
      },
      controller: 'CqHeaderController',
      controllerAs: 'vm',
      template: require('./header.html'),
    };
  }
})();

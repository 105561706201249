import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { PERFORMANCE } from '@ng-web-apis/common';
import { Observable, Subject } from 'rxjs';

declare global {
  interface Performance {
    memory?: {
      jsHeapSizeLimit: number;
      totalJSHeapSize: number;
      usedJSHeapSize: number;
    };
  }
}

export type PixiPerformanceLog = {
  fps: number;
  usedMemoryMB: number | null;
  maxMemoryMB: number | null;
  isResizedDocument: boolean;
};

const BYTES_IN_MEGABYTE = 1048576;

@Injectable()
export class PixiStats {
  private beginTime = (this.performance || Date).now();
  private prevTime = this.beginTime;
  private frames = 0;

  /**
   * Когда переключаешь вкладку, происходит падение фпс (потому что за промежуток времени рендерится сильно меньше кадров), поэтому мы скипаем один цикл
   */
  private changedVisibility = false;

  private resizedDocument = false;

  private logSubj = new Subject<PixiPerformanceLog>();

  constructor(
    @Inject(PERFORMANCE) private readonly performance: Performance,
    @Inject(DOCUMENT) document: Document,
    @Inject(RendererFactory2) rendererFactory: RendererFactory2,
  ) {
    const renderer2 = rendererFactory.createRenderer(null, null);
    renderer2.listen('document', 'visibilitychange', () => {
      this.changedVisibility = true;
    });
    renderer2.listen('window', 'resize', () => {
      this.resizedDocument = true;
    });
  }

  get log$(): Observable<PixiPerformanceLog> {
    return this.logSubj.asObservable();
  }

  private tick() {
    let usedMemoryMB: number | null = null;
    let maxMemoryMB: number | null = null;
    let fps: number = 0;

    this.frames++;
    const time = (performance || Date).now();

    if (time > this.prevTime + 1000) {
      fps = (this.frames * 1000) / (time - this.prevTime);

      this.prevTime = time;
      this.frames = 0;

      if (performance.memory) {
        const memory = this.performance.memory!;
        usedMemoryMB = memory.usedJSHeapSize / BYTES_IN_MEGABYTE;
        maxMemoryMB = memory.jsHeapSizeLimit / BYTES_IN_MEGABYTE;
      }

      this.emitLog(fps, maxMemoryMB, usedMemoryMB);
    }
    return time;
  }

  private emitLog(fps: number, maxMemoryMB: number | null, usedMemoryMB: number | null) {
    if (this.changedVisibility) {
      this.changedVisibility = false;
      return;
    }

    this.logSubj.next({
      fps,
      usedMemoryMB,
      maxMemoryMB,
      isResizedDocument: this.resizedDocument,
    });
    this.resizedDocument = false;
  }

  update() {
    this.beginTime = this.tick();
  }
}

<ng-container *transloco="let t; read: 'messageStatusComponent'">
  <ng-container [ngSwitch]="conversationStatus">
    <i
      *ngSwitchCase="'sent'"
      class="cqi-sm cqi-check-sent"
      [title]="t('sent')"
    ></i>
    <i
      *ngSwitchCase="'read'"
      class="cqi-sm cqi-check-read text-primary"
      [title]="t('read')"
    ></i>
    <i
      *ngSwitchCase="'closed'"
      class="cqi-sm cqi-check-circle text-success"
      [title]="t('closed')"
    ></i>
    <i
      *ngSwitchCase="'delayed'"
      class="cqi-sm cqi-stopwatch-o"
      [title]="t('delayed')"
    ></i>
    <i
      *ngSwitchCase="'returnFromDelayed'"
      class="cqi-sm cqi-stopwatch-o text-warning"
      [title]="t('returnFromDelayed')"
    ></i>
    <div
      *ngSwitchCase="'notReplied'"
      class="unanswered-counter label label-primary small flex justify-center align-items-center"
      [title]="t('unanswered')"
    >
      {{ conversation.admin_unread_count || '' }}
    </div>
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</ng-container>

/**
 * Компонент для превью блока 'Разделитель'
 */
(function () {
  'use strict';

  require('./popup-indent-block-preview.controller');
  require('./indent.html');

  angular.module('myApp.popupEditor').component('cqPopupIndentBlockPreview', cqPopupIndentBlockPreview());

  function cqPopupIndentBlockPreview() {
    return {
      bindings: {
        params: '=', // параметры блока
      },
      controller: 'CqPopupIndentBlockPreviewController',
      controllerAs: 'vm',
      template: require('./popup-indent-block-preview.html'),
    };
  }
})();

/**
 * Контроллер для модального окна редактирования связки Статус сделки retailCRM - Событие Carrot quest
 */
(function () {
  'use strict';

  angular
    .module('myApp.modals.editRetailcrmStatus')
    .controller('EditRetailcrmStatusModalController', EditRetailcrmStatusModalController);

  function EditRetailcrmStatusModalController(
    $filter,
    $uibModalInstance,
    $translate,
    PROJECT_NAME,
    validationHelper,
    retailcrmProps,
    eventTypes,
    status,
  ) {
    var vm = this;

    vm.addEventProp = addEventProp;
    vm.retailcrmProps = retailcrmProps;
    vm.eventTypes = eventTypes;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.status = status;
    vm.removeEventProp = removeEventProp;
    vm.resetStatus = resetStatus;
    vm.saveStatus = saveStatus;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      // в списке событий может не оказаться события, которое по умолчанию настроено для сделки, а так же события, которое введено вручную, поэтому их надо добавить
      var defaultEventName =
        $translate.instant('modals.editRetailcrmStatus.defaultEventName.crmName') +
        ': ' +
        status.groupName +
        ' - ' +
        status.name;
      var defaultEvent = $filter('filter')(eventTypes, { name: defaultEventName }, true)[0];

      if (angular.isUndefined(defaultEvent)) {
        eventTypes.push({
          name: defaultEventName,
          prettyName: defaultEventName,
          group: $translate.instant('models.property.groups.events'),
        });
      }

      var currentEventName = status.ordersEvent.event.prop;
      var currentEvent = $filter('filter')(eventTypes, { name: currentEventName }, true)[0];

      if (angular.isUndefined(currentEvent)) {
        eventTypes.push({
          name: currentEventName,
          prettyName: currentEventName,
          group: $translate.instant('models.property.groups.events'),
        });
      }
    }

    /**
     * Добавление свойств к связке Статус заказа - Событие
     */
    function addEventProp() {
      status.ordersEvent.eventProps.push({});
    }

    /**
     * Удаление свойств из связки Статус заказа - Событие
     *
     * @param eventProp
     */
    function removeEventProp(eventProp) {
      status.ordersEvent.eventProps.splice(status.ordersEvent.eventProps.indexOf(eventProp), 1);
    }

    /**
     * Сброс события к первоначальному состоянию
     */
    function resetStatus() {
      status.ordersEvent.checked = false;
      status.ordersEvent.event.prop =
        $translate.instant('modals.editRetailcrmStatus.defaultEventName.crmName') +
        ': ' +
        status.groupName +
        ' - ' +
        status.name;
    }

    /**
     * Сохранение состояния статуса
     *
     * @param isValid
     */
    function saveStatus(isValid) {
      if (isValid) {
        $uibModalInstance.close(status);
      }
    }
  }
})();

<ng-container *transloco="let t">
  <ng-container *ngFor="let filterControl of formArray.controls; let i = index">
    <cq-single-url-filter-configurator
      [index]="i"
      [isOnlyFilter]="formArray.length === 1"
      [formGroup]="$any(filterControl)"
      [options]="options"
      (dropFilterClick)="dropFilter(i)"
    ></cq-single-url-filter-configurator>
  </ng-container>

  <div class="margin-top-10 text-left">
    <span
      ngbTooltip="{{ t('urlFilterConfigurator.addPageButton.disabledTooltip', { maxPagesCount: filtersMaxCount }) }}"
      [disableTooltip]="formArray.length < filtersMaxCount"
    >
      <button
        [disabled]="formArray.length === filtersMaxCount"
        class="btn btn-borderless-primary"
        type="button"
        (click)="addUrlFilter()"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        <span>{{ t('urlFilterConfigurator.addPageButton.text') }}</span>
      </button>
    </span>
  </div>
</ng-container>

import Moment from 'moment';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationExternal } from '@http/integration/interfaces/integration.interfaces';

/** Класс для работы с интеграциями */
export abstract class Integration {
  /** Статус активности интегарции true - активна, false - не активана */
  active!: boolean;
  /** Дата создания интеграции */
  created: Moment.Moment | null = null;
  /** Идентификатор интеграции */
  id: string | null = null;
  /** Название интеграции */
  name!: string;
  /** Тип интеграции */
  abstract type: INTEGRATION_TYPES;

  constructor(integrationExternal?: IntegrationExternal) {
    // Если интеграция создается из внешнего формата, обновим базовые поля, иначе они останутся дефолтными
    if (integrationExternal) {
      this.update(integrationExternal);
    }
  }

  /** Получение базовых полей интеграции в вншенем формате */
  get baseExternalFormat(): IntegrationExternal {
    return {
      created: this.created! && this.created.unix(),
      type: this.type,
      id: this.id!,
      active: this.active,
      name: this.name,
    };
  }

  /** Получение внешнего формата интеграции */
  abstract get externalFormat(): IntegrationExternal;

  /**
   * Обновление базовых полей интеграции
   *
   * @param integrationExternal - Интеграция в вншенем формате
   */
  update(integrationExternal: IntegrationExternal): void {
    this.created = Moment(integrationExternal.created * 1000);

    this.type = integrationExternal.type;

    this.id = integrationExternal.id;
    this.active = integrationExternal.active;
    this.name = integrationExternal.name;
  }
}

/** Класс для работы с интегарциями, которые настраиваются через settings */
export abstract class SettingsIntegration<SettingsExternal> extends Integration {
  /** Настройки интеграции */
  abstract settings: SettingsExternal;

  /**
   * Обновление настроек интегарции
   *
   * @param settings - Настройки интеграции в внешнем формате
   */
  updateSettings(settings: SettingsExternal): void {
    this.settings = settings;
  }
}

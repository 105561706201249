<ng-container *transloco="let t; read: 'calendlyComponent'">
  <div class="card align-items-center">
    <div class="card-body">
      <h3 class="mt-10">
        {{
          t('heading', {
            integrationTypeName: integrationTypeName,
          })
        }}
      </h3>
      <p class="mt-10">
        {{
          t('description', {
            integrationTypeName: integrationTypeName,
          })
        }}
      </p>

      <cq-o-auth-button
        class="w-100 mt-15"
        [isAuthorized]="isAuthorized"
        [oAuthUrl]="oAuthUrl"
      >
        {{
          t('signInBtn', {
            integrationTypeName: integrationTypeName,
          })
        }}
      </cq-o-auth-button>

      <ng-container *ngIf="isAuthorized">
        <hr />

        <h3>{{ t('authenticatedInfo.heading') }}</h3>

        <p
          class="mb-15"
          [innerHTML]="t('authenticatedInfo.description')"
        ></p>

        <a
          type="button"
          class="btn btn-primary"
          uiSref="app.content.messagesAjs.chatBot"
        >
          {{ t('authenticatedInfo.chatBotBtn') }}
        </a>

        <hr />

        <cq-integration-name-control [control]="nameControl"></cq-integration-name-control>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="isAuthorized">
    <div class="text-right">
      <button
        class="btn btn-primary btn-lg"
        type="button"
        (click)="save()"
      >
        {{ 'models.integration.actions.save' | transloco }}
      </button>
    </div>
  </ng-container>
</ng-container>

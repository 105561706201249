import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { IntegrationService } from '@http/integration/services/integration.service';
import { IntegrationIconModule } from '@panel/app/pages/integrations/integration-icon/integration-icon.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { IntegrationCardComponent } from './integration-card.component';

@NgModule({
  declarations: [IntegrationCardComponent],
  exports: [IntegrationCardComponent],
  imports: [CommonModule, IntegrationIconModule, SharedModule, TranslocoModule, UIRouterModule],
  providers: [IntegrationService],
})
export class IntegrationCardModule {}

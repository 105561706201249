import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ConversationTagHelpServiceProvider } from '@panel/app/ajs-upgraded-providers';
import { TagCreateModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/tag-create/tag-create.module';

import { TagListEmptyComponent } from './tag-list-empty.component';

@NgModule({
  declarations: [TagListEmptyComponent],
  exports: [TagListEmptyComponent],
  imports: [CommonModule, TranslocoModule, TagCreateModule],
  providers: [ConversationTagHelpServiceProvider],
})
export class TagListEmptyModule {}

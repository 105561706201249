import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { BotActionNextBranchSelectorModule } from '@panel/app/pages/chat-bot/content/branch-editor/partials/bot-action-next-branch-selector/bot-action-next-branch-selector.module';
import { OneTimeFlagService } from '@panel/app/services';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchActionButtonComponent } from './button.component';

@NgModule({
  declarations: [BranchActionButtonComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TranslocoModule,
    BotActionNextBranchSelectorModule,
    NgbTooltipModule,
  ],
  exports: [BranchActionButtonComponent],
  providers: [CarrotquestHelperProvider, OneTimeFlagService],
})
export class BranchActionButtonModule {}

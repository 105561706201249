import { createWidget } from '@typeform/embed';
import { firstValueFrom, interval } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import {
  PSEUDO_DIRECTORY_IDS,
  PSEUDO_DIRECTORY_TYPES,
} from '../../../../app/http/message-directory/message-directory.constants';
import { MESSAGE_STATUSES } from '../../../../app/http/message/message.constants';
import { BILLING_ADD_ONS } from '../../../../app/services/billing-info/billing-info.constants';
import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';
import { CHAT_BOT_TYPE } from '../../../../app/http/chat-bot/types/chat-bot-external.types';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

const idsTypeformQuiz = {
  RU: 'fEvUmqoA',
  US: 'CQmBc00B',
};

(function () {
  'use strict';

  const isEqual = require('lodash/isEqual');

  angular.module('myApp.chatBot').controller('cqChatBotListController', cqChatBotListController);

  function cqChatBotListController(
    $filter,
    $scope,
    $state,
    $stateParams,
    $translate,
    $uibModal,
    toastr,
    CHAT_BOT_FILTER_TYPES,
    CHAT_BOT_TYPES_BY_FILTER_TYPE,
    INTEGRATION_TYPES,
    PANEL_URL,
    PROJECT_NAME,
    carrotquestHelper,
    billingInfoModel,
    chatBotModel,
    featureModel,
    l10nHelper,
    messageModel,
    paywallService,
    planVersionService,
    planFeatureAccessService,
    systemError,
    starterGuideModel,
  ) {
    var vm = this;

    /**
     * Со сколькими сообщениями совершается действие
     *
     * @type {{ALL: string, ONE: string, SEVERAL: string}}
     */
    var COUNT_ACTION_TYPE = {
      ONE: 'one', // с одним
      SEVERAL: 'several', // с несколькими
      ALL: 'all', // со всеми
    };

    vm.$onInit = init;

    function init() {
      vm.activeBotsAmount =
        vm.dataAboutActiveFacebookBots.amount +
        vm.dataAboutActiveLeadBots.amount +
        vm.dataAboutActiveWelcomeBots.amount +
        vm.dataAboutActiveWidgetBots.amount;

      /** Доступ до AI-бота */
      vm.accessToAiBot = planFeatureAccessService.getAccess(PLAN_FEATURE.AI_BOT, vm.currentApp);

      vm.accessToChatBotsMassActivation = planFeatureAccessService.getAccess(
        PLAN_FEATURE.CHAT_BOTS_MASS_ACTIVATION,
        vm.currentApp,
      );
      updateAccessesToBots();

      trackEnterOnPage();

      vm.availableChatBotFilters = getAvailableChatBotFilters();
      vm.CHAT_BOT_FILTER_TYPES = CHAT_BOT_FILTER_TYPES;
      vm.CHAT_BOT_TYPE = CHAT_BOT_TYPE;
      vm.CHAT_BOT_TYPES_BY_FILTER_TYPE = CHAT_BOT_TYPES_BY_FILTER_TYPE;
      vm.chatBots = []; // список загруженных ботов
      vm.widgetBots = []; // список widget ботов
      vm.telegramBotList = []; // Список загруженных Telegram-ботов
      vm.chatBotsPaginator = null; // пагинация ботов
      vm.widgetBotsPaginator = null; // пагинация widget ботов
      vm.changeFacebookBotActivityStatus = changeFacebookBotActivityStatus;
      vm.changeTelegramBotActivityStatus = changeTelegramBotActivityStatus;
      vm.changeRoutingBotActivityStatus = changeRoutingBotActivityStatus;
      vm.changeWidgetBotActivityStatus = changeWidgetBotActivityStatus;
      vm.checkStatusMessagesToDisable = checkStatusMessagesToDisable;
      vm.copyLeadBotToTelegramBot = copyLeadBotToTelegramBot;
      vm.copyWelcomeBotToTelegramBot = copyWelcomeBotToTelegramBot;
      vm.copyToWelcomeBot = copyToWelcomeBot;
      vm.createCopy = createCopy;
      vm.createTelegramBotCopy = createTelegramBotCopy;
      vm.currentStatus = getInitialStatus(); // фильтр автосообщений по статусу
      vm.currentWidgetBotFilterStatus = MESSAGE_STATUSES.ACTIVE; // фильтр автосообщений по статусу
      vm.getChatBots = getChatBots;
      vm.getChatBotFilterIcon = getChatBotFilterIcon;
      vm.getChatBotFilterTranslate = getChatBotFilterTranslate;
      vm.getOpenedWebPageComparisonPrettyName = getOpenedWebPageComparisonPrettyName;
      vm.getOpenedSdkPageComparisonPrettyName = getOpenedSdkPageComparisonPrettyName;
      vm.getTelegramIntegrationNameById = getTelegramIntegrationNameById;
      vm.getWidgetBotList = getWidgetBotList;
      vm.hasChatBotFilters = hasChatBotFilters;
      vm.INTEGRATION_TYPES = INTEGRATION_TYPES;
      vm.isChatBotHaveFiltersByUrl = isChatBotHaveFiltersByUrl;
      vm.isMessageHaveDeletedTags = isMessageHaveDeletedTags;
      vm.isMessageHaveFilters = isMessageHaveFilters;
      vm.isLeadbotHasOpenedPageTriggerTypes = isLeadbotHasOpenedPageTriggerTypes;
      vm.isLeadBotsHasTriggersPopoverEnabled = isLeadBotsHasTriggersPopoverEnabled;
      vm.isRequestPerformed = false; // Флаг выполнения запроса на получение сообщений в текущий момент времени
      vm.isShowTelegramBotAccessDenialBanner = isShowTelegramBotAccessDenialBanner;
      vm.isShowAppearanceButton = isShowAppearanceButton;
      vm.isShowConnectFacebookPageBanner = isShowConnectFacebookPageBanner;
      vm.isShowConnectTelegramPageBanner = isShowConnectTelegramPageBanner;
      vm.isShowOnboardingQuiz = isShowOnboardingQuiz();
      vm.isShowCreateTelegramBotZeroData = !vm.telegramBotAllList.length;
      vm.MESSAGE_STATUSES = MESSAGE_STATUSES;
      vm.openSettingsModal = openSettingsModal;
      vm.renameChatBot = renameChatBot;
      vm.renameTelegramBot = renameTelegramBot;
      vm.showPaywall = showPaywall;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.refreshChatBots = refreshChatBots;
      vm.refreshSelectedChatBots = refreshSelectedChatBots;
      vm.selectedChatBots = []; //Выделенные сообщений
      vm.selectedChatBotsAll = false; //выделены ли все чат-бот сообщения
      vm.selectedChatBotTypesFilter = getInitialSelectedChatBotTypesFilter(); // Начальное значение фильтра по типу бота
      vm.selectedTelegramIntegration = vm.telegramIntegrationList.filter((integration) => integration.id === null)[0];
      vm.setActive = angular.bind(null, changeChatBotsStatus, true);
      vm.setPaused = angular.bind(null, changeChatBotsStatus, false);
      vm.setSelectForAllMessages = setSelectForAllMessages;
      vm.trackClickOnAppearance = trackClickOnAppearance;
      vm.trackClickOnCreate = trackClickOnCreate;
      vm.trackClickOnCurrentStatus = trackClickOnCurrentStatus;
      vm.trackClickOnKnowledgeBaseInDescription = trackClickOnKnowledgeBaseInDescription;
      vm.trackClickOnNeedMoreFacebookTriggersBtn = trackClickOnNeedMoreFacebookTriggersBtn;
      vm.trackClickOnStatsChatBot = trackClickOnStatsChatBot;
      vm.trackClickRefresh = trackClickRefresh;
      vm.isShowCreateBotZeroData = !!!vm.chatBotsAll.chatBots.length;

      $scope.$watchGroup(
        [
          'vm.currentStatus',
          'vm.selectedChatBotTypesFilter',
          'vm.selectedTelegramIntegration',
          'vm.currentWidgetBotFilterStatus',
        ],
        onFiltersChange,
      );

      // Если необходимо показать квиз, то ждём рендера контейнера и инициализируем в нём виджет Typeform
      if (vm.isShowOnboardingQuiz) {
        interval(500)
          .pipe(
            takeWhile(() => !!document.getElementById('typeform-container'), true),
            take(1),
          )
          .subscribe(() => {
            createWidget(idsTypeformQuiz[l10nHelper.currentCountry], {
              container: document.getElementById('typeform-container'),
              onReady: trackEnterOnChatBotQuiz,
              onSubmit: trackSubmitChatBotQuiz,
            });
          });
      }

      /**
       * Вызывается на изменение фильтрации ботов по типу и статусу
       *
       * @param newValues
       */
      function onFiltersChange(newValues) {
        const newStatus = newValues[0];
        const newSelectedChatBotType = newValues[1];
        const newSelectedTelegramIntegration = newValues[2];
        const currentWidgetBotFilterStatus = newValues[3];

        switch (newSelectedChatBotType) {
          case CHAT_BOT_FILTER_TYPES.TELEGRAM:
            getTelegramBotList(newStatus, newSelectedTelegramIntegration.id, true);
            break;
          case CHAT_BOT_FILTER_TYPES.WEB_AND_MOBILE:
            getChatBots(newStatus, true);
            break;
          case CHAT_BOT_FILTER_TYPES.WIDGET:
            getWidgetBotList(currentWidgetBotFilterStatus, true);
            break;
        }

        vm.selectedChatBotsAll = false;
        vm.selectedChatBots = [];
      }
    }

    /**
     * Установка статуса автосообщению
     *
     * @param {Boolean} status - Статус на который будет изменен чат-бот
     * @param {Array} chatBots - Массив чат-ботов или чат-бот
     */
    function changeChatBotsStatus(status, chatBots) {
      var executedFunction;
      var actionType = getCountActionType(chatBots);
      var isShowConfirmModal = !(actionType === COUNT_ACTION_TYPE.ONE && status); // Надо ли показывать модалку с подтверждением

      if (isShowConfirmModal) {
        var heading, body;
        var confirmButtonText = status
          ? $translate.instant('chatBot.list.changeMessageStatusModal.activate.confirmButtonText')
          : $translate.instant('chatBot.list.changeMessageStatusModal.pause.confirmButtonText');

        if (actionType === COUNT_ACTION_TYPE.ONE) {
          if (!status) {
            heading = $translate.instant('chatBot.list.changeMessageStatusModal.pause.forOne.heading');
            body = $translate.instant('chatBot.list.changeMessageStatusModal.pause.forOne.body');
          }
        } else if (actionType === COUNT_ACTION_TYPE.SEVERAL) {
          if (status) {
            heading = $translate.instant('chatBot.list.changeMessageStatusModal.activate.forSeveral.heading');
            body = $translate.instant(
              'chatBot.list.changeMessageStatusModal.activate.forSeveral.body',
              { selectedChatBots: chatBots.length },
              'messageformat',
            );
          } else {
            heading = $translate.instant('chatBot.list.changeMessageStatusModal.pause.forSeveral.heading');
            body = $translate.instant(
              'chatBot.list.changeMessageStatusModal.pause.forSeveral.body',
              { selectedChatBots: chatBots.length },
              'messageformat',
            );
          }
        } else if (actionType === COUNT_ACTION_TYPE.ALL) {
          if (status) {
            heading = $translate.instant('chatBot.list.changeMessageStatusModal.activate.forAll.heading');

            if (vm.currentStatus === MESSAGE_STATUSES.ALL) {
              body = $translate.instant('chatBot.list.changeMessageStatusModal.activate.forAll.body1');
            } else if (vm.currentStatus === MESSAGE_STATUSES.STOPPED) {
              body = $translate.instant('chatBot.list.changeMessageStatusModal.activate.forAll.body2');
            }
          } else {
            heading = $translate.instant('chatBot.list.changeMessageStatusModal.pause.forAll.heading');
            if (vm.currentStatus === MESSAGE_STATUSES.ALL) {
              body = $translate.instant('chatBot.list.changeMessageStatusModal.pause.forAll.body1');
            } else if (vm.currentStatus === MESSAGE_STATUSES.ACTIVE) {
              body = $translate.instant('chatBot.list.changeMessageStatusModal.pause.forAll.body2');
            }
          }
        }

        var changeActiveModal = $uibModal.open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: heading,
                body: body,
                confirmButtonText: confirmButtonText,
              };
            },
          },
          templateUrl: 'js/shared/modals/confirm/confirm.html',
        });

        changeActiveModal.result.then(executeChangingStatus);
      } else {
        executeChangingStatus();
      }

      function executeChangingStatus() {
        if (status) {
          trackSetActiveLeadBot();
        } else {
          trackSetPauseLeadBot();
        }

        if (actionType === COUNT_ACTION_TYPE.ONE) {
          if (status) {
            executedFunction = angular.bind(null, messageModel.setActive, vm.currentApp.id, false, chatBots[0].id);
          } else {
            executedFunction = angular.bind(null, messageModel.setPaused, vm.currentApp.id, false, chatBots[0].id);
          }
        } else if (actionType === COUNT_ACTION_TYPE.SEVERAL) {
          if (status) {
            executedFunction = angular.bind(
              null,
              messageModel.setActive,
              vm.currentApp.id,
              false,
              $filter('map')(chatBots, 'id'),
            );
          } else {
            executedFunction = angular.bind(
              null,
              messageModel.setPaused,
              vm.currentApp.id,
              false,
              $filter('map')(chatBots, 'id'),
            );
          }
        } else if (actionType === COUNT_ACTION_TYPE.ALL) {
          if (status) {
            executedFunction = angular.bind(
              null,
              messageModel.setActive,
              vm.currentApp.id,
              false,
              null,
              MESSAGE_PART_TYPES.LEAD_BOT,
              vm.currentType,
              PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY],
            );
          } else {
            executedFunction = angular.bind(
              null,
              messageModel.setPaused,
              vm.currentApp.id,
              false,
              null,
              MESSAGE_PART_TYPES.LEAD_BOT,
              vm.currentType,
              PSEUDO_DIRECTORY_IDS[PSEUDO_DIRECTORY_TYPES.ALL_DIRECTORY],
            );
          }
        }

        firstValueFrom(executedFunction()).then(changeActiveSuccess);
      }

      function changeActiveSuccess(data) {
        // FIXME: Это должно быть, разумеется, не в success-колбэке, а в error, но сервер присылает success, и делает всё правильно.
        //  По идее, в модели при наличии поля errored должен возвращаться $q.reject(), но этого не делается из-за того, что придётся соблюдать формат объекта с ошибкой, которые обычно возвращает сервер.
        //  Но формирование объекта с ошибкой ещё большие костыли, чем просто обработать errored в success-колбэке
        if (data.errored.expiredMessages || data.errored.invalidChatBots || data.errored.invalidCapabilities) {
          var totalErroredChatBots =
            data.errored.expiredMessages + data.errored.invalidChatBots + data.errored.invalidCapabilities;
          var totalChatBots = totalErroredChatBots + data.updated;

          if (totalChatBots === 1 && !data.errored.invalidChatBots && !data.errored.expiredMessages) {
            toastr.error($translate.instant('chatBot.list.toasts.messageCapabilityNotAvailable'), {
              allowHtml: true,
              extendedTimeOut: 0,
              onTap: () => {
                showPaywall(vm.accessToChatBotsMassActivation.denialReason);

                toastr.clear();
              },
              tapToDismiss: false,
              timeOut: 8000,
            });
          } else if (totalChatBots === 1 && !data.errored.invalidChatBots) {
            toastr.error($translate.instant('chatBot.list.toasts.expiredMessageError'));
          } else if (totalChatBots === 1 && !data.errored.expiredMessages) {
            toastr.error($translate.instant('chatBot.list.toasts.chatBotValidationMessageError'));
          } else if (totalChatBots > 1 && !data.errored.invalidChatBots && !data.errored.expiredMessages) {
            toastr.error(
              $translate.instant(
                'chatBot.list.toasts.messagesCapabilityNotAvailable',
                {
                  erroredMessagesCount: totalErroredChatBots,
                  totalMessagesCount: totalChatBots,
                },
                'messageformat',
              ),
              {
                allowHtml: true,
                extendedTimeOut: 0,
                onTap: () => {
                  showPaywall(vm.accessToChatBotsMassActivation.denialReason);

                  toastr.clear();
                },
                tapToDismiss: false,
                timeOut: 8000,
              },
            );
          } else if (totalChatBots > 1 && !data.errored.invalidChatBots) {
            toastr.error(
              $translate.instant(
                'chatBot.list.toasts.expiredMessagesError',
                {
                  erroredChatBotsCount: totalErroredChatBots,
                  totalChatBotsCount: totalChatBots,
                },
                'messageformat',
              ),
            );
          } else if (totalChatBots > 1 && !data.errored.expiredMessages) {
            toastr.error(
              $translate.instant(
                'chatBot.list.toasts.chatBotValidationMessagesError',
                {
                  erroredChatBotsCount: totalErroredChatBots,
                  totalChatBotsCount: totalChatBots,
                },
                'messageformat',
              ),
            );
          } else if (totalChatBots > 1) {
            toastr.error(
              $translate.instant(
                'chatBot.list.toasts.activatedMessagesError',
                {
                  erroredChatBotsCount: totalErroredChatBots,
                  totalChatBotsCount: totalChatBots,
                },
                'messageformat',
              ),
            );
          }
        } else if (data.updated > 1) {
          if (status) {
            toastr.success(
              $translate.instant(
                'chatBot.list.toasts.activatedMessagesSuccess',
                { messagesCount: data.updated },
                'messageformat',
              ),
            );

            increaseActiveBotsAmount(vm.currentApp, data.updated);

            updateAccessesToBots();

            starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.LEADS_PASSING).subscribe();
          } else {
            toastr.success(
              $translate.instant(
                'chatBot.list.toasts.pausedMessagesSuccess',
                { messagesCount: data.updated },
                'messageformat',
              ),
            );

            decreaseActiveBotsAmount(vm.currentApp, data.updated);

            updateAccessesToBots();
          }
        } else {
          if (status) {
            toastr.success($translate.instant('chatBot.list.toasts.activatedMessageSuccess'));

            increaseActiveBotsAmount(vm.currentApp, 1);

            updateAccessesToBots();
          } else {
            toastr.success($translate.instant('chatBot.list.toasts.pausedMessageSuccess'));

            decreaseActiveBotsAmount(vm.currentApp, 1);

            updateAccessesToBots();
          }
        }

        vm.selectedChatBots = [];
        vm.selectedChatBotsAll = false;
        getChatBots(vm.currentStatus, true);
      }
    }

    /**
     * Смена статуса активности Facebook-бота
     */
    function changeFacebookBotActivityStatus() {
      const statusToChange = !vm.facebookBot.active;

      if (statusToChange) {
        trackSetActiveFacebookBot();
      } else {
        trackSetPauseFacebookBot();
      }

      return firstValueFrom(
        chatBotModel.saveFacebookBot(vm.currentApp.id, {
          ...vm.facebookBot,
          active: !vm.facebookBot.active,
        }),
      )
        .then(({ chatBot }) => {
          vm.facebookBot.active = chatBot.active;

          if (statusToChange) {
            increaseActiveBotsAmount(vm.currentApp, 1, vm.CHAT_BOT_TYPE.FACEBOOK);
          } else {
            decreaseActiveBotsAmount(vm.currentApp, 1, vm.CHAT_BOT_TYPE.FACEBOOK);
          }

          updateAccessesToBots();
        })
        .catch((response) => {
          if (response.error === 'CapabilityNotAllowed') {
            toastr.error($translate.instant('chatBot.list.toasts.messageCapabilityNotAvailable'), {
              allowHtml: true,
              extendedTimeOut: 0,
              onTap: () => {
                showPaywall(vm.accessToFacebookBots.denialReason);
                toastr.clear();
              },
              tapToDismiss: false,
              timeOut: 8000,
            });
          }
        });
    }

    /**
     * Смена статуса активности Telegram-бота
     *
     * @param {TelegramBot} telegramBot
     */
    function changeTelegramBotActivityStatus(telegramBot) {
      const statusToChange = !telegramBot.active;

      if (statusToChange) {
        trackSetActiveTelegramBot();
      } else {
        trackSetPauseTelegramBot();
      }

      if (statusToChange) {
        const similarTriggerAndIntegrationBot = vm.telegramBotAllList.filter(
          (bot) =>
            bot.active &&
            bot.triggerTypes[0] === telegramBot.triggerTypes[0] &&
            bot.integration === telegramBot.integration,
        )[0];

        if (similarTriggerAndIntegrationBot) {
          const modal = $uibModal.open({
            controller: 'ConfirmModalController',
            controllerAs: 'vm',
            templateUrl: 'js/shared/modals/confirm/confirm.html',
            size: 'md modal-dialog-centered',
            resolve: {
              modalWindowParams: function () {
                return {
                  heading: $translate.instant('chatBot.list.telegramBot.alreadyActiveTelegramBotModal.heading'),
                  body: $translate.instant('chatBot.list.telegramBot.alreadyActiveTelegramBotModal.body', {
                    currentTelegramBotName: telegramBot.name,
                    alreadyActiveTelegramBotName: similarTriggerAndIntegrationBot.name,
                    alreadyActiveTelegramBotUrl: getTelegramBotUrl(similarTriggerAndIntegrationBot),
                  }),
                  confirmButtonText: $translate.instant(
                    'chatBot.list.telegramBot.alreadyActiveTelegramBotModal.confirmButtonText',
                  ),
                };
              },
            },
          });

          return modal.result
            .then(() => saveTelegramBot({ ...telegramBot, active: !telegramBot.active }))
            .then(() => {
              similarTriggerAndIntegrationBot.active = !similarTriggerAndIntegrationBot.active;
            })
            .catch(() => {});
        }
      }

      return saveTelegramBot({ ...telegramBot, active: !telegramBot.active });
    }

    /**
     * Сохранение Telegram-бота
     *
     * @param {TelegramBot} telegramBot
     * @return {angular.IPromise<void[]>}
     */
    function saveTelegramBot(telegramBot) {
      return firstValueFrom(chatBotModel.saveTelegramBot(vm.currentApp.id, telegramBot))
        .then(({ chatBot }) => {
          increaseActiveBotsAmount(vm.currentApp, chatBot.active ? 1 : -1, CHAT_BOT_TYPE.TELEGRAM);
          telegramBot.active = chatBot.active;

          const botInAllList = vm.telegramBotAllList.filter((bot) => bot.id === telegramBot.id)[0];
          botInAllList.active = chatBot.active;

          getTelegramBotList(vm.currentStatus, vm.selectedTelegramIntegration.id, true);
          updateAccessesToBots();
        })
        .catch((response) => {
          if (response.error === 'CapabilityNotAllowed') {
            toastr.error($translate.instant('chatBot.list.toasts.messageCapabilityNotAvailable'), {
              allowHtml: true,
              extendedTimeOut: 0,
              onTap: () => {
                showPaywall(vm.accessToTelegramBots.denialReason);
                toastr.clear();
              },
              tapToDismiss: false,
              timeOut: 8000,
            });
          }

          if (response.error === 'ValidationError') {
            if (response.errorFields?.integration || response.errorFields?.integrationInactive) {
              $state.go('app.content.messagesAjs.telegramBot.edit', { telegramBotId: telegramBot.id });
            }
          }
        });
    }

    /**
     * Смена статуса активности роутинг бота
     */
    function changeRoutingBotActivityStatus() {
      const statusToChange = !vm.routingBot.active;

      openChangeRoutingBotActiveStatusConfirmModal(statusToChange).then((val) => {
        if (!val) {
          return;
        }

        if (statusToChange) {
          trackSetActiveRoutingBot();
        } else {
          trackSetPauseRoutingBot();
        }

        return firstValueFrom(
          chatBotModel.saveRoutingBot(vm.currentApp.id, {
            ...vm.routingBot,
            active: !vm.routingBot.active,
          }),
        )
          .then(({ chatBot }) => {
            vm.routingBot.active = chatBot.active;

            if (statusToChange) {
              increaseActiveBotsAmount(vm.currentApp, 1, vm.CHAT_BOT_TYPE.ROUTING);
            } else {
              decreaseActiveBotsAmount(vm.currentApp, 1, vm.CHAT_BOT_TYPE.ROUTING);
            }

            updateAccessesToBots();
          })
          .catch((response) => {
            if (response.error === 'CapabilityNotAllowed') {
              toastr.error($translate.instant('chatBot.list.toasts.messageCapabilityNotAvailable'), {
                allowHtml: true,
                extendedTimeOut: 0,
                onTap: () => {
                  showPaywall(vm.accessToWelcomeBots.denialReason);
                  toastr.clear();
                },
                tapToDismiss: false,
                timeOut: 8000,
              });
            }

            systemError.somethingWentWrongToast.show();
            throw new Error(response);
          });
      });
    }

    /**
     * Смена статуса активности Widget бота
     *
     * @param {WidgetBot} widgetBot
     */
    function changeWidgetBotActivityStatus(widgetBot) {
      const statusToChange = !widgetBot.active;

      if (statusToChange) {
        trackSetActiveWidgetBot();
      } else {
        trackSetPauseWidgetBot();
      }
      return firstValueFrom(chatBotModel.saveWidgetBot(vm.currentApp.id, { ...widgetBot, active: statusToChange }))
        .then(({ chatBot }) => {
          increaseActiveBotsAmount(vm.currentApp, chatBot.active ? 1 : -1, CHAT_BOT_TYPE.WIDGET);
          widgetBot.active = chatBot.active;

          getWidgetBotList(vm.currentWidgetBotFilterStatus, true);
          updateAccessesToBots();
        })
        .catch((response) => {
          if (response.error === 'CapabilityNotAllowed') {
            toastr.error($translate.instant('chatBot.list.toasts.messageCapabilityNotAvailable'), {
              allowHtml: true,
              extendedTimeOut: 0,
              onTap: () => {
                showPaywall(vm.accessToLeadBots.denialReason);
                toastr.clear();
              },
              tapToDismiss: false,
              timeOut: 8000,
            });
          }

          if (response.error === 'ValidationError') {
            toastr.error($translate.instant('chatBot.list.toasts.widgetBotValidationMessageError'));
          }
        });
    }

    /**
     * Проверка статуса выделенных сообщений на идентичность
     *
     * @param {Boolean} active - Флаг для актывных/приостановленных сообщений
     * @returns {Boolean}
     */
    function checkStatusMessagesToDisable(active) {
      //если выбранны активные сообщения то действие с активацией должно быть задизеблено и наоборот
      if (
        (vm.currentStatus === MESSAGE_STATUSES.ACTIVE && active) ||
        (vm.currentStatus === MESSAGE_STATUSES.STOPPED && !active)
      ) {
        return true;
      } else {
        //если выделены все сообщения и загружены не все сообщения, то дизейблить ничего не надо т.к. мы не можем знать какие статусы у незагруженных сообщений
        if (vm.selectedChatBotsAll && vm.chatBotsPaginator.paginatePosition) {
          return false;
        }
        //если кол-во выделенных сообщений со статусом "active" соответствует общему количеству выделенных сообщений, то надо задизеблить
        var activeMessagesLength = $filter('filter')(vm.selectedChatBots, { active: active }, true).length;
        return vm.selectedChatBots.length === activeMessagesLength;
      }
    }

    function copyLeadBotToTelegramBot(chatBotMessage) {
      carrotquestHelper.track('Чат-бот - Нажал на "Скопировать Telegram-бота из лид-бота"');

      $state.go('app.content.messagesAjs.telegramBot.createFromLeadBot', { leadBotId: chatBotMessage.id });
    }

    function copyWelcomeBotToTelegramBot() {
      carrotquestHelper.track('Чат-бот - Нажал на "Скопировать Telegram-бота из Welcome-бота"');

      $state.go('app.content.messagesAjs.telegramBot.createFromWelcomeBot');
    }

    /**
     * @param {Message} chatBotMessage
     */
    function copyToWelcomeBot(chatBotMessage) {
      carrotquestHelper.track('Чат-бот - Нажал на "Скопировать welcome-бота из лид-бота"');
      if (vm.routingBot?.id) {
        $state.go('app.content.messagesAjs.routingBot.editFromLeadBot', {
          routingBotId: vm.routingBot.id,
          chatBotMessageId: chatBotMessage.id,
        });
      } else {
        $state.go('app.content.messagesAjs.routingBot.createFromLeadBot', { chatBotMessageId: chatBotMessage.id });
      }
    }

    /**
     * Переход на состояние создания копии
     */
    function createCopy(chatBot) {
      vm.selectedChatBots.length <= 1 &&
        $state.go('app.content.messagesAjs.chatBot.edit.copy', { chatBotId: chatBot.id });
    }

    /**
     * Переход на состояние создания копии Телеграм-бота
     *
     * @param telegramBot
     */
    function createTelegramBotCopy(telegramBot) {
      $state.go('app.content.messagesAjs.telegramBot.edit.copy', { telegramBotId: telegramBot.id });
    }

    /**
     * Уменьшение количества активных ботов
     *
     * @param currentApp - Текущее приложение
     * @param decreaseAmount - Количество на которое уменьшить
     * @param botType - Тип бота
     */
    function decreaseActiveBotsAmount(currentApp, decreaseAmount, botType) {
      if (planVersionService.isV3(currentApp)) {
        if (!botType) {
          vm.dataAboutActiveLeadBots.amount = vm.dataAboutActiveLeadBots.amount - decreaseAmount;
          return;
        }

        switch (botType) {
          case vm.CHAT_BOT_TYPE.FACEBOOK:
            vm.dataAboutActiveFacebookBots.amount = vm.dataAboutActiveFacebookBots.amount - decreaseAmount;
            return;
          case vm.CHAT_BOT_TYPE.LEAD:
            vm.dataAboutActiveLeadBots.amount = vm.dataAboutActiveLeadBots.amount - decreaseAmount;
            return;
          case vm.CHAT_BOT_TYPE.ROUTING:
            vm.dataAboutActiveWelcomeBots.amount = vm.dataAboutActiveWelcomeBots.amount - decreaseAmount;
            return;
          case vm.CHAT_BOT_TYPE.TELEGRAM:
            vm.dataAboutActiveTelegramBots.amount = vm.dataAboutActiveTelegramBots.amount - decreaseAmount;
            return;
          case vm.CHAT_BOT_TYPE.WIDGET:
            vm.dataAboutActiveWidgetBots.amount = vm.dataAboutActiveWidgetBots.amount - decreaseAmount;
            return;
        }
      }

      vm.activeBotsAmount = vm.activeBotsAmount - decreaseAmount;
    }

    /**
     * Получение количества активных Facebook ботов
     *
     * @param currentApp - Текущее приложение
     *
     * @return {number}
     */
    function getActiveFacebookBotsAmount(currentApp) {
      switch (true) {
        case planVersionService.isV1(currentApp):
        case planVersionService.isV2(currentApp):
          return vm.activeBotsAmount;
        default:
          return vm.dataAboutActiveLeadBots.amount;
      }
    }

    /**
     * Получение количества активных Lead ботов
     *
     * @param currentApp - Текущее приложение
     *
     * @return {number}
     */
    function getActiveLeadBotsAmount(currentApp) {
      switch (true) {
        case planVersionService.isV1(currentApp):
        case planVersionService.isV2(currentApp):
          return vm.activeBotsAmount;
        default:
          return (
            vm.dataAboutActiveLeadBots.amount +
            vm.dataAboutActiveFacebookBots.amount +
            vm.dataAboutActiveWidgetBots.amount
          );
      }
    }

    /**
     * Получение количества активных Telegram ботов
     *
     * @param currentApp - Текущее приложение
     *
     * @return {number}
     */
    function getActiveTelegramBotsAmount(currentApp) {
      return vm.dataAboutActiveTelegramBots.amount;
    }

    /**
     * Получение количества активных Welcome ботов
     *
     * @param currentApp - Текущее приложение
     *
     * @return {number}
     */
    function getActiveWelcomeBotsAmount(currentApp) {
      switch (true) {
        case planVersionService.isV1(currentApp):
        case planVersionService.isV2(currentApp):
          return vm.activeBotsAmount;
        default:
          return vm.dataAboutActiveWelcomeBots.amount;
      }
    }

    /** Получение всех доступных типов ботов в тарифе и по фичегалке */
    function getAvailableChatBotFilters() {
      const availableChatBotFiltersArray = [];

      availableChatBotFiltersArray.push(CHAT_BOT_FILTER_TYPES.WEB_AND_MOBILE);
      availableChatBotFiltersArray.push(CHAT_BOT_FILTER_TYPES.TELEGRAM);

      if (featureModel.hasAccess(FEATURES.FACEBOOK_BOT)) {
        availableChatBotFiltersArray.push(CHAT_BOT_FILTER_TYPES.FACEBOOK);
      }

      if (featureModel.hasAccess(FEATURES.WIDGET_BOT)) {
        availableChatBotFiltersArray.push(CHAT_BOT_FILTER_TYPES.WIDGET);
      }

      if (vm.accessToAiBot.hasAccess || featureModel.hasAccess(FEATURES.CHAT_GPT)) {
        availableChatBotFiltersArray.push(CHAT_BOT_FILTER_TYPES.AI);
      }

      return availableChatBotFiltersArray;
    }

    /**
     * Получение списка Чат-ботов
     *
     * @param {MESSAGE_STATUSES} status Статус сообщения
     * @param {Boolean} forceReload Принудительная перезагрузка таблицы
     */
    function getChatBots(status, forceReload) {
      // если идёт перезагрукзка таблицы сообщений - всё обнуляем
      if (forceReload) {
        vm.chatBots = [];
        vm.chatBotsPaginator = null;
      }

      vm.isRequestPerformed = true;

      firstValueFrom(chatBotModel.getList(vm.currentApp.id, status, vm.chatBotsPaginator))
        .then(getChatBotsSuccess)
        .finally(getChatBotsFinally);

      function getChatBotsSuccess(data) {
        vm.chatBots.push.apply(vm.chatBots, data.chatBots);
        vm.chatBotsPaginator = data.paginatorParams;

        messageModel.parseMessageFilters(forceReload ? vm.chatBots : data.chatBots, vm.properties, vm.tags);
        messageModel.linkWithTriggers(forceReload ? vm.chatBots : data.chatBots, vm.properties.eventTypes);
      }

      function getChatBotsFinally() {
        vm.isRequestPerformed = false;
        setSelectForAllMessages();
      }
    }

    /**
     * Получение CSS-класса иконки для кнопки фильтра по ботам
     *
     * @param {CHAT_BOT_FILTER_TYPES} chatBotFilter Тип фильтра по ботам
     */
    function getChatBotFilterIcon(chatBotFilter) {
      switch (chatBotFilter) {
        case CHAT_BOT_FILTER_TYPES.WEB_AND_MOBILE:
          return 'cqi-comment-out-filled';
        case CHAT_BOT_FILTER_TYPES.TELEGRAM:
          return 'cqi-telegram';
        case CHAT_BOT_FILTER_TYPES.FACEBOOK:
          return 'cqi-facebook';
        case CHAT_BOT_FILTER_TYPES.WIDGET:
          return 'd-none'; // Для embedded-бота нет иконки
        case CHAT_BOT_FILTER_TYPES.AI:
          return 'cqi-stars';
      }
    }

    /**
     * Получение перевода для кнопки фильтра по ботам
     *
     * @param {CHAT_BOT_FILTER_TYPES} chatBotFilter Тип фильтра по ботам
     */
    function getChatBotFilterTranslate(chatBotFilter) {
      switch (chatBotFilter) {
        case CHAT_BOT_FILTER_TYPES.WEB_AND_MOBILE:
          return $translate.instant('chatBot.list.selectedChatBotButtonGroup.websiteAndMobileApp');
        case CHAT_BOT_FILTER_TYPES.TELEGRAM:
          return $translate.instant('chatBot.list.selectedChatBotButtonGroup.telegram', {
            integrationTypeName: $translate.instant(`models.integration.types.${INTEGRATION_TYPES.TELEGRAM}.name`),
          });
        case CHAT_BOT_FILTER_TYPES.FACEBOOK:
          return $translate.instant('chatBot.list.selectedChatBotButtonGroup.facebook', {
            integrationTypeName: $translate.instant(`models.integration.types.${INTEGRATION_TYPES.FACEBOOK}.name`),
          });
        case CHAT_BOT_FILTER_TYPES.WIDGET:
          return $translate.instant('chatBot.list.selectedChatBotButtonGroup.widget');
        case CHAT_BOT_FILTER_TYPES.AI:
          return $translate.instant('chatBot.list.selectedChatBotButtonGroup.ai');
      }
    }

    /**
     * Получение ссылки на редактирование ТГ-бота
     *
     * @param {TelegramBot} telegramBot
     * @return {string}
     */
    function getTelegramBotUrl(telegramBot) {
      return `${PANEL_URL}/${vm.currentApp.id}/messages/telegram-bot/${telegramBot.id}/edit`;
    }

    /**
     * Получение списка Telegram-ботов
     *
     * @param {MESSAGE_STATUSES} status Статус сообщения
     * @param {string} telegramIntegrationId ID интеграции с Telegram, к которой привязан бот
     * @param {Boolean} forceReload Принудительная перезагрузка таблицы
     */
    function getTelegramBotList(status, telegramIntegrationId, forceReload) {
      // если идёт перезагрузка таблицы сообщений - всё обнуляем
      if (forceReload) {
        vm.telegramBotList = [];
      }

      vm.isRequestPerformed = true;

      firstValueFrom(chatBotModel.getTelegramBotList(true, status, telegramIntegrationId))
        .then(getTelegramBotsSuccess)
        .finally(getTelegramBotsFinally);

      function getTelegramBotsSuccess(data) {
        vm.telegramBotList.push.apply(vm.telegramBotList, data);
      }

      function getTelegramBotsFinally() {
        vm.isRequestPerformed = false;
      }
    }

    /**
     * Получение списка Widget-ботов
     *
     * @param {MESSAGE_STATUSES} status Статус сообщения
     * @param {Boolean} forceReload Принудительная перезагрузка таблицы
     */
    function getWidgetBotList(status, forceReload) {
      if (forceReload) {
        vm.widgetBots = [];
        vm.widgetBotsPaginator = null;
      }
      vm.isRequestPerformed = true;

      firstValueFrom(chatBotModel.getWidgetBotList(true, status, vm.widgetBotsPaginator))
        .then(getWidgetBotsSuccess)
        .finally(getWidgetBotsFinally);

      function getWidgetBotsSuccess(data) {
        vm.widgetBots.push.apply(vm.widgetBots, data.bots);
        vm.widgetBotsPaginator = data.paginatePosition;
      }

      function getWidgetBotsFinally() {
        vm.isRequestPerformed = false;
      }
    }

    /** Получение начального выбранного фильтра по типам ботов */
    function getInitialSelectedChatBotTypesFilter() {
      if ($stateParams.filterType && Object.values(CHAT_BOT_FILTER_TYPES).includes($stateParams.filterType)) {
        return $stateParams.filterType;
      }

      if (isCameFromFacebookBot()) {
        return CHAT_BOT_FILTER_TYPES.FACEBOOK;
      }
      if (isCameFromTelegramBot()) {
        return CHAT_BOT_FILTER_TYPES.TELEGRAM;
      }
      if (isCameFromWidgetBot()) {
        return CHAT_BOT_FILTER_TYPES.WIDGET;
      }
      if (isCameFromAiBot()) {
        return CHAT_BOT_FILTER_TYPES.AI;
      }
      return CHAT_BOT_FILTER_TYPES.WEB_AND_MOBILE;
    }

    /**
     * Получение фильтра по статусу
     *
     * @return {MESSAGE_STATUSES}
     */
    function getInitialStatus() {
      if (!billingInfoModel.hasAddOn(vm.billingInfo, BILLING_ADD_ONS.CHAT_BOTS)) {
        return MESSAGE_STATUSES.ALL;
      }

      return MESSAGE_STATUSES.ACTIVE;
    }

    /**
     * Определение для одного, нескольких или всех сообщений будет производиться действие
     *
     * @param {Array} chatBots - Массив ботов
     * @returns {String}
     */
    function getCountActionType(chatBots) {
      if (chatBots.length === 1) {
        //для одного элемента
        return COUNT_ACTION_TYPE.ONE;
      } else if (
        chatBots.length > 1 &&
        (!vm.selectedMessagesAll || (vm.selectedMessagesAll && !vm.chatBotsPaginator.paginatePosition))
      ) {
        //если выделено больше 1 эелемента и загружены все сообщения
        return COUNT_ACTION_TYPE.SEVERAL;
      } else if (vm.selectedMessagesAll && vm.chatBotsPaginator.paginatePosition) {
        //если выделены все и  можно загрузить еще сообщения
        return COUNT_ACTION_TYPE.ALL;
      }
    }

    function getTelegramIntegrationNameById(integrationId) {
      return vm.telegramIntegrationList.filter((integration) => integration.id === integrationId)[0]?.name;
    }

    function getOpenedWebPageComparisonPrettyName(comparison) {
      return $translate.instant(`chatBot.list.triggerTypes.openedWebPage.${comparison}`);
    }

    function getOpenedSdkPageComparisonPrettyName(comparison) {
      return $translate.instant(`chatBot.list.triggerTypes.openedSdkPage.${comparison}`);
    }

    /**
     * Проверка наличия фильтров в чат-боте
     *
     * @param {Object} chatBot - Чат-бот сообщение
     *
     * @returns {boolean}
     */
    function hasChatBotFilters(chatBot) {
      return (
        chatBot.filters.filters.props.length > 0 ||
        chatBot.filters.filters.events.length > 0 ||
        chatBot.filters.filters.tags.length > 0 ||
        chatBot.jinjaFilterTemplate !== null
      );
    }

    /**
     * Увеличение количества активных ботов
     *
     * @param currentApp - Текущее приложение
     * @param increaseAmount - Количество на которое увеличить
     * @param botType - Тип бота
     */
    function increaseActiveBotsAmount(currentApp, increaseAmount, botType) {
      if (planVersionService.isV3(currentApp)) {
        if (!botType) {
          vm.dataAboutActiveLeadBots.amount = vm.dataAboutActiveLeadBots.amount + increaseAmount;
          return;
        }

        switch (botType) {
          case vm.CHAT_BOT_TYPE.FACEBOOK:
            vm.dataAboutActiveFacebookBots.amount = vm.dataAboutActiveFacebookBots.amount + increaseAmount;
            return;
          case vm.CHAT_BOT_TYPE.LEAD:
            vm.dataAboutActiveLeadBots.amount = vm.dataAboutActiveLeadBots.amount + increaseAmount;
            return;
          case vm.CHAT_BOT_TYPE.ROUTING:
            vm.dataAboutActiveWelcomeBots.amount = vm.dataAboutActiveWelcomeBots.amount + increaseAmount;
            return;
          case vm.CHAT_BOT_TYPE.TELEGRAM:
            vm.dataAboutActiveTelegramBots.amount = vm.dataAboutActiveTelegramBots.amount + increaseAmount;
            return;
          case vm.CHAT_BOT_TYPE.WIDGET:
            vm.dataAboutActiveWidgetBots.amount = vm.dataAboutActiveWidgetBots.amount + increaseAmount;
            return;
        }
      }

      vm.activeBotsAmount = vm.activeBotsAmount + increaseAmount;
    }

    /** Был ли произведён переход на страницу с редактора Facebook-бота */
    function isCameFromFacebookBot() {
      return $state.transition.from().name.includes('app.content.messagesAjs.facebookBot');
    }

    /** Был ли произведён переход на страницу с редактора Telegram-бота */
    function isCameFromTelegramBot() {
      return $state.transition.from().name.includes('app.content.messagesAjs.telegramBot');
    }

    /** Был ли произведён переход на страницу с редактора Widget-бота */
    function isCameFromWidgetBot() {
      return $state.transition.from().name.includes('app.content.messagesAjs.widgetBot');
    }

    /** Был ли произведён переход на страницу с настроек AI-бота */
    function isCameFromAiBot() {
      return $state.transition.from().name.includes('app.content.aiDataSources.botSettings');
    }

    /**
     * Проверка на наличие фильтров по URL в чат-боте
     *
     * @param {Object} chatBot - Чат-бот
     *
     * @returns {boolean}
     */
    function isChatBotHaveFiltersByUrl(chatBot) {
      if (!chatBot.sendingFilters) {
        return false;
      }

      if (chatBot.sendingFilters.filters.length > 0) {
        return true;
      }

      return false;
    }

    /**
     * Проверка есть ли у автосообщения удаленные теги
     *
     * @param {Object} tags Массив тегов из фильтров сообщения бота
     * @returns {boolean}
     */
    function isMessageHaveDeletedTags(tags) {
      return $filter('filter')(tags, isTagRemoved, true).length > 0;

      function isTagRemoved(tag) {
        return tag.tag.removed;
      }
    }

    /**
     * Проверка наличия фильтров в автосообщении
     *
     * @param {Object} message Автосообщение
     * @returns {boolean}
     */
    function isMessageHaveFilters(message) {
      return (
        message.filters.filters.props.length > 0 ||
        message.filters.filters.events.length > 0 ||
        message.filters.filters.tags.length > 0
      );
    }

    /**
     * Проверка на наличие новых триггеров в автосообщении
     *
     * @param {Object} chatBot - Автосообщение
     * @returns {Boolean}
     */
    function isLeadbotHasOpenedPageTriggerTypes(openedWebPageTriggers) {
      return openedWebPageTriggers.length;
    }

    /**
     * Проверка на наличие доп параметров в автосообщение для включения поповера
     *
     * @param {Object} chatBot - Автосообщение
     * @returns {Boolean}
     */
    function isLeadBotsHasTriggersPopoverEnabled(chatBot) {
      return (
        chatBot.triggers.length > 1 ||
        vm.hasChatBotFilters(chatBot) ||
        vm.isChatBotHaveFiltersByUrl(chatBot) ||
        vm.isLeadbotHasOpenedPageTriggerTypes(chatBot.triggerTypes.openedWebPageTriggers) ||
        vm.isLeadbotHasOpenedPageTriggerTypes(chatBot.triggerTypes.openedSdkPageTriggers)
      );
    }

    function openSettingsModal() {
      var setUpModal = $uibModal.open({
        component: 'cqLeadBotSettingsModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          sender: angular.bind(null, angular.identity, angular.copy(vm.botSender)),
        },
      });

      setUpModal.result.then(setUpModalSuccess);

      function setUpModalSuccess(settings) {
        angular.extend(vm.botSender, settings);
      }
    }

    /**
     * Открытие одалки подтверждающей мену статуса роутинг бота
     */
    function openChangeRoutingBotActiveStatusConfirmModal(statusToChange) {
      const modal = $uibModal.open({
        component: 'cqChangeRoutingBotActiveStatusConfirmWrapperModal',
        resolve: {
          statusToChange: angular.bind(null, statusToChange),
        },
      });

      return modal.result.then((val) => val).catch(() => false);
    }

    /**
     * Открытие модалки переименования бота
     *
     * @param {string} botName Название бота
     */
    function openRenameModal(botName) {
      return $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('chatBot.list.renameMessageModal.heading'),
              body: $translate.instant('chatBot.list.renameMessageModal.body', { botName }),
              confirmButtonText: $translate.instant('general.save'),
              inputMaxLength: 255,
              inputMaxLengthErrorText: $translate.instant(
                'chatBot.list.renameMessageModal.inputMaxLengthErrorText',
                { maxLength: 255 },
                'messageformat',
              ),
              inputPlaceholder: $translate.instant('chatBot.list.renameMessageModal.inputPlaceholder'),
              inputInitialValue: botName,
            };
          },
        },
        size: 'md modal-dialog-centered',
      });
    }

    /**
     * Показать пейволл
     *
     * @param denialReason - Причина отказа в доступе к фиче
     */
    function showPaywall(denialReason) {
      paywallService.showPaywall(vm.currentApp, denialReason);
    }

    /**
     * Переименование лид-бота
     *
     * @param chatBot Лид-бот
     */
    function renameChatBot(chatBot) {
      const renameChatBotMessageModal = openRenameModal(chatBot.name);

      renameChatBotMessageModal.result.then(saveRenamedChatBotMessage);

      // Обновление названия в списке

      function saveRenamedChatBotMessage(newChatBotMessageName) {
        if (newChatBotMessageName !== chatBot.name) {
          const params = {
            name: newChatBotMessageName,
          };
          firstValueFrom(messageModel.edit(chatBot.id, params)).then(
            angular.bind(null, saveRenamedChatBotMessageSuccess, newChatBotMessageName),
          );
        }
      }

      function saveRenamedChatBotMessageSuccess(newChatBotMessageName) {
        vm.isApiRequestPerformed = true;
        chatBot.name = newChatBotMessageName;
        toastr.success($translate.instant('chatBot.list.toasts.renameMessageSuccess'));
      }
    }

    /**
     * Переименование Телеграм-бота
     *
     * @param telegramBot Телеграм-бот
     */
    function renameTelegramBot(telegramBot) {
      const modal = openRenameModal(telegramBot.name);

      modal.result.then(saveRenamedChatBotMessage);

      function saveRenamedChatBotMessage(newTelegramBotName) {
        if (newTelegramBotName !== telegramBot.name) {
          firstValueFrom(
            chatBotModel.saveTelegramBot(vm.currentApp.id, {
              ...telegramBot,
              name: newTelegramBotName,
            }),
          ).then(angular.bind(null, saveRenamedTelegramBotMessageSuccess, newTelegramBotName));
        }
      }

      function saveRenamedTelegramBotMessageSuccess(newTelegramBotMessageName) {
        vm.isApiRequestPerformed = true;
        telegramBot.name = newTelegramBotMessageName;
        toastr.success($translate.instant('chatBot.list.toasts.renameMessageSuccess'));
      }
    }

    /**
     * Обновление таблицы чат-ботов
     *
     * @param {MESSAGE_STATUSES} status - Статус чат-бота
     */
    function refreshChatBots(status) {
      switch (vm.selectedChatBotTypesFilter) {
        case CHAT_BOT_FILTER_TYPES.TELEGRAM:
          getTelegramBotList(status, vm.selectedTelegramIntegration.id, true);
          break;
        case CHAT_BOT_FILTER_TYPES.WEB_AND_MOBILE:
          getChatBots(status, true);
          break;
        case CHAT_BOT_FILTER_TYPES.WIDGET:
          getWidgetBotList(status, true);
          break;
      }
    }

    /**
     * Обновление выделенных сообщений
     */
    function refreshSelectedChatBots() {
      vm.selectedChatBots = $filter('filter')(vm.chatBots, { checked: true }, true);
    }

    /** Проверка необходимости показа баннера отсутствия доступа к Telegram-ботам в тарифе */
    function isShowTelegramBotAccessDenialBanner() {
      return vm.selectedChatBotTypesFilter === CHAT_BOT_FILTER_TYPES.TELEGRAM && !vm.accessToTelegramBots.hasAccess;
    }

    /**
     * Показывать ли кнопку «Внешний вид ботов»
     *
     * @param {CHAT_BOT_FILTER_TYPES} selectedChatBotTypesFilter Выбранный фильтр по типу ботов
     * @returns {boolean}
     */
    function isShowAppearanceButton(selectedChatBotTypesFilter) {
      return selectedChatBotTypesFilter === CHAT_BOT_FILTER_TYPES.WEB_AND_MOBILE;
    }

    /**
     * Показывать ли баннер с подключением страниц Facebook.
     * Его нужно показать, если нет ни одной подписанной страницы во всех интеграциях Facebook.
     */
    function isShowConnectFacebookPageBanner() {
      const integrationsWithConnectedFacebookPages = vm.facebookActiveIntegrations.filter((integration) => {
        return Object.values(integration.settings.pages || {}).filter((page) => page.subscribed).length > 0;
      });

      return integrationsWithConnectedFacebookPages.length === 0;
    }

    /**
     * Показывать ли баннер с подключением страниц Telegram.
     * Его нужно показать, если нет ни одной подписанной страницы во всех интеграциях Telegram.
     */
    function isShowConnectTelegramPageBanner() {
      const integrationsWithConnectedTelegramPages = vm.telegramIntegrationList.filter((integration) => {
        return integration.active && Object.values(integration.settings || {}).filter((settings) => settings.botToken);
      });

      return integrationsWithConnectedTelegramPages.length === 0;
    }

    /**
     * Показывать или нет зеродату с отсутсвующим списком ботов,
     * когда ботов нет совсем
     * Нужно показать квиз, если пользователь ещё ни разу не создавал ни одного вида бота
     *
     * @returns {boolean}
     */
    function isShowOnboardingQuiz() {
      const dontHaveLeadBots = vm.chatBotsAll.chatBots.length === 0;
      const dontHaveWelcomeBot = !vm.routingBot;
      const dontHaveFacebookBot = !vm.facebookBot;
      const dontHaveTelegramBot = !vm.telegramBotList;
      const hasAccessToChatBotQuizFeature = featureModel.hasAccess(FEATURES.CHAT_BOT_QUIZ);

      return (
        dontHaveLeadBots &&
        dontHaveWelcomeBot &&
        dontHaveFacebookBot &&
        dontHaveTelegramBot &&
        hasAccessToChatBotQuizFeature
      );
    }

    /**
     * Отметить или снять галочки с сообщений
     */
    function setSelectForAllMessages() {
      for (var i = 0; i < vm.chatBots.length; i++) {
        vm.chatBots[i].checked = vm.selectedChatBotsAll;
      }
      refreshSelectedChatBots();
    }

    /**
     * Трек клика по кнопке "Внешний вид ботов"
     */
    function trackClickOnAppearance() {
      carrotquestHelper.track('Чат-бот - клик на изменить внешность ботов');
    }

    /**
     * Трек клика по кнопке "Создать лид-бота"
     */
    function trackClickOnCreate() {
      carrotquestHelper.track('Чат-бот - клик на создать лид-бота');
    }

    /**
     * Трек клика на фильтрацию по статусу
     */
    function trackClickOnCurrentStatus() {
      carrotquestHelper.track('Чат-бот - клик на фильтр');
    }

    /**
     * Трек клика по "Узнать больше о чат-ботах в базе знанинй"
     */
    function trackClickOnKnowledgeBaseInDescription() {
      carrotquestHelper.track('Чат бот - клик на узнайте больше в базе знаний');
    }

    /**
     * Трек клика по кнопке «Нужны другие триггеры в Facebook-боте»
     */
    function trackClickOnNeedMoreFacebookTriggersBtn() {
      carrotquestHelper.track('Чат-бот - клик на «Нужны другие триггеры в Facebook-боте»');
    }

    /**
     * Трек клика по кнопке «Чат-бот - переход на статистику»
     */
    function trackClickOnStatsChatBot() {
      carrotquestHelper.track('Чат-бот - переход на статистику');
    }

    /**
     * Трек клика на 'Обновить в списке чат-ботов'
     */
    function trackClickRefresh() {
      carrotquestHelper.track('Чат-бот - клик на "Обновить"');
    }

    /** Трек показа квиза */
    function trackEnterOnChatBotQuiz() {
      const eventName = 'Чат-бот - попал на квиз для простого запуска';

      carrotquestHelper.track(eventName);
    }

    /**
     * Трек захода на страницу
     */
    function trackEnterOnPage() {
      carrotquestHelper.track('Чат-бот - зашел');
    }

    /**
     * Трек смены статуса Facebook-бота на "Активен"
     */
    function trackSetActiveFacebookBot() {
      carrotquestHelper.track('Facebook-бот - активировал');
    }

    /**
     * Трек смены статуса Telegram-бота на "Активен"
     */
    function trackSetActiveTelegramBot() {
      carrotquestHelper.track('Telegram Welcome бот - активировал');
    }

    /**
     * Трек смены статуса Facebook-бота на "Приостановлен"
     */
    function trackSetPauseFacebookBot() {
      carrotquestHelper.track('Facebook-бот - приостановил');
    }

    /**
     * Трек смены статуса Facebook-бота на "Приостановлен"
     */
    function trackSetPauseTelegramBot() {
      carrotquestHelper.track('Telegram Welcome бот - приостановил');
    }

    /**
     * Трек смены статуса Велком-бота на "Активен"
     */
    function trackSetActiveRoutingBot() {
      carrotquestHelper.track('Welcome-бот - активировал');
    }

    /**
     * Трек смены статуса Велком-бота на "Приостановлен"
     */
    function trackSetPauseRoutingBot() {
      carrotquestHelper.track('Welcome-бот - приостановил');
    }

    /**
     * Трек смены статуса Лид-бота на "Активен"
     */
    function trackSetActiveLeadBot() {
      carrotquestHelper.track('Лид-бот - активировал');
    }

    /**
     * Трек смены статуса Лид-бота на "Приостановлен"
     */
    function trackSetPauseLeadBot() {
      carrotquestHelper.track('Лид-бот - приостановил');
    }

    /**
     * Трек смены статуса Widget бота на "Активен"
     */
    function trackSetActiveWidgetBot() {
      carrotquestHelper.track('Embedded bot - активировал');
    }

    /**
     * Трек смены статуса Widget бота на "Приостановлен"
     */
    function trackSetPauseWidgetBot() {
      carrotquestHelper.track('Embedded bot - приостановил');
    }

    /** Трек прохождения квиза */
    function trackSubmitChatBotQuiz() {
      const eventName = 'Чат-бот - квиз для простого запуска пройден';

      carrotquestHelper.track(eventName);
    }

    /** Обновление доступов до фичей, которые завязаны на количестве */
    function updateAccessesToBots() {
      vm.accessToFacebookBots = planFeatureAccessService.getAccess(
        PLAN_FEATURE.FACEBOOK_BOTS,
        vm.currentApp,
        getActiveFacebookBotsAmount(vm.currentApp),
      );
      // NOTE Widget боты тоже проверяются через accessToLeadBots
      vm.accessToLeadBots = planFeatureAccessService.getAccess(
        PLAN_FEATURE.LEAD_BOTS,
        vm.currentApp,
        getActiveLeadBotsAmount(vm.currentApp),
      );
      vm.accessToWelcomeBots = planFeatureAccessService.getAccess(
        PLAN_FEATURE.WELCOME_BOTS,
        vm.currentApp,
        getActiveWelcomeBotsAmount(vm.currentApp),
      );
      vm.accessToTelegramBots = planFeatureAccessService.getAccess(
        PLAN_FEATURE.TELEGRAM_BOTS,
        vm.currentApp,
        getActiveTelegramBotsAmount(vm.currentApp),
      );
    }
  }
})();

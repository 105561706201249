import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-non-working-time-reply-control',
  templateUrl: './non-working-time-reply-control.component.html',
  styleUrls: ['./non-working-time-reply-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NonWorkingTimeReplyControlComponent extends AbsCVAFormControlBasedComponent<string> {
  readonly control: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required],
  });

  constructor() {
    super();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AutoMessageSetDirectoryModalComponent } from './auto-message-set-directory-modal.component';

@NgModule({
  declarations: [AutoMessageSetDirectoryModalComponent],
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, SharedModule, TranslocoModule],
})
export class AutoMessageSetDirectoryModalModule {}

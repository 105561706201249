<ng-container *transloco="let t">
  <div class="card align-items-center">
    <div class="card-body">
      <form [formGroup]="integration.form">
        <cq-vk-o-auth-container
          [integrationId]="integration.id"
          [isAuthorized]="isAuthorized"
          (authorizeError)="onAuthorizeError()"
          (authorizeSuccess)="onAuthorizeSuccess()"
        ></cq-vk-o-auth-container>

        <ng-container *ngIf="isAuthorized">
          <hr />
          <cq-vk-groups-choice
            *ngIf="integration.id"
            [integrationId]="integration.id"
            [vkGroupForms]="vkGroupForms"
            (choiceGroups)="onChoiceGroups($event)"
          ></cq-vk-groups-choice>

          <hr />
          <cq-integration-name-control [control]="nameControl"></cq-integration-name-control>
        </ng-container>
      </form>
    </div>
  </div>

  <ng-container *ngIf="isAuthorized">
    <div class="text-right">
      <button
        *ngIf="!isGroupAuthorizationRequired()"
        class="btn btn-primary btn-lg"
        (click)="onClickSaveIntegration()"
        [disabled]="isDisabledSaveIntegration()"
        type="button"
      >
        {{ t('models.integration.actions.save') }}
      </button>

      <cq-o-auth-button
        *ngIf="isGroupAuthorizationRequired()"
        [oAuthUrl]="groupsOAuthUrl"
        [buttonSize]="'lg'"
        (authorizeError)="onAuthorizeGroupsError()"
        (authorizeSuccess)="onAuthorizeGroupsSuccess()"
      >
        {{ t('models.integration.actions.save') }}
      </cq-o-auth-button>
    </div>
  </ng-container>
</ng-container>

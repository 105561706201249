import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';

import { MESSAGE_PAGE_TYPES } from '@http/message/message.constants';
import { MESSAGE_PAGE_TYPE_TOKEN } from '@panel/app/partials/message-editor/tokens';
import { MessageEditorAnotherEventForm } from '@panel/app/partials/message-editor/trigger/another-events/another-event/message-editor-another-event.component';
import { AbsCVAFormArrayBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-array-based-component';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-message-editor-another-events',
  templateUrl: './message-editor-another-events.component.html',
  styleUrls: ['./message-editor-another-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorAnotherEventsComponent extends AbsCVAFormArrayBasedComponent<
  MessageEditorAnotherEventForm,
  FormControl
> {
  readonly control = this.fb.array<FormControl<MessageEditorAnotherEventForm>>([]);

  @Input()
  entityName: string = '';

  constructor(
    private readonly fb: FormBuilder,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly transloco: TranslocoService,
    @Inject(MESSAGE_PAGE_TYPE_TOKEN)
    protected readonly messagePageType: MESSAGE_PAGE_TYPES,
  ) {
    super();
  }

  addTrigger(): void {
    this.control.push(this.getNewControl());
  }

  /** Добавление триггера триггерного сообщения */
  getNewControl(value?: MessageEditorAnotherEventForm) {
    return this.fb.control<MessageEditorAnotherEventForm>(value ?? null);
  }

  openChat() {
    this.carrotquestHelper.open();
  }

  trackProblemsWithSelectOfTrigger() {
    if (this.messagePageType === MESSAGE_PAGE_TYPES.AUTO_MESSAGES) {
      this.carrotquestHelper.track('Триггерные сообщения - Настройки - Проблемы с выбором триггера');
      return;
    }

    this.carrotquestHelper.track('Чат-бот - Настройки - Проблемы с выбором триггера');
  }

  /** Трек клика на добавление триггера */
  trackAddTrigger() {
    this.carrotquestHelper.track(
      this.transloco.translate(this.messagePageType + '.breadcrumbs.title') + ' - клик на "Добавить ещё событие"',
    );
  }

  removeTrigger(index: number) {
    this.control.removeAt(index);
  }

  lastEventSelected() {
    if (!this.control.length) {
      return true;
    }
    return this.control.at(this.control.length - 1).value !== null;
  }
}

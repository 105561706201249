import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { VkNewModule } from '@panel/app/pages/integrations/content/vk/content/vk-new/vk-new.module';
import { VkOldModule } from '@panel/app/pages/integrations/content/vk/content/vk-old/vk-old.module';
import { VkComponent } from '@panel/app/pages/integrations/content/vk/vk.component';

@NgModule({
  declarations: [VkComponent],
  exports: [VkComponent],
  imports: [CommonModule, TranslocoModule, VkNewModule, VkOldModule],
})
export class VkModule {}

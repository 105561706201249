import { MEMBER_INVITATION_MODAL_OPENING_PLACE } from '../../../../app/partials/modals/member-invitation/member-invitation-modal.constants';
import { SPECIAL_PLANS_ARRAY } from '../../../../app/services/billing-info/billing-info.constants';
import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import {
  PSEUDO_TEAM_MEMBERS_GROUPS,
  PSEUDO_TEAM_MEMBERS_GROUPS_IDS,
} from '../../../../app/http/team-member-group/team-member-group.constants';
import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../../app/http/team-member/team-member.constants';
/**
 * Контроллер для таблицы членов команды
 */

(function () {
  'use strict';

  angular
    .module('myApp.teamMembers')
    .controller('CqTeamMembersTableWrapperController', CqTeamMembersTableWrapperController);

  function CqTeamMembersTableWrapperController(
    $filter,
    $scope,
    $stateParams,
    $translate,
    $uibModal,
    toastr,
    ACTIONS_ON_TEAM_MEMBERS_GROUP,
    PROJECT_NAME,
    SUPPORT_ACCOUNT_EMAIL,
    appModel,
    carrotquestHelper,
    dateRangePickerHelper,
    memberInvitationsService,
    paywallService,
    planFeatureAccessService,
    teamMemberGroupModel,
    teamMemberHelpService,
    teamMemberModel,
    validationHelper,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.currentTeamMembersCount = vm.teamMembers
        ? $filter('filter')(vm.teamMembers, { email: !vm.SUPPORT_ACCOUNT_EMAIL }, true).length
        : 0; // Текущее количество членов команды

      vm.accessToAdminGroups = planFeatureAccessService.getAccess(PLAN_FEATURE.APPS_ADMIN_GROUPS, vm.currentApp);
      vm.accessToTeamMembers = planFeatureAccessService.getAccess(
        PLAN_FEATURE.TEAM_MEMBERS,
        vm.currentApp,
        vm.currentTeamMembersCount,
      );
      vm.accessToSecurity = planFeatureAccessService.getAccess(PLAN_FEATURE.SECURITY, vm.currentApp);

      trackEnterOnPage();
      vm.addSupport = addSupport;
      vm.closeSearchTeamMemberInput = closeSearchTeamMemberInput;
      vm.currentUserPermissions = vm.djangoUser.prefs[vm.currentApp.id].permissions;
      vm.currentGroup = $filter('filter')(
        vm.teamMembersGroups,
        { id: PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.ALL_GROUPS] },
        true,
      )[0]; // Группа, которая выбрана в селекте фильтрации по группе. По умолчанию выбрано "Все группы"
      vm.currentTeamMember = $filter('filter')(vm.teamMembers, { id: vm.djangoUser.id }, true)[0]; // текущий пользователь админки FIXME currentApp.admins: в будущем, когда работа внутри app будет осуществляться не через djangoUser, а через teamMember - выпилить это, т.к. teamMember будет резолвиться в состоянии app
      vm.filteredTeamMembers = vm.teamMembers; // Отфильтрованный по группе и полю поиска массив членов команды. Передается в компонент cq-team-members-table
      /** Не показываем кнопку закрытия инпута с поиском, если нет доступа к группам, иначе пострадает UI */
      vm.isShowCloseSearchTeamMemberInputButton = vm.accessToAdminGroups.hasAccess;
      /** Если нет доступа к группам админов, то сразу показываем поле для поиска, иначе пострадает UI  */
      vm.isShowSearchTeamMemberInput = !vm.accessToAdminGroups.hasAccess;
      vm.lastSelectedGroup = null; // Последняя выбранная группа
      vm.logsActionsDateRelease = moment('14.03.2022', 'DD.MM.YYYY'); //Дата релиза логирования действий пользователей
      vm.onClickAddTeamMembersButton = onClickAddTeamMembersButton;
      vm.openTeamMembersGroupEditorModal = openTeamMembersGroupEditorModal;
      //** Получение настроек для DatePicker */
      vm.getDataPickerOptions = angular.extend(dateRangePickerHelper.getOptions(), {
        dateLimit: {
          quarters: 1,
        },
        maxDate: moment().endOf('day'),
        minDate: maxTime(moment().subtract(12, 'month'), vm.logsActionsDateRelease),
        opens: 'right',
      });
      vm.paywallService = paywallService;
      vm.refreshActions = refreshActions;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.searchPhrase = ''; // фраза для поиска членов команды
      vm.sendMessageAboutExportLogs = sendMessageAboutExportLogs;
      vm.showSearchTeamMemberInput = showSearchTeamMemberInput;
      vm.SUPPORT_ACCOUNT_EMAIL = SUPPORT_ACCOUNT_EMAIL;
      vm.tableDataActionsRange = {
        startDate: moment().subtract(6, 'days'),
        endDate: moment().endOf('day'),
      };
      vm.TEAM_MEMBER_PERMISSIONS = TEAM_MEMBER_PERMISSIONS;
      vm.teamMemberModel = teamMemberModel;
      vm.trackClickAddSupport = trackClickAddSupport;
      vm.trackClickCreateTeamMember = trackClickCreateTeamMember;
      vm.trackGroupChange = trackGroupChange;
      vm.validationHelper = validationHelper;

      $scope.$watch('vm.teamMembers', watchFilters, true);
      // HACK При удалении группы из модалки редактирования члена группы нужно сбрасывать фильтр по группе, если в нем выбрана удаляемая группа. Просто так это не сделать из-за потери референса на currentGroup, поэтому повешен watch на teamMembersGroups
      $scope.$watch('vm.teamMembersGroups', watchGroups, true);
      $scope.$watchCollection('[vm.currentGroup, vm.searchPhrase]', watchFilters);

      function watchFilters(newVal, oldVal) {
        var groupIdFilter =
          vm.currentGroup.id == PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.ALL_GROUPS]
            ? undefined
            : vm.currentGroup.id;
        var searchFilter = vm.searchPhrase;

        vm.filteredTeamMembers = $filter('filter')(
          $filter('filterBy')(vm.teamMembers, ['name', 'internalName', 'email'], searchFilter),
          { group: { id: groupIdFilter } },
          true,
        );
      }

      function watchGroups(newVal, oldVal) {
        if (!$filter('filter')(newVal, { id: vm.currentGroup.id }, true)[0]) {
          vm.currentGroup = $filter('filter')(
            vm.teamMembersGroups,
            { id: PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.ALL_GROUPS] },
            true,
          )[0];
        }
      }

      // Если в url'е присутствует get-параметр необходимо открыть модальное окно с приглашением в команду
      if ($stateParams['member_invitation'] === 'true') {
        openMemberInvitationsModal();
      }
    }

    /**
     * Добавление поддержки Carrot quest в качестве члена команды приложения
     */
    function addSupport() {
      firstValueFrom(teamMemberModel.addSupport(vm.currentApp.id)).then(createTeamMemberSuccess);

      function createTeamMemberSuccess(teamMemberId) {
        toastr.success(
          $translate.instant('teamMembersTableWrapper.toasts.supportAdded', { projectName: PROJECT_NAME }),
        );
        firstValueFrom(teamMemberModel.get(vm.currentApp.id, teamMemberId, vm.currentApp, true, false)).then(
          getTeamMemberSuccess,
        );
      }

      function getTeamMemberSuccess(teamMember) {
        setTimeout(() => {
          vm.teamMembers.push(teamMember);
        }, 0);
      }
    }

    /**
     * Закрытие поля поиска членов команды
     */
    function closeSearchTeamMemberInput() {
      vm.isShowSearchTeamMemberInput = false;
      vm.currentGroup = vm.lastSelectedGroup; // Возвращаем фильтр по группе
      vm.searchPhrase = ''; // Сбрасываем поиск
    }

    /**
     * Определяем максимальное время
     * @returns moment.Moment
     */
    function maxTime() {
      let args = Array.from(arguments);
      args = args.map((time) => +time.format('x'));
      let maxTime = Math.max(...args);
      return moment(maxTime);
    }

    /** Обработчик клика по кнопке добавления члена команды */
    function onClickAddTeamMembersButton() {
      vm.trackClickCreateTeamMember();

      return vm.teamMembers.length >= vm.appBlocks.adminsLimit
        ? openLimitOfTeamMembersReached()
        : openMemberInvitationsModal();
    }

    /** Открытие модального окна для приглашения нового члена команды */
    function openMemberInvitationsModal() {
      teamMemberHelpService
        .openModalInvitations(vm.currentTeamMember)
        .result.then((memberInvitations) => {
          return firstValueFrom(memberInvitationsService.send(memberInvitations[0]));
        })
        .then((invitation) => {
          vm.memberInvitations = [...vm.memberInvitations, invitation];

          // Поддерживаем трекинг событий из старого процесса добавления членов команды
          teamMemberHelpService.trackCreatingMember(vm.currentApp);

          teamMemberHelpService.trackSendingInvitations(MEMBER_INVITATION_MODAL_OPENING_PLACE.ADMINISTRATORS);
          teamMemberHelpService.showSuccessfulInvitationsSending();
        })
        .catch((err) => {
          const error = err?.meta?.error;
          if (error) {
            teamMemberHelpService.showFailedInvitationsSending(error);
          }
        });
    }

    /**
     * Открытие модалки с информацией о превышении максимального количества админов
     */
    function openLimitOfTeamMembersReached() {
      var modalInstance = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('teamMembersTableWrapper.teamMembersLimitReachedModal.heading'),
              body: $translate.instant(
                ~SPECIAL_PLANS_ARRAY.indexOf(vm.billingInfo.subscription.plan_id)
                  ? 'teamMembersTableWrapper.teamMembersLimitReachedModal.specialPlansBody'
                  : 'teamMembersTableWrapper.teamMembersLimitReachedModal.body',
                { limit: vm.appBlocks.adminsLimit },
              ),
              cancelButtonClass: 'hidden',
              confirmButtonText: $translate.instant(
                'teamMembersTableWrapper.teamMembersLimitReachedModal.confirmButtonText',
              ),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      modalInstance.result.then(openChat);

      function openChat() {
        carrotquestHelper.open();
      }
    }

    /**
     * Открытие модалки редактирования/создания группы членов команды
     *
     * @param {Object=} group Группа членов команды
     */
    function openTeamMembersGroupEditorModal(group) {
      var teamMembersGroupEditorModal = $uibModal.open({
        component: 'cqTeamMembersGroupEditorModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          group: angular.bind(null, angular.identity, angular.copy(group)),
        },
      });

      teamMembersGroupEditorModal.result.then(teamMembersGroupEditorSuccess);

      function teamMembersGroupEditorSuccess(response) {
        var group = $filter('filter')(vm.teamMembersGroups, { id: response.group.id }, true)[0];

        switch (response.action) {
          case ACTIONS_ON_TEAM_MEMBERS_GROUP.CREATE: {
            vm.teamMembersGroups.push(response.group);
            break;
          }
          case ACTIONS_ON_TEAM_MEMBERS_GROUP.REMOVE: {
            vm.teamMembersGroups.splice(vm.teamMembersGroups.indexOf(group), 1);
            // Если выбран фильтр по удаляемой группе, то надо выбрать другую
            if (vm.currentGroup.id == response.group.id) {
              vm.currentGroup = $filter('filter')(
                vm.teamMembersGroups,
                { id: PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.ALL_GROUPS] },
                true,
              )[0];
            }
            // Перенести всех членов команды из удаленной группы в группу Без группы
            for (var i = 0; i < vm.teamMembers.length; i++) {
              if (vm.teamMembers[i].group.id === response.group.id) {
                vm.teamMembers[i].group = $filter('filter')(
                  vm.teamMembersGroups,
                  { id: PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.WITHOUT_GROUP] },
                  true,
                )[0];
              }
            }
            break;
          }
          case ACTIONS_ON_TEAM_MEMBERS_GROUP.RENAME: {
            angular.extend(group, response.group);
            for (var i = 0; i < vm.teamMembers.length; i++) {
              if (vm.teamMembers[i].group.id === response.group.id) {
                vm.teamMembers[i].group = group;
              }
            }
            break;
          }
        }
      }
    }

    // Не придумал ничего лучше чем просто переприсвоить дату для обновления списка
    //* Функция перезагрузки списка логов */
    function refreshActions() {
      vm.tableDataActionsRange = { ...vm.tableDataActionsRange };
    }

    //* Сообщение в чат для экспорта логов */
    function sendMessageAboutExportLogs() {
      carrotquestHelper.sendChatMessage(
        $translate.instant('teamMembersTableWrapper.journalActionsExportMessage', {
          startDate: vm.tableDataActionsRange.startDate.format('L'),
          endDate: vm.tableDataActionsRange.endDate.format('L'),
        }),
      );
    }

    /**
     * Функция показа поля поиска членов команды
     */
    function showSearchTeamMemberInput() {
      // Запоминаем последнюю выбранную группу
      vm.lastSelectedGroup = vm.currentGroup;
      // Так как поиск производится по всему списку, нужно сбросить фильтр по группе
      vm.currentGroup = $filter('filter')(
        vm.teamMembersGroups,
        { id: PSEUDO_TEAM_MEMBERS_GROUPS_IDS[PSEUDO_TEAM_MEMBERS_GROUPS.ALL_GROUPS] },
        true,
      )[0];
      vm.isShowSearchTeamMemberInput = true;
    }

    /**
     * Трек клика на кнопку 'Разрешить доступ техподдержке'
     */
    function trackClickAddSupport() {
      carrotquestHelper.track('Добавление членов команды - кликнул на "Разрешить доступ техподдержке"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек клика на 'Добавить администратора'
     */
    function trackClickCreateTeamMember() {
      carrotquestHelper.track('Добавление членов команды - кликнул на "Добавить администратора"', {
        app: vm.currentApp.name,
      });
    }

    /**
     * Трек захода на страницу
     */
    function trackEnterOnPage() {
      carrotquestHelper.track('Настройки управление командой - зашел');
    }

    /**
     * Трек выбора группы
     */
    function trackGroupChange() {
      carrotquestHelper.track('Администраторы - применил фильтр "Группы"', {
        App: vm.currentApp.name,
        'Название группы': vm.currentGroup.name,
      });
    }
  }
})();

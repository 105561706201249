import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { EmailOperationSelectModule } from '@panel/app/partials/filters/components/email-operation-select/email-operation-select.module';
import { OperationSelectModule } from '@panel/app/partials/filters/components/operation-select/operation-select.module';
import { PropertySelectModule } from '@panel/app/partials/filters/components/property-select/property-select.module';
import { TelegramOperationSelectModule } from '@panel/app/partials/filters/components/telegram-operation-select/telegram-operation-select.module';

import { PropertyFilterComponent } from './property-filter.component';

@NgModule({
  declarations: [PropertyFilterComponent],
  imports: [
    CommonModule,
    NgbTooltipModule,
    OperationSelectModule,
    PropertySelectModule,
    ReactiveFormsModule,
    TranslocoModule,
    EmailOperationSelectModule,
    TelegramOperationSelectModule,
  ],
  exports: [PropertyFilterComponent],
})
export class PropertyFilterModule {}

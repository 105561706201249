import { translate } from '@jsverse/transloco';
import cloneDeep from 'lodash-es/cloneDeep';
import { Container, FederatedPointerEvent, Point, Text } from 'pixi.js';
import { Simple } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';
import { fromEvent, merge, Observable, of, partition, Subject } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, startWith, takeUntil, throttleTime } from 'rxjs/operators';
import { generate } from 'short-uuid';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { BotCanvasOverlayAbleElement } from '@panel/app/pages/chat-bot/content/canvas-editor/canvas-overlay/bot-blocks-overlay.interfaces';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ActionFactory, BranchFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { PIXI_INTERACTION_EVENT } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.types';
import { PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { getBlockCard } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/block.textures';
import { BlockHeadingBuildingElement } from '@panel/app/pages/chat-bot/content/views/building-elements';
import {
  ConnectionTarget,
  IPoint,
  VIEWPORT_ON_ZOOM_THROTTLE,
} from '@panel/app/pages/chat-bot/content/views/connection';
import { ABSCard } from '@panel/app/pages/chat-bot/content/views/element.abs';
import {
  BOT_CONDITION_BLOCK_DARKEN_PRIMARY_COLOR,
  BOT_CONDITION_BLOCK_PRIMARY_COLOR,
  BOT_MESSAGE_BLOCK_PRIMARY_COLOR,
  BOT_WHITE_COLOR,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { cloneObjectMutable } from '@panel/app/shared/functions/clone-object-mutable.function';
import { decreaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/decrease-ticker-max-fps.function';
import { increaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/increase-ticker-max-fps.function';
import { Immutable } from '@panel/app/shared/types/immutable.type';
import { ZoomedEventData } from '@panel/app/shared/types/zoomed-event-data.type';

import { BotBranchForm } from '../../../../forms/bot-branch.form';

export type BranchOptions = {
  isFirstBlockOfDefaultScenario?: boolean;
  /**
   * Не получилось избавиться от этой штуки, надо переделать у ActionForm nextBranchLinkId в nextBranch,
   * тогда получится
   * @param linkId
   */
  getBranchByLinkId: (linkId: string) => Branch | null;
};

export type BranchStyle = {
  padding: {
    vertical: number;
    horizontal: number;
  };
  border: {
    size: number;
    color: number;
    radius: number;
  };
  background: {
    color: number;
  };
  text: {
    color: number;
  };
  heading: {
    icon: {
      color: number;
      background: number;
    };
  };
};

/**
 * Типы блока
 */
export type BlockType = 'branch' | 'condition' | 'action' | 'meeting';

/**
 * Ширина ветки на канвасе
 */
export const CARD_WIDTH = 270;

/**
 * Максимальная длинна имени ветки
 */
const BRANCH_NAME_MAX_LENGTH = 30;

/**
 * Стандартные отрисовки ветки/элементов внутри ветки
 */
export const DEFAULT_CARD_STYLE: Immutable<BranchStyle> = {
  padding: {
    vertical: 10,
    horizontal: 10,
  },
  border: {
    size: 1,
    color: 0xe3e5e8,
    radius: 10,
  },
  background: {
    color: BOT_WHITE_COLOR,
  },
  text: {
    color: BOT_MESSAGE_BLOCK_PRIMARY_COLOR,
  },
  heading: {
    icon: {
      color: BOT_MESSAGE_BLOCK_PRIMARY_COLOR,
      background: BOT_WHITE_COLOR,
    },
  },
};

/**
 * Отступ от заколовка блока
 */
export const HEADING_INDENT: number = 5;

export const NOT_VALID_CARD_STYLE = {
  background: {
    color: 0xffeee6,
  },
  border: {
    color: 0xff7733,
  },
  text: {
    color: 0xcc4400,
  },
};

export class Branch extends ABSCard implements ConnectionTarget, BotCanvasOverlayAbleElement {
  private _active = false;
  /**
   * Меняет статус активности ветки на канваса, вызывает перерисовку (т.к. меняются цвета)
   */
  set active(value: boolean) {
    const needToRedraw = value !== this._active;
    this._active = value;
    if (needToRedraw) {
      this.redraw(false);
    }
  }

  protected get headingIcon(): string {
    throw new Error('Could not find heading icon');
  }

  get active() {
    return this._active;
  }

  blockType: BlockType;

  isDragging = false; // маркер того, идет ли перемещиение эелемента в данный момент
  protected hovered: boolean = false; // маркер того, происходит ли mouseover над блоком в момент перетягивания стрелки для присоидинения

  set hover(value: boolean) {
    const needToRedraw = value !== this.hovered;
    this.hovered = value;
    if (needToRedraw) {
      this.redraw(false);
    }
  }
  get hover(): boolean {
    return this.hovered;
  }

  private _heading: BlockHeadingBuildingElement | null = null;

  protected set heading(heading: BlockHeadingBuildingElement) {
    this._heading = heading;
  }

  protected get heading(): BlockHeadingBuildingElement {
    if (!this._heading) {
      throw new Error('Could not get block heading');
    }
    return this._heading;
  }

  makeCopy(): Branch {
    const data: ChatBotBranch = {
      actions: this._actions.map((action) => {
        return action.makeJSONCopy();
      }),
      coordinates: null,
      id: null,
      isInvalid: false,
      linkId: generate(),
      name: `${this.name.value}  ${translate('classes.branch.copy')}`,
      parentBranchIds: [],
    };
    return this.branchFactory.create(data, this.options);
  }

  /**
   * Т.к. при любом обновлении списка actions надо обновлять и список controls в форме,
   * притяно решение реализовать таким образом, а редактирование actions через отдельные методы,
   * которые обновят и controls тоже.
   * Оставляю так, до принятия более элегантного решения по структуре.
   */
  private _actions: BotAction[] = [];
  get actions(): ReadonlyArray<BotAction> {
    return this._actions;
  }

  /**
   * Рамка блока ветки (прямоугольник с белым бэкграундом и рамкой)
   */
  protected card: Container;

  /**
   * Контейнер, в котором лежат все эелементы Pixi от текущей Branch.
   * Сам не отрисовывается, но имеет свое положение в рамках канваса.
   */
  readonly container: Container;

  /**
   * Хранилище всех сабджектов (по сути эвент-эмиттеров)
   */
  private readonly eventEmitters = {
    move: new Subject<void>(), // Чтоб можно было трекать когда двигается и перерисовывать связи с новыми координатами
    redraw: new Subject<void>(), // Когда перерисовывается ветка
  };

  /**
   * FormGroup ветки, она же используется в редакторе, так что все изменения можно подтягивать отсюда
   */
  readonly form: BotBranchForm;

  /**
   * ID ветки с бэка
   */
  id: string | null;

  readonly initialAPIData: Readonly<ChatBotBranch>;

  private _isInterruptScenario: boolean;

  public set isInterruptScenario(isInterruptScenario: boolean) {
    this._isInterruptScenario = isInterruptScenario;
    this.heading.setBlockIcon(this.headingIcon);
  }

  public get isInterruptScenario(): boolean {
    return this._isInterruptScenario;
  }

  private _isDefaultScenario: boolean;
  public set isDefaultScenario(isDefaultScenario: boolean) {
    this._isDefaultScenario = isDefaultScenario;
  }

  public get isDefaultScenario(): boolean {
    return this._isDefaultScenario;
  }

  /**
   * Локальный ID ветки, генерится на фронте
   */
  linkId: string;

  /**
   * тип условия (по рабочему времени и тд)
   */
  readonly branchType: 'default' | 'appOnlineCondition';

  /**
   * Зум для дочернего элемента
   * @private
   */
  private _childScale: number = 1;
  private set childScale(scale: number) {
    // Построив график зависимостей родительсокго зума от зума дочернего элемента выяснил,
    // что график этих зависимостей логарифмический
    // и log(parentZoom)childZoom ≈ -1
    // соответственно чтобы вычислить какой должен быть зум у дочернего элемента надо возвести в степень
    // т.к. противоположная операция логарифму - это возведение в сепень
    this._childScale = Math.pow(scale, -1);
  }

  /**
   * Observable, который эмитит необходимость перерисовать связи
   */
  rerenderConnection$: Observable<void> = this.eventEmitters.move.asObservable();

  /**
   * Стили отрисовки ветки/элементов внутри ветки
   */
  protected style: BranchStyle = cloneDeep(DEFAULT_CARD_STYLE);

  private _valid = false;
  /**
   * Меняет статус валидности ветки на канваса, вызывает перерисовку (т.к. меняются цвета)
   */
  set valid(value: boolean) {
    const needToRedraw = value !== this._valid;
    this._valid = value;
    if (needToRedraw) {
      this.redraw(false);
    }
  }

  get valid() {
    return this._valid;
  }

  /**
   * Флаг для маркировки того, выделять ли ветку при "отпускании" ЛКМ
   */
  private selectOnPointerUp = false;

  constructor(
    branch: ChatBotBranch,
    private readonly cull: Simple,
    private readonly actionFactory: ActionFactory,
    private readonly branchFactory: BranchFactory,
    private readonly options: BranchOptions,
    private readonly viewport: Viewport,
    private readonly interactionService: PixiInteractionService,
    private readonly document: Document,
    protected readonly scenariosHelper: BotScenariosHelper,
    protected readonly pixiApp: PixiApplication,
  ) {
    super();
    // Assign branch data to properties, create Actions
    this.id = branch.id;
    this.linkId = branch.linkId ?? generate(); // short uid
    this.branchType = Branch.getBranchType(branch);
    this.blockType = Branch.getBlockType(branch);
    this._actions = branch.actions.map((action) => {
      return this.actionFactory.create(action, this);
    });
    this.form = new BotBranchForm({
      name: branch.name,
      actions: this.actions.map((item) => item.form),
    });
    this.initialAPIData = branch;
    this._isInterruptScenario = scenariosHelper.blockItselfIsPartOfInterruptScenario(this.linkId);
    this._isDefaultScenario = scenariosHelper.blockItselfIsPartOfDefaultScenario(this.linkId);

    this.overlayElementIDAttr = this.options.isFirstBlockOfDefaultScenario ? 'overlay-start-block' : undefined;

    // Init Canvas view. Set textStyles, Create canvas textNode, card, actions, etc, add it to view;
    this.renderBlockHeading();

    this.descriptionNode = this.drawDescription();
    this.card = this.drawCard();
    this.container = this.createContainer();
    this.container.name = `Branch ${branch.linkId}`;
    this.container.addChild(this.heading.container);
    this.container.addChild(this.card);
    this.valid = !branch.isInvalid;
    // Drawing actions
    if (this.descriptionNode) {
      this.card.addChild(this.descriptionNode);
      this.descriptionNode.position.set(this.style.padding.horizontal, this.style.padding.horizontal);
    }
    this.drawActions();
    this.initListeners();
    if (branch.coordinates) {
      this.container.x = branch.coordinates.x;
      this.container.y = branch.coordinates.y;
    }

    this.initSubscriptions();
  }

  get topLeftPositionOnCanvas(): IPoint {
    return {
      x: this.viewport.position.x + this.coordinates.x * this.viewport.scaled,
      y: this.viewport.position.y + this.coordinates.y * this.viewport.scaled,
    };
  }
  readonly positionChange$ = this.events.move$;

  readonly sizeChange$ = this.events.redraw$;

  readonly showOverlay$: Observable<boolean> = of(true);

  readonly overlayElementIDAttr?: string;

  /**
   * Добавляет action, добавляет его в форму, добавляет в подписку на изменения таргет ветки
   * @link _actions
   */
  addAction(action: BotAction, index?: number) {
    if (index !== undefined) {
      this._actions.splice(index, 0, action);
      this.form.controls.actions.insert(index, action.form);
    } else {
      this._actions.push(action);
      this.form.controls.actions.push(action.form);
    }
  }

  get apiReadyData(): ChatBotBranch {
    return {
      id: this.id,
      linkId: this.linkId,
      name: this.form.controls.name.value,
      parentBranchIds: [this.id || this.linkId],
      actions: this.actions.map((action) => action.apiReadyData),
      isInvalid: this.form.invalid,
      coordinates: {
        x: this.coordinates.x,
        y: this.coordinates.y,
      },
    };
  }

  getContentHeight(): number {
    let contentHeight = this.style.padding.vertical * 2;

    if (this.descriptionNode) {
      contentHeight += this.descriptionNode.height + 10;
    }

    let prevActionType: CHAT_BOT_ACTIONS_TYPES;
    this.actions.forEach((action) => {
      if (!action.renderReady) {
        return;
      }
      switch (action.type) {
        case CHAT_BOT_ACTIONS_TYPES.TEXT:
        case CHAT_BOT_ACTIONS_TYPES.EVENT:
        case CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION:
        case CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION:
        case CHAT_BOT_ACTIONS_TYPES.BUTTON:
        case CHAT_BOT_ACTIONS_TYPES.PROPERTY:
        case CHAT_BOT_ACTIONS_TYPES.FILE:
        case CHAT_BOT_ACTIONS_TYPES.OPERATOR:
        case CHAT_BOT_ACTIONS_TYPES.USER_TAG:
        case CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY:
        case CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION:
        case CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION:
        case CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD:
        case CHAT_BOT_ACTIONS_TYPES.NEXT:
        case CHAT_BOT_ACTIONS_TYPES.MEET:
          contentHeight += action.height + this.getYActionIndent(action.type, prevActionType);
          break;
        case CHAT_BOT_ACTIONS_TYPES.ASSISTANT:
        case CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE:
        case CHAT_BOT_ACTIONS_TYPES.CHANNEL:
        case CHAT_BOT_ACTIONS_TYPES.CLOSE:
          contentHeight += action.height;
          break;
        default:
          throw new Error(`Provide content height for the "${action.type}" action type`);
      }
      prevActionType = action.type;
    });
    return contentHeight;
  }

  /**
   * Удаление ветки с канваса, emit удаления, чтоб подписавшийся мог отрабатывать у себя удаление ветки
   */
  destroy() {
    // [...this.connectionsInfos].forEach(connection => connection.dropConnection());
    // придумать более элегантный способ комплита всех observables, возможно есть что-то вроде takeUntil только для Subj
    // пишут, что на самом деле не надо этого делать, если везде отписывать подписчиков; Можно и так, конечно, но для
    // безопасности пока оставлю как есть
    this.eventEmitters.redraw.complete();
    this.eventEmitters.move.complete();
    // this.eventEmitters.connectionPoint.click.complete();
    for (let i = this.actions.length - 1; i >= 0; i--) {
      this.actions[i].destroy();
    }
    super.destroy();
  }

  get description(): string | null {
    return null;
  }

  protected descriptionNode: Text | null = null;

  /**
   * Рисуем прямоугольник, делаем его интерактивным (шлет ивенты на нажатия).
   * buttonMode === cursor: pointer;
   */
  private drawCard(): Container {
    const height = this.getContentHeight();
    const card = getBlockCard(this.pixiApp.renderer, height, this.style.border.color);
    card.zIndex = 1;
    card.eventMode = 'static';
    return card;
  }

  /**
   * Отрисовка Actions в карточке
   * @private
   */
  protected drawActions() {
    let yPosition;
    let yIndent = this.descriptionNode
      ? this.descriptionNode.position.x + this.descriptionNode.height + this.style.padding.vertical
      : this.style.padding.vertical;

    let prevActionType: CHAT_BOT_ACTIONS_TYPES;
    this.actions.forEach((action) => {
      if (!action.renderReady) {
        return;
      }
      this.card.addChild(action.container);
      switch (action.type) {
        case CHAT_BOT_ACTIONS_TYPES.TEXT:
        case CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION:
        case CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION:
        case CHAT_BOT_ACTIONS_TYPES.MEET:
          yIndent += this.getYActionIndent(action.type, prevActionType);
          action.container.position.set(this.style.padding.horizontal, yIndent);
          yIndent += action.height;
          break;
        case CHAT_BOT_ACTIONS_TYPES.BUTTON:
          yIndent += this.getYActionIndent(action.type, prevActionType);
          action.container.position.set(0, yIndent);
          yIndent += action.height;
          action.connection?.redraw();
          break;
        case CHAT_BOT_ACTIONS_TYPES.EVENT:
        case CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION:
        case CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION:
        case CHAT_BOT_ACTIONS_TYPES.PROPERTY:
        case CHAT_BOT_ACTIONS_TYPES.USER_TAG:
        case CHAT_BOT_ACTIONS_TYPES.BUTTONS_PROPERTY:
        case CHAT_BOT_ACTIONS_TYPES.FILE:
        case CHAT_BOT_ACTIONS_TYPES.ASSISTANT:
        case CHAT_BOT_ACTIONS_TYPES.OPERATOR:
        case CHAT_BOT_ACTIONS_TYPES.CHANNEL:
        case CHAT_BOT_ACTIONS_TYPES.CLOSE:
        case CHAT_BOT_ACTIONS_TYPES.MARK_CONVERSATION_VISIBLE:
        case CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD:
          yIndent += this.getYActionIndent(action.type, prevActionType);
          action.container.position.set(this.style.padding.horizontal, yIndent);
          yIndent += action.height;
          break;
        case CHAT_BOT_ACTIONS_TYPES.NEXT:
          yIndent += this.getYActionIndent(action.type, prevActionType);
          action.container.position.set(CARD_WIDTH - action.width + action.connectionPoint!.width / 2, yIndent);
          yIndent += action.height;
          action.connection?.redraw();
          break;
        default:
          throw new Error(`Provide drawing info for the "${action.type}" action type`);
      }
      prevActionType = action.type;
    });
  }

  private drawDescription() {
    if (!this.description) {
      return null;
    }
    const fontSize = 12;
    const textOptions = {
      wordWrapWidth: CARD_WIDTH - this.style.padding.horizontal * 2,
      fontSize,
      lineHeight: fontSize * 1.4,
      fill: 0x9da3af,
    };
    return renderCanvasText(this.description, textOptions);
  }

  /**
   * Получение типа условия (по рабочему времени и тд)
   * @param branch
   */
  static getBranchType(branch: ChatBotBranch): Branch['branchType'] {
    if (branch.actions.length === 2) {
      const actionsTypes = branch.actions.map((action) => action.type);
      if (
        actionsTypes.includes(CHAT_BOT_ACTIONS_TYPES.APP_ONLINE_CONDITION) &&
        actionsTypes.includes(CHAT_BOT_ACTIONS_TYPES.DEFAULT_CONDITION)
      ) {
        return 'appOnlineCondition';
      }
    }
    return 'default';
  }

  /**
   * Получение типа блока
   * @param branch
   */
  static getBlockType(branch: ChatBotBranch): BlockType {
    const isActionMeetingExist = !!branch.actions.find((action) => action.type === CHAT_BOT_ACTIONS_TYPES.MEET);
    if (isActionMeetingExist) {
      return 'meeting';
    }

    if (Branch.getBranchType(branch) === 'appOnlineCondition') {
      return 'condition';
    }

    const actionsTypes = branch.actions.map((action) => action.type);
    if (actionsTypes.includes(CHAT_BOT_ACTIONS_TYPES.NEXT)) {
      return 'action';
    }

    return 'branch';
  }

  /**
   * Получение отступа для action исходя из
   *
   * @param actionType Тип действия
   * @param prevActionType Тип предыдущего действия
   * @private
   */
  private getYActionIndent(
    actionType: CHAT_BOT_ACTIONS_TYPES,
    prevActionType: CHAT_BOT_ACTIONS_TYPES | undefined,
  ): number {
    if (!prevActionType) {
      return 0;
    }

    if (actionType === CHAT_BOT_ACTIONS_TYPES.BUTTON && prevActionType !== CHAT_BOT_ACTIONS_TYPES.BUTTON) {
      return 15;
    }
    const actionBlockActions = [
      CHAT_BOT_ACTIONS_TYPES.PROPERTY,
      CHAT_BOT_ACTIONS_TYPES.USER_TAG,
      CHAT_BOT_ACTIONS_TYPES.EVENT,
      CHAT_BOT_ACTIONS_TYPES.AMOCRM_NOTIFICATION,
      CHAT_BOT_ACTIONS_TYPES.EMAIL_NOTIFICATION,
    ];
    if (actionBlockActions.includes(actionType) && actionBlockActions.includes(prevActionType)) {
      return 5;
    }
    return 10;
  }

  /**
   * См. ссылку
   * @link _actions
   */
  deleteAction(action: BotAction) {
    const index = this.actions.indexOf(action);
    this._actions.splice(index, 1);
    this.form.controls.actions.removeAt(index);
    action.destroy();
  }

  /**
   * См. ссылку
   * @link _actions
   */
  moveAction(index: number, newIndex: number) {
    const action = this._actions.splice(index, 1)[0];
    this._actions.splice(newIndex, 0, action);
    const formActions = this.form.controls.actions;
    formActions.removeAt(index);
    formActions.setValue([...formActions.value]);
    formActions.insert(newIndex, action.form);
  }

  /**
   * Все ивенты, что предоставляет "Ветка", все отправляется через asObservable,
   * чтоб снаружи нельзя было манипулировать потоком
   */
  get events() {
    const events = this.eventEmitters;
    return {
      move$: events.move.asObservable(),
      redraw$: events.redraw.asObservable(),
    };
  }

  get height(): number {
    return this.card.height;
  }

  private initSubscriptions() {
    this.form.controls.name.valueChanges
      .pipe(throttleTime(500, undefined, { trailing: true }), takeUntil(this.destroy$))
      .subscribe((name) => {
        this.heading.updateBlockName(name);
      });
    this.form.showError$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((showError) => (this.valid = !showError));

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$), throttleTime(500, undefined, { trailing: true }))
      .subscribe(() => {
        this.redraw();
      });
  }

  /**
   * Инициализация обработчиков
   * @private
   */
  private initListeners() {
    this.initDragListeners();
    this.initSelectListeners();
    this.initHoverListeners();
    this.initViewportZoomListeners();
  }

  /**
   * Обработка ивентов для перемещения блока по канвасу
   * @private
   */
  private initDragListeners(): void {
    let dragStartEvent: FederatedPointerEvent | null = null; // инфа о ивенте mousedown по элементу
    let localClickPosition: Point; // инфа о местоположении курсора относительно элемента
    let moveCount = 0; // посчитать сколько раз сместился блок. Если больше 3(из головы число) раз, то this.selectOnPointerUp = false;

    const onDragStart = (event: FederatedPointerEvent) => {
      dragStartEvent = event;
      localClickPosition = this.container.toLocal(event.global);
      this.isDragging = true;
      event.stopPropagation(); // чтоб ивент нажатия на элемент не прокидывался на полотно
    };
    const onDragEnd = () => {
      if (!this.isDragging) {
        return;
      }
      this.isDragging = false;
      dragStartEvent = null;
    };
    const onDragMove = () => {
      if (this.isDragging) {
        if (moveCount > 3) {
          this.selectOnPointerUp = false;
          moveCount = 0;
        } else {
          moveCount++;
        }
        if (!dragStartEvent) {
          return;
        }
        const newPosition = dragStartEvent.getLocalPosition(this.container.parent);
        this.container.position.set(newPosition.x - localClickPosition.x, newPosition.y - localClickPosition.y);
        this.eventEmitters.move.next();
        this.cull.updateObject(this.container);
        this.interactionService.registerDrag({ type: PIXI_INTERACTION_EVENT.BRANCH, instance: this });
      }
    };

    const dragStart$ = fromEvent<FederatedPointerEvent>(this.container, 'pointerdown');

    const dragEnd$ = merge(fromEvent(this.document, 'pointerup'), fromEvent(this.container, 'pointerupoutside'));

    dragStart$.pipe(increaseTickerMaxFPS(this.pixiApp), takeUntil(this.destroy$)).subscribe(onDragStart);

    dragStart$
      .pipe(
        mergeMap(() => {
          return fromEvent(this.viewport, 'pointermove').pipe(takeUntil(dragEnd$));
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(onDragMove);

    dragEnd$.pipe(decreaseTickerMaxFPS(this.pixiApp), takeUntil(this.destroy$)).subscribe(onDragEnd);
  }

  /**
   * Обработка событий ховера ветки
   * @private
   */
  private initHoverListeners(): void {
    //NOTE при pointermove ветка начинает срать событиями mouseover и mouseout, поэтому блочу при перетаскивании
    this.container.on('mouseover', (event: Event) => {
      event.stopPropagation();
      this.interactionService.registerHover({ type: PIXI_INTERACTION_EVENT.BRANCH, instance: this });
    });
  }

  /**
   * Обработка событий зума
   * @private
   */
  private initViewportZoomListeners(): void {
    const [withoutThrottle$, withThrottle$] = partition(
      fromEvent<ZoomedEventData>(this.viewport, 'zoomed'),
      (val: ZoomedEventData) => !!val.preventThrottle,
    );

    merge(
      withThrottle$.pipe(
        increaseTickerMaxFPS(this.pixiApp),
        throttleTime(VIEWPORT_ON_ZOOM_THROTTLE, undefined, { trailing: true }),
      ),
      withoutThrottle$.pipe(decreaseTickerMaxFPS(this.pixiApp)),
    )
      .pipe(
        map((event) => event.viewport.scaled),
        startWith(this.viewport.scaled),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((scale: number) => {
        this.childScale = scale;
        this.heading.setScale(this._childScale);
      });
  }

  /**
   * Обработка событий выделения ветки
   * @private
   */
  private initSelectListeners(): void {
    this.container.on('pointerdown', () => {
      this.selectOnPointerUp = true;
      this.interactionService.registerPointerDown({ type: PIXI_INTERACTION_EVENT.BRANCH, instance: this });
    });
    this.container.on('pointerup', () => {
      this.container.parent.emit('pointerup-on-branch', this);
      this.interactionService.registerPointerUp({ type: PIXI_INTERACTION_EVENT.BRANCH, instance: this });
      if (this.selectOnPointerUp) {
        this.selectOnPointerUp = false;
        this.interactionService.registerClick({ type: PIXI_INTERACTION_EVENT.BRANCH, instance: this });
      }
    });
  }

  /**
   * Перемещает ветку и перерисовывает связи
   */
  moveTo(x: number, y: number): void {
    this.container.position.set(x, y);
    this.eventEmitters.move.next();
    this.cull.updateObject(this.container);
  }

  /**
   * Геттер для удобного обращения к контролу name, чтоб вместо branch.form.get('name') делать branch.name
   */
  get name(): GenericFormControl<string> {
    return this.form.get('name');
  }

  get scaledHeight(): number {
    return this.card.height * this.viewport.scaled;
  }

  get scaledWidth(): number {
    return this.card.width * this.viewport.scaled;
  }

  /**
   * Получение координат для connection стрелки
   */
  get connectionPoint(): IPoint {
    const headingHeight = this.heading.container.height;
    return {
      x: this.coordinates.x - HEADING_INDENT,
      y: this.coordinates.y - (headingHeight / 2 + HEADING_INDENT),
    };
  }

  /**
   * Геттер для удобного обращения к контролу targetAction
   * hack еще и string потому что bot-branch.form.ts:14
   */
  get targetAction(): GenericFormControl<CHAT_BOT_ACTIONS_TYPES> {
    return this.form.get('targetAction') as GenericFormControl<CHAT_BOT_ACTIONS_TYPES>;
  }

  // Этот метод остался в силу того, что действия и интерфейсы были переработаны, а ветка еще нет
  render(): Container {
    // @ts-ignore TODO это надо будет выпилить
    return undefined;
  }

  /**
   * Перерисовывает карточку ветки по тем стилям, что указаны в пропертях
   * @private
   */
  redraw(withConnections: boolean = true): void {
    if (this.destroyed) {
      return;
    }
    this.style = this.getCurrentStyle();
    this.card.destroy();
    this.card.removeChildren();

    this.heading.updateStyle({
      icon: this.style.heading.icon,
      textColor: this.style.text.color,
    });
    this.updateHeading();

    this.card = this.drawCard();
    if (this.descriptionNode) {
      this.card.addChild(this.descriptionNode);
    }
    this.container.addChild(this.card);
    this.drawActions();
    if (withConnections) {
      this.eventEmitters.redraw.next();
    }
  }

  renderBlockHeading() {
    this.heading = new BlockHeadingBuildingElement(
      this.name.value,
      this.headingIcon,
      this._childScale,
      {
        icon: this.style.heading.icon,
        textColor: this.style.text.color,
      },
      this.pixiApp,
    );
  }

  /**
   * Не уверен, на сколько некрасиво это вышло, но в паре мест есть завязка на то, что тут в uid находится linkId,
   * поэтому менять надо аккуратно
   */
  get uid() {
    return this.linkId;
  }

  /**
   * Это станет абстрактным методом после превращения branch в абстрактный класс.
   * Обновляет заголовок.
   */
  protected updateHeading() {
    this.heading.redraw();
  }

  // TODO: этот метод нужно выпилить, он не используется и переопределяется в каждом блоке-наследнике
  protected getCurrentStyle(): BranchStyle {
    const style: BranchStyle = cloneObjectMutable(DEFAULT_CARD_STYLE);

    if (this.active) {
      switch (this.blockType) {
        case 'action':
          style.border.color = 0x66cc66;
          style.text.color = 0x66cc66;
          break;
        case 'branch':
          style.border.color = 0x5c5cd6;
          style.text.color = 0x5c5cd6;
          break;
        case 'condition':
          style.border.color = BOT_CONDITION_BLOCK_PRIMARY_COLOR;
          style.text.color = BOT_CONDITION_BLOCK_DARKEN_PRIMARY_COLOR;
          break;
      }
      style.border.size = 1;
    }
    if (this.blockType === 'action') {
      style.background.color = 0xecf9ec;
    }

    if (!this.active && this.valid) {
      style.border.color = DEFAULT_CARD_STYLE.border.color;
      style.text.color = DEFAULT_CARD_STYLE.text.color;
      style.border.size = 1;
    }

    if (!this.active && !this.valid) {
      style.border.color = NOT_VALID_CARD_STYLE.border.color;
      style.text.color = NOT_VALID_CARD_STYLE.text.color;
      style.border.size = 1;
    }

    if (this.hovered) {
      style.border.color = BOT_MESSAGE_BLOCK_PRIMARY_COLOR;
      style.text.color = 0x22252a;
      style.border.size = 1;
    }

    return style;
  }

  get width(): number {
    return this.card.width;
  }
}

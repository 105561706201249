import { AbstractControlOptions, AsyncValidatorFn, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

type FormState<T> = {
  value: T | null;
  disabled: boolean;
};

/**
 * @deprecated используй новые CVA-классы
 */
export class GenericFormControl<T> extends UntypedFormControl {
  readonly value!: T;
  readonly valueChanges!: Observable<T>;

  constructor(
    formState?: T | FormState<T> | null,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(formState, validatorOrOpts, asyncValidator);
  }

  setValue(
    value: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    },
  ): void {
    super.setValue(value, options);
  }

  patchValue(
    value: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    },
  ): void {
    super.patchValue(value, options);
  }
}

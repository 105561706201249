import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.conversations').controller('CqConversationsSingleController', CqConversationsSingleController);

  function CqConversationsSingleController(
    $filter,
    $q,
    $scope,
    $state,
    $stateParams,
    carrotquestHelper,
    conversationTagsModel,
    conversationModel,
    conversationsStoreService,
    systemError,
    utilsService,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      trackEnterOnPage();

      vm.activeConversation = null;
      vm.conversationDoesntExists = false; // флаг отсутствия диалога с таким ID
      vm.isDarkThemeActive = utilsService.isDarkThemeActive();
      vm.isHistoryShowed = false; // показывается ли сейчас история диалогов
      vm.onHideHistory = onHideHistory;
      vm.onShowHistory = onShowHistory;
      vm.openConversation = openConversation;
      vm.userRemoved = false; // флаг того, что пользователь удалён (или диалог с этим пользователем удалён, что в данный момент означает одно и то же)

      getConversations();
      getConversationTags();

      $scope.$on('message', handleRts);
    }

    /**
     * Переход на диалог
     * @param {Object} conversation - данные диалога
     */
    function openConversation(conversation) {
      if ($state.params.conversationId === conversation.id) {
        onHideHistory();
      } else {
        $state.go('app.content.conversations.detail', { conversationId: conversation.id });
      }
    }

    function activateConversation(conversation) {
      vm.activeConversation = conversation;
      vm.isHistoryShowed = false;
      vm.activeUserId = conversation ? conversation.user.id : -1; // HACK: на данный момент при удалении диалога карточку подругому не сбросить, при этом вылетит ошибка "Что-то пошло не так"
    }

    function getConversations() {
      firstValueFrom(conversationModel.get($stateParams.conversationId))
        .then(getConversationSuccess)
        .catch(getConversationError);

      function getConversationSuccess(response) {
        var conversation = response;

        if (conversation.removed) {
          // если диалог удалён, то и пользователь, с которым вёлся этот диалог тоже удалён
          vm.userRemoved = true;
        } else {
          activateConversation(conversation);
        }
      }

      function getConversationError(response) {
        if (response.meta.error === 'BadRequest' || response.meta.error === 'LookupError') {
          vm.conversationDoesntExists = true;
        } else {
          systemError.somethingWentWrongToast.show();
        }
        return $q.reject();
      }
    }

    function getConversationTags() {
      firstValueFrom(conversationTagsModel.getList(vm.currentApp.id)).then(getTagsListSuccess);

      function getTagsListSuccess(tags) {
        vm.tags = [];
        for (var i = 0; i < tags.length; i++) {
          vm.tags.push({ tag: tags[i] });
          conversationsStoreService.tags$.next(vm.tags);
        }
      }
    }

    function handleRts(event, info) {
      var channel = info.channel,
        data = info.data;

      if (channel.indexOf('conversation_tag_added.') === 0) {
        // Добавить в список тегов для поиска
        let callApply = false;
        var found = false;
        for (var i = 0; i < vm.tags.length; i++) {
          if (vm.tags[i].tag === data.tag) {
            found = true;
          }
        }
        if (!found) {
          vm.tags.push({ tag: data.tag });
          conversationsStoreService.addTag(data.tag);
          callApply = true;
        }

        callApply && $scope.$applyAsync();
      } else if (channel.indexOf('admin_presence_changed.') === 0) {
        let callApply = false;

        for (var i = 0; i < data.length; i++) {
          var admin = $filter('filter')(vm.teamMembers, { id: data[i].admin }, true)[0];
          admin.presence = data[i].presence;
          callApply = true;
        }

        callApply && $scope.$applyAsync();
      } else if (channel.indexOf('user_merge_finished.') === 0) {
        let callApply = false;

        if (data.removed === vm.activeUserId) {
          vm.activeUserId = data.new;
          callApply = true;
        }

        callApply && $scope.$applyAsync();
      } else if (channel.indexOf('channel_not_assigned_changed.') === 0) {
        //console.log(data);
      } else if (channel.indexOf('users_removed.') === 0) {
        let callApply = false;

        // при удалении приходит массив ids с ID удалённых пользователей
        if (~data.ids.indexOf(vm.activeUserId)) {
          vm.userRemoved = true;
          callApply = true;
        }

        callApply && $scope.$applyAsync();
      }
    }

    function onHideHistory() {
      vm.isHistoryShowed = false;
    }

    function onShowHistory() {
      vm.isHistoryShowed = true;
    }

    function trackEnterOnPage() {
      carrotquestHelper.track('Зашел на страницу диалогов', {
        App: vm.currentApp.name,
        New: 1,
      });
    }
  }
})();

(function () {
  'use strict';

  require('./chat-auto-reply.controller');

  angular.module('myApp.chatSettings').component('cqChatAutoReply', cqChatAutoReply());

  function cqChatAutoReply() {
    return {
      bindings: {
        autoReplyType: '=', // Тип собираемых контактов
        autoReplyConfig: '=', // Включение/выключение автоответа
        autoReplyTexts: '=', // Текст автоответа
        currentApp: '=', // Текущее приложение
      },
      controller: 'CqChatAutoReplyController',
      controllerAs: 'vm',
      template: require('./chat-auto-reply.html'),
    };
  }
})();

import { ValidatorFn, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';

import { BaseBotActionForm, CustomControlsWithBodyJsonRequired } from './base-action.form';

const eventKeyNameValidators: ValidatorFn[] = [Validators.required];

export class BotEventActionForm extends BaseBotActionForm {
  get allTouchedChanges$() {
    return extractTouchedChanges(this.controls.keyName).pipe(startWith(this.controls.keyName.touched));
  }

  getCustomControls(action: ChatBotAction): CustomControlsWithBodyJsonRequired {
    return {
      keyName: new GenericFormControl(action.keyName || null, eventKeyNameValidators),
      bodyJson: new GenericFormGroup({}),
    };
  }
}

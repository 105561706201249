<ng-container *transloco="let t; read: 'paymentsComponent'">
  <div class="card">
    <div class="card-body p-0">
      <ng-container *ngIf="invoices$.isEmpty$ | async">
        <cq-empty class="p-40"></cq-empty>
      </ng-container>

      <ng-container *ngIf="invoices$.isLoading$ | async">
        <cq-loading></cq-loading>
      </ng-container>

      <ng-container
        *tuiLet="{
          invoices: invoices$.elements$ | async,
          paginatorParameters: invoices$.paginatorParameters$ | async
        } as values"
      >
        <ng-container *ngIf="values.invoices && values.invoices.length && values.paginatorParameters">
          <cq-list
            [invoices]="values.invoices"
            [isNeedToShowShowMoreButton]="!!values.paginatorParameters.paginatePosition"
            (clickShowMoreButton)="showMore(values.paginatorParameters)"
          ></cq-list>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Компонент для работы с хлебными крошками в цепочках
 */
@Component({
  selector: 'cq-trigger-chain-breadcrumbs',
  templateUrl: './trigger-chain-breadcrumbs.component.html',
  styleUrls: ['./trigger-chain-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerChainBreadcrumbsComponent {
  /** Название цепочки */
  @Input()
  chainName: string | null = null;

  /** Callback на изменение названия цепочки */
  @Output()
  chainNameChange: Subject<string> = new Subject<string>();

  /** Определяет, задано ли название цепочки */
  nameIsNotNull(chainName: string | null): chainName is string {
    return chainName !== null;
  }

  /** Обрабатывает изменение названия цепочки */
  onChangeName(value: string): void {
    this.chainNameChange.next(value);
  }
}

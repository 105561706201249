import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';
import { firstValueFrom } from 'rxjs';

import { APP_CATEGORY } from '@http/app/app.constants';
import { AppModel } from '@http/app/app.model';
import { ModalHelperService } from '@panel/app/services';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { SystemErrorService } from '@panel/app-old/shared/services/system-error/system-error.service';

@Component({
  selector: 'cq-add-app-modal-step-second',
  templateUrl: './add-app-modal-step-second.component.html',
  styleUrls: ['./add-app-modal-step-second.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAppModalStepSecondComponent {
  /** тип приложения */
  @Input() appType!: string;

  /** флаг выполнения запроса */
  protected isApiRequestPerformed: boolean = false;

  /**
   * Максимальная длинна адреса сайта
   *
   * @type {Number}
   */
  protected APP_URL_MAX_LENGTH: number = 200;

  /**
   * Максимальная длинна названия компании
   *
   * @type {Number}
   */
  protected COMPANY_NAME_MAX_LENGTH: number = 30;

  public appForm = this.fb.group({
    appUrl: this.fb.control('', [Validators.required, Validators.maxLength(this.APP_URL_MAX_LENGTH)]),
    companyName: this.fb.control('', [Validators.required, Validators.maxLength(this.COMPANY_NAME_MAX_LENGTH)]),
  });

  constructor(
    protected readonly appModel: AppModel,
    protected readonly uiRouter: UIRouter,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly systemError: SystemErrorService,
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly modalService: ModalHelperService,
    protected readonly translocoService: TranslocoService,
    protected readonly fb: FormBuilder,
  ) {}

  /**
   * Создание нового приложения
   *
   * @param isValid Флаг валидности формы
   */
  createApp(isValid: any): void {
    let appData: any;

    if (isValid) {
      this.isApiRequestPerformed = true;
      this.uiRouter.stateService.params.createAppLoading = true;

      firstValueFrom(
        this.appModel.create(
          this.appForm.controls.appUrl.getRawValue()!,
          this.appForm.controls.companyName.getRawValue()!,
          this.appType,
        ),
      )
        .then((response: any) => {
          appData = response;

          this.carrotquestHelper.identify({ site_key: appData.apiKey });
          this.carrotquestHelper.identify([
            {
              op: 'union',
              key: 'apps',
              value: appData.name,
            },
          ]);
          this.carrotquestHelper.identify({ 'Последний созданный app': appData.id });
          this.carrotquestHelper.identify([
            {
              op: 'union',
              key: 'appsUrl',
              value: appData.origin,
            },
          ]);

          this.carrotquestHelper.track('Создал новое приложение', {
            name: appData.name,
            //@ts-ignore
            Тип: this.appModel.getTypeDescription(this.appType),
            Адрес: appData.origin,
            'App ID': appData.id,
          });
          this.carrotquestHelper.track('Создал новое приложение типа ' + this.getAppTypeDescription(this.appType), {
            name: appData.origin,
          });
        })
        .catch((error: any) => {
          this.isApiRequestPerformed = false;
          this.uiRouter.stateService.params.createAppLoading = false;

          if (error == 'AppAlreadyExistsError') {
            this.appForm.controls.companyName.setErrors({ companyNameExist: true });
            this.appForm.controls.companyName.markAsTouched();
          } else if (error == 'AppLimitError') {
            this.openTooMuchAppsModal();
          } else {
            this.systemError.somethingWentWrongToast.show();
          }
        })
        .finally(() => {
          if (appData && appData.id) {
            if (this.appForm.controls.companyName.hasError('companyNameExist')) {
              this.appForm.controls.companyName.setErrors({ companyNameExist: false });
              this.appForm.controls.companyName.markAsUntouched();
            }

            setTimeout(() => {
              this.isApiRequestPerformed = false;
              this.uiRouter.stateService.params.createAppLoading = false;
              this.ngbActiveModal.close(appData.id);
            }, 2000);
          }
        });
    }
  }

  /**
   * Получение описания типа приложения
   *
   * @param appCategories Категория приложения
   * @return {String}
   */
  getAppTypeDescription(appCategories: any): string {
    let appTypeDescription: string = '';

    switch (appCategories) {
      case APP_CATEGORY.LANDING: {
        appTypeDescription = 'Лендинг';
        break;
      }
      case APP_CATEGORY.SHOP: {
        appTypeDescription = 'Интернет-магазин';
        break;
      }
      case APP_CATEGORY.SAAS: {
        appTypeDescription = 'SaaS';
        break;
      }
      case APP_CATEGORY.OTHER: {
        appTypeDescription = 'Что-то другое';
        break;
      }
    }

    return appTypeDescription;
  }

  /**
   * Открытие модалки о превышении количества добавленных сайтов
   */
  openTooMuchAppsModal(): void {
    let modal = this.modalService
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('account.apps.tooMuchAppsModal.heading'),
        body: this.translocoService.translate('account.apps.tooMuchAppsModal.body'),
        confirmButtonText: this.translocoService.translate('account.apps.tooMuchAppsModal.confirmButtonText'),
        cancelButtonClass: 'hidden',
      })
      .open(ConfirmModalComponent);

    modal.result.then(() => {
      this.carrotquestHelper.sendChatMessage(
        this.translocoService.translate('account.apps.tooMuchAppsModal.chatMessage'),
      );
      this.ngbActiveModal.dismiss();
    });
  }
}

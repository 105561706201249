<div>
  <div
    class="d-flex align-items-center pt-15"
    [ngClass]="{ 'pb-15': userStatusForm.controls.userStatuses.getRawValue() !== USER_STATUSES_FOR_SENDING.NO_MATTER }"
  >
    <cq-switch-wrapper
      class="mr-15"
      [(value)]="userStatusSwitch"
      (valueChange)="changeUserStatusSwitch($event)"
    ></cq-switch-wrapper>
    <div class="white-space-nowrap">
      <h5
        class="d-inline-block mb-0 font-weight-bold"
        [innerHTML]="messagePageType + '.editor.userStatuses.heading' | transloco"
      ></h5>
      <i
        class="cqi-sm cqi-question-circle"
        [ngbTooltip]="tooltip"
      ></i>
    </div>
  </div>

  <div
    class="ml-50"
    *ngIf="userStatusForm.controls.userStatuses.getRawValue() !== USER_STATUSES_FOR_SENDING.NO_MATTER"
  >
    <div
      class="btn-group btn-group-toggle"
      [cqShowError]="userStatusForm.controls.userStatuses"
    >
      <label
        class="btn btn-outline-primary"
        [ngClass]="{
          active: userStatusForm.controls.userStatuses.getRawValue() === USER_STATUSES_FOR_SENDING.ONLINE_OR_IDLE
        }"
      >
        <input
          type="radio"
          [formControl]="userStatusForm.controls.userStatuses"
          [value]="USER_STATUSES_FOR_SENDING.ONLINE_OR_IDLE"
        />
        <span>{{
          'models.message.userStatusesForSending.' + USER_STATUSES_FOR_SENDING.ONLINE_OR_IDLE | transloco
        }}</span>
      </label>
      <label
        class="btn btn-outline-primary"
        [ngClass]="{
          active: userStatusForm.controls.userStatuses.getRawValue() === USER_STATUSES_FOR_SENDING.OFFLINE,
          disabled: currentMessage.sendingFilters.type !== SENDING_FILTERS_GROUP_TYPES.NO
        }"
      >
        <input
          type="radio"
          [formControl]="userStatusForm.controls.userStatuses"
          [disabled]="currentMessage.sendingFilters.type !== SENDING_FILTERS_GROUP_TYPES.NO"
          [value]="USER_STATUSES_FOR_SENDING.OFFLINE"
        />
        <span>{{ 'models.message.userStatusesForSending.' + USER_STATUSES_FOR_SENDING.OFFLINE | transloco }}</span>
      </label>
    </div>
    <cq-validation-messages [control]="userStatusForm.controls.userStatuses">
      <cq-validation-message errorName="required">
        {{ messagePageType + '.editor.userStatuses.userStatusesRadioGroup.errors.required' | transloco }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</div>

<ng-template #tooltip>
  <span [innerHTML]="messagePageType + '.editor.userStatuses.headingTooltip' | transloco"></span>
</ng-template>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { VK_FORMS_INTEGRATION_DEFAULT_QUESTION } from '@http/integration/integrations/vk-forms/constants/vk-forms-integration.constants';
import { VkFormsQuestionForm } from '@http/integration/integrations/vk-forms/forms/vk-forms-integration.form';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { App } from '@http/app/app.model';
import { Properties } from '@http/property/property.model';

@Component({
  selector: 'cq-vk-forms-questions[currentApp][properties][questions]',
  templateUrl: './vk-forms-questions.component.html',
  styleUrls: ['./vk-forms-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VkFormsQuestionsComponent {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Список свойств и событий, которые есть в App'е */
  @Input() properties!: Properties;
  /** Форма представления поля с кастомными вопросами в форме сбора заявок */
  @Input() questions!: GenericFormArray<VkFormsQuestionForm>;

  /** Получение контролов с вопросами формы */
  get questionsControls() {
    return this.questions.controls;
  }

  /*** Добавление свойства в форму */
  addQuestion(): void {
    this.questions.push(new VkFormsQuestionForm(VK_FORMS_INTEGRATION_DEFAULT_QUESTION));
  }

  /**
   * Удаление кастомного вопроса
   *
   * @param index - Позиция кастомного вопроса, который нужно удалить
   */
  removeQuestion(index: number): void {
    this.questions.removeAt(index);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { FileHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { ConversationPartPreviewModule } from '@panel/app/partials/conversation-part-preview/conversation-part-preview.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { SendingWhatsAppTemplateComponent } from './sending-whats-app-template.component';

@NgModule({
  declarations: [SendingWhatsAppTemplateComponent],
  exports: [SendingWhatsAppTemplateComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ConversationPartPreviewModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  providers: [FileHelperProvider],
})
export class SendingWhatsAppTemplateModule {}

import { Pipe, PipeTransform } from '@angular/core';
import Moment from 'moment';

@Pipe({
  name: 'userPropToIterable',
})
export class UserPropToIterablePipe implements PipeTransform {
  transform(value: string | boolean | Moment.Moment | Array<string> | undefined): Array<string> | undefined {
    if (Array.isArray(value)) {
      return value;
    }
    if (typeof value === 'string' || typeof value === 'boolean' || value instanceof Moment) {
      throw new Error('Only array type is supported');
    }
    return undefined;
  }
}

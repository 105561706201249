<ng-container *transloco="let t">
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="integration.form"
  >
    <!-- После корридорок (by ahmroma) было решено убирать эту часть из модалки, чтоб не усложнять создание интеграций -->
    <ng-container *ngIf="!isShowedFromModal">
      <h3>{{ t('emailNotificationComponent.notificationTriggers.heading') }}</h3>
      <div>{{ t('emailNotificationComponent.notificationTriggers.description') }}</div>
      <hr />
      <cq-email-notification-event-triggers
        [control]="eventTypeIdsControl"
        [currentApp]="currentApp"
        [properties]="properties"
      ></cq-email-notification-event-triggers>
      <hr />
      <cq-email-notification-chat-bots-list-triggers
        [chatBots]="chatBots"
        [integrationId]="integration.id"
        [isShowedFromModal]="isShowedFromModal"
      ></cq-email-notification-chat-bots-list-triggers>
      <hr />
      <cq-email-notification-message-triggers [messages]="autoMessages"></cq-email-notification-message-triggers>
      <hr />
    </ng-container>
    <cq-email-notification-team-members-select
      [control]="emailRecipientsControl"
      [emailRecipients]="emailRecipients"
      [teamMembers]="teamMembers"
    ></cq-email-notification-team-members-select>
    <hr />
    <cq-email-notification-user-props
      [control]="propsControl"
      [currentApp]="currentApp"
      [properties]="properties"
    ></cq-email-notification-user-props>
    <hr />
    <cq-email-notification-comment [control]="commentControl"></cq-email-notification-comment>
    <hr />
    <cq-email-notification-subject [control]="subjectControl"></cq-email-notification-subject>
    <hr />
    <h3 class="no-margin-top margin-bottom-20">{{ t('emailNotificationComponent.emailPreview') }}</h3>
    <cq-email-notification-email-preview
      [appName]="currentApp.name"
      [comment]="commentValue"
      [properties]="properties"
      [selectedEventTypesIds]="eventTypeIdsValue"
      [selectedUserProps]="propsValue"
      [subject]="subjectValue"
    ></cq-email-notification-email-preview>
    <hr />
    <cq-integration-name-control
      [control]="integration.form.controls.name"
      [customTranslations]="integrationNameControlCustomTranslations"
    ></cq-integration-name-control>
  </form>
</ng-container>

<div class="d-flex flex-column">
  <cq-trigger-chain-canvas-actions
    [hasAccessToCanvasScreenshotFeature]="hasAccessToCanvasScreenshotFeature"
    [hasAccessToCopyTemplate]="hasAccessToCopyTemplate"
    [isFullscreen]="canvasBaseService.isFullscreen"
    [zoomValue]="(canvasBaseService.zoomValue$ | async) ?? 0"
    (clickOnAddChainLinkButton)="onClickOnAddChainStepButton($event)"
    (clickOnChangeZoomButton)="onClickOnChangeZoomButton($event)"
    (clickOnCopyStructureForTemplate)="onClickCopyStructureForTemplate()"
    (clickOnGoToStartButton)="moveViewportToSendingConditions()"
    (clickOnTakeScreenshotButton)="takeScreenshot()"
    (clickOnToggleFullscreenModeButton)="onClickOnToggleFullscreenModeButton()"
  ></cq-trigger-chain-canvas-actions>

  <div
    #canvasContainer
    class="canvas-container position-absolute"
    (wheel)="onWheel($event)"
  >
    <cq-trigger-chain-overlay></cq-trigger-chain-overlay>
  </div>

  <cq-loader
    *ngIf="screenshotInProgress"
    @screenshotAnimation
    [background]="false"
    class="content-loader position-absolute"
  >
    {{ 'triggerChainsCanvasComponent.screenshotInProgress' | transloco }}
  </cq-loader>
</div>

import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

interface LinkEditorFormControls {
  text: string;
  url: string;
}

export class LinkEditorForm extends GenericFormGroup<LinkEditorFormControls> {
  constructor(
    text: string,
    url: string | null,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    const controls: Controls<LinkEditorFormControls> = {
      text: new GenericFormControl(text ?? null, Validators.required),
      url: new GenericFormControl<string>(url ?? '', Validators.required),
    };
    super(controls, validatorOrOpts, asyncValidator);
  }
}

import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AdminParamsModalComponent } from './admin-params-modal.component';

@NgModule({
  declarations: [AdminParamsModalComponent],
  imports: [CommonModule, TranslocoModule, ReactiveFormsModule, SharedModule, NgOptimizedImage],
})
export class AdminParamsModalModule {}

import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';

import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-css-selector-input',
  templateUrl: './css-selector-input.component.html',
  styleUrls: ['./css-selector-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CssSelectorInputComponent extends AbstractCVAControl<string> {
  readonly control = new GenericFormControl<string>(null, Validators.required);
}

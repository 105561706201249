import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  AMOCRM_FIELDS_TYPE,
  AMOCRM_PROPS_FIELDS_TYPE,
} from '@http/integration/integrations/amo/constants/amocrm-integration.constants';
import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { AmocrmField } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { IntegrationService } from '@http/integration/services/integration.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

@Component({
  selector: 'cq-create-new-field-modal',
  templateUrl: './amocrm-create-new-field-modal.component.html',
  styleUrls: ['./amocrm-create-new-field-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmocrmCreateNewFieldModalComponent {
  /** ID интеграции */
  @Input()
  integrationId!: string;

  /**
   * Тип полей из Amocrm
   */
  @Input()
  type!: AMOCRM_FIELDS_TYPE;

  /**
   * Максимальная длинна имени поля
   */
  NAME_MAX_LENGTH = 255;

  form = new GenericFormControl<string>(null, [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)]);

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    public activeModal: NgbActiveModal,
    private readonly amocrmService: AmocrmService,
    private readonly integrationService: IntegrationService,
  ) {}

  onSubmit(): void {
    if (this.form.valid) {
      if (this.type === 'lead') {
        this.amocrmService.createLeadFields(this.integrationId, this.form.value).subscribe(() => {
          this.activeModal.close(this.transformDataField(this.form.value));
        });
      } else {
        this.amocrmService.createContactFields(this.integrationId, this.form.value).subscribe(() => {
          this.activeModal.close(this.transformDataField(this.form.value));
        });
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  /**
   * Преобразование поля AmoCRM к нужному виду
   */
  transformDataField(name: string): AmocrmField {
    return {
      name,
      fieldType: AMOCRM_PROPS_FIELDS_TYPE.TEXT,
    };
  }
}

<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('createNewFieldModalComponent.heading.' + type, {integrationTypeName}) }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
      data-test="close-button"
      type="button"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <form
      (ngSubmit)="onSubmit()"
      autocomplete="off"
      id="form"
      name="form"
    >
      <div
        class="form-group mb-10"
        [cqShowError]="form"
      >
        <div class="mb-10">{{ t('createNewFieldModalComponent.fieldNameInput.label', {integrationTypeName}) }}</div>
        <input
          class="form-control"
          [formControl]="form"
          [placeholder]="t('createNewFieldModalComponent.fieldNameInput.placeholder', {integrationTypeName})"
          ngbAutofocus
          type="text"
        />
        <cq-validation-messages [control]="form">
          <cq-validation-message errorName="required">
            {{ t('createNewFieldModalComponent.fieldNameInput.errors.required') }}
          </cq-validation-message>
          <cq-validation-message errorName="maxlength">
            {{ t('createNewFieldModalComponent.fieldNameInput.errors.maxlength', { maxLength: NAME_MAX_LENGTH }) }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
      <small class="text-muted">{{ t('createNewFieldModalComponent.fieldNameInput.info') }}</small>
      <input
        class="hidden"
        id="form.submit"
        type="submit"
      />
    </form>
  </div>
  <div class="modal-footer flex flex-row">
    <button
      (click)="activeModal.dismiss()"
      class="btn btn-outline-primary"
      type="button"
      data-test="cancel-button"
    >
      {{ t('general.cancel') }}
    </button>
    <label
      class="btn btn-primary"
      for="form.submit"
      data-test="create-button"
      >{{ t('createNewFieldModalComponent.fieldNameInput.createButton.' + type) }}</label
    >
  </div>
</ng-container>

import { Directive, inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';

import { FORM_SUBMIT_TOKEN } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';

/**
 * Presents form as FormControl, value is an object
 */
@Directive()
export abstract class AbsBaseCVAComponent implements ControlValueAccessor, Validator, OnDestroy, OnInit {
  /**
   * Реализуем дестрой на уровне абстрактного класса, чтоб не приходилось каждый раз передавать $destroy снаружи
   */
  private readonly destroySubj = new Subject<void>();

  protected readonly destroy$ = this.destroySubj.asObservable();

  abstract readonly control: AbstractControl;

  protected readonly zone = inject(NgZone);

  protected readonly ngControl = inject(NgControl, { optional: true, self: true });

  protected readonly formSubmit$ = inject(FORM_SUBMIT_TOKEN, { optional: true });

  constructor() {
    if (!this.ngControl) {
      throw new Error(
        `NgControl not injected in ${this.constructor.name}!\n
         Use [(ngModel)] or [formControl] or formControlName for correct work.`,
      );
    }
    this.ngControl.valueAccessor = this;
    if (this.formSubmit$) {
      this.touchControlOnSubmit(this.formSubmit$);
    }
  }

  private touchControlOnSubmit(formSubmit$: Observable<void>) {
    formSubmit$.pipe(takeUntil(this.destroy$)).subscribe(() => this.control.markAllAsTouched());
  }

  ngOnInit() {
    if (this.ngControl) {
      this.syncInternalValidity(this.ngControl);
      this.syncExternalErrors(this.ngControl);
    }
    this.syncInternalChanges();
    this.syncInternalTouched();
  }

  ngOnDestroy() {
    this.destroySubj.next();
    this.destroySubj.complete();
  }

  protected onChange: Function = EMPTY_FUNCTION;

  protected onTouched: Function = EMPTY_FUNCTION;

  /**
   * Записывает value установленное программно.
   * В ситуации, когда:
   *  + значение передается через [(ngModel)]
   *  + контрол - FormGroup
   * writeValue сначала вызовется с val = null, что сломает "this.control.setValue", поэтому добавляем отдельно первую проверку
   *
   * Наверн лучше разделить это на разные классы для CVA на основе FormControl/FormGroup/FormArray, но не сейчас
   */
  writeValue(val: any): void {
    if (this.control instanceof UntypedFormGroup && val === null) {
      return;
    }

    if (val !== undefined) {
      this.control.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // для хотфикса отключаю это, потому что оно некорректно стало работать с ангуляром 16
    // оно вызывается на init с isDisabled = false, даже если в темплейте disabled никакой не передается,
    // в результате он включает все контролы, даже если делать этого не надо
    // дизейблы как фича для CVA нигде не использовалась, надо будет думать, если понадобится
    //isDisabled ? this.control.disable() : this.control.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return c.valid
      ? null
      : {
          [String(this.ngControl?.name)]: {
            value: this.control.value,
            message: `Nested AbstractControl is invalid`,
          },
        };
  }

  /** Передаёт ошибки из внешнего контрола внутреннему */
  private syncExternalErrors(ngControl: NgControl) {
    ngControl.statusChanges?.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe((value) => {
      if (ngControl.errors && Object.keys(ngControl.errors).length > 0) {
        this.control.setErrors(ngControl.errors);
      }
    });
  }

  /**
   * "Передает" изменения валидости внутреннего контрола внешнему
   */
  private syncInternalValidity(ngControl: NgControl) {
    this.control.statusChanges
      .pipe(startWith('INVALID', this.control.status), takeUntil(this.destroy$), pairwise(), debounceTime(50))
      .subscribe(([prev, curr]) => {
        // Приходиться костылять таким образом, потому что с асинхронными валидаторами будет бага
        // Проблема: https://github.com/angular/angular/issues/41519
        // Решение: https://github.com/angular/angular/issues/41519#issuecomment-1329451821
        if (!((prev === 'VALID' && curr === 'VALID') || (prev === 'INVALID' && curr === 'INVALID'))) {
          this.control.updateValueAndValidity();
          return;
        }
        // Проверять наличие ошибок нужно, чтоб не переписать уже отработавшие ошибки на уровне control'a из компонента,
        // который использует этот CVA
        // 2 ошибки одновременно не показываются все равно, так что выставлять ошибки отсюда можно только когда там уже все починено
        if (ngControl.control && !ngControl.control.errors) {
          const errors = this.validate(this.control);
          ngControl.control.setErrors(errors);
        }
      });
  }

  /**
   * "Передает" изменения value внутреннего контрола внешнему
   */
  protected syncInternalChanges() {
    this.control.valueChanges.pipe(takeUntil(this.destroySubj)).subscribe((value: any) => this.onChange(value));
  }

  /**
   * "Передает" изменения touched внутреннего контрола внешнему
   */
  private syncInternalTouched() {
    this.zone.onStable
      .pipe(
        map(() => this.control.touched),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        filter((v) => v),
      )
      .subscribe(() => this.onTouched());
  }
}

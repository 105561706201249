(function () {
  'use strict';

  angular.module('myApp.starterGuide', []);

  require('./chat-settings/starter-guide-chat-settings.component');
  require('./first-trigger-message/starter-guide-first-trigger-message.component');
  require('./installation-service/starter-guide-installation-service.component');
  require('./starter-guide.component');
  require('./work-time/starter-guide-work-time.component');
  require('./write-self/starter-guide-write-self.component');
})();

<ng-container *ngFor="let overlay of blocksOverlayService.elements$ | async; trackBy: trackByUid">
  <!-- Эта штука нужна для того, чтоб при обновлении позиции/размера обновлять оверлей -->
  <ng-container *tuiLet="updateOverlay$(overlay) | async as _">
    <cq-bot-single-block-overlay
      [width]="overlay.scaledWidth"
      [height]="overlay.scaledHeight"
      [x]="overlay.topLeftPositionOnCanvas.x"
      [y]="overlay.topLeftPositionOnCanvas.y"
      [id]="overlay.overlayElementIDAttr"
    ></cq-bot-single-block-overlay>
  </ng-container>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@panel/app/shared/shared.module';

import { ChatSettingsModule } from './chat/chat-settings.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ChatSettingsModule, SharedModule],
})
export class SettingsModule {}

import { FEATURES } from '../../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.directives.wizard').controller('CqWizardItemController', CqWizardItemController);

  function CqWizardItemController($q, featureModel) {
    var vm = this;

    vm.$onInit = init; // здесь надо использовать $onInit, т.к. в этот момент времени required-контроллеры однозначно будут bindToController (смотри документацию $compile -> require)

    function init() {
      vm.active = false; // флаг активности шага
      vm.complete = false; // флаг завершённости шага
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.destroy = destroy;
      vm.init = init;
      vm.onEnterFromNext = onEnterFromNext; // Пришли с любого шага правее в визарде
      vm.onEnterFromPrevious = onEnterFromPrevious; // Пришли с любого шага левее в визарде
      vm.onExitToNext = onExitToNext; // Уход на шаг правее
      vm.onExitToPrevious = onExitToPrevious; // Уход на шаг левее
      vm.selectStep = selectStep;

      vm.cqWizardController.addStep(vm);
    }

    /**
     * Удаление шага из массива шагов (вызывается при уничтожении шага)
     */
    function destroy() {
      vm.cqWizardController.removeStep(vm);
    }

    /**
     * Функция, которая выбирает необходимый колбэк onEnter или onEnterFromNext, превращает в Promise и вызывает его
     */
    function onEnterFromNext() {
      if (angular.isDefined(vm.onEnterCallback)) {
        return $q.resolve(vm.onEnterCallback());
      } else if (angular.isDefined(vm.onEnterFromNextCallback)) {
        return $q.resolve(vm.onEnterFromNextCallback());
      } else {
        return $q.resolve();
      }
    }

    /**
     * Функция, которая выбирает необходимый колбэк onEnter или onEnterFromPrevious, превращает в Promise и вызывает его
     */
    function onEnterFromPrevious() {
      if (angular.isDefined(vm.onEnterCallback)) {
        return $q.resolve(vm.onEnterCallback());
      } else if (angular.isDefined(vm.onEnterFromPreviousCallback)) {
        return $q.resolve(vm.onEnterFromPreviousCallback());
      } else {
        return $q.resolve();
      }
    }

    /**
     * Функция, которая выбирает необходимый колбэк onExit или onExitToPrevious, превращает в Promise и вызывает его.
     */
    function onExitToPrevious() {
      if (angular.isDefined(vm.onExitCallback)) {
        return $q.resolve(vm.onExitCallback());
      } else if (angular.isDefined(vm.onExitToPreviousCallback)) {
        return $q.resolve(vm.onExitToPreviousCallback());
      } else {
        return $q.resolve();
      }
    }

    /**
     * Функция, которая выбирает необходимый колбэк onExit или onExitToNext, превращает в Promise и вызывает его
     */
    function onExitToNext() {
      if (angular.isDefined(vm.onExitCallback)) {
        return $q.resolve(vm.onExitCallback());
      } else if (angular.isDefined(vm.onExitToNextCallback)) {
        return $q.resolve(vm.onExitToNextCallback());
      } else {
        return $q.resolve();
      }
    }

    /**
     * Выбор этого шага в качестве текущего
     */
    function selectStep() {
      vm.cqWizardController.goToStep(vm);
    }
  }
})();

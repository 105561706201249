import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import moment from 'moment/moment';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { TimepickerOption } from '@panel/app/shared/components/datetime-picker/types/timepicker-option.type';

@Component({
  selector: 'cq-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerComponent extends AbsCVAFormControlBasedComponent<moment.Moment> {
  TIME_H_OPTIONS: TimepickerOption[] = this.generateTimeOptions(24);
  TIME_M_OPTIONS: TimepickerOption[] = this.generateTimeOptions(60);

  timeH: number = moment().hours();
  timeM: number = moment().minutes();

  readonly control: FormControl<moment.Moment> = this.fb.control<moment.Moment>(moment(), { nonNullable: true });

  get controlValue(): moment.Moment {
    return this.control.getRawValue() ?? this.control.defaultValue;
  }

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  generateTimeOptions(count: number): TimepickerOption[] {
    return Array.from(Array(count).keys()).map((v) => ({
      value: v,
      label: v.toString().length === 2 ? v.toString() : `0${v}`,
      disabled: false,
    }));
  }

  onChangeTime() {
    let date = this.controlValue;

    this.control.setValue(date.set('hours', this.timeH).set('minutes', this.timeM));
  }

  writeValue(value: moment.Moment): void {
    if (value) {
      this.timeH = value.hours();
      this.timeM = value.minutes();
    }

    super.writeValue(value);
  }
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Billing } from '@http/billing/billing';
import { ApiAddCardResponse, ApiGenerateTmpInvoiceResponse, BillingDto } from '@http/billing/billing.types';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';
import {
  ApiGenerateTmpInvoiceParams,
  ApiGenerateTmpInvoiceRequest,
} from '@panel/app/services/billing-info/billing-info.types';
import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

/** Http-сервис для работы с биллингом */
@Injectable({ providedIn: 'root' })
export class BillingHttpService {
  constructor(private readonly http: HttpClient, private readonly instanceFactory: InstanceFactory) {}

  /** Запуск процедуры привязки карты для автоматической оплаты счетов */
  addCard(): Observable<string> {
    return this.http.post<ApiAddCardResponse>(`/billing/card`, {}).pipe(map((response) => response.clientSecret));
  }

  /** Отмена подписки прямо сейчас */
  cancelSubscriptionAtNow(): Observable<Billing> {
    return this.http
      .post<BillingDto>(
        `/billing/subscription/cancel`,
        { end_of_term: false },
        {
          context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
        },
      )
      .pipe(
        map((response) => {
          return this.instanceFactory.getInstanceBilling(response);
        }),
      );
  }

  /** Отмена подписки в конце биллинг периода */
  cancelSubscriptionAtEndBillingPeriod(): Observable<Billing> {
    return this.http
      .post<BillingDto>(
        `/billing/subscription/cancel`,
        { end_of_term: true },
        { context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true) },
      )
      .pipe(
        map((response) => {
          return this.instanceFactory.getInstanceBilling(response);
        }),
      );
  }

  /**
   * Изменение биллингового емейла
   */
  changeBillingEmail(email: string): Observable<Billing> {
    return this.http
      .patch<BillingDto>(
        '/billing',
        { email },
        { context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true) },
      )
      .pipe(
        map((response) => {
          return this.instanceFactory.getInstanceBilling(response);
        }),
      );
  }

  /** Удаление подключенной карты для оплат */
  deleteCard(): Observable<Billing> {
    return this.http.delete('/billing/card', {}).pipe(mergeMap(() => this.get()));
  }

  /**
   * Смена бухгалтерских емейлов
   *
   * @param bookerEmails Список бухгалтерских емейлов
   */
  editAccountingEmails(bookerEmails: string[]): Observable<Billing> {
    return this.http
      .patch<BillingDto>(
        '/billing',
        { booker_emails: bookerEmails },
        { context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true) },
      )
      .pipe(
        map((response) => {
          return this.instanceFactory.getInstanceBilling(response);
        }),
      );
  }

  /**
   * Комментарий из cqapi:
   * Генерирует временный invoice. Реальный Invoice в ChargeBee не создается
   *
   * @param params
   */
  generateTmpInvoice(params: ApiGenerateTmpInvoiceParams): Observable<ApiGenerateTmpInvoiceResponse> {
    let requestParams: ApiGenerateTmpInvoiceRequest = {
      amount: params.amount,
      initial: params.initial,
      plan_id: params.planId,
    };

    if (params.addons) {
      requestParams.addons = params.addons;
    }

    return this.http
      .post<ApiGenerateTmpInvoiceResponse>('/billing/tmp_invoices', requestParams)
      .pipe(map((response) => response));
  }

  /** Получение информации о биллинге */
  get(): Observable<Billing> {
    return this.http
      .get<any>(`/billing`, { context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true) })
      .pipe(
        map((response) => {
          response.subscription.paid_addons = response.paid_addons;
          return this.instanceFactory.getInstanceBilling(response as BillingDto);
        }),
      );
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@environment';
import { AppService } from '@http/app/services/app.service';
import {
  ALREADY_ACTIVE_TELEGRAM_BOT_MODAL_DATA_TOKEN,
  AlreadyActiveTelegramBotModalData,
} from '@panel/app/partials/modals/already-active-telegram-bot-modal/already-active-telegram-bot-modal.token';

@Component({
  selector: 'cq-already-active-telegram-bot-modal',
  templateUrl: './already-active-telegram-bot-modal.component.html',
  styleUrls: ['./already-active-telegram-bot-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlreadyActiveTelegramBotModalComponent implements OnInit {
  confirmNameControl!: FormControl<string | null>;

  ngOnInit() {
    const confirmNameValidator: ValidatorFn = (control) => {
      const value: string = control.value ?? '';
      return value.trim() === this.appName ? null : { confirmName: control.value };
    };
    this.confirmNameControl = new FormControl<string | null>(null, confirmNameValidator);
  }

  constructor(
    public activeModal: NgbActiveModal,
    private readonly appService: AppService,
    @Inject(ALREADY_ACTIVE_TELEGRAM_BOT_MODAL_DATA_TOKEN)
    public readonly data: AlreadyActiveTelegramBotModalData,
    private readonly translocoService: TranslocoService,
  ) {}

  get actionType(): 'pause' | 'remove' {
    return this.data.actionType;
  }

  get appName(): string | undefined {
    return this.data.appName;
  }

  get botCount(): number {
    return this.data.botCount;
  }

  get confirmButtonText(): string {
    switch (this.data.actionType) {
      case 'pause':
        return this.translocoService.translate(
          `alreadyActiveTelegramBotModalComponent.${this.actionType}.confirmButtonText`,
        );
      case 'remove':
        return this.translocoService.translate(
          `alreadyActiveTelegramBotModalComponent.${this.actionType}.confirmButtonText`,
        );
    }
  }

  get integrationName(): string {
    return this.data.integrationName;
  }

  get telegramBotListUrl(): string {
    return `${environment.panelUrl}/${this.appService.currentAppId}/messages/chat-bot?filterType=telegram`;
  }

  confirmRemove(): void {
    this.confirmNameControl.markAsTouched();
    if (this.confirmNameControl.valid) {
      this.activeModal.close();
    }
  }

  onClickConfirmModal(): void {
    if (this.actionType === 'pause') {
      this.activeModal.close();
      return;
    }

    if (this.actionType === 'remove') {
      this.confirmRemove();
    }
  }
}

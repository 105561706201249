import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, combineLatest, map, Observable, Subject, switchMap, tap } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';

import { AppService } from '@http/app/services/app.service';
import { LogsModel } from '@http/logs/logs.model';
import { Log } from '@http/logs/logs.types';
import { PaginationParamsRequest } from '@http/types';

type DateRangeChangeSubj = {
  startDate: moment.Moment;
  endDate: moment.Moment;
};
@Component({
  selector: 'cq-member-actions',
  templateUrl: './member-actions.component.html',
  styleUrls: ['./member-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberActionsComponent {
  /** Поток изменения даты */
  //Не придумал ничего лучше, чем повторно задать начальное значение, которое и так передается из инпута
  private _dateRange$: BehaviorSubject<DateRangeChangeSubj> = new BehaviorSubject({
    startDate: moment().subtract(6, 'days'),
    endDate: moment().endOf('day'),
  });
  @Input()
  set dateRange(value: DateRangeChangeSubj) {
    this._dateRange$.next(value);
  }

  /** Флаг подгрузки логов */
  isMoreDataLoading: boolean = true;

  /** Флаг загрузки логов */
  isDataLoading: boolean = true;

  /** Поток подгрузки логов */
  loadMore$: Subject<void> = new Subject();

  /** Массив для отображения логов в верстке */
  private logsCache: Log[] = [];

  /** Поток логов с подгрузкой следующих пачек и обновления по дате */
  membersActions$: Observable<Log[]> = combineLatest([
    //Обновление даты или ручная перезагрузка это всегда forceReload
    this._dateRange$.pipe(
      tap(() => (this.logsCache = [])),
      tap(() => (this.paginatePosition = null)),
      tap(() => (this.isDataLoading = false)),
    ),
    this.loadMore$.pipe(
      startWith(null),
      tap(() => (this.isMoreDataLoading = false)),
    ),
  ]).pipe(
    switchMap(([dataRange, _]) => {
      return this.logsModel.get(this.appService.currentAppId, dataRange, { paginatePosition: this.paginatePosition });
    }),
    tap(({ paginatePosition }) => this.updatePaginatePosition(paginatePosition)),
    map(({ membersActions }) => this.getLogs(membersActions)),
    tap(() => (this.isDataLoading = true)),
    tap(() => (this.isMoreDataLoading = true)),
  );

  paginatePosition: PaginationParamsRequest['paginatePosition'] = [];

  constructor(private readonly appService: AppService, private readonly logsModel: LogsModel) {}

  getLogs(membersActions: Log[]): Log[] {
    this.logsCache = [...this.logsCache, ...membersActions];
    return this.logsCache;
  }

  updatePaginatePosition(paginatePosition: PaginationParamsRequest['paginatePosition']) {
    this.paginatePosition = paginatePosition ? paginatePosition : null;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';

import { CarrotquestHelperProvider, L10nHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { AccountingEmailsModule } from '@panel/app/pages/subscription/general/signed/payment-info/accounting-emails/accounting-emails.module';
import { BillingEmailModule } from '@panel/app/pages/subscription/general/signed/payment-info/billing-email/billing-email.module';
import { CardModule } from '@panel/app/pages/subscription/general/signed/payment-info/card/card.module';
import { RequisitesModule } from '@panel/app/pages/subscription/general/signed/payment-info/requisites/requisites.module';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { ToFormattedMoneyPipe } from '@panel/app/shared/pipes/to-formatted-money/to-formatted-money.pipe';
import { SharedModule } from '@panel/app/shared/shared.module';

import { PaymentInfoComponent } from './payment-info.component';

@NgModule({
  declarations: [PaymentInfoComponent],
  exports: [PaymentInfoComponent],
  imports: [
    AccountingEmailsModule,
    BillingEmailModule,
    CardModule,
    CommonModule,
    RequisitesModule,
    TranslocoModule,
    TuiLetModule,
    SharedModule,
  ],
  providers: [ToFormattedMoneyPipe, CarrotquestHelperProvider, L10nHelperProvider, SubscriptionStore],
})
export class PaymentInfoModule {}

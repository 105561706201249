import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Invoice } from '@http/invoice/invoice';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

/** Компонент для работы со списком счетов */
@Component({
  selector: 'cq-list[invoices][isNeedToShowShowMoreButton][clickShowMoreButton]',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  /** Счета */
  @Input()
  invoices!: Invoice[];

  /** Нужно ли показывать кнопку дополнительной загрузки */
  @Input()
  isNeedToShowShowMoreButton: boolean = true;

  /** Callback на нажатие кнопки дополнительной загрузки */
  @Output()
  clickShowMoreButton: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly l10nHelperService: L10nHelperService,
  ) {}

  /** Обработчик на нажатие кнопки дополнительной загрузки счетов */
  onClickShowMoreButton(): void {
    this.clickShowMoreButton.emit();
  }

  /** Определяет нужно ли показывать кнопку оплаты счёта */
  isNeedToShowInvoicePayButton(): boolean {
    return this.l10nHelperService.isRusCountry();
  }

  /** Трек клика по кнопке оплаты счёта */
  trackClickPayButton(): void {
    const eventName = 'Оплата - клик в таблице по Оплатить';

    this.carrotquestHelper.track(eventName);
  }
}

import { InjectionToken, Provider } from '@angular/core';
import { UIRouter } from '@uirouter/core';

import { CHAT_BOT_URL_PATHS } from '@panel/app-old/components/chat-bot/chat-bot-routes.constants';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';

export const CHAT_BOT_TYPE_TOKEN = new InjectionToken<CHAT_BOT_TYPE>('Type of opened chat-bot editor');

export const chatBotTypeTokenProvider: Provider = {
  provide: CHAT_BOT_TYPE_TOKEN,
  useFactory: (uiRouter: UIRouter) => {
    const urlPath = uiRouter.urlService.path();
    switch (true) {
      case urlPath.indexOf(CHAT_BOT_URL_PATHS.LEAD_BOT) > -1:
        return CHAT_BOT_TYPE.LEAD;
      case urlPath.indexOf(CHAT_BOT_URL_PATHS.WELCOME_BOT) > -1:
        return CHAT_BOT_TYPE.ROUTING;
      case urlPath.indexOf(CHAT_BOT_URL_PATHS.FACEBOOK_BOT) > -1:
        return CHAT_BOT_TYPE.FACEBOOK;
      case urlPath.indexOf(CHAT_BOT_URL_PATHS.TELEGRAM_BOT) > -1:
        return CHAT_BOT_TYPE.TELEGRAM;
      case urlPath.indexOf(CHAT_BOT_URL_PATHS.WIDGET_BOT) > -1:
        return CHAT_BOT_TYPE.WIDGET;
      default:
        throw new Error('Failed to determine the type of chatbot');
    }
  },
  deps: [UIRouter],
};

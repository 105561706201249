<ng-container *transloco="let t; read: 'channelsTableComponent'">
  <cq-access-denial-banner
    *ngIf="!accessToMessagesChannels.hasAccess"
    [currentApp]="currentApp"
    [denialReason]="accessToMessagesChannels.denialReason"
  >
  </cq-access-denial-banner>

  <div class="flex align-items-center justify-space-between px-20 py-15">
    <div>
      <div>{{ t('header.description.firstLine') }}</div>
      <div>
        <span>{{ t('header.description.secondLine') }}</span>
        <a
          target="_blank"
          [href]="t('header.description.knowledgeBaseLink.href')"
          >{{ t('header.description.knowledgeBaseLink.text') }}</a
        >
      </div>
    </div>
    <!-- Создание канала в ручную без ограничений -->
    <a
      *ngIf="accessToMessagesChannels.hasAccess"
      class="btn btn-primary"
      (click)="trackClickCreateChannel()"
      uiSref="app.content.channels.create"
      >{{ t('header.createChannelButton') }}</a
    >
    <!-- Создание канала в ручную с ограничениями -->
    <a
      *ngIf="!accessToMessagesChannels.hasAccess"
      class="btn btn-primary"
      (click)="paywallService.showPaywall(currentApp, accessToMessagesChannels.denialReason)"
    >
      <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
      <span>{{ t('header.createChannelButton') }}</span>
    </a>
  </div>
  <table
    *ngIf="channels.length"
    class="channels-table table table-sm table-align-middle table-hover unavailable table-sticky-header border-top"
    [ngClass]="{ in: (isChannelsRequestPerformed | async) === false }"
  >
    <thead>
      <tr>
        <th>{{ t('table.headings.channelName') }}</th>
        <th>{{ t('table.headings.operators') }}</th>
        <th>{{ t('table.headings.channelType') }}</th>
        <th>
          <span>{{ t('table.headings.priority.text') }}</span>
          <i
            class="cqi-sm cqi-question-circle"
            [ngbTooltip]="t('table.headings.priority.tooltip')"
            placement="bottom"
            container="body"
          ></i>
        </th>
        <th class="shrink-by-content"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="cursor-pointer"
        *ngFor="let channel of channels | orderBy: 'priority'; trackBy: trackByChannel; let $index = index"
        uiSref="app.content.channels.edit"
        [uiParams]="{ channelId: channel.id }"
      >
        <td>
          <cq-channel-icon
            class="align-middle margin-right-10"
            [channel]="channel"
            lg
          ></cq-channel-icon>
          <strong class="align-middle">{{ channel.name }}</strong>
        </td>
        <td class="margin-between-cols-5">
          <div
            class="inline-block"
            *ngFor="let operator of channel.operators | slice: 0:OPERATORS_LIMIT"
          >
            <img
              class="avatar"
              [src]="operator.avatar"
              [ngbTooltip]="operator.name"
            />
          </div>
          <span
            *ngIf="channel.operators.length > OPERATORS_LIMIT"
            class="badge badge-pill badge-secondary"
            >{{ '+' + (channel.operators.length - OPERATORS_LIMIT) }}</span
          >
        </td>
        <td>{{ 'models.channel.types.' + channel.type + '.name' | transloco }}</td>
        <td>{{ channels.length - channel.priority }}</td>
        <td class="shrink-by-content text-right">
          <button
            *ngIf="$index < channels.length - 1"
            class="btn btn-borderless-primary"
            type="button"
            (click)="$event.stopPropagation(); changeChannelPriority(channel, $index + 1)"
          >
            <i class="btn-icon cqi-sm cqi-arrow-down"></i>
          </button>
          <button
            *ngIf="$index > 0"
            class="btn btn-borderless-primary"
            type="button"
            (click)="$event.stopPropagation(); changeChannelPriority(channel, $index - 1)"
          >
            <i class="btn-icon cqi-sm cqi-arrow-up"></i>
          </button>
          <a
            class="btn btn-borderless-primary"
            uiSref="app.content.channels.edit"
            [uiParams]="{ channelId: channel.id }"
          >
            <i class="btn-icon cqi-sm cqi-pencil"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    *ngIf="!channels.length"
    class="card-body"
  >
    <div class="zero-data-no-channels mx-auto text-center">
      <h4>{{ t('zeroData.heading') }}</h4>
      <div class="margin-bottom-30">{{ t('zeroData.description') }}</div>
      <div class="margin-between-cols-10">
        <!-- Создание канала в ручную без ограничений -->
        <a
          *ngIf="accessToMessagesChannels.hasAccess"
          class="btn btn-primary"
          (click)="trackClickCreateChannel()"
          uiSref="app.content.channels.create"
          >{{ t('zeroData.createChannelButton') }}</a
        >
        <!-- Создание канала в ручную с ограничениями -->
        <a
          *ngIf="!accessToMessagesChannels.hasAccess"
          class="btn btn-primary"
          (click)="paywallService.showPaywall(currentApp, accessToMessagesChannels.denialReason)"
        >
          <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
          <span>{{ t('zeroData.createChannelButton') }}</span>
        </a>

        <!-- Использование интеграций без ограничений -->
        <a
          *ngIf="accessToIntegrations.hasAccess"
          class="btn btn-outline-primary"
          uiSref="app.content.integrations.general"
          >{{ t('zeroData.setupIntegrationButton') }}</a
        >
        <!-- Использование интеграций с ограничениями -->
        <a
          *ngIf="!accessToIntegrations.hasAccess"
          class="btn btn-outline-primary"
          (click)="paywallService.showPaywall(currentApp, accessToIntegrations.denialReason)"
        >
          <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
          <span>{{ t('zeroData.setupIntegrationButton') }}</span>
        </a>
      </div>
    </div>
  </div>
</ng-container>

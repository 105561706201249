import { environment } from '@environment';

/**
 * Сервис для работы со скриптом для установки сервиса
 */
export class ServiceScriptService {
  /**
   * Получение скрипта для установки сервиса
   */
  getScript(apiKey: string): string {
    // language=HTML
    return `<!-- ${environment.projectName} BEGIN -->
<script type="text/javascript">
!function(){function t(t,e){return function(){window.${environment.apiObjectName}async.push(t,arguments)}}if("undefined"==typeof ${environment.apiObjectName}){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="${environment.cdnEndpoint}/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.${environment.apiObjectName}={},window.${environment.apiObjectName}async=[],${environment.apiObjectName}.settings={};for(var n=["connect","track","identify","auth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)${environment.apiObjectName}[n[a]]=t(n[a])}}(),${environment.apiObjectName}.connect("${apiKey}");
</script>
<!-- ${environment.projectName} END -->`;
  }
}

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';

/**
 * Компонент для редактирования текста в кнопке чат-виджета
 */
@Component({
  selector: 'cq-chat-widget-button-text[value]',
  templateUrl: './chat-widget-button-text.component.html',
  styleUrls: ['./chat-widget-button-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatWidgetButtonTextComponent {
  VALUE_MAX_LENGTH = 255;

  control: FormControl<string | null> = new FormControl<string | null>(null, [
    Validators.required,
    Validators.maxLength(this.VALUE_MAX_LENGTH),
  ]);

  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  @Input()
  set value(value: string) {
    this.control.setValue(value);
  }

  @Output()
  touchAndValidateCallback: Observable<ValidationCallback> = of(() => {
    this.control.markAsTouched();
    return this.control.disabled
      ? Promise.resolve(true)
      : this.control.status !== 'PENDING'
      ? Promise.resolve(this.control.valid)
      : firstValueFrom(
          this.control.statusChanges.pipe(
            filter((status) => status !== 'PENDING'),
            map(() => this.control.valid),
          ),
        );
  });

  @Output()
  valueChanges = this.control.valueChanges.pipe(distinctUntilChanged());
}

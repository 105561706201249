(function () {
  'use strict';

  angular
    .module('myApp.modals.addAnswerTemplate')
    .controller('AddAnswerTemplateModalController', AddAnswerTemplateModalController);

  function AddAnswerTemplateModalController(message) {
    var vm = this;

    init();

    function init() {
      vm.message = message;
    }
  }
})();

import { Inject, Injectable, NgZone } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Container, Graphics, LINE_CAP, LINE_JOIN } from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { combineLatest, merge, mergeMap, Observable, Subject, switchMap } from 'rxjs';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { UUID } from 'short-uuid';

import { PIXI_APP, PIXI_VIEWPORT, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { IPoint } from '@panel/app/pages/chat-bot/content/views/connection';
import { BOT_MESSAGE_BLOCK_PRIMARY_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { DestroyService } from '@panel/app/services';
import { CanvasBaseService } from '@panel/app/services/canvas/common/base/canvas-base.service';
import { CanvasInteractionService } from '@panel/app/services/canvas/common/interaction/canvas-interaction.service';
import { IBlockView } from '@panel/app/services/canvas/tirgger-chain/blocks/block.interfaces';
import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { BlockViewService } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.service';
import { Connection } from '@panel/app/services/canvas/tirgger-chain/connections/connection.types';
import { ConnectionValidationService } from '@panel/app/services/canvas/tirgger-chain/connections/connection-validation.service';
import { filterByInteractionViewType } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.helpers';
import { InteractionService } from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.service';
import {
  ExtendInteractionConfig,
  INTERACTION_ENTITY,
} from '@panel/app/services/canvas/tirgger-chain/interactions/interaction.types';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';
import { decreaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/decrease-ticker-max-fps.function';
import { increaseTickerMaxFPS } from '@panel/app/shared/functions/pixi/increase-ticker-max-fps.function';
import { enterZone } from '@panel/app/shared/functions/zone/enter-zone.function';
import { parallelWith } from '@panel/app/shared/functions/zone/parallel-with';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

type ConnectionChangeEvent = {
  sourceStepUUID: UUID;
  typeOfUpdate: 'nextStepOnSuccess' | 'nextStepOnFail';
  connectionInfo: Connection | null;
};

/**
 * Сервис для работы со связями между view на canvas
 */
@Injectable()
export class ConnectionService {
  /** Store связей */
  private store: Connection[] = [];

  /** Связь добавлена */
  private readonly connectionChangeSubj: Subject<ConnectionChangeEvent> = new Subject<ConnectionChangeEvent>();

  private readonly connectionDrawStartSubj: Subject<void> = new Subject();

  private readonly connectionDrawEndSubj: Subject<void> = new Subject();

  readonly connectionDrawStart$ = this.connectionDrawStartSubj.asObservable();

  readonly connectionDrawEnd$ = this.connectionDrawEndSubj.asObservable();

  readonly connectionChange$: Observable<ConnectionChangeEvent> = this.connectionChangeSubj.asObservable();

  constructor(
    private readonly canvasInteractionService: CanvasInteractionService,
    private readonly canvasBaseService: CanvasBaseService,
    private readonly destroy$: DestroyService,
    private readonly interactionService: InteractionService,
    @Inject(PIXI_VIEWPORT)
    public readonly viewport: Viewport,
    @Inject(PIXI_APP)
    public readonly pixiApp: PixiApplication,
    @Inject(WINDOW)
    public readonly window: Window,
    private readonly toastService: ToastService,
    private readonly ngZone: NgZone,
    private readonly blockViewService: BlockViewService,
    private readonly connectionValidationService: ConnectionValidationService,
  ) {}

  /** Инициализация наблюдателей */
  initObservers(): void {
    this.initDrawingOnPointerDown();
    this.initRedrawOnBlockMove();
  }

  private initDrawingOnPointerDown() {
    let drawnConnection: Graphics | null = null;

    const clearDrawnConnection = () => {
      drawnConnection?.destroy();
      drawnConnection = null;
    };

    this.interactionService.pointerDown$
      .pipe(
        takeUntil(this.destroy$),
        filterByInteractionViewType(INTERACTION_ENTITY.INTERACTIVE_BLOCK_PART),
        filter(pickOnlyConnectionPoint),
        tap(({ event }) => event.stopPropagation()),
        tap(({ view: sourceView }) => {
          let existedConnection = this.store.find((connection) => connection.source === sourceView);
          if (existedConnection) {
            this.removeConnection(existedConnection);
          }
        }),
        increaseTickerMaxFPS(this.pixiApp),
        mergeMap(({ view: sourceView }) => startDrawing$(sourceView)),
      )
      .subscribe();

    const startDrawing$ = (source: AbsInteractiveBlockPartView) =>
      combineLatest([
        this.interactionService.pointerMove$.pipe(
          filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
          switchMap(() => this.canvasInteractionService.pointerMove$),
        ),
        this.canvasInteractionService.moved$.pipe(startWith(null)),
      ]).pipe(
        tap(() => this.connectionDrawStartSubj.next()),
        parallelWith(changeStatusOnHover$(source)),
        map(([event, _]) => event),
        tap(clearDrawnConnection),
        tap((event) => {
          let sourceCoords = this.getCorrectCoordsSourceConnectionPoint(source);
          let targetCoords = this.viewport.toLocal(event.global);
          let renderedConnection = this.renderBezierLine(sourceCoords, targetCoords);
          this.viewport.addChild(renderedConnection);

          drawnConnection = renderedConnection;
        }),
        takeUntil(endDraw$(source)),
      );

    const endDraw$ = (source: AbsInteractiveBlockPartView) =>
      merge(waitForValidPointerUp$(source), waitForInvalidPointerUp$(source)).pipe(
        decreaseTickerMaxFPS(this.pixiApp),
        tap(() => this.connectionDrawEndSubj.next()),
      );

    const waitForValidPointerUp$ = (source: AbsInteractiveBlockPartView) =>
      this.interactionService.pointerUp$.pipe(
        filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
        tap(clearDrawnConnection),
        tap(({ view }) => resetViewState(view)),
        filter((target) => {
          return source.stepView.uuid !== target.view.uuid;
        }),
        filter(
          (target) =>
            this.connectionValidationService.validate(source.stepView, target.view, { connections: this.store }) ===
            null,
        ),
        tap(({ view: targetView }) => {
          let sourceCoords = this.getCorrectCoordsSourceConnectionPoint(source);
          let targetCoords = this.getCorrectCoordsTargetConnectionPoint(targetView);
          let renderedConnection = this.renderBezierLine(sourceCoords, targetCoords);
          this.addConnectionToViewport(renderedConnection, source, targetView);
        }),
      );

    const waitForInvalidPointerUp$ = (source: AbsInteractiveBlockPartView) =>
      merge(
        this.canvasInteractionService.pointerUp$,
        this.interactionService.pointerUp$.pipe(
          filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
          tap(({ view }) => resetViewState(view)),
          filter((target) => {
            return source.stepView.uuid !== target.view.uuid;
          }),
          map((target) =>
            this.connectionValidationService.validate(source.stepView, target.view, { connections: this.store }),
          ),
          filter((validationResult): validationResult is string => validationResult !== null),
          tap((error) => this.toastService.danger(error)),
          enterZone(this.ngZone),
        ),
      ).pipe(tap(clearDrawnConnection));

    const changeStatusOnHover$ = (source: AbsInteractiveBlockPartView) =>
      merge(indicateMouseout$(), indicateMouseover$(source));

    const indicateMouseout$ = () =>
      this.interactionService.mouseOut$.pipe(
        filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
        filter(({ view }) => view.type !== TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION),
        tap(({ view }) => resetViewState(view)),
      );

    const indicateMouseover$ = (source: AbsInteractiveBlockPartView) =>
      this.interactionService.mouseOver$.pipe(
        filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
        filter(({ view }) => view.type !== TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION),
        filter(({ view }) => view.uuid !== source.stepView.uuid),
        map((target) => {
          return [
            target,
            this.connectionValidationService.validate(source.stepView, target.view, { connections: this.store }),
          ] as const;
        }),
        tap(([{ view }, validationError]) => {
          if (validationError) {
            view.data.hoveredInvalid = true;
          } else {
            view.data.hoveredValid = true;
          }

          this.blockViewService.updateView(view, view.data);
        }),
      );

    function pickOnlyConnectionPoint(
      event: Extract<ExtendInteractionConfig, { type: INTERACTION_ENTITY.INTERACTIVE_BLOCK_PART }>,
    ) {
      return event.event.target === event.view.connectionPoint;
    }

    const resetViewState = (view: IBlockView) => {
      view.data.hoveredValid = false;
      view.data.hoveredInvalid = false;
      this.blockViewService.updateView(view, view.data);
    };
  }

  private initRedrawOnBlockMove() {
    this.interactionService.move$
      .pipe(
        takeUntil(this.destroy$),
        filterByInteractionViewType(INTERACTION_ENTITY.BLOCK),
        map((event) => {
          return this.getConnectionsByView(event.view);
        }),
        filter((connections) => connections.length > 0),
      )
      .subscribe((affectedConnection) => {
        affectedConnection.forEach((c) => {
          let oldConnection = c.connection;

          let sourceCoords = this.getCorrectCoordsSourceConnectionPoint(c.source);
          let targetCoords = this.getCorrectCoordsTargetConnectionPoint(c.target);
          let newConnection = this.renderBezierLine(sourceCoords, targetCoords);

          this.viewport.removeChild(oldConnection);
          this.viewport.addChild(newConnection);

          c.connection = newConnection;
        });
      });
  }

  /**
   * Добавляет связь
   *
   * @param connection - Связь
   * @param source - Источник
   * @param target - Цель
   */
  private addConnectionToViewport(connection: Graphics, source: AbsInteractiveBlockPartView, target: IBlockView): void {
    this.viewport.addChild(connection);

    const addedConnection: Connection = {
      connection,
      source,
      target,
    };

    this.store.push(addedConnection);

    let connectionChangeEvent: ConnectionChangeEvent = {
      sourceStepUUID: source.stepView.uuid,
      typeOfUpdate: this.getTypeOfUpdateForConnectionChangeEvent(source),
      connectionInfo: addedConnection,
    };
    this.connectionChangeSubj.next(connectionChangeEvent);
  }

  getConnectionsByView(view: IBlockView): Connection[] {
    return this.store.filter((connection) => {
      return connection.target === view || connection.source.stepView === view;
    });
  }

  /**
   * Добавление связи по шагам
   *
   * @param source - Шаг источник
   * @param target - Шаг цель
   */
  addConnectionBySteps(source: AbsInteractiveBlockPartView, target: IBlockView) {
    let sourceCoords = this.getCorrectCoordsSourceConnectionPoint(source);
    let targetCoords = this.getCorrectCoordsTargetConnectionPoint(target);
    let connection = this.renderBezierLine(sourceCoords, targetCoords);

    this.addConnectionToViewport(connection, source, target);
  }

  private getTypeOfUpdateForConnectionChangeEvent(
    sourceAction: AbsInteractiveBlockPartView,
  ): ConnectionChangeEvent['typeOfUpdate'] {
    switch (sourceAction.type) {
      case INTERACTIVE_BLOCK_PART.REACTION:
      case INTERACTIVE_BLOCK_PART.AUTOMESSAGE_SENT:
      case INTERACTIVE_BLOCK_PART.DELAY:
      case INTERACTIVE_BLOCK_PART.SENDING_CONDITION_TRIGGERS:
      case INTERACTIVE_BLOCK_PART.FILTER_MATCH:
        return 'nextStepOnSuccess';
      case INTERACTIVE_BLOCK_PART.DELAY_PASSED:
      case INTERACTIVE_BLOCK_PART.FILTER_NOT_MATCH:
      case INTERACTIVE_BLOCK_PART.NO_REACTION:
        return 'nextStepOnFail';
    }
  }

  removeConnectionsByStepUUID(stepUUID: UUID) {
    const connectionsToRemove = this.store.filter(({ source, target }) => {
      return source.stepView.uuid === stepUUID || target.uuid === stepUUID;
    });

    connectionsToRemove.forEach((connection) => {
      this.removeConnection(connection);
    });
  }

  private removeConnection(connection: Connection, notifyChanges: boolean = true): void {
    this.store.splice(this.store.indexOf(connection), 1);
    this.viewport.removeChild(connection.connection);
    connection.connection.destroy();

    if (notifyChanges) {
      const connectionChangeEvent: ConnectionChangeEvent = {
        sourceStepUUID: connection.source.stepView.uuid,
        typeOfUpdate: this.getTypeOfUpdateForConnectionChangeEvent(connection.source),
        connectionInfo: null,
      };
      this.connectionChangeSubj.next(connectionChangeEvent);
    }
  }

  /**
   * Перерисовываем исходящие связи (используется при перерисовке блоков, так как у connectionPoint может быть смещение)
   */
  redrawOutgoingConnectionsForView(view: IBlockView) {
    const connectionsToUpdate = this.store.filter(({ source, target }) => {
      return source.stepView.uuid === view.uuid;
    });

    if (connectionsToUpdate.length === 0) {
      return;
    }

    connectionsToUpdate.forEach((c) => {
      if (!c.source.hasConnection()) {
        return this.removeConnection(c, false);
      }

      this.redrawConnectionView(c);
    });
  }

  redrawAllConnections() {
    this.store.forEach((c) => {
      this.redrawConnectionView(c);
    });
  }

  private redrawConnectionView(c: Connection) {
    let connection = c.connection;
    connection.destroy();

    let sourceCoords = this.getCorrectCoordsSourceConnectionPoint(c.source);
    let targetCoords = this.getCorrectCoordsTargetConnectionPoint(c.target);
    let newConnection = this.renderBezierLine(sourceCoords, targetCoords);

    c.connection = newConnection;

    this.viewport.addChild(newConnection);
  }

  /**
   * Рендерит бизер-линию со стрелкой
   *
   * @param sourceCoords - Начальные координаты
   * @param targetCoords - Конечные координаты
   */
  private renderBezierLine(sourceCoords: IPoint, targetCoords: IPoint): Graphics {
    // Вспомогательная функция для расчёта координат
    const calcCoordToFrom = (point: IPoint): [x: number, y: number] => {
      return [point.x - sourceCoords.x, point.y - sourceCoords.y];
    };

    let line = new Graphics();

    line.zIndex = 0;
    line.addChild(line);

    // Настройка формулы Бизера
    let bezierIndent = Math.max(Math.abs(targetCoords.x - sourceCoords.x) * 0.3, 75);
    let bezierP2: IPoint = {
      x: sourceCoords.x + bezierIndent,
      y: sourceCoords.y,
    };
    let bezierP3: IPoint = {
      x: targetCoords.x - bezierIndent,
      y: targetCoords.y,
    };

    // Настройка стрелки в конце линии
    let arrowWidth = 4;
    let arrowHeight = 3;
    let arrowTopSide: IPoint = {
      x: targetCoords.x - arrowWidth,
      y: targetCoords.y - arrowHeight,
    };
    let arrowBottomSide: IPoint = {
      x: targetCoords.x - arrowWidth,
      y: targetCoords.y + arrowHeight,
    };

    // Рисование линии
    line.lineTextureStyle({
      width: 1,
      color: BOT_MESSAGE_BLOCK_PRIMARY_COLOR,
      cap: LINE_CAP.ROUND,
      join: LINE_JOIN.ROUND,
    });
    line.bezierCurveTo(...calcCoordToFrom(bezierP2), ...calcCoordToFrom(bezierP3), ...calcCoordToFrom(targetCoords));

    // Рисование стрелки в конце линии
    line.moveTo(...calcCoordToFrom(arrowTopSide));
    line.lineTo(...calcCoordToFrom(targetCoords));
    line.lineTo(...calcCoordToFrom(arrowBottomSide));

    // Позиционирование линии
    line.position.x = sourceCoords.x;
    line.position.y = sourceCoords.y;

    return line;
  }

  private getCorrectCoordsSourceConnectionPoint(source: AbsInteractiveBlockPartView): IPoint {
    let coords = ConnectionService.getRelativePosition(source.connectionPoint, this.viewport);

    coords.x = coords.x + source.connectionPoint.width;
    coords.y = coords.y + source.connectionPoint.height / 2;

    return coords;
  }

  private getCorrectCoordsTargetConnectionPoint(target: IBlockView): IPoint {
    if (!target.connectionPoint) {
      throw new Error('Method cannot be called for a target with no connection point');
    }

    let coords = ConnectionService.getRelativePosition(target.connectionPoint, this.viewport);

    const scaleMultiplier = !!target.zoomScalableContainer ? this.canvasBaseService.scaleValue : 1;

    // отступ небольшой, чтоб стрелка не врезалась прямо в элемент, и оставался небольшой отступ
    coords.x = coords.x - 3;
    coords.y = coords.y + (target.connectionPoint.height * scaleMultiplier) / 2;

    return coords;
  }

  /**
   * TODO Кажется, это в какие-то утилиты/хелперы, надо вынести
   * @param childContainer - контейнер, позицию которого ищем
   * @param deepParent - контейнер, относительно которого ищем позицию
   * @param viewport - Опциональный. Нужен, чтоб сделать поправку на zoom, если deepParent это сам viewport или pixiApp.container
   */
  static getRelativePosition(childContainer: Container, deepParent: Container, viewport?: Viewport) {
    let x: number = 0;
    let y: number = 0;

    let lastContainer: Container = childContainer;

    while (lastContainer !== deepParent) {
      x += lastContainer.x;
      y += lastContainer.y;

      if (!lastContainer.parent) {
        throw new Error('Could not find a parent container');
      }

      if (lastContainer.parent === viewport) {
        x *= viewport.scaled;
        y *= viewport.scaled;
      }

      lastContainer = lastContainer.parent;
    }

    return { x, y };
  }
}

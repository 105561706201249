import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { MomentModule } from 'ngx-moment';

import { PermissionDeniedModule } from '@panel/app/pages/permission-denied/permission-denied.module';
import { FirebaseKeyModule } from '@panel/app/pages/to-developers/shared/firebase-key/firebase-key.module';

import { ToDevelopersComponent } from './to-developers.component';

@NgModule({
  declarations: [ToDevelopersComponent],
  imports: [CommonModule, FirebaseKeyModule, PermissionDeniedModule, TranslocoModule, MomentModule],
})
export class ToDevelopersModule {}

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('ShopifyController', ShopifyController);

  function ShopifyController($state, $window, PROJECT_NAME, appModel, currentApp, integration) {
    let vm = this;

    vm.appModel = appModel;
    vm.checkCodeInstall = checkCodeInstall;
    vm.currentApp = currentApp;
    vm.integration = integration;
    vm.isManualExpanded = angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.toggleManualVisibility = toggleManualVisibility;

    /**
     *  Открытие страницы магазина + переадресация на батарейку
     */
    function checkCodeInstall() {
      let protocol = vm.integration.settings.useSsl ? 'https' : 'http';
      let shopUrl = protocol + '://' + vm.integration.settings.shop;

      $window.open(shopUrl);
      $state.go('app.content.dashboard');
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

<div class="card m-0 cursor-pointer">
  <div class="card-header-wrap position-relative">
    <div
      class="card-header position-absolute p-0 top-0 right-0 bottom-0 left-0"
      [ngStyle]="{ backgroundImage: 'url(' + getTemplateUrl(template.preview) + ')' }"
    ></div>
  </div>

  <div class="card-body">
    <div class="flex align-items-center mb-10 text-muted">
      <i
        *ngIf="$any(MESSAGE_PART_TYPE_ICON_CLASSES)[template.type]"
        class="cqi-sm margin-right-10"
        [ngClass]="$any(MESSAGE_PART_TYPE_ICON_CLASSES)[template.type]"
      ></i>
      <span>{{ 'models.message.messagePartTypes.' + template.type | transloco }}</span>
    </div>
    <div
      class="template-name mb-10"
      data-test="template-name"
    >
      {{ template.name }}
    </div>
    <span
      class="badge badge-light-secondary full-max-width text-truncate"
      data-test="template-tags"
      >{{ 'models.starterGuideModel.appUsageGoal.' + template.taskType | transloco }}</span
    >
  </div>
</div>

/**
 * Контроллер для статистики ручных сообщений
 */
import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { SENDING_TYPES } from '../../../../app/http/message/message.constants';
import { firstValueFrom } from 'rxjs';
import {
  EMAIL_TYPES,
  MESSAGE_PART_TYPES,
  POPUP_CHAT_TYPES,
  TYPE_OS_PREVIEW,
} from '../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular
    .module('myApp.autoMessages')
    .controller('CqAutoMessageStatisticsController', CqAutoMessageStatisticsController);

  function CqAutoMessageStatisticsController(
    $filter,
    $q,
    $scope,
    $state,
    $timeout,
    $translate,
    $uibModal,
    moment,
    AUTO_MESSAGE_STATISTICS_TABS,
    carrotquestHelper,
    caseStyleHelper,
    chartHelper,
    dateRangePickerHelper,
    featureModel,
    messageModel,
    messagePartModel,
    messageTestGroupModel,
    paywallService,
    planFeatureAccessService,
    timeUnitService,
  ) {
    var vm = this;

    var CAMELED_SENDING_TYPES = caseStyleHelper.valuesToCamelCase(angular.copy(SENDING_TYPES));
    var CONTROL_GROUP_SENDING_TYPE_COLORS = getControlGroupSendingTypeColors(CAMELED_SENDING_TYPES); // цвета для датасетов по контрольной группе
    var DATASET_TYPES = {
      // типы датасетов для графиков
      GENERAL: 'general',
      CONTROL_GROUP: 'controlGroup',
    };
    var fictiveTestGroup; // фиктивная тест-группа, нужна для вывода пункта 'За весь период' в фильтре пользователей и варианта сообщения с названием 'Кому отправлено сообщение' там же
    var MESSAGE_SENDING_TYPE_COLORS = getMessageSendingTypeColors(CAMELED_SENDING_TYPES); // цвета для датасетов по по сообщению

    vm.$onInit = init;

    function init() {
      vm.accessToAutoMessagesAbTesting = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_AB_TESTING,
        vm.currentApp,
      );
      vm.accessToAutoMessagesControlGroup = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_CONTROL_GROUP,
        vm.currentApp,
      );

      fictiveTestGroup = {
        id: -1,
        name: $translate.instant('autoMessages.statistics.fictiveTestGroupNames.allPeriod'),
        parts: [
          messagePartModel.getDefault(),
          messagePartModel.filterControlGroup(vm.currentMessage.activeTestGroup.parts),
        ],
      };

      vm.$translate = $translate;
      vm.AUTO_MESSAGE_STATISTICS_TABS = AUTO_MESSAGE_STATISTICS_TABS;
      vm.CAMELED_SENDING_TYPES = CAMELED_SENDING_TYPES;
      vm.caseStyleHelper = caseStyleHelper;
      vm.completeAbTest = completeAbTest;
      vm.CONTROL_GROUP_SENDING_TYPE_COLORS = CONTROL_GROUP_SENDING_TYPE_COLORS;
      vm.controlGroup = messagePartModel.filterControlGroup(vm.currentMessage.activeTestGroup.parts); // контрольная группа
      vm.currentTab = AUTO_MESSAGE_STATISTICS_TABS.GENERAL; // выбранная вкладка
      vm.DATASET_TYPES = DATASET_TYPES;
      vm.EMAIL_TYPES = EMAIL_TYPES;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.fictiveTestGroup = fictiveTestGroup;
      vm.getBlockPopupCount = getBlockPopupCount;
      vm.statisticsRange = {
        // датапикер на вкладке общей статистики
        dates: {
          // период отображения статистики
          startDate:
            moment().startOf('day').diff(moment(vm.currentMessage.created).startOf('day'), 'days') > 6
              ? moment().subtract(6, 'days').startOf('day')
              : moment(vm.currentMessage.created).startOf('day'),
          endDate: moment().endOf('day'),
        },
        options: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          dateLimit: {
            quarters: 1,
          },
          eventHandlers: {
            'show.daterangepicker': trackClickDatepicker,
          },
          maxDate: moment().endOf('day'),
          // Данные о получателях больше 1.5 лет назад были удалены, поэтому ограничиваем дату
          minDate:
            moment().diff(moment(vm.currentMessage.created), 'months') >= 18
              ? moment().subtract(18, 'months').startOf('day')
              : moment(vm.currentMessage.created).startOf('day'),
          opens: 'left',
          parentEl: '.datepicker-holder',
        }),
      };
      vm.getSendings = getSendings;
      vm.MESSAGE_SENDING_TYPE_COLORS = MESSAGE_SENDING_TYPE_COLORS;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.messageParts = messagePartModel.filterMessageParts(vm.currentMessage.activeTestGroup.parts); // варианты сообщения
      vm.messageModel = messageModel;
      vm.onCurrentTestGroupChange = onCurrentTestGroupChange;
      vm.onTestGroupCollapsed = onTestGroupCollapsed;
      vm.onTestGroupExpanding = onTestGroupExpanding;
      vm.openUser = openUser;
      vm.paywallService = paywallService;
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.SENDING_TYPES = SENDING_TYPES;
      vm.showSendings = showSendings;
      vm.statisticsChart = {
        // график для вывода статистики
        type: 'line', // тип графика
        instance: null, // текущий инстанс графика
        controlGroupDatasetsShown: [
          // типы датасетов по контрольной группе, которые показываются на графике в текущий момент
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.GOAL_COMPLETED,
        ],
        controlGroupDatasetsToShow: [
          // типы датасетов по контрольной группе, которые могут показываться на графике (из них строятся чекбоксы)
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.GOAL_COMPLETED,
        ],
        datasetsShown: [
          // типы датасетов, которые показываются на графике в текущий момент
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.GOAL_COMPLETED,
        ],
        datasetsToShow: [
          // типы датасетов, которые могут показываться на графике (из них строятся чекбоксы)
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.READ,
          CAMELED_SENDING_TYPES.REPLIED,
          CAMELED_SENDING_TYPES.CLICKED,
          CAMELED_SENDING_TYPES.GOAL_COMPLETED,
        ],
        defaultDataset: {
          // датасет по умолчанию, на основе которого создаются все датасеты для графика
          datasetSendingType: CAMELED_SENDING_TYPES.SENDED, // тип датасета по действию получателей сообщения
          datasetType: DATASET_TYPES.GENERAL, // тип датасета по типу варианта сообщения
          data: [],
          fill: false,
          hidden: false,
          label: '',
          lineTension: 0,
          sum: 0,
        },
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          elements: {
            line: {
              borderWidth: 2,
            },
          },
          layout: {
            padding: {
              top: 20,
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkipPadding: 10,
                  maxRotation: 0,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: chartHelper.removeDecimalLabels,
                },
              },
            ],
          },
          tooltips: {
            position: 'nearest',
          },
          zeroData: {
            text: $translate.instant('autoMessages.statistics.zeroData.dataset'),
          },
        },
      };
      vm.testGroups = []; // массив тест-групп сообщения
      vm.testGroupsWithAbTest = []; // массив тест-групп сообщения с А/Б тестом
      vm.timeUnitService = timeUnitService;
      vm.toggleDatasetsToShow = toggleDatasetsToShow;
      vm.trackClickAddAbTest = trackClickAddAbTest;
      vm.trackClickSendingFilter = trackClickSendingFilter;
      vm.trackClickSendingTypeFilter = trackClickSendingTypeFilter;
      vm.trackClickSetGoal = trackClickSetGoal;
      vm.trackClickShowSendings = trackClickShowSendings;
      vm.trackEnterInTab = trackEnterInTab;
      vm.TYPE_OS_PREVIEW = TYPE_OS_PREVIEW;
      vm.sendings = {
        // данные для вкладки 'Пользователи'
        currentStatistics: {}, // статистика для выбранного варианта сообщения из выбранной тест группы за выбранный промежуток времени
        currentMessagePart: fictiveTestGroup.parts[0], // выбранный вариант сообщения в фильтре
        currentSendingType: SENDING_TYPES.SENDED, // выбранный тип действий получателей сообщения
        currentTestGroup: fictiveTestGroup, // текущая тест-группа
        dateRangePickerOptions: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          dateLimit: {
            quarters: 1,
          },
          maxDate: moment().endOf('day'),
          minDate:
            // Данные о получателях больше 1.5 лет назад были удалены, поэтому ограничиваем дату
            moment().diff(moment(vm.currentMessage.created), 'months') >= 18
              ? moment().subtract(18, 'months').startOf('day')
              : moment(vm.currentMessage.created).startOf('day'),
          opens: 'right',
        }),
        forceReload: false, // флаг принудительной перезагрузки таблицы пользователей
        isRequestPerformed: false, // флаг выполнения запроса на получения пользователей в текущий момент времени
        paginatorParams: null, // параметры пагинации
        range: {
          // выбранный промежуток времени
          startDate:
            moment().startOf('day').diff(moment(vm.currentMessage.created).startOf('day'), 'days') > 6
              ? moment().subtract(6, 'days').startOf('day')
              : moment(vm.currentMessage.created).startOf('day'),
          endDate: moment().endOf('day'),
        },
        sendings: [], // список пользователей для таблицы
      };

      if (vm.currentMessage.type === MESSAGE_PART_TYPES.TELEGRAM) {
        // Удаляет статистику по READ для TG сообщений
        vm.statisticsChart.datasetsToShow.splice(1, 1);
      }

      // изначально в currentMessage.statistics содержится статистика за всё время, её сохраняем в другое поле, потому что в это, для универсализации работы в этом контроллере, будет записана статистика за период
      vm.currentMessage.allTimeStatistics = vm.currentMessage.statistics;

      // дополняем фиктивную тест-группу, не считая статистики, она будет заполнена статистикой за период, а не общей статистикой
      fictiveTestGroup.parts[0].id = -1;
      fictiveTestGroup.parts[0].name = $translate.instant('autoMessages.statistics.fictiveTestGroupNames.whomWasSent');

      // работа с тест-группами сообщения
      // в массив тест-групп с А/Б тестами помещаем активную тест-группу в случае, если в ней больше 1 варианта сообщения (т.е. проводится А/Б тест)
      // тест-группу нельзя передавать по ссылке, т.к. на вкладке Общая статистика будет использоваться агрегированная статистика по контрольной группе,
      // а на вкладке А/Б тест статистика для каждой тест-группы отдельно
      vm.currentMessage.activeTestGroup.statisticsRange = {
        dates: {
          startDate: moment().subtract(6, 'days').startOf('day'),
          endDate: moment().endOf('day'),
        },
      };
      vm.testGroups.push(angular.copy(vm.currentMessage.activeTestGroup));

      // получем список всех остальных тест-групп, которые существуют в сообщении
      getMessageTestGroups(vm.currentMessage.id);

      // получаем получателей по всему сообщению
      getSendingsStatistics(fictiveTestGroup.parts[0], vm.sendings.range.startDate, vm.sendings.range.endDate);

      // общая статистика обновляется если изменён период отображения
      $scope.$watchCollection('vm.statisticsRange.dates', function (newValue, oldValue) {
        // используется для отслеживания применения датапикера вместо события "apply.daterangepicker", т.к. это событие отрабатывает неправильно при выборе дат и клике за пределы датапикера (даты применятся, а событие не вызовется)
        if (newValue != oldValue) {
          trackApplyDatepicker();
        }

        vm.statisticsRange.description = generateStatisticsRangeDescription(newValue.startDate, newValue.endDate);

        $q.all([
          getMessageStatistics(vm.currentMessage.id, newValue.startDate, newValue.endDate),
          getControlGroupStatistics(vm.currentMessage.id, newValue.startDate, newValue.endDate),
        ]).then(function (statistics) {
          var messageStatistics = statistics[0];

          setDatasets(vm.currentMessage, vm.statisticsChart.datasetsToShow, vm.statisticsChart.datasetsShown);
          setDatasets(
            vm.controlGroup,
            vm.statisticsChart.controlGroupDatasetsToShow,
            vm.statisticsChart.controlGroupDatasetsShown,
          );

          vm.statisticsChart.data.labels = messageStatistics[Object.keys(messageStatistics)[0]].labels;

          chartHelper.updateChart(vm.statisticsChart.instance);
        });
      });

      // !!! здесь используется имеено $watchCollection, а не $watchGroup! Почему - читать тут: https://github.com/angular/angular.js/issues/8671
      // !!! просто использовать watch тоже нельзя, т.к. обработчик почему-то начинает вызываться бесконечно
      // если изменился хотя бы один из параметров - надо по-новой тянуть пользователей. Если изменились даты или вариант сообщения - надо ещё и статистику по этому варианту получать
      $scope.$watchCollection(
        '[vm.sendings.currentMessagePart, vm.sendings.range.startDate, vm.sendings.range.endDate, vm.sendings.currentSendingType]',
        function (newValues, oldValues) {
          // изменение любого параметра влечёт за собой полную перезагрузку страницы пользователей
          vm.sendings.forceReload = true;

          getSendings(newValues[0], newValues[1], newValues[2], newValues[3]);
          if (newValues[0] != oldValues[0] || newValues[1] != oldValues[1] || newValues[2] != oldValues[2]) {
            getSendingsStatistics(newValues[0], newValues[1], newValues[2]);
          }
        },
      );

      // если у какой-либо тест-группы меняются варианты - надо отфильтровать только тест-группы с А/Б тестами по-новой
      $scope.$watchCollection(
        function () {
          return vm.testGroups.map(mapFunction);

          function mapFunction(testGroup) {
            return testGroup.parts;
          }
        },
        function () {
          vm.testGroupsWithAbTest = messageTestGroupModel.filterAbTests(vm.testGroups);
        },
      );
    }

    /**
     * Завершение А/Б теста
     *
     * @param {Object} testGroup Тест-группа для закрытия
     */
    function completeAbTest(testGroup) {
      trackClickCompleteAbTest();

      var completeAbTestTestModal = $uibModal.open({
        controller: 'CompleteAbTestModalController',
        controllerAs: 'vm',
        resolve: {
          testGroup: angular.bind(null, angular.identity, testGroup),
          selectedMessagePart: angular.bind(null, angular.identity, null),
        },
        templateUrl: 'js/shared/modals/complete-ab-test/complete-ab-test.html',
      });

      completeAbTestTestModal.result.then(completeAbTestSuccess);

      function completeAbTestSuccess(data) {
        trackCompleteAbTest(data.selectedMessagePart);

        var newTestGroup = data.newTestGroup;
        getTestGroupParts(newTestGroup);
        vm.testGroups.unshift(newTestGroup);
      }
    }

    /**
     * Получение цветов для типов датасетов по контрольной группе
     *
     * @param {SENDING_TYPES} sendingTypes
     * @returns {Object}
     */
    function getControlGroupSendingTypeColors(sendingTypes) {
      // FIXME: цвета для графиков сделаны не слишком хорошо, убрать это всё, когда руки дойдут до рефакторинга
      var controlGroupColors = {};

      controlGroupColors[sendingTypes.GOAL_COMPLETED] = '#d8a5cc';
      controlGroupColors[sendingTypes.SENDED] = '#a37b9b';

      return controlGroupColors;
    }

    /**
     * Получение цветов для типов датасетов по сообщению
     *
     * @param {SENDING_TYPES} sendingTypes
     * @returns {Object}
     */
    function getMessageSendingTypeColors(sendingTypes) {
      // FIXME: цвета для графиков сделаны не слишком хорошо, убрать это всё, когда руки дойдут до рефакторинга
      var messageColors = [];

      messageColors[sendingTypes.CLICKED] = '#5a9ede';
      messageColors[sendingTypes.GOAL_COMPLETED] = '#377dc0';
      messageColors[sendingTypes.READ] = '#4086a3';
      messageColors[sendingTypes.REPLIED] = '#43a4cc';
      messageColors[sendingTypes.SENDED] = '#3e6372';

      return messageColors;
    }

    /**
     * Генерация текстового представление периода
     *
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @returns {String}
     */
    function generateStatisticsRangeDescription(startDate, endDate) {
      var resultString = [
        $translate.instant('autoMessages.statistics.general.datePeriodStatistic.heading.statisticFor'),
      ];
      var isEndDateSame = endDate.isSame(moment(), 'day');
      var diffInDays = endDate.diff(startDate, 'days');

      if (isEndDateSame && diffInDays == 0) {
        resultString.push(
          $translate.instant(
            'autoMessages.statistics.general.datePeriodStatistic.heading.today',
            { days: diffInDays + 1 },
            'messageformat',
          ),
        );
      } else {
        if (isEndDateSame) {
          resultString.push(
            $translate.instant(
              'autoMessages.statistics.general.datePeriodStatistic.heading.last',
              { days: diffInDays + 1 },
              'messageformat',
            ),
          );
        }
        resultString.push(
          $translate.instant(
            'autoMessages.statistics.general.datePeriodStatistic.heading.days',
            { days: diffInDays + 1 },
            'messageformat',
          ),
        );
      }

      return resultString.join(' ');
    }

    /**
     * Получение вариантов сообщения для тест-группы
     *
     * @param {Object} testGroup Тест-группа
     * @return {Promise}
     */
    function getTestGroupParts(testGroup) {
      let includeClosed = false;

      if (testGroup.closed) {
        includeClosed = true;
      }
      return firstValueFrom(messagePartModel.getMessageTestGroupParts(testGroup.id, includeClosed)).then(
        (messageParts) => (testGroup.parts = messageParts),
      );
    }

    /**
     * Получение статистики по контрольной группе
     *
     * @param {String} messageId ID сообщения
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @returns {Promise}
     */
    function getControlGroupStatistics(messageId, startDate, endDate) {
      return firstValueFrom(messageModel.getAggregatedControlGroupStatistics(messageId, startDate, endDate)).then(
        getAggregatedControlGroupStatisticsSuccess,
      );

      function getAggregatedControlGroupStatisticsSuccess(statistics) {
        vm.controlGroup.statistics = statistics;
        return vm.controlGroup.statistics;
      }
    }

    /**
     * Получение статистики сообщения за период
     *
     * @param {String} messageId ID сообщения
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @returns {Promise}
     */
    function getMessageStatistics(messageId, startDate, endDate) {
      return firstValueFrom(messageModel.getMessageStatistics(messageId, startDate, endDate)).then(
        getMessageStatisticsSuccess,
      );

      function getMessageStatisticsSuccess(statistics) {
        vm.currentMessage.statistics = statistics;
        fictiveTestGroup.parts[0].statistics = vm.currentMessage.statistics;
        return vm.currentMessage.statistics;
      }
    }

    /**
     * Получение тест-групп сообщений
     *
     * @param {String} messageId ID сообщения
     * @returns {Promise}
     */
    function getMessageTestGroups(messageId) {
      return firstValueFrom(messageTestGroupModel.getMessageTestGroups(messageId)).then(getMessageTestGroupsSuccess);

      function getMessageTestGroupsSuccess(testGroups) {
        // активная тест-группа уже добавлена в массив тест-групп, поэтому её надо отфильтровать
        testGroups = $filter('filter')(testGroups, { id: '!' + vm.currentMessage.activeTestGroup.id }, true);

        for (var i = 0; i < testGroups.length; i++) {
          var testGroup = testGroups[i];

          getTestGroupParts(testGroup);
        }

        vm.testGroups.push.apply(vm.testGroups, testGroups);
      }
    }

    /**
     * Получение пользователей
     *
     * @param {Object} messagePart Вариант сообщения
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @param {SENDING_TYPES} sendingType Тип действия получателя сообщения
     * @return {Promise}
     */
    function getSendings(messagePart, startDate, endDate, sendingType) {
      vm.sendings.currentSendingType = sendingType;
      vm.sendings.isRequestPerformed = true;

      // если идёт перезагрукзка таблицы пользователей - всё обнуляем
      if (vm.sendings.forceReload) {
        vm.sendings.sendings = [];
        vm.sendings.paginatorParams = null;
        vm.sendings.forceReload = false;
      }

      if (messagePart.id == fictiveTestGroup.parts[0].id) {
        return firstValueFrom(
          messageModel.getMessageSendings(
            vm.currentMessage.id,
            startDate,
            endDate,
            sendingType,
            vm.sendings.paginatorParams,
            true,
          ),
        )
          .then(getSendingsSuccess)
          .finally(getSendingsFinally);
      } else {
        if (messagePart.type == MESSAGE_PART_TYPES.CONTROL_GROUP) {
          return firstValueFrom(
            messagePartModel.getMessagePartSendings(
              messagePart.id,
              startDate,
              endDate,
              sendingType,
              vm.sendings.paginatorParams,
            ),
          )
            .then(getSendingsSuccess)
            .finally(getSendingsFinally);
        } else {
          return firstValueFrom(
            messagePartModel.getMessagePartSendings(
              messagePart.id,
              startDate,
              endDate,
              sendingType,
              vm.sendings.paginatorParams,
            ),
          )
            .then(getSendingsSuccess)
            .finally(getSendingsFinally);
        }
      }

      function getSendingsSuccess(data) {
        // защита от быстрого кликания пользователем по значениям фильтра
        if (
          vm.sendings.currentMessagePart.id == messagePart.id &&
          startDate.isSame(vm.sendings.range.startDate) &&
          endDate.isSame(vm.sendings.range.endDate) &&
          sendingType == vm.sendings.currentSendingType
        ) {
          vm.sendings.sendings.push.apply(vm.sendings.sendings, data.sendings);
          vm.sendings.paginatorParams = data.paginatorParams;
        }

        return data;
      }

      function getSendingsFinally() {
        vm.sendings.isRequestPerformed = false;
      }
    }

    /**
     * Получение статистики для вкладки с пользователями, для вывода её в большие кнопки
     *
     * @param {Object} messagePart Вариант сообщения
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @return {Promise}
     */
    function getSendingsStatistics(messagePart, startDate, endDate) {
      if (messagePart.id == fictiveTestGroup.parts[0].id) {
        return firstValueFrom(messageModel.getMessageStatistics(vm.currentMessage.id, startDate, endDate)).then(
          getStatisticsSuccess,
        );
      } else {
        if (messagePart.type == MESSAGE_PART_TYPES.CONTROL_GROUP) {
          return firstValueFrom(
            messagePartModel.getMessagePartStatistics(messagePart.id, startDate, endDate, false),
          ).then(getStatisticsSuccess);
        } else {
          return firstValueFrom(messagePartModel.getMessagePartStatistics(messagePart.id, startDate, endDate)).then(
            getStatisticsSuccess,
          );
        }
      }

      function getStatisticsSuccess(statistics) {
        vm.sendings.currentStatistics = statistics;
        return vm.sendings.currentStatistics;
      }
    }

    /**
     * Подсчёт количества блочных поп-апов в сообщении
     *
     * @returns {number}
     */
    function getBlockPopupCount() {
      return $filter('filter')(vm.messageParts, filterBlockPopups).length;

      function filterBlockPopups(messagePart) {
        return (
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
        );
      }
    }

    /**
     * Срабатывает при изменении тест-группы в селекте
     */
    function onCurrentTestGroupChange() {
      // если не было выбрано никакой тест-группы - выбираем фиктивную
      if (!vm.sendings.currentTestGroup) {
        vm.sendings.currentTestGroup = fictiveTestGroup;
      }

      // если у выбранной тест-группы отсутствуют варианты - их надо загрузить с сервера
      // у фиктивной тест-группы они всегда есть, поэтому бредовый запрос на сервер никак не уйдёт
      if (angular.isUndefined(vm.sendings.currentTestGroup.parts)) {
        firstValueFrom(messagePartModel.getMessageTestGroupParts(vm.sendings.currentTestGroup.id)).then(
          getMessageTestGroupPartsSuccess,
        );
      } else {
        vm.sendings.currentMessagePart = messagePartModel.filterMessageParts(vm.sendings.currentTestGroup.parts)[0];
      }

      function getMessageTestGroupPartsSuccess(testGroupParts) {
        vm.sendings.currentTestGroup.parts = testGroupParts;
        vm.sendings.currentMessagePart = messagePartModel.filterMessageParts(vm.sendings.currentTestGroup.parts)[0];
      }
    }

    /**
     * Срабатывает после закрытия uib-collapse
     *
     * @param {Object} testGroup Тест-группа
     */
    function onTestGroupCollapsed(testGroup) {
      testGroup.showContent = false;
    }

    /**
     * Срабатывает до открытия uib-collapse
     *
     * @param {Object} testGroup Тест-группа
     * @return {Promise}
     */
    function onTestGroupExpanding(testGroup) {
      trackClickOpenTestGroup();

      var deferred = $q.defer();

      testGroup.showContent = true;

      $timeout(function () {
        deferred.resolve();
      }, 0);

      return deferred.promise;
    }

    /**
     * Открытие модалки с пользователем
     *
     * @param {String} userId ID пользователя
     */
    function openUser(userId) {
      $uibModal.open({
        component: 'cqUserCardModal',
        resolve: {
          modalWindowParams: () => {
            return {
              billingInfo: vm.billingInfo,
              currentApp: vm.currentApp,
              djangoUser: vm.djangoUser,
              onRedirectConversationClick: true,
              userId: userId,
              telegramIntegrations: vm.telegramIntegrations,
            };
          },
        },
        size: 'lg',
        windowClass: 'user-card-modal',
      });
    }

    /**
     * Заполнение датасетов графика
     *
     * @param {Array.<Object>} messagePart Варианты сообщения
     * @param {Array.<SENDING_TYPES>} datasetsToShow Датасеты, которые будут показываться на графике
     * @param {Array.<SENDING_TYPES>} alreadyShown Датасеты, которые уже показываются в текущий момент
     */
    function setDatasets(messagePart, datasetsToShow, alreadyShown) {
      var dataset, datasetType, datasetSendingType, datasetLabel, datasetColors;

      for (var i = 0; i < datasetsToShow.length; i++) {
        datasetSendingType = datasetsToShow[i];
        var translatedDatasetSendingType = $translate.instant('models.message.statisticsTypes.' + datasetSendingType);
        datasetLabel =
          messagePart.type == MESSAGE_PART_TYPES.CONTROL_GROUP
            ? translatedDatasetSendingType + ' (' + messagePart.name + ')'
            : translatedDatasetSendingType;
        datasetType =
          messagePart.type == MESSAGE_PART_TYPES.CONTROL_GROUP ? DATASET_TYPES.CONTROL_GROUP : DATASET_TYPES.GENERAL;

        if (
          !(dataset = $filter('filter')(
            vm.statisticsChart.data.datasets,
            {
              datasetSendingType: datasetSendingType,
              datasetType: datasetType,
            },
            true,
          )[0])
        ) {
          dataset =
            vm.statisticsChart.data.datasets[
              vm.statisticsChart.data.datasets.push(angular.copy(vm.statisticsChart.defaultDataset)) - 1
            ];

          if (datasetType == DATASET_TYPES.CONTROL_GROUP) {
            datasetColors = chartHelper.generateColors(CONTROL_GROUP_SENDING_TYPE_COLORS[datasetSendingType]);
          } else {
            datasetColors = chartHelper.generateColors(MESSAGE_SENDING_TYPE_COLORS[datasetSendingType]);
          }
          chartHelper.mergeDatasetColors(dataset, datasetColors);
        }

        dataset.datasetSendingType = datasetSendingType;
        dataset.datasetType = datasetType;
        dataset.data = messagePart.statistics[datasetSendingType].data;
        dataset.hidden = alreadyShown.indexOf(datasetSendingType) == -1;
        dataset.label = datasetLabel;
        dataset.sum = messagePart.statistics[datasetSendingType].sum;
      }
    }

    /**
     * Функция перехода на вкладку с пользователями
     *
     * @param {Object} testGroup Тест-группа
     * @param {Object} messagePart Вариант сообщения из тест-группы
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @param {SENDING_TYPES} sendingType Тип действия получателя сообщения
     */
    function showSendings(testGroup, messagePart, startDate, endDate, sendingType) {
      vm.sendings.currentTestGroup = testGroup;
      vm.sendings.currentMessagePart = messagePart;
      vm.sendings.range.startDate = startDate;
      vm.sendings.range.endDate = endDate;
      vm.sendings.currentSendingType = sendingType;

      vm.currentTab = AUTO_MESSAGE_STATISTICS_TABS.SENDINGS;
    }

    /**
     * Переключение показа датасета на графике
     *
     * @param {SENDING_TYPES} datasetSendingType Датасет, который включается/отключается
     * @param {DATASET_TYPES} datasetType Тип датасета
     * @param {Array.<SENDING_TYPES>} alreadyShown Датасеты, которые показываются в текущий момент
     */
    function toggleDatasetsToShow(datasetSendingType, datasetType, alreadyShown) {
      trackClickChartLegend();

      var filteredDatasets = $filter('filter')(
        vm.statisticsChart.instance.data.datasets,
        {
          datasetType: datasetType,
          datasetSendingType: datasetSendingType,
        },
        true,
      );
      var datasetsShownIndex = alreadyShown.indexOf(datasetSendingType);

      if (datasetsShownIndex != -1) {
        alreadyShown.splice(datasetsShownIndex, 1);
      } else {
        alreadyShown.push(datasetSendingType);
      }

      for (var i = 0; i < filteredDatasets.length; i++) {
        var dataset = filteredDatasets[i];
        dataset.hidden = datasetsShownIndex != -1;
      }

      chartHelper.updateChart(vm.statisticsChart.instance);
    }

    /**
     * Трек применения датапикера
     */
    function trackApplyDatepicker() {
      carrotquestHelper.track('Автосообщения - применил датапикер', { Название: vm.currentMessage.name });
    }

    /**
     * Трек клика на кнопку "Добавить А/Б тест к данному автосообщению"
     */
    function trackClickAddAbTest() {
      carrotquestHelper.track('Добавить А/Б тест к данному автосообщению', { Название: vm.currentMessage.name });
    }

    /**
     * Трек клика на легенду графика на вкладке "Общая статистика"
     */
    function trackClickChartLegend() {
      carrotquestHelper.track('Автосообщения - клик на легенду графика на "Общая статистика"', {
        Название: vm.currentMessage.name,
      });
    }

    /**
     * Трек клика на "Завершить А/Б тест"
     */
    function trackClickCompleteAbTest() {
      carrotquestHelper.track('Автосообщения - клик на "Завершить А/Б тест"', { Название: vm.currentMessage.name });
    }

    /**
     * Трек клика на датапикер
     */
    function trackClickDatepicker() {
      carrotquestHelper.track('Автосообщения - клик на датапикер', { Название: vm.currentMessage.name });
    }

    /**
     * Трек клика на закрытую тест-группу в истории А/Б тестов
     */
    function trackClickOpenTestGroup() {
      carrotquestHelper.track('Автосообщения - посмотрел завершённый А/Б тест', { Название: vm.currentMessage.name });
    }

    /**
     * Трек клика на фильтр по действиям получателей
     *
     * @param {SENDING_TYPES} sendingType Действие получателя сообщения
     */
    function trackClickSendingTypeFilter(sendingType) {
      // todo: пока что закостылял, когда будет многоязычность - надо будет думать что отправлять в параметре 'Тип получателей' и где хранить возможные значения этого параметра
      var sendingTypeDescriptions = {};

      sendingTypeDescriptions[SENDING_TYPES.BOUNCED] = 'Bounced';
      sendingTypeDescriptions[SENDING_TYPES.CLICKED] = 'Перешли по ссылке';
      sendingTypeDescriptions[SENDING_TYPES.GOAL_COMPLETED] = 'Достигнуто целей';
      sendingTypeDescriptions[SENDING_TYPES.READ] = 'Прочитали';
      sendingTypeDescriptions[SENDING_TYPES.REPLIED] = 'Ответили';
      sendingTypeDescriptions[SENDING_TYPES.SENDED] = 'Отправлено';
      sendingTypeDescriptions[SENDING_TYPES.SPAM] = 'Пожаловались на спам';
      sendingTypeDescriptions[SENDING_TYPES.UNSUBSCRIBED] = 'Отписались от рассылки';

      carrotquestHelper.track('Автосообщения - клик по типу получателей в "Пользователи"', {
        Название: vm.currentMessage.name,
        'Тип получателей': sendingTypeDescriptions[sendingType],
      });
    }

    /**
     * Клик на кнопку "Установить цель"
     */
    function trackClickSetGoal() {
      carrotquestHelper.track('Автосообщения - клик на "Установить цель"', { Название: vm.currentMessage.name });
    }

    /**
     * Трек клика на кнопку "Посмотреть"
     *
     * @param {SENDING_TYPES} sendingType Действие получателя сообщения
     */
    function trackClickShowSendings(sendingType) {
      // todo: пока что закостылял, когда будет многоязычность - надо будет думать что отправлять в параметре 'Тип получателей' и где хранить возможные значения этого параметра
      var sendingTypeDescriptions = {};

      sendingTypeDescriptions[SENDING_TYPES.BOUNCED] = 'Bounced';
      sendingTypeDescriptions[SENDING_TYPES.CLICKED] = 'Перешли по ссылке';
      sendingTypeDescriptions[SENDING_TYPES.GOAL_COMPLETED] = 'Достигнуто целей';
      sendingTypeDescriptions[SENDING_TYPES.READ] = 'Прочитали';
      sendingTypeDescriptions[SENDING_TYPES.REPLIED] = 'Ответили';
      sendingTypeDescriptions[SENDING_TYPES.SENDED] = 'Отправлено';
      sendingTypeDescriptions[SENDING_TYPES.SPAM] = 'Пожаловались на спам';
      sendingTypeDescriptions[SENDING_TYPES.UNSUBSCRIBED] = 'Отписались от рассылки';

      carrotquestHelper.track('Автосообщения - клик на "Посмотреть"', {
        'Тип получателей': sendingTypeDescriptions[sendingType],
      });
    }

    /**
     * Трек клика на любой из фильтров по пользователям
     */
    function trackClickSendingFilter() {
      carrotquestHelper.track('Автосообщения - клик по фильтру в "Пользователи"', { Название: vm.currentMessage.name });
    }

    /**
     * Трек завершения А/Б теста
     *
     * @param {Object} messagePart Выбранный вариант сообщения
     */
    function trackCompleteAbTest(messagePart) {
      carrotquestHelper.track('Автосообщения - завершил А/Б тест', {
        Название: vm.currentMessage.name,
        'Выбранный вариант': messagePart.name,
      });
    }

    /**
     * Трек захода на вкладку
     *
     * @param {AUTO_MESSAGE_STATISTICS_TABS} tab Вкладка, на которую зашёл пользователь
     */
    function trackEnterInTab(tab) {
      var tabDescriptions = {};

      tabDescriptions[AUTO_MESSAGE_STATISTICS_TABS.AB_TEST.VALUE] = 'А/Б тест';
      tabDescriptions[AUTO_MESSAGE_STATISTICS_TABS.GENERAL.VALUE] = 'Общая статистика';
      tabDescriptions[AUTO_MESSAGE_STATISTICS_TABS.SENDINGS.VALUE] = 'Пользователи';

      carrotquestHelper.track('Автосообщения - зашёл на "' + tabDescriptions[tab.VALUE] + '"', {
        Название: vm.currentMessage.name,
      });
    }
  }
})();

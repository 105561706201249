import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider, ProductFeatureTextServiceProvider } from '@panel/app/ajs-upgraded-providers';

import { AccessDenialAlertComponent } from './access-denial-alert.component';

@NgModule({
  declarations: [AccessDenialAlertComponent],
  exports: [AccessDenialAlertComponent],
  imports: [CommonModule, NgbAlertModule],
  providers: [CarrotquestHelperProvider, ProductFeatureTextServiceProvider],
})
export class AccessDenialAlertModule {}

import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'cq-plan-capability-coin',
  template: '',
  styleUrls: ['./plan-capability-coin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanCapabilityCoinComponent {
  @HostBinding('class')
  classes = 'cqi-sm cqi-arrow-alt-circle-up cursor-pointer';
}

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('PrestashopController', PrestashopController);

  function PrestashopController($translate, PROJECT_NAME, USER_FILES_URL, carrotquestHelper, integration) {
    var vm = this;

    vm.$translate = $translate;
    vm.integration = integration;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.trackClickDownloadModule = trackClickDownloadModule;
    vm.USER_FILES_URL = USER_FILES_URL;

    /**
     * Трек клика на кнопку 'Скачать модуль'
     */
    function trackClickDownloadModule() {
      carrotquestHelper.track('Интеграции - ' + integration.type + ' - клик на "Скачать модуль"');
    }
  }
})();

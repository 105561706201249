import { HttpContextToken } from '@angular/common/http';

/** Токен для получения и meta, и data из ответа на запрос. Без него возвращается только то, что в data. */
export const EXTENDED_RESPONSE = new HttpContextToken<boolean>(() => false);

/** Токен для получения и meta, и data из ошибки на запрос. Без него возвращается только название ошибки. */
export const EXTENDED_ERROR = new HttpContextToken<boolean>(() => false);

/** Токен для скипа всех интерсепторов */
export const SKIP_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

export const SKIP_ERROR_HANDLE = new HttpContextToken<boolean>(() => false);

/**
 * Токен для предотвращения преобразования выборочных ключей ответа на запрос в camelCase.
 * Если передать true, то ни один ключ не будет преобразован в camelCase.
 * NOTE: не будут преобразовываться только те ключи, которые являются вложенными для переданных в этом токене.
 *  Для подробностей смотри документацию и реализацию caseStyleHelper.
 */
export const IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS = new HttpContextToken<string[] | boolean>(() => []);

/**
 * Токен для предотвращения преобразования ключей запроса в underscore.
 * Если передать true, то ни один ключ не будет преобразован в underscore.
 * NOTE: не будут преобразовываться только те ключи, которые являются вложенными для переданных в этом токене.
 *  Для подробностей смотри документацию и реализацию caseStyleHelper.
 */
export const IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS = new HttpContextToken<string[] | boolean>(() => []);

export const API_VERSION = new HttpContextToken<string>(() => 'ApiVersion');

export const IGNORE_REQUEST_PARAMS_CASE_TRANSFORM_FIELDS = new HttpContextToken<boolean>(() => false);

export const IGNORE_ID_AS_STRING = new HttpContextToken<boolean>(() => false);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import moment from 'moment/moment';
import { map } from 'rxjs/operators';

import { ArticleCategoryModel } from '@http/article-category/article-category.model';

@Injectable({ providedIn: 'root' })
export class ArticleModel {
  constructor(private readonly httpClient: HttpClient, private readonly articleCategoryModel: ArticleCategoryModel) {}

  /**
   * Изменение статуса индексации
   *
   * @param {String} appId - ID приложения
   * @param {String} articleId - ID статьи
   * @param {Boolean} indexedStatus - Статус индексации
   *
   * @return {Promise}
   */
  changeIndexed(appId: any, articleId: any, indexedStatus: any) {
    var params = {
      robots_index: indexedStatus,
    };

    return this.httpClient.patch('/knowledgebase/' + appId + '/articles/' + articleId, params);
  }

  /**
   * Изменение приоритета вывода статьи
   *
   * @param {String} appId - ID приложения
   * @param {String} articleId - ID статьи
   * @param {Number} priority - Приоритет
   *
   * @return {Promise}
   */
  changePriority(appId: any, articleId: any, priority: any) {
    var params = {
      priority: priority,
    };

    return this.httpClient.post('/knowledgebase/' + appId + '/articles/' + articleId + '/priority', params);
  }

  /**
   * Смена статуса опубликавнности статьи
   *
   * @param {String} appId - ID аппа
   * @param {String} articleId - ID шаблона
   * @param {Boolean} publicStatus - Статус опубликованности
   * @param {Boolean} hasKnowledgeBaseAddon - Подключена ли БЗ Pro
   *
   * @return {*}
   */
  changePublicStatus(appId: any, articleId: any, publicStatus: any, hasKnowledgeBaseAddon?: any) {
    var params: any = {
      public: publicStatus,
    };

    // Если не подключен PRO модуль, то надо выключить индексацию поисковиками
    if (!hasKnowledgeBaseAddon) {
      params.robots_index = publicStatus;
    }

    return this.httpClient.patch('/knowledgebase/' + appId + '/articles/' + articleId, params);
  }

  /**
   * Создание статьи
   *
   * @param {String} appId - ID аппа
   * @param {Object} article - Статья
   * @param {String|Array} errorsException - Исключает ошибки, по которым не надо показывать системную ошибку
   *
   * @returns {Promise}
   */
  create(appId: any, article: any, errorsException: any) {
    var params = {
      public: article.public,
      short_description: article.shortDescription,
      category: article.category.id,
      content: article.content,
      name: article.name,
      robots_index: article.robotsIndex,
      errorsException: errorsException,
    };

    return this.httpClient.post('/knowledgebase/' + appId + '/articles', params).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  /**
   * Получение статьи по её ID
   *
   * @param {String} appId - ID аппа
   * @param {String} articleId - ID шаблона
   * @param {Boolean=} [includeCategory=true] - Дополнительно получить категорию статьи
   *
   * @returns {Promise}
   */
  get(appId: any, articleId: any, includeCategory?: any) {
    includeCategory = angular.isUndefined(includeCategory) ? true : includeCategory;

    var params = {
      include_category: includeCategory,
    };

    return this.httpClient.get('/knowledgebase/' + appId + '/articles/' + articleId, { params }).pipe(
      map((response: any) => {
        var article = response;

        this.parse(article, includeCategory);

        return article;
      }),
    );
  }

  /**
   * Получение дефолтного шаблона
   *
   * @returns {Object}
   */
  getDefault() {
    return {
      body: '',
      category: null,
      id: '',
      name: '',
      priority: 0,
      public: true,
      robotsIndex: true,
      shortDescription: '',
    };
  }

  /**
   * Получение списка статей
   *
   * @param {String} appId - ID аппа
   * @param {Boolean=} [includeCategory=true] - Дополнительно получить полный сериализатор категории
   * @param {Boolean=} [includeNotPublic=false] - Получить вместе с неопубликованными статьями
   * @param {String=} [categoryId] - ID категории для которой надо получить статьи
   *
   * @returns {Promise}
   */
  getList(appId: any, includeCategory: any, includeNotPublic?: any, categoryId?: any) {
    includeCategory = angular.isUndefined(includeCategory) ? true : includeCategory;
    var params = {
      include_category: includeCategory,
      include_not_public: !!includeNotPublic,
    };

    if (categoryId) {
      //@ts-ignore
      params.category = categoryId;
    }

    return this.httpClient.get('/knowledgebase/' + appId + '/articles', { params }).pipe(
      map((response: any) => {
        var articles = orderBy(response, ['public', 'priority'], ['desc', 'desc']);

        for (var i = 0; i < articles.length; i++) {
          this.parse(articles[i], includeCategory);
        }

        return articles;
      }),
    );
  }

  /**
   * Получение количетсва статей
   *
   * @param {String} appId - ID аппа
   *
   * @return {Promise}
   */
  getListCount(appId: any) {
    return this.getList(appId, false, true).pipe(
      map((articles: any) => {
        return articles.length;
      }),
    );
  }

  /**
   * Парсинг статьи
   *
   * @param {Object} article - Статья
   * @param {Boolean} includeCategory - Флаг если были получены папки
   */
  parse(article: any, includeCategory: any) {
    if (includeCategory) {
      this.articleCategoryModel.parse(article.category);
    }

    article.created = article.created != null ? moment(article.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ') : article.created;
    article.updated = article.updated != null ? moment(article.updated, 'YYYY-MM-DDTHH:mm:ss.SSSZ') : article.updated;
  }

  /**
   * Удаление статьи
   *
   * @param {String} appId - ID аппа
   * @param {String} articleId - ID статьи
   *
   * @returns {*}
   */
  remove(appId: any, articleId: any) {
    return this.httpClient.delete('/knowledgebase/' + appId + '/articles/' + articleId, {});
  }

  /**
   * Удаление статей
   * note надо передавать либо массив с ID, либо фильтры (isPublic и  category)
   * TODO удаление с помощью фильтров не реальзивоано т.к. в данный момент получаются сразу се статьи.
   *  И реализовывать данный функционал пока бессмысленная тарта времени
   *
   * @param {String} appId - ID аппа
   * @param {Array.<String>=} articlesIds - ID статей
   * @param {Boolean=} isPublic - Фильтр по опубликованности
   * @param {Boolean=} category - Фильтр по категории
   *
   * @returns {*}
   */
  removeList(appId: any, articlesIds: any, isPublic?: any, category?: any) {
    var params: any = {};

    if (angular.isDefined(articlesIds)) {
      params.articles = articlesIds;
    } else {
      params.category = category;
      params.public = isPublic;
    }

    return this.httpClient.delete('/knowledgebase/' + appId + '/articles/', { body: params });
  }

  /**
   * Сохранение статьи
   *
   * @param {String} appId - ID аппа
   * @param {Object} article - Статья
   * @param {String|Array} errorsException - Исключает ошибки, по которым не надо показывать системную ошибку
   *
   * @returns {*}
   */
  save(appId: any, article: any, errorsException: any) {
    var params = {
      category: article.category.id,
      content: article.content,
      name: article.name,
      public: article.public,
      robots_index: article.robotsIndex,
      short_description: article.shortDescription,
      errorsException: errorsException,
    };

    return this.httpClient.patch('/knowledgebase/' + appId + '/articles/' + article.id, params);
  }

  /**
   * Поиск стратей
   *
   * @param {String} appId - ID аппа
   * @param {String} pattern - Поисковая фраза
   * @param {Boolean=} [includeCategory=true] - Включить данные о категории в статьи
   * @param {Boolean=} [includeNotPublic=true] - Включить поиск по не опубликованным статьям
   * @param {Boolean=} [includeRobotsIndex=true] - Включить данные о статусе индексированности
   * @param {Boolean=} [includeQuote=true] - Включить строку с вхождением поискового запроса
   */
  search(
    appId: any,
    pattern: any,
    includeCategory?: any,
    includeNotPublic?: any,
    includeRobotsIndex?: any,
    includeQuote?: any,
  ) {
    var params = {
      include_category: angular.isUndefined(includeCategory) ? true : includeCategory, // Включить категорию в статьи
      include_not_public: angular.isUndefined(includeNotPublic) ? true : includeNotPublic,
      include_quote: angular.isUndefined(includeQuote) ? true : includeQuote, // не включать кусок текста в котором найдено совпадение
      include_robots_index: angular.isUndefined(includeRobotsIndex) ? true : includeRobotsIndex, // Включать статус инексации
      pattern: pattern,
    };

    return this.httpClient.get('/knowledgebase/' + appId + '/search', { params }).pipe(
      map((response: any) => {
        var articles = response;

        for (var i = 0; i < articles.length; i++) {
          this.parse(articles[i], includeCategory);
        }

        return articles;
      }),
    );
  }

  /**
   * Задать категорию для статьи
   *
   * @param {String} appId - ID аппа
   * @param {Array.<String>} articlesIds - ID статей
   * @param {String} newCategoryId - ID новой категории
   *
   * @return {*}
   */
  setCategory(appId: any, articlesIds: any, newCategoryId: any) {
    var params = {
      articles: articlesIds,
      new_category: newCategoryId,
    };

    return this.httpClient.patch('/knowledgebase/' + appId + '/articles', params);
  }
}

import { Observable, tap } from 'rxjs';

import { PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';

/**
 * Восстанавливает maxFPS тикера до стандартного значения
 * Именно pipe, потому что изменять фпс нужно только во время интеракций, интеракции = observable
 */
export function increaseTickerMaxFPS(pixiApp: PixiApplication) {
  return <T>(source: Observable<T>): Observable<T> => source.pipe(tap(() => (pixiApp.ticker.maxFPS = 0)));
}

(function () {
  'use strict';

  require('./conversation-with-self.controller');

  angular.module('myApp.conversationWithSelf').component('cqConversationWithSelf', cqConversationWithSelf());

  function cqConversationWithSelf() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
      },
      controller: 'CqConversationWithSelfController',
      controllerAs: 'vm',
      template: require('./conversation-with-self.html'),
    };
  }
})();

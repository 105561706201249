(function () {
  'use strict';

  require('./facebook-leads-oauth.controller');

  angular.module('myApp.integrations').component('cqFacebookLeadsOAuth', cqFacebookLeadsOAuth());

  function cqFacebookLeadsOAuth() {
    return {
      controller: 'CqFacebookLeadsOAuthController',
      controllerAs: 'vm',
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmFieldsSelectModule } from '@panel/app/pages/integrations/content/amocrm/fields-select/amocrm-fields-select.module';
import { AmocrmPipesModule } from '@panel/app/pages/integrations/content/amocrm/shared/pipes/pipes.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmPropsToLeadComponent } from './amocrm-props-to-lead.component';

@NgModule({
  declarations: [AmocrmPropsToLeadComponent],
  exports: [AmocrmPropsToLeadComponent],
  imports: [
    AmocrmFieldsSelectModule,
    AmocrmPipesModule,
    CommonModule,
    NgbCollapseModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslocoModule,
    SharedModule,
  ],
  providers: [AmocrmService, IntegrationService],
})
export class AmocrmPropsToLeadModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { TriggerChainGeneralStatisticsBreadcrumbsComponent } from '@panel/app/pages/trigger-chains/general-statistics/components/breadcrumbs/trigger-chain-general-statistics-breadcrumbs.component';

@NgModule({
  declarations: [TriggerChainGeneralStatisticsBreadcrumbsComponent],
  exports: [TriggerChainGeneralStatisticsBreadcrumbsComponent],
  imports: [CommonModule, TranslocoModule, UIRouterModule],
})
export class TriggerChainGeneralStatisticsBreadcrumbsModule {}

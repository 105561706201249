import { Injectable } from '@angular/core';
import { isNumber } from 'lodash-es';

import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { FILTER_EVENT_TYPE } from '@panel/app/services/filter/filter.constants';
import {
  ExternalFilter,
  ExternalFilters,
  ExternalFilterTag,
} from '@panel/app/services/filter/types/filter.external-types';
import {
  EventFilter,
  Filters,
  PropertyFilter,
  TagFilter,
} from '@panel/app/services/filter/types/filter.internal-types';

const LIST_SEPARATOR = ',';

@Injectable({
  providedIn: 'root',
})
export class FilterParser {
  constructor() {}

  parseToExternal(filters: Filters): ExternalFilters {
    let filtersExternal: ExternalFilters = {
      type: filters.logicalOperation,
      filters: [
        ...this.parseEventFiltersToExternal(filters.filters.events),
        ...this.parsePropertyFiltersToExternal(filters.filters.props),
        ...this.parseTagFiltersToExternal(filters.filters.tags),
      ],
    };

    return filtersExternal;
  }

  parseToInternal(filtersExternal: ExternalFilters): Filters {
    let filters: Filters = {
      logicalOperation: filtersExternal.type,
      filters: {
        props: this.parsePropertyFiltersToInternal(this.filterPropertyFiltersFromExternal(filtersExternal.filters)),
        events: this.parseEventFiltersToInternal(this.filterEventFiltersFromExternal(filtersExternal.filters)),
        tags: this.parseTagFiltersToInternal(this.filterTagFiltersFromExternal(filtersExternal.filters)),
      },
    };

    return filters;
  }

  parsePropertyFiltersToExternal(propertyFilters: PropertyFilter[]): ExternalFilter[] {
    let externalPropertyFilters: ExternalFilter[] = [];

    propertyFilters.forEach((filter: PropertyFilter) => {
      if (!filter.propertyName) {
        return;
      }

      let externalFilter: ExternalFilter;

      switch (filter.operation.type) {
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN:
          externalFilter = {
            propertyName: filter.propertyName,
            type: filter.operation.type,
            value: filter.operation.value,
          };
          break;
        case ELASTICSEARCH_OPERATION.NUMBER_RANGE:
          externalFilter = {
            propertyName: filter.propertyName,
            type: filter.operation.type,
            value: filter.operation.value,
          };
          break;
        case ELASTICSEARCH_OPERATION.LIST_CONTAINS_ANY:
        case ELASTICSEARCH_OPERATION.LIST_CONTAINS_ALL:
        case ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ANY:
        case ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ALL:
          if (isNumber(filter.operation.value.value)) {
            throw new Error('List operation value should not be a number');
          }

          externalFilter = {
            propertyName: filter.propertyName,
            type: filter.operation.type,
            value: {
              value: filter.operation.value.value.split(LIST_SEPARATOR),
            },
          };
          break;
        default:
          externalFilter = {
            propertyName: filter.propertyName,
            type: filter.operation.type,
            value: filter.operation.value,
          };
          break;
      }

      externalPropertyFilters.push(externalFilter);
    });

    return externalPropertyFilters;
  }

  private parsePropertyFiltersToInternal(externalPropertyFilters: ExternalFilter[]): PropertyFilter[] {
    let propertyFilters: PropertyFilter[] = [];

    externalPropertyFilters.forEach((filter: ExternalFilter) => {
      let propertyFilter: PropertyFilter;

      switch (filter.type) {
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN:
          propertyFilter = {
            propertyName: filter.propertyName,
            operation: {
              type: filter.type,
              value: filter.value,
            },
          };
          break;
        case ELASTICSEARCH_OPERATION.NUMBER_RANGE:
          propertyFilter = {
            propertyName: filter.propertyName,
            operation: {
              type: filter.type,
              value: filter.value,
            },
          };
          break;
        case ELASTICSEARCH_OPERATION.LIST_CONTAINS_ANY:
        case ELASTICSEARCH_OPERATION.LIST_CONTAINS_ALL:
        case ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ANY:
        case ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ALL:
          propertyFilter = {
            propertyName: filter.propertyName,
            operation: {
              type: filter.type,
              value: {
                value: filter.value.value.join(LIST_SEPARATOR),
              },
            },
          };
          break;
        default:
          propertyFilter = {
            propertyName: filter.propertyName,
            operation: {
              type: filter.type,
              value: filter.value,
            },
          };
      }

      propertyFilters.push(propertyFilter);
    });

    if (externalPropertyFilters.length === 0) {
      propertyFilters.push({
        propertyName: null,
        operation: {
          type: ELASTICSEARCH_OPERATION.KNOWN,
          value: {
            value: 0,
          },
        },
      });
    }

    return propertyFilters;
  }

  parseEventFiltersToExternal(eventFilters: EventFilter[]): ExternalFilter[] {
    let externalEventFilters: ExternalFilter[] = [];

    eventFilters.forEach((filter: EventFilter) => {
      let externalFilter: ExternalFilter;

      if (!filter.eventId) {
        return;
      }

      let eventName = `$event_${filter.eventId}_${filter.eventType}`;
      switch (filter.operation.type) {
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN:
          externalFilter = {
            propertyName: eventName,
            type: filter.operation.type,
            value: filter.operation.value,
          };
          break;
        case ELASTICSEARCH_OPERATION.NUMBER_RANGE:
          externalFilter = {
            propertyName: eventName,
            type: filter.operation.type,
            value: filter.operation.value,
          };
          break;
        case ELASTICSEARCH_OPERATION.LIST_CONTAINS_ANY:
        case ELASTICSEARCH_OPERATION.LIST_CONTAINS_ALL:
        case ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ANY:
        case ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ALL:
          if (isNumber(filter.operation.value.value)) {
            throw new Error('List operation value should not be a number');
          }

          externalFilter = {
            propertyName: eventName,
            type: filter.operation.type,
            value: {
              value: filter.operation.value.value.split(LIST_SEPARATOR),
            },
          };
          break;
        default:
          externalFilter = {
            propertyName: eventName,
            type: filter.operation.type,
            value: filter.operation.value,
          };
      }

      externalEventFilters.push(externalFilter);
    });

    return externalEventFilters;
  }

  parseTagFiltersToExternal(internalTagFilters: TagFilter[]): ExternalFilterTag[] {
    return internalTagFilters.map((internalTagFilter) => {
      return {
        propertyName: '$tags',
        value: {
          value: internalTagFilter.tagId,
          viewValue: internalTagFilter.tagName,
        },
        type: internalTagFilter.type,
      };
    });
  }

  private parseEventFiltersToInternal(externalEventFilters: ExternalFilter[]): EventFilter[] {
    let eventFilters: EventFilter[] = [];

    externalEventFilters.forEach((externalFilter: ExternalFilter) => {
      if (!externalFilter.propertyName) {
        return;
      }

      let filter: EventFilter;

      let regex = /^(\$.*?)(?:_([^_]+))?$/;
      let match = externalFilter.propertyName.match(regex);
      if (!match) {
        throw new Error('RegExp is not matched');
      }

      let eventId = match[1].replace('$event_', '');
      let eventType = match[2] as FILTER_EVENT_TYPE;
      switch (externalFilter.type) {
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN:
        case ELASTICSEARCH_OPERATION.DAYS_MORE_THAN_OR_UNKNOWN:
        case ELASTICSEARCH_OPERATION.DAYS_LESS_THAN_OR_UNKNOWN:
          filter = {
            eventId,
            eventType: eventType,
            operation: {
              type: externalFilter.type,
              value: externalFilter.value,
            },
          };
          break;
        case ELASTICSEARCH_OPERATION.NUMBER_RANGE:
          filter = {
            eventId,
            eventType: eventType,
            operation: {
              type: externalFilter.type,
              value: externalFilter.value,
            },
          };
          break;
        case ELASTICSEARCH_OPERATION.LIST_CONTAINS_ANY:
        case ELASTICSEARCH_OPERATION.LIST_CONTAINS_ALL:
        case ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ANY:
        case ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS_ALL:
          filter = {
            eventId,
            eventType: eventType,
            operation: {
              type: externalFilter.type,
              value: {
                value: externalFilter.value.value.join(LIST_SEPARATOR),
              },
            },
          };
          break;
        default:
          filter = {
            eventId,
            eventType: eventType,
            operation: {
              type: externalFilter.type,
              value: externalFilter.value,
            },
          };
      }

      eventFilters.push(filter);
    });

    if (externalEventFilters.length === 0) {
      eventFilters.push({
        eventId: null,
        eventType: FILTER_EVENT_TYPE.COUNT,
        operation: {
          type: ELASTICSEARCH_OPERATION.KNOWN,
          value: {
            value: 0,
          },
        },
      });
    }

    return eventFilters;
  }

  private parseTagFiltersToInternal(externalTagFilters: ExternalFilterTag[]): TagFilter[] {
    return externalTagFilters.map((externalTagFilter): TagFilter => {
      return {
        tagId: externalTagFilter.value.value,
        tagName: externalTagFilter.value.viewValue,
        type: externalTagFilter.type,
      };
    });
  }

  private filterPropertyFiltersFromExternal(externalFilters: ExternalFilter[]): ExternalFilter[] {
    return externalFilters.filter((filter) => {
      return !filter.propertyName.includes('$event') && filter.propertyName !== '$tags';
    });
  }

  private filterEventFiltersFromExternal(externalFilters: ExternalFilter[]): ExternalFilter[] {
    return externalFilters.filter((filter) => {
      return filter.propertyName.includes('$event') && filter.propertyName !== '$tags';
    });
  }

  private filterTagFiltersFromExternal(externalFilters: ExternalFilter[]): ExternalFilterTag[] {
    return externalFilters.filter((filter): filter is ExternalFilterTag => filter.propertyName === '$tags');
  }
}

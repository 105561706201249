import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { tuiPure } from '@taiga-ui/cdk';

import { TelegramIntegrationExternal } from '@http/integration/integrations/telegram/interfaces/telegram-integration.interfaces';
import { TelegramIntegration } from '@http/integration/integrations/telegram/telegram-integration';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

export type TelegramListForm = {
  value: string;
};

@Component({
  selector: 'cq-telegram-value-select',
  templateUrl: './telegram-value-select.component.html',
  styleUrls: ['./telegram-value-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramValueSelectComponent
  extends AbsCVAFormGroupBasedComponent<ToFormGroupControls<TelegramListForm>>
  implements OnInit
{
  @Input({ required: true })
  telegramIntegrations: TelegramIntegrationExternal[] = [];

  // так как значение, которое гуляет в компонентах выше - это строка, надо преобразовать её в массив для ng-select
  selectedIntegrations: string[] = [];

  readonly control = this.fb.group({
    value: this.fb.control('', { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.selectedIntegrations =
      this.control.controls.value.value === '' ? [] : this.control.controls.value.value.split(',');
  }

  @tuiPure
  stringifyArray(integrations: TelegramIntegration[]): string {
    return integrations.map((i) => `@${i.settings.botName}`).join(', ');
  }

  updateFilterValue() {
    this.control.controls.value.setValue(this.selectedIntegrations.join(','));
  }

  bindValue(integration: TelegramIntegrationExternal) {
    return integration.settings.botId;
  }
}

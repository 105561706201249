import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Компонент для отображения zero-dat'ы когда не найдено ни одного приглашения членов команды
 */

@Component({
  selector: 'cq-member-invitations-not-found-zero-data',
  templateUrl: './member-invitations-not-found-zero-data.component.html',
  styleUrls: ['./member-invitations-not-found-zero-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberInvitationsNotFoundZeroDataComponent {}

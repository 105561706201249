import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { AUDIENCE_FILTER_TYPE, MESSAGE_PAGE_TYPES } from '../../../app/http/message/message.constants';
import { PSEUDO_SEGMENT_IDS } from '../../../app/http/segment/segment.constants';
import { firstValueFrom } from 'rxjs';
import {
  FILTER_EVENT_TYPE,
  FILTER_EVENT_TYPE_ARRAY,
  FILTER_LOGICAL_OPERATION,
  ICON_BY_FILTER_EVENT_TYPE,
} from '../../../app/services/filter/filter.constants';
import {
  ELASTICSEARCH_OPERATION_TYPES,
  ELASTICSEARCH_PROPERTY_OPERATIONS,
  ELASTICSEARCH_PROPERTY_OPERATIONS_BY_PSEUDO_TYPE,
  ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE,
} from '../../../app/http/property/property.constants';

(function () {
  'use strict';

  angular.module('myApp.filterSegments').controller('FilterSegmentsController', FilterSegmentsController);

  function FilterSegmentsController(
    $scope,
    $uibModal,
    $translate,
    $filter,
    $timeout,
    toastr,
    FILTER_SEGMENTS_PAGE_TYPE,
    carrotquestHelper,
    filterAjsModel,
    planFeatureAccessService,
    propertyModel,
    segmentModel,
    tagModel,
    validationHelper,
    messageUtilsModel,
  ) {
    let vm = this;

    /**
     * Время, через которое будет засабмичена форма с фильтрами
     *
     * @type {number}
     */
    const FILTERS_APPLY_TIMEOUT = 500;

    /**
     * Типы фильтров для трека
     *
     * @type {Object}
     */
    const FILTER_PROP_TYPES_FOR_TRACK = {
      EVENT: 'event',
      PROPERTY: 'property',
      All_OR_ANY: 'allOrAny',
    };

    /**
     * Виды дат для фильтра по дате
     *
     * @type {Array}
     */
    const FILTER_DATE_TYPE = ['minutes', 'hours', 'days', 'weeks'];

    /**
     * Таймаут применения фильтров в форме
     *
     * @type {null}
     */
    let filterTimeout = null;

    /**
     * Максимальная длина строки для фильтра по строкам
     *
     * @type {Number}
     */
    const MAX_LENGTH_VALUE_FILTER = 15;

    /**
     * Минимальная длина строки для фильтра по строкам
     *
     * @type {Number}
     */
    const MIN_LENGTH_VALUE_FILTER = 3;

    /**
     * Минимальное число для фильтра по числам
     *
     * @type {Number}
     */
    const MIN_NUMBER_VALUE_FILTER = 0;

    /**
     * Название свойства пользователя "Последняя посещенная страница"
     *
     * @type {string}
     */
    const LAST_VISITED_PAGE_USER_PROP_NAME = $translate.instant(
      'filterSegments.general.properties.lastVisitedPageUserPropName',
    );

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.AUDIENCE_FILTER_TYPE = AUDIENCE_FILTER_TYPE;
      vm.onJinjaFilterTemplateChanged = onJinjaFilterTemplateChanged;
      vm.checkJinjaFilterTemplate = checkJinjaFilterTemplate;

      vm.accessToEventsEventTypesCustom = planFeatureAccessService.getAccess(
        PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
        vm.currentApp,
      );
      vm.accessToUsersCustomProperties = planFeatureAccessService.getAccess(
        PLAN_FEATURE.USERS_CUSTOM_PROPERTIES,
        vm.currentApp,
      );
      vm.accessToUsersTags = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, vm.currentApp);

      vm.addFilterUserEvent = addFilterUserEvent;
      vm.addFilterUserProp = addFilterUserProp;
      vm.capabilityFilterByTags = capabilityFilterByTags;
      vm.clearFilters = clearFilters;
      vm.customScrollInstance = null;
      vm.customSelectDropdownClass = vm.customSelectDropdownClass ?? '';
      vm.ELASTICSEARCH_OPERATION_TYPES = ELASTICSEARCH_OPERATION_TYPES;
      vm.ELASTICSEARCH_PROPERTY_OPERATIONS = ELASTICSEARCH_PROPERTY_OPERATIONS;
      vm.FILTER_DATE_TYPE = FILTER_DATE_TYPE;
      vm.FILTER_EVENT_PROPERTY_TYPES_LIST = FILTER_EVENT_TYPE_ARRAY;
      vm.FILTER_PROP_TYPES_FOR_TRACK = FILTER_PROP_TYPES_FOR_TRACK;
      vm.FILTER_SEGMENTS_PAGE_TYPE = FILTER_SEGMENTS_PAGE_TYPE;
      vm.FILTER_TYPES = FILTER_LOGICAL_OPERATION;
      vm.filterDate = []; //Фильтр по дате
      vm.filterEventOperations = []; //Типы фильтра по событиям
      vm.filterPropOperations = []; //Типы фильтра
      vm.filterPropOperationsPush = []; //Типы фильтра для пушей
      vm.filterSegmentsModelsOptions = {
        // Общие настройки работы с моделями в фильтрах сегмента
        updateOn: 'default blur',
        debounce: {
          default: 500,
          blur: 0,
        },
      };
      vm.filterUserEvents = []; //Филтр события пользователя
      vm.filterUserProps = []; //Филтр свойства пользователя
      vm.filterUserTags = []; //Филтр теги пользователя
      vm.getElasticsearchOperationTypeByOperation = propertyModel.getElasticsearchOperationTypeByOperation;
      vm.getDeletedTagsFromSegment = getDeletedTagsFromSegment;
      vm.groupingByType = groupingByType;
      vm.ICON_BY_FILTER_EVENT_PROPERTY_TYPE = ICON_BY_FILTER_EVENT_TYPE;
      vm.isFirstElementError = isFirstElementError;
      vm.isOpenSendingFiltersPopover = isOpenSendingFiltersPopover;
      vm.isSearchSegmentDropdownOpen = false; // Открыт или нет дропдаун с выбором сегмента
      vm.isShowUserEventsFilter = true; //Флаг отображания фильтра по событиям
      vm.isShowUserPropsFilter = true; //Флаг отображания фильтра по свойствам
      vm.isShowUserTagsFilter = true; //Флаг отображания фильтра по тегам
      vm.isTagContains = isTagContains;
      vm.isTagSelected = isTagSelected;
      vm.isUserEventEmpty = angular.bind(null, isPropEmpty, 'eventType');
      vm.isUserPropEmpty = angular.bind(null, isPropEmpty, 'userProp');
      vm.messagePageType = vm.messagePageType || MESSAGE_PAGE_TYPES.AUTO_MESSAGES;
      vm.MAX_LENGTH_VALUE_FILTER = MAX_LENGTH_VALUE_FILTER;
      vm.MIN_LENGTH_VALUE_FILTER = MIN_LENGTH_VALUE_FILTER;
      vm.MIN_NUMBER_VALUE_FILTER = MIN_NUMBER_VALUE_FILTER;
      vm.LAST_VISITED_PAGE_USER_PROP_NAME = LAST_VISITED_PAGE_USER_PROP_NAME;
      vm.openRemoveSegmentModal = openRemoveSegmentModal;
      vm.openRemoveTagModal = openRemoveTagModal;
      vm.openSaveSegmentModal = openSaveSegmentModal;
      vm.PSEUDO_SEGMENT_IDS = PSEUDO_SEGMENT_IDS;
      vm.removeFilterUserEvent = removeFilterUserEvent;
      vm.removeFilterUserProp = removeFilterUserProp;
      vm.searchSegment = ''; // Строка для поиска сегмента
      vm.selectedFilterType = FILTER_LOGICAL_OPERATION.AND; //Выбранный тип фильтра
      vm.selectedSegment = {}; //Выбранный сегмент
      vm.selectSegment = selectSegment;
      vm.showSendingFiltersPopover = showSendingFiltersPopover;
      vm.submitFiltersForm = submitFiltersForm;
      vm.tagContainsChange = tagContainsChange;
      vm.tagSelectChange = tagSelectChange;
      vm.trackChangeFilterType = trackChangeFilterType;
      vm.trackClickFilterProp = trackClickFilterProp;
      vm.trackClickSaveSegment = trackClickSaveSegment;
      vm.trackClickSegment = trackClickSegment;
      vm.trackClickTag = trackClickTag;
      vm.trackClickTagContains = trackClickTagContains;
      vm.userEventRequired = angular.bind(null, propsRequired, 'eventType');
      vm.userEventTypes = []; //События пользователя
      vm.userPropRequired = angular.bind(null, propsRequired, 'userProp');
      vm.userProps = []; //Свойства пользователя
      vm.userTagFilter = userTagFilter;
      vm.validationHelper = validationHelper;
      vm.updateFilterFromA2 = updateFilterFromA2;
      vm.telegramFilterValid = () => true;
      vm.emailFilterValid = () => true;

      vm.filterEventOperations = getFilterPropEventOperations();
      vm.filterPropOperations = getFilterPropOperations();
      vm.telegramFilterPropOperations = ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE[ELASTICSEARCH_OPERATION_TYPES.ARRAY];
      vm.filterPropOperationsPush = getFilterPropOperationsPush();

      if (vm.accessToUsersCustomProperties.hasAccess) {
        vm.userProps = angular.copy(vm.props.userProps);
      } else {
        vm.userProps = angular.copy($filter('filter')(vm.props.userProps, { groupOrder: '!5' }));
      }
      if (vm.accessToEventsEventTypesCustom.hasAccess) {
        vm.userEventTypes = angular.copy(vm.props.eventTypes);
      } else {
        vm.userEventTypes = angular.copy($filter('filter')(vm.props.eventTypes, { name: '$' }));
      }
      vm.userEventTypeProps = angular.copy(vm.props.eventTypeProps);

      // Если фильтры пустые, то предзаполняем их стандартными (пустыми) данными. Иначе заполняем пришедшим фильтрами
      if (isFiltersEmpty(vm.filters) && !vm.hideSegments) {
        selectSegment($filter('filter')(vm.segments, { id: PSEUDO_SEGMENT_IDS.ALL })[0]);
      } else {
        vm.selectedFilterType = vm.filters.type;
        if (vm.filters.filters.props.length === 0) {
          addFilterUserProp();
        } else {
          vm.filterUserProps = angular.copy(vm.filters.filters.props);
        }

        if (vm.filters.filters.events.length === 0) {
          addFilterUserEvent();
        } else {
          vm.filterUserEvents = angular.copy(vm.filters.filters.events);
        }

        vm.filterUserTags = angular.copy(vm.filters.filters.tags);
      }

      for (var i = 1; i <= 59; i++) {
        vm.filterDate.push(i);
      }
      let filtersHasBeenInit = false;

      $scope.$watch(
        'vm.filters',
        () => {
          if (filtersHasBeenInit === false) {
            filtersHasBeenInit = true;
            return;
          }

          if (vm.filters.filters.props.length === 0 && vm.filterUserProps.length !== 0) {
            vm.filterUserProps = [filterAjsModel.getDefaultFilterProp()];
          }

          if (vm.filters.filters.events.length === 0 && vm.filterUserEvents.length !== 0) {
            vm.filterUserEvents = [filterAjsModel.getDefaultFilterEvent()];
          }

          if (vm.filters.filters.tags.length === 0 && vm.filterUserTags.length !== 0) {
            vm.filterUserTags = [];
          }
        },
        true,
      );

      $scope.$watch(
        '[vm.selectedFilterType, vm.filterUserProps, vm.filterUserEvents, vm.filterUserTags]',
        filtersWatch,
        true,
      );

      let initial = true;
      function filtersWatch(newVal, oldVal) {
        setCurrentDataToPropFilter(newVal[1], oldVal[1], initial);
        setCurrentDataToEventFilter(newVal[2], oldVal[2]);
        initial = false;

        filterTimeout = $timeout(() => submitFiltersForm(vm.filtersForm.$valid), FILTERS_APPLY_TIMEOUT);
      }
    }

    /**
     * Добавляет свойства пользователя
     */
    function addFilterUserProp() {
      vm.filterUserProps.push(filterAjsModel.getDefaultFilterProp());
    }

    /**
     * Добавляет событие пользователя
     */
    function addFilterUserEvent() {
      vm.filterUserEvents.push(filterAjsModel.getDefaultFilterEvent());
    }

    /**
     * Фильтрация тегов с учетом ограничений тарифа
     *
     * @param {Object} tag - Объект с информацией о теге
     *
     * @return {Boolean}
     */
    function capabilityFilterByTags(tag) {
      return vm.accessToUsersTags.hasAccess || isTagSelected(tag);
    }

    /**
     *  Очищает все выбранные фильтры
     */
    function clearFilters() {
      vm.filterUserProps = [];
      vm.filterUserEvents = [];
      vm.filterUserTags = [];

      addFilterUserProp();
      addFilterUserEvent();
    }

    /**
     * Обьединяет все фильтры для передачи в модель или другие контроллеры
     */
    function collectAllFilters() {
      var filters = {
        props: angular.copy($filter('filter')(vm.filterUserProps, { userProp: { name: '' } })),
        events: angular.copy($filter('filter')(vm.filterUserEvents, { eventType: { name: '' } })),
        tags: vm.filterUserTags,
      };

      for (var i = 0; i < filters.events.length; i++) {
        deleteExcessPropValue(filters.events[i]);
      }
      for (i = 0; i < filters.props.length; i++) {
        deleteExcessPropValue(filters.props[i]);
      }

      return {
        type: vm.selectedFilterType,
        filters: filters,
      };
    }

    /**
     * Удаляет лишние свойства для фильтра
     *
     * @param {Object} filterUserEventOrProp - Собитие или свойство пользователя
     */
    function deleteExcessPropValue(filterUserEventOrProp) {
      var operationType = propertyModel.getElasticsearchOperationTypeByOperation(filterUserEventOrProp.type);
      // Группы GENERAL и BOOLEAN содержит пустой обьект value
      if (
        operationType === ELASTICSEARCH_OPERATION_TYPES.GENERAL ||
        operationType === ELASTICSEARCH_OPERATION_TYPES.BOOLEAN
      ) {
        filterUserEventOrProp.value = {};
        // Группы NUMBER не содержит value.unit (поэтому его надо удалить).
      } else if (operationType === ELASTICSEARCH_OPERATION_TYPES.NUMBER) {
        delete filterUserEventOrProp.value.unit;
        // Если выбрана операция NUMBER_RANGE => удалить value.value, если нет => удалить value.value1 и value.value2
        if (filterUserEventOrProp.type === ELASTICSEARCH_PROPERTY_OPERATIONS.NUMBER_RANGE) {
          delete filterUserEventOrProp.value.value;
        } else {
          delete filterUserEventOrProp.value.value1;
          delete filterUserEventOrProp.value.value2;
        }
        // Группы STRING и ARRAY содержат только value.value => остальные свойства надо удалить
      } else if (
        operationType === ELASTICSEARCH_OPERATION_TYPES.STRING ||
        operationType === ELASTICSEARCH_OPERATION_TYPES.ARRAY
      ) {
        delete filterUserEventOrProp.value.unit;
        delete filterUserEventOrProp.value.value1;
        delete filterUserEventOrProp.value.value2;
        // Группы DATE содержит value.unit и value.value => удалить value.value1 и value.value2
      } else if (operationType === ELASTICSEARCH_OPERATION_TYPES.DATE) {
        delete filterUserEventOrProp.value.value1;
        delete filterUserEventOrProp.value.value2;
      }
    }

    /**
     * Отменяет таймаут
     */
    function destroy() {
      $timeout.cancel(filterTimeout);
    }

    /**
     * Получение фильтров для событий пользователя
     *
     * @returns {Array}
     */
    function getFilterPropEventOperations() {
      var filterEventOperations = [];
      for (var operationType in ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE) {
        if (
          (ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE.hasOwnProperty(operationType) &&
            operationType === ELASTICSEARCH_OPERATION_TYPES.GENERAL) ||
          operationType === ELASTICSEARCH_OPERATION_TYPES.NUMBER ||
          operationType === ELASTICSEARCH_OPERATION_TYPES.DATE
        ) {
          for (var i = 0; i < ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE[operationType].length; i++) {
            var operation = ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE[operationType][i];
            var visible = [];

            if (operationType === ELASTICSEARCH_OPERATION_TYPES.GENERAL) {
              visible.push(FILTER_EVENT_TYPE.COUNT, FILTER_EVENT_TYPE.FIRST, FILTER_EVENT_TYPE.LAST);
            } else if (operationType === ELASTICSEARCH_OPERATION_TYPES.NUMBER) {
              visible.push(FILTER_EVENT_TYPE.COUNT);
            } else if (operationType === ELASTICSEARCH_OPERATION_TYPES.DATE) {
              visible.push(FILTER_EVENT_TYPE.FIRST, FILTER_EVENT_TYPE.LAST);
            }

            filterEventOperations.push({
              value: operation,
              operationType: operationType,
              visible: visible,
            });
          }
        }
      }
      return filterEventOperations;
    }

    /**
     * Получение фильтров для свойств пользователя
     *
     * @returns {Array}
     */
    function getFilterPropOperations() {
      var filterPropOperations = [];
      for (var operationType in ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE) {
        if (ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE.hasOwnProperty(operationType)) {
          for (var i = 0; i < ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE[operationType].length; i++) {
            filterPropOperations.push({
              value: ELASTICSEARCH_PROPERTY_OPERATIONS_BY_TYPE[operationType][i],
              operationType: operationType,
            });
          }
        }
      }
      return filterPropOperations;
    }

    /**
     * Получение фильтров для свойств пользователя для пушей
     *
     * @returns {Array}
     */
    function getFilterPropOperationsPush() {
      var filterPropOperations = [];
      for (var operationPseudoType in ELASTICSEARCH_PROPERTY_OPERATIONS_BY_PSEUDO_TYPE) {
        if (ELASTICSEARCH_PROPERTY_OPERATIONS_BY_PSEUDO_TYPE.hasOwnProperty(operationPseudoType)) {
          for (var i = 0; i < ELASTICSEARCH_PROPERTY_OPERATIONS_BY_PSEUDO_TYPE[operationPseudoType].length; i++) {
            filterPropOperations.push({
              value: ELASTICSEARCH_PROPERTY_OPERATIONS_BY_PSEUDO_TYPE[operationPseudoType][i],
              operationType: operationPseudoType,
            });
          }
        }
      }
      return filterPropOperations;
    }

    /**
     * Группировка элементов селекта по типу свойств
     *
     * @param {Object} item - Элемент списка
     * @returns {String}
     */
    function groupingByType(item) {
      return $translate.instant('models.property.elasticsearchOperationTypeName.' + item.operationType);
    }

    /**
     * Проверка пустые ли фильтры у автосообщения
     *
     * @param {Object} filters Фильтры автосообщения
     * @returns {boolean}
     */
    function isFiltersEmpty(filters) {
      return (
        filters.type === FILTER_LOGICAL_OPERATION.AND &&
        filters.filters.props.length === 0 &&
        filters.filters.events.length === 0 &&
        filters.filters.tags.length === 0
      );
    }

    /**
     * Проверяет является ли текущий элемент самым первым элементом с ошибкой, чтобы показать сообщение об ошибке только над самым первым
     *
     * @param {String} filterSelectName - Имя валидируемого элемента
     * @param {Object} form - Форма
     *
     * @returns {boolean}
     */
    function isFirstElementError(filterSelectName, form) {
      //TODO Надо сделать чтобы показывалось для первого невалидного поля
      for (var key in form.$error) {
        if (form.$error.hasOwnProperty(key)) {
          return form.$error[key][0].$name === filterSelectName;
        }
      }
    }

    /**
     * Проверяет является ли список свойств/событий пустым
     *
     * @param {String} propertyKey - Имя ключа
     * @param {Array} prop - Свойства/события
     * @returns {boolean}
     */
    function isPropEmpty(propertyKey, prop) {
      var filter = {};
      filter[propertyKey] = { name: '' };
      return $filter('filter')(prop, filter).length === 0;
    }

    /**
     * Получение удаленных тегов из сегмента
     *
     * @param {Array.<Object>} tagsFilters Массив фильтров по тегам
     * @returns {boolean}
     */
    function getDeletedTagsFromSegment(tagsFilters) {
      return $filter('filter')(tagsFilters, { tag: { removed: '!!' } });
    }

    /**
     * Тег включен или исключен
     *
     * @param {Object} tag - Тег
     * @returns {boolean} True - если тег вклчюен
     */
    function isTagContains(tag) {
      var tagFiltered = $filter('filter')(vm.filterUserTags, { tag: { id: tag.id } }, true);

      return !!(tagFiltered[0] && tagFiltered[0].type === 'lcontains');
    }

    /**
     * Проверяет есть ли выбранные теги
     *
     * @param {Object} tag - Тег
     * @returns {boolean}
     */
    function isTagSelected(tag) {
      return $filter('filter')(vm.filterUserTags, { tag: { id: tag.id } }, true).length > 0;
    }

    /**
     * Показывать ли поповер с условиями отправки по URL
     *
     * @param filter - Фильтр, который нужно проверить
     *
     * @return {boolean}
     */
    function isOpenSendingFiltersPopover(filter) {
      return (
        vm.pageType === FILTER_SEGMENTS_PAGE_TYPE.MESSAGE_EDITOR &&
        vm.showedSendingFiltersPopoverInAudience !== false && // Если пользователь не закрывал поповер
        filter.userProp &&
        filter.userProp.prettyName === vm.LAST_VISITED_PAGE_USER_PROP_NAME &&
        $filter('filter')(
          vm.filterUserProps,
          { userProp: { prettyName: vm.LAST_VISITED_PAGE_USER_PROP_NAME } },
          true,
        ).indexOf(filter) === 0
      ); // Если это первое из нескольких выбранное свойство "Последняя посещенная страница"
    }

    /**
     * Открытие модалки удаления сегмента
     *
     * @param {Object} segment - Выбранный сегмент
     */
    function openRemoveSegmentModal(segment) {
      var removeSegmentModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: {
            heading: $translate.instant('filterSegments.removeSegmentModal.heading'),
            body: $translate.instant('filterSegments.removeSegmentModal.body', { activeSegmentName: segment.name }),
            confirmButtonText: $translate.instant('general.remove'),
          },
        },
      });

      removeSegmentModal.result.then(removeSegment);

      function removeSegment() {
        firstValueFrom(segmentModel.remove(segment.id)).then(removeSegmentSuccess);

        function removeSegmentSuccess() {
          toastr.success(
            $translate.instant('filterSegments.toasts.removeSegmentSuccess', { segmentName: segment.name }),
          );
          selectSegment($filter('filter')(vm.segments, { id: PSEUDO_SEGMENT_IDS.ALL })[0]);

          vm.segments.splice(vm.segments.indexOf(segment), 1);
        }
      }
    }

    /**
     * Открытие модалки подтверждения удаления тега пользователей
     *
     * @param {Object} tag Тег пользователей
     */
    function openRemoveTagModal(tag) {
      var removeTagtModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: {
            heading: $translate.instant('filterSegments.removeTagModal.heading'),
            body: $translate.instant('filterSegments.removeTagModal.body', { tag: tag.name }),
            confirmButtonText: $translate.instant('filterSegments.removeTagModal.confirmButtonText'),
          },
        },
      });

      removeTagtModal.result.then(removeTag);

      function removeTag() {
        firstValueFrom(tagModel.removeById(tag.id)).then(removeTagSuccess);

        function removeTagSuccess() {
          // Если тег выбран, то удаляем его из массива
          if (isTagSelected(tag)) {
            vm.filterUserTags.splice(
              vm.filterUserTags.indexOf($filter('filter')(vm.filterUserTags, { tag: { id: tag.id } }, true)[0]),
              1,
            );
          }

          // Обновляем список тегов и сегментов, чтобы связать фильтры в сегментах с удаленными тегами и показать в сегменте, что в нем есть удаленные теги
          firstValueFrom(tagModel.getList(vm.currentApp.id))
            .then(getTagListSuccess)
            .then(getSegmentsList)
            .then(getSegmentsSuccess);

          toastr.success($translate.instant('filterSegments.toasts.removeTagSuccess', { tag: tag.name }), {
            allowHtml: true,
          });

          function getSegmentsList() {
            return firstValueFrom(segmentModel.getList(vm.currentApp.id, true));
          }

          function getSegmentsSuccess(segments) {
            for (var i = 0; i < segments.length; i++) {
              filterAjsModel.linkWithPropsAndTags(segments[i].filters, vm.props, vm.tags);
            }
            vm.segments = segments;
          }

          function getTagListSuccess(tags) {
            vm.tags = tags;
          }
        }
      }
    }

    /**
     * Открытие модалки cохранения сегмента
     *
     * @param {Object} segment - Выбранный сегмент
     */
    function openSaveSegmentModal(segment) {
      var params = {
        currentApp: vm.currentApp,
        filters: collectAllFilters(),
        segment: segment,
      };

      var saveSegmentModal = $uibModal.open({
        controller: 'SaveSegmentModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/save-segment/save-segment.html',
        resolve: {
          modalWindowParams: function () {
            return params;
          },
        },
        size: 'md modal-dialog-centered',
      });

      saveSegmentModal.result.then(saveSegmentSuccess);

      function saveSegmentSuccess(segment) {
        //Если есть сегмент с ID, то изменяем его
        var existingSegment = $filter('filter')(vm.segments, { id: segment.id }, true)[0];
        if (existingSegment) {
          existingSegment.name = segment.name;
          existingSegment.filters = segment.filters;
        } else {
          vm.segments.splice(1, 0, segment); //Добавляем после дефолтного сегмента "Все пользователи". В текущем случае этот сегмент всегда тут будет, поэтому я не проверяю его наличие в массиве сегментов
        }
      }
    }

    /**
     * Проверяет фильтр на заполненность
     *
     * @param {String} propertyKey - Ключ свойства
     * @param {Object} userEventOrProp - Свойство или событие пользователя
     * @param {Array} userEventsOrPropsList - Список ввойств или событий пользователя
     * @returns {boolean}
     */
    function propsRequired(propertyKey, userEventOrProp, userEventsOrPropsList) {
      if (userEventsOrPropsList.length === 1) {
        // Если всего одни фильтр то он валиден в любом случае
        return false;
      } else {
        //Если у него есть name значит он валиден
        return !userEventOrProp[propertyKey];
      }
    }

    /**
     * Удаляет событие пользователя из фильтра
     *
     * @param {Object} filterUserEvent - Удаляемое событие пользователя
     * @param {Array} filterUserEventsList - Список фильтров откуда надо удалить
     */
    function removeFilterUserEvent(filterUserEvent, filterUserEventsList) {
      filterUserEventsList.splice(filterUserEventsList.indexOf(filterUserEvent), 1);

      //Если событий больше нет, надо добавить пустое событие
      if (filterUserEventsList.length === 0) {
        addFilterUserEvent();
      }
    }

    /**
     * Удаляет свойство пользователя из фильтра
     *
     * @param {Object} filterUserProp - Удаляемое свойство пользователя
     * @param {Array} filterUserPropsList - Список фильтров откуда надо удалить
     */
    function removeFilterUserProp(filterUserProp, filterUserPropsList) {
      filterUserPropsList.splice(filterUserPropsList.indexOf(filterUserProp), 1);

      //Если свойств больше нет, надо добавить пустое свойство
      if (filterUserPropsList.length === 0) {
        addFilterUserProp();
      }
    }

    /**
     * Выбор сегмента
     *
     * @param {Object} segment - Выбранный сегмент
     */
    function selectSegment(segment) {
      var deletedTags = getDeletedTagsFromSegment(segment.filters.filters.tags); // Удаленные теги в выбранном сегменте

      vm.selectedSegment = segment;
      vm.selectedFilterType = segment.filters.type;

      vm.filterUserProps = angular.copy(segment.filters.filters.props);
      vm.filterUserEvents = angular.copy(segment.filters.filters.events);

      vm.filterUserTags =
        deletedTags.length > 0
          ? $filter('filter')(angular.copy(segment.filters.filters.tags), { tag: { removed: '!' } })
          : angular.copy(segment.filters.filters.tags); // Исключаем из сегмента удаленные теги

      if (vm.filterUserProps.length === 0) {
        addFilterUserProp();
      }
      if (vm.filterUserEvents.length === 0) {
        addFilterUserEvent();
      }

      var newFilters = collectAllFilters();

      // Если в сегменте есть удаленный тег, то покажем модалку с оповещением об этом
      if (deletedTags.length > 0) {
        var deletedTagsNames = $filter('map')($filter('map')(deletedTags, 'tag'), parseTagName).join(', ');
        var segmentHaveDeletedTagsModal = $uibModal.open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant(
                  'filterSegments.segmentHaveDeletedTagsModal.heading',
                  { tagsCounter: deletedTags.length },
                  'messageformat',
                ),
                body: $translate.instant(
                  'filterSegments.segmentHaveDeletedTagsModal.body',
                  {
                    deletedTags: deletedTagsNames,
                    tagsCounter: deletedTags.length,
                  },
                  'messageformat',
                ),
                cancelButtonClass: 'hidden',
                confirmButtonText: $translate.instant('general.ok'),
              };
            },
          },
          templateUrl: 'js/shared/modals/confirm/confirm.html',
        });

        // Пересохраняем сегмент за пользователя, теперь без удаленного тега
        var params = {
          app: vm.currentApp.id,
          filters: newFilters,
          name: segment.name,
        };
        firstValueFrom(segmentModel.save(segment.id, params)).then(saveSuccess);
      }

      function parseTagName(tag) {
        return '“' + tag.name + '”';
      }

      function saveSuccess() {
        var existingSegment = $filter('filter')(vm.segments, { id: segment.id }, true)[0];
        existingSegment.filters = newFilters; // Обновляем фильтры в выбранном сегменте
      }
    }

    /**
     * Задает корректные данные для свойств
     *
     * @param {Array} newUserProps - Текущие значения для свойств ползьвателя
     * @param {Array} oldUserProps - Предыдущие значения для свойств ползователя
     * @param {Boolean} isInitial - первый раз функция должна выполниться
     */
    function setCurrentDataToPropFilter(newUserProps, oldUserProps, isInitial) {
      if (!angular.equals(newUserProps, oldUserProps) || isInitial) {
        for (var i = 0; i < newUserProps.length; i++) {
          if (newUserProps[i].userProp) {
            //!!! Мега еба... условия. Но к сожалению лучше я не придумал
            // Если userProp новых и старых свойств изменились и это не '$email_status'(Подписка на почтовые рассылки)
            if (
              (!oldUserProps[i] || !angular.equals(newUserProps[i].userProp, oldUserProps[i].userProp)) &&
              newUserProps[i].userProp.name !== '$email_status' &&
              newUserProps[i].userProp.name !== '$telegram_subscriptions'
            ) {
              //Если мы сменили пуши на не пуши
              if (
                newUserProps[i].userProp.name !== '$active_device_push_subscribed' &&
                oldUserProps[i] &&
                oldUserProps[i].userProp &&
                oldUserProps[i].userProp.name === '$active_device_push_subscribed'
              ) {
                newUserProps[i].type = ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN;
                //Если переключили после $email_status на другое значение или любого значения на пуши надо удалить лишние свойства и обнулить type
              } else if (
                ((newUserProps[i].userProp.name !== '$email_status' ||
                  newUserProps[i].userProp.name === '$telegram_subscriptions') &&
                  oldUserProps[i] &&
                  oldUserProps[i].userProp &&
                  (oldUserProps[i].userProp.name === '$email_status' ||
                    oldUserProps[i].userProp.name === '$telegram_subscriptions')) ||
                (newUserProps[i].userProp.name === '$active_device_push_subscribed' &&
                  oldUserProps[i] &&
                  oldUserProps[i].userProp &&
                  oldUserProps[i].userProp.name !== '$active_device_push_subscribed')
              ) {
                newUserProps[i].type = ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN;
                deleteExcessPropValue(newUserProps[i]);
              }
            } else if (
              newUserProps[i].userProp.name === '$telegram_subscriptions' &&
              !angular.equals(newUserProps[i].userProp, oldUserProps[i].userProp)
            ) {
              newUserProps[i].type = ELASTICSEARCH_PROPERTY_OPERATIONS.LIST_CONTAINS;
            }
            // Если типы старого и нового свойства отличаются, вызвать функцию которая выставляет дефолтные значения для свойств
            if (
              angular.isUndefined(oldUserProps[i]) ||
              propertyModel.getElasticsearchOperationTypeByOperation(newUserProps[i].type) !==
                propertyModel.getElasticsearchOperationTypeByOperation(oldUserProps[i].type)
            ) {
              setDefaultValues(newUserProps[i]);
            }
          }
        }
      }
    }

    /**
     * Задает корректные данные для событий
     *
     * @param {Array} newUserEvent - Текущие значения для событий пользователя
     * @param {Array} oldUserEvent - Предыдущие значения для событий пользователя
     */
    function setCurrentDataToEventFilter(newUserEvent, oldUserEvent) {
      if (!angular.equals(newUserEvent, oldUserEvent)) {
        for (var i = 0; i < newUserEvent.length; i++) {
          //NOTE тут исправил один баг при котором падала ошибка, когда длинна newUserEvent и oldUserEvent была разной. Надо проследить за этим решением
          //Да и если так подумать, то задавать корректные данные надо только при изенении событий, но не при удалении или добавлении
          if (newUserEvent[i] && newUserEvent[i].eventType && oldUserEvent[i] && oldUserEvent[i].eventType) {
            // Если типы старого и нового события отличаются, вызвать функцию которая выставляет дефолтные значения для событий
            if (
              propertyModel.getElasticsearchOperationTypeByOperation(newUserEvent[i].type) !==
              propertyModel.getElasticsearchOperationTypeByOperation(oldUserEvent[i].type)
            ) {
              setDefaultValues(newUserEvent[i]);
              // Если тип свойства события был изменен изменить filterUserEvent.type на 'known' т.к. он есть во всех типах
            } else if (newUserEvent[i].propertyType !== oldUserEvent[i].propertyType) {
              newUserEvent[i].type = ELASTICSEARCH_PROPERTY_OPERATIONS.KNOWN;
              setDefaultValues(newUserEvent[i]);
            }
          }
        }
      }
    }

    /**
     * Выставляет дефолтные значения для некоторых типов
     *
     * @param {Object} userEventOrProp - Собитие или свойство пользователя
     */
    function setDefaultValues(userEventOrProp) {
      // Если тип операции для свойства или события дата, выставить дефолтные значения
      if (
        propertyModel.getElasticsearchOperationTypeByOperation(userEventOrProp.type) ===
        ELASTICSEARCH_OPERATION_TYPES.DATE
      ) {
        //Если значения уже определены то не изменяем их
        userEventOrProp.value.value = !!~vm.filterDate.indexOf(userEventOrProp.value.value)
          ? userEventOrProp.value.value
          : vm.filterDate[0];
        userEventOrProp.value.unit = !!~FILTER_DATE_TYPE.indexOf(userEventOrProp.value.unit)
          ? userEventOrProp.value.unit
          : FILTER_DATE_TYPE[0];
      }
    }

    /**
     * Выбрано ли свойство пользователя "Последняя посещенная страница"
     *
     * @param {String} propName - Название свойства
     *
     * @return {boolean}
     */
    function showSendingFiltersPopover(propName) {
      if (vm.showedSendingFiltersPopoverInAudience === null && propName === LAST_VISITED_PAGE_USER_PROP_NAME) {
        vm.showedSendingFiltersPopoverInAudience = true;
        trackShowSendingFiltersPopover();
      }
    }

    /**
     * Применение фильтров в форме
     *
     * @param {Boolean} valid - Валидность формы
     */
    function submitFiltersForm(valid) {
      const newAngularValid = vm.telegramFilterValid() && vm.emailFilterValid();
      vm.valid = valid;
      vm.filters = collectAllFilters();
      if (vm.changed && valid && newAngularValid) {
        //HACK т.к. у vm.filters пропадает ссылка после присваивания, то вне компонента он обновится после дайджест цикла, а это происходит после выполнения vm.changed. Поэтому пришлось вызывать vm.changed через $timeout, чтобы к моменту вызова функции vm.filters был обновлен
        $timeout(function () {
          // Бага Car-10154. Код ниже позволяет избавиться от 2го запроса. Но это заплатка, тут надо более комплексное решение
          // TODO https://favro.com/organization/6fcfbd57a2b44fae2a92e7ea/b9ae317ef8ad43a24b977487?card=Car-10306
          if (!vm.requestIsPerforming) {
            vm.requestIsPerforming = true;
            vm.changed().finally(function () {
              vm.requestIsPerforming = false;
            });
          }
        }, 0);
      }
    }

    /**
     * Включить/исключить тег
     *
     * @param {Object} tag - Тег
     */
    function tagContainsChange(tag) {
      $timeout(() => {
        if (isTagContains(tag)) {
          $filter('filter')(vm.filterUserTags, { tag: { id: tag.id } }, true)[0].type = 'lnotcontains';
        } else {
          $filter('filter')(vm.filterUserTags, { tag: { id: tag.id } }, true)[0].type = 'lcontains';
        }
      });
    }

    /**
     * Выбор тега
     *
     * @param {Object} tag - Тег
     */
    function tagSelectChange(tag) {
      $timeout(() => {
        //Если тег уже выбран удаляем его из массива
        if (isTagSelected(tag)) {
          vm.filterUserTags.splice(
            vm.filterUserTags.indexOf($filter('filter')(vm.filterUserTags, { tag: { id: tag.id } }, true)[0]),
            1,
          );
        } else {
          vm.filterUserTags.push({
            tag: tag,
            type: 'lcontains',
          });
        }
      });
    }

    /**
     * Трек изменения флиьтра
     *
     * @param filterType
     */
    function trackClickFilterProp(filterType) {
      if (vm.template === 'horizontal') {
        carrotquestHelper.track('Автосообщения - клик на выбор фильтра в аудитории');
      } else {
        trackChangeFilter(filterType);
      }
    }

    /**
     * Трек события клика на сегмент
     */
    function trackClickSegment() {
      if (vm.template === 'horizontal') {
        carrotquestHelper.track('Автосообщения - клик на сегмент в аудитории');
      }
    }

    /**
     * Трек клика на тег
     */
    function trackClickTag() {
      if (vm.template === 'horizontal') {
        carrotquestHelper.track('Автосообщения - клик на тег');
      } else {
        carrotquestHelper.track('Пользователи - кликнул на тег');
      }
    }

    /**
     * Трек клика на включение/исключение тега
     */
    function trackClickTagContains() {
      if (vm.template == 'horizontal') {
        carrotquestHelper.track('Автосообщения - клик на тег - включать/исключать');
      } else {
        carrotquestHelper.track('Пользователи - кликнул на тег - включать/исключать');
      }
    }

    /**
     * Трек изменения фильтра
     */
    function trackChangeFilterType() {
      if (vm.template !== 'horizontal') {
        trackChangeFilter(FILTER_PROP_TYPES_FOR_TRACK.All_OR_ANY);
      }
    }

    /**
     * Трек изменения фильтра в зависимости от типа
     *
     * @param {FILTER_PROP_TYPES_FOR_TRACK} filterType - Тип фильтра
     */
    function trackChangeFilter(filterType) {
      var filterTypeDescriptions = {};

      filterTypeDescriptions[FILTER_PROP_TYPES_FOR_TRACK.EVENT] = 'События';
      filterTypeDescriptions[FILTER_PROP_TYPES_FOR_TRACK.PROPERTY] = 'Свойства';
      filterTypeDescriptions[FILTER_PROP_TYPES_FOR_TRACK.All_OR_ANY] = 'Всем/Любому';

      carrotquestHelper.track('Пользователи - применил фильтр', { Фильтр: filterTypeDescriptions[filterType] });
    }

    /**
     * Трек клика на "Сохранить как сегмент"
     */
    function trackClickSaveSegment() {
      carrotquestHelper.track('Пользователи - кликнул на "Сохранить как сегмент"', { App: vm.currentApp.name });
    }

    /**
     * Трек показа поповера с условиями отрпавки по URL
     */
    function trackShowSendingFiltersPopover() {
      let params = {
        'Тип коммуникации': vm.messagePageType,
      };

      carrotquestHelper.track('Ограничение отправки на страницах - показ поповера на шаге аудитория', params);
    }

    /**
     * Фильтр тегов пользователей
     * Мы должны показать удалённый тег в редакторе автосообщений, чтобы его можно было исключить из фильтров
     *
     * @param userTag Тег пользователя
     * @returns {boolean}
     */
    function userTagFilter(userTag) {
      return vm.pageType === vm.FILTER_SEGMENTS_PAGE_TYPE.MESSAGE_EDITOR || !userTag.removed;
    }

    function updateFilterFromA2(index, value) {
      vm.filterUserProps[index] = {
        ...vm.filterUserProps[index],
        ...value,
      };
      $timeout();
    }

    function onJinjaFilterTemplateChanged(changedJinjaFilterTemplate) {
      vm.jinjaFilterTemplate = changedJinjaFilterTemplate;
    }

    function checkJinjaFilterTemplate() {
      firstValueFrom(messageUtilsModel.checkJinjaFilterTemplate(vm.jinjaFilterTemplate))
        .then((response) => {
          vm.jinjaFilterTemplateCheckingResult = `${response.validationResult}`;
        })
        .catch((response) => {
          vm.jinjaFilterTemplateCheckingResult = response.errorMessage;
        });
    }
  }
})();

(function () {
  'use strict';

  require('./facebook-oauth.controller');

  angular.module('myApp.integrations').component('cqFacebookOAuth', cqFacebookOAuth());

  function cqFacebookOAuth() {
    return {
      controller: 'CqFacebookOAuthController',
      controllerAs: 'vm',
    };
  }
})();

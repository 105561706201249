(function () {
  'use strict';

  require('./starter-guide-write-self.controller');

  angular.module('myApp.starterGuide').component('cqStarterGuideWriteSelf', cqStarterGuideWriteSelf());

  function cqStarterGuideWriteSelf() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        isStepMade: '=', // Пройденность шага
      },
      controller: 'CqStarterGuideWriteSelfController',
      controllerAs: 'vm',
      template: require('./starter-guide-write-self.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ChannelModule } from '@panel/app/partials/channel/channel.module';
import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { TagDefaultModule } from '@panel/app/partials/tag-default/tag-default.module';

import { HotkeysModalComponent } from './hotkeys-modal.component';

@NgModule({
  declarations: [HotkeysModalComponent],
  exports: [HotkeysModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    NgbTooltipModule,
    ChannelIconModule,
    TagDefaultModule,
    ChannelModule,
    ReactiveFormsModule,
  ],
})
export class HotkeysModalModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { AddonsModule } from '@panel/app/pages/subscription/general/signed/addons/addons.module';
import { PlanInfoModule } from '@panel/app/pages/subscription/general/signed/plan-info/plan-info.module';
import { EmojiModule } from '@panel/app/partials/emoji/emoji.module';

import { EmojiSelectorComponent } from './emoji-selector.component';

@NgModule({
  declarations: [EmojiSelectorComponent],
  exports: [EmojiSelectorComponent],
  imports: [CommonModule, FormsModule, EmojiModule, AddonsModule, OverlayscrollbarsModule, PlanInfoModule],
})
export class EmojiSelectorModule {}

import { checkMessagePartValidityForNotification } from '../../utils/email-notification–availability.validator';
import { MESSAGE_PART_TYPES } from '../../../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../../../app/http/feature/feature.constants';

/**
 * Получение сгенерированного названия триггерного сообщения из типов вариантов в сообщении
 *
 * @param {Array.<Object>} messageParts Варианты сообщения
 * @param $translate Переводчик
 */
export function getGeneratedMessageName(messageParts, $translate) {
  let namePrefix =
    messageParts.length < 2
      ? $translate.instant('models.message.generatedNamePrefix.single')
      : $translate.instant('models.message.generatedNamePrefix.abTest');
  let messagePartsTypes = [];

  messageParts.forEach((part) => {
    messagePartsTypes.push($translate.instant('models.message.messagePartTypes.' + part.type));
  });

  return namePrefix + ' ' + messagePartsTypes.join('/');
}

(function () {
  'use strict';

  angular.module('myApp.autoMessages').controller('CqAutoMessageGoalController', CqAutoMessageGoalController);

  function CqAutoMessageGoalController(
    $scope,
    $translate,
    $filter,
    AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS,
    carrotquestHelper,
    featureModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.checkValidityForNotification = checkValidityForNotification;
      vm.collapseChange = collapseChange;
      vm.disableNotificationSelector = false;
      vm.isCollapsed = true; //Состояние аккордеона
      vm.FEATURES = FEATURES;
      vm.featureModel = featureModel;
      vm.getEventTypeByEventId = getEventTypeByEventId;
      vm.getIntegration = getIntegration;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.trackClickExpandSettingsGoal = trackClickExpandSettingsGoal;

      vm.abASide = null;
      vm.abBSide = null;

      checkValidityForNotification();
    }

    /**
     * Вызывает валидатор messageParts и в зависимости от результата может задизейблить селектор нотификации
     */
    function checkValidityForNotification() {
      const parts = vm.currentMessage.parts;
      const { aSideError, bSideError } = checkMessagePartValidityForNotification(parts, vm.MESSAGE_PART_TYPES);
      vm.abASide = aSideError;
      vm.abBSide = bSideError;

      if (parts.length === 1) {
        vm.disableNotificationSelector = Boolean(vm.abASide);
      } else if (parts.length === 2) {
        vm.disableNotificationSelector = vm.abASide && vm.abBSide;
      }
    }

    function collapseChange() {
      //Нужно чтобы отработало сразу
      $scope.$apply(() => {
        vm.isCollapsed = !vm.isCollapsed;
        vm.trackClickExpandSettingsGoal();
      });
    }

    /**
     * Возвращает имя события по ID
     *
     * @param {String} eventId - ID события
     * @returns String}
     */
    function getEventTypeByEventId(eventId) {
      return $filter('filter')(vm.eventTypes, { id: eventId }, true)[0];
    }

    /**
     * Получение объекта интеграции по ключу
     * @param {'amocrm' | 'emailNotification'} key
     * @returns {*|null}
     */
    function getIntegration(key) {
      const integrations = vm.currentMessage.notificationIntegrations;
      return integrations && (integrations[key]?.integration ?? null);
    }

    /**
     * Трек клика 'Автосообщения -  Настройка запуска - раскрыл настройки передачи данных и цели'
     */
    function trackClickExpandSettingsGoal() {
      if (vm.isCollapsed) {
        return;
      }
      carrotquestHelper.track('Автосообщения - Настройка запуска - раскрыл настройки передачи данных и цели');
    }
  }
})();

<ng-container *transloco="let t; read: 'autoAssignmentComponent'">
  <div class="card mb-0 border-0">
    <div class="card-body p-30">
      <div class="wrapper">
        <form (ngSubmit)="saveSettings()">
          <cq-enabling-feature-control [formControl]="activeControl"></cq-enabling-feature-control>
          <hr />
          <div [ngClass]="{ unavailable: !activeControl.value }">
            <cq-team-members-select-control
              class="mb-30"
              [teamMembers]="teamMembers"
              [formControl]="teamMembersControl"
            ></cq-team-members-select-control>
            <cq-assignment-type-control [formControl]="typeControl"></cq-assignment-type-control>
          </div>
          <button
            class="btn btn-primary mt-30"
            type="submit"
            [disabled]="isApiRequestPerformed$ | async"
          >
            {{ t('saveSettingsButton') }}
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-container>

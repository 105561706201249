<ng-container *transloco="let t; read: 'topUpBalanceModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <form
      (ngSubmit)="topUpBalance()"
      [formGroup]="topUpBalanceForm"
    >
      <div
        class="form-group"
        [cqShowError]="topUpBalanceForm.controls.amount"
      >
        <label for="amountValue">{{ t('topUpBalanceForm.amountControl.label') }}</label>
        <input
          id="amountValue"
          class="form-control"
          cqNumber
          numberType="FLOAT"
          [formControl]="topUpBalanceForm.controls.amount"
          [placeholder]="
            t('topUpBalanceForm.amountControl.placeholder', {
              min: MIN_PAYMENT_AMOUNT | toFormattedMoney,
              max: MAX_PAYMENT_AMOUNT | toFormattedMoney
            })
          "
        />
        <cq-validation-messages [control]="topUpBalanceForm.controls.amount">
          <cq-validation-message errorName="required">{{
            t('topUpBalanceForm.amountControl.errors.required')
          }}</cq-validation-message>
          <cq-validation-message errorName="min">{{
            t('topUpBalanceForm.amountControl.errors.min', { min: MIN_PAYMENT_AMOUNT | toFormattedMoney })
          }}</cq-validation-message>
          <cq-validation-message errorName="max">{{
            t('topUpBalanceForm.amountControl.errors.max', { max: MAX_PAYMENT_AMOUNT | toFormattedMoney })
          }}</cq-validation-message>
        </cq-validation-messages>
      </div>
      <input
        class="hidden"
        id="topUpBalanceForm.submit"
        type="submit"
        [disabled]="isApiRequestPerformed | async"
      />
    </form>
    <ng-container *tuiLet="subscriptionStore.billing$ | async as billing">
      <ng-container *ngIf="billing && billing.subscription">
        <div class="text-secondary">
          {{
            t('note', {
              date: billing.subscription.currentTermEnd | amDateFormat: 'L'
            })
          }}
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <label
      class="btn btn-primary"
      for="topUpBalanceForm.submit"
      [ngClass]="{ disabled: isDisabledChoosePaymentMethodButton() }"
      >{{ t('choosePaymentMethodButton') }}</label
    >
  </div>
</ng-container>

(function () {
  'use strict';

  require('./remove-conversation-part.controller');

  angular
    .module('myApp.modals.removeConversationPart')
    .component('cqRemoveConversationPartModal', cqRemoveConversationPartModal());

  function cqRemoveConversationPartModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentAssignee - Оператор, назначенный на диалог, в который отправляется приглашение
        // djangoUser - текущий пользователь
      },
      controller: 'CqRemoveConversationPartController',
      controllerAs: 'vm',
      template: require('./remove-conversation-part.html'),
    };
  }
})();

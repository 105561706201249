import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessagePreviewPhoneBottomWrapperComponent } from './message-preview-phone-bottom-wrapper.component';

@NgModule({
  declarations: [MessagePreviewPhoneBottomWrapperComponent],
  exports: [MessagePreviewPhoneBottomWrapperComponent],
  imports: [CommonModule],
})
export class MessagePreviewPhoneBottomWrapperModule {}

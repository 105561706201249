import { Pipe, PipeTransform } from '@angular/core';

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';

/**
 * Фильтрация доступных для выбора способов продолжить/закончить ветку
 */
@Pipe({
  name: 'filterTargetActionOptions',
})
export class FilterTargetActionOptionsPipe implements PipeTransform {
  transform(types: CHAT_BOT_ACTIONS_TYPES[], isInterruptScenarioBlock: boolean): CHAT_BOT_ACTIONS_TYPES[] {
    if (isInterruptScenarioBlock) {
      return types.filter((type) => {
        switch (type) {
          case CHAT_BOT_ACTIONS_TYPES.BUTTON:
          case CHAT_BOT_ACTIONS_TYPES.PROPERTY_FIELD:
          case CHAT_BOT_ACTIONS_TYPES.CLOSE:
            return false;
          default:
            return true;
        }
      });
    }
    return types;
  }
}

import { ELASTICSEARCH_PROPERTY_OPERATIONS } from '@http/property/property.constants';
import { TIME_UNIT_MEASURES, TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

/** Максимальное количество дней для задержки отправки триггерного сообщения */
export const AFTER_TIME_MAX_VALUE = 365;

/** Минимальное количество дней для задержки отправки триггерного сообщения */
export const AFTER_TIME_MIN_VALUE = 1;

/** Максимальный промежуток времени (в месяцах), по истечению которого сообщение удалится из истории диалогов */
export const DELETE_MESSAGE_MAX_INTERVAL = TIME_UNIT_MEASURES[TIME_UNITS.MONTH] * 3;

/** Минимальный промежуток времени (в секундах), по истечению которого сообщение удалится из истории диалогов */
export const DELETE_MESSAGE_MIN_INTERVAL = TIME_UNIT_MEASURES[TIME_UNITS.SECOND] * 30;

/** Максимальное количество дней, в течение которых отслеживается достижение цели пользователем */
export const GOAL_EVENT_MAX_TIMEOUT = 365;

/** Минимальное количество дней, в течение которых отслеживается достижение цели пользователем */
export const GOAL_EVENT_MIN_TIMEOUT = 1;

/** Максимальное время для повторной отправки */
export const REPEAT_MAX_DELAY = 365;

/** Минимальное время для повторной отправки */
export const REPEAT_MIN_DELAY = 1;

/** Минимальное время для повторной отправки цепочки (секунды) */
export const TRIGGER_CHAIN_REPEAT_MIN_DELAY = 60 * 10;

/** Группы типов URL-фильтров */
export enum SENDING_FILTERS_GROUP_TYPES {
  EXCLUSION = 'exclusion', // Группа исключения страниц
  INCLUSION = 'inclusion', // Группа включения страниц
  NO = 'no', // Отсутствие группы
}

/** Операции с фильтрами по URL */
export enum URL_FILTERS_OPERATIONS {
  URL_EQ = 'url_eq', // адрес равен
  URL_PATH_EQ = 'url_path_eq', // адрес равен, учитываем все параметры в адресе
  URL_NOT_EQ = 'url_neq', // адрес не равен
  URL_PATH_NOT_EQ = 'url_path_neq', // адрес не равен, учитываем все параметры в адресе
}

/** Получение массива типов фильтров по URL, на котором может сработать сообщение при исключении адресов */
export const SENDING_FILTERS_TYPES_ARRAY_FOR_EXCLUSION = [
  URL_FILTERS_OPERATIONS.URL_NOT_EQ, // не равно
  URL_FILTERS_OPERATIONS.URL_PATH_NOT_EQ, // адрес не равен, учитываем все параметры в адресе
  ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_CONTAINS, // не содержит
];

/** Получение массива типов фильтров по URL, на котором может сработать сообщение при включении адресов */
export const SENDING_FILTERS_TYPES_ARRAY_FOR_INCLUSION = [
  URL_FILTERS_OPERATIONS.URL_EQ, // равно
  URL_FILTERS_OPERATIONS.URL_PATH_EQ, // адрес равен, учитываем все параметры в адресе
  ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS, // содержит
];

/** Получение типов фильтров по URL, на котором может сработать сообщение */
export const SENDING_FILTERS_TYPES = {
  URL_CONTAINS: ELASTICSEARCH_PROPERTY_OPERATIONS.STR_CONTAINS, // содержит
  URL_NOT_CONTAINS: ELASTICSEARCH_PROPERTY_OPERATIONS.STR_NOT_CONTAINS, // не содержит
  URL_EQ: URL_FILTERS_OPERATIONS.URL_EQ, // равно
  URL_PATH_EQ: URL_FILTERS_OPERATIONS.URL_PATH_EQ, // содержит с параметрами
  URL_NOT_EQ: URL_FILTERS_OPERATIONS.URL_NOT_EQ, // не равно
  URL_PATH_NOT_EQ: URL_FILTERS_OPERATIONS.URL_PATH_NOT_EQ, // не содержит с параметрами
  URL_CONTAINS_WILDCARD: 'str_contains_wildcard', // содержит с звездочкой (операция не относится к эластику, поэтому добавлена отдельно)
  URL_NOT_CONTAINS_WILDCARD: 'str_notcontains_wildcard', // не содержит содержит с звездочкой (операция не относится к эластику, поэтому добавлена отдельно)
};

/**
 * Группы автособытий
 */
export enum AUTO_EVENTS_GROUPS {
  URL = 'url', // Посещение страницы
  URL_SCROLL = 'url_scroll', // Прокрутка страницы до конца
  CLICK = 'click', // Клик по элементу
  INPUT = 'input', // Заполнил поле ввода
  PRO = 'pro', // Продвинутый мастер сбора данных
}

/**
 * Получение списка групп автособытий
 */
export const AUTO_EVENTS_GROUPS_LIST = [
  AUTO_EVENTS_GROUPS.URL,
  AUTO_EVENTS_GROUPS.URL_SCROLL,
  AUTO_EVENTS_GROUPS.CLICK,
  AUTO_EVENTS_GROUPS.INPUT,
  AUTO_EVENTS_GROUPS.PRO,
];

/**
 *  Поулчение типов параметров автособытий
 */
export enum AUTO_EVENTS_PARAMS_TYPES {
  EVENT = 'event',
  IDENTIFY = 'identify',
}

/**
 *  Поулчение список типов параметров автособытий
 */
export const AUTO_EVENTS_PARAMS_TYPES_LIST = [AUTO_EVENTS_PARAMS_TYPES.EVENT, AUTO_EVENTS_PARAMS_TYPES.IDENTIFY];

/**
 * Типы автособытий
 */
export enum AUTO_EVENTS_TYPES {
  CLICK = 'click', // Клик по элементу
  FORM = 'form', // Отправка формы
  INPUT = 'input', // Заполнил поле ввода
  URL = 'url', // Посещение страницы
  URL_SCROLL = 'url_scroll', // Прокрутка страницы до конца
}

/**
 * Типы автособытий
 */
export const AUTO_EVENTS_TYPES_LIST = [
  AUTO_EVENTS_TYPES.URL,
  AUTO_EVENTS_TYPES.URL_SCROLL,
  AUTO_EVENTS_TYPES.CLICK,
  AUTO_EVENTS_TYPES.INPUT,
  AUTO_EVENTS_TYPES.FORM,
];

/**
 * Получение групп настроек мастера сбора данных
 */
export enum TRACK_MASTER_SETTING_GROUPS {
  BASE = 'base', // Базовые возможности
  PRO = 'pro', // Продвинутые возможности
}

/**
 * Получение групп настроек мастера сбора данных
 */
export const TRACK_MASTER_SETTING_GROUPS_LIST = [TRACK_MASTER_SETTING_GROUPS.BASE, TRACK_MASTER_SETTING_GROUPS.PRO];

/**
 * Получение настроек мастера сбора данных
 */
export enum TRACK_MASTER_SETTINGS {
  AUTO_TRACK = 'auto_track', // Автотрекинг
  CLICK = 'click', // Клик по элементу
  INPUT = 'input', // Заполнение поля ввода
  INTEGRATIONS = 'integrations', // Интеграции
  JS_API = 'js_api', // Использовать JavaScript API
  JS_CODE = 'js_code', // Настроить JavaScript код
  PRO = 'pro', // Продвинутый мастер сбора данных
  URL = 'url', // Посещение страницы
  URL_API = 'url_api', // Использовать URL API
  URL_SCROLL = 'url_scroll', // Прокрутка страницы до конца
  USER_ID = 'user_id', // Настроить передачу User ID
  WEB_API = 'web_api', // Использовать Web API
}

/**
 * Получение настроек по группе
 */
export const TRACK_MASTER_SETTING_BY_GROUPS = {
  [TRACK_MASTER_SETTING_GROUPS.BASE]: [
    TRACK_MASTER_SETTINGS.URL,
    TRACK_MASTER_SETTINGS.URL_SCROLL,
    TRACK_MASTER_SETTINGS.CLICK,
    TRACK_MASTER_SETTINGS.INPUT,
  ],
  [TRACK_MASTER_SETTING_GROUPS.PRO]: [
    TRACK_MASTER_SETTINGS.PRO,
    TRACK_MASTER_SETTINGS.JS_API,
    TRACK_MASTER_SETTINGS.WEB_API,
    TRACK_MASTER_SETTINGS.USER_ID,
    TRACK_MASTER_SETTINGS.JS_CODE,
    TRACK_MASTER_SETTINGS.URL_API,
    TRACK_MASTER_SETTINGS.AUTO_TRACK,
    TRACK_MASTER_SETTINGS.INTEGRATIONS,
  ],
};

export const AUTO_EVENTS_GROUPS_ICONS: Record<AUTO_EVENTS_GROUPS, string> = {
  [AUTO_EVENTS_GROUPS.URL]: 'cqi-window',
  [AUTO_EVENTS_GROUPS.URL_SCROLL]: 'cqi-window-arrow-down',
  [AUTO_EVENTS_GROUPS.CLICK]: 'cqi-cursor-arrow',
  [AUTO_EVENTS_GROUPS.INPUT]: 'cqi-italic',
  [AUTO_EVENTS_GROUPS.PRO]: '',
};

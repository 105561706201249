import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { WizardService } from '@panel/app/partials/wizard/service/wizard.service';

@Component({
  selector: 'cq-wizard-new',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardComponent implements OnInit {
  /** Имя текущего шага */
  @Input() currentStepName!: string;
  /** Класс для меню */
  @Input() menuClass?: string;
  /** Флаг нового визарда */
  @Input() newWizard?: boolean;
  /** Имя последнего завершенного шага */
  @Input() lastCompletedStepName?: string;
  /** Ограничен ли доступ к шагам */
  @Input() restricted?: boolean;

  protected currentStep: any = null;
  protected lastCompletedStep: any = null;

  constructor(protected readonly wizardService: WizardService, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.wizardService.restricted = this.restricted;
    this.wizardService.changes.subscribe((step) => {
      step.cdr && step.cdr.detectChanges();
      this.cdr.detectChanges();
    });
  }
}

<ng-container *transloco="let t">
  <div class="flex align-items-center margin-between-cols-20">
    <!-- Включить/Приостановить без ограничений по тарифу -->
    <button
      *ngIf="accessToIntegrationType.hasAccess"
      class="btn btn-outline-primary"
      (click)="onClickToggleIntegrationStateButton()"
    >
      <span *ngIf="isActiveIntegration()">
        {{ t('general.suspend') }}
      </span>

      <span *ngIf="isSuspendedIntegration()">
        {{ t('general.enable') }}
      </span>
    </button>

    <!-- Включить с ограничением по тарифу -->
    <button
      *ngIf="!accessToIntegrationType.hasAccess"
      class="btn btn-outline-primary"
      (click)="onClickToggleIntegrationStateButton()"
    >
      <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
      <span>{{ t('general.enable') }}</span>
    </button>

    <!-- Удалить -->
    <button
      class="btn btn-outline-primary"
      data-test="remove-integration-button"
      (click)="onClickRemoveIntegrationButton()"
    >
      {{ t('general.remove') }}
    </button>
  </div>
</ng-container>

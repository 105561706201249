import unionBy from 'lodash-es/unionBy';
import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.userCard').controller('CqUserEventsListController', CqUserEventsListController);

  function CqUserEventsListController(
    $filter,
    $q,
    $timeout,
    $uibModal,
    carrotquestHelper,
    paywallService,
    planFeatureAccessService,
    userModel,
    USER_EVENTS_LIST_TABS,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToUsersEvents = planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_EVENTS, vm.currentApp);

      vm.eventTab = USER_EVENTS_LIST_TABS.EVENTS_LIST; // вкладка в событиях
      vm.loadGroupEvents = loadGroupEvents;
      vm.openAddEventModal = openAddEventModal;
      vm.onEventGroupCollapsed = onEventGroupCollapsed;
      vm.paywallService = paywallService;
      vm.searchGroup = ''; // поиск события в группировке событий
      vm.updateEvents = updateEvents;
    }

    /**
     * Загрузка группы событий
     *
     * @param {Object} event Событие
     * @param {String} after Флаг пагинации
     */
    function loadGroupEvents(event, after) {
      var deferred = $q.defer();

      event.isGroupLoading = true;
      firstValueFrom(userModel.getEvents(vm.userId, event.name, true, after))
        .then(getEventsSuccess)
        .catch(() => {})
        .finally(() => {
          event.isGroupLoading = false;
        });

      return deferred.promise;

      function getEventsSuccess(data) {
        if (after) {
          event.userEvents = event.userEvents.concat(data.events);
        } else {
          event.userEvents = data.events;
        }

        event.userEventsPaginator = data.paginatorParams;

        for (var i = 0; i < event.userEvents.length; i++) {
          var props = [];
          for (var key in event.userEvents[i].props) {
            props.push({
              key: $filter('prettyEventPropName')(key),
              value: event.userEvents[i].props[key],
            });
          }
          event.userEvents[i].propsArray = props;
        }

        $timeout(function () {
          deferred.resolve();
        }, 0);
      }
    }

    /**
     * Открытие модалки добавления события пользователя
     */
    function openAddEventModal() {
      const addEventModal = $uibModal.open({
        component: 'cqAddEventModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          eventTypes: angular.bind(null, angular.identity, vm.eventTypes),
          userId: angular.bind(null, angular.identity, vm.userId),
        },
        size: 'md modal-dialog-centered',
      });

      addEventModal.result.then(addEventSuccess).catch(() => {});

      function addEventSuccess(event) {
        if (!event) {
          return;
        }

        trackToAddEventInCardUser();
        resetEvents();
      }
    }

    /**
     * Колбэк при сворачивании группы события
     * @param {Object} event Тип события
     */
    function onEventGroupCollapsed(event) {
      event.userEventsPaginator = null;
      event.userEvents = [];
    }

    /**
     * Обновление событий, после добавления события
     */
    function resetEvents() {
      vm.events = [];
      vm.eventsPaginator = null;
      updateEvents();
    }

    /**
     * Обновление списка событий
     */
    function updateEvents() {
      if (!vm.accessToUsersEvents.hasAccess) {
        return;
      }

      vm.eventsLoaded = false;

      firstValueFrom(userModel.getEvents(vm.userId, null, true, vm.eventsPaginator))
        .then(getUserEventsSuccess)
        .catch(() => {})
        .finally(() => {
          vm.eventsLoaded = true;
          $timeout();
        });

      function getUserEventsSuccess(data) {
        // Делаю объединение через unionBy по id, потому что могут быть кейсы, когда в массив придёт два одинаковых события
        vm.events = unionBy(vm.events, data.events, 'id');
        vm.eventsPaginator = data.paginatorParams;

        for (var i = 0; i < vm.events.length; i++) {
          var props = [];
          for (var key in vm.events[i].props) {
            props.push({
              key: $filter('prettyEventPropName')(key),
              value: vm.events[i].props[key],
            });
          }
          vm.events[i].propsArray = props;
        }
      }
    }

    function trackToAddEventInCardUser() {
      carrotquestHelper.track('Карточка пользователя - добавил событие');
    }
  }
})();

<ng-container *transloco="let t; read: 'amocrmDataFromContactsComponent'">
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="amoCrmDataCollapse.toggle()"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
  >
    <h4 class="no-margin">
      {{t('heading', {
      projectName,
      integrationTypeName
    })}}
    </h4>
    <div class="ml-auto">{{ amoCrmDataCount }}</div>
    <i
      class="cqi-sm ml-10"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>

  <div
    #amoCrmDataCollapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="pt-20">
      <div class="mb-20">
        {{t('description', {
        projectName,
        integrationTypeName
      })}}
      </div>

      <div
        *ngIf="controlAmocrmPropsMappingInternal.controls.length"
        class="d-flex align-items-center"
      >
        <p class="flex-grow no-flex-basis font-weight-bolder">{{t('amocrm.title', {integrationTypeName})}}</p>
        <div class="mx-10 invisible">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>
        <p class="flex-grow no-flex-basis font-weight-bolder">{{t('prop.title', {projectName})}}</p>
        <button
          class="btn btn-borderless-primary ml-10 invisible"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </div>

      <div
        class="d-flex align-items-center margin-bottom-15"
        *ngFor="let formGroup of controlAmocrmPropsMappingInternal.controls; first as first; index as index"
      >
        <div class="flex-grow min-width-0 no-flex-basis">
          <cq-amocrm-fields-select
            [fields]="amocrmContactFields"
            [formControl]="formGroup.controls.key"
            [integrationId]="integrationId"
            [type]="'contact'"
          ></cq-amocrm-fields-select>
        </div>

        <div class="mx-10">
          <i class="cqi-sm cqi-arrow-right"></i>
        </div>

        <div
          class="flex-grow min-width-0 no-flex-basis"
          [cqShowError]="formGroup.controls.value"
        >
          <ng-select
            *ngIf="isSelectedAmoSystemField(formGroup.controls.key)"
            [clearable]="false"
            [formControl]="formGroup.controls.value"
            [items]="systemProperties"
            [placeholder]="t('prop.select.placeholder')"
            [readonly]="true"
            [required]="true"
            bindLabel="prettyName"
            bindValue="name"
            groupBy="group"
          >
            <ng-template
              let-item="item"
              ng-option-tmp
            >
              <span class="text-wrap">{{ item.prettyName }}</span>
            </ng-template>
          </ng-select>
          <ng-select
            *ngIf="!isSelectedAmoSystemField(formGroup.controls.key)"
            [clearable]="false"
            [formControl]="formGroup.controls.value"
            [items]="customProperties"
            [placeholder]="t('prop.select.placeholder')"
            [required]="true"
            bindLabel="prettyName"
            bindValue="name"
            groupBy="group"
          >
            <ng-template
              let-item="item"
              ng-option-tmp
            >
              <span class="text-wrap">{{ item.prettyName }}</span>
            </ng-template>
          </ng-select>
          <cq-validation-messages [control]="formGroup.controls.value">
            <cq-validation-message errorName="required">
              {{ t('prop.select.errors.required', {integrationTypeName}) }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>

        <button
          class="btn btn-borderless-primary ml-10"
          (click)="removeItem(index)"
          type="button"
        >
          <i class="btn-icon cqi-sm cqi-times"></i>
        </button>
      </div>

      <button
        class="btn btn-borderless-primary mt-5"
        (click)="addProperty()"
        type="button"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        {{t('addAmocrmButton', {integrationTypeName})}}
      </button>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith, tap } from 'rxjs/operators';

import { ConversationPartAttachment } from '@http/conversation-part/internal-types';
import { WHATS_APP_TEMPLATE_HEADER_TYPE } from '@http/whats-app-template/constnats/whats-app-templates.constants';
import { WhatsAppTemplateModel } from '@http/whats-app-template/models/whats-app-template';
import {
  ParsedWhatsAppTemplate,
  WhatsappEdnaVariablesObj,
} from '@http/whats-app-template/types/whats-app-template.types';
import {
  WhatsAppEdnaTemplate,
  WhatsAppEdnaTemplateHeaderType,
} from '@http/whats-app-template/types/whats-app-templates.types';
import { EXPECTED_ATTACHMENT } from '@panel/app/partials/conversation-part-preview/conversation-part-preview.constants';
import { SendingWhatsAppTemplateForm } from '@panel/app/partials/modals/sending-whats-app-template/sending-whats-app-template.form';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

/** Максимальная длина текста шаблона */
const TEMPLATE_BODY_MAX_LENGTH = 1000;

@Component({
  selector: 'cq-sending-whats-app-template[template]',
  templateUrl: './sending-whats-app-template.component.html',
  styleUrls: ['./sending-whats-app-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendingWhatsAppTemplateComponent implements OnInit {
  /** Шаблон WhatsApp */
  @Input() template!: WhatsAppEdnaTemplate;

  /** Шаблон WhatsApp, распарсенный в формат реплики диалога */
  parsedTemplate!: ParsedWhatsAppTemplate;
  /** Распарсенный файл в формат аттача. Используется только для превью */
  parsedTemplateAttachment!: ConversationPartAttachment[];
  /** Шаблон WhatsApp, распарсенный в формат реплики диалога с подсветкой переменных. Используется только для превью */
  parsedTemplateBodyWithHtml!: string;
  /** Форма с контролами переменных */
  templateForm!: SendingWhatsAppTemplateForm;
  /** Массив переменных в тексте шаблона */
  //@ts-ignore
  templateVariables: RegExpMatchArray = [];

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly fileHelperService: FileHelperService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly whatsAppTemplateModel: WhatsAppTemplateModel,
  ) {}

  get attachmentControl() {
    return this.templateForm.controls.attachments;
  }

  /** Тип аттача в шаблоне */
  get headerType(): `${WHATS_APP_TEMPLATE_HEADER_TYPE}` | undefined {
    return this.template.content.header?.headerType;
  }

  get variablesControls() {
    return this.templateForm.controls.variables.controls;
  }

  ngOnInit(): void {
    this.parsedTemplate = this.whatsAppTemplateModel.parseToConversationReply(this.template);
    //@ts-ignore
    this.templateVariables = this.parsedTemplate.body.match(/{{\d+}}/g) || [];

    this.templateForm = new SendingWhatsAppTemplateForm(
      this.hasAttachment(this.headerType),
      this.templateVariables.length,
    );

    this.templateForm.controls.variables.valueChanges
      .pipe(
        // Формируем массив из пустых строк длиной === количеству переменных в шаблоне
        startWith(Array.from({ length: this.templateVariables.length }, () => '')),
        // Формируем объект вида {"1": "Значение переменной 1", "2": "Значение переменной 2", ...}
        tap((newValues: Array<string>) => {
          let variablesObj: WhatsappEdnaVariablesObj = {};
          newValues.forEach((value, index) => {
            variablesObj[index + 1] = value;
          });
          this.parsedTemplate.bodyJson.whatsappEdnaTemplate.variables = variablesObj;
        }),
        // Заменяем переменные в теле шаблона на введённые значения
        tap((newValues: Array<string>) => {
          let newBody = this.whatsAppTemplateModel.parseToConversationReply(this.template).body;

          newValues.forEach((value, index) => {
            newBody = this.templateBodyReplacer(value, index, newBody, this.templateVariables);
          });

          this.parsedTemplate.body = newBody;
        }),
        // Заменяем переменные в теле шаблона на введённые значения, обёрнутые в теги, для превью
        map((newValues: Array<string>): string => {
          let newBody = this.whatsAppTemplateModel.parseToConversationReply(this.template).body;

          newValues.forEach((value, index) => {
            newBody = this.templateBodyReplacer(value, index, newBody, this.templateVariables, true);
          });

          return newBody;
        }),
      )
      .subscribe((newBody: string) => (this.parsedTemplateBodyWithHtml = newBody));

    this.attachmentControl.valueChanges
      .pipe(
        startWith([]),
        // Кладём файл (именно File) в attachment
        tap((files: File[]) => {
          this.parsedTemplate.attachment = files;
        }),
        // Формируем объект для его прокидывания в превью реплики
        map((files: File[]) => {
          const conversationPartAttachments: ConversationPartAttachment[] = files.map((file: File) => {
            return {
              id: 0,
              created: file.lastModified,
              filename: file.name,
              size: file.size,
              //@ts-ignore
              url: file.url,
              type: file.type,
              mimeType: file.type,
            };
          });

          return conversationPartAttachments;
        }),
      )
      .subscribe(
        (conversationPartAttachments: ConversationPartAttachment[]) =>
          (this.parsedTemplateAttachment = conversationPartAttachments),
      );
  }

  /**
   * Получение mime-типов в зависимости от прикрепления шаблона WhatsApp edna
   *
   * @param templateHeaderType headerType шаблона WhatsApp edna
   */
  getAcceptableMimeTypesForAttach(templateHeaderType: WhatsAppEdnaTemplateHeaderType | undefined): string {
    switch (templateHeaderType) {
      case WHATS_APP_TEMPLATE_HEADER_TYPE.DOCUMENT:
        return this.fileHelperService.getAcceptableDocsExtensions();
      case WHATS_APP_TEMPLATE_HEADER_TYPE.IMAGE:
        return this.fileHelperService.getAcceptableImagesExtensions();
      case WHATS_APP_TEMPLATE_HEADER_TYPE.VIDEO:
        return this.fileHelperService.getAcceptableVideoExtensions();
      default:
        return this.fileHelperService.getAcceptableDocsExtensions();
    }
  }

  /**
   * Получение ожидаемого типа прикрепления в зависимости от типа заголовка шаблона WhatsApp edna
   *
   * @param templateHeaderType headerType шаблона WhatsApp edna
   */
  getAttachmentType(templateHeaderType: WhatsAppEdnaTemplateHeaderType | undefined): EXPECTED_ATTACHMENT | null {
    switch (templateHeaderType) {
      case WHATS_APP_TEMPLATE_HEADER_TYPE.DOCUMENT:
        return EXPECTED_ATTACHMENT.FILE;
      case WHATS_APP_TEMPLATE_HEADER_TYPE.IMAGE:
        return EXPECTED_ATTACHMENT.IMAGE;
      case WHATS_APP_TEMPLATE_HEADER_TYPE.VIDEO:
        return EXPECTED_ATTACHMENT.VIDEO;
      default:
        return null;
    }
  }

  /**
   * Получение максимального размера прикрепления (в Кб) в зависимости от типа заголовка шаблона WhatsApp edna
   *
   * @param templateHeaderType headerType шаблона WhatsApp edna
   */
  getMaxFileSize(templateHeaderType: WhatsAppEdnaTemplateHeaderType | undefined): number {
    switch (templateHeaderType) {
      case WHATS_APP_TEMPLATE_HEADER_TYPE.DOCUMENT:
        return 10 * 1024 * 1024;
      case WHATS_APP_TEMPLATE_HEADER_TYPE.IMAGE:
        return 5 * 1024 * 1024;
      case WHATS_APP_TEMPLATE_HEADER_TYPE.VIDEO:
        return 10 * 1024 * 1024;
      default:
        return 10 * 1024 * 1024;
    }
  }

  /**
   * Получение перевода для заголовка в DND зону
   *
   * @param templateHeaderType headerType шаблона WhatsApp edna
   */
  getUploadZoneHeadingTranslate(templateHeaderType: WhatsAppEdnaTemplateHeaderType | undefined): string {
    switch (templateHeaderType) {
      case WHATS_APP_TEMPLATE_HEADER_TYPE.DOCUMENT:
        return this.translocoService.translate('sendingWhatsAppTemplate.attachmentForm.document.uploadZone.heading');
      case WHATS_APP_TEMPLATE_HEADER_TYPE.IMAGE:
        return this.translocoService.translate('sendingWhatsAppTemplate.attachmentForm.image.uploadZone.heading');
      case WHATS_APP_TEMPLATE_HEADER_TYPE.VIDEO:
        return this.translocoService.translate('sendingWhatsAppTemplate.attachmentForm.video.uploadZone.heading');
      default:
        return this.translocoService.translate('sendingWhatsAppTemplate.attachmentForm.document.uploadZone.heading');
    }
  }

  /**
   * Получение перевода для подписи в DND зону
   *
   * @param templateHeaderType headerType шаблона WhatsApp edna
   */
  getUploadZoneTextTranslate(templateHeaderType: WhatsAppEdnaTemplateHeaderType | undefined): string {
    const maxFileSize = this.getMaxFileSize(templateHeaderType) / 1024 / 1024;
    switch (templateHeaderType) {
      case WHATS_APP_TEMPLATE_HEADER_TYPE.DOCUMENT:
        return this.translocoService.translate('sendingWhatsAppTemplate.attachmentForm.document.uploadZone.text', {
          maxFileSize: maxFileSize,
        });
      case WHATS_APP_TEMPLATE_HEADER_TYPE.IMAGE:
        return this.translocoService.translate('sendingWhatsAppTemplate.attachmentForm.image.uploadZone.text', {
          maxFileSize: maxFileSize,
        });
      case WHATS_APP_TEMPLATE_HEADER_TYPE.VIDEO:
        return this.translocoService.translate('sendingWhatsAppTemplate.attachmentForm.video.uploadZone.text', {
          maxFileSize: maxFileSize,
        });
      default:
        return this.translocoService.translate('sendingWhatsAppTemplate.attachmentForm.document.uploadZone.text', {
          maxFileSize: maxFileSize,
        });
    }
  }

  /**
   * Проверка есть ли прикрепление у шаблона WhatsApp edna
   *
   * @param templateHeaderType headerType шаблона WhatsApp edna
   */
  hasAttachment(templateHeaderType: WhatsAppEdnaTemplateHeaderType | undefined): boolean {
    return (
      templateHeaderType === WHATS_APP_TEMPLATE_HEADER_TYPE.DOCUMENT ||
      templateHeaderType === WHATS_APP_TEMPLATE_HEADER_TYPE.IMAGE ||
      templateHeaderType === WHATS_APP_TEMPLATE_HEADER_TYPE.VIDEO
    );
  }

  /** Отправка шаблона */
  onSubmit(): void {
    if (this.parsedTemplate.body.length > TEMPLATE_BODY_MAX_LENGTH) {
      this.toastService.danger(
        this.translocoService.translate('sendingWhatsAppTemplate.maxLengthErrorToast', {
          maxLength: TEMPLATE_BODY_MAX_LENGTH,
        }),
      );

      return;
    }

    if (this.templateForm.valid) {
      this.activeModal.close(this.parsedTemplate);
    } else {
      this.templateForm.markAllAsTouched();
    }
  }

  /**
   * Замена переменных в теле шаблона
   *
   * @param value Новое значение переменной
   * @param index Индекс переменной
   * @param body Тело шаблона, в котором происходит замена
   * @param templateVariablesArray Массив переменных
   * @param withTags Нужно ли оборачивать заменяемую переменную HTML тегами для подсветки
   * @private
   */
  private templateBodyReplacer(
    value: string,
    index: number,
    body: string,
    templateVariablesArray: RegExpMatchArray,
    withTags: boolean = false,
  ): string {
    return body.replace(templateVariablesArray[index], (match) => {
      if (withTags) {
        return !!value
          ? `<span class="bg-light-primary">${value}</span>`
          : `<span class="bg-light-primary">${match}</span>`;
      }

      return !!value ? value : match;
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationService } from '@http/integration/services/integration.service';
import { AlbatoAuthTokenModule } from '@panel/app/pages/integrations/content/albato/auth-token/albato-auth-token.module';
import { InputCopyButtonModule } from '@panel/app/partials/input-copy-button/input-copy-button.module';

import { AlbatoSettingsComponent } from './albato-settings.component';

@NgModule({
  declarations: [AlbatoSettingsComponent],
  exports: [AlbatoSettingsComponent],
  imports: [AlbatoAuthTokenModule, CommonModule, InputCopyButtonModule, TranslocoModule],
  providers: [IntegrationService],
})
export class AlbatoSettingsModule {}

(function () {
  'use strict';

  angular
    .module('myApp.starterGuide')
    .controller('CqStarterGuideWriteSelfController', CqStarterGuideWriteSelfController);

  function CqStarterGuideWriteSelfController() {
    let vm = this;

    vm.$onInit = init;

    function init() {}
  }
})();

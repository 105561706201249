import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';

@Component({
  selector: 'cq-articles-limitation-banner',
  templateUrl: './articles-limitation-banner.component.html',
  styleUrls: ['./articles-limitation-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlesLimitationBannerComponent implements OnInit {
  /** информация о биллинге */
  @Input() billingInfo!: any;
  /** Текущий App */
  @Input() currentApp!: any;

  protected accessToKnowledgeBasePro: any;

  constructor(
    protected readonly paywallService: PaywallService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
  ) {}

  ngOnInit(): void {
    this.accessToKnowledgeBasePro = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.KNOWLEDGE_BASE_PRO,
      this.currentApp,
    );
  }

  /**
   * Показ пейвола
   */
  showPaywall(): void {
    this.paywallService.showPaywall(this.currentApp, this.accessToKnowledgeBasePro.denialReason);
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PROMPT_MODAL_DATA_TOKEN, PromptModalData } from '@panel/app/shared/modals/prompt/prompt-modal.token';

@Component({
  selector: 'cq-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptModalComponent {
  control!: FormControl<string>;

  cancelButtonClass!: string;
  cancelButtonText!: string;
  confirmButtonClass!: string;
  confirmButtonText!: string;
  inputErrorText!: string;
  inputMaxLengthErrorText!: string;

  constructor(
    public activeModal: NgbActiveModal,
    @Inject(PROMPT_MODAL_DATA_TOKEN)
    private readonly data: PromptModalData,
    private readonly translocoService: TranslocoService,
  ) {
    this.control = new FormControl(this.data.text ?? '', {
      nonNullable: true,
      validators: [Validators.required, ...this.getExtraValidators()],
    });

    this.cancelButtonClass = this.data.cancelButtonClass ?? 'btn-outline-primary';
    this.cancelButtonText = this.data.cancelButtonText ?? this.translocoService.translate('general.cancel');
    this.confirmButtonClass = this.data.confirmButtonClass ?? 'btn-primary';
    this.confirmButtonText =
      this.data.confirmButtonText ?? this.translocoService.translate('modals.prompt.confirmButtonText');
    this.inputErrorText = this.data.inputErrorText ?? this.translocoService.translate('modals.prompt.inputErrorText');
    this.inputMaxLengthErrorText =
      this.data.inputMaxLengthErrorText ?? this.translocoService.translate('modals.prompt.inputMaxLengthErrorText');
  }

  get body() {
    return this.data.body;
  }

  get heading() {
    return this.data.heading;
  }

  get inputLabel() {
    return this.data.inputLabel;
  }

  get inputMaxLength() {
    return this.data.inputMaxLength ?? 0;
  }

  get inputPlaceholder() {
    return this.data.inputPlaceholder;
  }

  get text() {
    return this.data.text;
  }

  onSubmit(): void {
    if (this.control.valid) {
      this.activeModal.close(this.control.value);
    }
  }

  private getExtraValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (this.data.inputMaxLength) {
      validators.push(Validators.maxLength(this.data.inputMaxLength));
    }
    return validators;
  }
}

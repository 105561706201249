import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { MessageEditorTriggerSelectComponent } from '@panel/app/partials/message-editor/trigger/trigger-select/message-editor-trigger-select.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [MessageEditorTriggerSelectComponent],
  exports: [MessageEditorTriggerSelectComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, SharedModule, AccessDenialPopoverModule],
})
export class MessageEditorTriggerSelectModule {}

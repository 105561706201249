(function () {
  'use strict';

  angular.module('myApp.integrations').controller('FacebookController', FacebookController);

  function FacebookController(
    $filter,
    $location,
    $q,
    $scope,
    $state,
    $translate,
    $uibModal,
    $window,
    toastr,
    FACEBOOK_INTEGRATION_STEPS,
    PROJECT_NAME,
    integrationModel,
    l10nHelper,
    utilsModel,
    validationHelper,
    wizardHelper,
    currentApp,
    integration,
  ) {
    let vm = this;

    vm.$translate = $translate;
    vm.authorize = authorize;
    vm.checkOAuth = checkOAuth;
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.currentStep = angular.isDefined(integration.settings.lastCompletedStep)
      ? getStepByIndex(FACEBOOK_INTEGRATION_STEPS, integration.settings.lastCompletedStep + 1)
      : getStepByIndex(FACEBOOK_INTEGRATION_STEPS, 0); // текущий шаг визарда
    vm.FACEBOOK_INTEGRATION_STEPS = FACEBOOK_INTEGRATION_STEPS;
    vm.integration = integration;
    vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
      ? vm.currentStep.ORDER > integration.settings.lastCompletedStep
      : angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.oAuthErrors = {
      required: false,
      auth: false,
    };
    vm.openConfirmUnsubscribedModal = openConfirmUnsubscribedModal;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.updateUserPages = updateUserPages;
    vm.validationHelper = validationHelper;
    vm.wizard = null; // инстанс визарда

    init();

    function init() {
      // пока Facebook заблокирован в РФ - будем принудительно показывать предупреждение
      if (l10nHelper.isRusCountry()) {
        integration.warnings = integration.warnings || [];
        integration.warnings.push('blocking');
      }

      $scope.$watch('vm.currentStep', function (newValue) {
        //обновляем состояние инструкции в зависимости от текущего шага
        vm.isManualExpanded = angular.isDefined(integration.settings.lastCompletedStep)
          ? newValue.ORDER > integration.settings.lastCompletedStep
          : angular.isUndefined(integration.id);
      });

      wizardHelper.getWizard().then(getWizardSuccess);

      function getWizardSuccess(wizard) {
        vm.wizard = wizard;
      }
    }

    /**
     * Авторизация в Facebook
     */
    function authorize() {
      var w = 350;
      var h = 250;

      var wLeft = $window.screenLeft ? $window.screenLeft : $window.screenX;
      var wTop = $window.screenTop ? $window.screenTop : $window.screenY;

      var left = wLeft + $window.innerWidth / 2 - w / 2;
      var top = wTop + $window.innerHeight / 2 - h / 2;

      // чтобы всплывающее окно корректно открылось - его вначале надо открыть без url, а затем, когда url получен с сервера - заменить location.href на этот url
      var authWindow = $window.open(
        '',
        '_blank',
        'toolbar=0, menubar=0,height=' + h + ',width=' + w + ',top=' + top + ',left=' + left,
      );

      integrationModel.facebook.getOAuthUrl(currentApp.id, integration.id).then(function (url) {
        authWindow.location.href = url;
      });

      $window.addEventListener('message', messageListener, false);

      /**
       * Удаление слушателя сообщений из дочернего окна
       */
      function destroyMessageListener() {
        $window.removeEventListener('message', messageListener);
      }

      function messageListener(message) {
        $scope.$apply(function () {
          var origin = $('<a>', { href: message.origin })[0];

          if (origin.hostname === $location.host()) {
            if (message.data.status === 'accepted') {
              vm.oAuthErrors.auth = false;
              vm.oAuthErrors.required = false;
            } else if (message.data.status === 'error') {
              vm.oAuthErrors.auth = true;
            }
          }

          authWindow.close();
          destroyMessageListener();
          vm.wizard.nextStep();
        });
      }
    }

    /**
     * Проверяет авторизацию в Facebook, и если человек авторизован - делает запрос на получение страниц
     *
     * @return {Promise}
     */
    function checkOAuth() {
      if (!(vm.oAuthErrors.required || vm.oAuthErrors.auth)) {
        if (integration.settings.accessToken) {
          // если accessToken присутствует - запрашиваем актуальные страницы и переходим на следующий шаг
          return getUserPages(integration).then(getUserPagesSuccess);
        } else {
          // если accessToken отсутствует - проверяем его наличие в самой актуальной интеграции, и если он там есть - запрашиваем страницы Facebook
          return integrationModel.get(integration.id, integration.type).then(getSuccess).then(getUserPagesSuccess);
        }
      } else {
        return $q.reject();
      }

      function getSuccess(refreshedIntegration) {
        angular.extend(integration, refreshedIntegration);

        if (!integration.settings.accessToken) {
          vm.oAuthErrors.required = true;
          return $q.reject();
        } else {
          return getUserPages(integration);
        }
      }

      function getUserPagesSuccess() {
        createOrUpdateIntegration();
      }
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {form.FormController=} form Контроллер формы
     */
    function createOrUpdateIntegration(form) {
      if (form) {
        form.$commitViewValue();
        form.$setSubmitted();

        if (form.$invalid) {
          return $q.reject();
        }
      }

      integration.settings.lastCompletedStep =
        integration.settings.lastCompletedStep > vm.currentStep.ORDER
          ? integration.settings.lastCompletedStep
          : vm.currentStep.ORDER;

      if (integration.id) {
        return integrationModel.save(currentApp.id, integration).then(saveSuccess);
      } else {
        return integrationModel.create(currentApp.id, integration).then(createSuccess);
      }

      function saveSuccess(integration) {}

      function createSuccess(integration) {
        var params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        var transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
      }
    }

    /**
     * Получение шага из массива/объекта шагов steps по индексу index
     *
     * @param {Object|Array.<Object>} steps Массив/объект шагов, из которых осуществялется выборка
     * @param {Number} index Индекс шага (шаги упорядочены по ORDER, т.е. этот индекс и является ORDER)
     * @return {Object}
     */
    function getStepByIndex(steps, index) {
      var stepsArray = $filter('toArray')(steps);

      // если номер искомого шага больше, чем количество всех шагов - полагаем, что надо найти последний шаг
      index = index > stepsArray.length - 1 ? stepsArray.length - 1 : index;

      var step = $filter('filter')(stepsArray, { ORDER: index }, true)[0];
      if (step) {
        return step;
      } else {
        return $filter('filter')(stepsArray, { ORDER: 0 }, true)[0];
      }
    }

    /**
     * Получение страниц пользователя для интеграции с Facebook
     *
     * @param {Object} integration Интеграция
     * @return {Promise}
     */
    function getUserPages(integration) {
      return integrationModel.facebook.getUserPages(integration.id).then(getUserPagesSuccess);

      function getUserPagesSuccess(pages) {
        integration.settings.pages = pages;
      }
    }

    /**
     * Модальное окно при отключении страницы (информирует, что страница будет убрана из всех каналов)
     *
     * @param {Object} page Страница, которую отписываем
     */
    function openConfirmUnsubscribedModal(page) {
      if (!page.subscribed) {
        var modalInstance = $uibModal.open({
          controller: 'ConfirmModalController',
          controllerAs: 'vm',
          templateUrl: 'js/shared/modals/confirm/confirm.html',
          resolve: {
            modalWindowParams: function () {
              return {
                heading: $translate.instant('integrations.facebook.step3.unsubscribeConfirmationWindow.heading'),
                body: $translate.instant('integrations.facebook.step3.unsubscribeConfirmationWindow.body'),
                confirmButtonText: $translate.instant(
                  'integrations.facebook.step3.unsubscribeConfirmationWindow.confirmButton',
                ),
              };
            },
          },
        });

        modalInstance.result.catch(openModalError);
      }

      function openModalError() {
        page.subscribed = true;
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }

    /**
     * Обновление состояний пользовательских страниц страниц
     */
    function updateUserPages() {
      integrationModel.facebook.subscribe(integration).then(subscribeSuccess);

      function subscribeSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }
  }
})();

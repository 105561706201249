<ng-container *transloco="let t">
  <div class="card align-items-center">
    <cq-telegram
      #telegramComponent
      class="card-body"
      [currentAppId]="currentApp.id"
      [integrationExternal]="integrationExternal"
    ></cq-telegram>
  </div>
  <div class="text-right">
    <button
      class="btn btn-primary"
      [attr.disabled]="telegramComponent.isRequestPerformed ? true : null"
      data-test="create-integration-button"
      type="button"
      (click)="telegramComponent.onSubmit()"
    >
      {{ t('models.integration.actions.save') }}
    </button>
  </div>
</ng-container>

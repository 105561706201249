import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AutoMessageControlGroupComponent } from './auto-message-control-group.component';

@NgModule({
  declarations: [AutoMessageControlGroupComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FormsModule,
    AccessDenialPopoverModule,
    SharedModule,
    NgbTooltipModule,
    ReactiveFormsModule,
  ],
})
export class AutoMessageControlGroupModule {}

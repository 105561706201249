<ng-container *transloco="let t">
  <div
    *ngIf="!(isPartOfInterruptScenario && !allowUserReplies)"
    class="full-height d-flex flex-column"
  >
    <div class="padding-20 bordered-bottom">
      <div class="d-flex align-items-center">
        <cq-branch-name-editor
          [formNameControl]="branch.name"
          [isFullscreenEditorEnabled]="fullscreen"
          (blockDelete)="onBranchDelete()"
        ></cq-branch-name-editor>
      </div>
    </div>
    <overlay-scrollbars
      class="d-flex flex-grow-1 bg-white"
      [hidden]="showLoader"
    >
      <div class="padding-20">
        <ng-container [ngSwitch]="branch.blockType">
          <!--Стандарный блок-->
          <ng-container *ngSwitchCase="'branch'">
            <ng-container *ngTemplateOutlet="branchEditor"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'meeting'">
            <ng-container *ngTemplateOutlet="branchEditor"></ng-container>
          </ng-container>
          <ng-template #branchEditor>
            <cq-block-branch-editor
              [actionGroups]="actionGroups"
              [actionsToRender]="actionsToRender"
              (botActionCreate)="createBotAction($event, branch)"
              [branch]="branch"
              [buttonPropertyCheckboxControl]="buttonPropertyCheckboxControl"
              [changedControlToButton]="changedControlToButton$"
              [changeTargetAction]="changeTargetAction.bind(this)"
              [chatBotType]="chatBot.type"
              [chatBotId]="chatBot.id ?? null"
              (checkboxValueChange)="changeCheckboxValue($event)"
              [currentApp]="currentApp"
              [getActionHeaderIndex]="getActionHeaderIndex.bind(this)"
              [getCallbacks]="getCallbacks.bind(this)"
              [isButtonDisabled]="isButtonDisabled.bind(this)"
              (newButtonCreate)="onCreateNewButton()"
              [nextBranchOptions]="nextBranchOptions"
              [noticeOfTargetAction]="noticeOfTargetAction"
              [properties]="properties"
            ></cq-block-branch-editor>
          </ng-template>

          <!--Условия-->
          <ng-container *ngSwitchCase="'condition'">
            <cq-app-online-condition-editor
              [branch]="branch"
              [callbacks]="getConditionsBLockCallbacks()"
              [currentApp]="currentApp"
              [chatBotType]="chatBot.type"
              [chatBotId]="chatBot.id ?? null"
              [nextBranchOptions]="nextBranchOptions"
            ></cq-app-online-condition-editor>
          </ng-container>

          <!--Блок действий -->
          <ng-container *ngSwitchCase="'action'">
            <cq-block-action-editor
              [actionsToRender]="actionsToRender"
              (botActionCreate)="createBotAction($event, branch)"
              [branch]="branch"
              [callbacks]="getConditionsBLockCallbacks()"
              [chatBotType]="chatBot.type"
              [chatBotId]="chatBot.id ?? null"
              [currentApp]="currentApp"
              [getActionHeaderIndex]="getActionHeaderIndex.bind(this)"
              [getCallbacks]="getCallbacks.bind(this)"
              [isButtonDisabled]="isButtonDisabled.bind(this)"
              [nextBranchOptions]="nextBranchOptions"
              [properties]="properties"
            ></cq-block-action-editor>
          </ng-container>
        </ng-container>
      </div>
    </overlay-scrollbars>

    <!-- Loader -->
    <div
      [hidden]="!showLoader"
      class="d-flex flex-grow-1 position-relative"
    >
      <cq-loader></cq-loader>
    </div>
  </div>

  <!--Zero data для отображения в блоке прерывания с выключенной опцией прерывания-->
  <div
    *ngIf="isPartOfInterruptScenario && !allowUserReplies"
    class="d-flex flex-column full-height"
  >
    <div class="padding-20 bordered-bottom">
      <div class="d-flex align-items-center">
        <h3 class="flex-grow-1 no-margin-bottom mr-5 pt-5 pb-5 interrupt-block-disabled-heading">
          {{ branch.form.controls.name.value }}
        </h3>
      </div>
    </div>
    <div class="align-items-center d-flex flex-column flex-grow-1 justify-center padding-20 bg-white position-relative">
      <img src="assets/img/default/chat-bots/edit/zero-data-interrupt.svg" />
      <h4 class="margin-top-20">{{ t('zeroData.interrupt.heading') }}</h4>
      <div
        class="text-muted text-center"
        [innerHTML]="t('zeroData.interrupt.description')"
      ></div>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { AmocrmLeadTag } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { Message } from '@http/message/message.types';

/**
 * Компонент для вывода триггерных сообщений, при ответе на которые срабатывает интеграция
 */

@Component({
  selector: 'cq-integration-trigger-messages[integrationType][triggerMessages]',
  templateUrl: './integration-trigger-messages.component.html',
  styleUrls: ['./integration-trigger-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationTriggerMessagesComponent {
  /** Тип интеграции */
  @Input()
  integrationType!: INTEGRATION_TYPES;

  /** Список триггерных сообщений */
  @Input()
  triggerMessages: readonly Partial<Message>[] = [];

  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;

  /** Является ли список триггерных сообщений пустым */
  get isEmptyTriggerMessages(): boolean {
    return this.triggerMessages.length === 0;
  }

  /**
   * Получение иконки для типа сообщения
   *
   * !!!
   *  Это говно делалось на сверхзвуковой скорости дабы успеть до схлапывания чёрной дыры релиза,
   *  поэтому когда будет переписывать модель триггерных сообщений с JS на Angular, заменить это на константы.
   *
   * @param messageType - Тип сообщения
   */
  getIconForTriggerMessage(messageType: string): string {
    switch (messageType) {
      case 'block_popup_big':
        return 'cqi-block-popup-big';
      case 'block_popup_small':
        return 'cqi-block-popup-small';
      case 'email':
        return 'cqi-envelope-o';
      case 'popup_big':
        return 'cqi-popup-big-o';
      case 'popup_chat':
        return 'cqi-comment-out-о';
      case 'popup_small':
        return 'cqi-popup-small';
      case 'push':
        return 'cqi-web-push';
      case 'sdk_block_popup_small':
        return 'cqi-mobile-block-popup';
      case 'sdk_popup_chat':
        return 'cqi-mobile-comment-out';
      case 'sdk_push':
        return 'cqi-mobile-push';
      case 'webhook':
        return 'cqi-webhook';
      default:
        return '';
    }
  }

  /**
   * Получение всех тегов сделки amoCRM, которые настроены в триггерном сообщении
   *
   * @param triggerMessage Триггерное сообщение
   */
  getTriggerMessageAmocrmLeadTags(triggerMessage: Partial<Message>): AmocrmLeadTag[] {
    return triggerMessage.notificationIntegrations[0]?.messageConfig.tags ?? [];
  }

  /**
   * Проверка наличия тегов сделки amoCRM в триггерном сообщении
   *
   * @param triggerMessage Триггерное сообщение
   */
  isTriggerMessageAmocrmLeadTagsNotEmpty(triggerMessage: Partial<Message>): boolean {
    return this.getTriggerMessageAmocrmLeadTags(triggerMessage).length > 0;
  }
}

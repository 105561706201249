import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { tuiPure } from '@taiga-ui/cdk';

import { App } from '@http/app/app.model';
import {
  ACTIONS_GROUPS,
  CHAT_BOT_ACTIONS_TYPES,
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP,
} from '@http/chat-bot/chat-bot.constants';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import {
  BranchConditionCallbacks,
  BranchContainerCallbacks,
} from '@panel/app/pages/chat-bot/content/branch-editor/branch-editor.component';
import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { Properties } from '@http/property/property.model';

@Component({
  selector:
    'cq-block-action-editor[branch][callbacks][currentApp][actionsToRender][nextBranchOptions][isButtonDisabled][properties][chatBotType][getActionHeaderIndex][getCallbacks]',
  templateUrl: './block-action-editor.component.html',
  styleUrls: ['./block-action-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockActionEditorComponent {
  @Input()
  branch!: Branch;
  @Input()
  callbacks!: BranchConditionCallbacks;
  @Input()
  currentApp!: App;
  @Input()
  actionsToRender!: BotAction[];
  @Input()
  nextBranchOptions!: Branch[];
  @Input()
  isButtonDisabled!: (actionType: CHAT_BOT_ACTIONS_TYPES) => boolean;
  @Input()
  properties!: Properties;

  @Input()
  chatBotType: CHAT_BOT_TYPE | null = null;

  @Input()
  chatBotId: string | null = null;

  @Input()
  getActionHeaderIndex!: (actionType: CHAT_BOT_ACTIONS_TYPES, index: number) => number;
  @Input()
  getCallbacks!: (actionIndex: number, group: ACTIONS_GROUPS) => BranchContainerCallbacks;

  @Output()
  botActionCreate: EventEmitter<CHAT_BOT_ACTIONS_TYPES> = new EventEmitter<CHAT_BOT_ACTIONS_TYPES>();

  ACTIONS_GROUPS = ACTIONS_GROUPS;
  ACTION_TYPE = CHAT_BOT_ACTIONS_TYPES;
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP = CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP;

  actionsCollapse: { [key: string]: boolean } = {};

  @tuiPure
  getNextAction(branch: Branch): BotAction {
    const action = branch.actions.find((a) => a.type === CHAT_BOT_ACTIONS_TYPES.NEXT);
    if (!action) {
      throw new Error(`Couldn't find action`);
    }
    return action;
  }

  /**
   * Получение действий по группе
   */
  public getRenderContentActions(): BotAction[] {
    return this.actionsToRender.filter((action) => {
      return this.CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[ACTIONS_GROUPS.CONTENT].includes(action.type);
    });
  }

  /**
   * Трекинг по LinkId
   * @param index
   * @param action
   */
  trackByActionLinkId(index: number, action: BotAction): string {
    return action.linkId;
  }
}

import { ChannelNotificationSetting } from '@http/channel/channel.model';
import { ApiPatchAppMutedChannelsRequest } from '@http/channel/channel.types';
import {
  ApiNotificationSettingsRequest,
  ApiNotificationSettingsResponse,
  DjangoUserNotificationSettingsExternal,
  NotificationAppSettings,
  NotificationSettings,
} from '@panel/app/pages/account/shared/notification-settings/types';

export class NotificationSettingsMapper {
  static toInternalFormat(externalNotifications: ApiNotificationSettingsResponse): NotificationSettings {
    const internalSettings: NotificationSettings = {
      general: {
        enableNotificationSound: false,
        repeatSoundNotification: false,
        keepDesktopNotificationOpened: false,
      },
      apps: [],
    };

    if (externalNotifications.desktop_sound) {
      internalSettings.general.enableNotificationSound = externalNotifications.desktop_sound;
    }

    if (externalNotifications.desktop_infinite_sound) {
      internalSettings.general.repeatSoundNotification = externalNotifications.desktop_infinite_sound;
    }

    if (externalNotifications.desktop_notification_opened) {
      internalSettings.general.keepDesktopNotificationOpened = externalNotifications.desktop_notification_opened;
    }

    Object.keys(externalNotifications).forEach((key) => {
      switch (true) {
        case Number.isNaN(Number.parseInt(key)):
          break;
        default:
          internalSettings.apps.push({
            appId: key,
            settings: this.djangoUserAppSettingsToInternal(externalNotifications[key]),
          });
      }
    });

    return internalSettings;
  }

  /**
   * @param settings настройки в локальном формате
   * @param appsWithAccess список аппов, к которым у клиента есть доступ
   * @param mutedChannels список заглушенных каналов
   */
  static toExternalRequestFormat(
    settings: NotificationSettings,
    appsWithAccess: string[],
    mutedChannels: { app: string; mutedChannels: ChannelNotificationSetting[] }[],
  ): ApiNotificationSettingsRequest {
    const prefs: Record<string, boolean> = {
      desktop_sound: settings.general.enableNotificationSound,
      desktop_infinite_sound: settings.general.repeatSoundNotification,
      desktop_notification_opened: settings.general.keepDesktopNotificationOpened,
    };

    settings.apps.forEach((appSettings) => {
      if (!appsWithAccess.includes(appSettings.appId)) {
        return;
      }

      prefs[`notification_stats__${appSettings.appId}`] = appSettings.settings.stats.email;
      prefs[`notification_email_assigned_me__${appSettings.appId}`] = appSettings.settings.assignedMe.email;
      prefs[`notification_push_assigned_me__${appSettings.appId}`] = appSettings.settings.assignedMe.push;
      prefs[`notification_desktop_assigned_me__${appSettings.appId}`] = appSettings.settings.assignedMe.desktop;
      prefs[`notification_email_assigned_nobody__${appSettings.appId}`] = appSettings.settings.assignedNoOne.email;
      prefs[`notification_push_assigned_nobody__${appSettings.appId}`] = appSettings.settings.assignedNoOne.push;
      prefs[`notification_desktop_assigned_nobody__${appSettings.appId}`] = appSettings.settings.assignedNoOne.desktop;
      prefs[`notification_email_assigned_other_admin__${appSettings.appId}`] =
        appSettings.settings.assignedOtherAdmin.email;
      prefs[`notification_push_assigned_other_admin__${appSettings.appId}`] =
        appSettings.settings.assignedOtherAdmin.push;
      prefs[`notification_desktop_assigned_other_admin__${appSettings.appId}`] =
        appSettings.settings.assignedOtherAdmin.desktop;
    });

    const django_user_settings: ApiPatchAppMutedChannelsRequest[] = mutedChannels.map((ch) => {
      return {
        app: ch.app,
        muted_channels: ch.mutedChannels.map((channel) => channel.id),
      };
    });

    return { prefs: JSON.stringify(prefs), django_user_settings };
  }

  /**
   * Метод нужен только для того, чтоб после выполнения запроса обновить локально в старом ангуляре данные
   * @param settings
   */
  static toExternalResponseFormat(settings: NotificationSettings): ApiNotificationSettingsResponse {
    //@ts-ignore из-за того что результирующий тип тупой тут немного ломается типизация
    const result: NotificationSettingsExternal = {
      desktop_sound: settings.general.enableNotificationSound,
      desktop_infinite_sound: settings.general.repeatSoundNotification,
      desktop_notification_opened: settings.general.keepDesktopNotificationOpened,
    };

    settings.apps.forEach((appSettings) => {
      result[appSettings.appId] = {
        notification_stats: appSettings.settings.stats.email,
        notification_email_assigned_me: appSettings.settings.assignedMe.email,
        notification_push_assigned_me: appSettings.settings.assignedMe.push,
        notification_desktop_assigned_me: appSettings.settings.assignedMe.desktop,
        notification_email_assigned_nobody: appSettings.settings.assignedNoOne.email,
        notification_push_assigned_nobody: appSettings.settings.assignedNoOne.push,
        notification_desktop_assigned_nobody: appSettings.settings.assignedNoOne.desktop,
        notification_email_assigned_other_admin: appSettings.settings.assignedOtherAdmin.email,
        notification_push_assigned_other_admin: appSettings.settings.assignedOtherAdmin.push,
        notification_desktop_assigned_other_admin: appSettings.settings.assignedOtherAdmin.desktop,
      };
    });

    return result;
  }

  private static djangoUserAppSettingsToInternal(
    settings: DjangoUserNotificationSettingsExternal,
  ): NotificationAppSettings {
    return {
      stats: {
        email: settings.notification_stats,
      },
      assignedMe: {
        email: settings.notification_email_assigned_me,
        push: settings.notification_push_assigned_me,
        desktop: settings.notification_desktop_assigned_me,
      },
      assignedNoOne: {
        email: settings.notification_email_assigned_nobody,
        push: settings.notification_push_assigned_nobody,
        desktop: settings.notification_desktop_assigned_nobody,
      },
      assignedOtherAdmin: {
        email: settings.notification_email_assigned_other_admin,
        push: settings.notification_push_assigned_other_admin,
        desktop: settings.notification_desktop_assigned_other_admin,
      },
    };
  }
}

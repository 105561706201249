import { AbstractControl, ValidationErrors } from '@angular/forms';

export function domainPartValidator(control: AbstractControl): ValidationErrors | null {
  const DOMAIN_PART_REGEXP = /^([a-z\d][a-z\d-]*[a-z\d]|[a-z\d])$/;

  if (control.value === null || control.value === '') {
    return null;
  }

  // Валидация домена n-ого уровня
  const valid = DOMAIN_PART_REGEXP.test(control.value);

  return valid ? null : { domainPart: true };
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { AceModule } from 'ngx-ace-wrapper';

import { RequestBodyEditorComponent } from './request-body-editor.component';

@NgModule({
  declarations: [RequestBodyEditorComponent],
  exports: [RequestBodyEditorComponent],
  imports: [AceModule, CommonModule, TranslocoModule],
})
export class RequestBodyEditorModule {}

<ng-container *transloco="let t; read: 'singleUrlFilterConfiguratorComponent'">
  <ng-container *ngIf="wide$ | async; else wideFilterConfigurator">
    <div
      [formGroup]="formGroup"
      class="flex flex-nowrap align-items-center margin-top-10"
    >
      <div class="filter-type-selector margin-right-10">
        <ng-container *ngTemplateOutlet="filterTypeSelectorTmpl; context: { filterControl: formGroup }"></ng-container>
      </div>
      <div
        class="form-group has-feedback flex-grow no-margin-bottom"
        [cqShowError]="formGroup.get('match')"
      >
        <ng-container *ngTemplateOutlet="filterMatchInputTmpl; context: { filterControl: formGroup }"></ng-container>
      </div>
      <span *ngIf="!isOnlyFilter">
        <ng-container *ngTemplateOutlet="dropFilterButtonTmpl"></ng-container>
      </span>
    </div>
  </ng-container>

  <ng-template #wideFilterConfigurator>
    <div class="margin-top-10 margin-between-rows-10">
      <div class="text-muted small">
        {{ t('filtersCounter', { number: index + 1 }) }}
      </div>
      <div class="flex">
        <div class="form-group flex-grow no-margin-bottom">
          <ng-container
            *ngTemplateOutlet="filterTypeSelectorTmpl; context: { filterControl: formGroup }"
          ></ng-container>
        </div>
        <ng-container *ngIf="!isOnlyFilter">
          <ng-container *ngTemplateOutlet="dropFilterButtonTmpl"></ng-container>
        </ng-container>
      </div>
      <div
        class="form-group has-feedback no-margin-bottom"
        [cqShowError]="formGroup.get('match')"
      >
        <ng-container *ngTemplateOutlet="filterMatchInputTmpl; context: { filterControl: formGroup }"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- Поповер для онбординга -->
  <ng-template
    #urlEqualFilterPopover
    let-url="url"
  >
    <div>
      <div class="margin-bottom-10 font-weight-bold">
        {{ t('urlEqualFilterPopover.heading') }}
      </div>
      <div class="margin-bottom-10">{{ t('urlEqualFilterPopover.text1', { url: url }) }}</div>
      <div class="margin-bottom-10">{{ t('urlEqualFilterPopover.text2') }}</div>
      <div class="flex">
        <button
          class="btn btn-secondary"
          type="button"
          (click)="changeFilterTypeToMatchedWithParams()"
        >
          {{ t('urlEqualFilterPopover.toggleOptionButton') }}
        </button>
        <button
          class="btn btn-outline-primary margin-left-10"
          type="button"
          (click)="closePopover()"
        >
          {{ t('urlEqualFilterPopover.cancelButton') }}
        </button>
      </div>
    </div>
  </ng-template>

  <!-- Селектор выбора фильтра -->
  <ng-template
    #filterTypeSelectorTmpl
    let-filterControl="filterControl"
  >
    <ng-select
      [formControl]="filterControl.get('type')"
      [searchable]="false"
      [clearable]="false"
      (change)="onFilterTypeChange($event, filterControl.get('match')!)"
      [ngbPopover]="urlEqualFilterPopover"
      triggers="manual"
      container="body"
      popoverClass="dark-theme url-filter-onboarding-popover"
      [autoClose]="false"
    >
      <ng-template ng-label-tmp>
        {{ t('selectorOptions.' + filterControl.get('type')!.value + '.title') }}
      </ng-template>

      <ng-option
        *ngFor="let option of filterOptions"
        [value]="option"
      >
        <div>{{ t('selectorOptions.' + option + '.title') }}</div>
        <div class="small text-muted text-wrap">
          {{ t('selectorOptions.' + option + '.description') }}
        </div>
      </ng-option>
    </ng-select>
  </ng-template>

  <!-- Поле ввода фильтра -->
  <ng-template
    #filterMatchInputTmpl
    let-filterControl="filterControl"
  >
    <input
      class="form-control"
      [formControl]="filterControl.get('match')"
      placeholder="{{ t(formGroup.controls.type.value + 'Input.placeholder') }}"
    />
    <span class="form-control-feedback auto-pointer-events">
      <i
        class="cqi-sm cqi-question-circle"
        ngbTooltip="{{ t(formGroup.controls.type.value + 'Input.tooltip') }}"
        container="body"
        placement="top"
        tooltipClass="url-filter-tooltip"
      ></i>
    </span>

    <cq-validation-messages [control]="filterControl.get('match')">
      <cq-validation-message errorName="required">{{
        t(formGroup.controls.type.value + 'Input.errors.required')
      }}</cq-validation-message>
      <cq-validation-message errorName="minlength">
        {{ t(formGroup.controls.type.value + 'Input.errors.minlength', { minLength: URL_MIN_LENGTH }) }}
      </cq-validation-message>
      <cq-validation-message errorName="invalidUrl">
        <span *ngIf="isFullOrPartialMatch(filterControl.get('type')!.value)">
          {{ t(formGroup.controls.type.value + 'Input.errors.url') }}
        </span>
      </cq-validation-message>
      <cq-validation-message errorName="invalidUrlWithParams">
        <span *ngIf="isMatchWithParams(filterControl.get('type')!.value)">
          {{ t(formGroup.controls.type.value + 'Input.errors.urlWithParams') }}
        </span>
      </cq-validation-message>
    </cq-validation-messages>
  </ng-template>

  <!-- Кнопка удаления фильтра -->
  <ng-template #dropFilterButtonTmpl>
    <button
      class="btn btn-outline-primary margin-left-10"
      type="button"
      (click)="dropFilter()"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </ng-template>
</ng-container>

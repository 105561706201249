/**
 * Контроллер для настроек приложения
 */
import { SETTINGS_TABS } from './settings.constants';
import { FEATURES } from '../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.settings').controller('CqSettingsController', CqSettingsController);

  function CqSettingsController($filter, $scope, $stateParams, l10nHelper, carrotquestHelper, featureModel) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      trackEnterOnPage();

      vm.currentTab = SETTINGS_TABS.GENERAL_SETTINGS; // текущая вкладка
      vm.defaultMessageSender = $filter('filter')(vm.messageSenders, { isDefault: true }, true)[0]; // отправитель писем по умолчанию
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.l10nHelper = l10nHelper;
      vm.SETTINGS_TABS = SETTINGS_TABS;

      $scope.$watch('vm.currentTab', tabChanged);
      $scope.$watch('vm.messageSenders', messageSendersChanged, true);

      if ($stateParams.tab) {
        var tab = $filter('filter')($filter('toArray')(SETTINGS_TABS), $stateParams.tab, true)[0];
        vm.currentTab = tab || vm.currentTab;
      }

      /**
       * Коллбэк на смену вкладки
       *
       * @param newValue
       * @param oldValue
       */
      function tabChanged(newValue, oldValue) {
        if (newValue != oldValue) {
          if (newValue == SETTINGS_TABS.PRIVACY_SETTINGS) {
            trackEnterOnPrivacySettings();
          }
        }
      }

      /**
       * При изменении отправителей нужно получить нового отправителя по умолчанию
       *
       * @param newValue
       * @param oldValue
       */
      function messageSendersChanged(newValue, oldValue) {
        vm.defaultMessageSender = $filter('filter')(newValue, { isDefault: true }, true)[0];
      }
    }

    /**
     * Трек захода на страницу настроек
     */
    function trackEnterOnPage() {
      carrotquestHelper.track('Зашел на страницу настроек', { App: vm.currentApp.name });
    }

    /**
     * Трек захода на вкладку 'Настройки обработки персональных данных'
     */
    function trackEnterOnPrivacySettings() {
      carrotquestHelper.track('Настройки обработки персональных данных - Зашел', { App: vm.currentApp.name });
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { NewYearCertificatePayButtonComponent } from './new-year-certificate-pay-button.component';

@NgModule({
  declarations: [NewYearCertificatePayButtonComponent],
  exports: [NewYearCertificatePayButtonComponent],
  imports: [CommonModule, TranslocoModule],
})
export class NewYearCertificatePayButtonModule {}

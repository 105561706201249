import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.chatBot.routingBot.statistics').config(config);

  function config($stateProvider) {
    $stateProvider.state('app.content.messagesAjs.routingBot.statistics', {
      url: '/{routingBotId:id}',
      bindings: {
        routingBot: 'routingBot',
        currentApp: 'currentApp',
        djangoUser: 'djangoUser',
      },
      component: 'cqRoutingBotStatistics',
      resolve: {
        routingBot: getRoutingBot,
      },
    });

    /**
     * Получение роутинг-бота
     *
     * @param chatBotModel
     * @returns {Promise}
     */
    function getRoutingBot(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot());
    }
  }
})();

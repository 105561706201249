import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmLeadNoteModule } from '../lead-note/amocrm-lead-note.module';
import { AmocrmLeadTagsModule } from '../lead-tags/amocrm-lead-tags.module';
import { AmocrmModalLeadSettingsComponent } from './amocrm-modal-lead-settings.component';

@NgModule({
  declarations: [AmocrmModalLeadSettingsComponent],
  exports: [AmocrmModalLeadSettingsComponent],
  imports: [
    AmocrmLeadNoteModule,
    AmocrmLeadTagsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
    UIRouterModule,
  ],
})
export class AmocrmModalLeadSettingsModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AceConfigInterface } from 'ngx-ace-wrapper';
import { skip, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-request-body-editor',
  templateUrl: './request-body-editor.component.html',
  styleUrls: ['./request-body-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestBodyEditorComponent),
      multi: true,
    },
  ],
})
export class RequestBodyEditorComponent implements ControlValueAccessor, OnInit {
  formControl = new FormControl();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private readonly destroy$: DestroyService) {}

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$), skip(1)).subscribe((value) => this.onChange(value));
  }

  aceConfig: AceConfigInterface = {
    mode: 'json',
    theme: 'monokai',
  };

  /** ControlValueAccessor's methods implementation */

  registerOnChange(fn: (v: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: boolean): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.changeDetectorRef.markForCheck();
  }

  private onChange: (v: string) => void = (v: string) => {};

  private onTouched: () => void = () => {};
}

/**
 * Контроллер для чарта воронок
 */
import { STARTER_GUIDE_STEPS } from '../../../app/http/starter-guide/starter-guide.constants';

(function () {
  'use strict';

  angular.module('myApp.funnelChart').controller('CqFunnelChartController', CqFunnelChartController);

  function CqFunnelChartController(
    $filter,
    $scope,
    $state,
    $translate,
    $uibModal,
    $window,
    appModel,
    carrotquestHelper,
  ) {
    var vm = this;

    /**
     * Минимальное значение счетчика требуемое для отображения значений шкал графика
     *
     * @type {Number}
     */
    var FUNNEL_COUNTER_MIN_VALUE_FOR_DISPLAY_SCALE_VALUE = 50;

    /**
     * Дефолтные события воронки, используются при создании демо воронки в новых аппах
     *
     * @type {Array}
     */
    var FUNNEL_DEFAULT_EVENTS = {
      saas: ['$session_start', '$registered', '$authorized', '$order_completed'],
      shop: ['$session_start', '$product_viewed', '$cart_added', '$order_completed'],
      landing: ['$session_start', '$email_changed', '$order_completed'],
      other: ['$session_start', '$registered', '$authorized', '$order_completed'],
    };

    /**
     * Количество делений шкалы измерений графика
     *
     * @type {Number}
     */
    var FUNNEL_SCALE_DIVISION_LENGTH = 9;

    /**
     * Лимит символов выводимых в названии события в шаге воронки на графике
     *
     * @type {Number}
     */
    var FUNNEL_STEP_NAME_LIMIT = 40;

    /**
     * Лимит символов выводимых в названии события в шаге воронки в пояснении
     *
     * @type {Number}
     */
    var FUNNEL_STEP_EXTENDED_NAME_LIMIT = 120;

    // Имя страницы с которой используется компонент
    var trackingPageName = $state.includes('app.content.funnels')
      ? 'Воронки 2018'
      : $state.includes('app.content.dashboard')
      ? 'Dashboard'
      : '';

    vm.$onInit = init;

    function init() {
      vm.appCategory = vm.currentApp.settings.category || 'other'; // HACK Тип аппа, т.к. не у всех аппаов есть тип, необходимо делать проверку
      vm.appModel = appModel;
      vm.fakeCounters = []; // Демо данные счетчиков воронки
      vm.FUNNEL_COUNTER_MIN_VALUE_FOR_DISPLAY_SCALE_VALUE = FUNNEL_COUNTER_MIN_VALUE_FOR_DISPLAY_SCALE_VALUE;
      vm.FUNNEL_SCALE_DIVISION_LENGTH = FUNNEL_SCALE_DIVISION_LENGTH;
      vm.FUNNEL_STEP_EXTENDED_NAME_LIMIT = FUNNEL_STEP_EXTENDED_NAME_LIMIT;
      vm.FUNNEL_STEP_NAME_LIMIT = FUNNEL_STEP_NAME_LIMIT;
      vm.isEventDefault = isEventDefault;
      vm.isDefaultFunnelCompleted = false; // Флаг отображающий что все шаги воронки настроены
      vm.isFunnelHasDeletedEvents = false; // Флаг отображающий что в воронке есть удаленные события
      vm.isStepHasDeletedEvents = isStepHasDeletedEvents; // Функция проверки наличия в шаге удаленного события
      vm.redirectToStarterGuide = redirectToStarterGuide;
      vm.trackClickInstallCode = trackClickInstallCode;
      vm.trackClickOnSetUpEventLink = trackClickOnSetUpEventLink;
      vm.valuesScale = []; // Шкала значений

      $scope.$watch('vm.counters', watchCounters);
      $scope.$watch('vm.funnel.steps', watchFunnelSteps, true);

      /**
       * Отслеживаем изменения шагов воронки
       */
      function watchFunnelSteps() {
        isDefaultFunnelCompleted();
      }

      /**
       * Отслеживаем изменения счетчиков воронки
       */
      function watchCounters() {
        // Если выбрана основная воронка
        // обновляем демо данные основной воронки
        vm.funnel.isDefault && updateDemoFunnel();

        // Если значения вопронки удовлетворяют требованиям
        // обновляем значения шкал графика
        vm.counters[0] > FUNNEL_COUNTER_MIN_VALUE_FOR_DISPLAY_SCALE_VALUE && updateValueScale();
      }
    }

    /**
     * Обновляет флаг отображающий что все шаги воронки настроены
     */
    function isDefaultFunnelCompleted() {
      vm.isDefaultFunnelCompleted = true;

      vm.funnel.steps.forEach(function (item) {
        if (angular.isDefined(item[0].eventType)) {
          if (!item[0].eventType.active) {
            vm.isDefaultFunnelCompleted = false;
          }
        }
      });
    }

    /**
     * Проверяет содержится ли событие в списке дефолтных
     *
     * @param {String} eventName Имя события
     */
    function isEventDefault(eventName) {
      return !!~FUNNEL_DEFAULT_EVENTS[vm.appCategory].indexOf(eventName);
    }

    /**
     * Проверяет содержится ли в шаге воронки удаленное события
     *
     * @param {array} step Шаг воронки
     */
    function isStepHasDeletedEvents(step) {
      if ($filter('filter')(step, { eventType: { visible: false } }).length > 0) {
        vm.isFunnelHasDeletedEvents = true;
        vm.isSegmentationNotAvailable = true;
        return true;
      } else {
        return false;
      }
    }

    /** Редирект на стартергайд */
    function redirectToStarterGuide() {
      $state.go('app.content.dashboard', {
        step: STARTER_GUIDE_STEPS.INSTALLATION_SERVICE,
      });
    }

    /**
     * Трек клика по кнопке «Установить скрипт»
     */
    function trackClickInstallCode() {
      carrotquestHelper.track('block_clicked', {
        page_url: $window.location.pathname,
        type: 'install code to start',
      });
    }

    /**
     * Трек клика по ссылке "Настроить событие"
     *
     * @param {Object} eventName - Название события в воронке
     */
    function trackClickOnSetUpEventLink(eventName) {
      carrotquestHelper.track(trackingPageName + ' - клик по ссылке "Настроить событие"', {
        App: vm.currentApp.name,
        Название: eventName,
      });
    }

    /**
     * Обновляем демонстрационные значения основной воронки
     */
    function updateDemoFunnel() {
      vm.counters.forEach(function (currentValue, index, array) {
        // Если значение отсутствует, заменяем его на демо данные
        if (currentValue === 0) {
          vm.fakeCounters[index] = index !== 0 ? vm.fakeCounters[index - 1] / 2 : 100; // демо график в половину ниже предыдущего
        } else {
          vm.fakeCounters[index] = array[index];
        }
      });
    }

    /**
     * Обновить значения для шкалы
     */
    function updateValueScale() {
      var valueScale = [];
      var mainColumn = vm.counters[0]; // Первый шаг воронки в соответствии с которым строится шкала измерений

      // Максимальное значение шкалы измерений
      // т.к. первое деление шкалы измерений на половину выше первого столбца графика
      // в вычислениях уменьшаем количество делений шкалы измерений графика на половину одного деления
      var maxScaleValue = (mainColumn / (FUNNEL_SCALE_DIVISION_LENGTH - 0.5)) * FUNNEL_SCALE_DIVISION_LENGTH;
      var scaleValue = maxScaleValue / FUNNEL_SCALE_DIVISION_LENGTH; // Величина одного деления графика

      for (var i = 1; i <= FUNNEL_SCALE_DIVISION_LENGTH; i++) {
        valueScale.push(Math.round(scaleValue * i));
      }

      // возвращаем значения шкалы измерений графика снизу вверх
      vm.valuesScale = $filter('reverse')(valueScale);
    }
  }
})();

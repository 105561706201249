import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@environment';
import { FEATURES } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS } from '@http/integration/constants/integration.constants';
import { MessageModel } from '@http/message/message.model';
import { AmocrmModalLeadSettingsComponent } from '@panel/app/pages/integrations/content/amocrm/modal-lead-settings/amocrm-modal-lead-settings.component';
import { ModalHelperService } from '@panel/app/services';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-auto-message-amo-transfer',
  templateUrl: './auto-message-amo-transfer.component.html',
  styleUrls: ['./auto-message-amo-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoMessageAmoTransferComponent implements OnInit {
  @Input()
  abASide!: any;

  @Input()
  abBSide!: any;

  @Input()
  amocrmIntegrations!: any;

  @Input()
  currentApp!: any;

  @Input()
  currentMessage!: any;

  @Input()
  disableNotificationSelector!: any;

  @Output()
  wizardMoveStep: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NgbPopover)
  ngbPopover!: NgbPopover;

  protected control: any;
  protected amocrmNotificationSwitch: any;
  protected messagePartTypeName: any;

  protected AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS: any = AUTO_MESSAGE_EMAIL_NOTIFICATION_ERRORS;
  protected FEATURES: any = FEATURES;
  protected PROJECT_NAME: any = environment.projectName;

  constructor(
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly fb: FormBuilder,
    protected readonly featureModel: FeatureModel,
    protected readonly messageModel: MessageModel,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.control = this.fb.control(null, Validators.required);
    this.amocrmNotificationSwitch = this.getSwitchValue();
    this.messagePartTypeName = this.messageModel.getMessagePartTypeName(this.currentMessage.parts);

    if (this.currentMessage.notificationIntegrations && this.currentMessage.notificationIntegrations.amocrm) {
      this.control.setValue(this.currentMessage.notificationIntegrations.amocrm.integration.id, {
        emitEvent: false,
      });
    }

    this.control.valueChanges.subscribe((value: any) => {
      if (!value) {
        delete this.currentMessage.notificationIntegrations.amocrm;
        return;
      }

      this.currentMessage.notificationIntegrations = {
        ...this.currentMessage.notificationIntegrations,
        amocrm: {
          integration: this.amocrmIntegrations.find((item: any) => item.id === value),
        },
      };
    });
  }

  /**
   * Изменение значения свича передачи данных в амо
   * @param {boolean} value
   */
  changeAmocrmNotificationSwitch(value: any): void {
    if (!value) {
      this.removeIntegration();
    }
  }

  /**
   * Получение сгенерированного названия триггерного сообщения из типов вариантов в сообщении
   *
   * @param {Array.<Object>} messageParts Варианты сообщения
   * @param $translate Переводчик
   */
  getGeneratedMessageName(messageParts: any) {
    let namePrefix =
      messageParts.length < 2
        ? this.translocoService.translate('models.message.generatedNamePrefix.single')
        : this.translocoService.translate('models.message.generatedNamePrefix.abTest');
    let messagePartsTypes: any = [];

    messageParts.forEach((part: any) => {
      messagePartsTypes.push(this.translocoService.translate('models.message.messagePartTypes.' + part.type));
    });

    return namePrefix + ' ' + messagePartsTypes.join('/');
  }

  getSwitchValue() {
    if (this.currentMessage.notificationIntegrations && this.currentMessage.notificationIntegrations.amocrm) {
      return true;
    }

    return false;
  }

  /**
   * Добавление пустого messageConfig при
   */
  onAmocrmIntegrationSelect(integration: any): void {
    if (!integration.active) {
      return this.control.setValue(null);
    }

    if (
      this.currentMessage.notificationIntegrations.amocrm &&
      !this.currentMessage.notificationIntegrations.amocrm.messageConfig
    ) {
      const messageName = this.currentMessage.name || this.getGeneratedMessageName(this.currentMessage.parts);
      this.currentMessage.notificationIntegrations.amocrm = {
        integration: this.currentMessage.notificationIntegrations.amocrm.integration,
        messageConfig: {
          comment: this.translocoService.translate('autoMessages.editor.amocrm.defaultNoteText', { messageName }),
          message: this.currentMessage.id,
        },
      };
    }

    this.openAmocrmLeadSettingsModal(true);
  }

  /**
   * Открытие модального окна редактирования настроек сделки в AmoCRM
   *
   * @param {boolean=false} clearSelectedOnDismiss - Очищать ли select при закрытии модального окна
   */
  openAmocrmLeadSettingsModal(clearSelectedOnDismiss: any): void {
    const amocrmConfig = this.currentMessage.notificationIntegrations.amocrm;

    let modal = this.modalHelperService.open(AmocrmModalLeadSettingsComponent);

    modal.componentInstance.note = amocrmConfig.messageConfig?.comment ?? null;
    modal.componentInstance.leadTags = amocrmConfig.messageConfig?.tags ?? null;
    modal.componentInstance.modalParams = {
      integration: amocrmConfig.integration,
      integrationSource: 'message',
    };

    modal.result
      .then((value: any) => {
        amocrmConfig.messageConfig.comment = value.comment;
        amocrmConfig.messageConfig.tags = value.leadTags;
        this.trackAmocrmIntegrationSelect();
      })
      .catch(() => {
        if (clearSelectedOnDismiss) {
          this.removeIntegration();
        }
      });
  }

  /**
   * Удаление нотификации сообщения
   */
  removeIntegration(): void {
    this.control.setValue(null);
  }

  trackAmocrmIntegrationSelect(): void {
    const trackParams: any = {
      'Название автосообщения': this.currentMessage.name,
      app_id: this.currentApp.id,
    };
    const type = this.currentMessage.parts[0].type;
    trackParams['Тип автосообщения А'] = this.translocoService.translate(`models.message.messagePartTypes.${type}`);

    if (this.currentMessage.parts[1]) {
      const typeBSide = this.currentMessage.parts[1].type;
      trackParams['Тип автосообщения Б'] = this.translocoService.translate(
        `models.message.messagePartTypes.${typeBSide}`,
      );
    }

    this.carrotquestHelper.track(
      'Триггерные сообщения - передача данных и цель - настроил отправку данных в amoCRM',
      trackParams,
    );
  }
}

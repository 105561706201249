import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';

import { environment } from '@environment';
import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';

import { CarrotquestHelper } from '../../../../app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { VideoViewModalService } from '../../../services/video-view/video-view-modal.service';

@Component({
  selector: 'cq-starter-guide-leads-collection[setCurrentStepIsMade]',
  templateUrl: './leads-collection.component.html',
  styleUrls: ['./leads-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarterGuideLeadsCollectionComponent {
  /** Выполненность шага */
  @Input()
  isStepMade: boolean = false;

  /** Установка текущего шага - пройденным */
  @Output()
  setCurrentStepIsMade: EventEmitter<void> = new EventEmitter<void>();
  /** Текущий язык */
  activeLang!: string;
  /** Текущий название проекта */
  projectName: string = environment.projectName;

  constructor(
    private readonly carrotQuestHelper: CarrotquestHelper,
    private readonly translocoService: TranslocoService,
    private readonly videoViewModalService: VideoViewModalService,
    private readonly uiRouter: UIRouter,
  ) {
    this.activeLang = translocoService.getActiveLang();
  }

  goToBotCreation() {
    this.setCurrentStepIsMade.emit();

    const params = {
      ...this.uiRouter.stateService.params,
      fromStarterGuideStep: STARTER_GUIDE_STEPS.LEADS_COLLECTION,
    };
    this.uiRouter.stateService.go('app.content.messagesAjs.chatBot.create.new', params);
  }

  /**
   * Открытие модального окна для просмотра видео
   */
  openViewVideoModal(): void {
    this.trackOpenVideo();

    this.videoViewModalService.open({
      heading: this.translocoService.translate('starterGuideLeadsCollectionComponent.modal.heading'),
      videoUrl: this.translocoService.translate('starterGuideLeadsCollectionComponent.modal.src'),
    });
  }

  /**
   * Трек просмотра видео
   */
  private trackOpenVideo() {
    this.carrotQuestHelper.track('Стартергайд - видео про бота просмотрено');
  }
}

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

/**
 * Название Cookie, в которой хранится токен
 */
const cookieName: string = 'carrotquest_auth_token_panel';

/**
 * Количество дней, через которые кука авторизации просрочится
 */
const expires: number = 100;

/**
 * Сервис авторизации
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private cookieService: CookieService) {}

  /**
   * Получение токена авторизации
   */
  getToken(): string {
    if (this.isAuthorized()) {
      return this.cookieService.get(cookieName);
    } else {
      return '';
    }
  }

  /**
   * Авторизован ли пользователь
   */
  isAuthorized(): boolean {
    return this.cookieService.check(cookieName);
  }

  /**
   * Авторизация пользвоателя
   *
   * @param token Токен авторизации
   */
  login(token: string): void {
    return this.cookieService.set(cookieName, token, expires);
  }

  /**
   * Деавторизация пользователя
   */
  logout(): void {
    return this.cookieService.delete(cookieName);
  }
}

import { module } from 'angular';

import { ConversationTagAdapterService } from '@panel/app-old/shared/entities/conversation-tag/adapter/conversation-tag-adapter.service';
import { ConversationTagFactoryService } from '@panel/app-old/shared/entities/conversation-tag/factory/conversation-tag-factory.service';
import { ConversationTagHelpService } from '@panel/app-old/shared/entities/conversation-tag/help/conversation-tag-help.service';
import { ConversationTagHttpService } from '@panel/app-old/shared/entities/conversation-tag/http/conversation-tag-http.service';

module('myApp.entities.conversationTag', [])
  .service('conversationTagAdapterService', ConversationTagAdapterService)
  .service('conversationTagFactoryService', ConversationTagFactoryService)
  .service('conversationTagHelpService', ConversationTagHelpService)
  .service('conversationTagHttpService', ConversationTagHttpService);

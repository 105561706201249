/**
 * Компонент для превью блока 'Текст'
 */
(function () {
  'use strict';

  require('./popup-text-block-preview.controller');
  require('./text.html');
  // HACK: костыль, это должно делать не так. CSS в этом случае никак не обрабатывается angular-cli при билде/серве, потому что стоит ! перед ng-cache-loader
  require('!ng-cache-loader?prefix=panel/src:js/**&url=false!./text.scss');

  angular.module('myApp.popupEditor').component('cqPopupTextBlockPreview', cqPopupTextBlockPreview());

  function cqPopupTextBlockPreview() {
    return {
      bindings: {
        params: '=', // параметры блока
      },
      controller: 'CqPopupTextBlockPreviewController',
      controllerAs: 'vm',
      template: require('./popup-text-block-preview.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { UIRouter } from '@uirouter/core';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { Integration } from '@http/integration/integration';

/**
 * Компонент для работы с OAuth авторизацией
 *
 * NOTE:
 *  На данный компонент происходит редирект после попытки авторизоваться через OAuth
 */

@Component({
  selector: 'cq-integration-o-auth-handler',
  template: '',
  styleUrls: ['./integration-o-auth-handler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationOAuthHandlerComponent implements OnInit {
  constructor(@Inject(UIRouter) readonly uiRouter: UIRouter, @Inject(WINDOW) readonly windowRef: Window) {}

  ngOnInit(): void {
    if (!this.windowRef.opener) {
      throw Error('windowRef.opener is undefined');
    }

    const { error, integrationId, integrationType } = this.uiRouter.globals.params;

    if (error) {
      return this.authorizeError(error);
    } else {
      if (!integrationId) {
        throw Error('integrationId is undefined');
      }

      if (!integrationType) {
        throw Error('integrationType is undefined');
      }

      return this.authorizeSuccess(integrationId, integrationType);
    }
  }

  /**
   * Авторизация прошла успешно
   *
   * @param integrationId - Id интеграции, которая создалась после OAuth авторизации
   * @param integrationType - Тип интеграции, в которой произошла авторизация
   */
  authorizeSuccess(integrationId: Integration['id'], integrationType: INTEGRATION_TYPES): void {
    this.windowRef.opener.postMessage(
      {
        status: 'accepted',
        integrationId,
        integrationType,
      },
      '*',
    );
  }

  /**
   * Авторизация прошла неуспешно
   *
   * @param error - Ошибка, которую необходимо отправить в родительское окно
   */
  authorizeError(error: string): void {
    this.windowRef.opener.postMessage(
      {
        status: 'error',
        error: error,
      },
      '*',
    );
  }
}

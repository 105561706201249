import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationService } from '@http/integration/services/integration.service';
import { DestroyService } from '@panel/app/services';
import { SharedModule } from '@panel/app/shared/shared.module';

import { VkGroupsChoiceComponent } from './vk-groups-choice.component';

@NgModule({
  declarations: [VkGroupsChoiceComponent],
  exports: [VkGroupsChoiceComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule, TranslocoModule],
  providers: [DestroyService, IntegrationService],
})
export class VkGroupsChoiceModule {}

import { APP_TASKS } from '../../../app/http/app/app.constants';
import { FEATURE_BY_REGISTRATION_TASK, FEATURES } from '../../../app/http/feature/feature.constants';
import {
  STARTER_GUIDE_STEPS,
  STARTER_GUIDE_STEPS_BASICS,
  STARTER_GUIDE_STEPS_BY_TASK,
  STARTER_GUIDE_TASK_BY_APP_TASK,
  STARTER_GUIDE_TASKS,
} from '../../../app/http/starter-guide/starter-guide.constants';

(function () {
  'use strict';

  angular.module('myApp.starterGuide').controller('CqStarterGuideController', CqStarterGuideController);

  function CqStarterGuideController(
    $anchorScroll,
    $filter,
    $location,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $translate,
    $uibModal,
    PROJECT_NAME,
    blockingService,
    carrotquestHelper,
    featureModel,
    starterGuideModel,
  ) {
    let vm = this;

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.APP_TASKS = APP_TASKS;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.getNextStepName = getNextStepName;
      vm.getTranslatedAlertTextForStep = getTranslatedAlertTextForStep;
      vm.getTranslatedDescriptionTextForStep = getTranslatedDescriptionTextForStep;
      vm.isDisabledTaskSelect = blockingService.hasBlockingForMarketerCase(
        vm.currentApp.activation,
        vm.djangoUserTempData,
      );
      vm.isStepMade = isStepMade;
      vm.starterGuideStepsCollapsedStates = getStarterGuideStepsCollapsedStates();
      vm.onChangeTask = onChangeTask;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.selectedTask = getSelectedTask(); // Задача, которую решает сервис
      vm.STARTER_GUIDE_STEPS = STARTER_GUIDE_STEPS;
      vm.STARTER_GUIDE_STEPS_BASICS = STARTER_GUIDE_STEPS_BASICS;
      vm.STARTER_GUIDE_STEPS_BY_TASK = STARTER_GUIDE_STEPS_BY_TASK;
      vm.STARTER_GUIDE_TASKS = STARTER_GUIDE_TASKS;
      vm.starterGuideSteps = starterGuideModel.starterGuideSteps;
      vm.starterGuideStepsView = getStarterGuideSteps(); // Пройденность шагов для компонента. Нужен чтобы отображать алерт с задержкой
      /** Индекс, с которого начнётся отсчёт для шагов под выбранную задачу */
      vm.startIndexForTasks = STARTER_GUIDE_STEPS_BASICS.length + 1;
      vm.stepCompletion = stepCompletion;
      vm.toggleStep = toggleStep;

      trackEnteringStarterGuide();

      starterGuideModel.addPassedStepCallback(stepWasPassedCallback);

      if ($stateParams.showCompletionRegistrationModal) {
        $uibModal
          .open({
            component: 'cqCompletionRegistrationModalWrapper',
            size: 'md modal-dialog-centered',
          })
          .result.then(openQuizInRegistration, openQuizInRegistration);
      }
    }

    function destroy() {
      starterGuideModel.removePassedStepCallback(stepWasPassedCallback);
    }

    /**
     * Свернуть остальные вкладки
     *
     * @param {STARTER_GUIDE_STEPS} stepName Название шага стартергайда
     */
    function collapseOtherSteps(stepName) {
      for (const [step, isCollapsed] of Object.entries(vm.starterGuideStepsCollapsedStates)) {
        if (step !== stepName) {
          vm.starterGuideStepsCollapsedStates[step] = true;
        }
      }
    }

    /**
     * Раскрыть первый непройденный шаг
     *
     * @param {STARTER_GUIDE_STEPS} step Пройденный шаг
     */
    function expandNextNotMadeStep(step) {
      const nextStep = getNextStepName(step);

      toggleStep(nextStep);
    }

    /**
     * Получение названия следующего шага для переданного
     *
     * @param currentPassedStepName Название шага
     * @return {string|undefined}
     */
    function getNextStepName(currentPassedStepName) {
      let baseStepIndex = STARTER_GUIDE_STEPS_BASICS.indexOf(currentPassedStepName);
      let taskStepIndex = STARTER_GUIDE_STEPS_BY_TASK[vm.selectedTask].indexOf(currentPassedStepName);

      let isBaseStep = STARTER_GUIDE_STEPS_BASICS.includes(currentPassedStepName);
      let isTaskStep = STARTER_GUIDE_STEPS_BY_TASK[vm.selectedTask].includes(currentPassedStepName);

      let isLastBaseStep = baseStepIndex + 1 === STARTER_GUIDE_STEPS_BASICS.length;
      let isLastTaskStep = taskStepIndex + 1 === STARTER_GUIDE_STEPS_BY_TASK[vm.selectedTask].length;

      switch (true) {
        case isLastBaseStep:
          return getNextStepNameInSelectedTaskSteps();
        case isBaseStep:
          return STARTER_GUIDE_STEPS_BASICS.filter(
            (step, index) => index > baseStepIndex && !starterGuideModel.isStepIsMade(step),
          )[0];
        case isTaskStep:
          return getNextStepNameInSelectedTaskSteps(taskStepIndex + 1);
        default:
          return;
      }
    }

    /**
     * Получение первого непройденного шага в блоке из блока с шагами под выбранную задачу.
     * Можно указать индекс, с которого будет идти поиск.
     *
     * @param startIndex Индекс, с которого будет идти поиск.
     * @return {string|undefined}
     */
    function getNextStepNameInSelectedTaskSteps(startIndex = 0) {
      return STARTER_GUIDE_STEPS_BY_TASK[vm.selectedTask].filter(
        (step, index) => index >= startIndex && !starterGuideModel.isStepIsMade(step),
      )[0];
    }

    /**
     * Возвращает переведённый текст алерта для шага
     *
     * @param step - Шаг
     */
    function getTranslatedAlertTextForStep(step) {
      return $translate.instant(`starterGuideForMarketersComponent.steps.${step}.alert`);
    }

    /**
     * Возвращает переведённый текст описания для шага
     *
     * @param step - Шаг
     */
    function getTranslatedDescriptionTextForStep(step) {
      return $translate.instant(`starterGuideForMarketersComponent.steps.${step}.description`);
    }

    /**
     * Состояние свёрнутости шагов
     */
    function getStarterGuideStepsCollapsedStates() {
      const collapsedStates = {
        [STARTER_GUIDE_STEPS.CHAT_SETTINGS]: true,
        [STARTER_GUIDE_STEPS.WRITE_SELF]: true,
        [STARTER_GUIDE_STEPS.INSTALLATION_SERVICE]: true,
        [STARTER_GUIDE_STEPS.WORK_TIME]: true,
        [STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE]: true,
        [STARTER_GUIDE_STEPS.LEADS_COLLECTION]: true,
        [STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION]: true,
        [STARTER_GUIDE_STEPS.LEADS_PASSING]: true,
        [STARTER_GUIDE_STEPS.WARM_UP]: true,
        [STARTER_GUIDE_STEPS.SOCIAL_INTEGRATIONS]: true,
      };

      // Разворачиваем переданный шаг
      if (vm.starterGuideStep) {
        collapsedStates[vm.starterGuideStep] = false;
      }

      return collapsedStates;
    }

    /** Получение задачи стартергайда в зависимости от выбранной задачи в регистрации */
    function getSelectedTask() {
      /** Задача, выбранная при регистрации */
      const selectedAppTaskLS = localStorage.getItem('carrotquest_selected_task');
      /** Задача, выбранная в стартергайде */
      const selectedStarterGuideTaskLS = localStorage.getItem('carrotquest_selected_sg_task');

      switch (true) {
        case !!selectedStarterGuideTaskLS:
          return selectedStarterGuideTaskLS;
        case !!selectedAppTaskLS:
          return STARTER_GUIDE_TASK_BY_APP_TASK[selectedAppTaskLS];
        default:
          return STARTER_GUIDE_TASKS.TALK_TO_CUSTOMERS;
      }
    }

    /**
     * Получение пройденность шагов для компонента
     * @returns {{}}
     */
    function getStarterGuideSteps() {
      const steps = {};
      for (let step of vm.starterGuideSteps) {
        steps[step.name] = step.passed;
      }
      return steps;
    }

    /**
     * Выполнены ли шаг стартергайда
     *
     * @param {String} step - Шаг, который нужно проверить
     */
    function isStepMade(step) {
      if (step === STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION) {
        return (
          vm.starterGuideStepsView[STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE] ||
          vm.starterGuideStepsView[STARTER_GUIDE_STEPS.LEADS_COLLECTION]
        );
      }

      return vm.starterGuideStepsView[step];
    }

    /**
     * Колбэк на смену задачи, которую решает сервис
     *
     * @param newTask Выбранная задача
     */
    function onChangeTask(newTask) {
      $scope.$apply(() => {
        // Запоминаем выбранную задачу
        localStorage.setItem('carrotquest_selected_sg_task', newTask);

        vm.selectedTask = newTask;
      });
    }

    function openQuizInRegistration() {
      const selectedTask = vm.djangoUserTempData?.task;
      const featureBySelectedTask = FEATURE_BY_REGISTRATION_TASK[selectedTask];

      if (!vm.featureModel.hasAccess(featureBySelectedTask)) {
        return;
      }

      $uibModal
        .open({
          component: 'cqQuizInRegistrationModalWrapper',
          size: 'xl modal-dialog-centered',
          backdrop: 'static',
        })
        .result.then(
          () => {},
          () => {
            vm.starterGuideStepsCollapsedStates[vm.STARTER_GUIDE_STEPS.CHAT_SETTINGS] = false;
          },
        );
    }

    /**
     * Действия на завершение шага
     *
     * @param {STARTER_GUIDE_STEPS} step - Шаг, который завершили
     */
    function stepCompletion(step) {
      starterGuideModel.setStepIsMade(vm.currentApp.id, step).subscribe();
    }

    /**
     * Коллбек, вызываемый после установки шага в статус "пройдено"
     * @param {STARTER_GUIDE_STEPS} step
     */
    function stepWasPassedCallback(step) {
      expandNextNotMadeStep(step);
      updateMadeStep(step);
    }

    /**
     * Скрыть/показать шаг стартергайда
     *
     * @param {STARTER_GUIDE_STEPS} stepName Название шага, который нужно скрыть/показать
     */
    function toggleStep(stepName) {
      vm.starterGuideStepsCollapsedStates[stepName] = !vm.starterGuideStepsCollapsedStates[stepName];

      collapseOtherSteps(stepName);
    }

    /**
     * Обновить пройденность шага для компонента
     * @param {STARTER_GUIDE_STEPS} step
     */
    function updateMadeStep(step) {
      const ANIMATION_TIME = 300;
      const isMade = starterGuideModel.isStepIsMade(step);
      if (isMade !== vm.starterGuideStepsView[step]) {
        setTimeout(() => {
          vm.starterGuideStepsView[step] = isMade;
        }, ANIMATION_TIME);
      }
    }

    /**
     * Трекинг перехода на стартергайд
     */
    function trackEnteringStarterGuide() {
      const eventName = 'Стартергайд - переход';

      carrotquestHelper.track(eventName);
    }
  }
})();

import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';

(function () {
  'use strict';

  angular.module('myApp.settings').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.settings', {
        url: '/settings?{tab}',
        redirectTo: 'app.content.settings.general',
        template: '<div ui-view></div>',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
      })

      .state('app.content.settings.general', {
        url: '',
        bindings: {
          activeChatWidgetList: 'activeChatWidgetList',
          billingInfo: 'billingInfo',
          botSender: 'botSender',
          chatSettings: 'chatSettings',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
          knowledgeBase: 'knowledgeBase',
          messageSenders: 'messageSenders',
          privacySettings: 'privacySettings',
          pushSettings: 'pushSettings',
          routingBot: 'routingBot',
          savedReplies: 'savedReplies',
        },
        component: 'cqSettings',
        resolve: {
          activeChatWidgetList: getActiveChatWidgetList,
          botSender: getBotSender,
          chatSettings: getChatSettings, // FIXME: их можно не передавать снаружи, а создавать внутри компонента, т.к. по сути это копия currentApp.settings
          knowledgeBase: getKnowledgeBase,
          messageSenders: getMessageSenders,
          privacySettings: getPrivacySettings,
          pushSettings: getPushSettings,
          routingBot: getRoutingBot,
          savedReplies: getSavedReplies,
        },
      });

    /**
     * Получение списка активных чат-виджетов
     *
     * @param chatBotModel
     * @param currentApp
     * @returns {IPromise<ActiveChatWidget>}
     */
    function getActiveChatWidgetList(chatBotModel, currentApp) {
      return firstValueFrom(chatBotModel.getActiveWidgetBotList(currentApp.id));
    }

    /**
     * Получение отправителя бота
     *
     * @return {Promise}
     * @param $transition$
     */
    function getBotSender($transition$) {
      let messageSenderModel = $transition$.injector().get('messageSenderModel');

      return firstValueFrom(messageSenderModel.getBotSender());
    }

    /**
     * Получение настроек чата
     *
     * @param currentApp
     */
    function getChatSettings(currentApp) {
      return angular.copy(currentApp.settings);
    }

    /**
     * Получение БЗ
     *
     * @param knowledgeBaseModel
     * @param currentApp
     * @return {PromiseLike<any> | Promise<any>}
     */
    function getKnowledgeBase(knowledgeBaseModel, currentApp) {
      return firstValueFrom(knowledgeBaseModel.get(currentApp.id)).then(getSettingsSuccess);

      function getSettingsSuccess(response) {
        if (response === 'KnowledgeBaseAppDoesNotExist') {
          return null;
        } else {
          return response;
        }
      }
    }

    /**
     * Получение отправителей
     *
     * @param $transition$
     * @param currentApp
     */
    function getMessageSenders($transition$, currentApp) {
      let messageSenderModel = $transition$.injector().get('messageSenderModel');

      return firstValueFrom(messageSenderModel.getList(currentApp.id));
    }

    /**
     * Получение настроек для обработки персональных данных
     * NOTE: да, это копия обычных настроек. То же самое, что и chatSettings. Но почему так, почему нельзя просто передать те же самые chatSettings в компонент обработки персональных данных?
     *  А кейс следующий: меняю что-то в настройках обработки ПД, перехожу в настройки чата и нажимаю кнопку "Сохранить". В настройках чата chatSettings мерджатся с currentApp.settings
     *  Таким образом в currentApp.settings изменятся настройки, которые не должны были поменяться
     *
     * @param currentApp
     */
    function getPrivacySettings(currentApp) {
      return angular.copy(currentApp.settings);
    }

    /**
     * Получение настроек пушей
     *
     * @param $transition$
     * @param currentApp
     */
    function getPushSettings($transition$, currentApp) {
      let pushSettingsModel = $transition$.injector().get('pushSettingsModel');

      return firstValueFrom(pushSettingsModel.get(currentApp.id));
    }

    /**
     * Получение роутинг бота
     */
    function getRoutingBot(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot()).then((resp) => {
        if (!resp) {
          return null;
        }
        return resp;
      });
    }

    /**
     * Получение сохранённых ответов в чате
     *
     * @param savedReplyModel
     * @param currentApp
     */
    function getSavedReplies(savedReplyModel, currentApp) {
      return firstValueFrom(savedReplyModel.getList(currentApp.id));
    }
  }
})();

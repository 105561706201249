import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { DEFAULT_PRICING_ADDONS } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.constants';
import { AddonsConfiguration } from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.types';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';

@Component({
  selector: 'cq-pricing-new[currentApp][djangoUser]',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingComponent implements OnInit {
  @Input()
  currentApp!: App;

  @Input()
  djangoUser!: DjangoUser;

  addonsConfiguration: AddonsConfiguration = cloneDeep(DEFAULT_PRICING_ADDONS[environment.country]);

  expectedVisitors: number = 10000;

  /** Виден ли компонент сравнения тарифных планов */
  isVisibleComparisonComponent: boolean = false;

  needsPersonalPlan: boolean = false;

  /**
   * Конфигурация тарифных планов
   *
   * NOTE:
   *  Тип и значение неопределённо, т.к. сам механизм конфигурации тарифов сделан неправильно.
   *  Исправлять ничего не стали, т.к. необходимо было быстро зарелизить.
   */
  ratePlansConfig: any = undefined;

  constructor(
    private readonly billingInfoModel: BillingInfoModel,
    private readonly billingPlanService: BillingPlanService,
  ) {}

  ngOnInit(): void {
    this.ratePlansConfig = this.billingPlanService.getCurrentPlansSettings(
      this.currentApp,
      this.billingInfoModel.billingInfo,
    );
  }
}

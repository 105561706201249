import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BotButtonActionBodyJson, BotButtonActionIcon } from 'app/http/chat-bot/actions';
import { BehaviorSubject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { CHAT_BOT_BUTTON_ACTION_URL_TARGET } from '@http/chat-bot/types/action-internal.types';
import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { FEATURES_ONLY } from '@http/feature/feature.constants';
import { FeatureModel } from '@http/feature/feature.model';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationService } from '@http/integration/services/integration.service';
import { BlockType, Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { BUTTON_TEXT_MAX_LENGTH } from '@panel/app/pages/chat-bot/forms/actions/button-action.form';
import { DestroyService, OneTimeFlagService } from '@panel/app/services';
import { ONE_TIME_FLAGS } from '@panel/app/services/one-time-flag/one-time-flag.constants';
import { noWhitespaceValidator } from '@panel/app/shared/validators/no-whitespace/no-whitespace-validator';
import { URL_REGEXP } from '@panel/app/shared/validators/url/url';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** RegExp'ы для валидации ссылок на соцсети и мессенджеры */
export const FACEBOOK_REGEXP = /(?:https:)?\/\/m.me\/([\w\d]+)?/;
export const INSTAGRAM_REGEXP =
  /(?:https?:)?\/\/(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/;
export const MAILTO_REGEXP = /(?:mailto:)([A-z0-9_.+-]+@[A-z0-9_.-]+\.[A-z]+)?/;
export const PHONE_REGEXP = /(?:tel):(\+?[0-9. -]+)?/;
export const TELEGRAM_URL_REGEXP = /(?:https?:)?\/\/(?:t(?:elegram)?\.me|telegram\.org)\/([a-zA-Z0-9\_]{5,32})\/?/;
export const VIBER_REGEXP = /(viber\:\/\/)(pa)\?(chatURI=)([\w\d]+)?/;
export const VK_REGEXP = /(?:https:)?\/\/vk.me\/([a-zA-z0-9\_\.]+)?/;
export const WHATSAPP_REGEXP = /(?:https:)?\/\/wa.me\/([\w\d]+)?/;

@Component({
  selector: 'cq-branch-action-button[actionForm][targetBranchOptions][chatBotType]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class BranchActionButtonComponent implements OnInit {
  get actionForm(): BaseBotActionForm<BotButtonActionBodyJson> {
    return this._actionForm;
  }

  @Input()
  set actionForm(value: BaseBotActionForm<BotButtonActionBodyJson>) {
    this._actionForm = value;
    this._actionForm.controls.body.addValidators(this.getBodyValidator());
  }

  private _actionForm!: BaseBotActionForm<BotButtonActionBodyJson>; // Форма с действием

  @Input()
  targetBranchOptions!: Branch[]; // Массив с ветками

  @Input()
  chatBotType: CHAT_BOT_TYPE | null = null;

  @Input()
  chatBotId: string | null = null;

  @Output()
  buttonCreate: EventEmitter<void> = new EventEmitter(); // Callback на создание кнопки

  @Output()
  branchCreate: EventEmitter<BlockType> = new EventEmitter(); // Callback на создание ветки

  @Output()
  toBranch: EventEmitter<Branch | string> = new EventEmitter(); // Callback на переход в ветку

  @ViewChild(NgSelectComponent, { static: false })
  ngSelectComponent?: NgSelectComponent;

  BUTTON_TEXT_MAX_LENGTH = BUTTON_TEXT_MAX_LENGTH;

  hrefRegExpArray: RegExp[] = [];

  isIntegrationWithAuthLink: BehaviorSubject<INTEGRATION_TYPES | null> = new BehaviorSubject<INTEGRATION_TYPES | null>(
    null,
  );

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly destroy$: DestroyService,
    private readonly featureModel: FeatureModel,
    private readonly oneTimeFlagService: OneTimeFlagService,
    private readonly integrationService: IntegrationService,
  ) {}

  ngOnInit(): void {
    this.hrefRegExpArray = this.getHrefRegExpArray();

    const hrefValidators = [
      Validators.required,
      noWhitespaceValidator,
      (control: AbstractControl) => this.hrefValidator(control),
    ];

    this.enableUrlControl.valueChanges
      .pipe(takeUntil(this.destroy$), startWith(this.enableUrlControl.value))
      .subscribe((value: boolean) => {
        if (value) {
          this.hrefControl.addValidators(hrefValidators);
        } else {
          this.hrefControl.removeValidators(hrefValidators);
          this.hrefControl.setErrors(null);
        }
      });

    this.hrefControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (!value) {
        this.isIntegrationWithAuthLink.next(null);
        this.iconControl.setValue('cqi-external-link');
        return;
      }

      switch (true) {
        case FACEBOOK_REGEXP.test(value):
          this.isIntegrationWithAuthLink.next(INTEGRATION_TYPES.FACEBOOK);
          this.iconControl.setValue('cqi-facebook');
          break;
        case INSTAGRAM_REGEXP.test(value):
          this.isIntegrationWithAuthLink.next(null);
          this.iconControl.setValue('cqi-instagram');
          break;
        case MAILTO_REGEXP.test(value):
          this.isIntegrationWithAuthLink.next(null);
          this.iconControl.setValue('cqi-envelope-o');
          break;
        case PHONE_REGEXP.test(value):
          this.isIntegrationWithAuthLink.next(null);
          this.iconControl.setValue('cqi-phone');
          break;
        case TELEGRAM_URL_REGEXP.test(value):
          this.isIntegrationWithAuthLink.next(INTEGRATION_TYPES.TELEGRAM);
          this.iconControl.setValue('cqi-telegram');
          break;
        case VIBER_REGEXP.test(value):
          this.isIntegrationWithAuthLink.next(null);
          this.iconControl.setValue('cqi-viber');
          break;
        case VK_REGEXP.test(value):
          this.isIntegrationWithAuthLink.next(INTEGRATION_TYPES.VK);
          this.iconControl.setValue('cqi-vk');
          break;
        case WHATSAPP_REGEXP.test(value):
          this.isIntegrationWithAuthLink.next(INTEGRATION_TYPES.WHATS_APP_EDNA);
          this.iconControl.setValue('cqi-whatsapp');
          break;
        default:
          this.isIntegrationWithAuthLink.next(null);
          this.iconControl.setValue('cqi-external-link');
      }
    });
  }

  get enableUrlControl(): FormControl<boolean> {
    return this.actionForm.controls.bodyJson.controls.enableUrl;
  }

  get hasAccessToChatBotButtonLinkFeature(): boolean {
    return this.featureModel.hasAccess(FEATURES_ONLY.CHAT_BOT_BUTTON_LINK);
  }

  get hrefControl(): FormControl<string | null> {
    return this.actionForm.controls.bodyJson.controls.href;
  }

  get iconControl(): FormControl<BotButtonActionIcon> {
    return this.actionForm.controls.bodyJson.controls.icon;
  }

  get isTelegramBot(): boolean {
    return this.chatBotType === CHAT_BOT_TYPE.TELEGRAM;
  }

  get targetControl(): FormControl<CHAT_BOT_BUTTON_ACTION_URL_TARGET> {
    return this.actionForm.controls.bodyJson.controls.target;
  }

  changeUrlType(): void {
    this.targetControl.setValue(
      this.targetControl.value === CHAT_BOT_BUTTON_ACTION_URL_TARGET.BLANK
        ? CHAT_BOT_BUTTON_ACTION_URL_TARGET.TOP
        : CHAT_BOT_BUTTON_ACTION_URL_TARGET.BLANK,
    );
  }

  /** Для Facebook и Telegram бота макс. длина текста в кнопке 20 символов  */
  getBodyValidator() {
    if (this.chatBotType === CHAT_BOT_TYPE.FACEBOOK) {
      this.BUTTON_TEXT_MAX_LENGTH = 20;
    }
    if (this.chatBotType === CHAT_BOT_TYPE.TELEGRAM) {
      this.BUTTON_TEXT_MAX_LENGTH = 50;
    }
    return Validators.maxLength(this.BUTTON_TEXT_MAX_LENGTH);
  }

  /** Получение массива RegExp'ов для валидации ссылки по ним */
  getHrefRegExpArray() {
    const regExpForValidateArray = [
      FACEBOOK_REGEXP,
      INSTAGRAM_REGEXP,
      TELEGRAM_URL_REGEXP,
      VIBER_REGEXP,
      VK_REGEXP,
      WHATSAPP_REGEXP,
      URL_REGEXP,
    ];

    if (!this.isTelegramBot) {
      regExpForValidateArray.push(MAILTO_REGEXP, PHONE_REGEXP);
    }

    return regExpForValidateArray;
  }

  /**
   * Валидатор для ссылки
   *
   * @param control
   */
  hrefValidator(control: AbstractControl) {
    const isValid = this.hrefRegExpArray.some((regexp) => regexp.test(control.value));

    return !isValid ? { href: true } : null;
  }

  /** Получение переведённого названия типа интеграции */
  integrationTypeName(integrationType: INTEGRATION_TYPES): string {
    return this.integrationService.getTranslatedIntegrationType(integrationType);
  }

  /**
   * Записывает id выбранной ветки
   */
  onChangeBranch(branch: Branch): void {
    this._actionForm.nextBranchId = branch.id;
  }

  /** Обработчик клика по инпуту с вводом ссылки для кнопки */
  onClickHrefControl(): void {
    if (this.oneTimeFlagService.isSet(ONE_TIME_FLAGS.TRACK_CLICK_CHAT_BOT_BUTTON_URL_INPUT)) {
      return;
    }

    this.oneTimeFlagService.set(ONE_TIME_FLAGS.TRACK_CLICK_CHAT_BOT_BUTTON_URL_INPUT);
    this.trackClickHrefControl();
  }

  private trackClickHrefControl(): void {
    const eventName = 'Чат-бот - клик на поле ввода для ссылок';

    this.carrotquestHelper.track(eventName);
  }
}

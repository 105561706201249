import { TranslocoService } from '@jsverse/transloco';

import { CalendlyIntegrationForm } from '@http/integration/integrations/calendly/forms/calendly-form';

import { INTEGRATION_TYPES } from '../../constants/integration.constants';
import { SettingsIntegration } from '../../integration';
import {
  CalendlyIntegrationExternal,
  CalendlyIntegrationSettingsExternal,
} from './interfaces/calendly-integration.interface';

export class CalendlyIntegration extends SettingsIntegration<CalendlyIntegrationSettingsExternal> {
  /** Форма настройки интеграции */
  form: CalendlyIntegrationForm;
  /** Тип интеграции */
  type = INTEGRATION_TYPES.CALENDLY;
  /** Настройки интеграции */
  settings: CalendlyIntegrationSettingsExternal = {
    oauthAccessToken: null,
    oauthCsrfToken: null,
  };

  constructor(translocoService: TranslocoService, integrationExternal?: CalendlyIntegrationExternal) {
    super(integrationExternal);

    // Если интеграция создается из внешнего формата, обновим settings, иначе задаем дефолтные значения
    if (integrationExternal) {
      this.updateSettings(integrationExternal.settings);
    } else {
      this.active = true;

      const integrationTypeName = translocoService.translate(`models.integration.types.${this.type}.name`);
      this.name = translocoService.translate('models.integration.defaultValues.name', { integrationTypeName });
    }

    // Данные о настроенной интеграции собираются с формы и отправляются на backend
    this.form = new CalendlyIntegrationForm(this);
  }

  /**
   * Обновляет занчения формы настроек
   * @private
   */
  private updateFormBySettings() {
    this.form.controls.settings.patchValue(this.settings);
  }

  /**
   * Обновление настроек интегарции
   *
   * @param settings - Настройки интеграции во внешнем формате
   * @param updateFormSettings=false - Флаг для обновления формы настроек
   */
  updateSettings(settings: CalendlyIntegrationSettingsExternal, updateFormSettings: boolean = false): void {
    super.updateSettings(settings);

    if (updateFormSettings) {
      this.updateFormBySettings();
    }
  }

  get externalFormat(): CalendlyIntegrationExternal {
    return {
      ...this.baseExternalFormat,
      ...this.form.value,
    };
  }
}

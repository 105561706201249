(function () {
  'use strict';

  angular.module('myApp.integrations').constant('BITRIX24_INTEGRATION_STEPS', getBitrix24IntegrationSteps());

  function getBitrix24IntegrationSteps() {
    return {
      STEP_1: {
        ORDER: 0,
        TEMPLATE_URL: 'js/components/integrations/bitrix24/steps/step1.html',
        VALUE: 'step1',
      },
      STEP_2: {
        ORDER: 1,
        TEMPLATE_URL: 'js/components/integrations/bitrix24/steps/step2.html',
        VALUE: 'step2',
      },
      STEP_3: {
        ORDER: 2,
        TEMPLATE_URL: 'js/components/integrations/bitrix24/steps/step3.html',
        VALUE: 'step3',
      },
      STEP_4: {
        ORDER: 3,
        TEMPLATE_URL: 'js/components/integrations/bitrix24/steps/step4.html',
        VALUE: 'step4',
      },
      STEP_5: {
        ORDER: 4,
        TEMPLATE_URL: 'js/components/integrations/bitrix24/steps/step5.html',
        VALUE: 'step5',
      },
    };
  }
})();

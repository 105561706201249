import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Requisites } from '@http/requisites/requisites';

/** Компонент для работы с реквизитами компании */
@Component({
  selector: 'cq-requisites[requisites]',
  templateUrl: './requisites.component.html',
  styleUrls: ['./requisites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequisitesComponent {
  /** Реквизиты */
  @Input()
  requisites!: Requisites;

  /** Добавление реквизитов */
  @Output()
  addRequisites: EventEmitter<void> = new EventEmitter<void>();

  /** Редактирование реквизитов */
  @Output()
  editRequisites: EventEmitter<void> = new EventEmitter<void>();

  /** Обработчик добавления реквизитов */
  onAddRequisites(): void {
    this.addRequisites.emit();
  }

  /** Обработчик редактирования реквизитов */
  onEditRequisites(): void {
    this.editRequisites.emit();
  }
}

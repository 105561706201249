import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { BranchActionAppOfflineConditionComponent } from '@panel/app/pages/chat-bot/content/branch-editor/actions/app-offline-condition/app-offline-condition.component';
import { BotActionNextBranchSelectorModule } from '@panel/app/pages/chat-bot/content/branch-editor/partials/bot-action-next-branch-selector/bot-action-next-branch-selector.module';

@NgModule({
  declarations: [BranchActionAppOfflineConditionComponent],
  imports: [CommonModule, TranslocoModule, NgbPopoverModule, BotActionNextBranchSelectorModule, ReactiveFormsModule],
  exports: [BranchActionAppOfflineConditionComponent],
})
export class BranchActionAppOfflineConditionModule {}

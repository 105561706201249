import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { UserNoteComponent } from './user-note.component';

@NgModule({
  declarations: [UserNoteComponent],
  exports: [UserNoteComponent],
  imports: [CommonModule, TranslocoModule],
})
export class UserNoteModule {}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { generate } from 'short-uuid';

import { AppService } from '@http/app/services/app.service';
import { UserTag } from '@http/user/types/user.type';
import { AiDataBotSettingsStateService } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings-state.service';
import { ModalHelperService } from '@panel/app/services';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';

@Component({
  selector: 'cq-user-tag-control',
  templateUrl: './user-tag-control.component.html',
  styleUrls: ['./user-tag-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTagControlComponent extends AbsCVAFormControlBasedComponent<string | null> {
  readonly control: FormControl<string | null> = new FormControl<string | null>(null, {
    nonNullable: false,
  });

  constructor(
    private readonly appService: AppService,
    private readonly modalHelperService: ModalHelperService,
    public readonly state: AiDataBotSettingsStateService,
    private readonly translocoService: TranslocoService,
  ) {
    super();
  }

  /**
   * Открытие модального окна для добавления нового тега
   */
  openAddTagModal() {
    const addTagModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        confirmButtonText: this.translocoService.translate('userTagControlComponent.addTagModal.confirmButtonText'),
        heading: this.translocoService.translate('userTagControlComponent.addTagModal.heading'),
        inputErrorText: this.translocoService.translate('userTagControlComponent.addTagModal.inputErrorText'),
        inputLabel: this.translocoService.translate('userTagControlComponent.addTagModal.inputLabel'),
        inputPlaceholder: this.translocoService.translate('userTagControlComponent.addTagModal.inputPlaceholder'),
      })
      .open(PromptModalComponent);

    addTagModal.result.then(this.addTag.bind(this)).catch(() => {});
  }

  /**
   * Добавление нового тега
   *
   * @param tagName - Имя тега
   */
  addTag(tagName: UserTag['name']) {
    const newTag: UserTag = {
      id: generate(),
      name: tagName,
      removed: null,
      app: this.appService.currentAppId,
    };

    // при добавлении нового тега надо проверить не существует ли добавляемый тег
    const existingTagByName = this.state.tags$.getValue().filter((tag) => tag.name === tagName)[0];

    if (existingTagByName) {
      this.control.setValue(existingTagByName.name);
    } else {
      this.control.setValue(newTag.name);
      this.state.tags$.next([...this.state.tags$.getValue(), newTag]);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';

@Pipe({
  name: 'tcConnectionPointOverlayOptionsFilter',
})
export class TcConnectionPointOverlayOptionsFilterPipe implements PipeTransform {
  transform(
    options: readonly TRIGGER_CHAIN_BLOCK_TYPE[],
    sourceType: INTERACTIVE_BLOCK_PART,
  ): TRIGGER_CHAIN_BLOCK_TYPE[] {
    let allowedNextBlocks: TRIGGER_CHAIN_BLOCK_TYPE[];

    switch (sourceType) {
      case INTERACTIVE_BLOCK_PART.AUTOMESSAGE_SENT:
        allowedNextBlocks = [
          TRIGGER_CHAIN_BLOCK_TYPE.REACTION,
          TRIGGER_CHAIN_BLOCK_TYPE.DELAY,
          TRIGGER_CHAIN_BLOCK_TYPE.FILTER,
          TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS,
          TRIGGER_CHAIN_BLOCK_TYPE.EXIT,
        ];
        break;
      case INTERACTIVE_BLOCK_PART.DELAY:
        allowedNextBlocks = [
          TRIGGER_CHAIN_BLOCK_TYPE.EMAIL,
          TRIGGER_CHAIN_BLOCK_TYPE.POPUP,
          TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE,
          TRIGGER_CHAIN_BLOCK_TYPE.CHAT,
          TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK,
          TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH,
          TRIGGER_CHAIN_BLOCK_TYPE.FILTER,
          TRIGGER_CHAIN_BLOCK_TYPE.REACTION,
        ];
        break;
      case INTERACTIVE_BLOCK_PART.DELAY_PASSED:
        allowedNextBlocks = [
          TRIGGER_CHAIN_BLOCK_TYPE.EMAIL,
          TRIGGER_CHAIN_BLOCK_TYPE.POPUP,
          TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE,
          TRIGGER_CHAIN_BLOCK_TYPE.CHAT,
          TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK,
          TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH,
          TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS,
          TRIGGER_CHAIN_BLOCK_TYPE.EXIT,
        ];
        break;
      case INTERACTIVE_BLOCK_PART.FILTER_MATCH:
      case INTERACTIVE_BLOCK_PART.FILTER_NOT_MATCH:
        allowedNextBlocks = [
          TRIGGER_CHAIN_BLOCK_TYPE.EMAIL,
          TRIGGER_CHAIN_BLOCK_TYPE.POPUP,
          TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE,
          TRIGGER_CHAIN_BLOCK_TYPE.CHAT,
          TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK,
          TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH,
          TRIGGER_CHAIN_BLOCK_TYPE.DELAY,
          TRIGGER_CHAIN_BLOCK_TYPE.REACTION,
          TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS,
          TRIGGER_CHAIN_BLOCK_TYPE.EXIT,
        ];
        break;
      case INTERACTIVE_BLOCK_PART.REACTION:
      case INTERACTIVE_BLOCK_PART.NO_REACTION:
        allowedNextBlocks = [
          TRIGGER_CHAIN_BLOCK_TYPE.EMAIL,
          TRIGGER_CHAIN_BLOCK_TYPE.POPUP,
          TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE,
          TRIGGER_CHAIN_BLOCK_TYPE.CHAT,
          TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK,
          TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH,
          TRIGGER_CHAIN_BLOCK_TYPE.DELAY,
          TRIGGER_CHAIN_BLOCK_TYPE.FILTER,
          TRIGGER_CHAIN_BLOCK_TYPE.EXIT_SUCCESS,
          TRIGGER_CHAIN_BLOCK_TYPE.EXIT,
        ];
        break;
      case INTERACTIVE_BLOCK_PART.SENDING_CONDITION_TRIGGERS:
        allowedNextBlocks = [
          TRIGGER_CHAIN_BLOCK_TYPE.EMAIL,
          TRIGGER_CHAIN_BLOCK_TYPE.POPUP,
          TRIGGER_CHAIN_BLOCK_TYPE.TELEGRAM_MESSAGE,
          TRIGGER_CHAIN_BLOCK_TYPE.CHAT,
          TRIGGER_CHAIN_BLOCK_TYPE.WEBHOOK,
          TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH,
        ];
        break;
    }

    return options.filter((option) => allowedNextBlocks.includes(option));
  }
}

<ng-container *transloco="let t; read: 'nameComponent'">
  <ng-container
    *tuiLet="{
      billing: subscriptionStore.billing$ | async,
      currentApp: subscriptionStore.app$ | async,
      plan: subscriptionStore.plan$ | async
    } as values"
  >
    <ng-container *ngIf="values.billing && values.currentApp && values.plan">
      <div class="d-flex justify-content-between align-items-end">
        <div class="overflow-hidden">
          <span>
            <small class="text-secondary">{{ t('title') }}</small>
            <i
              *ngIf="values.plan.isArchive"
              class="cqi-sm cqi-info text-warning cursor-pointer ml-5"
              [ngbTooltip]="t('tooltip')"
            ></i>
          </span>

          <h4
            class="mb-0 text-truncate pr-20 font-weight-bold"
            [title]="values.plan.name"
          >
            {{ values.plan.name }}
          </h4>
        </div>

        <ng-container *ngIf="isShowSubscribeIntent(values.billing.subscription)">
          <cq-subscribe-intent (subscribeIntent)="onSubscribeIntent()"></cq-subscribe-intent>
        </ng-container>

        <ng-container *ngIf="!isShowSubscribeIntent(values.billing.subscription)">
          <div
            ngbDropdown
            placement="bottom-end"
          >
            <button
              class="btn btn-secondary"
              ngbDropdownToggle
              type="button"
            >
              {{ t('actions.title') }}
            </button>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                type="button"
                (click)="onChangePlan()"
              >
                {{ t('actions.change') }}
              </button>

              <ng-container
                *ngIf="
                  values.billing.subscription.isActive && !values.billing.subscription.isCancelledInEndBillingPeriod()
                "
              >
                <button
                  class="text-danger"
                  ngbDropdownItem
                  type="button"
                  (click)="onCancelSubscription()"
                >
                  {{ t('actions.cancel') }}
                </button>
              </ng-container>

              <ng-container *ngIf="values.billing.subscription.isCancelled">
                <button
                  class="text-success"
                  ngbDropdownItem
                  type="button"
                  (click)="onRenewSubscription(values.plan.name)"
                >
                  {{ t('actions.renew') }}
                </button>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

import { BILLING_ADD_ONS, UNIQUE_BILLING_PLAN_ID } from '@panel/app/services/billing-info/billing-info.constants';

export const V3_ADDONS_RU = [
  BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING,
  BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING_V0224,
  BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING_V0224,
  BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING_V0224,
  BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING_V0224,
  BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING_V0224,
  BILLING_ADD_ONS.BEE_EDITOR_V2,
  BILLING_ADD_ONS.SECURITY,
  BILLING_ADD_ONS.TELEGRAM_BOT,
];

export const V3_ADDONS_US = [
  BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING,
  BILLING_ADD_ONS.OPTIMIZATION_SUPPORT_UNITS_PRICING,
  BILLING_ADD_ONS.CHAT_BOTS_LIMIT_EXTENSION_UNITS_PRICING,
  BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING,
  BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING,
  BILLING_ADD_ONS.TRIGGER_MESSAGES_LIMIT_EXTENSION_UNITS_PRICING,
  BILLING_ADD_ONS.BEE_EDITOR_V2,
  BILLING_ADD_ONS.SECURITY,
  BILLING_ADD_ONS.TELEGRAM_BOT,
];

export const V3_ADDONS: Record<'RU' | 'US', BILLING_ADD_ONS[]> = {
  RU: V3_ADDONS_RU,
  US: V3_ADDONS_US,
};

export const V3_PLANS = [
  UNIQUE_BILLING_PLAN_ID.CONVERSATION,
  UNIQUE_BILLING_PLAN_ID.SUPPORT,
  UNIQUE_BILLING_PLAN_ID.MARKETING,
] as const;

export type V3_PLAN = typeof V3_PLANS[number];

type Util<K extends 'available' | 'not-available' | 'included', V extends boolean | null> = {
  readonly availability: K;
  value: V;
};

export type AddonAvailabilityPerPlan = {
  [PLAN in V3_PLAN]: Util<'available', boolean> | Util<'not-available' | 'included', null>;
};

export type AddonsConfiguration = {
  [ADDON in BILLING_ADD_ONS]?: AddonAvailabilityPerPlan;
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Component({
  selector: 'cq-quiz-skip-confirm-modal',
  templateUrl: './quiz-skip-confirm-modal.component.html',
  styleUrls: ['./quiz-skip-confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizSkipConfirmModalComponent {
  @Input()
  ajsModalInstance?: any;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly $uibModal: UibModalService,
    private readonly ngbActiveModal: NgbActiveModal,
  ) {}

  closeModal() {
    this.ajsModalInstance ? this.ajsModalInstance.close() : this.ngbActiveModal.close();
  }

  skipQuizConfirm() {
    this.trackSkipQuiz();
    this.$uibModal.dismissAll();
  }

  trackSkipQuiz() {
    this.carrotquestHelper.track('Онбординг - квиз для простого запуска пропущен');
  }
}

(function () {
  'use strict';

  require('./mytarget-oauth.controller');

  angular.module('myApp.integrations').component('cqMyTargetOAuth', cqMyTargetOAuth());

  function cqMyTargetOAuth() {
    return {
      controller: 'CqMyTargetOAuthController',
      controllerAs: 'vm',
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TuiLetModule } from '@taiga-ui/cdk';
import { MomentModule } from 'ngx-moment';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { TopUpBalanceModalComponent } from '@panel/app/pages/subscription/general/signed/balance/top-up-balance-modal/top-up-balance-modal.component';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { PipesModule } from '@panel/app/shared/pipes/pipes.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [TopUpBalanceModalComponent],
  imports: [CommonModule, MomentModule, TuiLetModule, TranslocoModule, ReactiveFormsModule, PipesModule, SharedModule],
  providers: [CarrotquestHelperProvider, NgbActiveModal, SubscriptionStore],
})
export class TopUpBalanceModalModule {}

<ng-container *transloco="let t; read: 'starterGuideLeadsPassingComponent'">
  <ngb-alert
    *ngIf="isStepMade"
    class="margin-bottom-30"
    [dismissible]="false"
    type="success"
    data-test="starter-guide-leads-passing-step-made-alert"
  >
    <i class="cqi-sm cqi-check-circle margin-right-10"></i>
    <span>{{ t('stepPassedAlert') }}</span>
  </ngb-alert>
  <div
    class="mb-20"
    [innerHTML]="description"
  ></div>
  <div class="d-flex margin-between-cols-20">
    <div
      class="card mb-0 flex-grow w-100 cursor-pointer"
      (click)="onClickIntegrationPreview(integration)"
      *ngFor="let integration of displayedIntegrations"
      data-test="starter-guide-leads-passing-integration"
    >
      <div class="card-body text-center">
        <img
          *ngIf="integration === STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES.LEAD_BOT; else integrationIcon"
          class="mx-auto mb-10"
          src="assets/img/default/starter-guide/leads-passing/inbox.svg"
        />
        <ng-template #integrationIcon>
          <cq-integration-icon
            class="integration-icon mx-auto mb-10"
            [type]="$any(integration)"
          ></cq-integration-icon>
        </ng-template>
        <div>
          <span>{{ getIntegrationTypeNameTranslate(integration) }}</span>
          <cq-plan-capability-coin *ngIf="!getAccessToIntegrationType(integration).hasAccess"></cq-plan-capability-coin>
        </div>
      </div>
    </div>
  </div>
</ng-container>

(function () {
  'use strict';

  require('./starter-guide-installation-service.controller');

  angular
    .module('myApp.starterGuide')
    .component('cqStarterGuideInstallationService', cqStarterGuideInstallationService());

  function cqStarterGuideInstallationService() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        isStepMade: '=', // Пройденность шага
      },
      controller: 'CqStarterGuideInstallationServiceController',
      controllerAs: 'vm',
      template: require('./starter-guide-installation-service.html'),
    };
  }
})();

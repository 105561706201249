<ng-container *transloco="let t; read: 'articlePreviewModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ 'articlePreviewModal.heading' | transloco }}</h3>
    <button
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body custom-scroll">
    <cq-article-preview
      [content]="modalWindowParams.content"
      [description]="modalWindowParams.description"
      [name]="modalWindowParams.name"
    ></cq-article-preview>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      (click)="activeModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <button
      class="btn btn-primary"
      (click)="activeModal.close()"
    >
      {{ 'articlePreviewModal.sendButton' | transloco }}
    </button>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'cq-message-name-editor[messageName]',
  templateUrl: './message-name-editor.component.html',
  styleUrls: ['./message-name-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageNameEditorComponent {
  readonly MESSAGE_NAME_MAX_LENGTH = 255;

  readonly formControl: FormControl = new FormControl('', {
    validators: [Validators.required, Validators.maxLength(this.MESSAGE_NAME_MAX_LENGTH)],
    nonNullable: true,
  });

  @Input()
  set messageName(value: string) {
    this.formControl.setValue(value);
  }

  @Output()
  messageNameChange = this.formControl.valueChanges.pipe(distinctUntilChanged());

  isMessageNameInputFocused = false;
}

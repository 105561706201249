import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { DjangoUserService } from '@http/django-user/services/django-user.service';
import { DeleteImpossibleError } from '@http/django-user/types/django-user.types';
import { APIError } from '@http/types';
import { DeleteDjangoUserModalComponent } from '@panel/app/partials/modals/delete-django-user-modal/delete-django-user-modal.component';
import { makeSequenceFromArray } from '@panel/app/shared/functions/make-sequence-from-array.function';

@Component({
  selector: 'cq-delete-django-user',
  templateUrl: './delete-django-user.component.html',
  styleUrls: ['./delete-django-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDjangoUserComponent {
  /**
   * Список аппов, которые препятствуют удалению django-пользователя
   */
  forbiddenApps: string[] = [];

  /**
   * Перечисление названий аппов, препятствующих удалению django-пользователя
   */
  forbiddenAppNames = '';

  /**
   * Флаг выполнения проверки возможности удаления в данный момент
   */
  isCheckDeleteRequestPerformed = false;

  /**
   * Инстанс тултипа с ошибкой
   */
  @ViewChild('tooltip')
  tooltip!: NgbTooltip;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly djangoUserService: DjangoUserService,
    private readonly ngbModal: NgbModal,
    private readonly transloco: TranslocoService,
  ) {}

  /**
   * Открытие модалки удаления django-пользователя
   */
  openDeleteDjangoUserModal() {
    this.isCheckDeleteRequestPerformed = true;

    // перед открытием модалки нужно проверить, можно ли вообще удалять текущего django-пользователя
    from(this.djangoUserService.checkDeletePossibility())
      .pipe(
        catchError((error: APIError<'DjangoUserForbiddenApps', DeleteImpossibleError>) => {
          // на самом деле, тут могут быть и другие ошибки, например, при отсутствии интернета
          // но из-за плохо написанного интерсептора ErrorResponseInterceptor предсказать структуру ошибки невозможно
          // поэтому пришлось при выставлении типа APIError всё равно писать error?.error
          if (error?.error === 'DjangoUserForbiddenApps') {
            this.forbiddenApps = error.errorMessage.forbiddenApps.map((app) => app.name);
            this.forbiddenAppNames = makeSequenceFromArray(this.forbiddenApps);
            this.tooltip.open();
          } else {
            this.tooltip.close();
          }

          throw error;
        }),
        finalize(() => {
          this.isCheckDeleteRequestPerformed = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe(() => {
        this.tooltip.close();
        this.ngbModal.open(DeleteDjangoUserModalComponent, { centered: true });
      });
  }
}

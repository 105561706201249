(function () {
  'use strict';

  angular.module('myApp.completionRegistration', []);

  require('./completion-registration.routes');

  require('./completion-registration.component');
  require('./form/completion-registration-form.component');
  require('./form/control-app-url/completion-registration-form-control-app-url.component');
  require('./form/control-name/completion-registration-form-control-name.component');
  require('./form/control-phone/completion-registration-form-control-phone.component');
  require('./header/completion-registration-header.component');
})();

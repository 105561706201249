/**
 * Контроллер для модального окна отложенной рассылки ручного сообщения
 *
 * При создании модального окна в resolve можно передать modalWindowParams - объект следующего вида:
 *    {String} appID - ID аппа
 *    {String} appName - имя аппа
 *    {String} appTimezone - Таймзона аппа
 *    {Number} appTimezoneOffset - Смещение времени
 *    {Object} currentMessage - Сообщение
 *    {Object} messageParams - Параметры сообщения
 *    {Object} pushSettings - Настройки пушей
 */
import { firstValueFrom } from 'rxjs';
import { MESSAGE_DELETING_TYPES } from '../../../../app/http/message/message.constants';

import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.modals.messagePlanning').controller('CqMessagePlanningController', CqMessagePlanningController);

  function CqMessagePlanningController(
    $filter,
    $interval,
    $q,
    $scope,
    $state,
    $translate,
    moment,
    toastr,
    PROJECT_NAME,
    TIME_UNITS,
    TIME_UNIT_MEASURES,
    USER_FILES_URL,
    carrotquestHelper,
    caseStyleHelper,
    messageModel,
    messagePartModel,
    popupBlockModel,
    timeUnitService,
    validationHelper,
  ) {
    var vm = this;

    var DELETE_MESSAGE_MAX_INTERVAL = TIME_UNIT_MEASURES[TIME_UNITS.MONTH] * 3; // максимальный промежуток в течении которого будет скрыто сообщение из истории диалогов
    var DELETE_MESSAGE_MIN_INTERVAL = TIME_UNIT_MEASURES[TIME_UNITS.SECOND] * 30; // минимальный промежуток в течении которого будет скрыто сообщение из истории диалогов
    var interval; // $interval для обновления времени

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.now = vm.resolve.currentTimestamp.utcOffset(vm.resolve.appTimezoneOffset); // Текущее время. Не по алфавиту т.к. надо определить раньше, для присваивания в minDate для datepickerOptions
      vm.maxTime = moment(vm.now).add(DELETE_MESSAGE_MAX_INTERVAL / TIME_UNIT_MEASURES[TIME_UNITS.MONTH], 'months'); // максимальное время, которое можно выбрать для удаления сообщения

      vm.DELETE_MESSAGE_MAX_INTERVAL = DELETE_MESSAGE_MAX_INTERVAL;
      vm.DELETE_MESSAGE_MIN_INTERVAL = DELETE_MESSAGE_MIN_INTERVAL;
      vm.deletableMessagePart = messagePartModel.isDeletableType(vm.resolve.currentMessage.type); // доступны ли для сообщения настройки удаления
      vm.deleteMessage = {
        deleteType: MESSAGE_DELETING_TYPES.TIME_INTERVAL,
        enabled: false,
        interval: {
          // Параметры для удаления через промежуток времени
          value: 24 * TIME_UNIT_MEASURES[TIME_UNITS.HOUR],
          unit: TIME_UNITS.HOUR,
          units: [TIME_UNITS.SECOND, TIME_UNITS.MINUTE, TIME_UNITS.HOUR, TIME_UNITS.DAY, TIME_UNITS.MONTH],
        },
        time: {
          // параметры для удаления в конкретное время
          date: moment(vm.now).add(1, 'days'), // дата для датапикера
          datepickerOptions: {
            // опции для датапикера
            singleDatePicker: true,
            maxDate: vm.maxTime,
            minDate: vm.now,
          },
          hours: vm.now.hours(), // часы
          minutes: vm.now.minutes(), // минуты
          time: moment(vm.now).add(1, 'days'), // время удаления сообщения (дата + часы + минуты)
          timeError: false, // Флаг ошибки с прошедшим временем удаления
        },
      };
      vm.deleteMessageForm = null;
      vm.errors = {
        deleteBeforeSend: false, // Когда настроил удаление раньше отправки
        deleteEqToSend: false, // Когда время отправки равно времени удаления сообщения
      };
      vm.isMessageSendingPerformed = false; // флаг выполнения запроса
      vm.MAX_HOURS = 23; // Максимально количество часов
      vm.MAX_MINUTES = 59; // Максимальное количество минут
      vm.MESSAGE_DELETING_TYPES = MESSAGE_DELETING_TYPES;
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.MIN_TIME = 0; // Минимальное число для времени (часы, минуты)
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.scheduleMessage = {
        // Отправка в заданное время
        date: moment(vm.now).add(2, 'hours'), // дата для датапикера
        datepickerOptions: {
          // Датапикер
          singleDatePicker: true,
          minDate: vm.now,
        },
        enabled: false,
        hours: moment(vm.now).add(2, 'hours').hours(), // часы
        minutes: vm.now.minutes(), // минуты
        time: moment(vm.now).add(2, 'hours'), // запланированное время отправки сообщения (дата + часы + минуты)
        timeError: false, // Флаг ошибки с прошедшим временем отправки
      };
      vm.scheduleMessageForm = null;
      vm.sendManualMessageWithParams = sendManualMessageWithParams;
      vm.TIME_UNIT_MEASURES = TIME_UNIT_MEASURES;
      vm.timeUnitService = timeUnitService;
      vm.validationHelper = validationHelper;

      $scope.$watchCollection(
        '[vm.deleteMessage.time.date, vm.deleteMessage.time.hours, vm.deleteMessage.time.minutes]',
        setDeleteMessageTime,
      );
      $scope.$watchCollection(
        '[vm.scheduleMessage.date, vm.scheduleMessage.hours, vm.scheduleMessage.minutes]',
        setScheduleMessageTime,
      );

      // если у сообщения тип такой, что его нельзя удалить, то активируем возможность планирования сообщения
      if (!vm.deletableMessagePart) {
        vm.scheduleMessage.enabled = true;
      }

      /**
       * Соединение даты, часов и минут в одну дату типа moment
       *
       * @param {moment} date Дата
       * @param {Number} hours Часы
       * @param {Number} minutes Минуты
       * @returns {moment}
       */
      function mergeTimeValues(date, hours, minutes) {
        var mergedTime;

        // если дата undefined, то считается, что она не прошла валидацию, поэтому берём текущую дату
        if (angular.isDefined(date)) {
          mergedTime = moment(date);
        } else {
          mergedTime = vm.resolve.currentTimestamp;
        }

        // то же самое с часами
        if (angular.isDefined(hours)) {
          mergedTime.hours(hours);
        } else {
          mergedTime.hours(0);
        }

        // то же самое с минутами
        if (angular.isDefined(minutes)) {
          mergedTime.minutes(minutes);
        } else {
          mergedTime.minutes(0);
        }

        mergedTime.utcOffset(vm.resolve.appTimezoneOffset, true);
        mergedTime.seconds(0); // секунды выставляем в 0. Их всё равно никак нельзя задать, поэтому делаем это для порядочка

        console.log(mergedTime.format());

        return mergedTime;
      }

      /**
       * Установка времени удаления сообщения
       *
       * @param newValue
       */
      function setDeleteMessageTime(newValue) {
        var date = newValue[0];
        var hours = newValue[1];
        var minutes = newValue[2];

        vm.deleteMessage.time.time = mergeTimeValues(date, hours, minutes);
        vm.deleteMessageForm && vm.deleteMessageForm.$setUntouched() && vm.deleteMessageForm.$setUntouched();
        vm.deleteMessage.time.timeError = false;
      }

      /**
       * Установка времени запланированной отправки сообщения
       *
       * @param newValue
       */
      function setScheduleMessageTime(newValue) {
        var date = newValue[0];
        var hours = newValue[1];
        var minutes = newValue[2];

        vm.scheduleMessage.time = mergeTimeValues(date, hours, minutes);
        vm.scheduleMessageForm && vm.scheduleMessageForm.$setPristine() && vm.scheduleMessageForm.$setUntouched();
        vm.scheduleMessage.timeError = false;
      }
    }

    /**
     * Отменяет interval
     */
    function destroy() {
      $interval.cancel(interval);
    }

    /**
     * Обработка нажатия на кнопку подтверждения
     *
     * @param {Boolean} isValid - Валидация общей формы с настройкой отправки сообщения
     */
    function sendManualMessageWithParams(isValid) {
      // FIXME валидация должна проводиться снаружи, при помощи директив
      if (isValid && !vm.isMessageSendingPerformed) {
        vm.errors.deleteBeforeSend = false;
        vm.errors.deleteEqToSend = false;
        vm.deleteMessage.time.timeError = false;
        vm.scheduleMessage.timeError = false;

        if (
          vm.scheduleMessage.enabled &&
          vm.deleteMessage.enabled &&
          vm.deleteMessage.deleteType === MESSAGE_DELETING_TYPES.CERTAIN_DATE
        ) {
          if (vm.deleteMessage.time.time.isBefore(vm.scheduleMessage.time, 'minutes')) {
            vm.errors.deleteBeforeSend = true;
            return;
          }

          if (vm.deleteMessage.time.time.isSame(vm.scheduleMessage.time, 'minutes')) {
            vm.errors.deleteEqToSend = true;
            return;
          }
        }

        vm.isMessageSendingPerformed = true;

        uploadPushIcon().then(saveAdditionalData).then(sendManualMessage).finally(scheduleMessageFinally);
      }

      // HACK: Быстрый фикс-копипаста, раньше вообще не загружались никакие данные при планировании рассылки
      function saveAdditionalData() {
        // HACK FIXME: дальше идёт говнокод, при помощи которого грузятся картинки и создаются события в блочных поп-апах
        var preprocessingTasks = [];

        if (
          vm.resolve.currentMessage.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          vm.resolve.currentMessage.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
        ) {
          var blockPopup = vm.resolve.currentMessage[vm.resolve.currentMessage.type];
          var popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

          blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

          for (var j = 0; j < popupBlocks.length; j++) {
            var popupBlock = popupBlocks[j];

            preprocessingTasks.push(popupBlockModel.saveAdditionalData(vm.resolve.appID, popupBlock));
          }
        } else if (vm.resolve.currentMessage.type === MESSAGE_PART_TYPES.TELEGRAM) {
          for (let content of vm.resolve.currentMessage[vm.resolve.currentMessage.type].bodyJson.contents) {
            preprocessingTasks.push(messagePartModel.saveAdditionalDataForTelegramMessage(content));
          }
        }

        preprocessingTasks.push(messagePartModel.saveAdditionalData(vm.resolve.currentMessage));

        return $q.all(preprocessingTasks);
      }

      function scheduleMessageFinally() {
        vm.isMessageSendingPerformed = false;
      }

      function sendManualMessage() {
        return firstValueFrom(
          messageModel.sendManualMessage(
            vm.resolve.appID,
            vm.resolve.messageParams,
            vm.resolve.currentMessage,
            vm.scheduleMessage.enabled ? vm.scheduleMessage.time : undefined,
            vm.deleteMessage.enabled ? getDeleteMessageTime() : undefined,
          ),
        ).then(plannedSuccess, plannedError);

        /**
         * Возвращает промежуток времени или дату в соответствии с выбранным типом удаления сообщения
         *
         * @return {Number}
         */
        function getDeleteMessageTime() {
          if (vm.deleteMessage.deleteType === MESSAGE_DELETING_TYPES.CERTAIN_DATE) {
            return vm.deleteMessage.time.time;
          } else if (vm.deleteMessage.deleteType === MESSAGE_DELETING_TYPES.TIME_INTERVAL) {
            return vm.deleteMessage.interval.value;
          }
        }

        function plannedError(response) {
          if (response.meta.error_fields.delivery_time) {
            vm.scheduleMessage.timeError = true;
          }

          if (response.meta.error_fields.expiration_time) {
            vm.deleteMessage.time.timeError = true;
          }
        }

        function plannedSuccess() {
          if (vm.scheduleMessage.enabled) {
            trackMessagePlanning();
            toastr.success(
              $translate.instant('modals.messagePlanning.toasts.messagePlanned', {
                url: $state.href('app.content.messagesAjs.manual'),
              }),
              { allowHtml: true },
            );
          } else {
            trackMessageSent();
            toastr.success($translate.instant('modals.messagePlanning.toasts.messageSent'));
          }

          vm.modalInstance.close();
        }
      }

      /**
       * Трек отправки запланированного ручного сообщения
       */
      function trackMessagePlanning() {
        carrotquestHelper.track('Отправка сообщения - запланировал отправку', {
          App: vm.resolve.appName,
          Тип: vm.resolve.currentMessage.type,
        });
      }

      /**
       * Трек отправки ручного сообщения
       */
      function trackMessageSent() {
        carrotquestHelper.track('Отправка сообщения - отправил сообщение', {
          App: vm.resolve.appName,
          Тип: vm.resolve.currentMessage.type,
        });

        /* HACK: У Яглы постоянно падают рассылки. Это событие для генерации сообщения в Slack */
        if (vm.resolve.appID == 2805 && vm.resolve.currentMessage.type == MESSAGE_PART_TYPES.EMAIL) {
          carrotquestHelper.track('Yagla начала рассылку');
        }
      }

      function uploadPushIcon() {
        if (
          vm.resolve.currentMessage.type == MESSAGE_PART_TYPES.PUSH &&
          vm.resolve.currentMessage[MESSAGE_PART_TYPES.PUSH].newIcon
        ) {
          return firstValueFrom(
            messageModel.uploadPushIcon(vm.resolve.appID, vm.resolve.currentMessage[MESSAGE_PART_TYPES.PUSH].newIcon),
          ).then(setIcon);
        } else {
          vm.resolve.currentMessage.icon = vm.resolve.pushSettings.icon;
          return $q.resolve();
        }

        function setIcon(iconUrls) {
          vm.resolve.currentMessage[MESSAGE_PART_TYPES.PUSH].newIconUrl =
            USER_FILES_URL + '/push-icons/' + iconUrls.filename;
        }
      }
    }
  }
})();

<ng-container *ngIf="inWrapper">
  <ng-container *ngIf="messagePartType === MESSAGE_PART_TYPES.POPUP_CHAT">
    <cq-message-preview-browser-wrapper>
      <cq-message-preview-popup-chat
        [app]="app"
        [content]="prepareMessagePartContent(messagePartType, content)"
        [language]="language"
        [sender]="sender"
      ></cq-message-preview-popup-chat>
    </cq-message-preview-browser-wrapper>
  </ng-container>

  <ng-container *ngIf="messagePartType === MESSAGE_PART_TYPES.SDK_POPUP_CHAT">
    <cq-message-preview-phone-bottom-wrapper>
      <cq-message-preview-sdk-popup-chat
        [app]="app"
        [content]="prepareMessagePartContent(messagePartType, content)"
        [language]="language"
        [sender]="sender"
      ></cq-message-preview-sdk-popup-chat>
    </cq-message-preview-phone-bottom-wrapper>
  </ng-container>
</ng-container>

<ng-container *ngIf="!inWrapper">
  <ng-container *ngIf="messagePartType === MESSAGE_PART_TYPES.POPUP_CHAT">
    <cq-message-preview-popup-chat
      [app]="app"
      [content]="prepareMessagePartContent(messagePartType, content)"
      [language]="language"
      [sender]="sender"
    ></cq-message-preview-popup-chat>
  </ng-container>
</ng-container>

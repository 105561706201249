(function () {
  'use strict';

  require('./popup-input-with-big-button-block-settings.controller');

  angular
    .module('myApp.popupEditor')
    .component('cqPopupInputWithBigButtonBlockSettings', cqPopupInputWithBigButtonBlockSettings());

  function cqPopupInputWithBigButtonBlockSettings() {
    return {
      bindings: {
        currentApp: '=', // текущий app
        eventTypes: '=', // типы событий
        messagePartType: '=', // Тип сообщения
        popupBlockParams: '=', // настройки блока поп-апа
        setErrorHandler: '&?', // функция для установки обработчика ошибок
        userProperties: '=', // свойства пользователя
      },
      controller: 'CqPopupInputWithBigButtonBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-input-with-big-button-block-settings.html'),
    };
  }
})();

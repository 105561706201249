import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppService } from '@http/app/services/app.service';
import { WHATS_APP_TEMPLATE_STATUS } from '@http/whats-app-template/constnats/whats-app-templates.constants';
import { ApiGetListResponse, WhatsAppEdnaTemplate } from '@http/whats-app-template/types/whats-app-templates.types';

/** Сервис для работы с шаблоны WhatsApp */
@Injectable({
  providedIn: 'root',
})
export class WhatsAppTemplateService {
  constructor(private readonly appService: AppService, private readonly http: HttpClient) {}

  /**
   * Получение списка шаблонов WhatsApp для конкретной интеграции
   *
   * @param integrationId ID интеграции
   */
  getList(integrationId: string): Observable<WhatsAppEdnaTemplate[]> {
    const params = { app: this.appService.currentAppId };
    return this.http.get<ApiGetListResponse>(`/whatsapp/edna/${integrationId}/templates/`, { params }).pipe(
      map((response) => response.result),
      map((templates: WhatsAppEdnaTemplate[]) =>
        templates.filter((template) => template.status === WHATS_APP_TEMPLATE_STATUS.APPROVED),
      ),
    );
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { generate } from 'short-uuid';

import { App } from '@http/app/app.model';
import { Tag } from '@http/tag/tag.types';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { ModalHelperService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';

@Component({
  selector: 'cq-branch-action-user-tag[actionForm][currentApp]',
  templateUrl: './user-tag.component.html',
  styleUrls: ['./user-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionUserTagComponent implements OnInit {
  @Input()
  actionForm!: BaseBotActionForm;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Доступ до тегов пользователей */
  accessToUsersTags: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  constructor(
    private readonly modalHelperService: ModalHelperService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly translocoService: TranslocoService,
    public readonly state: StateService,
  ) {}

  ngOnInit() {
    this.accessToUsersTags = this.planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, this.currentApp);
  }

  /**
   * Открытие модального окна для добавления нового тега
   */
  openAddTagModal() {
    const addTagModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        confirmButtonText: this.translocoService.translate('chatBot.botActionUserTag.addTagModal.confirmButtonText'),
        heading: this.translocoService.translate('chatBot.botActionUserTag.addTagModal.heading'),
        inputErrorText: this.translocoService.translate('chatBot.botActionUserTag.addTagModal.inputErrorText'),
        inputLabel: this.translocoService.translate('chatBot.botActionUserTag.addTagModal.inputLabel'),
      })
      .open(PromptModalComponent);

    addTagModal.result.then(this.addTag.bind(this));
  }

  /**
   * Добавление нового тега
   *
   * @param tagName - Имя тега
   */
  addTag(tagName: Tag['name']) {
    const newTag: Tag = {
      id: generate(),
      name: tagName,
      removed: null,
    };

    // при добавлении нового тега надо проверить не существует ли добавляемый тег
    const existingTagByName = this.state.tags$.getValue().filter((tag) => tag.name === tagName)[0];

    if (existingTagByName) {
      this.actionForm.controls.body.setValue(existingTagByName.name);
    } else {
      this.actionForm.controls.body.setValue(newTag.name);
      this.state.tags$.next([...this.state.tags$.getValue(), newTag]);
    }
  }
}

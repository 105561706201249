import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { InputNumberRangeComponent } from './input-number-range.component';

@NgModule({
  declarations: [InputNumberRangeComponent],
  exports: [InputNumberRangeComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, SharedModule, ReactiveFormsModule],
})
export class InputNumberRangeModule {}

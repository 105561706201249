<div class="modal-header">
  <h3 class="modal-title">{{ modalWindowParams.heading }}</h3>
  <button
    class="close"
    (click)="ngbActiveModal.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="margin-bottom-20">{{ modalWindowParams.body }}</div>
  <form
    autocomplete="off"
    novalidate
  >
    <div
      class="form-group no-margin-bottom"
      [cqShowError]="setDirectoryForm.controls.directory"
    >
      <ng-select
        [searchable]="true"
        [clearable]="false"
        [formControl]="setDirectoryForm.controls.directory"
        [items]="directoriesStore$ | async"
        [placeholder]="'modals.setMessageDirectory.directorySelect.placeholder' | transloco"
        bindLabel="prettyName"
        bindValue="id"
      >
        <ng-template ng-footer-tmp>
          <button
            (click)="openCreateDirectoryModal()"
            class="btn btn-secondary btn-block"
            type="button"
          >
            {{ 'modals.setMessageDirectory.directorySelect.actionText' | transloco }}
          </button>
        </ng-template>
      </ng-select>

      <cq-validation-messages [control]="setDirectoryForm.controls.directory">
        <cq-validation-message errorName="required">
          {{ 'modals.setMessageDirectory.directorySelect.errors.required' | transloco }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary"
    (click)="confirm()"
    [disabled]="isApiRequestPerformed || setDirectoryForm.invalid"
    type="button"
  >
    {{ 'modals.setMessageDirectory.moveButton' | transloco }}
  </button>
</div>

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('DialogflowController', DialogflowController);

  function DialogflowController(
    $state,
    $translate,
    toastr,
    PROJECT_NAME,
    integrationModel,
    validationHelper,
    currentApp,
    integration,
  ) {
    var vm = this;

    vm.$translate = $translate;
    vm.attachFile = attachFile;
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.integration = integration;
    vm.isManualExpanded = angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.isSecondApiVersion = !vm.integration.settings.accessToken; // Включена ли вторая версия API
    vm.jsonParsingInProcess = false; // Парсинг файла в процессе
    vm.jsonKey = integration.settings.jsonKey; // Распаршенный JSON
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.validationHelper = validationHelper;

    /**
     *  Прикрепление файла
     *
     * @param {Array.<File>} files
     */
    function attachFile(files) {
      if (vm.jsonParsingInProcess || !files || files.length !== 1) {
        return;
      }

      var file = files[0];
      if (!/(.json)$/i.test(file.name)) {
        toastr.error($translate.instant('integrations.dialogflow.toasts.fileExtension'));
        return;
      }

      vm.jsonParsingInProcess = true;

      var reader = new FileReader();
      reader.onload = function (evt) {
        vm.jsonKey = JSON.parse(evt.target.result);
        vm.jsonParsingInProcess = false;
      };
      reader.readAsText(file);
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function createOrUpdateIntegration(isValid) {
      if (isValid) {
        var integration = angular.copy(vm.integration);

        if (vm.isSecondApiVersion) {
          integration.settings.jsonKey = vm.jsonKey;
          delete integration.settings.accessToken;
        }

        if (integration.id) {
          integrationModel.save(currentApp.id, integration).then(saveSuccess);
        } else {
          integrationModel.create(currentApp.id, integration).then(createSuccess);
        }
      }

      function createSuccess(integration) {
        var params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        var transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка "Назад" вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

import { InjectionToken, Provider } from '@angular/core';

import { Connection } from '../views/connection';

export const BOT_CONNECTIONS_COLLECTION = new InjectionToken<Array<Connection>>(
  'Array to collect and share active connections',
);

export const connectionsCollectionProvider: Provider = {
  provide: BOT_CONNECTIONS_COLLECTION,
  useValue: [],
};

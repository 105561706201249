import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { INFO_BLOCK } from '@panel/app/pages/subscription/pricing/plans-comparison/plans-comparison.constants';
import { BILLING_ADD_ONS, UNIQUE_BILLING_PLAN_ID } from '@panel/app/services/billing-info/billing-info.constants';

/**
 * Компонент для работы со сравнением тарифных планов
 */

@Component({
  selector: 'cq-plans-comparison[expectedVisitors][ratePlansConfig]',
  templateUrl: './plans-comparison.component.html',
  styleUrls: ['./plans-comparison.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlansComparisonComponent {
  /** Предполагаемое количество уникальных пользователей */
  @Input()
  expectedVisitors: number = 1000;

  /**
   * Конфигурация тарифных планов
   *
   * NOTE:
   *  Тип и значение неопределённо, т.к. сам механизм конфигурации тарифов сделан неправильно.
   *  Исправлять ничего не стали, т.к. необходимо было быстро зарелизить.
   */
  @Input()
  ratePlansConfig: any = undefined;

  /** Аддоны */
  BILLING_ADD_ONS = BILLING_ADD_ONS;

  /** Блоки с информацией о доступах к фичам */
  INFO_BLOCK = INFO_BLOCK;

  /** Уникальные идентификаторы тарифных планов */
  UNIQUE_BILLING_PLAN_ID = UNIQUE_BILLING_PLAN_ID;

  /** Название проекта */
  projectName = environment.projectName;

  /** Показывать только различие в информации о доступе к фичам */
  showOnlyDifference: boolean = false;

  /** Блоки с информацией о доступе к фичам */
  get infoBlocks(): INFO_BLOCK[] {
    const orderedInfoBlocks = [
      INFO_BLOCK.LEADS_DATABASE,
      INFO_BLOCK.CHAT,
      INFO_BLOCK.CHATBOTS,
      INFO_BLOCK.TRIGGERED_MESSAGES,
      INFO_BLOCK.KNOWLEDGE_BASE,
      INFO_BLOCK.MANUAL_SENDING,
      INFO_BLOCK.INTEGRATION,
      INFO_BLOCK.ANALYTICS,
    ];

    /*
      В некоторых блоках нет отличий между тарифами, поэтому их необходимо скрыть полностью,
      если включен режим отображения "Только различия"
    */
    return this.showOnlyDifference
      ? orderedInfoBlocks.filter((block) => ![INFO_BLOCK.LEADS_DATABASE, INFO_BLOCK.INTEGRATION].includes(block))
      : orderedInfoBlocks;
  }

  constructor(private readonly translocoService: TranslocoService) {}

  /**
   * Получение количества Email в зависимости от предполагаемого количества уникальных пользователей
   *
   * @param planId - Идентификатор тарифного плана
   * @param expectedVisitors - Предполагаемое количество уникальных пользователей
   */
  getEmailsCountByExpectedVisitors(planId: UNIQUE_BILLING_PLAN_ID, expectedVisitors: number): number {
    return this.ratePlansConfig[planId][expectedVisitors].EMAILS;
  }

  /**
   * Получение переведённого заголовка для блока различий тарифа
   *
   * @param block - Блок, для которого необходимо получить переведённый заголовок
   */
  getTranslateForBlockTitle(block: INFO_BLOCK): string {
    return this.translocoService.translate(`plansComparisonComponent.${block}.heading`);
  }

  /**
   * Получение переведённого пункта в блоке различий тарифов
   *
   * @param block - Блок, для которого необходимо получить переведённый пункт
   * @param pointTranslocoKey - Ключ перевода для пункта
   */
  getTranslateForBlockPoint(block: INFO_BLOCK, pointTranslocoKey: string): string {
    return this.translocoService.translate(`plansComparisonComponent.${block}.points.${pointTranslocoKey}`, {
      projectName: this.projectName,
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom } from 'rxjs';

import { App } from '@http/app/app.model';
import { Billing } from '@http/billing/billing';
import { BillingHttpService } from '@http/billing/http/billing-http.service';
import { InvoiceHttpService } from '@http/invoice/http/invoice-http.service';
import { Invoice } from '@http/invoice/invoice';
import { INVOICE_PAGE_COUNT } from '@http/invoice/invoice.constants';
import { PlanHttpService } from '@http/plan/http/plan-http.service';
import { Plan } from '@http/plan/plan';
import { QuotasHttpService } from '@http/quotas/http/quotas-http.service';
import { Quotas } from '@http/quotas/quotas';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { PaginatableElements } from '@http/types';
import { SubscriptionComponent } from '@panel/app/pages/subscription/general/subscription.component';
import { SubscriptionModule } from '@panel/app/pages/subscription/general/subscription.module';
import { PricingWrapperComponent } from '@panel/app/pages/subscription/pricing-wrapper/pricing-wrapper.component';
import { PricingWrapperModule } from '@panel/app/pages/subscription/pricing-wrapper/pricing-wrapper.module';

export const SUBSCRIPTION_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.subscription',
    url: '/subscription',
    redirectTo: 'app.content.subscription.general',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
    },
  },
  {
    name: 'app.content.subscription.general',
    url: '',
    component: SubscriptionComponent,
    resolve: [
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'djangoUser',
        deps: ['djangoUser'],
        resolveFn: (djangoUser: any) => djangoUser,
      },
      {
        token: 'billing',
        deps: [BillingHttpService],
        resolveFn: getBilling,
      },
      {
        token: 'invoices',
        deps: [InvoiceHttpService],
        resolveFn: getInvoices,
      },
      {
        token: 'plan',
        deps: [PlanHttpService, 'currentApp'],
        resolveFn: getPlan,
      },
      {
        token: 'quotas',
        deps: [QuotasHttpService, 'currentApp'],
        resolveFn: getQuotas,
      },
    ],
  },
  {
    name: 'app.content.subscription.pricing',
    url: '/pricing',
    component: PricingWrapperComponent,
    resolve: [
      {
        token: 'app',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'billingInfo',
        deps: ['billingInfo'],
        resolveFn: (billingInfo: any) => billingInfo,
      },
      {
        token: 'djangoUser',
        deps: ['djangoUser'],
        resolveFn: (djangoUser: any) => djangoUser,
      },
    ],
  },
];

/**
 * Получение информации о биллинге
 */
function getBilling(billingHttpService: BillingHttpService): Promise<Billing> {
  return firstValueFrom(billingHttpService.get());
}

/**
 * Получение информации о счетах
 */
function getInvoices(invoiceHttpService: InvoiceHttpService): Promise<PaginatableElements<Invoice>> {
  return firstValueFrom(invoiceHttpService.getList(true, { paginateCount: INVOICE_PAGE_COUNT.FOR_SUBSCRIPTION_PAGE }));
}

/**
 * Получение информации о тарифном плане
 */
function getPlan(planHttpService: PlanHttpService, currentApp: App): Promise<Plan> {
  return firstValueFrom(planHttpService.get(currentApp.id));
}

/**
 * Получение информации о квотах
 */
function getQuotas(quotasHttpService: QuotasHttpService, currentApp: App): Promise<Quotas> {
  return firstValueFrom(quotasHttpService.get(currentApp.id));
}

@NgModule({
  imports: [
    CommonModule,
    SubscriptionModule,
    PricingWrapperModule,
    UIRouterUpgradeModule.forChild({ states: SUBSCRIPTION_PAGE_ROUTES }),
  ],
})
export class SubscriptionRoutingModule {}

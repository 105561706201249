import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { round } from 'lodash-es';

import { ConversationPartAttachment } from '@http/conversation-part/internal-types';

/** Preview для файловых прикреплений в реплике */
@Component({
  selector: 'cq-conversation-part-preview-file[attachment]',
  templateUrl: './conversation-part-preview-file.component.html',
  styleUrls: ['./conversation-part-preview-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPartPreviewFileComponent {
  /** Прикрепление */
  @Input() attachment: ConversationPartAttachment | null = null;

  /** Название прикрепления */
  get attachmentName(): string {
    let name = this.translocoService.translate('conversationPartPreviewFileComponent.zeroData');

    if (this.attachment) {
      name = this.attachment.filename;
    }

    return name;
  }

  /** Размер прикрепления */
  get attachmentSize(): number {
    let size = 0;

    if (this.attachment) {
      size = this.convertSizeAttachmentToMB(this.attachment.size);
    }

    return size;
  }

  constructor(private readonly translocoService: TranslocoService) {}

  /**
   * Конвертация размера прикрепления из KB в MB
   *
   * @param sizeAsKB - Размер в KB, который нужно преобразовать
   */
  convertSizeAttachmentToMB(sizeAsKB: number): number {
    return round(sizeAsKB / 1024 / 1024, 2);
  }
}

(function () {
  'use strict';

  angular
    .module('myApp.privacySettings')
    .controller('CqDoubleOptInSettingsController', CqDoubleOptInSettingsController);

  function CqDoubleOptInSettingsController($filter, $scope, $uibModal, fileHelper, validationHelper) {
    var vm = this;

    /**
     * Максимальная длинна названия компании
     *
     * @type {Number}
     */
    var APP_NAME_MAX_LENGTH = 30;

    /**
     * Максимальный размер загружаемого файла
     *
     * @type {Number}
     */
    var MAX_LOGO_SIZE = 1024 * 1024;

    vm.$onInit = init;

    function init() {
      vm.APP_NAME_MAX_LENGTH = APP_NAME_MAX_LENGTH;
      vm.doubleOptInSettingsForm = null;
      vm.fileHelper = fileHelper;
      vm.MAX_LOGO_SIZE = MAX_LOGO_SIZE;
      vm.newLogo = {
        file: null,
        preview: null,
      };
      vm.openEmailPreviewModal = openEmailPreviewModal;
      vm.openSubscriptionConfirmPreviewModal = openSubscriptionConfirmPreviewModal;
      vm.senderEmail = getSenderEmail();
      vm.validationHelper = validationHelper;

      $scope.$watch('vm.newLogo.file', setNewLogo);
      $scope.$watchGroup(['vm.currentApp.settings.messenger_append_app_name', 'vm.appName'], updateSenderEmail);

      function setNewLogo(logo) {
        vm.logo = logo;
      }

      function updateSenderEmail() {
        vm.senderEmail = getSenderEmail();
      }
    }

    /**
     * Получение адреса отправителя
     * NOTE: фактически то же самое делается в app/js/components/email-preview/email-preview.html, но тут формируется строка ngModel, а не html
     *
     * @returns {String}
     */
    function getSenderEmail() {
      var senderEmail = '';

      senderEmail += vm.messageSender.name;

      if (vm.currentApp.settings.messenger_append_app_name && vm.appName) {
        senderEmail += ' ' + vm.appName;
      }

      senderEmail += ' <' + vm.messageSender.emailName + vm.currentApp.settings.email_domain + '>';

      return senderEmail;
    }

    /**
     * Открытие модалки с превью письма подтверждения
     */
    function openEmailPreviewModal() {
      $uibModal.open({
        controller: 'DoubleOptInEmailPreviewModalController',
        controllerAs: 'vm',
        resolve: {
          appName: angular.bind(null, angular.identity, vm.appName),
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          messageSender: angular.bind(null, angular.identity, vm.messageSender),
          logo: angular.bind(null, angular.identity, vm.newLogo.preview || vm.settings.double_opt_in_logo),
        },
        templateUrl: 'js/shared/modals/double-opt-in-email-preview/double-opt-in-email-preview.html',
      });
    }

    /**
     * Открытие модалки с превью страницы после подтверждения подписки
     */
    function openSubscriptionConfirmPreviewModal() {
      $uibModal.open({
        controller: 'DoubleOptInSubscriptionConfirmPreviewModalController',
        controllerAs: 'vm',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          logo: angular.bind(null, angular.identity, vm.newLogo.preview || vm.settings.double_opt_in_logo),
        },
        templateUrl:
          'js/shared/modals/double-opt-in-subscription-confirm-preview/double-opt-in-subscription-confirm-preview.html',
      });
    }
  }
})();

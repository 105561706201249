(function () {
  'use strict';

  angular
    .module('myApp.directives.statisticsValue')
    .controller('CqStatisticsValueController', CqStatisticsValueController);

  function CqStatisticsValueController($filter) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.firstValueArray = [];
      vm.isSecondValueShowed = false;
      vm.parseFirstValue = angular.bind(null, parseValue, 'firstValueArray');
      vm.parseSecondValue = angular.bind(null, parseValue, 'secondValueArray');
      vm.placeholder = vm.placeholder || '\u2014';
      vm.secondValueArray = [];
      vm.toggleSecondValue = toggleSecondValue;
    }

    /**
     * Парсинг значения в массив
     */
    function parseValue(arrayName, value, measure) {
      var checkedValue = $filter('handleIncorrectValue')(value);
      if (checkedValue != null) {
        if (value.constructor == Array) {
          vm[arrayName] = value;
        } else {
          vm[arrayName] = [
            {
              value: value,
              measure: measure,
            },
          ];
        }
      } else {
        vm[arrayName] = [];
      }
    }

    /**
     * Функция показа второго значения при наведении мыши на первое / скрытия после отведения мыши
     *
     * @return {Boolean}
     */
    function toggleSecondValue() {
      if (vm.secondValueArray.length) {
        vm.isSecondValueShowed = !vm.isSecondValueShowed;
      } else {
        vm.isSecondValueShowed = false;
      }
    }
  }
})();

(function () {
  'use strict';

  require('./auto-event-properties-select.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventPropertiesSelect', cqAutoEventPropertiesSelect());

  function cqAutoEventPropertiesSelect() {
    return {
      bindings: {
        params: '=', // Параметры автособытия
        type: '@', // Тип автособытия
        userProps: '=', // Свойства пользователя
      },
      controller: 'CqAutoEventPropertiesSelectController',
      controllerAs: 'vm',
      template: require('./auto-event-properties-select.html'),
    };
  }
})();

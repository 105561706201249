(function () {
  'use strict';

  require('./bitrix1c.controller');

  angular.module('myApp.integrations').component('cqBitrix1cModal', cqBitrix1cModal());

  function cqBitrix1cModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // apiCredentials - ключи API
        // integration - текущая интеграция
      },
      controller: 'CqBitrix1cModalController',
      controllerAs: 'vm',
      template: require('./bitrix1c.html'),
    };
  }
})();

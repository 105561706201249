export const ARTICLES_INDEXED_STATUSES = {
  INDEXED: 'indexed', // Индексируются поисковиками
  NO_INDEXED: 'noIndexed', // Не индексируются поисковиками
};
export const ARTICLES_STATUSES = {
  PUBLISHED: 'published', // Опубликованные
  UNPUBLISHED: 'unpublished', // Неопубликованные
};
export const ARTICLES_INDEXED_STATUSES_LIST = [ARTICLES_INDEXED_STATUSES.INDEXED, ARTICLES_INDEXED_STATUSES.NO_INDEXED];
export const ARTICLES_STATUSES_LIST = [ARTICLES_STATUSES.PUBLISHED, ARTICLES_STATUSES.UNPUBLISHED];

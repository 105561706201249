(function () {
  'use strict';

  angular.module('myApp.messageTemplate').controller('CqCleanTemplateController', CqCleanTemplateController);

  function CqCleanTemplateController($state) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      // В зависимости от раздела сайта, в котором находится пользователь, меняет заголовок
      vm.title = $state.includes('app.content.messagesAjs')
        ? 'autoMessage'
        : $state.includes('app.content.messagesAjs.templates')
        ? 'template'
        : 'message';
    }
  }
})();

<ng-container *transloco="let t">
  <div class="card align-items-center">
    <cq-email-notification
      class="card-body"
      [autoMessages]="autoMessages"
      [chatBots]="chatBots"
      [currentApp]="currentApp"
      [emailRecipients]="emailRecipients"
      [integrationExternal]="integrationExternal"
      [properties]="properties"
      [teamMembers]="teamMembers"
    ></cq-email-notification>
  </div>
  <div class="text-right">
    <button
      class="btn btn-primary"
      type="button"
      (click)="emailNotificationComponent.onSubmit()"
    >
      {{ t('models.integration.actions.save') }}
    </button>
  </div>
</ng-container>

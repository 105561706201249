import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.integrations').controller('WebhookController', WebhookController);

  function WebhookController(
    $filter,
    $state,
    $translate,
    toastr,
    PROJECT_NAME,
    currentApp,
    eventTypeModel,
    integration,
    integrationModel,
    planFeatureAccessService,
    validationHelper,
  ) {
    let vm = this;

    vm.accessToIntegrationsWebhook = planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS_WEBHOOK, currentApp);

    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.currentApp = currentApp;
    vm.integration = integration;
    vm.isManualExpanded = angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.onCopyTokenSuccess = onCopyTokenSuccess;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      firstValueFrom(eventTypeModel.getList(currentApp.id)).then(function (eventTypes) {
        vm.eventTypes = eventTypes;
        if (typeof integration.settings.eventTypeIds === 'undefined') {
          integration.settings.eventTypeIds = [];
        }
        for (let i = 0; i < vm.eventTypes.length; i++) {
          if (integration.settings.eventTypeIds.indexOf(vm.eventTypes[i].id) != -1) {
            vm.eventTypes[i].checked = true;
          }
        }
        // HACK Удаляем из массива с событиями события, которые удалены
        vm.integration.settings.eventTypeIds = $filter('map')(
          $filter('filter')($filter('filter')(vm.eventTypes, { visible: true }), { checked: true }),
          'id',
        );
      });
    }

    /**
     * Сохранение/обновление интеграции
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function createOrUpdateIntegration(isValid) {
      if (isValid) {
        if (integration.id) {
          integrationModel.save(currentApp.id, integration).then(saveSuccess);
        } else {
          integrationModel.create(currentApp.id, integration).then(createSuccess);
        }
      }

      function createSuccess(integration) {
        let params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        let transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Токен успешно скопирован в буфер
     *
     */
    function onCopyTokenSuccess() {
      toastr.success($translate.instant('integrations.webhook.settingsForm.tokenInput.toasts.copyWebhookTokenSuccess'));
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

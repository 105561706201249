import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlbatoAuthTokenModule } from '@panel/app/pages/integrations/content/albato/auth-token/albato-auth-token.module';
import { AlbatoSettingsModule } from '@panel/app/pages/integrations/content/albato/settings/albato-settings.module';
import { AlbatoWrapperSettingsModule } from '@panel/app/pages/integrations/content/albato/wrapper-settings/albato-wrapper-settings.module';

@NgModule({
  imports: [AlbatoAuthTokenModule, AlbatoSettingsModule, AlbatoWrapperSettingsModule, CommonModule],
})
export class AlbatoModule {}

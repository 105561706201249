import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiInterceptor } from '@panel/app/shared/interceptors/api-endpoint/api-endpoint.interceptor';
import { ApiVersionInterceptor } from '@panel/app/shared/interceptors/api-version/api-version.interceptor';
import { AppInterceptor } from '@panel/app/shared/interceptors/app/app.interceptor';
import { AuthInterceptor } from '@panel/app/shared/interceptors/auth/auth.interceptor';
import { ErrorResponseInterceptor } from '@panel/app/shared/interceptors/error-response/error-response.interceptor';
import { FormDataInterceptor } from '@panel/app/shared/interceptors/form-data/form-data.interceptor';
import { IdAsStringInterceptor } from '@panel/app/shared/interceptors/id-as-string/id-as-string.interceptor';
import { NullRequestParamsInterceptor } from '@panel/app/shared/interceptors/null-request-params/null-request-params.interceptor';
import { RequestBodyToUnderscoreInterceptor } from '@panel/app/shared/interceptors/request-body-to-underscore/request-body-to-underscore.interceptor';
import { RequestParamsToUnderscoreInterceptor } from '@panel/app/shared/interceptors/request-params-to-underscore/request-params-to-underscore.interceptor';
import { ResponseToCamelCaseInterceptor } from '@panel/app/shared/interceptors/response-to-camel-case/response-to-camel-case.interceptor';
import { SuccessResponseInterceptor } from '@panel/app/shared/interceptors/success-response/success-response.interceptor';

export const httpInterceptorProviders = [
  // NOTE: интерсепторы вызываются в том порядке, в котором они указаны в этом массиве, поэтому добавлять/перетасовывать их надо с умом
  {
    provide: HTTP_INTERCEPTORS,
    useClass: IdAsStringInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiVersionInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: NullRequestParamsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestParamsToUnderscoreInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestBodyToUnderscoreInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ResponseToCamelCaseInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SuccessResponseInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorResponseInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: FormDataInterceptor,
    multi: true,
  },
];

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type Toast = {
  text: string;
  heading: string;
  autohide: boolean;
  delay: number;
  type: ToastType;
  classname: string;
  closable?: boolean;
  name?: string;
  closeButton?: boolean;
  onTap?: () => void;
};

export type ToastOptions = {
  autohide?: boolean;
  delay?: number;
  type?: ToastType;
  classname?: string;
  closable?: boolean;
  name?: string;
  closeButton?: boolean;
  onTap?: () => void;
};

const enum ToastType {
  DANGER = 'danger',
  SUCCESS = 'success',
  SECONDARY = 'secondary',
  WARNING = 'warning',
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  private toastsSubj = new BehaviorSubject<Toast[]>([]);

  toasts$ = this.toastsSubj.asObservable();

  public danger(text: string, heading: string = '', options: ToastOptions = {}): Toast {
    const toast = this.getOptions(text, heading, options, ToastType.DANGER);
    this.show(toast);
    return toast;
  }

  public warning(text: string, heading: string = '', options: ToastOptions = {}): Toast {
    const toast = this.getOptions(text, heading, options, ToastType.WARNING);
    this.show(toast);
    return toast;
  }

  public secondary(text: string, heading: string = '', options: ToastOptions = {}): Toast {
    const toast = this.getOptions(text, heading, options, ToastType.SECONDARY);
    this.show(toast);
    return toast;
  }

  public success(text: string, heading: string = '', options: ToastOptions = {}): Toast {
    const toast = this.getOptions(text, heading, options, ToastType.SUCCESS);
    this.show(toast);
    return toast;
  }

  public remove(toast: Toast) {
    this.toastsSubj.next(this.toasts.filter((t) => t !== toast));
  }

  private show(options: Toast) {
    this.toastsSubj.next([...this.toasts, options]);
  }

  public hide(toast: Toast): void {
    toast.delay = 0;
    toast.autohide = true;
  }

  public get(toastName: string): Toast | null {
    const toast = this.toasts.filter((toast) => toast.name === toastName);
    if (!toast) {
      return null;
    }
    return toast[0];
  }

  public isOpen(toastName: string): boolean {
    return this.toasts.filter((toast) => toast.name === toastName).length > 0;
  }

  private getOptions(text: string, heading: string, opt: ToastOptions, type: ToastType): Toast {
    return {
      text,
      heading,
      autohide: true,
      delay: 5000,
      type,
      classname: '',
      ...opt,
    };
  }

  private get toasts() {
    return this.toastsSubj.getValue();
  }
}

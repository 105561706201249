<ng-container *transloco="let t; read: 'pricingAddonComponent'">
  <!-- Убрать проверку на аддон безопасности, как только мы откроем его на всех -->
  <div
    *ngIf="!isSecurityAddon()"
    class="card-group"
    [attr.id]="addon"
  >
    <div class="card">
      <div class="card-body">
        <div class="font-weight-bold mb-10">{{ addonTranslate(addon) }}</div>
        <div class="text-secondary">{{ t('addons.description.' + addon) }}</div>
      </div>
    </div>
    <div
      *ngFor="let plan of plansPerAddon"
      class="card"
    >
      <div class="card-body d-flex align-items-center">
        <!--Аддон доступен для подключения-->
        <ng-container *ngIf="isAddonAvailableOnPlan(plan)">
          <ng-container *ngIf="addon !== BILLING_ADD_ONS.TELEGRAM_BOT; else telegramAddon">
            <cq-custom-price-cap
              *ngIf="needsPersonalPlan || !getPriceAddonPerExpectedVisitors(addon, expectedVisitors)"
            ></cq-custom-price-cap>

            <ng-container *ngIf="!needsPersonalPlan && getPriceAddonPerExpectedVisitors(addon, expectedVisitors)">
              <cq-switch-new
                class="mr-5"
                [ngModel]="addonConfigPerPlan[plan].value"
                (ngModelChange)="updateAddonConfigPerPlan(plan, $event)"
              ></cq-switch-new>
              {{
                t('available', {
                  price: (getPriceAddonPerExpectedVisitors(addon, expectedVisitors) | round | toFormattedMoney)
                })
              }}
            </ng-container>
          </ng-container>
          <ng-template #telegramAddon>
            <div class="d-flex flex-col justify-center">
              <span class="text-muted">{{ t('requestedPriceAddon.price') }}</span>
              <a
                *ngIf="l10nHelper.isRusCountry(); else usTelegram"
                href="https://t.me/Carrot_quest_Request_bot"
                target="_blank"
              >
                {{ t('requestedPriceAddon.contactUs') }}
              </a>
              <ng-template #usTelegram>
                <a
                  href="#"
                  (click)="connectAddonViaChat(addon)"
                >
                  {{ t('requestedPriceAddon.contactUs') }}
                </a>
              </ng-template>
            </div>
          </ng-template>
        </ng-container>

        <!--Аддон не доступен для подключения-->
        <ng-container *ngIf="isAddonNotAvailableOnPlan(plan)">
          <div class="text-muted">
            <i class="cqi-sm cqi-ban mr-5"></i>
            {{ t('notAvailable') }}
          </div>
        </ng-container>

        <!--Аддон входит в тариф-->
        <ng-container *ngIf="isAddonIncludedInPlan(plan)">
          <i class="cqi-sm cqi-check mr-5 text-primary"></i>
          {{ t('included') }}
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<div class="d-flex justify-content-center">
  <i
    *ngIf="isBooleanValue && value"
    class="cqi-sm cqi-check text-primary"
  ></i>
  <i
    *ngIf="isBooleanValue && !value"
    class="cqi-sm cqi-minus text-secondary"
  ></i>
  <span *ngIf="!isBooleanValue">{{ value }}</span>
</div>

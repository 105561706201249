<ng-container *transloco="let t">
  <div
    *ngIf="!file; else fileExist"
    class="form-control-container"
  >
    <cq-validation-messages [control]="attachments">
      <cq-validation-message errorName="required">
        {{ t('chatBot.actionFile.fileValidationInput.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
    <ngx-dropzone
      class="padding-30 card text-center file-upload cursor-pointer no-margin"
      [multiple]="false"
      [maxFileSize]="MAX_FILE_SIZE"
      (change)="onFileChange($event)"
      [cqShowError]="attachments"
      [classList]="['has-error', 'border-danger']"
    >
      <ngx-dropzone-label class="margin-15-neg">
        <h5>{{ t('chatBot.actionFile.dragFileField.heading') }}</h5>
        <div class="text-muted">
          {{ t('chatBot.actionFile.dragFileField.description', { maxFileSize: MAX_FILE_SIZE / 1024 / 1024 }) }}
        </div>
      </ngx-dropzone-label>
    </ngx-dropzone>
  </div>

  <ng-template #fileExist>
    <div class="flex align-items-center justify-space-between">
      <div class="text-primary">{{ file.name }}</div>
      <button
        class="btn btn-borderless-primary"
        type="button"
        (click)="removeFile()"
      >
        <i class="btn-icon cqi-sm cqi-trash"></i>
      </button>
    </div>
    <div class="text-muted">
      <small>{{ file.size! | bytes: 2 }}</small>
    </div>
  </ng-template>
</ng-container>

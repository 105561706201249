import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.addUserProperty')
    .controller('CqAddUserPropertyModalController', CqAddUserPropertyModalController);

  function CqAddUserPropertyModalController(
    $filter,
    $translate,
    $uibModal,
    propertyModel,
    userModel,
    validationHelper,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.addProperty = addProperty;
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса
      vm.propertyName = null;
      vm.propertyValue = null;
      vm.save = save;
      vm.validationHelper = validationHelper;
    }

    /**
     * Добавление несуществующего свойства
     *
     * @param {String} propertyName - Имя свойства
     */
    function addNonExistentProperty(propertyName) {
      const newProperty = propertyModel.getDefaultUserProperty(propertyName);
      vm.resolve.modalWindowParams.propertyList.push(newProperty);
      vm.propertyName = newProperty.name;
    }

    /**
     * Открытие модалки добавления нового свойства в список свойств
     */
    function addProperty() {
      const addPropertyModal = $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.addUserProperty.addPropertyModal.heading'),
              confirmButtonText: $translate.instant('modals.addUserProperty.addPropertyModal.confirmButtonText'),
              inputLabel: $translate.instant('modals.addUserProperty.addPropertyModal.inputLabel'),
              inputErrorText: $translate.instant('modals.addUserProperty.addPropertyModal.inputErrorText'),
            };
          },
        },
        size: 'md modal-dialog-centered',
      });

      addPropertyModal.result.then(addProperty);

      function addProperty(propertyName) {
        // при добавлении нового свойства надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
        const existingPropertyByFullName = $filter('filter')(
          vm.resolve.modalWindowParams.propertyList,
          { prettyName: propertyName },
          true,
        )[0];
        const existingPropertyByName = $filter('filter')(
          vm.resolve.modalWindowParams.propertyList,
          { name: propertyName },
          true,
        )[0];

        if (existingPropertyByName || existingPropertyByFullName) {
          vm.propertyName =
            (existingPropertyByName && existingPropertyByName.name) ||
            (existingPropertyByFullName && existingPropertyByFullName.name);
        } else {
          addNonExistentProperty(propertyName);
        }
      }
    }

    /**
     * Добавление свойства пользователю
     *
     * @param {Boolean} isValid — Флаг валидности формы
     */
    function save(isValid) {
      if (isValid) {
        vm.isApiRequestPerformed = true;

        const props = [
          {
            op: 'update_or_create',
            key: vm.propertyName,
            value: vm.propertyValue,
          },
        ];

        firstValueFrom(
          userModel.setProperties(
            vm.resolve.modalWindowParams.userId,
            vm.resolve.modalWindowParams.currentApp.id,
            props,
          ),
        )
          .then(setPropertiesSuccess)
          .then(setPropertiesFinally);
      }

      function setPropertiesFinally() {
        vm.isApiRequestPerformed = false;
      }

      function setPropertiesSuccess() {
        vm.modalInstance.close();
      }
    }
  }
})();

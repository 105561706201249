<ng-container *transloco="let t; read: 'tagCreateComponent'">
  <button
    class="btn btn-primary"
    (click)="onIntentToCreateTag()"
    type="button"
  >
    <i class="cqi-sm cqi-plus"></i>
    <span>{{ t('buttons.create.text') }}</span>
  </button>
</ng-container>

import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import { STARTER_GUIDE_STEPS } from '../../../app/http/starter-guide/starter-guide.constants';
import { firstValueFrom } from 'rxjs';

/**
 * Контроллер Live'а
 */
(function () {
  'use strict';

  angular.module('myApp.live').controller('CqLiveController', CqLiveController);

  function CqLiveController(
    $interval,
    $q,
    $scope,
    $state,
    $translate,
    $uibModal,
    $window,
    moment,
    toastr,
    appModel,
    carrotquestHelper,
    djangoUserModel,
    l10nHelper,
    planFeatureAccessService,
    userCardModalService,
    userModel,
  ) {
    var vm = this;

    var interval;
    var rts;

    vm.$onInit = init;
    vm.$onDestroy = onDestroy;
    vm.$onDestroy = onDestroy;

    function init() {
      vm.accessToLive = planFeatureAccessService.getAccess(PLAN_FEATURE.LIVE, vm.currentApp);
      vm.accessToManualMessagesChat = planFeatureAccessService.getAccess(
        PLAN_FEATURE.MANUAL_MESSAGES_CHAT,
        vm.currentApp,
      );

      trackEnterOnPage();

      vm.activeUsersCount = null; // Количество онлайн пользователей
      vm.appModel = appModel;
      vm.isOperator = djangoUserModel.isOperator(vm.currentApp.id, vm.djangoUser);
      vm.l10nHelper = l10nHelper;
      vm.redirectToStarterGuide = redirectToStarterGuide;
      vm.refresh = refresh;
      vm.selectUser = selectUser;
      vm.showMore = showMore;
      vm.showMoreLoading = false;
      vm.trackClickInstallCode = trackClickInstallCode;
      vm.trackClickUserCard = trackClickUserCard;
      vm.users = [];
      vm.usersLoading = false;
      vm.usersPaginator = null;
      vm.writeFromTable = writeFromTable;

      interval = $interval(timeProcess, 10 * 1000);
      $scope.$on('message', handleRts);

      refresh();

      function handleRts(event, info) {
        var channel = info.channel,
          data = info.data;

        if (channel.indexOf('user_presence_changed.') == 0) {
          let callApply = false;

          for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < vm.users.length; j++) {
              if (vm.users[j].id == data[i].user) {
                vm.users[j].presence = data[i].presence;
                callApply = true;
              }
            }
          }

          callApply && $scope.$applyAsync();
        } else if (channel.indexOf('user_merge_finished.') == 0) {
          let callApply = false;

          for (var j = 0; j < vm.users.length; j++) {
            if (vm.users[j].id == data.removed) {
              vm.users.splice(j, 1);
              callApply = true;
              break;
            }
          }

          callApply && $scope.$applyAsync();
        }
      }
    }

    function onDestroy() {
      $interval.cancel(interval);
    }

    function getTimeAgo(current, time) {
      if (time) {
        if (current.diff(time, 'seconds') < 60) {
          return $translate.instant('live.timeAgo.seconds');
        } else if (current.diff(time, 'minutes') >= 1 && current.diff(time, 'minutes') < 60) {
          return $translate.instant('live.timeAgo.minutes', { amount: current.diff(time, 'minutes') });
        } else if (current.diff(time, 'hours') >= 1 && current.diff(time, 'hours') < 24) {
          return $translate.instant('live.timeAgo.hours', { amount: current.diff(time, 'hours') });
        } else if (current.diff(time, 'days') >= 1 && current.diff(time, 'days') < 365) {
          return $translate.instant('live.timeAgo.days', { amount: current.diff(time, 'days') });
        } else {
          return $translate.instant('live.timeAgo.years', { amount: current.diff(time, 'years') });
        }
      }
    }

    /** Редирект на стартергайд */
    function redirectToStarterGuide() {
      $state.go('app.content.dashboard', {
        step: STARTER_GUIDE_STEPS.INSTALLATION_SERVICE,
      });
    }

    function refresh() {
      vm.activeUsersCount = null;
      vm.users = [];
      vm.usersPaginator = null;
      vm.usersLoading = true;
      $q.all([
        firstValueFrom(userModel.getActiveUsers(vm.currentApp.id, vm.usersPaginator)),
        firstValueFrom(userModel.getActiveUsersCount(vm.currentApp.id)),
      ])
        .then(getActiveUsersSuccess)
        .finally(getActiveUsersFinally);

      function getActiveUsersSuccess(responses) {
        vm.users = responses[0].users;
        timeProcess();
        vm.usersPaginator = responses[0].paginatorParams;
        vm.activeUsersCount = responses[1];
      }

      function getActiveUsersFinally() {
        vm.usersLoading = false;
      }
    }

    function selectUser(userId) {
      userCardModalService.showModal(vm.billingInfo, vm.currentApp, vm.djangoUser, userId, vm.telegramIntegrations);
    }

    function showMore() {
      if (vm.showMoreLoading) {
        return;
      }
      if (!vm.usersPaginator) {
        return;
      }

      vm.showMoreLoading = true;

      firstValueFrom(userModel.getActiveUsers(vm.currentApp.id, vm.usersPaginator, true)).then(getActiveUsersSuccess);

      function getActiveUsersSuccess(response) {
        vm.users = vm.users.concat(response.users);
        timeProcess();
        vm.usersPaginator = response.paginatorParams;
        vm.showMoreLoading = false;
        $scope.$applyAsync();
      }
    }

    function timeProcess() {
      for (var i = 0; i < vm.users.length; i++) {
        timeProcessUser(vm.users[i]);
      }
    }

    function timeProcessUser(user) {
      var now = moment();

      user.presenceDetails.sessionStartedHuman =
        user.presence === 'offline'
          ? $translate.instant('live.usersTable.body.leftUser')
          : getTimeAgo(now, user.presenceDetails.sessionStarted);
      user.props.$last_contacted__human = getTimeAgo(now, user.props.$last_contacted);
      user.props.$last_reply__human = getTimeAgo(now, user.props.$last_reply);

      user.props.$country__flag = (window.COUNTRY2CODE[user.props.$country] || 'unknown').toUpperCase();
    }

    /**
     * Трек клика по кнопке «Установить скрипт»
     */
    function trackClickInstallCode() {
      carrotquestHelper.track('button_clicked', {
        page_url: $window.location.pathname,
        type: 'install code',
      });
    }

    function trackEnterOnPage() {
      carrotquestHelper.track('LIVE - зашел', { App: vm.currentApp.name });
    }

    function writeFromTable(name, id) {
      var writeFilters = '{}';
      var writeManualIds = [id];
      var writeType = 'popup_chat';
      var writeUserCounterHint = 1;
      var writeUserNamesHint = name;

      var modalInstance = $uibModal.open({
        backdrop: 'static',
        controller: 'SendManualMessageModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/send-manual-message/send-manual-message.html',
        resolve: {
          billingInfo: angular.bind(null, angular.identity, vm.billingInfo),
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          djangoUser: angular.bind(null, angular.identity, vm.djangoUser),
          manualMessageParams: function () {
            return {
              filters: writeFilters,
              manualIds: writeManualIds,
              type: writeType,
              usersCount: writeUserCounterHint,
              userNames: writeUserNamesHint,
              telegramIntegrations: vm.telegramIntegrations,
            };
          },
        },
        size: 'right',
        windowClass: 'manual-message',
      });

      modalInstance.result.then(function () {
        toastr.success($translate.instant('live.toasts.sendSuccess'));
      });
    }

    /**
     * трек клика на карточку пользователя
     */
    function trackClickUserCard() {
      carrotquestHelper.track('Live - клик на карточку пользователя');
    }
  }
})();

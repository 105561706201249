<ng-container *transloco="let t; read: 'editAccountingEmailsModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="text-secondary mb-15">{{ t('body') }}</div>
    <form (ngSubmit)="onSubmit()">
      <ng-container *tuiLet="needShowRemoveControlButtons() as needShowRemoveControlButtons">
        <div
          [cqShowError]="control"
          *ngFor="let control of form.controls; let i = index; first as isFirst"
          [ngClass]="{ 'mt-15': !isFirst }"
        >
          <div class="d-flex">
            <input
              class="form-control"
              [formControl]="control"
              [placeholder]="t('emailInput.placeholder')"
            />
            <button
              *ngIf="needShowRemoveControlButtons"
              class="btn btn-outline-primary ml-10"
              type="button"
              (click)="removeControl(i)"
            >
              <i class="btn-icon cqi-sm cqi-times"></i>
            </button>
          </div>
          <cq-validation-messages [control]="control">
            <cq-validation-message errorName="maxlength">{{
              t('emailInput.errors.maxlength', { maxLength: ACCOUNTING_EMAIL_MAX_LENGTH })
            }}</cq-validation-message>
            <cq-validation-message errorName="required">{{ t('emailInput.errors.required') }}</cq-validation-message>
            <cq-validation-message errorName="email">{{ t('emailInput.errors.email') }}</cq-validation-message>
          </cq-validation-messages>
        </div>
      </ng-container>
      <input
        class="hidden"
        id="form.submit"
        type="submit"
        [disabled]="isApiRequestPerformed | async"
      />
    </form>
    <button
      *ngIf="needShowAddControlButton()"
      class="btn btn-secondary full-width mt-15"
      type="button"
      (click)="addControl()"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      {{ t('addEmailButton') }}
    </button>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <label
      class="btn btn-primary"
      for="form.submit"
      [ngClass]="{ disabled: isApiRequestPerformed | async }"
      >{{ 'general.save' | transloco }}</label
    >
  </div>
</ng-container>

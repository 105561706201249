<ng-container *transloco="let t">
  <div class="card align-items-center">
    <div class="card-body">
      <cq-amocrm-settings
        [chatBots]="chatBots"
        [currentApp]="currentApp"
        [integrationExternal]="integrationExternal"
        [isShowedFromModal]="isShowedFromModal"
        [triggerMessages]="triggerMessages"
      ></cq-amocrm-settings>
    </div>
  </div>

  <div class="text-right">
    <button
      class="btn btn-primary"
      (click)="amocrmSettingsComponent.onSubmit()"
      *ngIf="isEdit && isAuthorized"
      type="button"
    >
      {{ t('models.integration.actions.save') }}
    </button>
  </div>
</ng-container>

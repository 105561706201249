import { PLAN_FEATURE } from '../../../../../../app/services/billing/plan-feature/plan-feature.constants';
import { FEATURES } from '../../../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.autoMessages').controller('CqAutoMessageContentController', CqAutoMessageContentController);

  function CqAutoMessageContentController(
    $filter,
    $translate,
    $uibModal,
    carrotquestHelper,
    featureModel,
    messagePartModel,
    planFeatureAccessService,
    validationHelper,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.accessToAutoMessagesAbTesting = planFeatureAccessService.getAccess(
        PLAN_FEATURE.AUTO_MESSAGES_AB_TESTING,
        vm.currentApp,
      );

      vm.changeWebhookToLegacyOrNew = changeWebhookToLegacyOrNew;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.removeMessagePart = removeMessagePart;
      vm.toggleShowContent = toggleShowContent;
      vm.trackAddAbTest = trackAddAbTest;
      vm.trackClickCloseMessagePart = trackClickCloseMessagePart;
      vm.validationHelper = validationHelper;
    }

    /**
     * Удаление варианта автосообщения по индексу
     *
     * @param {Object} messagePart Удаляемый вариант
     */
    function removeMessagePart(messagePart) {
      var body;
      var isAbTest = true;

      // если у всех вариантов сообщения были ID - значит удаляется один из вариантов существующего в данный момент А/Б теста, по которому собирается статистика
      // и при удалении одного из вариантов этот А/Б тест должен завершиться
      for (var i = 0; i < vm.currentMessage.parts.length && isAbTest; i++) {
        if (!vm.currentMessage.parts[i].id) {
          isAbTest = false;
        }
      }

      var indexOfMessagePart = vm.currentMessage.parts.indexOf(messagePart);

      // todo: костыль исключительно для двух вариантов автосообщения, в будущем, если будет больше двух вариантов - надо будет переписать текст и убрать костыль
      var indexOfAnotherMessagePart = indexOfMessagePart === 0 ? 1 : 0;

      var messagePartLetter = $filter('letter')(indexOfMessagePart);
      var anotherMessagePartLetter = $filter('letter')(indexOfAnotherMessagePart);

      if (isAbTest) {
        body = $translate.instant('autoMessages.editor.removeMessagePartModal.abTestBody', {
          messagePartLetter: messagePartLetter,
          anotherMessagePartLetter: anotherMessagePartLetter,
        });
      } else {
        body = $translate.instant('autoMessages.editor.removeMessagePartModal.notAbTestBody', {
          messagePartLetter: messagePartLetter,
          anotherMessagePartLetter: anotherMessagePartLetter,
        });
      }

      var removeMessagePartModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('autoMessages.editor.removeMessagePartModal.heading', {
                messagePartLetter: messagePartLetter,
              }),
              body: body,
            };
          },
        },
      });

      removeMessagePartModal.result.then(removeMessagePartModalSuccess);

      function removeMessagePartModalSuccess() {
        trackRemoveMessagePart();

        vm.currentMessage.parts.splice(vm.currentMessage.parts.indexOf(messagePart), 1);
        messagePartModel.generatePartNames(vm.currentMessage.parts);
        vm.refreshProportions({ $messageParts: vm.currentMessage.parts });

        // если остался последний вариант автосообщения, и он закрыт - надо его открыть
        if (vm.currentMessage.parts.length === 1 && !vm.currentMessage.parts[0].showContent) {
          toggleShowContent(vm.currentMessage.parts[0]);
        }

        // при удалении любой из частей сообщения - тест-группа закрывается и выставляется флаг, что один из вариантов был удалён
        vm.currentMessage.closePreviousTestGroup = true;
      }
    }

    /**
     * Трек клика на добавление А/Б теста
     */
    function trackAddAbTest() {
      carrotquestHelper.track('Автосообщения - добавил вариант А/Б теста');
    }

    /**
     * Трек клика на закрытие варианта сообщения
     *
     * @param {Object} messagePart Вариант сообщения
     */
    function trackClickCloseMessagePart(messagePart) {
      carrotquestHelper.track('Автосообщения - клик на "Закрыть вариант"', { Вариант: messagePart.name });
    }

    /**
     * Переключение состояния показа варианта автосообщения
     *
     * @param {Object} messagePart Вариант автосообщения
     */
    function toggleShowContent(messagePart) {
      messagePart.showContent = !messagePart.showContent;
    }

    /**
     * Трек удаления варианта А/Б теста
     */
    function trackRemoveMessagePart() {
      carrotquestHelper.track('Автосообщения - удалил вариант А/Б теста');
    }

    /**
     * Переключение редактора вебхука между старым или новым видом
     */
    function changeWebhookToLegacyOrNew(messagePart) {
      if (messagePart.webhook.bodyJson) {
        messagePart.webhook.bodyJson = null;
      } else {
        messagePart.webhook.bodyJson = messagePartModel.getDefaultWebhookBodyJson();
      }
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import sortBy from 'lodash-es/sortBy';

import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { App } from '@http/app/app.model';
import { EventType, Properties } from '@http/property/property.model';

@Component({
  selector: 'cq-email-notification-event-triggers-readonly[currentApp][properties][selectedEventTriggers]',
  templateUrl: './event-triggers-readonly.component.html',
  styleUrls: ['./event-triggers-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationEventTriggersReadonlyComponent implements OnInit {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  /** Список свойств и событий, которые есть в App'е */
  @Input() properties!: Properties;
  /** Выбранные события, после которых отправляется уведомление о лиде */
  @Input() selectedEventTriggers!: EventType['name'][];

  /** Доступ до кастомных событий */
  accessToEventsEventTypesCustom: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  /** Отфильтрованные и отсортированные типы событий */
  filteredAndSortEventTypes: EventType[] = [];

  constructor(private readonly planFeatureAccessService: PlanFeatureAccessService) {}

  ngOnInit(): void {
    this.accessToEventsEventTypesCustom = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
      this.currentApp,
    );

    this.filteredAndSortEventTypes = this.getFilteredAndSortEventTypes();
  }

  /** Получение отфильтрованных и отсортированных типов событий */
  getFilteredAndSortEventTypes(): EventType[] {
    return this.getSortedEventTypesByPrettyName(this.getFilteredEventTypes(this.properties.eventTypes));
  }

  /**
   * Получение отфильтрованных событий по видимости (=удалённости) и доступности в тарифе
   *
   * @param eventTypes
   */
  getFilteredEventTypes(eventTypes: EventType[]): EventType[] {
    return eventTypes.filter((eventType: EventType) => {
      const isEventTypeVisible = eventType.visible;
      const isNotCapabilities = this.accessToEventsEventTypesCustom.hasAccess;
      const isSelectedEventTrigger = this.selectedEventTriggers.includes(eventType['name']);
      const isSystemEventType = eventType.name.includes('$');

      return isEventTypeVisible && (isNotCapabilities || isSystemEventType) && isSelectedEventTrigger;
    });
  }

  /**
   * Получение отсортированных по prettyName событий
   *
   * @param eventTypes
   */
  getSortedEventTypesByPrettyName(eventTypes: EventType[]): EventType[] {
    return sortBy(eventTypes, ['prettyName']);
  }
}

<ng-container *transloco="let t; read: 'fileBlockEditorComponent'">
  <div
    *ngIf="!file; else fileExist"
    class="form-control-container"
  >
    <cq-validation-messages [control]="control.controls.attachment">
      <cq-validation-message errorName="required">
        {{ t('fileValidationInput.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
    <ngx-dropzone
      class="p-30 card text-center file-upload cursor-pointer m-0"
      [multiple]="false"
      [maxFileSize]="MAX_FILE_SIZE"
      (change)="onFileChange($event)"
      [cqShowError]="control.controls.attachment"
      [classList]="['has-error', 'border-danger']"
    >
      <ngx-dropzone-label class="">
        <h5>{{ t('dragFileField.heading') }}</h5>
        <div class="text-muted">
          {{ t('dragFileField.description', { maxFileSize: MAX_FILE_SIZE / 1024 / 1024 }) }}
        </div>
      </ngx-dropzone-label>
    </ngx-dropzone>
  </div>

  <ng-template #fileExist>
    <div
      *ngIf="file"
      class="card"
    >
      <div class="card-body p-10 flex align-items-center justify-space-between">
        <div>{{ fileName }}</div>
        <div>
          <small class="text-muted">{{ file.size! | bytes: 2 }}</small>
          <button
            class="btn btn-borderless-primary"
            type="button"
            (click)="removeFile()"
          >
            <i class="btn-icon cqi-sm cqi-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TeamMemberAvatarModule } from '@panel/app/partials/team-member-avatar/team-member-avatar.module';

import { ConversationOperatorComponent } from './conversation-operator.component';

@NgModule({
  declarations: [ConversationOperatorComponent],
  imports: [CommonModule, TeamMemberAvatarModule],
  exports: [ConversationOperatorComponent],
})
export class ConversationOperatorModule {}

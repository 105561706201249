import { Container } from '@pixi/display';
import { IPointData } from '@pixi/math';
import { Text } from 'pixi.js';
import { merge, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { generate } from 'short-uuid';

import { getButtonBackground } from '@panel/app/pages/chat-bot/content/views/actions/button-action/button-action.textures';
import { BlockType, CARD_WIDTH } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { IPoint } from '@panel/app/pages/chat-bot/content/views/connection';
import { BOT_GREY, BOT_WHITE_COLOR } from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { UNICODE_ICON } from '@panel/app/shared/constants/unicode-icon.constants';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';
import { BotButtonActionBodyJson } from 'app/http/chat-bot/actions';

import { ActionStyle, BaseActionABS, ConnectionSourceActionABS, MAX_ACTION_WIDTH } from '../abstract';

export class BotButtonAction extends ConnectionSourceActionABS<BotButtonActionBodyJson> {
  blockType: BlockType = 'branch';

  rerenderConnection$!: Observable<void>;

  protected style!: ActionStyle;

  // Подписка на удаление nextBranch
  nextBranchDestroySub?: Subscription;

  uid!: string;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);
    this.render();
    this.uid = generate();
    this.rerenderConnection$ = this.externalPositionChange;
    merge(this.enableUrlControl.valueChanges, this.iconControl.valueChanges)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.redraw());
  }

  get apiReadyBodyJson(): BotButtonActionBodyJson {
    return {
      enableUrl: this.enableUrlControl.value,
      href: this.hrefControl.value,
      icon: this.iconControl.value,
      target: this.targetControl.value,
    };
  }

  get enableUrlControl() {
    return this.form.controls.bodyJson.controls.enableUrl;
  }

  get hrefControl() {
    return this.form.controls.bodyJson.controls.href;
  }

  get iconControl() {
    return this.form.controls.bodyJson.controls.icon;
  }

  get targetControl() {
    return this.form.controls.bodyJson.controls.target;
  }

  getConnectionPointCoords(element: Container | null = null): IPointData {
    if (!element) {
      return { x: 0, y: 0 };
    }
    return { x: CARD_WIDTH - this.connectionPoint.width / 2, y: element.height / 2 - this.connectionPoint.height / 2 };
  }

  get height(): number {
    return this.container.height;
  }

  get width(): number {
    return this.container.width;
  }

  redraw(): void {
    if (this.destroyed) {
      return;
    }
    this.container.removeChildren();
    this.render();
  }

  get renderReady(): boolean {
    return this.container.renderable && this.container.children.length > 0;
  }

  get connectionPointGlobalCoordinates(): IPoint {
    // TODO: Вот это обращение к parent.parent мне очень не нравится, хочется абстракции, но пока не удалось сообразить
    const branchPosition = this.container.parent.parent.position;
    return {
      x: branchPosition.x + CARD_WIDTH,
      y: branchPosition.y + this.container.y + this.container.height / 2,
    };
  }

  destroy() {
    this.connection?.dropConnection();
    super.destroy();
  }

  protected getStyle(): ActionStyle {
    return {
      padding: {
        vertical: 7,
        horizontal: 12,
      },
      border: {
        size: 1,
        color: BOT_GREY,
        radius: 10,
      },
      background: {
        color: BOT_WHITE_COLOR,
      },
    };
  }

  render(): Container {
    const hPadding = this.style.padding.horizontal * 2;
    const vPadding = this.style.padding.vertical * 2;
    const fontSize = 14;
    const textOptions = {
      fontSize,
      lineHeight: fontSize * 1.42857143,
      wordWrapWidth: MAX_ACTION_WIDTH - hPadding,
      fill: BOT_GREY,
    };
    let textPositionX = this.style.padding.horizontal;
    let textPositionY = this.style.padding.vertical;
    const textToRender = this.form.controls.body.value ?? '';
    let bgWidth = 0;
    let bgHeight = 0;
    let icon: Text;

    if (this.enableUrlControl.value) {
      icon = PixiIconRenderService.renderIcon(UNICODE_ICON[this.iconControl.value]);
      icon.position.set(this.style.padding.horizontal, this.style.padding.vertical);
      bgWidth += icon.width + 5;
      textPositionX += icon.width + 5;

      textOptions.wordWrapWidth -= icon.width + 5;
    }

    const text = renderCanvasText(textToRender, textOptions);
    bgWidth += text.width + hPadding;
    bgHeight += text.height + vPadding;

    text.position.set(textPositionX, textPositionY);

    const bg = getButtonBackground(this.pixiApp.renderer, bgWidth, bgHeight);
    bg.zIndex = 1;

    if (icon!) {
      bg.addChild(icon);
    }
    bg.addChild(text);

    this.container.addChild(bg, this.connectionPoint);

    const padding = (CARD_WIDTH - MAX_ACTION_WIDTH) / 2;
    bg.position.set(CARD_WIDTH - (bg.width + padding), bg.position.y);
    const { x: xConnectionPointPosition, y: yConnectionPointPosition } = this.getConnectionPointCoords(bg);
    const scale = this.getConnectionPointScale();
    this.connectionPoint.setTransform(xConnectionPointPosition, yConnectionPointPosition, scale, scale);

    return bg;
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DowngradeAbilitiesService } from '../downgrade-abilities.service';
import { PLAN_CHANGE_CONFIRM_MODAL_TOKEN, PlanChangeConfirmModalData } from '../plan-change-confirm-modal.token';

@Component({
  selector: 'cq-downgrade-abilities',
  templateUrl: './downgrade-abilities.component.html',
  styleUrls: ['./downgrade-abilities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DowngradeAbilitiesComponent {
  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly downgradeAbilitiesService: DowngradeAbilitiesService,
    @Inject(PLAN_CHANGE_CONFIRM_MODAL_TOKEN)
    public readonly data: PlanChangeConfirmModalData,
  ) {}
}

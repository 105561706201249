import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';
import { firstValueFrom } from 'rxjs';

import { AiDataModel } from '@http/ai-data/ai-data.model';
import { App } from '@http/app/app.model';
import { PropertyModel } from '@http/property/property.model';
import { TagModel } from '@http/tag/tag.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { AiDataBotSettingsComponent } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings.component';
import { AiDataSourcePageComponent } from '@panel/app/pages/ai-data-sources/ai-data-source-page.component';

export const AI_DATA_SOURCE_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.aiDataSources',
    component: AiDataSourcePageComponent,
    url: '',
    data: {
      roles: [TEAM_MEMBER_PERMISSIONS.ADMIN, TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN],
    },
    redirectTo: 'app.content.aiDataSources.botSettings',
  },
  {
    name: 'app.content.aiDataSources.botSettings',
    component: AiDataBotSettingsComponent,
    url: '/ai-bot-settings',
    resolve: [
      {
        token: 'currentApp',
        deps: ['currentApp'],
        resolveFn: (currentApp: any) => currentApp,
      },
      {
        token: 'properties',
        deps: [PropertyModel, 'currentApp'],
        resolveFn: getProperties,
      },
      {
        token: 'tags',
        deps: ['currentApp', TagModel],
        resolveFn: getTags,
      },
      {
        token: 'aiDataBotSettings',
        deps: [AiDataModel],
        resolveFn: getAiBotSettings,
      },
    ],
  },
];

function getAiBotSettings(aiDaraModel: AiDataModel) {
  return firstValueFrom(aiDaraModel.getSettings());
}

function getProperties(propertyModel: PropertyModel, currentApp: App) {
  return firstValueFrom(propertyModel.getList(currentApp.id));
}

function getTags(currentApp: App, tagModel: TagModel) {
  return firstValueFrom(tagModel.getList(currentApp.id));
}

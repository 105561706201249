<ng-container *transloco="let t; read: 'breadcrumbsComponent'">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
        class="breadcrumb-item active"
        aria-current="page"
      >
        {{ t('title') }}
      </li>
    </ol>
  </nav>
</ng-container>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { WINDOW } from '@ng-web-apis/common';
import moment from 'moment/moment';
import { Options } from 'sortablejs';

import { User } from '@http/user/types/user.type';
import { EditUserPropertyModalComponent } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.component';
import { EDIT_USER_PROPERTY_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.token';
import { ModalHelperService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { App } from '@http/app/app.model';
import { UserSystemProperty } from '@http/property/property.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Component({
  selector: 'cq-user-location[currentApp][user]',
  templateUrl: './user-location.component.html',
  styleUrls: ['./user-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLocationComponent {
  /** Отключен ли класс property-body у детей кастомных и системных св-в */
  disablePropertyBodyClass: boolean = false;

  @Input()
  currentApp!: App;

  @Input()
  pinnedProps: Array<UserSystemProperty | '$carrotId' | '$userId' | string> = [];

  @Input()
  user!: User;

  @Output()
  changePinnedPropsOrder: EventEmitter<string[]> = new EventEmitter<string[]>();

  /** Колбэк на открытие модалки редактирования свойств пользователя */
  @Output()
  openEditPropModal?: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  removePinnedProp: EventEmitter<string> = new EventEmitter();

  /** Колбэк на смену емейла пользователя */
  @Output()
  updateActiveConversation: EventEmitter<string> = new EventEmitter<string>();

  /** Колбэк на смену значения свойства пользователя */
  @Output()
  updateProps: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Настройки для библиотеки sortablejs
   */
  sortablejsOptions: Options = {
    handle: '.drag',
    onUpdate: this.onOrderUpdate.bind(this),
    ghostClass: 'draggable-object-ghost',
    dragClass: 'draggable-object',
    animation: 300,
    onStart: this.onStartDrag.bind(this),
    onEnd: this.onEndDrag.bind(this),
  };

  /** Массив кастомных свойств пользователя */
  customProps: string[] = [];

  /**
   * Возможность закрепления св-ва.
   * если true, то будет закрепление св-в,
   * если false, то будет открепление св-в.
   */
  pinningPossibility = false;

  /** Соединяет город, регион, и страну для отображения в темплейте */
  get userLocation(): string {
    return [this.user.props['$city'], this.user.props['$region'], this.user.props['$country']]
      .filter(Boolean)
      .join(', ');
  }

  /** Определяет локальное время пользователя */
  get userTime(): null | string {
    return this.user.timezoneOffset !== null ? moment().utc().add(this.user.timezoneOffset, 'm').format('LT') : null;
  }

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    public readonly cdr: ChangeDetectorRef,
    private readonly modalHelperService: ModalHelperService,
    private readonly toastService: ToastService,
    private readonly translocoService: TranslocoService,
    private readonly uibModalService: UibModalService,
    private readonly carrotquestHelper: CarrotquestHelper,
  ) {}

  /** Функция на успешное копирование элемента */
  copiedSuccess() {
    const text = this.translocoService.translate('userLocationComponent.copiedTooltip');
    this.toastService.success(text);
  }

  /** Склеенное значение локации и времени */
  getUserTimeWithLocation() {
    if (!this.userLocation) {
      return undefined;
    }
    return `${this.userLocation} ${this.userTime}`;
  }

  /**
   * Открытие модалки редактирования свойства пользователя
   *
   * @param property
   */
  openEditUserPropertyModal(property: {
    propertyName: string | undefined;
    propertyValue: string | undefined | string[] | boolean;
  }): void {
    if (this.openEditPropModal && this.user.id) {
      this.openEditPropModal.emit();

      this.modalHelperService
        .provide(EDIT_USER_PROPERTY_MODAL_DATA_TOKEN, {
          propertyName: property.propertyName as string,
          propertyValue: property.propertyValue as string,
          userId: this.user.id,
        })
        .open(EditUserPropertyModalComponent, {
          centered: true,
          scrollable: true,
        })
        .result.then(
          (propertyValue: string) => {
            this.updateProps?.emit();

            if (property.propertyName === '$email') {
              this.updateActiveConversation?.emit(propertyValue);
            }
          },
          () => {},
        );
    }
  }

  /** Является ли св-во системным, искл. Почта и Телефон */
  isSystemProp(propName: UserSystemProperty | string | string[] | undefined): boolean {
    if (propName === '$email' || propName === '$phone') {
      return false;
    }
    if (propName) {
      return propName[0] === '$';
    }
    return false;
  }

  trackByFn(index: number, value: string) {
    return index;
  }

  /** Email пользователя */
  userEmail(newCard: boolean = false): string {
    // Для старой карточки возвращаем плейсхолдер
    if (!this.user.props['$email'] && !newCard) {
      return this.translocoService.translate('userMainPropsComponent.emailTextarea.placeholder');
    }
    // Для новой карточки возвращаем прочерк
    if (!this.user.props['$email'] && newCard) {
      return '–';
    }

    return this.user.props['$email'] as string;
  }

  /** Телефон пользователя */
  userPhone(newCard: boolean = false): string {
    // Для старой карточки возвращаем плейсхолдер
    if (!this.user.props['$phone'] && !newCard) {
      return this.translocoService.translate('userMainPropsComponent.phoneTextarea.placeholder');
    }
    // Для новой карточки возвращаем прочерк
    if (!this.user.props['$phone'] && newCard) {
      return '–';
    }

    return this.user.props['$phone'] as string;
  }

  /**
   * Модалка удаления свойства пользователя
   * @param propName
   */
  removeUserProperty(propName: string) {
    this.uibModalService
      .open({
        component: 'cqRemoveUserPropertyModal',
        resolve: {
          modalWindowParams: () => {
            return {
              currentAppId: this.currentApp.id,
              propertyName: propName,
              userId: this.user.id,
            };
          },
        },
        size: 'md modal-dialog-centered',
      })
      .result.then(() => {
        this.updateProps?.emit();
      });
  }

  /**
   * Функция обработки отпускания элемента drag&drop
   */
  onOrderUpdate(): void {
    this.changePinnedPropsOrder.emit(this.pinnedProps);
    this.carrotquestHelper.track('Карточка пользователя — переместил свойство');
  }

  /**
   * Функция обработки старта перемещения элемента.
   * Необходима, чтобы был скрыт hover у изначальной позиции элемента.
   */
  onStartDrag(): void {
    this.disablePropertyBodyClass = true;
    this.cdr.detectChanges();
  }

  /**
   * HACK:
   * Функция обработки окончания перемещения элемента.
   * Необходима, чтобы вернуть классы элементам после отпускания.
   */
  onEndDrag(): void {
    this.windowRef.document.addEventListener(
      'mousemove',
      () => {
        this.disablePropertyBodyClass = false;
        this.cdr.detectChanges();
      },
      {
        once: true,
      },
    );
  }
}

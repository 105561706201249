import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { MemberInvitationComponent } from './member-invitation.component';

@NgModule({
  declarations: [MemberInvitationComponent],
  exports: [MemberInvitationComponent],
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, SharedModule, TranslocoModule],
  providers: [],
})
export class MemberInvitationModule {}

/**
 * Модуль дополнений для number-директив
 * Каждая директива из этого модуля должна выполняться с большим приоритетом, т.к. если приоритет больше - postlink функция выполнится позже.
 * Это нужно для того, чтобы основные number-директивы отработали раньше, и гарантированно раньше поместили свои $parsers и $formatters в pipeline,
 * и таким образом передали Number парсерам и форматтерам дополнений
 */
(function () {
  'use strict';

  angular.module('myApp.directives.number.additions', ['myApp.directives.number']);

  require('./max/max.directive');
  require('./min/min.directive');
  require('./multiplier/multiplier.directive');
})();

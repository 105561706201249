(function () {
  'use strict';

  require('./plan-capability-paywall-wrapper.controller');

  angular.module('myApp.planCapability').component('cqPlanCapabilityPaywallWrapper', cqPlanCapabilityPaywallWrapper());

  function cqPlanCapabilityPaywallWrapper() {
    return {
      bindings: {
        billingInfo: '=', // Информация о биллинге
        currentApp: '=', // Текущее приложение
        djangoUser: '=', // Текущий пользователь
        onRequestPerformed: '<?', // функция, которая выполнится при нажатии на кнопку в правом верхнем углу
      },
      controller: 'CqPlanCapabilityPaywallWrapperController',
      controllerAs: 'vm',
      template: require('./plan-capability-paywall-wrapper.html'),
    };
  }
})();

<ng-container *transloco="let t; read: 'stripeThreeDSModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('heading') }}</h3>
    <button
      class="close"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body stripe-payments position-relative">
    <iframe
      [src]="iframeSrc"
      (load)="onLoad()"
      frameborder="0"
    ></iframe>
    <div
      *ngIf="(iframeLoaded | async) === false"
      class="align-items-center bg-white bottom-0 d-flex h-100 justify-content-center position-absolute right-0 text-center w-100"
    >
      <i class="cqi-sm cqi-spinner inline-block spin text-success"></i>
    </div>
  </div>
</ng-container>

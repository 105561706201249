<ng-container *transloco="let t; read: 'chatAudioMessageComponent'">
  <div class="custom-control custom-checkbox mb-5">
    <input
      class="custom-control-input"
      id="enable-audio-message"
      [formControl]="formControl"
      type="checkbox"
    />
    <label
      class="custom-control-label font-weight-bold"
      for="enable-audio-message"
      >{{ t('label') }}</label
    >
  </div>
  <div class="mb-20">
    {{ t('description.1') }}
    <a
      href="#"
      (click)="showPaywall()"
      >{{ t('description.2') }}</a
    >
    {{ t('description.3') }}
  </div>
  <img
    src="assets/img/{{ activeLang }}/chat-settings/audio-msg.svg"
    width="350"
  />
</ng-container>

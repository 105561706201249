import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.teamMembersGroupEditor')
    .controller('CqTeamMembersGroupEditorController', CqTeamMembersGroupEditorController);

  function CqTeamMembersGroupEditorController(
    $interpolate,
    $translate,
    $uibModal,
    toastr,
    ACTIONS_ON_TEAM_MEMBERS_GROUP,
    carrotquestHelper,
    validationHelper,
    teamMemberGroupModel,
  ) {
    var vm = this;

    /**
     * Максимальная длина названия группы членов команды
     *
     * @type {Number}
     */
    var GROUP_NAME_MAX_LENGTH = 100;

    vm.$onInit = init;

    function init() {
      vm.apiMessageAdminGroupExistsError = false; // флаг уже существующей группы с таким именем
      vm.confirm = confirm;
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса
      vm.isEdit = false; //Создание или редактирование группы
      vm.GROUP_NAME_MAX_LENGTH = GROUP_NAME_MAX_LENGTH;
      vm.openRemoveTeamMembersGroupModal = openRemoveTeamMembersGroupModal;
      vm.validationHelper = validationHelper;

      if (angular.isDefined(vm.resolve.group)) {
        vm.isEdit = true;
      } else {
        trackStartCreateGroup();
      }
    }

    /**
     * Закрытие модалки с подтверждением (снаружи вызовется successCallback)
     *
     * @param {boolean} isValid - Валидностиь формы
     */
    function confirm(isValid) {
      if (isValid) {
        vm.isApiRequestPerformed = true;
        vm.apiMessageAdminGroupExistsError = false;

        if (vm.isEdit) {
          firstValueFrom(teamMemberGroupModel.save(vm.resolve.group))
            .then(editGroupSuccess, createOrEditGroupError)
            .finally(createOrEditGroupFinally);
        } else {
          firstValueFrom(teamMemberGroupModel.create(vm.resolve.group.name))
            .then(createGroupSuccess, createOrEditGroupError)
            .finally(createOrEditGroupFinally);
        }
      }

      /**
       * Ошибка создания/редактирования группы
       *
       * @param response
       */
      function createOrEditGroupError(response) {
        if (response.meta && response.meta.error === 'AdminGroupExistsError') {
          vm.apiMessageAdminGroupExistsError = true;
        }
      }

      /**
       * Финальная стадия удаления/редактирования группы
       */
      function createOrEditGroupFinally() {
        vm.isApiRequestPerformed = false;
      }

      /**
       * Группа успешно создана
       *
       * @param group
       */
      function createGroupSuccess(group) {
        trackGroupCreated(group.name);
        toastr.success(
          $translate.instant('modals.teamMembersGroupEditor.toasts.teamMembersGroupCreatedSuccess', {
            name: group.name,
          }),
        );
        vm.modalInstance.close({
          action: ACTIONS_ON_TEAM_MEMBERS_GROUP.CREATE,
          group: group,
        });
      }

      /**
       * Группа успешно отредактирована
       *
       * @param group
       */
      function editGroupSuccess(group) {
        trackGroupChanged(vm.resolve.group.name);
        toastr.success($translate.instant('modals.teamMembersGroupEditor.toasts.teamMembersGroupEditedSuccess'));
        vm.modalInstance.close({
          action: ACTIONS_ON_TEAM_MEMBERS_GROUP.RENAME,
          group: group,
        });
      }
    }

    /**
     * Открытие модалки удаления группы членов команды
     *
     * @param {Object} group Группа членов команды
     */
    function openRemoveTeamMembersGroupModal() {
      var removeTeamMembersGroupModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.teamMembersGroupEditor.removeTeamMembersGroupModal.heading'),
              body: $translate.instant('modals.teamMembersGroupEditor.removeTeamMembersGroupModal.body', {
                groupName: vm.resolve.group.name,
              }),
              confirmButtonText: $translate.instant(
                'modals.teamMembersGroupEditor.removeTeamMembersGroupModal.confirmButtonText',
              ),
            };
          },
        },
      });

      removeTeamMembersGroupModal.result.then(removeTeamMembersGroupModalSuccess);

      function removeTeamMembersGroupModalSuccess() {
        firstValueFrom(teamMemberGroupModel.remove(vm.resolve.group.id)).then(removeTeamMembersGroupSuccess);

        function removeTeamMembersGroupSuccess() {
          trackGroupRemoved(vm.resolve.group.name);
          toastr.success(
            $translate.instant('modals.teamMembersGroupEditor.toasts.teamMembersGroupRemoveSuccess', {
              groupName: vm.resolve.group.name,
            }),
          );
          vm.modalInstance.close({
            action: ACTIONS_ON_TEAM_MEMBERS_GROUP.REMOVE,
            group: vm.resolve.group,
          });
        }
      }
    }

    /**
     * Трек изменения группы
     *
     * @param {String} groupName - Имя группы
     */
    function trackGroupChanged(groupName) {
      carrotquestHelper.track('Группы - переименовал группу', {
        'Название группы': groupName,
      });
    }

    /**
     * Трек создания группы
     *
     * @param {String} groupName - Имя группы
     */
    function trackGroupCreated(groupName) {
      carrotquestHelper.track('Группы - создал новую группу', {
        'Название группы': groupName,
      });
    }

    /**
     * Событие создания группы
     */
    function trackStartCreateGroup() {
      carrotquestHelper.track('Группы - начал создавать новую группу');
    }

    /**
     * Трек удаления группы
     *
     * @param {String} groupName - Имя группы
     */
    function trackGroupRemoved(groupName) {
      carrotquestHelper.track('Группы - удалил группу', {
        'Название группы': groupName,
      });
    }
  }
})();

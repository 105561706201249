import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import moment from 'moment/moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { CHAT_BOT_EXPORT_HEADER } from '@http/chat-bot-statistics/chat-bot-statistics.constant';
import {
  ApiChatBotExportParamsResponse,
  ApiChatBotStatistics,
  ApiChatBotStatisticsSendings,
  ApiChatBotStatisticsSendingsResponse,
  ChatBotExportParams,
  ChatBotStatisticsStats,
} from '@http/chat-bot-statistics/chat-bot-statistics.types';
import { APIPaginatableResponse } from '@http/types';
import { UserModel } from '@http/user/user.model';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

/**
 * Сервис для работы с статистикой чат-ботов
 */
@Injectable({ providedIn: 'root' })
export class ChatBotStatisticsModel {
  constructor(
    private readonly cookieService: CookieService,
    private readonly userModel: UserModel,
    private readonly transloco: TranslocoService,
    private readonly http: HttpClient,
  ) {}

  /**
   * Экспорт получателей в CSV
   *
   */
  exportSendings(chatBotId: string, params: ChatBotExportParams): void {
    const csvHeaders: [CHAT_BOT_EXPORT_HEADER, string][] = [];

    params.headers.forEach((header) => {
      csvHeaders.push([header, this.transloco.translate(`models.chatBotStatistics.exportHeaders.${header}`)]);
    });

    const requestParams: ApiChatBotExportParamsResponse = {
      app: params.appId,
      auth_token: 'appm-' + params.appId + '-' + this.cookieService.get('carrotquest_auth_token_panel'),
      start_date: params.startDate.format('YYYY-MM-DD'),
      end_date: params.endDate.format('YYYY-MM-DD'),
      interaction: params.typeInteraction,
      as_csv: 'true',
      csv_delimiter: params.delimiter ?? ';',
      csv_encoding: params.encoding ?? 'utf-8-sig',
      csv_headers: JSON.stringify(csvHeaders),
    };

    window.open(
      `${environment.apiEndpoint}/chat_bots/${chatBotId}/interactions/sendings?${new URLSearchParams(
        requestParams,
      ).toString()}`,
      '_blank',
    );
  }

  /**
   * Получение получателей по типу взаимодействия с чат-ботом
   *
   * @param chatBotId - ID чат-бота
   * @param params - Параметры для запроса
   */
  public getSendings(chatBotId: string, params: ApiChatBotStatisticsSendings['params']) {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = params.paginatorParams ?? {};

    const requestParams: ApiChatBotStatisticsSendings['request'] = {
      app: params.appId,
      start_date: params.startDate.format('YYYY-MM-DD'),
      end_date: params.endDate.format('YYYY-MM-DD'),
      filter_interaction: params.typeInteraction,
      include_user: true,
      reverse_filter: false,
      paginate_direction: paginateDirection,
      paginate_count: paginateCount,
      paginate_including: paginateIncluding,
      paginate_position: paginatePosition?.join() || undefined,
      paginate_page_order: paginatePageOrder,
    };

    return this.http
      .get<APIPaginatableResponse<ApiChatBotStatisticsSendingsResponse[]>>(
        `/chat_bots/${chatBotId}/interactions/sendings`,
        {
          params: requestParams,
          context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, ['props']).set(EXTENDED_RESPONSE, true),
        },
      )
      .pipe(
        map((response) => {
          response.data.forEach((sending: any) => {
            this.userModel.parseUser(sending.user, true).subscribe();
            sending.sent = moment(sending.sent, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
          });

          return {
            sendings: response.data,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.nextBeforePosition,
            },
          };
        }),
      );
  }

  /**
   * Получение статистики по пользователям, которым отправляется чат-бот
   *
   * @param chatBotId - ID чат-бота
   * @param params - Параметры для запроса
   */
  public getSendingsStatistics(
    chatBotId: string,
    params: ApiChatBotStatistics['params'],
  ): Observable<ChatBotStatisticsStats> {
    const requestParams: ApiChatBotStatistics['request'] = {
      app: params.appId,
      start_date: params.startDate.format('YYYY-MM-DD'),
      end_date: params.endDate.format('YYYY-MM-DD'),
    };

    return this.http.get<ChatBotStatisticsStats>(`/chat_bots/${chatBotId}/interactions/stats`, {
      params: requestParams,
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }
}

import { create, enforce, group, omitWhen, test } from 'vest';

import { MAX_AFTER_DELAY } from '@http/message/message.constants';
import { TriggerChainStepDelay } from '@http/trigger-chain/internal-types';

export const triggerChainDelayStepValidator = create((step: TriggerChainStepDelay) => {
  group(step.uuid, () => {
    test('name', () => {
      enforce(step.name).isNotEmpty();
    });

    test('meta.nextStep', () => {
      enforce(step.meta.nextStep).isNotNull();
    });

    omitWhen(step.meta.delay === null, () => {
      test('meta.delay.time', () => {
        enforce(step.meta.delay?.time).isNotNullish();
      });

      test('meta.delay.time', () => {
        enforce(step.meta.delay?.time).lessThanOrEquals(MAX_AFTER_DELAY);
      });
    });

    omitWhen(step.meta.delay !== null, () => {
      test('meta.nextStepOnFail', () => {
        enforce(step.meta.nextStepOnFail).isNotNull();
      });
    });
  });
});

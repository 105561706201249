<ng-container *transloco="let t; read: 'messageEditorDispatchUrlComponent'">
  <cq-message-editor-switch
    [heading]="t('heading')"
    [switched]="typeControl.value !== SENDING_FILTERS_GROUP_TYPES.NO"
    (switchedChange)="onSwitchChange($event)"
    [disabled]="disableSwitch"
    [tooltip]="t('headingTooltip', { entityName: entityName })"
  >
    <div class="btn-group btn-group-toggle mb-5">
      <label
        class="btn btn-outline-primary"
        [class.active]="typeControl.value === filter"
        *ngFor="let filter of availableSendingFilters"
      >
        <input
          type="radio"
          [formControl]="typeControl"
          [value]="filter"
        />
        <span [innerHTML]="t('dispatchUrlEnablingRadioGroup.' + filter)"></span>
      </label>
    </div>
    <cq-url-filter-configurator
      [formArray]="control.controls.filters"
      [options]="urlFilterConfiguratorOptions"
    ></cq-url-filter-configurator>
  </cq-message-editor-switch>
</ng-container>

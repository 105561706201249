import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { MessageEditorCollapseModule } from '@panel/app/partials/message-editor/shared/message-editor-collapse/message-editor-collapse.module';

import { TgTriggerTypesModule } from '../trigger-types/tg-trigger-types.module';
import { TgTriggerComponent } from './tg-trigger.component';

@NgModule({
  exports: [TgTriggerComponent],
  declarations: [TgTriggerComponent],
  imports: [CommonModule, MessageEditorCollapseModule, TgTriggerTypesModule, TranslocoModule],
})
export class TgTriggerModule {}

<ng-container *transloco="let t; read: 'uniqUsersComponent'">
  <ng-container
    *tuiLet="{
      billing: subscriptionStore.billing$ | async,
      plan: subscriptionStore.plan$ | async,
      quotas: subscriptionStore.quotas$ | async
    } as values"
  >
    <ng-container *ngIf="values.plan && values.quotas">
      <div class="d-flex justify-content-between align-content-center">
        <div class="font-weight-bold">
          <span>{{ t('title') }}</span>
          <i
            class="cqi-sm cqi-question-circle ml-5"
            [ngbTooltip]="
              t('tooltip', {
                price: values.plan.overspendingUniqueUserPrice | toMoney | toFormattedMoney
              })
            "
            container="body"
            placement="top"
          ></i>
        </div>

        <!-- На активной подписке показываем израсходованное количество уникальных пользователей вместе с квотой  -->
        <span *ngIf="values.billing && !values.billing.subscription.isTrial">
          {{ values.quotas.spentUniqueUsers | toFormattedNumber }} /
          {{ values.plan.availableUniqueUsers | toFormattedNumber }}
        </span>

        <!-- На отменённой подписке и триале показываем израсходованное количество уникальных пользователей без квоты  -->
        <span *ngIf="values.billing && values.billing.subscription.isTrial">{{
          values.quotas.spentUniqueUsers | toFormattedNumber
        }}</span>
      </div>

      <ngb-progressbar
        *ngIf="values.billing && !values.billing.subscription.isTrial"
        class="mt-10 mb-5"
        [type]="values.quotas.getOverspendingUniqueUsers(values.plan.availableUniqueUsers) ? 'danger' : 'success'"
        [value]="values.quotas.spentUniqueUsers / values.plan.availableUniqueUsers | percent | toNumber"
      ></ngb-progressbar>

      <small
        *ngIf="
          values.billing &&
          !values.billing.subscription.isTrial &&
          values.quotas.getOverspendingUniqueUsers(values.plan.availableUniqueUsers)
        "
        class="d-block text-danger mt-10"
      >
        {{
          t('overspending', {
            price:
              values.quotas.getOverspendingUniqueUsers(values.plan.availableUniqueUsers) *
                values.plan.overspendingUniqueUserPrice
              | toMoney
              | toFormattedMoney
          })
        }}
      </small>
    </ng-container>
  </ng-container>
</ng-container>

import { INTEGRATION_TYPES } from './constants/integration.constants';

/**
 * Класс для работы с простыми интеграциями
 *
 * NOTE:
 *  Простые интеграции не требуют хранения настроек на backend, все настройки хранятся на стороне интеграции
 *  Пример: Alloka
 */
export abstract class SimpleIntegration {
  /** Название интеграции */
  name!: string;
  /** Тип интеграции */
  abstract type: INTEGRATION_TYPES;
}

<ng-container *transloco="let t">
  <div class="flex flex-col">
    <div
      class="form-group"
      [cqShowError]="actionForm.controls.keyName"
    >
      <label class="font-weight-normal small">{{ t('chatBot.botActionProperty.propertySelect.label') }}:</label>
      <cq-property-selector
        [currentApp]="currentApp"
        [formControl]="actionForm.controls.keyName"
        [(properties)]="propertyOptions.userProps"
        (selectedChange)="onChangeProperty($event)"
      >
      </cq-property-selector>
    </div>
    <div [cqShowError]="actionForm.controls.body">
      <label
        class="font-weight-normal small"
        for="propertyValue"
        >{{ t('chatBot.botActionProperty.propertyValueInput.label') }}:</label
      >
      <input
        class="form-control"
        id="propertyValue"
        required
        type="text"
        [formControl]="actionForm.controls.body"
        placeholder="{{ t('chatBot.botActionProperty.propertyValueInput.placeholder') }}"
      />
      <cq-validation-messages [control]="actionForm.controls.body">
        <cq-validation-message errorName="required">
          {{ t('chatBot.botActionProperty.propertyValueInput.errors.required') }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cq-article-preview-modal',
  templateUrl: './article-preview-modal.component.html',
  styleUrls: ['./article-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePreviewModalComponent {
  @Input()
  modalWindowParams: any;

  constructor(protected readonly activeModal: NgbActiveModal) {}
}

/**
 * Контроллер шаблона
 */

import { ConditionsSendingModel } from '../../../../../app/services/conditions-sending/conditions-sending.model';
import cloneDeep from 'lodash-es/cloneDeep';
import { TIME_UNIT_MEASURES, TIME_UNITS } from '../../../../shared/services/time-unit/time-unit.constants';
import { REPEAT_MAX_DELAY } from '../../../../../app/services/conditions-sending/conditions-sending.constants';
import { AUDIENCE_FILTER_TYPE } from '../../../../../app/http/message/message.constants';

(function () {
  'use strict';

  angular
    .module('myApp.triggerChains.sendingConditionsEditor')
    .controller('CqTriggerChainSendingConditionsEditorController', CqTriggerChainSendingConditionsEditorController);

  /**
   * @param $translate
   * @param {filterAjsModel} filterAjsModel
   * @constructor
   */
  function CqTriggerChainSendingConditionsEditorController($translate, filterAjsModel, timeUnitService) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      /** @type TriggerChainStepSendingConditions */
      vm.sendingConditionsStep = cloneDeep(vm.resolve.sendingConditionsStep);
      vm.sendingConditionsForm = null;
      vm.filtersForm = null;
      vm.deleteMessageForm = null;

      vm.message = getSettingsAsMessage(vm.resolve.sendingConditionsStep);
      vm.onTriggerWrapperStateLoaded = onTriggerWrapperStateLoaded;
      vm.translationEntityName = $translate.instant('triggerChainSendingConditionsEditor.entityName');
      vm.formSubmitSource = null; // Subject, чтоб уведомлять контролы триггеров о сабмите формы
      vm.validateTriggerFn = null;
      vm.currentApp = vm.resolve.currentApp;
      vm.properties = vm.resolve.properties;
      vm.tags = [
        ...vm.resolve.tags.filter((tag) => !tag.removed),
        // копипаста из автосообщений, не спрашивай (включая коммент ниже)
        // NOTE Трогать с осторожностью! Такая хитрая фильтрация тегов для того, чтобы показывать только не удаленные теги и удаленные, но которые находятся в фильтрах
        ...vm.sendingConditionsStep.meta.filters.filters.tags.map((item) => item.tag).filter((tag) => !!tag.removed),
      ];
      vm.shownAudienceFilterType = vm.message.jinjaFilterTemplate
        ? AUDIENCE_FILTER_TYPE.JINJA
        : AUDIENCE_FILTER_TYPE.DEFAULT;
      vm.message.isMessageHaveFilters =
        filterAjsModel.isMessageHaveFilters(vm.message) || vm.message.jinjaFilterTemplate !== null;

      vm.segments = vm.resolve.segments;
      vm.hasDeletableMessagePart = () => false;
      /** @type MessageEditorTriggerParams */
      vm.triggerParams = extractTriggerParams(vm.resolve.sendingConditionsStep);

      // Костыль для валидации новых компонентов при попытке выхода из вкладки триггеров
      // Валидаторы по дефолту возвращают true, чтоб клиента не останавливало, когда он пролистывает вкладки.
      // Если он только зашел на вкладку мы подразумеваем, что у него все валидно
      vm.triggerNewComponentValidators = {
        areSendingHoursValid: () => Promise.resolve(true),
        isDispatchValid: () => Promise.resolve(true),
        isSendingDelayValid: () => Promise.resolve(true),
        isSendingRepeatValid: () => Promise.resolve(true),
      };

      vm.submit = submit;
      vm.onTriggerParamsChange = onTriggerParamsChange;
    }

    /**
     * @param {TriggerChainStepSendingConditions} sendingConditionsStep
     *
     * @returns MessageEditorTriggerParams
     */
    function extractTriggerParams(sendingConditionsStep) {
      const { meta } = sendingConditionsStep;
      return {
        triggers: meta.triggers,
        triggerTypes: meta.triggerTypes,
        delay: meta.delay,
        // эти фильтры нужны в целом в каком-то виде, потому что переиспользуем компонент из триггерных
        sendingFilters: null,
      };
    }

    /**
     *
     * @param {MessageEditorTriggerParams} triggerParams
     */
    function onTriggerParamsChange(triggerParams) {
      // обновляем currentMessage
      vm.sendingConditionsStep.meta.triggers = triggerParams.triggers;
      vm.sendingConditionsStep.meta.triggerTypes = triggerParams.triggerTypes;
      vm.sendingConditionsStep.meta.delay = triggerParams.delay;
    }

    /**
     * @param {MessageEditorTriggerState} state
     */
    function onTriggerWrapperStateLoaded(state) {
      state.autoEvents$.next(vm.resolve.autoEvents);
      state.eventTypes$.next(vm.resolve.eventTypes);
      state.userProps$.next(vm.properties.userProps);
      state.currentApp$.next(vm.currentApp);
    }

    /**
     * Заворачивает все настройки условий отправки в объект сообщения.
     * Объект сообщения нам нужен, потому что этот компонент переиспользует настройки автосообщений,
     * которые требуют наличия объекта сообщения
     *
     * @param {TriggerChainStepSendingConditions} sendingConditionsStep
     */
    function getSettingsAsMessage(sendingConditionsStep) {
      const { meta } = sendingConditionsStep;
      const message = angular.extend(
        {
          // ВИД И СОДЕРЖАНИЕ
          closePreviousTestGroup: false, // закрывать предыдущую тест-группу или нет
          generateName: true, // использовать сгенерированное имя сообщения
          // ПРОВЕРКА И ЗАПУСК
          name: '',
          eventShow: false,
          eventMessage1: false,
          eventMessage2: false,
          eventMessage3: false,
          eventMessage4: false,
          eventMessage5: false,
          eventSended: '',
          eventRead: '',
          eventReplied: '',
          eventClicked: '',
          eventUnsubscribed: '',
          active: false,
          directory: {
            id: null,
            name: '',
            isSystem: false,
          },
        },
        Object.assign(ConditionsSendingModel.getDefault(), {
          triggerTypes: {
            openedWebPageTriggers: meta.triggerTypes.openedWebPageTriggers,
            leaveSiteAttemptTrigger: meta.triggerTypes.leaveSiteAttemptTrigger,
          },
          userStatuses: meta.userStatuses,
          filters: meta.filters,
          ...meta.sendingTime,
          ...meta.sendingRepeat,
          jinjaFilterTemplate: meta.jinjaFilterTemplate,
          isRepeat: meta.sendingRepeat.isEnabled,
          repeatDelay: meta.sendingRepeat.repeatDelay,
          repeatDelayTimeUnit: timeUnitService.getByValue(meta.sendingRepeat.repeatDelay, [
            TIME_UNITS.MINUTE,
            TIME_UNITS.HOUR,
            TIME_UNITS.DAY,
          ]),
          repeatDelayTimeUnits: [TIME_UNITS.MINUTE, TIME_UNITS.HOUR, TIME_UNITS.DAY],
          notSendReplied: meta.sendingRepeat.notSendReplied,
        }),
      );
      message.isMessageHaveFilters = filterAjsModel.isMessageHaveFilters(message);

      return message;
    }

    /**
     *
     * @param message
     * @param {TriggerChainStepSendingConditions} sendingConditionsStep
     * @returns TriggerChainStepSendingConditions
     */
    function extractSettingsFromMessage(message, sendingConditionsStep) {
      if (vm.shownAudienceFilterType === AUDIENCE_FILTER_TYPE.DEFAULT) {
        message.jinjaFilterTemplate = null;
      } else {
        message.filters = {
          type: 'and',
          filters: {
            props: [],
            events: [],
            tags: [],
          },
        };
      }

      return {
        ...sendingConditionsStep,
        meta: {
          ...sendingConditionsStep.meta,
          userStatuses: message.userStatuses,
          filters: message.filters,
          jinjaFilterTemplate: message.jinjaFilterTemplate,
          sendingTime: {
            isSendAtTime: message.isSendAtTime,
            sendTimeValueFromH: message.sendTimeValueFromH,
            sendTimeValueFromM: message.sendTimeValueFromM,
            sendTimeValueToH: message.sendTimeValueToH,
            sendTimeValueToM: message.sendTimeValueToM,
          },
          sendingRepeat: {
            isEnabled: message.isRepeat,
            repeatDelay: message.repeatDelay,
            notSendReplied: message.notSendReplied,
          },
        },
      };
    }

    function submit() {
      isFormValid().then((valid) => {
        if (!valid) {
          vm.formSubmitSource.next();
          return;
        }

        vm.sendingConditionsStep = extractSettingsFromMessage(vm.message, vm.sendingConditionsStep);
        vm.modalInstance.close(vm.sendingConditionsStep);
      });
    }

    /**
     * @returns Promise<boolean>
     */
    function isFormValid() {
      return Promise.all([
        vm.validateTriggerFn(),
        vm.triggerNewComponentValidators.isSendingRepeatValid(),
        Promise.resolve(vm.filtersForm.$valid),
      ]).then((res) => {
        return res.reduce((p, c) => p && c, true);
      });
    }
  }
})();

<section id="content">
  <div class="container-fluid limited-container">
    <cq-permission-denied *ngIf="!accessToUsersEvents.hasAccess"></cq-permission-denied>
    <div *ngIf="accessToUsersEvents.hasAccess">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li
            class="breadcrumb-item active"
            aria-current="page"
          >
            {{ 'eventsAnalytics.heading.breadcrumbs.eventReport' | transloco }}
          </li>
        </ol>
      </nav>
      <div
        class="margin-bottom-20"
        [innerHTML]="'eventsAnalytics.heading.description' | transloco"
      ></div>
      <div class="card no-margin-bottom">
        <div class="card-body">
          <div>
            <div class="flex margin-bottom-20 margin-between-cols-20">
              <div class="flex-grow">
                <cq-event-type-select
                  class="event-select"
                  [bindValue]="'id'"
                  [currentApp]="currentApp"
                  [eventTypes]="eventTypes"
                  [multiple]="true"
                  [showHeader]="false"
                  [showFooter]="false"
                  [closeOnSelect]="false"
                  [formControl]="selectedEventTypeIdsControl"
                ></cq-event-type-select>
              </div>
              <div class="graph-type-select-container no-flex-shrink">
                <ng-select
                  [searchable]="false"
                  [clearable]="false"
                  [(ngModel)]="graphType"
                  (change)="update(this.selectedEventTypeIdsControl.getRawValue())"
                  [items]="GRAPH_TYPES_ARRAY"
                >
                  <ng-template
                    ng-label-tmp
                    ng-option-tmp
                    let-item="item"
                  >
                    <span>{{ 'eventsAnalytics.graphTypeSelect.' + item | transloco }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div
              *ngIf="!loaded"
              class="loader-chart"
            >
              <cq-loader></cq-loader>
            </div>
            <div
              class="chart-div"
              id="chart-div"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

(function () {
  'use strict';

  angular.module('myApp.directives.dynamicIframe').controller('CqDynamicIframeController', CqDynamicIframeController);

  function CqDynamicIframeController($attrs) {
    var vm = this;

    vm.adjustHeight = adjustHeight;
    vm.adjustWidth = adjustWidth;
    vm.changeTypeOs = changeTypeOs;
    vm.forceResize = forceResize;
    vm.setId = setId;
    vm.setLocale = setLocale;
    vm.setTemplate = setTemplate;
    vm.updateIframe = updateIframe;

    /**
     * Подгонка высоты iframe под высоту контента внутри него
     *
     * @param {Object} iframeElement Элемент iframe
     * @param {Number} height высота оторую необходимо установит iframe
     */
    function adjustHeight(iframeElement, height) {
      if (angular.isDefined($attrs.fitByContentHeight) && iframeElement.is(':visible')) {
        iframeElement.height(height);
      }
    }

    /**
     * Подгонка ширины iframe под ширину контента внутри него
     *
     * @param {Object} iframeElement Элемент iframe
     * @param {Number} width ширина которую необходимо установить iframe
     */
    function adjustWidth(iframeElement, width) {
      if (angular.isDefined($attrs.fitByContentWidth) && iframeElement.is(':visible')) {
        iframeElement.width(width);
      }
    }

    /**
     * Смена операционной системы для просмотра превью для "Push в SDK"
     *
     * @param iframeElement Элемент iframe
     * @param {string} typeOs - Тип операционной системы, в которой необходимо показать превью Push
     */
    function changeTypeOs(iframeElement, typeOs) {
      postMessage(iframeElement, 'changeTypeOs', typeOs);
    }

    /**
     * Принудительный перерасчёт высоты и ширины внутри iframe
     *
     * @param {Object} iframeElement Элемент iframe
     */
    function forceResize(iframeElement) {
      postMessage(iframeElement, 'forceResize');
    }

    /**
     * Установка локали внутри iframe
     *
     * @param {Object} iframeElement Элемент iframe
     * @param {String} localeId ID локали (подробности смотри в функции setLocale внутри iframe)
     */
    function setLocale(iframeElement, localeId) {
      postMessage(iframeElement, 'setLocale', localeId);
    }

    /**
     * Передача темплейта для вывода внутрь iframe
     *
     * @param {Object} iframeElement Элемент iframe
     * @param {String} template Темплейт в виде строки, который компилируется уже внутри iframe
     */
    function setTemplate(iframeElement, template) {
      postMessage(iframeElement, 'setTemplate', template);
    }

    /**
     * Задает id iframe и body
     *
     * @param {Object} iframeElement Элемент iframe
     * @param {String} frameId id для iframe
     */
    function setId(iframeElement, frameId) {
      iframeElement.attr('id', frameId);
      postMessage(iframeElement, 'setId', frameId);
    }

    /**
     * Вызов функции обновления внутри iframe
     *
     * @param {Object} iframeElement Элемент iframe
     * @param {Object} content Контент, передаваемый в iframe
     */
    function updateIframe(iframeElement, content) {
      postMessage(iframeElement, 'updateIframe', content);
    }

    /**
     * Функция postMessage для отправки сообщений в iframe
     *
     * @param {Object} iframeElement Элемент iframe
     * @param {String} command тип сообщения
     * @param {String=} value значение
     */
    function postMessage(iframeElement, command, value) {
      var data = {
        command: command,
        value: value,
      };
      iframeElement[0].contentWindow.postMessage(data, '*');
    }
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cq-switch-wrapper',
  templateUrl: './switch-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchWrapperComponent {
  @Input()
  value: boolean = false;
  @Input()
  disabled: boolean = false;

  @Output()
  valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}
}

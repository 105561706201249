import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tuiPure } from '@taiga-ui/cdk';
import { debounceTime, distinctUntilChanged, map, Observable, startWith } from 'rxjs';

import { PSEUDO_CHANNEL_TYPES } from '@http/channel/channel.constants';
import { Channel } from '@http/channel/channel.model';
import { DestroyService } from '@panel/app/services';

@Component({
  selector: 'cq-channel-assignment[assignedChannel][channels]',
  templateUrl: './channel-assignment.component.html',
  styleUrls: ['./channel-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ChannelAssignmentComponent {
  /** Канал, на который назначен диалог */
  @Input()
  assignedChannel!: Channel;

  @Input()
  channels!: Channel[];

  /** Отключение возможности переназначить канал */
  @Input()
  disabled: boolean = false;

  @Output()
  assignedChannelChange = new EventEmitter<Channel>();

  /** Фильтрация по названию канала */
  nameFilter = new FormControl('', { nonNullable: true });

  filteredChannels$: Observable<Channel[]> = this.nameFilter.valueChanges.pipe(
    debounceTime(400),
    startWith(''),
    distinctUntilChanged(),
    map(() => {
      return this.filterByName(this.orderedChannels, this.nameFilter.value);
    }),
  );

  constructor(public readonly cdr: ChangeDetectorRef, public readonly destroy$: DestroyService) {}

  get orderedChannels(): Channel[] {
    return this.orderByPriority(this.channels);
  }

  /**
   * Переназначение диалога на канал
   *
   * @param channel Канал
   */
  assignTo(channel: Channel): void {
    if (this.assignedChannel === channel) {
      return;
    }

    this.assignedChannel = channel;
    this.assignedChannelChange.emit(channel);
  }

  /**
   * Фильтрация списка каналов по имени
   *
   * @param channels Список каналов
   * @param filter Фильтр по имени
   */
  @tuiPure
  filterByName(channels: Channel[], filter: string): Channel[] {
    return channels.filter((channel) => channel.name.toLowerCase().includes(filter.toLowerCase()));
  }

  /**
   * Упорядочивание каналов по приоритету
   *
   * @param channels Список каналов
   */
  @tuiPure
  orderByPriority(channels: Channel[]): Channel[] {
    return channels
      .filter((channel) => channel.type !== PSEUDO_CHANNEL_TYPES.ALL_CHANNELS)
      .sort((a, b) => a.priority - b.priority);
  }

  /** Трек по ID канала */
  trackByChannelId(index: number, channel: Channel): string | null {
    return channel.id;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';
import { NgObjectPipesModule } from 'ngx-pipes';

import { AddonModule } from '@panel/app/pages/subscription/general/signed/addons/addon/addon.module';
import { AddonsComponent } from '@panel/app/pages/subscription/general/signed/addons/addons.component';
import { UpgradeModule } from '@panel/app/pages/subscription/general/signed/addons/upgrade/upgrade.module';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [AddonsComponent],
  exports: [AddonsComponent],
  imports: [AddonModule, CommonModule, TranslocoModule, TuiLetModule, SharedModule, NgObjectPipesModule, UpgradeModule],
  providers: [SubscriptionStore],
})
export class AddonsModule {}

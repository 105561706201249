(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupCartBlockPreviewController', CqPopupCartBlockPreviewController);

  function CqPopupCartBlockPreviewController($q, $scope, $translate, $templateRequest, l10nHelper) {
    var vm = this;

    /**
     * Дефолтный товар
     *
     * @type {{name: string, price: number, img: string}}
     */
    var DEFAULT_CART_ITEM = {
      name: '',
      price: 0,
      img: '',
    };

    /**
     * Типы товаров
     *
     * @type {{APPLE: string, BANANA: string, CACTUS: string, LAMP: string, SOCKS: string}}
     */
    var CART_ITEM_TYPES = {
      APPLE: 'apple',
      BANANA: 'banana',
      CACTUS: 'cactus',
      LAMP: 'lamp',
      SOCKS: 'socks',
    };

    var cartItems = []; // Список товаров

    vm.$onInit = init;

    function init() {
      vm.parsedParams = null; // скорректированные параметры поп-апа
      vm.template = ''; // строка, содержащая html и css-код, необходимый для работы превью блока

      getTemplate();

      $scope.$watchCollection('vm.params', parseParams);
    }

    /**
     * Получения темплейта для блока поп-апа
     */
    function getTemplate() {
      var templateRequests = [];

      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/general-style.scss'));
      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/cart/cart.scss'));
      templateRequests.push($templateRequest('js/components/popup-editor/block-preview/cart/cart.html'));

      $q.all(templateRequests).then(getTemplatesSuccess);

      function getTemplatesSuccess(templates) {
        // NOTE: стили нужно обернуть в тег, т.к. итоговая строка должна быть валидным html
        vm.template = '<style>' + templates[0] + templates[1] + '</style>' + templates[2];
      }
    }

    /**
     * Получение рандомного товара
     */
    function getRandomItem() {
      var itemCart = angular.copy(DEFAULT_CART_ITEM);
      var cartItemTypesKeys = Object.keys(CART_ITEM_TYPES);
      var randomItemCartType = CART_ITEM_TYPES[cartItemTypesKeys[(cartItemTypesKeys.length * Math.random()) << 0]];

      itemCart.name = $translate.instant('popupCartBlockPreview.cartTypes.' + randomItemCartType);
      itemCart.img = 'assets/img/default/popup-editor/{{itemType}}.png'.replace('{{itemType}}', randomItemCartType);
      itemCart.price = (l10nHelper.isUsCountry() ? 100 : (10000 * Math.random()) << 0)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

      return itemCart;
    }

    /**
     * Парсинг исходных параметров блока
     *
     * @param {Object} params - Параметры блока
     */
    function parseParams(params) {
      if (params) {
        vm.parsedParams = angular.copy(params);

        while (vm.parsedParams.itemsCount && cartItems.length !== vm.parsedParams.itemsCount) {
          if (cartItems.length > vm.parsedParams.itemsCount) {
            cartItems.splice(-1, 1);
          } else if (cartItems.length < vm.parsedParams.itemsCount) {
            cartItems.push(getRandomItem());
          }
        }

        vm.parsedParams.cartItems = cartItems;
        vm.parsedParams.detailButtonText = $translate.instant('popupCartBlockPreview.detailButton');
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'engagementInConversationComponent'">
  <ngb-alert
    *ngIf="isTriggerMessageStepMade"
    [dismissible]="false"
    class="mb-30"
    type="success"
  >
    <i class="cqi-sm cqi-check-circle mr-10"></i>
    {{ t('successAlert.triggeredMessage') }}
  </ngb-alert>

  <ngb-alert
    *ngIf="isLeadsCollectionStepMade && !isTriggerMessageStepMade"
    [dismissible]="false"
    class="mb-30"
    type="success"
  >
    <i class="cqi-sm cqi-check-circle mr-10"></i>
    {{ t('successAlert.leadBot') }}
  </ngb-alert>

  <div class="d-flex mb-20 justify-space-between">
    <div class="white-space-pre-line">{{ t('description', { projectName: projectName }) }}</div>
    <a
      class="cursor-pointer"
      (click)="openViewVideoModal()"
    >
      <i class="cqi-sm cqi-play-circle"></i>
      <span>{{ t('video.text') }}</span>
    </a>
  </div>
  <div class="card-deck">
    <div class="card mr-10">
      <div class="row no-gutters">
        <div class="col-5 bg-light-primary min-height-200 d-flex justify-content-center align-items-center">
          <img
            class="w-100"
            src="assets/img/{{
              activeLang
            }}/starter-guide/engagement-in-conversation/onoarding_v2_engagement_message.png"
          />
        </div>
        <div class="col-7">
          <div class="px-20 py-30">
            <div class="font-weight-bold mb-10">{{ t('triggerMessage.heading') }}</div>
            <div class="text-secondary white-space-pre-line mb-15">{{ t('triggerMessage.description') }}</div>
            <button
              *ngIf="accessToAutoMessagesTotal.hasAccess"
              (click)="goToTriggerMessageCreation()"
              class="btn btn-primary"
            >
              {{ t('triggerMessage.button') }}
            </button>
            <cq-access-denial-popover
              *ngIf="!accessToAutoMessagesTotal.hasAccess"
              [currentApp]="app"
              [denialReason]="accessToAutoMessagesTotal.denialReason"
              [popoverPlacement]="'bottom-left'"
            >
              <a class="btn btn-primary">
                <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
                <span>{{ t('triggerMessage.button') }}</span>
              </a>
            </cq-access-denial-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="card ml-10">
      <div class="row no-gutters">
        <div class="col-5 bg-light-primary min-height-200 d-flex justify-content-center align-items-center">
          <img
            class="w-100"
            src="assets/img/{{ activeLang }}/starter-guide/engagement-in-conversation/onoarding_v2_engagement_bot.png"
          />
        </div>
        <div class="col-7">
          <div class="px-20 py-30">
            <div class="font-weight-bold mb-10">{{ t('leadbot.heading') }}</div>
            <div class="text-secondary mb-15">{{ t('leadbot.description') }}</div>
            <button
              *ngIf="accessToLeadBots.hasAccess"
              (click)="goToBotCreation()"
              class="btn btn-primary"
            >
              {{ t('leadbot.button') }}
            </button>
            <cq-access-denial-popover
              *ngIf="!accessToLeadBots.hasAccess"
              [currentApp]="app"
              [denialReason]="accessToLeadBots.denialReason"
              [popoverPlacement]="'bottom-left'"
            >
              <a class="btn btn-primary">
                <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
                <span>{{ t('leadbot.button') }}</span>
              </a>
            </cq-access-denial-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

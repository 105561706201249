import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EXTENDED_RESPONSE } from '@panel/app/shared/constants/http.constants';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Преобразует результат удачно выполненных запросов
 */
@Injectable()
export class SuccessResponseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (needToSkipInterceptors(request)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          if (request.context.has(EXTENDED_RESPONSE)) {
            event = event.clone({
              body: {
                data: event.body.data,
                meta: event.body.meta,
              },
            });
          } else {
            event = event.clone({
              body: event.body.data,
            });
          }
        }

        return event;
      }),
    );
  }
}

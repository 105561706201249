import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbAlert, NgbCollapse, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TuiLetModule } from '@taiga-ui/cdk';
import { UIRouterModule } from '@uirouter/angular';

import { AppService } from '@http/app/services/app.service';
import { AiDataBotSettingsStateService } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/ai-data-bot-settings-state.service';
import { CommunicationStyleControlComponent } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/communication-style-control/communication-style-control.component';
import { NonWorkingTimeReplyControlComponent } from '@panel/app/pages/ai-data-sources/ai-data-bot-settings/non-working-time-reply-control/non-working-time-reply-control.component';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AddressFormControlComponent } from './address-form-control/address-form-control.component';
import { AiDataBotSettingsComponent } from './ai-data-bot-settings.component';
import { BotRuleControlComponent } from './bot-rules-control/bot-rule-control.component';
import { CloseTimeoutControlComponent } from './close-timeout-control/close-timeout-control.component';
import { CompanyInfoControlComponent } from './company-info-control/company-info-control.component';
import { CompanyNameControlComponent } from './company-name-control/company-name-control.component';
import { CreativityLevelControlComponent } from './creativity-level-control/creativity-level-control.component';
import { RepliesLengthControlComponent } from './replies-length-control/replies-length-control.component';
import { StopPhrasesControlComponent } from './stop-phrases-control/stop-phrases-control.component';
import { UserEventControlComponent } from './user-event-control/user-event-control.component';
import { UserTagControlComponent } from './user-tag-control/user-tag-control.component';
import { WorkingTimeReplyControlComponent } from './working-time-reply-control/working-time-reply-control.component';

@NgModule({
  declarations: [
    AiDataBotSettingsComponent,
    CommunicationStyleControlComponent,
    AddressFormControlComponent,
    RepliesLengthControlComponent,
    CreativityLevelControlComponent,
    StopPhrasesControlComponent,
    WorkingTimeReplyControlComponent,
    NonWorkingTimeReplyControlComponent,
    CloseTimeoutControlComponent,
    UserTagControlComponent,
    UserEventControlComponent,
    BotRuleControlComponent,
    CompanyNameControlComponent,
    CompanyInfoControlComponent,
  ],
  imports: [
    CommonModule,
    TranslocoDirective,
    UIRouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
    TuiLetModule,
    NgxSliderModule,
    NgbAlert,
    NgbTooltip,
    NgbCollapse,
    FormsModule,
  ],
  providers: [AiDataBotSettingsStateService, AppService],
})
export class AiDataBotSettingsModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { ConversationPartPreviewAvatarComponent } from './conversation-part-preview-avatar.component';

@NgModule({
  declarations: [ConversationPartPreviewAvatarComponent],
  exports: [ConversationPartPreviewAvatarComponent],
  imports: [CommonModule, TranslocoModule],
})
export class ConversationPartPreviewAvatarModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

/** Компонент для работы с шапкой шага в стартергайде */
@Component({
  selector: 'cq-step-header[isOpened]',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepHeaderComponent {
  /** Шаг открыт */
  @Input()
  isOpened!: boolean;

  /** Callback на toggle */
  @Output()
  toggle: EventEmitter<void> = new EventEmitter<void>();

  /** Обработчик клика */
  @HostListener('click')
  onClick() {
    this.toggle.emit();
  }

  /** Получение класса для toggle-кнопки */
  getIconClass(): string {
    return this.isOpened ? 'cqi-chevron-up' : 'cqi-chevron-down';
  }
}

(function () {
  'use strict';

  angular.module('myApp.directives.chart').controller('CqChartController', CqChartController);

  function CqChartController($element, $attrs, chartHelper) {
    var vm = this;
    vm.createChart = createChart;

    /**
     * Создание нового графика ChartJs
     */
    function createChart() {
      vm.instance = chartHelper.createChart($element[0], vm.data.type, vm.data.data, vm.data.options);

      // если есть атрибут legend - создаётся легенда ChartJs при помощи generateLegend()
      if (angular.isDefined($attrs.legend)) {
        var legend, legendElement;
        legend = vm.instance.generateLegend();
        legendElement = angular.element(vm.legend);

        // если атрибут являлся селектором - вставляем легенду внутрь элемента, найденного по селектору, иначе - после елемента директивы
        if (legendElement[0]) {
          legendElement.append(legend);
        } else {
          $element.after(legend);
        }
      }
    }
  }
})();

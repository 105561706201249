import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { NgbProgressbar, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cq-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressbarComponent extends NgbProgressbar implements OnInit {
  @ContentChild('leftContent')
  leftContent: TemplateRef<unknown> | null = null;

  @ContentChild('rightContent')
  rightContent: TemplateRef<unknown> | null = null;

  @HostBinding('class.progress')
  progressClass = false; // HACK Из-за наследования класс NgbProgressbar компоненту cq-progressbar добавляется css-класс progress, который ломает вёрстку. Нужно его убрать

  @HostBinding('style.height')
  heightStyle = null; // HACK Из-за наследования класс NgbProgressbar компоненту cq-progressbar добавляется height внутрь style, который ломает вёрстку. Нужно его убрать

  @Input()
  tooltip?: string; // Текст тултипа

  constructor(config: NgbProgressbarConfig) {
    super(config);
  }

  ngOnInit() {
    // искусственно вводим ограничения, чтобы нельзя было при использовании нашего компонента использовать некоторый функционал ngb-progressbar
    this.height = '4px';
    this.showValue = false;
    // @ts-ignore там нельзя передавать undefined
    this.textType = undefined;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import { IntegrationOAuthHandlerComponent } from './integration-o-auth-handler.component';

@NgModule({
  declarations: [IntegrationOAuthHandlerComponent],
  exports: [IntegrationOAuthHandlerComponent],
  imports: [CommonModule, UIRouterModule],
})
export class IntegrationOAuthHandlerModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationService } from '@http/integration/services/integration.service';
import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { AmocrmEventTriggersForLeadComponent } from './amocrm-event-triggers-for-lead.component';

@NgModule({
  declarations: [AmocrmEventTriggersForLeadComponent],
  exports: [AmocrmEventTriggersForLeadComponent],
  imports: [CommonModule, EventTypeSelectModule, NgbCollapseModule, SharedModule, TranslocoModule, ReactiveFormsModule],
  providers: [IntegrationService],
})
export class AmocrmEventTriggersForLeadModule {}

<ng-container *transloco="let t; read: 'amocrmOAuthContainerComponent'">
  <h3 class="font-weight-bolder">{{ t('heading', {integrationTypeName}) }}</h3>
  <cq-amocrm-domain-selection
    class="mb-20"
    [ngClass]="{ hidden: !hasAccessToChoiceDomains() }"
    [(ngModel)]="accountDomain"
  ></cq-amocrm-domain-selection>
  <div
    class="mb-20"
    [innerHTML]="t('description', {integrationTypeName, projectName})"
  ></div>
  <cq-o-auth-button
    class="w-100"
    (authorizeError)="onAuthorizeError()"
    (authorizeSuccess)="onSuccessAuthorize()"
    [isAuthorized]="isAuthorized"
    [oAuthUrl]="oAuthUrl.authUrl[this.accountDomain]!"
  >
    <i class="btn-icon-left cqi-sm cqi-refresh-2"></i>
    {{ t('authorizeButton', {integrationTypeName}) }}
  </cq-o-auth-button>
</ng-container>

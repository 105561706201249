import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { AppService } from '@http/app/services/app.service';
import { LoaderModule } from '@panel/app/shared/visual-components/loader/loader.module';

import { MemberActionsNotFoundZeroDataModule } from './actions-not-found-zero-data/member-actions-not-found-zero-data.module';
import { MemberActionsTableModule } from './actions-table/member-actions-table.module';
import { MemberActionsComponent } from './member-actions.component';

@NgModule({
  declarations: [MemberActionsComponent],
  exports: [MemberActionsComponent],
  imports: [CommonModule, LoaderModule, MemberActionsTableModule, MemberActionsNotFoundZeroDataModule, TranslocoModule],
  providers: [AppService],
})
export class MemberActionsModule {}

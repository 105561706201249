import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiLetModule } from '@taiga-ui/cdk';

import { EmptyModule } from '@panel/app/pages/subscription/general/signed/invoices/empty/empty.module';
import { ListModule } from '@panel/app/pages/subscription/general/signed/invoices/list/list.module';
import { LoadingModule } from '@panel/app/pages/subscription/general/signed/invoices/loading/loading.module';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

import { InvoicesComponent } from './invoices.component';

@NgModule({
  declarations: [InvoicesComponent],
  exports: [InvoicesComponent],
  imports: [CommonModule, TranslocoModule, EmptyModule, ListModule, TuiLetModule, LoadingModule],
  providers: [SubscriptionStore],
})
export class InvoicesModule {}

/**
 * Директива кастомного скролла
 * Основана на OverlayScrollbars https://kingsora.github.io/OverlayScrollbars/#!documentation
 */
(function () {
  'use strict';

  angular.module('myApp.directives.customScroll').directive('cqCustomScroll', cqCustomScroll);

  function cqCustomScroll($parse, OverlayScrollbars) {
    return {
      controller: angular.noop,
      link: link,
      priority: -1, // приоритет выставлен таким для совместной работы с cqOverflow
      restrict: 'A',
    };

    function link(scope, iElement, iAttrs, controller) {
      var overlayScrollbarsInstance = OverlayScrollbars(iElement[0], {});
      var instanceSetter;

      if (iAttrs.cqCustomScroll) {
        instanceSetter = $parse(iAttrs.cqCustomScroll).assign;
        instanceSetter(scope, overlayScrollbarsInstance);
      }

      iElement.on('$destroy', destroy);
      scope.$watch(iAttrs.scrollOptions, watchScrollOptions);

      function destroy() {
        overlayScrollbarsInstance.destroy();
        instanceSetter && instanceSetter(scope, null);
      }

      function watchScrollOptions(newValue) {
        var options = angular.merge({}, OverlayScrollbars.env().getDefaultOptions(), newValue || {});
        // NOTE: вот тут внимательно! Например, на входе newValue == {a: 123}. Если в следующий вотчер сработает при newValue == {b: 321}, то опция a останется в инстансе, не перезатрётся
        overlayScrollbarsInstance.options(options);
      }
    }
  }
})();

(function () {
  'use strict';

  require('./chat-theme.controller');

  angular.module('myApp.chatSettings').component('cqChatTheme', cqChatTheme());

  function cqChatTheme() {
    return {
      bindings: {
        theme: '=', // Тип цветовой схемы чата
      },
      controller: 'CqChatThemeController',
      controllerAs: 'vm',
      template: require('./chat-theme.html'),
    };
  }
})();

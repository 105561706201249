import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { assign } from 'lodash-es';

import { VideoViewModalComponent } from '@panel/app/partials/modals/video-view/video-view-modal.component';
import { VideoViewModalModule } from '@panel/app/partials/modals/video-view/video-view-modal.module';
import { VideoViewModalParams } from '@panel/app/partials/modals/video-view/view-video-modal.types';

/** Сервис для работы с модальным окном "Просмотр видео" */
@Injectable({
  providedIn: 'root',
  deps: [VideoViewModalModule],
})
export class VideoViewModalService {
  /** Инстанс модального окна */
  modalInstance!: NgbModalRef;

  constructor(private readonly modalService: NgbModal) {}

  /** Закрытие модального окна для просмотра видео */
  close(): void {
    this.modalInstance.close();
  }

  /** Открытие модального окна для просмотра видео */
  open(modalParams: VideoViewModalParams, modalOptions?: NgbModalOptions): NgbModalRef {
    this.modalInstance = this.modalService.open(
      VideoViewModalComponent,
      assign(
        {
          backdrop: 'static',
          keyboard: false,
          centered: true,
          size: 'lg',
        },
        modalOptions,
      ),
    );

    (this.modalInstance.componentInstance.modalWindowParams as VideoViewModalParams) = {
      heading: modalParams.heading,
      videoUrl: modalParams.videoUrl,
    };

    return this.modalInstance;
  }
}

(function () {
  'use strict';

  require('./auto-event-delay.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventDelay', cqAutoEventDelay());

  function cqAutoEventDelay() {
    return {
      bindings: {
        delay: '=', // Время задержки
        delayHas: '=', // Включена ли задержка
        paramsType: '=', // Тип параметров
      },
      controller: 'CqAutoEventDelayController',
      controllerAs: 'vm',
      template: require('./auto-event-delay.html'),
    };
  }
})();

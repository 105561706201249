import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';
import { takeUntil } from 'rxjs/operators';

import {
  ARTICLES_INDEXED_STATUSES,
  ARTICLES_INDEXED_STATUSES_LIST,
  ARTICLES_STATUSES,
  ARTICLES_STATUSES_LIST,
} from '@http/article/article.constants';
import { ArticleModel } from '@http/article/article.model';
import { ARTICLES_COUNT_LIMIT } from '@http/knowledge-base/knowledge-base.constants';
import { LimitReachedModalComponent } from '@panel/app/pages/knowledge-base/shared/components/limit-reached-modal/limit-reached-modal.component';
import { SetArticleCategoryModalComponent } from '@panel/app/pages/knowledge-base/shared/components/set-article-category-modal/set-article-category-modal.component';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ArticlesListComponent implements OnInit, OnChanges {
  /** Количество статей */
  @Input() articlesCount!: number;
  /** Статьи */
  @Input() articles!: any[];
  /** Информация о биллинге */
  @Input() billingInfo!: any;
  /** Категории статей */
  @Input() categories!: any[];
  /** Текущий App */
  @Input() currentApp!: any;
  /** Текущая категория */
  @Input() currentCategory?: any;
  /** Флаг активности поиска */
  @Input() isSearch!: boolean;
  /** Callback для сброса поиска */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onResetSearch: EventEmitter<any> = new EventEmitter();
  /** Callback для обновления данных */
  //eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDataUpdate: EventEmitter<any> = new EventEmitter();

  protected accessToKnowledgeBasePro: any;
  protected filteredArticles: any = [];
  protected filters: any = this.getFilters();
  protected filtersCount: any = 0;
  protected selectedAllArticles: any = false;
  protected selectedArticles: any = [];
  protected readonly ARTICLES_STATUSES_LIST = ARTICLES_STATUSES_LIST;
  protected readonly ARTICLES_INDEXED_STATUSES_LIST = ARTICLES_INDEXED_STATUSES_LIST;
  protected articlesLeftOnStandart: any;

  constructor(
    protected readonly destroy$: DestroyService,
    protected readonly uiRouter: UIRouter,
    protected readonly translocoService: TranslocoService,
    protected readonly toastr: ToastService,
    protected readonly articleModel: ArticleModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly paywallService: PaywallService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly modalHelperService: ModalHelperService,
    protected readonly ngbModal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.accessToKnowledgeBasePro = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.KNOWLEDGE_BASE_PRO,
      this.currentApp,
    );

    this.articlesLeftOnStandart = ARTICLES_COUNT_LIMIT - this.articlesCount;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.articles && !!this.filters) {
      this.updateFilteredArticles(this.filters);
      if (this.selectedArticles.length) {
        this.selectedAllArticles = false;
        this.setSelectForAllArticles();
      }
    }

    if (changes.billingInfo) {
      this.accessToKnowledgeBasePro = this.planFeatureAccessService.getAccess(
        PLAN_FEATURE.KNOWLEDGE_BASE_PRO,
        this.currentApp,
      );
    }
  }

  /**
   * Изменение статуса инексации статьи
   *
   * @param article
   * @param indexedStatus
   */
  changeIndexed(article: any, indexedStatus: any): void {
    this.articleModel
      .changeIndexed(this.currentApp.id, article.id, indexedStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        article.robotsIndex = indexedStatus;
        this.onDataUpdate.emit();
        if (indexedStatus) {
          this.toastr.success(
            this.translocoService.translate('knowledgeBase.articlesList.toastr.articleIndexedStatusSuccess'),
          );
        } else {
          this.toastr.success(
            this.translocoService.translate('knowledgeBase.articlesList.toastr.articleNotIndexedStatusSuccess'),
          );
        }
      });
  }

  /**
   * Изменение приоритета статьи
   *
   * @param articleId - ID статьи
   * @param newPriority - Новый приоритет
   */
  changePriority(articleId: any, newPriority: any): void {
    this.articleModel
      .changePriority(this.currentApp.id, articleId, newPriority)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onDataUpdate.emit();
      });
  }

  /**
   * Изменение статуса статьи
   *
   * @param article - Статья
   * @param publicStatus - Статус опубликованности
   */
  changePublicStatus(article: any, publicStatus: any): void {
    this.articleModel
      .changePublicStatus(this.currentApp.id, article.id, publicStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // changePublicStatusSuccess
        article.public = publicStatus;
        this.onDataUpdate.emit();
        this.toastr.success(this.translocoService.translate('knowledgeBase.articlesList.toastr.changeStatusSuccess'));
      });
  }

  /**
   * Сбрость фильтры
   *
   * @param filters - Фильтры
   */
  clearFilters(filters: any): void {
    filters[ARTICLES_INDEXED_STATUSES.INDEXED] = false;
    filters[ARTICLES_INDEXED_STATUSES.NO_INDEXED] = false;
    filters[ARTICLES_STATUSES.PUBLISHED] = false;
    filters[ARTICLES_STATUSES.UNPUBLISHED] = false;
  }

  /**
   * Переход на состояние создания копии
   *
   * @param article - Статья
   */
  createCopy(article: any): void {
    if (this.accessToKnowledgeBasePro.hasAccess || this.articlesLeftOnStandart > 0) {
      this.uiRouter.stateService.go('app.content.knowledgeBaseArticleEditAjs.copy', {
        articleId: article.id,
        categoryId: this.isSearch ? article.category.id : this.currentCategory.id,
      });
    } else {
      this.openLimitReachedModal();
    }
  }

  /**
   * Получение фильтров для статей
   *
   * @return {*}
   */
  getFilters(): any {
    const filters: any = {};

    filters[ARTICLES_INDEXED_STATUSES.INDEXED] = false;
    filters[ARTICLES_INDEXED_STATUSES.NO_INDEXED] = false;
    filters[ARTICLES_STATUSES.PUBLISHED] = false;
    filters[ARTICLES_STATUSES.UNPUBLISHED] = false;

    return filters;
  }

  /**
   * Показывать кнопку перемещения статьи вниз или нет
   *
   * @param article - Статья
   * @param index - Интекс статьи в массиве
   *
   * @return {boolean}
   */
  isShowArrowDown(article: any, index: any): boolean {
    // Показывать елси она не в самом низу, если выключен поиск и если это не наименьшая приоритетная опубликованная статья
    return index !== this.filteredArticles.length - 1 && !this.isSearch && !this.isLessPriorityAndPublished(article);
  }

  /**
   * Является ли данная статья наименее приоритетной в случае, если ее статс опубликованна
   */
  isLessPriorityAndPublished(article: any): boolean {
    var publishedArticles = this.filteredArticles.filter((article: any) => article.public === true);
    return article.public && publishedArticles[publishedArticles.length - 1].id === article.id;
  }

  /**
   * Показывать кнопку перемещения статьи вверх или нет
   *
   * @param article - Статья
   * @param index - Интекс статьи в массиве
   *
   * @return {boolean}
   */
  isShowArrowUp(article: any, index: any): boolean {
    // Показывать елси она не на самом вверху, если выключен поиск и если это не самая приоритетная неопубликованная статья
    return index !== 0 && !this.isSearch && !this.isMostPriorityAndUnpublished(article);
  }

  /**
   * Является ли данная статья самой приоритетной в случае, если ее статс не опубликованна
   */
  isMostPriorityAndUnpublished(article: any): boolean {
    return !article.public && this.filteredArticles.filter((a: any) => !a.public)[0]?.id === article.id;
  }

  /**
   * Открытие модалки о превышении количества созданный статей
   */
  openLimitReachedModal(): void {
    const modal = this.modalHelperService.open(LimitReachedModalComponent);

    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
    };

    modal.result.then(() => {
      this.paywallService.showPaywall(this.currentApp, this.accessToKnowledgeBasePro.denialReason);
    });
  }

  /**
   * Открытие модалки подтверждения удаления статей
   *
   * @param articlesList - Массив статей
   */
  openRemoveArticlesModal(articlesList: any): void {
    let modalParams: any = {};

    if (articlesList.length === 1) {
      modalParams.heading = this.translocoService.translate(
        'knowledgeBase.articlesList.removeArticleModal.forOne.heading',
      );
      modalParams.body = this.translocoService.translate('knowledgeBase.articlesList.removeArticleModal.forOne.body', {
        articleName: articlesList[0].name,
      });
      modalParams.confirmButtonText = this.translocoService.translate(
        'knowledgeBase.articlesList.removeArticleModal.forOne.confirmButtonText',
      );
    } else {
      modalParams.heading = this.translocoService.translate(
        'knowledgeBase.articlesList.removeArticleModal.forMany.heading',
      );
      modalParams.body = this.translocoService.translate('knowledgeBase.articlesList.removeArticleModal.forMany.body', {
        count: articlesList.length,
      });
      modalParams.confirmButtonText = this.translocoService.translate(
        'knowledgeBase.articlesList.removeArticleModal.forMany.confirmButtonText',
      );
    }

    const modal = this.modalHelperService.provide(CONFIRM_MODAL_DATA_TOKEN, modalParams).open(ConfirmModalComponent, {
      modalDialogClass: 'cq-articles-list-remove-modal',
    });

    modal.result.then(() => {
      // removeArticlesModalSuccess
      let articlesIdsList = articlesList.map((article: any) => article.id);
      if (articlesIdsList.length === 1) {
        this.articleModel
          .remove(this.currentApp.id, articlesIdsList[0])
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            // removeArticlesSuccess
            this.toastr.success(
              this.translocoService.translate('knowledgeBase.articlesList.toastr.removeArticleSuccess'),
            );
            this.onDataUpdate.emit();
          });
      } else {
        this.articleModel
          .removeList(this.currentApp.id, articlesIdsList)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            // removeArticlesSuccess
            this.toastr.success(
              this.translocoService.translate('knowledgeBase.articlesList.toastr.removeArticlesSuccess', {
                count: articlesIdsList.length,
              }),
            );
            this.onDataUpdate.emit();
          });
      }
    });
  }

  /**
   * Открытие модалки смены категории
   *
   * @param articles - Статьи
   */
  openSetCategoryModal(articles: any): void {
    const modal = this.ngbModal.open(SetArticleCategoryModalComponent);

    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
      categories: this.categories,
      articles: articles,
    };

    modal.result.then(() => {
      this.onDataUpdate.emit();
    });
  }

  /**
   * Обновление выделенных статей
   */
  refreshSelectedArticles(): void {
    this.selectedArticles = this.filteredArticles.filter((article: any) => !!article.checked);
    this.selectedArticles = this.articles.filter((article: any) => article.checked);
  }

  /**
   * Отметить или снять галочки со статей
   */
  setSelectForAllArticles(): void {
    this.filteredArticles.forEach((article: any) => {
      article.checked = this.selectedAllArticles;
    });
    this.refreshSelectedArticles();
  }

  /**
   * Обновление отфильтрованных статей
   *
   * @param filters - Статусы статьи
   */
  updateFilteredArticles(filters: any): void {
    let filterParams: any = {};
    // Если данные поля совпадают => занчит выбрано все
    if (
      !(
        (!filters[ARTICLES_INDEXED_STATUSES.INDEXED] && !filters[ARTICLES_INDEXED_STATUSES.NO_INDEXED]) ||
        (filters[ARTICLES_INDEXED_STATUSES.INDEXED] && filters[ARTICLES_INDEXED_STATUSES.NO_INDEXED])
      )
    ) {
      filterParams.robotsIndex = filters[ARTICLES_INDEXED_STATUSES.INDEXED];
    }

    // Если данные поля совпадают => занчит выбрано все
    if (
      !(
        (!filters[ARTICLES_STATUSES.PUBLISHED] && !filters[ARTICLES_STATUSES.UNPUBLISHED]) ||
        (filters[ARTICLES_STATUSES.PUBLISHED] && filters[ARTICLES_STATUSES.UNPUBLISHED])
      )
    ) {
      filterParams.public = filters[ARTICLES_STATUSES.PUBLISHED];
    }

    this.filteredArticles = this.articles.filter((article: any) =>
      Object.keys(filterParams).every((param) => filterParams[param] === article[param]),
    );
  }

  /**
   * Трек клика создания статьи
   */
  trackClickCreateArticle(): void {
    this.carrotquestHelper.track('База знаний - клик на "Создать новую статью"');
  }

  /**
   * Трек создания копии
   */
  trackCopy(): void {
    this.carrotquestHelper.track('База знаний - создать копию');
  }

  /**
   * Трек публикации статьи
   */
  trackPublish(): void {
    this.carrotquestHelper.track('База знаний - опубликовать');
  }

  /**
   * Трек удаления статьи
   */
  trackRemoveArticle(articleId: any): void {
    this.carrotquestHelper.track('База знаний - удалил статью', {
      'id статьи': articleId,
    });
  }

  /**
   * Трек удаления статей
   */
  trackRemoveArticles(articlesLength: any): void {
    this.carrotquestHelper.track('База знаний - удалил статьи', {
      количество: articlesLength,
    });
  }

  /**
   * Трек перемещения статьи/статей
   */
  trackSetCategory(): void {
    this.carrotquestHelper.track('База знаний - переместить в категорию');
  }

  /**
   * Трек применения фильтра статуса
   */
  trackSetFilterPublicStatus(): void {
    this.carrotquestHelper.track('База знаний - применил фильтр статусы');
  }

  /**
   * Трек снятия с публикации
   */
  trackUnpublish(): void {
    this.carrotquestHelper.track('База знаний - снять с публикации');
  }

  trackByFn(article: any): any {
    return article.id;
  }

  onChangeFilter(status: any): void {
    this.filters[status] = !this.filters[status];

    const filtersArray = Object.keys(this.filters).map((key) => this.filters[key]);
    this.filtersCount = filtersArray.filter((value) => value === true).length;
    this.updateFilteredArticles(this.filters);
  }
}

/**
 * Сервис для работы с визардом
 */
(function () {
  'use strict';

  angular.module('myApp.services.wizardHelper').factory('wizardHelper', wizardHelper);

  function wizardHelper($rootScope, $q) {
    var wizards = [];
    var defaultWizardName = 'defaultWizard';

    return {
      addWizard: addWizard,
      getWizard: getWizard,
      removeWizard: removeWizard,
    };

    /**
     * Добавление визарда в массив визардов
     *
     * @param wizard
     */
    function addWizard(wizard) {
      wizards.push(wizard);
      $rootScope.$broadcast('cqWizard:created:' + (wizard.name || defaultWizardName), wizard);
    }

    /**
     * Получение визарда
     *
     * @param wizardName
     * @return {Promise}
     */
    function getWizard(wizardName) {
      var deferred = $q.defer();

      wizardName = wizardName || defaultWizardName;

      if (wizards[wizardName]) {
        deferred.resolve(wizards[wizardName]);
      } else {
        $rootScope.$on('cqWizard:created:' + wizardName, onWizardCreated);
      }

      return deferred.promise;

      function onWizardCreated(event, wizard) {
        deferred.resolve(wizard);
      }
    }

    /**
     * Удаление визарда
     *
     * @param wizard
     */
    function removeWizard(wizard) {
      wizards.splice(wizards.indexOf(wizard), 1);
      $rootScope.$broadcast('cqWizard:destroyed:' + (wizard.name || defaultWizardName), wizard);
    }
  }
})();

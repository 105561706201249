<ng-container *transloco="let t; read: 'amocrmEventTriggersForLeadComponent'">
  <div
    class="d-flex align-items-center cursor-pointer"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
    (click)="eventTypesCollapse.toggle()"
  >
    <h4 class="no-margin">{{ t('heading') }}</h4>
    <div class="ml-auto">{{ eventsCount }}</div>
    <i
      class="cqi-sm ml-10"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>
  <div
    #eventTypesCollapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="pt-20">
      <div class="mb-10">{{t('description', {integrationTypeName})}}</div>
      <div class="mb-20">
        <cq-event-type-select
          [autoEvents]="(amocrmState.autoEvents$ | async) ?? []"
          (autoEventsChange)="onAutoEventsChange($event)"
          [currentApp]="currentApp"
          [eventTypes]="(amocrmState.eventTypes$ | async) ?? []"
          (eventTypesChange)="onEventTypesChange($event)"
          [multiple]="true"
          [formControl]="controlLeadsEventTypeIds"
          [showHeader]="false"
        ></cq-event-type-select>
        <cq-validation-messages [control]="controlLeadsEventTypeIds">
          <cq-validation-message errorName="required">
            {{ t('select.errors.required', {integrationTypeName}) }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
      <div class="d-flex align-items-baseline text-secondary">
        <i class="cqi-sm cqi-lightbulb mr-5"></i>
        <span [innerHTML]="t('note', {integrationTypeName})"></span>
      </div>
    </div>
  </div>
</ng-container>

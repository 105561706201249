/**
 * Директива для вывода статистики тест-группы
 */
(function () {
  'use strict';

  require('./ab-test-statistics.controller');

  angular.module('myApp.directives.abTestStatistics').directive('cqAbTestStatistics', cqAbTestStatistics);

  function cqAbTestStatistics() {
    var nextId = 0;

    return {
      restrict: 'E',
      require: 'cqAbTestStatistics',
      scope: {
        currentApp: '=', // текущее приложение с необходимыми для отображения превью вариантов сообщений настройками
        currentMessage: '=', // сообщение, которому принадлежит тест-группа
        pushSettings: '=', // настройки пушей
        showSendingsCallback: '&', // функция, которая будет вызвана при клике на 'Посмотреть' в списке типов действий получателей сообщения
        testGroup: '=', // тест-группа
      },
      controller: 'CqAbTestStatisticsController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
      template: require('./ab-test-statistics.html'),
    };

    function link(scope, iElement, iAttrs, controller) {
      // всю эту свистопляску пришлось делать из-за datepicker'а, его родительским элементом должна быть директива, которая получается по селектору
      // и чтобы обеспечить уникальный селектор элемента директивы - пришлось клепать дополнительное поле к элементу директивы
      iElement.attr('directive-id', nextId);
      controller.elementId = nextId;
      nextId++;
      controller.init();
    }
  }
})();

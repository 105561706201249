/** Типы устройств, которые могут подписываться на Web Push/SDK */
export enum PUSH_SUBSCRIPTION_DEVICE_TYPE {
  MOBILE = 'mobile',
  PC = 'pc',
  TABLET = 'tablet',
}

/** Типы подписок */
export enum PUSH_SUBSCRIPTION_TYPE {
  BROWSER = 'browser', // Web Push
  MOBILE = 'mobile', // SDK
}

import { PLAN_FEATURE } from '../../../app/services/billing/plan-feature/plan-feature.constants';
import {
  STARTER_GUIDE_STEPS,
  STARTER_GUIDE_STEPS_BASICS,
  STARTER_GUIDE_STEPS_BY_TASK,
  STARTER_GUIDE_TASKS,
} from '../../../app/http/starter-guide/starter-guide.constants';
import { BILLING_PLAN_IDS } from '../../../app/services/billing-info/billing-info.constants';
import {
  DASHBOARD_DATE_GROUPING_TYPES,
  DASHBOARD_STATISTICS_TYPES,
} from '../../../app/http/dashboard/dashboard.constants';
import { firstValueFrom } from 'rxjs';
import { FEATURES } from '../../../app/http/feature/feature.constants';

/**
 * Контроллер Dashboard
 */
(function () {
  'use strict';

  angular.module('myApp.dashboard').controller('CqDashboardController', CqDashboardController);

  function CqDashboardController(
    $q,
    $scope,
    $state,
    $stateParams,
    $translate,
    $uibModal,
    l10nHelper,
    moment,
    PROJECT_NAME,
    appModel,
    billingInfoModel,
    blockingService,
    carrotquestHelper,
    chartHelper,
    dashboardModel,
    dateRangePickerHelper,
    featureModel,
    funnelModel,
    planVersionService,
    planFeatureAccessService,
    starterGuideModel,
    toastr,
  ) {
    var vm = this;

    var ARTICLES_LIST = [
      {
        name: $translate.instant('dashboard.articles.0.name'),
        title: $translate.instant('dashboard.articles.0.title'),
        url: $translate.instant('dashboard.articles.0.url'),
      },
      {
        name: $translate.instant('dashboard.articles.1.name'),
        title: $translate.instant('dashboard.articles.1.title'),
        url: $translate.instant('dashboard.articles.1.url'),
      },
      {
        name: $translate.instant('dashboard.articles.2.name'),
        title: $translate.instant('dashboard.articles.2.title'),
        url: $translate.instant('dashboard.articles.2.url'),
      },
      {
        name: $translate.instant('dashboard.articles.3.name'),
        title: $translate.instant('dashboard.articles.3.title'),
        url: $translate.instant('dashboard.articles.3.url'),
      },
      {
        name: $translate.instant('dashboard.articles.4.name'),
        title: $translate.instant('dashboard.articles.4.title'),
        url: $translate.instant('dashboard.articles.4.url'),
      },
      {
        name: $translate.instant('dashboard.articles.5.name'),
        title: $translate.instant('dashboard.articles.5.title'),
        url: $translate.instant('dashboard.articles.5.url'),
      },
      {
        name: $translate.instant('dashboard.articles.6.name'),
        title: $translate.instant('dashboard.articles.6.title'),
        url: $translate.instant('dashboard.articles.6.url'),
      },
      {
        name: $translate.instant('dashboard.articles.7.name'),
        title: $translate.instant('dashboard.articles.7.title'),
        url: $translate.instant('dashboard.articles.7.url'),
      },
    ];

    /**
     * Период кэширования данных воронки в секундах
     *
     * @type {Object}
     */
    var DASHBOARD_FUNNEL_CACHING_TIME = 14400; // 4 часа

    /**
     * Последняя дата обновления видео-уроков в блоке с видео на dashboard'e
     * NOTE Необходимо, чтобы в определеный|нужный момент, сбрасывать скрытие блока и пушить пользователя обновленными видео-уроками
     *
     * @type {string}
     */
    var LAST_DATE_UPDATE_VIDEO_LESSONS = '042020';

    /**
     * Список обущаючих видео
     *
     * @type {Array}
     */
    var VIDEO_LESSONS_LIST = [
      {
        url: $translate.instant('dashboard.videoLessons.videos.0.url'),
        name: $translate.instant('dashboard.videoLessons.videos.0.name'),
        showButton: false,
      },
      {
        url: $translate.instant('dashboard.videoLessons.videos.1.url'),
        name: $translate.instant('dashboard.videoLessons.videos.1.name'),
        showButton: false,
      },
      {
        url: $translate.instant('dashboard.videoLessons.videos.2.url'),
        name: $translate.instant('dashboard.videoLessons.videos.2.name'),
        showButton: false,
      },
      {
        url: $translate.instant('dashboard.videoLessons.videos.3.url'),
        name: $translate.instant('dashboard.videoLessons.videos.3.name'),
        showButton: true,
      },
    ];

    var axisSettings = {
      // настройки осей графиков
      xAxes: [
        {
          ticks: {
            autoSkipPadding: 10,
            maxRotation: 0,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: chartHelper.removeDecimalLabels,
          },
        },
      ],
    };

    var dashboardStatistics = []; //Статистика

    var funnelWasUsed = false; // Флаг используется для кеширования воронки при первом ее использовании

    vm.$onInit = init;

    function init() {
      vm.accessToDashboard = planFeatureAccessService.getAccess(PLAN_FEATURE.DASHBOARD, vm.currentApp);
      vm.accessToEventsFunnels = planFeatureAccessService.getAccess(PLAN_FEATURE.EVENTS_FUNNELS, vm.currentApp);
      vm.accessToManualMessagesEmail = planFeatureAccessService.getAccess(
        PLAN_FEATURE.MANUAL_MESSAGES_EMAIL,
        vm.currentApp,
      );

      carrotquestHelper.track('Dashboard - переход', { App: vm.currentApp.name });

      vm.$translate = $translate;
      vm.appModel = appModel;
      vm.appCategory = vm.currentApp.settings.category || 'other'; // HACK Тип аппа, т.к. не у всех аппаов есть тип, необходимо делать проверку
      vm.ARTICLES_LIST = ARTICLES_LIST;
      vm.autoMessages = {
        // отправленные автосообщения
        count: 0,
        chart: {
          type: 'bar',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('dashboard.autoMessagesOptions.label'),
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('dashboard.autoMessagesOptions.zeroData'),
            },
            maintainAspectRatio: false,
          },
        },
      };
      vm.BILLING_PLAN_IDS = BILLING_PLAN_IDS;
      vm.conversations = {
        // новые диалоги
        count: 0,
        chart: {
          type: 'bar',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('dashboard.conversationsOptions.label'),
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('dashboard.conversationsOptions.zeroData'),
            },
            maintainAspectRatio: false,
          },
        },
      };
      vm.counters = []; // Счётчики воронки
      vm.datepickerOptions = {
        // датапикер
        dates: {
          // период отображения статистики
          startDate: moment().subtract(1, 'month'),
          endDate: moment().endOf('day'),
        },
        dateRangePickerOptions: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          dateLimit: {
            quarters: 1,
          },
          maxDate: moment().endOf('day'),
          opens: 'left',
          parentEl: '#statistics-datepicker-holder',
        }),
      };
      vm.dateGroupingSelect = [
        {
          //Данные для select
          name: $translate.instant('dashboard.dateGrouping.' + DASHBOARD_DATE_GROUPING_TYPES.DAY),
          value: DASHBOARD_DATE_GROUPING_TYPES.DAY,
        },
        {
          name: $translate.instant('dashboard.dateGrouping.' + DASHBOARD_DATE_GROUPING_TYPES.WEEK),
          value: DASHBOARD_DATE_GROUPING_TYPES.WEEK,
        },
        {
          name: $translate.instant('dashboard.dateGrouping.' + DASHBOARD_DATE_GROUPING_TYPES.MONTH),
          value: DASHBOARD_DATE_GROUPING_TYPES.MONTH,
        },
      ];
      vm.DASHBOARD_FILTER_TYPE = {
        GUIDE: 'guide',
        SCENARIOS: 'scenarios',
        STATISTICS: 'statistics',
      };
      vm.isOperator = vm.djangoUser.prefs[vm.currentApp.id].permissions === 'operator';
      vm.funnel = !vm.isOperator && vm.funnels[0]; // Воронка для отображения в дашборде, основная воронка всегда первая в массиве
      vm.funnelRange = {
        // настройки периода отображения воронки
        dates: {
          // период отображения воронки
          startDate: moment().subtract(1, 'months'),
          endDate: moment().endOf('day'),
        },
        dateRangePickerOptions: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          dateLimit: {
            quarters: 1,
          },
          minDate: moment().subtract(2, 'years').startOf('day'),
          maxDate: moment().endOf('day'),
          opens: 'left',
          parentEl: '#funnel-datepicker-holder',
        }),
      };
      vm.hasAccessToGeneralFunnel = featureModel.hasAccess(FEATURES.GENERAL_FUNNEL_CHART);
      vm.hasAccessToLeadsChart = featureModel.hasAccess(FEATURES.LEADS_STATISTIC_CHART);
      vm.isDisabledDashboardFilterButton = blockingService.hasBlockingForMarketerCase(
        vm.currentApp.activation,
        vm.djangoUserTempData,
      );
      vm.isVideoLessonsCollapsed = getCollapseStateVideoLessons(); // Текущее состояние блока с видео
      vm.leads = {
        // собрынные лиды
        count: 0,
        chart: {
          type: 'line',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('dashboard.leadsOptions.label'),
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('dashboard.leadsOptions.zeroData'),
            },
            maintainAspectRatio: false,
          },
        },
      };
      vm.mainFunnel = null; // Воронка выбранная в качестве основной
      vm.manualEmailMessages = {
        // ручных емейл-рассылок
        count: 0,
        chart: {
          type: 'bar',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('dashboard.manualEmailMessagesOptions.label'),
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('dashboard.manualEmailMessagesOptions.zeroData'),
            },
            maintainAspectRatio: false,
          },
        },
      };
      vm.openVideoModal = openVideoModal;
      vm.openCreateFunnelModal = openCreateFunnelModal;
      vm.openDowngradeAbilitiesModal = openDowngradeAbilitiesModal();
      vm.openSelectMainFunnelModal = openSelectMainFunnelModal;
      vm.planVersionService = planVersionService;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.starterGuideSteps = starterGuideModel.starterGuideSteps;
      vm.starterGuideStep = $stateParams.starterGuideStep;
      vm.selectedDateGrouping = vm.dateGroupingSelect[0]; //Выбранные данные у select
      vm.selectedDashboardFilterType = someoneStepsOfStarterGuideCompleted()
        ? vm.DASHBOARD_FILTER_TYPE.SCENARIOS
        : vm.DASHBOARD_FILTER_TYPE.GUIDE;
      vm.shownReadyAutoMessagesTemplates = getShownReadyAutoMessagesTemplates();
      vm.showTemplatePreview = showTemplatePreview;
      vm.statisticsPeriodDescription = ''; //Текст для заголовка
      vm.toggleCollapsedVideoLessons = toggleCollapsedVideoLessons;
      vm.trackClickAllAutoMessages = trackClickAllAutoMessages;
      vm.trackClickAllConversationsStatistics = trackClickAllConversationsStatistics;
      vm.trackClickAllDialogs = trackClickAllDialogs;
      vm.trackClickAllFunnelsLink = trackClickAllFunnelsLink;
      vm.trackClickAllLeads = trackClickAllLeads;
      vm.trackClickGuideTab = trackClickGuideTab;
      vm.trackClickScenariosTab = trackClickScenariosTab;
      vm.trackClickStatisticsTab = trackClickStatisticsTab;
      vm.trackClickCreateFunnelModal = trackClickCreateFunnelModal;
      vm.trackClickOnCloseTemplateChatBotModal = trackClickOnCloseTemplateChatBotModal;
      vm.trackClickInstallService = trackClickInstallService;
      vm.trackClickVideoLesson = trackClickVideoLesson;
      vm.trackClickWatchMoreVideo = trackClickWatchMoreVideo;
      vm.VIDEO_LESSONS_LIST = VIDEO_LESSONS_LIST;
      vm.users = {
        // уникальные пользователм
        count: 0,
        chart: {
          type: 'line',
          instance: null,
          data: {
            labels: [],
            datasets: [
              {
                data: [],
                label: $translate.instant('dashboard.usersOptions.label'),
              },
            ],
          },
          options: {
            scales: axisSettings,
            zeroData: {
              text: $translate.instant('dashboard.usersOptions.zeroData'),
            },
            maintainAspectRatio: false,
          },
        },
      };

      $scope.$watch('vm.datepickerOptions.dates', watchDatepickerDates);

      // Если есть основная воронка, начинаем отслеживать изменения
      vm.funnel && $scope.$watch('vm.funnelRange.dates', watchDates);
      $scope.$watch('vm.selectedDateGrouping', watchDateGrouping);

      /**
       * Вызывается при изменении vm.selectedDateGrouping и производит группировку по выбранному типу
       *
       * @param newValue
       * @param oldValue
       */
      function watchDateGrouping(newValue, oldValue) {
        // защита от первого вызова колбэка, т.к. эта функция будет вызвана в наблюдателе vm.datepickerOptions.dates
        if (newValue !== oldValue) {
          getAllStatistics();
        }
      }

      /**
       * Вызывается при изменении дат и получает статистику и сразу группирует её по выбранному типу
       *
       * @param newValue
       * @param oldValue
       */
      function watchDatepickerDates(newValue, oldValue) {
        if (vm.accessToDashboard.hasAccess) {
          firstValueFrom(
            dashboardModel.getDashboardStatistics(vm.currentApp.id, newValue.startDate, newValue.endDate),
          ).then(getDashboardStatisticsSuccess);
          generateStatisticsPeriodDescription(newValue.startDate, newValue.endDate);

          function getDashboardStatisticsSuccess(statistics) {
            dashboardStatistics = statistics;
            getAllStatistics();
          }
        }
      }

      /**
       * Запросы к серверу при изменении дат для построения воронки
       *
       * @param newValues
       */
      function watchDates(newValues) {
        // Если воронка строится не в первый раз обновляем кеш
        var cashing = funnelWasUsed ? 0 : DASHBOARD_FUNNEL_CACHING_TIME;

        firstValueFrom(funnelModel.count(vm.funnel.id, newValues.startDate, newValues.endDate, cashing)).then(
          getCountSuccess,
        );

        function getCountSuccess(counters) {
          vm.counters = counters;
        }

        funnelWasUsed = true; // после первого построения воронки изменяем флаг
      }
    }

    /**
     * Генерация текстового представление периода
     *
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @returns {String}
     */
    function generateStatisticsPeriodDescription(startDate, endDate) {
      var result = '';
      var isEndDateSame = endDate.isSame(moment(), 'day');
      var diffInDays = endDate.diff(startDate, 'days');
      var isMonth = startDate.isSame(moment().subtract(1, 'month'), 'day');

      if (isMonth && isEndDateSame) {
        result = $translate.instant('dashboard.statistics.heading.lastMonth');
      } else if (isEndDateSame && diffInDays == 0) {
        result = $translate.instant('dashboard.statistics.heading.today');
      } else {
        if (isEndDateSame) {
          result = $translate.instant(
            'dashboard.statistics.heading.lastDays',
            { days: diffInDays + 1 },
            'messageformat',
          );
        } else {
          result = $translate.instant('dashboard.statistics.heading.days', { days: diffInDays + 1 }, 'messageformat');
        }
      }
      vm.statisticsPeriodDescription = result;
    }

    /**
     * Получение всей статистики
     */
    function getAllStatistics() {
      getSeparatelyStatistics(DASHBOARD_STATISTICS_TYPES.USERS_COUNT, vm.users);
      getSeparatelyStatistics(DASHBOARD_STATISTICS_TYPES.LEADS_COUNT, vm.leads);
      getSeparatelyStatistics(DASHBOARD_STATISTICS_TYPES.CONVERSATIONS_COUNT, vm.conversations);
      getSeparatelyStatistics(DASHBOARD_STATISTICS_TYPES.AUTO_MESSAGE_COUNT, vm.autoMessages);
      getSeparatelyStatistics(DASHBOARD_STATISTICS_TYPES.MANUAL_EMAIL_MESSAGES_COUNT, vm.manualEmailMessages);
    }

    /**
     * Получение состояния блока с видео (скрыт/раскрыт)
     * @returns {boolean}
     */
    function getCollapseStateVideoLessons() {
      return localStorage.getItem('isVideoLessonsCollapsed_' + LAST_DATE_UPDATE_VIDEO_LESSONS) === 'true';
    }

    /**
     * Сохранение статистики в настройки для дашборда
     *
     * @param {DASHBOARD_STATISTICS_TYPES} dashboardStatisticsType Тип статистики
     * @param {Object} chartOptions Настройки графика
     */
    function getSeparatelyStatistics(dashboardStatisticsType, chartOptions) {
      var statistics = dashboardModel.getParsedStatistics(
        dashboardStatistics,
        dashboardStatisticsType,
        vm.selectedDateGrouping.value,
        vm.datepickerOptions.dates.startDate,
        vm.datepickerOptions.dates.endDate,
      );

      chartOptions.chart.data.labels = statistics.labels;
      chartOptions.chart.data.datasets[0].data = statistics.data;
      chartOptions.count = statistics.count;

      chartHelper.updateChart(chartOptions.chart.instance);
    }

    /**
     * Получение массива определенных шаблонов.
     * Сделано так, потому что маркетинг хочет изменять сетку из 4 шаблонов
     *
     * @returns {*[]}
     */
    function getShownReadyAutoMessagesTemplates() {
      const idsRu = ['154', '153', '152', '151'];
      const idsEn = ['138', '137', '136', '135'];

      const finalIds = !l10nHelper.isUsCountry() ? idsRu : idsEn;

      return finalIds.map((id) => {
        return vm.readyAutoMessagesTemplates.find((template) => template.id === id);
      });
    }

    /**
     * Клик по видео
     *
     * @param {Object} video - Обеъкт с параметрами видео
     * @param {String} video.name - Название видео
     * @param {Object} video.url - Ссылка на видео
     * @param {Object} video.showButton - Показывать кнопку с курсом
     */
    function openVideoModal(video) {
      var videoModal = $uibModal.open({
        controller: 'VideoModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/video/video.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: video.name,
              videoUrl: video.url,
              showButton: video.showButton,
            };
          },
        },
        size: 'lg',
      });

      videoModal.result.then(
        angular.bind(null, trackCloseVideoModal, video.name),
        angular.bind(null, trackCloseVideoModal, video.name),
      );
    }

    /** Открывает модалку про даунгрейд */
    function openDowngradeAbilitiesModal() {
      if (!(localStorage.getItem('autoMessagesDowngrade') || localStorage.getItem('leadBotsDowngrade'))) {
        return;
      }
      $uibModal.open({
        component: 'cqDowngradeAbilitiesWrapperModal',
        resolve: {
          modalWindowParams: function () {
            return {
              triggeredMessagesDowngrade: !!localStorage.getItem('autoMessagesDowngrade'),
              leadBotsDowngrade: !!localStorage.getItem('leadBotsDowngrade'),
            };
          },
        },
        size: 'md modal-dialog-centered',
      });
    }

    /**
     * Открытие модалки создания воронки
     */
    function openCreateFunnelModal() {
      var defaultFunnel = funnelModel.getDefault(true);

      var funnelEditorModal = $uibModal.open({
        backdrop: 'static',
        component: 'cqFunnelEditorModal',
        resolve: {
          currentApp: angular.bind(null, angular.identity, vm.currentApp),
          currentFunnelName: angular.bind(null, angular.identity, vm.funnel ? vm.funnel.name : ''),
          funnel: angular.bind(null, angular.identity, defaultFunnel),
          isCreateMain: angular.bind(null, angular.identity, true),
          modalAction: angular.bind(null, angular.identity, 'create'),
          properties: angular.bind(null, angular.identity, vm.properties),
        },
        size: 'xl modal-dialog-scrollable',
        windowClass: 'funnel-editor-modal',
      });

      funnelEditorModal.result.then(createFunnelSuccess).catch(createFunnelError);

      function createFunnelSuccess() {
        $state.reload();

        toastr.success($translate.instant('funnels.general.toasts.createFunnelSuccess'));
      }

      function createFunnelError(error) {
        if (error == 'DefaultFunnelAlreadyExistsError') {
          toastr.error($translate.instant('funnels.general.toasts.defaultFunnelAlreadyExists'));
        }
      }
    }

    /**
     * Открытие модалки для выбора основной воронки
     */
    function openSelectMainFunnelModal() {
      var openSelectMainFunnelModal = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('dashboard.selectMainFunnelModal.heading'),
              body: $translate.instant('dashboard.selectMainFunnelModal.body', { name: vm.mainFunnel.name }),
              confirmButtonText: $translate.instant('dashboard.selectMainFunnelModal.confirmButtonText'),
            };
          },
        },
        templateUrl: 'js/shared/modals/confirm/confirm.html',
      });

      openSelectMainFunnelModal.result.catch(cancelSelectMainFunnelModal).then(selectMainFunnelModal);

      function cancelSelectMainFunnelModal() {
        trackCancelSelectMainFunnel();

        return $q.reject();
      }

      function selectMainFunnelModal() {
        return firstValueFrom(funnelModel.setMain(vm.currentApp.id, vm.mainFunnel.id)).then(selectMainFunnelSuccess);

        function selectMainFunnelSuccess() {
          $state.reload();

          trackSelectMainFunnel(vm.mainFunnel.name);
          toastr.success($translate.instant('dashboard.toasts.selectMainFunnelSuccess', { name: vm.mainFunnel.name }));
        }
      }
    }

    /**
     * Задает рандомный значения для всех графиков
     * NOTE: раньше использовалась, если код не установлен. Сейчас такого быть не может, но функцию я оставил, т.к. она полезная, может пригодиться
     */
    function setAllRandomStatistics() {
      setSeparatelyRandomStatistics(vm.users);
      setSeparatelyRandomStatistics(vm.leads);
      setSeparatelyRandomStatistics(vm.conversations);
      setSeparatelyRandomStatistics(vm.autoMessages);
      setSeparatelyRandomStatistics(vm.manualEmailMessages);
    }

    /**
     * Задает рандомные значения для графика
     *
     * @param {Object} chartOptions Настройки графика
     */
    function setSeparatelyRandomStatistics(chartOptions) {
      var count = 0;
      for (var i = 0; i < chartOptions.chart.data.datasets[0].data.length; i++) {
        chartOptions.chart.data.datasets[0].data[i] = Math.floor(Math.random() * 50);
        count += chartOptions.chart.data.datasets[0].data[i];
      }
      chartOptions.count = count;

      chartHelper.updateChart(chartOptions.chart.instance);
    }

    /**
     * Пройдены ли все шаги хотя бы из одного блока стартер гайда
     *
     * @returns {*}
     */
    function someoneStepsOfStarterGuideCompleted() {
      if (vm.isOperator) {
        return true;
      }

      const basicStepsCompleted = STARTER_GUIDE_STEPS_BASICS.every((stepName) => {
        return vm.starterGuideSteps.find((step) => step.name === stepName).passed;
      });
      const talkToCustomerStepsCompleted = STARTER_GUIDE_STEPS_BY_TASK[STARTER_GUIDE_TASKS.TALK_TO_CUSTOMERS].every(
        (stepName) => {
          if (stepName === STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION) {
            return (
              vm.starterGuideSteps.find((step) => step.name === STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE).passed ||
              vm.starterGuideSteps.find((step) => step.name === STARTER_GUIDE_STEPS.LEADS_COLLECTION).passed
            );
          }
          return vm.starterGuideSteps.find((step) => step.name === stepName).passed;
        },
      );
      const raiseSiteConversionStepsCompleted = STARTER_GUIDE_STEPS_BY_TASK[
        STARTER_GUIDE_TASKS.RAISE_SITE_CONVERSION
      ].every((stepName) => {
        if (stepName === STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION) {
          return (
            vm.starterGuideSteps.find((step) => step.name === STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE).passed ||
            vm.starterGuideSteps.find((step) => step.name === STARTER_GUIDE_STEPS.LEADS_COLLECTION).passed
          );
        }
        return vm.starterGuideSteps.find((step) => step.name === stepName).passed;
      });

      return basicStepsCompleted || talkToCustomerStepsCompleted || raiseSiteConversionStepsCompleted;
    }

    /**
     * Отображает превью шаблона или список шаблонов чат-ботов
     *
     * @param templateId
     */
    function showTemplatePreview(templateId = '') {
      const templateModal = $uibModal.open({
        component: 'cqChatBotTemplateWrapperModal',
        resolve: {
          modalWindowParams: function () {
            return {
              templates: vm.templates,
              botType: 'lead',
              isNewBot: true,
              templateId,
            };
          },
        },
        size: 'lg modal-dialog-centered',
      });

      templateModal.result
        .then(
          (template) => {
            $state.go(
              'app.content.messagesAjs.chatBot.create.fromTemplate',
              { chatBotTemplateId: template.id },
              { absolute: true },
            );
          },
          () => {
            vm.trackClickOnCloseTemplateChatBotModal();
          },
        )
        .catch(() => {});
    }

    /**
     * Сворачивает/разворачивает блок с обучающими видео
     */
    function toggleCollapsedVideoLessons() {
      vm.isVideoLessonsCollapsed = !vm.isVideoLessonsCollapsed;
      localStorage.setItem('isVideoLessonsCollapsed_' + LAST_DATE_UPDATE_VIDEO_LESSONS, vm.isVideoLessonsCollapsed);
    }

    /**
     * Трек отмена выбора основной воронки
     */
    function trackCancelSelectMainFunnel() {
      carrotquestHelper.track('Dashboard - отмена выбора основной воронки', { App: vm.currentApp.name });
    }

    /**
     * Трек клика "Все автосообщения"
     */
    function trackClickAllAutoMessages() {
      carrotquestHelper.track('Dashboard - клик на "все автосообщения"', { App: vm.currentApp.name });
    }

    /**
     * Трек клика "Статистика рассылок"
     */
    function trackClickAllConversationsStatistics() {
      carrotquestHelper.track('Dashboard - клик на "статистика рассылок"', { App: vm.currentApp.name });
    }

    /**
     * Трек клика "Все диалоги"
     */
    function trackClickAllDialogs() {
      carrotquestHelper.track('Dashboard - клик на "все диалоги"', { App: vm.currentApp.name });
    }

    /**
     * Трек клика по ссылке "Все воронки"
     */
    function trackClickAllFunnelsLink() {
      carrotquestHelper.track('Dashboard - клик по ссылке "Все воронки"', { App: vm.currentApp.name });
    }

    /**
     * Трек клика "Посмотреть всех лидов"
     */
    function trackClickAllLeads() {
      carrotquestHelper.track('Dashboard - клик на "посмотреть всех лидов"', { App: vm.currentApp.name });
    }

    function trackClickGuideTab() {
      carrotquestHelper.track('Гид по сервису', { App: vm.currentApp.name });
    }

    function trackClickScenariosTab() {
      carrotquestHelper.track('Сценарии работы', { App: vm.currentApp.name });
    }

    function trackClickStatisticsTab() {
      carrotquestHelper.track('Общая статистика', { App: vm.currentApp.name });
    }

    /**
     * Трек клика "Создайте основную воронку"
     */
    function trackClickCreateFunnelModal() {
      carrotquestHelper.track('Dashboard - клик "Создайте основную воронку"', { App: vm.currentApp.name });
    }

    /** Трек клика на 'Чат-бот - закрыл модалку с шаблонами' */
    function trackClickOnCloseTemplateChatBotModal() {
      carrotquestHelper.track('Чат-бот - закрыл модалку с шаблонами');
    }

    /**
     * Трек клика "Посмотреть всех лидов"
     */
    function trackClickInstallService() {
      carrotquestHelper.track('Dashboard - клик на "Установить сервис на сайт"', { App: vm.currentApp.name });
    }

    /**
     * Трек клика на обечающее видео
     *
     * @param {String} videoName - Имя видео
     */
    function trackClickVideoLesson(videoName) {
      carrotquestHelper.track('Click', {
        type: 'movies_from_dashboard',
        title: videoName,
      });
    }

    /**
     * Трек клика на ссылку "Смотреть больше на YouTube"
     */
    function trackClickWatchMoreVideo() {
      carrotquestHelper.track('Click', {
        type: 'movies_from_dashboard',
        title: 'Смотреть больше на YouTube',
      });
    }

    /**
     * Трек закрытия модалки с обучающим видео
     *
     * @param {String} videoName - Имя видео
     */
    function trackCloseVideoModal(videoName) {
      //carrotquest.track('Click', {'type': 'closed_modal_window', 'title': <название ролика>});
      carrotquestHelper.track('Click', {
        type: 'closed_modal_window',
        title: videoName,
      });
    }

    /**
     * Трек клика "Выбрать основную воронку"
     */
    function trackSelectMainFunnel(eventName) {
      carrotquestHelper.track('Dashboard - выбрал воронку из текущих', {
        App: vm.currentApp.name,
        Название: eventName,
      });
    }
  }
})();

<ng-container *transloco="let t">
  <div
    class="form-group no-margin-bottom"
    [cqShowError]="control"
  >
    <input
      class="form-control"
      [formControl]="control"
      [placeholder]="t('whatsAppEdnaAccountPhone.input.placeholder')"
      type="text"
    />
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">{{
        t('whatsAppEdnaAccountPhone.input.errors.required')
      }}</cq-validation-message>
      <cq-validation-message errorName="pattern">{{
        t('whatsAppEdnaAccountPhone.input.errors.invalid')
      }}</cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

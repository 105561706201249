<ng-container *transloco="let t; read: 'triggerChainCanvasActionsComponent'">
  <div class="d-flex p-20 justify-content-between border-bottom">
    <div class="d-flex margin-between-cols-10">
      <button
        class="btn btn-outline-primary"
        (click)="onClickGoToStartButton()"
        type="button"
      >
        <i class="btn-icon cqi-sm cqi-home-o"></i>
      </button>

      <div
        ngbDropdown
        [autoClose]="true"
      >
        <button
          class="btn btn-outline-primary"
          ngbDropdownToggle
          type="button"
        >
          <i class="btn-icon-left cqi-sm cqi-plus"></i>
          <span>{{ t('blocks.title') }}</span>
        </button>
        <div ngbDropdownMenu>
          <ng-container *ngIf="TRIGGER_CHAIN_CONTENT_BLOCK_TYPES.length">
            <div class="dropdown-header">{{ t('blocks.types.content.title') }}</div>
            <ng-container *ngFor="let stepType of TRIGGER_CHAIN_CONTENT_BLOCK_TYPES">
              <ng-container
                *ngTemplateOutlet="
                  stepType === TRIGGER_CHAIN_BLOCK_TYPE.SDK_PUSH ? sdkPushDropdownItem : dropdownButtonItem;
                  context: { stepType: stepType }
                "
              ></ng-container>
            </ng-container>
          </ng-container>

          <hr class="my-5" />

          <ng-container *ngIf="TRIGGER_CHAIN_LOGIC_BLOCK_TYPES.length">
            <div class="dropdown-header">{{ t('blocks.types.logic.title') }}</div>
            <button
              *ngFor="let stepType of TRIGGER_CHAIN_LOGIC_BLOCK_TYPES"
              ngbDropdownItem
              (click)="onClickAddChainLinkButton(stepType)"
              type="button"
            >
              <i
                class="cqi-sm mr-5"
                [ngClass]="getClassForStepIcon(stepType)"
              ></i>
              <span>{{ 'triggerChainBlock.type.' + stepType | transloco }}</span>
            </button>
          </ng-container>

          <hr class="my-5" />

          <ng-container *ngIf="TRIGGER_CHAIN_EXIT_BLOCK_TYPES.length">
            <div class="dropdown-header">
              <span>{{ t('blocks.types.exit.title') }}</span>
              <i
                class="cqi-sm cqi-question-circle"
                [ngbTooltip]="t('blocks.types.exit.tooltip')"
              ></i>
            </div>
            <button
              *ngFor="let stepType of TRIGGER_CHAIN_EXIT_BLOCK_TYPES"
              ngbDropdownItem
              (click)="onClickAddChainLinkButton(stepType)"
              type="button"
            >
              <i
                class="cqi-sm mr-5"
                [ngClass]="getClassForStepIcon(stepType)"
              ></i>
              <span>{{ 'triggerChainBlock.type.' + stepType | transloco }}</span>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="btn-group">
      <button
        class="btn btn-outline-primary"
        (click)="onClickChangeZoomButton(CANVAS_ZOOM_DIRECTION.DOWN)"
        type="button"
      >
        <i class="btn-icon cqi-sm cqi-minus-circle"></i>
      </button>
      <button
        class="btn btn-outline-primary"
        (click)="onClickChangeZoomButton(CANVAS_ZOOM_SETTING.DEFAULT_VALUE)"
        type="button"
      >
        {{ zoomValue }} %
      </button>
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="onClickChangeZoomButton(CANVAS_ZOOM_DIRECTION.UP)"
      >
        <i class="btn-icon cqi-sm cqi-plus-circle"></i>
      </button>
    </div>

    <div class="d-flex margin-between-cols-10">
      <button
        *ngIf="hasAccessToCanvasScreenshotFeature"
        class="btn btn-outline-primary"
        type="button"
        (click)="onClickTakeScreenshot()"
      >
        <i class="btn-icon-left cqi-sm cqi-camera-viewfinder"></i>
        <span>{{ t('takeScreenshotButton') }}</span>
        <cq-beta-label
          class="badge badge-light-secondary"
          [featureLabel]="FEATURE_LABELS_ONLY.CANVAS_SCREENSHOT"
        ></cq-beta-label>
      </button>
      <button
        *ngIf="hasAccessToCopyTemplate"
        class="btn btn-outline-primary"
        (click)="onClickCopyStructureForTemplate()"
      >
        <i class="btn-icon cqi-sm cqi-copy"></i>
      </button>
      <button
        class="btn btn-outline-primary"
        (click)="onClickToggleFullscreenModeButton()"
      >
        <i
          class="btn-icon cqi-sm"
          [ngClass]="getClassForToggleFullscreenIcon()"
        ></i>
      </button>
    </div>
  </div>

  <!--Шаблон для SDK кнопки дропдауна-->
  <ng-template
    #sdkPushDropdownItem
    let-stepType="stepType"
  >
    <ng-container
      *ngTemplateOutlet="isActive(stepType) ? dropdownButtonItem : inactiveSdk; context: { stepType: stepType }"
    ></ng-container>

    <!--Неактивная кнопка с SDK-->
    <ng-template #inactiveSdk>
      <div
        [ngbPopover]="sdkActivationPopover"
        placement="right"
        popoverClass="dark-theme"
        triggers="mouseenter"
        [autoClose]="true"
      >
        <button
          ngbDropdownItem
          type="button"
          class="disabled"
        >
          <i
            class="cqi-sm mr-5"
            [ngClass]="getClassForStepIcon(stepType)"
          ></i>
          <span>{{ 'triggerChainBlock.type.' + stepType | transloco }}</span>
        </button>
      </div>
    </ng-template>
  </ng-template>

  <!--Шаблон для активной кнопки дропдауна с контентом-->
  <ng-template
    #dropdownButtonItem
    let-stepType="stepType"
  >
    <button
      ngbDropdownItem
      (click)="onClickAddChainLinkButton(stepType)"
      type="button"
    >
      <i
        class="cqi-sm mr-5"
        [ngClass]="getClassForStepIcon(stepType)"
      ></i>
      <span>{{ 'triggerChainBlock.type.' + stepType | transloco }}</span>
    </button>
  </ng-template>

  <!--Шаблон для поповера с информаций об активации SDK пушей-->
  <ng-template #sdkActivationPopover>
    <div>
      <div class="margin-bottom-10 font-weight-bold">
        {{ t('sdkActivationPopover.heading') }}
      </div>
      <div class="margin-bottom-10">{{ t('sdkActivationPopover.text') }}</div>
      <div class="flex">
        <a
          class="btn btn-outline-primary"
          [href]="sdkPopoverLink"
          target="_blank"
        >
          {{ t('sdkActivationPopover.moreInfoButton') }}
        </a>
      </div>
    </div>
  </ng-template>
</ng-container>

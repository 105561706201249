import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { AmocrmWrapperSelectModalComponent } from './amocrm-wrapper-select-modal.component';

@NgModule({
  declarations: [AmocrmWrapperSelectModalComponent],
  exports: [AmocrmWrapperSelectModalComponent],
  imports: [CommonModule, TranslocoModule],
})
export class AmocrmWrapperSelectModalModule {}

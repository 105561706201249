<div class="progress-wrapper">
  <div class="no-space margin-bottom-5">
    <ng-content></ng-content>
  </div>

  <div class="progress">
    <div
      class="progress-bar"
      [attr.aria-labelledby]="title"
      [attr.aria-valuemax]="max"
      [attr.aria-valuemin]="0"
      [attr.aria-valuenow]="value"
      [attr.aria-valuetext]="1"
      role="progressbar"
      [ngClass]="type && 'bg-' + type"
      [ngStyle]="{ width: (percent < 100 ? percent : 100) + '%' }"
    ></div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { CalendlyIntegrationExternal } from '@http/integration/integrations/calendly/interfaces/calendly-integration.interface';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { BotMeetingActionForm } from '@panel/app/pages/chat-bot/forms/actions/meeting-action.form';
import { CalendlyEvent } from '@panel/app/pages/integrations/content/calendly/calendly.type';

@Component({
  selector: 'cq-branch-action-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotActionMeetingComponent implements OnInit {
  @Input({ required: true })
  actionForm!: BotMeetingActionForm;

  protected calendlyEventList: CalendlyEvent[] = [];
  protected calendlyIntegrationList: CalendlyIntegrationExternal[] = [];

  protected readonly INTEGRATION_TYPES = INTEGRATION_TYPES;
  constructor(private readonly stateService: StateService) {}

  ngOnInit(): void {
    this.calendlyEventList = this.stateService.calendlyEventList$.getValue();
    this.calendlyIntegrationList = this.stateService.calendlyIntegrationList$.getValue();

    this.hrefControl.valueChanges
      .pipe(
        tap((href: string) => {
          const currentCalendlyEvent = this.calendlyEventList.find(
            (calendlyEvent) => calendlyEvent.attributes.url === href,
          )!;

          this.nameControl.setValue(currentCalendlyEvent.attributes.name);
          this.integration.setValue(this.calendlyIntegrationList[0].id);
        }),
      )
      .subscribe();
  }

  get nameControl() {
    return this.actionForm.controls.bodyJson.controls.name;
  }
  get hrefControl() {
    return this.actionForm.controls.bodyJson.controls.href;
  }

  get integration() {
    return this.actionForm.controls.bodyJson.controls.integration;
  }
}

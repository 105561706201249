import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';

(function () {
  'use strict';

  angular.module('myApp.planCapability').config(config);

  function config($stateProvider) {
    $stateProvider
      .state('app.content.planCapabilityPaywall', {
        url: '/plan-capability-paywall',
        redirectTo: 'app.content.planCapabilityPaywall.general',
        template: '<div ui-view></div>',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
      })

      .state('app.content.planCapabilityPaywall.general', {
        url: '',
        bindings: {
          billingInfo: 'billingInfo',
          currentApp: 'currentApp',
          djangoUser: 'djangoUser',
        },
        component: 'cqPlanCapabilityPaywallWrapper',
      });
  }
})();

<ng-container *transloco="let t; read: 'amocrmModalLeadSettingsComponent'">
  <div class="modal-header flex-wrap">
    <h3 class="modal-title mb-5">
      {{ t('heading', { integrationName: modalParams?.integration?.name }) }}
    </h3>
    <button
      class="close"
      (click)="dismissModal()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
    <div class="w-100">
      <a
        uiSref="app.content.integrations.details.configured"
        target="_blank"
        [uiParams]="{ integrationType: modalParams?.integration?.type, integrationId: modalParams?.integration?.id }"
        class="cursor-pointer"
      >
        <i class="cqi-sm cqi-external-link"></i>
        <span>{{ t('aboutIntegrationLink') }}</span>
      </a>
    </div>
  </div>

  <div class="modal-body">
    <cq-amocrm-lead-note
      class="mb-30"
      [controlNote]="pipelineNoteControl"
      [isChatBot]="isChatBot()"
    ></cq-amocrm-lead-note>

    <cq-amocrm-lead-tags
      [isChatBot]="isChatBot()"
      [controlLeadTags]="controlLeadTags"
      [integrationId]="integrationId"
    ></cq-amocrm-lead-tags>
  </div>

  <div class="modal-footer border">
    <button
      class="btn btn-outline-primary"
      (click)="dismissModal()"
    >
      {{ 'general.cancel' | transloco }}
    </button>
    <button
      class="btn btn-primary"
      (click)="done()"
      [disabled]="pipelineNoteControl.invalid"
    >
      {{ t('doneButton') }}
    </button>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { SingleUrlFilterConfiguratorComponent } from '@panel/app/partials/url-filter-configurator/single-url-filter-configurator/single-url-filter-configurator.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [SingleUrlFilterConfiguratorComponent],
  exports: [SingleUrlFilterConfiguratorComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbPopoverModule,
    NgbTooltipModule,
  ],
  providers: [CarrotquestHelperProvider],
})
export class SingleUrlFilterConfiguratorModule {}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Sortable, { Options } from 'sortablejs';

interface SavedReply {
  id: string;
  app: string;
  body: string;
  bodyHeadline?: string;
  header: string;
  headerHeadline?: string;
  order: number;
}

@Component({
  selector: 'cq-saved-replies-new',
  templateUrl: './saved-replies-new.component.html',
  styleUrls: ['./saved-replies-new.component.scss'],
})
export class SavedRepliesNewComponent implements OnInit {
  @Input() isApiRequestPerformed: Boolean = false; // Статус выполенения запросов
  @Input() isSearch: Boolean = false; // Флаг, который показывает находимся ли мы в режиме поиска
  @Input() savedReplies: SavedReply[] = []; // Список сохранённых ответов
  @Input() searchedSavedReplies: SavedReply[] = []; // Список найденных через поиск сохранённых ответов
  @Output() savedReplyCreated = new EventEmitter(); // Колбэк при создании сохранённого ответа
  @Output() savedReplyOrderChanged = new EventEmitter<{ savedReply: SavedReply; newOrder: SavedReply['order'] }>(); // Колбэк при смене приоритета сохранённого ответа
  @Output() savedReplyEdited = new EventEmitter(); // Колбэк при редактировании сохранённого ответа
  @Output() savedReplyRemoved = new EventEmitter<SavedReply>(); // Колбэк при удалении сохранённого ответа
  @Output() searchReset = new EventEmitter(); // Колбэк при сбросе режима поиска
  @ViewChild('bottomScrollZone') bottomScrollZone!: ElementRef; // Нижняя область для scrolling'а при перетаскивании через drag&drop
  @ViewChild('topScrollZone') topScrollZone!: ElementRef; // Вернхяя область для scrolling'а при перетаскивании через drag&drop
  @ViewChild('savedRepliesTable') savedRepliesTable!: ElementRef; // Таблица с сохраненными ответами

  /**
   * Id html-node контейнера, который скролится при перетаскивании сохраненных ответов
   */
  CONTENT_NODE_ID: string = 'content';

  /**
   * Высота зоны в которую пользователь может перетащить в сохранный ответ, если захочет проскролить страинцу
   * Из-за нативного drag&drop'а в HTML5 всегда равен 20px
   */
  SCROLL_ZONE_HEIGHT: number = 20;

  /**
   * HTML-node контейнера, который скролится при перетаскивании сохраненных ответов
   */
  contentNode!: HTMLElement;

  /**
   * Настройки для библиотеки sortablejs
   */
  sortablejsOptions: Options = {
    handle: '.handle-icon',
    onChoose: this.onChoose.bind(this),
    onUnchoose: this.onUnchoose.bind(this),
    onUpdate: this.onUpdate.bind(this),
  };

  ngOnInit(): void {
    this.contentNode = document.getElementById(this.CONTENT_NODE_ID)!;
  }

  /**
   * Создание сохранённого ответа
   */
  createSavedReply(): void {
    this.savedReplyCreated.emit();
  }

  /**
   * Редактирование сохранённого ответа
   *
   * @param {SavedReply} savedReply — Редактируемый сохранённый ответ
   */
  editSavedReply(savedReply: SavedReply): void {
    this.savedReplyEdited.emit(savedReply);
  }

  /**
   * Обработчик "захвата" перетаскиваемого сохраненного ответа
   */
  onChoose(): void {
    let lastSavedReply =
      this.savedRepliesTable.nativeElement.rows[this.savedRepliesTable.nativeElement.rows.length - 1];

    if (this.contentNode.scrollTop >= this.topScrollZone.nativeElement.parentElement.offsetTop) {
      this.topScrollZone.nativeElement.classList.add('show');
    }

    if (
      this.contentNode.scrollTop + this.contentNode.clientHeight <
      lastSavedReply.offsetTop + lastSavedReply.clientHeight + this.savedRepliesTable.nativeElement.offsetTop
    ) {
      this.bottomScrollZone.nativeElement.classList.add('show');
    }
  }

  /**
   * Обработчик "отпускания" перетаскиваемого сохраненного ответа
   */
  onUnchoose(): void {
    this.topScrollZone.nativeElement.classList.remove('show');
    this.bottomScrollZone.nativeElement.classList.remove('show');
  }

  /**
   * Колбэк, срабатывающий при смене позиции ответа в списке после drag-n-drop
   *
   * @param event
   */
  onUpdate(event: Sortable.SortableEvent): void {
    let newOrder: SavedReply['order'];
    if (event.oldIndex! > event.newIndex!) {
      newOrder = this.savedReplies[event.newIndex! + 1].order;
    } else {
      newOrder = this.savedReplies[event.newIndex! - 1].order;
    }
    this.savedReplyOrderChanged.emit({
      savedReply: this.savedReplies[event.newIndex!],
      newOrder: newOrder,
    });
  }

  /**
   * Удаление сохранённого ответа
   *
   * @param {SavedReply} savedReply — Удаляемый сохранённый ответ
   */
  removeSavedReply(savedReply: SavedReply): void {
    this.savedReplyRemoved.emit(savedReply);
  }

  /**
   * Сброс режима поиска
   */
  resetSearch(): void {
    this.searchReset.emit();
  }

  /**
   * Функция для trackBy
   *
   * @param index
   * @param item
   */
  trackById(index: number, item: any): string {
    return item.id;
  }
}

import { UserColorsCalculationService } from '../../../../app/shared/services/user-colors-calculation/user-colors-calculation.service';
import { AUTO_REPLY_TYPE } from '../../../../app/http/app/app.constants';
import { firstValueFrom } from 'rxjs';
import { STARTER_GUIDE_STEPS } from '../../../../app/http/starter-guide/starter-guide.constants';

(function () {
  'use strict';

  angular.module('myApp.starterGuide').controller('CqStarterGuideWorkTimeController', CqStarterGuideWorkTimeController);

  function CqStarterGuideWorkTimeController($scope, carrotquestHelper, chatSettingsModel, starterGuideModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.AUTO_REPLY_TYPES = AUTO_REPLY_TYPE;
      vm.accentHexColor = vm.chatSettings.messenger_collapsed_color;
      vm.contrastColor = '#ffffff';
      vm.doAccentAndWhiteMatchGood = true;
      vm.iconTheme = 'default';
      vm.isContrastGood = true;
      vm.isWaitingOperatorResponse = true;
      vm.saveSettings = saveSettings;
      vm.trackingDataKnown = false; // Знаем ли мы данные пользователя
      vm.userColorAndThemeDependent = vm.accentHexColor;
      vm.workingTime = false; // Рабочее ли сейчас время

      $scope.$watchGroup(
        ['vm.chatSettings.messenger_collapsed_color', 'vm.chatSettings.messenger_theme'],
        function (newValues, oldValues) {
          const [color, theme] = newValues;
          calcChatColors(color);
        },
      );
    }

    /**
     * Вся логика - копипаста из репо cqstatic. Смотреть файл getUserColorPalette.js
     */
    function calcChatColors(newColor) {
      // В статике есть логика того, что мы меняем его цвет, если цвет у клиента слишком светлый в светлой теме
      vm.accentHexColor = UserColorsCalculationService.getMainUserColor(newColor, vm.chatSettings.messenger_theme);

      const { isLightOnClientsColorLooksWell, contrastColor, userColorAndThemeDependent, iconTheme, isContrastGood } =
        UserColorsCalculationService.getUserColorPalette(vm.accentHexColor, vm.chatSettings.messenger_theme);

      vm.contrastColor = contrastColor;
      vm.userColorAndThemeDependent = userColorAndThemeDependent;
      vm.doAccentAndWhiteMatchGood = isLightOnClientsColorLooksWell;
      vm.iconTheme = iconTheme;
      vm.isContrastGood = isContrastGood;
    }

    /**
     * Сохранение настроек
     *
     * @param {Boolean} valid Флаг валидности формы
     */
    function saveSettings(valid) {
      if (!valid) {
        return;
      }

      const settings = {
        messenger_auto_reply_show_config: vm.chatSettings.messenger_auto_reply_show_config,
        messenger_auto_reply_texts: vm.chatSettings.messenger_auto_reply_texts,
        messenger_auto_reply_type: vm.chatSettings.messenger_auto_reply_type,
        messenger_offline_if_no_operators: vm.chatSettings.messenger_offline_if_no_operators,
        messenger_work_time: vm.chatSettings.messenger_work_time,
      };

      firstValueFrom(chatSettingsModel.save(vm.currentApp.id, settings)).then(saveSuccess);

      function saveSuccess() {
        starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.WORK_TIME).subscribe();

        angular.extend(vm.currentApp.settings, vm.chatSettings);

        trackStarterGuideStepWorkTimeCompletion();
      }
    }

    /** Трекинг завершения шага "Рабочее время" в стартергайде */
    function trackStarterGuideStepWorkTimeCompletion() {
      const eventName = 'Стартергайд - рабочее время настроено';

      carrotquestHelper.track(eventName);
    }
  }
})();

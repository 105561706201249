import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ArticleModel } from '@http/article/article.model';
import { ArticleCategoryModel } from '@http/article-category/article-category.model';
import { DestroyService } from '@panel/app/services';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-knowledge-base-categories',
  templateUrl: './knowledge-base-categories.component.html',
  styleUrls: ['./knowledge-base-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class KnowledgeBaseCategoriesComponent implements OnInit {
  /** Количество статей */
  @Input() articlesCount!: number;
  /** информация о биллинге */
  @Input() billingInfo!: any;
  /** Категории статей */
  @Input() categories!: any[];
  /** Текущий App */
  @Input() currentApp!: any;
  /** Пользователь */
  @Input() djangoUser!: any;
  /** Подключён ли модуль с продвинутой базой знаний */
  @Input() isKnowledgeBaseProActivated!: boolean;
  /** Настройки БЗ */
  @Input() settings!: any;

  /** Отфильтрованные статьи */
  protected articles: any[] = [];
  /** Флаг показывающий загрузку данных */
  protected isLoading: boolean = false;
  /** Включен ли поиск */
  protected isSearch: boolean = false;
  /** Фраза для поиска */
  protected searchPhrase: string = '';

  constructor(
    protected readonly destroy$: DestroyService,
    protected readonly articleCategoryModel: ArticleCategoryModel,
    protected readonly articleModel: ArticleModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly uiRouter: UIRouter,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.carrotquestHelper.track('База знанний - переход');

    sessionStorage.setItem('currentArticleCategoryId', '');
  }

  /**
   * Сбросить поиск
   */
  resetSearch(): void {
    this.isSearch = false;
    this.searchPhrase = '';
  }

  /**
   * Поиск
   */
  search() {
    this.isLoading = true;

    return this.articleModel.search(this.currentApp.id, this.searchPhrase).pipe(
      tap((articles: any) => {
        this.articles = articles;
        this.isSearch = true;
      }),
    );
  }

  /**
   * Обработка сабмита формы поиска
   *
   * @param isValid - Валидность формы
   */
  submitSearch(isValid: any): void {
    if (isValid && this.searchPhrase.length) {
      this.trackSearch();
      this.search().subscribe(() => {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      });
    } else if (isValid && !this.searchPhrase.length) {
      this.resetSearch();
    }
  }

  /**
   * Обновление категории
   */
  updateCategories() {
    return this.articleCategoryModel.getList(this.currentApp.id).pipe(
      tap((categories: any) => {
        this.categories = categories;
      }),
    );
  }

  /**
   * Обновление данных
   */
  updateData(): void {
    this.isLoading = true;
    const queries: Promise<any>[] = [firstValueFrom(this.updateCategories())];

    if (this.isSearch) {
      queries.push(firstValueFrom(this.search()));
    }

    Promise.all(queries).finally(() => {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * Трек поиска
   */
  trackSearch(): void {
    this.carrotquestHelper.track('База знаний - применил поиск');
  }
}

(function () {
  'use strict';

  angular
    .module('myApp.completionRegistration')
    .controller('cqCompletionRegistrationFormController', cqCompletionRegistrationFormController);

  function cqCompletionRegistrationFormController() {
    let vm = this;
    vm.$onInit = init;

    function init() {
      vm.isRequiredMode = vm.isRequiredMode ?? null;
      vm.onSubmitCompletionRegistrationForm = onSubmitCompletionRegistrationForm;
    }

    /** Обработчик отправки формы завершения регистрации */
    function onSubmitCompletionRegistrationForm() {
      const isValidForm = vm.completionRegistrationForm.$valid;
      if (isValidForm) {
        vm.submitCompletionRegistrationForm();
      }
    }
  }
})();

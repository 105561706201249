<ng-container *transloco="let t">
  <h3>{{ t('vkOAuthContainerComponent.heading', { integrationTypeName: integrationTypeName }) }}</h3>
  <div class="margin-bottom-20">
    {{
      t('vkOAuthContainerComponent.description', {
        integrationTypeName: integrationTypeName,
        projectName: projectName
      })
    }}
  </div>
  <cq-o-auth-button
    class="w-100"
    [isAuthorized]="isAuthorized"
    [oAuthUrl]="oAuthUrl"
    (authorizeError)="onAuthorizeError($event)"
    (authorizeSuccess)="onSuccessAuthorize()"
  >
    <i class="btn-icon-left cqi-sm cqi-refresh-2"></i>
    {{
      t('vkOAuthContainerComponent.authorizeButton', {
        integrationTypeName: integrationTypeName
      })
    }}
  </cq-o-auth-button>
</ng-container>

(function () {
  'use strict';

  angular.module('myApp.users').controller('CqPreviewImportLeadsController', CqPreviewImportLeadsController);

  function CqPreviewImportLeadsController(PROJECT_NAME) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.previewParsedData = vm.parsedResults.data.slice(1, 6); // Для превью взять первые 5 элементов, не влючая заголовков
      vm.PROJECT_NAME = PROJECT_NAME;
    }
  }
})();

export enum ONE_TIME_FLAGS {
  SHOW_AI_DATA_SETTINGS_BANNER = 'show_ai_data_settings_banner',
  STARTER_GUIDE_LOGO_TOOLTIP = 'starter_guide_logo_tooltip',
  TELEGRAM_CHANNEL_POPOVER = 'telegram_channel_popover',
  TRACK_CLICK_CHAT_BOT_BUTTON_URL_INPUT = 'track_click_chat_bot_button_url_input',
  TRACK_CLICK_PLAY_AUDIO = 'track_click_play_audio',
  TRACK_CLICK_TRANSCRIBE_AUDIO = 'track_click_transcribe_audio',
  USE_CALENDLY_POPOVER = 'use_calendly_popover',
  WHATS_APP_CHANNEL_POPOVER = 'whats_app_channel_popover',
  WHATS_APP_INTEGRATION_ICON_HINT_MODAL = 'whats_app_integration_icon_hint_modal',
}

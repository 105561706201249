import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { TimeSinceLastReplyComponent } from '@panel/app/pages/conversations/conversations-list/time-since-last-reply/time-since-last-reply.component';

@NgModule({
  declarations: [TimeSinceLastReplyComponent],
  exports: [TimeSinceLastReplyComponent],
  imports: [CommonModule, TranslocoModule],
})
export class TimeSinceLastReplyModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Компонент для работы с текстом тега */
@Component({
  selector: 'cq-tag-text[text]',
  templateUrl: './tag-text.component.html',
  styleUrls: ['./tag-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagTextComponent {
  /** Текст тега */
  @Input()
  text!: string;
}

<ng-container *transloco="let t; read: 'cancellationExactlyModalComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('title') }}</h3>
    <button
      class="close"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div
      class="mb-10 font-weight-bold"
      [innerHTML]="t('content')"
    ></div>

    <div class="position-relative">
      <cq-validation-messages
        class="bottom-0"
        [control]="formControl"
      >
        <cq-validation-message errorName="required">{{
          'subscriptionCancellationComponent.errors.required' | transloco
        }}</cq-validation-message>
      </cq-validation-messages>

      <div class="margin-between-rows-10">
        <div class="custom-control custom-radio d-flex">
          <input
            class="custom-control-input"
            [attr.id]="t('reasons.no_feature')"
            [formControl]="formControl"
            [value]="t('reasons.no_feature')"
            type="radio"
          />
          <label
            class="custom-control-label"
            [attr.for]="t('reasons.no_feature')"
            >{{ t('reasons.no_feature') }}</label
          >
        </div>

        <div class="custom-control custom-radio d-flex">
          <input
            class="custom-control-input"
            [attr.id]="t('reasons.expensive')"
            [formControl]="formControl"
            [value]="t('reasons.expensive')"
            type="radio"
          />
          <label
            class="custom-control-label"
            [attr.for]="t('reasons.expensive')"
            >{{ t('reasons.expensive') }}</label
          >
        </div>

        <div class="custom-control custom-radio d-flex">
          <input
            class="custom-control-input"
            [attr.id]="t('reasons.hard', {projectName})"
            [formControl]="formControl"
            [value]="t('reasons.hard', {projectName})"
            type="radio"
          />
          <label
            class="custom-control-label"
            [attr.for]="t('reasons.hard', {projectName})"
            >{{ t('reasons.hard') }}</label
          >
        </div>

        <div class="custom-control custom-radio d-flex">
          <input
            class="custom-control-input"
            [attr.id]="t('reasons.another_service')"
            [formControl]="formControl"
            [value]="t('reasons.another_service')"
            type="radio"
          />
          <label
            class="custom-control-label"
            [attr.for]="t('reasons.another_service')"
            >{{ t('reasons.another_service') }}</label
          >
        </div>

        <div class="custom-control custom-radio d-flex">
          <input
            class="custom-control-input"
            [attr.id]="t('reasons.another_reason')"
            [formControl]="formControl"
            [value]="t('reasons.another_reason')"
            type="radio"
          />
          <label
            class="custom-control-label"
            [attr.for]="t('reasons.another_reason')"
            >{{ t('reasons.another_reason') }}</label
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container
      *tuiLet="{
        app: subscriptionStore.app$ | async,
        billing: subscriptionStore.billing$ | async
      } as values"
    >
      <ng-container *ngIf="values.app && values.billing && values.billing.subscription">
        <button
          class="btn btn-outline-danger"
          [disabled]="isRequestPerformed"
          type="button"
          (click)="onClickCancellationButton(values.app, values.billing, formControl.value, false)"
        >
          {{ t('buttons.cancelRightNow') }}
        </button>
        <button
          class="btn btn-outline-primary"
          [disabled]="isRequestPerformed"
          type="button"
          (click)="onClickCancellationButton(values.app, values.billing, formControl.value, true)"
        >
          {{ t('buttons.cancelAtCurrentTermEndDate') }}
        </button>
      </ng-container>
    </ng-container>

    <button
      class="btn btn-primary"
      type="button"
      (click)="ngbActiveModal.dismiss()"
    >
      {{ t('buttons.dontCancel') }}
    </button>
  </div>
</ng-container>

<cq-tag-default
  class="overflow-hidden margin-5"
  (activate)="activateTagCallback(tag)"
  (remove)="removeTagCallback(tag.tag)"
  [disabled]="includeNoTags"
  [canActivate]="true"
  [canRemove]="canRemove"
  [isActive]="tag.ticked"
  [text]="tag.tag"
  *ngFor="let tag of tags; trackBy: trackByFn"
></cq-tag-default>

<ng-container *transloco="let t">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a uiSref="app.content.integrations.general">
          {{ t('models.integration.breadcrumbs.integrations') }}
        </a>
      </li>
      <li class="breadcrumb-item">
        <a
          [uiParams]="{ integrationType: integrationExternal.type }"
          uiSref="app.content.integrations.preview"
          >{{ translatedIntegrationTypeName }}</a
        >
      </li>
      <li
        class="breadcrumb-item active"
        aria-current="page"
      >
        <span *ngIf="!isEdit">{{ t('models.integration.breadcrumbs.creating') }}</span>
        <span *ngIf="isEdit">{{ integrationExternal.name }}</span>
      </li>
    </ol>
  </nav>

  <ng-container *ngIf="isEdit">
    <!-- Предупреждение об приостановленной интеграции -->
    <div [ngbCollapse]="integrationExternal.active">
      <ngb-alert
        class="margin-bottom-20"
        [dismissible]="false"
        type="warning"
        >{{
          t('integrationDescriptionComponent.alerts.suspended', { integrationTypeName: translatedIntegrationTypeName })
        }}</ngb-alert
      >
    </div>

    <!-- Предупреждения в интеграции TODO: Перенести перевод в модели конкретной интеграции когда они будут на Angular -->
    <ngb-alert
      *ngFor="let error of integrationExternal.errors"
      class="margin-bottom-20"
      [dismissible]="false"
      type="danger"
      >{{
        t('integrationDescriptionComponent.alerts.errors.' + integrationExternal.type + '.' + error, {
          integrationTypeName: translatedIntegrationTypeName
        })
      }}</ngb-alert
    >
  </ng-container>

  <!-- Ошибки в интеграции TODO: Перенести перевод в модели конкретной интеграции когда они будут на Angular -->
  <ngb-alert
    *ngFor="let warning of integrationExternal.warnings"
    class="margin-bottom-20"
    [dismissible]="false"
    type="warning"
    >{{
      t('integrationDescriptionComponent.alerts.warnings.' + integrationExternal.type + '.' + warning, {
        integrationTypeName: translatedIntegrationTypeName
      })
    }}</ngb-alert
  >

  <div class="flex align-items-center margin-between-cols-10 margin-bottom-20">
    <!-- Иконка -->
    <cq-integration-icon
      class="integration-icon"
      [type]="integrationExternal.type"
    ></cq-integration-icon>

    <!-- Название и описание -->
    <div class="flex-grow-1">
      <div class="flex align-items-center margin-bottom-5">
        <h4 class="no-margin font-weight-bold">{{ translatedIntegrationTypeName }}</h4>
        <div
          *ngIf="isBetaIntegration()"
          class="label label-warning margin-left-10"
        >
          {{ t('general.beta') }}
        </div>
      </div>

      <div>{{ translatedIntegrationDescription }}</div>
    </div>

    <!-- Действия -->
    <cq-integration-description-actions
      *ngIf="isShowActionsOnIntegration()"
      [currentApp]="currentApp"
      [integrationExternal]="integrationExternal"
    ></cq-integration-description-actions>
  </div>
</ng-container>

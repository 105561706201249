import { ApiChatBotRequest } from '@http/chat-bot/types/chat-bot-external.types';
import { UtilsModel } from '@http/utils/utils.model';

/**
 * Сервис, предназначеный для поиска и отправки на бэк данных о запросах с дубликатами в ботах
 * По задаче https://favro.com/organization/6fcfbd57a2b44fae2a92e7ea/67cf7916416c0133b25bb9df?card=Car-43752
 */
export class DuplicateLoggerHelper {
  static hasDuplicates(chatBot: ApiChatBotRequest<any>): boolean {
    return chatBot.branches?.length
      ? // Пока что считаем, что одинаковые те ветки, что имеют одинаковые имена, координаты и количество едйствий
        chatBot.branches.some((branch1, index1) => {
          return chatBot.branches!.some((branch2, index2) => {
            return (
              index1 !== index2 &&
              branch1.name === branch2.name &&
              branch1.coordinates?.x === branch2.coordinates?.x &&
              branch1.coordinates?.y === branch2.coordinates?.y &&
              branch1.actions.length === branch2.actions.length
            );
          });
        })
      : false;
  }

  static sendLogs(utilsModel: UtilsModel, payload: ApiChatBotRequest<any>) {
    return utilsModel.saveLog('chat-bot-duplicates', 'chat-bot-duplicates', {}, payload).subscribe();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { App } from '@http/app/app.model';

@Pipe({
  name: 'appIdToName',
})
export class AppIdToNamePipe implements PipeTransform {
  transform(value: string, apps: App[]): string {
    const app = apps.find((app) => app.id === value);
    if (!app) {
      throw new Error('Could not find Application');
    }
    return app.name;
  }
}

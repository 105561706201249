import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { environment } from '@environment';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

/** Компонент для работы с вопросами и ответами */
@Component({
  selector: 'cq-faq[costOverspendingEmailPerOne][costOverspendingUniqueUserPerOne]',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {
  /** Стоимость перерасхода email за единицу */
  @Input()
  costOverspendingEmailPerOne!: number;

  /** Стоимость перерасхода уникальных пользователей за единицу */
  @Input()
  costOverspendingUniqueUserPerOne!: number;

  /** Email поддержки */
  emailSupport = environment.supportAccountEmail;

  /** Россия */
  isRusCountry: boolean = this.l10nHelperService.isRusCountry();

  /** Название проекта */
  nameProject = environment.projectName;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly l10nHelperService: L10nHelperService,
    protected readonly subscriptionStore: SubscriptionStore,
  ) {}

  /**
   * Трекает клик на вопрос
   *
   * @param questionNumber - Номер вопроса
   */
  trackClickQuestion(questionNumber: number): void {
    let eventName;

    switch (questionNumber) {
      case 1:
        eventName = 'Оплата – клик на вопрос «квота уников»';
        break;
      case 2:
        eventName = 'Оплата – клик на вопрос «квота писем»';
        break;
      case 3:
        eventName = 'Оплата – клик на вопрос «закрывающие доки»';
        break;
      case 4:
        eventName = 'Оплата – клик на вопрос «продление подписки»';
        break;
      case 5:
        eventName = 'Оплата – клик на вопрос «оплата после пробного периода»';
        break;
      case 6:
        eventName = 'Оплата – клик на вопрос «способы оплаты»';
        break;
      case 7:
        eventName = 'Оплата – клик на вопрос «если вовремя не оплатить»';
        break;
      case 8:
        eventName = 'Оплата – клик на вопрос «что значит архивный тариф»';
        break;
      case 9:
        eventName = 'Оплата – клик на вопрос «как сменить тариф»';
        break;
      case 10:
        eventName = 'Оплата – клик на вопрос «как отменить подписку»';
        break;
      case 11:
        eventName = 'Оплата – клик на вопрос «лицензионный договор»';
        break;
      case 12:
        eventName = 'Оплата – клик на вопрос «гарантии»';
        break;
      default:
        throw new Error('There is not provided case');
    }

    this.carrotquestHelper.track(eventName);
  }
}

(function () {
  'use strict';

  require('./bitrix24-oauth.controller');

  angular.module('myApp.integrations').component('cqBitrix24OAuth', cqBitrix24OAuth());

  function cqBitrix24OAuth() {
    return {
      controller: 'CqBitrix24OAuthController',
      controllerAs: 'vm',
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmDomainSelectionModule } from '@panel/app/pages/integrations/content/amocrm/domain-selection/amocrm-domain-selection.module';
import { OAuthButtonModule } from '@panel/app/pages/integrations/partials/o-auth-button/o-auth-button.module';
import { SystemToastService } from '@panel/app/services';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

import { AmocrmOAuthContainerComponent } from './amocrm-o-auth-container.component';

@NgModule({
  declarations: [AmocrmOAuthContainerComponent],
  exports: [AmocrmOAuthContainerComponent],
  imports: [AmocrmDomainSelectionModule, CommonModule, FormsModule, OAuthButtonModule, TranslocoModule],
  providers: [IntegrationService, SystemToastService, ToastService],
})
export class AmocrmOAuthContainerModule {}

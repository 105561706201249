import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom } from 'rxjs';

import { DjangoUserModel } from '@http/django-user/django-user.model';
import { EqualValidator } from '@panel/app/shared/validators/equal/equal-validator';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { SystemErrorService } from '@panel/app-old/shared/services/system-error/system-error.service';

type PasswordChangeForm = FormGroup<{
  oldPassword: FormControl<string>;
  newPassword: FormControl<string>;
  newPasswordConfirm: FormControl<string>;
}>;

@Component({
  selector: 'cq-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordChangeComponent {
  protected passwordChangeForm: PasswordChangeForm = this.fb.group({
    oldPassword: this.fb.control('', { nonNullable: true }),
    newPassword: this.fb.control('', { validators: [Validators.required, Validators.minLength(6)], nonNullable: true }),
    newPasswordConfirm: this.fb.control('', {
      validators: [Validators.required, EqualValidator.equal('newPassword')],
      nonNullable: true,
    }),
  });

  constructor(
    protected readonly djangoUserModel: DjangoUserModel,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly systemError: SystemErrorService,
    private readonly fb: FormBuilder,
    private readonly cookieService: CookieService,
  ) {}

  get oldPasswordControl() {
    return this.passwordChangeForm.controls.oldPassword;
  }

  get newPasswordControl() {
    return this.passwordChangeForm.controls.newPassword;
  }

  get newPasswordConfirmControl() {
    return this.passwordChangeForm.controls.newPasswordConfirm;
  }

  /**
   * Смена пароля
   *
   */
  changePassword(): void {
    if (!this.passwordChangeForm.valid) {
      this.passwordChangeForm.markAllAsTouched();
      return;
    }

    firstValueFrom(this.djangoUserModel.changePassword(this.oldPasswordControl.value, this.newPasswordControl.value))
      .then((response: any) => {
        this.cookieService.set('carrotquest_auth_token_panel', response.data.auth_token, {
          expires: 100,
          path: '/',
        }); // 100 дней

        this.toastr.success(
          this.translocoService.translate('account.passwordChange.passwordChangeForm.toasts.passwordChanged'),
        );
        this.trackPasswordChange();
        this.passwordChangeForm.controls.oldPassword.setErrors({
          wrongOldPassword: false,
        });
        this.passwordChangeForm.controls.oldPassword.markAsUntouched();
      })
      .catch((meta: any) => {
        if (meta.error == 'WrongPassword') {
          this.passwordChangeForm.controls.oldPassword.setErrors({
            wrongOldPassword: true,
          });
          this.passwordChangeForm.controls.oldPassword.markAsTouched();
        } else {
          this.systemError.somethingWentWrongToast.show();
        }
      });
  }

  /**
   * Трек успешного изменения пароля
   */
  trackPasswordChange(): void {
    this.carrotquestHelper.track('Аккаунт - Пароль - сменил пароль');
  }
}

/**
 * Фильтр, превращающий секунды в форматированное время
 */
(function () {
  'use strict';

  angular.module('myApp.filters.formatSeconds').filter('formatSeconds', formatSeconds);

  function formatSeconds($translate, timeUnitService) {
    return formatSecondsFilter;

    /**
     * Превращение количества секунд в форматированную строку
     *
     * @param {Number} valueInSeconds Время в секундах
     * @returns {String} строка времени в секундах, минутах, часах и т.д.
     */
    function formatSecondsFilter(valueInSeconds) {
      return timeUnitService.getTranslation(valueInSeconds, undefined, true);
    }
  }
})();

import { firstValueFrom } from 'rxjs';
import { STARTER_GUIDE_STEPS } from '../../../../app/http/starter-guide/starter-guide.constants';

(function () {
  'use strict';

  angular
    .module('myApp.serviceInstallation')
    .controller('CqServiceInstallationCheckerController', CqServiceInstallationCheckerController);

  function CqServiceInstallationCheckerController(
    $filter,
    $scope,
    $uibModal,
    $interval,
    $timeout,
    $window,
    moment,
    PROJECT_NAME,
    appModel,
    carrotquestHelper,
    serviceInstallationToast,
    starterGuideModel,
  ) {
    var vm = this;

    /**
     * Время медленной регулярной проверки установки кода
     * Используется когда НЕ открыто окно с сайтом пользователя
     *
     * @type {number}
     */
    const CHECK_SCRIPT_INSTALLATION_TIME_LONG = 10 * 1000;

    /**
     * Время быстрой регулярной проверки установки кода
     * Используется когда открыто окно с сайтом пользователя
     *
     * @type {number}
     */
    const CHECK_SCRIPT_INSTALLATION_TIME_FAST = 2 * 1000;

    /**
     * Промис остановки проверки установки кода
     *
     * @type {null}
     */
    var checkScriptInstallationInterval = null;

    /**
     * Объект окна сайта пользователя
     * @type {Window | null}
     */
    let usersSiteWindow = null;

    /**
     * Была ли вызвана ручная проверка скрипта во время выполнения автопроверки. Надо чтобы текст поменялся на клик после запроса
     * @type {boolean}
     */
    let wasClickedWhilePerforming = false;

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.appModel = appModel;
      vm.checkScriptInstallation = checkScriptInstallation;
      vm.checkScriptInstallationRequestPerformed = false; // флаг проверки установки сервиса на сайте
      vm.falseScriptCheckByUserCounter = 0; // количество проверок установки кода пользователем, результатом которых был FALSE
      vm.isLoaderVisible = isLoaderVisible;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.submitUrlForm = submitUrlForm;
      vm.trackClickCheckScriptInstallation = trackClickCheckScriptInstallation;
      vm.url = vm.currentApp.origin;

      if (appModel.isAppBlocked(vm.currentApp)) {
        startCheckScriptInterval(CHECK_SCRIPT_INSTALLATION_TIME_LONG);
      }
    }

    function destroy() {
      clearCheckScriptInterval();
    }

    /**
     * Обработка клика по кнопке "Проверить, установлен ли скрипт"
     *
     * @param {boolean} byUser - Метод был вызван пользователем
     */
    function checkScriptInstallation(byUser) {
      if (!vm.checkScriptInstallationRequestPerformed) {
        vm.checkScriptInstallationRequestPerformed = true;

        firstValueFrom(appModel.getLastApiConnectTime(vm.currentApp.id))
          .then(getLastApiConnectTimeSuccess)
          .finally(getLastApiConnectTimeFinally);
      } else {
        wasClickedWhilePerforming = byUser;
      }

      function getLastApiConnectTimeFinally() {
        // таймаут нужен для того, чтобы надпись "Проверяем наличие скрипта" не мгновенно исчезала после нажатия на кнопку, а то будут некрасивые скачки
        $timeout(function () {
          vm.checkScriptInstallationRequestPerformed = false;
        }, 1000);
      }

      function getLastApiConnectTimeSuccess(lastApiConnectTime) {
        vm.currentApp.last_api_connect_time = lastApiConnectTime;
        const isScriptInstalled = !appModel.isAppBlocked(vm.currentApp);

        if ((byUser || wasClickedWhilePerforming) && !isScriptInstalled && vm.falseScriptCheckByUserCounter < 4) {
          vm.falseScriptCheckByUserCounter++;
          wasClickedWhilePerforming = false;
        }

        if (isScriptInstalled) {
          $interval.cancel(checkScriptInstallationInterval);
          serviceInstallationToast.hideToast();

          starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.INSTALLATION_SERVICE).subscribe();
          setTimeout(() => {
            vm.currentApp.activation.installed_code = true;
          }, 0);

          if (vm.modalInstance) {
            vm.modalInstance.dismiss();
          }

          /**
           * Если есть открытое окно, надо запланировать его закрытие
           * Закрыть надо не сразу, чтобы избежать мигающих окон
           */
          if (usersSiteWindow && !usersSiteWindow.closed) {
            $timeout(() => {
              usersSiteWindow.close();
            }, 3000);
          }

          openCodeInstalledSuccessModal();
        } else {
          if (usersSiteWindow && usersSiteWindow.closed) {
            clearCheckScriptInterval();
            startCheckScriptInterval(CHECK_SCRIPT_INSTALLATION_TIME_LONG);
          }
        }
      }
    }

    /**
     * Остановка интервала проверки скрипта
     */
    function clearCheckScriptInterval() {
      $interval.cancel(checkScriptInstallationInterval);
    }

    /**
     * Показывать ли лоадер
     * @returns {boolean}
     */
    function isLoaderVisible() {
      return !!(vm.checkScriptInstallationRequestPerformed || (usersSiteWindow && !usersSiteWindow.closed));
    }

    /**
     * Открытие окна с сайтом пользователя для запуска jsconnect
     */
    function openUsersSite() {
      if (usersSiteWindow && !usersSiteWindow.closed) {
        usersSiteWindow.close();
      }
      const absoluteUrl = /(^http)|(^\/\/)/.test(vm.url) ? vm.url : `//${vm.url}`;
      usersSiteWindow = $window.open(absoluteUrl, '_blank', 'width=800,height=600');
    }

    /**
     * Открытие модалки об успешной установке скрипта
     */
    function openCodeInstalledSuccessModal() {
      $uibModal.open({
        component: 'cqCodeInstalledSuccessModal',
        size: 'md modal-dialog-centered',
        backdrop: 'static',
      });
    }

    /**
     * Запуск интервала для проверки скрипта
     * @param {number} intervalTime - Время интервала
     */
    function startCheckScriptInterval(intervalTime) {
      checkScriptInstallationInterval = $interval(checkScriptInstallation.bind(this, false), intervalTime);
    }

    /**
     * Сабмит формы с полем url'а сайта пользователя
     * Используется для открытия этого сайта в отдельном окне
     * @param {boolean} isValid - Валидность поля с URL'ом
     */
    function submitUrlForm(isValid) {
      if (!isValid) {
        return;
      }

      openUsersSite();

      clearCheckScriptInterval();
      startCheckScriptInterval(CHECK_SCRIPT_INSTALLATION_TIME_FAST);
    }

    /**
     * Трек клика на кнопке "Проверить, установлен ли скрипт"
     */
    function trackClickCheckScriptInstallation() {
      carrotquestHelper.track('Установка сервиса - кликнул на "Проверить, установлен ли сервис"', {
        app: vm.currentApp.name,
      });
    }
  }
})();

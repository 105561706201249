import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Options } from 'sortablejs';

import {
  DEFAULT_ORDER_SOCIAL_NETWORKS,
  SOCIAL_TYPE,
} from '@panel/app/pages/settings/chat/components/social-settings/social-settings.constants';
import { AppSettings, AppSocialSettings } from '@http/app/app.types';

type SocialSettings = {
  text: string | null;
  show: boolean;
};

@Component({
  selector: 'cq-social-settings',
  templateUrl: './social-settings.component.html',
  styleUrls: ['./social-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialSettingsComponent {
  private _messengerSettings!: AppSettings;

  @Input()
  set messengerSettings(value: AppSettings) {
    this._messengerSettings = value;

    // В случае если есть порядок соц. сетей с бека, то присваиваем его
    if (this._messengerSettings.messenger_icon_order && this._messengerSettings.messenger_icon_order.length > 0) {
      this.orderSocials = this._messengerSettings.messenger_icon_order;
    }
  }

  @Output()
  changeSettings: Subject<Partial<AppSocialSettings>> = new Subject();

  /**
   * Данный колбэк нужен для проставления валидности формы в vm.socialSettingsValid
   */
  @Output()
  changeValid: Subject<Boolean> = new Subject();

  orderSocials: SOCIAL_TYPE[] = DEFAULT_ORDER_SOCIAL_NETWORKS;

  /**
   * Настройки для библиотеки sortablejs
   */
  sortablejsOptions: Options = {
    handle: '.handle-icon',
    onUpdate: this.onOrderUpdate.bind(this),
  };

  /**
   * В целом надо переделывать использование компонента social-network.component, пока так
   * Если не сделать кеш, то он на каждый тик собирает новый объект и уходит в бесконечный цикл проверки изменений
   */
  private socialSettingsCache: Partial<Record<SOCIAL_TYPE, SocialSettings>> = {};

  /**
   * Получение объекта соц. сети по имени
   */
  getSocialSettingByName(name: SOCIAL_TYPE) {
    if (!this.socialSettingsCache[name]) {
      this.socialSettingsCache[name] = {
        text: this._messengerSettings[`messenger_icon_${name}_text` as const],
        show: this._messengerSettings[`messenger_icon_${name}_show` as const],
      };
    }
    return this.socialSettingsCache[name];
  }

  /**
   * Колбек при изменении настроек
   */
  onChangeSettings(event: SocialSettings, socialName: SOCIAL_TYPE) {
    let newSettings: Partial<AppSocialSettings> = {};
    // В случае, если соц. сеть не активна или она активна и её длина больше 0, то вернем true
    let validTextInput = !!(!event.show || (event.show && event.text && event.text.trim().length > 0));
    newSettings[`messenger_icon_${socialName}_show` as const] = event.show;
    newSettings[`messenger_icon_${socialName}_text` as const] = event.text;
    this.changeSettings.next(newSettings);
    this.changeValid.next(validTextInput);
  }

  /**
   * Функция обработки отпускания элемента drag&drop
   */
  onOrderUpdate(): void {
    this.changeSettings.next({ messenger_icon_order: this.orderSocials });
  }
}

<div
  *ngIf="savedReplies.length !== 0"
  class="card-header flex align-items-center"
>
  <form
    class="flex margin-right-20"
    novalidate
    (submit)="search(savedRepliesSearch.getRawValue())"
  >
    <div class="search-input form-group has-feedback has-feedback-left no-margin-bottom">
      <input
        class="form-control"
        autocomplete="off"
        name="search"
        [formControl]="savedRepliesSearch"
        [placeholder]="'settings.savedReplies.searchForm.searchInput.placeholder' | transloco"
      />
      <span class="form-control-feedback">
        <i class="cqi-sm cqi-search"></i>
      </span>
    </div>
    <button
      *ngIf="isSearch"
      class="btn btn-borderless-primary margin-left-5"
      type="button"
      (click)="disableSearch()"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </form>
  <button
    class="btn btn-primary margin-left-auto"
    (click)="openCreateSavedReplyModal()"
  >
    {{ 'settings.savedReplies.addSavedReplyButton' | transloco }}
  </button>
</div>
<cq-saved-replies-new
  (savedReplyOrderChanged)="changeSavedReplyOrder($event)"
  (savedReplyCreated)="openCreateSavedReplyModal()"
  (savedReplyEdited)="openEditSavedReplyModal($event)"
  (savedReplyRemoved)="removeSavedReply($event)"
  (searchReset)="resetSearch()"
  [isApiRequestPerformed]="isApiRequestPerformed"
  [isSearch]="isSearch"
  [savedReplies]="savedReplies"
  [searchedSavedReplies]="searchedSavedReplies"
></cq-saved-replies-new>

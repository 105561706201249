import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../../shared/shared.module';
import { CssSelectorInputComponent } from './css-selector-input.component';

@NgModule({
  declarations: [CssSelectorInputComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, ReactiveFormsModule, FormsModule, NgbTooltipModule],
  exports: [CssSelectorInputComponent],
})
export class CssSelectorInputModule {}

import { OnboardingModel } from '../../../app/http/onboarding/onboarding.model';

(function () {
  'use strict';

  angular.module('myApp.completionRegistration').config(configUiRouter).config(configStates).run(run);

  /**
   * Конфигурация uiRouter
   *
   * @param $locationProvider
   * @param $urlServiceProvider
   */
  function configUiRouter($locationProvider, $urlServiceProvider) {
    $locationProvider.html5Mode(true);
    $urlServiceProvider.config.caseInsensitive(true);
    $urlServiceProvider.config.strictMode(false);
  }

  function configStates($stateProvider) {
    $stateProvider.state('onboarding.appSettings', {
      url: '',
      component: 'cqCompletionRegistration',
      bindings: {
        currentApp: 'currentApp',
      },
    });
  }

  function run($state, $transitions, $window) {
    $transitions.onEnter({ entering: 'onboarding' }, onEnterToOnboardingState);
    $transitions.onSuccess({ exiting: 'onboarding' }, onExitToOnboardingState);
    $transitions.onEnter({ entering: 'onboarding.appSettings' }, onEnterToAppSettingsOnboardingState);

    /**
     * Переход на состояние онбординга
     */
    function onEnterToOnboardingState() {
      const viewportMetaTag = $window.document.getElementsByName('viewport')[0];

      viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1');
    }

    /**
     * Выход из состояния онбординга
     */
    function onExitToOnboardingState() {
      const viewportMetaTag = $window.document.getElementsByName('viewport')[0];

      viewportMetaTag.setAttribute('content', 'width=1024');
    }

    /**
     * Переход на стостояние настройки базовой аппа
     *
     * @param transition
     * @param state
     * @returns {*}
     */
    function onEnterToAppSettingsOnboardingState(transition, state) {
      var currentApp = transition.injector().get('currentApp');
      var djangoUser = transition.injector().get('djangoUser');

      // Если все данные пользователя и аппа не совпадают с емейлом (именно емейл подставляется по умолчанию при быстрой регистрации), то переходим в основную админку
      if (OnboardingModel.isBaseDataChanged(currentApp, djangoUser)) {
        return $state.target('app', angular.extend({}, transition.params(), { fromOnboarding: true }));
      }
    }
  }
})();

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { WINDOW } from '@ng-web-apis/common';
import { RenderTexture } from '@pixi/core';
import { Assets, DisplayObject, Graphics, Sprite } from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import { BehaviorSubject } from 'rxjs';

import { PIXI_APP, PIXI_VIEWPORT, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { CanvasBaseService } from '@panel/app/services/canvas/common/base/canvas-base.service';
import { CanvasRenderService } from '@panel/app/services/canvas/common/render/canvas-render.service';
import { HEX_COLOR } from '@panel/app/shared/constants/hex-color.constants';

@Injectable()
export class CanvasScreenshotService {
  /** Путь до изображения watermark */
  private watermarkPath!: string;

  /** Отступы в скриншоте */
  private readonly PADDING = 20;

  /** Название файла со скриншотом */
  private readonly SCREENSHOT_NAME = 'image.png';

  screenshotInProgress = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly canvasBaseService: CanvasBaseService,
    @Inject(DOCUMENT)
    private readonly document: Document,
    @Inject(WINDOW)
    public readonly window: Window,
    private readonly translocoService: TranslocoService,
    @Inject(PIXI_APP)
    private readonly pixiApp: PixiApplication,
    @Inject(PIXI_VIEWPORT)
    private readonly viewport: Viewport,
  ) {
    this.watermarkPath = `assets/img/${this.translocoService.getActiveLang()}/watermark.svg`;
  }

  /**
   * Основной метод для создания скриншота.
   * Загружает водяной знак, если он еще не был загружен, и вызывает метод создания скриншота.
   */
  async take(): Promise<void> {
    this.screenshotInProgress.next(true);

    await Assets.load(this.watermarkPath);
    await this.createWatermarkAndScreenshot();
  }

  /**
   * Создает водяной знак и скриншот.
   * Подготавливает сцену, создает текстуры, сохраняет скриншот и возвращает сцену в исходное состояние.
   */
  private async createWatermarkAndScreenshot(): Promise<void> {
    const cachedScale = this.viewport.scaled;
    const hiddenElements = this.viewport.children.filter((child) => !child.visible);

    this.prepareForScreenshot(cachedScale, hiddenElements);

    const watermark = this.createWatermarkSprite();
    this.viewport.addChild(watermark);
    this.positionWatermark(watermark);

    const renderTexture = this.pixiApp.renderer.generateTexture(this.viewport);
    const rect = this.createRectangle(renderTexture);

    await this.saveScreenshot(rect);

    this.resetAfterScreenshot(cachedScale, hiddenElements);

    this.viewport.removeChild(watermark);
    rect.destroy();
    renderTexture.destroy();
  }

  /**
   * Создает спрайт водяного знака из ресурса.
   *
   * @returns {Sprite} Спрайт водяного знака.
   */
  private createWatermarkSprite(): Sprite {
    return new Sprite(Assets.get(this.watermarkPath));
  }

  /**
   * Создание прямоугольника со скриншотом с дополнительными отступами
   *
   * @param renderTexture - Текстура, из которой создается скриншот.
   * @private
   */
  private createRectangle(renderTexture: RenderTexture): Graphics {
    const sprite = new Sprite(renderTexture);

    return CanvasRenderService.renderElementsInRectangle([sprite], {
      backgroundColor: HEX_COLOR.GRAY_900,
      borderRadius: 20,
      padding: {
        x: this.PADDING,
        y: this.PADDING,
      },
    });
  }

  /**
   * Позиционирует водяной знак в правом нижнем углу сцены.
   *
   * @param watermark - Спрайт водяного знака.
   */
  private positionWatermark(watermark: Sprite): void {
    const sceneBounds = this.viewport.getLocalBounds();
    const xCoord = sceneBounds.x + sceneBounds.width - watermark.width;
    const yCoord = sceneBounds.y + sceneBounds.height + watermark.height + 40;

    watermark.position.set(xCoord, yCoord);
  }

  /**
   * Подготавливает сцену для создания скриншота.
   * Ограничивает FPS, устанавливает масштабирование в 1 и делает все элементы видимыми.
   *
   * @param {number} cachedScale - Текущее значение масштабирования.
   * @param {DisplayObject[]} hiddenElements - Скрытые элементы сцены.
   */
  private prepareForScreenshot(cachedScale: number, hiddenElements: DisplayObject[]): void {
    this.pixiApp.ticker.maxFPS = 1;
    if (cachedScale !== 1) {
      this.canvasBaseService.zoomSet(100, true);
    }
    hiddenElements.forEach((el) => (el.visible = true));
  }

  /**
   * Сохраняет скриншот в виде файла PNG.
   *
   * @param renderTexture - Текстура, из которой создается скриншот.
   */
  private async saveScreenshot(renderTexture: Graphics): Promise<void> {
    // Извлекаем изображение из текстуры
    const image = await this.pixiApp.renderer.extract.image(renderTexture);

    // Создаем ссылку для скачивания
    const a = this.document.createElement('a');
    this.document.body.append(a);
    a.download = this.SCREENSHOT_NAME;
    a.href = image.src;
    a.click();
    a.remove();
  }

  /**
   * Возвращает сцену в исходное состояние после создания скриншота.
   * Сбрасывает положение сцены, FPS, видимость элементов и масштабирование.
   *
   * @param cachedScale - Исходное значение масштабирования.
   * @param hiddenElements - Элементы, которые были скрыты до создания скриншота.
   */
  private resetAfterScreenshot(cachedScale: number, hiddenElements: DisplayObject[]): void {
    this.pixiApp.stage.x = 0;
    this.pixiApp.stage.y = 0;
    this.pixiApp.ticker.maxFPS = 0;
    hiddenElements.forEach((el) => (el.visible = false));
    if (cachedScale !== 1) {
      this.canvasBaseService.zoomSet(cachedScale * 100);
    }

    this.screenshotInProgress.next(false);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { truncate } from 'lodash-es';

import { App } from '@http/app/app.model';
import { EMAIL_TYPES, MESSAGE_PART_TYPES } from '@http/message-part/message-part.constants';
import { AddDoctypePipe } from '@panel/app/shared/pipes/add-doctype/add-doctype.pipe';
import { AddTargetBlankPipe } from '@panel/app/shared/pipes/add-target-blank/add-target-blank.pipe';
import { RemoveHrefPipe } from '@panel/app/shared/pipes/remove-href/remove-href.pipe';
import { RemoveHtmlTagsPipe } from '@panel/app/shared/pipes/remove-html-tags/remove-html-tags.pipe';
import { RemoveJinjaPipe } from '@panel/app/shared/pipes/remove-jinja/remove-jinja.pipe';

@Component({
  selector: 'cq-message-preview',
  templateUrl: './message-preview.component.html',
  styleUrls: ['./message-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RemoveJinjaPipe, AddTargetBlankPipe, AddDoctypePipe, RemoveHrefPipe, RemoveHtmlTagsPipe],
})
export class MessagePreviewComponent {
  @Input({ required: true })
  app!: App;

  @Input({ required: true })
  content!: string;

  @Input()
  inWrapper: boolean = true;

  @Input({ required: true })
  language!: string;

  @Input({ required: true })
  messagePartType!: MESSAGE_PART_TYPES;

  @Input()
  sender: any;

  protected MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;

  constructor(
    protected readonly addDoctypePipe: AddDoctypePipe,
    protected readonly addTargetBlankPipe: AddTargetBlankPipe,
    protected readonly removeHrefPipe: RemoveHrefPipe,
    protected readonly removeHtmlTagsPipe: RemoveHtmlTagsPipe,
    protected readonly removeJinjaPipe: RemoveJinjaPipe,
    protected readonly translocoService: TranslocoService,
  ) {}

  /**
   * Подготовка контента сообщения в зависимости от его типа
   *
   * @param messagePartType Тип сообщения
   * @param messageContent Контент сообщения
   */
  prepareMessagePartContent(messagePartType: MESSAGE_PART_TYPES, messageContent: string): any {
    let preparedMessageContent;

    // в чате не может быть html-тегов
    if (~[MESSAGE_PART_TYPES.POPUP_CHAT, MESSAGE_PART_TYPES.SDK_POPUP_CHAT].indexOf(messagePartType)) {
      preparedMessageContent = messageContent;
      preparedMessageContent = this.removeJinjaPipe.transform(preparedMessageContent);

      if (messagePartType === MESSAGE_PART_TYPES.SDK_POPUP_CHAT && preparedMessageContent) {
        preparedMessageContent = this.removeHtmlTagsPipe.transform(preparedMessageContent);
        preparedMessageContent = truncate(preparedMessageContent, { length: 150 });
      }
    }

    // к кастомным email'ам, которые создаёт пользователь принудительно добавляем doctype для корректной работы JS
    if (
      ~[MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.HTML, MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.BEE].indexOf(
        messagePartType,
      )
    ) {
      preparedMessageContent = messageContent || '<br><br><br><br>';
      preparedMessageContent = this.removeJinjaPipe.transform(preparedMessageContent);
      preparedMessageContent = this.addDoctypePipe.transform(preparedMessageContent);
    }

    // везде, где можно вставить html, нужно принудительно добавить всем ссылкам target="_blank", чтобы их нельзя было открыть внутри iframe
    if (
      ~[
        MESSAGE_PART_TYPES.POPUP_SMALL,
        MESSAGE_PART_TYPES.POPUP_BIG,
        MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.DEFAULT,
        MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.HTML,
        MESSAGE_PART_TYPES.EMAIL + '_' + EMAIL_TYPES.BEE,
      ].indexOf(messagePartType)
    ) {
      preparedMessageContent = messageContent || '<br><br><br><br>';
      preparedMessageContent = this.removeJinjaPipe.transform(preparedMessageContent);
      preparedMessageContent = this.addTargetBlankPipe.transform(preparedMessageContent);
    }

    // веб-пуши поддерживают только jinja
    if (~[MESSAGE_PART_TYPES.PUSH, MESSAGE_PART_TYPES.SDK_PUSH].indexOf(messagePartType)) {
      preparedMessageContent = messageContent;
      preparedMessageContent = this.removeJinjaPipe.transform(preparedMessageContent);
    }

    // телеграм обрабатывается по другому
    if (~[MESSAGE_PART_TYPES.TELEGRAM].indexOf(messagePartType)) {
      preparedMessageContent = this.prepareMessagePartForTelegram(messageContent);
    }

    return preparedMessageContent;
  }

  /**
   * Подготовка контента сообщения в для Telegram
   *
   * @param tgContent Контент Telegram
   */
  prepareMessagePartForTelegram(tgContent: any): any {
    const res: any = {
      buttons: [],
      contents: [],
    };

    for (let button of tgContent.buttons) {
      // &shy; надо чтобы кнопки не скукоживались по высоте при отсутстви�� текста
      res.buttons.push(button.text || '&shy;');
    }

    for (let content of tgContent.contents) {
      let attach = null;
      if (content.attachment) {
        attach = {
          filename: content.attachment.filename || content.attachment.name,
          mimeType: content.attachment.mimeType || content.attachment.type,
          size: content.attachment.size,
          url: content.attachment.url,
          isImage: (content.attachment.mimeType || content.attachment.type).includes('image'),
          extension: getFileExtension(content.attachment.filename || content.attachment.name),
        };
      }
      res.contents.push({
        type: content.type,
        // &shy; надо чтобы блок не скукоживался по высоте при отсутствии текста
        value: this.removeHrefPipe.transform(content.value || '&shy;'),
        attachment: attach,
      });
    }

    return res;

    function getFileExtension(filename: any): any {
      // Убедимся, что имя файла содержит точку (.) и это не начало строки
      if (filename.includes('.') && !filename.startsWith('.')) {
        return filename.split('.').pop();
      }

      return '';
    }
  }
}

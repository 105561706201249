/**
 * Контроллер блока со сворачивающимся виедо
 */
(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventVideoController', CqAutoEventVideoController);

  function CqAutoEventVideoController() {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.isCollapsed = angular.isDefined(vm.initialCollapse) ? vm.initialCollapse : true;
    }
  }
})();

/**
 * Директива для валидации даты в формате moment
 */
(function () {
  'use strict';

  angular.module('myApp.directives.time').directive('cqMinTime', cqMinTime);

  function cqMinTime() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var minTime;

      scope.$watch(getEvaluatedMinTimeValue, watcher);
      controller.$validators.minTime = minTimeValidator;

      /**
       * Получение объекта moment из атрибута cq-min-time
       *
       * @returns {moment|undefined}
       */
      function getEvaluatedMinTimeValue() {
        return scope.$eval(iAttrs.cqMinTime);
      }

      /**
       * Проверка равенства значений модели и cqEqual
       * NOTE: считается, что в ngModel и cqMinTime передан объект moment, поэтому преобразований внутри валидатора не выполняется. Это сделано для того, чтобы валидатор отрабатывал максимально быстро, не тратя время на создание объектов moment
       *
       * @param modelValue
       * @return {Boolean}
       */
      function minTimeValidator(modelValue) {
        var parsedModelValue = modelValue && modelValue.hasOwnProperty('startDate') ? modelValue.startDate : modelValue;

        if (controller.$isEmpty(parsedModelValue) || !minTime) {
          return true;
        } else {
          return parsedModelValue.isAfter(minTime);
        }
      }

      /**
       * Вызывается при изменении moment в атрибуте cq-min-time
       *
       * @param {String} newValue Значение атрибута minHeight
       */
      function watcher(newValue) {
        minTime = newValue;

        // нужно вызывать валидацию принудительно, т.к. $validators могут отработать раньше $observe
        controller.$validate();
      }
    }
  }
})();

import { firstValueFrom } from 'rxjs';
import { MESSAGE_PART_TYPES } from '../../../app/http/message-part/message-part.constants';
import {
  CONVERSATION_PART_SENT_VIA,
  CONVERSATION_PART_TYPES,
} from '../../../app/http/conversation-part/conversation-part.constants';

(function () {
  'use strict';

  angular
    .module('myApp.conversationInHistory')
    .controller('CqConversationInHistoryController', CqConversationInHistoryController);

  function CqConversationInHistoryController(
    $filter,
    $state,
    $translate,
    moment,
    conversationPartModel,
    messageModel,
    timeUnitService,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.conversationParts = [];
      vm.conversationPartsRequestPerforming = false;
      vm.dateRange = timeUnitService.formatDateRange(
        vm.conversation.created,
        vm.conversation.important_part_last.created,
      );
      vm.getConversationUrl = getConversationUrl;

      // Данные для ngVue чтобы он не ругался на отсутствующие параметры
      vm.initialData = {
        approveBotTip: () => {},
        cancelBotTip: () => {},
        editBotTip: () => {},
        editMessage: () => {},
        onScrollBottom: () => {},
        onScrollTop: () => {},
        removeMessage: () => {},
        sendingFiles: [],
        states: {},
        typing: [],
      };
      vm.openSelectedConversation = openSelectedConversation;

      getConversationParts();
    }

    /**
     * Перейти к выбранному диалогу,
     * если вызов с другой страницы - перенаправить на конкретный диалог в разделе Диалоги
     * если вызов внутри модального окна - заркрываем его
     */
    function openSelectedConversation(conversation) {
      if (vm.onRedirectConversationClick) {
        $state.go('app.content.conversations.detail', { conversationId: conversation.id });
      } else {
        vm.onOpenConversationClick(conversation);
      }

      vm.onModalClose && vm.onModalClose();
    }

    /**
     * Получение сообщений диалога
     */
    function getConversationParts() {
      vm.conversationPartsRequestPerforming = true;

      // получаются только реплики пользователя, оператора и заметки, в количестве трёх штук
      firstValueFrom(
        conversationPartModel.getList(
          vm.conversation.id,
          [
            CONVERSATION_PART_TYPES.AUTO_REPLY,
            CONVERSATION_PART_TYPES.NOTE,
            CONVERSATION_PART_TYPES.REPLY_ADMIN,
            CONVERSATION_PART_TYPES.REPLY_USER,
            CONVERSATION_PART_TYPES.CHAT_BOT_ADMIN,
            CONVERSATION_PART_TYPES.CHAT_BOT_USER,
          ],
          vm.paginationParams ?? {
            paginateCount: 3,
          },
        ),
      )
        .then(getConversationPartsSuccess)
        .finally(getConversationPartsFinally);

      function getConversationPartsFinally() {
        vm.conversationPartsRequestPerforming = false;
      }

      function getConversationPartsSuccess(conversationParts) {
        vm.conversationParts = conversationParts.parts;

        setConversationPartMessageTitle(vm.conversation, vm.conversationParts);
      }
    }

    /**
     * Добавляет в реплику с заглушкой для поп-апов и емейлов дополнительное поле, которое сордержит в себе текст, отображаемый в самой заглушке
     * Например:
     * Это сообщение содержит письмо: ТУТ ТЕМА ПИСЬМА
     * Это сообщение содержит поп-ап: ТУТ НАЗВАНИЕ АВТОСООБЩЕНИЯ
     *
     * @param {Object} activeConversation — Текущий диалог
     * @param {Object} conversationParts — Реплики диалога
     */
    function setConversationPartMessageTitle(activeConversation, conversationParts) {
      var firstAdminReply = $filter('filter')(conversationParts, {
        first: true,
        type: CONVERSATION_PART_TYPES.REPLY_ADMIN,
      })[0];

      if (
        firstAdminReply &&
        !!~[
          MESSAGE_PART_TYPES.BLOCK_POPUP_BIG,
          MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL,
          MESSAGE_PART_TYPES.EMAIL,
          MESSAGE_PART_TYPES.POPUP_BIG,
          MESSAGE_PART_TYPES.POPUP_SMALL,
          MESSAGE_PART_TYPES.TELEGRAM,
        ].indexOf(activeConversation.type)
      ) {
        if (firstAdminReply.sent_via === CONVERSATION_PART_SENT_VIA.MESSAGE_AUTO) {
          firstValueFrom(messageModel.getAutoMessage(activeConversation.message, true, true, true))
            .then(getAutoMessageSuccess)
            .finally(getMessageFinally);
        } else if (firstAdminReply.sent_via === CONVERSATION_PART_SENT_VIA.MESSAGE_MANUAL) {
          firstValueFrom(messageModel.getManualMessage(activeConversation.message))
            .then(getManualMessageSuccess)
            .finally(getMessageFinally);
        }
      }

      function getAutoMessageSuccess(message) {
        firstAdminReply.messageTitle = message.name;
      }

      function getManualMessageSuccess(message) {
        if (~[MESSAGE_PART_TYPES.EMAIL].indexOf(message.type)) {
          firstAdminReply.messageTitle = $filter('limitTo')(message.subject, 100);
        } else if (~[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].indexOf(message.type)) {
          firstAdminReply.messageTitle = 'ID ' + message.id;
        } else if (message.type === MESSAGE_PART_TYPES.TELEGRAM) {
          const bodyJson = JSON.parse(message.bodyJson);

          let firstTextContent = null;

          for (let i = 0; i < bodyJson.contents.length; i++) {
            let content = bodyJson.contents[i];
            if (content.type === 'text') {
              firstTextContent = content;
              break;
            }
          }

          if (firstTextContent) {
            firstAdminReply.messageTitle = $filter('removeHtmlTags')(firstTextContent.value);
            firstAdminReply.messageTitle = $filter('limitTo')(firstAdminReply.messageTitle, 100);
          } else {
            firstAdminReply.messageTitle = $translate.instant('models.messagePart.customName.telegram');
          }
        } else {
          firstAdminReply.messageTitle = $filter('removeHtmlTags')(message.body);
          firstAdminReply.messageTitle = $filter('limitTo')(firstAdminReply.messageTitle, 100);
        }
      }

      function getMessageFinally() {
        // HACK Этой строчкой кода я заставляю Vue заново выполнить рендер тела диалога, иначе messageTitle не обновляется
        firstAdminReply.created = moment(firstAdminReply.created).add(0, 'seconds');
      }
    }

    /**
     * Получение ссылки на диалог по его ID
     *
     * @param {String} conversationId ID диалога
     */
    function getConversationUrl(conversationId) {
      return $state.href(
        'app.content.conversations.detail',
        { conversationId: vm.conversation.id },
        { absolute: true },
      );
    }
  }
})();

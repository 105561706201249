(function () {
  'use strict';

  angular.module('myApp.serviceSection', []);

  require('./service-section.routes');
  require('./visual-components/visual-components.controller');
  require('./visual-components/visual-components.html');
  require('./visual-components/steps/step-1.html');
  require('./visual-components/steps/step-2.html');
  require('./visual-components/steps/step-3.html');
  require('./visual-components/steps/step-4.html');
  require('./visual-components/steps/step-5.html');
  require('./visual-components/steps/step-6.html');
  require('./visual-components/templates/dropdown.html');
  require('./visual-components/templates/popover.html');
  require('./visual-components/templates/tooltip.html');
  require('./visual-components/views/accordion.html');
  require('./visual-components/views/alerts.html');
  require('./visual-components/views/badges.html');
  require('./visual-components/views/breadcrumbs.html');
  require('./visual-components/views/buttons.html');
  require('./visual-components/views/cards.html');
  require('./visual-components/views/collapse.html');
  require('./visual-components/views/date-range-picker.html');
  require('./visual-components/views/dropdowns.html');
  require('./visual-components/views/emoji-selector.html');
  require('./visual-components/views/forms.html');
  require('./visual-components/views/grid.html');
  require('./visual-components/views/icons.html');
  require('./visual-components/views/modals.html');
  require('./visual-components/views/navs.html');
  require('./visual-components/views/popovers.html');
  require('./visual-components/views/progress-bars.html');
  require('./visual-components/views/statistics-value.html');
  require('./visual-components/views/sticky-scroll.html');
  require('./visual-components/views/switches.html');
  require('./visual-components/views/tables.html');
  require('./visual-components/views/toasts.html');
  require('./visual-components/views/tooltips.html');
  require('./visual-components/views/typography.html');
  require('./visual-components/views/ui-select.html');
  require('./visual-components/views/user-tag.html');
  require('./visual-components/views/wizard.html');
  require('./visual-components/views/xss-test.html');
  require('./visual-components/views/zero-data.html');
})();

<ng-container *transloco="let t">
  <div class="row margin-bottom-30">
    <div class="col-12 col-xl-6">
      <div [innerHTML]="t('dataCollectionEcommerceComponent.description')"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <h4>
        <a
          [href]="t('dataCollectionEcommerceComponent.dataViaWebApi.headingLink.href')"
          target="_blank"
          >{{ t('dataCollectionEcommerceComponent.dataViaWebApi.headingLink.text') }}</a
        >
      </h4>
      <div
        class="margin-bottom-15"
        [innerHTML]="t('dataCollectionEcommerceComponent.dataViaWebApi.description')"
      ></div>
      <ol class="list-round">
        <li>
          <a
            [href]="t('dataCollectionEcommerceComponent.dataViaWebApi.linkList.viewingProductLink.href')"
            target="_blank"
            >{{ t('dataCollectionEcommerceComponent.dataViaWebApi.linkList.viewingProductLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionEcommerceComponent.dataViaWebApi.linkList.addProductInCartLink.href')"
            target="_blank"
            >{{ t('dataCollectionEcommerceComponent.dataViaWebApi.linkList.addProductInCartLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionEcommerceComponent.dataViaWebApi.linkList.payingOrderLink.href')"
            target="_blank"
            >{{ t('dataCollectionEcommerceComponent.dataViaWebApi.linkList.payingOrderLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionEcommerceComponent.dataViaWebApi.linkList.orderingLink.href')"
            target="_blank"
            >{{ t('dataCollectionEcommerceComponent.dataViaWebApi.linkList.orderingLink.text') }}</a
          >
        </li>
      </ol>
    </div>

    <div class="col-6">
      <h4>
        <a
          [href]="t('dataCollectionEcommerceComponent.trackMaster.headingLink.href')"
          target="_blank"
          >{{ t('dataCollectionEcommerceComponent.trackMaster.headingLink.text') }}</a
        >
      </h4>
      <div
        class="margin-bottom-15"
        [innerHTML]="t('dataCollectionEcommerceComponent.trackMaster.description')"
      ></div>
      <ol class="list-round margin-bottom-20">
        <li>
          <a
            [href]="t('dataCollectionEcommerceComponent.trackMaster.linkList.viewingAndAddProductInCartLink.href')"
            target="_blank"
            >{{ t('dataCollectionEcommerceComponent.trackMaster.linkList.viewingAndAddProductInCartLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionEcommerceComponent.trackMaster.linkList.inCartPageLink.href')"
            target="_blank"
            >{{ t('dataCollectionEcommerceComponent.trackMaster.linkList.inCartPageLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionEcommerceComponent.trackMaster.linkList.orderingLink.href')"
            target="_blank"
            >{{ t('dataCollectionEcommerceComponent.trackMaster.linkList.orderingLink.text') }}</a
          >
        </li>
      </ol>
    </div>
  </div>
</ng-container>

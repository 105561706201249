import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { IntegrationSelectComponent } from '@panel/app/partials/integration-select/integration-select.component';
import { DirectivesModule } from '@panel/app/shared/directives/directives.module';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [IntegrationSelectComponent],
  exports: [IntegrationSelectComponent],
  imports: [CommonModule, NgSelectModule, TranslocoModule, ReactiveFormsModule, DirectivesModule, SharedModule],
  providers: [],
})
export class IntegrationSelectModule {}

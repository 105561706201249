/**
 * Директива для редактирования вида и содержания сообщения
 */
(function () {
  'use strict';

  angular.module('myApp.directives.listFilter').directive('cqListFilter', cqListFilter);

  function cqListFilter() {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        filter: '=', // строка поиска
        placeholder: '@?',
      },
      controller: angular.noop,
      controllerAs: 'vm',
      bindToController: true,
      template: require('./list-filter.html'),
    };
  }
})();

<div class="modal-header">
  <h3
    *ngIf="!isEdit"
    class="modal-title"
    data-test="cq-articles-category-editor-modal-create-heading"
  >
    {{ 'modals.articlesCategoryEditor.heading.create' | transloco }}
  </h3>
  <h3
    *ngIf="isEdit"
    class="modal-title"
  >
    {{ 'modals.articlesCategoryEditor.heading.rename' | transloco }}
  </h3>
  <button
    class="close"
    (click)="activeModal.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body">
  <form
    autocomplete="off"
    id="categoryForm"
    name="categoryForm"
    novalidate
    (ngSubmit)="confirm(categoryForm.valid)"
  >
    <!--НАЗВАНИЕ-->
    <div class="form-group row margin-bottom-30">
      <label class="col-3 col-form-label">{{
        'modals.articlesCategoryEditor.addArticlesCategoryForm.categoryNameInput.label' | transloco
      }}</label>
      <div class="col-9">
        <div class="position-relative">
          <input
            [formControl]="categoryForm.controls.categoryName"
            class="form-control"
            autofocus
            required
            cq-dynamic-validation="{apiMessageArticlesCategoryExistsError: apiMessageArticlesCategoryExistsError}"
            [placeholder]="
              'modals.articlesCategoryEditor.addArticlesCategoryForm.categoryNameInput.placeholder' | transloco
            "
            data-test="cq-articles-category-editor-modal-name-input"
          />
          <cq-validation-messages [control]="categoryForm.controls.categoryName">
            <cq-validation-message errorName="required">
              {{
                'modals.articlesCategoryEditor.addArticlesCategoryForm.categoryNameInput.errors.required' | transloco
              }}
            </cq-validation-message>
            <cq-validation-message errorName="maxlength">
              {{
                'modals.articlesCategoryEditor.addArticlesCategoryForm.categoryNameInput.errors.maxlength' | transloco
              }}
            </cq-validation-message>
            <cq-validation-message errorName="apiMessageArticlesCategoryExistsError">
              {{
                'modals.articlesCategoryEditor.addArticlesCategoryForm.categoryNameInput.errors.apiMessageArticlesCategoryExistsError'
                  | transloco
              }}
            </cq-validation-message>
          </cq-validation-messages>
        </div>
      </div>
    </div>

    <!--ИКОНКА-->
    <div class="form-group row no-margin-bottom">
      <label class="col-3 col-form-label">{{
        'modals.articlesCategoryEditor.addArticlesCategoryForm.changeIcon.input.label' | transloco
      }}</label>
      <div class="col-9">
        <div class="d-flex margin-between-cols-15">
          <div
            class="emoji-icon align-middle"
            [innerHTML]="newIcon.icon || modalWindowParams['category'].icon"
          ></div>
          <div class="d-flex align-items-center">
            <div
              class="margin-right-15"
              ngbDropdown
            >
              <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                (click)="trackClickOnChooseAnotherEmoji()"
                ngbDropdownToggle
              >
                {{
                  'modals.articlesCategoryEditor.addArticlesCategoryForm.changeIcon.input.changeIconButton' | transloco
                }}
              </button>
              <div ngbDropdownMenu>
                <cq-emoji-selector (onEmojiSelect)="selectEmoji($event)"></cq-emoji-selector>
              </div>
            </div>
            <button
              *ngIf="newIcon.icon !== DEFAULT_EMOJI"
              class="btn btn-sm btn-borderless-primary"
              type="button"
              (click)="setDefaultIcon()"
            >
              {{ 'modals.articlesCategoryEditor.addArticlesCategoryForm.changeIcon.setDefaultButton' | transloco }}
            </button>
          </div>
        </div>
        <small class="form-text">{{
          'modals.articlesCategoryEditor.addArticlesCategoryForm.changeIcon.help' | transloco
        }}</small>
      </div>
    </div>
    <input
      id="submit"
      class="hidden"
      type="submit"
    />
  </form>
</div>
<div class="modal-footer">
  <label
    for="submit"
    class="btn btn-primary"
    [ngClass]="{ disabled: (isApiRequestPerformed | async) === true || !isDataChanged() }"
    data-test="cq-articles-category-editor-modal-create-button"
  >
    {{
      !isEdit
        ? ('modals.articlesCategoryEditor.createButton' | transloco)
        : ('modals.articlesCategoryEditor.renameButton' | transloco)
    }}
  </label>
</div>

import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';

/**
 * Типы агрегации статистики по каналам
 */
export enum CHANNEL_STATISTICS_AGGREGATIONS {
  AVG = 'AVG', // среднее значение (например, для времени ответа или размера диалога)
  MAX = 'MAX', // максимальное значение
  MIN = 'MIN', // минимальное значение
}

/**
 * Возможные кодировки экспортированных файлов
 */
export enum CHANNEL_STATISTICS_EXPORT_ENCODINGS {
  UTF_8 = 'utf-8',
  UTF_8_SIG = 'utf-8-sig',
  WINDOWS_1251 = 'windows-1251',
}

/**
 * Параметры, по которым можно получать статистику по каналам
 */
export enum CHANNEL_STATISTICS_TYPES {
  ANSWER_SPEED = 'answer_speed', // Скорость ответа (без учета рабочего/нерабочего времени)
  ANSWER_SPEED_NOT_WORKING_TIME = 'answer_speed_not_working_time', // Скорость ответа в нерабочее время
  ANSWER_SPEED_WORKING_TIME = 'answer_speed_working_time', // Скорость ответа в рабочее время
  CLOSED = 'closed', // Количество закрытых диалогов
  CLOSED_BY_USER = 'closed_by_user', // Количество пользователей с закрытыми диалогами
  DIALOG_SIZE = 'dialog_size', // Длина диалога
  LOST_CUSTOMERS = 'lost_customers', // Количество упущенных диалогов
  NOT_ANSWERED = 'not_answered', // Количество неотвеченных диалогов
  OPENED = 'opened', // Количество новых диалогов
  OPENED_BY_USER = 'opened_by_user', // Количество задавших вопрос
}

/**
 * Типы каналов
 */
export enum CHANNEL_TYPES {
  EMAIL = 'email', // Почтовый канал
  FACEBOOK = 'facebook', // Facebook
  INSTAGRAM = 'instagram', // Instagram
  MANUAL = 'manual', // Свой канал
  TELEGRAM = 'telegram', // Telegram
  VIBER = 'viber', // Viber
  VK = 'vk', // ВКонтакте
  WHATS_APP = 'whatsapp', // WhatsApp
  YANDEX_DIALOGS = 'yandex_dialogs', // Яндекс.Диалоги
  MOBILE = 'mobile', // мобилки: android и ios
}

/**
 * Упорядоченный массив типов каналов
 */
export const CHANNEL_TYPES_ARRAY = [
  CHANNEL_TYPES.MANUAL,
  CHANNEL_TYPES.EMAIL,
  CHANNEL_TYPES.VIBER,
  CHANNEL_TYPES.WHATS_APP,
  CHANNEL_TYPES.VK,
  CHANNEL_TYPES.TELEGRAM,
  CHANNEL_TYPES.FACEBOOK,
  CHANNEL_TYPES.INSTAGRAM,
  CHANNEL_TYPES.MOBILE,
];

/**
 * Каналы с таймером ответа в интеграцию и время ответа в часах
 */
export const CHANNELS_WITH_INTEGRATION_REPLY_TIMER = {
  [INTEGRATION_TYPES.WHATS_APP_EDNA]: 24,
};

/**
 * Получение типов псевдоканалов
 */
export enum PSEUDO_CHANNEL_TYPES {
  ALL_CHANNELS = 'allChannels', // псевдоканал "Все каналы"
  WITHOUT_CHANNEL = 'withoutChannel', // псевдоканал "Без канала"
}

/**
 * Псевдоканалы и соответствующие им ID
 */
export const PSEUDO_CHANNEL_IDS = {
  // было принято решение использовать в качестве идентификатора псевдоканала "Все каналы" null,
  // т.к. null никогда не отсылается на сервер, а если ID канала не пришёл на сервер - он вернёт вообще все диалоги
  [PSEUDO_CHANNEL_TYPES.ALL_CHANNELS]: null,
  // на бэкэнде если диалог находится в канале с ID === '0',
  // значит на самом деле он не привязан к каналу
  [PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL]: '0',
};

export const REQUIRED_INTEGRATIONS_FOR_CHANNELS = {
  [CHANNEL_TYPES.FACEBOOK]: INTEGRATION_TYPES.FACEBOOK,
  [CHANNEL_TYPES.INSTAGRAM]: INTEGRATION_TYPES.INSTAGRAM,
  [CHANNEL_TYPES.TELEGRAM]: INTEGRATION_TYPES.TELEGRAM,
  [CHANNEL_TYPES.VIBER]: INTEGRATION_TYPES.VIBER,
  [CHANNEL_TYPES.WHATS_APP]: INTEGRATION_TYPES.WHATS_APP_EDNA,
  [CHANNEL_TYPES.VK]: INTEGRATION_TYPES.VK,
  [CHANNEL_TYPES.YANDEX_DIALOGS]: INTEGRATION_TYPES.YANDEX_DIALOGS,
};

/**
 * Системные каналы (каждый системный канал можно создать только 1 раз)
 */
export const SYSTEM_CHANNEL_TYPES = [CHANNEL_TYPES.VIBER];

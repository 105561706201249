<ng-container *transloco="let t">
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="collapse.toggle()"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
  >
    <h4 class="no-margin">{{ t('emailNotificationChatBotsListTriggers.heading') }}</h4>
    <i
      class="cqi-sm ml-auto"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>
  <div
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="padding-top-15">
      <ng-container *ngIf="!isShowedFromModal; else createChatBotNote">
        <ng-container *ngIf="chatBots.length > 0; else zeroData">
          <div class="margin-bottom-15">{{ t('emailNotificationChatBotsListTriggers.description') }}</div>
          <div class="chat-bots-list margin-bottom-20 margin-between-rows-10">
            <div *ngFor="let chatBot of chatBots">
              <span class="font-weight-bold">{{ chatBot.name }}</span>
              <div>
                {{
                  t('emailNotificationChatBotsListTriggers.blocks', {
                    blocks: getBranchesWithEmailNotificationAction(chatBot).length
                  })
                }}
                {{ getFormattedChatBotBranchesNames(chatBot) }}
              </div>
            </div>
          </div>
          <div class="text-secondary">
            <div class="d-flex align-items-baseline">
              <i class="cqi-sm cqi-lightbulb margin-right-5"></i>
              <div>
                <span>{{ t('emailNotificationChatBotsListTriggers.note') }}</span>
                <a uiSref="app.content.messagesAjs.chatBot.list">{{
                  t('emailNotificationChatBotsListTriggers.goToChatBotsListLink')
                }}</a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #zeroData>
          <div class="margin-bottom-20">{{ t('emailNotificationChatBotsListTriggers.zeroData.text') }}</div>
          <a
            class="btn btn-secondary"
            uiSref="app.content.messagesAjs.chatBot.list"
            >{{ t('emailNotificationChatBotsListTriggers.zeroData.goToChatBotsListButton') }}</a
          >
        </ng-template>
      </ng-container>
      <ng-template #createChatBotNote>
        <ngb-alert
          [dismissible]="false"
          type="secondary"
        >
          <div class="d-flex align-items-baseline">
            <i class="cqi-sm cqi-lightbulb margin-right-5"></i>
            <div>{{ t('emailNotificationChatBotsListTriggers.chatBotNote') }}</div>
          </div>
        </ngb-alert>
      </ng-template>
    </div>
  </div>
</ng-container>

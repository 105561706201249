import { SENDING_TYPES } from '../../../../app/http/message/message.constants';
import { firstValueFrom } from 'rxjs';

import {
  EMAIL_TYPES,
  MESSAGE_PART_TYPES,
  POPUP_CHAT_TYPES,
  TYPE_OS_PREVIEW,
} from '../../../../app/http/message-part/message-part.constants';
import { FEATURES } from '../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular
    .module('myApp.directives.abTestStatistics')
    .controller('CqAbTestStatisticsController', CqAbTestStatisticsController);

  function CqAbTestStatisticsController(
    $scope,
    $filter,
    $q,
    $translate,
    $uibModal,
    moment,
    carrotquestHelper,
    caseStyleHelper,
    chartHelper,
    dateRangePickerHelper,
    featureModel,
    messagePartModel,
    messageTestGroupModel,
  ) {
    var vm = this;

    var CAMELED_SENDING_TYPES = caseStyleHelper.valuesToCamelCase(angular.copy(SENDING_TYPES)); // т.к. данные статистики в camelCase - чтобы их корректно получать надо типы действий получателей сообщения тоже сделать перевести в camelCase
    var CONTROL_GROUP_SENDING_TYPE_COLORS = getControlGroupSendingTypeColors(CAMELED_SENDING_TYPES); // цвета для датасетов по контрольной группе
    var DATASET_TYPES = {
      // типы датасетов для графиков
      GENERAL: 'general',
      CONTROL_GROUP: 'controlGroup',
    };
    var MESSAGE_PART_SENDING_TYPE_COLORS = getMessagePartSendingTypeColors(CAMELED_SENDING_TYPES); // цвета для датасетов по вариантам сообщения

    vm.$onInit = init;

    function init() {
      vm.CAMELED_SENDING_TYPES = CAMELED_SENDING_TYPES;
      vm.caseStyleHelper = caseStyleHelper;
      vm.chartHelper = chartHelper;
      vm.completeAbTest = completeAbTest;
      vm.CONTROL_GROUP_SENDING_TYPE_COLORS = CONTROL_GROUP_SENDING_TYPE_COLORS;
      vm.controlGroup = null; // контрольная группа
      vm.DATASET_TYPES = DATASET_TYPES;
      vm.EMAIL_TYPES = EMAIL_TYPES;
      vm.featureModel = featureModel;
      vm.FEATURES = FEATURES;
      vm.getBlockPopupCount = getBlockPopupCount;
      vm.init = init;
      vm.messageParts = []; // варианты сообщения в тест-группе
      vm.MESSAGE_PART_TYPES = MESSAGE_PART_TYPES;
      vm.MESSAGE_PART_SENDING_TYPE_COLORS = MESSAGE_PART_SENDING_TYPE_COLORS;
      vm.POPUP_CHAT_TYPES = POPUP_CHAT_TYPES;
      vm.SENDING_TYPES = SENDING_TYPES;
      vm.showSendings = showSendings;
      vm.statisticsChart = {
        // график для вывода статистики
        type: 'line', // тип графика
        instance: null, // текущий инстанс графика
        controlGroupDatasetsShown: [
          // типы датасетов по контрольной группе, которые показываются на графике в текущий момент
          // @formatter:off
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.GOAL_COMPLETED,
          // @formatter:on
        ],
        controlGroupDatasetsToShow: [
          // типы датасетов по контрольной группе, которые могут показываться на графике (из них строятся чекбоксы)
          // @formatter:off
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.GOAL_COMPLETED,
          // @formatter:on
        ],
        datasetsShown: [
          // типы датасетов по вариантам сообщения, которые показываются на графике в текущий момент
          // @formatter:off
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.GOAL_COMPLETED,
          // @formatter:on
        ],
        datasetsToShow: [
          // типы датасетов по вариантам сообщения, которые могут показываться на графике (из них строятся чекбоксы)
          // @formatter:off
          CAMELED_SENDING_TYPES.SENDED,
          CAMELED_SENDING_TYPES.READ,
          CAMELED_SENDING_TYPES.REPLIED,
          CAMELED_SENDING_TYPES.CLICKED,
          CAMELED_SENDING_TYPES.GOAL_COMPLETED,
          // @formatter:on
        ],
        defaultDataset: {
          // датасет по умолчанию, на основе которого создаются все датасеты для графика
          datasetSendingType: CAMELED_SENDING_TYPES.SENDED, // тип датасета по действию получателей сообщения
          datasetType: DATASET_TYPES.GENERAL, // тип датасета по типу варианта сообщения
          data: [],
          fill: false,
          hidden: false,
          label: '',
          lineTension: 0,
          messagePartName: '', // имя варианта сообщения (вдобавок к datasetSendingType и datasetType для отличия одного варианта сообщения от другого)
          sum: 0,
        },
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          elements: {
            line: {
              borderWidth: 2,
            },
          },
          layout: {
            padding: {
              top: 20,
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkipPadding: 10,
                  maxRotation: 0,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: chartHelper.removeDecimalLabels,
                },
              },
            ],
          },
          tooltips: {
            position: 'nearest',
          },
          zeroData: {
            text: $translate.instant('directives.abTestStatistics.zeroData.defaultDataset'),
          },
        },
      };
      vm.statisticsRange = {
        dates: {
          // период отображения статистики (вычисляется в init())
          startDate: null,
          endDate: null,
        },
        description: '', // текстовое представление периода
        options: angular.extend(dateRangePickerHelper.getOptions(), {
          // опции для датапикера
          opens: 'left',
          dateLimit: {
            quarters: 1,
          },
          maxDate: vm.testGroup.closed ? moment(vm.testGroup.closed).endOf('day') : moment().endOf('day'),
          minDate: moment(vm.testGroup.created).startOf('day'),
        }),
      };
      vm.TYPE_OS_PREVIEW = TYPE_OS_PREVIEW;
      vm.toggleDatasetsToShow = toggleDatasetsToShow;
      vm.trackClickShowSendings = trackClickShowSendings;

      // если тест-группа завершена - выбираем дату начала и дату закрытия как начальную и конечную даты вывода статистики
      if (vm.testGroup.closed) {
        vm.statisticsRange.dates.startDate = moment(vm.testGroup.created).startOf('day');
        vm.statisticsRange.dates.endDate = moment(vm.testGroup.closed).endOf('day');
      } else {
        vm.statisticsRange.dates.endDate = moment().endOf('day');
        // если тест-группа не завершена - надо отобразить статистику за последние 7 дней, учитывая, что АБ тест может длиться и меньше 7 дней
        if (moment().startOf('day').diff(moment(vm.testGroup.created).startOf('day'), 'days') > 6) {
          moment().subtract(6, 'days').startOf('day');
        } else {
          moment(vm.testGroup.created).startOf('day');
        }
      }

      vm.statisticsRange.options.parentEl =
        'cq-ab-test-statistics[directive-id="' + vm.elementId + '"] .datepicker-holder';

      // если у тест-группы уже получены варианты сообщения - надо их распарсить
      if (vm.testGroup.parts) {
        parseTestGroupParts(vm.testGroup.parts);

        // если у этих вариантов уже есть статистика - нужно отрисовать график
        if (vm.testGroup.parts[0].statistics) {
          setDatasets(vm.messageParts, vm.statisticsChart.datasetsToShow, vm.statisticsChart.datasetsShown);
          setDatasets(
            [vm.controlGroup],
            vm.statisticsChart.controlGroupDatasetsToShow,
            vm.statisticsChart.controlGroupDatasetsShown,
          );

          vm.statisticsChart.data.labels = vm.testGroup.parts[0].statistics[CAMELED_SENDING_TYPES.BOUNCED].labels;

          chartHelper.updateChart(vm.statisticsChart.instance);
        }
      }

      // если у тест-группы задан промежуток отображения статистики - надо сохранить этот промежуток
      if (vm.testGroup.statisticsRange && vm.testGroup.statisticsRange.dates) {
        angular.extend(vm.statisticsRange.dates, vm.testGroup.statisticsRange.dates);
      } else {
        vm.testGroup.statisticsRange = {};
      }

      angular.extend(vm.testGroup.statisticsRange, vm.statisticsRange);
      vm.testGroup.statisticsRange.description = generateStatisticsRangeDescription(
        vm.testGroup.statisticsRange.dates.startDate,
        vm.testGroup.statisticsRange.dates.endDate,
      );

      // статистика обновляется если изменён период отображения
      $scope.$watchCollection('vm.testGroup.statisticsRange.dates', function (newValue, oldValue) {
        vm.testGroup.statisticsRange.description = generateStatisticsRangeDescription(
          newValue.startDate,
          newValue.endDate,
        );

        // если либо нет вариантов в тест-группе, либо нет их статистики, либо изменились значения периода отображения статистики - запрашиваем варианты и их статистику
        if (!(vm.testGroup.parts && vm.testGroup.parts[0].statistics) || newValue != oldValue) {
          $q.all([
            getTestGroupParts(vm.testGroup),
            getTestGroupStatistics(vm.testGroup.id, newValue.startDate, newValue.endDate),
          ]).then(function (results) {
            var parts = results[0];
            var partStatistics = results[1];

            for (var i = 0; i < parts.length; i++) {
              parts[i].statistics = partStatistics[parts[i].id];
            }

            vm.testGroup.parts = parts;

            parseTestGroupParts(parts);

            setDatasets(vm.messageParts, vm.statisticsChart.datasetsToShow, vm.statisticsChart.datasetsShown);
            setDatasets(
              [vm.controlGroup],
              vm.statisticsChart.controlGroupDatasetsToShow,
              vm.statisticsChart.controlGroupDatasetsShown,
            );

            vm.statisticsChart.data.labels =
              partStatistics[Object.keys(partStatistics)[0]][CAMELED_SENDING_TYPES.BOUNCED].labels;

            chartHelper.updateChart(vm.statisticsChart.instance);
          });
        }
      });
    }

    function completeAbTest(testGroup, selectedMessagePart) {
      var completeAbTestTestModal = $uibModal.open({
        controller: 'CompleteAbTestModalController',
        controllerAs: 'vm',
        resolve: {
          testGroup: angular.bind(null, angular.identity, testGroup),
          selectedMessagePart: angular.bind(null, angular.identity, selectedMessagePart),
        },
        templateUrl: 'js/shared/modals/complete-ab-test/complete-ab-test.html',
      });

      completeAbTestTestModal.result.then(completeAbTestSuccess);

      function completeAbTestSuccess(data) {
        var newTestGroup = data.newTestGroup;
        getTestGroupParts(newTestGroup).then(getTestGroupPartsSuccess);
        vm.testGroups.unshift(newTestGroup); // FIXME: вот эта строчка падает, а появилась она тут с самого начала написания А/Б тестов

        function getTestGroupPartsSuccess(parts) {
          testGroup.parts = parts;
        }
      }
    }

    /**
     * Генерация текстового представление периода
     *
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @returns {String}
     */
    function generateStatisticsRangeDescription(startDate, endDate) {
      var resultString = [$translate.instant('directives.abTestStatistics.datePeriodStatistic.heading.statisticFor')];
      var isEndDateSame = endDate.isSame(moment(), 'day');
      var diffInDays = endDate.diff(startDate, 'days');

      if (isEndDateSame && diffInDays == 0) {
        resultString.push(
          $translate.instant(
            'directives.abTestStatistics.datePeriodStatistic.heading.today',
            { days: diffInDays + 1 },
            'messageformat',
          ),
        );
      } else {
        if (isEndDateSame) {
          resultString.push(
            $translate.instant(
              'directives.abTestStatistics.datePeriodStatistic.heading.last',
              { days: diffInDays + 1 },
              'messageformat',
            ),
          );
        }
        resultString.push(
          $translate.instant(
            'directives.abTestStatistics.datePeriodStatistic.heading.days',
            { days: diffInDays + 1 },
            'messageformat',
          ),
        );
      }

      return resultString.join(' ');
    }

    /**
     * Подсчёт количества блочных поп-апов в сообщении
     *
     * @returns {number}
     */
    function getBlockPopupCount() {
      return $filter('filter')(vm.messageParts, filterBlockPopups).length;

      function filterBlockPopups(messagePart) {
        return (
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          messagePart.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
        );
      }
    }

    /**
     * Получение цветов для типов датасетов по контрольной группе
     *
     * @param {SENDING_TYPES} sendingTypes
     * @returns {Object}
     */
    function getControlGroupSendingTypeColors(sendingTypes) {
      // FIXME: цвета для графиков сделаны не слишком хорошо, убрать это всё, когда руки дойдут до рефакторинга
      var controlGroupColors = {};

      controlGroupColors[sendingTypes.GOAL_COMPLETED] = '#d8a5cc';
      controlGroupColors[sendingTypes.SENDED] = '#a37b9b';

      return controlGroupColors;
    }

    /**
     * Получение цветов для типов датасетов по вариантам сообщения
     *
     * @param {SENDING_TYPES} sendingTypes
     * @returns {Array.<Object>}
     */
    function getMessagePartSendingTypeColors(sendingTypes) {
      // FIXME: цвета для графиков сделаны не слишком хорошо, убрать это всё, когда руки дойдут до рефакторинга
      var messagePartColors = [];

      messagePartColors[0] = {};
      messagePartColors[0][sendingTypes.CLICKED] = '#edc53d';
      messagePartColors[0][sendingTypes.GOAL_COMPLETED] = '#e0c406';
      messagePartColors[0][sendingTypes.READ] = '#d1915f';
      messagePartColors[0][sendingTypes.REPLIED] = '#f7ac71';
      messagePartColors[0][sendingTypes.SENDED] = '#b8743f';

      messagePartColors[1] = {};
      messagePartColors[1][sendingTypes.CLICKED] = '#40d651';
      messagePartColors[1][sendingTypes.GOAL_COMPLETED] = '#35b445';
      messagePartColors[1][sendingTypes.READ] = '#9ed546';
      messagePartColors[1][sendingTypes.REPLIED] = '#b2e23f';
      messagePartColors[1][sendingTypes.SENDED] = '#89c22b';

      return messagePartColors;
    }

    /**
     * Получение вариантов сообщения тест-группы
     * Если варианты сообщения уже существуют в тест-группе - запроса на сервер не происходит
     *
     * @param {Object} testGroup тест-группа
     * @returns {Promise}
     */
    function getTestGroupParts(testGroup) {
      // если варианты в тест-группе уже присутствуют - они и возвращаются
      if (testGroup.parts) {
        return $q.when(testGroup.parts);
      } else {
        return firstValueFrom(messagePartModel.getMessageTestGroupParts(testGroup.id));
      }
    }

    /**
     * Получение статистики тест-группы
     *
     * @param {String} testGroupId ID тест-группы
     * @param {moment} startDate Дата начала периора
     * @param {moment} endDate Дата конца периода
     * @returns {Promise}
     */
    function getTestGroupStatistics(testGroupId, startDate, endDate) {
      return firstValueFrom(messageTestGroupModel.getMessageTestGroupStatistics(testGroupId, startDate, endDate));
    }

    /**
     * Парсинг вариантов сообщения тест-группы
     *
     * @param {Array.<Object>} parts Варианты сообщения
     */
    function parseTestGroupParts(parts) {
      vm.controlGroup = $filter('filter')(parts, { type: MESSAGE_PART_TYPES.CONTROL_GROUP }, true)[0];
      vm.messageParts = $filter('filter')(parts, { type: '!' + MESSAGE_PART_TYPES.CONTROL_GROUP }, true);
    }

    /**
     * Заполнение датасетов графика
     *
     * @param {Array.<Object>} messageParts Варианты сообщения
     * @param {Array.<SENDING_TYPES>} datasetsToShow Датасеты, которые будут показываться на графике
     * @param {Array.<SENDING_TYPES>} alreadyShown Датасеты, которые уже показываются в текущий момент
     */
    function setDatasets(messageParts, datasetsToShow, alreadyShown) {
      var dataset, datasetType, datasetSendingType, datasetLabel, datasetColors;
      for (var j = 0; j < messageParts.length; j++) {
        var messagePart = messageParts[j];

        for (var i = 0; i < datasetsToShow.length; i++) {
          datasetSendingType = datasetsToShow[i];

          datasetLabel =
            $translate.instant('models.message.statisticsTypes.' + datasetSendingType) + ' (' + messagePart.name + ')';
          datasetType =
            messagePart.type == MESSAGE_PART_TYPES.CONTROL_GROUP ? DATASET_TYPES.CONTROL_GROUP : DATASET_TYPES.GENERAL;

          if (
            !(dataset = $filter('filter')(
              vm.statisticsChart.data.datasets,
              {
                datasetSendingType: datasetSendingType,
                datasetType: datasetType,
                messagePartName: messagePart.name,
              },
              true,
            )[0])
          ) {
            dataset =
              vm.statisticsChart.data.datasets[
                vm.statisticsChart.data.datasets.push(angular.copy(vm.statisticsChart.defaultDataset)) - 1
              ];

            if (datasetType == DATASET_TYPES.CONTROL_GROUP) {
              datasetColors = chartHelper.generateColors(CONTROL_GROUP_SENDING_TYPE_COLORS[datasetSendingType]);
            } else {
              datasetColors = chartHelper.generateColors(MESSAGE_PART_SENDING_TYPE_COLORS[j][datasetSendingType]);
            }
            chartHelper.mergeDatasetColors(dataset, datasetColors);
          }

          dataset.datasetSendingType = datasetSendingType;
          dataset.datasetType = datasetType;
          dataset.data = messagePart.statistics[datasetSendingType].data;
          dataset.hidden = alreadyShown.indexOf(datasetSendingType) == -1;
          dataset.label = datasetLabel;
          dataset.messagePartName = messagePart.name;
          dataset.sum = messagePart.statistics[datasetSendingType].sum;
        }
      }
    }

    /**
     * Вызов showSendingsCallback с параметрами
     *
     * @param {Object} testGroup Тест-группа
     * @param {Object} messagePart Вариант сообщения
     * @param {moment} startDate Дата начала периода
     * @param {moment} endDate Дата конца периода
     * @param {SENDING_TYPES} sendingType Тип действия получателя сообщения
     */
    function showSendings(testGroup, messagePart, startDate, endDate, sendingType) {
      var params = {
        testGroup: testGroup,
        messagePart: messagePart,
        startDate: startDate,
        endDate: endDate,
        sendingType: sendingType,
      };

      vm.showSendingsCallback(params);
    }

    /**
     * Переключение показа датасета на графике
     *
     * @param {SENDING_TYPES} datasetSendingType Датасет, который включается/отключается
     * @param {DATASET_TYPES} datasetType Тип датасета
     * @param {Array.<SENDING_TYPES>} alreadyShown Датасеты, которые показываются в текущий момент
     */
    function toggleDatasetsToShow(datasetSendingType, datasetType, alreadyShown) {
      trackClickChartLegend();

      var filteredDatasets = $filter('filter')(
        vm.statisticsChart.instance.data.datasets,
        {
          datasetType: datasetType,
          datasetSendingType: datasetSendingType,
        },
        true,
      );
      var datasetsShownIndex = alreadyShown.indexOf(datasetSendingType);

      if (datasetsShownIndex != -1) {
        alreadyShown.splice(datasetsShownIndex, 1);
      } else {
        alreadyShown.push(datasetSendingType);
      }

      for (var i = 0; i < filteredDatasets.length; i++) {
        var dataset = filteredDatasets[i];
        dataset.hidden = datasetsShownIndex != -1;
      }

      chartHelper.updateChart(vm.statisticsChart.instance);
    }

    /**
     * Трек клика на легенду графика
     */
    function trackClickChartLegend() {
      carrotquestHelper.track('Автосообщения - клик на легенду графика на "А/Б тест"', {
        Название: vm.currentMessage.name,
      });
    }

    /**
     * Трек клика на кнопку "Посмотреть"
     *
     * @param {Object} messagePart Вариант сообщения
     * @param {SENDING_TYPES} sendingType Действие получателя сообщения
     */
    function trackClickShowSendings(messagePart, sendingType) {
      // todo: пока что закостылял, когда будет многоязычность - надо будет думать что отправлять в параметре 'Тип получателей' и где хранить возможные значения этого параметра
      var sendingTypeDescriptions = {};

      sendingTypeDescriptions[SENDING_TYPES.BOUNCED] = 'Bounced';
      sendingTypeDescriptions[SENDING_TYPES.CLICKED] = 'Перешли по ссылке';
      sendingTypeDescriptions[SENDING_TYPES.GOAL_COMPLETED] = 'Достигнуто целей';
      sendingTypeDescriptions[SENDING_TYPES.READ] = 'Прочитали';
      sendingTypeDescriptions[SENDING_TYPES.REPLIED] = 'Ответили';
      sendingTypeDescriptions[SENDING_TYPES.SENDED] = 'Отправлено';
      sendingTypeDescriptions[SENDING_TYPES.SPAM] = 'Пожаловались на спам';
      sendingTypeDescriptions[SENDING_TYPES.UNSUBSCRIBED] = 'Отписались от рассылки';

      carrotquestHelper.track('Автосообщения - клик на "Посмотреть"', {
        'Тип получателей': sendingTypeDescriptions[sendingType],
        Вариант: messagePart.name,
      });
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { InputNumberComponent } from './input-number.component';

@NgModule({
  declarations: [InputNumberComponent],
  exports: [InputNumberComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, SharedModule, ReactiveFormsModule],
})
export class InputNumberModule {}

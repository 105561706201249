import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import isEqual from 'lodash-es/isEqual';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { VkGroupForm } from '@http/integration/integrations/vk/forms/vk-integration.form';
import { VkIntegrationGroupExternal } from '@http/integration/integrations/vk/interfaces/vk-integration.interfaces';
import { IntegrationService } from '@http/integration/services/integration.service';
import { DestroyService } from '@panel/app/services';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';

/**
 * Выбор групп ВКонтакте, которые будут подключены к интеграции
 */

@Component({
  selector: 'cq-vk-groups-choice[integrationId][vkGroupForms][choiceGroups]',
  templateUrl: './vk-groups-choice.component.html',
  styleUrls: ['./vk-groups-choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class VkGroupsChoiceComponent implements OnInit {
  /** ID интеграции */
  @Input()
  integrationId!: string | null;

  /** Формы групп ВКонтакте */
  @Input()
  vkGroupForms!: GenericFormArray<VkGroupForm>;

  /** Callback на выбор группы */
  @Output()
  choiceGroups: EventEmitter<number[]> = new EventEmitter();

  /** Текущее название проекта */
  projectName: string = environment.projectName;

  constructor(
    @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
  ) {}

  ngOnInit(): void {
    this.getUserGroups();
  }

  /**
   * Добавление формы группы ВКонтакте к существующим формам
   *
   * @param vkGroupForm - Форма, которую необходимо добавить
   */
  addVkGroupForm(vkGroupForm: VkGroupForm) {
    this.vkGroupForms.push(vkGroupForm);
  }

  /**
   * Создание формы группы в ВКонтакте по внешнему формату
   *
   * @param vkGroupExternal - Группа в ВКонтакте во внешнем формате
   */
  createVkGroupForm(vkGroupExternal: VkIntegrationGroupExternal): VkGroupForm {
    return new VkGroupForm(vkGroupExternal);
  }

  /** Получение списка сообществ ВК, которые администрирует пользователь */
  getUserGroups() {
    this.integrationService
      .vkGetUserGroups(this.integrationId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((returnedVkGroups) => {
        returnedVkGroups.forEach((group) => {
          const existFormGroup = this.getExistVkGroupFrom(group);
          if (!existFormGroup) {
            const groupForm = this.createVkGroupForm(group);
            this.addVkGroupForm(groupForm);
          }
          if (existFormGroup && !isEqual(group, existFormGroup.value)) {
            existFormGroup.setValue(group);
          }
        });

        this.cdr.markForCheck();
      });
  }

  /**
   * ПОлучить форму группы ВК
   *
   * NOTE:
   *  Из-за особенностей реализации интеграции с ВКонтакте на backend'е, при запросе групп из ВКонтакте,
   *  backend записывает полученные группы в интеграцию. Необходимо проверить, сохранена ли группа,
   *  которую вернул ВКонтакте в настройках интеграции, а также надо сравнить данные т.к. они могут отличаться
   *
   * @param vkGroup - Сообщество ВКонтакте
   */
  getExistVkGroupFrom(vkGroup: VkIntegrationGroupExternal): VkGroupForm | undefined {
    return this.vkGroupForms.controls.filter((group) => group.controls.id.value === vkGroup.id)[0];
  }

  /** Обработчик выбора группы */
  onChangeGroup() {
    const allGroupsRaw: VkIntegrationGroupExternal[] = this.vkGroupForms.getRawValue();
    const groupsIdsWithRequiredAuthorization = allGroupsRaw
      .filter((group) => group.subscribed && !group.accessToken)
      .map((group) => group.id);

    this.choiceGroups.emit(groupsIdsWithRequiredAuthorization);
  }
}

/**
 * Компонент для работы с формой завершения регистрации
 */

(function () {
  'use strict';

  require('./completion-registration-form.controller');

  angular
    .module('myApp.completionRegistration')
    .component('cqCompletionRegistrationForm', cqCompletionRegistrationForm());

  function cqCompletionRegistrationForm() {
    return {
      bindings: {
        /** Инстанс формы завершения регистрации */
        completionRegistrationForm: '=',
        /** Выполняется ли запрос на backend */
        isApiRequestPerformed: '=',
        /** Будут ли поля обязательными */
        isRequiredMode: '=',
        /** Регистрационные данные пользователя */
        registrationUserData: '=',
        /** Callback на отправку формы завершения регистрации */
        submitCompletionRegistrationForm: '&',
      },
      controller: 'cqCompletionRegistrationFormController',
      controllerAs: 'vm',
      template: require('./completion-registration-form.html'),
    };
  }
})();

import { Injectable } from '@angular/core';
import { some } from 'lodash-es';

import { App } from '@http/app/app.model';
import { PLAN_CAPABILITIES } from '@http/plan-capability/plan-capability.constants';
import { PlanCapabilityModel } from '@http/plan-capability/plan-capability.model';
import { ComparisonOperator } from '@http/plan-capability/plan-capability.types';
import { PlanAddonService } from '@panel/app/services/billing/plan-addon/plan-addon.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { SpecialProductFeatures } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';

@Injectable({ providedIn: 'root' })
export class PlanSpecialFeatureAccessService {
  constructor(
    private readonly planAddonService: PlanAddonService,
    private readonly planVersionService: PlanVersionService,
    private readonly planCapabilityModel: PlanCapabilityModel,
  ) {}

  /**
   * Проверка наличия доступа к «специальным» фичам
   *
   * @param productFeature - Фича
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @param comparisonOperator - Оператор сравнения для фич с лимитом
   */
  hasAccess(
    productFeature: SpecialProductFeatures,
    app: App,
    amount?: number,
    comparisonOperator: ComparisonOperator = '<',
  ): boolean {
    switch (productFeature) {
      case PLAN_FEATURE.AI_BOT:
        return this.hasAccessToAiBot(app);
      case PLAN_FEATURE.AUTO_MESSAGES_MASS_ACTIVATION:
        return this.hasAccessToAutoMessagesMassActivation(app);
      case PLAN_FEATURE.AUTO_MESSAGES_TOTAL:
        return this.hasAccessToAutoMessagesTotal(app, amount, comparisonOperator);
      case PLAN_FEATURE.AUTO_MESSAGES_PAGE:
        return this.hasAccessToAutoMessagesPage(app);
      case PLAN_FEATURE.CHAT_BOTS_MASS_ACTIVATION:
        return this.hasAccessToChatBotsMassActivation(app);
      case PLAN_FEATURE.CHAT_BOTS_PAGE:
        return this.hasAccessToChatBotsPage(app);
      case PLAN_FEATURE.FACEBOOK_BOTS:
        return this.hasAccessToFacebookBots(app, amount);
      case PLAN_FEATURE.KNOWLEDGE_BASE:
        return this.hasAccessToKnowledgeBase(app);
      case PLAN_FEATURE.KNOWLEDGE_BASE_PRO:
        return this.hasAccessToKnowledgeBasePro(app);
      case PLAN_FEATURE.LEAD_BOTS:
        return this.hasAccessToLeadBots(app, amount);
      case PLAN_FEATURE.MANUAL_MESSAGES_BULK_SEND:
        return this.hasAccessToManualMessageBulkSend(app, amount);
      case PLAN_FEATURE.MESSAGES_CHANNELS:
        return this.hasAccessToMessagesChannels(app, amount);
      case PLAN_FEATURE.NO_BRANDING:
        return this.hasAccessToNoBranding(app);
      case PLAN_FEATURE.WELCOME_BOTS:
        return this.hasAccessToWelcomeBot(app, amount);
      case PLAN_FEATURE.TELEGRAM_BOTS:
        return this.hasAccessToTelegramBots(app, amount);
    }
  }

  /**
   * Проверка доступа до страницы триггерных сообщений
   *
   * @param app - Текущее приложение
   */
  hasAccessToAutoMessagesPage(app: App): boolean {
    switch (true) {
      case this.planVersionService.isLTD():
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL);
      default:
        return true;
    }
  }

  /**
   * Проверка доступа до фичи "Ручное создание каналов"
   *
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   */
  hasAccessToMessagesChannels(app: App, amount?: number): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.MESSAGES_CHANNELS, amount);
      case this.planVersionService.isLTD():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.MESSAGES_CHANNELS, amount);
      case this.planVersionService.isV1(app):
        return true;
      case this.planVersionService.isV2(app):
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.MESSAGES_CHANNELS, amount);
      default:
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.MESSAGES_CHANNELS, amount);
    }
  }

  /**
   * Проверка доступа до фичи "AI-бот"
   *
   * @param app - Текущее приложение
   * @private
   */
  private hasAccessToAiBot(app: App): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
      case this.planVersionService.isLTD():
      case this.planVersionService.isTrial():
        return false;
      case this.planVersionService.isV1(app):
      case this.planVersionService.isV2(app):
      case this.planVersionService.isV3(app):
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.AI_BOTS_UNITS_PRICING) ||
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.AI_BOTS_UNITS_PRICING_V2)
        );
      default:
        throw new Error('Unknown plan version');
    }
  }

  /**
   * Проверка доступа до фичи "Массовая активация триггерных сообщений"
   *
   * @param app - Текущее приложение
   * @private
   */
  private hasAccessToAutoMessagesMassActivation(app: App): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
      case this.planVersionService.isLTD():
        return false;
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isV1(app):
        return true;
      case this.planVersionService.isV2(app):
      default:
        return false;
    }
  }

  /**
   * Проверка доступа до фичи "Триггерные сообщения"
   *
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @param comparisonOperator - Оператор сравнения для фич с лимитом
   * @private
   */
  private hasAccessToAutoMessagesTotal(
    app: App,
    amount?: number,
    comparisonOperator: ComparisonOperator = '<',
  ): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
      case this.planVersionService.isLTD():
        if (!amount && amount !== 0) {
          throw new Error('Provide current amount of messages');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL, amount, comparisonOperator);
      case this.planVersionService.isTrial():
      case this.planVersionService.isV1(app):
        return true;
      case this.planVersionService.isV2(app):
      default:
        if (!amount && amount !== 0) {
          throw new Error('Provide current amount of messages');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.AUTO_MESSAGES_TOTAL, amount, comparisonOperator);
    }
  }

  /**
   * Проверка доступа до фичи "Массовая активация чат-ботов"
   *
   * @param app - Текущее приложение
   * @private
   */
  private hasAccessToChatBotsMassActivation(app: App): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
      case this.planVersionService.isLTD():
        return false;
      case this.planVersionService.isTrial():
      case this.planVersionService.isV1(app):
        return true;
      case this.planVersionService.isV2(app):
      default:
        return false;
    }
  }

  private hasAccessToChatBotsPage(app: App): boolean {
    switch (true) {
      case this.planVersionService.isLTD():
        const hasAccessToLeadBots = this.hasAccess(
          PLAN_FEATURE.LEAD_BOTS,
          app,
          // Не передаю amount, потому что мне неважно ограничение по лимитам, блокировать раздел надо только при отстутствии доступа вообще
          0,
        );
        const hasAccessToWelcomeBots = this.hasAccess(PLAN_FEATURE.WELCOME_BOTS, app, 0);
        const hasAccessToFacebookBots = this.hasAccess(PLAN_FEATURE.FACEBOOK_BOTS, app, 0);

        return hasAccessToLeadBots || hasAccessToWelcomeBots || hasAccessToFacebookBots;
      default:
        return true;
    }
  }

  /**
   * Проверка доступа до фичи "Facebook-боты"
   *
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @private
   */
  private hasAccessToFacebookBots(app: App, amount?: number): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS) &&
          this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL, amount)
        );
      case this.planVersionService.isLTD():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL, amount);
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isV1(app):
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS);
      case this.planVersionService.isV2(app):
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS) &&
          this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL, amount)
        );
      default:
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }

        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL, amount);
    }
  }

  /**
   * Проверка доступа до фичи "Без брендинга"
   *
   * @param app - Текущее приложение
   * @private
   */
  private hasAccessToNoBranding(app: App): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
      case this.planVersionService.isLTD():
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.NO_BRANDING);
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isV1(app):
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.NO_BRANDING);
      case this.planVersionService.isV2(app):
        return some([BILLING_ADD_ONS.NO_BRANDING, BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING], (noBrandingAddOn) =>
          this.planAddonService.isAddonConnected(noBrandingAddOn),
        );
      default:
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING);
    }
  }

  /**
   * Проверка доступа до фичи "Telegram-боты"
   *
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @private
   */
  private hasAccessToTelegramBots(app: App, amount?: number): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return (
          (this.planAddonService.isAddonConnected(BILLING_ADD_ONS.TELEGRAM_BOT) &&
            this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL, amount)) ||
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM)
        );
      case this.planVersionService.isLTD():
        return false;
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isV1(app):
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.TELEGRAM_BOT_FOR_OLD_PLANS) ||
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM_FOR_OLD_PLANS)
        );
      case this.planVersionService.isV2(app):
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return (
          (this.planAddonService.isAddonConnected(BILLING_ADD_ONS.TELEGRAM_BOT) &&
            this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL, amount)) ||
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM)
        );
      case this.planVersionService.isV3(app):
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }

        return (
          (this.planAddonService.isAddonConnected(BILLING_ADD_ONS.TELEGRAM_BOT) &&
            this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.TELEGRAM_BOTS_TOTAL, amount)) ||
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.MESSAGES_TO_TELEGRAM)
        );
      default:
        throw new Error('Unknown plan version');
    }
  }

  /**
   * Проверка доступа до фичи "База знаний"
   *
   * @param app - Текущее приложение
   */
  private hasAccessToKnowledgeBase(app: App): boolean {
    switch (true) {
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isFreemium():
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.KNOWLEDGE_BASE);
      case this.planVersionService.isLTD():
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING);
      case this.planVersionService.isV1(app):
        return true;
      case this.planVersionService.isV2(app):
      case this.planVersionService.isV3(app):
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.KNOWLEDGE_BASE);
      default:
        throw new Error('Unknown plan version');
    }
  }

  /**
   * Проверка доступа до фичи "База знаний PRO"
   *
   * @param app - Текущее приложение
   * @private
   */
  private hasAccessToKnowledgeBasePro(app: App): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE);
      case this.planVersionService.isLTD():
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING) ||
          this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.KNOWLEDGE_BASE_PRO)
        );
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isV1(app):
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE);
      case this.planVersionService.isV2(app):
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE);
      default:
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING) ||
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.PRO_KNOWLEDGE_BASE_UNITS_PRICING_V0224) ||
          this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.KNOWLEDGE_BASE_PRO)
        );
    }
  }

  /**
   * Проверка доступа до фичи "Лид-боты"
   *
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @private
   */
  private hasAccessToLeadBots(app: App, amount?: number): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS) &&
          this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL, amount)
        );
      case this.planVersionService.isLTD():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL, amount);
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isV1(app):
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS);
      case this.planVersionService.isV2(app):
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS) &&
          this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL, amount)
        );
      default:
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }

        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.LEAD_BOTS_TOTAL, amount);
    }
  }

  /**
   * Проверка доступа до фичи "Welcome-боты"
   *
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @private
   */
  private hasAccessToWelcomeBot(app: App, amount?: number): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS) &&
          this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL, amount)
        );
      case this.planVersionService.isLTD():
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.LTD_WELCOME_BOTS);
      case this.planVersionService.isTrial():
        return true;
      case this.planVersionService.isV1(app):
        return this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS);
      case this.planVersionService.isV2(app):
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.CHAT_BOTS) &&
          this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.CHAT_BOTS_TOTAL, amount)
        );
      default:
        return (
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING) ||
          this.planAddonService.isAddonConnected(BILLING_ADD_ONS.WELCOME_BOTS_UNITS_PRICING_V0224)
        );
    }
  }

  /**
   * Проверка доступа до фичи "Массовая отправка ручных сообщений"
   *
   * @param app - Текущее приложение
   * @param amount - Количество фичи
   * @private
   */
  private hasAccessToManualMessageBulkSend(app: App, amount?: number): boolean {
    switch (true) {
      case this.planVersionService.isFreemium():
        return true;
      case this.planVersionService.isLTD():
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.MANUAL_MESSAGES_BULK_SEND, amount, '<=');
      case this.planVersionService.isTrial():
      case this.planVersionService.isV1(app):
        return true;
      case this.planVersionService.isV2(app):
        return true;
      default:
        if (!amount && amount !== 0) {
          throw new Error('Provide amount');
        }
        return this.planCapabilityModel.hasAccess(PLAN_CAPABILITIES.MANUAL_MESSAGES_BULK_SEND, amount, '<=');
    }
  }
}

/**
 * Компонент для превью блока 'Кнопка'
 */
(function () {
  'use strict';

  require('./popup-button-block-preview.controller');
  require('./button.html');
  // HACK: костыль, это должно делать не так. CSS в этом случае никак не обрабатывается angular-cli при билде/серве, потому что стоит ! перед ng-cache-loader
  require('!ng-cache-loader?prefix=panel/src:js/**&url=false!./button.scss');

  angular.module('myApp.popupEditor').component('cqPopupButtonBlockPreview', cqPopupButtonBlockPreview());

  function cqPopupButtonBlockPreview() {
    return {
      bindings: {
        params: '=', // параметры блока
      },
      controller: 'CqPopupButtonBlockPreviewController',
      controllerAs: 'vm',
      template: require('./popup-button-block-preview.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TEAM_MEMBER_DEFAULT_AVATAR_URL } from '@http/team-member/team-member.constants';

@Component({
  selector: 'cq-conversation-part-preview-avatar',
  templateUrl: './conversation-part-preview-avatar.component.html',
  styleUrls: ['./conversation-part-preview-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPartPreviewAvatarComponent {
  /** Аватар для превью реплики */
  get avatarBackgroundImageUrl() {
    return `url(${TEAM_MEMBER_DEFAULT_AVATAR_URL})`;
  }
}

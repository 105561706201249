import { InjectionToken } from '@angular/core';

import { ConversationPart } from '@http/conversation-part/conversation-part.types';
import { DjangoUser } from '@http/django-user/django-user.types';

/**
 * Тип данных, передаваемых в модалку с контентом, который был использован для ответа AI-ботом
 */
export type ConversationPartAiContentModalData = {
  /** Реплика бота */
  conversationPart: ConversationPart;
  /** Текущий пользователь */
  djangoUser: DjangoUser;
  /** Домен базы знаний */
  knowledgeBaseDomain: string;
};

export const CONVERSATION_PART_AI_CONTENT_MODAL_DATA_TOKEN = new InjectionToken<ConversationPartAiContentModalData>(
  'ConversationPartAiContentModal data provider',
);

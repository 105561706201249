import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';

import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

type SocialNetworkConfig = {
  text: string;
  show: boolean;
};

@Component({
  selector: 'cq-social-network[socialName]',
  templateUrl: './social-network.component.html',
  styleUrls: ['./social-network.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialNetworkComponent extends AbstractCVAControl<SocialNetworkConfig> {
  @Input()
  socialName!: string;

  control = new GenericFormGroup<SocialNetworkConfig>({
    text: new GenericFormControl<string>(null, Validators.required),
    show: new GenericFormControl<boolean>(false),
  });

  readonly SOCIAL_SETTING_TEXT_MAX_LENGTH = 255;
}

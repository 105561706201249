import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { EventTypeSelectModule } from '@panel/app/partials/event-type-select/event-type-select.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { MessageEditorAnotherEventComponent } from './message-editor-another-event.component';

@NgModule({
  declarations: [MessageEditorAnotherEventComponent],
  exports: [MessageEditorAnotherEventComponent],
  imports: [CommonModule, EventTypeSelectModule, SharedModule, TranslocoModule, ReactiveFormsModule, FormsModule],
})
export class MessageEditorAnotherEventModule {}

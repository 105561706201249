import { Directive, inject } from '@angular/core';
import { AbstractControl, FormGroup, NgControl } from '@angular/forms';

import { AbsBaseCVAComponent } from './abstract-base-cva-component';

@Directive()
export abstract class AbsCVAFormGroupBasedComponent<
  TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
  },
> extends AbsBaseCVAComponent {
  abstract readonly control: FormGroup<TControl>;

  protected readonly ngControl = inject(NgControl, { optional: true, self: true });

  constructor() {
    super();
  }
}

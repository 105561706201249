<ng-container *transloco="let t; read: 'addressFormControlComponent'">
  <h4>{{ t('heading') }}</h4>
  <div class="d-flex align-items-center">
    <div class="custom-control custom-radio custom-control-inline">
      <input
        class="custom-control-input"
        id="radio-0"
        type="radio"
        [value]="AiBotAddressForm.INFORMAL"
        [formControl]="control"
      />
      <label
        class="custom-control-label"
        for="radio-0"
        >{{ t('controls.0') }}</label
      >
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input
        class="custom-control-input"
        id="radio-1"
        type="radio"
        [value]="AiBotAddressForm.NEUTRAL"
        [formControl]="control"
      />
      <label
        class="custom-control-label"
        for="radio-1"
        >{{ t('controls.1') }}</label
      >
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input
        class="custom-control-input"
        id="radio-2"
        type="radio"
        [value]="AiBotAddressForm.FORMAL"
        [formControl]="control"
      />
      <label
        class="custom-control-label"
        for="radio-2"
        >{{ t('controls.2') }}</label
      >
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TeamMemberPresence } from '@http/team-member/team-member.types';

@Component({
  selector: 'cq-team-member-avatar',
  templateUrl: './team-member-avatar.component.html',
  styleUrls: ['./team-member-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMemberAvatarComponent {
  /**
   * Аватар пользователя
   */
  @Input()
  avatar!: string;

  /**
   * Статус пользователя
   */
  @Input()
  status?: TeamMemberPresence;

  /**
   * Размер аватарки
   */
  @Input()
  size: 'lg' | 'md' | 'xs' = 'md';

  /**
   * Получение CSS класса для соответствующего статуса
   */
  get statusCssClass(): string {
    switch (this.status) {
      case 'online':
        return 'bg-success';
      case 'offline':
        return 'bg-danger';
      default:
        return '';
    }
  }
}

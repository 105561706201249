import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { tuiPure } from '@taiga-ui/cdk';
import { firstValueFrom, Subject } from 'rxjs';

import { App } from '@http/app/app.model';
import { TagModel } from '@http/tag/tag.model';
import { UserTag } from '@http/user/types/user.type';
import { ModalHelperService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { ELASTICSEARCH_OPERATION } from '@panel/app/services/elasticsearch-operation/elasticsearch-operation.constants';
import { TagFilter } from '@panel/app/services/filter/types/filter.internal-types';
import { ConfirmModalComponent } from '@panel/app/shared/modals/confirm-modal/confirm-modal.component';
import { CONFIRM_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/confirm-modal/confirm-modal.token';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-tag-filters',
  templateUrl: './tag-filters.component.html',
  styleUrls: ['./tag-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagFiltersComponent implements OnInit {
  @Input()
  app!: App;

  @Input()
  defaultFilters: TagFilter[] = [];

  @Input()
  tags: UserTag[] = [];

  @Input()
  showRemoved = false;

  @Input()
  canRemoveTags = false;

  @Output()
  defaultFiltersChange: Subject<TagFilter[]> = new Subject();

  protected accessToUsersTags!: ProductFeatureAccess;

  constructor(
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly tagModel: TagModel,
    private readonly modalHelper: ModalHelperService,
    private readonly transloco: TranslocoService,
    private readonly toastr: ToastService,
    private readonly carrotquestHelper: CarrotquestHelper,
  ) {}

  ngOnInit(): void {
    this.accessToUsersTags = this.planFeatureAccessService.getAccess(PLAN_FEATURE.USERS_TAGS, this.app);
  }

  trackById(index: number, item: UserTag) {
    return item.id;
  }

  @tuiPure
  getFilteredTags(tags: UserTag[], defaultFilters: TagFilter[], showRemoved: boolean) {
    return showRemoved
      ? tags.filter((tag) => {
          return !tag.removed || defaultFilters.some((filterTag) => filterTag.tagId === tag.id);
        })
      : tags.filter((tag) => !tag.removed);
  }

  changeTagFilterType(tag: UserTag) {
    const foundIndex = this.defaultFilters.findIndex((tagFilter) => tagFilter.tagId === tag.id);
    if (foundIndex === -1) {
      throw new Error('Could not find a tag to update');
    }
    this.defaultFilters[foundIndex] = {
      ...this.defaultFilters[foundIndex],
      type:
        this.defaultFilters[foundIndex].type === ELASTICSEARCH_OPERATION.LIST_CONTAINS
          ? ELASTICSEARCH_OPERATION.LIST_NOT_CONTAINS
          : ELASTICSEARCH_OPERATION.LIST_CONTAINS,
    };
    this.defaultFiltersChange.next(this.defaultFilters);
  }

  /**
   * Тег включен или исключен
   */
  isTagContains(tag: UserTag) {
    const tagFiltered = this.defaultFilters.find((userTag) => userTag.tagId === tag.id);

    return !!(tagFiltered && tagFiltered.type === 'lcontains');
  }

  /**
   * Проверяет есть ли выбранные теги
   */
  isTagSelected(tag: UserTag) {
    return this.defaultFilters.some((userTag) => userTag.tagId === tag.id);
  }

  tagSelectChange(tag: UserTag) {
    this.isTagSelected(tag) ? this.removeTagFilter(tag) : this.addTagFilter(tag);
  }

  removeTagFilter(tag: UserTag) {
    const foundIndex = this.defaultFilters.findIndex((tagFilter) => tagFilter.tagId === tag.id);
    this.defaultFilters.splice(foundIndex, 1);
    this.defaultFilters = [...this.defaultFilters];
    this.defaultFiltersChange.next(this.defaultFilters);
  }

  removeTag(tag: UserTag) {
    const foundIndex = this.tags.findIndex((t) => tag.id === t.id);
    this.tags.splice(foundIndex, 1);
    this.tags = [...this.tags];
  }

  addTagFilter(tag: UserTag) {
    this.defaultFilters = [
      ...this.defaultFilters,
      { tagId: tag.id, tagName: tag.name, type: ELASTICSEARCH_OPERATION.LIST_CONTAINS },
    ];
    this.defaultFiltersChange.next(this.defaultFilters);
  }

  openRemoveTagModal(tag: UserTag) {
    this.modalHelper
      .provide(CONFIRM_MODAL_DATA_TOKEN, {
        heading: this.transloco.translate('tagFiltersComponent.removeTagModal.heading'),
        body: this.transloco.translate('tagFiltersComponent.removeTagModal.body', { tag: tag.name }),
        confirmButtonText: this.transloco.translate('tagFiltersComponent.removeTagModal.confirmButtonText'),
      })
      .open(ConfirmModalComponent)
      .result.then(() => {
        firstValueFrom(this.tagModel.removeById(tag.id)).then(() => {
          // Если тег выбран, то удаляем его из массива
          if (this.isTagSelected(tag)) {
            this.removeTagFilter(tag);
          }
          this.removeTag(tag);

          this.toastr.success(this.transloco.translate('filterSegments.toasts.removeTagSuccess', { tag: tag.name }));
        });
      });
  }

  trackClickTagContains() {
    this.carrotquestHelper.track('Пользователи - кликнул на тег - включать/исключать');
  }

  trackClickTag() {
    this.carrotquestHelper.track('Пользователи - кликнул на тег');
  }
}

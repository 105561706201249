(function () {
  'use strict';

  angular.module('myApp.autoMessages').factory('AUTO_MESSAGE_STATISTICS_TABS', getAutoMessageStatisticsTabs);

  function getAutoMessageStatisticsTabs() {
    return {
      AB_TEST: {
        ORDER: 1,
        TEMPLATE_URL: 'js/components/auto-messages/statistics/tabs/ab-test.html',
        VALUE: 'abTest',
      },
      GENERAL: {
        ORDER: 0,
        TEMPLATE_URL: 'js/components/auto-messages/statistics/tabs/general.html',
        VALUE: 'general',
      },
      SENDINGS: {
        ORDER: 2,
        TEMPLATE_URL: 'js/components/auto-messages/statistics/tabs/sendings.html',
        VALUE: 'sendings',
      },
    };
  }
})();

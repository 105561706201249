(function () {
  'use strict';

  angular.module('myApp.integrations').controller('CqWordpressModalController', CqWordpressModalController);

  function CqWordpressModalController($translate, PROJECT_NAME, carrotquestHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.$translate = $translate;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.trackClickDownloadPlugin = trackClickDownloadPlugin;
    }

    /**
     * Трек клика на кнопку 'Скачать плагин'
     */
    function trackClickDownloadPlugin() {
      carrotquestHelper.track('Интеграции - ' + vm.resolve.integration.type + ' - клик на "Скачать плагин"');
    }
  }
})();

<div
  class="flex flex-col"
  *transloco="let t"
>
  <!--ФЛАГ - СОХРАНЯТЬ СВОЙСТВО ИЛИ НЕТ  -->
  <div class="margin-bottom-20">
    <cq-checkbox-control
      [formControl]="actionForm.controls.active"
      class="margin-top-10 margin-bottom-10"
    >
      {{ t('chatBot.actionPropertyField.writePropertyCheckbox.actionText') }}
    </cq-checkbox-control>

    <!-- ВЫБОР СВОЙСТВА ПОЛЬЗОВАТЕЛЯ -->
    <div
      *ngIf="actionForm.controls.active.value"
      class="form-group margin-top-15 no-margin-bottom"
      [cqShowError]="actionForm.controls.keyName"
    >
      <cq-property-selector
        [currentApp]="currentApp"
        [formControl]="actionForm.controls.keyName"
        [(properties)]="propertyOptions.userProps"
        (selectedChange)="onChangeProperty($event)"
      ></cq-property-selector>
      <small
        *ngIf="validatableField"
        class="d-inline-block text-muted margin-top-5"
      >
        {{ t('chatBot.actionPropertyField.propertySelect.validationTips.' + actionForm.controls.keyName.value, {projectName}) }}
      </small>
      <div
        *ngIf="
          isRusCountry && isNewSettingsForPrivacyPolicy && validatableField && isActiveMessengerShowConfirmSubscription
        "
        class="d-flex mt-5"
      >
        <i class="cqi-sm cqi-info text-warning mr-5"></i>
        <small class="text-secondary">{{
          t('chatBot.actionPropertyField.propertySelect.confirmSubscriptionWarning')
        }}</small>
      </div>
    </div>

    <!-- ВЫБОР ПЛЕЙСХОЛДЕРА -->
    <cq-bot-custom-placeholder
      *ngIf="isCustomPlaceholderAllowed"
      [actionForm]="actionForm"
      class="form-group margin-top-15 no-margin-bottom"
    ></cq-bot-custom-placeholder>
  </div>

  <!-- ВЫБОР БЛОКА -->
  <cq-bot-action-next-branch-selector
    [formControl]="actionForm.controls.nextBranchLinkId!"
    [canSelectConditionAsNextBranch]="true"
    [chatBotType]="chatBotType"
    [chatBotId]="chatBotId"
    [nextBranchOptions]="targetBranchOptions"
    (branchSelect)="onChangeBranch($event)"
    (addBranchClick)="branchCreate.next($event)"
  ></cq-bot-action-next-branch-selector>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { END_TIME_FEATURES, FEATURES } from 'app/http/feature/feature.constants';
import { ApiGetListRequest } from 'app/http/feature/feature.types';
import moment from 'moment';
import Moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environment';

/**
 * Сервис для работы с фичами
 */
@Injectable({ providedIn: 'root' })
export class FeatureModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Список доступных фичей
   *
   * NOTE Массив нужен, чтобы в hasAccess каждый раз не передавать список фичей
   */
  private features: FEATURES[] = [];

  /**
   * Получение списка доступных фич
   *
   * @param appId - ID аппа
   */
  public getList(appId?: string): Observable<FEATURES[]> {
    let params: ApiGetListRequest = {};

    // NOTE: ID приложения передаётся в APIRequest, чтобы он наверняка записался в get-параметр
    if (appId) {
      params.app = appId;
    }

    return this.http.get<FEATURES[]>('/features', { params }).pipe(tap((features) => (this.features = features)));
  }

  /**
   * Проверяет доступна ли фича
   *
   * @param feature - Имя фичи или интеграции
   * @param appCreated - Дата созадния аппа
   */
  public hasAccess(feature: FEATURES, appCreated?: Moment.Moment): boolean {
    const appsFeaturesIncludesFeature = this.features.includes(feature);
    const appCreatedBeforeEndTimeOfFeature =
      !!appCreated &&
      appCreated.isBefore(moment(END_TIME_FEATURES[feature.toUpperCase() as keyof typeof END_TIME_FEATURES]));
    return environment.sandboxEnabled || appsFeaturesIncludesFeature || appCreatedBeforeEndTimeOfFeature;
  }
}

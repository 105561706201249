import { ChangeDetectionStrategy, Component, HostListener, Input, ViewEncapsulation } from '@angular/core';

import { App } from '@http/app/app.model';
import { TEAM_MEMBER_DEFAULT_AVATAR_URL } from '@http/team-member/team-member.constants';
import { ColorService } from '@panel/app/services/color/color.service';

@Component({
  selector: 'cq-carrot-notification-frame-sdk',
  templateUrl: './carrot-notification-frame.component.html',
  styleUrls: ['./carrot-notification-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CarrotNotificationFrameComponent {
  @Input({ required: true })
  app!: App;

  @Input({ required: true })
  content!: string;

  @Input({ required: true })
  language!: string;

  @Input({ required: true })
  sender!: any;

  @HostListener('click')
  onClick(event: Event): void {
    return event.preventDefault();
  }

  constructor(protected readonly colorService: ColorService) {}

  getRgbFromHex(hex: string): string | null {
    return this.colorService.getRgbFromHex(hex);
  }

  getSenderAvatarPath(): string {
    if (this.sender) {
      return this.sender.avatar;
    }

    return TEAM_MEMBER_DEFAULT_AVATAR_URL;
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[cqFloatNew]',
})
export class FloatDirective {
  private regex: RegExp = new RegExp(/^-?\d*[.,]?\d*$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      this.specialKeys.indexOf(event.key) !== -1 ||
      ((event.ctrlKey || event.metaKey) &&
        (event.key === 'a' || event.key === 'v' || event.key === 'c' || event.key === 'x'))
    ) {
      return;
    }

    const current: string = this.el.nativeElement.value;

    if (event.key === '.') {
      event.preventDefault();
      if (!current.includes(',')) {
        this.insertAtCursor(',');
      }
      return;
    }

    const next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (window as any)['clipboardData'];
    let pastedInput = clipboardData.getData('text');

    pastedInput = pastedInput.replace('.', ',');

    if (!String(pastedInput).match(this.regex) || pastedInput.split(',').length > 2) {
      event.preventDefault();
    } else {
      this.el.nativeElement.value = pastedInput;
      event.preventDefault();

      this.el.nativeElement.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = this.el.nativeElement;
    const value = input.value.replace('.', ',');
    if (value !== input.value) {
      input.value = value;

      input.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }

  private insertAtCursor(text: string) {
    const input = this.el.nativeElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    input.value = input.value.substring(0, start) + text + input.value.substring(end);
    input.selectionStart = input.selectionEnd = start + text.length;

    input.dispatchEvent(new Event('input', { bubbles: true }));
  }
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import cloneDeep from 'lodash-es/cloneDeep';
import extend from 'lodash-es/extend';
import filter from 'lodash-es/filter';
import isEqual from 'lodash-es/isEqual';
import uniq from 'lodash-es/uniq';
import Moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '@environment';
import {
  CHANNEL_STATISTICS_AGGREGATIONS,
  CHANNEL_STATISTICS_EXPORT_ENCODINGS,
  CHANNEL_STATISTICS_TYPES,
  CHANNEL_TYPES,
  PSEUDO_CHANNEL_IDS,
  PSEUDO_CHANNEL_TYPES,
} from '@http/channel/channel.constants';
import {
  ApiChannelStatisticResponse,
  ApiCreateChannelResponse,
  ApiGetChannelResponse,
  ApiGetCountersResponse,
} from '@http/channel/channel.types';
import { CaseStyleHelper } from '@panel/app/services';
import { FilterAjsModel } from '@panel/app/services/filter-ajs/filter-ajs.model';
import { LS_MUTED_CHANNELS } from '@panel/app/shared/constants/localstorage.keys';
import { isDefined } from '@panel/app/shared/functions/is-defended.function';
import { SKIP_APP_INTERCEPTOR } from '@panel/app/shared/interceptors/app/app.interceptor';
import { Modify } from '@panel/app/shared/types/modify.type';

enum CHANNEL_SETTING {
  REFERRER = 'referrer',
  USER_FILTERS = 'userFilters',
  EMAILS = 'emails',
  GROUPS = 'groups',
  PAGES = 'pages',
  ACCOUNT_PHONES = 'accountPhones',
  BOT_IDS = 'botIds',
}

// TODO Добавить описание для чего эти типы
type CHANNEL_SETTING_SHORT =
  | CHANNEL_SETTING.REFERRER
  | CHANNEL_SETTING.EMAILS
  | CHANNEL_SETTING.GROUPS
  | CHANNEL_SETTING.PAGES;

export type AppMutedChannels = { appId: string; mutedChannels: string[] };

export type Channel = {
  autoSet: boolean; // флаг включения попадания диалога в свой канал
  autoSetSettings: {
    // !!! необходимо добавить необязательность из-за возможности автосоздания каналов на беке,
    // !!! где auto_set_settings для каждого типа канала содержит разный набор атрибутов
    // !!! p.s. при создании канала на фронте так же auto_set_settings содержит не все ключи которые здесь перечислены
    // если просто доьавить необязательность, тоо можно умереть исправляя\добавляя проверки... нужен рефакторинг, что не входит в таску с инстой
    // TODO это произошло из-за портянок в шаблонах, которые разруливают эту необязательность - нарушение принципа единой ответственности
    [CHANNEL_SETTING.REFERRER]: {
      // NOTE настройка страницы начала диалога для своих каналов
      enabled: boolean; // флаг включения страницы начала диалога
      filters: {
        // страница начала диалога
        url: string;
        subpages: boolean;
      }[];
    };
    [CHANNEL_SETTING.USER_FILTERS]: {
      // NOTE настройка аудитории для своих каналов
      enabled: boolean; // флаг влючения аудитории
      // раньше вместо пустого массива была строка '{"type":"and","filters":[]}', поменял для того чтоб типы сходились
      filters: {
        type: 'and' | 'or';
        filters: {
          props: any[];
          events: any[];
          tags: any[];
        };
      }; // настройки для фильтра сегментов
    };
    [CHANNEL_SETTING.EMAILS]: {
      // NOTE настройка переадресации с почтовых адресов для почтовых каналов
      enabled: boolean; // флаг включения переадресации
      filters: string[]; // емейлы с которых происходит автоназначение
    };
    [CHANNEL_SETTING.PAGES]: {
      // NOTE настройка переадресации со страниц Facebook, для канала facebook
      enabled: boolean;
      filters: string[];
    };
    [CHANNEL_SETTING.GROUPS]: {
      // NOTE Настройка получения сообщения из сообществ ВК
      enabled: boolean;
      filters: string[];
    };
    [CHANNEL_SETTING.ACCOUNT_PHONES]: {
      enabled: boolean;
      filters: string[];
    };
    [CHANNEL_SETTING.BOT_IDS]: {
      enabled: boolean;
      filters: string[];
    };
  };
  avatar: string; // аватар
  droppable: boolean; // можно ли переносить в канал диалоги
  isPrior: boolean; // флаг создания канала с наивысшим приоритетом (true - после создания канала ему даётся самый высокий приоритет, false - самый низкий)
  id: string | null;
  name: string; // имя
  // TODO: тут и выше чекнуть типы которые лежат в массиве
  operators: any[]; // массив операторов
  priority: number; // приоритет канала
  readPermission: boolean; // можно ли читать канал
  type: string; // тип канала
  notAssignedCount?: number;
  notReadCount?: number;
};

export type ChannelNotificationSetting = {
  id: string;
  name: string;
  avatar: string;
  type: string;
  readPermission: boolean;
};

type ChannelSetting = Channel['autoSetSettings'][keyof Channel['autoSetSettings']]['filters'];

export type Counters = {
  notAssigned: { [key: string]: number };
  notRead: { [key: string]: number };
};

type ChannelStatistic = Modify<
  ApiChannelStatisticResponse[number],
  {
    channel: Channel;
    labels: string[];
  }
>[];

export type ParsedChannelStatistic = {
  data: number[];
  tableData: ChannelStatistic;
  labels: string[];
};

/**
 * Сервис для работы с каналами
 */
@Injectable({ providedIn: 'root' })
export class ChannelModel {
  /**
   * Канал по умолчанию
   */
  static DEFAULT_CHANNEL: Channel = {
    autoSet: false, // флаг включения попадания диалога в свой канал
    autoSetSettings: {
      referrer: {
        // NOTE настройка страницы начала диалога для своих каналов
        enabled: false, // флаг включения страницы начала диалога
        filters: [
          {
            // страница начала диалога
            url: '',
            subpages: true,
          },
        ],
      },
      userFilters: {
        // NOTE настройка аудитории для своих каналов
        enabled: false, // флаг влючения аудитории
        // раньше вместо пустого массива была строка , поменял для того чтоб типы сходились
        filters: {
          type: 'and',
          filters: {
            props: [],
            events: [],
            tags: [],
          },
        }, // настройки для фильтра сегментов
      },
      emails: {
        // NOTE настройка переадресации с почтовых адресов для почтовых каналов
        enabled: false, // флаг включения переадресации
        filters: [''], // емейлы с которых происходит автоназначение
      },
      groups: {
        // NOTE Настройка получения сообщения из сообществ ВК
        enabled: true,
        filters: [],
      },
      pages: {
        // NOTE настройка переадресации со страниц Facebook, для канала facebook
        enabled: false,
        filters: [],
      },
      accountPhones: {
        enabled: false,
        filters: [],
      },
      botIds: {
        enabled: false,
        filters: [],
      },
    },
    avatar: '', // аватар
    droppable: true, // можно ли переносить в канал диалоги
    isPrior: false, // флаг создания канала с наивысшим приоритетом (true - после создания канала ему даётся самый высокий приоритет, false - самый низкий)
    name: '', // имя
    operators: [], // массив операторов
    priority: 0, // приоритет канала
    readPermission: true, // можно ли читать канал
    type: '', // тип канала
    id: '',
  };

  /**
   * Счётчики непрочтённых сообщений и неназначенных диалогов для каждого канала
   */
  channelCounters: Counters = {
    notRead: {},
    notAssigned: {},
  };

  /**
   * Список загруженных в данный момент каналов
   * HACK counters: сделано это из-за того, что RTS присылает счётчики по каналам не учитывая разрешения пользователя на эти каналы.
   * Поэтому приходится проверять их руками в функции hasPermissions
   */
  channelsList: Channel[] = [];

  constructor(
    private readonly transloco: TranslocoService,
    private readonly cookieService: CookieService,
    private readonly http: HttpClient,
    private readonly caseStyleHelper: CaseStyleHelper,
    private readonly filterAjsModel: FilterAjsModel,
  ) {}

  addEmailInAutoSet(setting: Channel['autoSetSettings']['emails']['filters']) {
    return this.addItemInAutoSet(CHANNEL_SETTING.EMAILS, setting);
  }

  addPageInAutoSet(setting: Channel['autoSetSettings']['referrer']['filters']) {
    return this.addItemInAutoSet(CHANNEL_SETTING.REFERRER, setting);
  }

  addFacebookPageInAutoSet(setting: Channel['autoSetSettings']['pages']['filters']) {
    return this.addItemInAutoSet(CHANNEL_SETTING.PAGES, setting);
  }

  /**
   * Добавляет элемент в настройку автоназначения
   *
   * @param settingName имя настройки автоназначения
   * @param setting настройки, куда добавить элемент
   */
  addItemInAutoSet(settingName: CHANNEL_SETTING_SHORT, setting: ChannelSetting): void {
    const element = ChannelModel.DEFAULT_CHANNEL.autoSetSettings[settingName].filters[0];
    let itemToAdd: object | Array<any> | any;
    if (Array.isArray(setting)) {
      itemToAdd = cloneDeep(element);
      setting.push(itemToAdd);
    }
  }

  /**
   * Создание канала
   *
   * @param appId ID приложения
   * @param channel Канал
   */
  create(appId: string, channel: Channel): Observable<ApiCreateChannelResponse> {
    let autoSetSettings;

    if (channel.type === CHANNEL_TYPES.MANUAL) {
      autoSetSettings = {
        referrer: {
          enabled: channel.autoSetSettings.referrer.enabled,
          filters: this.caseStyleHelper.keysToUnderscore(
            channel.autoSetSettings.referrer.filters.filter(this.itemUrlExistsPredicate),
          ),
        },
        user_filters: {
          enabled: channel.autoSetSettings.userFilters.enabled,
          //@ts-ignore
          filters: this.filterAjsModel.parseToServerFormat(channel.autoSetSettings.userFilters.filters, false),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.EMAIL) {
      autoSetSettings = {
        emails: {
          enabled: channel.autoSetSettings.emails.enabled,
          filters: uniq(channel.autoSetSettings.emails.filters).filter(this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.FACEBOOK) {
      autoSetSettings = {
        pages: {
          enabled: true, // Note У facebook по умолчанию должен присутствовать фильтр
          filters: uniq(channel.autoSetSettings.pages.filters).filter(this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.INSTAGRAM) {
      autoSetSettings = {
        pages: {
          enabled: true, // Note У facebook по умолчанию должен присутствовать фильтр
          filters: uniq(channel.autoSetSettings.pages.filters).filter(this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.VK) {
      autoSetSettings = {
        groups: {
          enabled: true, // Note У VK по умолчанию должен присутствовать фильтр
          filters: uniq(channel.autoSetSettings.groups.filters).filter(this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.WHATS_APP) {
      autoSetSettings = {
        account_phones: {
          enabled: true, // Note У WhtasApp по умолчанию должен присутствовать фильтр
          filters: uniq(channel.autoSetSettings.accountPhones.filters).filter(this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.TELEGRAM) {
      autoSetSettings = {
        bot_ids: {
          enabled: true, // Note У Telegram может отсутствовать фильтр у старых каналов
          filters: channel.autoSetSettings.botIds
            ? uniq(channel.autoSetSettings.botIds.filters).filter(this.itemExistsPredicate)
            : [],
        },
      };
    }

    const body = {
      app: appId,
      auto_set: channel.type === CHANNEL_TYPES.MANUAL ? channel.autoSet : true,
      is_prior: channel.isPrior,
      name: channel.name,
      type: channel.type,
      operators: channel.operators,
      auto_set_settings: autoSetSettings,
    };

    return this.http.post<ApiCreateChannelResponse>('/channels', body);
  }

  /**
   * Получение канала по ID
   *
   * @param channelId ID канала
   */
  get(channelId: string): Observable<Channel> {
    return this.http.get<ApiGetChannelResponse>(`/channels/${channelId}`).pipe(
      map((response: ApiGetChannelResponse) => {
        // @ts-ignore игнор, потому что в результате keysToCamelCase типы не сходятся ни с чем
        this.parseChannel(response, true);
        return response as unknown as Channel;
      }),
    );
  }

  /**
   * Получение псевдоканала 'Все каналы'
   *
   */
  getAllChannelsPseudoChannel(): Channel {
    const allChannelsChannel = this.getDefaultChannel(PSEUDO_CHANNEL_TYPES.ALL_CHANNELS);

    allChannelsChannel.id = PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS];
    allChannelsChannel.name = this.transloco.translate('models.channel.pseudoChannelTypes.allChannels');
    if (allChannelsChannel.id) {
      allChannelsChannel.notAssignedCount = this.channelCounters.notAssigned[allChannelsChannel.id] || 0;
      allChannelsChannel.notReadCount = this.channelCounters.notRead[allChannelsChannel.id] || 0;
    }

    return allChannelsChannel;
  }

  static getChannelShortName(channelName: string): string {
    channelName = channelName.toUpperCase();
    if (channelName.indexOf(' ') !== -1) {
      const words = channelName.split(/[ ]+/);
      return words[0][0] + words[1][0];
    } else {
      return channelName.slice(0, 2);
    }
  }

  /**
   * Получение счётчиков непрочитанных и неотвеченных диалогов для всех каналов
   *
   * @param appId ID приложения
   */
  getCounters(appId: string): Observable<Counters> {
    // при новом получении счётчиков происходит сброс уже существующих
    // это помогает решить проблему старых счётчиков при переключении приложения
    // возникает она из-за того, что счётчики продолжают храниться в channelCounters,
    // их нужно сбрасывать вручную, и сброс было решено сделать здесь
    this.resetCounters();

    return this.http.get<ApiGetCountersResponse>(`/apps/${appId}/allchannelcounters`).pipe(
      map((response: ApiGetCountersResponse) => {
        this.caseStyleHelper.keysToCamelCase(response);

        // @ts-ignore игнор, потому что в результате keysToCamelCase типы не сходятся ни с чем
        this.setNotAssignCounter(response.notAssigned);
        // @ts-ignore игнор, потому что в результате keysToCamelCase типы не сходятся ни с чем
        this.setNotReadCounter(response.notRead);

        return this.channelCounters;
      }),
    );
  }

  /**
   * Получение канала по умолчанию с указанным типом
   *
   * @param channelType Тип канала
   */
  getDefaultChannel(channelType: PSEUDO_CHANNEL_TYPES | CHANNEL_TYPES): Channel {
    const defaultChannel = cloneDeep(ChannelModel.DEFAULT_CHANNEL);

    defaultChannel.type = channelType;

    return defaultChannel;
  }

  /**
   * Получение списка каналов для текущего сайта
   *
   * @param appId ID сайта
   * @param includeAllChannels Флаг добавления псевдоканала 'Все каналы' в список
   * @param includeWithoutChannel Флаг добавления псевдоканала 'Без канала' в список
   * @param ignoreLoadingBar Показывать бегунок в верху шапки
   */
  getList(
    appId: string,
    includeAllChannels?: boolean,
    includeWithoutChannel?: boolean,
    ignoreLoadingBar: boolean = false,
  ): Observable<Channel[]> {
    return this.http
      .get<Channel[]>(`/apps/${appId}/channels`, {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((channels: Channel[]) => {
          // HACK в этой переменной будут храниться отфильтрованные каналы,
          //  в зависимости от флагов includeAllChannels и includeWithoutChannel.
          //  Это сделано из-за того, что в channelsList должен всегда храниться полный список каналов,
          //  ключая все псевдоканалы, для корректного обновления счётчиков
          //  Но при этом возвращать функция должна список каналов с учётом флагов includeAllChannels, includeWithoutChannel
          let filteredChannels: Channel[] = [];

          channels.unshift(this.getWithoutChannelsPseudoChannel());
          channels.unshift(this.getAllChannelsPseudoChannel());

          // HACK counters: костылищи из-за того, что надо хранить channelsList для правильной работы счётчиков
          //  (RTS присылает счётчики без учёта разрешений на канал), при этом сохраняя на него ссылку
          this.channelsList.length = 0;
          extend(this.channelsList, channels);
          extend(filteredChannels, channels);

          // HACK: парсинг каналов делается после заполнения channelsList,
          //  т.к. в парсинге используется функция hasPermissions, которая внутри себя использует channelsList
          channels.forEach((channel) => {
            this.parseChannel(channel);
          });

          // убираем из отфильтрованного списка каналов псевдоканалы в зависимости от значения флагов
          if (!includeWithoutChannel) {
            filteredChannels = filteredChannels.filter(
              (channel) => channel.type !== PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL,
            );
          }

          if (!includeAllChannels) {
            filteredChannels = filteredChannels.filter((channel) => channel.type !== PSEUDO_CHANNEL_TYPES.ALL_CHANNELS);
          }

          return filteredChannels;
        }),
      );
  }

  /**
   * Получение списка каналов для текущего аппа в разделе "Оповещения"
   *
   * @param appId
   * @param ignoreLoadingBar
   */
  getNotificationSettingsChannels(
    appId: string,
    ignoreLoadingBar: boolean = false,
  ): Observable<ChannelNotificationSetting[]> {
    const params = {
      include_droppable: false,
      include_operators: false,
      include_not_assigned_count: false,
      include_not_read_count: false,
      include_auto_set: false,
      include_auto_set_settings: false,
      include_priority: false,
    };

    return this.http
      .get<ChannelNotificationSetting[]>(`/apps/${appId}/channels`, {
        params,
        context: new HttpContext().set(SKIP_APP_INTERCEPTOR, true).set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(
        map((channels) => {
          channels.unshift(this.getWithoutChannelsPseudoChannelNotificationSetting());

          return channels;
        }),
      );
  }

  /**
   * Получение списка каналов в которых выключены уведомления
   *
   * @param appId - Id аппа
   * @param djangoUserId - Id джанго юзера
   */
  getMutedChannels(appId: string, djangoUserId: string = '$self_django_user'): Observable<string[]> {
    let params = {
      app: appId,
      include_pinned_props: false,
      include_temp_data: false,
    };

    return this.http
      .get<{ [key: string]: string[] }>(`/django_users/${djangoUserId}/settings`, {
        params,
        context: new HttpContext().set(SKIP_APP_INTERCEPTOR, true),
      })
      .pipe(
        map((data) => {
          return data.mutedChannels || [];
        }),
        tap((mutedChannels: string[]) => {
          localStorage.setItem(LS_MUTED_CHANNELS, JSON.stringify(mutedChannels));
        }),
      );
  }

  /**
   * Получение списка каналов в которых выключены уведомления из LocalStorage
   */
  getMutedChannelsFromLocalStorage(): string[] {
    let resultFromLS = localStorage.getItem(LS_MUTED_CHANNELS);
    return resultFromLS ? JSON.parse(resultFromLS) : [];
  }

  /**
   * Получение статистики по каналам
   *
   * @param statType Данные, по которым получается статистика
   * @param appId ID сайта
   * @param startDate Дата начала периода
   * @param endDate Дата конца периода
   * @param channelsIds ID канала
   * @param aggregation Агрегация
   * @param exportAsCsv Нужно ли экспортировать полученные результаты в CSV
   * @param csvHeaders Массив массивов с заголовками для CSV-файла
   * @param csvDelimiter Разделитель записей в CSV
   * @param csvEncoding Кодировка CSV-файла
   */
  getStatistics(
    statType: string | undefined,
    appId: string,
    startDate: Moment.Moment,
    endDate: Moment.Moment,
    channelsIds: string[] | null[],
    aggregation: CHANNEL_STATISTICS_AGGREGATIONS = CHANNEL_STATISTICS_AGGREGATIONS.AVG,
    exportAsCsv: boolean = false,
    csvHeaders: string[] = [],
    csvDelimiter: string = ';',
    csvEncoding: CHANNEL_STATISTICS_EXPORT_ENCODINGS = CHANNEL_STATISTICS_EXPORT_ENCODINGS.WINDOWS_1251,
  ): Observable<ParsedChannelStatistic> | void {
    if (!isDefined(startDate)) {
      throw Error('startDate must be specified');
    }

    if (!isDefined(endDate)) {
      throw Error('endDate must be specified');
    }

    if (startDate > endDate) {
      throw Error('startDate must be lower than endDate');
    }

    const params: any = {
      end_date: endDate.format('YYYY-MM-DD'),
      speed_aggregate_function: aggregation || CHANNEL_STATISTICS_AGGREGATIONS.AVG,
      start_date: startDate.format('YYYY-MM-DD'),
    };

    if (statType) {
      params.stat_type = statType;
    }

    // если статистика запрашивается для псевдоканала "Все каналы" - на бэк ничего посылать не нужно, тогда он вернёт стату по всем каналам
    if (!(channelsIds.length === 1 && channelsIds[0] === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS])) {
      params.channel = channelsIds;
    }

    // FIXME APIRequest: В какой-то из веток APIRequest был сильно переписал, и эта штука не будет работать. Исправить при сливании с веткой, в которой изменён APIRequest
    if (exportAsCsv) {
      params.auth_token = 'appm-' + appId + '-' + this.cookieService.get('carrotquest_auth_token_panel');
      params.as_csv = exportAsCsv;
      params.csv_headers = JSON.stringify(csvHeaders);
      params.csv_delimiter = csvDelimiter || ';';
      params.csv_encoding = csvEncoding || CHANNEL_STATISTICS_EXPORT_ENCODINGS.UTF_8_SIG;

      window.open(
        environment.apiEndpoint + '/apps/' + appId + '/channels_stats?' + new URLSearchParams(params).toString(),
        '_blank',
      );
    } else {
      return this.http.get<ChannelStatistic>(`/apps/${appId}/channels_stats`, { params }).pipe(
        map((response) => {
          return this.parseStatistics(response);
        }),
      );
    }
  }

  /**
   * Получение псевдоканала 'Без канала'
   */
  getWithoutChannelsPseudoChannel(): Channel {
    const withoutChannelsChannel = this.getDefaultChannel(PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL);

    withoutChannelsChannel.id = PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL];
    withoutChannelsChannel.name = this.transloco.translate('models.channel.pseudoChannelTypes.withoutChannel');
    withoutChannelsChannel.notAssignedCount = this.channelCounters.notAssigned[withoutChannelsChannel.id] || 0;
    withoutChannelsChannel.notReadCount = this.channelCounters.notRead[withoutChannelsChannel.id] || 0;

    return withoutChannelsChannel;
  }

  /**
   * Получение псевдоканала 'Без канала'
   */
  getWithoutChannelsPseudoChannelNotificationSetting(): ChannelNotificationSetting {
    const withoutChannelsChannel = this.getDefaultChannel(PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL);

    withoutChannelsChannel.id = PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL];
    withoutChannelsChannel.name = this.transloco.translate('models.channel.pseudoChannelTypes.withoutChannel');
    withoutChannelsChannel.readPermission = true;

    return withoutChannelsChannel as ChannelNotificationSetting;
  }

  /**
   * Проверка прав на канал
   *
   * @param id ID индефикатор канала
   */
  hasPermissions(id: string): boolean {
    const isAccessAllowed = false;
    const filteredChannel = this.channelsList.find((channel) => channel.id === id);

    return filteredChannel ? filteredChannel.readPermission : isAccessAllowed;
  }

  /**
   * Проверяет элементы на пустоту
   */
  itemExistsPredicate(item: string): boolean {
    return !!item;
  }

  /**
   * Проверяет элементы на наличие поля url
   */
  itemUrlExistsPredicate(item: { url?: string }): boolean {
    return !!item.url;
  }

  /**
   * Парсинг канала
   */
  parseChannel(channel: Channel, onlyOperatorIds = false): void {
    this.setCountersFromChannel(channel);

    // HACK: парсинг каналов используется в статистике по каналам. При этом в статистике приходит псевдоканал 'Без канала',
    //  в котором нет ничего кроме названия и ID, поэтому нужна проверка на существование этих двух полей в канале
    if (channel.type === CHANNEL_TYPES.MANUAL) {
      if (channel.autoSetSettings.referrer) {
        if (!channel.autoSetSettings.referrer.filters.length) {
          this.addItemInAutoSet(CHANNEL_SETTING.REFERRER, channel.autoSetSettings.referrer.filters);
        }
      } else {
        channel.autoSetSettings.referrer = cloneDeep(ChannelModel.DEFAULT_CHANNEL.autoSetSettings.referrer);
      }

      if (channel.autoSetSettings.userFilters) {
        // если фильтр пустой, то присваиваем ему дефолтный, если нет, то приводим к виду с которой работает сегментация
        if (!isEqual({}, channel.autoSetSettings.userFilters.filters)) {
          // NOTE сегментция работает с андерскором и должна получать строку
          //channel.autoSetSettings.userFilters.filters = this.caseStyleHelper.keysToUnderscore(channel.autoSetSettings.userFilters.filters as unknown as object);
        } else {
          channel.autoSetSettings.userFilters.filters =
            ChannelModel.DEFAULT_CHANNEL.autoSetSettings.userFilters.filters;
        }
      } else {
        channel.autoSetSettings.userFilters = cloneDeep(ChannelModel.DEFAULT_CHANNEL.autoSetSettings.userFilters);
      }
    } else if (channel.type === CHANNEL_TYPES.EMAIL) {
      if (!channel.autoSetSettings.emails) {
        channel.autoSetSettings.emails = cloneDeep(ChannelModel.DEFAULT_CHANNEL.autoSetSettings.emails);
      } else if (!channel.autoSetSettings.emails.filters.length) {
        this.addItemInAutoSet(CHANNEL_SETTING.EMAILS, channel.autoSetSettings.emails.filters);
      }
    } else if ([CHANNEL_TYPES.FACEBOOK, CHANNEL_TYPES.INSTAGRAM].includes(channel.type as CHANNEL_TYPES)) {
      if (!channel.autoSetSettings.pages) {
        channel.autoSetSettings.pages = cloneDeep(ChannelModel.DEFAULT_CHANNEL.autoSetSettings.pages);
      } else if (!channel.autoSetSettings.pages.filters.length) {
        this.addItemInAutoSet(CHANNEL_SETTING.PAGES, channel.autoSetSettings.pages.filters);
      }
    } else if ([CHANNEL_TYPES.VK].includes(channel.type as CHANNEL_TYPES)) {
      // Под это условие попадут только те каналы, которые создавались после релиза фичи
      // с возможностью выбора сообществ, из которых будут поступать сообщения в канал
      if (!!channel.autoSetSettings.groups?.filters?.length) {
        this.addItemInAutoSet(CHANNEL_SETTING.GROUPS, channel.autoSetSettings.groups.filters);
      }
    }

    // эта штука тут нужна, чтоб избавляться от загрузки всех связей (потому что зачем?), хватит и айдишников
    if (onlyOperatorIds) {
      channel.operators = channel.operators.map((op) => op.id);
    }

    // NOTE: счётчики из канала удаляются специально, чтобы не было соблазна их читать напрямую из канала.
    //  Все актуальные счётчики хранятся в объекте channelCounters
    delete channel.notAssignedCount;
    delete channel.notReadCount;
  }

  /**
   * Парсинг статистики по каналам
   *
   * @param statistics Статистика по каналам
   */
  parseStatistics(statistics: ChannelStatistic): ParsedChannelStatistic {
    const labels: string[] = [];
    const data: number[] = [];
    const tableData = statistics;

    statistics.forEach((statistic) => {
      this.parseChannel(statistic.channel);

      // HACK: поскольку на бэкэнде не хранится канал 'Без канала', к тому же на бэке переводить его название не просто,
      //  то его нужно генерировать и парсить самому
      // функцию парсинга нужно вызывать перед генерированием канала, т.к. она считывает счётчики, присланные вместе с каналом.
      // Затем канал 'Без канала' генерируется и снова парсится
      if (statistic.channel.id === PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.WITHOUT_CHANNEL]) {
        statistic.channel = this.getWithoutChannelsPseudoChannel();
        this.parseChannel(statistic.channel);
      }

      labels.push(statistic.channel.name);
      data.push(statistic.stat.opened);
    });

    return {
      labels,
      data,
      tableData,
    };
  }

  /**
   * Удаление канала
   *
   * @param channelId ID канала
   */
  remove(channelId: string): Observable<unknown> {
    return this.http.delete<unknown>(`/channels/${channelId}`);
  }

  resetCounters(): void {
    let channelId;

    for (channelId in this.channelCounters.notAssigned) {
      if (this.channelCounters.notAssigned.hasOwnProperty(channelId)) {
        delete this.channelCounters.notAssigned[channelId];
      }
    }

    for (channelId in this.channelCounters.notRead) {
      if (this.channelCounters.notRead.hasOwnProperty(channelId)) {
        delete this.channelCounters.notRead[channelId];
      }
    }
  }

  /**
   * Сохранение канала
   *
   * @param channel Канал
   */
  save(channel: Channel): Observable<unknown> {
    let autoSetSettings;
    if (channel.type === CHANNEL_TYPES.MANUAL) {
      // В фильтрах канала могут быть удалённые теги. Необходимо их убрать перед отправкой на бэк,
      //  иначе свалится ошибка в модели фильтров
      channel.autoSetSettings.userFilters.filters.filters.tags =
        channel.autoSetSettings.userFilters.filters.filters.tags.filter((tag) => tag.tag);

      autoSetSettings = {
        referrer: {
          enabled: channel.autoSetSettings.referrer.enabled,
          filters: this.caseStyleHelper.keysToUnderscore(
            filter(channel.autoSetSettings.referrer.filters, this.itemUrlExistsPredicate),
          ),
        },
        user_filters: {
          enabled: channel.autoSetSettings.userFilters.enabled,
          //@ts-ignore
          filters: this.filterAjsModel.parseToServerFormat(channel.autoSetSettings.userFilters.filters, false),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.EMAIL) {
      autoSetSettings = {
        emails: {
          enabled: channel.autoSetSettings.emails.enabled,
          filters: filter(uniq(channel.autoSetSettings.emails.filters), this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.FACEBOOK) {
      autoSetSettings = {
        pages: {
          enabled: true, // Note У facebook по умолчанию должен присутствовать фильтр
          filters: filter(uniq(channel.autoSetSettings.pages.filters), this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.WHATS_APP) {
      autoSetSettings = {
        account_phones: {
          enabled: true, // Note У WhtasApp по умолчанию должен присутствовать фильтр
          filters: uniq(channel.autoSetSettings.accountPhones.filters).filter(this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.INSTAGRAM) {
      autoSetSettings = {
        pages: {
          enabled: true, // Note У facebook по умолчанию должен присутствовать фильтр
          filters: filter(uniq(channel.autoSetSettings.pages.filters), this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.VK && channel.autoSetSettings.groups) {
      autoSetSettings = {
        groups: {
          enabled: true, // Note У VK по умолчанию должен присутствовать фильтр
          filters: filter(uniq(channel.autoSetSettings.groups.filters), this.itemExistsPredicate),
        },
      };
    } else if (channel.type === CHANNEL_TYPES.TELEGRAM) {
      autoSetSettings = {
        bot_ids: {
          enabled: true, // Note У Telegram может отсутствовать фильтр у старых каналов
          filters: channel.autoSetSettings.botIds
            ? uniq(channel.autoSetSettings.botIds.filters).filter(this.itemExistsPredicate)
            : [],
        },
      };
    }

    const body = {
      auto_set: channel.type === CHANNEL_TYPES.MANUAL ? channel.autoSet : true,
      name: channel.name,
      type: channel.type,
      operators: channel.operators,
      auto_set_settings: autoSetSettings,
    };

    return this.http.put(`/channels/${channel.id}`, body);
  }

  /**
   * Перезаписывает счетчик заданного типа
   *
   * @param counterType Тип счётчика
   * @param counters Объект значений счётчика для каждого канала
   */
  setCounter(counterType: keyof Counters, counters: Counters[keyof Counters]): void {
    // среди всех счётчиков всех каналов вычленяем те, к которым у пользователя есть доступ
    // HACK counters: сделано это из-за того, что RTS присылает счётчики по каналам не учитывая разрешения пользователя на эти каналы.
    let allCounters = { ...this.channelCounters[counterType], ...counters };
    let permittedCounters = Object.fromEntries(
      Object.entries(allCounters).filter(([channelId, counter]) => this.hasPermissions(channelId)),
    );

    // считаем сумму счётчиков по всем каналам, предварительно обнулив её предыдущее значение
    permittedCounters[PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS] as unknown as string] = 0;
    permittedCounters[PSEUDO_CHANNEL_IDS[PSEUDO_CHANNEL_TYPES.ALL_CHANNELS] as unknown as string] = Object.values(
      permittedCounters,
    ).reduce((sum, value) => sum + value, 0);

    // чтобы в Angular всё было ок - счётчики должны быть иммутабельны, поэтому заменяем объект целиком
    this.channelCounters[counterType] = permittedCounters;
  }

  /**
   * Установка счётчиков для канала
   *
   * @param channel Канал
   */
  setCountersFromChannel(channel: any): void {
    const notAssignedCounter: any = {};
    const notReadCounter: any = {};

    notAssignedCounter[channel.id] = channel.notAssignedCount;
    notReadCounter[channel.id] = channel.notReadCount;

    this.setNotAssignCounter(notAssignedCounter);
    this.setNotReadCounter(notReadCounter);
  }

  /**
   * Установка счётчика неназначенных диалогов
   *
   * @param counters Объект значений счётчика для каждого канала
   */
  setNotAssignCounter(counters: Counters['notAssigned']): void {
    this.setCounter('notAssigned', counters);
  }

  /**
   * Установка счётчика непрочитанных сообщений
   *
   * @param counters Объект значений счётчика для каждого канала
   */
  setNotReadCounter(counters: Counters['notRead']): void {
    this.setCounter('notRead', counters);
  }

  /**
   * Установка приоритета каналу
   *
   * @param channelId ID канала
   * @param priority Приоритет
   */
  setPriority(channelId: string, priority: number): Observable<unknown> {
    const body = {
      priority,
    };

    return this.http.post(`/channels/${channelId}/priority`, body);
  }

  get statistics() {
    return {
      exportAsCsv: this.getStatistics.bind(this, undefined),
      getAnswerSpeedNotWorkingTime: this.getStatistics.bind(
        this,
        CHANNEL_STATISTICS_TYPES.ANSWER_SPEED_NOT_WORKING_TIME,
      ),
      getAnswerSpeedWorkingTime: this.getStatistics.bind(this, CHANNEL_STATISTICS_TYPES.ANSWER_SPEED_WORKING_TIME),
      getClosed: this.getStatistics.bind(this, CHANNEL_STATISTICS_TYPES.CLOSED),
      getClosedByUser: this.getStatistics.bind(this, CHANNEL_STATISTICS_TYPES.CLOSED_BY_USER),
      getDialogSize: this.getStatistics.bind(this, CHANNEL_STATISTICS_TYPES.DIALOG_SIZE),
      getLostCustomers: this.getStatistics.bind(this, CHANNEL_STATISTICS_TYPES.LOST_CUSTOMERS),
      getNotAnswered: this.getStatistics.bind(this, CHANNEL_STATISTICS_TYPES.NOT_ANSWERED),
      getOpened: this.getStatistics.bind(this, CHANNEL_STATISTICS_TYPES.OPENED),
      getOpenedByUser: this.getStatistics.bind(this, CHANNEL_STATISTICS_TYPES.OPENED_BY_USER),
    };
  }

  /**
   * Отключить/включить уведомления о событиях из канала
   *
   * @param mutedChannel - замьюченный канал
   * @param isMuted - замьючен ли канал
   * @param currentAppId - Текущий appId
   * @param djangoUserId - Id джанго юзера
   */
  toggleChannelNotification(
    mutedChannel: string,
    isMuted: boolean,
    currentAppId: string,
    djangoUserId: string,
  ): Observable<string[]> {
    let resultFromLS = localStorage.getItem(LS_MUTED_CHANNELS);
    let mutedChannels = resultFromLS ? JSON.parse(resultFromLS) : [];

    // Добавляем/удаляем из списка замьюченных каналов
    if (isMuted) {
      mutedChannels = mutedChannels.filter((x: string) => x !== mutedChannel);
    } else {
      mutedChannels.push(mutedChannel);
    }

    let body = {
      muted_channels: mutedChannels,
    };

    return this.http.patch<string[]>(`/django_users/${djangoUserId}/settings`, body).pipe(
      tap(() => {
        localStorage.setItem(LS_MUTED_CHANNELS, JSON.stringify(mutedChannels));
      }),
      map(() => mutedChannels),
    );
  }
}

/**
 * Фильтр, переводящий называния системных свойств событий
 * FIXME: удалить этот фильтр, когда все свойства будут обрабатываться в одном месте в модели
 */
(function () {
  'use strict';

  angular.module('myApp.filters.prettyEventPropName').filter('prettyEventPropName', prettyEventPropName);

  function prettyEventPropName(propertyModel) {
    return prettyEventPropNameFilter;

    /**
     * Получение названия свойства события
     *
     * @param {String} eventPropertyName Название свойства события
     * @returns {String}
     */
    function prettyEventPropNameFilter(eventPropertyName) {
      return propertyModel.parseEventPropertyName(eventPropertyName);
    }
  }
})();

<div class="product-feature-popover-content">
  <ng-content></ng-content>
</div>
<div
  class="full-height full-width position-absolute top-0 cursor-pointer"
  [ngbPopover]="popoverContent"
  [autoClose]="popoverAutoClose"
  [container]="popoverContainer"
  [disablePopover]="popoverDisable"
  [placement]="popoverPlacement"
  #activePopover="ngbPopover"
  popoverClass="product-feature-popover"
></div>

<ng-template #popoverContent>
  <div data-test="popover">
    <div class="flex align-items-baseline margin-bottom-10">
      <span class="font-weight-bold">{{ denialReasonTitleText }}</span>
      <button
        class="btn btn-text-primary margin-left-auto"
        type="button"
        (click)="activePopover.close()"
        data-test="close-popover"
      >
        <i class="btn-icon cqi-sm cqi-times"></i>
      </button>
    </div>
    <div class="margin-bottom-10">{{ denialReasonDescriptionText }}</div>
    <button
      class="btn btn-primary full-width"
      type="button"
      (click)="handleClickAction(currentApp, denialReason)"
    >
      <i class="btn-icon-left cqi-sm cqi-lightning"></i>
      <span>{{ denialReasonTexts.action }}</span>
    </button>
  </div>
</ng-template>

export enum DATA_SOURCE_STATUS {
  ALL = 'all',
  ACTIVE = 'active',
  STOPPED = 'stopped',
}
export const DATA_SOURCE_STATUS_FILTERED: DATA_SOURCE_STATUS[] = [
  DATA_SOURCE_STATUS.ACTIVE,
  DATA_SOURCE_STATUS.STOPPED,
];

export enum DATA_SOURCE {
  ALL = 'all',
  KNOWLEDGE_BASE = 'knowledge_base',
}
export const DATA_SOURCE_FILTERED: DATA_SOURCE[] = [DATA_SOURCE.KNOWLEDGE_BASE];

export const FILTER_DEFAULT_VALUES = {
  STATUS: DATA_SOURCE_STATUS.ALL,
  SOURCE: DATA_SOURCE.ALL,
  CATEGORY: '0',
  SEARCH_PHRASE: '',
};

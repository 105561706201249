import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { L10nHelperProvider } from '@panel/app/ajs-upgraded-providers';

import { IntegrationIconComponent } from './integration-icon.component';

@NgModule({
  declarations: [IntegrationIconComponent],
  exports: [IntegrationIconComponent],
  imports: [TranslocoModule],
  providers: [L10nHelperProvider],
})
export class IntegrationIconModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { ChatWidgetHeaderComponent } from '@panel/app/pages/widget-bot/editor/components/header/chat-widget-header.component';
import { SharedModule } from '@panel/app/shared/shared.module';

@NgModule({
  declarations: [ChatWidgetHeaderComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, ReactiveFormsModule],
  exports: [ChatWidgetHeaderComponent],
})
export class ChatWidgetHeaderModule {}

import { UntypedFormControl, ValidationErrors } from '@angular/forms';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

/** RegExp для проверка валидности URL */
export const URL_REGEXP =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

/**
 * Валидация URL
 *
 * @param control Контрол формы
 */
export function UrlValidator(control: GenericFormControl<string> | UntypedFormControl): ValidationErrors | null {
  const value = control.value;

  return !value?.match(URL_REGEXP) ? { url: value } : null;
}

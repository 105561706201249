import { AUDIENCE_FILTER_TYPE, MESSAGE_PAGE_TYPES } from '../../../../../../app/http/message/message.constants';

(function () {
  'use strict';

  angular.module('myApp.autoMessages').controller('CqAutoMessageAudienceController', CqAutoMessageAudienceController);

  function CqAutoMessageAudienceController(
    $scope,
    FILTER_SEGMENTS_PAGE_TYPE,
    carrotquestHelper,
    filterAjsModel,
    messageModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.changeHasFiltersSwitch = changeHasFiltersSwitch;
      vm.AUDIENCE_FILTER_TYPE = AUDIENCE_FILTER_TYPE;
      vm.collapseChange = collapseChange;
      vm.emptySettings = emptySettings;
      vm.FILTER_SEGMENTS_PAGE_TYPE = FILTER_SEGMENTS_PAGE_TYPE;
      vm.hasFilters = vm.currentMessage.isMessageHaveFilters;
      vm.isCollapsed = true; //Состояние аккордеона
      vm.isMessageHaveFilters = isMessageHaveFilters;
      vm.messagePageType = vm.messagePageType || MESSAGE_PAGE_TYPES.AUTO_MESSAGES; // Тип страницы, на которой размещен компонент, необходимо для корректного трекинга событий и переводов
      vm.messagePartTypeName = messageModel.getMessagePartTypeName(vm.currentMessage.parts);
      vm.trackClickExpandSettingsAudience = trackClickExpandSettingsAudience;
    }

    /**
     * Изменение значения свича фильтров
     * @param hasFilters
     */
    function changeHasFiltersSwitch(hasFilters) {
      $scope.$apply(() => {
        vm.currentMessage.isMessageHaveFilters = hasFilters;
      });
    }

    function collapseChange() {
      //Нужно чтобы отработало сразу
      $scope.$apply(() => {
        vm.isCollapsed = !vm.isCollapsed;
        vm.trackClickExpandSettingsAudience();
      });
    }

    /**
     * Состояние когда все настройки выключены
     * @returns {boolean}
     */
    function emptySettings() {
      return !(
        vm.currentMessage.isMessageHaveFilters ||
        vm.isMessageHaveFilters() ||
        vm.currentMessage.isControlGroupEnabled ||
        vm.currentMessage.userStatuses
      );
    }

    /**
     * Проверка наличия фильтров в триггерных сообщениях
     *
     * @returns {boolean}
     */
    function isMessageHaveFilters() {
      return (
        (vm.currentMessage.isMessageHaveFilters && filterAjsModel.isMessageHaveFilters(vm.currentMessage)) ||
        vm.currentMessage.jinjaFilterTemplate !== null
      );
    }

    /**
     * Трек клика 'Чат-бот - Настройка запуска - раскрыл настройки аудитории'
     */
    function trackClickExpandSettingsAudience() {
      if (vm.isCollapsed) {
        return;
      }
      if (vm.messagePageType === MESSAGE_PAGE_TYPES.CHAT_BOTS) {
        carrotquestHelper.track('Чат-бот - Настройка запуска - раскрыл настройки аудитории');
      } else {
        carrotquestHelper.track('Автосообщения - Настройка запуска - раскрыл настройки аудитории');
      }
    }
  }
})();

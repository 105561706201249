(function () {
  'use strict';

  require('./leads-import-finished.controller');

  angular.module('myApp.users').component('cqLeadsImportFinished', cqLeadsImportFinished());

  function cqLeadsImportFinished() {
    return {
      bindings: {
        currentApp: '=', // Текущее приложение
        importFinished: '<', // Флаг окончания импорта
        importTags: '<', // Импортируемые теги
        importType: '<', // Тип импорта
        importTypes: '<', // Типы импорта
        onTryAgainImport: '<', // Функция, срабатывающая при повторе импорта
        updatesPlanned: '<', // Количество лидов, которые будут загружены (приходит из бека)
      },
      controller: 'CqLeadsImportFinishedController',
      controllerAs: 'vm',
      template: require('./leads-import-finished.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { MomentModule } from 'ngx-moment';

import { SharedModule } from '@panel/app/shared/shared.module';

import { TriggerChainTableItemModule } from './../trigger-chain-table-item/trigger-chain-table-item.module';
import { TriggerChainTableComponent } from './trigger-chain-table.component';

@NgModule({
  declarations: [TriggerChainTableComponent],
  exports: [TriggerChainTableComponent],
  imports: [
    CommonModule,
    FormsModule,
    MomentModule,
    NgbPopoverModule,
    NgbTooltipModule,
    SharedModule,
    TranslocoModule,
    TriggerChainTableItemModule,
    UIRouterModule,
  ],
})
export class TriggerChainTableModule {}

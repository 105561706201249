import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { SearchComponent } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/components/search/search.component';
import { ManageTagsStore } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.store';
import { ConversationTag } from '@panel/app-old/shared/entities/conversation-tag/conversation-tag';
import { ConversationTagHelpService } from '@panel/app-old/shared/entities/conversation-tag/help/conversation-tag-help.service';
import { ConversationTagHttpService } from '@panel/app-old/shared/entities/conversation-tag/http/conversation-tag-http.service';
import { ArrayStream } from '@panel/app-old/shared/patterns/array-stream/array-stream';

/** Компонент для работы с тегами диалогов */
@Component({
  selector: 'cq-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTagsComponent {
  /** Фраза для поиска */
  searchPhrase: string | null = null;

  /** Теги */
  tags$: ArrayStream<ConversationTag> = this.manageTagsStore.tags$;

  @ViewChild(SearchComponent) search!: SearchComponent;

  constructor(
    private readonly conversationTagHelpService: ConversationTagHelpService,
    private readonly conversationTagHttpService: ConversationTagHttpService,
    private readonly manageTagsStore: ManageTagsStore,
  ) {
    this.initTags$();
  }

  /** Инициализирует переменную tags$ */
  initTags$(): void {
    this.conversationTagHttpService.getAll().then((tags) => {
      this.tags$.setAll(tags);
    });
  }

  /** Обрабатывает намеренье создать тег */
  onIntentToCreateTag(): void {
    this.conversationTagHelpService.startCreateTagProcess();
  }

  /**
   * Обрабатывает намеренье удалить тег
   *
   * @param tag - Тег
   */
  onIntentToRemoveTag(tag: ConversationTag): void {
    tag.startRemoveProcess();
  }

  /**
   * Обрабатывает поиск
   *
   * @param searchPhrase - Поисковая фраза
   */
  onSearch(searchPhrase: string): void {
    const regExp = new RegExp(searchPhrase);

    this.tags$.setFilter((tag) => regExp.test(tag.text));
  }

  /** Обрабатывает сброс поиска */
  onResetSearch(): void {
    this.search.resetSearchPhrase();

    this.tags$.resetFilters();
  }
}

<ng-container *transloco="let t">
  <div class="banner-container flex align-items-center justify-center bg-light-primary padding-10">
    <div
      class="margin-right-15 text-primary"
      [innerHTML]="t('newYearBannerComponent.title')"
    ></div>
    <a
      class="btn btn-outline-primary"
      [attr.href]="t('newYearBannerComponent.learnMoreLink.href')"
      target="_blank"
      >{{ t('newYearBannerComponent.learnMoreLink.text') }}</a
    >
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { PipesModule } from '@panel/app/shared/pipes/pipes.module';

import { MessageComponent } from './message.component';

@NgModule({
  declarations: [MessageComponent],
  imports: [CommonModule, PipesModule, TranslocoModule],
  exports: [MessageComponent],
})
export class MessageModule {}

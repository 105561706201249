(function () {
  'use strict';

  require('./service-installation.controller');

  angular.module('myApp.serviceInstallation').component('cqServiceInstallation', cqServiceInstallation());

  function cqServiceInstallation() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        djangoUser: '=', // текущий пользователь
      },
      controller: 'CqServiceInstallationController',
      controllerAs: 'vm',
      template: require('./service-installation.html'),
    };
  }
})();

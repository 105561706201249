<ng-container *transloco="let t; read: 'eventNameInputComponent'">
  <div>
    <div class="d-flex align-items-center mb-10">
      <strong>{{ t('heading.text') }}</strong>
    </div>
    <div [cqShowError]="control">
      <input
        class="form-control"
        [formControl]="control"
        placeholder="{{ t('input.placeholder') }}"
      />
      <cq-validation-messages [control]="control">
        <cq-validation-message errorName="required">{{ t('input.errors.required') }}</cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('input.errors.maxlength', { maxLength: AUTO_EVENT_NAME_MAX_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </div>
</ng-container>

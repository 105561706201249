import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BillingInfo } from '@panel/app/services/billing-info/billing-info.model';
import { App } from '@http/app/app.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-new-year-certificate-pay-button[billingInfo][billingPlanName][currentApp][payPeriod][uniqUsersCount]',
  templateUrl: './new-year-certificate-pay-button.component.html',
  styleUrls: ['./new-year-certificate-pay-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewYearCertificatePayButtonComponent {
  /** Текущий биллинг */
  @Input() billingInfo!: BillingInfo;
  /** Название тарифного плана */
  @Input() billingPlanName!: string;
  /** Текущий App */
  @Input() currentApp!: App;
  /** Период оплаты */
  @Input() payPeriod!: number;
  /** Количество уникальных пользователей */
  @Input() uniqUsersCount!: number;

  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly planVersionService: PlanVersionService,
    private readonly translocoService: TranslocoService,
  ) {}

  /** Является ли тарифный план версии V0421 */
  isV0421Plan(): boolean {
    return this.planVersionService.isV0421(this.currentApp);
  }

  /** Отправление сообщения в чат */
  sendChatMessage() {
    let message = this.translocoService.translate('newYearCertificatePayButtonComponent.message', {
      billingPlanName: this.billingPlanName,
      uniqUsersCount: this.uniqUsersCount,
      payPeriod: this.payPeriod,
      version: this.isV0421Plan() ? '(V0421)' : '',
    });

    this.carrotquestHelper.sendChatMessage(message);
  }
}

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MESSAGE_PART_TYPES, POPUP_REPLY_TYPES, RECIPIENT_TYPES } from '@http/message-part/message-part.constants';
import { READY_MESSAGE_TEMPLATE_SCOPES_OF_USE } from '@http/ready-message-template/ready-message-template.constants';
import {
  ApiReadyMessageTemplateList,
  ApiReadyMessageTemplateResponse,
} from '@http/ready-message-template/ready-message-template.types';
import { APP_USAGE_GOAL } from '@http/starter-guide/starter-guide.types';
import { TemplateDirectoryModel } from '@http/template-directory/template-directory.model';
import { POPUP_BUTTON_BLOCK_TYPES } from '@panel/app/services/popup-block/popup-block.constants';
import { EXTENDED_RESPONSE } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class ReadyMessageTemplateModel {
  constructor(private readonly http: HttpClient, private readonly templateDirectoryModel: TemplateDirectoryModel) {}

  /**
   * Фильтрация шаблонов
   * NOTE : Не показывать шаблоны в которых есть подписка на Web Push.
   * NOTE Роман Е. Модифицировать стандартные типы в SDK-типы, чтобы получить акутальные SDK-типам шаблоны.
   *
   * @param templates - Шаблоны
   * @returns - Шаблоны
   */
  filterTemplate(templates: any) {
    for (let i = templates.length - 1; i >= 0; i--) {
      if (!!~[MESSAGE_PART_TYPES.BLOCK_POPUP_BIG, MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL].indexOf(templates[i].type)) {
        let parsedBodyJson = JSON.parse(templates[i].bodyJson);
        //@ts-ignore
        let flattenBlocks = this.$filter('flatten')(parsedBodyJson.blocks);
        if (
          flattenBlocks.filter(
            (block: any) => block.params && block.params.type === POPUP_BUTTON_BLOCK_TYPES.PUSH_SUBSCRIPTION,
          ).length > 0
        ) {
          templates.splice(i, 1);
        }
      } else if (
        !!~[MESSAGE_PART_TYPES.POPUP_BIG, MESSAGE_PART_TYPES.POPUP_SMALL].indexOf(templates[i].type) &&
        templates[i].replyType === POPUP_REPLY_TYPES.PUSH
      ) {
        templates.splice(i, 1);
      } else if (templates[i].recipient_type === RECIPIENT_TYPES.SDK) {
        templates[i].type = RECIPIENT_TYPES.SDK + '_' + templates[i].type;
      }
    }
    return templates;
  }

  /**
   * Получение готового шаблона
   *
   * @param templateId - ID шаблона
   */
  get(templateId: string) {
    return this.http.get<any>('/readymessagetemplates/' + templateId).pipe(
      map((data) => {
        this.parse(data);
        return data;
      }),
    );
  }

  /**
   * Получение дефолтного шаблона
   */
  getDefault() {
    return {
      active: true,
      body: '',
      bodyJson: '',
      directory: this.templateDirectoryModel.getWithoutDirectory(),
      id: '',
      name: '',
      pureHtml: false,
      replyType: 'text',
      screenShot: null,
      subject: '',
      type: MESSAGE_PART_TYPES.POPUP_CHAT,
    };
  }

  /**
   * Получение списка шаблонов сообщений
   *
   * @param appId - ID аппа
   * @param messagePartTypes - Типы сообщений
   * @param scopeOfUse Сфера использования
   * @param paginationEnabled - Флаг отключения пагинации (при отключении вернет все шаблоны)
   * @param paginatorParams - Параметры пагинации
   * @param searchPhrase
   * @param taskType тип задачи выполняемой нашим аппом
   *
   * @returns {Promise}
   */
  getList(
    appId: string,
    messagePartTypes: string | string[],
    scopeOfUse: READY_MESSAGE_TEMPLATE_SCOPES_OF_USE,
    paginationEnabled: boolean = true,
    paginatorParams?: any,
    searchPhrase?: string,
    taskType: APP_USAGE_GOAL | null = null,
  ): Observable<ApiReadyMessageTemplateList> {
    const {
      paginateDirection = 'before',
      paginateCount = 20,
      paginateIncluding = false,
      paginatePosition = [],
      paginatePageOrder = 'desc',
    } = paginatorParams ?? {};

    const messagePartTypesArray = parseMessagePartTypes(messagePartTypes);

    const params: any = {
      app_type: scopeOfUse === READY_MESSAGE_TEMPLATE_SCOPES_OF_USE.ANY ? null : scopeOfUse,
      task_type: taskType,
      pagination_enabled: paginationEnabled,
      name_contains: searchPhrase || null,
      paginatorParams: {
        paginate_direction: paginateDirection,
        paginate_count: paginateCount,
        paginate_including: paginateIncluding,
        paginate_position: paginatePosition.join() || null,
        paginate_page_order: paginatePageOrder,
      },
    };

    // HACK Роман Е. SDK-типы - это не отдельные типы шаблонов сообщений на backend, а те же самые (кроме Push в SDK).
    // HACK SDK-типы отличаются на backend от обычных по значению recipient_type = sdk|web.
    // HACK Получаем шаблоны, ориентируясь на значение recipient_type.
    for (let i = 0; i < messagePartTypesArray.length; i++) {
      // По умолчанию считаем, что нам нужны шаблоны с обычынми типами
      params.recipient_type = RECIPIENT_TYPES.WEB;

      // Если нам нужны шаблоны с SDK-типами
      if (
        !!~[
          MESSAGE_PART_TYPES.SDK_POPUP_CHAT,
          MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL,
          MESSAGE_PART_TYPES.SDK_PUSH,
        ].indexOf(messagePartTypesArray[i] as MESSAGE_PART_TYPES)
      ) {
        params.recipient_type = RECIPIENT_TYPES.SDK;
      }

      // Маскируем SDK-тип для backend (кроме Push в SDK)
      if (messagePartTypesArray[i] !== MESSAGE_PART_TYPES.SDK_PUSH) {
        messagePartTypesArray[i] = messagePartTypesArray[i].replace(RECIPIENT_TYPES.SDK + '_', '');
      }
    }

    params.types = !!~messagePartTypesArray.indexOf(MESSAGE_PART_TYPES.ALL)
      ? null
      : JSON.stringify(messagePartTypesArray);

    return this.http
      .get<any>('/readymessagetemplates', { params, context: new HttpContext().set(EXTENDED_RESPONSE, true) })
      .pipe(
        map((response) => {
          const templates = response.data;

          for (let i = 0; i < templates.length; i++) {
            this.parse(templates);
          }

          return {
            templates: templates,
            paginatorParams: {
              paginateDirection,
              paginateCount,
              paginateIncluding,
              paginatePageOrder,
              paginatePosition: response.meta.nextBeforePosition,
            },
          };
        }),
      );

    function parseMessagePartTypes(messagePartTypes: string | string[]) {
      const array = [];

      if (angular.isString(messagePartTypes)) {
        array.push(messagePartTypes);
        return array;
      } else {
        return messagePartTypes;
      }
    }
  }

  /**
   * Парсинг готовых шаблонов
   * NOTE: вместо этой функции должна быть фукнция преобразования готового шаблона к обычному
   */
  parse(readyTemplate: ApiReadyMessageTemplateResponse) {
    readyTemplate.active = false;
    readyTemplate.directory = this.templateDirectoryModel.getWithoutDirectory();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@panel/app/shared/shared.module';

import { WizardComponent } from './wizard.component';

@NgModule({
  declarations: [WizardComponent],
  exports: [WizardComponent],
  imports: [CommonModule, SharedModule],
})
export class WizardModule {}

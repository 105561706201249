(function () {
  'use strict';

  require('./double-opt-in-subscription-confirm-preview.controller');

  angular
    .module('myApp.privacySettings')
    .component('cqDoubleOptInSubscriptionConfirmPreview', cqDoubleOptInSubscriptionConfirmPreview());

  function cqDoubleOptInSubscriptionConfirmPreview() {
    return {
      bindings: {
        currentApp: '=', // приложение
        logo: '=', // логотип
      },
      controller: 'CqDoubleOptInSubscriptionConfirmPreviewController',
      controllerAs: 'vm',
      template: require('./double-opt-in-subscription-confirm-preview.html'),
    };
  }
})();

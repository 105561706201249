<ng-container *transloco="let t; read: 'companyNameControlComponent'">
  <div [cqShowError]="control">
    <label for="companyName">{{ t('label') }}</label>
    <input
      class="form-control"
      [formControl]="control"
      [placeholder]="t('input.placeholder')"
      id="companyName"
    />

    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('input.errors.required') }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

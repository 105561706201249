import { Injectable, InjectionToken, Injector, StaticProvider } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export type Newable<T> = { new (...args: any[]): T };

/**
 * Смысл сервиса в том, чтоб открывать модалку и удобно передавать данные через DI Токены,
 * так как стандартный синтаксис ngbModal не очень удобный.
 *
 * Пример:
 *
 * modalHelperService
 *   .provide(TOKEN1, token1Value)
 *   .provide(TOKEN2, token2Value)
 *   .open(ModalComponent, anyOptions)
 */
@Injectable({
  providedIn: 'root',
})
export class ModalHelperService {
  private providers: StaticProvider[] = [];

  constructor(private readonly ngbModal: NgbModal) {}

  /**
   * В качестве значения provide и value можно передавать все, что принмает обычный провайдер (см. доку DI)
   * Если передать в качестве provide типизированный InjectionToken, то тип value будет проверяться тайпскриптом
   */
  provide<T = any>(provide: InjectionToken<T>, value: T): this;
  provide<T>(provide: Newable<T>, value: T): this;
  provide(provide: any, value: any): this {
    this.providers.push({
      provide,
      useValue: value,
    });
    return this;
  }

  /**
   * Полностью повторяет вызов ngbModal.open, но убирает из option injector, так как провайдеры передаются методом выше.
   * Предыдущий список провайдеров чистится для случая, когда из одной модалки вызывается другая.
   * Сервис синглтон - если не почистить, то провайдеры будут общими.
   */
  open(content: any, options: Omit<NgbModalOptions, 'injector'> = {}): NgbModalRef {
    const providers = [...this.providers];
    this.providers = [];

    return this.ngbModal.open(content, {
      injector: Injector.create({
        providers,
      }),
      ...options,
    });
  }
}

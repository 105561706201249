(function () {
  'use strict';

  angular.module('myApp.filters.toUnderscore').filter('cqToUnderscore', cqToUnderscore);

  function cqToUnderscore(caseStyleHelper) {
    return toUnderscoreFilter;

    /**
     * Преобразование строки в underscore
     *
     * @param {String} value Строка для преобразования
     * @returns {String}
     */
    function toUnderscoreFilter(value) {
      return caseStyleHelper.toUnderscore(value);
    }
  }
})();

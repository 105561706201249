<ng-container *transloco="let t">
  <div class="row margin-bottom-30">
    <div class="col-12 col-xl-6">
      <div [innerHTML]="t('dataCollectionSassComponent.description')"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <h4>
        <a
          [href]="t('dataCollectionSassComponent.dataViaWebApi.headingLink.href')"
          target="_blank"
          >{{ t('dataCollectionSassComponent.dataViaWebApi.headingLink.text') }}</a
        >
      </h4>
      <div class="margin-bottom-15">{{ t('dataCollectionSassComponent.dataViaWebApi.description') }}</div>
      <ol class="list-round">
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.userIdTransferLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.userIdTransferLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.authorizationPropertiesLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.authorizationPropertiesLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.userDataPropertiesLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.userDataPropertiesLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.propertiesTransferLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.propertiesTransferLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.paidOrderLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.paidOrderLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.lastOrderAmountLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.lastOrderAmountLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.userProfitLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.userProfitLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.orderAmountLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.orderAmountLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.linkList.uploadUsersLink.href')"
            target="_blank"
            translate=""
            >{{ t('dataCollectionSassComponent.dataViaWebApi.linkList.uploadUsersLink.text') }}</a
          >
        </li>
      </ol>

      <hr class="margin-bottom-30 margin-top-30" />

      <div class="row">
        <div class="col-12 col-xl-7">
          <a
            [href]="t('dataCollectionSassComponent.dataViaWebApi.developerApiLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.dataViaWebApi.developerApiLink.text') }}</a
          >
        </div>
      </div>
    </div>

    <div class="col-6">
      <h4>{{ t('dataCollectionSassComponent.trackMaster.heading') }}</h4>
      <div class="margin-bottom-15">{{ t('dataCollectionSassComponent.trackMaster.description') }}</div>
      <ol class="list-round">
        <li>
          <a
            [href]="t('dataCollectionSassComponent.trackMaster.linkList.visitToPageLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.trackMaster.linkList.visitToPageLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.trackMaster.linkList.clickOnElementLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.trackMaster.linkList.clickOnElementLink.text') }}</a
          >
        </li>
        <li>
          <a
            [href]="t('dataCollectionSassComponent.trackMaster.linkList.inputFieldLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.trackMaster.linkList.inputFieldLink.text') }}</a
          >
        </li>
      </ol>

      <hr class="margin-bottom-30 margin-top-30" />

      <div class="row">
        <div class="col-12 col-xl-7">
          <a
            [href]="t('dataCollectionSassComponent.trackMaster.knowledgeBaseLink.href')"
            target="_blank"
            >{{ t('dataCollectionSassComponent.trackMaster.knowledgeBaseLink.text') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>

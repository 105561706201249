import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { KnowledgeBasePageComponent } from '@panel/app/pages/knowledge-base/knowledge-base-page.component';
import { KNOWLEDGE_BASE_PAGE_ROUTES } from '@panel/app/pages/knowledge-base/knowledge-base-page.routes';
import { ArticleEditModule } from '@panel/app/pages/knowledge-base/pages/article-edit/article-edit.module';
import { KnowledgeBaseActivateWrapperModule } from '@panel/app/pages/knowledge-base/pages/knowledge-base-activate-wrapper/knowledge-base-activate-wrapper.module';
import { KnowledgeBaseArticlesModule } from '@panel/app/pages/knowledge-base/pages/knowledge-base-articles/knowledge-base-articles.module';
import { KnowledgeBaseCategoriesModule } from '@panel/app/pages/knowledge-base/pages/knowledge-base-categories/knowledge-base-categories.module';
import { ArticlePreviewModule } from '@panel/app/pages/knowledge-base/shared/components/article-preview/article-preview.module';
import { ArticlePreviewModalModule } from '@panel/app/pages/knowledge-base/shared/components/article-preview-modal/article-preview-modal.module';
import { ArticlesCategoryEditorModalModule } from '@panel/app/pages/knowledge-base/shared/components/articles-category-editor-modal/articles-category-editor-modal.module';
import { ArticlesLimitationBannerModule } from '@panel/app/pages/knowledge-base/shared/components/articles-limitation-banner/articles-limitation-banner.module';
import { ArticlesListModule } from '@panel/app/pages/knowledge-base/shared/components/articles-list/articles-list.module';
import { ArticlesSearchModule } from '@panel/app/pages/knowledge-base/shared/components/articles-search/articles-search.module';
import { CategoryListModule } from '@panel/app/pages/knowledge-base/shared/components/category-list/category-list.module';
import { KnowledgeBaseModule } from '@panel/app/pages/knowledge-base/shared/components/knowledge-base/knowledge-base.module';
import { KnowledgeBaseActivateModule } from '@panel/app/pages/knowledge-base/shared/components/knowledge-base-activate/knowledge-base-activate.module';
import { KnowledgeBasePopoverModule } from '@panel/app/pages/knowledge-base/shared/components/knowledge-base-popover/knowledge-base-popover.module';
import { KnowledgeBaseSettingsModalModule } from '@panel/app/pages/knowledge-base/shared/components/knowledge-base-settings-modal/knowledge-base-settings-modal.module';
import { LimitReachedModalModule } from '@panel/app/pages/knowledge-base/shared/components/limit-reached-modal/limit-reached-modal.module';
import { SetArticleCategoryModalModule } from '@panel/app/pages/knowledge-base/shared/components/set-article-category-modal/set-article-category-modal.module';

@NgModule({
  declarations: [KnowledgeBasePageComponent],
  exports: [KnowledgeBasePageComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    KnowledgeBaseCategoriesModule,
    KnowledgeBaseArticlesModule,
    KnowledgeBaseActivateWrapperModule,
    ArticleEditModule,
    UIRouterUpgradeModule.forChild({ states: KNOWLEDGE_BASE_PAGE_ROUTES }),
    KnowledgeBaseSettingsModalModule,
    LimitReachedModalModule,
    KnowledgeBasePopoverModule,
    KnowledgeBaseActivateModule,
    KnowledgeBaseModule,
    CategoryListModule,
    ArticlesSearchModule,
    ArticlesListModule,
    ArticlesLimitationBannerModule,
    ArticlePreviewModalModule,
    ArticlePreviewModule,
    ArticlesCategoryEditorModalModule,
    SetArticleCategoryModalModule,
  ],
})
export class KnowledgeBasePageModule {}

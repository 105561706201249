import { InjectionToken } from '@angular/core';

import { STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE } from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.types';

/**
 * Тип данных, передаваемых в модалку с превью интеграции в стартергайде
 */
export type LeadsPassingPreviewModalData = {
  /** Заголовок модалки */
  heading: string;
  /** Значение редактируемого свойства */
  integrationType: STARTER_GUIDE_LEADS_PASSING_INTEGRATION_TYPES_TYPE;
};

/**
 * Токен для передачи данных через DI в модалку с превью интеграции в стартергайде
 */
export const LEADS_PASSING_PREVIEW_MODAL_DATA_TOKEN = new InjectionToken<LeadsPassingPreviewModalData>(
  'LeadsPassingPreviewModal data provider',
);

<div class="container-fluid limited-container padding-bottom-60 margin-bottom-60">
  <div class="flex align-items-center no-flex-shrink">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item active"
          aria-current="page"
        >
          <div>
            <span>{{ 'autoMessages.breadcrumbs.title' | transloco }}</span>
            <i
              class="cqi-sm cqi-question-circle"
              [ngbTooltip]="'autoMessages.table.tooltip' | transloco"
              container="body"
              placement="bottom"
            ></i>
          </div>
        </li>
      </ol>
    </nav>
    <div class="flex align-items-center margin-left-auto position-relative">
      <!-- Рекомендованные сообщения -->
      <button
        *ngIf="accessToReadyAutoMessage.hasAccess"
        class="btn btn-text-primary"
        (click)="openReadyAutoMessagesModal(); trackOpenRecommendedTemplates()"
      >
        <i class="btn-icon-left cqi-sm cqi-star"></i>
        <span data-test="trigger-messages-ready-made-templates-button">{{
          'autoMessages.table.recommendedMessagesButtonNew' | transloco
        }}</span>
      </button>
      <button
        *ngIf="!accessToReadyAutoMessage.hasAccess"
        class="btn btn-text-primary"
        type="button"
        (click)="paywallService.showPaywall(currentApp, accessToReadyAutoMessage.denialReason)"
      >
        <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
        <span>{{ 'autoMessages.table.recommendedMessagesButtonNew' | transloco }}</span>
      </button>

      <!-- Шаблоны -->
      <a
        *ngIf="accessToMessagesTemplates.hasAccess"
        class="btn btn-text-primary margin-left-20"
        uiSref="app.content.messagesAjs.templates"
      >
        <i class="btn-icon-left cqi-sm cqi-templates"></i>
        <span>{{ 'autoMessages.table.messageTemplatesButton' | transloco }}</span>
      </a>
      <button
        *ngIf="!accessToMessagesTemplates.hasAccess"
        class="btn btn-text-primary margin-left-20"
        type="button"
        (click)="paywallService.showPaywall(currentApp, accessToMessagesTemplates.denialReason)"
      >
        <cq-plan-capability-coin class="btn-icon-left"></cq-plan-capability-coin>
        <span>{{ 'autoMessages.table.messageTemplatesButton' | transloco }}</span>
      </button>

      <div class="bordered-left margin-left-20 padding-top-25"></div>

      <!-- Обучающие статьи -->
      <a
        class="btn btn-text-primary margin-left-20"
        target="_blank"
        (click)="trackClickOnKnowledgeBaseInDescription()"
        [href]="'autoMessages.table.helpArticlesLink.href' | transloco"
      >
        <i class="btn-icon-left cqi-sm cqi-book"></i>
        <span>{{ 'autoMessages.table.helpArticlesLink.text' | transloco }}</span>
      </a>
      <!-- Создать сообщение -->
      <a
        class="btn btn-primary margin-left-30"
        uiSref="app.content.messages.auto.create"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        <span>{{ 'autoMessages.table.createAutoMessageButton' | transloco }}</span>
      </a>
    </div>
  </div>
  <div class="card position-sticky">
    <div class="card-header sticky-top">
      <div
        *ngIf="!selectedMessages.length && !selectedMessagesAll"
        class="flex justify-space-between"
      >
        <cq-auto-message-status-filter
          [currentDirectory]="currentDirectory"
          [currentStatus]="currentStatus"
          (changeStatus)="onChangeStatus($event)"
        ></cq-auto-message-status-filter>

        <div class="flex flex-grow align-items-center justify-end">
          <cq-directory-filter
            class="message-directory"
            [currentApp]="currentApp"
            [currentDirectoryId]="currentDirectory.id"
            (changeDirectory)="onChangeDirectory($event)"
          ></cq-directory-filter>

          <cq-auto-message-type-filter
            class="message-part-type"
            [currentApp]="currentApp"
            [currentType]="currentType"
            (changeType)="onChangeType($event)"
          ></cq-auto-message-type-filter>

          <!--ОБНОВЛЕНИЕ СПИСКА-->
          <button
            class="btn btn-outline-primary"
            (click)="trackClickRefresh(); refreshMessages(currentType, currentStatus, currentDirectory)"
          >
            <i class="btn-icon cqi-sm cqi-refresh-2"></i>
          </button>
        </div>
      </div>

      <cq-mass-action-panel
        [accessToSelectedAutoMessages]="accessToSelectedAutoMessages"
        [accessToSelectedTelegramAutoMessages]="accessToSelectedTelegramAutoMessages"
        [activeMessagesAmounts]="activeMessagesAmounts"
        [currentApp]="currentApp"
        [currentDirectory]="currentDirectory"
        [currentStatus]="currentStatus"
        [messagesPaginator]="messagesPaginator"
        [selectedMessages]="selectedMessages"
        [selectedMessagesAll]="selectedMessagesAll"
        (clickActivateButton)="changeMessageStatus(true, $event)"
        (clickArchiveButton)="archiveOrReestablishMessage(false, $event)"
        (clickChangeDirectoryButton)="openSetDirectoryModal($event)"
        (clickCopyButton)="createCopy($event)"
        (clickPauseButton)="changeMessageStatus(false, $event)"
        (clickReestablishButton)="archiveOrReestablishMessage(true, $event)"
      ></cq-mass-action-panel>
    </div>

    <cq-auto-message-table
      [accessToSelectedAutoMessages]="accessToSelectedAutoMessages"
      [accessToTelegramAutoMessages]="accessToTelegramAutoMessages"
      [activeMessagesAmounts]="activeMessagesAmounts"
      [appBlocks]="appBlocks"
      [currentApp]="currentApp"
      [isAllMessageSelected]="selectedMessagesAll"
      [messages]="messages"
      (clickActivateButton)="changeMessageStatus(true, $event)"
      (clickArchiveButton)="archiveOrReestablishMessage(false, $event)"
      (clickChangeDirectoryButton)="openSetDirectoryModal($event)"
      (clickCopyButton)="createCopy($event)"
      (clickPauseButton)="changeMessageStatus(false, $event)"
      (clickReestablishButton)="archiveOrReestablishMessage(true, $event)"
      (clickRenameButton)="openRenameModal($event)"
      (selectAllMessage)="setSelectForAllMessages($event)"
      (selectMessage)="refreshSelectedMessages()"
    ></cq-auto-message-table>

    <div
      *ngIf="messagesPaginator?.paginatePosition && !isMessagesRequestPerformed"
      class="card-body text-center"
    >
      <button
        class="btn btn-outline-primary"
        type="button"
        (click)="getMessages(currentType, currentStatus, currentDirectory, false)"
      >
        {{ 'general.showMore' | transloco }}
      </button>
    </div>
    <div
      *ngIf="messages.length === 0 && !isMessagesRequestPerformed"
      class="card-body text-center"
    >
      <cq-zero-data class="margin-bottom-10">
        <span>{{ 'autoMessages.table.table.body.zeroData.notHaveActiveAutoMessages' | transloco }}</span>
      </cq-zero-data>
      <a
        class="btn btn-primary"
        uiSref="app.content.messagesAjs.create"
        >{{ 'autoMessages.table.table.body.zeroData.createAutoMessageButton' | transloco }}
      </a>
    </div>
  </div>
</div>
<cq-loader
  *ngIf="isMessagesRequestPerformed"
  class="content-loader"
></cq-loader>

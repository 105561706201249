(function () {
  'use strict';

  angular.module('myApp.integrations').controller('JsScriptController', JsScriptController);

  function JsScriptController(
    $state,
    $scope,
    $translate,
    toastr,
    PROJECT_NAME,
    integrationModel,
    validationHelper,
    currentApp,
    integration,
  ) {
    var vm = this;

    vm.aceOptions = {
      theme: 'monokai',
      mode: 'javascript',
      fontSize: '16px',
      highlightActiveLine: false,
      printMarginColumn: 100,
    };
    vm.createOrUpdateIntegration = createOrUpdateIntegration;
    vm.integration = integration;
    vm.isManualExpanded = angular.isUndefined(integration.id); // раскрыта ли инструкция
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.toggleManualVisibility = toggleManualVisibility;
    vm.validationHelper = validationHelper;
    vm.applyChanges = () => $scope.$applyAsync();

    /**
     * Сохранение/обновление интеграции
     *
     * @param {Boolean} isValid Флаг валидности формы
     */
    function createOrUpdateIntegration(isValid) {
      if (isValid) {
        if (integration.id) {
          integrationModel.save(currentApp.id, integration).then(saveSuccess);
        } else {
          integrationModel.create(currentApp.id, integration).then(createSuccess);
        }
      }

      function createSuccess(integration) {
        var params = {
          integrationType: integration.type,
          integrationId: integration.id,
        };

        var transitionParams = {
          location: 'replace', // сделано для того, чтобы не сохранять в истории текущую страницу, то есть кнопка 'Назад' вела в список интеграций, а не на ненастроенную интеграцию
        };

        // после создания интеграции надо перенаправить на состояние созданной интеграции
        $state.go('app.content.integrations.details.configured.' + integration.type, params, transitionParams);
        toastr.success(
          $translate.instant('integrations.integration.toasts.created', {
            integrationTypeName: $translate.instant('models.integration.types.' + integration.type + '.name'),
          }),
        );
      }

      function saveSuccess() {
        toastr.success($translate.instant('integrations.integration.toasts.saved'));
      }
    }

    /**
     * Переключение видимости инструкции
     */
    function toggleManualVisibility() {
      vm.isManualExpanded = !vm.isManualExpanded;
    }
  }
})();

(function () {
  'use strict';

  require('./double-opt-in-email-preview.controller');

  angular.module('myApp.privacySettings').component('cqDoubleOptInEmailPreview', cqDoubleOptInEmailPreview());

  function cqDoubleOptInEmailPreview() {
    return {
      bindings: {
        appName: '=', // название приложения
        currentApp: '=', // приложение
        messageSender: '=', // отправитель для письма подтверждения Double Opt-In
        logo: '=', // логотип
      },
      controller: 'CqDoubleOptInEmailPreviewController',
      controllerAs: 'vm',
      template: require('./double-opt-in-email-preview.html'),
    };
  }
})();

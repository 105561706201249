<div class="modal-header">
  <h3
    class="modal-title"
    transloco="hotkeysModal.title"
  ></h3>
  <button
    class="close"
    (click)="closeFn()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>

<div class="modal-body">
  <h4
    class="margin-top-15 margin-bottom-15"
    transloco="hotkeysModal.legend"
  ></h4>
  <div class="row">
    <div class="col-6 margin-between-rows-10">
      <!--сообщение оператора отправлено, но не прочитано-->
      <div>
        <i class="cqi-sm cqi-check-sent text-primary margin-right-5"></i>
        <span transloco="hotkeysModal.notRead"></span>
      </div>
      <!--сообщение оператора прочитано-->
      <div>
        <i class="cqi-sm cqi-check-read text-primary margin-right-5"></i>
        <span transloco="hotkeysModal.read"></span>
      </div>
      <!--количество новых сообщений от пользователя-->
      <div>
        <div
          class="unanswered-counter label label-primary small inline-flex justify-center align-items-center margin-right-5"
        >
          1
        </div>
        <span transloco="hotkeysModal.newMessagesCount"></span>
      </div>
      <!--на диалог не назначен ни один оператор-->
      <div>
        <i class="cqi-sm cqi-user-square text-danger margin-right-5"></i>
        <span transloco="hotkeysModal.noAssignedOperator"></span>
      </div>
      <!--диалог закрыт-->
      <div>
        <i class="cqi-sm cqi-check-circle text-success margin-right-5"></i>
        <span transloco="hotkeysModal.closedConversation.description"></span>
        <i
          class="cqi-sm cqi-question-circle cursor-pointer"
          [ngbTooltip]="closedConversation"
          container="body"
          placement="right"
          triggers="click:blur"
        ></i>
      </div>
      <!--диалог отложен-->
      <div>
        <i class="cqi-sm cqi-stopwatch-o margin-right-5"></i>
        <span transloco="hotkeysModal.delayedConversation.description"></span>
        <i
          class="cqi-sm cqi-question-circle cursor-pointer"
          [ngbTooltip]="delayedConversation"
          container="body"
          placement="right"
          triggers="click:blur"
        ></i>
      </div>
      <!--диалог был отложен и он напомнил о себе-->
      <div>
        <i class="cqi-sm cqi-stopwatch-o text-warning margin-right-5"></i>
        <span transloco="hotkeysModal.delayedConversationAndReminded.description"></span>
        <i
          class="cqi-sm cqi-question-circle cursor-pointer"
          [ngbTooltip]="delayedConversationAndReminded"
          container="body"
          placement="right"
          triggers="click:blur"
        ></i>
      </div>
      <!--последнее сообщение в диалоге — автоответ-->
      <div>
        <i class="icon auto-reply-icon inline-block margin-right-5"></i>
        <span transloco="hotkeysModal.autoReplyLast.description"></span>
        <i
          class="cqi-sm cqi-question-circle cursor-pointer"
          [ngbTooltip]="autoReplyLast"
          container="body"
          placement="right"
          triggers="click:blur"
        ></i>
      </div>
      <!-- последнее сообщение в диалоге — заметка оператора-->
      <div>
        <i class="icon note-icon inline-block margin-right-5"></i>
        <span transloco="hotkeysModal.noteLast"></span>
      </div>
      <!--значок канала, в котором находится диалог-->
      <div>
        <cq-channel-icon
          [channel]="vkOrFacebookChannel"
          xs
        ></cq-channel-icon>
        <span transloco="hotkeysModal.channelsIcon"></span>
      </div>
    </div>

    <div class="col-6 margin-between-rows-20">
      <!--тег диалога-->
      <div>
        <cq-tag-default
          class="align-middle"
          [text]="'hotkeysModal.conversationTag.tag' | transloco"
        ></cq-tag-default>
        <span transloco="hotkeysModal.conversationTag.description"></span>
        <i
          class="cqi-sm cqi-question-circle cursor-pointer"
          [ngbTooltip]="dialogsTag"
          container="body"
          placement="right"
          triggers="click:blur"
        ></i>
      </div>
      <!--в этом канале есть неотвеченные диалоги-->
      <div class="flex align-items-center justify-start">
        <div class="margin-right-15">
          <cq-channel
            [channel]="vkOrFacebookChannel"
            [notReadCount]="NOT_READ_COUNT_PREVIEW_VALUE"
          ></cq-channel>
        </div>
        <span transloco="hotkeysModal.hasNotRead"></span>
      </div>
      <!--у вас есть неразобранные диалоги, то есть на них не назначен ни один оператор-->
      <div class="flex align-items-center justify-start">
        <div class="margin-right-15">
          <cq-channel
            [channel]="allChannelsChannel"
            [isActive]="true"
            [notAssignedCount]="NOT_ASSIGNED_COUNT_PREVIEW_VALUE"
          ></cq-channel>
        </div>
        <span transloco="hotkeysModal.thereAreUnparsedConversations"></span>
      </div>
    </div>
  </div>

  <hr class="margin-bottom-30" />

  <!-- переключение хоткея для отправки сообщений -->
  <h4
    class="margin-bottom-15"
    transloco="hotkeysModal.hotKeys"
  ></h4>
  <div class="row">
    <div class="col-12 margin-between-rows-15 margin-bottom-30">
      <div transloco="hotkeysModal.dialogManagement.title"></div>
      <div
        class="custom-control custom-radio"
        *ngFor="let key of ['byCtrEnter', 'byEnter']"
      >
        <input
          class="custom-control-input"
          [formControl]="sendByKeyControl"
          [id]="key"
          type="radio"
          [value]="key"
          [attr.disabled]="isRequestExecuted ? true : null"
        />
        <label
          class="custom-control-label"
          [for]="key"
          [innerHTML]="'hotkeysModal.writingAndSending.sendByEnterRadio.' + key | transloco"
        ></label>
      </div>
    </div>

    <div class="col-6 margin-between-rows-10">
      <div
        class="margin-bottom-15"
        transloco="hotkeysModal.dialogManagement.title"
      ></div>
      <!--Закрыть диалог-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">O</div>
        </div>
        <div transloco="hotkeysModal.dialogManagement.closeDialog"></div>
      </div>
      <!--Закрыть диалог и снять назначение-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">Q</div>
        </div>
        <div transloco="hotkeysModal.dialogManagement.closeAndRemoveAppointment"></div>
      </div>
      <!--Назначить диалог на меня-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">M</div>
        </div>
        <div transloco="hotkeysModal.dialogManagement.assignToMe"></div>
      </div>
      <!--Снять назначение-->
      <div class="flex align-items-center justify-start margin-bottom-30">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">B</div>
        </div>
        <div transloco="hotkeysModal.dialogManagement.removeAppointment"></div>
      </div>

      <div
        class="margin-bottom-15"
        transloco="hotkeysModal.savedAnswers.title"
      ></div>
      <!--Сохранённый ответ (первые 9)-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">1-9</div>
        </div>
        <div transloco="hotkeysModal.savedAnswers.savedAnswers"></div>
      </div>
      <!--Открыть список сохранённых ответов-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">S</div>
        </div>
        <div transloco="hotkeysModal.savedAnswers.openSavedAnswersList"></div>
      </div>
    </div>

    <div class="col-6 margin-between-rows-10">
      <div
        class="margin-bottom-15"
        transloco="hotkeysModal.dialogNavigation.title"
      ></div>
      <!--Перейти на диалог выше в списке-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key">Page Up</div>
        </div>
        <div transloco="hotkeysModal.dialogNavigation.goToDialogAboveList"></div>
      </div>
      <!--Перейти на диалог ниже в списке-->
      <div class="flex align-items-center justify-start margin-bottom-30">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key">Page Down</div>
        </div>
        <div transloco="hotkeysModal.dialogNavigation.goToDialogBelowList"></div>
      </div>

      <div
        class="margin-bottom-15"
        transloco="hotkeysModal.additionalActions.title"
      ></div>
      <!--Создать заметку-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">H</div>
        </div>
        <div transloco="hotkeysModal.additionalActions.createNote"></div>
      </div>
      <!--Открыть диалог в новой вкладке-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">L</div>
        </div>
        <div transloco="hotkeysModal.additionalActions.openInNewTab"></div>
      </div>
      <!--Открыть карточку пользователя-->
      <div class="flex align-items-center justify-start">
        <div class="hotkey-group flex flex-nowrap align-items-center">
          <div class="key margin-right-5">Ctrl</div>
          <div class="key">U</div>
        </div>
        <div transloco="hotkeysModal.additionalActions.openUserCard"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #autoReplyLast>
  <span transloco="hotkeysModal.autoReplyLast.tooltip"></span>
</ng-template>

<ng-template #closedConversation>
  <span transloco="hotkeysModal.closedConversation.tooltip"></span>
</ng-template>

<ng-template #delayedConversation>
  <span transloco="hotkeysModal.delayedConversation.tooltip"></span>
</ng-template>

<ng-template #delayedConversationAndReminded>
  <span transloco="hotkeysModal.delayedConversationAndReminded.tooltip"></span>
</ng-template>

<ng-template #dialogsTag>
  <span transloco="hotkeysModal.conversationTag.tooltip"></span>
</ng-template>

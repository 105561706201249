import { firstValueFrom } from 'rxjs';
import { FEATURE_LABELS } from '../../../../app/http/feature-label/feature-label.constants';

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqWelcomeMessageController', CqWelcomeMessageController);

  function CqWelcomeMessageController(
    $scope,
    $translate,
    $uibModal,
    carrotquestHelper,
    chatBotModel,
    featureLabelModel,
    validationHelper,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      let regularWelcomeMessage = !vm.showKbLinkInWelcomeMessage
        ? vm.message
        : $translate.instant('welcomeMessage.regularMessage');
      let knowledgeBaseWelcomeMessage = vm.showKbLinkInWelcomeMessage
        ? vm.message
        : $translate.instant('welcomeMessage.knowledgeBaseMessage');

      vm.FEATURE_LABELS = FEATURE_LABELS;
      vm.featureLabelModel = featureLabelModel;
      vm.getRoutingBotLink = getRoutingBotLink;
      vm.isRoutingBotActive = isRoutingBotActive;
      vm.returnGreeting = returnGreeting;
      vm.validationHelper = validationHelper;

      $scope.$watch('vm.showKbLinkInWelcomeMessage', (newVal, oldVal) => {
        // сохраняем то, что пользователь до этого ввёл в поле
        if (newVal !== oldVal) {
          if (oldVal) {
            knowledgeBaseWelcomeMessage = vm.message;
          } else {
            regularWelcomeMessage = vm.message;
          }

          if (newVal) {
            vm.message = knowledgeBaseWelcomeMessage;
            trackAddKbLink();
          } else {
            vm.message = regularWelcomeMessage;
          }
        }
      });
    }

    /**
     * Получение ссылки до роутинг-бота
     * - создание если его нет
     * - редактирование если уже создан
     * @returns {string}
     */
    function getRoutingBotLink() {
      return vm.routingBot === null
        ? 'app.content.messagesAjs.routingBot.create'
        : 'app.content.messagesAjs.routingBot.edit({routingBotId: vm.routingBot.id})';
    }

    /**
     * Активность бота
     * @returns {boolean}
     */
    function isRoutingBotActive() {
      return !!vm.routingBot && vm.routingBot.active;
    }

    /**
     * Открытие одалки подтверждающей мену статуса роутинг бота
     */
    function openChangeRoutingBotActiveStatusConfirmModal(statusToChange) {
      const modal = $uibModal.open({
        component: 'cqChangeRoutingBotActiveStatusConfirmWrapperModal',
        resolve: {
          statusToChange: angular.bind(null, statusToChange),
        },
      });

      return modal.result.then((val) => val).catch(() => false);
    }

    /**
     * Возвращает приветсвие (приостанавливает роутиг-бота)
     */
    function returnGreeting() {
      const statusToChange = false;

      openChangeRoutingBotActiveStatusConfirmModal(statusToChange).then((val) => {
        if (!val) {
          return;
        }

        firstValueFrom(
          chatBotModel.saveRoutingBot(vm.currentApp.id, { ...vm.routingBot, active: statusToChange }),
        ).then(({ chatBot }) => {
          vm.routingBot.active = chatBot.active;
        });
      });
    }

    /**
     * Трек добавления ссылки на БЗ в приветственное сообщение
     */
    function trackAddKbLink() {
      carrotquestHelper.track('Настройки чата – добавил ссылку на БЗ');
    }
  }
})();

<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('createUrlAutoEventComponent.heading') }}</h3>

    <button
      class="close"
      (click)="ajsModalInstance.dismiss()"
      aria-label="Close"
      type="button"
      data-test="close-modal-btn"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <form
      (ngSubmit)="onSubmit()"
      autocomplete="off"
      id="urlAutoEventForm"
      name="urlAutoEventForm"
    >
      <!--НАЗВАНИЕ СОБЫТИЯ-->
      <cq-event-name-input
        class="form-group"
        [formControl]="urlAutoEventForm.controls.name"
      ></cq-event-name-input>
      <!--АДРЕС СТРАНИЦЫ-->
      <div class="form-group">
        <label class="mb-10">{{ t('createUrlAutoEventComponent.pageUrlInput.label') }}</label>
        <cq-url-input [formControl]="urlAutoEventForm.controls.url"></cq-url-input>
      </div>

      <!--УЧИТЫВАТЬ ПОДСТРАНИЦЫ-->
      <div class="form-group">
        <cq-checkbox-control [formControl]="urlAutoEventForm.controls.hasSubpages">
          {{ t('createUrlAutoEventComponent.hasSubpagesCheckbox') }}
        </cq-checkbox-control>
      </div>

      <ng-container *ngIf="eventDuplicates$ | async as duplicates">
        <cq-duplicates-errors
          (selectEvent)="selectEvent($event)"
          *ngIf="duplicates.length > 0"
          [eventDuplicates]="duplicates"
        ></cq-duplicates-errors>
      </ng-container>
      <input
        class="hidden"
        id="urlAutoEventForm.submit"
        type="submit"
      />
    </form>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-outline-secondary"
      (click)="ajsModalInstance.dismiss()"
      type="button"
      data-test="cancel-modal-btn"
    >
      {{ t('general.cancel') }}
    </button>
    <label
      [ngClass]="{ disabled: ((eventDuplicates$ | async) ?? []).length > 0 }"
      class="btn btn-primary"
      for="urlAutoEventForm.submit"
    >
      {{ t('createUrlAutoEventComponent.saveButton') }}
    </label>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'chatWidgetButtonTextComponent'">
  <label for="button-text-value">{{ t('input.label') }}:</label>
  <div
    class="form-control-container"
    [cqShowError]="control"
  >
    <input
      class="form-control no-resize"
      id="button-text-value"
      [formControl]="control"
      placeholder="{{ t('input.placeholder') }}"
    />
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('input.errors.required') }}
      </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('input.errors.maxlength', { maxLength: VALUE_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { AutoAssignmentModule } from '@panel/app/pages/conversations-settings/shared/components/auto-assignment/auto-assignment.module';
import { ConversationsSettingsBreadcrumbsModule } from '@panel/app/pages/conversations-settings/shared/components/breadcrumbs/conversations-settings-breadcrumbs.module';
import { ChannelsTableModule } from '@panel/app/pages/conversations-settings/shared/components/channels-table/channels-table.module';
import { ManageTagsModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.module';
import { SavedRepliesModule } from '@panel/app/pages/conversations-settings/shared/components/saved-replies/saved-replies.module';
import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { ConversationsSettingsComponent } from './conversations-settings.component';

@NgModule({
  declarations: [ConversationsSettingsComponent],
  imports: [
    CommonModule,
    ConversationsSettingsBreadcrumbsModule,
    NgbNavModule,
    ChannelsTableModule,
    TranslocoModule,
    ManageTagsModule,
    SavedRepliesModule,
    AutoAssignmentModule,
    SharedModule,
    AccessDenialPopoverModule,
  ],
})
export class ConversationsSettingsModule {}

<ng-container *transloco="let t">
  <div
    *ngIf="!files.length"
    class="form-control-container"
  >
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">{{
        t('fileUploadDropzone.fileForm.errors.required')
      }}</cq-validation-message>
    </cq-validation-messages>
    <ngx-dropzone
      #ngxDropzone
      class="file-upload padding-30 card text-center cursor-pointer no-margin justify-content-center"
      (change)="onFileChange($event)"
      [accept]="accept"
      [classList]="['has-error', 'border-danger']"
      [cqShowError]="control"
      [maxFileSize]="maxFileSize"
      [multiple]="multiple"
    >
      <ngx-dropzone-label class="margin-15-neg">
        <ng-content></ng-content>
      </ngx-dropzone-label>
    </ngx-dropzone>
  </div>
  <ng-container *ngIf="files.length">
    <div
      class="d-flex align-items-start justify-space-between"
      *ngFor="let file of files"
    >
      <div class="d-flex align-items-center">
        <div
          class="attachment-icon bg-primary d-flex flex-shrink-0 align-items-center justify-content-center margin-right-10"
        >
          <i class="cqi-sm cqi-paperclip"></i>
        </div>
        <div>
          <div class="text-primary">{{ file.name }}</div>
          <small class="text-muted">{{ file.size! | bytes: 2 }}</small>
        </div>
      </div>
      <button
        class="btn btn-borderless-primary"
        (click)="removeFile(file)"
        type="button"
      >
        <i class="btn-icon-left cqi-sm cqi-trash"></i>
        <span>{{ t('general.remove') }}</span>
      </button>
    </div>
  </ng-container>
</ng-container>

<div class="modal-header">
  <h3 class="modal-title">{{ heading }}</h3>
  <button
    class="close"
    (click)="activeModal.dismiss()"
    aria-label="Close"
    type="button"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body">
  <div
    *ngIf="body"
    class="mb-20"
    [innerHTML]="body"
  ></div>
  <form
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    id="promptForm"
    name="promptForm"
  >
    <div
      class="form-group no-margin-bottom"
      [cqShowError]="control"
    >
      <label
        *ngIf="inputLabel"
        for="promptInput"
        >{{ inputLabel }}</label
      >
      <input
        id="promptInput"
        class="form-control"
        [formControl]="control"
        [placeholder]="inputPlaceholder"
        name="text"
        ngbAutofocus
      />
      <cq-validation-messages [control]="control">
        <cq-validation-message errorName="required">{{ inputErrorText }}</cq-validation-message>
        <cq-validation-message errorName="maxlength">{{ inputMaxLengthErrorText }}</cq-validation-message>
      </cq-validation-messages>
    </div>
    <input
      class="hidden"
      id="promptForm.submit"
      type="submit"
    />
  </form>
</div>
<div class="modal-footer">
  <button
    (click)="activeModal.dismiss()"
    [ngClass]="['btn', cancelButtonClass]"
    type="button"
  >
    {{ cancelButtonText }}
  </button>
  <label
    [ngClass]="['btn', confirmButtonClass]"
    for="promptForm.submit"
    >{{ confirmButtonText }}</label
  >
</div>

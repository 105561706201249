<ng-container *transloco="let t; read: 'knowledgeBaseActivateWrapperComponent'">
  <section id="content">
    <div class="container-fluid limited-container margin-top-50">
      <div class="card no-margin-bottom">
        <cq-knowledge-base-activate
          [currentApp]="currentApp"
          [settings]="settings"
        ></cq-knowledge-base-activate>
      </div>
    </div>
  </section>
</ng-container>

<ng-container *transloco="let t; read: 'telegramOperationSelectComponent'">
  <ng-select
    [clearable]="false"
    [formControl]="control.controls.type"
    [items]="TELEGRAM_OPERATIONS"
    [searchable]="false"
  >
    <ng-template
      ng-label-tmp
      ng-option-tmp
      let-item="item"
    >
      {{ t('operations.' + item) }}
    </ng-template>
  </ng-select>

  <cq-telegram-value-select
    [telegramIntegrations]="telegramIntegrations"
    [formControl]="control.controls.value"
  >
  </cq-telegram-value-select>
</ng-container>

<ng-container *transloco="let t; read: 'paymentInfoComponent'">
  <ng-container *ngIf="subscriptionStore.billing$ | async as billing">
    <h4 class="mb-15 font-weight-bold">{{ t('title') }}</h4>
    <ul class="list-group">
      <li class="list-group-item py-10">
        <cq-card
          [billing]="billing"
          [requestPerformed]="isAddCardRequestPerformed | async"
          (addCard)="onAddCard()"
          (changeCard)="onChangeCard()"
          (removeCard)="onRemoveCard()"
        ></cq-card>
      </li>
      <li class="list-group-item py-10">
        <cq-billing-email
          [customer]="billing.customer"
          (addEmail)="onAddBillingEmail()"
          (editEmail)="onEditBillingEmail()"
        ></cq-billing-email>
      </li>
      <li
        *cqHideInUs
        class="list-group-item py-10"
      >
        <cq-accounting-emails
          [accountingEmails]="billing.accountingEmails"
          (addEmails)="onAddAccountingEmails()"
          (editEmails)="onEditAccountingEmails()"
        ></cq-accounting-emails>
      </li>
      <li
        *cqHideInUs
        class="list-group-item py-10"
      >
        <cq-requisites
          [requisites]="billing.requisites"
          (addRequisites)="onAddRequisites()"
          (editRequisites)="onEditRequisites()"
        ></cq-requisites>
      </li>
    </ul>
  </ng-container>
</ng-container>

import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';

import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { App } from '@http/app/app.model';

@Component({
  selector: 'cq-chat-audio-message[currentApp][value]',
  templateUrl: './chat-audio-message.component.html',
  styleUrls: ['./chat-audio-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatAudioMessageComponent implements OnInit {
  formControl: FormControl<boolean> = new FormControl<boolean>(true, { nonNullable: true });

  @Input()
  currentApp!: App;

  @Input()
  set value(value: boolean) {
    this.formControl.setValue(value);
  }

  @Output()
  valueChanges = this.formControl.valueChanges;

  /** Доступ до распознавания текста из аудиосообщений */
  accessToTranscribeAudio: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  /** Текущий язык */
  activeLang: string;

  constructor(
    private readonly paywallService: PaywallService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly translocoService: TranslocoService,
  ) {
    this.activeLang = this.translocoService.getActiveLang();
  }

  ngOnInit() {
    this.accessToTranscribeAudio = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.TRANSCRIBE_AUDIO,
      this.currentApp,
    );
  }

  /** Показ пейвола с покупкой тарифа, который включает в себя planCapability */
  showPaywall() {
    this.paywallService.showPaywall(this.currentApp, this.accessToTranscribeAudio.denialReason);
  }
}

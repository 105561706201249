import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { firstValueFrom, merge } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { EventType } from '@http/property/property.model';
import { AUTO_EVENTS_GROUPS } from '@http/track-master/track-master.constants';
import { AutoEvent, TrackMasterModel } from '@http/track-master/track-master.model';
import { AutoEventsDuplicatesHelperService } from '@panel/app/partials/track-master/services/duplicates-helper.service';
import { DestroyService } from '@panel/app/services';
import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';

/**
 * Параметры передаваемые в модалку
 */
export type CreateUrlAutoEventModalParams = {
  currentAppId: string;
  autoEvents: AutoEvent[];
  eventTypes: EventType[];
};

interface UrlAutoEventFormControls {
  name: string;
  url: string;
  hasSubpages: boolean;
}

@Component({
  selector: 'cq-create-url-auto-event',
  templateUrl: './create-url-auto-event.component.html',
  styleUrls: ['./create-url-auto-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, AutoEventsDuplicatesHelperService],
})
export class CreateUrlAutoEventComponent {
  /**
   * Инстанс AJS модалки. Нужен для закрытия.
   * TODO Переделать при переезде на A2 компонента, который вызывает эту модалку
   */
  @Input()
  ajsModalInstance?: any;

  /** Параметры, передаваемые в модалку */
  @Input()
  modalWindowParams!: CreateUrlAutoEventModalParams;

  urlAutoEventForm = this.fb.group<UrlAutoEventFormControls>({
    name: null,
    url: null,
    hasSubpages: false,
  });

  eventDuplicates$ = merge(
    this.urlAutoEventForm.controls.name.valueChanges,
    this.urlAutoEventForm.controls.url.valueChanges,
    this.urlAutoEventForm.controls.hasSubpages.valueChanges,
  ).pipe(
    debounceTime(500),
    map(() => {
      return this.autoEventsDuplicatesHelper.getDuplicates(this.autoEvent, this.modalWindowParams.autoEvents);
    }),
    takeUntil(this.destroy$),
  );

  constructor(
    private readonly autoEventsDuplicatesHelper: AutoEventsDuplicatesHelperService,
    private readonly destroy$: DestroyService,
    private readonly trackMasterModel: TrackMasterModel,
    @Inject(UntypedFormBuilder)
    private readonly fb: GenericFormBuilder,
  ) {}

  onSubmit() {
    if (this.urlAutoEventForm.invalid || this.autoEventsDuplicatesHelper.hadDuplicatesLastTime) {
      this.urlAutoEventForm.markAllAsTouched();
      return;
    }

    firstValueFrom(this.trackMasterModel.create(this.modalWindowParams.currentAppId, this.autoEvent)).then(
      (response) => {
        this.ajsModalInstance.close(response);
      },
    );
  }

  /**
   * Собирает автособытие
   */
  get autoEvent(): AutoEvent {
    const autoEvent = this.trackMasterModel.getDefault(AUTO_EVENTS_GROUPS.URL);

    autoEvent.name = this.urlAutoEventForm.controls.name.value || '';
    autoEvent.url = this.urlAutoEventForm.controls.url.value || '';
    autoEvent.urlContains = this.urlAutoEventForm.controls.hasSubpages.value;
    autoEvent.app = this.modalWindowParams.currentAppId;

    return autoEvent;
  }

  /**
   * Выбор события из существющих
   * @param autoEvent - Автособытие
   */
  selectEvent(autoEvent: AutoEvent) {
    const eventType = this.modalWindowParams.eventTypes.find((eventType) => eventType.name === autoEvent.name);

    if (!eventType) {
      throw new Error('No such event');
    }

    this.ajsModalInstance.close({
      autoEvent,
      eventType,
    });
  }
}

import { Validators } from '@angular/forms';
import filter from 'lodash-es/filter';

import { INTEGRATION_NAME_MAX_LENGTH } from '@http/integration/constants/integration.constants';
import {
  AmocrmEventMapperExternal,
  AmocrmIntegrationSettingsExternal,
} from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { arrayToMap } from '@panel/app/shared/functions/map/array-to-map.function';
import { mapToArray } from '@panel/app/shared/functions/map/map-to-array.function';
import { PseudoMap } from '@panel/app/shared/types/pseudo-map.type';

import { AmocrmIntegration } from '../amocrm-integration';
import { AmocrmIntegrationFormData, AmocrmIntegrationSettingsFormData } from '../types/amocrm-integration.types';

/** Класс для работы с формой представления AmoCRM-интеграции */
export class AmocrmIntegrationForm extends GenericFormGroup<AmocrmIntegrationFormData> {
  constructor(integration: AmocrmIntegration) {
    let controls: Controls<AmocrmIntegrationFormData> = {
      name: new GenericFormControl(integration.name, [
        Validators.required,
        Validators.maxLength(INTEGRATION_NAME_MAX_LENGTH),
      ]),
      settings: new AmocrmSettingsFormGroup(integration.settings),
    };

    super(controls, null, null);
  }
}

/** Класс для работы с формой настроек AmoCRM-интеграции */
export class AmocrmSettingsFormGroup extends GenericFormGroup<AmocrmIntegrationSettingsFormData> {
  constructor(settings: AmocrmIntegrationSettingsExternal) {
    /**
     * Формирование массива контролов для поля leadsEventsMapping
     *
     * @param properties Поле leadsEventsMapping
     */
    function getLeadsEventsMappingFormArray<T>(
      properties: AmocrmEventMapperExternal[],
    ): GenericFormArray<AmocrmLeadsEventsMappingForm> {
      return new GenericFormArray(
        properties.map((property) => {
          return new AmocrmLeadsEventsMappingForm(property);
        }),
      );
    }

    /**
     * Преобразую поля amocrmLeadsPropsMapping и standardProps в удобный формат: в UI они никак не разделяются +
     * имеют разный формат на бэке
     */
    const amocrmPropsMappingInternal = {
      ...settings.amocrmPropsMapping,
      ...parseStandardPropsToInternalFormat(settings.standardProps),
    };

    /**
     * Преобразование поля leadsPropsMapping в удобный формат
     *
     * NOTE:
     *  На backend'е в этом поле лежат свойства и события, а в интерфейсе необходимо визуально и функционально
     *  разделить их.
     *
     */
    const leadsEventTypesMapping = arrayToMap(
      filter(mapToArray(settings.leadsPropsMapping), (property: PseudoMap) => property.value.includes('$event_')),
    );
    const leadsPropsMapping = arrayToMap(
      filter(mapToArray(settings.leadsPropsMapping), (property: PseudoMap) => !property.value.includes('$event_')),
    );

    /**
     * Преобразую поле propsMapping в удобный формат: на бэке в этом поле лежат и свойства, и события, а в интерфейсе
     * необходимо визуально разделить их и дать возможность добавлять новые элемента в оба этих поля.
     */
    const userEventsTypesMapping = arrayToMap(
      filter(mapToArray(settings.propsMapping), (property: PseudoMap) => property.value.includes('$event_')),
    );
    const userPropsMapping = arrayToMap(
      filter(mapToArray(settings.propsMapping), (property: PseudoMap) => !property.value.includes('$event_')),
    );

    const controls: Controls<AmocrmIntegrationSettingsFormData> = {
      amocrmAddress: new GenericFormControl(settings.amocrmAddress),
      amocrmLeadsPropsMapping: getGenericFormArray(settings.amocrmLeadsPropsMapping),
      amocrmPropsMappingInternal: getGenericFormArray(amocrmPropsMappingInternal),
      amocrmUserHash: new GenericFormControl(settings.amocrmUserHash),
      amocrmUserLogin: new GenericFormControl(settings.amocrmUserLogin),
      eventTypeIds: new GenericFormControl(settings.eventTypeIds),
      leadDefaultPipeline: new GenericFormControl(settings.leadDefaultPipeline, [Validators.required]),
      leadDefaultStatus: new GenericFormControl(settings.leadDefaultStatus, [Validators.required]),
      leadsEventsMapping: getLeadsEventsMappingFormArray(settings.leadsEventsMapping),
      leadsEventTypeIds: new GenericFormControl(settings.leadsEventTypeIds),
      leadEventTypesMapping: getGenericFormArray(leadsEventTypesMapping),
      leadPropsMapping: getGenericFormArray(leadsPropsMapping),
      oauthAccessExpiresAt: new GenericFormControl(settings.oauthAccessExpiresAt),
      oauthAccessToken: new GenericFormControl(settings.oauthAccessToken),
      oauthCsrfToken: new GenericFormControl(settings.oauthCsrfToken),
      oauthRefreshExpiresAt: new GenericFormControl(settings.oauthRefreshExpiresAt),
      oauthRefreshToken: new GenericFormControl(settings.oauthRefreshToken),
      userEventsTypesMapping: getGenericFormArray(userEventsTypesMapping),
      userPropsMapping: getGenericFormArray(userPropsMapping),
      systemFields: getGenericFormArray(settings.systemFields),
      webhookIsSet: new GenericFormControl(settings.webhookIsSet),
      clientId: new GenericFormControl(settings.clientId),
      clientSecret: new GenericFormControl(settings.clientSecret),
    };

    super(controls, null, null);
  }
}

type PropertyMapper = Record<string, string>;

/** Класс для работы с формой полей настроек AmoCRM по типу map (someKey: {key1: value1, key2: value2...}) */
export class AmocrmMappingForm extends GenericFormGroup<PropertyMapper> {
  constructor(map: PropertyMapper) {
    const key = Object.keys(map)[0];
    const value = map[key];

    const controls: Controls<PropertyMapper> = {
      key: new GenericFormControl(!!key ? key : null),
      value: new GenericFormControl(!!value ? value : null),
    };

    super(controls, null, null);
  }
}

type AmocrmEventMapperInternal = {
  checked: boolean;
  event: Record<string, string>;
  eventProps: GenericFormArray<AmocrmMappingForm>;
};

/** Класс для работы с формой поля leadsEventsMapping */
export class AmocrmLeadsEventsMappingForm extends GenericFormGroup<AmocrmEventMapperInternal> {
  constructor(event: AmocrmEventMapperExternal) {
    const eventProps = getGenericFormArray(event.eventProps);
    const controls: Controls<AmocrmEventMapperInternal> = {
      checked: new GenericFormControl(event.checked),
      event: new AmocrmMappingForm(event.event),
      eventProps: eventProps,
    };

    super(controls, null, null);
  }
}

function parseStandardPropsToInternalFormat(standardProps: AmocrmIntegrationSettingsExternal['standardProps']) {
  const newObj = {};
  for (let key in standardProps) {
    switch (key) {
      case '$name':
        if (standardProps[key]) {
          Object.assign(newObj, { 'Имя контакта': key });
        }
        break;
      case '$email':
        if (standardProps[key]) {
          Object.assign(newObj, { Email: key });
        }
        break;
      case '$phone':
        if (standardProps[key]) {
          Object.assign(newObj, { Телефон: key });
        }
        break;
    }
  }

  return newObj;
}

/**
 * Формирование массива контролов из map-поля (someKey: {key1: value1, key2: value2...})
 *
 * @param property map-поле
 */
function getGenericFormArray<T extends object>(property: T): GenericFormArray<AmocrmMappingForm> {
  const propertyKeys = Object.keys(property);

  return new GenericFormArray(
    propertyKeys.map((propertyKey: string) => {
      return new AmocrmMappingForm({
        //@ts-ignore
        [propertyKey]: property[propertyKey],
      });
    }),
  );
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from '@panel/app/shared/shared.module';

import { UserSegmentsComponent } from './user-segments.component';

@NgModule({
  declarations: [UserSegmentsComponent],
  exports: [UserSegmentsComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, NgbCollapseModule, ClipboardModule, NgbTooltipModule],
})
export class UserSegmentsModule {}

<ng-container *transloco="let t; read: 'triggerChainStepEditorWrapComponent'">
  <div class="padding-20 bordered-bottom">
    <div class="d-flex align-items-center">
      <cq-trigger-chain-step-name-editor [formControl]="control"></cq-trigger-chain-step-name-editor>

      <div ngbDropdown>
        <button
          class="btn btn-borderless-primary"
          type="button"
          ngbDropdownToggle
        >
          <i class="btn-icon-left cqi-sm cqi-ellipsis"></i>
        </button>
        <div ngbDropdownMenu>
          <button
            ngbDropdownItem
            class="btn btn-borderless-primary"
            type="button"
            (click)="onCopyBlockClick()"
          >
            <i class="btn-icon cqi-sm cqi-copy"></i>
            <span>{{ t('copy') }}</span>
          </button>
          <button
            ngbDropdownItem
            class="btn btn-borderless-primary"
            type="button"
            (click)="onDeleteBlockClick()"
          >
            <i class="btn-icon cqi-sm cqi-trash"></i>
            <span>{{ t('remove') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</ng-container>

//NOTE покачто не используется как компонент из-за того что в других помпонентах идет обрпщение к скоупу, котого нет в компоненте
(function () {
  'use strict';

  require('./general.controller');

  angular.module('myApp.general').component('cqGeneral', cqGeneral());

  function cqGeneral() {
    return {
      bindings: {
        billingInfo: '=', // информация по биллингу
        channelCounters: '=', // Счетчик сообщений
        currentApp: '=', // Текущий апп
        djangoUserTempData: '=', // Временные данные django-пользователя
      },
      controller: 'CqGeneralController',
      controllerAs: 'vm',
      template: require('./general.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import {
  CarrotquestHelperProvider,
  ConversationTagFactoryServiceProvider,
  ConversationTagHelpServiceProvider,
} from '@panel/app/ajs-upgraded-providers';
import { ManageTagsStore } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.store';
import { SharedModule } from '@panel/app/shared/shared.module';

import { CreateTagComponent } from './create-tag.component';

@NgModule({
  declarations: [CreateTagComponent],
  exports: [CreateTagComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, ReactiveFormsModule],
  providers: [
    CarrotquestHelperProvider,
    ConversationTagFactoryServiceProvider,
    ConversationTagHelpServiceProvider,
    ManageTagsStore,
  ],
})
export class CreateTagModule {}

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqWidgetAnimationController', CqWidgetAnimationController);

  function CqWidgetAnimationController(l10nHelper) {
    let vm = this;

    /**
     * Набор анимаций для Carrot quest
     * TODO: этот список должен быть как enum в модели app или app-settings (которой ещё нет)
     *
     * @type {string[]}
     */
    const CARROT_QUEST_ANIMATIONS = ['horizontal_funnel', 'vertical_funnel', 'smile', 'winking_smile'];

    /**
     * Набор анимаций для Dashly
     * TODO: этот список должен быть как enum в модели app или app-settings (которой ещё нет)
     *
     * @type {string[]}
     */
    const DASHLY_ANIMATIONS = ['dashly_dash', 'dashly_dance', 'dashly_bounce'];

    vm.$onInit = init;

    function init() {
      vm.animationsList = l10nHelper.isUsCountry() ? DASHLY_ANIMATIONS : CARROT_QUEST_ANIMATIONS;
      vm.isAnimationActive = {}; // Параметр передаваемый во фрейм с превью чата, для активации анимации
    }
  }
})();

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.unsubscribeSdkPush')
    .controller('CqUnsubscribeSdkPushModalController', CqUnsubscribeSdkPushModalController);

  function CqUnsubscribeSdkPushModalController(
    $uibModal,
    $translate,
    PUSH_SUBSCRIPTIONS_TYPES,
    carrotquestHelper,
    pushSubscriptionModel,
  ) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.openModalConfirmUnsubscribeSdkPush = openModalConfirmUnsubscribeSdkPush;
    }

    /**
     * Открытие модального окна подтверждения отписки устройства от пушей SDK
     *
     * @param {object} subscription Объект подписки
     */
    function openModalConfirmUnsubscribeSdkPush(subscription) {
      const modalInstance = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.unsubscribeSdkPush.unsubscribeModal.heading'),
              body: $translate.instant('modals.unsubscribeSdkPush.unsubscribeModal.body'),
              confirmButtonText: $translate.instant('modals.unsubscribeSdkPush.unsubscribeModal.confirmButtonText'),
              needConfirmation: false,
            };
          },
        },
      });

      modalInstance.result
        .then(() =>
          firstValueFrom(
            pushSubscriptionModel.unsubscribeByDeviceGuid(
              subscription.deviceGuid,
              vm.resolve.userId,
              PUSH_SUBSCRIPTIONS_TYPES.MOBILE,
            ),
          ),
        )
        .then(() => {
          carrotquestHelper.track('Карточка пользователя - отписал лида от SDK', {
            $device_guid: subscription.deviceGuid,
          });
          vm.resolve.subscriptions.splice(vm.resolve.subscriptions.indexOf(subscription), 1);

          if (vm.resolve.subscriptions.length === 0) {
            vm.modalInstance.dismiss();
          }
        });
    }
  }
})();

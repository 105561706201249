import { ChangeDetectionStrategy, Component } from '@angular/core';
import { differenceBy } from 'lodash-es';

import { Addon } from '@http/addon/addon';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

/** Компонент для работы с аддонами */
@Component({
  selector: 'cq-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddonsComponent {
  constructor(protected subscriptionStore: SubscriptionStore) {}

  /**
   * Получение не подключенных аддонов
   *
   * @param availableAddons - Доступные для подключения аддоны
   * @param connectedAddons - Подключенные аддоны
   */
  getAddonsNotConnected(availableAddons: Addon[], connectedAddons: Addon[]): Addon[] {
    return differenceBy(availableAddons, connectedAddons, (addon) => {
      /**
       * Сейчас есть минимум 3 аддона с телегой
       *  - tg-bot-marketing
       *  - telegram-bot (+ версия для старых)
       *  - messages-to-telegram(-tmp) (+ версия для старых)
       * И чтобы при подключенном аддоне, в список рекомендуемых не лез другой аддон с телегой
       * добавлена проверка, уравнивающая все ТГ аддоны
       */
      if (addon.id.includes('telegram') || addon.id.includes('tg')) {
        return 'Telegram';
      }
      return addon.name;
    });
  }

  /**
   * Пустой список аддонов
   *
   * @param addons - Список аддонов
   */
  isEmptyListAddons(addons: Addon[]): boolean {
    return addons.length === 0;
  }

  /** Обработчик клика по кнопке подключения аддона */
  onClickConnectButton(addon: Addon): void {
    addon.connectViaChat();
  }
}

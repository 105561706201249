import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { ChannelNotificationSetting } from '@http/channel/channel.model';

@Component({
  selector: 'cq-notification-channel-settings',
  templateUrl: './notification-channel-settings.component.html',
  styleUrls: ['./notification-channel-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationChannelSettingsComponent {
  /** Массив с объектами всех каналов аппа */
  @Input()
  channelList: ChannelNotificationSetting[] = [];

  /** FormControl с незаглушенными объектами каналов */
  @Input()
  notMutedChannelsControl!: FormControl;

  /** Событие на изменение списка селекта */
  @Output()
  noMutedChannels = new Subject<boolean>();

  constructor() {}

  /** Добавление всех каналов в незаглушенные */
  addAllChannels() {
    this.notMutedChannelsControl.setValue(this.channelList);
  }

  /** Отправляется значение для дизейбла чекбоксов настроек оповещений */
  onChangeNotMutedChannels() {
    this.noMutedChannels.next(!this.notMutedChannelsControl.value.length);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';

/**
 * Сервис связывающий источники смены ветки и слушателей таких изменений
 * Добавил, потому что в какой-то момент передавать между всеми компонентами эту информацию стало накладно, так проще
 */
@Injectable()
export class BotBranchSelectService extends BehaviorSubject<Branch | null> {
  constructor() {
    super(null);
  }
}

<ng-container *transloco="let t">
  <button
    class="btn btn-borderless-primary"
    data-test="send-chat-message-button"
    (click)="sendChatMessage()"
  >
    <i class="btn-icon-left cqi-sm cqi-tree"></i>
    <span>{{ t('newYearCertificatePayButtonComponent.button.text') }}</span>
  </button>
</ng-container>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { User, UserConsent } from '@http/user/types/user.type';
import { UserModel } from '@http/user/user.model';
import { UserConsentModalComponent } from '@panel/app/partials/modals/user-consent-modal/user-consent-modal.component';
import { USER_CONSENT_MODAL_DATA_TOKEN } from '@panel/app/partials/modals/user-consent-modal/user-consent-modal.token';
import { ModalHelperService } from '@panel/app/services';
import { LS_UNCOLLAPSED_USER_CARD_BLOCKS } from '@panel/app/shared/constants/localstorage.keys';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Component({
  selector: 'cq-user-consent',
  templateUrl: './user-consent.component.html',
  styleUrls: ['./user-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserConsentComponent implements OnInit {
  @Input()
  user!: User;

  @Output()
  collapseStateChange: EventEmitter<string> = new EventEmitter();

  /** Состояние коллапса */
  isCollapsed: boolean = true;

  /** Имеется какое-либо согласие */
  thereIsAnyConsent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  userConsents!: UserConsent[];

  /** Имя для localeStorage */
  userConsentLSName = 'user_consent';

  constructor(
    private readonly modalHelperService: ModalHelperService,
    private readonly $uibModal: UibModalService,
    private readonly userModel: UserModel,
  ) {
    if (localStorage.getItem(LS_UNCOLLAPSED_USER_CARD_BLOCKS)?.includes(this.userConsentLSName)) {
      this.isCollapsed = false;
    }
  }

  ngOnInit() {
    firstValueFrom(this.userModel.getUserConsentList(this.user.id)).then((res: UserConsent[]) => {
      this.userConsents = res;
      this.thereIsAnyConsent.next(!!res.length);
    });
  }

  /**
   * Изменение состояние collapse
   *
   * NOTE:
   *  Так реализовано из-за того, что
   *  при использовании двусторонней связи в ngbCollapse блок дёргается при открытии и закрытии
   */
  changeCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * Открытие модалки с соглашениями пользователя
   */
  openConsentHistory(): void {
    this.modalHelperService
      .provide(USER_CONSENT_MODAL_DATA_TOKEN, {
        consents: this.userConsents,
      })
      .open(UserConsentModalComponent, {
        centered: true,
        size: 'lg',
      });
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';
import { EventType } from '@http/property/property.model';

@Component({
  selector: 'cq-event-select[events]',
  templateUrl: './event-select.component.html',
  styleUrls: ['./event-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSelectComponent extends AbsCVAFormControlBasedComponent<string | null> {
  @Input()
  events!: EventType[];

  readonly control: FormControl<string | null> = this.fb.control(null, { nonNullable: true });

  constructor(private readonly fb: FormBuilder) {
    super();
  }

  protected trackByFn(prop: EventType): string {
    return prop.name;
  }

  protected searchFn(term: string, item: EventType) {
    return !!item.prettyName && item.prettyName.toLowerCase().indexOf(term.toLowerCase()) > -1;
  }
}

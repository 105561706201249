<ng-container *transloco="let t; read: 'eventSelectComponent'">
  <ng-select
    [clearable]="false"
    [formControl]="control"
    [items]="events"
    [placeholder]="t('placeholder')"
    [trackByFn]="trackByFn"
    [searchFn]="searchFn"
    [virtualScroll]="true"
    bindValue="id"
  >
    <ng-template
      ng-label-tmp
      ng-option-tmp
      let-event="item"
    >
      <div class="d-flex align-items-center z-in">
        <span class="ng-value-label flex-grow-1 white-space-normal">{{ event.prettyName }}</span>
        <i
          class="cqi-sm cqi-triangle-exclamation-o margin-left-5 text-danger"
          *ngIf="!event.active"
          [ngbTooltip]="t('unusedEventTooltip')"
          container="body"
          placement="top top-right top-left auto"
        ></i>
      </div>
    </ng-template>
  </ng-select>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { DialogStartComponent } from './dialog-start.component';

@NgModule({
  declarations: [DialogStartComponent],
  exports: [DialogStartComponent],
  imports: [CommonModule, NgbTooltipModule, ReactiveFormsModule, TranslocoModule, SharedModule],
})
export class DialogStartModule {}

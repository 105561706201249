import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  MessageAttachment,
  MessageAttachmentTemporary,
  MessageTelegramContent,
} from '@http/message-part/message-part.types';
import { QuillEditorFormat } from '@panel/app/partials/quill-text-editor/quill-text-editor.component';
import { AbsCVAFormGroupBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-group-based-component';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';

@Component({
  selector: 'cq-text-block-editor',
  templateUrl: './text-block-editor.component.html',
  styleUrls: ['./text-block-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextBlockEditorComponent extends AbsCVAFormGroupBasedComponent<
  ToFormGroupControls<MessageTelegramContent>
> {
  @Output()
  readonly delete = new EventEmitter<void>();

  TEXT_MAX_LENGTH = 4096;

  formats: QuillEditorFormat[] = ['bold', 'italic', 'strike', 'underline', 'link'];

  readonly control: FormGroup<ToFormGroupControls<MessageTelegramContent>> = this.fb.group<
    ToFormGroupControls<MessageTelegramContent>
  >({
    type: this.fb.control<'text'>('text', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    value: this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(this.TEXT_MAX_LENGTH)]),
    // TODO Car-64627
    //attachment: this.fb.control<MessageAttachment | File | null>(null),
    attachment: this.fb.control<MessageAttachment | MessageAttachmentTemporary | null>(null),
  });

  constructor(private readonly fb: FormBuilder) {
    super();
  }
}

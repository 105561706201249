import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/** Компонент для работы с бухгалтерскими емейлами */
@Component({
  selector: 'cq-accounting-emails[accountingEmails]',
  templateUrl: './accounting-emails.component.html',
  styleUrls: ['./accounting-emails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountingEmailsComponent {
  /** Список бухгалтерских емейлов */
  @Input()
  accountingEmails!: string[];

  /** Добавление емейлов */
  @Output()
  addEmails: EventEmitter<void> = new EventEmitter<void>();

  /** Редактирование емейлов */
  @Output()
  editEmails: EventEmitter<void> = new EventEmitter<void>();

  /** Обработчик добавления емейлов */
  onAddEmails(): void {
    this.addEmails.emit();
  }

  /** Обработчик редактирования емейлов */
  onEditEmails(): void {
    this.editEmails.emit();
  }

  /** Строка с перечислением всех бухгалтерских емейлов */
  get formattedAccountingEmails(): string {
    return this.accountingEmails.join(', ');
  }
}

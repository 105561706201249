<cq-knowledge-base
  [billingInfo]="billingInfo"
  [currentApp]="currentApp"
  [djangoUser]="djangoUser"
  [isKnowledgeBaseProActivated]="isKnowledgeBaseProActivated"
  [isLoading]="isLoading"
  [settings]="settings"
>
  <cq-category-list
    *ngIf="!isSearch"
    [categories]="categories"
    [currentApp]="currentApp"
    (onDataUpdate)="updateData()"
  >
    <cq-articles-search
      [isSearch]="isSearch"
      (onResetSearch)="resetSearch()"
      (onSubmitSearch)="submitSearch($event)"
      [(searchPhrase)]="searchPhrase"
    ></cq-articles-search>
  </cq-category-list>
  <cq-articles-list
    *ngIf="isSearch"
    [articles]="articles"
    [articlesCount]="articlesCount"
    [billingInfo]="billingInfo"
    [categories]="categories"
    [currentApp]="currentApp"
    [isSearch]="isSearch"
    (onDataUpdate)="updateData()"
    (onResetSearch)="resetSearch()"
  >
    <cq-articles-search
      [isSearch]="isSearch"
      (onResetSearch)="resetSearch()"
      (onSubmitSearch)="submitSearch($event)"
      [(searchPhrase)]="searchPhrase"
    ></cq-articles-search>
  </cq-articles-list>
</cq-knowledge-base>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { EditSavedReplyModalModule } from '@panel/app/pages/conversations-settings/shared/components/edit-saved-reply-modal/edit-saved-reply-modal.module';
import { SavedRepliesNewModule } from '@panel/app/partials/saved-replies-new/saved-replies-new.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { SavedRepliesComponent } from './saved-replies.component';

@NgModule({
  declarations: [SavedRepliesComponent],
  imports: [
    CommonModule,
    SavedRepliesNewModule,
    SharedModule,
    TranslocoModule,
    FormsModule,
    EditSavedReplyModalModule,
    ReactiveFormsModule,
  ],
  exports: [SavedRepliesComponent],
})
export class SavedRepliesModule {}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import html2canvas from 'html2canvas';
import { from, switchMap, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { TEAM_MEMBER_DEFAULT_AVATAR_URL } from '@http/team-member/team-member.constants';
import { DestroyService } from '@panel/app/services';
import { TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { ColorService } from '@panel/app/services/color/color.service';
import { MessagePreviewScreenshotService } from '@panel/app/services/message-preview-screenshot/message-preview-screenshot.service';
import { PREVIEW_MAX_HEIGHT_PX } from '@panel/app-old/shared/services/message-preview-image/message-preview-image.constants';

@Component({
  selector: 'cq-carrot-notification-frame',
  templateUrl: './carrot-notification-frame.component.html',
  styleUrls: ['./carrot-notification-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [DestroyService],
})
export class CarrotNotificationFrameComponent implements AfterViewInit {
  @Input({ required: true })
  app!: App;

  @Input({ required: true })
  content!: string;

  @Input({ required: true })
  language!: string;

  @Input({ required: true })
  sender!: any;

  @HostListener('click')
  onClick(event: Event): void {
    return event.preventDefault();
  }

  /** Область, которая используется для скриншота превью в шаге триггерного сообщения */
  @ViewChild('screenshotArea')
  screenshotArea!: ElementRef<HTMLElement>;

  constructor(
    private readonly colorService: ColorService,
    private readonly destroyService: DestroyService,
    private readonly messagePreviewScreenshotService: MessagePreviewScreenshotService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngAfterViewInit(): void {
    this.initScreenshotRequestObserver();
  }

  getRgbFromHex(hex: string): string | null {
    return this.colorService.getRgbFromHex(hex);
  }

  getSenderAvatarPath(): string {
    if (this.sender) {
      return this.sender.avatar;
    }

    return TEAM_MEMBER_DEFAULT_AVATAR_URL;
  }

  getSenderName(): string {
    if (this.sender) {
      return this.sender.name;
    }

    return this.translocoService.translate(
      `carrotNotificationFrameComponent.messageInputPlaceholder.${this.language}.defaultSenderName`,
    );
  }

  initScreenshotRequestObserver(): void {
    this.messagePreviewScreenshotService.screenshotRequest$
      .pipe(
        takeUntil(this.destroyService),
        tap(() => {
          this.prepareScreenshotArea();
        }),
        switchMap(() =>
          from(
            html2canvas(this.screenshotArea.nativeElement, {
              backgroundColor: null,
              allowTaint: true,
              useCORS: true,
              scale: 2,
            }),
          ),
        ),
      )
      .subscribe((result) => {
        this.messagePreviewScreenshotService.screenshotComplete$.next({
          base64Url: result.toDataURL(),
          heightPx: this.screenshotArea.nativeElement.clientHeight,
        });
      });
  }

  prepareScreenshotArea(): void {
    this.screenshotArea.nativeElement.style.border = '1px solid #c7cad1';
    this.screenshotArea.nativeElement.style.width = TRIGGER_CHAIN_BLOCK_CONTENT_MAX_PX + 'px';
    this.screenshotArea.nativeElement.style.maxHeight = PREVIEW_MAX_HEIGHT_PX + 'px';
  }
}

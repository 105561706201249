/**
 * Директива валидации доменного имени (без точки, исключительно домен n-ного уровня)
 */
(function () {
  'use strict';

  angular.module('myApp.directives.domainPart').directive('cqDomainPart', cqDomainPart);

  function cqDomainPart() {
    var DOMAIN_PART_REGEXP = /^([a-z\d][a-z\d-]*[a-z\d]|[a-z\d])$/;

    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$validators.domainPart = domainPart;

      /**
       * Функция для валидации домена n-ного уровня
       *
       * @param modelValue Значение модели для валидации
       * @returns {Boolean}
       */
      function domainPart(modelValue) {
        // если значение модели пустое - валидация не требуется
        if (controller.$isEmpty(modelValue)) {
          return true;
        }

        return DOMAIN_PART_REGEXP.test(modelValue);
      }
    }
  }
})();

/**
 * Контроллер Названия события
 */
import { AUTO_EVENTS_PARAMS_TYPES } from '../../../../../app/http/track-master/track-master.constants';

(function () {
  'use strict';

  angular.module('myApp.trackMaster').controller('CqAutoEventNameController', CqAutoEventNameController);

  function CqAutoEventNameController(validationHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.AUTO_EVENTS_PARAMS_TYPES = AUTO_EVENTS_PARAMS_TYPES;
      vm.removeDescription = removeDescription;
      vm.validationHelper = validationHelper;
      vm.isShowDescription = getIsShowDescription();
    }

    /**
     * Удаление Описания
     */
    function removeDescription() {
      vm.description = '';
      vm.isShowDescription = false;
    }

    /**
     * Получение начального состояния отображения описания
     *
     * @returns {boolean}
     */
    function getIsShowDescription() {
      return !!vm.description;
    }
  }
})();

import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgSelectComponent } from '@ng-select/ng-select';

import { ArticleCategory } from '@http/article-category/internal-types/article-category.internal.type';
import { AiDataSourcesListStateService } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-sources-list-state.service';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryFilterComponent extends AbsCVAFormControlBasedComponent<string> {
  @ViewChild(NgSelectComponent)
  categoriesSelect!: NgSelectComponent;

  readonly control: FormControl<string> = new FormControl<string>('0', {
    nonNullable: true,
    validators: [Validators.required],
  });

  constructor(
    public readonly store: AiDataSourcesListStateService,
    private readonly translocoService: TranslocoService,
  ) {
    super();
  }

  getCategoryName(category: ArticleCategory) {
    if (category.id === '0') {
      return this.translocoService.translate('categoryFilterComponent.all');
    }

    return category.name;
  }
}

(function () {
  'use strict';

  require('./email-template-preview.controller');

  angular
    .module('myApp.modals.emailTemplatePreview')
    .component('cqEmailTemplatePreviewModal', cqEmailTemplatePreviewModal());

  function cqEmailTemplatePreviewModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp - Данные текущего приложения
        // messageSender - Отправитель письма
      },
      controller: 'CqEmailTemplatePreviewModalController',
      controllerAs: 'vm',
      template: require('./email-template-preview.html'),
    };
  }
})();

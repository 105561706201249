import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';

/** Компонент для работы со стоимостью тарифа */
@Component({
  selector: 'cq-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostComponent {
  constructor(protected readonly subscriptionStore: SubscriptionStore) {}
}

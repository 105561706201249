(function () {
  'use strict';

  require('./message-planning.controller');

  angular.module('myApp.modals.messagePlanning').component('cqMessagePlanning', cqMessagePlanning());

  function cqMessagePlanning() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // appID - ID приложения
        // appTimezone - временная зона (текстовое название)
        // appTimezoneOffset - смещение временной зоны
        // currentMessage - сообщение
        // currentTimestamp - текущий timestamp. Берётся с бэка для точного времени
        // messageParams - параметры сообщения
        // pushSettings - настройки пушей
      },
      controller: 'CqMessagePlanningController',
      controllerAs: 'vm',
      template: require('./message-planning.html'),
    };
  }
})();

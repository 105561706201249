<ng-container *transloco="let t; read: 'amocrmPropsToContactComponent'">
  <div
    class="d-flex align-items-center cursor-pointer"
    (click)="eventTypesCollapse.toggle()"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapse"
  >
    <h4 class="no-margin">
      {{t('heading', {
      projectName,
      integrationTypeName
    })}}
    </h4>
    <div class="ml-auto">{{ userPropsCount }}</div>
    <i
      class="cqi-sm ml-10"
      [ngClass]="{ 'cqi-chevron-up': !isCollapsed, 'cqi-chevron-down': isCollapsed }"
    ></i>
  </div>
  <div
    #eventTypesCollapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="pt-20">
      <!-- Свойства -->
      <div class="mb-15">
        <div class="mb-20">
          {{t('propToAmocrm.description', {
          integrationTypeName,
          projectName
        })}}
        </div>

        <div class="d-flex align-items-center">
          <p class="flex-grow no-flex-basis font-weight-bolder">{{t('propToAmocrm.prop.title', {projectName})}}</p>
          <div class="mx-10 invisible">
            <i class="cqi-sm cqi-arrow-right"></i>
          </div>
          <p class="flex-grow no-flex-basis font-weight-bolder">
            {{t('propToAmocrm.amocrm.title', {integrationTypeName})}}
          </p>
          <button
            class="btn btn-borderless-primary ml-10 invisible"
            type="button"
          >
            <i class="btn-icon cqi-sm cqi-times"></i>
          </button>
        </div>

        <!-- Стандартные свойства -->
        <div
          class="d-flex align-items-center margin-bottom-15"
          *ngFor="let formGroup of standardPropsFormArray.controls; first as first"
        >
          <div class="flex-grow min-width-0 no-flex-basis">
            <ng-select
              [clearable]="false"
              [formControl]="formGroup.controls.key"
              [items]="(amocrmState.userProps$ | async) ?? []"
              [placeholder]="t('propToAmocrm.prop.select.placeholder')"
              [required]="true"
              bindLabel="prettyName"
              bindValue="name"
            ></ng-select>
          </div>

          <div class="mx-10">
            <i class="cqi-sm cqi-arrow-right"></i>
          </div>

          <div class="flex-grow min-width-0 no-flex-basis">
            <cq-amocrm-fields-select
              [fields]="amocrmContactFields"
              [formControl]="formGroup.controls.value"
              [integrationId]="integrationId"
              [type]="'contact'"
            ></cq-amocrm-fields-select>
          </div>

          <button
            class="btn btn-borderless-primary ml-10"
            disabled
            type="button"
          >
            <i class="btn-icon cqi-sm cqi-times"></i>
          </button>
        </div>

        <!-- Кастомные свойства -->
        <div
          class="d-flex align-items-center mb-15"
          *ngFor="let formGroup of userPropsGroup.controls; index as index"
        >
          <div
            class="flex-grow min-width-0 no-flex-basis"
            [cqShowError]="formGroup.controls.value"
          >
            <ng-select
              [clearable]="false"
              [formControl]="formGroup.controls.value"
              [items]="(amocrmState.userProps$ | async) ?? []"
              [placeholder]="t('propToAmocrm.prop.select.placeholder')"
              [required]="true"
              [virtualScroll]="true"
              bindLabel="prettyName"
              bindValue="name"
            >
              <ng-template
                let-item="item"
                ng-option-tmp
              >
                <span class="text-wrap">{{ item.prettyName }}</span>
              </ng-template>
            </ng-select>
            <cq-validation-messages [control]="formGroup.controls.value">
              <cq-validation-message errorName="required">
                {{ t('propToAmocrm.prop.select.errors.required') }}
              </cq-validation-message>
            </cq-validation-messages>
          </div>

          <div class="mx-10">
            <i class="cqi-sm cqi-arrow-right"></i>
          </div>

          <div class="flex-grow min-width-0 no-flex-basis">
            <cq-amocrm-fields-select
              [fields]="amocrmContactFields | duplicatesFields: formGroup.controls.key.value:chosenPropFields"
              [formControl]="formGroup.controls.key"
              [integrationId]="integrationId"
              [type]="'contact'"
            ></cq-amocrm-fields-select>
          </div>

          <button
            class="btn btn-borderless-primary ml-10"
            (click)="removeItem(userPropsGroup, index)"
            type="button"
          >
            <i class="btn-icon cqi-sm cqi-times"></i>
          </button>
        </div>

        <button
          class="btn btn-borderless-primary mt-5"
          (click)="addProperty()"
          type="button"
        >
          <i class="btn-icon-left cqi-sm cqi-plus"></i>
          {{ t('propToAmocrm.addPropButton') }}
        </button>
      </div>

      <!-- События -->
      <div>
        <div class="mb-20">
          {{t('eventTypeToAmocrm.description', {
          projectName,
          integrationTypeName
        })}}
        </div>

        <div
          *ngIf="userEventsTypesGroup.controls.length"
          class="d-flex align-items-center"
        >
          <p class="flex-grow no-flex-basis font-weight-bolder">
            {{t('eventTypeToAmocrm.eventType.title', {projectName})}}
          </p>
          <div class="mx-10 invisible">
            <i class="cqi-sm cqi-arrow-right"></i>
          </div>
          <p class="flex-grow no-flex-basis font-weight-bolder">
            {{t('eventTypeToAmocrm.amocrm.title', {integrationTypeName})}}
          </p>
          <button
            class="btn btn-borderless-primary ml-10 invisible"
            type="button"
          >
            <i class="btn-icon cqi-sm cqi-times"></i>
          </button>
        </div>

        <div
          class="d-flex align-items-center margin-bottom-15"
          *ngFor="let formGroup of userEventsTypesGroup.controls; index as index"
        >
          <div
            class="flex-grow min-width-0 no-flex-basis"
            [cqShowError]="formGroup.controls.value"
          >
            <ng-select
              [clearable]="false"
              [formControl]="formGroup.controls.value"
              [items]="(amocrmState.eventTypeProps$ | async) ?? []"
              [placeholder]="t('eventTypeToAmocrm.eventType.select.placeholder')"
              [required]="true"
              [virtualScroll]="true"
              bindLabel="prettyName"
              bindValue="name"
            >
              <ng-template
                let-item="item"
                ng-option-tmp
              >
                <span class="text-wrap">{{ item.prettyName }}</span>
              </ng-template>
            </ng-select>
            <cq-validation-messages [control]="formGroup.controls.value">
              <cq-validation-message errorName="required">
                {{ t('eventTypeToAmocrm.eventType.select.errors.required') }}
              </cq-validation-message>
            </cq-validation-messages>
          </div>

          <div class="mx-10">
            <i class="cqi-sm cqi-arrow-right"></i>
          </div>

          <div class="flex-grow min-width-0 no-flex-basis">
            <cq-amocrm-fields-select
              [fields]="amocrmContactFields | duplicatesFields: formGroup.controls.key.value:chosenPropFields"
              [formControl]="formGroup.controls.key"
              [integrationId]="integrationId"
              [type]="'contact'"
            ></cq-amocrm-fields-select>
          </div>

          <button
            class="btn btn-borderless-primary ml-10"
            (click)="removeItem(userEventsTypesGroup, index)"
            type="button"
          >
            <i class="btn-icon cqi-sm cqi-times"></i>
          </button>
        </div>

        <button
          class="btn btn-borderless-primary mt-5"
          (click)="addEvent()"
          type="button"
        >
          <i class="btn-icon-left cqi-sm cqi-plus"></i>
          {{ t('eventTypeToAmocrm.addEventTypeButton') }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

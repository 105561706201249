<h2>
  ng-select
  <small class="text-secondary">
    Подробнее читать
    <a
      href="https://ng-select.github.io/ng-select#/data-sources/"
      target="_blank"
      >тут</a
    >
  </small>
</h2>
<div class="text-danger">
  Не смог по нормальному заставить работать в AngualrJS (но справдилвости ради стоит уточнить, что времени на это
  потратил немного), поэтому NG-SELECT нужно использовать только в новых компонентах
</div>

<div class="row">
  <div class="col-6">
    <h3>Обычный селектор</h3>
  </div>
  <div class="col-6">
    <h3>Мультиселектор</h3>
  </div>
</div>

<div class="row margin-bottom-10">
  <div class="col-12">Группы</div>
  <div class="col-6">
    <ng-select
      [clearable]="true"
      [items]="items"
      required
      bindLabel="name"
      groupBy="group"
      placeholder="Placeholder"
      [(ngModel)]="selectedItem"
    ></ng-select>
  </div>
  <div class="col-6">
    <ng-select
      [clearable]="true"
      [items]="items"
      required
      [multiple]="true"
      bindLabel="name"
      placeholder="Placeholder"
      groupBy="group"
      [(ngModel)]="selectedItems"
    ></ng-select>
  </div>
</div>

<div class="row mb-10">
  <div class="col-6">
    Кастомная группа
    <ng-select
      [clearable]="false"
      [items]="items"
      bindLabel="name"
      placeholder="Placeholder"
      groupBy="group"
      [(ngModel)]="selectedItem"
    >
      <ng-template
        ng-optgroup-tmp
        let-item="item"
      >
        <i class="cqi-sm cqi-flag"></i>
        {{ item.group }}
      </ng-template>
    </ng-select>
  </div>
</div>

<div class="row margin-bottom-10">
  <div class="col-12">Header и footer в меню выбора</div>
  <div class="col-6 padding-top-20">
    <ng-select
      [clearable]="false"
      [items]="items"
      bindLabel="name"
      placeholder="Placeholder"
      [(ngModel)]="selectedItem"
    >
      <ng-container *ng-header-tmp>
        <button class="btn btn-primary full-width">Header button</button>
      </ng-container>
      <ng-container *ng-footer-tmp>
        <button class="btn btn-primary full-width">Footer button</button>
      </ng-container>
    </ng-select>
  </div>
  <div class="col-6">
    Кастомный <code>ng-label</code>
    <ng-select
      [clearable]="false"
      [items]="items"
      [multiple]="true"
      bindLabel="name"
      placeholder="Placeholder"
      [(ngModel)]="selectedItems"
    >
      <ng-template
        ng-label-tmp
        let-item="item"
        let-clear="clear"
      >
        <span class="ng-value-label">{{ item.name }}</span>
        <span
          class="ng-value-icon right"
          (click)="clear(item)"
          aria-hidden="true"
          >× Delete</span
        >
      </ng-template>

      <ng-container *ng-header-tmp>
        Можно добавить
        <div class="badge badge-pill badge-primary">все что угодно</div>
      </ng-container>
      <ng-container *ng-footer-tmp>
        <div class="flex flex-row">
          <button class="btn btn-primary flex-grow margin-right-5">1</button>
          <button class="btn btn-primary flex-grow">2</button>
        </div>
      </ng-container>
    </ng-select>
  </div>
</div>

<div class="row margin-bottom-10">
  <div class="col-12">Disabled селекторы</div>
  <div class="col-6">
    <ng-select
      [clearable]="true"
      [items]="items"
      bindLabel="name"
      placeholder="Placeholder"
      [(ngModel)]="selectedItem"
      disabled
    ></ng-select>
  </div>
  <div class="col-6">
    <ng-select
      [clearable]="false"
      [items]="items"
      [multiple]="true"
      bindLabel="name"
      placeholder="Placeholder"
      disabled
      [(ngModel)]="selectedItems"
    ></ng-select>
  </div>
</div>

<div class="row mb-10">
  <div class="col-6">
    Шапка с кнопками, которые выглядят как опции
    <ng-select
      class="ng-select-header-options"
      [clearable]="false"
      #selectorHeaderOptions
      [items]="items"
      bindLabel="name"
      placeholder="Placeholder"
      groupBy="group"
      [(ngModel)]="selectedItem"
    >
      <ng-template ng-header-tmp>
        <button
          (click)="selectorHeaderOptions.close()"
          class="ng-select-header-option"
          type="button"
        >
          Кнопка как опция 1
        </button>
        <button
          (click)="selectorHeaderOptions.close()"
          class="ng-select-header-option"
          type="button"
        >
          Кнопка как опция 2
        </button>
      </ng-template>
    </ng-select>
  </div>
</div>

<div class="row">
  <div class="col-12">Результат</div>
  <div class="col-6">
    <pre [textContent]="selectedItem | json"></pre>
  </div>
  <div class="col-6">
    <pre [textContent]="selectedItems | json"></pre>
  </div>
</div>

import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { AddAppModalStepFirstComponent } from '@panel/app/pages/account/shared/add-app-modal/add-app-modal-step-first/add-app-modal-step-first.component';
import { AddAppModalStepSecondComponent } from '@panel/app/pages/account/shared/add-app-modal/add-app-modal-step-second/add-app-modal-step-second.component';

@Component({
  selector: 'cq-add-app-modal',
  templateUrl: './add-app-modal.component.html',
  styleUrls: ['./add-app-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAppModalComponent {
  /** тип приложения */
  protected appType: string = '';

  @ViewChild(AddAppModalStepFirstComponent) addAppModalStepFirstComponent: any;
  @ViewChild(AddAppModalStepSecondComponent) addAppModalStepSecondComponent: any;

  /**
   * Проверка валидности формы
   *
   * @param form Контроллер формы
   */
  isFormValid(form: any): any {
    if (!form) {
      return;
    }

    if (form.valid) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  }

  markAsTouchedForm(form: any): void {
    form.markAllAsTouched();
  }
}

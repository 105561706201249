import { Injectable } from '@angular/core';
import { filter, toArray } from 'lodash-es';

import { environment } from '@environment';
import { CaseStyleHelper } from '@panel/app/services';
import { UTM_MARKS_SYSTEM_VALUES, UTM_MARKS_TYPES } from '@panel/app/services/utm-mark/utm-mark.constants';

@Injectable({
  providedIn: 'root',
})
export class UtmMarkModel {
  constructor(private readonly caseStyleHelper: CaseStyleHelper) {}

  /**
   * Получение объекта с UTM метками
   */
  getDefault(): any {
    const utm: any = {};

    utm[UTM_MARKS_TYPES.UTM_CAMPAIGN] = {
      isCustom: true,
      values: {
        custom: null,
        system: null,
      },
    };
    utm[UTM_MARKS_TYPES.UTM_CONTENT] = {
      isCustom: true,
      values: {
        custom: null,
        system: null,
      },
    };
    utm[UTM_MARKS_TYPES.UTM_MEDIUM] = {
      isCustom: true,
      values: {
        custom: null,
        system: null,
      },
    };
    utm[UTM_MARKS_TYPES.UTM_SOURCE] = {
      isCustom: true,
      values: {
        custom: null,
        system: null,
      },
    };
    utm[UTM_MARKS_TYPES.UTM_TERM] = {
      isCustom: true,
      values: {
        custom: null,
        system: null,
      },
    };

    return utm;
  }

  /**
   * Получение объекта с предустановленными значениями UTM меток
   */
  getPredefined(): any {
    const predefinedUtmMarks: any = this.getDefault();

    predefinedUtmMarks[UTM_MARKS_TYPES.UTM_CAMPAIGN] = {
      isCustom: false,
      values: {
        custom: null,
        system: UTM_MARKS_SYSTEM_VALUES.MESSAGE_SUBJECT,
      },
    };
    // 27.08.2019 Ваня Берестов: Скрыто, т.к. на данный момент в эту переменную может подставляться только email
    /*predefinedUtmMarks[UTM_MARKS_TYPES.UTM_MEDIUM] = {
      isCustom: false,
      values: {
        custom: null,
        system: UTM_MARKS_SYSTEM_VALUES.MESSAGE_TYPE
      }
    };*/
    predefinedUtmMarks[UTM_MARKS_TYPES.UTM_SOURCE] = {
      isCustom: true,
      values: {
        custom: environment.projectName.toLowerCase().replace(/\s/g, ''),
        system: null,
      },
    };

    return predefinedUtmMarks;
  }

  /**
   * Проверка на пустоту во всех UTM метках
   */
  isEmpty(utmMarks: any): boolean {
    const notEmptyUtmMarks = filter(toArray(utmMarks), (utmMark: any) => {
      return utmMark.isCustom ? !!utmMark.values.custom : !!utmMark.values.system;
    });
    return notEmptyUtmMarks.length === 0;
  }

  /**
   * Парсинг UTM меток во внутренний формат админки
   */
  parseToInternalFormat(serverUtmMarks: any): any {
    const internalUtmMarks = this.getDefault();

    for (const utmMark in serverUtmMarks) {
      if (serverUtmMarks.hasOwnProperty(utmMark)) {
        if (!serverUtmMarks[utmMark].custom) {
          internalUtmMarks[utmMark].isCustom = false;
          internalUtmMarks[utmMark].values.system = serverUtmMarks[utmMark].value;
        } else {
          internalUtmMarks[utmMark].values.custom = serverUtmMarks[utmMark].value;
        }
      }
    }

    return internalUtmMarks;
  }

  /**
   * Парсинг UTM меток в формат сервера
   */
  parseToServerFormat(internalUtmMarks: any): any {
    const serverUtmMarks: any = {};
    for (const utmMark in internalUtmMarks) {
      if (internalUtmMarks.hasOwnProperty(utmMark)) {
        const utmMarkValue = internalUtmMarks[utmMark].isCustom
          ? internalUtmMarks[utmMark].values.custom
          : internalUtmMarks[utmMark].values.system;
        if (utmMarkValue) {
          serverUtmMarks[utmMark] = {
            custom: internalUtmMarks[utmMark].isCustom,
            value: utmMarkValue,
          };
        }
      }
    }
    this.caseStyleHelper.keysToUnderscore(serverUtmMarks);

    return serverUtmMarks;
  }
}

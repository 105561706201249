<ng-container *transloco="let t">
  <h3>{{ t('vkFormsWebhookUrlComponent.heading') }}</h3>
  <div class="margin-bottom-10">{{ t('vkFormsWebhookUrlComponent.description') }}</div>
  <div class="input-group">
    <input
      class="form-control"
      [placeholder]="t('vkFormsWebhookUrlComponent.input.placeholder')"
      [value]="webhookUrl"
      disabled
      type="text"
    />
    <span class="input-group-btn">
      <button
        class="btn btn-primary"
        type="button"
        (click)="copyUrl()"
      >
        <i class="btn-icon-left cqi-sm cqi-copy"></i>
        {{ t('vkFormsWebhookUrlComponent.copyToClipboardButton') }}
      </button>
    </span>
  </div>
</ng-container>

<ng-container *transloco="let t; read: 'whatsAppChannelPopoverWrapperComponent'">
  <div
    [ngbPopover]="popoverTemplate"
    [autoClose]="false"
    triggers="manual"
    popoverClass="dark-theme whats-app-channel-popover"
    container="body"
    [placement]="placement"
  ></div>

  <ng-template #popoverTemplate>
    <div class="font-weight-bold mb-15">{{ t('heading') }}</div>
    <div
      *ngIf="!operator"
      class="mb-15"
    >
      <div class="mb-15">{{ t('admin.description1') }}</div>
      <div>{{ t('admin.description2') }}</div>
    </div>
    <div
      *ngIf="operator"
      class="mb-15"
    >
      <div class="mb-15">{{ t('operator.description1') }}</div>
      <div>{{ t('operator.description2') }}</div>
    </div>

    <button
      class="btn btn-secondary"
      type="button"
      (click)="popover.close()"
    >
      {{ t('okButton') }}
    </button>
    <button
      *ngIf="!operator"
      class="btn btn-borderless-primary ml-10"
      (click)="goToConversationsSettingsPageFromWhatsAppPopover()"
    >
      {{ t('goToChannelsButton') }}
    </button>
  </ng-template>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WizardItemComponent } from './wizard-item.component';

@NgModule({
  declarations: [WizardItemComponent],
  exports: [WizardItemComponent],
  imports: [CommonModule],
})
export class WizardItemModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  forwardRef,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

import { SwitchGoal, TextPosition } from '@panel/app/shared/visual-components/switch/switch';

@Component({
  selector: 'cq-switch-new[formControlName],cq-switch-new[formControl],cq-switch-new[ngModel]',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements OnInit, ControlValueAccessor {
  @Input()
  goal: SwitchGoal = 'check';

  @Input()
  textPosition: TextPosition = 'right'; // Позиция текста

  @ContentChild('trueText')
  trueText: TemplateRef<unknown> | null = null;

  @ContentChild('falseText')
  falseText: TemplateRef<unknown> | null = null;

  switchControl = new UntypedFormControl();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.switchControl.valueChanges.subscribe((value) => this.onChange(value));
  }

  /**
   * Получение темплейта для соответствующего значения switch'а
   */
  get textTemplate(): TemplateRef<unknown> | null {
    return this.switchControl.value ? this.trueText : this.falseText;
  }

  registerOnChange(fn: (v: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.switchControl.disable();
    } else {
      this.switchControl.enable();
    }
  }

  writeValue(value: boolean): void {
    this.switchControl.setValue(value, { emitEvent: false });
    this.cdr.markForCheck();
  }

  /**
   * Отметить контрол формы как touched
   */
  markAsTouched() {
    if (this.switchControl.untouched && this.switchControl.enabled) {
      this.onTouched();
      this.switchControl.markAsTouched();
    }
  }

  get isDisabled(): boolean {
    return this.switchControl.disabled;
  }

  private onChange: (v: boolean) => void = (v: boolean) => {};

  private onTouched: () => void = () => {};
}

(function () {
  'use strict';

  require('./popup-html-block-settings.controller');

  angular.module('myApp.popupEditor').component('cqPopupHtmlBlockSettings', cqPopupHtmlBlockSettings());

  function cqPopupHtmlBlockSettings() {
    return {
      bindings: {
        popupBlockParams: '=', // настройки блока поп-апа
      },
      controller: 'CqPopupHtmlBlockSettingsController',
      controllerAs: 'vm',
      template: require('./popup-html-block-settings.html'),
    };
  }
})();

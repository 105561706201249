import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Компонент-zero-data для счетов */
@Component({
  selector: 'cq-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent {}

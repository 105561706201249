import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Component({
  selector: 'cq-visual-components-page',
  templateUrl: './visual-components-page.component.html',
  styleUrls: ['./visual-components-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisualComponentsPageComponent {
  constructor(private readonly uiRouter: UIRouter) {}

  goToState(name: string): void {
    this.uiRouter.stateService.go(name);
  }
}

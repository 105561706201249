import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationService } from '@http/integration/services/integration.service';
import { AmocrmEventTriggersForContactsModule } from '@panel/app/pages/integrations/content/amocrm/event-triggers-for-contact/amocrm-event-triggers-for-contacts.module';
import { AmocrmPropsToContactModule } from '@panel/app/pages/integrations/content/amocrm/props-to-contact/amocrm-props-to-contact.module';

import { AmocrmDataToContactsComponent } from './amocrm-data-to-contacts.component';

@NgModule({
  declarations: [AmocrmDataToContactsComponent],
  exports: [AmocrmDataToContactsComponent],
  imports: [
    AmocrmEventTriggersForContactsModule,
    AmocrmPropsToContactModule,
    CommonModule,
    NgbCollapseModule,
    NgbTooltipModule,
    TranslocoModule,
  ],
  providers: [IntegrationService],
})
export class AmocrmDataToContactsModule {}

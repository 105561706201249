<ng-container *transloco="let t; read: 'searchComponent'">
  <div class="flex">
    <div class="form-group has-feedback has-feedback-left mb-0">
      <input
        class="form-control"
        [(ngModel)]="searchPhrase"
        (ngModelChange)="onInputSearchPhrase($event)"
        [placeholder]="t('input.placeholder')"
        type="text"
      />

      <span class="form-control-feedback">
        <i class="cqi-sm cqi-search"></i>
      </span>
    </div>

    <button
      *ngIf="searchPhrase"
      class="btn btn-borderless-primary ml-5"
      (click)="onClickResetSearchButton()"
      type="button"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { EventTypeSelectModule } from '../../event-type-select/event-type-select.module';
import { EventTypeSelectTriggerWrapperComponent } from './event-type-select-trigger-wrapper.component';

@NgModule({
  declarations: [EventTypeSelectTriggerWrapperComponent],
  exports: [EventTypeSelectTriggerWrapperComponent],
  imports: [CommonModule, EventTypeSelectModule, FormsModule],
})
export class EventTypeSelectTriggerWrapperModule {}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { App } from '@http/app/app.model';
import { Properties } from '@http/property/property.model';
import { TriggerChainStepFilter } from '@http/trigger-chain/internal-types';
import {
  TRIGGER_CHAIN_FILTER_MODAL_DATA_TOKEN,
  TriggerChainFilterEditorComponent,
} from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-filter-editor/trigger-chain-filter-editor.component';
import { TriggerChainStepEditorInteractionsService } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/trigger-chain-step-editor-interactions.service';
import { TriggerChainEditorStore } from '@panel/app/pages/trigger-chains/editor/trigger-chain-editor.store';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  FORM_SUBMIT_TOKEN,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { DestroyService, ModalHelperService } from '@panel/app/services';

@Component({
  selector: 'cq-trigger-chain-filter-editor-modal-wrap',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class TriggerChainFilterEditorModalWrapComponent implements OnInit {
  @Input({ required: true })
  app!: App;

  @Input({ required: true })
  isValidationStrict: boolean = false;

  @Input({ required: true })
  properties!: Properties;

  @Input({ required: true })
  step!: TriggerChainStepFilter;

  @Output()
  stepChange: Subject<TriggerChainStepFilter> = new Subject();

  constructor(
    private readonly modalService: ModalHelperService,
    private readonly triggerChainStepEditorInteractionsService: TriggerChainStepEditorInteractionsService,
    private readonly triggerChainEditorStore: TriggerChainEditorStore,
    @Inject(FORM_SUBMIT_TOKEN)
    private readonly formSubmit$: Observable<void>,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    private readonly formSubmitSubject: Subject<void>,
    private readonly destroy$: DestroyService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.modalService
      .provide(TRIGGER_CHAIN_FILTER_MODAL_DATA_TOKEN, {
        app: this.app,
        properties: this.properties,
        step: this.step,
        isValidationStrict: this.isValidationStrict,
      })
      .provide(TriggerChainStepEditorInteractionsService, this.triggerChainStepEditorInteractionsService)
      .provide(TriggerChainEditorStore, this.triggerChainEditorStore)
      .provide(FORM_SUBMIT_TOKEN, this.formSubmit$)
      .provide(FORM_SUBMIT_SOURCE_TOKEN, this.formSubmitSubject)
      .provide(FORM_SUBMIT_SOURCE_TOKEN, this.formSubmitSubject)
      .provide(DestroyService, this.destroy$)
      .open(TriggerChainFilterEditorComponent, {
        windowClass: 'modal-fullscreen-right',
        backdrop: 'static',
        keyboard: false,
      })
      .result.then((step) => this.stepChange.next(step));
  }
}

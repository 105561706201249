import { create, enforce, group, omitWhen, test } from 'vest';

import { TriggerChainStepReactionCheck } from '@panel/app/http/trigger-chain/internal-types';
import { TRIGGER_CHAIN_REACTION_TYPE } from '@panel/app/http/trigger-chain/trigger-chain.constants';

export const triggerChainReactionStepValidator = create((step: TriggerChainStepReactionCheck) => {
  group(step.uuid, () => {
    test('name', () => {
      enforce(step.name).isNotEmpty();
    });

    test('meta.nextStep', () => {
      enforce(step.meta.nextStep).isNotEmpty();
    });

    test('meta.nextStepOnFail', () => {
      enforce(step.meta.nextStepOnFail).isNotEmpty();
    });

    test('meta.reactionTime.time', () => {
      enforce(step.meta.reactionTime.time).isNotNull();
    });

    test('meta.reactionTime.time', () => {
      enforce(step.meta.reactionTime.time).lessThan(60 * 60 * 24 * 90);
    });

    test('meta.reactionType', () => {
      enforce(step.meta.reactionType).isNotNull();
    });

    omitWhen(step.meta.reactionType !== TRIGGER_CHAIN_REACTION_TYPE.EVENT_TYPE, () => {
      test('meta.event', () => {
        enforce(step.meta.event).isNotNull();
      });
    });
  });
});

<ng-container *transloco="let t; read: 'triggerChainGeneralStatisticsBreadcrumbsComponent'">
  <ol class="breadcrumb mt-0 mb-0">
    <li class="breadcrumb-item d-flex align-items-center">
      <a uiSref="app.content.triggerChains">{{ t('title') }}</a>
    </li>
    <li
      class="breadcrumb-item active"
      aria-current="page"
    >
      {{ chainName }}
    </li>
  </ol>
</ng-container>

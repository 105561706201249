import { Validators } from '@angular/forms';

import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

interface DeleteDjangoUserModalFormControls {
  password: string;
}

export class DeleteDjangoUserForm extends GenericFormGroup<DeleteDjangoUserModalFormControls> {
  constructor() {
    const controls: Controls<DeleteDjangoUserModalFormControls> = {
      password: new GenericFormControl<string>(null, Validators.required),
    };

    super(controls);
  }
}

import { firstValueFrom } from 'rxjs';
import { MERGE_OPERATIONS, MERGE_OPERATIONS_KEYS } from '../../../../app/http/user/user.constants';

(function () {
  'use strict';

  angular
    .module('myApp.modals.manualUserMerge')
    .controller('CqManualUserMergeModalController', CqManualUserMergeModalController);

  function CqManualUserMergeModalController($q, $scope, $translate, $filter, $uibModal, LongPollConnection, userModel) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.customPropsList = []; // Общий список пользовательских свойств склеиваемых лидов
      vm.mainUserId = ''; // ID пользователя, в которого будет происходить склейка
      vm.mergeRules = {}; // Список правил склейки (как и какие свойства склеиваемых пользователей перейдут в конечного пользователя)
      vm.mergeUsers = mergeUsers;
      vm.propBackground = propBackground;
      vm.resultingUser = {}; // Пользователь, который получиться в результате склейки
      vm.rtsChannels = []; // RTS каналы, которые слушаются для поддержания пользователей в актуальном состоянии при открытом окне
      vm.systemPropsList = []; // Общий список стандартных свойств склеиваемых пользователей
      vm.userDoesntExists = []; // Отметка существования пользователя в базе. Порядок соответствует порядку в vm.users
      vm.users = []; // Склеиваемые пользователи, со всеми их свойствами

      $scope.$watch('vm.mainUserId', constructNewUser); // При изменении основного пользователя
      $scope.$on('message', handleRts);

      firstValueFrom(userModel.getMergeRules()).then(processMergeRules); // Получаем с бека правила мерджа свойств

      function processMergeRules(rules) {
        vm.mergeRules = rules;
        getUsers().then(processUsers); // Актуализируем инфу о пользователях
      }
    }

    /**
     * Собираем конечного пользователя из двух выбранных
     *
     * @param {String} mainUserId ID основного пользователя, в которого будет происходить мердж
     */
    function constructNewUser(mainUserId) {
      var commonCustomPropsList = []; // Общий список пользовательских свойств склеиваемых пользователй
      var commonSystemPropsList = []; // Общий список системных свойств склеиваемых пользователей
      var mainUser = $filter('filter')(vm.users, { id: mainUserId }, true)[0]; // Основной пользователь (новый), в которого будет склеиваться
      var secondaryUser = $filter('filter')(vm.users, { id: '!' + mainUserId }, true)[0]; // Вторичный пользователь (удаляемый), который будет склеен

      // Этот этап нужен для копирования данных вне свойств (имя, плейсхлдер, id и т.д.)
      if (
        (vm.mergeRules.default == MERGE_OPERATIONS_KEYS.NEW ||
          vm.mergeRules.default == MERGE_OPERATIONS_KEYS.NEW_IF_EXISTS) &&
        angular.isDefined(mainUser)
      ) {
        // Если правила по умолчанию определяют свойства нового пользователя в приоритете, то делаем дубликат основного пользователя
        vm.resultingUser = angular.copy(mainUser);
        if (angular.isUndefined(secondaryUser)) {
          // При обновлении по rts может возникнуть ситуация, когда оба склеиваемых пользователя одинаковы
          return;
        }
      } else {
        // В противном случае в приоритете свойства удаляемого пользователя, поэтому делаем его дубликат
        vm.resultingUser = angular.copy(secondaryUser);
        if (angular.isUndefined(mainUser)) {
          // При обновлении по rts может возникнуть ситуация, когда оба склеиваемых пользователя одинаковы
          return;
        }
      }

      // Составляем общие списки свойств, фильтруем их чтобы не было дубликатов
      for (var i = 0; i < vm.users.length; i++) {
        commonSystemPropsList = $filter('unique')(
          $filter('concat')(commonSystemPropsList, Object.keys(vm.users[i].props)),
        );
        commonCustomPropsList = $filter('unique')(
          $filter('concat')(commonCustomPropsList, Object.keys(vm.users[i].propsCustom)),
        );
      }

      // Обновляем системные свойства конечного пользователя в соответствии с правилами склейки
      for (var i = 0; i < commonSystemPropsList.length; i++) {
        if (commonSystemPropsList[i] in vm.mergeRules) {
          vm.resultingUser.props[commonSystemPropsList[i]] = MERGE_OPERATIONS[vm.mergeRules[commonSystemPropsList[i]]](
            mainUser.props[commonSystemPropsList[i]],
            secondaryUser.props[commonSystemPropsList[i]],
          );
        } else {
          vm.resultingUser.props[commonSystemPropsList[i]] = MERGE_OPERATIONS[vm.mergeRules.default](
            mainUser.props[commonSystemPropsList[i]],
            secondaryUser.props[commonSystemPropsList[i]],
          );
        }
      }

      // Обновляем пользовательские свойства конечного пользователя в соответствии с правилом по умолчанию
      for (var i = 0; i < commonCustomPropsList.length; i++) {
        vm.resultingUser.propsCustom[commonCustomPropsList[i]] = MERGE_OPERATIONS[vm.mergeRules.default](
          mainUser.propsCustom[commonCustomPropsList[i]],
          secondaryUser.propsCustom[commonCustomPropsList[i]],
        );
      }
    }

    /**
     * Загрузка конкретного пользователя
     *
     * @param {String} userId ID пользователя, которого загружаем
     */
    function getUser(userId) {
      return firstValueFrom(userModel.get(userId)).then(getUserSuccess, getUserError);

      function getUserError(response) {
        vm.userDoesntExists.push(true);
        return $q.reject();
      }

      function getUserSuccess(user) {
        user.props.$banned = user.props.$banned || false;
        vm.userDoesntExists.push(false);
        return user;
      }
    }

    /**
     * Загрузка полного списка свойств, для склеиваемых пользователей
     *
     * @returns {Promise}
     */
    function getUsers() {
      var promises = [];

      for (var i = 0; i < vm.resolve.users.length; i++) {
        promises.push(getUser(vm.resolve.users[i].id));
      }

      return $q.all(promises);
    }

    /**
     * Нужно перехватывать RTS-сообщения при удалении или склейке текущих пользователей, чтобы в соответствии с этим обновлять карточку
     *
     * @param event
     * @param info
     */
    function handleRts(event, info) {
      var channel = info.channel,
        data = info.data;

      if (channel.indexOf('user_merge_finished.') == 0) {
        let callApply = false;

        // если один из текущих пользователей склеился в нового - надо получить информацию о новом пользователе
        var removedUser = $filter('filter')(vm.users, { id: data.removed }, true);
        if (removedUser.length > 0) {
          var newUserId = data.new;
          callApply = true;

          getUser(newUserId).then(function (user) {
            var removedUserIndex = vm.users.indexOf(removedUser[0]);
            vm.users.splice(removedUserIndex, 1, user);
            processUsers(vm.users);
            callApply && $scope.$applyAsync();
          });
        }
      } else if (channel.indexOf('users_removed.') == 0) {
        let callApply = false;

        // при удалении приходит массив ids с ID удалённых пользователей
        for (var i = 0; i < vm.users.length; i++) {
          if (~data.ids.indexOf(vm.users[i].userId)) {
            vm.userDoesntExists[i] = true;
            callApply = true;
          }
        }

        callApply && $scope.$applyAsync();
      } else if (channel.indexOf('user_presence_changed.') === 0) {
        let callApply = false;

        for (var i = 0; i < data.length; i++) {
          for (var j = 0; j < vm.users.length; j++) {
            if (vm.users[j].id == data[i].user) {
              vm.users[j].presence = data[i].presence;
              callApply = true;
            }
          }
        }

        callApply && $scope.$applyAsync();
      }
    }

    /**
     * Склеивание пользователй
     */
    function mergeUsers() {
      var mainUser = $filter('filter')(vm.users, { id: vm.mainUserId }, true)[0];
      var removingUser = $filter('filter')(vm.users, { id: '!' + vm.mainUserId }, true)[0];

      if (
        (mainUser.props.$user_id || mainUser.props.$app_user_id) &&
        (removingUser.props.$user_id || removingUser.props.$app_user_id)
      ) {
        openErrorModal($translate.instant('modals.manualUserMerge.mergeErrors.bothHaveUserID'));
        return;
      }

      if (removingUser.presence != 'offline') {
        openErrorModal($translate.instant('modals.manualUserMerge.mergeErrors.removingUserActive'));
        return;
      }

      firstValueFrom(userModel.merge(mainUser.id, removingUser.id)).then(mergeSuccess, mergeFail);

      function mergeSuccess(message) {
        if (vm.rts) {
          vm.rts.destroy.resolve();
        }
        vm.modalInstance.close(vm.mainUserId);
      }

      function mergeFail(error) {
        if (error == 'HasAppUserIdError') {
          openErrorModal($translate.instant('modals.manualUserMerge.mergeErrors.bothHaveUserID'));
        }
      }
    }

    /**
     * Обработка обоих склеиваемых пользователей
     * (Списки общих свойств, подписка на RTS)
     *
     * @param {Array.<Object>} users Массив пользователей
     */
    function processUsers(users) {
      vm.users = users;

      vm.customPropsList = []; // Список выводимых пользовательских свойств
      vm.systemPropsList = []; // Список выводимых системных свойств
      vm.rtsChannels = [];

      for (var i = 0; i < vm.users.length; i++) {
        vm.rtsChannels.push('user_props_changed.' + vm.resolve.currentApp.id + '.' + vm.users[i].id);
        vm.customPropsList = $filter('concat')(vm.customPropsList, Object.keys(vm.users[i].propsCustom));
        vm.systemPropsList = $filter('concat')(vm.systemPropsList, Object.keys(vm.users[i].props));
      }

      vm.customPropsList = $filter('unique')(vm.customPropsList);
      vm.systemPropsList = $filter('unique')(vm.systemPropsList);

      var ignore_props = ['$email', '$phone', '$name', '$name_placeholder']; // Контакты выводяться отдельно, поэтому уберем их из списка

      // HACK: Т.к. свойства выводятся ng-repeat'ом, а не хардкодом, как обычно, для адекватной читаемости таблицы ограничиваем свойства наличием перевода
      var cleanedSystemPropsList = angular.copy(vm.systemPropsList);
      for (var i = 0; i < vm.systemPropsList.length; i++) {
        var key = 'models.property.userSystemProperties.' + vm.systemPropsList[i];
        var translation = $translate.instant(key); // Проверяем наличие перевода для свойства, если нет, то не выводим
        var spliceIndex = cleanedSystemPropsList.indexOf(vm.systemPropsList[i]);
        if (ignore_props.indexOf(vm.systemPropsList[i]) > -1 || translation == key) {
          cleanedSystemPropsList.splice(spliceIndex, 1);
        }
      }
      vm.systemPropsList = cleanedSystemPropsList;

      updateResultingUser(vm.users); // Собираем из всего выше полученного конечного пользователя

      if (vm.rts) {
        vm.rts.destroy.resolve();
      }

      vm.rts = LongPollConnection(vm.rtsChannels, function (channel, message) {
        if (channel.indexOf('user_props_changed.') == 0) {
          var userId = channel.replace('user_props_changed.' + vm.resolve.currentApp.id + '.', '');
          if ($filter('filter')(vm.users, { id: userId }, true).length) {
            getUser(userId).then(updateUser);
          }
        }

        function updateUser(user) {
          var oldUser = $filter('filter')(vm.users, { id: user.id }, true)[0];
          var i = vm.users.indexOf(oldUser);
          if (i > -1) {
            vm.users[i] = user;
            updateResultingUser(vm.users);
            $scope.$applyAsync();
          }
        }
      });
    }

    /**
     * Подсветка свойств, в зависимости от попадания в конечного пользователя
     *
     * @param {String} propKey Ключ свойства
     * @param {*} resultingUserProp Свойства конечного пользователя
     * @param {String} currentUserId ID текущего пользователя
     * @param {*} currentUserProp Свойство текущего пользователя
     * @param {*} otherUserProp Свойство второго пользователя
     * @returns {String} Класс подсветки
     */
    function propBackground(propKey, resultingUserProp, currentUserId, currentUserProp, otherUserProp) {
      if (
        propKey in vm.mergeRules &&
        (vm.mergeRules[propKey] == MERGE_OPERATIONS_KEYS.SUM ||
          vm.mergeRules[propKey] == MERGE_OPERATIONS_KEYS.SET_UNION ||
          vm.mergeRules[propKey] == MERGE_OPERATIONS_KEYS.AND ||
          vm.mergeRules[propKey] == MERGE_OPERATIONS_KEYS.SET_INTERSECTION ||
          vm.mergeRules[propKey] == MERGE_OPERATIONS_KEYS.LIST_CONCAT)
      ) {
        //Все ссумируемые/объединяемые свойства окрашиваются в зелёный цвет
        if (angular.isUndefined(currentUserProp) && angular.isUndefined(otherUserProp)) {
          return 'bg_added'; // Подсветка у конечного пользователя
        } else {
          return 'bg_adding'; // Подсветка у склеиваемых пользователей
        }
      } else if (
        angular.isDefined(currentUserProp) &&
        angular.isDefined(resultingUserProp) &&
        angular.equals(resultingUserProp, currentUserProp) &&
        (angular.equals(currentUserId, vm.mainUserId) || !angular.equals(currentUserProp, otherUserProp))
      ) {
        return 'bg_selected'; // Если выбирается одно из двух свойств, то окрашивается в голубой (при совпадении значений, берёться свойство текущего выбранного пользователя)
      } else {
        return '';
      }
    }

    /**
     * Инициирует обновление конечного пользователя
     *
     * @param {Array.<Object>} users Массив пользователей
     */
    function updateResultingUser(users) {
      if (vm.mainUserId && $filter('filter')(users, { id: vm.mainUserId }, true).length) {
        constructNewUser(vm.mainUserId);
      } else if (vm.users.length > 1) {
        vm.mainUserId = users[0].id || vm.mainUserId;
      }
    }

    /**
     * Открытие модалки с ошибкой склейки
     *
     * @param {String} error Текст ошибки
     */
    function openErrorModal(error) {
      var modalInstance = $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('modals.manualUserMerge.mergeErrors.heading'),
              body: error,
              cancelButtonClass: 'btn-primary',
              cancelButtonText: $translate.instant('general.close'),
              confirmButtonClass: 'hidden',
            };
          },
        },
      });
    }
  }
})();

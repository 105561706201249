import { STARTER_GUIDE_STEPS } from '../../../app/http/starter-guide/starter-guide.constants';

(function () {
  'use strict';

  angular
    .module('myApp.conversationWithSelf')
    .controller('CqConversationWithSelfController', CqConversationWithSelfController);

  function CqConversationWithSelfController(
    $scope,
    $state,
    $translate,
    $window,
    CDN_ENDPOINT,
    CONVERSATION_WITH_SELF_WINDOW_PARAMS,
    FIRST_DIALOG_URL,
    carrotquestHelper,
    starterGuideModel,
  ) {
    let vm = this;

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      vm.isStarterGuideWriteSelfStepMade = isStarterGuideWriteSelfStepMade;
      vm.openConversationWithSelfWindow = openConversationWithSelfWindow;
      vm.isConversationWithSelfWindowOpened = isConversationWithSelfWindowOpened;
    }

    function destroy() {
      if ($window[CONVERSATION_WITH_SELF_WINDOW_PARAMS.NAME]) {
        removeEventListeners();
      }
    }

    /** Добавление слушателей сообщений от window чата с самим собой */
    function addEventListeners() {
      $window.addEventListener('message', onCloseConversationWithSelfWindow);
      $window.addEventListener('message', onOpenChatWithSelf);
      $window.addEventListener('message', onStartConversation);
    }

    /** Закрытие window диалога с самим собой */
    function closeConversationWithSelfWindow() {
      $window[CONVERSATION_WITH_SELF_WINDOW_PARAMS.NAME] = null;

      removeEventListeners();

      trackCloseConversationWithSelfWindow();

      if (!isConversationsPage()) {
        redirectToConversationsPage();
      }
    }

    /** Получение url window для чата с самим собой */
    function getConversationWithSelfUrl() {
      return (
        FIRST_DIALOG_URL + `?api_key=${vm.currentApp.api_key}` + `&lang=${$translate.use()}` + `&cdn=${CDN_ENDPOINT}`
      );
    }

    /**
     * Получение параметров window для чата с самим собой
     *
     * @param {object} windowParams - Параметры window для чата с самим собой
     *
     * @return {string}
     */
    function getConversationWithSelfWindowOptions(windowParams) {
      return [
        'toolbar=no',
        'menubar=no',
        'location=no',
        'status=no',
        'scrollbars=no',
        `width=${windowParams.width}`,
        `height=${windowParams.height}`,
        `left=${windowParams.left}`,
        `top=${windowParams.top}`,
      ].join(',');
    }

    /**
     * Является ли страница, на которой находится пользователь, страницей диалогов
     *
     * @return {boolean}
     */
    function isConversationsPage() {
      return $state.current.name === 'app.content.conversations.general';
    }

    /**
     * Открыт ли window диалога с самим собой
     *
     * @return {Boolean}
     */
    function isConversationWithSelfWindowOpened() {
      return !!$window[CONVERSATION_WITH_SELF_WINDOW_PARAMS.NAME];
    }

    /**
     * Является ли страница, на которой находится пользователь, страницей стартергайда
     *
     * @return {boolean}
     */
    function isStarterGuidePage() {
      return $state.current.name === 'app.content.starterGuide';
    }

    /**
     * Пройден ли шаг "Напиши себе сам" в стартергайде
     *
     * @return {Boolean}
     */
    function isStarterGuideWriteSelfStepMade() {
      return starterGuideModel.isStepIsMade(STARTER_GUIDE_STEPS.WRITE_SELF);
    }

    /**
     * Обработчик закрытия window диалога с самим собой
     *
     * @param event
     */
    function onCloseConversationWithSelfWindow(event) {
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data === 'close') {
          closeConversationWithSelfWindow();

          // Нужно, чтобы кнопка "Открыть чат" меняла состояние моментально
          $scope.$applyAsync(() => {});
        }
      }
    }

    /**
     * Обработчик открытия чата в window диалога с самим собой
     *
     * @param event
     */
    function onOpenChatWithSelf(event) {
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data === 'chatOpen') {
          trackOpenChatWithSelf();
        }
      }
    }

    /**
     * Обработчик старта диалога в window диалога с самим собой
     *
     * @param event
     */
    function onStartConversation(event) {
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data === 'startConversation') {
          starterGuideModel.setStepIsMade(vm.currentApp.id, STARTER_GUIDE_STEPS.WRITE_SELF).subscribe();

          trackStarterGuideStepWriteSelfCompletion();
        }
      }
    }

    /** Открытие window для чата с самим собой */
    function openConversationWithSelfWindow() {
      const windowParams = {
        width: CONVERSATION_WITH_SELF_WINDOW_PARAMS.WIDTH,
        height: CONVERSATION_WITH_SELF_WINDOW_PARAMS.HEIGHT,
        left: $window.screenLeft + $window.innerWidth - CONVERSATION_WITH_SELF_WINDOW_PARAMS.WIDTH - 10,
        top: $window.screenTop + $window.innerHeight - CONVERSATION_WITH_SELF_WINDOW_PARAMS.HEIGHT + 20,
      };

      /*
       HACK:
         Присваиваем открываемое окно глобальной переменной,
         чтобы вне зависимости от life cycle hook's компонента, сделать редирект на страницу диалогов
         и удалить не нужные event listener'ы
      */
      const windowUrl = getConversationWithSelfUrl();
      const windowOptions = getConversationWithSelfWindowOptions(windowParams);
      $window[CONVERSATION_WITH_SELF_WINDOW_PARAMS.NAME] = $window.open(windowUrl, null, windowOptions);

      addEventListeners();

      trackClickOnOpenChatButton();

      // Нужно, чтобы кнопка "Открыть чат" меняла состояние моментально
      $scope.$applyAsync(() => {});
    }

    /** Перенаправление пользователя на страницу диалогов */
    function redirectToConversationsPage() {
      $state.go('app.content.conversations.general', {
        ...$state.params,
        fromStarterGuideStep: STARTER_GUIDE_STEPS.WRITE_SELF,
      });
    }

    /** Удаление слушателей сообщений от window чата с самим собой */
    function removeEventListeners() {
      $window.removeEventListener('message', onCloseConversationWithSelfWindow);
      $window.removeEventListener('message', onOpenChatWithSelf);
      $window.removeEventListener('message', onStartConversation);
    }

    /** Трекинг клика по кнопке "Открыть чат" */
    function trackClickOnOpenChatButton() {
      carrotquestHelper.track('Click', {
        test: '09012020',
        type: 'open_chat',
      });
    }

    /** Трекинг закрытия window диалога с самим собой */
    function trackCloseConversationWithSelfWindow() {
      carrotquestHelper.track('Click', {
        type: 'test',
        test: '09012020',
        name: 'close_chat_window',
      });
    }

    /** Трекинг открытия чата в window диалога с самим собой */
    function trackOpenChatWithSelf() {
      carrotquestHelper.track('Pageview', {
        test: '09012020',
        type: 'chat_opened',
      });
    }

    /** Трекинг завершения шага "Напиши себе сам" в стартергайде */
    function trackStarterGuideStepWriteSelfCompletion() {
      const eventName = 'Стартергайд - отправлено тестовое сообщение в чат';

      carrotquestHelper.track(eventName);
    }
  }
})();

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

/**
 *  Сервис со вспомогательными запросами к серверу
 */
@Injectable({ providedIn: 'root' })
export class UtilsModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Асинхронная серверная валидация
   *
   * @param {Object} settings Параметры запроса
   * @param {String} settings.validator Тип, которому должно удовлетворять значение
   * @param {Object=} settings.validatorArguments Параметры валидации
   * @param {*} settings.value Значение, которое надо провалидировать на сервере
   */
  asyncValidate(settings: any): Observable<{ valid: boolean }> {
    const params: any = {
      ignoreErrors: true,
      ignoreLoadingBar: true,
      validator: settings.validator,
      validator_arguments: settings.validatorArguments,
      value: settings.value,
    };

    return this.http.get<{ valid: boolean }>('/utils/validate/', { params });
  }

  /**
   * Получение токена beefree
   *
   * @param {String} appId ID приложения
   */
  getBeeFreeToken(appId: string) {
    const params: any = {
      app: appId,
    };

    return this.http.get('/utils/beefreetoken', {
      params,
      context: new HttpContext().set(EXTENDED_RESPONSE, true).set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
    });
  }

  /**
   * Получение текущего timestamp
   */
  getTimestamp() {
    return this.http.get<any>('/status/timestamp').pipe(
      map((data) => {
        return moment(data.timestamp * 1000);
      }),
    );
  }

  /**
   * Логирование на сервер
   *
   * @param {String} messageType - Группа логов
   * @param {String} message - Название сообщения для лога
   * @param {Object=} data={} - Данные для логирования
   * @param {Object=} dataPlain
   */
  saveLog(messageType: string, message: string, data: any, dataPlain: any) {
    const body: any = {
      message_type: messageType,
      message: message,
      data: data,
      data_plain: dataPlain,
    };

    return this.http.post<unknown>('/utils/logs', body);
  }
}

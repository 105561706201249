import { CONFIGURABLE_INTEGRATION_TYPES } from '../../../app/http/integration/constants/integration.constants';
import { AlbatoWrapperSettingsComponent } from '../../../app/pages/integrations/content/albato/wrapper-settings/albato-wrapper-settings.component';
import { AllokaComponent } from '../../../app/pages/integrations/content/alloka/alloka.component';
import { AmocrmWrapperSettingsComponent } from '../../../app/pages/integrations/content/amocrm/wrapper-settings/amocrm-wrapper-settings.component';
import { EmailNotificationWrapperComponent } from '../../../app/pages/integrations/content/email-notification/wrapper/email-notification-wrapper.component';
import { GoogleAnalyticsComponent } from '../../../app/pages/integrations/content/google-analytics/google-analytics.component';
import { TelegramWrapperComponent } from '../../../app/pages/integrations/content/telegram/wrapper/telegram-wrapper.component';
import { VkFormsWrapperComponent } from '../../../app/pages/integrations/content/vk-forms/wrapper/vk-forms-wrapper.component';
import { VkComponent } from '../../../app/pages/integrations/content/vk/vk.component';
import { WhatsAppEdnaComponent } from '../../../app/pages/integrations/content/whats-app-edna/whats-app-edna.component';
import { IntegrationListComponent } from '../../../app/pages/integrations/integration-list/integration-list.component';
import { IntegrationPreviewComponent } from '../../../app/pages/integrations/integration-preview/integration-preview.component';
import { IntegrationDescriptionComponent } from '../../../app/pages/integrations/partials/integration-description/integration-description.component';
import { IntegrationOAuthHandlerComponent } from '../../../app/pages/integrations/partials/integration-o-auth-handler/integration-o-auth-handler.component';
import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';
import { DJANGO_USER_INTEGRATION_TYPES } from '../../../app/http/django-user-integration/django-user-integration.constants';
import { CalendlyComponent } from '../../../app/pages/integrations/content/calendly/calendly.component';

(function () {
  'use strict';

  angular.module('myApp.integrations').config(config).run(run);

  /**
   * Конфигурация роутов интеграций
   *
   * @param $stateProvider
   * @param INTEGRATION_TYPES
   */
  function config($stateProvider, INTEGRATION_TYPES) {
    $stateProvider
      .state('app.content.integrations', {
        url: '/integrations',
        redirectTo: 'app.content.integrations.general',
        template:
          '\
          <section id="content">\
            <div class="container-fluid limited-container" ui-view autoscroll></div>\
          </section>\
        ',
        data: {
          roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
        },
      })

      .state('app.content.integrations.general', {
        url: '',
        component: IntegrationListComponent,
        resolve: {
          configuredIntegrations: getConfiguredIntegrations,
          integrationsLimit: getIntegrationsLimit,
        },
      })

      .state('app.content.integrations.details', {
        url: '/{integrationType}',
        redirectTo: 'app.content.integrations.details.unconfigured',
        template: '\
          <div ui-view="description"></div>\
          <div ui-view="integration"></div>\
        ',
      })

      .state('app.content.integrations.preview', {
        url: '/{integrationType}/preview',
        component: IntegrationPreviewComponent,
        bindings: {
          currentApp: 'currentApp',
          integrations: 'integrations',
        },
        resolve: {
          integrationType: getIntegrationType,
          integrations: getIntegrationsForPreviewPage,
          telegramBotAllList: (chatBotModel, integrationType, telegramBotStore) => {
            if (integrationType === INTEGRATION_TYPES.TELEGRAM) {
              return getTelegramBotAllList(chatBotModel, telegramBotStore);
            }

            return new Promise((resolve) => resolve([]));
          },
        },
      })

      // NOTE: три нижних состояния специально не сделаны абстрактными, чтобы на них можно было переходить! Если на них нельзя будет переходить - хуки onEnter в секции run не будут отрабаывать
      .state('app.content.integrations.details.unconfigured', {
        url: '',
        views: {
          description: {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
            },
            component: IntegrationDescriptionComponent,
          },
        },
        resolve: {
          integration: getIntegration,
        },
      })

      .state('app.content.integrations.details.configured', {
        // HACK: query-параметры code и state внесёны сюда из-за bitrix24, т.к. старая авторизация вела непосредственно на настроенную интеграцию с bitrix24
        url: '/{integrationId:id}?code&state',
        views: {
          description: {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
            },
            component: IntegrationDescriptionComponent,
          },
        },
        params: {
          code: {
            dynamic: true,
          },
        },
        resolve: {
          integration: getIntegration,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.ALBATO, {
        views: {
          bindings: {
            currentApp: 'currentApp',
          },
          'integration@app.content.integrations.details': {
            component: AlbatoWrapperSettingsComponent,
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.ALLOKA, {
        views: {
          'integration@app.content.integrations.details': {
            component: AllokaComponent,
          },
        },
        params: {
          isSimpleIntegration: true,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.AMOCRM, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              autoEvents: 'autoEvents',
              chatBots: 'chatBots',
              currentApp: 'currentApp',
              integrationExternal: 'integration',
              properties: 'properties',
              triggerMessages: 'triggerMessages',
            },
            component: AmocrmWrapperSettingsComponent,
          },
        },
        resolve: {
          autoEvents: getAutoEvents,
          chatBots: getChatBots,
          triggerMessages: getTriggerMessages,
          properties: getProperties,
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.AMOCRM, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              autoEvents: 'autoEvents',
              chatBots: 'chatBots',
              integrationExternal: 'integration',
              properties: 'properties',
              triggerMessages: 'triggerMessages',
            },
            component: AmocrmWrapperSettingsComponent,
          },
        },
        resolve: {
          autoEvents: getAutoEvents,
          triggerMessages: getTriggerMessages,
          chatBots: getChatBots,
          properties: getProperties,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.BITRIX24, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/bitrix24/bitrix24.html',
            controller: 'Bitrix24Controller',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.BITRIX24, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/bitrix24/bitrix24.html',
            controller: 'Bitrix24Controller',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.BITRIX1C, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/bitrix1c/bitrix1c.html',
            controller: 'Bitrix1cController',
            controllerAs: 'vm',
          },
        },
        params: {
          isSimpleIntegration: true,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.CALENDLY, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              integration: 'integration',
            },
            component: CalendlyComponent,
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.CALENDLY, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              integrationExternal: 'integration',
            },
            component: CalendlyComponent,
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.DIALOGFLOW, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/dialogflow/dialogflow.html',
            controller: 'DialogflowController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.DIALOGFLOW, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/dialogflow/dialogflow.html',
            controller: 'DialogflowController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.ECWID, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/ecwid/ecwid.html',
            controller: 'EcwidController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.ECWID, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/ecwid/ecwid.html',
            controller: 'EcwidController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.EMAIL, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/email/email.html',
            controller: 'EmailController',
            controllerAs: 'vm',
          },
        },
        params: {
          isSimpleIntegration: true,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.EMAIL_NOTIFICATION, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              autoMessages: 'autoMessages',
              chatBots: 'chatBots',
              currentApp: 'currentApp',
              emailRecipients: 'emailRecipients',
              integrationExternal: 'integration',
              properties: 'properties',
              teamMembers: 'teamMembers',
            },
            component: EmailNotificationWrapperComponent,
          },
        },
        resolve: {
          autoMessages: getTriggerMessages,
          chatBots: getChatBots,
          emailRecipients: getEmailRecipients,
          properties: getProperties,
          teamMembers: getTeamMembers,
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.EMAIL_NOTIFICATION, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              autoMessages: 'autoMessages',
              chatBots: 'chatBots',
              currentApp: 'currentApp',
              emailRecipients: 'emailRecipients',
              integrationExternal: 'integration',
              properties: 'properties',
              teamMembers: 'teamMembers',
            },
            component: EmailNotificationWrapperComponent,
          },
        },
        resolve: {
          autoMessages: getTriggerMessages,
          chatBots: getChatBots,
          emailRecipients: getEmailRecipients,
          properties: getProperties,
          teamMembers: getTeamMembers,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.FACEBOOK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/facebook/facebook.html',
            controller: 'FacebookController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.FACEBOOK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/facebook/facebook.html',
            controller: 'FacebookController',
            controllerAs: 'vm',
          },
        },
      })
      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.INSTAGRAM, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/instagram/instagram.html',
            controller: 'InstagramController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.INSTAGRAM, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/instagram/instagram.html',
            controller: 'InstagramController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.FACEBOOK_LEADS, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/facebook-leads/facebook-leads.html',
            controller: 'FacebookLeadsController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.FACEBOOK_LEADS, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/facebook-leads/facebook-leads.html',
            controller: 'FacebookLeadsController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.GOOGLE_ANALYTICS, {
        views: {
          'integration@app.content.integrations.details': {
            component: GoogleAnalyticsComponent,
          },
        },
        params: {
          isSimpleIntegration: true,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.INSALES, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/insales/insales.html',
            controller: 'InSalesController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.INSALES, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/insales/insales.html',
            controller: 'InSalesController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.JSSCRIPT, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/jsscript/jsscript.html',
            controller: 'JsScriptController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.JSSCRIPT, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/jsscript/jsscript.html',
            controller: 'JsScriptController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.LAMBDASCRIPT, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/lambdascript/lambdascript.html',
            controller: 'AwsLambdaController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.LAMBDASCRIPT, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/lambdascript/lambdascript.html',
            controller: 'AwsLambdaController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.MIXPANEL, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/mixpanel/mixpanel.html',
            controller: 'MixpanelController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.MIXPANEL, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/mixpanel/mixpanel.html',
            controller: 'MixpanelController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.MYTARGET, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/mytarget/mytarget.html',
            controller: 'MyTargetController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.MYTARGET, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/mytarget/mytarget.html',
            controller: 'MyTargetController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.OMNIDESK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/omnidesk/omnidesk.html',
            controller: 'OmnideskController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.OMNIDESK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/omnidesk/omnidesk.html',
            controller: 'OmnideskController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.OPENCART, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/opencart/opencart.html',
            controller: 'OpenCartController',
            controllerAs: 'vm',
          },
        },
        params: {
          isSimpleIntegration: true,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.PRESTASHOP, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/prestashop/prestashop.html',
            controller: 'PrestashopController',
            controllerAs: 'vm',
          },
        },
        params: {
          isSimpleIntegration: true,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.RETAILCRM, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/retailcrm/retailcrm.html',
            controller: 'RetailcrmController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.RETAILCRM, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/retailcrm/retailcrm.html',
            controller: 'RetailcrmController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.ROISTAT, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/roistat/roistat.html',
            controller: 'RoistatController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.ROISTAT, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/roistat/roistat.html',
            controller: 'RoistatController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.SEGMENT, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integration: 'integration',
            },
            component: 'cqSegment',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.SEGMENT, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integration: 'integration',
            },
            component: 'cqSegment',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.SENDSAY, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/sendsay/sendsay.html',
            controller: 'SendsayController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.SENDSAY, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/sendsay/sendsay.html',
            controller: 'SendsayController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.SHOPIFY, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/shopify/shopify.html',
            controller: 'ShopifyController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.SHOPIFY, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/shopify/shopify.html',
            controller: 'ShopifyController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.SLACK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/slack/slack.html',
            controller: 'SlackController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.SLACK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/slack/slack.html',
            controller: 'SlackController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.TELEGRAM, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
            },
            component: TelegramWrapperComponent,
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.TELEGRAM, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
            },
            component: TelegramWrapperComponent,
          },
        },
        resolve: {
          telegramBotAllList: getTelegramBotAllList,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.USEDESK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/usedesk/usedesk.html',
            controller: 'UsedeskController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.USEDESK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/usedesk/usedesk.html',
            controller: 'UsedeskController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.VIBER, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/viber/viber.html',
            controller: 'ViberController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.VIBER, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/viber/viber.html',
            controller: 'ViberController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.VK, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
            },
            component: VkComponent,
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.VK, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
            },
            component: VkComponent,
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.VK_FORMS, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
              properties: 'properties',
            },
            component: VkFormsWrapperComponent,
          },
        },
        resolve: {
          properties: getProperties,
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.VK_FORMS, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
              properties: 'properties',
            },
            component: VkFormsWrapperComponent,
          },
        },
        resolve: {
          properties: getProperties,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.WEBHOOK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/webhook/webhook.html',
            controller: 'WebhookController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.WEBHOOK, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/webhook/webhook.html',
            controller: 'WebhookController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.WORDPRESS, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/wordpress/wordpress.html',
            controller: 'WordPressController',
            controllerAs: 'vm',
          },
        },
        params: {
          isSimpleIntegration: true,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.YANDEX_METRICA, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/yandex-metrica/yandex-metrica.html',
            controller: 'YandexMetricaController',
            controllerAs: 'vm',
          },
        },
        params: {
          isSimpleIntegration: true,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.WHATS_APP_EDNA, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
            },
            component: WhatsAppEdnaComponent,
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.WHATS_APP_EDNA, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integrationExternal: 'integration',
            },
            component: WhatsAppEdnaComponent,
          },
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.ZENDESK, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              integration: 'integration',
              properties: 'properties',
            },
            component: 'cqZendesk',
          },
        },
        resolve: {
          properties: getProperties,
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.ZENDESK, {
        views: {
          'integration@app.content.integrations.details': {
            bindings: {
              currentApp: 'currentApp',
              integration: 'integration',
              properties: 'properties',
            },
            component: 'cqZendesk',
          },
        },
        resolve: {
          properties: getProperties,
        },
      })

      .state('app.content.integrations.details.unconfigured.' + INTEGRATION_TYPES.ZAPIER, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/zapier/zapier.html',
            controller: 'ZapierController',
            controllerAs: 'vm',
          },
        },
      })

      .state('app.content.integrations.details.configured.' + INTEGRATION_TYPES.ZAPIER, {
        views: {
          'integration@app.content.integrations.details': {
            templateUrl: 'js/components/integrations/zapier/zapier.html',
            controller: 'ZapierController',
            controllerAs: 'vm',
          },
        },
      })

      // !!! переход на OAuth интеграций и дочерние состояния возможен только из нового окна, открытого при помощи $window.open
      .state('oAuth.integrations', {
        url: '/integrations/{integrationType}?{integrationId:id}&{appId:id}&code&state&auth&error&csrfToken',
      })

      .state('oAuth.integrations.' + INTEGRATION_TYPES.AMOCRM, {
        views: {
          '@': {
            component: IntegrationOAuthHandlerComponent,
          },
        },
      })

      .state('oAuth.integrations.' + INTEGRATION_TYPES.BITRIX24, {
        views: {
          '@': {
            component: 'cqBitrix24OAuth',
          },
        },
      })

      .state('oAuth.integrations.' + INTEGRATION_TYPES.CALENDLY, {
        views: {
          '@': {
            component: IntegrationOAuthHandlerComponent,
          },
        },
      })

      .state('oAuth.integrations.' + INTEGRATION_TYPES.FACEBOOK, {
        views: {
          '@': {
            component: 'cqFacebookOAuth',
          },
        },
      })

      .state('oAuth.integrations.' + INTEGRATION_TYPES.INSTAGRAM, {
        views: {
          '@': {
            component: 'cqInstagramOAuth',
          },
        },
      })

      .state('oAuth.integrations.' + INTEGRATION_TYPES.FACEBOOK_LEADS, {
        views: {
          '@': {
            component: 'cqFacebookLeadsOAuth',
          },
        },
      })

      .state('oAuth.integrations.' + INTEGRATION_TYPES.MYTARGET, {
        views: {
          '@': {
            component: 'cqMyTargetOAuth',
          },
        },
      })

      .state('oAuth.integrations.' + INTEGRATION_TYPES.VK, {
        views: {
          '@': {
            component: IntegrationOAuthHandlerComponent,
          },
        },
      });

    function getAutoEvents(currentApp, trackMasterModel) {
      return firstValueFrom(trackMasterModel.getList(currentApp.id));
    }

    /**
     * Получение списка настроенных интеграций
     *
     * @param CONFIGURABLE_INTEGRATION_TYPES
     * @param featureModel
     * @param integrationModel
     * @param currentApp
     * @return {Promise}
     */
    function getConfiguredIntegrations(CONFIGURABLE_INTEGRATION_TYPES, featureModel, integrationModel, currentApp) {
      // чтобы делать как можно меньше запросов - оставляем только те интеграции, для которых включены фичагалки
      let integrationTypes = Object.values(CONFIGURABLE_INTEGRATION_TYPES).filter((integrationType) =>
        featureModel.hasAccess(integrationType),
      );

      return integrationModel.getList(currentApp.id, integrationTypes);
    }

    /**
     * Получение списка получателей всего App'а
     *
     * @param currentApp - Текущее приложение
     * @param integrationModel - Модель для работы с интеграциями
     *
     * @return {Promise}
     */
    function getEmailRecipients(currentApp, integrationModel) {
      return integrationModel.getEmailRecipients(currentApp.id);
    }

    /**
     * Получение интеграции
     *
     * @param $q
     * @param $state
     * @param $stateParams
     * @param INTEGRATION_TYPES
     * @param djangoUserIntegrationModel
     * @param integrationModel
     * @returns {Promise}
     */
    function getIntegration($q, $state, $stateParams, INTEGRATION_TYPES, djangoUserIntegrationModel, integrationModel) {
      if ($stateParams.integrationId) {
        let integrationPromise, djangoUserIntegrationsPromise;

        // если тип интеграции является ещё и интеграцией для django-пользователя, нужно дополнительно запросить все такие интеграции во всём аппе
        if (
          Object.keys(DJANGO_USER_INTEGRATION_TYPES)
            .map((key) => DJANGO_USER_INTEGRATION_TYPES[key])
            .includes($stateParams.integrationType)
        ) {
          djangoUserIntegrationsPromise = firstValueFrom(djangoUserIntegrationModel.get($stateParams.integrationType));
        } else {
          djangoUserIntegrationsPromise = $q.resolve([]);
        }

        // если открывается уже настроенная интеграция - тащим её данные с сервера
        integrationPromise = integrationModel.get($stateParams.integrationId, $stateParams.integrationType);

        return $q
          .all([integrationPromise, djangoUserIntegrationsPromise])
          .then(([integration, djangoUserIntegrations]) => {
            integration.djangoUserIntegrations = djangoUserIntegrations;
            return integration;
          });
      } else {
        return integrationModel.getDefaultIntegration($stateParams.integrationType);
      }
    }

    /**
     * Получение максимального количества интеграций, которые можно настроить в аппе
     *
     * @param $q
     * @param appBlocks
     * @returns {*}
     */
    function getIntegrationsLimit($q, appBlocks) {
      return $q.resolve(appBlocks.integrationsLimit);
    }

    /**
     * Получение списка чат-ботов, которые используют интеграцию
     *
     * @param $q
     * @param $stateParams
     * @param currentApp
     * @param integrationModel
     * @returns {Promise|*}
     */
    function getChatBots($q, $stateParams, currentApp, integrationModel) {
      if ($stateParams.integrationId) {
        return integrationModel.getChatBots($stateParams.integrationType, $stateParams.integrationId, currentApp);
      } else {
        return $q.resolve([]);
      }
    }

    /**
     * Получаем из параметров урла тип интеграции
     */
    function getIntegrationType($stateParams) {
      return $stateParams.integrationType;
    }

    /**
     * Получаем нераспарсеный список интеграций для странички превью
     */
    function getIntegrationsForPreviewPage(currentApp, integrationType, integrationModel) {
      if (CONFIGURABLE_INTEGRATION_TYPES.includes(integrationType)) {
        return integrationModel.getByType(currentApp.id, integrationType, false);
      }
      return [];
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {Promise}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
    }

    /**
     * Получение списка всех Telegram-ботов
     *
     * @param chatBotModel
     * @param telegramBotStore
     * @returns {Promise}
     */
    function getTelegramBotAllList(chatBotModel, telegramBotStore) {
      return firstValueFrom(chatBotModel.getTelegramBotList(true)).then((telegramBotList) => {
        telegramBotStore.telegramBotAllList$.next(telegramBotList);
        return telegramBotList;
      });
    }

    /**
     * Получение списка триггерных сообщений, которые используют интеграцию
     *
     * @param $q
     * @param $stateParams
     * @param currentApp
     * @param integrationModel
     * @returns {Promise|*}
     */
    function getTriggerMessages($q, $stateParams, currentApp, integrationModel) {
      if ($stateParams.integrationId) {
        return integrationModel.getAutoMessages($stateParams.integrationType, $stateParams.integrationId, currentApp);
      } else {
        return $q.resolve([]);
      }
    }
  }

  /**
   * Настройка перенаправления на состояния конкретных интеграций
   */
  function run($filter, $q, $transitions, $state, $window, INTEGRATIONS_ONE_TO_APP_ARRAY, INTEGRATION_TYPES) {
    // при открытии состояния ненастроенных интеграций - нужно перейти в состояние конкретной интеграции (смотри роуты выше, они сделаны хитро, с возможностью передачи integrationType в параметры состояния)
    $transitions.onEnter({ to: 'app.content.integrations.details.unconfigured' }, redirectToIntegrationState);
    // то же самое с активными интеграциями
    $transitions.onEnter({ to: 'app.content.integrations.details.configured' }, redirectToIntegrationState);
    // !!! переход на OAuth интеграций и дочерние состояния возможен только из нового окна, открытого при помощи $window.open
    $transitions.onEnter({ to: 'oAuth.integrations' }, redirectToIntegrationOAuthState);
    // редирект на интеграции при попытке зайти в превью несуществующей интеграции
    $transitions.onStart({ to: 'app.content.integrations.**.preview' }, redirectIfIntegrationDoesNotExist);

    function redirectIfIntegrationDoesNotExist(transition) {
      const targetIntegrationPreview = transition.targetState().params().integrationType;
      if (!Object.values(INTEGRATION_TYPES).includes(targetIntegrationPreview)) {
        return $state.target('app.content.integrations.general', transition.params());
      }
    }

    /**
     * Переход на состояние конкретной интеграции
     *
     * @param transition
     * @param state
     */
    function redirectToIntegrationState(transition, state) {
      const integrationModel = transition.injector().get('integrationModel');
      let integrationType = transition.params().integrationType;

      if (!~INTEGRATIONS_ONE_TO_APP_ARRAY.indexOf(integrationType)) {
        return $state.target(transition.to().name + '.' + transition.params().integrationType, transition.params());
      }

      // !!! для старых интеграций Bitrix24 ссылка после авторизации вела на конкретную интеграцию с GET-параметром code
      //  но в связи с переделкой OAuth ссылки для авторизации поменялись, и нужно перенаправить старые ссылки на Bitrix24 на новые
      if (transition.params().code) {
        return $state.target('oAuth.integrations.' + transition.params().integrationType, transition.params());
      }

      // Если осуществился переход на интеграцию, которая настраивается один раз на апп, то пытаемся найти уже настроенную и перекинуть на неё
      // Это нужно для того, чтобы пользователь не пытался создать вторую интеграцию
      return transition
        .injector()
        .getAsync('currentApp')
        .then((currentApp) => integrationModel.getByType(currentApp.id, integrationType))
        .then(redirect);

      function redirect(integrations) {
        let integration = integrations[0];

        if (integration) {
          return $state.target(
            'app.content.integrations.details.configured.' + integrationType,
            angular.extend({}, transition.params(), {
              integrationId: integration.id,
              integrationType: integrationType,
            }),
          );
        } else {
          return $state.target(transition.to().name + '.' + integrationType, transition.params());
        }
      }
    }

    /**
     * Перенаправление на конкретную интеграцию по её типу
     *
     * @param transition
     * @param state
     * @returns {*}
     */
    function redirectToIntegrationOAuthState(transition, state) {
      let params = {};

      // !!! потенциально все интеграции могут иметь абсолютно различный механизм OAuth, поэтому и обрабатываться они могут по-разному
      if (
        [
          INTEGRATION_TYPES.FACEBOOK,
          INTEGRATION_TYPES.FACEBOOK_LEADS,
          INTEGRATION_TYPES.INSTAGRAM,
          INTEGRATION_TYPES.MYTARGET,
        ].includes(transition.params().integrationType)
      ) {
        const transitionParams = transition.params();
        const stateFormat = /^app\.([0-9]+)\.integration\.([0-9]+)\.[a-z0-9\-]+$/;

        // если state не приходит в GET-параметрах - считаем, что авторизация провалена,
        // т.к. именно в state приходит appId и integrationId, а без них сделать запрос авторизации на бэкенд не получится
        if (!transitionParams.state) {
          $window.opener.postMessage(
            {
              status: 'error',
            },
            '*',
          );
          return false;
        }

        const integrationInfo = transitionParams.state.match(stateFormat);

        // если state не удалось распарсить и достать из него appId и integrationId, то тоже бросаем ошибку
        if (!integrationInfo) {
          $window.opener.postMessage(
            {
              status: 'error',
            },
            '*',
          );
          return false;
        }

        params.appId = integrationInfo[1];
        params.integrationId = integrationInfo[2];
      }

      if ([INTEGRATION_TYPES.BITRIX24].includes(transition.params().integrationType)) {
        const transitionParams = transition.params();
        const stateFormat = /^app\.([0-9]+)\.integration\.([0-9]+)\.([0-9a-f\-]+)$/;

        // если state не приходит в GET-параметрах - считаем, что авторизация провалена,
        // т.к. именно в state приходит appId и integrationId, а без них сделать запрос авторизации на бэкенд не получится
        if (!transitionParams.state) {
          $window.opener.postMessage(
            {
              status: 'error',
            },
            '*',
          );
          return false;
        }

        const integrationInfo = transitionParams.state.match(stateFormat);

        // если state не удалось распарсить и достать из него appId и integrationId, то тоже бросаем ошибку
        if (!integrationInfo) {
          $window.opener.postMessage(
            {
              status: 'error',
            },
            '*',
          );
          return false;
        }

        params.appId = integrationInfo[1];
        params.integrationId = integrationInfo[2];
        params.csrfToken = integrationInfo[3];
      }

      return $state.target(
        transition.to().name + '.' + transition.params().integrationType,
        angular.extend({}, transition.params(), params),
      );
    }
  }
})();

import { ui } from 'angular';

import { App } from '@http/app/app.model';
import { TEAM_MEMBER_PERMISSIONS } from '@http/team-member/team-member.constants';
import { TeamMember } from '@http/team-member/team-member.types';
import { MEMBER_INVITATION_MODAL_OPENING_PLACE } from '@panel/app/partials/modals/member-invitation/member-invitation-modal.constants';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/**
 * Сервис-хелпер для работы с членами команды
 *
 * NOTE:
 *  Данная сервис является дублёром сервиса-хелпера для работы с членами команды на Angular.
 *  При добавлении или изменении функционала необходимо синхронизировать их между собой.
 */

export class TeamMemberHelpService {
  // @ngInject
  constructor(
    private $uibModal: ui.bootstrap.IModalService,
    private carrotquestHelper: CarrotquestHelper,
    private toastr: ToastService,
    private $translate: any,
  ) {}

  /**
   * Получение описания для прав пользователя
   *
   * @param permission - Права пользователя
   *
   * @return {String}
   */
  getDescriptionForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.$translate.instant('models.teamMember.permissions.' + permission + '.description');
  }

  /**
   * Получение класса для иконки обозначающей права пользователя
   *
   * @param permission - Права пользователя
   *
   * @return {String}
   */
  getIconClassForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    switch (permission) {
      case TEAM_MEMBER_PERMISSIONS.ADMIN:
        return 'cqi-star';
      case TEAM_MEMBER_PERMISSIONS.OPERATOR:
        return 'cqi-headset';
      case TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN:
        return 'cqi-superman-sign';
      default:
        throw new Error('There is case not handle');
    }
  }

  /**
   * Получение класса для иконки обозначающей права экспорта
   *
   * @return {String}
   */
  getIconClassForPermissionExport(): string {
    return 'cqi-export';
  }

  /**
   * Получение класса для иконки обозначающей права массовой рассылки
   *
   * @return {String}
   */
  getIconClassForPermissionSendBulk(): string {
    return 'cqi-envelopes';
  }

  /**
   * Получение названия для прав пользователя
   *
   * @param permission - Права пользователя
   *
   * @return {String}
   */
  getNameForPermission(permission: TEAM_MEMBER_PERMISSIONS): string {
    return this.$translate.instant('models.teamMember.permissions.' + permission + '.name');
  }

  /**
   * Получение названия для права на экспорт
   *
   * @return {String}
   */
  getNameForPermissionExport(): string {
    return this.$translate.instant('models.teamMember.permissions.export.name');
  }

  /**
   * Получение названия для права на массовую рассылку
   *
   * @return {String}
   */
  getNameForPermissionSendBulk(): string {
    return this.$translate.instant('models.teamMember.permissions.sendBulk.name');
  }

  /**
   * Открытие модального окна с приглашением
   *
   * @param currentTeamMember - Текущий член команды, который выполняет приглашение
   *
   * @return {ui.bootstrap.IModalInstanceService}
   */
  openModalInvitations(currentTeamMember: TeamMember): ui.bootstrap.IModalInstanceService {
    return this.$uibModal.open({
      component: 'cqMemberInvitationModalWrapper',
      size: 'md modal-dialog-centered',
      resolve: {
        modalWindowParams: function () {
          return {
            currentTeamMember,
          };
        },
      },
    });
  }

  /**
   * Показ механики после не удачной отправки приглашений
   *
   * @param error - Название ошибки
   *
   * @return {void}
   */
  showFailedInvitationsSending(error: string): void {
    if (!error) {
      return;
    }

    let text = '';
    switch (error) {
      case 'DuplicateAdminError':
        text = this.$translate.instant('models.teamMember.toasts.duplicateAdminError');
        break;
      case 'DuplicateInviteError':
        text = this.$translate.instant('models.teamMember.toasts.duplicateInviteError');
        break;
      case 'TooManyAdminsError':
        text = this.$translate.instant('teamMembers.editor.toasts.tooManyAdminsError');
        break;
    }

    this.toastr.warning(text);
  }

  /**
   * Показ механики после успешной отправки приглашений
   *
   * @return {void}
   */
  showSuccessfulInvitationsSending(): void {
    const text = this.$translate.instant('models.teamMember.toasts.sendInvitation');
    this.toastr.success(text);
  }

  /**
   * Трек добавления нового члена команды
   *
   * NOTE:
   *  Название события является историческим и не отражает того, когда оно действительно отправляется.
   *
   * @return {void}
   */
  trackCreatingMember(currentApp: App): void {
    const eventName = 'Добавление членов команды из меню - добавил администратора';
    const param = {
      app: currentApp.name,
    };

    this.carrotquestHelper.track(eventName, param);
  }

  /**
   * Трекинг отправления приглашения
   *
   * @param openingPlace - Место из которого открывается модальное окно
   *
   * @return {void}
   */
  trackSendingInvitations(openingPlace: MEMBER_INVITATION_MODAL_OPENING_PLACE): void {
    let space: string;
    switch (openingPlace) {
      case MEMBER_INVITATION_MODAL_OPENING_PLACE.NAVIGATION:
        space = 'Меню';
        break;
      case MEMBER_INVITATION_MODAL_OPENING_PLACE.ADMINISTRATORS:
        space = 'Управление командой';
    }

    const eventName = `Приглашение членов команды - из ${space}`;

    this.carrotquestHelper.track(eventName);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { UIRouterModule } from '@uirouter/angular';

import { IntegrationService } from '@http/integration/services/integration.service';
import { CarrotquestHelperProvider } from '@panel/app/ajs-upgraded-providers';
import { IntegrationIconModule } from '@panel/app/pages/integrations/integration-icon/integration-icon.module';

import { LeadsPassingPreviewModalComponent } from './leads-passing-preview-modal.component';

@NgModule({
  declarations: [LeadsPassingPreviewModalComponent],
  exports: [LeadsPassingPreviewModalComponent],
  imports: [CommonModule, IntegrationIconModule, TranslocoModule, UIRouterModule],
  providers: [CarrotquestHelperProvider, IntegrationService],
})
export class LeadsPassingPreviewModalModule {}

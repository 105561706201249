/**
 * Директива, используемая для ограничения минимальной ширины загружаемого изображения в пикселях
 */
(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('cqImageMaxWidth', cqImageMaxWidth);

  function cqImageMaxWidth($q, fileHelper) {
    return {
      restrict: 'A',
      require: ['ngModel', 'cqFile'],
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var maxWidth;
      var ngModelController = controller[0];

      if (ngModelController) {
        iAttrs.$observe('cqImageMaxWidth', observe);
        ngModelController.$asyncValidators.maxWidth = maxWidthValidator;
      }

      /**
       * Возвращает ширину изображения
       *
       * @param {Object} imageSize - Размеры изображения
       */
      function getImageWidth(imageSize) {
        var deferred = $q.defer();

        if (maxWidth && imageSize.width > maxWidth) {
          deferred.reject();
        } else {
          deferred.resolve();
        }

        return deferred.promise;
      }

      /**
       * Валидатор, проверяющий соответствие минимальной допустимую ширину и ширину загружаемого изображения
       *
       * @param viewValue
       *
       * @returns {Boolean}
       */
      function maxWidthValidator(viewValue) {
        if (ngModelController.$isEmpty(viewValue)) {
          return $q.resolve();
        } else {
          return fileHelper.getImageSize(viewValue).then(getImageWidth);
        }
      }

      /**
       * Возвращает значение атрибута maxWidth
       *
       * @param {String} newValue - Значение атрибута maxWidth
       */
      function observe(newValue) {
        maxWidth = newValue;

        // нужно вызывать валидацию принудительно, т.к. $validators могут отработать раньше $observe
        ngModelController.$validate();
      }
    }
  }
})();

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Quotas } from '@http/quotas/quotas';
import { QuotasDto } from '@http/quotas/quotas.types';
import { InstanceFactory } from '@panel/app/services/billing/instance-factory/instance-factory';
import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

/** Http-сервис для работы с квотами */
@Injectable({ providedIn: 'root' })
export class QuotasHttpService {
  constructor(private readonly http: HttpClient, private readonly instanceFactory: InstanceFactory) {}

  /**
   * Получение информации о квотах
   *
   * @param appId - ID текущее приложение
   * @param ignoreLoadingBar - Игнорировать ли загрузочный бар
   */
  get(appId: string, ignoreLoadingBar: boolean = false): Observable<Quotas> {
    return this.http
      .get<unknown>(`/apps/${appId}/billing/usage`, {
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(NGX_LOADING_BAR_IGNORED, ignoreLoadingBar),
      })
      .pipe(map((response) => this.instanceFactory.getInstanceQuotas(response as QuotasDto)));
  }
}

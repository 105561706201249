import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { AmocrmIntegrationExternal } from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { AmocrmLeadTag } from '@http/integration/integrations/amo/types/amocrm-integration.types';
import { BotBranchSelectService } from '@panel/app/pages/chat-bot/content/services/bot-branch-select.service';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { BotAction } from '@panel/app/pages/chat-bot/content/views/actions/interfaces';
import { BotAmocrmNotificationActionForm } from '@panel/app/pages/chat-bot/forms/actions/amocrm-notification-action.form';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import {
  AmocrmModalLeadSettingsComponent,
  AmocrmNoteEditorModalParams,
} from '@panel/app/pages/integrations/content/amocrm/modal-lead-settings/amocrm-modal-lead-settings.component';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-branch-action-amocrm-notification[actionForm][action]',
  templateUrl: './amocrm-notification.component.html',
  styleUrls: ['./amocrm-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionAmocrmNotificationComponent implements OnInit {
  @Input()
  actionForm!: BotAmocrmNotificationActionForm;

  @Input()
  action!: BotAction;

  /** Список интеграций amoCRM */
  amocrmIntegrations: AmocrmIntegrationExternal[] = this.getAmocrmIntegrations();

  currentValue: BaseBotActionForm['value'];

  constructor(
    @Inject(CarrotquestHelper) private readonly carrotquestHelper: CarrotquestHelper,
    private readonly integrationFactory: IntegrationFactory,
    private readonly modalService: NgbModal,
    public readonly stateService: StateService,
    private readonly branchSelectService: BotBranchSelectService,
  ) {}

  ngOnInit(): void {
    this.currentValue = this.actionForm.value.bodyJson.integration;
  }

  /**
   * Получает объект выбранной интеграции
   */
  get selectedIntegration(): AmocrmIntegrationExternal {
    return this.stateService.amocrmIntegrationsExternal$
      .getValue()
      .filter((int) => int.id === this.integrationControl.value)[0];
  }

  /** Контрол формы с интеграцией */
  get integrationControl(): GenericFormControl<string> {
    return this.actionForm.controls.bodyJson.controls.integration;
  }

  /** Показывать кнопку редактирования примечания для интеграции */
  get isNoteEditButtonVisible(): boolean {
    return !!this.actionForm.value.bodyJson.integration;
  }

  /**
   * Список интеграций amoCRM с дополнительным полем «disabled»
   * для отключения возможности выбрать приостановленную интеграцию
   */
  getAmocrmIntegrations(): AmocrmIntegrationExternal[] {
    return this.stateService.amocrmIntegrationsExternal$.getValue().map((integration) => {
      return { ...integration, disabled: !integration.active };
    });
  }

  /**
   * Открытие модального окна редактирования настроек сделки в AmoCRM
   *
   * @param integrationExternal - Выбранная интеграция
   */
  openAmocrmLeadSettingsModal(integrationExternal: AmocrmIntegrationExternal): void {
    const modalRef = this.modalService.open(AmocrmModalLeadSettingsComponent, {
      centered: true,
    });
    modalRef.componentInstance.note = this.actionForm.value.bodyJson.comment;
    modalRef.componentInstance.leadTags = this.actionForm.value.bodyJson.tags;

    (modalRef.componentInstance.modalParams as AmocrmNoteEditorModalParams) = {
      integrationSource: 'bot',
      integration: integrationExternal,
    };

    modalRef.result
      .then((value: { comment: string; leadTags: AmocrmLeadTag[] }) => {
        // Если до этого ни чего не было выбрано, надо вызвать событие
        !this.currentValue && this.trackAddIntegration();

        this.currentValue = integrationExternal.id;
        this.actionForm.controls.bodyJson.controls.comment.setValue(value.comment);
        this.actionForm.controls.bodyJson.controls.tags.setValue(value.leadTags);
      })
      .catch(() => {})
      .then(() => {
        this.actionForm.controls.body.setValue(this.currentValue);
      });
  }

  /**
   * Трек события выбора интеграции AmoCRM
   * @private
   */
  private trackAddIntegration(): void {
    this.carrotquestHelper.track('Чат-бот - добавил в блок отправку данных в amoCRM', {
      'Название бота': this.stateService.chatBot$.getValue()?.name,
      'Название блока': this.branchSelectService.getValue()?.name.value,
    });
  }
}

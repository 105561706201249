/**
 * Директива, которая осуществляет проверку, что введённое в input число меньше, чем число, заданное в директиву
 */
(function () {
  'use strict';

  angular.module('myApp.directives.number.additions').directive('cqMultiplier', ['$parse', cqMultiplier]);

  function cqMultiplier($parse) {
    return {
      priority: 1010,
      restrict: 'A',
      require: ['ngModel', '?cqInteger', '?cqMoney'],
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var ngModelController = controller[0];
      var cqIntegerController = controller[1];
      var cqMoneyController = controller[2];

      if (!cqIntegerController && !cqMoneyController) {
        throw Error('cqMultiplier expects either cqInteger or cqMoney to be defined');
      }

      var multiplier;

      iAttrs.$observe('cqMultiplier', observe);
      ngModelController.$parsers.push(parser);
      ngModelController.$formatters.push(formatter);

      function formatter(modelValue) {
        /*if (angular.isDefined(multiplier)) {
          return modelValue / multiplier;
        } else {
          var parsedValue = $parse(iAttrs['cqMultiplier'])(scope);

          if (angular.isDefined(parsedValue) && !angular.isNumber(parsedValue)) {
            parsedValue = parseFloat(parsedValue);
          }
          multiplier = angular.isNumber(parsedValue) && !isNaN(parsedValue) ? parsedValue : undefined;
          if (angular.isDefined(multiplier)) {
            return modelValue / multiplier;
          } else {
            return modelValue;
          }
        }*/

        // todo костыль: $formatters отрабатывают до первого $observe, поэтому к этому моменту multiplier не подтянут из атрибутов! Поэтому его надо достать руками, но только в первый раз. Решения нигде не нашёл, кроме того, что в observe самому вызывать все $formatters ещё раз, но это ещё более костыльное решение
        if (angular.isUndefined(multiplier)) {
          var parsedMultiplier = $parse(iAttrs.cqMultiplier)(scope);

          if (angular.isDefined(parsedMultiplier) && !angular.isNumber(parsedMultiplier)) {
            parsedMultiplier = parseFloat(parsedMultiplier);
          }
          multiplier = angular.isNumber(parsedMultiplier) && !isNaN(parsedMultiplier) ? parsedMultiplier : undefined;
        }

        if (angular.isDefined(multiplier)) {
          var parsedModelValue =
            angular.isDefined(modelValue) && !angular.isNumber(modelValue) ? parseFloat(modelValue) : modelValue;
          if (angular.isNumber(parsedModelValue) && !isNaN(parsedModelValue)) {
            return parsedModelValue / multiplier;
          } else {
            return modelValue;
          }
        } else {
          return modelValue;
        }
      }

      /**
       * Динамическое получение значения из атрибута cq-max (чтобы можно было задавать в атрибут angular expression)
       *
       * @param value Значение, переданное в атрибут
       */
      function observe(value) {
        if (angular.isDefined(value) && !angular.isNumber(value)) {
          value = parseFloat(value);
        }
        multiplier = angular.isNumber(value) && !isNaN(value) ? value : undefined;

        var currentViewValue = ngModelController.$viewValue;

        // todo такое решение немного кривовато, может быть можно как-то по-другому (если просто делать ngModelController.$setViewValue(currentViewValue) - ничего не произойдёт, $parsers не вызовутся)
        // принудительный вызов $parsers, чтобы в модель записалось новое число
        ngModelController.$setViewValue(null);
        ngModelController.$setViewValue(currentViewValue);
      }

      function parser(viewValue) {
        if (angular.isDefined(multiplier) && angular.isNumber(viewValue)) {
          return viewValue * multiplier;
        } else {
          return viewValue;
        }
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DuplicatesFieldsPipe } from '@panel/app/pages/integrations/content/amocrm/shared/pipes/duplicates-fields.pipe';

@NgModule({
  declarations: [DuplicatesFieldsPipe],
  exports: [DuplicatesFieldsPipe],
  imports: [CommonModule],
})
export class AmocrmPipesModule {}

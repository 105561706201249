/**
 * Какая-то срань, старый фильтр
 */
(function () {
  'use strict';

  angular.module('myApp.filters.userPropPretty').filter('userPropPretty', userPropPretty);

  function userPropPretty($filter, $sce, $translate, moment) {
    var DATE_REGEXP = /^(\d\d\d\d)-(\d\d)-(\d\d)(T|t)(\d\d):(\d\d):(\d\d)$/;

    return userPropPrettyFilter;

    function tagsReplace(str) {
      // Проверка на случай если свойство пользователя отсутствует
      if (!str) {
        return '';
      }

      str = str.toString();

      return str.replace(/[&<>]/g, function (tag) {
        var tagsToReplace = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
        };
        return tagsToReplace[tag] || tag;
      });
    }

    function userPropPrettyFilter(prop) {
      if (typeof prop == 'undefined') {
        return $translate.instant('general.unknown').toLowerCase();
      } else if (DATE_REGEXP.test(prop)) {
        return $filter('cqPrettyDate')(new Date(prop.toUpperCase() + 'Z').getTime());
      } else if (prop instanceof moment) {
        return $filter('cqPrettyDate')(prop);
      } else if (typeof prop == 'string' && (prop.indexOf('http://') == 0 || prop.indexOf('https://') == 0)) {
        var wrap = document.createElement('div');
        var anch = document.createElement('a');
        var textarea = document.createElement('textarea');
        textarea.innerHTML = prop;
        anch.href = textarea.value;
        anch.target = '_blank';
        anch.innerHTML = tagsReplace(prop);
        wrap.appendChild(anch);
        return $sce.trustAsHtml(wrap.innerHTML);
      } else if (prop === true) {
        return $translate.instant('general.yes');
      } else if (prop === false) {
        return $translate.instant('general.no');
      } else if (angular.isObject(prop) && !angular.isArray(prop)) {
        return JSON.stringify(prop);
      } else {
        return $sce.trustAsHtml(tagsReplace(prop));
      }
    }
  }
})();

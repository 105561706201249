<ng-container *transloco="let t; read: 'teamMembersSelectComponent'">
  <h4 class="mb-5">{{ t('heading') }}</h4>
  <div class="text-secondary mb-15">{{ t('description1') }}</div>
  <div class="text-secondary mb-10">
    {{
      control.value.length
        ? t('description2.withOperators', { count: control.value.length })
        : t('description2.withoutOperators')
    }}
  </div>
  <ng-select
    [items]="teamMembers"
    [groupBy]="groupFn"
    [searchFn]="searchOperatorFn"
    [multiple]="true"
    [clearable]="false"
    bindValue="id"
    [hideSelected]="true"
    placeholder="{{ t('select.placeholder') }}"
    [formControl]="control"
  >
    <ng-template
      let-clear="clear"
      let-items="items"
      ng-multi-label-tmp
    >
      <div
        class="ng-value"
        *ngFor="let item of items"
      >
        <button
          class="btn btn-sm btn-outline-primary flex align-items-center overflow-hidden"
          type="button"
        >
          <div class="flex align-items-center">
            <img
              class="avatar margin-right-5"
              [src]="item.avatar"
            />
            <span class="margin-right-5">{{ item.name }}</span>
          </div>
          <span
            class="flex"
            (click)="clear(item)"
          >
            <i class="cqi-xs cqi-times ml-5"></i>
          </span>
        </button>
      </div>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-item="item"
    >
      <cq-conversation-operator [teamMember]="item"></cq-conversation-operator>
    </ng-template>
  </ng-select>
  <cq-validation-messages [control]="control">
    <cq-validation-message errorName="required">
      {{ t('select.errors.required') }}
    </cq-validation-message>
  </cq-validation-messages>
</ng-container>

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { distinctUntilChanged, first, map, mergeMap, startWith } from 'rxjs/operators';

import {
  ACTIONS_GROUPS,
  CHAT_BOT_ACTIONS_TYPES,
  CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP,
} from '@http/chat-bot/chat-bot.constants';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { GenericFormArray } from '@panel/app/shared/abstractions/deprecated/generic-form-array';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';
import { extractValidationError } from '@panel/app/shared/functions/extract-validation-error.function';
import { extractTouchedChanges } from '@panel/app/shared/functions/touch-pristine-changes';
import { Modify } from '@panel/app/shared/types/modify.type';

type FormConstructorInput = Modify<
  Pick<ChatBotBranch, 'name' | 'actions'>,
  {
    actions: BaseBotActionForm[];
  }
>;

type BotBranchFormData = FormConstructorInput & {
  targetAction: CHAT_BOT_ACTIONS_TYPES;
  buttonsWereTouched: boolean;
};

function actionsValidator(control: GenericFormArray<BaseBotActionForm>): ValidationErrors | null {
  const isInvalid = control.controls.some((actionControl) => actionControl.invalid);
  return isInvalid ? { actions: { value: control.value } } : null;
}

/**
 * Валидатор наличия >=1 BUTTON, при выборе targetAction CHAT_BOT_ACTIONS_TYPES.BUTTON
 * @param form
 */
function buttonActionsExistenceValidator(form: AbstractControl): ValidationErrors | null {
  // Ангуляровские типы не очень, потому что в ValidatorFn может быть передана форма, поэтому так
  if (!(form instanceof BotBranchForm)) {
    return null;
  }

  const isButtonTarget = form.controls.targetAction.value === CHAT_BOT_ACTIONS_TYPES.BUTTON;
  const buttonsWereTouched = form.controls.buttonsWereTouched.value;
  const thereIsNoButtons = !form.controls.actions.controls.find(
    (actionForm) => actionForm.type === CHAT_BOT_ACTIONS_TYPES.BUTTON,
  );

  const invalid = isButtonTarget && buttonsWereTouched && thereIsNoButtons;

  return invalid ? { buttonRequired: { value: form } } : null;
}

/**
 * Максимальная длинна названия ветки
 */
export const BRANCH_NAME_MAX_LENGTH: number = 70;

export class BotBranchForm extends GenericFormGroup<BotBranchFormData> {
  constructor(branch?: FormConstructorInput) {
    const controls: Controls<BotBranchFormData> = {
      name: new GenericFormControl(branch?.name ?? '', [
        Validators.required,
        Validators.maxLength(BRANCH_NAME_MAX_LENGTH),
      ]),
      actions: new GenericFormArray<BaseBotActionForm>(branch?.actions ?? [], [actionsValidator, Validators.required]),
      // У контролов ниже  должно быть валидаторов, т.к. это хелперы control, который не влияет на итоговую валидность всей ветки
      targetAction: new GenericFormControl(BotBranchForm.getTargetActionValue(branch!)),
      buttonsWereTouched: new GenericFormControl(false),
    };
    const syncValidators: ValidatorFn[] = [buttonActionsExistenceValidator];
    super(controls, syncValidators);
  }

  /**
   * Запускает валидацию контролов контролов ветки и вызывает аналогичный метод у всех действий
   * @param touch
   */
  revalidate(touch: boolean = true) {
    if (touch) {
      this.markAllAsTouched();
    }
    this.get('name').updateValueAndValidity();
    this.get('actions').controls.forEach((actionForm) => actionForm.revalidate());
  }

  /**
   * Т.к. в некоторых ситуациях при невалидность ветки не нужно показывать ошибку на канвасе, мы не можем использовать маркер валидности формы,
   * поэтому тут мы собираем все условия, при которых ветку на канвасе отрисовывать невалидной
   */
  public get showError$(): Observable<boolean> {
    return combineLatest([
      this.thereIsTouchedAndInvalidControlChanges$.pipe(startWith(false)),
      this.botNameTouchedInvalidChanges$,
      this.botTouchedInvalidChanges$.pipe(startWith(false)),
    ]).pipe(
      map((statuses) => statuses.reduce((acc, val) => acc || val, false)),
      distinctUntilChanged(),
    );
  }

  /**
   * Валидация touched бота, по наличию действий
   * @private
   */
  private get botTouchedInvalidChanges$(): Observable<boolean> {
    return combineLatest([
      this.controls.targetAction.valueChanges.pipe(
        startWith(this.controls.targetAction.value),
        map((val) => val === CHAT_BOT_ACTIONS_TYPES.BUTTON),
      ),
      this.controls.buttonsWereTouched.valueChanges.pipe(startWith(false)),
      extractValidationError(this, 'buttonRequired'),
    ]).pipe(map((statuses) => statuses.reduce((acc, val) => acc && val, true)));
  }

  /**
   * Валидация touched имени бота
   * @private
   */
  private get botNameTouchedInvalidChanges$(): Observable<boolean> {
    return combineLatest([
      extractTouchedChanges(this.controls.name).pipe(startWith(this.controls.name.touched)),
      this.controls.name.statusChanges.pipe(
        startWith(this.controls.name.status),
        map((status) => status === 'INVALID'),
      ),
    ]).pipe(map((statuses) => statuses.reduce((acc, val) => acc && val, true)));
  }

  private get thereIsTouchedAndInvalidControlChanges$(): Observable<boolean> {
    return combineLatest([extractTouchedChanges(this), this.statusChanges.pipe(startWith(this.status))]).pipe(
      map(() => {
        return this.controls.actions.controls.map((action) => {
          return action.allTouchedAndInvalidChanges$.pipe(first());
        });
      }),
      map((observables): Observable<[touched: boolean, invalid: boolean][]> => {
        return forkJoin(...observables);
      }),
      mergeMap((res): Observable<boolean> => {
        return res.pipe(
          map((r) => {
            return r.some(([touched, invalid]) => {
              return touched && invalid;
            });
          }),
        );
      }),
    );
  }

  /**
   * получить целевое действие по списку действий
   */
  static getTargetActionValue(branch: FormConstructorInput): CHAT_BOT_ACTIONS_TYPES {
    const targetAction = branch.actions.find((action) =>
      CHAT_BOT_ACTIONS_TYPES_LIST_BY_GROUP[ACTIONS_GROUPS.TARGET_SELECT].includes(action.type),
    );

    if (!targetAction) {
      return CHAT_BOT_ACTIONS_TYPES.CLOSE;
    }

    return targetAction.type;
  }
}

import { ProjectPartial } from '@environments/types';

export type UppercaseLanguage = Uppercase<ProjectPartial['language']>;

type DateFormatsForRange = {
  [index in UppercaseLanguage]: {
    DAY: string;
    DAY_MONTH: string;
    DAY_YEAR: string;
    DAY_MONTH_YEAR: string;
  };
};

/**
 * Форматы для диапазона дат
 * Брались отсюда https://unicode.org/cldr/charts/latest/verify/dates/
 */
export const DATE_FORMATS_FOR_RANGE: DateFormatsForRange = {
  EN: {
    DAY: 'D',
    DAY_MONTH: 'MMMM D',
    DAY_YEAR: 'D, YYYY', // это поле добавлено только из-за английского языка, для кейса January 13 – 14, 2012
    DAY_MONTH_YEAR: 'MMMM D, YYYY',
  },
  RU: {
    DAY: 'D',
    DAY_MONTH: 'D MMMM',
    DAY_YEAR: 'D YYYY',
    DAY_MONTH_YEAR: 'D MMMM YYYY',
  },
};

/** Единицы времени */
export enum TIME_UNITS {
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
}

/** Величины в секундах для каждой единицы времени */
export const TIME_UNIT_MEASURES = {
  [TIME_UNITS.SECOND]: 1,
  [TIME_UNITS.MINUTE]: 60,
  [TIME_UNITS.HOUR]: 60 * 60,
  [TIME_UNITS.DAY]: 60 * 60 * 24,
  [TIME_UNITS.MONTH]: 60 * 60 * 24 * 30,
};

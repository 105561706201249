(function () {
  'use strict';

  require('./starter-guide-work-time.controller');

  angular.module('myApp.starterGuide').component('cqStarterGuideWorkTime', cqStarterGuideWorkTime());

  function cqStarterGuideWorkTime() {
    return {
      bindings: {
        chatSettings: '=', // Настройки чата
        currentApp: '=', // Текущее приложение
        isStepMade: '=', // Пройденность шага
      },
      controller: 'CqStarterGuideWorkTimeController',
      controllerAs: 'vm',
      template: require('./starter-guide-work-time.html'),
    };
  }
})();

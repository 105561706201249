import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { OriginalInboundEmailViewModalParams } from '@panel/app/partials/modals/original-inbound-email-view/original-inbound-email-view-modal.types';

/**
 * Модальное окно для просмотра оригинального входящего email
 */

@Component({
  selector: 'cq-original-inbound-email-view-modal[modalWindowParams]',
  templateUrl: './original-inbound-email-view-modal.component.html',
  styleUrls: ['./original-inbound-email-view-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OriginalInboundEmailViewModalComponent {
  /** Инстанс модального окна на AngularJS */
  @Input()
  ajsModalInstance?: any;

  /** Параметры модального окна */
  @Input()
  modalWindowParams!: OriginalInboundEmailViewModalParams;

  /** Получение содержимого email */
  get body(): OriginalInboundEmailViewModalParams['body'] {
    return this.modalWindowParams.body;
  }

  /** Получение ID email сообщения */
  get messageId(): OriginalInboundEmailViewModalParams['messageId'] {
    return this.modalWindowParams.messageId;
  }

  /** Получение получателя email */
  get recipient(): OriginalInboundEmailViewModalParams['recipient'] {
    return this.modalWindowParams.recipient;
  }

  /** Получение отправителя email */
  get sender(): OriginalInboundEmailViewModalParams['sender'] {
    return this.modalWindowParams.sender;
  }

  /** Получение темы email */
  get subject(): OriginalInboundEmailViewModalParams['subject'] {
    return this.modalWindowParams.subject;
  }

  constructor(private readonly ngbActiveModal: NgbActiveModal) {}

  /** Отклонение модального окна */
  dismissModal(): void {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.ngbActiveModal.dismiss();
  }

  /** Обработчик клика*/
  onClickHeaderCloseButton(): void {
    this.dismissModal();
  }

  onClickFooterCloseButton(): void {
    this.dismissModal();
  }
}

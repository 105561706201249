<ng-container *transloco="let t; read: 'inputCopyButtonComponent'">
  <div class="input-group">
    <input
      class="form-control"
      [placeholder]="inputPlaceholder"
      [value]="inputValue"
      disabled
      type="text"
      data-test="input-copy-button-input"
    />
    <span class="input-group-append">
      <button
        class="btn btn-primary"
        (click)="copyValue()"
        type="button"
        data-test="input-copy-button-button"
      >
        <i class="btn-icon-left cqi-sm cqi-copy"></i>
        <span>{{ getButtonText() }}</span>
      </button>
    </span>
  </div>
</ng-container>

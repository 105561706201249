import { Renderer, RenderTexture } from '@pixi/core';
import { Graphics, Sprite, Texture } from 'pixi.js';

import { BOT_MOST_LIGHTEST_GREY } from '../../utils/colors';
import { MAX_ACTION_WIDTH } from '../abstract';

type OptionSplitter = {
  linePadding: number;
};

const defaultOption = {
  linePadding: 40,
};
const width = MAX_ACTION_WIDTH;

let cache: Map<number, Texture> = new Map();

let hiddenActionTexture: Texture | null = null;

let lastRendererContextUID: number | null = null;

/**
 * Отрисовывает две последовательные горизонтальные линии с отступом.
 * Используется во действиях close, channel, etc
 */
export function getHorizontalSplitter(renderer: Renderer, option: OptionSplitter = defaultOption): Sprite {
  if (renderer.CONTEXT_UID !== lastRendererContextUID) {
    hiddenActionTexture = null;
  }
  lastRendererContextUID = renderer.CONTEXT_UID;

  if (cache.has(option.linePadding)) {
    return new Sprite(cache.get(option.linePadding));
  }

  const singleLineWidth = width / 2;

  const line = new Graphics();
  line
    .lineStyle(1, BOT_MOST_LIGHTEST_GREY, 1)
    .lineTo(singleLineWidth - option.linePadding / 2, 0)
    .moveTo(singleLineWidth + option.linePadding / 2, 0)
    .lineTo(width, 0);

  const renderTexture = RenderTexture.create({ width: line.width, height: line.height + 2 });

  line.position.y = 1;

  renderer.render(line, { renderTexture });

  hiddenActionTexture = renderTexture;

  cache.set(option.linePadding, hiddenActionTexture);

  return new Sprite(hiddenActionTexture);
}

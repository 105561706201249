import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPromise } from 'angular';
import { firstValueFrom } from 'rxjs';

import { DjangoUserModel } from '@http/django-user/django-user.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { IMAGE_EXTENSION } from '@panel/app-old/shared/services/file-helper/file-helper.constants';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';

@Component({
  selector: 'cq-admin-params-modal',
  templateUrl: './admin-params-modal.component.html',
  styleUrls: ['./admin-params-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminParamsModalComponent implements OnInit {
  /** Объект с данными для модального окна */
  @Input() modalWindowParams!: any;

  /**
   * Максимальный размер аватара (1 Мб)
   *
   * @type {number}
   */
  protected AVATAR_MAX_SIZE: number = 1024 * 1024;

  /**
   * Ограничение на максимальную длину внутреннего имени
   *
   * @type {number}
   */
  protected INTERNAL_NAME_MAX_LENGTH: number = 255;

  /**
   * Ограничение на максимальную длину видимого имени
   *
   * @type {number}
   */
  protected NAME_MAX_LENGTH: number = 30;

  protected adminParamsForm = this.fb.group({
    name: this.fb.control(null, [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)]),
    internalName: this.fb.control(null, [Validators.maxLength(this.INTERNAL_NAME_MAX_LENGTH)]),
    avatar: this.fb.control(null),
  });

  protected IMAGE_EXTENSION: any = IMAGE_EXTENSION;
  protected newAvatar: any = {
    file: null, // файл
    preview: null, // превью
  };
  protected uploadAvatarError: any = false; // Флаг ошибки загрузки аватарки
  protected isApiRequestPerformed = false; // флаг выполнения запроса

  constructor(
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly djangoUserModel: DjangoUserModel,
    protected readonly fileHelper: FileHelperService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly carrotquestHelper: CarrotquestHelper,
  ) {
    this.adminParamsForm.valueChanges.subscribe((values) => {
      this.modalWindowParams.name = values.name;
      this.modalWindowParams.internalName = values.internalName;

      if (this.modalWindowParams.avatar !== values.avatar) {
        this.newAvatar.file = values.avatar;
        this.carrotquestHelper.track('Загрузил аватарку в аккаунт');
      }
    });
  }

  ngOnInit(): void {
    this.adminParamsForm.setValue({
      name: this.modalWindowParams.name,
      internalName: this.modalWindowParams.internalName,
      avatar: this.modalWindowParams.avatar,
    });
  }

  /**
   * Сохранение параметров админа
   *
   * @param isValid - Валидность формы
   */
  saveAdminParams(isValid: any): void {
    if (isValid && !this.isApiRequestPerformed) {
      this.adminParamsForm.controls.avatar.setErrors({ uploadAvatarError: false });
      this.adminParamsForm.controls.avatar.markAsUntouched();

      this.isApiRequestPerformed = true;

      this.saveAvatar()
        .catch(() => {
          this.adminParamsForm.controls.avatar.setErrors({ uploadAvatarError: true });
          this.adminParamsForm.controls.avatar.markAsTouched();

          return Promise.reject();
        })
        .then(() => this.saveNames())
        .then(() => this.ngbActiveModal.close())
        .finally(() => {
          this.isApiRequestPerformed = false;
        });
    }
  }

  /**
   * Сохранение аватарки
   *
   * @returns
   */
  saveAvatar(): any {
    if (this.newAvatar.file) {
      return firstValueFrom(this.djangoUserModel.uploadAvatar(this.modalWindowParams.app.id, this.newAvatar.file));
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Сохранение имен
   *
   * @returns
   */
  saveNames(): IPromise<any> {
    var prefs: any = {};
    prefs['name__' + this.modalWindowParams.app.id] = this.modalWindowParams.name;
    prefs['name_internal__' + this.modalWindowParams.app.id] = this.modalWindowParams.internalName;

    var params: any = {
      prefs: JSON.stringify(prefs),
    };

    return firstValueFrom(this.djangoUserModel.save(params, true));
  }

  /**
   * Установка аватара по умолчанию
   */
  setDefaultAvatar(): void {
    this.newAvatar.file = null;
    this.newAvatar.preview = null;
    this.cdr.detectChanges();
  }
}

import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.removeUserProperty')
    .controller('CqRemoveUserPropertyModalController', CqRemoveUserPropertyModalController);

  function CqRemoveUserPropertyModalController($translate, userModel) {
    let vm = this;

    vm.$onInit = init;

    function init() {
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса
      vm.getUserSystemPropTitle = getUserSystemPropTitle;
      vm.removeUserProperty = removeUserProperty;
    }

    /** Получить перевод имени для системного св-ва пользователя */
    function getUserSystemPropTitle(propName) {
      if (propName === '$email' || propName === '$phone') {
        return $translate.instant('models.property.userSystemProperties.' + propName);
      }
      return propName;
    }

    function removeUserProperty() {
      vm.isApiRequestPerformed = true;

      const props = [
        {
          op: 'delete',
          key: vm.resolve.modalWindowParams.propertyName,
          value: 0,
        },
      ];

      firstValueFrom(
        userModel.setProperties(
          vm.resolve.modalWindowParams.userId,
          vm.resolve.modalWindowParams.currentAppId,
          props,
          true,
        ),
      )
        .then(removePropertySuccess)
        .then(removePropertyFinally);

      function removePropertySuccess() {
        vm.modalInstance.close();
      }

      function removePropertyFinally() {
        vm.isApiRequestPerformed = false;
      }
    }
  }
})();

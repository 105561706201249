import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cq-conversation-part-preview-text[text]',
  templateUrl: './conversation-part-preview-text.component.html',
  styleUrls: ['./conversation-part-preview-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationPartPreviewTextComponent {
  /** Текст сообщения в реплике */
  @Input() text!: string | null;
}

import { ChangeDetectionStrategy, Component, NgZone, Optional, Self } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';

export type PageUrlForm = {
  url: string;
  hasSubpages: boolean;
};

@Component({
  selector: 'cq-page-url',
  templateUrl: './page-url.component.html',
  styleUrls: ['./page-url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageUrlComponent extends AbstractCVAControl<PageUrlForm> {
  control = this.fb.group<PageUrlForm>({
    url: this.fb.control<string>(null, Validators.required),
    hasSubpages: false,
  });

  constructor(
    private readonly fb: GenericFormBuilder,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }
}

import cloneDeep from 'lodash-es/cloneDeep';
import { switchMap, take, map } from 'rxjs/operators';
import { firstValueFrom, from } from 'rxjs';
import { EMAIL_TYPES, MESSAGE_PART_TYPES } from '../../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular
    .module('myApp.triggerChains.automessageEditor')
    .controller('CqTriggerChainAutomessageEditorController', CqTriggerChainAutomessageEditorController);

  function CqTriggerChainAutomessageEditorController(
    $filter,
    $translate,
    $q,
    $uibModal,
    messagePreviewImageService,
    messagePreviewService,
    messagePartModel,
    popupBlockModel,
    messageUtilsModel,
    messagePreviewScreenshotService,
  ) {
    let vm = this;

    let preprocessingTasks = [];

    vm.$onInit = init;

    function init() {
      vm.contentForm = null;
      vm.autoMessageStep = cloneDeep(vm.resolve.autoMessageStep);
      vm.isPreviewLoading = false;
      vm.messagePart = vm.autoMessageStep.meta.message.parts[0];
      vm.removeClickSubj = vm.resolve.removeClick;
      vm.copyClickSubj = vm.resolve.copyClick;
      vm.removeBlock = removeBlock;
      vm.copyBlock = copyBlock;
      vm.updateMessagePart = updateMessagePart;
    }

    function openConfirmRemoveTriggerChainStepModal() {
      return $uibModal.open({
        controller: 'ConfirmModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/confirm/confirm.html',
        windowTopClass: 'd-flex align-items-center',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant(
                'triggerChainAutomessageEditor.openConfirmRemoveTriggerChainStepModal.heading',
              ),
              body: $translate.instant('triggerChainAutomessageEditor.openConfirmRemoveTriggerChainStepModal.body'),
            };
          },
        },
      });
    }

    function copyBlock() {
      vm.copyClickSubj.next(vm.resolve.autoMessageStep);
    }
    function removeBlock() {
      openConfirmRemoveTriggerChainStepModal().result.then(() => {
        vm.modalInstance.dismiss();
        vm.removeClickSubj.next(vm.resolve.autoMessageStep);
      });
    }

    function updateMessagePart(valid) {
      /**
       * это короче костыль, потому что как-то тупо работает обертка компонента редактора имени,
       * присылался валидный статус при пустом имени.
       * с ходу решить не вышло, решил забить
       */
      const messageNameValid = () => {
        return !!vm.autoMessageStep.name && vm.autoMessageStep.name.length < 70;
      };

      if (!valid || !messageNameValid()) {
        vm.messagePart.handleError();
        return;
      }

      let blockPopupMessageParts = messagePartModel
        .filterByMessagePartType([vm.messagePart], MESSAGE_PART_TYPES.BLOCK_POPUP_BIG)
        .concat(messagePartModel.filterByMessagePartType([vm.messagePart], MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL))
        .concat(messagePartModel.filterByMessagePartType([vm.messagePart], MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL));

      let imagesBeforeLoad = [];
      if (blockPopupMessageParts.length) {
        vm.isPreviewLoading = true;

        for (let i = 0; i < blockPopupMessageParts.length; i++) {
          let blockPopup = blockPopupMessageParts[i][blockPopupMessageParts[i].type];
          let popupBlocks = $filter('flatten')(blockPopup.bodyJson.blocks);

          blockPopup.bodyJson.footer && popupBlocks.push(blockPopup.bodyJson.footer);

          for (let j = 0; j < popupBlocks.length; j++) {
            let popupBlock = popupBlocks[j];

            if (popupBlock.type === 'image') {
              imagesBeforeLoad.push(popupBlock);
            }

            preprocessingTasks.push(popupBlockModel.saveAdditionalData(vm.resolve.currentApp.id, popupBlock));
          }

          preprocessingTasks.push(popupBlockModel.saveBackgroundImage(blockPopup.bodyJson.params));
        }
      } else {
        vm.isPreviewLoading = true;
      }

      $q.all(preprocessingTasks)
        .then(() => {
          if (messagePreviewImageService.hasIframes()) {
            messagePreviewImageService.startGeneratePreviewImage();

            return firstValueFrom(
              messagePreviewImageService.previewImage$.pipe(
                take(1),
                switchMap(({ base64ImageData, heightPx }) =>
                  messagePreviewService.save(base64ImageData).pipe(map(({ link }) => ({ link, heightPx }))),
                ),
              ),
            ).then(({ link, heightPx }) => {
              vm.autoMessageStep.meta.previewImage = { url: link, heightPx };
            });
          } else if (vm.messagePart.type === MESSAGE_PART_TYPES.WEBHOOK) {
            vm.autoMessageStep.meta.previewImage = { url: '', heightPx: 0 };
            return Promise.resolve();
          } else if (vm.messagePart.type === MESSAGE_PART_TYPES.EMAIL && vm.messagePart.email.type === EMAIL_TYPES.AI) {
            vm.autoMessageStep.meta.previewImage = { url: '', heightPx: 0 };
            return Promise.resolve();
          } else {
            messagePreviewScreenshotService.screenshotRequest$.next();

            return firstValueFrom(
              messagePreviewScreenshotService.screenshotComplete$.pipe(
                switchMap(({ base64Url, heightPx }) =>
                  messagePreviewService.save(base64Url).pipe(map(({ link }) => ({ link, heightPx }))),
                ),
              ),
            ).then(({ link, heightPx }) => {
              vm.autoMessageStep.meta.previewImage = { url: link, heightPx };
            });
          }
        })
        .then(() => {
          return saveTelegramImages(vm.autoMessageStep);
        })
        .then(() => {
          vm.modalInstance.close(vm.autoMessageStep);
        });
    }

    function saveTelegramImages(autoMessageStep) {
      const tgPart = autoMessageStep.meta.message.parts.find((part) => part.type === MESSAGE_PART_TYPES.TELEGRAM);
      if (!tgPart) {
        return Promise.resolve();
      }
      const fileContentsToSave = tgPart[MESSAGE_PART_TYPES.TELEGRAM].bodyJson.contents.filter(
        (content) => content.type === 'file' && !!content.attachment.fileBlob,
      );
      if (fileContentsToSave.length === 0) {
        return Promise.resolve();
      }
      return Promise.all(
        fileContentsToSave.map((fileContent) => {
          return firstValueFrom(messageUtilsModel.saveFile(fileContent.attachment)).then((file) => {
            fileContent.attachment = file;
            return Promise.resolve();
          });
        }),
      );
    }
  }
})();

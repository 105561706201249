/**
 * Удаление html-тегов из строки
 */
(function () {
  'use strict';

  angular.module('myApp.filters.removeHtmlTags').filter('removeHtmlTags', removeHtmlTags);

  function removeHtmlTags() {
    return removeHtmlTagsFilter;

    /**
     * Удаление html тегов
     *
     * @param {String} value Строка, из которой нужно удалить теги
     * @return {String}
     */
    function removeHtmlTagsFilter(value) {
      if (angular.isDefined(value)) {
        value = value.replace(/<(?:.|\n)*?>/gm, ' '); // удаление тегов
        value = value.replace(/&nbsp;/gm, ' '); // Удаление неразрывных пробелов (хз зачем, это было написано давно)
        value = value.replace(/^\s+|\s+$/gm, ''); // трим
      }

      return value || '...';
    }
  }
})();

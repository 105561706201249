<ng-container *transloco="let t; read: 'knowledgeBaseActivateComponent'">
  <div class="modal-header">
    <h3 class="modal-title">{{ 'knowledgeBaseActivate.heading' | transloco }}</h3>
    <button
      *ngIf="activeModal"
      class="close"
      (click)="activeModal.dismiss()"
    >
      <i class="cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>
  <div class="modal-body no-padding-top no-padding-bottom">
    <div
      *cqHideInRu
      class="video-wrap position-relative margin-bottom-20"
    >
      <iframe
        class="position-absolute top-0 right-0 bottom-0 left-0 full-width full-height"
        allow="autoplay; fullscreen"
        allowfullscreen
        frameborder="0"
        src="https://player.vimeo.com/video/374396170"
      ></iframe>
    </div>
    <div
      *cqHideInUs
      class="video-wrap position-relative margin-bottom-20"
    >
      <iframe
        class="position-absolute top-0 right-0 bottom-0 left-0 full-width full-height"
        allow="autoplay; fullscreen"
        allowfullscreen
        frameborder="0"
        src="https://player.vimeo.com/video/374367385"
      ></iframe>
    </div>
    <div class="row">
      <div
        class="col-6 margin-bottom-30"
        *ngFor="let ability of range(1, 4); trackBy: trackBy"
      >
        <div class="flex align-items-center margin-bottom-10">
          <div
            class="ability-emoji flex align-items-center margin-right-10"
            [innerHTML]="'knowledgeBaseActivate.abilities.' + ability + '.emoji' | transloco"
          ></div>
          <h4
            class="no-margin-bottom"
            [innerHTML]="'knowledgeBaseActivate.abilities.' + ability + '.heading' | transloco"
          ></h4>
        </div>
        <p
          class="text-muted no-margin-bottom"
          [innerHTML]="'knowledgeBaseActivate.abilities.' + ability + '.description' | transloco"
        ></p>
      </div>
    </div>
  </div>
  <div class="modal-footer bordered-top justify-content-start">
    <button
      class="btn btn-primary"
      (click)="createKnowledgeBase()"
    >
      {{ 'knowledgeBaseActivate.createButton' | transloco }}
    </button>
  </div>
</ng-container>

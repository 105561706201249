import * as angular from 'angular';

import { environment } from '@environment';

export class L10nHelperService {
  currentCountry: 'US' | 'RU';
  LANGUAGE_MAP: Record<string, string> = {
    en: 'en-us',
    ru: 'ru-ru',
    es: 'es-es',
  };

  //@ngInject
  constructor() {
    this.currentCountry = environment.country;
  }

  /**
   * Получение четырёхсимвольного представления языка по двухсимвольному
   */
  getFourLetterLang(twoLetterLang: string): string {
    if (angular.isDefined(twoLetterLang)) {
      twoLetterLang = twoLetterLang.toLowerCase();
    }
    // считаем, что если ничего не найдено - возвращаем значение, поданное на вход, обработка ошибок незачем
    return this.LANGUAGE_MAP[twoLetterLang] || twoLetterLang;
  }

  /**
   * Получение двухсимвольного представления языка по четырёхсимвольному
   *
   * @param {String} fourLetterLang Четырёхсимвольное представление языка
   */
  getTwoLetterLang(fourLetterLang: string): string {
    if (angular.isDefined(fourLetterLang)) {
      fourLetterLang = fourLetterLang.toLowerCase();
    }
    // считаем, что если ничего не найдено - возвращаем значение, поданное на вход, обработка ошибок незачем
    return (
      Object.keys(this.LANGUAGE_MAP)
        .filter((key) => this.LANGUAGE_MAP[key] === fourLetterLang)
        .shift() || fourLetterLang
    );
  }

  isUsCountry(): boolean {
    return this.currentCountry === 'US';
  }

  isRusCountry(): boolean {
    return this.currentCountry === 'RU';
  }
}

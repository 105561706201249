(function () {
  'use strict';

  require('./domain-warming-up.controller');

  angular.module('myApp.dashboard').component('cqDomainWarmingUp', cqDomainWarmingUp());

  function cqDomainWarmingUp() {
    return {
      bindings: {
        emailsCount: '<', // количество отправленных емейлов
        emailsLimit: '<', // общее количество емейлов, которое нужно отправить для прогрева домена
      },
      controller: 'CqDomainWarmingUpController',
      controllerAs: 'vm',
      template: require('./domain-warming-up.html'),
    };
  }
})();

<ng-container *transloco="let t">
  <ng-container *ngFor="let prop of systemProps; trackBy: trackByFn">
    <div
      *ngIf="(getUserSystemPropValue(prop) || isRequirementProp(prop)) && (!isPinned(prop) || !pinningPossibility)"
      [ngClass]="{ 'property-body': !disablePropertyBodyClass }"
      class="align-items-center padding-5 padding-left-15 flex property-body"
    >
      <div
        class="flex-grow-1"
        [ngbTooltip]="
          pinningPossibility
            ? t('userSystemsPropsComponent.copyTooltip')
            : t('userSystemsPropsComponent.copyAndDragTooltip')
        "
        [disableTooltip]="!getUserSystemPropValue(prop) || disablePropertyBodyClass"
        placement="top-left"
        container="body"
      >
        <div
          class="flex-grow-1"
          ngxClipboard
          (cbOnSuccess)="copiedSuccess()"
          [cbContent]="getUserSystemPropValue(prop)"
        >
          <small class="property-title">
            {{ t('userSystemsPropsComponent.systemProps.' + prop) }}
          </small>

          <div
            *ngIf="!isPropWithArray(prop) && !isGoogleClientsIds(prop) && !isPropWithLink(prop)"
            [ngClass]="{ 'text-muted': !getUserSystemPropValue(prop) }"
            class="prop-value"
          >
            {{ getModifySystemPropValue(prop) }}
          </div>

          <ng-container *ngIf="isPropWithArray(prop) && !isGoogleClientsIds(prop)">
            <div
              [ngClass]="{ 'text-muted': !getUserSystemPropValue(prop) }"
              class="prop-value"
              *ngFor="let item of getUserSystemPropValue(prop) | userPropToIterable; let i = index; trackBy: trackByFn"
            >
              <span>
                {{
                  t('userSystemsPropsComponent.systemProps.' + prop + '.index', { index: wrap(i + 1, '', ': ') }) + item
                }}
              </span>
            </div>
          </ng-container>

          <div
            *ngIf="
              ((isPropWithLink(prop) && isRequirementProp(prop)) || isPropWithLink(prop)) && !isGoogleClientsIds(prop)
            "
            [ngClass]="{ 'text-muted': !getUserSystemPropValue(prop) }"
            class="prop-value"
          >
            <a
              (click)="$event.stopPropagation()"
              target="_blank"
              [attr.href]="getUserSystemPropValue(prop)"
            >
              {{ getUserSystemPropValue(prop) }}
            </a>
            <span [hidden]="getUserSystemPropValue(prop)">{{ t('general.unknown') }}</span>
          </div>

          <ng-container *ngIf="isGoogleClientsIds(prop)">
            <div
              class="prop-value"
              [ngClass]="{ 'text-muted': !getUserSystemPropValue(prop) }"
              *ngFor="let item of user.props.$google_client_ids | userPropToIterable; let i = index; trackBy: trackByFn"
            >
              {{ i + 1 + ': ' + item }}
            </div>
          </ng-container>
        </div>
      </div>

      <div class="flex">
        <div
          *ngIf="pinningPossibility"
          class="edit-prop-btn justify-center hide"
          [ngbTooltip]="t('userSystemsPropsComponent.pin')"
          placement="top-right"
          container="body"
        >
          <button
            class="btn btn-secondary"
            type="button"
            (click)="pinProp.emit(prop)"
          >
            <i class="btn-icon cqi-sm cqi-pin"></i>
          </button>
        </div>

        <div
          *ngIf="!pinningPossibility"
          class="flex pin justify-center align-items-center"
        >
          <i class="text-muted btn-icon cqi-sm cqi-pin"></i>
        </div>
        <div
          *ngIf="!pinningPossibility"
          class="edit-prop-btn justify-center hide"
          [ngbTooltip]="t('userSystemsPropsComponent.unpin')"
          placement="top-right"
          container="body"
        >
          <button
            class="btn btn-secondary"
            type="button"
            (click)="unpinProp.emit(prop)"
          >
            <i class="btn-icon cqi-sm cqi-pin-strikethrough"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '@panel/app/shared/shared.module';

import { MemberInvitationsNotFoundZeroDataComponent } from './member-invitations-not-found-zero-data.component';

@NgModule({
  declarations: [MemberInvitationsNotFoundZeroDataComponent],
  exports: [MemberInvitationsNotFoundZeroDataComponent],
  imports: [CommonModule, TranslocoModule, SharedModule],
})
export class MemberInvitationsNotFoundZeroDataModule {}

import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input } from '@angular/core';

import { FEATURE_LABELS } from '@http/feature-label/feature-label.constants';
import { FeatureLabelModel } from '@http/feature-label/feature-label.model';

@Component({
  selector: 'cq-new-label[featureLabel]',
  templateUrl: './new-label.component.html',
  styleUrls: ['./new-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewLabelComponent {
  @HostBinding('class')
  classes = 'badge badge-success';

  @HostBinding('style.display')
  private get display(): string {
    if (this.featureLabelModel.isNew(this.featureLabel)) {
      return '';
    }

    return 'none';
  }

  @Input()
  featureLabel!: FEATURE_LABELS;

  constructor(@Inject(FeatureLabelModel) readonly featureLabelModel: FeatureLabelModel) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChannelIconComponent } from './channel-icon.component';

@NgModule({
  declarations: [ChannelIconComponent],
  exports: [ChannelIconComponent],
  imports: [CommonModule],
})
export class ChannelIconModule {}

<div class="d-flex align-items-center">
  <cq-team-member-avatar
    class="flex-shrink-0"
    [avatar]="teamMember.avatar"
    [status]="teamMember.presence"
    size="xs"
  ></cq-team-member-avatar>
  <div class="name ml-10 overflow-hidden">
    <div class="text-truncate">
      {{ teamMember.name }}
    </div>
    <div
      *ngIf="showInternalName"
      class="text-truncate small text-muted"
    >
      {{ teamMember.internalName }}
    </div>
  </div>
</div>

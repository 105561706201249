/**
 * Контроллер для модального окна с вводом данных для заказа настройки сбора данных
 *
 * При создании модального окна в resolve можно передать modalWindowParams - объект следующего вида:
 *    {Object=} djangoUser
 *    {String=} appAddress адрес сайта
 */
(function () {
  'use strict';

  angular
    .module('myApp.modals.orderDataCollectionSettings')
    .controller('OrderDataCollectionSettingsModalController', OrderDataCollectionSettingsModalController);

  function OrderDataCollectionSettingsModalController(
    $filter,
    $interpolate,
    $uibModal,
    $uibModalInstance,
    carrotquestHelper,
    modalWindowParams,
    validationHelper,
  ) {
    var vm = this;

    vm.getOrderDataSettings = getOrderDataSettings;
    vm.modalWindowParams = modalWindowParams;
    vm.validationHelper = validationHelper;

    init();

    function init() {
      modalWindowParams.name = modalWindowParams.djangoUser.name ?? '';
      modalWindowParams.telephone = modalWindowParams.djangoUser.telephone ?? '';
      modalWindowParams.skype = modalWindowParams.djangoUser.skype ?? '';
      modalWindowParams.email = modalWindowParams.djangoUser.email ?? '';
    }

    /**
     * Закрытие модалки с передачей заполненых свойств из формы в событие
     *
     * @param {Boolean} isValid
     */
    function getOrderDataSettings(isValid) {
      if (isValid) {
        var userDataForDemo = {
          Имя: modalWindowParams.name,
          Телефон: modalWindowParams.telephone,
          Skype: modalWindowParams.skype,
          Email: modalWindowParams.email,
          'Адрес сайта': modalWindowParams.appAddress,
        };
        carrotquestHelper.track('Сбор данных о пользователях - заказал настройку', userDataForDemo);
        carrotquestHelper.identify({
          $name: modalWindowParams.name,
          $phone: modalWindowParams.telephone,
        });
        $uibModalInstance.close();
      }
    }
  }
})();

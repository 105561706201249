(function () {
  'use strict';

  angular
    .module('myApp.modals.chatBotTemplateModalWrapper')
    .component('cqChatBotTemplateWrapperModal', cqChatBotTemplateWrapperModal());

  function cqChatBotTemplateWrapperModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // modalWindowParams - смотри тип ChatBotTemplateModalData
      },
      controller: angular.noop(),
      controllerAs: 'vm',
      template: require('./chat-bot-template-wrapper.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Channel } from '@http/channel/channel.model';

@Component({
  selector: 'cq-conversation-channel[channel]',
  templateUrl: './conversation-channel.component.html',
  styleUrls: ['./conversation-channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationChannelComponent {
  @Input()
  channel!: Channel;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/**
 * Компонент для отображения zero-dat'ы когда не отправлено ни одного приглашения членов команды
 */

@Component({
  selector: 'cq-member-invitations-not-exist-zero-data[clickAddTeamMembersButton]',
  templateUrl: './member-invitations-not-exist-zero-data.component.html',
  styleUrls: ['./member-invitations-not-exist-zero-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberInvitationsNotExistZeroDataComponent {
  /** Callback на клик по кнопке добавления члена команды */
  @Output()
  clickAddTeamMembersButton: EventEmitter<void> = new EventEmitter<void>();

  /** Обработчик клика по кнопке добавления новых членов команды */
  onClickAddTeamMembersButton(): void {
    this.clickAddTeamMembersButton.emit();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { MessageModule } from '@panel/app/pages/conversations/conversations-list/message/message.module';
import { MessageStatusModule } from '@panel/app/pages/conversations/conversations-list/message-status/message-status.module';
import { TimeSinceLastReplyModule } from '@panel/app/pages/conversations/conversations-list/time-since-last-reply/time-since-last-reply.module';
import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { TagDefaultModule } from '@panel/app/partials/tag-default/tag-default.module';
import { UserAvatarModule } from '@panel/app/partials/user-card/user-avatar/user-avatar.module';

import { ConversationCardComponent } from './conversation-card.component';

@NgModule({
  declarations: [ConversationCardComponent],
  imports: [
    ChannelIconModule,
    CommonModule,
    MessageStatusModule,
    TagDefaultModule,
    TimeSinceLastReplyModule,
    TranslocoModule,
    UserAvatarModule,
    MessageModule,
  ],
  exports: [ConversationCardComponent],
})
export class ConversationCardModule {}

<ng-container *transloco="let t; read: 'channelAssignmentComponent'">
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
  >
    <button
      class="btn btn-borderless-primary d-inline-flex align-items-center w-100"
      type="button"
      [disabled]="disabled"
      ngbDropdownToggle
      [ngbTooltip]="t('tooltip')"
      [autoClose]="false"
      placement="top"
      container="body"
    >
      <cq-conversation-channel [channel]="assignedChannel"></cq-conversation-channel>
      <i class="cqi-sm cqi-chevron-down flex-shrink-0 margin-left-5"></i>
    </button>

    <div
      class="overflow-hidden"
      ngbDropdownMenu
    >
      <div class="dropdown-menu-inner">
        <div class="has-feedback has-feedback-left m-0 bordered-bottom">
          <input
            class="form-control form-control-lg"
            [autofocus]="dropdown.isOpen()"
            [formControl]="nameFilter"
            [placeholder]="t('dropdown.searchChannelInput.placeholder')"
          />
          <span class="form-control-feedback">
            <i class="cqi-sm cqi-search"></i>
          </span>
        </div>
        <div class="custom-scroll overflow-y-auto py-5">
          <button
            type="button"
            ngbDropdownItem
            *ngFor="let channel of filteredChannels$ | async; trackBy: trackByChannelId; empty: blank"
            (click)="assignTo(channel)"
          >
            <cq-conversation-channel [channel]="channel"></cq-conversation-channel>
            <i
              *ngIf="channel === assignedChannel"
              class="dropdown-item-icon-right cqi-sm cqi-check"
            ></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #blank>
    <h4 class="m-auto p-15 text-center text-muted">
      {{ t('dropdown.zeroData.noChannelFound') }}
    </h4>
  </ng-template>
</ng-container>

<div class="modal-header">
  <h3
    *ngIf="modalWindowParams.articles.length === 1"
    class="modal-title"
  >
    {{ 'modals.setArticleCategory.heading.forOne' | transloco }}
  </h3>
  <h3
    *ngIf="modalWindowParams.articles.length !== 1"
    class="modal-title"
  >
    {{ 'modals.setArticleCategory.heading.forFew' | transloco }}
  </h3>
  <button
    class="close"
    (click)="ngbActiveModal.dismiss()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<div class="modal-body">
  <div
    *ngIf="modalWindowParams.articles.length === 1"
    class="margin-bottom-20"
  >
    {{ 'modals.setArticleCategory.body.forOne' | transloco }}
  </div>
  <div
    *ngIf="modalWindowParams.articles.length !== 1"
    class="margin-bottom-20"
  >
    {{ 'modals.setArticleCategory.body.forFew' | transloco: { articlesCount: modalWindowParams.articles.length } }}
  </div>
  <form
    autocomplete="off"
    novalidate
    (submit)="confirm(setCategoryForm.valid)"
  >
    <div class="form-group no-margin-bottom">
      <ng-select
        [items]="modalWindowParams.categories"
        bindLabel="name"
        bindValue="id"
        [required]="true"
        [clearable]="false"
        [formControl]="setCategoryForm.controls.category"
        [cqShowError]="setCategoryForm.controls.category"
      >
        <ng-container *ng-footer-tmp>
          <button
            class="btn btn-secondary btn-block"
            (click)="openCreateCategoryModal()"
            type="button"
          >
            {{ 'modals.setArticleCategory.categorySelect.actionText' | transloco }}
          </button>
        </ng-container>
      </ng-select>
      <cq-validation-messages [control]="setCategoryForm.controls.category">
        <cq-validation-message errorName="required">
          {{ 'modals.setArticleCategory.categorySelect.errors.required' | transloco }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary"
    type="button"
    [ngClass]="{ disabled: isApiRequestPerformed || initialCategory?.id === newCategory?.id }"
    (click)="confirm(setCategoryForm.valid)"
  >
    {{ 'modals.setArticleCategory.moveButton' | transloco }}
  </button>
</div>

import { IPointData, Sprite } from 'pixi.js';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, map, startWith, take, takeUntil } from 'rxjs/operators';

import { PIXI_INTERACTION_EVENT } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.types';
import {
  getConnectionPoint,
  getHoveredConnectionPoint,
} from '@panel/app/pages/chat-bot/content/views/actions/abstract/connect-action/connect-action.textures';
import { BlockType } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { Connection, ConnectionSource } from '@panel/app/pages/chat-bot/content/views/connection';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions';
import { AbstractConstructorParameters } from '@panel/app/shared/types/abstract-constructor-parameters.type';

import { BaseActionABS } from '../base-action.abs';

export const CONNECTION_POINT_DIAMETER = 2;
export const CONNECTION_POINT_BORDER_SIZE = 1;

export abstract class ConnectionSourceActionABS<BodyJson extends object = EMPTY_BODY_JSON>
  extends BaseActionABS<BodyJson>
  implements ConnectionSource
{
  abstract blockType: BlockType;

  // Точка, от которой рисуются связи
  connectionPoint: Sprite = new Sprite();

  // Подписка на удаление nextBranch
  nextBranchDestroySub?: Subscription;

  constructor(...args: AbstractConstructorParameters<typeof BaseActionABS>) {
    super(...args);
    this.renderConnectionPoint();
    this.initConnectionListeners();

    fromEvent(this.viewport, 'zoomed')
      .pipe(
        map(() => this.getConnectionPointScale()),
        takeUntil(this.destroy$),
      )
      .subscribe((scale) => {
        this.connectionPoint.scale.set(scale, scale);
      });
  }

  addConnectionInfo(connection: Connection): void {
    this.connection = connection;
  }

  deleteConnectionInfo(connection: Connection): void {
    this.connection = undefined;
  }

  abstract getConnectionPointCoords(): IPointData;

  getConnectionPointScale(): number {
    const val1 = Math.pow(this.viewport.scaled, -1);
    const val2 = Math.max(val1, 1);
    return Math.min(val2, 1.75);
  }

  updateConnectionTarget(nextBranchLinkId: string | null) {
    const branch = this.canvasBranches.get(nextBranchLinkId ?? '');
    if (!branch) {
      this.nextBranchLinkId.setValue(null);
      this.form.nextBranchId = null;
      return;
    }
    this.nextBranchLinkId.setValue(branch.linkId);
    this.form.nextBranchId = branch.id;
  }

  protected initSubscriptions() {
    super.initSubscriptions();
    this.subToNextBranchChange();

    this.canvasBranches.update$.pipe(startWith(), takeUntil(this.destroy$), debounceTime(300)).subscribe(() => {
      this.subToNextBranchDestroy();
    });
  }

  private initConnectionListeners(): void {
    this.connectionPoint.on('pointerdown', (event: Event) => {
      event.stopPropagation(); // чтоб ивент не прокидывался на полотно и не отрабатывали dragListeners
      if (this.connection) {
        this.connection.startDrawToNewTarget();
      } else {
        this.connection = this.connectionFactory.create(this);
      }
      this.pixiInteractionService.registerPointerDown({
        type: PIXI_INTERACTION_EVENT.CONNECTION_POINT,
        instance: this,
      });
    });

    this.connectionPoint.on('mouseover', () => this.setHoveredTexture());
    this.connectionPoint.on('mouseout', () => this.setDefaultTexture());
  }

  renderConnectionPoint() {
    const circle = this.connectionPoint;

    this.setDefaultTexture();
    circle.eventMode = 'static';
    circle.cursor = 'pointer';
    circle.zIndex = 2;
  }

  /**
   * Рисование вида по умолчанию
   */
  setDefaultTexture(): void {
    this.connectionPoint.texture = getConnectionPoint(this.pixiApp.renderer);
  }

  /**
   * Рисование вида при наведении
   */
  setHoveredTexture(): void {
    this.connectionPoint.texture = getHoveredConnectionPoint(this.pixiApp.renderer);
  }

  // Подписка на изменение nextBranchLinkId
  private subToNextBranchChange() {
    this.form.controls.nextBranchLinkId.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((nextBranchLinkId: string | null) => {
        if (this.connection?.target) {
          this.botScenariosHelper.removeTargetFromScenarioOfSource(this, this.connection.target);
        }
        this.connection?.dropConnection();
        const targetBranch = this.canvasBranches.get(nextBranchLinkId ?? '');
        if (!targetBranch) {
          return;
        }
        this.connection = this.connectionFactory.create(this, targetBranch);
        this.botScenariosHelper.addTargetToScenarioOfSource(this, targetBranch);
        this.subToNextBranchDestroy();
      });
  }

  /**
   * Подписка на удаление активной nextBranch
   */
  private subToNextBranchDestroy() {
    this.nextBranchDestroySub?.unsubscribe();
    const targetBranch = this.canvasBranches.get(this.form.controls.nextBranchLinkId.value ?? '');
    if (!targetBranch) {
      return;
    }
    this.nextBranchDestroySub = targetBranch.destroy$
      //Заменил .first() на take(1) т.к. когда ничего не приходит падает ошибка, take(1) при пустых значениях ошибку не выдает
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe(() => {
        this.form.controls.nextBranchLinkId.setValue(null);
        this.form.nextBranchId = null;
      });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { ParamEditorModule } from '@panel/app/pages/trigger-messages/webhook-part-editor/components/param-editor/param-editor.module';

import { QueryParamsEditorComponent } from './query-params-editor.component';

@NgModule({
  declarations: [QueryParamsEditorComponent],
  exports: [QueryParamsEditorComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, ReactiveFormsModule, ParamEditorModule],
})
export class QueryParamsEditorModule {}

<ng-container *transloco="let t; read: 'headersEditorComponent'">
  <cq-param-editor
    class="mb-10"
    *ngFor="let header of defaultHeaders.controls"
    [formControl]="header"
    [paramsDisabled]="true"
    [paramInfo]="t('defaultHeaderTooltips.' + (header.value.key | lowercase))"
  ></cq-param-editor>

  <cq-param-editor
    class="mb-10"
    *ngFor="let header of control.controls; let i = index"
    [formControl]="header"
    (delete)="deleteHeader(i)"
  ></cq-param-editor>

  <button
    class="btn btn-block btn-secondary mt-15"
    (click)="addHeader()"
    type="button"
  >
    <i class="btn-icon-left cqi-sm cqi-plus"></i>
    <span>{{ t('addHeaderButton') }}</span>
  </button>
</ng-container>

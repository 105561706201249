import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';

@Component({
  selector: 'cq-telegram-manual[integrationTypeName][isManualExpanded]',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramManualComponent {
  /** Название типа текущей интеграции */
  @Input() integrationTypeName!: string;
  /** Свёрнута или нет инструкция по настройке интеграции */
  @Input() isManualExpanded!: boolean;
  /** Текущий язык */
  activeLang: string;
  /** Типы интеграций */
  INTEGRATION_TYPES = INTEGRATION_TYPES;
  /** Название проекта */
  projectName = environment.projectName;

  constructor(private readonly translocoService: TranslocoService) {
    this.activeLang = translocoService.getActiveLang();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { AddonsModule } from '@panel/app/pages/subscription/general/signed/addons/addons.module';
import { BalanceModule } from '@panel/app/pages/subscription/general/signed/balance/balance.module';
import { FaqModule } from '@panel/app/pages/subscription/general/signed/faq/faq.module';
import { InvoicesModule } from '@panel/app/pages/subscription/general/signed/invoices/invoices.module';
import { PaymentInfoModule } from '@panel/app/pages/subscription/general/signed/payment-info/payment-info.module';
import { PlanInfoModule } from '@panel/app/pages/subscription/general/signed/plan-info/plan-info.module';

import { SignedComponent } from './signed.component';

@NgModule({
  declarations: [SignedComponent],
  exports: [SignedComponent],
  imports: [
    AddonsModule,
    BalanceModule,
    CommonModule,
    FaqModule,
    InvoicesModule,
    OverlayscrollbarsModule,
    PaymentInfoModule,
    PlanInfoModule,
    TranslocoModule,
  ],
})
export class SignedModule {}

(function () {
  'use strict';

  require('./team-members-table.controller');

  angular.module('myApp.teamMembers').component('cqTeamMembersTable', cqTeamMembersTable());

  function cqTeamMembersTable() {
    return {
      bindings: {
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // текущее приложение
        currentTeamMembersCount: '=', // Текущее количество членов команды
        djangoUser: '=', // текущий пользователь
        teamMembers: '=', // члены команды
        teamMembersGroups: '=', // Группы членов команд
      },
      controller: 'CqTeamMembersTableController',
      controllerAs: 'vm',
      template: require('./team-members-table.html'),
    };
  }
})();

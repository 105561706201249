import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { ChatAudioMessageComponent } from '@panel/app/pages/settings/chat/components/audio-message/chat-audio-message.component';

@NgModule({
  declarations: [ChatAudioMessageComponent],
  exports: [ChatAudioMessageComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule],
})
export class ChatAudioMessageModule {}

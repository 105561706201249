import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { STARTER_GUIDE_STEPS } from 'app/http/starter-guide/starter-guide.constants';
import { mergeMap, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { STARTER_GUIDE_SOCIAL_INTEGRATION_SKIPPED } from '@http/app/app.constants';
import { App, AppModel } from '@http/app/app.model';
import { AppActivation, AppTask } from '@http/app/app.types';
import {
  ApiStarterGuideSetStepIsMadeRequest,
  StarterGuide,
  StarterGuideStep,
} from '@http/starter-guide/starter-guide.types';
import { CaseStyleHelper } from '@panel/app/services';
import { IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({ providedIn: 'root' })
export class StarterGuideModel {
  /**
   * Шаги стартер гайда
   */
  public starterGuideSteps = this.getStarterGuideSteps();

  /**
   * Коллбеки, вызываемые при выполнении шага
   * @private
   */
  private stepPassedCallbacks: Set<(step: STARTER_GUIDE_STEPS) => void> = new Set<
    (step: STARTER_GUIDE_STEPS) => void
  >();

  constructor(private appModel: AppModel, private http: HttpClient, private caseStyleHelper: CaseStyleHelper) {}

  /**
   * Добавить коллбек на выполнение шага
   * @param callback
   */
  public addPassedStepCallback(callback: (step: STARTER_GUIDE_STEPS) => void) {
    this.stepPassedCallbacks.add(callback);
  }

  /**
   * Удалить коллбек
   * @param callback
   */
  public removePassedStepCallback(callback: (step: STARTER_GUIDE_STEPS) => void) {
    if (this.stepPassedCallbacks.has(callback)) {
      this.stepPassedCallbacks.delete(callback);
    }
  }

  /**
   * Получение данных онбординга
   *
   * @param app Текущее приложение
   *
   * @return {Promise}
   */
  public get(app: App): Observable<StarterGuide> {
    return this.http.get<StarterGuide>(`/apps/${app.id}/onboarding`).pipe(
      mergeMap((starterGuide) => {
        return this.parse(starterGuide, app);
      }),
    );
  }

  /**
   * Пройден ли стартергайд полностью
   */
  public isCompleted(): boolean {
    return this.starterGuideSteps.every((step) => step.passed);
  }

  /**
   * Пройден ли шаг стартергайда
   *
   * @param stepName Название шага
   */
  public isStepIsMade(stepName: string): boolean {
    if (stepName === STARTER_GUIDE_STEPS.ENGAGEMENT_IN_A_CONVERSATION) {
      return (
        this.starterGuideSteps.find((step) => step.name === STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE)!.passed ||
        this.starterGuideSteps.find((step) => step.name === STARTER_GUIDE_STEPS.LEADS_COLLECTION)!.passed
      );
    }

    const step = this.starterGuideSteps.find((step) => step.name === stepName)!;

    return step.passed;
  }

  /**
   * Парсинг объекта онбординг
   *
   * @param starterGuide
   * @param app
   */
  public parse(starterGuide: StarterGuide, app: App): Observable<StarterGuide> {
    return this.http
      .get<AppActivation>('/apps/' + app.id + '/activation', {
        context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true),
      })
      .pipe(
        map((activation) => {
          for (let i = 0; i < this.starterGuideSteps.length; i++) {
            let step = this.starterGuideSteps[i];
            step.passed = false;

            switch (step.name) {
              case STARTER_GUIDE_STEPS.SOCIAL_INTEGRATIONS: {
                step.passed =
                  !!activation.social_network_integrations_reply_user ||
                  !!localStorage.getItem(STARTER_GUIDE_SOCIAL_INTEGRATION_SKIPPED);
                break;
              }
              case STARTER_GUIDE_STEPS.INSTALLATION_SERVICE: {
                step.passed = !this.appModel.isAppBlocked(app);
                break;
              }
              default: {
                //@ts-ignore
                step.passed = starterGuide[this.caseStyleHelper.toCamelCase(step.name)];
                break;
              }
            }
          }

          return starterGuide;
        }),
      );
  }

  /**
   * Проставление выбранной цели в регистрации на этапе вопросов
   *
   * @param appId ID приложения
   * @param goal Выбранная цель
   */
  setUsageGoal(appId: string, goal: AppTask): Observable<unknown> {
    const body = {
      //@ts-ignore
      app_usage_goal: this.caseStyleHelper.toUnderscore(goal),
    };

    return this.http.patch(`/apps/${appId}/onboarding`, body);
  }

  /**
   * Ставит шаг в выполненное состояние
   *
   * @param appId ID приложения
   * @param step Шаг
   */
  setStepIsMade(appId: string, step: STARTER_GUIDE_STEPS): Observable<unknown> {
    let stepIdx = 0;
    let body: ApiStarterGuideSetStepIsMadeRequest = {};

    this.starterGuideSteps.find((val, idx) => {
      if (val.name === step && !val.passed) {
        stepIdx = idx;
        body[step] = true;
      }
    });

    return this.http.patch(`/apps/${appId}/onboarding`, body).pipe(
      tap(() => {
        this.starterGuideSteps[stepIdx].passed = true;
        this.starterGuideSteps.find((s) => s.name === step)!.passed = true;
      }),
      tap(() => {
        for (let callback of this.stepPassedCallbacks) {
          callback(step);
        }
      }),
    );
  }

  /**
   * Получение шагов батарейки
   */
  private getStarterGuideSteps(): StarterGuideStep[] {
    return [
      {
        name: STARTER_GUIDE_STEPS.CHAT_SETTINGS,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.WRITE_SELF,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.GET_MORE,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.WORK_TIME,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.LEADS_COLLECTION,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.LEADS_PASSING,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.WARM_UP,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.SOCIAL_INTEGRATIONS,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.INSTALLATION_SERVICE,
        passed: false,
      },
      {
        name: STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE,
        passed: false,
      },
    ];
  }
}

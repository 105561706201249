import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '@panel/app/shared/shared.module';

import { AlreadyActiveTelegramBotModalComponent } from './already-active-telegram-bot-modal.component';

@NgModule({
  declarations: [AlreadyActiveTelegramBotModalComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, SharedModule, ReactiveFormsModule],
  providers: [NgbActiveModal],
})
export class AlreadyActiveTelegramBotModalModule {}

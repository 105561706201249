import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Observable } from 'rxjs';

import { AuthService } from '@panel/app/services/auth.service';
import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Добавляет параметр authToken ко всем запросам, которые посылаются на бэкенд
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private uiRouter: UIRouter) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!needToSkipInterceptors(request)) {
      const token = this.authService.getToken();

      if (token) {
        // если запрос посылается из аппа - к нему нужно добавить префикс appm-APP_ID, многие запросы без этого префикса свалятся
        let appId = this.uiRouter.globals.params.appId as string;

        if (!appId) {
          // эта штука отрабатывает только тогда, когда грузится впервые апп и в параметрах еще нет appId
          appId =
            this.uiRouter.stateService.transition && this.uiRouter.stateService.transition.targetState().params().appId;
        }

        const appIdPrefix = appId ? `appm-${appId}-` : '';

        request = request.clone({
          setHeaders: {
            Authorization: `Token ${appIdPrefix}${token}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}

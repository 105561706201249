import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import {
  STRIPE_3DS_MODAL_DATA,
  Stripe3dsModalComponent,
} from '@panel/app/pages/subscription/modals/stripe-3ds-modal/stripe-3ds-modal.component';
import { ModalHelperService } from '@panel/app/services';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { FirstPromoterService } from '@panel/app/services/first-promoter/first-promoter.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';

type ModalData = {
  payPeriod: any;
  amount: any;
  chosenDiscount: any;
  planName: any;
  currentApp: App;
  addOns: any[];
  planId: any;
  billingInfo: any;
  getPriceWithSale: (amount: number, payPeriod?: number) => number;
  getPriceWithoutSale: (amount: number, payPeriod?: number) => number;
};

export const STRIPE_PAY_MODAL_DATA = new InjectionToken<ModalData>('Modal data for StripePayModalComponent');

@Component({
  selector: 'cq-stripe-pay-modal',
  templateUrl: './stripe-pay-modal.component.html',
  styleUrls: ['./stripe-pay-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripePayModalComponent implements OnInit, OnDestroy {
  protected readonly STRIPE_CARD_ID = 'card-element-modal';

  protected isAddCardRequest: boolean = false;

  private create3DSFrameInterval: any = null;

  private stripe: any;

  private elements: any;

  private card: any;

  protected isApiRequestPerformed = false;

  protected isStipeElementsReady = false;

  protected cardValidation = {
    // Валидация карты
    complete: false, // Флаг показываюий завершенность заполнения карты
    errorMessage: '', // Сообщение об ошибке
    empty: false,
    isError: false, // Флаг показывающий наличие ошибки
  };

  protected cardValidationHelper: string | null = null; // Нужна для валидации карты

  protected readonly payByStripeForm = this.fb.group({
    card: [
      '',
      [
        (control: AbstractControl): ValidationErrors | null => {
          if (this.cardValidation.empty) {
            return { required: 'required' };
          }
          return null;
        },
        (control: AbstractControl): ValidationErrors | null => {
          if (this.cardValidation.isError) {
            return { cardError: this.cardValidation.errorMessage };
          }
          return null;
        },
      ],
    ],
  });

  constructor(
    @Inject(STRIPE_PAY_MODAL_DATA)
    protected readonly resolve: ModalData,
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly fb: FormBuilder,
    protected readonly billingInfoModel: BillingInfoModel,
    protected readonly toastr: ToastService,
    protected readonly modalService: ModalHelperService,
    protected readonly transloco: TranslocoService,
    protected readonly firstPromoterService: FirstPromoterService,
    protected readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.create3DSFrameInterval = setInterval(() => {
      if (document.getElementById(this.STRIPE_CARD_ID)) {
        this.createStripe();
        clearInterval(this.create3DSFrameInterval);
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.create3DSFrameInterval);
  }

  /** Была ли выполнена первая оплата со скидкой по реферальной программе */
  hasFirstPaymentDiscount(): any {
    return this.firstPromoterService.hasFirstPaymentDiscount();
  }

  payByStripe(isValid: any): any {
    let paymentIntent: any; // Объект с информацией о платежном намерениии в Stripe
    const addedAddOns = this.resolve.addOns
      .filter((addOn) => addOn.active) // Фильтруем только активные addOns
      .map((addOn) => addOn.id);
    const amount = Math.ceil(this.resolve.getPriceWithSale(this.resolve.amount, this.resolve.payPeriod) * 100); // Стоимость умножается на 100, так как на бэк должна отправиться сумма в копейках

    this.isApiRequestPerformed = true;

    if (isValid && !this.cardValidation.isError) {
      const params: any = {
        amount: amount,
        planId: this.resolve.planId,
      };

      if (addedAddOns.length > 0) {
        params.addons = addedAddOns;
      }

      firstValueFrom(this.billingInfoModel.generateTmpInvoice(this.resolve.currentApp.id, params))
        .then((response: any) => {
          return firstValueFrom(
            this.billingInfoModel.sendStripePaymentIntent(this.resolve.currentApp.id, response.data.code),
          );
        })
        .then((response: any) => {
          paymentIntent = response.data; // Информация с намерением оплаты

          let confirmCardPaymentParams: any = {
            // Параметры запроса оплаты по карты
            payment_method: {
              card: this.card,
            },
            setup_future_usage: 'off_session',
            return_url: `${environment.panelUrl}/3ds/stripe`,
          };

          return this.stripe.confirmCardPayment(paymentIntent.client_secret, confirmCardPaymentParams, {
            handleActions: false,
          });
        })
        .then((response: any) => {
          if (response.error) {
            // Если при оплате произошли технические ошибки на стороне Stripe
            this.toastr.danger(response.error.message);
            this.isApiRequestPerformed = false;
          } else if (response.paymentIntent.status === 'requires_action') {
            // Если требуется 3DS-подтверждение от банка
            const stripe3DSModal = this.modalService
              .provide(STRIPE_3DS_MODAL_DATA, { iframeSrc: response.setupIntent.next_action.redirect_to_url.url })
              .open(Stripe3dsModalComponent);

            firstValueFrom(stripe3DSModal.closed)
              .then(() => {
                return this.stripe.retrievePaymentIntent(paymentIntent.client_secret);
              })
              .then((response: any) => {
                if (response.error) {
                  // Если при 3DS-подтверждении произошли технические ошибки на стороне Stripe
                  this.isApiRequestPerformed = false;
                  this.toastr.danger(response.error.message);
                } else {
                  if (response.paymentIntent.status !== 'succeeded') {
                    // Если 3DS-подтверждение карты не осуществлена по каким-то причинам
                    this.isApiRequestPerformed = false;
                    this.toastr.danger(
                      this.transloco.translate('stripePaymentSuccessModal.paymentStatus.errors.toast.failed'),
                    );
                  } else {
                    // Если 3DS-подтверждение от банка прошло успешно
                    if (addedAddOns.length > 0) {
                      this.addAddOnsToBillingInfo(addedAddOns);
                    }

                    this.isApiRequestPerformed = false;
                    this.ngbActiveModal.close();
                  }
                }
              });
          } else if (response.paymentIntent.status !== 'succeeded') {
            // Если оплата не осуществлена по каким-то причинам
            this.toastr.danger(this.transloco.translate('stripePaymentSuccessModal.paymentStatus.errors.toast.failed'));
          } else {
            // Если 3DS-подтверждение от банка не требовалось и оплата прошла успешно
            if (addedAddOns.length > 0) {
              this.addAddOnsToBillingInfo(addedAddOns);
            }

            this.isApiRequestPerformed = false;
            this.ngbActiveModal.close();
          }
        });
    }
  }

  /**
   * Добавление аддонов в billingInfo
   *
   * @param {Array<string>} addOns Добавляемые аддоны
   */
  addAddOnsToBillingInfo(addOns: any): void {
    this.resolve.billingInfo.addons.concat(addOns);
  }

  /**
   * Создание Stripe элементов
   * NOTE: В fonts.src указан протокл и адрес до панели, потому что Stripe, на основании этого поля, генерирует font-face, в котором указывает полный url до шрифта.
   *
   */
  createStripe() {
    //@ts-ignore
    this.stripe = Stripe(environment.stripePaymentsPublicKey); // Создание экземпляра объетка Stripe

    this.elements = this.stripe.elements({
      // Создание экзмеляра elements, который управляет группой элементов
      fonts: [
        {
          family: 'PT Root UI',
          src: `url(${environment.panelUrl}/assets/fonts/PTRootUICQ-Regular.woff2)`,
          weight: '400',
        },
        {
          family: 'PT Root UI',
          src: `url(${environment.panelUrl}/assets/fonts/PTRootUICQ-Regular.woff)`,
          weight: '400',
        },
      ],
      locale: 'en',
    });

    let style = {
      // Стили для создаваемых Stripe элементов
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"PT Root UI"',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    };

    this.card = this.elements.create('card', style);
    this.card.mount(`#${this.STRIPE_CARD_ID}`);

    this.card.on('ready', () => this.onReadyStripe());
    this.card.on('change', (change: any) => this.onChangeStripe(change));
  }

  /**
   * Изменения в Stripe elements
   */
  onChangeStripe(error: any) {
    this.cardValidation.complete = error.complete;
    this.cardValidation.errorMessage = error.error ? error.error.message : '';
    this.cardValidation.isError = !!error.error && error.error?.code;
    this.cardValidation.empty = error.empty;
    this.payByStripeForm.controls.card.updateValueAndValidity();

    if (!this.cardValidation.empty) {
      this.payByStripeForm.controls.card.markAsTouched();
    }
    this.cdr.markForCheck();
  }

  onReadyStripe() {
    this.isStipeElementsReady = true;
    setTimeout(() => {
      this.card.focus();
      this.cdr.markForCheck();
    }, 1000);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { VideoViewModalParams } from '@panel/app/partials/modals/video-view/view-video-modal.types';

@Component({
  selector: 'cq-view-video-modal[modalWindowParams]',
  templateUrl: './view-video-modal.component.html',
  styleUrls: ['./view-video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoViewModalComponent {
  /** Параметры модального окна */
  @Input() modalWindowParams!: VideoViewModalParams;

  /** Получение заголовка */
  get heading(): string {
    return this.modalWindowParams.heading;
  }

  /** Получение Url видео */
  get videoUrl(): SafeResourceUrl | '' {
    return this.modalWindowParams.videoUrl
      ? this.sanitizer.bypassSecurityTrustResourceUrl(this.modalWindowParams.videoUrl)
      : '';
  }

  constructor(public activeModal: NgbActiveModal, private readonly sanitizer: DomSanitizer) {}

  /** Закрытие модального окна */
  closeModal() {
    this.activeModal.dismiss();
  }
}

/**
 * Компонент готовых шаблонов
 */
(function () {
  'use strict';

  require('./ready-message-templates.controller');

  angular.module('myApp.readyMessageTemplates').component('cqReadyMessageTemplates', cqReadyMessageTemplates());

  function cqReadyMessageTemplates() {
    return {
      bindings: {
        currentApp: '=', // текущее приложение
        onLoad: '&?', // Колбэк при загрузке шаблонов
      },
      controller: 'CqReadyMessageTemplatesController',
      controllerAs: 'vm',
      template: require('./ready-message-templates.html'),
    };
  }
})();

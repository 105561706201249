<ng-container *transloco="let t">
  <div class="card">
    <div class="card-body margin-between-rows-20">
      <div>
        <div class="font-weight-bold">{{ t('integrations.alloka.manual.heading') }}</div>
        <div
          class="margin-bottom-20"
          [innerHTML]="t('integrations.alloka.manual.description', { integrationTypeName: integrationTypeTranslation })"
        ></div>
        <div class="text-center">
          <img
            class="full-max-width"
            [src]="'assets/img/' + activeLang + '/integrations/alloka/alloka-2.gif'"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-3 step-description">
          <h4 class="font-weight-bold">
            {{ t('integrations.alloka.manual.step1.heading', { integrationTypeName: integrationTypeTranslation }) }}
          </h4>
        </div>
        <div class="col-9">
          <div
            [innerHTML]="
              t('integrations.alloka.manual.step1.description', { integrationTypeName: integrationTypeTranslation })
            "
          ></div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 step-description">
          <h4 class="font-weight-bold">{{ t('integrations.alloka.manual.step2.heading') }}</h4>
        </div>
        <div class="col-9">
          <div class="margin-bottom-10">
            {{ t('integrations.alloka.manual.step2.description', { integrationTypeName: integrationTypeTranslation }) }}
          </div>
          <img
            class="full-max-width"
            [src]="'assets/img/' + activeLang + '/integrations/alloka/alloka-1.jpg'"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-3 step-description">
          <h4 class="font-weight-bold">{{ t('integrations.alloka.manual.codeInsertion.heading') }}</h4>
          <div>{{ t('integrations.alloka.manual.codeInsertion.headingDescription') }}</div>
        </div>
        <div class="col-9">
          <pre>&lt;!-- <span>{{ projectName }}</span> + <span>{{ integrationTypeTranslation }}</span> --&gt;
&lt;script src="https://analytics.alloka.ru/integrations/carrot.js" type="text/javascript"&gt;&lt;/script&gt;
&lt;!-- <span>{{ projectName }}</span> + <span>{{ integrationTypeTranslation }}</span> --&gt;</pre>
        </div>
      </div>
      <div class="row">
        <div class="col-3 step-description">
          <h4 class="font-weight-bold">{{ t('integrations.alloka.manual.step3.heading') }}</h4>
        </div>
        <div class="col-9">
          <div class="margin-bottom-10">
            {{
              t('integrations.alloka.manual.step3.description', {
                integrationTypeName: integrationTypeTranslation,
                projectName: projectName
              })
            }}
          </div>
          <img
            class="full-max-width"
            [src]="'assets/img/' + activeLang + '/integrations/alloka/alloka-3.jpg'"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="text-right">
    <a
      class="btn btn-primary"
      href="https://alloka.ru/"
      target="_blank"
      (click)="trackClickGoToAlloka()"
      >{{ t('integrations.alloka.goToAllokaButton', { integrationTypeName: integrationTypeTranslation }) }}</a
    >
  </div>
</ng-container>

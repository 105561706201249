import { Directive, Inject } from '@angular/core';
import { INTERSECTION_THRESHOLD, IntersectionObserverService } from '@ng-web-apis/intersection-observer';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';

import { DestroyService } from '@panel/app/services';

/**
 * Вешается на эелемент с position: sticky.
 *
 * UPD: Оказалось, что это не такой прикольный инструмент как хотелось бы, потому что для его работы нужно пересечение
 * sticky элементом края viewport, что в нашем случае является изменение позиции top/bottom/left/right на 1px
 * (поэтому в некоторых местах можно увидеть -1px). Иначе intersectionObserver незарегистрирует пересечение
 *
 * В момент, когда этот элемент запинен вешает на него аттрибут stuck (прилип)
 */
@Directive({
  selector: '[cqStickyStuck]',
  providers: [
    IntersectionObserverService,
    {
      provide: INTERSECTION_THRESHOLD,
      useValue: 1,
    },
    DestroyService,
  ],
})
export class StickyStuckDirective {
  constructor(
    @Inject(IntersectionObserverService)
    entries$: IntersectionObserverService,
    destroy$: DestroyService,
  ) {
    entries$
      .pipe(
        takeUntil(destroy$),
        mergeMap((entry) => entry),
        // TLDR: Согласно спеке, rootBounds не должен быть null (в нашем кейсе)
        // https://www.w3.org/TR/intersection-observer/#dom-intersectionobserverentry-rootbounds
        // Подробней (далее моя персональная теория, почему так происходит):
        // Первое значение получаемое subscribe содержит две entity, хотя подписываемся мы на один элемент
        // и однозначно entity должно быть в количестве 1. Подозреваю, что директива инитится раньше,
        // чем компонент попадает в DOM, поэтому одно из этих значений, из того времени, когда эелемент еще не в DOM,
        // поэтому у него rootBounds=null и именно поэтому мы его отсекаем.
        filter((entry) => entry.rootBounds !== null),
      )
      .subscribe((entry) => {
        entry.target.toggleAttribute('stuck', entry.intersectionRatio < 1);
      });
  }
}

/**
 * Компонент страницы шаблонов сообщений
 */
(function () {
  'use strict';

  require('./message-templates-popover.controller');

  angular.module('myApp.messageTemplatesPopover').component('cqMessageTemplatesPopover', cqMessageTemplatesPopover());

  function cqMessageTemplatesPopover() {
    return {
      bindings: {
        currentApp: '=', // Текущий апп
        djangoUser: '=', // Текущий пользователь
        isMessagePartValid: '=?', // Валидность типа сообщения, используемая при сохранении
        messagePart: '=?', // Вариант сообщения для сохранения его в шаблон
        onTemplateClick: '&?', // Callback вызываемый при клике на шаблон
        onCleanClick: '&?', // Callback вызываемый при клике на "создать автосообщение с нуля"
        isPopover: '=?', // Указывает на то, открываются шаблоны в поповере или по умолчанию открыты
        closePopover: '<', // callback закрытия поповера
      },
      controller: 'CqMessageTemplatesPopoverController',
      controllerAs: 'vm',
      template: require('./message-templates-popover.html'),
    };
  }
})();

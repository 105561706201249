import { PLAN_FEATURE } from '../../../../app/services/billing/plan-feature/plan-feature.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.dashboard').controller('CqKarmaNotificationController', CqKarmaNotificationController);

  function CqKarmaNotificationController(
    carrotquestHelper,
    caseStyleHelper,
    emailKarmaModel,
    planFeatureAccessService,
  ) {
    var vm = this;
    vm.$onInit = init;

    function init() {
      vm.accessToManualMessagesEmail = planFeatureAccessService.getAccess(
        PLAN_FEATURE.MANUAL_MESSAGES_EMAIL,
        vm.currentApp,
      );

      vm.karma = {}; // Статус и тип статуса, который необходимо выводить
      vm.imageSrc = null; // Картинка статуса
      vm.openChat = openChat;

      getEmailKarma(vm.currentApp.id);
    }

    /**
     * Получает данные по карме и составляет объект с нужными данными
     *
     * @param {String} appId ID приложения
     */
    function getEmailKarma(appId) {
      vm.accessToManualMessagesEmail.hasAccess &&
        firstValueFrom(emailKarmaModel.getEmailKarma(appId)).then(getEmailKarmaSuccess);

      function getEmailKarmaSuccess(emailKarma) {
        vm.karma = emailKarma;
        vm.karma.worstMetrics.status = caseStyleHelper.toCamelCase(vm.karma.worstMetrics.status);
        vm.karma.worstMetrics.type = caseStyleHelper.toCamelCase(vm.karma.worstMetrics.type);
        vm.imageSrc = getImageSrc(emailKarma.worstMetrics.status);
      }
    }

    /**
     * Получение пути до картинки статуса кармы в зависимости от статуса
     *
     * @param {String} status Статус кармы
     * @return {String}
     */
    function getImageSrc(status) {
      var urlPart = caseStyleHelper.toKebabCase(status);
      return 'assets/img/default/dashboard/karma-notification/karma-status.svg'.replace('karma-status', urlPart);
    }

    /**
     * Открытие чата
     */
    function openChat() {
      carrotquestHelper.open();
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Subject } from 'rxjs';

import { AddonHttpService } from '@http/addon/http/addon-http.service';
import { App } from '@http/app/app.model';
import {
  AddonAvailabilityPerPlan,
  V3_PLAN,
} from '@panel/app/pages/subscription/pricing/pricing-addons/pricing-addons.types';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

/**
 * Компонент для работы с аддоном в зависимости от тарифных планов
 */

@Component({
  selector: 'cq-pricing-addon',
  templateUrl: './pricing-addon.component.html',
  styleUrls: ['./pricing-addon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingAddonComponent {
  @Input()
  currentApp!: App;

  /** Аддон */
  @Input()
  addon!: BILLING_ADD_ONS;

  /** Конфигурация аддона по тарифным планам */
  @Input()
  addonConfigPerPlan!: AddonAvailabilityPerPlan;

  /** Предполагаемое количество уникальных пользователей */
  @Input()
  expectedVisitors: number = 1000;

  /** Подключен ли аддон */
  @Input()
  set isAddonConnected(value: boolean) {
    this.plansPerAddon.forEach((plan) => {
      this.addonConfigPerPlan[plan].value = value;
    });
  }

  /** Callback на изменение конфигурации аддона по тарифным планам */
  @Output()
  addonConfigPerPlanChange = new Subject<AddonAvailabilityPerPlan>();

  @Input()
  needsPersonalPlan = false;

  readonly BILLING_ADD_ONS = BILLING_ADD_ONS;

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly billingPlanService: BillingPlanService,
    readonly l10nHelper: L10nHelperService,
    private readonly httpService: AddonHttpService,
    private readonly transloco: TranslocoService,
  ) {}

  /** Получение тарифных планов в зависимости от аддона */
  get plansPerAddon(): V3_PLAN[] {
    return Object.keys(this.addonConfigPerPlan) as V3_PLAN[];
  }

  /** Получение названия аддона */
  public addonTranslate(addon: BILLING_ADD_ONS): string {
    return this.translocoService.translate(`models.billingInfo.billingAddOns.${addon}`);
  }

  /**
   * Получение стоимости аддона в зависимости от предполагаемого количества уникальных пользователей
   *
   * @param addon - Аддон, стоимость которого необходимо получить
   * @param expectedVisitors - Предполагаемое количество уникальных пользователей
   */
  public getPriceAddonPerExpectedVisitors(addon: BILLING_ADD_ONS, expectedVisitors: number): number {
    return this.billingPlanService.getAddOnPrice(addon, this.currentApp, expectedVisitors);
  }

  /**
   * Доступен ли аддон на тарифном плане
   *
   * @param plan - Тарифный план, на котором необходимо проверить недоступность аддона
   */
  public isAddonAvailableOnPlan(plan: V3_PLAN): boolean {
    return this.addonConfigPerPlan[plan].availability === 'available';
  }

  /**
   * Включён ли аддон в тарифный план
   *
   * @param plan - Тарифный план, на котором необходимо проверить недоступность аддона
   */
  public isAddonIncludedInPlan(plan: V3_PLAN): boolean {
    return this.addonConfigPerPlan[plan].availability === 'included';
  }

  /**
   * Недоступен ли аддон на тарифном плане
   *
   * @param plan - Тарифный план, на котором необходимо проверить недоступность аддона
   */
  public isAddonNotAvailableOnPlan(plan: V3_PLAN): boolean {
    return this.addonConfigPerPlan[plan].availability === 'not-available';
  }

  public isSecurityAddon(): boolean {
    return this.addon === BILLING_ADD_ONS.SECURITY;
  }

  /**
   * Обновление конфигурации аддонов в зависимости от тарифного плана
   *
   * @param plan - Тарифный план, на котором необходимо изменить конфигурацию аддона
   * @param newValue - Новое значение, на которое необходимо изменить конфигурацию аддона
   */
  public updateAddonConfigPerPlan(plan: V3_PLAN, newValue: boolean): void {
    this.addonConfigPerPlanChange.next({
      ...this.addonConfigPerPlan,
      [plan]: {
        availability: this.addonConfigPerPlan[plan].availability,
        value: newValue,
      },
    });
  }

  connectAddonViaChat(addon: BILLING_ADD_ONS) {
    const message = this.transloco.translate('addons.connectViaChatMessage', { name: addon });

    this.httpService.connectViaChat(message);
  }
}

/**
 * Фильтрация массива шаблонов по типу сообщения
 */
import { MESSAGE_PART_TYPES } from '../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.filters.filterTemplates').filter('filterTemplates', filterTemplates);

  function filterTemplates() {
    return filterTemplatesFilter;

    /**
     * Фильтр массива шаблонов по типу варианта сообщения
     *
     * @param {Array.<Object>} templates Изначальный массив шаблонов
     * @param {MESSAGE_PART_TYPES} messagePartType Тип варианта сообщения
     * @returns {Array} Отфильтрованный массив шаблонов
     */
    function filterTemplatesFilter(templates, messagePartType) {
      var filteredTemplates = [];

      angular.forEach(templates, function (template) {
        if (messagePartType === MESSAGE_PART_TYPES.POPUP_BIG || messagePartType === MESSAGE_PART_TYPES.POPUP_SMALL) {
          if (template.type === MESSAGE_PART_TYPES.POPUP_BIG || template.type === MESSAGE_PART_TYPES.POPUP_SMALL) {
            filteredTemplates.push(template);
          }
        } else if (
          messagePartType === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
          messagePartType === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
        ) {
          if (
            template.type === MESSAGE_PART_TYPES.BLOCK_POPUP_BIG ||
            template.type === MESSAGE_PART_TYPES.BLOCK_POPUP_SMALL
          ) {
            filteredTemplates.push(template);
          }
        } else {
          if (template.type === messagePartType) {
            filteredTemplates.push(template);
          }
        }
      });

      return filteredTemplates;
    }
  }
})();

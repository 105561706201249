/**
 * Директива копирует передаваемый ей текст или текст из элемента в буфер обмена
 */
(function () {
  'use strict';

  require('./copy-text.controller');

  angular.module('myApp.directives.copyText').directive('cqCopyText', cqCopyText);

  function cqCopyText() {
    return {
      restrict: 'A',
      scope: {
        elementId: '@?', //Id елемента из которого надо скопировать текст
        text: '=?', //Текст который надо скопировать
        copySuccessText: '@?', //Текст при успешном копировании
        isHideSuccessText: '=?', //Флаг позволяющий отключить тостер об успешном копировании
        onCopied: '&?',
      },
      controller: 'cqCopyTextController',
      controllerAs: 'vm',
      bindToController: true,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      iElement.on('click', controller.copyText);
    }
  }
})();

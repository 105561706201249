import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { needToSkipInterceptors } from '@panel/app/shared/functions/skip-interceptors.function';

/**
 * Удаляет параметры запроса со значением null
 */
@Injectable()
export class NullRequestParamsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!needToSkipInterceptors(request)) {
      const filteredParams = request.params.keys().reduce((params, key) => {
        const value = request.params.get(key);
        return !['undefined', 'null'].includes(value as string)
          ? params.set(key, value as string | number | boolean)
          : params;
      }, new HttpParams());

      request = request.clone({
        params: filteredParams,
      });
    }

    return next.handle(request);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AbstractTemplate } from '@panel/app/shared/types/abstract-template.type';

@Injectable()
export class ViewStateService {
  public state$: BehaviorSubject<TemplateViewState> = new BehaviorSubject(initialState);

  setState(template: null | AbstractTemplate) {
    this.state$.next({
      template,
      view: template ? 'preview' : 'list',
    });
  }
}

/**
 * Данные состояния
 */
export type TemplateViewState = {
  template: null | AbstractTemplate;
  view: 'list' | 'preview';
};

/**
 * Начальное состояние
 */
const initialState: TemplateViewState = {
  template: null,
  view: 'list',
};

<ng-container *transloco="let t; read: 'datePickerComponent'">
  <ngb-datepicker
    #datepicker
    class="ngb-custom-date-picker p-10"
    [dayTemplate]="day"
    (dateSelect)="onSelectDate($event)"
    navigation="none"
  >
    <ng-template ngbDatepickerContent>
      <cq-datepicker-month-navigation
        [datepicker]="datepicker"
        [initialDate]="initialDate"
      ></cq-datepicker-month-navigation>

      <div *ngFor="let month of datepicker.state.months">
        <ngb-datepicker-month
          class="border rounded"
          [month]="month"
        ></ngb-datepicker-month>
      </div>
    </ng-template>
  </ngb-datepicker>

  <ng-template
    #day
    let-date
    let-focused="focused"
  >
    <div
      class="date-range-picker-day-view"
      [class.outside]="isOutside(datepicker.state, date)"
      [class.focused]="focused"
      [class.hover]="isHover(date)"
      [class.selected]="isSelected(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </div>
  </ng-template>
</ng-container>

(function () {
  'use strict';

  angular.module('myApp.services.apiRequest').factory('APIRequest', apiRequest);

  function apiRequest($http, $q, $rootScope, ipCookie, API_ENDPOINT, systemError) {
    return performRequest;

    /**
     * Выполнение запросов к серверу
     *
     * @param {String} method Метод HTTP-запроса
     * @param {String} endpoint URL запроса в API
     * @param {Object} params Параметры запроса
     * @return {Promise}
     */
    function performRequest(
      method,
      endpoint,
      { paginatorParams, ignoreLoadingBar = false, ignoreErrors = false, apiVersion, errorsException, ...params },
    ) {
      const finalEndpoint = `${API_ENDPOINT}${endpoint}`;
      var paramsGET, paramsPOST;

      let token = ipCookie('carrotquest_auth_token_panel');
      let app;

      //TODO Костыль для быстрой работы с шардами и для вызова методов аппа вне аппа
      if (params && params.app) {
        token = 'appm-' + params.app + '-' + token;
      } else if ($rootScope.currentApp) {
        token = 'appm-' + $rootScope.currentApp.id + '-' + token;
        app = $rootScope.currentApp.id;
      }

      if (method == 'GET') {
        paramsGET = angular.extend(
          {},
          params,
          paginatorParams,
          { id_as_string: true },
          app ? { app } : {},
          apiVersion ? { version: apiVersion } : {},
        );
        paramsPOST = '';
      } else {
        paramsGET = '';
        paramsPOST = angular.extend(
          {},
          params,
          paginatorParams,
          { id_as_string: true },
          app ? { app } : {},
          apiVersion ? { version: apiVersion } : {},
        );
      }

      var paramsPOSTSerialized = serializer(paramsPOST);

      var formData = new FormData();

      angular.forEach(paramsPOSTSerialized, function (item, key) {
        formData.append(key, item);
      });

      let headers = {
        'Content-Type': undefined, // браузер за нас решит какой content-type подставлять
      };

      if (token) {
        headers['Authorization'] = `Token ${token}`;
      }

      return $http({
        method: method,
        url: finalEndpoint,
        params: paramsGET,
        data: formData,
        headers: headers,
        ignoreLoadingBar: ignoreLoadingBar,
      }).then(
        function (response) {
          return response.data;
        },
        function (response) {
          if (!ignoreErrors) {
            if (navigator.onLine) {
              showSomethingWentWrong(response.data, errorsException);
            } else {
              systemError.offlineRequestToast.show();
            }
          }

          return $q.reject(response.data);
        },
      );
    }

    /**
     * Сериализатор, который преобразует любой массив или объект в JSON-строку, а файлы и примитивы оставляет как есть
     *
     * @param {Object} obj Исходный объект
     * @return {Object}
     */
    function serializer(obj) {
      var destination = {};

      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (angular.isObject(obj[key]) && !(obj[key] instanceof File)) {
            // если это объект, и при этом не файл - его надо превратить в JSON-строку
            destination[key] = angular.toJson(obj[key]);
          } else if (obj[key] !== null && obj[key] !== undefined) {
            // если это примитив или файл - просто присваиваем
            destination[key] = obj[key];
          }
        }
      }

      return destination;
    }

    /**
     * Показ ошибки "Кажется, что-то пошло не так"
     *
     * @param msg
     * @param {String|Array} exceptions Ошибки на которые не показывать системную ошибку
     */
    function showSomethingWentWrong(msg, exceptions) {
      if (msg && msg.meta && msg.meta.error) {
        if (msg.meta.error === 'PermissionDenied') {
          return;
        } else if (angular.isDefined(exceptions)) {
          if (angular.isArray(exceptions)) {
            for (var i = 0; i < exceptions.length; i++) {
              if (exceptions[i] == msg.meta.error) {
                return;
              }
            }
          } else {
            if (exceptions == msg.meta.error) {
              return;
            }
          }
        }
      }

      systemError.somethingWentWrongToast.show();
    }
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tuiPure } from '@taiga-ui/cdk';
import { sortBy } from 'lodash-es';
import { GroupByPipe } from 'ngx-pipes';
import { debounceTime, distinctUntilChanged, map, Observable, startWith, tap } from 'rxjs';

import { DjangoUser } from '@http/django-user/django-user.types';
import { TeamMember } from '@http/team-member/team-member.types';
import { TeamMemberGroup } from '@http/team-member-group/team-member-group.types';

@Component({
  selector: 'cq-operator-assignment[assignedTeamMember][djangoUser][teamMembers]',
  templateUrl: './operator-assignment.component.html',
  styleUrls: ['./operator-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorAssignmentComponent {
  /** Член команды, на которого назначен диалог */
  @Input()
  assignedTeamMember!: TeamMember | null;

  // HACK: пришлось сюда передавать django-пользователя, т.к. нужен его ID для определения себя среди списка членов команды
  // Вообще, ID django-пользователя нужно получать через сервис, по аналогии с appId, но я не смог этого сделать, т.к. из роутера не получилось достать djangoUser
  @Input()
  djangoUser!: DjangoUser;

  @Input()
  teamMembers!: TeamMember[];

  /** Отключение возможности переназначить оператора */
  @Input()
  disabled: boolean = false;

  @Output()
  assignedTeamMemberChange = new EventEmitter<TeamMember | null>();

  /** Фильтрация по имени члена команды */
  nameFilter = new FormControl('', { nonNullable: true });

  /** Фильтруются ли в данный момент члены команды */
  filtered = false;

  /** Отфильтрованные по имени члены команды */
  filteredTeamMembers$: Observable<TeamMember[]> = this.nameFilter.valueChanges.pipe(
    debounceTime(400),
    startWith(''),
    distinctUntilChanged(),
    tap((nameFilter: string) => {
      if (nameFilter === '') {
        this.filtered = false;
      } else {
        this.filtered = true;
      }
    }),
    map((nameFilter) => {
      return this.filterByName(this.sortedTeamMembers, nameFilter);
    }),
    map((teamMembers) => {
      return teamMembers.filter((teamMember) => teamMember.id !== this.me.id);
    }),
  );

  /** Группировка отфильтрованных членов команды по уникальным группам */
  groupedTeamMembers$: Observable<Map<TeamMemberGroup, TeamMember[]>> = this.filteredTeamMembers$.pipe(
    map((teamMembers) => {
      return this.groupByPipe.transform(teamMembers, 'group.name');
    }),
    map((groupedTeamMembers: { [key: string]: TeamMember[] }) => {
      const map = new Map<TeamMemberGroup, TeamMember[]>();

      this.getUniqueGroups(this.teamMembers).forEach((teamMemberGroup) => {
        if (groupedTeamMembers[teamMemberGroup.name]) {
          map.set(teamMemberGroup, groupedTeamMembers[teamMemberGroup.name]);
        }
      });

      return map;
    }),
  );

  constructor(private readonly groupByPipe: GroupByPipe) {}

  get me(): TeamMember {
    return this.getMe(this.teamMembers, this.djangoUser.id);
  }

  get sortedTeamMembers(): TeamMember[] {
    return this.sortByName(this.teamMembers);
  }

  /**
   * Переназначение диалога на члена команды
   *
   * @param teamMember Член команды
   */
  assignTo(teamMember: TeamMember | null): void {
    if (this.assignedTeamMember === teamMember) {
      return;
    }

    this.assignedTeamMember = teamMember;
    this.assignedTeamMemberChange.emit(teamMember);
  }

  /**
   * Case insensitive фильтрация списка членов команды по имени
   *
   * @param teamMembers Список членов команды
   * @param filter Значение фильтра
   */
  @tuiPure
  filterByName(teamMembers: TeamMember[], filter: string): TeamMember[] {
    return teamMembers.filter((teamMember) => teamMember.name.toLowerCase().includes(filter.toLowerCase()));
  }

  /**
   * Получение себя
   *
   * @param teamMembers Список членов команды
   */
  @tuiPure
  getMe(teamMembers: TeamMember[], myId: string): TeamMember {
    return teamMembers.find((teamMember) => teamMember.id === myId)!;
  }

  /**
   * Получение отсортированного по имени списка уникальных групп членов команды
   * Сортировка - case insensitive
   * Системные группы идут в конце списка
   *
   * @param teamMembers Список членов команды
   */
  @tuiPure
  getUniqueGroups(teamMembers: TeamMember[]): TeamMemberGroup[] {
    return sortBy(
      [...new Map(teamMembers.map((teamMember) => [teamMember.group.id, teamMember.group])).values()],
      ['isSystem', (group) => group.name.toLowerCase()],
    );
  }

  /**
   * Case insensitive сортировка членов команды
   *
   * @param teamMembers Список членов команды
   */
  @tuiPure
  sortByName(teamMembers: TeamMember[]): TeamMember[] {
    return sortBy(teamMembers, (teamMember) => teamMember.name.toLowerCase());
  }

  /** Трек по ID канала */
  trackByTeamMemberId(index: number, teamMember: TeamMember): string {
    return teamMember.id;
  }

  /** Трек по названию группы */
  trackByGroupName(index: number, groupedTeamMembers: { key: TeamMemberGroup; value: TeamMember[] }): string {
    return groupedTeamMembers.key.id;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UIRouter } from '@uirouter/core';

import { KnowledgeBaseSettingsModalComponent } from '@panel/app/pages/knowledge-base/shared/components/knowledge-base-settings-modal/knowledge-base-settings-modal.component';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-knowledge-base',
  templateUrl: './knowledge-base.component.html',
  styleUrls: ['./knowledge-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeBaseComponent implements OnInit {
  /** информация о биллинге */
  @Input() billingInfo!: any;
  /** Текущий App */
  @Input() currentApp!: any;
  /** Текущая категория */
  @Input() currentCategory?: any;
  /** Пользователь */
  @Input() djangoUser!: any;
  /** Подключён ли модуль с продвинутой базой знаний */
  @Input() isKnowledgeBaseProActivated!: boolean;
  /** Идет ли в данный момент загрузка данных */
  @Input() isLoading!: boolean;
  /** Настройки БЗ */
  @Input() settings!: any;

  protected accessToKnowledgeBase: any;

  constructor(
    protected readonly billingInfoModel: BillingInfoModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly uiRouter: UIRouter,
    protected readonly ngbModal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.accessToKnowledgeBase = this.planFeatureAccessService.getAccess(PLAN_FEATURE.KNOWLEDGE_BASE, this.currentApp);
  }

  /**
   * Открытие модалки настройки БЗ
   */
  openSetUpModal(): void {
    const setUpModal = this.ngbModal.open(KnowledgeBaseSettingsModalComponent);
    setUpModal.componentInstance.modalWindowParams = {
      billingInfo: this.billingInfo,
      currentApp: this.currentApp,
      settings: this.settings,
    };

    setUpModal.result.then((settings: any) => Object.assign(this.settings, settings));
  }

  /**
   * Трек клика на "Перейти к базе знаний"
   */
  trackClickGoToBase(): void {
    this.carrotquestHelper.track('База знаний - клик на перейти к базе знаний');
  }

  /**
   * Трек клика на настроить
   */
  trackClickSetUp(): void {
    this.carrotquestHelper.track('База знаний - клик на "Настроить"');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { DatetimePickerModule } from '@panel/app/shared/components/datetime-picker/datetime-picker.module';

import { DatepickerPageComponent } from './datepicker-page.component';

@NgModule({
  declarations: [DatepickerPageComponent],
  exports: [DatepickerPageComponent],
  imports: [CommonModule, TranslocoModule, FormsModule, DatetimePickerModule],
})
export class DatepickerPageModule {}

(function () {
  'use strict';

  require('./auto-event-header.controller');

  angular.module('myApp.trackMaster').component('cqAutoEventHeader', cqAutoEventHeader());

  function cqAutoEventHeader() {
    return {
      bindings: {
        id: '<?', // Id автособытия
        onRemoved: '&?', // Функция вызовется после удаления
        group: '<', // Группа настройки
      },
      controller: 'CqAutoEventController',
      controllerAs: 'vm',
      template: require('./auto-event-header.html'),
    };
  }
})();

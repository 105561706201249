import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';

import { Subscription } from '@http/subscription/subscription';
import { CancellationPreventModalComponent } from '@panel/app/pages/subscription/general/signed/plan-info/name/cancellation-prevent-modal/cancellation-prevent-modal.component';
import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { App } from '@http/app/app.model';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

/** Компонент для работы с названием тарифа */
@Component({
  selector: 'cq-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameComponent {
  constructor(
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly ngbModal: NgbModal,
    protected readonly paywallService: PaywallService,
    private readonly stateService: StateService,
    protected readonly subscriptionStore: SubscriptionStore,
    private readonly translocoService: TranslocoService,
  ) {}

  redirectToPricing(): void {
    this.stateService.go('app.content.subscription.pricing');
  }

  /**
   * Показывает компонент оформления подписки
   *
   * @param subscription - Подписка
   */
  isShowSubscribeIntent(subscription: Subscription): boolean {
    return subscription.isTrial;
  }

  /** Обрабатывает отмену подписки */
  onCancelSubscription(): void {
    this.trackCancelSubscription();

    this.openCancellationModal();
  }

  /** Обрабатывает смену тарифа */
  onChangePlan(): void {
    this.trackChangePlan();

    this.redirectToPricing();
  }

  /** Обрабатывает намерение оформить подписку */
  onSubscribeIntent(): void {
    this.redirectToPricing();
  }

  /**
   * Обрабатывает возобновление подписки
   *
   * @param planName - Название плана
   */
  onRenewSubscription(planName: string): void {
    this.trackRenewSubscription();

    this.sendRenewSubscriptionMessageToChat(planName);
  }

  /** Открывает модальное окно для отмены подписки */
  openCancellationModal(): void {
    this.ngbModal.open(CancellationPreventModalComponent);
  }

  /**
   * Открывает модальное окно с тарифами
   *
   * @param app - Приложение
   */
  openPaywallModal(app: App): void {
    this.paywallService.showPaywall(app, null);
  }

  /**
   * Отправляет сообщение о желании возобновить подписку в чат
   *
   * @param planName - Название плана
   */
  sendRenewSubscriptionMessageToChat(planName: string): void {
    const message = this.translocoService.translate('nameComponent.renewMessageChat', { planName });

    this.carrotquestHelper.sendChatMessage(message);
  }

  /** Трекинг смену тарифа */
  trackChangePlan(): void {
    const eventName = 'Оплата - клик по Изменить тариф';

    this.carrotquestHelper.track(eventName);
  }

  /** Трекает отмену подписки */
  trackCancelSubscription(): void {
    const eventName = 'Оплата - клик по Отменить подписку';

    this.carrotquestHelper.track(eventName);
  }

  /** Трекает возобновление подписки */
  trackRenewSubscription(): void {
    const eventName = 'Оплата – клик на Возобновить подписку';

    this.carrotquestHelper.track(eventName);
  }
}

(function () {
  'use strict';

  require('./trigger-chain-working-dynamics.controller');

  angular
    .module('myApp.triggerChains.workingDynamics')
    .component('cqTriggerChainWorkingDynamics', cqTriggerChainWorkingDynamics());

  function cqTriggerChainWorkingDynamics() {
    return {
      bindings: {
        currentMessage: '=', // Сообщение из триггерной цепочки
        sendings: '=', // Данные для компонента HACK Этот объект — помойка из разных настроек, оставил так, как есть
        setSendingsTab: '&?', // Callback на выбор вкладки с отправителями
        statisticsRange: '=', // Данные для датапикера HACK Этот объект — помойка из разных настроек, оставил так, как есть
      },
      controller: 'CqTriggerChainWorkingDynamicsController',
      controllerAs: 'vm',
      template: require('./trigger-chain-working-dynamics.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from '../../../shared/shared.module';
import { UserCustomsPropsComponent } from './user-customs-props.component';

@NgModule({
  declarations: [UserCustomsPropsComponent],
  exports: [UserCustomsPropsComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    SharedModule,
    NgbCollapseModule,
    ClipboardModule,
    NgbTooltipModule,
    NgbDropdownModule,
  ],
  providers: [],
})
export class UserCustomsPropsModule {}

import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from '@http/app/services/app.service';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { EmailNotificationIntegration } from '@http/integration/integrations/email-notification/email-notification-integration';
import { EmailNotificationIntegrationExternal } from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { EmailRecipient } from '@http/integration/integrations/email-notification/types/email-notification-integration.types';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { StateService } from '@panel/app/pages/chat-bot/content/services/state.service';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { EmailNotificationCreateModalWrapperComponent } from '@panel/app/pages/integrations/content/email-notification/create-modal-wrapper/email-notification-create-modal-wrapper.component';
import { EmailNotificationCreateModalParams } from '@panel/app/pages/integrations/content/email-notification/create-modal-wrapper/email-notification-create-modal-wrapper.types';
import { EmailNotificationSelectModalWrapperComponent } from '@panel/app/pages/integrations/content/email-notification/select-modal-wrapper/email-notification-select-modal-wrapper.component';
import { EmailNotificationSelectModalWindowParams } from '@panel/app/pages/integrations/content/email-notification/select-modal-wrapper/email-notification-select-modal-wrapper.types';
import { App } from '@http/app/app.model';
import { Properties } from '@http/property/property.model';

@Component({
  selector: 'cq-branch-action-email-notification[actionForm][currentApp][propertyOptions]',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.scss'],
})
export class BranchActionEmailNotificationComponent implements OnInit {
  @Input() actionForm!: BaseBotActionForm;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  @Input() propertyOptions!: Properties;
  currentValue: BaseBotActionForm['value'];

  /** Список получателей оповещений для аппа */
  emailRecipients!: EmailRecipient[];

  constructor(
    private readonly integrationFactory: IntegrationFactory,
    private readonly modalService: NgbModal,
    public readonly stateService: StateService,
    private readonly appService: AppService,
  ) {}

  ngOnInit(): void {
    this.currentValue = this.actionForm.value.body;
    this.appService.getEmailRecipients(this.currentApp.id).subscribe((emailRecipients: EmailRecipient[]) => {
      this.emailRecipients = emailRecipients;
    });
  }

  /**
   * Открытие модалки создания интеграции
   */
  openCreateNewIntegrationModal(): void {
    const modalRef = this.modalService.open(EmailNotificationCreateModalWrapperComponent, {
      centered: true,
      scrollable: true,
    });
    (modalRef.componentInstance.modalWindowParams as EmailNotificationCreateModalParams) = {
      currentApp: this.currentApp,
      integrationExternal: this.integrationFactory.create(INTEGRATION_TYPES.EMAIL_NOTIFICATION).externalFormat,
      properties: this.propertyOptions,
      emailRecipients: this.emailRecipients,
      teamMembers: this.stateService.teamMembers$.getValue(),
    };

    modalRef.result.then((integration: EmailNotificationIntegration) => {
      this.stateService.emailNotificationIntegrationsExternal$.next([
        ...this.stateService.emailNotificationIntegrationsExternal$.value,
        integration.externalFormat,
      ]);
      this.actionForm.controls.body.setValue(integration.id!);
      this.actionForm.integrationName = integration.name;
    });
  }

  /**
   * Открытие модалки выбора интеграции
   *
   * @param integrationExternal Выбранная интеграция
   */
  openSelectIntegrationModal(integrationExternal: EmailNotificationIntegrationExternal): void {
    const modalRef = this.modalService.open(EmailNotificationSelectModalWrapperComponent, {
      centered: true,
      scrollable: true,
    });
    (modalRef.componentInstance.modalWindowParams as EmailNotificationSelectModalWindowParams) = {
      currentApp: this.currentApp,
      integrationExternal: integrationExternal,
      properties: this.propertyOptions,
    };

    modalRef.result.then(
      () => {
        this.currentValue = integrationExternal.id;
        this.actionForm.integrationName = integrationExternal.name;
        this.actionForm.controls.body.setValue(integrationExternal.id!);
      },
      () => {
        this.actionForm.controls.body.setValue(this.currentValue);
      },
    );
  }
}

<div class="modal-header">
  <h3 class="modal-title">{{ 'modals.knowledgeBaseSettings.heading' | transloco }}</h3>
  <button
    class="close"
    (click)="activeModal.close()"
  >
    <i class="cqi-sm cqi-03x cqi-times"></i>
  </button>
</div>
<form
  autocomplete="off"
  novalidate
  (submit)="save(knowledgeBaseForm.valid)"
>
  <ul
    #nav="ngbNav"
    ngbNav
    [(activeId)]="activeTab"
    class="nav nav-tabs-light"
  >
    <li
      [ngbNavItem]="TABS.STANDARD"
      class="margin-left-20"
    >
      <a ngbNavLink>{{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.settingsTabs.standard' | transloco }}</a>
      <ng-template ngbNavContent>
        <div class="modal-body">
          <!--ДОМЕН РАЗМЕЩЕНИЯ-->
          <div class="form-group row margin-bottom-30">
            <div class="col-4">
              <label class="margin-bottom-5 no-padding-top">{{
                'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.label' | transloco
              }}</label>
              <div>{{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.description' | transloco }}</div>
            </div>
            <div class="col-8">
              <cq-switcher
                [formControl]="knowledgeBaseForm.controls.isPersonalDomain"
                class="margin-bottom-15"
                [leftValue]="false"
                [rightValue]="true"
              >
                <ng-template #leftText>
                  <span>
                    {{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.domainTypeSwitch.carrot'
                        | transloco: { projectName: PROJECT_NAME }
                    }}
                  </span>
                </ng-template>
                <ng-template #rightText>
                  <span>
                    {{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.domainTypeSwitch.personal' | transloco }}
                  </span>
                </ng-template>
              </cq-switcher>
              <!--Персональный домен-->
              <div
                *ngIf="knowledgeBaseForm.controls.isPersonalDomain.getRawValue()"
                [cqShowError]="knowledgeBaseForm.controls.personalDomain"
              >
                <input
                  [formControl]="knowledgeBaseForm.controls.personalDomain"
                  class="form-control"
                  [attr.disabled]="true"
                  required
                  [placeholder]="
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.personalDomainInput.placeholder'
                      | transloco
                  "
                />
                <cq-validation-messages [control]="knowledgeBaseForm.controls.personalDomain">
                  <cq-validation-message errorName="required">
                    {{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.personalDomainInput.errors.required'
                        | transloco
                    }}
                  </cq-validation-message>
                  <cq-validation-message errorName="maxlength">
                    {{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.personalDomainInput.errors.maxLength'
                        | transloco: { maxLength: MAX_DOMAIN_LENGTH }
                    }}
                  </cq-validation-message>
                  <cq-validation-message errorName="apiMessageDomainAlreadyRegisteredError">
                    {{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.personalDomainInput.errors.apiMessageDomainAlreadyRegisteredError'
                        | transloco
                    }}
                  </cq-validation-message>
                </cq-validation-messages>
                <div>
                  <small>{{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.personalDomainInput.description'
                      | transloco
                  }}</small>
                </div>
                <a
                  class="margin-top-5 inline-block cursor-pointer"
                  (click)="sendChatMessage()"
                  >{{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.changeLogo.input.changeDomainButton' | transloco
                  }}</a
                >
              </div>
              <!--Домен Carrot quest-->
              <div
                *ngIf="!knowledgeBaseForm.controls.isPersonalDomain.getRawValue()"
                [cqShowError]="knowledgeBaseForm.controls.carrotDomain"
              >
                <div class="input-group">
                  <input
                    class="form-control"
                    [formControl]="knowledgeBaseForm.controls.carrotDomain"
                    required
                    [placeholder]="
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.carrotDomainInput.placeholder'
                        | transloco
                    "
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">{{ '.' + modalWindowParams.settings.defaultDomain }}</div>
                  </div>
                </div>
                <cq-validation-messages [control]="knowledgeBaseForm.controls.carrotDomain">
                  <cq-validation-message errorName="required">
                    {{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.carrotDomainInput.errors.required'
                        | transloco
                    }}
                  </cq-validation-message>
                  <cq-validation-message errorName="maxlength">
                    {{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.carrotDomainInput.errors.maxLength'
                        | transloco: { maxLength: MAX_DOMAIN_LENGTH }
                    }}
                  </cq-validation-message>
                  <cq-validation-message errorName="domainPart">
                    {{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.domainInputs.carrotDomainInput.errors.domainPart'
                        | transloco
                    }}
                  </cq-validation-message>
                </cq-validation-messages>
              </div>
            </div>
          </div>
          <!--ЛОГОТИП-->
          <div class="form-group row margin-bottom-30">
            <div class="col-4">
              <label class="margin-bottom-5 no-padding-top">{{
                'modals.knowledgeBaseSettings.knowledgeBaseForm.changeLogo.input.label' | transloco
              }}</label>
              <div>
                {{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.changeLogo.input.description' | transloco }}
              </div>
            </div>
            <div class="col-8">
              <div
                *ngIf="newLogo.preview || modalWindowParams.settings.logo"
                class="margin-bottom-15"
              >
                <img
                  class="logo"
                  [src]="
                    newLogo.preview ||
                    USER_FILES_URL +
                      '/knowledge-bases-images/logos/' +
                      modalWindowParams.currentApp.id +
                      '/' +
                      modalWindowParams.settings.logo
                  "
                />
              </div>
              <div class="flex align-items-center">
                <div class="position-relative margin-right-10">
                  <label class="btn btn-sm btn-outline-primary">
                    <span>{{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.changeLogo.input.changeLogoButton' | transloco
                    }}</span>
                    <input
                      [formControl]="knowledgeBaseForm.controls.logo"
                      class="hidden"
                      [attr.accept]="fileHelper.getAcceptableImagesExtensions()"
                      type="file"
                      cqFile
                      [(cqFileAsDataUrl)]="newLogo.preview"
                      [cqMaxSize]="LOGO_MAX_SIZE"
                      (change)="trackChangeLogo()"
                    />
                  </label>
                  <cq-validation-messages [control]="knowledgeBaseForm.controls.logo">
                    <cq-validation-message errorName="accept">
                      {{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.changeLogo.input.errors.accept' | transloco }}
                    </cq-validation-message>
                    <cq-validation-message errorName="maxSize">
                      {{
                        'modals.knowledgeBaseSettings.knowledgeBaseForm.changeLogo.input.errors.maxSize'
                          | transloco: { maxFileSize: LOGO_MAX_SIZE / 1000000 }
                      }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
                <div class="small">
                  {{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.changeLogo.input.help'
                      | transloco: { maxFileSize: '1' }
                  }}
                </div>
              </div>
            </div>
          </div>
          <!--Robots.txt-->
          <div class="form-group row no-margin-bottom">
            <div class="col-4">
              <label class="margin-bottom-5 no-padding-top">{{
                'modals.knowledgeBaseSettings.knowledgeBaseForm.robots.label' | transloco
              }}</label>
              <div>{{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.robots.description' | transloco }}</div>
            </div>
            <div class="col-8">
              <a
                class="inline-block margin-bottom-5"
                target="_blank"
                [href]="'https://' + modalWindowParams.settings.fullDomain + '/robots.txt'"
              >
                <span>{{
                  'modals.knowledgeBaseSettings.knowledgeBaseForm.robots.seeTheFileLink.text' | transloco
                }}</span>
                <i class="cqi-sm cqi-external-link"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer bordered-top">
          <button
            class="btn btn-primary"
            type="submit"
            [attr.disabled]="isApiRequestPerformed ? true : null"
          >
            {{ 'modals.knowledgeBaseSettings.saveButton' | transloco }}
          </button>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="TABS.ADVANCED">
      <a ngbNavLink>
        {{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.settingsTabs.advanced' | transloco }}
      </a>
      <ng-template ngbNavContent>
        <div
          class="modal-body"
          [ngClass]="{ 'text-muted': !accessToKnowledgeBasePro.hasAccess }"
        >
          <!--СЧЁТЧИКИ АНАЛИТИКИ-->
          <div
            *cqHideInUs
            class="form-group row margin-bottom-30"
          >
            <div class="col-4">
              <label class="margin-bottom-5 no-padding-top">{{
                'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.label' | transloco
              }}</label>
              <div>
                {{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.description' | transloco }}
              </div>
            </div>
            <div class="col-8">
              <!--Идентификатор отслеживания Google Analytics-->
              <div class="margin-bottom-10">
                <div class="margin-bottom-5">
                  {{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.googleAnalyticsInput.description'
                      | transloco
                  }}
                </div>
                <div [cqShowError]="knowledgeBaseForm.controls.googleAnalytics">
                  <input
                    [formControl]="knowledgeBaseForm.controls.googleAnalytics"
                    class="form-control"
                    [attr.disabled]="accessToKnowledgeBasePro.hasAccess ? null : true"
                    placeholder="{{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.googleAnalyticsInput.placeholder'
                        | transloco
                    }}"
                  />
                  <cq-validation-messages [control]="knowledgeBaseForm.controls.googleAnalytics">
                    <cq-validation-message errorName="maxlength">
                      {{
                        'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.googleAnalyticsInput.errors.maxLength'
                          | transloco: { maxLength: MAX_GOOGLE_TAG_LENGTH }
                      }}
                    </cq-validation-message>
                    <cq-validation-message errorName="googleId">
                      {{
                        'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.googleAnalyticsInput.errors.googleId'
                          | transloco
                      }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
                <div
                  *ngIf="isKeyGoogleAnalyticsOld()"
                  class="mt-5"
                >
                  <i class="cqi-sm cqi-triangle-exclamation-o text-danger mr-5"></i>
                  <span class="text-danger">{{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.googleAnalyticsInput.note'
                      | transloco
                  }}</span>
                </div>
              </div>
              <!--Код счётчика Яндекс.Метрики-->
              <div>
                <div class="margin-bottom-5">
                  {{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.yandexMetrikaInput.description'
                      | transloco
                  }}
                </div>
                <div [cqShowError]="knowledgeBaseForm.controls.yandexMetrika">
                  <input
                    [formControl]="knowledgeBaseForm.controls.yandexMetrika"
                    class="form-control"
                    [attr.disabled]="accessToKnowledgeBasePro.hasAccess ? null : true"
                    placeholder="{{
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.yandexMetrikaInput.placeholder'
                        | transloco
                    }}"
                  />
                  <cq-validation-messages [control]="knowledgeBaseForm.controls.yandexMetrika">
                    <cq-validation-message errorName="integer">
                      {{
                        'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.yandexMetrikaInput.errors.integer'
                          | transloco
                      }}
                    </cq-validation-message>
                    <cq-validation-message errorName="maxlength">
                      {{
                        'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.yandexMetrikaInput.errors.max'
                          | transloco: { maxLength: MAX_YANDEX_METRIKA_LENGTH }
                      }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
              </div>
            </div>
          </div>

          <!--GOOGLE ANALYTICS-->
          <div
            *cqHideInRu
            class="form-group row margin-bottom-30"
          >
            <div class="col-4">
              <label class="margin-bottom-5 no-padding-top">{{
                'modals.knowledgeBaseSettings.knowledgeBaseForm.googleAnalytics.label' | transloco
              }}</label>
              <div>
                {{ 'modals.knowledgeBaseSettings.knowledgeBaseForm.googleAnalytics.description' | transloco }}
              </div>
            </div>
            <div class="col-8">
              <div class="margin-bottom-10">
                <div class="margin-bottom-5">
                  {{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.googleAnalytics.googleAnalyticsInput.description'
                      | transloco
                  }}
                </div>
                <div [cqShowError]="knowledgeBaseForm.controls.googleAnalytics">
                  <input
                    [formControl]="knowledgeBaseForm.controls.googleAnalytics"
                    class="form-control"
                    [attr.disabled]="accessToKnowledgeBasePro.hasAccess ? null : true"
                    [placeholder]="
                      'modals.knowledgeBaseSettings.knowledgeBaseForm.googleAnalytics.googleAnalyticsInput.placeholder'
                        | transloco
                    "
                  />

                  <cq-validation-messages [control]="knowledgeBaseForm.controls.googleAnalytics">
                    <cq-validation-message errorName="maxlength">
                      {{
                        'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.googleAnalyticsInput.errors.maxLength'
                          | transloco: { maxLength: MAX_GOOGLE_TAG_LENGTH }
                      }}
                    </cq-validation-message>
                    <cq-validation-message errorName="googleId">
                      {{
                        'modals.knowledgeBaseSettings.knowledgeBaseForm.analyticsCounter.googleAnalyticsInput.errors.googleId'
                          | transloco
                      }}
                    </cq-validation-message>
                  </cq-validation-messages>
                </div>
                <div
                  *ngIf="isKeyGoogleAnalyticsOld()"
                  class="mt-5"
                >
                  <i class="cqi-sm cqi-triangle-exclamation-o text-danger mr-5"></i>
                  <span class="text-danger">{{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.googleAnalytics.googleAnalyticsInput.note'
                      | transloco
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <!--ОТСУТСТВИЕ БРЕНДИНГА-->
          <div class="form-group row margin-bottom-30">
            <div class="col-4">
              <label class="margin-bottom-5">{{
                'modals.knowledgeBaseSettings.knowledgeBaseForm.whiteLabel.label' | transloco
              }}</label>
              <div>
                {{
                  'modals.knowledgeBaseSettings.knowledgeBaseForm.whiteLabel.description'
                    | transloco: { projectName: PROJECT_NAME }
                }}
              </div>
            </div>
            <div class="col-8">
              <div class="custom-control custom-checkbox">
                <input
                  [formControl]="knowledgeBaseForm.controls.noPoweredBy"
                  class="custom-control-input"
                  id="no-powered-by"
                  type="checkbox"
                  [attr.disabled]="accessToKnowledgeBasePro.hasAccess ? null : true"
                />
                <label
                  class="custom-control-label"
                  for="no-powered-by"
                  >{{
                    'modals.knowledgeBaseSettings.knowledgeBaseForm.whiteLabel.whiteLabelCheckbox.label' | transloco
                  }}</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer bordered-top">
          <!--Кнопка сохранения-->
          <button
            *ngIf="accessToKnowledgeBasePro.hasAccess"
            class="btn btn-primary"
            type="submit"
            [attr.disabled]="isApiRequestPerformed ? true : null"
          >
            {{ 'modals.knowledgeBaseSettings.saveButton' | transloco }}
          </button>
          <!--Продвинутые настройки-->
          <div
            *ngIf="!accessToKnowledgeBasePro.hasAccess"
            class="w-100 text-center"
          >
            {{ 'modals.knowledgeBaseSettings.proAddOn.withCapabilities.description' | transloco }}
            <a
              href
              (click)="openBillingModal()"
              >{{ 'modals.knowledgeBaseSettings.proAddOn.withoutCapabilities.linkText' | transloco }}</a
            >
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</form>

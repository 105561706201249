import './blocking/blocking.module';
import './block-panel-helper/block-panel-helper.module';
import './carrotquest-helper/carrotquest-helper.module';
import './electron-api/electron-api.module';
import './file-helper/file-helper.module';
import './l10n-helper/l10n-helper.module';
import './mute-notifications/mute-notifications.module';
import './one-time-flag/one-time-flag.module';
import '@panel/app-old/shared/services/message-preview-image/message-preview-image.module';
import './product-feature-text/product-feature-text.module';
import './service-script/service-script.module';
import './team-member-help/team-member-help.module';
import './telegram-bot-editor-store/telegram-bot-store.module';
import './time-unit/time-unit.module';
import './uib-modal-service/open-uib-modal.service.module';
import './user-card-modal/user-card-modal.module';
import './service-installation-toast/service-installation-toast.module';
import './utils/utils.module';
import './system-error/system-error.module';

import { module } from 'angular';

require('./a-b-test/a-b-test.module');
require('./accounting-helper/accounting-helper.module');
require('./api-request/api-request.module');
require('./browser-detector/browser-detector.module');
require('./canvas-helper/canvas-helper.module');
require('./case-style-helper/case-style-helper.module');
require('./chart-helper/chart-helper.module');
require('./date-range-picker-helper/date-range-picker-helper.module');
require('./desktop-notification/desktop-notification.module');
require('./favicon-notification/favicon-notification.module');
require('./limit-of-unique-visitors-modal/limit-of-unique-visitors-modal.module');
require('./long-poll-connection/long-poll-connection.module');
require('./my-http-param-serializer/my-http-param-serializer.module');
require('./page-title-helper/page-title-helper.module');
require('./party-is-over-modal/party-is-over-modal.module');
require('./short-uuid-helper/short-uuid-helper.module');
require('./validation-helper/validation-helper.module');
require('./wizard-helper/wizard-helper.module');
require('./write-to-integration-modal/write-to-integration-modal.module');

module('myApp.services', [
  'myApp.services.abTest',
  'myApp.services.accounting',
  'myApp.services.apiRequest',
  'myApp.services.blockPanelHelper',
  'myApp.services.blockingService',
  'myApp.services.browserDetector',
  'myApp.services.canvasHelper',
  'myApp.services.carrotquestHelper',
  'myApp.services.caseStyleHelper',
  'myApp.services.chartHelper',
  'myApp.services.dateRangePickerHelper',
  'myApp.services.desktopNotification',
  'myApp.services.electronApi',
  'myApp.services.faviconNotification',
  'myApp.services.fileHelper',
  'myApp.services.l10nHelper',
  'myApp.services.limitOfUniqueVisitorsModalService',
  'myApp.services.longPollConnection',
  'myApp.services.messagePreviewImageService',
  'myApp.services.muteNotifications',
  'myApp.services.myHttpParamSerializer',
  'myApp.services.oneTimeFlagHelper',
  'myApp.services.pageTitleHelper',
  'myApp.services.partyIsOverModalService',
  'myApp.services.productFeatureTextService',
  'myApp.services.serviceInstallationToast',
  'myApp.services.serviceScript',
  'myApp.services.shortUuid',
  'myApp.services.systemError',
  'myApp.services.teamMemberHelp',
  'myApp.services.telegramBotStore',
  'myApp.services.timeUnit',
  'myApp.services.uibModalService',
  'myApp.services.userCardModalService',
  'myApp.services.utils',
  'myApp.services.validationHelper',
  'myApp.services.wizardHelper',
  'myApp.services.writeToIntegrationModalService',
]);

(function () {
  'use strict';

  require('./limit-of-unique-visitors.controller');

  angular
    .module('myApp.modals.limitOfUniqueVisitors')
    .component('cqLimitOfUniqueVisitorsModal', cqLimitOfUniqueVisitorsModal());

  function cqLimitOfUniqueVisitorsModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=', // В resolve должны передаваться следующие параметры:
        // {Object} appBlocks - Текущие блокировки
        // {Object} billingInfo - Текущий биллинг
        // {Object} currentApp - Текущее приложение
        // {Object} djangoUser - Текущий пользователь
      },
      controller: 'CqLimitOfUniqueVisitorsController',
      controllerAs: 'vm',
      template: require('./limit-of-unique-visitors.html'),
    };
  }
})();

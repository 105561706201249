/**
 * Валидатор для проверки количества килобайт, которое занимает HTML (используется в валидации писем)
 */
import { HTML_MAX_SIZE } from '../../../../app/http/message-part/message-part.constants';

(function () {
  'use strict';

  angular.module('myApp.directives.htmlMaxSizeValidation').directive('cqHtmlMaxSize', cqHtmlMaxSize);

  function cqHtmlMaxSize(messagePartModel) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      controller.$validators.htmlMaxSize = htmlMaxSize;

      /**
       * Валидация значения input
       *
       * @param modelValue Значение, которое записано в модель
       * @param viewValue Значение, которое отображается в input
       * @returns {Boolean}
       */
      function htmlMaxSize(modelValue, viewValue) {
        if (controller.$isEmpty(modelValue)) {
          return true;
        } else {
          return messagePartModel.getEmailSize(modelValue) <= HTML_MAX_SIZE;
        }
      }
    }
  }
})();

<ng-container *transloco="let t; read: 'eventPropEditorComponent'">
  <div class="d-flex align-items-center justify-content-between mb-10">
    <div
      class="form-group flex-grow-1 mr-10 mb-0"
      [cqShowError]="keyForm"
    >
      <input
        type="text"
        class="form-control"
        [formControl]="keyForm"
        [placeholder]="t('propKeyInput.placeholder')"
      />
      <cq-validation-messages [control]="keyForm">
        <cq-validation-message errorName="required"> {{ t('propKeyInput.errors.required') }} </cq-validation-message>
        <cq-validation-message errorName="maxlength">
          {{ t('propKeyInput.errors.maxlength', { maxLength: PARAM_MAX_LENGTH }) }}
        </cq-validation-message>
      </cq-validation-messages>
    </div>
    <button
      class="btn btn-outline-secondary"
      (click)="delete.emit()"
      type="button"
    >
      <i class="btn-icon cqi-sm cqi-times"></i>
    </button>
  </div>
  <div
    class="form-group mb-0"
    [cqShowError]="valueForm"
  >
    <input
      type="text"
      class="form-control"
      [formControl]="valueForm"
      [placeholder]="t('propValueInput.placeholder')"
    />
    <cq-validation-messages [control]="valueForm">
      <cq-validation-message errorName="required"> {{ t('propValueInput.errors.required') }} </cq-validation-message>
      <cq-validation-message errorName="maxlength">
        {{ t('propValueInput.errors.maxlength', { maxLength: PARAM_MAX_LENGTH }) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';

import { CarrotNotificationFrameComponent } from './carrot-notification-frame.component';

@NgModule({
  declarations: [CarrotNotificationFrameComponent],
  exports: [CarrotNotificationFrameComponent],
  imports: [CommonModule, TranslocoDirective],
})
export class CarrotNotificationFrameModule {}

import { Container } from 'pixi.js';

import { TRIGGER_CHAIN_BLOCK_TYPE } from '@panel/app/services/canvas/tirgger-chain/blocks/block-view.constants';
import { AbsInteractiveBlockPartView } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part.view';
import { INTERACTIVE_BLOCK_PART } from '@panel/app/services/canvas/tirgger-chain/interactive-block-parts/interactive-block-part-view.constants';

export class SendingConditionsTriggersView extends AbsInteractiveBlockPartView<TRIGGER_CHAIN_BLOCK_TYPE.SENDING_CONDITION> {
  type: INTERACTIVE_BLOCK_PART = INTERACTIVE_BLOCK_PART.SENDING_CONDITION_TRIGGERS;

  hasConnection(): boolean {
    return this.stepView.data.nextStepConnected;
  }

  renderContent(): Container {
    return this.connectionPoint;
  }
}

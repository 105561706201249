(function () {
  'use strict';

  angular
    .module('myApp.chatSettings')
    .controller('CqConversationFinalCloseTimeoutController', CqConversationFinalCloseTimeoutController);

  function CqConversationFinalCloseTimeoutController(validationHelper) {
    let vm = this;

    /**
     * Минимальное значение таймаута решения вопроса, в минутах
     *
     * @type {number}
     */
    const TIMEOUT_MIN_VALUE = 1;

    vm.$onInit = init;

    function init() {
      vm.TIMEOUT_MIN_VALUE = TIMEOUT_MIN_VALUE;
      vm.validationHelper = validationHelper;
    }
  }
})();

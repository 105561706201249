/** Компонент модального окна отписки устройств пользователя от SDK */
(function () {
  'use strict';

  angular.module('myApp.modals.unsubscribeSdkPush').component('cqUnsubscribeSdkPushModal', cqUnsubscribeSdkPushModal());

  require('./unsubscribe-sdk-push.controller');

  function cqUnsubscribeSdkPushModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // {Object} subscriptions - Список подписок SDK
        // {Object} userId - ID пользователя
      },
      controller: 'CqUnsubscribeSdkPushModalController',
      controllerAs: 'vm',
      template: require('./unsubscribe-sdk-push.html'),
    };
  }
})();

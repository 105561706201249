import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { OAuthButtonComponent } from '@panel/app/pages/integrations/partials/o-auth-button/o-auth-button.component';

@NgModule({
  declarations: [OAuthButtonComponent],
  exports: [OAuthButtonComponent],
  imports: [CommonModule, TranslocoModule],
})
export class OAuthButtonModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Message } from '@http/message/message.types';

@Component({
  selector: 'cq-email-notification-message-triggers-readonly[messages]',
  templateUrl: './message-triggers-readonly.component.html',
  styleUrls: ['./message-triggers-readonly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailNotificationMessageTriggersReadonlyComponent {
  @Input() messages: readonly Partial<Message>[] = [];
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, NgZone, Optional, Output, Self } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { GenericFormBuilder } from '@panel/app/services/generic-form-builder.service';
import { AbstractCVAControl } from '@panel/app/shared/abstractions/cva/abstract-cva-control';
import { App } from '@http/app/app.model';
import { UserProperty } from '@http/property/property.model';

export type WritePropertyValueForm = {
  propertyName: string | null;
  value: string | null;
};

@Component({
  selector: 'cq-write-property-value[currentApp][properties][inputPlaceholder][inputError]',
  templateUrl: './write-property-value.component.html',
  styleUrls: ['./write-property-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WritePropertyValueComponent extends AbstractCVAControl<WritePropertyValueForm> {
  /** Текущее приложение */
  @Input()
  currentApp!: App;

  @Input()
  properties: UserProperty[] = [];

  @Input()
  inputPlaceholder: string = '';

  @Input()
  inputError: string = '';

  @Output()
  propertiesChange = new Subject<UserProperty[]>();

  readonly control = this.fb.group<WritePropertyValueForm>({
    propertyName: this.fb.control<string>(null),
    value: this.fb.control<string>(null, Validators.required),
  });

  constructor(
    private readonly fb: GenericFormBuilder,
    @Self()
    @Optional()
    ngControl: NgControl | null,
    ngZone: NgZone,
  ) {
    super(ngControl, ngZone);
  }

  @HostBinding('class')
  get class(): string {
    return 'flex';
  }

  get propertyName() {
    return this.control.controls.propertyName;
  }

  get value() {
    return this.control.controls.value;
  }
}

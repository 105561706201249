import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ConversationTag } from '@panel/app-old/shared/entities/conversation-tag/conversation-tag';

/** Компонент для работы со списком тегов */
@Component({
  selector: 'cq-tag-list[list][intentToRemoveTag]',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListComponent {
  /** Список */
  @Input()
  list!: ConversationTag[];

  /** Callback на намеренье удалить тег */
  @Output()
  intentToRemoveTag: EventEmitter<ConversationTag> = new EventEmitter<ConversationTag>();

  /**
   * Обрабатывает намеренье удалить тег
   *
   * @param tag - Тег
   */
  onIntentToRemoveTag(tag: ConversationTag): void {
    this.intentToRemoveTag.emit(tag);
  }
}

// Ключ для localstorage, по которому содержится ID аппа
// ID аппа хранится только для того, чтобы делать перенаправление на последний открытый апп при переходе на carrotquest.io/panel/toApp
export const LS_CURRENT_APP_ID = 'currentAppId';

// Ключ, в котором содержится набор флагов, по которым определяется выполнение единоразового действия.
// Например, показать модалку только один раз
export const LS_ONE_TIME_FLAGS = 'carrotquest_one_time_flags';

// Ключ для состояния свернутых блоков
export const LS_UNCOLLAPSED_USER_CARD_BLOCKS = 'uncollapsed_blocks';

// Ключ для массива с закрепленными свойствами
export const LS_PINNED_PROPS = 'pinned_props';

// Ключ для массива с замьюченными каналами
export const LS_MUTED_CHANNELS = 'muted_channels';

/** Ключ показывающий, что произошел даунгрейд у автосообщений */
export const LS_AUTO_MESSAGES_DOWNGRADE = 'autoMessagesDowngrade';

/** Ключ показывающий, что произошел даунгрейд у лидботов */
export const LS_LEAD_BOTS_DOWNGRADE = 'leadBotsDowngrade';

export const LS_MOVE_STARTER_GUIDE_MODAL = 'move_starter_guide_modal';

import { firstValueFrom } from 'rxjs';
import { TEAM_MEMBER_PERMISSIONS } from '../../../app/http/team-member/team-member.constants';

(function () {
  'use strict';

  angular.module('myApp.conversationsStatistics').config(config);

  function config($stateProvider) {
    $stateProvider.state('app.content.conversations.statistics', {
      url: '/statistics',
      bindings: {
        billingInfo: 'billingInfo',
        channels: 'channels',
        currentApp: 'currentApp',
        djangoUser: 'djangoUser',
        teamMembers: 'teamMembers',
      },
      component: 'cqConversationsStatistics',
      resolve: {
        channels: getChannels,
        teamMembers: getTeamMembersWithPseudoTeamMembers,
      },
      data: {
        roles: [TEAM_MEMBER_PERMISSIONS.SUPER_ADMIN, TEAM_MEMBER_PERMISSIONS.ADMIN],
      },
    });

    /**
     * Получение списка каналов
     *
     * @param channelModel
     * @param currentApp
     * @return {Promise}
     */
    function getChannels(channelModel, currentApp) {
      return firstValueFrom(channelModel.getList(currentApp.id, true, true));
    }

    /**
     * Получение списка членов команды с псевдо членоми команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {Promise}
     */
    function getTeamMembersWithPseudoTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, true, false, true, true));
    }
  }
})();

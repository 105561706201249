import { Pipe, PipeTransform } from '@angular/core';

import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';

@Pipe({
  name: 'filterNextBranchOptions',
  pure: true,
})
export class FilterNextBranchOptionsPipe implements PipeTransform {
  transform(nextBranchOptions: Branch[], canSelectConditionAsNextBranch: boolean): Branch[] {
    return canSelectConditionAsNextBranch
      ? nextBranchOptions
      : nextBranchOptions.filter((branch) => branch.branchType === 'default');
  }
}

(function () {
  'use strict';

  require('./plan-capability-auto-message-total-paywall-modal.controller');

  angular
    .module('myApp.planCapability')
    .component('cqPlanCapabilityAutoMessageTotalPaywallModal', cqPlanCapabilityAutoMessageTotalPaywallModal());

  function cqPlanCapabilityAutoMessageTotalPaywallModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        //activeTriggerMessagesCount - Количество активных автосообщений
        //currentApp - Текущее приложение
        //denialReason - Информация о причинах отказа в доступе к фиче
        //messageCount - Количество автосообщений которые собираются активировать
        //showReturnToEditButton - Показать кнопку "Вернуться к редактированию"
      },
      controller: 'CqPlanCapabilityAutoMessageTotalPaywallModalController',
      controllerAs: 'vm',
      template: require('./plan-capability-auto-message-total-paywall-modal.html'),
    };
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessagePreviewPopupChatModule } from '@panel/app/partials/message-preview/previews/popup-chat/message-preview-popup-chat.module';
import { MessagePreviewSdkPopupChatModule } from '@panel/app/partials/message-preview/previews/sdk-popup-chat/message-preview-sdk-popup-chat.module';
import { MessagePreviewBrowserWrapperModule } from '@panel/app/partials/message-preview/wrappers/browser/message-preview-browser-wrapper.module';
import { MessagePreviewPhoneBottomWrapperModule } from '@panel/app/partials/message-preview/wrappers/phone-bottom/message-preview-phone-bottom-wrapper.module';

import { MessagePreviewComponent } from './message-preview.component';

@NgModule({
  declarations: [MessagePreviewComponent],
  exports: [MessagePreviewComponent],
  imports: [
    CommonModule,
    MessagePreviewBrowserWrapperModule,
    MessagePreviewPhoneBottomWrapperModule,
    MessagePreviewPopupChatModule,
    MessagePreviewSdkPopupChatModule,
  ],
})
export class MessagePreviewModule {}

import { AUTO_REPLY_TYPE } from '../../../../app/http/app/app.constants';

(function () {
  'use strict';

  angular.module('myApp.chatSettings').controller('CqChatAutoReplyController', CqChatAutoReplyController);

  function CqChatAutoReplyController(PROJECT_NAME, carrotquestHelper, validationHelper) {
    let vm = this;

    /**
     * Максимальное значение таймаута отправки автоответа, в секундах
     *
     * @type {number}
     */
    const TIMEOUT_MAX_VALUE = 120;

    /**
     * Минимальное значение таймаута отправки автоответа, в секундах
     *
     * @type {number}
     */
    const TIMEOUT_MIN_VALUE = 1;

    vm.$onInit = init;

    function init() {
      vm.AUTO_REPLY_TYPES = AUTO_REPLY_TYPE;
      vm.PROJECT_NAME = PROJECT_NAME;
      vm.TIMEOUT_MAX_VALUE = TIMEOUT_MAX_VALUE;
      vm.TIMEOUT_MIN_VALUE = TIMEOUT_MIN_VALUE;
      vm.trackClickNotWorkEnabled = trackClickNotWorkEnabled;
      vm.trackClickWorkEnabled = trackClickWorkEnabled;
      vm.trackSelectAutoReplyType = trackSelectAutoReplyType;
      vm.validationHelper = validationHelper;
    }

    /**
     * Трек клика на чекбокс "Включить" для автоответов в нерабочее время
     */
    function trackClickNotWorkEnabled() {
      carrotquestHelper.track('Автоответ в чате - клик включить/выключить в нерабочее время', {
        app_id: vm.currentApp.id,
      });
    }

    /**
     * Трек клика на чекбокс "Включить" для автоответов в рабочее время
     */
    function trackClickWorkEnabled() {
      carrotquestHelper.track('Автоответ в чате - клик включить/выключить в рабочее время', {
        app_id: vm.currentApp.id,
      });
    }

    /**
     * Трек выбора сбора email'а в автоответах
     *
     * @param {String} autoReplyType выбранный тип автоответа
     */
    function trackSelectAutoReplyType(autoReplyType) {
      if (autoReplyType === AUTO_REPLY_TYPE.EMAIL) {
        carrotquestHelper.track('Автоответ в чате - выбран email', { app_id: vm.currentApp.id });
      } else if (autoReplyType === AUTO_REPLY_TYPE.PHONE) {
        carrotquestHelper.track('Автоответ в чате - выбран телефон', { app_id: vm.currentApp.id });
      } else if (autoReplyType === AUTO_REPLY_TYPE.NO) {
        carrotquestHelper.track('Автоответ в чате - выбран без поля', { app_id: vm.currentApp.id });
      }
    }
  }
})();

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { firstValueFrom } from 'rxjs';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { DjangoUser } from '@http/django-user/django-user.types';
import { PlanVersionService } from '@panel/app/services/billing/plan-version/plan-version.service';
import { BILLING_ADD_ONS } from '@panel/app/services/billing-info/billing-info.constants';
import { BillingInfo, BillingInfoModel } from '@panel/app/services/billing-info/billing-info.model';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';
import { FirstPromoterService } from '@panel/app/services/first-promoter/first-promoter.service';
import { PLAN_CAPABILITIES_PRICING_PLACE } from '@panel/app/services/plan-capability-helper/plan-capability-helper.constants';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

/**
 * Прайсинг старый, подразумевается, что его редачить не будут, так как он существует только для совместимости
 * Но переносить все равно надо, так что все оставляем как есть
 */
@Component({
  selector: 'cq-pricing-old',
  templateUrl: './pricing-old.component.html',
  styleUrls: ['./pricing-old.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingOldComponent implements OnInit {
  @Input()
  billingInfo!: BillingInfo;

  @Input()
  currentApp!: App;

  @Input()
  djangoUser!: DjangoUser;

  @Input()
  callPlace: PLAN_CAPABILITIES_PRICING_PLACE = PLAN_CAPABILITIES_PRICING_PLACE.BILLING;

  protected limitUniqueUsers!: number;

  protected addOns: any[] = [];

  protected slider: any = {
    value: 1000,
    options: {
      floor: 1000,
      ceil: 10000,
      hidePointerLabels: true,
      hideLimitLabels: true,
      showSelectionBar: true,
    },
  }; // Параметры слайдера с выбором посетителей

  protected appQuotas: any = {};

  protected billingPlans!: any;

  protected billingPlansSettings!: any;

  protected chosenDiscount!: any;

  protected currentPlanDesriptionTranslationKey!: string;

  protected currentPlanName = ''; // Информация о названии текущего тарифного плана

  protected discountsByPeriod!: Record<string, number>;

  protected isCollapsedFullComparison = true;

  protected noBrandingAddOnId!: any;

  protected numberMonthsInDiscountsByPeriod!: any;

  protected PROJECT_NAME = environment.projectName;

  protected readonly NEW_PLANS_NAMES = {
    PLAN_0: this.transloco.translate('pricingOldComponent.heroBlock.plan0.name'),
    PLAN_1: this.transloco.translate('pricingOldComponent.heroBlock.plan1.name'),
    PLAN_2: this.transloco.translate('pricingOldComponent.heroBlock.plan2.name'),
    PLAN_3: this.transloco.translate('pricingOldComponent.heroBlock.plan3.name'),
  };

  constructor(
    protected readonly l10nHelperService: L10nHelperService,
    protected readonly billingPlanService: BillingPlanService,
    protected readonly l10nHelper: L10nHelperService,
    protected readonly transloco: TranslocoService,
    protected readonly planVersionService: PlanVersionService,
    protected readonly billingInfoModel: BillingInfoModel,
    protected readonly modalService: UibModalService,
    protected readonly firstPromoterService: FirstPromoterService,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.billingPlans = this.billingPlanService.getCurrentPlans(this.currentApp, this.billingInfo);
    this.billingPlansSettings = this.billingPlanService.getCurrentPlansSettings(this.currentApp, this.billingInfo);
    this.chosenDiscount = this.billingPlanService.getCurrentChosenDiscount(this.currentApp, this.billingInfo);
    this.currentPlanDesriptionTranslationKey = this.billingPlanService.getCurrentPlanDescriptionTranslateKey(
      this.currentApp,
      this.billingInfo,
    );
    this.discountsByPeriod = this.billingPlanService.getCurrentDiscountsByPeriod(this.currentApp, this.billingInfo);
    this.limitUniqueUsers = this.billingPlanService.getCurrentLimitUniqueUsers(this.currentApp, this.billingInfo);
    this.noBrandingAddOnId = this.billingPlanService.getNoBrandingBillingAddOnId(this.currentApp);
    this.numberMonthsInDiscountsByPeriod = this.billingPlanService.getCurrentMonthsInDiscountsByPeriod(
      this.currentApp,
      this.billingInfo,
    );

    if (this.currentApp) {
      this.getTariffInfo().then(() => {
        if (this.callPlace === PLAN_CAPABILITIES_PRICING_PLACE.BILLING) {
          // Если компонент вызывается в биллинге
          this.trackViewNewBillingPage();
        }
      });

      // NOTE:
      //  Если сразу инициализировать слайдер с нужными параметрами, то при открытии его в пейволе, а так же
      //  при открытии пейвола на странице подписки, бегунок не устанавливается в нужное положение.
      this.getQuotas()
        .then(() => this.fullConfigureSlider())
        .then(() => this.changeDetectorRef.markForCheck());
    }

    const noBrandingBillingAddOnId = this.billingPlanService.getNoBrandingBillingAddOnId(this.currentApp);
    this.addOns.push(this.billingPlanService.getBillingAddOn(noBrandingBillingAddOnId, this.currentApp, 1000));
  }

  /**
   * Получение отформатированной стоимости аддона
   *
   * @param addOnId ID аддона
   * @return Отформатированная стоимость аддона
   */
  getAddOnFormattedPrice(addOnId: any): string {
    let value = 1000;
    if (this.slider.value !== 0) {
      value = this.slider.value;
    }
    return this.billingPlanService.getAddOnFormattedPrice(addOnId, this.currentApp, value);
  }

  /**
   * Получение перевода стоимости аддона
   *
   * @param addOnId ID аддона
   * @return Отформатированная стоимость аддона
   */
  getAddOnPriceTranslate(addOnId: any): string {
    let formattedAddOnPrice = '';

    if (addOnId === BILLING_ADD_ONS.NO_BRANDING_UNITS_PRICING && this.slider.value === this.limitUniqueUsers) {
      formattedAddOnPrice = this.transloco.translate('pricingOldComponent.heroBlock.sliderBlock.addOns.overLimit');
    } else {
      formattedAddOnPrice = this.transloco.translate('pricingOldComponent.heroBlock.sliderBlock.addOns.perMonth', {
        addOnPrice: this.getAddOnFormattedPrice(addOnId),
      });
    }

    return formattedAddOnPrice;
  }

  /**
   * Получение квот
   *
   * @return Обещание, которое вернёт квоты
   */
  getQuotas(): Promise<any> {
    return firstValueFrom(this.billingInfoModel.getAppQuotas(this.currentApp.id, false)).then((quotas: any) => {
      this.appQuotas = quotas;
    });
  }

  /**
   * Конфигурирование слайдера с уникальными посетителями после инициализации
   */
  fullConfigureSlider(): void {
    const newSliderValue = this.getForecastValue(this.appQuotas.users7);
    this.slider.value = newSliderValue === 0 ? this.slider.value : newSliderValue; // Значение слайдера
    this.slider.options = {
      ...this.slider.options,
      ceil: this.limitUniqueUsers - 1, // Конечное значение
      stepsArray: this.getSliderSteps(), // Список шагов слайдера
    };
  }

  /** Получение стоимости всех активных аддонов */
  getActiveAddOnsPrice(): any {
    const activeAddOns = this.addOns.filter((addOn: any) => addOn.active);
    const activeAddOnsPriceSum = activeAddOns
      .map((addOn: any) => this.billingPlanService.getAddOnPrice(addOn.id, this.currentApp, this.slider.value))
      .reduce((a: any, b: any) => a + b, 0);

    return activeAddOnsPriceSum;
  }

  /**
   * Получение ближайшего к прогнозируемому значению уников значения слайдера
   *
   * @return {Number}
   */
  getForecastValue(expectUsers: any): number {
    let forecastValue = 0;

    if (expectUsers) {
      let expectValue = Math.round((expectUsers / 7) * 30);
      let stepsArray = this.getSliderSteps();

      stepsArray.filter((step: any) => {
        if (!forecastValue && step.value > expectValue) {
          forecastValue = step.value;
        }
      });
    }

    return forecastValue;
  }

  /**
   * Получение шагов слайдера
   *
   * NOTE:
   *  Шаги слайдера формируются из шкалы уникальных пользователей на capability-тарифах
   *
   * @return {Object}
   */
  getSliderSteps(): any {
    return this.billingPlanService.getCurrentScaleUniqueUsers(this.currentApp, this.billingInfo);
  }

  /**
   * Получение информации о тарифном плане
   *
   * @return {Promise}
   */
  getTariffInfo(): Promise<any> {
    return firstValueFrom(this.billingInfoModel.getTariffInfo(this.currentApp.id, false)).then((tariffInfo: any) => {
      this.currentPlanName = tariffInfo.invoiceName;
    });
  }

  /**
   * Получение стоимости тарифа с учетом скидки за выбранный период оплаты
   *
   * @param {Number} price Стоимость тарифа
   * @param {Number} payPeriod Период оплаты
   * @return {number}
   */
  getPriceWithSale(price: any, payPeriod: any = 1): number {
    const activeAddOnsPrice = this.getActiveAddOnsPrice();

    return price * payPeriod * (1 - this.chosenDiscount) + activeAddOnsPrice * payPeriod;
  }

  /**
   * Получение стоимости тарифа без учёта скидки за выбранный период оплаты
   *
   * @param {Number} price Стоимость тарифа
   * @param {Number} payPeriod Период оплаты
   * @return {number}
   */
  getPriceWithoutSale(price: any, payPeriod: any = 1): number {
    const activeAddOnsPrice = this.getActiveAddOnsPrice();

    return price * payPeriod + activeAddOnsPrice * payPeriod;
  }

  /** Была ли выполнена первая оплата со скидкой по реферальной программе */
  hasFirstPaymentDiscount(): any {
    return this.firstPromoterService.hasFirstPaymentDiscount();
  }

  /**
   * Отправка сообщения в чат
   *
   * @param {String} newPlan - Название плана, на который нужно переключиться
   */
  sendChatMessage(newPlan: any): void {
    // На Freemium доступно только 1000 уникальных пользователей
    const users: any = newPlan === this.NEW_PLANS_NAMES.PLAN_0 ? 1000 : this.slider.value;

    const params: any = {
      currentPlan: this.currentPlanName,
      newPlan: newPlan.replace(/&nbsp;/g, ' '),
      users: users,
      appId: this.currentApp.id,
      payPeriod: this.numberMonthsInDiscountsByPeriod[this.chosenDiscount],
    };
    this.carrotquestHelper.sendChatMessage(
      this.transloco.translate('pricingOldComponent.sendMessageText', {
        currentPlan: params.currentPlan,
        newPlan: params.newPlan,
        users: params.users,
        appId: params.appId,
        payPeriod: params.payPeriod,
      }),
    );
  }

  /**
   * Трекинг клика на кнопку выбора тарифа
   *
   * @param {NEW_PLANS_NAMES} tariffName - Название тарифа, который выбрал пользователь
   */
  trackClickChoosePlanButton(tariffName: any): void {
    if (this.callPlace === PLAN_CAPABILITIES_PRICING_PLACE.BILLING) {
      this.carrotquestHelper.track('Тариф коммуникации - клик на тариф страница оплаты', {
        'Текущий тариф': this.currentPlanName,
        'Новый тариф': tariffName,
      });
    } else if (this.callPlace === PLAN_CAPABILITIES_PRICING_PLACE.PAYWALL) {
      this.carrotquestHelper.track('Тариф коммуникации - клик на тариф пейволл', {
        'Текущий тариф': this.currentPlanName,
        'Новый тариф': tariffName,
      });
    }
  }

  /**
   * Трекинг выбора Premium тарифа
   */
  trackChoicePremium(): void {
    this.carrotquestHelper.track('Клик на “связаться с нами” на странице оплаты');
  }

  /**
   * Трекинг просмотра новой страницы биллинга с блоком тарифной сетки
   */
  trackViewNewBillingPage(): void {
    this.carrotquestHelper.track('Тариф коммуникации - перешел на страницу оплата', {
      'Текущий тариф': this.currentPlanName,
    });
  }
}

<ng-container *transloco="let t; read: 'aiDataSourcesListComponent'">
  <div class="d-flex justify-space-between align-items-start">
    <div [innerHTML]="t('nav.description')"></div>
    <a
      class="btn btn-borderless-primary"
      uiSref="app.content.aiDataSources.botSettings"
    >
      <i class="btn-icon-left cqi-sm cqi-cog"></i>
      {{ t('aiBotSettingsButton') }}</a
    >
  </div>

  <cq-ai-data-settings-banner
    *ngIf="isShowSettingsBanner | async"
    class="mt-20"
    (clickCloseButton)="closeSettingsBanner()"
  ></cq-ai-data-settings-banner>
  <div class="mt-20">
    <h4 class="mb-20">{{ t('dataSources') }}</h4>
    <cq-ai-data-source-table></cq-ai-data-source-table>
  </div>
</ng-container>

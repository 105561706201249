import { firstValueFrom } from 'rxjs';
import { INTEGRATION_TYPES } from '../../../../../app/http/integration/constants/integration.constants';
import { MESSAGE_STATUSES } from '../../../../../app/http/message/message.constants';
import { CHAT_BOT_TYPE } from '../../../../../app/http/chat-bot/types/chat-bot-external.types';
import { FEATURES } from '../../../../../app/http/feature/feature.constants';

(function () {
  'use strict';

  angular.module('myApp.chatBot.leadBot').config(config);

  function config($stateProvider) {
    $stateProvider

      .state('app.content.messagesAjs.chatBot.create', {
        url: '/new?{chatBotTemplateId}&{hideTemplateModal:bool}',
        redirectTo: (transition) => {
          const chatBotTemplateId = transition.params().chatBotTemplateId;

          if (chatBotTemplateId) {
            return {
              state: 'app.content.messagesAjs.chatBot.create.fromTemplate',
              params: transition.params(),
            };
          }

          return {
            state: 'app.content.messagesAjs.chatBot.create.new',
            params: transition.params(),
          };
        },
      })
      .state('app.content.messagesAjs.chatBot.create.new', {
        url: '?{fromStarterGuideStep}',
        resolve: {
          autoEvents: getAutoEvents,
          calendlyEventList: getCalendlyEventList,
          calendlyIntegrationList: getCalendlyIntegrationList,
          channels: getChannels,
          chatBotMessage: getInitialBotMessage,
          conversationTags: getAllConversationTags,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveTelegramBots: getDataAboutActiveTelegramBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          isFirstLeadBot: isFirstLeadBot,
          properties: getProperties,
          templates: getTemplates,
          segments: getSegments,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          telegramIntegrations: getTelegramIntegrations,
        },
        bindings: {
          autoEvents: 'autoEvents',
          billingInfo: 'billingInfo',
          channels: 'channels',
          chatBotMessage: 'chatBotMessage',
          conversationTags: 'conversationTags',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveTelegramBots: 'dataAboutActiveTelegramBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          djangoUserTempData: 'djangoUserTempData',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          isFirstLeadBot: 'isFirstLeadBot',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          templates: 'templates',
          segments: 'segments',
          tags: 'tags',
          teamMembers: 'teamMembers',
          telegramIntegrations: 'telegramIntegrations',
        },
        component: 'cqChatBotEdit',
      })

      .state('app.content.messagesAjs.chatBot.create.fromTemplate', {
        url: '?{chatBotTemplateId}',
        resolve: {
          autoEvents: getAutoEvents,
          calendlyIntegrationList: getCalendlyIntegrationList,
          calendlyEventList: getCalendlyEventList,
          channels: getChannels,
          chatBotMessage: getTemplateForCreate,
          conversationTags: getAllConversationTags,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveTelegramBots: getDataAboutActiveTelegramBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          isFirstLeadBot: isFirstLeadBot,
          properties: getProperties,
          templates: getTemplates,
          segments: getSegments,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          telegramIntegrations: getTelegramIntegrations,
        },
        bindings: {
          autoEvents: 'autoEvents',
          billingInfo: 'billingInfo',
          channels: 'channels',
          chatBotMessage: 'chatBotMessage',
          conversationTags: 'conversationTags',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveTelegramBots: 'dataAboutActiveTelegramBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          djangoUserTempData: 'djangoUserTempData',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          isFirstLeadBot: 'isFirstLeadBot',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          templates: 'templates',
          segments: 'segments',
          tags: 'tags',
          teamMembers: 'teamMembers',
          telegramIntegrations: 'telegramIntegrations',
        },
        component: 'cqChatBotEdit',
      })

      .state('app.content.messagesAjs.chatBot.edit', {
        url: '/{chatBotId:id}/edit?{fromStarterGuideStep}&{step:int}',
        resolve: {
          autoEvents: getAutoEvents,
          calendlyEventList: getCalendlyEventList,
          calendlyIntegrationList: getCalendlyIntegrationList,
          channels: getChannels,
          chatBotMessage: getLeadBot,
          conversationTags: getAllConversationTags,
          dataAboutActiveFacebookBots: getDataAboutActiveFacebookBots,
          dataAboutActiveLeadBots: getDataAboutActiveLeadBots,
          dataAboutActiveTelegramBots: getDataAboutActiveTelegramBots,
          dataAboutActiveWelcomeBots: getDataAboutActiveWelcomeBots,
          emailNotificationIntegrationsExternal: getEmailNotificationIntegrationsExternal,
          amocrmIntegrationsExternal: getAmocrmIntegrationsExternal,
          properties: getProperties,
          templates: getTemplates,
          segments: getSegments,
          tags: getAllUserTags,
          teamMembers: getTeamMembers,
          telegramIntegrations: getTelegramIntegrations,
        },
        bindings: {
          autoEvents: 'autoEvents',
          billingInfo: 'billingInfo',
          channels: 'channels',
          chatBotMessage: 'chatBotMessage',
          conversationTags: 'conversationTags',
          currentApp: 'currentApp',
          dataAboutActiveFacebookBots: 'dataAboutActiveFacebookBots',
          dataAboutActiveLeadBots: 'dataAboutActiveLeadBots',
          dataAboutActiveTelegramBots: 'dataAboutActiveTelegramBots',
          dataAboutActiveWelcomeBots: 'dataAboutActiveWelcomeBots',
          djangoUser: 'djangoUser',
          djangoUserTempData: 'djangoUserTempData',
          emailNotificationIntegrationsExternal: 'emailNotificationIntegrationsExternal',
          amocrmIntegrationsExternal: 'amocrmIntegrationsExternal',
          planCapabilities: 'planCapabilities',
          properties: 'properties',
          templates: 'templates',
          segments: 'segments',
          tags: 'tags',
          teamMembers: 'teamMembers',
          telegramIntegrations: 'telegramIntegrations',
        },
        component: 'cqChatBotEdit',
      })

      .state('app.content.messagesAjs.chatBot.edit.copy', {
        url: '/copy?{fromStarterGuideStep}',
      });

    /**
     * Получание автособытий
     * @param currentApp
     * @param trackMasterModel
     * @returns {*}
     */
    function getAutoEvents(currentApp, trackMasterModel) {
      return firstValueFrom(trackMasterModel.getList(currentApp.id));
    }

    /**
     * Получение всех тегов диалогов
     *
     * @param {Object} currentApp - Текущий app
     * @param {Object} conversationTagsModel - Модель тегов диалога
     *
     * @returns {Promise}
     */
    function getAllConversationTags(currentApp, conversationTagsModel) {
      return firstValueFrom(conversationTagsModel.getList(currentApp.id));
    }

    /**
     * Получение всех тегов пользователя
     *
     * @param currentApp
     * @param tagModel
     * @returns {Promise}
     */
    function getAllUserTags(currentApp, tagModel) {
      return firstValueFrom(tagModel.getList(currentApp.id));
    }

    /**
     * Получение существующих интеграций AmoCRM
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getAmocrmIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.AMOCRM);
    }

    /** Получения списка интеграций Calendly
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     * @return {Promise}
     * */
    function getCalendlyIntegrationList(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel
        .getByType(currentApp.id, INTEGRATION_TYPES.CALENDLY)
        .then((integrations) => integrations.filter((integration) => integration.active));
    }

    /** Получения списка календарей в Calendly
     * @return {Promise}
     * */
    function getCalendlyEventList(integrationModel, calendlyIntegrationList, featureModel) {
      if (!calendlyIntegrationList.length) {
        return Promise.resolve([]);
      }

      const integrationId = calendlyIntegrationList[0].id;

      return integrationModel.getCalendlyEventList(integrationId);
    }

    function getTelegramIntegrations(integrationModel, currentApp) {
      return Promise.all([integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.TELEGRAM)]).then((response) => {
        return [...response[0]].filter((integration) => {
          return integration.active === true;
        });
      });
    }

    /**
     * Получение списка каналов
     *
     * @param channelModel
     * @param currentApp
     * @return {Promise}
     */
    function getChannels(channelModel, currentApp) {
      return firstValueFrom(channelModel.getList(currentApp.id));
    }

    /**
     * Получение информации об активных Facebook ботах
     *
     * @param chatBotModel
     */
    function getDataAboutActiveFacebookBots(chatBotModel) {
      return firstValueFrom(chatBotModel.getFacebookBot()).then((response) => {
        const facebookBots = response ? [response].filter((bot) => bot.active) : [];
        return {
          ids: facebookBots.map((bot) => bot.id),
          amount: facebookBots.length,
        };
      });
    }

    /**
     * Получение информации об активных Lead ботах
     *
     * @param currentApp
     * @param MESSAGE_STATUSES
     * @param chatBotModel
     */
    function getDataAboutActiveLeadBots(currentApp, chatBotModel) {
      return firstValueFrom(chatBotModel.getList(currentApp.id, MESSAGE_STATUSES.ACTIVE)).then((response) => {
        return {
          ids: response.chatBots.map((bot) => bot.id),
          amount: response.chatBots.length,
        };
      });
    }

    /**
     * Получение информации об активных Telegram ботах
     *
     * @param chatBotModel
     */
    function getDataAboutActiveTelegramBots(chatBotModel) {
      return firstValueFrom(chatBotModel.getTelegramBotList()).then((response) => {
        const telegramBots = response ? response.filter((bot) => bot.active) : [];
        return {
          ids: telegramBots.map((bot) => bot.id),
          amount: telegramBots.length,
        };
      });
    }

    /**
     * Получение информации об активных Welcome ботах
     *
     * @param chatBotModel
     */
    function getDataAboutActiveWelcomeBots(chatBotModel) {
      return firstValueFrom(chatBotModel.getRoutingBot()).then((response) => {
        const welcomeBots = response ? [response].filter((bot) => bot.active) : [];
        return {
          ids: welcomeBots.map((bot) => bot.id),
          amount: welcomeBots.length,
        };
      });
    }

    /**
     * Получение существующих интеграций "Уведомление на Email"
     *
     * @param INTEGRATION_TYPES
     * @param currentApp
     * @param integrationModel
     *
     * @returns {Promise}
     */
    function getEmailNotificationIntegrationsExternal(INTEGRATION_TYPES, currentApp, integrationModel) {
      return integrationModel.getByType(currentApp.id, INTEGRATION_TYPES.EMAIL_NOTIFICATION);
    }

    /**
     * Получение бота для создания
     *
     * @param chatBotModel
     * @return {Array<Object>}
     */
    function getInitialBotMessage(chatBotModel) {
      return chatBotModel.getInitialBotMessage();
    }

    /**
     * Получение чат-бота
     *
     * @param $state
     * @param $stateParams
     * @param currentApp
     * @param chatBotModel
     * @return {{}}
     */
    function getLeadBot($state, $stateParams, currentApp, chatBotModel) {
      if ($stateParams.chatBotId) {
        const bot = firstValueFrom(chatBotModel.getLeadBot(currentApp.id, $stateParams.chatBotId));

        if (bot) {
          return bot;
        } else {
          $state.go('app.content.messagesAjs.chatBot.create', angular.extend({}, $stateParams, { chatBotId: null }));
        }
      } else {
        $state.go('app.content.messagesAjs.chatBot.create', angular.extend({}, $stateParams, { chatBotId: null }));
      }
    }

    /**
     * Флаг, является ли бот самым первым (пригождается для онбординга)
     * @returns boolean
     */
    function isFirstLeadBot(currentApp, chatBotModel) {
      // Нам по большому счету не важна сама пагинация, важно, что есть хотяб 1 лид-бот
      const paginatorParams = {
        paginateCount: 1,
      };
      return firstValueFrom(chatBotModel.getList(currentApp.id, MESSAGE_STATUSES.ALL, paginatorParams)).then((resp) => {
        return resp.chatBots.length === 0;
      });
    }

    /**
     * Получение списка свойств и типов событий
     *
     * @param currentApp
     * @param propertyModel
     * @return {Promise}
     */
    function getProperties(currentApp, propertyModel) {
      return firstValueFrom(propertyModel.getList(currentApp.id));
    }

    /**
     * Получение шаблонов чат-бота
     * @param currentApp
     * @param chatBotTemplateModel
     * @returns {*}
     */
    function getTemplates(currentApp, chatBotTemplateModel) {
      return firstValueFrom(chatBotTemplateModel.getList(currentApp.id, CHAT_BOT_TYPE.LEAD));
    }

    /**
     * Получение шаблона для создания бота на его основе
     *
     * @param $stateParams
     * @param chatBotModel
     * @param chatBotTemplateModel
     * @param currentApp
     *
     * @returns {ChatBot}
     */
    function getTemplateForCreate($stateParams, chatBotModel, chatBotTemplateModel, currentApp) {
      return firstValueFrom(chatBotTemplateModel.get($stateParams.chatBotTemplateId, currentApp.id)).then(
        (template) => {
          const initialBotMessage = chatBotModel.getInitialBotMessage();
          initialBotMessage.bot = chatBotTemplateModel.getChatBotFromTemplate(template, CHAT_BOT_TYPE.LEAD);
          initialBotMessage.name = template.name;
          return initialBotMessage;
        },
      );
    }

    /**
     * Получение сегментов
     *
     * @param $transition$
     * @param currentApp
     * @param tags
     * @param properties
     * @returns {*}
     */
    function getSegments($transition$, currentApp, tags, properties) {
      let filterAjsModel = $transition$.injector().get('filterAjsModel');
      let segmentModel = $transition$.injector().get('segmentModel');

      return firstValueFrom(segmentModel.getList(currentApp.id, true)).then(getSegmentsSuccess);

      function getSegmentsSuccess(segments) {
        for (let i = 0; i < segments.length; i++) {
          filterAjsModel.linkWithPropsAndTags(segments[i].filters, properties, tags);
        }
        return segments;
      }
    }

    /**
     * Получение членов команды
     *
     * @param $transition$
     * @param currentApp
     * @returns {*}
     */
    function getTeamMembers($transition$, currentApp) {
      let teamMemberModel = $transition$.injector().get('teamMemberModel');

      return firstValueFrom(teamMemberModel.getList(currentApp.id, currentApp, undefined, true));
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { SharedModule } from '../../../../../shared/shared.module';
import { MassActionPanelComponent } from './mass-action-panel.component';

@NgModule({
  declarations: [MassActionPanelComponent],
  exports: [MassActionPanelComponent],
  imports: [CommonModule, SharedModule, TranslocoModule],
})
export class MassActionPanelModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from '../../../shared/shared.module';
import { UserSubscriptionsComponent } from './user-subscriptions.component';

@NgModule({
  declarations: [UserSubscriptionsComponent],
  exports: [UserSubscriptionsComponent],
  imports: [ClipboardModule, CommonModule, NgbCollapseModule, NgbTooltipModule, SharedModule, TranslocoModule],
})
export class UserSubscriptionsModule {}

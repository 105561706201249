import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { NotificationSettingsGeneralComponent } from '@panel/app/pages/account/shared/notification-settings/notification-settings-general/notification-settings-general.component';

@NgModule({
  declarations: [NotificationSettingsGeneralComponent],
  exports: [NotificationSettingsGeneralComponent],
  imports: [CommonModule, TranslocoModule, FormsModule],
})
export class NotificationSettingsGeneralModule {}

import Split from 'split.js';

(function () {
  'use strict';

  angular.module('myApp.directives.split').controller('CqSplitController', CqSplitController);

  function CqSplitController() {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.addView = addView;
      vm.destroy = destroy;
      vm.views = [];
      vm.onViewsChange = onViewsChange;
      vm.removeView = removeView;
      vm.splitInstance = null;
    }

    /**
     * Добавление информации о split-view, вызывается из split-view
     *
     * @param view
     */
    function addView(view) {
      vm.views.push(view);
    }

    /**
     * Формирование кастомных стилей для элементов
     *
     * @param {('width'|'height')} dimension Высота или ширина
     * @param {Number} size Размер элемента по высоте или ширине
     * @param {Number} gutterSize Размер разделителя по высоте или ширине
     * @return {Object}
     */
    function defaultElementStyle(dimension, size, gutterSize) {
      return {
        webkitFlexBasis: 'calc(' + size + '% - ' + gutterSize + 'px)',
        'flex-basis': 'calc(' + size + '% - ' + gutterSize + 'px)',
      };
    }

    /**
     * Формирование кастомного разделителя
     *
     * @param {Number} i Индекс разделителя
     * @param {('horizontal', 'vertical')} gutterDirection Вертикальный или горизонтальный разделитель
     * @return {HTMLElement}
     */
    function defaultGutter(i, gutterDirection) {
      var gutterWrapper = angular.element('<div class="gutter-wrapper flex "></div>');
      var gutter = angular.element('<div class="gutter gutter-' + gutterDirection + '"></div>');
      var gutterIndent = angular.isDefined(vm.gutterIndent) ? parseInt(vm.gutterIndent) : null;

      gutterWrapper.append(gutter);

      if (gutterIndent) {
        if (gutterDirection == 'horizontal') {
          gutter.css({
            'margin-right': vm.gutterIndent + 'px',
            'margin-left': vm.gutterIndent + 'px',
          });
        } else if (gutterDirection == 'vertical') {
          gutter.css({
            'margin-top': vm.gutterIndent + 'px',
            'margin-bottom': vm.gutterIndent + 'px',
          });
        }
      }

      return gutterWrapper[0];
    }

    /**
     * Формирование стилей для кастомного разделителя
     *
     * @param {('width'|'height')} dimension Высота или ширина
     * @param {Number} gutterSize Размер разделителя по высоте или ширине
     * @return {Object}
     */
    function defaultGutterStyle(dimension, gutterSize) {
      // правила взяты из https://autoprefixer.github.io/ с приминением текущего browserslist проекта panel (default)
      return {
        '-webkit-flex-basis': gutterSize + 'px',
        '-ms-flex-preferred-size': gutterSize + 'px',
        'flex-basis': gutterSize + 'px',
        '-webkit-flex-shrink': 0,
        '-ms-flex-negative': 0,
        'flex-shrink': 0,
      };
    }

    /**
     * Уничтожение экземпляра split
     */
    function destroy() {
      vm.splitInstance.destroy();
      vm.splitInstance = null;
    }

    /**
     * Функция, которая выполняется при изменении разделённых элементов
     *
     * @param newValue
     * @param oldValue
     */
    function onViewsChange(newValue, oldValue) {
      // если добавился или убрался один из split-view - надо уничтожить instance и пересоздать его, чтобы было правильное количество gutter'ов
      if (oldValue.length != newValue.length && vm.splitInstance) {
        vm.splitInstance.destroy();
      }

      // если элементов меньше 2 - вообще можно ничего не создавать, ибо ресайзить нечего
      if (newValue.length < 2) {
        return;
      }

      // сортировать надо shallow-copy массив, т.к. функция sort меняет текущий массив, и $watchCollection вызовется ещё раз
      var sortedViews = newValue.slice().sort(function (a, b) {
        if (a.element.next().is(b.element)) {
          return -1;
        } else if (a.element.prev().is(b.element)) {
          return 1;
        } else {
          return 0;
        }
      });

      // минимальный размер можно задать как у каждого элемента по отдельности в split-view, так и для всех элементов разом
      var minSizes = angular.isDefined(vm.minSize)
        ? parseInt(vm.minSize)
        : sortedViews.map(function (a) {
            return angular.isDefined(a.minSize) ? parseInt(a.minSize, 10) : undefined;
          });

      var sizes = sortedViews.map(function (a) {
        return angular.isDefined(a.size) ? parseInt(a.size, 10) : undefined;
      });

      var elements = sortedViews.map(function (a) {
        return a.element[0];
      });

      var params = {
        cursor: vm.cursor,
        direction: vm.direction,
        elementStyle: vm.elementStyle || defaultElementStyle,
        gutter: angular.isDefined(vm.gutter) ? angular.bind(null, vm.gutter, vm.gutterIndent) : defaultGutter,
        gutterSize: angular.isDefined(vm.gutterSize) ? parseInt(vm.gutterSize, 10) : undefined,
        gutterStyle: vm.gutterStyle || defaultGutterStyle,
        minSize: minSizes,
        onDrag: vm.onDrag,
        onDragEnd: vm.onDragEnd,
        onDragStart: vm.onDragStart,
        sizes: sizes,
        snapOffset: angular.isDefined(vm.snapOffset) ? parseInt(vm.snapOffset, 10) : undefined,
      };

      vm.splitInstance = Split(elements, params);
    }

    /**
     * Удаление элемента
     *
     * @param {Object} view Элемент
     */
    function removeView(view) {
      var index = vm.views.indexOf(view);
      if (index !== -1) {
        vm.views.splice(index, 1);
      }
    }
  }
})();

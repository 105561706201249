<ng-container *transloco="let t">
  <div class="modal-header">
    <h3 class="modal-title">{{ t('sendScriptInstructionComponent.header') }}</h3>
    <button
      class="close"
      (click)="ajsModalInstance.dismiss()"
    >
      <i class="btn-icon cqi-sm cqi-03x cqi-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="mb-10">{{ t('sendScriptInstructionComponent.body') }}</div>
    <form
      class="d-flex"
      (ngSubmit)="onSubmit()"
    >
      <div
        class="form-group no-margin-bottom w-100"
        [cqShowError]="emailControl"
      >
        <input
          class="form-control"
          [formControl]="emailControl"
          [placeholder]="t('sendScriptInstructionComponent.sendInstructionsForm.emailInput.placeholder')"
          name="email"
        />
        <cq-validation-messages [control]="emailControl">
          <cq-validation-message errorName="required">
            {{ t('sendScriptInstructionComponent.sendInstructionsForm.emailInput.errors.required') }}
          </cq-validation-message>
          <cq-validation-message errorName="email">
            {{ t('sendScriptInstructionComponent.sendInstructionsForm.emailInput.errors.email') }}
          </cq-validation-message>
        </cq-validation-messages>
      </div>
      <button
        class="btn btn-primary ml-10"
        type="submit"
        [attr.disabled]="(isDataLoadingObs$ | async) ? true : null"
      >
        {{ t('sendScriptInstructionComponent.sendInstructionsForm.sendButton') }}
      </button>
    </form>
  </div>

  <div class="modal-footer flex-nowrap">
    <div class="mr-auto">
      {{ t('sendScriptInstructionComponent.sendInstructionsLink.text') }}
    </div>
    <button
      class="btn btn-outline-primary"
      type="button"
      (click)="copyLink()"
      data-test="send-script-modal-copy-btn"
    >
      <i class="btn-icon-left cqi-sm cqi-copy"></i>
      {{ t('sendScriptInstructionComponent.sendInstructionsLink.copyLinkButton') }}
    </button>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { SubscriptionStore } from '@panel/app/pages/subscription/general/subscription.store';
import { emailValidator } from '@panel/app/shared/validators/email/email-validator';

type AccountingEmailsFormType = FormArray<FormControl<string>>;

/** Компонент-модалка для редактирования бухгалтерских емейлов */
@Component({
  selector: 'cq-edit-accounting-emails-modal',
  templateUrl: './edit-accounting-emails-modal.component.html',
  styleUrls: ['./edit-accounting-emails-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAccountingEmailsModalComponent {
  /** Максимальная длина бухгалтерского емейла */
  ACCOUNTING_EMAIL_MAX_LENGTH = 50;

  /** Форма с бухгалтерскими емейлами */
  form: AccountingEmailsFormType = this.initForm();

  /** Флаг выполнения запроса */
  isApiRequestPerformed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public readonly ngbActiveModal: NgbActiveModal, private readonly subscriptionStore$: SubscriptionStore) {}

  /** Добавление контрола с новым емейлом */
  addControl(): void {
    const control = this.createFormControl();
    this.form.controls.push(control);
  }

  /** Нужно ли показать кнопку добавления нового контрола с емейлом */
  needShowAddControlButton(): boolean {
    return this.form.controls.length !== 5;
  }

  /** Нужно ли показать кнопку удаления контрола с емейлом */
  needShowRemoveControlButtons(): boolean {
    return this.form.controls.length > 1;
  }

  /** Обработчик отправки формы */
  onSubmit(): void {
    if (!this.isFormArrayValid(this.form)) {
      this.form.markAllAsTouched();

      return;
    }

    const billing = this.subscriptionStore$.billing$.getValue()!;
    const newEmails = this.form.controls.map((control) => control.value);

    this.isApiRequestPerformed.next(true);

    billing
      .editAccountingEmails(newEmails)
      .then((response) => this.ngbActiveModal.close(response))
      .finally(() => this.isApiRequestPerformed.next(false));
  }

  /**
   * Удаление контрола с емейлом
   
   * @param index Индекс контрола в массиве
   */
  removeControl(index: number): void {
    this.form.removeAt(index);
  }

  /**
   * Создание FormControl с емейлом
   *
   * @param email Бухгалтерский емейл
   * @private
   */
  private createFormControl(email: string = ''): FormControl<string> {
    return new FormControl<string>(email, {
      nonNullable: true,
      validators: [Validators.maxLength(this.ACCOUNTING_EMAIL_MAX_LENGTH), Validators.required, emailValidator],
    });
  }

  /** Начальная инициализация формы с емейлами */
  private initForm(): AccountingEmailsFormType {
    const billing = this.subscriptionStore$.billing$.getValue()!;
    const accountingEmails = billing.accountingEmails;
    const formControls = accountingEmails.map((email: string) => this.createFormControl(email));

    if (formControls.length === 0) {
      formControls.push(this.createFormControl());
    }

    return new FormArray<FormControl<string>>(formControls);
  }

  /**
   * Проверка валидности формы
   *
   * @param formArray Форма типа FormArray
   * @private
   */
  private isFormArrayValid(formArray: FormArray): boolean {
    return formArray.controls.every((control) => control.valid);
  }
}

<div
  class="ready-message-template position-relative flex flex-col overflow-hidden bordered full-height cursor-pointer"
  data-test="ready-made-templates-item"
>
  <div class="full-height flex align-items-start">
    <img
      class="full-max-width full-width"
      alt="preview"
      [src]="previewSrc"
    />
  </div>
  <div class="description position-absolute bottom-0 full-width padding-20 bordered-top">
    <div class="margin-bottom-5 text-muted">
      <i
        class="cqi-sm"
        [ngClass]="templateTypeIconCssClass"
      ></i>
      <small data-test="ready-made-templates-template-type">{{ templateTypeText }}</small>
    </div>
    <div
      class="name"
      [title]="template.name"
    >
      {{ template.name }}
    </div>
  </div>
</div>

<ng-container *transloco="let t; read: 'amocrmFieldsSelectComponent.' + type">
  <div [cqShowError]="control">
    <ng-select
      [items]="fields"
      [formControl]="control"
      [clearable]="false"
      [placeholder]="t('placeholder', {integrationTypeName})"
      bindLabel="name"
      bindValue="name"
      groupBy="fieldType"
    >
      <ng-template
        let-field="item"
        ng-label-tmp
      >
        <span
          class="d-flex align-items-center"
          *ngIf="isRemovedField(field.name)"
        >
          <i
            class="cqi-sm cqi-triangle-exclamation position-relative text-danger margin-right-5"
            [ngbTooltip]="tooltipRemovedFieldLead"
            container="body"
          ></i>
          <span class="flex-grow-1 text-truncate">
            {{ t('fieldLead.removed') }}
          </span>
          <ng-template #tooltipRemovedFieldLead>
            <div [innerHTML]="t('tooltips.removedFieldLead')"></div>
          </ng-template>
        </span>

        <span *ngIf="!isRemovedField(field.name)">{{ field.name }}</span>
      </ng-template>

      <ng-template
        let-field="item"
        ng-optgroup-tmp
      >
        {{ t('fieldTypes.' + field.fieldType) }}
      </ng-template>

      <ng-template
        let-item="item"
        ng-option-tmp
      >
        <span class="text-wrap">{{ item.name }}</span>
      </ng-template>

      <ng-container *ng-footer-tmp>
        <button
          class="btn btn-block btn-secondary"
          (click)="openCreateNewFieldModal()"
          type="button"
        >
          <i class="cqi-sm cqi-plus"></i>
          {{ t('createNewFieldButton') }}
        </button>
      </ng-container>
    </ng-select>
    <cq-validation-messages [control]="control">
      <cq-validation-message errorName="required">
        {{ t('errors.required', {integrationTypeName}) }}
      </cq-validation-message>
    </cq-validation-messages>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { TeamMember } from '@http/team-member/team-member.types';
import { AbsCVAFormControlBasedComponent } from '@panel/app/shared/abstractions/cva/abstract-cva-form-control-based-component';

@Component({
  selector: 'cq-team-members-select-control',
  templateUrl: './team-members-select-control.component.html',
  styleUrls: ['./team-members-select-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMembersSelectControlComponent extends AbsCVAFormControlBasedComponent<string[]> {
  /** Члены команды */
  @Input() teamMembers!: TeamMember[];

  readonly control: FormControl<string[]> = new FormControl<string[]>([], {
    nonNullable: true,
    validators: [Validators.required],
  });

  constructor() {
    super();
  }

  searchOperatorFn(term: string, item: TeamMember) {
    return (
      (!!item.name && item.name.toLowerCase().search(term.toLowerCase()) > -1) ||
      (!!item.group && !!item.group.name && item.group.name.toLowerCase().search(term.toLowerCase()) > -1)
    );
  }

  groupFn(teamMember: TeamMember) {
    return teamMember.group.name;
  }
}

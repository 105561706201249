export enum FILTER_LOGICAL_OPERATION {
  AND = 'and',
  OR = 'or',
}

export enum FILTER_DATE_VALUE_UNIT {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  WEEKS = 'weeks',
}

export enum FILTER_EVENT_TYPE {
  COUNT = 'count',
  FIRST = 'first',
  LAST = 'last',
}

/** Получить список */
export const FILTER_EVENT_TYPE_ARRAY = [FILTER_EVENT_TYPE.COUNT, FILTER_EVENT_TYPE.FIRST, FILTER_EVENT_TYPE.LAST];

/** Получить иконку по типу свойства фильтра события */
export const ICON_BY_FILTER_EVENT_TYPE = {
  [FILTER_EVENT_TYPE.COUNT]: 'cqi-stopwatch',
  [FILTER_EVENT_TYPE.FIRST]: 'cqi-step-backward',
  [FILTER_EVENT_TYPE.LAST]: 'cqi-step-forward',
};

import { TranslocoService } from '@jsverse/transloco';

import { environment } from '@environment';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { SettingsIntegration } from '@http/integration/integration';
import { PREINSTALLED_PROPERTIES } from '@http/integration/integrations/email-notification/constants/email-notification-integration.constants';
import {
  EmailNotificationIntegrationExternal,
  EmailNotificationIntegrationSettingsExternal,
} from '@http/integration/integrations/email-notification/interfaces/email-notification-integration.interfaces';
import { EmailNotificationIntegrationForm } from '@panel/app/pages/integrations/content/email-notification/email-notification.form';
import { UserProperty } from '@http/property/property.model';

/** Класс для работы с интеграцией "Уведомление на почту" */
export class EmailNotificationIntegration extends SettingsIntegration<EmailNotificationIntegrationSettingsExternal> {
  /** Форма настройки интеграции */
  form: EmailNotificationIntegrationForm;
  /** Тип интеграции */
  type = INTEGRATION_TYPES.EMAIL_NOTIFICATION;
  /** Настройки интеграции */
  settings: EmailNotificationIntegrationSettingsExternal = {
    subject: '',
    emailRecipients: [],
    comment: '',
    props: [],
    eventTypeIds: [],
  };

  /** Получение внешнего формата интеграции */
  get externalFormat(): EmailNotificationIntegrationExternal {
    return {
      ...this.baseExternalFormat,
      ...this.form.value,
    };
  }

  constructor(translocoSerivce: TranslocoService, integrationExternal?: EmailNotificationIntegrationExternal) {
    super(integrationExternal);

    // Если интеграция создается из внешнего формата, обновим settings, иначе задаем дефолтные значения
    if (integrationExternal) {
      this.updateSettings(integrationExternal.settings);
    } else {
      this.active = true;

      this.name = translocoSerivce.translate(`emailNotificationIntegration.defaultValues.name`);
      this.settings.subject = translocoSerivce.translate(`emailNotificationIntegration.defaultValues.subject`, {
        projectName: environment.projectName,
      });
      this.settings.comment = translocoSerivce.translate(`emailNotificationIntegration.defaultValues.comment`);

      this.settings.props = this.getPreinstalledPropertyNames();
    }

    // Данные о настроенной интеграции собираются с формы и отправляются на backend
    this.form = new EmailNotificationIntegrationForm(this);
  }

  /** Получение имён предустановленных свойств пользователя */
  getPreinstalledPropertyNames(): UserProperty['name'][] {
    return PREINSTALLED_PROPERTIES.map((preinstalledProperty) => preinstalledProperty.name);
  }

  /**
   * Обновление settings
   *
   * @param settings - Настройки интеграции в внешнем формате
   */
  updateSettings(settings: any): void {
    this.settings = settings;
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '@ng-web-apis/common';
import { BehaviorSubject } from 'rxjs';

import {
  STRIPE_THREE_DS_MODAL_DATA_TOKEN,
  StripeThreeDSModalData,
} from '@panel/app/pages/subscription/general/stripe-three-d-s-modal/stripe-three-d-s-modal.token';

/** Компонент для 3DS верификации карты Stripe */
@Component({
  selector: 'cq-stripe-three-d-secure-modal',
  templateUrl: './stripe-three-d-s-modal.component.html',
  styleUrls: ['./stripe-three-d-s-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeThreeDSModalComponent {
  /** Флаг готовности документа iframe */
  iframeLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Обработанный sanitizer'ом URL для iframe */
  iframeSrc!: SafeUrl;

  constructor(
    @Inject(STRIPE_THREE_DS_MODAL_DATA_TOKEN)
    public readonly data: StripeThreeDSModalData,
    public readonly ngbActiveModal: NgbActiveModal,
    private readonly sanitizer: DomSanitizer,
    @Inject(WINDOW) readonly windowRef: Window,
  ) {
    this.iframeSrc = sanitizer.bypassSecurityTrustResourceUrl(data.iframeSrc);
  }

  /** Обработчик успешной загрузки документа в iframe */
  onLoad(): void {
    this.iframeLoaded.next(true);

    this.windowRef.addEventListener('message', (event) => this.check3DSCompletion(event));
  }

  /**
   * Прослушивание сообщений от iframe с 3DS
   *
   * @param event
   * @private
   */
  private check3DSCompletion(event: MessageEvent): void {
    if (event.data === '3DS-authentication-complete') {
      this.ngbActiveModal.close();
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TriggerChainSendingConditionsEditorComponent } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/trigger-chain-sending-conditions-editor/trigger-chain-sending-conditions-editor.component';

@NgModule({
  declarations: [TriggerChainSendingConditionsEditorComponent],
  imports: [CommonModule],
  exports: [TriggerChainSendingConditionsEditorComponent],
})
export class TriggerChainSendingConditionsEditorModule {}

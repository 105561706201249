import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cq-not-assigned',
  templateUrl: './not-assigned.component.html',
  styleUrls: ['./not-assigned.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotAssignedComponent {
  @Input()
  unsorted: boolean = false;
}

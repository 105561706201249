import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import moment from 'moment';
import { map } from 'rxjs/operators';

import { PushSubscription } from '@http/push-subscription/push-subscription.types';

/**
 * Сервис для работы с подписками на пуши
 */
@Injectable({ providedIn: 'root' })
export class PushSubscriptionModel {
  constructor(private readonly http: HttpClient) {}

  /**
   * Получение списка токенов пользователя
   *
   * @param userId carrotquest_uid пользователя
   */
  getList(userId: string) {
    return this.http
      .get<any[]>('/users/' + userId + '/firebasetokens', {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((data) => {
          return this.getListSuccess(data);
        }),
      );
  }

  /**
   * Приведение токенов пользователя во внутренний формат
   */
  getListSuccess(pushSubscriptions: any[]) {
    for (let i = 0; i < pushSubscriptions.length; i++) {
      this.parsePushSubscriptionToInternalFormat(pushSubscriptions[i]);
    }

    return pushSubscriptions;
  }

  /**
   * Парсинг подписок на пуши во внутренний формат
   *
   * @param {Object} pushSubscription
   */
  parsePushSubscriptionToInternalFormat(pushSubscription: PushSubscription) {
    pushSubscription.created = moment(pushSubscription.created, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
  }

  /**
   * Отписать пользователя от пушей по deviceGuid
   *
   * @param deviceGuid guid устройства, которое отписывается
   * @param userId carrotquest_uid пользователя
   * @param deviceType Тип устройства, которое отписывается
   */
  unsubscribeByDeviceGuid(deviceGuid: string, userId: string, deviceType: string) {
    const body: { device_guid: string; type?: string } = {
      device_guid: deviceGuid,
    };

    if (deviceType) {
      body['type'] = deviceType;
    }

    return this.http.post('/users/' + userId + '/pushes_unsubscribe', body);
  }

  /**
   * Отписка от пушей по id токена
   *
   * @param tokenId id токена подписки firebase в базе
   */
  unsubscribeByFirebaseTokenId(tokenId: string) {
    return this.http.post('/userfirebasetokens/' + tokenId + '/unsubscribe', {});
  }
}

<ng-container *transloco="let t; read: 'descriptionPopoverTemplateComponent'">
  <ng-template #templateRef>
    <div class="d-flex flex-column margin-between-rows-10">
      <div
        *ngFor="let trigger of triggers"
        class="badge badge-pill badge-light-secondary"
      >
        {{ trigger }}
      </div>
    </div>
  </ng-template>
</ng-container>

(function () {
  'use strict';

  require('./components/all-time-stats/trigger-chain-all-time-stats.module');
  require('./components/date-period-stats/trigger-chain-date-period-stats.module');
  require('./components/message-preview/trigger-chain-message-preview.module');
  require('./components/sending-type-switcher/trigger-chain-sending-type-switcher.module');
  require('./components/working-dynamics/trigger-chain-working-dynamics.module');

  angular.module('myApp.triggerChains.detailedStatistics', [
    'myApp.triggerChains.allTimeStats',
    'myApp.triggerChains.datePeriodStats',
    'myApp.triggerChains.messagePreview',
    'myApp.triggerChains.sendingTypeSwitcher',
    'myApp.triggerChains.workingDynamics',
  ]);

  require('./trigger-chain-detailed-statistics.constants');
  require('./trigger-chain-detailed-statistics.component');
})();

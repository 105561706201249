import { DATA_COLLECTION_TABS } from '../../../app/pages/data-collection/pages/data-collection/data-collection.constants';
import { firstValueFrom } from 'rxjs';
import { TRACK_MASTER_SETTINGS } from '../../../app/http/track-master/track-master.constants';

(function () {
  'use strict';

  angular.module('myApp.trackMaster').config(config).run(run);

  /**
   * Конфигурация роутов мастера сбора данных
   *
   * @param $stateProvider
   */
  function config($stateProvider) {
    $stateProvider
      .state('app.content.trackmaster', {
        url: '/{trackMasterBase:(?:trackmaster|data-collection)}',
        params: {
          // NOTE: пришлось так изгаляться с параметром, т.к. части МСД должны открываться как по ссылке /trackmaster/что-то, так и /data-collection/что-то
          trackMasterBase: 'data-collection',
        },
        redirectTo: function (transition) {
          // при прямом переходе на это состояние, нужно открывать сбор данных о посетителях на вкладке МСД
          return {
            state: 'app.content.dataCollection',
            params: {
              ...transition.params(),
              tab: DATA_COLLECTION_TABS.TRACK_MASTER,
            },
          };
        },
        resolve: {
          // NOTE: части МСД должны открываться как по ссылке /trackmaster/что-то, так и /data-collection/что-то
          stateUrlHack: function ($q, $state, $transition$) {
            // если вместо data-collection в ссылке trackmaster - меняем его
            if ($transition$.params().trackMasterBase === 'data-collection') {
              return $q.resolve();
            } else {
              $transition$.abort(); // отменяем переход на состояние и формируем новое состояние
              // параметры, полученные через $state.transition.params() нельзя менять, они immutable, поэтому создаётся новый объект и туда кладутся все параметры (подробнее тут https://github.com/angular-ui/ui-router/issues/3506#issuecomment-322370709)
              let params = angular.extend({}, $transition$.params(), { trackMasterBase: 'data-collection' });

              $state.go($state.transition.to().name, params);

              return $q.reject('redirection');
            }
          },
        },
        template: require('./track-master.wrapper.html'),
      })

      .state('app.content.trackmaster.details', {
        url: '/{autoEventGroup}',
        redirectTo: 'app.content.trackmaster.details.unconfigured',
        views: {
          header: {
            component: 'cqTrackMasterHeader',
          },
        },
      })

      .state('app.content.trackmaster.details.unconfigured', {
        url: '',
        resolve: {
          autoEvent: getAutoEvent,
          properties: getProperties,
        },
      })

      .state('app.content.trackmaster.details.configured', {
        url: '/{autoEventId:id}',
        resolve: {
          autoEvent: getAutoEvent,
          properties: getProperties,
        },
      })

      .state('app.content.trackmaster.details.unconfigured.' + TRACK_MASTER_SETTINGS.URL, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqBaseAutoEventsWrapper',
          },
          '@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqTrackMasterUrl',
          },
        },
      })

      .state('app.content.trackmaster.details.configured.' + TRACK_MASTER_SETTINGS.URL, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqBaseAutoEventsWrapper',
          },
          '@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqTrackMasterUrl',
          },
        },
      })

      .state('app.content.trackmaster.details.unconfigured.' + TRACK_MASTER_SETTINGS.URL_SCROLL, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              properties: 'properties',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqBaseAutoEventsWrapper',
          },
          '@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqTrackMasterUrlScroll',
          },
        },
      })

      .state('app.content.trackmaster.details.configured.' + TRACK_MASTER_SETTINGS.URL_SCROLL, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              properties: 'properties',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqBaseAutoEventsWrapper',
          },
          '@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqTrackMasterUrlScroll',
          },
        },
      })

      .state('app.content.trackmaster.details.unconfigured.' + TRACK_MASTER_SETTINGS.CLICK, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              properties: 'properties',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqBaseAutoEventsWrapper',
          },
          '@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqTrackMasterClick',
          },
        },
      })

      .state('app.content.trackmaster.details.configured.' + TRACK_MASTER_SETTINGS.CLICK, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              properties: 'properties',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqBaseAutoEventsWrapper',
          },
          '@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqTrackMasterClick',
          },
        },
      })

      .state('app.content.trackmaster.details.unconfigured.' + TRACK_MASTER_SETTINGS.INPUT, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              properties: 'properties',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqBaseAutoEventsWrapper',
          },
          '@.': {
            bindings: {
              autoEvent: 'autoEvent',
              properties: 'properties',
            },
            component: 'cqTrackMasterInput',
          },
        },
      })

      .state('app.content.trackmaster.details.configured.' + TRACK_MASTER_SETTINGS.INPUT, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              properties: 'properties',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
            },
            component: 'cqBaseAutoEventsWrapper',
          },
          '@.': {
            bindings: {
              autoEvent: 'autoEvent',
              properties: 'properties',
            },
            component: 'cqTrackMasterInput',
          },
        },
      })

      .state('app.content.trackmaster.details.unconfigured.' + TRACK_MASTER_SETTINGS.PRO, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              properties: 'properties',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
              properties: 'properties',
            },
            component: 'cqTrackMasterPro',
          },
        },
      })

      .state('app.content.trackmaster.details.configured.' + TRACK_MASTER_SETTINGS.PRO, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              autoEvent: 'autoEvent',
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
              djangoUser: 'djangoUser',
              properties: 'properties',
            },
            component: 'cqAutoEventSetting',
          },
          'setting@.': {
            bindings: {
              autoEvent: 'autoEvent',
              properties: 'properties',
            },
            component: 'cqTrackMasterPro',
          },
        },
      })

      .state('app.content.trackmaster.details.unconfigured.' + TRACK_MASTER_SETTINGS.JS_CODE, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
            },
            component: 'cqJsCode',
          },
        },
      })

      .state('app.content.trackmaster.details.unconfigured.' + TRACK_MASTER_SETTINGS.AUTO_TRACK, {
        views: {
          '@app.content.trackmaster': {
            bindings: {
              billingInfo: 'billingInfo',
              currentApp: 'currentApp',
            },
            component: 'cqAutoTracking',
          },
        },
      });

    /**
     * Получение одного автособытия
     *
     * @param trackMasterModel
     * @param $stateParams
     * @param $state
     * @returns {*|PromiseLike<T>|Promise<T>}
     */
    function getAutoEvent(trackMasterModel, $stateParams, $state) {
      if ($stateParams.autoEventId) {
        return firstValueFrom(trackMasterModel.get($stateParams.autoEventId)).catch(getAutoEventError);
      } else {
        return trackMasterModel.getDefault($stateParams.autoEventGroup);
      }

      function getAutoEventError() {
        // Если запрос завершился ошибкой, скорее всего, автособытия с таким ID нет => надо перенаправить на создание нового
        $state.go(
          'app.content.trackmaster.details.unconfigured' + '.' + $stateParams.autoEventGroup,
          angular.extend({}, $state.transition.params(), { autoEventId: null }),
        );
      }
    }

    /**
     * Получение свойств
     *
     * @param propertyModel
     * @param currentApp
     * @returns {*}
     */
    function getProperties(propertyModel, currentApp) {
      return firstValueFrom(propertyModel.getList(currentApp.id)).then(getPropertiesSuccess);

      function getPropertiesSuccess(properties) {
        return propertyModel.filterPropsForTrackMaster(properties);
      }
    }
  }

  /**
   * Настройка перенаправления на состояния конкретных настройки
   *
   * @param $transitions
   * @param $state
   */
  function run($transitions, $state) {
    $transitions.onEnter({ to: 'app.content.trackmaster.details.unconfigured' }, redirectToSettingState);
    $transitions.onEnter({ to: 'app.content.trackmaster.details.configured' }, redirectToSettingState);

    /**
     * Переход на состояние конкретной настройки
     *
     * @param transition
     * @param state
     */
    function redirectToSettingState(transition, state) {
      return $state.target(transition.to().name + '.' + transition.params().autoEventGroup, transition.params());
    }
  }
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

import { MessageStatusComponent } from './message-status.component';

@NgModule({
  declarations: [MessageStatusComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [MessageStatusComponent],
})
export class MessageStatusModule {}

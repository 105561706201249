(function () {
  'use strict';

  angular.module('myApp.directives.file', []);

  require('./accept/accept.directive');
  require('./file/file.directive');
  require('./file-as-blob/file-as-blob.directive');
  require('./file-as-data-url/file-as-data-url.directive');
  require('./image-max-height/image-max-height.directive');
  require('./image-max-width/image-max-width.directive');
  require('./image-min-height/image-min-height.directive');
  require('./image-min-width/image-min-width.directive');
  require('./max-size/max-size.directive');
})();

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchActionAssistantComponent } from './assistant.component';

@NgModule({
  declarations: [BranchActionAssistantComponent],
  imports: [CommonModule, TranslocoModule, SharedModule, NgSelectModule, ReactiveFormsModule],
  exports: [BranchActionAssistantComponent],
})
export class BranchActionAssistantModule {}

import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Channel } from '@http/channel/channel.model';
import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotAction } from '@http/chat-bot/types/action-internal.types';
import { AmocrmIntegrationExternal } from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { TeamMember } from '@http/team-member/team-member.types';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';
import { Controls, GenericFormGroup } from '@panel/app/shared/abstractions/deprecated/generic-form-group';

export type ActionValidationExtra = {
  amocrmIntegrations: AmocrmIntegrationExternal[];
  channels: Channel[];
  teamMembers: TeamMember[];
};

type BotActionFormData<BodyJson extends object = EMPTY_BODY_JSON> = Pick<
  ChatBotAction<BodyJson>,
  'active' | 'body' | 'keyName' | 'nextBranchLinkId' | 'attachments' | 'bodyJson'
>;

export type EMPTY_BODY_JSON = {};

export type CustomControlsWithBodyJsonRequired<BodyJson extends object = EMPTY_BODY_JSON> = Partial<
  Omit<BotActionControls<BodyJson>, 'bodyJson'>
> &
  Pick<BotActionControls<BodyJson>, 'bodyJson'>;

export type BotActionControls<BodyJson extends object> = Controls<BotActionFormData<BodyJson>>;

export interface BaseBotActionForm<BodyJson extends object = EMPTY_BODY_JSON> {
  /**
   * Вызывается после конструктора
   */
  afterInit?(): void;
}

export abstract class BaseBotActionForm<BodyJson extends object = EMPTY_BODY_JSON> extends GenericFormGroup<
  BotActionFormData<BodyJson>
> {
  nextBranchId: string | null = null;

  prettyKeyName: string | null = null;

  integrationName: string | null = null;

  type: CHAT_BOT_ACTIONS_TYPES;

  destroy$: Subject<void>;

  constructor(
    action: ChatBotAction,
    validationExtra: ActionValidationExtra,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super({} as any, validatorOrOpts, asyncValidator);

    const controls = this.getControls(action, validationExtra);
    Object.entries(controls).forEach(([controlName, control]) => {
      this.addControl(controlName, control);
    });

    this.integrationName = action.integrationName ?? null;
    this.prettyKeyName = action.prettyKeyName ?? null;
    this.nextBranchId = action.nextBranchId ?? null;
    this.type = action.type;
    this.destroy$ = new Subject();

    if (this.afterInit) {
      this.afterInit();
    }
  }

  private getControls(action: ChatBotAction, validationExtra: ActionValidationExtra): BotActionControls<BodyJson> {
    const customControls = this.getCustomControls(action, validationExtra);
    return {
      active: new GenericFormControl(action.active || false),
      body: new GenericFormControl(action.body || null),
      keyName: new GenericFormControl(action.keyName || null),
      nextBranchLinkId: new GenericFormControl(action.nextBranchLinkId ?? null),
      attachments: new GenericFormControl(action.attachments ?? []),
      ...customControls,
    };
  }

  /**
   * Заменять какие-либо контролы с другими валидациями либо добавлять новые
   */
  abstract getCustomControls(
    action: ChatBotAction,
    validationExtra: ActionValidationExtra,
  ): CustomControlsWithBodyJsonRequired<BodyJson>;

  revalidate(touch: boolean = true) {
    if (touch) {
      this.markAllAsTouched();
    }
    this.get('active').updateValueAndValidity();
    this.get('body').updateValueAndValidity();
    this.get('bodyJson').updateValueAndValidity();
    this.get('attachments').updateValueAndValidity();
    this.get('nextBranchLinkId').updateValueAndValidity();
    this.get('keyName').updateValueAndValidity();
  }

  public destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Показывает, были ли touched все controls этого action
   */
  abstract get allTouchedChanges$(): Observable<boolean>;

  /**
   * Лучше всего понять по использованию, вот кейс:
   * Я хочу показать ошибку на ветке в канвасе только если все поля "touched", и при этом среди них есть невалидные.
   */
  get allTouchedAndInvalidChanges$(): Observable<[touched: boolean, invalid: boolean]> {
    return combineLatest([
      this.allTouchedChanges$,
      this.statusChanges.pipe(
        startWith(this.status),
        map((status) => status === 'INVALID'),
      ),
    ]);
  }
}

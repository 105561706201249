import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { KnowledgeBaseModel } from '@http/knowledge-base/knowledge-base.model';
import { DestroyService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { BillingPlanService } from '@panel/app/services/billing-plan/billing-plan.service';
import { domainPartValidator } from '@panel/app/shared/validators/domain-part/domain-part';
import { googleIdValidator } from '@panel/app/shared/validators/google-id/google-id-validator';
import { integerValidator } from '@panel/app/shared/validators/integer/integer-validator';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { FileHelperService } from '@panel/app-old/shared/services/file-helper/file-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';

@Component({
  selector: 'cq-knowledge-base-settings-modal',
  templateUrl: './knowledge-base-settings-modal.component.html',
  styleUrls: ['./knowledge-base-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class KnowledgeBaseSettingsModalComponent implements OnInit {
  /** Параметры, передаваемые в модальное окно */
  _modalWindowParams: any;

  @Input() set modalWindowParams(value: any) {
    this._modalWindowParams = value;

    this.knowledgeBaseForm.setValue(
      {
        isPersonalDomain: value.settings.isPersonalDomain,
        personalDomain: value.settings.isPersonalDomain ? value.settings.domain : '',
        carrotDomain: value.settings.isPersonalDomain ? '' : value.settings.domain,
        logo: value.settings.logo,
        googleAnalytics: value.settings.googleAnalytics,
        yandexMetrika: value.settings.yandexMetrika,
        noPoweredBy: value.settings.noPoweredBy,
      },
      { emitEvent: false },
    );
  }

  get modalWindowParams(): any {
    return this._modalWindowParams;
  }

  /**
   * Поля формы
   *
   * @type {Object<String>}
   */
  protected FIELDS: { [key: string]: string } = {
    CARROT_DOMAIN: 'carrotDomain',
    GOOGLE_ANALYTICS: 'googleAnalytics',
    LOGO: 'logo',
    PERSONAL_DOMAIN: 'personalDomain',
    WHITE_LABEL: 'whiteLabel',
    YANDEX_METRIKA: 'yandexMetrika',
  };

  /**
   * Максимальная длинна домена
   *
   * @type {number}
   */
  protected MAX_DOMAIN_LENGTH: number = 100;

  /**
   * Максимальная длинна яндекс метирки
   *
   * @type {number}
   */
  protected MAX_YANDEX_METRIKA_LENGTH: number = 25;

  /**
   * Максимальная длинна Google Tag manager ID
   *
   * @type {number}
   */
  protected MAX_GOOGLE_TAG_LENGTH: number = 25;

  /**
   * Максимальный размер логотипа (1мб)
   *
   * @type {number}
   */
  protected LOGO_MAX_SIZE: number = 1024 * 1024;

  /**
   * Вкладки
   *
   * @type {{STANDARD: String, ADVANCED: String}}
   */
  protected TABS: { STANDARD: string; ADVANCED: string } = {
    STANDARD: 'standard',
    ADVANCED: 'advanced',
  };

  protected accessToKnowledgeBasePro: any;
  protected activeTab: any = this.TABS.STANDARD;
  protected carrotDomain: any = ''; // Домен Carrot quest;
  protected isApiRequestPerformed: boolean = false; // Флаг выполнения запроса;
  protected newLogo: any = {
    file: null, // файл
    preview: null, // превью
  };
  protected personalDomain: any = ''; // Собственный домен;
  protected PROJECT_NAME = environment.projectName;
  protected USER_FILES_URL = environment.userFilesUrl;

  protected knowledgeBaseForm = this.fb.group({
    isPersonalDomain: this.fb.control(false),
    personalDomain: this.fb.control('', [Validators.maxLength(this.MAX_DOMAIN_LENGTH)]),
    carrotDomain: this.fb.control('', [
      Validators.required,
      Validators.maxLength(this.MAX_DOMAIN_LENGTH),
      domainPartValidator,
    ]),
    logo: this.fb.control('', []),
    googleAnalytics: this.fb.control('', [Validators.maxLength(this.MAX_GOOGLE_TAG_LENGTH), googleIdValidator]),
    yandexMetrika: this.fb.control('', [Validators.maxLength(this.MAX_YANDEX_METRIKA_LENGTH), integerValidator]),
    noPoweredBy: this.fb.control(''),
  });

  constructor(
    protected readonly destroy$: DestroyService,
    protected readonly activeModal: NgbActiveModal,
    protected readonly fb: FormBuilder,
    protected readonly billingPlanService: BillingPlanService,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly fileHelper: FileHelperService,
    protected readonly knowledgeBaseModel: KnowledgeBaseModel,
    protected readonly l10nHelper: L10nHelperService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly translocoService: TranslocoService,
    protected readonly paywallService: PaywallService,
  ) {}

  ngOnInit(): void {
    this.accessToKnowledgeBasePro = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.KNOWLEDGE_BASE_PRO,
      this.modalWindowParams.currentApp,
    );

    if (this.modalWindowParams.settings.isPersonalDomain) {
      this.personalDomain = this.modalWindowParams.settings.domain;
      this.knowledgeBaseForm.controls.carrotDomain.removeValidators(Validators.required);
      this.knowledgeBaseForm.controls.carrotDomain.updateValueAndValidity();
    } else {
      this.carrotDomain = this.modalWindowParams.settings.domain;
      this.knowledgeBaseForm.controls.carrotDomain.addValidators(Validators.required);
      this.knowledgeBaseForm.controls.carrotDomain.updateValueAndValidity();
    }

    this.knowledgeBaseForm.controls.isPersonalDomain.valueChanges.subscribe((value) => {
      if (value) {
        this.knowledgeBaseForm.controls.carrotDomain.removeValidators(Validators.required);
        this.knowledgeBaseForm.controls.carrotDomain.updateValueAndValidity();
      } else {
        this.knowledgeBaseForm.controls.carrotDomain.addValidators(Validators.required);
        this.knowledgeBaseForm.controls.carrotDomain.updateValueAndValidity();
      }
    });

    this.knowledgeBaseForm.valueChanges.subscribe((value) => {
      this.modalWindowParams.settings = {
        ...this.modalWindowParams.settings,
        ...value,
      };
    });
  }

  /**
   * Проверка валидности ключа гугл аналитики
   *
   * @return {boolean}
   */
  isKeyGoogleAnalyticsOld(): boolean {
    const isGoogleAnalyticsAdded =
      !!this.modalWindowParams.settings.googleAnalytics && this.modalWindowParams.settings.googleAnalytics.length > 0;
    return isGoogleAnalyticsAdded && /^(UA-).+/i.test(this.modalWindowParams.settings.googleAnalytics);
  }

  /**
   * Сохранение настроек
   *
   * @param isValid - Валидность формы
   */
  save(isValid: any): void {
    if (isValid) {
      this.isApiRequestPerformed = true;

      this.modalWindowParams.settings.domain = this.knowledgeBaseForm.controls.isPersonalDomain.getRawValue()
        ? this.knowledgeBaseForm.controls.personalDomain.getRawValue()
        : this.knowledgeBaseForm.controls.carrotDomain.getRawValue();
      this.modalWindowParams.settings.logo = this.newLogo.preview
        ? this.knowledgeBaseForm.controls.logo.getRawValue()
        : this.modalWindowParams.settings.logo;

      this.knowledgeBaseModel
        .save(this.modalWindowParams.currentApp, this.modalWindowParams.settings)
        .pipe(
          takeUntil(this.destroy$),
          catchError((response: any) => {
            if (response.meta && response.meta.error === 'DomainAlreadyRegisteredError') {
              this.knowledgeBaseForm.controls.personalDomain.setErrors({
                apiMessageDomainAlreadyRegisteredError: true,
              });
              this.knowledgeBaseForm.controls.personalDomain.markAsTouched();
            }
            this.isApiRequestPerformed = false;
            return of();
          }),
        )
        .subscribe(() => {
          this.knowledgeBaseModel
            .get(this.modalWindowParams.currentApp.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((settings: any) => {
              this.knowledgeBaseForm.controls.personalDomain.setErrors({
                apiMessageDomainAlreadyRegisteredError: false,
              });
              this.knowledgeBaseForm.controls.personalDomain.markAsUntouched();

              this.trackSave();
              this.activeModal.close(settings);

              this.isApiRequestPerformed = false;
            });

          this.isApiRequestPerformed = false;
        });
    } else {
      this.moveToErrorTab();
    }
  }

  /**
   * Получение стоимости аддона
   *
   * @param addOnId ID аддона
   * @return Отформатированная стоимость аддона
   */
  getAddOnFormattedPrice(addOnId: any): string {
    const parsedAddOnPrice: string = this.billingPlanService.getAddOnFormattedPrice(
      addOnId,
      this.modalWindowParams.currentApp,
    );
    return parsedAddOnPrice;
  }

  /**
   * Получение полей формы по вкладкам
   */
  getFieldsByTabs(): any {
    let fieldsByTabs: any = {};

    fieldsByTabs[this.TABS.STANDARD] = [this.FIELDS.PERSONAL_DOMAIN, this.FIELDS.CARROT_DOMAIN, this.FIELDS.LOGO];
    fieldsByTabs[this.TABS.ADVANCED] = [this.FIELDS.GOOGLE_ANALYTICS, this.FIELDS.WHITE_LABEL];

    if (!this.l10nHelper.isUsCountry()) {
      fieldsByTabs[this.TABS.ADVANCED].push(this.FIELDS.YANDEX_METRIKA);
    }

    return fieldsByTabs;
  }

  /**
   * Переключение на вкладку с ошибкой
   */
  moveToErrorTab(): void {
    if (!this.hasTabError(this.activeTab)) {
      this.activeTab = this.activeTab === this.TABS.STANDARD ? this.TABS.ADVANCED : this.TABS.STANDARD;
    }
  }

  /**
   * Есть ли на вкладке ошибка
   *
   * @param tab
   *
   * @return {boolean}
   */
  private hasTabError(tab: any): boolean {
    if (tab === this.TABS.STANDARD) {
      return (
        this.knowledgeBaseForm.controls.isPersonalDomain.invalid ||
        this.knowledgeBaseForm.controls.personalDomain.invalid ||
        this.knowledgeBaseForm.controls.carrotDomain.invalid ||
        this.knowledgeBaseForm.controls.logo.invalid
      );
    }

    if (tab === this.TABS.ADVANCED) {
      return (
        this.knowledgeBaseForm.controls.googleAnalytics.invalid ||
        this.knowledgeBaseForm.controls.yandexMetrika.invalid ||
        this.knowledgeBaseForm.controls.noPoweredBy.invalid
      );
    }

    return false;
  }

  /**
   * Написать в чат
   */
  sendChatMessage(): void {
    this.carrotquestHelper.sendChatMessage(
      this.translocoService.translate(
        'modals.knowledgeBaseSettings.knowledgeBaseForm.changeLogo.input.changeDomainChatMessage',
      ),
    );
  }

  /**
   * Трек сохранения настроек
   */
  trackSave(): void {
    this.carrotquestHelper.track('База знаний - применил настройки', {
      'Тип домена': this.modalWindowParams.settings.isPersonalDomain ? 'собственный домен ' : 'домен carrot quest',
    });
  }

  /**
   * Трек сохранения настроек
   */
  trackChangeLogo(): void {
    this.carrotquestHelper.track('База знаний - загрузил новый логотип');
  }

  /**
   * Открытие модалки с выбором тарифа для Базы знаний
   */
  openBillingModal(): void {
    this.paywallService.showPaywall(this.modalWindowParams.currentApp, this.accessToKnowledgeBasePro.denialReason);
  }
}

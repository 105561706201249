import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { mergeMap, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { AppService } from '@http/app/services/app.service';
import { EXCEPT_PARSE_FIELD, INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { IntegrationFactory } from '@http/integration/factories/integration.factory';
import { Integration } from '@http/integration/integration';
import { ExtractIntegration, IntegrationInstance } from '@http/integration/integration.types';
import { TELEGRAM_API_ERRORS } from '@http/integration/integrations/telegram/constants/telegram-integration.constants';
import {
  VkIntegrationGroupExternal,
  VkIntegrationSettingsExternal,
} from '@http/integration/integrations/vk/interfaces/vk-integration.interfaces';
import { VkIntegration } from '@http/integration/integrations/vk/vk-integration';
import { IntegrationExternal } from '@http/integration/interfaces/integration.interfaces';
import {
  IntegrationAutoMessage,
  OAuthUrlApiResponse,
  VkUserGroupsApiResponse,
} from '@http/integration/types/integration.types';
import { APIError, APIResponse } from '@http/types';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import {
  IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS,
  IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS,
} from '@panel/app/shared/constants/http.constants';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { ApiLeadBotResponse } from '@http/chat-bot/types/chat-bot-external.types';

type APIErrorTelegram = APIError<string, string> & {
  integrationId: number;
};

/** Сервис для работы с интеграциями */
@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  constructor(
    private readonly appService: AppService,
    @Inject(HttpClient) private http: HttpClient,
    @Inject(IntegrationFactory) private integrationFactory: IntegrationFactory,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    @Inject(TranslocoService) private readonly translocoService: TranslocoService,
    private readonly toastService: ToastService,
  ) {}

  //По-хорошему надо сделать абстрактный класс с дефолтным набором функций
  //И для каждой интеграции наследоваться от класса и реализовывать метод
  //Т.к. пока на это нет времени, то сделано так
  printErrorsTelegram(error: APIErrorTelegram) {
    if (error?.error === TELEGRAM_API_ERRORS.INTERGRATION_ALREADY_EXISTS) {
      const textError = this.translocoService.translate('telegram.errors.integrationAlreadyExists', {
        url: `${environment.panelUrl}/${this.appService.currentAppId}/integrations/telegram/${error.integrationId}`,
      });
      this.toastService.danger(textError);
    }
  }

  /**
   * Создание интеграции
   *
   * @param appId - Id приложения
   * @param integration - Инстанс интеграции
   */
  create<T extends Integration>(appId: string, integration: T): Observable<T> {
    const params = {
      ...integration.externalFormat,
      app: appId,
    };

    // Указываем поля, которые интерцептор не должен преобразовывать во внешний формат, если они есть
    let requestOptions = {};

    if (EXCEPT_PARSE_FIELD[integration.type]?.INTERNAL.length) {
      requestOptions = {
        context: new HttpContext().set(
          IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS,
          EXCEPT_PARSE_FIELD[integration.type].INTERNAL,
        ),
      };
    }

    return this.http.post<IntegrationExternal>(`/integrations`, params, requestOptions).pipe(
      map((response) => {
        return this.integrationFactory.create(response.type, response);
      }),
      catchError((err) => {
        this.printErrorsTelegram(err);
        throw err;
      }),
    );
  }

  /**
   * Получение доступа до типа интеграции
   *
   * @param integrationType - Тип интеграции
   * @param currentApp - Текущее приложение
   */
  getAccessToIntegrationType(integrationType: INTEGRATION_TYPES, currentApp: App): ProductFeatureAccess {
    switch (integrationType) {
      case INTEGRATION_TYPES.MIXPANEL:
      case INTEGRATION_TYPES.ROISTAT:
        return this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS_ANALYTICS, currentApp);
      case INTEGRATION_TYPES.AMOCRM:
      case INTEGRATION_TYPES.BITRIX24:
      case INTEGRATION_TYPES.RETAILCRM:
        return this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS_CRM, currentApp);
      case INTEGRATION_TYPES.JSSCRIPT:
        return this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS_JSSCRIPT, currentApp);
      case INTEGRATION_TYPES.WEBHOOK:
        return this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS_WEBHOOK, currentApp);
      case INTEGRATION_TYPES.EMAIL_NOTIFICATION:
        return this.planFeatureAccessService.getAccess(PLAN_FEATURE.INTEGRATIONS_EMAIL_NOTIFICATION, currentApp);
      default:
        return {
          hasAccess: true,
          denialReason: null,
        };
    }
  }

  /**
   * Получение OAuth Url для авторизации в интеграциях
   *
   * @param integrationType - Тип интеграции, для которой необходимо получить OAuth Url
   * @param integrationId - Id интеграции, для которой необходимо получить OAuth Url
   */
  getOAuthUrl(
    integrationType: INTEGRATION_TYPES,
    integrationId: string | undefined | null,
  ): Observable<OAuthUrlApiResponse> {
    const params: any = {};

    if (integrationId) {
      params['integration'] = integrationId;
    }

    return this.http.get<OAuthUrlApiResponse>(`/integrations/${integrationType}/oauth_url`, {
      params,
    });
  }

  /**
   * Получение списка триггерных сообщений, которые используют интеграция «Уведомление на Email»
   *
   * @param integrationId ID интеграции
   * @param appId ID приложения
   */
  emailNotificationGetAutoMessages(integrationId: string, appId: string): Observable<IntegrationAutoMessage[]> {
    return this.getAutoMessages(INTEGRATION_TYPES.EMAIL_NOTIFICATION, integrationId, appId);
  }

  /**
   * Получение списка чат-ботов, которые используют интеграцию «Уведомление на Email»
   *
   * @param integrationId ID интеграции
   * @param appId ID приложения
   */
  emailNotificationGetChatBots(integrationId: string, appId: string): Observable<ApiLeadBotResponse[]> {
    return this.getChatBots<ApiLeadBotResponse[]>(INTEGRATION_TYPES.EMAIL_NOTIFICATION, integrationId, appId);
  }

  /**
   * Получение интеграции по ID
   *
   * @param id ID интеграции
   * @param type Тип интеграции
   */
  get<T extends ExtractIntegration>(id: string, type: T): Observable<IntegrationInstance<T>> {
    // Указываем поля, которые интерцептор не должен преобразовывать во внутренний формат
    const options = {
      context: new HttpContext().set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, EXCEPT_PARSE_FIELD[type].EXTERNAL),
    };

    return this.http
      .get<IntegrationExternal>(`/integrations/${type}/${id}`, options)
      .pipe(map((response) => this.integrationFactory.create(type, response)));
  }

  /**
   * Получение списка интеграций по типу
   */
  getAllByType<T extends IntegrationExternal>(type: INTEGRATION_TYPES): Observable<T[]> {
    const params = { app: this.appService.currentAppId };
    return this.http.get<T[]>(`/integrations/${type}`, { params });
  }

  /** Получение переведённого названия типа интеграции */
  getTranslatedIntegrationType(integrationType: INTEGRATION_TYPES): string {
    return this.translocoService.translate(`models.integration.types.${integrationType}.name`);
  }

  /**
   * Получение списка триггерных сообщений, которые используют текущую интеграцию
   *
   *
   * @param integrationType Тип интеграции
   * @param integrationId ID интеграции
   * @param appId ID текущего приложения
   */
  getAutoMessages(
    integrationType: INTEGRATION_TYPES,
    integrationId: string,
    appId: string,
  ): Observable<IntegrationAutoMessage[]> {
    const params = {
      app: appId,
    };
    return this.http
      .get<APIResponse<IntegrationAutoMessage[]>>(`/integrations/${integrationType}/${integrationId}/messages`, {
        params,
      })
      .pipe(map((response) => response.data));
  }

  /**
   * Получение списка чат-ботов, которые используют текущую интеграцию
   *
   *
   * @param integrationType Тип интеграции
   * @param integrationId ID интеграции
   * @param appId ID текущего приложения
   */
  getChatBots<T>(integrationType: INTEGRATION_TYPES, integrationId: string, appId: string): Observable<T> {
    const params = {
      app: appId,
    };
    return this.http
      .get<APIResponse<T>>(`/integrations/${integrationType}/${integrationId}/chat_bots`, { params: params })
      .pipe(map((response) => response.data));
  }

  /**
   * Удаление интеграции
   *
   * @param id ID интеграции
   * @param type Тип интеграции
   */
  remove(id: string, type: INTEGRATION_TYPES): Observable<void> {
    return this.http.delete<void>(`/integrations/${type}/${id}`);
  }

  /**
   * Сохранение интеграции из внутреннего формата интеграций
   *
   * @param appId ID приложения
   * @param integration Интеграция
   */
  save<T extends Integration>(appId: string, integration: T): Observable<T> {
    return this.saveFromJson(appId, integration.externalFormat).pipe(
      map((response) => {
        return this.integrationFactory.create(response.type, response);
      }),
    );
  }

  /**
   * Сохранение интеграции, но в параметрах уже externalFormat тип интеграции
   */
  saveFromJson<T extends IntegrationExternal>(appId: string, integration: T): Observable<T> {
    const params = {
      ...integration,
      app: appId,
    };

    // Указываем поля, которые интерцептор не должен преобразовывать во внешний формат, если они есть
    let requestOptions = {};
    if (EXCEPT_PARSE_FIELD[integration.type]?.INTERNAL.length) {
      requestOptions = {
        context: new HttpContext().set(
          IGNORE_REQUEST_BODY_CASE_TRANSFORM_FIELDS,
          EXCEPT_PARSE_FIELD[integration.type].INTERNAL,
        ),
      };
    }

    return this.http.put<T>(`/integrations/${integration.type}/${integration.id}`, params, requestOptions).pipe(
      catchError((err) => {
        this.printErrorsTelegram(err);
        throw err;
      }),
    );
  }

  /**
   * Изменение состояния интеграции "Активная|Приостановленная"
   *
   * @param integrationExternal - Интеграция во внешнем формате
   */
  toggleState(integrationExternal: IntegrationExternal): Observable<IntegrationExternal> {
    const params = {
      active: !integrationExternal.active,
    };

    return this.http
      .patch<IntegrationExternal>(`/integrations/${integrationExternal.type}/${integrationExternal.id}`, params)
      .pipe(
        catchError((err) => {
          this.printErrorsTelegram(err);
          throw err;
        }),
      );
  }

  /**
   * Получение oAuth URL на запрос доступа к группам в ВК
   *
   * @param integrationId ID интеграции
   * @param groups ID сообществ, для которых запрашивается авторизация
   */
  vkGetGroupsOAuthUrl(integrationId: string, groups: number[]): Observable<OAuthUrlApiResponse> {
    const params = {
      groups_ids: JSON.stringify(groups),
    };
    return this.http.get<OAuthUrlApiResponse>(`/integrations/vk/${integrationId}/oauth_groups_url`, { params });
  }

  /**
   * Получение списка групп в VK, которые администрирует пользователь
   *
   * @param integrationId ID интеграции
   */
  vkGetUserGroups(integrationId: string | null): Observable<VkIntegrationGroupExternal[]> {
    return this.http
      .get<VkUserGroupsApiResponse>(`/integrations/vk/${integrationId}/user_groups`, {})
      .pipe(map((data) => data.groups));
  }

  /**
   * Подписать группы
   *
   * @param integrationId
   * @param groupsIdToSubscribe
   */
  vkSubscribe(integrationId: string, groupsIdToSubscribe: number[]): Observable<VkIntegrationSettingsExternal> {
    return this.http.post<VkIntegrationSettingsExternal>(`/integrations/vk/${integrationId}/subscribe`, {
      groups_ids: groupsIdToSubscribe,
    });
  }

  /**
   * Сохранение интеграции с ВК
   *
   * NOTE
   *  Перед сохранением интеграции ВК, надо отдельно выполнить запрос на подписку групп
   *  Это надо сделать чтобы бек синхронно создал сервера для групп, в случае их отсутствия
   *
   * @param appId - ID аппа
   * @param integration - Интеграция
   */
  vkSave(appId: string, integration: VkIntegration): Observable<VkIntegration> {
    if (!integration.id) {
      throw new Error('You have to create an integration before subscribing');
    }
    const groupToSubscribe = integration.groupsForSubscribing.map((group) => group.groupId || group.id);

    return this.vkSubscribe(integration.id, groupToSubscribe).pipe(
      mergeMap((settingsResponse) => {
        integration.updateSettings(settingsResponse, true);
        return this.save(appId, integration);
      }),
    );
  }
}

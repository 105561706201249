/**
 * Контроллер для модального окна удаления сообщения из диалога
 */
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('myApp.modals.removeConversationPart')
    .controller('CqRemoveConversationPartController', CqRemoveConversationPartController);

  function CqRemoveConversationPartController(conversationPartModel) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.isApiRequestPerformed = false; // Флаг выполнения запроса
      vm.removeConversationPart = removeConversationPart;
    }

    function removeConversationPart() {
      vm.isApiRequestPerformed = true;

      firstValueFrom(conversationPartModel.remove(vm.resolve.currentApp.id, vm.resolve.activeConversationParts[0].id))
        .then(removeConversationPartSuccess)
        .finally(removeConversationPartFinally);

      function removeConversationPartFinally() {
        vm.isApiRequestPerformed = false;
      }

      function removeConversationPartSuccess(response) {
        vm.modalInstance.close();
      }
    }
  }
})();

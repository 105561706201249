import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { MessageEditorCollapseComponent } from './message-editor-collapse.component';

@NgModule({
  declarations: [MessageEditorCollapseComponent],
  exports: [MessageEditorCollapseComponent],
  imports: [CommonModule, NgbCollapseModule, TranslocoModule],
})
export class MessageEditorCollapseModule {}

(function () {
  'use strict';

  angular.module('myApp.trackMaster').component('cqTrackMasterModalUrlScroll', cqTrackMasterModalUrlScroll());

  function cqTrackMasterModalUrlScroll() {
    return {
      bindings: {
        autoEvent: '=', // Автособытие
      },
      controller: angular.noop,
      controllerAs: 'vm',
      template: require('./track-master-url-scroll.html'),
    };
  }
})();

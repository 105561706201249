<form novalidate>
  <div class="card">
    <div class="card-body">
      <h2>{{ 'firebaseKey.title' | transloco }}</h2>
      <div
        class="margin-bottom-15"
        [innerHTML]="'firebaseKey.description' | transloco: { projectName: projectName }"
      ></div>
      <div class="flex align-items-center margin-bottom-15">
        <label class="btn btn-primary margin-right-15">
          <span *ngIf="!keyExist">{{ 'firebaseKey.firebaseKeyForm.keyDoesntExist.chooseFileButton' | transloco }}</span>
          <span *ngIf="keyExist">{{ 'firebaseKey.firebaseKeyForm.keyExist.chooseFileButton' | transloco }}</span>
          <!-- требование дизайна - показывать ошибки тостами, в том числе ошибки валидации. Поэтому allowInvalid: true, чтобы значение ng-model менялось и отрабатывал $watch в контроллере -->
          <input
            [formControl]="firebaseKeyForm.controls.key"
            class="hidden"
            type="file"
            accept=".json"
            required
            cqFile
            (change)="sendFirebaseKey()"
          />
        </label>
        <div
          *ngIf="!keyExist"
          class="text-secondary"
        >
          {{ 'firebaseKey.firebaseKeyForm.keyDoesntExist.description' | transloco }}
        </div>
        <div
          *ngIf="keyExist"
          class="text-success"
        >
          {{
            'firebaseKey.firebaseKeyForm.keyExist.description'
              | transloco
                : { firebaseProjectName: currentApp.settings.messenger_firebase_account_credentials_project_id }
          }}
        </div>
      </div>
      <a
        class="margin-right-20"
        target="_blank"
        [href]="'firebaseKey.serverKeyLink.href' | transloco"
        >{{ 'firebaseKey.serverKeyLink.text' | transloco }}</a
      >
      <a
        target="_blank"
        [href]="'firebaseKey.setFirebaseAppLink.href' | transloco"
        >{{ 'firebaseKey.setFirebaseAppLink.text' | transloco }}</a
      >
    </div>
  </div>
</form>

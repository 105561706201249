import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';

import { AmocrmIntegration } from '@http/integration/integrations/amo/amocrm-integration';
import {
  AmocrmPipeline,
  AmocrmPipelineModified,
} from '@http/integration/integrations/amo/interfaces/amocrm-integration.interfaces';
import { AmocrmService } from '@http/integration/integrations/amo/services/amocrm.service';
import { IntegrationService } from '@http/integration/services/integration.service';
import { INTEGRATION_TYPES } from '@http/integration/constants/integration.constants';
import { DestroyService } from '@panel/app/services';
import { GenericFormControl } from '@panel/app/shared/abstractions/deprecated/generic-form-control';

/**
 * Компонент для работы со статусом сделки при ёё создании в AmoCRM
 */

@Component({
  selector: 'cq-amocrm-pipeline[controlLeadDefaultPipeline][controlLeadDefaultStatus][integrationId]',
  templateUrl: './amocrm-pipeline.component.html',
  styleUrls: ['./amocrm-pipeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AmocrmPipelineComponent implements OnInit {
  /** ID воронки, в которую будет добавлена сделка */
  @Input()
  controlLeadDefaultPipeline!: GenericFormControl<AmocrmIntegration['settings']['leadDefaultPipeline']>;

  /** Контрол для ID статуса сделки в amoCRM, с которым сделка будет создана */
  @Input()
  controlLeadDefaultStatus!: GenericFormControl<AmocrmIntegration['settings']['leadDefaultStatus']>;

  /** ID текущей интеграции */
  @Input()
  integrationId!: string | null;

  /** Воронки amoCRM, отфильтрованные по полю isEditable */
  filteredPipelines: AmocrmPipelineModified[] = [];

  /** Воронки amoCRM + соответствующие статусам события в Carrot quest/Dashly */
  pipelines: AmocrmPipelineModified[] = [];

  /** Выбранный статус сделки */
  selectedPipeline!: AmocrmPipelineModified;

  /** Получение переведённого названия типа интеграции */
  get integrationTypeName(): string {
    return this.integrationService.getTranslatedIntegrationType(INTEGRATION_TYPES.AMOCRM);
  }

  constructor(
    @Inject(AmocrmService) private readonly amocrmService: AmocrmService,
    @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
    @Inject(DestroyService) private readonly destroy$: DestroyService,
    @Inject(IntegrationService) private readonly integrationService: IntegrationService,
  ) {}

  ngOnInit(): void {
    this.getPipelinesIntegration();
  }

  /**
   * Так как значения в списке выводятся не просто по имени, а через "label", искать нужно тоже по нему
   */
  findPipeline(term: string, item: AmocrmPipelineModified): boolean {
    const label = this.getHumanizedPipelineName(item);
    return label.toLowerCase().search(term.toLowerCase()) > -1;
  }

  /**
   * Получение отформатированного названия статуса сделки
   *
   * @param pipeline - Статус сделки
   */
  getHumanizedPipelineName(pipeline: AmocrmPipeline): string {
    return pipeline.name + ': ' + pipeline.statusName;
  }

  /** Получение статусов сделки */
  getPipelinesIntegration(): void {
    this.amocrmService
      .getPipelinesIntegration(this.integrationId!)
      .pipe(
        takeUntil(this.destroy$),
        map<AmocrmPipeline[], AmocrmPipelineModified[]>((pipelines: AmocrmPipeline[]) => {
          return pipelines.reduce((newArr: AmocrmPipelineModified[], pipeline) => {
            newArr.push({
              ...pipeline,
              additionalId: `${pipeline.id}-${pipeline.statusId}`,
            });

            return newArr;
          }, []);
        }),
      )
      .subscribe((response) => {
        this.pipelines = response;
        this.filteredPipelines = this.pipelines.filter((pipeline) => pipeline.isEditable);

        this.selectedPipeline = this.getSelectedPipeline(this.filteredPipelines);

        this.cdr.detectChanges();
      });
  }

  /**
   * Получение выбранного статуса сделки
   *
   * @param pipelines - Статус сделки
   */
  getSelectedPipeline(pipelines: AmocrmPipelineModified[]): AmocrmPipelineModified {
    return pipelines.filter((pipeline) => {
      return (
        this.controlLeadDefaultPipeline.value === pipeline.id &&
        this.controlLeadDefaultStatus.value === pipeline.statusId
      );
    })[0];
  }

  /**
   * Обработчик изменения select'а
   *
   * @param $event - Объект с выбранным статусом сделки
   */
  onChangeSelect($event: AmocrmPipelineModified): void {
    this.controlLeadDefaultPipeline.setValue($event.id);
    this.controlLeadDefaultStatus.setValue($event.statusId);
  }
}

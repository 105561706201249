(function () {
  'use strict';

  require('./chat-operators-status.controller');

  angular.module('myApp.chatSettings').component('cqChatOperatorsStatus', cqChatOperatorsStatus());

  function cqChatOperatorsStatus() {
    return {
      bindings: {
        onlineStatus: '=', // Текст сообщения в рабочее время
        offlineStatus: '=', // Текст сообщения в нерабочее время
        showStatus: '=', // Отображение статуса
      },
      controller: 'CqChatOperatorsStatusController',
      controllerAs: 'vm',
      template: require('./chat-operators-status.html'),
    };
  }
})();

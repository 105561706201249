<ng-container *transloco="let t; read: 'albatoAuthTokenComponent'">
  <div class="input-group">
    <input
      class="form-control"
      disabled
      type="text"
      placeholder="{{ t('input.placeholder') }}"
    />
    <span class="input-group-append">
      <button
        class="btn btn-primary"
        (click)="onClickButton()"
        type="button"
        data-test="albato-auth-token-button"
      >
        <i class="btn-icon-left cqi-sm cqi-plus"></i>
        <span>{{ t('button.text') }}</span>
      </button>
    </span>
  </div>
</ng-container>

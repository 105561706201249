/**
 * Контроллер Send manual message
 */
import { EMAIL_KARMA_STATUSES } from '../../../../../app/http/email-karma/email-karma.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.modals.sendManualMessage').controller('CqKarmaHintController', CqKarmaHintController);

  function CqKarmaHintController(caseStyleHelper, emailKarmaModel) {
    var vm = this;
    vm.$onInit = init;

    function init() {
      vm.EMAIL_KARMA_STATUSES = EMAIL_KARMA_STATUSES; // константа со всеми статусами
      vm.imageSrc = null; // Картинка статуса
      vm.karma = {}; // Хранит следующие данные: статус кармы, путь к переводам тултипа, процент bounce, количество жалоб
      vm.openChat = openChat;

      getEmailKarma(vm.currentAppId);
    }

    /**
     * Получает данные по карме и составляет объект с нужными данными
     *
     * @param {String} appId ID приложения
     */
    function getEmailKarma(appId) {
      firstValueFrom(emailKarmaModel.getEmailKarma(appId)).then(getEmailKarmaSuccess);

      function getEmailKarmaSuccess(emailKarma) {
        vm.karma = emailKarma;
        vm.karma.worstMetrics.status = caseStyleHelper.toCamelCase(vm.karma.worstMetrics.status);
        vm.karma.worstMetrics.type = caseStyleHelper.toCamelCase(vm.karma.worstMetrics.type);
        vm.imageSrc = getImageSrc(emailKarma.worstMetrics.status);

        if (vm.karma.worstMetrics.status) {
          vm.onSuccess && vm.onSuccess(vm.karma.worstMetrics.status);
        }
      }
    }

    /**
     * Получение пути до картинки статуса кармы в зависимости от статуса
     *
     * @param status
     * @return {string}
     */
    function getImageSrc(status) {
      var urlPart = caseStyleHelper.toKebabCase(status);
      return 'assets/img/default/send-manual-message/karma-status.svg'.replace('karma-status', urlPart);
    }

    /**
     * Открытие чата
     */
    function openChat() {
      carrotquestHelper.open();
    }
  }
})();

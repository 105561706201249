(function () {
  'use strict';

  angular.module('myApp.trackMaster').component('cqBaseAutoEventsWrapper', cqBaseAutoEventsWrapper());

  function cqBaseAutoEventsWrapper() {
    return {
      bindings: {
        autoEvent: '=', // Автособытие
        properties: '=?', // Свойства
      },
      controller: angular.noop,
      controllerAs: 'vm',
      template: require('./base-auto-events-wrapper.html'),
    };
  }
})();

<a
  class="btn btn-borderless-danger"
  (click)="onClickSubscriptionStatus()"
  *ngIf="isTrial"
>
  <i class="btn-icon-left cqi-sm cqi-clock"></i>
  <span
    [translocoParams]="{ days: $any(subscription.trial_end) | amDifference: $any(now | async):'days' }"
    transloco="subscriptionStatus.inTrial"
  ></span>
</a>

<a
  class="btn btn-borderless-danger"
  (click)="onClickSubscriptionStatus()"
  *ngIf="isEndedTrial"
>
  <i class="btn-icon-left cqi-sm cqi-clock"></i>
  <span transloco="subscriptionStatus.trialIsOver"></span>
</a>

<a
  class="btn btn-borderless-danger"
  (click)="onClickSubscriptionStatus()"
  *ngIf="hasUnpaidInvoice"
>
  <i class="btn-icon-left cqi-sm cqi-fire-o"></i>
  <span transloco="subscriptionStatus.paySubscription"></span>
</a>

import { CHAT_BOT_ACTIONS_TYPES } from '@http/chat-bot/chat-bot.constants';
import { ChatBotBranch } from '@http/chat-bot/types/branch-internal.types';
import { EMPTY_BODY_JSON } from '@panel/app/pages/chat-bot/forms/actions';

/**
 * Интерфейс прикрепления файла к ветке бота
 *
 * NOTE У этого свойства нет четкой структуры
 * 1. Когда файл добавлен с ПК в форму. У него тип File, но vue и либа ng-file-upload доабавлюят свои свойста
 * 2. Когда свойсво получено с бека
 */
export type ChatBotActionAttachment = {
  $ngfBlobUrl?: string; // Добавляются ng-file-upload
  $ngfDataUrlFilterInProgress?: boolean; // Добавляются ng-file-upload
  created: number;
  filename: string;
  filesize?: string; // Добавляется Vue
  id?: string;
  mimeType: string;
  name?: string; // name === filename. Добавляется Vue и для Vue при получении
  size?: number;
  type?: string;
  url: string;
};

/**
 * Тип действия бота
 */
export type ChatBotAction<BodyJson extends object = EMPTY_BODY_JSON> = {
  id?: string;
  linkId: string;
  type: CHAT_BOT_ACTIONS_TYPES;
  active: boolean;
  isReplyAction: boolean;
  // TODO: TAG:ACTION_TYPES с форм приходят null значения, надо думать че делать, тип не сошелся в actionMapper
  body: string;
  bodyJson: BodyJson;
  attachments: ChatBotActionAttachment[];
  // TODO TAG:ACTION_TYPES
  //  Узнать почему тут нет null, потому что когда парсится экшен, он без этого ID точно
  currentBranchLinkId: string;
  nextBranchId: string | null;
  nextBranchLinkId: string | null;
  order: number;
  /**
   * @deprecated это, кажется, нигде на фронтах не используется, поэтому думаю, что можно удалять
   */
  nextBranch?: ChatBotBranch | null;
  isInvalid: boolean;
  // TODO: TAG:ACTION_TYPES с форм приходят null значения, надо думать че делать, тип не сошелся в actionMapper
  keyName: string;
  prettyKeyName?: string | null;
  integrationName?: string | null;
};

/** В какой вкладке будет открываться ссылка в кнопке бота */
export enum CHAT_BOT_BUTTON_ACTION_URL_TARGET {
  BLANK = '_blank', // Ссылка откроется в новой вкладке
  TOP = '_top', // Ссылка откроется в текущей вкладке
}

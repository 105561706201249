import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

import { AutoEventsDuplicatesErrorsModule } from '@panel/app/partials/track-master/duplicates-errors/duplicates-errors.module';
import { EventNameInputModule } from '@panel/app/partials/track-master/event-name-input/event-name-input.module';
import { AutoEventsDuplicatesHelperService } from '@panel/app/partials/track-master/services/duplicates-helper.service';
import { UrlInputModule } from '@panel/app/partials/url-input/url-input.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { CreateUrlAutoEventComponent } from './create-url-auto-event.component';

@NgModule({
  declarations: [CreateUrlAutoEventComponent],
  imports: [
    AutoEventsDuplicatesErrorsModule,
    CommonModule,
    TranslocoModule,
    EventNameInputModule,
    UrlInputModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [CreateUrlAutoEventComponent],
  providers: [AutoEventsDuplicatesHelperService],
})
export class CreateUrlAutoEventModule {}

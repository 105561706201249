import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { ArticleModel } from '@http/article/article.model';
import { ARTICLES_COUNT_LIMIT } from '@http/knowledge-base/knowledge-base.constants';
import { LimitReachedModalComponent } from '@panel/app/pages/knowledge-base/shared/components/limit-reached-modal/limit-reached-modal.component';
import { DestroyService, ModalHelperService } from '@panel/app/services';
import { PaywallService } from '@panel/app/services/billing/paywall/paywall.service';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { FroalaHelperService } from '@panel/app/shared/services/froala-helper/froala-helper.service';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';
import { L10nHelperService } from '@panel/app-old/shared/services/l10n-helper/l10n-helper.service';
import { UibModalService } from '@panel/app-old/shared/services/uib-modal-service/open-uib-modal.service';

@Component({
  selector: 'cq-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ArticleEditComponent implements OnInit, OnChanges, AfterViewInit {
  /** Статья */
  @Input() article!: any;
  /** Количество статей */
  @Input() articlesCount!: number;
  /** Информация о биллинге */
  @Input() billingInfo!: any;
  /** Текущий App */
  @Input() currentApp!: any;
  /** Текущая категория */
  @Input() currentCategory!: any;
  /** Пользователь */
  @Input() djangoUser!: any;
  /** Подключён ли модуль с продвинутой базой знаний */
  @Input() isKnowledgeBaseProActivated!: boolean;
  /** Настройки БЗ */
  @Input() settings!: any;

  protected MAX_DESCRIPTION_LENGTH: number = 255;
  protected MAX_NAME_LENGTH: number = 100;
  protected MIN_NAME_LENGTH: number = 3;
  protected toolbarButtons: string[] = [
    'clearFormatting',
    '|',
    'bold',
    'italic',
    'paragraphFormat',
    'fontSize',
    'align',
    '|',
    'insertCode',
    '|',
    'insertLink',
  ];
  protected accessToKnowledgeBasePro: any;
  protected apiMessageArticleAlreadyExistsError: any = false;
  protected articlesLeftOnStandart: any;
  protected froalaOptions: any | null = null;
  protected initialPublicStatus: any;
  @ViewChild('editor', { static: false }) editorEl: any;

  protected editorInstance: any | null = null;

  articlePreviewForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(this.MIN_NAME_LENGTH),
      Validators.maxLength(this.MAX_NAME_LENGTH),
    ]),
    description: new FormControl('', [Validators.required, Validators.maxLength(this.MAX_DESCRIPTION_LENGTH)]),
    content: new FormControl('', Validators.required),
  });

  constructor(
    protected readonly destroy$: DestroyService,
    protected readonly cookieService: CookieService,
    protected readonly articleModel: ArticleModel,
    protected readonly carrotquestHelper: CarrotquestHelper,
    protected readonly froalaHelper: FroalaHelperService,
    protected readonly l10nHelper: L10nHelperService,
    protected readonly paywallService: PaywallService,
    protected readonly planFeatureAccessService: PlanFeatureAccessService,
    protected readonly toastr: ToastService,
    protected readonly translocoService: TranslocoService,
    protected readonly $uibModal: UibModalService,
    protected readonly uiRouter: UIRouter,
    protected readonly modalHelperService: ModalHelperService,
  ) {
    this.articlePreviewForm.valueChanges.subscribe((value) => {
      this.article.name = value.name;
      this.article.shortDescription = value.description;
      this.article.content = value.content;
    });
  }

  ngOnInit(): void {
    this.accessToKnowledgeBasePro = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.KNOWLEDGE_BASE_PRO,
      this.currentApp,
    );

    this.carrotquestHelper.track('База знаний - переход на статью');

    this.articlesLeftOnStandart = ARTICLES_COUNT_LIMIT - this.articlesCount;
    this.initialPublicStatus = this.article.public;

    this.froalaOptions = {
      disableRightClick: true,
      direction: 'ltr',
      events: {
        //@ts-ignore
        'image.error': (error) => {
          this.froalaHelper.handleImageUploadError(error, this.editorInstance);
        },
        contentChanged: () => {
          //@ts-ignore
          this.articlePreviewForm.controls.content.setValue(this.editorInstance?.html.get());
        },
        blur: () => {
          this.articlePreviewForm.controls.content.markAsTouched();
        },
      },
      fontSize: ['13', '15', '17', '23'],
      heightMin: 100,
      toolbarInline: true,
      quickInsertEnabled: true,
      quickInsertButtons: ['image', 'video', 'table', 'insertPre', 'ol', 'ul', 'hr'],
      lineBreakerTags: ['.table-responsive', 'hr', 'form', 'dl', 'span.fr-video', '.fr-embedly', 'pre'],
      pastePlain: true,
      imageEditButtons: [
        'imageReplace',
        'imageRemove',
        'imageLink',
        'linkOpen',
        'linkEdit',
        'linkRemove',
        'imageAlt',
        'imageSize',
      ],
      imageMaxSize: 1024 * 1024,
      imageUploadURL:
        environment.apiEndpoint +
        '/knowledgebase/image?froala=true&auth_token=' +
        'appm-' +
        this.currentApp.id +
        '-' +
        this.cookieService.get('carrotquest_auth_token_panel'),
      key: environment.froalaEditorKey,
      language: environment.language,
      linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
      linkList: [
        {
          href: 'https://google.com',
          rel: 'nofollow',
          target: '_blank',
          text: 'Google',
        },
      ],
      listAdvancedTypes: false,
      imageDefaultWidth: 0,
      paragraphFormat: {
        N: this.translocoService.translate('articleEdit.froalaOptions.paragraphFormat.normal'),
        H1: this.translocoService.translate('articleEdit.froalaOptions.paragraphFormat.h1'),
        H2: this.translocoService.translate('articleEdit.froalaOptions.paragraphFormat.h2'),
      },
      placeholderText: this.translocoService.translate('articleEdit.froalaOptions.placeholderText'),
      requestWithCORS: false,
      theme: 'carrot-quest',
      toolbarButtons: this.toolbarButtons,
      //@ts-ignore
      toolbarButtonsMD: this.toolbarButtons,
      //@ts-ignore
      toolbarButtonsSM: this.toolbarButtons,
      //@ts-ignore
      toolbarButtonsXS: this.toolbarButtons,
      toolbarSticky: false,
    };

    this.uiRouter.stateService.is('app.content.knowledgeBaseAjs.copy') && this.preparingCreateCopy();

    if (!this.article.id) {
      this.article.category = this.currentCategory;
    }

    if (this.article.id && !this.accessToKnowledgeBasePro.hasAccess) {
      this.article.robotsIndex = this.article.public;
    }

    this.articlePreviewForm.setValue({
      name: this.article.name,
      description: this.article.shortDescription,
      content: this.article.content ?? this.article.body,
    });
  }

  ngAfterViewInit() {
    if (this.froalaOptions) {
      //this.editorInstance = new FroalaEditor(this.editorEl.nativeElement, this.froalaOptions);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    ////NOTE с какого то хера ng-quill-editor, если что-то написать а потом удалить,
    //// вместо пустой строки он возвращает строку с переносом.
    //// Если этого не делать, то поле перестает валидироваться по атрибуту required
    if (changes.article) {
      const name = changes.article.currentValue.name;
      const shortDescription = changes.article.currentValue.shortDescription;
      if (name && name.match(/\n/g)) {
        this.article.name = name.replace(/\n/g, '');
      }
      if (shortDescription && shortDescription.match(/\n/g)) {
        this.article.shortDescription = shortDescription.replace(/\n/g, '');
      }
    }

    if (changes.billingInfo && !changes.billingInfo.isFirstChange()) {
      const newAddons = changes.billingInfo.currentValue.addons;
      const oldAddons = changes.billingInfo.previousValue.addons;
      if (newAddons !== oldAddons) {
        this.accessToKnowledgeBasePro.hasAccess = this.planFeatureAccessService.getAccess(
          PLAN_FEATURE.KNOWLEDGE_BASE_PRO,
          this.currentApp,
        );
      }
    }
  }

  /**
   * Добавление горячих клавиш в Quill
   */
  blockEnterKey(quillInstance: any): void {
    quillInstance.keyboard.bindings[13].unshift({
      key: 13,
      ctrlKey: true,
      handler: () => false,
    });

    quillInstance.keyboard.bindings[13].unshift({
      key: 13,
      metaKey: true,
      handler: () => false,
    });

    quillInstance.keyboard.bindings[13].unshift({
      key: 13,
      shiftKey: null, //Either enter or shift+enter will trigger
      handler: () => false,
    });
  }

  /**
   * Подготавливает к созданию его копии
   */
  preparingCreateCopy(): void {
    this.article.name = this.article.name + ' (' + this.translocoService.translate('articleEdit.copy') + ')';
    this.article.id = null;
  }

  /**
   * Сохранение статьи
   *
   * @param isValid Флаг валидности формы
   */
  save(isValid: any): void {
    if (isValid) {
      this.apiMessageArticleAlreadyExistsError = false;

      const saveOrUpdateArticle = this.article.id
        ? this.articleModel.save(this.currentApp.id, this.article, 'ArticleAlreadyExistsError')
        : this.articleModel.create(this.currentApp.id, this.article, 'ArticleAlreadyExistsError');

      saveOrUpdateArticle
        .pipe(
          takeUntil(this.destroy$),
          catchError((response: any) => {
            if (response.meta && response.meta.error === 'ArticleAlreadyExistsError') {
              this.apiMessageArticleAlreadyExistsError = true;
            }
            return of();
          }),
        )
        .subscribe((response: any) => {
          if (this.article.id) {
            this.trackSaveArticle(this.article.public);
            this.initialPublicStatus = this.article.public;
            this.toastr.success(this.translocoService.translate('articleEdit.toasts.saveArticleSuccess'));
          } else {
            this.trackCreateArticle(this.article.public);
            // If ARTICLES_COUNT_LIMIT - 1 and user created another one =>
            // => they created the last one for the standard module
            if (this.articlesCount === ARTICLES_COUNT_LIMIT - 1 && !this.accessToKnowledgeBasePro.hasAccess) {
              this.trackCreatedLastArticle();
              this.openLimitReachedModal();
            }

            this.uiRouter.stateService.go('app.content.knowledgeBaseAjs', {
              articleId: response.id,
              categoryId: this.currentCategory.id,
            });
            this.toastr.success(this.translocoService.translate('articleEdit.toasts.createArticleSuccess'));
          }
        });
    } else {
      if (this.articlePreviewForm.controls.name.invalid) {
        this.articlePreviewForm.controls.name.markAsTouched();
      }
      if (this.articlePreviewForm.controls.description.invalid) {
        this.articlePreviewForm.controls.description.markAsTouched();
      }
      if (this.articlePreviewForm.controls.content.invalid) {
        this.articlePreviewForm.controls.content.markAsTouched();
      }

      // Scroll up to show invalid fields to user
      angular.element('#content')[0].scrollTo(0, 0);
    }
  }

  /**
   * Callback смены статуса опубликованности
   *
   * @param publicStatus
   */
  onArticlePublicChange(publicStatus: any): void {
    if (!publicStatus) {
      this.article.robotsIndex = false;
    } else if (publicStatus && !this.accessToKnowledgeBasePro.hasAccess) {
      this.article.robotsIndex = true;
    }
  }

  /**
   * Колбэк инициализации Quill
   *
   * @param quillInstance
   */
  onQuillCreated(quillInstance: any): void {
    this.blockEnterKey(quillInstance);
  }

  /**
   * Открытие модалки о превышении количества созданный статей
   */
  openLimitReachedModal(): void {
    const modal = this.modalHelperService.open(LimitReachedModalComponent);

    modal.componentInstance.modalWindowParams = {
      currentApp: this.currentApp,
    };

    modal.result.then((addon: any) => {
      this.paywallService.showPaywall(this.currentApp, this.accessToKnowledgeBasePro.denialReason);
    });
  }

  /**
   * Трек клика сохранения статьи
   */
  trackClickSaveArticle(): void {
    this.carrotquestHelper.track('База знаний - клик на "Сохранить"');
  }

  /**
   * Трек создания последней статьи
   */
  trackCreatedLastArticle(): void {
    this.carrotquestHelper.track('База знаний - Создал 30-ю статью');
  }

  /**
   * Трек создания статьи
   *
   * @param articlePublic - Статус опубликованости статьи
   */
  trackCreateArticle(articlePublic: any): void {
    this.carrotquestHelper.track('База знаний - создал статью', {
      'Статус публикации': articlePublic,
    });
  }

  /**
   * Трек сохранения статьи
   *
   * @param articlePublic - Статус опубликованости статьи
   */
  trackSaveArticle(articlePublic: any): void {
    this.carrotquestHelper.track('База знаний - сохранил статью', {
      'Статус публикации': articlePublic,
    });
  }

  onNameChange(name: string): void {
    name = name.replace(/\n/g, '');

    this.articlePreviewForm.controls.name.setValue(name);
  }

  onDescriptionChange(description: string): void {
    description = description.replace(/\n/g, '');

    this.articlePreviewForm.controls.description.setValue(description);
  }
}

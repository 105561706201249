import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'cq-message-editor-switch[heading]',
  templateUrl: './message-editor-switch.component.html',
  styleUrls: ['./message-editor-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageEditorSwitchComponent {
  @Input()
  switched: boolean = false;

  @Input()
  heading: string = '';

  @Input()
  tooltip?: string;

  @Input()
  disabled: boolean = false;

  @Output()
  switchedChange: Subject<boolean> = new Subject();
}

import { translate, TranslocoService } from '@jsverse/transloco';
import { Graphics, TextStyle } from 'pixi.js';
import { Simple } from 'pixi-cull';
import { Viewport } from 'pixi-viewport';
import { Subject } from 'rxjs';

import { CHAT_BOT_TYPE } from '@http/chat-bot/types/chat-bot-external.types';
import { BotScenariosHelper } from '@panel/app/pages/chat-bot/content/services/bot-scenarios.helper';
import { ConnectionFactory } from '@panel/app/pages/chat-bot/content/services/factories';
import { PixiInteractionService } from '@panel/app/pages/chat-bot/content/services/interaction-service/pixi-interaction.service';
import { OverlayBootstrapElementsHelper } from '@panel/app/pages/chat-bot/content/services/overlay-bootstrap-elements-helper.service';
import { BranchViewMap, PixiApplication } from '@panel/app/pages/chat-bot/content/tokens';
import { TriggersPopoverTemplateComponent } from '@panel/app/pages/chat-bot/content/views/badges/triggers-popover-template/triggers-popover-template.component';
import { Branch } from '@panel/app/pages/chat-bot/content/views/blocks/base-block/branch';
import { Connection } from '@panel/app/pages/chat-bot/content/views/connection';
import {
  BOT_GREY,
  BOT_LIGHT_GREY,
  BOT_PRIMARY_COLOR,
  BOT_WHITE_COLOR,
} from '@panel/app/pages/chat-bot/content/views/utils/colors';
import { renderCanvasText } from '@panel/app/pages/chat-bot/content/views/utils/helpers-functions';
import { shortenString } from '@panel/app/shared/functions/shorten-string.function';
import { PixiGeometryService } from '@panel/app/shared/services/pixi/geometry/pixi-geometry.service';
import { PixiIconRenderService } from '@panel/app/shared/services/pixi/icon-render/pixi-icon-render.service';
import { Immutable } from '@panel/app/shared/types/immutable.type';

import {
  BADGE_ELEMENTS_INDENT,
  BADGE_HORIZONTAL_PADDING,
  BADGE_VERTICAL_PADDING,
  BadgeStyle,
  BaseBadge,
  DEFAULT_BADGE_STYLE,
} from './base-badge';

const TRIGGER_MAX_LENGTH = 22;

const START_BADGE_STYLE: Immutable<BadgeStyle> = {
  border: {
    ...DEFAULT_BADGE_STYLE.border,
    color: BOT_PRIMARY_COLOR,
  },
  background: {
    ...DEFAULT_BADGE_STYLE.background,
    color: BOT_PRIMARY_COLOR,
  },
  text: {
    ...DEFAULT_BADGE_STYLE.text,
    color: BOT_WHITE_COLOR,
  },
};

export class StartBadge extends BaseBadge {
  triggerPlus: Graphics | null = null;

  readonly sizeChange$ = new Subject<void>();

  constructor(
    botScenariosHelper: BotScenariosHelper,
    cull: Simple,
    interactionService: PixiInteractionService,
    document: Document,
    connectionFactory: ConnectionFactory,
    transloco: TranslocoService,
    targetBlock: Branch | undefined,
    botType: CHAT_BOT_TYPE,
    canvasBranches: BranchViewMap,
    viewport: Viewport,
    bootstrapElementsHelper: OverlayBootstrapElementsHelper,
    pixiApp: PixiApplication,
    private _triggers: string[] = [],
    private _openWebPageTriggerAmount: number = 0,
    private _openSdkPageTriggerAmount: number = 0,
    private _leaveSiteAttemptTrigger: boolean = false,
  ) {
    super(
      botScenariosHelper,
      cull,
      interactionService,
      document,
      connectionFactory,
      transloco,
      targetBlock,
      botType,
      canvasBranches,
      viewport,
      bootstrapElementsHelper,
      pixiApp,
    );

    this.container.name = 'Start badge';
    botType === CHAT_BOT_TYPE.LEAD && (this.container.cursor = 'pointer');

    this.redraw();
    this.initConnectionListeners();
  }

  redraw() {
    this.card.destroy();
    this.container.removeChildren();
    this.card = this.drawCard();
    this.container.addChild(this.card);
    this.renderDefaultConnectionPoint();
    this.container.addChild(this.connectionPoint);
    this.setPivot(this.viewport.scaled);
    this.sizeChange$.next();
    this.eventEmitters.move.next();
  }

  set triggers(value: string[]) {
    this._triggers = value;
    this.redraw();
  }

  /**  Имена триггеров и кайндов триггер типов */
  get triggers() {
    return this._triggers;
  }

  set leaveSiteAttemptTrigger(value: boolean) {
    this._leaveSiteAttemptTrigger = value;
    this.redraw();
  }

  get leaveSiteAttemptTrigger() {
    return this._leaveSiteAttemptTrigger;
  }

  set openWebPageTriggerAmount(value: number) {
    this._openWebPageTriggerAmount = value;
    this.redraw();
  }

  set openSdkPageTriggerAmount(value: number) {
    this._openSdkPageTriggerAmount = value;
    this.redraw();
  }

  get openWebPageTriggerAmount() {
    return this._openWebPageTriggerAmount;
  }

  get openSdkPageTriggerAmount() {
    return this._openSdkPageTriggerAmount;
  }

  addConnectionInfo(connection: Connection) {
    super.addConnectionInfo(connection);
  }

  get apiReadyData(): {
    startBranchId: string | null;
    startBranchLinkId: string | null;
  } {
    return {
      startBranchId: this.form.nextBranchId,
      startBranchLinkId: this.form.controls.nextBranchLinkId.value,
    };
  }

  /**
   * Стили бейджа
   */
  protected get style(): BadgeStyle {
    return START_BADGE_STYLE;
  }

  /**
   * Коллбек для события клика
   */
  protected clickCallback() {}

  deleteConnectionInfo(connection: Connection) {
    super.deleteConnectionInfo(connection);
  }

  /**
   * Коллбек для mouseover события
   */
  protected mouseoverCallback() {
    if ([CHAT_BOT_TYPE.TELEGRAM, CHAT_BOT_TYPE.LEAD].includes(this.botType)) {
      // Это условие сработает только для CHAT_BOT_TYPE.LEAD
      if (this.openWebPageTriggerAmount || this.openSdkPageTriggerAmount) {
        return;
      }

      const firstTriggerWasNotShorten = shortenString(this.triggers[0], TRIGGER_MAX_LENGTH) === this.triggers[0];
      if (this.triggers.length === 1 && firstTriggerWasNotShorten) {
        return;
      }

      this.bootstrapElementsHelper.destroyPopover(this.uid);
      this.bootstrapElementsHelper.createPopover(
        this.uid,
        TriggersPopoverTemplateComponent,
        { placement: 'bottom', popoverClass: 'start-badge-triggers-popover' },
        {
          triggers: this.triggers,
        },
      );
      this.bootstrapElementsHelper.openPopover(this.uid);
      return;
    }

    this.bootstrapElementsHelper.createTooltip(
      this.uid,
      this.transloco.translate(`chatBot.badges.startBadge.descriptionTooltip.${this.botType}`),
    );

    this.bootstrapElementsHelper.openTooltip(this.uid);
  }

  drawCard(): Graphics {
    if (this.botType === CHAT_BOT_TYPE.LEAD || this.botType === CHAT_BOT_TYPE.TELEGRAM) {
      return this.drawLeadBotBadge();
    }
    return this.drawDefaultBadge();
  }

  /**
   * Коллбек для mouseout события
   */
  protected mouseoutCallback() {
    if (this.botType === CHAT_BOT_TYPE.LEAD) {
      this.bootstrapElementsHelper.closePopover(this.uid);
    } else {
      this.bootstrapElementsHelper.closeTooltip(this.uid);
    }
  }

  drawDefaultBadge(): Graphics {
    const icon = PixiIconRenderService.renderIcon('\ue94f', { fill: this.style.text.color });
    const fontSize = this.style.text.size;
    const textOptions: Partial<TextStyle> = {
      fontSize,
      lineHeight: fontSize * 1.4,
      wordWrap: false,
      fill: this.style.text.color,
      align: 'left',
      fontStyle: 'normal',
      fontWeight: '400',
    };
    const text = renderCanvasText(this.transloco.translate(`chatBot.badges.startBadge.${this.botType}`), textOptions);
    const contentHeight = Math.max(icon.height, text.height);
    const rectWidth = text.width + icon.width + BADGE_ELEMENTS_INDENT + BADGE_HORIZONTAL_PADDING * 2;
    const rectHeight = contentHeight + BADGE_VERTICAL_PADDING * 2;
    const rect = PixiGeometryService.renderRectangle(rectWidth, rectHeight, {
      border: this.style.border,
      backgroundColor: this.style.background.color,
    });

    rect.addChild(icon);
    rect.addChild(text);

    this.updateContentPosition(rect, [text, icon]);

    return rect;
  }

  get triggerIsNotSelectedText() {
    return translate('chatBot.badges.startBadge.triggerIsNotSelected');
  }

  get triggerOpenWebPageText() {
    return translate('chatBot.badges.startBadge.openWebPage');
  }

  get triggerOpenSdkPageText() {
    return translate('chatBot.badges.startBadge.openSdkPage');
  }

  get triggerleaveSiteAttemptTriggerText() {
    return translate('chatBot.badges.startBadge.leaveSiteAttemptTrigger');
  }

  drawLeadBotBadge() {
    this.triggerPlus?.destroy();
    this.triggerPlus = null;

    const hasTriggers = this.triggers?.length > 1;

    const fontSize = this.style.text.size;
    const textOptions: Partial<TextStyle> = {
      fontSize,
      lineHeight: fontSize * 1.4,
      wordWrap: false,
      fill: this.style.text.color,
      align: 'left',
      fontStyle: 'normal',
      fontWeight: '400',
    };
    const text = renderCanvasText(this.transloco.translate(`chatBot.badges.startBadge.${this.botType}`), textOptions);
    const triggerLabel = this.drawTriggerLabel();

    if (hasTriggers) {
      this.triggerPlus = this.drawTriggerPlus(triggerLabel.height);
    }
    const contentHeight = Math.max(triggerLabel.height, text.height, this.triggerPlus?.height ?? 0);
    const rectWidth =
      text.width +
      triggerLabel.width +
      (this.triggerPlus?.width ?? 0) +
      (this.triggerPlus ? BADGE_ELEMENTS_INDENT * 2 : BADGE_ELEMENTS_INDENT) +
      BADGE_HORIZONTAL_PADDING * 2;
    const rectHeight = contentHeight + BADGE_VERTICAL_PADDING * 2;
    const rect = PixiGeometryService.renderRectangle(rectWidth, rectHeight, {
      border: this.style.border,
      backgroundColor: this.style.background.color,
    });

    rect.addChild(text);
    rect.addChild(triggerLabel);
    const elementsToPosition = [text, triggerLabel];

    if (this.triggerPlus) {
      rect.addChild(this.triggerPlus);
      elementsToPosition.push(this.triggerPlus);
    }

    this.updateContentPosition(rect, elementsToPosition);

    return rect;
  }

  private drawTriggerTextLabel(text: string, fillColor: number = BOT_GREY): Graphics {
    const badgeIndent = 12;
    const badgeVerticalPadding = 1;

    const shortenTrigger = shortenString(text, TRIGGER_MAX_LENGTH);
    const triggerText = renderCanvasText(shortenTrigger, {
      wordWrap: false,
      fill: fillColor,
    });
    const rectWidth = triggerText.width + badgeIndent * 2;
    const rectHeight = triggerText.height + badgeVerticalPadding * 2;
    const rect = PixiGeometryService.renderRectangle(rectWidth, rectHeight, {
      border: {
        size: 0,
        radius: 30,
      },
      backgroundColor: BOT_WHITE_COLOR,
    });
    rect.addChild(triggerText);
    triggerText.position.x = (rect.width - triggerText.width) / 2;
    return rect;
  }

  drawTriggerLabel(): Graphics {
    if (this.leaveSiteAttemptTrigger) {
      return this.drawTriggerTextLabel(this.triggerleaveSiteAttemptTriggerText);
    }
    if (this.openWebPageTriggerAmount > 0) {
      return this.drawTriggerTextLabel(this.triggerOpenWebPageText);
    }
    if (this.openSdkPageTriggerAmount > 0) {
      return this.drawTriggerTextLabel(this.triggerOpenSdkPageText);
    }
    if (this.triggers?.length > 0) {
      return this.drawTriggerTextLabel(this.triggers[0]);
    }
    return this.drawTriggerTextLabel(this.triggerIsNotSelectedText, BOT_LIGHT_GREY);
  }

  drawTriggerPlus(height: number) {
    const triggers = this.triggers;

    const text = renderCanvasText(`+${triggers.length - 1}`, { fontSize: 12, fill: BOT_GREY });
    const el = PixiGeometryService.renderRectangle(text.width + 10, height, {
      backgroundColor: BOT_WHITE_COLOR,
      border: { size: 0, radius: 30 },
    });
    el.addChild(text);
    text.position.x = (el.width - text.width) / 2;
    text.position.y = (el.height - text.height) / 2;
    el.eventMode = 'static';
    return el;
  }
}

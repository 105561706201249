import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { BotCustomPlaceholderModule } from '@panel/app/pages/chat-bot/content/branch-editor/actions/property-field/bot-custom-placeholder/bot-custom-placeholder.module';
import { BotActionNextBranchSelectorModule } from '@panel/app/pages/chat-bot/content/branch-editor/partials/bot-action-next-branch-selector/bot-action-next-branch-selector.module';
import { PropertySelectorModule } from '@panel/app/partials/property-selector/property-selector.module';
import { SharedModule } from '@panel/app/shared/shared.module';

import { BranchActionPropertyFieldComponent } from './property-field.component';

@NgModule({
  declarations: [BranchActionPropertyFieldComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    SharedModule,
    NgbPopoverModule,
    PropertySelectorModule,
    BotActionNextBranchSelectorModule,
    BotCustomPlaceholderModule,
  ],
  exports: [BranchActionPropertyFieldComponent],
})
export class BranchActionPropertyFieldModule {}

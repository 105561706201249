import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import sortBy from 'lodash-es/sortBy';

import { App } from '@http/app/app.model';
import { EventTypeModel } from '@http/event-type/event-type.model';
import { BaseBotActionForm } from '@panel/app/pages/chat-bot/forms/actions/base-action.form';
import { ModalHelperService } from '@panel/app/services';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { PromptModalComponent } from '@panel/app/shared/modals/prompt/prompt-modal.component';
import { PROMPT_MODAL_DATA_TOKEN } from '@panel/app/shared/modals/prompt/prompt-modal.token';
import { EventType, Properties } from '@http/property/property.model';

@Component({
  selector: 'cq-branch-action-event[actionForm][currentApp][propertyOptions]',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchActionEventComponent implements OnInit, OnChanges {
  @Input()
  actionForm!: BaseBotActionForm;

  /** Текущее приложение */
  @Input()
  currentApp!: App;

  @Input()
  propertyOptions!: Properties;

  /** Доступ до кастомных событий */
  accessToEventsEventTypesCustom: ProductFeatureAccess = { hasAccess: true, denialReason: null };

  constructor(
    public eventTypeModel: EventTypeModel,
    private readonly modalHelperService: ModalHelperService,
    private readonly planFeatureAccessService: PlanFeatureAccessService,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Отфильтрованные и отсортированные типы событий
   */
  get eventTypes(): EventType[] {
    const filteredEvents = this.propertyOptions.eventTypes.filter((eventType: EventType) => {
      const isEventTypeSelected = eventType.prettyName === this.actionForm.controls.keyName.value;
      const isEventTypeVisible = eventType.visible;
      const isNotCapabilities = this.accessToEventsEventTypesCustom.hasAccess;
      const isSystemEventTypes = eventType.name.includes('$');

      return isEventTypeVisible && (isNotCapabilities || isEventTypeSelected || isSystemEventTypes);
    });

    return sortBy(filteredEvents, ['prettyName']);
  }

  ngOnInit() {
    this.accessToEventsEventTypesCustom = this.planFeatureAccessService.getAccess(
      PLAN_FEATURE.EVENTS_EVENT_TYPES_CUSTOM,
      this.currentApp,
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const actionForm = changes.actionForm;
    if (actionForm && actionForm.previousValue !== actionForm.currentValue) {
      this.addEventType(this.actionForm.controls.keyName.value, false);
    }
  }

  onChangeEvent(event: EventType) {
    this.actionForm.prettyKeyName = event.prettyName;
  }

  /**
   * Добавление нового события
   *
   * @param eventTypeName Название события
   * @param fireValueChange Посылать ли евент о смене значения
   */
  addEventType(eventTypeName: EventType['name'], fireValueChange: boolean = true): void | boolean {
    if (!eventTypeName) {
      return false;
    }

    const newEventType: EventType = this.eventTypeModel.getDefault(eventTypeName);

    // при добавлении нового события надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
    const existingEventTypeByName = this.propertyOptions.eventTypes.filter(
      (eventType: EventType) => eventType.name === newEventType.name,
    )[0];
    const existingEventTypeByPrettyName = this.propertyOptions.eventTypes.filter(
      (eventType: EventType) => eventType.prettyName === newEventType.prettyName,
    )[0];

    if (!existingEventTypeByName && !existingEventTypeByPrettyName) {
      this.propertyOptions.eventTypes = [...this.propertyOptions.eventTypes, newEventType];
    }
    this.actionForm.prettyKeyName = newEventType.prettyName;
    this.actionForm.controls.keyName.setValue(newEventType.name, { emitEvent: fireValueChange });
  }

  /**
   * Открытие модалка создания нового типа события
   */
  openCreateNewEventModal(): void {
    const createNewEventModal = this.modalHelperService
      .provide(PROMPT_MODAL_DATA_TOKEN, {
        heading: this.translocoService.translate('chatBot.botActionEvent.addEventModal.heading'),
        body: this.translocoService.translate('chatBot.botActionEvent.addEventModal.body'),
        inputPlaceholder: this.translocoService.translate('chatBot.botActionEvent.addEventModal.inputPlaceholder'),
        inputErrorText: this.translocoService.translate('chatBot.botActionEvent.addEventModal.inputErrorText'),
        confirmButtonText: this.translocoService.translate('chatBot.botActionEvent.addEventModal.confirmButtonText'),
      })
      .open(PromptModalComponent);

    createNewEventModal.result.then((newEventTypeName: string) => this.addEventType(newEventTypeName));
  }

  eventSelectTrackFn(property: EventType): string {
    return property.name;
  }
}

<ng-container *transloco="let t; read: 'outdatedBrowserBanner'">
  <div
    class="ml-auto"
    [innerHTML]="t('text', { projectName: projectName })"
  ></div>
  <a
    class="btn btn-outline-primary ml-15 mr-auto"
    [href]="outdatedBrowserArticle"
    (click)="trackClickLearnMoreButton(); learnMoreButtonClick.emit()"
    target="_blank"
  >
    {{ t('detailButton') }}
  </a>
  <button
    class="btn btn-text-primary ml-15"
    (click)="setOutdatedBrowserBannerCookie(); trackClickCloseButton(); closeButtonClick.emit()"
  >
    <i class="btn-icon cqi-sm cqi-times"></i>
  </button>
</ng-container>

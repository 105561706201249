import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { CarrotquestHelperProvider, ProductFeatureTextServiceProvider } from '@panel/app/ajs-upgraded-providers';

import { AccessDenialPopoverComponent } from './access-denial-popover.component';

@NgModule({
  declarations: [AccessDenialPopoverComponent],
  exports: [AccessDenialPopoverComponent],
  imports: [CommonModule, NgbPopoverModule],
  providers: [CarrotquestHelperProvider, ProductFeatureTextServiceProvider],
})
export class AccessDenialPopoverModule {}

<ng-container *transloco="let t; read: 'tcConnectionPointOverlayComponent'">
  <div
    class="d-inline-block h-100 w-100"
    ngbDropdown
    container="body"
    placement="right-top right-bottom"
  >
    <div
      class="h-100 w-100"
      ngbDropdownAnchor
    ></div>
    <div
      ngbDropdownMenu
      aria-labelledby="dropdownManual"
    >
      <ng-container *ngIf="_overlaySource">
        <ng-container
          *ngIf="
            TRIGGER_CHAIN_LOGIC_BLOCK_TYPES
              | tcConnectionPointOverlayOptionsFilter: overlaySource.type as logicBlockOptions
          "
        >
          <ng-container
            *ngIf="
              TRIGGER_CHAIN_CONTENT_BLOCK_TYPES
                | tcConnectionPointOverlayOptionsFilter: overlaySource.type as contentBlockOptions
            "
          >
            <ng-container
              *tuiLet="
                TRIGGER_CHAIN_EXIT_BLOCK_TYPES
                  | tcConnectionPointOverlayOptionsFilter: overlaySource.type as exitBlockOptions
              "
            >
              <ng-container *ngIf="contentBlockOptions.length">
                <div class="dropdown-header">{{ t('blockGroup.content.title') }}</div>
                <ng-container *ngFor="let blockType of contentBlockOptions">
                  <button
                    *ngIf="isActive(blockType)"
                    ngbDropdownItem
                    (click)="onAddBlockButtonClick(blockType)"
                    type="button"
                  >
                    <i
                      class="cqi-sm mr-5"
                      [ngClass]="getClassForStepIcon(blockType)"
                    ></i>
                    <span>{{ 'triggerChainBlock.type.' + blockType | transloco }}</span>
                  </button>
                </ng-container>
              </ng-container>

              <!-- between content and logic  -->
              <hr
                *ngIf="contentBlockOptions.length && logicBlockOptions.length"
                class="my-5"
              />
              <!-- between content and exit, if there is no logic blocks  -->
              <hr
                *ngIf="contentBlockOptions.length && !logicBlockOptions.length && exitBlockOptions.length"
                class="my-5"
              />

              <ng-container *ngIf="logicBlockOptions.length">
                <div class="dropdown-header">{{ t('blockGroup.logic.title') }}</div>
                <button
                  *ngFor="let blockType of logicBlockOptions"
                  ngbDropdownItem
                  (click)="onAddBlockButtonClick(blockType)"
                  type="button"
                >
                  <i
                    class="cqi-sm mr-5"
                    [ngClass]="getClassForStepIcon(blockType)"
                  ></i>
                  <span>{{ 'triggerChainBlock.type.' + blockType | transloco }}</span>
                </button>
              </ng-container>

              <!-- between logic and exit  -->
              <hr
                *ngIf="logicBlockOptions.length && exitBlockOptions.length"
                class="my-5"
              />

              <ng-container *ngIf="exitBlockOptions.length">
                <div class="dropdown-header">
                  <span>{{ t('blockGroup.exit.title') }}</span>
                  <i
                    class="cqi-sm cqi-question-circle"
                    [ngbTooltip]="t('blockGroup.exit.tooltip')"
                  ></i>
                </div>
                <button
                  *ngFor="let blockType of exitBlockOptions"
                  ngbDropdownItem
                  (click)="onAddBlockButtonClick(blockType)"
                  type="button"
                >
                  <i
                    class="cqi-sm mr-5"
                    [ngClass]="getClassForStepIcon(blockType)"
                  ></i>
                  <span>{{ 'triggerChainBlock.type.' + blockType | transloco }}</span>
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

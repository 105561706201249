/**
 * Контроллер компонента выбора в какое свойство записать данные
 */
(function () {
  'use strict';

  angular
    .module('myApp.trackMaster')
    .controller('CqAutoEventWritePropertyController', CqAutoEventWritePropertyController);

  function CqAutoEventWritePropertyController($filter, $translate, $uibModal, validationHelper, propertyModel) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.validationHelper = validationHelper;
      vm.addPropsOption = addPropsOption;

      if (!$filter('filter')(vm.userProps, { name: vm.key }, true)[0]) {
        addNonExistentProperty(vm.key);
      }
    }

    /**
     * Добавление несуществующего свойства
     *
     * @param {String} propertyName - Имя свойства
     */
    function addNonExistentProperty(propertyName) {
      var newProperty = propertyModel.getDefaultUserProperty(propertyName);
      vm.userProps.push(newProperty);
      vm.key = newProperty.name;
    }

    /**
     * Добавление нового свойства
     */
    function addPropsOption() {
      var addPropertyModal = $uibModal.open({
        controller: 'PromptModalController',
        controllerAs: 'vm',
        templateUrl: 'js/shared/modals/prompt/prompt.html',
        resolve: {
          modalWindowParams: function () {
            return {
              heading: $translate.instant('autoEventWriteProperty.addPropertyModal.heading'),
              confirmButtonText: $translate.instant('autoEventWriteProperty.addPropertyModal.confirmButtonText'),
              inputLabel: $translate.instant('autoEventWriteProperty.addPropertyModal.inputLabel'),
              inputErrorText: $translate.instant('autoEventWriteProperty.addPropertyModal.inputErrorText'),
            };
          },
        },
      });

      addPropertyModal.result.then(addProperty);

      function addProperty(propertyName) {
        // при добавлении нового свойства надо проверить не существует ли добавляемое свойство как по имени на сервере, так и по выводимому имени
        var existingPropertyByName = $filter('filter')(vm.userProps, { name: propertyName }, true)[0];

        if (existingPropertyByName) {
          vm.key = existingPropertyByName.name;
        } else {
          addNonExistentProperty(propertyName);
        }
      }
    }
  }
})();

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, StaticProvider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { downgradeComponent, downgradeInjectable, downgradeModule } from '@angular/upgrade/static';
import { NgbActiveModal, NgbAlert, NgbModule, NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarComponent } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { UIRouterModule } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UIRouter } from '@uirouter/core';
import { UIRouterRx } from '@uirouter/rx';
import angular from 'angular';
import { ACE_CONFIG, AceComponent, AceModule } from 'ngx-ace-wrapper';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { MomentModule } from 'ngx-moment';
import { SortablejsModule } from 'nxt-sortablejs';

import { environment } from '@environment';
import { AppComponent } from '@panel/app/app.component';
import { AccountPageModule } from '@panel/app/pages/account/account-page.module';
import { MemberActionsComponent } from '@panel/app/pages/admins/actions/member-actions.component';
import { AdminsModule } from '@panel/app/pages/admins/admins.module';
import { MemberInvitationsComponent } from '@panel/app/pages/admins/invitations/member-invitations.component';
import { AiDataSourcesModule } from '@panel/app/pages/ai-data-sources/ai-data-sources.module';
import { AiDataSourceListComponent } from '@panel/app/pages/ai-data-sources/ai-data-sources-list/ai-data-source-list.component';
import { AutoMessagesPageModule } from '@panel/app/pages/auto-messages/auto-messages-page.module';
import { AutoMessageAmoTransferComponent } from '@panel/app/pages/auto-messages/components/edit/amo-transfer/auto-message-amo-transfer.component';
import { AutoMessageAmoTransferModule } from '@panel/app/pages/auto-messages/components/edit/amo-transfer/auto-message-amo-transfer.module';
import { AutoMessageControlGroupComponent } from '@panel/app/pages/auto-messages/components/edit/control-group/auto-message-control-group.component';
import { AutoMessageControlGroupModule } from '@panel/app/pages/auto-messages/components/edit/control-group/auto-message-control-group.module';
import { AutoMessageEmailNotificationComponent } from '@panel/app/pages/auto-messages/components/edit/email-notification/auto-message-email-notification.component';
import { AutoMessageGoalComponent } from '@panel/app/pages/auto-messages/components/edit/goal/auto-message-goal.component';
import { AutoMessageGoalModule } from '@panel/app/pages/auto-messages/components/edit/goal/auto-message-goal.module';
import { AutoMessageUserStatusesComponent } from '@panel/app/pages/auto-messages/components/edit/user-statuses/auto-message-user-statuses.component';
import { AutoMessageUserStatusesModule } from '@panel/app/pages/auto-messages/components/edit/user-statuses/auto-message-user-statuses.module';
import { ComponentsPreviewModule } from '@panel/app/pages/components-preview/components-preview.module';
import { SelectPreviewComponent } from '@panel/app/pages/components-preview/select-preview/select-preview.component';
import { SwitchPreviewComponent } from '@panel/app/pages/components-preview/switch-preview/switch-preview.component';
import { ChannelAssignmentComponent } from '@panel/app/pages/conversations/channel-assignment/channel-assignment.component';
import { ChannelAssignmentModule } from '@panel/app/pages/conversations/channel-assignment/channel-assignment.module';
import { ConversationOperatorComponent } from '@panel/app/pages/conversations/conversation-operator/conversation-operator.component';
import { ConversationOperatorModule } from '@panel/app/pages/conversations/conversation-operator/conversation-operator.module';
import { ConversationsModule } from '@panel/app/pages/conversations/conversations.module';
import { ConversationsChannelListComponent } from '@panel/app/pages/conversations/conversations-channel-list/conversations-channel-list.component';
import { ConversationsChannelListModule } from '@panel/app/pages/conversations/conversations-channel-list/conversations-channel-list.module';
import { AssigneeFilterComponent } from '@panel/app/pages/conversations/conversations-list/assignee-filter/assignee-filter.component';
import { ConversationCardComponent } from '@panel/app/pages/conversations/conversations-list/conversation-card/conversation-card.component';
import { ConversationCardModule } from '@panel/app/pages/conversations/conversations-list/conversation-card/conversation-card.module';
import { ConversationsListModule } from '@panel/app/pages/conversations/conversations-list/conversations-list.module';
import { SearchFilterComponent } from '@panel/app/pages/conversations/conversations-list/search-filter/search-filter.component';
import { ConversationStatusFilterComponent } from '@panel/app/pages/conversations/conversations-list/status-filter/status-filter.component';
import { TagsFilterComponent } from '@panel/app/pages/conversations/conversations-list/tags-filter/tags-filter.component';
import { UnansweredFilterComponent } from '@panel/app/pages/conversations/conversations-list/unanswered-filter/unanswered-filter.component';
import { MessageInputComponent } from '@panel/app/pages/conversations/message-input/message-input.component';
import { MessageInputModule } from '@panel/app/pages/conversations/message-input/message-input.module';
import { NotAssignedComponent } from '@panel/app/pages/conversations/not-assigned/not-assigned.component';
import { NotAssignedModule } from '@panel/app/pages/conversations/not-assigned/not-assigned.module';
import { OperatorAssignmentComponent } from '@panel/app/pages/conversations/operator-assignment/operator-assignment.component';
import { OperatorAssignmentModule } from '@panel/app/pages/conversations/operator-assignment/operator-assignment.module';
import { StatsCollectorComponent } from '@panel/app/pages/conversations/stats-collector/stats-collector.component';
import { StatsCollectorModule } from '@panel/app/pages/conversations/stats-collector/stats-collector.module';
import { ConversationsUserCardComponent } from '@panel/app/pages/conversations/user-card/user-card.component';
import { ConversationsUserCardModule } from '@panel/app/pages/conversations/user-card/user-card.module';
import { ConversationsSettingsPageModule } from '@panel/app/pages/conversations-settings/conversations-settings-page.module';
import { ConversationsSettingsBreadcrumbsModule } from '@panel/app/pages/conversations-settings/shared/components/breadcrumbs/conversations-settings-breadcrumbs.module';
import { ChannelsTableModule } from '@panel/app/pages/conversations-settings/shared/components/channels-table/channels-table.module';
import { ManageTagsModule } from '@panel/app/pages/conversations-settings/shared/components/manage-tags/manage-tags.module';
import { DataCollectionPageModule } from '@panel/app/pages/data-collection/data-collection-page.module';
import { EventsAnalyticsPageModule } from '@panel/app/pages/events-analytics/events-analytics-page.module';
import { AmocrmModalLeadSettingsComponent } from '@panel/app/pages/integrations/content/amocrm/modal-lead-settings/amocrm-modal-lead-settings.component';
import { EmailNotificationCreateModalWrapperComponent } from '@panel/app/pages/integrations/content/email-notification/create-modal-wrapper/email-notification-create-modal-wrapper.component';
import { EmailNotificationComponent } from '@panel/app/pages/integrations/content/email-notification/email-notification.component';
import { EmailNotificationSelectModalWrapperComponent } from '@panel/app/pages/integrations/content/email-notification/select-modal-wrapper/email-notification-select-modal-wrapper.component';
import { IntegrationsModule } from '@panel/app/pages/integrations/integrations.module';
import { KnowledgeBasePageModule } from '@panel/app/pages/knowledge-base/knowledge-base-page.module';
import { KnowledgeBasePopoverComponent } from '@panel/app/pages/knowledge-base/shared/components/knowledge-base-popover/knowledge-base-popover.component';
import { LeadsTableColumnFilterComponent } from '@panel/app/pages/leads/leads-table-props-filter/leads-table-column-filter.component';
import { LeadsTableColumnFilterModule } from '@panel/app/pages/leads/leads-table-props-filter/leads-table-column-filter.module';
import { LogoutModule } from '@panel/app/pages/logout/logout.module';
import { PermissionDeniedComponent } from '@panel/app/pages/permission-denied/permission-denied.component';
import { PermissionDeniedModule } from '@panel/app/pages/permission-denied/permission-denied.module';
import { QuestionsModule } from '@panel/app/pages/questions/questions.module';
import { ChatAudioMessageComponent } from '@panel/app/pages/settings/chat/components/audio-message/chat-audio-message.component';
import { DialogStartComponent } from '@panel/app/pages/settings/chat/components/dialog-start/dialog-start.component';
import { DialogStartModule } from '@panel/app/pages/settings/chat/components/dialog-start/dialog-start.module';
import { SocialSettingsComponent } from '@panel/app/pages/settings/chat/components/social-settings/social-settings.component';
import { DEFAULT_ORDER_SOCIAL_NETWORKS } from '@panel/app/pages/settings/chat/components/social-settings/social-settings.constants';
import { SocialSettingsModule } from '@panel/app/pages/settings/chat/components/social-settings/social-settings.module';
import { VisibilitySettingsComponent } from '@panel/app/pages/settings/chat/components/visibility-settings/visibility-settings.component';
import { SettingsModule } from '@panel/app/pages/settings/settings.module';
import { EngagementInConversationComponent } from '@panel/app/pages/starter-guide/engagement-in-conversation/engagement-in-conversation.component';
import { EngagementInConversationModule } from '@panel/app/pages/starter-guide/engagement-in-conversation/engagement-in-conversation.module';
import { StarterGuideHeaderComponent } from '@panel/app/pages/starter-guide/header/starter-guide-header.component';
import { StarterGuideHeaderModule } from '@panel/app/pages/starter-guide/header/starter-guide-header.module';
import { StarterGuideLeadsCollectionComponent } from '@panel/app/pages/starter-guide/leads-collection/leads-collection.component';
import { StarterGuideLeadsCollectionModule } from '@panel/app/pages/starter-guide/leads-collection/leads-collection.module';
import { StarterGuideLeadsPassingComponent } from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.component';
import { StarterGuideLeadsPassingModule } from '@panel/app/pages/starter-guide/leads-passing/starter-guide-leads-passing.module';
import { StarterGuideSocialIntegrationsComponent } from '@panel/app/pages/starter-guide/social-integrations/starter-guide-social-integrations.component';
import { StarterGuideSocialIntegrationsModule } from '@panel/app/pages/starter-guide/social-integrations/starter-guide-social-integrations.module';
import { StarterGuideForMarketersComponent } from '@panel/app/pages/starter-guide/starter-guide-for-marketers/starter-guide-for-marketers.component';
import { StarterGuideForMarketersModule } from '@panel/app/pages/starter-guide/starter-guide-for-marketers/starter-guide-for-marketers.module';
import { StarterGuideWarmUpComponent } from '@panel/app/pages/starter-guide/warm-up/starter-guide-warm-up.component';
import { StarterGuideWarmUpModule } from '@panel/app/pages/starter-guide/warm-up/starter-guide-warm-up.module';
import { PricingComponent } from '@panel/app/pages/subscription/pricing/pricing.component';
import { PricingOldComponent } from '@panel/app/pages/subscription/pricing-old/pricing-old.component';
import { SubscriptionRoutingModule } from '@panel/app/pages/subscription/subscription.routing-module';
import { ToDevelopersPageModule } from '@panel/app/pages/to-developers/to-developers-page.module';
import { TriggerChainStepNameEditorAjsComponent } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-name-editor-ajs/trigger-chain-step-name-editor-ajs.component';
import { TriggerChainStepNameEditorAjsModule } from '@panel/app/pages/trigger-chains/editor/components/trigger-chain-step-editor/components-per-step-type/shared/trigger-chain-step-name-editor-ajs/trigger-chain-step-name-editor-ajs.module';
import { TriggerChainsModule } from '@panel/app/pages/trigger-chains/trigger-chains.module';
import { AiContentMakerComponent } from '@panel/app/pages/trigger-messages/email-part-editor/components/ai-content-maker/ai-content-maker.component';
import { LegacyWebhookPartEditorComponent } from '@panel/app/pages/trigger-messages/legacy-webhook-part-editor/legacy-webhook-part-editor.component';
import { TelegramPartEditorComponent } from '@panel/app/pages/trigger-messages/telegram-part-editor/telegram-part-editor.component';
import { TriggerMessagesModule } from '@panel/app/pages/trigger-messages/trigger-messages.module';
import { WebhookPartEditorComponent } from '@panel/app/pages/trigger-messages/webhook-part-editor/webhook-part-editor.component';
import { VisualComponentsPageModule } from '@panel/app/pages/visual-components/visual-components-page.module';
import { ChatWidgetButtonTextComponent } from '@panel/app/pages/widget-bot/editor/components/button-text/chat-widget-button-text.component';
import { ChatWidgetHeaderComponent } from '@panel/app/pages/widget-bot/editor/components/header/chat-widget-header.component';
import { ChatWidgetInsertingIntoChatComponent } from '@panel/app/pages/widget-bot/editor/components/inserting-into-chat-toggle/chat-widget-inserting-into-chat.component';
import { ActiveChatWidgetOrderComponent } from '@panel/app/pages/widget-bot/editor/components/order/active-chat-widget-order.component';
import { ChatWidgetOverheaderComponent } from '@panel/app/pages/widget-bot/editor/components/overheader/chat-widget-overheader.component';
import { WidgetBotEditorModule } from '@panel/app/pages/widget-bot/editor/widget-bot-editor.module';
import { AccessDenialAlertComponent } from '@panel/app/partials/access-denial/alert/access-denial-alert.component';
import { AccessDenialBannerComponent } from '@panel/app/partials/access-denial/banner/access-denial-banner.component';
import { AccessDenialBannerModule } from '@panel/app/partials/access-denial/banner/access-denial-banner.module';
import { AccessDenialModalComponent } from '@panel/app/partials/access-denial/modal/access-denial-modal.component';
import { AccessDenialModalModule } from '@panel/app/partials/access-denial/modal/access-denial-modal.module';
import { AccessDenialPopoverComponent } from '@panel/app/partials/access-denial/popover/access-denial-popover.component';
import { AccessDenialPopoverModule } from '@panel/app/partials/access-denial/popover/access-denial-popover.module';
import { AceEditorWrapperForAjsComponent } from '@panel/app/partials/ace-editor-wrapper-for-ajs/ace-editor-wrapper-for-ajs.component';
import { AceEditorWrapperForAjsModule } from '@panel/app/partials/ace-editor-wrapper-for-ajs/ace-editor-wrapper-for-ajs.module';
import { BannerEarlyAccessComponent } from '@panel/app/partials/banner-early-access/banner-early-access.component';
import { BannerEarlyAccessModule } from '@panel/app/partials/banner-early-access/banner-early-access.module';
import { ChannelModule } from '@panel/app/partials/channel/channel.module';
import { ChannelIconComponent } from '@panel/app/partials/channel-icon/channel-icon.component';
import { ChannelIconModule } from '@panel/app/partials/channel-icon/channel-icon.module';
import { ChannelMuteModule } from '@panel/app/partials/channel-mute/channel-mute.module';
import { TgIntegrationComponent } from '@panel/app/partials/chat-bot/telegram-bot/integration/tg-integration.component';
import { TgTriggerComponent } from '@panel/app/partials/chat-bot/telegram-bot/trigger/tg-trigger.component';
import { ChatWidgetListComponent } from '@panel/app/partials/chat-preview/chat-widget-list/chat-widget-list.component';
import { ChatWidgetListModule } from '@panel/app/partials/chat-preview/chat-widget-list/chat-widget-list.module';
import { ConversationReplyTimerComponent } from '@panel/app/partials/conversation-reply-timer/conversation-reply-timer.component';
import { ConversationReplyTimerModule } from '@panel/app/partials/conversation-reply-timer/conversation-reply-timer.module';
import { ConversationStatisticChannelSelectComponent } from '@panel/app/partials/conversation-statistic-channel-select/conversation-statistic-channel-select.component';
import { ConversationStatisticChannelSelectModule } from '@panel/app/partials/conversation-statistic-channel-select/conversation-statistic-channel-select.module';
import { ConversationStatisticSourceSelectComponent } from '@panel/app/partials/conversation-statistic-source-select/conversation-statistic-source-select.component';
import { ConversationStatisticSourceSelectModule } from '@panel/app/partials/conversation-statistic-source-select/conversation-statistic-source-select.module';
import { ConversationTagListComponent } from '@panel/app/partials/conversation-tag-list/conversation-tag-list.component';
import { ConversationTagListModule } from '@panel/app/partials/conversation-tag-list/conversation-tag-list.module';
import { ConversationTagsComponent } from '@panel/app/partials/conversation-tags/conversation-tags.component';
import { ConversationTagsModule } from '@panel/app/partials/conversation-tags/conversation-tags.module';
import { ConversationUserListComponent } from '@panel/app/partials/conversation-user-list/conversation-user-list.component';
import { ConversationUserListModule } from '@panel/app/partials/conversation-user-list/conversation-user-list.module';
import { DataCollectionEcommerceComponent } from '@panel/app/partials/data-collection/ecommerce/data-collection-ecommerce.component';
import { DataCollectionSaasComponent } from '@panel/app/partials/data-collection/sass/data-collection-saas.component';
import { EmojiComponent } from '@panel/app/partials/emoji/emoji.component';
import { EmojiModule } from '@panel/app/partials/emoji/emoji.module';
import { EmojiSelectorComponent } from '@panel/app/partials/emoji-selector/emoji-selector.component';
import { EmojiSelectorModule } from '@panel/app/partials/emoji-selector/emoji-selector.module';
import { EmailOperationSelectWrapComponent } from '@panel/app/partials/filters/components/email-operation-select/email-operation-select-wrap/email-operation-select-wrap.component';
import { TelegramOperationSelectWrapComponent } from '@panel/app/partials/filters/components/telegram-operation-select/telegram-operation-select-wrap/telegram-operation-select-wrap.component';
import { MessageFiltersComponent } from '@panel/app/partials/message-editor/message-filters/message-filters.component';
import { MessageFiltersModule } from '@panel/app/partials/message-editor/message-filters/message-filters.module';
import { MessageNameEditorComponent } from '@panel/app/partials/message-editor/message-name-editor/message-name-editor.component';
import { MessageNameEditorModule } from '@panel/app/partials/message-editor/message-name-editor/message-name-editor.module';
import { MessageEditorCollapseComponent } from '@panel/app/partials/message-editor/shared/message-editor-collapse/message-editor-collapse.component';
import { MessageEditorCollapseModule } from '@panel/app/partials/message-editor/shared/message-editor-collapse/message-editor-collapse.module';
import { MessageEditorSwitchModule } from '@panel/app/partials/message-editor/shared/message-editor-switch/message-editor-switch.module';
import { MessageEditorSendingHoursComponent } from '@panel/app/partials/message-editor/trigger/sending-hours/message-editor-sending-hours.component';
import { MessageEditorSendingHoursModule } from '@panel/app/partials/message-editor/trigger/sending-hours/message-editor-sending-hours.module';
import { MessageEditorSendingRepeatComponent } from '@panel/app/partials/message-editor/trigger/sending-repeat/message-editor-sending-repeat.component';
import { MessageEditorSendingRepeatModule } from '@panel/app/partials/message-editor/trigger/sending-repeat/message-editor-sending-repeat.module';
import { MessagePreviewComponent } from '@panel/app/partials/message-preview/message-preview.component';
import { MessagePreviewModule } from '@panel/app/partials/message-preview/message-preview.module';
import { AlreadyActiveTelegramBotModalModule } from '@panel/app/partials/modals/already-active-telegram-bot-modal/already-active-telegram-bot-modal.module';
import { ChangeRoutingBotActiveStatusConfirmComponent } from '@panel/app/partials/modals/change-routing-bot-active-status-confirm/change-routing-bot-active-status-confirm.component';
import { ChangeRoutingBotActiveStatusConfirmModule } from '@panel/app/partials/modals/change-routing-bot-active-status-confirm/change-routing-bot-active-status-confirm.module';
import { ChatBotTemplateComponent } from '@panel/app/partials/modals/chat-bot-template/chat-bot-template.component';
import { ChatBotTemplateModule } from '@panel/app/partials/modals/chat-bot-template/chat-bot-template.module';
import { CompletionRegistrationModalComponent } from '@panel/app/partials/modals/completion-registration-modal/completion-registration-modal.component';
import { CompletionRegistrationModalModule } from '@panel/app/partials/modals/completion-registration-modal/completion-registration-modal.module';
import { ConversationPartAiContentModalModule } from '@panel/app/partials/modals/conversation-part-ai-content-modal/conversation-part-ai-content-modal.module';
import { CreateUrlAutoEventComponent } from '@panel/app/partials/modals/create-url-auto-event/create-url-auto-event.component';
import { CreateUrlAutoEventModule } from '@panel/app/partials/modals/create-url-auto-event/create-url-auto-event.module';
import { EditEventTypeModule } from '@panel/app/partials/modals/edit-event-type/edit-event-type.module';
import { EditUserPropertyModalModule } from '@panel/app/partials/modals/edit-user-property/edit-user-property-modal.module';
import { HotkeysModalComponent } from '@panel/app/partials/modals/hotkeys-modal/hotkeys-modal.component';
import { HotkeysModalModule } from '@panel/app/partials/modals/hotkeys-modal/hotkeys-modal.module';
import { InsertPropsIntoTextModalComponent } from '@panel/app/partials/modals/insert-props-into-text/insert-props-into-text-modal.component';
import { InsertPropsIntoTextModalModule } from '@panel/app/partials/modals/insert-props-into-text/insert-props-into-text-modal.module';
import { LeaveSiteAttemptSettingComponent } from '@panel/app/partials/modals/leave-site-attempt-setting/leave-site-attempt-setting.component';
import { LeaveSiteAttemptSettingModule } from '@panel/app/partials/modals/leave-site-attempt-setting/leave-site-attempt-setting.module';
import { MemberInvitationModalComponent } from '@panel/app/partials/modals/member-invitation/member-invitation-modal.component';
import { MemberInvitationModalModule } from '@panel/app/partials/modals/member-invitation/member-invitation-modal.module';
import { MoveStartGuideModalComponent } from '@panel/app/partials/modals/move-start-guide-modal/move-start-guide-modal.component';
import { MoveStartGuideModalModule } from '@panel/app/partials/modals/move-start-guide-modal/move-start-guide-modal.module';
import { OriginalInboundEmailViewModalComponent } from '@panel/app/partials/modals/original-inbound-email-view/original-inbound-email-view-modal.component';
import { OriginalInboundEmailViewModalModule } from '@panel/app/partials/modals/original-inbound-email-view/original-inbound-email-view-modal.module';
import { QuizInRegistrationModalComponent } from '@panel/app/partials/modals/quiz-in-registration-modal/quiz-in-registration-modal.component';
import { QuizInRegistrationModalModule } from '@panel/app/partials/modals/quiz-in-registration-modal/quiz-in-registration-modal.module';
import { QuizSkipConfirmModalComponent } from '@panel/app/partials/modals/quiz-in-registration-modal/quiz-skip-confirm-modal/quiz-skip-confirm-modal.component';
import { QuizSkipConfirmModalModule } from '@panel/app/partials/modals/quiz-in-registration-modal/quiz-skip-confirm-modal/quiz-skip-confirm-modal.module';
import { ReadyAutoMessagesModalModule } from '@panel/app/partials/modals/ready-auto-messages-modal/ready-auto-messages-modal.module';
import { SendScriptInstructionComponent } from '@panel/app/partials/modals/send-script-instruction/send-script-instruction.component';
import { SendScriptInstructionModule } from '@panel/app/partials/modals/send-script-instruction/send-script-instruction.module';
import { UserConsentModalModule } from '@panel/app/partials/modals/user-consent-modal/user-consent-modal.module';
import { WriteToIntegrationsModalComponent } from '@panel/app/partials/modals/write-to-integrations-modal/write-to-integrations-modal.component';
import { WriteToIntegrationsModalModule } from '@panel/app/partials/modals/write-to-integrations-modal/write-to-integrations-modal.module';
import { NavigationComponent } from '@panel/app/partials/navigation/navigation.component';
import { NavigationModule } from '@panel/app/partials/navigation/navigation.module';
import { NavigationLogoModule } from '@panel/app/partials/navigation-logo/navigation-logo.module';
import { NewYearCertificatePayButtonModule } from '@panel/app/partials/new-year/certificate-pay-button/new-year-certificate-pay-button.module';
import { NewYearBannerModule } from '@panel/app/partials/new-year/new-year-banner/new-year-banner.module';
import { NewYearSaleLabelModule } from '@panel/app/partials/new-year/new-year-sale-label/new-year-sale-label.module';
import { OutdatedBrowserBannerComponent } from '@panel/app/partials/outdated-browser-banner/outdated-browser-banner.component';
import { OutdatedBrowserBannerModule } from '@panel/app/partials/outdated-browser-banner/outdated-browser-banner.module';
import { ReadyMessageTemplateComponent } from '@panel/app/partials/ready-message-template/ready-message-template.component';
import { ReadyMessageTemplateModule } from '@panel/app/partials/ready-message-template/ready-message-template.module';
import { SavedRepliesNewModule } from '@panel/app/partials/saved-replies-new/saved-replies-new.module';
import { INTEGRATIONS_HEADLINERS } from '@panel/app/partials/service-installation/service-installation-integrations/service-installation-integrations.constants';
import { ServiceInstallationIntegrationsModule } from '@panel/app/partials/service-installation/service-installation-integrations/service-installation-integrations.module';
import { SubscriptionStatusModule } from '@panel/app/partials/subscription-status/subscription-status.module';
import { TagDefaultComponent } from '@panel/app/partials/tag-default/tag-default.component';
import { TemplateModule } from '@panel/app/partials/template/template.module';
import { TemplateCardComponent } from '@panel/app/partials/template/template-card/template-card.component';
import { UserAvatarComponent } from '@panel/app/partials/user-card/user-avatar/user-avatar.component';
import { UserAvatarModule } from '@panel/app/partials/user-card/user-avatar/user-avatar.module';
import { UserCardHeadComponent } from '@panel/app/partials/user-card/user-card-head/user-card-head.component';
import { UserCardHeadModule } from '@panel/app/partials/user-card/user-card-head/user-card-head.module';
import { UserConsentComponent } from '@panel/app/partials/user-card/user-consent/user-consent.component';
import { UserConsentModule } from '@panel/app/partials/user-card/user-consent/user-consent.module';
import { UserLocationComponent } from '@panel/app/partials/user-card/user-location/user-location.component';
import { UserNotesListComponent } from '@panel/app/partials/user-card/user-notes-list/user-notes-list.component';
import { UserPropsComponent } from '@panel/app/partials/user-card/user-props/user-props.component';
import { UserPropsModule } from '@panel/app/partials/user-card/user-props/user-props.module';
import { UserSegmentsComponent } from '@panel/app/partials/user-card/user-segments/user-segments.component';
import { UserSegmentsModule } from '@panel/app/partials/user-card/user-segments/user-segments.module';
import { UserSubscriptionsComponent } from '@panel/app/partials/user-card/user-subscriptions/user-subscriptions.component';
import { UserSubscriptionsModule } from '@panel/app/partials/user-card/user-subscriptions/user-subscriptions.module';
import { UserTagsComponent } from '@panel/app/partials/user-card/user-tags/user-tags.component';
import { UserTagsModule } from '@panel/app/partials/user-card/user-tags/user-tags-module';
import { UserTagComponent } from '@panel/app/partials/user-tag/user-tag.component';
import { UserTagModule } from '@panel/app/partials/user-tag/user-tag.module';
import { WhatsAppTemplatesPopoverComponent } from '@panel/app/partials/whats-app-templates-popover/whats-app-templates-popover.component';
import { WhatsAppTemplatesPopoverModule } from '@panel/app/partials/whats-app-templates-popover/whats-app-templates-popover.module';
import { EventTypeSelectTriggerWrapperModule } from '@panel/app/partials/wrappers/event-type-select-trigger-wrapper/event-type-select-trigger-wrapper.module';
import { AjsDisplaySafehtmlComponent } from '@panel/app/shared/components/ajs-display-safehtml/ajs-display-safehtml.component';
import { AjsDisplaySafehtmlModule } from '@panel/app/shared/components/ajs-display-safehtml/ajs-display-safehtml.module';
import { StaticProgressbarComponent } from '@panel/app/shared/components/static-progressbar/static-progressbar.component';
import { StaticProgressbarModule } from '@panel/app/shared/components/static-progressbar/static-progressbar.module';
import { ModalComponent } from '@panel/app/shared/modals/modal/modal.component';
import { ModalModule } from '@panel/app/shared/modals/modal/modal.module';
import { httpInterceptorProviders } from '@panel/app/shared/providers/http-interceptor.providers';
import { SharedModule } from '@panel/app/shared/shared.module';
import { BetaLabelComponent } from '@panel/app/shared/visual-components/beta-label/beta-label.component';
import { LoaderComponent } from '@panel/app/shared/visual-components/loader/loader.component';
import { PlanCapabilityCoinComponent } from '@panel/app/shared/visual-components/plan-capability-coin/plan-capability-coin.component';
import { PreviewWrapperComponent } from '@panel/app/shared/visual-components/preview-wrapper/preview-wrapper.component';
import { SwitchWrapperComponent } from '@panel/app/shared/visual-components/switch-wrapper/switch-wrapper.component';

import { AjsServiceProviders } from './ajs-upgraded-providers';
import { AutoMessageEmailNotificationModule } from './pages/auto-messages/components/edit/email-notification/auto-message-email-notification.module';
import { ChatBotModule } from './pages/chat-bot/chat-bot.module';
import { ContentComponent } from './pages/chat-bot/content/content.component';
import { ButtonMenuComponent } from './pages/conversations/conversations-list/button-menu/button-menu.component';
import { AccessDenialAlertModule } from './partials/access-denial/alert/access-denial-alert.module';
import { TelegramBotModule } from './partials/chat-bot/telegram-bot/telegram-bot.module';
import { MessageEditorTriggerWrapperComponent } from './partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-wrapper.component';
import { MessageEditorTriggerWrapperModule } from './partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-wrapper.module';
import { SubscriptionStatusComponent } from './partials/subscription-status/subscription-status.component';
import { tokenProviders } from './shared/tokens';
import { NewLabelComponent } from './shared/visual-components/new-label/new-label.component';
import { ProgressbarComponent } from './shared/visual-components/progressbar/progressbar.component';
import { ToastComponent } from './shared/visual-components/toast/toast.component';
import { ToastService } from './shared/visual-components/toast/toast-service';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  imports: [
    AceModule,
    AdminsModule,
    NavigationModule,
    CommonModule,
    ComponentsPreviewModule,
    ConversationsModule,
    BrowserModule,
    UIRouterModule,
    UIRouterUpgradeModule,
    HttpClientModule,
    MomentModule,
    NgbModule,
    SortablejsModule.forRoot({ animation: 150 }),
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    ChatBotModule,
    IntegrationsModule,
    NgSelectModule,
    ClipboardModule,
    LoadingBarHttpClientModule,
    SharedModule,
    SettingsModule,
    LeaveSiteAttemptSettingModule,
    EditUserPropertyModalModule,
    AccountPageModule,
    ToDevelopersPageModule,
    TriggerChainsModule,
    TriggerMessagesModule,
    SubscriptionRoutingModule,
    QuestionsModule,
    UserConsentModalModule,
    KnowledgeBasePageModule,
    LogoutModule,
    ConversationsSettingsPageModule,
    ConversationPartAiContentModalModule,
    EditEventTypeModule,
    AiDataSourcesModule,

    // Модули компонентов, которые используются при downgradeComponent ниже
    AccessDenialAlertModule,
    AccessDenialBannerModule,
    AccessDenialModalModule,
    AccessDenialPopoverModule,
    AlreadyActiveTelegramBotModalModule,
    InsertPropsIntoTextModalModule,
    BannerEarlyAccessModule,
    ChangeRoutingBotActiveStatusConfirmModule,
    ChannelIconModule,
    ChannelModule,
    ChannelMuteModule,
    ChatBotTemplateModule,
    CompletionRegistrationModalModule,
    ChannelAssignmentModule,
    ConversationCardModule,
    ConversationOperatorModule,
    ConversationReplyTimerModule,
    ConversationsChannelListModule,
    ConversationsSettingsBreadcrumbsModule,
    ConversationStatisticChannelSelectModule,
    ConversationStatisticSourceSelectModule,
    ConversationTagListModule,
    ConversationTagsModule,
    ConversationUserListModule,
    ConversationsListModule,
    ConversationsUserCardModule,
    CreateUrlAutoEventModule,
    DialogStartModule,
    EventTypeSelectTriggerWrapperModule,
    EngagementInConversationModule,
    HotkeysModalModule,
    LeadsTableColumnFilterModule,
    ManageTagsModule,
    MemberInvitationModalModule,
    MoveStartGuideModalModule,
    MessageEditorCollapseModule,
    MessageEditorSendingHoursModule,
    MessageEditorSendingRepeatModule,
    MessageEditorSwitchModule,
    MessageInputModule,
    MessageNameEditorModule,
    ModalModule,
    NavigationLogoModule,
    NewYearBannerModule,
    NewYearCertificatePayButtonModule,
    NewYearSaleLabelModule,
    NotAssignedModule,
    OperatorAssignmentModule,
    OriginalInboundEmailViewModalModule,
    OutdatedBrowserBannerModule,
    PermissionDeniedModule,
    QuizInRegistrationModalModule,
    QuizSkipConfirmModalModule,
    SavedRepliesNewModule,
    SendScriptInstructionModule,
    ServiceInstallationIntegrationsModule,
    SocialSettingsModule,
    StarterGuideForMarketersModule,
    StarterGuideHeaderModule,
    StarterGuideLeadsCollectionModule,
    StarterGuideLeadsPassingModule,
    StarterGuideSocialIntegrationsModule,
    StarterGuideWarmUpModule,
    StatsCollectorModule,
    StaticProgressbarModule,
    SubscriptionStatusModule,
    TemplateModule,
    UserAvatarModule,
    UserCardHeadModule,
    UserConsentModule,
    UserPropsModule,
    UserSegmentsModule,
    UserSubscriptionsModule,
    UserTagModule,
    UserTagsModule,
    WhatsAppTemplatesPopoverModule,
    WidgetBotEditorModule,
    WriteToIntegrationsModalModule,
    MessageFiltersModule,
    ReadyAutoMessagesModalModule,
    MessageEditorTriggerWrapperModule,
    TelegramBotModule,
    TriggerChainStepNameEditorAjsModule,
    VisualComponentsPageModule,
    EmojiSelectorModule,
    EmojiModule,
    ChatWidgetListModule,
    ChannelsTableModule,
    DataCollectionPageModule,
    EventsAnalyticsPageModule,
    AutoMessagesPageModule,
    AutoMessageControlGroupModule,
    AutoMessageUserStatusesModule,
    AutoMessageEmailNotificationModule,
    AutoMessageAmoTransferModule,
    AutoMessageGoalModule,
    MessagePreviewModule,
    AjsDisplaySafehtmlModule,
    ReadyMessageTemplateModule,
    AceEditorWrapperForAjsModule,
  ],
  declarations: [AppComponent],
  providers: [
    NgbActiveModal,
    ToastService,
    CookieService,
    ...AjsServiceProviders,
    ...tokenProviders,
    ...httpInterceptorProviders,
    {
      provide: ACE_CONFIG,
      useValue: {
        mode: 'javascript',
        theme: 'monokai',
      },
    },
  ],
})
export class AppModule {
  constructor(ngSelectConfig: NgSelectConfig, clipboardService: ClipboardService, uiRouter: UIRouter) {
    //NOTE перевести ng-select через translocoService не выходит, пока оставлю так
    if (environment.language === 'ru') {
      ngSelectConfig.notFoundText = 'Ничего не найдено';
      ngSelectConfig.typeToSearchText = 'Введите запрос';
      ngSelectConfig.addTagText = 'Добавить';
      ngSelectConfig.loadingText = 'Загрузка';
      ngSelectConfig.clearAllText = 'Очистить';
    }

    /** Настройка сервиса ClipboardService
     *
     * При копировании сервис создаёт textarea в body. Настраиваем так, чтобы она удалялась после каждого копирования
     */
    clipboardService.configure({
      cleanUpAfterCopy: true,
    });

    uiRouter.plugin(UIRouterRx);
  }

  // этот метод должен оставаться пустым, лишь бы компилятор не упал https://angular.io/guide/upgrade-performance#bootstrapping-with-downgrademodule
  // поэтому просто игнорим ошибки eslint
  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface, @angular-eslint/no-empty-lifecycle-method
  ngDoBootstrap() {}
}

const bootstrapFn = (extraProviders: StaticProvider[]) => {
  const platformRef = platformBrowserDynamic(extraProviders);
  return platformRef.bootstrapModule(AppModule);
};

export const downgradedAppModule = downgradeModule(bootstrapFn);

angular
  .module(downgradedAppModule)
  .constant('DEFAULT_ORDER_SOCIAL_NETWORKS', DEFAULT_ORDER_SOCIAL_NETWORKS)
  .constant('INTEGRATIONS_HEADLINERS', INTEGRATIONS_HEADLINERS)
  .directive('cqApp', downgradeComponent({ component: AppComponent }))
  .directive('cqAccessDenialAlert', downgradeComponent({ component: AccessDenialAlertComponent }))
  .directive('cqAccessDenialBanner', downgradeComponent({ component: AccessDenialBannerComponent }))
  .directive('cqAccessDenialModal', downgradeComponent({ component: AccessDenialModalComponent }))
  .directive('cqAccessDenialPopover', downgradeComponent({ component: AccessDenialPopoverComponent }))
  .directive('cqAiContentMaker', downgradeComponent({ component: AiContentMakerComponent }))
  .directive('cqBannerEarlyAccess', downgradeComponent({ component: BannerEarlyAccessComponent }))
  .directive('cqBetaLabel', downgradeComponent({ component: BetaLabelComponent }))
  .directive('cqBotContent', downgradeComponent({ component: ContentComponent }))
  .directive(
    'cqChangeRoutingBotActiveStatusConfirm',
    downgradeComponent({ component: ChangeRoutingBotActiveStatusConfirmComponent }),
  )
  .directive('cqChannelAssignment', downgradeComponent({ component: ChannelAssignmentComponent }))
  .directive('cqChannelIcon', downgradeComponent({ component: ChannelIconComponent }))
  .directive('cqChatBotTemplate', downgradeComponent({ component: ChatBotTemplateComponent }))
  .directive('cqChatAudioMessage', downgradeComponent({ component: ChatAudioMessageComponent }))
  .directive('cqConversationCard', downgradeComponent({ component: ConversationCardComponent }))
  .directive('cqConversationOperator', downgradeComponent({ component: ConversationOperatorComponent }))
  .directive('cqConversationsButtonMenu', downgradeComponent({ component: ButtonMenuComponent }))
  .directive('cqConversationReplyTimer', downgradeComponent({ component: ConversationReplyTimerComponent }))
  .directive('cqConversationsStatsCollector', downgradeComponent({ component: StatsCollectorComponent }))
  .directive('cqConversationTags', downgradeComponent({ component: ConversationTagsComponent }))
  .directive('cqConversationUserList', downgradeComponent({ component: ConversationUserListComponent }))
  .directive('cqConversationsChannelList', downgradeComponent({ component: ConversationsChannelListComponent }))
  .directive(
    'cqOriginalInboundEmailViewModal',
    downgradeComponent({ component: OriginalInboundEmailViewModalComponent }),
  )
  .directive(
    'cqConversationStatisticChannelSelect',
    downgradeComponent({ component: ConversationStatisticChannelSelectComponent }),
  )
  .directive(
    'cqConversationStatisticSourceSelect',
    downgradeComponent({ component: ConversationStatisticSourceSelectComponent }),
  )
  .directive('cqConversationsUserCard', downgradeComponent({ component: ConversationsUserCardComponent }))
  .directive('cqDataCollectionEcommerce', downgradeComponent({ component: DataCollectionEcommerceComponent }))
  .directive('cqDataCollectionSaas', downgradeComponent({ component: DataCollectionSaasComponent }))
  .directive('cqDialogStart', downgradeComponent({ component: DialogStartComponent }))
  .directive('cqCompletionRegistrationModal', downgradeComponent({ component: CompletionRegistrationModalComponent }))
  .directive('cqConversationTagList', downgradeComponent({ component: ConversationTagListComponent }))
  .directive('cqCreateUrlAutoEvent', downgradeComponent({ component: CreateUrlAutoEventComponent }))
  .directive('cqEmailNotification', downgradeComponent({ component: EmailNotificationComponent }))
  .directive(
    'cqEmailNotificationModalWrapper',
    downgradeComponent({ component: EmailNotificationCreateModalWrapperComponent }),
  )
  .directive(
    'cqEmailNotificationSelectModalWrapper',
    downgradeComponent({ component: EmailNotificationSelectModalWrapperComponent }),
  )
  .directive('cqHotkeysModal', downgradeComponent({ component: HotkeysModalComponent }))
  .directive('cqLeadsTableColumnFilter', downgradeComponent({ component: LeadsTableColumnFilterComponent }))
  .directive('cqMemberActions', downgradeComponent({ component: MemberActionsComponent }))
  .directive('cqMemberInvitations', downgradeComponent({ component: MemberInvitationsComponent }))
  .directive('cqLoader', downgradeComponent({ component: LoaderComponent }))
  .directive('cqLeaveSiteAttemptSetting', downgradeComponent({ component: LeaveSiteAttemptSettingComponent }))
  .directive('cqMemberInvitationModal', downgradeComponent({ component: MemberInvitationModalComponent }))
  .directive('cqInsertPropsIntoTextModal', downgradeComponent({ component: InsertPropsIntoTextModalComponent }))
  .directive('cqMoveStartGuideModal', downgradeComponent({ component: MoveStartGuideModalComponent }))
  .directive('cqMessageInput', downgradeComponent({ component: MessageInputComponent }))
  .directive('cqMessageNameEditor', downgradeComponent({ component: MessageNameEditorComponent }))
  .directive('cqModal', downgradeComponent({ component: ModalComponent }))
  .directive('cqNewLabel', downgradeComponent({ component: NewLabelComponent }))
  .directive('cqNotAssigned', downgradeComponent({ component: NotAssignedComponent }))
  .directive('cqOperatorAssignment', downgradeComponent({ component: OperatorAssignmentComponent }))
  .directive('cqPermissionDenied', downgradeComponent({ component: PermissionDeniedComponent }))
  .directive('cqPlanCapabilityCoin', downgradeComponent({ component: PlanCapabilityCoinComponent }))
  .directive('cqPreviewWrapper', downgradeComponent({ component: PreviewWrapperComponent }))
  .directive('cqProgressbar', downgradeComponent({ component: ProgressbarComponent }))
  .directive('cqQuizInRegistrationModal', downgradeComponent({ component: QuizInRegistrationModalComponent }))
  .directive('cqQuizSkipConfirmModal', downgradeComponent({ component: QuizSkipConfirmModalComponent }))
  .directive('cqSendScriptInstruction', downgradeComponent({ component: SendScriptInstructionComponent }))
  .directive('cqSelectPreview', downgradeComponent({ component: SelectPreviewComponent }))
  .directive('cqSwitchPreview', downgradeComponent({ component: SwitchPreviewComponent }))
  .directive('cqSocialSettings', downgradeComponent({ component: SocialSettingsComponent }))
  .directive('cqStarterGuideForMarketers', downgradeComponent({ component: StarterGuideForMarketersComponent }))
  .directive('cqStarterGuideHeader', downgradeComponent({ component: StarterGuideHeaderComponent }))
  .directive('cqStarterGuideLeadsCollection', downgradeComponent({ component: StarterGuideLeadsCollectionComponent }))
  .directive('cqStarterGuideLeadsPassing', downgradeComponent({ component: StarterGuideLeadsPassingComponent }))
  .directive(
    'cqStarterGuideSocialIntegrations',
    downgradeComponent({ component: StarterGuideSocialIntegrationsComponent }),
  )
  .directive('cqStarterGuideWarmUp', downgradeComponent({ component: StarterGuideWarmUpComponent }))
  .directive('cqSubscriptionStatus', downgradeComponent({ component: SubscriptionStatusComponent }))
  .directive('cqSwitchNew', downgradeComponent({ component: SwitchWrapperComponent }))
  .directive('cqTagDefault', downgradeComponent({ component: TagDefaultComponent }))
  .directive('cqTemplateCard', downgradeComponent({ component: TemplateCardComponent }))
  .directive('cqTgIntegration', downgradeComponent({ component: TgIntegrationComponent }))
  .directive('cqTgTrigger', downgradeComponent({ component: TgTriggerComponent }))
  .directive('cqToast', downgradeComponent({ component: ToastComponent }))
  .directive('cqUserAvatar', downgradeComponent({ component: UserAvatarComponent }))
  .directive('cqOutdatedBrowserBanner', downgradeComponent({ component: OutdatedBrowserBannerComponent }))
  .directive('cqUserCardHead', downgradeComponent({ component: UserCardHeadComponent }))
  .directive('cqUserConsent', downgradeComponent({ component: UserConsentComponent }))
  .directive('cqUserLocation', downgradeComponent({ component: UserLocationComponent }))
  .directive('cqUserProps', downgradeComponent({ component: UserPropsComponent }))
  .directive('cqUserNotesList', downgradeComponent({ component: UserNotesListComponent }))
  .directive('cqUserSegments', downgradeComponent({ component: UserSegmentsComponent }))
  .directive('cqUserSubscriptions', downgradeComponent({ component: UserSubscriptionsComponent }))
  .directive('cqUserTag', downgradeComponent({ component: UserTagComponent }))
  .directive('cqUserTags', downgradeComponent({ component: UserTagsComponent }))
  .directive('cqVisibilitySettings', downgradeComponent({ component: VisibilitySettingsComponent }))
  .directive('cqWhatsAppTemplatesPopover', downgradeComponent({ component: WhatsAppTemplatesPopoverComponent }))
  .directive('cqWriteToIntegrationsModal', downgradeComponent({ component: WriteToIntegrationsModalComponent }))
  .directive('ngbAlert', downgradeComponent({ component: NgbAlert }))
  .directive('ngbProgressbar', downgradeComponent({ component: NgbProgressbar }))
  .directive('cqAmocrmModalLeadSettings', downgradeComponent({ component: AmocrmModalLeadSettingsComponent }))
  .directive('cqMessageEditorCollapse', downgradeComponent({ component: MessageEditorCollapseComponent }))
  .directive('cqMessageEditorSendingHours', downgradeComponent({ component: MessageEditorSendingHoursComponent }))
  .directive('cqMessageEditorSendingRepeat', downgradeComponent({ component: MessageEditorSendingRepeatComponent }))
  .directive('cqNavigation', downgradeComponent({ component: NavigationComponent }))
  .directive(
    'cqStarterGuideEngagementInConversation',
    downgradeComponent({ component: EngagementInConversationComponent }),
  )
  .directive('cqMessageEditorTriggerWrapper', downgradeComponent({ component: MessageEditorTriggerWrapperComponent }))
  .directive(
    'cqTriggerChainStepNameEditorAjs',
    downgradeComponent({ component: TriggerChainStepNameEditorAjsComponent }),
  )
  .directive('cqEmoji', downgradeComponent({ component: EmojiComponent }))
  .directive('cqEmojiSelector', downgradeComponent({ component: EmojiSelectorComponent }))
  .directive('cqChatWidgetButtonText', downgradeComponent({ component: ChatWidgetButtonTextComponent }))
  .directive('cqChatWidgetInsertingIntoChat', downgradeComponent({ component: ChatWidgetInsertingIntoChatComponent }))
  .directive('cqActiveChatWidgetOrder', downgradeComponent({ component: ActiveChatWidgetOrderComponent }))
  .directive('cqChatWidgetOverheader', downgradeComponent({ component: ChatWidgetOverheaderComponent }))
  .directive('cqChatWidgetHeader', downgradeComponent({ component: ChatWidgetHeaderComponent }))
  .directive('cqChatWidgetList', downgradeComponent({ component: ChatWidgetListComponent }))
  .directive('cqKnowledgeBasePopover', downgradeComponent({ component: KnowledgeBasePopoverComponent }))
  .directive('cqPricingNew', downgradeComponent({ component: PricingComponent }))
  .directive('cqPricingOld', downgradeComponent({ component: PricingOldComponent }))
  .directive('cqTelegramOperationSelectWrap', downgradeComponent({ component: TelegramOperationSelectWrapComponent }))
  .directive('cqEmailOperationSelectWrap', downgradeComponent({ component: EmailOperationSelectWrapComponent }))

  .directive('cqStaticProgressbar', downgradeComponent({ component: StaticProgressbarComponent }))
  .directive('cqMessageFilters', downgradeComponent({ component: MessageFiltersComponent }))

  .directive('cqTelegramPartEditor', downgradeComponent({ component: TelegramPartEditorComponent }))
  .directive('cqWebhookPartEditor', downgradeComponent({ component: WebhookPartEditorComponent }))
  .directive('cqLegacyWebhookPartEditor', downgradeComponent({ component: LegacyWebhookPartEditorComponent }))
  .directive('cqUnansweredFilter', downgradeComponent({ component: UnansweredFilterComponent }))
  .directive('cqConversationStatusFilter', downgradeComponent({ component: ConversationStatusFilterComponent }))
  .directive('cqAssigneeFilter', downgradeComponent({ component: AssigneeFilterComponent }))
  .directive('cqTagsFilter', downgradeComponent({ component: TagsFilterComponent }))
  .directive('cqSearchFilter', downgradeComponent({ component: SearchFilterComponent }))

  .directive('cqAutoMessageControlGroup', downgradeComponent({ component: AutoMessageControlGroupComponent }))
  .directive('cqAutoMessageUserStatuses', downgradeComponent({ component: AutoMessageUserStatusesComponent }))
  .directive('cqAutoMessageEmailNotification', downgradeComponent({ component: AutoMessageEmailNotificationComponent }))
  .directive('cqAutoMessageAmoTransfer', downgradeComponent({ component: AutoMessageAmoTransferComponent }))
  .directive('cqAutoMessageGoal', downgradeComponent({ component: AutoMessageGoalComponent }))

  .directive('cqAiDataSourceList', downgradeComponent({ component: AiDataSourceListComponent }))

  .directive('cqMessagePreview', downgradeComponent({ component: MessagePreviewComponent }))
  .directive('ngxLoadingBar', downgradeComponent({ component: LoadingBarComponent }))
  .directive('cqReadyMessageTemplate', downgradeComponent({ component: ReadyMessageTemplateComponent }))
  .directive('cqAjsDisplaySafehtml', downgradeComponent({ component: AjsDisplaySafehtmlComponent }))

  .directive('ngxAce', downgradeComponent({ component: AceComponent }))
  .directive('cqAceEditorWrapperForAjs', downgradeComponent({ component: AceEditorWrapperForAjsComponent }))

  .factory('toastHelper', downgradeInjectable(ToastService));

import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid/interfaces';

import { PermissionDeniedComponent } from '@panel/app/pages/permission-denied/permission-denied.component';

export const PERMISSION_DENIED_PAGE_ROUTES: NgHybridStateDeclaration[] = [
  {
    name: 'app.content.permissionDenied',
    component: PermissionDeniedComponent,
    url: '/permission-denied',
  },
];

(function () {
  'use strict';

  angular
    .module('myApp.serviceInstallation')
    .controller('CqServiceInstallationController', CqServiceInstallationController);

  function CqServiceInstallationController(PROJECT_NAME, appModel, l10nHelper) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.appModel = appModel;
      vm.l10nHelper = l10nHelper;
      vm.PROJECT_NAME = PROJECT_NAME;
    }
  }
})();

import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { TimeSelectorConfig } from '@panel/app/partials/message-editor/shared/time-selector/time-selector.component';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';
import { DestroyService } from '@panel/app/services';
import {
  REPEAT_MAX_DELAY,
  REPEAT_MIN_DELAY,
} from '@panel/app/services/conditions-sending/conditions-sending.constants';
import { ToFormGroupControls } from '@panel/app/shared/types/to-form-group-controls.type';
import { TIME_UNITS } from '@panel/app-old/shared/services/time-unit/time-unit.constants';

type SendingRepeatConfig = {
  timeConfig: TimeSelectorConfig;
  notSendReplied: boolean;
};

@Component({
  selector: 'cq-message-editor-sending-repeat',
  templateUrl: './message-editor-sending-repeat.component.html',
  styleUrls: ['./message-editor-sending-repeat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class MessageEditorSendingRepeatComponent implements OnInit {
  /**
   * Включена ли настройка повторной отправки
   */
  @Input()
  isEnabled = true;

  /**
   * Человекочитаемое название раздела в котором используется компонент
   */
  @Input()
  entityName: string = '';

  @Input()
  repeat: SendingRepeatConfig = {
    timeConfig: {
      time: 1,
      unit: TIME_UNITS.HOUR,
    },
    notSendReplied: false,
  };

  @Input()
  set minRepeatDelay(delayInSec: number) {
    this.REPEAT_MIN_SECONDS_DELAY = delayInSec;
  }

  @Input()
  selectableUnits: TIME_UNITS[] = [TIME_UNITS.SECOND, TIME_UNITS.MINUTE, TIME_UNITS.HOUR, TIME_UNITS.DAY];

  @Output()
  isEnabledChange = new Subject<boolean>();

  @Output()
  repeatOutput = new Subject<SendingRepeatConfig>();

  readonly REPEAT_MAX_DAYS_DELAY = REPEAT_MAX_DELAY;

  REPEAT_MIN_SECONDS_DELAY = REPEAT_MIN_DELAY;

  readonly form = this.fb.group<ToFormGroupControls<SendingRepeatConfig>>({
    timeConfig: this.fb.control({ time: 1, unit: TIME_UNITS.SECOND }, { nonNullable: true }),
    notSendReplied: this.fb.control(false, { nonNullable: true }),
  });

  @Output()
  touchAndValidateCallback: Observable<ValidationCallback> = of(() => {
    if (!this.isEnabled) {
      return Promise.resolve(true);
    }
    this.form.markAllAsTouched();
    return this.form.status !== 'PENDING'
      ? Promise.resolve(this.form.valid)
      : firstValueFrom(
          this.form.statusChanges.pipe(
            filter((status) => status !== 'PENDING'),
            map(() => this.form.valid),
          ),
        );
  });

  constructor(private readonly fb: FormBuilder, private readonly destroy$: DestroyService) {
    this.form.valueChanges.pipe(takeUntil(destroy$)).subscribe(() => {
      const { notSendReplied, timeConfig } = this.form.getRawValue();
      this.repeatOutput.next({
        timeConfig,
        notSendReplied,
      });
    });
  }

  ngOnInit() {
    this.form.controls.timeConfig.setValue(this.repeat.timeConfig, {
      emitEvent: false,
    });
    this.form.controls.notSendReplied.setValue(this.repeat.notSendReplied, {
      emitEvent: false,
    });
  }
}

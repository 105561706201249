import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

import { AppService } from '@http/app/services/app.service';
import { EventTypeModel } from '@http/event-type/event-type.model';

@Component({
  selector: 'cq-create-event-by-name-modal',
  templateUrl: './create-event-type-modal.component.html',
  styleUrls: ['./create-event-type-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEventTypeModalComponent {
  nameControl = new UntypedFormControl(null);

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly appService: AppService,
    private readonly eventTypeModel: EventTypeModel,
  ) {}

  create() {
    if (!this.nameControl.valid) {
      return this.nameControl.markAsTouched();
    }

    firstValueFrom(this.eventTypeModel.create(this.appService.currentAppId, this.nameControl.value)).then(
      (response) => {
        this.activeModal.close(response);
      },
    );
  }
}

/**
 * Контроллер для модального окна с Bee Editor
 */
import { BEE_EDITOR_BUGFIX_REGEXP, STORAGE_KEYS } from '../../../../app/http/message-part/message-part.constants';
import { firstValueFrom } from 'rxjs';

(function () {
  'use strict';

  angular.module('myApp.modals.beeEditor').controller('CqBeeEditorModalController', CqBeeEditorModalController);

  function CqBeeEditorModalController(
    $http,
    $interpolate,
    $translate,
    LANGUAGE,
    USER_FILES_URL,
    l10nHelper,
    utilsModel,
  ) {
    var vm = this;

    vm.$onInit = init;

    function init() {
      vm.closeBeeEditor = closeBeeEditor;

      getBeeEditor();
    }

    var beeConfig = {
      //  uid: 'test1-clientside2',
      container: 'bee-plugin-container',
      autosave: 15,
      language: LANGUAGE == 'en' ? 'en-US' : 'ru-RU',
      onSave: function (jsonFile, htmlFile) {
        /** Фикс бага редактора BEE https://favro.com/organization/6fcfbd57a2b44fae2a92e7ea/82b511342275b939d5555602?card=Car-28087
         * Суть бага в том, что класс .big не позволял адаптироваться картинке под ширину экрана на mail.ru
         * Этой регуляркой этот класс вырезается из атрибут class в html письма
         * */
        const parsedHtmlFile = htmlFile.replace(BEE_EDITOR_BUGFIX_REGEXP, '$1');

        sessionStorage.setItem(STORAGE_KEYS.STORAGE_BEE_EDITOR, '');
        vm.modalInstance.close({
          jsonFile: jsonFile,
          htmlFile: parsedHtmlFile,
        });
      },
      specialLinks: [
        {
          label: $translate.instant('modals.beeEditor.unsubscribe'),
          link: '{{unsubscribe_url}}',
        },
      ],
      onAutoSave: function (jsonFile) {
        sessionStorage.setItem(STORAGE_KEYS.STORAGE_BEE_EDITOR, jsonFile);
      },
      onSend: function (htmlFile) {},
      onError: function (errorMessage) {
        console.log('onError ', errorMessage);
      },
    };

    /**
     * Закрытие модалки
     */
    function closeBeeEditor() {
      var params = {};
      var editorJsonFile = sessionStorage.getItem(STORAGE_KEYS.STORAGE_BEE_EDITOR);
      if (editorJsonFile) {
        params.editorJsonFile = editorJsonFile;
      }
      vm.modalInstance.close(params);
    }

    /**
     * Получение шаблона для редактора
     */
    function getBeeEditor() {
      var bee = null;

      firstValueFrom(utilsModel.getBeeFreeToken(vm.resolve.currentApp.id)).then(getTokenSuccess);

      function getTokenSuccess(response) {
        beeConfig.uid = response.data.uid;
        BeePlugin.create(response.data.token, beeConfig, function (beePluginInstance) {
          bee = beePluginInstance;
          if (vm.resolve.template) {
            bee.start(JSON.parse(vm.resolve.template));
          } else {
            var httpParams = {
              cache: true,
              transformResponse: angular.identity, // не надо парсить JSON заранее, всё равно $interpolate вызывается только от строки
            };

            $http
              .get('assets/bee-editor/bee-template-LANGUAGE.json'.replace('LANGUAGE', LANGUAGE), httpParams)
              .then(function (response) {
                var jsonString = $interpolate(response.data)({
                  logoUrl: l10nHelper.isUsCountry()
                    ? USER_FILES_URL + '/files/bee-editor/dashly-logo.png'
                    : USER_FILES_URL + '/files/bee-editor/carrot-quest-logo.png',
                });

                bee.start(JSON.parse(jsonString));
              });
          }
        });
      }
    }
  }
})();

(function () {
  'use strict';

  require('./email-templates.controller');

  angular.module('myApp.emailTemplates').component('cqEmailTemplates', cqEmailTemplates());

  function cqEmailTemplates() {
    return {
      bindings: {
        currentApp: '=', // приложение
        messageSender: '=', // отправитель письма
        showAppName: '<', // показывать название приложение или нет
      },
      controller: 'CqEmailTemplatesController',
      controllerAs: 'vm',
      template: require('./email-templates.html'),
    };
  }
})();

import { ACTIONS_ON_DIRECTORY } from '@panel/app-old/shared/modals/directory-editor/directory-editor.constants';

(function () {
  'use strict';

  angular
    .module('myApp.modals.directoryEditor', ['myApp.modals.directoryEditor.templates'])
    .constant('ACTIONS_ON_DIRECTORY', ACTIONS_ON_DIRECTORY);

  require('./templates/templates.module');
})();

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'cq-legacy-webhook-part-editor',
  templateUrl: './legacy-webhook-part-editor.component.html',
  styleUrls: ['./legacy-webhook-part-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegacyWebhookPartEditorComponent {
  @Input({ required: true })
  set body(body: string) {
    this.urlForm.setValue(body, { emitEvent: false });
  }

  readonly URL_MAX_LENGTH = 2000;

  urlForm = this.fb.control<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(this.URL_MAX_LENGTH)],
  });

  @Output()
  bodyChange = this.urlForm.valueChanges;

  constructor(private readonly fb: FormBuilder) {}
}

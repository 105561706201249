import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import Moment from 'moment';

import { SUBSCRIPTION_STATUSES } from '@panel/app/services/billing-info/billing-info.constants';

/** Help-сервис для работы с подпиской */
@Injectable({ providedIn: 'root' })
export class SubscriptionHelpService {
  constructor(private readonly transloco: TranslocoService) {}

  /**
   * Возвращает текст статуса подписки
   *
   * @param status - Статус подписки
   * @param endDate - Дата окончания
   */
  getSubscriptionStatusText(status: SUBSCRIPTION_STATUSES, endDate: number): string {
    const formattedDate = Moment(endDate).format('L');
    return this.transloco.translate(`subscription.status.${status}`, { endDate: formattedDate });
  }
}

<input
  class="form-control margin-bottom-40"
  [placeholder]="'account.apps.appSearchStringInput.placeholder' | transloco"
  [(ngModel)]="appSearchString"
  (click)="trackClickOnAppSearch()"
/>
<table class="table table-hover table-align-middle margin-bottom-20">
  <thead>
    <tr>
      <th>{{ 'account.apps.table.headings.appName' | transloco }}</th>
      <th>{{ 'account.apps.table.headings.appOrigin' | transloco }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr
      class="cursor-pointer"
      *ngFor="let app of filteredApps; let i = index; trackBy: trackByFn"
      (click)="openApp(app.id)"
    >
      <td>{{ app.name }}</td>
      <td>{{ app.origin }}</td>
      <td class="text-right">
        <a
          class="btn btn-primary"
          uiSref="app"
          [uiParams]="{ appId: app.id }"
          (click)="$event.stopPropagation()"
          >{{ 'account.apps.table.goToButton' | transloco }}</a
        >
      </td>
    </tr>
  </tbody>
</table>
<cq-zero-data
  *ngIf="appList.length > 0 && filteredApps.length === 0"
  class="margin-top-30 margin-bottom-20"
  src="assets/img/default/not-found.png"
>
  <span>{{ 'account.apps.zeroData.appNotFound' | transloco }}</span>
</cq-zero-data>
<div
  *ngIf="isWebApp"
  class="text-center"
>
  <cq-zero-data *ngIf="appList.length === 0">
    <div class="margin-bottom-20">{{ 'account.apps.zeroData.haveNoApps' | transloco }}</div>
    <button
      class="btn btn-lg btn-primary"
      (click)="openCreateAppModal(true)"
    >
      {{ 'account.apps.zeroData.addAppButton' | transloco }}
    </button>
  </cq-zero-data>
  <!-- NOTE Возможность блокировать добавление сайтов контролируется через max_apps у djangoUser на бэкенде -->
  <button
    *ngIf="appList.length < djangoUser.max_apps && filteredApps.length !== 0"
    class="btn btn-lg btn-outline-primary"
    (click)="openCreateAppModal(true)"
  >
    {{ 'account.apps.addMoreAppButton' | transloco }}
  </button>
  <button
    *ngIf="appList.length >= djangoUser.max_apps && filteredApps.length !== 0"
    class="btn btn-lg btn-outline-primary"
    (click)="openTooMuchAppsModal()"
  >
    {{ 'account.apps.addMoreAppButton' | transloco }}
  </button>
</div>

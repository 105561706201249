(function () {
  'use strict';

  angular.module('myApp.filters.replaceDivEmoji').filter('replaceDivEmoji', replaceDivEmoji);

  function replaceDivEmoji(emojiService) {
    return replaceDivEmojiFilter;

    /**
     * Преобразования div-элемента с эмоджи на эмоджи в внутреннем формате админки
     *
     * @param {String} value Строка для преобразования
     *
     * @returns {String}
     */
    function replaceDivEmojiFilter(value) {
      return emojiService.replaceDivEmojiToNativeEmoji(value);
    }
  }
})();

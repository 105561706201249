(function () {
  'use strict';

  angular.module('myApp.integrations').controller('WordPressController', WordPressController);

  function WordPressController($translate, PROJECT_NAME, carrotquestHelper, integration) {
    var vm = this;

    vm.$translate = $translate;
    vm.integration = integration;
    vm.PROJECT_NAME = PROJECT_NAME;
    vm.trackClickDownloadPlugin = trackClickDownloadPlugin;

    /**
     * Трек клика на кнопку 'Скачать плагин'
     */
    function trackClickDownloadPlugin() {
      carrotquestHelper.track('Интеграции - ' + integration.type + ' - клик на "Скачать плагин"');
    }
  }
})();

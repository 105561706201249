import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import cloneDeep from 'lodash-es/cloneDeep';
import { map, Observable, switchMap } from 'rxjs';

import {
  PSEUDO_TEMPLATE_DIRECTORY_IDS,
  PSEUDO_TEMPLATE_DIRECTORY_TYPES,
} from '@http/template-directory/template-directory.constants';
import { EXTENDED_RESPONSE, IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS } from '@panel/app/shared/constants/http.constants';

@Injectable({
  providedIn: 'root',
})
export class TemplateDirectoryModel {
  DEFAULT_DIRECTORY = {
    id: null,
    name: '',
    isSystem: false,
  };

  getAllDirectory = this.getPseudoDirectory.bind(this, PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY);
  getWithoutDirectory = this.getPseudoDirectory.bind(this, PSEUDO_TEMPLATE_DIRECTORY_TYPES.WITHOUT_DIRECTORY);

  constructor(private readonly httpClient: HttpClient, private readonly translocoService: TranslocoService) {}

  /**
   * Создание папки
   */
  create(appId: any, name: any, errorsException: any | any[]): Observable<any> {
    const params = {
      app: appId,
      name: name,
      errorsException: errorsException,
    };

    return this.httpClient
      .post('/messagetemplatesdirectories', params, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        switchMap((response: any) => {
          return this.getDirectory(appId, response.data.id);
        }),
      );
  }

  /**
   * Редактирование папки
   */
  edit(appId: any, directory: any, errorsException: any | any[]): Observable<any> {
    const params = {
      app: appId,
      name: directory.prettyName,
      errorsException: errorsException,
    };

    return this.httpClient
      .put(`/messagetemplatesdirectories/${directory.id}`, params, {
        context: new HttpContext()
          .set(IGNORE_RESPONSE_CASE_TRANSFORM_FIELDS, true)
          .set(EXTENDED_RESPONSE, true)
          .set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        switchMap(() => {
          return this.getDirectory(appId, directory.id);
        }),
      );
  }

  /**
   * Получение папок
   */
  getList(appId: any, includeWithoutDirectory: any, includeAllDirectory: any): Observable<any> {
    return this.httpClient
      .get(`/apps/${appId}/messagetemplatesdirectories`, {
        context: new HttpContext().set(EXTENDED_RESPONSE, true),
      })
      .pipe(
        map((response: any) => {
          let directories = response.data;

          if (includeAllDirectory) {
            directories.push(this.getPseudoDirectory(PSEUDO_TEMPLATE_DIRECTORY_TYPES.ALL_DIRECTORY));
          }

          if (includeWithoutDirectory) {
            directories.push(this.getPseudoDirectory(PSEUDO_TEMPLATE_DIRECTORY_TYPES.WITHOUT_DIRECTORY));
          }

          for (let i = 0; i < directories.length; i++) {
            this.parse(directories[i]);
          }
          return directories;
        }),
      );
  }

  /**
   * Парсинг папки
   */
  parse(directory: any): void {
    if (/^\$/.test(directory.name)) {
      directory.prettyName = this.translocoService.translate(
        `models.templateDirectory.systemMessageDirectoryTypes.${directory.name}`,
      );
    } else {
      directory.prettyName = directory.name;
    }
  }

  /**
   * Удаление папки
   */
  remove(appId: any, directoryId: any): Observable<any> {
    const params: any = {
      app: appId,
    };

    return this.httpClient.delete(`/messagetemplatesdirectories/${directoryId}`, {
      params,
      context: new HttpContext().set(EXTENDED_RESPONSE, true).set(NGX_LOADING_BAR_IGNORED, true),
    });
  }

  /**
   * Получение директории по ID
   */
  getDirectory(appId: any, directoryId: any): Observable<any> {
    const params: any = {
      app: appId,
    };

    return this.httpClient
      .get(`/messagetemplatesdirectories/${directoryId}`, {
        params,
        context: new HttpContext().set(EXTENDED_RESPONSE, true).set(NGX_LOADING_BAR_IGNORED, true),
      })
      .pipe(
        map((response: any) => {
          const directory = response.data;
          this.parse(directory);
          return directory;
        }),
      );
  }

  /**
   * Получение псевдопапки
   */
  getPseudoDirectory(directoryType: any): any {
    const pseudoDirectory: any = cloneDeep(this.DEFAULT_DIRECTORY);

    pseudoDirectory.id = PSEUDO_TEMPLATE_DIRECTORY_IDS[directoryType];
    pseudoDirectory.name = directoryType;
    pseudoDirectory.isSystem = true;
    this.parse(pseudoDirectory);
    return pseudoDirectory;
  }
}

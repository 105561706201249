(function () {
  'use strict';

  angular.module('myApp.filters.toCamelCase').filter('cqToCamelCase', cqToCamelCase);

  function cqToCamelCase(caseStyleHelper) {
    return toCamelCaseFilter;

    /**
     * Преобразование строки в camel case
     *
     * @param {String} value Строка для преобразования
     * @returns {String}
     */
    function toCamelCaseFilter(value) {
      return caseStyleHelper.toCamelCase(value);
    }
  }
})();

(function () {
  'use strict';

  require('./auto-tracking.controller');

  angular.module('myApp.trackMaster').component('cqAutoTracking', cqAutoTracking());

  function cqAutoTracking() {
    return {
      bindings: {
        billingInfo: '=', // информация о биллинге
        currentApp: '=', // Текущий App
        djangoUser: '=', // Джанго юзер
      },
      controller: 'AutoTrackingController',
      controllerAs: 'vm',
      template: require('./auto-tracking.html'),
    };
  }
})();

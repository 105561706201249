/**
 * Помощник для работы с bootstrap Date Range Picker
 */
(function () {
  'use strict';

  angular.module('myApp.services.dateRangePickerHelper').factory('dateRangePickerHelper', dateRangePickerHelper);

  function dateRangePickerHelper($translate, moment) {
    return {
      getOptions: getOptions,
    };

    /**
     * Получение дополнительных настроек для календаря
     * NOTE: пришлось сделать получение опций, которые нужно переводить отдельно, т.к. конфиг для date range picker является константой, и его можно изменять только в блоке .config, а в этом блоке переводы недоступны
     *
     * @return {Object}
     */
    function getOptions() {
      var ranges = {};

      ranges[$translate.instant('services.dateRangePickerHelper.ranges.today')] = [
        moment().startOf('day'),
        moment().endOf('day'),
      ];
      ranges[$translate.instant('services.dateRangePickerHelper.ranges.yesterday')] = [
        moment().subtract(1, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day'),
      ];
      ranges[$translate.instant('services.dateRangePickerHelper.ranges.last7Days')] = [
        moment().subtract(6, 'days').startOf('day'),
        moment().endOf('day'),
      ];
      ranges[$translate.instant('services.dateRangePickerHelper.ranges.lastMonth')] = [
        moment().subtract(1, 'month').startOf('day'),
        moment().endOf('day'),
      ];
      ranges[$translate.instant('services.dateRangePickerHelper.ranges.thisMonth')] = [
        moment().startOf('month'),
        moment().endOf('day'),
      ];
      ranges[$translate.instant('services.dateRangePickerHelper.ranges.previousMonth')] = [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ];

      return {
        locale: {
          applyLabel: $translate.instant('services.dateRangePickerHelper.locale.applyLabel'),
          cancelLabel: $translate.instant('general.cancel'),
          customRangeLabel: $translate.instant('services.dateRangePickerHelper.locale.customRangeLabel'),
        },
        ranges: ranges,
      };
    }
  }
})();

<div class="text-center">
  <h3>{{ 'modals.addApp.body.step1.title' | transloco }}</h3>
  <form
    class="flex flex-wrap justify-center"
    novalidate
  >
    <div class="d-flex margin-10 margin-between-cols-20">
      <cq-radio-new
        name="appType"
        [value]="APP_CATEGORY.SAAS"
        [(model)]="appTypeValue"
      >
        <div
          class="box card inline-block no-margin-bottom cursor-pointer"
          [ngClass]="{ 'active border-danger': appType === APP_CATEGORY.SAAS }"
        >
          <img
            class="app-type-icon"
            src="assets/img/default/add-app/saas.svg"
          />
          <h4 class="margin-top-10 no-margin-bottom">{{ 'modals.addApp.body.step1.appTypes.saas' | transloco }}</h4>
        </div>
      </cq-radio-new>
      <cq-radio-new
        name="appType"
        [value]="APP_CATEGORY.SHOP"
        [(model)]="appTypeValue"
      >
        <div
          class="box card inline-block no-margin-bottom cursor-pointer"
          [ngClass]="{ 'active border-danger': appType === APP_CATEGORY.SHOP }"
        >
          <img
            class="app-type-icon"
            src="assets/img/default/add-app/shop.svg"
          />
          <h4 class="margin-top-10 no-margin-bottom">{{ 'modals.addApp.body.step1.appTypes.shop' | transloco }}</h4>
        </div>
      </cq-radio-new>
    </div>
    <div class="d-flex margin-10 margin-between-cols-20">
      <cq-radio-new
        name="appType"
        [value]="APP_CATEGORY.LANDING"
        [(model)]="appTypeValue"
      >
        <div
          class="box card inline-block no-margin-bottom cursor-pointer"
          [ngClass]="{ 'active border-danger': appType === APP_CATEGORY.LANDING }"
        >
          <img
            class="app-type-icon"
            src="assets/img/default/add-app/landing.svg"
          />
          <h4 class="margin-top-10 no-margin-bottom">{{ 'modals.addApp.body.step1.appTypes.landing' | transloco }}</h4>
        </div>
      </cq-radio-new>
      <cq-radio-new
        name="appType"
        [value]="APP_CATEGORY.OTHER"
        [(model)]="appTypeValue"
      >
        <div
          class="box card inline-block no-margin-bottom cursor-pointer"
          [ngClass]="{ 'active border-danger': appType === APP_CATEGORY.OTHER }"
        >
          <img
            class="app-type-icon"
            src="assets/img/default/add-app/other.svg"
          />
          <h4 class="margin-top-10 no-margin-bottom">{{ 'modals.addApp.body.step1.appTypes.other' | transloco }}</h4>
        </div>
      </cq-radio-new>
    </div>
  </form>
  <div class="margin-bottom-30">{{ 'modals.addApp.body.step1.description' | transloco }}</div>
  <button
    class="btn btn-lg btn-primary margin-bottom-10"
    type="button"
    (click)="trackClickSelectAppType(appType); wizardService.nextStep()"
    [disabled]="appTypeForm.invalid"
  >
    {{ 'modals.addApp.body.step1.chooseTypeButton' | transloco }}
  </button>
</div>

import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { IPromise } from 'angular';
import { firstValueFrom, Subject } from 'rxjs';

import { App } from '@http/app/app.model';
import { AppMutedChannels, Channel, ChannelModel, ChannelNotificationSetting } from '@http/channel/channel.model';
import { DjangoUserModel } from '@http/django-user/django-user.model';
import { NotificationSettingsMapper } from '@panel/app/pages/account/shared/notification-settings/mappers';
import { DEFAULT_NOTIFICATION_SETTINGS_GENERAL } from '@panel/app/pages/account/shared/notification-settings/notification-settings-general/notification-settings-general.constants';
import {
  ApiNotificationSettingsResponse,
  NotificationSettings,
} from '@panel/app/pages/account/shared/notification-settings/types';
import { cloneObjectMutable } from '@panel/app/shared/functions/clone-object-mutable.function';
import { ToastService } from '@panel/app/shared/visual-components/toast/toast-service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsComponent implements OnInit {
  /** Массив с ид аппа и заглушенными каналами */
  appsMutedChannels: AppMutedChannels[] = [];

  /** Каналы аппов */
  channelList: ChannelNotificationSetting[] = [];

  /** Преобразованные в удобный объект аппы с каналами */
  filteredAppsChannels: Record<string, ChannelNotificationSetting[]> = {};

  /** Объект для дизейбла чекбоксов не персональных настроек */
  disabledNotPersonalNotifications: Record<string, boolean> = {};

  mutedChannels: { app: string; mutedChannels: ChannelNotificationSetting[] }[] = [];

  /** Группа форм с формами незаглушенных каналов аппа  */
  readonly notMutedChannelsFormGroup = new FormGroup<Record<string, FormControl>>({});

  /**
   * Состояния ngbCollapse по аппам
   */
  collapsedAppsState: Record<string, boolean> = {};

  @Input()
  set settingsExternal(value: ApiNotificationSettingsResponse) {
    this.settings = NotificationSettingsMapper.toInternalFormat(value);
  }

  /** Список приложений текущего пользователя */
  @Input()
  appList: App[] = [];

  @Output()
  settingsChange = new Subject<NotificationSettings>();

  settings: NotificationSettings = {
    general: cloneObjectMutable(DEFAULT_NOTIFICATION_SETTINGS_GENERAL),
    apps: [],
  };

  constructor(
    private readonly channelModel: ChannelModel,
    private readonly djangoUserModel: DjangoUserModel,
    private readonly carrotquestHelper: CarrotquestHelper,
    private readonly toastService: ToastService,
    private readonly transloco: TranslocoService,
  ) {}

  ngOnInit() {
    this.fillNotMutedChannelsFormGroupControls();

    // При наличии только 1го аппа нас-ка разворачивается сразу, поэтому запрашиваем каналы сразу
    if (this.settings.apps.length === 1) {
      this.addChannelList(this.settings.apps[0].appId);
    }
    this.settings.apps.forEach((app) => {
      this.collapsedAppsState[app.appId] = this.settings.apps.length !== 1;
    });
  }

  /**
   * Добавление каналов и настроек аппа при развертывании настроек оповещений аппа
   *
   * @param appId - Ид аппа
   * @param collapse - элемент для сворачивания\разворачивания
   */
  addChannelList(appId: string, collapse: NgbCollapse | undefined = undefined): void {
    if (!this.notMutedChannelsFormGroup.controls[appId].value) {
      firstValueFrom(this.channelModel.getMutedChannels(appId)).then((mutedChannels: string[]) => {
        this.appsMutedChannels.push({ appId, mutedChannels });

        this.getNotificationSettingsChannels(appId).then((channels) => {
          this.setValueNotMutedChannelsFormGroup(channels, appId);
          this.filteredAppsChannels[appId] = channels;
          this.channelList.push(...channels);

          collapse?.toggle();
        });
      });
    } else {
      collapse?.toggle();
    }
  }

  /**
   * Получение списка настроек заглушенных каналов для аппа
   *
   * @param appId - Ид аппа
   */
  getNotificationSettingsChannels(appId: string): IPromise<ChannelNotificationSetting[]> {
    return firstValueFrom(this.channelModel.getNotificationSettingsChannels(appId)).then((channels) => {
      return channels.filter((channel) => channel.readPermission);
    });
  }

  /**
   * Изменение состояния не персональных настроек дизейбла при колбеке и отключение оповещений не персональных настроек
   * @param appId
   * @param value
   */
  changeDisabledNotPersonalNotifications(appId: string, value: boolean): void {
    if (value) {
      const disabledNotifications = {
        desktop: false,
        push: false,
        email: false,
      };

      const app = this.settings.apps.find((app: any) => app.appId === appId);

      if (app) {
        app.settings.assignedNoOne = Object.assign({}, disabledNotifications);
        app.settings.assignedOtherAdmin = Object.assign({}, disabledNotifications);
      }
    }

    this.disabledNotPersonalNotifications[appId] = value;
  }

  /** Создание контроллеров notMutedChannelsFormGroup с пустыми значениями */
  fillNotMutedChannelsFormGroupControls(): void {
    this.appList.forEach((app) => this.notMutedChannelsFormGroup.addControl(app.id, new FormControl()));
  }

  /**
   * Заполнение значения формы notMutedChannelsFormGroup
   */
  setValueNotMutedChannelsFormGroup(channelList: ChannelNotificationSetting[], appId: string): void {
    const appMutedChannels = this.appsMutedChannels.find((ch) => ch.appId === appId);

    if (appMutedChannels) {
      const notMutedChannels = channelList.filter((channel) => {
        return appMutedChannels.mutedChannels && !appMutedChannels.mutedChannels.includes(channel.id);
      });
      this.notMutedChannelsFormGroup.controls[appMutedChannels.appId].setValue(notMutedChannels);

      this.disabledNotPersonalNotifications[appMutedChannels.appId] = !notMutedChannels.length;
    }
  }

  /**
   * Сохранение настроек оповещений аппов
   */
  saveSettings(): void {
    this.setMutedChannels();
    const notificationSettingsExternal = NotificationSettingsMapper.toExternalRequestFormat(
      this.settings,
      this.appList.map((app) => app.id),
      this.mutedChannels,
    );
    firstValueFrom(this.djangoUserModel.save(notificationSettingsExternal)).then(() => {
      this.toastService.success(this.transloco.translate('notificationSettingsComponent.toasts.notificationsSaved'));
      this.trackMutedChannelsOnSave();
      this.settingsChange.next(this.settings);
    });
  }

  /**
   * Устанавливает список с объектами заглушенных каналов
   */
  setMutedChannels(): void {
    Object.entries(this.notMutedChannelsFormGroup.value).forEach(([app, value]) => {
      if (value) {
        const notMutedChannels = value.map((channel: Channel) => channel.name);
        this.mutedChannels.push({
          app,
          mutedChannels: this.channelList.filter((ch) => !notMutedChannels.includes(ch.name)),
        });
      }
    });
  }

  trackByFn(index: number, value: NotificationSettings['apps'][number]) {
    return value.appId;
  }

  trackMutedChannelsOnSave() {
    Object.entries(this.notMutedChannelsFormGroup.value).forEach(([app, value]) => {
      if (value) {
        const notMutedChannels = value.map((channel: Channel) => channel.name);
        this.carrotquestHelper.track('Каналы - изменил настройки оповещений', {
          app,
          'Каналы с оповещениями': notMutedChannels,
          'Каналы без уведомлений': this.mutedChannels,
        });
      }
    });
  }
}

<div class="row h-100">
  <div class="col-4 pr-10 mh-100">
    <div class="card mb-0 mh-100">
      <overlay-scrollbars class="h-100 os-host-flexbox">
        <div class="card-body">
          <div class="margin-between-rows-20">
            <cq-plan-info></cq-plan-info>
            <cq-addons></cq-addons>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
  </div>
  <div class="col-8 pl-10 mh-100">
    <div class="card mh-100">
      <overlay-scrollbars class="h-100 os-host-flexbox">
        <div class="card-body p-0">
          <div class="p-20 margin-between-rows-20">
            <cq-balance></cq-balance>
            <cq-invoices></cq-invoices>
            <ng-container *ngIf="subscriptionStore.billing$ | async as billing">
              <cq-payment-info *ngIf="!billing.subscription.isTrial"></cq-payment-info>
            </ng-container>
            <ng-container *ngIf="subscriptionStore.plan$ | async as plan">
              <cq-faq
                [costOverspendingEmailPerOne]="plan.overspendingEmailPrice"
                [costOverspendingUniqueUserPerOne]="plan.overspendingUniqueUserPrice"
              ></cq-faq>
            </ng-container>
          </div>
        </div>
      </overlay-scrollbars>
    </div>
  </div>
</div>

<ng-container *transloco="let t">
  <ngb-alert
    *ngIf="isStepMade"
    class="margin-bottom-30"
    [dismissible]="false"
    type="success"
  >
    <i class="cqi-sm cqi-check-circle margin-right-10"></i>
    <span>{{ t('starterGuideSocialIntegrationsComponent.stepPassedAlert') }}</span>
  </ngb-alert>
  <div class="margin-bottom-20">
    {{ t('starterGuideSocialIntegrationsComponent.description', { projectName: projectName }) }}
  </div>
  <cq-service-installation-integrations></cq-service-installation-integrations>
  <a
    *ngIf="isShowSkippedLink()"
    class="d-block margin-top-30"
    href="#"
    (click)="skipStarterGuideSocialIntegrationsStep()"
  >
    {{ t('starterGuideSocialIntegrationsComponent.skipStep') }}</a
  >
</ng-container>

(function () {
  'use strict';

  require('./chat-settings.controller');

  angular.module('myApp.chatSettings').component('cqChatSettings', cqChatSettings());

  function cqChatSettings() {
    return {
      bindings: {
        activeChatWidgetList: '=', // Список активных чат-виджетов
        billingInfo: '=', // информация о биллинге
        botSender: '=', // Отправитель бота
        currentApp: '=', // приложение
        djangoUser: '=', // текущий пользователь
        knowledgeBase: '=', // Настройки БЗ
        messageSender: '=', // отправитель письма по-умолчанию
        routingBot: '=', // ID роутинг бота
      },
      controller: 'CqChatSettingsController',
      controllerAs: 'vm',
      template: require('./chat-settings.html'),
    };
  }
})();

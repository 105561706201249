import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DEFAULT_MEMBER_INVITATION } from '@http/team-member-invitation/constants/member-invitations.constants';
import {
  MemberInvitation,
  MemberInvitationFormData,
  MemberInvitationNotificationsFormData,
  MemberInvitationsModalParams,
} from '@http/team-member-invitation/types/member-invitations.types';
import { emailValidator } from '@panel/app/shared/validators/email/email-validator';

/**
 * Компонент-модальное окно для работы с приглашениями в команду
 */

@Component({
  selector: 'cq-member-invitation-modal',
  templateUrl: './member-invitation-modal.component.html',
  styleUrls: ['./member-invitation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberInvitationModalComponent {
  /** Инстанс модального окна на AngularJS */
  @Input()
  ajsModalInstance?: any;

  /** Параметры, передаваемые в модальное окно */
  @Input()
  modalWindowParams!: MemberInvitationsModalParams;

  /** Массив с формами приглашений */
  memberInvitationFormArray: FormArray<FormGroup<MemberInvitationFormData>> = new FormArray([
    this.getMemberInvitationForm(DEFAULT_MEMBER_INVITATION),
  ]);

  /** Можно ли отправлять несколько приглашений сразу */
  isMultipleMode: boolean = false;

  /** Получение текущего члена команды, который выполняет приглашение */
  get currentTeamMember() {
    return this.modalWindowParams.currentTeamMember;
  }

  /** Получение контролов массива с формами приглашений */
  get memberInvitationForms() {
    return this.memberInvitationFormArray.controls;
  }

  /** Получение DTO c приглашениями */
  get memberInvitations() {
    return this.memberInvitationFormArray.getRawValue();
  }

  constructor(private readonly ngbActiveModal: NgbActiveModal) {}

  /** Добавление еще одного приглашения */
  addMemberInvitation(): void {
    this.memberInvitationFormArray.push(this.getMemberInvitationForm(DEFAULT_MEMBER_INVITATION));
  }

  /** Закрытие модального окна */
  closeModal(): void {
    this.ajsModalInstance
      ? this.ajsModalInstance.close(this.memberInvitations)
      : this.ngbActiveModal.close(this.memberInvitations);
  }

  /** Отклонение модального окна */
  dismissModal(): void {
    this.ajsModalInstance ? this.ajsModalInstance.dismiss() : this.ngbActiveModal.dismiss();
  }

  /**
   * Получение человекопонятного индекса
   *
   * @param notHumanizedIndex - Не человекопонятный индекс элемента
   */
  getHumanizedIndex(notHumanizedIndex: number): number {
    return notHumanizedIndex + 1;
  }

  getMemberInvitationForm(memberInvitation: MemberInvitation) {
    return new FormGroup<MemberInvitationFormData>({
      email: new FormControl(memberInvitation.email, {
        nonNullable: true,
        validators: [Validators.required, emailValidator],
      }),
      notifications: new FormGroup<MemberInvitationNotificationsFormData>({
        desktop: new FormGroup({
          assignedMe: new FormControl(memberInvitation.notifications.desktop.assignedMe, {
            nonNullable: true,
          }),
          assignedNobody: new FormControl(memberInvitation.notifications.desktop.assignedNobody, {
            nonNullable: true,
          }),
          assignedOtherAdmin: new FormControl(memberInvitation.notifications.desktop.assignedOtherAdmin, {
            nonNullable: true,
          }),
          mentioned: new FormControl(memberInvitation.notifications.desktop.mentioned, {
            nonNullable: true,
          }),
        }),
        email: new FormGroup({
          assignedMe: new FormControl(memberInvitation.notifications.email.assignedMe, {
            nonNullable: true,
          }),
          assignedNobody: new FormControl(memberInvitation.notifications.email.assignedNobody, {
            nonNullable: true,
          }),
          assignedOtherAdmin: new FormControl(memberInvitation.notifications.email.assignedOtherAdmin, {
            nonNullable: true,
          }),
          mentioned: new FormControl(memberInvitation.notifications.email.mentioned, {
            nonNullable: true,
          }),
        }),
        push: new FormGroup({
          assignedMe: new FormControl(memberInvitation.notifications.push.assignedMe, {
            nonNullable: true,
          }),
          assignedNobody: new FormControl(memberInvitation.notifications.push.assignedNobody, {
            nonNullable: true,
          }),
          assignedOtherAdmin: new FormControl(memberInvitation.notifications.push.assignedOtherAdmin, {
            nonNullable: true,
          }),
          mentioned: new FormControl(memberInvitation.notifications.push.mentioned, {
            nonNullable: true,
          }),
        }),
        stats: new FormControl(memberInvitation.notifications.stats, {
          nonNullable: true,
        }),
      }),
      permissions: new FormControl(memberInvitation.permissions, {
        nonNullable: true,
      }),
      permissionsExport: new FormControl(memberInvitation.permissionsExport, {
        nonNullable: true,
      }),
      permissionsSendBulk: new FormControl(memberInvitation.permissionsSendBulk, {
        nonNullable: true,
      }),
      permissionsConversationUserList: new FormControl(memberInvitation.permissionsConversationUserList, {
        nonNullable: true,
      }),
    });
  }

  /** Все ли приглашения валидные */
  isAllMemberInvitationValid(): boolean {
    return this.memberInvitationFormArray.valid;
  }

  /**
   * Доступна ли отправка приглашений
   *
   * NOTE:
   *  Данная функция возвращает true|null, т.к. используется для определение disabled состояния у кнопки отправки
   *  приглашения, а [disabled] ожидает true|null.
   */
  isDisabledSendInvitationButton(): boolean | null {
    return this.isAllMemberInvitationValid() ? null : true;
  }

  /** Отправляется ли несколько приглашений сразу */
  isMultipleMemberInvitations(): boolean {
    return this.memberInvitationForms.length > 1;
  }

  /**
   * Удаление приглашения
   *
   * @param orderNumber - Порядковый номер приглашения
   */
  removeMemberInvitation(orderNumber: number): void {
    this.memberInvitationFormArray.removeAt(orderNumber);
  }
}

/**
 * Директива для получения Blob-объекта при загрузке файла
 */
(function () {
  'use strict';

  angular.module('myApp.directives.file').directive('cqFileAsBlob', cqFileAsBlob);

  function cqFileAsBlob($parse, fileHelper) {
    return {
      restrict: 'A',
      require: ['ngModel', 'cqFile'],
      controller: angular.noop,
      link: link,
    };

    function link(scope, iElement, iAttrs, controller) {
      var modelSetter = $parse(iAttrs.cqFileAsBlob).assign;
      var ngModelController = controller[0];

      scope.$watch(
        function () {
          return ngModelController.$viewValue;
        },
        function (newValue) {
          var blob;

          if (newValue) {
            blob = fileHelper.getBlob(newValue);
          } else {
            blob = null;
          }

          modelSetter(scope, blob);
        },
      );
    }
  }
})();

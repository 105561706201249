import {
  POPUP_BLOCK_BACKGROUND_POSITION_TYPES,
  POPUP_BLOCK_BACKGROUND_TYPES,
  POPUP_BLOCK_TYPES,
} from '../../../../../app/services/popup-block/popup-block.constants';

(function () {
  'use strict';

  angular
    .module('myApp.popupEditor')
    .controller('CqPopupTextBlockSettingsController', CqPopupTextBlockSettingsController);

  function CqPopupTextBlockSettingsController(
    $scope,
    $translate,
    FROALA_EDITOR_KEY,
    LANGUAGE,
    carrotquestHelper,
    fileHelper,
    froalaHelper,
    popupBlockModel,
    shortUuid,
    validationHelper,
  ) {
    var vm = this;

    /**
     * Максимальный размер картинки
     *
     * @type {Number}
     */
    var BACKGROUND_IMAGE_MAX_SIZE = 1024 * 1024;

    /**
     * Минимальное значение минимальной высоты блока
     *
     * @type {number}
     */
    var BLOCK_MIN_HEIGHT = 0;

    /**
     * Максимальное значение минимальной высоты блока
     *
     * @type {number}
     */
    var BLOCK_MAX_HEIGHT = 1000;

    /**
     * Типы минимальной высоты блока
     *
     * @type {Object}
     */
    var BLOCK_MIN_HEIGHT_TYPES = {
      BY_TEXT_HEIGHT: 'byTextHeight', // по высоте текста
      FIXED: 'fixed', // фиксированная, в пикселях
    };

    /**
     * Настройки для редактора Froala
     *
     * @type {Object}
     */
    var FROALA_OPTIONS = {
      colorsHEXInput: true,
      events: {
        'froalaEditor.initialized': froalaHelper.autoFocusOnEvent,
      },
      fontSize: ['10', '11', '12', '14', '16', '18', '20', '24', '30', '36', '48', '60', '72', '96'],
      imagePaste: false,
      imageUpload: false,
      key: FROALA_EDITOR_KEY,
      language: LANGUAGE,
      lineHeights: {
        '1 ': '1',
        1.1: '1.1',
        1.2: '1.2',
        1.3: '1.3',
        1.4: '1.4',
        1.5: '1.5',
        1.6: '1.6',
      },
      linkAlwaysBlank: true,
      linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
      linkInsertButtons: [],
      pasteDeniedTags: ['table', 'th', 'tr', 'td', 'ul', 'ol', 'dl', 'li', 'dt', 'dd'], // хоть и pastePlain включен - фроала по прежнему продолжает вставлять таблицы и списки
      pastePlain: true,
      placeholderText: $translate.instant('popupTextBlockSettings.froala.placeholderText'),
      requestWithCORS: false,
      theme: 'carrot-quest',
      toolbarButtons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'fontSize',
        'lineHeight',
        'align',
        'color',
        '|',
        'insertLink',
        'insert_user_prop',
        '|',
        'changeEditorColor',
      ],
      toolbarButtonsMD: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'fontSize',
        'lineHeight',
        'align',
        'color',
        '|',
        'insertLink',
        'insert_user_prop',
        '|',
        'changeEditorColor',
      ],
      toolbarButtonsSM: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'fontSize',
        'lineHeight',
        'align',
        'color',
        '|',
        'insertLink',
        'insert_user_prop',
        '|',
        'changeEditorColor',
      ],
      toolbarButtonsXS: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'fontSize',
        'lineHeight',
        'align',
        'color',
        '|',
        'insertLink',
        'insert_user_prop',
        '|',
        'changeEditorColor',
      ],
      toolbarSticky: false,
    };
    FROALA_OPTIONS.lineHeights[$translate.instant('general.default')] = '';

    /**
     * Вкладки
     *
     * @type {{CONTENT: String, STYLE: String}}
     */
    var TABS = {
      CONTENT: 'content', // содержимое
      STYLE: 'style', // стиль
    };

    /**
     * Уникальный идентификатор для корректной работы радиобаттонов при наличии нескольких компонентов на одной странице
     * Без этого индентификатора, ID у input'ов будут одинаковые, а это противоречит правилам HTML, поэтому при клике на чекбоксы/радио будут баги
     * Тот же самый баг будет с одинаковыми именами input[type="radio"], т.к. такие радио объединяются в radio group
     */
    const UUID = shortUuid();

    vm.$onInit = init;
    vm.$onDestroy = destroy;

    function init() {
      /**
       * Изначальная фоновая картинка
       *
       * @type {File|String|null}
       */
      var currentBackgroundImage = vm.popupBlockParams.backgroundImage;

      vm.backgroundPosition = popupBlockModel.getBackgroundPositionType(vm.popupBlockParams); // текущий тип позиционирования фона
      vm.BACKGROUND_IMAGE_MAX_SIZE = BACKGROUND_IMAGE_MAX_SIZE;
      vm.BLOCK_MIN_HEIGHT = BLOCK_MIN_HEIGHT;
      vm.BLOCK_MAX_HEIGHT = BLOCK_MAX_HEIGHT;
      vm.BLOCK_MIN_HEIGHT_TYPES = BLOCK_MIN_HEIGHT_TYPES;
      vm.blockMinHeightType = getBlockMinHeightType(); // текущий тип минимальной высоты блока
      vm.contentForm = null; // форма вкладки "Содержимое"
      vm.currentBackgroundImagePreview =
        currentBackgroundImage instanceof File
          ? fileHelper.getObjectUrl(currentBackgroundImage)
          : currentBackgroundImage; // URL изначальной фоновой картинки (для вывода превью)
      vm.currentTab = TABS.CONTENT; // текущая вкладка
      vm.fileHelper = fileHelper;
      vm.FROALA_OPTIONS = FROALA_OPTIONS;
      vm.newBackgroundImage = {
        // новая фоновая картинка
        file: null, // файл нового фона
        url: '', // URL нового фона (для вывода превью)
      };
      vm.POPUP_BLOCK_BACKGROUND_TYPES = POPUP_BLOCK_BACKGROUND_TYPES;
      vm.POPUP_BLOCK_BACKGROUND_POSITION_TYPES = POPUP_BLOCK_BACKGROUND_POSITION_TYPES;
      vm.popupBlockModel = popupBlockModel;
      vm.setBlockMinHeightType = setBlockMinHeightType;
      vm.showBackgroundColorSettings = vm.popupBlockParams.backgroundType === POPUP_BLOCK_BACKGROUND_TYPES.COLOR;
      vm.styleForm = null; // форма вкладки "Стиль"
      vm.TABS = TABS;
      vm.trackClickOnContent = trackClickOnContent;
      vm.trackClickOnStyle = trackClickOnStyle;
      vm.UUID = UUID;
      vm.validationHelper = validationHelper;

      vm.setErrorHandler && vm.setErrorHandler({ handler: handleError });

      $scope.$watch('vm.newBackgroundImage.file', setBackgroundImage);

      /**
       * Получение текущего типа минимальной высоты блока
       *
       * @return {BLOCK_MIN_HEIGHT_TYPES}
       */
      function getBlockMinHeightType() {
        if (vm.popupBlockParams.autoMinHeight) {
          return BLOCK_MIN_HEIGHT_TYPES.BY_TEXT_HEIGHT;
        } else {
          return BLOCK_MIN_HEIGHT_TYPES.FIXED;
        }
      }

      /**
       * Установка фоновой картинки
       * Так пришлось извращаться, потому что поле vm.popupBlockParams.backgroundImage напрямую не связано с моделью инпута
       *
       * @param {File|String|null} newValue
       */
      function setBackgroundImage(newValue) {
        if (newValue) {
          // если новая фоновая картинка присутствует (выбрана и валидна) - выставляем её в качестве нового фона
          vm.popupBlockParams.backgroundImage = newValue;
        } else {
          // иначе - возвращаем старую картинку
          vm.popupBlockParams.backgroundImage = currentBackgroundImage;
        }
      }
    }

    function destroy() {
      // при уничтожении компонента нужно выставить значения необязательным полям, т.к. все поля должны быть заполнены
      if (!vm.popupBlockParams.text) {
        vm.popupBlockParams.text = $translate.instant('popupTextBlockSettings.defaultText');
      }
    }

    /**
     * Функция открытия вкладки с ошибкой при отправке формы снаружи компонента
     */
    function handleError() {
      // искусственно сабмитим формы (с ngForm по-другому не получится) на случай, если родительская форма не была засабмичена, чтобы отображались ошибки
      vm.contentForm.$setSubmitted();
      vm.styleForm.$setSubmitted();

      // если открыта невалидная вкладка - переключаться на другую вкладку не нужно
      if (vm.contentForm.$invalid && vm.styleForm.$valid) {
        vm.currentTab = TABS.CONTENT;
      } else if (vm.styleForm.$invalid && vm.contentForm.$valid) {
        vm.currentTab = TABS.STYLE;
      }
    }

    /**
     * Установка параметров блока в зависимости от типа минимальной высоты блока
     *
     * @param {BLOCK_MIN_HEIGHT_TYPES} blockMinHeightType Тип минимальной высоты блока
     */
    function setBlockMinHeightType(blockMinHeightType) {
      switch (blockMinHeightType) {
        case BLOCK_MIN_HEIGHT_TYPES.BY_TEXT_HEIGHT:
          vm.popupBlockParams.autoMinHeight = true;
          break;
        case BLOCK_MIN_HEIGHT_TYPES.FIXED:
          vm.popupBlockParams.autoMinHeight = false;
          break;
      }
    }

    /**
     * Трек клика на вкладку 'Содержимое'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnContent(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на содержимое блока', {
        Название: POPUP_BLOCK_TYPES.TEXT,
      });
    }

    /**
     * Трек клика на вкладку 'Стиль'
     *
     * @param {MESSAGE_PART_TYPES} messagePartType - Тип сообщения
     */
    function trackClickOnStyle(messagePartType) {
      var sdkPhrase = '';
      if (messagePartType === MESSAGE_PART_TYPES.SDK_BLOCK_POPUP_SMALL) {
        sdkPhrase = ' в SDK';
      }

      carrotquestHelper.track('Конструктор поп-апов' + sdkPhrase + ' - клик на стиль блока', {
        Название: POPUP_BLOCK_TYPES.TEXT,
      });
    }
  }
})();

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { mergeMap, shareReplay, startWith } from 'rxjs/operators';

import { DjangoUserModel } from '@http/django-user/django-user.model';
import { DjangoUser } from '@http/django-user/django-user.types';

@Injectable({
  providedIn: 'root',
})
export class DjangoUserService {
  private readonly refresh$ = new Subject<void>();

  private readonly getDjangoUser$ = this.djangoUserModel.get(true);

  readonly djangoUser$: Observable<DjangoUser> = merge(this.refresh$, this.djangoUserModel.refresh$).pipe(
    // Нужен, чтоб при первой подписке на observable отработали пайпы и отдали значение
    // Если это удалить, то при первой подписке подписчик сразу ничего не получит, пока не будет вызван refresh$
    startWith(null),
    mergeMap(() => this.getDjangoUser$),
    // Кэширует последнее значение, чтоб при каждой новой подписке (и без необходимости) не вызывался запрос на бэк
    shareReplay(1),
  );

  constructor(private readonly djangoUserModel: DjangoUserModel, private readonly http: HttpClient) {}

  /**
   * Проверка возможности удаления пользователя
   */
  checkDeletePossibility(): Observable<{}> {
    return this.http.get<{}>('/djangousers/$self_django_user/check_before_delete', {});
  }

  /**
   * Удаление django-пользователя
   *
   * @param password Пароль от аккаунта
   */
  delete(password: string): Observable<{}> {
    return this.http.delete<{}>('/djangousers/$self_django_user', { params: { password } });
  }

  refreshDjangoUser(): void {
    return this.refresh$.next();
  }
}

<ng-container *transloco="let t; read: 'categoryListComponent'">
  <div
    *ngIf="categories.length"
    class="card-header flex"
  >
    <!--СОЗДАТЬ КАТЕГОРИЮ-->
    <button
      class="btn btn-outline-primary margin-right-20"
      (click)="trackClickAddCategory(); openArticlesCategoryEditorModal()"
      data-test="cq-category-list-create-category-button"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ 'knowledgeBase.categoryList.createCategoryButton' | transloco }}</span>
    </button>

    <!--ПОИСК-->
    <div class="margin-left-auto">
      <ng-content></ng-content>
    </div>
  </div>

  <table
    *ngIf="categories.length"
    class="table table-align-middle table-sticky-header position-sticky"
  >
    <thead>
      <tr>
        <th>{{ 'knowledgeBase.categoryList.table.headings.name' | transloco }}</th>
        <th>{{ 'knowledgeBase.categoryList.table.headings.articlesCount' | transloco }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let category of categories; index as index; trackBy: trackByFn">
        <!--НАЗВАНИЕ-->
        <td>
          <div class="flex">
            <h4
              class="category-icon margin-right-20 no-margin-bottom"
              [innerHTML]="category.icon"
            ></h4>
            <a
              class="inline-block"
              uiSref="app.content.knowledgeBase.category"
              [uiParams]="{ categoryId: category.id }"
              [attr.data-test]="'cq-category-list-category-link-' + index"
              >{{ category.name }}</a
            >
          </div>
        </td>

        <!--КОЛИЧЕСТВО СТАТЕЙ-->
        <td>
          <div class="text-break">
            {{
              'knowledgeBase.categoryList.table.body.articlesCount'
                | transloco: { articlesCount: category.articlesCounter }
            }}
          </div>
        </td>

        <!--КНОПКИ-->
        <td class="shrink-by-content">
          <div class="flex align-items-center justify-end">
            <!--Переместить вниз-->
            <button
              *ngIf="index !== categories.length - 1"
              class="btn btn-borderless-primary"
              (click)="changePriority(category.id, categories[index + 1].priority)"
            >
              <i class="btn-icon cqi-sm cqi-arrow-down"></i>
            </button>
            <!--Переместить вверх-->
            <button
              *ngIf="index !== 0"
              class="btn btn-borderless-primary"
              (click)="changePriority(category.id, categories[index - 1].priority)"
            >
              <i class="btn-icon cqi-sm cqi-arrow-up"></i>
            </button>
            <div
              class="inline-block"
              ngbDropdown
            >
              <button
                class="btn btn-borderless-primary"
                type="button"
                ngbDropdownToggle
                [attr.data-test]="'cq-category-list-ellipsis-button-' + index"
              >
                <i class="btn-icon cqi-sm cqi-ellipsis"></i>
              </button>
              <div
                class="dropdown-menu-right"
                ngbDropdownMenu
              >
                <!--Изменить-->
                <button
                  class="dropdown-item"
                  type="button"
                  (click)="openArticlesCategoryEditorModal(category)"
                >
                  <i class="dropdown-item-icon-left cqi-sm cqi-pencil"></i>
                  <span>{{ 'knowledgeBase.categoryList.table.body.articleActionsDropdown.change' | transloco }}</span>
                </button>
                <!--Удалить-->
                <div
                  [ngbTooltip]="
                    'knowledgeBase.categoryList.table.body.articleActionsDropdown.remove.canNotRemoveTooltip'
                      | transloco
                  "
                  [disableTooltip]="category.articlesCounter === 0"
                  placement="left"
                >
                  <button
                    class="dropdown-item"
                    type="button"
                    (click)="openRemoveArticlesCategoryModal(category)"
                    [disabled]="category.articlesCounter !== 0"
                    [attr.data-test]="'cq-category-list-delete-button-' + index"
                  >
                    <i class="dropdown-item-icon-left cqi-sm cqi-trash"></i>
                    <span>{{
                      'knowledgeBase.categoryList.table.body.articleActionsDropdown.remove.text' | transloco
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!--ZERO DATA-->
  <div
    *ngIf="!categories.length"
    class="margin-60 text-center"
  >
    <img
      class="margin-bottom-20"
      src="assets/img/default/book.png"
    />
    <h4>{{ 'knowledgeBase.categoryList.zeroData.noCategories.heading' | transloco }}</h4>
    <div
      class="margin-bottom-20"
      [innerHTML]="'knowledgeBase.categoryList.zeroData.noCategories.description' | transloco"
    ></div>
    <button
      class="btn btn-outline-primary"
      (click)="openArticlesCategoryEditorModal()"
    >
      <i class="btn-icon-left cqi-sm cqi-plus"></i>
      <span>{{ 'knowledgeBase.categoryList.zeroData.noCategories.createButton' | transloco }}</span>
    </button>
  </div>
</ng-container>

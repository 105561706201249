import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { UIRouter } from '@uirouter/core';

import { environment } from '@environment';
import { App } from '@http/app/app.model';
import { STARTER_GUIDE_STEPS } from '@http/starter-guide/starter-guide.constants';
import { PLAN_FEATURE } from '@panel/app/services/billing/plan-feature/plan-feature.constants';
import { ProductFeatureAccess } from '@panel/app/services/billing/plan-feature/plan-feature.types';
import { PlanFeatureAccessService } from '@panel/app/services/billing/plan-feature-access/plan-feature-access.service';
import { VideoViewModalService } from '@panel/app/services/video-view/video-view-modal.service';
import { CarrotquestHelper } from '@panel/app-old/shared/services/carrotquest-helper/carrotquest-helper.service';

@Component({
  selector: 'cq-starter-guide-engagement-in-conversation[app][isTriggerMessageStepMade][isLeadsCollectionStepMade]',
  templateUrl: './engagement-in-conversation.component.html',
  styleUrls: ['./engagement-in-conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngagementInConversationComponent implements OnInit {
  @Input()
  app!: App;

  @Input()
  isTriggerMessageStepMade: boolean = false;

  @Input()
  isLeadsCollectionStepMade: boolean = false;

  @Input()
  activeLeadBots = 0;

  @Input()
  activeTriggerMessages = 0;

  readonly activeLang = this.translocoService.getActiveLang();

  readonly projectName: string = environment.projectName;

  accessToLeadBots!: ProductFeatureAccess;

  accessToAutoMessagesTotal!: ProductFeatureAccess;

  constructor(
    private readonly carrotQuestHelper: CarrotquestHelper,
    private readonly translocoService: TranslocoService,
    private readonly videoViewModalService: VideoViewModalService,
    private readonly uiRouter: UIRouter,
    private readonly productFeatureAccess: PlanFeatureAccessService,
  ) {}

  ngOnInit() {
    this.accessToLeadBots = this.productFeatureAccess.getAccess(PLAN_FEATURE.LEAD_BOTS, this.app, this.activeLeadBots);
    this.accessToAutoMessagesTotal = this.productFeatureAccess.getAccess(
      PLAN_FEATURE.AUTO_MESSAGES_TOTAL,
      this.app,
      this.activeTriggerMessages,
    );
  }

  goToTriggerMessageCreation(): void {
    this.uiRouter.stateService.go('app.content.messagesAjs.create', {
      fromStarterGuideStep: STARTER_GUIDE_STEPS.FIRST_TRIGGER_MESSAGE,
    });
    this.carrotQuestHelper.track('Стартергайд - нажал кнопку создания триггерного сообщения');
  }

  goToBotCreation(): void {
    this.uiRouter.stateService.go('app.content.messagesAjs.chatBot.create.new', {
      fromStarterGuideStep: STARTER_GUIDE_STEPS.LEADS_COLLECTION,
    });
    this.carrotQuestHelper.track('Стартергайд - нажал кнопку создания лид-бота');
  }

  /**
   * Открытие модального окна для просмотра видео
   */
  openViewVideoModal(): void {
    this.trackOpenVideo();

    this.videoViewModalService.open({
      heading: this.translocoService.translate('engagementInConversationComponent.video.text'),
      videoUrl: this.translocoService.translate('engagementInConversationComponent.video.link'),
    });
  }

  trackOpenVideo(): void {
    this.carrotQuestHelper.track('Стартергайд - видео про разинцу между ботом и триггерным просмотрено');
  }
}

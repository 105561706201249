(function () {
  'use strict';

  require('./templates.controller');

  angular
    .module('myApp.modals.directoryEditor.templates')
    .component('cqTemplatesDirectoryEditorModal', cqTemplatesDirectoryEditorModal());

  function cqTemplatesDirectoryEditorModal() {
    return {
      bindings: {
        modalInstance: '=',
        resolve: '=',
        // В resolve должны передаваться следующие параметры:
        // currentApp - текущее приложение
        // {Object=} directory - Папка
      },
      controller: 'CqTemplatesDirectoryEditorController',
      controllerAs: 'vm',
      template: require('../directory-editor.html'),
    };
  }
})();

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

import { App } from '@http/app/app.model';
import { IntegrationExternal } from '@http/integration/interfaces/integration.interfaces';
import {
  MessageTelegramBodyJson,
  MessageTelegramButton,
  MessageTelegramContent,
} from '@http/message-part/message-part.types';
import { TelegramBodyJsonForm } from '@panel/app/pages/trigger-messages/telegram-part-editor/telegram-part-editor.types';
import { messageTelegramEmptyBlocksValidator } from '@panel/app/pages/trigger-messages/telegram-part-editor/telegram-part-editor.validators';
import {
  FORM_SUBMIT_SOURCE_TOKEN,
  formSubmitTokenProviders,
} from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger.tokens';
import { MessageEditorTriggerState } from '@panel/app/partials/message-editor/trigger/message-editor-trigger-wrapper/message-editor-trigger-state.service';
import { ValidationCallback } from '@panel/app/partials/message-editor/trigger/validation-callback.type';
import { DestroyService } from '@panel/app/services';
import { EventType, UserProperty } from '@http/property/property.model';

@Component({
  selector: 'cq-telegram-part-editor',
  templateUrl: './telegram-part-editor.component.html',
  styleUrls: ['./telegram-part-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, formSubmitTokenProviders, MessageEditorTriggerState],
})
export class TelegramPartEditorComponent implements OnInit {
  @Input()
  set bodyJson(bodyJson: MessageTelegramBodyJson) {
    // HACK this.bodyJsonChange.emit(value) триггерит этот инпут
    // этим условием я ограничил пересбор формы ТОЛЬКО на реальный инпут
    if (this._bodyJson === bodyJson) {
      return;
    }
    this._bodyJson = bodyJson;
    this.form.setValue(bodyJson, { emitEvent: false });
    this.telegramIntegrationId = bodyJson.integration;
  }
  _bodyJson!: MessageTelegramBodyJson;

  @Input()
  set userProps(userProps: UserProperty[]) {
    this.messageEditorTriggerState.userProps$.next(userProps);
  }

  @Input()
  telegramIntegrations: IntegrationExternal[] = [];

  @Input()
  set eventTypes(eventTypes: EventType[]) {
    // HACK eventTypesChange.emit триггерит этот инпут
    // этим условием я ограничил пересбор формы ТОЛЬКО на реальный инпут
    if (this.messageEditorTriggerState.eventTypes$.getValue() === eventTypes) {
      return;
    }
    this.messageEditorTriggerState.eventTypes$.next(eventTypes);
  }

  @Input()
  currentApp!: App;

  @Output()
  bodyJsonChange: EventEmitter<MessageTelegramBodyJson> = new EventEmitter<MessageTelegramBodyJson>();

  @Output()
  submitValidation: EventEmitter<() => void> = new EventEmitter<() => void>();

  /**
   * Этот Output нужен чтобы засинхронить валидации Ajs с валидностью формы этого компонента
   */
  @Output()
  validationChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  readonly eventTypesChange = new EventEmitter<EventType[]>();

  form = this.fb.group<TelegramBodyJsonForm>(
    {
      contents: this.fb.control<MessageTelegramContent[]>([], { nonNullable: true }),
      buttons: this.fb.control<MessageTelegramButton[]>([], {
        nonNullable: true,
        validators: [Validators.maxLength(10)],
      }),
      integration: this.fb.control<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
      propToWrite: this.fb.control<string | null>(null),
    },
    {
      validators: messageTelegramEmptyBlocksValidator,
    },
  );

  telegramIntegrationId: string | null = null;

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroy$: DestroyService,
    @Inject(FORM_SUBMIT_SOURCE_TOKEN)
    readonly formSubmitSubject: Subject<void>,
    protected readonly messageEditorTriggerState: MessageEditorTriggerState,
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((formData) => {
      //@ts-ignore
      this._bodyJson = formData;
      //@ts-ignore
      this.bodyJsonChange.emit(formData);
    });
    this.form.statusChanges
      .pipe(startWith(this.form.status), takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => {
        this.validationChange.emit(this.form.valid);
      });

    this.submitValidation.emit(() => {
      this.formSubmitSubject.next();
      this.integrationValidationCallback();
    });

    this.messageEditorTriggerState.eventTypes$
      .pipe(takeUntil(this.destroy$))
      .subscribe((eventTypes) => this.eventTypesChange.next(eventTypes));
  }

  setValidationCallback(cb: ValidationCallback) {
    this.integrationValidationCallback = cb;
  }

  integrationValidationCallback = () => {};

  onTelegramIntegrationIdChange(value: any) {
    const integration = this.telegramIntegrations.find((integration) => integration.id === value);
    this.form.controls.integration.setValue(integration?.id || null);
  }

  get buttons() {
    return this.form.controls.buttons;
  }

  get contents() {
    return this.form.controls.contents;
  }

  get propToWrite() {
    return this.form.controls.propToWrite;
  }
}

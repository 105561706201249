(function () {
  'use strict';

  angular
    .module('myApp.privacySettings')
    .controller('CqDoubleOptInSubscriptionConfirmPreviewController', CqDoubleOptInSubscriptionConfirmPreviewController);

  function CqDoubleOptInSubscriptionConfirmPreviewController() {
    var vm = this;
  }
})();
